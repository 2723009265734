var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "header-wrap"
  }, [_vm.isNeedInfo ? _c('div', {
    staticClass: "status-header"
  }, [_vm._v(" " + _vm._s("".concat(_vm.tt('입고하기 위해, [정보입력]을 클릭하여 필수정보 입력을'), " ").concat(_vm.tt('완성해 주세요'))) + " ")]) : _vm._e(), _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s("".concat(_vm.statusText, " ").concat(_vm.inventory.name)))]), _c('div', {
    staticClass: "subtext"
  }, [_vm.item.brand && _vm.item.brand.name ? _c('div', {
    staticClass: "brand",
    domProps: {
      "innerHTML": _vm._s(_vm.formatBrand(_vm.item.brand))
    }
  }) : _vm._e(), _vm.item.inventoryFields.subname ? _c('div', {
    staticClass: "subname",
    domProps: {
      "innerHTML": _vm._s(_vm.formatText(_vm.item.inventoryFields.subname))
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "right print-hide"
  }, [_c('div', {
    staticClass: "right-gray"
  }, [_c('base-button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$emit('print');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('출력')))]), _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('삭제')))]), _vm.item.status === 'WAIT' && !_vm.isNeedInfo ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$emit('edit');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('편집')))]) : _vm._e()], 1), _vm.item.status !== 'COMPLETE' ? _c('div', {
    staticClass: "right-primary"
  }, [_vm.isNeedInfo ? _c('button', {
    staticClass: "button-primary",
    on: {
      "click": function click($event) {
        return _vm.$emit('needInput');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('정보 입력')))]) : _vm.item.status === 'WAIT' ? _c('button', {
    staticClass: "button-primary",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-cargo'].show();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('입고 처리')) + " ")]) : _vm._e()]) : _vm._e()])]), _c('modal-confirm', {
    ref: "modal-cargo",
    attrs: {
      "title": _vm.tt('입고 처리'),
      "content": _vm.tt('입고 처리 하시겠습니까?'),
      "confirmText": "입고 처리"
    },
    on: {
      "confirm": _vm.processCargo
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }