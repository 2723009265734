var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "address-input-wrapper"
  }, [_vm.hasPostcode ? _c('div', {
    staticClass: "address-search"
  }, [_c('number-input', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "error": !_vm.isValidPostcode,
      "inputStyle": {
        width: 'calc(100% - 8px)'
      },
      "disabled": ""
    },
    model: {
      value: _vm.info.postcode,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "postcode", $$v);
      },
      expression: "info.postcode"
    }
  }), _c('button', {
    staticClass: "btn btn-gray sm",
    on: {
      "click": _vm.openAddressFinder
    }
  }, [_vm._v(_vm._s(_vm.tt('주소검색')))])], 1) : _vm._e(), _c('div', {
    staticClass: "address-search"
  }, [_c('text-input', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "error": !_vm.isValidAddress,
      "placeholder": _vm.addressPlaceholder,
      "inputStyle": {
        width: !_vm.hasPostcode ? 'calc(100% - 8px)' : '100%'
      },
      "disabled": ""
    },
    model: {
      value: _vm.info.address,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "address", $$v);
      },
      expression: "info.address"
    }
  }), !_vm.hasPostcode ? _c('button', {
    staticClass: "btn btn-gray sm",
    on: {
      "click": _vm.openAddressFinder
    }
  }, [_vm._v(_vm._s(_vm.tt('주소검색')))]) : _vm._e()], 1), _c('text-input', {
    attrs: {
      "placeholder": _vm.tt('상세주소를 입력해주세요'),
      "error": !_vm.isValidAddressDetail,
      "errorMessage": _vm.errorMessage,
      "disabled": !_vm.info.address,
      "inputStyle": {
        width: '100%'
      }
    },
    on: {
      "input": function input($event) {
        _vm.$emit('search', _vm.getAddress());
      }
    },
    model: {
      value: _vm.info.addressDetail,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "addressDetail", $$v);
      },
      expression: "info.addressDetail"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }