<template>
	<div class="common-detail-wrapper">
		<h5>{{ tt('예산 편성') }}</h5>
		<div class="status-table-wrapper">
			<h6>{{ tt('예산표') }}</h6>
			<div class="status-table">
				<div class="left-fixed" style="width: 200px">
					<div style="width: 200px">
						<div class="header group bd sm">{{ tt('예산명') }}</div>
						<div class="item bd">{{ name }}</div>
					</div>
				</div>
				<div class="table-content">
					<div :style="{ '--width': `${Object.keys(monthKey).length * 120}px` }">
						<div class="column" v-for="key in Object.keys(monthKey)" :key="key">
							<div class="header sm">
								<div>
									<div class="item bd" :class="{ disabled: budgetType == 'YEAR' && key != 'year' }">
										{{ tt(monthKey[key]) }}
									</div>
								</div>
							</div>
							<div class="item">
								<div>
									<div class="item bd" v-if="key != 'year'">
										<input
											:ref="key"
											v-if="budgetType == 'MONTH'"
											class="input-budget"
											:placeholder="tt('금액 입력')"
											type="number"
											@change="setBudget(key)"
										/>
										<div v-else class="disabled-area" @click="showChangeModal"></div>
									</div>
									<div class="item bd" v-else>
										<input
											:ref="key"
											v-if="budgetType == 'YEAR'"
											class="input-budget"
											:placeholder="tt('금액 입력')"
											type="number"
											@change="setBudget(key)"
										/>
										<div class="item disabled-area yellow" v-else @click="showChangeModal">{{ tt('${1}원', totalAmount) }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<modal-confirm ref="modal-change" title="예산 분류 변경" confirmText="변경" :content="modalChangeContent" />
	</div>
</template>

<script>
import MixinDate from '@/mixins/date'
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'BudgetDivide',
	mixins: [MixinDate],
	computed: {
		...mapGetters('budget/item', ['budgetType', 'year', 'budget', 'name']),
		monthKey() {
			let monthKey = { year: this.tt('계(${1}년)', this.year) }
			monthKey = {
				...monthKey,
				...this.monthObject,
			}
			return monthKey
		},
		modalChangeContent() {
			return `현재 예산 분류가 ‘${this.budgetType == 'YEAR' ? '연' : '월별'} 예산’으로 선택되어있습니다.<br>${
				this.budgetType == 'YEAR' ? '월별' : '연'
			} 예산으로 변경하시겠습니까?`
		},
		totalAmount() {
			let amount = 0
			if (this.budgetType == 'MONTH')
				Object.entries(this.budget).forEach(([key, value]) => {
					if (key != 'year') amount += value ?? 0
				})
			else amount = this.budget.year
			return amount
		},
	},
	created() {
		if (this.budgetType == null || this.budgetType == undefined) {
			this.$router.go(-1)
		}
	},
	mounted() {
		this.setData()
	},
	methods: {
		...mapMutations('budget/item', ['setRegistrationInfo']),
		showChangeModal() {
			this.$refs['modal-change'].show(() => {
				this.changeBudgetType()
			})
		},
		changeBudgetType() {
			let budgetType = this.budgetType == 'YEAR' ? 'MONTH' : 'YEAR'
			this.setRegistrationInfo({ budgetType: budgetType })
			this.$forceUpdate()
			this.$root.toast('예산 분류 변경', `${budgetType == 'YEAR' ? '월별' : '연'} 예산으로 변경되었습니다.`, 'success')
			this.setData()
		},
		isValid() {
			try {
				let budget = {}
				Object.keys(this.budget).forEach(key => {
					if (this.$refs[key] && this.$refs[key][0]) budget[key] = this.$refs[key][0].value
					else budget[key] = null
				})
				this.setRegistrationInfo({ budget })
				if (this.totalAmount <= 0) throw 'err'
				return true
			} catch (error) {
				console.log(error)
				this.$root.toast('예산 편성', `예산을 0원 이상 입력해주세요.`, 'error')
				return false
			}
		},
		setBudget(key) {
			let budget = {}
			budget[key] = this.$refs[key][0].value
			this.setRegistrationInfo({ budget })
		},
		async setData() {
			if (this.budget != null) {
				await this.$nextTick()
				Object.entries(this.budget).forEach(([key, value]) => {
					if (value != null) this.$refs[key][0].value = value
				})
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
@import '@/styles/scss/table/_table-budget.scss';
.subtext {
	margin-top: 10px;
	font-size: 14px;
	color: $SECONDARY_2;
}
.common-detail-wrapper {
	min-height: calc(100% - 130px);
}
.input-budget {
	background: $LAB_WHITE;
	border: 1px solid $INPUT;
	border-radius: 5px;
	height: 30px;
	width: 110px;
	padding-left: 10px;
}
.table-content {
	width: calc(100% - 200px);
}
.disabled-area {
	background: $GREY_1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	&:hover,
	&:active {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), $GREY_1;
	}
}
.disabled {
	color: #cacbc8;
}
</style>
