var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inputWrapper"
  }, [_c('div', {
    staticClass: "storage step"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('보관함 등록하기')))]), _vm._l(_vm.storageList, function (storage, index) {
    return _c('div', {
      key: index,
      staticClass: "storage-item"
    }, [_c('h1', [_vm._v(_vm._s(storage))]), _c('button', {
      attrs: {
        "id": index
      },
      on: {
        "click": function click($event) {
          return _vm.deleteStorage(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/Close-1.svg")
      }
    })])]);
  }), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputStorageValue,
      expression: "inputStorageValue"
    }],
    ref: "input",
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('예: 위험물보관함')
    },
    domProps: {
      "value": _vm.inputStorageValue
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addStorageList.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputStorageValue = $event.target.value;
      }
    }
  }), _vm.isValid && _vm.storageList.length == 0 ? _c('span', {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.tt('엔터키를 눌러 목록에 추가하세요')))]) : _vm._e()])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }