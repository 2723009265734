export default {
	props: {
		value: {
			type: String,
			default: '',
		},
		optionList: {
			type: Array,
			default: null,
		},
		isSetDefault: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShowOption: false,
			inputValue: null,
		}
	},
	computed: {
		selectOptionName() {
			return this.inputValue ? this.inputValue : this.placeholder
		},
	},
	watch: {
		isShowOption(value) {
			if (value) {
				window.addEventListener('click', this.closeOptionList)
			} else {
				window.removeEventListener('click', this.closeOptionList)
			}
		},
		value(changeValue) {
			this.inputValue = changeValue
		},
	},
	mounted() {
		if (this.inputValue !== this.value) {
			this.inputValue = this.value
		}
	},
	methods: {
		toggleShowOption(e) {
			let beforeState = this.isShowOption
			if (!this.optionList || this.optionList.length <= 1) return
			document.body.click()
			this.isShowOption = !beforeState
		},
		closeOptionList(e) {
			if (e.target.parentNode !== this.$refs[`select-box`]) {
				this.close()
			}
		},
		clickOption(item) {
			this.inputValue = item
			this.$emit('input', item)
			this.isShowOption = false
		},
		close() {
			this.isShowOption = false
		},
	},
}
