const Validation = {
	autoTraderNumber(companyNum) {
		// ref: https://life-with-coding.tistory.com/46 [나다움]
		companyNum = companyNum.replace(/[^0-9]/g, '')
		let tempNum = ''
		if (companyNum.length < 4) {
			return companyNum
		} else if (companyNum.length < 6) {
			tempNum += companyNum.substr(0, 3)
			tempNum += '-'
			tempNum += companyNum.substr(3, 2)
			return tempNum
		} else if (companyNum.length < 11) {
			tempNum += companyNum.substr(0, 3)
			tempNum += '-'
			tempNum += companyNum.substr(3, 2)
			tempNum += '-'
			tempNum += companyNum.substr(5)
			return tempNum
		} else {
			tempNum += companyNum.substr(0, 3)
			tempNum += '-'
			tempNum += companyNum.substr(3, 2)
			tempNum += '-'
			tempNum += companyNum.substr(5)
			return tempNum
		}
	},
	autoHypenTel(str) {
		// ref: https://hgko1207.github.io/2019/01/18/javascript-autoHypenTel/
		str = str.replace(/[^0-9]/g, '')
		let tmp = ''

		if (str.substring(0, 2) == '02') {
			// 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
			if (str.length < 3) {
				return str
			} else if (str.length < 6) {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2)
				return tmp
			} else if (str.length < 10) {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2, 3)
				tmp += '-'
				tmp += str.substr(5)
				return tmp
			} else {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2, 4)
				tmp += '-'
				tmp += str.substr(6, 4)
				return tmp
			}
		} else {
			// 핸드폰 및 다른 지역 전화번호 일 경우
			if (str.length < 4) {
				return str
			} else if (str.length < 7) {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3)
				return tmp
			} else if (str.length < 11) {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3, 3)
				tmp += '-'
				tmp += str.substr(6)
				return tmp
			} else {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3, 4)
				tmp += '-'
				tmp += str.substr(7)
				return tmp
			}
		}
	},
	autoCasno(str) {
		str = str.replace(/[^0-9]/g, '')
		let tmp = ''
		let len = str.length
		if (str.length < 2) {
			return str
		} else if (str.length < 4) {
			tmp += str.substr(0, len - 1) + '-' + str.substr(len - 1)
		} else {
			tmp += str.substr(0, len - 3) + '-' + str.substr(len - 3, 2) + '-' + str.substr(len - 1)
		}
		return tmp
	},
	validCasno(str) {
		str = str.replace(/[^0-9]/g, '')
		let sum = 0
		let j = str.length - 1
		if (str.length < 5) return false
		for (let i = 0; i < str.length - 1; i++) {
			sum += parseInt(str[i]) * j--
		}
		return sum % 10 == parseInt(str[str.length - 1])
	},
	validPhoneNumber(phoneNumber) {
		if (phoneNumber.length > 0) {
			const isValid = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(phoneNumber)
			return isValid
		} else {
			return false
		}
	},
}

export default Validation
