<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalInitPwSetting">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('초기 비밀번호 설정') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<h6>{{ tt('초기 비밀번호를 설정해주세요') }}</h6>
					<p v-html="tt('유저 계정의 초기 비밀번호를 설정합니다.<br />멤버 등록 시, 유저의 이메일로 초기 비밀번호가 전송됩니다.')"></p>
					<input type="text" :placeholder="tt('초기 비밀번호')" v-model="initPw" />
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="changePw">
							{{ tt('저장') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { mapState, mapActions } from 'vuex'
import instituteMemberAPI from '@/services/api/institute/member'

export default {
	name: 'ModalInitPwSetting',
	mixins: [MixinModal],
	data() {
		return {
			initPw: null,
			mode: null,
		}
	},
	methods: {
		show(mode) {
			this.initPw = null

			if (mode) {
				this.mode = mode
			}

			this.loadInitPw()
			this.isShow = true
		},
		async loadInitPw() {
			const response = await instituteMemberAPI.lookupInitPw(this.$route.params.instituteId)

			this.initPw = response.data.password
		},
		changePw() {
			const payload = {
				password: this.initPw,
			}

			if (this.initPw.length == 0 || this.initPw == null) {
				this.$root.toast(this.tt('저장 실패'), this.tt('초기 비밀번호를 입력해주세요'), 'error')

				return false
			} else if (this.initPw.length < 6) {
				this.$root.toast(this.tt('저장 실패'), this.tt('초기 비밀번호는 6자리 이상이어야 합니다'), 'error')

				return false
			}

			instituteMemberAPI.updateInitPw(this.$route.params.instituteId, payload)

			this.$root.toast(this.tt('초기 비밀번호 설정 완료'), this.tt('초기 비밀번호가 설정되었습니다'), 'success')

			if (this.mode == 'init') {
				this.$emit('registering')
			} else {
				this.$emit('hideInitPwSetting')
			}
			this.hide()
		},
		close() {
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalInitPwSetting {
	color: #000;
	h6 {
		font-size: 1rem;
		font-weight: 700;
	}
	p {
		font-size: 0.875rem;
		font-weight: normal;
		margin-top: 5px;
	}
	input {
		width: 100%;
		border: 1px solid #e6e6e6;
		border-radius: 5px;
		padding: 14px;
		font-size: 0.875rem;
		margin-top: 14px;
	}
}
</style>
