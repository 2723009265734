var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLogin ? _c('div', {
    staticClass: "migration-header"
  }, [_c('span', {
    staticClass: "migration-header-text",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  }), _c('div', {
    staticClass: "migration-right-header"
  }, [_c('app-header-menu')], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }