<template>
	<div class="button-area-wrapper">
		<div class="left-area">
			<!-- <div class="question-text" @click="openMigrationDocs">
				<img src="@/assets/svg/migration/icon-question.svg" />
				<span class="question-inner-text" v-text="questionAreaText"></span>
			</div> -->
		</div>
		<div class="right-area">
			<div class="cancel-button" @click="handleClickBack">
				{{ tt(backButtonText) }}
			</div>
			<div class="lm-button-w150" @click="handleClickNext" :disabled="nextDisable" :class="{ disable: nextDisable }">
				<span class="button-text">
					{{ tt(nextButtonText) }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import MixinMigration from '@/mixins/migration'
export default {
	mixins: [MixinMigration],
	props: {
		backButtonText: {
			type: String,
			default: '이전으로',
		},
		nextButtonText: {
			type: String,
			default: '다음으로',
		},
		nextDisable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			questionAreaText: this.tt('데이터는 어떻게 이전되나요?'),
		}
	},
	methods: {
		handleClickNext() {
			this.$emit('next')
		},
		handleClickBack() {
			this.$emit('back')
		},
	},
}
</script>

<style lang="scss" scoped>
.button-area-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding-top: 30px;
	.left-area {
		.question-text {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 400;
			line-height: 23px;
			letter-spacing: 0px;
			text-align: left;
			color: $SECONDARY_1;
			.question-inner-text {
				padding-left: 6px;
			}
		}
	}
	.right-area {
		width: 245px;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.cancel-button {
			cursor: pointer;
			width: 60px;
			height: 24px;
			color: $SECONDARY_2;
			font-size: 16px;
			font-weight: 700;
			line-height: 23px;
			letter-spacing: 0px;
			text-align: right;
		}
		.lm-button-w150 {
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $DEFAULT;
			font-size: 16px;
			font-weight: 700;
			line-height: 23px;
			letter-spacing: 0px;
			text-align: center;
			height: 48px;
			width: 150px;
			border-radius: 5px;
			background: $LAB_YELLOW;
			box-shadow: 0px 1px 2px 0px #0000001a;

			&.disable {
				cursor: initial;
				border: 1px solid $GREY_4;
				background: $GREY_2;
				color: #bebebe;
			}
		}
	}
}
</style>
