<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title"><img src="@/assets/svg/store/labmanager-store.svg" style="margin-right: 5px" />{{ tt('상품 검색') }}</div>
		</template>
		<template>
			<div class="search-wrap">
				<div class="search-box-main">
					<div class="search-box-wrap">
						<!-- <search-box
							:placeholder="tt('상품명, CAS No, 제품번호, 브랜드로 검색')"
							:value="keyword"
							@change="changeMainSearch"
						></search-box> -->
						<store-search-box
							ref="search-box"
							:placeholder="tt('물품명, CAS No, 제품번호, 브랜드로 검색')"
							:value="keyword"
							@change="changeMainSearch"
						/>
						<merck-tooltip v-if="keyword.length === 0 && !selectedItem && isMerckTooltipShow" :cssVariable="cssVariable">
							<img width="360px" src="@/assets/images/icon-sale-search2.png" />
						</merck-tooltip>
					</div>
					<!-- <div class="btn-wrap">
						<base-button @click="subSearch = !subSearch">
							{{ tt('결과 내 검색') }}
							<img class="chevron" v-if="!subSearch" src="./svg/chevron-down.svg" />
							<img class="chevron" v-else src="./svg/chevron-up.svg" />
						</base-button>
					</div> -->
				</div>
				<!-- <div class="search-box-sub" v-if="subSearch">
					<sub-search-box
						noRecent
						:placeholder="tt('결과 내 검색')"
						v-if="subSearch"
						:value="subKeyword"
						@change="changeSubSearch"
					></sub-search-box>
				</div> -->
			</div>
			<div class="table-wrap" v-if="!selectedItem">
				<search-sort-tab :tabList="tabList" :selectTab="catalogSelectTab" @change="changeTab"></search-sort-tab>
				<ul v-if="list.length !== 0" ref="scroll-container" @scroll="onScroll">
					<merck-tooltip v-if="keyword && isMerckTooltipShow" :cssVariable="cssVariable2">
						<img width="360px" src="@/assets/images/icon-sale-select.png" />
					</merck-tooltip>
					<product-info-item
						v-for="(item, index) in list"
						:key="index"
						:item="item"
						:keyword="keyword"
						@click="getStoreItem"
					></product-info-item>
				</ul>
				<div class="no-data" v-else-if="keyword.length === 0">
					<h6>{{ tt('상품 검색') }}</h6>
					<span>
						{{ tt('상품명, CAS No, 제품번호, 브랜드로 검색해주세요') }}
					</span>
				</div>
				<div class="no-data" v-else>
					<h6>{{ tt('결과 없음') }}</h6>
					<p>
						{{ tt('검색결과가 없습니다.') }} <br />
						{{ tt('랩매니저 스토어를 통해 상품을 요청해보세요.') }}
					</p>
				</div>
			</div>
			<div class="product-detail-wrap" v-else>
				<image-button :text="'뒤로가기'" :image="require('@/assets/svg/Left.svg')" @click="prev"></image-button>
				<ul>
					<product-info-item :item="selectedItem" :keyword="keyword" :usePrice="true" :isSearch="false"></product-info-item>
				</ul>
				<div class="check-wrap">
					<search-check-box
						:title="'판매자'"
						:list="sellerFilterList"
						:type="'sellerIds'"
						@change="setProductLoadParams"
					></search-check-box>
					<search-check-box
						:title="'사이즈(용량)'"
						:list="sizeFilterList"
						:type="'options'"
						@change="setProductLoadParams"
					></search-check-box>
				</div>
				<product-search-sort-tab :tabList="tabList" :selectTab="productSelectTab" @change="changeProductTab"></product-search-sort-tab>
				<div style="position: relative">
					<product-info-table @addShow="isTooltip = false" @addHide="isTooltip = true" @confirm="hide"></product-info-table>
					<merck-tooltip
						v-if="isMerckTooltipShow && isTooltip && selectedItem.brand && selectedItem.brand.isMerck"
						:cssVariable="cssVariable3"
					>
						<img width="360px" src="@/assets/images/icon-sale-add.png" />
					</merck-tooltip>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<base-button @click="hide">{{ tt('닫기') }}</base-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SearchBox from '@/views/components/searchBox'
import SearchSortTab from './components/modalStoreSearchProduct/SearchSortTab.vue'
import ProductInfoItem from './components/modalStoreSearchProduct/ProductInfoItem.vue'
import SearchCheckBox from './components/modalStoreSearchProduct/SearchCheckBox.vue'
import ProductInfoTable from './components/modalStoreSearchProduct/ProductInfoTable.vue'
import MerckTooltip from '@/views/common/components/tooltip/MerckTooltip.vue'
import MixinScrolling from '@/mixins/scrolling'
//
import StoreSearchBox from '@/views/components/storeSearchBox'
import { BUS } from '@/utils/modules/EventBus'

export default {
	components: {
		SearchBox: SearchBox,
		SubSearchBox: SearchBox,
		SearchSortTab: SearchSortTab,
		ProductSearchSortTab: SearchSortTab,
		ProductInfoItem,
		SearchCheckBox,
		ProductInfoTable,
		MerckTooltip,
		//
		StoreSearchBox,
	},
	mixins: [MixinScrolling],
	props: {},
	watch: {
		isBottom(value) {
			if (value) {
				this.addList()
			}
		},
	},
	created() {
		BUS.$on('merckTooltipHide', isShow => {
			this.isMerckTooltipShow = isShow
		})
	},
	computed: {
		...mapState('draft/form', ['list', 'selectedItem']),
		...mapState('scroll', ['scrollHeight']),
		cssVariable() {
			return {
				'--top': '-44%',
				'--left': '99%',
				'--backgroundColor': 'transparent',
				'--textColor': '#eb3c96',
			}
		},
		cssVariable2() {
			return {
				'--top': '4%',
				'--left': '50%',
				'--backgroundColor': 'transparent',
				'--textColor': '#eb3c96',
			}
		},
		cssVariable3() {
			return {
				'--top': '-10%',
				'--left': '59%',
				'--backgroundColor': 'transparent',
				'--textColor': '#eb3c96',
			}
		},
		sellerFilterList() {
			let list = [
				{
					name: '전체',
					id: 'ALL',
				},
			]
			let arr = []
			this.selectedItem.productInfos.forEach(pi => {
				if (!arr.some(it => it.id === pi.seller.id)) arr.push({ name: pi.seller.name, id: pi.seller.id })
			})
			return [...list, ...arr]
		},
		sizeFilterList() {
			let list = [
				{
					name: '전체',
					id: 'ALL',
				},
			]
			let arr = []
			this.selectedItem.productInfos.forEach(pi => {
				pi.products.forEach(p => {
					if (!arr.some(it => it.id === p.options[0]?.value))
						arr.push({
							name: p.options[0]?.value,
							id: p.options[0]?.value,
						})
				})
			})
			return [...list, ...arr]
		},
	},
	data() {
		return {
			keyword: '',
			subKeyword: '',
			subSearch: false,
			catalogSelectTab: 0,
			productSelectTab: 0,
			tabList: [
				{
					id: 'RECOMMEND',
					name: '추천순',
					direction: 'DESC',
				},
				{
					id: 'FAVORITE',
					name: '구매 많은순',
					direction: 'DESC',
				},
				{
					id: 'PRICE',
					name: '낮은 가격순',
					direction: 'ASC',
				},
				{
					id: 'PRICE',
					name: '높은 가격순',
					direction: 'DESC',
				},
			],
			isMerckTooltipShow: true,
			isTooltip: true,
		}
	},
	methods: {
		...mapMutations('draft/form', ['setInitSearchProduct', 'setSelectedItem']),
		...mapMutations('scroll', ['setScrollHeight']),
		...mapActions('draft/form', ['setLoadParams', 'getItem', 'setProductLoadParams', 'addList']),
		init() {
			this.keyword = ''
			this.subKeyword = ''
			this.subSearch = false
			this.catalogSelectTab = 0
			this.productSelectTab = 0
			this.setInitSearchProduct()
		},
		show() {
			this.init()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		prev() {
			this.productSelectTab = 0
			this.setSelectedItem(null)
			setTimeout(() => {
				this.$refs['scroll-container'].scrollTo(0, this.scrollHeight)
			}, 100)
		},
		async getStoreItem(id, isRecommend) {
			const { scrollTop } = this.$refs['scroll-container']
			this.setScrollHeight(scrollTop)
			await this.getItem({ id, isRecommend })
		},
		changeMainSearch(keyword) {
			this.keyword = keyword.value
			this.setSelectedItem(null)
			this.setLoadParams({ offset: 0, keyword: this.keyword, keywordTarget: keyword.type })
			if (this.list.length > 0) this.moveScrollTop()
			this.$refs['search-box'].focusOut()
		},
		changeSubSearch(keyword) {
			this.subKeyword = keyword
			this.setLoadParams({ offset: 0, subKeyword: this.subKeyword })
			if (this.list.length > 0) this.moveScrollTop()
		},
		changeTab(index) {
			this.catalogSelectTab = index
			this.setLoadParams({ offset: 0, order: [this.tabList[index].id], orderDirection: [this.tabList[index].direction] })
			if (this.list.length > 0) this.moveScrollTop()
		},
		changeProductTab(index) {
			this.productSelectTab = index
			this.setProductLoadParams({ offset: 0, order: [this.tabList[index].id], orderDirection: [this.tabList[index].direction] })
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-store-search-product.scss';

.btn-wrap {
	width: calc(100% - 370px);
	display: flex;
	justify-content: space-between;
}
.no-data {
	display: flex;
	width: 100%;
	height: 580px;
	background-color: $GREY_2;
	border-top: 1px solid $DIVIDER;
	border-bottom: 1px solid $DIVIDER;
	font-size: 0.875rem;
	align-items: center;
	justify-content: center;
	color: $PLACEHOLDER;
	text-align: center;
	flex-direction: column;
	h6 {
		font-weight: 700;
		font-size: 1.125rem;
		margin-bottom: 10px;
	}
	span {
		font-weight: 400;
		font-size: 0.875rem;
	}
	button {
		margin-top: 15px;
		border: 1px solid #000000;
		border-radius: 4px;
	}
}
</style>
