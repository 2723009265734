<template>
	<b-modal ref="modal-single-select-storage" centered no-close-on-backdrop no-fade @close="hide">
		<template #modal-title
			><div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="search-wrap">
				<search-box noRecent :placeholder="tt('보관함을 검색해주세요')" :value="keyword" @change="setKeyword" ref="search-box"></search-box>
			</div>
			<div class="folding-wrapper">
				<div @click="setTreeOpen(true)"><img src="@/assets/svg/icon-unfold.svg" />{{ tt('전체 펼치기') }}</div>
				<span class="br"></span>
				<div @click="setTreeOpen(false)"><img src="@/assets/svg/icon-fold.svg" />{{ tt('전체 접기') }}</div>
			</div>
			<div class="content">
				<div class="tree-wrap">
					<location-node v-for="location in list" :key="location.id" :node="location" type="location"></location-node>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left">
					<button class="button-white" @click="goStorageManagement">
						{{ tt('보관함 관리') }}
					</button>
				</div>
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="complete">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import PageName from '@/utils/define/PageName'
import SearchBox from '@/views/components/searchBox/index.vue'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import LocationNode from '@/views/common/components/tree/gen2/components/LocationNode.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
	name: 'ModalSingleSelectStorage',
	components: { SearchBox, TreeView, LocationNode },
	props: {
		title: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			default: null,
		},
	},
	computed: {
		...mapGetters('tree/location', ['list', 'keyword', 'selectedNode']),
	},
	beforeDestroy() {
		this.setSelectedNode(null)
	},
	methods: {
		...mapActions('tree/location', ['getLocationStorageTree']),
		...mapMutations('tree/location', ['setUsingTreeNodeType', 'setKeyword', 'setSelectedNode', 'setTreeOpen']),
		async show(defaultStorage) {
			if (defaultStorage) {
				this.setSelectedNode(defaultStorage)
			}
			this.setUsingTreeNodeType(['storage'])
			await this.getLocationStorageTree()
			this.$refs['modal-single-select-storage'].show()
		},
		hide() {
			this.$refs['search-box'].deleteValue()
			this.$refs['modal-single-select-storage'].hide()
			this.setSelectedNode(null)
			// this.$emit('complete', null)
		},
		complete() {
			this.$refs['search-box'].deleteValue()
			this.$refs['modal-single-select-storage'].hide()
			this.$emit('complete', this.selectedNode)
		},
		goStorageManagement() {
			this.$router.push({
				name: PageName.Institution.Location,
			})
		},
		init() {
			this.setSelectedNode(null)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-single-select-storage.scss';
.tree-wrap {
	margin-left: -15px;
	margin-top: 10px;
}
</style>
