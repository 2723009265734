<template>
	<div class="form-wrap">
		<div class="header">
			<div>
				<span>{{ tt('기안') }}</span>
			</div>
			<div class="draft-info">
				<b>{{ tt('기안') }}</b>
				<p>
					<span>{{ user.name }}</span>
					<span>{{ groupName }}</span>
				</p>
			</div>
		</div>
		<div class="content">
			<div>
				<span>{{ tt('결재자') }}</span>
			</div>
			<draggable v-model="selectedItems" draggable=".approval-info" v-bind="dragOptions" @start="isDragging = true" @end="isDragging = false">
				<transition-group type="transition" name="flip-list">
					<div class="approval-info" v-for="(member, index) in selectedItems" :key="`member-${index}`">
						<img class="item-handle" :src="require('@/assets/svg/purchase/Move.svg')" />
						<b>{{ tt('결재자') }}</b>
						<p>
							<span>{{ member.name }}</span>
							<span>{{ member.parent.name }}</span>
						</p>
						<img :src="require('@/assets/svg/Trash.svg')" @click="deleteMember(index)" />
					</div>
				</transition-group>
			</draggable>
			<div v-if="selectedItems.length === 0" class="no-data" :class="{ error: !isValid }">
				<p>
					<span>{{ tt('결재선을 설정해 주세요') }}</span>
				</p>
			</div>
		</div>
		<div class="footer">
			<span v-html="footerText"></span>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import draggable from 'vuedraggable'

export default {
	name: 'ApprovalLineForm',
	components: {
		draggable,
	},
	props: {
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isDragging: false,
		}
	},
	computed: {
		...mapState('user', ['user']),
		...mapState('institute/member', ['selectedMembers']),
		...mapState('draft/item', ['groupList', 'draftInfo']),
		groupName() {
			const selectedGroup = this.groupList.find(group => group.id === this.draftInfo.groupId)
			if (!selectedGroup) return ''
			return selectedGroup.name
		},
		footerText() {
			return this.tt(`<p>≡ 에 마우스를 올린 후,</p><b>드래그</b><p>하여&nbsp</p><b>결재 순서</b><p>를 바꿀 수 있습니다.</p>`)
		},
		selectedItems: {
			get() {
				return this.selectedMembers
			},
			set(value) {
				this.setSelectedMembers(value)
			},
		},
		dragOptions() {
			return {
				handle: '.item-handle',
				// group: { pull: true, put: true },
				// animation: 150,
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('institute/member', ['setSelectedMembers', 'deleteMember']),
	},
}
</script>

<style lang="scss" scoped>
.form-wrap {
	width: 60%;
	border: 1px solid $INPUT;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-left: 20px;
	.header {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100px;
		div {
			padding: 10px;
			&:nth-child(1) {
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				span {
					font-weight: 700;
					font-size: 14px;
					color: $SECONDARY_2;
				}
				background: $GREY_2;
				border-bottom: 1px solid $INPUT;
			}
		}
	}
	.content {
		height: 442px;
		overflow-y: auto;
		overflow-x: hidden;
		display: flex;
		flex-direction: column;
		& > div:nth-child(1) {
			padding: 10px;
			span {
				font-weight: 700;
				font-size: 14px;
				color: $SECONDARY_2;
			}
			background: $GREY_2;
			border-top: 1px solid $INPUT;
			border-bottom: 1px solid $INPUT;
		}
		.no-data {
			color: $SECONDARY_2;
			align-self: center;
			font-size: 14px;
			line-height: 350px;
			&.error {
				color: $RED;
			}
		}
	}
	.footer {
		background: #fbfaf9;
		border-top: 1px solid $INPUT;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		span {
			padding: 6px;
			display: flex;
			justify-content: center;
			font-size: 12px;
		}
	}
	.draft-info {
		display: flex;
		font-size: 0.75rem;
		color: $SECONDARY_2;
		margin-top: 6px;
		b {
			height: 20px;
			padding: 0 5px;
			border-radius: 5px;
			font-weight: 700;
			text-align: center;
			display: inline-block;
			margin-right: 10px;
			background-color: $DIVIDER;
			color: $SECONDARY_2;
			&.approval {
				background: $SUB-YELLOW_100;
				color: $TEXT_YELLOW;
			}
		}
		p {
			span {
				font-weight: 400;
				color: $SECONDARY_2;
				padding-right: 16px;
				position: relative;
				&:nth-last-child(2) {
					&:before {
						width: 1px;
						height: 12px;
						background-color: $PLACEHOLDER;
						position: absolute;
						top: 2px;
						right: 8px;
						content: '';
					}
				}
			}
		}
	}
	.approval-info {
		display: flex;
		font-size: 0.75rem;
		// margin-top: 6px;
		border-bottom: 1px solid $INPUT;
		line-height: 24px;
		padding: 10px;
		&.flip-list-move {
			transition: transform 0.5s;
		}
		&.no-move {
			transition: transform 0s;
		}
		&.sortable-ghost {
			opacity: 0.5;
			background: $DIVIDER;
		}
		img {
			cursor: pointer;
			margin-right: 10px;
			&:last-child {
				margin-left: auto;
			}
		}
		b {
			padding: 0 5px;
			border-radius: 5px;
			font-weight: 700;
			text-align: center;
			display: inline-block;
			margin-right: 10px;
			background-color: $SUB-YELLOW_100;
			color: $TEXT_YELLOW;
		}
		p {
			span {
				font-weight: 400;
				padding-right: 16px;
				position: relative;
				&:nth-last-child(2) {
					&:before {
						width: 1px;
						height: 12px;
						background-color: $PLACEHOLDER;
						position: absolute;
						top: 2px;
						right: 8px;
						content: '';
					}
				}
			}
		}
		h2 {
			font-weight: 400;
		}
	}
}
</style>
