var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "select-box",
    staticClass: "form-select-box unit"
  }, [_c('div', {
    staticClass: "select-box"
  }, [_vm.placeholder ? _c('span', [_vm._v(_vm._s(_vm.placeholder))]) : _vm._e(), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "toggle-class": "text-decoration-none",
      "variant": "link",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.selectOptionName)))])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.optionList, function (option) {
    return _c('b-dropdown-item', {
      key: "option-".concat(option),
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.clickOption(option);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(option)) + " ")]);
  }), 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }