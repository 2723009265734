export default {
	computed: {
		isASC() {
			return this.orderDirection == 'ASC'
		},
		defaultUnCheck() {
			return ['categoryClassification', 'categoryType']
		},
	},
	methods: {
		changeOrder(order) {
			let orderDirection = 'ASC'
			// 즐겨찾기 sort는 desc만 , 즐겨찾기 sort 해제했을시 - CREATE_DATETIME & DESC로
			if ((this.order == order && this.isASC) || order === 'FAVORITE' || this.order === 'FAVORITE') orderDirection = 'DESC'
			this.setLoadParams({
				order: [order],
				orderDirection: [orderDirection],
			})
		},
		orderClass(order) {
			return {
				order: true,
				active: order == this.order,
				asc: this.isASC,
			}
		},
		isView(type) {
			return (this.checkedViewOption.length == 0 && !this.defaultUnCheck.includes(type)) || this.checkedViewOption.includes(type)
		},
	},
}
