<template>
	<div class="detail-page-wrapper" v-if="item && item.id == $route.params.id">
		<!-- header -->
		<draft-detail-header
			:title="item.subject"
			:subtitle="item.no"
			:status="item.status"
			:permission="item.draftDetailPermission"
			:orderId="item.orderId"
			@click="showConfirmModal"
		></draft-detail-header>
		<div class="detail-content-wrapper">
			<div>
				<store-order-info
					v-if="item.purchaseType == 'INTERNAL'"
					ref="store-order-info"
					type="DRAFT"
					content="상신하신 지출 결의서가 승인되면, 랩매니저 스토어에 주문이 자동 접수됩니다."
				/>
			</div>
			<div>
				<h5>{{ tt('결재 정보') }}</h5>
				<div class="approval-info-wrapper">
					<approval-info-item
						v-if="item.register"
						:approvalTitle="'기안'"
						:name="item.register.name"
						:affiliation="item.group.name"
						:dateTime="item.createDatetime ? formatDatetime(item.createDatetime) : '-'"
					></approval-info-item>
					<div>
						<approval-info-item
							v-for="(approval, index) in item.approvals"
							:key="`approval_${index}`"
							:approvalTitle="'결재'"
							:approvalState="approval.status"
							:name="approval.user.name"
							:affiliation="approval.group.name"
							:dateTime="approval.concludeDatetime ? formatDatetime(approval.concludeDatetime) : '-'"
						></approval-info-item>
					</div>
				</div>
			</div>
			<div>
				<h5>{{ tt('기안 정보') }}</h5>
				<info-table :items="draftInfoForTable"></info-table>
			</div>
			<div>
				<h5>{{ tt('상품 정보') }}</h5>
				<product-info-table :productInfoList="item.purchaseItems"></product-info-table>
			</div>
			<div v-if="item.budgetStatus">
				<total-amount :title="'예산 현황'" :info="amountForm" :budget="item.budgetStatus"></total-amount>
			</div>
			<div>
				<div class="file-wrap">
					<h5>{{ tt('첨부 파일') }}</h5>
					<image-button
						v-if="attachmentsForTable[0].files && attachmentsForTable[0].files.length > 0"
						:text="'모두 다운로드'"
						:image="require('@/assets/svg/icon-file-download.svg')"
						@click="$refs['files-table'].downloadAll()"
					></image-button>
				</div>
				<info-table ref="files-table" :items="attachmentsForTable"></info-table>
			</div>
		</div>
		<!-- Modal -->

		<modal-confirm ref="modal-cancel" title="상신 취소" content="지출결의서 상신을 취소하시겠습니까?" confirmText="상신 취소" variant="warning" />
		<modal-confirm
			ref="modal-order-cancel"
			title="주문 취소"
			content="해당 결재에 연결된 스토어 주문이 있습니다.<br>주문 상세보기 버튼을 눌러 주문을 취소해주세요."
			confirmText="주문 상세보기"
			variant="warning"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MixinExpenditureDetail from '@/mixins/purchase/expenditureDetail'
import DraftDetailHeader from '../components/detail/header.vue'
import ApprovalInfoItem from '@/views/purchase/draft/components/detail/ApprovalInfoItem.vue'
import InfoTable from '@/views/purchase/draft/components/detail/InfoTable.vue'
import ProductInfoTable from '@/views/purchase/draft/components/detail/ProductInfoTable.vue'
import TotalAmount from '@/views/purchase/draft/components/detail/TotalAmount.vue'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
import ImageButton from '@/views/common/components/baseButton/ImageButton.vue'
import StoreOrderInfo from '@/views/purchase/draft/components/regist/StoreOrderInfo.vue'
export default {
	name: 'DraftDetail',
	mixins: [MixinExpenditureDetail],
	props: {},
	components: {
		DraftDetailHeader,
		ApprovalInfoItem,
		InfoTable,
		ProductInfoTable,
		TotalAmount,
		ModalCommon,
		ImageButton,
		StoreOrderInfo,
	},

	data() {
		return {}
	},
	computed: {
		...mapState('draft/item', ['item']),
	},
	methods: {
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		...mapActions('draft/item', ['getDetail', 'delete']),
		async loadDetail() {
			try {
				await this.getDetail(this.$route.params.id)
				this.setStorageDetailPaths([this.item.subject])
			} catch (err) {
				this.$root.toast('기안', '기안을 취소하거나 삭제한 문서입니다.', 'error')
				this.$router.go(-1)
			}
		},
		showDraftCancelModal() {
			if (this.$root.userPermission.draft?.delete)
				this.$refs['modal-common'].show('상신 취소', '지출결의서 상신을 취소하시겠습니까?', 'complete', '상신 취소')
			else this.$root.toast('권한', '상신 취소 권한이 없습니다.', 'error')
		},

		showConfirmModal(params) {
			if (this.$root.userPermission.draft?.update) {
				if (params == 'CANCEL') {
					this.$refs['modal-cancel'].show(() => {
						this.cancelDraft()
					})
				} else if (params == 'STORE_ORDER') {
					this.gotoStoreOrderDetail()
				} else if (params == 'STORE_PAYMENT') {
					this.gotoStorePayment()
				}
			} else this.$root.toast('권한', `${title} 권한이 없습니다.`, 'error')
		},
		async cancelDraft() {
			try {
				await this.delete(this.$route.params.id)
				this.$router.go(-1)
				this.$root.toast(this.tt('상신 취소 완료'), this.tt('지출결의 상신을 취소했습니다.'), 'success')
			} catch {
				this.$root.toast(this.tt('상신 취소 실패'), this.tt('최종 승인 또는 반려된 기안은 취소할 수 없습니다.'), 'error')
			}
		},
		gotoStoreOrderDetail() {
			this.$root.goToStore(`mypage/order/${this.item.orderId}`)
		},
		gotoStorePayment() {
			this.$root.goToStore(`mypage/unpayment`)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/purchase/expenditure/detail/_expenditure-detail.scss';
</style>
