import inventoryAPI from '@/services/api/inventory/gen2'

const state = {
	list: [],
	count: 0,
	loadParams: {
		offset: 0,
		length: 50,
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
		keyword: '',
		startDatetime: '',
		endDatetime: '',
	},
	filter: {},
	selectedItems: [],
}

const getters = {
	filter: state => state.filter,
	isEmptySelectedItems: state => state.selectedItems.length === 0,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			state.loadParams[key] = loadParams[key]
		})
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	setSelectedItems: (state, data) => {
		state.selectedItems = data
	},
}

const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		try {
			const response = await inventoryAPI.history(rootGetters.instituteId, state.loadParams)
			commit('setList', response.data)
		} catch (e) {
			console.warn(e)
		}
	},
	getFilter: async ({ commit, rootGetters }) => {
		try {
			const response = await inventoryAPI.historyFilter(rootGetters.instituteId)
			commit('setFilter', response.data)
		} catch (e) {}
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		dispatch('getList')
	},
	removeItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.removeHistory(rootGetters.instituteId, body)
		dispatch('getList')
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
