var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "Modal ModalNoticeOrganization"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Modal__container"
  }, [_c('div', {
    staticClass: "Modal__content"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('조직도 설정하기')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('기관 내 <b>그룹(부서/연구실/팀)</b>을 트리구조로 등록할 수 있습니다.<br>그룹별로 <b>그룹원 관리,</b> 그룹에 <b>접근 가능한 인원/그룹을 관리</b>할 수 있습니다.'))
    }
  }), _vm._m(0), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('시작하기')) + " ")])])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/create-1.png")
    }
  })]);
}]

export { render, staticRenderFns }