<template>
	<div class="page-wrapper">
		<migration-header :isMigration="true" :text="headerText"></migration-header>
		<div class="content-wrapper">
			<div class="text-wrapper">
				<h5 class="title" v-html="titleText"></h5>
				<h6 class="sub-title" v-html="tt('선택한 연구실을 랩매니저 PRO로 이전합니다.')"></h6>
				<!-- '•' -->
				<ul class="migration-info">
					<li class="migration-info-text">
						{{ tt('연구실이 이전되면 연구실에 소속된 모든 멤버는 랩매니저 PRO를 사용하게 됩니다.') }}
					</li>

					<li class="migration-info-text">
						{{ tt('랩매니저 PRO로 이전된 연구실 멤버는 랩매니저 구버전 접속이 불가합니다.') }}
					</li>
				</ul>
			</div>
			<migration-lab-selector :selectedLabId="selectedLabId" :labs="legacyLab" @selectedLab="getSelectedLab"> </migration-lab-selector>
			<migration-button-area @next="goToNext" @back="geToBack" :nextDisable="!isSelectAnyLab"></migration-button-area>
		</div>
	</div>
</template>

<script>
import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'
import { exproUrl } from '@/services/api/common'
import MixinMigration from '@/mixins/migration'
import MigrationHeader from '@/views/migration/components/MigrationHeader.vue'
import MigrationLabSelector from '@/views/migration/components/MigrationLabSelector.vue'
import MigrationButtonArea from '@/views/migration/components/MigrationButtonArea.vue'

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	components: {
		LmConfirmButtonMiddle,
		MigrationHeader,
		MigrationLabSelector,
		MigrationButtonArea,
	},
	mixins: [MixinMigration],
	// async created(){
	//     await
	// },
	data() {
		return {
			headerText: this.tt('이전할 연구실 선택'),
			titleText: this.tt('랩매니저 PRO로 이전할 연구실을 선택해주세요.'),
			content: this.tt('기존 랩매니저에 사용 데이터가 존재하는 계정의 경우<br>계정, 물품 정보 등 데이터 이전부터 수행하게 됩니다.'),
			selectedLabId: [],
		}
	},
	created() {
		this.loadLegacyLab()
		this.selectedLabId = [...this.selectedLab]
	},
	computed: {
		...mapState('migration', ['legacyLab', 'selectedLab']),
		isSelectAnyLab() {
			return this.selectedLabId.length > 0
		},
	},
	methods: {
		...mapMutations('migration', ['initSelectedLab', 'setSelectedLab']),
		...mapActions('migration', ['loadLegacyLab', 'initMigrationData']),
		goToExperience() {
			window.open(exproUrl, '_self')
		},
		getSelectedLab(selectedLab) {
			this.selectedLabId = [...selectedLab]
		},
		geToBack() {
			this.initMigrationData()
			this.routeMigrationMain()
		},
		goToNext() {
			if (this.isSelectAnyLab) {
				this.routeMigrationInputInstitutionName()
				this.setSelectedLab(this.selectedLabId)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.content-wrapper {
		display: flex;
		flex-direction: column;
		width: 700px;
		border-radius: 16px;
		padding: 40px 50px 30px 50px;
		background: white;
		box-shadow: 0px 1px 2px 0px #0000001a;

		.text-wrapper {
			margin-bottom: 40px;
			.title {
				font-size: 24px;
				font-weight: 700;
				line-height: 35px;
				letter-spacing: 0px;
				text-align: left;
			}

			.sub-title {
				margin-top: 10px;
				font-size: 14px;
				font-weight: 400;
				letter-spacing: 0px;
				text-align: left;
			}
			.migration-info {
				margin: 10px 0px;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0px;
				text-align: left;
				color: $DEFAULT;
				.migration-info-text {
					list-style-type: decimal;
					margin-left: 20px;
				}
			}
			.content {
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 26px;
				letter-spacing: 0px;
				text-align: center;
				margin-bottom: 10px;
			}

			.subtext {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0px;
				text-align: center;
				color: $SECONDARY_2;
			}
		}
		.button-wrapper {
			display: flex;
			height: 104px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 40px;
		}
	}
}
</style>
