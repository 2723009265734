var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "button-area-wrapper"
  }, [_c('div', {
    staticClass: "left-area"
  }), _c('div', {
    staticClass: "right-area"
  }, [_c('div', {
    staticClass: "cancel-button",
    on: {
      "click": _vm.handleClickBack
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.backButtonText)) + " ")]), _c('div', {
    staticClass: "lm-button-w150",
    "class": {
      disable: _vm.nextDisable
    },
    attrs: {
      "disabled": _vm.nextDisable
    },
    on: {
      "click": _vm.handleClickNext
    }
  }, [_c('span', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.nextButtonText)) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }