<template>
	<div class="label-wrap">
		<ghs-label-3130-a v-if="volume >= 500000"></ghs-label-3130-a>
		<ghs-label-3130-b v-else-if="volume < 500000 && volume >= 200000"></ghs-label-3130-b>
		<ghs-label-3120 v-else-if="volume < 200000 && volume >= 50000"></ghs-label-3120>
		<ghs-label-3118 v-else-if="volume < 50000 && volume >= 5000"></ghs-label-3118>
		<ghs-label-3114 v-else></ghs-label-3114>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import GhsLabel3130_A from './GHSLabel3130_A.vue'
import GhsLabel3130_B from './GHSLabel3130_B.vue'
import GhsLabel3120 from './GHSLabel3120.vue'
import GhsLabel3118 from './GHSLabel3118.vue'
import GhsLabel3114 from './GHSLabel3114.vue'
export default {
	name: 'GHSLabel',
	components: {
		'ghs-label-3130-a': GhsLabel3130_A,
		'ghs-label-3130-b': GhsLabel3130_B,
		'ghs-label-3120': GhsLabel3120,
		'ghs-label-3118': GhsLabel3118,
		'ghs-label-3114': GhsLabel3114,
	},
	props: {
		// g, ml 단위 기준
		volume: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('modal/ghs', ['previewData', 'pCodeText', 'hCodeText', 'provider', 'signalText', 'ghsPictogram']),
		qrSticker() {
			return i => this.previewData?.managementFields?.qrSticker[i]
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.label-wrap {
	width: fit-content;
	max-width: 960px;
	height: 560px;
	overflow: auto;
}
</style>
