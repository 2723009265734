import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'

const routes = [
	{
		name: PageName.Purchase.DraftList,
		path: RoutePath.Purchase.DraftList,
		props: true,
		component: () => import('@/views/purchase/draft/pages/DraftList.vue'),
	},
	{
		name: PageName.Purchase.DraftDetail,
		path: RoutePath.Purchase.DraftDetail,
		props: true,
		component: () => import('@/views/purchase/draft/pages/DraftDetail.vue'),
	},
	{
		name: PageName.Purchase.DraftRegistration,
		path: RoutePath.Purchase.DraftRegistration,
		props: true,
		component: () => import('@/views/purchase/draft/pages/DraftRegistration.vue'),
	},
]

export default routes
