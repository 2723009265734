var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "Popup__depth3"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Location__delete-location-popup Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), !_vm.hideClose ? _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('p', {
    staticClass: "Popup__plain-text",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.content))
    }
  })]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__delete-btn-group Popup__btn-group"
  }, [!_vm.hideCancel ? _c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.cancelText)) + " ")]) : _vm._e(), !_vm.hideConfirm ? _c('button', {
    staticClass: "Btn__confirm",
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.buttonText)) + " ")]) : _vm._e()])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }