<template>
	<div class="detail-wrapper">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ tt('예산 현황') }}</h2>
			</div>
		</div>
		<div class="status-btn-wrapper">
			<list-filter-tab
				v-for="status in yearStatus"
				:isActive="status === selectedYear"
				:text="tt('${1}년', status)"
				:key="status"
				@click="changeActiveStatus(status)"
			></list-filter-tab>
		</div>
		<div class="status-wrapper">
			<group-tree ref="group-tree" class="group-tree-wrapper" :isFloding="isFloding" @floding="isFloding = true" @change="changeGroup" />
			<div class="budget-wrapper" :class="{ floding: isFloding }">
				<budget ref="budget" class="budget-wrapper" :group="group" :year="selectedYear" :isFloding="isFloding" @unflod="isFloding = false" />
			</div>
		</div>
	</div>
</template>
<script>
import ListFilterTab from '@/views/common/components/buttons/ListFilterButton.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Budget from './budget.vue'
import GroupTree from '../components/status/GroupTree.vue'
export default {
	components: {
		ListFilterTab,
		GroupTree,
		Budget,
	},
	computed: {
		...mapGetters('budget/status', ['yearStatus', 'selectedYear', 'item']),
	},
	data() {
		return {
			group: null,
			isFloding: false,
		}
	},
	async created() {
		await this.getBudgetYears()
	},
	methods: {
		...mapActions('budget/status', ['getBudgetYears']),
		...mapMutations('budget/status', {
			setStatusLoadParams: 'setLoadParams',
		}),
		...mapMutations('budget/expenditure', {
			setExpenditureLoadParams: 'setLoadParams',
		}),
		changeActiveStatus(year) {
			this.setLoadParams({ year })
		},
		changeGroup(groups) {
			this.group = groups[0]
			this.setLoadParams({ groupsId: groups.map(g => g.id) })
		},
		async setLoadParams(payload) {
			this.setStatusLoadParams(payload)
			this.setExpenditureLoadParams(payload)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.status-btn-wrapper {
	display: flex;
	width: 100%;
	height: 54px;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid $DIVIDER;
	padding: 0px 30px;
}
.status-wrapper {
	display: flex;
	height: calc(100% - 114px);
	width: 100%;
	.group-tree-wrapper {
		flex-basis: 330px;
		height: 100%;
		border-right: 1px solid $DIVIDER;
	}
	.budget-wrapper {
		flex-basis: calc(100% - 330px);
		overflow-x: auto;
		&.floding {
			flex-basis: 100%;
		}
	}
}
</style>
