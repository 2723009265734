<template>
	<div class="detail-page-wrapper">
		<div class="header">
			<span class="subject">
				{{ item.subject }}
			</span>
		</div>
		<div class="detail-content-wrapper">
			<div>
				<h5>{{ tt('결재 정보') }}</h5>
				<div class="approval-info-wrapper">
					<approval-info-item
						v-if="item.register"
						:approvalTitle="'기안'"
						:name="item.register.name"
						:affiliation="item.group.name"
						:dateTime="item.createDatetime ? formatDatetime(item.createDatetime) : '-'"
					></approval-info-item>
					<div>
						<approval-info-item
							v-for="(approval, index) in item.approvals"
							:key="`approval_${index}`"
							:approvalTitle="'결재'"
							:approvalState="approval.status"
							:name="approval.user.name"
							:affiliation="approval.group.name"
							:dateTime="approval.concludeDatetime ? formatDatetime(approval.concludeDatetime) : '-'"
						></approval-info-item>
					</div>
				</div>
			</div>
			<div>
				<h5>{{ tt('기안 정보') }}</h5>
				<info-table :items="draftInfoForTable"></info-table>
			</div>
			<div>
				<h5>{{ tt('상품 정보') }}</h5>
				<product-info-table :productInfoList="item.purchaseItems"></product-info-table>
			</div>
			<div class="description-wrap">
				<div class="description">
					{{ `${tt('추후 정산시, 선택하는 상품항목에 따라 최종 결제금액이')} ${tt('지출 예정금액 합계와 상이할 수 있습니다.')}` }}
				</div>
			</div>
			<div>
				<div class="file-wrap">
					<h5>{{ tt('첨부 파일') }}</h5>
					<button class="file-all-down" @click="$refs['files-table'].downloadAll()">
						<img class="icon-down" src="@/views/purchase/cargo/svg/icon-file-download.svg" />
						<p>{{ tt('모두 다운로드') }}</p>
					</button>
				</div>
				<info-table ref="files-table" :items="attachmentsForTable"></info-table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DraftDetailHeader from '@/views/purchase/draft/components/detail/header.vue'
import ApprovalInfoItem from '@/views/purchase/draft/components/detail/ApprovalInfoItem.vue'
import InfoTable from '@/views/purchase/draft/components/detail/InfoTable.vue'
import ProductInfoTable from '@/views/purchase/draft/components/detail/ProductInfoTable.vue'
import TotalAmount from '@/views/purchase/draft/components/detail/TotalAmount.vue'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
import MixinExpenditureDetail from '@/mixins/purchase/expenditureDetail'

export default {
	name: 'DetailDraft',
	mixins: [MixinExpenditureDetail],
	props: {},
	components: {
		DraftDetailHeader,
		ApprovalInfoItem,
		InfoTable,
		ProductInfoTable,
		TotalAmount,
		ModalCommon,
	},
	created() {
		this.loadDetail()
	},
	mounted() {},
	data() {
		return {}
	},
	computed: {
		...mapState('draft/item', ['item']),
		...mapGetters('cargo/item', ['draftId']),
	},
	methods: {
		...mapActions('draft/item', ['getDetail']),
		async loadDetail() {
			await this.getDetail(this.draftId)
		},
	},
}
</script>

<style lang="scss" scoped>
.detail-page-wrapper {
	overflow-y: auto;
	.header {
		height: 50px;
		text-align: left;
		border-bottom: 1px solid $SECONDARY_1;
		.subject {
			font-size: 24px;
			font-weight: 700;
		}
	}
	.detail-content-wrapper {
		padding: 20px 0px;
		position: relative;
		text-align: left;
		> div {
			margin-bottom: 50px;
		}
		h5 {
			margin-bottom: 20px;
			font-weight: 700;
			font-size: 20px;
		}
		.approval-info-wrapper {
			display: flex;
			align-items: center;
			padding: 0px;
			gap: 20px;
			width: 100%;
			margin-top: 15px;
			& > div {
				&:nth-child(2) {
					display: flex;
					gap: 20px;
					padding-left: 20px;
					border-left: 1px solid $INPUT;
				}
			}
		}
	}
}
::v-deep .value.lg {
	font-size: 20px !important;
	font-weight: 700 !important;
}

.description-wrap {
	margin-top: -35px;
	text-align: center;
	margin-bottom: 25px !important;
	.description {
		color: $SECONDARY_2;
		font-size: 16px;
		font-weight: 400;
	}
}

.file-wrap {
	display: flex;
	justify-content: space-between;
	margin-bottom: -25px;
	.file-all-down {
		background: $GREY_4;
		display: flex;
		border-radius: 4px;
		padding: 5px 10px;
		height: 30px;
		font-size: 14px;
		font-weight: 700;
		&:hover {
			background: $HOVER_BUTTON_COMMON;
		}
		.icon-down {
		}
	}
}
</style>
