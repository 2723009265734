var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SafetyDangerStorage"
  }, [_c('div', {
    staticClass: "title-wrap",
    staticStyle: {
      "height": "55px"
    }
  }, [_c('h2', {
    staticClass: "AppContainer__title",
    staticStyle: {
      "padding-top": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('위험물 지정수량 배수')) + " ")]), _c('div', {
    staticClass: "switch-box"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('재고 있는 품목만 보기')))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    ref: "showOnlyExist",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.showOnlyExist
    },
    on: {
      "change": _vm.changeExist
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _vm.dangerStorageList.length > 0 ? _c('div', {
    staticClass: "AppContainer__tabs"
  }, [_c('div', {
    staticClass: "tab-inner"
  }, _vm._l(_vm.dangerStorageList, function (item) {
    return _c('span', {
      key: item.id
    }, [_c('input', {
      attrs: {
        "type": "radio",
        "name": "safetyDangerousArea",
        "id": 'area' + item.id
      },
      domProps: {
        "checked": item.id == _vm.tab
      },
      on: {
        "click": function click($event) {
          return _vm.selectTab(item.id);
        }
      }
    }), _c('label', {
      attrs: {
        "for": 'area' + item.id
      }
    }, [item.status === 'SAFE' ? _c('img', {
      attrs: {
        "src": require("@/assets/svg/icon-safe.svg")
      }
    }) : _c('img', {
      attrs: {
        "src": require("@/assets/svg/icon-error.svg")
      }
    }), _vm._v(" " + _vm._s(item.name) + " "), item.isNew ? _c('img', {
      staticStyle: {
        "margin-left": "4px",
        "width": "14px",
        "height": "14px"
      },
      attrs: {
        "src": require("@/assets/svg/new.svg")
      }
    }) : _vm._e()])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "AppContainer"
  }, [_c('div', {
    staticClass: "Filter__box"
  }, [_c('search-box', {
    ref: "search-box",
    staticClass: "search-box",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "noRecent": "",
      "placeholder": _vm.tt('검색'),
      "value": _vm.keyword
    },
    on: {
      "change": _vm.changeKeyword
    }
  }), _c('div', {
    staticClass: "right-btn-group"
  }, [_vm.userPermission.safe && _vm.userPermission.safe.dangerstorage.create ? _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.showManagement
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/authority-setting.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('저장소 관리')) + " ")]) : _vm._e(), _vm.userPermission.safe && _vm.userPermission.safe.dangerstorage.create ? _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.showModalSetting(_vm.tab);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/authority-setting.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('지정수량 배수 설정')) + " ")]) : _vm._e(), _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.downloadExcel
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-excel.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('위험물 현황 다운로드')) + " ")])])], 1), _c('div', {
    ref: "tables",
    staticClass: "Table__wrap scroll horizational-scroll",
    "class": {
      experience: _vm.isAppModeExperience
    }
  }, [_c('table', [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "150"
    }
  }, [_vm._v(_vm._s(_vm.tt('유별')))]), _c('th', {
    attrs: {
      "width": "180"
    }
  }, [_vm._v(_vm._s(_vm.tt('품명')))]), _c('th', {
    attrs: {
      "width": "150"
    }
  }, [_vm._v(_vm._s(_vm.tt('재고량')))]), _c('th', {
    attrs: {
      "width": "100"
    }
  }, [_vm._v(_vm._s(_vm.tt('최대 저장량')))]), _c('th', {
    attrs: {
      "width": "100"
    }
  }, [_vm._v(_vm._s(_vm.tt('배수')))]), _c('th', {
    attrs: {
      "width": "560"
    }
  }, [_vm._v(_vm._s(_vm.tt('그래프')))])])]), _vm.statusList.length > 0 ? _c('tbody', [_vm._l(_vm.statusList, function (item, idx) {
    return _vm._l(item.childLegalList, function (child, index) {
      return _c('tr', {
        key: child.legalId,
        ref: child.legalId,
        refInFor: true,
        "class": {
          active: _vm.activeTr === child.legalId && _vm.tab == _vm.multipleId
        }
      }, [index == 0 ? _c('td', {
        staticClass: "category",
        attrs: {
          "rowspan": item.childLegalList.length
        }
      }, [_vm._v(" " + _vm._s(_vm.tt(item.legalName)) + " ")]) : _vm._e(), _c('td', {
        staticClass: "move-page",
        on: {
          "click": function click($event) {
            return _vm.goInventory(item.legalName, child);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.tt(child.legalName)) + " ")]), _c('td', [_c('p', {
        staticClass: "stock",
        "class": _vm.comparisionAmount(child.stockVolume, child.limitVolume) > 1 ? 'safe' : 'error'
      }, [_vm._v(" " + _vm._s(_vm.commaVolume(child.stockVolume)) + _vm._s(child.volumeUnit) + " "), _vm.comparisionAmount(child.stockVolume, child.limitVolume) < 1 ? _c('span', [_vm._v(_vm._s(_vm.tt('저장량 초과')))]) : _vm._e()])]), _c('td', [_c('p', {
        staticClass: "store"
      }, [_vm._v(" " + _vm._s(_vm.commaVolume(child.limitVolume)) + " " + _vm._s(child.volumeUnit) + " ")])]), _c('td', [_c('button', {
        staticClass: "Btn__hover-balloon-w",
        "class": {
          notClick: _vm.userPermission.safe && !_vm.userPermission.safe.dangerstorage.create
        },
        on: {
          "click": function click($event) {
            _vm.userPermission.safe && _vm.userPermission.safe.dangerstorage.create ? _vm.showModalSetting(_vm.tab, item.legalId, child) : '';
          }
        }
      }, [_c('span', [_vm._v(_vm._s(child.multiple))]), _c('div', {
        staticClass: "balloon"
      }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('클릭해서 지정수량 배수를 관리할 수 있습니다')) + " ")])])])]), _c('td', [_c('div', {
        staticClass: "lm-progress-bar-wrap"
      }, [_c('div', {
        ref: "lm-progress-bar" + idx + index,
        refInFor: true,
        staticClass: "lm-progress-bar"
      }, [_c('div', {
        ref: "stock-tooltip" + idx + index,
        refInFor: true,
        staticClass: "stock-tooltip",
        "class": _vm.comparisionAmount(child.stockVolume, child.limitVolume) > 1 ? 'safe' : 'error'
      }, [_vm._v(" " + _vm._s(_vm.tt('재고량')) + ": " + _vm._s(_vm.commaVolume(child.stockVolume)) + _vm._s(child.volumeUnit) + " "), _c('i', {
        ref: "stock-arrow" + idx + index,
        refInFor: true,
        staticClass: "stock-arrow"
      })]), _c('div', {
        ref: "available" + idx + index,
        refInFor: true,
        staticClass: "available"
      }), _c('div', {
        ref: "non-available" + idx + index,
        refInFor: true,
        staticClass: "non-available",
        "class": _vm.comparisionAmount(child.stockVolume, child.limitVolume) > 1 ? 'hide' : ''
      }), _c('div', {
        ref: "store-notice" + idx + index,
        refInFor: true,
        staticClass: "store-notice"
      }, [_c('i', {
        ref: "notice-arrow" + idx + index,
        refInFor: true
      }), _c('span', [_vm._v(_vm._s(_vm.tt('최대 저장량')) + ": " + _vm._s(_vm.commaVolume(child.limitVolume)) + _vm._s(child.volumeUnit))])])])])])]);
    });
  })], 2) : _vm._e()]), _vm.isLoading ? _c('lm-modal-loading', {
    attrs: {
      "play": _vm.isLoading,
      "height": "calc(100% - 40px)"
    }
  }) : _vm.statusList.length == 0 && !_vm.isLoading ? _c('no-data-list', {
    attrs: {
      "image": _vm.noData.image,
      "title": _vm.noData.title,
      "content": _vm.noData.content
    }
  }) : _vm._e()], 1)]), _c('modal-quantity-multiple-setting', {
    ref: "modal-quantity-multiple-setting",
    attrs: {
      "storageList": _vm.dangerStorageList
    },
    on: {
      "hide": _vm.hideSetting
    }
  }), _c('modal-management-storage', {
    ref: "modal-management-storage",
    on: {
      "loadList": _vm.loadStorage,
      "loadSummary": _vm.loadSummary
    }
  }), _c('ModalConfirm', {
    ref: "modalDownload",
    attrs: {
      "title": "관리대장 다운로드",
      "content": "\uB2E4\uC74C\uC758 \uC774\uBA54\uC77C \uC8FC\uC18C\uB85C \uAD00\uB9AC\uB300\uC7A5 \uB2E4\uC6B4\uB85C\uB4DC \uB9C1\uD06C\uB97C \uC804\uC1A1\uD569\uB2C8\uB2E4.<br/>\uC774\uBA54\uC77C \uC8FC\uC18C: ".concat(_vm.$root.user.email, "<br/><br/>\uD30C\uC77C \uD06C\uAE30\uC5D0 \uB530\uB77C \uC774\uBA54\uC77C \uC218\uC2E0\uC740 \uCD5C\uB300 30\uBD84 \uC18C\uC694\uB420 \uC218 \uC788\uC2B5\uB2C8\uB2E4."),
      "confirmText": "전송"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }