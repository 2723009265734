<template>
	<tr class="table-header">
		<th :class="orderClass('CODE')" @click.stop="changeOrder('CODE')">
			<span>{{ tt('예산 번호') }}</span>
		</th>
		<th :class="orderClass('NAME')" @click.stop="changeOrder('NAME')">
			<span>{{ tt('예산 이름') }}</span>
		</th>
		<th v-if="isView('JoinedGroupRepName')">
			<span>{{ tt('참여 그룹') }}</span>
		</th>
		<th :class="orderClass('BUDGET')" @click.stop="changeOrder('BUDGET')" v-if="isView('budget')">
			<span>{{ tt('총 예산 합계') }}</span>
		</th>
		<th :class="orderClass('EXPENDITURE')" @click.stop="changeOrder('EXPENDITURE')" v-if="isView('expenditure')">
			<span>{{ tt('총 지출 합계') }}</span>
		</th>
		<th :class="orderClass('BALANCE')" @click.stop="changeOrder('BALANCE')" v-if="isView('balance')">
			<span>{{ tt('총 잔액 합계') }}</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')">
			<span>{{ tt('등록일시') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapState('budget/list', ['checkedViewOption']),
		...mapGetters('budget/list', ['order', 'orderDirection']),
	},
	mixins: [MixinHeader],
	methods: {
		...mapActions('budget/list', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
