<template>
	<div class="Modal ModalNoticeLicense" v-if="isShow">
		<div class="Popup__background"></div>
		<div class="Modal__container">
			<div class="Modal__content">
				<h2 v-html="tt('프로 라이선스에서<br>사용할 수 있는 기능입니다')"></h2>
				<p>
					{{ tt('라이선스를 업그레이드하고 더 다양한 기능을 만나보세요.') }}
				</p>
				<div class="imgbox">
					<img src="@/assets/images/notice-license.png" />
				</div>
				<div class="btn-wrap">
					<button class="Btn__yellow" @click="goPrice">
						{{ tt('어떤 기능이 있나요?') }}
					</button>
					<button class="Btn__common" @click="hide">
						{{ tt('닫기') }}
					</button>
				</div>
				<label>
					<input type="checkbox" />
					{{ tt('다시보지 않기') }}
				</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isShow: false,
		}
	},
	methods: {
		show() {
			this.isShow = true
		},
		hide() {
			this.isShow = false
		},
		goPrice() {
			location.href = 'https://www.lab-manager.com/price'
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalNoticeLicense {
	.Modal__content {
		width: 400px;
		padding: 50px;
		box-sizing: initial;
		h2 {
			font-size: 1.5rem;
			font-weight: 700;
		}
		p {
			font-size: 0.875rem;
			font-weight: 400;
			margin-top: 10px;
		}
		.imgbox {
			margin: 48px auto;
			width: 100%;
			img {
				width: 100%;
			}
		}
		.btn-wrap {
			width: 100%;
			text-align: center;
			button {
				width: 100%;
				font-size: 1rem;
				font-weight: 700;
				margin-bottom: 10px;
				height: 48px;
			}
			.Btn__yellow {
				box-shadow: unset;
			}
		}
		label {
			width: 100;
			display: flex;
			align-items: center;
			height: auto;
			font-size: 0.875rem;
			font-weight: 400;
			input {
				margin-right: 10px;
			}
		}
	}
}
</style>
