var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "AddStorageBoxPopup"
  }, [_c('div', {
    staticClass: "Popup__background"
  }, [_c('div', {
    staticClass: "StorageBox-add-popup Popup__container Popup__size-md"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "StorageBox-add-group"
  }, [_c('h6', {
    staticClass: "Popup__content-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.tt('보관함 정보')) + " ")]), _c('ul', {
    staticClass: "Popup__form form-top"
  }, [_c('li', {
    staticClass: "Popup__input-field",
    "class": {
      error: !_vm.valid.name
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('보관함 이름'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.tt(_vm.placeholders.name)
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": _vm.inputName
    }
  }), _c('span', {
    staticClass: "noti"
  }, [_vm._v(_vm._s(_vm.tt(_vm.placeholders.name)))])]), _c('li', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('유형')))]), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('유형을 입력해주세요')
    },
    domProps: {
      "value": _vm.form.type
    },
    on: {
      "input": _vm.inputType
    }
  })])])]), _c('div', {
    staticClass: "StorageBox-add-info"
  }, [_c('h6', {
    staticClass: "Popup__content-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.tt('관리 정보')) + " ")]), _c('ul', {
    staticClass: "Popup__form"
  }, [_c('li', {
    staticClass: "Popup__select-field top",
    "class": {
      error: !_vm.valid.locationId
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('위치'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "Popup__input-field select-location-wrapper"
  }, [_c('div', {
    staticClass: "select-location"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.locationPathname,
      expression: "locationPathname"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt(_vm.placeholders.location),
      "readonly": ""
    },
    domProps: {
      "value": _vm.locationPathname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.locationPathname = $event.target.value;
      }
    }
  })]), _c('button', {
    staticClass: "lm-button-common",
    on: {
      "click": _vm.selectLocation
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('선택')) + " ")])]), _c('span', {
    staticClass: "noti"
  }, [_vm._v(_vm._s(_vm.placeholders.location))])]), _c('li', {
    staticClass: "Popup__select-field top",
    "class": {
      error: !_vm.valid.assigneeGroupId
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('담당 그룹')) + " "), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "Popup__input-field Dropdown-input",
    staticStyle: {
      "height": "auto",
      "align-items": "center"
    }
  }, [_c('div', {
    on: {
      "click": function click($event) {
        return _vm.openModalSelectGroup(_vm.form.assigneeGroupId);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.assigneeGroup.name,
      expression: "form.assigneeGroup.name"
    }],
    staticClass: "pointer",
    attrs: {
      "type": "text",
      "placeholder": _vm.tt(_vm.placeholders.group),
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.assigneeGroup.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form.assigneeGroup, "name", $event.target.value);
      }
    }
  })])]), _c('span', {
    staticClass: "noti"
  }, [_vm._v(_vm._s(_vm.placeholders.group))])]), _c('li', {
    staticClass: "Popup__select-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('정관리자')))]), _c('div', {
    staticClass: "Popup__input-field Dropdown-input",
    on: {
      "click": _vm.openModalSelectAssignee
    }
  }, [_c('input', {
    staticClass: "pointer",
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('정관리자를 선택해주세요'),
      "readonly": ""
    },
    domProps: {
      "value": _vm.assignee && _vm.assignee.name
    }
  })])]), _c('li', {
    staticClass: "Popup__select-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('부관리자')))]), _c('div', {
    staticClass: "Popup__input-field Dropdown-input",
    on: {
      "click": _vm.openModalSelectSubassignee
    }
  }, [_c('input', {
    staticClass: "pointer",
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('부관리자를 선택해주세요'),
      "readonly": ""
    },
    domProps: {
      "value": _vm.subAssignee && _vm.subAssignee.name
    }
  })])])])]), _c('div', {
    staticClass: "StorageBox-add-warning"
  }, [_c('h6', {
    staticClass: "Popup__content-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.tt('경고 표지')) + " ")]), _vm.warningSigns ? _c('ul', {
    staticClass: "StorageBox__warning-list"
  }, _vm._l(_vm.warningSigns, function (warningSign) {
    return _c('li', {
      key: warningSign.id,
      staticClass: "StorageBox__warning-item"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.form.warningSignsId,
        expression: "form.warningSignsId"
      }],
      attrs: {
        "id": warningSign.id,
        "type": "checkbox"
      },
      domProps: {
        "value": warningSign.id,
        "checked": Array.isArray(_vm.form.warningSignsId) ? _vm._i(_vm.form.warningSignsId, warningSign.id) > -1 : _vm.form.warningSignsId
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.form.warningSignsId,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = warningSign.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.form, "warningSignsId", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.form, "warningSignsId", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.form, "warningSignsId", $$c);
          }
        }
      }
    }), _c('label', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: _vm.tt(warningSign.nameKo ? warningSign.nameKo : warningSign.nameEn),
        expression: "tt(warningSign.nameKo ? warningSign.nameKo : warningSign.nameEn)",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "for": warningSign.id
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(warningSign.nameKo ? warningSign.nameKo : warningSign.nameEn)) + " ")]), !warningSign.link ? _c('img', {
      attrs: {
        "src": require("@/assets/svg/member-photo-default.svg")
      }
    }) : _vm._e(), _c('img', {
      attrs: {
        "src": warningSign.link
      }
    })]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])])])]), _c('modal-select-member', {
    ref: "modal-select-member",
    on: {
      "selectMember": _vm.selectMember
    }
  }), _c('modal-select-group', {
    ref: "modal-select-group",
    attrs: {
      "title": "담당 그룹 선택",
      "isInclude": false,
      "isSelectSingle": true,
      "defaultData": _vm.groups
    },
    on: {
      "confirm": _vm.completeSelectedGroup
    }
  }), _c('modal-select-location', {
    attrs: {
      "isShow": _vm.showModalSelectLocation,
      "selectedLocation": _vm.locationInfo
    },
    on: {
      "close": function close($event) {
        _vm.showModalSelectLocation = false;
      },
      "complete": _vm.completeSelectLocation
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }