<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper" @click.stop="toggleShowOption">
			<div class="form-select-box" ref="select-box">
				<div class="select-box">
					<b-dropdown toggle-class="text-decoration-none" variant="link" right>
						<template #button-content>
							<p :class="{ placeholder: !inputValue }">{{ selectOptionName }}</p>
						</template>

						<b-dropdown-item @click.stop="clickOption(option)" :key="`option-${option.id}`" v-for="option in optionList">
							<div class="member-wrap">
								<img v-if="option.image" class="member-img" :src="option.image" />
								<img v-else class="member-img" src="@/assets/svg/member-photo-default.svg" />
								<div class="member-name">{{ option.name }}</div>
							</div>
						</b-dropdown-item>
					</b-dropdown>
				</div>
				<!-- <p class="select-item-text" :class="{ placeholder: !inputValue }">{{ tt(`${selectOptionName}`) }}</p> -->
				<!-- <div ref="form-option-list" class="form-option-list" v-if="isShowOption">
					<ul class="option-list">
						<li class="option-member" @click.stop="clickOption(option)" :key="`option-${option.id}`" v-for="option in optionList">
							<img v-if="option.image" class="member-img" :src="option.image" />
							<img v-else class="member-img" src="@/assets/svg/member-photo-default.svg" />
							<div>{{ option.name }}</div>
						</li>
					</ul>
				</div> -->
			</div>
		</div>
		<span class="error" v-if="error">{{ errorMessage }}</span>
	</div>
</template>
<script>
import MixinFormSelect from '@/mixins/form/formSelect'
export default {
	name: 'FormSelectMember',
	components: {},
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			default: null,
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		optionList: {
			type: Array,
			default: null,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [MixinFormSelect],
	data() {
		return {
			isShowOption: false,
			inputValue: null,
		}
	},
	computed: {
		selectOptionName() {
			return this.inputValue ? this.inputValue.name : this.placeholder
		},
		error() {
			return this.inputValue === null && this.isShowError && this.required
		},
		cookiesKey() {
			return 'SELECT_MEMBER_KEY'
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		toggleShowOption(e) {
			let beforeState = this.isShowOption
			if (!this.optionList || this.optionList.length <= 0) return
			document.body.click()
			this.isShowOption = !beforeState
		},
		clickOption(item) {
			this.inputValue = item
			this.$cookies.set(this.cookiesKey, this.inputValue)
			this.$emit('input', item)
			this.isShowOption = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-select.scss';
.form-select-box {
	padding: 0px !important;
	.select-box {
		height: 100% !important;
		.dropdown {
			width: 100%;
			height: 100%;
		}
		::v-deep {
			button {
				background: white;
				height: calc(100% - 1px);
				justify-content: flex-start;
				&:focus {
					box-shadow: none;
				}
				p {
					width: 100%;
					text-align: left;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}
	}
}

::v-deep {
	.dropdown-menu {
		width: 300px;
		max-height: 240px;
		overflow-y: scroll;
	}
	li {
		margin: 5px;
		.dropdown-item {
			padding: 5px;
			border-radius: 4px;
			&:active {
				color: $DEFAULT;
				background: #e9ecef;
			}
			&:hover {
				background: $SUB-YELLOW_100;
			}
		}
	}
	.btn-link {
		&:hover {
			color: $DEFAULT;
		}
	}

	.btn-group > .btn {
		border: 0px !important;
	}
}
p.placeholder {
	color: $PLACEHOLDER;
	font-size: 12px !important;
}
.member-wrap {
	display: flex;
	font-size: 14px;
	.member-name {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
}
</style>
