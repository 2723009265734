<template>
	<div class="payment-requirement-authentication">
		<div
			class="content"
			v-html="
				tt(
					'화학물질관리법 준수사항으로 [랩매니저 스토어]는 유해화학물질 <br />통신 판매 시 구매자의 실명, 연령 확인 및 본인인증을 진행합니다'
				)
			"
		></div>
		<div v-if="!isVerified">
			<div class="authentication">
				<button class="btn btn-white lg" :class="!isValid ? 'invalid' : null" @click="onAuthentication">{{ tt('휴대폰 본인인증') }}</button>
			</div>
		</div>
		<div v-else>
			<div class="authentication">
				<div class="complete">
					<div class="text">{{ tt('본인인증 완료') }}</div>
					<img src="@/assets/svg/payment/requirement/check_white.svg" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
const { IMP } = window

export default {
	name: 'PaymentRequirementAuthentication',
	data() {
		return {
			isValid: true,
		}
	},
	computed: {
		...mapState('lms/user', ['user']),
		isVerified() {
			return this.user?.isVerified
		},
	},
	created() {
		this.fetchUserInfo()
	},
	methods: {
		...mapActions('lms/user', ['getUserInfo', 'verification']),
		async fetchUserInfo() {
			await this.getUserInfo()
		},
		onAuthentication() {
			const data = {
				merchant_uid: `merchant_${new Date().getTime()}`,
				min_age: 14,
			}

			IMP.init('imp45147695')
			IMP.certification(data, this.callback)
		},
		callback(response) {
			const { success, imp_uid, error_msg } = response
			if (success) {
				this.authentication(imp_uid)
			} else {
				this.$root.toast('에러', error_msg, ToastType.ERROR)
			}
		},
		async authentication(imp_uid) {
			try {
				await this.verification({
					impUid: imp_uid,
				})
				this.$root.toast('본인인증 완료', '본인인증이 완료되었습니다', ToastType.SUCCESS)
				await this.fetchUserInfo()
				this.$emit('next')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		validate() {
			this.isValid = true
			if (!this.isVerified) this.isValid = false
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-requirement-authentication {
	.content {
		font-size: 0.875rem;
	}
	.authentication {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;
		button {
			width: 400px;
			height: 48px;
			font-size: 0.875rem;
			font-weight: 700;

			&.invalid {
				border: 1px solid $RED;
				color: $RED;
			}
		}
		&.invalid {
			border: 1px solid $RED;
		}
		.complete {
			width: 400px;
			height: 48px;
			background-color: $GREEN;
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			.text {
				font-size: 0.875rem;
				font-weight: 700;
				color: $LAB_WHITE;
			}
			img {
				margin-left: 10px;
			}
		}
	}
}
</style>
