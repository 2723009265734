const isClickedInstitution = {
	organization: [],
	clickedorganization: [],
	location: [],
	clickedlocation: [],
	storage: [],
	member: [],
	permission: [],
}

const isClickedInventory = {
	inventory: {
		chemical: [],
		chromatography: [],
		labware: [],
		protection: [],
		etc: [],
	},
	history: [],
	disposal: [],
}

const isClickedSafety = {
	msds: [],
	dangerstorage: [],
	harmfulchemical: [],
	management: [],
	checkup: [],
	legal: [],
}

export { isClickedInstitution, isClickedInventory, isClickedSafety }
