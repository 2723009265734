<template>
	<table class="lm-table" ref="inventory-table">
		<table-header />
		<table-item v-for="item in list" :key="item.id" :item="item" @showSelectStorageModal="showChangeStorageModal" @click="goToDetail" />
		<div class="empty-table" v-if="count === 0">
			<img class="empty-img" :src="emptyImage" />
			<p class="bold">{{ tt(emptyText) }}</p>
			<p class="sub" v-html="emptySubText"></p>
		</div>
		<modal-single-select-storage title="위치 및 보관함 선택" ref="modal-select-storage" @complete="changeStorage"></modal-single-select-storage>
	</table>
</template>
<script>
import PageName from '@/utils/define/PageName'
import TableHeader from './header.vue'
import TableItem from './item.vue'
import ModalSingleSelectStorage from '@/views/common/components/modalGen2/ModalSingleSelectStorage.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
	components: {
		TableHeader,
		TableItem,
		ModalSingleSelectStorage,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		previousPage: {
			type: Object,
			default: () => {},
		},
	},
	mounted() {
		if (this.previousPage?.name === PageName.InventoryGen2.Detail || this.previousPage?.name == PageName.Purchase.CargoDetail)
			this.$refs['inventory-table'].scrollTo(0, this.scrollHeight)
	},
	computed: {
		...mapState('inventoryGen2/history', ['count', 'loadParams']),
		...mapState('scroll', ['scrollHeight']),
		emptyImage() {
			return this.loadParams.keyword ? require('@/assets/svg/no-data_search.svg') : require('@/assets/svg/empty/inventory-empty-data.svg')
		},
		emptyText() {
			return this.tt(this.loadParams.keyword ? '검색 결과가 없습니다' : '물품 관리기록이 없습니다')
		},
		emptySubText() {
			return this.loadParams.keyword ? this.tt('입력자, 물품 이름으로 검색할 수 있습니다.') : ''
		},
	},
	data() {
		return {
			selectedId: null,
		}
	},
	methods: {
		...mapMutations('scroll', ['setScrollHeight']),
		...mapActions('inventoryGen2/history', ['changeItemStorage']),
		showChangeStorageModal(id) {
			this.selectedId = id
			this.$refs['modal-select-storage'].show()
		},
		async changeStorage(selectStorage) {
			if (selectStorage === null) return
			await this.changeItemStorage({
				itemsId: this.selectedId,
				storageId: selectStorage.id,
			})
			this.$root.toast(this.tt('물품 복구 완료'), this.tt('물품이 복구되었습니다.'), 'success')
		},
		moveScrollTop() {
			this.setScrollHeight(0)
			this.$refs['inventory-table'].scrollTo(0, this.scrollHeight)
		},
		goToDetail(id) {
			const { scrollTop } = this.$refs['inventory-table']
			this.setScrollHeight(scrollTop)
			this.$router.push({
				name: 'InventoryDetailGen2',
				params: {
					id: id,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.lm-table {
	position: relative;
	height: 100%;
	text-align: left;
	display: block;
	overflow: overlay;
	white-space: nowrap;
	border-spacing: 0;
	border-collapse: separate;
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: $SECONDARY_2;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		text-align: center;
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
