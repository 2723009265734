<template>
	<div class="detail-header" v-if="item">
		<div class="title-wrap">
			<h2 class="title">{{ tt(title) }}</h2>
			<p class="create-time">{{ createDatetime }}</p>
		</div>
		<div class="button-wrap">
			<div class="left">
				<button class="button-gray" @click="inputDirect">{{ tt('수기 등록') }}</button>
				<button class="button-gray" v-if="isVisibleSecondBtn" @click="clickSecondBtn">{{ tt(secondBtnText) }}</button>
			</div>
			<div class="right">
				<button class="button-red" @click="$refs['modal-delete'].show()">{{ tt('삭제') }}</button>
			</div>
		</div>
		<modal-confirm
			ref="modal-delete"
			:title="`${title} 삭제`"
			:content="`${title}을 삭제하시겠습니까?`"
			variant="warning"
			@confirm="deleteWaitingItem"
		></modal-confirm>
		<modal-camera ref="modal-camera" :title="tt('추가 촬영')" :isPrev="false" :max="cameraMax" nextBtnText="완료" @next="addImage"></modal-camera>
		<modal-search-product
			ref="modal-search-product"
			title="등록할 물품 검색"
			:categoryInfo="categoryInfo"
			@selectItem="selectProduct"
			@cancel="$refs['modal-search-product'].hide()"
			@inputDirect="inputDirectAtSearchProduct"
			forRegistItem
		></modal-search-product>
		<modal-item-product
			ref="modal-product"
			title="물품정보 등록"
			@cancel="initItem()"
			@complete="$refs['modal-management'].show()"
		></modal-item-product>
		<modal-item-management ref="modal-management" title="관리정보 등록" @goPrev="$refs['modal-product'].show()"></modal-item-management>
	</div>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import PageName from '@/utils/define/PageName'
import ModalCamera from '@/views/inventoryGen2/components/regist/modal/ModalCamera.vue'
import ModalItemProduct from '@/views/inventoryGen2/components/regist/modal/ModalItemProduct.vue'
import ModalItemManagement from '@/views/inventoryGen2/components/regist/modal/ModalItemManagement.vue'
import ModalSearchProduct from '@/views/common/components/modalGen2/ModalSearchProduct.vue'
import { base64toFile } from '@/utils/modules/FileUtils'

export default {
	name: 'DetailHeader',
	components: { ModalCamera, ModalItemProduct, ModalItemManagement, ModalSearchProduct },
	mixins: [MixinItem],
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/item', ['categoryInfo']),
		title() {
			return this.item?.status === 'ING' ? '분석 중인 물품' : '분석 불가능한 물품'
		},
		createDatetime() {
			return this.formatDatetimeFromISO(this.item?.createDatetime, 'yyyy-MM-dd HH:mm:ss')
		},
		secondBtnText() {
			return this.item?.registerMethod === 'AUTO_IMAGE' ? '추가 촬영' : '재업로드'
		},
		isVisibleSecondBtn() {
			return !(this.item?.registerMethod === 'AUTO_IMAGE' && this.item?.imageList?.length >= 10)
		},
		cameraMax() {
			return 10 - this.item?.imageList?.length
		},
		customFieldObject() {
			let obj = {}
			this.item?.customField?.forEach(item => {
				obj[item.key] = item.value
			})
			return obj
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.setCategoryInfo(this.categoryInfo)
	},
	methods: {
		...mapActions('inventoryGen2/item', ['deleteInspectItem', 'addInspectItemImage', 'getWaitingDetail']),
		...mapMutations('inventoryGen2/form', [
			'setFormItem',
			'setItemCustomFields',
			'setCategory',
			'setCategoryInfo',
			'initItem',
			'setItemFormType',
			'setInspectId',
		]),
		...mapActions('inventoryGen2/form', ['getMsds']),
		async deleteWaitingItem() {
			await this.deleteInspectItem([this.item.id])
			this.$root.toast('물품 삭제', `${this.title}이 삭제되었습니다.`, ToastType.SUCCESS)
			this.$router.replace({
				name: PageName.InventoryGen2.WaitList,
			})
		},
		clickSecondBtn() {
			this.$refs['modal-camera'].show()
		},
		async addImage(urls) {
			let formData = new FormData()
			formData.append('inspectId', this.item.id)
			formData.append('assigneeId', this.item.assignee.id)
			urls.forEach(url => formData.append('images', base64toFile(url, `image${new Date().getUTCMilliseconds()}.jpg`)))
			await this.addInspectItemImage(formData)
			this.$root.toast('사진 추가 완료', '물품사진을 추가하였습니다', ToastType.SUCCESS)
			await this.getWaitingDetail(this.item.id)
			this.$refs['modal-camera'].initData()
		},
		inputDirect() {
			this.setInspectId(this.item.id)
			this.setCategory(this.item?.category)
			this.setCategoryInfo(this.categoryInfo)
			this.setItemFormType('INSPECT_REGIST')
			this.$refs['modal-search-product'].show()
		},
		async selectProduct(item) {
			let newItem = {}
			newItem = Object.assign(newItem, this.item)
			newItem = Object.assign(newItem, item)

			this.setFormItem(newItem)
			this.setItemCustomFields(this.customFieldObject)
			await this.getMsds({
				brandId: newItem.brandId,
				productCode: newItem.productCode,
			})
			this.$refs['modal-product'].show()
		},
		inputDirectAtSearchProduct() {
			this.setFormItem(this.item)
			this.setItemCustomFields(this.customFieldObject)
			this.$refs['modal-product'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.detail-header {
	display: flex;
	padding: 16px 30px;
	text-align: left;
	justify-content: space-between;
	border-bottom: 1px solid $DIVIDER;

	.title-wrap {
		display: grid;
		gap: 6px;
		.title {
			font-size: 24px;
			font-weight: 700;
		}
		.create-time {
			font-size: 12px;
			font-weight: 400;
			color: $SECONDARY_2;
		}
	}
	.button-wrap {
		display: flex;
		align-items: center;
		.left + .right {
			align-items: center;
			&::before {
				content: '';
				margin-left: 20px;
				margin-right: 20px;
				height: 24px;
				border-right: 1px solid $DIVIDER;
			}
		}
		.left {
			display: flex;
			gap: 10px;
		}

		.right {
			display: flex;
		}
	}
}

.button-gray {
	justify-content: center;
	background: $GREY_4;
	display: flex;
	border-radius: 5px;
	padding: 5px 10px;
	min-height: 36px;
	font-size: 14px;
	font-weight: 700;
	min-width: 100px;
	&:hover {
		background: $HOVER_BUTTON_COMMON;
	}
}

.button-red {
	min-width: 100px;
	font-size: 14px;
	font-weight: 700;
	display: flex;
	border-radius: 5px;
	padding: 5px 10px;
	min-height: 36px;
	justify-content: center;
	background: linear-gradient(0deg, $SUB-RED, $SUB-RED), $LAB_WHITE;
	color: $RED;

	&:hover {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(0deg, $SUB-RED, $SUB-RED);
	}
}
</style>
