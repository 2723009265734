import axiosInstance from '@/services/axios'
import categoryAPI from '../../category'

const endPoint = {
	getCargoList: instId => `/institute/${instId}/cargo/list`,
	registCargo: instId => `/institute/${instId}/cargo`,
	divideCargoItem: instId => `/institute/${instId}/cargo/subdivide`,
	getCargoDetail: instId => `/institute/${instId}/cargo`,
	deleteCargoItem: instId => `/institute/${instId}/cargo`,
	processCargoItem: instId => `/institute/${instId}/cargo/inventory/register`,
	getFilter: instId => `/institute/${instId}/cargo/list/filter`,
	exportExcel: instId => `/institute/${instId}/cargo/list/export/excel`,
	editCargo: instId => `/institute/${instId}/cargo`,
	getGhsPreview: instId => `/institute/${instId}/safety/ghs/preview`,
	exportGhsQrPdf: instId => `/institute/${instId}/safety/ghs/export/pdf`,
}

const cargoAPI = {
	getCargoList: (instId, params) =>
		axiosInstance.get(endPoint.getCargoList(instId), {
			params: params,
		}),
	registCargo: (instId, formData) =>
		axiosInstance.post(endPoint.registCargo(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	divideCargoItem: (instId, body) => axiosInstance.patch(endPoint.divideCargoItem(instId), body),
	getCargoDetail: (instId, params) => axiosInstance.get(endPoint.getCargoDetail(instId), { params: params }),
	deleteCargoItem: (instId, body) => axiosInstance.delete(endPoint.deleteCargoItem(instId), { params: body }),
	processCargoItem: (instId, body) => axiosInstance.post(endPoint.processCargoItem(instId), body),
	getFilter: instId => axiosInstance.get(endPoint.getFilter(instId)),
	getCategoryInfo: (instId, params) => categoryAPI.getCategoryInfo(instId, params),
	exportExcel: (instId, body) =>
		axiosInstance.post(endPoint.exportExcel(instId), body, {
			responseType: 'blob',
		}),
	editCargo: (instId, formData) =>
		axiosInstance.put(endPoint.editCargo(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	getGhsPreview: (instId, params) => axiosInstance.get(endPoint.getGhsPreview(instId), { params: params }),
	exportGhsQrPdf: (instId, body) =>
		axiosInstance.post(endPoint.exportGhsQrPdf(instId), body, {
			responseType: 'blob',
		}),
}

export default cargoAPI
