<template>
	<div class="AddAuthorityAdminPopup" v-if="isShow">
		<div class="Popup__background"></div>
		<div class="Popup__container Popup__size-sm">
			<div class="Popup__top">
				<h5 class="Popup__title">{{ tt(title) }}</h5>
				<button class="Popup__close-btn" @click="hide">
					<img src="@/assets/svg/popup-close.svg" alt="close" />
				</button>
			</div>
			<div class="Popup__content">
				<search-box :placeholder="tt(searchBoxPlaceholder)" @inputValue="setKeyword" @search="setGroupMemberList(true)"></search-box>
				<div class="Organization__member-list">
					<div class="StorageBox__group-no-data no-data-box" v-if="!isSearched && !hasMember">
						<img class="no-member-img" src="@/assets/svg/member-photo-default.svg" alt="no-image" />
						<p v-html="tt('기관 내 멤버가 없습니다.') + '<br>' + tt('멤버를 추가해주세요')"></p>
						<br />
						<button class="Btn__management" type="button" @click="goToMemberManagement">
							{{ tt('멤버 관리') }}
						</button>
					</div>
					<div class="StorageBox__group-no-data no-data-box" v-else-if="isSearched && !hasMember">
						<img class="no-member-img" src="@/assets/svg/member-photo-default.svg" alt="no-image" />
						<p v-html="tt('검색 결과가 없습니다')"></p>
						<br />
					</div>
					<ul class="horizational-list" v-else ref="list" @scroll.passive="scroll">
						<li
							class="Organization__member-item safe"
							v-for="(member, idx) in members"
							:key="`${member.id}-${idx}`"
							@click="clickMember(member.id)"
						>
							<img v-if="!member.image" src="@/assets/svg/member-detail-photo.svg" alt="group-image" />
							<img v-else :src="member.image" />
							<div class="Organization__group-item-info" style="width: calc(100% - 90px)">
								<h6 v-html="highlightName(member.name)"></h6>
								<!-- <h6>{{member.name}}</h6> -->
								<p>{{ showGroupNames(member.groups) }}</p>
							</div>
							<input
								:id="member.id"
								:value="member.id"
								type="radio"
								v-model="selectedMemberId"
								name="selectMember"
								:checked="member.id === selectedMemberId"
							/>
							<label :for="member.id" class="radio"></label>
						</li>
					</ul>
				</div>
			</div>
			<div class="Popup__bottom">
				<button class="Btn__management" type="button" @click="goToMemberManagement" v-if="!isStart">
					{{ tt('멤버 관리') }}
				</button>
				<div class="Popup__complete-btn-group Popup__btn-group">
					<button class="Btn__close" type="button" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="Btn__complete" type="button" @click="complete">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SearchBox from '@/views/common/components/SearchBox.vue'
import MixinModal from '@/mixins/modal'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import instituteInformationAPI from '@/services/api/institute/information'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ModalSelectMember',
	props: {
		isStart: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		SearchBox,
	},
	mixins: [MixinModal],
	data() {
		return {
			title: '',
			members: [],
			originMemberList: [],
			selectedMemberId: '',
			searchBoxPlaceholder: '멤버 이름으로 검색해주세요',
			offset: 0,
			length: 10,
			searchBoxInputValue: '',
			isSearched: false,
			totalCount: undefined,
			hasMember: true,
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('member', ['memberList']),
		...mapGetters('organization', ['selectedGroupInfo']),
		getSelectedMember() {
			return this.members.filter(item => item.id === this.selectedMemberId)[0]
		},
	},
	methods: {
		...mapActions('member', ['lookupMemberList']),
		initSearchOption() {
			this.offset = 0
			this.keyword = ''
			this.searchBoxInputValue = ''
			this.isSearched = false
		},
		show(modalType, member) {
			this.isShow = true
			this.modalType = modalType
			this.selectedMemberId = ![undefined, null].includes(member) ? member.id : ''
			switch (modalType) {
				case 'safeResponsibility':
					this.title = this.tt('연구실안전책임자 지정')
					break
				case 'safeAdmin':
					this.title = this.tt('연구실안전관리자 지정')
					break
				case 'STORAGE_ASSIGNEE':
					this.title = this.tt('정관리자 선택')
					break
				case 'STORAGE_SUBASSIGNEE':
					this.title = this.tt('부관리자 선택')
					break
			}
			this.setGroupMemberList(false)
		},
		hide() {
			this.isShow = false
			this.initSearchOption()
		},
		clickMember(memberId) {
			this.selectedMemberId = memberId
		},
		complete() {
			const completeData = {
				modalType: this.modalType,
				member: this.getSelectedMember,
			}
			this.$emit('selectMember', completeData)
			this.hide()
		},
		setKeyword(keyword) {
			this.searchBoxInputValue = keyword
		},
		scroll() {
			const { clientHeight, scrollTop, scrollHeight } = this.$refs['list']
			const isScrollEnd = clientHeight + scrollTop === scrollHeight
			const hasMoreData = this.totalCount - (this.offset + this.length) > 0
			// 무한 스크롤 조건
			if (hasMoreData && isScrollEnd) {
				this.offset += this.length
				this.addGroupMemberList()
			}
		},
		setGroupMemberList(isSearched) {
			//일단 전체 목록 조회로 테스트 => 그룹의 멤버 조회로 작업 필요
			// TODO: 마무리 작업 필요
			this.keyword = this.searchBoxInputValue
			const instId = this.$route.params.instituteId
			const payload = {
				instId: instId,
				keyword: this.keyword,
				groupsId: [],
			}
			this.lookupMemberList(payload).then(() => {
				this.members = this.memberList.members
				this.originMemberList = [...this.members]
				this.totalCount = this.memberList.count

				if (this.members.length > 0) this.hasMember = true
				else this.hasMember = false
			})
			this.isSearched = isSearched
		},
		addGroupMemberList() {
			if (this.modalType.indexOf('safe') < -1) {
				const instId = this.userInstInfo.id
				const groupsIdList = []
				instituteInformationAPI.lookupMemberAll(instId, this.keyword, groupsIdList, this.offset, this.length).then(res => {
					this.members = [...this.members, ...res.data.members]
					this.originMemberList = [...this.members]
				})
			}
		},
		goToMemberManagement() {
			this.$router.push({
				name: PageName.Institution.MemberManagement,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_storage-box.scss';
.AddAuthorityAdminPopup {
	.horizational-list {
		height: 500px;
		width: 100%;
		padding-top: 0;
		margin-top: 20px;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		overflow-y: auto;
		li {
			padding: 12px;
			border-bottom: 0;
			transition: 0.3s all linear;
			> img {
				margin-top: 3px;
				object-fit: contain !important;
			}
			&:hover {
				background-color: $GREY_1;
			}
			button {
				img {
					width: 20px;
				}
			}
			> div {
				h6 {
					> img {
						width: 12px;
						margin-left: 6px;
					}
				}
			}
		}
	}
}

.StorageBox__group-no-data {
	&.no-data-box {
		min-height: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

.no-member-img {
	padding-bottom: 10px;
}
</style>
