var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SafetyLegalProduct"
  }, [_c('h2', {
    staticClass: "AppContainer__title"
  }, [_vm._v(_vm._s(_vm.tt('그룹별 법령물품')))]), _c('div', {
    staticClass: "AppContainer"
  }, [_c('div', {
    staticClass: "Filter__box"
  }, [_c('search-box', {
    attrs: {
      "placeholder": _vm.tt('그룹 이름을 검색해주세요'),
      "loadList": _vm.loadLegalList
    }
  })], 1), _c('div', {
    staticClass: "Table__wrap scroll"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th', {
    staticClass: "headerHover",
    "class": {
      asc: this.ordersDirection[0] == 'ASC'
    },
    attrs: {
      "width": "250"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('그룹 이름')) + " "), _c('button', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow-b.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.changeSort
    }
  })])])]), _c('th', {
    attrs: {
      "width": "280"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('화학물질관리법')))])]), _c('th', {
    attrs: {
      "width": "280"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('폐기물관리법')))])]), _c('th', {
    attrs: {
      "width": "280"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('산업안전보건법')))])]), _c('th', {
    attrs: {
      "width": "280"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('위험물관리법')))])])])]), _c('tbody', _vm._l(_vm.list, function (item) {
    return _c('legal-item', {
      key: item.id,
      attrs: {
        "item": item
      }
    });
  }), 1)]), _vm.list.length == 0 ? _c('no-data-list', {
    attrs: {
      "image": _vm.noData.image,
      "title": _vm.noData.title,
      "content": _vm.noData.content
    }
  }) : _vm._e()], 1)]), _c('pagination', {
    attrs: {
      "total": _vm.count
    },
    on: {
      "page": _vm.pagination
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }