var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header column"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt('스티커 출력 양식')))]), _c('div', {
    staticClass: "form-header-subtext"
  }, [_vm._v(" " + _vm._s(_vm.tt('물품에 부착할 [물품 ID 스티커] 양식을 선택해주세요.')) + " ")])]), _c('div', {
    staticClass: "form-data-wrap"
  }, [_vm.isCategoryChemical ? _c('button', {
    staticClass: "sticker-btn",
    "class": {
      active: _vm.stickerType === 'GHS+QR'
    },
    on: {
      "click": _vm.showSelectGHSQR
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('GHS 라벨+QR 스티커')) + " ")]) : _vm._e(), _c('button', {
    staticClass: "sticker-btn",
    "class": {
      active: _vm.stickerType === 'QR'
    },
    on: {
      "click": function click($event) {
        return _vm.changeType('QR');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('QR 스티커')))]), _c('button', {
    staticClass: "sticker-btn",
    "class": {
      active: !_vm.stickerType
    },
    on: {
      "click": function click($event) {
        return _vm.changeType(null);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('부착 안 함')) + " ")])]), _vm.isRequireGhs ? _c('div', {
    staticClass: "warn-msg-wrap"
  }, [_c('p', {
    staticClass: "warn-msg",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('등록하려는 화학물질은 <b class=underline>「위험물안전관리법」시행규칙 별표 19</b>에 의하여 <b>GHS라벨</b>을 부착해야합니다.'))
    },
    on: {
      "click": function click($event) {
        return _vm.openUrl('https://www.law.go.kr/LSW/admRulLsInfoP.do?admRulSeq=2100000049106');
      }
    }
  })]) : _vm._e(), _c('modal-confirm', {
    ref: "modal-make-ghs-confirm",
    attrs: {
      "title": "GHS 라벨 생성",
      "content": "등록하려는 물품의 GHS 라벨이 없습니다. GHS라벨을 생성해주세요.<br/>제품 별 최초 1회 필요로합니다.",
      "confirmText": "라벨 생성"
    },
    on: {
      "confirm": _vm.selectGHSQR,
      "cancel": _vm.cancelGHSQR
    }
  }), _c('modal-make-ghs', {
    ref: "make-ghs",
    attrs: {
      "title": "GHS 라벨 생성",
      "msds": _vm.msds
    },
    on: {
      "close": function close($event) {
        _vm.cancelGHSQR();

        _vm.cancelParseMsds();
      },
      "showGuide": function showGuide($event) {
        return _vm.$refs['guide'].show();
      },
      "goNext": function goNext($event) {
        _vm.$refs['make-ghs'].hide();

        _vm.$refs['ghs-form'].show();

        _vm.cancelParseMsds();
      }
    }
  }), _c('modal-ghs-form', {
    ref: "ghs-form",
    on: {
      "close": function close($event) {
        return _vm.cancelGHSQR();
      },
      "goNext": _vm.showPreviewGHS,
      "goPrev": function goPrev($event) {
        _vm.$refs['make-ghs'].show('prev');

        _vm.$refs['ghs-form'].hide();
      }
    }
  }), _c('modal-ghs-preview', {
    ref: "ghs-preview",
    on: {
      "close": function close($event) {
        return _vm.cancelGHSQR();
      },
      "goPrev": function goPrev($event) {
        _vm.$refs['ghs-preview'].hide();

        _vm.$refs['ghs-form'].show();
      },
      "complete": _vm.completeCreateGHS
    }
  }), _c('modal-ghs-label', {
    ref: "ghs-label",
    on: {
      "edit": _vm.editGHS,
      "delete": _vm.deleteGHS
    }
  }), _c('modal-confirm', {
    ref: "modal-ghs-unselect",
    attrs: {
      "title": "선택 해제",
      "content": _vm.tt('등록하려는 화학물질은 <b class=underline>「위험물안전관리법」시행규칙 별표 19</b>에 의하여 <b>GHS라벨</b>을 부착해야합니다.'),
      "confirmText": "선택 해제"
    },
    on: {
      "confirm": _vm.unselectGHS,
      "cancel": function cancel($event) {
        return _vm.$refs['modal-ghs-unselect'].hide();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }