<template>
	<div class="page-wrapper">
		<approval-list-header
			:title="tt('지출결의서 결재함')"
			:dateButtonSubtext="dateButtonSubtext"
			:calendarDatetime="calendarDatetime"
			@changeDate="changeCalendar"
			@changeLoadParams="setLoadParams"
		></approval-list-header>
		<approval-list-table ref="approval-table" v-if="previousPage" :previousPage="previousPage" :list="list"></approval-list-table>
		<div class="pagination-wrapper">
			<pagination ref="pagination" @page="pagination" :total="count"></pagination>
		</div>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MixinExpenditureList from '@/mixins/purchase/expenditureList'
import ApprovalListHeader from '../components/list/ApprovalListHeader.vue'
import ApprovalListTable from '../components/table'
import Pagination from '@/views/common/components/Pagination.vue'

export default {
	name: 'ApprovalList',
	mixins: [MixinExpenditureList],
	components: {
		ApprovalListHeader,
		ApprovalListTable,
		Pagination,
	},
	computed: {
		...mapState('approval/list', ['list', 'count']),
		...mapGetters('approval/list', ['offset', 'length']),
	},
	mounted() {
		if (this.previousPage?.name !== PageName.Purchase.ApprovalDetail) {
			this.initFilterCookies(true)
			this.$refs['pagination'].init(1)
		} else {
			this.$refs['pagination']?.changePage(this.offset, this.length)
		}
	},
	data() {
		return {
			previousPage: null,
			// cookie key
			cookieFilterKey: 'expenseApprovalFilter',
			// cookieVisibleColumnsKey: 'expenseApprovalVisibleColumns',
			cookieDatetimeFilterKey: 'expenseApprovalDatetimeFilter',
		}
	},
	methods: {
		...mapMutations('approval/list', ['initFilterCookies']),
		...mapActions('approval/list', ['setLoadParams']),
		async pagination(page) {
			this.$refs['approval-table']?.moveScrollTop()
			await this.setLoadParams(page)
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	display: flex;
	flex-direction: column;
}
</style>
