<template>
	<div>
		<card-possession-status
			v-if="id == 1"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:list="possessionTrend"
			:toggleCard="toggleCard"
		></card-possession-status>
		<card-health-checkup
			v-if="id == 2 && userPermission.safe && userPermission.safe.diagnosis.read && !isBasic"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:list="diagnosisList"
			:toggleCard="toggleCard"
		></card-health-checkup>
		<card-registering-item
			v-if="id == 3"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:trend="createTrend"
			:summary="createSummary"
			:toggleCard="toggleCard"
		></card-registering-item>
		<card-disposal-status
			v-if="id == 4"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:trend="disposalTrend"
			:summary="disposalSummary"
			:toggleCard="toggleCard"
		></card-disposal-status>
		<card-msds-upload
			v-if="id == 5"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:list="msdsUpload"
			:toggleCard="toggleCard"
		></card-msds-upload>
		<card-storage-quantity
			v-if="id == 6 && userPermission.safe && userPermission.safe.dangerstorage.read && !isBasic"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:list="dangerStorage"
			:toggleCard="toggleCard"
		></card-storage-quantity>
		<card-chemical-substances-status
			v-if="id == 7"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:trend="useTrend"
			:list="useSummary"
			:toggleCard="toggleCard"
		></card-chemical-substances-status>
		<card-law-material
			v-if="id == 8 && userPermission.safe && userPermission.safe.legalitems.read && !isBasic"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:list="legalList.list"
			:toggleCard="toggleCard"
		></card-law-material>
		<card-disposal-item
			v-if="id == 9"
			:id="id"
			:instId="instId"
			:visible="visible"
			:setting="setting"
			:list="disposalItem"
			:toggleCard="toggleCard"
		></card-disposal-item>
	</div>
</template>

<script>
import CardHealthCheckup from '@/views/dashboard/pages/components/CardHealthCheckup.vue'
import CardStorageQuantity from '@/views/dashboard/pages/components/CardStorageQuantity.vue'
import CardLawMaterial from '@/views/dashboard/pages/components/CardLawMaterial.vue'
import CardDisposalItem from '@/views/dashboard/pages/components/CardDisposalItem.vue'
import CardMsdsUpload from '@/views/dashboard/pages/components/CardMsdsUpload.vue'
import CardRegisteringItem from '@/views/dashboard/pages/components/CardRegisteringItem.vue'
import CardDisposalStatus from '@/views/dashboard/pages/components/CardDisposalStatus.vue'
import CardPossessionStatus from '@/views/dashboard/pages/components/CardPossessionStatus.vue'
import CardChemicalSubstancesStatus from '@/views/dashboard/pages/components/CardChemicalSubstancesStatus.vue'
import { mapGetters } from 'vuex'
export default {
	name: 'DashboardCards',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		instId: {
			type: String,
			default: '',
		},
		createTrend: {
			type: Object,
			default: () => {},
		},
		createSummary: {
			type: Object,
			default: () => {},
		},
		disposalTrend: {
			type: Object,
			default: () => {},
		},
		disposalSummary: {
			type: Object,
			default: () => {},
		},
		useTrend: {
			type: Object,
			default: () => {},
		},
		useSummary: {
			type: Object,
			default: () => {},
		},
		possessionTrend: {
			type: Object,
			default: () => {},
		},
		disposalItem: {
			type: Object,
			default: () => {},
		},
		msdsUpload: {
			type: Object,
			default: () => {},
		},
		diagnosisList: {
			type: Object,
			default: () => {
				return {
					unsafeCount: 0,
					overdueList: [],
					targetList: [],
				}
			},
		},
		dangerStorage: {
			type: Object,
			default: () => {
				return {
					unsafeCount: 0,
					list: [],
				}
			},
		},
		legalList: {
			type: Object,
			default: () => {
				return {
					list: [],
				}
			},
		},
	},
	components: {
		CardHealthCheckup,
		CardStorageQuantity,
		CardLawMaterial,
		CardDisposalItem,
		CardMsdsUpload,
		CardRegisteringItem,
		CardDisposalStatus,
		CardPossessionStatus,
		CardChemicalSubstancesStatus,
	},
	mounted() {},
	data() {
		return {
			displayCardList: [],
		}
	},
	computed: {
		...mapGetters('license', ['isBasic']),
	},
	methods: {
		toggleCard(id, val) {
			const payload = {
				id: id,
				visible: val,
			}

			this.$emit('visibleCard', payload)
		},
	},
}
</script>
