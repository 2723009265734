var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "changeInitPw"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('새로운 비밀번호 설정')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>${1}님</b> 안녕하세요!<br>새로운 비밀번호를 설정해주세요<br>영문, 숫자, 특수문자를 함께 사용하면 보다 안전합니다', _vm.user.name))
    }
  }), _c('div', {
    staticClass: "email-box"
  }, [_c('p', [_vm._v(_vm._s(_vm.user.email))])]), _c('div', {
    staticClass: "form-box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.password,
      expression: "user.password"
    }],
    attrs: {
      "type": "password",
      "placeholder": _vm.tt('비밀번호 입력(6자리 이상)')
    },
    domProps: {
      "value": _vm.user.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.user, "password", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newPw,
      expression: "newPw"
    }],
    attrs: {
      "type": "password",
      "placeholder": _vm.tt('새로운 비밀번호 확인')
    },
    domProps: {
      "value": _vm.newPw
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.newPw = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('확인')) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }