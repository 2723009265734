<template>
	<table class="lm-table">
		<table-header />
		<tbody>
			<table-item v-for="item in list" :key="item.id" :item="item" />
		</tbody>
		<div class="empty-table" v-if="count === 0">
			<img class="empty-img" src="@/assets/svg/empty/inventory-empty-data.svg" />
			<p class="bold">{{ tt('검색 결과가 없습니다') }}</p>
			<p class="sub">
				{{ tt('예산 번호, 예산 이름, 참여그룹으로 검색할 수 있습니다.') }}
			</p>
		</div>
	</table>
</template>
<script>
import TableHeader from './header.vue'
import TableItem from './item.vue'
import { mapState } from 'vuex'
export default {
	components: {
		TableHeader,
		TableItem,
	},
	computed: {
		...mapState('budget/list', ['count']),
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
}
</script>
<style lang="scss" scoped>
.lm-table {
	position: relative;
	height: 100%;
	text-align: left;
	display: block;
	white-space: nowrap;
	border-spacing: 0;
	border-collapse: separate;
	tbody {
		overflow: auto;
	}
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: $SECONDARY_2;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
