var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.count !== 0 ? _c('div', {
    ref: "menu-wrapper",
    staticClass: "floating-menu-wrapper",
    style: {
      left: _vm.leftPosition
    }
  }, [_c('div', {
    staticClass: "counting-box"
  }, [_c('div', {
    staticClass: "counter"
  }, [_vm._v(_vm._s(_vm.count) + "개")]), _c('div', {
    staticClass: "counter-text"
  }, [_vm._v(_vm._s(_vm.tt('선택')))])]), _vm._t("default")], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }