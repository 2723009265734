<template>
	<b-modal centered hide-footer @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<div class="seller-wrapper">
			<div class="seller-image" v-if="seller.id">
				<img :src="image" />
			</div>
			<div class="seller-info">
				<div class="header">{{ tt('판매자 정보') }}</div>
				<div class="info-table">
					<div class="info-row">
						<div class="label">{{ tt('판매자') }}</div>
						<div class="content">{{ tt(`${cleanData(seller.name)}`) }}</div>
					</div>
					<div class="info-row">
						<div class="label">{{ tt('담당자') }}</div>
						<div class="content">{{ tt(`${cleanData(seller.contactName)}`) }}</div>
					</div>
					<div class="info-row">
						<div class="label">{{ tt('연락처') }}</div>
						<div class="content">{{ tt(`${cleanData(seller.contactPhone)}`) }}</div>
					</div>
					<div class="info-row">
						<div class="label">{{ tt('사업자등록번호') }}</div>
						<div class="content">{{ tt(`${cleanData(seller.businessNumber)}`) }}</div>
					</div>
					<div class="info-row">
						<div class="label">{{ tt('주소') }}</div>
						<div class="content">{{ tt(`${cleanData(seller.address)}`) }}</div>
					</div>
					<!-- <div class="info-row">
						<div class="label">이메일</div>
						<div class="content">{{ seller.email }}</div>
					</div> -->
				</div>
				<div class="buttons" v-if="seller.id">
					<base-button :color="'primary'" @click="goPage()">{{ tt('판매자 상품 보기') }}</base-button>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
export default {
	components: {},
	props: {
		seller: Object,
	},
	computed: {
		image() {
			return this.seller.image ?? require('@/assets/svg/no-image.svg')
		},
	},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cleanData(data) {
			return data ?? '-'
		},
		goPage() {
			this.$root.goToStore(`search/seller`, { id: this.seller.id })
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	border-bottom: none;
	padding-bottom: 0;
}
.seller-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0px 30px;
	width: 100%;
	.seller-image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 400px;
		height: 130px;
		border: 1px solid $INPUT;
		padding: 20px 30px;
		border-radius: 4px;
		margin-bottom: 30px;
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
	.seller-info {
		margin-bottom: 30px;
		width: 100%;
		.header {
			width: 100%;
			font-weight: 700;
			font-size: 16px;
			line-height: 23px;
		}
		.info-table {
			margin-top: 10px;
			border-top: 1px solid $INPUT;
			.info-row {
				width: 100%;
				border-bottom: 1px solid $DIVIDER;
				height: 40px;
				display: flex;
				flex-direction: row;
				& > * {
					display: flex;
					align-items: center;
					height: 100%;
					font-size: 14px;
					padding-left: 12px;
					padding-right: 12px;
				}
				.label {
					width: 140px;
					background: $GREY_1;
					color: $SECONDARY_1;
				}
				.content {
					width: 260px;
					color: $DEFAULT;
					word-break: keep-all;
				}
			}
		}
	}
	.buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		.btn-primary {
			width: 200px;
			margin-left: 10px;
		}
	}
}
</style>
