<template>
	<div class="store-info-wrapper">
		<img src="@/assets/svg/icon-lab-manager-store.svg" />
		<div>
			<h3>{{ tt('랩매니저 스토어에서 상품 선택') }} <img src="@/assets/svg/icon-info.svg" @click="$refs['modal'].show()" /></h3>
			<p v-if="content" v-html="tt(content)"></p>
			<template v-else>
				<p v-if="isOrder">{{ tt('1. 주문하기를 선택하면, 랩매니저 스토어에 주문이 자동 접수됩니다.') }}</p>
				<p v-else>{{ tt('1. 지출결의서 승인시, 랩매니저 스토어에 주문이 자동접수됩니다. ') }}</p>
				<p>{{ tt('2. 판매자가 주문을 승인하면, 후불결제를 진행할 수 있습니다.') }}</p>
			</template>
		</div>
		<modal-store-order-tip ref="modal" :type="type" />
	</div>
</template>
<script>
import ModalStoreOrderTip from '../modal/ModalStoreOrderTip.vue'
export default {
	props: {
		type: {
			type: String,
			default: 'ORDER',
		},
		content: {
			type: String,
			default: null,
		},
	},
	computed: {
		isOrder() {
			return this.type == 'ORDER'
		},
	},
	components: {
		ModalStoreOrderTip,
	},
}
</script>
<style lang="scss" scoped>
.store-info-wrapper {
	width: 100%;
	padding: 16px;
	background-color: $SUB-YELLOW_100;
	border-radius: 5px;
	display: flex;
	& > img {
		margin-right: 24px;
	}
	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		h3 {
			align-items: center;
			display: flex;
			color: $DEFAULT;
			font-size: 16px;
			font-weight: 700;
			img {
				margin-left: 10px;
				cursor: pointer;
			}
		}
		p {
			font-size: 14px;
			color: $SECONDARY_1;
		}
	}
}
</style>
