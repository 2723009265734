import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'

const routes = [
	{
		name: PageName.InventoryGen2.List,
		path: RoutePath.InventoryGen2.List,
		props: true,
		component: () => import('@/views/inventoryGen2/list/index'),
	},
	{
		name: PageName.InventoryGen2.WaitList,
		path: RoutePath.InventoryGen2.WaitList,
		props: true,
		component: () => import('@/views/inventoryGen2/wait/index'),
	},
	{
		name: PageName.InventoryGen2.HistoryList,
		path: RoutePath.InventoryGen2.HistoryList,
		props: true,
		component: () => import('@/views/inventoryGen2/history/index'),
	},
	{
		name: PageName.InventoryGen2.DisposalList,
		path: RoutePath.InventoryGen2.DisposalList,
		props: true,
		component: () => import('@/views/inventoryGen2/disposal/index'),
	},
	{
		name: PageName.InventoryGen2.Detail,
		path: RoutePath.InventoryGen2.Detail,
		props: true,
		component: () => import('@/views/inventoryGen2/detail/index'),
	},
	{
		name: PageName.InventoryGen2.WaitDetail,
		path: RoutePath.InventoryGen2.WaitDetail,
		props: true,
		component: () => import('@/views/inventoryGen2/waitDetail/index.vue'),
	},
	{
		name: PageName.InventoryGen2.DisposalDetail,
		path: RoutePath.InventoryGen2.DisposalDetail,
		props: true,
		component: () => import('@/views/inventoryGen2/disposalDetail/index.vue'),
	},
]

export default routes
