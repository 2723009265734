<template>
	<div class="check-item">
		<div class="check-line" v-b-tooltip.hover="node.name">
			<label>
				<input class="checkbox" type="checkbox" @change="checked(node)" :checked="checkedList.includes(node.id)" />
				<span v-html="replaceHighlight(node.name)"></span
			></label>
			<img
				v-if="node.children?.length > 0"
				@click.stop="toggleTreeOpen"
				class="arrow"
				:class="{ fold: isTreeOpen }"
				src="@/assets/svg/Triangle_Down.svg"
			/>
		</div>
		<div class="children" v-if="isTreeOpen">
			<filter-children v-for="child in node.children" :key="child.id" :node="child" :checkedList="checkedList" @checked="checked" />
		</div>
	</div>
</template>
<script>
import StringUtils from '@/utils/modules/StringUtils'
import { isChildrenChecked } from '@/utils/Tree'
import { mapState, mapGetters } from 'vuex'
import MixinTree from '@/mixins/tree'
export default {
	name: 'FilterChildren',
	mixins: [MixinTree],
	props: {
		node: {
			type: Object,
		},
		checkedList: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapGetters('filter', ['text']),
	},
	mounted() {
		if (isChildrenChecked(this.node.children, this.checkedList)) {
			this.isTreeOpen = true
		}
	},
	methods: {
		checked(node) {
			this.$emit('checked', node)
		},
		replaceHighlight(value) {
			return StringUtils.replaceHighlight(value, this.text, 'span', 'highlight')
		},
	},
}
</script>
<style scoped lang="scss">
.check-item {
	display: flex;
	flex-direction: column;
	gap: 10px;
	.check-line {
		width: 100%;
		display: flex;
		label {
			cursor: pointer;
			padding-left: 2px;
			display: flex;
			input {
				margin-top: 3px;
				margin-right: 5px;
			}
			align-items: flex-start;
			gap: 10px;
			width: calc(100% - 20px);
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			font-size: 14px;
			span {
				margin-left: 5px;
			}
		}
		.arrow {
			margin-left: auto;
			&.fold {
				transform: rotate(180deg);
			}
		}
	}
	.children {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-left: 10px;
	}
}
::v-deep {
	.highlight {
		background-color: $SUB-YELLOW_100;
		&.active {
			background-color: #ff3b314d;
		}
	}
}
</style>
