<template>
	<tr>
		<td>
			<button class="checkbox" @click="checkBoxClick" :class="{ active: isClick }"></button>
		</td>
		<td>
			<b v-if="item.name">{{ item.name }}</b>
			<span class="Btn__hover-balloon-w" v-else-if="validText.name"
				>{{ tt('미입력') }}
				<div class="balloon">
					<p>{{ validText.name }}</p>
				</div>
			</span>
		</td>
		<td>
			<span class="Btn__hover-balloon-w" v-if="validText.email"
				>{{ item.email ? item.email : '미입력' }}
				<div class="balloon">
					<p>{{ validText.email }}</p>
				</div>
			</span>
			<span v-else>{{ item.email }}</span>
		</td>
		<td v-if="$route.query.page">
			<span v-for="(groups, index) in item.groups" :key="`groups` + index">
				<span class="Btn__hover-balloon-w" v-if="validText.groups[index]"
					>{{ groups.fullPathName }}
					<div class="balloon">
						<p>{{ validText.groups[index] }}</p>
					</div></span
				>
				<span v-else>{{ groups.fullPathName }}</span
				><span v-if="index != item.groups.length - 1">, </span>
			</span>
		</td>
		<td>
			<span class="Btn__hover-balloon-w" v-if="validText.phone"
				>{{ item.phone ? item.phone : '미입력' }}
				<div class="balloon">
					<p>{{ validText.phone }}</p>
				</div>
			</span>
			<span v-else>{{ item.phone }}</span>
		</td>
		<td>
			<span v-for="(permission, index) in item.permissions" :key="permission.key">
				<span v-if="permission.name != null">
					<span v-if="validText.permissions[index]" class="Btn__hover-balloon-w"
						>{{ permission.name }}
						<div class="balloon">
							<p>{{ validText.permissions[index] }}</p>
						</div></span
					>
					<span v-else>{{ permission.name }}</span
					><span v-if="index != item.permissions.length - 1">, </span>
				</span>
			</span>
		</td>
		<td>
			<button class="btn" @click="showRegisteringMember">
				{{ tt('정보편집') }}
			</button>
			<button class="btn" @click="showExportInst(item.id)" v-if="mode == 'individual'" :class="{ isNone: item.id == info.id }">
				{{ tt('내보내기') }}
			</button>
			<button class="btn" @click="showDeleteMember(index)" v-else>
				{{ tt('삭제하기') }}
			</button>
		</td>
	</tr>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		item: {
			type: Object,
			default: () => {},
		},
		allCheck: {
			type: Boolean,
			default: false,
		},
		headers: {
			type: Array,
			default: [],
		},
		offset: {
			type: Number,
			default: 0,
		},
		length: {
			type: Number,
			default: 0,
		},
		mode: {
			type: String,
			default: '',
		},
		checkList: {
			type: Array,
			default: [],
		},
		info: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	created() {
		this.isValid()
	},
	watch: {
		allCheck: function () {
			if (this.allCheck) {
				this.isClick = true
			} else {
				this.isClick = false
			}
		},
		isClick: function () {
			if (this.isClick) {
				this.$emit('checkId', this.item.id)
			} else {
				this.$emit('uncheckId', this.item.id)
			}
		},
		checkList: function () {
			if (this.checkList.length == 0) {
				this.isClick = false
			}
		},
		item: function () {
			this.isValid()
		},
	},
	data() {
		return {
			isClick: false,
			validText: {
				name: null,
				email: null,
				phone: null,
				groups: {},
				permissions: {},
			},
		}
	},
	computed: {
		excelList() {
			this.isValid()
			return this.list
		},
	},
	methods: {
		checkBoxClick() {
			if (this.isClick) {
				this.isClick = false
			} else {
				this.isClick = true
			}
		},
		showRegisteringMember() {
			this.$emit('showRegisteringMember', 'edit', this.item, this.index)
		},
		showExportInst(id) {
			this.$emit('showExportInst', id)
		},
		showDeleteMember(index) {
			this.$emit('showDeleteMember', index)
		},
		isValid() {
			if (this.mode == 'all') {
				const itemKeys = Object.keys(this.item)
				this.validText = {
					name: null,
					email: null,
					phone: null,
					groups: {},
					permissions: {},
				}
				for (let keys of itemKeys) {
					if (this.item.errors) {
						this.item.errors.forEach(x => {
							if (keys == x.field) {
								if (keys == 'permissions') {
									this.item.permissions.forEach((permission, index) => {
										if (x.message.indexOf(permission.name) >= 0) {
											this.validText['permissions'][index] = x.message
										}
									})
								} else if (keys == 'groups') {
									this.item.groups.forEach((group, index) => {
										if (x.message.indexOf(group.fullPathName) >= 0) {
											this.validText['groups'][index] = x.message
										}
									})
								} else {
									this.validText[`${x.field}`] = x.message
								}
							}
						})
					}
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
tr {
	td {
		&:last-child {
			button {
				display: inline-block;
				&.isNone {
					visibility: hidden;
					opacity: 0;
				}
				&:last-child {
					margin-left: 10px;
				}
			}
		}
		.Btn__hover-balloon-w {
			color: $RED;
			padding-left: 18px;
			background-image: url(~@/assets/svg/NA.svg);
			background-repeat: no-repeat;
			background-size: 24px;
			background-position: center left -6px;
			color: $RED;
			&:hover {
				.balloon {
					opacity: 1;
					visibility: visible;
					max-width: unset;
					width: unset;
					p {
						position: relative;
						padding-left: 18px;
						background-image: url(~@/assets/svg/NA.svg);
						background-repeat: no-repeat;
						background-size: 24px;
						background-position: center left -6px;
						color: $RED;
						white-space: nowrap;
					}
				}
			}
		}
	}
}
</style>
