var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    ref: "AddChildStorageBoxPopup",
    staticClass: "AddChildStorageBoxPopup"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Location__edit-location-popup Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.hide
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.labelName))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('하위 보관함명을 입력해주세요')
    },
    domProps: {
      "value": _vm.storageName
    },
    on: {
      "input": _vm.changeStorageName
    }
  })])]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    "class": {
      Btn__complete: !_vm.isSameName,
      Btn__disabled: _vm.isSameName
    },
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }