<template>
	<tr class="table-header">
		<th :class="orderClass('STATUS')" @click.stop="changeOrder('STATUS')">
			<span>{{ tt('결재상태') }}</span>
		</th>
		<th v-if="isView('no')" :class="orderClass('NO')" @click.stop="changeOrder('NO')">
			<span>{{ tt('문서번호') }}</span>
		</th>
		<th :class="orderClass('SUBJECT')" @click.stop="changeOrder('SUBJECT')">
			<span>{{ tt('기안 제목') }}</span>
		</th>
		<th v-if="isView('budgetName')" :class="orderClass('BUDGET')" @click.stop="changeOrder('BUDGET')">
			<span>{{ tt('예산 이름') }}</span>
		</th>
		<th v-if="isView('purchaseType')">
			<span>{{ tt('구매방식') }}</span>
		</th>
		<th v-if="isView('group')" :class="orderClass('GROUP')" @click.stop="changeOrder('GROUP')">
			<span>{{ tt('기안 연구실') }}</span>
		</th>
		<th v-if="isView('register')" :class="orderClass('REGISTER')" @click.stop="changeOrder('REGISTER')">
			<span>{{ tt('기안자') }}</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')">
			<span>{{ tt('기안일시') }}</span>
		</th>
		<th v-if="isView('concludeDatetime')" :class="orderClass('CONCLUDE_DATETIME')" @click.stop="changeOrder('CONCLUDE_DATETIME')">
			<span>{{ tt('결재완료일시') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapState('draft/list', ['checkedViewOption']),
		...mapGetters('draft/list', ['order', 'orderDirection']),
	},
	mixins: [MixinHeader],
	methods: {
		...mapActions('draft/list', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
