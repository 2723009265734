var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    ref: "btn-wrapper",
    staticClass: "Btn__select",
    attrs: {
      "disabled": _vm.disabled
    }
  }, [_c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "readonly": ""
    },
    domProps: {
      "value": _vm.selectedOptionText
    },
    on: {
      "mousedown": _vm._inputMouseDown,
      "click": function click($event) {
        return _vm.$refs['btn-wrapper'].focus();
      }
    }
  }), _c('ul', {
    ref: "option-box",
    staticClass: "Form__select-option-list scroll"
  }, _vm._l(_vm.list, function (option) {
    return _c('li', {
      key: option[_vm.valueKey],
      staticClass: "Form__select-option-item",
      on: {
        "click": function click($event) {
          return _vm.selectOption(option);
        }
      }
    }, [_vm._v(" " + _vm._s(option[_vm.textKey]) + " ")]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }