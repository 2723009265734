var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-form-item', {
    staticClass: "input-form-item",
    attrs: {
      "title": _vm.title,
      "required": _vm.required,
      "direction": _vm.direction,
      "description": _vm.description
    }
  }, [_c(_vm.inputComponent, {
    ref: _vm.ref,
    tag: "component",
    attrs: {
      "value": _vm.value,
      "placeholder": _vm.tt(_vm.placeholder),
      "maxlength": _vm.maxlength,
      "min": _vm.min,
      "max": _vm.max,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "autofocus": _vm.autofocus,
      "error": _vm.error,
      "errorMessage": _vm.errorMessage,
      "inputStyle": _vm.inputStyle,
      "postcode": _vm.postcode,
      "address": _vm.address,
      "addressDetail": _vm.addressDetail
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      },
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "keydown": function keydown($event) {
        return _vm.$emit('keydown', $event);
      },
      "keyup": function keyup($event) {
        return _vm.$emit('keyup', $event);
      },
      "search": function search($event) {
        return _vm.$emit('searchAddress', $event);
      }
    }
  }), _vm.button ? _c('button', {
    staticClass: "btn",
    "class": {
      disabled: _vm.buttonDisabled
    },
    attrs: {
      "disabled": _vm.buttonDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.button)) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }