import axiosInstance from '@/services/axios'

const endPoint = {
	lookupMigrationLab: () => `/user/migration/lab`,
	lookupMigrationUser: () => `/user/migration/user`,
	requestMigration: () => `/user/migration`,
	requestSelectMigration: () => `/user/migration/select`,
	requestRejectMigration: () => `/user/migration/reject`,
	requestFirstVisit: () => `/user/migration/firstvisit`,
}

const setParams = params => {
	return { params: params }
}

const lookupMigrationLab = () => {
	return axiosInstance.get(endPoint.lookupMigrationLab())
}
const lookupMigrationUser = params => {
	return axiosInstance.get(endPoint.lookupMigrationUser(), setParams(params))
}
const requestMigration = body => {
	return axiosInstance.post(endPoint.requestMigration(), body)
}
const requestSelectMigration = body => {
	return axiosInstance.post(endPoint.requestSelectMigration(), body)
}

const requestRejectMigration = () => {
	return axiosInstance.post(endPoint.requestRejectMigration())
}

const requestFirstVisit = () => {
	return axiosInstance.post(endPoint.requestFirstVisit())
}

export { lookupMigrationLab, lookupMigrationUser, requestMigration, requestSelectMigration, requestRejectMigration, requestFirstVisit }
