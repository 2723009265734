<template>
	<div class="number-input-wrapper" v-if="isShow" @click.stop>
		<div class="inner-wrapper">
			<card-number-input-button
				:number="number"
				v-for="(number, index) in numbers"
				:key="`${number}-${index}`"
				@add="addNumber"
			></card-number-input-button>
			<div class="bottom-button-wrapper">
				<div class="card-input-button-bottom" @click.stop="remove">
					<img src="@/assets/svg/delete-card-input-number.svg" width="28" />
				</div>
				<div class="card-input-button-bottom unselectable" @click.stop="reset">
					{{ tt('재배열') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CardNumberInputButton from '@/views/institute/information/modals/CardNumberInputButton.vue'
export default {
	components: { CardNumberInputButton },
	props: {
		numbers: {
			type: Array,
			default: () => new Array(12).fill('', 0, 12),
		},
		isShow: {
			type: Boolean,
			defualt: false,
		},
	},
	watch: {
		isShow(value) {
			if (!value) {
				this.$emit('reset')
			}
		},
	},
	methods: {
		addNumber(number) {
			this.$emit('add', number)
		},
		reset() {
			this.$emit('reset')
		},
		remove() {
			this.$emit('remove')
		},
	},
}
</script>

<style lang="scss" scoped>
.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.card-input-button-bottom {
	display: flex;
	background: $LAB_WHITE;
	box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.5), 0px 0.5px 1px $DIM;
	border-radius: 5px;
	width: 87px;
	height: 40px;
	align-items: center;
	justify-content: center;
	font-weight: 700;
}

.number-input-wrapper {
	box-sizing: border-box;
	position: absolute;
	max-width: 203px;
	padding: 10px 5px 10px 10px;
	background: $DIVIDER;

	border: 1px solid $INPUT;
	box-sizing: border-box;
	/* web shadow */

	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	.inner-wrapper {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		height: 180px;
		.bottom-button-wrapper {
			display: flex;
			width: 100%;
			justify-content: space-between;
			margin-right: 4px;
			.bottom-button {
			}
		}
	}
}
</style>
