<template>
	<div class="Table__pagination" :style="{ position: notSticky ? 'relative' : 'sticky' }">
		<ul class="pagination">
			<li class="first" @click="firstPage" :class="firstDisabled ? 'disabled' : ''">
				<img src="@/assets/svg/pagination-arrow.svg" /><img src="@/assets/svg/pagination-arrow.svg" />
			</li>
			<li class="prev" @click="prevPage" :class="prevDisabled ? 'disabled' : ''">
				<img src="@/assets/svg/pagination-arrow.svg" />
			</li>
			<li @click="currentPage(page)" v-for="page in pagination" :key="page" :class="page == current ? 'active' : ''">
				{{ page }}
			</li>
			<li class="next" @click="nextPage" :class="{ disabled: nextDisabled || maxPage == 0 }">
				<img src="@/assets/svg/pagination-arrow.svg" />
			</li>
			<li class="last" @click="lastPage" :class="{ disabled: lastDisabled || maxPage == 0 }">
				<img src="@/assets/svg/pagination-arrow.svg" />
				<img src="@/assets/svg/pagination-arrow.svg" />
			</li>
		</ul>
		<div class="Table__per-page" v-if="isPerPage">
			<p>
				{{ total }}개 중 {{ total > 0 ? offset + 1 : 0 }} -
				{{ offset + length > total ? total : offset + length }}
			</p>
			<button class="btn Btn__select" :class="{ active: selectOption }" @blur="selectOption = false" @click="selectMainOption">
				<span>{{ length }}개</span>
				<ul class="Form__select-option-list scroll" v-if="selectOption">
					<li class="Form__select-option-item" v-for="item in pageOptionList" :key="item" @click="selectPageOption(item)">{{ item }}개</li>
				</ul>
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
	name: 'pagination',
	props: {
		total: {
			type: Number,
			default: 0,
		},
		isPerPage: {
			type: Boolean,
			default: true,
		},
		notSticky: {
			type: Boolean,
			default: false,
		},
		propsOffset: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			pagination: [],
			current: 1,
			totalPage: 0,
			selectOption: false,
			pageOptionList: [10, 50, 100, 150, 200, 250, 300],
			offset: 0,
			length: 50,
			maxPage: 1,
			fixedCount: 5,
		}
	},
	created() {
		this.init()
		this.togglePaginationOption(false)
	},
	watch: {
		total() {
			this.init()
		},
		propsOffset(value) {
			this.offset = value
			this.init(value / this.length + 1)
		},
	},
	computed: {
		...mapState('pagination', ['showPaginationOption']),
		firstDisabled() {
			return this.current === 1
		},
		prevDisabled() {
			return this.current === 1
		},
		nextDisabled() {
			return this.current >= this.totalPage
		},
		lastDisabled() {
			return this.current >= this.totalPage
		},
	},
	methods: {
		...mapActions('pagination', ['togglePaginationOption']),
		init(current = 1) {
			this.current = current
			this.totalPage = Math.ceil(this.total / this.length)
			if (this.totalPage <= this.fixedCount) {
				this.maxPage = this.totalPage
			} else {
				let pageCount = Math.ceil(this.current / this.fixedCount) * this.fixedCount
				this.maxPage = pageCount <= this.totalPage ? pageCount : this.totalPage
			}
			this.pagination = []
			if (this.maxPage > 0) {
				for (let i = Math.floor((this.current - 1) / this.fixedCount) * this.fixedCount; i < this.maxPage; i++) {
					this.pagination.push(i + 1)
				}
			} else {
				this.pagination = [1]
			}
		},
		changePage(offset, length) {
			this.length = length
			this.offset = offset
			this.init(offset / length + 1)
		},
		currentPage(page) {
			this.current = page
			this.offset = (page - 1) * this.length
			this.$emit('page', { length: this.length, offset: this.offset })
		},
		// 첫번째 페이지로 이동
		firstPage() {
			this.currentPage(1)
			this.current = 1
			this.pagination = []
			this.maxPage = this.totalPage <= this.fixedCount ? this.totalPage : 5
			for (let i = 0; i < this.maxPage; i++) {
				this.pagination.push(i + 1)
			}
		},
		// 이전 페이지로 이동
		prevPage() {
			if (this.current > 1) {
				this.currentPage(this.current - 1)
				if (this.current % this.fixedCount === 0) {
					this.pagination = []
					this.maxPage = this.current
					for (let i = this.maxPage - this.fixedCount; i < this.maxPage; i++) {
						this.pagination.push(i + 1)
					}
				}
			}
		},
		// 다음 페이지로 이동
		nextPage() {
			if (this.current !== this.totalPage) {
				if (this.current % this.fixedCount === 0) {
					this.pagination = []
					this.currentPage(this.current + 1)

					if (this.maxPage + this.fixedCount < this.totalPage) {
						this.maxPage = this.maxPage + this.fixedCount
					} else {
						this.maxPage = this.totalPage
					}
					for (let i = this.current; i <= this.maxPage; i++) {
						this.pagination.push(i)
					}
				} else {
					if (this.current + 1 > this.totalPage) {
						this.currentPage(this.totalPage)
					} else {
						this.currentPage(this.current + 1)
					}
				}
			}
		},
		// 마지막 페이지로 이동
		lastPage() {
			this.currentPage(this.totalPage)
			this.pagination = []
			const totalCount = Math.floor(this.totalPage / this.fixedCount) * this.fixedCount
			if (this.current % 5 === 0) {
				for (let i = totalCount - 5; i < this.current; i++) {
					this.pagination.push(i + 1)
				}
			} else {
				for (let i = totalCount; i < this.current; i++) {
					this.pagination.push(i + 1)
				}
			}
		},
		selectMainOption() {
			this.selectOption = !this.selectOption
			this.togglePaginationOption(this.selectOption)
		},
		selectPageOption(item) {
			this.length = item
			this.offset = 0
			const page = {
				length: this.length,
				offset: 0,
			}
			this.init()
			this.$emit('page', page)
		},
	},
}
</script>

<style lang="scss" scoped>
.Table__pagination {
	.Table__per-page {
		.Btn__select {
			&:focus {
				.Form__select-option-list {
					display: none;
				}
			}
			&.active {
				.Form__select-option-list {
					display: block;
					max-height: unset;
					z-index: 10;
				}
			}
		}
	}
}
</style>
