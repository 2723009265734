<template>
	<form-management
		title="관리정보"
		ref="form-management"
		:categoryInfo="categoryInfo"
		:memberList="getMemberList.members"
		:productInfo="cargoInfo"
		:isManagementValid="isManagementValid"
		:isUnitValid="isManagementUnitValid"
		:isShowError="showError"
		@change="changeFormManagement"
		@removeImg="removeImage"
	></form-management>
</template>
<script>
import FormManagement from '@/views/common/components/form/FormManagement.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	name: 'CargoFormManagement',
	components: { FormManagement },
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('cargo/form', ['categoryInfo', 'cargoInfo', 'isManagementValid', 'isManagementUnitValid', 'showError']),
		...mapGetters('member', ['getMemberList']),
	},
	watch: {},
	created() {
		this.lookupMemberList()
		this.getGhsStatement()
	},
	mounted() {
		this.setTagList(this.cargoInfo?.tagList)
	},
	methods: {
		...mapMutations('cargo/form', ['setCargoInfo']),
		...mapMutations('tag', ['setTagList']),
		...mapActions('member', ['lookupMemberList']),
		...mapActions('modal/ghs', ['getGhsStatement']),
		changeFormManagement(value, fieldKey, items) {
			const newCargo = { ...this.$_.cloneDeep(this.cargoInfo) }
			if (typeof value === 'object') {
				switch (fieldKey) {
					case 'tag':
						newCargo[`${fieldKey}sId`] = value ? value : ''
						break
					case 'storage':
					case 'assignee':
					case 'buyer':
						newCargo[`${fieldKey}Id`] = value.id
						newCargo[fieldKey] = value
						break
					case 'image':
						newCargo['images'] = value
						break
					case 'qrSticker':
						newCargo['qrSticker'] = value ? value : ''
						break
					default:
						if (value?.value) {
							newCargo[`${fieldKey}`] = value.value
						}
						if (value?.unit) {
							newCargo[`${fieldKey}Unit`] = value.unit
						}
						break
				}
				this.setCargoInfo(newCargo)
			} else {
				newCargo[fieldKey] = value
				this.setCargoInfo(newCargo)
			}
		},
		removeImage(removeImageLink) {
			const newCargo = { ...this.cargoInfo }
			newCargo['deleteImages'] = removeImageLink
			this.setCargoInfo(newCargo)
		},
		showRequire() {
			this.$refs['form-management'].showRequire()
		},
	},
}
</script>

<style lang="scss" scoped></style>
