var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('migration-header', {
    attrs: {
      "isMigration": true,
      "text": _vm.headerText
    }
  }), _c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h5', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.titleText)
    }
  }), _c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })]), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    staticClass: "institution-name-input",
    attrs: {
      "placeholder": _vm.inputPlaceholder
    },
    domProps: {
      "value": _vm.instituteName
    },
    on: {
      "keyup": _vm.changeInstituteName,
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.isAnyNameInput ? _vm.routeMigrationMemberList() : '';
      }
    }
  })]), _c('migration-button-area', {
    attrs: {
      "nextDisable": !_vm.isAnyNameInput
    },
    on: {
      "back": _vm.routeMigrationSelectLab,
      "next": function next($event) {
        _vm.isAnyNameInput ? _vm.routeMigrationMemberList() : '';
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }