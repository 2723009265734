var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-requirement-authentication"
  }, [_c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('화학물질관리법 준수사항으로 [랩매니저 스토어]는 유해화학물질 <br />통신 판매 시 구매자의 실명, 연령 확인 및 본인인증을 진행합니다'))
    }
  }), !_vm.isVerified ? _c('div', [_c('div', {
    staticClass: "authentication"
  }, [_c('button', {
    staticClass: "btn btn-white lg",
    "class": !_vm.isValid ? 'invalid' : null,
    on: {
      "click": _vm.onAuthentication
    }
  }, [_vm._v(_vm._s(_vm.tt('휴대폰 본인인증')))])])]) : _c('div', [_c('div', {
    staticClass: "authentication"
  }, [_c('div', {
    staticClass: "complete"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.tt('본인인증 완료')))]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/payment/requirement/check_white.svg"),
      "alt": ""
    }
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }