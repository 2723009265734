import axiosInstance from '@/services/axios'

const endPoint = {
	lookupPreviousEbillInfo: instId => `/institute/${instId}/payment/ebill/previous/info`,
	createPaymentEbill: instId => `/institute/${instId}/payment/ebill`,
	registPaymentCard: instId => `/institute/${instId}/payment/card`,
	lookupPaymentCard: instId => `/institute/${instId}/payment/card/list`,
	lookupPaymentDefaultCard: instId => `/institute/${instId}/payment/card/default`,
	deletePaymentCard: instId => `/institute/${instId}/payment/card`,

	ciPrevious: instId => `/institute/${instId}/payment/ci/previous/info`,
	ci: instId => `/institute/${instId}/payment/ci`,
	ciDownload: instId => `/institute/${instId}/payment/ci/download`,
}

const setParams = params => {
	return { params: params }
}

const lookupPreviousEbillInfo = instId => axiosInstance.get(endPoint.lookupPreviousEbillInfo(instId))
const createPaymentEbill = (instId, params) => axiosInstance.post(endPoint.createPaymentEbill(instId), params)
const registPaymentCard = (instId, params) => axiosInstance.post(endPoint.registPaymentCard(instId), params)
const lookupPaymentCard = instId => axiosInstance.get(endPoint.lookupPaymentCard(instId))
const lookupPaymentDefaultCard = instId => axiosInstance.get(endPoint.lookupPaymentDefaultCard(instId))
const deletePaymentCard = (instId, params) => axiosInstance.delete(endPoint.deletePaymentCard(instId), setParams(params))

const getPreviousCi = instituteId => axiosInstance.get(endPoint.ciPrevious(instituteId))
const getCi = (instituteId, id) => axiosInstance.get(endPoint.ci(instituteId), { params: { id } })
const downloadCi = (instituteId, body) => axiosInstance.post(endPoint.ciDownload(instituteId), body)

export {
	lookupPreviousEbillInfo,
	createPaymentEbill,
	registPaymentCard,
	lookupPaymentCard,
	lookupPaymentDefaultCard,
	deletePaymentCard,
	getPreviousCi,
	getCi,
	downloadCi,
}
