import locationAPI from '@/services/api/institute/location'
import storageAPI from '@/services/api/institute/storage'

const Location = {
	namespaced: true,
	state: () => ({
		locationTree: undefined,
		selectedLocation: undefined,
		selectedLocationId: undefined,
		locationStorageTree: null,
	}),
	getters: {
		getLocationTree(state) {
			return state.locationTree
		},
		getSelectedLocation(state) {
			return state.selectedLocation
		},
		locationStorageTree(state) {
			return state.locationStorageTree
		},
	},
	mutations: {
		setLocationTree(state, payload) {
			state.locationTree = payload
		},
		setSelectedLocation(state, payload) {
			state.selectedLocation = payload
		},
		setSelectedLocationId(state, payload) {
			state.selectedLocationId = payload
		},
		setLocationStorageTree(state, locationStorageTree) {
			state.locationStorageTree = locationStorageTree
		},
	},
	actions: {
		async loadLocationTree({ commit }, institutionId) {
			const response = await locationAPI.lookupLocationTree(institutionId)
			commit('setLocationTree', response.data)
		},
		async loadLocationTreeV2({ commit }, institutionId) {
			return new Promise(async (resolve, reject) => {
				try {
					const response = await locationAPI.lookupLocationTree(institutionId)
					commit('setLocationTree', response.data)
					resolve(response)
				} catch (error) {
					reject(error)
				}
			})
		},
		async loadLocationDetail({ commit }, payload) {
			const { institutionId, locationId } = payload
			const response = await locationAPI.lookupLocationDetail(institutionId, locationId)
			commit('setSelectedLocationId', locationId)
			commit('setSelectedLocation', response.data)
		},
		lookupTotalStorageTree: async ({ commit }, instId) => {
			return await storageAPI.lookupTotalStorageTree(instId)
		},
		lookupTotalLocationTree: async ({ commit }, instId) => {
			return await locationAPI.lookupLocationTree(instId)
		},
		createLocation: async ({ commit }, payload) => {
			const { instId, body } = payload
			await locationAPI.createLocation(instId, body)
		},
		getLocationStorageTree: async ({ commit, rootGetters }) => {
			const response = await locationAPI.lookupLocationStorageTree(rootGetters.instituteId)
			commit('setLocationStorageTree', response.data)
		},
	},
}
export default Location
