import { MiddleClassToString } from '@/utils/define/ItemCode'
import { DateTime } from 'luxon'
import { Check } from '@/utils/define/Translation'
import Decimal from 'decimal.js'
import { mapState } from 'vuex'
import StringUtils from '@/utils/modules/StringUtils'
export default {
	data() {
		return {
			nullText: '-',
		}
	},
	computed: {
		...mapState('user', ['user']),
		isNew() {
			let clickedList = JSON.parse(this.$cookies.get(`${this.$route.name}_clickedItem`)) ?? []
			let isClick = clickedList.some(it => it.id == this.item.id)
			return (this.item.isNew || this.$DateTime.now().minus({ days: 1 }) <= this.$DateTime.fromISO(this.item.createDatetime)) && !isClick
		},
		createDatetime() {
			if (!this.item.createDatetime) return null
			return this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy.MM.dd T')
		},
		expireDatetime() {
			if (!this.item.expireDatetime) return null
			return this.$DateTime.fromISO(this.item.expireDatetime).toFormat('yyyy.MM.dd T')
		},
		updateDatetime() {
			if (!this.item.updateDatetime) return null
			return this.$DateTime.fromISO(this.item.updateDatetime).toFormat('yyyy.MM.dd T')
		},
		defaultUnCheck() {
			return ['categoryClassification', 'categoryType']
		},
	},
	methods: {
		formatText(item) {
			return item ? item : this.nullText
		},
		formatBrand(brand) {
			return brand ? brand.name : this.nullText
		},
		formatStorage(storage) {
			return storage ? storage.pathName : this.nullText
		},
		formatCategory(category) {
			return `${MiddleClassToString(category)}/${category.name}`
		},
		formatNumber(number) {
			const volume =
				number > 0 && number < 1 ? Decimal(number).toFixed() : Number(number).toLocaleString(undefined, { maximumFractionDigits: 10 })
			return volume
		},
		formatVolume(value, unit) {
			if (typeof value == 'number') {
				const volume = value >= 0 && value < 1 ? Decimal(value).toFixed() : Number(value).toLocaleString()
				return value != null ? `${volume}${this.tt(unit) || ''}` : this.nullText
			} else {
				return value != null ? `${value}${this.tt(unit) || ''}` : this.nullText
			}
		},
		fromISO(iso) {
			return DateTime.fromISO(iso)
		},
		formatDateFromISO(iso, format = 'MM-dd') {
			return iso ? this.fromISO(iso).toFormat(format) : this.nullText
		},
		formatDatetimeFromISO(iso, format = 'yyyy-MM-dd HH:mm') {
			return iso ? this.fromISO(iso).toFormat(format) : this.nullText
		},
		getWeekDayFromISO(iso) {
			const weekday = this.fromISO(iso).weekday - 1
			if (Check.English) {
				return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][weekday]
			} else {
				return ['월', '화', '수', '목', '금', '토', '일'][weekday]
			}
		},
		formatProfileImg(img) {
			return img ? img : require('@/assets/svg/icon/icon-no-member-img.svg')
		},
		isView(type) {
			return (this.checkedViewOption.length == 0 && !this.defaultUnCheck.includes(type)) || this.checkedViewOption.includes(type)
		},
		isEmpty(value) {
			return value ?? '-'
		},
		formatNumberLocale(num) {
			let numVal = Number(num ?? 0)
			if (typeof numVal === 'number') return numVal.toLocaleString()
			else return num
		},
		financial(num) {
			if (num != null) {
				const decimal = new Decimal(num)
				return decimal.toFixed()
			}
		},
		setNew() {
			let clickedList = JSON.parse(this.$cookies.get(`${this.$route.name}_clickedItem`)) ?? []
			if (this.isNew && !clickedList.some(it => it.id == this.item.id)) {
				clickedList.push({
					id: this.item.id,
					datetime: this.item.createDatetime,
				})
				this.$cookies.set(`${this.$route.name}_clickedItem`, JSON.stringify(clickedList), '1d')
			}
		},
		replaceHighlight(value) {
			return value ? StringUtils.replaceHighlight(value, this.keyword ?? '', 'span', 'highlight') : '-'
		},
	},
}
