var render = function () {
  var _vm$item$virtualAccou, _vm$item$virtualAccou2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', [_vm._v(_vm._s(_vm.formatDatetime(_vm.item.createDatetime, false)))]), _c('td', [_vm._v(_vm._s(_vm.item.quantity == _vm.MAX_QUANTITY ? _vm.tt('무제한') : _vm.formatNumberLocale(_vm.item.quantity)))]), _c('td', [_c('p', [_vm._v(_vm._s(_vm.formatNumberLocale(_vm.item.periodDaysCount) + _vm.tt('일')))]), _c('span', [_vm._v(_vm._s("(".concat(_vm.item.period, ")")))])]), _c('td', [_vm._v(_vm._s("\u20A9".concat(_vm.formatNumberLocale(_vm.item.totalPrice))))]), _c('td', [_vm._v(_vm._s(_vm.item.type == 'FREE_TIER' ? '프리티어' : _vm.paymentMethod2Text(_vm.item.paymentMethod, _vm.isAdditionItem)))]), _c('td', [_vm.isPaymentEbill && _vm.isPaymentWait ? _c('div', {
    staticClass: "payment-status warning"
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.tt('입금대기')) + " "), _c('margin-span', {
    attrs: {
      "styleObj": {
        marginRight: '3px'
      }
    }
  }), _c('lm-ballon', {
    attrs: {
      "title": _vm.tt('입금대기'),
      "content": _vm.tt("".concat(_vm.item.virtualAccount.bankName, " ").concat(_vm.item.virtualAccount.number, " (\uC608\uAE08\uC8FC: ").concat(_vm.item.virtualAccount.holder, ")<br>\uACB0\uC81C\uAE08\uC561: \uFFE6").concat(_vm.formatNumberLocale(_vm.item.totalPrice))),
      "balloonStyle": {
        width: '340px'
      }
    }
  })], 1), _c('span', [_vm._v(_vm._s("".concat(_vm.tt('입금기한'), ": ").concat(_vm.formatDatetime(_vm.item.virtualAccount.expireDatetime))))])]) : _vm._e(), _c('div', [_vm.isPaymentCancel ? _c('div', {
    staticClass: "payment-status warning"
  }, [_c('b', [_vm._v(_vm._s(_vm.tt('주문취소')))])]) : _vm._e(), _vm.isPaymentSuccess ? _c('div', {
    staticClass: "payment-status"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('결제 완료')))])]) : _vm._e(), _vm.isPaymentFail ? _c('div', {
    staticClass: "payment-status"
  }, [_c('p', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.item.memo,
      expression: "item.memo",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "underline"
  }, [_vm._v(_vm._s(_vm.tt('결제 실패')))])]) : _vm._e()])]), _c('td', [_c('div', {
    staticClass: "download-link"
  }, [_vm.isPaymentEbill && !_vm.isPaymentCancel && (_vm.item.receiptUrl || (_vm$item$virtualAccou = _vm.item.virtualAccount) !== null && _vm$item$virtualAccou !== void 0 && _vm$item$virtualAccou.confirmationUrl) ? [_c('a', {
    attrs: {
      "download": "",
      "href": _vm.item.receiptUrl
    }
  }, [_vm._v(_vm._s(_vm.tt('전자세금계산서')))]), _c('span', [_vm._v("/")]), _c('a', {
    attrs: {
      "download": "",
      "href": (_vm$item$virtualAccou2 = _vm.item.virtualAccount) === null || _vm$item$virtualAccou2 === void 0 ? void 0 : _vm$item$virtualAccou2.confirmationUrl
    }
  }, [_vm._v(_vm._s(_vm.tt('가상계좌발급서')))]), _c('span', [_vm._v("/")]), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('showInvoice');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('거래명세서')))])] : _vm.isPaymentEbill && _vm.isPaymentCancel ? [_vm.item.cancelReceiptUrl ? _c('a', {
    attrs: {
      "download": "",
      "href": _vm.item.cancelReceiptUrl
    }
  }, [_vm._v(_vm._s(_vm.tt('(취소)전자세금계산서')))]) : _vm._e(), _vm.item.cancelReceiptUrl && _vm.item.receiptUrl ? _c('span', [_vm._v("/")]) : _vm._e(), _vm.item.receiptUrl ? _c('a', {
    attrs: {
      "download": "",
      "href": _vm.item.receiptUrl
    }
  }, [_vm._v(_vm._s(_vm.tt('(구매)전자세금계산서')))]) : _vm._e()] : !_vm.isPaymentEbill && _vm.item.receiptUrl ? [_c('a', {
    attrs: {
      "download": "",
      "href": _vm.item.receiptUrl
    }
  }, [_vm._v(_vm._s(_vm.tt('영수증')))]), _c('span', [_vm._v("/")]), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('showInvoice');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('거래명세서')))])] : [_vm._v("-")]], 2)]), _c('td', [_vm._v(" " + _vm._s(_vm.item.payDate) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }