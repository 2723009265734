var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-wrapper"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.tt('수령자 정보')) + " "), _c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.check,
      expression: "check"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.check) ? _vm._i(_vm.check, null) > -1 : _vm.check
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.check,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.check = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.check = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.check = $$c;
        }
      }
    }
  }), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm.tt('주문자 정보와 동일')))])])]), _c('div', {
    staticClass: "order-user-wrapper"
  }, [_c('input-text', {
    ref: "recipientName",
    attrs: {
      "isRequired": "",
      "title": "이름",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "placeholder": "이름을 입력해주세요",
      "inputValue": _vm.$root.user.name,
      "inValidText": '이름을 입력해주세요',
      "isValid": !_vm.isValid || !!_vm.$refs['recipientName'].value
    }
  }), _c('input-text', {
    ref: "recipientPhone",
    attrs: {
      "isRequired": "",
      "title": "연락처",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "inputValue": _vm.$root.user.phone,
      "placeholder": "연락처를 입력해주세요",
      "inValidText": '연락처를 입력해주세요',
      "isValid": !_vm.isValid || !!_vm.$refs['recipientPhone'].value
    }
  }), _c('input-text', {
    ref: "deliveryRequest",
    attrs: {
      "title": "배송요청사항",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "placeholder": "최대 50자 입력이 가능합니다"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }