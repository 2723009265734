<template>
	<b-modal ref="modal" centered hide-footer no-close-on-backdrop :style="{ '--overflow': loading ? 'hidden' : 'auto' }">
		<template #modal-title>
			<div class="title">{{ tt('GHS 라벨') }}</div>
			<div class="button-area">
				<button class="button-gray" @click="printGHS">
					<img src="@/assets/svg/icon-print.svg" width="24" />
					{{ tt('인쇄') }}
				</button>
				<button class="button-gray" @click="download">
					<img src="@/views/common/components/modalGen2/svg/icon-file-download.svg" width="24" />
					{{ tt('다운로드') }}
				</button>
				<button class="button-gray" @click="$emit('edit')">
					<img src="@/views/common/components/modalGen2/svg/icon-edit.svg" width="24" />
					{{ tt('편집') }}
				</button>
				<button class="button-gray" @click="$emit('delete')">
					<img src="@/views/common/components/modalGen2/svg/icon-round-delete.svg" width="24" />
					{{ tt('삭제') }}
				</button>
			</div>
		</template>
		<template>
			<ghs-label :volume="normalizedVolume"></ghs-label>
			<div id="exportDiv" hidden></div>
		</template>
	</b-modal>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import GhsLabel from './GHSLabel.vue'
import html2canvas from 'html2canvas'
import html2pdf from 'html2pdf.js'

export default {
	name: 'ModalGHSLabel',
	components: { GhsLabel },
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			count: 0,
		}
	},
	computed: {
		...mapGetters('modal/ghs', [
			'ghsPictogram',
			'previewData',
			'selectedPrecautionCode',
			'signalText',
			'hCodeText',
			'pCodeText',
			'provider',
			'normalizedVolume',
		]),
		removeAllBlank() {
			return str => str.replaceAll(/\s/g, '')
		},
		labelType() {
			if (this.normalizedVolume) {
				const volume = this.normalizedVolume
				if (volume >= 500000) {
					return '3130A'
				} else if (volume < 500000 && volume >= 200000) {
					return '3130B'
				} else if (volume < 200000 && volume >= 50000) {
					return '3120'
				} else if (volume < 50000 && volume >= 5000) {
					return '3118'
				} else {
					return '3114'
				}
			} else {
				return null
			}
		},
		isLandscape() {
			if (this.labelType) {
				switch (this.labelType) {
					case '3130A':
					case '3130B':
					case '3118':
						return true
					case '3120':
					case '3114':
					default:
						return false
				}
			} else {
				return null
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('modal/printSticker', ['getGhsPreview']),
		...mapMutations('modal/printSticker', ['setType']),
		async show() {
			this.setType('inventory')
			this.$refs['modal'].show()
			if (this.item) {
				await this.getGhsPreview([this.item.id || this.item.inventoryId])
			}
		},
		hide() {
			this.$refs['modal'].hide()
		},
		printGHS() {
			this.print()
		},
		setExportElement(imgUrl, width, height) {
			const imgEl = new Image()
			imgEl.src = imgUrl
			imgEl.style = `width: ${width}; height: ${height}; margin: 20px;`
			imgEl.id = 'exportImage'
			document.querySelector('#exportDiv').appendChild(imgEl)
		},
		async print() {
			const targetEl = document.querySelector('#ghsContent')
			let newWin = window.open('')
			let printHtml =
				targetEl.innerHTML +
				`<style type="text/css">@media print{@page{ padding: 0; margin: 0; size: ${this.isLandscape ? 'landscape;' : 'portrait;'}; }}</style>`
			newWin.document.write(printHtml)
			newWin.print()
		},
		async download() {
			const element = document.querySelector('#ghsContent')
			let option = {
				filename: `GHS_${this.previewData.name}.pdf`,
				image: { type: 'jpg', quality: 0.95 },
				html2canvas: {
					scale: 3,
					dpi: 192,
				},
				jsPDF: { orientation: this.isLandscape ? 'landscape' : 'portrait' },
			}
			html2pdf(element, option)
		},
		async createGHSImageUrl(elementId) {
			const canvas = await html2canvas(document.querySelector(elementId), { scale: 3 })
			return canvas.toDataURL('image/png')
		},
		cancelLoading() {
			this.loading = false
		},
	},
}
</script>

<style lang="scss" scoped>
.counter {
	position: absolute;
	width: 100%;
	height: 400px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.loading {
	display: flex;
	position: absolute;
	width: 100%;
	height: 600px;
	top: 10px;
	z-index: 100;
}
.Form__wrap {
	padding: 10px;
	background: white;
	border-radius: 10px;
	width: fit-content;
}

.ghs-wrap {
	width: 100%;
	min-width: 640px;
	padding: 0px;
	border: 1px solid black;
	border-radius: 10px;
	font-size: 9pt;
	background-color: $LAB_WHITE;
}

.text-container {
	border-bottom: 1px solid black;
	&:last-child {
		border-bottom: none;
	}
	padding: 10px;
	min-height: 100px;
	overflow: hidden;
	position: relative;
	&.provider {
		display: flex;
		min-height: 80px;
		height: 80px;
		justify-content: space-between;
		.left-container {
			width: calc(100% - 80px);
		}
		.qr-container {
			position: absolute;
			top: 0px;
			right: 0px;
			width: 80px;
			height: 80px;
			border-left: 1px solid black;
		}
	}
	.subtitle {
		font-size: 12px;
		font-weight: 700;
		padding-bottom: 2px;
	}
	.text {
		max-width: 640px;
		font-size: 10px;
		font-weight: 400;
		overflow-wrap: break-word;
		&.red {
			color: $RED;
		}
	}
}

.provider-info {
	padding: 10px;
	height: 85px;
}

::v-deep .modal-header {
	padding: 20px;
	background: white;
	box-shadow: 0px 1px 2px 0px #0000001a;

	.title {
		font-size: 18px;
		font-weight: 700;
	}
	.button-area {
		display: flex;
		height: 50px;
		gap: 10px;
		padding: 24px 0px 20px 0px;
	}
}

.button-gray {
	top: 20px;
	right: 65px;
	background: $GREY_4;
	display: flex;
	border-radius: 4px;
	padding: 5px 10px;
	height: 30px;
	font-size: 14px;
	font-weight: 700;
	&:hover {
		background: $HOVER_BUTTON_COMMON;
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 1000px;
	.modal-content {
		background-color: $LAB_WHITE;
		border: 0;
	}
}

::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 0;
	padding: 0px;
	height: 600px;
	overflow: auto;
	align-items: center;
	background: $SYSTEM_BACKGROUND;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	.content-wrap {
		width: 100%;
		padding: 20px;
		background: $SYSTEM_BACKGROUND;
		overflow: auto;
		max-height: 560px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		display: flex;
		justify-content: center;
	}
	.content {
		height: 100%;
		border-top: 1px solid $DIVIDER;
		h4 {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 8px;
		}
		.text {
			font-size: 14px;
		}
	}
}
</style>
