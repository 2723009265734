var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card MsdsUpload",
    "class": [{
      warning: _vm.list.nonUploadCount > 0
    }, {
      safe: _vm.list.nonUploadCount < 1
    }, {
      displayOff: !_vm.displayCard
    }]
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._m(0), _c('h4', {
    staticClass: "Btn__hover-balloon-w",
    on: {
      "click": function click($event) {
        return _vm.goPage();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('MSDS 등록 현황')) + " "), _c('div', {
    staticClass: "balloon"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('MSDS 페이지로 이동합니다')))])]), _vm._m(1)]), _vm.setting == false ? _c('h3', {
    staticClass: "ml-auto",
    domProps: {
      "textContent": _vm._s(_vm.list.nonUploadCount > 0 ? _vm.list.nonUploadCount + _vm.tt('종') + '\n' + _vm.tt('업로드 필요') : _vm.tt('안전'))
    }
  }) : _c('div', {
    staticClass: "ml-auto switch-setting"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('대시보드에 노출')))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.displayCard,
      expression: "displayCard"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.displayCard) ? _vm._i(_vm.displayCard, null) > -1 : _vm.displayCard
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.displayCard,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.displayCard = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.displayCard = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.displayCard = $$c;
        }
      }, function ($event) {
        return _vm.toggleCard(_vm.id, _vm.displayCard);
      }]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('div', {
    staticClass: "card-noti"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('MSDS 관리')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("\uD654\uD559\uBB3C\uC9C8 \uC911\uC5D0\uC11C <b>\uBE0C\uB79C\uB4DC</b>\uC640 <b>\uC81C\uD488\uBC88\uD638</b>\uAC00 \uAC19\uC740 \uACBD\uC6B0 <b>\uB3D9\uC77C\uD55C \uC885\uB958</b>\uB85C \uCDE8\uAE09\uD569\uB2C8\uB2E4.<br>MSDS\uB294 \uD654\uD559\uBB3C\uC9C8 \uC885\uB958 \uB2E8\uC704\uB85C \uB4F1\uB85D\uC774 \uD544\uC694\uD569\uB2C8\uB2E4."))
    }
  })]), _c('div', {
    staticClass: "card-box"
  }, [_c('div', {
    staticClass: "box-title"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('화학물질 MSDS 등록 현황')))])]), _c('div', {
    staticClass: "box-content"
  }, [_vm.list.allCount == 0 ? _c('div', {
    staticClass: "chart-wrap no-data-chart-wrap"
  }, [_c('div', {
    staticClass: "no-data_chart"
  }, [_c('div', {
    staticClass: "balloon"
  }, [_c('span'), _c('p', [_vm._v(_vm._s(_vm.tt('등록된 MSDS')))]), _c('b', [_vm._v("0" + _vm._s(_vm.tt('종')) + " 0%")])])]), _c('div', {
    staticClass: "chart-title"
  }, [_c('p', {
    staticStyle: {
      "width": "100px",
      "word-break": "keep-all"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.tt('MSDS를 관리해야 할 물질이 없습니다'))
    }
  })])]) : _c('div', {
    staticClass: "chart-box"
  }, [_c('GChart', {
    ref: "chart",
    attrs: {
      "type": "PieChart",
      "data": _vm.chartData,
      "options": _vm.chartOptions,
      "events": _vm.chartEvents
    }
  }), _c('div', {
    staticClass: "chart-title"
  }, [_vm.list.allCount > 0 ? _c('h2', {
    staticStyle: {
      "color": "#0dc577"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.uploadPercent) + "%")])]) : _c('p', {
    staticStyle: {
      "font-size": "0.75rem",
      "color": "#000",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s('MSDS를 관리해야 할 물질이 없습니다') + " ")])])], 1), _c('div', {
    staticClass: "chart-list"
  }, [_c('ul', [_c('li', {
    on: {
      "click": function click($event) {
        return _vm.goPage(0);
      }
    }
  }, [_c('div', {
    staticClass: "circle green"
  }), _c('p', [_vm._v(_vm._s(_vm.tt('MSDS 등록된 화학물질')))]), _c('h5', [_vm._v(_vm._s(_vm.uploadedCount) + _vm._s(_vm.tt('종')))])]), _c('li', {
    on: {
      "click": function click($event) {
        return _vm.goPage(1);
      }
    }
  }, [_c('div', {
    staticClass: "circle palewgreen"
  }), _c('p', [_vm._v(_vm._s(_vm.tt('MSDS 업로드가 필요한 화학물질')))]), _c('h5', [_vm._v(_vm._s(_vm.list.nonUploadCount) + _vm._s(_vm.tt('종')))])]), _c('li', {
    staticClass: "total"
  }, [_c('p', [_vm._v("Total:")]), _c('h5', [_vm._v(_vm._s(_vm.list.allCount) + _vm._s(_vm.tt('종')))])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/dashboard-icon4.svg"),
      "alt": "icon"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow-diagonal.svg"),
      "alt": "icon"
    }
  })]);
}]

export { render, staticRenderFns }