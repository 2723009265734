var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.selectedItems.length > 0 ? _c('div', {
    staticClass: "Menu__floating"
  }, [_c('h4', [_c('span', {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.tt('${1}개', _vm.selectedItems.length)))]), _vm._v(" " + _vm._s(_vm.tt('선택')) + " ")]), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-restore'].show();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Restore.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('복구')))])]), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-remove'].show();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Trash.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('영구삭제')))])])]), _c('modal-confirm', {
    ref: "modal-restore",
    attrs: {
      "title": "물품 복구",
      "confirmText": "복구",
      "variant": "primary",
      "content": "\uC120\uD0DD\uB41C ".concat(_vm.selectedItems.length, "\uAC1C \uBB3C\uD488\uC744 \uBCF5\uAD6C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?")
    },
    on: {
      "confirm": _vm.restore
    }
  }), _c('modal-confirm', {
    ref: "modal-remove",
    attrs: {
      "title": "물품 영구삭제",
      "confirmText": "영구삭제",
      "variant": "warning",
      "content": "\uC120\uD0DD\uB41C ".concat(_vm.selectedItems.length, "\uAC1C \uBB3C\uD488\uC744 \uC601\uAD6C\uC801\uC73C\uB85C \uC0AD\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?")
    },
    on: {
      "confirm": _vm.remove
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }