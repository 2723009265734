var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "size": "xl",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('구매내역 조회')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('닫기')) + " ")])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', [_c('thead', [_c('tr', _vm._l(_vm.headerList, function (item, index) {
    return _c('th', {
      key: 'history' + index
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]), _c('tbody', _vm._l(_vm.getLicenseHistory, function (orderItem) {
    return _c('modal-order-history-item', {
      key: orderItem.id,
      attrs: {
        "item": orderItem
      },
      on: {
        "showInvoice": function showInvoice($event) {
          return _vm.showInovice(orderItem.ciId, orderItem.id);
        }
      }
    });
  }), 1)])])], _c('ModalDownloadInvoice', {
    ref: "ModalDownloadInvoice"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }