var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-field-wrap"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.fieldName)))]), _vm.isRequire && _vm.isData == null ? _c('div', {
    staticClass: "field-data-wrap-red"
  }, [_vm._v(_vm._s(_vm.tt('미입력')))]) : _vm.isData ? _c('div', {
    staticClass: "field-image-wrap"
  }, _vm._l(_vm.image, function (item, idx) {
    return _c('img', {
      key: item.id,
      staticClass: "field-image",
      attrs: {
        "src": item.link
      },
      on: {
        "click": function click($event) {
          return _vm.showImage(item.link, idx);
        }
      }
    });
  }), 0) : _c('div', {
    staticClass: "field-image-wrap"
  }, [_c('div', {
    staticClass: "field-text"
  }, [_vm._v("-")])]), _c('modal-multi-image-viewer', {
    ref: "image-viewer",
    attrs: {
      "title": "이미지",
      "imageList": _vm.image.map(function (i) {
        return i.link;
      }),
      "isCaption": false
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }