var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.tt(_vm.mode == 'REGISTER' ? '결제카드 등록' : '결제카드 변경')) + " ")];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('button', {
          staticClass: "Btn__yellow",
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.mode == 'REGIST' ? '등록 및 결제하기' : '변경하기')) + " ")])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "input-wrapper"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('결제수단')))]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('카드번호'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    ref: "input-cardnum",
    "class": {
      'on-input': _vm.cardInput,
      error: _vm.cardNoError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('14 ~ 16자리 번호를 입력해주세요'),
      "maxlength": _vm.cardNumberMaxLength,
      "readonly": ""
    },
    domProps: {
      "value": _vm.cardNumber
    },
    on: {
      "focus": function focus($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.cardInput = true;
      },
      "click": function click($event) {
        $event.stopPropagation();
        _vm.cardInput = true;
      }
    }
  }), _vm.cardNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('유효하지 않은 카드번호입니다.')) + " ")])]) : _vm._e(), _vm.cardInput ? _c('card-number-keyboard', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.showKeypad,
      expression: "showKeypad"
    }],
    on: {
      "add": _vm.addNumber,
      "remove": _vm.removeInputNumber
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('유효기간(MM/YY)')) + " "), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.expirationError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('MM/YY'),
      "maxlength": "5"
    },
    domProps: {
      "value": _vm.form.expirationMonthYear
    },
    on: {
      "input": _vm.inputExpirationMonthYear
    }
  }), _vm.expirationError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('잘못된 유효기간입니다.')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('비밀번호 앞 2자리')) + " "), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.passwordError
    },
    attrs: {
      "type": "password",
      "placeholder": _vm.tt('비밀번호 앞 2자리를 입력해주세요'),
      "maxlength": "2"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": _vm.inputPassword
    }
  }), _vm.passwordError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('잘못된 비밀번호입니다.')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('생년월일/사업자등록번호'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.registrationNoError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('생년월일(6자리) 혹은 사업자 등록번호(10자리)를 입력해주세요'),
      "maxlength": "12"
    },
    domProps: {
      "value": _vm.form.registrationNumber
    },
    on: {
      "input": _vm.inputRegistrationNumber
    }
  }), _vm.registrationNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('잘못된 생년월일 또는 사업자등록번호입니다.')) + " ")])]) : _vm._e()]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('개인카드 혹은 기명 법인카드인 경우 생년월일 6자리를 입력해주세요.<br>무기명 법인카드인 경우 사업자등록번호 10자리를 입력해주세요.'))
    }
  }), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('연락처'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.phoneNoError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('연락처를 입력해주세요'),
      "maxlength": "13"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": _vm.autoHypenTel
    }
  }), _vm.phoneNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연락처를 형식에 맞게 입력해주세요')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('이메일'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    "class": {
      error: _vm.emailNoError
    },
    attrs: {
      "type": "email",
      "placeholder": _vm.tt('이메일 입력해주세요')
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _vm.emailNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('이메일을 형식에 맞게 입력해주세요')) + " ")])]) : _vm._e()])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }