<template>
	<tr class="table-item" @click="$emit('click', item)">
		<td>
			<div class="info-wrap">
				<p class="info-title">
					<span class="has-item" v-if="item.isInventory">{{ tt('(Registererd)') }}</span> {{ item.name }}
				</p>
				<p class="info-sub" v-html="formatText(item.subname)"></p>
			</div>
		</td>
		<td v-if="isChemical">
			<div v-html="formatText(item.casno)"></div>
		</td>
		<td>
			<div v-html="formatText(item.productCode)"></div>
		</td>
		<td>
			<div class="brand-wrap">
				<img v-if="item.brand && item.brand.image" width="70" height="30" :src="item.brand.image" />
				<span v-html="formatBrand(item.brand)"></span>
			</div>
		</td>
		<td v-if="isChemical">
			<div v-if="item.volumes && item.volumes.length > 0">
				<span :id="`tooltip-target-${item.id}`" class="hover">{{ tt('${1} 종류', item.volumes.length) }}</span>
				<b-tooltip :target="`tooltip-target-${item.id}`" triggers="hover">
					<p class="volume" v-for="volume in item.volumes" :key="volume">{{ volume }}</p>
				</b-tooltip>
			</div>
			<div v-else v-html="nullText"></div>
		</td>
	</tr>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
export default {
	name: 'SearchProductItem',
	mixins: [MixinItem],
	components: {},
	props: {
		item: {
			type: Object,
			default: null,
		},
		isChemical: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			nullText: `<span class="nodata">${this.tt('정보없음')}</span>`,
		}
	},
	computed: {
		volumeCounts() {
			if (this.item.volumes.length > 0) return ``
			return this.nullText
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
::v-deep .nodata,
::v-deep {
	.volume {
		width: 100px;
		text-align: left;
		margin-bottom: 4px;
	}
}
.has-item {
	color: $PLACEHOLDER;
}

.table-item {
	border-bottom: 1px solid #fcfcfc;
	cursor: pointer;
	background: white;
	z-index: 10;
	&:hover {
		background: $GREY_1;
		transition: 0.5s all ease;
	}
	td {
		padding: 10px 10px;
		border-bottom: 1px solid $DIVIDER;
		height: 60px;
		&:first-of-type {
			padding-left: 40px;
		}
		& > div {
			display: flex;
			align-items: center;
			height: 100%;
			font-size: 14px;
			font-weight: 400;
			> p {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding: 20px 0px;
			}
			.hover {
				color: $BLUE;
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.info-wrap {
			display: block;
			.info-title {
				width: 250px;
				font-weight: 700;
				font-size: 14px;
				padding: 0px !important;
			}
			.info-sub {
				width: 250px;
				font-size: 12px;
				color: $SECONDARY_2;
				padding: 0px !important;
			}
		}
	}
}
</style>
