<template>
	<div class="Modal ModalCompleteTransfer" v-if="isShow">
		<div class="Popup__background"></div>
		<div class="Modal__container">
			<div class="Modal__content">
				<div class="imgbox">
					<img src="@/assets/images/check.png" />
				</div>
				<h2>{{ tt('랩매니저 PRO 이전 완료') }}</h2>
				<p v-html="tt('랩매니저 PRO로 이전이 완료되었습니다.<br/> 지금 랩매니저 PRO의 새로운 기능을 경험해 보세요!')"></p>
				<div class="btn-wrap">
					<button class="Btn__yellow" @click="start">
						{{ tt('랩매니저 PRO 시작하기') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { requestFirstVisit } from '@/services/api/migration'

export default {
	data() {
		return {
			isShow: true,
		}
	},
	methods: {
		async start() {
			await requestFirstVisit()
			this.isShow = false
		},
		goToGuide() {},
	},
}
</script>

<style lang="scss" scoped>
.ModalCompleteTransfer {
	.Modal__container {
		border-radius: 16px;
		.Modal__content {
			padding: 50px;
			img {
				width: 400px;
			}
			h2 {
				margin-top: 30px;
				font-size: 2rem;
				font-weight: 700;
			}
			p {
				margin-top: 10px;
				font-size: 0.875rem;
				font-weight: 400;
				line-height: 1.5;
			}
			.btn-wrap {
				margin-top: 40px;
				display: block;
				text-align: center;
				button {
					height: 48px;
					&.Btn__yellow {
						font-size: 1rem;
						font-weight: 700;
						padding: 0 20px;
					}
					&.guide {
						font-size: 0.875rem;
						font-weight: 700;
						color: $SECONDARY_2;
						text-decoration: underline;
						margin: 10px auto 0;
					}
				}
			}
		}
	}
}
</style>
