<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="InventoryFilterPopup">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-md">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('필터') }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<filter-list
						v-if="groupList.length > 0"
						ref="filter-list-group"
						:name="tt('그룹')"
						v-model="groupList"
						textKey="name"
						valueKey="id"
					></filter-list>

					<filter-list
						v-if="classification == 'CAT_CHEMICAL' && methodList.length > 0"
						ref="filter-list-method"
						:name="tt('등록방식')"
						v-model="methodList"
					></filter-list>

					<filter-list
						ref="filter-list-status"
						:name="this.classification == 'DISPOSAL' ? tt('분류') : tt('상태')"
						v-model="statusList"
						v-if="statusList.length > 0"
					></filter-list>

					<filter-list
						ref="filter-list-type"
						:name="tt('구분')"
						v-model="typeList"
						textKey="name"
						valueKey="id"
						v-if="typeList.length > 0"
					></filter-list>

					<filter-list
						ref="filter-list-category"
						:name="tt('카테고리')"
						v-model="categoryList"
						textKey="name"
						valueKey="id"
						v-if="categoryList.length > 0"
					></filter-list>

					<filter-list ref="filter-list-legal" :name="tt('해당법령')" v-model="legalList" v-if="legalList.length > 0"></filter-list>

					<filter-list
						ref="filter-list-storage"
						:name="tt('보관위치')"
						v-model="storageList"
						textKey="name"
						valueKey="id"
						v-if="storageList.length > 0"
					></filter-list>

					<filter-list
						ref="filter-list-assignees"
						:name="classification == 'HISTORY' ? tt('멤버') : checkWait ? tt('담당자') : tt('등록자')"
						v-model="assigneesList"
						textKey="name"
						valueKey="id"
						v-if="assigneesList.length > 0"
					></filter-list>

					<filter-list
						ref="filter-list-dumpers"
						:name="tt('폐기/삭제자')"
						v-model="dumpersList"
						textKey="name"
						valueKey="id"
						v-if="dumpersList.length > 0"
					></filter-list>
					<filter-list
						v-if="msdsKindList.length > 0"
						ref="filter-list-msdsKind"
						:name="tt('MSDS 유형')"
						v-model="msdsKindList"
					></filter-list>
					<filter-list
						v-if="classification != 'CAT_ETC' && brandList.length > 0"
						ref="filter-list-brand"
						:name="tt('브랜드')"
						v-model="brandList"
						textKey="name"
						valueKey="id"
					></filter-list>

					<filter-list
						v-if="classification == 'CAT_CHEMICAL' && expireList.length > 0"
						ref="filter-list-expire"
						:name="tt('유효기간')"
						v-model="expireList"
					></filter-list>

					<filter-list
						v-if="(classification == 'CAT_CHEMICAL' || classification == 'CAT_ETC') && volumeList.length > 0"
						ref="filter-list-volume"
						:name="tt('잔여 용량')"
						v-model="volumeList"
					></filter-list>
				</div>
				<div class="Popup__bottom">
					<button class="btn" @click="resetAll">
						{{ tt('전체 초기화') }}
					</button>
					<div class="Popup__btn-group">
						<button class="Btn__complete" @click="complete">
							{{ tt('저장') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { Category, Classification, RegistType } from '@/utils/define/ItemCode'
import FilterList from '@/views/common/components/FilterList.vue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'ModalFilter',
	mixins: [MixinModal],
	components: {
		FilterList,
	},
	props: {
		filterStorageList: { type: Array },
		filterAssigneesList: { type: Array },
		filterBrandList: { type: Array },
		filterMethodList: { type: Array },
		filterStatusList: { type: Array },
		filterLegalList: { type: Array },
		filterExpireList: { type: Array },
		filterVolumeList: { type: Array },
		filterCategoryList: { type: Array },
		filterGroupList: { type: Array },
		filterDumpersList: { type: Array },
		filterTypeList: { type: Array },
		filterMsdsKindList: { type: Array },
	},
	data() {
		return {
			type: '',
			filterList: {},
			storageList: [],
			assigneesList: [],
			brandList: [],
			methodList: [],
			statusList: [],
			legalList: [],
			expireList: [],
			volumeList: [],
			categoryList: [],
			groupList: [],
			dumpersList: [],
			typeList: [],
			msdsKindList: [],
			categoryListMore: false,
			legalListMore: false,
			storageListMore: false,
			brandListMore: false,
			assignListMore: false,
			expireListMore: false,
			classification: null,
			checkWait: true,
		}
	},
	computed: {
		...mapState('user', ['institute']),
	},
	methods: {
		show(classification, type, reset) {
			if (this.filterStorageList != undefined) this.storageList = this.$root.deepCopy(this.filterStorageList)
			if (this.filterAssigneesList != undefined) this.assigneesList = this.$root.deepCopy(this.filterAssigneesList)
			if (this.filterBrandList != undefined) this.brandList = this.$root.deepCopy(this.filterBrandList)
			if (this.filterMethodList != undefined) this.methodList = this.$root.deepCopy(this.filterMethodList)
			if (this.filterStatusList != undefined) this.statusList = this.$root.deepCopy(this.filterStatusList)
			if (this.filterLegalList != undefined) this.legalList = this.$root.deepCopy(this.filterLegalList)
			if (this.filterExpireList != undefined) this.expireList = this.$root.deepCopy(this.filterExpireList)
			if (this.filterVolumeList != undefined) this.volumeList = this.$root.deepCopy(this.filterVolumeList)
			if (this.filterCategoryList != undefined) this.categoryList = this.$root.deepCopy(this.filterCategoryList)
			if (this.filterGroupList != undefined) this.groupList = this.$root.deepCopy(this.filterGroupList)
			if (this.filterDumpersList != undefined) this.dumpersList = this.$root.deepCopy(this.filterDumpersList)
			if (this.filterTypeList != undefined) this.typeList = this.$root.deepCopy(this.filterTypeList)
			if (this.filterMsdsKindList != undefined) this.msdsKindList = this.$root.deepCopy(this.filterMsdsKindList)

			if (reset) this.resetAll()
			this.instId = this.institute.id
			this.isShow = true
			this.type = type
			this.classification = classification
			if (this.$route.name == 'WaitingRegistration') {
				this.checkWait = false
			} else {
				this.checkWait = true
			}
		},
		close() {
			this.$emit('close')
		},
		resetAll() {
			if (this.methodList.length > 0)
				this.methodList.forEach(el => {
					el.checked = false
				})
			if (this.categoryList.length > 0)
				this.categoryList.forEach(el => {
					el.checked = false
				})
			if (this.statusList.length > 0)
				this.statusList.forEach(el => {
					el.checked = false
				})
			if (this.legalList.length > 0)
				this.legalList.forEach(el => {
					el.checked = false
				})
			if (this.storageList.length > 0)
				this.storageList.forEach(el => {
					el.checked = false
				})
			if (this.assigneesList.length > 0)
				this.assigneesList.forEach(el => {
					el.checked = false
				})
			if (this.brandList.length > 0)
				this.brandList.forEach(el => {
					el.checked = false
				})
			if (this.expireList.length > 0)
				this.expireList.forEach(el => {
					el.checked = false
				})
			if (this.volumeList.length > 0)
				this.volumeList.forEach(el => {
					el.checked = false
				})
			if (this.groupList.length > 0)
				this.groupList.forEach(el => {
					el.checked = false
				})
			if (this.dumpersList.length > 0)
				this.dumpersList.forEach(el => {
					el.checked = false
				})
			if (this.typeList.length > 0)
				this.typeList.forEach(el => {
					el.checked = false
				})
			if (this.msdsKindList.length > 0)
				this.msdsKindList.forEach(el => {
					el.checked = false
				})
		},
		complete() {
			this.filterList = {
				methodList: this.methodList,
				statusList: this.statusList,
				categoryList: this.categoryList,
				legalList: this.legalList,
				storageList: this.storageList,
				assigneesList: this.assigneesList,
				brandList: this.brandList,
				expireList: this.expireList,
				volumeList: this.volumeList,
				groupList: this.groupList,
				dumpersList: this.dumpersList,
				typeList: this.typeList,
				msdsKindList: this.msdsKindList,
			}
			this.$emit('filter', this.filterList)
		},
	},
}
</script>

<style lang="scss" scoped>
.InventoryFilterPopup {
	.Popup__container {
		padding: 0;
		.Popup__top {
			padding: 20px;
			border-bottom: 1px solid $DIVIDER;
		}
		.Popup__content {
			padding: 20px;
			width: 100%;
			box-sizing: border-box;
			min-height: 40vh;
			max-height: 60vh;
			overflow-y: auto;
			.flex {
				margin-bottom: 1rem;
				h5 {
					font-size: 1rem;
					font-weight: 700;
					color: #000;
				}
				.btn {
					font-weight: 700;
				}
			}
			.Filter__wrap {
				&:last-child {
					border-bottom: 0;
				}
			}
			.Filter__list {
				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}
		}
		.Popup__bottom {
			padding-top: 20px;
			background: $LAB_WHITE;
			border-top: 1px solid $DIVIDER;
			.btn {
				margin: 0;
			}
		}
	}
}
.Filter__wrap {
	padding-bottom: 20px;
}
</style>
