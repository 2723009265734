<template>
	<div class="ingredient-item-body">
		<form-ingredient-input
			ref="form"
			v-for="(className, idx) in classNames"
			:key="`${className}${idx}`"
			:isUseSearch="className !== 'ratio'"
			:className="className"
			:value="ingredient[className]"
			@input="handleInput"
			@clickItem="clickItem"
		></form-ingredient-input>
		<!-- :showError="showError && !!ingredient[className]" -->

		<div>
			<div class="del-btn">
				<img src="@/assets/svg/icon/icon-trash-enable.svg" @click="deleteItem" />
			</div>
		</div>
	</div>
</template>

<script>
import FormIngredientInput from './FormIngredientInput.vue'

export default {
	name: 'FormIngredientListItem',
	components: { FormIngredientInput },
	props: {
		value: {
			type: [Object, null],
			default: null,
		},
		showError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			ingredient: {
				name: null,
				casno: null,
				ratio: null,
			},
			isShowRequire: false,
		}
	},
	computed: {
		classNames() {
			return ['name', 'casno', 'ratio']
		},
	},
	created() {},
	mounted() {
		if (this.value.name !== this.ingredient.name || this.value.casno !== this.ingredient.casno || this.value.ratio !== this.ingredient.ratio) {
			this.ingredient = { ...this.value }
		}
	},
	watch: {
		value: {
			deep: true,
			handler(value) {
				this.ingredient = { ...value }
			},
		},
	},
	methods: {
		handleInput(changeValue) {
			const { className, value } = changeValue
			const form = this.$refs['form']
			switch (className) {
				case 'name':
					this.ingredient.name = value
					form?.[0].initRequire()
					break
				case 'casno':
					this.ingredient.casno = value
					form?.[1].initRequire()
					break
				case 'ratio':
					this.ingredient.ratio = value
					form?.[2].initRequire()
					break
			}
			this.$emit('change', this.ingredient)
		},
		deleteItem() {
			this.$emit('delete')
		},
		clickItem(item) {
			this.ingredient = {
				name: item.name,
				casno: item.casno,
				ratio: item.ratio,
			}
			this.$emit('change', this.ingredient)
		},
		showRequire() {
			const { name, casno, ratio } = this.ingredient
			if (name || casno || ratio) {
				this.$refs['form'].forEach(ref => ref.showRequire())
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.ingredient-item-body {
	display: flex;
	border: 1px solid $DIVIDER;
	gap: 10px;
	padding: 10px;
	align-items: flex-start;

	.ingredient-textarea {
		display: flex;
		align-items: center;
		gap: 10px;

		&.ingredient-name {
			width: 270px;
		}
		&.ingredient-casno {
			width: 150px;
		}
		&.ingredient-ratio {
			width: 150px;
		}
	}
}
.del-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $DIVIDER;
	width: 30px;
	height: 30px;
	border-radius: 5px;
	cursor: pointer;
	&.disable {
		cursor: not-allowed;
	}
}
</style>
