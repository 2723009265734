var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', [_c('button', {
    staticClass: "checkbox",
    "class": {
      active: _vm.isClick
    },
    on: {
      "click": _vm.checkBoxClick
    }
  })]), _vm.headers[0].value ? _c('td', {
    on: {
      "click": _vm.goInventory
    }
  }, [_c('div', {
    staticClass: "description"
  }, [_vm.item.name ? _c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.item.name))
    }
  }) : _vm._e(), _vm.item.brand || _vm.item.subname ? _c('div', [_vm.item.brand ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.item.brand.name))
    }
  }) : _vm._e(), _vm.item.brand && _vm.item.subname ? _c('span', [_vm._v(_vm._s(' | ') + " ")]) : _vm._e(), _vm.item.subname ? _c('span', [_vm._v(_vm._s(_vm.item.subname))]) : _vm._e()]) : _vm._e()])]) : _vm._e(), _vm.headers[1].value ? _c('td', {
    on: {
      "click": _vm.goInventory
    }
  }, [_vm.item.productCode ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.item.productCode))
    }
  }) : _c('p', {
    staticClass: "nodata-txt"
  }, [_vm._v(_vm._s(_vm.tt('정보없음')))])]) : _vm._e(), _vm.headers[2].value ? _c('td', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.item.casno))
    },
    on: {
      "click": _vm.goInventory
    }
  }) : _vm._e(), _vm.headers[3].value ? _c('td', {
    on: {
      "click": _vm.goInventory
    }
  }, [_vm._v(_vm._s(_vm.commaVolume(_vm.item.totalNormalizedVolume)) + _vm._s(_vm.item.totalNormalizedVolumeUnit))]) : _vm._e(), _vm.headers[4].value ? _c('td', {
    on: {
      "click": _vm.goInventory
    }
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.item.createDatetime)))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }