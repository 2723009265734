var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-item', {
    attrs: {
      "title": _vm.title,
      "subContentText": _vm.subContentText,
      "required": _vm.isRequired
    }
  }, [_c('span', {
    staticClass: "input-wrap"
  }, [_vm.type === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    "class": {
      error: !_vm.isValid
    },
    style: "width:".concat(_vm.inputWidthSize, "px; height:").concat(_vm.inputHeightSize, "px;"),
    attrs: {
      "placeholder": _vm.tt(_vm.placeholder),
      "disabled": _vm.isReadOnly,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.value) ? _vm._i(_vm.value, null) > -1 : _vm.value
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.value,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.value = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.value = $$c;
        }
      }, function ($event) {
        return _vm.$emit('change');
      }]
    }
  }) : _vm.type === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    "class": {
      error: !_vm.isValid
    },
    style: "width:".concat(_vm.inputWidthSize, "px; height:").concat(_vm.inputHeightSize, "px;"),
    attrs: {
      "placeholder": _vm.tt(_vm.placeholder),
      "disabled": _vm.isReadOnly,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.value, null)
    },
    on: {
      "change": [function ($event) {
        _vm.value = null;
      }, function ($event) {
        return _vm.$emit('change');
      }]
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    "class": {
      error: !_vm.isValid
    },
    style: "width:".concat(_vm.inputWidthSize, "px; height:").concat(_vm.inputHeightSize, "px;"),
    attrs: {
      "placeholder": _vm.tt(_vm.placeholder),
      "disabled": _vm.isReadOnly,
      "type": _vm.type
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change');
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.value = $event.target.value;
      }
    }
  }), _vm.unit ? _c('b', {
    staticClass: "unit"
  }, [_vm._v(_vm._s(_vm.tt(_vm.unit)))]) : _vm._e()]), !_vm.isValid ? _c('div', {
    staticClass: "error"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/purchase/icon-error.svg"),
      "alt": "error"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt(_vm.inValidText)))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }