import groupAPI from '@/services/api/institute/group'
import _ from 'lodash'
import { getListByKeyword, getListBySelected } from '@/utils/Tree'
const getChildren = list => {
	let arr = []
	if (list) {
		list.forEach(node => {
			arr.push(node)
			arr.push(...getChildren(node.children))
		})
	}
	return arr
}

export default {
	namespaced: true,
	state: {
		groups: [],
		selectedGroups: [],
		keyword: '',
		list: [],
	},
	mutations: {
		setGroups: (state, data) => {
			state.groups = data
		},
		setSelectGroup: (state, group) => {
			if (state.selectedGroups.find(g => g.id == group.id)) {
				const children = getChildren(group.children)
				const notSelectChildren = children.filter(it => state.selectedGroups.find(g => g.id == it.id))
				if (children.length == notSelectChildren.length) {
					children.forEach(it => {
						state.selectedGroups.remove(it.id, 'id')
					})
				}
				state.selectedGroups.remove(group.id, 'id')
			} else {
				const children = getChildren(group.children).filter(it => !state.selectedGroups.find(g => g.id == it.id))
				state.selectedGroups.push(group)
				state.selectedGroups.push(...children)
			}
		},
		setSelectSingleGroup: (state, group) => {
			state.selectedGroups = [group]
		},
		initSelectedGroups: state => {
			state.selectedGroups = []
		},
		setSelectedGroup: (state, selectedGroups) => {
			state.selectedGroups = _.cloneDeep(selectedGroups)
		},
		setKeyword: (state, keyword) => {
			state.keyword = keyword
			if (state.keyword.length == 0 && state.selectedGroups.length > 0) {
				state.list = getListBySelected(
					state.groups,
					state.selectedGroups.map(g => g.id)
				)
			} else {
				state.list = getListByKeyword(state.groups, state.keyword)
			}
		},
		setTreeOpen: (state, isOpen) => {
			state.list = getListByKeyword(state.groups, state.keyword, isOpen)
		},
	},
	actions: {
		getGroups: async ({ commit, rootGetters }, payload) => {
			const response = await groupAPI.lookupGroupTree(rootGetters.instituteId, payload)
			commit('setGroups', response.data)
			commit('setKeyword', '')
			return response.data
		},
	},
}
