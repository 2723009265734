<template>
	<div class="button-manage">
		<div class="manage" @click="subscriptionFold = !subscriptionFold">
			{{ tt('정기결제 관리') }}
			<span class="fold" v-if="!isFail"
				>{{ licenseManagement.subscription.paymentDate }} 결제 예정
				<img :class="{ rotate: subscriptionFold }" src="@/assets/svg/arrow-down.svg" />
			</span>
			<span class="fold" v-else>
				<div class="red"><img src="@/assets/svg/input-icon-warning.svg" /> 결제 불가</div>
				<img :class="{ rotate: subscriptionFold }" src="@/assets/svg/arrow-down.svg" />
			</span>
		</div>
		<div class="manage-info" v-if="subscriptionFold">
			<div class="info-row">
				<div>등록된 결제 수단</div>
				<div>
					<!-- lmpro-voc-channel -> 2025.01.13 월요일 오전 11:52분 윤여범님 요청 voc로 백엔드팀(lsh)에게 전달받고 주석처리 후 보여주는 정보 변경 -->
					<!-- <b>{{ defaultCreditCard.name }} **** **** **** {{ defaultCreditCard.number }}</b> -->
					<b>{{ licenseManagement.subscription?.card?.name }} **** **** **** {{ licenseManagement.subscription?.card?.number }}</b>
				</div>
			</div>
			<div class="info-row">
				<div>결제 예정일</div>
				<div>{{ licenseManagement.subscription.paymentDate }}</div>
			</div>
			<div class="info-row">
				<div>라이선스 수</div>
				<div>
					{{ licenseManagement.subscription.quantity == 99999 ? '무제한' : licenseManagement.subscription.quantity.toLocaleString() }}
				</div>
			</div>
			<div class="info-row">
				<div>라이선스 적용기간</div>
				<div>{{ licenseManagement.subscription.period }}</div>
			</div>
			<div class="info-row">
				<div>라이선스 적용일수</div>
				<div>{{ licenseManagement.subscription.periodDaysCount }}일</div>
			</div>
			<div class="info-row">
				<div>결제 예정 금액</div>
				<div class="fold" @click="priceFold = !priceFold" style="cursor: pointer">
					￦{{ licenseManagement.subscription.totalPrice.toLocaleString() }}
					<img :class="{ rotate: priceFold }" src="@/assets/svg/arrow-down.svg" />
				</div>
			</div>
			<template v-if="priceFold">
				<div class="info-row" style="padding-right: 20px">
					<div>소계</div>
					<div>￦{{ licenseManagement.subscription.price.toLocaleString() }}</div>
				</div>
				<div class="info-row" style="padding-right: 20px">
					<div>VAT(10%)</div>
					<div>￦{{ licenseManagement.subscription.vat.toLocaleString() }}</div>
				</div>
			</template>
			<button class="button-black" @click.stop="$emit('manageSubscribe')">
				{{ tt('정기결제 관리') }}
			</button>
		</div>
	</div>
</template>
<script>
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
export default {
	props: {
		isFail: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [MixinLicense, MixinPayment],
	data() {
		return {
			subscriptionFold: false,
			priceFold: false,
		}
	},
	mounted() {
		this.subscriptionFold = this.open
	},
}
</script>
<style lang="scss" scoped>
.button-manage {
	background-color: $GREY_4;
	border-radius: 10px;
	padding: 10px 16px;
	width: 100%;
	min-height: 48px;
	height: unset;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	&:hover {
		background: #f2f3f4;
	}
}
.manage {
	margin: 0;
	width: 100%;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #000;
	font-weight: 600;
	cursor: pointer;
	&:hover {
		background-color: unset;
	}
}
.fold {
	display: flex;
	font-weight: 400;
	img {
		transform: rotate(180deg);
	}
	.rotate {
		transform: rotate(0deg);
	}
}
.manage-info {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	.info-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 400;
		div:first-child {
			color: $SECONDARY_2;
		}
	}
	.button-black {
		margin-top: 10px;
	}
}
</style>
