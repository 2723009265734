var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$root.user.language ? _c('div', {
    staticClass: "createSmallInstitution wrapper"
  }, [_c('migration-header', {
    attrs: {
      "isMigration": false,
      "text": _vm.tt(_vm.headerText)
    }
  }), _c('progress-indicator', {
    attrs: {
      "progressStep": _vm.progressStep
    }
  }), _c('div', {
    staticClass: "boxwrap"
  }, [_c('require-info', {
    attrs: {
      "progressStep": _vm.progressStep,
      "groupName": _vm.createParams.groupName
    }
  }), _vm.progressStep == 1 ? _c('input-info-step1', {
    ref: "step1",
    attrs: {
      "value": _vm.createParams.instituteName,
      "placeHolder": _vm.tt('예: 스마트잭'),
      "notiMsg": _vm.tt(_vm.notiMsg)
    },
    on: {
      "changeInputValue": _vm.changeInputValue,
      "next": _vm.next
    }
  }) : _vm.progressStep == 2 ? _c('input-info-step2', {
    ref: "step2",
    attrs: {
      "value": _vm.createParams.groupName,
      "placeHolder": _vm.tt('예: 그린촉매연구실 또는 연구지원팀'),
      "notiMsg": _vm.tt(_vm.notiMsg)
    },
    on: {
      "changeInputValue": _vm.changeInputValue,
      "next": _vm.next
    }
  }) : _vm.progressStep == 3 ? _c('input-info-step3', {
    ref: "step3",
    attrs: {
      "createStorageList": _vm.createParams.storagesName
    },
    on: {
      "changeStorageList": _vm.changeStorageList
    }
  }) : _vm._e(), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('button', {
    staticClass: "next",
    attrs: {
      "disabled": _vm.isLoading
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.nextBtn)) + " ")]), _vm.progressStep > 1 ? _c('button', {
    staticClass: "prev",
    on: {
      "click": _vm.prev
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('이전')) + " ")]) : _vm._e()])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }