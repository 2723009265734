<template>
	<div class="Btn__hover-balloon-w" @click="handleClick">
		<img v-if="iconColor === 'black'" class="hover-question" src="@/assets/svg/hover-question-black.svg" />
		<img v-if="iconColor === 'gray'" class="hover-question" src="@/assets/svg/hover-question-gray.svg" />
		<div class="balloon" :class="{ left: direction === 'left' }" :style="balloonStyle">
			<h6 v-html="title"></h6>
			<p v-html="content"></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LmBallon',
	props: {
		// Balloon 제목에 들어갈 텍스트
		title: {
			type: String,
			default: '',
		},
		// Balloon에 들어갈 텍스트
		content: {
			type: String,
			default: '',
		},
		// 물음표 아이콘 컬러
		// type: black, gray
		iconColor: {
			type: String,
			default: 'black',
		},
		direction: {
			type: String,
			default: 'right',
		},
		balloonStyle: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		handleClick() {
			this.$emit('click')
		},
	},
}
</script>

<style lang="scss" scoped>
.Btn__hover-balloon-w {
	position: relative;
	text-align: left;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-top: 2px;
	img {
		width: 16px;
		height: 16px;
	}
	&:hover {
		.balloon {
			visibility: visible;
			transition: 0.3s all ease;
		}
	}
	.balloon {
		position: absolute;
		padding: 15px 15px;
		display: block;
		background: $LAB_WHITE;
		border-radius: 5px;
		top: 30px;
		left: -18px;
		color: #000;
		font-weight: normal;
		visibility: hidden;
		z-index: 11;
		border: 1px solid $SECONDARY_2;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
		width: 344px;
		box-sizing: border-box;
		max-width: none;
		white-space: initial;
		text-align: left;
		&.left {
			left: -275px;
		}
		h6 {
			font-size: 14px;
			color: #000;
			font-weight: 700;
			padding-bottom: 3px;
		}
		p {
			width: 100% !important;
			/* word-break: keep-all; */
			font-size: 12px;
			font-weight: 400;
		}
	}
	.balloon:before {
		border-top: 0px solid transparent;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		border-bottom: 9px solid rgba(0, 0, 0, 0.6);
		content: '';
		position: absolute;
		top: -9.2px;
		left: 16px;
		z-index: 1003;
	}

	.balloon.left:before {
		top: -9px;
		left: 274px;
	}

	.balloon:after {
		border-top: 0px solid transparent;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 8px solid $LAB_WHITE;
		content: '';
		position: absolute;
		top: -8px;
		left: 17px;
		z-index: 1004;
	}

	.balloon.left:after {
		left: 275px;
	}
}
</style>
