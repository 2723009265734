<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt(title) }}</div>
			<div class="form-msg" :class="{ error: !isValid }">
				{{ tt(validMessage) }}
			</div>
		</div>
		<div class="error-msg" v-if="isShowError">
			{{ tt('카테고리 선택은 필수입니다.') }}
		</div>
		<category-selector
			ref="category-selector"
			:fixLargeCategory="isFixCategory"
			:fixMiddleCategory="isFixCategory"
			:value="category"
			@change="selectCategory"
		></category-selector>
	</div>
</template>
<script>
import CategorySelector from '@/views/common/components/categorySelector/CategorySelector.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'CargoCategorySelector',
	components: { CategorySelector },
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isShowError: false,
		}
	},
	computed: {
		...mapGetters('cargo/form', ['category', 'categoryInfo', 'cargoFormType']),
		validMessage() {
			return this.isValid ? this.tt('입력 완료') : this.tt('필수값을 선택해주세요')
		},
		isValid() {
			return this.category?.id
		},
		isFixCategory() {
			return ['EDIT', 'NEED_INPUT_LIST', 'NEED_INPUT_DETAIL'].includes(this.cargoFormType)
		},
	},
	watch: {
		isValid(value) {
			if (value) this.isShowError = false
		},
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.setData()
		})
	},
	methods: {
		...mapActions('cargo/form', ['selectCategory']),
		setData() {
			if (this.$refs['category-selector']) {
				this.$refs['category-selector'].setData(this.categoryInfo)
			}
		},
		showRequire() {
			this.isShowError = !this.isValid
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
.error-msg {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $SUB-RED;
	color: $RED;
	font-size: 14px;
	font-weight: 400;
	border-radius: 5px;
	min-height: 40px;
}
</style>
