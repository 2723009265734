var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt('등록할 수량')))]), _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isCounterValid
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('${1}개', _vm.multiCreateCount)) + " ")])]), _vm.isShowError ? _c('div', {
    staticClass: "error-msg"
  }, [_vm._v(" " + _vm._s(_vm.tt('수량은 최소 ${1}개 이상 최대 ${2}개 이하입니다.', _vm.min, _vm.max)) + " ")]) : _vm._e(), _c('form-counter', {
    ref: "form-counter",
    attrs: {
      "title": _vm.tt('등록할 수량'),
      "min": _vm.min,
      "max": _vm.max,
      "value": _vm.value,
      "hasOrderCount": _vm.hasOrderCount,
      "isShowError": _vm.error
    },
    on: {
      "change": _vm.setCount
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }