<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalManagementNotice">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-md">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt('특별관리물질 취급일지') }}
					</h5>
					<button class="Popup__close-btn">
						<img src="@/assets/svg/popup-close.svg" alt="close" @click="close" />
					</button>
				</div>
				<div class="Popup__content">
					<h6>{{ tt('특별관리물질 취급방법') }}</h6>
					<ul>
						<li>
							{{
								tt(
									'1. "특별관리물질이란 「산업안전보건법 시행규칙」 별표 18 제1호나목에 따른 발암성 물질, 생식세포 변이원성 물질, 생식독성(生殖毒性) 물질 등 근로자에게 중대한 건강장해를 일으킬 우려가 있는 물질로서 별표 12에서 특별관리물질로 표기된 물질을 말합니다.'
								)
							}}
						</li>
						<li>
							{{
								tt(
									'2. 별표 12에 따른 물질을 취급하는 경우에는 물질명·사용량 및 작업내용 등이 포함된 특별관리물질 취급일지를 작성하여 갖춰두어야 합니다.'
								)
							}}
						</li>
						<li>
							{{ tt('3. 법령 관리 물품 기능을 통해 연구실에서 보유한 특별관리물질을 살펴볼 수 있습니다.') }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
export default {
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	methods: {
		close() {
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalManagementNotice {
	.Popup__content {
		padding-bottom: 20px;
		h6 {
			font-size: 0.875rem;
			color: #000;
			font-weight: 700;
			padding-top: 20px;
			margin-bottom: 10px;
		}
		ul {
			li {
				font-size: 0.875rem;
				font-weight: 400;
				color: #000;
				word-break: keep-all;
			}
		}
	}
}
</style>
