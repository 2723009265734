import PageName from '@/utils/define/PageName'
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters('cargo/list', {
			cargoListCount: 'count',
		}),
		today() {
			return new Date().toISOString()
		},
		oneMonthBeforeDate() {
			return (() => {
				const date = new Date()
				date.setMonth(date.getMonth() - 1)
				return date.toISOString()
			})()
		},
	},
	watch: {
		// filter list 확인 후 isActiveFilter 처리해주기
	},
	created() {
		this.createdInit()
	},
	data() {
		return {
			// calendar
			dateButtonSubtext: null,
			calendarDatetime: [],
		}
	},
	methods: {
		createdInit() {
			// 저장될 정보 쿠키 체크
			const savedDatetime = this.$cookies.get(this.cookieDatetimeFilterKey)
			if (savedDatetime) {
				this.changeCalendar(JSON.parse(savedDatetime))
			} else {
				this.changeCalendar([null, null])
			}
		},
		async changeCalendar(date) {
			this.isActiveCalendar = true
			let startDate = null
			let endDate = null
			if (date[0] != null) {
				startDate = this.formatDate(this.$DateTime.fromJSDate(new Date(date[0])).toISO())
				endDate = this.formatDate(this.$DateTime.fromJSDate(new Date(date[1])).toISO())
				this.dateButtonSubtext = `${startDate} ~ ${endDate}`
			} else {
				startDate = null
				endDate = null
				this.dateButtonSubtext = null
			}
			this.calendarDatetime = [...date]

			if (this.$route.name == PageName.Purchase.Product.Pay)
				await this.setLoadParams({
					startOrderCreateDatetime: startDate,
					endOrderCreateDatetime: endDate,
				})
			else if (this.$route.name == PageName.Purchase.CargoList) {
				await this.setLoadParams({
					startDatetime: startDate,
					endDatetime: endDate,
				})
				if (this.$route.query.isStore && this.cargoListCount === 0) {
					this.$router.push({
						name: PageName.Purchase.Product.Order,
					})
				}
			} else
				await this.setLoadParams({
					startDraftCreateDatetime: startDate,
					endDraftCreateDatetime: endDate,
				})
			this.$cookies.set(this.cookieDatetimeFilterKey, JSON.stringify(this.calendarDatetime))
		},
		async getCurrentPage(page) {
			// pagination
			await this.setLoadParams(page)
		},
		search() {
			// 검색
		},
	},
}
