var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "size": "sm",
      "hide-header": "",
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/221223.png")
    },
    on: {
      "click": _vm.moveToUrl
    }
  }), _c('div', {
    staticClass: "btn-footer"
  }, [_c('button', {
    on: {
      "click": _vm.clickDonwShow
    }
  }, [_vm._v(_vm._s(_vm.tt('일주일간 보지 않기')))]), _c('button', {
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(_vm._s(_vm.tt('닫기')))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }