<template>
	<div class="migration-header">
		<span class="migration-header-text" v-html="tt(text)"></span>
		<div class="migration-right-header">
			<app-header-menu v-model="menuUserInfo" v-if="!isAppModeExperience" :isMigration="isMigration"></app-header-menu>
		</div>
	</div>
</template>

<script>
import AppHeaderMenu from '@/views/app/components/AppHeaderMenu'
export default {
	components: { AppHeaderMenu },
	props: {
		text: {
			type: String,
			default: '',
		},
		isMigration: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			menuUserInfo: {
				name: '',
			},
		}
	},
	watch: {
		userInfo: {
			deep: true,
			handler(newValue) {
				this.menuUserInfo.name = newValue.name
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.migration-header {
	width: 100%;
	display: flex;
	align-items: center;
	height: 58px;
	position: absolute;
	top: 0;
	left: 0;
	background: inherit;
	.migration-header-text {
		display: inline-block;
		font-size: 1rem;
		font-weight: 700;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	.migration-right-header {
		margin-left: auto;
	}
}
</style>
