<template>
	<modal-filter ref="modal" @confirm="confirm" @reset="reset">
		<template #modal-content v-if="filter">
			<filter-list ref="filter-status" title="분류" :filterCookieName="filterCookieName.status" :list="filter.status"> </filter-list>
			<filter-list ref="filter-categoriesId" title="카테고리" :filterCookieName="filterCookieName.categoriesId" :list="filter.categories">
			</filter-list>
			<filter-list ref="filter-assigneesId" title="담당자" :filterCookieName="filterCookieName.assigneesId" :list="filter.assignees">
			</filter-list>
			<filter-list ref="filter-dumpersId" title="폐기/삭제자" :filterCookieName="filterCookieName.dumpersId" :list="filter.dumpers">
			</filter-list>
		</template>
	</modal-filter>
</template>
<script>
import ModalFilter from '@/views/common/components/modalGen2/ModalFilterBase.vue'
import FilterList from '@/views/common/components/modalGen2/components/filter/FilterList.vue'
import { mapActions, mapState } from 'vuex'
export default {
	components: {
		ModalFilter,
		FilterList,
	},
	computed: {
		...mapState('inventoryGen2/disposal', ['filter', 'filterCookieName']),
	},
	methods: {
		...mapActions('inventoryGen2/disposal', ['getFilter']),
		async show() {
			if (this.filter == null) await this.getFilter()
			this.$refs['modal'].show()
		},
		confirm() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.setCookies !== 'undefined') it.setCookies()
			})
			let params = {
				status: this.$refs['filter-status'].checkedList ?? null,
				categoriesId: this.$refs['filter-categoriesId'].checkedList ?? null,
				assigneesId: this.$refs['filter-assigneesId'].checkedList ?? null,
				dumpersId: this.$refs['filter-dumpersId'].checkedList ?? null,
			}
			this.$emit('changeLoadParams', params)
			this.$refs['modal'].hide()
			this.$root.toast('필터', '필터가 적용되었습니다.', 'success')
		},
		reset() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.reset !== 'undefined') it.reset()
			})
		},
	},
}
</script>
<style lang="scss" scoped></style>
