var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item && _vm.item.id == _vm.$route.params.id ? _c('div', {
    staticClass: "detail-page-wrapper",
    attrs: {
      "id": "approval-detail"
    }
  }, [_c('approval-detail-header', {
    attrs: {
      "title": _vm.item.subject,
      "subtitle": _vm.item.no,
      "status": _vm.item.approvalStatus,
      "permission": _vm.item.draftDetailPermission,
      "orderId": _vm.item.orderId
    },
    on: {
      "click": _vm.showConfirmModal,
      "print": _vm.showPrintPopup
    }
  }), _c('div', {
    staticClass: "detail-content-wrapper"
  }, [_c('div', [_vm.item.purchaseType == 'INTERNAL' ? _c('store-order-info', {
    ref: "store-order-info",
    attrs: {
      "type": "DRAFT"
    }
  }) : _vm._e()], 1), _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('결재 정보')))]), _c('div', {
    staticClass: "approval-info-wrapper"
  }, [_vm.item.register ? _c('approval-info-item', {
    attrs: {
      "approvalTitle": '기안',
      "name": _vm.item.register.name,
      "affiliation": _vm.item.group.name,
      "dateTime": _vm.item.createDatetime ? _vm.formatDatetime(_vm.item.createDatetime) : '-'
    }
  }) : _vm._e(), _c('div', _vm._l(_vm.item.approvals, function (approval, index) {
    return _c('approval-info-item', {
      key: "approval_".concat(index),
      attrs: {
        "approvalTitle": '결재',
        "approvalState": approval.status,
        "name": approval.user.name,
        "affiliation": approval.group.name,
        "dateTime": approval.concludeDatetime ? _vm.formatDatetime(approval.concludeDatetime) : '-'
      }
    });
  }), 1)], 1)]), _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('기안 정보')))]), _c('info-table', {
    attrs: {
      "items": _vm.draftInfoForTable
    }
  })], 1), _c('div', {
    staticClass: "print-section"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('상품 정보')))]), _c('product-info-table', {
    attrs: {
      "productInfoList": _vm.item.purchaseItems
    }
  })], 1), _vm.item.budgetStatus ? _c('div', [_c('total-amount', {
    attrs: {
      "title": '예산 현황',
      "info": _vm.amountForm,
      "budget": _vm.item.budgetStatus
    }
  })], 1) : _vm._e(), _c('div', [_c('div', {
    staticClass: "file-wrap"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('첨부 파일')))]), _vm.attachmentsForTable[0].files && _vm.attachmentsForTable[0].files.length > 0 ? _c('image-button', {
    attrs: {
      "text": '모두 다운로드',
      "image": require('@/assets/svg/icon-file-download.svg')
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['files-table'].downloadAll();
      }
    }
  }) : _vm._e()], 1), _c('info-table', {
    ref: "files-table",
    attrs: {
      "items": _vm.attachmentsForTable
    }
  })], 1)]), _c('modal-confirm', {
    ref: "modal-cancel",
    attrs: {
      "title": "결재 취소",
      "content": "지출결의서 결재를 취소하시겠습니까?<br>결재를 취소 후, 다시 승인하거나 반려하실 수 있습니다.",
      "confirmText": "결재 취소",
      "variant": "warning"
    }
  }), _c('modal-confirm', {
    ref: "modal-accept",
    attrs: {
      "title": "지출 승인",
      "content": _vm.acceptContent,
      "confirmText": "승인",
      "variant": "accept"
    }
  }), _c('modal-confirm', {
    ref: "modal-reject",
    attrs: {
      "title": "지출 반려",
      "content": "지출결의서를 반려하시겠습니까?",
      "confirmText": "반려",
      "variant": "warning"
    }
  }), _c('modal-confirm', {
    ref: "modal-order-cancel",
    attrs: {
      "title": "주문 취소",
      "content": "해당 결재에 연결된 스토어 주문이 있습니다.<br>주문 상세보기 버튼을 눌러 주문을 취소해주세요.",
      "confirmText": "주문 상세보기",
      "variant": "warning"
    }
  }), _c('modal-remain-later-pay', {
    ref: "modal-remain-later-pay"
  }), _c('modal-loading', {
    ref: "modal-loading"
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }