<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper" :class="{ error: error }">
			<single-date-picker @changeDate="changeDate" :placeholder="tt(placeholder)" ref="datepicker"> </single-date-picker>
			<div class="date" :class="{ valid: diffDatetime > 0, invalid: diffDatetime <= 0 }" v-if="isExpireDatetime">{{ dateString }}</div>
		</div>
		<span class="error" v-if="error">{{ tt('일자를 선택해주세요') }}</span>
	</div>
</template>

<script>
import SingleDatePicker from '@/views/common/components/SingleDatePicker.vue'
import { DateTime } from 'luxon'

export default {
	name: 'FormDatetime',
	components: { SingleDatePicker },
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			date: null,
		}
	},
	computed: {
		isExpireDatetime() {
			return this.name.includes('유효기간')
		},
		diffDatetime() {
			return this.date ? Math.ceil(DateTime.fromISO(this.date).diffNow(['days']).days) : ''
		},
		dateString() {
			return this.diffDatetime || this.diffDatetime === 0 ? `(${this.diffDatetime}일)` : ''
		},
		error() {
			return this.required && this.isShowError && !this.inputValue
		},
	},
	watch: {
		value(date) {
			if (this.value !== this.date) {
				this.date = date
				this.$refs['datepicker'].setDate(date)
			}
		},
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			if (this.value !== this.date) {
				this.date = this.value
				this.$refs['datepicker'].setDate(this.value)
			}
		})
	},
	methods: {
		changeDate(date) {
			this.date = date
			this.$emit('input', date)
		},
	},
}
</script>

<style lang="scss" scoped>
.input-wrapper.error ::v-deep {
	.mx-input-wrapper {
		border: 1px solid $RED;
	}
}

.input-wrapper ::v-deep {
	position: relative;
	.datepicker {
		margin-left: 0px;
	}
	.mx-input {
		background: white;
		height: 28px;
		font-weight: 400;
		font-size: 14px;
		&::placeholder {
			color: $PLACEHOLDER;
			font-size: 12px;
		}
	}
	.mx-input-wrapper {
		width: 300px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.date {
		position: absolute;
		left: 100px;
		z-index: 2;
		font-size: 14px;
		&.valid {
			color: $GREEN;
		}
		&.invalid {
			color: $RED;
		}
	}
}

.form-input {
	position: relative;
	// width: 300px;
	// max-width: 300px;
	width: calc(50% - 9px);
	&.list {
		.input-wrapper {
			input[type='text'],
			input[type='number'] {
				height: 36px;
				border: 1px solid $COLOR_CCC;
				padding-left: 12px;
			}
		}
	}
	&.error {
		span.error {
			display: block;
			font-size: 0.75rem;
			font-weight: 400;
			padding-left: 18px;
			background-image: url(~@/assets/svg/input-icon-warning.svg);
			background-repeat: no-repeat;
			background-size: 12px;
			background-position: center left 0;
			color: $RED;
			margin-top: 4px;
		}
		input[type='text'],
		input[type='number'] {
			border-color: $RED !important;
		}
		.mx-input {
		}
	}
	.name {
		font-size: 0.75rem;
		font-weight: 400;
		margin-bottom: 8px !important;
	}
	span.error {
		display: none;
	}
	.input-wrapper {
		display: flex;
		align-items: center;
		input::placeholder {
			color: $PLACEHOLDER;
		}
	}
}
</style>
