var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "AppSideMenu__container"
  }, [_vm.institute ? _c('div', {
    staticClass: "logo",
    on: {
      "click": _vm.goDashboard
    }
  }, [_c('div', {
    staticClass: "imgbox",
    "class": {
      noLogo: !_vm.institute.logo
    }
  }, [_vm.institute.logo ? _c('img', {
    attrs: {
      "src": _vm.institute.logo + "?date=".concat(new Date().getTime())
    }
  }) : _c('h5', [_vm._v(_vm._s(_vm.institute.name ? _vm.institute.name.slice(0, 1) : ''))])]), _c('h3', [_vm._v(_vm._s(_vm.institute.name))]), _vm.isTrial || _vm.isFreeTier ? _c('span', {
    staticClass: "badge-trial"
  }, [_vm._v(_vm._s(_vm.tt('무료')))]) : !_vm.isLicenseActivate ? _c('span', {
    staticClass: "badge-trial expired"
  }, [_vm._v(_vm._s(_vm.tt('만료됨')))]) : _vm._e()]) : _vm._e(), _c('app-side-global-filter', {
    attrs: {
      "userPermission": _vm.userPermission
    }
  }), _c('ul', {
    ref: "menu-container",
    staticClass: "AppSideMenu__item-container"
  }, _vm._l(_vm.menuList, function (menu, index) {
    return _c('li', {
      key: "menu_" + index
    }, [_c('div', {
      staticClass: "title",
      "class": {
        selected: _vm.isSelected(menu.menuPath)
      },
      on: {
        "click": function click(event) {
          return _vm.toggle(event, menu.menuTitle);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/svg/' + menu.menuIcon),
        "alt": "icon"
      }
    }), _c('h6', [_vm._v(" " + _vm._s(_vm.tt(menu.menuTitle)) + " ")]), menu.menuImage ? _c('img', {
      staticStyle: {
        "width": "calc(100% - 120px)",
        "margin-right": "10px"
      },
      attrs: {
        "src": require('@/assets/svg/' + menu.menuImage)
      }
    }) : _vm._e(), _c('image-button', {
      "class": {
        menuFold: _vm.openMenuList.includes(menu.menuTitle)
      },
      attrs: {
        "image": require('@/assets/svg/arrow-down.svg')
      }
    })], 1), menu.childList && _vm.openMenuList.includes(menu.menuTitle) ? _c('ul', {
      staticClass: "sub-menu"
    }, _vm._l(menu.childList, function (sub, index) {
      return _c('li', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.showMenu(sub.menuPath),
          expression: "showMenu(sub.menuPath)"
        }],
        key: "submenu_" + index,
        "class": [{
          block: _vm.block(sub.menuPath)
        }, {
          fold: sub.menuFold != null
        }]
      }, [_c('p', {
        "class": {
          selected: _vm.isSelected(sub.menuPath)
        },
        on: {
          "click": function click($event) {
            $event.stopPropagation();
            sub.clickMenu && !_vm.block(sub.menuPath) ? _vm.handleClick(sub.clickMenu(), $event) : sub.clickMenu && _vm.block(sub.menuPath) ? _vm.showLicense() : '';
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.tt(sub.menuTitle)) + " "), sub.icon ? _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: sub.tooltip,
          expression: "sub.tooltip",
          modifiers: {
            "hover": true
          }
        }],
        staticClass: "newpage",
        attrs: {
          "src": require('@/assets/svg/' + sub.icon),
          "alt": "icon"
        }
      }) : _vm._e(), _vm.warningSafety(sub.menuPath) && _vm.type[sub.menuPath] ? _c('img', {
        staticClass: "warning",
        attrs: {
          "src": require("@/assets/svg/input-icon-warning.svg")
        }
      }) : _vm._e(), _vm.subNewInventory(sub.menuPath) ? _c('img', {
        staticClass: "new",
        attrs: {
          "src": require("@/assets/svg/new.svg")
        }
      }) : _vm._e()]), sub.pageName === 'ProductOrder' && !_vm.isSelected(sub.menuPath) ? _c('merck-tooltip', {
        attrs: {
          "cssVariable": _vm.cssVariable,
          "isAnimation": true,
          "isClose": false,
          "isBrandModal": false
        }
      }, [_c('img', {
        attrs: {
          "width": "135px",
          "src": require("@/assets/images/icon-sale-side.png")
        }
      })]) : _vm._e(), sub.menuFold != null ? _c('image-button', {
        "class": {
          menuFold: _vm.fold
        },
        attrs: {
          "image": require('@/assets/svg/arrow-down.svg')
        },
        on: {
          "click": function click($event) {
            _vm.fold = !_vm.fold;
          }
        }
      }) : _vm._e(), _c('div', {
        staticClass: "tooltip"
      }, [_vm._v(" " + _vm._s(_vm.tt('STANDARD 라이선스에서 사용 가능합니다')) + " ")]), sub.subMenuList ? _c('ul', {
        staticClass: "depth-menu",
        "class": {
          menuFold: _vm.fold
        }
      }, _vm._l(sub.subMenuList, function (item, index) {
        return _c('li', {
          key: "depth_" + index,
          ref: sub.menuTitle,
          refInFor: true
        }, [_c('p', {
          "class": {
            selected: _vm.isSelected(item.menuPath, item.categoryType)
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              item.clickMenu ? _vm.handleClick(item.clickMenu(), $event) : '';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(item.menuTitle)) + " "), item.icon ? _c('img', {
          attrs: {
            "src": require(item.icon)
          }
        }) : _vm._e(), _vm.subNewInventory(item.menuPath) ? _c('img', {
          staticClass: "new",
          attrs: {
            "src": require("@/assets/svg/new.svg")
          }
        }) : _vm._e()])]);
      }), 0) : _vm._e()], 1);
    }), 0) : _vm._e()]);
  }), 0), _c('inventory-gen2-setting', {
    ref: "inventory-gen2-setting"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }