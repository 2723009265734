var render = function () {
  var _vm$instInfo$info$log, _vm$instInfo$info$nam, _vm$instInfo$info$tra, _vm$instInfo$info$pho, _vm$instInfo$info$add;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail-wrapper"
  }, [_c('div', {
    staticClass: "common-header-wrapper"
  }, [_c('div', [_c('h2', [_vm._v(_vm._s(_vm.tt('기관 정보')))])])]), _vm.instInfo ? _c('div', {
    staticClass: "common-detail-wrapper"
  }, [_c('div', {
    staticClass: "institute-header"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": (_vm$instInfo$info$log = _vm.instInfo.info.logo) !== null && _vm$instInfo$info$log !== void 0 ? _vm$instInfo$info$log : require('@/assets/svg/institution-building.svg'),
      "alt": "icon"
    }
  }), _c('h3', {
    staticClass: "institute-name"
  }, [_vm._v(" " + _vm._s((_vm$instInfo$info$nam = _vm.instInfo.info.name) !== null && _vm$instInfo$info$nam !== void 0 ? _vm$instInfo$info$nam : '-') + " ")]), _c('div', {
    staticClass: "right-btn-group"
  }, [_vm.userPermission.institute && _vm.userPermission.institute.info.update ? _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.$refs['ModalEditInformation'].show();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-edit.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('기관 정보 편집')) + " ")]) : _vm._e(), _vm.userPermission.license && _vm.userPermission.license.read ? _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.$refs['ModalLicenseHistory'].show();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/History(Button).svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt('구매내역')) + " ")]) : _vm._e(), _vm.userPermission.license && _vm.userPermission.license.read && _vm.userPermission.license.update && !_vm.isAppModeExperience ? _c('button', {
    staticClass: "btn Btn__license point",
    "class": {
      active: !_vm.isLicenseActivate
    },
    on: {
      "click": _vm.onManageLicense
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-license.svg"),
      "alt": "관리icon"
    }
  }), _vm._v(_vm._s(_vm.tt('라이선스 관리')) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "institute-content"
  }, [_c('h5', [_vm._v("기관 정보")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('사업자등록번호')))]), _c('p', {
    "class": {
      'no-data': !_vm.instInfo.info.traderRegistrationNumber
    }
  }, [_vm._v(" " + _vm._s((_vm$instInfo$info$tra = _vm.instInfo.info.traderRegistrationNumber) !== null && _vm$instInfo$info$tra !== void 0 ? _vm$instInfo$info$tra : _vm.tt('정보 없음')) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('연락처')))]), _c('p', {
    "class": {
      'no-data': !_vm.instInfo.info.phone
    }
  }, [_vm._v(" " + _vm._s((_vm$instInfo$info$pho = _vm.instInfo.info.phone) !== null && _vm$instInfo$info$pho !== void 0 ? _vm$instInfo$info$pho : _vm.tt('정보 없음')) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('주소')))]), _c('p', {
    "class": {
      'no-data': !_vm.instInfo.info.address
    }
  }, [_vm._v(" " + _vm._s((_vm$instInfo$info$add = _vm.instInfo.info.address) !== null && _vm$instInfo$info$add !== void 0 ? _vm$instInfo$info$add : _vm.tt('정보 없음')) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('총 멤버 수')))]), _c('p', [_vm._v(_vm._s(_vm.instInfo.allMemberCount))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('시스템관리자')))]), _c('p', [_vm._v(_vm._s(_vm.setAlmightyMembers))])])]), _c('h5', [_vm._v("라이선스")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('상태')))]), _c('p', {
    staticClass: "license-status",
    "class": {
      warning: !_vm.isLicenseActivate
    }
  }, [_vm._v(" " + _vm._s(_vm.licenseStatusText) + " ")]), _vm.isLicenseDepositWait ? _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.tt('계산서 발행 후 30일 이내 입금하지 않으면, 라이선스가 비활성화 됩니다.'),
      expression: "tt('계산서 발행 후 30일 이내 입금하지 않으면, 라이선스가 비활성화 됩니다.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "payment-status"
  }, [_vm._v(" " + _vm._s(_vm.tt('입금대기')) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('라이선스 유형')))]), _c('p', [_vm._v(_vm._s(_vm.licenseTypeText))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('만료일')))]), _c('p', [_vm._v(_vm._s(_vm.formatDatetime(_vm.license.expireDate)))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('라이선스 수')))]), _c('p', [_vm._v(_vm._s(_vm.licenseQuantityText))])])])])]) : _vm._e(), _c('ModalLicenseHistory', {
    ref: "ModalLicenseHistory"
  }), _c('ModalEditInformation', {
    ref: "ModalEditInformation"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }