<template>
	<tr class="select-table-body" :class="{ disabled: item.isBlock }">
		<td>
			<input type="checkbox" v-model="checked" />
		</td>
		<td>
			<option-info :product="item" :useSale="useSale" :storeStock="storeStock" v-b-tooltip.hover.bottom :title="tooltipText"></option-info>
			<div v-if="item.isBlock" class="error">
				<img src="@/assets/svg/purchase/icon-error.svg" alt="error" />
				<span>{{ blockReason }}</span>
			</div>
		</td>
		<td>
			<!-- m10 디자인 변경으로 인한 주석 처리 ( 기존꺼는 견적서와 장바구니 때문에 수량 변경 안되게 되어있었음 ) -->
			<!-- <b v-if="item.productId">{{ quantity }}</b> -->
			<input-button-counter ref="input-button-counter" v-model="quantity" :min="min" :max="max" sizeType="sm"></input-button-counter>
		</td>
		<td>
			<div>
				<div :class="{ red: item?.discountPrice }">
					<b>{{ tt('${1}원', formatNumberLocale(item?.discountPrice ?? 0)) }}</b>
				</div>
				<div class="sale-percent" v-if="item?.discountPercent">
					<!-- 10% 할인 이미지 태그 -->
					{{ item?.discountPercent ?? 0 }}% 할인
				</div>
			</div>
		</td>
		<td>
			<div>
				<div>
					<b>{{ tt('${1}원', formatNumberLocale(item?.sellingPrice ?? 0)) }}</b>
				</div>
				<div v-if="item?.discountPrice" style="text-decoration: line-through; color: #999">
					{{ tt('${1}원', formatNumberLocale(item.unitPrice * item.quantity)) }}
				</div>
			</div>
		</td>
		<td>
			<div>
				<div>
					<p v-if="item.deliveryFee">{{ tt('${1}원', formatNumberLocale(item.deliveryFee)) }}</p>
					<b v-else>{{ tt('무료배송') }}</b>
				</div>
			</div>
		</td>
	</tr>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import OptionInfo from './OptionInfo'
import InputButtonCounter from '@/views/purchase/cargo/components/cargoList/counter/InputButtonCounter.vue'
export default {
	components: {
		OptionInfo,
		InputButtonCounter,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		allChecked: {
			type: Boolean,
			default: false,
		},
		useSale: {
			type: Boolean,
			default: true,
		},
		index: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			checked: false,
			min: 1,
			max: Number.MAX_SAFE_INTEGER,
		}
	},
	watch: {
		checked(val) {
			console.log('checked', this.selectedItems)
			if (val) {
				this.setSelectedItems(this.selectedItems.concat(this.index))
			} else {
				this.setSelectedItems(this.selectedItems.filter(idx => idx !== this.index))
			}
		},
		allChecked(val) {
			this.checked = val
		},
		selectedItems(val) {
			if (val.length === 0) this.checked = false
		},
	},
	computed: {
		...mapState('draft/item', ['selectedItems', 'ownStoreProductList']),
		blockReason() {
			if (this.item.blockReason == 'SHAK_CANT_DELIVERY') {
				return '선택한 배송지에 배송이 불가능한 상품입니다.'
			}
			return '배송이 불가능 하거나 판매 중지된 상품입니다.'
		},
		quantity: {
			get() {
				return this.item.quantity
			},
			set(value) {
				this.setQuantity({ index: this.index, quantity: value })
			},
		},
		storeStock() {
			let stock = 0
			if (this.item.brand && this.item.brand.masterId)
				this.ownStoreProductList.forEach(it => {
					if (it.masterBrandId === this.item.brand.masterId && it.productCode === this.item.productCode && it.count > 0) {
						stock = it.count
					}
				})
			return stock
		},
		tooltipText() {
			return this.storeStock > 0 ? `동일 물품 잔여 용량: 총 ${this.storeStock}개` : ''
		},
	},
	methods: {
		...mapMutations('draft/item', ['setSelectedItems']),
		...mapActions('draft/item', ['setQuantity']),
	},
}
</script>

<style lang="scss" scoped>
::v-deep .tooltip-inner {
	background-color: $SECONDARY_1;
	color: $INPUT;
}
.red {
	color: $RED;
}
.select-table-body {
	border-top: 1px solid $INPUT;
	&.disabled td > * {
		opacity: 0.5;
		cursor: not-allowed;
	}
	td {
		padding-top: 20px;
		padding-bottom: 20px;
		height: 100%;
		border-right: 1px solid $INPUT;
		text-align: center;
		.input-wrap {
			width: 70%;
			margin: auto;
		}
		.sale-percent {
			margin: 0 auto;
			background-color: $RED;
			color: white;
			width: fit-content;
			border-radius: 4px;
			padding: 2px 4px;
			font-size: 12px;
			font-weight: 700;
		}
	}
	td:first-child {
		padding-left: 20px;
		text-align: left;
		border-right: none;
	}
	td:last-child {
		border-right: none;
	}

	.error {
		opacity: 1 !important;
		margin-top: 10px;
		margin-left: 95px;
		display: flex;
		span {
			font-size: 0.75rem;
			color: $RED;
			margin-left: 6px;
		}
	}
}
.count-input-wrapper {
	display: inline-flex;
	flex-direction: row;
	border: 1px solid $INPUT;
	border-radius: 5px;
	padding: 4px;
	height: 30px;
	width: 90px;
	background: white;
	input {
		text-align: left;
		border: none;
		width: 35px;
		padding-left: 5px;
	}
	button {
		background-color: $GREY_4;
		margin-left: auto;
		width: 20px;
		height: 20px;
		border: none;
		border-radius: 4px;
		background-repeat: no-repeat;
		background-position: center;
		text-indent: -9999px;
		background-size: 16px;
		cursor: pointer;
		transition: all 0.3s;
		&.minus-btn {
			background-image: url(~@/assets/svg/input-count-minus.svg);
		}
		&.plus-btn {
			background-image: url(~@/assets/svg/input-count-plus.svg);
		}
		&.disable {
			color: $COLOR_CCC;
			background: linear-gradient(0deg, $GREY_4, $GREY_4), linear-gradient(0deg, rgba(242, 243, 244, 0.2), rgba(242, 243, 244, 0.2)),
				linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
			cursor: not-allowed;
		}
	}
	&.lg {
		height: 40px;
		width: 120px;
		input {
			width: 40px;
		}
		button {
			width: 30px;
			height: 30px;
		}
	}
}
</style>
