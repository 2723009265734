<template>
	<div class="page-wrapper">
		<receive-invite-header></receive-invite-header>
		<div class="content-slide-wrapper">
			<div class="content-wrapper">
				<div class="text-wrapper">
					<h3 class="title" v-html="tt(title)"></h3>
					<div class="content" v-html="tt(content)"></div>
					<div class="sub-text" v-html="tt(subText)"></div>
				</div>
			</div>
			<div class="institute-item-wrapper">
				<invited-institute-item
					:title="invitationInstitute.title"
					:subTitle="invitationInstitute.subTitle"
					:logo="invitationInstitute.logo"
					:info="inviteInfo"
					:isExpired="isExpired"
					:isOverCountLicense="isOverCountLicense"
					@click="onClickJoinInstitute"
				></invited-institute-item>
				<invited-institute-item
					v-if="isLoginUser && !isHideCreateComponent"
					:title="anotherInstitute.title"
					:subTitle="anotherInstitute.subTitle"
					:logo="anotherInstitute.logo"
					@click="onClickCreateInstitute"
				></invited-institute-item>
			</div>
			<div v-if="!isLoginUser" class="invite-footer">
				랩매니저 PRO는
				<span>재고관리</span> 및 <span>안전관리</span>에 특화된 연구실 전용 솔루션 입니다.
			</div>
		</div>
		<modal-common-confirm
			:isShow="isShowModal"
			:title="confirmModalTitle"
			:content="confirmModalContent"
			:buttonText="confirmModalButtonText"
			:cancelText="confirmModalCancelButton"
			:hideConfirm="isHideModalConfirmBtn"
			@confirm="onClickConfirmModal"
			@close="isShowModal = false"
		></modal-common-confirm>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isLogin, goToSSOMain, goToSSOEmail } from '@/services/api/common'
import ReceiveInviteHeader from '@/views/invite/receive/components/ReceiveInviteHeader.vue'
import InvitedInstituteItem from '@/views/invite/receive/components/InvitedInstituteItem.vue'
import ModalCommonConfirm from '@/views/common/components/modals/ModalCommonConfirm.vue'
import memberAPI from '@/services/api/member'
import PageName from '@/utils/define/PageName'

const INVITE_TYPE = {
	CREATE: 'create',
	LEGACY: 'legacy',
	JOIN_ALREADY: 'joinAlready',
	JOIN_PRO: 'joinPro',
	JOIN_NEW: 'joinNew',
	EXPIRE: 'expire',
	OVER_COUNT: 'overCount',
	CONFIRM_EMAIL_CREATE: 'confirmEmailCreate',
	CONFIRM_EMAIL_EXPIRE: 'confirmEmailExpire',
}

export default {
	components: {
		ReceiveInviteHeader,
		InvitedInstituteItem,
		ModalCommonConfirm,
	},
	data() {
		return {
			inviteType: '',
			title: '',
			content: '',
			subText: '',
			inviteInfo: {},
			invitationInstitute: {},
			anotherInstitute: {},
			isLegacyUser: false,
			isExpired: false,
			isOverCountLicense: false,
			icon: {
				default: require('@/assets/svg/default-institute-logo.svg'),
				legacy: require('@/assets/svg/migration/icon-legacy-lab-manager.svg'),
				add: require('@/assets/svg/add-institute.svg'),
			},
			isShowModal: false,
			isHideModalConfirmBtn: false,
			confirmModalTitle: '',
			confirmModalContent: '',
			confirmModalButtonText: '',
			confirmModalCancelButton: '',
		}
	},
	computed: {
		...mapState('user', ['user']),
		// 현재 로그인한 사용자가 이미 가입한 기관이 있는지 여부
		isAlreadyJoinInstitute() {
			return !this.isEmptyObject(this.institute)
		},
		isLoginUser() {
			return this.user && this.user.email
		},
		isHideCreateComponent() {
			return !this.isLegacyUser && !this.isAlreadyJoinInstitute
		},
	},
	created() {
		if (this.mobileCheck()) {
			// if (location.href.includes('dev')) location.href = location.href.replace('dev-pro', 'm-dev-pro')
			// else location.href = location.href.replace('pro', 'm-pro')
			// TEST를 위한 임시 주석
			location.href = location.href.replace('dev-pro', 'm-dev-pro')
		}
	},
	async mounted() {
		await this.init()

		// SSO 다녀온지 판별 후 즉시 이벤트 실행
		if (this.$route.query.immediateEvent) this.branchOfJoinEventByLogin()
	},
	methods: {
		...mapActions('user', ['loadUserInstituteInfo']),
		isEmptyObject(obj) {
			if (!obj) return true
			return Object.keys(obj).length === 0 && obj.constructor === Object
		},
		async init() {
			try {
				if (isLogin()) await this.loadUserInstituteInfo() // 로그인한 사용자의 기관 조회
				const { data } = await this.fetchInstitute() // 초대한 기관 정보 조회
				this.inviteInfo = data

				this.setUserType() // 레거시 사용자인지 확인
				this.setLicenseStatus() // 라이선스 만료 또는 개수 초과인지 확인
				this.setTitleArea() // 조회한 정보에 따라 초대받기 메인 타이틀 변경
				this.setInvitationInstitute() // 초대한 기관 정보 설정
				this.setAnotherInstitute() // 로그인한 사용자의 기관 정보 설정
			} catch (e) {
				console.error(e)

				// 유효하지 않은 초대 코드인 경우 로그인한 사용자의 기관 유무에 따라 화면 이동
				if (e.message.includes('404')) {
					if (!alert('유효하지 않는 초대 코드입니다.')) {
						this.institute ? this.routeDashboard() : this.routeCreateInstitution()
					}
				}
			}
		},
		fetchInstitute() {
			if (!this.$route.query.code) return
			return memberAPI.lookupInviteMember(this.$route.query.code)
		},
		setUserType() {
			this.isLegacyUser = this.user?.isLegacyUser
		},
		setLicenseStatus() {
			this.isOverCountLicense = this.inviteInfo.institute.memberCount >= this.inviteInfo.license.quantity
			this.isExpired = this.inviteInfo.license.status === 'EXPIRED'
		},
		setTitleArea() {
			if (isLogin()) {
				if (this.isLegacyUser && this.isAlreadyJoinInstitute) {
					this.title = '랩매니저 PRO로 업그레이드하고<br>초대받은 그룹에 가입하세요.'
					this.content = '초대받은 기관은 랩매니저 PRO를 사용하고 있습니다.<br>초대 정보를 확인하고, 해당 기관에 가입합니다.'
					this.subText = ''
				} else if (this.isLegacyUser && !this.isAlreadyJoinInstitute) {
					this.title = '랩매니저 PRO로 업그레이드하고<br>초대받은 그룹에 가입하세요.'
					this.content = '초대받은 그룹은 랩매니저 PRO를 사용하고 있습니다.<br>그룹 정보를 확인하고, 해당 그룹에 가입합니다.'
					this.subText = ''
				} else {
					this.title = '초대받은 그룹에 가입 하시겠습니까?'
					this.content = '초대받은 그룹에 가입하거나, 기존 소속기관에 접속하여,<br>랩매니저 PRO를 사용할 수 있습니다.'
					this.subText = ''
				}
			} else {
				this.title = ' 초대받은 그룹에 가입하세요.'
				this.content = '초대받은 그룹 정보를 확인하고, 해당 그룹에 가입합니다.'
				this.subText = ''
			}
		},
		setInvitationInstitute() {
			let title = this.tt('${1}에 가입하기', this.convertTitle())

			this.setInstitute(
				this.invitationInstitute,
				title,
				this.tt('${1}님의 초대를 수락합니다.', this.inviteInfo.host.name),
				this.inviteInfo.institute.logo ? this.inviteInfo.institute.logo : this.icon.default
			)
		},
		convertTitle() {
			let title = this.inviteInfo.institute.name
			if (this.inviteInfo.groups.length === 1) {
				title += ` ${this.inviteInfo.groups[0].name}`
			} else if (this.inviteInfo.groups.length > 1) {
				title += ` ${this.inviteInfo.groups[0].name} 외 ${this.inviteInfo.groups.length - 1}개의 연구실`
			}
			return title
		},
		setAnotherInstitute() {
			if (this.isLegacyUser) {
				if (this.isAlreadyJoinInstitute) {
					// 레거시 사용자 && 이미 가입한 기관이 있는 경우
					this.setInstitute(
						this.anotherInstitute,
						this.tt('기존 랩매니저 이용하기'),
						this.tt('기존 랩매니저를 그대로 이용하실 수 있습니다.'),
						this.icon.legacy
					)
				} else {
					// 레거시 사용자 && 가입한 기관이 없는 경우
					this.setInstitute(
						this.anotherInstitute,
						this.tt('새로운 기관 생성하기'),
						this.tt('초대를 수락하지 않고 새로운 기관을 생성합니다.'),
						this.icon.add
					)
				}
			} else if (this.isAlreadyJoinInstitute) {
				// PRO 사용자 && 이미 가입한 기관이 있는 경우
				this.setInstitute(
					this.anotherInstitute,
					`${this.tt('기존 소속기관으로 접속하기')} (${this.institute.name})`,
					this.tt('초대를 수락하지 않고, 기존에 소속된 기관으로 접속합니다.'),
					this.institute && this.institute.logo ? this.institute.logo : this.icon.default
				)
			} else {
				// PRO 사용자 && 가입한 기관이 없는 경우
				this.setInstitute(
					this.anotherInstitute,
					this.tt('새로운 기관 생성하기'),
					this.tt('초대를 수락하지 않고 새로운 기관을 생성합니다.'),
					this.icon.add
				)
			}
		},
		// 컴포넌트별 기관(institute) 정보 세팅
		setInstitute(institute, title, subTitle, logo) {
			institute.title = title
			institute.subTitle = subTitle
			institute.logo = logo
		},
		// 초대 기관 컴포넌트 클릭 이벤트 (분기별 타입, 모달 세팅)
		onClickJoinInstitute() {
			if (isLogin()) {
				// 로그인한 경우 분기 처리
				this.branchOfJoinEventByLogin()
			} else {
				// 로그인하지 않은 경우 분기 처리 - SSO 이동
				this.branchOfJoinEventByNotLogin()
			}
		},
		branchOfJoinEventByLogin() {
			if (this.isExpired) {
				if (this.isLegacyUser) {
					// 라이선스 만료 시 레거시 사용자인 경우
					this.showExpireModal(true)
				} else {
					// 라이선스 만료 시 레거시 사용자가 아닌 경우
					this.showExpireModal()
				}
			} else if (this.isOverCountLicense) {
				// 라이선스 개수 초과인 경우
				this.showOverCountModal()
			} else if (this.isLegacyUser) {
				// 레거시 사용자인 경우
				this.showJoinProModal()
			} else if (this.isAlreadyJoinInstitute) {
				// 이미 가입한 기관이 있는 PRO 사용자인 경우
				this.showJoinNewModal()
			} else {
				// 그 외의 경우 바로 가입 후 대시보드로 이동
				this.acceptInvite()
			}
		},
		branchOfJoinEventByNotLogin() {
			goToSSOMain(`${location.href}&immediateEvent=true`)
		},
		// 사용자가 가입한 기관 컴포넌트 클릭 이벤트 (분기별 타입, 모달 세팅)
		onClickCreateInstitute() {
			if (this.isLegacyUser) {
				if (this.isAlreadyJoinInstitute) {
					// 레거시 사용자 && 이미 가입한 기관이 있는 경우
					this.showLegacyModal()
				} else {
					// 레거시 사용자 && 가입한 기관이 없는 경우
					this.showCreateModal(true)
				}
			} else {
				if (this.isAlreadyJoinInstitute) {
					// PRO 사용자 && 이미 가입한 기관이 있는 경우
					this.showJoinAlreadyModal()
				} else {
					// PRO 사용자 && 가입한 기관이 없는 경우
					this.showCreateModal()
				}
			}
		},
		showExpireModal(isConfirmEmail = false) {
			this.inviteType = isConfirmEmail ? INVITE_TYPE.CONFIRM_EMAIL_EXPIRE : INVITE_TYPE.EXPIRE
			this.showConfirmModal(
				'새로운 기관 가입하기',
				`현재 가입 중인 기관은 <span style="color: #ff3b31; font-weight: bold;">라이선스가 만료된 상태</span>입니다.<br>
                라이선스 구매 전에는 정상적인 사용이 불가능합니다.<br>
                그래도 가입하시겠습니까?`,
				'가입하기'
			)
		},
		showOverCountModal() {
			this.inviteType = INVITE_TYPE.OVER_COUNT
			this.showConfirmModal(
				'가입실패',
				`현재 가입 중인 기관의 <span style="color: #ff3b31; font-weight: bold;">라이선스 개수가 부족</span>합니다.<br>
                <span style="font-weight: bold;">라이선스 구매 후, 다시 시도</span>해주세요.`,
				'',
				'닫기',
				true
			)
		},
		showJoinProModal() {
			this.inviteType = INVITE_TYPE.JOIN_PRO
			this.showConfirmModal(
				'랩매니저 PRO 업그레이드하고, 가입하기',
				`랩매니저 PRO로 업그레이드하고, 초대받은 그룹에 가입합니다.<br>
                이후, 기존 랩매니저의 접속 및 사용은 불가능합니다.<br>
                그래도 가입하시겠습니까?`,
				'가입하기'
			)
		},
		showJoinNewModal() {
			this.inviteType = INVITE_TYPE.JOIN_NEW
			this.showConfirmModal(
				'새로운 기관 가입하기',
				`모든 멤버는, 오직 하나의 기관에만 가입할 수 있습니다.<br>
				초대받은 새로운 기관에 가입하면, 기존 기관에서 자동 탈퇴됩니다.<br>
				그래도 가입하시겠습니까?
				<div>
                    <ul>
                        <li style="display: flex; flex-direction: row; align-items: flex-start; padding: 10px 15px;
                            background: #FAFAFC; border: 1px solid #eeeeee; border-radius: 10px; margin: 30px 0px 10px 0;
                            overflow: hidden; white-space: nowrap;">
                            기존 소속기관: ${this.inviteInfo.institute.name}
                        </li>
                    </ul>
                </div>`,
				'가입하기'
			)
		},
		showCreateModal(isConfirmEmail = false) {
			this.inviteType = isConfirmEmail ? INVITE_TYPE.CONFIRM_EMAIL_CREATE : INVITE_TYPE.CREATE
			this.showConfirmModal('새로운 기관 생성하기', '초대받은 그룹에 가입하지 않고, 새로운 기관을 생성하시겠습니까?', '생성하기')
		},
		showLegacyModal() {
			this.inviteType = INVITE_TYPE.LEGACY
			this.showConfirmModal('기존 랩매니저 이용하기', '초대받은 그룹에 가입하지 않고, 기존 랩매니저를 이용하시겠습니까?', '이용하기')
		},
		showJoinAlreadyModal() {
			this.inviteType = INVITE_TYPE.JOIN_ALREADY
			this.showConfirmModal(
				'기존 소속기관으로 접속하기',
				`초대에 응하지 않고, 기존 소속기관으로 접속합니다.<br>
                초대는 자동으로 취소됩니다.`,
				'기존 소속기관 접속'
			)
		},
		// 모달 값 설정 후 보여주기
		showConfirmModal(title, content, confirmText, cancelText = '취소', isHideConfirm = false) {
			this.confirmModalTitle = this.tt(title)
			this.confirmModalContent = this.tt(content)
			this.confirmModalButtonText = this.tt(confirmText)
			this.confirmModalCancelButton = this.tt(cancelText)
			this.isHideModalConfirmBtn = isHideConfirm
			this.isShowModal = true
		},
		// 모달 확인버튼(가입하기, 생성하기 등) 이벤트
		onClickConfirmModal() {
			// 모달 오픈할 때 설정한 타입으로 분기 처리
			switch (this.inviteType) {
				case INVITE_TYPE.EXPIRE: // 라이선스 만료 (초대 기관 컴포넌트)
				case INVITE_TYPE.CONFIRM_EMAIL_EXPIRE: // 라이선스 만료 && 이메일 인증 필요 (초대 기관 컴포넌트)
					// 가입 -> 라이선스 만료 페이지(LicenseExpirePage.vue)로 이동
					// 이메일 인증이 필요한 경우(레거시) 해당 페이지에서 이메일 인증 모달 호출
					this.aceeptInviteExpired()
					break
				case INVITE_TYPE.CREATE: // 새로운 기관 생성 (기존 기관 컴포넌트)
					// 조직 생성하기로 이동
					this.routeCreateInstitution()
					break
				case INVITE_TYPE.CONFIRM_EMAIL_CREATE: // 새로운 기관 생성 && 이메일 인증 필요 (기존 기관 컴포넌트)
					// 이메일 인증 모달 호출
					this.$emit('openEmailCertificationModal')
					break
				case INVITE_TYPE.LEGACY: // 레거시 사용자 && 이미 가입한 기관이 있는 경우 (기존 기관 컴포넌트)
				case INVITE_TYPE.JOIN_ALREADY: // PRO 사용자 && 이미 가입한 기관이 있는 경우 (기존 기관 컴포넌트)
					// 기존 기관 대시보드로 이동
					this.routeDashboard()
					break
				case INVITE_TYPE.JOIN_PRO: // 레거시 사용자인 경우 (초대 기관 컴포넌트)
					// 가입 -> 이메일 인증 페이지로 이동
					this.acceptInviteCertificated()
					break
				case INVITE_TYPE.JOIN_NEW: // PRO 사용자 && 이미 가입한 기관이 있는 경우 (초대 기관 컴포넌트)
					// 기존 기관 탈퇴 후 초대 받은 기관 가입하기
					this.acceptInvite()
					break
			}
		},
		async acceptInvite() {
			await this.accept()
			await this.loadUserInstituteInfo()
			this.routeDashboardWithIsJoin()
		},
		async acceptInviteCertificated() {
			await this.accept()
			goToSSOEmail()
		},
		async aceeptInviteExpired() {
			await this.accept()
			this.routeLicenseExpirePage()
		},
		accept() {
			return memberAPI.lookupAcceptInvite({
				code: this.$route.query.code,
			})
		},
		// 대시보드로 이동
		routeDashboard() {
			this.$router.replace({
				name: PageName.Dashboard,
				params: {
					instituteId: this.institute && this.institute.id ? this.institute.id : null,
				},
			})
		},
		// 대시보드로 이동 - isJoin 파라미터 추가 (대시보드에서 가입완료 모달을 띄우기 위해)
		routeDashboardWithIsJoin() {
			this.$router.replace({
				name: PageName.Dashboard,
				params: {
					instituteId: this.institute.id,
					isJoin: true,
					joinModalTitle: '참여 완료!',
				},
			})
		},
		// 라이선스 만료 페이지로 이동
		routeLicenseExpirePage() {
			this.$router.replace({
				name: PageName.Invite.Expire,
				params: {
					name: this.convertTitle(),
					logo: this.inviteInfo.institute.logo ? this.inviteInfo.institute.logo : this.icon.default,
				},
			})
		},

		mobileCheck() {
			let check = false
			;(function (a) {
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
						a
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true
			})(navigator.userAgent || navigator.vendor || window.opera)
			return check
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background: white;
	.content-slide-wrapper {
		width: 100vw;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		align-items: center;
		.content-wrapper {
			width: 750px;
			display: flex;
			flex-direction: column;
			.text-wrapper {
				margin-bottom: 40px;
				.title {
					font-size: 48px;
					font-style: normal;
					font-weight: 700;
					line-height: 70px;
					letter-spacing: 0px;
					text-align: center;
					margin-bottom: 20px;
				}
				.content {
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 26px;
					letter-spacing: 0px;
					text-align: center;
					margin-bottom: 10px;
				}
				.sub-text {
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 17px;
					letter-spacing: 0px;
					text-align: center;
					color: $SECONDARY_2;
				}
			}
			.institute-item-wrapper {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
		.invite-footer {
			margin: 10px 0 0 0;
			font-size: 14px;
			color: $SECONDARY_2;
			span {
				font-weight: bold;
			}
		}
	}
}
</style>
