import locationAPI from '@/services/api/institute/location'
import { getListByKeyword, getListBySelected, getStorageCount } from '@/utils/Tree'

const state = {
	selectedNode: null,
	usingTreeNodeType: null,
	keyword: '',
	tree: [],
	list: [],
}

const getters = {
	selectedNode: state => state.selectedNode,
	usingTreeNodeType: state => state.usingTreeNodeType,
	keyword: state => state.keyword,
	tree: state => state.tree,
	list: state => state.list,
}

const mutations = {
	setSelectedNode: (state, nodeId) => {
		state.selectedNode = nodeId
	},
	setUsingTreeNodeType: (state, usingTreeNode) => {
		state.usingTreeNodeType = usingTreeNode
	},
	setKeyword: (state, keyword) => {
		state.keyword = keyword
		if (state.keyword.length == 0 && state.selectedNode) {
			state.list = getListBySelected(state.tree, [state.selectedNode.id])
		} else {
			state.list = getListByKeyword(state.tree, state.keyword)
		}
	},
	setTree: (state, tree) => {
		state.tree = tree
	},
	setTreeOpen: (state, isOpen) => {
		state.list = getListByKeyword(state.tree, state.keyword, isOpen)
	},
}

const actions = {
	getLocationStorageTree: async ({ commit, rootGetters }) => {
		const response = await locationAPI.lookupLocationStorageTree(rootGetters.instituteId)
		commit('setTree', response.data)
		commit('setKeyword', '')
	},
}
export default { namespaced: true, state, getters, mutations, actions }
