var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.tt('라이선스 기간 추가')) + " ")];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('결제하기')) + " ")])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "license-select-wrapper"
  }, [_c('div', {
    staticClass: "license-summary"
  }, [_c('img', {
    staticClass: "imgbox",
    attrs: {
      "src": require("@/assets/images/lm-pro-icon.png")
    }
  }), _c('div', {
    staticClass: "txtbox"
  }, [_c('h6', [_vm._v(_vm._s(_vm.licenseTypeText))]), _vm.isFreeTier ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('30일 무료')) + " ")]) : _vm.isTrial ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('무료')) + " ")]) : _vm.isEducation ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('${1}원 / 연구실당 (연 기준 ・ VAT 별도)', _vm.formatNumberLocale(_vm.licenseRatePlan['YEAR']['EDUCATION']))) + " ")]) : _c('p', [_vm._v(" " + _vm._s(_vm.tt('${1}원 / 라이선스 당(월 기준 ・ VAT 별도)', _vm.formatNumberLocale(_vm.getAdditonalPrice))) + " ")])])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.tt('현재')))]), _c('div', {
    staticClass: "flex"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('멤버 수 / 라이선스')))]), _c('span', {
    staticClass: "license-member"
  }, [_c('span', {
    staticClass: "license-now-member",
    "class": {
      red: _vm.instInfo.allMemberCount >= this.license.quantity
    }
  }, [_vm._v(" " + _vm._s(_vm.formatNumberLocale(_vm.instInfo.allMemberCount)) + "  ")]), _c('span', {
    staticClass: "license-quantity"
  }, [_vm._v(" " + _vm._s(!(_vm.isTrial || _vm.isFreeTier || !_vm.isLicenseActivate) ? ' / ' + (_vm.license.quantity == _vm.MAX_QUANTITY ? _vm.tt('무제한') : _vm.formatNumberLocale(_vm.license.quantity)) : '') + " ")])])])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.tt('추가할 기간')))]), _c('div', {
    staticClass: "num-box"
  }, [_c('button', {
    "class": {
      cant: _vm.month < 2 || _vm.month <= 12 && _vm.isEducation
    },
    on: {
      "click": _vm.reducePeriod
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-minus.svg")
    }
  })]), _vm.isEducation ? _c('p', {
    staticClass: "license-count-unit"
  }, [_vm._v(_vm._s(_vm.tt('${1}년', _vm.month / 12)))]) : _c('p', {
    staticClass: "license-count-unit"
  }, [_vm._v(_vm._s(_vm.tt('${1}개월', _vm.month)))]), _c('button', {
    "class": {
      cant: _vm.month >= 11 && !_vm.isEducation
    },
    attrs: {
      "id": "add-period"
    },
    on: {
      "click": _vm.addPeriod
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-plus.svg")
    }
  })]), _vm.month >= 11 && !_vm.isEducation ? _c('b-tooltip', {
    attrs: {
      "target": "add-period",
      "triggers": "hover"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('기간은 11개월까지 추가할 수 있습니다.')) + " ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "license-date"
  }, [_c('ul', [_c('li', [_c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.month % 12 == 0 ? _vm.tt('1년 당') : _vm.tt('1개월 당')) + " ")]), _c('span', [_vm._v("   " + _vm._s(_vm.tt('결제 금액')) + " ")])]), _c('span', [_vm._v(" ￦" + _vm._s(_vm.formatNumberLocale(parseInt(_vm.month % 12 == 0 ? _vm.licensePurchase.price : _vm.licensePurchase.price / _vm.month)) + '(VAT 미포함)') + " ")])]), _c('li', [_c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.tt('추가 기간(${1})', _vm.month % 12 == 0 ? _vm.month / 12 + '년' : _vm.month + '개월')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.tt('의 결제 금액')) + " ")])]), _c('span', [_vm._v(" ￦" + _vm._s(_vm.formatNumberLocale(_vm.licensePurchase.totalPrice) + '(VAT 포함)') + " ")])])])]), _c('div', {
    staticClass: "license-price"
  }, [_c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('소계')))]), _c('span', [_vm._v(" ￦" + _vm._s(_vm.formatNumberLocale(_vm.licensePurchase.price)) + " ")])]), _c('li', [_c('p', [_vm._v("VAT(10%)")]), _c('span', [_vm._v(" ￦" + _vm._s(_vm.formatNumberLocale(_vm.licensePurchase.vat)) + " ")])]), _c('li', [_c('h3', [_vm._v(_vm._s(_vm.tt('합계')))]), _c('h2', [_vm._v("￦" + _vm._s(_vm.formatNumberLocale(_vm.licensePurchase.totalPrice)))])])])]), _c('div', {
    staticClass: "license-payment"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('결제수단')))]), _c('div', [_c('label', {
    staticClass: "card-registered-label"
  }, [_c('div', {
    staticClass: "input-div",
    on: {
      "click": function click($event) {
        _vm.selectedPayment = _vm.paymentMethod.CARD_REGISTERED;
      }
    }
  }, [_vm.selectedPayment != _vm.paymentMethod.CARD_REGISTERED ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/radio-off.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/svg/radio-on.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt(_vm.hasDefaultCreditCard ? '등록된 카드로 결제' : '카드 등록 후 결제')) + " ")]), _vm.selectedPayment == _vm.paymentMethod.CARD_REGISTERED && _vm.hasDefaultCreditCard ? _c('div', {
    staticClass: "change-card",
    on: {
      "click": _vm.editCard
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('결제카드 변경')))]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-blue-right-arrow.svg"),
      "width": "16",
      "height": "24"
    }
  })]) : _vm._e()]), _c('label', {
    on: {
      "click": function click($event) {
        _vm.selectedPayment = _vm.paymentMethod.ELECTRONIC_BILL;
      }
    }
  }, [_vm.selectedPayment != _vm.paymentMethod.ELECTRONIC_BILL ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/radio-off.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/svg/radio-on.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt('전자세금계산서')) + " ")])])]), _c('div', {
    staticClass: "license-agree"
  }, [_c('div', {
    staticClass: "agreement-box"
  }, [_c('label', {
    staticStyle: {
      "cursor": "pointer"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.agreement,
      expression: "agreement"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.agreement) ? _vm._i(_vm.agreement, null) > -1 : _vm.agreement
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.agreement,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.agreement = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.agreement = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.agreement = $$c;
        }
      }
    }
  }), _vm._v(" " + _vm._s(_vm.tt('(필수) 이용약관을 확인하였으며, 구매 진행에 동의합니다.')) + " ")]), _c('a', {
    attrs: {
      "href": "https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('보기')) + " ")])])])])], _c('ModalCard', {
    ref: "ModalCard"
  }), _c('ModalEbill', {
    ref: "ModalEbill"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }