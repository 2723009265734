var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "legacy-item-wrapper",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "item-header"
  }, [_vm._m(0), _c('div', {
    staticClass: "text-wrapper"
  }, [_c('div', {
    staticClass: "text-title",
    domProps: {
      "innerHTML": _vm._s(_vm.titleText)
    }
  }), _c('div', {
    staticClass: "text-content",
    domProps: {
      "innerHTML": _vm._s(_vm.contentText)
    }
  })]), _vm._m(1)]), _c('div', {
    staticClass: "item-institute-info"
  }, [_c('ul', {
    staticClass: "item-text-list"
  }, [_c('li', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.tt('기관명')) + " : " + _vm._s(_vm.item.name))]), _c('li', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.tt('연구실')) + " : " + _vm._s(_vm.item.groupNames))]), _c('li', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.tt('요청인')) + " : " + _vm._s(_vm.item.userName))]), _c('li', {
    staticClass: "item-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('요청 일시')) + " : " + _vm._s(_vm.formatDatetime(_vm.item.startDatetime)) + " ")])])]), _c('div', {
    staticClass: "item-license-info"
  }, [_c('ul', {
    staticClass: "item-text-list"
  }, [_c('li', {
    staticClass: "item-text",
    domProps: {
      "innerHTML": _vm._s(_vm.licenseText)
    }
  }), !_vm.isStatusActive ? _c('li', {
    staticClass: "item-text",
    domProps: {
      "innerHTML": _vm._s(_vm.licenseCountText)
    }
  }) : _vm._e()])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon-wrapper"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/migration/icon-new-lab-manager.svg")
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "arrow-wrapper"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow/icon-arrow-right.svg")
    }
  })]);
}]

export { render, staticRenderFns }