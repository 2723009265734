<template>
	<div class="Organization__group">
		<div class="AppContainer__subtitle">
			<h6>
				{{ isTypeInstitution ? tt('기관 멤버') : tt('그룹원') }}
			</h6>
			<button class="AppContainer__subtitle-btn btn" v-if="isTypeInstitution && !isStart" @click="goMember">
				<img src="@/assets/svg/member.svg" alt="icon" />
				{{ tt('멤버 전체 보기') }}
			</button>
			<button
				v-else-if="(!isTypeInstitution && userPermission.institute && userPermission.institute.organization.update) || isStart"
				class="AppContainer__subtitle-btn btn Btn__hover-balloon"
				:class="[{ point: (isStart && order == 2) || isHint }, { pointTop: (isStart && order == 2) || isHint }]"
			>
				<img @click="openModalAddMember" src="@/assets/svg/add-member.svg" alt="icon" />
				<span @click="openModalAddMember">{{ tt('그룹원 추가') }}</span>
				<div class="balloon" v-if="!isStart">
					{{ tt('기관에 소속된 멤버를 내 그룹에 추가할 수 있어요') }}
				</div>
				<div class="order-balloon" v-if="(isStart && order == 2) || isHint">
					<div class="order-top">
						<div class="order">
							<span></span>
							<span class="active"></span>
							<span></span>
						</div>
						<button @click="closeOrder" v-if="!isHint">
							<img src="@/assets/svg/popup-close.svg" alt="close" />
						</button>
					</div>
					<h6>{{ tt('2. 그룹원 추가하기') }}</h6>
					<p>{{ tt('그룹에 소속된 멤버를 추가해보세요.') }}</p>
				</div>
			</button>
		</div>
		<div class="Organization__group-list">
			<ul class="horizational-list" v-if="hasMember">
				<li
					class="Organization__group-item"
					v-for="(member, idx) in groupMembers.list"
					:key="`member-${idx}`"
					@click="openModalMemberInfo(member.id)"
				>
					<img v-if="hasImage(member.image)" :src="member.image" alt="group-image" /><img
						v-else
						src="@/assets/svg/member-photo-default.svg"
						alt="group-image"
					/>
					<div class="Organization__group-item-info">
						<h6>{{ member.name }}</h6>
						<p>{{ member.email }}</p>
					</div>
					<button
						class="AppContainer__subtitle-btn btn Btn__hover-balloon r-m20"
						@click.stop="removeGroupMember(member.id)"
						v-if="!isTypeInstitution && userPermission.institute && userPermission.institute.organization.update"
					>
						{{ tt('내보내기') }}
					</button>
				</li>
			</ul>
			<no-group-member v-else></no-group-member>
			<organization-pagination
				v-if="hasMembers"
				:isPerPage="false"
				:total="groupMembers.count"
				@page="getGroupMemberInfoParams"
			></organization-pagination>
		</div>
	</div>
</template>

<script>
import NoGroupMember from '@/views/institute/components/NoGroupMember.vue'
import PageName from '@/utils/define/PageName'
import OrganizationPagination from '@/views/institute/organization/components/OrganizationPagination.vue'

export default {
	name: 'OrganizationMemberList',
	components: {
		NoGroupMember,
		OrganizationPagination,
	},
	props: {
		groupInfo: {
			type: Object,
			default: () => {
				0
			},
		},
		groupMembers: {
			type: Object,
			default: () => {
				0
			},
		},
		isStart: {
			type: Boolean,
			default: false,
		},
		isHint: {
			type: Boolean,
			default: false,
		},
		order: {
			type: Number,
			default: 0,
		},
	},
	watch: {},
	data() {
		return {}
	},
	computed: {
		hasMember() {
			if (this.groupInfo && this.groupInfo.members) {
				return this.members.length > 0
			}
		},
		members() {
			if (this.groupInfo && this.groupInfo.members) {
				return this.groupInfo.members
			}
		},
		isTypeInstitution() {
			return this.groupInfo && this.groupInfo.type === 'INSTITUTE'
		},
		hasMembers() {
			return this.groupMembers.count > 0
		},
	},
	methods: {
		hasImage(img) {
			return img !== null
		},
		openModalAddMember() {
			this.$emit('openModal', 'ADD_MEMBER')
		},
		openModalMemberInfo(memberId) {
			this.$emit('openModalMemberInfo', memberId)
		},
		removeGroupMember(memberId) {
			this.$emit('openDeleteModal', memberId)
		},
		goMember() {
			this.$router.push({
				name: PageName.Institution.MemberManagement,
			})
		},
		closeOrder() {
			this.$emit('closeOrder')
		},
		getGroupMemberInfoParams(params) {
			this.$emit('getGroupMembersParams', params)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_organization.scss';

.Btn__hover-balloon {
	padding: 5px 10px !important;
}

.Btn__hover-balloon {
	&:hover {
		.balloon {
			visibility: visible !important;
			opacity: 1 !important;
			display: block !important;
		}
	}
	&:focus {
		.balloon {
			display: none !important;
		}
	}
}
</style>
