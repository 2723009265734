var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-wrapper"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.tt('예산 정보')) + " ")]), _c('div', {
    staticClass: "order-user-wrapper"
  }, [_c('div', {
    staticClass: "type-group"
  }, [_c('input-text', {
    attrs: {
      "title": "주문 연구실",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "inputValue": _vm.selectedGroup ? _vm.selectedGroup.name : _vm.tt('주문 연구실을 선택해주세요'),
      "isReadOnly": true
    }
  }), _c('button', {
    on: {
      "click": _vm.showSelectedGroupModal
    }
  }, [!_vm.selectedGroup ? _c('img', {
    attrs: {
      "src": require('@/assets/svg/Next.svg')
    }
  }) : _c('img', {
    attrs: {
      "src": require('@/assets/svg/icon-delete-2.svg')
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.completeSelectedGroup(null);
      }
    }
  })])], 1), _c('input-select', {
    ref: "budgetId",
    attrs: {
      "title": '예산',
      "inputWidthSize": 400,
      "placeholder": '예산을 선택해주세요',
      "list": _vm.getUsableBudgetList,
      "item": _vm.draftInfo.budgetId
    },
    on: {
      "change": _vm.changeBudget
    }
  }, [_vm.getUsableBudgetList.length === 1 ? _c('div', {
    staticClass: "list-no-data"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.selectedGroup ? '등록된 예산이 없습니다.' : '주문 연구실을 선택해주세요')))]), _vm.selectedGroup ? _c('span', {
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'BudgetCreate'
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('예산 등록하기')))]) : _vm._e()]) : _vm._e()])], 1), _c('modal-select-group', {
    ref: "modal-select-group",
    attrs: {
      "title": "기안 연구실 선택",
      "isSelectSingle": true,
      "defaultData": _vm.groups
    },
    on: {
      "confirm": _vm.completeSelectedGroup
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }