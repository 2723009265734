var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "add-count-wrap",
    style: "top: ".concat(_vm.top, "px; right: calc(100vw - ").concat(_vm.right, "px)")
  }, [_c('div', [_c('b', [_vm._v(_vm._s(_vm.tt('추가할 수량')))]), _c('p', [_vm._v(_vm._s(_vm.tt('을 입력해주세요.')))]), _c('button', {
    on: {
      "click": _vm.hide
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('input-button-counter', {
    ref: "input-button-counter",
    attrs: {
      "min": _vm.min,
      "sizeType": "sm"
    },
    model: {
      value: _vm.quantity,
      callback: function callback($$v) {
        _vm.quantity = $$v;
      },
      expression: "quantity"
    }
  }), _c('base-button', {
    staticStyle: {
      "height": "40px"
    },
    attrs: {
      "color": 'primary'
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(_vm._s(_vm.tt('추가하기')))])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }