<template>
	<div class="lm-button-wrapper">
		<button class="lm-button" :class="color" @click.stop="handleClick">
			<span>{{ text }}</span>
		</button>
	</div>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: '',
		},
	},
	methods: {
		handleClick() {
			this.$emit('click')
		},
	},
}
</script>

<style lang="scss" scoped>
button.lm-button {
	width: 200px;
	height: 47px;
	background-color: $LAB_YELLOW;
	cursor: pointer;

	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 23px;
	letter-spacing: 0px;
	text-align: center;
	color: $DEFAULT;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	justify-content: center;
	&:hover {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), $LAB_YELLOW;
	}

	&.gray {
		background: $GREY_2;
		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $GREY_4, $GREY_4);
		}
	}
}
</style>
