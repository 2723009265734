var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "lm-table"
  }, [_c('table-header'), _vm._l(_vm.list, function (item) {
    return _c('table-item', {
      key: item.id,
      attrs: {
        "item": item
      }
    });
  }), _vm.count === 0 ? _c('div', {
    staticClass: "empty-table"
  }, [_c('img', {
    staticClass: "empty-img",
    attrs: {
      "src": _vm.emptyImage
    }
  }), _c('p', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.tt(_vm.emptyText)))]), _c('p', {
    staticClass: "sub",
    domProps: {
      "innerHTML": _vm._s(_vm.emptySubText)
    }
  })]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }