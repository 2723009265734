<template>
	<div class="tree-node-wrap" :class="{ root: node.parentId == null && type !== 'member' }">
		<div class="tree-node" :class="{ active: isActive(node) }">
			<div class="info-wrap" :class="{ default: type !== 'member', root: type === 'root' }" @dblclick.stop="selectMember(node)">
				<div v-if="isSubtree">
					<img v-if="isTreeOpen" class="tree-close" src="../svg/icon-node-close.svg" width="16" height="24" @click="toggleTreeOpen" />
					<img v-else class="tree-open" src="../svg/icon-node-open.svg" width="16" height="24" @click="toggleTreeOpen" />
				</div>
				<img
					v-if="type === 'root'"
					class="tree-icon"
					style="filter: brightness(0)"
					src="@/assets/svg/institution-building.svg"
					width="20"
					height="20"
				/>
				<img v-else-if="type === 'member' && isActive(node)" class="tree-icon" src="../svg/icon-member_y.svg" width="20" height="20" />
				<img v-else-if="type === 'member'" class="tree-icon" src="../svg/icon-member.svg" width="20" height="20" />
				<img v-else class="tree-icon" src="@/assets/svg/group.svg" width="20" height="20" />
				<div class="name" v-html="name"></div>
			</div>
		</div>
		<!-- TODO: isCheckbox로 체크박스 사용 모달로 변경? -->
		<!-- <div class="checkbox" v-if="!notChecked">
			<input type="checkbox" :checked="selectedGroups.find(g => g.id == node.id)" @change="selectGroup(node)" />
		</div> -->
		<div v-if="isTreeOpen" class="node-children">
			<tree-node ref="child-node" v-for="group in node.children" :key="group.id" :node="group" :type="'group'" :parent="node"></tree-node>
			<template>
				<tree-node
					ref="child-node"
					v-for="member in node.members"
					:key="member.id"
					:node="member"
					:type="'member'"
					:parent="node"
				></tree-node>
			</template>
		</div>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import MixinTree from '@/mixins/tree'
export default {
	name: 'TreeNode',
	components: {},
	props: {
		node: {
			type: [Object, null],
			default: null,
		},
		type: {
			type: String,
			default: '',
		},
		parent: {
			type: [Object, null],
			default: null,
		},
	},
	mixins: [MixinTree],
	computed: {
		...mapState('institute/member', ['selectedMembers', 'keyword']),
	},
	methods: {
		...mapMutations('institute/member', ['setSelectMember']),
		selectMember(member) {
			if (this.selectedMembers.some(selectedMember => selectedMember.id === member.id)) {
				this.$root.toast(this.tt('결재선 추가 불가'), this.tt('멤버를 중복해서 추가할 수 없습니다.'), 'error')
				return false
			}
			if (this.type === 'member' && this.selectedMembers.length < 4) this.setSelectMember({ ...member, ...{ parent: this.parent } })
		},
		isActive(node) {
			return this.selectedMembers.some(member => member.id === node.id && member.parent.id === this.parent.id)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../scss/_tree-node.scss';
</style>
