<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('태그 편집') }}</div>
		</template>
		<template>
			<div class="select-explain">
				{{ tt('삭제할 태그를 선택하세요') }}
			</div>
			<div class="tag-list">
				<div class="tag" :class="{ active: selectedIds.includes(tag.id) }" v-for="tag in tagList" :key="tag.id" @click="toggleTag(tag.id)">
					{{ `#${tag.name}` }}
				</div>
			</div>
		</template>
		<template #modal-footer>
			<button class="button-white" @click="$emit('cancel')">
				{{ tt('취소') }}
			</button>
			<button class="button-danger" :disabled="selectedIds.length == 0" @click="complete">
				{{ tt('삭제' + (selectedIds.length != 0 ? `(${selectedIds.length})` : '')) }}
			</button>
		</template>
		<ModalConfirm
			ref="modalDelete"
			title="태그 삭제"
			:content="`${selectedIds.length}개의 태그를 삭제하시겠습니까? 기존에 등록된 물품에서 태그가 모두 삭제됩니다.`"
			variant="warning"
			confirmText="삭제"
		/>
	</b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	computed: {
		...mapGetters('tag', ['tagList', 'selectedTag']),
	},
	data() {
		return {
			selectedIds: [],
		}
	},
	methods: {
		...mapActions('tag', ['getTagList', 'deleteTag']),
		async show() {
			await this.getTagList()
			this.selectedIds = []
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		toggleTag(id) {
			if (this.selectedIds.includes(id)) {
				this.selectedIds.remove(id)
			} else {
				this.selectedIds.push(id)
			}
		},
		complete() {
			this.$refs['modalDelete'].show(async () => {
				try {
					await this.deleteTag(this.selectedIds)
					this.$root.toast('태그 삭제', '태그가 삭제되었습니다.', 'success')
					await this.getTagList()
					this.hide()
				} catch (e) {
					this.$root.toast('태그 삭제', e?.response?.data?.msg, 'error')
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .modal-dialog {
	max-width: 700px;
	.modal-header {
		border-bottom: 1px solid $DIVIDER;
	}
	.modal-body {
		padding: 0px;
		.select-explain {
			width: 100%;
			font-size: 14px;
			color: $SECONDARY_1;
			text-align: center;
			padding: 10px 0px;
			background-color: $GREY_1;
			border-bottom: 1px solid $DIVIDER;
		}
		.tag-list {
			padding: 10px;
			display: inline-flex;
			flex-wrap: wrap;
			gap: 8px;
			.tag {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 30px;
				padding: 5px 8px;
				border-radius: 30px;
				color: $SECONDARY_1;
				background-color: $DIVIDER;
				border: 1px solid transparent;
				&.active {
					background-color: $SUB-RED;
					border: 1px solid $RED;
				}
			}
		}
	}
	.modal-footer {
		border-top: 1px solid $DIVIDER;
		button {
			border: 0;
			box-shadow: none;
			display: flex;
			height: auto;
			align-items: center;
			cursor: pointer;
			white-space: pre;
			transition: 0.5s all ease;
			min-width: 100px;
			padding: 5px 10px;
			font-size: 14px;
			font-weight: 700;
			border-radius: 5px;
			justify-content: center;
			&.button-white {
				background: $LAB_WHITE;
				border: 1px solid $DIVIDER;
				color: #111 !important;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
						linear-gradient(0deg, #fff, #fff);
				}
			}
			&.button-danger {
				background: $RED;
				color: $LAB_WHITE;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $RED, $RED);
				}
			}
		}
	}
}
</style>
