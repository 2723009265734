var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Btn__hover-balloon-w",
    on: {
      "click": _vm.handleClick
    }
  }, [_vm.iconColor === 'black' ? _c('img', {
    staticClass: "hover-question",
    attrs: {
      "src": require("@/assets/svg/hover-question-black.svg")
    }
  }) : _vm._e(), _vm.iconColor === 'gray' ? _c('img', {
    staticClass: "hover-question",
    attrs: {
      "src": require("@/assets/svg/hover-question-gray.svg")
    }
  }) : _vm._e(), _c('div', {
    staticClass: "balloon",
    "class": {
      left: _vm.direction === 'left'
    },
    style: _vm.balloonStyle
  }, [_c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }