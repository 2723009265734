var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Organization",
    attrs: {
      "id": "Organization"
    }
  }, [_c('div', {
    staticClass: "AppContainer__location-title"
  }, [_c('h5', {
    staticClass: "AppContainer__location-name"
  }, [_vm.groupInfo && _vm.groupInfo.type == 'INSTITUTE' ? _c('img', {
    staticClass: "instituteImage",
    attrs: {
      "src": require("@/assets/svg/institution-building_y.svg")
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.getGroupName) + " ")])]), _c('organization-info-item-list', {
    attrs: {
      "groupInfo": _vm.groupInfo,
      "userPermission": _vm.userPermission
    },
    on: {
      "openModalEditGroup": _vm.openModalEditGroup
    }
  }), _c('organization-member-list', {
    attrs: {
      "groupInfo": _vm.groupInfo,
      "groupMembers": _vm.groupMembers,
      "isStart": _vm.isStart,
      "order": _vm.order,
      "isHint": _vm.isHint,
      "userPermission": _vm.userPermission
    },
    on: {
      "openModal": _vm.openModalAddMember,
      "openDeleteModal": _vm.openModalDeleteMember,
      "openModalMemberInfo": _vm.openModalMemberInfo,
      "closeOrder": _vm.closeOrder,
      "getGroupMembersParams": _vm.getGroupMembersParams
    }
  }), _c('organization-access-list', {
    attrs: {
      "groupInfo": _vm.groupInfo,
      "isStart": _vm.isStart,
      "order": _vm.order,
      "isHint": _vm.isHint,
      "userPermission": _vm.userPermission
    },
    on: {
      "openMemberModal": _vm.openModalAddMemberAuth,
      "openGroupModal": _vm.openModalAddGroupAuth,
      "refresh": _vm.refresh,
      "closeOrder": _vm.closeOrder
    }
  }), _vm.groupInfo ? _c('organization-storage-box', {
    attrs: {
      "groupInfo": _vm.groupInfo,
      "isStart": _vm.isStart,
      "userPermission": _vm.userPermission
    },
    on: {
      "showModalDeleteGroup": _vm.openModalDeleteGroup,
      "showStorage": _vm.openModalStorage,
      "showModalDeleteStorage": _vm.showModalDeleteStorage,
      "showModalEditStorage": _vm.showModalEditStorage
    }
  }) : _vm._e(), _c('modal-add-storage-v-2', {
    ref: "modal-edit-storage",
    attrs: {
      "type": "EDIT"
    },
    on: {
      "refresh": _vm.refreshData
    }
  }), _c('modal-delete-location', {
    ref: "modal-delete",
    on: {
      "refresh": _vm.refreshData
    }
  }), _c('modal-add-member', {
    ref: "modal-add-member",
    attrs: {
      "groupInfo": _vm.groupInfo,
      "isStart": true
    },
    on: {
      "refresh": _vm.refresh,
      "changeOrderAccess": _vm.changeOrderAccess,
      "completeOrganization": _vm.completeOrganization
    }
  }), _c('modal-add-group', {
    ref: "modal-add-group",
    attrs: {
      "isStart": true
    },
    on: {
      "refresh": _vm.refresh,
      "changeOrderGroup": _vm.changeOrderGroup
    }
  }), _c('modal-confirm-delete', {
    ref: "modal-confirm-delete",
    attrs: {
      "title": _vm.title,
      "content": _vm.content
    },
    on: {
      "hideMemberInfo": _vm.hideMemberInfo,
      "refresh": _vm.refresh
    }
  }), _c('modal-add-group-auth', {
    ref: "modal-add-group-auth",
    attrs: {
      "groupInfo": _vm.groupInfo
    },
    on: {
      "refresh": _vm.refresh,
      "completeOrganization": _vm.completeOrganization
    }
  }), _c('modal-member-info', {
    ref: "modal-member-info",
    attrs: {
      "userPermission": _vm.userPermission
    },
    on: {
      "delete": _vm.openModalDeleteMember,
      "refresh": _vm.refresh,
      "showRegisteringMember": _vm.showRegisteringMember
    }
  }), _c('modal-treeview', {
    ref: "modal-treeview",
    attrs: {
      "portalTo": "lm-modal",
      "title": _vm.tt('보관함 관리'),
      "searchPlaceholder": _vm.tt('보관함을 검색해주세요'),
      "treeData": _vm.storageTreeData,
      "hideSubStorage": true,
      "mode": "multiStorage"
    },
    on: {
      "complete": _vm.hideModalStorage
    }
  }), _c('modal-registering-member', {
    ref: "modal-registering-member",
    attrs: {
      "page": "member",
      "userInfo": _vm.user
    },
    on: {
      "update": _vm.updateRegistering,
      "complete": _vm.completeRegistering
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }