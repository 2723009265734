import axiosInstance from '@/services/axios'
import cookies from 'vue-cookies'
import userAPI from '@/services/api/institute/user'
import { goToSSOMain, COOKIES_DOMAIN, logout } from '@/services/api/common'
import axios from 'axios'

cookies.config({
	domain: COOKIES_DOMAIN,
})

const axiosConfig = {
	initAxios: () => {
		axios.axiosInstance.defaults.baseURL = process.env.VUE_APP_BASE_URL
		axiosConfig.setAuthToken()
		axiosConfig.setGlobalFilter()
		axios.defaults.timeout = 100000

		//request intercepter
		axios.interceptors.request.use(
			function (config) {
				console.log(config)
				return config
			},
			function (error) {
				return error
			}
		)

		//response intercepter
		axios.interceptors.response.use(
			function (response) {
				return response
			},
			function (error) {
				//TODO: response code 별로 분기
				// console.error(`응답 실패 >>> response : ${JSON.stringify(error)}`);
				try {
					switch (error.response.status) {
						case 401:
							userAPI.logout()
							goToSSOMain()
							break
						case 404:
							console.warn(error.response.data.msg)
							break
						default:
							break
					}

					return Promise.reject(error)
				} catch (error) {
					return Promise.reject(error)
				}
			}
		)
	},
	setAuthToken: () => {
		// 현우 : 여기서 모드 검사, token 값을 강제로 셋팅 해주면 됨
		axios.defaults.headers.common['Authorization'] = cookies.get(process.env.VUE_APP_TOKEN_KEY)
	},
	setGlobalFilter: () => {
		axios.defaults.headers.common['Global-Filter-Storage'] = cookies.get('GlobalFilterStorage')
	},
}

export default axiosConfig
