<template>
	<div class="no-data-box">
		<img src="@/assets/svg/no-data_group.svg" alt="no-image" />
		<h6>{{ tt('그룹원이 없습니다') }}</h6>
		<p>
			<b>[{{ tt('그룹원 추가') }}]</b>
			{{ tt('버튼을 클릭하여 추가해보세요') }}
		</p>
	</div>
</template>

<script>
export default {
	name: 'noGroupMember',
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_organization.scss';
</style>
