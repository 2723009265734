const verifyInteger = targetValue => {
	// 정수 검증 regex
	// 맨 앞에는 0이 들어오지 않게 작업
	let value = null
	const regex = /^[1-9]\d*$/
	const isNumber = targetValue.match(regex)?.length > 0
	if (isNumber) {
		value = targetValue
	} else {
		const length = targetValue.length
		value = targetValue.slice(0, length - 1).trimLeft()
	}
	return value
}

const verifyFloat = targetValue => {
	// 실수 검증 regex
	let value = null
	const regex = /^\d*\.?\d*$/
	const matchedValue = targetValue.match(regex)
	const isFloat = matchedValue?.length > 0
	if (isFloat) {
		value = targetValue
	} else {
		const length = targetValue.length
		value = targetValue.slice(0, length - 1).trimLeft()
	}
	return value
}

export { verifyInteger, verifyFloat }
