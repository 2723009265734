import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	lookupDangerStorageList: (instId, keyword) => {
		let parameter = `/institute/${instId}/safety/dangerstorage/list?`
		if (keyword != undefined) parameter += `&keyword=${keyword}`

		return parameter
	},
	lookupStorageStatusList: instId => `/institute/${instId}/safety/dangerstorage/status`,
	lookupStorageLegalList: (instId, id) => {
		let parameter = `/institute/${instId}/safety/dangerstorage/legal?id=${id}`
		return parameter
	},
	setMultiple: instId => `institute/${instId}/safety/dangerstorage/multiple`,
	changeStorageOrder: instId => `institute/${instId}/safety/dangerstorage/order`,
	updateDangerStorage: instId => `institute/${instId}/safety/dangerstorage`,
	deleteDangerStorage: (instId, ids) => `institute/${instId}/safety/dangerstorage?${ParamMaker.setArrayString('ids', ids)}`,
	exportExcel: instId => `/institute/${instId}/safety/dangerstorage/export/excel`,
}

const dangerStorageAPI = {
	lookupDangerStorageList: (instId, keyword) => axiosInstance.get(endPoint.lookupDangerStorageList(instId, keyword)),
	lookupStorageStatusList: (instId, params) => axiosInstance.get(endPoint.lookupStorageStatusList(instId), { params }),
	lookupStorageLegalList: (instId, id) => axiosInstance.get(endPoint.lookupStorageLegalList(instId, id)),
	setMultiple: (instId, body) => axiosInstance.put(endPoint.setMultiple(instId), body),
	changeStorageOrder: (instId, body) => axiosInstance.put(endPoint.changeStorageOrder(instId), body),
	addDangerStorage: (instId, body) => axiosInstance.post(endPoint.updateDangerStorage(instId), body),
	updateDangerStorage: (instId, body) => axiosInstance.put(endPoint.updateDangerStorage(instId), body),
	deleteDangerStorage: (instId, ids) => axiosInstance.delete(endPoint.deleteDangerStorage(instId, ids)),
	exportExcel: (instId, body) =>
		axiosInstance.post(endPoint.exportExcel(instId), body, {
			responseType: 'blob',
			timeout: 10000,
		}),
}

export default dangerStorageAPI
