var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Authority"
  }, [_c('h2', {
    staticClass: "AppContainer__title"
  }, [_vm._v(" " + _vm._s(_vm.tt('권한')) + " ")]), _c('div', {
    staticClass: "Authority__filter"
  }, [_c('search-box', {
    attrs: {
      "placeholder": _vm.tt('검색'),
      "loadList": _vm.loadPermissionList
    }
  }), _vm.userPermission.permission && _vm.userPermission.permission.create ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn ml-auto",
    on: {
      "click": function click($event) {
        _vm.setLite ? _vm.showLicense() : _vm.showAddModal();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg//button-icon-lisence-copy.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('권한 추가')) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "Authority__table Table__wrap"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('정렬순서')))])]), _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('권한')))])]), _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('편집일')))])]), _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('관리')))])])])]), _c('tbody', _vm._l(_vm.authority.list, function (item, index) {
    return _c('permission-item', {
      key: item.id,
      attrs: {
        "item": item,
        "order": index,
        "total": _vm.authority.list.length,
        "allCheck": _vm.isActive,
        "userPermission": _vm.userPermission,
        "license": _vm.license
      },
      on: {
        "duplicate": _vm.showDuplicateModal,
        "edit": _vm.showEditModal,
        "delete": _vm.showDeleteModal,
        "top": _vm.moveTop,
        "down": _vm.moveDown,
        "showLicense": _vm.showLicense
      }
    });
  }), 1)]), _vm.permissionList.count == 0 ? _c('no-data-list', {
    attrs: {
      "image": _vm.noData.image,
      "title": _vm.noData.title,
      "content": _vm.noData.content
    }
  }) : _vm._e()], 1), _c('pagination', {
    attrs: {
      "total": _vm.permissionList.count
    },
    on: {
      "page": _vm.pagination
    }
  }), _c('modal-add-permission', {
    ref: "modal-add-permission",
    on: {
      "isHide": _vm.hideAddModal
    }
  }), _c('modal-duplicate-permission', {
    ref: "modal-duplicate-permission",
    on: {
      "isHide": _vm.hideDuplicateModal
    }
  }), _c('modal-edit-permission', {
    ref: "modal-edit-permission",
    on: {
      "isHide": _vm.hideEditModal
    }
  }), _c('modal-delete-permission', {
    ref: "modal-delete-permission",
    on: {
      "isHide": _vm.hideDeleteModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }