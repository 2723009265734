<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="EditAuthorityPopup">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt('권한명 편집') }}
					</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field" :class="{ error: check }">
						<p>{{ tt('권한명') }}<span class="required">*</span></p>
						<input type="text" :placeholder="tt('권한명을 입력해주세요')" :value="authority.name" @input="changeAuthName" />
						<span class="noti">{{ tt('이미 동일한 이름의 권한이 있습니다.') }}</span>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="submit">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { mapState, mapActions } from 'vuex'
import institutePermissionAPI from '@/services/api/institute/permission'

export default {
	name: 'ModalEditPermission',
	mixins: [MixinModal],
	data() {
		return {
			authority: {},
			authorityCopy: {},
			check: false,
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('permission', ['permission']),
	},
	methods: {
		...mapActions('permission', ['lookupPermission']),
		async show(id) {
			this.authority = {}
			this.check = false
			const response = await institutePermissionAPI.lookupPermission(this.$route.params.instituteId, id)
			this.authority = response.data
			this.authorityCopy = JSON.parse(JSON.stringify(response.data))

			this.isShow = true
		},
		close() {
			this.hide()
		},
		async submit() {
			if (this.authority.name != this.authorityCopy.name) {
				const response = await institutePermissionAPI.lookupPermissionList(this.$route.params.instituteId, 0, undefined, '')
				const authority = response.data.list.map(x => x.name)
				if (!authority.includes(this.authority.name)) {
					this.$emit('isHide', this.authority)
					this.check = false
				} else {
					this.$root.toast('권한 편집 실패', '권한명을 확인해주세요', 'error')
					this.check = true
				}
			} else {
				this.close()
			}
		},
		changeAuthName(e) {
			this.authority.name = e.target.value
			this.check = false
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__input-field {
	.noti {
		font-size: 0.75rem;
		background-repeat: no-repeat;
		background-size: 12px;
		padding-left: 17px;
		width: 100%;
		background-position: top 3px left 0;
		font-weight: 400;
		height: 20px;
		visibility: hidden;
	}
	&.error {
		input[type='text'] {
			border: 1px solid $RED;
		}
		.noti {
			background-image: url(~@/assets/svg/noti-error.svg);
			color: $RED;
			visibility: visible;
		}
	}
}
</style>
