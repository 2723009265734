var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('태그 편집')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('button', {
          staticClass: "button-white",
          on: {
            "click": function click($event) {
              return _vm.$emit('cancel');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-danger",
          attrs: {
            "disabled": _vm.selectedIds.length == 0
          },
          on: {
            "click": _vm.complete
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('삭제' + (_vm.selectedIds.length != 0 ? "(".concat(_vm.selectedIds.length, ")") : ''))) + " ")])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "select-explain"
  }, [_vm._v(" " + _vm._s(_vm.tt('삭제할 태그를 선택하세요')) + " ")]), _c('div', {
    staticClass: "tag-list"
  }, _vm._l(_vm.tagList, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "tag",
      "class": {
        active: _vm.selectedIds.includes(tag.id)
      },
      on: {
        "click": function click($event) {
          return _vm.toggleTag(tag.id);
        }
      }
    }, [_vm._v(" " + _vm._s("#".concat(tag.name)) + " ")]);
  }), 0)], _c('ModalConfirm', {
    ref: "modalDelete",
    attrs: {
      "title": "태그 삭제",
      "content": "".concat(_vm.selectedIds.length, "\uAC1C\uC758 \uD0DC\uADF8\uB97C \uC0AD\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C? \uAE30\uC874\uC5D0 \uB4F1\uB85D\uB41C \uBB3C\uD488\uC5D0\uC11C \uD0DC\uADF8\uAC00 \uBAA8\uB450 \uC0AD\uC81C\uB429\uB2C8\uB2E4."),
      "variant": "warning",
      "confirmText": "삭제"
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }