var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.tt('구성성분')))]), _vm.hasIngredients ? _c('button', {
    staticClass: "init-btn",
    on: {
      "click": _vm.initIngredient
    }
  }, [_vm._v(_vm._s(_vm.tt('초기화')))]) : _c('button', {
    staticClass: "init-btn input",
    on: {
      "click": _vm.initIngredient
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('입력하기'))), _c('img', {
    staticClass: "arrow-right",
    attrs: {
      "src": require("@/assets/svg/chevron/chevron_right.svg")
    }
  })])]), _vm.hasIngredients ? _c('div', {
    staticClass: "ingredient-item-wrapper"
  }, [_c('div', {
    staticClass: "ingredient-item-header"
  }, [_c('div', {
    staticClass: "header ingredient-name"
  }, [_vm._v(" " + _vm._s(_vm.tt('물질 이름')) + " "), _c('p', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "header ingredient-casno"
  }, [_vm._v("CAS No")]), _c('div', {
    staticClass: "header ingredient-ratio"
  }, [_vm._v(" " + _vm._s(_vm.tt('성분비')) + " "), _c('p', {
    staticClass: "required"
  }, [_vm._v("*")])])]), _vm._l(_vm.ingredient, function (item, idx) {
    return _c('form-ingredient-list-item', {
      key: idx,
      ref: "form-item",
      refInFor: true,
      attrs: {
        "value": item,
        "showError": _vm.isShowError
      },
      on: {
        "delete": function _delete() {
          return _vm.deleteItem(idx);
        },
        "change": function change(value) {
          return _vm.changeItem(value, idx);
        }
      }
    });
  }), _c('div', {
    staticClass: "add-line-btm"
  }, [_c('div', {
    staticClass: "add-btn",
    on: {
      "click": _vm.addIngredient
    }
  }, [_vm._v("+ " + _vm._s(_vm.tt('추가하기')))])])], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }