<template>
	<div class="changeInitPw">
		<div class="wrapper">
			<h5>{{ tt('새로운 비밀번호 설정') }}</h5>
			<p
				v-html="
					tt(
						'<b>${1}님</b> 안녕하세요!<br>새로운 비밀번호를 설정해주세요<br>영문, 숫자, 특수문자를 함께 사용하면 보다 안전합니다',
						user.name
					)
				"
			></p>
			<div class="email-box">
				<p>{{ user.email }}</p>
			</div>
			<div class="form-box">
				<input type="password" :placeholder="tt('비밀번호 입력(6자리 이상)')" v-model="user.password" />
				<input type="password" :placeholder="tt('새로운 비밀번호 확인')" v-model="newPw" />
				<button class="Btn__yellow" @click="submit">
					{{ tt('확인') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { goToSSOMain } from '@/services/api/common'
import instituteMemberAPI from '@/services/api/institute/member'
import { ToastType } from '@/utils/define/ToastType'

export default {
	data() {
		return {
			user: {
				name: '',
				email: '',
				password: null,
			},
			newPw: null,
			code: '',
			isValid: true,
			errorCode: {
				INVAILD_USER: '05001',
				FAIL_CHANGE_PASSWORD: '05008',
			},
		}
	},
	async created() {
		this.code = this.$route.params.code
		await this.loadUserCode()
			.then(response => {
				this.user = response.data
			})
			.catch(error => {
				const errorCode = error.response.data.code
				const timeoutSecond = 3000

				switch (errorCode) {
					case this.errorCode.FAIL_CHANGE_PASSWORD:
						this.$root.toast(this.tt('비밀번호 변경 실패'), error.response.data.msg, ToastType.ERROR)
						break
					case this.errorCode.INVAILD_USER:
						this.$root.toast(this.tt('유효하지 않은 사용자입니다'), error.response.data.msg, ToastType.ERROR)
						break
				}

				setTimeout(() => {
					goToSSOMain()
				}, timeoutSecond)
			})
	},
	methods: {
		async loadUserCode() {
			return await instituteMemberAPI.checkInitPw(this.code)
		},
		validPw() {
			let msg = null

			if (this.user.password == null || this.user.password.length == 0) {
				msg = '비밀번호를 입력해주세요'
			} else if (this.user.password.length < 6) {
				msg = '비밀번호를 6자리 이상 입력해주세요'
			} else if (this.newPw == null || this.newPw.length == 0) {
				msg = '새로운 비밀번호 확인을 입력해주세요'
			} else if (this.newPw.length < 6) {
				msg = '새로운 비밀번호를 6자리 이상 입력해주세요'
			} else if (this.user.password != this.newPw) {
				msg = '비밀번호와 비밀번호 확인이 동일하지 않습니다. 다시 입력해주세요'
			}

			if (msg) {
				this.$root.toast('비밀번호 재설정 실패', msg, 'error')
				this.isValid = false
			} else {
				this.isValid = true
			}
		},
		async submit() {
			this.validPw()

			if (this.isValid == false) {
				return false
			}

			const payload = {
				code: this.code,
				password: this.user.password,
			}

			const response = await instituteMemberAPI.changeInitPw(payload)
			this.$root.toast('비밀번호 재설정 완료', '비밀번호 재설정이 완료되었습니다', 'success')

			goToSSOMain()
		},
	},
}
</script>

<style lang="scss">
.changeInitPw {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: $LAB_WHITE;
	color: #000;
}

.changeInitPw {
	.wrapper {
		width: 400px;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		h5 {
			font-size: 1.5rem;
			font-weight: 700;
			margin-bottom: 10px;
		}
		p {
			font-size: 0.875rem;
			font-weight: 400;
			b {
				font-weight: 700;
				display: inline-block;
			}
		}
		.email-box {
			width: 100%;
			background-color: $GREY_1;
			border-radius: 5px;
			margin-top: 40px;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			p {
				font-size: 0.875rem;
				color: $DEFAULT;
				font-weight: 400;
			}
		}
		.form-box {
			width: 100%;
			margin-top: 10px;
			input {
				width: 100%;
				border: 1px solid #e6e6e6;
				border-radius: 5px;
				padding: 0 12px;
				height: 48px;
				margin-top: 10px;
				display: block;
			}
			button {
				width: 100%;
				display: block;
				height: 48px;
				margin-top: 40px;
				font-size: 1rem;
				font-weight: 700;
			}
		}
	}
}
</style>
