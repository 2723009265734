<template>
	<tr class="table-item" :class="{ error: (item.error && item.error.length > 0) || !item.storage }">
		<td @click.stop>
			<!-- 체크박스 -->
			<span class="checkbox"><input type="checkbox" v-model="checked" /> </span>
		</td>
		<td>
			<!-- 정보 -->
			<div class="info" style="width: 300px">
				<button @click.stop="onClickDelete">
					<img src="@/views/inventoryGen2/components/svg/icon-remove-round-x.svg" />
				</button>
				<div v-b-tooltip.hover.html.bottom="hoverText">
					<div>
						<img v-if="item.error.length > 0 || !item.storage" :src="unusableImage" />
						<h6 style="font-weight: 700">{{ item.name }}</h6>
					</div>
					<div class="subtext">
						<p v-if="item.brand && item.brand.name">{{ item.brand.name }}</p>
						<p v-if="item.subname">{{ item.subname }}</p>
					</div>
				</div>
			</div>
		</td>
		<td style="height: 80px">
			<!-- 제품번호 -->
			<div>
				<span>{{ isEmpty(item.productCode) }}</span>
			</div>
		</td>
		<td>
			<!-- CAS No -->
			<div>
				<span>{{ isEmpty(item.casno) }}</span>
			</div>
		</td>
		<td>
			<!-- 사이즈 -->
			<div>
				<span>{{ volume }}</span>
			</div>
		</td>
		<td>
			<!-- 수량 -->
			<div>
				<span>{{ tt('${1}개', item.multiCreateCount) }}</span>
			</div>
		</td>
		<td>
			<!-- 보관함 -->
			<div class="storage-wrap">
				<input type="text" :value="storageName" disabled />
				<button class="btn" @click.stop="showSelectModal">{{ tt('선택') }}</button>
			</div>
		</td>
		<td>
			<!-- 정보 수정 -->
			<div style="min-width: 80px">
				<button class="btn" @click.stop="showEditModal">{{ tt('수정') }}</button>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import MixinItem from '@/mixins/inventory/MixinItem'
import { DateTime } from 'luxon'
import { MiddleClassToString, LargeClassToString } from '@/utils/define/ItemCode'
export default {
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('inventoryGen2/form', ['excelList', 'selectedItems']),
		checked: {
			get() {
				return this.selectedItems.some(item => item.tempId === this.item.tempId)
			},
			set(value) {
				if (value) {
					this.setSelectedItems(this.selectedItems.concat(this.item))
				} else {
					this.setSelectedItems(this.selectedItems.filter(item => item.tempId !== this.item.tempId))
				}
			},
		},
		volume() {
			if ((this.item.volume ?? null) == null) return '-'
			return `${this.formatNumberLocale(this.financial(this.item.volume))}${this.item.volumeUnit ?? ''}`
		},
		storageName() {
			return this.item.storage && this.item.storage.pathName ? this.item.storage.pathName : '-'
		},
		unusableImage() {
			return require('@/views/inventoryGen2/svg/Unusable.svg')
		},
		hoverText() {
			// return this.item.error.length > 0 ? `<div><img :src="require('@/views/inventoryGen2/svg/Unusable.svg')"></div>` : ''
			return this.item?.error?.length > 0 || !this.item.storage
				? `<div class="hover-wrap"><img src="${this.unusableImage}" /><p>올바르지 않은 입력값이 있습니다. 정보를 수정해주세요</p></div>`
				: ''
		},
	},
	methods: {
		...mapMutations('inventoryGen2/form', ['setExcelList', 'setSelectedItems']),
		showSelectModal() {
			this.$emit('select', this.item)
		},
		showEditModal() {
			this.$emit('edit', this.item)
		},
		onClickDelete() {
			this.setExcelList(this.excelList.filter(it => it.tempId !== this.item.tempId))
			this.setSelectedItems(this.selectedItems.filter(item => item.tempId !== this.item.tempId))
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
::v-deep .hover-wrap {
	display: flex;
	gap: 5px;
	color: $RED;
}
.table-item {
	&.error {
		div {
			color: $RED;
			p {
				color: $RED;
			}
		}
	}
}
.info {
	display: flex;
	gap: 5px;
	& > div :first-child {
		display: flex;
		gap: 5px;
	}
	h6 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	span {
		display: flex;
		gap: 10px;
		b {
			border-radius: 4px;
			background-color: $SUB-RED;
			padding: 3px;
			font-size: 10px;
			color: $RED;
			&.HOLDING_OWN {
				color: $BLUE;
				background-color: $SUB-BLUE;
			}
		}
	}
	.subtext {
		display: flex;
		gap: 10px;
		p {
			font-size: 12px;
			color: $SECONDARY_2;
			&:first-child:nth-last-child(1) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&:nth-child(2) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-left: 10px;
				border-left: 1px solid $INPUT;
			}
		}
	}
}
.storage-wrap {
	display: flex;
	gap: 20px;
	input {
		border-radius: 5px;
		background: $GREY_2;
		border: 1px solid #cccccc;
		width: 180px;
		height: 30px;
	}
}
</style>
