var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isValid || !_vm.isUnitValid
    }
  }, [_vm._v(" " + _vm._s(_vm.isValid && _vm.isUnitValid ? _vm.tt('입력 완료') : !_vm.isUnitValid ? _vm.tt('단위를 선택해주세요') : _vm.tt('필수값을 입력해주세요')) + " ")])]), _vm.isSearch ? _c('button', {
    staticClass: "search-button",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-search-product'].show();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon/search/icon-search-black.svg"),
      "width": "24",
      "height": "24"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.tt('검색하기')) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "form-data-wrap"
  }, [_vm._l(_vm.inventoryFields, function (field) {
    return _c(_vm.formInput(field), {
      key: field.key,
      ref: "form-product",
      refInFor: true,
      tag: "component",
      attrs: {
        "name": field.name,
        "value": _vm.formValue(field.key),
        "unit": _vm.formUnit(field.key),
        "required": field.isRequire,
        "optionList": field.unit,
        "field": field,
        "placeholder": _vm.formMessage(field),
        "errorMessage": _vm.formMessage(field),
        "isCasno": field.key == 'casno',
        "isShowError": _vm.isShowError,
        "item": _vm.productInfo
      },
      on: {
        "input": function input(value) {
          return _vm.inputFormValue(value, field.key);
        }
      }
    });
  }), _vm.forDraft ? [_c('form-counter', {
    attrs: {
      "title": "수량",
      "min": 1,
      "value": 1
    },
    on: {
      "input": function input(value) {
        return _vm.inputFormValue(value, 'quantity');
      }
    }
  }), _c('form-number-unit', {
    key: "unitPrice",
    ref: "form-product",
    attrs: {
      "name": '단가',
      "value": _vm.formValue('unitPrice'),
      "unit": _vm.formUnit('priceUnit'),
      "required": true,
      "optionList": ['원'],
      "placeholder": '가격을 입력해주세요',
      "errorMessage": '가격을 입력해주세요',
      "isShowError": _vm.isShowError
    },
    on: {
      "input": function input(value) {
        return _vm.inputFormValue(value, 'unitPrice');
      }
    }
  })] : _vm._e()], 2), _c('modal-search-product', {
    ref: "modal-search-product",
    attrs: {
      "title": "물품 검색하기",
      "categoryInfo": _vm.categoryInfo,
      "forRegistItem": _vm.forRegistItem
    },
    on: {
      "selectItem": function selectItem(item) {
        return _vm.$emit('selectItem', item);
      },
      "inputDirect": function inputDirect($event) {
        return _vm.$refs['modal-search-product'].hide();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }