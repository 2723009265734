<template>
	<div class="table-wrapper">
		<div class="info-table">
			<div v-for="item in items" :key="item.label" :class="item.class">
				<span>{{ tt(item.label) }}</span>
				<span v-if="item.files && item.files.length > 0">
					<a v-for="file in item.files" :key="file.id" :href="file.url" ref="download-file">
						<img src="@/assets/svg/docs.svg" />
						{{ file.name }}
					</a>
				</span>
				<span v-else v-html="tt(item.value ? replaceValue(item.value) : '-')" />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
		},
		items: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		replaceValue(val) {
			return val.replace(/\\r\\n|\\n|\\r/gm, '<br />')
		},
		downloadAll(index = 0) {
			if (this.items.length > 0) {
				this.$refs['download-file'][index].click()
				if (this.$refs['download-file'][index + 1]) {
					setTimeout(() => {
						this.downloadAll(index + 1)
					}, 200)
				}
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.table-wrapper {
	text-align: left;
	margin-top: 30px;
	margin-bottom: 20px;
	.info-table {
		width: 100%;
		border-top: 1px solid $INPUT;
		display: flex;
		flex-wrap: wrap;
		& > div {
			display: flex;
			border-bottom: 1px solid $DIVIDER;
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
			color: $DEFAULT;
			width: 50%;
			min-height: 40px;
			&.lg {
				width: 100%;
			}
			& > span {
				display: inline-block;
				padding: 12px;
				a {
					display: block;
					line-height: 20px;
				}
			}
			& > span:first-child {
				background-color: $GREY_1;
				width: 200px;
			}
		}
	}
}
</style>
