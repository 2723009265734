var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal-view-option', {
    ref: "modal",
    on: {
      "confirm": _vm.confirm
    },
    scopedSlots: _vm._u([{
      key: "modal-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "list-wrapper"
        }, [_c('div', {
          staticClass: "title-wrapper"
        }, [_c('h5', [_vm._v(_vm._s(_vm.tt('조회 항목')))]), _c('button', {
          on: {
            "click": _vm.checkedAll
          }
        }, [_vm._v(_vm._s(_vm.tt('전체선택')))])]), _c('div', {
          staticClass: "check-list"
        }, _vm._l(_vm.viewOptionList, function (item) {
          return _c('label', {
            key: item.id,
            staticClass: "check-item"
          }, [_c('input', {
            staticClass: "checkbox",
            attrs: {
              "type": "checkbox",
              "disabled": item.isRequire
            },
            domProps: {
              "checked": _vm.checkedList.includes(item.id)
            },
            on: {
              "change": function change($event) {
                return _vm.checked(item.id);
              }
            }
          }), _vm._v(" " + _vm._s(_vm.tt(item.name)) + " ")]);
        }), 0)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }