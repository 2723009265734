var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-requirement-business"
  }, [_c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('사업자등록증은 연구실별로 한번 등록하면, 모든 멤버에게 인증됩니다. <br />전자세금 계산시 발행처 및 연구기관 인증에 사용됩니다.'))
    }
  }), _vm.isEmpty ? _c('div', {
    staticClass: "business-registration-regist-form"
  }, [_c('input-form-item', {
    ref: "name",
    attrs: {
      "direction": "column",
      "title": "사업자등록증(결제처) 별칭",
      "placeholder": "사업자등록증 별칭을 입력해주세요",
      "inputStyle": {
        width: '466px'
      }
    },
    model: {
      value: _vm.info.name,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "name", $$v);
      },
      expression: "info.name"
    }
  }), _c('upload-form-item', {
    ref: "upload",
    attrs: {
      "direction": "column",
      "title": "사업자등록증 업로드"
    },
    on: {
      "upload": _vm.onUploadFile,
      "delete": _vm.onDeleteFile
    }
  }), _c('div', {
    staticClass: "button-wrapper"
  }, [_c('button', {
    staticClass: "btn btn-primary lg",
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(_vm._s(_vm.tt('다음')))])])], 1) : _c('div', _vm._l(_vm.businessRegistrations, function (businessRegistration) {
    return _c('div', {
      key: businessRegistration.id,
      staticClass: "business-registration-list"
    }, [_c('div', {
      staticClass: "business-registration-item"
    }, [_c('div', [_c('span', {
      staticClass: "status",
      "class": businessRegistration.status
    }, [_vm._v(_vm._s(_vm.tt(_vm.STATUS[businessRegistration.status])))])]), _c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(businessRegistration.name) + " ")])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }