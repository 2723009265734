var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-sort-tab"
  }, [_c('div', {
    staticClass: "tab-inner"
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c('span', {
      key: index
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectTabId,
        expression: "selectTabId"
      }],
      attrs: {
        "type": "radio",
        "id": 'tab' + index + 1,
        "name": "TabList"
      },
      domProps: {
        "value": index,
        "checked": _vm._q(_vm.selectTabId, index)
      },
      on: {
        "change": function change($event) {
          _vm.selectTabId = index;
        }
      }
    }), _c('label', {
      attrs: {
        "for": 'tab' + index + 1
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(item.name)) + " ")])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }