var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tree-node-wrap",
    "class": {
      root: _vm.node.parentId == null && _vm.type !== 'member'
    }
  }, [_c('div', {
    staticClass: "tree-node",
    "class": {
      active: _vm.isActive(_vm.node)
    }
  }, [_c('div', {
    staticClass: "info-wrap",
    "class": {
      "default": _vm.type !== 'member',
      root: _vm.type === 'root'
    },
    on: {
      "dblclick": function dblclick($event) {
        $event.stopPropagation();
        return _vm.selectMember(_vm.node);
      }
    }
  }, [_vm.isSubtree ? _c('div', [_vm.isTreeOpen ? _c('img', {
    staticClass: "tree-close",
    attrs: {
      "src": require("../svg/icon-node-close.svg"),
      "width": "16",
      "height": "24"
    },
    on: {
      "click": _vm.toggleTreeOpen
    }
  }) : _c('img', {
    staticClass: "tree-open",
    attrs: {
      "src": require("../svg/icon-node-open.svg"),
      "width": "16",
      "height": "24"
    },
    on: {
      "click": _vm.toggleTreeOpen
    }
  })]) : _vm._e(), _vm.type === 'root' ? _c('img', {
    staticClass: "tree-icon",
    staticStyle: {
      "filter": "brightness(0)"
    },
    attrs: {
      "src": require("@/assets/svg/institution-building.svg"),
      "width": "20",
      "height": "20"
    }
  }) : _vm.type === 'member' && _vm.isActive(_vm.node) ? _c('img', {
    staticClass: "tree-icon",
    attrs: {
      "src": require("../svg/icon-member_y.svg"),
      "width": "20",
      "height": "20"
    }
  }) : _vm.type === 'member' ? _c('img', {
    staticClass: "tree-icon",
    attrs: {
      "src": require("../svg/icon-member.svg"),
      "width": "20",
      "height": "20"
    }
  }) : _c('img', {
    staticClass: "tree-icon",
    attrs: {
      "src": require("@/assets/svg/group.svg"),
      "width": "20",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "name",
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  })])]), _vm.isTreeOpen ? _c('div', {
    staticClass: "node-children"
  }, [_vm._l(_vm.node.children, function (group) {
    return _c('tree-node', {
      key: group.id,
      ref: "child-node",
      refInFor: true,
      attrs: {
        "node": group,
        "type": 'group',
        "parent": _vm.node
      }
    });
  }), _vm._l(_vm.node.members, function (member) {
    return _c('tree-node', {
      key: member.id,
      ref: "child-node",
      refInFor: true,
      attrs: {
        "node": member,
        "type": 'member',
        "parent": _vm.node
      }
    });
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }