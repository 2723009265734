<template>
	<div class="detail-wrap" id="cargo-detail" v-if="item">
		<detail-header
			@delete="$refs['modal-delete'].show()"
			@edit="showCargoEditProduct"
			@needInput="showCargoNeedInputProduct"
			@print="showPrintPopup"
		></detail-header>
		<component v-bind:is="activeBadge" :item="item" :legals="legals"></component>
		<div class="detail-tab">
			<div class="tab-button" :class="{ select: selectTab === tab.key }" v-for="tab in tabList" :key="tab.key" @click="selectTab = tab.key">
				{{ tt(tab.title) }}
			</div>
		</div>
		<div class="active-page-wrap">
			<component v-bind:is="activePage" :item="item" :legals="legals"></component>
		</div>
		<detail-footer
			:item="item"
			:isShowEdit="isShowEdit"
			:isShowPrintSticker="isShowPrintSticker"
			:isShowGHSButton="isShowGHSButton"
			@edit="showCargoEditProduct"
			@delete="$refs['modal-delete'].show()"
			@makeGHS="makeGHS"
			@manageGHS="manageGHS"
		></detail-footer>

		<!-- modal 영역 -->
		<modal-form-cargo-product ref="modal-form-cargo-product" @goNext="showCargoManagement"></modal-form-cargo-product>
		<modal-form-cargo-management ref="modal-form-cargo-management" @goPrev="showCargoProduct"></modal-form-cargo-management>

		<modal-confirm
			ref="modal-delete"
			variant="warning"
			title="입고 물품 삭제"
			content="입고 물품을 정말로 삭제하시겠습니까?"
			confirmText="삭제"
			@confirm="deleteItem"
		/>
		<modal-confirm
			ref="modal-delete-ghs"
			variant="warning"
			title="GHS 라벨 삭제"
			content="GHS 라벨을 정말로 삭제하시겠습니까?"
			confirmText="삭제"
			@confirm="deleteGHS"
		/>
		<modal-make-ghs
			ref="make-ghs"
			:msds="item.msds"
			@showGuide="$refs['guide'].show()"
			@goNext="
				$refs['make-ghs'].hide()
				$refs['ghs-form'].show()
			"
			@uploadMSDS="uploadMSDS"
		/>
		<modal-ghs-form
			ref="ghs-form"
			@goNext="showPreviewGHS"
			@goPrev="
				$refs['make-ghs'].show('prev')
				$refs['ghs-form'].hide()
			"
		></modal-ghs-form>
		<modal-ghs-preview
			ref="ghs-preview"
			@goPrev="
				$refs['ghs-preview'].hide()
				$refs['ghs-form'].show()
			"
			@complete="completeCreateGHS"
		></modal-ghs-preview>
		<modal-ghs-label ref="ghs-label" @edit="editGHS" @delete="showModalDeleteGHS"></modal-ghs-label>
	</div>
</template>
<script>
import PageName from '@/utils/define/PageName'
import { ToastType } from '@/utils/define/ToastType'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import DetailHeader from '@/views/purchase/cargo/components/cargoDetail/DetailHeader.vue'
import BadgeListChemical from '../components/cargoDetail/badge/BadgeListChemical.vue'
import BadgeListDefault from '../components/cargoDetail/badge/BadgeListDefault.vue'
import DetailProductInfo from '../components/cargoDetail/productInfo/DetailProductInfo.vue'
import DetailPurchaseList from '../components/cargoDetail/DetailPurchaseList.vue'
import DetailDraft from '../components/cargoDetail/DetailDraft.vue'
import DetailFooter from '../components/cargoDetail/DetailFooter.vue'

import ModalFormCargoProduct from '@/views/purchase/cargo/components/cargoList/modal/ModalFormCargoProduct.vue'
import ModalFormCargoManagement from '@/views/purchase/cargo/components/cargoList/modal/ModalFormCargoManagement.vue'

import ModalMakeGhs from '@/views/common/components/modalGen2/ghs/ModalMakeGHS.vue'
import ModalGhsForm from '@/views/common/components/modalGen2/ghs/ModalGHSForm.vue'
import ModalGhsPreview from '@/views/common/components/modalGen2/ghs/ModalGHSPreview.vue'
import ModalGhsLabel from '@/views/common/components/modalGen2/ghs/ModalGHSLabel.vue'

import MixinModalGhs from '@/mixins/modalGhs/mixinModalGhs'
import { FindLargeClass } from '@/utils/define/ItemCode'
export default {
	name: 'CargoDetail',
	mixins: [MixinModalGhs],
	components: {
		DetailHeader,
		DetailFooter,
		ModalFormCargoProduct,
		ModalFormCargoManagement,
		ModalMakeGhs,
		ModalGhsForm,
		ModalGhsPreview,
		ModalGhsLabel,
	},
	props: {},
	data() {
		return {
			selectTab: 'productInfo',
		}
	},
	computed: {
		...mapGetters('cargo/item', ['item', 'legals', 'formShape']),
		...mapGetters('modal/ghs', ['ghsData', 'mode']),
		...mapState('draft/item', {
			draftItem: 'item',
		}),
		inventory() {
			return this.item?.inventoryFields
		},
		management() {
			return this.item?.managementFields
		},
		tabList() {
			const tabList = [
				{
					title: '물품정보',
					key: 'productInfo',
				},

				// {
				// 	title: '주문내역 조회',
				// 	key: 'purchaseList',
				// },
			]
			if (this.item?.draftId) {
				tabList.push({
					title: '지출결의서 조회',
					key: 'draft',
				})
			}
			return tabList
		},
		activePage() {
			return {
				productInfo: DetailProductInfo,
				purchaseList: DetailPurchaseList,
				draft: DetailDraft,
			}[this.selectTab]
		},
		isShowEdit() {
			return this.item?.isReady
		},
		activeBadge() {
			switch (this.item?.category?.classification || FindLargeClass(this.item?.categoryType)) {
				case 'CAT_CHEMICAL':
					return BadgeListChemical
				default:
					return BadgeListDefault
			}
		},
		isCategoryChemical() {
			return this.item?.category?.classification === 'CAT_CHEMICAL'
		},
		isShowPrintSticker() {
			if (this.item?.managementFields?.stickerType == null) return false
			if (this.item?.managementFields?.stickerType == 'GHS+QR')
				return this.item?.managementFields?.qrSticker?.length > 0 && !!this.item?.managementFields?.ghs
			return this.item?.managementFields?.qrSticker?.length > 0
		},
		isShowGHSButton() {
			return this.isCategoryChemical && this.item.status !== 'COMPLETE' && this.item?.managementFields?.stickerType === 'GHS+QR'
		},
	},
	watch: {},
	async created() {
		let cargoId = this.$route.params.cargoId
		this.initCargoItem()
		await this.getCargoItem(cargoId)
		this.getUnitList()
		if (this.item.category) {
			await this.getCategoryInfo(this.item.category.id)
		} else {
			await this.getCategoryFieldsByType(this.item.categoryType)
		}
	},
	mounted() {},
	methods: {
		...mapMutations('cargo/form', ['setCargoFormType', 'setCargoInfo']),
		...mapActions('cargo/form', ['setForm', 'editCargo']),
		...mapMutations('cargo/item', ['initCargoItem']),
		...mapActions('cargo/item', ['getCargoItem', 'getCategoryInfo', 'getCategoryFieldsByType', 'deleteCargoItem']),
		...mapMutations('tag', ['setSelectedTag']),
		...mapMutations('modal/ghs', ['setPreviewData']),
		...mapActions('modal/ghs', ['getUnitList']),
		async loadDetail() {
			// 유효한 지출결의서인지 체크용
			await this.getDetail(this.item.draftId)
		},
		async showCargoNeedInputProduct() {
			this.setCargoFormType('NEED_INPUT_DETAIL')
			await this.setForm(this.formShape)
			this.setSelectedTag(null)
			await this.$refs['modal-form-cargo-product'].show()
		},
		async showCargoEditProduct() {
			if (this.$root.userPermission.cargo?.update) {
				this.setCargoFormType('EDIT')
				await this.setForm(this.formShape)
				this.setSelectedTag(this.formShape.tagList.map(tag => tag.id))
				await this.$refs['modal-form-cargo-product'].show()
			} else this.$root.toast('권한', '물품 편집 권한이 없습니다.', 'error')
		},
		showCargoProduct() {
			this.$refs['modal-form-cargo-product'].show()
			this.$refs['modal-form-cargo-management'].hide()
		},
		showCargoManagement() {
			this.$refs['modal-form-cargo-product'].hide()
			this.$refs['modal-form-cargo-management'].show()
		},
		async deleteItem() {
			if (this.$root.userPermission.cargo?.delete) {
				this.deleteCargoItem([this.$route.params.cargoId])
				this.$router.replace({
					name: PageName.Purchase.CargoList,
				})
				this.$root.toast('삭제 완료', '물품이 삭제 되었습니다.', ToastType.SUCCESS)
			} else this.$root.toast('권한', '물품 삭제 권한이 없습니다.', 'error')
		},
		async completeCreateGHS() {
			// 물품 편집으로 GHS라벨정보 등록
			await this.setForm({ ...this.formShape, ghs: this.ghsData, deleteGhs: false })
			await this.editCargo()
			await this.getCargoItem(this.$route.params.cargoId)
			this.setPreviewData(this.item)
			this.$refs['ghs-preview'].hide()
			this.$refs['ghs-label'].show()
			let text = this.mode === 'EDIT' ? '편집' : '생성'
			this.$root.toast(`GHS ${text} 완료`, `GHS 라벨 ${text}이 완료되었습니다`, ToastType.SUCCESS)
		},
		async uploadMSDS(file) {
			await this.setForm({ ...this.formShape, msds: file })
			await this.editCargo()
			await this.getCargoItem(this.$route.params.cargoId)
			this.setPreviewData(this.item)
		},
		async deleteGHS() {
			let form = this.formShape
			delete form['ghs']
			await this.setForm({ ...form, deleteGhs: true })
			await this.editCargo()
			await this.getCargoItem(this.$route.params.cargoId)
			this.$refs['ghs-label'].hide()
		},
		showPrintPopup() {
			const printTitle = `${this.item.inventoryFields.name}_물품입고_상세페이지`

			const styles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'))
				.map(style => style.outerHTML)
				.join('')

			const printContents = document.getElementById('cargo-detail').outerHTML
			const popupWin = window.open('', '_blank', 'width=1000,height=1500')
			popupWin.document.open()
			popupWin.document.write(`
			    <html>
                <head>
                    <title>${printTitle}</title>
                    ${styles}
                    <style>
                    #cargo-detail {
                        width: 100%;
                        height: 100%;
                    }
                    .print-hide {
                        display: none !important;
                    }
                    @media print {
                        body {
                            overflow: visible;
                        }
                        #cargo-detail {
                            height: auto !important;
                        }
                        .print-section {
                            page-break-inside: avoid;
                        }
                    }
                    </style>
                </head>
                <body onload="window.print(); window.close();">
                    ${printContents}
                </body>
                </html>
			`)
			popupWin.document.close()
			popupWin.onload = function () {
				popupWin.focus()
				popupWin.print()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.detail-wrap {
	display: flex;
	flex-direction: column;
	height: 100% !important;
	overflow-y: auto;
	.detail-tab {
		display: inherit;
		flex-direction: row;
		padding: 0px 30px;
		border-bottom: 1px solid $DIVIDER;
		.tab-button {
			font-size: 16px;
			font-weight: 400;
			max-width: 300px;
			padding: 15px 0px 10px 0px;
			margin: 0px 10px;
			color: $PLACEHOLDER;
			cursor: pointer;
			&.select {
				font-weight: 700;
				color: $DEFAULT;
				border-bottom: 2px solid $LAB_YELLOW;
				cursor: default;
			}
		}
	}
	.active-page-wrap {
		padding: 20px 30px;
		padding-bottom: 60px;
	}
}

.button-gray {
	min-width: 100px;
	justify-content: center;
	background: $GREY_4;
	display: flex;
	border-radius: 5px;
	padding: 5px 10px;
	height: 36px;
	font-size: 14px;
	font-weight: 700;
	&:hover {
		background: $HOVER_BUTTON_COMMON;
	}
}

.button-primary {
	min-width: 100px;
	justify-content: center;
	background: $LAB_YELLOW;
	display: flex;
	border-radius: 5px;
	padding: 5px 10px;
	height: 36px;
	font-size: 14px;
	font-weight: 700;
	&:hover {
		background: $HOVER_BUTTON_PRIMARY;
	}
}
</style>
