var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-wrapper"
  }, [_c('div', {
    staticClass: "title-area"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " ")]), _vm.status || _vm.subtitle ? _c('div', {
    staticClass: "subtitle"
  }, [_vm.status ? _c('b', {
    "class": _vm.status
  }, [_vm._v(_vm._s(_vm.tt(_vm.statusKor)))]) : _vm._e(), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.subtitle)
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "btn-area"
  }, [_c('div', [_vm.permission.draftCancel ? _c('base-button', {
    attrs: {
      "color": 'warning only'
    },
    on: {
      "click": function click($event) {
        return _vm.click('CANCEL');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('상신 취소')))]) : _vm._e()], 1), _vm.orderId ? _c('div', [_c('base-button', {
    on: {
      "click": function click($event) {
        return _vm.click('STORE_ORDER');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('주문 상세보기')))])], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }