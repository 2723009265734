<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="MemberInfoPopup">
			<div class="Popup__background"></div>
			<div class="Memeber__info-popup Popup__container Popup__size-sm" v-if="member.id.length > 0">
				<div class="Popup__top">
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Member__info-img">
						<img v-if="hasImg" :src="getSrc(member.image)" />
						<img v-else src="@/assets/svg/member-detail-photo.svg" alt="member-image" />
					</div>
					<h4 class="Member__info-name">{{ member.name }}</h4>
					<div class="Member__info-personal Member__info-content">
						<h5>{{ tt('정보') }}</h5>
						<ul>
							<li>
								<h6>{{ tt('이메일') }}</h6>
								<p>{{ member.email }}</p>
							</li>
							<li v-if="member.phone">
								<h6>{{ tt('연락처') }}</h6>
								<p>{{ member.phone }}</p>
							</li>
							<li v-if="member.permissions.length > 0">
								<h6>{{ tt('권한') }}</h6>
								<p>{{ showPermissions }}</p>
							</li>
						</ul>
					</div>
					<div class="Member__info-affiliation Member__info-content">
						<h5>{{ tt('소속') }}</h5>
						<ul>
							<li v-for="(groupName, idx) in groupNames" :key="`${groupName}-${idx}`">
								<h6>{{ tt('소속') }}{{ idx + 1 }}</h6>
								<p>{{ groupName }}</p>
							</li>
							<li v-if="groupNames.length == 0">
								<p>{{ tt('소속 없음') }}</p>
							</li>
						</ul>
					</div>
				</div>
				<!--권한에 따라서 보이는게 다릅니다.-->
				<div class="Popup__bottom">
					<div class="Popup__center-btn-group Popup__btn-group">
						<button class="Btn__export" @click="deleteMember" v-if="userPermission.institute.member.delete">
							{{ tt('내보내기') }}
						</button>
						<button class="Btn__edit" @click="editMember" v-if="userPermission.institute.member.update" style="width: unset">
							{{ tt('정보편집') }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<modal-registering-member
			ref="modal-registering-member"
			page="member"
			:userInfo="userInfo"
			@complete="completeRegistering"
		></modal-registering-member>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import institutionGroupAPI from '@/services/api/institute/group'
import instituteMemberAPI from '@/services/api/institute/member'
import { mapState, mapGetters, mapActions } from 'vuex'
import ModalRegisteringMember from '@/views/institute/member/modals/ModalRegisteringMember.vue'
import Validation from '@/utils/Validation'

export default {
	name: 'ModalMemberInfo',
	mixins: [MixinModal],
	props: {
		userInfo: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		'modal-registering-member': ModalRegisteringMember,
	},
	data() {
		return {
			instId: '',
			memberId: undefined,
			groupId: undefined,
			member: {
				id: '',
				image: undefined,
				name: '',
				email: '',
				phone: '',
				permissions: [],
				groups: [],
			},
			displayMember: false,
		}
	},
	computed: {
		...mapGetters('organizationTree', ['selectedGroupInfo']),
		showPermissions() {
			return this.member.permissions.map(item => item.name).join(', ')
		},
		hasImg() {
			return this.member.image !== null && this.member.image !== undefined
		},
		groupNames() {
			return this.member.groups
				.filter((group, idx) => {
					return group
				})
				.map(item => item.name)
		},
	},
	methods: {
		show(id, instId) {
			this.isShow = true
			instituteMemberAPI.lookupMember(instId, id).then(response => {
				this.member = response.data
				if (this.member.phone.indexOf('-') < 0) {
					this.member.phone = Validation.autoHypenTel(this.member.phone)
				}
			})
		},
		deleteMember() {
			this.$emit('delete', this.member.id)
		},
		editMember() {
			this.$emit('showRegisteringMember', 'edit', this.member)
			// this.$refs['modal-registering-member'].show('edit', this.member)
		},
		hideEditInfo(member) {
			this.member = member
			this.$refs['modal-info-edit-member'].hide()
			this.$emit('info', this.member)
		},
		getSrc(file) {
			if (typeof file == 'string') {
				return file
			} else {
				return file ? URL.createObjectURL(file) : null
			}
		},
		close() {
			this.hide()
		},
		completeRegistering() {
			this.$emit('completeRegistering')
			this.$refs['modal-registering-member'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_memberInfo.scss';
.Btn__export {
	color: white !important;
}
</style>
