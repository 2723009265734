var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "InstManagement__header-container",
    "class": {
      subtitle: _vm.hasSubTitle
    }
  }, [_c('div', {
    staticClass: "InstManagement__header-container-left"
  }, [_c('div', {
    staticClass: "InstManagement__header-title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " ")]), _vm.hasSubTitle ? _c('div', {
    staticClass: "InstManagement__header-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.subTitle) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "InstManagement__header-container-right"
  }, [_vm.hasEditButton ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn ml-auto",
    on: {
      "click": _vm.editButtonFn
    }
  }, [_c('img', {
    attrs: {
      "alt": "icon",
      "src": require("@/assets/svg/button-icon-edit.svg")
    }
  }), _vm._v(_vm._s(_vm.tt('편집')) + " ")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }