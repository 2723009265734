var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.focusOut,
      expression: "focusOut"
    }],
    ref: "search-box",
    staticClass: "lm-sb-wrapper"
  }, [_c('div', {
    staticClass: "lm-sb",
    "class": {
      readonly: _vm.readonly,
      focus: _vm.isFocus
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.text,
      expression: "text"
    }],
    ref: "input",
    staticClass: "transparent",
    attrs: {
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "placeholder": _vm.tt(_vm.placeholder)
    },
    domProps: {
      "value": _vm.text
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onDataChanged.apply(null, arguments);
      },
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.text = $event.target.value;
      }, _vm.handleInput],
      "focus": function focus($event) {
        _vm.isFocus = true;
      }
    }
  }), _vm.text != null && !_vm.readonly ? [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isScan,
      expression: "isScan"
    }],
    staticClass: "reset",
    on: {
      "click": _vm.resetBarcode
    }
  }, [_c('p', [_vm._v(_vm._s('Reset'))])]), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasQR && _vm.text.length == 0,
      expression: "hasQR && text.length == 0"
    }],
    staticClass: "qr",
    attrs: {
      "src": require("./assets/ic-qr.svg")
    },
    on: {
      "click": _vm.clickQR
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isScan && _vm.text.length > 0,
      expression: "!isScan && text.length > 0"
    }],
    staticClass: "qr",
    attrs: {
      "src": require("./assets/ic-delete.svg")
    },
    on: {
      "click": _vm.deleteValue
    }
  })] : _vm._e(), _c('img', {
    staticClass: "btn-search",
    attrs: {
      "src": require("./assets/ic-search.svg")
    },
    on: {
      "click": _vm.onDataChanged
    }
  })], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isKeywordWrapperOpen,
      expression: "isKeywordWrapperOpen"
    }],
    staticClass: "keyword-wrapper"
  }, [_vm.hasTag ? _c('tag', {
    attrs: {
      "tags": _vm.tags
    },
    on: {
      "clickTag": _vm.clickTag
    }
  }) : _vm._e(), _c('recent', {
    ref: "recent",
    on: {
      "clickRecent": _vm.clickRecent
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }