<template>
	<div class="item-wrap">
		<div class="header-wrap">
			<button class="item-handle"></button>
			<h4>{{ textCode(item.code) }}</h4>
			<button class="btn Dropdown__btn">
				<img src="@/views/inventoryGen2/svg/Setting.svg" />
				<div class="Dropdown__menu">
					<button @click="$emit('deleteManagement', index)">
						{{ tt('관리번호에서 제외하기') }}
					</button>
					<button @click="$emit('changeManagement', index)">
						{{ tt('관리항목 변경하기') }}
					</button>
				</div>
			</button>
		</div>
		<ul>
			<li v-for="(subItem, idx) in item.marks" :key="subItem.originId">
				<input type="text" :value="subItem.value" @blur="valueChange($event, idx)" maxlength="6" :disabled="!item.canEditMark" />
				<p>{{ tt(subItem.text) }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		...mapState('inventoryGen2/setting', ['mngcode']),
	},
	methods: {
		...mapActions('inventoryGen2/setting', ['editMngcode']),
		textCode(code) {
			switch (code) {
				case 'MNGCODE_STORAGE_NAME':
					return this.tt('보관함')
				case 'MNGCODE_INVENTORY_ORDER':
					return this.tt('등록순서')
				case 'MNGCODE_CATEGORY_NAME':
					return this.tt('카테고리')
				case 'MNGCODE_DATE_YY':
					return this.tt('등록 연도(YY)')
				case 'MNGCODE_DATE_YYMMDD':
					return this.tt('등록 연도/월/일(YYMMDD)')
				case 'MNGCODE_INVENTORY_NAME':
					return this.tt('물품명')
				case 'MNGCODE_STORAGE_ORDER':
					return this.tt('보관함 등록순서')
				default:
					return this.tt('정의되지 않음')
			}
		},
		async valueChange(e, idx) {
			const newClassList = JSON.parse(JSON.stringify(this.mngcode.activeClassList))
			newClassList[this.index].marks[idx].value = e.target.value
			await this.editMngcode({ activeClassList: newClassList })
			this.$root.toast(this.tt('관리번호 재설정'), this.tt('물품 개수에 따라 시간이 다소 소요될 수 있습니다'), 'success')
		},
	},
}
</script>

<style lang="scss" scoped>
.item-wrap {
	width: 270px;
	border: 1px solid $DIVIDER;
	border-radius: 8px;
	display: inline-block;
	margin-top: 15px;
	margin-right: 15px;
	vertical-align: top;
	&.sortable-chosen * {
		background-color: $DIVIDER !important;
	}
	&.sortable-drag {
		border: 1px solid $LAB_YELLOW;
		background-color: #fffaec !important;
	}
	&.sortable-drag * {
		background-color: #fffaec !important;
	}
	.header-wrap {
		display: flex;
		padding: 10px;
		background: $GREY_1;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		& > button:last-child {
			margin-left: auto;
			width: 24px;
			height: 24px;
		}
		.item-handle {
			background-color: transparent;
			border: none;
			width: 16px;
			height: 20px;
			background-image: url(~@/assets/svg/draggable.svg);
			background-repeat: no-repeat;
			background-size: 20px;
			margin-right: 5px;
			cursor: move;
		}
		h4 {
			font-size: 1rem;
			color: #000;
			font-weight: 700;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	ul {
		li {
			padding: 10px;
			display: flex;
			width: 100%;
			align-items: center;
			border-bottom: 1px solid #e4e5ea;
			p {
				font-size: 0.875rem;
				color: #000;
				font-weight: 400;
				user-select: none;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			input {
				width: 86px;
				height: 30px;
				border: 1px solid rgba(0, 0, 0, 0);
				border-radius: 3px;
				background: rgba(0, 0, 0, 0);
				user-select: none;
				font-weight: bold;
				color: #000;
				&:focus {
					border: 1px solid #000;
				}
			}
			&:last-child {
				border-bottom: 0;
				border-radius: 0 0 8px 8px;
			}
		}
	}
}
</style>
