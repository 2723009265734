import { mapState } from 'vuex'
export default {
	created() {},
	mounted() {},
	data() {
		return {
			isDragged: false,
			cardWidth: 'calc((100% / 3) - 22px)',
			cardMinWidth: '265px',
			cardMarginBottom: '-25px',
			storageDataPrefix: 'storageData_',
			checkerPrefix: 'checker_',
		}
	},
	computed: {
		...mapState('location', ['selectedLocation']),
	},
	methods: {
		storageDragstart(event, item) {
			event.target.style.width = event.path[0].clientWidth + 'px'
			event.target.style.height = event.path[0].clientHeight + 2 + 'px'
			event.target.style.marginBottom = this.cardMarginBottom
			event.dataTransfer.setData(
				'data',
				JSON.stringify({
					storageItem: item,
				})
			)
		},
		storageDrag(event, item) {
			event.preventDefault()
			document.getElementById(`${this.storageDataPrefix}${item.id}`).style.display = 'none'
			event.target.style.backgroundColor = '#EEEEEE'
			event.target.style.border = '1px'
		},
		storageDragend(event, item) {
			document.getElementById(`${this.storageDataPrefix}${item.id}`).style.display = 'inline-block'

			event.target.style.backgroundColor = ''
			event.target.style.border = '1px solid #ddd'
			event.target.style.marginBottom = ''
		},
		storageDragenter(event, item) {
			const isSameLocation = this.selectedLocation.info.id === item.id
			if (isSameLocation) {
				return
			}
			document.getElementById(`${this.checkerPrefix}${item.id}`).style.backgroundColor = '#FEC741'
		},
		storageDrop(event, item) {
			const isSameLocation = this.selectedLocation.info.id === item.id
			if (isSameLocation) {
				return
			}
			event.preventDefault()
			const data = JSON.parse(event.dataTransfer.getData('data'))
			document.getElementById(`${this.checkerPrefix}${item.id}`).style.backgroundColor = 'inherit'
			return data
		},
		storageDragleave(event, item) {
			const isSameLocation = this.selectedLocation.info.id === item.id
			if (isSameLocation) {
				return
			}
			document.getElementById(`${this.checkerPrefix}${item.id}`).style.backgroundColor = 'inherit'
		},
		storageDragover(event, item) {
			const isSameLocation = this.selectedLocation.info.id === item.id
			if (isSameLocation) {
				return
			}
			document.getElementById(`${this.checkerPrefix}${item.id}`).style.backgroundColor = '#FEC741'
			event.preventDefault()
		},
	},
}
