<template>
	<div class="tree-wrapper" :class="{ floding: isFloding }">
		<div class="tree-header">
			<div class="search-wrap">
				<search-box class="search-box" noRecent :placeholder="tt('그룹으로 검색')" :value="keyword" @change="setKeyword"></search-box>
				<image-button class="btn-floding" :image="require('../svg/arrow-floding.svg')" @click="$emit('floding')" />
			</div>
			<div class="folding-wrapper">
				<div @click="setTreeOpen(true)"><img src="@/assets/svg/icon-unfold.svg" />{{ tt('전체 펼치기') }}</div>
				<span class="br"></span>
				<div @click="setTreeOpen(false)"><img src="@/assets/svg/icon-fold.svg" />{{ tt('전체 접기') }}</div>
			</div>
		</div>
		<div class="tree-wrap">
			<group-node notChecked v-for="node in list" :key="node.id" :node="node"></group-node>
		</div>
	</div>
</template>

<script>
import GroupNode from '@/views/common/components/tree/gen2/components/GroupNode.vue'
import SearchBox from '@/views/components/searchBox'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
	components: {
		GroupNode,
		SearchBox,
	},
	props: {
		isFloding: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('institute/group', ['keyword', 'groups', 'selectedGroups', 'list']),
	},
	watch: {
		selectedGroups() {
			this.$emit('change', this.selectedGroups)
		},
	},
	async created() {
		await this.getGroups({
			name: '',
			duplication: false,
		})
		this.setSelectSingleGroup(this.groups[0])
	},
	methods: {
		...mapMutations('institute/group', ['setSelectSingleGroup', 'setKeyword', 'setTreeOpen']),
		...mapActions('institute/group', ['getGroups']),
	},
}
</script>

<style lang="scss" scoped>
.tree-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	.tree-header {
		padding: 15px 30px 10px;
		z-index: 200;
		background-color: white;
		position: sticky;
		left: 0px;
		top: 0px;
		// border-bottom: 1px solid #e8e8e8;
	}
	.search-wrap {
		margin-bottom: 15px;
		display: flex;
		.search-box {
			width: 200px;
		}
		.btn-floding {
			margin-left: auto;
			width: 30px;
		}
	}
	.folding-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 14px;
		color: $DEFAULT;
		div {
			&:hover {
				background-color: $GREY_2;
			}
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 6px;
		}
		.br {
			display: block;
			width: 1px;
			height: 20px;
			background-color: $DIVIDER;
		}
	}
	&.floding {
		display: none;
		// padding: 15px 0px;
		// overflow: hidden;
		// width: 0px;
		// transition: width 0.3s ease-out;
	}
	.tree-wrap {
		width: 330px;
		overflow: auto;
		height: calc(100% - 92px);
	}
}
</style>
