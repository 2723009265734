var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "msds-file-viewer",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v("MSDS")])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "tab-menu"
  }, [_vm.msds && _vm.msds.master ? _c('button', {
    staticClass: "tab-menu-item",
    "class": {
      selected: _vm.isSelected('master')
    },
    on: {
      "click": function click($event) {
        return _vm.setTab('master');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('제조사 MSDS')) + " ")]) : _vm._e(), _c('button', {
    staticClass: "tab-menu-item",
    "class": {
      selected: _vm.isSelected('institute')
    },
    on: {
      "click": function click($event) {
        return _vm.setTab('institute');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('업로드된 MSDS')) + " ")])]), _c('div', {
    staticClass: "button-wrap"
  }, [!_vm.ghs ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$emit('createGHS');
      }
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/common/components/modalGen2/svg/icon-round-plus.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('GHS 라벨 생성')))])]) : _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$emit('previewGHS');
      }
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/purchase/cargo/svg/icon-ghs.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('GHS 라벨 보기')))])]), _vm.msds && !_vm.msds['institute'] ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.upload
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/common/components/modalGen2/svg/icon-file-upload.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('업로드')))])]) : _vm._e(), _vm.msds && _vm.msds['institute'] ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-delete'].show();
      }
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/common/components/modalGen2/svg/icon-round-delete.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('삭제')))])]) : _vm._e(), _vm.msds && _vm.msds['institute'] ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-change-file'].show();
      }
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/common/components/modalGen2/svg/icon-file-change.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('파일변경')))])]) : _vm._e(), _c('input', {
    ref: "input-file",
    attrs: {
      "type": "file",
      "accept": ".pdf",
      "hidden": ""
    },
    on: {
      "change": function change($event) {
        return _vm.$emit(_vm.msds['institute'] ? 'changeFile' : 'uploadFile', $event);
      }
    }
  })]), _c('div', {
    staticClass: "content"
  }, [_vm.type && _vm.msds[_vm.type] && _vm.msds[_vm.type].url != null ? _c('iframe', {
    staticClass: "pdf-viewer",
    attrs: {
      "id": "myframe",
      "src": _vm.msds[_vm.type].url,
      "frameborder": "0"
    }
  }) : _c('div', {
    staticClass: "upload-wrap"
  }, [_c('input', {
    ref: "drop-file",
    staticClass: "dropFile",
    attrs: {
      "type": "file",
      "accept": ".pdf"
    },
    on: {
      "change": function change($event) {
        return _vm.$emit(_vm.msds['institute'] ? 'changeFile' : 'uploadFile', $event);
      },
      "drop": _vm.dropFile
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-upload.svg"),
      "alt": "icon",
      "width": "67",
      "height": "87"
    }
  }), _c('div', {
    staticClass: "upload-text",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('업로드할 MSDS를<br>드래그 앤 드랍해주세요(PDF)'))
    }
  }), _c('button', {
    staticClass: "lmbutton background-gray",
    on: {
      "click": _vm.upload
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-upload-file.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('업로드')) + " ")])])])], _c('modal-confirm', {
    ref: "modal-change-file",
    attrs: {
      "title": "업로드된 MSDS 변경",
      "content": "정말로 업로드된 MSDS의 파일을 대체하시겠습니까?<br/>기존 파일은 사라집니다",
      "confirmText": "파일변경"
    },
    on: {
      "confirm": _vm.upload
    }
  }), _c('modal-confirm', {
    ref: "modal-delete",
    attrs: {
      "variant": "warning",
      "title": "업로드된 MSDS 삭제",
      "content": "정말로 업로드된 MSDS를 삭제하시겠습니까?<br/>이 동작은 되돌릴 수 없습니다",
      "confirmText": "삭제"
    },
    on: {
      "confirm": _vm.onDelete
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }