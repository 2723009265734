<template>
	<div class="detail-wrapper">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ tt('기관 정보') }}</h2>
			</div>
		</div>
		<div class="common-detail-wrapper" v-if="instInfo">
			<div class="institute-header">
				<img class="logo" :src="instInfo.info.logo ?? require('@/assets/svg/institution-building.svg')" alt="icon" />
				<h3 class="institute-name">
					{{ instInfo.info.name ?? '-' }}
				</h3>
				<div class="right-btn-group">
					<button
						class="btn"
						@click="$refs['ModalEditInformation'].show()"
						v-if="userPermission.institute && userPermission.institute.info.update"
					>
						<img src="@/assets/svg/button-icon-edit.svg" alt="icon" />{{ tt('기관 정보 편집') }}
					</button>
					<button class="btn" @click="$refs['ModalLicenseHistory'].show()" v-if="userPermission.license && userPermission.license.read">
						<img src="@/assets/svg/History(Button).svg" />
						{{ tt('구매내역') }}
					</button>
					<button
						class="btn Btn__license point"
						:class="{ active: !isLicenseActivate }"
						@click="onManageLicense"
						v-if="userPermission.license && userPermission.license.read && userPermission.license.update && !isAppModeExperience"
					>
						<img src="@/assets/svg/button-icon-license.svg" alt="관리icon" />{{ tt('라이선스 관리') }}
					</button>
				</div>
			</div>
			<div class="institute-content">
				<h5>기관 정보</h5>
				<div class="content">
					<div class="item">
						<div class="label">{{ tt('사업자등록번호') }}</div>
						<p :class="{ 'no-data': !instInfo.info.traderRegistrationNumber }">
							{{ instInfo.info.traderRegistrationNumber ?? tt('정보 없음') }}
						</p>
					</div>
					<div class="item">
						<div class="label">{{ tt('연락처') }}</div>
						<p :class="{ 'no-data': !instInfo.info.phone }">
							{{ instInfo.info.phone ?? tt('정보 없음') }}
						</p>
					</div>
					<div class="item">
						<div class="label">{{ tt('주소') }}</div>
						<p :class="{ 'no-data': !instInfo.info.address }">
							{{ instInfo.info.address ?? tt('정보 없음') }}
						</p>
					</div>
					<div class="item">
						<div class="label">{{ tt('총 멤버 수') }}</div>
						<p>{{ instInfo.allMemberCount }}</p>
					</div>
					<div class="item">
						<div class="label">{{ tt('시스템관리자') }}</div>
						<p>{{ setAlmightyMembers }}</p>
					</div>
				</div>
				<h5>라이선스</h5>
				<div class="content">
					<div class="item">
						<div class="label">{{ tt('상태') }}</div>
						<p
							class="license-status"
							:class="{
								warning: !isLicenseActivate,
							}"
						>
							{{ licenseStatusText }}
						</p>

						<button
							class="payment-status"
							v-if="isLicenseDepositWait"
							v-b-tooltip.hover="tt('계산서 발행 후 30일 이내 입금하지 않으면, 라이선스가 비활성화 됩니다.')"
						>
							{{ tt('입금대기') }}
						</button>
					</div>
					<div class="item">
						<div class="label">{{ tt('라이선스 유형') }}</div>
						<p>{{ licenseTypeText }}</p>
					</div>
					<div class="item">
						<div class="label">{{ tt('만료일') }}</div>
						<p>{{ formatDatetime(license.expireDate) }}</p>
					</div>
					<div class="item">
						<div class="label">{{ tt('라이선스 수') }}</div>
						<p>{{ licenseQuantityText }}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- modals -->
		<ModalLicenseHistory ref="ModalLicenseHistory" />
		<ModalEditInformation ref="ModalEditInformation" />
	</div>
</template>
<script>
import ModalLicenseHistory from '../modals/ModalLicenseHistory.vue'
import ModalEditInformation from '../modals/ModalEditInformation.vue'
import ModalLicenseManage from '../modals/ModalLicenseManage.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
export default {
	name: 'InstitutionInfoPage',
	components: {
		ModalLicenseHistory,
		ModalEditInformation,
	},
	mixins: [MixinLicense, MixinPayment],
	mounted() {},
	computed: {
		...mapState('user', ['institute', 'user']),
		setAlmightyMembers() {
			return this.instInfo.almightyMembers
				?.map(function (i) {
					return i.name
				})
				?.join(', ')
		},
	},
	async created() {
		try {
			this.refreshData()
			await this.loadInstitutionInfo(this.$route.params.instituteId)
			await this.loadLicense()
			await this.loadLicenseManagement()
			this.showToastNeedCert()
			// TODO: 기획 정의 필요 - 현재 기본으로 설정된 카드가 없을때 404 error 발생
			await this.loadDefaultCreditCard()
		} catch (e) {}
	},
	methods: {
		...mapActions('license', ['loadLicense', 'loadLicenseHistory']),
		...mapActions('user', ['loadUserInstituteInfo']),
		...mapActions('institutionInformation', ['loadInstitutionInfo']),
		async refreshData() {
			await this.loadUserInstituteInfo()
			const institutionId = this.$route.params.instituteId
			await this.loadInstitutionInfo(institutionId)
			this.isSubscribePurchaseMode = false
			this.$emit('changeInfo', this.instInfo)

			if (this.license?.status == 'EXPIRED') {
				this.$root.toast('라이선스 만료', '라이선스가 만료되었습니다', 'error')
			}
		},
		onManageLicense() {
			if (this.isTrial) {
				this.$root.howToAddLicense()
			} else {
				this.$root.managementLicense()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.common-detail-wrapper {
	padding: 30px;
	& > div {
		margin-bottom: 30px;
	}
}
.institute-header {
	display: flex;
	width: 100%;
	align-items: center;
	padding-bottom: 30px;
	border-bottom: 1px solid $DIVIDER;
	.logo {
		width: 80px;
		height: 80px;
		object-fit: cover;
		border-radius: 16px;
		padding: 6px;
		border: 1px solid #e4e5ea;
		margin-right: 20px;
	}
	h3.institute-name {
		font-size: 1.5rem;
		font-weight: 700;
		color: #000;
	}
	.btn {
		&:hover {
			background-color: #dadbdc;
		}
	}
	.Btn__license {
		background-color: #fecb4e;
		&:hover {
			background-color: $LAB_YELLOW;
		}
	}
}
.institute-content {
	display: flex;
	flex-direction: column;
	h5 {
		font-size: 1rem;
		font-weight: 700;
		margin-bottom: 20px;
	}
	.content {
		display: inline-flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
		.item {
			width: 25%;
			padding-bottom: 30px;
			.label {
				font-size: 0.75rem;
				color: $SECONDARY_2;
				margin-bottom: 6px;
			}
			p {
				font-size: 0.875rem;
				color: $DEFAULT;
				&.no-data {
					color: $PLACEHOLDER;
				}
			}
			.license-status {
				display: inline-flex;
				padding: 0 5px;
				height: 24px;
				background: #f4fffa;
				border: 1px solid #b5e7d2;
				border-radius: 5px;
				align-items: center;
				color: $GREEN;
				box-sizing: border-box;
				font-weight: 700;
				font-size: 12px;
				img {
					width: 12px;
					height: 12px;
					margin-right: 3px;
				}
				&.warning {
					background: #fff5f5;
					border: 1px solid #ffdad7;
					color: $RED;
					img {
						content: url(~@/assets/svg/sign-warning.svg);
					}
				}
			}
			.payment-status {
				padding: 0 5px;
				height: 24px;
				display: inline-block;
				background: #fff3ed;
				border: 1px solid #ffb999;
				color: #ff8248;
				border-radius: 5px;
				font-weight: 700;
				font-size: 12px;
				margin-left: 5px;
			}
			.license-count {
				display: inline-flex;
				align-items: center;
				span {
					display: inline-block;
					color: $RED;
					font-weight: 700;
				}
			}
		}
	}
}
</style>
