<template>
	<b-modal centered hide-footer size="sm" @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div style="display: flex; font-size: 16px">
				<p>{{ tt('상품을 추가했습니다.') }}</p>
			</div>
		</template>
		<template>
			<base-button @click="confirm" style="width: 100%; margin-bottom: 10px; height: 40px" :color="'primary'">{{
				tt('상품 주문으로 돌아가기')
			}}</base-button>
			<base-button @click="hide" style="width: 100%; height: 40px">{{ tt('계속 검색하기') }}</base-button>
		</template>
	</b-modal>
</template>

<script>
export default {
	components: {},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		confirm() {
			this.$emit('confirm')
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-sm {
	max-width: 350px;
}
::v-deep .close {
	color: $SECONDARY_2;
}
::v-deep .modal-header {
	border-bottom: none;
	padding-bottom: 0;
}
::v-deep .modal-content {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
