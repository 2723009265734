<template>
	<div>
		<modal-regist-item ref="modal-regist-item" title="물품 등록" @next="registItemNextStep"></modal-regist-item>
		<modal-camera
			ref="modal-camera"
			title="사진으로 등록"
			isStartPopup
			@noPermission="noPermissionCam"
			@prev="$refs['modal-regist-item'].show()"
			@next="$refs['modal-item-management'].show()"
			@change="changeUrls"
		></modal-camera>
		<modal-search-product
			ref="modal-search-product"
			title="등록할 물품 검색"
			:categoryInfo="categoryInfo"
			forRegistItem
			@selectItem="selectProduct"
			@inputDirect="$refs['modal-item-product'].show()"
			@setRegistType="type => setRegistMethod(type)"
		></modal-search-product>
		<modal-item-product ref="modal-item-product" title="물품정보 등록" @cancel="initItem()" @complete="showManagementModal" forRegistItem>
		</modal-item-product>
		<modal-item-management
			ref="modal-item-management"
			title="관리정보 등록"
			@goPrev="managementPrev"
			@complete="managementComplete"
		></modal-item-management>
		<modal-complete-regist
			ref="modal-complete-regist"
			title="물품 등록 완료!"
			confirmBtnText="더 등록하기"
			@confirm="registMore"
			@cancel="initItem()"
		></modal-complete-regist>
		<modal-excel-regist ref="modal-excel-regist" title="Excel 파일로 등록" @complete="excelRegistComplete"></modal-excel-regist>
	</div>
</template>
<script>
import ModalRegistItem from '@/views/inventoryGen2/components/regist/modal/ModalRegistItem.vue'
import ModalCamera from '@/views/inventoryGen2/components/regist/modal/ModalCamera.vue'
import ModalItemManagement from '@/views/inventoryGen2/components/regist/modal/ModalItemManagement.vue'
import ModalCompleteRegist from '@/views/inventoryGen2/components/regist/modal/ModalCompleteRegist.vue'
import ModalSearchProduct from '@/views/common/components/modalGen2/ModalSearchProduct.vue'
import ModalItemProduct from '@/views/inventoryGen2/components/regist/modal/ModalItemProduct.vue'
import ModalExcelRegist from '@/views/inventoryGen2/components/regist/modal/ModalExcelRegist.vue'
import { RegistType } from '@/utils/define/ItemCode'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
export default {
	name: 'ItemModals',
	components: {
		ModalRegistItem,
		ModalCamera,
		ModalItemManagement,
		ModalCompleteRegist,
		ModalSearchProduct,
		ModalItemProduct,
		ModalExcelRegist,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/form', ['item', 'registMethod', 'categoryInfo', 'isChemical', 'registMethod', 'itemFormDataByDirect']),
		...mapGetters('inventoryGen2/list', ['selectedCategory']),
		...mapGetters('form/image', ['removeImageLink']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/form', [
			'setImageList',
			'setFormItem',
			'initRegistMore',
			'initItem',
			'setShowError',
			'setFormType',
			'setItemFormType',
			'setRegistMethod',
		]),
		...mapActions('inventoryGen2/form', ['registItemByImage', 'getMsds', 'registItemByDirect']),
		...mapActions('inventoryGen2/list', ['getList']),
		registItemNextStep() {
			const isError = !(this.categoryInfo && this.registMethod)
			if (isError) {
				this.setShowError(isError)
				return
			}
			this.setItemFormType('NORMAL_REGIST')
			switch (this.registMethod) {
				case RegistType.AUTO.IMAGE:
					this.showCamera()
					break
				case RegistType.MANUAL.DIRECT:
				case RegistType.MANUAL.SEARCH:
				case RegistType.MANUAL.QR:
					if (this.isChemical) this.$refs['modal-search-product'].show()
					else this.$refs['modal-item-product'].show()
					break
				case RegistType.MANUAL.FILE:
					this.$refs['modal-excel-regist'].show()
					break
				default:
					break
			}
		},
		showManagementModal() {
			let newItem = this.$_.cloneDeep(this.item)

			//담당자 기본값 설정
			newItem.assignee = this.$root.user
			newItem.assigneeId = this.$root.user.id
			//보관함 기본값 설정
			const storageCookiesValue = this.$cookies.get('SELECT_STORAGE_KEY')
			if (storageCookiesValue) {
				newItem.storage = storageCookiesValue
				newItem.storageId = storageCookiesValue.id
			}
			this.setFormItem(newItem)
			this.$refs['modal-item-management'].show()
		},
		noPermissionCam() {
			this.$refs['modal-regist-item'].show()
			this.$root.toast('카메라 사용 차단됨', '브라우저 설정에서 차단을 해제해주세요', ToastType.ERROR)
		},
		changeUrls(urls) {
			const images = [...urls.filter(u => !this.item?.deleteImages?.includes(u))]
			this.setFormItem({ ...this.item, imageList: images, images: images })
		},
		showRegist() {
			this.$refs['modal-regist-item'].show()
		},
		showCamera(type) {
			if (type === 'prev') {
				const images = this.$_.cloneDeep(this.item.images)
				this.$refs['modal-camera'].setData([...images?.filter(img => !this.item?.deleteImages?.includes(img))])
				this.item?.deleteImages?.forEach(url => {
					window.URL.revokeObjectURL(url)
				})
			} else {
				this.$refs['modal-camera'].setData([])
			}
			this.$refs['modal-camera'].show()
		},
		managementPrev() {
			switch (this.registMethod) {
				case RegistType.AUTO.IMAGE:
					this.showCamera('prev')
					break
				case RegistType.MANUAL.DIRECT:
				case RegistType.MANUAL.SEARCH:
				case RegistType.MANUAL.QR:
					this.$refs['modal-item-product'].show(true)
				default:
					break
			}
		},
		managementComplete() {
			this.$refs['modal-complete-regist'].show()
			this.getList()
		},
		registMore() {
			this.$refs['modal-complete-regist'].hide()
			this.initRegistMore()
			switch (this.registMethod) {
				case RegistType.AUTO.IMAGE:
					this.$refs['modal-camera'].setData([])
					this.$refs['modal-camera'].show()
					break
				case RegistType.MANUAL.DIRECT:
				case RegistType.MANUAL.SEARCH:
				case RegistType.MANUAL.QR:
					if (this.isChemical) this.$refs['modal-search-product'].show()
					else this.$refs['modal-item-product'].show()
					break
				default:
					break
			}
		},
		async selectProduct(item) {
			this.setFormItem(item)
			await this.getMsds({
				brandId: item.brand?.id,
				productCode: item.productCode,
			})
			this.$refs['modal-item-product'].show()
		},
		excelRegistComplete() {
			console.log('엑셀등록 ㅇㅋ')
		},
	},
}
</script>

<style lang="scss" scoped></style>
