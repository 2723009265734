var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "option-info left"
  }, [_c('div', {
    staticClass: "option-image"
  }, [_c('img', {
    staticClass: "option-image-box",
    attrs: {
      "src": _vm.image
    }
  })]), _c('div', {
    staticClass: "option-content"
  }, [_c('div', {
    staticClass: "seller-name"
  }, [_c('span', [_vm._v(" " + _vm._s("[".concat(_vm.tt(_vm.product.seller.name), "]")) + " ")])]), _c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")]), _c('div', {
    staticClass: "subname"
  }, [_vm._v(" " + _vm._s(_vm.product.subname) + " ")]), _c('div', {
    staticClass: "data"
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('카테고리')) + " : " + _vm._s(_vm.tt(_vm.cleanData(_vm.category2String(_vm.product.categoryType)))))]), _c('span', [_vm._v(_vm._s(_vm.tt('브랜드')) + " : " + _vm._s(_vm.tt(_vm.cleanData(_vm.product.brand ? _vm.product.brand.name : null))))]), _c('span', [_vm._v(_vm._s(_vm.tt('제품번호')) + " : " + _vm._s(_vm.tt(_vm.cleanData(_vm.product.productCode))))]), _c('span', [_vm._v(_vm._s(_vm.tt('CAS No')) + " : " + _vm._s(_vm.tt(_vm.cleanData(_vm.product.casno))))]), _vm.product.volume ? _c('span', [_vm._v(" " + _vm._s(_vm.tt('사이즈(용량)')) + " : " + _vm._s(_vm.tt("".concat(_vm.product.volume ? _vm.product.volume : _vm.product.currentVolume).concat(_vm.product.volumeUnit))) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "price-delivery"
  }, [_c('span', {
    staticClass: "price"
  }, [_vm._v(" " + _vm._s(_vm.tt('${1}원', _vm.CommaNum(_vm.product.sellingUnitPrice))) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }