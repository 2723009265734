import { MiddleClass } from '@/utils/define/ItemCode'
import PageName from '@/utils/define/PageName'
import { mapActions } from 'vuex'

export default {
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	methods: {
		...mapActions('inventoryGen2/item', ['disposeItem', 'returnItem', 'consumeItem']),
		...mapActions('inventoryGen2/historyInfo', ['getList']),
		async dispose(memo) {
			try {
				await this.disposeItem({
					itemsId: [this.item.id],
					memo: memo,
				})
				this.$root.toast('폐기 완료', '물품이 폐기되었습니다', 'success')
				this.goToInventoryList()
			} catch (e) {
				this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
			}
		},
		async disposeEmptyVolume(memo, consumeBody) {
			if (consumeBody) {
				await this.completeUse(consumeBody)
			}
			await this.dispose(memo)
			this.$refs['modal-empty-volume'].hide()
		},
		async noDisposeEmptyVolume(consumeBody) {
			await this.completeUse(consumeBody)
			this.$refs['modal-empty-volume'].hide()
		},
		goToInventoryList() {
			this.$router.replace({
				name: PageName.InventoryGen2.List,
			})
		},
		async completeUse(body) {
			let type = this.item.category.type
			let data = {
				memo: body?.memo,
			}
			if (type === MiddleClass.CHROMATOGRAPHY) {
				data = {
					changeUseCount: Number(this.useVolume),
					...data,
				}
			} else if (type !== MiddleClass.EQUIPMENT) {
				data = {
					changeVolume: Number(this.useVolume),
					changeVolumeUnit: this.item.volumeUnit,
					...data,
				}
			}

			if (this.item.status === 'HOLDING_OWN' || this.item.status === 'HOLDING') {
				// 1. 물품 사용중 , 2. 잔량모두입력 = 반납 ( return )
				data = {
					itemsId: [this.item.id],
					...data,
				}
				await this.returnItem(data)
			} else {
				// 1. 물품 미사용 , 2. 잔량모두입력 = 사용량입력 ( consume )
				data = {
					id: this.item.id,
					...data,
				}
				await this.consumeItem(data)
			}

			await this.getList()
			this.$root.toast('사용 완료', '물품이 사용 완료되었습니다', 'success')
		},
	},
}
