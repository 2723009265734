var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail-wrapper"
  }, [_c('div', {
    staticClass: "common-header-wrapper"
  }, [_c('div', [_c('h2', [_vm._v(_vm._s(_vm.tt(_vm.title)))])])]), _c(_vm.pageName, {
    ref: "registration-form",
    tag: "component"
  }), _c('div', {
    staticClass: "common-detail-bottom"
  }, [_c('base-button', {
    attrs: {
      "size": "lg",
      "color": "primary only"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.page == 1 ? '나가기' : '이전으로')))]), _c('base-button', {
    attrs: {
      "size": "lg",
      "color": "primary"
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v(_vm._s(_vm.tt('다음으로')))])], 1), _c('modal-confirm', {
    ref: "modal-out",
    attrs: {
      "title": "페이지 이동",
      "confirmText": _vm.page == 1 ? '나가기' : '이전으로',
      "content": "페이지를 이동하면 지금까지 작성한 내용이 사라집니다.<br>페이지를 이동하시겠습니까?"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }