<template>
	<thead>
		<td class="type">{{ `${tt('타입')}/${tt('규격')}` }}</td>
		<td class="preview">{{ tt('미리보기') }}</td>
	</thead>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'TableHeader',
	components: {},
	props: {},
	data() {
		return {
			checkedAll: false,
		}
	},
	computed: {
		...mapGetters('modal/printSticker', ['previewData', 'allItemIds']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('modal/printSticker', ['setSelectedItem']),
		selectAll() {
			this.setSelectedItem(this.isChecked ? [] : [...this.allItemIds])
		},
	},
}
</script>

<style lang="scss" scoped>
td + td {
	border-left: 1px solid $DIVIDER;
}

thead {
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid $DIVIDER;
	position: sticky;
	top: 0;
	background: white;
	z-index: 10;
	td {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		font-size: 14px;
		font-weight: 400;
		&.type {
			width: 240px;
		}
		&.preview {
			width: 110px;
		}
	}
}

input.someChecked {
	&::before {
		width: 18px;
		height: 18px;
		content: '';
		border: 1px solid #ccc;
		border-radius: 4px;
		background-color: $LAB_WHITE;
		background-size: 24px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(~@/views/purchase/cargo/svg/icon-check-mutiple-status.svg) !important;
		z-index: 1;
		margin-top: -2px;
		margin-left: -1px;
		box-sizing: border-box;
	}
}
</style>
