var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    style: {
      '--overflow': _vm.loading ? 'hidden' : 'auto'
    },
    attrs: {
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('GHS 라벨')))]), _c('div', {
          staticClass: "button-area"
        }, [_c('button', {
          staticClass: "button-gray",
          on: {
            "click": _vm.printGHS
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/svg/icon-print.svg"),
            "width": "24"
          }
        }), _vm._v(" " + _vm._s(_vm.tt('인쇄')) + " ")]), _c('button', {
          staticClass: "button-gray",
          on: {
            "click": _vm.download
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/views/common/components/modalGen2/svg/icon-file-download.svg"),
            "width": "24"
          }
        }), _vm._v(" " + _vm._s(_vm.tt('다운로드')) + " ")]), _c('button', {
          staticClass: "button-gray",
          on: {
            "click": function click($event) {
              return _vm.$emit('edit');
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/views/common/components/modalGen2/svg/icon-edit.svg"),
            "width": "24"
          }
        }), _vm._v(" " + _vm._s(_vm.tt('편집')) + " ")]), _c('button', {
          staticClass: "button-gray",
          on: {
            "click": function click($event) {
              return _vm.$emit('delete');
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/views/common/components/modalGen2/svg/icon-round-delete.svg"),
            "width": "24"
          }
        }), _vm._v(" " + _vm._s(_vm.tt('삭제')) + " ")])])];
      },
      proxy: true
    }])
  }, [[_c('ghs-label', {
    attrs: {
      "volume": _vm.normalizedVolume
    }
  }), _c('div', {
    attrs: {
      "id": "exportDiv",
      "hidden": ""
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }