<template>
	<div class="lm-sb-wrapper" ref="search-box" v-click-outside="focusOut">
		<div class="lm-sb" :class="{ readonly: readonly, focus: isFocus }">
			<input
				ref="input"
				:disabled="disabled"
				:readonly="readonly"
				class="transparent"
				v-model="text"
				@keypress.enter="onDataChanged"
				@input="handleInput"
				@focus="isFocus = true"
				:placeholder="tt(placeholder)"
			/>
			<template v-if="text != null && !readonly">
				<button v-show="isScan" class="reset" @click="resetBarcode">
					<p>{{ 'Reset' }}</p>
				</button>
				<img v-show="hasQR && text.length == 0" class="qr" src="./assets/ic-qr.svg" @click="clickQR" />
				<img v-show="!isScan && text.length > 0" class="qr" src="./assets/ic-delete.svg" @click="deleteValue" />
			</template>
			<img class="btn-search" src="./assets/ic-search.svg" @click="onDataChanged" />
		</div>
		<div class="keyword-wrapper" v-show="isKeywordWrapperOpen">
			<tag v-if="hasTag" :tags="tags" @clickTag="clickTag" />
			<recent ref="recent" @clickRecent="clickRecent" />
		</div>
	</div>
</template>

<script>
import Recent from './recent'
import Tag from './tag.vue'
import { mapActions } from 'vuex'
export default {
	components: {
		Recent,
		Tag,
	},
	props: {
		hasQR: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '검색',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		noRecent: {
			type: Boolean,
			default: false,
		},
		isScan: {
			type: Boolean,
			default: false,
		},
		hasTag: {
			type: Boolean,
			default: false,
		},
		isImmediately: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			text: '',
			isFocus: false,
			tags: [],
		}
	},
	watch: {
		isFocus(val) {
			if (val && this.hasTag) this.getTags()
		},
		value(val) {
			this.text = val
		},
	},
	computed: {
		isKeywordWrapperOpen() {
			return this.isFocus && !this.noRecent
		},
	},
	mounted() {
		// if (!this.readonly) {
		// 	this.focus()
		// }
		if (this.value != this.text) {
			this.text = this.value
		}
	},
	methods: {
		...mapActions('inventoryGen2/list', ['getTagList']),
		async onDataChanged() {
			if (!this.isScan) {
				this.$emit('input', this.text)
				this.$emit('change', this.text)
				if (!this.noRecent) this.$refs['recent'].addRecent(this.text)
			}
			if (this.hasTag && !this.isScan) {
				this.getTags()
			}
		},
		async getTags() {
			let response = await this.getTagList()
			if (response.status == 200) {
				const { list } = response.data
				this.tags = list
			}
		},
		clickTag(tag) {
			this.text = '#' + tag.name
			this.$emit('clickTag', tag.id)
			if (!this.noRecent) this.$refs['recent'].addRecent(this.text)
			this.isFocus = false
		},
		clickRecent(keyword) {
			this.text = keyword
			this.onDataChanged()
			this.isFocus = false
		},
		focus() {
			this.$refs.input.focus()
		},
		deleteValue() {
			this.text = ''
			this.onDataChanged()
		},
		clickQR() {
			this.$emit('clickQR')
		},
		focusOut() {
			this.isFocus = false
		},
		handleInput(e) {
			// 한국어 변경 즉시 반영되게 작업
			this.text = e.target.value
			// 아래 코드 작성이유 : 담당자 검색 모달에서 input시 즉시 결과 반영 요청 (2023.03.28)
			if (this.isImmediately) this.$emit('change', this.text)
		},
		resetBarcode() {
			this.$emit('resetBarcode')
		},
	},
}
</script>

<style lang="scss" scoped>
.lm-sb-wrapper {
	position: relative;
	.lm-sb {
		position: relative;
		min-width: 200px;
		height: 30px;
		background-color: white;
		border-radius: 4px;
		display: flex;
		align-items: center;
		padding-right: 26px;
		border: 1px solid #e4e5ea;
		&.readonly {
			border: none;
		}
		&.focus {
			border-color: #000;
			filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
		}
		input {
			background-color: transparent;
			width: 100%;
			height: 100%;
			padding-left: 10px;
			font-size: 14px;
			font-weight: 400;
			color: $DEFAULT;
			border: none;
		}
		img {
			padding: 5px;
			cursor: pointer;
		}
		.qr {
			margin-left: auto;
		}
		.reset {
			padding: 0px;
			margin-right: 10px;
			color: $BLUE;
			text-decoration: underline;
		}
		.btn-search {
			cursor: pointer;
			width: 30px;
			height: 100%;
			position: absolute;
			right: 0px;
			background-color: $GREY_4;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			padding: 3px;
		}
	}
	.keyword-wrapper {
		z-index: 200;
		width: 100%;
	}
}
</style>
