var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "progress-indicator"
  }, [_c('div', {
    staticClass: "indicator",
    style: "width: ".concat(_vm.step, "%")
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }