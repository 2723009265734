<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade hide-footer @close="cancel">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="body">
				<component
					v-if="isCanCount"
					v-bind:is="categoryByInput"
					:volume="volume"
					:volumeUnit="volumeUnit"
					:useCount="useCount"
					@input="volume => setUseVolume(volume)"
				></component>
				<div class="reason-wrap">
					<div class="input-head-text">
						<p>{{ tt('사용 사유') }}</p>
						<p class="text-length">
							<span class="length" :class="{ over: isLengthOver }"> {{ reason.length }} </span>/{{ textMaxLength }}
						</p>
					</div>
					<div v-click-outside="focusOut">
						<textarea
							class="input-reason"
							:class="{ error: isShowReasonError && isLengthOver }"
							:value="formattedReason"
							@input="e => setReason(e.target.value)"
							@focus="isFocus = true"
							:placeholder="tt(`사용 사유를 입력해주세요(${textMaxLength}자 제한)`)"
						/>
						<p class="error-msg" v-if="isShowReasonError && isLengthOver">
							<img src="@/views/inventoryGen2/components/svg/icon-error.svg" />
							{{ tt('글자수를 초과하였습니다.') }}
						</p>
						<recent
							ref="recent"
							:show="isKeywordWrapperOpen"
							:list="commentList"
							@clickComment="clickComment"
							@deleteComment="clickDeleteComment"
						/>
					</div>
				</div>
			</div>
			<div class="modal-footer-wrap">
				<button class="button-white" @click="cancel">
					{{ tt(cancelText) }}
				</button>
				<button :class="`button-${variant}`" @click="confirm">
					{{ tt(confirmText) }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
import ChemicalInput from '@/views/inventoryGen2/components/detail/modal/modalInputUse/chemicalInput.vue'
import ChromatographyInput from '@/views/inventoryGen2/components/detail/modal/modalInputUse/chromatographyInput.vue'
import DefaultInput from '@/views/inventoryGen2/components/detail/modal/modalInputUse/defaultInput.vue'
import Recent from '@/views/inventoryGen2/components/detail/modal/modalInputUse/recent.vue'
import { LargeClass } from '@/utils/define/ItemCode'
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
export default {
	name: 'ModalInputUse',
	mixins: [MixinItem],
	components: { Recent },
	props: {
		title: {
			type: String,
			default: '',
		},
		volume: {
			type: [Number, String],
			default: null,
		},
		volumeUnit: {
			type: String,
			default: '',
		},
		textMaxLength: {
			type: Number,
			default: 200,
		},
		variant: {
			type: String,
			default: 'primary',
		},
		confirmText: {
			type: String,
			default: '',
		},
		cancelText: {
			type: String,
			default: '',
		},
		noRecent: {
			type: Boolean,
			default: false,
		},
		largeClass: {
			type: String,
		},
		useCount: {
			type: [Number, String],
			default: '',
		},
		isCanCount: {
			type: Boolean,
			default: true,
		},
		type: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isFocus: false,
			commentList: [],
		}
	},
	computed: {
		...mapGetters('modal/inputUse', [
			'useVolume',
			'reason',
			'isShowVolumeError',
			'isShowReasonError',
			'isInputVolumeOver',
			'isInputVolumeEmpty',
			'isInputReasonOver',
			'formattedReason',
		]),
		categoryByInput() {
			switch (this.largeClass) {
				case LargeClass.CHEMICAL:
					return ChemicalInput
				case LargeClass.CHROMATOGRAPHY:
					return ChromatographyInput
				default:
					return DefaultInput
			}
		},
		isLengthOver() {
			return this.reason.length > this.textMaxLength
		},
		isKeywordWrapperOpen() {
			return this.isFocus && this.reason.length <= 0 && !this.noRecent
		},
		isCategoryChromatography() {
			return this.largeClass === LargeClass.CHROMATOGRAPHY
		},
		isCategoryChemical() {
			return this.largeClass === LargeClass.CHEMICAL
		},
	},
	watch: {
		reason(value) {
			if (value.length < this.textMaxLength) {
				this.setIsShowReasonError(false)
			}
		},
	},
	methods: {
		...mapMutations('modal/inputUse', ['setUseVolume', 'setReason', 'setIsShowVolumeError', 'setIsShowReasonError']),
		...mapActions('inventoryGen2/item', ['getComment', 'addComment', 'deleteComment']),
		async initData() {
			this.setUseVolume(null)
			this.setReason('')
			this.setIsShowVolumeError(false)
			this.setIsShowReasonError(false)
			if (this.type) this.commentList = await this.getComment('RETURN')
			this.isFocus = false
		},
		show() {
			this.$refs['modal'].show()
			this.initData()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cancel() {
			this.$emit('cancel')
			this.hide()
		},
		async confirm() {
			if (this.type == 'CONSUME' && Number(this.useVolume || 0) == 0) {
				this.$root.toast(`사용량 입력 불가`, '사용량을 입력해주세요.', 'error')
				return
			}
			if (!this.isCategoryChromatography && (this.isInputVolumeOver(this.volume) || this.isLengthOver)) {
				this.checkValid()
				return
			}
			let isEmptyVolume = this.volume === this.useVolume && this.isCanCount
			let returnValue = {
				memo: this.reason,
			}
			if (this.isCategoryChromatography) {
				returnValue = { changeUseCount: this.useVolume, ...returnValue }
			} else {
				returnValue = { changeVolume: this.useVolume, changeVolumeUnit: this.volumeUnit, ...returnValue }
			}
			this.$emit(isEmptyVolume ? 'showEmptyVolume' : 'confirm', returnValue)
			if (this.reason) await this.addComment({ type: 'RETURN', comment: this.formattedReason })
			this.hide()
		},
		checkValid() {
			this.setIsShowVolumeError(this.isInputVolumeOver(this.volume))
			this.setIsShowReasonError(this.isInputReasonOver(this.textMaxLength) || this.isInputReasonOver)
		},
		clickComment(text) {
			this.setReason(text)
			this.isFocus = false
		},
		async clickDeleteComment(id) {
			await this.deleteComment(id)
			this.commentList = await this.getComment(this.type)
		},
		focusOut() {
			this.isFocus = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/inventory/gen2/modal/_modal-input-use.scss';
</style>
