<template>
	<div class="badge-list-wrap" v-if="item">
		<item-badge :image="categoryImage" :text="categoryName" subtext="카테고리"></item-badge>
		<item-badge
			v-if="!isCategoryEquipment"
			:image="require(`@/views/purchase/cargo/svg/badge-box${isCurrentVolumeValid ? '' : '-red'}.svg`)"
			:text="formatVolumeBadge"
			:subtext="subtext"
			:isValid="isCurrentVolumeValid"
		></item-badge>
	</div>
</template>
<script>
import ItemBadge from '@/views/purchase/cargo/components/cargoDetail/badge/ItemBadge.vue'
import MixinItem from '@/mixins/inventory/MixinItem'
import { MiddleClass, MiddleClassToString } from '@/utils/define/ItemCode'
export default {
	name: 'BadgeListDefault',
	mixins: [MixinItem],
	components: { ItemBadge },
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			nullText: `<p class="nodata">${this.tt('정보없음')}</p>`,
		}
	},
	computed: {
		isCategoryEquipment() {
			return this.item.category.type === MiddleClass.EQUIPMENT || this.item.categoryType === MiddleClass.EQUIPMENT
		},
		isCategoryETC() {
			return this.item.category.type === MiddleClass.ETC
		},
		categoryName() {
			if (this.item?.category?.name) {
				return this.item.category.name.length >= 10 ? `${this.item.category.name.slice(0, 10)}...` : this.item.category.name
			} else {
				return MiddleClassToString(this.item?.category?.type)
			}
		},
		categoryImage() {
			switch (this.item?.category?.type) {
				case 'CAT_CHROMATOGRAPHY':
					return require('@/views/purchase/cargo/svg/category/chromatography.svg')
				case 'CAT_LABWARE_EQUIPMENT':
					return require('@/views/purchase/cargo/svg/category/equipment.svg')
				case 'CAT_LABWARE_TOOL':
					return require('@/views/purchase/cargo/svg/category/tool.svg')
				case 'CAT_PROTECTION':
					return require('@/views/purchase/cargo/svg/category/protection.svg')
				case 'CAT_ETC':
					return require('@/views/purchase/cargo/svg/category/etc.svg')
				default:
					return require('@/views/purchase/cargo/svg/category/etc.svg')
			}
		},
		isCurrentVolumeValid() {
			return this.item.currentVolume > 0 || !this.isCategoryETC
		},
		formatVolumeBadge() {
			return this.item.currentVolume > 0
				? `${Number(this.item.currentVolume).toLocaleString()}${this.item.volumeUnit ?? ''}`
				: this.tt('수량없음')
		},
		subtext() {
			return '잔여 수량'
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.badge-list-wrap {
	display: inherit;
	flex-direction: row;
	padding: 0px 30px;
	border-bottom: 1px solid $DIVIDER;
}

.badge-wrap {
	&:first-child {
		border-left: 1px solid $DIVIDER;
		border-right: 1px solid $DIVIDER;
	}
	&:last-child {
		border-right: 1px solid $DIVIDER;
	}
}

.badge-wrap + .badge-wrap {
	border-right: 1px solid $DIVIDER;
}
</style>
