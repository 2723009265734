<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="Modal ModalRegistCard Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm" ref="container">
				<div class="Popup__top">
					<h5 class="Popup__title" v-text="mode == 'REGIST' ? tt('결제카드 등록') : tt('결제카드 변경')"></h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__notice" v-if="mode === 'CHANGE'">
					<div class="notice-text">{{ tt('기존의 결제카드 정보가 새로운 카드 정보로 대체됩니다.') }}</div>
				</div>
				<div class="Popup__content">
					<h5 tabindex="0">{{ tt('결제수단') }}</h5>
					<div class="Popup__input-field">
						<p>{{ tt('카드번호') }}<span class="required">*</span></p>
						<input
							type="text"
							:placeholder="tt('14 ~ 16자리 번호를 입력해주세요')"
							:maxlength="cardNumberMaxLength"
							@input="autoDashCreditCard"
							:value="cardNumber"
							@focus.stop.prevent="$root.cardInput = true"
							@click.stop
							ref="input-cardnum"
							:class="{
								'on-input': $root.cardInput,
								error: cardNoError,
							}"
							readonly
						/>
						<span class="error-text" v-if="cardNoError">
							<img src="@/assets/svg/input-icon-warning.svg" />
							<p>
								{{ tt('유효하지 않은 카드번호입니다.') }}
							</p>
						</span>
						<card-number-keyboard
							:isShow="$root.cardInput"
							:numbers="cardInputButtonNumbers"
							@add="addNumber"
							@reset="resetInputNumber"
							@remove="removeInputNumber"
							@click.stop
						></card-number-keyboard>
					</div>
					<div class="Popup__input-field">
						<p>
							{{ tt('유효기간(MM/YY)') }}
							<span class="required">*</span>
						</p>
						<input
							type="text"
							:placeholder="tt('MM/YY')"
							maxlength="5"
							@input="inputExpirationMonthYear"
							:value="form.expirationMonthYear"
							:class="{ error: expirationError }"
						/>
						<span class="error-text" v-if="expirationError">
							<img src="@/assets/svg/input-icon-warning.svg" />
							<p>
								{{ tt('잘못된 유효기간입니다.') }}
							</p>
						</span>
					</div>
					<div class="Popup__input-field">
						<p>
							{{ tt('비밀번호 앞 2자리') }}
							<span class="required">*</span>
						</p>
						<input
							type="password"
							:placeholder="tt('비밀번호 앞 2자리를 입력해주세요')"
							maxlength="2"
							@input="inputPassword"
							:value="form.password"
							:class="{ error: passwordError }"
						/>

						<span class="error-text" v-if="passwordError">
							<img src="@/assets/svg/input-icon-warning.svg" />
							<p>
								{{ tt('잘못된 비밀번호입니다.') }}
							</p>
						</span>
					</div>
					<div class="Popup__input-field">
						<p>{{ tt('생년월일/사업자등록번호') }}<span class="required">*</span></p>
						<input
							type="text"
							:placeholder="tt('생년월일(6자리) 혹은 사업자 등록번호(10자리)를 입력해주세요')"
							maxlength="12"
							@input="inputRegistrationNumber"
							:value="form.registrationNumber"
							:class="{ error: registrationNoError }"
						/>
						<span class="error-text" v-if="registrationNoError">
							<img src="@/assets/svg/input-icon-warning.svg" />
							<p>
								{{ tt('잘못된 생년월일 또는 사업자등록번호입니다.') }}
							</p>
						</span>
					</div>
					<span
						v-html="
							tt(
								'개인카드 혹은 기명 법인카드인 경우 생년월일 6자리를 입력해주세요.<br>무기명 법인카드인 경우 사업자등록번호 10자리를 입력해주세요.'
							)
						"
					></span>
					<div class="Popup__input-field">
						<p>{{ tt('연락처') }}<span class="required">*</span></p>
						<input
							type="text"
							:placeholder="tt('연락처를 입력해주세요')"
							maxlength="13"
							:value="form.phone"
							@input="autoHypenTel"
							:class="{ error: phoneNoError }"
						/>
						<span class="error-text" v-if="phoneNoError">
							<img src="@/assets/svg/input-icon-warning.svg" />
							<p>
								{{ tt('연락처를 형식에 맞게 입력해주세요') }}
							</p>
						</span>
					</div>
					<div class="Popup__input-field">
						<p>{{ tt('이메일') }}<span class="required">*</span></p>
						<input type="email" :placeholder="tt('이메일 입력해주세요')" v-model="form.email" :class="{ error: emailNoError }" />
						<span class="error-text" v-if="emailNoError">
							<img src="@/assets/svg/input-icon-warning.svg" />
							<p>
								{{ tt('이메일을 형식에 맞게 입력해주세요') }}
							</p>
						</span>
					</div>

					<button v-if="isChangeMode && !isPaymentCardError" class="Btn__yellow" :disabled="!isFormValid" @click="changeOrRegistCard">
						{{ tt('저장하기') }}
					</button>
					<button v-else-if="isPaymentCardError" class="Btn__yellow" :disabled="!isFormValid" @click="changeOrRegistCard">
						{{ tt('등록하고 다음단계로') }}
					</button>

					<button v-else-if="onlyRegist" class="Btn__yellow" :disabled="!isFormValid" @click="registCard">
						{{ tt('등록하기') }}
					</button>
					<button v-else class="Btn__yellow" :disabled="!isFormValid" @click="registCardAndPurchase">
						{{ tt('등록 및 결제하기') }}
					</button>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
import CardNumberKeyboard from '@/views/institute/information/modals/CardNumberKeyboard.vue'
import { ToastType } from '@/utils/define/ToastType'
import Validation from '@/utils/Validation'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'ModalRegistCard',
	mixins: [MixinLicense, MixinPayment],
	components: { CardNumberKeyboard },
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: String,
			default: 'REGIST',
		},
		onlyRegist: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			cardNumber: '',
			expirationMonthYear: '',
			cardInfo: {
				number: null,
				period: null,
				password: null,
				birth: null,
			},
			cardNumberMaxLength: 19,
			formNumberMaxLength: 16,
			form: {
				number: '',
				password: '',
				expirationMonthYear: '',
				registrationNumber: '',
				isDefault: true,
				phone: '',
				email: '',
			},
			cardInputButtonNumbers: [],
			displayCardInput: false,
			cardNoError: false,
			passwordError: false,
			expirationError: false,
			registrationNoError: false,
			phoneNoError: false,
			emailNoError: false,
		}
	},
	created() {
		this.$root.cardInput = false
		this.cardInputButtonNumbers = this.shuffledNumberArray()
	},
	mounted() {},
	watch: {
		isShow: {
			async handler(value) {
				if (value) {
					this.form = {
						number: '',
						// 이니시스에서 필수값이 아님(22.02.22 현우님이랑 확인)
						password: '',
						expirationMonthYear: '',
						registrationNumber: '',
						isDefault: true,
						phone: '',
						email: '',
					}
					this.cardNumber = ''
					this.expirationMonthYear = ''
					// 에러 초기화
					this.cardNoError = false
					this.passwordError = false
					this.expirationError = false
					this.registrationNoError = false
					this.phoneNoError = false
					this.emailNoError = false
				}

				if (this.user?.phone) {
					this.form.phone = Validation.autoHypenTel(this.user.phone)
				}
				if (this.user?.email) this.form.email = this.user.email
			},
		},
		'form.number': {
			deep: true,
			handler(newValue) {
				this.cardNumber = this.addCardDash(newValue, true)
			},
		},
		expirationMonthYear: {
			handler(newValue) {
				this.form.expirationMonthYear = this.addYearMonthDelimeter(newValue)
			},
		},
	},
	computed: {
		isFormValid() {
			const isCardNoValid = this.form.number.length === this.formNumberMaxLength
			const isExpirationValid = this.form.expirationMonthYear.length === 5
			const isPasswordValid = this.form.password.length === 2
			const isRegistrationNumberValid = this.form.registrationNumber.length === 6 || this.form.registrationNumber.length === 12
			const isPhoneValid = this.form.phone.length > 0
			const isEmailValid = this.form.email.length > 0

			return isCardNoValid && isExpirationValid && isPasswordValid && isRegistrationNumberValid && isPhoneValid && isEmailValid
		},
		isChangeMode() {
			return this.mode === 'CHANGE'
		},
		...mapState('user', ['user']),
	},
	methods: {
		close() {
			this.$emit('close')
		},
		initError() {
			this.cardNoError = false
			this.passwordError = false
			this.expirationError = false
			this.registrationNoError = false
			this.phoneNoError = false
		},
		checkRegistCardError(msg) {
			const errorCode = msg.replace(/[^0-9]/g, '')
			this.initError()
			switch (errorCode) {
				case '1214': // 카드번호 오류
					this.cardNoError = true
					break
				case '1219': // 비밀번호 상이
					this.passwordError = true
					break
				case '1223': //유효기간 오류
					this.expirationError = true
					break
				case '1251': //사업자번호 상이
					this.registrationNoError = true
					break
			}

			this.$root.toast(this.tt('결제카드 등록 실패'), msg, 'error')
		},
		async registCard() {
			if (!this.validPhone()) {
				this.phoneNoError = true
				return
			}
			if (!this.validEmail()) {
				this.emailNoError = true
				return
			}
			let response = null
			try {
				response = await this.registDefaultCreditCard(this.form)
			} catch (error) {
				if (error) {
					const { msg } = error.response.data
					this.checkRegistCardError(msg)
					return
				}
			}
			await this.loadLicense()
			await this.loadLicenseHistory()
			await this.loadDefaultCreditCard()
			this.$emit('close')
			this.$emit('showManageSubscribe')
		},
		async registCardAndPurchase() {
			if (!this.validPhone()) {
				this.phoneNoError = true
				return
			}
			if (!this.validEmail()) {
				this.emailNoError = true
				return
			}
			if (this.mode === 'REGIST') {
				//카드 등록
				let response = null
				try {
					response = await this.registDefaultCreditCard(this.form)
				} catch (error) {
					const { msg } = error.response.data
					this.checkRegistCardError(msg)
					return
				}
				const cardId = response.data.id
				// 카드 아이디 받아서 세팅
				this.setCardId(cardId)

				// 카드로 베이직 구매
				try {
					let purchaseResponse = await this.purchaseLicenseBasic({
						email: this.form.email,
					})
				} catch (error) {
					const { msg } = error.response.data
					this.checkRegistCardError(msg)
					return
				}

				// 라이선스 데이터 갱신
				await this.loadLicense()
				await this.loadLicenseHistory()

				// 정기 결제 설정
				await this.subscriptBasicLicense({
					quantity: this.licensePurchase.quantity,
					month: this.licensePurchase.month,
					cardId: cardId,
				})
				this.showSuccessToast()
				// 완료 모달 켜기
				this.$emit('close')
				this.$emit('complete')
			}
		},
		async changeOrRegistCard() {
			if (!this.validPhone()) {
				this.phoneNoError = true
				return
			}
			// 카드 재등록 후

			let response = null
			try {
				response = await this.registDefaultCreditCard(this.form)
			} catch (error) {
				const { msg } = error.response.data
				this.checkRegistCardError(msg)
				return
			}
			this.showSuccessChangeCardToast()
			const cardId = response.data.id

			// 정기 결제 편집
			if (this.beingSubscription) {
				if (this.isPaymentCardError) {
					// 카드 에러가 있는 경우

					await this.loadLicense()
					await this.loadDefaultCreditCard()
					if (this.isLicenseActivate) {
						// 활성화일때(ACTIVATE)
						this.$emit('showManageSubscribe')
					} else {
						// 만료일때(EXPIRED)
						this.$emit('showModalPurchase')
					}
					this.$emit('close')
					return
				} else {
					await this.updateLicenseSubscription({
						quantity: this.licensePurchase.quantity,
						month: this.licensePurchase.month,
						cardId: cardId,
					})
				}
			}
			await this.loadLicense()
			await this.loadDefaultCreditCard()

			this.$emit('close')
		},
		showSuccessChangeCardToast() {
			const title = this.tt('결제카드 변경 완료')
			const content = this.tt('결제카드가 변경되었습니다.')
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
		},
		autoDashCreditCard(event) {
			let cardNum = event.target.value
			const isNotNumber = event.data < '0' && event.data > '9'
			if (isNotNumber) {
				event.target.value = cardNum.strsub(0, cardNum - 2)
			}
			// const checkNumberRegex = /(\d|-)+/
			let removeDashCardNum = cardNum.replace(/[^0-9]/g, '')
			let _cardNum = this.addCardDash(removeDashCardNum, true)

			this.form.number = _cardNum
		},

		inputExpirationMonthYear(event) {
			this.expirationMonthYear = event.target.value.replace(/[^0-9]/g, '')
			const isNumber = event.data >= '0' && event.data <= '9'
			if (isNumber) {
				event.target.value = this.addYearMonthDelimeter(this.expirationMonthYear)
			} else {
				const length = this.form.expirationMonthYear.length
				event.target.value = this.expirationMonthYear.substr(0, length)
			}
		},
		addYearMonthDelimeter(yearMonth) {
			if (yearMonth.length > 2) {
				return [yearMonth.substr(0, 2), yearMonth.substr(2, 2)].join('/')
			} else {
				return yearMonth
			}
		},
		replaceAstrisk(str) {
			let result = ''
			for (let i = 0, length = str.length; i < length; i++) {
				result += '*'
			}
			return result
		},
		addCardDash(cardNum, hide = false) {
			var amexPrefixRegex = /^3[47]/
			var dinersPrefixRegex = /^3(?:0[0-5]|[689][0-9])|^3095/

			var isAmexcard = amexPrefixRegex.test(cardNum)
			var isDinerscard = dinersPrefixRegex.test(cardNum)
			if (isAmexcard) {
				this.cardNumberMaxLength = 17
				this.formNumberMaxLength = 15
			} else if (isDinerscard) {
				this.cardNumberMaxLength = 16
				this.formNumberMaxLength = 14
			} else {
				this.cardNumberMaxLength = 19
				this.formNumberMaxLength = 16
			}
			let cardNumLength = cardNum.length
			let cardNumber = []
			let delimeter = '-'

			if (isAmexcard) {
				// 15자리
				if (cardNumLength <= 4) {
					return cardNum
				} else if (cardNumLength > 4 && cardNumLength <= 10) {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 6)) : cardNum.substr(4, 6))
					return cardNumber.join(delimeter)
				} else {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 6)) : cardNum.substr(4, 6))
					cardNumber.push(cardNum.substr(10, 5))
					return cardNumber.join(delimeter)
				}
			} else if (isDinerscard) {
				// 14자리
				if (cardNumLength <= 4) {
					return cardNum
				} else if (cardNumLength > 4 && cardNumLength <= 10) {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 6)) : cardNum.substr(4, 6))
					return cardNumber.join(delimeter)
				} else {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 6)) : cardNum.substr(4, 6))
					cardNumber.push(cardNum.substr(10, 4))
					return cardNumber.join(delimeter)
				}
			} else {
				// 16자리
				if (cardNumLength <= 4) {
					return cardNum
				} else if (cardNumLength > 4 && cardNumLength <= 8) {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 4)) : cardNum.substr(4, 4))
					return cardNumber.join(delimeter)
				} else if (cardNumLength > 8 && cardNumLength <= 12) {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 4)) : cardNum.substr(4, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(8, 4)) : cardNum.substr(8, 4))
					return cardNumber.join(delimeter)
				} else {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 4)) : cardNum.substr(4, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(8, 4)) : cardNum.substr(8, 4))
					cardNumber.push(cardNum.substr(12, 4))
					return cardNumber.join(delimeter)
				}
			}
		},
		addNumber(number) {
			if (this.formNumberMaxLength > this.form.number.length) {
				this.form.number += number
			}
		},
		resetInputNumber() {
			this.cardInputButtonNumbers = this.$_.shuffle([...Array.from(Array(10).keys()).concat(['', ''])])
		},
		removeInputNumber() {
			const numberLength = this.form.number.length
			this.form.number = this.form.number.slice(0, numberLength - 1)
		},
		focusInputCard() {
			this.$refs['input-cardnum'].focus()
		},
		shuffledNumberArray() {
			return this.$_.shuffle([...Array.from(Array(10).keys()).concat(['', ''])])
		},
		inputPassword(event) {
			const isNumber = event.data >= '0' && event.data <= '9'
			if (isNumber) {
				this.form.password = event.target.value
			} else {
				event.target.value = this.form.password
			}
		},
		inputRegistrationNumber(event) {
			const isNumber = event.data >= '0' && event.data <= '9'
			const value = event.target.value.replace(/[^0-9]/g, '')
			const isDelete = event.inputType === 'deleteContentBackward'
			if (isNumber || isDelete) {
				if (value.length <= 6) {
					event.target.value = value
					this.form.registrationNumber = event.target.value.replace(/[^0-9]/g, '')

					console.warn(event.target.value)
				} else {
					const traderNumber = this.autoRegistrationNumber(value)
					event.target.value = traderNumber
					this.form.registrationNumber = traderNumber
				}
			} else {
				event.target.value = value.slice(0, value.length - 2)
			}
		},
		autoRegistrationNumber(companyNum) {
			// ref: https://life-with-coding.tistory.com/46 [나다움]
			companyNum = companyNum.replace(/[^0-9]/g, '')
			let tempNum = ''
			if (companyNum.length < 4) {
				return companyNum
			} else if (companyNum.length < 6) {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				return tempNum
			} else if (companyNum.length < 11) {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				tempNum += '-'
				tempNum += companyNum.substr(5)
				return tempNum
			} else {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				tempNum += '-'
				tempNum += companyNum.substr(5)
				return tempNum
			}
		},
		autoHypenTel(e) {
			const phoneNumber = Validation.autoHypenTel(e.target.value)
			this.setPhone(phoneNumber)
		},
		setPhone(phoneNumber) {
			this.form.phone = phoneNumber
		},
		validPhone() {
			let result = true
			if (this.form.phone?.length >= 1) {
				if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.form.phone)) {
					result = false
				}
			}
			return result
		},
		validEmail() {
			// ref: https://www.w3resource.com/javascript/form/email-validation.php
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)) {
				return true
			}
			return false
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__notice {
	display: flex;
	margin: 0px 20px 20px;
	.notice-text {
		width: 100%;
		padding: 10px;
		background: $GREY_0;
		border: 1px solid $DIVIDER;
		box-sizing: border-box;
		border-radius: 5px;
		font-size: 14px;
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
	}
}
.on-input {
	border: 1px solid black !important;
}
input {
	&.error {
		border: solid 1px #ff3b31 !important;
	}
}

.error-text {
	display: flex !important;
	align-items: center;
	img {
		margin-top: 1px;
		margin-right: 6px;
	}
	p {
		padding: 0px !important;
		color: #ff3b31 !important;
	}
}

.ModalRegistCard {
	color: #000;
	.Popup__content {
		h5 {
			font-size: 0.875rem;
			font-weight: 700;
			margin-bottom: -10px;
		}
		.Popup__input-field {
			margin-top: 20px;
		}
		> span {
			font-size: 10px;
			font-weight: 400;
			display: block;
			color: $PLACEHOLDER;
			margin-top: 8px;
		}
		.Btn__yellow {
			width: 100%;
			height: 48px;
			margin-top: 20px;
			margin-bottom: 10px;
			font-size: 0.875rem;
			font-weight: 700;
		}
	}
}
</style>
