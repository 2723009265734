var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('receive-invite-header'), _c('div', {
    staticClass: "content-slide-wrapper"
  }, [_c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h3', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.title))
    }
  }), _c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.content))
    }
  }), _c('div', {
    staticClass: "sub-text",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.subText))
    }
  })])]), _c('div', {
    staticClass: "institute-item-wrapper"
  }, [_c('invited-institute-item', {
    attrs: {
      "title": _vm.invitationInstitute.title,
      "subTitle": _vm.invitationInstitute.subTitle,
      "logo": _vm.invitationInstitute.logo,
      "info": _vm.inviteInfo,
      "isExpired": _vm.isExpired,
      "isOverCountLicense": _vm.isOverCountLicense
    },
    on: {
      "click": _vm.onClickJoinInstitute
    }
  }), _vm.isLoginUser && !_vm.isHideCreateComponent ? _c('invited-institute-item', {
    attrs: {
      "title": _vm.anotherInstitute.title,
      "subTitle": _vm.anotherInstitute.subTitle,
      "logo": _vm.anotherInstitute.logo
    },
    on: {
      "click": _vm.onClickCreateInstitute
    }
  }) : _vm._e()], 1), !_vm.isLoginUser ? _c('div', {
    staticClass: "invite-footer"
  }, [_vm._v(" 랩매니저 PRO는 "), _c('span', [_vm._v("재고관리")]), _vm._v(" 및 "), _c('span', [_vm._v("안전관리")]), _vm._v("에 특화된 연구실 전용 솔루션 입니다. ")]) : _vm._e()]), _c('modal-common-confirm', {
    attrs: {
      "isShow": _vm.isShowModal,
      "title": _vm.confirmModalTitle,
      "content": _vm.confirmModalContent,
      "buttonText": _vm.confirmModalButtonText,
      "cancelText": _vm.confirmModalCancelButton,
      "hideConfirm": _vm.isHideModalConfirmBtn
    },
    on: {
      "confirm": _vm.onClickConfirmModal,
      "close": function close($event) {
        _vm.isShowModal = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }