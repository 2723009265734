import _ from 'lodash'
import list from './list'
import item from './item'
import form from './form'

export default {
	namespaced: true,
	modules: {
		list,
		item,
		form,
	},
}
