<template>
	<div class="detail-page-wrapper" v-if="item && item.draftPurchaseItemId == $route.params.id">
		<!-- header -->
		<detail-header
			:title="item.purchaseItems[0].name"
			:subtitle="subtitle"
			:status="item.store.paymentStatus"
			:orderId="item.orderId"
			@click="showConfirmModal"
			:canPay="isCanPay"
			:item="item"
		></detail-header>
		<div class="detail-content-wrapper">
			<div>
				<store-order-info v-if="!item.isDirect" ref="store-order-info" type="DRAFT" />
				<store-order-info v-else ref="store-order-info" content="판매자가 주문을 승인하면, 후불결제를 진행할 수 있습니다." type="ORDER" />
			</div>
			<div>
				<h5>{{ tt('주문 정보') }}</h5>
				<info-table :items="orderInfo"></info-table>
				<p class="order-store-description">{{ tt('취소 / 교환 / 반품 접수는 랩매니저 스토어에서 이용가능합니다') }}</p>
			</div>
			<div>
				<h5>{{ tt('결제 정보') }}</h5>
				<info-table :items="paymentInfo"></info-table>
			</div>
			<div v-if="!item.isDirect">
				<h5>{{ tt('결재 정보') }}</h5>
				<div class="approval-info-wrapper">
					<approval-info-item
						v-if="item.register"
						:approvalTitle="'기안'"
						:name="item.register.name"
						:affiliation="item.group.name"
						:dateTime="item.createDatetime ? formatDatetime(item.createDatetime) : '-'"
					></approval-info-item>
					<div>
						<approval-info-item
							v-for="(approval, index) in item.approvals"
							:key="`approval_${index}`"
							:approvalTitle="'결재'"
							:approvalState="approval.status"
							:name="approval.user.name"
							:affiliation="approval.group.name"
							:dateTime="approval.concludeDatetime ? formatDatetime(approval.concludeDatetime) : '-'"
						></approval-info-item>
					</div>
				</div>
			</div>
			<div v-if="!item.isDirect">
				<h5>{{ tt('기안 정보') }}</h5>
				<info-table :items="draftInfoForTable"></info-table>
			</div>
			<div v-if="item.budgetStatus">
				<total-amount :title="'예산 현황'" :info="amountForm" :budget="item.budgetStatus"></total-amount>
			</div>
			<div>
				<h5>{{ tt('상품 정보') }}</h5>
				<product-info-table :productInfoList="item.purchaseItems"></product-info-table>
				<p class="purchase-description">
					{{ tt('추후 정산 시, 선택하는 상품항목에 따라 최종 결제금액이 지출 예정금액 합계와 상이할 수 있습니다.') }}
				</p>
			</div>
			<div v-if="attachmentsForTable[0].files">
				<div class="file-wrap">
					<h5>{{ tt('첨부 파일') }}</h5>
					<image-button
						v-if="attachmentsForTable[0].files && attachmentsForTable[0].files.length > 0"
						:text="'모두 다운로드'"
						:image="require('@/assets/svg/icon-file-download.svg')"
						@click="$refs['files-table'].downloadAll()"
					></image-button>
				</div>
				<info-table ref="files-table" :items="attachmentsForTable"></info-table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MixinExpenditureDetail from '@/mixins/purchase/expenditureDetail'
import DetailHeader from '../components/payDetail/header.vue'
import ApprovalInfoItem from '@/views/purchase/draft/components/detail/ApprovalInfoItem.vue'
import InfoTable from '@/views/purchase/draft/components/detail/InfoTable.vue'
import ProductInfoTable from '@/views/purchase/draft/components/detail/ProductInfoTable.vue'
import TotalAmount from '@/views/purchase/draft/components/detail/TotalAmount.vue'
import ModalConfirm from '@/views/common/components/modalGen2/ModalConfirm.vue'
import StoreOrderInfo from '@/views/purchase/draft/components/regist/StoreOrderInfo.vue'

export default {
	name: 'ProductPayDetail',
	mixins: [MixinExpenditureDetail],
	components: {
		DetailHeader,
		ApprovalInfoItem,
		InfoTable,
		ProductInfoTable,
		TotalAmount,
		ModalConfirm,
		StoreOrderInfo,
	},
	computed: {
		...mapState('purchaseProduct/item', ['item']),
		subtitle() {
			const name = this.item.budget?.name ? this.item.budget?.name + ' ' : ''
			const no = !this.item.isDirect ? this.item.no : ''
			return name + no
		},
		isCanPay() {
			return this.item.store.cancelStatus == null && this.item.store.status != 'WAIT' && this.item.store.paymentStatus == null
		},
	},
	methods: {
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		...mapActions('purchaseProduct/item', ['getDetail']),
		async loadDetail() {
			await this.getDetail(this.$route.params.id)
			this.setStorageDetailPaths([this.item.purchaseItems[0].name])
		},
		showConfirmModal(params) {
			if (this.$root.userPermission.draft?.update) {
				if (params == 'STORE_ORDER') {
					this.gotoStoreOrderDetail()
				} else if (params == 'STORE_PAYMENT') {
					this.gotoStorePayment()
				}
			} else this.$root.toast('권한', `${title} 권한이 없습니다.`, 'error')
		},
		gotoStoreOrderDetail() {
			window.open(`${storeUrl()}/mypage/order/${this.item.orderId}?isPro=true&instituteId=${this.lmsInstituteId}`)
		},
		gotoStorePayment() {
			const { status } = this.item.store
			if (status != 'WAIT' && this.item.store.paymentStatus == null && this.item.store.cancelStatus == null) {
				this.$root.goToStore(`mypage/unpayment/pay`, { orderItemIds: JSON.stringify([this.item.store.orderItemId]) })
			} else {
				this.$root.toast('결제', `재고확인 이후에 결제가 가능합니다`, 'error')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/purchase/expenditure/detail/_expenditure-detail.scss';
.check-accept {
	font-size: 14px;
	input {
		margin-right: 10px;
	}
}
.order-store-description {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: $DEFAULT;
	&::before {
		content: '';
		margin-right: 8px;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: $DEFAULT;
	}
}
.purchase-description {
	margin-top: 15px;
	font-size: 16px;
	line-height: 23px;
	text-align: center;
	color: $SECONDARY_2;
}
</style>
