var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    on: {
      "click": function click($event) {
        return _vm.detail({
          id: 'ALL',
          name: _vm.item.name
        });
      }
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.item.name))])]), _vm._l(_vm.item.legalByItemsCount, function (legalCount) {
    return _c('td', {
      key: legalCount.id,
      on: {
        "click": function click($event) {
          return _vm.detail(legalCount);
        }
      }
    }, [_c('p', [_vm._v(_vm._s(legalCount.kindCount) + _vm._s(_vm.tt('종')) + " (" + _vm._s(legalCount.count) + _vm._s(_vm.tt('개')) + ")")])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }