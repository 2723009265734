<template>
	<div class="header-wrapper">
		<h2 class="header-title">{{ title }}</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<!-- search box -->
				<search-box class="search-box" :placeholder="tt('예산 번호, 이름, 참여그룹으로 검색')" @change="changeKeyword"> </search-box>
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
					:image="require('@/assets/svg/filter.svg')"
					@click="filterBtnClick"
				></image-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button :text="'보기옵션'" :image="require('@/assets/svg/ViewOption(Button).svg')" @click="watchBtnClick"></image-button>
				<excel-download-button @downloadAll="exportList('ALL')" @downloadFiltered="exportList('FILTERED')" />
				<image-button :text="'예산 등록'" :image="require('@/assets/svg/icon/black-round-plus.svg')" @click="registBtnClick"></image-button>
			</div>
		</div>
		<div class="status-btn-wrapper">
			<list-filter-tab
				v-for="status in yearStatus"
				:isActive="status === selectedYear"
				:text="tt('${1}년', status)"
				:key="status"
				@click="changeActiveStatus(status)"
			></list-filter-tab>
		</div>
		<modal-filter ref="modal-filter" @changeLoadParams="changeLoadParams" />
		<modal-view-option ref="modal-view-option" />
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import ListFilterTab from '@/views/common/components/buttons/ListFilterButton.vue'
import SearchBox from '@/views/components/searchBox'
import ModalFilter from './ModalFilter.vue'
import ModalViewOption from './ModalViewOption.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ExcelDownloadButton from '@/views/common/components/baseButton/ExcelDownloadButton.vue'

export default {
	components: {
		SearchBox,
		ListFilterTab,
		ModalFilter,
		ModalViewOption,
		ExcelDownloadButton,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters('budget/list', ['yearStatus', 'selectedYear', 'filteredCount']),
		currentYear() {
			return this.$DateTime.now().year
		},
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
	},
	async created() {
		await this.getBudgetYears()
	},

	methods: {
		...mapActions('budget/list', ['exportExcel', 'getBudgetYears']),
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		datePickerBtnClick() {
			console.log('데이트피커')
		},
		watchBtnClick() {
			this.$refs['modal-view-option'].show()
		},
		downloadBtnClick() {
			console.log('목록다운로드')
		},
		registBtnClick() {
			if (this.$root.userPermission.budget?.create) this.$router.push({ name: PageName.Budget.Create })
			else this.$root.toast('권한', '예산 등록 권한이 없습니다.', 'error')
		},
		changeActiveStatus(status) {
			this.currentStatus = status
			this.changeLoadParams({ year: status })
		},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword })
		},
		changeLoadParams(params) {
			this.$emit('changeLoadParams', params)
		},
		async exportList(type) {
			const response = await this.exportExcel(type)
			window.fileDownload(response, `${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('${1}년 예산 목록', this.selectedYear)}.xlsx`)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/list/_list-header.scss';
</style>
