var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": _vm.portalTo
    }
  }, [_c('div', {
    staticClass: "AddAuthorityMemberPopup Popup__depth3"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('button', {
    staticClass: "Popup__close-btn",
    attrs: {
      "type": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg")
    },
    on: {
      "click": _vm.hide
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('lm-input-search', {
    staticClass: "block",
    attrs: {
      "placeholder": _vm.tt(_vm.searchPlaceholder)
    },
    on: {
      "search": _vm.search
    }
  }), _c('div', {
    staticClass: "Organization__member-list"
  }, [_c('ul', {
    staticClass: "horizational-list"
  }, [_vm.treeData ? _c('tree-view', {
    ref: "tree-view",
    attrs: {
      "treeData": _vm.selectTreeData,
      "textField": "name",
      "draggable": false,
      "selectWithChild": false,
      "mode": _vm.mode,
      "modal": "location",
      "keyword": _vm.keyword
    },
    on: {
      "changeActive": _vm.getActiveId,
      "load": _vm.loadedTree
    }
  }) : _vm._e()], 1)])], 1), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('button', {
    staticClass: "Btn__management",
    on: {
      "click": _vm.goPage
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('보관함 관리')) + " ")]), _c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }