<template>
	<div class="form-input" :class="{ error: !isValid }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper">
			<input
				class="qr"
				:class="{ full: isRegistFull }"
				type="text"
				readonly
				:placeholder="tt('등록 안 됨')"
				:value="tt(statusText)"
				@click.stop="showQrModal"
			/>
			<div class="reset" v-if="statusText" @click="init">{{ tt('리셋') }}</div>
			<button class="qr-button" @click="showQrModal">{{ tt('스캔') }}</button>
		</div>
		<modal-scan-qr-sticker ref="modal-scan-qr" :maxSize="maxSize" @save="handleSave"></modal-scan-qr-sticker>
	</div>
</template>
<script>
import ModalTreeview from '../modals/ModalTreeview.vue'
import ModalSingleSelectStorage from '@/views/common/components/modalGen2/ModalSingleSelectStorage.vue'
import ModalScanQrSticker from '@/views/common/components/modalGen2/ModalScanQrSticker.vue'
import { mapMutations } from 'vuex'

export default {
	name: 'FormQr',
	components: { ModalTreeview, ModalSingleSelectStorage, ModalScanQrSticker },
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: Array,
			default: null,
		},
		maxSize: {
			type: Number,
			default: 0,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			qrList: [],
		}
	},
	computed: {
		statusText() {
			return this.qrList?.length > 0 ? `등록됨(${this.qrList?.length}/${this.maxSize})` : ''
		},
		isRegistFull() {
			return this.qrList?.length === this.maxSize
		},
		settingValue() {
			return this.value?.map((qr, i) => {
				return {
					code: qr,
					id: i,
					status: 'OK',
				}
			})
		},
	},
	watch: {
		value: {
			deep: true,
			handler(value) {
				this.qrList = this.settingValue
			},
		},
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.qrList = this.value
		})
	},
	methods: {
		...mapMutations('form/qrcode', ['setQrcodeList', 'setMaxSize', 'setInit', 'setSelectedList']),
		async showQrModal() {
			await this.setInit()
			this.$refs['modal-scan-qr'].show()
			this.setQrcodeList(this.settingValue)
			this.setMaxSize(this.maxSize)
			this.setSelectedList(this.settingValue)
		},
		handleSave(qrcodeList) {
			this.qrList = qrcodeList
			this.$refs['modal-scan-qr'].hide()
			this.$emit('input', this.qrList)
		},
		init() {
			this.qrList = []
			this.$emit('input', [])
			this.setInit()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-qr.scss';
</style>
