var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('물품관리 설정')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }), _c('div', {
          staticClass: "footer-right"
        }, [_c('base-button', {
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(_vm._s(_vm.tt('닫기')))])], 1)])];
      },
      proxy: true
    }])
  }, [[_c('management-tab', {
    attrs: {
      "tabList": _vm.tabList,
      "selectTab": _vm.selectTab
    },
    on: {
      "change": _vm.changeTab
    }
  }), _c('div', {
    staticClass: "management-wrap"
  }, [_c(_vm.pageName, {
    tag: "component"
  })], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }