<template>
	<tr class="table-item" :class="{ new: isNew }" @click="goToDetail">
		<td @click.stop>
			<span class="checkbox"><input type="checkbox" v-model="checked" /> </span>
		</td>
		<td>
			<div>
				<span style="display: flex; gap: 10px">
					<img :src="status.image" />
					<p class="status" :class="item.status">
						{{ tt(status.text) }}
					</p>
				</span>
			</div>
		</td>
		<td style="min-width: 250px">
			<div>
				<span>{{ categoryKor }}</span>
			</div>
		</td>
		<td style="min-width: 800px">
			<div class="imgbox" v-if="item.images">
				<img v-for="(image, index) in item.images.slice(0, 3)" :key="`image-${index}`" :src="image" />
				<div v-if="item.images.length > 3">
					<p>{{ `외 ${item.images.length - 3}` }}</p>
				</div>
			</div>
		</td>
		<td style="min-width: 200px">
			<div>
				<span>{{ isEmpty(item.register.name) }}</span>
			</div>
		</td>
		<td style="min-width: 200px">
			<div>
				<span>{{ createDatetime }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
const STATUS = {
	ING: {
		image: require('@/views/inventoryGen2/svg/Usable.svg'),
		text: '분석중',
	},
	FAIL: {
		image: require('@/views/inventoryGen2/svg/Unusable.svg'),
		text: '분석불가',
	},
}
import { mapState, mapMutations } from 'vuex'
import MixinItem from '@/mixins/inventory/MixinItem'
import { LargeClassToString, MiddleClassToString } from '@/utils/define/ItemCode'
import { DateTime } from 'luxon'
import PageName from '@/utils/define/PageName'
export default {
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('inventoryGen2/wait', ['selectedItems', 'checkedViewOption']),
		status() {
			return STATUS[this.item.status]
		},
		categoryKor() {
			// if (!this.item.category?.type) return ''
			// return this.tt(MiddleClassToString(this.item.category.type))
			return this.item.category.name
		},
		checked: {
			get() {
				return this.selectedItems.some(item => item.id === this.item.id)
			},
			set(value) {
				if (value) {
					this.setSelectedItems(this.selectedItems.concat(this.item))
				} else {
					this.setSelectedItems(this.selectedItems.filter(item => item.id !== this.item.id))
				}
			},
		},
	},
	methods: {
		...mapMutations('inventoryGen2/wait', ['setSelectedItems']),
		goToDetail() {
			this.setNew()
			this.$router.push({
				name: PageName.InventoryGen2.WaitDetail,
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
.status {
	font-weight: 700;
	color: $GREEN;
	&.FAIL {
		color: $RED;
	}
}
.imgbox {
	display: flex;
	gap: 10px;
	img {
		object-fit: cover;
		width: 36px;
		height: 36px;
		border-radius: 6px;
	}
	div {
		width: 36px;
		height: 36px;
		background: $DIVIDER;
		border-radius: 6px;
		p {
			color: $SECONDARY_2;
			font-size: 14px;
			text-align: center;
			transform: translate(0, 35%);
		}
	}
}
</style>
