<template>
	<div class="tree-node-wrap" :class="{ root: node.parentId == null }">
		<div class="tree-node">
			<div
				class="info-wrap"
				:class="{ checked: notChecked && selectedGroups.find(g => g.id == node.id), root: node.parentId == null }"
				@click.stop="selectGroup(node)"
			>
				<div v-if="isSubtree">
					<img v-if="isTreeOpen" class="tree-close" src="../svg/icon-node-close.svg" width="16" height="24" @click.stop="toggleTreeOpen" />
					<img v-else class="tree-open" src="../svg/icon-node-open.svg" width="16" height="24" @click.stop="toggleTreeOpen" />
				</div>
				<img v-if="notChecked && selectedGroups.find(g => g.id == node.id)" class="tree-icon" :src="imageSrc" width="20" height="20" />
				<img v-else class="tree-icon" :src="imageSrc" style="filter: brightness(0)" width="20" height="20" />
				<div class="name" v-b-tooltip.hover="node.name" v-html="name"></div>
			</div>
		</div>
		<div
			class="radio"
			v-if="!notChecked && isSelectSingle && (!isInclude || !node.members || node.members.findIndex(it => it.id == $root.user.id) >= 0)"
		>
			<input type="radio" :checked="selectedGroups.find(g => g.id == node.id)" @change="selectGroup(node)" />
			<span></span>
		</div>
		<div class="checkbox" v-else-if="!notChecked && !isSelectSingle">
			<input type="checkbox" :checked="selectedGroups.find(g => g.id == node.id)" @change="selectGroup(node)" />
		</div>
		<div v-if="isTreeOpen" class="node-children">
			<tree-node
				:isInclude="isInclude"
				ref="child-node"
				:isSelectSingle="isSelectSingle"
				:notChecked="notChecked"
				v-for="group in node.children"
				:key="group.id"
				:node="group"
			></tree-node>
		</div>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import MixinTree from '@/mixins/tree'
export default {
	name: 'TreeNode',
	components: {},
	props: {
		node: {
			type: [Object, null],
			default: null,
		},
		isSelectSingle: {
			type: Boolean,
			default: false,
		},
		isInclude: {
			type: Boolean,
			default: true,
		},
		notChecked: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [MixinTree],
	computed: {
		...mapState('institute/group', ['selectedGroups', 'keyword']),
		imageSrc() {
			return this.node.parentId != null ? require('@/assets/svg/group_y.svg') : require('@/assets/svg/institution-building_y.svg')
		},
	},
	methods: {
		...mapMutations('institute/group', ['setSelectGroup', 'setSelectSingleGroup']),
		selectGroup(group) {
			if (
				!this.notChecked &&
				this.isSelectSingle &&
				(!this.isInclude || !this.node.members || this.node.members.findIndex(it => it.id == this.$root.user.id) >= 0)
			)
				this.setSelectSingleGroup(group)
			else if (this.notChecked) this.setSelectSingleGroup(group)
			else if (!this.isSelectSingle) this.setSelectGroup(group)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../scss/_tree-node.scss';
</style>
