<template>
	<div class="search-box-wrapper" v-click-outside="focusOut">
		<b-dropdown variant="transparent" menu-class="option-menu" no-caret>
			<template v-slot:button-content>
				<div class="type">
					<span>{{ typeText[currentType] }}</span>
					<img src="./assets/dropdown.svg" />
				</div>
			</template>
			<b-dropdown-item v-for="(value, key) in typeText" :key="key" @click="currentType = key">{{ value }}</b-dropdown-item>
		</b-dropdown>
		<input
			ref="keyword"
			class="searchbox-keyword"
			v-model="text"
			:placeholder="tt(placeholder)"
			@keypress.enter="onDataChanged"
			@input="handleInput"
			@focus="isFocus = true"
		/>
		<div class="icon-wrapper">
			<img class="delete" v-if="text.length > 0" @click="deleteValue" src="./assets/delete.svg" />
			<img class="qr" v-if="hasQR && text.length === 0" src="./assets/search-qr.svg" @click="clickQR" />
			<div class="search" @click="onDataChanged">
				<img src="./assets/search-white.svg" />
			</div>
		</div>
		<div class="keyword-wrapper" v-show="isKeywordWrapperOpen">
			<recent ref="recent" @clickRecent="clickRecent" />
		</div>
	</div>
</template>
<script>
import Recent from './recent'
const TYPE_TEXT = {
	ALL: '통합검색',
	NAME: '물품명',
	CASNO: 'CAS No',
	CATNO: '제품번호',
	BRAND: '브랜드',
	SELLER: '판매자',
}
export default {
	components: {
		Recent,
	},
	props: {
		hasQR: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '검색',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		noRecent: {
			type: Boolean,
			default: false,
		},
		isScan: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			text: '',
			isFocus: false,
			currentType: 'ALL',
		}
	},
	computed: {
		typeText() {
			return TYPE_TEXT
		},
		isKeywordWrapperOpen() {
			return this.isFocus && !this.noRecent
		},
	},
	watch: {
		value(val) {
			this.text = val
		},
	},
	mounted() {
		if (this.value != this.text) {
			this.text = this.value
		}
	},
	methods: {
		async onDataChanged() {
			if (!this.isScan) {
				this.$emit('input', { value: this.text, type: this.currentType })
				this.$emit('change', { value: this.text, type: this.currentType })
				if (!this.noRecent) this.$refs['recent'].addRecent({ value: this.text, type: this.currentType })
			}
		},
		handleInput(e) {
			// 한국어 변경 즉시 반영되게 작업
			this.text = e.target.value
		},
		deleteValue() {
			this.text = ''
			this.currentType = 'ALL'
			this.onDataChanged()
			this.isFocus = false
		},
		clickQR() {
			this.$emit('clickQR')
		},
		clickRecent(recent) {
			this.text = recent.value
			this.currentType = recent.type
			this.onDataChanged()
			this.isFocus = false
		},
		focusOut() {
			this.$refs['keyword'].blur()
			this.isFocus = false
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .dropdown-item {
	font-size: 14px;
}
::v-deep .keyword-text {
	width: 100% !important;
}
.keyword-wrapper {
	top: 40px;
	width: 370px;
}
::v-deep .btn {
	height: auto !important;
	background: none !important;
	border: none !important;
	&:focus {
		box-shadow: none !important;
	}
}
ul.dropdown-menu.option-menu.show {
	background-color: green;
	top: 5px !important;
	box-shadow: 0px 4px 10px $DIM;
	border: 0;
}
.search-box-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	width: 470px;
	height: 40px;
	border: 2px solid $LAB_YELLOW;
	border-radius: 5px;
	.dropdown {
		height: 100%;
		width: 122px;
		border-radius: 5px 0 0 5px;
		.type {
			font-size: 14px;
			font-weight: 500;
			color: $DEFAULT;
			height: 20px;
			display: flex;
			align-items: center;
			padding-left: 10px;
			text-align: left;
			span {
				padding-right: 15px;
			}
			img {
				width: 8px;
				margin-left: auto;
				margin-right: 10px;
			}
			border-right: 1px solid $INPUT;
		}
		button {
			&:hover,
			&:focus {
				border: none !important;
			}
		}
	}
	input {
		font-size: 14px;
		font-weight: 400;
		border: none;
		box-shadow: none;
		width: 340px;
		height: 100%;
		padding-left: 8px;
	}
	.icon-wrapper {
		cursor: pointer;
		height: 100%;
		display: flex;
		align-items: center;
		margin-left: auto;
		.delete {
			// display: none;
			margin-right: 12px;
		}
		.qr {
			margin-right: 12px;
		}
		.search {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 100%;
			background-color: $LAB_YELLOW;
		}
	}
	// .keyword-list {
	// 	z-index: 10;
	// 	position: absolute;
	// 	width: 410px;
	// 	right: 40px;
	// 	max-height: 350px;
	// 	top: 40px;
	// 	overflow: auto;
	// 	font-size: 14px;
	// 	opacity: 0;
	// 	visibility: hidden;
	// 	&.active {
	// 		opacity: 1;
	// 		visibility: visible;
	// 	}
	// 	.title {
	// 		width: 100%;
	// 		height: 25px;
	// 		align-items: flex-end;
	// 		padding: 0 10px;
	// 		display: flex;
	// 		span {
	// 			font-size: 0.75rem;
	// 			font-weight: 400;
	// 		}
	// 		button {
	// 			margin-left: auto;
	// 			font-size: 0.75rem;
	// 			font-weight: 700;
	// 		}
	// 	}
	// 	.no-body {
	// 		padding: 3px 0 10px;
	// 		.line {
	// 			display: flex;
	// 			font-size: 12px;
	// 			margin: 5px 5px 0;
	// 			padding-left: 10px;
	// 			padding-right: 10px;
	// 			line-height: 30px;
	// 			cursor: pointer;
	// 			border-radius: 4px;
	// 			&:hover {
	// 				background-color: #f2f2f2;
	// 			}
	// 			.keyword-type {
	// 				display: inline-block;
	// 				width: 85px;
	// 				img {
	// 					width: 14px;
	// 					margin-right: 10px;
	// 				}
	// 				color: $SECONDARY_2;
	// 			}
	// 			.keyword-value {
	// 				overflow: hidden;
	// 				text-overflow: ellipsis;
	// 				white-space: nowrap;
	// 				color: black;
	// 				display: inline-block;
	// 				// padding-right: 20px;
	// 				width: calc(100% - 110px);
	// 			}
	// 			.keyword-remove {
	// 				position: absolute;
	// 				right: 10px;
	// 				img {
	// 					width: 24px;
	// 					padding: 4px;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}
</style>
