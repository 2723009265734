<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="title">{{ tt('구성성분') }}</div>
			<button class="init-btn" v-if="hasIngredients" @click="initIngredient">{{ tt('초기화') }}</button>
			<button class="init-btn input" @click="initIngredient" v-else>
				{{ tt('입력하기') }}<img class="arrow-right" src="@/assets/svg/chevron/chevron_right.svg" />
			</button>
		</div>
		<div class="ingredient-item-wrapper" v-if="hasIngredients">
			<div class="ingredient-item-header">
				<div class="header ingredient-name">
					{{ tt('물질 이름') }}
					<p class="required">*</p>
				</div>
				<div class="header ingredient-casno">CAS No</div>
				<div class="header ingredient-ratio">
					{{ tt('성분비') }}
					<p class="required">*</p>
				</div>
			</div>
			<form-ingredient-list-item
				ref="form-item"
				v-for="(item, idx) in ingredient"
				:key="idx"
				:value="item"
				:showError="isShowError"
				@delete="() => deleteItem(idx)"
				@change="value => changeItem(value, idx)"
			></form-ingredient-list-item>
			<div class="add-line-btm">
				<div class="add-btn" @click="addIngredient">+ {{ tt('추가하기') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import FormIngredientListItem from '@/views/common/components/form/ingredient/FormIngredientListItem.vue'
export default {
	name: 'FormIngredientList',
	props: {
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	components: { FormIngredientListItem },
	data() {
		return {
			ingredient: null,
		}
	},
	computed: {
		hasIngredients() {
			return this.ingredient?.length > 0
		},
		defaultItem() {
			return { casno: null, name: null, ratio: null }
		},
	},
	watch: {},
	methods: {
		initIngredient() {
			this.ingredient = [{ ...this.defaultItem }]
			this.$emit('change', this.ingredient)
		},
		addIngredient() {
			this.ingredient = [...this.ingredient, { ...this.defaultItem }]
			this.$emit('change', this.ingredient)
		},
		deleteItem(idx) {
			this.ingredient = this.ingredient.filter((item, i) => i !== idx)
			this.$emit('change', this.ingredient)
		},
		setIngredientItem({ item, index }) {
			this.ingredient[index] = item
			this.$emit('change', this.ingredient)
		},
		checkValid() {
			const ingredientForms = this.$refs['ingredientForm']
			let hasInvalid = false
			if (ingredientForms) {
				for (let i = 0; i < ingredientForms.length; i++) {
					const isValid = ingredientForms[i].checkValid()
					if (!isValid) hasInvalid = true
				}
			}
			return !hasInvalid
		},
		initValid() {
			const ingredientForms = this.$refs['ingredientForm']
			ingredientForms?.forEach(ref => {
				ref.initValid()
			})
		},
		changeItem(item, index) {
			const { name, casno, ratio } = item
			this.ingredient[index] = {
				name: name,
				casno: casno,
				ratio: ratio,
			}
			this.$emit('change', this.ingredient)
		},
		setData(ingredient) {
			if (ingredient) {
				this.ingredient = ingredient
			}
		},
		getData() {
			return [...this.ingredient]
		},
		showRequire() {
			this.$refs['form-item']?.forEach(ref => ref.showRequire())
		},
	},
}
</script>
<style lang="scss" scoped>
.arrow-right {
	width: 16px;
	height: 24px;
}
.form-wrap {
	display: flex;
	flex-direction: column;
	.form-header {
		width: 100%;
		height: 30px;
		display: flex;
		align-content: center;
		justify-content: space-between;
		.title {
			font-size: 16px;
			font-weight: 700;
			color: #000;
		}
		.init-btn {
			padding: 5px 10px;
			height: 30px;
			font-size: 14px;
			font-weight: 700;
			color: $BLACK_87;
			border-radius: 4px;
			background-color: $GREY_4;
			&:hover {
				background: $HOVER_BUTTON_COMMON;
			}
		}
	}
}
.ingredient-item-wrapper {
	.ingredient-item-header {
		display: flex;
		border: 1px solid $DIVIDER;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		gap: 10px;
		padding: 10px;

		text-align: left;
		div {
			width: 100%;
		}
		.header {
			height: 20px;
			font-size: 14px;
			font-weight: 700;
			color: $SECONDARY_2;
			&.ingredient-name {
				width: 250px;
			}
			&.ingredient-casno {
				width: 140px;
			}
			&.ingredient-ratio {
				width: 140px;
			}
		}
	}

	.add-line-btm {
		display: flex;
		border-left: 1px solid $DIVIDER;
		border-right: 1px solid $DIVIDER;
		border-bottom: 1px solid $DIVIDER;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 10px;
		.add-btn {
			height: 20px;
			font-size: 14px;
			font-weight: 700;
			color: $BLUE;
			cursor: pointer;
		}
	}
}

.ingredient-table-header {
	border-bottom: solid 1px $DIVIDER;
}
.ingredient-table-row {
	display: flex;
	td {
		padding: 10px;
		display: flex;
		align-items: center;
	}
}
</style>
