<template>
	<div class="global-filter-wrap">
		<div
			class="global-filter"
			:class="{
				active: storageCount > 0 && storageCount != checkedCount,
			}"
			@click.stop="toggleFilter"
		>
			<img src="@/assets/svg/inquiry.svg" style="margin-right: 6px" />
			<p>{{ tt('조회 범위') }}:</p>
			<h5 class="view-inst-all">{{ inquiryScope }}</h5>
			<button class="arrow-box">
				<span class="arrow"></span>
			</button>
		</div>
		<modal-global-filter ref="modal-global-filter"></modal-global-filter>
	</div>
</template>

<script>
import ModalGlobalFilter from '@/views/common/components/modalGen2/ModalGlobalFilter.vue'
import { mapState, mapActions } from 'vuex'
export default {
	components: {
		ModalGlobalFilter,
	},
	async mounted() {
		await this.getLocationStorageTree()
	},
	computed: {
		checkedCount() {
			const count = (JSON.parse(localStorage.getItem('GlobalFilterStorage-Headers')) ?? []).length
			return count == 0 ? this.storageCount : count
		},
		...mapState('tree/globalFilter', ['storageCount']),
		inquiryScope() {
			if (this.checkedCount == this.storageCount) {
				if (this.userPermission.storage && this.userPermission.storage.read) {
					return this.tt('기관 전체')
				} else {
					return this.tt('${1}개 보관함', this.checkedCount) + '(All)'
				}
			} else {
				return this.tt('${1}개 보관함', this.checkedCount)
			}
		},
	},
	methods: {
		...mapActions('tree/globalFilter', ['getLocationStorageTree']),
		toggleFilter() {
			this.$refs['modal-global-filter'].toggle()
		},
	},
}
</script>

<style lang="scss" scoped>
.global-filter-wrap {
	position: relative;
	.global-filter {
		width: 100%;
		display: flex;
		height: 54px;
		align-items: center;
		padding: 0 8px 0 16px;
		cursor: pointer;
		&.active {
			background-color: $SUB-YELLOW_200;
		}
		p {
			font-size: 0.75rem;
			color: #000;
			font-weight: 700;
		}
		h5 {
			margin-left: 5px;
			font-size: 0.75rem;
			font-weight: 400;
		}
		.arrow-box {
			width: 32px;
			height: 32px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: auto;
			transition: all ease 0.3s;
			.arrow {
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 7px solid #c4c4c4;
			}
			&:hover {
				background-color: $DIVIDER;
			}
			&:focus {
				background-color: $DIVIDER;
			}
		}
	}
}

.hide {
	opacity: 0;
	visibility: hidden;
}
.show {
	opacity: 1;
	visibility: visible;
}

.view-inst-all {
	margin-bottom: 0px;
}
</style>
