var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "store-info-wrapper"
  }, [_c('b', [_vm._v(_vm._s(_vm.tt('구매 안내')))]), _c('div', {
    staticClass: "process-wrap"
  }, [_c('div', {
    staticClass: "left-wrap"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-1.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('랩매니저 스토어 상품 검색')))])]), _c('img', {
    attrs: {
      "src": require("./images/icon-arrow.svg")
    }
  }), _c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-2.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('상품주문')))])])]), _c('img', {
    attrs: {
      "src": require("./images/icon-arrow.svg")
    }
  }), _c('div', {
    staticClass: "right-wrap"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('결제 가능 시점')))]), _c('div', [_c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-3.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('랩매니저 스토어 주문승인')))])]), _c('img', {
    attrs: {
      "src": require("./images/icon-arrow.svg")
    }
  }), _c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-4.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('상품배송')))])])])])]), _c('span', {
    staticClass: "button-wrap"
  }, [_vm._v(" " + _vm._s(_vm.tt('구매 프로세스')) + " ")]), _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('1. 구매관리 메뉴에서의 상품 주문은 후불결제 방식으로 진행됩니다. (300만원 한도)')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('2. 상품을 주문하면, 랩매니저 스토어로 주문내역이 접수됩니다(랩매니저 스토어에서 주문내역, 배송내역, 결제관리 등 확인 가능).')) + " ")]), _c('p', [_vm._v(_vm._s(_vm.tt('3. 판매자의 주문확인 및 주문승인 처리 후 배송이 시작됩니다.')))]), _c('p', [_vm._v(_vm._s(_vm.tt('4. 대금결제는 판매자의 주문승인 처리 이후 단계부터 가능합니다(랩매니저 스토어 결제관리 페이지에서 가능).')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('5. 누적 미결제금액 한도는 300만원이며, 주문일 기준 익월 말까지 해당 주문금액을 결제해주셔야 합니다(미결제시 추가 주문 불가능).')) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }