<template>
	<div class="merck-tooltip" v-if="show" :style="cssVariable" :class="{ animation: isAnimation }">
		<img v-if="image" :src="image" />
		<div class="merck-tooltip-inner" @click.prevent="onClickPromotionMore" :class="[placement, isBrandModal ? 'cursor' : '']">
			<slot></slot>
		</div>
		<button v-if="isClose" class="close" @click.prevent="$refs['modal-hide'].show()">
			<img src="@/assets/svg/popup-close-black.svg" alt="close" />
		</button>
		<modal-confirm
			ref="modal-hide"
			title="할인구매 팁 보지않기"
			:cancelText="'닫기'"
			:confirmText="'일주일간 보지 않기'"
			content="MERCK 등 인기 시약 브랜드 최대 20% 할인 관련 TIP을 숨기시겠습니까?"
			@confirm="merckHide"
		/>
		<ModalPromotionBrandTable ref="ModalPromotionBrandTable" />
	</div>
</template>

<script>
/*
cssVariable: {
	'--top': '-50%',
	'--left': '45%',
	'--backgroundColor': '#fff',
	'--textColor': '#eb3c96',
	'--border': '1px solid #eb3c96',
	'--borderColor': '#eb3c96',
}
*/
const dontShow7daysMerckTooltipKey = 'dontShow7daysMerckTooltipKey'
import { BUS } from '@/utils/modules/EventBus'
import ModalPromotionBrandTable from '../modalGen2/ModalPromotionBrandTable.vue'
export default {
	name: 'MerckTooltip',
	components: {
		ModalPromotionBrandTable,
	},
	props: {
		cssVariable: {
			type: Object,
			default: {},
		},
		image: {
			type: String,
			default: '',
		},
		isAnimation: {
			type: Boolean,
			default: false,
		},
		placement: {
			type: String,
			default: '',
		},
		isBrandModal: {
			type: Boolean,
			default: true,
		},
		isClose: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		isTooltipShow() {
			let date = this.$cookies.get(dontShow7daysMerckTooltipKey) ?? null
			if (date == null) return true
			return this.$DateTime.fromISO(date).plus({ days: 7 }) <= this.$DateTime.now()
		},
		show() {
			return !this.isClose || this.isTooltipShow
		},
	},
	methods: {
		merckHide() {
			this.$cookies.set(dontShow7daysMerckTooltipKey, this.$DateTime.now().toISO(), '7d')
			BUS.$emit('merckTooltipHide', false)
		},
		onClickPromotionMore() {
			if (this.isBrandModal) this.$refs['ModalPromotionBrandTable'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.close {
	position: absolute;
	top: 12px;
	right: 26px;
	margin: 2px 0 0 5px;
	padding: 5px;
	align-items: start;
	&:hover {
		opacity: unset !important;
	}
	img {
		width: 10px;
		height: 12px;
	}
}
.merck-tooltip {
	position: absolute;
	display: flex;
	width: min-content;
	background-color: var(--backgroundColor);
	border: var(--border);
	border-radius: 8px;
	padding: 0 10px;
	top: var(--top);
	left: var(--left);
	z-index: 30;
	filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
	& > img {
		margin: 5px;
	}
	&.animation {
		animation: float 1s ease-in-out infinite;
		// filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
	}
	.merck-tooltip-inner {
		color: var(--textColor);
		padding: 5px 0;
		top: -5px;
		left: 105%;
		font-size: 12px;
		width: max-content;
		display: flex;
		flex-direction: column;
		&.cursor {
			cursor: pointer;
		}
		img {
			margin-right: 5px;
		}
		&:before {
			content: '';
			position: absolute;
			border-style: solid;
			border-width: 7px;
			margin-top: -6px;
			border-color: transparent var(--borderColor, transparent) transparent transparent;
			top: 50%;
			right: 100%;
		}
		&:after {
			content: '';
			position: absolute;
			border-style: solid;
			border-width: 6px;
			margin-top: -5px;
			top: 50%;
			right: 100%;
			border-color: transparent var(--backgroundColor) transparent transparent;
		}
		//
		&.bottom {
			&:before {
				margin-top: 0px;
				border-width: 6px;
				top: 101%;
				right: 49%;
				border-color: var(--textColor) transparent transparent transparent;
			}
			&:after {
				margin-top: 0px;
				top: 100%;
				right: 49%;
				border-color: var(--backgroundColor) transparent transparent transparent;
			}
		}
		&.bottom-right {
			&:before {
				margin-top: 0px;
				border-width: 6px;
				top: 101%;
				right: 10%;
				border-color: var(--textColor) transparent transparent transparent;
			}
			&:after {
				margin-top: 0px;
				top: 100%;
				right: 10%;
				border-color: var(--backgroundColor) transparent transparent transparent;
			}
		}
		//
		&.bottom-left {
			&:before {
				margin-top: 0px;
				border-width: 6px;
				top: 101%;
				right: 90%;
				border-color: var(--textColor) transparent transparent transparent;
			}
			&:after {
				margin-top: 0px;
				top: 100%;
				right: 90%;
				border-color: var(--backgroundColor) transparent transparent transparent;
			}
		}
		.highlight-red {
			padding: 1px;
			padding-right: 5px;
			color: $RED;
			font-weight: bold;
			background-image: url('~@/assets/svg/highlight_red.svg');
			background-size: cover;
		}
	}
}
@keyframes float {
	0% {
		// box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
	50% {
		// box-shadow: 0 25px 15px 0px $DIM;
		transform: translatex(-3px);
	}
	100% {
		// box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translatey(0px);
	}
}
</style>
