var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "migration-header"
  }, [_c('span', {
    staticClass: "migration-header-text",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.text))
    }
  }), _c('div', {
    staticClass: "migration-right-header"
  }, [!_vm.isAppModeExperience ? _c('app-header-menu', {
    attrs: {
      "isMigration": _vm.isMigration
    },
    model: {
      value: _vm.menuUserInfo,
      callback: function callback($$v) {
        _vm.menuUserInfo = $$v;
      },
      expression: "menuUserInfo"
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }