<template>
	<div class="SafetyChemicals">
		<h2 class="AppContainer__title">{{ tt('유해화학물질') }}</h2>
		<div class="AppContainer">
			<div class="Filter__box">
				<search-box :placeholder="tt('검색')" :loadList="loadChemcialList"></search-box>
				<div class="right-btn-group">
					<button class="btn management" @click="showPeriod" :disabled="checkList.length == 0">
						<img src="@/assets/svg/icon-download.svg" alt="icon" />{{ tt('관리대장 다운로드') }}
						<div class="balloon">
							<p>{{ tt('목록에서 항목을 선택해주세요') }}</p>
						</div>
					</button>
					<button class="btn" @click="showPeriodAll">
						<img src="@/assets/svg/download-all.svg" alt="icon" />
						{{ tt('전체 관리대장 다운로드') }}
					</button>
					<button class="btn" @click="downloadExcel"><img src="@/assets/svg/icon-excel.svg" alt="icon" />{{ tt('목록 다운로드') }}</button>
				</div>
			</div>
			<div class="Table__wrap">
				<table>
					<thead>
						<tr>
							<th>
								<button class="checkbox" :class="({ active: isActive }, { active: isCheck })" @click="allChecked"></button>
							</th>
							<th
								v-for="item in checkHeaderList"
								:key="item.name"
								:width="item.width"
								:class="[{ headerHover: item.sort }, { asc: item.direction == 'ASC' }]"
							>
								<span>
									{{ tt(item.name) }}
									<button @click="changeSort(item)" v-if="item.sort">
										<img src="@/assets/svg/arrow-b.svg" alt="icon" />
									</button>
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<chemical-item
							:allCheck="isActive"
							@checkId="addCheckIdList"
							@uncheckId="deleteCheckIdList"
							:headers="headerList"
							v-for="item in list"
							:key="item.id"
							:item="item"
							:instId="instId"
							:checkList="checkList"
							@download="showPeriod"
							:keyword="listKeyword"
						></chemical-item>
					</tbody>
				</table>
				<no-data-list v-if="list.length == 0" :image="noData.image" :title="noData.title" :content="noData.content"></no-data-list>
			</div>
		</div>

		<pagination :total="count" @page="pagination"></pagination>

		<div class="Menu__floating" v-if="checkList.length > 0">
			<h4>
				<span class="count">{{ checkList.length }}{{ tt('개') }}</span
				>{{ tt('선택') }}
			</h4>
			<div class="btn-group">
				<button @click="showPeriod">
					<img src="@/assets/svg/icon-download.svg" alt="버튼icon" />
					<span>{{ tt('다운로드') }}</span>
				</button>
			</div>
		</div>

		<ModalConfirm
			ref="modalDownload"
			title="관리대장 다운로드"
			:content="`다음의 이메일 주소로 관리대장 다운로드 링크를 전송합니다.<br/>이메일 주소: ${$root.user.email}<br/><br/>파일 크기에 따라 이메일 수신은 최대 30분 소요될 수 있습니다.`"
			confirmText="전송"
		/>
		<!--modal-->
		<modal-period-setting hasFormat ref="modal-period" :title="modalTitle" @isHide="hidePeriod"></modal-period-setting>
		<!-- <modal-view-chemicals
			:isShow="displayViewChemicals"
			@close="hideViewChemicals"
		></modal-view-chemicals> -->
		<modal-loading ref="modal-loading" title="다운로드 중입니다." />
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import ModalPeriodSetting from '@/views/safety/management/modals/ModalPeriodSetting.vue'
import SearchBox from '@/views/common/components/SearchBox.vue'
// import ModalViewChemicals from '@/publishing/web/app/safety/modals/ModalViewChemicals.vue'
import ChemcialItem from '@/views/safety/chemical/components/ChemcialItem.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import NoDataList from '@/views/common/components/NoDataList.vue'
import ModalLoading from '@/views/common/components/modalGen2/ModalLoading.vue'
export default {
	name: 'SafetyChemicals',
	components: {
		SearchBox,
		ModalPeriodSetting,
		Pagination,
		'chemical-item': ChemcialItem,
		NoDataList,
		ModalLoading,
	},
	created() {
		this.instId = this.$route.params.instituteId
		this.checkHeaderList = this.headerList
		this.loadChemcialList()
	},
	data() {
		return {
			instId: null,
			isActive: false,
			isCheck: false,
			order: [],
			orderDirection: [],
			checkList: [],
			count: 0,
			list: [],
			offset: 0,
			length: 50,
			keyword: undefined,
			headerList: [
				{
					name: '정보',
					width: 600,
					value: true,
					sort: false,
				},
				{
					name: '제품번호',
					width: 160,
					value: true,
					sort: false,
				},
				{
					name: 'CAS No',
					width: 160,
					value: true,
					sort: false,
				},
				{
					name: '잔여 용량',
					width: 180,
					value: true,
					sort: false,
				},
				{
					name: '최초 등록일자',
					width: 180,
					value: true,
					sort: true,
					order: 'CREATE_DATETIME',
				},
				// {
				// 	name: '관리대장',
				// 	width: 200,
				// 	value: true,
				// 	sort: false,
				// },
				// {
				// 	name: '업데이트일',
				// 	width: 210,
				// 	value: true,
				// 	sort: true,
				// 	order: 'LAST_UPDATE_DATETIME',
				// 	direction: 'DESC',
				// },
			],
			checkHeaderList: [],
			displaySetting: false,
			displayViewChemicals: false,
			targetList: [],
			selectItem: null,
			modalTitle: '',
			noData: {
				image: '',
				title: '',
				content: '',
			},
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('chemical', ['chemicalList']),
		...mapState('institutionInformation', ['listKeyword']),
	},
	watch: {
		list(val) {
			if (val.length == 0) {
				this.noData = {
					image: 'list',
					title: '유해화학물질이 없습니다',
					content: '유해화학물질이 등록되면 자동으로 목록에 추가됩니다',
				}
				if (this.listKeyword.length > 0) {
					this.noData = {
						image: 'search',
						title: '조회된 결과가 없습니다.',
						content: '다시 한번 확인해 주세요.',
					}
				}
			}
		},
	},
	methods: {
		...mapMutations('institutionInformation', ['setKeyword']),
		...mapActions('chemical', ['lookupChemicalList', 'exportChemicalDiaryExcel', 'exportChemicalExcel', 'exportChemicalExcelStartDate']),
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
			this.loadChemcialList()
		},
		loadChemcialList() {
			const payload = {
				instId: this.instId,
				offset: this.offset,
				length: this.length,
				keyword: this.listKeyword,
				order: this.order,
				orderDirection: this.orderDirection,
			}

			this.lookupChemicalList(payload).then(() => {
				this.count = this.chemicalList.count
				this.list = this.chemicalList.list
			})
		},
		addCheckIdList(item) {
			this.checkList.push(item)
			if (this.checkList.length == this.list.length) {
				this.isCheck = true
			}
		},
		deleteCheckIdList(item) {
			for (let i = 0; i < this.checkList.length; i++) {
				if (JSON.stringify(this.checkList[i]) == JSON.stringify(item)) {
					this.checkList.splice(i, 1)
				}
			}
			this.isCheck = false
		},
		allChecked() {
			if (this.isActive) {
				this.isActive = false
				this.isCheck = false
			} else {
				this.isActive = true
				this.isCheck = true
			}
		},
		changeSort(item) {
			this.order = []
			this.orderDirection = []
			this.order.push(item.order)
			if (item.direction == 'DESC') item.direction = 'ASC'
			else item.direction = 'DESC'
			this.orderDirection.push(item.direction)
			this.loadChemcialList()
		},
		async showPeriod(item) {
			if (item.name != undefined) this.selectItem = item
			else this.selectItem = null

			let targets = []

			if (this.selectItem) {
				const target = {
					brandId: this.selectItem.brand.id ? this.selectItem.brand.id : '',
					productCode: this.selectItem.productCode ? this.selectItem.productCode : '',
				}
				targets.push(target)
			} else {
				this.checkList.forEach(x => {
					const target = {
						brandId: x.brandId != null ? x.brandId : '',
						productCode: x.productCode != null ? x.productCode : '',
						casno: x.casno != null ? x.casno : '',
						name: x.name != null ? x.name : '',
					}
					targets.push(target)
				})
			}

			const payload = {
				instId: this.$route.params.instituteId,
				body: {
					targets: targets,
				},
			}

			const response = await this.exportChemicalExcelStartDate(payload)

			this.modalTitle = '다운로드할 관리대장 기간 설정'
			this.$refs['modal-period'].show(response.data.startDatetime)

			console.log(response.data.startDatetime)
		},
		async showPeriodAll() {
			const payload = {
				instId: this.$route.params.instituteId,
				targets: null,
			}

			const response = await this.exportChemicalExcelStartDate(payload)

			this.modalTitle = '전체 다운로드할 관리대장 기간 설정'
			this.$refs['modal-period'].show(response.data.startDatetime, 'all')
		},
		hidePeriod(date, type, format) {
			this.$refs['modal-period'].hide()

			this.$refs['modalDownload'].show(() => {
				if (type && type == 'all') this.downloadAll(date, format)
				else this.downloadSelect(date, format)
			})
		},
		async downloadAll(date, format) {
			this.$refs['modal-loading'].show()
			const startDate = date ? this.formatDate(date[0]) : null
			const endDate = date ? this.formatDate(date[1]) : null

			const payload = {
				instId: this.$route.params.instituteId,
				targets: null,
				body: {
					email: this.$root.user.email,
					startDatetime: startDate,
					endDatetime: endDate,
					format,
				},
			}

			try {
				const response = await this.exportChemicalDiaryExcel(payload)

				if (response.status == '200') {
					// const disposition = {}
					// response.headers['content-disposition'].split(';').forEach(item => {
					// 	item = item.split('=')
					// 	disposition[item[0].trim()] = item[1] ? item[1].replace(/"/g, '') : true
					// })
					// console.log(decodeURIComponent(disposition.filename))
					// window.fileDownload(response, decodeURIComponent(disposition.filename))
					this.$root.toast(this.tt('전송 완료'), this.tt('유해화학물질 전체 관리대장을 메일로 전송하였습니다'), 'success')
				} else if (response.status === '404') {
					this.$root.toast(this.tt('관리대장 기록 없음'), this.tt('선택한 기간에 유해화학물질 관리대장 기록이 없습니다.'), 'error')
				}
			} catch (e) {
				this.$root.toast(this.tt('관리대장 기록 없음'), this.tt('선택한 기간에 유해화학물질 관리대장 기록이 없습니다.'), 'error')
			}
			this.$refs['modal-loading'].hide()
		},
		async downloadSelect(date, format) {
			this.$refs['modal-loading'].show()
			const startDate = date ? this.formatDate(date[0]) : null
			const endDate = date ? this.formatDate(date[1]) : null
			let targets = []

			if (this.selectItem) {
				const target = {
					brandId: this.selectItem.brand.id ? this.selectItem.brand.id : '',
					productCode: this.selectItem.productCode ? this.selectItem.productCode : '',
				}
				targets.push(target)
			} else {
				this.checkList.forEach(x => {
					const target = {
						brandId: x.brandId != null ? x.brandId : '',
						productCode: x.productCode != null ? x.productCode : '',
						casno: x.casno != null ? x.casno : '',
						name: x.name != null ? x.name : '',
					}
					targets.push(target)
				})
			}

			const payload = {
				instId: this.instId,
				body: {
					email: this.$root.user.email,
					startDatetime: startDate,
					endDatetime: endDate,
					targets: targets,
					format,
				},
			}
			try {
				const response = await this.exportChemicalDiaryExcel(payload)

				if (response.status == '200') {
					// const disposition = {}
					// response.headers['content-disposition'].split(';').forEach(item => {
					// 	item = item.split('=')
					// 	disposition[item[0].trim()] = item[1] ? item[1].replace(/"/g, '') : true
					// })
					// window.fileDownload(response, this.tt('유해화학물질 관리대장') + '.zip')
					this.$root.toast(this.tt('전송 완료'), this.tt('유해화학물질 전체 관리대장을 메일로 전송하였습니다'), 'success')
				} else if (response.status === '404') {
					this.$root.toast(this.tt('관리대장 기록 없음'), this.tt('선택한 기간에 유해화학물질 관리대장 기록이 없습니다.'), 'error')
				}
			} catch (e) {
				this.$root.toast(this.tt('관리대장 기록 없음'), this.tt('선택한 기간에 유해화학물질 관리대장 기록이 없습니다.'), 'error')
			}
			this.$refs['modal-loading'].hide()
		},
		async downloadExcel() {
			this.$refs['modal-loading'].show()
			const response = await this.exportChemicalExcel(this.instId)
			window.fileDownload(response, this.formatDate(new Date()) + '_' + this.tt('유해화학물질 리스트') + '.xlsx')
			this.$refs['modal-loading'].hide()
		},
		formatDate(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear()
			if (month.length < 2) {
				month = '0' + month
			}
			if (day.length < 2) {
				day = '0' + day
			}
			return [year, month, day].join('-')
		},
	},
}
</script>

<style lang="scss" scoped>
.SafetyChemicals {
	.btn {
		img {
			padding-right: 0;
		}
	}
	.management {
		.balloon {
			display: none;
		}
	}
	.management:disabled {
		position: relative;
		.balloon {
			position: absolute;
			padding: 12px 15px;
			display: block;
			background: $LAB_WHITE;
			border-radius: 5px;
			top: 37px;
			right: 10px;
			color: #000;
			font-weight: normal;
			font-size: 0.8125rem;
			visibility: hidden;
			z-index: 30;
			border: 1px solid rgba(0, 0, 0, 0.6);
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
			width: unset;
			padding: 10px 15px;
			box-sizing: border-box;
			white-space: initial;
			text-align: left;
			h6 {
				font-size: 0.875rem;
				color: #000;
				font-weight: 700;
				padding-bottom: 3px;
			}
			p {
				width: 100%;
				white-space: pre;
			}
		}
		.balloon:before {
			border-top: 0px solid transparent;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-bottom: 9px solid rgba(0, 0, 0, 0.6);
			content: '';
			position: absolute;
			top: -9.2px;
			right: 14px;
			z-index: 1003;
		}
		.balloon:after {
			border-top: 0px solid transparent;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 8px solid $LAB_WHITE;
			content: '';
			position: absolute;
			top: -8px;
			right: 15px;
			z-index: 1004;
		}
		&:hover {
			.balloon {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
</style>
