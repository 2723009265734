var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item-wrapper"
  }, [_c('div', {
    staticClass: "item-title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]), _c('div', {
    staticClass: "item-content"
  }, [_vm._t("default"), _vm.subContentText ? _c('div', {
    staticClass: "item-sub-text",
    domProps: {
      "innerHTML": _vm._s(_vm.subContentText)
    }
  }) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }