var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppHeader__menu-list"
  }, [_vm._l(_vm.itemList, function (item, index) {
    return _c('div', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.getItemEvent(item);
        }
      }
    }, [_c('item', {
      attrs: {
        "text": _vm.tt(item.itemName)
      }
    })], 1);
  }), _c('div', {
    staticClass: "AppHeader__menu-divider"
  }), _c('div', {
    staticClass: "sub-menu-wrap",
    on: {
      "mouseover": _vm.enterMenu,
      "mouseout": _vm.leaveMenu
    }
  }, [_c('button', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('서비스 이용')) + " ")]), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVisibleNestedMenu,
      expression: "isVisibleNestedMenu"
    }],
    staticClass: "subm-menu"
  }, _vm._l(_vm.subItemList, function (item, index) {
    return _c('li', {
      key: "submenu-" + index,
      staticClass: "menu-item",
      on: {
        "click": function click($event) {
          return _vm.getItemEvent(item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(item.itemName)) + " ")]);
  }), 0)]), _c('modal-account-setting', {
    ref: "modal-account-setting"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }