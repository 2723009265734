<template>
	<div class="page-wrapper">
		<migration-header :isMigration="true"></migration-header>
		<div class="content-wrapper">
			<div class="text-wrapper">
				<img class="logo" src="@/assets/svg/icon-logo.svg" />
				<h3 class="title" v-html="title"></h3>
				<div class="content" v-html="content"></div>
				<div class="subtext" v-html="subtext"></div>
			</div>
			<div class="button-wrapper">
				<lm-confirm-button-middle :text="migrationText" @click="routeMigrationSelectLab"></lm-confirm-button-middle>
			</div>
			<div class="show-more">
				<img src="@/assets/svg/migration/icon-question.svg" />
				<a href="https://smartjack.notion.site/PRO-f1d67324bafd4ffa925f9990215c11e8?pvs=4" target="_blank">
					이전하는 방법을 먼저 확인해 보세요!
				</a>
			</div>
			<!-- <img class="event-banner" src="./event_migration.png" /> -->
		</div>
	</div>
</template>

<script>
import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'
import { exproUrl } from '@/services/api/common'
import MixinMigration from '@/mixins/migration'
import MigrationHeader from '@/views/migration/components/MigrationHeader.vue'
import PageName from '@/utils/define/PageName'

export default {
	components: { LmConfirmButtonMiddle, MigrationHeader },
	mixins: [MixinMigration],
	data() {
		return {
			title: this.tt('새로운 랩매니저를 만나보세요'),
			content: this.tt(
				'기존 서비스는 2023년 9월 16일 부로 종료되어 이용이 불가능합니다.<br/>계속 사용을 원하신다면 랩매니저 PRO로 데이터를 이전해주시기 바랍니다.'
			),
			subtext: this.tt('데이터 이전 후 <b style="color: #0b7cff">14일 동안 무료</b>로 사용할 수 있습니다.'),
			migrationText: this.tt('랩매니저 PRO로 이전하기'),
		}
	},
	created() {},
	computed: {},
	methods: {
		goToExperience() {
			// window.open(exproUrl, '_self')
			location.href = 'https://app.lab-manager.com'
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	.content-wrapper {
		width: 600px;
		.text-wrapper {
			margin-bottom: 40px;
			.logo {
				margin-bottom: 20px;
				border-radius: 20px;
				width: 80px;
				height: 80px;
			}
			.title {
				font-size: 48px;
				font-style: normal;
				font-weight: 700;
				line-height: 70px;
				letter-spacing: 0px;
				text-align: center;
				margin-bottom: 20px;
			}

			.content {
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 26px;
				letter-spacing: 0px;
				text-align: center;
				margin-bottom: 10px;
				color: black;
			}

			.subtext {
				margin-top: 20px;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0px;
				text-align: center;
				color: $SECONDARY_2;
			}
		}
		.button-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			justify-content: space-between;
			margin-top: 60px;
		}
		.show-more {
			display: flex;
			align-items: center;
			margin-top: 20px;
			justify-content: center;
			a {
				font-size: 14px;
				text-decoration: underline;
			}
		}
		.event-banner {
			width: 100%;
		}

		.bottom-text-wrapper {
			cursor: pointer;
			.bottom-text {
				font-weight: 700;
				font-size: 14px;
				line-height: 20px;
				text-align: center;
				text-decoration-line: underline;
				color: $SECONDARY_2;
			}
		}
	}
}
</style>
