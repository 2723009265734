var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-msds', {
    ref: "form-msds",
    attrs: {
      "msdsInfo": _vm.msds,
      "value": _vm.msdsFile
    },
    on: {
      "change": _vm.changeFile,
      "delete": _vm.deleteFile
    }
  }, [[_c('modal-confirm', {
    ref: "modal-confirm",
    attrs: {
      "title": "MSDS 직접 업로드",
      "content": "이미 등록된 MSDS가 있습니다. MSDS파일을 변경하시면 브랜드, 제품번호가 동일한 모든 물품의 MSDS가 최신화됩니다. 업로드 하시겠습니까?"
    },
    on: {
      "confirm": function confirm($event) {
        return _vm.setUploadMsds(_vm.file);
      },
      "cancel": function cancel($event) {
        return _vm.$refs['form-msds'].deleteFile();
      }
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }