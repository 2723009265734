<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="selectStoragePopup Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt(title) }}
					</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<lm-input-search @search="searchTree" :placeholder="placeholder"></lm-input-search>
					<div>
						<ul class="horizational-list">
							<tree-view
								v-if="treeData"
								ref="modal-tree-view"
								key="modal-tree-view"
								textField="name"
								:draggable="false"
								:mode="mode"
								:selectWithChild="checkChild"
								:keyword="keyword"
								@changeActive="getActiveId"
								:treeData="treeData"
								@load="loadedTree"
								modal="location"
							></tree-view>
						</ul>
					</div>
				</div>
				<div class="Popup__bottom">
					<button class="Btn__management" @click="goManagementLocation">
						{{ tt('위치 관리') }}
					</button>
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="submit">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
// import SearchBox from '@/views/common/components/SearchBox.vue'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import LmInputSearch from '@/views/common/components/input/LmInputSearch.vue'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ModalSelectStorage',
	components: { 'tree-view': TreeView, LmInputSearch },
	mixins: [MixinModal],
	props: {
		treeData: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
		},
		mode: {
			type: String,
			default: '',
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
		checkChild: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			keyword: '',
			selectedGroupList: null,
		}
	},
	computed: {
		placeholder() {
			if (this.mode == 'STORAGE') {
				return '위치를 검색해주세요'
			} else {
				return '검색'
			}
		},
		setMode() {
			if (this.mode == 'STORAGE') {
				return 'selectable'
			}
		},
	},
	watch: {},
	methods: {
		show() {
			this.isShow = true
			this.selectedGroupList = null
		},
		close() {
			this.hide()
		},
		searchTree(keyword) {
			this.keyword = keyword
		},
		loadedTree() {
			this.$refs['modal-tree-view'].setActiveIdList([...this.selectedList])
		},
		getActiveId(idList, dataList) {
			const arr = dataList.filter((element, index) => {
				return dataList.indexOf(element) === index
			})
			this.selectedGroupList = arr
		},
		goManagementLocation() {
			this.$router.push({
				name: PageName.Institution.Location,
			})
		},
		submit() {
			this.$emit('submit', this.selectedGroupList)
		},
	},
}
</script>

<style lang="scss" scoped>

@import '@/styles/scss/institution/_organization.scss';

.selectStoragePopup {
	.horizational-list {
		height: 500px;
		width: 100%;
		padding-top: 0;
		margin-top: 20px;
		overflow-y: auto;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		li {
			padding: 12px;
			border-bottom: 0;
			transition: 0.3s all linear;
			&:hover {
				background-color: $GREY_1;
			}
			button {
				img {
					width: 20px;
					background-image: '@/assets/svg/Select-Circle.svg';
				}
			}
			> div {
				h6 {
					> img {
						width: 12px;
						margin-left: 6px;
					}
				}
			}
		}
	}
}

.LmInputSearch__container {
	width: 100%;
}
</style>
