var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('업로드한 이미지')))]), _c('div', {
    staticClass: "image-list"
  }, _vm._l(_vm.images, function (image, idx) {
    return _c('img', {
      key: image,
      attrs: {
        "width": "110px",
        "height": "150px",
        "src": image
      },
      on: {
        "click": function click($event) {
          return _vm.$refs['image-viewer'].show(idx);
        }
      }
    });
  }), 0), _c('modal-multi-image-viewer', {
    ref: "image-viewer",
    attrs: {
      "title": "이미지",
      "imageList": _vm.images
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }