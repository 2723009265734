var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "Modal ModalCompleteTransfer"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Modal__container"
  }, [_c('div', {
    staticClass: "Modal__content"
  }, [_vm._m(0), _c('h2', [_vm._v(_vm._s(_vm.tt('랩매니저 PRO 이전 완료')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('랩매니저 PRO로 이전이 완료되었습니다.<br/> 지금 랩매니저 PRO의 새로운 기능을 경험해 보세요!'))
    }
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.start
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('랩매니저 PRO 시작하기')) + " ")])])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/check.png")
    }
  })]);
}]

export { render, staticRenderFns }