<template>
	<div class="Menu__floating" v-if="selectedItems.length > 0">
		<h4>
			<span class="count">{{ tt('${1}개', selectedItems.length) }}</span>
			{{ tt('선택') }}
		</h4>
		<div class="btn-group">
			<button @click="$refs['modal-restore'].show()">
				<img src="@/assets/svg/Restore.svg" />
				<span>{{ tt('복구') }}</span>
			</button>
			<button @click="$refs['modal-remove'].show()">
				<img src="@/assets/svg/Trash.svg" />
				<span>{{ tt('영구삭제') }}</span>
			</button>
		</div>
		<modal-confirm
			ref="modal-restore"
			title="물품 복구"
			confirmText="복구"
			variant="primary"
			:content="`선택된 ${selectedItems.length}개 물품을 복구하시겠습니까?`"
			@confirm="restore"
		/>
		<modal-confirm
			ref="modal-remove"
			title="물품 영구삭제"
			confirmText="영구삭제"
			variant="warning"
			:content="`선택된 ${selectedItems.length}개 물품을 영구적으로 삭제하시겠습니까?`"
			@confirm="remove"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
	computed: {
		...mapState('inventoryGen2/disposal', ['selectedItems']),
	},
	methods: {
		...mapMutations('inventoryGen2/disposal', ['setSelectedItems']),
		...mapActions('inventoryGen2/disposal', ['restoreItem', 'removeItem']),
		async restore() {
			await this.restoreItem({ itemsId: this.selectedItems.map(it => it.id) })
			this.$root.toast('물품 복구', `${this.selectedItems.length}개의 물품이 복구되었습니다.`, 'success')
			this.setSelectedItems([])
		},
		async remove() {
			await this.removeItem({ itemsId: this.selectedItems.map(it => it.id) })
			this.$root.toast('물품 영구 삭제', `${this.selectedItems.length}개의 물품이 영구삭제되었습니다.`, 'success')
			this.setSelectedItems([])
		},
	},
}
</script>

<style lang="scss" scoped></style>
