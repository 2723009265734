var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasCasno ? _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('물성 정보')))]), _c('div', {
    staticClass: "property-list"
  }, [_c('button', {
    on: {
      "click": _vm.goToChemspider
    }
  }, [_c('property', {
    attrs: {
      "img": require('@/views/inventoryGen2/components/svg/icon-chem-spider.svg'),
      "title": "Chem Spider",
      "subtext": "켐스파이더에서 정보 보기"
    }
  })], 1), _c('button', {
    on: {
      "click": _vm.goToSRD
    }
  }, [_c('property', {
    attrs: {
      "img": require('@/views/inventoryGen2/components/svg/icon-srd.svg'),
      "title": "물성 정보 참조",
      "subtext": "Standard Reference Data"
    }
  })], 1)])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }