var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('관리번호 가이드')))])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "boxwrap"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('1. 물품관리번호(MNG Code)의 구성')))]), _c('div', {
    staticClass: "yellow-box"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.tt('물품관리번호: A - A - AA - 21 - 11')) + " ")]), _c('div', {
    staticClass: "flex management"
  }, [_c('div', [_c('b', [_vm._v("A ")]), _c('p', [_vm._v("1st"), _c('br'), _vm._v(_vm._s(_vm.tt('관리항목')))])]), _c('div', [_c('b', [_vm._v("A ")]), _c('p', [_vm._v("2nd"), _c('br'), _vm._v(_vm._s(_vm.tt('관리항목')))])]), _c('div', [_c('b', [_vm._v("AA ")]), _c('p', [_vm._v("3rd"), _c('br'), _vm._v(_vm._s(_vm.tt('관리항목')))])]), _c('div', [_c('b', [_vm._v("21 ")]), _c('p', [_vm._v("4th"), _c('br'), _vm._v(_vm._s(_vm.tt('관리항목')))])]), _c('div', [_c('b', [_vm._v("11 ")]), _c('p', [_vm._v("5th"), _c('br'), _vm._v(_vm._s(_vm.tt('관리항목')))])])])]), _c('div', {
    staticClass: "description"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("[\uAD00\uB9AC\uBC88\uD638]\uB294 [\uAD00\uB9AC\uD56D\uBAA9]\uC774 \uC21C\uCC28\uC801\uC73C\uB85C \uBC30\uC5F4\uB418\uC5B4 \uB9CC\uB4E4\uC5B4\uC9D1\uB2C8\uB2E4.<br />(\uCD5C\uB300 5\uAC1C\uB85C \uAD6C\uC131 \uAC00\uB2A5) <br />"))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("\uC608\uB97C\uB4E4\uC5B4 [\uB4F1\uB85D \uC5F0\uC6D0\uC77C] - [\uCE74\uD14C\uACE0\uB9AC] - [\uB4F1\uB85D \uC21C\uC11C] - [\uBCF4\uAD00\uD568] - [\uBB3C\uD488\uBA85 \uC55E \uB450\uAE00\uC790]\uC758 \uC21C\uC11C\uB85C \uAD00\uB9AC\uBC88\uD638\uB97C \uAD6C\uC131\uD558\uC600 \uB54C \uAD00\uB9AC\uBC88\uD638\uB294 230327 - \uC2DC\uC57D - 806 - A\uBCF4\uAD00\uD568 - Ac \uB85C \uAD6C\uC131\uB418\uAC8C \uB429\uB2C8\uB2E4."))
    }
  })])]), _c('div', {
    staticClass: "boxwrap"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('2. 관리항목의 구성')))]), _c('div', {
    staticClass: "yellow-box"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', [_c('p', [_vm._v(_vm._s(_vm.tt('별칭')))]), _c('b', [_vm._v("A")])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.tt('내용')))]), _c('b', [_vm._v(_vm._s(_vm.tt('위험물 보관소')))])])])]), _c('div', {
    staticClass: "description"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('각 관리항목은 [별칭]과 [내용]으로 구성되어 있습니다.'))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('[별칭]은 숫자 또는 텍스트(최대 6자리)로 구성할 수 있습니다'))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('[내용]은 별칭의 원래 명칭이므로 수정할 수 없습니다.'))
    }
  })])]), _c('div', {
    staticClass: "boxwrap"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('3. 관리항목의 별칭 편집하기')))]), _c('div', {
    staticClass: "summary"
  }, [_c('div', {
    staticClass: "black-box"
  }, [_c('b', [_vm._v(_vm._s(_vm.tt('스잭')))]), _c('span', {
    staticClass: "cursor"
  })]), _c('p', [_vm._v(_vm._s(_vm.tt('스마트잭연구실')))])]), _c('div', {
    staticClass: "description"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('[별칭]으로 표시되는 관리항목(그룹, 카테고리, 보관위치)은 별칭 영역을 클릭하여 바로 편집할 수 있습니다.'))
    }
  })])]), _c('div', {
    staticClass: "boxwrap"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('4. 관리번호 순서 변경하기')))]), _c('div', {
    staticClass: "Item__classification-list"
  }, [_c('div', {
    staticClass: "pointer"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Combined Shape.svg"),
      "alt": "arrow-icon"
    }
  })]), _c('div', {
    staticClass: "Item__classification-item"
  }, [_c('h5', [_c('button', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/draggable.svg"),
      "alt": "icon"
    }
  })]), _vm._v(" " + _vm._s(_vm.tt('그룹')) + " "), _c('button', {
    staticClass: "btn"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/setting-2.svg"),
      "alt": "icon"
    }
  })])]), _c('ul', [_c('li', [_c('h6', [_vm._v("L1")]), _c('p', [_vm._v(_vm._s(_vm.tt('스마트잭연구실')))])]), _c('li', [_c('h6', [_vm._v("L2")]), _c('p', [_vm._v(_vm._s(_vm.tt('스마트잭연구실')))])])])])]), _c('div', {
    staticClass: "description"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('각 관리항목 타이틀 좌측의 아이콘(|||)을 드래그하여 순서를 변경할 수 있습니다.'))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('주의) 기 등록된 관리번호는 추후 설정에서 관리번호 구성항목 및 순서를 변경해도 소급하여 반영(변경)되지 않습니다. 또한 관리번호 중 ‘보관함’의 경우 등록 후 보관함을 이동하더라도 그 값이 변경되지 않습니다.'))
    }
  })])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }