var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-item', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.hideSelect,
      expression: "hideSelect"
    }],
    attrs: {
      "title": _vm.title,
      "subContentText": _vm.subContentText,
      "required": _vm.isRequired
    }
  }, [_vm.list.length > 0 ? _c('div', {
    staticClass: "select-wrapper",
    "class": {
      error: !_vm.isValid
    },
    style: "width:".concat(_vm.inputWidthSize, "px; height:").concat(_vm.inputHeightSize, "px;"),
    on: {
      "click": _vm.toggleSelect
    }
  }, [_c('b-dropdown', {
    ref: "dropdown",
    attrs: {
      "no-caret": "",
      "toggle-class": "option-wrapper",
      "variant": "link",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [!_vm.selectedValue && _vm.placeholder ? _c('span', [_vm._v(_vm._s(_vm.tt(_vm.placeholder)))]) : _vm._e(), _vm.selectedValue && _vm.selectedValue.code ? _c('b', {
          ref: "code"
        }, [_vm._v(_vm._s(_vm.tt(_vm.selectedValue.code)))]) : _vm._e(), _vm.selectedValue ? _c('p', {
          ref: "name",
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.selectedValue.name)) + " ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 3710952976)
  }, _vm._l(_vm.list, function (item, index) {
    return _c('b-dropdown-item', {
      key: "item-".concat(index),
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.changeOption(index);
        }
      }
    }, [item.code ? _c('b', [_vm._v(" " + _vm._s(_vm.tt(item.code)) + " ")]) : _vm._e(), _c('p', [_vm._v(" " + _vm._s(_vm.tt(item.name)) + " ")])]);
  }), 1)], 1) : _vm._e(), !_vm.isValid ? _c('div', {
    staticClass: "error"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/purchase/icon-error.svg"),
      "alt": "error"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt(_vm.inValidText)))])]) : _vm._e(), _c('div', [_vm._t("default"), _vm.list.length == 0 ? _vm._t("empty") : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }