<template>
	<base-item :title="title" :subContentText="subContentText" :required="isRequired" v-click-outside="hideSelect">
		<div
			v-if="list.length > 0"
			class="select-wrapper"
			:class="{ error: !isValid }"
			@click="toggleSelect"
			:style="`width:${inputWidthSize}px; height:${inputHeightSize}px;`"
		>
			<b-dropdown ref="dropdown" no-caret toggle-class="option-wrapper" variant="link" right>
				<template #button-content>
					<span v-if="!selectedValue && placeholder">{{ tt(placeholder) }}</span>
					<b v-if="selectedValue && selectedValue.code" ref="code">{{ tt(selectedValue.code) }}</b>
					<p v-if="selectedValue" class="name" ref="name">
						{{ tt(selectedValue.name) }}
					</p>
				</template>
				<b-dropdown-item @click.stop="changeOption(index)" :key="`item-${index}`" v-for="(item, index) in list">
					<b v-if="item.code">
						{{ tt(item.code) }}
					</b>
					<p>
						{{ tt(item.name) }}
					</p>
				</b-dropdown-item>
			</b-dropdown>
		</div>
		<!-- <div class="option-wrapper" v-show="isShowSelect">
			<ul>
				<li v-for="(item, index) in list" :key="`item-${index}`" :value="item.value" @click="changeOption(index)">
					<b v-if="item.code">
						{{ tt(item.code) }}
					</b>
					<p>
						{{ tt(item.name) }}
					</p>
				</li>
			</ul>
		</div> -->
		<div v-if="!isValid" class="error">
			<img src="@/assets/svg/purchase/icon-error.svg" alt="error" />
			<span>{{ tt(inValidText) }}</span>
		</div>
		<div>
			<slot></slot>
			<slot v-if="list.length == 0" name="empty"></slot>
		</div>
	</base-item>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputSelect',
	components: {
		BaseItem,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		inputWidthSize: {
			type: Number,
			default: 300,
		},
		inputHeightSize: {
			type: Number,
			default: 36,
		},
		placeholder: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		inValidText: {
			type: String,
			default: '',
		},
		item: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			isShowSelect: false,
			value: null,
		}
	},
	computed: {
		selectedValue() {
			return this.list.find(item => item.value == this.value)
		},
	},
	watch: {
		item() {
			this.value = this.item
		},
	},
	created() {
		if (this.item) this.value = this.item
	},
	methods: {
		toggleSelect() {
			this.isShowSelect = !this.isShowSelect
		},
		changeOption(index) {
			this.value = this.list[index].value
			this.$emit('change', this.value)
			this.isShowSelect = false
		},
		hideSelect() {
			this.isShowSelect = false
			if (this.$refs['dropdown']) this.$refs['dropdown'].hide()
		},
		setValue(value) {
			this.value = value
		},
	},
}
</script>

<style lang="scss" scoped>
.select-wrapper {
	width: 30%;
	height: 36px;
	background-image: url(~@/assets/svg/purchase/select-triangle.svg);
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: center right 8px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid $COLOR_CCC;
	&.error {
		border-color: $RED;
	}
}

::v-deep {
	.dropdown {
		height: 100%;
		width: 100%;
	}
	.option-wrapper {
		justify-content: left;
		width: 100%;
		height: 100%;
		background-color: transparent;
		border: none;
		padding-left: 10px;
		text-align: left;
		text-decoration: none;
		&:hover,
		&:active,
		&:focus {
			color: $DEFAULT;
			box-shadow: none;
		}
		b {
			margin-right: 10px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 90px;
		}
		p {
			max-width: 90%;
			min-width: 90px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: 400;
		}
		span {
			color: $PLACEHOLDER;
		}
	}
	ul {
		width: 100%;
		overflow-y: auto;
		max-height: 200px;
		padding: 5px;
		li {
			padding-left: 10px;
			font-size: 0.875rem;
			display: flex;
			align-items: center;
			width: 100%;
			height: 36px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				background-color: $GREY_2;
				border-radius: 4px;
			}
			a {
				display: flex;
				padding: 0px;
				width: 100%;
				height: 100%;
				align-items: center;
				&:hover {
					background-color: transparent;
				}
				&:active {
					color: $DEFAULT;
				}
				b {
					margin-right: 10px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					max-width: 90px;
				}
				p {
					max-width: 100%;
					min-width: 90px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
	}
}
.error:not(.select-wrapper) {
	display: flex;
	span {
		font-size: 0.75rem;
		color: $RED;
		margin-left: 6px;
	}
}
</style>
