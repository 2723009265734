var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "ModalWithdrawalMember Popup__depth2"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__content"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('${1}님', _vm.member))), _c('br'), _vm._v(_vm._s(_vm.tt('정말로 탈퇴하시겠어요?')))]), _c('p', [_vm._v(_vm._s(_vm.tt('랩매니저 회원 탈퇴 시, 모든 이용정보가 삭제됩니다.'))), _c('br'), _vm._v(_vm._s(_vm.tt('이용 정보는 탈퇴 후 6개월 간 보관됩니다.')))]), _c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.agreement,
      expression: "agreement"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.agreement) ? _vm._i(_vm.agreement, null) > -1 : _vm.agreement
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.agreement,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.agreement = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.agreement = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.agreement = $$c;
        }
      }
    }
  }), _vm._v(_vm._s(_vm.tt('안내사항을 모두 확인했으며, 이에 동의합니다')))]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('button', {
    staticClass: "Btn__withdrawal",
    on: {
      "click": _vm.withdrawal
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('회원탈퇴')) + " ")]), _c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }