import { render, staticRenderFns } from "./SafetyManagementMaterials.vue?vue&type=template&id=7d1e661c&scoped=true&"
import script from "./SafetyManagementMaterials.vue?vue&type=script&lang=js&"
export * from "./SafetyManagementMaterials.vue?vue&type=script&lang=js&"
import style0 from "./SafetyManagementMaterials.vue?vue&type=style&index=0&id=7d1e661c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d1e661c",
  null
  
)

export default component.exports