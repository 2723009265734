<template>
	<div class="license-selector-wrapper">
		<div class="switcher">
			<div class="yearly" @click="changeMonthly(false)" :class="{ selected: !isMonthly }">
				{{ tt('연간결제') }} <span>{{ tt('최대 -16%') }}</span>
			</div>
			<div class="monthly" @click="changeMonthly(true)" :class="{ selected: isMonthly }">
				{{ tt('월간 결제') }}
			</div>
		</div>
		<div class="selector-header">
			<span class="header-title">{{ tt('라이선스 선택') }}</span>
			<span class="header-side-text">
				{{ tt('(VAT 별도)') }}
			</span>
		</div>
		<div class="selector-wrapper">
			<license-selector-item
				v-for="license in licenseTypes"
				:key="license"
				:licenseType="license"
				:selectedLicenseType="licenseType"
				@getLicenseType="getLicenseType"
			>
			</license-selector-item>
		</div>
	</div>
</template>

<script>
import LicenseSelectorItem from '@/views/institute/components/LicenseSelectorItem.vue'
import { mapMutations, mapState } from 'vuex'
export default {
	components: { LicenseSelectorItem },
	watch: {
		licenseType: {
			handler() {
				this.$emit('licenseType', this.licenseType)
			},
		},
	},
	created() {},
	data() {
		return {
			licenseType: 'STANDARD',
			licenseTypes: ['STANDARD', 'EDUCATION', 'BASIC'],
		}
	},
	computed: {
		...mapState('license', ['isMonthly']),
	},
	methods: {
		...mapMutations('license', ['setMonthly']),
		getLicenseType(licenseType) {
			this.licenseType = licenseType
		},
		changeMonthly(isMonthly) {
			this.setMonthly(isMonthly)
			if (isMonthly && this.licenseType == 'EDUCATION') {
				this.licenseType = 'STANDARD'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
div.license-selector-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	.switcher {
		height: 40px;
		border-radius: 20px;
		background-color: $DIVIDER;
		padding: 2px;
		display: flex;
		align-items: center;
		width: fit-content;
		& > div {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			font-size: 12px;
			gap: 5px;
			span {
				color: $RED;
			}
			padding: 0px 30px;
		}
		.selected {
			background-color: white;
			border-radius: 20px;
		}
	}
	div.selector-header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 15px;
		font-size: 12px;
		font-weight: 400;
		color: $DEFAULT;
		span.header-title {
		}
		span.header-side-text {
		}
	}
	.selector-wrapper {
		margin-top: 10px;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
</style>
