var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal-form-cargo-management",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": function click($event) {
              return _vm.$emit('goPrev');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('이전으로')) + " ")])]), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.clickComplete
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_c('cargo-form-management', {
    ref: "form-management"
  }), _c('form-select-sticker', {
    ref: "form-select-sticker",
    attrs: {
      "category": _vm.category,
      "item": _vm.cargoInfo,
      "isGhs": !!_vm.cargoInfo.ghs
    },
    on: {
      "change": _vm.setStickerType,
      "completeGHS": _vm.completeGHS
    }
  }), _c('form-check-auto-cargo')]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }