var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "sm",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "font-size": "16px"
          }
        }, [_c('p', [_vm._v(_vm._s(_vm.tt('상품을 추가했습니다.')))])])];
      },
      proxy: true
    }])
  }, [[_c('base-button', {
    staticStyle: {
      "width": "100%",
      "margin-bottom": "10px",
      "height": "40px"
    },
    attrs: {
      "color": 'primary'
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(_vm._s(_vm.tt('상품 주문으로 돌아가기')))]), _c('base-button', {
    staticStyle: {
      "width": "100%",
      "height": "40px"
    },
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(_vm._s(_vm.tt('계속 검색하기')))])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }