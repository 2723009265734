<template>
	<div class="header-wrapper">
		<h2 class="header-title">{{ title }}</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
					:image="require('@/assets/svg/filter.svg')"
					@click="filterBtnClick"
				></image-button>
				<!-- date button -->
				<date-picker-button
					:image="require('@/assets/svg/list/inventory-calendar.svg')"
					:activeImage="require('@/assets/svg/list/active/calendar-active.svg')"
					:text="'날짜'"
					:subText="dateButtonSubtext"
					:defaultTime="calendarDatetime"
					:isActive="dateButtonSubtext != null"
					@pickDate="pickDate"
					@changeDate="changeCalendar"
				></date-picker-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button
					:disabled="selectedItems.length === 0"
					:text="'삭제'"
					:image="require('@/assets/svg/Trash.svg')"
					@click="$refs['modal-remove'].show()"
				></image-button>
			</div>
		</div>
		<div class="status-btn-wrapper">
			<list-filter-tab
				v-for="status in filterTabButton"
				:isActive="status.id === selectedStatus"
				:text="status.name"
				:key="status.id"
				@click="changeActiveStatus(status.id)"
			></list-filter-tab>
		</div>
		<modal-filter ref="modal-filter" @changeLoadParams="setLoadParams" />
		<modal-confirm
			ref="modal-remove"
			title="등록대기 중인 물품 삭제"
			confirmText="삭제"
			variant="warning"
			:content="`등록 대기 중인 물품을 삭제하시겠습니까?`"
			@confirm="remove"
		/>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import ListFilterTab from '@/views/common/components/buttons/ListFilterButton.vue'
import ModalFilter from './ModalFilter.vue'
// import ModalViewOption from './ModalViewOption.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import DatePickerButton from '@/views/common/components/baseButton/DatePickerButton.vue'
import MixinListFixedHeader from '@/views/inventoryGen2/mixin/MixinListFixedHeader'

export default {
	mixins: [MixinListFixedHeader],
	components: {
		ListFilterTab,
		ModalFilter,
		// ModalViewOption,
		DatePickerButton,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState('inventoryGen2/wait', ['selectedItems']),
		...mapGetters('inventoryGen2/wait', ['selectedStatus', 'filteredCount']),
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
		filterTabButton() {
			return [
				{ id: 'ALL', name: '전체' },
				{ id: 'ING', name: '분석중' },
				{ id: 'FAIL', name: '분석불가' },
			]
		},
	},
	data() {
		return {
			isActiveCalendar: false,
			cookieFilterKey: 'inventoryWaitFilter',
			cookieDatetimeFilterKey: 'inventoryWaitDatetimeFilter',
		}
	},
	created() {
		this.createdInit()
	},
	methods: {
		...mapMutations('inventoryGen2/wait', ['setSelectedItems']),
		...mapActions('inventoryGen2/wait', ['setLoadParams', 'removeItem']),
		async remove() {
			await this.removeItem({ ids: this.selectedItems.map(it => it.id) })
			this.$root.toast('물품 삭제 완료', `등록대기 중인 물품이 삭제되었습니다.`, 'success')
			this.setSelectedItems([])
		},
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		watchBtnClick() {
			this.$refs['modal-view-option'].show()
		},
		pickDate() {
			this.$root.toast(this.tt('기간 필터 적용 완료'), this.tt('기간 필터가 적용되었습니다.'), 'success')
		},
		async changeActiveStatus(id) {
			const classification = id === 'ALL' ? ['ING', 'FAIL'] : [id]
			await this.setLoadParams({ status: classification })
		},
		async changeKeyword(keyword) {
			await this.setLoadParams({ keyword })
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/list/_list-header.scss';
</style>
