<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalRegisteringMember">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title" v-text="mode == 'edit' ? tt('멤버 정보편집') : tt('멤버 등록')"></h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content" :class="groupList.length > 2 || permissions.length > 3 ? 'isOverflow' : ''">
					<div class="Member__info-img" v-if="page == 'member' && mode == 'edit'">
						<img
							name="logo"
							:src="imageUrl ? imageUrl : info.image ? info.image : require('@/assets/svg/member-detail-photo.svg')"
							alt="member-image"
						/>
						<input type="file" accept="image/*" hidden ref="imgFile" @change="uploadImage" />
					</div>
					<div class="Popup__input-field" :class="{ error: !isValidEmail }">
						<p>{{ tt('이메일(계정)') }}<span class="required">*</span></p>
						<input
							type="text"
							:placeholder="tt('이메일(계정)을 입력해주세요')"
							v-model="info.email"
							@input="isValidEmail = true"
							:disabled="page == 'member' && mode == 'edit'"
						/>
						<span>{{ tt(validEmailText) }}</span>
					</div>
					<div class="Popup__input-field" :class="{ error: !isValidPw }">
						<p>{{ tt('비밀번호') }}<span class="required" v-if="mode == 'add' || (page != 'member' && isAlmighty == false)">*</span></p>
						<div class="flex">
							<input
								:type="mode == 'edit' ? 'password' : 'text'"
								:placeholder="tt('비밀번호를 입력해주세요')"
								v-model="info.password"
								@input="isValidPw = true"
								maxlength="20"
								:disabled="mode == 'edit' && page == 'member' && userInfo.id != info.id"
							/>
							<button class="ml-auto btn" @click="showInitPw">
								{{ tt('초기화') }}
							</button>
						</div>
						<span>{{ tt(validPwText) }}</span>
					</div>
					<div class="Popup__input-field" :class="{ error: !isValidName }">
						<p>{{ tt('이름') }}<span class="required">*</span></p>
						<input type="text" :placeholder="tt('이름을 입력해주세요')" v-model="info.name" @input="isValidName = true" />
						<span>{{ tt('필수값을 입력해주세요') }}</span>
					</div>
					<div class="Popup__input-field" :class="{ error: !isValidPhone }">
						<p>{{ tt('연락처') }}</p>
						<input type="text" :placeholder="tt('연락처를 입력해주세요')" :value="info.phone" @input="autoHypenTel" maxlength="13" />
						<span>{{ tt('연락처를 형식에 맞게 입력해주세요') }}</span>
					</div>
					<div class="Popup__select-field setting-select-field deleteable-input-field">
						<p>{{ tt('권한 지정') }}</p>
						<permission-box
							v-for="(item, index) in permissions"
							:item="item"
							:list="authorityList"
							:selectedList="selectedAuthorityList"
							@select="selectAuthority"
							@delete="deleteAuthority"
							:index="index"
							:key="'permission' + index"
						></permission-box>
						<button
							class="AppContainer__add-item-btn"
							@click="addAuthority"
							v-if="
								(page == 'member' && permissions.length < authorityList.length) ||
								(page == 'create' && permissions.length < 2) ||
								page == 'excel'
							"
						>
							+{{ tt('추가하기') }}
						</button>
					</div>
					<div
						class="Popup__input-field deleteable-input-field Dropdown-input"
						v-if="page == 'member' || ($route.query.page && page == 'excel')"
					>
						<p>{{ tt('소속') }}</p>
						<group-box
							v-for="(group, index) in groupList"
							:key="'group' + index"
							:item="group"
							:list="groupList"
							:index="index"
							@select="addGroup"
							@delete="deleteGroup"
						></group-box>
						<button class="AppContainer__add-item-btn" @click="addGroup">+{{ tt('추가하기') }}</button>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button @click="close" class="Btn__close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="complete" v-text="mode == 'edit' ? tt('완료') : tt('등록')"></button>
					</div>
				</div>
			</div>
		</div>

		<!--modals-->
		<modal-notice-init-pw ref="modal-notice-init-pw" @initPw="initPw" :password="password"></modal-notice-init-pw>
		<modal-select-group ref="modal-select-group" @select="editGroup"></modal-select-group>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import ModalNoticeInitPw from '@/views/creation/modals/ModalNoticeInitPw.vue'
import PermissionBox from '@/views/creation/pages/components/PermissionBox.vue'
import GroupBox from '@/views/institute/member/modals/components/GroupBox.vue'
import Validation from '@/utils/Validation'
import institutePermissionAPI from '@/services/api/institute/permission'
import instituteMemberAPI from '@/services/api/institute/member'
import instituteInformationAPI from '@/services/api/institute/information'
import ModalSelectGroup from '@/views/institute/organization/modals/ModalAddGroupAuth.vue'
import { mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	mixins: [MixinModal],
	props: {
		page: {
			type: String,
			default: '',
		},
		userInfo: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		PermissionBox,
		'modal-notice-init-pw': ModalNoticeInitPw,
		ModalSelectGroup,
		GroupBox,
	},
	data() {
		return {
			mode: null,
			authorityList: [],
			selectedAuthorityList: [],
			info: {
				email: null,
				password: null,
				name: null,
				phone: null,
				permissions: [],
				permissionsName: [],
				groups: [],
				groupsName: [],
			},
			permissions: [],
			isValidName: true,
			isValidPw: true,
			isValidEmail: true,
			isValidPhone: true,
			keyword: '',
			offset: 0,
			length: 50,
			password: null,
			validEmailText: '',
			validPwText: '',
			groupList: [],
			imageUrl: null,
			isAlmighty: false,
		}
	},
	computed: {},
	methods: {
		...mapActions('member', ['updateMember']),
		async show(mode, item) {
			this.mode = mode
			this.isValidName = true
			this.isValidPw = true
			this.isValidEmail = true
			this.isValidPhone = true
			this.permissions = []
			this.groupList = []
			this.imageUrl = null
			this.isAlmighty = false
			await this.loadPermission()
			await this.loadInitPw()
			if (this.page != 'member') {
				const response = await instituteInformationAPI.lookupInstInfo(this.$route.params.instituteId)

				const almightyMembers = response.data.almightyMembers
				almightyMembers.forEach(x => {
					if (this.userInfo.id == x.id) {
						this.isAlmighty = true
					}
				})
			}
			this.isShow = true

			//멤버 편집 시
			if (this.mode == 'edit') {
				if (item.id) {
					this.info.id = item.id
				}
				this.info.email = item.email
				this.info.name = item.name
				this.info.phone = item.phone
				if (this.info.phone.indexOf('-') < 0) {
					this.info.phone = Validation.autoHypenTel(this.info.phone)
				}
				this.info.image = item.image
				//1. 페이지가 멤버인 경우 + 사용자 본인인 경우 - 비밀번호 데이터 필요
				//2. 페이지가 멤버인 경우 + 관리자인 경우 - 비밀번호 빈값, input disabled
				//3. 페이지가 시작하기인 경우 + 관리자 - 비밀번호 노출, 변경가능
				if (this.page == 'create' && this.userInfo.isMaster == true) {
					this.info.password = item.password
				} else if (this.page == 'member' || (this.page == 'create' && this.userInfo.isMaster == false)) {
					this.info.password = null
				} else if (this.page == 'excel' && this.mode == 'edit' && !this.$route.query.page) {
					this.info.password = this.password
				}

				item.permissions.forEach(x => {
					this.permissions.push({
						id: x.id,
						name: x.name,
					})
				})
				this.selectedAuthorityList = this.permissions

				if (this.page == 'excel') {
					item.groups.forEach(x => {
						this.groupList.push({
							id: x.id,
							name: x.fullPathName,
							fullPathName: x.fullPathName,
						})
					})
				} else {
					item.groups.forEach(x => {
						this.groupList.push({
							id: x.id,
							name: x.name,
						})
					})
				}
			} else {
				this.info = {
					email: null,
					password: this.password,
					name: null,
					phone: null,
					permissionsId: [],
					groupsId: [],
				}
				if (this.permissions.length == 0) {
					this.permissions.push({
						id: this.authorityList[0].id,
						name: this.authorityList[0].name,
					})
					this.selectedAuthorityList = this.permissions
				}
			}

			if (this.groupList.length == 0) this.groupList.push({ id: '', name: '' })
		},
		async loadPermission() {
			const response = await institutePermissionAPI.lookupPermissionList(this.$route.params.instituteId, this.offset, this.length, this.keyword)
			this.authorityList = response.data.list
		},
		async loadInitPw() {
			const response = await instituteMemberAPI.lookupInitPw(this.$route.params.instituteId)

			this.password = response.data.password
		},
		close() {
			this.hide()
		},
		autoHypenTel(e) {
			const phoneNumber = Validation.autoHypenTel(e.target.value)
			this.setPhone(phoneNumber)
			this.isValidPhone = true
		},
		setPhone(phoneNumber) {
			this.info.phone = phoneNumber
		},
		addAuthority() {
			this.permissions.push({
				id: '',
				name: '',
			})
		},
		selectAuthority(select) {
			this.permissions[select.index] = {
				id: select.item.id,
				name: select.item.name,
			}

			this.selectedAuthorityList = this.permissions
		},
		deleteAuthority(select) {
			if (this.permissions.length < 2) {
				this.permissions = [
					{
						id: '',
						name: '',
					},
				]
			} else {
				this.permissions.splice(select.index, 1)
			}
		},
		addGroup() {
			if (this.groupList[0].id == '') this.groupList = []
			this.$refs['modal-select-group'].show(
				'GROUP',
				this.groupList.map(x => x.id),
				this.groupList
			)
		},
		editGroup(selected) {
			this.groupList = []
			const selectGroupList = selected.data.filter(x => {
				return x != undefined
			})
			selectGroupList.forEach(x => {
				if (this.page == 'excel') {
					this.groupList.push({ id: x.id, name: x.fullPathName })
				} else {
					this.groupList.push({ id: x.id, name: x.name })
				}
			})
		},
		deleteGroup(index) {
			this.groupList.splice(index, 1)
		},
		validEmail() {
			const emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i

			if (this.info.email == null) {
				this.validEmailText = '이메일을 입력해주세요'
				this.isValidEmail = false
			} else if (!emailRule.test(this.info.email)) {
				this.validEmailText = '이메일 형식이 맞지 않습니다'
				this.isValidEmail = false
			}
		},
		validPhone() {
			if (this.info.phone && this.info.phone.length >= 1) {
				if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.info.phone)) {
					this.isValidPhone = false
				}
			}
		},
		async complete() {
			await this.validEmail()
			await this.validPhone()
			this.info.permissionsId = []
			this.info.permissionsName = []
			this.info.groupsId = []
			this.info.groupsFullPathName = []

			this.permissions.forEach(x => {
				this.info.permissionsId.push(x.id)
				this.info.permissionsName.push(x.name)
			})
			this.groupList.forEach(x => {
				this.info.groupsId.push(x.id)
				this.info.groupsFullPathName.push(x.name)
			})

			if (!this.info.password || !this.info.name) {
				if (this.mode == 'add' || (this.page != 'member' && this.isAlmighty == false)) {
					if (this.info.password == null || this.info.password.length == 0) {
						this.isValidPw = false
						this.validPwText = '비밀번호를 입력해주세요'
					} else if (this.info.password.length < 6) {
						this.isValidPw = false
						this.validPwText = '비밀번호를 6자리 이상으로 입력해주세요'
					}
				}

				if (this.info.name == null || this.info.name.length == 0 || !this.info.name.replace(/\s/g, '')) {
					this.isValidName = false
				}
			}

			if (!this.isValidName || !this.isValidPw || !this.isValidEmail || !this.isValidPhone) {
				return false
			}

			if (this.mode == 'edit') {
				let response = null
				//전반적 편집시
				const formData = new FormData()
				formData.append('id', this.info.id)
				formData.append('name', this.info.name)
				formData.append('phone', this.info.phone)
				//멤버 페이지에서 관리자가 비밀번호 편집할 경우 비밀번호는 required 가 아닙니다
				//관리자 또한 사용자이기 때문에 사용자와 관리자의 이메일이 같을 경우에는 자신의 정보는 편집이 가능해야 합니다. (사용자도 자신의 정보 편집 필요)
				if (this.page != 'member' && this.isAlmighty == false) {
					formData.append('password', this.info.password)
				}
				for (let i = 0; i < this.info.permissionsId.length; i++) {
					formData.append(`permissionsId[${i}]`, this.info.permissionsId[i])
				}

				//멤버관리 - 멤버 편집 시
				if (this.page == 'member') {
					if (this.imageUrl) {
						formData.append('image', this.info.image)
					}
					for (let i = 0; i < this.info.groupsId.length; i++) {
						formData.append(`groupsId[${i}]`, this.info.groupsId[i])
					}

					if (this.info.groupsId.length == 0) {
						formData.append(`groupsId[0]`, [])
					}
				}

				//엑셀 일괄등록 멤버 정보 편집시
				if (this.page == 'excel') {
					this.info.permissionsName = this.info.permissionsName.join(',')
					this.info.groupsFullPathName = this.info.groupsFullPathName.join(',')
				} else {
					response = await instituteMemberAPI.updateMember(this.$route.params.instituteId, formData)
					if (response.status == 205) {
						this.$root.toast('정보 편집 완료', '멤버 정보가 편집되었습니다', 'success')
					}
				}
				this.$emit('update', this.info)
			} else {
				await instituteMemberAPI
					.addMember(this.$route.params.instituteId, this.info)
					.then(() => {
						this.$emit('complete', this.info, this.mode)

						this.$root.toast('멤버 등록 완료', '멤버가 등록되었습니다', 'success')
					})
					.catch(error => {
						if (error.response.data) {
							this.$root.toast('멤버 등록 실패', error.response.data.msg, 'error')
						}

						if (error.response.data.code == '12011') {
							this.validEmailText = error.response.data.msg
							this.isValidEmail = false
						}
					})
			}
		},
		showInitPw() {
			this.$refs['modal-notice-init-pw'].show()
		},
		async initPw(password) {
			this.info.password = password
			this.$refs['modal-notice-init-pw'].hide()
			this.isValidPw = true
			const formdata = new FormData()
			formdata.append('id', this.info.id)
			formdata.append('password', password)
			try {
				await this.updateMember({
					instId: this.$route.params.instituteId,
					body: formdata,
				})
				this.showToastCompleteInitPw()
			} catch (error) {
				this.showToastFailInitPw()
			}
		},
		showToastCompleteInitPw() {
			const title = this.tt('비밀번호 초기화 완료')
			const content = this.tt('비밀번호가 초기화되었습니다')
			this.$root.toast(title, content, ToastType.SUCCESS)
		},
		showToastFailInitPw() {
			const title = this.tt('비밀번호 초기화 실패')
			const content = this.tt('비밀번호가 초기화에 실패했습니다.')
			this.$root.toast(title, content, ToastType.ERROR)
		},
		uploadImage() {
			const file = this.$refs['imgFile'].files[0]
			const fReader = new FileReader()
			fReader.readAsDataURL(file)
			fReader.onloadend = event => {
				file.url = event.target.result
				this.imageUrl = file.url
			}
			this.info.image = file
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalRegisteringMember {
	.Popup__content {
		&.isOverflow {
			max-height: 70vh;
			overflow-y: auto;
		}
		> div {
			margin-top: 20px;
			&.Member__info-img {
				width: 100px;
				height: 100px;
				margin: 0 auto 20px;
				border-radius: 50px;
				overflow: hidden;
				cursor: pointer;
				position: relative;
				cursor: pointer;
				img {
					width: inherit;
					height: inherit;
					object-fit: cover;
				}
				input {
					width: inherit;
					height: inherit;
					display: block;
					position: absolute;
					top: 0;
					opacity: 0;
					cursor: pointer;
				}
			}
			&:nth-child(1) {
				margin-top: 0;
			}
			.flex {
				input {
					margin-right: 10px;
				}
				.btn {
					background-color: $GREY_2;
					min-width: 100px;
				}
			}
			&.error {
				input {
					border-color: $RED;
				}
				> span {
					display: block;
				}
			}
			> span {
				font-size: 0.75rem;
				color: $RED;
				font-weight: 400;
				position: relative;
				height: 17px;
				display: none;
				padding-left: 16px;
				padding-top: 2px;
				&:before {
					position: absolute;
					width: 12px;
					height: 12px;
					content: url(~@/assets/svg/noti-error.svg);
					top: 4px;
					left: 0;
				}
			}
		}
	}
}
</style>
