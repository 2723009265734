var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "detail-wrap"
  }, [_c('detail-header', {
    attrs: {
      "item": _vm.item
    }
  }), _c('div', {
    staticClass: "product-info-wrap"
  }, [_vm.isIng ? _c('p', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.tt('AI 기술을 통한 시약정보 자동보정기술과 전문 검수원의 철저한 검수를 통해 정확한 정보로 물품을 등록해드립니다')) + " ")]) : _vm._e(), _vm.isFail ? _c('ul', {
    staticClass: "description"
  }, _vm._l(_vm.failMemos, function (memo, idx) {
    return _c('li', {
      key: idx,
      staticClass: "fail-memo"
    }, [_vm._v(" " + _vm._s(memo) + " ")]);
  }), 0) : _vm._e(), _c('member-info', {
    attrs: {
      "item": _vm.item
    }
  }), _vm.item.registerMethod === 'AUTO_IMAGE' && _vm.item.imageList ? _c('image-field', {
    attrs: {
      "images": _vm.item.imageList.map(function (i) {
        return i.link;
      })
    }
  }) : _vm._e(), _c('product-info', {
    attrs: {
      "item": _vm.item
    }
  }), _c('management-info', {
    attrs: {
      "item": _vm.item,
      "managementFields": _vm.managementFields
    }
  }), _vm.hasCustomField ? _c('custom-info', {
    attrs: {
      "item": _vm.item,
      "customFields": _vm.customFields,
      "customFieldsData": _vm.customFieldsData
    }
  }) : _vm._e()], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }