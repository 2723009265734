const state = {
	imageList: null,
	removeImageLink: null,
}
const getters = {
	imageList: state => state.imageList,
	removeImageLink: state => state.removeImageLink,
}
const mutations = {
	initImage: () => {
		state.imageList = null
		state.removeImageLink = null
	},
	setImageList: (state, imageList) => {
		state.imageList = imageList
	},
	setRemoveImageLink: (state, removeImageLink) => {
		state.removeImageLink = removeImageLink
	},
}
const actions = {}
export default { namespaced: true, state, getters, mutations, actions }
