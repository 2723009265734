<template>
	<b-modal centered scrollable hide-footer @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('관리번호 가이드') }}</div>
		</template>
		<template>
			<div class="boxwrap">
				<h5>{{ tt('1. 물품관리번호(MNG Code)의 구성') }}</h5>
				<div class="yellow-box">
					<h6>
						{{ tt('물품관리번호: A - A - AA - 21 - 11') }}
					</h6>
					<div class="flex management">
						<div>
							<b>A </b>
							<p>1st<br />{{ tt('관리항목') }}</p>
						</div>
						<div>
							<b>A </b>
							<p>2nd<br />{{ tt('관리항목') }}</p>
						</div>
						<div>
							<b>AA </b>
							<p>3rd<br />{{ tt('관리항목') }}</p>
						</div>
						<div>
							<b>21 </b>
							<p>4th<br />{{ tt('관리항목') }}</p>
						</div>
						<div>
							<b>11 </b>
							<p>5th<br />{{ tt('관리항목') }}</p>
						</div>
					</div>
				</div>
				<div class="description">
					<p v-html="tt(`[관리번호]는 [관리항목]이 순차적으로 배열되어 만들어집니다.<br />(최대 5개로 구성 가능) <br />`)"></p>
					<p
						v-html="
							tt(
								`예를들어 [등록 연원일] - [카테고리] - [등록 순서] - [보관함] - [물품명 앞 두글자]의 순서로 관리번호를 구성하였 때 관리번호는 230327 - 시약 - 806 - A보관함 - Ac 로 구성되게 됩니다.`
							)
						"
					></p>
				</div>
			</div>

			<div class="boxwrap">
				<h5>{{ tt('2. 관리항목의 구성') }}</h5>
				<div class="yellow-box">
					<div class="flex">
						<div>
							<p>{{ tt('별칭') }}</p>
							<b>A</b>
						</div>
						<div>
							<p>{{ tt('내용') }}</p>
							<b>{{ tt('위험물 보관소') }}</b>
						</div>
					</div>
				</div>
				<div class="description">
					<p v-html="tt('각 관리항목은 [별칭]과 [내용]으로 구성되어 있습니다.')"></p>
					<p v-html="tt('[별칭]은 숫자 또는 텍스트(최대 6자리)로 구성할 수 있습니다')"></p>
					<p v-html="tt('[내용]은 별칭의 원래 명칭이므로 수정할 수 없습니다.')"></p>
				</div>
			</div>
			<div class="boxwrap">
				<h5>{{ tt('3. 관리항목의 별칭 편집하기') }}</h5>
				<div class="summary">
					<div class="black-box">
						<b>{{ tt('스잭') }}</b>
						<span class="cursor"></span>
					</div>
					<p>{{ tt('스마트잭연구실') }}</p>
				</div>
				<div class="description">
					<p v-html="tt('[별칭]으로 표시되는 관리항목(그룹, 카테고리, 보관위치)은 별칭 영역을 클릭하여 바로 편집할 수 있습니다.')"></p>
				</div>
			</div>
			<div class="boxwrap">
				<h5>{{ tt('4. 관리번호 순서 변경하기') }}</h5>
				<div class="Item__classification-list">
					<div class="pointer">
						<img src="@/assets/svg/Combined Shape.svg" alt="arrow-icon" />
					</div>
					<div class="Item__classification-item">
						<h5>
							<button>
								<img src="@/assets/svg/draggable.svg" alt="icon" />
							</button>
							{{ tt('그룹') }}
							<button class="btn">
								<img src="@/assets/svg/setting-2.svg" alt="icon" />
							</button>
						</h5>
						<ul>
							<li>
								<h6>L1</h6>
								<p>{{ tt('스마트잭연구실') }}</p>
							</li>
							<li>
								<h6>L2</h6>
								<p>{{ tt('스마트잭연구실') }}</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="description">
					<p v-html="tt('각 관리항목 타이틀 좌측의 아이콘(|||)을 드래그하여 순서를 변경할 수 있습니다.')"></p>
					<p
						v-html="
							tt(
								'주의) 기 등록된 관리번호는 추후 설정에서 관리번호 구성항목 및 순서를 변경해도 소급하여 반영(변경)되지 않습니다. 또한 관리번호 중 ‘보관함’의 경우 등록 후 보관함을 이동하더라도 그 값이 변경되지 않습니다.'
							)
						"
					></p>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	border: 0;
}
.boxwrap {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 30px;
	font-size: 14px;
	h5 {
		color: #555;
		font-size: 16px;
	}
	.yellow-box {
		width: 100%;
		background: $SUB-YELLOW_100;
		border-radius: 10px;
		padding: 1rem;
		h6 {
			font-size: 0.875rem;
			padding-bottom: 1rem;
		}
		.flex {
			&.management {
				div {
					margin-right: 0;
					width: 20%;
				}
			}
			div {
				font-size: 0.875rem;
				margin-right: 30px;
				b {
					display: inline-block;
					border-bottom: 2px solid $DEFAULT;
					font-weight: 700;
					margin-bottom: 7px;
					padding-bottom: 1px;
				}
				p {
					font-size: 0.75rem;
					display: block;
					color: rgba(0, 0, 0, 0.87);
					margin-top: 10px;
				}
			}
		}
		&:nth-child(2) {
			.flex {
				div {
					p {
						margin-bottom: 7px;
					}
				}
			}
		}
	}
	.description {
		p {
			position: relative;
			padding-left: 16px;
			&::before {
				position: absolute;
				left: 4px;
				top: 8px;
				content: '';
				display: inline-block;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: #111;
			}
		}
	}
	.summary {
		width: 250px;
		height: 40px;
		box-sizing: border-box;
		border-top: 1px solid #e4e5ee;
		border-bottom: 1px solid #e4e5ee;
		display: flex;
		align-items: center;
		.black-box {
			width: 75px;
			height: 30px;
			border: 1px solid #000;
			border-radius: 2px;
			padding: 5px 10px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			b {
				font-size: 0.875rem;
				font-weight: 700;
			}
			span {
				display: inline-block;
				width: 2px;
				height: 14px;
				background: #007aff;
				margin-left: 3px;
			}
		}
		p {
			font-weight: 400;
			font-size: 0.875rem;
			padding-left: 9px;
		}
	}
	.Item__classification-list {
		width: 290px;
		padding: 20px;
		border: 1px solid $DIVIDER;
		border-radius: 8px;
		position: relative;
		.pointer {
			position: absolute;
			top: 5px;
			left: 5px;
			border: 4px solid $LAB_YELLOW;
			border-radius: 32px;
			width: 64px;
			height: 64px;
			box-sizing: border-box;
			img {
				position: absolute;
				top: 30px;
				left: 30px;
			}
		}
		.Item__classification-item {
			width: 100%;
			border: 1px solid $DIVIDER;
			border-radius: 8px;
			overflow: hidden;
			h5 {
				padding: 5px;
				box-sizing: border-box;
				height: 40px;
				background: $GREY_1;
				width: 100%;
				border-radius: 8px 8px 0 0;
				display: flex;
				align-items: center;
				font-size: 1rem;
				color: #000;
				font-weight: 700;
				button {
					padding: 0 0 0 6px;
				}
				.btn {
					margin-left: auto;
					width: 30px;
					height: 30px;
					background: $GREY_4;
					border-radius: 4px;
					padding: 0;
					img {
						margin: 0 auto;
						padding-right: 0;
					}
				}
			}
			ul {
				max-height: 56vh;
				overflow-y: auto;
				li {
					height: 40px;
					box-sizing: border-box;
					border-bottom: 1px solid #e4e5ea;
					background-color: $LAB_WHITE;
					width: 100%;
					display: flex;
					align-items: center;
					padding: 10px;
					h6 {
						font-size: 1rem;
						color: #000;
						font-weight: 700;
						width: 70px;
					}
					p {
						font-size: 0.875rem;
						color: #000;
						font-weight: 400;
					}
					&:last-child {
						border-bottom: 0;
					}
				}
			}
		}
	}
}
</style>
