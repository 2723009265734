import lmsAPI from '@/services/api/purchase/lms'
import user from './user'
import businessRegistration from './businessRegistration'

export default {
	namespaced: true,
	modules: {
		user,
		businessRegistration,
	},
	state: {
		lmsInstituteId: null,
		lmsInstitute: null,
		deliveryList: [],
		requirement: null,
		nopaidList: [],
	},
	mutations: {
		setInstitute: (state, data) => {
			state.lmsInstitute = data
			state.lmsInstituteId = data.id
		},
		setDeliveryList: (state, list) => {
			state.deliveryList = list
		},
		setRequirement: (state, data) => {
			state.requirement = data
		},
		setNopaidList: (state, list) => {
			state.nopaidList = list
		},
	},
	actions: {
		getLMSInstituteId: async ({ commit, rootGetters }) => {
			if (rootGetters.instituteId) {
				const response = await lmsAPI.institute(rootGetters.instituteId)
				if (response.status == 200) {
					commit('setInstitute', response.data)
				}
			}
		},
		getDeliveryList: async ({ commit, rootGetters }) => {
			const response = await lmsAPI.deliveryList(rootGetters.instituteId)
			commit('setDeliveryList', response.data)
		},
		addAddress: async ({ rootGetters, dispatch }, body) => {
			const response = await lmsAPI.addAddress(rootGetters.instituteId, body)
			dispatch('getDeliveryList')
			return response
		},
		updateAddress: async ({ rootGetters, dispatch }, payload) => {
			const { body } = payload
			const response = await lmsAPI.updateAddress(rootGetters.instituteId, body)
			dispatch('getDeliveryList')
			return response
		},
		deleteAddress: async ({ rootGetters, dispatch }, params) => {
			const response = await lmsAPI.deleteAddress(rootGetters.instituteId, params)
			dispatch('getDeliveryList')
			return response
		},
		getRequirement: async ({ commit, rootGetters, state }) => {
			const response = await lmsAPI.requirement(rootGetters.instituteId, state.lmsInstituteId)
			commit('setRequirement', response.data)
		},
		getNopaidList: async ({ commit, rootGetters }) => {
			const response = await lmsAPI.nopaidSummary(rootGetters.instituteId)
			commit('setNopaidList', response.data)
		},
		getOrderInfo: async ({ commit, rootGetters }, params) => {
			const response = await lmsAPI.orderInfo(rootGetters.instituteId, params)
			return response.data
		},
		getPromotionBrandList: async ({ rootGetters }) => {
			const response = await lmsAPI.getPromotionBrandList(rootGetters.instituteId)
			return response.data
		},
	},
}
