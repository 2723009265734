<template>
	<div class="Filter__wrap">
		<div class="flex">
			<h5>{{ name }}</h5>
			<button class="Btn__common ml-auto" @click="allCheck">
				{{ tt('전체선택') }}
			</button>
			<button class="Btn__common" @click="reset" style="margin-left: 10px">
				{{ tt('초기화') }}
			</button>
		</div>
		<ul class="Filter__list" :class="listMore == true ? 'unfold' : 'folded'">
			<li
				class="Filter__item"
				v-for="(item, index) in value"
				:key="`filter-item${name == '폐기자' ? '-dumper' : ''}-${valueKey ? item[valueKey] : item.value}${index}`"
			>
				<input
					type="checkbox"
					:id="`filter-${name == '폐기자' ? '-dumper' : ''}${valueKey ? item[valueKey] : item.value}${index}`"
					v-model="item.checked"
				/>
				<img
					v-if="name == '담당자' || name == '등록자' || name == '폐기/삭제자' || name == '멤버'"
					:src="item.image ? item.image : require('@/assets/svg/member-photo-default.svg')"
					style="margin-top: -2px"
				/>
				<label
					v-b-tooltip.hover="textKey ? tt(item[textKey]) : tt(item.text)"
					:for="`filter-${name == '폐기자' ? '-dumper' : ''}${valueKey ? item[valueKey] : item.value}${index}`"
					>{{ textKey ? tt(item[textKey]) : tt(item.text) }}</label
				>
			</li>
		</ul>
		<button
			v-if="value.length > 9"
			class="more"
			@click="listMore = !listMore"
			:class="listMore ? 'unfold' : 'folded'"
			v-text="listMore ? tt('접기') : tt('모두보기')"
		></button>
	</div>
</template>

<script>
export default {
	name: 'FilterList',
	props: ['name', 'value', 'textKey', 'valueKey'],
	data() {
		return {
			listMore: false,
		}
	},
	mounted() {
		this.listFold()
	},
	methods: {
		listFold() {
			const valueArr = this.value.slice(10, this.value.length)
			const checkedArr = []

			valueArr.forEach(x => {
				checkedArr.push(x.checked)
			})

			if (checkedArr.indexOf(true) >= 0) {
				this.listMore = true
			}
		},
		allCheck() {
			this.value.forEach(el => {
				el.checked = true
			})
			this.$emit('input', this.value)
		},
		reset() {
			this.value.forEach(el => {
				el.checked = false
			})
		},
		getCheckedList() {
			const result = []
			this.value.forEach(el => {
				if (el.checked) {
					result.push(el)
				}
			})

			return result
		},
	},
}
</script>
