<template>
	<div class="page-wrapper">
		<div class="info-wrap">
			<h1>{{ tt('상품주문이 완료되었습니다') }}</h1>
			<b>{{ tt('주문번호 : ${1}', orderNumber) }}</b>
			<b>{{ tt('주문자 이메일 : ${1}', ordererEmail) }}</b>
			<span
				v-html="
					tt(
						`랩매니저 스토어에 상품 주문이 접수되었습니다.<br>'주문 상세 화면으로 이동'을 클릭 하시면 주문 정보를 확인할 수 있습니다.<br>※ 상품대금 결제는 주문자 승인 이후 가능 합니다.`
					)
				"
			></span>
			<div class="button-wrap">
				<base-button style="height: 47px" @click="goOrder">{{ tt('상품 추가 주문하기') }}</base-button>
				<base-button style="height: 47px" :color="'primary'" @click="goStore">{{ tt('주문상세 화면으로 이동') }}</base-button>
			</div>
		</div>
		<img src="@/assets/svg/store/order-success.svg" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
export default {
	data() {
		return {
			orderNumber: 'gd',
			ordererEmail: 'gdd3',
		}
	},
	mounted() {
		this.initOrderInfo()
	},
	methods: {
		...mapActions('lms', ['getOrderInfo']),
		async initOrderInfo() {
			const response = await this.getOrderInfo({ orderId: this.$route.params.id })
			this.orderNumber = response?.orderNo
			this.ordererEmail = response?.orderer?.email ?? 'email이 없습니다.'
		},
		goOrder() {
			this.$router.push({ name: PageName.Purchase.Product.Order })
		},
		goStore() {
			this.$root.goToStore(`mypage/order/${this.$route.params.id}`)
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	display: flex;
	justify-content: space-between;
	padding: 70px;
	& > img {
		width: 320px;
		height: 320px;
		margin-right: 150px;
	}
	.info-wrap {
		padding: 30px 0 0 50px;
		text-align: left;
		display: flex;
		flex-direction: column;
		gap: 10px;
		& > h1 {
			font-weight: 700;
			margin-bottom: 20px;
		}
		.button-wrap {
			display: flex;
			gap: 10px;
			margin-top: 20px;
			& > button {
				width: 200px;
				height: 36px;
			}
		}
	}
}
</style>
