var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-input', {
    ref: "base-input",
    attrs: {
      "type": "number",
      "value": _vm.value,
      "placeholder": _vm.placeholder,
      "min": _vm.min,
      "max": _vm.max,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "autofocus": _vm.autofocus,
      "error": !_vm.isValid || _vm.error,
      "errorMessage": _vm.errorMessage,
      "inputStyle": _vm.inputStyle
    },
    on: {
      "input": _vm.onInput,
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "keydown": function keydown($event) {
        return _vm.$emit('keydown', $event);
      },
      "keyup": function keyup($event) {
        return _vm.$emit('keyup', $event);
      },
      "click": function click($event) {
        return _vm.$emit('click', $event);
      },
      "focus": function focus($event) {
        return _vm.$emit('focus', $event);
      },
      "blur": function blur($event) {
        return _vm.$emit('blur', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }