import inventoryAPI from '@/services/api/inventory/gen2'
import cookies from 'vue-cookies'
import { disposalViewOption } from '@/utils/define/ViewOption'

// TODO: 페이지 리팩토링 후 제대로 사용 ( 현재는 exportExcel만 )

const state = {
	list: [],
	count: 0,
	loadParams: {
		offset: 0,
		length: 50,
		order: ['DUMP_DATETIME'],
		orderDirection: ['DESC'],
		keyword: '',
		startDatetime: '',
		endDatetime: '',
		status: JSON.parse(cookies.get('filter_disposal_status')) || [],
		categoriesId: JSON.parse(cookies.get('filter_disposal_categoriesId')) || [],
		assigneesId: JSON.parse(cookies.get('filter_disposal_assigneesId')) || [],
		dumpersId: JSON.parse(cookies.get('filter_disposal_dumpersId')) || [],
	},
	selectedItems: [],
	checkedViewOption: JSON.parse(cookies.get('viewOption_disposal')) ?? [],
	viewOptionCookieName: 'viewOption_disposal',
	viewOptionList: disposalViewOption,
	filter: null,
	filterCookieName: {
		status: 'filter_disposal_status',
		categoriesId: 'filter_disposal_categoriesId',
		assigneesId: 'filter_disposal_assigneesId',
		dumpersId: 'filter_disposal_dumpersId',
	},
	recentPageName: null,
}

const getters = {
	filter: state => state.filter,
	filteredCount: state =>
		state.loadParams.status.length +
		state.loadParams.categoriesId.length +
		state.loadParams.assigneesId.length +
		state.loadParams.dumpersId.length,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
	isEmptySelectedItems: state => state.selectedItems.length === 0,
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setLoadParams: (state, loadParams) => {
		console.log(loadParams)
		Object.keys(loadParams).forEach(key => {
			if (loadParams[key] == null) {
				delete state.loadParams[key]
			} else state.loadParams[key] = loadParams[key]
		})
	},
	setSelectedItems: (state, data) => {
		state.selectedItems = data
	},
	setViewOptions: (state, list) => {
		cookies.set(state.viewOptionCookieName, JSON.stringify(list))
		state.checkedViewOption = list
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	initFilterCookies: state => {
		Object.values(state.filterCookieName).forEach(key => {
			cookies.set(key, JSON.stringify([]))
		})
		state.loadParams = {
			offset: 0,
			length: 50,
			order: ['DUMP_DATETIME'],
			orderDirection: ['DESC'],
			keyword: '',
			startDatetime: '',
			endDatetime: '',
			status: JSON.parse(cookies.get('filter_disposal_status')) || [],
			categoriesId: JSON.parse(cookies.get('filter_disposal_categoriesId')) || [],
			assigneesId: JSON.parse(cookies.get('filter_disposal_assigneesId')) || [],
			dumpersId: JSON.parse(cookies.get('filter_disposal_dumpersId')) || [],
		}
	},
	setPageName: (state, name) => {
		state.recentPageName = name
	},
}

const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		const response = await inventoryAPI.disposal(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	getFilter: async ({ commit, rootGetters }) => {
		const response = await inventoryAPI.disposalFilter(rootGetters.instituteId)
		commit('setFilter', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		await dispatch('getList')
	},
	restoreItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.restoreItem(rootGetters.instituteId, body)
		dispatch('getList')
	},
	removeItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.removeDisposal(rootGetters.instituteId, body)
		dispatch('getList')
	},
	exportExcelDisposeList: async ({ getters, rootGetters }, payload) => {
		const response = await inventoryAPI.exportExcelDisposeList(rootGetters.instituteId, payload)
		return response
	},
}

export default { namespaced: true, state, getters, mutations, actions }
