<template>
	<div id="startPro">
		<div class="page-header">
			<label v-for="item in tabList" :key="item.mode" :class="[{ active: item.mode == mode }, { disabled: item.mode > beginStep }]">
				<input type="radio" v-model="mode" :value="item.mode" :disabled="item.mode > beginStep" />
				<img :src="require('@/assets/svg/' + item.image)" />
				{{ tt(item.name) }}
			</label>
			<!-- <user-info-box></user-info-box> -->
		</div>
		<registering-member ref="registeringMember" v-if="mode == null"></registering-member>
		<registering-organization
			ref="registering-organization"
			v-else-if="mode == 1"
			:mode="mode"
			:displayNoticeOrganization="notice.organization"
			:userPermission="userPermission"
			@hideNoticeOrganization="hideNoticeOrganization"
			@complete="complete"
			@nextStep="nextStep"
		></registering-organization>
		<registering-location
			v-else
			:displayNoticeLocation="notice.location"
			:displayNoticeStorage="notice.storage"
			@hideNoticeLocation="hideNoticeLocation"
			@hideNoticeStorage="hideNoticeStorage"
			:mode="mode"
			@complete="complete"
			@nextStep="nextStep"
		></registering-location>
		<modal-well-done ref="modal-well-done" :content="content" :button="buttonTxt" :mode="mode" @nextStep="nextStep"></modal-well-done>

		<div class="fixed-btn" @click="nextStep">
			<button class="Btn__yellow">
				{{ tt(nextTxt) }}
			</button>
			<button class="Btn__skip" @click="goDashboard">
				{{ tt('초기설정 건너뛰기') }}
			</button>
		</div>
	</div>
</template>

<script>
import registeringMember from '@/views/creation/pages/registeringMember.vue'
import registeringOrganization from '@/views/creation/pages/registeringOrganization.vue'
import registeringLocation from '@/views/creation/pages/registeringLocation.vue'
import instituteInformationAPI from '@/services/api/institute/information'
import userAPI from '@/services/api/institute/user'
import userInfoBox from '@/views/creation/pages/components/userInfoBox.vue'
import ModalWellDone from '@/views/creation/modals/ModalWellDone.vue'
import PageName from '@/utils/define/PageName'
import axiosInstance from '@/services/axios'
import { mapActions } from 'vuex'

export default {
	props: {},
	components: {
		registeringMember,
		registeringOrganization,
		registeringLocation,
		userInfoBox,
		ModalWellDone,
	},
	async created() {
		try {
			await this.loadUserPermission()
			await this.getInstInfo()
			await this.loadUserInfo()
			await this.loadUserInstituteInfo()
			await this.loginUser()
		} catch (error) {}
	},
	async mounted() {
		if (this.$route.query.isStart) {
			this.mode = null
		} else {
			this.mode = this.beginStep
		}

		if (this.beginStep > 3) {
			this.mode = 3
		}
	},
	data() {
		return {
			mode: null,
			beginStep: null,
			tabList: [
				{
					name: '멤버',
					mode: null,
					image: 'tab-icon-member.svg',
				},
				{
					name: '조직도',
					mode: 1,
					image: 'Groups.svg',
				},
				{
					name: '위치',
					mode: 2,
					image: 'tab-icon-location.svg',
				},
				{
					name: '보관함',
					mode: 3,
					image: 'add-storage.svg',
				},
			],
			notice: {
				organization: true,
				location: true,
				storage: true,
			},
			completeStep: {
				organization: false,
				location: false,
				storage: false,
			},
			userPermission: {},
		}
	},
	computed: {
		content() {
			if (this.mode == 1) {
				return '이어서 조직도를 완성해보세요'
			} else if (this.mode == 2) {
				return '기관의 건물위치(구조)를 반영하여 완성해보세요'
			} else if (this.mode == 3) {
				return '초기 설정이 모두 완료되었습니다'
			}
		},
		buttonTxt() {
			if (this.mode == 1) {
				return '조직도 설정 계속하기'
			} else if (this.mode == 2) {
				return '위치 등록 계속하기'
			} else if (this.mode == 3) {
				return '보관함 추가 계속하기'
			}
		},
		nextTxt() {
			if (this.mode == 1) {
				return '조직도 설정 완료'
			} else if (this.mode == 2) {
				return '위치 등록 완료'
			} else if (this.mode == 3) {
				return '랩매니저 Pro 시작하기'
			} else {
				return '멤버 등록 완료'
			}
		},
	},
	methods: {
		...mapActions('user', ['loadUserInfo', 'loadUserInstituteInfo', 'loginUser']),
		hideNoticeOrganization() {
			this.notice.organization = false
		},
		hideNoticeLocation() {
			this.notice.location = false
		},
		hideNoticeStorage() {
			this.notice.storage = false
		},
		async getInstInfo() {
			const response = await instituteInformationAPI.lookupInstInfo(this.$route.params.instituteId)

			this.beginStep = response.data.info.beginStep
		},
		async loadUserPermission() {
			//임시 권한 설정
			const response = await userAPI.lookupUserPermission()

			this.userPermission = response.data
		},
		changeStep(mode) {
			const formData = new FormData()
			formData.append('beginStep', mode)

			instituteInformationAPI.editInstInfo(this.$route.params.instituteId, formData)
		},
		async complete() {
			if (this.mode == 1) {
				if (!this.completeStep.organization) {
					await this.$refs['modal-well-done'].show()
				}
				this.completeStep.organization = true
			} else if (this.mode == 2) {
				if (!this.completeStep.location) {
					await this.$refs['modal-well-done'].show()
				}
				this.completeStep.location = true
			} else if (this.mode == 3) {
				if (!this.completeStep.storage) {
					await this.$refs['modal-well-done'].show()
				}
				this.completeStep.storage = true
			}
		},
		async nextStep() {
			if (this.mode == null) {
				await this.changeStep(1)
				this.mode = 1
			} else if (this.mode == 1) {
				await this.changeStep(2)
				this.mode = 2
			} else if (this.mode == 2) {
				await this.changeStep(3)
				this.mode = 3
			} else if (this.mode == 3) {
				await this.changeStep(4)
				this.$router.push({
					name: 'Dashboard',
					params: {
						instituteId: this.$route.params.instituteId,
					},
				})
			}
			await this.getInstInfo()
		},
		async goDashboard() {
			this.mode = 4
			await this.changeStep(4)
			this.$router.push({
				name: PageName.Dashboard,
				params: {
					instituteId: this.$route.params.instituteId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
//temp
#startPro {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 101;
	top: 0;
	left: 0;
	background-color: $LAB_WHITE;
	> div {
		margin-top: 80px;
	}
}

#startPro {
	color: #000;
	height: 100%;
	.page-header {
		width: 100%;
		margin: 0 auto;
		position: fixed;
		top: 0;
		height: 58px;
		z-index: 102;
		background-color: $LAB_WHITE;
		display: flex;
		align-items: center;
		justify-content: center;
		label {
			display: inline-flex;
			align-items: center;
			color: #000;
			min-width: 76px;
			padding: 0 15px;
			height: 28px;
			border-radius: 30px;
			background-color: $GREY_4;
			margin: 0 5px;
			color: #000;
			font-size: 0.875rem;
			font-weight: 700;
			box-sizing: border-box;
			cursor: pointer;
			transition: 0.3s all ease;
			input[type='radio'] {
				width: 0;
				height: 0;
				visibility: hidden;
				opacity: 0;
				padding: 0;
				margin: 0;
			}
			&.disabled {
				color: $PLACEHOLDER;
				font-weight: 400;
				img {
					opacity: 0.4;
				}
				&:hover {
					background-color: $GREY_4 !important;
					font-weight: 400;
				}
			}
			&:hover {
				background-color: #dadbdc !important;
				transition: 0.3s all ease;
				font-weight: 700;
				color: $PLACEHOLDER;
			}
			&.active {
				background: linear-gradient(95.59deg, $LAB_YELLOW -0.51%, #ffa90b 100.51%);
				color: #000;
				font-weight: 700;
				img {
					opacity: 1;
				}
			}
			&.complete {
				color: #000;
				font-weight: 700;
			}
		}
	}
	.fixed-btn {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		height: 78px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid $DIVIDER;
		background-color: $LAB_WHITE;
		.Btn__yellow {
			width: 195px;
			height: 48px;
			font-size: 1rem;
			font-weight: 700;
		}
		.Btn__skip {
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translate(0, -50%);
			font-size: 1rem;
			color: $SECONDARY_2;
			font-weight: 700;
			padding-right: 20px;
			background-image: url(~@/assets/svg/arrow-right.svg);
			background-repeat: no-repeat;
			background-position: center right 0;
		}
	}
}
</style>
