import { render, staticRenderFns } from "./TotalAmount.vue?vue&type=template&id=531b4a41&scoped=true&"
import script from "./TotalAmount.vue?vue&type=script&lang=js&"
export * from "./TotalAmount.vue?vue&type=script&lang=js&"
import style0 from "./TotalAmount.vue?vue&type=style&index=0&id=531b4a41&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531b4a41",
  null
  
)

export default component.exports