var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.tt('라이선스 주문 완료')) + " ")];
      },
      proxy: true
    }])
  }, [[_c('img', {
    attrs: {
      "src": require("@/assets/images/check.png")
    }
  }), _c('div', {
    staticClass: "license-summary"
  }, [_c('img', {
    staticClass: "imgbox",
    attrs: {
      "src": require("@/assets/images/lm-pro-icon.png")
    }
  }), _c('div', {
    staticClass: "txtbox"
  }, [_c('h6', [_vm._v(_vm._s(_vm.licenseTypeText))]), _vm.isEducation ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('${1}원 / 연구실당 (연 기준 ・ VAT 별도)', _vm.formatNumberLocale(_vm.licenseRatePlan['YEAR']['EDUCATION']))) + " ")]) : _c('p', [_vm._v(" " + _vm._s(_vm.tt('${1}원 / 라이선스 당(월 기준 ・ VAT 별도)', _vm.formatNumberLocale(_vm.getAdditonalPrice))) + " ")])])]), _c('div', {
    staticClass: "license-info"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('라이선스 정보')))]), _c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('상태')))]), _c('div', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "license-status",
    "class": {
      expired: !_vm.isLicenseActivate
    }
  }, [_vm._v(" " + _vm._s(_vm.licenseStatusText) + " ")]), _vm.isLicenseDepositWait ? _c('span', {
    staticClass: "waiting-status"
  }, [_vm._v(" " + _vm._s(_vm.tt('입금대기')) + " ")]) : _vm._e()])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('라이선스 만료일')))]), _c('span', {
    "class": {
      red: !_vm.isLicenseActivate,
      bold: !_vm.isLicenseActivate
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDatetime(_vm.license.expireDate)) + " ")])]), _vm.isFreeTier ? _c('li', [_c('p', [_vm._v(" " + _vm._s(_vm.tt('멤버 수')) + " ")]), _c('span', {
    staticClass: "license-member"
  }, [_vm._v(" " + _vm._s(_vm.formatNumberLocale(_vm.memberCount)) + " ")])]) : _c('li', [_c('p', [_vm._v(" " + _vm._s(_vm.tt('구매한 라이선스 수')) + " ")]), _c('span', {
    staticClass: "licese-member"
  }, [_c('span', {
    staticClass: "license-count"
  }, [_vm._v(" " + _vm._s(_vm.purchaseCompleteInfo.quantity == _vm.MAX_QUANTITY ? _vm.tt('무제한') : _vm.formatNumberLocale(_vm.purchaseCompleteInfo.quantity)) + " ")])])])])]), _c('PaymentStatus'), _c('div', {
    staticClass: "modal-footer"
  }, [_c('button', {
    staticClass: "button-primary",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('확인')) + " ")])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }