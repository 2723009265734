import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'

const routes = [
	{
		name: PageName.Budget.List,
		path: RoutePath.Budget.List,
		props: true,
		component: () => import('@/views/budget/list/index'),
	},
	{
		name: PageName.Budget.Status,
		path: RoutePath.Budget.Status,
		props: true,
		component: () => import('@/views/budget/status'),
	},
	{
		name: PageName.Budget.Create,
		path: RoutePath.Budget.Create,
		props: true,
		component: () => import('@/views/budget/create'),
	},
	{
		name: PageName.Budget.Edit,
		path: RoutePath.Budget.Edit,
		props: true,
		component: () => import('@/views/budget/create'),
	},
	{
		name: PageName.Budget.Detail,
		path: RoutePath.Budget.Detail,
		props: true,
		component: () => import('@/views/budget/detail'),
	},
]

export default routes
