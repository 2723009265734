<template>
	<div class="use-wrap">
		<div class="input-head-text">
			<p>{{ tt('사용횟수') }}</p>
			<p>
				<span class="volume-text">{{ tt(`현재 사용횟수`) }}</span> :
				<span class="current-volume">{{ tt('${1}회', useCount) }}</span>
			</p>
		</div>
		<div class="input-container">
			<div class="input-wrap">
				<input
					class="input-use"
					:class="{ error: isShowVolumeError && isInputVolumeOver }"
					:value="useValue"
					:placeholder="tt(`사용횟수를 입력해주세요`)"
					@input="handleInput"
				/>
				<span class="input-unit">{{ tt('회') }}</span>
			</div>
		</div>
		<p class="error-msg" v-if="isShowVolumeError && isInputVolumeOver">
			<img src="@/views/inventoryGen2/components/svg/icon-error.svg" />
			{{ tt(`사용횟수를 입력해주세요.`) }}
		</p>
	</div>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
import Decimal from 'decimal.js'
import { verifyInteger } from '@/utils/modules/VerifyForm.js'
export default {
	name: 'ChromatograhpyInput',
	components: {},
	mixins: [MixinItem],
	props: {
		useCount: {
			type: [Number, String],
			default: null,
		},
		volumeUnit: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			useValue: null,
			isShowVolumeError: false,
		}
	},
	computed: {
		isInputVolumeOver() {
			if (typeof this.useValue === 'string') {
				return this.numberUseValue > this.volume
			} else {
				return this.useValue > this.volume
			}
		},
		isInputVolumeEmpty() {
			return this.useValue === null || this.useValue == 0
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		init() {
			this.useValue = null
			isShowVolumeError = false
		},
		useAll() {
			this.useValue = this.volume
		},
		handleInput(e) {
			e.target.value = verifyInteger(e.target.value)
			this.useValue = e.target.value

			this.$emit('input', this.useValue ? Decimal(this.useValue).toNumber() : '')
		},
	},
}
</script>

<style lang="scss" scoped>
input {
	width: 100%;
	font-size: 14px;
	padding: 0px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	padding: 5px 60px 5px 10px;
	&.error {
		border: 1px solid #ff3b31 !important;
	}
}

p {
	font-size: 14px;
	font-weight: 400;
}

.input-container {
	display: flex;
	gap: 10px;
	height: 30px;
	.input-wrap {
		display: flex;
		width: 100%;
		position: relative;
		.input-unit {
			width: 60px;
			position: absolute;
			top: 4px;
			right: 0px;
			font-size: 14px;
			text-align: right;
			padding-right: 10px;
		}
	}
}

.use-wrap {
	.input-head-text {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
		& > p {
			font-size: 12px;
			.volume-text {
				color: $PLACEHOLDER;
			}
			.current-volume {
				color: $BLUE;
			}
		}
	}
	.input-use {
		&:focus-within {
			border: 1px solid $SECONDARY_1;
		}
	}
	.require {
		color: $RED;
	}
}

.error-msg {
	display: flex;
	align-items: center;
	color: $RED;
	font-size: 12px;
	font-weight: 400;
	img {
		margin-right: 6px;
	}
}
</style>
