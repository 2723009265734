var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "select-table-wrapper"
  }, [_c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', {
    staticClass: "select-table"
  }, [_c('tr', {
    staticClass: "select-table-header"
  }, [_c('th', {
    attrs: {
      "width": "633px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('상품 정보')))])]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('수량')))])]), _c('th', {
    attrs: {
      "width": "135px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('판매가')))])]), _c('th', {
    attrs: {
      "width": "130px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('담은사람')))])])]), _vm._l(_vm.list, function (item, index) {
    return _c('table-item', {
      key: "product-".concat(index),
      attrs: {
        "item": item,
        "index": index,
        "allChecked": _vm.allChecked
      }
    });
  })], 2)]), _vm.list.length === 0 ? _c('div', {
    staticClass: "no-data"
  }, [_c('img', {
    attrs: {
      "src": require('@/views/common/components/modalGen2/svg/cart.svg')
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('장바구니에 상품이 없습니다')))])]) : _vm._e(), _c('product-info-price-table', {
    attrs: {
      "total": _vm.totalPrice,
      "deliveryFee": _vm.totalDeliveryFee,
      "sale": _vm.totalSalePrice,
      "vatPrice": _vm.vatPrice
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }