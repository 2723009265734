<template>
	<div
		class="license-item"
		@click.stop="selectLicense"
		:class="{ point: isSelected, selected: isSelected, grey: disabled }"
		:id="`${licenseType}-disabled`"
	>
		<div class="license-item-header">
			<span class="license-item-title">
				{{ tt(licenseType2Text(licenseType)) }}
				<div v-if="isTypePro" class="recommand-icon">
					{{ tt('추천!') }}
				</div>
			</span>
			<ul class="license-item-content">
				<li v-if="licenseType != 'BASIC'">{{ tt('모든 기능 사용') }}</li>
				<li v-else>{{ tt('재고관리 기능에 집중') }}</li>
				<li class="important" v-if="licenseType == 'EDUCATION'">{{ tt('인원 추가 무제한') }}</li>
				<li class="important">
					{{ tt('MERCK 전 상품 10% 상시할인') }}
					<img src="@/assets/svg/Question.svg" @click.prevent="goM10" v-b-tooltip.hover="'Merck 10% 할인 안내로 가기'" />
				</li>
			</ul>
		</div>
		<div class="license-item-price">
			<div class="price">{{ `₩${licensePrice}` }}</div>
			<div class="monhtly">
				{{ tt('월간 금액') }} /
				<span :class="{ red: licenseType == type.EDUCATION }">{{ tt(licenseType == type.EDUCATION ? '연구실당' : '계정당') }}</span>
			</div>
			<div class="yearPrice">※ 연간 금액 = {{ `₩${licenseYearPrice}` }}/{{ tt(licenseType == type.EDUCATION ? '연구실' : '계정') }}</div>
		</div>
		<b-tooltip v-if="disabled" placement="bottom" :target="`${licenseType}-disabled`">{{ disabledReason }}</b-tooltip>
	</div>
</template>

<script>
import MixinLicense from '@/mixins/license'
export default {
	mixins: [MixinLicense],
	props: {
		licenseType: {
			type: String,
			default: '',
		},
		selectedLicenseType: {
			type: String,
			default: '',
		},
	},
	computed: {
		isTypePro() {
			return this.licenseType === this.type.STANDARD
		},
		isSelected() {
			return this.licenseType === this.selectedLicenseType
		},
		licensePrice() {
			if (this.licenseType == this.type.EDUCATION) {
				return this.formatNumberLocale(this.licenseRatePlan['YEAR']['EDUCATION'] / 12)
			} else return this.formatNumberLocale(this.licenseRatePlan[this.isMonthly ? 'MONTH' : 'YEAR'][this.licenseType])
		},
		licenseYearPrice() {
			if (this.licenseType == this.type.EDUCATION) return this.formatNumberLocale(this.licenseRatePlan['YEAR']['EDUCATION'])
			else return this.formatNumberLocale(this.licenseRatePlan['YEAR'][this.licenseType] * 12)
		},
		disabled() {
			return this.licenseType == 'BASIC' || (this.isMonthly && this.licenseType == 'EDUCATION')
		},
		disabledReason() {
			if (this.disabled)
				return this.licenseType == 'BASIC' ? this.tt('곧 출시 예정입니다.') : this.tt('대학 무제한 플랜은 연간 구매만 가능합니다.')
			else return null
		},
	},
	methods: {
		selectLicense() {
			if (this.disabled) return
			this.$emit('selectLicense', this.licenseType)
		},
	},
}
</script>

<style lang="scss" scoped>
.point {
	&:after {
		border-radius: 16px !important;
	}

	&:before {
		border-radius: 13px !important;
	}
}

.hover-question-gray {
	width: 12px;
	height: 12px;
}

.recommand-icon {
	background-color: #faa400;
	border-radius: 4px;
	display: flex;
	// width: 30px;
	padding-left: 4px;
	padding-right: 4px;
	height: 16px;
	margin-left: 4px;
	font-size: 10px;
	font-weight: 700;
	color: $LAB_WHITE;
	justify-content: center;
}

div.license-item {
	width: 460px;
	min-height: 100px;
	padding: 20px;
	cursor: pointer;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	gap: 10px;
	border-radius: 16px;
	background: $LAB_WHITE;
	border: 1px solid $DIVIDER;
	&:hover {
		background-color: $SUB-YELLOW_100;
	}
	&.grey {
		background-color: $GREY_2;
		& * {
			font-weight: 400 !important;
			color: $SECONDARY_2 !important;
		}
	}
	div.license-item-header {
		display: flex;
		flex-direction: column;
		span.license-item-title {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 700 !important;
		}
		.license-item-content {
			margin-top: 5px;
			padding-left: 10px;
			li {
				list-style: inside;
				font-size: 12px;
				font-weight: 400;
				color: $DEFAULT;
				&.important {
					color: $RED;
					font-weight: 700;
				}
				img {
					cursor: pointer;
					vertical-align: middle;
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	div.license-item-price {
		height: 100%;
		display: flex;
		flex-direction: column;
		font-size: 18px;
		text-align: right;
		font-weight: 400;
		gap: 2px;
		.monhtly {
			font-size: 12px;
			color: $DEFAULT;
			.red {
				color: $RED;
				margin-bottom: 16px;
			}
		}
		.yearPrice {
			font-size: 12px;
			color: $RED;
		}
	}
}
</style>
