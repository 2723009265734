<template>
	<tr class="select-table-body">
		<td>
			<div>
				<span class="checkbox"><input type="checkbox" v-model="checked" /></span>
				<option-info :product="item"></option-info>
			</div>
		</td>
		<td>
			<b>{{ item.quantity }}</b>
			<!-- <input-button-counter ref="input-button-counter" v-model="quantity" :min="min" :max="max" sizeType="sm"></input-button-counter> -->
		</td>
		<td>
			<div>
				<div>
					<b>{{ tt('${1}원', formatNumberLocale(item.sellingPrice)) }}</b>
				</div>
				<div v-if="item.discountPrice" style="text-decoration: line-through; color: #999">
					{{ tt('${1}원', formatNumberLocale(item.discountPrice)) }}
				</div>
			</div>
		</td>
		<td>
			<span>{{ tt(item.user.name) }}</span>
		</td>
	</tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import InputButtonCounter from '@/views/purchase/cargo/components/cargoList/counter/InputButtonCounter.vue'
import OptionInfo from './OptionInfo.vue'
export default {
	components: {
		InputButtonCounter,
		OptionInfo,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
			default: 0,
		},
		allChecked: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('draft/form', ['selectedCartItems']),
		quantity: {
			get() {
				return this.item.quantity
			},
			set(value) {
				this.setCartProductCount({ index: this.index, quantity: value })
			},
		},
		checked: {
			get() {
				return this.selectedCartItems.some(item => item.id === this.item.id)
			},
			set(value) {
				if (value) {
					this.setSelectedCartItems(this.selectedCartItems.concat(this.item))
				} else {
					this.setSelectedCartItems(this.selectedCartItems.filter(item => item.id !== this.item.id))
				}
			},
		},
	},
	watch: {
		allChecked: {
			handler(val) {
				this.checked = val
			},
			immediate: true,
		},
	},
	data() {
		return {
			// checked: false,
			min: 0,
			max: Number.MAX_SAFE_INTEGER,
		}
	},
	methods: {
		...mapMutations('draft/form', ['setSelectedCartItems', 'setCartProductCount']),
	},
}
</script>

<style lang="scss" scoped>
.select-table-body {
	border-top: 1px solid $INPUT;

	td {
		padding-top: 20px;
		padding-bottom: 20px;
		height: 100%;
		border-right: 1px solid $INPUT;
		text-align: center;
		.input-wrap {
			width: 70%;
			margin: auto;
		}
	}
	td:first-child {
		padding-left: 20px;
		text-align: left;
		& > div {
			display: flex;
			gap: 15px;
			span {
				display: flex;
				align-items: center;
			}
		}
		// border-right: none;
	}
	td:last-child {
		border-right: none;
	}
}
.count-input-wrapper {
	display: inline-flex;
	flex-direction: row;
	border: 1px solid $INPUT;
	border-radius: 5px;
	padding: 4px;
	height: 30px;
	width: 90px;
	background: white;
	input {
		text-align: left;
		border: none;
		width: 35px;
		padding-left: 5px;
	}
	button {
		background-color: $GREY_4;
		margin-left: auto;
		width: 20px;
		height: 20px;
		border: none;
		border-radius: 4px;
		background-repeat: no-repeat;
		background-position: center;
		text-indent: -9999px;
		background-size: 16px;
		cursor: pointer;
		transition: all 0.3s;
		&.minus-btn {
			background-image: url(~@/assets/svg/input-count-minus.svg);
		}
		&.plus-btn {
			background-image: url(~@/assets/svg/input-count-plus.svg);
		}
		&.disable {
			color: $COLOR_CCC;
			background: linear-gradient(0deg, $GREY_4, $GREY_4), linear-gradient(0deg, rgba(242, 243, 244, 0.2), rgba(242, 243, 244, 0.2)),
				linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
			cursor: not-allowed;
		}
	}
	&.lg {
		height: 40px;
		width: 120px;
		input {
			width: 40px;
		}
		button {
			width: 30px;
			height: 30px;
		}
	}
}
</style>
