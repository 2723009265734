<template>
	<div class="page-wrapper">
		<migration-header :isMigration="true" :text="headerText"></migration-header>
		<div class="content-container">
			<div class="content-wrapper">
				<div class="text-wrapper">
					<h3 class="text-title" v-html="title"></h3>
					<div class="text-content" v-html="content"></div>
				</div>
				<div class="timer-container">
					<img src="@/assets/svg/migration/migration-timer.svg" />
					<div class="timer-text" v-html="timerText"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'
import MixinMigration from '@/mixins/migration'
import MigrationHeader from '@/views/migration/components/MigrationHeader.vue'
import { mapActions, mapState } from 'vuex'

export default {
	components: { LmConfirmButtonMiddle, MigrationHeader },
	mixins: [MixinMigration],
	data() {
		return {
			headerText: this.tt('데이터 이전'),
			title: this.tt('랩매니저 PRO로 데이터 이전 중입니다.'),
			content: this.tt(
				'최대 10분 가량 소요되며, 더 이상 구버전 서비스를 사용할 수 없습니다.<br/>데이터 이전이 완료되면, 이메일을 통해 안내해 드리겠습니다.'
			),
			bottomLeftText: this.tt('데이터는 어떻게 이전되나요?'),
			bottomRightText: this.tt('랩매니저 가이드보기'),
			overTime: null,
		}
	},
	created() {
		setInterval(this.setDurationSeconds, 1000)
		setInterval(this.callNewEOLState, 10000)
	},
	computed: {
		...mapState('appEOL', ['eolState', 'beforeStatus']),
		timerText() {
			return this.overTime ? `${this.overTime} ${this.tt('초과')}` : ''
		},
	},
	methods: {
		...mapActions('appEOL', ['loadEolState']),
		setDurationSeconds() {
			const timeFormat = 'mm:ss'
			const calibrationSecond = 4 // 보정시간
			const startDatetime = this.$DateTime.fromISO(this.eolState.startDatetime)

			const nowDatetime = this.$DateTime.now()
			const diffSeconds = nowDatetime.diff(startDatetime).toFormat(timeFormat)
			this.overTime = diffSeconds
		},
		async callNewEOLState() {
			try {
				await this.loadEolState()
				const isSameBeforeState = this.beforeStatus === this.eolState.status
				if (!isSameBeforeState) {
					location.reload()
				}
			} catch (e) {
			} finally {
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $GREY_0;
	.content-container {
		width: 700px;
		height: 640px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 40px 50px;
		background-color: $LAB_WHITE;
		border-radius: 16px;
		gap: 10px;
		box-shadow: 0px 1px 2px 0px #0000001a;

		.content-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			.text-wrapper {
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				align-content: space-between;
				justify-content: space-between;
				width: 100%;
				height: 90px;
				.text-title {
					font-size: 24px;
					font-weight: 700;
					line-height: 35px;
					letter-spacing: 0px;
					text-align: left;
				}
				.text-content {
					font-size: 16px;
					font-weight: 400;
					line-height: 23px;
					letter-spacing: 0px;
					text-align: left;
				}
			}
			.timer-container {
				margin-top: 30px;
				margin-bottom: 30px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				height: 360px;
				width: 600px;
				border-radius: 16px;
				background: $GREY_0;
				box-shadow: 0px 1px 2px 0px #0000001a;
				gap: 10px;
				.timer-text {
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: center;
				}
			}
		}

		.bottom-content-wrapper {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: space-between;
			.left-content {
				cursor: pointer;
				display: flex;
				align-items: center;
				font-size: 16px;
				font-weight: 400;
				line-height: 23px;
				letter-spacing: 0px;
				text-align: left;
				color: $SECONDARY_1;
			}

			.right-content {
				cursor: pointer;
				display: flex;
				align-items: flex-start;
				font-size: 16px;
				font-weight: 700;
				line-height: 23px;
				letter-spacing: 0px;
				text-align: right;
				color: $SECONDARY_2;
			}
		}
	}
}
</style>
