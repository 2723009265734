<template>
	<!-- step2 -->
	<div class="regist-step2-wrapper" v-if="draftInfo.subject">
		<store-order-info
			v-if="draftInfo.purchaseType == 'INTERNAL'"
			ref="store-order-info"
			type="DRAFT"
			content="상신하신 지출 결의서가 승인되면, 랩매니저 스토어에 주문이 자동 접수됩니다."
		/>
		<delivery-info ref="delivery-info" :isValid="isValid" v-if="draftInfo.purchaseType == 'INTERNAL'" @changeAddress="changeAddress" />
		<div class="product-info-wrapper">
			<h5>{{ tt('상품 정보') }}</h5>
			<div class="btn-area">
				<template v-if="draftInfo.purchaseType == 'INTERNAL'">
					<image-button
						:image="require('@/assets/svg/Search.svg')"
						:text="'랩매니저 스토어에서 검색'"
						@click="storeShowModal('modal-store-search-product')"
					></image-button>
					<image-button
						:image="require('@/assets/svg/purchase/icon-cart.svg')"
						:text="'장바구니에서 추가'"
						@click="storeShowModal('modal-add-cart-product')"
					></image-button>
					<image-button
						:text="'견적서로 추가'"
						:image="require('@/assets/svg/purchase/icon-estimate.svg')"
						@click="storeShowModal('modal-estimate')"
					></image-button>
				</template>
				<template v-else>
					<image-button
						:image="require('@/assets/svg/Add(Button).svg')"
						:text="'상품 추가'"
						@click="$refs['modal-add-product'].show()"
					></image-button>
				</template>
				<base-button :disabled="selectedItems.length === 0" @click="btnSelectedDelete">{{ tt('선택 삭제') }}</base-button>
			</div>
			<div>
				<product-info-regist-table :productInfoList="draftInfo.purchaseItems"></product-info-regist-table>
			</div>
		</div>
		<div class="budget-status-wrapper" v-if="getSelectedBudget">
			<total-amount :title="'예산 현황'" :info="amountForm" :budget="getSelectedBudget"></total-amount>
		</div>
		<template v-if="draftInfo.purchaseType == 'INTERNAL'">
			<orderer-info ref="orderer-info" :isValid="isValid" />
			<recipient-info ref="recipient-info" @getOrdererInfo="getOrdererInfo" :isValid="isValid" />
		</template>
		<div class="file-wrapper">
			<h5>{{ tt('첨부 파일') }}</h5>
			<div class="type-file">
				<div class="upload-box" ref="upload-box">
					<input class="dropFile" type="file" ref="dropFile" @change="onChange" @drop="dropFile" multiple />
					<div class="file-box" v-if="draftInfo.attachments.length == 0">
						<p>{{ tt('첨부할 파일을 마우스로 끌어오거나, 직접 선택해 주세요') }}</p>
						<div class="btn-wrap">
							<button class="btn" ref="select-btn" @click="uploadFile">{{ tt('파일 선택') }}</button>
						</div>
					</div>
					<ul class="file-list" v-else>
						<li v-for="(file, index) in draftInfo.attachments" :key="`key${index}`">
							<img src="@/assets/svg/docs.svg" />
							<p>{{ tt(file.name) }}</p>
							<button class="close-btn" @click="deleteAttachments(index)">
								<img src="@/assets/svg/Close-1.svg" />
							</button>
						</li>
					</ul>
				</div>
				<button class="btn" @click="uploadFile">{{ tt('파일 선택') }}</button>
			</div>
		</div>
		<modal-confirm
			ref="modal-estimate-delete"
			title="상품 삭제"
			content="견적서로 추가된 상품은 전체 삭제됩니다.<br>상품 전체 삭제하시겠습니까?"
			confirmText="전체 삭제"
		/>
		<modal-add-product ref="modal-add-product" @addProduct="addPurchaseItem" />
		<modal-store-search-product ref="modal-store-search-product" />
		<modal-estimate ref="modal-estimate" @addProductByEstimate="addProductByEstimate" />
		<modal-add-cart-product ref="modal-add-cart-product" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TotalAmount from '@/views/purchase/draft/components/detail/TotalAmount.vue'
import ProductInfoRegistTable from '@/views/purchase/draft/components/regist/ProductInfoRegistTable.vue'
import ModalAddProduct from '../modal/ModalAddProduct.vue'
import ModalStoreSearchProduct from '@/views/common/components/modalGen2/ModalStoreSearchProduct.vue'
import ModalEstimate from '../modal/ModalEstimate.vue'
import ModalAddCartProduct from '@/views/common/components/modalGen2/ModalAddCartProduct.vue'
import DeliveryInfo from './DeliveryInfo.vue'
import OrdererInfo from './OrdererInfo.vue'
import RecipientInfo from './RecipientInfo.vue'
import StoreOrderInfo from './StoreOrderInfo.vue'
import MixinLicense from '@/mixins/license'
export default {
	name: 'DraftRegistrationStep2',
	props: {},
	components: {
		TotalAmount,
		ProductInfoRegistTable,
		ModalAddProduct,
		ModalStoreSearchProduct,
		ModalEstimate,
		ModalAddCartProduct,
		DeliveryInfo,
		OrdererInfo,
		RecipientInfo,
		StoreOrderInfo,
	},
	mixins: [MixinLicense],
	data() {
		return {
			files: [],
			isValid: false,
		}
	},
	computed: {
		...mapState('draft/item', ['draftInfo', 'selectedItems']),
		...mapGetters('draft/item', ['getSelectedBudget']),
		amountForm() {
			let form = {}
			if (!this.getSelectedBudget) return form
			let thisMonth = this.getSelectedBudget.budgetType === 'MONTH' ? `${this.getSelectedBudget.month}월` : ''
			form = {
				title: {
					name: `${this.getSelectedBudget.code} ${this.getSelectedBudget.name}`,
					status: this.getSelectedBudget.budgetType,
				},
				total: {
					name: this.tt('${1}년 ${2} 남은 잔액', this.getSelectedBudget.year, thisMonth),
					value: this.getSelectedBudget.summary?.budget ? this.getSelectedBudget.summary.budget : 0,
				},
				expense: {
					name: this.tt('지출 예정 금액'),
					value: this.draftInfo.expendAmount,
				},
				balance: {
					name: this.tt('결제 후 예상 잔액'),
					value: this.getSelectedBudget.summary.budget - this.draftInfo.expendAmount,
				},
			}
			return form
		},
	},
	created() {
		this.initDraftRegistrationStep2()
		if (!this.draftInfo.subject) {
			this.$router.go(-1)
		}
	},
	methods: {
		...mapMutations('draft/item', ['setDraftInfo', 'initPurchaseItems', 'deleteAttachments', 'initDraftRegistrationStep2', 'setOrdererInfo']),
		...mapActions('draft/item', ['setPurchaseItems', 'deletePurchaseItems']),
		...mapMutations('draft/form', ['setDeliveryId']),
		showAddCartModal() {},
		addPurchaseItem(item) {
			this.setPurchaseItems(item)
		},
		getOrdererInfo() {
			this.setOrdererInfo(this.$refs['orderer-info'].info)
		},
		btnSelectedDelete() {
			if (this.draftInfo.estimateId != null) {
				this.$refs['modal-estimate-delete'].show(() => {
					this.initPurchaseItems()
				})
			} else this.deletePurchaseItems()
		},
		dropFile(event) {
			event.preventDefault()
			this.$refs['dropFile'].files = event.dataTransfer.files
			this.onChange()
		},
		uploadFile() {
			this.$refs['dropFile'].click()
		},
		storeShowModal(modalName) {
			const deliveryId = this.$refs['delivery-info'].info.deliveryId
			if (!deliveryId) this.$root.toast(`배송지 선택`, `배송지를 선택해주세요.`, 'error')
			else {
				this.setDeliveryId(deliveryId)
				this.$refs[modalName].show()
			}
		},
		onChange() {
			let fileValues = Object.values(this.$refs['dropFile'].files)
			let files = []
			if (this.draftInfo.attachments.length > 0) {
				this.draftInfo.attachments.forEach(file => {
					files.push(file)
				})
			}
			fileValues.forEach(file => {
				files.push(file)
			})

			this.setDraftInfo({ ...this.draftInfo, ...{ attachments: files } })
		},
		validation() {
			this.isValid = false
			if (this.draftInfo.purchaseType == 'INTERNAL') {
				if (this.isTrial || this.isFreeTier) {
					throw 'no license'
				}
				const deliveryInfo = this.$refs['delivery-info'].info
				const ordererInfo = this.$refs['orderer-info'].info
				const recipientInfo = this.$refs['recipient-info'].info
				const lmsInfo = { ...deliveryInfo, ...ordererInfo, ...recipientInfo }
				console.log(lmsInfo)
				Object.keys(lmsInfo).forEach(key => {
					if (lmsInfo[key] == '' && key != 'deliveryRequest') {
						this.isValid = true
						throw 'invalid'
					}
				})
				this.setDraftInfo({ ...this.draftInfo, ...lmsInfo })
			}
			if (this.draftInfo.purchaseItems.length === 0) {
				// this.$root.toast('상품 정보 미입력', '상품을 추가하여 주세요.', 'error')
				throw 'no product'
			}
		},
		changeAddress() {
			this.initPurchaseItems()
		},
		addProductByEstimate(estimate) {
			let estimateArr = []
			this.initPurchaseItems()
			estimate.products.forEach(item => {
				let selectItem = {}
				Object.entries(item).forEach(([key, value]) => {
					if (key === 'id') {
						selectItem['estimateItemId'] = value
					} else if (value) {
						selectItem[key] = value
					}
				})
				estimateArr.push(selectItem)
			})
			this.setPurchaseItems(estimateArr)
			this.setDraftInfo({ ...this.draftInfo, estimateId: estimate.id })
		},
	},
}
</script>

<style lang="scss" scoped>
.regist-step2-wrapper {
	h5 {
		font-weight: 700;
		// margin-bottom: 30px;
	}
	& > div {
		margin-bottom: 30px;
	}
	.product-info-wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 30px;
		div {
			display: flex;
			& > button {
				&:nth-last-child(1) {
					margin-left: auto;
					justify-content: flex-end;
				}
			}
		}
		.btn-area {
			gap: 20px;
		}
	}
	.file-wrapper {
		h5 {
			margin-bottom: 30px;
		}
		.type-file {
			.upload-box {
				// width: 800px;
				height: 120px;
				border: 1px dashed $FILE_BORDER;
				position: relative;

				overflow-y: auto;
				cursor: pointer;
				margin-bottom: 10px;
				input {
					width: 100%;
					height: 100%;
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					padding: 0 0;
					cursor: pointer;
					&.isFile {
						visibility: hidden;
					}
				}
				.file-box {
					text-align: center;
					width: 100%;
					z-index: 2;
					// position: relative;
					padding-top: 26px;
					.btn-wrap {
						width: 100%;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						button {
							margin-top: 10px;
							cursor: pointer;
						}
					}
				}
				.file-list {
					width: 100%;
					padding: 14px 10px;
					height: 100%;
					// position: relative;
					z-index: 2;
					li {
						width: 100%;
						height: 30px;
						display: flex;
						align-items: center;
						font-size: 0.875rem;
						font-weight: 400;
						img {
							margin-right: 2px;
						}
						button {
							position: relative;
						}
					}
				}
			}
		}
	}
}
</style>
