const PageName = {
	Main: 'main',
	Home: 'Home',
	RedirectToken: 'redirectToken',
	Migration: {
		Main: 'MigrationMain',
		NoAdmin: 'MigrationNoAdmin',
		Being: 'MigrationBeing',
		Select: 'MigrationSelect',
		SelectLab: 'MigrationSelectLab',
		InputInstitutionName: 'MigrationInputInstitutionName',
		MemberList: 'MigrationMemberList',
	},
	creation: {
		create: 'createInstitution',
	},
	Dashboard: 'Dashboard',
	Download: 'Download',
	Institution: {
		Information: 'InstitutionInformation',
		Organization: 'InstitutionOrganizaion',
		OrganizationDetail: 'InstitutionOrganizaionDetail',
		Location: 'InstitutionLocation',
		MemberManagement: 'MemberManagement',
		StorageDetail: 'InstitutionStorageDetail',
		AuthoritySetting: 'AuthoritySetting',
		AuthorityDetail: 'AuthorityDetail',
	},
	// Inventory: {
	// 	Whole: 'Inventory',
	// 	History: 'InventoryHistory',
	// 	HistoryItem: 'HistoryItem',
	// 	Disposal: 'InventoryDisposal',
	// 	Setting: 'InventorySetting',
	// 	DisposalDetail: 'DisposalDetail',
	// 	List: 'InventoryList',
	// 	Wait: 'WaitingRegistration',
	// 	WaitDetail: 'WaitingRegistrationDetail',
	// },
	InventoryGen2: {
		List: 'InventoryListGen2', // TODO : temp
		Detail: 'InventoryDetailGen2', // TODO : temp
		WaitList: 'WaitingList',
		WaitDetail: 'WaitingDetail',
		HistoryList: 'HistoryList',
		DisposalList: 'DisposalList',
		DisposalDetail: 'DisposalDetailGen2', // TODO : temp
		Setting: 'InventoryGen2Setting',
	},
	Safety: {
		MSDS: 'SafetyMSDS',
		GHSLabel: 'SafetyGHSLabel',
		DangerousStorage: 'SafetyDangerousStorage',
		DangerousStorageDetail: 'SafetyDangerousStorageDetail',
		DangerousStorageInfo: 'SafetyDangerousStorageInfo',
		ManagementMaterials: 'SafetyManagementMaterials',
		ManagementMaterialsDetail: 'ManagementMaterialsDetail',
		ManagementInfo: 'ManagementInfo',
		ChemicalMaterials: 'SafetyChemicalMaterials',
		ChemicalMaterialsDetail: 'SafetyChemicalMaterialsDetail',
		ChemicalInfo: 'ChemicalInfo',
		HealthCheck: 'SafetyHealthCheck',
		HealthCheckDetail: 'SafetyHealthCheckDetail',
		LawMaterials: 'SafetyLawMaterial',
		LegalProduct: 'SafetyLegalProduct',
		LegalProductDetail: 'SafetyLegalProductDetail',
	},
	Invite: {
		Receive: 'ReceiveInvitePage',
		Expire: 'LicenseExpirePage',
	},
	Budget: {
		Status: 'BudgetStatus',
		List: 'BudgetList',
		Detail: 'BudgetDetail',
		Create: 'BudgetCreate',
		Edit: 'BudgetEdit',
	},
	Purchase: {
		DraftList: 'DraftList',
		DraftDetail: 'DraftDetail',
		DraftRegistration: 'DraftRegistration',
		ApprovalList: 'ApprovalList',
		ApprovalDetail: 'ApprovalDetail',
		DeadlineList: 'DeadlineList',
		DeadlineDetail: 'DeadlineDetail',
		CargoDetail: 'CargoDetail',
		CargoList: 'CargoList',
		Product: {
			Order: 'ProductOrder',
			Pay: 'ProductPay',
			PayDetail: 'ProductPayDetail',
			OrderComplete: 'OrderComplete',
		},
	},
}

export default PageName
