<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade hide-footer>
		<template #modal-title>
			{{ tt('라이선스 주문 완료') }}
		</template>
		<template>
			<img src="@/assets/images/check.png" />
			<div class="license-summary">
				<img class="imgbox" src="@/assets/images/lm-pro-icon.png" />
				<div class="txtbox">
					<h6>{{ licenseTypeText }}</h6>
					<p v-if="isEducation">
						{{ tt('${1}원 / 연구실당 (연 기준 ・ VAT 별도)', formatNumberLocale(licenseRatePlan['YEAR']['EDUCATION'])) }}
					</p>
					<p v-else>
						{{ tt('${1}원 / 라이선스 당(월 기준 ・ VAT 별도)', formatNumberLocale(getAdditonalPrice)) }}
					</p>
				</div>
			</div>
			<div class="license-info">
				<h5>{{ tt('라이선스 정보') }}</h5>
				<ul>
					<li>
						<p>{{ tt('상태') }}</p>
						<div class="status">
							<span
								class="license-status"
								:class="{
									expired: !isLicenseActivate,
								}"
							>
								{{ licenseStatusText }}
							</span>
							<span class="waiting-status" v-if="isLicenseDepositWait">
								{{ tt('입금대기') }}
							</span>
						</div>
					</li>
					<li>
						<p>{{ tt('라이선스 만료일') }}</p>
						<span :class="{ red: !isLicenseActivate, bold: !isLicenseActivate }">
							{{ formatDatetime(license.expireDate) }}
						</span>
					</li>
					<!--라이선스 활성화 된 경우(결제 완료)-->
					<li v-if="isFreeTier">
						<p>
							{{ tt('멤버 수') }}
						</p>
						<span class="license-member">
							{{ formatNumberLocale(memberCount) }}
						</span>
					</li>
					<li v-else>
						<p>
							{{ tt('구매한 라이선스 수') }}
						</p>
						<span class="licese-member">
							<span class="license-count">
								{{ purchaseCompleteInfo.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(purchaseCompleteInfo.quantity) }}
							</span>
						</span>
					</li>
				</ul>
			</div>
			<PaymentStatus />
			<div class="modal-footer">
				<button class="button-primary" @click="hide">
					{{ tt('확인') }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import PaymentStatus from '../components/PaymentStatus.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
export default {
	components: {
		PaymentStatus,
	},
	mixins: [MixinLicense, MixinPayment],
	methods: {
		async show() {
			await this.loadLicenseManagement()
			await this.loadLicenseRatePlan()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-manage.scss';
.license-info {
	border-top: 1px solid $INPUT;
	padding-top: 20px;
}
</style>
