<template>
	<div class="regist-step1-wrapper">
		<div class="approval-info-wrapper">
			<div>
				<h5>{{ tt('결재 정보') }}</h5>
				<image-button :text="'결재선 설정'" @click="showApprovalLineSettingModal"></image-button>
			</div>
			<div class="approval-item-wrapper">
				<approval-info-item
					v-if="user.id"
					:approvalTitle="'기안'"
					:name="user.name"
					:affiliation="selectedGroup ? selectedGroup.name : ''"
					:isFooter="false"
				></approval-info-item>
				<div v-if="draftInfo.approvalLine.length > 0">
					<approval-info-item
						v-for="(approval, index) in draftInfo.approvalLine"
						:key="`approval-${index}`"
						:approvalTitle="'결재'"
						:name="approval.name"
						:affiliation="approval.parent.name"
						:isFooter="false"
					></approval-info-item>
				</div>
			</div>
			<div v-if="isValid && draftInfo.approvalLine.length === 0" class="error">
				<img src="@/assets/svg/purchase/icon-error.svg" alt="error" />
				<span>{{ tt('결재선을 설정해주세요.') }}</span>
			</div>
		</div>
		<div class="draft-info-wrapper">
			<h5>{{ tt('기안 정보') }}</h5>
			<base-item :title="'기안자'">
				<div class="type-member">
					<img :src="user.image ? user.image : require('@/assets/svg/member-photo-default.svg')" />
					<p>{{ tt(user.name) }}</p>
				</div>
			</base-item>
			<!-- temp -->
			<div class="type-group">
				<input-text
					title="기안 연구실"
					:isRequired="true"
					:inputWidthSize="400"
					:inputHeightSize="36"
					:inValidText="'기안 연구실을 선택해주세요.'"
					:inputValue="selectedGroup ? selectedGroup.name : '기안 연구실을 선택해주세요'"
					:isReadOnly="true"
				></input-text>
				<button @click="showSelectedGroupModal">
					<img :src="require('@/assets/svg/Next.svg')" />
				</button>
			</div>
			<input-text
				ref="subject"
				title="기안 제목"
				placeholder="기안 제목을 입력해주세요"
				:isRequired="true"
				:inputWidthSize="400"
				:inputHeightSize="36"
				:inValidText="'기안 제목을 입력해주세요.'"
				:inputValue="draftInfo.subject"
				:isValid="!isValid || !!$refs['subject'].value"
			></input-text>
			<input-text-area
				ref="content"
				title="기안 내용"
				placeholder="기안 내용을 입력해주세요"
				:inputWidthSize="600"
				:inputHeightSize="120"
				:isReadOnly="false"
				:inputValue="draftInfo.content"
			></input-text-area>
			<input-select
				ref="budgetId"
				:title="'예산'"
				:inputWidthSize="400"
				:placeholder="'예산을 선택해주세요'"
				:list="getUsableBudgetList"
				:item="draftInfo.budgetId"
			>
				<div class="list-no-data" v-if="getUsableBudgetList.length === 1">
					<p>{{ tt(selectedGroup ? '등록된 예산이 없습니다.' : '주문 연구실을 선택해주세요') }}</p>
					<span v-if="selectedGroup" @click="$router.push({ name: 'BudgetCreate' })">{{ tt('예산 등록하기') }}</span>
				</div>
			</input-select>

			<input-radio
				ref="purchaseType"
				:title="'구매방식'"
				:list="purchaseTypeList"
				:isRequired="true"
				:selectedValue="draftInfo.purchaseType"
				:isValid="!isValid || !!$refs['purchaseType'].value"
			></input-radio>
		</div>
		<modal-form-approval-line-selected
			ref="modal-form-approval-line-selected"
			:defaultData="draftInfo.approvalLine"
			@complete="completeDraftApprovalLine"
		></modal-form-approval-line-selected>
		<modal-select-group
			title="기안 연구실 선택"
			ref="modal-select-group"
			:isSelectSingle="true"
			:defaultData="groups"
			@confirm="completeSelectedGroup"
		/>
	</div>
</template>

<script>
import _ from 'lodash'
import PageName from '@/utils/define/PageName'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ApprovalInfoItem from '@/views/purchase/draft/components/detail/ApprovalInfoItem.vue' // detail꺼 사용 (TODO: 공용 컴포넌트화)
import ImageButton from '@/views/common/components/baseButton/ImageButton.vue'
import InputText from '@/views/common/components/items/InputText.vue'
import InputTextArea from '@/views/common/components/items/InputTextArea.vue'
import InputSelect from '@/views/common/components/items/InputSelect.vue'
import InputRadio from '@/views/common/components/items/InputRadio.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
// 모달
import ModalFormApprovalLineSelected from '@/views/purchase/draft/components/modal/ModalFormApprovalLineSelected.vue'
import ModalSelectGroup from '@/views/common/components/modalGen2/ModalSelectGroup.vue'

export default {
	name: 'DraftRegistrationStep1',
	props: {},
	components: {
		ApprovalInfoItem,
		ImageButton,
		InputText,
		InputTextArea,
		InputSelect,
		InputRadio,
		BaseItem,
		ModalFormApprovalLineSelected,
		ModalSelectGroup,
	},
	data() {
		return {
			selectPurchaseTypeTab: 1,
			isValid: false,
		}
	},
	computed: {
		...mapState('user', ['user']),
		...mapState('institute/group', ['groups', 'selectedGroups']),
		...mapState('institute/member', ['selectedMembers']),
		...mapState('draft/item', ['groupList', 'draftInfo', 'selectedGroup']),
		...mapGetters('draft/item', ['getUsableBudgetList']),
		purchaseTypeList() {
			return [
				{
					id: 1,
					value: 'INTERNAL',
					name: '랩매니저 스토어',
				},
				{
					id: 2,
					value: 'EXTERNAL',
					name: '외부 거래처',
				},
			]
		},
	},
	created() {
		if (this.selectedGroup) this.setSelectedGroup([this.selectedGroup])
		else this.initSelectedGroups()
	},
	methods: {
		...mapActions('institute/group', ['getGroups']),
		...mapActions('draft/item', ['setDraftGroupId']),
		...mapMutations('institute/group', ['setSelectedGroup', 'initSelectedGroups']),
		...mapMutations('institute/member', ['setSelectedMembers']),
		...mapMutations('draft/item', ['setDraftInfo', 'setDraftApprovalLine', 'setDraftGroup', 'setBudgetId']),
		...mapMutations('institute/member', ['setSelectedMembers']),
		showApprovalLineSettingModal() {
			if (this.draftInfo.approvalLine.length >= 0) this.setSelectedMembers(_.cloneDeep(this.draftInfo.approvalLine))
			this.$refs['modal-form-approval-line-selected'].show()
		},
		completeDraftApprovalLine(selectedMembers) {
			this.$refs['modal-form-approval-line-selected'].hide()
			this.setDraftApprovalLine(_.cloneDeep(selectedMembers))
		},
		async showSelectedGroupModal() {
			this.$refs['modal-select-group'].show(this.selectedGroup ? [this.selectedGroup] : null)
		},
		completeSelectedGroup(selectedGroups) {
			if (selectedGroups) {
				this.setDraftGroup(selectedGroups[0])
				this.setDraftGroupId(selectedGroups[0].id)
			} else {
				this.initSelectedGroups()
				this.setDraftGroup(null)
				this.setDraftGroupId(null)
			}
			this.setBudgetId(null)
		},
		validation() {
			this.isValid = false
			let form = {}
			Object.keys(this.$refs).forEach(key => {
				if (!this.isValid && this.$refs[key].isRequired && !this.$refs[key].value) {
					this.isValid = true
					throw 'invalid'
				}
				if (this.$refs[key].isRequired !== undefined) form[key] = this.$refs[key].value ?? ''
			})
			if (this.draftInfo.approvalLine.length === 0) {
				this.isValid = true
				throw 'approvalLine'
			}
			this.setDraftInfo({ ...this.draftInfo, ...form })
		},
	},
}
</script>

<style lang="scss" scoped>
.regist-step1-wrapper {
	h5 {
		font-weight: 700;
		margin-bottom: 30px;
	}
	.type-member {
		display: flex;
		gap: 10px;
		line-height: 24px;
		img {
			width: 24px;
			height: 24px;
			border-radius: 24px;
			border: solid 1px #ccc;
		}
	}
	.type-group {
		height: 67px;
		button {
			position: relative;
			top: -52px;
			left: 250px;
			display: flex;
			width: 400px;
			height: 36px;
			padding: 12px 6px;
			justify-content: right;
			&.delete {
				width: 50px;
				left: 562px;
			}
		}
	}
	.approval-info-wrapper {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		.error {
			display: flex;
			span {
				font-size: 0.75rem;
				color: $RED;
				margin-left: 6px;
			}
		}
		div {
			display: flex;
			&:nth-child(1) {
				justify-content: space-between;
			}
		}
		.approval-item-wrapper {
			display: flex;
			gap: 20px;
			& > div:nth-child(2) {
				display: flex;
				gap: 20px;
				padding-left: 20px;
				border-left: 1px solid $INPUT;
			}
		}
	}
	.list-no-data {
		display: flex;
		justify-content: space-between;
		width: 33%;
		font-size: 14px;
		padding: 5px 0px;
		p {
			color: $SECONDARY_2;
			font-weight: 400;
		}
		span {
			cursor: pointer;
			text-decoration-line: underline;
			font-weight: 500;
			color: $BLUE;
		}
	}
}
</style>
