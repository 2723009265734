import inspectAPI from '@/services/api/inventory/inspect'

const inspectCategory = {
	namespaced: true,
	state: {
		tab: 1,
		inspectList: {},
		inspect: {},
		registers: [],
	},
	getters: {
		getTab: state => state.tab,
		getInspectList: state => state.inspectList,
		getInspect: state => state.inspect,
		getRegisters: state => state.registers,
	},
	mutations: {
		setTab: (state, payload) => {
			state.tab = payload
		},
		setInspectList: (state, payload) => {
			state.inspectList = payload
		},
		setInspect: (state, payload) => {
			state.inspect = payload
		},
		setRegisters: (state, payload) => {
			state.registers = payload
		},
	},
	actions: {
		lookupInspectList: async ({ commit }, payload) => {
			const { instId, offset, length, orders, ordersDirection, status, categoriesId, registersId, startDatetime, endDatetime } = payload
			const response = await inspectAPI.lookupInspectList(
				instId,
				offset,
				length,
				orders,
				ordersDirection,
				status,
				categoriesId,
				registersId,
				startDatetime,
				endDatetime
			)
			commit('setInspectList', response.data)
		},
		lookupInspect: async ({ commit }, payload) => {
			const { instId, id } = payload
			const response = await inspectAPI.lookupInspect(instId, id)
			commit('setInspect', response.data)
		},
		lookupRegisters: async ({ commit }, instId) => {
			const response = await inspectAPI.lookupRegisters(instId)
			commit('setRegisters', response.data.registers)
		},
		editInspect: async ({ commit }, payload) => {
			const { instId, formData } = payload
			await inspectAPI.editInspect(instId, formData)
		},
	},
}

export default inspectCategory
