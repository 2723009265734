const Classification = {
	CHEMICAL: 'CAT_CHEMICAL', //화학물질
	CHROMATOGRAPHY: 'CAT_CHROMATOGRAPHY', //분석용 칼럼
	LABWARE: 'CAT_LABWARE', //연구장비
	PROTECTION: 'CAT_PROTECTION', //보호구
	ETC: 'CAT_ETC', //소모품
	ALL: 'CAT_ALL',
	typeToString: type => {
		switch (type) {
			case Classification.CHEMICAL:
				return '화학물질'
			case Classification.CHROMATOGRAPHY:
				return '분석용 칼럼'
			case Classification.LABWARE:
				return '연구장비'
			case Classification.PROTECTION:
				return '보호구'
			case Classification.ETC:
				return '소모품'
			case Classification.ALL:
				return '물품 목록'
		}
	},
	typeToClassification: type => {
		switch (type) {
			case Classification.CHEMICAL:
				return 'chemical'
			case Classification.CHROMATOGRAPHY:
				return 'chromatography'
			case Classification.LABWARE:
				return 'labware'
			case Classification.PROTECTION:
				return 'protection'
			case Classification.ETC:
				return 'etc'
			case Classification.ALL:
				return 'whole'
		}
	},
	typeToColor: type => {
		switch (type) {
			case Classification.CHEMICAL:
				return '#FEC741'
			case Classification.CHROMATOGRAPHY:
				return '#DD6FB2'
			case Classification.LABWARE:
				return '#0dc577'
			case Classification.PROTECTION:
				return '#8968f7'
			case Classification.ETC:
				return '#85B5FF'
		}
	},
	typeToClass: type => {
		switch (type) {
			case Classification.CHEMICAL:
				return 'yellow'
			case Classification.CHROMATOGRAPHY:
				return 'purple'
			case Classification.LABWARE:
				return 'green'
			case Classification.PROTECTION:
				return 'violet'
			case Classification.ETC:
				return 'skyblue'
		}
	},
	typeToCategory: type => {
		switch (type) {
			case 'chemical':
				return '화학물질'
			case 'chromatography':
				return '분석용 칼럼'
			case 'labware':
				return '연구장비'
			case 'protection':
				return '보호구'
			case 'etc':
				return '소모품'
		}
	},
}

const Category = {
	REAGENT: `${Classification.CHEMICAL}_REAGENT`, // 시약
	MADE_REAGENT: `${Classification.CHEMICAL}_MADE_REAGENT`, // 제조 시약
	RAW_MATERIAL: `${Classification.CHEMICAL}_RAWMATERIAL`, // 원료
	MADE_RAW_MATERIAL: `${Classification.CHEMICAL}_MADE_RAWMATERIAL`, // 제조 원료
	STANDARD: `${Classification.CHEMICAL}_STANDARD`, // 표준품
	GAS: `${Classification.CHEMICAL}_GAS`, // 가스
	EQUIPMENT: `${Classification.LABWARE}_EQUIPMENT`, // 기계
	TOOL: `${Classification.LABWARE}_TOOL`, // 기구
	PROTECTION: `${Classification.PROTECTION}`, // 보호구
	ETC: `${Classification.ETC}`, // 소모품
	CHROMATOGRAPHY: `${Classification.CHROMATOGRAPHY}`,
	typeToString: type => {
		switch (type) {
			case Category.REAGENT:
				return '시약'
			case Category.MADE_REAGENT:
				return '제조 시약'
			case Category.RAW_MATERIAL:
				return '원료'
			case Category.MADE_RAW_MATERIAL:
				return '제조 원료'
			case Category.STANDARD:
				return '표준품'
			case Category.GAS:
				return '가스'
			case Category.CHROMATOGRAPHY:
				return '분석용칼럼'
			case Category.EQUIPMENT:
				return '기계'
			case Category.TOOL:
				return '기구'
			case Category.PROTECTION:
				return '보호구'
			case Category.ETC:
				return '소모품'
		}
	},
}

const RegistType = {
	AUTO: {
		IMAGE: 'AUTO_IMAGE',
		DOC: 'AUTO_DOC',
		LMS: 'AUTO_LMS',
	},
	MANUAL: {
		FILE: 'MANUAL_FILE',
		CARGO: 'MANUAL_CARGO',
		DIRECT: 'MANUAL_DIRECT',
		SUBDIVIDE: 'MANUAL_SUBDIVIDE',
		QR: 'MANUAL_QR',
		SEARCH: 'MANUAL_SEARCH',
	},
	DRAFT: {
		LMS: 'DRAFT_LMS',
		EXTERNAL: 'DRAFT_EXTERNAL',
	},
	isTypeAuto: type => {
		return RegistType.AUTO.IMAGE === type || RegistType.AUTO.DOC === type || RegistType.AUTO.LMS === type
	},
	typeToString: type => {
		switch (type) {
			case RegistType.AUTO.IMAGE:
				return '사진 분석'
			case RegistType.AUTO.DOC:
				return 'MSDS / COA 분석'
			case RegistType.AUTO.LMS:
				return '스토어'
			case RegistType.MANUAL.FILE:
				return '엑셀 업로드'
			case RegistType.MANUAL.CARGO:
				return '입고 등록'
			case RegistType.MANUAL.DIRECT:
				return '직접입력'
			case RegistType.MANUAL.QR:
				return 'QR 검색'
			case RegistType.MANUAL.SEARCH:
				return 'DB 검색'
			case RegistType.MANUAL.SUBDIVIDE:
				return '소분'
			case RegistType.DRAFT.LMS:
				return '기안을 통한 등록(랩매니저 스토어)'
			case RegistType.DRAFT.EXTERNAL:
				return '기안을 통한 등록(외부거래처)'
			default:
				return '-'
		}
	},
}

// 220824 : Gen2 이후 코드 작성
const LargeClass = {
	CHEMICAL: 'CAT_CHEMICAL', //화학물질
	CHROMATOGRAPHY: 'CAT_CHROMATOGRAPHY', //분석용 칼럼
	LABWARE: 'CAT_LABWARE', //연구장비
	PROTECTION: 'CAT_PROTECTION', //보호구
	ETC: 'CAT_ETC', //소모품
}

const LargeClassToString = type => {
	switch (type) {
		case LargeClass.CHEMICAL:
			return '화학물질'
		case LargeClass.CHROMATOGRAPHY:
			return '분석용 칼럼'
		case LargeClass.LABWARE:
			return '연구장비'
		case LargeClass.PROTECTION:
			return '보호구'
		case LargeClass.ETC:
			return '소모품'
	}
	return null
}

const MiddleClass = {
	REAGENT: `${LargeClass.CHEMICAL}_REAGENT`, // 시약
	MADE_REAGENT: `${LargeClass.CHEMICAL}_MADE_REAGENT`, // 제조 시약
	RAW_MATERIAL: `${LargeClass.CHEMICAL}_RAWMATERIAL`, // 원료
	GAS: `${LargeClass.CHEMICAL}_GAS`, // 가스
	STANDARD: `${LargeClass.CHEMICAL}_STANDARD`, // 표준품
	EQUIPMENT: `${LargeClass.LABWARE}_EQUIPMENT`, // 기계
	TOOL: `${LargeClass.LABWARE}_TOOL`, // 기구
	PROTECTION: `${LargeClass.PROTECTION}`, // 보호구
	ETC: `${LargeClass.ETC}`, // 소모품
	CHROMATOGRAPHY: `${LargeClass.CHROMATOGRAPHY}`, // 분석용 칼럼
}

const MiddleClassList = {
	CAT_CHEMICAL: [MiddleClass.REAGENT, MiddleClass.MADE_REAGENT, MiddleClass.RAW_MATERIAL, MiddleClass.STANDARD],
	CAT_CHROMATOGRAPHY: [MiddleClass.CHROMATOGRAPHY],
	CAT_LABWARE: [MiddleClass.EQUIPMENT, MiddleClass.TOOL],
	CAT_TOOL: [MiddleClass.TOOL],
	CAT_PROTECTION: [MiddleClass.PROTECTION],
	CAT_ETC: [MiddleClass.ETC],
}

const MiddleClassToString = type => {
	switch (type) {
		case MiddleClass.REAGENT:
			return '시약'
		case MiddleClass.MADE_REAGENT:
			return '제조 시약'
		case MiddleClass.RAW_MATERIAL:
			return '원료'
		case MiddleClass.STANDARD:
			return '표준품'
		case MiddleClass.GAS:
			return '가스'
		case MiddleClass.CHROMATOGRAPHY:
			return '분석용칼럼'
		case MiddleClass.EQUIPMENT:
			return '기계'
		case MiddleClass.TOOL:
			return '기구'
		case MiddleClass.PROTECTION:
			return '보호구'
		case MiddleClass.ETC:
			return '소모품'
	}
	return null
}

const FindLargeClass = middleClass => {
	let result = null
	Object.keys(MiddleClassList).forEach(key => {
		if (MiddleClassList[key].includes(middleClass)) {
			result = key
		}
	})
	return result
}

export { Category, Classification, RegistType, LargeClass, MiddleClass, LargeClassToString, MiddleClassToString, MiddleClassList, FindLargeClass }
