<template>
	<div class="page-wrapper">
		<fixed-header :title="tt('등록대기 중인 물품')" />
		<div class="table-wrapper">
			<wait-table :list="list"></wait-table>
			<floating-menu v-if="selectedItems.length > 0"></floating-menu>
		</div>
		<pagination ref="pagination" :total="count" :propsOffset="loadParams.offset" @page="pagination"></pagination>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import Pagination from '@/views/common/components/Pagination.vue'
import FixedHeader from '../components/wait/FixedHeader.vue'
import FloatingMenu from '../components/wait/FloatingMenu.vue'
import WaitTable from '../components/waitTable'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
	components: {
		Pagination,
		FixedHeader,
		FloatingMenu,
		WaitTable,
	},
	computed: {
		...mapState('inventoryGen2/wait', ['list', 'count', 'selectedItems', 'loadParams']),
	},
	mounted() {
		if (this.previousPage?.name !== PageName.InventoryGen2.WaitDetail) {
			this.setSelectedItems([])
			this.initFilterCookies()
			this.$refs['pagination'].init(1)
		}
	},
	methods: {
		...mapMutations('inventoryGen2/wait', ['setSelectedItems', 'initFilterCookies']),
		...mapActions('inventoryGen2/wait', ['setLoadParams']),
		async pagination(page) {
			await this.setLoadParams(page)
		},
	},
	// inside vue-router
	// refs : https://forum.vuejs.org/t/get-the-previous-url-on-inside-a-component-with-vue-router/18010/4
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 215px);
	}
}
</style>
