<template>
	<div class="information-wrapper">
		<p class="warning"><b>주의:</b> 기한 내 입금 되지 않을 경우, 일부 이용자의 접속 불가 등 서비스 이용에 문제가 발생할 수 있습니다.</p>
		<div class="info-list">
			<div>
				<p class="name">입금 기한</p>
				<p>
					<b>
						{{ formatExpireDate(licenseManagement.schedule.expireDatetime) }}
					</b>
				</p>
			</div>
			<div>
				<p class="name">계좌 정보</p>
				<p>
					{{
						tt(
							licenseManagement.schedule.virtualAccountBankName + ' ${1} (예금주: ${2})',
							licenseManagement.schedule.virtualAccountNumber,
							licenseManagement.schedule.virtualAccountHolder
						)
					}}
				</p>
			</div>
			<div>
				<p class="name">결제 금액</p>
				<p class="blue">
					{{ tt('₩${1}', formatNumberLocale(licenseManagement.schedule.totalPrice)) }}
				</p>
			</div>
		</div>
		<button class="btn-cancel" @click="$refs['modal-confirm'].show()">
			{{ tt('주문 취소') }}
		</button>
		<modal-confirm
			ref="modal-confirm"
			:title="tt('주문취소')"
			:content="tt('정말로 라이선스 구매 주문을 취소하시겠습니까?')"
			:confirmText="tt('주문취소')"
			variant="warning"
			@confirm="cancelOrder"
		></modal-confirm>
	</div>
</template>
<script>
import ModalConfirm from '@/views/common/components/modalGen2/ModalConfirm.vue'
import MixinLicense from '@/mixins/license'
export default {
	components: {
		ModalConfirm,
	},
	mixins: [MixinLicense],
	methods: {
		async cancelOrder() {
			const response = await this.cancelWaitingLicense()
			if (response.status == 205) {
				this.$root.toast('라이선스 주문취소', '라이선스 구매주문이 취소되었습니다.', 'success')
				this.loadLicense()
			}
			this.$refs['modal-confirm'].hide()
			await this.loadLicenseManagement()
			this.$emit('hide')
		},
	},
}
</script>
<style lang="scss" scoped>
.information-wrapper {
	padding: 20px 0;
	border-top: 1px solid $INPUT;
	display: flex;
	flex-direction: column;
	gap: 15px;
	.warning {
		font-size: 14px;
		color: $RED;
		word-break: keep-all;
	}
	.info-list {
		font-size: 14px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		& > div {
			display: flex;
			justify-content: space-between;
			.name {
				color: $SECONDARY_2;
			}
			.blue {
				color: $BLUE;
			}
		}
	}
	.btn-cancel {
		margin-top: 5px;
		justify-content: center;
		border-radius: 5px;
		height: 48px;
		color: $RED;
		background-color: $SUB-RED;
		width: 100%;
		font-size: 14px;
	}
}
</style>
