<template>
	<div class="search-sort-tab">
		<div class="tab-inner">
			<span v-for="(item, index) in tabList" :key="index">
				<input type="radio" :id="'tab' + index + 1" name="TabList" :value="index" v-model="selectTabId" />
				<label :for="'tab' + index + 1">
					{{ tt(item.name) }}
					<!-- <span class="count">{{ item.count }}</span> -->
				</label>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchSortTab',
	components: {},
	props: {
		tabList: {
			type: Array,
			default: () => {},
		},
		selectTab: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		selectTabId: {
			get() {
				return this.selectTab
			},
			set(newValue) {
				this.$emit('change', newValue)
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.search-sort-tab {
	width: 100%;
	border-bottom: 1px solid $DIVIDER;
	display: flex;
	align-items: center;
	height: 40px;
	.tab-inner {
		display: flex;
		align-items: center;
		padding-left: 20px;
		span {
			cursor: pointer;
			input[type='radio'] {
				width: 0;
				height: 0;
				opacity: 0;
				visibility: hidden;
			}
			input[type='radio']:checked ~ label {
				font-weight: 700;
				color: black;
				background-image: url(~@/views/common/components/modalGen2/svg/sort-checked.svg);
				// background-position: center left 0;
				background-size: 14px;
				background-repeat: no-repeat;
				padding-left: 18px;
			}
			label {
				font-size: 0.875rem;
				display: inline-block;
				color: $SECONDARY_2;
				font-weight: 400;
				margin-right: 20px;
				cursor: pointer;
			}
		}
	}
}
</style>
