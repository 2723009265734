import cargoAPI from '@/services/api/purchase/cargo'
import categoryAPI from '@/services/api/category'

const state = {
	item: null,
	categoryInfo: null,
	categoryFieldsByType: null,
}
const getters = {
	item: state => state.item,
	legals: state => {
		let result = {}
		if (state.item && state.item.legalList) {
			state.item.legalList.forEach(item => {
				result[item.legalId] = item
			})
			return result
		} else {
			return null
		}
	},
	inventoryFields: state => {
		if (state.categoryInfo) {
			return state.categoryInfo?.fields?.inventoryFields
		} else {
			return state.categoryFieldsByType?.inventoryFields.map(item => {
				item.isActive = true
				return item
			})
		}
	},
	managementFields: state => {
		if (state.categoryInfo) {
			return state.categoryInfo?.fields?.managementFields
		} else {
			return state.categoryFieldsByType?.managementFields.map(item => {
				item.isActive = true
				return item
			})
		}
	},
	formShape: state => {
		if (state.item) {
			const form = {}
			Object.keys(state.item).forEach(key => {
				if (!['inventoryFields', 'managementFields'].includes(key)) {
					form[key] = state.item[key]
				} else {
					Object.keys(state.item[key]).forEach(innerKey => {
						switch (innerKey) {
							case 'volumeUnit':
								form[innerKey] = state.item[key]?.[innerKey]
								// 잔여 용량 대응
								form['currentVolumeUnit'] = state.item?.[key]?.['currentVolumeUnit']
									? state.item?.[key]?.['currentVolumeUnit']
									: state.item['volumeUnit']
								break
							case 'images':
								form['image'] = state.item[key]['images']
							default:
								form[innerKey] = state.item[key]?.[innerKey]
								break
						}
					})
				}
			})
			return form
		}
		return null
	},
	draftId: state => state?.item?.draftId,
}
const mutations = {
	setCargoItem: (state, item) => {
		state.item = item
	},
	setCategoryInfo: (state, info) => {
		state.categoryInfo = info
	},
	setCategoryFieldsByType: (state, fields) => {
		state.categoryFieldsByType = fields
	},
	initCargoItem: state => {
		state.item = null
		state.categoryInfo = null
		state.categoryFieldsByType = null
	},
}
const actions = {
	getCargoItem: async ({ commit, rootGetters }, cargoId) => {
		const response = await cargoAPI.getCargoDetail(rootGetters.instituteId, { id: cargoId })
		commit('setCargoItem', response.data)
	},
	getCategoryInfo: async ({ commit, rootGetters }, id) => {
		const response = await categoryAPI.getCategoryInfo(rootGetters.instituteId, { id: id })
		await commit('setCategoryInfo', response.data)
	},
	getCategoryFieldsByType: async ({ commit, rootGetters }, type) => {
		const response = await categoryAPI.getCategoryFieldsByType(rootGetters.instituteId, { type: type })
		await commit('setCategoryFieldsByType', response.data)
	},
	processCargoItem: async ({ rootGetters }, ids) => {
		await cargoAPI.processCargoItem(rootGetters.instituteId, { ids: ids })
	},
	deleteCargoItem: async ({ commit, rootGetters }, ids) => {
		await cargoAPI.deleteCargoItem(rootGetters.instituteId, { ids: ids })
	},
}

export default { namespaced: true, state, getters, mutations, actions }
