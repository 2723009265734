<template>
	<div class="card possessionStatus" :class="!displayCard ? 'displayOff' : ''">
		<div class="card-title">
			<div class="imgbox">
				<img src="@/assets/svg/dashboard-icon3.svg" alt="icon" />
			</div>
			<h4 class="Btn__hover-balloon-w" @click="goPage">
				{{ tt('물품 보유 현황') }}
				<div class="balloon">
					<p>{{ tt('물품목록으로 이동합니다') }}</p>
				</div>
				<button>
					<img src="@/assets/svg/arrow-diagonal.svg" alt="icon" />
				</button>
			</h4>
			<div class="ml-auto switch-setting" v-if="setting == true">
				<h5>{{ tt('대시보드에 노출') }}</h5>
				<label class="switch">
					<input type="checkbox" v-model="displayCard" @change="toggleCard(id, displayCard)" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="card-box">
			<div class="box-title">
				<h5>{{ tt('물품 보유 현황') }}</h5>
			</div>
			<div class="box-content">
				<div class="chart-wrap">
					<div class="no-data_chart" v-if="!showChart"></div>
					<GChart type="PieChart" :data="chartData" :options="chartOptions" :events="chartEvents" ref="chart" />
					<div class="chart-title">
						<h2>{{ list.total }}{{ tt('개') }}</h2>
						<p>{{ tt('총 보유 물품 수') }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="card-box">
			<div class="box-title">
				<h5>{{ tt('보유 물품 수') }}</h5>
			</div>
			<div class="box-content">
				<div class="box-inner" v-for="(item, index) in list.classification" :key="index">
					<span class="subtitle">{{ tt(matchSubtitle(index)) }}</span>

					<ul>
						<li v-for="subitem in item" :key="subitem.id" @click="goPageDetail(subitem.id, subitem.classification)">
							<div class="circle" :class="matchColors(subitem.classification)"></div>
							<p>{{ subitem.name }}</p>
							<h5>{{ subitem.count.toLocaleString() }}{{ tt('개') }}</h5>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { Classification } from '@/utils/define/ItemCode'
import PageName from '@/utils/define/PageName'

export default {
	name: 'CardPossessionStatus',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Object,
		},
		toggleCard: {
			type: Function,
			default: () => {},
		},
	},
	components: {
		GChart,
	},
	computed: {
		chartData() {
			return this.makeCharts()
		},
		chartOptions() {
			return {
				legend: 'none',
				colors: this.colorList,
				width: 330,
				height: 330,
				chartArea: {
					width: '90%',
					height: '90%',
				},
				backgroundColor: 'transparent',
				pieSliceBorderColor: 'white',
				pieSliceText: 'none',
				pieHole: 0.8,
				tooltip: {
					isHtml: true,
				},
			}
		},
	},
	mounted() {
		this.displayCard = this.visible
		this.makeCharts()
	},
	data() {
		return {
			displayCard: true,
			colorList: [],
			showChart: true,
			filterChart: [],
			chartEvents: {
				select: () => {
					const chart = this.$refs.chart.chartObject
					const row = chart.getSelection()[0].row
					this.goFilter(row)
				},
			},
		}
	},
	methods: {
		makeCharts() {
			const summary = this.list.classification
			this.showChart = false
			this.filterChart = []

			if (summary != null) {
				const entries = Object.values(summary)
				let chartTitleArr = []

				if (entries.length > 0) {
					for (let item of entries) {
						for (let subitem of item) {
							this.filterChart.push({
								classification: subitem.classification,
								id: subitem.id,
							})

							const chartArr = [
								subitem.name,
								subitem.count,
								subitem.name +
									':' +
									'\n' +
									'\n' +
									subitem.count +
									this.tt('개') +
									'(' +
									Math.round((subitem.count / this.list.total) * 100) +
									'%)',
							]
							if (subitem.count > 0) {
								this.showChart = true
							}
							chartTitleArr.push(chartArr)
							//push colors
							this.colorList.push(this.makeColors(subitem.classification))
						}
					}

					chartTitleArr.unshift(['title', 'count', { type: 'string', role: 'tooltip', p: { html: true } }])
					return chartTitleArr
				} else {
					chartTitleArr.unshift(['title', 'count', { type: 'string', role: 'tooltip', p: { html: true } }])
					this.colorList.push('')
					const emptyArr = [null, 0, '']
					chartTitleArr.push(emptyArr)

					return chartTitleArr
				}
			}
		},
		makeColors(classification) {
			return Classification.typeToColor(classification)
		},
		matchColors(classification) {
			return Classification.typeToClass(classification)
		},
		matchSubtitle(index) {
			return Classification.typeToString(index)
		},
		goPage() {
			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					classification: 'ALL',
				},
			})
		},
		goPageDetail(id, classification) {
			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					classification: classification,
					categoriesId: [id],
				},
			})
		},
		goFilter(index) {
			let classification = this.filterChart[index].classification
			let id = this.filterChart[index].id

			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					classification: classification,
					categoriesId: [id],
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';

.possessionStatus {
	.chart-wrap {
		display: flex;
		align-items: center;
		height: auto;
		justify-content: center;
	}
	li {
		transition: 0.3s;
		background-color: $LAB_WHITE;
		cursor: pointer;
		&:hover {
		}
		a {
			display: flex;
			width: 100%;
			height: inherit;
			align-items: center;
			h5 {
				margin-left: auto;
			}
		}
	}
}

.no-data_chart {
	position: absolute;
	width: 300px;
	height: 300px;
	background-color: $LAB_WHITE;
	border-radius: 300px;
	border: 30px solid $GREY_1;
}
</style>
