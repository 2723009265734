<template>
	<div class="sub-wrap">
		<span>{{ tt(title) }}</span>
		<ul>
			<li
				v-for="(item, index) in list"
				:key="`category${index}`"
				ref="list"
				:class="{ active: item.id === activeId }"
				@click="detailCategory(item.id)"
			>
				<img :src="require(`@/views/inventoryGen2/svg/category/${item.type}.svg`)" />
				<p>{{ tt(item.name) }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		// title: {
		// 	type: String,
		// 	default: '',
		// },
		list: {
			type: Array,
			default: () => [],
		},
		activeId: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
	},
	computed: {
		title() {
			return {
				chemical: '화학물질',
				chromatography: '분석용 칼럼',
				labware: '연구장비',
				protection: '보호구',
				etc: '소모품',
			}[this.type]
		},
	},
	methods: {
		detailCategory(id) {
			this.$emit('detail', id)
		},
	},
}
</script>

<style lang="scss" scoped>
.sub-wrap {
	margin-bottom: 10px;
	width: 250px;
	white-space: nowrap;
	span {
		font-size: 12px;
		color: $PLACEHOLDER;
	}
	img {
		width: 30px;
		height: 30px;
	}
	li {
		padding: 5px;
		display: flex;
		gap: 10px;
		border-radius: 10px;
		cursor: pointer;
		p {
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 14px;
			line-height: 28px;
		}
		&.active {
			font-weight: 700;
			background: $SUB-YELLOW_100;
		}
	}
}
</style>
