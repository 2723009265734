import inventoryAPI from '@/services/api/inventory'
import storageAPI from '@/services/api/institute/storage'

const inventoryCategory = {
	namespaced: true,
	state: {
		chemTab: {
			id: 'ALL',
			name: '화학물질 전체',
			classification: 'CAT_CHEMICAL',
			type: 'ALL',
		},
		chroTab: {
			id: 'ALL',
			name: '분석용 칼럼 전체',
			classification: 'CAT_CHROMATOGRAPHY',
			type: 'ALL',
		},
		labTab: {
			id: 'ALL',
			name: '연구장비 전체',
			classification: 'CAT_LABWARE',
			type: 'ALL',
		},
		proTab: {
			id: 'ALL',
			name: '보호구 전체',
			classification: 'CAT_PROTECTION',
			type: 'ALL',
		},
		etcTab: {
			id: 'ALL',
			name: '소모품 전체',
			classification: 'CAT_ETC',
			type: 'ALL',
		},
		allTab: {
			id: 'ALL',
			name: '물품 전체',
			classification: 'CAT_ALL',
			type: 'ALL',
		},
		chemList: [
			{ key: 'check', name: 'check', value: true, fixed: true },
			{
				key: 'status',
				name: '상태',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'mngcode',
				name: '관리번호',
				width: 100,
				value: true,
				sort: true,
				order: 'MNGCODE',
				direction: 'DESC',
			},
			{
				key: 'incompanyMngcode',
				name: '사내관리번호',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'category',
				name: '카테고리',
				width: 169,
				value: true,
				sort: false,
			},
			{
				key: 'name',
				name: '정보',
				width: 346,
				value: true,
				sort: true,
				order: 'NAME',
				direction: 'DESC',
			},
			{
				key: 'productCode',
				name: '제품번호',
				width: 100,
				value: true,
				sort: true,
				order: 'PRODUCT_CODE',
				direction: 'DESC',
			},
			{
				key: 'lotno',
				name: 'LOT No',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'casno',
				name: 'CAS No',
				width: 90,
				value: true,
				sort: true,
				order: 'CASNO',
				direction: 'DESC',
			},
			{
				key: 'volume',
				name: '사이즈(용량)',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'currentVolume',
				name: '잔여 용량',
				width: 90,
				value: true,
				sort: true,
				order: 'CURRENT_VOLUME',
				direction: 'DESC',
			},
			{
				key: 'location',
				name: '위치',
				width: 190,
				value: true,
				sort: true,
			},
			{
				key: 'storage',
				name: '보관함',
				width: 190,
				value: true,
				sort: false,
			},
			{
				key: 'holder',
				name: '사용자',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'assignee',
				name: '담당자',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'createDatetime',
				name: '등록일',
				width: 190,
				value: true,
				sort: true,
				order: 'CREATE_DATETIME',
				direction: 'DESC',
			},
			{
				key: 'expireDatetime',
				name: '만료일',
				width: 150,
				value: true,
				sort: false,
			},
		],
		chroList: [
			{ key: 'check', name: 'check', value: true, fixed: true },
			{
				key: 'status',
				name: '상태',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'mngcode',
				name: '관리번호',
				width: 100,
				value: true,
				sort: true,
				order: 'MNGCODE',
				direction: 'DESC',
			},
			{
				key: 'incompanyMngcode',
				name: '사내관리번호',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'category',
				name: '카테고리',
				width: 169,
				value: true,
				sort: false,
			},
			{
				key: 'name',
				name: '정보',
				width: 346,
				value: true,
				sort: true,
				order: 'NAME',
				direction: 'DESC',
			},
			{
				key: 'productCode',
				name: '제품번호',
				width: 100,
				value: true,
				sort: true,
				order: 'PRODUCT_CODE',
				direction: 'DESC',
			},
			{ key: 'usp', name: 'USP', width: 90, value: true, sort: false },
			{
				key: 'innerDiameter',
				name: '내경(ID)',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'particleSize',
				name: 'Particle Size',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'length',
				name: '길이',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'location',
				name: '위치',
				width: 190,
				value: true,
				sort: true,
			},
			{
				key: 'storage',
				name: '보관함',
				width: 190,
				value: true,
				sort: false,
			},
			{
				key: 'useCount',
				name: '사용횟수',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'assignee',
				name: '담당자',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'createDatetime',
				name: '등록일',
				width: 190,
				value: true,
				sort: true,
				order: 'CREATE_DATETIME',
				direction: 'DESC',
			},
		],
		labList: [
			{ key: 'check', name: 'check', value: true, fixed: true },
			{
				key: 'status',
				name: '상태',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'mngcode',
				name: '관리번호',
				width: 100,
				value: true,
				sort: true,
				order: 'MNGCODE',
				direction: 'DESC',
			},
			{
				key: 'incompanyMngcode',
				name: '사내관리번호',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'category',
				name: '카테고리',
				width: 169,
				value: true,
				sort: false,
			},
			{
				key: 'name',
				name: '정보',
				width: 346,
				value: true,
				sort: true,
				order: 'NAME',
				direction: 'DESC',
			},
			{
				key: 'brand',
				name: '브랜드',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'modelName',
				name: '모델명',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'location',
				name: '위치',
				width: 190,
				value: true,
				sort: true,
				order: 'LOCATION',
				direction: 'DESC',
			},
			{
				key: 'storage',
				name: '보관함',
				width: 190,
				value: true,
				sort: false,
			},
			{
				key: 'assignee',
				name: '담당자',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'createDatetime',
				name: '등록일',
				width: 190,
				value: true,
				sort: true,
				order: 'CREATE_DATETIME',
				direction: 'DESC',
			},
		],
		proList: [
			{ key: 'check', name: 'check', value: true, fixed: true },
			{
				key: 'status',
				name: '상태',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'mngcode',
				name: '관리번호',
				width: 100,
				value: true,
				sort: true,
				order: 'MNGCODE',
				direction: 'DESC',
			},
			{
				key: 'incompanyMngcode',
				name: '사내관리번호',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'category',
				name: '카테고리',
				width: 169,
				value: true,
				sort: false,
			},
			{
				key: 'name',
				name: '정보',
				width: 346,
				value: true,
				sort: true,
				order: 'NAME',
				direction: 'DESC',
			},
			{
				key: 'brand',
				name: '브랜드',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'location',
				name: '위치',
				width: 190,
				value: true,
				sort: true,
				order: 'LOCATION',
				direction: 'DESC',
			},
			{
				key: 'storage',
				name: '보관함',
				width: 190,
				value: true,
				sort: false,
			},
			{
				key: 'assignee',
				name: '담당자',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'createDatetime',
				name: '등록일',
				width: 190,
				value: true,
				sort: true,
				order: 'CREATE_DATETIME',
				direction: 'DESC',
			},
		],
		etcList: [
			{ key: 'check', name: 'check', value: true, fixed: true },
			{
				key: 'status',
				name: '상태',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'mngcode',
				name: '관리번호',
				width: 100,
				value: true,
				sort: true,
				order: 'MNGCODE',
				direction: 'DESC',
			},
			{
				key: 'incompanyMngcode',
				name: '사내관리번호',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'category',
				name: '카테고리',
				width: 169,
				value: true,
				sort: false,
			},
			{
				key: 'name',
				name: '정보',
				width: 346,
				value: true,
				sort: true,
				order: 'NAME',
				direction: 'DESC',
			},
			{
				key: 'etcVolume',
				name: '잔여 수량',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'location',
				name: '위치',
				width: 190,
				value: true,
				sort: true,
				order: 'LOCATION',
				direction: 'DESC',
			},
			{
				key: 'storage',
				name: '보관함',
				width: 190,
				value: true,
				sort: false,
			},
			{
				key: 'assignee',
				name: '담당자',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'createDatetime',
				name: '등록일',
				width: 190,
				value: true,
				sort: true,
				order: 'CREATE_DATETIME',
				direction: 'DESC',
			},
		],
		allList: [
			{ key: 'check', name: 'check', value: true, fixed: true },
			{
				key: 'status',
				name: '상태',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'assigneeGroup',
				name: '그룹',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'mngcode',
				name: '관리번호',
				width: 100,
				value: true,
				sort: true,
				order: 'MNGCODE',
				direction: 'DESC',
			},
			{
				key: 'incompanyMngcode',
				name: '사내관리번호',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'category',
				name: '카테고리',
				width: 169,
				value: true,
				sort: false,
			},
			{
				key: 'name',
				name: '정보',
				width: 346,
				value: true,
				sort: true,
				order: 'NAME',
				direction: 'DESC',
			},
			{
				key: 'brand',
				name: '브랜드',
				width: 90,
				value: true,
				sort: false,
			},
			{
				key: 'productCode',
				name: '제품번호',
				width: 100,
				value: false,
				sort: true,
				order: 'PRODUCT_CODE',
				direction: 'DESC',
			},
			{
				key: 'casno',
				name: 'CAS No',
				width: 90,
				value: false,
				sort: true,
				order: 'CASNO',
				direction: 'DESC',
			},
			{
				key: 'volume',
				name: '사이즈(용량)',
				width: 90,
				value: false,
				sort: false,
			},
			{
				key: 'currentVolume',
				name: '잔여 용량',
				width: 90,
				value: false,
				sort: true,
				order: 'CURRENT_VOLUME',
				direction: 'DESC',
			},
			{ key: 'usp', name: 'USP', width: 90, value: false, sort: false },
			{
				key: 'innerDiameter',
				name: '내경(ID)',
				width: 90,
				value: false,
				sort: false,
			},
			{
				key: 'particleSize',
				name: 'Particle Size',
				width: 90,
				value: false,
				sort: false,
			},
			{
				key: 'length',
				name: '길이',
				width: 90,
				value: false,
				sort: false,
			},
			{
				key: 'useCount',
				name: '사용횟수',
				width: 90,
				value: false,
				sort: false,
			},
			{
				key: 'modelName',
				name: '모델명',
				width: 90,
				value: false,
				sort: false,
			},
			{
				key: 'etcVolume',
				name: '잔여 수량',
				width: 90,
				value: false,
				sort: false,
			},
			{
				key: 'location',
				name: '위치',
				width: 190,
				value: true,
				sort: true,
				order: 'LOCATION',
				direction: 'DESC',
			},
			{
				key: 'storage',
				name: '보관함',
				width: 190,
				value: true,
				sort: false,
			},
			{
				key: 'assignee',
				name: '담당자',
				width: 100,
				value: true,
				sort: false,
			},
			{
				key: 'createDatetime',
				name: '등록일',
				width: 190,
				value: true,
				sort: true,
				order: 'CREATE_DATETIME',
				direction: 'DESC',
			},
			{
				key: 'expireDatetime',
				name: '만료일',
				width: 150,
				value: false,
				sort: false,
			},
		],
		waitTab: 1,
		waitingList: {
			count: 0,
			list: [],
		},
		inventoryList: {
			count: 0,
			list: [],
		},
		inventory: {},
		assignees: [],
		registrants: [],
		logList: [],
		dumpList: [],
		status: '',
		product: [],
		ingredient: [],
		brand: [],
		bundleList: [],
		storageList: [],
		tagList: [],
		searchTagList: [],
		ids: [],
		selectDivide: [],
		unit: [],
		inventoryFilter: {
			status: [],
			registerMethods: [],
			categoriesId: [],
			storagesId: [],
			assigneesId: [],
			brandsId: [],
			expireDate: [],
			currentVolume: [],
			threshold: [],
			bundle: [],
			legalsId: [],
			groupsId: [],
		},
		dumpFilter: {
			startDatetime: null,
			endDatetime: null,
			status: [],
			categoriesId: [],
			assigneesId: [],
			dumpersId: [],
		},
	},
	getters: {
		getChemTab: state => state.chemTab,
		getChroTab: state => state.chroTab,
		getLabTab: state => state.labTab,
		getProTab: state => state.proTab,
		getEtcTab: state => state.etcTab,
		getChemList: state => state.chemList,
		getChroList: state => state.chroList,
		getLabList: state => state.labList,
		getProList: state => state.proList,
		getEtcList: state => state.etcList,
		getWaitTab: state => state.waitTab,
		getWaitingList: state => state.waitingList,
		getInventoryList: state => state.inventoryList,
		getInventory: state => state.inventory,
		getAssignees: state => state.assignees,
		getRegistrants: state => state.registrants,
		getLogList: state => state.logList,
		getDumpList: state => state.dumpList,
		getStatus: state => state.status,
		getProduct: state => state.product,
		getIngredient: state => state.ingredient,
		getBrand: state => state.brand,
		getBundleList: state => state.bundleList,
		getStorageList: state => state.storageList,
		getTagList: state => state.tagList,
		getIds: state => state.ids,
		getSelectDivide: state => state.selectDivide,
		getUnit: state => state.unit,
	},
	mutations: {
		setChemTab: (state, payload) => {
			state.chemTab = payload
		},
		setChroTab: (state, payload) => {
			state.chroTab = payload
		},
		setLabTab: (state, payload) => {
			state.labTab = payload
		},
		setProTab: (state, payload) => {
			state.proTab = payload
		},
		setEtcTab: (state, payload) => {
			state.etcTab = payload
		},
		setAllList: (state, payload) => {
			state.allList = payload
		},
		setChemList: (state, payload) => {
			state.chemList = payload
		},
		setChroList: (state, payload) => {
			state.chroList = payload
		},
		setLabList: (state, payload) => {
			state.labList = payload
		},
		setProList: (state, payload) => {
			state.proList = payload
		},
		setEtcList: (state, payload) => {
			state.etcList = payload
		},
		setWaitTab: (state, payload) => {
			state.waitTab = payload
		},
		setWaitingList: (state, payload) => {
			state.waitingList = payload
		},
		setInventoryList: (state, payload) => {
			state.inventoryList = payload
		},
		setInventory: (state, payload) => {
			state.inventory = payload
		},
		setAssignees: (state, payload) => {
			state.assignees = payload
		},
		setRegistrants: (state, payload) => {
			state.registrants = payload
		},
		setLogList: (state, payload) => {
			state.logList = payload
		},
		setDumpList: (state, payload) => {
			state.dumpList = payload
		},
		setStatus: (state, payload) => {
			state.status = payload
		},
		setProduct: (state, payload) => {
			state.product = payload
		},
		setIngredient: (state, payload) => {
			state.ingredient = payload
		},
		removeIngredient: (state, payload) => {
			state.ingredient = state.ingredient.filter((item, index) => index !== payload)
		},
		setBrand: (state, payload) => {
			state.brand = payload
		},
		setBundleList: (state, payload) => {
			state.bundleList = payload
		},
		setStorageList: (state, payload) => {
			state.storageList = payload
		},
		setTagList: (state, payload) => {
			state.tagList = payload
		},
		setSearchTagList: (state, payload) => {
			state.searchTagList = payload
		},
		setIds: (state, payload) => {
			state.ids = payload
		},
		setSelectDivide: (state, payload) => {
			state.selectDivide = payload
		},
		setUnit: (state, payload) => {
			state.unit = payload
		},
		setFilter: (state, payload) => {
			state.inventoryFilter = payload
		},
		setDumpFilter: (state, payload) => {
			state.dumpFilter = payload
		},
	},
	actions: {
		lookupWaitingList: async ({ commit }, payload) => {
			const { instId, offset, length, status, categoriesType, registersId, startDatetime, endDatetime } = payload
			const response = await inventoryAPI.lookupWaitingList(
				instId,
				offset,
				length,
				status,
				categoriesType,
				registersId,
				startDatetime,
				endDatetime
			)
			commit('setWaitingList', response.data)
		},
		lookupBundleList: async ({ commit }, payload) => {
			const { instId, categoryId, groupby, classification, keyword } = payload
			const response = await inventoryAPI.lookupBundleList(instId, categoryId, groupby, classification, keyword)
			commit('setBundleList', response.data)
		},
		lookupInventory: async ({ commit }, payload) => {
			const { instId, id } = payload
			const response = await inventoryAPI.lookupInventory(instId, id)
			commit('setInventory', response.data)
		},
		lookupAssignees: async ({ commit }, instId) => {
			const response = await inventoryAPI.lookupAssignees(instId)
			commit('setAssignees', response.data)
		},
		lookupRegistrants: async ({ commit }, instId) => {
			const response = await inventoryAPI.lookupRegistrants(instId)
			commit('setRegistrants', response.data)
		},
		createInventory: async ({ commit }, payload) => {
			const { instId, formData } = payload
			return await inventoryAPI.createInventory(instId, formData)
		},
		updateInventory: async ({ commit }, payload) => {
			const { instId, formData } = payload
			await inventoryAPI.updateInventory(instId, formData)
		},
		subdivideInventory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await inventoryAPI.subdivideInventory(instId, body)
		},
		deleteInventory: async ({ commit }, payload) => {
			const { instId, itemsId, memo } = payload
			await inventoryAPI.deleteInventory(instId, itemsId, memo)
		},
		completeInventory: async ({ commit }, payload) => {
			const { instId, itemsId } = payload
			await inventoryAPI.completeInventory(instId, itemsId).then(response => {
				commit('setStatus', response.status)
			})
		},
		updateStorageInventory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await inventoryAPI.updateStorageInventory(instId, body)
		},
		disposeInventory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await inventoryAPI.disposeInventory(instId, body)
		},
		restoreInventory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await inventoryAPI.restoreInventory(instId, body).then(response => {
				commit('setStatus', response.status)
			})
		},
		holdInventory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await inventoryAPI.holdInventory(instId, body)
		},
		returnInventory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await inventoryAPI.returnInventory(instId, body)
		},
		consumeInventory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await inventoryAPI.consumeInventory(instId, body)
		},
		lookupLogList: async ({ commit }, payload) => {
			const { instId, itemId, offset, length, keyword, startDatetime, endDatetime, order, orderDirection, categoriesId, membersId, types } =
				payload
			const response = await inventoryAPI.lookupLogList(
				instId,
				itemId,
				offset,
				length,
				keyword,
				startDatetime,
				endDatetime,
				order,
				orderDirection,
				categoriesId,
				membersId,
				types
			)
			commit('setLogList', response.data)
		},
		deleteLogInventory: async ({ commit }, payload) => {
			const { instId, ids } = payload
			await inventoryAPI.deleteLogInvengory(instId, ids)
		},
		lookupDumpList: async ({ commit }, payload) => {
			const {
				instId,
				offset,
				length,
				keyword,
				startDatetime,
				endDatetime,
				order,
				orderDirection,
				status,
				categoriesId,
				assigneesId,
				dumpersId,
			} = payload
			const response = await inventoryAPI.lookupDumpList(
				instId,
				offset,
				length,
				keyword,
				startDatetime,
				endDatetime,
				order,
				orderDirection,
				status,
				categoriesId,
				assigneesId,
				dumpersId
			)
			commit('setDumpList', response.data)
		},
		searchProduct: async ({ commit }, payload) => {
			const { instId, categoryType, keyword, offset, length } = payload
			const response = await inventoryAPI.searchProduct(instId, categoryType, keyword, offset, length)
			commit('setProduct', response.data)
		},
		searchIngredient: async ({ commit }, payload) => {
			const { instId, keyword, offset, length } = payload
			const response = await inventoryAPI.searchIngredient(instId, keyword, offset, length)
			commit('setIngredient', response.data)
		},
		searchBrand: async ({ commit }, payload) => {
			const { instId, keyword, isUse } = payload
			const response = await inventoryAPI.searchBrand(instId, keyword, isUse)
			commit('setBrand', response.data)
		},
		lookupStorageList: async ({ commit }, instId) => {
			const response = await storageAPI.lookupTotalStorageTree(instId)
			commit('setStorageList', response.data)
		},
		lookupTagList: async ({ commit }, instId) => {
			const response = await inventoryAPI.lookupTagList(instId)
			commit('setTagList', response.data)
		},
		lookupSearchTagList: async ({ commit }, payload) => {
			const { instId, keyword, offset, length, categoriesId } = payload
			const response = await inventoryAPI.lookupSearchTagList(instId, keyword, offset, length, categoriesId)
			commit('setSearchTagList', response.data)
		},
		createTag: async ({ commit }, payload) => {
			const { instId, body } = payload
			await inventoryAPI.createTag(instId, body)
		},
		deleteTag: async ({ commit }, payload) => {
			const { instId, id } = payload
			await inventoryAPI.deleteTag(instId, id)
		},
		lookupUnit: async ({ commit }, value) => {
			const response = await inventoryAPI.lookupUnit(value)
			commit('setUnit', response.data)
		},
	},
}

export default inventoryCategory
