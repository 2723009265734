import deadlineAPI from '@/services/api/purchase/deadline'
import _ from 'lodash'
import cookies from 'vue-cookies'
import { deadlineViewOption } from '@/utils/define/ViewOption'

const state = {
	list: [],
	count: 0,
	loadParams: {
		keyword: '', // 검색어
		offset: 0,
		length: 50,
		settleStatus: [],
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
		draftStatus: [], // 상태 필터
		budgetsId: JSON.parse(cookies.get('filter_draft_budget')) ?? [], // 예산 유형 필터
		draftGroupsId: JSON.parse(cookies.get('filter_draft_group')) ?? [], // 기안 연구실 그룹 필터
		startDraftCreateDatetime: '',
		endDraftCreateDatetime: '',
	},
	selectedItems: [],
	filter: null,
	filterCookieName: {
		budget: 'filter_deadline_budget',
		group: 'filter_deadline_group',
	},
	viewOptionCookieName: 'viewOption_deadline',
	checkedViewOption: JSON.parse(cookies.get('viewOption_deadline')) ?? [],
	viewOptionList: deadlineViewOption,
}

const getters = {
	keyword: state => state.loadParams.keyword,
	getSelectedIds: state => state.selectedItems.map(item => item.id),
	selectedStatus: state => (state.loadParams.settleStatus.length == 0 ? 'ALL' : state.loadParams.settleStatus[0]),
	filteredCount: state => state.loadParams.draftGroupsId.length + state.loadParams.budgetsId.length,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setOffset: (state, pageNumber) => {
		state.loadParams.offset = (pageNumber - 1) * length
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			state.loadParams[key] = loadParams[key]
		})
	},
	setSelectedItems: (state, data) => {
		state.selectedItems = data
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	setViewOptions: (state, list) => {
		cookies.set(state.viewOptionCookieName, JSON.stringify(list))
		state.checkedViewOption = list
	},
}

const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		const response = await deadlineAPI.list(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	getFilter: async ({ commit, rootGetters }) => {
		const response = await deadlineAPI.filterList(rootGetters.instituteId)
		commit('setFilter', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		dispatch('getList')
	},
	settle: async ({ commit, dispatch, rootGetters }, body) => {
		await deadlineAPI.settle(rootGetters.instituteId, body)
		dispatch('getList')
		commit('setSelectedItems', [])
	},
	unSettle: async ({ commit, dispatch, rootGetters }, body) => {
		await deadlineAPI.unSettle(rootGetters.instituteId, body)
		dispatch('getList')
		commit('setSelectedItems', [])
	},

	exportExcel: async ({ state, rootGetters }, type) => {
		const response = await deadlineAPI.listExportExcel(rootGetters.instituteId, type == 'ALL' ? null : state.loadParams)
		return response
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
