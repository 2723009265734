var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-wrap"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tr', {
    staticClass: "table-header",
    attrs: {
      "cellspacing": "0",
      "cellpadding": "0"
    }
  }, [_c('th', {
    staticClass: "header-text"
  }, [_vm._v(_vm._s(_vm.tt('물질 이름')))]), _c('th', {
    staticClass: "header-text"
  }, [_vm._v("CAS No")]), _c('th', {
    staticClass: "header-text"
  }, [_vm._v(_vm._s(_vm.tt('성분비')))])]), _vm._l(_vm.ingredient, function (item, i) {
    return _c('tr', {
      key: "".concat(item.name, "-").concat(i),
      staticClass: "table-body"
    }, [_c('td', {
      staticClass: "name"
    }, [_c('div', {
      staticClass: "data"
    }, [_vm._v(_vm._s(item.name))])]), _c('td', {
      staticClass: "casno"
    }, [_c('div', {
      staticClass: "data"
    }, [_vm._v(_vm._s(item.casno))])]), _c('td', {
      staticClass: "ratio"
    }, [_c('div', {
      staticClass: "data"
    }, [_vm._v(_vm._s(item.ratio))])])]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }