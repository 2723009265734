import axiosInstance from '@/services/axios'

const endPoint = {
	getList: '/popup/list',
}

const eolAPI = {
	getList: () => {
		return axiosInstance.get(endPoint.getList)
	},
}

export default eolAPI
