<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt(title) }}</div>
			<div class="form-msg" :class="{ error: !isValid }">
				{{ isValid ? tt('입력완료') : tt('필수값을 입력해주세요') }}
			</div>
		</div>
		<div class="form-data-wrap">
			<form-text-single-line
				ref="form-seller"
				v-for="field in sellerFields"
				:key="field.key"
				:name="formName(field.key)"
				:value="formValue(field.key)"
				:placeholder="formMessage(field)"
				:errorMessage="formMessage(field)"
				:isShowError="isShowError"
				:required="field.isRequire"
				@input="value => inputFormValue(value, field.key)"
			></form-text-single-line>
		</div>
	</div>
</template>
<script>
const SELLER_FIELD_NAME = {
	name: '판매자 이름',
	contactName: '담당자',
	contactPhone: '연락처',
	address: '주소',
	businessNumber: '사업자등록번호',
}
import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
export default {
	name: 'FormSeller',
	components: { FormTextSingleLine },
	props: {
		title: {
			type: String,
			default: '',
		},
		isValid: {
			type: Boolean,
			default: false,
		},
		sellerInfo: {
			type: Object,
			default: () => {
				return {}
			},
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		sellerFields() {
			return [
				{ key: 'name', isRequire: true },
				{ key: 'contactName', isRequire: false },
				{ key: 'contactPhone', isRequire: false },
				{ key: 'address', isRequire: false },
				{ key: 'businessNumber', isRequire: false },
			]
		},
		formName() {
			return fieldKey => {
				return SELLER_FIELD_NAME[fieldKey]
			}
		},
		formValue() {
			return fieldKey => {
				return this.sellerInfo[fieldKey]
			}
		},
		formMessage() {
			return field => {
				if (field.key === 'name') return `상호명을 입력해주세요`
				else return `${SELLER_FIELD_NAME[field.key]}를 입력해주세요`
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		inputFormValue(value, fieldKey) {
			this.$emit('change', value, fieldKey)
		},
		showRequire() {
			this.$refs['form-seller'].forEach(ref => {
				ref.showRequire()
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
</style>
