var render = function () {
  var _vm$sale;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "total-price-wrapper"
  }, [_c('div', [_c('b', [_vm._v(_vm._s(_vm.tt('지출 예정금액 합계:')))]), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('정상 판매가')))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(_vm.total))))]), _c('img', {
    "class": {
      red: _vm.sale !== 0
    },
    attrs: {
      "src": require("@/assets/svg/table-minus.svg")
    }
  }), _c('div', {
    staticClass: "label",
    "class": {
      red: _vm.sale !== 0
    }
  }, [_vm._v(_vm._s(_vm.tt('할인금액')))]), _c('div', {
    staticClass: "value",
    "class": {
      red: _vm.sale !== 0
    }
  }, [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale((_vm$sale = _vm.sale) !== null && _vm$sale !== void 0 ? _vm$sale : 0))))]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/table-plus.svg")
    }
  }), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('배송비')))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.deliveryFee ? _vm.tt('${1}원', _vm.formatNumberLocale(_vm.deliveryFee)) : _vm.tt('무료')))]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/table-plus.svg")
    }
  }), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('VAT(10%)')))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(_vm.vatPrice))))]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/table-equal.svg")
    }
  }), _c('div', {
    staticClass: "value lg"
  }, [_c('b', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(_vm.totalExpenditurePrice))))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }