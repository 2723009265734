var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "Modal ModalRegistCard Popup__depth2"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    ref: "container",
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title",
    domProps: {
      "textContent": _vm._s(_vm.mode == 'REGIST' ? _vm.tt('결제카드 등록') : _vm.tt('결제카드 변경'))
    }
  }), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _vm.mode === 'CHANGE' ? _c('div', {
    staticClass: "Popup__notice"
  }, [_c('div', {
    staticClass: "notice-text"
  }, [_vm._v(_vm._s(_vm.tt('기존의 결제카드 정보가 새로운 카드 정보로 대체됩니다.')))])]) : _vm._e(), _c('div', {
    staticClass: "Popup__content"
  }, [_c('h5', {
    attrs: {
      "tabindex": "0"
    }
  }, [_vm._v(_vm._s(_vm.tt('결제수단')))]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('카드번호'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    ref: "input-cardnum",
    "class": {
      'on-input': _vm.$root.cardInput,
      error: _vm.cardNoError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('14 ~ 16자리 번호를 입력해주세요'),
      "maxlength": _vm.cardNumberMaxLength,
      "readonly": ""
    },
    domProps: {
      "value": _vm.cardNumber
    },
    on: {
      "input": _vm.autoDashCreditCard,
      "focus": function focus($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.$root.cardInput = true;
      },
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }), _vm.cardNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('유효하지 않은 카드번호입니다.')) + " ")])]) : _vm._e(), _c('card-number-keyboard', {
    attrs: {
      "isShow": _vm.$root.cardInput,
      "numbers": _vm.cardInputButtonNumbers
    },
    on: {
      "add": _vm.addNumber,
      "reset": _vm.resetInputNumber,
      "remove": _vm.removeInputNumber,
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  })], 1), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('유효기간(MM/YY)')) + " "), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.expirationError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('MM/YY'),
      "maxlength": "5"
    },
    domProps: {
      "value": _vm.form.expirationMonthYear
    },
    on: {
      "input": _vm.inputExpirationMonthYear
    }
  }), _vm.expirationError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('잘못된 유효기간입니다.')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('비밀번호 앞 2자리')) + " "), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.passwordError
    },
    attrs: {
      "type": "password",
      "placeholder": _vm.tt('비밀번호 앞 2자리를 입력해주세요'),
      "maxlength": "2"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": _vm.inputPassword
    }
  }), _vm.passwordError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('잘못된 비밀번호입니다.')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('생년월일/사업자등록번호'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.registrationNoError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('생년월일(6자리) 혹은 사업자 등록번호(10자리)를 입력해주세요'),
      "maxlength": "12"
    },
    domProps: {
      "value": _vm.form.registrationNumber
    },
    on: {
      "input": _vm.inputRegistrationNumber
    }
  }), _vm.registrationNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('잘못된 생년월일 또는 사업자등록번호입니다.')) + " ")])]) : _vm._e()]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('개인카드 혹은 기명 법인카드인 경우 생년월일 6자리를 입력해주세요.<br>무기명 법인카드인 경우 사업자등록번호 10자리를 입력해주세요.'))
    }
  }), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('연락처'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.phoneNoError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('연락처를 입력해주세요'),
      "maxlength": "13"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": _vm.autoHypenTel
    }
  }), _vm.phoneNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연락처를 형식에 맞게 입력해주세요')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('이메일'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    "class": {
      error: _vm.emailNoError
    },
    attrs: {
      "type": "email",
      "placeholder": _vm.tt('이메일 입력해주세요')
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _vm.emailNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('이메일을 형식에 맞게 입력해주세요')) + " ")])]) : _vm._e()]), _vm.isChangeMode && !_vm.isPaymentCardError ? _c('button', {
    staticClass: "Btn__yellow",
    attrs: {
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.changeOrRegistCard
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('저장하기')) + " ")]) : _vm.isPaymentCardError ? _c('button', {
    staticClass: "Btn__yellow",
    attrs: {
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.changeOrRegistCard
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('등록하고 다음단계로')) + " ")]) : _vm.onlyRegist ? _c('button', {
    staticClass: "Btn__yellow",
    attrs: {
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.registCard
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('등록하기')) + " ")]) : _c('button', {
    staticClass: "Btn__yellow",
    attrs: {
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.registCardAndPurchase
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('등록 및 결제하기')) + " ")])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }