<template>
	<div class="form-input" :class="{ error: isShowRequire }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper">
			<input type="text" :placeholder="tt(placeholder)" v-model="inputValue" @input="handleInput" />
		</div>
		<span class="error" v-if="isShowRequire">{{ tt(errorMessage) }}</span>
	</div>
</template>

<script>
import StringUtils from '@/utils/modules/StringUtils'
export default {
	name: 'FormPhone',
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputValue: '',
			isShowRequire: false,
		}
	},
	watch: {
		value() {
			if (this.inputValue !== this.value) {
				let value = StringUtils.autoHypenTel(this.value)
				if (value.length <= 13) {
					this.inputValue = value
				} else {
					this.inputValue = value.substr(0, 14)
				}
			}
		},
	},
	mounted() {
		if (this.inputValue !== this.value) this.inputValue = this.value
	},
	methods: {
		handleInput(e) {
			let value = e.target.value.trimLeft()
			value = StringUtils.autoHypenTel(value)
			if (value.length <= 13) {
				this.inputValue = value
			} else {
				this.inputValue = value.substr(0, 13)
			}
			this.$emit('input', this.inputValue)
		},
		showRequire() {
			this.isShowRequire = this.required && (!this.inputValue || this.inputValue.length < 13)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-input.scss';
</style>
