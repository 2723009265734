<template>
	<div class="search-wrapper">
		<div class="table-wrapper">
			<div class="search-row">
				<div class="label">{{ tt('검색') }}</div>
				<div>
					<div class="column-box">
						<search-box ref="search-box" class="search-box" noRecent :placeholder="tt(placeholder)" @change="changeKeyword"></search-box>
					</div>
				</div>
			</div>
			<div class="search-row">
				<div class="label">{{ tt('기간 조회') }}</div>
				<div>
					<div class="column-box multidate">
						<div class="column-text">{{ tt('날짜') }}:</div>
						<form-multi-select-datetime @change="date => $emit('changeDatetime', date)"></form-multi-select-datetime>
					</div>
				</div>
			</div>
		</div>
		<div class="table-wrapper">
			<div class="search-row">
				<div class="label">{{ tt('내용') }}</div>
				<div class="column-box">
					<label v-for="item in typeList" :key="item.id">
						<input class="checkbox" type="checkbox" :checked="isCheckedItem(item.id, 'id')" @change="checkedItem(item, 'id')" />
						<span v-b-tooltip.hover="tt(item.name)">{{ tt(item.name) }}</span>
					</label>
				</div>
			</div>

			<div class="result-row" v-show="checkedList.length > 0">
				<div @click="reset">
					<b>{{ tt('전체해제') }}</b
					><img src="@/views/budget/components/svg/icon-reset.svg" />
					<div class="line"></div>
				</div>
				<div class="select-item-wrapper">
					<div class="selected-item" v-for="item in checkedList" :key="item.id">
						<div v-b-tooltip.hover="tt(item.name)">{{ tt(item.name) }}</div>
						<img src="@/views/budget/components/svg/icon-remove.svg" @click="removeItem(item)" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SearchBox from '@/views/components/searchBox'
import FormMultiSelectDatetime from '@/views/common/components/form/FormMultiSelectDatetime.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'ManageInfoSearch',
	props: {
		placeholder: {
			type: String,
			default: '검색',
		},
	},
	components: {
		SearchBox,
		FormMultiSelectDatetime,
	},
	computed: {
		...mapGetters('inventoryGen2/historyInfo', ['filter']),
		typeList() {
			let types = this.filter?.types ?? []
			let list = [
				{
					id: 'ALL',
					name: '전체',
				},
				...types,
			]
			return list
		},
	},
	data() {
		return {
			checkedList: [],
		}
	},
	watch: {},
	created() {
		this.getFilter()
	},
	methods: {
		...mapActions('inventoryGen2/historyInfo', ['getFilter']),
		changeKeyword(keyword) {
			this.$emit('changeKeyword', keyword)
		},
		checkedItem(item, type) {
			if (item.id == 'ALL') this.checkedAll(type)
			else {
				if (this.checkedList.find(it => it.id === item.id)) {
					this.checkedList.remove(item.id, 'id')
				} else {
					this.checkedList.push({ ...item })
				}
			}
			this.$emit('changeCheck', this.checkedList)
		},
		checkedAll(type) {
			let list = this.typeList.slice(1)
			if (list.length == this.checkedList.length) {
				this.checkedList.removeAll(list, 'id')
			} else {
				this.checkedList.removeAll(list, 'id')
				this.checkedList.push(...list.map(i => ({ ...i, type })))
			}
		},
		removeItem(item) {
			this.checkedList.remove(item.id, 'id')
			this.$emit('changeCheck', this.checkedList)
		},
		reset() {
			this.checkedList = []
			if (this.$refs['search-box']) this.$refs['search-box'].text = ''
			this.$emit('changeCheck', this.checkedList)
		},
		checkedListByType(type) {
			return this.checkedList.map(it => it.id)
		},
		isCheckedItem(id, type) {
			let list = this.typeList.slice(1)
			if (id == 'ALL') return list.length == this.checkedList.length
			return this.checkedListByType(type).includes(id)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-search.scss';
.search-row {
	text-align: left !important;
}
.column-text {
	width: 70px !important;
}
.column-box {
	label {
		display: flex !important;
		align-items: center;
		flex-basis: 230px !important;
		span {
			width: 100% !important;
		}
	}
	&.multidate {
		gap: 0px !important;
	}
}
::v-deep {
	.mx-input-wrapper {
		width: 227px !important;
	}
}
</style>
