var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "EditMemberAuthorityPopup"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Memeber__info-popup Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(" " + _vm._s(_vm.tt('권한 관리')) + " ")]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('선택된 멤버의 권한이 저장됩니다.')))]), _c('div', {
    staticClass: "Popup__select-field setting-select-field deleteable-input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('권한 지정')))]), _vm._l(_vm.selectAuthorityList, function (permission, index) {
    return _c('permission-box', {
      key: 'permission' + index,
      attrs: {
        "item": permission,
        "list": _vm.authorityList,
        "index": index,
        "selectedList": _vm.selectAuthorityList
      },
      on: {
        "select": _vm.selectAuthority,
        "delete": _vm.deleteAuthority
      }
    });
  }), _vm.selectAuthorityList.length < _vm.authorityList.length ? _c('button', {
    staticClass: "AppContainer__add-item-btn",
    on: {
      "click": _vm.addAuthority
    }
  }, [_vm._v(" +" + _vm._s(_vm.tt('추가하기')) + " ")]) : _vm._e()], 2)]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('저장')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }