var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    ref: "inventory-table",
    staticClass: "lm-table"
  }, [_c('table-header'), _vm._l(_vm.list, function (item) {
    return _c('table-item', {
      key: item.tempId,
      attrs: {
        "item": item
      },
      on: {
        "select": _vm.showSelectModal,
        "edit": _vm.showEditModal
      }
    });
  }), _vm.list.length === 0 ? _c('div', {
    staticClass: "empty-table",
    on: {
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.dropFile.apply(null, arguments);
      },
      "dragenter": function dragenter($event) {
        $event.preventDefault();
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
      }
    }
  }, [_c('img', {
    staticClass: "empty-img",
    attrs: {
      "src": _vm.emptyImage
    }
  }), _c('p', {
    staticClass: "bold",
    domProps: {
      "innerHTML": _vm._s(_vm.emptyText)
    }
  }), _c('p', {
    staticClass: "sub"
  }, [_c('image-button', {
    attrs: {
      "text": '양식파일 다운로드',
      "image": require('@/views/inventoryGen2/components/svg/icon-upload.svg')
    },
    on: {
      "click": _vm.fileDown
    }
  }), _c('image-button', {
    attrs: {
      "text": '파일첨부',
      "image": require('@/views/inventoryGen2/components/svg/icon-upload.svg')
    },
    on: {
      "click": _vm.uploadFile
    }
  })], 1)]) : _vm._e(), _c('input', {
    ref: "dropFile",
    staticClass: "dropFile",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onChange
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }