var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "store-info-wrapper"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-lab-manager-store.svg")
    }
  }), _c('div', [_c('h3', [_vm._v(_vm._s(_vm.tt('랩매니저 스토어에서 상품 선택')) + " "), _c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-info.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal'].show();
      }
    }
  })]), _vm.content ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.content))
    }
  }) : [_vm.isOrder ? _c('p', [_vm._v(_vm._s(_vm.tt('1. 주문하기를 선택하면, 랩매니저 스토어에 주문이 자동 접수됩니다.')))]) : _c('p', [_vm._v(_vm._s(_vm.tt('1. 지출결의서 승인시, 랩매니저 스토어에 주문이 자동접수됩니다. ')))]), _c('p', [_vm._v(_vm._s(_vm.tt('2. 판매자가 주문을 승인하면, 후불결제를 진행할 수 있습니다.')))])]], 2), _c('modal-store-order-tip', {
    ref: "modal",
    attrs: {
      "type": _vm.type
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }