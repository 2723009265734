<template>
	<b-modal ref="modal" size="xl" centered v-if="budget" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('예산현황표') }}: {{ budget.name }}</div>
		</template>
		<div class="status-table">
			<div class="left-fixed" style="width: 360px">
				<div>
					<div class="header">
						<div>
							<div class="item bd" style="width: 360px">
								{{ tt('예산') }}
							</div>
						</div>
						<div>
							<div class="item bd" style="width: 120px">
								{{ tt('코드') }}
							</div>
							<div class="item bd" style="min-width: 240px">
								{{ tt('이름') }}
							</div>
						</div>
					</div>
					<div class="item budget">
						<div>
							<div class="item bd" style="width: 120px" v-b-tooltip.hover="budget.code">
								<p>{{ budget.code }}</p>
							</div>
							<div class="item bd" style="min-width: 240px" v-b-tooltip.hover="budget.name">
								<p>{{ budget.name }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="table-content">
				<div :style="{ '--width': `${Object.keys(monthKey).length * 390}px` }">
					<div class="column" v-for="key in Object.keys(monthKey)" :key="key">
						<div class="header">
							<div>
								<div class="item bd">
									{{ tt(monthKey[key]) }}
								</div>
							</div>
							<div>
								<div class="item bd">
									{{ tt('예산') }}
								</div>
								<div class="item bd">
									{{ tt('지출') }}
								</div>
								<div class="item bd">
									{{ tt('잔액') }}
								</div>
							</div>
						</div>
						<div class="item">
							<div>
								<div class="item bd">{{ tt('${1}원', budget.budgetStatus[key].budget.toLocaleString()) }}</div>
								<div class="item bd">{{ tt('${1}원', budget.budgetStatus[key].expenditure.toLocaleString()) }}</div>
								<div class="item bd">{{ tt('${1}원', budget.budgetStatus[key].balance.toLocaleString()) }}</div>
								<!-- <div class="item bd">{{ sum(key, 'expenditure') }}</div>
								<div class="item bd">{{ shareValue(key, 'balance') }}</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template #modal-footer>
			<div class="footer-right">
				<button class="button-white" @click="hide()">
					{{ tt('닫기') }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import MixinDate from '@/mixins/date'

export default {
	props: {
		budget: {
			type: Object,
			default: null,
		},
	},
	computed: {
		monthKey() {
			let monthKey = { total: this.tt('계(${1}년)', this.budget.year) }
			if (this.budget.budgetType == 'MONTH')
				monthKey = {
					...monthKey,
					...this.monthObject,
				}
			return monthKey
		},
	},
	mixins: [MixinDate],
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-base.scss';
@import '@/styles/scss/table/_table-budget.scss';
.table-content {
	flex-basis: calc(100% - 360px);
}
::v-deep {
	.modal-body {
		padding: 0px;
	}
	.status-table {
		border: none;
	}
}
</style>
