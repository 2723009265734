var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: _vm.error
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleShowOption.apply(null, arguments);
      }
    }
  }, [_c('div', {
    ref: "select-box",
    staticClass: "form-select-box"
  }, [_c('div', {
    staticClass: "select-box"
  }, [_c('p', {
    staticClass: "select-item-text",
    "class": {
      placeholder: !_vm.inputValue
    }
  }, [_vm._v(_vm._s(_vm.tt("".concat(_vm.selectOptionName))))])]), _vm.isShowOption ? _c('div', {
    ref: "form-option-list",
    staticClass: "form-option-list"
  }, [_c('ul', {
    staticClass: "option-list"
  }, _vm._l(_vm.optionList, function (option, idx) {
    return _c('li', {
      key: "option-".concat(option, "-").concat(idx),
      staticClass: "option-item",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.clickOption(option);
        }
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0)]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }