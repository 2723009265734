import inventoryAPI from '@/services/api/inventory/gen2'
import _ from 'lodash'
import cookies from 'vue-cookies'

const state = {
	list: [],
	count: 0,
	loadParams: {
		offset: 0,
		length: 50,
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
		startDatetime: null,
		endDatetime: null,
		categoriesId: JSON.parse(cookies.get('filter_wait_categoriesId')) || [],
		status: ['ING', 'FAIL'],
		registersId: JSON.parse(cookies.get('filter_wait_registersId')) || [],
	},
	selectedItems: [],
	filter: null,
	filterCookieName: {
		registersId: 'filter_wait_registersId',
		categoriesId: 'filter_wait_categoriesId',
	},
}

const getters = {
	selectedStatus: state => (state.loadParams.status.length === 2 ? 'ALL' : state.loadParams.status[0]),
	filteredCount: state => state.loadParams.registersId.length + state.loadParams.categoriesId.length,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
	isEmptySelectedItems: state => state.selectedItems.length === 0,
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			if (loadParams[key] == null) {
				delete state.loadParams[key]
			} else state.loadParams[key] = loadParams[key]
		})
	},
	setSelectedItems: (state, data) => {
		state.selectedItems = data
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	initFilterCookies: (state, needReset) => {
		if (needReset)
			Object.values(state.filterCookieName).forEach(key => {
				cookies.set(key, JSON.stringify([]))
			})
		state.loadParams = {
			keyword: '',
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			startDatetime: null,
			endDatetime: null,
			categoriesId: JSON.parse(cookies.get('filter_wait_categoriesId')) || [],
			status: ['ING', 'FAIL'],
			registersId: JSON.parse(cookies.get('filter_wait_registersId')) || [],
		}
	},
}

const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		const response = await inventoryAPI.inspect(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	getFilter: async ({ commit, rootGetters }) => {
		const response = await inventoryAPI.inspectFilter(rootGetters.instituteId)
		commit('setFilter', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		dispatch('getList')
	},
	removeItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.removeInspect(rootGetters.instituteId, body)
		dispatch('getList')
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
