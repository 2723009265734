var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('migration-header', {
    attrs: {
      "isMigration": true
    }
  }), _c('div', {
    staticClass: "content-slide-wrapper"
  }, [_c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h3', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  }), _c('div', {
    staticClass: "subtext",
    domProps: {
      "innerHTML": _vm._s(_vm.subtext)
    }
  })])]), _c('div', {
    staticClass: "institute-item-wrapper"
  }, [_vm._l(_vm.migrationInstitutes, function (item, index) {
    return _c('migration-select-item-new', {
      key: "migration-item-new-".concat(index),
      attrs: {
        "item": item
      },
      on: {
        "click": _vm.showMigrationModal
      }
    });
  }), _c('migration-select-item-legacy', {
    on: {
      "click": _vm.showMigrationModal
    }
  })], 2)]), _c('modal-common-confirm', {
    attrs: {
      "isShow": _vm.displayConfirmModal,
      "hideConfirm": _vm.hideConfirmButton,
      "title": _vm.confirmModalTitle,
      "content": _vm.confirmModalContent,
      "buttonText": _vm.confirmModalButtonText,
      "cancelText": _vm.confirmModalCancelButton
    },
    on: {
      "confirm": _vm.confirmModalClick,
      "close": function close($event) {
        _vm.displayConfirmModal = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }