var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _vm.requiredCustomFields.length > 0 ? _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isCustomValid
    }
  }, [_vm._v(" " + _vm._s(_vm.isCustomValid ? _vm.tt('입력 완료') : _vm.tt('필수값을 입력해주세요')) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-data-wrap"
  }, [_vm._l(_vm.requiredCustomFields, function (field) {
    return _c(_vm.formInput(field), {
      key: "".concat(_vm.categoryInfo.id, "-").concat(field.key),
      ref: "form",
      refInFor: true,
      tag: "component",
      attrs: {
        "value": _vm.formValue(field),
        "name": field.name,
        "required": field.isRequire,
        "placeholder": _vm.formMessage(field),
        "optionList": _vm.formOptionList(field),
        "errorMessage": _vm.formMessage(field),
        "isShowError": _vm.isShowError
      },
      on: {
        "change": function change(value) {
          return _vm.changeFormCustom(value, field);
        },
        "input": function input(value) {
          return _vm.inputFormValue(value, field);
        }
      }
    });
  }), _vm._l(_vm.customFields, function (field) {
    return _c(_vm.formInput(field), {
      key: "".concat(_vm.categoryInfo.id, "-").concat(field.key),
      ref: "form",
      refInFor: true,
      tag: "component",
      attrs: {
        "value": _vm.formValue(field),
        "name": field.name,
        "required": field.isRequire,
        "placeholder": _vm.formMessage(field),
        "optionList": _vm.formOptionList(field),
        "errorMessage": _vm.formMessage(field),
        "isShowError": _vm.isShowError
      },
      on: {
        "change": function change(value) {
          return _vm.changeFormCustom(value, field);
        },
        "input": function input(value) {
          return _vm.inputFormValue(value, field);
        }
      }
    });
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }