<template>
	<div class="Authority">
		<h2 class="AppContainer__title">
			{{ tt('권한') }}
		</h2>
		<div class="Authority__filter">
			<search-box :placeholder="tt('검색')" :loadList="loadPermissionList"></search-box>
			<button
				class="AppContainer__subtitle-btn btn ml-auto"
				@click="setLite ? showLicense() : showAddModal()"
				v-if="userPermission.permission && userPermission.permission.create"
			>
				<img src="@/assets/svg//button-icon-lisence-copy.svg" alt="icon" />
				{{ tt('권한 추가') }}
			</button>
		</div>
		<div class="Authority__table Table__wrap">
			<table>
				<thead>
					<tr>
						<th>
							<span>{{ tt('정렬순서') }}</span>
						</th>
						<th>
							<span>{{ tt('권한') }}</span>
						</th>
						<th>
							<span>{{ tt('편집일') }}</span>
						</th>
						<th>
							<span>{{ tt('관리') }}</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<permission-item
						v-for="(item, index) in authority.list"
						v-bind:key="item.id"
						:item="item"
						:order="index"
						:total="authority.list.length"
						:allCheck="isActive"
						@duplicate="showDuplicateModal"
						@edit="showEditModal"
						@delete="showDeleteModal"
						@top="moveTop"
						@down="moveDown"
						:userPermission="userPermission"
						:license="license"
						@showLicense="showLicense"
					></permission-item>
				</tbody>
			</table>
			<no-data-list v-if="permissionList.count == 0" :image="noData.image" :title="noData.title" :content="noData.content"></no-data-list>
		</div>

		<pagination :total="permissionList.count" @page="pagination"></pagination>

		<modal-add-permission ref="modal-add-permission" @isHide="hideAddModal"></modal-add-permission>
		<modal-duplicate-permission ref="modal-duplicate-permission" @isHide="hideDuplicateModal"></modal-duplicate-permission>
		<modal-edit-permission ref="modal-edit-permission" @isHide="hideEditModal"></modal-edit-permission>
		<modal-delete-permission ref="modal-delete-permission" @isHide="hideDeleteModal"></modal-delete-permission>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import PermissionItem from '@/views/institute/permission/components/PermissionItem.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import ModalAddPermission from '@/views/institute/permission/modals/ModalAddPermission.vue'
import ModalDuplicatePermission from '@/views/institute/permission/modals/ModalDuplicatePermission.vue'
import ModalEditPermission from '@/views/institute/permission/modals/ModalEditPermission.vue'
import ModalDeletePermission from '@/views/institute/permission/modals/ModalDeletePermission.vue'
import SearchBox from '@/views/common/components/SearchBox.vue'
import router from '@/router'
import NoDataList from '@/views/common/components/NoDataList.vue'

export default {
	name: 'PermissionList',
	props: {
		license: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		PermissionItem,
		Pagination,
		ModalAddPermission,
		ModalDuplicatePermission,
		ModalEditPermission,
		ModalDeletePermission,
		SearchBox,
		NoDataList,
	},
	data() {
		return {
			instId: '',
			keyword: '',
			offset: 0,
			length: 50,
			isActive: false,
			isCheck: false,
			dragging: false,
			dragIndex: 0,
			dragFutureIndex: 0,
			authority: Object,
			selectItem: undefined,
			noData: {
				image: 'search',
				title: '조회된 결과가 없습니다',
				content: '다시 한 번 확인해 주세요',
			},
		}
	},
	created() {
		this.instId = this.$route.params.instituteId
		this.loadPermissionList()
	},
	watch: {
		dragging: function () {
			if (!this.dragging) {
				this.orderEdit()
			}
		},
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('permission', ['permissionList', 'permissionId', 'permission']),
		...mapState('institutionInformation', ['listKeyword']),
		setLite() {
			if (this.license.type == 'BASIC' || this.license.type == 'FREE_TIER') {
				return true
			} else {
				return false
			}
		},
	},
	methods: {
		...mapMutations('institutionInformation', ['setKeyword']),
		...mapActions('permission', [
			'lookupPermissionList',
			'lookupPermission',
			'updateOrder',
			'addPermission',
			'copyPermission',
			'updatePermission',
			'deletePermission',
		]),
		loadPermissionList() {
			const payload = {
				instId: this.instId,
				keyword: this.listKeyword,
				offset: this.offset,
				length: this.length,
			}
			this.lookupPermissionList(payload).then(() => {
				this.authority = this.$root.deepCopy(this.permissionList)
			})
		},
		checkMove(evt) {
			this.dragIndex = evt.draggedContext.index
			this.dragFutureIndex = evt.draggedContext.futureIndex
		},
		moveTop(data) {
			let current = this.authority.list[data.order - 1]
			let change = this.authority.list[data.order - 2]
			current.order = data.order - 1
			change.order = data.order + 1
			this.moveRow()
		},
		moveDown(data) {
			let current = this.authority.list[data.order - 1]
			let change = this.authority.list[data.order]
			current.order = data.order + 1
			change.order = data.order - 1
			this.moveRow()
		},
		moveRow() {
			let list = []
			this.authority.list.forEach(element => {
				let item = {
					id: element.id,
					order: element.order,
				}
				list.push(item)
			})

			const payload = {
				instId: this.instId,
				body: {
					permissions: list,
				},
			}
			this.updateOrder(payload)
				.then(() => {
					this.$root.toast('권한 순서 변경', '권한 순서가 변경되었습니다', 'success')
				})
				.catch(error => {
					const title = this.tt('권한 순서 변경 실패')
					if (error.response.data.code == '03006') {
						this.$root.toast(title, error.response.data.msg, 'error')
					} else {
						this.$root.toast(title, '권한 순서 변경에 실패했습니다', 'error')
					}
				})
			this.loadPermissionList()
		},
		orderEdit() {
			let copyList = this.authority.list
			let list = []
			for (let i = 0; i < copyList.length; i++) {
				let item = {
					id: copyList[i].id,
					order: i + 1,
				}
				list.push(item)
			}
			const payload = {
				instId: this.instId,
				body: {
					permissions: list,
				},
			}
			this.updateOrder(payload).then(() => {
				this.loadPermissionList()
			})
		},
		showAddModal() {
			this.$refs['modal-add-permission'].show()
		},
		hideAddModal(name) {
			this.$refs['modal-add-permission'].hide()
			if (name != undefined && name != '') {
				const payload = {
					instId: this.instId,
					body: {
						name: name,
					},
				}
				this.addPermission(payload).then(() => {
					this.$root.toast('권한생성 완료', '권한이 생성되었습니다', 'success')
					router.push({
						name: 'AuthorityDetail',
						params: { id: this.permissionId },
					})
				})
			}
		},
		showDuplicateModal(item) {
			this.$refs['modal-duplicate-permission'].show()
			this.selectItem = item
		},
		async hideDuplicateModal(check) {
			this.$refs['modal-duplicate-permission'].hide()
			if (check) {
				let payload = {
					instId: this.instId,
					body: {
						id: this.selectItem.id,
					},
				}
				await this.copyPermission(payload)
				this.$root.toast('복제 완료', '권한이 복제되었습니다', 'success')
				this.loadPermissionList()
			}
		},
		showEditModal(item) {
			this.$refs['modal-edit-permission'].show(item.id)
			this.selectItem = item
		},
		hideEditModal(permission) {
			this.$refs['modal-edit-permission'].hide()
			let authority = {}
			let payload = {}
			if (this.selectItem.isAlmighty) {
				authority = {
					id: permission.id,
					name: permission.name,
				}
				payload = {
					instId: this.instId,
					body: authority,
				}
			} else {
				payload = {
					instId: this.instId,
					body: permission,
				}
			}
			this.updatePermission(payload).then(() => {
				this.$root.toast('권한명 편집 완료', '권한명이 편집되었습니다', 'success')
				this.loadPermissionList()
			})
		},
		showDeleteModal(item) {
			this.$refs['modal-delete-permission'].show()
			this.selectItem = item
		},
		hideDeleteModal(check) {
			this.$refs['modal-delete-permission'].hide()
			if (check) {
				const payload = {
					instId: this.instId,
					id: this.selectItem.id,
				}
				this.deletePermission(payload).then(() => {
					this.$root.toast('권한 삭제 완료', '권한을 삭제하였습니다', 'success')
					this.authority.list.splice(this.selectItem.order - 1, 1)
					this.orderEdit()
				})
			}
		},
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
			this.loadPermissionList()
		},
		showLicense() {
			this.$emit('showLicense')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_authority.scss';
.Table__wrap {
	table {
		thead {
			th:nth-child(1) {
				width: 110 !important;
				max-width: 110px;
				padding-left: 20px;
				span {
					border: none !important;
				}
			}
		}
	}
}
</style>
