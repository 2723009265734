var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "select-table-body"
  }, [_c('td', [_c('div', [_c('span', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, null) > -1 : _vm.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checked = $$c;
        }
      }
    }
  })]), _c('option-info', {
    attrs: {
      "product": _vm.item
    }
  })], 1)]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.item.quantity))])]), _c('td', [_c('div', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(_vm.item.sellingPrice))))])]), _vm.item.discountPrice ? _c('div', {
    staticStyle: {
      "text-decoration": "line-through",
      "color": "#999"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(_vm.item.discountPrice))) + " ")]) : _vm._e()])]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.tt(_vm.item.user.name)))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }