var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-item', {
    attrs: {
      "title": "참여 부서",
      "required": _vm.isRequired
    }
  }, [_c('div', {
    staticClass: "button-wrapper"
  }, [_c('image-button', {
    attrs: {
      "text": '참여 부서 추가',
      "image": require('@/assets/svg/icon/black-round-plus.svg')
    },
    on: {
      "click": _vm.addGroup
    }
  })], 1), _c('div', {
    staticClass: "group-wrapper"
  }, _vm._l(_vm.selectedGroups, function (group) {
    return _c('div', {
      key: group.id,
      staticClass: "group-item"
    }, [_c('input', {
      attrs: {
        "type": "text",
        "disabled": ""
      },
      domProps: {
        "value": group.name
      }
    }), _c('image-button', {
      attrs: {
        "image": require('@/assets/svg/recycle-bin.svg')
      },
      on: {
        "click": function click($event) {
          return _vm.deleteGroup(group);
        }
      }
    })], 1);
  }), 0), !_vm.isValid ? _c('div', {
    staticClass: "error"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/purchase/icon-error.svg"),
      "alt": "error"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt(_vm.inValidText)))])]) : _vm._e(), _c('modal-select-group', {
    ref: "modal-select-group",
    attrs: {
      "title": "참여부서"
    },
    on: {
      "confirm": _vm.setSelectedGroup
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }