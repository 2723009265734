var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "form-wrap"
  }, [_c('form-text-single-line', {
    attrs: {
      "name": '카테고리 이름',
      "value": _vm.categoryInfoName,
      "placeholder": '카테고리 이름을 입력해 주세요',
      "errorMessage": '이름 입력해주세요!!!',
      "required": true
    },
    on: {
      "input": function input(value) {
        return _vm.inputFormValue(value);
      }
    }
  })], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }