<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt(title) }}</div>
			<div class="form-msg" :class="{ error: !isValid || !isUnitValid }">
				{{ isValid && isUnitValid ? tt('입력 완료') : !isUnitValid ? tt('단위를 선택해주세요') : tt('필수값을 입력해주세요') }}
			</div>
		</div>
		<button class="search-button" v-if="isSearch" @click="$refs['modal-search-product'].show()">
			<img src="@/assets/svg/icon/search/icon-search-black.svg" width="24" height="24" />
			<div>
				{{ tt('검색하기') }}
			</div>
		</button>
		<div class="form-data-wrap">
			<component
				ref="form-product"
				v-bind:is="formInput(field)"
				v-for="field in inventoryFields"
				:name="field.name"
				:value="formValue(field.key)"
				:unit="formUnit(field.key)"
				:required="field.isRequire"
				:optionList="field.unit"
				:key="field.key"
				:field="field"
				:placeholder="formMessage(field)"
				:errorMessage="formMessage(field)"
				:isCasno="field.key == 'casno'"
				:isShowError="isShowError"
				:item="productInfo"
				@input="value => inputFormValue(value, field.key)"
			></component>
			<template v-if="forDraft">
				<form-counter title="수량" :min="1" :value="1" @input="value => inputFormValue(value, 'quantity')"> </form-counter>
				<form-number-unit
					ref="form-product"
					:name="'단가'"
					:value="formValue('unitPrice')"
					:unit="formUnit('priceUnit')"
					:required="true"
					:optionList="['원']"
					:key="`unitPrice`"
					:placeholder="'가격을 입력해주세요'"
					:errorMessage="'가격을 입력해주세요'"
					:isShowError="isShowError"
					@input="value => inputFormValue(value, 'unitPrice')"
				></form-number-unit>
			</template>
		</div>
		<modal-search-product
			ref="modal-search-product"
			title="물품 검색하기"
			:categoryInfo="categoryInfo"
			@selectItem="item => $emit('selectItem', item)"
			:forRegistItem="forRegistItem"
			@inputDirect="$refs['modal-search-product'].hide()"
		></modal-search-product>
	</div>
</template>
<script>
import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
import FormBrand from '@/views/common/components/form/FormBrand.vue'
import FormNumberUnit from '@/views/common/components/form/FormNumberWithUnit.vue'
import FormTextWithUnit from '@/views/common/components/form/FormTextWithUnit.vue'
import FormPhRange from '@/views/common/components/form/FormPhRange.vue'
import ModalSearchProduct from '@/views/common/components/modalGen2/ModalSearchProduct.vue'
import FormCounter from '@/views/common/components/form/FormCounter.vue'
import { LargeClass } from '@/utils/define/ItemCode'
import FormNumber from '@/views/common/components/form/FormNumber.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'FormProduct',
	components: { FormTextSingleLine, FormBrand, FormNumberUnit, FormTextWithUnit, ModalSearchProduct, FormCounter, FormNumber },
	props: {
		title: {
			type: String,
			default: '',
		},
		isValid: {
			type: Boolean,
			default: false,
		},
		isUnitValid: {
			type: Boolean,
			default: false,
		},
		isSearch: {
			type: Boolean,
			default: true,
		},
		categoryInfo: {
			type: Object,
			default: () => {
				return {}
			},
		},
		productInfo: {
			type: Object,
			default: () => {
				return {}
			},
		},
		forDraft: {
			type: Boolean,
			default: false,
		},
		hideFieldKey: {
			type: Array,
			default: () => [],
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
		forRegistItem: {
			type: Boolean,
			defaul: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		inventoryFields() {
			return this.categoryInfo?.fields?.inventoryFields.filter(
				f => (f.isActive && !f.isReadOnly && !this.hideFieldKey.includes(f.key)) || this.forDraft
			)
		},
		formInput() {
			return field => {
				const { varType, key } = field
				if (key === 'brand') {
					return FormBrand
				}
				if (key === 'phRange') {
					return FormPhRange
				}
				return {
					text_single_line: FormTextSingleLine,
					number_with_unit: FormNumberUnit,
					text_single_line_with_unit: FormTextWithUnit,
					number: FormNumber,
				}[varType]
			}
		},
		formValue() {
			return fieldKey => {
				if (fieldKey === 'brand') {
					return {
						id: this.productInfo?.brandId ?? this.productInfo?.brand?.id,
						name: this.productInfo?.brandName ?? this.productInfo?.brand?.name,
					}
				} else if (fieldKey === 'phRange') {
					return {
						from: this.productInfo.phRangeFrom,
						to: this.productInfo.phRangeTo,
					}
				} else {
					return this.productInfo?.[fieldKey]
				}
			}
		},
		formUnit() {
			return fieldKey => {
				if (this.productInfo?.category?.classification === LargeClass.ETC && fieldKey === 'currentVolume') {
					return this.productInfo?.[`volumeUnit`]
				}
				return this.productInfo?.[`${fieldKey}Unit`]
			}
		},
		formMessage() {
			return field => {
				switch (field.name) {
					case '이름':
					case '서브네임':
					case '물품 사이즈(용량)':
					case '분자량':
					case 'MOL':
					case '분자식':
					case '발화점':
					case '끓는점':
					case '녹는점':
					case '내경(ID)':
					case 'Pore Size':
					case '잔여 용량':
					case '기계 유형':
					case '모델명':
					case '기구 유형':
					case '규격':
					case '보호구 유형':
					case '잔여 수량':
					case '패키지 수량':
					case '보유 패키지 수량':
						return `${field.name}을 입력해주세요`
					case '브랜드':
					case '제품번호':
					case 'CAS No':
					case '농도(밀도)':
					case '순도':
					case '보관온도':
					case '용해도':
					case 'USP/Phase':
					case 'Particle Size':
					case '길이':
					case 'Carbon Load(%)':
					case 'Endcapped':
						return `${field.name}를 입력해주세요`
					default:
						return '입력해주세요'
				}
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		inputFormValue(value, fieldKey) {
			this.$emit('change', value, fieldKey)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
</style>
