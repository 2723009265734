var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('button', {
    ref: "btn-wrapper",
    staticClass: "Btn__select",
    attrs: {
      "disabled": _vm.disabled
    }
  }, [_c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('권한을 지정해주세요'),
      "disabled": _vm.disabled,
      "readonly": ""
    },
    domProps: {
      "value": _vm.inputText
    },
    on: {
      "mousedown": _vm._inputMouseDown,
      "click": function click($event) {
        _vm.$refs['btn-wrapper'].focus(), _vm.checkArr();
      }
    }
  }), _c('ul', {
    ref: "option-box",
    staticClass: "Form__select-option-list scroll"
  }, _vm._l(_vm.list, function (option) {
    return _c('li', {
      key: option.id,
      staticClass: "Form__select-option-item",
      "class": {
        disabled: _vm.selectedArr[option.name] == true
      },
      attrs: {
        "value": option.name
      },
      on: {
        "click": function click($event) {
          _vm.selectedArr[option.name] == true ? '' : _vm.selectOption(option);
        }
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 0)]), _c('button', {
    staticClass: "Btn-setting-item btn",
    attrs: {
      "disabled": _vm.inputText.length == 0
    },
    on: {
      "click": function click($event) {
        return _vm.deleteOption(_vm.item);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Trash.svg"),
      "alt": "icon"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }