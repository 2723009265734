import approvalAPI from '@/services/api/purchase/approval'
import _ from 'lodash'

const state = {
	item: null,
}

const getters = {}

const mutations = {
	setItem: (state, data) => {
		state.item = data
	},
}

const actions = {
	getDetail: async ({ commit, rootGetters }, id) => {
		const response = await approvalAPI.detail(rootGetters.instituteId, id)
		commit('setItem', response.data)
		return response
	},
	draftConclude: async ({ dispatch, rootGetters }, body) => {
		const { id } = body
		const response = await approvalAPI.draftConclude(rootGetters.instituteId, body)
		dispatch('getDetail', id)
		return response
	},
	draftConcludeCancel: async ({ dispatch, rootGetters }, body) => {
		const { id } = body
		const response = await approvalAPI.draftConcludeCancel(rootGetters.instituteId, body)
		dispatch('getDetail', id)
		return response
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
