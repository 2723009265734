const EOLType = {
	NEW: 'NEW',
	DONE: 'DONE',
	AVAILABLE: 'AVAILABLE',
	NOPERM: 'NOPERM',
	ING: 'ING',
	SELECT: 'SELECT',
	REJECT: 'REJECT',
}

export { EOLType }
