<template>
	<div class="LmInputSearch__container">
		<input class="LmInputSearch__input" :value="keyword" @input="changeValue" :placeholder="tt(placeholder)" @keypress.enter="search" />
		<div class="LmInputSearch__button-wrapper">
			<group-search @keyup:enter="search"></group-search>
		</div>
	</div>
</template>

<script>
import GroupSearch from '@/components/svg/GroupSearch.vue'

export default {
	props: {
		variant: {
			type: String,
		},
		placeholder: {
			type: String,
			default: '검색',
		},
	},
	components: {
		GroupSearch,
	},
	data() {
		return {
			keyword: '',
		}
	},
	methods: {
		changeValue(event) {
			this.keyword = event.target.value
			this.$emit('inputValue', this.keyword)
		},
		search() {
			this.$emit('search', this.keyword)
		},
	},
}
</script>

<style lang="scss" scoped>
.LmInputSearch__container {
	display: flex;
	width: 200px;
	border-radius: 5px;
	border: 1px solid #e4e5ea;
	transition: 0.5s all ease;
	height: 30px;
	box-sizing: border-box;
	margin-right: 30px;
	&.block {
		width: 100%;
	}
	input {
		border: 0;
		color: $DEFAULT;
		font-size: 0.875rem;
		width: calc(100% - 30px);
		padding: 0 9px;
	}
	> button {
		padding: 5px;
		margin: 0;
		background: $GREY_4;
		justify-content: center;
		height: auto;
		transition: 0.5s all ease;
		height: 28px;
		width: 30px;
		border-radius: 0 5px 5px 0;
		img {
			width: 20px;
			padding-left: 3px;
		}
		&:hover {
			background: #dadbdc;
		}
	}
	&:hover {
		border: 1px solid #000;
		filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
	}
}

.LmInputSearch__input {
	width: 170px;
	border: solid 1px #e4e5ea;
	border-radius: 4px 0 0 4px;
	height: 28px;
	margin: 0px;
	padding: 0 9px;
	font-size: 12px;
}

.LmInputSearch__button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 28px;
	border-radius: 0px 4px 4px 0px;
	// border: solid 1px #e4e5ea;
	border-left-width: 0px;
	border-right-width: 1px;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-color: #e4e5ea;
	background-color: $GREY_4;
}
</style>
