var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "ModalHealthCheckupNotice"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-md"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt('특수건강진단')))]), _c('button', {
    staticClass: "Popup__close-btn"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    },
    on: {
      "click": _vm.close
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "boxwrap"
  }, [_c('div', {
    staticClass: "txtbox"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('특수건강진단이란?')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('산업안전보건법 제 130조에 따라 특수건강진단 대상 유해인자에 노출되는 업무에 종사하는 근로자')) + " ")])]), _c('div', {
    staticClass: "txtbox"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('특수건강진단 대상인원')))]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.tt('1. 화학적 인자(유기화합물, 금속류, 산 및 알카리류 등) 163종')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('2. 분진(곡물분진, 광물성분진, 면분진, 목재분진, 융접흄, 유리섬유 분진, 석면 분진) 7종')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('3. 물리적 인자(소음, 진동, 방사선, 고기압, 저기압, 유해광선 등) 8종')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('4. 야간작업 2종')) + " "), _c('div', [_vm._v(" " + _vm._s(_vm.tt('가. 6개월간 밤 12시부터 오전 5시까지의 시간을 포함하여 계속되는 8시간 작업을 월 평균 4회 이상 수행하는 경우'))), _c('br'), _vm._v(" " + _vm._s(_vm.tt('6개월간 오후 10시부터 다음날 오전 6시 사이의 시간 중 작업을 월 평균 60시간 이상 수행하는 경우')) + " ")])]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('※ 산업안전보건법 시행규칙 [별표 22] 참조 ')) + " ")])])]), _c('div', {
    staticClass: "txtbox"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('특수건강진단 기한 부여 기준')))]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.tt('특수건강진단 시기 및 주기 표를 기반으로 사용자가 사용한 대상 물질에 따라, 특수건강진단을 받아야하는 가장 빠른 일자를 건강진단 기한으로 합니다.')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('처음 사용하는 물질의 경우, 첫번째 진단시기를 적용합니다.')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('같은 물질을 여러번 사용하는 경우, 해당 물질의 진단주기를 바탕으로 진단시기를 적용합니다.')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('다음 특수건강진단을 1달이내로 받아야 하는 경우, 상태가 위험으로 표시됩니다.')) + " ")])])]), _c('div', {
    staticClass: "txtbox"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.tt('특수건강검진 시기 및 주기 (산업안전보건법 제202조 1항 관련)')) + " ")]), _c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.tt('구분')))]), _c('th', [_vm._v(_vm._s(_vm.tt('대상 유해인자')))]), _c('th', [_vm._v("CAS")]), _c('th', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('배치 후 첫번째<br>검진 시기'))
    }
  }), _c('th', [_vm._v(_vm._s(_vm.tt('검진 주기')))])])]), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("1")]), _c('td', [_vm._v("N,N-Dimethylacetamide(N,N-디메틸아세트아미드)")]), _c('td', [_vm._v("127-19-5")]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.tt('${1}개월 이내', 1)))]), _c('td', {
    staticClass: "last",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("6" + _vm._s(_vm.tt('개월')))])]), _c('tr', [_c('td', [_vm._v("N N-Dimethylformamide(N,N-디메틸포름아미드)")]), _c('td', [_vm._v("68-12-2")])]), _c('tr', [_c('td', [_vm._v("2")]), _c('td', [_vm._v("Benzene(벤젠)")]), _c('td', [_vm._v("71-43-2")]), _c('td', [_vm._v(_vm._s(_vm.tt('${1}개월 이내', 2)))]), _c('td', {
    staticClass: "last"
  }, [_vm._v("6" + _vm._s(_vm.tt('개월')))])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v("3")]), _c('td', [_vm._v("1,1,2,2-Tetrachloroethane(1,1,2,2-테트라클로로에탄)")]), _c('td', [_vm._v("79-34-5")]), _c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(_vm._s(_vm.tt('${1}개월 이내', 3)))]), _c('td', {
    staticClass: "last",
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v("6" + _vm._s(_vm.tt('개월')))])]), _c('tr', [_c('td', [_vm._v("Carbon tetrachloride(사염화탄소)")]), _c('td', [_vm._v("56-23-5")])]), _c('tr', [_c('td', [_vm._v("Acrylonitrile(아크릴로니트릴)")]), _c('td', [_vm._v("107-13-1")])]), _c('tr', [_c('td', [_vm._v("Vinyl chloride(염화비닐)")]), _c('td', [_vm._v("75-01-4")])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("4")]), _c('td', [_vm._v("Asbestos(석면)")]), _c('td', [_vm._v("1332-21-4")]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.tt('${1}개월 이내', 12)))]), _c('td', {
    staticClass: "last",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("12" + _vm._s(_vm.tt('개월')))])]), _c('tr', [_c('td', [_vm._v("Cotton Dust(면 분진)")]), _c('td', {
    staticClass: "no-data"
  }, [_vm._v(" " + _vm._s(_vm.tt('정보없음')) + " ")])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("5")]), _c('td', [_vm._v("Mineral Dust(석면)")]), _c('td', {
    staticClass: "no-data"
  }, [_vm._v(" " + _vm._s(_vm.tt('정보없음')) + " ")]), _c('td', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v(_vm._s(_vm.tt('${1}개월 이내', 12)))]), _c('td', {
    staticClass: "last",
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("24" + _vm._s(_vm.tt('개월')))])]), _c('tr', [_c('td', [_vm._v("Wood Dust(목재 분진)")]), _c('td', {
    staticClass: "no-data"
  }, [_vm._v(" " + _vm._s(_vm.tt('정보없음')) + " ")])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt('소음 및 충격소음')))]), _c('td', {
    staticClass: "no-data"
  }, [_vm._v(" " + _vm._s(_vm.tt('정보없음')) + " ")])]), _c('tr', [_c('td', [_vm._v("6")]), _c('td', [_vm._v(" " + _vm._s(_vm.tt('1~5를 제외한 201조에 해당하는 물질')) + " ")]), _c('td', [_c('a', {
    attrs: {
      "href": "https://www.law.go.kr/LSW/lsBylInfoPLinkR.do?lsiSeq=212709&lsNm=%EC%82%B0%EC%97%85%[…]9&bylNo=0022&bylBrNo=00&bylCls=BE&bylEfYd=20200116&bylEfYdYn=Y",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.tt('별표22 참조')))])]), _c('td', [_vm._v(_vm._s(_vm.tt('${1}개월 이내', 6)))]), _c('td', {
    staticClass: "last"
  }, [_vm._v("12" + _vm._s(_vm.tt('개월')))])])])])])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }