<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalNoticeInitPw Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('비밀번호 초기화') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text" v-html="tt('선택된 계정의 비밀번호를 재설정합니다.<br>아래의 초기 비밀번호로 초기화됩니다.')"></p>
					<div class="box">
						<p>{{ password }}</p>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button @click="close" class="Btn__close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="initPw">
							{{ tt('초기화') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { mapState, mapActions } from 'vuex'

export default {
	mixins: [MixinModal],
	props: {
		password: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	methods: {
		show() {
			this.isShow = true
		},
		close() {
			this.hide()
		},
		initPw() {
			this.$emit('initPw', this.password)
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalNoticeInitPw {
	.box {
		width: 100%;
		background-color: $GREY_1;
		border-radius: 5px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		p {
			font-size: 0.875rem;
			color: $DEFAULT;
			font-weight: 400;
		}
	}
}
</style>
