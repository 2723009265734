<template>
	<div class="page-wrapper">
		<migration-header :isMigration="true" :text="headerText"></migration-header>
		<div class="content-wrapper">
			<div class="text-wrapper">
				<h5 class="title" v-html="titleText"></h5>
				<div class="migration-member-info-wrapper">
					<div class="info-text-wrapper top">
						<div class="info-header">
							<img class="info-image" src="@/assets/svg/migration/icon-migration-member-green.svg" />
							<div class="text-green">{{ textGreen }}</div>
						</div>

						<div class="info-text" v-html="infoTextGreen"></div>
					</div>
					<div class="info-text-wrapper">
						<div class="info-header">
							<img class="info-image" src="@/assets/svg/migration/icon-migration-member-red.svg" />
							<div class="text-red">{{ textRed }}</div>
						</div>
						<div class="info-text" v-html="infoTextRed"></div>
					</div>
				</div>
				<div class="subtext" v-html="subtext"></div>
			</div>
			<migration-member-table :members="migrationMember"></migration-member-table>
			<migration-button-area
				@back="routeMigrationInputInstitutionName"
				@next="confirm"
				:nextButtonText="nextButtonText"
				:nextDisable="isNextDisabled"
			></migration-button-area>
		</div>
		<modal-common-confirm
			:title="modalCommonTitle"
			:content="modalCommonContent"
			:isShow="displayConfirmModal"
			@close="displayConfirmModal = false"
			:buttonText="confirmButtonText"
			@confirm="callMigrationApi"
		></modal-common-confirm>
		<modal-confirm
			ref="modal-no-member"
			title="자동 이전 대상자 없음"
			content="랩매니저 PRO 자동 이전 가능한 대상자가 없습니다.<br/><br/>* 문의사항: 고객센터 1533-3061"
			:hasConfirm="false"
			cancelText="닫기"
		/>
	</div>
</template>

<script>
import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'
import MixinMigration from '@/mixins/migration'
import MigrationHeader from '@/views/migration/components/MigrationHeader.vue'
import MigrationButtonArea from '@/views/migration/components/MigrationButtonArea.vue'
import MigrationMemberTable from '@/views/migration/components/MigrationMemberTable.vue'
import ModalCommonConfirm from '@/views/common/components/modals/ModalCommonConfirm.vue'
import { mapActions, mapState } from 'vuex'
import { EOLType } from '@/utils/define/DefineEOLType'
import PageName from '@/utils/define/PageName'

export default {
	components: {
		LmConfirmButtonMiddle,
		MigrationHeader,
		MigrationButtonArea,
		MigrationMemberTable,
		ModalCommonConfirm,
	},
	mixins: [MixinMigration],
	data() {
		return {
			headerText: this.tt('멤버 확인'),
			titleText: this.tt('랩매니저 PRO로 이전되는 멤버를 확인해 주세요.'),
			content: this.tt('기관명은 랩매니저 PRO의 필수 정보입니다.<br>연구실이 속한 기관의 이름을 입력해주세요.'),
			subtext: this.tt(
				'※ [PRO 이전하기]를 클릭하면 자동 이전 대상 멤버와 함께 랩매니저 PRO로 이전됩니다.<br/>이전된 후에는 랩매니저 구버전 접속이 불가합니다.'
			),
			textGreen: this.tt('자동 이전'),
			infoTextGreen: this.tt('단일 연구실에 소속된 멤버입니다. 연구실 이전된 후 랩매니저 PRO를 사용하게 됩니다.'),
			textRed: this.tt('자동 이전 제외'),
			infoTextRed: this.tt(
				'복수 연구실에 소속된 멤버입니다. 랩매니저 PRO에서는 하나의 연구실에만 소속될 수 있기 때문에 해당 멤버는 추후 연구실 선택이 필요합니다.'
			),
			nextButtonText: this.tt('PRO 이전하기'),
			confirmButtonText: this.tt('이전하기'),
			modalCommonTitle: this.tt('랩매니저 PRO로 이전하기'),
			modalCommonContent: this.tt(
				'멤버 및 연구실 데이터가 이전되며 최대 10분 가량 소요됩니다.<br />이전 중에는 서비스를 이용할 수 없으며, 이전 작업은 취소가 불가합니다.'
			),
			displayConfirmModal: false,
			testMemberList: [],
		}
	},
	computed: {
		...mapState('migration', ['migrationMember']),
		isNextDisabled() {
			return !this.migrationMember.some(it => it.status == 'AVAILABLE')
		},
	},
	async mounted() {
		await this.loadMigrationUser()
		if (this.isNextDisabled) {
			this.$refs['modal-no-member'].show()
		}
	},
	methods: {
		...mapActions('appEOL', ['loadEolState']),
		...mapActions('migration', ['loadMigrationUser', 'requestMigration']),
		async callMigrationApi() {
			await this.requestMigration()
			await this.loadEolState()
			location.reload()
		},
		confirm() {
			if (!this.isNextDisabled) {
				this.displayConfirmModal = true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.content-wrapper {
		display: flex;
		flex-direction: column;
		width: 700px;
		border-radius: 16px;
		padding: 40px 50px 30px 50px;
		background: white;
		box-shadow: 0px 1px 2px 0px #0000001a;

		.text-wrapper {
			margin-bottom: 30px;
			.title {
				font-size: 24px;
				font-weight: 700;
				line-height: 35px;
				letter-spacing: 0px;
				text-align: left;
				margin-bottom: 10px;
			}

			.migration-member-info-wrapper {
				background: $GREY_1;
				width: 100%;
				border-radius: 5px;
				margin-bottom: 10px;
				display: flex;
				flex-direction: column;
				padding: 12px 12px 16px 12px;
				.info-text-wrapper {
					&.top {
						margin-bottom: 20px;
					}

					.info-header {
						display: flex;
						.info-image {
							padding-right: 10px;
						}
						.text-green {
							color: $GREEN;
							font-size: 14px;
							font-weight: 700;
							line-height: 20px;
							letter-spacing: 0px;
							text-align: left;
						}
						.text-red {
							color: $RED;
							font-size: 14px;
							font-weight: 700;
							line-height: 20px;
							letter-spacing: 0px;
							text-align: left;
						}
					}
					.info-text {
						font-size: 14px;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: 0px;
						text-align: left;
						margin-left: 34px;
					}
				}
			}

			.subtext {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0px;
				text-align: left;
				color: $SECONDARY_2;
			}
		}
	}
}
</style>
