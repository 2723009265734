<template>
	<div class="Modal ModalNoticeLocation" v-if="isShow">
		<div class="Popup__background"></div>
		<div class="Modal__container">
			<div class="Modal__content">
				<h5>{{ tt('보관함 추가하기') }}</h5>
				<p
					v-html="
						tt(
							'생성된 위치 중에 <b>보관함을 등록할 위치를 선택</b>하고, 그 위치에 <b>보관함을 등록</b>할 수 있습니다.<br>보관함의 <b>관리자, 경고표지</b> 등을 입력하여 관리할 수 있습니다.'
						)
					"
				></p>
				<div class="imgbox">
					<img src="@/assets/images/create-3.png" />
				</div>
				<div class="btn-group">
					<button class="Btn__yellow" @click="close">
						{{ tt('시작하기') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalNoticeLocation',
	mixins: [MixinModal],
	methods: {
		close() {
			this.$emit('hideNoticeStorage')
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalNoticeLocation {
	.Modal__container {
		.Modal__content {
			padding: 40px;
			max-height: unset;
			h5 {
				font-size: 1.5rem;
				font-weight: 700;
				width: 100%;
				text-align: left;
			}
			p {
				font-size: 0.875rem;
				color: $DEFAULT;
				margin-top: 10px;
				width: 100%;
				text-align: left;
			}
			.imgbox {
				margin: 30px 0;
				width: 600px;
				img {
					width: 100%;
				}
			}
			.Btn__yellow {
				width: 195px;
				height: 48px;
				font-size: 1rem;
				font-weight: 700;
			}
		}
	}
}
</style>
