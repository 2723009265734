var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    staticClass: "modal",
    attrs: {
      "id": "ModalPaymentRequirement",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "no-fade": "",
      "title": _vm.tt('주문 필수 정보 등록'),
      "ok-title": _vm.tt('확인'),
      "cancel-title": _vm.tt('닫기'),
      "cancel-variant": "default",
      "ok-variant": "primary",
      "size": "md",
      "centered": ""
    },
    on: {
      "close": _vm.cancel,
      "cancel": _vm.cancel,
      "ok": _vm.complete,
      "show": _vm.init
    }
  }, [_c('div', {
    staticClass: "modal-payment-requirement"
  }, [_c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('랩매니저 스토어에서 판매하는 제품들이 연구목적으로 사용되는 것을 확인 하기위해 회원님이 소속된 학교/기업등을 확인합니다'))
    }
  }), _c('div', {
    staticClass: "business"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": function click($event) {
        return _vm.active(_vm.ACTIVE_TYPE.BUSINESS);
      }
    }
  }, [_c('div', [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasBusinessRegistration && _vm.valid.business,
      expression: "!hasBusinessRegistration && valid.business"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_1.svg"),
      "alt": ""
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasBusinessRegistration && !_vm.valid.business,
      expression: "!hasBusinessRegistration && !valid.business"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_1_red.svg"),
      "alt": ""
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasBusinessRegistration,
      expression: "hasBusinessRegistration"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_1_green.svg"),
      "alt": ""
    }
  }), _c('span', {
    "class": !_vm.valid.business ? 'invalid' : null,
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v(_vm._s(_vm.tt('사업자등록증(결제처) 정보')))]), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasBusinessRegistration,
      expression: "hasBusinessRegistration"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/check_green.svg"),
      "alt": ""
    }
  })]), _c('div', [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowBusiness,
      expression: "isShowBusiness"
    }],
    attrs: {
      "src": require("@/assets/svg/arrow_up_b.svg")
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowBusiness,
      expression: "!isShowBusiness"
    }],
    attrs: {
      "src": require("@/assets/svg/arrow_down_g.svg")
    }
  })])]), _c('payment-requirement-business', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowBusiness,
      expression: "isShowBusiness"
    }],
    ref: "payment-requirement-business",
    staticClass: "payment-requirement-business",
    on: {
      "next": _vm.next
    }
  })], 1), _c('div', {
    staticClass: "shipping"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": function click($event) {
        return _vm.active(_vm.ACTIVE_TYPE.SHIPPING);
      }
    }
  }, [_c('div', [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasDelivery && _vm.valid.shipping,
      expression: "!hasDelivery && valid.shipping"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_2.svg"),
      "alt": ""
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasDelivery && !_vm.valid.shipping,
      expression: "!hasDelivery && !valid.shipping"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_2_red.svg"),
      "alt": ""
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasDelivery,
      expression: "hasDelivery"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_2_green.svg"),
      "alt": ""
    }
  }), _c('span', {
    "class": !_vm.hasDelivery && !_vm.valid.shipping ? 'invalid' : null,
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v(_vm._s(_vm.tt('배송지 정보')))]), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasDelivery,
      expression: "hasDelivery"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/check_green.svg"),
      "alt": ""
    }
  })]), _c('div', [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowShipping,
      expression: "isShowShipping"
    }],
    attrs: {
      "src": require("@/assets/svg/arrow_up_b.svg")
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowShipping,
      expression: "!isShowShipping"
    }],
    attrs: {
      "src": require("@/assets/svg/arrow_down_g.svg")
    }
  })])]), _c('payment-requirement-shipping', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowShipping,
      expression: "isShowShipping"
    }],
    ref: "payment-requirement-shipping",
    staticClass: "payment-requirement-shipping",
    on: {
      "next": _vm.next
    }
  })], 1), _c('div', {
    staticClass: "authentication"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": function click($event) {
        return _vm.active(_vm.ACTIVE_TYPE.AUTHENTICATION);
      }
    }
  }, [_c('div', [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isVerifiedUser && _vm.valid.authentication,
      expression: "!isVerifiedUser && valid.authentication"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_3.svg"),
      "alt": ""
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isVerifiedUser && !_vm.valid.authentication,
      expression: "!isVerifiedUser && !valid.authentication"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_3_red.svg"),
      "alt": ""
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVerifiedUser,
      expression: "isVerifiedUser"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/circle_num_3_green.svg"),
      "alt": ""
    }
  }), _c('span', {
    "class": !_vm.valid.authentication ? 'invalid' : null,
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v(_vm._s(_vm.tt('본인 인증 정보')))]), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVerifiedUser,
      expression: "isVerifiedUser"
    }],
    attrs: {
      "src": require("@/assets/svg/payment/requirement/check_green.svg"),
      "alt": ""
    }
  })]), _c('div', [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowAuthentication,
      expression: "isShowAuthentication"
    }],
    attrs: {
      "src": require("@/assets/svg/arrow_up_b.svg")
    }
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowAuthentication,
      expression: "!isShowAuthentication"
    }],
    attrs: {
      "src": require("@/assets/svg/arrow_down_g.svg")
    }
  })])]), _c('payment-requirement-authentication', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowAuthentication,
      expression: "isShowAuthentication"
    }],
    ref: "payment-requirement-authentication",
    staticClass: "payment-requirement-authentication",
    on: {
      "next": _vm.next
    }
  })], 1), _c('div', {
    staticClass: "footer"
  }, [_c('ul', [_c('li', [_vm._v(_vm._s(_vm.tt('시험용, 연구용, 검사용 시약은 해당용도로만 사용되어야합니다.')))]), _c('li', [_vm._v(_vm._s(_vm.tt('취급 시 유해화학물질 취급기준을 준수 하여야합니다.')))])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }