var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    ref: "cargo-table",
    staticClass: "cargo-table-wrap",
    attrs: {
      "cellspacing": "0",
      "cellpadding": "0"
    }
  }, [_c('cargo-table-header'), _vm._l(_vm.list, function (cargo) {
    return _c('cargo-table-item', {
      key: cargo.id,
      attrs: {
        "item": cargo
      },
      on: {
        "needInputCargoInfo": function needInputCargoInfo(item) {
          return _vm.$emit('needInputCargoInfo', item);
        },
        "click": _vm.goToDetail
      }
    });
  }), _vm.isEmptyList ? _c('div', {
    staticClass: "empty-table"
  }, [_c('img', {
    staticClass: "empty-img",
    attrs: {
      "src": require("@/assets/svg/empty/inventory-empty-data.svg")
    }
  }), _c('p', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.tt('입고할 물품이 없습니다')))]), _c('p', {
    staticClass: "sub"
  }, [_vm._v(_vm._s(_vm.tt('입고할 항목을 추가해보세요')))])]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }