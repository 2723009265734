var render = function () {
  var _vm$userPermission$li;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_vm.isShow ? _c('div', {
    staticClass: "Modal ModalLicenseManagement"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt('라이선스 관리')))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "license-info"
  }, [_c('div', {
    staticClass: "license-summary"
  }, [_c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/lm-pro-icon.png")
    }
  })]), _c('div', {
    staticClass: "txtbox"
  }, [_c('div', {
    staticClass: "license-header-text"
  }, [_c('h6', [_vm._v(_vm._s(_vm.licenseTypeText))]), _c('margin-span', {
    attrs: {
      "styleObj": {
        marginRight: '3px'
      }
    }
  }), _vm.isNotCertEdu ? _c('lm-ballon', {
    attrs: {
      "title": _vm.tt('교육기관 인증 방법'),
      "content": _vm.tt('인증자료 제출 필수<br>&nbsp;•&nbsp;학생일 경우: 학생증 앞면, 입학 허가서<br>&nbsp;•&nbsp;교수님일 경우: 교직원증, 재직증명서<br><br>lmcs@smartjackwp.com으로 인증자료를 보내주세요.<br>영업일 기준 2일내 인증이 완료됩니다.')
    }
  }) : _vm._e()], 1), _vm.isFreeTier ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('30일 무료')) + " ")]) : _vm.isTrial ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('무료')) + " ")]) : _vm.isEducation ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('${1}원 / 연 (연구실 당)', _vm.formatNumberLocale(_vm.licenseRatePlan['YEAR']['EDUCATION']))) + " ")]) : _c('p', [_vm._v(" " + _vm._s(_vm.tt('${1}원 / 월 (라이선스 당)', _vm.formatNumberLocale(_vm.getAdditonalPrice))) + " ")])])]), _c('div', {
    staticClass: "license-current"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('현재 라이선스')))]), _vm.isLicenseDepositWait ? _c('div', {
    staticClass: "waiting-noti"
  }, [_c('ul', [_c('li', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('${1}까지 입금하지 않으면 라이선스가 <b class=red>비활성화</b>됩니다.', _vm.tt("<b class='red'>".concat(_vm.formatExpireDate(_vm.licenseManagement.schedule.expireDatetime), "</b>"))))
    }
  })])]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.tt(_vm.licenseManagement.schedule.virtualAccountBankName + ' ${1} (예금주: ${2})', _vm.licenseManagement.schedule.virtualAccountNumber, _vm.licenseManagement.schedule.virtualAccountHolder)) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('결제금액: ${1}원', _vm.formatNumberLocale(_vm.licenseManagement.schedule.totalPrice))) + " ")])])]) : _vm._e(), _c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('상태')))]), _c('div', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "license-status",
    "class": {
      expired: !_vm.isLicenseActivate
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/sign-safe.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.licenseStatusText) + " ")]), _vm.isLicenseDepositWait ? _c('span', {
    staticClass: "waiting-status"
  }, [_vm._v(" " + _vm._s(_vm.tt('입금대기')) + " ")]) : _vm._e()])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('라이선스 만료일')))]), _c('span', [_vm._v(" " + _vm._s(_vm.formatDatetime(_vm.license.expireDate)) + " ")])]), _vm.isFreeTier ? _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('멤버 수')))]), _c('span', {
    staticClass: "license-member"
  }, [_vm._v(" " + _vm._s(_vm.memberCount) + " ")])]) : _c('li', [_c('p', [_vm._v(" " + _vm._s(_vm.tt('활성화된 멤버 / 라이선스 수')) + " ")]), _c('span', {
    staticClass: "license-member"
  }, [_c('span', {
    "class": {
      red: _vm.isMemberFull
    }
  }, [_vm._v(" " + _vm._s(_vm.formatNumberLocale(_vm.memberCount)) + " ")]), _c('span', {
    staticClass: "license-count"
  }, [_vm._v(" / " + _vm._s(_vm.license.quantity == _vm.MAX_QUANTITY ? _vm.tt('무제한') : _vm.formatNumberLocale(_vm.license.quantity)) + " ")])])])])]), _vm.isLicenseDepositWait ? _c('button', {
    staticClass: "Btn__yellow",
    domProps: {
      "textContent": _vm._s(_vm.tt('주문취소'))
    },
    on: {
      "click": _vm.clickOrderCancel
    }
  }) : !_vm.isLicenseDepositWait && _vm.userPermission.license && _vm.userPermission.license.create && !_vm.isEducation ? _c('button', {
    staticClass: "Btn__yellow",
    domProps: {
      "textContent": _vm._s(!_vm.isLicenseActivate || _vm.isFreeTier || _vm.isTrial ? _vm.tt('라이선스 구매') : _vm.tt('라이선스 추가'))
    },
    on: {
      "click": function click($event) {
        !_vm.isLicenseActivate || _vm.isFreeTier || _vm.isTrial ? _vm.showPurchase('buying') : _vm.showPurchase('add');
      }
    }
  }) : (_vm$userPermission$li = _vm.userPermission.license) !== null && _vm$userPermission$li !== void 0 && _vm$userPermission$li.create && !_vm.isLicenseActivate ? _c('button', {
    staticClass: "Btn__yellow",
    domProps: {
      "textContent": _vm._s(_vm.tt('라이선스 구매'))
    },
    on: {
      "click": function click($event) {
        return _vm.showPurchase('buying');
      }
    }
  }) : _vm._e(), _vm.beingSubscription ? _c('div', {
    staticClass: "regular-payment"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('다음 결제')))]), _c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('등록된 결제 수단')))]), _c('b', [_vm._v(" " + _vm._s("".concat(_vm.licenseManagement.subscription.card.issuer).concat(_vm.tt('카드'), " **** **** **** ").concat(_vm.licenseManagement.subscription.card.number)) + " ")])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('다음 결제일')))]), _c('span', [_vm._v(" " + _vm._s(_vm.licenseManagement.subscription.paymentDate) + " ")])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('라이선스 수')))]), _c('span', {
    "class": {
      red: _vm.isLicenseLessThanPurchaseError
    }
  }, [_vm._v(" " + _vm._s(_vm.licenseManagement.subscription.quantity == _vm.MAX_QUANTITY ? _vm.tt('무제한') : _vm.formatNumberLocale(_vm.licenseManagement.subscription.quantity)) + " ")])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('라이선스 적용기간')))]), _c('span', [_vm._v(" " + _vm._s(_vm.licenseManagement.subscription.period) + " ")])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('라이선스 적용일수')))]), _c('span', [_vm._v(" " + _vm._s(_vm.formatNumberLocale(_vm.licenseManagement.subscription.month) + _vm.tt('개월')) + " ")])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('결제 예정 금액')))]), _c('span', {
    staticClass: "license-price"
  }, [_vm._v(" ￦" + _vm._s(_vm.formatNumberLocale(_vm.licenseManagement.subscription.totalPrice)) + " "), _c('i', [_c('img', {
    "class": {
      folded: _vm.priceFolded
    },
    attrs: {
      "src": require("@/assets/svg/arrow-down.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.toggle();
      }
    }
  })])])]), !_vm.priceFolded ? _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('소계')))]), _c('span', [_vm._v(" ￦" + _vm._s(_vm.formatNumberLocale(_vm.licenseManagement.subscription.price)) + " ")])]) : _vm._e(), !_vm.priceFolded ? _c('li', [_c('p', [_vm._v("VAT(10%)")]), _c('span', [_vm._v(" ￦" + _vm._s(_vm.formatNumberLocale(_vm.licenseManagement.subscription.vat)) + " ")])]) : _vm._e()])]) : _vm._e(), !_vm.isFreeTier && _vm.beingSubscription && _vm.isLicenseActivate ? _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.manageSubscribe
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('정기결제 관리')) + " ")]) : _vm._e(), _vm.beingSubscription && _vm.isPaymentCardError && _vm.userPermission.license && _vm.userPermission.license.update && _vm.isLicenseActivate ? _c('div', {
    staticClass: "not-use-noti"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<span style=color:#ff5746;>결제카드 정보</span>와 관련하여 문제가 발생했습니다. <br>결제카드를 업데이트해주세요'))
    }
  }), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('changeCard');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('정기결제 관리')) + " ")])]) : _vm._e(), _vm.memberMoreThanSubscribeLicense && _vm.userPermission.license && _vm.userPermission.license.update ? _c('div', {
    staticClass: "not-use-noti"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<span style=color:#ff5746;>결제될 라이선스 수</span>보다 <span style=color:#ff5746;>멤버 수</span>가 더 많습니다.<br>정기결제 정보를 업데이트해주세요'))
    }
  }), _vm.userPermission.license && _vm.userPermission.license.update ? _c('button', {
    on: {
      "click": function click($event) {
        return _vm.showPurchase('buying');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('정기결제 관리')) + " ")]) : _vm._e()]) : _vm._e(), _vm.isSubscriptionPurchaseError ? _c('div', {
    staticClass: "not-use-noti"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<span style=color:#ff5746;>결제실패</span> 로 라이선스 결제가 완료되지 않았습니다.<br>문의: 02-1533-3061'))
    }
  })]) : _vm._e()])])]), _c('modal-confirm-delete-v-2', {
    ref: "modal-confirm-delete",
    attrs: {
      "title": _vm.tt('주문취소'),
      "content": _vm.tt('정말로 라이선스 구매 주문을 취소하시겠습니까?'),
      "delBtnText": _vm.tt('주문취소'),
      "delBtnFn": _vm.cancelOrder
    }
  }), _c('modal-common-confirm', {
    attrs: {
      "isShow": _vm.displayModalRegistCardConfirm,
      "title": _vm.tt('결제카드 등록'),
      "content": _vm.tt('정기결제를 설정하기 위해서는 먼저 결제카드를 등록해야합니다.')
    },
    on: {
      "close": function close($event) {
        _vm.displayModalRegistCardConfirm = false;
      },
      "confirm": function confirm($event) {
        _vm.displayModalRegistCard = true;
      }
    }
  }), _c('modal-regist-card', {
    attrs: {
      "isShow": _vm.displayModalRegistCard,
      "mode": _vm.beingSubscription ? 'CHANGE' : 'REGIST',
      "onlyRegist": ""
    },
    on: {
      "close": function close($event) {
        _vm.displayModalRegistCard = false;
      },
      "showManageSubscribe": function showManageSubscribe($event) {
        return _vm.showPurchase('management');
      }
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }