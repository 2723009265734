<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<!-- 버튼 영역 -->
			<div class="button-wrap">
				<div>
					<image-button
						:text="'파일첨부'"
						:image="require('@/views/inventoryGen2/components/svg/icon-upload.svg')"
						@click="$refs['excel-table'].uploadFile()"
					></image-button>
					<image-button
						:text="'양식파일 다운로드'"
						:image="require('@/views/inventoryGen2/components/svg/icon-upload.svg')"
						@click="fileDown"
					></image-button>
				</div>
				<div>
					<image-button
						:text="'보관함 지정'"
						:image="require('@/assets/svg/StorageAdd.svg')"
						:disabled="selectedItems.length === 0"
						@click="showModalSelectStorage"
					></image-button>
					<image-button
						:text="'삭제'"
						:image="require('@/assets/svg/Trash.svg')"
						:disabled="selectedItems.length === 0"
						@click="onClickDelete"
					></image-button>
				</div>
			</div>
			<div v-if="isLoading">
				<lm-modal-loading ref="modal-loading" height="100%" :play="isLoading" backgroundColor="#FFFFFFCC"></lm-modal-loading>
				<div class="progress-wrap">
					<b-progress :value="progressCount" :max="excelListTotalCount"></b-progress>
				</div>
			</div>
			<div v-else style="height: calc(100% - 70px)">
				<div
					v-if="excelList.length > 0"
					class="valid-wrap"
					:class="{ error: !isValidExcelList || excelListTotalCount > 500 }"
					v-html="validText"
				></div>
				<excel-table ref="excel-table" :list="excelList" @select="showModalSelectStorage" @edit="showEditModal"></excel-table>
			</div>
		</template>
		<template #modal-footer>
			<modal-footer completeText="완료" cancelText="취소" @cancel="cancel" @complete="complete"></modal-footer>
		</template>
		<modal-item-product ref="modal-item-product" title="물품정보 수정" @complete="$refs['modal-item-management'].show()"></modal-item-product>
		<modal-item-management
			ref="modal-item-management"
			title="관리 및 추가정보"
			@goPrev="$refs['modal-item-product'].show()"
			@complete="managementComplete"
		></modal-item-management>
		<modal-single-select-storage ref="modal-select-storage" title="보관함 선택" @complete="selectStorage"></modal-single-select-storage>
		<modal-complete-regist
			ref="modal-complete-regist"
			title="물품 등록 완료!"
			confirmBtnText="더 등록하기"
			@confirm="registMore"
			@cancel="modalHide"
		></modal-complete-regist>
	</b-modal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import ModalItemProduct from '@/views/inventoryGen2/components/regist/modal/ModalItemProduct.vue'
import ModalItemManagement from '@/views/inventoryGen2/components/regist/modal/ModalItemManagement.vue'
import ModalSingleSelectStorage from '@/views/common/components/modalGen2/ModalSingleSelectStorage.vue'
import ModalCompleteRegist from '@/views/inventoryGen2/components/regist/modal/ModalCompleteRegist.vue'
import ModalFooter from '@/views/common/components/modals/ModalFooter.vue'
import LmModalLoading from '@/views/common/components/LmModalLoading.vue'
import ExcelTable from './excelTable'
export default {
	components: {
		ModalItemProduct,
		ModalItemManagement,
		ModalSingleSelectStorage,
		ModalCompleteRegist,
		ModalFooter,
		LmModalLoading,
		ExcelTable,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState('inventoryGen2/form', ['category', 'excelList', 'selectedItems', 'registMethod']),
		...mapGetters('inventoryGen2/form', ['isValidExcelList', 'excelListTotalCount']),
		validText() {
			return this.isValidExcelList
				? this.excelListTotalCount <= 500
					? this.tt('모든 항목이&nbsp;<p class="green">정상적으로 입력</p>되었습니다.')
					: this.tt('<p class="red">등록가능한 물품갯수 500개</p>를 초과하였습니다.&nbsp;<p class="red">물품갯수</p>를 수정해 주세요.')
				: this.tt('<p class="red">올바르지 않은 정보로 입력된 항목</p>이 있습니다.&nbsp;<b>[수정]</b> 버튼을 클릭하여 정보를 수정해 주세요.')
		},
	},
	data() {
		return {
			selectItem: null,
			isLoading: false,
			// 등록 성공한 물품 갯수
			progressCount: 0,
		}
	},
	methods: {
		...mapMutations('inventoryGen2/form', [
			'initItem',
			'setExcelList',
			'setFormItem',
			'setItemCustomFields',
			'initRegistMore',
			'initExcelList',
			'setItemFormType',
			'setSelectedItems',
		]),
		...mapActions('inventoryGen2/form', ['getList', 'downloadExcel', 'validate', 'registExcelItem']),
		...mapActions('inventoryGen2/list', ['getList']),
		show() {
			this.initExcelList()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async complete() {
			if (!this.isValidExcelList || this.excelListTotalCount > 500) {
				this.$root.toast('엑셀로 등록 실패', '정보를 수정해주세요', 'error')
				return
			}
			if (this.excelList.length <= 0) {
				this.$root.toast('엑셀로 등록 실패', '엑셀 파일을 업로드해주세요', 'error')
				return
			}

			this.isLoading = true
			// 사전 validate 체크
			try {
				const response = await this.validate()
				if (response.some(it => it.error.length > 0)) {
					// 사전 validate 체크했을때 error 있을경우 등록 실패
					this.$root.toast(`엑셀로 등록 실패`, `${response.find(it => it.error.length > 0).error[0]}`, 'error')
					this.isLoading = false
				} else {
					const errorList = []
					for (const it of this.excelList) {
						try {
							const formData = this.toFormData(it)
							const response = await this.registExcelItem(formData)
							this.progressCount += response.ids.length
						} catch (e) {
							errorList.push({ ...it, error: [e.response?.data?.msg] })
						}
					}

					this.isLoading = false
					if (this.excelListTotalCount !== this.progressCount) {
						this.$root.toast(
							'일부 등록 완료',
							`총 ${this.excelListTotalCount}개의 물품중 ${
								this.excelListTotalCount - this.progressCount
							}개의 물품을 등록 실패하였습니다`,
							'warning'
						)
						this.setExcelList(errorList)
						this.setSelectedItems([])
					} else {
						this.$root.toast('등록 완료', '물품이 등록되었습니다.', 'success')
						this.$refs['modal-complete-regist'].show()
					}
					this.getList()
					this.progressCount = 0
				}
			} catch (e) {
				this.isLoading = false
			}
		},
		registMore() {
			this.$refs['modal-complete-regist'].hide()
			this.initRegistMore()
			this.initExcelList()
		},
		modalHide() {
			this.initItem()
			this.$refs['modal'].hide()
		},
		toFormData(item) {
			if (!this.category) return
			const formData = new FormData()
			formData.append('registerMethod', this.registMethod)
			Object.entries(item)?.forEach(([key, value]) => {
				if (value !== null) {
					switch (key) {
						case 'register':
							formData.append('registerId', value.id)
							break
						case 'ingredient':
							if (value?.length > 0) {
								let idx = 0
								value?.forEach(it => {
									if (it.name && it.ratio) {
										formData.append(`${key}[${idx}][name]`, it.name)
										formData.append(`${key}[${idx}][ratio]`, it.ratio)

										if (it.casno) {
											formData.append(`${key}[${idx}][casno]`, it.casno)
										}
										idx++
									}
								})
							}
							break
						case 'images':
							value.forEach(it => formData.append(`images`, it))
							break
						case 'tagsId':
							// 편집시 데이터 없는 경우
							if (value?.length !== 0) {
								value.forEach(it => formData.append(`${key}[]`, it))
							} else {
								formData.append(`${key}[]`, '')
							}
							break
						case 'deleteImages':
						case 'qrSticker':
							if (value?.length === 0) {
								formData.append(key, '')
							} else {
								value?.forEach(it => formData.append(`${key}[]`, it))
							}
							break
						case 'customField':
							let idx = 0
							item?.customField?.forEach(it => {
								// 등록시 value 값에 빈 string을 전달시 error
								if (it?.value) {
									if (typeof it.value == 'object') {
										formData.append(`customField[${idx}][key]`, it.key)
										formData.append(`customField[${idx}][value]`, it.value.id)
									} else {
										formData.append(`customField[${idx}][key]`, it.key)
										formData.append(`customField[${idx}][value]`, it.value)
									}
									idx++
								}
							})
							break
						case 'expireDatetime':
						case 'buyDatetime':
						case 'openDatetime':
							if (value) {
								formData.append(`${key}`, value)
							}
							break
						case 'msdsFile':
							formData.append('msds', value)
							break
						case 'error':
						case 'tempId':
						case 'assignee':
						case 'storage':
						case 'brand':
						case 'ingredientList':
						case 'msds':
							break
						default:
							if ((value ?? null) !== null) {
								formData.append(key, value)
							}
							break
					}
				}
			})

			return formData
		},
		cancel() {
			this.$refs['modal'].hide()
		},
		async fileDown() {
			const response = await this.downloadExcel()
			window.fileDownload(response, '물품 등록 양식.xlsx')
		},
		showEditModal(item) {
			this.setItemFormType('EXCEL_EDIT')
			this.setFormItem(item)
			if (item.customField?.length > 0) {
				const custom = {}
				Object.values(item.customField)?.forEach(it => {
					custom[it.key] = it.value
				})
				this.setItemCustomFields(custom)
			}
			this.$refs['modal-item-product'].show()
		},
		managementComplete() {
			console.log('관리 및 추가정보 모달 complete', this.excelList)
		},
		onClickDelete() {
			this.setExcelList(this.excelList.filter(it => !this.selectedItems.some(sit => sit.tempId === it.tempId)))
			this.setSelectedItems([])
		},
		showModalSelectStorage(item) {
			let storage = null
			if (item) {
				this.selectItem = item
				storage = this.selectItem.storage
			} else {
				this.selectItem = null
			}
			this.$refs['modal-select-storage'].show(storage)
		},
		selectStorage(selectStorage) {
			if (selectStorage === null) return
			const arr = JSON.parse(JSON.stringify(this.excelList))
			arr.forEach(it => {
				if (this.selectItem) {
					if (it.tempId === this.selectItem.tempId) {
						it.storage = selectStorage
						it.storageId = selectStorage.id
					}
				} else {
					if (this.selectedItems.some(sit => sit.tempId === it.tempId)) {
						it.storage = selectStorage
						it.storageId = selectStorage.id
					}
				}
			})
			this.setExcelList(arr)
			this.selectItem = null
			this.$refs['modal-select-storage'].init()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-excel-regist.scss';
::v-deep .progress-bar {
	background-color: $LAB_YELLOW;
}
.progress-wrap {
	width: 50%;
	position: absolute;
	left: 25%;
}
.valid-wrap {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	background: linear-gradient(0deg, rgba(13, 197, 119, 0.1), rgba(13, 197, 119, 0.1)), $LAB_WHITE;
	&.error {
		background: linear-gradient(0deg, rgba(255, 87, 70, 0.1), rgba(255, 87, 70, 0.1)), $LAB_WHITE;
		p {
			color: $RED;
		}
	}
}
::v-deep .green {
	color: $GREEN;
}
::v-deep .red {
	color: $RED;
}
</style>
