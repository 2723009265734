var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.subPath ? _c('section', {
    staticClass: "AppHeader__container",
    "class": {
      experience: _vm.isAppModeExperience
    }
  }, [_c('div', {
    staticClass: "AppHeader__left-header"
  }, [_c('p', {
    on: {
      "click": _vm.goInsitution
    }
  }, [_vm._v(_vm._s(_vm.headerPath))]), _c('span', [_vm._v(" / ")]), _c('p', {
    "class": {
      active: _vm.detailPaths.length == 0 && !_vm.$route.params.name
    },
    on: {
      "click": _vm.clickMenu
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.subPath)) + " ")]), _vm.detailPaths.length > 0 ? _c('b', [_vm._v(" / " + _vm._s(_vm.tt(_vm.detailPaths[0])))]) : _vm.$route.params.name ? _c('b', [_vm._v(" / " + _vm._s(_vm.$route.params.name))]) : _vm._e()]), _c('div', {
    staticClass: "AppHeader__right-header"
  }, [_c('div', {
    staticClass: "btn-notice",
    on: {
      "click": function click($event) {
        return _vm.$root.managementLicense();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-billing.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt('라이선스')))]), _c('div', {
    staticClass: "btn-notice",
    on: {
      "click": _vm.goNotice
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-notice.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt('공지사항')))]), _c('app-notice'), !_vm.isAppModeExperience ? _c('app-header-menu') : _vm._e()], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }