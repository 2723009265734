var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "size": "lg",
      "hide-footer": "",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.tt('법령별 출처')) + " ")])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "content"
  }, [_c('h4', [_vm._v(_vm._s(_vm.tt('위험물관리법')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('국가위험물 정보시스템')) + " ")]), _c('h4', [_vm._v(_vm._s(_vm.tt('산업안전보건법')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('안전보건공단 화학물질정보')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('국가법령정보센터')) + " ")]), _c('h4', [_vm._v(_vm._s(_vm.tt('화학물질관리법')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('화학물질정보시스템')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('화학물질종합정보시스템')) + " ")]), _c('h4', [_vm._v(_vm._s(_vm.tt('폐기물관리법')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('국가법령정보센터')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('화학물질종합정보시스템')) + " ")]), _c('div', {
    staticClass: "comment"
  }, [_vm._v("*일부 CAS no의 경우 자체적으로 수집한 Data를 기반함")])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }