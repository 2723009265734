<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt('카테고리 선택') }}</div>
			<div class="form-msg" :class="{ error: !isValid }">
				{{ tt(validMessage) }}
			</div>
		</div>
		<category-selector ref="category-selector" noSmall :value="category" @change="selectCategory"></category-selector>
	</div>
</template>
<script>
import CategorySelector from '@/views/common/components/categorySelector/CategorySelector.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'CargoCategorySelector',
	components: { CategorySelector },
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('draft/form', ['category', 'categoryInfo']),
		validMessage() {
			return this.isValid ? this.tt('입력완료') : this.tt('필수값을 선택해주세요')
		},
		isValid() {
			return this.category
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.setData()
		})
	},
	methods: {
		...mapActions('draft/form', ['selectCategory']),
		setData() {
			if (this.$refs['category-selector']) {
				this.$refs['category-selector'].setData(this.categoryInfo)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
</style>
