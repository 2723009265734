import logger from '@/services/logger'
import { mapGetters, mapMutations } from 'vuex' //DATA: 로깅 모듈 관련 - 장은학

export default {
	data() {
		return {
			isShow: false,
			modalType: '',
			keyword: '',
		}
	},
	computed: {
		//DATA: 로깅 모듈 관련 - 장은학
		//DATA START ---
		...mapGetters('logger', ['getSession']),
		// ---DATA END
	},
	watch: {
		//DATA: 로깅 모듈 관련 - 장은학
		//DATA START ---
		isShow() {
			if (this.isShow) {
				logger.modalReport(this.$options.name, this.$route, this.getSession)
				this.updateLastElapsedMs(new Date().getTime())
			} else {
				logger.modalHideReport(this.$options.name, this.$route, this.getSession)
				this.updateLastElapsedMs(new Date().getTime())
			}
		},
		// ---DATA END
	},
	beforeMount() {
		window.addEventListener('keyup', this.keyupEvent)
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.keyupEvent)
	},
	methods: {
		...mapMutations('logger', ['updateLastElapsedMs']), //DATA: 로깅 모듈 관련 - 장은학
		keyupEvent(e) {
			if (e.keyCode == 27) {
				this.hide()
			}
		},
		show() {
			this.isShow = true
		},
		hide() {
			this.isShow = false
		},
		goToMemberManagement() {
			this.$router.push({ path: '/MemberManagement' })
		},
		goToOrganizationTree() {
			if (this.$route.name === 'OrganizationTree') {
				this.hide()
			} else {
				this.$router.replace({ path: '/OrganizationTree' })
			}
		},
		goToLocationTree() {
			if (this.$route.name === 'Location') {
				this.hide()
			} else {
				this.$router.replace({ path: '/institute/location' })
			}
		},
		showGroupNames(groups) {
			return groups
				.map(group => {
					return group.name
				})
				.join(', ')
		},
		highlightName(name) {
			const lowercaseName = name.toLowerCase()
			const characterIndex = lowercaseName.indexOf(this.keyword.toLowerCase())
			if (name.length > 0 && characterIndex > -1) {
				const subStrCharacter = name.substr(characterIndex, this.keyword.length)
				return name.replace(subStrCharacter, `<mark>${subStrCharacter}</mark>`)
			} else {
				return name
			}
		},
		refresh() {
			this.$emit('refresh')
		},
		focusInput() {
			if (this.$refs.scan) {
				this.$refs.scan.focus()
			}
		},
	},
}
