<template>
	<div class="table-wrap">
		<table class="table">
			<tr class="table-header" cellspacing="0" cellpadding="0">
				<th class="header-text">{{ tt('물질 이름') }}</th>
				<th class="header-text">CAS No</th>
				<th class="header-text">{{ tt('성분비') }}</th>
			</tr>
			<tr class="table-body" v-for="(item, i) in ingredient" :key="`${item.name}-${i}`">
				<td class="name">
					<div class="data">{{ item.name }}</div>
				</td>
				<td class="casno">
					<div class="data">{{ item.casno }}</div>
				</td>
				<td class="ratio">
					<div class="data">{{ item.ratio }}</div>
				</td>
			</tr>
		</table>
	</div>
</template>
<script>
export default {
	name: 'IngredientTable',
	components: {},
	props: {
		ingredient: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
th + th,
td + td {
	border-left: 1px solid $DIVIDER;
}

tr + tr {
	border-top: 1px solid $DIVIDER;
}

.table-wrap {
	width: 100%;
	border-radius: 5px;
	border: 1px solid $DIVIDER;

	.table {
		border-style: hidden;
		margin: 0px;
		text-align: left;
		.table-header {
			background: $GREY_2;
			border-bottom: 1px solid $DIVIDER;
			font-size: 14px;
			font-weight: 700;
			color: $SECONDARY_2;
			th {
				padding: 10px 30px;
			}
		}
		.table-body {
			td {
				padding: 10px 30px;
				height: auto;
				word-break: break-word;
				.data {
					font-size: 16px;
					font-weight: 400;
					overflow: hidden;
				}
			}
			.name {
				min-width: 300px;
				max-width: 400px;
			}
			.casno {
				min-width: 200px;
				max-width: 300px;
			}
			.ratio {
				min-width: 200px;
				max-width: 300px;
			}
		}
	}
}
</style>
