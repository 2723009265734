<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">
				{{ tt(title) }}
				<img
					class="question"
					width="24"
					height="24"
					src="@/views/purchase/cargo/svg/icon-round-question.svg"
					@click="$refs['modal-describe'].show()"
				/>
			</div>
		</template>
		<template>
			<div class="body">
				<table>
					<table-header></table-header>
					<tbody>
						<table-item ref="item" v-for="(item, idx) in previewData" :item="item" :key="item.id" :idx="idx + 1"></table-item>
					</tbody>
				</table>
			</div>
			<a href="https://www.law.go.kr/LSW/admRulLsInfoP.do?admRulSeq=2100000049106" target="_blank">
				<div
					class="btm-describe"
					v-html="
						tt('GHS+QR 스티커는 <b class=\'underline\'>「위험물안전관리법」시행규칙 별표 19</b>에 의하여 지정된 법령규격으로 출력됩니다.')
					"
				></div>
			</a>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<button class="button-primary" @click="exportPdf()">
					{{ buttonText }}
				</button>
			</div>
		</template>
		<modal-describe-sticker ref="modal-describe"></modal-describe-sticker>
	</b-modal>
</template>
<script>
import TableItem from './modalPrintSticker/TableItem.vue'
import TableHeader from './modalPrintSticker/TableHeader.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import ModalDescribeSticker from '@/views/purchase/cargo/components/cargoList/modal/ModalDescribeSticker.vue'
export default {
	name: 'ModalPrintSticker',
	components: { TableHeader, TableItem, ModalDescribeSticker },
	props: {
		maxSize: {
			type: Number,
			default: 0,
		},
		selectedCargoId: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			checkedAll: false,
		}
	},
	computed: {
		...mapGetters('modal/printSticker', ['previewData', 'selectedItem', 'selectedCount']),
		title() {
			return '물품ID 스티커 출력'
		},
		buttonText() {
			return this.selectedCount ? this.tt('PDF로 내보내기 (${1})', this.selectedCount) : this.tt('PDF로 내보내기')
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('modal/printSticker', ['getGhsPreview', 'setInit', 'exportPdf']),
		...mapMutations('modal/printSticker', ['setSelectedItem', 'setType']),
		async show() {
			this.setInit()
			this.$refs['modal'].show()
			this.setType('cargo')
			await this.getGhsPreview(this.selectedCargoId)
			this.$nextTick(() => {
				if (this.$refs['item']) {
					this.$refs['item'].forEach(item => item.resizeSvg())
				}
			})
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .underline {
	text-decoration: underline;
}

table {
	background: $LAB_WHITE;
}

::v-deep .modal-header {
	padding: 15px 20px;
	border: 0;
	font-weight: 700;
	height: 54px;
	.modal-title {
		font-weight: 700;
		font-size: 16px;
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 350px;
	max-height: 700px;
	.title {
		display: flex;
		align-items: center;
		img {
			margin-top: 4px;
		}
	}
	.modal-content {
		background-color: $LAB_WHITE;
		border-radius: 8px;
		border: 0;

		.form-wrap {
			padding: 20px;
			background: white;
			border: 1px solid #ccc;
			border-radius: 8px;
			gap: 20px;
			.form-header {
				font-weight: 700;
				font-size: 16px;
				.form-title {
				}
				.form-msg {
				}
			}
		}
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	padding: 0px;
	overflow-y: auto;
	border-top: 1px solid $DIVIDER;
	background: $SYSTEM_BACKGROUND;

	.body {
		height: 680px;
		overflow-y: auto;
		overflow-x: hidden;
		.qr-counter {
			background: #fbfaf9;
			padding: 15px 20px;
			color: $LAB_BLACK;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 50px;
			&.full {
				background: linear-gradient(0deg, rgba(13, 197, 119, 0.1), rgba(13, 197, 119, 0.1)), linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
			}
		}
		.counter {
			font-size: 20px;
			font-weight: 700;
			&.full {
				color: $GREEN;
			}
		}
	}
	.btm-describe {
		position: relative;
		width: 100%;
		bottom: 0;
		height: 50px;
		padding: 7px 16px;
		text-align: center;
		font-size: 12px;
		font-weight: 400;
		background-color: #fbfaf9;
		align-items: center;
		border-top: 1px solid $DIVIDER;
		cursor: pointer;
	}
}

::v-deep .modal-footer {
	align-items: center;
	.modal-footer-wrap {
		margin: 0px;
		width: 100%;
		display: flex;
		gap: 10px;
		.button-gray {
			display: flex;
			justify-content: center;
			width: 100px;
			height: 50px;
			background-color: $GREY_2;
			font-size: 16px;
			font-weight: 700;
			border-radius: 5px;
		}
		.button-primary {
			display: flex;
			justify-content: center;
			width: 100%;
			height: 50px;
			background-color: $LAB_YELLOW;
			font-size: 16px;
			font-weight: 700;
			border-radius: 5px;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
			}
		}
	}
}

.question {
	cursor: pointer;
}
</style>
