<template>
	<div class="datepicker">
		<p>{{ tt(title) }}:</p>
		<date-picker v-model="time" valueType="format" range :lang="lang" :placeholder="placeholder"></date-picker>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import MixinDate from '@/mixins/date'
export default {
	props: {
		title: {
			type: String,
			default: '날짜',
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	components: {
		DatePicker,
	},
	mixins: [MixinDate],
	data() {
		return {
			time: [],
		}
	},
	watch: {
		time: function () {
			this.$emit('changeDate', this.time)
		},
	},
	methods: {
		value(time) {
			if (time == null) this.time = []
			else this.time = time
		},
	},
}
</script>
<style lang="scss" scoped>
.datepicker {
	margin-left: 20px;
	p {
		display: inline-block;
		font-size: 0.875rem;
		color: rgba(0, 0, 0, 0.87);
		font-weight: 400;
		padding-right: 5px;
	}
	::v-deep {
		.mx-datepicker {
			background: white;
			border-radius: 4px;
			color: rgba(0, 0, 0, 0.87);
			font-weight: 700;
			border: 0;
			height: 30px;
			width: 220px;
			box-sizing: border-box;
			.mx-input {
				font-size: 0.875rem;
				color: rgba(0, 0, 0, 0.87);
				font-weight: 700;
				// border: 0;
				height: 30px;
				background: white;
				border: 1px solid $INPUT;
				box-shadow: none;
				color: #000 !important;
			}
			.mx-icon-calendar {
				content: url('~@/assets/svg/item-info-icon2.svg');
				background: $GREY_4;
				padding: 4px;
				height: 28px;
				right: 1px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				border-left: 1px solid $INPUT;
			}
		}
	}
}
</style>
