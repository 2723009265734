<template>
	<b-modal ref="preview-modal" centered @close="$emit('close')">
		<template #modal-title>
			<div class="title">{{ tt('GHS 라벨 미리보기') }}</div>
		</template>
		<template>
			<ghs-label :volume="normalizedVolume"></ghs-label>
		</template>
		<template #modal-footer>
			<modal-footer @goPrev="$emit('goPrev')" @ok="$emit('complete')" @cancel="cancel" isPrev></modal-footer>
		</template>
	</b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import GhsLabel from './GHSLabel.vue'
import ModalFooter from '@/views/common/components/modalGen2/components/footer/ModalFooter.vue'
export default {
	name: 'ModalGHSPreview',
	components: { GhsLabel, ModalFooter },
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('modal/ghs', ['normalizedVolume']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('modal/ghs', ['getUnitList', 'setInit']),
		show() {
			this.$refs['preview-modal'].show()
		},
		hide() {
			this.$refs['preview-modal'].hide()
		},
		cancel() {
			this.hide()
			this.setInit()
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	padding: 20px;
	background: $GREY_1;
	box-shadow: 0px 1px 2px 0px #0000001a;
	.title {
		font-size: 18px;
		font-weight: 700;
	}
	.button-gray {
		position: absolute;
		top: 20px;
		right: 65px;
		background: $GREY_4;
		display: flex;
		border-radius: 4px;
		padding: 5px 10px;
		height: 30px;
		font-size: 14px;
		font-weight: 700;
		&:hover {
			background: $HOVER_BUTTON_COMMON;
		}
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 1000px;
	width: fit-content;
	.modal-content {
		background-color: $LAB_WHITE;
		border: 0;
	}
}

::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	padding: 20px;
	justify-content: center;
	overflow: auto;
	background: $GREY_1;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	.content {
		height: 100%;
		border-top: 1px solid $DIVIDER;
		h4 {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 8px;
		}
		.text {
			font-size: 14px;
		}
	}
}

::v-deep .modal-footer {
	padding: 20px;
	border: 0;
	background: $GREY_1;
}
</style>
