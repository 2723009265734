var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lab-selector-wrapper"
  }, [_c('div', {
    staticClass: "lab-selector-counter"
  }, [_c('span', {
    staticClass: "lab-selector-counter-text",
    domProps: {
      "innerHTML": _vm._s(_vm.selectedLabCountText)
    }
  })]), _c('div', {
    staticClass: "lab-selector-list-wrapper"
  }, _vm._l(_vm.labs, function (item) {
    return _c('migration-lab-selector-item', {
      key: "lab-item-".concat(item.labId),
      attrs: {
        "item": item,
        "selectedLabId": _vm.selectedLabId
      },
      on: {
        "click": _vm.selectItem
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }