<template>
	<tr class="table-header gray">
		<th>
			<div>{{ tt('예산차감일시') }}</div>
		</th>
		<th>
			<div style="min-width: 250px; max-width: 300px">{{ tt('예산 이름') }}</div>
		</th>
		<th>
			<div>{{ tt('예산 번호') }}</div>
		</th>
		<th>
			<div style="min-width: 200px; max-width: 250px">{{ tt('기안 제목') }}</div>
		</th>
		<th>
			<div>{{ tt('지출금액') }}</div>
		</th>
		<th>
			<div>{{ tt('기안 연구실') }}</div>
		</th>
		<th>
			<div>{{ tt('기안자') }}</div>
		</th>
		<th>
			<div>{{ tt('기안상신일시') }}</div>
		</th>
		<th>
			<div>{{ tt('결재완료일시') }}</div>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	computed: {
		...mapGetters('budget/list', ['order', 'orderDirection']),
		isASC() {
			return this.orderDirection == 'ASC'
		},
	},
	methods: {
		...mapActions('budget/list', ['setLoadParams']),
		changeOrder(order) {
			let orderDirection = 'ASC'
			if (this.order == order && this.isASC) orderDirection = 'DESC'
			this.setLoadParams({
				order: [order],
				orderDirection: [orderDirection],
			})
		},
		orderClass(order) {
			return {
				order: true,
				active: order == this.order,
				asc: this.isASC,
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
