<template>
	<div class="menu">
		<div class="left">
			<button class="button-gray" @click="$emit('addDivide')">
				<img src="@/views/inventoryGen2/svg/detail/icon-subdivide.svg" width="24" height="24" />
				<span>
					{{ tt('소분 용량 입력') }}
				</span>
			</button>
		</div>
		<div class="right">
			<button class="button-gray" :disabled="noItem" @click="$emit('selectStorage')">
				<img :src="require(`@/views/inventoryGen2/svg/detail/icon-storage-add${noItem ? '-disabled' : ''}.svg`)" width="24" height="24" />
				<span> {{ tt('보관함 지정') }}</span>
			</button>
			<button class="button-gray" :disabled="noItem" @click="removeSelectedItem()">
				<img :src="require(`@/views/inventoryGen2/svg/detail/icon-trash${noItem ? '-disabled' : ''}.svg`)" width="24" height="24" />
				<span> {{ tt('삭제') }}</span>
			</button>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'DivideMenu',
	components: {},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/divide', ['divideItems', 'selectedItem']),
		noItem() {
			return this.divideItems?.length <= 0 || this.selectedItem?.length === 0
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('inventoryGen2/divide', ['removeSelectedItem']),
	},
}
</script>

<style lang="scss" scoped>
.button-gray {
	border: 1px solid $GREY_4 !important;
}
</style>
