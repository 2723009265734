<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="EditMemberAuthorityPopup">
			<div class="Popup__background"></div>
			<div class="Memeber__info-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt('권한 관리') }}
					</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>

				<div class="Popup__content">
					<h6>{{ tt('선택된 멤버의 권한이 저장됩니다.') }}</h6>
					<div class="Popup__select-field setting-select-field deleteable-input-field">
						<p>{{ tt('권한 지정') }}</p>
						<permission-box
							v-for="(permission, index) in selectAuthorityList"
							:key="'permission' + index"
							:item="permission"
							:list="authorityList"
							:index="index"
							@select="selectAuthority"
							@delete="deleteAuthority"
							:selectedList="selectAuthorityList"
						>
						</permission-box>
						<button class="AppContainer__add-item-btn" @click="addAuthority" v-if="selectAuthorityList.length < authorityList.length">
							+{{ tt('추가하기') }}
						</button>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button @click="close" class="Btn__close">
							{{ tt('취소') }}
						</button>
						<button @click="save" class="Btn__complete">
							{{ tt('저장') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import PermissionBox from '@/views/creation/pages/components/PermissionBox.vue'
import { mapState, mapActions } from 'vuex'
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalEditMemberPermission',
	mixins: [MixinModal],
	components: {
		PermissionBox,
	},
	data() {
		return {
			authority: false,
			authorityList: [],
			selectAuthorityList: [],
			list: [],
			selectItem: Object,
			authorityInfo: {},
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('permission', ['permissionList']),
	},
	methods: {
		...mapActions('permission', ['lookupPermissionList']),
		async show(permissions) {
			this.isShow = true
			this.selectAuthorityList = [...permissions]

			const payload = {
				instId: this.institute.id,
				keyword: '',
				offset: 0,
				length: undefined,
			}
			await this.lookupPermissionList(payload).then(() => {
				this.authorityInfo = this.$root.deepCopy(this.permissionList)
				this.authorityList = this.authorityInfo.list
			})
		},
		selectAuthority(select) {
			this.selectAuthorityList[select.index] = {
				id: select.item.id,
				name: select.item.name,
			}
			const index = this.authorityList.findIndex(x => {
				return x.id == select.item.id
			})
			// this.authorityList.splice(index, 1);
		},
		addAuthority() {
			this.selectAuthorityList.push({
				id: '',
				name: '',
			})
		},
		deleteAuthority(select) {
			if (this.selectAuthorityList.length < 2) {
				this.selectAuthorityList = [
					{
						id: '',
						name: '',
					},
				]
			} else {
				this.selectAuthorityList.splice(select.index, 1)
			}
		},
		close() {
			this.hide()
		},
		save() {
			this.$emit('isHide', this.selectAuthorityList)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_member.scss';
</style>
