var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: !_vm.isValid
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "taglist"
  }, [_c('div', {
    staticClass: "tagitem add",
    on: {
      "click": _vm.showModalAddTag
    }
  }, [_c('img', {
    attrs: {
      "width": "24",
      "height": "24",
      "src": require("@/assets/svg/icon/black-round-plus.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt('태그 추가')) + " ")]), _vm._l(_vm.tagList, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "tagitem",
      "class": {
        active: _vm.isActive(tag.id)
      },
      on: {
        "click": function click($event) {
          return _vm.handleClick(tag.id);
        }
      }
    }, [_vm._v(" " + _vm._s("#".concat(tag.name)) + " ")]);
  })], 2), _c('div', {
    staticClass: "btn-edit",
    on: {
      "click": function click($event) {
        return _vm.$refs['ModalEdtiFormTagList'].show();
      }
    }
  }, [_vm._v("편집")]), _c('modal-add-tag', {
    ref: "modal-add-tag",
    on: {
      "activeTag": _vm.activeTag
    }
  }), _c('ModalEdtiFormTagList', {
    ref: "ModalEdtiFormTagList"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }