var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('button', {
          staticClass: "button-gray",
          on: {
            "click": _vm.showGuide
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/views/common/components/modalGen2/svg/icon-question.svg"),
            "width": "24",
            "height": "24"
          }
        }), _vm._v(" " + _vm._s(_vm.tt('GHS 라벨 생성 가이드')) + " ")]), _c('modal-ghs-guide', {
          ref: "guide"
        })];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "content-tab-wrap"
  }, [_vm.msds && _vm.msds.master ? _c('div', {
    staticClass: "tab-item",
    "class": {
      active: _vm.tabType === 'master'
    },
    on: {
      "click": function click($event) {
        _vm.tabType = 'master';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('제조사 MSDS')) + " ")]) : _vm._e(), _vm.msds && _vm.msds.institute ? _c('div', {
    staticClass: "tab-item",
    "class": {
      active: _vm.tabType === 'institute'
    },
    on: {
      "click": function click($event) {
        _vm.tabType = 'institute';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('업로드된 MSDS')) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "content-label-info-wrap"
  }, [_c('div', {
    staticClass: "msds-wrap"
  }, [!_vm.noMsds ? _c('iframe', {
    staticClass: "pdf-viewer",
    attrs: {
      "src": _vm.msdsUrl ? _vm.msdsUrl : _vm.uploadUrl,
      "frameborder": "0"
    }
  }) : _c('div', {
    staticClass: "no-msds"
  }, [_c('input', {
    ref: "input-file",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "accept": "application/haansoftpdf, application/pdf"
    },
    on: {
      "change": _vm.changeItem,
      "drop": _vm.dropFile
    }
  }), _c('img', {
    attrs: {
      "src": require("@/views/common/components/modalGen2/svg/icon-upload-big.svg"),
      "width": "67",
      "height": "80"
    }
  }), _c('div', {
    staticClass: "no-msds-text",
    domProps: {
      "innerHTML": _vm._s(_vm.tt("Sigma-Aldrich\uC758 MSDS\uB97C \uC5C5\uB85C\uB4DC\uD558\uC2DC\uBA74,<br />\uBB3C\uB9AC\uC801 \uC704\uD5D8\uC131\uACFC \uAC74\uAC15 \uC720\uD574\uC131\uC774 \uC790\uB3D9\uC73C\uB85C \uC120\uD0DD\uB429\uB2C8\uB2E4.<br />MSDS\uB97C \uB4DC\uB798\uADF8 \uC564 \uB4DC\uB78D\uD574\uC8FC\uC138\uC694(PDF)"))
    }
  }), _c('div', {
    staticClass: "button-wrap"
  }, [_c('button', {
    staticClass: "button-normal"
  }, [_c('img', {
    attrs: {
      "src": require("@/views/common/components/modalGen2/svg/icon-file-upload.svg"),
      "width": "24",
      "height": "24"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('파일첨부')))])])])])]), _c('div', {
    staticClass: "label-info-wrap"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_vm.isShowWarning ? _c('div', {
    staticClass: "warn-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("@/views/common/components/modalGen2/svg/icon-round-warn.svg"),
      "width": "12",
      "height": "12"
    }
  }), _c('div', {
    staticClass: "warn",
    domProps: {
      "innerHTML": _vm._s(_vm.tt("MSDS \uBD84\uC11D\uC774 \uBD88\uAC00\uB2A5\uD558\uC5EC GHS \uB77C\uBCA8\uC774 \uC0DD\uC131\uB418\uC9C0 \uC54A\uC558\uC2B5\uB2C8\uB2E4. <br />\uAC00\uC774\uB4DC\uB97C \uCC38\uACE0\uD558\uC5EC \uC704\uD5D8\uC131 \uBD84\uB958\uB97C \uC9C1\uC811 \uC120\uD0DD\uD574\uC8FC\uC138\uC694."))
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "info-title"
  }, [_vm._v(" " + _vm._s(_vm.tt('위험성 분류')) + " ")]), _c('label-list', {
    ref: "label-list"
  }), _c('text-list', {
    ref: "text-list"
  })], 1), _c('div', {
    staticClass: "button-area"
  }, [_c('button', {
    staticClass: "button-primary",
    on: {
      "click": _vm.goNext
    }
  }, [_vm._v(_vm._s(_vm.tt('다음으로')))])])])])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }