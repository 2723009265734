var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "total-amount-wrap"
  }, [_c('div', {
    staticClass: "total-amount-title"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _vm.budget ? _c('image-button', {
    attrs: {
      "text": '상세 예산현황표 보기',
      "image": require('@/assets/svg/table.svg')
    },
    on: {
      "click": _vm.showBudgetStatusTable
    }
  }) : _vm._e()], 1), _vm.info ? _c('div', {
    staticClass: "total-amount"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.infoTitle)
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt(_vm.status)))])]), _c('div', {
    staticClass: "amount-box"
  }, [_c('div', {
    staticClass: "total"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.info.total.name)))]), _c('h6', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.CommaNum(_vm.info.total.value))))])]), _c('div', {
    staticClass: "expense"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.info.expense.name)))]), _c('h6', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.CommaNum(_vm.info.expense.value))))])]), _c('div', {
    staticClass: "balance"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.info.balance.name)))]), _c('h6', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.CommaNum(_vm.info.balance.value))))])])]), _vm.amountNoti ? _c('div', {
    staticClass: "noti"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.amountNoti)
    }
  })]) : _vm._e()]) : _vm._e(), _c('modal-budget-table', {
    ref: "modal-budget-table",
    attrs: {
      "budget": _vm.budget
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }