<template>
	<div class="LocationPage__page-container" v-if="treeData && storageInfoData">
		<div class="LocationStorage" v-if="topStorageData">
			<inst-header
				v-if="topStorageData"
				:title="topStorageData.name"
				:hasSubTitle="true"
				:subTitle="subTitle"
				:hasEditButton="userPermission.storage.update && !topStorageData.parentId"
				:editButtonFn="showEditStorage"
				:userPermission="userPermission"
			></inst-header>
			<div class="Location__content-container">
				<div class="Location__content-left">
					<div class="Location__chart-header" :class="{ shadow: showBoxShadow }">
						<lm-input-search @search="inputTreeSearch"></lm-input-search>
						<button class="AppContainer__subtitle-btn btn bold" @click="showModalAddChildStorage" v-if="userPermission.storage.create">
							<img src="@/assets/svg/button-icon-lisence-copy.svg" alt="icon" />
							{{ tt('생성') }}
						</button>
					</div>
					<div class="Location__main-container" ref="chart-main-container" v-on:scroll="handleScroll">
						<button class="AppContainer__subtitle-btn btn" style="margin-left: 10px; margin-bottom: 10px" @click="returnPage">
							<img src="@/assets/svg/icon-left.svg" alt="icon" />
							<b v-html="$route.path.indexOf('organization') > 0 ? tt('조직도 관리로 돌아가기') : tt('위치 관리로 돌아가기')"></b>
						</button>
						<TreeView
							v-if="treeData"
							ref="storage-tree-view"
							:treeData="treeData"
							textField="name"
							@changeTree="changeTree"
							mode="active"
							:selectWithChild="false"
							@changeActive="changeActiveId"
							:keyword="keyword"
							page="storage"
						></TreeView>
					</div>
				</div>

				<div class="Location__content-right" v-if="storageInfoData">
					<div class="Storage">
						<div class="AppContainer__location-title">
							<h5 class="AppContainer__location-name">
								{{ storageInfoData.name }}
							</h5>
							<img
								v-if="!isTopStorageData && userPermission.storage.update"
								class="Location__button-arrow"
								src="@/assets/svg/Button_edit.svg"
								@click="showModalEditChildStorage"
							/>
							<img
								v-if="!isTopStorageData && userPermission.storage.delete"
								class="Location__button-arrow"
								src="@/assets/svg/icon-delete-round.svg"
								@click="showModalDeleteChild"
							/>
						</div>
						<div class="Storage__warning" v-if="isTopStorageData">
							<div class="AppContainer__subtitle">
								<h6>{{ tt('경고 표지') }}</h6>
							</div>
							<ul
								class="Storage__warning-list"
								v-if="storageInfoData.warningSignImages && storageInfoData.warningSignImages.length > 0"
							>
								<li
									class="Storage__warning-item"
									v-for="(warningSign, index) in storageInfoData.warningSignImages"
									:key="`warningSign-${index}`"
								>
									<img :src="warningSign.link" alt="경고icon" />
								</li>
							</ul>
							<div v-else class="no-data">{{ tt('없음') }}</div>
						</div>
						<div class="Storage__admin" v-if="isTopStorageData">
							<div class="AppContainer__subtitle">
								<h6>{{ tt('관리자') }}</h6>
							</div>
							<ul class="Storage__admin-list horizontal-list" v-if="hasAssignee">
								<li class="Storage__admin-item flex" v-if="storageInfoData.assignee">
									<img v-if="storageInfoData.assignee.image" :src="storageInfoData.assignee.image" alt="image" />
									<img v-else src="@/assets/svg/member-photo-default.svg" alt="image" />
									<div>
										<h6>
											{{ storageInfoData.assignee.name }}
										</h6>
										<p>
											{{ hypenPhoneNumber(storageInfoData.assignee.phone) }}
										</p>
									</div>
									<span class="ml-auto">{{ tt('정') }}</span>
								</li>
								<li class="Storage__admin-item flex" v-if="storageInfoData.subAssignee">
									<img v-if="storageInfoData.subAssignee.image" :src="storageInfoData.subAssignee.image" alt="image" />
									<img v-else src="@/assets/svg/member-photo-default.svg" alt="image" />
									<div>
										<h6>
											{{ storageInfoData.subAssignee.name }}
										</h6>
										<p>
											{{ hypenPhoneNumber(storageInfoData.subAssignee.phone) }}
										</p>
									</div>
									<span class="ml-auto">{{ tt('부') }}</span>
								</li>
							</ul>
							<div v-else class="no-data">
								{{ tt('지정 안 됨') }}
							</div>
						</div>
						<div class="Storage__products">
							<div class="AppContainer__subtitle">
								<h6>{{ tt('보관물품') }}</h6>
								<margin-div :divStyle="{ marginRight: '3px' }"></margin-div>
								<span class="total-count" v-html="tt('(총 ${1}개)', formatNumberLocale(storageInfoData.itemCountAllCategory))"
									>s</span
								>
							</div>
							<ul class="Storage__products-list" v-if="storageInfoData.itemCountByCategory">
								<li v-for="item in storageInfoData.itemCountByCategory" :key="item.id" @click="goInventory(item)">
									<img :src="require('@/assets/svg/' + changeTypeToImage(item.type))" />
									<p class="name">{{ item.name }}</p>
									<p class="ml-auto" v-text="tt('${1}개', item.count)"></p>
								</li>
							</ul>
							<div class="Organization__access-no-data no-data-box" v-else>
								<img src="@/assets/svg/no-data_item.svg" alt="no-image" />
								<h6>{{ tt('보관물품이 없습니다') }}</h6>
								<p>
									{{ tt('보관함에 물품을 등록하면 자동으로 목록이 추가됩니다') }}
								</p>
							</div>
						</div>

						<div class="Storage__regulation">
							<div class="AppContainer__subtitle">
								<h6>{{ tt('법적 규제 물질') }}</h6>
								<margin-div :divStyle="{ marginRight: '3px' }"></margin-div>
								<span class="total-count" v-html="tt('(총 ${1}개)', formatNumberLocale(storageInfoData.itemCountAllLegal))"></span>
							</div>
							<ul class="Storage__regulation-list">
								<li
									class="Storage__regulation-item"
									v-for="(legalItem, index) in storageInfoData.itemCountByLegal"
									:key="`legalItem-${index}`"
									@click="goInventoryLegal(legalItem)"
								>
									<img :src="require(`@/assets/svg/${legalItem.id}.svg`)" alt="물품icon" />
									<p class="name">{{ tt(legalItem.name) }}</p>
									<p class="ml-auto" v-text="tt('${1}개', legalItem.count)"></p>
								</li>
							</ul>
						</div>
						<div class="Storage__authority" v-if="isTopStorageData">
							<div class="AppContainer__subtitle">
								<h6>{{ tt('접근 권한') }}</h6>
								<button class="AppContainer__subtitle-btn btn Dropdown__btn">
									<img class="icon-setting-auth" src="@/assets/svg/Authority.svg" alt="icon" />{{ tt('접근권한 추가') }}
									<div class="Dropdown__menu">
										<button @click="showGroupSelect">
											{{ tt('그룹') }}
										</button>
										<button @click="showMemberSelect">
											{{ tt('멤버') }}
										</button>
									</div>
								</button>
							</div>
							<div
								class="no-data-box"
								v-if="topStorageData.access && topStorageData.access.groups.length == 0 && topStorageData.access.users.length == 0"
							>
								<img src="@/assets/svg/no-data_setting.svg" alt="no-image" />
								<h6>
									{{ tt('접근 권한을 가진 그룹 또는 멤버가 없습니다') }}
								</h6>
								<p v-html="tt('<b>[접근권한 관리]</b> 버튼을 클릭하여 추가해보세요')"></p>
							</div>
							<ul class="Storage__authority-list horizational-list" v-else>
								<li
									clas="Storage__authority-item"
									v-for="groupAccess in topStorageData.access.groups"
									:key="`groupAccess-${groupAccess.id}`"
								>
									<div class="box">
										<h3>
											{{ getGroupInitialCharacter(groupAccess.name) }}
										</h3>
									</div>
									<div class="Organization__access-item-info">
										<h6>{{ groupAccess.name }}</h6>
										<p>{{ groupAccess.memberCount }}{{ tt('명') }}</p>
									</div>
									<button class="btn Btn__select Dropdown__btn">
										{{ tt(showAccessPermission(groupAccess.accessPermission)) }}
										<div class="Dropdown__menu">
											<button v-if="groupAccess.accessPermission.update" @click="updatePermission('read', groupAccess)">
												<h6>{{ tt('조회 허용') }}</h6>
												<p>
													{{ tt('연구실의 정보, 물품 등을 조회할 수 있습니다.') }}
												</p>
											</button>
											<button v-if="!groupAccess.accessPermission.update" @click="updatePermission('update', groupAccess)">
												<h6>{{ tt('편집 허용') }}</h6>
												<p>
													{{ tt('연구실의 정보, 물품 등을 조회하고 편집할 수 있습니다.') }}
												</p>
											</button>
											<button @click="removePermission(groupAccess)">
												<h6 class="red-color">
													{{ tt('접근 권한 없애기') }}
												</h6>
												<p>
													{{ tt('접근 권한을 없앱니다.') }}
												</p>
											</button>
										</div>
									</button>
								</li>
								<li
									clas="Storage__authority-item"
									v-for="userAccess in topStorageData.access.users"
									:key="`userAccess-${userAccess.id}`"
								>
									<img src="@/assets/svg/member-detail-photo.svg" />
									<div class="Organization__access-item-info">
										<h6>{{ userAccess.name }}</h6>
										<p>{{ userAccess.email }}</p>
									</div>
									<button class="btn Btn__select Dropdown__btn">
										{{ tt(showAccessPermission(userAccess.accessPermission)) }}
										<div class="Dropdown__menu">
											<button v-if="userAccess.accessPermission.update" @click="updatePermission('read', userAccess)">
												<h6>{{ tt('조회 허용') }}</h6>
												<p>
													{{ tt('연구실의 정보, 물품 등을 조회할 수 있습니다.') }}
												</p>
											</button>
											<button v-if="!userAccess.accessPermission.update" @click="updatePermission('update', userAccess)">
												<h6>{{ tt('편집 허용') }}</h6>
												<p>
													{{ tt('연구실의 정보, 물품 등을 조회하고 편집할 수 있습니다.') }}
												</p>
											</button>
											<button @click="removePermission(userAccess)">
												<h6 class="red-color">
													{{ tt('접근 권한 없애기') }}
												</h6>
												<p>
													{{ tt('접근 권한을 없앱니다.') }}
												</p>
											</button>
										</div>
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<!--modals-->
			<modal-select-group-v-2
				v-if="isTopStorageData"
				ref="modalSelectGroup"
				:title="tt('권한 추가: 그룹')"
				:treeData="groupTreeData"
				:selectedList="storageInfoData.access.groups.map(group => group.id)"
				:searchPlaceholder="tt('그룹 이름으로 검색해주세요')"
				@completeFn="manageGroupAuth"
			></modal-select-group-v-2>
			<modal-select-member-v-2
				v-if="isTopStorageData"
				ref="modalSelectMember"
				:title="tt('권한 추가: 멤버')"
				:listData="memberListData"
				:totalCount="memberListCount"
				:originMemberList="originMemberList"
				:offset="memberParams.offset"
				:length="memberParams.length"
				@addListDataFn="addMemberListData"
				@completeFn="manageMemberAuth"
				@searchMember="searchMember"
			></modal-select-member-v-2>
			<add-child-storage-box-popup
				ref="modal-add-child-storage"
				:title="tt('하위 보관함 등록')"
				:labelName="tt('하위 보관함명')"
				@complete="addChildStorage"
			></add-child-storage-box-popup>
			<add-child-storage-box-popup
				ref="modal-edit-child-storage"
				:title="tt('하위 보관함 편집')"
				:labelName="tt('하위 보관함명')"
				@complete="editChildStorage"
			></add-child-storage-box-popup>
			<modal-confirm-delete-v-2
				ref="modal-delete"
				:title="`${storageInfoData.name}` + tt('삭제')"
				:content="`${storageInfoData.name}` + tt('하위 보관함을 삭제하시겠습니까?')"
				:delBtnFn="deleteChildStorage"
			></modal-confirm-delete-v-2>
			<modal-add-storage-v-2
				ref="modal-edit-storage"
				v-if="topStorageData"
				:storageData="topStorageData"
				@refresh="refresh"
			></modal-add-storage-v-2>
		</div>
	</div>
</template>

<script>
import LmInputSearch from '@/views/common/components/input/LmInputSearch.vue'
import InstHeader from '@/views/institute/components/InstHeader'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import StorageItem from '@/views/institute/location/components/StorageItem.vue'
import { mapMutations, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
import Validation from '@/utils/Validation'
import ModalAddGroupAuth from '@/views/institute/organization/modals/ModalAddGroupAuth.vue'
import ModalSelectGroupV2 from '@/views/common/components/modals/ModalSelectGroupV2.vue'
import ModalSelectMemberV2 from '@/views/common/components/modals/ModalSelectMemberV2.vue'
import ModalConfirmDeleteV2 from '@/views/common/components/modals/ModalConfirmDeleteV2.vue'
import ObjectUtils from '@/utils/modules/ObjectUtils'
import instituteMemberAPI from '@/services/api/institute/member'
import AddChildStorageBoxPopup from '@/views/institute/location/modals/AddChildStorageBoxPopup.vue'
import storageAPI from '@/services/api/institute/storage'
import { ToastType } from '@/utils/define/ToastType'
import instituteGroupAPI from '@/services/api/institute/group'
import ModalAddStorageV2 from '@/views/institute/location/modals/ModalAddStorageV2.vue'
import { Classification } from '@/utils/define/ItemCode'

export default {
	name: 'StorageDetailPage',
	components: {
		InstHeader,
		TreeView,
		LmInputSearch,
		StorageItem,
		ModalSelectGroupV2,
		ModalSelectMemberV2,
		AddChildStorageBoxPopup,
		ModalConfirmDeleteV2,
		ModalAddStorageV2,
	},
	data() {
		return {
			productsList: [],
			showBoxShadow: false,
			keyword: '',
			treeData: null,
			storageData: null,
			topStorageData: null,
			storageInfoData: null,
			instituteId: null,
			groupTreeData: null,
			memberListData: null,
			memberListCount: 0,
			memberParams: {
				keyword: '',
				offset: 0,
				length: 10,
			},
			storageSearchKeyword: '',
			activeId: null,
			storageId: '',
		}
	},
	created() {
		const { instituteId } = this.$route.params
		this.instituteId = instituteId
	},
	async mounted() {
		const { storageId } = this.$route.params
		await this.refresh()
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('storage', ['selectedStorage', 'storageList']),
		hasAssignee() {
			return this.storageInfoData.assignee?.id || this.storageInfoData.subAssignee?.id
		},
		assigneeImg() {
			return this.storageInfoData.assignee?.image
		},
		subAssigneeImg() {
			return this.storageInfoData.subAssignee?.image
		},
		subTitle() {
			let groupNames
			if (this.topStorageData.assigneeGroup) {
				groupNames = this.topStorageData.assigneeGroup.name
			}
			const pathName = this.topStorageData.pathName
			const { type } = this.topStorageData

			if (type) {
				return `${groupNames} ${groupNames ? `\n/\n` : ''} ${type} / ${pathName}`
			} else {
				return `${groupNames ? groupNames : ''} ${groupNames ? `\n/\n` : ''} ${pathName}`
			}
		},
		isTopStorageData() {
			return this.storageInfoData.parentId === null
		},
		originMemberList() {
			return this.topStorageData.access.users.map(user => user.id)
		},
	},
	methods: {
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		async refresh() {
			let storages = this.$route.params.storageId
			await this.loadStorageTree()
			await this.loadTopStorageDetailInfo(storages)
			this.$refs['storage-tree-view'].setActiveId(storages)
		},
		async loadStorageTree() {
			const { instituteId, storageId } = this.$route.params
			const targetResponse = await storageAPI.lookupTargetStorageTree(instituteId, storageId)
			this.activeId = storageId
			this.treeData = [targetResponse.data]
			this.setStorageDetailPaths([this.treeData[0].name])
			await this.loadStorageDetailInfo(storageId)
		},
		handleScroll() {
			const scrollTop = this.$refs['chart-main-container'].scrollTop
			this.showBoxShadow = scrollTop !== 0 ? true : false
		},
		async loadTopStorageDetailInfo(storageId) {
			const { instituteId } = this.$route.params
			const response = await storageAPI.lookupDetailStorageInfo(instituteId, storageId)
			this.topStorageData = response.data
		},
		async loadStorageDetailInfo(storageId) {
			const { instituteId } = this.$route.params
			const response = await storageAPI.lookupDetailStorageInfo(instituteId, storageId)
			this.storageInfoData = response.data
		},
		changeActiveId(storageId) {
			this.loadStorageDetailInfo(storageId)
			this.$refs['storage-tree-view'].setActiveId(storageId)
			this.activeId = storageId
		},
		async changeTree(node, parentNode) {
			const { instituteId } = this.$route.params
			const body = {
				id: node.id,
				parentId: parentNode.id,
				name: node.name,
			}
			await storageAPI.updateChildStorage(instituteId, body)
		},
		inputTreeSearch(keyword) {
			this.keyword = keyword
		},
		async updatePermission(type, access) {
			const { instituteId } = this.$route.params
			const { accessId } = access
			const storageId = this.topStorageData.id
			const requestData = {
				storageId: storageId,
				accessId: accessId,
				permission: {
					read: false,
					update: false,
				},
			}

			if (type === 'read') {
				requestData.permission.read = true
			} else if (type === 'update') {
				requestData.permission.read = true
				requestData.permission.update = true
			}

			await storageAPI.updateStorageAccess(instituteId, requestData)
			const title = this.tt('접근 권한 편집 완료')
			const content = this.tt('접근 권한이 편집되었습니다')
			this.$root.toast(title, content, ToastType.SUCCESS)

			this.loadStorageDetailInfo(storageId)
			this.loadTopStorageDetailInfo(storageId)
		},
		async removePermission(access) {
			const { accessId } = access
			const { instituteId } = this.$route.params
			const storageId = this.topStorageData.id
			const paramsObject = {
				storageId: storageId,
				accessIds: [accessId],
			}

			let title
			let content
			let status

			try {
				const response = await storageAPI.deleteStorageAccess(instituteId, paramsObject)

				if (response.status == 205) {
					title = this.tt('접근 권한 삭제 완료')
					content = this.tt('접근 권한이 삭제되었습니다')
					status = 'success'
				}
			} catch (error) {
				title = this.tt('접근 권한 삭제 불가')
				content = error.response.data.msg
				status = 'error'
			}

			this.$root.toast(title, content, status)

			this.loadStorageDetailInfo(storageId)
			this.loadTopStorageDetailInfo(storageId)
		},
		showAccessPermission(permission) {
			const { update } = permission
			return update ? this.tt('편집 허용') : this.tt('조회 허용')
		},
		returnPage() {
			if (this.$route.path.indexOf('organization') >= 0) {
				this.$router.replace({
					name: PageName.Institution.Organization,
				})
			} else {
				this.$router.replace({ name: PageName.Institution.Location })
			}
		},
		hypenPhoneNumber(str) {
			return str ? Validation.autoHypenTel(str) : ''
		},
		async showGroupSelect() {
			const response = await instituteGroupAPI.lookupGroupTree(this.instituteId)
			this.groupTreeData = ObjectUtils.deepCopy(response.data)
			this.$refs['modalSelectGroup'].show()
		},
		async manageGroupAuth(changedList) {
			const { addedIdList, initialIdList, removedIdList } = changedList
			const storageId = this.topStorageData.id
			if (addedIdList.length > 0) {
				const permission = {
					read: true,
					update: false,
				}
				const addData = {
					storageId: storageId,
					groupsId: addedIdList,
					permission: permission,
				}
				await storageAPI.addStorageAccess(this.instituteId, addData)
			}

			if (removedIdList.length > 0) {
				const removeAccessIdList = this.storageInfoData.access.groups
					.filter(item => removedIdList.indexOf(item.id) >= 0)
					.map(item => item.accessId)
				const paramsObject = {
					storageId: storageId,
					accessIds: removeAccessIdList,
				}
				await storageAPI.deleteStorageAccess(this.instituteId, paramsObject)
			}
			this.loadStorageDetailInfo(storageId)
			this.loadTopStorageDetailInfo(storageId)

			const title = this.tt('접근 권한 추가 완료')
			const content = this.tt('접근 권한이 추가되었습니다')
			this.$root.toast(title, content, ToastType.SUCCESS)
		},
		async showMemberSelect() {
			this.memberParams = {
				keyword: '',
				offset: 0,
				length: 20,
			}
			const response = await instituteMemberAPI.searchMemberList(this.instituteId, this.memberParams)
			this.$refs['modalSelectMember'].show()
			this.memberListData = response.data.members
			this.memberListCount = response.data.count
		},
		async addMemberListData() {
			this.memberParams.offset += this.memberParams.length
			const response = await instituteMemberAPI.searchMemberList(this.instituteId, this.memberParams)

			this.memberListData = [...this.memberListData, ...response.data.members]
		},
		async searchMember(keyword) {
			this.memberParams = {
				keyword: keyword,
				offset: 0,
				length: 20,
			}
			const response = await instituteMemberAPI.searchMemberList(this.instituteId, this.memberParams)
			this.memberListData = response.data.members
			this.memberListCount = response.data.count
		},
		async manageMemberAuth(memberList) {
			const storageId = this.topStorageData.id
			const removeList = this.originMemberList.filter(member => !(memberList.indexOf(member) >= 0))

			const addList = memberList.filter(member => !(this.originMemberList.indexOf(member) >= 0))

			if (addList.length > 0) {
				const permission = {
					read: true,
					update: false,
				}
				const data = {
					storageId: storageId,
					usersId: addList,
					permission: permission,
				}
				await storageAPI.addStorageAccess(this.instituteId, data)
			}
			if (removeList.length > 0) {
				const removeAccessIdList = this.storageInfoData.access.users
					.filter(user => removeList.indexOf(user.id) >= 0)
					.map(user => user.accessId)
				const paramsObject = {
					storageId: storageId,
					accessIds: removeAccessIdList,
				}
				await storageAPI.deleteStorageAccess(this.instituteId, paramsObject)
			}
			this.loadStorageDetailInfo(storageId)
			this.loadTopStorageDetailInfo(storageId)
		},
		getGroupInitialCharacter(groupName) {
			return groupName.substring(0, 1)
		},
		showEditStorage() {
			this.$refs['modal-edit-storage'].show(this.topStorageData, 'EDIT_DETAIL')
		},
		showModalAddChildStorage() {
			const modalAddChildStorage = this.$refs['modal-add-child-storage']
			modalAddChildStorage.show()
			modalAddChildStorage.setStorageName('')
		},
		showModalEditChildStorage() {
			const modalEditChildStorage = this.$refs['modal-edit-child-storage']
			modalEditChildStorage.show()
			modalEditChildStorage.setStorageName(this.storageInfoData.name)
		},
		async editChildStorage(storageName) {
			const { instituteId, storageId } = this.$route.params
			const body = {
				id: this.activeId,
				parentId: this.storageInfoData.parentId,
				name: storageName,
			}
			await storageAPI.updateChildStorage(instituteId, body)

			const targetResponse = await storageAPI.lookupTargetStorageTree(instituteId, storageId)
			this.treeData = [targetResponse.data]
			await this.loadStorageDetailInfo(this.activeId)

			const title = this.tt('하위 보관함') + `${storageName}` + '\n' + this.tt('편집 완료')
			const content = this.tt('하위 보관함이 변경되었습니다')
			this.$root.toast(title, content, ToastType.SUCCESS)
		},
		async addChildStorage(storageName) {
			const { instituteId, storageId } = this.$route.params
			const body = {
				parentId: this.activeId,
				name: storageName,
			}
			await storageAPI.createChildStorage(instituteId, body)
			const targetResponse = await storageAPI.lookupTargetStorageTree(instituteId, storageId)
			this.treeData = [targetResponse.data]
			await this.loadStorageDetailInfo(this.activeId)
			this.$nextTick(() => {
				this.$refs['storage-tree-view'].setActiveId(this.activeId)
			})

			const title = this.tt('하위 보관함') + `${storageName}` + '\n' + this.tt('생성 완료')
			const content = this.tt('하위 보관함이 생성되었습니다')
			this.$root.toast(title, content, ToastType.SUCCESS)
		},
		showModalDeleteChild() {
			this.$refs['modal-delete'].show()
		},
		async deleteChildStorage() {
			const { instituteId, storageId } = this.$route.params
			try {
				const parentId = this.storageInfoData.parentId
				await storageAPI.deleteStroage(instituteId, this.storageInfoData.id)

				const title = `${this.storageInfoData.name}` + this.tt('삭제 완료')
				const content = this.tt('하위 보관함이 삭제되었습니다.')
				this.$root.toast(title, content, ToastType.SUCCESS)

				const targetResponse = await storageAPI.lookupTargetStorageTree(instituteId, storageId)
				this.treeData = [targetResponse.data]
				// const parentId = this.storageInfoData.parentId
				await this.loadStorageDetailInfo(parentId)

				this.$refs['storage-tree-view'].setActiveId(parentId)
				this.$nextTick(() => {
					this.$refs['storage-tree-view'].setActiveId(parentId)
				})
				this.activeId = parentId
			} catch (error) {
				const title = `${this.storageInfoData.name}` + this.tt('삭제 불가')
				if (error.response.status === 412) {
					const content = this.tt('물품을 이동 또는 삭제 후 다시 시도해주세요')
					this.$root.toast(title, content, ToastType.ERROR)
				} else {
					this.$root.toast(title, error.response.data.msg, ToastType.ERROR)
				}
			}
		},
		changeTypeToImage(type) {
			if (type == 'CAT_CHEMICAL_REAGENT') {
				return 'category-detail-icon0.svg'
			} else if (type == 'CAT_CHEMICAL_MADE_REAGENT') {
				return 'category-detail-icon1.svg'
			} else if (type == 'CAT_CHEMICAL_RAWMATERIAL') {
				return 'category-detail-icon2.svg'
			} else if (type == 'CAT_CHEMICAL_STANDARD') {
				return 'category-detail-icon4.svg'
			} else if (type == 'CAT_CHROMATOGRAPHY') {
				return 'category-column.svg'
			} else if (type == 'CAT_LABWARE_TOOL') {
				return 'category-experiment2.svg'
			} else if (type == 'CAT_LABWARE_EQUIPMENT') {
				return 'category-research.svg'
			} else if (type == 'CAT_PROTECTION') {
				return 'category-preotective-gear.svg'
			} else {
				return 'category-equipment.svg'
			}
		},
		goInventory(item) {
			const { storageId } = this.$route.params
			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					categoriesId: [item.id],
					categoryType: item.classification,
					storagesId: [this.storageInfoData.id ? this.storageInfoData.id : storageId],
				},
			})
		},
		goInventoryLegal(item) {
			const { storageId } = this.$route.params

			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					legalsId: [item.id],
					storagesId: [this.storageInfoData.id ? this.storageInfoData.id : storageId],
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_location-storage.scss';
@import '@/styles/scss/institution/_organization.scss';
@import '@/styles/scss/global/_storage-box.scss';
@import '@/styles/scss/location/_storage.scss';

.total-count {
	font-weight: 400;
}

.no-data {
	padding: 10px 0px !important;
	font-weight: 400;
	font-size: 14px;
	color: $PLACEHOLDER;
}

#Location {
	border-left: solid 1px rgba(228, 229, 234, 1);
	min-width: 670px !important;
}

.Location__content-right {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.LocationStorage {
	height: 100% !important;
}
.Location__content-container {
	display: flex !important;
	height: calc(100% - 67px);
}
.LocationPage__page-container {
	display: block;
}

.Location__chart-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-width: 390px;
	height: 70px;
	padding: 20px 30px;
	&.shadow {
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	}
}

.bold {
	font-weight: 700;
	font-size: 14px;
}

.ml-10 {
	margin-left: 10px;
}

.Location__main-container {
	display: block;
	position: relative;
	width: 100%;
	height: calc(100% - 70px);
	overflow-y: auto;
	user-select: none;
	padding-left: 20px;
}

.Location__content-left {
	width: 450px;
}

.Location__button-arrow {
	margin-left: 10px;
	cursor: pointer;
}

.StorageBox__item {
	margin-bottom: 20px;
}

.icon-setting-auth {
	padding-right: 5px;
}

.new-icon {
	width: 16px;
	height: 16px;
	margin-left: 8px;
}
</style>
