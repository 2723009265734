var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('fixed-header', {
    attrs: {
      "title": _vm.tt('예산 목록')
    },
    on: {
      "changeLoadParams": _vm.setLoadParams
    }
  }), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('budget-table', {
    attrs: {
      "list": _vm.list
    }
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.count
    },
    on: {
      "page": _vm.pagination
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }