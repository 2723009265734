const draftStatus = {
	ALL: '전체',
	NOW: '결재대기',
	ING: '진행중',
	ACCEPT: '승인',
	REJECT: '반려',
}

const paymentStatus = {
	null: '미결제',
	PAID: '결제완료',
	WAIT: '입금대기',
	CANCEL: '결제취소',
}
const paymentMethod = {
	CARD: '카드결제',
	DIRECT_CARD: '바로결제',
	LATER: '나중에결제',
	EBILL: '전자세금계산서',
}

export { draftStatus, paymentStatus, paymentMethod }
