var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-item"
  }, [_c('td', [_c('span', {
    staticClass: "checkbox",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, null) > -1 : _vm.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checked = $$c;
        }
      }
    }
  })])]), _c('td', [_c('span', {
    staticClass: "profile-wrap"
  }, [_c('img', {
    staticClass: "profile-img",
    attrs: {
      "src": _vm.userImage
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt(_vm.item.user.name)))])])]), _c('td', [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.type.title)) + " ")])]), _c('td', {
    staticStyle: {
      "min-width": "400px"
    }
  }, [_c('div', {
    staticClass: "content-wrap",
    staticStyle: {
      "min-width": "250px",
      "max-width": "370px"
    }
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html",
      value: _vm.tt(_vm.type.content),
      expression: "tt(type.content)",
      modifiers: {
        "hover": true,
        "html": true
      }
    }],
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.type.content))
    }
  })])]), _c('td', {
    staticStyle: {
      "min-width": "160px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.createDatetime))])]), _c('td', {
    staticStyle: {
      "min-width": "100px"
    }
  }, [_c('div', {
    staticClass: "button-wrap"
  }, [_c('button', {
    staticClass: "button-delete",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$refs['modal-remove'].show();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-disposal.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('삭제')))])])])]), _c('modal-confirm', {
    ref: "modal-remove",
    attrs: {
      "title": "기록 삭제하기",
      "confirmText": "삭제하기",
      "variant": "warning",
      "content": "\uC815\uB9D0\uB85C \uC120\uD0DD\uD55C \uAE30\uB85D\uC744 \uC0AD\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?</br>\uC774 \uB3D9\uC791\uC740 \uB418\uB3CC\uB9B4 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4."
    },
    on: {
      "confirm": _vm.remove
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }