var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "hide-footer": ""
    },
    on: {
      "close": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "text-wrap",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.headerText))
    }
  }), _c('div', {
    staticClass: "reason-wrap"
  }, [_c('div', {
    staticClass: "input-head-text"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.titleTextarea)))]), _c('p', {
    staticClass: "text-length"
  }, [_c('span', {
    staticClass: "length",
    "class": {
      over: _vm.isLengthOver
    }
  }, [_vm._v(_vm._s(_vm.text.length))]), _vm._v(" /" + _vm._s(_vm.textMaxLength) + " ")])]), _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.focusOut,
      expression: "focusOut"
    }]
  }, [_c('textarea', {
    staticClass: "input-reason",
    "class": {
      error: _vm.isShowTextError && _vm.isLengthOver
    },
    attrs: {
      "placeholder": _vm.tt(_vm.placeholder)
    },
    domProps: {
      "value": _vm.formattedText
    },
    on: {
      "input": _vm.setText,
      "focus": function focus($event) {
        _vm.isFocus = true;
      }
    }
  }), _vm._v(" "), _vm.isShowTextError && _vm.isLengthOver ? _c('p', {
    staticClass: "error-msg"
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-error.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt(_vm.errorMsg)) + " ")]) : _vm._e(), _c('recent', {
    ref: "recent",
    attrs: {
      "show": _vm.isKeywordWrapperOpen,
      "list": _vm.commentList
    },
    on: {
      "clickComment": _vm.clickComment,
      "deleteComment": _vm.clickDeleteComment
    }
  })], 1)])]), _c('div', {
    staticClass: "modal-footer-wrap"
  }, [_c('button', {
    staticClass: "button-white",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _vm.useMiddleBtn ? _c('button', {
    staticClass: "button-white",
    on: {
      "click": function click($event) {
        return _vm.$emit('clickMiddle');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.middleBtnText)) + " ")]) : _vm._e(), _c('button', {
    "class": "button-".concat(_vm.variant),
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.confirmText)) + " ")])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }