<template>
	<div class="store-info-wrapper">
		<b>{{ tt('구매 안내') }}</b>
		<div class="process-wrap">
			<div class="left-wrap">
				<div>
					<img src="./images/icon-tip-1.png" />
					<p>{{ tt('랩매니저 스토어 상품 검색') }}</p>
				</div>
				<img src="./images/icon-arrow.svg" />
				<div>
					<img src="./images/icon-tip-2.png" />
					<p>{{ tt('상품주문') }}</p>
				</div>
			</div>
			<img src="./images/icon-arrow.svg" />
			<div class="right-wrap">
				<p>{{ tt('결제 가능 시점') }}</p>
				<div>
					<div>
						<img src="./images/icon-tip-3.png" />
						<p>{{ tt('랩매니저 스토어 주문승인') }}</p>
					</div>
					<img src="./images/icon-arrow.svg" />
					<div>
						<img src="./images/icon-tip-4.png" />
						<p>{{ tt('상품배송') }}</p>
					</div>
				</div>
			</div>
		</div>
		<span class="button-wrap">
			{{ tt('구매 프로세스') }}
			<!-- <img src="@/assets/svg/icon-polygon2.svg" :class="{ isShow: isProcessShow }" /> -->
		</span>

		<div class="info-wrap">
			<p>{{ tt('1. 구매관리 메뉴에서의 상품 주문은 후불결제 방식으로 진행됩니다. (300만원 한도)') }}</p>
			<p>
				{{
					tt('2. 상품을 주문하면, 랩매니저 스토어로 주문내역이 접수됩니다(랩매니저 스토어에서 주문내역, 배송내역, 결제관리 등 확인 가능).')
				}}
			</p>
			<p>{{ tt('3. 판매자의 주문확인 및 주문승인 처리 후 배송이 시작됩니다.') }}</p>
			<p>{{ tt('4. 대금결제는 판매자의 주문승인 처리 이후 단계부터 가능합니다(랩매니저 스토어 결제관리 페이지에서 가능).') }}</p>
			<p>
				{{
					tt(
						'5. 누적 미결제금액 한도는 300만원이며, 주문일 기준 익월 말까지 해당 주문금액을 결제해주셔야 합니다(미결제시 추가 주문 불가능).'
					)
				}}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			// isProcessShow: false,
		}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.store-info-wrapper {
	width: 100%;
	padding: 16px;
	background-color: $SUB-YELLOW_100;
	border-radius: 5px;
	b {
		font-size: 14px;
	}
	.info-wrap {
		padding: 5px;
		p {
			font-size: 14px;
		}
	}
	.button-wrap {
		font-size: 12px;
		font-weight: 500;
		// color: $BLUE;
		cursor: pointer;
		img {
			&.isShow {
				transform: rotateX(180deg);
			}
		}
	}
	.process-wrap {
		margin-top: 30px;
		margin-bottom: 20px;
		display: flex;
		gap: 15px;
		p {
			text-align: center;
			width: 80px;
			color: $SECONDARY_2;
			word-break: break-word;
			font-size: 11px;
			font-weight: 500;
			margin-top: 10px;
		}
		& > img {
			margin-top: -10px;
		}
		.left-wrap {
			display: flex;
			gap: 15px;
			margin-top: 36px;
			& > div {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			& > img {
				width: 16px;
				margin-top: -45px;
			}
		}
		.right-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 16px 0 16px;
			background-color: $SUB-YELLOW_200;
			border-radius: 8px;
			border: 1px solid $LAB_YELLOW;
			& > p {
				margin-bottom: 10px !important;
			}
			& > div {
				display: flex;
				gap: 15px;
				padding-bottom: 10px;
				p {
					margin-left: 20px;
				}
				& > img {
					margin-top: -37px;
				}
			}
		}
	}
}
</style>
