<template>
	<table class="lm-table" ref="inventory-table">
		<table-header />
		<table-item v-for="item in list" :key="item.tempId" :item="item" @select="showSelectModal" @edit="showEditModal" />
		<div class="empty-table" v-if="list.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
			<img class="empty-img" :src="emptyImage" />
			<p class="bold" v-html="emptyText"></p>
			<p class="sub">
				<image-button
					:text="'양식파일 다운로드'"
					:image="require('@/views/inventoryGen2/components/svg/icon-upload.svg')"
					@click="fileDown"
				></image-button>
				<image-button
					:text="'파일첨부'"
					:image="require('@/views/inventoryGen2/components/svg/icon-upload.svg')"
					@click="uploadFile"
				></image-button>
			</p>
		</div>
		<input class="dropFile" type="file" ref="dropFile" @change="onChange" />
	</table>
</template>
<script>
import TableHeader from './header.vue'
import TableItem from './item.vue'
import { mapState, mapActions } from 'vuex'
export default {
	components: {
		TableHeader,
		TableItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapState('inventoryGen2/form', ['category']),
		emptyImage() {
			return require('@/assets/svg/Upload(Button).svg')
		},
		emptyText() {
			return this.tt(
				'등록할 물품의 Excel 파일을<br />드래그 앤 드랍해주세요(xlsx, xlsm, xlsb, xltx)<br />한번에 업로드할 수 있는 물품의 수는 최대 500개 입니다. <br />물품관리 -> 설정을 변경하였다면 양식 파일을 새로 다운로드 받아주세요.'
			)
		},
	},
	methods: {
		...mapActions('inventoryGen2/form', ['checkExcel', 'downloadExcel']),
		goToDetail() {},
		uploadFile() {
			this.$refs.dropFile.value = null
			this.$refs['dropFile'].click()
		},
		dropFile(event) {
			this.$refs.dropFile.files = event.dataTransfer.files
			this.onChange()
		},
		async onChange() {
			try {
				const files = [...this.$refs.dropFile.files]
				if (files.length > 0) {
					const formData = new FormData()
					formData.append('categoryId', this.category.id)
					formData.append('file', files[0])
					await this.checkExcel({ formData, user: this.$root.user })
				}
			} catch (e) {
				this.$root.toast(`ERROR`, `${e.response?.data?.msg}`, 'error')
			}
		},
		async fileDown() {
			const response = await this.downloadExcel()
			window.fileDownload(response, '물품 등록 양식.xlsx')
		},
		showSelectModal(item) {
			this.$emit('select', item)
		},
		showEditModal(item) {
			this.$emit('edit', item)
		},
	},
}
</script>
<style lang="scss" scoped>
.dropFile {
	display: none;
}
.lm-table {
	position: relative;
	height: calc(100% - 40px);
	text-align: left;
	display: block;
	overflow: overlay;
	white-space: nowrap;
	border-spacing: 0;
	border-collapse: separate;
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	gap: 20px;
	.empty-img {
		width: 80px;
		height: 80px;
		margin-bottom: 24px;
		opacity: 0.5;
	}
	.bold {
		color: $PLACEHOLDER;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
		text-align: center;
	}
	.sub {
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
</style>
