var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": function close(e) {
        return _vm.cancel(e);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('modal-footer', {
          attrs: {
            "completeText": "다음",
            "cancelText": "취소"
          },
          on: {
            "cancel": _vm.cancel,
            "complete": _vm.complete
          }
        })];
      },
      proxy: true
    }])
  }, [[_vm.itemFormType !== 'NORMAL_EDIT' ? _c('item-form-counter', {
    attrs: {
      "min": 1,
      "value": _vm.multiCreateCount
    }
  }) : _vm._e(), _c('item-form-product'), _vm.isUsedIngredient ? _c('item-form-ingredient-list', {
    ref: "item-form-ingredient-list"
  }) : _vm._e(), _vm.isUsedIngredient ? _c('item-form-msds', {
    ref: "form-msds"
  }) : _vm._e()], _c('modal-confirm', {
    ref: "modal-confirm",
    attrs: {
      "title": "편집 취소",
      "content": "취소하면 편집된 정보가 적용되지 않습니다. 편집을 취소하시겠습니까?",
      "confirmText": "편집 취소"
    },
    on: {
      "confirm": function confirm($event) {
        return _vm.hide();
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }