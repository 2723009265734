var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "global-filter-wrap"
  }, [_c('div', {
    staticClass: "global-filter",
    "class": {
      active: _vm.storageCount > 0 && _vm.storageCount != _vm.checkedCount
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleFilter.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "6px"
    },
    attrs: {
      "src": require("@/assets/svg/inquiry.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('조회 범위')) + ":")]), _c('h5', {
    staticClass: "view-inst-all"
  }, [_vm._v(_vm._s(_vm.inquiryScope))]), _vm._m(0)]), _c('modal-global-filter', {
    ref: "modal-global-filter"
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "arrow-box"
  }, [_c('span', {
    staticClass: "arrow"
  })]);
}]

export { render, staticRenderFns }