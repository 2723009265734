<template>
	<div class="Modal ModalDeleteStorage" v-if="isShow">
		<div class="Popup__background"></div>
		<div class="Popup__container Popup__size-sm">
			<div class="Popup__top">
				<h5 class="Popup__title">저장소 삭제</h5>
				<button class="Popup__close-btn" @click="close">
					<img src="@/assets/svg/popup-close.svg" alt="close" />
				</button>
			</div>
			<div class="Popup__content">
				<p class="Popup__plain-text">
					정말로 저장소를 삭제하시겠습니까?<br />
					이 동작은 되돌릴 수 없습니다.
				</p>
			</div>
			<div class="Popup__bottom">
				<div class="Popup__btn-group">
					<button class="Btn__close" @click="close">
						{{ tt('취소') }}
					</button>
					<button class="Btn__delete" @click="submit">삭제</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isShow: false,
			dangerStorageItem: {},
		}
	},
	components: {},
	methods: {
		submit() {
			if (this.dangerStorageItem.length > 0) {
				this.dangerStorageItem.forEach(item => {
					if (item.checked === true) {
						item.state = 'DELETE'
					}
				})
			} else {
				if (this.dangerStorageItem.checked === true) {
					this.dangerStorageItem.state = 'DELETE'
				}
			}
			this.$emit('onSubmit', this.dangerStorageItem)
			this.close()
		},
		show(dangerStorageItem) {
			this.dangerStorageItem = dangerStorageItem
			this.isShow = true
		},
		close() {
			this.isShow = false
		},
	},
}
</script>
