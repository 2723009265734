var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('tr', [_c('td', {
    staticClass: "checkbox"
  }, [_c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isChecked
    },
    on: {
      "click": _vm.selectItem
    }
  })]), _c('td', {
    staticClass: "no"
  }, [_vm._v(_vm._s(_vm.leadingZeros(_vm.idx, 3)))]), _c('td', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('p', {
    staticClass: "sticker-type"
  }, [_vm._v(_vm._s(_vm.item.type))]), _c('p', {
    staticClass: "size"
  }, [_vm._v(_vm._s(_vm.item.paperSpec))])])]), _c('td', {
    staticClass: "preview"
  }, [_c('div', {
    staticClass: "preview-content",
    on: {
      "click": function click($event) {
        return _vm.$refs['viewer'].show();
      }
    }
  }, [!_vm.isTypeQr ? _c('div', {
    ref: "previewImage",
    staticClass: "preview-image",
    domProps: {
      "innerHTML": _vm._s(_vm.itemImage)
    }
  }) : _c('div', {
    staticClass: "qr-wrap"
  }, [_c('img', {
    attrs: {
      "width": "70",
      "height": "70",
      "src": _vm.itemImage
    }
  })]), _c('p', {
    staticClass: "code"
  }, [_vm._v(_vm._s(_vm.firstIdentifierCode))])])]), _c('modal-multi-image-viewer', {
    ref: "viewer",
    attrs: {
      "title": "미리보기",
      "imageList": _vm.item.image,
      "isRawSvg": !_vm.isTypeQr,
      "isCaption": _vm.isTypeQr,
      "captionList": _vm.identifierList
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }