<template>
	<div class="header-wrapper">
		<div class="title-area">
			<h2 class="title">
				{{ tt(title) }}
			</h2>
			<div class="subtitle" v-if="status || subtitle">
				<b v-if="status" :class="status">{{ tt(statusKor) }}</b>
				<p v-html="subtitle"></p>
			</div>
		</div>
		<div class="btn-area">
			<div>
				<base-button v-if="permission.draftCancel" @click="click('CANCEL')" :color="'warning only'">{{ tt('상신 취소') }}</base-button>
			</div>

			<div v-if="orderId">
				<base-button @click="click('STORE_ORDER')">{{ tt('주문 상세보기') }}</base-button>
				<!-- <base-button @click="click('STORE_PAYMENT')" color="primary">{{ tt('결제하기') }}</base-button> -->
			</div>
		</div>
	</div>
</template>

<script>
import { draftStatus } from '@/utils/define/StatusCode'

export default {
	components: {},
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		status: {
			type: String,
			default: '',
		},
		permission: {
			type: Object,
			default: () => {},
		},
		orderId: {
			type: Number,
		},
	},
	computed: {
		statusKor() {
			return draftStatus[this.status]
		},
	},
	methods: {
		click(params) {
			this.$emit('click', params)
		},
	},
}
</script>

<style lang="scss" scoped>
.header-wrapper {
	width: 100%;
	padding: 8px 30px;
	min-height: 54px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
	border-bottom: 1px solid $DIVIDER;
	top: 60px;
	background: white;
	z-index: 200;
	.title {
		font-size: 1.5rem;
		font-weight: bold;
		color: #000;
		text-align: left;
		align-items: center;
		height: auto;
	}
	.subtitle {
		display: flex;
		font-size: 0.75rem;
		color: $SECONDARY_2;
		margin-top: 6px;
		b {
			height: 20px;
			padding: 0 5px;
			border-radius: 5px;
			font-weight: 700;
			text-align: center;
			display: inline-block;
			margin-right: 10px;
			background-color: $DIVIDER;
			color: $SECONDARY_2;
			&.ING {
				background-color: $DIVIDER;
				color: $SECONDARY_2;
			}
			&.ACCEPT {
				background-color: rgba(13, 197, 119, 0.15);
				color: $GREEN;
			}
			&.REJECT {
				background-color: $COLOR_BACKGROUND_RED;
				color: $RED;
			}
		}
		p {
			span {
				font-weight: 400;
				color: $SECONDARY_2;
				padding-right: 16px;
				position: relative;
				&:nth-last-child(2) {
					&:before {
						width: 1px;
						height: 12px;
						background-color: $DIVIDER;
						position: absolute;
						top: 4px;
						right: 8px;
						content: '';
					}
				}
			}
		}
		h2 {
			font-weight: 400;
		}
	}
	.btn-area {
		& > div {
			display: flex;
			gap: 40px;
			button {
				height: 36px;
				border-radius: 5px;
				&:nth-child(2) {
					// padding-left: 20px;
					// border-left: 1px solid $INPUT;
					&:before {
						width: 1px;
						height: 25px;
						background-color: $DIVIDER;
						position: relative;
						right: 30px;
						content: '';
					}
				}
			}
		}
	}
}
</style>
