<template>
	<b-modal ref="modal" centered no-close-on-backdrop hide-header-close hide-footer>
		<div class="title">
			{{ tt(title) }}
		</div>
		<img src="@/views/inventoryGen2/svg/create/success-image-check-200x200.svg" width="200" />
		<div class="button-wrap">
			<button
				class="button-primary"
				@click="
					$emit('confirm')
					hide()
				"
			>
				{{ tt(confirmBtnText) }}
			</button>
			<button
				class="button-white"
				@click="
					$emit('cancel')
					hide()
				"
			>
				{{ tt(cancelBtnText) }}
			</button>
		</div>
	</b-modal>
</template>
<script>
export default {
	name: 'ModalCompleteRegist',
	components: {},
	props: {
		title: {
			type: String,
			default: '',
		},
		confirmBtnText: {
			type: String,
			default: '',
		},
		cancelBtnText: {
			type: String,
			default: '닫기',
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-complete-regist.scss';
</style>
