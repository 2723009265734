var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEmpty,
      expression: "!isEmpty"
    }],
    staticClass: "toggle-text-area-container"
  }, [_c('div', {
    staticClass: "toggle-title-area"
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    },
    on: {
      "click": _vm.onClickExpandBtn
    }
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title) + ": " + _vm._s(_vm.count))]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/green-bottom-arrow.svg"),
      "alt": "expand"
    }
  })])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowTextArea,
      expression: "isShowTextArea"
    }],
    staticClass: "toggle-contents-area"
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }