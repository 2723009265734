<template>
	<div v-show="!isEmpty" class="toggle-text-area-container">
		<div class="toggle-title-area">
			<div style="display: flex" @click="onClickExpandBtn">
				<span class="title">{{ title }}: {{ count }}</span>
				<img src="@/assets/svg/green-bottom-arrow.svg" alt="expand" />
			</div>
		</div>
		<div v-show="isShowTextArea" class="toggle-contents-area">
			{{ text }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'ToggleTextArea',
	props: {
		title: {
			type: String,
			required: true,
		},
		width: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			data: [],
			count: 0,
			text: '',
			isShowTextArea: false,
		}
	},
	computed: {
		isEmpty() {
			return this.count <= 0
		},
	},
	mounted() {
		if (this.width > 0) {
			const containerEl = document.querySelector('.toggle-text-area-container')
			containerEl.style.width = `${this.width}px`
		}
	},
	methods: {
		onClickExpandBtn() {
			this.isShowTextArea = !this.isShowTextArea
		},
		push(arr) {
			this.data = this.data.concat(arr)
			this.count = this.data.length
			this.text = this.data.join(', ')
		},
	},
}
</script>

<style lang="scss" scoped>
.toggle-title-area {
	display: flex;
	align-items: center;
	margin-bottom: 4px;
	.title {
		font-size: 10px;
		font-weight: bold;
		color: $GREEN;
		margin: 0 4px 0 0;
	}
}
.toggle-contents-area {
	width: 100%;
	font-size: 10px;
}
</style>
