<template>
	<div class="createSmallInstitution wrapper" v-if="$root.user.language">
		<migration-header :isMigration="false" :text="tt(headerText)"></migration-header>
		<progress-indicator :progressStep="progressStep"></progress-indicator>
		<div class="boxwrap">
			<require-info :progressStep="progressStep" :groupName="createParams.groupName"></require-info>
			<input-info-step1
				v-if="progressStep == 1"
				:value="createParams.instituteName"
				:placeHolder="tt('예: 스마트잭')"
				:notiMsg="tt(notiMsg)"
				@changeInputValue="changeInputValue"
				@next="next"
				ref="step1"
			/>
			<input-info-step2
				v-else-if="progressStep == 2"
				:value="createParams.groupName"
				:placeHolder="tt('예: 그린촉매연구실 또는 연구지원팀')"
				:notiMsg="tt(notiMsg)"
				@changeInputValue="changeInputValue"
				@next="next"
				ref="step2"
			/>
			<input-info-step3
				v-else-if="progressStep == 3"
				@changeStorageList="changeStorageList"
				:createStorageList="createParams.storagesName"
				ref="step3"
			/>
			<!-- <input-info-step4 v-else-if="progressStep == 4 && instituteId" ref="step4" :link="inviteLink" :instId="instituteId" :groupId="groupId" /> -->
			<div class="btn-wrap">
				<button class="next" @click="next" :disabled="isLoading">
					{{ tt(nextBtn) }}
				</button>
				<button class="prev" v-if="progressStep > 1" @click="prev">
					{{ tt('이전') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import instituteInformationAPI from '@/services/api/institute/information'
import instituteGroupAPI from '@/services/api/institute/group'
import instituteStorageAPI from '@/services/api/institute/storage'
import instituteLocationAPI from '@/services/api/institute/location'
import instituteMemberAPI from '@/services/api/institute/member'
import instituteAPI from '@/services/api/institute'
import MigrationHeader from '@/views/migration/components/MigrationHeader.vue'
import ProgressIndicator from '@/views/creation/pages/components/progressIndicator.vue'
import RequireInfo from '@/views/creation/pages/components/requireInfo.vue'
import InputInfoStep1 from '@/views/creation/pages/components/inputSimpleInfo.vue'
import InputInfoStep2 from '@/views/creation/pages/components/inputSimpleInfo.vue'
import InputInfoStep3 from '@/views/creation/pages/components/inputStorageInfo.vue'
// import InputInfoStep4 from '@/views/creation/pages/components/inputMemberInvite.vue'

export default {
	components: {
		MigrationHeader,
		ProgressIndicator,
		RequireInfo,
		InputInfoStep1,
		InputInfoStep2,
		InputInfoStep3,
		// InputInfoStep4,
	},
	data() {
		return {
			progressStep: 1,
			originalParams: {
				instituteName: '',
				groupName: '',
				storagesName: [],
			},
			createParams: {
				instituteName: '',
				groupName: '',
				storagesName: [],
			},
			headerText: '랩매니저 PRO 시작하기',
			placeHolder: '예: 스마트잭',
			notiMsg: '기관명을 입력해주세요.',
			instituteId: '',
			groupId: '',
			groupLocationId: '',
			isModify: false,
			inviteLink: '',
			isLoading: false,
		}
	},
	computed: {
		nextBtn() {
			if (this.progressStep == 3) {
				return '랩매니저 시작하기'
			} else {
				return '다음'
			}
		},
	},
	methods: {
		async next() {
			switch (this.progressStep) {
				case 1:
					this.createParams.instituteName = this.createParams.instituteName.trim()
					this.notiMsg = '기관명을 입력해주세요.'
					if (this.createParams.instituteName == '') {
						this.$refs.step1.isValid = false
						return false
					}
					break
				case 2:
					this.createParams.groupName = this.createParams.groupName.trim()
					this.notiMsg = '그룹명을 입력해주세요.'
					if (this.createParams.groupName == '') {
						this.$refs.step2.isValid = false
						return false
					}
					break
				case 3:
					if (this.isLoading) return
					this.isLoading = true
					if (this.createParams.storagesName.length == 0) {
						this.$refs.step3.isValid = false
						this.createParams.storagesName.push('보관함')
					}
					if (this.isModify) {
						await this.updateCreateParams()
					} else {
						await this.createInst()
					}
					this.isLoading = false
					// 	break
					// case 4:
					this.$router.replace({
						name: 'Dashboard',
						params: {
							instituteId: this.instituteId,
							isJoin: true,
							joinModalTitle: '초기설정 완료!',
							// withInit: true,
						},
					})
			}
			this.progressStep += 1
		},
		prev() {
			if (this.progressStep == 3) {
				this.originalParams = JSON.parse(JSON.stringify(this.createParams))
				this.isModify = true
			}
			this.progressStep -= 1
		},
		changeInputValue(inputValue) {
			switch (this.progressStep) {
				case 1:
					this.createParams.instituteName = inputValue
					break
				case 2:
					this.createParams.groupName = inputValue
					break
			}
		},
		changeStorageList(storageList) {
			this.createParams.storagesName = storageList
		},
		async createInst() {
			const response = await instituteAPI.createInstitute(this.createParams)
			response?.data?.instituteId ? (this.instituteId = response.data.instituteId) : (this.instituteId = '')
			response?.data?.groupId ? (this.groupId = response.data.groupId) : (this.groupId = '')
			response?.data?.groupLocationId ? (this.groupLocationId = response.data.groupLocationId) : (this.groupLocationId = '')

			// await this.createInviteLink()
		},
		// async createInviteLink() {
		// 	const response = await instituteMemberAPI.createInviteLink(this.instituteId, {
		// 		groupsId: [this.groupId],
		// 	})
		// 	response?.data?.link ? (this.inviteLink = response.data.link) : (this.inviteLink = '')
		// },
		async updateCreateParams() {
			const formData = new FormData()
			const addStorages = this.getChangedStorages(this.createParams.storagesName, this.originalParams.storagesName)
			const deleteStorages = this.getChangedStorages(this.originalParams.storagesName, this.createParams.storagesName)

			let locationId = ''
			let deleteStorageIdList = []
			await instituteStorageAPI.lookupRootStorageList(this.instituteId).then(result => {
				result.data.forEach(storage => {
					if (deleteStorages.includes(storage.name)) {
						deleteStorageIdList.push(storage.id)
					}
					if (storage.locationId != this.groupLocationId) locationId = storage.locationId
				})
			})

			if (addStorages && addStorages.length > 0) {
				addStorages.forEach(storage => {
					instituteStorageAPI.createStorage(this.instituteId, {
						name: storage,
						assigneeGroupId: this.groupId,
						locationId: this.groupLocationId,
					})
				})
			}

			if (deleteStorages && deleteStorages.length > 0) {
				deleteStorageIdList.forEach(id => {
					instituteStorageAPI.deleteStroage(this.instituteId, id)
				})
			}

			if (this.originalParams.instituteName != this.createParams.instituteName) {
				formData.append('name', this.createParams.instituteName)
				await instituteInformationAPI.editInstInfo(this.instituteId, formData)
				await instituteLocationAPI.updateLocation(this.instituteId, {
					id: locationId,
					name: this.createParams.instituteName,
				})
			}
			if (this.originalParams.groupName != this.createParams.groupName) {
				await instituteGroupAPI.editGroup(this.groupId, {
					name: this.createParams.groupName,
				})
				await instituteLocationAPI.updateLocation(this.instituteId, {
					id: this.groupLocationId,
					name: this.createParams.groupName,
				})
			}
		},
		getChangedStorages(target, value) {
			const arr = []
			target.forEach(storage => {
				if (!value.includes(storage)) {
					arr.push(storage)
				}
			})
			return arr
		},
	},
}
</script>

<style lang="scss" scoped>
.createSmallInstitution {
	width: 100%;
	height: calc(100vh - 64px);
	margin-top: 64px;
	background-color: $LAB_WHITE;
	overflow-y: auto;
	.boxwrap {
		width: 100%;
		max-width: 900px;
		margin: 120px auto 0;
		text-align: left;
		.btn-wrap {
			width: 100%;
			display: flex;
			align-items: center;
			margin-top: 20px;
			margin-bottom: 60px;
			button {
				width: 200px;
				height: 48px;
				font-size: 1rem;
				font-weight: 700;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				transition: all 0.3s;
				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
				&.prev {
					border: 1px solid $INPUT;
					&:hover {
						background-color: rgba(0, 0, 0, 0.05);
					}
				}
				&.next {
					background-color: $LAB_YELLOW;
					margin-right: 10px;
					transition: all 0.3s;
					&:hover {
						background-color: #d8a937;
					}
				}
			}
		}
	}
}
</style>
