var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "detail-wrap"
  }, [_c('detail-header', {
    attrs: {
      "item": _vm.item
    },
    on: {
      "updateFavorite": _vm.updateFavorite
    }
  }), _c(_vm.activeBadge, {
    tag: "component",
    attrs: {
      "item": _vm.item,
      "legals": _vm.legals
    }
  }), _c('div', {
    staticClass: "register-info-wrap"
  }, [_c('div', {
    staticClass: "info-title"
  }, [_vm._v(_vm._s(_vm.tt('물품정보')))]), _vm.isCategoryChemical ? _c('properties-info', {
    attrs: {
      "item": _vm.item
    }
  }) : _vm._e(), _vm.hasMsds || !!_vm.item.ghs ? _c('safety-info', {
    attrs: {
      "msds": _vm.item.msds,
      "ghs": _vm.item.ghs
    },
    on: {
      "manageGHS": _vm.manageGHS,
      "uploadFile": function uploadFile($event) {
        return _vm.getDetail(_vm.$route.params.id);
      },
      "changeFile": function changeFile($event) {
        return _vm.getDetail(_vm.$route.params.id);
      },
      "createGHS": function createGHS($event) {
        return _vm.$refs['make-ghs'].show();
      }
    }
  }) : _vm._e(), _c('member-info', {
    attrs: {
      "item": _vm.item
    }
  }), _c('product-info', {
    attrs: {
      "item": _vm.item,
      "inventoryFieldsInfo": _vm.inventoryFields
    }
  }), _c('management-info', {
    attrs: {
      "item": _vm.item,
      "managementFields": _vm.managementFields
    }
  }), _vm.hasCustomFields ? _c('custom-info', {
    attrs: {
      "item": _vm.item,
      "customFields": _vm.customFields,
      "customFieldsData": _vm.customFieldsData
    }
  }) : _vm._e(), _c('ingredient-info', {
    attrs: {
      "ingredient": _vm.item && _vm.item.ingredientList
    }
  }), !_vm.isBasic ? _c('legal-info', {
    attrs: {
      "legals": _vm.legals,
      "ingredientList": _vm.item.ingredientList,
      "casno": _vm.item.casno
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "manage-info-wrap"
  }, [_c('div', {
    staticClass: "info-title"
  }, [_vm._v(" " + _vm._s(_vm.tt('물품관리 기록')) + " ")]), _c('div', {
    staticClass: "filter-wrap"
  }, [_c('div', {
    staticClass: "filter-wrap-header"
  }, [_c('div', {
    staticClass: "bold-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('상세검색')) + " ")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.tt('물품관리 기록에서 조회할 항목을 검색하거나 선택할 수 있습니다')) + " ")])]), _c('history-info', {
    attrs: {
      "item": _vm.item
    }
  })], 1)]), !_vm.item.isDel && !_vm.item.isDispose ? _c('detail-footer', {
    attrs: {
      "item": _vm.item,
      "isShowGHSButton": _vm.isCategoryChemical
    },
    on: {
      "makeGHS": _vm.makeGHS,
      "manageGHS": _vm.manageGHS
    }
  }) : _vm._e(), _c('modal-confirm', {
    ref: "modal-delete-ghs",
    attrs: {
      "variant": "warning",
      "title": "GHS 라벨 삭제",
      "content": "GHS 라벨을 삭제하면 브랜드, 제품번호가 동일한 모든 물품의 GHS라벨이 삭제됩니다. 삭제 하시겠습니까?",
      "confirmText": "삭제"
    },
    on: {
      "confirm": _vm.deleteGHS
    }
  }), _c('modal-make-ghs', {
    ref: "make-ghs",
    attrs: {
      "msds": _vm.item.msds
    },
    on: {
      "showGuide": function showGuide($event) {
        return _vm.$refs['guide'].show();
      },
      "goNext": function goNext($event) {
        _vm.$refs['make-ghs'].hide();

        _vm.$refs['ghs-form'].show();
      },
      "uploadMSDS": _vm.uploadMsdsFile
    }
  }), _c('modal-ghs-form', {
    ref: "ghs-form",
    on: {
      "goNext": _vm.showPreviewGHS,
      "goPrev": function goPrev($event) {
        _vm.$refs['make-ghs'].show('prev');

        _vm.$refs['ghs-form'].hide();
      }
    }
  }), _c('modal-ghs-preview', {
    ref: "ghs-preview",
    on: {
      "goPrev": function goPrev($event) {
        _vm.$refs['ghs-preview'].hide();

        _vm.$refs['ghs-form'].show();
      },
      "complete": _vm.completeCreateGHS
    }
  }), _c('modal-ghs-label', {
    ref: "ghs-label",
    attrs: {
      "item": _vm.item
    },
    on: {
      "edit": _vm.editGHS,
      "delete": _vm.showModalDeleteGHS
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }