import businessRegistrationAPI from '@/services/api/purchase/lms//businessRegistration'

const businessRegistration = {
	namespaced: true,
	state: {
		businessRegistrations: [],
	},
	mutations: {
		setBusinessRegistrations: (state, payload) => {
			state.businessRegistrations = payload
		},
	},
	actions: {
		lookupRegistration: async ({ commit, rootGetters }, lmsInstId) => {
			const response = await businessRegistrationAPI.lookupRegistration(rootGetters.instituteId, lmsInstId)
			commit('setBusinessRegistrations', response.data)
		},
		addRegistration: async ({ rootGetters }, { lmsInstId, formData }) => {
			return await businessRegistrationAPI.addRegistration(rootGetters.instituteId, lmsInstId, formData)
		},
		deleteRegistration: async ({ rootGetters }, { lmsInstId, id }) => {
			return await businessRegistrationAPI.deleteRegistration(rootGetters.instituteId, lmsInstId, id)
		},
	},
}

export default businessRegistration
