<template>
	<div class="search-row">
		<div class="label">{{ tt(title) }}</div>
		<div class="column-box">
			<label v-for="(item, index) in list" :key="index">
				<input class="checkbox" type="checkbox" :checked="isChecekdItem(item.id)" @change="checkedItem(item)" />
				<span v-b-tooltip.hover="item.name">{{ tt(item.name) }}</span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			checkedList: [],
		}
	},
	components: {},
	computed: {},
	watch: {
		checkedList() {
			this.$emit('change', { [this.type]: this.checkedList.filter(i => i.id !== 'ALL').map(i => i.id) })
		},
	},
	methods: {
		isChecekdItem(id) {
			if (id == 'ALL') return this.list.length == this.checkedList.length
			return this.checkedList.map(it => it.id).includes(id)
		},
		checkedItem(item) {
			if (item.id == 'ALL') this.checekdAll()
			else {
				if (this.checkedList.find(it => it.id == item.id)) {
					this.checkedList.remove(item.id, 'id')
				} else this.checkedList.push({ ...item })
			}
		},
		checekdAll() {
			if (this.list.length === this.checkedList.length) {
				this.checkedList.removeAll(this.list, 'id')
			} else {
				this.checkedList.removeAll(this.list, 'id')
				this.checkedList.push(...this.list.map(i => ({ ...i })))
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.tooltip {
	top: 35px !important;
	left: -20px !important;
}
.search-row {
	display: flex;
	width: 100%;
	height: 45px;
	overflow: auto;
	border-bottom: 1px solid $DIVIDER;
	& > * {
		display: inline-block;
		width: calc(100% - 200px);
	}
	&:last-of-type {
		border-bottom: none;
	}
	.label {
		position: sticky;
		top: 0px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		font-weight: 700;
		color: $SECONDARY_2;
		display: inline-block;
		padding: 16px 12px;
		background-color: $GREY_1;
		border-right: 1px solid $DIVIDER;
		width: 200px;
		flex-basis: 200px;
	}
	.column-box {
		width: calc(100% - 200px);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 12px 20px;
		gap: 16px;
		& > * {
			flex-basis: 14%;
			// &.search-box {
			// 	flex-basis: 300px;
			// 	width: 300px;
			// 	margin-left: 0px;
			// }
			// &.date-picker {
			// 	flex-basis: auto;
			// 	margin-left: 0px;
			// }
		}
		label {
			cursor: pointer;
			margin-bottom: 0px;
			span {
				display: inline-block;
				vertical-align: middle;
				width: 75%;
				margin-left: 10px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>
