var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Organization__info"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('정보')))]), !_vm.isInstitute && _vm.userPermission.group && _vm.userPermission.group.update ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn",
    on: {
      "click": _vm.openModalEditGroup
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-edit.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('편집')) + " ")]) : _vm._e()]), _c('ul', {
    staticClass: "Organization__info-list"
  }, [_c('li', {
    staticClass: "Organization__info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('그룹 유형')))]), _c('p', [_vm._v(_vm._s(_vm.groupType))])]), _vm.phone ? _c('li', {
    staticClass: "Organization__info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('연락처')))]), _c('p', [_vm._v(_vm._s(_vm.phone))])]) : _vm._e(), _c('li', {
    staticClass: "Organization__info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.groupInfo && _vm.groupInfo.type == 'INSTITUTE' ? _vm.tt('기관 멤버 수') : _vm.tt('멤버수')))]), _c('p', [_vm._v(_vm._s(_vm.memberCountText))])]), _vm._l(_vm.locations, function (location, idx) {
    return _vm.groupInfo.type != 'INSTITUTE' ? _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hasLocation,
        expression: "hasLocation"
      }],
      key: "org-info-".concat(location, "-").concat(idx),
      staticClass: "Organization__info-item"
    }, [_vm.hasLocationSingle ? _c('span', [_vm._v(_vm._s(_vm.tt('위치')))]) : _c('span', [_vm._v(_vm._s(_vm.tt('위치')) + _vm._s(idx + 1))]), _c('p', [_vm._v(_vm._s(location.name))])]) : _vm._e();
  }), _vm.isTypeLab && _vm.assignee.name ? _c('li', {
    staticClass: "Organization__info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('연구실안전책임자')))]), _c('p', [_vm._v(_vm._s(_vm.assignee.name))])]) : _vm._e(), _vm.isTypeLab && _vm.subAssignee.name ? _c('li', {
    staticClass: "Organization__info-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('연구실안전관리자')))]), _c('p', [_vm._v(_vm._s(_vm.subAssignee.name))])]) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }