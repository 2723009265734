<template>
	<b-modal ref="modal" centered hide-footer no-close-on-backdrop no-fade @close="close">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
			<button class="button-gray" @click="showGuide">
				<img src="@/views/common/components/modalGen2/svg/icon-question.svg" width="24" height="24" />
				{{ tt('GHS 라벨 생성 가이드') }}
			</button>
			<modal-ghs-guide ref="guide"></modal-ghs-guide>
		</template>
		<template>
			<div class="content">
				<div class="content-tab-wrap">
					<div v-if="msds && msds.master" class="tab-item" :class="{ active: tabType === 'master' }" @click="tabType = 'master'">
						{{ tt('제조사 MSDS') }}
					</div>
					<div v-if="msds && msds.institute" class="tab-item" :class="{ active: tabType === 'institute' }" @click="tabType = 'institute'">
						{{ tt('업로드된 MSDS') }}
					</div>
				</div>
				<div class="content-label-info-wrap">
					<div class="msds-wrap">
						<iframe v-if="!noMsds" class="pdf-viewer" :src="msdsUrl ? msdsUrl : uploadUrl" frameborder="0"></iframe>
						<div v-else class="no-msds">
							<input
								class="input-file"
								type="file"
								ref="input-file"
								@change="changeItem"
								accept="application/haansoftpdf, application/pdf"
								@drop="dropFile"
							/>
							<img src="@/views/common/components/modalGen2/svg/icon-upload-big.svg" width="67" height="80" />
							<div
								class="no-msds-text"
								v-html="
									tt(
										`Sigma-Aldrich의 MSDS를 업로드하시면,<br />물리적 위험성과 건강 유해성이 자동으로 선택됩니다.<br />MSDS를 드래그 앤 드랍해주세요(PDF)`
									)
								"
							></div>
							<div class="button-wrap">
								<button class="button-normal">
									<img src="@/views/common/components/modalGen2/svg/icon-file-upload.svg" width="24" height="24" />
									<span>{{ tt('파일첨부') }}</span>
								</button>
							</div>
						</div>
					</div>
					<div class="label-info-wrap">
						<div class="info-wrap">
							<div v-if="isShowWarning" class="warn-wrap">
								<img src="@/views/common/components/modalGen2/svg/icon-round-warn.svg" width="12" height="12" />
								<div
									class="warn"
									v-html="
										tt(
											`MSDS 분석이 불가능하여 GHS 라벨이 생성되지 않았습니다. <br />가이드를 참고하여 위험성 분류를 직접 선택해주세요.`
										)
									"
								></div>
							</div>
							<div class="info-title">
								{{ tt('위험성 분류') }}
							</div>
							<label-list ref="label-list"></label-list>
							<text-list ref="text-list"></text-list>
						</div>
						<div class="button-area">
							<button class="button-primary" @click="goNext">{{ tt('다음으로') }}</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { ToastType } from '@/utils/define/ToastType'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LabelList from '@/views/common/components/modalGen2/components/modalGhs/LabelList.vue'
import TextList from '@/views/common/components/modalGen2/components/modalGhs/TextList.vue'

import ModalGhsGuide from '@/views/common/components/modalGen2/ghs/ModalGHSGuide.vue'

export default {
	name: 'ModalMakeGhs',
	components: { LabelList, TextList, ModalGhsGuide },
	props: {
		msds: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			tabType: null, //master, institute
		}
	},
	computed: {
		...mapGetters('modal/ghs', [
			'selectedItem',
			'activeParsedPrecautionList',
			'activeParsedGhsList',
			'uploadFile',
			'uploadUrl',
			'selectedPrecautionCode',
			'mode',
		]),
		title() {
			return this.mode === 'EDIT' ? 'GHS 라벨 편집' : 'GHS 라벨 생성'
		},
		msdsUrl() {
			return this.msds?.[this.tabType]?.url
		},
		noMsds() {
			return !(this.msds?.institute || this.msds?.kosha || this.msds?.master) && !this.uploadUrl
		},
		isShowWarning() {
			return this.noMsds || !this.parseMsds
		},
		isValidGHS() {
			const hasSelectedItem =
				this.selectedItem &&
				Object.keys(this.selectedItem)
					?.map(key => this.selectedItem[key].length > 0)
					.some(value => value) &&
				this.selectedPrecautionCode &&
				this.selectedPrecautionCode.length > 0
			return hasSelectedItem
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('modal/ghs', ['setSelectedItem', 'setParsedMsds', 'setUploadFile', 'setUploadUrl']),
		...mapActions('modal/ghs', ['parseMsds', 'setInit', 'cancelParseMsds']),
		show(value) {
			this.$refs['modal'].show()
			this.setTab()
			if (value === 'prev') {
				this.$nextTick(() => {
					this.setData()
				})
			} else {
				switch (this.mode) {
					case 'ADD':
						this.setInit()
						this.loadParserData()
						break
					case 'EDIT':
						this.$nextTick(() => {
							this.setData()
						})
						break
				}
			}
		},
		hide(value) {
			this.$refs['modal'].hide()
		},
		close() {
			this.cancelParseMsds()
			this.$emit('close')
		},
		setTab() {
			if (this.msds?.master) {
				this.tabType = 'master'
			} else if (this.msds?.institute) {
				this.tabType = 'institute'
			} else {
				this.tabType = null
			}
		},
		async loadParserData() {
			if (this.msdsUrl || this.uploadFile) {
				try {
					const form = new FormData()
					if (this.msdsUrl) {
						let fileUrl = this.msdsUrl?.replace('https://msds.lab-manager.com/v/u?url=', '')
						form.append('url', decodeURIComponent(fileUrl))
					}
					if (this.uploadFile) {
						form.append('file', this.uploadFile)
					}
					const data = await this.parseMsds(form)
					if (data) {
						let hasParsingData = data['항2']['유해/위험성']?.length > 0 || data['항2']['유해/위험문구']?.length > 0
						if (hasParsingData) {
							this.setSelectedItem({
								physical: this.activeParsedGhsList.filter(item => item.code.indexOf('P') > -1),
								health: this.activeParsedGhsList.filter(item => item.code.indexOf('H') > -1),
								environment: this.activeParsedGhsList.filter(item => item.code.indexOf('E') > -1),
							})
							this.$refs['text-list'].setData(this.activeParsedPrecautionList)
							this.$root.toast(
								'MSDS 분석 완료',
								'MSDS 분석을 통해, 물리적 위험성과<br/>건강유해성을 자동으로 선택하였습니다',
								ToastType.SUCCESS
							)
						} else {
							this.$root.toast(
								'MSDS 분석 실패',
								'MSDS 분석에 실패하였습니다.<br/>물리적 위험성과 건강유해성을 선택해주세요.',
								ToastType.ERROR
							)
						}
					}
				} catch (err) {
					console.error(err)
				}
			}
		},
		setData() {
			this.$refs['text-list'].setData(this.selectedPrecautionCode)
			this.$refs['label-list'].setData()
		},
		async changeItem() {
			event.preventDefault()
			if (this.$refs['input-file'].files[0]) {
				let fReader = new FileReader()
				fReader.onload = e => {
					this.setUploadFile(this.$refs['input-file'].files[0])
					this.setUploadUrl(e.target.result)
					this.loadParserData()
					this.$emit('uploadMSDS', this.$refs['input-file'].files[0])
				}
				fReader.readAsDataURL(this.$refs['input-file'].files[0])
			}
		},
		async dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				let fReader = new FileReader()
				fReader.onload = e => {
					this.setUploadFile(event.dataTransfer.files[0])
					this.setUploadUrl(e.target.result)
					this.loadParserData()
					this.$emit('uploadMSDS', event.dataTransfer.files[0])
				}
				fReader.readAsDataURL(event.dataTransfer.files[0])
			}
		},
		goNext() {
			if (this.isValidGHS) {
				this.$emit('goNext')
			} else {
				this.$root.toast('MSDS 분석 실패', '가이드를 참고하여 위험성 분류 입력을 완료해주세요.', ToastType.ERROR)
			}
		},
		showGuide() {
			this.$refs['guide'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	padding: 20px;
	border: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	.title {
		font-size: 18px;
		font-weight: 700;
	}
	.button-gray {
		position: absolute;
		top: 20px;
		right: 65px;
		background: $GREY_4;
		display: flex;
		border-radius: 4px;
		padding: 5px 10px;
		height: 30px;
		font-size: 14px;
		font-weight: 700;
		&:hover {
			background: $HOVER_BUTTON_COMMON;
		}
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 1000px;
	height: 840px;
	.modal-content {
		background-color: $LAB_WHITE;
		border-radius: 8px;
		border: 0;
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	padding: 0px;
	gap: 20px;
	height: 700px;
	overflow: hidden;
	background: white;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	.content {
		height: 100%;
		.content-tab-wrap {
			display: flex;
			background: white;
			padding: 0px 20px;
			height: 40px;
			gap: 20px;
			.tab-item {
				padding-bottom: 13px;
				font-size: 14px;
				font-weight: 400;
				cursor: pointer;
				color: $PLACEHOLDER;
				&.active {
					color: $DEFAULT;
					font-weight: 700;
					border-bottom: 2px solid $LAB_YELLOW;
				}
			}
		}
		.content-label-info-wrap {
			display: flex;
			height: calc(100% - 40px);
			border-top: 1px solid $SYSTEM_BACKGROUND;
			.msds-wrap {
				width: 600px;
				height: 100%;
				background: $SYSTEM_BACKGROUND;
				.pdf-viewer {
					width: 100%;
					height: 100%;
				}
				.no-msds {
					display: flex;
					gap: 20px;
					height: 100%;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					.input-file {
						width: 545px;
						height: 660px;
						top: 40px;
					}
					.no-msds-text {
						color: $PLACEHOLDER;
						font-size: 18px;
						font-weight: 700;
					}
				}
			}
			.label-info-wrap {
				display: flex;
				flex-direction: column;
				gap: 20px;
				width: 400px;
				height: 100%;
				overflow-y: auto;
				.info-wrap {
					display: flex;
					flex-direction: column;
					gap: 20px;
					padding: 20px;
					.warn-wrap {
						display: flex;
						background: #fff5f5;
						border-radius: 8px;
						padding: 10px;
						img {
							margin-top: 4px;
							margin-right: 6px;
						}
						.warn {
							font-size: 12px;
							color: #ff0000;
							font-weight: 400;
						}
					}
					.info-title {
						font-size: 16px;
						font-weight: 700;
					}
				}
				.button-area {
					display: flex;
					justify-content: flex-end;
					position: sticky;
					bottom: 0;
					border-top: 1px solid $DIVIDER;
					height: 70px;
					padding: 20px;
					background: white;
					.button-primary {
						justify-content: center;
						min-width: 100px;
						background: $LAB_YELLOW;
						display: flex;
						border-radius: 4px;
						padding: 5px 10px;
						height: 30px;
						font-size: 14px;
						font-weight: 700;
						&:hover {
							background: $HOVER_BUTTON_PRIMARY;
						}
					}
				}
			}
		}
	}
}

.button-wrap {
	margin-top: 10px;
	.button-normal {
		background: $GREY_4;
		display: flex;
		border-radius: 4px;
		padding: 5px 10px;
		height: 30px;
		font-size: 14px;
		font-weight: 700;
		border: 1px solid #000000;
		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $GREY_4, $GREY_4);
		}
	}
}
input[type='file'] {
	width: 580px;
	height: 700px;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	padding: 0 0;
	cursor: pointer;
	&.isFile {
		visibility: hidden;
	}
}
</style>
