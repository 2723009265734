<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalEditStorage Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title" v-text="mode == 'ADD' ? tt('저장소 추가') : tt('저장소 편집')"></h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field">
						<p>{{ tt('저장소 이름') }}<span class="required">*</span></p>
						<input type="text" :placeholder="tt('저장소 이름을 입력해주세요')" v-model="storageName" />
					</div>
					<div class="Popup__input-field deleteable-input-field Dropdown-input mt-20">
						<p>{{ tt('위치') }}<span class="required">*</span></p>
						<div v-for="(item, index) in locationList" :key="`location_` + index">
							<input
								type="text"
								:placeholder="tt('위치를 선택해주세요')"
								readonly
								:value="setLocationName(item.locationName)"
								@click="setLocation"
							/>
							<button class="Btn-delete-item btn" @click="removeLocationList(index)" :disabled="locationList.length < 2">
								<img src="@/assets/svg/recycle-bin.svg" />
							</button>
						</div>

						<button class="AppContainer__add-item-btn" @click="addLocation">+{{ tt('추가하기') }}</button>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button
							class="Btn__complete"
							@click="submit"
							:disabled="
								(locationList.length > 0 && locationList[0].locationId.length == 0) || !storageName || !storageName.replace(/\s/g, '')
							"
						>
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
			<modal-select-treeview
				ref="modal-select-treeview"
				title="위치 지정"
				mode="STORAGE"
				:treeData="treeData"
				:selectedList="locationIdList"
				@submit="addStorage"
				:checkChild="true"
			></modal-select-treeview>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import ModalSelectTreeview from '@/views/common/components/modals/ModalSelectTreeview.vue'
import locationAPI from '@/services/api/institute/location'
import storageAPI from '@/services/api/institute/storage'

export default {
	name: 'ModalEditStorage',
	components: { ModalSelectTreeview },
	mixins: [MixinModal],
	data() {
		return {
			mode: '',
			treeData: [],
			storageId: null,
			storageName: null,
			locationList: [
				{
					locationId: '',
					locationName: '',
				},
			],
			locationIdList: [],
			warningSigns: [],
		}
	},
	methods: {
		show(mode, item) {
			this.isShow = true
			this.mode = mode
			this.locationIdList = []

			if (this.mode == 'ADD') {
				this.storageName = null
				this.locationList = [
					{
						locationId: '',
						locationName: '',
					},
				]
			} else {
				this.locationList = []
				if (item) {
					this.storageName = item.name
					this.storageId = item.id
					item.locations.forEach(x => {
						this.locationList.push({
							locationId: x.id,
							locationName: x.pathName,
						})
						this.locationIdList.push(x.id)
					})
				}
			}

			this.loadLocationList()
		},
		async loadLocationList() {
			let response = await locationAPI.lookupLocationTree(this.$route.params.instituteId)

			this.treeData = response.data
		},
		async loadWarningSigns() {
			const response = await storageAPI.lookupWarningSigns()

			this.warningSigns = response.data
		},
		close() {
			this.hide()
		},
		submit() {
			const payload = {
				name: this.storageName,
				locationsId: this.locationIdList,
			}

			this.$emit('submit', payload, this.mode, this.storageId)
		},
		addLocation() {
			this.locationList.push({
				locationId: '',
				locationName: '',
			})
		},
		removeLocationList(idx) {
			this.locationList.splice(idx, 1)
			this.locationIdList.splice(idx, 1)
		},
		setLocation() {
			this.$refs['modal-select-treeview'].show()
		},
		setLocationName(name) {
			return name
		},
		addStorage(list) {
			this.locationList = []
			this.locationIdList = []

			this.$refs['modal-select-treeview'].hide()
			list.forEach(x => {
				this.locationList.push({
					locationId: x.id,
					locationName: x.pathName,
				})
				this.locationIdList.push(x.id)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalEditStorage {
	.Popup__content {
		max-height: 70vh;
		overflow-y: auto;
	}
}
</style>
