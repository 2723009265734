<template>
	<b-modal ref="msds-file-viewer" centered hide-footer no-close-on-backdrop no-fade @close="hide">
		<template #modal-title>
			<div class="title">MSDS</div>
		</template>
		<template>
			<div class="tab-menu">
				<button class="tab-menu-item" :class="{ selected: isSelected('master') }" @click="setTab('master')" v-if="msds && msds.master">
					{{ tt('제조사 MSDS') }}
				</button>
				<button class="tab-menu-item" :class="{ selected: isSelected('institute') }" @click="setTab('institute')">
					{{ tt('업로드된 MSDS') }}
				</button>
			</div>
			<div class="button-wrap">
				<button class="button-gray" v-if="!ghs" @click="$emit('createGHS')">
					<img class="image-icon" src="@/views/common/components/modalGen2/svg/icon-round-plus.svg" />
					<p>{{ tt('GHS 라벨 생성') }}</p>
				</button>
				<button class="button-gray" v-else @click="$emit('previewGHS')">
					<img class="image-icon" src="@/views/purchase/cargo/svg/icon-ghs.svg" />
					<p>{{ tt('GHS 라벨 보기') }}</p>
				</button>

				<button class="button-gray" v-if="msds && !msds['institute']" @click="upload">
					<img class="image-icon" src="@/views/common/components/modalGen2/svg/icon-file-upload.svg" />
					<p>{{ tt('업로드') }}</p>
				</button>

				<button class="button-gray" v-if="msds && msds['institute']" @click="$refs['modal-delete'].show()">
					<img class="image-icon" src="@/views/common/components/modalGen2/svg/icon-round-delete.svg" />
					<p>{{ tt('삭제') }}</p>
				</button>

				<button class="button-gray" v-if="msds && msds['institute']" @click="$refs['modal-change-file'].show()">
					<img class="image-icon" src="@/views/common/components/modalGen2/svg/icon-file-change.svg" />
					<p>{{ tt('파일변경') }}</p>
				</button>
				<input ref="input-file" type="file" accept=".pdf" hidden @change="$emit(msds['institute'] ? 'changeFile' : 'uploadFile', $event)" />
			</div>
			<div class="content">
				<iframe
					class="pdf-viewer"
					id="myframe"
					v-if="type && msds[type] && msds[type].url != null"
					:src="msds[type].url"
					frameborder="0"
				></iframe>
				<div v-else class="upload-wrap">
					<input
						class="dropFile"
						ref="drop-file"
						type="file"
						accept=".pdf"
						@change="$emit(msds['institute'] ? 'changeFile' : 'uploadFile', $event)"
						@drop="dropFile"
					/>
					<img src="@/assets/svg/icon-upload.svg" alt="icon" width="67" height="87" />
					<div class="upload-text" v-html="tt('업로드할 MSDS를<br>드래그 앤 드랍해주세요(PDF)')"></div>
					<button class="lmbutton background-gray" @click="upload">
						<img src="@/assets/svg/icon-upload-file.svg" alt="icon" />
						{{ tt('업로드') }}
					</button>
				</div>
			</div>
		</template>
		<modal-confirm
			ref="modal-change-file"
			title="업로드된 MSDS 변경"
			content="정말로 업로드된 MSDS의 파일을 대체하시겠습니까?<br/>기존 파일은 사라집니다"
			confirmText="파일변경"
			@confirm="upload"
		></modal-confirm>
		<modal-confirm
			ref="modal-delete"
			variant="warning"
			title="업로드된 MSDS 삭제"
			content="정말로 업로드된 MSDS를 삭제하시겠습니까?<br/>이 동작은 되돌릴 수 없습니다"
			confirmText="삭제"
			@confirm="onDelete"
		></modal-confirm>
	</b-modal>
</template>

<script>
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
import { mapMutations } from 'vuex'
export default {
	name: 'MSDSMultiFileViewer',
	components: {
		ModalCommon,
	},
	props: {
		msds: {
			type: Object,
			default: null,
		},
		ghs: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			type: null,
		}
	},
	computed: {
		isSelected() {
			return type => type === this.type
		},
	},

	methods: {
		...mapMutations('msds', ['setShowModal']),
		show(type) {
			this.$refs['msds-file-viewer'].show()
			this.type = type
		},
		hide() {
			this.setShowModal(false)
			this.$emit('loadList')
			this.$refs['msds-file-viewer'].hide()
		},
		setTab(type) {
			this.type = type
		},
		upload() {
			this.$refs['input-file'].value = null
			this.$refs['input-file'].click()
			this.$emit('uploadFile')
		},
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.$refs['drop-file'].files = event.dataTransfer.files
				this.$emit(this.msds['institute'] ? 'changeFile' : 'uploadFile', event)
			}
		},
		onDelete() {
			this.$emit('delete')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/viewer/_msds-multi-file-viewer.scss';
</style>
