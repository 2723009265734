<template>
	<div class="total-price-wrapper">
		<div>
			<b>{{ tt('지출 예정금액 합계:') }}</b>
			<div class="label">{{ tt('정상 판매가') }}</div>
			<div class="value">{{ tt('${1}원', formatNumberLocale(total)) }}</div>
			<img :class="{ red: sale !== 0 }" src="@/assets/svg/table-minus.svg" />
			<div :class="{ red: sale !== 0 }" class="label">{{ tt('할인금액') }}</div>
			<div :class="{ red: sale !== 0 }" class="value">{{ tt('${1}원', formatNumberLocale(sale ?? 0)) }}</div>
			<img src="@/assets/svg/table-plus.svg" />
			<div class="label">{{ tt('배송비') }}</div>
			<div class="value">{{ deliveryFee ? tt('${1}원', formatNumberLocale(deliveryFee)) : tt('무료') }}</div>
			<img src="@/assets/svg/table-plus.svg" />
			<div class="label">{{ tt('VAT(10%)') }}</div>
			<div class="value">{{ tt('${1}원', formatNumberLocale(vatPrice)) }}</div>
			<img src="@/assets/svg/table-equal.svg" />
			<div class="value lg">
				<b>{{ tt('${1}원', formatNumberLocale(totalExpenditurePrice)) }}</b>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		total: {
			type: [Number, String],
			default: 0,
		},
		deliveryFee: {
			type: [Number, String],
			default: 0,
		},
		sale: {
			type: [Number, String],
			default: 0,
		},
		vatPrice: {
			type: [Number, String],
			default: 0,
		},
	},
	computed: {
		totalExpenditurePrice() {
			let price = 0
			price = this.total + this.deliveryFee - this.sale + this.vatPrice
			return price
		},
	},
}
</script>
<style lang="scss" scoped>
.total-price-wrapper {
	display: flex;
	width: 100%;
	height: 74px;
	border-top: 1px solid $INPUT;
	background: $GREY_0;
	justify-content: center;
	align-items: center;
	line-height: 20px;
	img {
		&.red {
			filter: invert(57%) sepia(40%) saturate(7462%) hue-rotate(334deg) brightness(103%) contrast(93%);
		}
	}
	div {
		display: flex;
		gap: 10px;
		b {
			margin-right: 10px;
		}
		.label {
			color: $SECONDARY_2;
			&.red {
				color: $RED;
				font-weight: 700;
			}
		}
		.value {
			&.red {
				color: $RED;
				font-weight: 700;
			}
			&.lg {
				font-size: 1.25rem;
			}
		}
	}
}
</style>
