<template>
	<div class="detail-wrapper" v-if="group">
		<div class="common-header-wrapper">
			<image-button v-if="isFloding" class="btn-floding" :image="require('../components/svg/arrow-floding.svg')" @click="$emit('unflod')" />
			<h2>{{ group.name }}</h2>
		</div>
		<div class="budget-nav-wrapper">
			<div class="nav-item" :class="{ active: pageName == 'BudgetStatus' }" @click="pageName = 'BudgetStatus'">{{ tt('예산') }}</div>
			<div class="nav-item" :class="{ active: pageName == 'ExpenditureList' }" @click="pageName = 'ExpenditureList'">{{ tt('지출내역') }}</div>
		</div>
		<div class="content-wrapper common-detail-wrapper">
			<component ref="current-page" v-bind:is="pageName" :group="group" :year="selectedYear" />
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import BudgetStatus from '../components/status/BudgetStatus.vue'
import ExpenditureList from '../components/status/ExpenditureList.vue'
export default {
	components: {
		BudgetStatus,
		ExpenditureList,
	},
	props: {
		group: {
			type: Object,
		},
		isFloding: {
			type: Boolean,
			default: false,
		},
		year: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			pageName: 'BudgetStatus',
		}
	},
	watch: {
		group() {
			if (this.$refs['current-page']) this.$refs['current-page'].loadData()
		},
		year() {
			if (this.$refs['current-page']) this.$refs['current-page'].loadData()
		},
	},
	computed: {
		...mapGetters('budget/status', ['selectedYear']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';

.btn-floding {
	margin-right: 15px;
	width: 30px;
	transform: rotate(180deg);
}
.common-header-wrapper {
	// position: sticky;
	// top: 0;
	border-bottom: none;
}
.budget-nav-wrapper {
	display: flex;
	padding-left: 30px;
	border-bottom: 1px solid $DIVIDER;
	gap: 20px;
	.nav-item {
		cursor: pointer;
		color: $PLACEHOLDER;
		font-size: 16px;
		font-weight: 400;
		padding-bottom: 10px;
		&.active {
			color: black;
			font-weight: 700;
			border-bottom: 2px solid $LAB_YELLOW;
		}
		&:hover {
			color: $SECONDARY_2;
		}
	}
}
.detail-wrapper {
	height: 100%;
}
.content-wrapper {
	// overflow-y: auto;
	// height: calc(100% - 97px);
}
</style>
