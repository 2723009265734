var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tree-node-wrap",
    "class": {
      root: _vm.node.parentId == null
    }
  }, [_c('div', {
    staticClass: "tree-node"
  }, [_c('div', {
    staticClass: "info-wrap",
    "class": {
      checked: _vm.notChecked && _vm.selectedGroups.find(function (g) {
        return g.id == _vm.node.id;
      }),
      root: _vm.node.parentId == null
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.selectGroup(_vm.node);
      }
    }
  }, [_vm.isSubtree ? _c('div', [_vm.isTreeOpen ? _c('img', {
    staticClass: "tree-close",
    attrs: {
      "src": require("../svg/icon-node-close.svg"),
      "width": "16",
      "height": "24"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleTreeOpen.apply(null, arguments);
      }
    }
  }) : _c('img', {
    staticClass: "tree-open",
    attrs: {
      "src": require("../svg/icon-node-open.svg"),
      "width": "16",
      "height": "24"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleTreeOpen.apply(null, arguments);
      }
    }
  })]) : _vm._e(), _vm.notChecked && _vm.selectedGroups.find(function (g) {
    return g.id == _vm.node.id;
  }) ? _c('img', {
    staticClass: "tree-icon",
    attrs: {
      "src": _vm.imageSrc,
      "width": "20",
      "height": "20"
    }
  }) : _c('img', {
    staticClass: "tree-icon",
    staticStyle: {
      "filter": "brightness(0)"
    },
    attrs: {
      "src": _vm.imageSrc,
      "width": "20",
      "height": "20"
    }
  }), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.node.name,
      expression: "node.name",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "name",
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  })])]), !_vm.notChecked && _vm.isSelectSingle && (!_vm.isInclude || !_vm.node.members || _vm.node.members.findIndex(function (it) {
    return it.id == _vm.$root.user.id;
  }) >= 0) ? _c('div', {
    staticClass: "radio"
  }, [_c('input', {
    attrs: {
      "type": "radio"
    },
    domProps: {
      "checked": _vm.selectedGroups.find(function (g) {
        return g.id == _vm.node.id;
      })
    },
    on: {
      "change": function change($event) {
        return _vm.selectGroup(_vm.node);
      }
    }
  }), _c('span')]) : !_vm.notChecked && !_vm.isSelectSingle ? _c('div', {
    staticClass: "checkbox"
  }, [_c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.selectedGroups.find(function (g) {
        return g.id == _vm.node.id;
      })
    },
    on: {
      "change": function change($event) {
        return _vm.selectGroup(_vm.node);
      }
    }
  })]) : _vm._e(), _vm.isTreeOpen ? _c('div', {
    staticClass: "node-children"
  }, _vm._l(_vm.node.children, function (group) {
    return _c('tree-node', {
      key: group.id,
      ref: "child-node",
      refInFor: true,
      attrs: {
        "isInclude": _vm.isInclude,
        "isSelectSingle": _vm.isSelectSingle,
        "notChecked": _vm.notChecked,
        "node": group
      }
    });
  }), 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }