<template>
	<div class="header-wrapper">
		<h2 class="header-title">{{ tt('입고관리') }}</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<div class="search-box">
					<search-box
						:value="keyword"
						:hasQr="false"
						:disabled="false"
						:readonly="false"
						:placeholder="tt('검색')"
						@input="changeKeyword"
					></search-box>
				</div>
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
					:image="require('@/assets/svg/filter.svg')"
					@click="filterBtnClick"
				></image-button>
				<date-picker-button
					:image="require('@/assets/svg/list/inventory-calendar.svg')"
					:activeImage="require('@/assets/svg/list/active/calendar-active.svg')"
					:text="'목록 추가일'"
					:subText="dateButtonSubtext"
					:defaultTime="calendarDatetime"
					:isActive="dateButtonSubtext != null"
					@pickDate="pickDate"
					@changeDate="changeDate"
				></date-picker-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button :text="tt('보기옵션')" :image="require('@/assets/svg/ViewOption(Button).svg')" @click="watchBtnClick"></image-button>
				<excel-download-button @downloadAll="exportList('ALL')" @downloadFiltered="exportList('FILTERED')" />
				<image-button
					:image="require('@/assets/svg/icon/black-round-plus.svg')"
					:text="tt('항목 추가')"
					@click="registBtnClick"
				></image-button>
			</div>
		</div>
		<div class="status-btn-wrapper">
			<list-filter-button
				v-for="status in filterButton"
				:isActive="status.id === selectedStatus"
				:key="status.id"
				:text="status.name"
				@click="changeActiveStatus(status.id)"
			></list-filter-button>
		</div>
		<modal-filter ref="modal-filter" @changeLoadParams="changeLoadParams" />
		<modal-view-option ref="modal-view-option" />
	</div>
</template>
<script>
import SearchBox from '@/views/components/searchBox'
import ImageButton from '@/views/common/components/baseButton/ImageButton.vue'
import DatePickerButton from '@/views/common/components/baseButton/DatePickerButton.vue'
import ListFilterButton from '@/views/common/components/buttons/ListFilterButton.vue'
import ModalViewOption from './modal/ModalViewOption.vue'
import ModalFilter from './modal/ModalFilter.vue'
import ExcelDownloadButton from '@/views/common/components/baseButton/ExcelDownloadButton.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
	name: 'CargoListHeader',
	props: {
		calendarDatetime: {
			type: Array,
			default: [],
		},
		dateButtonSubtext: {
			type: String,
			default: '',
		},
	},
	components: { SearchBox, ImageButton, DatePickerButton, ListFilterButton, ModalViewOption, ModalFilter, ExcelDownloadButton },
	computed: {
		...mapGetters('cargo/list', ['keyword', 'selectedStatus', 'filteredCount']),
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
		filterButton() {
			return [
				{ id: 'ALL', name: this.tt('전체') },
				{ id: 'WAIT', name: this.tt('입고 대기') },
				{ id: 'COMPLETE', name: this.tt('입고 완료') },
			]
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('cargo/list', ['setLoadParams', 'exportExcel']),
		...mapMutations('cargo/form', ['setInitCargoInfo']),
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		watchBtnClick() {
			this.$refs['modal-view-option'].show()
		},
		registBtnClick() {
			this.setInitCargoInfo()
			this.$emit('showAddCargoModal')
		},
		pickDate() {
			this.$root.toast(this.tt('기간 필터 적용 완료'), this.tt('기간 필터가 적용되었습니다.'), 'success')
		},
		changeDate(date) {
			this.$emit('changeDate', date)
		},
		changeActiveStatus(id) {
			const status = id === 'ALL' ? [] : [id]
			this.$cookies.set('filter_cargo_status', JSON.stringify(status))
			this.changeLoadParams({ status: status })
		},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword })
		},
		changeLoadParams(params) {
			this.$emit('changeLoadParams', params)
		},
		async exportList(type) {
			const response = await this.exportExcel(type)
			window.fileDownload(
				response,
				`${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('${1}_입고목록', type === 'ALL' ? '전체' : '필터적용')}.xlsx`
			)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/list/_list-header.scss';
</style>
