var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-header"
  }, [_c('th', {
    staticClass: "header-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allCheck,
      expression: "allCheck"
    }],
    staticClass: "checkbox",
    "class": {
      someChecked: !_vm.allCheck && !_vm.isEmptySelectedItems
    },
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.allCheck) ? _vm._i(_vm.allCheck, null) > -1 : _vm.allCheck
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.allCheck,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.allCheck = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.allCheck = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.allCheck = $$c;
        }
      }
    }
  })]), _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('입력자')))])]), _c('th', {
    "class": _vm.orderClass('TYPE'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('TYPE');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('내용')))])]), _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('상세')))])]), _vm.isView('categoryClassification') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('카테고리 분류')))])]) : _vm._e(), _vm.isView('categoryType') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('카테고리 상세 분류')))])]) : _vm._e(), _vm.isView('category') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('카테고리')))])]) : _vm._e(), _vm.isView('mngCode') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('관리번호')))])]) : _vm._e(), _vm.isView('name') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('물품정보')))])]) : _vm._e(), _vm.isView('brand') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('브랜드')))])]) : _vm._e(), _vm.isView('productCode') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('제품번호')))])]) : _vm._e(), _vm.isView('createDatetime') ? _c('th', {
    "class": _vm.orderClass('CREATE_DATETIME'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('CREATE_DATETIME');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('시간')))])]) : _vm._e(), _c('th', [_c('span', {
    staticStyle: {
      "min-width": "80px"
    }
  }, [_vm._v(_vm._s(_vm.tt('기록 삭제')))])]), _c('th', [_c('span', {
    staticStyle: {
      "min-width": "80px"
    }
  }, [_vm._v(_vm._s(_vm.tt('물품 복구')))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }