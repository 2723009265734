var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "Modal ModalNoticeLocation"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Modal__container"
  }, [_c('div', {
    staticClass: "Modal__content"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('보관위치 생성하기')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('기관 내 <b>그룹(부서/연구실/팀)</b>을 트리구조로 등록할 수 있습니다.<br>등록한 위치에 <b>보관함</b>을 생성하고, 이 위치를 사용하는 <b>연구실/부서/팀</b>을 지정할 수 있습니다.'))
    }
  }), _vm._m(0), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('시작하기')) + " ")])])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/create-2.png")
    }
  })]);
}]

export { render, staticRenderFns }