var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isValid
    }
  }, [_vm._v(" " + _vm._s(_vm.isValid ? _vm.tt('입력완료') : _vm.tt('필수값을 입력해주세요')) + " ")])]), _c('div', {
    staticClass: "form-data-wrap"
  }, _vm._l(_vm.sellerFields, function (field) {
    return _c('form-text-single-line', {
      key: field.key,
      ref: "form-seller",
      refInFor: true,
      attrs: {
        "name": _vm.formName(field.key),
        "value": _vm.formValue(field.key),
        "placeholder": _vm.formMessage(field),
        "errorMessage": _vm.formMessage(field),
        "isShowError": _vm.isShowError,
        "required": field.isRequire
      },
      on: {
        "input": function input(value) {
          return _vm.inputFormValue(value, field.key);
        }
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }