<template>
	<b-modal centered scrollable @close="e => cancel(e)" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<item-form-counter v-if="itemFormType !== 'NORMAL_EDIT'" :min="1" :value="multiCreateCount"></item-form-counter>
			<item-form-product></item-form-product>
			<item-form-ingredient-list v-if="isUsedIngredient" ref="item-form-ingredient-list"></item-form-ingredient-list>
			<item-form-msds v-if="isUsedIngredient" ref="form-msds"></item-form-msds>
		</template>
		<template #modal-footer>
			<modal-footer completeText="다음" cancelText="취소" @cancel="cancel" @complete="complete"></modal-footer>
		</template>
		<modal-confirm
			ref="modal-confirm"
			title="편집 취소"
			content="취소하면 편집된 정보가 적용되지 않습니다. 편집을 취소하시겠습니까?"
			confirmText="편집 취소"
			@confirm="hide()"
		></modal-confirm>
	</b-modal>
</template>
<script>
import ModalFooter from '@/views/common/components/modals/ModalFooter.vue'
import ItemFormCounter from '@/views/inventoryGen2/components/regist/form/ItemFormCounter.vue'
import ItemFormProduct from '@/views/inventoryGen2/components/regist/form/ItemFormProduct.vue'
import ItemFormIngredientList from '@/views/inventoryGen2/components/regist/form/ItemFormIngredientList.vue'
import ItemFormMsds from '@/views/inventoryGen2/components/regist/form/ItemFormMsds.vue'
import { mapGetters, mapMutations } from 'vuex'
import { LargeClass, MiddleClass } from '@/utils/define/ItemCode'

export default {
	name: 'ModalItemProduct',
	components: { ModalFooter, ItemFormCounter, ItemFormProduct, ItemFormIngredientList, ItemFormMsds },
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/form', ['category', 'isProductValid', 'isUnitValid', 'isIngredientValid', 'itemFormType', 'item']),
		isUsedIngredient() {
			return [LargeClass.CHEMICAL].includes(this.category?.classification)
		},
		isUsedMsds() {
			return this.isUsedIngredient && ![MiddleClass.GAS].includes(this.category?.type)
		},
		multiCreateCount() {
			return this.item?.multiCreateCount ?? 1
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/form', ['setShowError', 'initItem', 'setGhs', 'setFormItem']),
		show(isPrev) {
			this.$refs['modal'].show()
			// this.$nextTick(() => {
			// 	if (this.item?.msds?.institute) {
			// 		this.$refs['form-msds'].setData({ name: '업로드 된 MSDS.pdf' })
			// 	}
			// })
			if (!isPrev) {
				if (this.itemFormType === 'NORMAL_EDIT') {
					if (this.item?.ghs) {
						this.setGhs(this.item.ghs)
					}
					this.setFormItem({ ...this.item, currentVolumeUnit: this.item?.volumeUnit, multiCreateCount: 1 })
				} else {
					if (this.itemFormType !== 'EXCEL_EDIT') this.setFormItem({ ...this.item, multiCreateCount: 1 })
				}
			}
		},
		hide() {
			if (this.itemFormType === 'NORMAL_EDIT') {
				this.setGhs(null)
			}
			this.$refs['modal'].hide()
		},
		cancel(e) {
			if (this.itemFormType === 'NORMAL_EDIT' || this.itemFormType === 'EXCEL_EDIT') {
				this.$refs['modal-confirm'].show()
				e?.preventDefault()
				return
			}
			this.$emit('cancel')
			this.initItem()
			this.hide()
		},
		complete() {
			const isValid = this.isProductValid && this.isUnitValid && this.isIngredientValid
			const hasMultiCreateCount = this.item?.multiCreateCount > 0 && this.item?.multiCreateCount <= 500
			const isEditMode = this.itemFormType === 'NORMAL_EDIT'
			const checkTotalValid = isEditMode ? isValid : isValid && hasMultiCreateCount
			this.setShowError(!checkTotalValid)
			if (this.isUsedIngredient) this.$refs['item-form-ingredient-list'].showRequire()

			if (checkTotalValid) {
				this.$emit('complete')
				this.hide()
			} else {
				this.scrollToError()
			}
		},
		scrollToError() {
			setTimeout(() => {
				const errorEl = document.getElementsByClassName('error')?.[0]
				const scrollBody = document.getElementsByClassName('modal-body')?.[0]
				const offset = errorEl?.offsetTop
				scrollBody?.scrollTo(0, offset - 120)
			}, 200)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-form-item.scss';
</style>
