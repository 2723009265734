const state = {
	scrollHeight: 0,
}

const getters = {}

const mutations = {
	setScrollHeight: (state, height) => {
		state.scrollHeight = height
	},
	clearScrollHeight: state => {
		state.scrollHeight = 0
	},
}

const actions = {}
export default { namespaced: true, state, getters, mutations, actions }
