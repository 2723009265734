<template>
	<div class="divide-counter-wrap">
		<div class="divide-counter-header">
			<div class="text" v-html="tt('<b>분리할 수량</b>을 입력해주세요.')"></div>
			<img class="close" @click.stop="$emit('close')" src="@/assets/svg/popup-close.svg" width="14" height="14" />
		</div>

		<input-button-counter :max="max" :min="min" v-model="divideCount"></input-button-counter>
		<button class="confirm-button" @click="$emit('divide', divideCount)">
			{{ tt('분리하기') }}
		</button>
	</div>
</template>
<script>
import InputButtonCounter from '@/views/purchase/cargo/components/cargoList/counter/InputButtonCounter.vue'
export default {
	components: { InputButtonCounter },
	props: {
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: Number.MAX_SAFE_INTEGER,
		},
		value: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			divideCount: 1,
		}
	},
	computed: {},
	watch: {
		value(value) {
			this.divideCount = value
		},
	},
	created() {},
	mounted() {
		if (this.divideCount !== this.value) this.divideCount = this.value
	},
	methods: {
		changeValue(changeValue) {
			// console.warn('change counte value', changeValue)
		},
	},
}
</script>

<style lang="scss" scoped>
.divide-counter-wrap {
	display: flex;
	flex-direction: column;
	padding: 12px 8px;
	color: $DEFAULT;
	gap: 10px;
	.divide-counter-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.text {
			font-weight: 400;
		}
		.close {
			cursor: pointer;
		}
	}
	.confirm-button {
		padding: 10px 80px;
		border-radius: 5px;
		background-color: $LAB_YELLOW;
		font-weight: 700;
		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
		}
	}
}
</style>
