import ghsAPI from '@/services/api/ghs'
import { DateTime } from 'luxon'

const state = {
	selectedItem: [],
	previewData: null,
	type: null,
}
const getters = {
	selectedItem: state => state.selectedItem,
	isSelectedAll: state => state.selectedItem?.length === state.previewData?.length,
	isEmptySelectedItem: state => !state.selectedItem || state.selectedItem?.length === 0,
	allItemIds: state => state?.previewData?.map(item => item.id),
	previewData: state => state.previewData,
	selectedCount: state => state.selectedItem?.length,
	getItem: state => idx => state.previewData.filter(item => item.id === idx)?.[0],
	qrStickerData: state => state.previewData?.[0]?.qrSticker?.[0],
}
const mutations = {
	setSelectedItem: (state, selectedItem) => {
		state.selectedItem = selectedItem
	},
	setPreviewData: (state, previewData) => {
		state.previewData = previewData
	},
	setType: (state, type) => {
		state.type = type
	},
}

const actions = {
	setInit: ({ commit }) => {
		commit('setSelectedItem', [])
		commit('setPreviewData', null)
	},
	getGhsPreview: async ({ state, commit, rootGetters }, ids) => {
		const response = await ghsAPI.getGhsPreview(rootGetters.instituteId, { [`${state.type}Ids`]: ids })
		commit('setPreviewData', response.data)
	},
	exportPdf: async ({ state, getters, rootGetters }, fileName) => {
		if (state.selectedItem.length > 0) {
			const response = await ghsAPI.exportGhsQrPdf(rootGetters.instituteId, {
				[`${state.type}Ids`]: state.selectedItem,
			})
			if (!fileName) {
				let str = state.type === 'inventory' ? '물품관리' : '입고관리'
				window.fileDownload(response, `${DateTime.now().toFormat('yyyyMMdd')}_${str}_물품ID스티커.pdf`)
			} else {
				window.fileDownload(response, `${DateTime.now().toFormat('yyyyMMdd')}_${fileName}_물품ID스티커.pdf`)
			}
		}
	},
	exportPdfById: async ({ state, rootGetters }, { fileName, inventoryId }) => {
		const response = await ghsAPI.exportGhsQrPdf(rootGetters.instituteId, {
			[`${state.type}Ids`]: [inventoryId],
			type: 'GHS+QR',
		})
		console.log(response)
		console.log(URL.createObjectURL(response.data))
		window.fileDownload(response, fileName)
	},
	getPdfBlobUrl: async ({ state, rootGetters }, inventoryId) => {
		const response = await ghsAPI.exportGhsQrPdf(rootGetters.instituteId, {
			[`${state.type}Ids`]: [inventoryId],
			type: 'GHS+QR',
		})
		const blob = new Blob([response.data], { type: 'application/pdf' })
		return URL.createObjectURL(blob)
	},
}

export default { namespaced: true, state, getters, mutations, actions }
