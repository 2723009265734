import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCreateTrendList: (instId, scope) => `/institute/${instId}/dashboard/inventory/trend/list/create?scope=${scope}`,
	lookupCreateTrendSummary: (instId, scope) => `/institute/${instId}/dashboard/inventory/trend/summary/create?scope=${scope}`,
	lookupDisposalTrendList: (instId, scope) => `/institute/${instId}/dashboard/inventory/trend/list/dispose?scope=${scope}`,
	lookupDisposalTrendSummary: (instId, scope) => `/institute/${instId}/dashboard/inventory/trend/summary/dispose?scope=${scope}`,
	lookupUseTrendList: (instId, scope) => `/institute/${instId}/dashboard/inventory/trend/list/use?scope=${scope}`,
	lookupUseTrendSummary: (instId, scope) => `/institute/${instId}/dashboard/inventory/trend/summary/use?scope=${scope}`,
	lookupPossessionTrendList: instId => `/institute/${instId}/dashboard/inventory/trend/supply`,
	lookupDisposalItem: instId => `/institute/${instId}/dashboard/inventory/trend/need/dispose`,
	lookupMsdsUpload: instId => `/institute/${instId}/dashboard/safety/msds/trend`,
	lookupDashboardList: instId => `/institute/${instId}/dashboard/component/setting/web`,
	lookupDiagnosisList: instId => `institute/${instId}/dashboard/safety/specialdiagnosis`,
	lookupDangerStorage: instId => `institute/${instId}/dashboard/safety/dangerstorage`,
	lookupLegalList: instId => `institute/${instId}/dashboard/safety/legal`,
}

const dashboardAPI = {
	lookupCreateTrendList: (instId, scope) => axiosInstance.get(endPoint.lookupCreateTrendList(instId, scope)),
	lookupCreateTrendSummary: (instId, scope) => axiosInstance.get(endPoint.lookupCreateTrendSummary(instId, scope)),
	lookupDisposalTrendList: (instId, scope) => axiosInstance.get(endPoint.lookupDisposalTrendList(instId, scope)),
	lookupDisposalTrendSummary: (instId, scope) => axiosInstance.get(endPoint.lookupDisposalTrendSummary(instId, scope)),
	lookupUseTrendList: (instId, scope) => axiosInstance.get(endPoint.lookupUseTrendList(instId, scope)),
	lookupUseTrendSummary: (instId, scope) => axiosInstance.get(endPoint.lookupUseTrendSummary(instId, scope)),
	lookupPossessionTrendList: instId => axiosInstance.get(endPoint.lookupPossessionTrendList(instId)),
	lookupDisposalItem: instId => axiosInstance.get(endPoint.lookupDisposalItem(instId)),
	lookupMsdsUpload: instId => axiosInstance.get(endPoint.lookupMsdsUpload(instId)),
	lookupDashboardList: instId => axiosInstance.get(endPoint.lookupDashboardList(instId)),
	updateDashboardSetting: (instId, body) => axiosInstance.put(endPoint.lookupDashboardList(instId), body),
	lookupDiagnosisList: instId => axiosInstance.get(endPoint.lookupDiagnosisList(instId)),
	lookupDangerStorage: instId => axiosInstance.get(endPoint.lookupDangerStorage(instId)),
	lookupLegalList: instId => axiosInstance.get(endPoint.lookupLegalList(instId)),
}

export default dashboardAPI
