var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card storageQuantity",
    "class": [{
      warning: _vm.list.unsafeCount > 0
    }, {
      safe: _vm.list.unsafeCount == 0
    }, {
      displayOff: !_vm.displayCard
    }]
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._m(0), _c('h4', {
    staticClass: "Btn__hover-balloon-w",
    on: {
      "click": _vm.goPage
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('저장소별 위험물 지정수량 현황')) + " "), _c('div', {
    staticClass: "balloon"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('위험물 지정수량 배수로 이동합니다')))])]), _vm._m(1)]), _vm.setting == false ? _c('h3', {
    staticClass: "ml-auto",
    domProps: {
      "textContent": _vm._s(_vm.list.unsafeCount > 0 ? _vm.list.unsafeCount + _vm.tt('개') + '\n' + _vm.tt('위험') : _vm.tt('안전'))
    }
  }) : _c('div', {
    staticClass: "ml-auto switch-setting"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('대시보드에 노출')))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.displayCard,
      expression: "displayCard"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.displayCard) ? _vm._i(_vm.displayCard, null) > -1 : _vm.displayCard
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.displayCard,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.displayCard = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.displayCard = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.displayCard = $$c;
        }
      }, function ($event) {
        return _vm.toggleCard(_vm.id, _vm.displayCard);
      }]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('div', {
    staticClass: "card-noti"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('위험물 지정수량 초과 현황')))]), _vm.list.unsafeCount > 0 ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('저장소별로 <span style=color:#ff3b31>초과된 품목</span>만 보여집니다.'))
    }
  }) : _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('모든 저장소가 <span style=color:#0dc577;>안전하게</span> 관리되고 있습니다.'))
    }
  })]), _vm._l(_vm.list.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "card-box",
      on: {
        "click": function click($event) {
          return _vm.goStorage(item.name);
        }
      }
    }, [_c('div', {
      staticClass: "box-title"
    }, [_c('h5', [_vm._v(_vm._s(item.name))]), _c('h4', {
      staticClass: "ml-auto",
      "class": item.status == 'UNSAFE' ? 'warning' : 'safe'
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/icon-error.svg"),
        "alt": "icon"
      }
    }), _c('span', {
      domProps: {
        "textContent": _vm._s(item.status == 'UNSAFE' ? _vm.tt('위험') : _vm.tt('안전'))
      }
    })])]), _c('div', {
      staticClass: "box-content"
    }, _vm._l(item.unsafeLegalList, function (subitem, index) {
      return _c('div', {
        key: 'storage-' + index,
        staticClass: "box-inner"
      }, [_c('span', {
        staticClass: "subtitle"
      }, [_vm._v(_vm._s(subitem.legalName))]), _c('ul', _vm._l(subitem.childLegalList, function (item) {
        return _c('li', {
          key: item.legalId
        }, [_c('p', [_vm._v(_vm._s(item.legalName))]), _vm._m(2, true)]);
      }), 0)]);
    }), 0)]);
  })], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/dashboard-icon5.svg"),
      "alt": "icon"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow-diagonal.svg"),
      "alt": "icon"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-error.svg"),
      "alt": "icon"
    }
  })]);
}]

export { render, staticRenderFns }