var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "regist-step1-wrapper"
  }, [_c('div', {
    staticClass: "approval-info-wrapper"
  }, [_c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('결재 정보')))]), _c('image-button', {
    attrs: {
      "text": '결재선 설정'
    },
    on: {
      "click": _vm.showApprovalLineSettingModal
    }
  })], 1), _c('div', {
    staticClass: "approval-item-wrapper"
  }, [_vm.user.id ? _c('approval-info-item', {
    attrs: {
      "approvalTitle": '기안',
      "name": _vm.user.name,
      "affiliation": _vm.selectedGroup ? _vm.selectedGroup.name : '',
      "isFooter": false
    }
  }) : _vm._e(), _vm.draftInfo.approvalLine.length > 0 ? _c('div', _vm._l(_vm.draftInfo.approvalLine, function (approval, index) {
    return _c('approval-info-item', {
      key: "approval-".concat(index),
      attrs: {
        "approvalTitle": '결재',
        "name": approval.name,
        "affiliation": approval.parent.name,
        "isFooter": false
      }
    });
  }), 1) : _vm._e()], 1), _vm.isValid && _vm.draftInfo.approvalLine.length === 0 ? _c('div', {
    staticClass: "error"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/purchase/icon-error.svg"),
      "alt": "error"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('결재선을 설정해주세요.')))])]) : _vm._e()]), _c('div', {
    staticClass: "draft-info-wrapper"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('기안 정보')))]), _c('base-item', {
    attrs: {
      "title": '기안자'
    }
  }, [_c('div', {
    staticClass: "type-member"
  }, [_c('img', {
    attrs: {
      "src": _vm.user.image ? _vm.user.image : require('@/assets/svg/member-photo-default.svg')
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt(_vm.user.name)))])])]), _c('div', {
    staticClass: "type-group"
  }, [_c('input-text', {
    attrs: {
      "title": "기안 연구실",
      "isRequired": true,
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "inValidText": '기안 연구실을 선택해주세요.',
      "inputValue": _vm.selectedGroup ? _vm.selectedGroup.name : '기안 연구실을 선택해주세요',
      "isReadOnly": true
    }
  }), _c('button', {
    on: {
      "click": _vm.showSelectedGroupModal
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/svg/Next.svg')
    }
  })])], 1), _c('input-text', {
    ref: "subject",
    attrs: {
      "title": "기안 제목",
      "placeholder": "기안 제목을 입력해주세요",
      "isRequired": true,
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "inValidText": '기안 제목을 입력해주세요.',
      "inputValue": _vm.draftInfo.subject,
      "isValid": !_vm.isValid || !!_vm.$refs['subject'].value
    }
  }), _c('input-text-area', {
    ref: "content",
    attrs: {
      "title": "기안 내용",
      "placeholder": "기안 내용을 입력해주세요",
      "inputWidthSize": 600,
      "inputHeightSize": 120,
      "isReadOnly": false,
      "inputValue": _vm.draftInfo.content
    }
  }), _c('input-select', {
    ref: "budgetId",
    attrs: {
      "title": '예산',
      "inputWidthSize": 400,
      "placeholder": '예산을 선택해주세요',
      "list": _vm.getUsableBudgetList,
      "item": _vm.draftInfo.budgetId
    }
  }, [_vm.getUsableBudgetList.length === 1 ? _c('div', {
    staticClass: "list-no-data"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.selectedGroup ? '등록된 예산이 없습니다.' : '주문 연구실을 선택해주세요')))]), _vm.selectedGroup ? _c('span', {
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'BudgetCreate'
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('예산 등록하기')))]) : _vm._e()]) : _vm._e()]), _c('input-radio', {
    ref: "purchaseType",
    attrs: {
      "title": '구매방식',
      "list": _vm.purchaseTypeList,
      "isRequired": true,
      "selectedValue": _vm.draftInfo.purchaseType,
      "isValid": !_vm.isValid || !!_vm.$refs['purchaseType'].value
    }
  })], 1), _c('modal-form-approval-line-selected', {
    ref: "modal-form-approval-line-selected",
    attrs: {
      "defaultData": _vm.draftInfo.approvalLine
    },
    on: {
      "complete": _vm.completeDraftApprovalLine
    }
  }), _c('modal-select-group', {
    ref: "modal-select-group",
    attrs: {
      "title": "기안 연구실 선택",
      "isSelectSingle": true,
      "defaultData": _vm.groups
    },
    on: {
      "confirm": _vm.completeSelectedGroup
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }