import chemicalAPI from '@/services/api/safety/chemical'
import legalAPI from '@/services/api/safety/legal'

const safetyChemical = {
	namespaced: true,
	state: {
		chemicalList: {},
		ingredientLegals: {},
	},
	getters: {
		getChemcial: state => state.chemicalList,
	},
	mutations: {
		setChemicalList: (state, payload) => {
			state.chemicalList = payload
		},
		setIngredientCasno: (state, ingredientLegals) => {
			state.ingredientLegals = ingredientLegals
		},
		initIngredientCasno: (state, ingredientLegals) => {
			state.ingredientLegals = {}
		},
	},
	actions: {
		lookupChemicalList: async ({ commit }, payload) => {
			const { instId, offset, length, order, orderDirection, keyword } = payload
			const response = await chemicalAPI.lookupChemicalList(instId, offset, length, order, orderDirection, keyword)
			commit('setChemicalList', response.data)
		},
		exportChemicalDiaryExcel: async ({ commit }, payload) => {
			const { instId, body } = payload
			return await chemicalAPI.exportChemicalDiaryExcel(instId, body)
		},
		exportChemicalExcel: async ({ commit }, instId) => {
			return await chemicalAPI.exportChemicalExcel(instId)
		},
		exportChemicalExcelStartDate: async ({ commit }, payload) => {
			const { instId, body } = payload
			return await chemicalAPI.exportChemicalExcelStartDate(instId, body)
		},
		getLegalByCasno: async ({ rootGetters, state, commit }, casno) => {
			const response = await legalAPI.legalListByCasno(rootGetters.instituteId, {
				casno,
			})
			commit('setIngredientCasno', response.data)
		},
	},
}

export default safetyChemical
