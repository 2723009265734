<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper" @click.stop="toggleShowOption">
			<div class="form-select-box" ref="select-box">
				<div class="select-box">
					<p class="select-item-text" :class="{ placeholder: !inputValue }">{{ tt(`${selectOptionName}`) }}</p>
				</div>
				<div ref="form-option-list" class="form-option-list" v-if="isShowOption">
					<ul class="option-list">
						<li
							class="option-item"
							@click.stop="clickOption(option)"
							:key="`option-${option}-${idx}`"
							v-for="(option, idx) in optionList"
						>
							{{ option }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import MixinFormSelect from '@/mixins/form/formSelect'
export default {
	name: 'FormSelect',
	components: {},
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		optionList: {
			type: Array,
			default: null,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [MixinFormSelect],
	data() {
		return {
			isShowOption: false,
			inputValue: null,
		}
	},
	computed: {
		error() {
			return this.inputValue == null && this.isShowError && this.required
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-select.scss';
</style>
