import { ToastType } from '@/utils/define/ToastType'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
	data() {
		return {}
	},
	computed: {
		...mapGetters('modal/ghs', ['mode', 'ghsData']),
	},
	methods: {
		...mapMutations('modal/ghs', ['setPreviewData', 'setMode']),
		...mapActions('modal/ghs', ['setInit', 'getUnitList', 'uploadGhsLabel', 'deleteGhsLabel', 'getGhsStatement', 'cancelParseMsds']),
		makeGHS() {
			this.setInit()
			this.setMode('ADD')
			this.$refs['make-ghs'].show()
		},
		showPreviewGHS() {
			if (this.mode === 'ADD') {
				this.setPreviewData(this.item)
			}
			this.$refs['ghs-form'].hide()
			this.$refs['ghs-preview'].show()
		},
		editGHS() {
			this.setMode('EDIT')
			this.$refs['ghs-label'].hide()
			this.$refs['make-ghs'].show()
		},
		showModalDeleteGHS() {
			this.$refs['modal-delete-ghs'].show()
		},
		completeCreateGHS() {
			this.$refs['ghs-preview'].hide()
			this.$refs['ghs-label'].show()
			let text = this.mode === 'EDIT' ? '편집' : '생성'
			this.$root.toast(`GHS ${text} 완료`, `GHS 라벨 ${text}이 완료되었습니다`, ToastType.SUCCESS)
			this.$emit('completeGHS', this.ghsData)
		},
		manageGHS() {
			this.$refs['ghs-label'].show()
			this.setPreviewData(this.item)
		},
	},
}
