<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<textarea class="form-textarea" :placeholder="tt(placeholder)" :value="replaceValue" @input="handleInput"></textarea>
		<span class="error" v-if="error">{{ tt(errorMessage) }}</span>
	</div>
</template>

<script>
export default {
	name: 'FormMultipleLine',
	components: {},
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			deafult: false,
		},
	},
	data() {
		return {
			inputValue: '',
		}
	},
	computed: {
		error() {
			return this.required && this.isShowError && this.inputValue === null
		},
		replaceValue() {
			return this.inputValue?.replace(/\\r\\n|\\n|\\r/gm, '\n')
		},
	},
	watch: {
		value(inputValue) {
			if (this.value !== this.inputValue) {
				this.inputValue = inputValue
			}
		},
		// inputValue(inputValue) {
		// 	this.$emit('input', inputValue)
		// },
	},
	created() {},
	mounted() {
		if (this.value !== this.inputValue) {
			this.inputValue = this.value
			this.$emit('input', this.replaceValue)
		}
	},
	methods: {
		handleInput(e) {
			this.$emit('input', e.target.value)
		},
	},
}
</script>

<style lang="scss" scoped>
.form-input {
	position: relative;
	width: 100%;
	textarea {
		height: 80px;
		width: 100%;
		border: 1px solid $COLOR_CCC;
		padding: 5px 10px;
		border-radius: 5px;
		font-size: 14px;
		&:focus {
			border: 1px solid black;
		}
	}
	textarea::placeholder {
		color: $PLACEHOLDER;
		font-size: 12px;
	}
	&.error {
		span.error {
			display: block;
			font-size: 0.75rem;
			font-weight: 400;
			padding-left: 18px;
			background-image: url(~@/assets/svg/input-icon-warning.svg);
			background-repeat: no-repeat;
			background-size: 12px;
			background-position: center left 0;
			color: $RED;
			margin-top: 4px;
		}
		textarea {
			border: 1px solid $RED !important;
		}
	}
	.name {
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 8px !important;
	}
	span.error {
		display: none;
	}

	.form-textarea {
		display: flex;
		align-items: center;
	}
}
</style>
