import inventoryAPI from '@/services/api/inventory'
import _ from 'lodash'
const state = {
	brandList: [],
}

const getters = {
	brandList: state => state.brandList,
}

const mutations = {
	setBrandList: (state, brandList) => {
		state.brandList = _.cloneDeep(brandList)
	},
}

const actions = {
	getBrandList: async ({ rootGetters, commit }, keyword) => {
		const response = await inventoryAPI.getBrandList(rootGetters.instituteId, {
			isUse: false,
			keyword: keyword,
		})
		commit('setBrandList', response.data)
	},
}
export default { namespaced: true, state, getters, mutations, actions }
