<template>
	<div class="detail-wrap" v-if="item">
		<detail-header :item="item"></detail-header>
		<component v-bind:is="activeBadge" :item="item" :legals="legals"></component>
		<div class="register-info-wrap">
			<div class="info-title">{{ tt('물품정보') }}</div>
			<properties-info v-if="isCategoryChemical" :item="item"></properties-info>
			<safety-info
				v-if="hasMsds || !!item.ghs"
				:msds="item.msds"
				:ghs="item.ghs"
				@uploadFile="getDetail($route.params.id)"
				@changeFile="getDetail($route.params.id)"
			></safety-info>
			<member-info :item="item"></member-info>
			<product-info :item="item" :inventoryFieldsInfo="inventoryFields"></product-info>
			<management-info :item="item" :managementFields="managementFields"></management-info>
			<custom-info v-if="hasCustomFields" :item="item" :customFields="customFields" :customFieldsData="customFieldsData"></custom-info>
			<ingredient-info :ingredient="item && item.ingredientList"></ingredient-info>
			<legal-info v-if="!isBasic" :legals="legals" :ingredientList="item.ingredientList" :casno="item.casno"></legal-info>
		</div>
		<div class="manage-info-wrap">
			<div class="info-title">
				{{ tt('물품관리 기록') }}
			</div>
			<div class="filter-wrap">
				<div class="filter-wrap-header">
					<div class="bold-text">
						{{ tt('상세검색') }}
					</div>
					<div class="text">
						{{ tt('물품관리 기록에서 조회할 항목을 검색하거나 선택할 수 있습니다') }}
					</div>
				</div>
				<history-info :item="item"></history-info>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DetailHeader from '@/views/inventoryGen2/components/disposalDetail/header/DetailHeader.vue'
import BadgeListChemical from '@/views/inventoryGen2/components/disposalDetail/badge/BadgeListChemical.vue'
import BadgeListDefault from '@/views/inventoryGen2/components/disposalDetail/badge/BadgeListDefault.vue'
import PropertiesInfo from '@/views/inventoryGen2/components/disposalDetail/propertiesInfo/PropertiesInfo.vue'
import SafetyInfo from '@/views/inventoryGen2/components/disposalDetail/safetyInfo/SafetyInfo.vue'
import ManagementInfo from '@/views/inventoryGen2/components/disposalDetail/managementInfo/ManagementInfo.vue'
import DetailFooter from '@/views/inventoryGen2/components/disposalDetail/footer/DetailFooter.vue'
import ProductInfo from '@/views/inventoryGen2/components/disposalDetail/productInfo/ProductInfo.vue'
import CustomInfo from '@/views/inventoryGen2/components/disposalDetail/customInfo'
import HistoryInfo from '@/views/inventoryGen2/disposalDetail/historyInfo'

import MemberInfo from '@/views/purchase/cargo/components/cargoDetail/productInfo/memberInfo/MemberInfo.vue'
import IngredientInfo from '@/views/purchase/cargo/components/cargoDetail/productInfo/ingredientInfo/IngredientInfo.vue'
import LegalInfo from '@/views/purchase/cargo/components/cargoDetail/productInfo/leaglInfo/LegalInfo.vue'
import { LargeClass } from '@/utils/define/ItemCode'

import MixinModalGhs from '@/mixins/modalGhs/mixinModalGhs'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'InventoryDetail',
	mixins: [MixinModalGhs],
	components: {
		DetailHeader,
		BadgeListDefault,
		PropertiesInfo,
		SafetyInfo,
		ManagementInfo,
		DetailFooter,
		ProductInfo,
		CustomInfo,
		HistoryInfo,
		MemberInfo,
		IngredientInfo,
		LegalInfo,
	},
	computed: {
		...mapGetters('inventoryGen2/item', ['item', 'legals', 'inventoryFields', 'managementFields', 'customFields', 'customFieldsData']),
		...mapGetters('license', ['isBasic']),
		isCategoryChemical() {
			return this.item?.category?.classification === LargeClass.CHEMICAL
		},
		hasMsds() {
			return this.isCategoryChemical && Object.values(this.item?.msds).some(i => i)
		},
		hasLegals() {
			if (this.legals) {
				let totalLegalList = []
				Object.keys(this.legals).forEach(key => {
					totalLegalList.push(this.legals[key].list)
				})
				totalLegalList = totalLegalList.flat()
				return totalLegalList.length > 0
			}
			return null
		},
		hasCustomFields() {
			return this.customFields?.length > 0
		},
		activeBadge() {
			switch (this.item?.category?.classification) {
				case 'CAT_CHEMICAL':
					return BadgeListChemical
				default:
					return BadgeListDefault
			}
		},
		ghsPayload() {
			return this.item?.brand?.id && this.item?.productCode
				? {
						brandId: this.item?.brand?.id,
						productCode: this.item?.productCode,
						inventoryId: this.item?.id,
						ghs: this.ghsData,
				  }
				: {
						inventoryId: this.item?.id,
						ghs: this.ghsData,
				  }
		},
	},
	async created() {
		await this.getDetail(this.$route.params.id)
		await this.getCategoryInfo()
		// this.getGhsStatement()
		this.getUnitList()

		// AppHeader 경로 표시
		this.setStorageDetailPaths([this.item.name])
	},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/item', ['setItem']),
		...mapActions('inventoryGen2/item', ['getDetail', 'getCategoryInfo']),
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		manageGHS() {
			this.$refs['ghs-label'].show()
			this.setPreviewData(this.item)
		},
	},
	beforeDestroy() {
		this.setItem(null)
	},
}
</script>

<style lang="scss" scoped>
.detail-wrap {
	display: flex;
	flex-direction: column;
	height: auto !important;
	margin-bottom: 60px;
	min-width: 1080px;
	.register-info-wrap {
		padding: 20px 30px;
		.info-title {
			display: flex;
			height: 50px;
			font-size: 24px;
			font-weight: 700;
			padding-bottom: 15px;
			border-bottom: 1px solid black;
		}
	}

	.manage-info-wrap {
		padding: 20px 30px;
		justify-content: space-between;
		.info-title {
			width: 100%;
			display: flex;
			height: 50px;
			font-size: 24px;
			font-weight: 700;
			padding-bottom: 15px;
			justify-content: space-between;
			border-bottom: 1px solid black;
		}
	}
}

.filter-wrap {
	.filter-wrap-header {
		display: flex;
		padding: 30px 0 15px 0;
		align-items: center;
		.bold-text {
			font-size: 16px;
			font-weight: 700;
			margin-right: 10px;
		}
		.text {
			font-size: 14px;
			font-weight: 400;
			color: $SECONDARY_1;
		}
	}
}
</style>
