import diagnosisAPI from '@/services/api/safety/diagnosis'

const safetyHealth = {
	namespaced: true,
	state: {
		diagnosisList: {},
		diagnosisInfo: {},
		diagnosisUsageInfo: {},
		diagnosisLogInfo: {},
	},
	getters: {
		getDiagnosis: state => state.diagnosisList,
		getDiagnosisInfo: state => state.diagnosisInfo,
		getDiagnosisUsageInfo: state => state.diagnosisUsageInfo,
		getDiagnosisLogInfo: state => state.diagnosisLogInfo,
	},
	mutations: {
		setDiagnosisList: (state, payload) => {
			state.diagnosisList = payload
		},
		setDiagnosisInfo: (state, payload) => {
			state.diagnosisInfo = payload
		},
		setDiagnosisUsageInfo: (state, payload) => {
			state.diagnosisUsageInfo = payload
		},
		setDiagnosisLogInfo: (state, payload) => {
			state.diagnosisLogInfo = payload
		},
	},
	actions: {
		lookupDiagnosisList: async ({ commit }, payload) => {
			const { instId, offset, length, order, orderDirection, keyword, showOnlyOverdue } = payload
			const response = await diagnosisAPI.lookupDiagnosisList(instId, offset, length, order, orderDirection, keyword, showOnlyOverdue)
			commit('setDiagnosisList', response.data)
		},
		lookupDiagnosisInfo: async ({ commit }, payload) => {
			const { instId, userId, offset, length, startDatetime, endDatetime } = payload
			const response = await diagnosisAPI.lookupDiagnosisInfo(instId, userId, offset, length, startDatetime, endDatetime)
			commit('setDiagnosisInfo', response.data)
		},
		lookupDiagnosisUsageInfo: async ({ commit }, payload) => {
			const { instId, userId, offset, length, startDatetime, endDatetime } = payload
			const response = await diagnosisAPI.lookupDiagnosisUsageInfo(instId, userId, offset, length, startDatetime, endDatetime)
			commit('setDiagnosisUsageInfo', response.data)
		},
		lookupDiagnosisLogInfo: async ({ commit }, payload) => {
			const { instId, userId, offset, length, startDatetime, endDatetime } = payload
			const response = await diagnosisAPI.lookupDiagnosisLogInfo(instId, userId, offset, length, startDatetime, endDatetime)
			commit('setDiagnosisLogInfo', response.data)
		},
		updateDiagnosisDate: async ({ commit }, payload) => {
			const { instId, body } = payload
			return await diagnosisAPI.updateDiagnosisDate(instId, body)
		},
		deleteDiagnosis: async ({ commit }, payload) => {
			const { instId, ids } = payload
			return await diagnosisAPI.deleteDiagnosis(instId, ids)
		},
		exportExcel: async ({ commit }, instId) => {
			return await diagnosisAPI.exportExcel(instId)
		},
	},
}

export default safetyHealth
