var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-radio-wrap"
  }, [_c('base-item', {
    attrs: {
      "title": _vm.title,
      "required": _vm.isRequired
    }
  }, [_c('div', {
    staticClass: "input-radio-content"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('span', {
      key: "radio_".concat(index)
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.value,
        expression: "value"
      }],
      attrs: {
        "id": "radio_".concat(index),
        "type": "radio",
        "name": "radioList"
      },
      domProps: {
        "value": item.value,
        "checked": _vm._q(_vm.value, item.value)
      },
      on: {
        "change": [function ($event) {
          _vm.value = item.value;
        }, function ($event) {
          return _vm.changeItem(item.value);
        }]
      }
    }), _c('label', {
      attrs: {
        "for": "radio_".concat(index)
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(item.name)) + " ")])]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }