const Check = {
	English: false,
	isType: type => {
		if (type == 'en') {
			Check.English = true
		} else {
			Check.English = false
		}
	},
}
export { Check }
