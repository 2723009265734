var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('div', {
    staticClass: "merck-tooltip",
    "class": {
      animation: _vm.isAnimation
    },
    style: _vm.cssVariable
  }, [_vm.image ? _c('img', {
    attrs: {
      "src": _vm.image
    }
  }) : _vm._e(), _c('div', {
    staticClass: "merck-tooltip-inner",
    "class": [_vm.placement, _vm.isBrandModal ? 'cursor' : ''],
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickPromotionMore.apply(null, arguments);
      }
    }
  }, [_vm._t("default")], 2), _vm.isClose ? _c('button', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$refs['modal-hide'].show();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close-black.svg"),
      "alt": "close"
    }
  })]) : _vm._e(), _c('modal-confirm', {
    ref: "modal-hide",
    attrs: {
      "title": "할인구매 팁 보지않기",
      "cancelText": '닫기',
      "confirmText": '일주일간 보지 않기',
      "content": "MERCK 등 인기 시약 브랜드 최대 20% 할인 관련 TIP을 숨기시겠습니까?"
    },
    on: {
      "confirm": _vm.merckHide
    }
  }), _c('ModalPromotionBrandTable', {
    ref: "ModalPromotionBrandTable"
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }