var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('tr', {
    staticClass: "table-item"
  }, [_c('td', {
    staticClass: "header-checkbox"
  }, [_c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isChecked,
      expression: "isChecked"
    }],
    attrs: {
      "type": "checkbox",
      "selected": _vm.isKeySelectedAll
    },
    domProps: {
      "checked": Array.isArray(_vm.isChecked) ? _vm._i(_vm.isChecked, null) > -1 : _vm.isChecked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.isChecked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isChecked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isChecked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isChecked = $$c;
        }
      }
    }
  })])]), _c('td', {
    staticClass: "volume"
  }, [_c('div', {
    staticClass: "volume-wrap"
  }, [_c('img', {
    staticClass: "remove-btn",
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/remove-circle.svg"),
      "width": "18",
      "height": "18"
    },
    on: {
      "click": _vm.removeItem
    }
  }), _c('form-divide', {
    attrs: {
      "value": _vm.item.volume,
      "unit": _vm.item.volumeUnit,
      "forModalDivide": ""
    }
  })], 1)]), _c('td', {
    staticClass: "storage"
  }, [_c('div', {
    staticClass: "storage-wrap"
  }, [_c('form-storage', {
    attrs: {
      "value": _vm.item.storage
    },
    on: {
      "input": _vm.changeStorage
    }
  })], 1)]), _c('td', {
    staticClass: "manage"
  }, [_c('div', {
    staticClass: "manage-wrap"
  }, [_c('button', {
    staticClass: "btn-edit",
    on: {
      "click": function click($event) {
        return _vm.$emit('showManage');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/icon-pencil.svg"),
      "width": "24",
      "height": "24"
    }
  })]), _vm.isChangedItem[_vm.idx] ? _c('span', {
    staticClass: "change-text"
  }, [_vm._v(_vm._s(_vm.tt('변경됨')))]) : _vm._e()])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }