<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			{{ tt('라이선스 기간 추가') }}
		</template>
		<template>
			<div class="license-select-wrapper">
				<div class="license-summary">
					<img class="imgbox" src="@/assets/images/lm-pro-icon.png" />
					<div class="txtbox">
						<h6>{{ licenseTypeText }}</h6>
						<p v-if="isFreeTier">
							{{ tt('30일 무료') }}
						</p>
						<p v-else-if="isTrial">
							{{ tt('무료') }}
						</p>
						<p v-else-if="isEducation">
							{{ tt('${1}원 / 연구실당 (연 기준 ・ VAT 별도)', formatNumberLocale(licenseRatePlan['YEAR']['EDUCATION'])) }}
						</p>
						<p v-else>
							{{ tt('${1}원 / 라이선스 당(월 기준 ・ VAT 별도)', formatNumberLocale(getAdditonalPrice)) }}
						</p>
					</div>
				</div>
				<div>
					<p>{{ tt('현재') }}</p>
					<div class="flex">
						<p>{{ tt('멤버 수 / 라이선스') }}</p>
						<span class="license-member">
							<span
								class="license-now-member"
								:class="{
									red: instInfo.allMemberCount >= this.license.quantity,
								}"
							>
								{{ formatNumberLocale(instInfo.allMemberCount) }}&nbsp;
							</span>
							<span class="license-quantity">
								{{
									!(isTrial || isFreeTier || !isLicenseActivate)
										? ' / ' + (license.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(license.quantity))
										: ''
								}}
							</span>
						</span>
					</div>
				</div>
				<div>
					<p>{{ tt('추가할 기간') }}</p>
					<div class="num-box">
						<button :class="{ cant: month < 2 || (month <= 12 && isEducation) }" @click="reducePeriod">
							<img src="@/assets/svg/input-minus.svg" />
						</button>
						<p class="license-count-unit" v-if="isEducation">{{ tt('${1}년', month / 12) }}</p>
						<p class="license-count-unit" v-else>{{ tt('${1}개월', month) }}</p>
						<button :class="{ cant: month >= 11 && !isEducation }" @click="addPeriod" id="add-period">
							<img src="@/assets/svg/input-plus.svg" />
						</button>
						<b-tooltip v-if="month >= 11 && !isEducation" target="add-period" triggers="hover">
							{{ tt('기간은 11개월까지 추가할 수 있습니다.') }}
						</b-tooltip>
					</div>
				</div>
				<div class="license-date">
					<ul>
						<li>
							<p>
								<span class="bold">
									{{ month % 12 == 0 ? tt('1년 당') : tt('1개월 당') }}
								</span>
								<span> &nbsp; {{ tt('결제 금액') }} </span>
							</p>
							<span>
								￦{{
									formatNumberLocale(parseInt(month % 12 == 0 ? licensePurchase.price : licensePurchase.price / month)) +
									'(VAT 미포함)'
								}}
							</span>
						</li>
						<li>
							<p>
								<span class="bold">
									{{ tt('추가 기간(${1})', month % 12 == 0 ? month / 12 + '년' : month + '개월') }}
								</span>
								<span>
									{{ tt('의 결제 금액') }}
								</span>
							</p>
							<span> ￦{{ formatNumberLocale(licensePurchase.totalPrice) + '(VAT 포함)' }} </span>
						</li>
					</ul>
				</div>
				<div class="license-price">
					<ul>
						<li>
							<p>{{ tt('소계') }}</p>
							<span> ￦{{ formatNumberLocale(licensePurchase.price) }} </span>
						</li>
						<li>
							<p>VAT(10%)</p>
							<span> ￦{{ formatNumberLocale(licensePurchase.vat) }} </span>
						</li>
						<li>
							<h3>{{ tt('합계') }}</h3>
							<h2>￦{{ formatNumberLocale(licensePurchase.totalPrice) }}</h2>
						</li>
					</ul>
				</div>
				<div class="license-payment">
					<h5>{{ tt('결제수단') }}</h5>
					<div>
						<label class="card-registered-label">
							<div class="input-div" @click="selectedPayment = paymentMethod.CARD_REGISTERED">
								<img src="@/assets/svg/radio-off.svg" v-if="selectedPayment != paymentMethod.CARD_REGISTERED" />
								<img src="@/assets/svg/radio-on.svg" v-else />
								{{ tt(hasDefaultCreditCard ? '등록된 카드로 결제' : '카드 등록 후 결제') }}
							</div>
							<div
								class="change-card"
								v-if="selectedPayment == paymentMethod.CARD_REGISTERED && hasDefaultCreditCard"
								@click="editCard"
							>
								<span>{{ tt('결제카드 변경') }}</span>
								<img src="@/assets/svg/icon-blue-right-arrow.svg" width="16" height="24" />
							</div>
						</label>
						<label @click="selectedPayment = paymentMethod.ELECTRONIC_BILL">
							<img src="@/assets/svg/radio-off.svg" v-if="selectedPayment != paymentMethod.ELECTRONIC_BILL" />
							<img src="@/assets/svg/radio-on.svg" v-else />
							{{ tt('전자세금계산서') }}
						</label>
					</div>
				</div>
				<div class="license-agree">
					<div class="agreement-box">
						<label style="cursor: pointer">
							<input type="checkbox" v-model="agreement" />
							{{ tt('(필수) 이용약관을 확인하였으며, 구매 진행에 동의합니다.') }}
						</label>
						<a href="https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7" target="_blank">
							{{ tt('보기') }}
						</a>
					</div>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<button class="button-primary" @click="confirm">
				{{ tt('결제하기') }}
			</button>
		</template>
		<ModalCard ref="ModalCard" />
		<ModalEbill ref="ModalEbill" />
	</b-modal>
</template>
<script>
import ModalCard from './ModalCard.vue'
import ModalEbill from './ModalEbill.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
import LicenseSelector from '../components/LicenseSelector.vue'
export default {
	components: {
		LicenseSelector,
		ModalCard,
		ModalEbill,
	},
	mixins: [MixinLicense, MixinPayment],
	data() {
		return {
			month: 12,
			quantity: 0,
			plan: null,
			selectedPayment: 'CARD_REGISTERED',
			agreement: false,
		}
	},
	methods: {
		async show() {
			this.initData()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async initData() {
			this.month = this.isEducation ? 12 : 1
			this.quantity = this.license.quantity
			this.plan = this.license.type
			this.selectedPayment = this.paymentMethod.CARD_REGISTERED
			this.agreement = false
			await this.getAdditionMonth({
				value: this.month,
			})
			await this.loadDefaultCreditCard()
		},
		async reducePeriod() {
			if (this.isEducation) {
				this.month -= 12
				if (this.month <= 0) this.month = 12
			} else {
				this.month--
				if (this.month <= 0) this.month = 1
			}
			await this.getAdditionMonth({
				value: this.month,
			})
		},
		async addPeriod() {
			if (this.isEducation) {
				this.month += 12
			} else {
				this.month++
				if (this.month >= 12) this.month = 11
			}
			await this.getAdditionMonth({
				value: this.month,
			})
		},
		editCard() {
			this.$refs['ModalCard'].show('EDIT', async cardId => {
				await this.loadDefaultCreditCard()
			})
		},
		confirm() {
			if (this.selectedPayment == this.paymentMethod.CARD_REGISTERED) {
				if (this.hasDefaultCreditCard) {
					this.addLicense({ cardId: this.defaultCreditCard.id })
				} else {
					// 카드 등록
					this.$refs['ModalCard'].show('REGISTER', cardId => this.addLicense({ cardId }))
				}
			} else {
				this.$refs['ModalEbill'].show(ebillId => this.addLicense({ ebillId }))
			}
		},
		async addLicense({ ebillId, cardId }) {
			try {
				// 카드로 베이직 구매
				await this.additionMonth({
					cardId: cardId,
					ebillId: ebillId,
					value: this.month,
					paymentMethod: this.selectedPayment,
				})
				this.$emit('complete')
				this.hide()
			} catch (e) {
				console.log(e)
				this.$root.toast('결제 실패', e?.response?.data?.msg ?? '결제에 실패하였습니다. 결제정보를 다시 확인해주세요', 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-purchase.scss';
::v-deep {
	.modal-body {
		padding-top: 0px;
		.license-select-wrapper {
			margin-top: 0px;
			border-top: none;
		}
	}
}
</style>
