var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "startPro"
    }
  }, [_c('div', {
    staticClass: "page-header"
  }, _vm._l(_vm.tabList, function (item) {
    return _c('label', {
      key: item.mode,
      "class": [{
        active: item.mode == _vm.mode
      }, {
        disabled: item.mode > _vm.beginStep
      }]
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.mode,
        expression: "mode"
      }],
      attrs: {
        "type": "radio",
        "disabled": item.mode > _vm.beginStep
      },
      domProps: {
        "value": item.mode,
        "checked": _vm._q(_vm.mode, item.mode)
      },
      on: {
        "change": function change($event) {
          _vm.mode = item.mode;
        }
      }
    }), _c('img', {
      attrs: {
        "src": require('@/assets/svg/' + item.image)
      }
    }), _vm._v(" " + _vm._s(_vm.tt(item.name)) + " ")]);
  }), 0), _vm.mode == null ? _c('registering-member', {
    ref: "registeringMember"
  }) : _vm.mode == 1 ? _c('registering-organization', {
    ref: "registering-organization",
    attrs: {
      "mode": _vm.mode,
      "displayNoticeOrganization": _vm.notice.organization,
      "userPermission": _vm.userPermission
    },
    on: {
      "hideNoticeOrganization": _vm.hideNoticeOrganization,
      "complete": _vm.complete,
      "nextStep": _vm.nextStep
    }
  }) : _c('registering-location', {
    attrs: {
      "displayNoticeLocation": _vm.notice.location,
      "displayNoticeStorage": _vm.notice.storage,
      "mode": _vm.mode
    },
    on: {
      "hideNoticeLocation": _vm.hideNoticeLocation,
      "hideNoticeStorage": _vm.hideNoticeStorage,
      "complete": _vm.complete,
      "nextStep": _vm.nextStep
    }
  }), _c('modal-well-done', {
    ref: "modal-well-done",
    attrs: {
      "content": _vm.content,
      "button": _vm.buttonTxt,
      "mode": _vm.mode
    },
    on: {
      "nextStep": _vm.nextStep
    }
  }), _c('div', {
    staticClass: "fixed-btn",
    on: {
      "click": _vm.nextStep
    }
  }, [_c('button', {
    staticClass: "Btn__yellow"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.nextTxt)) + " ")]), _c('button', {
    staticClass: "Btn__skip",
    on: {
      "click": _vm.goDashboard
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('초기설정 건너뛰기')) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }