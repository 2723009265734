<template>
	<div class="Modal ModalWellDone" v-if="isShow">
		<div class="Popup__background"></div>
		<div class="Modal__container">
			<div class="Modal__content">
				<div class="imgbox">
					<img src="@/assets/images/check.png" />
				</div>
				<h2>Well Done!</h2>
				<p>{{ tt(content) }}</p>
				<button class="Btn__yellow" @click="close">
					{{ tt(button) }}
				</button>
				<span @click="goGuide" v-if="mode == 3" style="cursor: pointer">{{ tt('랩매니저 가이드보기') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import MixinModal from '@/mixins/modal'
export default {
	name: 'ModalWellDone',
	mixins: [MixinModal],
	props: {
		content: {
			type: String,
			default: '',
		},
		button: {
			type: String,
			default: '',
		},
		mode: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {}
	},
	methods: {
		close() {
			this.hide()
			this.$emit('changeNextStep')
		},
		goGuide() {
			window.open('https://www.notion.so/smartjack/User-Guide-PC-8248257f8c84420ba787f7f0408e3566')
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalWellDone {
	.Modal__content {
		padding: 40px;
		h2 {
			font-size: 2rem;
			font-weight: 700;
		}
		p {
			font-size: 1rem;
			font-weight: 700;
			margin-top: 10px;
		}
		.Btn__yellow {
			margin-top: 40px;
			width: 195px;
			height: 48px;
			font-size: 1rem;
			font-weight: 700;
		}
		span {
			margin-top: 36px;
			display: block;
			text-decoration: underline;
			color: $SECONDARY_2;
			font-size: 0.875rem;
			font-weight: 700;
			cursor: pointer;
		}
	}
}
</style>
