<template>
	<table class="cargo-table-wrap" cellspacing="0" cellpadding="0" ref="cargo-table">
		<cargo-table-header></cargo-table-header>
		<cargo-table-item
			v-for="cargo in list"
			:item="cargo"
			:key="cargo.id"
			@needInputCargoInfo="item => $emit('needInputCargoInfo', item)"
			@click="goToDetail"
		></cargo-table-item>
		<div class="empty-table" v-if="isEmptyList">
			<img class="empty-img" src="@/assets/svg/empty/inventory-empty-data.svg" />
			<p class="bold">{{ tt('입고할 물품이 없습니다') }}</p>
			<p class="sub">{{ tt('입고할 항목을 추가해보세요') }}</p>
		</div>
	</table>
</template>
<script>
import PageName from '@/utils/define/PageName'
import CargoTableItem from './CargoTableItem.vue'
import CargoTableHeader from './CargoTableHeader.vue'
import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
	props: {
		previousPage: {
			type: Object,
			default: () => {},
		},
	},
	components: { CargoTableItem, CargoTableHeader },
	data() {
		return {}
	},
	computed: {
		...mapState('scroll', ['scrollHeight']),
		...mapGetters('cargo/list', ['list', 'isEmptyList']),
	},
	watch: {},
	created() {},
	mounted() {
		if (this.previousPage?.name === PageName.Purchase.CargoDetail) {
			this.$refs['cargo-table'].scrollTo(0, this.scrollHeight)
		}
	},
	methods: {
		...mapMutations('scroll', ['setScrollHeight']),
		goToDetail(id) {
			const { scrollTop } = this.$refs['cargo-table']
			this.setScrollHeight(scrollTop)
			this.$router.push({
				name: PageName.Purchase.CargoDetail,
				params: {
					cargoId: id,
				},
			})
		},
		moveScrollTop() {
			this.setScrollHeight(0)
			this.$refs['cargo-table'].scrollTo(0, this.scrollHeight)
		},
	},
}
</script>

<style lang="scss" scoped>
.cargo-table-wrap {
	height: calc(100% - 220px);
	text-align: left;
	display: block;
	overflow: auto;
	white-space: nowrap;
	border-spacing: 0;
	border-collapse: separate;
}

.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: $SECONDARY_2;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
