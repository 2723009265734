var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "no-data-inventory",
    staticClass: "no-data-list"
  }, [_c('img', {
    "class": {
      list: _vm.image == 'list'
    },
    attrs: {
      "src": _vm.image.length > 0 ? require('@/assets/svg/no-data_' + _vm.image + '.svg') : ''
    }
  }), _c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.title))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.content))
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }