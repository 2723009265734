import msdsAPI from '@/services/api/safety/msds'
import inventoryAPI from '@/services/api/inventory/gen2'
const safetyMsds = {
	namespaced: true,
	state: {
		msdsList: {},
		isShowModal: false,
		clickedItem: {},
		msds: {},
	},
	getters: {
		getMsdsList: state => state.msdsList,
	},
	mutations: {
		setMsdsList: (state, payload) => {
			state.msdsList = payload
		},
		setShowModal: (state, payload) => {
			state.isShowModal = payload
		},
		setClickedItem: (state, payload) => {
			state.clickedItem = payload
		},
		setMsds: (state, payload) => {
			state.msds = payload
		},
	},
	actions: {
		lookupMsdsList: async ({ commit }, payload) => {
			const { instId, offset, length, order, orderDirection, keyword, status, brandsId } = payload
			const response = await msdsAPI.lookupMsdsList(instId, offset, length, order, orderDirection, keyword, status, brandsId)
			commit('setMsdsList', response.data)
		},
		deleteMsds: async ({ rootGetters }, payload) => {
			await inventoryAPI.deleteMsds(rootGetters.instituteId, payload)
		},
		getMsds: async ({ rootGetters, commit, state }) => {
			const { brand, productCode, inventoryId } = state.clickedItem
			let params
			if (brand?.id && productCode) {
				params = {
					brandId: brand.id,
					productCode: productCode,
				}
			} else if (inventoryId) {
				params = {
					inventoryId: inventoryId,
				}
			}
			const response = await msdsAPI.getMsds(rootGetters.instituteId, params)
			const updateItem = {
				...state.clickedItem,
				msds: {
					institute: response.data.institute,
					kosha: response.data.kosha,
					master: response.data.master,
				},
				ghs: response.data.ghs,
			}
			commit('setClickedItem', updateItem)
		},
	},
}

export default safetyMsds
