<template>
	<div
		class="card healthCheckup"
		:class="[
			{ warning: list.unsafeCount > 0 },
			{
				safe: list.unsafeCount == 0,
			},
			{ displayOff: !displayCard },
		]"
	>
		<div class="card-title">
			<div class="imgbox">
				<img src="@/assets/svg/dashboard-icon2.svg" alt="icon" />
			</div>
			<h4 class="Btn__hover-balloon-w" @click="goPage">
				{{ tt('특수건강진단 현황') }}
				<div class="balloon">
					<p>{{ tt('특수건강진단 목록으로 이동합니다') }}</p>
				</div>
				<button>
					<img src="@/assets/svg/arrow-diagonal.svg" alt="icon" />
				</button>
			</h4>
			<h3 class="ml-auto" v-if="setting == false" v-text="list.unsafeCount ? tt('${1}명 진단 필요', list.unsafeCount) : tt('안전')"></h3>
			<div class="ml-auto switch-setting" v-else>
				<h5>{{ tt('대시보드에 노출') }}</h5>
				<label class="switch">
					<input type="checkbox" v-model="displayCard" @change="toggleCard(id, displayCard)" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="card-noti">
			<h6>{{ tt('특수건강진단 현황') }}</h6>
			<p
				v-if="list.overdueList.length > 0 || list.targetList.length > 0"
				v-html="
					tt(
						'<span style=color:#ff3b31;>진단일 초과자</span>와 진단 대상자 중<span style=color:#ff3b31;> 1달 안에 진단을 받아야 하는 인원</span>들을 보여줍니다.'
					)
				"
			></p>
			<p v-else>{{ tt('특수건강진단이 필요한 인원이 없습니다.') }}</p>
		</div>
		<div class="card-box" v-if="list.overdueList.length > 0 || list.targetList.length > 0">
			<div class="box-title">
				<h5>{{ tt('진단일 초과자') }}</h5>
				<h4 class="ml-auto warning" v-if="list.overdueList.length > 0">
					<img src="@/assets/svg/icon-error.svg" alt="icon" />{{ tt('${1}명', list.overdueList.length) }}
				</h4>
				<h4 class="ml-auto" v-else>{{ tt('없음') }}</h4>
			</div>
			<div class="box-content">
				<ul>
					<li v-for="item in list.overdueList" :key="item.id" @click="goDetail(item.id)">
						<div class="imgbox">
							<img :src="item.image ? item.image : require('@/assets/svg/member-photo-default.svg')" alt="people" />
						</div>
						<p>{{ item.name }}</p>
						<span><img src="@/assets/svg/icon-error.svg" alt="icon" /></span>
					</li>
				</ul>
			</div>
		</div>
		<div class="card-box" v-if="list.overdueList.length > 0 || list.targetList.length > 0">
			<div class="box-title">
				<h5>{{ tt('진단 대상자') }}</h5>
				<h4 class="ml-auto" v-if="list.targetList.length > 0">
					{{ tt('${1}명', list.targetList.length) }}
				</h4>
				<h4 class="ml-auto" v-else>{{ tt('없음') }}</h4>
			</div>
			<div class="box-content">
				<ul>
					<li v-for="item in list.targetList" :key="item.id" @click="goDetail(item.id)">
						<div class="imgbox">
							<img :src="item.image ? item.image : require('@/assets/svg/member-photo-default.svg')" alt="people" />
						</div>
						<p>{{ item.name }}</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'CardHealthCheckup',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Object,
			default: () => {
				return {
					unsafeCount: 0,
					overdueList: [],
					targetList: [],
				}
			},
		},
		toggleCard: {
			type: Function,
			default: () => {},
		},
	},
	mounted() {
		this.displayCard = this.visible
	},
	data() {
		return {
			displayCard: true,
		}
	},
	methods: {
		goPage() {
			this.$router.push({
				name: PageName.Safety.HealthCheck,
			})
		},
		goDetail(id) {
			this.$router.push({
				name: PageName.Safety.HealthCheckDetail,
				params: {
					id: id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';
</style>
