<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper">
			<input type="number" :placeholder="tt(placeholder)" v-model="inputValue" @input="handleChange" />
		</div>
		<span class="error" v-if="error">{{ tt(errorMessage) }}</span>
	</div>
</template>

<script>
import { verifyInteger } from '@/utils/modules/VerifyForm.js'
export default {
	name: 'FormNumber',
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputValue: '',
		}
	},
	computed: {
		error() {
			return this.required && this.isShowError && this.inputValue !== null
		},
	},
	watch: {
		value() {
			if (this.inputValue !== this.value) this.inputValue = this.value
		},
	},
	mounted() {
		if (this.inputValue !== this.value) this.inputValue = this.value
	},
	methods: {
		handleChange(e) {
			// e.target.value = verifyInteger(e.target.value)
			this.inputValue = e.target.value
			this.$emit('input', this.inputValue)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-input.scss';
</style>
