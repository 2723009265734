<template>
	<div class="inputWrapper">
		<div class="storage step">
			<h5>{{ tt('보관함 등록하기') }}</h5>
			<div class="storage-item" v-for="(storage, index) in storageList" :key="index">
				<h1>{{ storage }}</h1>
				<button :id="index" @click="deleteStorage(index)">
					<img src="@/assets/svg/Close-1.svg" />
				</button>
			</div>
			<div>
				<input type="text" ref="input" @keypress.enter="addStorageList" v-model="inputStorageValue" :placeholder="tt('예: 위험물보관함')" />
				<span v-if="isValid && storageList.length == 0" class="info">{{ tt('엔터키를 눌러 목록에 추가하세요') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		createStorageList: {
			type: Array,
		},
	},
	created() {
		this.storageList = this.createStorageList
	},
	mounted() {
		this.$refs.input.focus()
	},
	data() {
		return {
			storageList: [],
			inputStorageValue: '',
			isValid: true,
		}
	},
	methods: {
		addStorageList(e) {
			if (this.inputStorageValue == '') {
				return
			}
			this.isValid = true
			this.storageList.push(this.inputStorageValue)
			this.inputStorageValue = ''
			this.$emit('changeStorageList', this.storageList)
		},
		deleteStorage(index) {
			this.storageList.splice(index, 1)
			this.$emit('changeStorageList', this.storageList)
		},
	},
}
</script>

<style lang="scss" scoped>
.inputWrapper {
	h5 {
		padding-bottom: 5px;
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	input[type='text'] {
		width: 700px;
		height: 48px;
		border: 1px solid #e6e6e6;
		border-radius: 5px;
		padding: 0 12px;
		&:focus {
			border-color: #000;
		}
		&.error {
			border-color: $RED;
		}
		&.error ~ .noti {
			display: block;
		}
	}
	.noti {
		width: 100%;
		display: none;
		font-size: 0.75rem;
		color: $RED;
		font-weight: 400;
		background-image: url(~@/assets/svg/noti-error.svg);
		padding-left: 16px;
		background-position: center left 0;
		background-repeat: no-repeat;
		margin-top: 10px;
	}
	.info {
		width: 100%;
		display: flex;
		font-size: 0.75rem;
		color: $BLUE;
		font-weight: 400;
		background-position: center left 0;
		background-repeat: no-repeat;
		margin-top: 10px;
	}
	.storage-item {
		width: 700px;
		display: flex;
		align-items: center;
		height: 48px;
		border: 1px solid #e6e6e6;
		background-color: $LAB_WHITE;
		font-size: 1rem;
		border-radius: 5px;
		padding: 0 12px;
		margin-bottom: 10px;
		h1 {
			font-size: inherit;
			font-weight: 400;
		}
		button {
			margin-left: auto;
			width: 24px;
			height: 24px;
			margin-right: 2px;
			img {
				width: inherit;
				height: inherit;
				margin-bottom: 0;
			}
		}
	}
}
</style>
