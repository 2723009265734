var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-header"
  }, [_c('th', {
    "class": _vm.orderClass('CODE'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('CODE');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('예산 번호')))])]), _c('th', {
    "class": _vm.orderClass('NAME'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('NAME');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('예산 이름')))])]), _vm.isView('JoinedGroupRepName') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('참여 그룹')))])]) : _vm._e(), _vm.isView('budget') ? _c('th', {
    "class": _vm.orderClass('BUDGET'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('BUDGET');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('총 예산 합계')))])]) : _vm._e(), _vm.isView('expenditure') ? _c('th', {
    "class": _vm.orderClass('EXPENDITURE'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('EXPENDITURE');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('총 지출 합계')))])]) : _vm._e(), _vm.isView('balance') ? _c('th', {
    "class": _vm.orderClass('BALANCE'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('BALANCE');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('총 잔액 합계')))])]) : _vm._e(), _c('th', {
    "class": _vm.orderClass('CREATE_DATETIME'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('CREATE_DATETIME');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('등록일시')))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }