import { lookupMigrationLab, lookupMigrationUser, requestMigration } from '@/services/api/migration'
import axiosInstance from '@/services/axios'

const index = {
	namespaced: true,
	state: () => ({
		selectedLab: [],
		legacyLab: [],
		instituteName: '',
		migrationMember: [],
	}),
	getters: {
		selectedLabCount: state => state.selectedLab.length,
	},
	mutations: {
		initSelectedLab: state => (state.selectedLab = []),
		initInstituteName: state => (state.instituteName = ''),
		setSelectedLab: (state, payload) => (state.selectedLab = [...payload]),
		setLegacyLab: (state, payload) => (state.legacyLab = [...payload]),
		setInstituteName: (state, payload) => (state.instituteName = payload),
		setMigrationMember: (state, payload) => (state.migrationMember = [...payload]),
	},
	actions: {
		loadLegacyLab: ({ commit }, payload) => {
			return lookupMigrationLab().then(response => {
				commit('setLegacyLab', response.data.list)
			})
		},
		loadMigrationUser: ({ commit, state }) => {
			return lookupMigrationUser({
				labIds: [...state.selectedLab],
			}).then(response => {
				commit('setMigrationMember', response.data)
			})
		},
		requestMigration: ({ commit, state }) => {
			return requestMigration({
				instituteName: state.instituteName,
				labIds: state.selectedLab,
			})
		},
		initMigrationData: ({ commit }) => {
			commit('initSelectedLab')
			commit('initInstituteName')
		},
	},
}
export default index
