<template>
	<b-modal ref="modal" centered size="lg" scrollable no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">
				{{ tt('필터') }}
				<SearchFilter v-if="hasSearch" />
			</div>
		</template>
		<slot name="modal-content"> </slot>
		<template #modal-footer>
			<div class="footer-left">
				<button class="button-white" @click="$emit('reset')">
					{{ tt('전체 초기화') }}
				</button>
			</div>
			<div class="footer-right">
				<button :class="`button-primary`" @click="$emit('confirm')">
					{{ tt('적용') }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import SearchFilter from '@/views/components/searchBox/filter.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
	components: {
		SearchFilter,
	},
	props: {
		hasSearch: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('filter', ['selected']),
		body() {
			return this.$refs['modal'].$refs['body']
		},
	},
	methods: {
		...mapMutations('filter', ['initData']),
		show() {
			this.initData()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-base.scss';
::v-deep {
	.modal-dialog {
		max-width: 700px;
	}
	.modal-content {
		min-height: 580px;
		// padding: 0px;
	}
	.modal-title {
		width: 100%;
	}
	.title {
		width: 100%;
		display: flex;
		align-items: center;
		.lm-sb-wrapper {
			margin-left: auto;
			margin-right: 16px;
		}
	}
}
</style>
