<template>
	<div class="SafetyDangerStorage">
		<div class="title-wrap" style="height: 55px">
			<h2 class="AppContainer__title" style="padding-top: 0">
				{{ tt('위험물 지정수량 배수') }}
			</h2>
			<div class="switch-box">
				<h6>{{ tt('재고 있는 품목만 보기') }}</h6>
				<label class="switch">
					<input ref="showOnlyExist" type="checkbox" :checked="showOnlyExist" @change="changeExist" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="AppContainer__tabs" v-if="dangerStorageList.length > 0">
			<div class="tab-inner">
				<span v-for="item in dangerStorageList" :key="item.id">
					<input type="radio" :checked="item.id == tab" name="safetyDangerousArea" :id="'area' + item.id" @click="selectTab(item.id)" />
					<label :for="'area' + item.id">
						<img src="@/assets/svg/icon-safe.svg" v-if="item.status === 'SAFE'" />
						<img src="@/assets/svg/icon-error.svg" v-else />
						{{ item.name }}
						<img v-if="item.isNew" style="margin-left: 4px; width: 14px; height: 14px" src="@/assets/svg/new.svg" />
					</label>
				</span>
			</div>
		</div>
		<div class="AppContainer">
			<div class="Filter__box">
				<search-box
					style="width: 300px"
					noRecent
					ref="search-box"
					class="search-box"
					:placeholder="tt('검색')"
					:value="keyword"
					@change="changeKeyword"
				></search-box>
				<div class="right-btn-group">
					<button class="btn" @click="showManagement" v-if="userPermission.safe && userPermission.safe.dangerstorage.create">
						<img src="@/assets/svg/authority-setting.svg" alt="icon" />{{ tt('저장소 관리') }}
					</button>
					<button class="btn" @click="showModalSetting(tab)" v-if="userPermission.safe && userPermission.safe.dangerstorage.create">
						<img src="@/assets/svg/authority-setting.svg" alt="icon" />{{ tt('지정수량 배수 설정') }}
					</button>
					<button class="btn" @click="downloadExcel">
						<img src="@/assets/svg/icon-excel.svg" alt="icon" />{{ tt('위험물 현황 다운로드') }}
					</button>
				</div>
			</div>
			<div class="Table__wrap scroll horizational-scroll" :class="{ experience: isAppModeExperience }" ref="tables">
				<table>
					<thead>
						<tr>
							<th width="150">{{ tt('유별') }}</th>
							<th width="180">{{ tt('품명') }}</th>
							<th width="150">{{ tt('재고량') }}</th>
							<th width="100">{{ tt('최대 저장량') }}</th>
							<th width="100">{{ tt('배수') }}</th>
							<th width="560">{{ tt('그래프') }}</th>
						</tr>
					</thead>
					<tbody v-if="statusList.length > 0">
						<template v-for="(item, idx) in statusList">
							<tr
								v-for="(child, index) in item.childLegalList"
								:key="child.legalId"
								:ref="child.legalId"
								:class="{
									active: activeTr === child.legalId && tab == multipleId,
								}"
							>
								<!-- 유별 -->
								<td :rowspan="item.childLegalList.length" v-if="index == 0" class="category">
									{{ tt(item.legalName) }}
								</td>
								<!-- 품명 -->
								<td @click="goInventory(item.legalName, child)" class="move-page">
									{{ tt(child.legalName) }}
								</td>
								<!-- 재고량 -->
								<td>
									<p class="stock" :class="comparisionAmount(child.stockVolume, child.limitVolume) > 1 ? 'safe' : 'error'">
										{{ commaVolume(child.stockVolume) }}{{ child.volumeUnit }}
										<span v-if="comparisionAmount(child.stockVolume, child.limitVolume) < 1">{{ tt('저장량 초과') }}</span>
									</p>
								</td>
								<!-- 최대 저장량 -->
								<td>
									<p class="store">
										{{ commaVolume(child.limitVolume) }}
										{{ child.volumeUnit }}
									</p>
								</td>
								<!-- 배수 -->
								<td>
									<button
										:class="{
											notClick: userPermission.safe && !userPermission.safe.dangerstorage.create,
										}"
										class="Btn__hover-balloon-w"
										@click="
											userPermission.safe && userPermission.safe.dangerstorage.create
												? showModalSetting(tab, item.legalId, child)
												: ''
										"
									>
										<span>{{ child.multiple }}</span>
										<div class="balloon">
											<p>
												{{ tt('클릭해서 지정수량 배수를 관리할 수 있습니다') }}
											</p>
										</div>
									</button>
								</td>
								<!-- 그래프 -->
								<td>
									<div class="lm-progress-bar-wrap">
										<div class="lm-progress-bar" :ref="`lm-progress-bar` + idx + index">
											<div
												class="stock-tooltip"
												:ref="`stock-tooltip` + idx + index"
												:class="comparisionAmount(child.stockVolume, child.limitVolume) > 1 ? 'safe' : 'error'"
											>
												{{ tt('재고량') }}: {{ commaVolume(child.stockVolume) }}{{ child.volumeUnit }}
												<i class="stock-arrow" :ref="`stock-arrow` + idx + index"></i>
											</div>
											<div class="available" :ref="`available` + idx + index"></div>
											<div
												class="non-available"
												:ref="`non-available` + idx + index"
												:class="comparisionAmount(child.stockVolume, child.limitVolume) > 1 ? 'hide' : ''"
											></div>
											<div class="store-notice" :ref="`store-notice` + idx + index">
												<i :ref="`notice-arrow` + idx + index"></i>
												<span>{{ tt('최대 저장량') }}: {{ commaVolume(child.limitVolume) }}{{ child.volumeUnit }}</span>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
				<lm-modal-loading v-if="isLoading" :play="isLoading" height="calc(100% - 40px)"></lm-modal-loading>
				<no-data-list
					v-else-if="statusList.length == 0 && !isLoading"
					:image="noData.image"
					:title="noData.title"
					:content="noData.content"
				></no-data-list>
			</div>
		</div>

		<modal-quantity-multiple-setting ref="modal-quantity-multiple-setting" :storageList="dangerStorageList" @hide="hideSetting">
		</modal-quantity-multiple-setting>
		<modal-management-storage ref="modal-management-storage" @loadList="loadStorage" @loadSummary="loadSummary"></modal-management-storage>
		<ModalConfirm
			ref="modalDownload"
			title="관리대장 다운로드"
			:content="`다음의 이메일 주소로 관리대장 다운로드 링크를 전송합니다.<br/>이메일 주소: ${$root.user.email}<br/><br/>파일 크기에 따라 이메일 수신은 최대 30분 소요될 수 있습니다.`"
			confirmText="전송"
		/>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import dangerStorageAPI from '@/services/api/safety/dangerStorage'
import PageName from '@/utils/define/PageName'
import SearchBox from '@/views/components/searchBox'
import ModalQuantityMultipleSetting from '@/views/safety/dangerStorage/modals/ModalQuantityMultipleSetting.vue'
import ModalManagementStorage from '@/views/safety/dangerStorage/modals/ModalManagementStorage.vue'
import LmModalLoading from '@/views/common/components/LmModalLoading.vue'
import NoDataList from '@/views/common/components/NoDataList.vue'

export default {
	name: 'SafetyDangerStorage',
	components: {
		SearchBox,
		'modal-quantity-multiple-setting': ModalQuantityMultipleSetting,
		'modal-management-storage': ModalManagementStorage,
		LmModalLoading,
		NoDataList,
	},
	async mounted() {
		if (this.previousPage?.name !== PageName.Safety.DangerousStorageDetail) {
			this.initParams()
			await this.loadStorage()
		} else {
			await this.loadDangerStorage()
			await this.getStatusList()
		}
	},
	created() {},
	computed: {
		...mapGetters('dangerStorage', ['tab', 'dangerStorageList', 'statusList', 'keyword', 'count', 'showOnlyExist']),
	},
	watch: {
		statusList(val) {
			console.log(val)
			if ((val?.length ?? 0) == 0) {
				this.noData = {
					image: 'list',
					title: '저장소가 없습니다',
					content: '위험물 지정수량 배수를 관리하려면<br><b>[저장소 관리]-[저장소 추가]</b>버튼을 클릭하여 저장소를 추가해주세요',
				}
				if ((this.keyword?.length ?? 0) > 0) {
					this.noData = {
						image: 'search',
						title: '조회된 결과가 없습니다.',
						content: '다시 한번 확인해 주세요.',
					}
				}
			}
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
	data() {
		return {
			availableBarWidth: null,
			nonAvailableBarWidth: null,
			displaySetting: false,
			displayManagement: false,
			isLoading: false,
			activeTr: null,
			multipleId: null,
			noData: {
				image: '',
				title: '',
				content: '',
			},
			previousPage: null,
		}
	},
	methods: {
		...mapMutations('dangerStorage', ['initParams']),
		...mapActions('dangerStorage', ['getDangerStorage', 'setSetting', 'getStorageStatusList', 'exportExcel']),
		async loadStorage() {
			this.isLoading = true
			let tab = null
			await this.loadDangerStorage()
			if (this.$route.query.storage) {
				this.dangerStorageList.forEach(x => {
					if (this.$route.query.storage == x.name) {
						tab = x.id
					}
				})
			} else {
				if (this.dangerStorageList.length > 0) {
					tab = this.dangerStorageList[0].id
				}
			}
			await this.getStatusList({ id: tab })
			this.isLoading = false
		},
		async loadDangerStorage() {
			await this.getDangerStorage()
		},
		async changeKeyword(value) {
			await this.getStatusList({ keyword: value })
		},
		async changeExist() {
			await this.getStatusList({ showOnlyExist: this.$refs['showOnlyExist'].checked })
		},
		async selectTab(selectItem) {
			await this.getStatusList({ id: selectItem })
		},
		async getStatusList(params = {}) {
			this.isLoading = true
			await this.getStorageStatusList(params)
			await this.resizeWidth()
			this.isLoading = false
		},
		showModalSetting(storageId, category, item) {
			this.$refs['modal-quantity-multiple-setting'].show(storageId, category, item)
		},
		showManagement() {
			this.$refs['modal-management-storage'].show()
		},
		hideManagement() {
			this.displayManagement = false
		},
		comparisionAmount(stock, store) {
			const availableBarPercent = store / stock

			return availableBarPercent
		},
		commaVolume(obj) {
			let volume = String(obj)

			var regx = new RegExp(/(-?\d+)(\d{3})/)
			var bExists = volume.indexOf('.', 0) //0번째부터 .을 찾는다.
			var strArr = volume.split('.')
			while (regx.test(strArr[0])) {
				//문자열에 정규식 특수문자가 포함되어 있는지 체크
				//정수 부분에만 콤마 달기
				strArr[0] = strArr[0].replace(regx, '$1,$2') //콤마추가하기
			}

			if (bExists > -1) {
				//. 소수점 문자열이 발견되지 않을 경우 -1 반환
				volume = strArr[0] + '.' + strArr[1]
			} else {
				//정수만 있을경우 //소수점 문자열 존재하면 양수 반환
				volume = strArr[0]
			}
			return volume
		},
		resizeAvailableBarWidth(stock, store) {
			const wholeAmount = store + stock

			if (stock < store) {
				return (stock / store) * 100
			} else {
				return (store / wholeAmount) * 100
			}
		},
		resizeWidth() {
			const list = this.statusList

			if (list.length > 0) {
				for (let i = 0; i < list.length; i++) {
					for (let j = 0; j < list[i].childLegalList.length; j++) {
						//bar width, tooltip reposition
						const stock = list[i].childLegalList[j].stockVolume
						const limit = list[i].childLegalList[j].limitVolume

						//1. 재고량 <= 최대 저쟝량
						if (stock <= limit) {
							this.$refs['store-notice' + i + j][0].style.right = '0px'
							this.$refs['notice-arrow' + i + j][0].style.right = '0px'
							//1-1. 재고량 / 최대저장량 이 1% 미만일 경우
							if (this.resizeAvailableBarWidth(stock, limit) < 1) {
								this.$refs['available' + i + j][0].style.width = '1%'
								//1-2. 재고량 / 최대저쟝량 이 1% 이상인 경우
							} else {
								this.$refs['available' + i + j][0].style.width = this.resizeAvailableBarWidth(stock, limit) + '%'
							}
							//2. 재고량 > 최대저쟝량인 경우
						} else if (stock > limit) {
							this.$refs['available' + i + j][0].style.width = this.resizeAvailableBarWidth(stock, limit) + '%'
							this.$refs['non-available' + i + j][0].style.width = 100 - this.resizeAvailableBarWidth(stock, limit) + '%'
							this.$refs['store-notice' + i + j][0].style.left = this.resizeAvailableBarWidth(stock, limit) - 1 + '%'
							this.$refs['store-notice' + i + j][0].style.marginLeft =
								'-' + this.$refs['store-notice' + i + j][0].clientWidth / 2 + 'px'
							this.$refs['notice-arrow' + i + j][0].style.left = 'calc(50% + 1px)'
							if (this.resizeAvailableBarWidth(stock, limit) < 1) {
								this.$refs['store-notice' + i + j][0].style.left = '0'
								this.$refs['notice-arrow' + i + j][0].style.left = '0'
								this.$refs['store-notice' + i + j][0].style.marginLeft = '0'
							}

							if (this.resizeAvailableBarWidth(stock, limit) > 97) {
								this.$refs['store-notice' + i + j][0].style.left = 'unset'
								this.$refs['store-notice' + i + j][0].style.right = '-2px'
								this.$refs['notice-arrow' + i + j][0].style.left = 'unset'
								this.$refs['notice-arrow' + i + j][0].style.right = '-2px'
							}
						}

						//balloon
						if (this.resizeAvailableBarWidth(list[i].childLegalList[j].stockVolume, list[i].childLegalList[j].limitVolume) < 33) {
							this.$refs['stock-tooltip' + i + j][0].style.left =
								this.resizeAvailableBarWidth(list[i].childLegalList[j].stockVolume, list[i].childLegalList[j].limitVolume) - 2 + '%'
							this.$refs['stock-arrow' + i + j][0].style.left = '5px'
						} else if (this.resizeAvailableBarWidth(list[i].childLegalList[j].stockVolume, list[i].childLegalList[j].limitVolume) < 67) {
							this.$refs['stock-tooltip' + i + j][0].style.left =
								this.resizeAvailableBarWidth(list[i].childLegalList[j].stockVolume, list[i].childLegalList[j].limitVolume) + '%'
							this.$refs['stock-tooltip' + i + j][0].style.marginLeft =
								'-' + this.$refs['stock-tooltip' + i + j][0].clientWidth / 2 + 'px'
							this.$refs['stock-arrow' + i + j][0].style.left = 'calc(50% - 6px)'
						} else {
							this.$refs['stock-tooltip' + i + j][0].style.right = '0px'
							this.$refs['stock-arrow' + i + j][0].style.right = '5px'
						}
					}
				}
			}
		},
		async hideSetting(payload) {
			this.activeTr = null
			this.multipleId = null

			const response = await this.setSetting(payload)

			if (response.status == 205) {
				this.$refs['modal-quantity-multiple-setting'].hide()
				// this.showOnlyExist = false
				await this.getStatusList({ id: payload.id })
				const tr = this.$refs[payload.legalId][0]
				tr.scrollIntoView({ behavior: 'smooth', block: 'center' })
				this.activeTr = payload.legalId
				this.multipleId = payload.id
				const toastTitle = this.tt('지정수량 배수 설정')
				const toastContent = this.tt('배수 설정이 완료되었습니다')
				this.$root.toast(toastTitle, toastContent, 'success')
			}
		},
		formatDate(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear()
			if (month.length < 2) {
				month = '0' + month
			}
			if (day.length < 2) {
				day = '0' + day
			}
			return [year, month, day].join('-')
		},
		async downloadExcel() {
			this.$refs['modalDownload'].show(async () => {
				try {
					const response = await this.exportExcel({
						email: this.$root.user.email,
					})
					if (response.status == '200') {
						this.$root.toast(this.tt('전송 완료'), this.tt('위험물 현황을 메일로 전송하였습니다'), 'success')
					} else if (response.status === '404') {
						this.$root.toast(this.tt('관리대장 기록 없음'), this.tt('위험물 현황 기록이 없습니다.'), 'error')
					}
				} catch (e) {
					console.warn(e)
				}
			})
			// const response = await this.exportExcel()
			// window.fileDownload(response, this.formatDate(new Date()) + `_` + this.tt('위험물 지정수량 배수 리스트.xlsx'))
		},
		goInventory(name, item) {
			const locations = this.dangerStorageList.find(it => it.id == this.tab)?.locations ?? []
			this.$router.push({
				name: PageName.Safety.DangerousStorageDetail,
				params: {
					legalName: name + '/' + item.legalName,
				},
				query: {
					detailLegalsId: [item.legalId],
					locationsId: locations.map(it => it.id),
				},
			})
		},
		loadSummary() {
			this.$emit('loadSummary')
		},
	},
}
</script>

<style lang="scss" scoped>
.SafetyDangerStorage {
	.title-wrap {
		width: 100%;
		display: flex;
		align-items: center;
		height: auto;
		.switch-box {
			margin-left: auto;
			margin-right: 30px;
			display: flex;
			align-items: center;
			height: auto;
			padding: 9px 15px;
			background: $SUB-YELLOW_100;
			border-radius: 10px;
			h6 {
				font-size: 0.875rem;
				color: rgba(0, 0, 0, 0.87);
				font-weight: 700;
				margin-right: 10px;
			}
			.switch {
				margin-bottom: 0px;
			}
		}
	}
	.AppContainer__tabs {
		label {
			img {
				margin-right: 5px;
				margin-bottom: -1px;
			}
		}
	}
	.Table__wrap {
		overflow: initial;
		overflow-x: auto;
		overflow-y: auto;
		height: calc(100vh - 250px);
		table {
			thead {
				z-index: 100;
				tr {
					th {
						min-width: 150px;
						width: 150px;
						max-width: 150px;
						border-right: 1px solid $DIVIDER;
						text-align: center;
						word-break: keep-all;
						white-space: unset;
						&:last-child {
							border-right: 0;
						}
					}
				}
			}
			tbody {
				tr {
					&:hover {
						background-color: $LAB_WHITE;
					}
				}
				tr {
					&.active {
						background-color: $SUB-YELLOW_100;
						.category {
							background-color: $LAB_WHITE;
						}
					}
					.type {
						background-color: $LAB_WHITE;
					}
					&:hover {
						.type {
							background-color: $LAB_WHITE;
						}
					}
					td {
						min-width: unset;
						width: unset;
						max-width: unset;
						border-right: 1px solid $DIVIDER;
						text-align: center;
						&:last-child {
							border-right: 0;
						}
						&.move-page {
							cursor: pointer;
						}
						.stock {
							font-weight: bold;
							&.safe {
								color: $GREEN;
							}
							&.error {
								color: $RED;
							}
							span {
								font-weight: 400;
								font-size: 10px;
								color: $RED;
								display: block;
							}
						}
						.store {
							color: $MANAGE_ORANGE;
							font-weight: 400;
						}
						.Btn__hover-balloon-w {
							width: 100%;
							justify-content: center;
							> span {
								padding-bottom: 0px;
								border-bottom: 1px solid #000;
								display: inline-block;
								&:hover {
									font-weight: bold;
								}
							}
							.balloon {
								left: unset;
								right: 36px;
								width: 290px;
								&:before {
									left: unset;
									right: 20px;
								}
								&:after {
									left: unset;
									right: 21px;
								}
							}
							&:hover {
								.balloon {
									opacity: 1;
									visibility: visible;
								}
							}
							&:focus {
								.balloon {
									opacity: 0;
									visibility: hidden;
								}
							}
						}
					}
				}
			}
		}
	}
	.lm-progress-bar-wrap {
		width: calc(100% - 80px);
		max-width: 590px;
		min-width: 590px;
		margin: 0 auto;
		padding: 30px 0 20px;
		.stock-tooltip {
			padding: 2px 8px;
			display: inline-block;
			border-radius: 5px;
			color: $LAB_WHITE;
			font-weight: 700;
			font-size: 0.75rem;
			height: 22px;
			position: absolute;
			top: -30px;
			margin-bottom: 10px;
			i {
				border-bottom: 0px solid transparent;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				content: '';
				position: absolute;
				top: 20px;
				display: block;
			}
			&.safe {
				background: $GREEN;
				i {
					border-top: 6px solid $GREEN;
				}
			}
			&.error {
				background: $RED;
				i {
					border-top: 6px solid $RED;
				}
			}
		}
		.store-notice {
			font-size: 10px;
			font-weight: 400;
			text-align: center;
			color: $MANAGE_ORANGE;
			margin-top: 10px;
			position: absolute;
			display: inline-block;
			bottom: -25px;
			i {
				border-bottom: 6px solid $MANAGE_ORANGE;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: 0px solid transparent;
				content: '';
				position: absolute;
				top: -8px;
				display: block;
			}
		}
		.lm-progress-bar {
			width: 100%;
			height: 16px;
			background-color: #e4e5ea;
			border-radius: 4px;
			display: flex;
			align-items: center;
			position: relative;
			.available {
				height: inherit;
				background-color: $GREEN;
				border-radius: 4px 0 0 4px;
			}
			.non-available {
				height: inherit;
				background: linear-gradient(90deg, #57c17e 0%, $LAB_YELLOW 0.51%, #ff3b31 15.85%);
				border-radius: 0 4px 4px 0;
				&.hide {
					visibility: hidden;
				}
			}
		}
	}
}

.notClick {
	text-decoration: unset !important;
	span {
		border-bottom: 0 !important;
		&:hover {
			font-weight: normal !important;
			cursor: default !important;
		}
	}
	.balloon {
		display: none;
	}
}
</style>
