import Decimal from 'decimal.js'

const state = {
	item: null,
	data: [{ key: 0, count: null, volume: null }],
}

const getters = {
	data: state => state.data,
	// remainVolume: state => state.
	usedVolume: state => {
		let result = 0
		state.data.forEach(item => {
			result += Decimal.mul(item.volume ?? 0, item.count ?? 0).toNumber()
		})
		return result
	},
}

const mutations = {
	setInit: state => {
		state.data = [{ key: 0, count: null, volume: null }]
		state.item = null
	},
	setItem: (state, item) => {
		state.item = item
	},
	setData: (state, data) => {
		state.data = data
	},
}

const actions = {}

export default { namespaced: true, state, getters, mutations, actions }
