var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "TreeView"
    }
  }, [_c('DraggableTree', {
    ref: "tree",
    attrs: {
      "data": _vm.treeData,
      "width": "".concat(_vm.width, "px"),
      "height": "100%",
      "draggable": _vm.draggable
    },
    on: {
      "click": _vm.click,
      "change": _vm.change
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var data = _ref.data,
            store = _ref.store;
        return _c('div', {
          staticClass: "checker",
          attrs: {
            "id": "checker_".concat(data.id)
          },
          on: {
            "dragenter": function dragenter($event) {
              $event.stopPropagation();
              ;
              (_vm.isStoragePage || _vm.isStartPage) && _vm.storageDragenter($event, data);
            },
            "dragover": function dragover($event) {
              $event.stopPropagation();
              ;
              (_vm.isStoragePage || _vm.isStartPage) && _vm.storageDragover($event, data);
            },
            "dragleave": function dragleave($event) {
              $event.stopPropagation();
              ;
              (_vm.isStoragePage || _vm.isStartPage) && _vm.storageDragleave($event, data);
            },
            "drop": function drop($event) {
              $event.stopPropagation();
              ;
              (_vm.isStoragePage || _vm.isStartPage) && _vm.moveStorage($event, data);
            }
          }
        }, [!data.isDragPlaceHolder ? [data[_vm.childrenField] && data[_vm.childrenField].length ? _c('button', {
          "class": data.open ? 'unfold' : 'folded',
          on: {
            "click": function click($event) {
              return _vm.open($event, store, data);
            }
          }
        }, [_vm._v(" " + _vm._s(data.open ? '-' : '+') + "  ")]) : _vm._e(), (_vm.page == 'organization' || _vm.modal == 'group') && _vm.isRoot(data.id) ? _c('span', {
          staticClass: "icon",
          staticStyle: {
            "margin-left": "15px"
          }
        }, [_c('img', {
          staticClass: "instituteImage",
          attrs: {
            "src": require("@/assets/svg/institution-building_y.svg")
          }
        })]) : (_vm.page == 'organization' || _vm.modal == 'group') && !_vm.isRoot(data.id) ? _c('span', {
          staticClass: "icon",
          staticStyle: {
            "margin-left": "15px"
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/svg/group_y.svg")
          }
        })]) : _vm.page == 'location' || _vm.modal == 'location' && !data.storage ? _c('span', {
          staticClass: "icon",
          staticStyle: {
            "margin-left": "15px"
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/svg/location_y.svg")
          }
        })]) : _vm._e(), _vm.page == 'storage' || data.storage && _vm.modal == 'location' ? _c('span', {
          staticClass: "icon storage",
          staticStyle: {
            "margin-left": "15px"
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/svg/storage_y.svg")
          }
        })]) : _vm._e(), _c('div', {
          staticClass: "node-text",
          "class": {
            'root-text': _vm.isRoot(data.id),
            storage: data.storage
          },
          domProps: {
            "innerHTML": _vm._s(_vm.setMarkTag(data[_vm.textField]))
          }
        }), _vm.isNotUndefined(data[_vm.subTextField]) ? _c('div', {
          staticClass: "node-sub-text"
        }, [_vm._v(_vm._s(data[_vm.subTextField]) + _vm._s(_vm.subTextEndWord))]) : _vm._e(), _vm.page && data.isNew ? _c('div', {
          staticClass: "new-img"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/svg/new.svg")
          }
        })]) : _vm._e(), _vm.mode === 'selectable' && (!_vm.isRoot(data.id) || _vm.modal == 'location') || _vm.mode === 'multiStorage' && data.storage || _vm.mode === 'STORAGE' && _vm.modal === 'location' && !_vm.isRoot(data.id) ? _c('input', {
          attrs: {
            "type": "checkbox",
            "id": "treeviewCheckbox".concat(data.id)
          },
          domProps: {
            "value": data.id,
            "checked": _vm.activeIdList.includes(data.id)
          }
        }) : _vm._e(), _vm.mode === 'selectable' && (!_vm.isRoot(data.id) || _vm.modal == 'location') || _vm.mode === 'multiStorage' && data.storage || _vm.mode === 'STORAGE' && _vm.modal === 'location' && !_vm.isRoot(data.id) ? _c('label', {
          staticClass: "checkboxLabel",
          attrs: {
            "for": "treeviewCheckbox".concat(data.id)
          },
          on: {
            "click": function click($event) {
              return _vm.click(store, data);
            }
          }
        }) : _vm._e(), _vm.mode === 'storage' && data.storage || _vm.mode === 'storageGroup' ? _c('input', {
          attrs: {
            "type": "radio",
            "id": "treeviewCheckbox".concat(data.id)
          },
          domProps: {
            "value": data.id,
            "checked": _vm.activeIdList.includes(data.id)
          }
        }) : _vm._e(), _vm.mode === 'storage' && data.storage || _vm.mode === 'storageGroup' ? _c('label', {
          staticClass: "radioLabel",
          attrs: {
            "for": "treeviewCheckbox".concat(data.id)
          },
          on: {
            "click": function click($event) {
              return _vm.click(store, data);
            }
          }
        }) : _vm._e()] : _vm._e()], 2);
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }