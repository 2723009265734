var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "registeringOrganization"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('조직도 설정하기')))])]), _c('div', {
    staticClass: "boxwrap"
  }, [_c('div', {
    staticClass: "organization-tree"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('lm-input-search', {
    attrs: {
      "placeholder": _vm.tt('그룹명으로 검색')
    },
    on: {
      "search": _vm.searchTree
    }
  }), _c('div', {
    staticClass: "right-btn-group"
  }, [_c('button', {
    staticClass: "btn",
    "class": {
      point: _vm.order == 1 || _vm.isHint
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg//button-icon-lisence-copy.svg"),
      "alt": "icon"
    },
    on: {
      "click": _vm.openModalAddGroup
    }
  }), _c('span', {
    on: {
      "click": _vm.openModalAddGroup
    }
  }, [_vm._v(_vm._s(_vm.tt('생성')))]), _vm.order == 1 || _vm.isHint ? _c('div', {
    staticClass: "order-balloon"
  }, [_c('div', {
    staticClass: "order-top"
  }, [_vm._m(0), !_vm.isHint ? _c('button', {
    on: {
      "click": _vm.closeOrder
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })]) : _vm._e()]), _c('h6', [_vm._v(" " + _vm._s(_vm.tt('1. 연구실/팀/부서 등록하기')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('그룹(연구실/팀/부서)을 등록해주세요')) + " ")])]) : _vm._e()]), _c('button', {
    staticClass: "btn ml-10",
    on: {
      "click": _vm.showModalGroupDelete
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Trash.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('삭제')) + " ")])])], 1), _c('div', {
    ref: "chart-main-container",
    staticClass: "OrganizationTree__main-container",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_vm.treeData ? _c('page-tree-view', {
    ref: "page-tree-view",
    attrs: {
      "textField": "name",
      "subTextField": "membersCount",
      "treeData": _vm.treeData,
      "subTextEndWord": _vm.tt('명'),
      "keyword": _vm.keyword,
      "width": 800,
      "userPermission": _vm.userPermission
    },
    on: {
      "changeActive": _vm.changeActiveId,
      "changeTree": _vm.changeTree
    }
  }) : _vm._e()], 1)]), _vm.selectedGroupInfo ? _c('tree-info-area', {
    ref: "tree-info-area",
    attrs: {
      "isStart": true,
      "groupInfo": _vm.selectedGroupInfo,
      "isHint": _vm.isHint,
      "order": _vm.order,
      "userPermission": _vm.userPermission
    },
    on: {
      "refresh": _vm.refreshData,
      "changeOrderGroup": _vm.changeOrderGroup,
      "changeOrderAccess": _vm.changeOrderAccess,
      "completeOrganization": _vm.complete,
      "closeOrder": _vm.closeOrder
    }
  }) : _vm._e()], 1)])]), _vm.isHint ? _c('div', {
    staticClass: "hint-popup"
  }, [_c('div', {
    staticClass: "Popup__background",
    on: {
      "click": _vm.hideHint
    }
  })]) : _vm._e(), _c('button', {
    staticClass: "Btn__hint",
    on: {
      "click": _vm.showHint
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Hint.svg")
    }
  })]), _c('modal-confirm-delete', {
    ref: "modal-confirm-delete",
    attrs: {
      "title": _vm.tt(_vm.deleteTitleText),
      "content": _vm.tt(_vm.deleteContentText)
    },
    on: {
      "refresh": _vm.refreshData
    }
  }), _c('modal-notice-organization', {
    ref: "modal-notice-organization",
    on: {
      "hideNotice": _vm.hideNotice
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order"
  }, [_c('span', {
    staticClass: "active"
  }), _c('span'), _c('span')]);
}]

export { render, staticRenderFns }