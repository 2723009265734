<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalInputCheckup">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('검진일 입력') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field">
						<p>{{ tt('최근 검진일 선택') }}</p>
						<date-picker
							v-model="date"
							valueType="format"
							:placeholder="tt('최근 검진일을 선택해주세요')"
							:disabled-date="afterToday"
							:lang="lang"
						></date-picker>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="submit" :disabled="date == null">
							{{ tt('저장') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import DatePicker from 'vue2-datepicker'

import MixinDate from '@/mixins/date'
export default {
	name: 'ModalInputCheckup',
	mixins: [MixinModal, MixinDate],
	components: {
		DatePicker,
	},
	props: {},
	data() {
		return {
			date: null,
			usersId: [],
		}
	},
	methods: {
		show(usersId) {
			this.isShow = true
			this.usersId = usersId
			this.date = null

			if (Array.isArray(usersId)) {
				this.usersId = usersId
			} else {
				this.usersId = [usersId]
			}
		},
		close() {
			this.hide()
		},
		submit() {
			this.$emit('submit', this.usersId, this.date)
		},
		afterToday(date) {
			let today = new Date()
			today.setHours(0, 0, 0, 0)

			return date > new Date(today.getTime() + 0.1 * 24 * 3600 * 1000)
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalInputCheckup {
	.Popup__content {
		.mx-datepicker {
			width: 100%;
		}
	}
}
</style>
