<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">
				{{ tt(title) }}
				<img
					class="question"
					width="24"
					height="24"
					src="@/views/purchase/cargo/svg/icon-round-question.svg"
					@click="$refs['modal-describe'].show()"
				/>
			</div>
		</template>
		<template>
			<div class="body" v-if="previewData">
				<table>
					<table-header></table-header>
					<tbody>
						<tr>
							<td class="type" v-if="data">
								<div class="content" v-if="ghs">
									<p class="sticker-type">{{ data.type }}</p>
									<p class="size">{{ ghs.paperSpec }}</p>
								</div>
								<div class="content" v-else-if="data.qrData">
									<p class="sticker-type">{{ data.type }}</p>
									<p class="size">{{ data.paperSpec }}</p>
								</div>
							</td>
							<td class="preview">
								<div class="preview-content" @click="$refs['viewer'].show()">
									<div class="preview-image" ref="previewImage" v-html="itemImage" v-if="!isTypeQr"></div>
									<div v-else class="qr-wrap">
										<img width="70" height="70" :src="itemImage" />
									</div>
									<p class="code">{{ identifierCode }}</p>
								</div>
							</td>
						</tr>
					</tbody>
					<modal-multi-image-viewer
						v-if="data"
						ref="viewer"
						title="미리보기"
						:imageList="data.image"
						:isRawSvg="!isTypeQr"
						:isCaption="isTypeQr"
						:captionList="identifierList"
						:isSingle="true"
					></modal-multi-image-viewer>
				</table>
			</div>
			<a href="https://www.law.go.kr/LSW/admRulLsInfoP.do?admRulSeq=2100000049106" target="_blank">
				<div
					class="btm-describe"
					v-html="
						tt('GHS+QR 스티커는 <b class=\'underline\'>「위험물안전관리법」시행규칙 별표 19</b>에 의하여 지정된 법령규격으로 출력됩니다.')
					"
				></div>
			</a>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<button class="button-primary" :disabled="!data" @click="exportPdf(item.name)">
					{{ tt('PDF로 출력') }}
				</button>
			</div>
		</template>
		<modal-describe-sticker ref="modal-describe"></modal-describe-sticker>
	</b-modal>
</template>
<script>
import TableHeader from './TableHeader.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ModalMultiImageViewer from '@/views/common/components/modalGen2/ModalMultiImageViewer.vue'
import ModalDescribeSticker from '@/views/inventoryGen2/components/list/modal/ModalDescribeSticker.vue'

export default {
	name: 'ModalPrintStickerSingle',
	components: { TableHeader, ModalMultiImageViewer, ModalDescribeSticker },
	props: {
		type: {
			type: String,
			default: '',
		},
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			checkedAll: false,
		}
	},
	computed: {
		...mapGetters('modal/printSticker', ['previewData', 'selectedCount']),
		title() {
			return '물품ID 스티커 출력'
		},
		data() {
			return this.previewData?.[0]
		},
		ghs() {
			return this.data?.ghs
		},
		isTypeQr() {
			return this.data?.type === 'QR'
		},
		itemImage() {
			return this.data?.image?.[0]
		},
		identifierList() {
			return [this.identifierCode]
		},
		identifierCode() {
			return this.data?.qrSticker?.[0]?.identifier.slice(0, 12)
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.resizeSvg()
	},
	methods: {
		...mapActions('modal/printSticker', ['getGhsPreview', 'setInit', 'exportPdf']),
		...mapMutations('modal/printSticker', ['setSelectedItem', 'setType']),
		async show() {
			this.setInit()
			this.setType(this.type)
			this.setSelectedItem([this.item?.id])
			this.$refs['modal'].show()
			await this.getGhsPreview([this.item?.id])
			this.resizeSvg()
		},
		hide() {
			this.$refs['modal'].hide()
			this.setType(null)
		},
		resizeSvg() {
			if (this.$refs['previewImage']) {
				this.$refs['previewImage'].childNodes[1]?.classList.add('svg')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .underline {
	text-decoration: underline;
}

table {
	background: $LAB_WHITE;
}

::v-deep .modal-header {
	padding: 15px 20px;
	border: 0;
	font-weight: 700;
	height: 54px;
	.modal-title {
		font-weight: 700;
		font-size: 16px;
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 350px;
	max-height: 700px;
	.title {
		display: flex;
		align-items: center;
		img {
			margin-top: 4px;
		}
	}
	.modal-content {
		background-color: $LAB_WHITE;
		border-radius: 8px;
		border: 0;

		.form-wrap {
			padding: 20px;
			background: white;
			border: 1px solid #ccc;
			border-radius: 8px;
			gap: 20px;
			.form-header {
				font-weight: 700;
				font-size: 16px;
				.form-title {
				}
				.form-msg {
				}
			}
		}
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	padding: 0px;
	overflow-y: auto;
	border-top: 1px solid $DIVIDER;
	background: $SYSTEM_BACKGROUND;

	.body {
		overflow-y: auto;
		overflow-x: hidden;
		.qr-counter {
			background: #fbfaf9;
			padding: 15px 20px;
			color: $LAB_BLACK;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 50px;
			&.full {
				background: linear-gradient(0deg, rgba(13, 197, 119, 0.1), rgba(13, 197, 119, 0.1)), linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
			}
		}
		.counter {
			font-size: 20px;
			font-weight: 700;
			&.full {
				color: $GREEN;
			}
		}
	}
	.btm-describe {
		position: relative;
		width: 100%;
		bottom: 0;
		height: 50px;
		padding: 7px 16px;
		text-align: center;
		font-size: 12px;
		font-weight: 400;
		background-color: #fbfaf9;
		align-items: center;
		border-top: 1px solid $DIVIDER;
		cursor: pointer;
	}
}

::v-deep .modal-footer {
	align-items: center;
	.modal-footer-wrap {
		margin: 0px;
		width: 100%;
		display: flex;
		gap: 10px;
		.button-gray {
			display: flex;
			justify-content: center;
			width: 100px;
			height: 50px;
			background-color: $GREY_2;
			font-size: 16px;
			font-weight: 700;
			border-radius: 5px;
		}
		.button-primary {
			display: flex;
			justify-content: center;
			width: 100%;
			height: 50px;
			background-color: $LAB_YELLOW;
			font-size: 16px;
			font-weight: 700;
			border-radius: 5px;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
			}
		}
	}
}

.question {
	cursor: pointer;
}

.qr-wrap {
	border: 1px solid black;
}

::v-deep .svg {
	border: 2px solid;
	width: 100px;
	height: 80px;
}

td + td {
	border-left: 1px solid $DIVIDER;
}

tr {
	display: flex;
	td {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100px;
		border-bottom: 1px solid $DIVIDER;
		&.type {
			width: 240px;
			.content {
				text-align: center;
				.sticker-type {
					color: $BLUE;
					font-size: 14px;
					font-weight: 400;
				}
				.size {
					font-size: 12px;
					font-weight: 400;
				}
			}
		}
		&.preview {
			width: 110px;
			text-align: center;
			.preview-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				.preview-image {
					width: 100px;
					height: 80px;
				}
				.code {
					font-size: 10px;
				}
			}
		}
	}
}
</style>
