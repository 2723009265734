var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    on: {
      "click": _vm.authorityDetail
    }
  }, [_vm._v(_vm._s(_vm.order + 1))]), _c('td', {
    on: {
      "click": _vm.authorityDetail
    }
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.item.name) + " "), _vm.item.isNew && !_vm.isClicked ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/new.svg")
    }
  }) : _vm._e()])]), _c('td', {
    on: {
      "click": _vm.authorityDetail
    }
  }, [_vm._v(_vm._s(_vm.item.lastUpdateDate))]), _c('td', [_c('div', {
    staticClass: "Btn__group"
  }, [_c('button', {
    staticClass: "btn arrow",
    attrs: {
      "disabled": _vm.item.order == 1 ? true : false
    },
    on: {
      "click": function click($event) {
        _vm.setLite ? _vm.showLicense() : _vm.moveTop();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding": "0"
    },
    attrs: {
      "src": require("@/assets/svg/arrow-up.svg"),
      "alt": "arrow-icon"
    }
  })]), _c('button', {
    staticClass: "btn arrow",
    attrs: {
      "disabled": _vm.item.order == _vm.total ? true : false
    },
    on: {
      "click": function click($event) {
        _vm.setLite ? _vm.showLicense() : _vm.moveDown();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding": "0"
    },
    attrs: {
      "src": require("@/assets/svg/arrow-down.svg"),
      "alt": "arrow-icon"
    }
  })]), _vm.userPermission.permission.update && _vm.userPermission.permission.create ? _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        _vm.setLite ? _vm.showLicense() : _vm.duplicateAuthority();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Duplicate.svg"),
      "alt": "복제icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('복제')) + " ")]) : _vm._e(), _vm.userPermission.permission.update ? _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        _vm.setLite ? _vm.showLicense() : _vm.editAuthority();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-edit.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('편집')) + " ")]) : _vm._e(), _vm.userPermission.permission["delete"] ? _c('button', {
    staticClass: "btn",
    attrs: {
      "disabled": _vm.item.isAlmighty
    },
    on: {
      "click": function click($event) {
        _vm.setLite ? _vm.showLicense() : _vm.deleteAuthority();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Trash.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('삭제')) + " ")]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }