// DATA: logger state 관리 - 장은학
import axiosInstance from '@/services/axios'

const index = {
	namespaced: true,
	state: {
		session: {
			user_id: '',
			remote_addr: '',
			http_user_agent: '',
			http_referer: '',
			token: '',
			time_init_server: '',
			time_init_client: '',
			timestamp_init_server: '',
			timestamp_init_client: '',
			timestamp_last_elapsed_ms: '',
		},
	},
	mutations: {
		setLoggerSession: (state, payload) => {
			const initTimeSnapshot = new Date()
			state.session.user_id = payload.id
			state.session.token = payload.token
			state.session.remote_addr = payload.remote_addr
			state.session.http_user_agent = payload.http_user_agent
			state.session.http_referer = payload.http_referer
			state.session.time_init_server = payload.time_local
			state.session.time_init_client = initTimeSnapshot.toISOString()
			state.session.timestamp_init_server = new Date(payload.time_local).getTime()
			state.session.timestamp_init_client = initTimeSnapshot.getTime()
			state.session.timestamp_last_elapsed_ms = initTimeSnapshot.getTime()
		},
		updateLastElapsedMs(state, timestamp_last_elapsed_ms) {
			state.session.timestamp_last_elapsed_ms = timestamp_last_elapsed_ms
		},
	},

	getters: {
		getSession: state => state.session,
	},
	actions: {
		initLoggerProfile: async ({ commit }, token) => {
			const res = await axiosInstance.get('https://dev-data-science.lab-manager.com/remote')
			res.data.token = token
			commit('setLoggerSession', res.data)
		},
	},
}

export default index
