<template>
	<div class="info-wrap" v-if="isIngredient">
		<p class="info-header">{{ tt('구성성분') }}</p>
		<div class="field-wrap">
			<ingredient-table :ingredient="ingredient"></ingredient-table>
		</div>
	</div>
</template>
<script>
import IngredientTable from './IngredientTable.vue'
export default {
	name: 'IngredientInfo',
	components: { IngredientTable },
	props: {
		ingredient: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		isIngredient() {
			return this.ingredient?.length > 0
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	gap: 20px;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.field-wrap {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}
}
</style>
