import axios from 'axios'

const KAFKA_ENDPOINT = 'https://dev-data-science.lab-manager.com/topics'
const KAFKA_HEADER_CONTENT_TYPE = 'application/vnd.kafka.json.v2+json'
const KAFKA_HEADER_ACCEPT = 'application/vnd.kafka.v2+json'

const msToTime = duration => {
	const milliseconds = Math.floor((duration % 1000) / 100)
	const s = Math.floor((duration / 1000) % 60)
	const m = Math.floor((duration / (1000 * 60)) % 60)
	const h = Math.floor((duration / (1000 * 60 * 60)) % 24)

	return {
		h: h,
		m: m,
		s: s,
		mill: milliseconds,
		total: duration,
	}
}

const parseRoute = route => {
	let res = route.name ? route.name : route

	if (route.name === 'InventoryList') {
		const category = route.fullPath.split('/').slice(-1)[0]
		if (category === 'etc') {
			res = 'InventorySupply'
		} else {
			res = 'Inventory' + category.charAt(0).toUpperCase() + category.slice(1)
		}
	}
	return res
}

const lmeAppRouteSerializer = (to, from, session) => {
	to = parseRoute(to)
	from = parseRoute(from)

	const event_time = new Date()
	const event_timestamp = event_time.getTime()
	const timestamp_last_elapsed_ms = session.timestamp_last_elapsed_ms

	const template = {}
	template.records = []

	const rec = {}

	rec.value = {}
	rec.value.to = to
	rec.value.from = from
	rec.value.session = {}
	rec.value.session.remote_addr = session.remote_addr
	rec.value.session.http_user_agent = session.http_user_agent
	rec.value.session.http_referer = session.http_referer
	rec.value.session.token = session.token

	rec.value.session.timestamp_init_server = session.timestamp_init_server
	rec.value.session.timestamp_init_client = session.timestamp_init_client
	rec.value.session.time_elapsed_ms = event_timestamp - timestamp_last_elapsed_ms

	if (to === 'EXIT') {
		const ms_total = event_timestamp - timestamp_last_elapsed_ms
		rec.value.session.total_time_elapsed = msToTime(ms_total)
	}

	template.records.push(rec)
	//console.log('route', rec)

	return template
}

const send = (topic, data) => {
	axios
		.post(`${KAFKA_ENDPOINT}/${topic}`, data, {
			headers: {
				'Content-Type': KAFKA_HEADER_CONTENT_TYPE,
				Accept: KAFKA_HEADER_ACCEPT,
			},
		})
		.then(res => {
			//console.log(data.records[0].value)
			//console.log('research: log has been sent successfully')
		})
		.catch(e => {
			//console.log(e)
		})
}

export default {
	routeReport(to, from, session) {
		const req_body = lmeAppRouteSerializer(to, from, session)
		if (req_body.records.length > 0) {
			//console.log('route', req_body.records[0].value.session.total_time_elapsed)
			send('lme-app-route', req_body)
		}
	},
	modalReport(modalName, routeName, session) {
		const from = parseRoute(routeName)
		const to = `${from}-modal-${modalName}`
		const req_body = lmeAppRouteSerializer(to, from, session)
		send('lme-app-route', req_body)
	},
	modalHideReport(modalName, routeName, session) {
		const to = parseRoute(routeName)
		const from = `${to}-modal-${modalName}`
		const req_body = lmeAppRouteSerializer(to, from, session)
		send('lme-app-route', req_body)
	},
}
