<template>
	<tr class="table-item">
		<th>
			<input
				:disabled="item.status === 'FAIL' || (!value && maxSize === selectedLength)"
				type="checkbox"
				v-model="value"
				@click="$emit('checked', item)"
			/>
		</th>
		<th>
			<div>{{ leadingZeros(idx, 3) }}</div>
		</th>
		<th class="code" style="width: 110px">
			<div>{{ item.code }}</div>
		</th>
		<th style="width: 90px">
			<div :class="{ ok: item.status === 'OK', fail: item.status === 'FAIL' }">
				<p>
					{{ itemStatus }}
				</p>
				<p class="reason" v-if="item.reason">{{ `(${tt(`${item.reason}`)})` }}</p>
			</div>
		</th>
		<th>
			<div class="delete-button" @click="$emit('delete', item)"><img src="@/assets/svg/icon/icon-trash-enable.svg" /></div>
		</th>
	</tr>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
	name: 'QrScanTableItem',
	components: {},
	props: {
		idx: {
			type: Number,
			default: 0,
		},
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			value: false,
		}
	},
	computed: {
		...mapState('form/qrcode', ['maxSize']),
		...mapGetters('form/qrcode', ['qrcodeList', 'selectedList', 'selectedLength']),
		itemStatus() {
			return {
				OK: this.tt('정상'),
				FAIL: this.tt(`실패`),
			}[this.item.status]
		},
	},
	watch: {
		selectedList: {
			deep: true,
			handler(value) {
				this.value = this.selectedList?.map(item => item.code)?.includes(this.item.code) && this.item.status === 'OK'
			},
		},
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.value = this.selectedList?.map(item => item.code)?.includes(this.item.code) && this.item.status === 'OK'
		})
	},
	methods: {
		// refs: http://mwultong.blogspot.com/2007/01/0-leading-zero-to-number-javascript.html
		leadingZeros(n, digits) {
			var zero = ''
			n = n.toString()

			if (n.length < digits) {
				for (var i = 0; i < digits - n.length; i++) zero += '0'
			}
			return zero + n
		},
	},
}
</script>

<style lang="scss" scoped>
.table-item {
	height: 40px;
	top: 0;
	background-color: white;
	border-bottom: 1px solid $DIVIDER;
	&.gray {
		background-color: $GREY_2;
	}
	th {
		padding: 10px 0px;
		width: 50px;
		height: 100px;
		font-size: 14px;
		font-weight: 400;
		text-align: center;
		&.code {
			word-break: break-all;
		}
	}
	th + th {
		border-left: 0.5px solid $DIVIDER;
	}
}

.reason {
	font-size: 12px;
}

.ok {
	color: $GREEN;
}

.fail {
	color: $RED;
}

.delete-button {
	cursor: pointer;
}

input:disabled {
	cursor: not-allowed;
}
</style>
