var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.tags.length > 0 ? _c('div', {
    staticClass: "keyword-header"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.tt('태그 찾기')) + " ")])]) : _vm._e(), _c('ul', {
    staticClass: "tag-keyword-list"
  }, _vm._l(_vm.tags, function (tag, index) {
    return _c('li', {
      key: "tag_".concat(index),
      staticClass: "tag-keyword",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$emit('clickTag', tag);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(tag.name) + " ")])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }