<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddStorageBoxPopup">
			<div class="Popup__background">
				<div class="StorageBox-add-popup Popup__container Popup__size-md">
					<div class="Popup__top">
						<h5 class="Popup__title">{{ tt(title) }}</h5>
					</div>
					<div class="Popup__content">
						<div class="StorageBox-add-group">
							<h6 class="Popup__content-subtitle">
								{{ tt('보관함 정보') }}
							</h6>
							<ul class="Popup__form form-top">
								<li class="Popup__input-field" :class="{ error: !valid.name }">
									<p>{{ tt('보관함 이름') }}<span class="required">*</span></p>
									<input type="text" :placeholder="tt(placeholders.name)" :value="form.name" @input="inputName" />
									<span class="noti">{{ tt(placeholders.name) }}</span>
								</li>

								<li class="Popup__input-field">
									<p>{{ tt('유형') }}</p>
									<input type="text" :placeholder="tt('유형을 입력해주세요')" :value="form.type" @input="inputType" />
								</li>
							</ul>
						</div>
						<div class="StorageBox-add-info">
							<h6 class="Popup__content-subtitle">
								{{ tt('관리 정보') }}
							</h6>
							<ul class="Popup__form">
								<li class="Popup__select-field top" :class="{ error: !valid.locationId }">
									<p>{{ tt('위치') }}<span class="required">*</span></p>
									<div class="Popup__input-field select-location-wrapper">
										<div class="select-location">
											<input type="text" :placeholder="tt(placeholders.location)" readonly v-model="locationPathname" />
										</div>
										<button class="lm-button-common" @click="selectLocation">
											{{ tt('선택') }}
										</button>
									</div>
									<span class="noti">{{ placeholders.location }}</span>
								</li>
								<!-- 담당 그룹 -->
								<li class="Popup__select-field top" :class="{ error: !valid.assigneeGroupId }">
									<p>
										{{ tt('담당 그룹') }}
										<span class="required">*</span>
									</p>
									<div class="Popup__input-field Dropdown-input" style="height: auto; align-items: center">
										<div @click="openModalSelectGroup(form.assigneeGroupId)">
											<input
												class="pointer"
												type="text"
												:placeholder="tt(placeholders.group)"
												v-model="form.assigneeGroup.name"
												readonly
											/>
										</div>
									</div>
									<span class="noti">{{ placeholders.group }}</span>
								</li>
								<!-- 정 관리자 -->
								<li class="Popup__select-field">
									<p>{{ tt('정관리자') }}</p>
									<div class="Popup__input-field Dropdown-input" @click="openModalSelectAssignee">
										<input
											class="pointer"
											type="text"
											:placeholder="tt('정관리자를 선택해주세요')"
											readonly
											:value="assignee && assignee.name"
										/>
									</div>
								</li>
								<!-- 부 관리자 -->
								<li class="Popup__select-field">
									<p>{{ tt('부관리자') }}</p>
									<div class="Popup__input-field Dropdown-input" @click="openModalSelectSubassignee">
										<input
											class="pointer"
											type="text"
											:placeholder="tt('부관리자를 선택해주세요')"
											:value="subAssignee && subAssignee.name"
											readonly
										/>
									</div>
								</li>
							</ul>
						</div>
						<div class="StorageBox-add-warning">
							<h6 class="Popup__content-subtitle">
								{{ tt('경고 표지') }}
							</h6>
							<ul class="StorageBox__warning-list" v-if="warningSigns">
								<li class="StorageBox__warning-item" v-for="warningSign in warningSigns" :key="warningSign.id">
									<input :id="warningSign.id" type="checkbox" :value="warningSign.id" v-model="form.warningSignsId" />
									<label :for="warningSign.id" v-b-tooltip.hover="tt(warningSign.nameKo ? warningSign.nameKo : warningSign.nameEn)">
										{{ tt(warningSign.nameKo ? warningSign.nameKo : warningSign.nameEn) }}
									</label>
									<img v-if="!warningSign.link" src="@/assets/svg/member-photo-default.svg" />
									<img :src="warningSign.link" />
								</li>
							</ul>
						</div>
					</div>
					<div class="Popup__bottom">
						<div class="Popup__complete-btn-group Popup__btn-group">
							<button class="Btn__close" @click="hide">
								{{ tt('취소') }}
							</button>
							<button class="Btn__complete" @click="complete">
								{{ tt('완료') }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<modal-select-member ref="modal-select-member" @selectMember="selectMember"> </modal-select-member>
		<!-- <modal-add-group-auth ref="modal-select-group" @selectGroups="selectGroups"> </modal-add-group-auth> -->
		<modal-select-group
			title="담당 그룹 선택"
			ref="modal-select-group"
			:isInclude="false"
			:isSelectSingle="true"
			:defaultData="groups"
			@confirm="completeSelectedGroup"
		/>
		<modal-select-location
			:isShow="showModalSelectLocation"
			:selectedLocation="locationInfo"
			@close="showModalSelectLocation = false"
			@complete="completeSelectLocation"
		>
		</modal-select-location>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { mapState, mapActions, mapMutations } from 'vuex'
import ModalSelectMember from '@/views/institute/organization/modals/ModalSelectMember.vue'
import ModalAddGroupAuth from '@/views/institute/organization/modals/ModalAddGroupAuth.vue'
import ModalSelectGroup from '@/views/common/components/modalGen2/ModalSelectGroup.vue'
import storageAPI from '@/services/api/institute/storage'
import { ToastType } from '@/utils/define/ToastType'
import ModalSelectLocation from '@/views/institute/location/modals/ModalSelectLocation.vue'

export default {
	name: 'ModalAddStorageV2',
	mixins: [MixinModal],
	components: {
		ModalSelectMember,
		ModalAddGroupAuth,
		ModalSelectGroup,
		ModalSelectLocation,
	},
	props: {
		storageData: {
			type: Object,
		},
		selectLocationInfo: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			form: {
				name: '',
				type: '',
				locationId: '',
				assigneeId: '',
				subAssigneeId: '',
				assigneeGroup: {},
				assigneeGroupId: '',
				assigneeGroupName: '',
				warningSignsId: [],
			},
			assignee: {},
			subAssignee: {},
			groupNameList: [],
			warningSigns: [],
			signs: [],
			locationPathname: '',
			locationInfo: {},
			showModalSelectLocation: false,
			placeholders: {
				name: '보관함 이름을 입력해주세요',
				location: '위치를 선택해주세요',
				group: '담당 그룹을 선택해주세요',
			},
			valid: {
				name: true,
				locationId: true,
				assigneeGroupId: true,
			},
			type: 'ADD',
		}
	},
	computed: {
		...mapState('institute/group', ['groups']),
		...mapState('storage', ['editStorageId']),
		isTypeAdd() {
			return this.type === 'ADD'
		},
		isTypeEdit() {
			return this.type === 'EDIT'
		},
		isTypeEditDetailPage() {
			return this.type === 'EDIT_DETAIL'
		},
		title() {
			return this.type == 'ADD' ? this.tt('보관함 추가') : this.tt('보관함 편집')
		},
	},
	methods: {
		...mapActions('institute/group', ['getGroups']),
		async show(data, type) {
			this.showModalSelectLocation = false
			this.type = type
			this.valid = {
				name: true,
				locationId: true,
				assigneeGroupId: true,
			}
			await this.loadWarningSigns()
			if (this.isTypeAdd) {
				await this.initForm()
				this.form.locationId = this.selectLocationInfo.id
				this.locationInfo = {
					info: {
						id: this.selectLocationInfo.id,
						name: this.selectLocationInfo.name,
						pathName: this.selectLocationInfo.pathName,
					},
				}
				this.locationPathname = this.selectLocationInfo.pathName
			} else if (this.isTypeEdit) {
				const response = await storageAPI.lookupDetailStorageInfo(this.$route.params.instituteId, data ? data.id : this.editStorageId)
				const { assignee, subAssignee, locationId, id, name, type, pathName, assigneeGroup, warningSignImages } = response.data

				this.form = {
					id: id,
					name: name,
					type: type ? type : '',
					assigneeGroup: assigneeGroup ? assigneeGroup : {},
					assigneeGroupId: assigneeGroup ? assigneeGroup.id : '',
					assigneeGroupName: assigneeGroup ? assigneeGroup.name : '',
					locationId: locationId,
					assigneeId: assignee ? assignee.id : '',
					subAssigneeId: subAssignee ? subAssignee.id : '',
					warningSignsId: warningSignImages ? warningSignImages.map(warningSign => warningSign.id) : [],
				}
				this.assignee = assignee
				this.subAssignee = subAssignee
				this.locationPathname = pathName
				this.locationInfo = {
					info: {
						id: locationId,
						name: name,
						pathName: pathName,
					},
				}
			} else if (this.isTypeEditDetailPage) {
				const { assignee, subAssignee, locationId, id, name, type, pathName, assigneeGroup, warningSignImages } = this.storageData

				this.form = {
					id: id,
					name: name,
					type: type,
					assigneeGroup: assigneeGroup ? assigneeGroup : {},
					assigneeGroupId: assigneeGroup ? assigneeGroup.id : '',
					assigneeGroupName: assigneeGroup ? assigneeGroup.name : '',
					locationId: locationId,
					assigneeId: assignee ? assignee.id : '',
					subAssigneeId: subAssignee ? subAssignee.id : '',
					warningSignsId: warningSignImages ? warningSignImages.map(warningSign => warningSign.id) : [],
				}
				this.assignee = assignee
				this.subAssignee = subAssignee
				this.locationPathname = pathName
				this.locationInfo = {
					info: {
						id: this.storageData.locationId,
						name: this.storageData.name,
						pathName: this.storageData.pathName,
					},
				}
			}
			this.isShow = true
		},
		initForm() {
			this.form = {
				name: '',
				type: '',
				assigneeGroup: {},
				locationId: '',
				assigneeId: '',
				subAssigneeId: '',
				warningSignsId: [],
			}
			this.assignee = {}
			this.subAssignee = {}
			this.locationInfo = {
				info: {
					id: null,
					name: null,
					pathName: null,
				},
			}
		},
		validation() {
			let result = true
			const validArr = Object.keys(this.valid)
			validArr.forEach(key => {
				if (!this.form[key]) {
					this.valid[key] = false
					result = false
				}
			})

			return result
		},
		async complete() {
			if (!this.validation()) {
				this.$root.toast(this.tt('필수값 미입력'), this.tt('필수값을 입력해주세요'), 'warning')
				return false
			}

			const instId = this.$route.params.instituteId
			if (this.isTypeAdd) {
				await storageAPI.createStorage(instId, this.form)
				const title = this.tt('보관함 추가 완료')
				const content = this.tt('보관함이 추가되었습니다')
				this.$root.toast(title, content, ToastType.SUCCESS)
				this.$emit('refresh', this.form.locationId)
			} else if (this.isTypeEdit || this.isTypeEditDetailPage) {
				await storageAPI.updateStorage(instId, this.form)
				const title = this.tt('보관함 편집 완료')
				const content = this.tt('보관함이 편집되었습니다')
				this.$root.toast(title, content, ToastType.SUCCESS)
				this.$emit('refresh', this.form.locationId)
			}
			this.hide()
			this.$emit('complete')
		},
		async loadWarningSigns() {
			const response = await storageAPI.lookupWarningSigns()

			this.warningSigns = response.data
		},
		async openModalSelectGroup(groupId) {
			this.valid.assigneeGroupId = true
			await this.getGroups() // 공통
			this.$refs['modal-select-group'].show(this.form.assigneeGroup?.id ? [this.form.assigneeGroup] : null)
		},
		openModalSelectAssignee() {
			this.$refs['modal-select-member'].show('STORAGE_ASSIGNEE', this.assignee)
		},
		openModalSelectSubassignee() {
			this.$refs['modal-select-member'].show('STORAGE_SUBASSIGNEE', this.subAssignee)
		},
		inputName(e) {
			this.form.name = e.target.value
			this.valid.name = true
		},
		inputType(e) {
			this.form.type = e.target.value
		},
		selectLocation() {
			this.valid.locationId = true
			this.showModalSelectLocation = true
		},
		completeSelectedGroup(selectedGroups) {
			if (selectedGroups) {
				const groupData = selectedGroups[0]
				this.form.assigneeGroup = {
					id: groupData.id,
					name: groupData.name,
				}
				this.form.assigneeGroupId = groupData.id
				this.form.assigneeGroupName = groupData.name
			}
		},
		selectMember(data) {
			if (data.modalType === 'STORAGE_ASSIGNEE') {
				this.form.assigneeId = data.member.id
				this.assignee = data.member
			} else {
				this.form.subAssigneeId = data.member.id
				this.subAssignee = data.member
			}
		},
		completeSelectLocation(locationInfo) {
			this.locationInfo = { ...locationInfo }
			this.form.locationId = this.locationInfo.id
			this.locationPathname = this.locationInfo.pathName
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/location/_add-storage.scss';

@import '@/styles/scss/institution/_modal-add-group.scss';
.Popup__container {
	position: relative;
	top: 50%;
	/* margin-top: 150px; */
	margin-bottom: 150px;
}
.AddStorageBoxPopup {
	.Popup__container {
		.Popup__content {
			padding-bottom: 0;
			max-height: 70vh;
			overflow-y: auto;
			.Popup__input-field {
				input {
					text-decoration: none;
				}
			}
			.StorageBox-add-group {
				.Popup__input-field {
					width: 300px !important;
				}
			}
			.StorageBox-add-info {
				margin-bottom: 20px;
			}
			ul {
				li {
					&.error {
						.noti {
							background-image: url(~@/assets/svg/noti-error.svg);
							color: $RED;
							display: block;
							visibility: visible;
							margin-bottom: 0;
						}
						input {
							border: 1px solid $RED;
						}
					}
					.noti {
						width: 100%;
						font-size: 0.75rem;
						color: $GREEN;
						background-image: url(~@/assets/svg/noti-checked.svg);
						background-repeat: no-repeat;
						background-size: 12px;
						background-position: top 3px left 0;
						font-weight: 400;
						padding-left: 17px;
						height: 20px;
						display: block;
						visibility: hidden;
						margin-bottom: -20px;
					}
				}
			}
		}
	}
}

> div.StorageBox-add-group .Popup__input-field {
	width: 70%;
}

.pointer {
	cursor: pointer;
}

.form-top {
	display: flex;
	justify-content: space-between;
}

.select-location {
	width: 227px;
	background-color: $GREY_2;
	input {
		background-image: none !important;
	}
}

.lm-button-common {
	width: 60px;
	height: 30px;
	border: 1px solid #e5e5e5;
	border-radius: 4px;
	padding: 5px 10px 5px 10px;
	font-size: 14px;
	font-weight: 700;
	display: flex;
	justify-content: center;
}
.select-location-wrapper {
	display: flex;
	justify-content: space-between;
}

.Popup__select-field {
	&.top {
		margin-bottom: 20px !important;
	}
}
</style>
