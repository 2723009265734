<template>
	<div class="info-wrap" v-if="item.assignee">
		<p class="info-header">{{ tt('연락처') }}</p>
		<div class="flex">
			<div class="member-wrap">
				<div class="member-info-wrap">
					<img class="member-img" v-if="item.assignee.image" :src="item.assignee.image" />
					<img class="member-img" v-else src="@/assets/svg/noMemberImg.svg" />
					<div class="member-info">
						<p class="name">{{ item.assignee.name }}</p>
						<p class="phone">{{ item.assignee.phone }}</p>
					</div>
				</div>
				<div class="member-spot">
					{{ tt('담당자') }}
				</div>
			</div>
			<div class="member-wrap" v-if="item.holder">
				<div class="member-info-wrap">
					<img class="member-img" v-if="item.holder.image" :src="item.holder.image" />
					<img class="member-img" v-else src="@/assets/svg/noMemberImg.svg" />
					<div class="member-info">
						<p class="name">{{ item.holder.name }}</p>
						<p class="phone">{{ item.holder.phone }}</p>
					</div>
				</div>
				<div class="member-spot">
					{{ tt('사용자') }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'MemberInfo',
	components: {},
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	.flex {
		gap: 20px;
	}
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.member-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		padding: 20px;
		background: $GREY_0;
		width: 415px;
		height: 70px;
		border-radius: 8px;
		border: 1px solid $DIVIDER;
		.member-info-wrap {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.member-info {
				text-align: left;
				.name {
					font-weight: 700;
					font-size: 14px;
				}
				.phone {
					font-weight: 400;
					font-size: 12px;
					color: #00000099;
				}
			}
			.member-img {
				width: 36px;
				height: 36px;
				border-radius: 18px;
				margin-right: 10px;
			}
		}
		.member-spot {
			font-weight: 500;
			font-size: 14px;
			color: $SECONDARY_2;
		}
	}
}
</style>
