var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('draft-list-header', {
    attrs: {
      "title": _vm.tt('지출결의서 기안함'),
      "dateButtonSubtext": _vm.dateButtonSubtext,
      "calendarDatetime": _vm.calendarDatetime
    },
    on: {
      "changeDate": _vm.changeCalendar,
      "changeLoadParams": _vm.setLoadParams
    }
  }), _vm.previousPage ? _c('draft-list-table', {
    ref: "draft-table",
    attrs: {
      "previousPage": _vm.previousPage,
      "list": _vm.list
    }
  }) : _vm._e(), _c('div', {
    staticClass: "pagination-wrapper"
  }, [_c('pagination', {
    ref: "pagination",
    attrs: {
      "total": _vm.count
    },
    on: {
      "page": _vm.pagination
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }