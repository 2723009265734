<template>
	<div v-show="isShow" class="keypad-dialog">
		<div class="keypad-container">
			<div class="keypad-value">
				<a href="#" @click.stop="close"><small>닫기</small></a>
			</div>
			<div v-for="(row, index) in rows" :key="index" class="keypad-row">
				<div v-for="(col, idx) in row" :key="`${index}${idx}`" class="keypad-flex keypad-class">
					<div class="keypad" @click="onInput(col)">
						<div class="keypad-center">{{ col }}</div>
					</div>
				</div>
			</div>
			<div class="keypad-row">
				<div class="keypad-flex keypad-class">
					<div class="keypad" @click="onReset">
						<div class="keypad-center delete">전체삭제</div>
					</div>
				</div>
				<div class="keypad-flex keypad-class">
					<div class="keypad" @click="onDelete">
						<div class="keypad-center">
							<img src="@/assets/svg/delete-keypad.svg" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NumericKeypad',
	props: {
		column: {
			type: Number,
			default: 4,
		},
	},
	data() {
		return {
			isShow: false,
			keys: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '', ''],
			rows: [],
		}
	},
	methods: {
		init() {
			this.shuffle(this.keys)
			this.rows = this.division(this.keys, this.column)
		},
		show() {
			if (this.isShow) return

			this.init()
			this.isShow = true
		},
		hide() {
			if (!this.isShow) return

			this.isShow = false
		},
		close() {
			this.$emit('close')
		},
		onInput(key) {
			if (!key && key !== 0) return

			this.$emit('input', key)
		},
		onReset() {
			this.$emit('reset')
		},
		onDelete() {
			this.$emit('delete')
		},
		shuffle(array) {
			for (let index = array.length - 1; index > 0; index--) {
				const randomPosition = Math.floor(Math.random() * (index + 1))

				const temporary = array[index]
				array[index] = array[randomPosition]
				array[randomPosition] = temporary
			}
		},
		division(array, count) {
			const temp = this.$_.cloneDeep(array)
			const length = temp.length
			const divide = Math.floor(length / count) + (Math.floor(length % count) > 0 ? 1 : 0)

			const result = []
			for (let i = 0; i < divide; i++) {
				result.push(temp.splice(0, count))
			}
			return result
		},
	},
}
</script>

<style lang="scss" scoped>
.keypad-hide {
	visibility: hidden;
}
.keypad-row {
	display: flex;
	width: 100%;
}
.keypad-class {
	color: #888;
	background: #fafafa;
	border-right: 0.004rem solid $INPUT;
	border-bottom: 0.004rem solid $INPUT;
}
.keypad-dialog {
	position: absolute;
	left: 0;
	bottom: -345px;
	width: 100%;
	z-index: 1;
}
.keypad-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	min-width: 0;
	flex-direction: row;
}
.keypad-value {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 63px;
	border: 1px solid $INPUT;
	background: $LAB_WHITE;
	width: 100%;
	padding: 20px;
	small {
		font-size: 1rem;
		font-weight: 500;
	}
}
.keypad-flex {
	flex: 1;
	height: 67px;
}
.keypad {
	width: 100%;
	height: 100%;
	text-align: center;
	vertical-align: center;
	margin: 0 auto;
	background: $LAB_WHITE;
	&:hover {
		background: $GREY_1;
	}
}
.keypad-center {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	font-size: 1.5rem;
	font-weight: 500;
	color: $SECONDARY_1;
	&.delete {
		font-size: 1rem;
	}
}
.keypad-delete {
	font-size: 1.5rem;
}
</style>
