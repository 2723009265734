var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-textarea-wrap"
  }, [_c('base-item', {
    attrs: {
      "title": _vm.title
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    style: "width:".concat(_vm.inputWidthSize, "px; height:").concat(_vm.inputHeightSize, "px;"),
    attrs: {
      "placeholder": _vm.tt(_vm.placeholder),
      "disabled": _vm.isReadOnly
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.value = $event.target.value;
      }
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }