<template>
	<b-modal centered scrollable ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<item-form-select-sticker v-if="item"></item-form-select-sticker>
		</template>
		<template #modal-footer>
			<modal-footer @cancel="hide" @complete="clickComplete"></modal-footer>
		</template>
	</b-modal>
</template>
<script>
import ModalFooter from '@/views/common/components/modals/ModalFooter.vue'
import ItemFormSelectSticker from '@/views/inventoryGen2/components/regist/form/ItemFormSelectSticker.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
	name: 'ModalSelectStickerType',
	components: { ModalFooter, ItemFormSelectSticker },
	props: {
		title: {
			type: String,
			default: '물품ID 스티커 출력',
		},
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('inventoryGen2/item', ['getDetail']),
		...mapMutations('inventoryGen2/form', ['setCategory', 'setFormItem', 'setGhs']),
		...mapActions('inventoryGen2/form', ['getCategoryInfo', 'editStickerType']),
		...mapActions('modal/ghs', ['setInit']),
		...mapActions('inventoryGen2/historyInfo', ['getList']),
		async show() {
			this.setInit()
			this.setCategory(this.item.category)
			await this.getCategoryInfo()
			this.setFormItem(this.item)
			if (this.item?.ghs) {
				this.setGhs(this.item.ghs)
			}
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
			this.setCategory(null)
			this.setFormItem(null)
			this.setGhs(null)
		},
		async clickComplete() {
			try {
				await this.editStickerType()
				await this.getDetail(this.item.id)
				await this.getList()
				this.hide()
				if (this.item.stickerType != null) {
					this.$emit('complete')
				}
			} catch (e) {
				this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';

::v-deep .modal-dialog {
	max-width: 700px;
	.modal-content {
		background-color: $GREY_1;
		border-radius: 8px;
		border: 0;
		.form-wrap {
			padding: 20px;
			background: white;
			border: 1px solid $DIVIDER;
			border-radius: 8px;
			gap: 20px;
			.form-header {
				font-weight: 700;
				font-size: 16px;
				.form-title {
				}
				.form-msg {
				}
			}
		}
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	padding: 0px 20px;
	border: 0;
	gap: 20px;
	overflow-x: hidden;
}

::v-deep .modal-header {
	padding: 20px;
	border: 0;
}

::v-deep .modal-footer {
	padding: 20px;
	border: 0;
	.modal-footer-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.footer-left,
		.footer-right {
			display: flex;
			gap: 10px;
			button {
				border: 0;
				box-shadow: none;
				display: flex;
				height: auto;
				align-items: center;
				cursor: pointer;
				white-space: pre;
				transition: 0.5s all ease;
				min-width: 100px;
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 700;
				border-radius: 5px;
				justify-content: center;
			}
			.button-white {
				background: $LAB_WHITE;
				border: 1px solid $DIVIDER;
				color: #111 !important;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
						linear-gradient(0deg, #fff, #fff);
				}
			}
			.button-primary {
				background: $LAB_YELLOW;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
				}
			}
		}
	}
}

::v-deep .modal-title {
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	.title {
		color: #111 !important;
	}
}
</style>
