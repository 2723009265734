<template>
	<div class="SafetyManagementMaterials">
		<h2 class="AppContainer__title">
			{{ tt('특별관리물질') }}
			<button @click="showNotice">
				<img src="@/assets/svg/Question.svg" alt="icon" />
			</button>
		</h2>
		<div class="AppContainer">
			<div class="Filter__box">
				<search-box :placeholder="tt('검색')" :loadList="loadManagementList"></search-box>
				<div class="right-btn-group">
					<!--이후 추가작업 필요-->
					<button class="btn" @click="showPeriod">
						<img src="@/assets/svg/icon-document.svg" alt="icon" />{{ tt('특별관리물질 취급일지 다운로드') }}
					</button>
					<button class="btn" @click="downloadExcel"><img src="@/assets/svg/icon-excel.svg" alt="icon" />{{ tt('목록 다운로드') }}</button>
				</div>
			</div>
			<div class="Table__wrap scroll">
				<table>
					<thead>
						<tr>
							<th
								v-for="header in headerList"
								:key="header.name"
								:width="header.width"
								:class="[{ headerHover: header.sort }, { asc: header.direction == 'ASC' }]"
							>
								<span>
									{{ tt(header.name) }}
									<button @click="changeSort(header)" v-if="header.sort">
										<img src="@/assets/svg/arrow-b.svg" alt="icon" />
									</button>
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<management-item
							:keyword="listKeyword"
							:headers="headerList"
							v-for="item in list"
							:key="item.id"
							:item="item"
						></management-item>
					</tbody>
				</table>
				<no-data-list v-if="list.length == 0" :image="noData.image" :title="noData.title" :content="noData.content"></no-data-list>
			</div>
		</div>

		<pagination :total="count" @page="pagination"></pagination>

		<!--modal-->
		<modal-period-setting ref="modal-period" :title="tt('조회할 특별관리물질 취급일지 기간 설정')" @isHide="hidePeriod"></modal-period-setting>
		<modal-management-notice :isShow="displayNotice" @close="hideNotice"></modal-management-notice>
		<modal-loading ref="modal-loading" title="다운로드 중입니다." />
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import SearchBox from '@/views/common/components/SearchBox.vue'
import ManagementItem from '@/views/safety/management/components/ManagementItem.vue'

//modals
import ModalPeriodSetting from '@/views/safety/management/modals/ModalPeriodSetting.vue'
import ModalManagementNotice from '@/views/safety/management/modals/ModalManagementNotice.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import NoDataList from '@/views/common/components/NoDataList.vue'
import ModalLoading from '@/views/common/components/modalGen2/ModalLoading.vue'
export default {
	name: 'SafetyManagementMaterials',
	components: {
		SearchBox,
		Pagination,
		ModalPeriodSetting,
		ManagementItem,
		ModalManagementNotice,
		NoDataList,
		ModalLoading,
	},
	created() {
		this.instId = this.$route.params.instituteId
		this.loadManagementList()
	},
	data() {
		return {
			instId: null,
			isActive: false,
			isCheck: false,
			order: [],
			orderDirection: [],
			checkList: [],
			count: 0,
			list: [],
			offset: 0,
			length: 50,
			keyword: undefined,
			headerList: [
				{
					name: this.tt('정보'),
					width: 600,
					value: true,
					sort: false,
				},
				{
					name: this.tt('제품번호'),
					width: 160,
					value: true,
					sort: false,
				},
				{
					name: 'CAS No',
					width: 160,
					value: true,
					sort: false,
				},
				{
					name: this.tt('잔여 용량'),
					width: 180,
					value: true,
					sort: false,
				},
				{
					name: this.tt('최초 등록일자'),
					width: 180,
					value: true,
					sort: true,
					order: 'CREATE_DATETIME',
					direction: 'DESC',
				},
			],
			displayViewChemicals: false,
			displayNotice: false,
			noData: {
				image: '',
				title: '',
				content: '',
			},
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('management', ['managementList']),
		...mapState('institutionInformation', ['listKeyword']),
	},
	watch: {
		list(val) {
			if (val.length == 0) {
				this.noData = {
					image: 'list',
					title: '특별관리물질이 없습니다',
					content: '특별관리물질이 등록되면 자동으로 목록에 추가됩니다',
				}
				if (this.listKeyword.length > 0) {
					this.noData = {
						image: 'search',
						title: '조회된 결과가 없습니다.',
						content: '다시 한번 확인해 주세요.',
					}
				}
			}
		},
	},
	methods: {
		...mapMutations('institutionInformation', ['setKeyword']),
		...mapActions('management', ['lookupManagementList', 'exportDiaryExcel', 'exportExcel']),
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
			this.loadManagementList()
		},
		loadManagementList() {
			const payload = {
				instId: this.instId,
				offset: this.offset,
				length: this.length,
				keyword: this.listKeyword,
				order: this.order,
				orderDirection: this.orderDirection,
			}

			this.lookupManagementList(payload).then(() => {
				this.count = this.managementList.count
				this.list = this.managementList.list
				console.log(this.list)
			})
		},
		changeSort(item) {
			this.order = []
			this.orderDirection = []
			this.order.push(item.order)
			if (item.direction == 'DESC') item.direction = 'ASC'
			else item.direction = 'DESC'
			this.orderDirection.push(item.direction)
			this.loadManagementList()
		},
		showPeriod() {
			this.$refs['modal-period'].show()
		},
		async hidePeriod(date) {
			this.$refs['modal-period'].hide()
			console.log(date)
			const startDate = date && date[0] ? this.formatDate(date[0]) : null
			const endDate = date && date[1] ? this.formatDate(date[1]) : null

			const payload = {
				instId: this.instId,
				body: {
					startDatetime: startDate,
					endDatetime: endDate,
				},
			}
			this.$refs['modal-loading'].show()
			try {
				const response = await this.exportDiaryExcel(payload)
				window.fileDownload(response, date ? startDate + '~' + endDate + `_` : '' + this.tt('특별관리물질 취급일지') + `.xlsx`)
			} catch (e) {}
			this.$refs['modal-loading'].hide()
		},
		async downloadExcel() {
			this.$refs['modal-loading'].show()
			try {
				const response = await this.exportExcel(this.instId)
				window.fileDownload(response, this.formatDate(new Date()) + `_` + this.tt('특별관리물질 리스트.xlsx'))
			} catch (e) {}
			this.$refs['modal-loading'].hide()
		},
		formatDate(date) {
			if (date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear()
				if (month.length < 2) {
					month = '0' + month
				}
				if (day.length < 2) {
					day = '0' + day
				}
				return [year, month, day].join('-')
			}
		},
		showNotice() {
			this.displayNotice = true
		},
		hideNotice() {
			this.displayNotice = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/safety/_safety.scss';

h2 {
	display: flex;
	align-items: center;
	height: auto;
	button {
		width: 24px;
		height: 24px;
		padding: 0;
		margin-left: 4px;
		img {
			width: inherit;
			height: inherit;
		}
	}
}
</style>
