import axiosInstance from '@/services/axios'

const endPoint = {
	lookupInviteMember: code => `/member/invite?code=${code}`,
	lookupAcceptInvite: () => '/member/invite/accept',
}

const memberAPI = {
	lookupInviteMember: code => axiosInstance.get(endPoint.lookupInviteMember(code)),
	lookupAcceptInvite: body => axiosInstance.post(endPoint.lookupAcceptInvite(), body),
}

export default memberAPI
