<template>
	<b-modal ref="modal" size="sm" hide-header centered hide-footer no-close-on-backdrop no-fade>
		<img src="@/assets/images/221223.png" @click="moveToUrl" />
		<div class="btn-footer">
			<button @click="clickDonwShow">{{ tt('일주일간 보지 않기') }}</button>
			<button @click="hide">{{ tt('닫기') }}</button>
		</div>
	</b-modal>
</template>
<script>
const dontShow7daysKey = 'dontShow7daysKeyResearch'
export default {
	created() {},
	computed: {
		isNoticeShowAllow() {
			let date = this.$cookies.get(dontShow7daysKey) ?? null
			if (date == null) return true
			return this.$DateTime.fromISO(date).plus({ days: 7 }) <= this.$DateTime.now()
		},
	},
	methods: {
		async show() {
			let lastDay = this.$DateTime.local(2022, 12, 1).endOf('month')
			if (this.isNoticeShowAllow && lastDay > this.$DateTime.now()) {
				this.$refs.modal.show()
			}
		},
		hide() {
			this.$refs.modal.hide()
		},
		clickDonwShow() {
			this.$cookies.set(dontShow7daysKey, this.$DateTime.now().toISO(), '7d')
			this.$refs.modal.hide()
		},
		moveToUrl() {
			window.open('https://smartjack.notion.site/5c65082106a34b09b7c397be47538559', '_blank')
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.modal-dialog {
		width: 335px;
		max-width: 335px;
		.modal-content {
			border: none;
			background-color: transparent;
		}
	}
	.modal-body {
		padding: 0px;
		& > img,
		.popup-wrapper {
			border-radius: 10px 10px 0px 0px;
			width: 335px;
			height: 430px;
			cursor: pointer;
		}
		.popup-wrapper {
			background-color: $SUB-YELLOW_100;
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: unset;
			.icon-notice {
				margin-top: 48px;
			}
			.btn-notice {
				cursor: pointer;
				margin-top: auto;
				margin-bottom: 48px;
			}
			h2 {
				font-size: 22px;
				color: $SECONDARY_1;
				margin-top: 30px;
				font-weight: 600;
			}
			p {
				padding: 0px 20px;
				width: 100%;
				margin-top: 20px;
				font-size: 15px;
				word-break: break-all;
				font-weight: 400;
				line-height: 200%;
			}
		}
		.btn-footer {
			width: 100%;
			height: 56px;
			display: flex;
			background-color: white;
			border-radius: 0px 0px 10px 10px;
			button {
				justify-content: center;
				align-items: center;
				width: 50%;
				font-size: 14px;
				color: $SECONDARY_2;
				font-weight: 500;
				&:last-child {
					border-left: 1px solid $DIVIDER;
				}
			}
		}
	}
}
</style>
