<template>
	<div class="page-wrapper">
		<migration-header :isMigration="true"></migration-header>
		<div class="content-wrapper">
			<div class="text-wrapper">
				<h3 class="title" v-html="title"></h3>
				<div class="content" v-html="content"></div>
			</div>
			<div class="button-wrapper">
				<lm-confirm-button-middle color="gray" :text="cancelText" @click="goToExperience"></lm-confirm-button-middle>
			</div>
			<div class="bottom-text-wrapper" @click="openMigrationDocs">
				<span class="bottom-text" v-html="bottomText"></span>
			</div>
		</div>
	</div>
</template>

<script>
import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'
import { exproUrl } from '@/services/api/common'
import MixinMigration from '@/mixins/migration'
import MigrationHeader from '@/views/migration/components/MigrationHeader.vue'

export default {
	components: { LmConfirmButtonMiddle, MigrationHeader },
	mixins: [MixinMigration],
	data() {
		return {
			title: this.tt('데이터 이전 권한이 없습니다'),
			content: this.tt(
				'랩매니저 PRO 전환 및 데이터 이전에는 <b>관리자</b> 혹은 <b>PI</b>의 권한이 필요합니다.<br> 관리자, PI에게 전환(이관)을 요청하세요.'
			),
			migrationText: this.tt('데이터 이전하기'),
			cancelText: this.tt('돌아가기'),
			bottomText: this.tt('데이터는 어떻게 이전되나요?'),
		}
	},
	created() {},
	computed: {},
	methods: {
		goToExperience() {
			window.open(exproUrl, '_self')
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.content-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 600px;
		height: 550px;
		.text-wrapper {
			margin-bottom: 40px;
			.title {
				font-size: 48px;
				font-style: normal;
				font-weight: 700;
				line-height: 70px;
				letter-spacing: 0px;
				text-align: center;
				margin-bottom: 20px;
			}

			.content {
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 26px;
				letter-spacing: 0px;
				text-align: center;
				margin-bottom: 10px;
			}

			.subtext {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0px;
				text-align: center;
				color: $SECONDARY_2;
			}
		}
		.button-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 40px;
			height: auto;
		}
		.bottom-text-wrapper {
			cursor: pointer;
			.bottom-text {
				font-weight: 700;
				font-size: 14px;
				line-height: 20px;
				text-align: center;
				text-decoration-line: underline;
				color: $SECONDARY_2;
			}
		}
	}
}
</style>
