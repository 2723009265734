<template>
	<div>
		<form-product
			forDraft
			title="상품 정보"
			v-if="category"
			:productInfo="productInfo"
			:categoryInfo="categoryInfo"
			:isValid="isProductValid"
			:isUnitValid="isUnitValid"
			ref="form-product"
			@change="changeFormProduct"
			@selectItem="handleSelectItem"
			:isShowError="showError"
		>
		</form-product>
		<form-seller
			title="판매자 정보"
			v-if="category"
			:sellerInfo="sellerInfo"
			:isShowError="showError"
			:isValid="isSellerValid"
			ref="form-seller"
			@change="changeFormSeller"
		>
		</form-seller>
	</div>
</template>
<script>
import FormProduct from '@/views/common/components/form/FormProduct.vue'
import FormSeller from '@/views/common/components/form/FormSeller.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'DarftFormProduct',
	components: {
		FormProduct,
		FormSeller,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('draft/form', [
			'category',
			'showError',
			'categoryInfo',
			'productInfo',
			'sellerInfo',
			'isProductValid',
			'isSellerValid',
			'isUnitValid',
		]),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('draft/form', ['setProductInfo', 'setSellerInfo']),
		handleSelectItem(item) {
			const { brand, casno, kind, modelName, name, productCode, spec, subname, volume, volumeUnit } = item
			this.setProductInfo({
				...this.productInfo,
				casno: casno,
				kind: kind,
				modelName: modelName,
				name: name,
				productCode: productCode,
				spec: spec,
				subname: subname,
				volume: volume,
				volumeUnit: volumeUnit,
			})
			if (brand) {
				this.setProductInfo({ ...this.productInfo, brandId: brand.id, brandName: brand.name })
			}
		},
		changeFormProduct(value, fieldKey) {
			const newProduct = { ...this.productInfo }

			if (typeof value === 'object') {
				this.setObjectValue(newProduct, fieldKey, value)
			} else {
				newProduct[fieldKey] = value
				this.setProductInfo(newProduct)
			}
		},
		changeFormSeller(value, fieldKey) {
			const newProduct = { ...this.sellerInfo }
			newProduct[fieldKey] = value
			this.setSellerInfo(newProduct)
		},
		setObjectValue(newProduct, fieldKey, value) {
			switch (fieldKey) {
				case 'volume':
				case 'currentVolume':
					// 잔여 용량 대응
					this.setVolume(newProduct, fieldKey, value)
					break
				case 'brand':
					// 브랜드
					this.setBrand(newProduct, value)
					break
				case 'phRange':
					this.setPhRange(newProduct, value)
					break
				default:
					if (value.unit) {
						newProduct[`${fieldKey}Unit`] = value.unit
					}
					newProduct[fieldKey] = value.value

					this.setProductInfo(newProduct)
					break
			}
		},
		setVolume(newProduct, fieldKey, value) {
			newProduct['currentVolumeUnit'] = value.unit
			newProduct['volumeUnit'] = value.unit
			if (fieldKey === 'volume') {
				newProduct.volume = value.value
				if (newProduct.volume < newProduct.currentVolume) {
					newProduct.currentVolume = value.value
				}
			}
			if (fieldKey === 'currentVolume') {
				console.warn(value.value, newProduct.volume)
				newProduct.currentVolume = value.value
				if (!newProduct.volume || newProduct.volume < value.value) {
					newProduct.volume = value.value
				}
			}
			this.setProductInfo(newProduct)
		},
		setBrand(newProduct, value) {
			if (value.id) {
				newProduct['brandName'] = value.name
				newProduct['brandId'] = value.id
			} else if (value.name) {
				newProduct['brandName'] = value.name
			}
			this.setProductInfo(newProduct)
		},
		setPhRange(newProduct, value) {
			newProduct['phRangeFrom'] = value.from
			newProduct['phRangeTo'] = value.to
			this.setProductInfo(newProduct)
		},
		showRequire() {
			this.$refs['form-product'].showRequire()
		},
	},
}
</script>

<style lang="scss" scoped>
div {
	&:nth-child(1) {
		margin-bottom: 20px;
	}
}
</style>
