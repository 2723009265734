var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ingredient-item",
    "class": [_vm.className]
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.textAreaValue,
      expression: "textAreaValue"
    }],
    ref: "input",
    "class": {
      error: _vm.error
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder,
      "readonly": false
    },
    domProps: {
      "value": _vm.textAreaValue
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.textAreaValue = $event.target.value;
      }, _vm.handleInput],
      "change": _vm.autoResizeTextarea,
      "focus": function focus($event) {
        _vm.showSearch = true;
      },
      "focusout": function focusout($event) {
        _vm.showSearch = false;
      },
      "click": _vm.autoResizeTextarea,
      "keydown": _vm.autoResizeTextarea,
      "keyup": _vm.autoResizeTextarea
    }
  }), _vm._v(" "), _vm.error ? _c('span', {
    staticClass: "error"
  }, [_c('img', {
    staticClass: "error-img",
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _vm._v(_vm._s(_vm.errorMsg))]) : _vm._e(), _vm.showSearchItemList ? _c('form-ingredient-search-list', {
    attrs: {
      "keyword": _vm.value,
      "className": _vm.className
    },
    on: {
      "clickItem": _vm.clickItem
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }