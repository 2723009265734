import { render, staticRenderFns } from "./SafetyHealthCheckup.vue?vue&type=template&id=dfe6ba02&scoped=true&"
import script from "./SafetyHealthCheckup.vue?vue&type=script&lang=js&"
export * from "./SafetyHealthCheckup.vue?vue&type=script&lang=js&"
import style0 from "./SafetyHealthCheckup.vue?vue&type=style&index=0&id=dfe6ba02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfe6ba02",
  null
  
)

export default component.exports