<template>
	<form-custom
		title="추가정보"
		:isCustomValid="isCustomValid"
		:categoryInfo="categoryInfo"
		:memberList="getMemberList.members"
		:itemCustomField="itemCustomField"
		:isShowError="showError"
		@change="setFormValue"
	></form-custom>
</template>
<script>
import FormCustom from '@/views/common/components/form/FormCustom.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
	name: 'ItemFormCustom',
	components: { FormCustom },
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/form', ['categoryInfo', 'item', 'customFieldList', 'itemCustomField', 'isCustomValid', 'showError']),
		...mapGetters('member', ['getMemberList']),
	},
	watch: {},
	created() {
		this.lookupMemberList()
	},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/form', ['setFormItem', 'setItemCustomFields']),
		...mapActions('member', ['lookupMemberList']),
		setFormValue(value, key, varType) {
			let data = { ...this.itemCustomField }
			switch (varType) {
				case 'text_single_line':
				case 'select_option':
				case 'select_datetime':
				case 'text_multiple_line':
					data[key] = value
					break
				case 'select_member':
					data[key] = value
					break
				case 'text_single_line_with_unit':
				case 'number_with_unit':
					data[key] = value.value
					break
				default:
					data[key] = value
					break
			}
			this.setItemCustomFields(data)
			this.setFormItem({ ...this.item, customField: this.customFieldList })
		},
	},
}
</script>

<style lang="scss" scoped></style>
