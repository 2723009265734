var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "information-wrapper"
  }, [_vm._m(0), _c('div', {
    staticClass: "info-list"
  }, [_c('div', [_c('p', {
    staticClass: "name"
  }, [_vm._v("입금 기한")]), _c('p', [_c('b', [_vm._v(" " + _vm._s(_vm.formatExpireDate(_vm.licenseManagement.schedule.expireDatetime)) + " ")])])]), _c('div', [_c('p', {
    staticClass: "name"
  }, [_vm._v("계좌 정보")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt(_vm.licenseManagement.schedule.virtualAccountBankName + ' ${1} (예금주: ${2})', _vm.licenseManagement.schedule.virtualAccountNumber, _vm.licenseManagement.schedule.virtualAccountHolder)) + " ")])]), _c('div', [_c('p', {
    staticClass: "name"
  }, [_vm._v("결제 금액")]), _c('p', {
    staticClass: "blue"
  }, [_vm._v(" " + _vm._s(_vm.tt('₩${1}', _vm.formatNumberLocale(_vm.licenseManagement.schedule.totalPrice))) + " ")])])]), _c('button', {
    staticClass: "btn-cancel",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-confirm'].show();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('주문 취소')) + " ")]), _c('modal-confirm', {
    ref: "modal-confirm",
    attrs: {
      "title": _vm.tt('주문취소'),
      "content": _vm.tt('정말로 라이선스 구매 주문을 취소하시겠습니까?'),
      "confirmText": _vm.tt('주문취소'),
      "variant": "warning"
    },
    on: {
      "confirm": _vm.cancelOrder
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "warning"
  }, [_c('b', [_vm._v("주의:")]), _vm._v(" 기한 내 입금 되지 않을 경우, 일부 이용자의 접속 불가 등 서비스 이용에 문제가 발생할 수 있습니다.")]);
}]

export { render, staticRenderFns }