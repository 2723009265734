<template>
	<div class="StorageBox__group" v-if="groupInfo && !isStart">
		<div class="AppContainer__subtitle">
			<h6>
				{{ groupInfo && groupInfo.type == 'INSTITUTE' ? tt('기관 공용 보관함') : tt('보관함') }}
			</h6>
			<button
				v-if="$root.userPermission.storage && $root.userPermission.storage.update"
				class="AppContainer__subtitle-btn btn"
				type="button"
				@click="showStorage"
			>
				<img src="@/assets/svg/add-storage.svg" alt="icon" style="max-height: 20px" />{{ tt('보관함 관리') }}
			</button>
		</div>
		<div class="institution-storage-noti" v-if="groupInfo.type == 'INSTITUTE'">
			<h6>💡 {{ tt('기관 공용 보관함') }}</h6>
			<p>
				{{ tt('공용 보관함으로 지정하면 모든 멤버가 사용할 수 있습니다.') }}
			</p>
		</div>
		<div class="no-data-box" v-if="noStorages && $root.userPermission.storage && $root.userPermission.storage.update">
			<img src="@/assets/svg/no-data_storage.svg" alt="no-image" />
			<h6 v-text="groupInfo.type == 'INSTITUTE' ? tt('기관에서 공용으로 사용하는 보관함이 없습니다') : tt('지정된 보관함이 없습니다')"></h6>
			<p v-html="tt(groupInfo.type == 'INSTITUTE' ? '<b>[보관함 관리]</b>를 클릭하여 추가해보세요' : '보관함을 지정해보세요')"></p>
		</div>
		<div class="accordin" v-else v-for="(storage, idx) in storages" :key="`storage-${idx}`">
			<button class="Btn__collapse" @click="checkIsFold(idx)" :class="{ checked: checkFoldList[idx] }">
				{{ replaceLocationPathNames(storage.locationPathNames) }}
				<span>{{ storage.storages.length }}{{ tt('개의 보관함') }}</span>
				<i></i>
			</button>
			<ul class="StorageBox__list" :class="boxListClass(idx)" v-if="storage.storages">
				<storage-item
					:storageInfo="innerStorage"
					v-for="(innerStorage, inIdx) in storage.storages"
					:key="`${innerStorage.name}-${inIdx}`"
					:isOrganization="true"
					:userPermission="userPermission"
					@editStorage="showModalEditStorage"
					@deleteStorage="showModalDeleteStorage"
					v-if="storages"
				></storage-item>
			</ul>
		</div>
		<div class="StorageBox__delete" v-if="groupInfo.type != 'INSTITUTE'">
			<div class="right-btn-group" @click="showModalDeleteGroup">
				<button class="Btn__delete">
					{{ tt('그룹 삭제') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import StorageItem from '@/views/institute/location/components/StorageItem.vue'

export default {
	name: 'OrganizationStorageBox',
	components: { StorageItem },
	props: {
		groupInfo: {
			type: Object,
		},
		isStart: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			storages: [],
			checkFoldList: [],
		}
	},
	mounted() {
		this.storages = this.groupInfo.storages
		this.checkFoldList = this.storages.map(() => {
			return true
		})
	},
	watch: {
		groupInfo: {
			deep: true,
			handler(newGroupInfo) {
				this.storages = newGroupInfo.storages
				this.checkFoldList = this.storages.map(() => {
					return true
				})
				// console.warn('group ', newGroupInfo)
				// console.warn('location ', newGroupInfo.locations)
				// console.warn(newGroupInfo.storages)
			},
		},
	},
	computed: {
		noStorages() {
			return this.storages.length === 0
		},
	},
	methods: {
		isChecked(index) {
			return this.checkFoldList[index]
		},
		replaceLocationPathNames(pathName) {
			return pathName.replaceAll(',', ' / ')
		},
		checkIsFold(index) {
			this.checkFoldList[index] = !this.checkFoldList[index]
			this.checkFoldList = [...this.checkFoldList]
		},
		boxListClass(index) {
			return this.checkFoldList[index] ? 'show' : 'hide'
		},
		setGroupsName(groups) {
			return groups.map(item => item.name).join(', ')
		},
		showModalDeleteGroup() {
			this.$emit('showModalDeleteGroup')
		},
		showStorage() {
			this.$emit('showStorage')
		},
		showModalDeleteStorage(data) {
			this.$emit('showModalDeleteStorage', data)
		},
		showModalEditStorage(data) {
			this.$emit('showModalEditStorage', data)
		},
		refresh(id) {
			this.$emit('refresh')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_storage-box.scss';

.institution-storage-noti {
	width: 100%;
	height: 60px;
	background-color: $SUB-YELLOW_100;
	padding: 20px 15px;
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	color: #000;
	h6 {
		font-size: 0.875rem;
		font-weight: 700;
		margin-right: 30px;
		white-space: nowrap;
	}
	p {
		font-size: 0.875rem;
		font-size: 400;
	}
}
</style>
