<template>
	<div class="card chemicalSubstanceStatus" :class="!displayCard ? 'displayOff' : ''">
		<div class="card-title">
			<div class="imgbox">
				<img src="@/assets/svg/dashboard-icon6.svg" alt="icon" />
			</div>
			<h4 class="Btn__hover-balloon-w" @click="goPage">
				{{ tt('화학물질 현황') }}
				<div class="balloon">
					<p>{{ tt('화학물질 물품 목록으로 이동합니다') }}</p>
				</div>
				<button>
					<img src="@/assets/svg/arrow-diagonal.svg" alt="icon" />
				</button>
			</h4>
			<div class="ml-auto switch-setting" v-if="setting == true">
				<h5>{{ tt('대시보드에 노출') }}</h5>
				<label class="switch">
					<input type="checkbox" v-model="displayCard" @change="toggleCard(id, displayCard)" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="card-box">
			<div class="box-title">
				<h5>{{ tt('물품 사용 트렌드') }}</h5>
				<div class="ml-auto radio-btn-wrap">
					<input type="radio" name="chemcialDateSelect" id="chemcialDate1" value="0" v-model="selectDate" /><label for="chemcialDate1">{{
						tt('일')
					}}</label>
					<input type="radio" name="chemcialDateSelect" id="chemcialDate2" value="1" v-model="selectDate" /><label for="chemcialDate2">{{
						tt('주')
					}}</label>
					<input type="radio" name="chemcialDateSelect" id="chemcialDate3" value="2" v-model="selectDate" /><label for="chemcialDate3">{{
						tt('월')
					}}</label>
				</div>
			</div>
			<div class="box-content">
				<div class="item-noti" @click="goHistory()" style="cursor: pointer">
					<p>
						<span v-if="selectDate == 0">{{ tt('오늘 사용량') }}</span
						><span v-else-if="selectDate == 1">{{ tt('금주 사용량') }}</span
						><span v-else>{{ tt('이번 달 사용량') }}</span>
					</p>
					<h3>{{ mathRound(trend.current) }}kg</h3>
					<span :class="trend.contrast > 0 ? 'up' : trend.contrast < 0 ? 'down' : ''"
						><span class="triangle"></span>{{ mathRound(trend.contrast) }}kg(<span v-if="selectDate == 0">{{ tt('전일대비') }}</span
						><span v-else-if="selectDate == 1">{{ tt('전주대비') }}</span
						><span v-else>{{ tt('전달대비') }}</span
						>)</span
					>
				</div>
				<div class="chart-column-wrap" ref="chart-column-wrap" style="cursor: default">
					<div v-if="!showChart" class="list-no-data">
						<img src="@/assets/svg/no-data_item.svg" alt="icon" />
						<b>{{ tt('사용한 물품이 없습니다') }}</b>
					</div>
					<GChart
						:class="showChart ? '' : 'noChart'"
						type="ColumnChart"
						:data="trendChartData"
						:options="columnChartOptions"
						class="column-chart"
						style="width: 100%"
					/>
				</div>
			</div>
		</div>
		<div class="card-box">
			<div class="box-title">
				<h5>{{ tt('유효기간별 보유현황') }}</h5>
			</div>
			<div class="box-content">
				<div class="horizational-chart-list">
					<div class="chart-box">
						<img v-if="!showExpireChart" src="@/assets/svg/no-data_piechart.svg" />
						<GChart ref="expireChart" type="PieChart" :data="expireChartData" :options="pieChartOptions" :events="chartEvents" />
					</div>
					<div class="chart-list">
						<ul>
							<li v-for="(item, index) in list.expireDate" :key="index" @click="goInventory({ expireDate: [item.id] })">
								<div class="circle" :class="matchExpireColors(item.id)"></div>
								<p>
									{{ matchExpireTitle(item.id) }}
								</p>
								<h5>{{ item.count.toLocaleString() }}개</h5>
							</li>
							<li class="total">
								<p>Total:</p>
								<h5>{{ totalCount(list.expireDate) }}{{ tt('개') }}</h5>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="card-box">
			<div class="box-title">
				<h5>{{ tt('안전재고량 현황') }}</h5>
			</div>
			<div class="box-content">
				<div class="horizational-chart-list">
					<div class="chart-box">
						<img v-if="!showThresholdChart" src="@/assets/svg/no-data_piechart.svg" />
						<GChart ref="thresholdChart" type="PieChart" :data="thresholdChartData" :options="pieChartOptions2" :events="chartEvents2" />
					</div>
					<div class="chart-list">
						<ul>
							<li v-for="(item, index) in list.threshold" :key="index" @click="goInventory({ threshold: [item.id] })">
								<div class="circle" :class="matchThresholdColors(item.id)"></div>
								<p>
									{{ matchThresholdTitle(item.id) }}
								</p>
								<h5>{{ item.count.toLocaleString() }}개</h5>
							</li>
							<li class="total">
								<p>Total:</p>
								<h5>{{ totalCount(list.threshold) }}{{ tt('개') }}</h5>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { GChart } from 'vue-google-charts'
import { Classification } from '@/utils/define/ItemCode'
import PageName from '@/utils/define/PageName'

export default {
	name: 'CardChemicalSubstance',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		instId: {
			type: String,
			default: '',
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Object,
			default: () => {},
		},
		toggleCard: {
			type: Function,
			default: () => {},
		},
		trend: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		GChart,
	},
	mounted() {
		this.displayCard = this.visible
	},
	watch: {
		selectDate(val) {
			const params = {
				instId: this.$route.params.instituteId,
				scope: ['day', 'week', 'month'][val],
			}
			this.lookupUseTrendList(params)
		},
	},
	computed: {
		thresholdChartData() {
			return this.matchThresholdData()
		},
		expireChartData() {
			return this.matchExpireData()
		},
		trendChartData() {
			return this.matchTrendData()
		},
		columnChartOptions() {
			return {
				legend: 'none',
				colors: ['#0DC577'],
				backgroundColor: {
					fill: 'transparent',
					stroke: 'transparent',
				},
				height: 185,
				bar: { groupWidth: '80%' },
				vAxis: {
					gridlines: {},
					gridlineColor: 'transparent',
					textPosition: 'none',
					baselineColor: '#ddd',
				},
				hAxis: {
					gridlineColor: 'transparent',
					baselineColor: 'transparent',
					textStyle: {
						color: '#666',
						fontSize: 10,
					},
					maxTextLines: 2,
					ticks: this.ticks,
				},
				tooltip: {
					isHtml: true,
				},
			}
		},
	},
	data() {
		return {
			pieChartOptions: {
				legend: 'none',
				colors: ['#3cb58d', '#febf20', '#FF3B31'],
				width: 210,
				height: 210,
				chartArea: {
					width: '90%',
					height: '90%',
				},
				backgroundColor: 'transparent',
				pieSliceBorderColor: 'transparent',
				pieSliceText: 'none',
				pieHole: 0.7,
				tooltip: {
					isHtml: true,
				},
			},
			pieChartOptions2: {
				legend: 'none',
				colors: ['#3cb58d', '#FF5746', '#BDBDBD'],
				width: 210,
				height: 210,
				chartArea: {
					width: '90%',
					height: '90%',
				},
				backgroundColor: 'transparent',
				pieSliceBorderColor: 'transparent',
				pieSliceText: 'none',
				pieHole: 0.7,
				tooltip: {
					isHtml: true,
				},
			},
			chartEvents: {
				select: e => {
					console.log(e)
					const chart = this.$refs.expireChart.chartObject
					const row = chart.getSelection()[0].row
					this.goInventory({ expireDate: [this.list.expireDate[row].id] })
				},
			},
			chartEvents2: {
				select: e => {
					console.log(e)
					const chart = this.$refs.thresholdChart.chartObject
					const row = chart.getSelection()[0].row
					this.goInventory({ threshold: [this.list.threshold[row].id] })
				},
			},
			displayCard: true,
			selectDate: 0,
			ticks: [],
			divide: 3,
			trendData: [],
			showChart: true,
			showThresholdChart: true,
			showExpireChart: true,
		}
	},
	methods: {
		...mapActions('dashboard', ['lookupUseTrendList', 'lookupUseTrendSummary']),
		matchTrendData() {
			this.trendData = []
			const hAxisArr = []
			const dateArr = []
			this.showChart = false

			if (this.trend.list != null) {
				const item = this.trend.list
				const tickArr = []

				for (let i = 0; i < item.length; i++) {
					const trendArr = [i, item[i].consumed, item[i].date + '\n' + '\n' + item[i].consumed + 'kg']
					if (item[i].consumed > 0) {
						this.showChart = true
					}
					this.trendData.push(trendArr)
					dateArr.push(this.dateFormat(item[i].date))
					hAxisArr.push(i)
				}

				const divide = this.divide
				hAxisArr.forEach(function (n) {
					if (n % divide == 0) {
						tickArr.push({
							v: n,
							f: dateArr[n],
						})
					}
				})

				this.ticks = tickArr

				this.trendData.unshift([this.tt('날짜'), this.tt('사용량'), { type: 'string', role: 'tooltip', p: { html: true } }])
			}

			return this.trendData
		},
		matchThresholdTitle(volume) {
			if (volume == '<0') {
				return this.tt('안전재고량 미만')
			} else if (volume == '>=0') {
				return this.tt('안전재고량 이상')
			} else {
				return this.tt('안전재고량 미설정')
			}
		},
		matchThresholdData() {
			const thresholdData = []
			this.showThresholdChart = false

			if (this.list.threshold != null) {
				for (let item of this.list.threshold) {
					const thresholdArr = [
						this.matchThresholdTitle(item.id),
						item.count,
						this.matchThresholdTitle(item.id) + ':' + '\n' + '\n' + item.count + this.tt('개'),
					]
					if (item.count > 0) {
						this.showThresholdChart = true
					}
					thresholdData.push(thresholdArr)
				}
			}

			thresholdData.unshift(['title', 'count', { type: 'string', role: 'tooltip', p: { html: true } }])

			return thresholdData
		},
		matchThresholdColors(volume) {
			if (volume == '>=0') {
				return 'darkgreen'
			} else if (volume == '<0') {
				return 'red'
			} else {
				return 'gray'
			}
		},
		matchExpireTitle(date) {
			if (date == '>30') {
				return this.tt('30일 이후')
			} else if (date == '<=30') {
				return this.tt('30일 이전')
			} else {
				return this.tt('만료됨')
			}
		},
		matchExpireData() {
			const expireData = []
			this.showExpireChart = false

			if (this.list.expireDate != null) {
				for (let item of this.list.expireDate) {
					const expireArr = [
						this.matchExpireTitle(item.id),
						item.count,
						this.matchExpireTitle(item.id) + ':' + '\n' + '\n' + item.count.toLocaleString() + this.tt('개'),
					]
					if (item.count > 0) {
						this.showExpireChart = true
					}
					expireData.push(expireArr)
				}
			}

			expireData.unshift(['title', 'count', { type: 'string', role: 'tooltip', p: { html: true } }])

			return expireData
		},
		matchExpireColors(date) {
			if (date == '>30') {
				return 'darkgreen'
			} else if (date == '<=30') {
				return 'orange'
			} else {
				return 'red'
			}
		},
		dateFormat(date) {
			if (this.selectDate == 1) {
				this.divide = 5
				return date.slice(0, date.indexOf('('))
			} else if (this.selectDate == 2) {
				this.divide = 3
				return date.slice(0, 8)
			} else {
				this.divide = 7
				return date
			}
		},
		goPage() {
			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					classification: 'CAT_CHEMICAL',
				},
			})
		},
		mathRound(num) {
			return Math.round(num * 1000) / 1000
		},
		getDateStr(date) {
			var year = date.getFullYear()
			var month = date.getMonth() + 1
			var day = date.getDate()

			month = month < 10 ? '0' + String(month) : month
			day = day < 10 ? '0' + String(day) : day

			return year + '-' + month + '-' + day
		},
		goHistory() {
			let day = new Date()
			let monthOfYear = day.getMonth()
			let dayOfMonth = day.getDate()
			if (this.selectDate == 1) {
				day.setDate(dayOfMonth - 7)
			} else if (this.selectDate == 2) {
				day.setMonth(monthOfYear - 1)
			}

			let startDatetime = this.getDateStr(day)
			let endDatetime = this.getDateStr(new Date())
			let query = {
				startDatetime: startDatetime,
				endDatetime: endDatetime,
				types: ['CONSUME'],
			}

			this.$router.push({
				name: PageName.InventoryGen2.HistoryList,
				query: query,
			})
		},
		totalCount(list) {
			let sum = 0
			if (list) {
				list.forEach(item => {
					sum = sum + item.count
				})
			}

			return sum.toLocaleString()
		},
		goInventory(option) {
			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					classification: 'CAT_CHEMICAL',
					...option,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';

.total {
	border-bottom: 0 !important;
	p {
		font-weight: 600 !important;
	}
}
</style>
