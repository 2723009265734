<template>
	<div class="footer-wrap print-hide">
		<div class="left-wrap">
			<button v-if="isShowEdit" class="button-gray" @click="$emit('edit')">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-edit.svg" />
				<p>{{ tt('편집') }}</p>
			</button>
			<button v-if="isShowDelete" class="button-gray" @click="$emit('delete')">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-round-delete.svg" />
				<p>{{ tt('삭제') }}</p>
			</button>
		</div>
		<div class="right-wrap">
			<button class="button-gray" @click="searchStore">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-cart.svg" />
				<p>{{ tt('랩매니저 스토어에서 찾기') }}</p>
			</button>

			<button v-if="isShowPrintSticker" class="button-gray" @click="$refs['modal-print-sticker'].show()">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-print.svg" />
				<p>{{ tt('물품ID 스티커 출력') }}</p>
			</button>

			<button v-if="isShowGHSButton && !isGhsRegistered" class="button-gray" @click="$emit('makeGHS')">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-ghs.svg" />
				<p>{{ tt('GHS 라벨 생성') }}</p>
			</button>
			<button v-else-if="isShowGHSButton && isGhsRegistered" class="button-gray" @click="$emit('manageGHS')">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-ghs.svg" />
				<p>{{ tt('GHS 라벨 관리') }}</p>
			</button>
		</div>
		<modal-print-sticker ref="modal-print-sticker" :selectedCargoId="[item.id]"></modal-print-sticker>
	</div>
</template>
<script>
import ModalPrintSticker from '@/views/purchase/cargo/components/cargoList/modal/ModalPrintSticker.vue'
export default {
	name: 'DetailFooter',
	components: { ModalPrintSticker },
	props: {
		item: {
			type: Object,
			default: null,
		},
		isShowEdit: {
			type: Boolean,
			default: true,
		},
		isShowDelete: {
			type: Boolean,
			default: true,
		},
		isShowPrintSticker: {
			type: Boolean,
			default: false,
		},
		isShowGHSButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		isGhsRegistered() {
			if (this.item) {
				return !!this.item.inventoryFields.ghs
			} else {
				return null
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		searchStore() {
			this.$root.goToStore(`search/list`, { keyword: this.item.name, type: 'NAME' })
		},
	},
}
</script>

<style lang="scss" scoped>
.footer-wrap {
	display: flex;
	position: fixed;
	bottom: 0;
	width: calc(100% - 250px);
	height: 60px;
	background-color: white;
	border-top: 1px solid $DIVIDER;
	justify-content: space-between;
	padding: 15px 30px;
	align-items: center;
	z-index: 200;
	.left-wrap {
		display: flex;
		gap: 10px;
	}
	.right-wrap {
		display: flex;
		gap: 10px;
	}
}

.button-gray {
	justify-content: center;
	background: $GREY_4;
	display: flex;
	border-radius: 4px;
	padding: 5px 10px;
	height: 30px;
	font-size: 14px;
	font-weight: 700;
	&:hover {
		background: $HOVER_BUTTON_COMMON;
	}
	.image-icon {
		width: 24px;
		height: 24px;
		margin-right: 2px;
	}
}
</style>
