<template>
	<b-modal ref="modal" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop no-fade>
		<div class="body">
			<lottie-animation ref="lottie-animation" :animationData="require('@/assets/json/lm_loading_lottie.json')" loop></lottie-animation>
			<h5>{{ tt(title) }}</h5>
			<p v-if="!noMsg">{{ tt('잠시만 기다려주시기 바랍니다.') }}</p>
		</div>
	</b-modal>
</template>
<script>
import LottieAnimation from 'lottie-web-vue'
export default {
	components: {
		LottieAnimation,
	},
	props: {
		title: {
			type: String,
			default: '로딩 중입니다.',
		},
		noMsg: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},

		playLoading() {
			this.$refs['lottie-animation'].anim.play()
		},
		stopLoading() {
			this.$refs['lottie-animation'].anim.stop()
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.modal-dialog {
		max-width: 335px;
		.modal-body {
			padding: 40px;
		}
	}
}
.body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	h5 {
		font-size: 18px;
		color: $DEFAULT;
	}
	p {
		margin-top: 10px;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
