<template>
	<div class="label-wrap">
		<div class="list-wrap">
			<p class="list-title">{{ tt('물리적 위험성') }}</p>
			<button-list ref="list-p" :list="physicalCodeList" @change="list => handleChange('physical', list)"></button-list>
		</div>
		<div class="list-wrap">
			<p class="list-title">{{ tt('건강 유해성') }}</p>
			<button-list ref="list-h" :list="healthCodeList" @change="list => handleChange('health', list)"></button-list>
		</div>
		<div class="list-wrap">
			<p class="list-title">{{ tt('환경 유해성') }}</p>
			<button-list ref="list-e" :list="environmentCodeList" @change="list => handleChange('environment', list)"></button-list>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ButtonList from './ButtonList.vue'

export default {
	name: 'LabelList',
	components: { ButtonList },
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('modal/ghs', ['selectedItem', 'physicalCodeList', 'healthCodeList', 'environmentCodeList']),
	},
	watch: {
		selectedItem: {
			deep: true,
			handler(value) {
				if (value) {
					this.setData()
				}
			},
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('modal/ghs', ['setSelectedItem']),
		handleChange(code, list) {
			const newSelectedItem = this.selectedItem ? { ...this.selectedItem } : {}
			newSelectedItem[code] = list
			this.setSelectedItem(newSelectedItem)
		},
		setData() {
			this.$refs['list-p'].setData(this.selectedItem?.physical ?? [])
			this.$refs['list-h'].setData(this.selectedItem?.health ?? [])
			this.$refs['list-e'].setData(this.selectedItem?.environment ?? [])
		},
	},
}
</script>

<style lang="scss" scoped>
.label-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
	.list-wrap {
		display: flex;
		flex-direction: column;
		.list-title {
			font-weight: 700;
			font-size: 12px;
			margin-bottom: 10px !important;
		}
	}
}
</style>
