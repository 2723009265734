<template>
	<tr v-if="item">
		<td class="checkbox"><input type="checkbox" :checked="isChecked" @click="selectItem" /></td>
		<td class="no">{{ leadingZeros(idx, 3) }}</td>
		<td class="type">
			<div class="content">
				<p class="sticker-type">{{ item.type }}</p>
				<p class="size">{{ item.paperSpec }}</p>
			</div>
		</td>
		<td class="preview">
			<div class="preview-content" @click="$refs['viewer'].show()">
				<div class="preview-image" ref="previewImage" v-html="itemImage" v-if="!isTypeQr"></div>
				<div v-else class="qr-wrap">
					<img width="70" height="70" :src="itemImage" />
				</div>
				<p class="code">{{ firstIdentifierCode }}</p>
			</div>
		</td>
		<modal-multi-image-viewer
			ref="viewer"
			title="미리보기"
			:imageList="item.image"
			:isRawSvg="!isTypeQr"
			:isCaption="isTypeQr"
			:captionList="identifierList"
		></modal-multi-image-viewer>
	</tr>
</template>
<script>
import ModalMultiImageViewer from '@/views/common/components/modalGen2/ModalMultiImageViewer.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'TableItem',
	components: { ModalMultiImageViewer },
	props: {
		idx: {
			type: Number,
			default: -1,
		},
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('modal/printSticker', ['selectedItem']),
		isChecked() {
			return this.selectedItem?.includes(this.item.id)
		},
		isTypeQr() {
			return this.item.type === 'QR'
		},
		identifierList() {
			return this.item?.qrSticker?.map(qr => qr.identifier.slice(0, 12))
		},
		itemImage() {
			return this.item?.image[0]
		},
		firstIdentifierCode() {
			return this.identifierList?.[0]
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('modal/printSticker', ['setSelectedItem']),
		// refs: http://mwultong.blogspot.com/2007/01/0-leading-zero-to-number-javascript.html
		leadingZeros(n, digits) {
			var zero = ''
			n = n.toString()

			if (n.length < digits) {
				for (var i = 0; i < digits - n.length; i++) zero += '0'
			}
			return zero + n
		},
		resizeSvg() {
			if (this.$refs['previewImage']) {
				this.$refs['previewImage'].childNodes[1].classList.add('svg')
			}
		},
		selectItem() {
			this.setSelectedItem(this.isChecked ? this.selectedItem.filter(item => item !== this.item.id) : [...this.selectedItem, this.item.id])
		},
	},
}
</script>

<style lang="scss" scoped>
.qr-wrap {
	border: 1px solid black;
}

::v-deep .svg {
	width: 100px;
	height: 80px;
}

td + td {
	border-left: 1px solid $DIVIDER;
}

tr {
	display: flex;
	td {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100px;
		border-bottom: 1px solid $DIVIDER;
		&.checkbox {
			width: 50px;
		}
		&.no {
			width: 60px;
		}
		&.type {
			width: 130px;
			.content {
				text-align: center;
				.sticker-type {
					color: $BLUE;
					font-size: 14px;
					font-weight: 400;
				}
				.size {
					font-size: 12px;
					font-weight: 400;
				}
			}
		}
		&.preview {
			width: 110px;
			text-align: center;
			.preview-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				.preview-image {
					width: 100px;
					height: 80px;
				}
				.code {
					font-size: 10px;
				}
			}
		}
	}
}
</style>
