var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "datepicker"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.title)) + ":")]), _c('date-picker', {
    attrs: {
      "valueType": "format",
      "range": "",
      "lang": _vm.lang,
      "placeholder": _vm.placeholder
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }