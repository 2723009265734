import { mapState, mapActions } from 'vuex'

export default {
	computed: {
		...mapState('lms', ['lmsInstituteId', 'lmsInstitute', 'requirement', 'deliveryList']),
	},
	methods: {
		...mapActions('lms', ['getLMSInstituteId', 'getRequirement', 'getDeliveryList', 'addAddress', 'updateAddress']),
		async initLMSID() {
			if (!this.lmsInstituteId) {
				await this.getLMSInstituteId()
			}
		},
	},
	created() {
		this.initLMSID()
	},
}
