<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade no-close-on-esc>
		<h1>{{ tt('이대로 주문 하시겠습니까?') }}</h1>
		<p v-html="tt('라이선스 없이 주문할 경우 할인적용을 받아볼 수 없습니다.<br />(라이선스 구매시 최대 20% 할인)')"></p>
		<table>
			<thead>
				<tr>
					<th>{{ tt('구분') }}</th>
					<th>{{ tt('할인가') }}</th>
					<th>{{ tt('정상 판매가') }}</th>
				</tr>
			</thead>
			<tbody v-if="items?.length > 0">
				<tr>
					<td>{{ tt('결제 예정금액') }}</td>
					<td style="text-decoration: line-through; color: #ff3b31">{{ tt('${1}원', formatNumberLocale(expectedSalePayPrice)) }}</td>
					<td>
						<b>{{ tt('${1}원', formatNumberLocale(expectedNormalPayPrice)) }}</b>
					</td>
				</tr>
			</tbody>
		</table>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<button class="button-primary" @click="change">
					{{ tt('정상 판매가로 변경하기') }}
				</button>
				<button class="button-white" @click="prev">
					{{ tt('이전으로') }}
				</button>
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: [],
		},
	},
	computed: {
		expectedSalePayPrice() {
			return (
				this.items
					.map(it => it.sellingUnitPrice * it.quantity)
					.reduce((acc, cur) => {
						return (acc += cur)
					}) * 1.1
			)
		},
		expectedNormalPayPrice() {
			return (
				this.items
					.map(it => it.unitPrice * it.quantity)
					.reduce((acc, cur) => {
						return (acc += cur)
					}) * 1.1
			)
		},
	},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		change() {
			this.$emit('change')
			this.hide()
		},
		prev() {
			this.$emit('prev')
			this.hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
table {
	width: 100%;
	th,
	td {
		height: 40px;
		text-align: center;
		border: 1px solid $INPUT;
		&:first-child {
			background: $GREY_2;
			border-left: none;
		}
		&:last-child {
			border-right: none;
		}
	}
	th {
		background: $GREY_2;
	}
}
::v-deep {
	.modal-dialog {
		max-width: 580px;
		width: 580px;
	}
	.modal-content {
		border-radius: 20px;
	}
	.modal-body {
		overflow: hidden;
		align-items: center;
		padding: 0px 50px;
		padding-bottom: 20px;
		h1 {
			font-size: 32px;
		}
		p {
			font-size: 16px;
			color: $DEFAULT;
			text-align: center;
		}
	}
	.modal-footer-wrap {
		flex-direction: column;
		gap: 10px;
		padding-bottom: 30px;
		button {
			max-width: 260px;
			width: 260px;
			font-size: 16px !important;
			height: 48px !important;
		}
	}
}
</style>
