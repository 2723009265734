<template>
	<div class="payment-requirement-shipping">
		<div class="content">{{ tt('주문을 통해 제품을 수령하실 배송지 정보를 입력해 주세요') }}</div>
		<div v-if="isEmpty" class="address-regist-form">
			<input-form-item
				ref="name"
				v-model="info.name"
				direction="column"
				title="배송지명"
				placeholder="배송지명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0"
			/>
			<input-form-item
				ref="instituteName"
				v-model="info.instituteName"
				direction="column"
				title="학교 / 기업명"
				placeholder="학교 / 기업명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0"
			/>
			<input-form-item
				ref="groupName"
				v-model="info.groupName"
				direction="column"
				title="학과 / 부서명"
				placeholder="학과 / 부서명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0"
			/>
			<input-form-item
				ref="address"
				:postcode="info.postcode"
				:address="info.address"
				:addressDetail="info.addressDetail"
				direction="column"
				type="address"
				title="주소"
				:inputStyle="inputStyle"
				@searchAddress="onSearchAddress"
			/>
			<!-- <div class="checkbox-wrapper">
				<input checked type="checkbox" id="save" name="save" class="checkbox" disabled />
				<label for="save">{{ tt('기본 배송지로 저장') }}</label>
			</div> -->
			<div class="button-wrapper">
				<button class="btn btn-primary lg" @click="onSave">{{ tt('다음') }}</button>
			</div>
		</div>
		<div v-else>
			<div v-for="address in deliveryList" :key="address.id" class="address-list">
				<div class="address-item">
					<div>
						<span v-if="address.isDefault" class="status">{{ tt('기본 배송지') }}</span>
					</div>
					<div class="name">
						{{ address.name }}
					</div>
					<div class="address">
						{{ addressFullName(address) }}
					</div>
					<div class="institute">
						{{ instituteFullName(address) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import { mapState, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import MixinStore from '@/mixins/store'

export default {
	name: 'PaymentRequirementShipping',
	components: {
		InputFormItem,
	},
	mixins: [MixinStore],
	data() {
		return {
			inputStyle: {
				width: '466px',
			},
			info: {
				id: 0,
				name: '',
				instituteName: '',
				groupName: '',
				postcode: '',
				address: '',
				addressDetail: '',
			},
		}
	},
	computed: {
		isEmpty() {
			return this.$_.isEmpty(this.deliveryList)
		},
	},
	created() {
		this.fetchAddressList()
	},
	methods: {
		async fetchAddressList() {
			await this.getDeliveryList()
		},
		onSearchAddress({ postcode, address, addressDetail }) {
			this.info.postcode = postcode
			this.info.address = address
			this.info.addressDetail = addressDetail
			this.info = this.$_.cloneDeep(this.info)
		},
		async onSave(e) {
			e.preventDefault()
			if (!this.validate()) return

			try {
				await this.addAddress({ ...this.info, isDefault: true })
				await this.fetchAddressList()
				this.$emit('next')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		addressFullName(address) {
			return `[${address.postcode}] ${address.address} ${address.addressDetail}`
		},
		instituteFullName(address) {
			return `${address.instituteName} / ${address.groupName}`
		},
		validate() {
			let valid = true
			if (!this.$refs['name'].validate()) valid = false
			if (!this.$refs['instituteName'].validate()) valid = false
			if (!this.$refs['groupName'].validate()) valid = false
			if (!this.$refs['address'].validate()) valid = false
			return valid
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-requirement-shipping {
	.content {
		font-size: 0.875rem;
	}
	.address-regist-form {
		.checkbox-wrapper {
			display: flex;
			align-items: center;
			gap: 11px;
			margin-bottom: 40px;
			label {
				font-size: 0.875rem;
				font-weight: 500;
				color: $COLOR_000;
			}
		}
		.button-wrapper {
			margin-top: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			button {
				width: 400px;
				height: 48px;
				&.btn-primary {
					background-color: $LAB_YELLOW;
				}
			}
		}
	}
	.address-list {
		margin-top: 20px;
		&:not(:first-child) {
			margin-top: 5px;
		}
		.address-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border: 1px solid $INPUT;
			border-radius: 5px;
			background-color: $GREY_2;
			font-weight: 700;
			padding: 20px;

			.status {
				padding: 1px 5px;
				background: $DIVIDER;
				border-radius: 5px;
				font-size: 0.75rem;
				margin-bottom: 5px;
			}

			.address {
				margin-top: 5px;
				font-size: 0.875rem;
				font-weight: 500;
				color: $SECONDARY_2;
			}
			.institute {
				margin-top: 5px;
				font-size: 0.875rem;
				font-weight: 500;
				color: $PLACEHOLDER;
			}
		}
	}
}
</style>
