<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddAuthorityPopup">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt('권한 생성') }}
					</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field" :class="{ error: check }">
						<p>{{ tt('권한명') }}<span class="required">*</span></p>
						<input type="text" :placeholder="tt('권한명을 입력해주세요')" v-model="authorityName" />
						<span class="noti">{{ tt('이미 동일한 이름의 권한이 있습니다.') }}</span>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="submit">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import institutePermissionAPI from '@/services/api/institute/permission'
import { mapState } from 'vuex'

export default {
	name: 'ModalAddPermission',
	mixins: [MixinModal],
	data() {
		return {
			authorityName: '',
			check: false,
		}
	},
	computed: {
		...mapState('user', ['institute']),
	},
	methods: {
		show() {
			this.isShow = true
			this.authorityName = ''
		},
		close() {
			this.$emit('isHide')
		},
		async submit() {
			const response = await institutePermissionAPI.lookupPermissionList(this.institute.id, 0, undefined, '')
			const authority = response.data.list.map(x => x.name)
			if (!authority.includes(this.authorityName)) {
				this.$emit('isHide', this.authorityName)
				this.check = false
			} else {
				this.$root.toast('권한 생성 실패', '권한명을 확인해주세요', 'error')
				this.check = true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__input-field {
	.noti {
		font-size: 0.75rem;
		background-repeat: no-repeat;
		background-size: 12px;
		padding-left: 17px;
		width: 100%;
		background-position: top 3px left 0;
		font-weight: 400;
		height: 20px;
		visibility: hidden;
		display: none;
	}
	&.error {
		.noti {
			background-image: url(~@/assets/svg/noti-error.svg);
			color: $RED;
			visibility: visible;
			display: block;
		}
	}
}
</style>
