<template>
	<div class="legacy-item-wrapper" @click.stop="handleClick">
		<div class="content-wrapper">
			<div class="item-header">
				<div class="icon-wrapper">
					<img src="@/assets/svg/migration/icon-new-lab-manager.svg" />
				</div>
				<div class="text-wrapper">
					<div class="text-title" v-html="titleText"></div>
					<div class="text-content" v-html="contentText"></div>
				</div>
				<div class="arrow-wrapper">
					<img src="@/assets/svg/arrow/icon-arrow-right.svg" />
				</div>
			</div>
			<div class="item-institute-info">
				<ul class="item-text-list">
					<li class="item-text">{{ tt('기관명') }} : {{ item.name }}</li>
					<li class="item-text">{{ tt('연구실') }} : {{ item.groupNames }}</li>
					<li class="item-text">{{ tt('요청인') }} : {{ item.userName }}</li>
					<li class="item-text">
						{{ tt('요청 일시') }} :
						{{ formatDatetime(item.startDatetime) }}
					</li>
				</ul>
			</div>
			<div class="item-license-info">
				<ul class="item-text-list">
					<li class="item-text" v-html="licenseText"></li>
					<li class="item-text" v-if="!isStatusActive" v-html="licenseCountText"></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			titleText: this.tt('랩매니저 PRO 전환하기'),
		}
	},
	computed: {
		...mapState('license', ['type', 'status']),
		isStatusActive() {
			return [this.status.ACTIVATE].includes(this.item.license.status)
		},
		contentText() {
			return `<b>${this.item.userName}</b>${this.tt('님이 만드신 새로운 기관으로 가입합니다.')}`
		},
		licenseText() {
			let text = `${this.tt('라이선스')}: `
			const licenseName = this.item.license.name
			const licenseStatus = this.item.license.status
			switch (licenseStatus) {
				case this.status.ACTIVATE:
				case this.status.FULL:
					text += `${licenseName}(${this.tt('활성화')}: ${this.formatDate(this.item.license.expireDatetime)})`
					break
				case this.status.EXPIRED:
					text += `<b class="red">${licenseName}(${this.tt('만료됨')})</b>`
					break
			}
			return text
		},
		licenseCountText() {
			let text = `${this.tt('활성화된 멤버')} / ${this.tt('라이선스 수')}: `
			const licenseStatus = this.item.license.status
			const licenseQuantity = this.item.license.quantity
			const memberCount = this.item.memberCount
			const countText = `${memberCount} / ${licenseQuantity}`
			switch (licenseStatus) {
				case this.status.FULL:
					text += `<b class="red">${countText}(${this.tt('라이선스 수 부족')})</b>`
					break
				case this.status.ACTIVATE:
				case this.status.EXPIRED:
					text += countText
					break
			}
			return text
		},
	},
	methods: {
		handleClick() {
			this.$emit('click', this.item)
		},
	},
}
</script>

<style lang="scss" scoped>
.red::v-deep {
	color: red;
}

.legacy-item-wrapper {
	cursor: pointer;
	width: 600px;
	left: 0px;
	top: 296px;
	border-radius: 16px;
	margin: 20px;
	.content-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		border: 2px solid $TEXT_YELLOW;
		box-shadow: 0px 1px 2px 0px #0000001a;
		height: 100%;
		padding: 20px;
		.item-header {
			display: flex;
			padding: 0 0 10px 0;
			align-items: center;

			.icon-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: -14px;
				margin-left: -19px;
			}
			.text-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 5px 10px;
				width: 100%;
				height: 58px;

				.text-title {
					font-size: 18px;
					font-weight: 700;
					line-height: 26px;
					letter-spacing: 0px;
					text-align: left;
				}
				.text-content {
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: left;
					color: $SECONDARY_2;
				}
			}
			.arrow-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}
		}

		.item-institute-info,
		.item-license-info {
			background: $GREY_0;
			border: 1px solid $DIVIDER;
			width: 100%;
			border-radius: 10px;
			padding: 15px;
			margin: 5px 0px;

			.item-text-list {
				margin-left: 25px;
				.item-text {
					list-style-type: initial;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					text-align: left;
					color: $DEFAULT;
				}
			}
		}
	}
}
</style>
