var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_c('form-text-single-line', {
    attrs: {
      "name": '항목명',
      "value": _vm.field.name,
      "placeholder": '항목명을 입력해 주세요',
      "required": true
    },
    on: {
      "input": function input(value) {
        return _vm.inputFormName(value);
      }
    }
  }), _c('form-select', {
    attrs: {
      "name": '입력방식',
      "required": true,
      "optionList": _vm.articleList.map(function (it) {
        return it.text;
      }),
      "placeholder": _vm.tt('입력방식을 선택해주세요'),
      "value": _vm.selectedText
    },
    on: {
      "input": function input(text) {
        return _vm.inputFormValue(text);
      }
    }
  }), _vm.field.varType === 'text_single_line_with_unit' ? _c('form-text-single-line', {
    attrs: {
      "name": '단위',
      "value": _vm.field.unit[0],
      "placeholder": '단위를 입력해주세요',
      "required": true
    },
    on: {
      "input": function input(value) {
        return _vm.inputFormUnit(value);
      }
    }
  }) : _vm._e(), _vm.field.varType === 'select_option' ? _c('div', {
    staticClass: "option-wrap"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('옵션'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _vm._l(_vm.field.option, function (item, index) {
    return _c('div', {
      key: "option-".concat(index),
      staticClass: "input-wrapper"
    }, [_c('input', {
      attrs: {
        "type": "text",
        "placeholder": _vm.tt('옵션을 입력해주세요')
      },
      domProps: {
        "value": item
      },
      on: {
        "input": function input($event) {
          return _vm.handleChange($event, index);
        }
      }
    }), _c('button', {
      staticClass: "Btn-delete-item btn",
      on: {
        "click": function click($event) {
          return _vm.deleteOption(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/recycle-bin.svg")
      }
    })])]);
  }), _c('button', {
    on: {
      "click": _vm.addOption
    }
  }, [_vm._v("+" + _vm._s(_vm.tt('추가하기')))])], 2) : _vm._e(), _c('div', {
    staticClass: "flex"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.field.isRequire,
      expression: "field.isRequire"
    }],
    attrs: {
      "type": "checkbox",
      "id": "setting"
    },
    domProps: {
      "checked": Array.isArray(_vm.field.isRequire) ? _vm._i(_vm.field.isRequire, null) > -1 : _vm.field.isRequire
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.field.isRequire,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.field, "isRequire", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.field, "isRequire", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.field, "isRequire", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "setting-label",
    attrs: {
      "for": "setting"
    }
  }, [_vm._v(_vm._s(_vm.tt('필수 입력값으로 설정')) + " "), _c('button', {
    staticClass: "Btn__hover-balloon-w",
    attrs: {
      "type": "button"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/question-mark.svg"),
      "alt": "icon"
    }
  }), _c('div', {
    staticClass: "balloon"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('필수 입력값으로 설정')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('필수 입력값으로 설정하면 물품 등록 시 해당 정보를 필수적으로 입력해야합니다.')) + " ")])])])])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }