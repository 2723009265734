var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "id": _vm.id,
      "centered": "",
      "title": _vm.tt(_vm.modalTitle),
      "ok-title": _vm.tt('저장'),
      "ok-variant": "warning",
      "ok-only": "",
      "ok-disabled": _vm.isDisabledBtn,
      "button-size": "lg",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "show": _vm.onShow,
      "ok": _vm.onSave
    }
  }, [_c('div', [_c('input-form-item', {
    staticStyle: {
      "padding": "0",
      "width": "100%"
    },
    attrs: {
      "direction": "column",
      "title": "배송지명",
      "placeholder": "배송지명을 입력해주세요",
      "inputStyle": _vm.inputStyle,
      "autofocus": !_vm.isEdit,
      "required": ""
    },
    model: {
      value: _vm.info.name,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "name", $$v);
      },
      expression: "info.name"
    }
  }), _c('input-form-item', {
    staticStyle: {
      "padding": "0",
      "width": "100%",
      "margin-top": "10px"
    },
    attrs: {
      "direction": "column",
      "title": "학교 / 기업명",
      "placeholder": "학교 / 기업명을 입력해주세요",
      "inputStyle": _vm.inputStyle,
      "required": ""
    },
    model: {
      value: _vm.info.instituteName,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "instituteName", $$v);
      },
      expression: "info.instituteName"
    }
  }), _c('input-form-item', {
    staticStyle: {
      "padding": "0",
      "width": "100%",
      "margin-top": "10px"
    },
    attrs: {
      "direction": "column",
      "title": "학과 / 부서명",
      "placeholder": "학과 / 부서명을 입력해주세요",
      "inputStyle": _vm.inputStyle,
      "required": ""
    },
    model: {
      value: _vm.info.groupName,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "groupName", $$v);
      },
      expression: "info.groupName"
    }
  }), _c('input-form-item', {
    attrs: {
      "postcode": _vm.info.postcode,
      "address": _vm.info.address,
      "addressDetail": _vm.info.addressDetail,
      "direction": "column",
      "type": "address",
      "title": "주소",
      "inputStyle": _vm.inputStyle,
      "required": ""
    },
    on: {
      "searchAddress": _vm.onSearchAddress
    }
  }), _c('div', {
    staticClass: "chekbox-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.info.isDefault,
      expression: "info.isDefault"
    }],
    staticClass: "checkbox",
    attrs: {
      "type": "checkbox",
      "id": "save",
      "name": "save"
    },
    domProps: {
      "checked": Array.isArray(_vm.info.isDefault) ? _vm._i(_vm.info.isDefault, null) > -1 : _vm.info.isDefault
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.info.isDefault,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.info, "isDefault", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.info, "isDefault", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.info, "isDefault", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "save"
    }
  }, [_vm._v(_vm._s(_vm.tt('기본 배송지로 저장')))])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }