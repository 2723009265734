<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="Modal ModalCompleteRegisteringLicense">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('라이선스 주문 완료') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="imgbox">
						<img src="@/assets/images/check.png" />
					</div>
					<div class="license-summary">
						<div class="imgbox">
							<img src="@/assets/images/lm-pro-icon.png" />
						</div>
						<div class="txtbox">
							<h6>{{ licenseTypeTextForComplete }}</h6>
							<p>
								{{
									tt(
										`￦${formatNumberLocale(getCompletePurchasePrice)} / ${
											purchaseForm.plan == type.EDUCATION ? '연구실당(년 기준,' : '계정당(월 기준,'
										} VAT 별도)`
									)
								}}
							</p>
						</div>
					</div>
					<div class="license-info">
						<h5>{{ tt('라이선스 정보') }}</h5>
						<ul>
							<li>
								<p>{{ tt('상태') }}</p>
								<div class="license-status-wrapper">
									<span class="license-status" :class="{ warning: !isLicenseActivate }">
										<img v-if="isLicenseActivate" src="@/assets/svg/sign-safe.svg" height="12" />
										<img v-else src="@/assets/svg/sign-warning.svg" height="12" />
										{{ licenseStatusText }}
									</span>
									<span v-if="purchaseCompleteInfo.paymentStatus === 'WAIT'" class="license-status warning">
										{{ tt('입금대기') }}
									</span>
								</div>
							</li>
							<li v-if="purchaseCompleteInfo.paymentStatus != 'WAIT'">
								<p>{{ tt('라이선스 만료일') }}</p>
								<span>
									{{ formatDatetime(license.expireDate) }}
								</span>
							</li>
							<li>
								<p>{{ tt('구매한 라이선스 수') }}</p>
								<span>{{
									purchaseCompleteInfo.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(purchaseCompleteInfo.quantity)
								}}</span>
							</li>
						</ul>
					</div>
					<div class="payment-info">
						<h5>{{ tt('결제 정보') }}</h5>
						<div class="noti-box" v-if="isPaymentMethodEbill">
							<p
								v-html="
									tt(
										'${1}까지 입금하지 않으면 라이선스가 <b class=red>비활성화</b>됩니다.',
										tt(`<b class='red'>${formatDepositExpireDate()}</b>`)
									)
								"
							></p>
						</div>

						<div class="noti-box last" v-if="isPaymentMethodEbill">
							<p>
								{{
									`${licenseManagement.schedule.virtualAccountBankName} ${licenseManagement.schedule.virtualAccountNumber} (${tt(
										'예금주'
									)}: ${licenseManagement.schedule.virtualAccountHolder})`
								}}
							</p>
							<p>
								{{ `${tt('결제금액')}: ₩${formatNumberLocale(licenseManagement.schedule.totalPrice)}` }}
							</p>
						</div>
						<ul>
							<li>
								<p>{{ tt('결제 상태') }}</p>
								<!--전자세금계산서의 경우-->
								<span v-if="isPaymentMethodEbill && isLicenseDepositWait" class="uncompleted">{{ tt('입금대기') }} </span>
								<!--이외-->
								<span v-else>{{ tt('결제 완료') }}</span>
							</li>

							<li v-if="isPaymentMethodEbill">
								<!--전자세금계산서의 경우-->
								<p>{{ tt('입금기한') }}</p>
								<span>{{ formatDatetime(licenseManagement.schedule.expireDatetime, false) }}</span>
							</li>
							<li>
								<p>
									{{ !isPaymentMethodEbill ? tt('결제일') : tt('주문일') }}
								</p>
								<span>
									{{ formatDatetime(purchaseCompleteInfo.createDatetime, false) }}
								</span>
							</li>
							<li>
								<p>{{ tt('결제수단') }}</p>
								<span>{{ paymentMethod2Text(this.purchaseCompleteInfo.paymentMethod, isAdditionMode) }}</span>
							</li>
							<li>
								<p>{{ tt('거래서류') }}</p>
								<!--전자세금계산서의 경우-->
								<div v-if="isPaymentMethodEbill">
									<a download :href="purchaseCompleteInfo.receiptUrl">
										{{ tt('전자세금계산서') }}
									</a>
									<span> / </span>
									<a download :href="purchaseCompleteInfo.virtualAccountConfirmationUrl">
										{{ tt('가상계좌발급서') }}
									</a>
								</div>
								<!--이외-->
								<a download :href="purchaseCompleteInfo.receiptUrl" v-else>{{ tt('영수증') }}</a>
							</li>
							<li>
								<p>{{ tt('금액') }}</p>
								<b> ￦{{ formatNumberLocale(purchaseCompleteInfo.totalPrice) }} </b>
							</li>
						</ul>
					</div>
				</div>
				<div class="Popup__bottom">
					<button class="Btn__yellow" @click="close">
						{{ tt(completeText) }}
					</button>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinLicense from '@/mixins/license'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	name: 'ModalCompleteLicensePayment',
	mixins: [MixinLicense],
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		isAdditionMode: {
			type: Boolean,
			default: false,
		},
		completeText: {
			type: String,
			default: '확인',
		},
	},
	data() {
		return {}
	},
	watch: {
		async isShow(value) {
			if (value) {
				await this.loadLicenseManagement()
			} else {
			}
		},
	},
	computed: {
		...mapState('license', ['license', 'purchaseForm', 'paymentMethod', 'licenseManagement', 'purchaseCompleteInfo']),
		...mapGetters('license', ['isLicenseActivate', 'isLicenseDepositWait']),
		isPaymentMethodEbill() {
			return this.purchaseCompleteInfo.paymentMethod == this.paymentMethod.ELECTRONIC_BILL
			// return true
		},
		isPaymentMethodCardRegistered() {
			return this.purchaseCompleteInfo.paymentMethod == this.paymentMethod.CARD_REGISTERED
		},
	},
	methods: {
		...mapActions('license', ['loadLicenseManagement']),
		// show(mode) {
		// 	this.mode = mode;
		// 	this.isShow = true;
		// },
		close() {
			if (this.beingSubscription && this.paymentMethod.CARD_REGISTERED && this.isAdditionMode) {
				this.$emit('showConfirmSubscribeModal')
			}
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
.noti-box {
	width: 100%;
	border: 1px solid $DIVIDER;
	border-radius: 5px;
	padding: 15px;
	margin-top: 10px;
	background-color: $GREY_0;
	p {
		font-size: 0.875rem;
		color: #000;
		padding-left: 12px;
		position: relative;
		&:before {
			width: 3px;
			height: 3px;
			background-color: #000;
			content: '';
			border-radius: 3px;
			top: 10px;
			left: 0;
			position: absolute;
		}
		span {
			font-weight: bold;
		}
	}
	&.last {
		margin-bottom: 10px;
	}
}

.ModalCompleteRegisteringLicense {
	color: #000;
	.Popup__container {
		height: calc(100vh - 135px);
	}
	.Popup__content {
		max-height: calc(100% - 135px);
		overflow-y: auto;
		> .imgbox {
			width: 100%;
			height: 200px;
			text-align: center;
		}
		.license-summary {
			display: flex;
			align-items: center;
			height: auto;
			.imgbox {
				width: 50px;
				height: 50px;
				overflow: hidden;
				border-radius: 10px;
				img {
					width: inherit;
					height: inherit;
				}
			}
			.txtbox {
				margin-left: 20px;
				h6 {
					font-size: 0.875rem;
					font-weight: 700;
				}
				p {
					font-size: 0.875rem;
					font-weight: 400;
					margin-top: 2px;
				}
			}
		}
		.license-info {
			margin-top: 20px;
			.license-status {
				img {
					margin-right: 3px;
				}
				border-radius: 5px;
				border: 1px solid #b5e7d2;
				color: $GREEN;
				background-color: #f4fffa;
				display: flex;
				align-items: center;
				padding: 6px 10px;
				font-size: 12px;
				font-weight: 700;
				&.warning {
					border: 1px solid #ffdad7;
					color: $RED;
					background-color: #fff5f5;
				}
			}
		}
		.payment-info {
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px solid $INPUT;
		}
		h5 {
			font-size: 0.875rem;
			font-weight: 700;
			margin-bottom: 10px;
		}
		ul {
			width: 100%;
			li {
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				p {
					font-size: 0.875rem;
					font-weight: 400;
					color: $SECONDARY_2;
				}
				span {
					font-size: 0.875rem;
					font-weight: 400;
					display: inline-block;
					margin-left: auto;
					&.uncompleted {
						display: flex;
						align-items: center;
						height: auto;
						font-size: 14px;
						font-weight: 700;
						color: $RED;
						button {
							padding: 0;
							img {
								width: 20px;
								height: 20px;
							}
							.balloon {
								p {
									color: #000;
									font-size: 0.75rem;
								}
							}
						}
					}
				}
				h3 {
					font-size: 1.25rem;
					font-weight: 700;
					color: $SECONDARY_2;
					margin-top: 10px;
				}
				h2 {
					font-size: 1.25rem;
					font-weight: 700;
					margin-left: auto;
					margin-top: 10px;
				}
				> b {
					font-size: 0.875rem;
					font-weight: 700;
					display: inline-block;
					margin-left: auto;
					text-align: right;
					span {
						display: inline-block;
						font-size: inherit;
						font-weight: inherit;
					}
				}
				a {
					font-size: 0.875rem;
					font-weight: 400;
					display: inline-block;
					text-decoration: underline;
					color: $BLUE;
					margin-left: auto;
				}
				> div {
					margin-left: auto;
					display: flex;
					align-items: center;
					height: auto;
					span {
						display: inline-block;
						margin: 0 3px;
					}
				}
			}
		}
	}
}

.Popup__bottom {
	padding-top: 0px !important;
	// padding-bottom: 0px !important;
	.Btn__yellow {
		width: 100%;
		height: 48px;
		font-weight: 700;
		margin: 10px 0 10px;
	}
}
</style>
