var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-header gray"
  }, [_c('th', [_c('div', [_vm._v(_vm._s(_vm.tt('예산차감일시')))])]), _c('th', [_c('div', {
    staticStyle: {
      "min-width": "250px",
      "max-width": "300px"
    }
  }, [_vm._v(_vm._s(_vm.tt('예산 이름')))])]), _c('th', [_c('div', [_vm._v(_vm._s(_vm.tt('예산 번호')))])]), _c('th', [_c('div', {
    staticStyle: {
      "min-width": "200px",
      "max-width": "250px"
    }
  }, [_vm._v(_vm._s(_vm.tt('기안 제목')))])]), _c('th', [_c('div', [_vm._v(_vm._s(_vm.tt('지출금액')))])]), _c('th', [_c('div', [_vm._v(_vm._s(_vm.tt('기안 연구실')))])]), _c('th', [_c('div', [_vm._v(_vm._s(_vm.tt('기안자')))])]), _c('th', [_c('div', [_vm._v(_vm._s(_vm.tt('기안상신일시')))])]), _c('th', [_c('div', [_vm._v(_vm._s(_vm.tt('결재완료일시')))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }