<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade @close="cancel" body-class="position-static">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<FormBrand ref="FormBrand" :name="label" v-model="inputValue" :placeholder="`${label}을 입력해주세요`" />
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" v-if="noCancel" @click="cancel">
						{{ tt(cancelText) }}
					</button>
					<button :class="`button-${variant}`" v-if="hasConfirm" @click="confirm">
						{{ tt(confirmText) }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import FormBrand from '@/views/common/components/form/FormBrand.vue'
export default {
	name: 'ModalConfirm',
	components: {
		FormBrand,
	},
	props: {
		title: {
			type: String,
		},
		label: {
			type: String,
		},
		noCancel: {
			type: Boolean,
			default: true,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		hasConfirm: {
			type: Boolean,
			default: true,
		},
		noContent: {
			type: Boolean,
			default: true,
		},
		confirmText: {
			type: String,
			default: '확인',
		},
		cancelText: {
			type: String,
			default: '취소',
		},
		variant: {
			type: String,
			default: 'primary',
		},
	},
	data() {
		return {
			confirmFn: null,
			cancelFn: null,
			inputValue: {
				id: '',
				name: '',
			},
			dropdown: {
				y: 0,
				x: 0,
			},
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show(confirmFn = null, cancelFn = null) {
			this.inputValue = {
				id: '',
				name: '',
			}
			if (confirmFn) this.confirmFn = confirmFn
			if (cancelFn) this.cancelFn = cancelFn
			this.$refs['modal'].show()
			this.$nextTick().then(() => {
				console.log(this.$refs['FormBrand'])
			})
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cancel() {
			this.$emit('cancel')
			if (this.cancelFn) this.cancelFn()
			this.hide()
		},
		confirm() {
			if (this.inputValue?.name == '') this.inputValue.id = ''
			this.$emit('confirm', this.inputValue)
			if (this.confirmFn) this.confirmFn(this.inputValue)
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';

::v-deep {
	.modal-body {
		overflow-x: unset;
	}
	.form-input {
		width: 100%;
	}
	.brand-list {
		// position: fixed !important;
	}
}
</style>
