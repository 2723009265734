import ghsAPI from '@/services/api/ghs'
import inventoryAPI from '@/services/api/inventory/gen2'
import settingAPI from '@/services/api/inventory/setting'
import { LargeClass, MiddleClass, RegistType } from '@/utils/define/ItemCode'
import { base64toFile } from '@/utils/modules/FileUtils'
import { random, cloneDeep } from 'lodash'

const state = {
	item: null,
	registMethod: null,
	category: null,
	categoryInfo: null,
	itemFormType: null, //'NORMAL_REGIST', 'NORMAL_EDIT', 'INSPECT_REGIST'
	uploadMsds: null,
	imageList: null,
	itemCustomField: null,
	showError: false,
	isRequireGhs: null,
	ghs: null,
	hasChangedStickerType: false,
	excelList: [],
	selectedItems: [],
	inspectId: null,
}

const getters = {
	item: state => {
		if (!state.item) return null
		let item = {}
		Object.entries(state?.item)?.forEach(([key, value]) => {
			if (key == 'memo' && value) {
				item[key] = value.replace(/\\r\\n|\\n|\\r/gm, '\n')
			} else {
				item[key] = value
			}
		})
		return item
	},
	category: state => state.category,
	categoryInfo: state => state.categoryInfo,
	isGas: state => state.category?.type === MiddleClass.GAS,
	isChemical: state => state.category?.classification === LargeClass.CHEMICAL,
	itemFormType: state => state.itemFormType,
	registMethod: state => state.registMethod,
	imageList: state => state.imageList,
	mngFields: state => state?.categoryInfo?.fields?.managementFields,
	requiredMngFields: (state, getters) => getters?.mngFields?.filter(f => f.isActive && f.isRequire && !f.readOnly),
	normalMngFields: (state, getters) => getters?.mngFields?.filter(f => f.isActive && !f.isRequire && !f.readOnly),
	customFields: state => state?.categoryInfo?.fields?.customFields,
	isActiveExpireDate: state => state?.categoryInfo?.fields?.managementFields?.some(f => f.isActive && !f.readOnly && f.key == 'expireDatetime'),
	expirationDay: state => state.categoryInfo?.expirationDay ?? 730,
	itemCustomField: state => state?.itemCustomField,
	isCategoryId: state => state.categoryInfo?.id,
	customFieldList: state => {
		let fieldList = []
		if (state.itemCustomField) {
			Object.entries(state?.itemCustomField)?.forEach(([key, value]) => {
				fieldList.push({ key: key, value: value ?? '' })
			})
			return fieldList
		} else {
			return null
		}
	},
	isProductValid: state => {
		if (state.categoryInfo) {
			const requireFields = state.categoryInfo?.fields?.inventoryFields
				.filter(f => f.isActive && !f.readOnly && f.isRequire)
				.map(item => item.key)
			let isRequireData = true
			if (!requireFields) return false
			requireFields?.forEach(requireKey => {
				// 필수 값인 경우
				if (!state.item?.[requireKey]) {
					isRequireData = false
				}
			})
			return isRequireData
		} else return false
	},
	isUnitValid: state => {
		const useUnitFields = state.categoryInfo?.fields?.inventoryFields
			.filter(
				f => f.isActive && !f.readOnly && f?.unit?.length > 1 && (f.key !== 'currentVolume' || state.category.classification == 'CAT_ETC')
			)
			.map(item => item.key)
		let isRequireData = true
		useUnitFields?.forEach(unitKey => {
			if (!!state.item?.[unitKey] && !state.item?.[`${unitKey == 'currentVolume' ? 'volume' : unitKey}Unit`]) {
				// 값은 있지만 Unit이 없는 경우
				isRequireData = false
			}
		})
		return isRequireData
	},
	isIngredientValid: state => {
		let validIngredient = state.item?.ingredient?.filter(i => i.name || i.ratio || i.casno)
		if (validIngredient?.length > 0) {
			return validIngredient.find(i => !i.name || !i.ratio) === undefined
		} else return true
	},
	isManagementValid: (state, getters) => {
		if (state.categoryInfo) {
			const requireFields = getters?.mngFields.filter(f => f.isActive && !f.readOnly && f.isRequire).map(item => item.key)
			let isRequireData = true
			requireFields?.forEach(requireKey => {
				// 필수 값인 경우
				if (!state.item?.[requireKey]) {
					isRequireData = false
				}
			})

			// Unit이 있는 값인 경우

			return isRequireData
		} else return false
	},
	isManagementUnitValid: (state, getters) => {
		if (state.categoryInfo) {
			const useUnitFields = state.categoryInfo?.fields.managementFields
				.filter(f => f.isActive && !f.readOnly && f?.unit?.length > 1)
				.map(item => item.key)
			let isRequireData = true
			useUnitFields?.forEach(unitKey => {
				if (!!state.item?.[unitKey] && !state.item?.[`${unitKey}Unit`]) {
					// 값은 있지만 Unit이 없는 경우
					isRequireData = false
				}
			})
			return isRequireData
		} else return false
	},
	isCustomValid: (state, getters) => {
		if (state.categoryInfo) {
			const requireFields = getters?.customFields?.filter(f => f.isActive && !f.readOnly && f.isRequire).map(item => item.key)
			let isRequireData = true

			requireFields?.forEach(key => {
				// 필수 값인 경우
				if (!state?.itemCustomField?.[key]) {
					isRequireData = false
				}
			})

			return isRequireData
		} else return false
	},
	showError: state => state.showError,
	itemFormDataByImage: state => {
		if (state.category) {
			const formData = new FormData()
			formData.append('categoryId', state.categoryInfo.id)
			Object.entries(state?.item)?.forEach(([key, value]) => {
				if (value) {
					switch (key) {
						case 'assignee':
						case 'category':
						case 'storage':
							break
						case 'imageList':
							value?.forEach(item =>
								typeof item === 'object'
									? formData.append('images', item)
									: formData.append(`images`, base64toFile(item, `image${random()}.jpg`))
							)
							break
						case 'tagsId':
							// 편집시 데이터 없는 경우
							// if (value?.length === 0) {
							// 	formData.append(key, '')
							// } else {
							value?.forEach(item => formData.append(`${key}[]`, item))
							// }
							break
						case 'deleteImages':
						case 'qrSticker':
							if (value?.length === 0) {
								formData.append(key, '')
							} else {
								value?.forEach(item => formData.append(`${key}[]`, item))
							}
							break
						case 'customField':
							let idx = 0
							Object.entries(state?.itemCustomField)?.forEach(([key, value]) => {
								if (!state?.categoryInfo?.fields?.customFields.find(it => it.key == key && it.isActive)) return
								if (value) {
									formData.append(`customField[${idx}][key]`, key)
									formData.append(`customField[${idx}][value]`, value)
									idx++
								}
							})
							break
						default:
							formData.append(key, value)
							break
					}
				} else {
					if (key === 'deleteGhs') formData.append('deleteGhs', value)
				}
			})
			if (state.uploadMsds) {
				formData.append('msds', state.uploadMsds)
			}
			return formData
		} else {
		}
	},
	itemFormDataByDirect: state => {
		if (state.category) {
			const formData = new FormData()
			formData.append('categoryId', state.categoryInfo.id)
			formData.append('categoryType', state.categoryInfo.type)
			if (state.item?.brand?.id) {
				formData.append('brandId', state.item.brand.id)
			} else {
				if (state.item?.brandName) {
					formData.append('brandName', state.item.brandName)
				}
			}
			Object.entries(state.item)?.forEach(([key, value]) => {
				if (value !== null) {
					if (
						[
							'volumes',
							'brand_id',
							'sudo_code',
							'storage',
							'assignee',
							// 'msds',
							'status',
							'category',
							'legalList',
							// 'ghs',
							'mdl_no',
							'catalog_id',
							'location',
							'buyer',
							'tagList',
							// 'imageList',
							'currentVolumeUnit',
							'ingredientList',
							'brand',
							'brandName',
							'brandId',
						].includes(key)
					) {
						return
					}

					switch (key) {
						case 'ghs':
							if (state.itemFormType === 'NORMAL_COPY') {
								if (value) {
									formData.append(`copyGhs`, value.id)
								}
							}
							break
						case 'msds':
							if (state.itemFormType === 'NORMAL_COPY') {
								if (value.institute) {
									formData.append(`copyMsds`, value.institute.id)
								}
							}
							break
						case 'imageList':
							if (state.itemFormType === 'NORMAL_COPY') {
								if (!state.item.images) {
									// 이미지 추가 또는 삭제를 안하고 그냥 보낼시,
									value.forEach(item => formData.append(`copyImages[]`, item.id))
								} else {
									// 이미지 추가 또는 삭제를 했을때,
									value.forEach(item => {
										if (state.item.images.some(img => img.id === item.id)) {
											formData.append(`copyImages[]`, item.id)
										}
									})
								}
							}
							break
						case 'volume':
							if (value) formData.append('volume', value)
							break
						case 'register':
							formData.append('registerId', value.id)
							break
						case 'ingredient':
							if (value?.length > 0) {
								let count = 0
								value?.forEach((item, idx) => {
									if (item.name && item.ratio) {
										formData.append(`${key}[${count}][name]`, item.name)
										formData.append(`${key}[${count}][ratio]`, item.ratio)

										if (item.casno) {
											formData.append(`${key}[${count}][casno]`, item.casno)
										}
										count++
									}
								})
							}
							break
						case 'images':
							value.forEach(item => {
								if (item instanceof File) {
									formData.append(`images`, item)
								}
							})
							break
						case 'tagsId':
							// 편집시 데이터 없는 경우
							if (value?.length !== 0) {
								value.forEach(item => formData.append(`${key}[]`, item))
							} else {
								formData.append(`${key}[]`, '')
							}
							break
						case 'deleteImages':
						case 'qrSticker':
							if (value?.length === 0) {
								formData.append(key, '')
							} else {
								if (state.itemFormType === 'NORMAL_EDIT' && key == 'qrSticker') {
									formData.append(key, value[0])
								} else {
									value?.forEach(item => formData.append(`${key}[]`, item))
								}
							}
							break
						case 'customField':
							let idx = 0
							state.item?.customField?.forEach(item => {
								if (!state?.categoryInfo?.fields?.customFields.find(it => it.key == item.key && it.isActive)) return
								if (state.itemFormType === 'NORMAL_EDIT') {
									if ((item?.value ?? null) != null && item?.value) {
										if (typeof item.value == 'object') {
											formData.append(`customField[${idx}][key]`, item.key)
											formData.append(`customField[${idx}][value]`, item.value.id)
										} else {
											formData.append(`customField[${idx}][key]`, item.key)
											formData.append(`customField[${idx}][value]`, item.value)
										}
									} else {
										formData.append(`customField[${idx}][key]`, item.key)
										formData.append(`customField[${idx}][value]`, '')
									}
									idx++
								} else {
									// 등록시 value 값에 빈 string을 전달시 error
									if ((item?.value ?? null) != null && item?.value) {
										if (typeof item.value == 'object') {
											formData.append(`customField[${idx}][key]`, item.key)
											formData.append(`customField[${idx}][value]`, item.value.id)
										} else {
											formData.append(`customField[${idx}][key]`, item.key)
											formData.append(`customField[${idx}][value]`, item.value)
										}
										idx++
									}
								}
							})
							break
						case 'expireDatetime':
						case 'buyDatetime':
						case 'openDatetime':
							if (value) {
								formData.append(`${key}`, value)
							}
							break
						default:
							if (state.itemFormType === 'NORMAL_EDIT') {
								formData.append(key, value ?? '')
							} else {
								if ((value ?? null) != null) {
									formData.append(key, value)
								}
							}
							break
					}
				} else {
					if (['brandId'].includes(key)) {
						formData.append(key, '')
					}
					if (['stickerType'].includes(key)) {
						if (state.itemFormType === 'NORMAL_EDIT') {
							formData.append(key, '')
						}
					}
				}
			})
			if (state.uploadMsds) {
				formData.append('msds', state.uploadMsds)
				if (state.itemFormType === 'NORMAL_COPY') {
					formData.delete('copyMsds')
				}
			}
			if (state.item?.stickerType === 'GHS+QR' && state.ghs) {
				const { dangerCode, precautionCode, provider, etc } = state.ghs
				dangerCode?.forEach(code => formData.append('ghs[dangerCode][]', code))
				precautionCode?.forEach(code => formData.append('ghs[precautionCode][]', code))

				const { name, phone, address } = provider
				formData.append('ghs[provider][name]', name)
				formData.append('ghs[provider][phone]', phone)
				formData.append('ghs[provider][address]', address)
				formData.append('ghs[etc]', etc ?? '')
			}
			return formData
		} else {
			return null
		}
	},
	uploadMsds: state => state.uploadMsds,
	isRequireGhs: state => state.isRequireGhs,
	ghs: state => state.ghs,
	itemFormType: state => state.itemFormType,
	hasChangedStickerType: state => state.hasChangedStickerType,
	isEmptySelectedItems: state => state.selectedItems.length === 0,
	isValidExcelList: state => {
		return !state.excelList.some(it => it.error.length > 0 || !it.storage)
	},
	excelListTotalCount: state => state.excelList.map(it => it.multiCreateCount).reduce((prev, curr) => prev + curr, 0),
}

const mutations = {
	initItem: state => {
		state.category = null
		state.categoryInfo = null
		state.item = {}
		state.registMethod = null
		state.imageList = []
		state.itemCustomField = {}
		state.uploadMsds = null
		state.isRequireGhs = null
		state.ghs = null
		state.hasChangedStickerType = false
		state.formType = null
		state.itemFormType = null
		// state.excelList = []
	},
	initRegistMore: state => {
		state.item = {}
		state.imageList = []
		state.itemCustomField = {}
		state.uploadMsds = null
		state.isRequireGhs = null
		state.ghs = null
		state.hasChangedStickerType = false
	},
	initExcelList: state => {
		state.excelList = []
		state.selectedItems = []
	},
	setRegistMethod: (state, registMethod) => {
		state.registMethod = registMethod
	},
	setCategory: (state, category) => {
		state.category = category
	},
	setCategoryInfo: (state, categoryInfo) => {
		state.categoryInfo = categoryInfo
	},
	setFormItem: (state, item) => {
		state.item = item
		if (item?.brand_id) {
			state.item.brandId = item.brand_id
		}
		if (item?.ingredientList) {
			state.item.ingredient = cloneDeep(item.ingredientList)
		}
		if (typeof item?.qrSticker === 'string') {
			state.item.qrSticker = [item?.qrSticker]
		}
	},
	setItemDivide: (state, item) => {
		state.item = item
		if (item?.brand_id) {
			state.item.brandId = item.brand_id
		}
		if (item?.ingredientList) {
			state.item.ingredient = cloneDeep(item.ingredientList)
		}
		if (typeof item?.qrSticker === 'string') {
			state.item.qrSticker = [item?.qrSticker]
		}
		let data = {}
		state.item?.customField?.forEach(item => {
			data[item.key] = item.value
		})
		state.itemCustomField = data
	},
	setImageList: (state, imageList) => {
		state.imageList = imageList
	},
	setItemCustomFields: (state, customField) => {
		state.itemCustomField = customField
	},
	setShowError: (state, showError) => {
		state.showError = showError
	},
	setIngredientInfo: (state, ingredient) => {
		const item = { ...state.item }
		item.ingredient = ingredient
		item.ingredientList = ingredient
		state.item = item
	},
	setUploadMsds: (state, uploadMsds) => {
		state.uploadMsds = uploadMsds
	},
	setMsds: (state, msds) => {
		let item = { ...state.item }
		item.msds = msds
		state.item = { ...item }
	},
	setIsRequireGhs: (state, requireGhs) => {
		state.isRequireGhs = requireGhs
	},
	setGhs: (state, ghs) => {
		state.ghs = ghs
	},
	setItemFormType: (state, itemFormType) => {
		state.itemFormType = itemFormType
	},
	setHasChangedStickerType: (state, isChanged) => {
		state.hasChangedStickerType = isChanged
	},
	setExcelList: (state, list) => {
		state.excelList = list
	},
	setSelectedItems: (state, data) => {
		state.selectedItems = data
	},
	setItemMsds: (state, msdsFile) => {
		state.uploadMsds = null
		let item = { ...state.item }
		item.msdsFile = msdsFile
		state.item = { ...item }
	},
	editExcelListItem: state => {
		state.excelList.forEach((item, index) => {
			if (item.tempId === state.item.tempId) {
				// 물품 정보 편집 성공했을때...error 초기화
				state.item.error = []
				state.excelList.splice(index, 1, state.item)
			}
		})
	},
	setInspectId: (state, id) => {
		state.inspectId = id
	},
}

const actions = {
	getCategoryInfo: async ({ state, commit, rootGetters }) => {
		const response = await settingAPI.lookupCategory(rootGetters.instituteId, state.category.id)
		commit('setCategoryInfo', response.data)
	},
	registItemByImage: async ({ getters, rootGetters }) => {
		const repsonse = await inventoryAPI.registItemByImage(rootGetters.instituteId, getters.itemFormDataByImage)
	},
	getMsds: async ({ rootGetters, commit }, params) => {
		try {
			const response = await inventoryAPI.getMsds(rootGetters.instituteId, params)
			commit('setMsds', response.data)
		} catch (e) {
			if (e.response.status === 400) {
				commit('setMsds', null)
			}
		}
	},
	uploadMsds: async ({ rootGetters, commit }, { inventoryId, brandId, productCode, file }) => {
		const formData = new FormData()
		formData.append('inventoryId', inventoryId)
		formData.append('file', file)
		if (brandId && productCode) {
			formData.append('brandId', brandId)
			formData.append('productCode', productCode)
		}
		await inventoryAPI.uploadMsds(rootGetters.instituteId, formData)
	},
	registItemByDirect: async ({ rootGetters, state }, { formData, ghsData, registMethod }) => {
		if (!formData.has('registerMethod')) {
			formData.append('registerMethod', registMethod)
		}
		const response = await inventoryAPI.registItem(rootGetters.instituteId, formData)
		if (ghsData != null) {
			await ghsAPI.getGhs(rootGetters.instituteId, {
				brandId: state.item?.brand?.id,
				productCode: state.item?.productCode,
				inventoryId: response.data.ids?.[0],
				ghs: ghsData,
			})
		}
		return response.data
	},
	getIsRequireGhs: async ({ rootGetters, state, commit }) => {
		const response = await ghsAPI.getGhsRequireState(rootGetters.instituteId, { casno: state.item?.casno })
		commit('setIsRequireGhs', response.data)
	},
	getGhs: async ({ rootGetters, commit, state }, params) => {
		const response = await ghsAPI.getGhs(rootGetters.instituteId, params)
		commit('setGhs', response.data)
	},
	registInspectItem: async ({ rootGetters }, body) => {
		const response = await inventoryAPI.registInspectItem(rootGetters.instituteId, body)
	},
	editInspectItem: async ({ rootGetters, getters }) => {
		let formData = getters.itemFormDataByDirect
		formData.delete('id')
		formData.append('inspectId', state.inspectId)
		const response = await inventoryAPI.editInspectItem(rootGetters.instituteId, getters.itemFormDataByDirect)
	},
	editItem: async ({ rootGetters, getters }) => {
		const formData = getters.itemFormDataByDirect
		if (!formData.has('itemId')) {
			formData.append('itemId', state.item?.id)
		}
		const response = await inventoryAPI.editItem(rootGetters.instituteId, formData)
	},
	editStickerType: async ({ rootGetters, state }) => {
		const formData = new FormData()
		formData.append('itemId', state.item?.id)
		formData.append('stickerType', state.item?.stickerType == null ? '' : state.item?.stickerType)
		if (state.item?.stickerType === 'GHS+QR' && state.ghs) {
			const { dangerCode, precautionCode, provider, etc } = state.ghs
			dangerCode?.forEach(code => formData.append('ghs[dangerCode][]', code))
			precautionCode?.forEach(code => formData.append('ghs[precautionCode][]', code))

			const { name, phone, address } = provider
			formData.append('ghs[provider][name]', name)
			formData.append('ghs[provider][phone]', phone)
			formData.append('ghs[provider][address]', address)
			formData.append('ghs[etc]', etc ?? '')
		}
		const response = await inventoryAPI.editItem(rootGetters.instituteId, formData)
	},
	downloadExcel: async ({ state, rootGetters }) => {
		const response = await inventoryAPI.downloadExcel(rootGetters.instituteId, { categoryId: state.category.id })
		return response
	},
	checkExcel: async ({ state, commit, rootGetters }, { formData, user }) => {
		const response = await inventoryAPI.checkExcel(rootGetters.instituteId, formData)
		if (response.data.length > 0) {
			response.data.forEach((it, index) => {
				it.tempId = index + 1
				it.assignee = user
				it.assigneeId = user.id
			})
		}
		commit('setExcelList', response.data)
		commit('setSelectedItems', [])
	},
	validate: async ({ state, commit, rootGetters }) => {
		const response = await inventoryAPI.validate(rootGetters.instituteId, { categoryId: state.category.id, list: state.excelList })
		return response.data
	},
	registExcelItem: async ({ state, commit, rootGetters }, formData) => {
		const response = await inventoryAPI.registItem(rootGetters.instituteId, formData)
		return response.data
		// if (ghsData != null) {
		// 	await ghsAPI.getGhs(rootGetters.instituteId, {
		// 		brandId: state.item?.brand?.id,
		// 		productCode: state.item?.productCode,
		// 		inventoryId: response.data.ids?.[0],
		// 		ghs: ghsData,
		// 	})
		// }
	},
}

export default { namespaced: true, state, getters, mutations, actions }
