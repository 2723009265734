var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ghs-wrapper"
  }, [_c('img', {
    staticClass: "ghs-icon-img",
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/icon-ghs-item.svg")
    }
  }), _c('div', {
    staticClass: "ghs-content"
  }, [_c('div', {
    staticClass: "ghs-title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " ")]), _c('div', {
    staticClass: "ghs-subtext"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.subtext)) + " ")])]), _c('div', {
    staticClass: "btn-wrapper"
  }, [_c('button', {
    staticClass: "show-ghs"
  }, [_vm._v(_vm._s(_vm.tt('보러가기')))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }