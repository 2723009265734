<template>
	<base-button @click="handleClick" :isActive="isActive">
		<div class="btn-datepicker-wrapper">
			<date-picker
				range
				clearable
				valueType="format"
				ref="date-picker"
				:open="openDatePicker"
				v-model="time"
				:lang="lang"
				:disabled-date="disabledDate"
				@pick="pickDate"
				:value-type="'YYYY-MM-DDTHH:mm:ss.sss'"
			></date-picker>
		</div>
		<div v-if="openDatePicker" class="datepicker-background" @click.stop="openDatePicker = false"></div>
		<img :src="img" />
		<div class="btn-text">{{ tt(text) }}</div>
		<div class="btn-subtext">{{ tt(subText) }}</div>
		<img v-if="time[0] != null" @click.stop="reset" class="remove" src="@/views/budget/components/svg/icon-remove.svg" />
	</base-button>
</template>

<script>
// DatePicker refs: https://github.com/mengxiong10/vue2-datepicker
import DatePicker from 'vue2-datepicker'
import MixinDate from '@/mixins/date.js'
// TODO: MixinButton?

export default {
	components: {
		DatePicker,
	},
	mixins: [MixinDate],
	props: {
		disabledDate: {
			type: Function,
			default: () => {},
		},
		defaultTime: {
			type: Array,
			default: null,
		},
		image: {
			type: String,
			defalut: '',
		},
		activeImage: {
			type: String,
			defalut: '',
		},
		text: {
			type: String,
			default: '',
		},
		subText: {
			type: String,
			default: '',
		},
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		img() {
			return this.isActive ? this.activeImage : this.image
		},
	},
	watch: {
		defaultTime: {
			deep: true,
			handler(value) {
				if (this.defaultTime) {
					this.time = [...this.defaultTime]
				}
			},
		},
		openDatePicker: {
			handler(value) {
				if (!value) {
					this.pickCount = 0
				}
			},
		},
	},
	mounted() {
		if (this.defaultTime) {
			this.time = [...this.defaultTime]
		}
	},
	data() {
		return {
			pickCount: 0,
			openDatePicker: false,
			time: [null, null],
		}
	},
	methods: {
		handleClick() {
			this.openDatePicker = !this.openDatePicker
			this.$emit('click') //emit 안해도 될듯?
		},
		pickDate(date) {
			this.pickCount++
			if (this.pickCount % 2 === 0) {
				console.warn('pickDate')
				this.openDatePicker = false
				this.$emit('pickDate')
				this.$emit('changeDate', this.time)
			}
		},
		reset() {
			this.$refs['date-picker'].clear()
			this.$emit('changeDate', this.time)
		},
	},
}
</script>

<style lang="scss" scoped>
// datepicker 관련 CSS
.btn-datepicker-wrapper {
	width: 0px;
	position: relative;
	top: 10px;
	left: -10px;
	.mx-datepicker-range {
		width: 0px;
	}
	.mx-datepicker::v-deep .mx-input-wrapper .mx-input {
		width: 0px;
		display: none;
	}
	::v-deep .mx-icon-calendar {
		display: none;
	}
}
.datepicker-background {
	position: fixed;
	width: 100vw;
	top: 0;
	height: 100vh;
	left: 0;
	z-index: 0;
}
.remove {
	width: 10px;
	height: 10px;
}
.btn-text,
.btn-subtext {
	margin-top: -2px;
}
</style>
