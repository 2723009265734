<template>
	<b-modal centered scrollable @close="cancel" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="camera-wrap">
				<!-- v-if="cameraActive" -->
				<div class="start-popup" v-if="isStartPopup && showPopup" v-html="tt('시약의 정보가 잘 보이도록<br/>여러번 촬영해주세요')"></div>
				<web-cam
					width="1000"
					height="600"
					:resolution="resolution"
					:deviceId="deviceId"
					:autoplay="true"
					:frontCam="false"
					@cameras="onCameras"
					@started="startedCameras"
					@stopped="stoppedCameras"
					@error="error"
					ref="camera"
				></web-cam>
				<div class="image-list">
					<img v-if="!isMax" class="image-add" src="@/views/inventoryGen2/svg/create/add-image.svg" @click="uploadImage" />
					<input
						class="image-input"
						type="file"
						ref="file-input"
						@change="onChangeFile"
						accept="image/png, image/gif, image/jpeg, image/jpg"
						multiple
						hidden
					/>
					<div class="image-wrap" v-for="(url, idx) in urls" :key="idx">
						<img class="upload-image" :src="imageSrc(url)" @click="$refs['image-viewer'].show(idx)" />
						<img class="remove-image" @click="removeImage(url)" src="@/views/inventoryGen2/svg/create/icon-remove-img.svg" />
					</div>
					<modal-multi-image-viewer ref="image-viewer" title="이미지" :imageList="urls"></modal-multi-image-viewer>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left">
					<button class="button-white" @click="clickPrev" v-if="isPrev">
						{{ tt('이전으로') }}
					</button>
				</div>

				<div class="capture-button-wrap">
					<img
						class="capture-button"
						v-if="isCameraStarted && !isMax"
						@click="capture"
						src="@/views/inventoryGen2/svg/create/cam-capture-button.svg"
					/>
				</div>

				<div class="footer-right">
					<button class="button-primary" :disabled="!hasImages" @click="hasImages ? goNext() : ''">
						{{ tt(nextBtnText) }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { WebCam } from 'vue-cam-vision'
import { ToastType } from '@/utils/define/ToastType'
import ModalMultiImageViewer from '@/views/common/components/modalGen2/ModalMultiImageViewer.vue'
export default {
	name: 'ModalCamera',
	components: { WebCam, ModalMultiImageViewer },
	props: {
		title: {
			type: String,
			default: '',
		},
		max: {
			type: [Number, String],
			default: 10,
		},
		isStartPopup: {
			type: Boolean,
			default: false,
		},
		isPrev: {
			type: Boolean,
			default: true,
		},
		nextBtnText: {
			type: String,
			default: '다음',
		},
	},
	data() {
		return {
			resolution: {
				width: 1000,
				height: 600,
			},
			devices: [],
			urls: [],
			isCameraStarted: false,
			showPopup: true,
		}
	},
	computed: {
		deviceId() {
			return this.devices?.[0]?.deviceId
		},
		hasImages() {
			return this.urls?.length > 0
		},
		isMax() {
			return this.urls?.length === this.max
		},
	},
	watch: {
		urls: {
			deep: true,
			handler() {
				this.$emit('change', this.urls)
			},
		},
	},
	created() {},
	mounted() {},
	methods: {
		show() {
			this.$refs['modal'].show()
			this.showPopup = true
			this.$nextTick(() => {
				if (this.isStartPopup) {
					setTimeout(() => {
						this.showPopup = false
					}, 2000)
				}
			})
		},
		hide() {
			this.$refs['modal'].hide()
			this.isCameraStarted = false
		},
		cancel() {
			this.urls.forEach(url => {
				window.URL.revokeObjectURL(url)
			})
			this.initData()

			this.hide()
			this.$emit('cancel')
		},
		goNext() {
			this.$emit('next', this.urls)
			this.hide()
		},
		startedCameras() {
			this.isCameraStarted = true
		},
		stoppedCameras() {
			this.isCameraStarted = false
		},
		error(e) {
			this.hide()
			this.$emit('noPermission')
		},
		onCameras(cameras) {
			this.devices = cameras
			this.$refs['camera'].start()
		},
		async capture() {
			let capturedBase64Img = await this.$refs['camera'].capture()
			if (!(capturedBase64Img === 'data:,')) {
				this.urls.push(capturedBase64Img)
			} else {
				this.$root.toast('카메라 인식 에러', '', ToastType.WARNING)
			}
		},
		removeImage(url) {
			this.urls = this.urls.filter(item => item !== url)
			this.$root.toast('이미지 삭제', '이미지가 삭제되었습니다', ToastType.SUCCESS)
			window.URL.revokeObjectURL(url)
		},
		uploadImage() {
			this.$refs['file-input'].click()
		},
		initData() {
			this.urls = []
			this.isCameraStarted = false
		},
		setData(data) {
			this.urls = data
		},
		onChangeFile(event) {
			if (event.target.files.length > 0 && event.target.files.length <= 10 - this.urls.length) {
				let url = event.target.files
				this.urls = [...this.urls, ...url]
			} else {
				this.$root.toast('이미지', `이미지는 최대 10개 등록할 수 있습니다.`, 'error')
			}
		},
		clickPrev() {
			this.$emit('prev')
			this.$refs['modal'].hide()
		},
		imageSrc(url) {
			return typeof url === 'object' ? window.URL.createObjectURL(url) : url
		},
	},
}
</script>

<style lang="scss" scoped>
.camera-wrap {
	background: $DEFAULT;
	height: 600px;
	overflow: hidden;
	.start-popup {
		background: #00000066;
		min-width: 232px;
		height: 60px;
		position: absolute;
		border-radius: 10px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		top: calc(50% - 30px);
		left: calc(50% - 116px);
		text-align: center;
		font-size: 14px;
		font-weight: 700;
	}
	.image-list {
		display: flex;
		position: absolute;
		bottom: 0;
		height: 80px;
		width: 100%;
		background: #f1f1f133;
		backdrop-filter: blur(13.6px);
		padding: 16px;
		gap: 10px;
		.image-add {
			cursor: pointer !important;
		}
		.image-wrap {
			position: relative;
			.upload-image {
				width: 48px;
				height: 48px;
				border-radius: 5px;
				cursor: pointer;
				object-fit: cover;
			}
			.remove-image {
				position: absolute;
				right: -8px;
				top: -6px;
				cursor: pointer;
			}
		}
	}
}
::v-deep .modal-dialog {
	max-width: 1000px;
	.modal-content {
		background-color: $GREY_1;
		border-radius: 8px;
		border: 0;
		.form-wrap {
			padding: 20px;
			background: white;
			border: 1px solid $DIVIDER;
			border-radius: 8px;
			gap: 20px;
			.form-header {
				font-weight: 700;
				font-size: 16px;
				.form-title {
				}
				.form-msg {
				}
			}
		}
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	padding: 0px 0px;
	border: 0;
	gap: 20px;
	overflow-x: hidden;
}

::v-deep .modal-header {
	padding: 20px;
	border: 0;
}

::v-deep .modal-footer {
	padding: 20px;
	border: 0;
	min-height: 110px;
	.modal-footer-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.capture-button-wrap {
			display: flex;
			position: relative;
			justify-content: center;
		}
		.footer-left,
		.footer-right {
			width: 50%;
			display: flex;
			gap: 10px;
			align-items: center;
		}

		.footer-right {
			justify-content: flex-end;
		}
		.capture-button {
			cursor: pointer;
		}

		button {
			border: 0;
			box-shadow: none;
			display: flex;
			max-height: 30px;
			align-items: center;
			cursor: pointer;
			white-space: pre;
			transition: 0.5s all ease;
			min-width: 100px;
			padding: 5px 10px;
			font-size: 14px;
			font-weight: 700;
			border-radius: 5px;
			justify-content: center;
		}

		.button-white {
			background: $LAB_WHITE;
			border: 1px solid $DIVIDER;
			color: #111 !important;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
					linear-gradient(0deg, #fff, #fff);
			}
		}
		.button-primary {
			background: $LAB_YELLOW;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
			}
			&:disabled {
				cursor: not-allowed;
			}
		}
	}
}

::v-deep .modal-title {
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	.title {
		color: #111 !important;
	}
}
video {
	object-fit: fill !important;
}
</style>
