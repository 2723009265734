<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalHealthCheckupNotice">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-md">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('특수건강진단') }}</h5>
					<button class="Popup__close-btn">
						<img src="@/assets/svg/popup-close.svg" alt="close" @click="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="boxwrap">
						<div class="txtbox">
							<h6>{{ tt('특수건강진단이란?') }}</h6>
							<p>
								{{ tt('산업안전보건법 제 130조에 따라 특수건강진단 대상 유해인자에 노출되는 업무에 종사하는 근로자') }}
							</p>
						</div>
						<div class="txtbox">
							<h6>{{ tt('특수건강진단 대상인원') }}</h6>
							<ul>
								<li>
									{{ tt('1. 화학적 인자(유기화합물, 금속류, 산 및 알카리류 등) 163종') }}
								</li>
								<li>
									{{ tt('2. 분진(곡물분진, 광물성분진, 면분진, 목재분진, 융접흄, 유리섬유 분진, 석면 분진) 7종') }}
								</li>
								<li>
									{{ tt('3. 물리적 인자(소음, 진동, 방사선, 고기압, 저기압, 유해광선 등) 8종') }}
								</li>
								<li>
									{{ tt('4. 야간작업 2종') }}
									<div>
										{{
											tt(
												'가. 6개월간 밤 12시부터 오전 5시까지의 시간을 포함하여 계속되는 8시간 작업을 월 평균 4회 이상 수행하는 경우'
											)
										}}<br />
										{{ tt('6개월간 오후 10시부터 다음날 오전 6시 사이의 시간 중 작업을 월 평균 60시간 이상 수행하는 경우') }}
									</div>
								</li>

								<li>
									{{ tt('※ 산업안전보건법 시행규칙 [별표 22] 참조 ') }}
								</li>
							</ul>
						</div>
						<div class="txtbox">
							<h6>{{ tt('특수건강진단 기한 부여 기준') }}</h6>
							<ul>
								<li>
									{{
										tt(
											'특수건강진단 시기 및 주기 표를 기반으로 사용자가 사용한 대상 물질에 따라, 특수건강진단을 받아야하는 가장 빠른 일자를 건강진단 기한으로 합니다.'
										)
									}}
								</li>
								<li>
									{{ tt('처음 사용하는 물질의 경우, 첫번째 진단시기를 적용합니다.') }}
								</li>
								<li>
									{{ tt('같은 물질을 여러번 사용하는 경우, 해당 물질의 진단주기를 바탕으로 진단시기를 적용합니다.') }}
								</li>
								<li>
									{{ tt('다음 특수건강진단을 1달이내로 받아야 하는 경우, 상태가 위험으로 표시됩니다.') }}
								</li>
							</ul>
						</div>
						<div class="txtbox">
							<h6>
								{{ tt('특수건강검진 시기 및 주기 (산업안전보건법 제202조 1항 관련)') }}
							</h6>
							<table>
								<thead>
									<tr>
										<th>{{ tt('구분') }}</th>
										<th>{{ tt('대상 유해인자') }}</th>
										<th>CAS</th>
										<th v-html="tt('배치 후 첫번째<br>검진 시기')"></th>
										<th>{{ tt('검진 주기') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td rowspan="2">1</td>
										<td>N,N-Dimethylacetamide(N,N-디메틸아세트아미드)</td>
										<td>127-19-5</td>
										<td rowspan="2">{{ tt('${1}개월 이내', 1) }}</td>
										<td rowspan="2" class="last">6{{ tt('개월') }}</td>
									</tr>
									<tr>
										<td>N N-Dimethylformamide(N,N-디메틸포름아미드)</td>
										<td>68-12-2</td>
									</tr>
									<tr>
										<td>2</td>
										<td>Benzene(벤젠)</td>
										<td>71-43-2</td>
										<td>{{ tt('${1}개월 이내', 2) }}</td>
										<td class="last">6{{ tt('개월') }}</td>
									</tr>
									<tr>
										<td rowspan="4">3</td>
										<td>1,1,2,2-Tetrachloroethane(1,1,2,2-테트라클로로에탄)</td>
										<td>79-34-5</td>
										<td rowspan="4">{{ tt('${1}개월 이내', 3) }}</td>
										<td rowspan="4" class="last">6{{ tt('개월') }}</td>
									</tr>
									<tr>
										<td>Carbon tetrachloride(사염화탄소)</td>
										<td>56-23-5</td>
									</tr>
									<tr>
										<td>Acrylonitrile(아크릴로니트릴)</td>
										<td>107-13-1</td>
									</tr>
									<tr>
										<td>Vinyl chloride(염화비닐)</td>
										<td>75-01-4</td>
									</tr>
									<tr>
										<td rowspan="2">4</td>
										<td>Asbestos(석면)</td>
										<td>1332-21-4</td>
										<td rowspan="2">{{ tt('${1}개월 이내', 12) }}</td>
										<td rowspan="2" class="last">12{{ tt('개월') }}</td>
									</tr>
									<tr>
										<td>Cotton Dust(면 분진)</td>
										<td class="no-data">
											{{ tt('정보없음') }}
										</td>
									</tr>
									<tr>
										<td rowspan="3">5</td>
										<td>Mineral Dust(석면)</td>
										<td class="no-data">
											{{ tt('정보없음') }}
										</td>
										<td rowspan="3">{{ tt('${1}개월 이내', 12) }}</td>
										<td rowspan="3" class="last">24{{ tt('개월') }}</td>
									</tr>
									<tr>
										<td>Wood Dust(목재 분진)</td>
										<td class="no-data">
											{{ tt('정보없음') }}
										</td>
									</tr>
									<tr>
										<td>{{ tt('소음 및 충격소음') }}</td>
										<td class="no-data">
											{{ tt('정보없음') }}
										</td>
									</tr>
									<tr>
										<td>6</td>
										<td>
											{{ tt('1~5를 제외한 201조에 해당하는 물질') }}
										</td>
										<td>
											<a
												href="https://www.law.go.kr/LSW/lsBylInfoPLinkR.do?lsiSeq=212709&lsNm=%EC%82%B0%EC%97%85%[…]9&bylNo=0022&bylBrNo=00&bylCls=BE&bylEfYd=20200116&bylEfYdYn=Y"
												target="_blank"
												>{{ tt('별표22 참조') }}</a
											>
										</td>
										<td>{{ tt('${1}개월 이내', 6) }}</td>
										<td class="last">12{{ tt('개월') }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalHealthCheckupNotice',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		close() {
			this.$emit('close')
		},
		goRules() {},
	},
}
</script>

<style lang="scss" scoped>
.ModalHealthCheckupNotice {
	.Popup__container {
		padding-bottom: 0;
	}
	.Popup__top {
		padding-bottom: 20px;
	}
	.Popup__content {
		height: 60vh;
		padding: 0 0;
		padding-bottom: 20px;
		.boxwrap {
			width: 100%;
			padding: 20px 20px 20px;
			overflow-y: auto;
			height: inherit;
			background-color: $GREY_1;
			box-sizing: border-box;
		}
	}
	.txtbox {
		&:first-child {
			h6 {
				margin-top: 0;
			}
		}
		h6 {
			font-size: 0.875rem;
			color: #000;
			font-weight: 700;
			margin-top: 20px;
			margin-bottom: 10px;
		}
		p {
			font-size: 0.75rem;
			font-weight: 400;
			color: #000;
			word-break: keep-all;
		}
		ul {
			li {
				font-size: 0.75rem;
				font-weight: 400;
				color: #000;
				word-break: keep-all;
			}
		}
	}
	table {
		width: 100%;
		border-radius: 5px;
		background: $LAB_WHITE;
		overflow: hidden;
		border: 1px solid $INPUT;
		border-collapse: unset;
		border-spacing: 0;
		border-right: 0;
		thead {
			background-color: $GREY_2;
			th {
				border-right: 1px solid $INPUT;
				border-bottom: 1px solid $INPUT;
				font-size: 0.75rem;
				color: $SECONDARY_1;
				text-align: center;
				height: 50px;
				box-sizing: border-box;
			}
		}
		tbody {
			tr {
				td {
					border-bottom: 1px solid $DIVIDER;
					border-right: 1px solid $DIVIDER;
					font-size: 0.75rem;
					color: #000;
					font-weight: 400;
					padding: 8px 10px;
					&.last {
						border-right: 1px solid $INPUT;
					}
					a {
						text-decoration: underline;
						font-weight: normal;
					}
					&.no-data {
						color: $PLACEHOLDER;
					}
				}
				&:last-child {
					td {
						border-bottom: 0;
					}
				}
			}
		}
	}
}
</style>
