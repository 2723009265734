import ghsAPI from '@/services/api/ghs'
import inventoryAPI from '@/services/api/inventory'
import msdsParserAPI from '@/services/api/msdsParser'
import Decimal from 'decimal.js'

const state = {
	mode: 'ADD', // ADD, EDIT
	parsedMsds: null,
	selectedItem: null,
	uploadFile: null,
	uploadUrl: null,
	selectedPrecautionCode: null,
	previewData: null,
	provider: null,
	qrcodeList: null,
	unitList: null,
	statements: null,
	etc: null,
}

const findHazardIdx = dangerCode => {
	return state.statements?.hazard?.findIndex(item => item.code === dangerCode)
}

const getters = {
	mode: state => {
		return state.mode
	},
	selectedItem: state => {
		return state.selectedItem
	},
	activeParsedGhsList: state => {
		const data = state.parsedMsds?.['항2']
		const result = state.statements?.hazard?.filter(h => data?.['유해/위험성']?.includes(h.name))
		return result
	},
	activeParsedPrecautionList: state => {
		const result = []
		if (state.parsedMsds) {
			const data = state.parsedMsds?.['항2']
			Object.keys(data?.['유해/위험문구']).forEach(hCode => {
				result.push(hCode)
			})
			return result
		} else {
			return null
		}
	},
	physicalCodeList: state => {
		return state.statements?.hazard?.filter(h => h.code.indexOf('P') > -1)
	},
	healthCodeList: state => {
		return state.statements?.hazard?.filter(h => h.code.indexOf('H') > -1)
	},
	environmentCodeList: state => {
		return state.statements?.hazard?.filter(h => h.code.indexOf('E') > -1)
	},
	hCodeList: state => {
		let result = []
		if (state.selectedItem) {
			Object.values(state.selectedItem).forEach(value => {
				result = [...result, ...value.map(v => v.hCode)]
			})
			return [...new Set(result.flat())].sort()
		} else {
			return null
		}
	},
	ghsPictogram: state => {
		if (state.selectedPrecautionCode) {
			return [...new Set(state.selectedPrecautionCode.map(code => state.statements?.hCode?.[code]?.ghs).flat())].filter(item => item)
		} else {
			return false
		}
	},
	uploadFile: state => state.uploadFile,
	uploadUrl: state => state.uploadUrl,
	selectedPrecautionCode: state => state.selectedPrecautionCode,
	previewData: state => state.previewData,
	signalText: state => {
		if (state.selectedPrecautionCode) {
			const signals = state.selectedPrecautionCode?.map(code => state.statements?.hCode?.[code]?.signal)
			if (signals.includes('위험')) {
				return '위험'
			} else if (signals.includes('경고')) {
				return '경고'
			} else {
				return '없음'
			}
		} else {
			return '없음'
		}
	},
	hCodeText: state => {
		return state.selectedPrecautionCode?.map(code => state.statements?.hCode?.[code]?.text)?.join(' ')
	},
	pCodeText: state => {
		const pCodeList = state.selectedPrecautionCode?.map(code => state.statements?.hCode?.[code]?.pCode).flat()
		return pCodeList?.map(code => state.statements?.pCode[code]).join(' ')
	},
	dangerCode: state => {
		return Object.keys(state.selectedItem)
			?.map(key => state.selectedItem[key].map(item => item.code))
			?.flat()
	},
	provider: state => {
		return state.provider
	},
	isProviderValid: state => {
		if (!state.provider) {
			return false
		} else {
			const { name, address, phone } = state.provider
			return name && address && phone
		}
	},
	qrcodeList: state => state.qrcodeList,
	normalizedVolume: state => {
		if (state.unitList && state.previewData) {
			// state.volumeUnit
			const { volume, volumeUnit } = state.previewData
			const value = new Decimal(volume ?? 0)
			let unit = state.unitList[volumeUnit] ?? null
			if (unit) {
				let unitVolume = new Decimal(state.unitList[volumeUnit])
				return Decimal.mul(value, unitVolume).toNumber()
			}
			return null
		} else {
			return null
		}
	},
	ghsData: (state, getters) => {
		return {
			precautionCode: getters.selectedPrecautionCode ?? [],
			dangerCode: getters.dangerCode ?? [],
			provider: state.provider,
			etc: state.etc ?? '',
		}
	},
	etc: state => state.etc,
}

const mutations = {
	setParsedMsds: (state, parsedMsds) => {
		state.parsedMsds = parsedMsds
	},
	setSelectedItem: (state, selectedItem) => {
		state.selectedItem = selectedItem
	},
	setUploadFile: (state, uploadFile) => {
		state.uploadFile = uploadFile
	},
	setUploadUrl: (state, uploadUrl) => {
		state.uploadUrl = uploadUrl
	},
	setSelectedPrecautionCode: (state, selectedPrecautionCode) => {
		state.selectedPrecautionCode = selectedPrecautionCode
	},
	setPreviewData: (state, previewData) => {
		state.previewData = previewData
		if (previewData?.inventoryFields?.ghs || previewData?.ghs) {
			const { dangerCode, precautionCode, provider, etc } = previewData?.inventoryFields?.ghs || previewData?.ghs
			state.selectedItem = {
				physical: dangerCode.filter(item => item.indexOf('P') > -1).map(code => state.statements?.hazard?.[findHazardIdx(code)]),
				health: dangerCode.filter(item => item.indexOf('H') > -1).map(code => state.statements?.hazard?.[findHazardIdx(code)]),
				environment: dangerCode.filter(item => item.indexOf('E') > -1).map(code => state.statements?.hazard?.[findHazardIdx(code)]),
			}
			state.selectedPrecautionCode = precautionCode
			state.provider = provider
			state.etc = etc
		}
	},
	setProvider: (state, provider) => {
		state.provider = provider
	},
	setQrcodeList: (state, qrcodeList) => {
		state.qrcodeList = qrcodeList
	},
	setUnitList: (state, unitList) => {
		state.unitList = unitList
	},
	setMode: (state, mode) => {
		state.mode = mode
	},
	setEtc: (state, etc) => {
		state.etc = etc
	},
	setGhsStatements: (state, statements) => {
		state.statements = statements
	},
}

const actions = {
	setInit: async ({ commit }) => {
		commit('setSelectedItem', null)
		commit('setParsedMsds', null)
		commit('setSelectedPrecautionCode', null)
		commit('setUploadFile', null)
		commit('setUploadUrl', null)
		commit('setProvider', null)
		commit('setQrcodeList', null)
		commit('setEtc', null)
	},
	parseMsds: async ({ commit }, form) => {
		try {
			const response = await msdsParserAPI.getMsdsParseResult(form)
			console.warn(response)
			commit('setParsedMsds', response.data.data)
			return response.data.data
		} catch (e) {
			console.error('parse msds error')
			return e.data
		}
	},
	cancelParseMsds: () => {
		msdsParserAPI.cancelMsdsParse()
	},
	getUnitList: async ({ commit }) => {
		const response = await inventoryAPI.lookupUnit()
		commit('setUnitList', response.data)
	},
	getGhsStatement: async ({ commit, rootGetters }) => {
		const response = await ghsAPI.statements(rootGetters.instituteId)
		commit('setGhsStatements', response.data)
	},
	uploadGhsLabel: async ({ rootGetters }, payload) => {
		await ghsAPI.uploadGhs(rootGetters.instituteId, payload)
	},
	deleteGhsLabel: async ({ rootGetters }, params) => {
		await ghsAPI.deleteGhs(rootGetters.instituteId, params)
	},
}
export default { namespaced: true, state, getters, mutations, actions }
