var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ingredient-item-body"
  }, [_vm._l(_vm.classNames, function (className, idx) {
    return _c('form-ingredient-input', {
      key: "".concat(className).concat(idx),
      ref: "form",
      refInFor: true,
      attrs: {
        "isUseSearch": className !== 'ratio',
        "className": className,
        "value": _vm.ingredient[className]
      },
      on: {
        "input": _vm.handleInput,
        "clickItem": _vm.clickItem
      }
    });
  }), _c('div', [_c('div', {
    staticClass: "del-btn"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon/icon-trash-enable.svg")
    },
    on: {
      "click": _vm.deleteItem
    }
  })])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }