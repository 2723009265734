import { paymentStatus, paymentMethod } from '@/utils/define/StatusCode'
export default {
	computed: {
		draftInfoForTable() {
			const approvalFinishDatetime =
				this.item?.settleDatetime || this.item?.concludeDatetime
					? this.$DateTime.fromISO(this.item?.settleDatetime || this.item.concludeDatetime).toFormat('yyyy-MM-dd T')
					: `<p style="color:#999999">${this.tt('결재 예정')}</p>`
			return [
				{ label: this.tt('기안 연구실'), value: this.item.group?.name },
				{ label: this.tt('기안자'), value: this.item.register?.name },
				{
					label: this.tt('기안일시'),
					value: this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd T'),
				},
				{
					label: this.tt(this.item.isSettle ? '정산완료일시' : '결재완료일시'),
					value: approvalFinishDatetime,
				},
				{ label: this.tt('예산 번호'), value: this.item.budgetStatus?.code },
				{ label: this.tt('예산 이름'), value: this.item.budgetStatus?.name },
				{
					label: this.tt('지출금액'),
					// value: this.item.budgetStatus?.summary?.expenditure ? this.item.budgetStatus.summary.expenditure.toLocaleString() + '원' : '-',
					value: this.item.expendAmount ? this.tt('${1}원', this.item.expendAmount.toLocaleString()) : '-',
				},
				{
					label: this.tt('구매방식'),
					// value: this.item.budgetStatus?.summary?.expenditure ? this.item.budgetStatus.summary.expenditure.toLocaleString() + '원' : '-',
					value: this.item.purchaseType == 'INTERNAL' ? this.tt('랩매니저 스토어') : this.tt('외부 거래처'),
				},
				{
					label: this.tt('문서번호'),
					value: this.item.no,
					class: 'lg',
				},
				{
					label: this.tt('기안 제목'),
					value: this.item.subject,
					class: 'lg',
				},
				{
					label: this.tt('기안 내용'),
					value: this.item.content ?? '-',
					class: 'lg',
				},
			]
		},
		attachmentsForTable() {
			return [
				{
					label: this.tt('첨부 파일'),
					files: this.item.attachments,
					class: 'lg',
				},
			]
		},
		amountForm() {
			// 예산 현황 form setting
			let form = {}
			let today = new Date()
			let year = this.item.budgetStatus?.year ? this.item.budgetStatus?.year : today.getFullYear()
			let month = today.getMonth() + 1

			let thisMonth = this.item?.budgetStatus?.budgetType === 'MONTH' ? this.tt(`${this.item.budgetStatus.month}월`) : ''
			form = {
				title: {
					name: this.item.budget
						? this.tt(`${this.item.budget.code} ${this.item.budget.name} ${this.item.group.name}_${this.item.group.pathName}`)
						: '',
					status: this.item.budgetStatus?.budgetType,
				},
				total: {
					name: this.tt('${1}년 ${2} 남은 잔액', year, thisMonth),
					value: this.item.budgetStatus?.summary?.budget ? this.item.budgetStatus.summary.budget : 0,
				},
				expense: {
					name: this.tt('지출 예정 금액'),
					value: this.item.status === 'ACCEPT' ? this.item.budgetStatus?.summary?.expenditure ?? 0 : this.item.expendAmount ?? 0,
				},
				balance: {
					name: this.tt('결제 후 예상 잔액'),
					// value: this.item.budgetStatus?.summary?.balance ? this.item.budgetStatus.summary.balance : 0,
					value:
						this.item.status === 'ACCEPT'
							? this.item.budgetStatus?.summary?.balance ?? 0
							: this.item.budgetStatus?.summary?.budget - this.item.expendAmount,
				},
			}

			return form
		},

		orderStatus() {
			const { status, returnStatus, cancelStatus } = this.item.store
			if (returnStatus == 'DONE') return '교환/반품 완료'
			else if (cancelStatus == 'DONE') return '주문취소 완료'
			else if (status == 'WAIT' && cancelStatus == null) return '재고확인중'
			else if (status == 'APPROVE') return '출고준비중'
			else if (status == 'SHIPPING_ING') return '배송중'
			else if (status == 'SHIPPING_COMPLETE') return '배송완료'
			else if (status == 'CONFIRM') return '구매확정'
			return '재고확인중'
		},
		orderInfo() {
			return [
				{
					label: this.tt('주문번호'),
					value: this.item.store.orderNo ?? '-',
				},
				{
					label: this.tt('주문상태'),
					value: this.orderStatus ?? '-',
				},
				{
					label: this.tt('주문일시'),
					value: this.parsedateTime(this.item.createDatetime),
				},
				{
					label: this.tt('주문 처리일시'),
					value: this.parsedateTime(this.item.store.approveDatetime),
				},
				{
					label: this.tt('배송 시작일시'),
					value: this.parsedateTime(this.item.store.releaseDatetime),
				},
				{
					label: this.tt('배송 완료일시'),
					value: this.parsedateTime(this.item.store.shippingDatetime),
				},
			]
		},
		paymentInfo() {
			return [
				{
					label: this.tt('결제상태'),
					value: paymentStatus[this.item.store.paymentStatus],
					class: 'lg',
				},
				{
					label: this.tt('결제번호'),
					value: this.item.store.paymentNo ?? '-',
				},
				{
					label: this.tt('결제자'),
					value: this.item.store.payerName ?? '-',
				},
				{
					label: this.tt('결제금액'),
					value: this.tt('${1}원', this.item.store.orderItemPriceWithVat.toLocaleString()),
					class: 'lg',
				},
				{
					label: this.tt('결제처'),
					value: this.item.store.businessRegistrationName,
				},
				{
					label: this.tt('결제수단'),
					value: this.item.store.paymentMethod ? paymentMethod[this.item.store.paymentMethod] : '-',
				},
				{
					label: this.tt('결제 요청일'),
					value: this.parsedateTime(this.item.store.paymentCreateDatetime),
				},
				{
					label: this.tt('결제일시'),
					value: this.parsedateTime(this.item.store.paymentDatetime),
				},
			]
		},
	},
	watch: {
		$route() {
			this.loadDetail()
		},
	},
	data() {
		return {}
	},
	created() {
		this.loadDetail()
	},
	methods: {
		parsedateTime(datetime) {
			if (datetime) {
				return this.$DateTime.fromISO(datetime).toFormat('yyyy-MM-dd T')
			}
			return '-'
		},
	},
}
