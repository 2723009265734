<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('상품 추가') }}</div>
		</template>
		<template>
			<draft-category-selector />
			<!-- <form-counter
				title="등록할 수량"
				:min="1"
				:value="multiCreateCount"
				:hasOrderCount="hasOrderCount"
				@change="setMultiCreateCount"
			></form-counter> -->
			<draft-form-product ref="form-product" />
			<!-- <form-ingredient-list v-if="isUsedIngredient" ref="form-ingredient"></form-ingredient-list> -->
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="confirm">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import DraftCategorySelector from '../form/DraftCategorySelector.vue'
import DraftFormProduct from '../form/DraftFormProduct.vue'
export default {
	components: {
		DraftCategorySelector,
		DraftFormProduct,
	},
	computed: {
		...mapGetters('draft/form', ['productInfo', 'sellerInfo', 'isProductValid', 'isSellerValid']),
	},
	methods: {
		...mapMutations('draft/form', ['setInitProductInfo', 'setShowError']),
		show() {
			this.setInitProductInfo()
			this.$refs['modal'].show()
			this.setShowError(false)
		},
		hide() {
			this.$refs['modal'].hide()
		},
		confirm() {
			this.setShowError(true)
			if (this.isProductValid && this.isSellerValid) {
				this.$emit('addProduct', {
					...this.productInfo,
					...{ seller: this.sellerInfo },
					...{ sellingPrice: this.productInfo.unitPrice * this.productInfo.quantity },
					...{ sellingUnitPrice: this.productInfo.unitPrice },
					...{ discountUnitPrice: this.productInfo.discountUnitPrice || 0 },
				})
				this.hide()
				this.$root.toast(this.tt('상품 추가 완료'), this.tt('입력한 상품이 추가되었습니다.'), 'success')
				this.setShowError(false)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-form-cargo.scss';
</style>
