<template>
	<ul class="recent-reason-wrap" v-show="hasRecent">
		<div class="reason-header">
			<p class="title">최근 입력한 사유</p>
			<p class="delete-btn" @click="removeAllRecent">전체 삭제</p>
		</div>
		<div>
			<li class="reason-wrap" v-for="(text, idx) in recentList" :key="idx" @click.stop="$emit('clickRecent', text)">
				<span v-html="text"></span>
				<img src="@/assets/svg/Close-1.svg" width="24" height="24" @click.stop="removeRecent(text)" />
			</li>
		</div>
	</ul>
</template>
<script>
export default {
	name: 'InputUseRecentTexts',
	props: {
		recentId: {
			type: String,
			default: '',
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			recentList: [],
		}
	},
	computed: {
		recentKey() {
			return `${this.$route.name}_${this.$root.user?.id}_${this.recentId}`
		},
		hasRecent() {
			return this.recentList?.length !== 0 && this.show
		},
	},
	mounted() {
		this.recentList = JSON.parse(this.$cookies.get(this.recentKey) ?? '[]')
	},
	methods: {
		addRecent(text) {
			if (text.length >= 1) {
				if (this.recentList.includes(text)) this.recentList.remove(text)
				this.recentList.unshift(text)
				this.recentList = this.recentList.slice(0, 20)
				this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
			}
		},
		removeRecent(text) {
			this.recentList.remove(text)
			this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
		},
		removeAllRecent() {
			this.recentList = []
			this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
		},
	},
}
</script>

<style lang="scss" scoped>
.recent-reason-wrap {
	padding-top: 0 !important;
	.reason-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		top: 0;
		z-index: 2;
		padding: 10px 5px;
		background: $LAB_WHITE;
		.title {
			font-size: 0.75rem;
			color: $SECONDARY_2;
		}
		.delete-btn {
			font-size: 0.75rem;
			font-weight: bold;
			color: $BLUE;
			cursor: pointer;
		}
	}
	.reason-wrap {
		span {
			width: 100%;
			word-break: break-all;
		}
	}
}
</style>
