var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: _vm.isShowRequire
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt(_vm.placeholder)
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.inputValue = $event.target.value;
      }, _vm.handleInput]
    }
  })]), _vm.isShowRequire ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.tt(_vm.errorMessage)))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }