<template>
	<div class="msds-wrapper">
		<img class="msds-icon-img" :src="msdsImg" />
		<div class="msds-content">
			<div class="msds-title">
				{{ tt(title) }}
			</div>
			<div class="msds-subtext">
				{{ tt(subtext) }}
			</div>
		</div>
		<div class="btn-wrapper">
			<button class="show-msds" @click="handleClick">{{ tt('보기') }}</button>
		</div>
		<msds-file-viewer ref="msds-viewer" :msdsKey="msdsKey" :isShow="true" :pdfUrl="msds.url"></msds-file-viewer>
	</div>
</template>

<script>
import MsdsFileViewer from '@/views/common/components/modalGen2/MsdsFileViewer.vue'
export default {
	name: 'CargoFormMsdsItem',
	props: {
		msds: {
			type: Object,
			default: () => {},
		},
		msdsKey: {
			type: String,
			default: 'institute',
		},
	},
	components: { MsdsFileViewer },
	computed: {
		msdsImg() {
			switch (this.msdsKey) {
				case 'master':
					return this.msds?.brand?.image ?? require('@/assets/svg/icon/msds/icon-msds-noimg.svg')
				case 'kosha':
					return require('@/assets/svg/icon/msds/icon-msds-kosha.svg')
				case 'institute':
				default:
					return require('@/assets/svg/icon/msds/icon-msds-institute.svg')
			}
		},
		title() {
			switch (this.msdsKey) {
				case 'master':
					return '제조사 MSDS'
				case 'kosha':
					return '안전보건공단 MSDS'
				case 'institute':
					return '업로드된 MSDS'
				default:
					return ''
			}
		},
		subtext() {
			switch (this.msdsKey) {
				case 'master':
					return this.msds?.brand?.name ?? ''
				case 'kosha':
					return '출처: 안전보건공단 MSDS(msds.kosha.or.kr)'
				case 'institute':
					return this.msds?.createDatetime ? `출처 ${this.formatDate(this.msds.createDatetime, 'yyyy.MM.dd')}${this.tt('업로드됨')}` : ''
				default:
					return ''
			}
		},
	},
	methods: {
		handleClick() {
			this.$refs['msds-viewer'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.msds-wrapper {
	display: flex;
	padding-top: 20px;
	.msds-icon-img {
		width: 36px;
		height: 36px;
		border-radius: 5px;
		border: 0.5px solid $COLOR_CCC;
		margin-top: 2px;
	}
	.msds-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-left: 10px;
		padding-bottom: 13px;
		border-bottom: 1px solid $DIVIDER;
		.msds-title {
			font-size: 14px;
			font-weight: 700;
		}
		.msds-subtext {
			text-align: left;
			font-size: 12px;
			font-weight: 400;
			color: $BLACK_60;
		}
	}
	.btn-wrapper {
		display: flex;
		align-items: center;
		padding-bottom: 13px;
		border-bottom: 1px solid $DIVIDER;
		.show-msds {
			height: 30px;
			font-size: 14px;
			font-weight: 700;
			color: $COLOR_000000DE;
			background: $GREY_4;
			border-radius: 15px;
			padding: 5px 10px;
		}
	}
}
</style>
