var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading-wrapper",
    style: _vm.cssProps
  }, [_c('div', {
    staticClass: "lottie-wrap"
  }, [_c('lottie-animation', {
    ref: "lottie-animation",
    attrs: {
      "animationData": require('@/assets/json/lm_loading_lottie.json'),
      "autoPlay": _vm.play,
      "loop": true
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }