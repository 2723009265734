var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "hide-footer": ""
    },
    on: {
      "close": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "text-wrap",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('사용 후 잔여 용량이 없습니다.<br />물품을 폐기하고, 사용완료하시겠습니까?'))
    }
  }), _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.focusOut,
      expression: "focusOut"
    }],
    staticClass: "reason-wrap"
  }, [_c('div', {
    staticClass: "input-head-text"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('폐기 사유')))]), _c('p', {
    staticClass: "text-length"
  }, [_c('span', {
    staticClass: "length",
    "class": {
      over: _vm.isLengthOver
    }
  }, [_vm._v(_vm._s(_vm.text.length))]), _vm._v(" /" + _vm._s(_vm.textMaxLength) + " ")])]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.text,
      expression: "text"
    }],
    staticClass: "input-reason",
    "class": {
      error: _vm.isShowTextError && _vm.isLengthOver
    },
    attrs: {
      "placeholder": _vm.tt("\uD3D0\uAE30 \uC0AC\uC720\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694(".concat(_vm.textMaxLength, "\uC790 \uC81C\uD55C)"))
    },
    domProps: {
      "value": _vm.text
    },
    on: {
      "focus": function focus($event) {
        _vm.isFocus = true;
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.text = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.isShowTextError && _vm.isLengthOver ? _c('p', {
    staticClass: "error-msg"
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-error.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt('글자수를 초과하였습니다.')) + " ")]) : _vm._e(), _c('recent', {
    ref: "recent",
    attrs: {
      "show": _vm.isKeywordWrapperOpen,
      "list": _vm.commentList
    },
    on: {
      "clickComment": _vm.clickRecent,
      "deleteComment": _vm.clickDeleteComment
    }
  })], 1)]), _c('div', {
    staticClass: "modal-footer-wrap"
  }, [_c('button', {
    staticClass: "button-white",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "button-white",
    on: {
      "click": _vm.noDisposeComplete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('폐기없이 사용 완료')) + " ")]), _c('button', {
    staticClass: "button-warning",
    on: {
      "click": _vm.disposeComplete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('폐기하고 사용 완료')) + " ")])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }