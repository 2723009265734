<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<input type="checkbox" :class="{ someChecked: !isCheckedAll && !isEmptySelectItem }" :checked="isCheckedAll" @change="checkedAll()" />
		</th>
		<th>
			<span>{{ tt('결제상태') }}</span>
		</th>
		<th>
			<span>{{ tt('주문상태') }}</span>
		</th>
		<th>
			<span>{{ tt('주문번호') }}</span>
		</th>
		<th>
			<span>{{ tt('상품 정보') }}</span>
		</th>
		<th v-if="isView('quantity')">
			<span>{{ tt('수량') }}</span>
		</th>
		<th v-if="isView('payerName')">
			<span>{{ tt('결제자') }}</span>
		</th>
		<th v-if="isView('budgetName')">
			<span>{{ tt('예산 이름') }}</span>
		</th>
		<th v-if="isView('price')">
			<span>{{ tt('결제금액') }}</span>
		</th>
		<th v-if="isView('draftNo')">
			<span>{{ tt('문서번호') }}</span>
		</th>
		<th v-if="isView('paymentDatetime')">
			<span>{{ tt('결제일시') }}</span>
		</th>
		<th v-if="isView('draftName')">
			<span>{{ tt('기안 제목') }}</span>
		</th>
		<th v-if="isView('group')">
			<span>{{ tt('주문 연구실') }}</span>
		</th>
		<th v-if="isView('orderer')">
			<span>{{ tt('주문자') }}</span>
		</th>
		<th v-if="isView('createDatetime')">
			<span>{{ tt('주문일시') }}</span>
		</th>
		<th v-if="isView('concluder')">
			<span>{{ tt('결재자') }}</span>
		</th>
		<th v-if="isView('concludeDatetime')">
			<span>{{ tt('결재완료일시') }}</span>
		</th>
		<th v-if="isView('paymentNo')">
			<span>{{ tt('결제번호') }}</span>
		</th>
		<th v-if="isView('paymentCreateDatetime')">
			<span>{{ tt('결제 요청일') }}</span>
		</th>
		<th v-if="isView('businessRegistrationName')">
			<span>{{ tt('결제처') }}</span>
		</th>
		<th v-if="isView('paymentMethod')">
			<span>{{ tt('결제수단') }}</span>
		</th>
		<th v-if="isView('platform')">
			<span>{{ tt('주문경로') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapState('purchaseProduct/list', ['checkedViewOption']),
		...mapGetters('purchaseProduct/list', ['list', 'isEmptySelectItem', 'selectItem', 'order', 'orderDirection']),
		isCheckedAll() {
			return this.list?.length > 0 && this.list?.length === this.selectItem.length
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapActions('purchaseProduct/list', ['setLoadParams']),
		...mapMutations('purchaseProduct/list', ['checkedAll']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
