var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "registeringLocation"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.mode == 2 ? _vm.tt('보관위치 생성하기') : _vm.tt('보관함 생성하기')) + " ")])]), _c('div', {
    staticClass: "boxwrap"
  }, [_c('div', {
    staticClass: "location-tree"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('lm-input-search', {
    attrs: {
      "placeholder": _vm.tt('위치명으로 검색')
    },
    on: {
      "search": _vm.searchTree
    }
  }), _c('button', {
    staticClass: "btn",
    "class": {
      point: _vm.mode == 2 && _vm.order == 1 || _vm.isLocationHint
    },
    attrs: {
      "disabled": _vm.mode == 3
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-lisence-copy.svg"),
      "alt": "icon"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.mode == 3 ? '' : _vm.showModalAddChildLocation('ADD_LOCATION');
      }
    }
  }), _c('span', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.mode == 3 ? '' : _vm.showModalAddChildLocation('ADD_LOCATION');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('생성')))]), _vm.mode == 2 && _vm.order == 1 || _vm.isLocationHint ? _c('div', {
    staticClass: "order-balloon left"
  }, [_c('div', {
    staticClass: "order-top"
  }, [_vm._m(0), !_vm.isLocationHint ? _c('button', {
    on: {
      "click": _vm.closeOrder
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })]) : _vm._e()]), _c('h6', [_vm._v(_vm._s(_vm.tt('1. 위치 등록하기')))]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.tt('클릭해서 건물위치(구조)를 등록해보세요.')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('하위 위치를 등록하려면 위치를 선택하고 버튼을 클릭하세요.')) + " ")])])]) : _vm._e()]), _c('button', {
    staticClass: "btn",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "disabled": _vm.mode == 3 || _vm.locationTree && _vm.locationTree[0].children.length == 0
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showModalDeleteLocation.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Trash.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('삭제')) + " ")])], 1), _c('div', {
    ref: "chart-main-container",
    staticClass: "Location__main-container",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_vm.treeData.length > 0 ? _c('location-tree-view', {
    ref: "location-tree-view",
    attrs: {
      "treeData": _vm.treeData,
      "textField": "name",
      "mode": "active",
      "selectWithChild": false,
      "keyword": _vm.keyword
    },
    on: {
      "changeActive": _vm.changeActiveId,
      "changeTree": _vm.changeTree,
      "moveStorage": _vm.moveStorage
    }
  }) : _vm._e(), _vm.mode == 3 && _vm.order == 1 || _vm.isStorageHint ? _c('div', {
    staticClass: "order-balloon-left"
  }, [_c('div', {
    staticClass: "order-top"
  }, [_vm._m(1), !_vm.isStorageHint ? _c('button', {
    on: {
      "click": _vm.closeOrder
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })]) : _vm._e()]), _c('h6', [_vm._v(_vm._s(_vm.tt('1. 위치 선택하기')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('보관함을 추가할 위치를 클릭하여 선택하세요')) + " ")])]) : _vm._e()], 1)]), _vm.selectedLocation ? _c('div', {
    staticClass: "location-info"
  }, [_c('div', {
    staticClass: "AppContainer__location-title"
  }, [_c('h5', {
    staticClass: "AppContainer__location-name"
  }, [_vm._v(" " + _vm._s(_vm.selectedLocation.info.name) + " ")]), _c('img', {
    staticClass: "Location__button-arrow",
    attrs: {
      "src": require("@/assets/svg/Button_edit.svg")
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showModalAddChildLocation('EDIT_LOCATION');
      }
    }
  })]), _c('div', {
    staticClass: "Location__group-box"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle",
    staticStyle: {
      "align-items": "start"
    }
  }, [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('사용 그룹')))])]), _c('button', {
    staticClass: "AppContainer__subtitle-btn btn",
    "class": {
      point: _vm.mode == 2 && _vm.order == 2 || _vm.isLocationHint
    }
  }, [_c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": require("@/assets/svg/authority-setting.svg"),
      "alt": "icon"
    }
  }), _c('span', {
    on: {
      "click": _vm.showModalAddUsingGroup
    }
  }, [_vm._v(_vm._s(_vm.tt('사용 그룹 관리')))]), _vm.mode == 2 && _vm.order == 2 || _vm.isLocationHint ? _c('div', {
    staticClass: "order-balloon"
  }, [_c('div', {
    staticClass: "order-top"
  }, [_vm._m(2), !_vm.isLocationHint ? _c('button', {
    on: {
      "click": _vm.closeOrder
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })]) : _vm._e()]), _c('h6', [_vm._v(" " + _vm._s(_vm.tt('2. 사용 그룹 추가하기')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('위치를 사용하고 있는 그룹을 추가해주세요.')) + " ")])]) : _vm._e()])]), _vm.hasGroups ? _c('p', {
    staticClass: "Location__group-location"
  }, [_vm._v(" " + _vm._s(_vm.usingGroups) + " ")]) : _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_group.svg"),
      "alt": "no-image"
    }
  }), _c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('${1}(을)를 사용 중인 그룹이 없습니다.', _vm.selectedLocation.info.name))
    }
  }), _c('p', {
    staticStyle: {
      "padding-top": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>[사용 그룹 관리]</b>버튼을 클릭하여 그룹을 추가해보세요'))
    }
  })])]), _c('div', {
    staticClass: "StorageBox__group"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('보관함')))])]), _c('button', {
    staticClass: "AppContainer__subtitle-btn btn",
    "class": {
      point: _vm.mode == 3 && _vm.order == 2 || _vm.isStorageHint
    },
    attrs: {
      "disabled": _vm.mode == 2
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/add-storage.svg"),
      "alt": "추가icon"
    }
  }), _c('span', {
    on: {
      "click": function click($event) {
        _vm.mode == 2 ? '' : _vm.showModalAddStorage('ADD');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('보관함 추가')))]), _vm.mode == 3 && _vm.order == 2 || _vm.isStorageHint ? _c('div', {
    staticClass: "order-balloon"
  }, [_c('div', {
    staticClass: "order-top"
  }, [_vm._m(3), !_vm.isStorageHint ? _c('button', {
    on: {
      "click": _vm.closeOrder
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })]) : _vm._e()]), _c('h6', [_vm._v(_vm._s(_vm.tt('2. 보관함 추가하기')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('위치에 배치된 보관함을 추가해주세요.')) + " ")])]) : _vm._e()])]), _vm.hasStorages ? _c('ul', {
    staticClass: "StorageBox__list"
  }, _vm._l(_vm.selectedLocation.storages, function (storage) {
    return _c('storage-item', {
      key: "storage-".concat(storage.id),
      attrs: {
        "storageInfo": storage,
        "isStart": true,
        "mode": _vm.mode,
        "userPermission": _vm.userPermission
      },
      on: {
        "editStorage": _vm.showModalEditStorage,
        "deleteStorage": _vm.showModalDeleteStorage,
        "refresh": _vm.refreshData
      }
    });
  }), 1) : _vm._e(), !_vm.hasStorages && _vm.mode == 2 ? _c('div', {
    staticClass: "no-data-box start"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_lock.svg")
    }
  }), _c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('보관위치 생성하기가 완료된 후 다음 단계에서<br>보관함 추가가 가능합니다.'))
    }
  })]) : !_vm.hasStorages && _vm.mode == 3 ? _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_storage.svg"),
      "alt": "no-image"
    }
  }), _c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('보관함이 없습니다.'))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>[보관함 추가]</b> 버튼을 클릭하여 보관함을 추가해보세요'))
    }
  })]) : _vm._e()])]) : _c('div', {
    staticClass: "no-location"
  }, [_c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_location.svg")
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.tt('위치를 선택해주세요')))]), _c('p', [_vm._v(_vm._s('좌측 영역에서 위치를 선택해주세요'))])])])])])]), _vm.isLocationHint || _vm.isStorageHint ? _c('div', {
    staticClass: "hint-popup"
  }, [_c('div', {
    staticClass: "Popup__background",
    on: {
      "click": _vm.hideHint
    }
  })]) : _vm._e(), _c('button', {
    staticClass: "Btn__hint",
    on: {
      "click": _vm.showHint
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Hint.svg")
    }
  })]), _c('modal-add-child-location', {
    ref: "modal-add-child-location",
    on: {
      "refresh": _vm.refreshData
    }
  }), _c('modal-delete-location', {
    ref: "modal-delete",
    on: {
      "refresh": _vm.refreshData
    }
  }), _c('modal-add-using-group', {
    ref: "modal-add-using-group",
    attrs: {
      "isStart": true
    },
    on: {
      "refresh": _vm.hideModalAddUsingGroup,
      "completeLocation": _vm.complete
    }
  }), _vm.selectedLocation ? _c('modal-add-storage-v-2', {
    ref: "modal-add-storage",
    attrs: {
      "type": "ADD",
      "locationId": _vm.selectedLocation.info.id
    },
    on: {
      "refresh": _vm.refreshData,
      "complete": _vm.complete
    }
  }) : _vm._e(), _vm.selectedLocation ? _c('modal-add-storage-v-2', {
    ref: "modal-edit-storage",
    attrs: {
      "type": "EDIT",
      "locationId": _vm.selectedLocation.info.id
    },
    on: {
      "refresh": _vm.refreshData
    }
  }) : _vm._e(), _c('modal-notice-location', {
    ref: "modal-notice-location",
    on: {
      "hideNoticeLocation": _vm.hideNoticeLocation
    }
  }), _c('modal-notice-storage', {
    ref: "modal-notice-storage",
    on: {
      "hideNoticeStorage": _vm.hideNoticeStorage
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order"
  }, [_c('span', {
    staticClass: "active"
  }), _c('span')]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order"
  }, [_c('span', {
    staticClass: "active"
  }), _c('span')]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order"
  }, [_c('span'), _c('span', {
    staticClass: "active"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order"
  }, [_c('span'), _c('span', {
    staticClass: "active"
  })]);
}]

export { render, staticRenderFns }