var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "use-wrap"
  }, [_c('div', {
    staticClass: "input-head-text"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('사용횟수')))]), _c('p', [_c('span', {
    staticClass: "volume-text"
  }, [_vm._v(_vm._s(_vm.tt("\uD604\uC7AC \uC0AC\uC6A9\uD69F\uC218")))]), _vm._v(" : "), _c('span', {
    staticClass: "current-volume"
  }, [_vm._v(_vm._s(_vm.tt('${1}회', _vm.useCount)))])])]), _c('div', {
    staticClass: "input-container"
  }, [_c('div', {
    staticClass: "input-wrap"
  }, [_c('input', {
    staticClass: "input-use",
    "class": {
      error: _vm.isShowVolumeError && _vm.isInputVolumeOver
    },
    attrs: {
      "placeholder": _vm.tt("\uC0AC\uC6A9\uD69F\uC218\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694")
    },
    domProps: {
      "value": _vm.useValue
    },
    on: {
      "input": _vm.handleInput
    }
  }), _c('span', {
    staticClass: "input-unit"
  }, [_vm._v(_vm._s(_vm.tt('회')))])])]), _vm.isShowVolumeError && _vm.isInputVolumeOver ? _c('p', {
    staticClass: "error-msg"
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-error.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt("\uC0AC\uC6A9\uD69F\uC218\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.")) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }