<template>
	<div v-if="item">
		<p class="header">{{ tt('물품정보') }}</p>
		<safety-info v-if="isCategoryTypeChemical && hasMsds" :msds="item.msds"></safety-info>
		<member-info :item="item"></member-info>
		<product-info :item="item"></product-info>
		<management-info :item="item"></management-info>
		<ingredient-info :ingredient="item.inventoryFields.ingredient"></ingredient-info>
		<legal-info
			v-if="!isBasic"
			:legals="legals"
			:ingredientList="item.inventoryFields.ingredient"
			:casno="item.inventoryFields.casno"
		></legal-info>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SafetyInfo from './safetyInfo/SafetyInfo.vue'
import MemberInfo from './memberInfo/MemberInfo.vue'
import ProductInfo from './productInfo/ProductInfo.vue'
import ManagementInfo from './managementInfo/ManagementInfo.vue'
import IngredientInfo from './ingredientInfo/IngredientInfo.vue'
import LegalInfo from './leaglInfo/LegalInfo.vue'
import { MiddleClassList } from '@/utils/define/ItemCode'
export default {
	name: 'DetailProductInfo',
	components: { SafetyInfo, MemberInfo, ProductInfo, ManagementInfo, IngredientInfo, LegalInfo },
	props: {
		item: {
			type: Object,
			default: null,
		},
		legals: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('license', ['isBasic']),
		hasMsds() {
			if (this.item?.msds) {
				return Object.values(this.item?.msds)
					.filter(item => item)
					.some(e => e)
			} else {
				return false
			}
		},
		isCategoryTypeChemical() {
			return MiddleClassList.CAT_CHEMICAL.includes(this.item?.category?.type)
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.header {
	font-size: 24px;
	font-weight: 700;
	padding-bottom: 15px;
	text-align: left;
	border-bottom: 1px solid $DEFAULT;
}
</style>
