import { render, staticRenderFns } from "./ModalMainResearch.vue?vue&type=template&id=1c42f0fc&scoped=true&"
import script from "./ModalMainResearch.vue?vue&type=script&lang=js&"
export * from "./ModalMainResearch.vue?vue&type=script&lang=js&"
import style0 from "./ModalMainResearch.vue?vue&type=style&index=0&id=1c42f0fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c42f0fc",
  null
  
)

export default component.exports