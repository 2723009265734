<template>
	<div class="search-wrapper">
		<div class="table-wrapper">
			<div class="search-row">
				<div class="label">{{ tt('검색') }}</div>
				<div>
					<div class="column-box">
						<search-box ref="search-box" class="search-box" noRecent :placeholder="tt(placeholder)" @change="changeKeyword"></search-box>
					</div>
				</div>
			</div>
		</div>
		<div class="table-wrapper">
			<div class="search-row">
				<div class="label">{{ tt('분류') }}</div>
				<div class="column-box">
					<label v-for="item in categoryList" :key="item.id">
						<input
							class="checkbox"
							type="checkbox"
							:checked="isChecekdItem(item.id, 'CATEGORY')"
							@change="checkedItem(item, 'CATEGORY')"
						/>
						<span>{{ tt(item.name) }}</span>
					</label>
				</div>
			</div>
			<div class="search-row" v-if="isMonth">
				<div class="label">{{ tt('월') }}</div>
				<div class="column-box">
					<label v-for="item in monthList" :key="item.id" v-b-tooltip.hover="tt(item.name)">
						<input class="checkbox" type="checkbox" :checked="isChecekdItem(item.id, 'MONTH')" @change="checkedItem(item, 'MONTH')" />
						<span>{{ tt(item.name) }}</span>
					</label>
				</div>
			</div>
			<div class="search-row" v-if="groupList.length > 1">
				<div class="label">{{ tt('그룹') }}</div>
				<div class="column-box">
					<label v-for="item in groupList" :key="item.id" v-b-tooltip.hover="tt(item.name)">
						<input class="checkbox" type="checkbox" :checked="isChecekdItem(item.id, 'GROUP')" @change="checkedItem(item, 'GROUP')" />
						<span>{{ tt(item.name) }}</span>
					</label>
				</div>
			</div>
			<div class="search-row" v-if="budgetList.length > 1">
				<div class="label">{{ tt('예산') }}</div>
				<div class="column-box">
					<label v-for="item in budgetList" :key="item.id" v-b-tooltip.hover="tt(item.name)">
						<input class="checkbox" type="checkbox" :checked="isChecekdItem(item.id, 'BUDGET')" @change="checkedItem(item, 'BUDGET')" />
						<span>{{ tt(item.name) }}</span>
					</label>
				</div>
			</div>
			<div class="result-row" v-show="checkedList.length > 0">
				<div @click="reset">
					<b>{{ tt('전체해제') }}</b
					><img src="../svg/icon-reset.svg" />
					<div class="line"></div>
				</div>
				<div class="select-item-wrapper">
					<div class="selected-item" v-for="item in checkedList" :key="item.id">
						<div v-b-tooltip.hover="tt(item.name)">{{ tt(item.name) }}</div>
						<img src="../svg/icon-remove.svg" @click="removeItem(item)" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SearchBox from '@/views/components/searchBox'
import MixinSearch from '@/views/budget/mixin/search'
export default {
	props: {
		groups: {
			type: Array,
			default: () => [],
		},
		year: {
			type: Number,
		},
		isMonth: {
			type: Boolean,
			default: true,
		},
		budgets: {
			type: Array,
			default: () => [],
		},
		placeholder: {
			type: String,
			default: '그룹으로 검색',
		},
	},
	mixins: [MixinSearch],
	components: {
		SearchBox,
	},
	computed: {},
	methods: {
		changeKeyword(keyword) {
			this.$emit('changeKeyword', keyword)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-search.scss';
</style>
