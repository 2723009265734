<template>
	<div class="gettingStart">
		<div class="starting">
			<div class="wrapper">
				<h1>Getting Started!</h1>
				<p v-html="tt('랩매니저 Pro를 시작하기 위해서 멤버를 등록하고<br>조직도, 건물 위치, 보관함 등을 설정해주세요!')"></p>
				<div class="btn-wrap">
					<button class="Btn__yellow" @click="goRegisteringMember">
						{{ tt('설정하기') }}
					</button>
					<button class="Btn__lg" @click="goExperience">
						{{ tt('튜토리얼') }}
					</button>
					<span @click="goGuide">
						{{ tt('랩매니저 가이드보기') }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import userInfoBox from '@/views/creation/pages/components/userInfoBox.vue'

export default {
	components: {
		userInfoBox,
	},
	methods: {
		goRegisteringMember() {
			this.$router.push({
				name: 'startPro',
				params: {
					instituteId: this.$route.params.instituteId,
				},
			})
		},
		goExperience() {
			location.replace('https://ex-pro.lab-manager.com')
		},
		goGuide() {
			window.open('https://www.notion.so/smartjack/User-Guide-PC-8248257f8c84420ba787f7f0408e3566')
		},
	},
}
</script>

<style lang="scss" scoped>
//temp
.gettingStart {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 101;
	top: 0;
	left: 0;
	background-color: $LAB_WHITE;
}

.gettingStart {
	width: 100vw;
	height: 100vh;
	background-color: $LAB_WHITE;
	position: fixed;
	color: #000;
	.starting {
		display: flex;
		height: inherit;
		justify-content: center;
		align-items: center;
		h1 {
			font-size: 100px;
		}
		p {
			font-size: 1.125rem;
			font-weight: 500;
			margin-top: 13px;
		}
		.btn-wrap {
			width: 200px;
			margin: 50px auto;
			text-align: center;
			button {
				width: 100%;
				height: 47px;
				font-size: 1rem;
				color: #000;
				font-weight: 700;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				&:first-child {
					margin-bottom: 10px;
				}
			}
			span {
				margin-top: 36px;
				display: inline-block;
				text-decoration: underline;
				color: $SECONDARY_2;
				font-size: 0.875rem;
				font-weight: 700;
				cursor: pointer;
			}
		}
	}
}
</style>
