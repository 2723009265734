<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt(title) }}</div>
			<div class="form-msg" :class="{ error: !isCustomValid }" v-if="requiredCustomFields.length > 0">
				{{ isCustomValid ? tt('입력 완료') : tt('필수값을 입력해주세요') }}
			</div>
		</div>
		<div class="form-data-wrap">
			<component
				ref="form"
				v-for="field in requiredCustomFields"
				v-bind:is="formInput(field)"
				:key="`${categoryInfo.id}-${field.key}`"
				:value="formValue(field)"
				:name="field.name"
				:required="field.isRequire"
				:placeholder="formMessage(field)"
				:optionList="formOptionList(field)"
				:errorMessage="formMessage(field)"
				:isShowError="isShowError"
				@change="value => changeFormCustom(value, field)"
				@input="value => inputFormValue(value, field)"
			></component>
			<component
				ref="form"
				v-for="field in customFields"
				v-bind:is="formInput(field)"
				:key="`${categoryInfo.id}-${field.key}`"
				:value="formValue(field)"
				:name="field.name"
				:required="field.isRequire"
				:placeholder="formMessage(field)"
				:optionList="formOptionList(field)"
				:errorMessage="formMessage(field)"
				:isShowError="isShowError"
				@change="value => changeFormCustom(value, field)"
				@input="value => inputFormValue(value, field)"
			></component>
		</div>
	</div>
</template>
<script>
import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
import FormNumberUnit from '@/views/common/components/form/FormNumberWithUnit.vue'
import FormSelect from '@/views/common/components/form/FormSelect.vue'
import FormSelectMember from '@/views/common/components/form/FormSelectMember.vue'
import FormDatetime from '@/views/common/components/form/FormDatetime.vue'
import FormMultipleLine from '@/views/common/components/form/FormMultipleLine.vue'
import FormTextWithUnit from '@/views/common/components/form/FormTextWithUnit.vue'

export default {
	name: 'FormCustom',
	components: {},
	props: {
		title: {
			type: String,
			default: '',
		},
		isCustomValid: {
			type: Boolean,
			default: false,
		},
		categoryInfo: {
			type: Object,
			default: null,
		},
		memberList: {
			type: Array,
			default: () => [],
		},
		itemCustomField: {
			type: Object,
			default: null,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		customFields() {
			return this.categoryInfo?.fields?.customFields?.filter(f => f.isActive && !f.isRequire && !f.readOnly) ?? []
		},
		requiredCustomFields() {
			return this.categoryInfo?.fields?.customFields?.filter(f => f.isActive && f.isRequire && !f.readOnly) ?? []
		},
		formInput() {
			return field => {
				const { varType, key } = field
				return {
					text_single_line: FormTextSingleLine,
					text_single_line_with_unit: FormTextWithUnit,
					number_with_unit: FormNumberUnit,
					select_option: FormSelect,
					select_member: FormSelectMember,
					select_datetime: FormDatetime,
					text_multiple_line: FormMultipleLine,
					null: '',
				}[varType]
			}
		},
		formOptionList() {
			return field => {
				const { varType, key } = field
				switch (varType) {
					case 'text_single_line_with_unit':
						return field.unit
					case 'select_option':
						return field.option
					case 'select_member':
						return this.memberList.map(item => {
							return {
								id: item.id,
								image: item.image,
								name: item.name,
							}
						})
					default:
						return field.option
				}
			}
		},
		formMessage() {
			return field => {
				return `${field.name}을(를) 입력해주세요`
			}
		},
		formValue() {
			return field => {
				let value = this.itemCustomField?.[field.key]
				return value ? value : null
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		changeFormCustom(value, field) {
			this.$emit('change', value, field.key, field.varType)
		},
		showRequire() {
			this.$refs['form'].showRequire()
		},
		inputFormValue(value, field) {
			this.$emit('change', value, field.key, field.varType)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
</style>
