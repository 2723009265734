import image from './image'
import brand from './brand'
import qrcode from './qrcode'

export default {
	namespaced: true,
	modules: {
		image,
		brand,
		qrcode,
	},
}
