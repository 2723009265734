var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card healthCheckup",
    "class": [{
      warning: _vm.list.unsafeCount > 0
    }, {
      safe: _vm.list.unsafeCount == 0
    }, {
      displayOff: !_vm.displayCard
    }]
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._m(0), _c('h4', {
    staticClass: "Btn__hover-balloon-w",
    on: {
      "click": _vm.goPage
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('특수건강진단 현황')) + " "), _c('div', {
    staticClass: "balloon"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('특수건강진단 목록으로 이동합니다')))])]), _vm._m(1)]), _vm.setting == false ? _c('h3', {
    staticClass: "ml-auto",
    domProps: {
      "textContent": _vm._s(_vm.list.unsafeCount ? _vm.tt('${1}명 진단 필요', _vm.list.unsafeCount) : _vm.tt('안전'))
    }
  }) : _c('div', {
    staticClass: "ml-auto switch-setting"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('대시보드에 노출')))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.displayCard,
      expression: "displayCard"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.displayCard) ? _vm._i(_vm.displayCard, null) > -1 : _vm.displayCard
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.displayCard,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.displayCard = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.displayCard = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.displayCard = $$c;
        }
      }, function ($event) {
        return _vm.toggleCard(_vm.id, _vm.displayCard);
      }]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('div', {
    staticClass: "card-noti"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('특수건강진단 현황')))]), _vm.list.overdueList.length > 0 || _vm.list.targetList.length > 0 ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<span style=color:#ff3b31;>진단일 초과자</span>와 진단 대상자 중<span style=color:#ff3b31;> 1달 안에 진단을 받아야 하는 인원</span>들을 보여줍니다.'))
    }
  }) : _c('p', [_vm._v(_vm._s(_vm.tt('특수건강진단이 필요한 인원이 없습니다.')))])]), _vm.list.overdueList.length > 0 || _vm.list.targetList.length > 0 ? _c('div', {
    staticClass: "card-box"
  }, [_c('div', {
    staticClass: "box-title"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('진단일 초과자')))]), _vm.list.overdueList.length > 0 ? _c('h4', {
    staticClass: "ml-auto warning"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-error.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('${1}명', _vm.list.overdueList.length)) + " ")]) : _c('h4', {
    staticClass: "ml-auto"
  }, [_vm._v(_vm._s(_vm.tt('없음')))])]), _c('div', {
    staticClass: "box-content"
  }, [_c('ul', _vm._l(_vm.list.overdueList, function (item) {
    return _c('li', {
      key: item.id,
      on: {
        "click": function click($event) {
          return _vm.goDetail(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "imgbox"
    }, [_c('img', {
      attrs: {
        "src": item.image ? item.image : require('@/assets/svg/member-photo-default.svg'),
        "alt": "people"
      }
    })]), _c('p', [_vm._v(_vm._s(item.name))]), _vm._m(2, true)]);
  }), 0)])]) : _vm._e(), _vm.list.overdueList.length > 0 || _vm.list.targetList.length > 0 ? _c('div', {
    staticClass: "card-box"
  }, [_c('div', {
    staticClass: "box-title"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('진단 대상자')))]), _vm.list.targetList.length > 0 ? _c('h4', {
    staticClass: "ml-auto"
  }, [_vm._v(" " + _vm._s(_vm.tt('${1}명', _vm.list.targetList.length)) + " ")]) : _c('h4', {
    staticClass: "ml-auto"
  }, [_vm._v(_vm._s(_vm.tt('없음')))])]), _c('div', {
    staticClass: "box-content"
  }, [_c('ul', _vm._l(_vm.list.targetList, function (item) {
    return _c('li', {
      key: item.id,
      on: {
        "click": function click($event) {
          return _vm.goDetail(item.id);
        }
      }
    }, [_c('div', {
      staticClass: "imgbox"
    }, [_c('img', {
      attrs: {
        "src": item.image ? item.image : require('@/assets/svg/member-photo-default.svg'),
        "alt": "people"
      }
    })]), _c('p', [_vm._v(_vm._s(item.name))])]);
  }), 0)])]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/dashboard-icon2.svg"),
      "alt": "icon"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow-diagonal.svg"),
      "alt": "icon"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-error.svg"),
      "alt": "icon"
    }
  })]);
}]

export { render, staticRenderFns }