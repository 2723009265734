import { render, staticRenderFns } from "./FormTagList.vue?vue&type=template&id=790bb16f&scoped=true&"
import script from "./FormTagList.vue?vue&type=script&lang=js&"
export * from "./FormTagList.vue?vue&type=script&lang=js&"
import style0 from "./FormTagList.vue?vue&type=style&index=0&id=790bb16f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790bb16f",
  null
  
)

export default component.exports