<template>
	<table class="lm-table">
		<table-header />
		<table-item v-for="item in list" :key="item.id" :item="item" />
		<div class="empty-table" v-if="count === 0">
			<img class="empty-img" :src="require('@/assets/svg/no-data_item.svg')" />
			<p class="bold">{{ tt('등록대기 중인 물품이 없습니다') }}</p>
			<p class="sub">{{ tt('사진으로 등록하면 목록에 자동으로 추가됩니다') }}</p>
		</div>
	</table>
</template>
<script>
import TableHeader from './header.vue'
import TableItem from './item.vue'
import { mapState } from 'vuex'
export default {
	components: {
		TableHeader,
		TableItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapState('inventoryGen2/wait', ['count', 'loadParams']),
	},
	methods: {},
}
</script>
<style lang="scss" scoped>
.lm-table {
	position: relative;
	height: 100%;
	text-align: left;
	display: block;
	overflow: overlay;
	white-space: nowrap;
	border-spacing: 0;
	border-collapse: separate;
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: $PLACEHOLDER;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		text-align: center;
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
