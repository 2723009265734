var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lm-input-wrapper"
  }, [_c('span', {
    staticClass: "input-title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _vm.isRequired ? _c('p', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]), _c('input', {
    staticClass: "lm-input",
    "class": [{
      error: _vm.isError
    }],
    attrs: {
      "placeholder": _vm.placeholder,
      "maxlength": "13"
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.handleInput
    }
  }), _vm.isError ? _c('span', {
    staticClass: "input-error-msg"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }