<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Location__delete-location-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt(title) }}</h5>
					<button class="Popup__close-btn" @click="cancel">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text" v-html="tt(content)"></p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__delete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="cancel">
							{{ tt('취소') }}
						</button>
						<button class="Btn__delete" @click="confirm">
							{{ tt(delBtnText) }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
export default {
	name: 'ModalConfirmDeleteV2',
	mixins: [MixinModal],
	props: {
		title: {
			type: String,
		},
		content: {
			type: String,
		},
		delBtnText: {
			type: String,
			default: '삭제',
		},
		delBtnFn: {
			type: Function,
		},
	},
	data() {
		return {}
	},
	methods: {
		show() {
			this.isShow = true
		},
		hide() {
			this.isShow = false
		},
		confirm() {
			this.delBtnFn()
			this.hide()
		},
		cancel() {
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped></style>
