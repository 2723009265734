<template>
	<tr class="table-item" :class="{ new: isNew }" @click="goToDetail">
		<td @click.stop>
			<!-- 체크박스 -->
			<span class="checkbox"><input type="checkbox" v-model="checked" /> </span>
		</td>
		<td>
			<div style="min-width: 100px">
				<span style="display: flex; gap: 10px">
					<img :src="status.image" />
					<p class="status" :class="item.status">
						{{ tt(status.text) }}
					</p>
				</span>
			</div>
		</td>
		<td @click.stop>
			<span class="favorite-wrap"><input type="checkbox" class="favorite" v-model="isFavorite" /></span>
		</td>
		<td style="height: 80px">
			<div class="info" style="min-width: 250px; max-width: 400px" v-b-tooltip.hover="item.name">
				<span>
					<b v-if="item.holder" :class="item.status"> {{ tt('사용자') }}: {{ item.holder.name }} </b>
					<b v-if="item.volume === 0">{{ tt('용량없음') }}</b>
					<b v-if="isExpire">{{ tt('유효기간 만료') }}</b>
				</span>
				<h6 style="font-weight: 700" v-html="replaceHighlight(item.name)"></h6>
				<div class="subtext">
					<p v-if="item.brand && item.brand.name">{{ item.brand.name }}</p>
					<p v-if="item.subname">{{ item.subname }}</p>
				</div>
			</div>
		</td>
		<td v-if="isView('categoryClassification')">
			<div>
				<span>{{ tt(categoryClasification) }}</span>
			</div>
		</td>
		<td v-if="isView('categoryType')">
			<div>
				<span>{{ tt(categoryType) }}</span>
			</div>
		</td>
		<td v-if="isView('category')">
			<div>
				<span>{{ item.category.name }}</span>
			</div>
		</td>
		<td v-if="isView('brand')">
			<div>
				<span>{{ isEmpty(item.brand?.name) }}</span>
			</div>
		</td>
		<td v-if="isView('mngcode')">
			<div v-b-tooltip.hover="item.mngcode == null ? '관리번호 생성중입니다.\n1~2분정도 소요됩니다.' : null">
				<span v-html="replaceHighlight(item.mngcode == null && item.isPostprocessing ? '생성중' : item.mngcode ?? '-')"></span>
			</div>
		</td>
		<td v-if="isView('incompanyMngcode')">
			<!-- 사내관리번호 -->
			<div>
				<span v-html="replaceHighlight(item.incompanyMngcode)"></span>
			</div>
		</td>
		<td v-if="isView('productCode')">
			<div>
				<span v-html="replaceHighlight(item.productCode)"></span>
			</div>
		</td>
		<td v-if="isView('casno') && isSelectedChemical">
			<div>
				<span v-html="replaceHighlight(item.casno)"></span>
			</div>
		</td>
		<td v-if="isView('lotno')">
			<!-- Lot No -->
			<div>
				<span v-html="replaceHighlight(item.lotno)"></span>
			</div>
		</td>
		<td v-if="isView('volume') && isSelectedChemical">
			<div>
				<span>{{ volume }}</span>
			</div>
		</td>
		<td v-if="isView('currentVolume') && isSelectedChemical">
			<div>
				<span>{{ currentVolume }}</span>
			</div>
		</td>
		<td v-if="isView('etc_volume')">
			<div>
				<span>{{ packageVolume }}</span>
			</div>
		</td>
		<td v-if="isView('etc_currentvolume')">
			<div>
				<span>{{ currentPackageVolume }}</span>
			</div>
		</td>
		<td v-if="isView('location')">
			<div class="right">
				<span>{{ locationWithStorage }}</span>
			</div>
		</td>
		<td v-if="isView('group')">
			<div>
				<span>{{ item.storage.assigneeGroup ? isEmpty(item.storage.assigneeGroup.name) : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('assignee')">
			<div class="profile-wrap" style="min-width: 100px">
				<img class="profile-img" :src="assigneeImage" />
				<p v-html="replaceHighlight(item.assignee?.name)"></p>
			</div>
		</td>
		<td v-if="isView('msds') && isSelectedChemical">
			<div class="msds-wrap" :class="item.hasMsds && isItemChemical ? 'registered' : 'warning'">
				<template v-if="isItemChemical">
					<img src="@/assets/images/uploaded.png" alt="icon" />
					<p v-text="item.hasMsds ? tt('등록됨') : tt('업로드 필요')"></p>
				</template>
				<p v-else>{{ tt('해당 없음') }}</p>
			</div>
		</td>
		<td v-if="isView('expireDatetime') && isSelectedChemical">
			<div>
				<span>{{ isEmpty(expireDatetime) }}</span>
			</div>
		</td>
		<td v-if="isView('createDatetime')">
			<div>
				<span>{{ isEmpty(createDatetime) }}</span>
			</div>
		</td>
		<td v-if="isView('updateDatetime')">
			<div>
				<span>{{ isEmpty(updateDatetime) }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
const STATUS = {
	AVAILABLE: {
		image: require('@/views/inventoryGen2/svg/Usable.svg'),
		text: '사용가능',
	}, // 사용가능
	UNAVAILABLE: {
		image: require('@/views/inventoryGen2/svg/Unusable.svg'),
		text: '사용불가',
	}, // 사용불가
	HOLDING_OWN: {
		image: require('@/views/inventoryGen2/svg/Subtract.svg'),
		text: '사용중',
	}, // 사용중 (나)
}
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import MixinItem from '@/mixins/inventory/MixinItem'
import { DateTime } from 'luxon'
import { MiddleClassToString, LargeClassToString } from '@/utils/define/ItemCode'
import { Classification } from '@/utils/define/ItemCode'
export default {
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		keyword: {
			type: String,
			default: '',
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('inventoryGen2/list', ['selectedItems', 'checkedViewOption']),
		...mapGetters('inventoryGen2/list', ['selectedCategory']),
		categoryClasification() {
			return LargeClassToString(this.item.category.classification) ?? '-'
		},
		categoryType() {
			return MiddleClassToString(this.item.category.type) ?? '-'
		},
		status() {
			return STATUS[this.item.status]
		},
		assigneeImage() {
			return this.item.assignee && this.item.assignee.image ? this.item.assignee.image : require('@/assets/svg/member-photo-default.svg')
		},
		isExpire() {
			return this.item.expireDatetime < this.$DateTime.now().toISO()
		},
		locationWithStorage() {
			const locationName = this.item.location.pathName
			const storageName = this.item.storage.pathName
			return locationName.concat('/', storageName)
		},
		volume() {
			if ((this.item.volume ?? null) == null) return '-'
			return this.item.category.classification === 'CAT_CHEMICAL'
				? `${this.formatNumberLocale(this.financial(this.item.volume))}${this.item.volumeUnit ?? ''}`
				: '-'
		},
		packageVolume() {
			if ((this.item.volume ?? null) == null) return '-'
			return this.item.category.classification === 'CAT_CHEMICAL'
				? '-'
				: `${this.formatNumberLocale(this.financial(this.item.volume))}${this.item.volumeUnit ?? ''}`
		},
		currentVolume() {
			if ((this.item.currentVolume ?? null) == null) return '-'
			return this.item.category.classification === 'CAT_CHEMICAL'
				? `${this.formatNumberLocale(this.financial(this.item.currentVolume))}${this.item.volumeUnit ?? ''}`
				: '-'
		},
		currentPackageVolume() {
			if ((this.item.currentVolume ?? null) == null) return '-'
			return this.item.category.classification === 'CAT_CHEMICAL'
				? '-'
				: `${this.formatNumberLocale(this.financial(this.item.currentVolume))}${this.item.volumeUnit ?? ''}`
		},
		checked: {
			get() {
				return this.selectedItems.some(item => item.id === this.item.id)
			},
			set(value) {
				if (value) {
					this.setSelectedItems(this.selectedItems.concat(this.item))
				} else {
					this.setSelectedItems(this.selectedItems.filter(item => item.id !== this.item.id))
				}
			},
		},
		isSelectedChemical() {
			return this.selectedCategory == Classification.CHEMICAL || this.selectedCategory == 'ALL'
		},
		isItemChemical() {
			return this.item.category.classification == Classification.CHEMICAL
		},
		isFavorite: {
			get() {
				return this.item.favorite
			},
			async set(value) {
				if (value) await this.addFavorite(this.item.id)
				else await this.deleteFavorite(this.item.id)
			},
		},
	},
	methods: {
		...mapMutations('inventoryGen2/list', ['setSelectedItems']),
		...mapActions('inventoryGen2/list', ['addFavorite', 'deleteFavorite']),
		goToDetail() {
			this.setNew()
			this.$emit('click', this.item.id)
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
.msds-wrap {
	display: flex;
	line-height: 14px;
	gap: 5px;
	&.registered {
		color: $GREEN;
		img {
			height: 14px;
		}
	}
	&.warning {
		color: $PLACEHOLDER !important;
		img {
			content: url(~@/assets/svg/not-uploaded.svg);
		}
	}
}
.status {
	font-weight: 700;
	color: $RED;
	&.AVAILABLE {
		color: $GREEN;
	}
	&.HOLDING_OWN {
		color: $BLUE;
	}
}
.info {
	h6 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	span {
		display: flex;
		gap: 10px;
		b {
			border-radius: 4px;
			background-color: $SUB-RED;
			padding: 3px;
			font-size: 10px;
			color: $RED;
			&.HOLDING_OWN {
				color: $BLUE;
				background-color: $SUB-BLUE;
			}
		}
	}
	.subtext {
		display: flex;
		gap: 10px;
		p {
			font-size: 12px;
			color: $SECONDARY_2;
			&:first-child:nth-last-child(1) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&:nth-child(2) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-left: 10px;
				border-left: 1px solid $INPUT;
			}
		}
	}
}
::v-deep {
	.highlight {
		background-color: #ff3b314d;
	}
}
</style>
