import axiosInstance from '@/services/axios'

/**
 * instId : 기관 아이디
 */

const endPoint = {
	createLocation: instId => `/institute/${instId}/location`,
	lookupLocationTree: instId => `/institute/${instId}/location/tree`,
	lookupLocationDetail: (instId, locationId) => `/institute/${instId}/location?id=${locationId}`,
	updateLocation: instId => `/institute/${instId}/location`,
	deleteLocation: (instId, locationId) => `/institute/${instId}/location?id=${locationId}`,
	lookupWarningList: () => `/storage/warning/signs`,
	lookupLocationStorageTree: instId => `/institute/${instId}/location/storage/tree`,
}

const locationAPI = {
	createLocation: (instId, data) => axiosInstance.post(endPoint.createLocation(instId), data),
	lookupLocationTree: instId => axiosInstance.get(endPoint.lookupLocationTree(instId)),
	lookupLocationDetail: (instId, locationId) => axiosInstance.get(endPoint.lookupLocationDetail(instId, locationId)),
	updateLocation: (instId, data) => axiosInstance.put(endPoint.updateLocation(instId), data),
	deleteLocation: (instId, locationId) => axiosInstance.delete(endPoint.deleteLocation(instId, locationId)),
	lookupWarningList: () => axiosInstance.get(endPoint.lookupWarningList()),
	lookupLocationStorageTree: instId => axiosInstance.get(endPoint.lookupLocationStorageTree(instId)),
}

export default locationAPI
