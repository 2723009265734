<template>
	<div class="card disposalStatus" :class="!displayCard ? 'displayOff' : ''">
		<div class="card-title">
			<div class="imgbox">
				<img src="@/assets/svg/dashboard-icon1.svg" alt="icon" />
			</div>
			<h4 class="Btn__hover-balloon-w" @click="goPage()">
				{{ tt('물품 폐기 현황') }}
				<div class="balloon">
					<p>{{ tt('폐기/삭제된 목록으로 이동합니다') }}</p>
				</div>
				<button>
					<img src="@/assets/svg/arrow-diagonal.svg" alt="icon" />
				</button>
			</h4>
			<div class="ml-auto switch-setting" v-if="setting == true">
				<h5>{{ tt('대시보드에 노출') }}</h5>
				<label class="switch">
					<input type="checkbox" v-model="displayCard" @change="toggleCard(id, displayCard)" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="card-box">
			<div class="box-title">
				<h5>{{ tt('물품 폐기 트렌드') }}</h5>
				<div class="ml-auto radio-btn-wrap">
					<input type="radio" name="disposalDateSelect" id="disposalDate1" value="0" v-model="selectDate" /><label for="disposalDate1">{{
						tt('일')
					}}</label>
					<input type="radio" name="disposalDateSelect" id="disposalDate2" value="1" v-model="selectDate" /><label for="disposalDate2">{{
						tt('주')
					}}</label>
					<input type="radio" name="disposalDateSelect" id="disposalDate3" value="2" v-model="selectDate" /><label for="disposalDate3">{{
						tt('월')
					}}</label>
				</div>
			</div>
			<div class="box-content">
				<div class="item-noti" @click="goHistory(null)" style="cursor: pointer">
					<p>
						<span v-if="selectDate == 0">{{ tt('오늘 폐기된 물품 수') }}</span
						><span v-else-if="selectDate == 1">{{ tt('금주 폐기된 물품 수') }}</span
						><span v-else>{{ tt('이번 달 폐기된 물품 수') }}</span>
					</p>
					<h3>{{ trend.current }}{{ tt('개') }}</h3>
					<span :class="trend.contrast > 0 ? 'up' : trend.contrast < 0 ? 'down' : ''"
						><span class="triangle"></span>{{ trend.contrast }}{{ tt('개') }}(<span v-if="selectDate == 0">{{ tt('전일대비') }}</span
						><span v-else-if="selectDate == 1">{{ tt('전주대비') }}</span
						><span v-else>{{ tt('전달대비') }}</span
						>)</span
					>
				</div>
				<div class="chart-column-wrap" ref="chart-column-wrap" style="cursor: default">
					<div v-if="!showChart" class="list-no-data">
						<img src="@/assets/svg/no-data_item.svg" alt="icon" />
						<b>{{ tt('폐기된 물품이 없습니다') }}</b>
					</div>
					<GChart
						:class="showChart ? '' : 'noChart'"
						type="ColumnChart"
						:data="trendChartData"
						:options="chartOptions"
						class="column-chart"
						style="width: 100%"
					/>
				</div>
			</div>
		</div>
		<div class="card-box">
			<div class="box-title">
				<h5>
					<span v-if="selectDate == 0">{{ tt('오늘 폐기된 물품 수') }}</span
					><span v-else-if="selectDate == 1">{{ tt('금주 폐기된 물품 수') }}</span
					><span v-else>{{ tt('이번 달 폐기된 물품 수') }}</span>
				</h5>
			</div>
			<div class="box-content">
				<div class="box-inner" v-for="(item, index) in summary" :key="index">
					<span class="subtitle">{{ tt(matchSubtitle(index)) }}</span>

					<ul>
						<li v-for="subitem in item" :key="subitem.id" @click="goHistory(subitem.id)">
							<p>{{ subitem.name }}</p>
							<h5>{{ subitem.count.toLocaleString() }}{{ tt('개') }}</h5>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { GChart } from 'vue-google-charts'
import { Classification } from '@/utils/define/ItemCode'
import PageName from '@/utils/define/PageName'

export default {
	name: 'CardDisposalStatus',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		instId: {
			type: String,
			default: '',
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		trend: {
			type: Object,
			default: () => {},
		},
		summary: {
			type: Object,
			default: () => {},
		},
		toggleCard: {
			type: Function,
			default: () => {},
		},
	},
	components: {
		GChart,
	},
	mounted() {
		this.displayCard = this.visible
	},
	watch: {
		selectDate(val) {
			const params = {
				instId: this.$route.params.instituteId,
				scope: ['day', 'week', 'month'][val],
			}
			this.lookupDisposalTrendList(params)
			this.lookupDisposalTrendSummary(params)
		},
	},
	computed: {
		trendChartData() {
			return this.matchTrendData()
		},
		chartOptions() {
			return {
				legend: 'none',
				colors: ['#FF3B31'],
				backgroundColor: {
					fill: 'transparent',
					stroke: 'transparent',
				},
				height: 185,
				bar: { groupWidth: '80%' },
				vAxis: {
					gridlines: {},
					gridlineColor: 'transparent',
					textPosition: 'none',
					baselineColor: '#ddd',
				},
				hAxis: {
					gridlineColor: 'transparent',
					baselineColor: 'transparent',
					textStyle: {
						color: '#666',
						fontSize: 10,
					},
					maxTextLines: 2,
					ticks: this.ticks,
				},
				tooltip: {
					isHtml: true,
				},
			}
		},
	},
	data() {
		return {
			displayCard: true,
			selectDate: 0,
			ticks: [],
			divide: 3,
			trendData: [],
			showChart: true,
		}
	},
	methods: {
		...mapActions('dashboard', ['lookupDisposalTrendList', 'lookupDisposalTrendSummary']),
		matchTrendData() {
			this.trendData = []
			const hAxisArr = []
			const dateArr = []
			this.showChart = false

			if (this.trend.list != null) {
				const item = this.trend.list
				const tickArr = []

				for (let i = 0; i < item.length; i++) {
					const trendArr = [i, item[i].count, item[i].date + ':' + '\n' + '\n' + item[i].count + this.tt('개')]
					if (item[i].count > 0) {
						this.showChart = true
					}
					this.trendData.push(trendArr)
					dateArr.push(this.dateFormat(item[i].date))
					hAxisArr.push(i)
				}

				const divide = this.divide
				hAxisArr.forEach(function (n) {
					if (n % divide == 0) {
						tickArr.push({
							v: n,
							f: dateArr[n],
						})
					}
				})

				this.ticks = tickArr

				this.trendData.unshift([this.tt('날짜'), this.tt('개수'), { type: 'string', role: 'tooltip', p: { html: true } }])
			}

			return this.trendData
		},
		matchSubtitle(index) {
			return Classification.typeToString(index)
		},
		dateFormat(date) {
			if (this.selectDate == 1) {
				this.divide = 5
				return date.slice(0, date.indexOf('('))
			} else if (this.selectDate == 2) {
				this.divide = 3
				return date.slice(0, 8)
			} else {
				this.divide = 7
				return date
			}
		},
		getDateStr(date) {
			var year = date.getFullYear()
			var month = date.getMonth() + 1
			var day = date.getDate()

			month = month < 10 ? '0' + String(month) : month
			day = day < 10 ? '0' + String(day) : day

			return year + '-' + month + '-' + day
		},
		goPage() {
			this.$router.push({
				name: PageName.InventoryGen2.DisposalList,
			})
		},
		goHistory(id = null) {
			let day = new Date()
			let monthOfYear = day.getMonth()
			let dayOfMonth = day.getDate()
			if (this.selectDate == 1) {
				day.setDate(dayOfMonth - 7)
			} else if (this.selectDate == 2) {
				day.setMonth(monthOfYear - 1)
			}

			let startDatetime = this.getDateStr(day)
			let endDatetime = this.getDateStr(new Date())
			let query = {
				startDatetime: startDatetime,
				endDatetime: endDatetime,
				status: ['DISPOSE'],
			}
			if (id) {
				query.categoriesId = [id]
			}
			this.$router.push({
				name: PageName.InventoryGen2.DisposalList,
				query: query,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';

.disposalStatus {
	li {
		cursor: pointer;
	}
}
</style>
