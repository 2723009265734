var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "image-viewer",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "content"
  }, [!_vm.isRawSvg ? _c('img', {
    staticClass: "image",
    attrs: {
      "src": _vm.image
    }
  }) : _c('div', {
    staticClass: "image",
    domProps: {
      "innerHTML": _vm._s(_vm.image)
    }
  })])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }