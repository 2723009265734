<template>
	<div class="card-input-wrapper">
		<number-input
			ref="firstInput"
			v-model="cardNumbers[0]"
			:placeholder="placeholder"
			:maxlength="4"
			:disabled="disabled"
			:error="!isValid || error"
			:errorMessage="errorMessage"
			:inputStyle="{ width: '100%', textAlign: 'center' }"
			style="flex: 1"
			@input="onInput(0, $event)"
			@focus="hideKeypad"
		/>
		<password-input
			v-model="cardNumbers[1]"
			:placeholder="placeholder"
			:maxlength="4"
			:disabled="disabled"
			:error="!isValid || error"
			:errorMessage="errorMessage"
			:inputStyle="{ width: '100%', textAlign: 'center' }"
			style="flex: 1"
			readonly
			@click="initKeypad(1)"
		/>
		<password-input
			v-model="cardNumbers[2]"
			:placeholder="placeholder"
			:maxlength="4"
			:disabled="disabled"
			:error="!isValid || error"
			:errorMessage="errorMessage"
			:inputStyle="{ width: '100%', textAlign: 'center' }"
			style="flex: 1"
			readonly
			@click="initKeypad(2)"
		/>
		<number-input
			ref="lastInput"
			v-model="cardNumbers[3]"
			:placeholder="placeholder"
			:maxlength="4"
			:disabled="disabled"
			:error="!isValid || error"
			:errorMessage="errorMessage"
			:inputStyle="{ width: '100%', textAlign: 'center' }"
			style="flex: 1"
			@input="onInput(3, $event)"
			@focus="hideKeypad"
		/>

		<numeric-keypad ref="keypad" @input="onInputKeypad" @delete="onDeleteKeypad" @reset="onResetKeypad" @close="hideKeypad" />
	</div>
</template>

<script>
import BaseInput from '@/components/input/BaseInput.vue'
import NumberInput from '@/components/input/NumberInput.vue'
import PasswordInput from '@/components/input/PasswordInput.vue'
import NumericKeypad from '@/components/input/NumericKeypad.vue'

export default {
	name: 'CardInput',
	components: {
		BaseInput,
		NumberInput,
		PasswordInput,
		NumericKeypad,
	},
	extends: BaseInput,
	data() {
		return {
			cardNumbers: ['', '', '', ''],
			currentIndex: 0,
		}
	},
	watch: {
		cardNumbers: {
			handler(val) {
				this.$emit('input', val.join(''))
			},
			deep: true,
		},
	},
	methods: {
		initKeypad(index) {
			this.hideKeypad()

			this.currentIndex = index
			this.showKeypad()
		},
		showKeypad() {
			this.$refs.keypad.show()
		},
		hideKeypad() {
			this.$refs.keypad.hide()
		},
		onInput(index, value) {
			this.cardNumbers[index] = value
			this.cardNumbers = this.$_.cloneDeep(this.cardNumbers)
			this.moveFocusInput(index)
		},
		moveFocusInput(index) {
			if (this.cardNumbers[index].length >= 4) {
				if (index === 0) {
					this.initKeypad(1)
					this.$refs.firstInput.blur()
				}
			} else if (this.cardNumbers[index].length <= 0) {
				if (index === 3) {
					this.initKeypad(2)
					this.$refs.lastInput.blur()
				}
			}
		},
		onInputKeypad(value) {
			if (this.cardNumbers[this.currentIndex].length >= 4) return

			this.cardNumbers[this.currentIndex] += value
			this.cardNumbers = this.$_.cloneDeep(this.cardNumbers)
			this.moveFocusKeypad()
		},
		onDeleteKeypad() {
			if (this.cardNumbers[this.currentIndex].length <= 0) return

			let temp = ''
			for (let i = this.currentIndex; i >= 0; i--) {
				if (this.cardNumbers[i].length <= 0) continue

				temp = this.cardNumbers[i].substring(0, this.cardNumbers[i].length - 1)
				break
			}
			this.cardNumbers[this.currentIndex] = temp
			this.cardNumbers = this.$_.cloneDeep(this.cardNumbers)
			this.moveFocusKeypad()
		},
		moveFocusKeypad() {
			if (this.cardNumbers[this.currentIndex].length >= 4) {
				if (this.currentIndex === 1) {
					this.initKeypad(this.currentIndex + 1)
				} else if (this.currentIndex === 2) {
					this.$refs.lastInput.focus()
				}
				return
			} else if (this.cardNumbers[this.currentIndex].length <= 0) {
				if (this.currentIndex === 1) {
					this.$refs.firstInput.focus()
				} else if (this.currentIndex === 2) {
					this.initKeypad(this.currentIndex - 1)
				}
			}
		},
		onResetKeypad() {
			this.cardNumbers = ['', '', '', '']
			this.currentIndex = 0
			this.hideKeypad()
		},
	},
}
</script>

<style lang="scss" scoped>
.card-input-wrapper {
	display: flex;
	gap: 8px;
	width: 100%;
}
</style>
