var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showBalloon ? _c('div', {
    staticClass: "order-balloon",
    "class": {
      left: _vm.mode == 'left'
    }
  }, [_c('div', {
    staticClass: "order-top"
  }, [_c('div', {
    staticClass: "order"
  }, _vm._l(_vm.orderStep, function (item) {
    return _c('span', {
      "class": {
        active: _vm.order == item
      }
    });
  }), 0), _c('button', {
    on: {
      "click": function click($event) {
        _vm.showBalloon = false;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('h6', [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('p', [_vm._v(_vm._s(_vm.tt(_vm.content)))])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }