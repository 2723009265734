<template>
	<div class="Menu__floating" v-if="selectedItems.length > 0">
		<h4>
			<span class="count">{{ tt('${1}개', selectedItems.length) }}</span>
			{{ tt('선택') }}
		</h4>
		<div class="btn-group">
			<div v-b-tooltip.hover="!canUse ? tt('사용할 수 없는 항목이 선택되었습니다') : ''">
				<button @click="$refs['modal-use-inventory'].show()" :class="{ disabled: !canUse }">
					<img v-if="canUse" src="@/assets/svg/Use.svg" />
					<img v-else src="@/assets/svg/Use-disabled.svg" />
					<span>{{ tt('사용') }}</span>
				</button>
			</div>
			<div v-b-tooltip.hover="!canReturn ? tt('사용완료할 수 없는 항목이 선택되었습니다') : ''">
				<button @click="$refs['modal-return-inventory'].show()" :class="{ disabled: !canReturn }">
					<img v-if="canReturn" src="@/assets/svg/Return.svg" />
					<img v-else src="@/assets/svg/Return-disabled.svg" />
					<span>{{ tt('사용완료') }}</span>
				</button>
			</div>
			<div v-b-tooltip.hover="!canMove ? tt('이동할 수 없는 항목이 선택되었습니다') : ''">
				<button @click="$refs['modal-select-storage'].show()" :class="{ disabled: !canMove }">
					<img v-if="canMove" src="@/assets/svg/Move.svg" />
					<img v-else src="@/assets/svg/Move-disabled.svg" />
					<span>{{ tt('이동') }}</span>
				</button>
			</div>
			<div>
				<button @click="$refs['modal-print-sticker'].show()">
					<img src="@/views/inventoryGen2/svg/Print.svg" />
					<span>{{ tt('스티커 출력') }}</span>
				</button>
			</div>
			<div v-b-tooltip.hover="!canDispose ? tt('폐기할 수 없는 항목이 선택되었습니다') : ''">
				<button @click="$refs['modal-dispose'].show()" :class="{ disabled: !canDispose }">
					<img v-if="canDispose" src="@/assets/svg/dispose.svg" />
					<img v-else src="@/assets/svg/Dispose-disabled.svg" />
					<span>{{ tt('폐기') }}</span>
				</button>
			</div>
			<div
				v-if="userPermission.item && userPermission.item.delete"
				v-b-tooltip.hover="!canRemove ? tt('삭제할 수 없는 항목이 선택되었습니다') : ''"
			>
				<button @click="$refs['modal-remove'].show()" :class="{ disabled: !canRemove }" v-if="userPermission && userPermission.item.delete">
					<img v-if="canRemove" src="@/assets/svg/Trash.svg" />
					<img v-else src="@/assets/svg/Trash-disabled.svg" />
					<span>{{ tt('삭제') }}</span>
				</button>
			</div>
			<div style="position: relative" v-b-tooltip.hover="!canEdit ? tt('편집할 수 없는 항목이 선택되었습니다') : ''">
				<button @click="onShowEdit" :class="{ disabled: !canEdit, active: showEdit }">
					<img v-if="canEdit" src="@/assets/svg/button-icon-edit.svg" />
					<img v-else src="@/assets/svg/button-icon-edit-disabled.svg" />
					<span>{{ tt('편집') }}</span>
				</button>
				<div class="dropup" v-if="showEdit">
					<div @click="$refs['ModalMultiEditName'].show()">이름</div>
					<div v-if="canEditExpireDatetime" @click="$refs['ModalDatepicker'].show()">유효기간</div>
					<div v-else class="canEdit" v-b-tooltip.html.hover.right="'선택항목 중에 유효기간 정보가<br/>없는 물품이 존재합니다.'">
						유효기간
					</div>
					<div v-if="canEditExpireDatetime" @click="$refs['ModalMultiEditCasno'].show()">CAS No</div>
					<div v-else class="canEdit" v-b-tooltip.html.hover.right="'선택항목 중에 CAS No 정보가<br/>없는 물품이 존재합니다.'">CAS No</div>
					<div @click="$refs['ModalMultiEditCode'].show()">제품번호</div>
					<div @click="$refs['ModalBrand'].show()">브랜드</div>
					<div @click="$refs['ModalSelectMember'].show()">담당자</div>
				</div>
			</div>
		</div>
		<modal-confirm
			ref="modal-use-inventory"
			title="물품 사용"
			confirmText="사용"
			@confirm="use"
			:content="`선택된 ${selectedItems.length}개 물품을 사용하시겠습니까?`"
		/>
		<modal-confirm
			ref="modal-return-inventory"
			title="물품 사용완료"
			confirmText="사용완료"
			@confirm="useComplete"
			:content="`선택된 ${selectedItems.length}개 물품을 사용완료하시겠습니까?`"
		/>
		<modal-single-select-storage title="위치 및 보관함 선택" ref="modal-select-storage" @complete="changeStorage"></modal-single-select-storage>
		<modal-print-sticker ref="modal-print-sticker" :selectedId="selectedItemsId"></modal-print-sticker>
		<modal-textarea
			ref="modal-dispose"
			title="물품 폐기"
			titleTextarea="폐기 사유"
			:headerText="`${selectedItems.length}개 물품을 정말로 폐기하시겠습니까? 이 동작은 되돌릴 수 없습니다.`"
			placeholder="폐기 사유를 입력해주세요(100자 제한)"
			confirmText="폐기"
			variant="warning"
			type="DISPOSE"
			@complete="dispose"
		></modal-textarea>
		<modal-textarea
			ref="modal-remove"
			title="물품 삭제"
			titleTextarea="삭제 사유"
			:headerText="`${selectedItems.length}개 물품을 정말로 삭제하시겠습니까? 이 동작은 되돌릴 수 없습니다.`"
			placeholder="삭제 사유를 입력해주세요(100자 제한)"
			confirmText="삭제"
			variant="warning"
			type="DELETE"
			@complete="remove"
		></modal-textarea>
		<ModalPrompt
			ref="ModalMultiEditName"
			:title="`${selectedItemsId.length}개 물품 편집`"
			label="이름"
			@confirm="value => (changeEditValue(value, 'name'), $refs['ModalConfirmEditName'].show())"
			isRequired
			confirmText="변경"
		/>
		<ModalPrompt
			ref="ModalMultiEditCasno"
			:title="`${selectedItemsId.length}개 물품 편집`"
			label="CAS No"
			@confirm="value => (changeEditValue(value, 'casno'), $refs['ModalConfirmEditCasno'].show())"
			isRequired
			confirmText="변경"
		/>
		<ModalConfirm
			ref="ModalConfirmEditName"
			title="정말로 변경하시겠습니까?"
			:content="`선택된 ${selectedItemsId.length}개 물품의 이름이 '${editValue.name}'(으)로 변경됩니다.`"
			confirmText="완료"
			@confirm="updateMultipleConfirm"
		/>
		<ModalConfirm
			ref="ModalConfirmEditCasno"
			title="정말로 변경하시겠습니까?"
			:content="`선택된 ${selectedItemsId.length}개 물품의 CAS No가 '${editValue.casno}'로 변경됩니다.<br />변경된 정보로 인해 안전관리 내 일부 페이지에 영향을 미칠 수 있습니다.`"
			confirmText="완료"
			@confirm="updateMultipleConfirm"
		/>
		<ModalPrompt
			ref="ModalMultiEditCode"
			:title="`${selectedItemsId.length}개 물품 편집`"
			label="제품번호"
			@confirm="value => (changeEditValue(value, 'productCode'), $refs['ModalConfirmEditCode'].show())"
			confirmText="변경"
		/>
		<ModalConfirm
			ref="ModalConfirmEditCode"
			title="정말로 변경하시겠습니까?"
			:content="
				editValue.productCode
					? `선택된 ${selectedItemsId.length}개 물품의 제품번호가 ${editValue.productCode} 변경됩니다.<br />변경될 물품의 MSDS가 삭제되거나 새롭게 매칭될 수 있습니다.`
					: `입력된 값이 없습니다. 선택된 ${selectedItemsId.length}개 물품의 제품번호를 모두 지우겠습니까?<br />제품번호를 지울 경우 매칭된 MSDS가 삭제됩니다.`
			"
			confirmText="완료"
			@confirm="updateMultipleConfirm"
		/>
		<ModalDatepicker
			ref="ModalDatepicker"
			:title="`${selectedItemsId.length}개 물품 편집`"
			label="유효기간"
			@confirm="value => (changeEditValue(value, 'expireDatetime'), $refs['ModalConfirmEditExpireDatetime'].show())"
			confirmText="변경"
		/>
		<ModalConfirm
			ref="ModalConfirmEditExpireDatetime"
			title="정말로 변경하시겠습니까?"
			:content="
				editValue.expireDatetime
					? `선택된 ${selectedItemsId.length}개 물품의 유효기간이 ‘${editValue.expireDatetime}’(으)로 변경됩니다.`
					: `입력된 값이 없습니다. 선택된 ${selectedItemsId.length}개 물품의 유효기간을 모두 지우겠습니까?`
			"
			confirmText="완료"
			@confirm="updateMultipleConfirm"
		/>
		<ModalBrand
			ref="ModalBrand"
			:title="`${selectedItemsId.length}개 물품 편집`"
			label="브랜드"
			@confirm="value => (changeEditValue(value, 'brand'), $refs['ModalConfirmEditBrand'].show())"
			confirmText="변경"
		/>
		<ModalConfirm
			ref="ModalConfirmEditBrand"
			title="정말로 변경하시겠습니까?"
			:content="
				editValue.brand?.id || editValue.brand?.name
					? `선택된 ${selectedItemsId.length}개 물품의 브랜드가 ${editValue.brand?.name} 변경됩니다.<br />변경될 물품의 MSDS가 삭제되거나 새롭게 매칭될 수 있습니다.`
					: `입력된 값이 없습니다. 선택된 ${selectedItemsId.length}개 물품의 브랜드를 모두 지우겠습니까?<br />제품번호를 지울 경우 매칭된 MSDS가 삭제됩니다.`
			"
			confirmText="완료"
			@confirm="updateMultipleConfirm"
		/>
		<ModalSelectMember
			ref="ModalSelectMember"
			:title="`${selectedItemsId.length}개 물품 편집`"
			isSelectSingle
			confirmText="변경"
			@confirm="list => (changeEditValue(list[0], 'assignee'), $refs['ModalConfirmEditAssignee'].show())"
		/>
		<ModalConfirm
			ref="ModalConfirmEditAssignee"
			title="정말로 변경하시겠습니까?"
			:content="`선택된 ${selectedItemsId.length}개 물품의 담당자가 ‘${editValue.assignee?.name}’님으로 변경됩니다.`"
			confirmText="완료"
			@confirm="updateMultipleConfirm"
		/>
		<ModalLoading ref="ModalLoading" noMsg title="물품 편집 중입니다." />
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import ModalSingleSelectStorage from '@/views/common/components/modalGen2/ModalSingleSelectStorage.vue'
import ModalPrintSticker from './modal/ModalPrintSticker.vue'
import ModalTextarea from './modal/modalTextarea'
import ModalDatepicker from '@/views/common/components/modalGen2/ModalDatepicker.vue'
import ModalBrand from '@/views/common/components/modalGen2/ModalBrand.vue'
import ModalSelectMember from '@/views/common/components/modalGen2/ModalSelectMember.vue'
import ModalLoading from '@/views/common/components/modalGen2/ModalLoading.vue'
import { Classification } from '@/utils/define/ItemCode'
export default {
	components: {
		ModalSingleSelectStorage,
		ModalPrintSticker,
		ModalTextarea,
		ModalDatepicker,
		ModalBrand,
		ModalSelectMember,
		ModalLoading,
	},
	computed: {
		...mapGetters('inventoryGen2/list', ['selectedItemsId']),
		...mapState('inventoryGen2/list', ['selectedItems']),
		canUse() {
			return this.selectedItems.every(item => item.status === 'AVAILABLE')
		},
		canReturn() {
			return this.selectedItems.every(item => item.status === 'HOLDING_OWN')
		},
		canMove() {
			return this.selectedItems.every(item => item.status !== 'HOLDING')
		},
		canPrint() {
			return true
		},
		canDispose() {
			return this.selectedItems.every(item => item.status !== 'HOLDING')
		},
		canRemove() {
			return this.selectedItems.every(item => item.status !== 'HOLDING')
		},
		canEdit() {
			return true
		},
		canEditExpireDatetime() {
			return this.selectedItems.every(item => item.category.classification == Classification.CHEMICAL)
		},
	},
	data() {
		return {
			showEdit: false,
			editValue: {},
		}
	},
	methods: {
		...mapMutations('inventoryGen2/list', ['setSelectedItems']),
		...mapActions('inventoryGen2/list', ['useItem', 'returnItem', 'changeItemStorage', 'disposeItem', 'removeItem', 'updateMultiple']),
		async use() {
			try {
				await this.useItem(this.selectedItemsId)
				this.$root.toast(this.tt('물품 사용'), this.tt(`${this.selectedItemsId.length}개 물품을 사용 처리하였습니다.`), 'success')
				this.setSelectedItems([])
			} catch (err) {
				this.$root.toast(
					this.tt(`물품 사용 오류`),
					this.tt(err?.response?.data?.msg ?? '오류가 발생하였습니다. 고객센터에 문의 해주세요.'),
					'error'
				)
			}
		},
		async useComplete() {
			try {
				await this.returnItem(this.selectedItemsId)
				this.$root.toast(this.tt('물품 사용완료'), this.tt(`${this.selectedItemsId.length}개 물품을 사용완료 처리하였습니다.`), 'success')
				this.setSelectedItems([])
			} catch (err) {
				this.$root.toast(
					this.tt(`물품 사용 완료 오류`),
					this.tt(err?.response?.data?.msg ?? '오류가 발생하였습니다. 고객센터에 문의 해주세요.'),
					'error'
				)
			}
		},
		async changeStorage(selectStorage) {
			try {
				if (selectStorage === null) return
				await this.changeItemStorage({
					itemsId: this.selectedItemsId,
					storageId: selectStorage.id,
				})
				this.$root.toast('보관함 변경 완료', '보관함이 변경되었습니다', 'success')
				this.setSelectedItems([])
			} catch (err) {
				this.$root.toast(
					this.tt(`보관함 변경 오류`),
					this.tt(err?.response?.data?.msg ?? '오류가 발생하였습니다. 고객센터에 문의 해주세요.'),
					'error'
				)
			}
		},
		async dispose(memo) {
			try {
				await this.disposeItem({ itemsId: this.selectedItemsId, memo: memo })
				this.$root.toast('폐기 완료', `${this.selectedItemsId.length}개 물품이 폐기되었습니다`, 'success')
				this.setSelectedItems([])
			} catch (err) {
				this.$root.toast(
					this.tt(`폐기 오류`),
					this.tt(err?.response?.data?.msg ?? '오류가 발생하였습니다. 고객센터에 문의 해주세요.'),
					'error'
				)
			}
		},
		async remove(memo) {
			try {
				await this.removeItem({ itemsId: this.selectedItemsId, memo: memo })
				this.$root.toast('삭제 완료', `${this.selectedItemsId.length}개 물품이 삭제되었습니다`, 'success')
				this.setSelectedItems([])
			} catch (err) {
				this.$root.toast(
					this.tt(`삭제 오류`),
					this.tt(err?.response?.data?.msg ?? '오류가 발생하였습니다. 고객센터에 문의 해주세요.'),
					'error'
				)
			}
		},
		onShowEdit(e) {
			if (!this.canEdit) return
			this.showEdit = !this.showEdit
			this.editValue = {}
		},
		changeEditValue(value, key) {
			this.editValue = {}
			this.editValue[key] = value
		},
		async updateMultipleConfirm() {
			try {
				if (this.editValue.brand) {
					this.editValue.brandId = this.editValue.brand?.id
					this.editValue.brandName = this.editValue.brand?.name
				}
				if (this.editValue.assignee) this.editValue.assigneeId = this.editValue.assignee?.id
				const body = {}
				Object.keys(this.editValue).forEach(key => {
					body[key] = this.editValue[key] || null
				})
				if (body.assignee) delete body.assignee
				else if (body.brand) delete body.brand
				this.$refs['ModalLoading'].show()
				await this.updateMultiple({ ...body, itemIds: this.selectedItemsId })
				this.$root.toast('편집 완료', `${this.selectedItemsId.length}개 물품이 편집되었습니다`, 'success')
				this.showEdit = false
			} catch (err) {
				console.log(err)
				this.$root.toast(
					this.tt(`편집 오류`),
					this.tt(err?.response?.data?.msg ?? '오류가 발생하였습니다. 고객센터에 문의 해주세요.'),
					'error'
				)
			}
			this.$refs['ModalLoading'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
.btn-group {
	button.disabled {
		pointer-events: none;
		cursor: default !important;
	}
	button {
		padding: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 50px;
	}
}
.dropup {
	top: -205px;
	left: -50px;
	position: absolute;
	width: 160px;
	height: 190px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 1px 2px 16px 0px rgba(0, 0, 0, 0.1);
	border: 1px solid $INPUT;
	padding: 5px;
	& > div {
		height: 30px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		&:hover:not(.canEdit) {
			cursor: pointer;
			background-color: $GREY_4;
			border-radius: 5px;
		}
		&.canEdit {
			color: $PLACEHOLDER;
		}
	}
}
</style>
