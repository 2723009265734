<template>
	<b-modal centered scrollable @close="hide" size="xl" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('견적서로 추가') }}</div>
		</template>
		<div class="estimate-wrapper">
			<h2>{{ tt('견적함') }}</h2>
			<div class="search-wrapper">
				<h5 class="sub-title">
					{{ tt('상세검색') }}
					<span>{{ tt('조회할 항목을 검색하거나 선택할 수 있습니다') }}</span>
				</h5>
				<div class="table-wrapper">
					<div class="search-row">
						<div class="label">{{ tt('검색') }}</div>
						<div class="column-box">
							<search-box
								class="search-box"
								noRecent
								:placeholder="tt('견적번호, 상품정보로 검색')"
								@change="changeKeyword"
							></search-box>
						</div>
					</div>
					<div class="search-row">
						<div class="label">{{ tt('기간조회') }}</div>
						<div class="column-box">
							<date-picker ref="date-picker1" @changeDate="changeDate" class="date-picker" title="견적생성일" />
						</div>
					</div>
				</div>
			</div>
			<h5 class="sub-title">
				{{ tt('견적 목록') }} {{ count > 0 ? `(${count})` : '' }}
				<span class="canOrder">{{ tt('배송지에 따라 주문가능한 견적서만 선택가능합니다.') }}</span>
			</h5>
			<template v-if="count > 0">
				<table class="estimate-table">
					<table-header />
					<table-item v-for="item in list" :key="item.id" :item="item" />
				</table>
				<div class="pagination-wrapper">
					<pagination @page="getCurrentPage" :total="count" :isPerPage="false"></pagination>
				</div>
			</template>
			<div class="empty-table" v-else>
				<img class="empty-img" src="@/assets/svg/empty/estimate-empty-data.svg" />
				<p class="sub">{{ tt('작성하거나, 전달 받은 견적이 없습니다.') }}</p>
			</div>
		</div>
		<template #modal-footer>
			<div class="footer-left"></div>
			<div class="footer-right">
				<base-button @click="hide">{{ tt('닫기') }}</base-button>
				<base-button color="primary" @click="addProduct" :disabled="selectedEstimateId == null">{{ tt('추가하기') }}</base-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import SearchBox from '@/views/components/searchBox'
import DatePicker from '@/views/common/components/DatePicker.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import TableItem from './components/estimate/TableItem.vue'
import TableHeader from './components/estimate/TableHeader.vue'
import { mapActions, mapState } from 'vuex'
export default {
	components: {
		SearchBox,
		DatePicker,
		Pagination,
		TableItem,
		TableHeader,
	},
	computed: {
		...mapState('draft/estimate', ['list', 'count', 'selectedEstimateId']),
	},
	methods: {
		...mapActions('draft/estimate', ['setLoadParams', 'getDetail']),
		show() {
			this.$refs['modal'].show()
			this.setLoadParams({ keyword: null, startDatetime: null, endDatetime: null, offset: 0, length: 10 })
		},
		hide() {
			this.$refs['modal'].hide()
		},
		changeKeyword(keyword) {
			this.setLoadParams({ keyword })
		},
		changeDate(range) {
			const startDate = range ? range[0] : null
			const endDate = range[1] ? range[1] : null
			this.setLoadParams({ startDatetime: startDate, endDatetime: endDate })
		},
		getCurrentPage(page) {
			this.setLoadParams(page)
		},
		async addProduct() {
			const estimate = await this.getDetail()
			this.$emit('addProductByEstimate', estimate)
			this.hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-base.scss';
@import '@/styles/scss/table/_table-search.scss';
.estimate-wrapper {
	padding: 10px;
	h2 {
		font-size: 24px;
		color: 3111;
		border-bottom: 1px solid $DEFAULT;
		padding-bottom: 16px;
		margin-bottom: 16px;
	}
	.sub-title {
		display: flex;
		align-items: flex-end;
		margin-bottom: 16px;
		font-size: 16px;
		font-weight: 700;
		color: $DEFAULT;
		span {
			font-size: 14px;
			font-weight: 400;
			color: $SECONDARY_1;
			margin-left: 8px;
			&.canOrder {
				font-size: 12px;
				margin-left: auto;
			}
		}
	}
	.table-wrapper {
		margin-bottom: 50px;
	}
	.estimate-table {
		width: 100%;
	}
	.search-wrapper {
		margin: 0px;
	}
	.pagination-wrapper {
		margin-top: 20px;
		margin-bottom: -20px;
		.Table__pagination {
			border-top: 0px;
		}
	}
	.empty-table {
		border-top: 1px solid $DIVIDER;
		height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.bold {
			color: $SECONDARY_2;
			font-weight: 700;
			font-size: 16px;
			margin-bottom: 6px;
		}
		.sub {
			font-weight: 400;
			font-size: 14px;
			color: $PLACEHOLDER;
		}
	}
}
</style>
