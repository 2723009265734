<template>
	<div class="search-wrapper">
		<div class="table-wrapper">
			<div class="search-row">
				<div class="label">{{ tt('검색') }}</div>
				<div class="column-box">
					<search-box
						class="search-box"
						noRecent
						:placeholder="tt('예산번호, 기안 제목, 연구실으로 검색')"
						@change="changeKeyword"
					></search-box>
				</div>
			</div>
			<div class="search-row">
				<div class="label">{{ tt('기간조회') }}</div>
				<div class="column-box">
					<date-picker ref="date-picker1" @changeDate="r => changeDate(r, 'create')" class="date-picker" title="예산차감일" />
					<date-picker ref="date-picker2" @changeDate="r => changeDate(r, 'conclude')" class="date-picker" title="결재완료일" />
					<date-picker ref="date-picker3" @changeDate="r => changeDate(r, 'draft')" class="date-picker" title="기안상신일" />
				</div>
			</div>
			<div class="result-row" v-show="searchFilterList.length > 0">
				<div @click="resetSearch">
					<b>{{ tt('초기화') }}</b
					><img src="../svg/icon-reset.svg" />
					<div class="line"></div>
				</div>
				<div class="select-item-wrapper">
					<div class="selected-item" v-for="item in searchFilterList" :key="item.id">
						<div v-b-tooltip.hover="item.name">{{ item.name }}</div>
						<img src="../svg/icon-remove.svg" @click="removeSearchItem(item)" />
					</div>
				</div>
			</div>
		</div>
		<div class="table-wrapper">
			<div class="search-row" v-if="budgetList.length > 1">
				<div class="label">{{ tt('예산') }}</div>
				<div class="column-box">
					<label v-for="item in budgetList" :key="item.id" v-b-tooltip.hover="item.name">
						<input class="checkbox" type="checkbox" :checked="isChecekdItem(item.id, 'BUDGET')" @change="checkedItem(item, 'BUDGET')" />
						<span>{{ tt(item.name) }}</span>
					</label>
				</div>
			</div>
			<div class="search-row" v-if="groupList.length > 1">
				<div class="label">{{ tt('기안 연구실') }}</div>
				<div class="column-box">
					<label v-for="item in groupList" :key="item.id" v-b-tooltip.hover="item.name">
						<input class="checkbox" type="checkbox" :checked="isChecekdItem(item.id, 'GROUP')" @change="checkedItem(item, 'GROUP')" />
						<span>{{ tt(item.name) }}</span>
					</label>
				</div>
			</div>
			<div class="search-row" v-if="userList.length > 1">
				<div class="label">{{ tt('기안자') }}</div>
				<div class="column-box">
					<label v-for="item in userList" :key="item.id" v-b-tooltip.hover="item.name">
						<input class="checkbox" type="checkbox" :checked="isChecekdItem(item.id, 'USER')" @change="checkedItem(item, 'USER')" />
						<span>{{ tt(item.name) }}</span>
					</label>
				</div>
			</div>
			<div class="result-row" v-show="checkedList.length > 0">
				<div @click="reset">
					<b>{{ tt('전체해제') }}</b
					><img src="../svg/icon-reset.svg" />
					<div class="line"></div>
				</div>
				<div class="select-item-wrapper">
					<div class="selected-item" v-for="item in checkedList" :key="item.id">
						<div v-b-tooltip.hover="item.name">{{ tt(item.name) }}</div>
						<img src="../svg/icon-remove.svg" @click="removeItem(item)" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SearchBox from '@/views/components/searchBox'
import DatePicker from '@/views/common/components/DatePicker.vue'
import { mapGetters, mapActions } from 'vuex'
import MixinSearch from '@/views/budget/mixin/search'
export default {
	props: {
		budgetId: {
			type: [String, Number],
			default: null,
		},
		year: {
			type: Number,
		},
	},
	mixins: [MixinSearch],
	components: {
		SearchBox,
		DatePicker,
	},
	computed: {
		...mapGetters('budget/expenditure', ['groups', 'users', 'budgets']),
	},
	data() {
		return {
			searchFilterList: [],
		}
	},
	watch: {
		async checkedList() {
			await this.setFiltered(this.checkedList)
		},
	},
	async created() {
		await this.getFilter({ budgetId: this.budgetId, year: this.year })
	},
	methods: {
		...mapActions('budget/expenditure', ['getFilter', 'setLoadParams', 'setFiltered']),
		async changeKeyword(keyword) {
			// this.$emit('changeKeyword', keyword)
			await this.setLoadParams({ keyword })
		},
		async changeDate(range, type) {
			console.log(type)
			const startDate = range ? range[0] : null
			const endDate = range[1] ? range[1] : null
			let filterValue = {
				name: '',
				id: type,
			}
			let rangeText = `${startDate}~${endDate}`
			if (type == 'create') {
				await this.setLoadParams({ startCreateDatetime: startDate, endCreateDatetime: endDate })
				filterValue.name = this.tt('예산차감일') + `: ${rangeText}`
			} else if (type == 'conclude') {
				await this.setLoadParams({ startDraftConcludeDatetime: startDate, endDraftConcludeDatetime: endDate })
				filterValue.name = this.tt('결재완료일') + `: ${rangeText}`
			} else if (type == 'draft') {
				await this.setLoadParams({ startDraftCreateDatetime: startDate, endDraftCreateDatetime: endDate })
				filterValue.name = this.tt('기안상신일') + `: ${rangeText}`
			}
			if (this.searchFilterList.find(it => it.id == type)) this.searchFilterList.remove(type, 'id')
			if (startDate != null) this.searchFilterList.push(filterValue)
		},
		removeSearchItem(item) {
			if (item.id == 'create') this.$refs['date-picker1'].value(null)
			else if (item.id == 'conclude') this.$refs['date-picker2'].value(null)
			else if (item.id == 'draft') this.$refs['date-picker3'].value(null)
		},
		resetSearch() {
			this.searchFilterList.forEach(s => this.removeSearchItem(s))
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-search.scss';
</style>
