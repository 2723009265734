<template>
	<tr class="table-header">
		<th class="header-checkbox" id="allCheckbox">
			<input type="checkbox" id="allCheck" class="checkbox" v-model="allCheck" :class="{ someChecked: !allCheck && !isEmptySelectedItems }" />
		</th>
		<th>
			<span>{{ tt('상태') }}</span>
		</th>
		<th class="header-checkbox">
			<span><input type="checkbox" class="favorite" v-model="isFavoriteAsc" /></span>
		</th>
		<th :class="orderClass('NAME')" @click.stop="changeOrder('NAME')">
			<span>{{ tt('정보') }}</span>
		</th>
		<th v-if="isView('categoryClassification')">
			<span>{{ tt('카테고리 분류') }}</span>
		</th>
		<th v-if="isView('categoryType')">
			<span>{{ tt('카테고리 상세 분류') }}</span>
		</th>
		<th v-if="isView('category')">
			<span>{{ tt('카테고리') }}</span>
		</th>
		<th v-if="isView('brand')">
			<span>{{ tt('브랜드') }}</span>
		</th>
		<th v-if="isView('mngcode')">
			<span>{{ tt('관리번호') }}</span>
		</th>
		<th v-if="isView('incompanyMngcode')">
			<!-- 사내관리번호 -->
			<span>{{ tt('사내관리번호') }}</span>
		</th>
		<th :class="orderClass('PRODUCT_CODE')" @click.stop="changeOrder('PRODUCT_CODE')" v-if="isView('productCode')">
			<span>{{ tt('제품번호') }}</span>
		</th>
		<th :class="orderClass('CASNO')" @click.stop="changeOrder('CASNO')" v-if="isView('casno') && isSelectedChemical">
			<span>{{ tt('CAS No') }}</span>
		</th>
		<th v-if="isView('lotno')">
			<!-- Lot No -->
			<span>{{ tt('Lot No') }}</span>
		</th>
		<th :class="orderClass('VOLUME')" @click.stop="changeOrder('VOLUME')" v-if="isView('volume') && isSelectedChemical">
			<span>{{ tt('물품 사이즈(용량)') }}</span>
		</th>
		<th :class="orderClass('CURRENT_VOLUME')" @click.stop="changeOrder('CURRENT_VOLUME')" v-if="isView('currentVolume') && isSelectedChemical">
			<span>{{ tt('잔여 용량') }}</span>
		</th>
		<th v-if="isView('etc_volume')">
			<span>{{ tt('패키지 수량') }}</span>
		</th>
		<th v-if="isView('etc_currentvolume')">
			<span>{{ tt('잔여 수량') }}</span>
		</th>
		<th :class="orderClass('STORAGE')" @click.stop="changeOrder('STORAGE')" v-if="isView('location')">
			<span>{{ tt('위치/보관함') }}</span>
		</th>
		<th v-if="isView('group')">
			<span>{{ tt('담당 그룹') }}</span>
		</th>
		<th v-if="isView('assignee')">
			<span>{{ tt('담당자') }}</span>
		</th>
		<th v-if="isView('msds') && isSelectedChemical">
			<!-- MSDS -->
			<span>{{ tt('MSDS') }}</span>
		</th>
		<th v-if="isView('expireDatetime') && isSelectedChemical">
			<span>{{ tt('유효기간') }}</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')" v-if="isView('createDatetime')">
			<span>{{ tt('등록일시') }}</span>
		</th>
		<th :class="orderClass('UPDATE_DATETIME')" @click.stop="changeOrder('UPDATE_DATETIME')" v-if="isView('updateDatetime')">
			<span>{{ tt('최근 변경일시') }}</span>
		</th>
		<b-tooltip ref="tooltip" target="allCheck" :show.sync="showTooltip" custom-class="tooltip-wrap">
			<b-overlay :show="isAllSelected" spinner-variant="primary" rounded="sm">
				<div style="display: flex; align-items: center" v-click-outside="onTooltipOutisde">
					{{ selectedText }}
					<button @click="allSelected">
						<p>{{ tt('${1}개의 모든 항목 선택', count.toLocaleString()) }}</p>
					</button>
					<img class="close" src="@/assets/svg/close.svg" @click="$refs.tooltip.$emit('close')" />
				</div>
			</b-overlay>
		</b-tooltip>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import MixinHeader from '@/mixins/table/header'
import { Classification } from '@/utils/define/ItemCode'
export default {
	data() {
		return {
			isAllSelected: false,
			showTooltip: false,
		}
	},
	watch: {
		allCheck(val) {
			if (val) {
				this.showTooltip = true
			} else {
				this.showTooltip = false
			}
		},
	},
	computed: {
		...mapState('inventoryGen2/list', ['list', 'count', 'selectedItems', 'checkedViewOption']),
		...mapGetters('inventoryGen2/list', ['order', 'orderDirection', 'isEmptySelectedItems', 'selectedCategory']),
		allCheck: {
			get() {
				return this.list.every(it => this.selectedItems.map(sit => sit.id).includes(it.id)) && this.list.length !== 0
			},
			set(val) {
				if (val) this.setSelectedItems(this.list)
				else this.setSelectedItems([])
			},
		},
		selectedText() {
			return this.selectedItems.length === this.count
				? this.tt('전체 항목 ${1}개가 선택되었습니다.', this.selectedItems.length)
				: this.tt('이 페이지에 있는 항목 ${1}개가 선택되었습니다.', this.selectedItems.length)
		},
		isSelectedChemical() {
			return this.selectedCategory == Classification.CHEMICAL || this.selectedCategory == 'ALL'
		},
		isFavoriteAsc: {
			get() {
				return this.order === 'FAVORITE'
			},
			set(value) {
				if (value) this.changeOrder('FAVORITE')
				else this.changeOrder('CREATE_DATETIME')
			},
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapMutations('inventoryGen2/list', ['setSelectedItems']),
		...mapActions('inventoryGen2/list', ['setLoadParams', 'getAllList']),
		async allSelected() {
			this.isAllSelected = true
			const response = await this.getAllList()
			this.setSelectedItems(response.list)
			this.isAllSelected = false
		},
		onTooltipOutisde(e) {
			console.log(e.target)
			this.$refs.tooltip.$emit('close')
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
::v-deep {
	.text-primary {
		color: $LAB_YELLOW !important;
		height: 20px;
		width: 20px;
	}
}
.tooltip-wrap {
	top: -15px !important;
	opacity: 1;
	::v-deep.tooltip-inner {
		max-width: unset;
		display: flex;
		box-shadow: 0px 2px 4px 0px #0000001a;
		border: 1px solid $FILE_BORDER;
		color: $DEFAULT;
		background-color: $LAB_WHITE;
		font-size: 14px;
		padding: 10px 20px;
		button {
			margin-left: 10px;
			p {
				text-decoration: underline;
				color: $BLUE;
			}
		}
		.close {
			margin-left: 10px;
			opacity: 0.4;
			&:hover {
				opacity: 0.7;
				cursor: pointer;
			}
		}
	}
	::v-deep.arrow {
		color: $LAB_WHITE;
	}
	::v-deep.arrow::after {
		margin-left: 1px;
		position: absolute;
		content: '';
		border-color: transparent;
		border-style: solid;
		color: $LAB_WHITE;
		border-top-color: $LAB_WHITE;
		border-bottom-color: $LAB_WHITE;
		border-width: 0 9px 9px;
		top: -2px;
	}
	::v-deep.arrow::before {
		color: $LAB_WHITE;
		border-top-color: $FILE_BORDER;
		border-bottom-color: $FILE_BORDER;
		border-width: 0 10px 9px;
	}
}
::v-deep {
	.tooltip {
	}
}
</style>
