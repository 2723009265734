<template>
	<div class="page-wrapper">
		<fixed-header :title="title" :subTitle="subTitle" @changeLoadParams="changeLoadParams" />

		<div class="table-wrapper" :class="{ isInventoryList: isInventoryList }">
			<lm-modal-loading v-if="isLoading" ref="modal-loading" height="100%" :play="isLoading" backgroundColor="#FFFFFFCC"></lm-modal-loading>
			<template v-else>
				<inventory-table ref="inventory-table" :list="list" :previousPage="previousPage"></inventory-table>
				<floating-menu v-if="selectedItems.length > 0"></floating-menu>
			</template>
		</div>
		<pagination ref="pagination" :propsOffset="loadParams.offset" :total="count" @page="pagination"></pagination>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import Pagination from '@/views/common/components/Pagination.vue'
import FixedHeader from '../components/list/FixedHeader.vue'
import FloatingMenu from '../components/list/FloatingMenu.vue'
import InventoryTable from '../components/table'
import LmModalLoading from '@/views/common/components/LmModalLoading.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
	components: {
		Pagination,
		FixedHeader,
		FloatingMenu,
		InventoryTable,
		LmModalLoading,
	},
	computed: {
		...mapState('inventoryGen2/list', ['selectedBundleOption']),
		...mapState('inventoryGen2/list', ['filterCookieName', 'list', 'count', 'selectedItems', 'recentPageName', 'loadParams']),
		title() {
			return this.$route.params?.name || this.$route.params?.legalName || this.tt('물품목록')
		},
		subTitle() {
			return this.$route.params?.casno ? `CAS: ${this.$route.params?.casno}` : null
		},
		isInventoryList() {
			return this.$route.name == PageName.InventoryGen2.List
		},
	},
	data() {
		return {
			previousPage: null,
			isLoading: false,
		}
	},
	async mounted() {
		if (
			this.previousPage?.name !== null &&
			(this.$route.name !== this.recentPageName || this.previousPage?.name !== PageName.InventoryGen2.Detail)
		) {
			this.setSelectedItems([])
			this.initFilterCookies(true)
			this.initBundleParams(this.selectedBundleOption == 'none' ? null : this.selectedBundleOption)
			this.$refs['pagination'].init(1)
		} else {
			this.$refs['pagination'].changePage(this.loadParams.offset, this.loadParams.length)
		}
		await this.initList()
		if (this.selectedBundleOption != 'none') {
			await this.getBundleList()
		}
		this.setPageName(this.$route.name)
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
	methods: {
		...mapMutations({
			initBundleParams: 'inventoryGen2/bundle/initParams',
		}),
		...mapMutations('inventoryGen2/list', ['setSelectedItems', 'initFilterCookies', 'setPageName']),
		...mapActions('inventoryGen2/list', ['getList', 'setLoadParams']),
		...mapActions({
			getBundleList: 'inventoryGen2/bundle/getList',
		}),
		async pagination(page) {
			if (this.$refs['inventory-table']) this.$refs['inventory-table'].moveScrollTop()
			await this.setLoadParams(page)
		},
		async changeLoadParams(params) {
			this.isLoading = true
			await this.setLoadParams(params)
			if (this.selectedBundleOption != 'none') await this.getBundleList()
			this.isLoading = false
		},
		async initList() {
			let query = Object.assign({}, this.$route.query)
			let params = Object.assign({}, this.$route.params)
			let loadParams = {}
			if (query?.classification) {
				if (query?.classification != 'ALL') loadParams.categoriesClassification = [query.classification]
			}
			if (query?.categoriesId) {
				loadParams.categoriesId = query.categoriesId instanceof Array ? query.categoriesId : [query.categoriesId]
				this.$cookies.set(this.filterCookieName.categoriesId, JSON.stringify(loadParams.categoriesId))
			}
			if (query?.expireDate) {
				loadParams.expireDate = query.expireDate instanceof Array ? query.expireDate : [query.expireDate]
				this.$cookies.set(this.filterCookieName.expireDate, JSON.stringify(loadParams.expireDate))
			}
			if (query?.currentVolume) {
				loadParams.currentVolume = query.currentVolume instanceof Array ? query.currentVolume : [query.currentVolume]
				this.$cookies.set(this.filterCookieName.currentVolume, JSON.stringify(loadParams.currentVolume))
			}
			if (query?.threshold) {
				loadParams.threshold = query.threshold instanceof Array ? query.threshold : [query.threshold]
				this.$cookies.set(this.filterCookieName.threshold, JSON.stringify(loadParams.threshold))
			}
			if (query?.legalsId) {
				if (query?.legalsId == 'ALL') loadParams.legalsId = []
				else loadParams.legalsId = query.legalsId instanceof Array ? query.legalsId : [query.legalsId]
				this.$cookies.set(this.filterCookieName.legalsId, JSON.stringify(loadParams.legalsId))
			}
			if (query?.detailLegalsId) {
				loadParams.detailLegalsId = query.detailLegalsId instanceof Array ? query.detailLegalsId : [query.detailLegalsId]
			}
			if (query?.locationsId) {
				loadParams.locationsId = query.locationsId instanceof Array ? query.locationsId : [query.locationsId]
			}
			if (query?.storagesId) {
				loadParams.storagesId = query.storagesId instanceof Array ? query.storagesId : [query.storagesId]
				this.$cookies.set(this.filterCookieName.storagesId, JSON.stringify(loadParams.storagesId))
			}
			if (query?.brandsId) {
				loadParams.brandsId = query.brandsId instanceof Array ? query.brandsId : [query.brandsId]
				this.$cookies.set(this.filterCookieName.brandsId, JSON.stringify(loadParams.brandsId))
			}
			if (query?.hasMsds) {
				loadParams.hasMsds = query.hasMsds instanceof Array ? query.hasMsds : [query.hasMsds]
				this.$cookies.set(this.filterCookieName.hasMsds, JSON.stringify(loadParams.hasMsds))
			}
			if (params?.casno) {
				loadParams.bundle = { casno: params.casno }
			}
			if (params?.name) {
				loadParams.bundle = { ...(loadParams.bundle ?? {}), name: params.name }
			}
			if (params?.productCode) {
				loadParams.bundle = { ...(loadParams.bundle ?? {}), productCode: params.productCode }
			}
			if (query?.groupsId) {
				loadParams.groupsId = query.groupsId instanceof Array ? query.groupsId : [query.groupsId]
			}
			if (Object.keys(query).length > 0) this.$router.replace({ query: {} })
			this.isLoading = true
			await this.setLoadParams(loadParams)
			this.isLoading = false
		},
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 180px);
		&.isInventoryList {
			height: calc(100% - 215px);
		}
	}
}
</style>
