var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "no-close-on-backdrop": "",
      "centered": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.tt('QR code/Barcode로 물품 정보찾기')) + " ")];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": function click($event) {
              return _vm.$refs['modal-available-brand'].show();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/views/inventoryGen2/svg/detail/icon-question.svg")
          }
        }), _vm._v(_vm._s(_vm.tt('스캔 가능한 브랜드보기')) + " ")])]), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('닫기')) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_vm.type === 'product' ? _c('div', {
    staticClass: "product-description"
  }, [_c('p', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('시약병에 부착된 라벨의 QR코드 혹은 바코드를 스캔해주세요'))
    }
  }), _c('p', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('아래 예시 이미지를 참고해주세요'))
    }
  })]) : _c('div', {
    staticClass: "inventory-description"
  }, [_c('ul', [_c('li', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('시약병에 부착된 라벨의 QR코드 혹은 바코드를 스캔해주세요'))
    }
  }), _c('li', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('물품에 할당된 QR 스티커를 스캔해주세요'))
    }
  })])]), _vm.type === 'product' ? _c('img', {
    staticClass: "image",
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/example-qr-search-image.svg")
    }
  }) : _c('div', {
    staticClass: "image-wrap"
  }, [_c('img', {
    staticClass: "image",
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/ex-qr-search-inventory1.svg")
    }
  }), _c('img', {
    staticClass: "image",
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/ex-qr-search-inventory2.svg")
    }
  })]), _vm.isLoading ? _c('div', {
    staticClass: "loading-lottie"
  }, [_c('lm-modal-loading', {
    ref: "modal-loading",
    attrs: {
      "height": _vm.type === 'product' ? '500px' : '227px',
      "play": _vm.isLoading,
      "backgroundColor": "#FFFFFFCC"
    }
  }), _c('div', {
    staticClass: "loading-text"
  }, [_vm._v(_vm._s(_vm.tt('물품을 검색 중입니다.')))])], 1) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.qrValue,
      expression: "qrValue"
    }],
    ref: "scan",
    staticClass: "scan-input",
    attrs: {
      "autofocus": ""
    },
    domProps: {
      "value": _vm.qrValue
    },
    on: {
      "~blur": function blur($event) {
        return _vm.blur.apply(null, arguments);
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.inputQr.apply(null, arguments);
      },
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.qrValue = null;
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.qrValue = $event.target.value;
      }
    }
  })], _c('modal-available-brand', {
    ref: "modal-available-brand"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }