import MarginDiv from '@/views/common/components/style/MarginDiv'
import { APP_MODE_PRODUCTION, APP_MODE_EXPERIENCE, APP_MODE_DEV_EXPERIENCE, APP_MODE_DEVELOP } from '@/utils/define/Constant'
import { EOLType } from '@/utils/define/DefineEOLType'
import PageName from '@/utils/define/PageName'
import { mapActions, mapState } from 'vuex'
import { getLegacyServiceUrl } from '@/services/api/common'
import Cookies from 'js-cookie'

export default {
	components: { MarginDiv },
	data() {
		return {
			rightMenuOn: false,
			groupSelectorOn: false,
			dropdownOn: false,
			orgTreeDropdownOn: false,
			currentLabId: null,
			isNoticeOn: false,
			cardInput: false,
			isSearchRecentOn: false,
			eolType: EOLType.AVAILABLE,
			// NEW
			// DONE
			// AVAILABLE
			// NOPERM
			// ING
			// SELECT
			// REJECT
		}
	},
	created() {
		this.instituteId = Cookies.get('instituteId')
	},
	computed: {
		...mapState('lms', ['lmsInstituteId']),
		...mapState('userPermission', ['userPermission']),
		...mapState('user', ['institute']),
		...mapState('appEOL', ['eolState']),
		serviceUrl() {
			const debug = process.env.NODE_ENV !== 'production'
			return debug
				? location.hostname.includes('lab-manager-enterprise.com')
					? 'https://dev-app.lab-manager-enterprise.com'
					: 'http://localhost:8080'
				: 'https://app.lab-manager-enterprise.com'
		},
		appMode() {
			// 앱 모드 체크용
			return {
				APP_MODE_PRODUCTION: APP_MODE_PRODUCTION, // 실서버
				APP_MODE_DEVELOP: APP_MODE_DEVELOP, // 개발용
				APP_MODE_EXPERIENCE: APP_MODE_EXPERIENCE, // 둘러보기 실서버용
				APP_MODE_DEV_EXPERIENCE: APP_MODE_DEV_EXPERIENCE, // 둘러보기 개발용
			}
		},
		isAppModeExperience() {
			const appMode = process.env.VUE_APP_MODE
			// const appMode = this.appMode.APP_MODE_DEV_EXPERIENCE
			// const appMode = this.appMode.APP_MODE_DEV_EXPERIENCE
			return [this.appMode.APP_MODE_EXPERIENCE, this.appMode.APP_MODE_DEV_EXPERIENCE].includes(appMode)
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},

	methods: {
		...mapActions('lms', ['getLMSInstituteId']),
		initStateOnMenu() {
			this.rightMenuOn = false
			this.groupSelectorOn = false
			this.dropdownOn = false
			this.orgTreeDropdownOn = false
			this.isNotice = false
			this.isSearchRecentOn = false
		},
		routeMigrationData() {
			this.$router.push({
				name: PageName.Migration.Main,
			})
		},
		routeMigrationNoAdmin() {
			this.$router.push({
				name: PageName.Migration.NoAdmin,
			})
		},
		routeMigrationBeing() {
			this.$router.push({
				name: PageName.Migration.Being,
			})
		},
		routeLegacyLM() {
			window.open(getLegacyServiceUrl(), '_self')
		},
		routeCreateInstitution() {
			this.$router.push({
				name: PageName.creation.create,
			})
		},
		routeDashboard() {
			this.$router.replace({
				name: PageName.Dashboard,
				params: {
					instituteId: this.institute.id,
				},
			})
		},
		routeMigrationSelect() {
			this.$router.push({
				name: PageName.Migration.Select,
			})
		},
		formatNumberLocale(num) {
			if (typeof num === 'number') return num.toLocaleString()
			else return num
		},
		formatDatetime(isoDatetime, format = 'yyyy-MM-dd HH:mm:ss') {
			return this.$DateTime.fromISO(isoDatetime).toFormat(format)
		},
		formatDate(isoDatetime, format = 'yyyy-MM-dd') {
			return this.$DateTime.fromISO(isoDatetime).toFormat(format)
		},

		actionEol() {
			// this.eolType => this.eolState.status
			const status = this.eolState.status
			// const status = this.eolType
			switch (status) {
				case EOLType.NEW: // 최초 가입자
					// // Action : 기관 생성 페이지 진입
					this.routeCreateInstitution()
					break
				case EOLType.DONE: // 전환 완료
					// Action : 대시보드
					this.routeDashboard()
					break
				case EOLType.AVAILABLE: // 전환 대상자 - 관리자
					// Action : 데이터 이전하기 페이지 노출
					this.routeMigrationData()
					break
				case EOLType.NOPERM: // 전환 대상자 - 연구원
					// Action : 데이터 이전 권한 없음 페이지 노출
					this.routeMigrationNoAdmin()
					break
				case EOLType.SELECT: // 선택이전 - 미선택
					// Action : 선택 이전 대상 페이지 노출
					this.routeMigrationSelect()
					break
				case EOLType.ING: // 전환 중
					// Action : 데이터 이전 중 페이지 노출
					this.routeMigrationBeing()
					break
				case EOLType.REJECT: // 선택이전 - 거절
					// Action : 레거시로 자동 이동
					this.routeLegacyLM()
					break
			}
		},

		isRedirectByEolAction() {
			// TODO: 고쳐야함
			// this.eolType => this.eolState.status
			// const status = this.eolType
			const status = this.eolState.status
			// const status = "NEW"
			let isRoutePushed = false
			switch (status) {
				case EOLType.AVAILABLE: // 전환 대상자 - 관리자
				// Action : 데이터 이전하기 페이지 노출
				case EOLType.NOPERM: // 전환 대상자 - 연구원
				// Action : 데이터 이전 권한 없음 페이지 노출
				case EOLType.SELECT: // 선택이전 - 미선택
				// Action : 선택 이전 대상 페이지 노출
				case EOLType.ING: // 전환 중
				// Action : 데이터 이전 중 페이지 노출
				case EOLType.REJECT: // 선택이전 - 거절
					// Action : 레거시로 자동 이동
					isRoutePushed = true
					break
				case EOLType.NEW: // 최초 가입자
				case EOLType.DONE: // 전환 완료
					// this.routeCreateInstitution()
					// this.routeDashboard()
					isRoutePushed = false
					break
			}

			return isRoutePushed
		},
		replaceAllNotDigit(n) {
			let num = typeof n === 'number' ? String(n) : n
			return num?.replaceAll(/[^\d,\.]/g, '')
		},
		addNumberComma(numStr) {
			return Number(this.replaceAllNotDigit(String(numStr).replaceAll(',', ''))).toLocaleString()
		},
		goM10() {
			window.open('https://lab-manager.com/promotion/m10')
		},
	},
}
