import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	lookupDiagnosisList: (instId, offset, length, order, orderDirection, keyword, showOnlyOverdue) => {
		let parameter = `/institute/${instId}/safety/specialdiagnosis/list?offset=${offset}&length=${length}&showOnlyOverdue=${showOnlyOverdue}`
		if (order.length != 0) parameter += `&${ParamMaker.setArrayString('order', order)}`
		if (orderDirection.length != 0) parameter += `&${ParamMaker.setArrayString('orderDirection', orderDirection)}`
		if (keyword != undefined) parameter += `&keyword=${keyword}`
		return parameter
	},
	lookupDiagnosisInfo: (instId, userId, offset, length, startDatetime, endDatetime) => {
		let parameter = `/institute/${instId}/safety/specialdiagnosis?userId=${userId}`
		if ((startDatetime != undefined) & (endDatetime != undefined)) parameter += `&startDatetime=${startDatetime}&endDatetime=${endDatetime}`
		return parameter
	},
	lookupDiagnosisUsageInfo: (instId, userId, offset, length, startDatetime, endDatetime) => {
		let parameter = `/institute/${instId}/safety/specialdiagnosis/inventory/log?userId=${userId}&offset=${offset}&length=${length}`
		if ((startDatetime != undefined) & (endDatetime != undefined)) parameter += `&startDatetime=${startDatetime}&endDatetime=${endDatetime}`
		return parameter
	},
	lookupDiagnosisLogInfo: (instId, userId, offset, length, startDatetime, endDatetime) => {
		let parameter = `/institute/${instId}/safety/specialdiagnosis/log/list?userId=${userId}&offset=${offset}&length=${length}`
		if ((startDatetime != undefined) & (endDatetime != undefined)) parameter += `&startDatetime=${startDatetime}&endDatetime=${endDatetime}`
		return parameter
	},
	updateDiagnosisDate: instId => `/institute/${instId}/safety/specialdiagnosis/log`,
	deleteDiagnosis: (instId, ids) => `/institute/${instId}/safety/specialdiagnosis/log?${ParamMaker.setArrayString('ids', ids)}`,
	exportExcel: instId => `/institute/${instId}/safety//specialdiagnosis/list/export/excel`,
}

const diagnosisAPI = {
	lookupDiagnosisList: (instId, offset, length, order, orderDirection, keyword, showOnlyOverdue) =>
		axiosInstance.get(endPoint.lookupDiagnosisList(instId, offset, length, order, orderDirection, keyword, showOnlyOverdue)),
	lookupDiagnosisInfo: (instId, userId, offset, length, startDatetime, endDatetime) =>
		axiosInstance.get(endPoint.lookupDiagnosisInfo(instId, userId, offset, length, startDatetime, endDatetime)),
	lookupDiagnosisUsageInfo: (instId, userId, offset, length, startDatetime, endDatetime) =>
		axiosInstance.get(endPoint.lookupDiagnosisUsageInfo(instId, userId, offset, length, startDatetime, endDatetime)),
	lookupDiagnosisLogInfo: (instId, userId, offset, length, startDatetime, endDatetime) =>
		axiosInstance.get(endPoint.lookupDiagnosisLogInfo(instId, userId, offset, length, startDatetime, endDatetime)),
	updateDiagnosisDate: (instId, body) => axiosInstance.post(endPoint.updateDiagnosisDate(instId), body),
	deleteDiagnosis: (instId, ids) => axiosInstance.delete(endPoint.deleteDiagnosis(instId, ids)),
	exportExcel: instId =>
		axiosInstance.post(
			endPoint.exportExcel(instId),
			{},
			{
				responseType: 'blob',
			}
		),
}

export default diagnosisAPI
