var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal-scan-qr-sticker",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('button', {
          staticClass: "button-gray",
          on: {
            "click": _vm.setInit
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('초기화')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": function click($event) {
              return _vm.$emit('save', _vm.validSelectedQrCodeList);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.saveButtonText) + " ")])])];
      },
      proxy: true
    }])
  }, [[_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.scannedCode,
      expression: "scannedCode"
    }],
    ref: "scan",
    staticStyle: {
      "opacity": "0",
      "position": "absolute",
      "left": "0px",
      "top": "0px",
      "ime-mode": "disabled",
      "-webkit-ime-mode": "disabled",
      "-moz-ime-mode": "disabled",
      "-ms-ime-mode": "disabled"
    },
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.scannedCode
    },
    on: {
      "blur": _vm.autoFocus,
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.scanCode.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.scannedCode = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "qr-counter",
    "class": {
      full: _vm.isRegistFull
    }
  }, [_c('div', {
    staticClass: "counter-text"
  }, [_vm._v(_vm._s(_vm.tt('할당된 QR/물품 수량')))]), _c('div', {
    staticClass: "counter",
    "class": {
      full: _vm.isRegistFull
    }
  }, [_vm._v(_vm._s("".concat(_vm.selectedLength, "/").concat(_vm.maxSize)))])]), _c('qr-scan-table', {
    attrs: {
      "itemList": _vm.qrcodeList,
      "maxSize": _vm.maxSize
    }
  }), _c('div', {
    staticClass: "btm-describe"
  }, [_vm._v(_vm._s(_vm.tt('스캐너를 이용해 물품에 할당할 QR 스티커를 스캔해주세요')))])], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }