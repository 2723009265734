var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasRecent,
      expression: "hasRecent"
    }],
    staticClass: "recent-reason-wrap"
  }, [_c('div', {
    staticClass: "reason-header"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("최근 입력한 사유")]), _c('p', {
    staticClass: "delete-btn",
    on: {
      "click": _vm.removeAllRecent
    }
  }, [_vm._v("전체 삭제")])]), _c('div', _vm._l(_vm.recentList, function (text, idx) {
    return _c('li', {
      key: idx,
      staticClass: "reason-wrap",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$emit('clickRecent', text);
        }
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(text)
      }
    }), _c('img', {
      attrs: {
        "src": require("@/assets/svg/Close-1.svg"),
        "width": "24",
        "height": "24"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.removeRecent(text);
        }
      }
    })]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }