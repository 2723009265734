<template>
	<div class="page-wrapper">
		<fixed-header :title="tt('예산 목록')" @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<budget-table :list="list" />
		</div>
		<pagination :total="count" @page="pagination"></pagination>
	</div>
</template>
<script>
import Pagination from '@/views/common/components/Pagination.vue'
import FixedHeader from '../components/list/FixedHeader.vue'
import BudgetTable from '../components/table'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
	components: {
		FixedHeader,
		BudgetTable,
		Pagination,
	},
	computed: {
		...mapState('budget/list', ['list', 'count']),
		...mapGetters('budget/list', ['keyword', 'selectedYear']),
	},
	created() {
		console.log(this.$router.history)
		this.setLoadParams({ keyword: '' })
	},
	methods: {
		...mapActions('budget/list', ['getList', 'setLoadParams']),
		pagination(page) {
			this.setLoadParams(page)
		},
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 215px);
	}
}
</style>
