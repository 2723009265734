var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card lawMaterial",
    "class": {
      displayOff: !_vm.displayCard
    }
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._m(0), _c('h4', {
    staticClass: "Btn__hover-balloon-w",
    on: {
      "click": _vm.goPage
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('법령별 화학물질 현황')) + " "), _c('div', {
    staticClass: "balloon"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('그룹별 법령물품으로 이동합니다')))])]), _vm._m(1)]), _vm.setting == true ? _c('div', {
    staticClass: "ml-auto switch-setting"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('대시보드에 노출')))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.displayCard,
      expression: "displayCard"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.displayCard) ? _vm._i(_vm.displayCard, null) > -1 : _vm.displayCard
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.displayCard,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.displayCard = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.displayCard = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.displayCard = $$c;
        }
      }, function ($event) {
        return _vm.toggleCard(_vm.id, _vm.displayCard);
      }]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])]) : _vm._e()]), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "card-box"
    }, [_c('div', {
      staticClass: "box-title",
      on: {
        "click": function click($event) {
          return _vm.goInventory(item.legalId);
        }
      }
    }, [_c('h5', [_vm._v(_vm._s(_vm.tt(item.legalName)))]), _c('h4', {
      staticClass: "ml-auto"
    }, [_vm._v(_vm._s(item.kindCount) + _vm._s(_vm.tt('종')) + " (" + _vm._s(item.count) + _vm._s(_vm.tt('개')) + ")")])]), _c('div', {
      staticClass: "box-content"
    }, [_c('ul', _vm._l(item.childLegalList, function (subitem) {
      return _c('li', {
        key: subitem.legalId,
        on: {
          "click": function click($event) {
            $event.stopPropagation();
            return _vm.goInventory(subitem.legalId);
          }
        }
      }, [_c('p', [_vm._v(_vm._s(_vm.tt(subitem.legalName)))]), _c('h5', [_vm._v(_vm._s(subitem.kindCount) + _vm._s(_vm.tt('종')) + " (" + _vm._s(subitem.count) + _vm._s(_vm.tt('개')) + ")")])]);
    }), 0)])]);
  })], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/dashboard-icon7.svg"),
      "alt": "icon"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow-diagonal.svg"),
      "alt": "icon"
    }
  })]);
}]

export { render, staticRenderFns }