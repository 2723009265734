<template>
	<div class="pageHeader">
		<button @click="goPrev">{{ tt('이전으로') }}</button>
		<h6>{{ tt(title) }}</h6>
	</div>
</template>

<script>
export default {
	name: 'pageHeader',
	props: {
		title: {
			type: String,
			default: '',
		},
		isStart: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		goPrev() {
			if (this.isStart) {
				this.$emit('goStartMember')
			} else {
				this.$router.go(-1)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.pageHeader {
	width: 100%;
	height: 58px;
	background-color: $LAB_WHITE;
	position: sticky;
	display: flex;
	align-items: center;
	button {
		font-size: 1rem;
		color: $DEFAULT;
		font-weight: 400;
		padding-left: 20px;
		background-image: url(~@/assets/svg/arrow-left_s.svg);
		background-repeat: no-repeat;
		background-position: center left 0;
		background-size: 20px;
	}
	h6 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 1rem;
		font-weight: 700;
	}
}
</style>
