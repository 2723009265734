<template>
	<div class="label-wrap">
		<div class="list-wrap">
			<p class="list-title">{{ tt('유해･위험 문구') }}</p>
			<p class="nodata" v-if="!isHCodeList">{{ tt('위험성 분류를 먼저 선택해 주세요') }}</p>
			<div class="text-list">
				<div class="hazard-text-wrap" v-for="hCode in hCodeList" :key="hCode">
					<input type="checkbox" v-model="checkedItem" :value="hCode" :id="hCode" />
					<label class="hazard-text" :for="hCode">
						<div class="code">{{ hCode }}</div>
						<div class="text">{{ tt(hCodeText(hCode)) }}</div>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { hCode } from '@/utils/define/Gen2Ghs'
export default {
	name: 'TextList',
	components: {},
	props: {},
	data() {
		return {
			checkedItem: [],
		}
	},
	computed: {
		...mapGetters('modal/ghs', ['hCodeList']),
		hCodeText() {
			return code => hCode[code]?.text
		},
		isHCodeList() {
			return this.hCodeList?.length > 0
		},
	},
	watch: {
		checkedItem: {
			deep: true,
			handler() {
				this.setSelectedPrecautionCode(this.checkedItem)
				this.$emit('change', this.checkedItem)
			},
		},
		hCodeList: {
			deep: true,
			handler(value) {
				this.checkedItem = this.checkedItem.filter(item => value.includes(item))
			},
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('modal/ghs', ['setSelectedPrecautionCode']),
		setData(checkedItem) {
			if (checkedItem) {
				this.checkedItem = [...checkedItem]
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.label-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
	.list-wrap {
		.list-title {
			font-weight: 700;
			font-size: 12px;
			margin-bottom: 10px !important;
		}
		.text-list {
			display: flex;
			flex-direction: column;
			gap: 8px;
			flex-wrap: wrap;
			.hazard-text-wrap {
				cursor: pointer;
				padding-left: 2px;
				display: flex;
				align-items: flex-start;
				input {
					margin-top: 3px;
				}
				.hazard-text {
					display: flex;
					cursor: pointer;
					margin-left: 14px;
					.code {
						font-size: 14px;
						font-weight: 700;
					}
					.text {
						margin-left: 10px;
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}
		.nodata {
			font-weight: 400;
			font-size: 12px;
			color: #00000099;
		}
	}
}
</style>
