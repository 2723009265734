const LocationAuthority = {
	namespaced: true,
	state: () => ({
		_location: {
			list: undefined,
			add: undefined,
			delete: undefined,
		},
	}),
	getters: {},
	mutations: {},
	actions: {},
}
export default LocationAuthority
