<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('물품관리 설정') }}</div>
		</template>
		<template>
			<management-tab :tabList="tabList" :selectTab="selectTab" @change="changeTab"></management-tab>
			<div class="management-wrap">
				<component :is="pageName"></component>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<base-button @click="hide">{{ tt('닫기') }}</base-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ManagementTab from '../components/setting/ManagementTab.vue'
import Category from './category.vue'
import Number from './number.vue'

export default {
	components: {
		ManagementTab,
		Category,
		Number,
	},
	props: {},
	computed: {
		pageName() {
			return this.tabList[this.selectTab].id
		},
	},
	data() {
		return {
			selectTab: 0,
			tabList: [
				{
					id: 'Category',
					name: '카테고리',
				},
				{
					id: 'Number',
					name: '관리번호',
				},
			],
		}
	},
	methods: {
		show() {
			this.selectTab = 0
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		changeTab(index) {
			this.selectTab = index
		},
	},
}
</script>

<style lang="scss" scoped>
// @import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-store-search-product.scss';
.management-wrap {
	// padding: 15px 20px;
	width: 100%;
	border-bottom: 1px solid $DIVIDER;
}
</style>
