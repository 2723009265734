<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			{{ tt('라이선스 수 추가') }}
		</template>
		<template>
			<div class="license-select-wrapper">
				<div class="license-summary">
					<img class="imgbox" src="@/assets/images/lm-pro-icon.png" />
					<div class="txtbox">
						<h6>{{ licenseTypeText }}</h6>
						<p v-if="isFreeTier">
							{{ tt('30일 무료') }}
						</p>
						<p v-else-if="isTrial">
							{{ tt('무료') }}
						</p>
						<p v-else-if="isEducation">
							{{ tt('${1}원 / 연구실당 (연 기준 ・ VAT 별도)', formatNumberLocale(licenseRatePlan['YEAR']['EDUCATION'])) }}
						</p>
						<p v-else>
							{{ tt('${1}원 / 라이선스 당(월 기준 ・ VAT 별도)', formatNumberLocale(getAdditonalPrice)) }}
						</p>
					</div>
				</div>
				<div>
					<p>{{ tt('현재') }}</p>
					<div class="flex">
						<p>{{ tt('멤버 수 / 라이선스') }}</p>
						<span class="license-member">
							<span
								class="license-now-member"
								:class="{
									red: instInfo.allMemberCount >= this.license.quantity,
								}"
							>
								{{ formatNumberLocale(instInfo.allMemberCount) }}&nbsp;
							</span>
							<span class="license-quantity">
								{{
									!(isTrial || isFreeTier || !isLicenseActivate)
										? ' / ' + (license.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(license.quantity))
										: ''
								}}
							</span>
						</span>
					</div>
				</div>
				<div>
					<p>{{ tt('추가할 라이선스') }}</p>
					<div
						class="num-box"
						:class="{
							warning: isLowerQuantityThanMember,
						}"
					>
						<button :disabled="quantity < 2 || isLowerQuantityThanMember" @click="reduceCount">
							<img src="@/assets/svg/input-minus.svg" />
						</button>
						<input
							class="license-count"
							v-model="quantity"
							type="number"
							min="1"
							pattern="[0-9]+"
							@onkeydown="preventInputE()"
							:class="{
								red: isLowerQuantityThanMember,
							}"
						/>
						<button @click="addCount">
							<img src="@/assets/svg/input-plus.svg" />
						</button>
					</div>
				</div>
				<div class="input-warning" v-if="isLowerQuantityThanMember">
					<img src="@/assets/svg/input-icon-warning.svg" />
					<p>
						{{ tt('라이선스 수는 멤버수 보다 적을 수 없습니다.') }}
					</p>
				</div>
				<div class="license-date">
					<ul>
						<li>
							<p>{{ tt('라이선스 적용기간') }}</p>
							<span>{{ licensePurchase.period }}</span>
						</li>
						<li>
							<p>{{ tt('라이선스 적용일수') }}</p>
							<span v-if="licensePurchase.chargedMonths">
								{{ licensePurchase.chargedMonths ? formatNumberLocale(licensePurchase.chargedMonths) + tt('개월') : '' }}
								{{ licensePurchase.chargedDays ? formatNumberLocale(licensePurchase.chargedDays) + tt('일') : '' }}
							</span>
							<span v-else>
								{{ licensePurchase.month ? formatNumberLocale(licensePurchase.month) + tt('개월') : '' }}
							</span>
						</li>
						<li>
							<p>
								<span class="bold">
									{{ tt('라이선스 당') }}
									{{ licensePurchase.chargedMonths ? formatNumberLocale(licensePurchase.chargedMonths) + tt('개월') : '' }}
									{{ licensePurchase.chargedDays ? formatNumberLocale(licensePurchase.chargedDays) + tt('일') : '' }}
								</span>
								<span>
									{{ tt('에 대한 비례 결제금액') }}
								</span>
							</p>
							<span> ￦{{ formatNumberLocale(parseInt(licensePurchase.unitPrice * 1.1)) + '(VAT 미포함)' }} </span>
						</li>
						<li>
							<p>
								<span class="bold">
									{{ tt('추가할 라이선스 수(${1})', quantity) }}
								</span>
								<span>
									{{ tt('의 총 결제 금액') }}
								</span>
							</p>
							<span> ￦{{ formatNumberLocale(licensePurchase.totalPrice) + '(VAT 포함)' }} </span>
						</li>
					</ul>
				</div>
				<div class="license-price">
					<ul>
						<li>
							<p>{{ tt('소계') }}</p>
							<span> ￦{{ formatNumberLocale(licensePurchase.price) }} </span>
						</li>
						<li>
							<p>VAT(10%)</p>
							<span> ￦{{ formatNumberLocale(licensePurchase.vat) }} </span>
						</li>
						<li>
							<h3>{{ tt('합계') }}</h3>
							<h2>￦{{ formatNumberLocale(licensePurchase.totalPrice) }}</h2>
						</li>
					</ul>
				</div>
				<div class="license-payment">
					<h5>{{ tt('결제수단') }}</h5>
					<div>
						<template>
							<label class="card-registered-label">
								<div class="input-div" @click="selectedPayment = paymentMethod.CARD_REGISTERED">
									<img src="@/assets/svg/radio-off.svg" v-if="selectedPayment != paymentMethod.CARD_REGISTERED" />
									<img src="@/assets/svg/radio-on.svg" v-else />
									{{ tt(hasDefaultCreditCard ? '등록된 카드로 결제' : '카드 등록 후 결제') }}
								</div>
								<div
									class="change-card"
									v-if="selectedPayment == paymentMethod.CARD_REGISTERED && hasDefaultCreditCard"
									@click="editCard"
								>
									<span>{{ tt('결제카드 변경') }}</span>
									<img src="@/assets/svg/icon-blue-right-arrow.svg" width="16" height="24" />
								</div>
							</label>
							<div class="card-box" v-if="hasDefaultCreditCard && selectedPayment == paymentMethod.CARD_REGISTERED">
								<div class="imgbox">
									<img src="@/assets/svg/card.svg" />
								</div>
								<div class="txtbox">
									<p>
										{{ defaultCreditCard.issuer + tt('카드') }}

										({{ defaultCreditCard.number }})
									</p>
								</div>
							</div>
						</template>
						<template v-if="!beingSubscription">
							<label @click="selectedPayment = paymentMethod.ELECTRONIC_BILL">
								<img src="@/assets/svg/radio-off.svg" v-if="selectedPayment != paymentMethod.ELECTRONIC_BILL" />
								<img src="@/assets/svg/radio-on.svg" v-else />
								{{ tt('전자세금계산서') }}
							</label>
						</template>
					</div>
				</div>
				<div class="license-agree">
					<div class="agreement-box">
						<label style="cursor: pointer">
							<input type="checkbox" v-model="agreement" />
							{{ tt('(필수) 이용약관을 확인하였으며, 구매 진행에 동의합니다.') }}
						</label>
						<a href="https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7" target="_blank">
							{{ tt('보기') }}
						</a>
					</div>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<button class="button-primary" :disabled="disableConfirmButton" @click="confirm">
				{{ tt('결제하기') }}
			</button>
		</template>
		<ModalCard ref="ModalCard" />
		<ModalEbill ref="ModalEbill" />
	</b-modal>
</template>
<script>
import ModalCard from './ModalCard.vue'
import ModalEbill from './ModalEbill.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
import LicenseSelector from '../components/LicenseSelector.vue'
export default {
	components: {
		LicenseSelector,
		ModalCard,
		ModalEbill,
	},
	mixins: [MixinLicense, MixinPayment],
	data() {
		return {
			quantity: 1,
			selectedPayment: 'CARD_REGISTERED',
			agreement: false,
		}
	},
	computed: {
		isLowerQuantityThanMember() {
			return this.quantity + this.license.quantity < this.memberCount
		},
		disableConfirmButton() {
			return !this.agreement || this.isLowerQuantityThanMember
		},
	},
	methods: {
		async show() {
			await this.initData()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async initData() {
			this.quantity = this.memberCount - this.license.quantity > 0 ? this.memberCount - this.license.quantity : 1
			this.selectedPayment = this.paymentMethod.CARD_REGISTERED
			this.agreement = false

			await this.getAdditionQuantity({
				value: this.quantity,
			})
			await this.loadDefaultCreditCard()
		},
		async reduceCount() {
			this.quantity--
			if (this.quantity <= 0) this.quantity = 1
			await this.getAdditionQuantity({
				value: this.quantity,
			})
		},
		async addCount() {
			this.quantity++
			await this.getAdditionQuantity({
				value: this.quantity,
			})
		},
		confirm() {
			if (this.selectedPayment == this.paymentMethod.CARD_REGISTERED) {
				if (this.hasDefaultCreditCard) {
					this.addLicense({ cardId: this.defaultCreditCard.id })
				} else {
					// 카드 등록
					this.$refs['ModalCard'].show('REGISTER', cardId => this.addLicense({ cardId }))
				}
			} else {
				this.$refs['ModalEbill'].show(ebillId => this.addLicense({ ebillId }))
				// 전자세금 계산서
			}
		},
		editCard() {
			this.$refs['ModalCard'].show('EDIT', async cardId => {
				await this.loadDefaultCreditCard()
			})
		},
		async addLicense({ cardId, ebillId }) {
			try {
				// 카드로 베이직 구매
				await this.additionQuantity({
					cardId: cardId,
					ebillId: ebillId,
					value: this.quantity,
					paymentMethod: this.selectedPayment,
				})
				this.$emit('complete')
				this.hide()
			} catch (e) {
				console.log(e)
				this.$root.toast('결제 실패', e?.response?.data?.msg ?? '결제에 실패하였습니다. 결제정보를 다시 확인해주세요', 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-purchase.scss';
::v-deep {
	.modal-body {
		padding-top: 0px;
		.license-select-wrapper {
			margin-top: 0px;
			border-top: none;
		}
	}
}
</style>
