<template>
	<div class="summary-wrapper" v-if="summary">
		<div>
			<img src="../svg/icon-budget-plus.svg" />
			<div class="value plus">{{ tt('${1}원', budget) }}</div>
			<div class="label">{{ tt('총 예산 합계') }}</div>
		</div>
		<div>
			<img src="../svg/icon-budget-minus.svg" />
			<div class="value minus">{{ tt('${1}원', expenditure) }}</div>
			<div class="label">{{ tt('총 지출 합계') }}</div>
		</div>
		<div>
			<img src="../svg/icon-budget-sum.svg" />
			<div class="value sum">{{ tt('${1}원', balance) }}</div>
			<div class="label">{{ tt('총 잔액 합계') }}</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		summary: {
			type: Object,
		},
	},
	computed: {
		budget() {
			return this.summary?.budget?.toLocaleString() ?? 0
		},
		expenditure() {
			return this.summary?.expenditure?.toLocaleString() ?? 0
		},
		balance() {
			return this.summary?.balance?.toLocaleString() ?? 0
		},
	},
}
</script>
<style lang="scss" scoped>
.summary-wrapper {
	width: 100%;
	display: flex;
	gap: 20px;
	& > div {
		flex-basis: 33.3%;
		height: 120px;
		background-color: $GREY_0;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		img {
			margin-bottom: 2px;
		}
		.label {
			font-size: 14px;
			line-height: 20px;
			color: $DEFAULT;
		}
		.value {
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			&.plus {
				color: #00438e;
			}
			&.minus {
				color: $RED;
			}
			&.sum {
				color: #00a9e5;
			}
		}
	}
}
</style>
