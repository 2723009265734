<template>
	<form-msds :msdsInfo="cargoInfo.msds" :value="uploadMsds" @change="handleChange"></form-msds>
</template>
<script>
import FormMsds from '@/views/common/components/form/msds/FormMsds.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
	name: 'CargoFormMsds',
	components: {
		FormMsds,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapState('cargo/form', ['uploadMsds']),
		...mapGetters('cargo/form', ['cargoInfo']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('cargo/form', ['setMsds']),
		handleChange(value) {
			// value [Array]
			this.setMsds(value?.[0] ?? null)
		},
	},
}
</script>

<style lang="scss" scoped></style>
