var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('보관함 등록순서')))])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('p', [_vm._v(" 물품관리번호를 구성할 때, 각 보관함에 물품이 등록된 순서를 번호로 부여하는 방법을 이용할 수 있습니다. 보관함 등록순서는 아래와 같이 부여됩니다. ")]), _c('div', {
    staticClass: "yellow-box"
  }, [_c('div', [_c('div', {
    staticClass: "mark"
  }, [_vm._v("121")]), _c('div', [_vm._v("A")])]), _c('div', [_vm._v("_")]), _c('div', [_c('div', {
    staticClass: "mark"
  }, [_vm._v("24910")]), _c('div', [_vm._v("B")])])]), _c('p', [_c('b', [_vm._v("A:")]), _vm._v(" 첫번째 숫자는 각 보관함에 부여된 고유한 번호"), _c('br'), _c('b', [_vm._v("B:")]), _vm._v(" 두번째 숫자는 해당 보관함에 등록된 순서를 각각 의미합니다. ")]), _c('p', [_vm._v("따라서, 위의 보관함 등록순서는 121번 보관함에 24,910번째로 등록된 물품을 의미합니다.")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }