import userAPI from '@/services/api/purchase/lms/user'
import _ from 'lodash'

const User = {
	namespaced: true,
	state: {
		user: null,
	},
	getters: {
		userInfo: state => state.user,
	},
	mutations: {
		setUser: (state, payload) => {
			state.user = payload
		},
	},
	actions: {
		getUserInfo: async ({ rootGetters, commit }) => {
			const response = await userAPI.getUserInfo(rootGetters.instituteId)
			commit('setUser', response.data)
			return response
		},
		verification: async ({ rootGetters }, body) => {
			const response = await userAPI.verification(rootGetters.instituteId, body)
			return response.data
		},
	},
}

export default User
