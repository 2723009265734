<template>
	<div class="address-input-wrapper">
		<div class="address-search" v-if="hasPostcode">
			<number-input
				v-model="info.postcode"
				:error="!isValidPostcode"
				:inputStyle="{ width: 'calc(100% - 8px)' }"
				style="width: 100%"
				disabled
			/>
			<button class="btn btn-gray sm" @click="openAddressFinder">{{ tt('주소검색') }}</button>
		</div>
		<div class="address-search">
			<text-input
				style="width: 100%"
				v-model="info.address"
				:error="!isValidAddress"
				:placeholder="addressPlaceholder"
				:inputStyle="{ width: !hasPostcode ? 'calc(100% - 8px)' : '100%' }"
				disabled
			/>
			<button v-if="!hasPostcode" class="btn btn-gray sm" @click="openAddressFinder">{{ tt('주소검색') }}</button>
		</div>
		<text-input
			v-model="info.addressDetail"
			:placeholder="tt('상세주소를 입력해주세요')"
			:error="!isValidAddressDetail"
			:errorMessage="errorMessage"
			:disabled="!info.address"
			:inputStyle="{ width: '100%' }"
			@input="$emit('search', getAddress())"
		/>
	</div>
</template>

<script>
import BaseInput from '@/components/input/BaseInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import NumberInput from '@/components/input/NumberInput.vue'
import { addressFinderOpen } from '@/services/api/addressFinder'

export default {
	name: 'AddressInput',
	components: {
		TextInput,
		NumberInput,
	},
	extends: BaseInput,
	props: {
		hasPostcode: {
			type: Boolean,
			default: true,
		},
		postcode: {
			type: String,
			default: '',
		},
		address: {
			type: String,
			default: '',
		},
		addressDetail: {
			type: String,
			default: '',
		},
		addressPlaceholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			info: {
				postcode: '',
				address: '',
				addressDetail: '',
			},
			isValidPostcode: true,
			isValidAddress: true,
			isValidAddressDetail: true,
		}
	},
	created() {
		this.info.postcode = this.postcode
		this.info.address = this.address
		this.info.addressDetail = this.addressDetail
	},
	watch: {
		address() {
			this.info.address = this.address
		},
		addressDetail() {
			this.info.addressDetail = this.addressDetail
		},
		postcode() {
			this.info.postcode = this.postcode
		},
	},
	methods: {
		async openAddressFinder() {
			const result = await addressFinderOpen(window)
			this.info.postcode = result.zonecode
			this.info.address = result.roadAddress
			this.info.addressDetail = ''
			this.$emit('search', this.getAddress())
		},
		getAddress() {
			return {
				postcode: this.info.postcode,
				address: this.info.address,
				addressDetail: this.info.addressDetail,
			}
		},
		validate() {
			this.isValidPostcode = !!this.info.postcode
			this.isValidAddress = !!this.info.address
			this.isValidAddressDetail = !!this.info.addressDetail

			return this.isValidPostcode && this.isValidAddress && this.isValidAddressDetail
		},
	},
}
</script>

<style lang="scss" scoped>
.address-input-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 560px;
	.address-search {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.btn {
		width: 93px;
		height: 40px;
		white-space: nowrap;
	}
}
</style>
