import inventoryAPI from '@/services/api/inventory/gen2'
import cookies from 'vue-cookies'
import { historyViewOption } from '@/utils/define/ViewOption'
const state = {
	list: [],
	count: 0,
	loadParams: {
		offset: 0,
		length: 50,
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
		keyword: '',
		startDatetime: '',
		endDatetime: '',
		types: JSON.parse(cookies.get('filter_history_types')) || [],
		categoriesId: JSON.parse(cookies.get('filter_history_categoriesId')) || [],
		membersId: [],
		qrBarcode: '',
	},
	selectedItems: [],
	checkedViewOption: JSON.parse(cookies.get('viewOption_history')) ?? [],
	viewOptionCookieName: 'viewOption_history',
	viewOptionList: historyViewOption,
	filter: null,
	filterCookieName: {
		types: 'filter_history_types',
		categoriesId: 'filter_history_categoriesId',
	},
}

const getters = {
	filter: state => state.filter,
	filteredCount: state => state.loadParams.types.length + state.loadParams.categoriesId.length,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
	isEmptySelectedItems: state => state.selectedItems.length === 0,
	keyword: state => state.loadParams.keyword,
	offset: state => state.loadParams.offset,
	length: state => state.loadParams.length,
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			if (loadParams[key] == null) {
				delete state.loadParams[key]
			} else state.loadParams[key] = loadParams[key]
		})
	},
	setSelectedItems: (state, data) => {
		state.selectedItems = data
	},
	setViewOptions: (state, list) => {
		cookies.set(state.viewOptionCookieName, JSON.stringify(list))
		state.checkedViewOption = list
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	initFilterCookies: state => {
		Object.values(state.filterCookieName).forEach(key => {
			cookies.set(key, JSON.stringify([]))
		})
		state.loadParams = {
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			keyword: '',
			startDatetime: '',
			endDatetime: '',
			types: JSON.parse(cookies.get('filter_history_types')) || [],
			categoriesId: JSON.parse(cookies.get('filter_history_categoriesId')) || [],
			membersId: [],
			qrBarcode: '',
		}
	},
}

const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		const response = await inventoryAPI.history(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	getFilter: async ({ commit, rootGetters }) => {
		const response = await inventoryAPI.historyFilter(rootGetters.instituteId)
		commit('setFilter', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		await dispatch('getList')
	},
	removeItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.removeHistory(rootGetters.instituteId, body)
		dispatch('getList')
	},
	checkRestore: async ({ rootGetters }, body) => {
		const response = await inventoryAPI.checkRestore(rootGetters.instituteId, body)
		return response.data
	},
	restoreItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.restoreItem(rootGetters.instituteId, body)
		dispatch('getList')
	},
	changeItemStorage: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.changeStorage(rootGetters.instituteId, body)
		dispatch('getList')
	},
	exportExcel: async ({ state, rootGetters }, type) => {
		const response = await inventoryAPI.exportExcelHistory(rootGetters.instituteId, type == 'ALL' ? null : state.loadParams)
		return response
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
