<template>
	<tr class="select-table-header">
		<th width="50px"><input type="checkbox" v-model="allCheck" /></th>
		<th width="633px">
			<span>{{ tt('상품 정보') }}</span>
		</th>
		<th width="100px">
			<span>{{ tt('수량') }}</span>
		</th>
		<th width="120px">
			<span :class="{ red: isSale }">{{ tt('할인금액') }}</span>
		</th>
		<th width="135px">
			<span>{{ tt('상품금액') }}</span>
		</th>
		<th width="130px">
			<span>{{ tt('배송비') }}</span>
		</th>
	</tr>
</template>

<script>
import { mapState } from 'vuex'
export default {
	watch: {
		allCheck(val) {
			this.$emit('change', val)
		},
		selectedItems(val) {
			if (val.length === 0) this.allCheck = false
		},
	},
	data() {
		return {
			allCheck: false,
		}
	},
	computed: {
		...mapState('draft/item', ['draftInfo', 'selectedItems']),
		isSale() {
			return this.draftInfo.purchaseItems.some(it => it.discountPrice > 0)
		},
	},
}
</script>

<style lang="scss" scoped>
.red {
	color: $RED;
}
.select-table-header {
	background: $GREY_2;
	th:first-child {
		padding-left: 20px;
		text-align: left;
	}
	th {
		padding-top: 5px;
		padding-bottom: 5px;
		color: $SECONDARY_2;
		text-align: center;
	}
}
</style>
