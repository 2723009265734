<template>
	<div class="search-list-wrapper">
		<ul class="search-list" @scroll="onScroll" ref="scroll-container">
			<li class="search-item" @mousedown="clickItem(item)" v-for="item in searchList" :key="`searchIngredient-${Math.random()}-${item.name}`">
				<div class="item-content">
					<div class="name" v-html="className === 'name' ? setHighlight(item.name) : item.name"></div>
					<div class="casno" v-html="className === 'casno' ? setHighlight(item.casno) : item.casno"></div>
				</div>
			</li>
		</ul>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import StringUtils from '@/utils/modules/StringUtils'
import MixinScrolling from '@/mixins/scrolling'
export default {
	name: 'FormIngredientSearchList',
	components: {},
	mixins: [MixinScrolling],
	props: {
		className: {
			type: String,
			default: '',
		},
		keyword: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			highlightClass: 'lm-highlight',
			highlightTag: 'span',
		}
	},
	computed: {
		...mapGetters('ingredient', ['searchList', 'searchParams']),
		isScrollBottom() {
			return this.scrollDirection == 'DOWN' && this.lastScrollTop > 900
		},
	},
	watch: {
		isBottom(value) {
			if (value) {
				let { offset, length } = this.searchParams
				this.setParams({
					keyword: this.keyword,
					offset: offset + length,
					length: length,
				})
				this.addSearchList()
			}
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('ingredient', ['setParams']),
		...mapActions('ingredient', ['addSearchList']),
		setHighlight(data) {
			return this.keyword ? StringUtils.replaceHighlight(data, this.keyword, this.highlightTag, this.highlightClass) : data
		},
		clickItem(item) {
			this.$emit('clickItem', item)
		},
	},
}
</script>

<style lang="scss" scoped>
.search-list-wrapper {
	position: relative;
	ul.search-list {
		width: 450px;
		box-shadow: 0px 4px 2px 0px #0000001a;
		max-height: 300px;
		overflow-y: scroll;
		background: white;
		position: absolute;
		top: 0;
		z-index: 2000;
		border-radius: 4px;
		padding: 5px;
		li.search-item {
			padding: 8px 10px;
			border-radius: 4px;
			&:hover {
				cursor: pointer;
				background: $SUB-YELLOW_100;
			}
			.item-content {
				display: flex;
				flex-direction: column;
				.name {
					font-size: 14px;
					font-weight: 700;
					color: $DEFAULT;
				}
				.casno {
					font-size: 14px;
					font-weight: 400;
					color: $SECONDARY_2;
				}
			}
		}
	}
}

::v-deep .lm-highlight {
	background-color: $LAB_YELLOW !important;
}
</style>
