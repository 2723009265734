var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "LocationPage__page-container"
  }, [_c('div', {
    staticClass: "LocationStorage"
  }, [_c('inst-header', {
    attrs: {
      "title": _vm.tt('위치 및 보관함')
    }
  }), _c('div', {
    staticClass: "Location__content-container",
    "class": {
      experience: _vm.isAppModeExperience
    }
  }, [_c('div', {
    staticClass: "Location__content-left"
  }, [_c('div', {
    staticClass: "Location__chart-header",
    "class": {
      shadow: _vm.showBoxShadow
    }
  }, [_c('lm-input-search', {
    attrs: {
      "placeholder": _vm.tt('위치명으로 검색')
    },
    on: {
      "search": _vm.searchTree
    }
  }), _vm.userPermission.location && _vm.userPermission.location.create ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn bold",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showModalAddChildLocation('ADD_LOCATION');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-lisence-copy.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('생성')) + " ")]) : _vm._e(), _vm.userPermission.location && _vm.userPermission.location["delete"] ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn ml-10 bold",
    attrs: {
      "disabled": _vm.selectedLocation && !_vm.selectedLocation.info.parentId
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showModalDeleteLocation.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Trash.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('삭제')) + " ")]) : _vm._e()], 1), _c('div', {
    ref: "chart-main-container",
    staticClass: "Location__main-container",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('location-tree-view', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchTreeData,
      expression: "searchTreeData"
    }],
    ref: "location-tree-view",
    attrs: {
      "treeData": _vm.treeData,
      "textField": "name",
      "mode": "active",
      "selectWithChild": false,
      "keyword": _vm.keyword,
      "isStoragePage": true,
      "page": "location"
    },
    on: {
      "changeActive": _vm.changeActiveId,
      "changeTree": _vm.changeTree,
      "moveStorage": _vm.moveStorage,
      "withResult": _vm.withResult
    }
  }), !_vm.searchTreeData ? _c('no-data-list', {
    attrs: {
      "image": "search",
      "title": "검색 결과가 없습니다",
      "content": "단어의 철자가 정확한지 확인하세요"
    }
  }) : _vm._e()], 1)]), _vm.selectedLocation ? _c('div', {
    staticClass: "Location",
    attrs: {
      "id": "Location"
    }
  }, [_c('div', {
    staticClass: "AppContainer__location-title"
  }, [_c('h5', {
    staticClass: "AppContainer__location-name"
  }, [_vm._v(" " + _vm._s(_vm.selectedLocation.info.name) + " ")]), _vm.userPermission.location.update ? _c('img', {
    staticClass: "Location__button-arrow",
    attrs: {
      "src": require("@/assets/svg/Button_edit.svg")
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showModalAddChildLocation('EDIT_LOCATION');
      }
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "Location__group-box"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('사용 그룹')))])]), _c('button', {
    staticClass: "AppContainer__subtitle-btn btn",
    on: {
      "click": _vm.showModalAddUsingGroup
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/authority-setting.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('사용 그룹 관리')) + " ")])]), _vm.hasGroups ? _c('p', {
    staticClass: "Location__group-location"
  }, [_vm._v(" " + _vm._s(_vm.usingGroups) + " ")]) : _vm._e(), !_vm.hasGroups ? _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_group.svg"),
      "alt": "no-image"
    }
  }), _c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('${1}(을)를 사용 중인 그룹이 없습니다.', _vm.selectedLocation.info.name))
    }
  }), _c('p', {
    staticStyle: {
      "padding-top": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>[사용 그룹 관리]</b>버튼을 클릭하여 그룹을 추가해보세요'))
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "StorageBox__group"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('보관함')))])]), _vm.userPermission.storage.create ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn",
    on: {
      "click": function click($event) {
        return _vm.showModalEditStorage();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/add-storage.svg"),
      "alt": "추가icon"
    }
  }), _vm._v(_vm._s(_vm.tt('보관함 추가')) + " ")]) : _vm._e()]), _vm.hasStorages ? _c('ul', {
    staticClass: "StorageBox__list"
  }, _vm._l(_vm.selectedLocation.storages, function (storage) {
    return _c('storage-item', {
      key: "storage-".concat(storage.id),
      attrs: {
        "storageInfo": storage,
        "userPermission": _vm.userPermission
      },
      on: {
        "editStorage": _vm.showModalEditStorage,
        "deleteStorage": _vm.showModalDeleteStorage,
        "refresh": _vm.refreshData
      }
    });
  }), 1) : _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_storage.svg"),
      "alt": "no-image"
    }
  }), _c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('보관함이 없습니다.'))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>[보관함 추가]</b> 버튼을 클릭하여 보관함을 추가해보세요'))
    }
  })])])]) : _vm._e()])], 1), _c('modal-add-child-location', {
    ref: "modal-add-child-location",
    on: {
      "refresh": _vm.refreshData
    }
  }), _c('modal-delete-location', {
    ref: "modal-delete",
    on: {
      "refresh": _vm.refreshData
    }
  }), _c('modal-add-using-group', {
    ref: "modal-add-using-group",
    on: {
      "refresh": _vm.hideModalAddUsingGroup
    }
  }), _vm.selectedLocation ? _c('modal-add-storage-v-2', {
    ref: "modal-edit-storage",
    attrs: {
      "selectLocationInfo": _vm.selectedLocation.info,
      "locationId": _vm.selectedLocation.info.id
    },
    on: {
      "refresh": _vm.refreshData
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }