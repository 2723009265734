import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'

const CargoList = () => import('@/views/purchase/cargo/pages/CargoList.vue')
const CargoDetail = () => import('@/views/purchase/cargo/pages/CargoDetail.vue')

const routes = [
	{
		name: PageName.Purchase.CargoList,
		path: RoutePath.Purchase.CargoList,
		component: CargoList,
	},
	{
		name: PageName.Purchase.CargoDetail,
		path: RoutePath.Purchase.CargoDetail,
		component: CargoDetail,
	},
]

export default routes
