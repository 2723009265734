<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalPeriodSetting">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt(title) }}</h5>
					<button class="Popup__close-btn">
						<img src="@/assets/svg/popup-close.svg" alt="close" @click="close" />
					</button>
				</div>
				<div class="Popup__content Form__wrap">
					<template v-if="hasFormat">
						<div class="Popup__input-field">
							<p>{{ tt('서식 설정') }}</p>
						</div>
						<div class="Form__field form-select">
							<input
								type="text"
								:placeholder="tt('서식을 선택해주세요')"
								readonly
								@click="isFormat = !isFormat"
								:class="isFormat == true ? 'active' : ''"
								:value="format?.text"
							/>
							<ul class="Form__select-list" v-if="isFormat == true">
								<li v-for="item in formatList" :key="item.id" @click="selectFormat(item)">
									{{ item.text }}
								</li>
							</ul>
						</div>
					</template>
					<div class="Popup__input-field mt-8">
						<p>{{ tt('기간 설정') }}</p>
						<date-picker v-model="date" range placeholder="기간을 설정해주세요" @change="directDate" :lang="lang"></date-picker>
					</div>
					<div class="Form__field form-select mt-8">
						<input
							type="text"
							:placeholder="tt('기간을 선택해주세요')"
							readonly
							@click="isPeriod = !isPeriod"
							:class="isPeriod == true ? 'active' : ''"
							v-model="period"
						/>
						<ul class="Form__select-list" v-if="isPeriod == true">
							<li v-for="item in periodList" :key="item.id" @click="selectPeriod(item)">
								{{ item.text }}
							</li>
						</ul>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="complete">
							{{ tt('저장') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import DatePicker from 'vue2-datepicker'

import { mapState, mapActions } from 'vuex'
import MixinModal from '@/mixins/modal'
import MixinDate from '@/mixins/date'
export default {
	name: 'ModalPeriodSetting',
	mixins: [MixinModal, MixinDate],
	props: {
		title: {
			type: String,
			default: '',
		},
		hasFormat: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		DatePicker,
	},
	data() {
		return {
			date: null,
			isPeriod: false,
			period: null,
			periodList: [
				{ id: 1, text: this.tt('1주'), value: 'WEEK' },
				{ id: 2, text: this.tt('1개월'), value: '1MONTH' },
				{ id: 3, text: this.tt('6개월'), value: '6MONTH' },
				{ id: 4, text: this.tt('1년'), value: 'YEAR' },
				{ id: 5, text: this.tt('기간 전체'), value: null },
				{ id: 6, text: this.tt('직접입력'), value: 'DIRECT' },
			],
			isFormat: false,
			format: null,
			formatList: [
				{ id: 75, text: this.tt('별지 제 75호 서식 화학물지 제조, 수입, 사용, 판매 관리대장') },
				{ id: 76, text: this.tt('별지 제 76호 서식 화학물질 보관, 저장 관리대장') },
			],
			downloadType: '',
			startDate: null,
		}
	},
	watch: {},
	methods: {
		show(startDate, type) {
			this.isShow = true
			this.date = null
			this.period = null
			this.format = null
			this.downloadType = type
			if (startDate) {
				this.startDate = startDate
			}
		},
		close() {
			this.hide()
		},
		complete() {
			this.$emit('isHide', this.date, this.downloadType, this.format?.id)
		},
		selectPeriod(period) {
			let today = new Date()

			const year = today.getFullYear()
			const month = today.getMonth()
			const day = today.getDate()

			if (period.value == 'WEEK') {
				this.date = [new Date(year, month, day - 7), today]
			} else if (period.value == '1MONTH') {
				this.date = [new Date(year, month - 1, day), today]
			} else if (period.value == '6MONTH') {
				this.date = [new Date(year, month - 6, day), today]
			} else if (period.value == 'YEAR') {
				this.date = [new Date(year - 1, month, day), today]
			} else {
				this.date = [this.startDate ? new Date(this.startDate) : null, today]
			}

			this.period = period.text
			this.isPeriod = false
		},
		selectFormat(format) {
			this.format = format
			this.isFormat = false
		},
		directDate() {
			this.period = this.tt('직접입력')
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalPeriodSetting {
	.Popup__content {
		.mx-datepicker {
			width: 100%;
		}
	}

	.Form__wrap {
		.Form__field {
			&.form-select {
				width: 100%;
				display: block;
				input {
					&.active {
						border-color: #000;
					}
					padding-left: 10px;
				}
				.Form__select-list {
					top: 32px;
				}
			}
		}
	}
}
</style>
