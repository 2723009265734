<template>
	<table class="draft-table-wrap" ref="draft-table">
		<table-header />
		<table-item v-for="item in list" :key="item.id" :item="item" @click="goToDetail" />
		<div class="empty-table" v-if="count === 0">
			<img class="empty-img" src="@/assets/svg/empty/inventory-empty-data.svg" />
			<p class="bold">{{ tt('검색 결과가 없습니다') }}</p>
			<p class="sub">
				{{ tt('문서 번호, 기안 제목, 기안 연구실으로 검색할 수 있습니다.') }}
			</p>
		</div>
	</table>
</template>
<script>
import PageName from '@/utils/define/PageName'
import TableHeader from './header.vue'
import TableItem from './item.vue'
import { mapState, mapMutations } from 'vuex'
export default {
	components: {
		TableHeader,
		TableItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		previousPage: {
			type: Object,
			default: () => {},
		},
	},
	mounted() {
		if (this.previousPage?.name === PageName.Purchase.DraftDetail) {
			this.$refs['draft-table'].scrollTo(0, this.scrollHeight)
		}
	},
	computed: {
		...mapState('scroll', ['scrollHeight']),
		...mapState('draft/list', ['count']),
	},
	methods: {
		...mapMutations('scroll', ['setScrollHeight']),
		goToDetail(id) {
			const { scrollTop } = this.$refs['draft-table']
			this.setScrollHeight(scrollTop)
			this.$router.push({
				name: PageName.Purchase.DraftDetail,
				params: {
					id,
				},
			})
		},
		moveScrollTop() {
			this.setScrollHeight(0)
			this.$refs['cargo-table'].scrollTo(0, this.scrollHeight)
		},
	},
}
</script>
<style lang="scss" scoped>
.draft-table-wrap {
	border-top: 1px solid $DIVIDER;
	height: calc(100% - 220px);
	text-align: left;
	display: block;
	overflow: auto;
	white-space: nowrap;
}

.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: $SECONDARY_2;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
