var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('migration-header', {
    attrs: {
      "isMigration": true
    }
  }), _c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": require("@/assets/svg/icon-logo.svg")
    }
  }), _c('h3', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  }), _c('div', {
    staticClass: "subtext",
    domProps: {
      "innerHTML": _vm._s(_vm.subtext)
    }
  })]), _c('div', {
    staticClass: "button-wrapper"
  }, [_c('lm-confirm-button-middle', {
    attrs: {
      "text": _vm.migrationText
    },
    on: {
      "click": _vm.routeMigrationSelectLab
    }
  })], 1), _vm._m(0)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "show-more"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/migration/icon-question.svg")
    }
  }), _c('a', {
    attrs: {
      "href": "https://smartjack.notion.site/PRO-f1d67324bafd4ffa925f9990215c11e8?pvs=4",
      "target": "_blank"
    }
  }, [_vm._v(" 이전하는 방법을 먼저 확인해 보세요! ")])]);
}]

export { render, staticRenderFns }