var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-enforce-focus": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.purchaseLicense
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('라이선스 구매하기')) + " ")]), _c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.freetier
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('1개월 무료 체험하기')) + " ")]), _c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('닫기')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('h1', [_vm._v("라이선스 추가하기")]), _c('p', [_vm._v(" 라이선스 수 제한으로 멤버 추가, 초대가 불가합니다."), _c('br'), _vm._v(" 라이선스를 구매하거나, 인원 무제한 체험을 선택해보세요. ")]), _c('table', {
    staticClass: "table-license"
  }, [_c('tr', [_c('th', [_vm._v("구분")]), _c('th', [_vm._v("라이선스 구매")]), _c('th', [_vm._v("인원 무제한 체험")])]), _c('tr', [_c('th', [_vm._v("기능")]), _c('td', [_vm._v("모든 기능")]), _c('td', [_vm._v("모든 기능")])]), _c('tr', [_c('th', [_vm._v("계정 수")]), _c('td', [_vm._v("약정 계정 수")]), _c('td', [_vm._v("제한없음")])]), _c('tr', [_c('th', [_vm._v("기간")]), _c('td', [_vm._v("약정 기간")]), _c('td', {
    staticClass: "red"
  }, [_vm._v("1개월")])]), _c('tr', [_c('th', [_vm._v("Merck 등 인기 브랜드"), _c('br'), _vm._v("최대 20% 할인혜택")]), _c('td', [_vm._v("O")]), _c('td', {
    staticClass: "red"
  }, [_vm._v("X")])])]), _c('div', {
    staticClass: "grey-box"
  }, [_vm._v(" 멤버 추가 없이 1계정은 계속 무료사용이 가능합니다. "), _c('br'), _vm._v(" 계속 무료로 사용하시려면 아래의 ‘닫기’를 클릭해주세요. ")]), _c('ModalConfirm', {
    ref: "modal-freeTier",
    attrs: {
      "title": "1개월 인원 무제한 체험하기",
      "content": "1개월 무료체험 후에는 라이선스를 구매해야 사용할 수 있습니다. <br />인원 무제한 체험을 시작 하시겠습니까?",
      "confirmText": "무료 체험하기"
    },
    on: {
      "confirm": _vm.freetierConfirm
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }