<template>
	<b-modal ref="image-viewer" size="xl" centered hide-footer no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="viewer">
				<div class="image-background">
					<img class="image" :src="imageSrc(selectImg)" v-if="!isRawSvg" />
					<div class="image" ref="large-img" v-html="selectImg" v-else></div>
				</div>
			</div>
			<div class="img-slider">
				<div
					class="slider-content"
					:class="{ selected: isSelected(idx) }"
					v-for="(image, idx) in imageList"
					:key="idx"
					@click="setImageIdx(idx)"
				>
					<img class="slider-image" :src="imageSrc(image)" v-if="!isRawSvg" ref="image" />
					<div
						class="slider-image"
						:class="{ selected: isSelected(idx) }"
						@click="setImageIdx(idx)"
						v-html="image"
						v-else
						ref="image"
					></div>
					<div class="caption" v-if="isCaption">{{ captionList[idx] }}</div>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalMultiImageViewer',
	props: {
		title: {
			type: String,
			default: '',
		},
		imageList: {
			type: Array,
			default: null,
		},
		captionList: {
			type: Array,
			default: null,
		},
		isCaption: {
			type: Boolean,
			default: false,
		},
		isRawSvg: {
			type: Boolean,
			default: false,
		},
		isSingle: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShow: false,
			selectIdx: 0,
		}
	},
	components: {},
	computed: {
		isSelected() {
			return idx => idx === this.selectIdx
		},
		selectImg() {
			return this.imageList?.[this.selectIdx]
		},
	},
	methods: {
		show(idx) {
			this.$refs['image-viewer'].show()
			this.$nextTick(() => {
				if (this.isRawSvg) {
					this.resizeSvg()
				}
			})
			if (idx || idx === 0) {
				this.setImageIdx(idx)
			}
		},
		hide() {
			this.$refs['image-viewer'].hide()
		},
		setImageIdx(idx) {
			this.selectIdx = idx
		},
		resizeSvg() {
			this.$refs['image']?.forEach(el => {
				el.childNodes?.[1]?.classList.add('svg')
			})
			if (this.isSingle) {
				this.$refs['large-img'].childNodes?.[1]?.classList.add('large-svg')
			}
		},
		imageSrc(url) {
			return typeof url === 'object' ? window.URL.createObjectURL(url) : url
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.svg {
		width: 100px;
		height: 150px;
	}
	.large-svg {
		width: 500px !important;
		height: 500px !important;
	}
}

::v-deep .modal-header {
	padding: 20px;
	border: 0;
	.title {
		font-size: 18px;
		font-weight: 700;
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
	width: auto;
}

::v-deep .modal-dialog {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 800px;
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	gap: 20px;
	padding: 0px;
	width: 800px;
	height: 700px;
	overflow: hidden;
	border-top: 1px solid $DIVIDER;
	padding-bottom: 0px;
	.viewer {
		height: 500px;
		width: 100%;
		text-align: center;
		overflow-x: auto;
		background: $GREY_0;
		.image {
			max-height: 100%;
			max-width: 100%;
		}
		.image-background {
			min-width: 100%;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.img-slider {
		display: flex;
		width: 100%;
		gap: 8px;
		overflow-x: scroll;
		padding: 0 10px 5px 10px;
		height: 172px;
		.slider-content {
			align-items: center;
			// max-width: 300px;
			// min-width: 100px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			background: white;
			border-radius: 6px;
			cursor: pointer;
			padding: 1px;
			border: 3px solid transparent;
			&.selected {
				border: 3px solid $LAB_YELLOW;
				cursor: default;
			}
			.slider-image {
				width: auto;
				height: 100%;
				border-radius: 3px;
			}
		}
	}
}
</style>
