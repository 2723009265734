var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('물품정보')))]), _c('div', {
    staticClass: "field-wrap"
  }, [_vm.item ? _c('product-info-field', {
    attrs: {
      "item": _vm.item,
      "name": "카테고리",
      "value": _vm.item.category.name
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }