import axiosInstance from '@/services/axios'

const endPoint = {
	lookupLicense: instId => `/institute/${instId}/license`,
	lookupLicenseManagement: instId => `institute/${instId}/license/management`,
	lookupLicenseHistory: instId => `/institute/${instId}/license/history`,
	lookupLicenseRatePlan: instId => `/institute/${instId}/license/plan`,
	lookupLicenseBasic: instId => `/institute/${instId}/license/charge`,
	purchaseLicenseBasic: instId => `/institute/${instId}/license`,
	additionMonth: instId => `/institute/${instId}/license/addition/month`,
	getAdditionMonth: instId => `/institute/${instId}/license/charge/addition/month`,
	getAdditionQuantity: instId => `/institute/${instId}/license/charge/addition/quantity`,
	additionQuantity: instId => `/institute/${instId}/license/addition/quantity`,
	cancelWaitingLicense: instId => `/institute/${instId}/license/ebill/waiting`,
	subscriptBasicLicense: instId => `/institute/${instId}/license/subscription`,
	lookupLicenseInfo4Subscription: instId => `/institute/${instId}/license/charge/subscription`,
	updateLicenseSubscription: instId => `/institute/${instId}/license/subscription`,
	unsubscribeLicense: instId => `/institute/${instId}/license/subscription`,
	executePurchaseSubscribe: instId => `/institute/${instId}/license/basic/subscription/execute`,
	lookupAuthEdu: instId => `/institute/${instId}/license/cert/edu`,
	freetier: instituteId => `/institute/${instituteId}/license/freetier`,
	paymentDown: instId => `/institute/${instId}/payment/ci/download`,
}

const setConfig = params => {
	return { params: params }
}

const lookupLicense = instId => axiosInstance.get(endPoint.lookupLicense(instId))
const lookupLicenseManagement = instId => axiosInstance.get(endPoint.lookupLicenseManagement(instId))
const lookupLicenseHistory = instId => axiosInstance.get(endPoint.lookupLicenseHistory(instId))
const lookupLicenseRatePlan = (instId, params) => axiosInstance.get(endPoint.lookupLicenseRatePlan(instId), setConfig(params))
const lookupLicenseBasic = (instId, params) => axiosInstance.get(endPoint.lookupLicenseBasic(instId), setConfig(params))
const purchaseLicenseBasic = (instId, params) => axiosInstance.post(endPoint.purchaseLicenseBasic(instId), params)
const additionMonth = (instId, params) => axiosInstance.post(endPoint.additionMonth(instId), params)
const getAdditionQuantity = (instId, params) => axiosInstance.get(endPoint.getAdditionQuantity(instId), setConfig(params))
const getAdditionMonth = (instId, params) => axiosInstance.get(endPoint.getAdditionMonth(instId), setConfig(params))
const additionQuantity = (instId, params) => axiosInstance.post(endPoint.additionQuantity(instId), params)
const cancelWaitingLicense = instId => axiosInstance.delete(endPoint.cancelWaitingLicense(instId))
const subscriptBasicLicense = (instId, params) => axiosInstance.post(endPoint.subscriptBasicLicense(instId), params)
const lookupLicenseInfo4Subscription = (instId, params) => axiosInstance.get(endPoint.lookupLicenseInfo4Subscription(instId), setConfig(params))
const updateLicenseSubscription = (instId, params) => axiosInstance.put(endPoint.updateLicenseSubscription(instId), params)
const unsubscribeLicense = instId => axiosInstance.delete(endPoint.unsubscribeLicense(instId))
const executePurchaseSubscribe = (instId, body) => axiosInstance.post(endPoint.executePurchaseSubscribe(instId), body)
const lookupAuthEdu = instId => axiosInstance.get(endPoint.lookupAuthEdu(instId))
const requestFreetier = instituteId => axiosInstance.post(endPoint.freetier(instituteId))
const paymentDown = (instId, body) => axiosInstance.post(endPoint.paymentDown(instId), body)
export {
	lookupLicense,
	lookupLicenseManagement,
	lookupLicenseHistory,
	lookupLicenseRatePlan,
	lookupLicenseBasic,
	purchaseLicenseBasic,
	additionMonth,
	getAdditionQuantity,
	getAdditionMonth,
	cancelWaitingLicense,
	additionQuantity,
	subscriptBasicLicense,
	lookupLicenseInfo4Subscription,
	updateLicenseSubscription,
	unsubscribeLicense,
	executePurchaseSubscribe,
	lookupAuthEdu,
	requestFreetier,
	paymentDown,
}
