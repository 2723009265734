<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<input
			type="text"
			:placeholder="tt(placeholder)"
			v-model="brandValue.name"
			v-debounce:300="handleInput"
			@focus="searchBrand"
			@blur="active = false"
		/>
		<span class="error" v-if="error">{{ tt(placeholder) }}</span>
		<ul class="brand-list" :class="{ active: active }">
			<li class="brand-item" v-if="noData">{{ tt('결과가 없습니다.') }}</li>
			<template v-else>
				<li
					class="brand-item"
					v-for="brand in brandList"
					:key="brand.id"
					@click.stop="clickItem(brand)"
					v-html="replaceHighlight(brand.name)"
				></li>
			</template>
		</ul>
	</div>
</template>

<script>
import StringUtils from '@/utils/modules/StringUtils'
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'FormBrand',
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			default: () => {
				return {
					name: '',
					id: '',
				}
			},
		},
		placeholder: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			active: false,
			brandValue: {
				name: null,
			},
		}
	},
	computed: {
		...mapGetters('form/brand', ['brandList']),
		noData() {
			return this.brandList.length === 0
		},
		error() {
			return this.required && this.isShowError && !this.brandValue?.name
		},
	},
	watch: {
		'value.name': {
			handler(value) {
				if (this.brandValue.name !== this.value) {
					this.brandValue = this.$_.cloneDeep(this.value)
				}
			},
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (this.brandValue.id !== this.value.id || this.brandValue.name !== this.value.name) {
				this.brandValue = this.$_.cloneDeep(this.value)
			}
		})
	},
	methods: {
		...mapActions('form/brand', ['getBrandList']),
		changeValue(brand) {
			this.brandValue = this.$_.cloneDeep(brand)
			this.getBrandList(this.brandValue.name)
			this.$emit('input', brand)
		},
		handleInput(value) {
			this.brandValue.name = value
			this.getBrandList(this.brandValue.name)
			const newBrand = { name: value }
			this.$emit('input', newBrand)
		},
		clickItem(brand) {
			this.changeValue(brand)
			this.active = false
		},
		async searchBrand() {
			this.active = true
			this.getBrandList(this.brandValue.name)
		},
		replaceHighlight(text) {
			if (text) {
				return StringUtils.replaceHighlight(text, this.brandValue?.name, 'span', 'jw-highlight')
			}
			return text
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep span.jw-highlight {
	background: $LAB_YELLOW !important;
}
.brand-list {
	position: absolute;
	top: 56px;
	left: 0px;
	opacity: 0;
	visibility: hidden;
	width: 295px;
	position: absolute;
	box-shadow: 0px 4px 10px rgb(0 0 0 / 20%);
	background-color: white;
	transition: 0.5s all ease;
	padding: 5px;
	border-radius: 4px;
	overflow: hidden;
	overflow-y: scroll;
	max-height: 180px;
	z-index: 4;
	&.active {
		opacity: 1;
		visibility: visible;
	}
	.brand-item {
		width: 100%;
		padding: 5px 10px;
		font-size: 14px;
		font-weight: 400;
		cursor: pointer;
		&:hover {
			background: $SUB-YELLOW_100;
		}
	}
}
.form-input {
	position: relative;
	// width: 100%;
	// max-width: 300px;
	width: calc(50% - 9px);
	&.list {
		input[type='text'] {
			height: 36px;
			border: 1px solid $INPUT_BORDER;
			padding-left: 12px;
		}
	}
	&.error {
		span.error {
			display: block;
			font-size: 0.75rem;
			font-weight: 400;
			padding-left: 18px;
			background-image: url(~@/assets/svg/input-icon-warning.svg);
			background-repeat: no-repeat;
			background-size: 12px;
			background-position: center left 0;
			color: $RED;
			margin-top: 4px;
		}
		input[type='text'] {
			border-color: $RED;
		}
	}
	.name {
		font-size: 0.75rem;
		font-weight: 400;
		margin-bottom: 8px !important;
	}
	span.error {
		display: none;
	}
	input[type='text'] {
		width: 100%;
		border-radius: 5px;
		font-size: 0.875rem;
		font-weight: 400;
		padding-left: 10px;
		height: 30px;
		border: 1px solid $COLOR_CCC;
		&:read-only {
			background-color: $GREY_1;
			border: 1px solid $INPUT_BORDER;
			&:focus {
				border: 1px solid $INPUT_BORDER;
			}
		}
	}
	input::placeholder {
		color: $PLACEHOLDER;
	}
	b {
		position: absolute;
		top: 32px;
		right: 12px;
		font-size: 12px;
		color: $DEFAULT;
		font-weight: 400;
	}
}
</style>
