var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('modal-footer', {
          attrs: {
            "completeText": "완료",
            "cancelText": "취소"
          },
          on: {
            "cancel": _vm.cancel,
            "complete": _vm.complete
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "button-wrap"
  }, [_c('div', [_c('image-button', {
    attrs: {
      "text": '파일첨부',
      "image": require('@/views/inventoryGen2/components/svg/icon-upload.svg')
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['excel-table'].uploadFile();
      }
    }
  }), _c('image-button', {
    attrs: {
      "text": '양식파일 다운로드',
      "image": require('@/views/inventoryGen2/components/svg/icon-upload.svg')
    },
    on: {
      "click": _vm.fileDown
    }
  })], 1), _c('div', [_c('image-button', {
    attrs: {
      "text": '보관함 지정',
      "image": require('@/assets/svg/StorageAdd.svg'),
      "disabled": _vm.selectedItems.length === 0
    },
    on: {
      "click": _vm.showModalSelectStorage
    }
  }), _c('image-button', {
    attrs: {
      "text": '삭제',
      "image": require('@/assets/svg/Trash.svg'),
      "disabled": _vm.selectedItems.length === 0
    },
    on: {
      "click": _vm.onClickDelete
    }
  })], 1)]), _vm.isLoading ? _c('div', [_c('lm-modal-loading', {
    ref: "modal-loading",
    attrs: {
      "height": "100%",
      "play": _vm.isLoading,
      "backgroundColor": "#FFFFFFCC"
    }
  }), _c('div', {
    staticClass: "progress-wrap"
  }, [_c('b-progress', {
    attrs: {
      "value": _vm.progressCount,
      "max": _vm.excelListTotalCount
    }
  })], 1)], 1) : _c('div', {
    staticStyle: {
      "height": "calc(100% - 70px)"
    }
  }, [_vm.excelList.length > 0 ? _c('div', {
    staticClass: "valid-wrap",
    "class": {
      error: !_vm.isValidExcelList || _vm.excelListTotalCount > 500
    },
    domProps: {
      "innerHTML": _vm._s(_vm.validText)
    }
  }) : _vm._e(), _c('excel-table', {
    ref: "excel-table",
    attrs: {
      "list": _vm.excelList
    },
    on: {
      "select": _vm.showModalSelectStorage,
      "edit": _vm.showEditModal
    }
  })], 1)], _c('modal-item-product', {
    ref: "modal-item-product",
    attrs: {
      "title": "물품정보 수정"
    },
    on: {
      "complete": function complete($event) {
        return _vm.$refs['modal-item-management'].show();
      }
    }
  }), _c('modal-item-management', {
    ref: "modal-item-management",
    attrs: {
      "title": "관리 및 추가정보"
    },
    on: {
      "goPrev": function goPrev($event) {
        return _vm.$refs['modal-item-product'].show();
      },
      "complete": _vm.managementComplete
    }
  }), _c('modal-single-select-storage', {
    ref: "modal-select-storage",
    attrs: {
      "title": "보관함 선택"
    },
    on: {
      "complete": _vm.selectStorage
    }
  }), _c('modal-complete-regist', {
    ref: "modal-complete-regist",
    attrs: {
      "title": "물품 등록 완료!",
      "confirmBtnText": "더 등록하기"
    },
    on: {
      "confirm": _vm.registMore,
      "cancel": _vm.modalHide
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }