<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('물품 소분 하기') }}</div>
		</template>
		<template>
			<div class="body-wrap">
				<divide-menu
					@addDivide="addDivide"
					@selectStorage="
						$refs['storage-selector'].show()
						setSelectedNode(null)
					"
				></divide-menu>
				<div class="table-wrap">
					<divide-table-header></divide-table-header>
					<div class="table-body" v-if="noItem">
						<div class="no-item">
							<img width="69" height="80" src="@/views/inventoryGen2/svg/detail/divide-no-item-icon.svg" />
							<span class="nodata-msg">{{ tt('소분할 용량과 개수를 입력해주세요') }}</span>
							<div class="button-wrap">
								<button class="button-normal" @click="addDivide">
									<img src="@/views/inventoryGen2/svg/detail/icon-subdivide.svg" width="24" height="24" />
									<span>{{ tt('소분 용량 입력') }} </span>
								</button>
							</div>
						</div>
					</div>
					<divide-table-item
						v-for="(item, idx) in divideItems"
						:idx="idx"
						:item="item"
						:key="item.divideKeyNo"
						@removeItem="removeItem(idx)"
						@showManage="showManage(idx)"
					></divide-table-item>
				</div>
			</div>
			<modal-add-divide
				ref="modal-add-divide"
				:item="item"
				:divideItemsVolume="divideItemsVolume"
				@complete="
					data =>
						addDivideItem({
							detailItem: item,
							data: data,
						})
				"
			></modal-add-divide>
			<modal-single-select-storage ref="storage-selector" title="보관함 지정" @complete="selectStorage"></modal-single-select-storage>
			<modal-item-management
				title="관리 및 추가정보"
				ref="modal-item-management"
				forDivide
				@complete="completeManagement"
			></modal-item-management>
			<modal-textarea
				ref="modal-dispose"
				title="잔여 용량 없음"
				titleTextarea="폐기 사유"
				headerText="소분 후 잔여 잔여 용량 없습니다.<br/>기존 물품을 폐기하고, 소분을 완료하시겠습니까? "
				useMiddleBtn
				middleBtnText="폐기없이 소분 완료"
				confirmText="폐기하고 소분 완료"
				placeholder="폐기 사유를 입력해주세요(100자 제한)"
				variant="warning"
				type="DISPOSE"
				@clickMiddle="memo => modalDisposeDivide(false, memo)"
				@complete="memo => modalDisposeDivide(true, memo)"
			></modal-textarea>
		</template>
		<template #modal-footer>
			<modal-footer okText="소분하기" :isOkDisabled="noItem" @cancel="$refs['modal'].hide()" @ok="divide"></modal-footer>
		</template>
	</b-modal>
</template>
<script>
import ModalFooter from '@/views/common/components/modalGen2/components/footer/ModalFooter.vue'
import DivideTableHeader from '@/views/inventoryGen2/components/detail/modal/modalDivide/divideTableHeader.vue'
import ModalAddDivide from '@/views/inventoryGen2/components/detail/modal/modalAddDivide/index.vue'
import DivideTableItem from '@/views/inventoryGen2/components/detail/modal/modalDivide/divideTableItem.vue'
import ModalSingleSelectStorage from '@/views/common/components/modalGen2/ModalSingleSelectStorage.vue'
import ModalItemManagement from '@/views/inventoryGen2/components/regist/modal/ModalItemManagement.vue'
import DivideMenu from '@/views/inventoryGen2/components/detail/modal/modalDivide/divideMenu.vue'
import ModalTextarea from '@/views/inventoryGen2/components/detail/modal/modalTextarea/index.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ModalDivide',
	components: {
		ModalFooter,
		DivideTableHeader,
		ModalAddDivide,
		DivideTableItem,
		ModalSingleSelectStorage,
		ModalItemManagement,
		DivideMenu,
		ModalTextarea,
	},
	props: {},
	data() {
		return {
			list: [],
			divideKeyNo: 0,
			selectIdx: null,
		}
	},
	computed: {
		...mapGetters('inventoryGen2/item', ['item']),
		...mapGetters('inventoryGen2/divide', ['divideItems', 'divideItemsVolume', 'selectedItem', 'originDivideItems']),
		...mapGetters('tag', ['selectedTagObj']),
		...mapGetters('modal/addDivide', ['usedVolume']),
		...mapGetters('inventoryGen2/form', ['itemCustomField']),
		noItem() {
			return this.divideItems?.length <= 0
		},
		hasToDispose() {
			return this.usedVolume === this.item.currentVolume
		},
		itemCustomFields() {
			const data = {}
			this.divideItem[this.selectIdx]?.customField.forEach(item => {
				data[item.key] = item.value
			})
			return data
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/divide', [
			'setInitDivide',
			'setResetItem',
			'setDivideItems',
			'setDivideItemsByIdx',
			'setOriginDivideItems',
			'setIsDispose',
			'setDisposeMemo',
		]),
		...mapActions('inventoryGen2/item', ['getDetail']),
		...mapActions('inventoryGen2/divide', ['addDivideItem', 'changeSelectedItemStorage', 'removeSelectedItem', 'divideItem']),
		...mapMutations('tree/location', ['setSelectedNode']),
		...mapMutations('inventoryGen2/form', ['setCategoryInfo', 'setCategory', 'setItemCustomFields', 'setItemFormType', 'setItemDivide']),
		...mapActions('inventoryGen2/form', ['getCategoryInfo']),
		...mapMutations('form/image', ['initImage', 'setImageList']),
		...mapMutations('tag', ['initTag', 'setSelectedTag']),
		...mapActions('inventoryGen2/historyInfo', ['getList']),
		show() {
			this.$refs['modal'].show()
			this.setInitDivide()
		},
		hide() {
			this.$refs['modal'].hide()
			this.setDivideItems([])
			this.setOriginDivideItems([])
		},
		addDivide() {
			this.$refs['modal-add-divide'].show()
		},
		removeItem(idx) {
			this.setDivideItems(this.divideItems.filter((item, i) => i != idx))
		},
		async showManage(idx) {
			this.setItemFormType('DIVIDE')
			this.selectIdx = idx
			await this.setCategory(this.item.category)
			await this.getCategoryInfo()

			const item = this.divideItems[idx]
			// QR 코드 세팅을 가능케하기 위해 multiCreateCount 추가
			item.multiCreateCount = 1
			await this.setItemDivide(item)
			this.$refs['modal-item-management'].show()
			this.setImageList(item.images)
			// tag 정보 세팅
			this.setSelectedTag(item.tagList.map(tag => tag.id))
		},
		completeManagement(item) {
			// tag 정보 저장
			let newItem = this.$_.cloneDeep(item)
			if (newItem.customField?.length === 0) {
				newItem.customField = null
			}
			this.setDivideItemsByIdx({ item: newItem, idx: this.selectIdx })
			this.$root.toast('관리 및 추가정보 입력 완료', '관리 및 추가정보가 입력되었습니다', ToastType.SUCCESS)

			// Image, Tag vuex 초기화
			this.initTag()
			this.selectIdx = null
		},
		selectStorage(storage) {
			this.changeSelectedItemStorage(storage)
			this.$root.toast('보관함 지정 완료', '보관함이 지정었습니다', ToastType.SUCCESS)
		},
		async divide() {
			try {
				if (this.hasToDispose) {
					this.$refs['modal-dispose'].show()
				} else {
					await this.divideItem()
					await this.getDetail(this.$route.params.id)
					await this.getList()
					this.$root.toast('소분 완료', '물품 소분이 완료되었습니다', ToastType.SUCCESS)
					this.hide()
					this.initImage()
				}
			} catch (e) {
				this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
			}
		},
		async modalDisposeDivide(willDispose, memo) {
			try {
				if (willDispose) {
					this.setIsDispose(willDispose)
					this.setDisposeMemo(memo)
					await this.divideItem()
					this.$root.toast('소분: 폐기 완료', '잔여 잔여 용량 없는 물품이 폐기되엇습니다', ToastType.SUCCESS)
					this.$root.toast('소분 완료', '물품 소분이 완료되었습니다', ToastType.SUCCESS)
					this.initImage()
					this.$router.replace({
						name: PageName.InventoryGen2.List,
					})
				} else {
					await this.divideItem()
					await this.getDetail(this.$route.params.id)
					this.$root.toast('소분 완료', '물품 소분이 완료되었습니다', ToastType.SUCCESS)
					this.$refs['modal-dispose'].hide()
					this.hide()
					this.initImage()
				}
				await this.getList()
				this.setInitDivide()
			} catch (e) {
				this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-divide.scss';
.button-normal {
	border: 1px solid black;
}
</style>
