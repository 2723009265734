var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "size": "xl",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('구매관리 관련')) + " "), _c('span', {
          staticClass: "tip"
        }, [_vm._v("Tip")])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "content"
  }, [_vm.isOrder ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("[Tip 1] \uC0C1\uD488\uC8FC\uBB38 \uD654\uBA74\uC5D0\uC11C \uC0C1\uD488\uC744 \uC8FC\uBB38\uD558\uBA74,&nbsp;<span style='color:#faa400'>\uB7A9\uB9E4\uB2C8\uC800 \uC2A4\uD1A0\uC5B4\uB85C \uC811\uC218</span>\uB429\uB2C8\uB2E4."))
    }
  }) : _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("[Tip 1] \uB7A9\uB9E4\uB2C8\uC800 \uC2A4\uD1A0\uC5B4 \uC0C1\uD488\uC744 \uD3EC\uD568\uD55C \uC9C0\uCD9C\uACB0\uC758\uC11C\uB97C \uC2B9\uC778\uD558\uBA74, \uB7A9\uB9E4\uB2C8\uC800 \uC2A4\uD1A0\uC5B4\uC5D0&nbsp;<span style='color:#faa400'>\uC790\uB3D9\uC73C\uB85C \uC8FC\uBB38\uC774 \uC811\uC218</span>\uB429\uB2C8\uB2E4."))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("[Tip 2] \uD310\uB9E4\uC790\uAC00 \uC8FC\uBB38\uC744 \uC2B9\uC778\uD55C \uD6C4\uC5D0,&nbsp;<span style='color:#faa400'>\uD6C4\uBD88\uACB0\uC81C</span>\uAC00 \uAC00\uB2A5\uD569\uB2C8\uB2E4. \uC0C1\uD488\uACB0\uC81C \uD398\uC774\uC9C0\uC5D0\uC11C \uACB0\uC81C\uD560 \uC0C1\uD488\uC744 \uD655\uC778\uD574\uBCF4\uC138\uC694"))
    }
  }), _c('div', {
    staticClass: "flow-wrapper"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-1.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('랩매니저 스토어 상품 선택')))])]), _c('img', {
    attrs: {
      "src": require("./images/icon-arrow.svg")
    }
  }), !_vm.isOrder ? [_c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-2-1.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('지출결의서 작성')))])]), _c('img', {
    attrs: {
      "src": require("./images/icon-arrow.svg")
    }
  })] : _vm._e(), _c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-2.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt(_vm.isOrder ? '상품주문' : '지출결의서 승인')))])]), _c('img', {
    attrs: {
      "src": require("./images/icon-arrow.svg")
    }
  }), _c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-3.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('랩매니저 스토어 주문 접수')))])]), _c('img', {
    attrs: {
      "src": require("./images/icon-arrow.svg")
    }
  }), _c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-4.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('판매자 주문 승인')))])]), _c('img', {
    attrs: {
      "src": require("./images/icon-bar.svg")
    }
  }), _c('div', [_c('img', {
    attrs: {
      "src": require("./images/icon-tip-5.png")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('후불 결제')))])])], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }