import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	list: (instId, paramsObject) => `/institute/${instId}/draft/list/approve?${ParamMaker.setParamUsingObject(paramsObject)}`,
	filterList: instId => `/institute/${instId}/draft/filter/approve`,
	detail: (instId, id) => `/institute/${instId}/draft/${id}`,
	draftConclude: instId => `/institute/${instId}/draft/conclude`,
	draftConcludeCancel: instId => `/institute/${instId}/draft/conclude/cancel`,
	listExportExcel: instituteId => `/institute/${instituteId}/draft/list/approve/export/excel`,
}

const approvalAPI = {
	list: (instId, paramsObject) => axiosInstance.get(endPoint.list(instId, paramsObject)),
	filterList: instId => axiosInstance.get(endPoint.filterList(instId)),
	detail: (instId, id) => axiosInstance.get(endPoint.detail(instId, id)),
	draftConclude: (instId, body) => axiosInstance.post(endPoint.draftConclude(instId), body),
	draftConcludeCancel: (instId, body) => axiosInstance.patch(endPoint.draftConcludeCancel(instId), body),
	listExportExcel: (institueId, payload) =>
		axiosInstance.post(endPoint.listExportExcel(institueId), payload, {
			responseType: 'blob',
		}),
}

export default approvalAPI
