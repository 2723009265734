var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.treeData && _vm.storageInfoData ? _c('div', {
    staticClass: "LocationPage__page-container"
  }, [_vm.topStorageData ? _c('div', {
    staticClass: "LocationStorage"
  }, [_vm.topStorageData ? _c('inst-header', {
    attrs: {
      "title": _vm.topStorageData.name,
      "hasSubTitle": true,
      "subTitle": _vm.subTitle,
      "hasEditButton": _vm.userPermission.storage.update && !_vm.topStorageData.parentId,
      "editButtonFn": _vm.showEditStorage,
      "userPermission": _vm.userPermission
    }
  }) : _vm._e(), _c('div', {
    staticClass: "Location__content-container"
  }, [_c('div', {
    staticClass: "Location__content-left"
  }, [_c('div', {
    staticClass: "Location__chart-header",
    "class": {
      shadow: _vm.showBoxShadow
    }
  }, [_c('lm-input-search', {
    on: {
      "search": _vm.inputTreeSearch
    }
  }), _vm.userPermission.storage.create ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn bold",
    on: {
      "click": _vm.showModalAddChildStorage
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-lisence-copy.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('생성')) + " ")]) : _vm._e()], 1), _c('div', {
    ref: "chart-main-container",
    staticClass: "Location__main-container",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('button', {
    staticClass: "AppContainer__subtitle-btn btn",
    staticStyle: {
      "margin-left": "10px",
      "margin-bottom": "10px"
    },
    on: {
      "click": _vm.returnPage
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-left.svg"),
      "alt": "icon"
    }
  }), _c('b', {
    domProps: {
      "innerHTML": _vm._s(_vm.$route.path.indexOf('organization') > 0 ? _vm.tt('조직도 관리로 돌아가기') : _vm.tt('위치 관리로 돌아가기'))
    }
  })]), _vm.treeData ? _c('TreeView', {
    ref: "storage-tree-view",
    attrs: {
      "treeData": _vm.treeData,
      "textField": "name",
      "mode": "active",
      "selectWithChild": false,
      "keyword": _vm.keyword,
      "page": "storage"
    },
    on: {
      "changeTree": _vm.changeTree,
      "changeActive": _vm.changeActiveId
    }
  }) : _vm._e()], 1)]), _vm.storageInfoData ? _c('div', {
    staticClass: "Location__content-right"
  }, [_c('div', {
    staticClass: "Storage"
  }, [_c('div', {
    staticClass: "AppContainer__location-title"
  }, [_c('h5', {
    staticClass: "AppContainer__location-name"
  }, [_vm._v(" " + _vm._s(_vm.storageInfoData.name) + " ")]), !_vm.isTopStorageData && _vm.userPermission.storage.update ? _c('img', {
    staticClass: "Location__button-arrow",
    attrs: {
      "src": require("@/assets/svg/Button_edit.svg")
    },
    on: {
      "click": _vm.showModalEditChildStorage
    }
  }) : _vm._e(), !_vm.isTopStorageData && _vm.userPermission.storage["delete"] ? _c('img', {
    staticClass: "Location__button-arrow",
    attrs: {
      "src": require("@/assets/svg/icon-delete-round.svg")
    },
    on: {
      "click": _vm.showModalDeleteChild
    }
  }) : _vm._e()]), _vm.isTopStorageData ? _c('div', {
    staticClass: "Storage__warning"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('경고 표지')))])]), _vm.storageInfoData.warningSignImages && _vm.storageInfoData.warningSignImages.length > 0 ? _c('ul', {
    staticClass: "Storage__warning-list"
  }, _vm._l(_vm.storageInfoData.warningSignImages, function (warningSign, index) {
    return _c('li', {
      key: "warningSign-".concat(index),
      staticClass: "Storage__warning-item"
    }, [_c('img', {
      attrs: {
        "src": warningSign.link,
        "alt": "경고icon"
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "no-data"
  }, [_vm._v(_vm._s(_vm.tt('없음')))])]) : _vm._e(), _vm.isTopStorageData ? _c('div', {
    staticClass: "Storage__admin"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('관리자')))])]), _vm.hasAssignee ? _c('ul', {
    staticClass: "Storage__admin-list horizontal-list"
  }, [_vm.storageInfoData.assignee ? _c('li', {
    staticClass: "Storage__admin-item flex"
  }, [_vm.storageInfoData.assignee.image ? _c('img', {
    attrs: {
      "src": _vm.storageInfoData.assignee.image,
      "alt": "image"
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/svg/member-photo-default.svg"),
      "alt": "image"
    }
  }), _c('div', [_c('h6', [_vm._v(" " + _vm._s(_vm.storageInfoData.assignee.name) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.hypenPhoneNumber(_vm.storageInfoData.assignee.phone)) + " ")])]), _c('span', {
    staticClass: "ml-auto"
  }, [_vm._v(_vm._s(_vm.tt('정')))])]) : _vm._e(), _vm.storageInfoData.subAssignee ? _c('li', {
    staticClass: "Storage__admin-item flex"
  }, [_vm.storageInfoData.subAssignee.image ? _c('img', {
    attrs: {
      "src": _vm.storageInfoData.subAssignee.image,
      "alt": "image"
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/svg/member-photo-default.svg"),
      "alt": "image"
    }
  }), _c('div', [_c('h6', [_vm._v(" " + _vm._s(_vm.storageInfoData.subAssignee.name) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.hypenPhoneNumber(_vm.storageInfoData.subAssignee.phone)) + " ")])]), _c('span', {
    staticClass: "ml-auto"
  }, [_vm._v(_vm._s(_vm.tt('부')))])]) : _vm._e()]) : _c('div', {
    staticClass: "no-data"
  }, [_vm._v(" " + _vm._s(_vm.tt('지정 안 됨')) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "Storage__products"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('보관물품')))]), _c('margin-div', {
    attrs: {
      "divStyle": {
        marginRight: '3px'
      }
    }
  }), _c('span', {
    staticClass: "total-count",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('(총 ${1}개)', _vm.formatNumberLocale(_vm.storageInfoData.itemCountAllCategory)))
    }
  }, [_vm._v("s")])], 1), _vm.storageInfoData.itemCountByCategory ? _c('ul', {
    staticClass: "Storage__products-list"
  }, _vm._l(_vm.storageInfoData.itemCountByCategory, function (item) {
    return _c('li', {
      key: item.id,
      on: {
        "click": function click($event) {
          return _vm.goInventory(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/svg/' + _vm.changeTypeToImage(item.type))
      }
    }), _c('p', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _c('p', {
      staticClass: "ml-auto",
      domProps: {
        "textContent": _vm._s(_vm.tt('${1}개', item.count))
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "Organization__access-no-data no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_item.svg"),
      "alt": "no-image"
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.tt('보관물품이 없습니다')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('보관함에 물품을 등록하면 자동으로 목록이 추가됩니다')) + " ")])])]), _c('div', {
    staticClass: "Storage__regulation"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('법적 규제 물질')))]), _c('margin-div', {
    attrs: {
      "divStyle": {
        marginRight: '3px'
      }
    }
  }), _c('span', {
    staticClass: "total-count",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('(총 ${1}개)', _vm.formatNumberLocale(_vm.storageInfoData.itemCountAllLegal)))
    }
  })], 1), _c('ul', {
    staticClass: "Storage__regulation-list"
  }, _vm._l(_vm.storageInfoData.itemCountByLegal, function (legalItem, index) {
    return _c('li', {
      key: "legalItem-".concat(index),
      staticClass: "Storage__regulation-item",
      on: {
        "click": function click($event) {
          return _vm.goInventoryLegal(legalItem);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/".concat(legalItem.id, ".svg")),
        "alt": "물품icon"
      }
    }), _c('p', {
      staticClass: "name"
    }, [_vm._v(_vm._s(_vm.tt(legalItem.name)))]), _c('p', {
      staticClass: "ml-auto",
      domProps: {
        "textContent": _vm._s(_vm.tt('${1}개', legalItem.count))
      }
    })]);
  }), 0)]), _vm.isTopStorageData ? _c('div', {
    staticClass: "Storage__authority"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('접근 권한')))]), _c('button', {
    staticClass: "AppContainer__subtitle-btn btn Dropdown__btn"
  }, [_c('img', {
    staticClass: "icon-setting-auth",
    attrs: {
      "src": require("@/assets/svg/Authority.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('접근권한 추가')) + " "), _c('div', {
    staticClass: "Dropdown__menu"
  }, [_c('button', {
    on: {
      "click": _vm.showGroupSelect
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('그룹')) + " ")]), _c('button', {
    on: {
      "click": _vm.showMemberSelect
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('멤버')) + " ")])])])]), _vm.topStorageData.access && _vm.topStorageData.access.groups.length == 0 && _vm.topStorageData.access.users.length == 0 ? _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_setting.svg"),
      "alt": "no-image"
    }
  }), _c('h6', [_vm._v(" " + _vm._s(_vm.tt('접근 권한을 가진 그룹 또는 멤버가 없습니다')) + " ")]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>[접근권한 관리]</b> 버튼을 클릭하여 추가해보세요'))
    }
  })]) : _c('ul', {
    staticClass: "Storage__authority-list horizational-list"
  }, [_vm._l(_vm.topStorageData.access.groups, function (groupAccess) {
    return _c('li', {
      key: "groupAccess-".concat(groupAccess.id),
      attrs: {
        "clas": "Storage__authority-item"
      }
    }, [_c('div', {
      staticClass: "box"
    }, [_c('h3', [_vm._v(" " + _vm._s(_vm.getGroupInitialCharacter(groupAccess.name)) + " ")])]), _c('div', {
      staticClass: "Organization__access-item-info"
    }, [_c('h6', [_vm._v(_vm._s(groupAccess.name))]), _c('p', [_vm._v(_vm._s(groupAccess.memberCount) + _vm._s(_vm.tt('명')))])]), _c('button', {
      staticClass: "btn Btn__select Dropdown__btn"
    }, [_vm._v(" " + _vm._s(_vm.tt(_vm.showAccessPermission(groupAccess.accessPermission))) + " "), _c('div', {
      staticClass: "Dropdown__menu"
    }, [groupAccess.accessPermission.update ? _c('button', {
      on: {
        "click": function click($event) {
          return _vm.updatePermission('read', groupAccess);
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('조회 허용')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연구실의 정보, 물품 등을 조회할 수 있습니다.')) + " ")])]) : _vm._e(), !groupAccess.accessPermission.update ? _c('button', {
      on: {
        "click": function click($event) {
          return _vm.updatePermission('update', groupAccess);
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('편집 허용')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연구실의 정보, 물품 등을 조회하고 편집할 수 있습니다.')) + " ")])]) : _vm._e(), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.removePermission(groupAccess);
        }
      }
    }, [_c('h6', {
      staticClass: "red-color"
    }, [_vm._v(" " + _vm._s(_vm.tt('접근 권한 없애기')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('접근 권한을 없앱니다.')) + " ")])])])])]);
  }), _vm._l(_vm.topStorageData.access.users, function (userAccess) {
    return _c('li', {
      key: "userAccess-".concat(userAccess.id),
      attrs: {
        "clas": "Storage__authority-item"
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/member-detail-photo.svg")
      }
    }), _c('div', {
      staticClass: "Organization__access-item-info"
    }, [_c('h6', [_vm._v(_vm._s(userAccess.name))]), _c('p', [_vm._v(_vm._s(userAccess.email))])]), _c('button', {
      staticClass: "btn Btn__select Dropdown__btn"
    }, [_vm._v(" " + _vm._s(_vm.tt(_vm.showAccessPermission(userAccess.accessPermission))) + " "), _c('div', {
      staticClass: "Dropdown__menu"
    }, [userAccess.accessPermission.update ? _c('button', {
      on: {
        "click": function click($event) {
          return _vm.updatePermission('read', userAccess);
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('조회 허용')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연구실의 정보, 물품 등을 조회할 수 있습니다.')) + " ")])]) : _vm._e(), !userAccess.accessPermission.update ? _c('button', {
      on: {
        "click": function click($event) {
          return _vm.updatePermission('update', userAccess);
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('편집 허용')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연구실의 정보, 물품 등을 조회하고 편집할 수 있습니다.')) + " ")])]) : _vm._e(), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.removePermission(userAccess);
        }
      }
    }, [_c('h6', {
      staticClass: "red-color"
    }, [_vm._v(" " + _vm._s(_vm.tt('접근 권한 없애기')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('접근 권한을 없앱니다.')) + " ")])])])])]);
  })], 2)]) : _vm._e()])]) : _vm._e()]), _vm.isTopStorageData ? _c('modal-select-group-v-2', {
    ref: "modalSelectGroup",
    attrs: {
      "title": _vm.tt('권한 추가: 그룹'),
      "treeData": _vm.groupTreeData,
      "selectedList": _vm.storageInfoData.access.groups.map(function (group) {
        return group.id;
      }),
      "searchPlaceholder": _vm.tt('그룹 이름으로 검색해주세요')
    },
    on: {
      "completeFn": _vm.manageGroupAuth
    }
  }) : _vm._e(), _vm.isTopStorageData ? _c('modal-select-member-v-2', {
    ref: "modalSelectMember",
    attrs: {
      "title": _vm.tt('권한 추가: 멤버'),
      "listData": _vm.memberListData,
      "totalCount": _vm.memberListCount,
      "originMemberList": _vm.originMemberList,
      "offset": _vm.memberParams.offset,
      "length": _vm.memberParams.length
    },
    on: {
      "addListDataFn": _vm.addMemberListData,
      "completeFn": _vm.manageMemberAuth,
      "searchMember": _vm.searchMember
    }
  }) : _vm._e(), _c('add-child-storage-box-popup', {
    ref: "modal-add-child-storage",
    attrs: {
      "title": _vm.tt('하위 보관함 등록'),
      "labelName": _vm.tt('하위 보관함명')
    },
    on: {
      "complete": _vm.addChildStorage
    }
  }), _c('add-child-storage-box-popup', {
    ref: "modal-edit-child-storage",
    attrs: {
      "title": _vm.tt('하위 보관함 편집'),
      "labelName": _vm.tt('하위 보관함명')
    },
    on: {
      "complete": _vm.editChildStorage
    }
  }), _c('modal-confirm-delete-v-2', {
    ref: "modal-delete",
    attrs: {
      "title": "".concat(_vm.storageInfoData.name) + _vm.tt('삭제'),
      "content": "".concat(_vm.storageInfoData.name) + _vm.tt('하위 보관함을 삭제하시겠습니까?'),
      "delBtnFn": _vm.deleteChildStorage
    }
  }), _vm.topStorageData ? _c('modal-add-storage-v-2', {
    ref: "modal-edit-storage",
    attrs: {
      "storageData": _vm.topStorageData
    },
    on: {
      "refresh": _vm.refresh
    }
  }) : _vm._e()], 1) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }