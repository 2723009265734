var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail-page-wrapper"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('span', {
    staticClass: "subject"
  }, [_vm._v(" " + _vm._s(_vm.item.subject) + " ")])]), _c('div', {
    staticClass: "detail-content-wrapper"
  }, [_c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('결재 정보')))]), _c('div', {
    staticClass: "approval-info-wrapper"
  }, [_vm.item.register ? _c('approval-info-item', {
    attrs: {
      "approvalTitle": '기안',
      "name": _vm.item.register.name,
      "affiliation": _vm.item.group.name,
      "dateTime": _vm.item.createDatetime ? _vm.formatDatetime(_vm.item.createDatetime) : '-'
    }
  }) : _vm._e(), _c('div', _vm._l(_vm.item.approvals, function (approval, index) {
    return _c('approval-info-item', {
      key: "approval_".concat(index),
      attrs: {
        "approvalTitle": '결재',
        "approvalState": approval.status,
        "name": approval.user.name,
        "affiliation": approval.group.name,
        "dateTime": approval.concludeDatetime ? _vm.formatDatetime(approval.concludeDatetime) : '-'
      }
    });
  }), 1)], 1)]), _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('기안 정보')))]), _c('info-table', {
    attrs: {
      "items": _vm.draftInfoForTable
    }
  })], 1), _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('상품 정보')))]), _c('product-info-table', {
    attrs: {
      "productInfoList": _vm.item.purchaseItems
    }
  })], 1), _c('div', {
    staticClass: "description-wrap"
  }, [_c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s("".concat(_vm.tt('추후 정산시, 선택하는 상품항목에 따라 최종 결제금액이'), " ").concat(_vm.tt('지출 예정금액 합계와 상이할 수 있습니다.'))) + " ")])]), _c('div', [_c('div', {
    staticClass: "file-wrap"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('첨부 파일')))]), _c('button', {
    staticClass: "file-all-down",
    on: {
      "click": function click($event) {
        return _vm.$refs['files-table'].downloadAll();
      }
    }
  }, [_c('img', {
    staticClass: "icon-down",
    attrs: {
      "src": require("@/views/purchase/cargo/svg/icon-file-download.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('모두 다운로드')))])])]), _c('info-table', {
    ref: "files-table",
    attrs: {
      "items": _vm.attachmentsForTable
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }