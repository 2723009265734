<template>
	<b-modal @close="cancel" centered scrollable ref="modal-form-cargo-product" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="form-wrap sm" v-if="cargoFormType === 'ADD'">
				<div class="msg-warning">
					{{ `${tt('지출결의서 상신을 하지 않고 직접 추가하는 물품의 경우')}` }}
					<b>{{ `${tt('지출 내역 및 예산에 반영되지 않습니다.')}` }}</b>
				</div>
			</div>
			<cargo-category-selector title="카테고리 선택" ref="category-selector"></cargo-category-selector>
			<cargo-form-counter ref="form-counter"></cargo-form-counter>
			<cargo-form-product ref="form-product" v-if="isCategoryId"></cargo-form-product>
			<cargo-form-ingredient-list v-if="isUsedIngredient && isCategoryId" ref="form-ingredient"></cargo-form-ingredient-list>
			<cargo-form-msds v-if="isUsedMsds && isCategoryId"></cargo-form-msds>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" @click="cancel">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="goNext">
						{{ tt('다음') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import CargoFormProduct from '@/views/purchase/cargo/components/cargoList/form/CargoFormProduct.vue'
import CargoCategorySelector from '@/views/purchase/cargo/components/cargoList/categorySelector/CargoCategorySelector.vue'
import CargoFormIngredientList from '@/views/purchase/cargo/components/cargoList/form/CargoFormIngredientList.vue'
import CargoFormMsds from '@/views/purchase/cargo/components/cargoList/form/CargoFormMsds.vue'
import CargoFormCounter from '@/views/purchase/cargo/components/cargoList/form/CargoFormCounter.vue'
import { mapGetters, mapMutations } from 'vuex'
import { LargeClass, MiddleClass } from '@/utils/define/ItemCode'
import MixinFormScroll from '@/mixins/form/formScroll'

export default {
	name: 'ModalFormCargoProduct',
	components: {
		CargoFormProduct,
		CargoCategorySelector,
		CargoFormIngredientList,
		CargoFormMsds,
		CargoFormCounter,
	},
	mixins: [MixinFormScroll],
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('cargo/form', [
			'cargoFormType',
			'multiCreateCount',
			'category',
			'isUnitValid',
			'isProductValid',
			'isIngredientValid',
			'isCategoryId',
		]),
		title() {
			switch (this.cargoFormType) {
				case 'ADD':
					return this.tt('물품정보 등록')
				case 'NEED_INPUT_LIST':
				case 'NEED_INPUT_DETAIL':
					return this.tt('물품정보 입력')
				case 'EDIT':
					return this.tt('물품정보 편집')
				default:
					return this.tt('물품정보 등록')
			}
		},
		isUsedIngredient() {
			return [LargeClass.CHEMICAL].includes(this.category?.classification)
		},
		isUsedMsds() {
			return this.isUsedIngredient && ![MiddleClass.GAS].includes(this.category?.type)
		},
		isCounterValid() {
			return this.multiCreateCount > 0 && this.multiCreateCount <= 500
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('cargo/form', ['setMultiCreateCount', 'setInitCargoInfo', 'setShowError']),
		...mapMutations('tag', ['setTagList']),
		...mapMutations('form/image', ['setRemoveImageLink']),
		show() {
			this.$refs['modal-form-cargo-product'].show()
		},
		hide() {
			this.$refs['modal-form-cargo-product'].hide()
		},
		cancel() {
			this.$refs['modal-form-cargo-product'].hide()
			this.setInitCargoInfo()
			this.setTagList(null)
			this.setRemoveImageLink(null)
		},
		async goNext() {
			if (this.isUsedIngredient) {
				this.setShowError(!(this.isProductValid && this.isUnitValid && this.isIngredientValid && this.isCounterValid))
				if (this.isProductValid && this.isUnitValid && this.isIngredientValid && this.isCounterValid) {
					this.$emit('goNext')
				} else {
					await this.$refs['category-selector']?.showRequire()
					await this.scrollToInvalid()
				}
			} else {
				this.setShowError(!(this.isProductValid && this.isUnitValid && this.isCounterValid))
				if (this.isProductValid && this.isUnitValid && this.isCounterValid) {
					this.$emit('goNext')
				} else {
					await this.$refs['category-selector']?.showRequire()
					await this.scrollToInvalid()
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-form-cargo.scss';
</style>
