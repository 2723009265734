import dashboardAPI from '@/services/api/dashboard'

const dashboardComponent = {
	namespaced: true,
	state: {
		dashboardList: {},
		createTrend: {},
		createSummary: {},
		disposalTrend: {},
		disposalSummary: {},
		useTrend: {},
		useSummary: {},
		possessionTrend: {},
		disposalItem: {},
		msdsUpload: {},
		diagnosisList: {
			unsafeCount: 0,
			overdueList: [],
			targetList: [],
		},
		dangerStorage: {},
		legalList: {},
	},
	getters: {
		getDashboardList: state => state.dashboardList,
		getCreateTrendList: state => state.createTrend,
		getCreateTrendSummary: state => state.createSummary,
		getDisposalTrendList: state => state.disposalTrend,
		getDisposalTrendSummary: state => state.disposalSummary,
		getUseTrendList: state => state.useTrend,
		getUseTrendSummary: state => state.useSummary,
		getPossessionTrendList: state => state.possessionTrend,
		getDisposalItem: state => state.disposalItem,
		getMsdsUpload: state => state.msdsUpload,
		getDiagnosisList: state => state.diagnosisList,
		getDangerStorage: state => state.dangerStorage,
		getLegalList: state => state.legalList,
	},
	mutations: {
		setDashboardList: (state, payload) => {
			state.dashboardList = payload
		},
		setCreateTrendList: (state, payload) => {
			state.createTrend = payload
		},
		setCreateTrendSummary: (state, payload) => {
			state.createSummary = payload
		},
		setDisposalTrendList: (state, payload) => {
			state.disposalTrend = payload
		},
		setDisposalTrendSummary: (state, payload) => {
			state.disposalSummary = payload
		},
		setUseTrendList: (state, payload) => {
			state.useTrend = payload
		},
		setUseTrendSummary: (state, payload) => {
			state.useSummary = payload
		},
		setPossessionTrendList: (state, payload) => {
			state.possessionTrend = payload
		},
		setDisposalItem: (state, payload) => {
			state.disposalItem = payload
		},
		setMsdsUpload: (state, payload) => {
			state.msdsUpload = payload
		},
		setDiagnosisList: (state, payload) => {
			state.diagnosisList = payload
		},
		setDangerStorage: (state, payload) => {
			state.dangerStorage = payload
		},
		setLegalList: (state, payload) => {
			state.legalList = payload
		},
	},
	actions: {
		lookupDashboardList: async ({ commit }, instId) => {
			const response = await dashboardAPI.lookupDashboardList(instId)
			commit('setDashboardList', response.data)
		},
		lookupCreateTrendList: async ({ commit }, payload) => {
			const { instId, scope } = payload
			const response = await dashboardAPI.lookupCreateTrendList(instId, scope)
			commit('setCreateTrendList', response.data)
		},
		lookupCreateTrendSummary: async ({ commit }, payload) => {
			const { instId, scope } = payload
			const response = await dashboardAPI.lookupCreateTrendSummary(instId, scope)
			commit('setCreateTrendSummary', response.data)
		},
		lookupDisposalTrendList: async ({ commit }, payload) => {
			const { instId, scope } = payload
			const response = await dashboardAPI.lookupDisposalTrendList(instId, scope)
			commit('setDisposalTrendList', response.data)
		},
		lookupDisposalTrendSummary: async ({ commit }, payload) => {
			const { instId, scope } = payload
			const response = await dashboardAPI.lookupDisposalTrendSummary(instId, scope)
			commit('setDisposalTrendSummary', response.data)
		},
		lookupUseTrendList: async ({ commit }, payload) => {
			const { instId, scope } = payload
			const response = await dashboardAPI.lookupUseTrendList(instId, scope)
			commit('setUseTrendList', response.data)
		},
		lookupUseTrendSummary: async ({ commit }, payload) => {
			const { instId, scope } = payload
			const response = await dashboardAPI.lookupUseTrendSummary(instId, scope)
			commit('setUseTrendSummary', response.data)
		},
		lookupPossessionTrendList: async ({ commit }, instId) => {
			const response = await dashboardAPI.lookupPossessionTrendList(instId)
			commit('setPossessionTrendList', response.data)
		},
		lookupDisposalItem: async ({ commit }, instId) => {
			const response = await dashboardAPI.lookupDisposalItem(instId)
			commit('setDisposalItem', response.data)
		},
		lookupMsdsUpload: async ({ commit }, instId) => {
			const response = await dashboardAPI.lookupMsdsUpload(instId)
			commit('setMsdsUpload', response.data)
		},
		lookupDiagnosisList: async ({ commit }, instId) => {
			const response = await dashboardAPI.lookupDiagnosisList(instId)
			commit('setDiagnosisList', response.data)
		},
		lookupDangerStorage: async ({ commit }, instId) => {
			const response = await dashboardAPI.lookupDangerStorage(instId)
			commit('setDangerStorage', response.data)
		},
		lookupLegalList: async ({ commit }, instId) => {
			const response = await dashboardAPI.lookupLegalList(instId)
			commit('setLegalList', response.data)
		},
		updateDashboardSetting: async ({ commit }, payload) => {
			const { instId, body } = payload
			await dashboardAPI.updateDashboardSetting(instId, body)
		},
	},
}

export default dashboardComponent
