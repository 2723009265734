var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('태그 추가')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])];
      }
    }])
  }, [[_c('div', {
    staticClass: "form-input"
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt('태그'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    "class": {
      error: _vm.isInvalid
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('태그를 입력해주세요')
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputValue = $event.target.value;
      }
    }
  })]), _vm.isInvalid ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.tt('태그를 입력해주세요')))]) : _vm._e()])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }