<template>
	<div class="form-input" :class="{ error: error, 'modal-divide': forModalDivide }">
		<div class="input-wrapper">
			<input
				class="form-number-input"
				type="number"
				ref="input-number"
				:placeholder="tt(placeholder)"
				:value="inputValue"
				@input="handleInput"
				:disabled="forModalDivide"
			/>
			<b class="unit">{{ tt(unit) }}</b>
		</div>
		<span class="error" v-if="error">{{ errorMessage }}</span>
	</div>
</template>
<script>
import FormUnitSelectOption from '@/views/common/components/form/FormUnitSelectOption'
import Decimal from 'decimal.js'
export default {
	name: 'FormDivide',
	components: { FormUnitSelectOption },
	props: {
		value: {
			type: [String, Number],
			default: '',
		},
		unit: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
		forModalDivide: {
			type: Boolean,
			default: false,
		},
		useFloat: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputValue: '',
		}
	},
	computed: {
		error() {
			return this.required && this.isShowError && (!this.inputValue || this.inputValue <= 0)
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (this.inputValue !== this.value) this.inputValue = this.value
		})
	},
	watch: {
		value(value) {
			if (this.inputValue !== this.value) {
				this.inputValue = this.value
			}
		},
	},
	methods: {
		handleInput(e) {
			// const regex = this.useFloat ? /^\d*[.]{0,1}\d{0,}$/ : /^[1-9]\d*$/
			// const isNumber = e.target.value.match(regex)?.length > 0
			// if (isNumber) {
			this.inputValue = e.target.value.indexOf('e') >= 0 ? new Decimal(e.target.value) : e.target.value
			// } else {
			// 	const length = e.target.value.length
			// 	e.target.value = e.target.value.slice(0, length - 1)
			// 	this.useValue = e.target.value
			// }
			// console.warn(e, isNumber)
			this.$emit('input', this.inputValue ? Decimal(this.inputValue).toString() : '')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-divide.scss';
</style>
