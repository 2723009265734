var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: _vm.error
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper",
    "class": {
      error: _vm.error
    }
  }, [_c('single-date-picker', {
    ref: "datepicker",
    attrs: {
      "placeholder": _vm.tt(_vm.placeholder)
    },
    on: {
      "changeDate": _vm.changeDate
    }
  }), _vm.isExpireDatetime ? _c('div', {
    staticClass: "date",
    "class": {
      valid: _vm.diffDatetime > 0,
      invalid: _vm.diffDatetime <= 0
    }
  }, [_vm._v(_vm._s(_vm.dateString))]) : _vm._e()], 1), _vm.error ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.tt('일자를 선택해주세요')))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }