<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalManagementStorage">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-lg">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('저장소 관리') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="top flex">
						<button class="btn" @click="showAddStorage">
							<img src="@/assets/svg/Add(Button).svg" alt="icon" />
							{{ tt('저장소 추가') }}
						</button>
						<button class="btn" :disabled="checkList.length == 0" @click="showDeleteStorage()">
							<img src="@/assets/svg/icon-delete.svg" alt="icon" />
							{{ tt('저장소 삭제') }}
						</button>
					</div>
					<div class="boxwrap">
						<div class="Table__wrap scroll">
							<table>
								<thead>
									<th>
										<button class="checkbox" :class="({ active: isActive }, { active: isCheck })" @click="allChecked"></button>
									</th>
									<th v-for="item in checkHeaderList" :key="item.name" :width="item.width">
										<span>
											{{ tt(item.name) }}
										</span>
									</th>
								</thead>
								<tbody>
									<management-storage-item
										:allCheck="isActive"
										:headers="headerList"
										v-for="(item, index) in dangerStorageList"
										:key="item.id"
										:item="item"
										:order="index"
										:count="count"
										:index="index"
										:instId="$route.params.instituteId"
										:checkList="checkList"
										@checkId="addCheckIdList"
										@uncheckId="deleteCheckIdList"
										@move="changeOrder"
										@showEditStorage="showEditStorage"
										@showDeleteStorage="showDeleteStorage"
									></management-storage-item>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('닫기') }}
						</button>
					</div>
				</div>
			</div>

			<!--modals-->
			<modal-edit-storage ref="modal-edit-storage" @submit="addStorage"></modal-edit-storage>
			<modal-common ref="modal-common" @isHide="hideDelete"></modal-common>
		</div>
	</portal>
</template>

<script>
import dangerStorageAPI from '@/services/api/safety/dangerStorage'
import MixinModal from '@/mixins/modal'
import ModalEditStorage from '@/views/safety/dangerStorage/modals/ModalEditStorage.vue'
import ModalDeleteStorage from '@/views/safety/dangerStorage/modals/ModalDeleteStorage.vue'
import ManagementStorageItem from '@/views/safety/dangerStorage/modals/components/ManagementStorageItem.vue'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'

export default {
	name: 'ModalManagementStorage',
	mixins: [MixinModal],
	components: {
		ModalEditStorage,
		ModalDeleteStorage,
		ManagementStorageItem,
		ModalCommon,
	},
	props: {},
	created() {
		this.checkHeaderList = this.headerList
	},
	data() {
		return {
			dangerStorageList: [],
			isActive: false,
			isCheck: false,
			checkList: [],
			headerList: [
				{
					name: '상태',
					width: 120,
					value: true,
					sort: false,
				},
				{
					name: '저장소 이름',
					width: 240,
					value: true,
					sort: false,
				},
				{
					name: '위치 정보',
					width: 260,
					value: true,
					sort: false,
				},
				{
					name: '순서',
					width: 120,
					value: true,
					sort: false,
				},
				{
					name: '관리',
					width: 220,
					value: true,
					sort: false,
				},
			],
			checkHeaderList: [],
			keyword: null,
			count: null,
			deleteId: [],
		}
	},
	computed: {},
	watch: {},
	methods: {
		async loadDangerStorage() {
			const instId = this.$route.params.instituteId

			const response = await dangerStorageAPI.lookupDangerStorageList(instId, this.keyword)
			this.count = response.data.count
			this.dangerStorageList = response.data.list
		},
		show() {
			this.isShow = true
			this.list = this.item
			this.deleteId = []
			this.checkList = []
			this.loadDangerStorage()
		},
		addCheckIdList(id) {
			const code = id
			this.checkList.push(code)
			if (this.checkList.length == this.dangerStorageList.length) {
				this.isCheck = true
			}
		},
		deleteCheckIdList(id) {
			const code = id
			for (let i = 0; i < this.checkList.length; i++) {
				if (this.checkList[i] == code) {
					this.checkList.splice(i, 1)
				}
			}
			this.isCheck = false
		},
		allChecked() {
			if (this.isActive) {
				this.isActive = false
				this.isCheck = false
			} else {
				this.isActive = true
				this.isCheck = true
			}
		},
		changeOrder(id, direction) {
			const instId = this.$route.params.instituteId

			const payload = {
				id: id,
				direction: direction,
			}

			dangerStorageAPI.changeStorageOrder(instId, payload).then(() => {
				this.loadDangerStorage()
			})
		},
		close() {
			this.hide()
			this.$emit('loadList')
		},
		showAddStorage() {
			this.$refs['modal-edit-storage'].show('ADD')
		},
		showEditStorage(mode, item) {
			this.$refs['modal-edit-storage'].show(mode, item)
		},
		showDeleteStorage(id) {
			if (id) {
				this.deleteId.push(id)
			} else {
				this.deleteId = this.checkList
			}
			const title = this.tt('저장소 삭제')
			const desc = this.tt('정말로 저장소를 삭제하시겠습니까?') + '&' + this.tt('이 동작은 되돌릴 수 없습니다')
			const button = 'delete'
			const button_text = this.tt('삭제')
			this.$refs['modal-common'].show(title, desc, button, button_text)
		},
		hideDelete(select) {
			if (select.check == true) {
				const payload = {
					instId: this.$route.params.instituteId,
					ids: this.deleteId,
				}

				dangerStorageAPI.deleteDangerStorage(payload.instId, payload.ids).then(() => {
					this.loadDangerStorage()
					this.$emit('loadSummary')

					const toastTitle = this.tt('저장소 삭제 완료')
					const toastContent = this.tt('저장소 삭제가 완료되었습니다.')
					this.$root.toast(toastTitle, toastContent, 'success')
				})
			}

			this.$refs['modal-common'].hide()
		},
		addStorage(payload, mode, id) {
			const instId = this.$route.params.instituteId
			const nameReg = /^[^*?:\\/[\]]*$/
			if (!nameReg.test(payload?.name)) {
				this.$root.toast('저장소 이름', '저장소 이름에 특수문자가 포함될 수 없습니다.', 'error')
				return
			}
			if (mode == 'ADD') {
				dangerStorageAPI.addDangerStorage(instId, payload).then(() => {
					this.loadDangerStorage()
					this.$emit('loadSummary')

					const toastTitle = this.tt('저장소 추가')
					const toastContent = this.tt('저장소 추가가 완료되었습니다')
					this.$root.toast(toastTitle, toastContent, 'success')
				})
			} else if (mode == 'EDIT') {
				const editPayload = {
					id: id,
					name: payload.name,
					locationsId: payload.locationsId,
				}

				dangerStorageAPI.updateDangerStorage(instId, editPayload).then(() => {
					this.loadDangerStorage()
					this.$emit('loadSummary')
				})

				const toastTitle = this.tt('저장소 편집')
				const toastContent = this.tt('저장소 편집이 완료되었습니다')
				this.$root.toast(toastTitle, toastContent, 'success')
			}
			this.$refs['modal-edit-storage'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalManagementStorage {
	.Popup__container {
		padding: 0;
		.Popup__top {
			padding: 20px;
		}
		.Popup__content {
			padding: 0;
			.top {
				padding: 0 20px 20px;
				.btn {
					font-weight: bold;
					margin: 0 10px 0 0;
					img {
						padding-right: 0;
					}
				}
			}
			.boxwrap {
				background: $SYSTEM_BACKGROUND;
				height: 60vh;
				.Table__wrap {
					height: inherit;
					table {
						thead {
							box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
						}
						tbody {
							tr {
								td {
									.state {
										&.safe {
											color: #57c17e;
										}
									}
									.Btn__hover-balloon-w {
										padding: 0;
										font-size: 0.875rem;
										&:hover {
											.balloon {
												opacity: 1;
												visibility: visible;
											}
										}
									}
									.btn-wrap {
										button {
											margin-right: 10px;
										}
										.arrow {
											width: 30px;
											height: 30px;
											padding: 0;
											text-align: center;
											justify-content: center;
											img {
												padding-right: 0;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.Popup__bottom {
			padding: 20px;
			border-top: 1px solid $DIVIDER;
		}
	}
}
</style>
