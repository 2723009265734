<template>
	<ul class="toggle-list">
		<b-overlay :show="isUpdating" spinner-variant="primary" rounded="sm">
			<li v-for="(field, index) in fields" :key="field.key">
				<p>
					{{ tt(field.name) }}
					<span v-if="field.isRequire" class="required">*</span>
					<span v-if="field.key === 'expireDatetime' && field.isActive === true" class="expireDay">
						{{ tt('(${1}일)', category.expirationDay) }}
					</span>
					<span class="txt" v-if="type === 'custom'">
						{{ tt(switchText(field.varType)) }}
					</span>
				</p>
				<p v-if="field.key === 'expireDatetime' && field.isActive === true" @click="showChangeExpireDatetimeModal" class="changeBtn">
					{{ tt('변경') }}
				</p>
				<div class="switch-wrap">
					<label class="switch" v-b-tooltip.hover="tooltipText(field.isRequire)" @click="clickToggle(field.isRequire)">
						<input
							type="checkbox"
							:checked="field.isActive"
							:readonly="isUpdating"
							:disabled="field.isRequire || !isUpdate"
							@input="updateItem($event, index)"
						/>
						<span class="slider round"></span>
					</label>
					<button v-if="type === 'custom'" class="btn Dropdown__btn">
						<img src="@/views/inventoryGen2/svg/Setting.svg" />
						<div class="Dropdown__menu">
							<span v-b-tooltip.hover="!isUpdate ? tt('권한이 없습니다.') : ''">
								<button :disabled="!isUpdate" @click="$emit('editField', index)">
									{{ tt('편집') }}
								</button>
							</span>
							<span v-b-tooltip.hover="!isDelete ? tt('권한이 없습니다.') : ''">
								<button :disabled="!isDelete" @click="deleteCategoryField(index)">
									{{ tt('삭제') }}
								</button>
							</span>
						</div>
					</button>
				</div>
			</li>
		</b-overlay>
		<modal-confirm ref="modal-change-expire-datetime" title="유효기간 변경" confirmText="변경" :noContent="false" @confirm="changeExpireDatetime">
			<form-text-single-line
				:name="'유효기간(일)'"
				:value="expireDatetime"
				:placeholder="'유효기간을 입력해 주세요'"
				:required="true"
				@input="value => inputFormValue(value)"
			></form-text-single-line>
		</modal-confirm>
		<modal-confirm
			ref="modal-delete-field"
			title="항목 삭제"
			confirmText="삭제"
			variant="warning"
			:content="`정말로 항목을 삭제하시겠습니까?</br>기존 물품 정보에서 항목이 삭제됩니다.`"
		>
		</modal-confirm>
	</ul>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
import ModalLoading from '@/views/common/components/modalGen2/ModalLoading.vue'
export default {
	components: {
		FormTextSingleLine,
	},
	props: {
		fields: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState('inventoryGen2/setting', ['category']),
		isUpdate() {
			return this.userPermission.category && this.userPermission.category.update
		},
		isDelete() {
			return this.userPermission.category && this.userPermission.category.delete
		},
	},
	data() {
		return {
			expireDatetime: '',
			isUpdating: false,
		}
	},
	methods: {
		...mapActions('inventoryGen2/setting', ['getCategory', 'editCategory']),
		async updateItem(e, index) {
			if (this.isUpdating) return
			this.isUpdating = true
			const newFields = JSON.parse(JSON.stringify(this.category.fields))
			newFields[`${this.type}Fields`][index].isActive = e.target.checked
			await this.editCategory({
				id: this.category.id,
				fields: newFields,
			})
			await this.$nextTick().then()
			this.$root.toast(this.tt('편집 완료'), this.tt('카테고리가 편집되었습니다'), 'success')
			this.isUpdating = false
		},
		clickToggle(isRequire) {
			if (isRequire) this.$root.toast(this.tt('항목 비활성화 불가능'), this.tt('선택하신 항목은 필수값입니다'), 'warning')
		},
		showChangeExpireDatetimeModal() {
			this.expireDatetime = this.category.expirationDay
			this.$refs['modal-change-expire-datetime'].show()
		},
		inputFormValue(value) {
			this.expireDatetime = value
		},
		async changeExpireDatetime() {
			if (this.expireDatetime) {
				await this.editCategory({
					id: this.category.id,
					expirationDay: this.expireDatetime,
				})
				this.$root.toast(this.tt('변경 완료'), this.tt('변경사항이 반영되었습니다.'), 'success')
			} else this.$root.toast(this.tt('유효기간 미입력'), this.tt('유효기간을 입력해주시기 바랍니다.'), 'warning')
		},
		deleteCategoryField(index) {
			this.$refs['modal-delete-field'].show(async () => {
				try {
					const newFields = JSON.parse(JSON.stringify(this.category.fields))
					newFields['customFields'].splice(index, 1)
					await this.editCategory({
						id: this.category.id,
						fields: newFields,
					})
					this.$root.toast(this.tt('항목 삭제 완료'), this.tt('항목이 삭제되었습니다'), 'success')
				} catch (e) {}
			})
		},
		switchText(varType) {
			switch (varType) {
				case 'text_single_line':
					return this.tt('텍스트 및 숫자 입력(한 줄)')
				case 'text_multiple_line':
					return this.tt('텍스트 및 숫자 입력(여러 줄)')
				case 'text_single_line_with_unit':
					return this.tt('텍스트 및 숫자 입력 + 단위')
				case 'select_option':
					return this.tt('옵션 선택')
				case 'select_datetime':
					return this.tt('날짜 선택')
				case 'select_member':
					return this.tt('멤버 선택')
			}
		},
		tooltipText(bool) {
			return !this.isUpdate ? this.tt('권한이 없습니다.') : bool ? this.tt('필수값은 해제할 수 없습니다.') : ''
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.text-primary {
		color: $LAB_YELLOW !important;
	}
	.b-overlay-wrap {
		width: 100%;
	}
}
.Dropdown__menu {
	button:disabled {
		border: 0 !important;
	}
}
.form-input {
	width: 100%;
	max-width: none;
}
.toggle-list {
	display: flex;
	flex-wrap: wrap;
	li {
		display: inline-flex;
		justify-content: space-between;
		margin-right: 10px;
		border-top: 1px solid $DIVIDER;
		align-items: center;
		padding: 10px 0;
		width: calc(50% - 10px);
		min-width: 300px;
		height: unset;
		p {
			font-size: 14px;
			// display: flex;
			span.txt {
				display: block;
				font-size: 0.75rem;
				color: $PLACEHOLDER;
				font-weight: 400;
				padding-top: 2px;
			}
		}
		.switch-wrap {
			display: flex;
			.switch {
				margin-top: 2px;
			}
			& > button {
				margin-left: 10px;
				width: 24px;
				height: 24px;
			}
		}
		.expireDay {
			margin-left: 5px;
			color: $LAB_YELLOW;
		}
		.changeBtn {
			margin-left: auto;
			margin-right: 5px;
			color: $SECONDARY_2;
			cursor: pointer;
		}
	}
}
</style>
