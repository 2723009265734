var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_vm.isShow ? _c('div', {
    staticClass: "Popup__depth3"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.cancel
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('p', {
    staticClass: "Popup__plain-text"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.desc.split('&')[0]))), _c('br'), _vm._v(" " + _vm._s(_vm.tt(_vm.desc.split('&')[1])) + " ")])]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    "class": "Btn__".concat(_vm.button),
    staticStyle: {
      "width": "unset",
      "min-width": "80px"
    },
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.button_text)) + " ")])])])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }