var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt('카테고리 선택')))]), _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isValid
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.validMessage)) + " ")])]), _c('category-selector', {
    ref: "category-selector",
    attrs: {
      "noSmall": "",
      "value": _vm.category
    },
    on: {
      "change": _vm.selectCategory
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }