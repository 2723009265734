import productAPI from '@/services/api/purchase/product'
import _ from 'lodash'
import cookies from 'vue-cookies'
import { pruchaseProductViewOption } from '@/utils/define/ViewOption'
const state = {
	list: [],
	count: 0,
	loadParams: {
		keyword: '', // 검색어
		offset: 0,
		length: 50,
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
		budgetsId: JSON.parse(cookies.get('filter_purchase_product_budgetsId')) ?? [], // 예산 유형 필터
		draftGroupsId: JSON.parse(cookies.get('filter_purchase_product_draftGroupsId')) ?? [], // 기안 연구실 그룹 필터
		startOrderCreateDatetime: '',
		endOrderCreateDatetime: '',
	},
	filter: null,
	filterCookieName: {
		budgetsId: 'filter_purchase_product_budgetsId',
		draftGroupsId: 'filter_purchase_product_draftGroupsId',
	},
	selectItem: [],
	viewOptionCookieName: 'viewOption_purchase_product',
	checkedViewOption: JSON.parse(cookies.get('viewOption_purchase_product')) ?? [],
	viewOptionList: pruchaseProductViewOption,
	noPaymentSummary: null,
}

const getters = {
	list: state => state.list,
	keyword: state => state.loadParams.keyword,
	filteredCount: state => state.loadParams.draftGroupsId.length + state.loadParams.budgetsId.length,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
	selectItem: state => state.selectItem,
	selectItemId: state => state.selectItem.map(i => i.id),
	isEmptySelectItem: state => state.selectItem.length === 0,
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
		state.noPaymentSummary = data.noPaymentSummary
	},
	setOffset: (state, pageNumber) => {
		state.loadParams.offset = (pageNumber - 1) * length
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			state.loadParams[key] = loadParams[key]
		})
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	setViewOptions: (state, list) => {
		cookies.set(state.viewOptionCookieName, JSON.stringify(list))
		state.checkedViewOption = list
	},
	initSelectItem: state => {
		state.selectItem = []
	},
	initFilterCookies: (state, needReset) => {
		if (needReset)
			Object.values(state.filterCookieName).forEach(key => {
				cookies.set(key, JSON.stringify([]))
			})
		state.loadParams = {
			keyword: '', // 검색어
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			budgetsId: JSON.parse(cookies.get('filter_purchase_product_budgetsId')) ?? [], // 예산 유형 필터
			draftGroupsId: JSON.parse(cookies.get('filter_purchase_product_draftGroupsId')) ?? [], // 기안 연구실 그룹 필터
			startOrderCreateDatetime: '',
			endOrderCreateDatetime: '',
		}
	},
	checkedAll: state => {
		const list = state.list.filter(it => it.status != 'WAIT' && it.paymentStatus == null && it.cancelStatus == null)
		if (list.length > 0 && list.length === state.selectItem.length) state.selectItem = []
		else state.selectItem = list.map(it => it.id)
	},
	checkedId: (state, id) => {
		if (state.selectItem.includes(id)) state.selectItem.remove(id)
		else state.selectItem.push(id)
	},
}

const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		const response = await productAPI.list(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	getFilter: async ({ commit, rootGetters }) => {
		const response = await productAPI.filterList(rootGetters.instituteId)
		commit('setFilter', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		dispatch('getList')
	},
	exportExcel: async ({ state, rootGetters }, type) => {
		const response = await productAPI.listExportExcel(rootGetters.instituteId, type == 'ALL' ? null : state.loadParams)
		return response
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
