var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "lab-selector-item-wrapper",
    "class": {
      checked: _vm.isChecked,
      ' no-permission': !_vm.item.canMigration
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "initial-container",
    "class": {
      'no-permission': !_vm.item.canMigration
    }
  }, [_c('span', {
    staticClass: "initial-name",
    domProps: {
      "textContent": _vm._s(_vm.initialName)
    }
  })]), _c('div', {
    staticClass: "text-wrapper",
    "class": {
      'no-permission': !_vm.item.canMigration
    }
  }, [_c('div', {
    staticClass: "lab-name",
    domProps: {
      "textContent": _vm._s(_vm.item.labName)
    }
  }), _c('div', {
    staticClass: "subtext",
    domProps: {
      "textContent": _vm._s(_vm.itemSubtext)
    }
  })]), _c('div', {
    staticClass: "icon-wrapper",
    "class": {
      'no-permission': !_vm.item.canMigration
    }
  }, [_vm.item.canMigration ? _c('img', {
    staticClass: "select-icon"
  }) : _c('div', {
    staticClass: "no-permission-icon-wrapper"
  }, [_c('div', {
    staticClass: "no-permission-text"
  }, [_vm._v(_vm._s(_vm.tt('권한없음')))]), _c('img', {
    staticClass: "select-icon",
    "class": {
      'no-permission': !_vm.item.canMigration
    }
  })])]), _c('div', {
    staticClass: "Btn__hover-balloon-w"
  }, [_c('div', {
    staticClass: "balloon"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.ballonText)
    }
  })])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }