import eolAPI from '@/services/api/eol'
import { EOLType } from '@/utils/define/DefineEOLType'

const AppEOL = {
	namespaced: true,
	state: () => ({
		beforeStatus: null,
		eolState: null,
	}),
	getters: {
		isNewUser: state => state.eolState && state.eolState.status === EOLType.NEW,
		isMigrationPage: state => state.eolState && [EOLType.ING, EOLType.AVAILABLE, EOLType.NOPERM, EOLType.SELECT].includes(state.eolState.status),
	},
	mutations: {
		setBeforeStatus: state => (state.beforeStatus = state.eolState ? state.eolState.status : null),
		setEolState: (state, payload) => (state.eolState = payload),
	},
	actions: {
		loadEolState: ({ commit }, payload) => {
			return new Promise(async (resolve, reject) => {
				try {
					const response = await eolAPI.loadEolState(payload)
					commit('setBeforeStatus')
					commit('setEolState', { ...response.data })
					resolve()
				} catch (error) {
					reject(error)
				}
			})
		},
	},
}
export default AppEOL
