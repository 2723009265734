import PageName from '@/utils/define/PageName'

export default {
	data() {
		return {}
	},
	created() {},
	computed: {},
	methods: {
		openMigrationDocs() {
			const migrationDocUrl = 'https://smartjack.notion.site/PRO-ba63ad98f3ea470bb011bdf877d956a4'
			window.open(migrationDocUrl, '_blank')
		},
		openMigrationGuide() {
			const migrationGuideUrl = 'https://smartjack.notion.site/User-Guide-PC-8248257f8c84420ba787f7f0408e3566'
			window.open(migrationGuideUrl, '_blank')
		},
		routeMigrationInputInstitutionName() {
			this.$router.push({
				name: PageName.Migration.InputInstitutionName,
			})
		},
		routeMigrationSelectLab() {
			this.$router.push({
				name: PageName.Migration.SelectLab,
			})
		},
		routeMigrationMain() {
			this.$router.push({
				name: PageName.Migration.Main,
			})
		},
		routeMigrationMemberList() {
			this.$router.push({
				name: PageName.Migration.MemberList,
			})
		},
	},
}
