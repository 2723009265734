var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.previewData ? _c('div', {
    staticStyle: {
      "width": "fit-content",
      "display": "flex",
      "flex-direction": "column",
      "padding": "3mm",
      "background": "white",
      "border-radius": "10px"
    },
    attrs: {
      "id": "ghsContent"
    }
  }, _vm._l(_vm.labelCount, function (item, idx) {
    return _c('div', {
      key: item,
      style: _vm.ghsWrapStyle,
      attrs: {
        "id": "ghsWrap".concat(idx)
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "height": "100%",
        "justify-content": "space-around"
      }
    }, [_c('div', {
      style: _vm.fieldStyle
    }, [_c('h1', {
      style: _vm.nameStyle
    }, [_vm._v(" " + _vm._s(_vm.previewData.name) + " ")]), _c('div', {
      staticStyle: {
        "margin-top": "5px",
        "font-size": "10px",
        "transform": "translate(-10%, 50%) scale(0.8)",
        "display": "inline-block"
      }
    }, [_c('span', [_vm._v("CAS: " + _vm._s(_vm.previewData.casno))]), _vm._v(" | "), _vm.signalText ? _c('span', [_vm._v(_vm._s(_vm.tt('신호어')) + " : " + _vm._s(_vm.tt(_vm.signalText)))]) : _vm._e(), _vm._v(" | "), _vm.previewData.productCode ? _c('span', [_vm._v(_vm._s(_vm.tt('제품번호')) + " : " + _vm._s(_vm.previewData.productCode))]) : _vm._e(), _vm._v(" | "), _vm.previewData.brand && _vm.previewData.brand.name ? _c('span', [_vm._v(_vm._s(_vm.tt('브랜드')) + " : " + _vm._s(_vm.previewData.brand.name))]) : _vm._e()])]), _vm.ghsPictogram ? _c('div', {
      style: _vm.fieldStyle
    }, _vm._l(_vm.ghsPictogram, function (img) {
      return _c('img', {
        key: img,
        style: _vm.pictogramStyle,
        attrs: {
          "src": require("@/assets/ghs/".concat(img, ".png"))
        }
      });
    }), 0) : _vm._e(), _c('div', {
      staticClass: "text-container",
      style: _vm.textContainerStyle
    }, [_c('h2', {
      staticClass: "subtitle",
      style: _vm.styleSubtitle
    }, [_vm._v(" " + _vm._s(_vm.tt('유해·위험 문구')) + " ")]), _vm.hCodeText ? _c('div', {
      staticClass: "text",
      style: _vm.textStyle
    }, [_vm._v(" " + _vm._s(_vm.hCodeText) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "text-container",
      style: _vm.textContainerStyle
    }, [_c('h2', {
      staticClass: "subtitle",
      style: _vm.styleSubtitle
    }, [_vm._v(_vm._s(_vm.tt('예방조치문구')))]), _vm.pCodeText ? _c('div', {
      staticClass: "text",
      style: _vm.textStyle
    }, [_vm._v(" " + _vm._s(_vm.ellipsisPCode) + " ")]) : _vm._e(), _c('div', {
      staticClass: "text red",
      staticStyle: {
        "font-size": "7px",
        "font-weight": "400",
        "overflow-wrap": "break-word",
        "color": "#ff3b31",
        "transform": "translate(-10%, 0%) scale(0.8)",
        "display": "inline-block"
      }
    }, [_vm._v(" *" + _vm._s(_vm.tt('자세한 예방조치는 MSDS를 참고해주세요.')) + " ")])]), _c('div', {
      staticClass: "text-container provider",
      staticStyle: {
        "border-bottom": "none",
        "display": "flex",
        "justify-content": "space-between",
        "padding": "0",
        "height": "100%"
      }
    }, [_c('div', {
      staticClass: "left-container",
      staticStyle: {
        "width": "70%",
        "padding": "5px"
      }
    }, [_c('h2', {
      staticClass: "subtitle",
      style: _vm.styleSubtitle
    }, [_vm._v(_vm._s(_vm.tt('공급자 정보')))]), _vm.provider ? _c('div', {
      staticClass: "text",
      style: _vm.textStyle
    }, [_vm._v(_vm._s(_vm.provider.name) + " | " + _vm._s(_vm.provider.phone) + " | " + _vm._s(_vm.provider.address))]) : _vm._e(), _vm.etc ? _c('div', {
      staticClass: "text",
      style: _vm.textStyle
    }, [_vm._v(" " + _vm._s(_vm.etc) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "qr-container",
      style: _vm.qrContainerStyle
    }, [_vm.previewData.qrSticker ? _c('div', [_c('qr-code', {
      staticStyle: {
        "margin": "auto",
        "width": "30px",
        "margin-top": "5px"
      },
      attrs: {
        "text": _vm.previewData.qrSticker,
        "size": _vm.qrSize
      }
    }), _c('p', {
      staticClass: "qr-code",
      style: _vm.qrCodeStyle
    }, [_vm._v(" " + _vm._s(_vm.previewData.qrSticker.slice(0, 12)) + " ")])], 1) : _vm.qrStickerData ? _c('div', [_c('img', {
      style: _vm.qrImgStyle,
      attrs: {
        "src": _vm.qrStickerData.image
      }
    }), _c('p', {
      staticClass: "qr-code",
      style: _vm.qrCodeStyle
    }, [_vm._v(" " + _vm._s(_vm.qrStickerData.identifier.slice(0, 12)) + " ")])]) : _vm._e()])])])]);
  }), 0) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }