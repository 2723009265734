<template>
	<div class="LocationPage__page-container">
		<div class="LocationStorage">
			<inst-header :title="tt('위치 및 보관함')"></inst-header>
			<div class="Location__content-container" :class="{ experience: isAppModeExperience }">
				<div class="Location__content-left">
					<div class="Location__chart-header" :class="{ shadow: showBoxShadow }">
						<lm-input-search @search="searchTree" :placeholder="tt('위치명으로 검색')"></lm-input-search>
						<button
							class="AppContainer__subtitle-btn btn bold"
							@click.stop="showModalAddChildLocation('ADD_LOCATION')"
							v-if="userPermission.location && userPermission.location.create"
						>
							<img src="@/assets/svg/button-icon-lisence-copy.svg" alt="icon" />
							{{ tt('생성') }}
						</button>
						<button
							class="AppContainer__subtitle-btn btn ml-10 bold"
							@click.stop="showModalDeleteLocation"
							:disabled="selectedLocation && !selectedLocation.info.parentId"
							v-if="userPermission.location && userPermission.location.delete"
						>
							<img src="@/assets/svg/Trash.svg" alt="icon" />
							{{ tt('삭제') }}
						</button>
					</div>
					<div class="Location__main-container" ref="chart-main-container" v-on:scroll="handleScroll">
						<location-tree-view
							v-show="searchTreeData"
							ref="location-tree-view"
							:treeData="treeData"
							textField="name"
							@changeActive="changeActiveId"
							@changeTree="changeTree"
							mode="active"
							:selectWithChild="false"
							:keyword="keyword"
							:isStoragePage="true"
							@moveStorage="moveStorage"
							@withResult="withResult"
							page="location"
						></location-tree-view>
						<no-data-list
							v-if="!searchTreeData"
							image="search"
							title="검색 결과가 없습니다"
							content="단어의 철자가 정확한지 확인하세요"
						></no-data-list>
					</div>
				</div>
				<div id="Location" class="Location" v-if="selectedLocation">
					<div class="AppContainer__location-title">
						<h5 class="AppContainer__location-name">
							{{ selectedLocation.info.name }}
						</h5>
						<img
							class="Location__button-arrow"
							src="@/assets/svg/Button_edit.svg"
							@click.stop="showModalAddChildLocation('EDIT_LOCATION')"
							v-if="userPermission.location.update"
						/>
					</div>
					<!-- 사용그룹 : Start -->
					<div class="Location__group-box">
						<div class="AppContainer__subtitle">
							<div>
								<h6>{{ tt('사용 그룹') }}</h6>
							</div>
							<button class="AppContainer__subtitle-btn btn" @click="showModalAddUsingGroup">
								<img src="@/assets/svg/authority-setting.svg" alt="icon" />{{ tt('사용 그룹 관리') }}
							</button>
						</div>

						<p v-if="hasGroups" class="Location__group-location">
							{{ usingGroups }}
						</p>
						<div v-if="!hasGroups" class="no-data-box">
							<img src="@/assets/svg/no-data_group.svg" alt="no-image" />
							<h6 v-html="tt('${1}(을)를 사용 중인 그룹이 없습니다.', selectedLocation.info.name)"></h6>
							<p style="padding-top: 0" v-html="tt('<b>[사용 그룹 관리]</b>버튼을 클릭하여 그룹을 추가해보세요')"></p>
						</div>
					</div>
					<!-- 사용그룹 : End -->
					<!-- 보관함 : Start  -->
					<div class="StorageBox__group">
						<div class="AppContainer__subtitle">
							<div>
								<h6>{{ tt('보관함') }}</h6>
							</div>
							<button class="AppContainer__subtitle-btn btn" @click="showModalEditStorage()" v-if="userPermission.storage.create">
								<img src="@/assets/svg/add-storage.svg" alt="추가icon" />{{ tt('보관함 추가') }}
							</button>
						</div>
						<ul class="StorageBox__list" v-if="hasStorages">
							<storage-item
								v-for="storage in selectedLocation.storages"
								:key="`storage-${storage.id}`"
								:storageInfo="storage"
								:userPermission="userPermission"
								@editStorage="showModalEditStorage"
								@deleteStorage="showModalDeleteStorage"
								@refresh="refreshData"
							></storage-item>
						</ul>
						<div v-else class="no-data-box">
							<img src="@/assets/svg/no-data_storage.svg" alt="no-image" />
							<h6 v-html="tt('보관함이 없습니다.')"></h6>
							<p v-html="tt('<b>[보관함 추가]</b> 버튼을 클릭하여 보관함을 추가해보세요')"></p>
						</div>
					</div>
					<!-- 보관함 : End  -->
				</div>
			</div>
		</div>
		<!-- 모달 : Start -->
		<modal-add-child-location ref="modal-add-child-location" @refresh="refreshData"></modal-add-child-location>
		<modal-delete-location ref="modal-delete" @refresh="refreshData"></modal-delete-location>
		<modal-add-using-group ref="modal-add-using-group" @refresh="hideModalAddUsingGroup"></modal-add-using-group>
		<!-- <modal-add-storage-v-2
			v-if="selectedLocation"
			ref="modal-add-storage"
			type="ADD"
			:selectLocationInfo="selectedLocation.info"
			:locationId="selectedLocation.info.id"
			@refresh="refreshData"
		></modal-add-storage-v-2> -->
		<modal-add-storage-v-2
			v-if="selectedLocation"
			ref="modal-edit-storage"
			:selectLocationInfo="selectedLocation.info"
			:locationId="selectedLocation.info.id"
			@refresh="refreshData"
		></modal-add-storage-v-2>
		<!-- 모달 : End -->
	</div>
</template>

<script>
import LmInputSearch from '@/views/common/components/input/LmInputSearch.vue'
import InstHeader from '@/views/institute/components/InstHeader'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import StorageItem from '@/views/institute/location/components/StorageItem.vue'
import { mapActions, mapState } from 'vuex'
import ModalAddChildLocation from '@/views/institute/location/modals/ModalAddChildLocation.vue'
import ModalDeleteLocation from '@/views/institute/location/modals/ModalDeleteLocation.vue'
import ModalAddUsingGroup from '@/views/institute/organization/modals/ModalAddGroupAuth.vue'
import locationAPI from '@/services/api/institute/location'
import ModalAddStorageV2 from '@/views/institute/location/modals/ModalAddStorageV2.vue'
import storageAPI from '@/services/api/institute/storage'
import { ToastType } from '@/utils/define/ToastType'
import NoDataList from '@/views/common/components/NoDataList.vue'

export default {
	name: 'LocationPage',
	components: {
		InstHeader,
		'location-tree-view': TreeView,
		LmInputSearch,
		StorageItem,
		ModalAddChildLocation,
		ModalDeleteLocation,
		ModalAddUsingGroup,
		ModalAddStorageV2,
		NoDataList,
	},
	data() {
		return {
			instituteId: '',
			showBoxShadow: false,
			keyword: '',
			treeData: [],
			searchTreeData: true,
			type: null,
		}
	},
	async created() {
		this.refreshData(this.selectedLocationId)
	},
	watch: {},
	computed: {
		...mapState('user', ['institute']),
		...mapState('location', ['selectedLocation', 'selectedLocationId', 'locationTree']),
		usingGroups() {
			return this.selectedLocation.groups.map(item => item.name).join(', ')
		},
		hasGroups() {
			return this.selectedLocation.groups.length > 0
		},
		hasStorages() {
			return this.selectedLocation.storages.length > 0
		},
		groupName() {
			return this.selectedLocation.info.name
		},
	},
	methods: {
		...mapActions('location', ['loadLocationTree', 'loadLocationDetail']),
		async refreshData(locationId) {
			this.instituteId = this.$route.params.instituteId
			await this.loadLocationTree(this.instituteId)
			this.treeData = this.$root.deepCopy(this.locationTree)

			const locations = locationId ? locationId : this.treeData[0].id

			const detailPayload = {
				institutionId: this.instituteId,
				locationId: locations,
			}
			await this.loadLocationDetail(detailPayload)
			this.$refs['location-tree-view'].setActiveId(locations)
			console.log(this.selectedLocation)
		},
		handleScroll() {
			const scrollTop = this.$refs['chart-main-container'].scrollTop
			this.showBoxShadow = scrollTop !== 0 ? true : false
		},
		async changeActiveId(locationId) {
			const detailPayload = {
				institutionId: this.instituteId,
				locationId: locationId,
			}
			await this.loadLocationDetail(detailPayload)
			this.$refs['location-tree-view'].setActiveId(locationId)
		},
		changeTree(node, parentNode) {
			if (this.userPermission.institute && this.userPermission.location.update) {
				locationAPI.updateLocation(this.instituteId, {
					parentId: parentNode.id,
					id: node.id,
				})
			} else {
				this.$root.toast('권한 없음', '권한이 없습니다.', 'error')
				this.refreshData(this.selectedLocation.info.id)
			}
		},
		searchTree(keyword) {
			this.keyword = keyword
		},
		showModalAddChildLocation(type) {
			this.$refs['modal-add-child-location'].show(type)
		},
		showModalDeleteLocation() {
			this.$refs['modal-delete'].show('location')
		},
		showModalAddUsingGroup() {
			const modalUsingGroup = this.$refs['modal-add-using-group']
			modalUsingGroup.show(
				'LOCATION',
				this.selectedLocation.groups.map(group => group.id)
			)
		},
		hideModalAddUsingGroup(list) {
			this.$refs['modal-add-using-group'].hide()
			storageAPI
				.addUsingGroup(this.instituteId, {
					locationId: this.selectedLocation.info.id,
					groupsId: list.map(item => item.id),
				})
				.then(() => {
					this.refreshData(this.selectedLocation.info.id)
				})
		},
		showModalDeleteStorage(data) {
			this.$refs['modal-delete'].show('storage', data)
		},
		showModalEditStorage(data, mode) {
			let type
			if (mode) {
				type = mode
			} else {
				type = 'ADD'
			}

			this.$refs['modal-edit-storage'].show(data, type)
		},
		async moveStorage(emitData) {
			const { storageItem, locationId } = emitData
			const response = await storageAPI.updateStorage(this.instituteId, {
				id: storageItem.id,
				locationId: locationId,
			})
			this.showSuccessStorageMoveToast()
			this.refreshData(this.selectedLocation.info.id)
		},
		showSuccessStorageMoveToast() {
			const title = this.tt('보관함 이동 완료')
			const content = this.tt('보관함이 이동하였습니다.')
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
		},
		withResult(result) {
			this.searchTreeData = result
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_location-storage.scss';
@import '@/styles/scss/institution/_organization.scss';
@import '@/styles/scss/global/_storage-box.scss';

#Location {
	width: 100%;
	height: 100%;
	border-left: solid 1px rgba(228, 229, 234, 1);
	overflow-y: auto;
}

.LocationPage__page-container {
	display: block;
}

.LocationStorage {
	overflow: hidden;
	height: 100%;
	// height: calc(100% - 60px) !important;
}

.Location__content-container {
	display: flex !important;
	height: calc(100% - 55px);
}

.Location__chart-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-width: 390px;
	height: 70px;
	padding: 20px 30px;
	&.shadow {
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	}
}

.bold {
	font-weight: 700;
	font-size: 14px;
}

.ml-10 {
	margin-left: 10px;
}

.Location__main-container {
	display: block;
	position: relative;
	width: 100%;
	height: calc(100% - 70px);
	overflow: scroll;
	user-select: none;
	padding-left: 20px;
}

.Location__content-left {
	width: 450px;
	height: 100%;
}

.Location__content-right {
	height: 100%;
}

.Location__button-arrow {
	margin-left: 10px;
	cursor: pointer;
}

.StorageBox__item {
	margin-bottom: 20px;
}
</style>
