<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			{{ tt('정기결제 관리') }}
		</template>
		<template v-if="beingSubscription">
			<div class="license-select-wrapper">
				<div class="license-summary">
					<img class="imgbox" src="@/assets/images/lm-pro-icon.png" />
					<div class="txtbox">
						<h6>{{ licenseTypeText }}</h6>
						<p v-if="isEducation">
							{{ tt('${1}원 / 연구실당 (연 기준 ・ VAT 별도)', formatNumberLocale(licenseRatePlan['YEAR']['EDUCATION'])) }}
						</p>
						<p v-else>
							{{ tt('${1}원 / 라이선스 당(월 기준 ・ VAT 별도)', formatNumberLocale(getSubscribePrice)) }}
						</p>
					</div>
				</div>
				<button class="button-red" @click="$refs['modal-cancel'].show()">{{ tt('졍기결제 해제') }}</button>
				<div class="border-top">
					<!-- <p>{{ tt('현재') }}</p> -->
					<div class="flex" v-if="licenseManagement.subscription.errorCode != null">
						<p>{{ tt('상태') }}</p>
						<div class="status">
							<span class="license-status expired">
								{{ tt('결제 불가') }}
							</span>
						</div>
					</div>
					<!-- <div class="flex">
						<p>{{ tt('멤버 수 / 라이선스') }}</p>
						<span class="license-member">
							<span
								class="license-now-member"
								:class="{
									red: instInfo.allMemberCount >= license.quantity,
								}"
							>
								{{ formatNumberLocale(instInfo.allMemberCount) }}&nbsp;
							</span>
							<span class="license-quantity">
								{{ ' / ' + (license.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(license.quantity)) }}
							</span>
						</span>
					</div> -->
					<div class="flex">
						<p>{{ tt('라이선스 적용기간') }}</p>
						<span>
							{{ licenseManagement.subscription.period }}
						</span>
					</div>
					<div class="flex">
						<p>{{ tt('라이선스 적용일수') }}</p>
						<span> {{ licenseManagement.subscription.periodDaysCount }}일 </span>
					</div>
				</div>
				<div class="license-price">
					<div class="flex">
						<p>{{ tt('소계') }}</p>
						<span> ￦{{ formatNumberLocale(licenseManagement.subscription.price) }} </span>
					</div>
					<div class="flex">
						<p>VAT(10%)</p>
						<span> ￦{{ formatNumberLocale(licenseManagement.subscription.vat) }} </span>
					</div>
					<div class="flex">
						<h3>{{ tt('합계') }}</h3>
						<h2>￦{{ formatNumberLocale(licenseManagement.subscription.totalPrice) }}</h2>
					</div>
				</div>
				<div class="license-payment">
					<h5>{{ tt('결제수단') }}</h5>
					<div class="flex">
						<p>카드번호</p>
						<span>
							<b>{{ licenseManagement.subscription.card.name }} **** **** **** {{ licenseManagement.subscription.card.number }}</b>
						</span>
					</div>
					<div class="flex">
						<p>결제 예정일</p>
						<span>{{ licenseManagement.subscription.paymentDate }}</span>
					</div>
					<div class="flex" v-if="false">
						<p>라이선스 수</p>
						<span>
							{{
								licenseManagement.subscription.quantity == 99999 ? '무제한' : licenseManagement.subscription.quantity.toLocaleString()
							}}
							<span class="blue add" @click="$refs['ModalEditQuantity'].show()">
								{{ tt('변경') }}
							</span>
						</span>
					</div>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<button v-if="isLicenseActivate" class="button-primary" @click="change">
				{{ tt('결제카드 변경') }}
			</button>
			<button v-else class="button-primary" @click="purchase">
				{{ tt('결제카드 변경 후 결제') }}
			</button>
		</template>
		<ModalCard ref="ModalCard" />
		<modal-confirm
			ref="modal-cancel"
			:title="tt('졍기결제 해제')"
			:content="tt('정말로 정기결제를 해제하시겠습니까?')"
			:confirmText="tt('해제')"
			variant="warning"
			@confirm="cancelSubscribe"
		></modal-confirm>
		<ModalPrompt
			type="number"
			:defaultValue="licenseManagement.subscription.quantity"
			ref="ModalEditQuantity"
			title="정기결제 라이선스 수 변경"
			label="변경할 라이선스 수"
			@confirm="editQauntity"
			isRequired
			confirmText="변경"
		>
		</ModalPrompt>
		<modal-confirm
			ref="modalEditConfirm"
			title="정기결제 라이선스 수 변경"
			:content="tt('정말로 라이선스 수를 변경하시겠습니까?<br />다음 결제일 부터 바뀐 라이선스 수가 적용됩니다.')"
			:confirmText="tt('변경')"
		></modal-confirm>
	</b-modal>
</template>
<script>
import ModalCard from './ModalCard.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
import ModalConfirm from '@/views/common/components/modalGen2/ModalConfirm.vue'
import ModalPrompt from '@/views/common/components/modalGen2/ModalPrompt.vue'
export default {
	components: {
		ModalCard,
		ModalConfirm,
		ModalPrompt,
	},
	mixins: [MixinLicense, MixinPayment],
	computed: {
		latestLicense() {
			return this.licenseManagement.subscription.latestLicense
		},
		licenseTypeText() {
			return this.licenseType2Text(this.latestLicense.type)
		},
		getSubscribePrice() {
			if (this.latestLicense.month >= 12) {
				return this.licenseRatePlan['YEAR'][this.latestLicense.type]
			} else return this.licenseRatePlan['MONTH'][this.latestLicense.type]
		},
	},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		change() {
			this.$refs['ModalCard'].show('EDIT', async cardId => {
				await this.updateSubscribe({ cardId: cardId })
			})
		},
		purchase() {
			this.$refs['ModalCard'].show('EDIT', async cardId => {
				await this.createSubscribe(cardId)
			})
		},
		async cancelSubscribe() {
			try {
				await this.unsubscribeLicense()
				this.$root.toast('정기결제 해제', '정기결제가 해제되었습니다.', 'success')
				await this.loadLicense()
				await this.loadLicenseManagement()
				this.hide()
			} catch (e) {
				console.log(e)
				this.$root.toast('해제 실패', e?.response?.data?.msg ?? '해제에 실패하였습니다. 잠시 후 다시 시도해주세요', 'error')
			}
		},
		editQauntity(value) {
			if (value > 0) {
				this.$refs['modalEditConfirm'].show(() => {
					this.updateSubscribe({ quantity: Number(value) })
				})
			} else {
				this.$root.toast('변경 실패', '라이선스 수를 정확히 입력해주세요.', 'error')
			}
		},
		async updateSubscribe({ quantity, cardId }) {
			try {
				await this.updateLicenseSubscription({ quantity, cardId: cardId ?? this.defaultCreditCard.id })
				this.$root.toast('정기결제 변경', '정기결제가 변경되었습니다.', 'success')
				await this.loadLicense()
				await this.loadLicenseManagement()
			} catch (e) {
				console.log(e)
				this.$root.toast('변경 실패', e?.response?.data?.msg ?? '변경에 실패하였습니다. 잠시 후 다시 시도해주세요', 'error')
			}
		},
		async createSubscribe(cardId) {
			try {
				// 카드로 베이직 구매
				await this.purchaseLicenseBasic({
					isSubscribe: true,
					cardId: cardId,
					quantity: this.latestLicense.quantity,
					month: this.latestLicense.month,
					plan: this.latestLicense.type,
					paymentMethod: this.paymentMethod.CARD_REGISTERED,
				})
				this.$emit('complete')
				this.hide()
			} catch (e) {
				console.log(e)
				this.$root.toast('결제 실패', e?.response?.data?.msg ?? '결제에 실패하였습니다. 결제정보를 다시 확인해주세요', 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-purchase.scss';
::v-deep {
	.modal-body {
		padding-top: 0px;
		.license-select-wrapper {
			margin-top: 0px;
			border-top: none;
			.license-summary {
				border-bottom: none;
			}
			.border-top {
				border-top: 1px solid $INPUT;
			}
			.button-red {
				align-items: center;
				justify-content: center;
				border-radius: 5px;
				margin-bottom: 20px;
				font-size: 14px;
				font-weight: 700;
				background: linear-gradient(0deg, rgba(255, 87, 70, 0.2) 0%, rgba(255, 87, 70, 0.2) 100%), #fff;
				color: $RED;
				width: 100%;
				height: 48px;
				&:hover {
					opacity: 0.8;
				}
			}
		}

		.status {
			margin-left: auto;
			display: flex;
			align-items: center;
			span {
				margin-left: 5px;
				font-size: 14px;
				font-weight: 400;
				&.license-status {
					height: 24px;
					border: 1px solid #b5e7d2;
					border-radius: 5px;
					display: inline-flex;
					font-size: 12px;
					font-weight: 700;
					align-items: center;
					padding: 0 5px;
					background-color: #f4fffa;
					color: $GREEN;
					img {
						margin-right: 2px;
					}
					&.expired {
						font-size: 12px;
						font-weight: 700;
						border: 1px solid #ffdad7;
						border-radius: 5px;
						color: #ff5746;
						background-color: #fff5f5;
						img {
							content: url(~@/assets/svg/sign-warning.svg);
						}
					}
				}
				&.waiting-status {
					padding: 0 5px;
					height: 24px;
					display: flex;
					background: #fff3ed;
					border: 1px solid #ffb999;
					color: #ff8248;
					border-radius: 5px;
					font-weight: 700;
					align-items: center;
					font-size: 12px;
					margin-left: 5px;
				}
			}
		}
	}
}
</style>
