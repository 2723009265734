var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-list-wrapper"
  }, [_c('ul', {
    ref: "scroll-container",
    staticClass: "search-list",
    on: {
      "scroll": _vm.onScroll
    }
  }, _vm._l(_vm.searchList, function (item) {
    return _c('li', {
      key: "searchIngredient-".concat(Math.random(), "-").concat(item.name),
      staticClass: "search-item",
      on: {
        "mousedown": function mousedown($event) {
          return _vm.clickItem(item);
        }
      }
    }, [_c('div', {
      staticClass: "item-content"
    }, [_c('div', {
      staticClass: "name",
      domProps: {
        "innerHTML": _vm._s(_vm.className === 'name' ? _vm.setHighlight(item.name) : item.name)
      }
    }), _c('div', {
      staticClass: "casno",
      domProps: {
        "innerHTML": _vm._s(_vm.className === 'casno' ? _vm.setHighlight(item.casno) : item.casno)
      }
    })])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }