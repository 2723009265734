<template>
	<b-dropdown ref="dropdown" v-click-outside="hide" no-caret toggle-class="text-decoration-none" variant="link" right>
		<template #button-content>
			<img src="@/assets/svg/excel-download.svg" />
			<div class="btn-text" v-if="text">{{ tt(text) }}</div>
			<div class="btn-subtext" v-if="subText">{{ tt(subText) }}</div>
		</template>
		<b-dropdown-item href="#" @click="downloadAll">
			<img src="@/assets/svg/all-list.svg" />
			<div>
				<h6>{{ tt('전체 목록 다운로드') }}</h6>
				<p>
					{{ tt('전체 목록을 엑셀파일로 다운로드합니다.') }}
				</p>
			</div>
		</b-dropdown-item>
		<b-dropdown-item href="#" @click="downloadFiltered">
			<img src="@/assets/svg/filter-list.svg" />
			<div>
				<h6>{{ tt('필터링된 목록 다운로드') }}</h6>
				<p>
					{{ tt('필터가 적용된 목록을 엑셀파일로 다운로드합니다.') }}
				</p>
			</div>
		</b-dropdown-item>
		<ModalLoading ref="ModalLoading" noMsg title="엑셀 파일 다운로드 중입니다." />
	</b-dropdown>
</template>
<script>
import ModalLoading from '@/views/common/components/modalGen2/ModalLoading.vue'
export default {
	components: {
		ModalLoading,
	},
	props: {
		text: {
			type: String,
			default: '목록 다운로드',
		},
		subText: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
		}
	},
	watch: {
		isLoading(val) {
			if (val) this.$refs['ModalLoading'].show()
			else {
				this.$refs['ModalLoading'].hide()
			}
		},
	},
	methods: {
		downloadAll() {
			this.$emit('downloadAll')
		},
		downloadFiltered() {
			this.$emit('downloadFiltered')
		},
		hide() {
			this.$refs['dropdown'].hide()
		},
		setLoading(val) {
			this.isLoading = val
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.text-primary {
		color: $LAB_YELLOW !important;
		width: 20px;
		height: 20px;
		margin-top: 4px;
	}
	.dropdown-menu {
		padding: 5px;
		border-radius: 5px;
	}
	.dropdown-item {
		display: flex;
		align-items: center;
		padding: 5px;
		border-radius: 5px;
		img {
			margin-right: 10px;
		}
		h6 {
			font-size: 0.875rem;
			font-weight: 700;
			color: #000;
		}
		p {
			font-size: 0.75rem;
			font-weight: 400;
		}
		&:hover,
		&:active {
			background-color: $GREY_2;
			border-radius: 4px;
		}
	}
	.btn {
		display: flex;
		background-color: $GREY_4;
		border-radius: 4px;
		height: 30px;
		padding: 5px 10px;
		font-size: 14px;
		font-weight: 700;
		gap: 5px;
		align-items: center;
		text-align: center;
		justify-content: center;
		&:focus {
			box-shadow: none;
		}
	}
}
</style>
