b
<template>
	<modal-view-option ref="modal" @confirm="confirm">
		<template #modal-content>
			<div class="list-wrapper">
				<div class="title-wrapper">
					<h5>{{ tt('조회 항목') }}</h5>
					<button @click="checkedAll">{{ tt('전체선택') }}</button>
				</div>
				<div class="check-list">
					<label class="check-item" v-for="item in viewOptionList" :key="item.id">
						<input
							class="checkbox"
							type="checkbox"
							:disabled="item.isRequire"
							@change="checked(item.id)"
							:checked="checkedList.includes(item.id)"
						/>
						{{ tt(item.name) }}
					</label>
				</div>
			</div>
		</template>
	</modal-view-option>
</template>
<script>
import ModalViewOption from '@/views/common/components/modalGen2/ModalViewOptionBase.vue'
import { mapMutations, mapState } from 'vuex'

export default {
	components: {
		ModalViewOption,
	},
	computed: {
		...mapState('inventoryGen2/history', ['viewOptionList', 'checkedViewOption', 'viewOptionCookieName']),
	},
	data() {
		return {
			checkedList: [],
		}
	},
	methods: {
		...mapMutations('inventoryGen2/history', ['setViewOptions']),
		show() {
			this.checkedList = this.$_.cloneDeep(this.checkedViewOption)
			if (this.checkedList.length == 0) this.checkedList = this.viewOptionList.filter(it => it.default != false).map(it => it.id)
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		confirm() {
			this.setViewOptions(this.checkedList)
			this.$refs['modal'].hide()
			this.$root.toast('보기옵션', '보기옵션이 저장되었습니다.', 'success')
		},
		checkedAll() {
			this.checkedList = this.viewOptionList.map(it => it.id)
		},
		checked(id) {
			if (this.checkedList.includes(id)) this.checkedList.remove(id)
			else this.checkedList.push(id)
		},
	},
}
</script>

<style scoped lang="scss">
.list-wrapper {
	// padding: 25px 20px;
	.title-wrapper {
		display: flex;
		align-items: center;
		h5 {
			font-size: 14px;
			color: $SECONDARY_2;
		}
		button {
			margin-left: auto;
			color: $BLUE;
			font-size: 14px;
			font-weight: 700;
		}
	}
	.check-list {
		width: 100%;
		margin-top: 10px;
		display: inline-flex;
		flex-wrap: wrap;
		gap: 10px;
		.check-item {
			cursor: pointer;
			padding-left: 2px;
			align-items: center;
			display: flex;
			gap: 10px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-size: 14px;
			flex-basis: calc((100% - 10px) / 2);
		}
	}
}
</style>
