<template>
	<form-fold-management
		title="관리정보"
		ref="form-fold-management"
		:categoryInfo="categoryInfo"
		:memberList="getMemberList.members"
		:productInfo="item"
		:expirationDay="expirationDay"
		:isManagementValid="isManagementValid"
		:isUnitValid="isManagementUnitValid"
		:isShowError="showError"
		@change="changeFormManagement"
		@removeImg="removeImage"
	></form-fold-management>
</template>
<script>
import FormFoldManagement from '@/views/common/components/form/FormFoldManagement.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	name: 'ItemFormManagement',
	components: { FormFoldManagement },
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/form', [
			'categoryInfo',
			'item',
			'isActiveExpireDate',
			'expirationDay',
			'isManagementValid',
			'isManagementUnitValid',
			'showError',
		]),
		...mapGetters('member', ['getMemberList']),
	},
	watch: {},
	created() {
		this.lookupMemberList()
	},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/form', ['setFormItem']),
		...mapMutations('tag', ['setTagList']),
		...mapActions('member', ['lookupMemberList']),
		...mapActions('modal/ghs', ['getGhsStatement']),
		changeFormManagement(value, fieldKey, items) {
			const newItem = { ...this.$_.cloneDeep(this.item) }
			if (typeof value === 'object') {
				switch (fieldKey) {
					case 'tag':
						newItem[`${fieldKey}sId`] = value ? value : ''
						break
					case 'storage':
					case 'assignee':
					case 'buyer':
						newItem[`${fieldKey}Id`] = value?.id
						newItem[fieldKey] = value
						break
					case 'imageList':
					case 'image':
					case 'images':
						newItem['images'] = value
						break
					case 'qrSticker':
						newItem['qrSticker'] = value ? value : ''
						break
					default:
						newItem[`${fieldKey}`] = value?.value ?? null
						if (value?.unit) newItem[`${fieldKey}Unit`] = value?.unit
						break
				}
				this.setFormItem(newItem)
			} else {
				// if (fieldKey == 'openDatetime' && this.isActiveExpireDate && value) {
				// 	newItem['expireDatetime'] = this.$DateTime.fromISO(value).plus({ days: this.expirationDay }).toISODate()
				// }
				newItem[fieldKey] = value ?? null
				this.setFormItem(newItem)
			}
		},
		removeImage(removeImageIds) {
			const newItem = { ...this.item }
			console.log(removeImageIds)
			newItem['deleteImages'] = removeImageIds
			this.setFormItem(newItem)
		},
	},
}
</script>

<style lang="scss" scoped></style>
