<template>
	<div class="lm-table-wrapper">
		<bundleTable v-show="selectedBundleOption != 'none'" :previousPage="previousPage" />
		<table class="lm-table" ref="inventory-table">
			<table-header />
			<table-item v-for="item in list" :key="item.id" :item="item" @click="goToDetail" :keyword="loadParams.keyword" />
			<div class="empty-table" v-if="count === 0">
				<img class="empty-img" :src="emptyImage" />
				<p class="bold">{{ tt(emptyText) }}</p>
				<p class="sub" v-html="emptySubText"></p>
			</div>
		</table>
	</div>
</template>
<script>
import PageName from '@/utils/define/PageName'
import bundleTable from './bundleTable.vue'
import TableHeader from './header.vue'
import TableItem from './item.vue'
import { mapState, mapMutations } from 'vuex'
export default {
	components: {
		bundleTable,
		TableHeader,
		TableItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		previousPage: {
			type: Object,
			default: () => {},
		},
	},
	mounted() {
		console.log(2, this.previousPage)
		if (this.previousPage?.name === PageName.InventoryGen2.Detail) this.$refs['inventory-table'].scrollTo(0, this.scrollHeight)
	},
	computed: {
		...mapState('inventoryGen2/list', ['count', 'loadParams', 'selectedBundleOption']),
		...mapState('scroll', ['scrollHeight']),
		emptyImage() {
			return this.loadParams.keyword || this.isOnFilter
				? require('@/assets/svg/no-data_search.svg')
				: require('@/assets/svg/empty/inventory-empty-data.svg')
		},
		emptyText() {
			return this.loadParams.keyword || this.isOnFilter ? '검색 결과가 없습니다' : '등록된 물품이 없습니다'
		},
		emptySubText() {
			return this.loadParams.keyword || this.isOnFilter
				? this.tt('물품 이름, 카테고리, 브랜드, 제품번호, CAS No,<br />담당자로 검색할 수 있습니다.')
				: this.tt('물품을 등록해보세요')
		},
		isOnFilter() {
			return (
				this.loadParams.registerMethods.length > 0 ||
				this.loadParams.status.length > 0 ||
				this.loadParams.categoriesId.length > 0 ||
				this.loadParams.brandsId.length > 0 ||
				this.loadParams.storagesId.length > 0 ||
				this.loadParams.legalsId.length > 0 ||
				this.loadParams.currentVolume.length > 0 ||
				this.loadParams.threshold.length > 0 ||
				this.loadParams.expireDate.length > 0
			)
		},
	},
	methods: {
		...mapMutations('scroll', ['setScrollHeight']),
		goToDetail(id) {
			const { scrollTop } = this.$refs['inventory-table']
			this.setScrollHeight(scrollTop)
			this.$router.push({
				name: 'InventoryDetailGen2',
				params: {
					id: id,
				},
			})
		},
		moveScrollTop() {
			this.setScrollHeight(0)
			this.$refs['inventory-table'].scrollTo(0, this.scrollHeight)
		},
	},
}
</script>
<style lang="scss" scoped>
.lm-table-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
}

.lm-table {
	position: relative;
	height: 100%;
	text-align: left;
	display: block;
	overflow: overlay;
	white-space: nowrap;
	border-spacing: 0;
	border-collapse: separate;
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: $SECONDARY_2;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		text-align: center;
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
