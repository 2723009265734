<template>
	<tr>
		<td>
			<button class="checkbox" @click="checkBoxClick" :class="{ active: isClick }"></button>
		</td>
		<td v-if="headers[0].value" @click="detail(item)">
			<div class="state" :class="item.status != 'OK' ? 'warning' : ''">
				<span style="display: inline-flex; align-items: center">
					<img v-if="item.status == 'OK'" src="@/assets/svg/icon-safe.svg" alt="상태icon" />
					<img v-else src="@/assets/svg/health-warning.svg" alt="상태icon" />
				</span>
				<p v-text="item.status == 'OK' ? tt('안전') : tt('위험')"></p>
			</div>
		</td>
		<td v-if="headers[1].value" @click="detail(item)">
			<div class="info">
				<img :src="item.user.image ? item.user.image : require('@/assets/svg/member-photo-default.svg')" />
				<div>
					<h6>{{ item.user.name }}</h6>
					<p>{{ item.user.email }}</p>
				</div>
			</div>
		</td>
		<td v-if="headers[2].value" @click="detail(item)">
			<span v-if="item.groupList.length == 1">{{ item.groupList[0].name }}</span>
			<span v-else-if="item.groupList.length > 1"
				>{{ item.groupList[0].name }} {{ tt('외') }} {{ item.groupList.length - 1 }} {{ tt('개 그룹') }}</span
			>
		</td>
		<td v-if="headers[3].value" @click="detail(item)">
			<p :class="item.lastDiagDate ? '' : 'nodata-txt'" v-text="item.lastDiagDate ? formatDate(item.lastDiagDate) : tt('정보없음')"></p>
		</td>
		<td v-if="headers[4].value" @click="detail(item)">
			<p v-if="!item.nextDiagDate" style="display: inline" class="nodata-txt">정보없음</p>
			<p v-if="item.status == 'OK' && item.nextDiagDate" style="display: inline-block">{{ tt('${1}까지', formatDate(item.nextDiagDate)) }}</p>
			<p v-if="item.status == 'DANGER' && item.nextDiagDate" class="error">
				<img src="@/assets/svg/icon-error.svg" alt="icon" />{{ tt('${1}까지', formatDate(item.nextDiagDate))
				}}<span v-if="progessDate(item.nextDiagDate) > 0">({{ tt('${1}일 경과', progessDate(item.nextDiagDate)) }})</span>
			</p>
		</td>
		<td v-if="headers[5].value && userPermission.safe && userPermission.safe.diagnosis.create">
			<button class="btn input-checkup" @click="showInput"><img src="@/assets/svg/icon-edit.svg" alt="icon" />{{ tt('검진일 입력') }}</button>
		</td>
	</tr>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'HealthItem',
	props: {
		instId: {
			type: String,
			default: null,
		},
		item: {
			type: Object,
			default: null,
		},
		allCheck: {
			type: Boolean,
			default: false,
		},
		headers: {
			type: Array,
			default: [],
		},
		offset: {
			type: Number,
			default: 0,
		},
		length: {
			type: Number,
			default: 0,
		},
	},
	created() {
		this.today = new Date()
	},
	watch: {
		allCheck: function () {
			if (this.allCheck) {
				this.isClick = true
			} else {
				this.isClick = false
			}
		},
		isClick: function () {
			if (this.isClick) {
				this.$emit('checkId', this.item.user.id)
			} else {
				this.$emit('uncheckId', this.item.user.id)
			}
		},
		checkList: function () {
			if (this.checkList == 0) {
				this.isClick = false
			}
		},
	},
	data() {
		return {
			isClick: false,
			today: '',
		}
	},
	methods: {
		checkBoxClick() {
			if (this.isClick) {
				this.isClick = false
			} else {
				this.isClick = true
			}
		},
		formatDate(date) {
			if (date) {
				return this.$DateTime.fromISO(date).toISODate()
			}
			return '-'
		},
		progessDate(date) {
			if (date) {
				let diffDays = this.$DateTime.now().diff(this.$DateTime.fromISO(date), 'days')
				console.log(diffDays.toObject())
				return Math.floor(diffDays.toObject().days)
			}
		},
		detail(item) {
			this.$router.push({
				name: PageName.Safety.HealthCheckDetail,
				params: {
					id: this.item.user.id,
				},
			})
		},
		showInput() {
			this.$emit('inputDiagnosis', this.item.user.id)
		},
	},
}
</script>

<style lang="scss" scoped>
tr {
	td {
		.state {
			&.safe {
				color: #57c17e;
			}
			&.error {
				color: $RED;
			}
		}
		.nodata-txt {
			color: $PLACEHOLDER;
		}
		.info {
			cursor: pointer;
			text-align: left;
			display: flex;
			img {
				display: inline-block;
				width: 40px;
				height: 40px;
				border-radius: 8px;
				overflow: hidden;
				object-fit: cover;
			}
			div {
				padding-left: 10px;
				h6 {
					font-size: 1rem;
					color: #000;
					font-weight: bold;
					display: block;
					> span {
						font-weight: normal;
						padding-left: 2px;
						display: inline-block;
					}
				}
				p {
					font-size: 0.75rem;
					color: $PLACEHOLDER;
					font-weight: 400;
				}
			}
		}
		> p {
			display: flex;
			align-items: center;
			height: auto;
			img {
				margin-right: 5px;
			}
			&.error {
				color: $RED;
			}
			&.no-data {
				color: $PLACEHOLDER;
			}
		}
	}
}
.input-checkup {
	margin-left: 5px;
}
</style>
