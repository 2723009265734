import axiosInstance from '@/services/axios'

const baseStoreURL = instituteId => `/institute/${instituteId}/store/tunnel/mall`

const endPoint = {
	lookupRegistration: (instId, lmsInstId) => `${baseStoreURL(instId)}/institute/${instId}/businessRegistration/list`,
	addRegistration: (instId, lmsInstId) => `${baseStoreURL(instId)}/institute/${instId}/businessRegistration`,
	deleteRegistration: (instId, lmsInstId, id) => `${baseStoreURL(instId)}/institute/${instId}/businessRegistration?businessRegistrationId=${id}`,
}

const businessRegistrationAPI = {
	lookupRegistration: (instId, lmsInstId) => axiosInstance.get(endPoint.lookupRegistration(instId, lmsInstId)),
	addRegistration: (instId, lmsInstId, formData) =>
		axiosInstance.post(endPoint.addRegistration(instId, lmsInstId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	deleteRegistration: (instId, lmsInstId, id) => axiosInstance.delete(endPoint.deleteRegistration(instId, lmsInstId, id)),
}

export default businessRegistrationAPI
