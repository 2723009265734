var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pageHeader"
  }, [_c('button', {
    on: {
      "click": _vm.goPrev
    }
  }, [_vm._v(_vm._s(_vm.tt('이전으로')))]), _c('h6', [_vm._v(_vm._s(_vm.tt(_vm.title)))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }