<template>
	<tr class="table-item" :class="{ new: isNew }" @click="goToDetail(item.id)">
		<td>
			<span class="checkbox" @click.stop>
				<input type="checkbox" v-model="isChecked" />
			</span>
		</td>
		<!-- 입고 상태 -->
		<td>
			<div class="status" :class="{ complete: isComplete }">
				<p>{{ tt(statusText) }}</p>
				<p v-if="isNeedInfo" class="need-info" @click.stop="$emit('needInputCargoInfo', item)">{{ tt('정보입력 필요') }}</p>
			</div>
		</td>
		<!-- 수량 -->
		<td v-if="isView('multiCreateCount')">
			<div class="count-wrap" ref="countWrap">
				<button
					class="divide-button"
					:class="{ disabled: isCannotDivide }"
					:id="`divideCounter${item.id}`"
					@click.stop="isShowCounter = !isShowCounter"
				>
					{{ tt('분리') }}
				</button>
				<div class="count">{{ formatNumber(item.multiCreateCount) }}{{ tt('개') }}</div>
				<b-popover class="cargo-popover" :target="`divideCounter${item.id}`" placement="bottom" ref="popover" triggers="click blur">
					<popover-divide-counter
						ref="counter"
						:max="item.multiCreateCount - 1"
						:min="1"
						:value="1"
						@close="$refs.popover.$emit('close')"
						@divide="divide"
					></popover-divide-counter>
				</b-popover>
			</div>
		</td>
		<!-- 위치/보관함 -->
		<td v-if="isView('location')">
			<div class="right">
				<span>{{ formatStorage(item.storage) }}</span>
			</div>
		</td>
		<!--  구매처 -->
		<td v-if="isView('registerMethod')">
			<div>
				<span>{{ tt(registerMethod(formatText(item.registerMethod))) }}</span>
			</div>
		</td>
		<!-- 정보 -->
		<td v-if="isView('name')">
			<div class="info-wrap" v-b-tooltip.hover="item.name">
				<p class="info-title">{{ formatText(item.name) }}</p>
				<p class="info-sub" v-if="item.brand || item.subname">
					{{ item.brand ? item.brand.name : '' }} |
					{{ item.subname ? item.subname : '' }}
				</p>
			</div>
		</td>
		<!-- 카테고리 -->
		<td v-if="isView('category')">
			<div class="category">
				<!-- <span>{{ formatCategory(item.categoryType) }}</span> -->
				<span>{{ formatText(item.category ? item.category.name : null) }}</span>
			</div>
		</td>
		<!-- 브랜드 -->
		<td v-if="isView('brand')">
			<div>
				<span>{{ formatBrand(item.brand) }}</span>
			</div>
		</td>
		<!-- 제품번호 -->
		<td v-if="isView('productCode')">
			<div>
				<span>{{ formatText(item.productCode) }}</span>
			</div>
		</td>
		<!-- 용량/패키지 수량	 -->
		<td v-if="isView('volume')">
			<div>
				<span>{{ volume }}</span>
			</div>
		</td>
		<!-- 배송정보 -->
		<td v-if="isView('shipStatus')">
			<div>
				<p>{{ shipStatus }}</p>
				<p class="expect-time">{{ formatExpectDatetime }}</p>
			</div>
		</td>
		<!-- 입고자 -->
		<td v-if="isView('register')">
			<div class="profile-wrap">
				<img class="profile-img" :src="formatProfileImg(item.register.image)" />
				<span>
					{{ formatText(item.register.name) }}
				</span>
			</div>
		</td>
		<!-- 입고일시 -->
		<td v-if="isView('completeDatetime')">
			<div>
				<span>{{ formatDatetimeFromISO(item.completeDatetime) }}</span>
			</div>
		</td>
		<!-- 목록추가일시 -->
		<td>
			<div>
				<span>{{ formatDatetimeFromISO(item.createDatetime) }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import PopoverDivideCounter from '@/views/purchase/cargo/components/cargoList/popover/PopoverDivideCounter.vue'
import MixinItem from '@/mixins/inventory/MixinItem'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import PageName from '@/utils/define/PageName'
import { ToastType } from '@/utils/define/ToastType'
import { LargeClass } from '@/utils/define/ItemCode'

export default {
	name: 'CargoTableItem',
	components: { PopoverDivideCounter },
	mixins: [MixinItem],
	props: {
		item: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			isShowDivideModal: false,
			isChecked: false,
			isShowCounter: false,
		}
	},
	watch: {
		isIncludeId(value) {
			this.isChecked = value
		},
		isChecked(value) {
			if (value) {
				if (!this.isIncludeId) {
					this.setSelectItem([...this.selectItem, this.item])
				}
			} else {
				this.setSelectItem(this.selectItem.filter(itemId => itemId.id !== this.item.id))
			}
		},
	},
	computed: {
		...mapState('cargo/list', ['checkedViewOption']),
		...mapGetters('cargo/list', ['selectItem', 'selectItemId']),
		isNeedInfo() {
			return !this.isComplete && !this.item.isReady
		},
		isComplete() {
			return this.item.status === 'COMPLETE'
		},
		statusText() {
			return {
				WAIT: '입고 대기',
				COMPLETE: '입고 완료',
			}[this.item.status]
		},
		shipStatus() {
			return {
				ING: '배송중',
				COMPLETE: '배송완료',
			}[this.item.shipStatus]
		},
		isCannotDivide() {
			return this.item?.multiCreateCount === 1 || this.item?.status === 'COMPLETE'
		},
		formatExpectDatetime() {
			if (this.item.shipArriveExpectDatetime || this.item.shipArriveDatetime) {
				return this.item.shipStatus === 'COMPLETE'
					? `${this.formatDateFromISO(this.item.shipArriveDatetime, 'M/d')}(${this.getWeekDayFromISO(this.item.shipArriveDatetime)}) 
				${this.tt('도착')}`
					: `${this.formatDateFromISO(this.item.shipArriveExpectDatetime, 'M/d')}(${this.getWeekDayFromISO(
							this.item.shipArriveExpectDatetime
					  )}) 
				${this.tt('도착예정')}`
			} else {
				return '-'
			}
		},
		isIncludeId() {
			return this.selectItemId.includes(this.item.id)
		},
		registerMethod() {
			return registerMethod => {
				return {
					DRAFT_LMS: '랩매니저 스토어',
					DRAFT_EXTERNAL: '외부 거래처',
					MANUAL_CARGO: '수기 등록',
					SVO: '서브원몰',
				}[registerMethod]
			}
		},
		volume() {
			return this.item.categoryType === LargeClass.ETC
				? this.formatVolume(this.item.currentVolume, this.item.volumeUnit)
				: this.formatVolume(this.item.volume, this.item.volumeUnit)
		},
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			if (this.isIncludeId) {
				this.isChecked = true
			}
		})
	},
	methods: {
		...mapMutations('cargo/list', ['setSelectItem']),
		...mapActions('cargo/list', ['divideCargoItem']),
		goToDetail() {
			this.setNew()
			this.$emit('click', this.item.id)
		},
		async divide(multiCreateCount) {
			if (this.isDivideDisabled) return
			await this.divideCargoItem({
				id: this.item.id,
				multiCreateCount: multiCreateCount,
			})
			this.$refs.popover.$emit('close')
			this.$root.toast('분리 완료', '입고항목을 분리하였습니다.', ToastType.SUCCESS)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-item.scss';
</style>
