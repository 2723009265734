import axiosInstance from '@/services/axios'

const endPoint = {
	// list - 물품 목록
	list: instId => `/institute/${instId}/inventory/list`,
	filter: instId => `/institute/${instId}/inventory/list/filter`,
	tagList: instId => `/institute/${instId}/tag/list`,
	exportExcelList: instId => `/institute/${instId}/inventory/list/export/excel`,
	favorite: instId => `/institute/${instId}/inventory/favorite`,
	// item - 상세
	getDetail: instId => `/institute/${instId}/inventory`,
	getWaitingDetail: instId => `/institute/${instId}/inspect`,
	uploadMsds: instId => `/institute/${instId}/safety/msds`,
	deleteMsds: instId => `/institute/${instId}/safety/msds`,
	useItem: instId => `/institute/${instId}/inventory/hold`,
	returnItem: instId => `/institute/${instId}/inventory/return`,
	consumeItem: instId => `/institute/${instId}/inventory/consume`,
	changeStorage: instId => `/institute/${instId}/inventory/storage`,
	removeItem: instId => `/institute/${instId}/inventory`,
	disposeItem: instId => `/institute/${instId}/inventory/dispose`,
	deleteInspectItem: instId => `/institute/${instId}/inspect`,
	editInspectItemUser: instId => `/institute/${instId}/inspect/user`,
	comment: instId => `/institute/${instId}/inventory/comment`,
	// history - 관리기록
	history: instId => `/institute/${instId}/inventory/log`,
	historyFilter: instId => `/institute/${instId}/inventory/log/filter`,
	removeHistory: instId => `/institute/${instId}/inventory/log`,
	checkRestore: instId => `/institute/${instId}/inventory/restore/check`,
	restoreItem: instId => `/institute/${instId}/inventory/restore`,
	exportExcelHistory: instId => `/institute/${instId}/inventory/log/export/excel`,
	// form - 등록
	registItemByImage: instId => `/institute/${instId}/inspect/image`,
	getMsds: instId => `/institute/${instId}/safety/msds`,
	registItem: instId => `/institute/${instId}/inventory`,
	registInspectItem: instId => `/institute/${instId}/inspect/inventory/register`,
	editInspectItem: instId => `/institute/${instId}/inspect/user`,
	editItem: instId => `/institute/${instId}/inventory`,
	editItemMultiple: instId => `/institute/${instId}/inventory/multiple`,
	downloadExcel: instId => `/institute/${instId}/inventory/excel/download`,
	checkExcel: instId => `institute/${instId}/inventory/excel/check`,
	validate: instId => `institute/${instId}/inventory/validate`,
	// setting - 카테고리 설정
	categoryList: instId => `/institute/${instId}/category/list`,
	category: instId => `/institute/${instId}/category`,
	mngcode: instId => `/institute/${instId}/mngcode`,
	editMngcode: instId => `/institute/${instId}/mngcode`,
	countCategory: instId => `/institute/${instId}/category/inventory/count`,
	addCategory: instId => `/institute/${instId}/category`,
	editCategory: instId => `/institute/${instId}/category`,
	deleteCategory: instId => `/institute/${instId}/category`,
	initializeCategory: instId => `/institute/${instId}/category/initialize`,
	copyCategory: instId => `/institute/${instId}/category/copy`,
	// 소분
	divideItem: instId => `/institute/${instId}/inventory/subdivide`,
	// wait - 등록대기중인
	inspect: instId => `/institute/${instId}/inspect/list`,
	inspectFilter: instId => `/institute/${instId}/inspect/list/filter`,
	removeInspect: instId => `/institute/${instId}/inspect`,
	// dispose - 폐기/삭제
	disposal: instId => `/institute/${instId}/inventory/dump/list`,
	disposalFilter: instId => `/institute/${instId}/inventory/dump/list/filter`,
	removeDisposal: instId => `/institute/${instId}/inventory/complete`,
	exportExcelDisposeList: instId => `/institute/${instId}/inventory/dump/list/export/excel`,
	paymentDown: instId => `/institute/${instId}/payment/ci/download`,

	bundleList: instituteId => `/institute/${instituteId}/inventory/bundle/list`,
}

const inventoryAPI = {
	paymentDown: (instId, body) => axiosInstance.post(endPoint.paymentDown(instId), body),
	list: (instId, params) => axiosInstance.get(endPoint.list(instId), { params }),
	filter: (instId, params) => axiosInstance.get(endPoint.filter(instId), { params }),
	history: (instId, params) => axiosInstance.get(endPoint.history(instId), { params }),
	historyFilter: instId => axiosInstance.get(endPoint.historyFilter(instId)),
	inspect: (instId, params) => axiosInstance.get(endPoint.inspect(instId), { params }),
	inspectFilter: instId => axiosInstance.get(endPoint.inspectFilter(instId)),
	getDetail: (instId, params) => axiosInstance.get(endPoint.getDetail(instId), { params: params }),
	uploadMsds: (instId, body) =>
		axiosInstance.post(endPoint.uploadMsds(instId), body, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	deleteMsds: (instId, params) => axiosInstance.delete(endPoint.deleteMsds(instId), { params: params }),
	useItem: (instId, body) => axiosInstance.patch(endPoint.useItem(instId), body),
	returnItem: (instId, body) => axiosInstance.patch(endPoint.returnItem(instId), body),
	consumeItem: (instId, body) => axiosInstance.patch(endPoint.consumeItem(instId), body),
	changeStorage: (instId, body) => axiosInstance.patch(endPoint.changeStorage(instId), body),
	addFavorite: (instId, params) => axiosInstance.post(endPoint.favorite(instId), params),
	deleteFavorite: (instId, params) => axiosInstance.delete(endPoint.favorite(instId), { params }),
	// setting
	categoryList: instId => axiosInstance.get(endPoint.categoryList(instId)),
	category: (instId, params) => axiosInstance.get(endPoint.category(instId), { params }),
	mngcode: instId => axiosInstance.get(endPoint.mngcode(instId)),
	editMngcode: (instId, body) => axiosInstance.put(endPoint.editMngcode(instId), body),
	countCategory: (instId, params) => axiosInstance.get(endPoint.countCategory(instId), { params }),
	addCategory: (instId, params) => axiosInstance.post(endPoint.addCategory(instId), params),
	editCategory: (instId, params) => axiosInstance.put(endPoint.editCategory(instId), params),
	deleteCategory: (instId, params) => axiosInstance.delete(endPoint.deleteCategory(instId), { params }),
	initializeCategory: (instId, params) => axiosInstance.put(endPoint.initializeCategory(instId), params),
	copyCategory: (instId, params) => axiosInstance.post(endPoint.copyCategory(instId), params),
	//
	removeItem: (instId, params) => axiosInstance.delete(endPoint.removeItem(instId), { params: params }),
	disposeItem: (instId, body) => axiosInstance.patch(endPoint.disposeItem(instId), body),
	registItemByImage: (instId, formData) =>
		axiosInstance.post(endPoint.registItemByImage(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	getMsds: (instId, params) => axiosInstance.get(endPoint.getMsds(instId), { params: params }),
	getWaitingDetail: (instId, params) => axiosInstance.get(endPoint.getWaitingDetail(instId), { params: params }),
	deleteInspectItem: (instId, params) => axiosInstance.delete(endPoint.deleteInspectItem(instId), { params: params }),
	editInspectItemUser: (instId, formData) =>
		axiosInstance.put(endPoint.editInspectItemUser(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	registItem: (instId, formData) =>
		axiosInstance.post(endPoint.registItem(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	registInspectItem: (instId, body) => axiosInstance.post(endPoint.registInspectItem(instId), body),
	editInspectItem: (instId, formData) =>
		axiosInstance.put(endPoint.editInspectItem(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	editItem: (instId, formData) =>
		axiosInstance.put(endPoint.editItem(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	editItemMultiple: (instId, body) => axiosInstance.put(endPoint.editItemMultiple(instId), body),
	divideItem: (instId, formData) =>
		axiosInstance.put(endPoint.divideItem(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	getComment: (instId, params) => axiosInstance.get(endPoint.comment(instId), { params }),
	addComment: (instId, body) => axiosInstance.post(endPoint.comment(instId), body),
	deleteComment: (instId, params) => axiosInstance.delete(endPoint.comment(instId), { params }),
	// inspect
	removeInspect: (instId, params) => axiosInstance.delete(endPoint.removeInspect(instId), { params: params }),
	// history
	removeHistory: (instId, params) => axiosInstance.delete(endPoint.removeHistory(instId), { params: params }),
	checkRestore: (instId, params) => axiosInstance.get(endPoint.checkRestore(instId), { params: params }),
	restoreItem: (instId, body) => axiosInstance.patch(endPoint.restoreItem(instId), body),
	exportExcelList: (instId, body) =>
		axiosInstance.post(endPoint.exportExcelList(instId), body, {
			responseType: 'blob',
		}),
	exportExcelHistory: (instId, body) =>
		axiosInstance.post(endPoint.exportExcelHistory(instId), body, {
			responseType: 'blob',
		}),
	tagList: (instId, params) => axiosInstance.get(endPoint.tagList(instId), { params }),
	downloadExcel: (instId, body) =>
		axiosInstance.post(endPoint.downloadExcel(instId), body, {
			responseType: 'blob',
		}),
	checkExcel: (instId, body) =>
		axiosInstance.post(endPoint.checkExcel(instId), body, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	validate: (instId, body) => axiosInstance.post(endPoint.validate(instId), body),
	// disposal
	disposal: (instId, params) => axiosInstance.get(endPoint.disposal(instId), { params }),
	disposalFilter: instId => axiosInstance.get(endPoint.disposalFilter(instId)),
	removeDisposal: (instId, params) => axiosInstance.delete(endPoint.removeDisposal(instId), { params: params }),
	exportExcelDisposeList: (instId, body) =>
		axiosInstance.post(endPoint.exportExcelDisposeList(instId), body, {
			responseType: 'blob',
		}),

	getBundleList: (instiuteId, params) => axiosInstance.get(endPoint.bundleList(instiuteId), { params }),
}

export default inventoryAPI
