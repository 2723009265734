var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-footer-wrap"
  }, [_c('div', {
    staticClass: "left"
  }, [_vm.isPrev ? _c('button', {
    staticClass: "button-white",
    on: {
      "click": function click($event) {
        return _vm.$emit('goPrev');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.prevText)))]) : _vm._e()]), _c('div', {
    staticClass: "right"
  }, [_c('button', {
    staticClass: "button-white",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.cancelText)))]), _c('button', {
    staticClass: "button-primary",
    attrs: {
      "disabled": _vm.isOkDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('ok');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.okText)))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }