<template>
	<div class="info-wrap print-section">
		<p class="info-header">{{ tt('관리정보') }}</p>
		<div class="field-wrap">
			<management-info-field
				v-for="fieldInfo in fields"
				:item="item"
				:fieldInfo="fieldInfo"
				:fieldName="formatFieldName(fieldInfo)"
				:isAcvite="fieldInfo.isActive"
				:isRequire="fieldInfo.isRequire"
				:key="`${fieldInfo.key}`"
				:fieldText="formatFieldText(fieldInfo.key)"
				:isData="isData(fieldInfo.key)"
				:hideField="hideField"
			></management-info-field>
			<product-id-sticker-field
				v-if="qrStickerField && qrStickerField.isActive"
				fieldName="물품 ID 스티커"
				:itemId="item.id"
				:qrSticker="item.managementFields.qrSticker"
				:stickerType="item.managementFields.stickerType"
				:ghs="item.inventoryFields.ghs"
			></product-id-sticker-field>
		</div>
		<div class="field-wrap">
			<image-field
				v-if="imageField && imageField.isActive"
				fieldName="이미지"
				:isRequire="imageField.isRequire"
				:image="item.managementFields.images"
			></image-field>
		</div>
		<div class="field-wrap">
			<tag-field
				v-if="tagField && tagField.isActive"
				fieldName="태그"
				:isRequire="tagField.isRequire"
				:tag="item.managementFields.tagList"
			></tag-field>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ManagementInfoField from './ManagementInfoField.vue'
import MixinItem from '@/mixins/inventory/MixinItem'
import { RegistType } from '@/utils/define/ItemCode'
import ImageField from './ImageField.vue'
import TagField from './TagField.vue'
import ProductIdStickerField from './ProductIdStickerField.vue'

export default {
	name: 'ManagementInfo',
	components: { ManagementInfoField, ImageField, TagField, ProductIdStickerField },
	mixins: [MixinItem],
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('cargo/item', ['managementFields']),
		fields() {
			let buyKey = ''
			if (this.findFieldIdxByKeyName('buyer') > 0) buyKey = 'buyer'
			else if (this.findFieldIdxByKeyName('buyDatetime') > 0) buyKey = 'buyDatetime'
			else if (this.findFieldIdxByKeyName('price') > 0) buyKey = 'price'

			return this.managementFields?.filter(field => field.isActive && (!this.hideField.includes(field.key) || buyKey == field.key))
		},
		hideField() {
			return ['tag', 'image', 'buyer', 'buyDatetime', 'price', 'createDatetime']
		},
		findFieldIdxByKeyName() {
			return keyname => this.managementFields?.findIndex(it => it.key == keyname)
		},
		qrStickerField() {
			if (this.managementFields) {
				const idx = this.findFieldIdxByKeyName('qrSticker')
				return this.managementFields[idx]
			} else {
				return -1
			}
		},
		imageField() {
			if (this.managementFields) {
				const idx = this.findFieldIdxByKeyName('image')
				return this.managementFields[idx]
			} else {
				return -1
			}
		},
		tagField() {
			if (this.managementFields) {
				const idx = this.findFieldIdxByKeyName('tag')
				return this.managementFields[idx]
			} else {
				return -1
			}
		},
		categoryFields() {
			if (this.item) {
				return this.managementFields
			} else {
				return null
			}
		},
		formatFieldName() {
			return fieldInfo => {
				const fieldKey = fieldInfo.key
				switch (fieldKey) {
					case 'expireDatetime':
						return '유효기간'
					case 'buyer':
					case 'buyDatetime':
					case 'price':
						return '구매자/구매일/가격'
					case 'register':
						return '등록자/등록일'
					default:
						return fieldInfo.name
				}
			}
		},
		formatFieldText() {
			return fieldKey => {
				if (this.item) {
					const mngField = this.item.managementFields
					const value = mngField[fieldKey]
					switch (fieldKey) {
						case 'assignee':
							return this.item?.assignee?.name
						case 'register':
							return `${this.formatText(this.item?.register?.name)}/${this.formatDateFromISO(this.item?.createDatetime, 'yyyy-MM-dd')}`
						case 'buyer':
						case 'buyDatetime':
						case 'price':
							let name = '-',
								date = '-',
								price = '-'
							if (this.findFieldIdxByKeyName('buyer')) name = this.formatText(this.item?.buyer?.name)
							if (this.findFieldIdxByKeyName('buyDatetime'))
								date = this.formatDateFromISO(this.item?.managementFields?.buyDatetime, 'yyyy-MM-dd')
							if (this.findFieldIdxByKeyName('price'))
								price = this.formatVolume(this.item?.managementFields?.price, this.item?.managementFields?.priceUnit)
							return `${name}/${date}/${price}`
						case 'qrSticker':
							const qrSticker = this.item.managementFields?.['qrSticker']
							const qrLength = qrSticker.length
							if (qrLength > 0) {
								return `${this.tt('등록됨')}(${qrLength}/${this.item.multiCreateCount})`
							} else {
								return this.tt('등록 안 됨')
							}
						case 'storage':
						case 'location':
							return this.item?.[fieldKey]?.pathName
						case 'registerMethod':
							return this.tt(RegistType.typeToString(value))
						case 'expireDatetime':
						case 'openDatetime':
							return `${this.formatDateFromISO(value, 'yyyy-MM-dd')}`
						case 'threshold':
							return mngField.threshold ? `${mngField.threshold}${mngField.thresholdUnit}` : '-'
						default:
							return value ? value : '-'
					}
				}
				return null
			}
		},
		isData() {
			return key => {
				if (this.item) {
					const value = this.item.managementFields[key]
					switch (key) {
						case 'qrSticker':
							return this.item?.length > 0
						case 'storage':
						case 'location':
						case 'assignee':
							return !!this.item[key]
						case 'register':
							return !!this.item.register && !!this.item.createDatetime
						default:
							return !!value
					}
				}
				return false
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	gap: 20px;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.field-wrap {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}
}
</style>
