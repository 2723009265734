var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "size": "sm",
      "hide-header": "",
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    }
  }, [_c('div', {
    staticClass: "popup-wrapper"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/icon-remain-clock.png")
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.title))]), _c('button', {
    staticClass: "button-primary",
    on: {
      "click": _vm.goToStorePay
    }
  }, [_vm._v(_vm._s(_vm.tt('결제관리 바로가기 >')))]), _c('div', {
    staticClass: "remain-table"
  }, [_c('div', {
    staticClass: "remian-row"
  }, [_c('div', [_vm._v(_vm._s(_vm.tt('월')))]), _c('div', [_vm._v(_vm._s(_vm.tt('정산금액')))]), _c('div', [_vm._v(_vm._s(_vm.tt('상태')))])]), _vm._l(_vm.nopaidList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "remian-row"
    }, [_c('div', [_vm._v(_vm._s(_vm.dateFormat(item)))]), _c('div', [_vm._v(_vm._s(_vm.tt('${1}원', item.noPaidPriceWithVat.toLocaleString())))]), _c('div', [_vm._v(_vm._s(_vm.statusToText(item)))])]);
  })], 2), _c('p', [_vm._v(_vm._s(_vm.tt('정산기간 : 배송완료일이 속한 월의 익월 말일까지 입니다.')))]), _c('p', [_vm._v(_vm._s(_vm.tt('정산금액 : 배송 완료되었으나 결제되지 않은 총금액입니다.')))])]), _c('div', {
    staticClass: "btn-footer"
  }, [_c('button', {
    on: {
      "click": _vm.clickDonwShow
    }
  }, [_vm._v(_vm._s(_vm.tt('일주일간 보지 않기')))]), _c('button', {
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(_vm._s(_vm.tt('닫기')))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }