<template>
	<div class="header-wrap" v-if="item">
		<div class="status-header" v-if="isNeedInfo">
			{{ `${tt('입고하기 위해, [정보입력]을 클릭하여 필수정보 입력을')} ${tt('완성해 주세요')}` }}
		</div>
		<div class="header">
			<div class="left">
				<div class="title">{{ `${statusText} ${inventory.name}` }}</div>
				<div class="subtext">
					<div class="brand" v-if="item.brand && item.brand.name" v-html="formatBrand(item.brand)"></div>
					<div class="subname" v-if="item.inventoryFields.subname" v-html="formatText(item.inventoryFields.subname)"></div>
				</div>
			</div>
			<div class="right print-hide">
				<div class="right-gray">
					<base-button class="button-gray" @click="$emit('print')">{{ tt('출력') }}</base-button>
					<button class="button-gray" @click="$emit('delete')">{{ tt('삭제') }}</button>
					<button class="button-gray" v-if="item.status === 'WAIT' && !isNeedInfo" @click="$emit('edit')">{{ tt('편집') }}</button>
				</div>
				<div class="right-primary" v-if="item.status !== 'COMPLETE'">
					<button class="button-primary" v-if="isNeedInfo" @click="$emit('needInput')">{{ tt('정보 입력') }}</button>
					<button class="button-primary" v-else-if="item.status === 'WAIT'" @click="$refs['modal-cargo'].show()">
						{{ tt('입고 처리') }}
					</button>
				</div>
			</div>
		</div>
		<modal-confirm
			ref="modal-cargo"
			:title="tt('입고 처리')"
			:content="tt('입고 처리 하시겠습니까?')"
			confirmText="입고 처리"
			@confirm="processCargo"
		/>
	</div>
</template>

<script>
import MixinItem from '@/mixins/inventory/MixinItem'
import { mapActions, mapGetters } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
export default {
	name: 'Header',
	mixins: [MixinItem],
	components: {},
	props: {},
	data() {
		return {
			nullText: `<span class="nodata">${this.tt('정보없음')}</span>`,
		}
	},
	computed: {
		...mapGetters('cargo/item', ['item']),
		inventory() {
			return this.item?.inventoryFields
		},
		management() {
			return this.item?.managementFields
		},
		isNeedInfo() {
			return !this.item.isReady && this.item.status !== 'COMPLETE'
		},
		statusText() {
			return `[${
				{
					WAIT: this.tt('입고 대기'),
					COMPLETE: this.tt('입고 완료'),
				}[this.item.status]
			}]`
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('cargo/item', ['processCargoItem', 'getCargoItem', 'getCategoryInfo']),
		async processCargo() {
			if (this.$root.userPermission.cargo?.update) {
				await this.processCargoItem([this.item.id])
				await this.getCargoItem(this.item.id)
				await this.getCategoryInfo(this.item.category.id)
				this.$root.toast('입고 완료', '물품이 입고 처리 되었습니다.', ToastType.SUCCESS)
			} else this.$root.toast('권한', '입고 처리 권한이 없습니다.', 'error')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/purchase/cargo/detail/_cargo-detail-header.scss';
</style>
