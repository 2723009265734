var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "lm-button",
    "class": [_vm.color, {
      disable: _vm.disable
    }],
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }