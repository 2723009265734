<template>
	<portal to="lm-modal">
		<template v-if="isShow">
			<div class="Modal ModalPaymentTax Popup__depth2">
				<div class="Popup__centered">
					<div class="Popup__container Popup__size-sm" ref="container">
						<div class="Popup__top">
							<h5 class="Popup__title">
								{{ tt('전자세금계산서 결제') }}
							</h5>
							<button class="Popup__close-btn" @click="close">
								<img src="@/assets/svg/popup-close.svg" alt="close" />
							</button>
						</div>
						<div class="Popup__content">
							<h5>{{ tt('기업정보') }}</h5>
							<div class="Popup__input-field">
								<p>{{ tt('상호명') }}<span class="required">*</span></p>
								<input type="text" v-model="form.nameOfCompany" :placeholder="tt('법인명을 입력해주세요')" />
							</div>
							<div class="Popup__input-field">
								<p>
									{{ tt('사업자등록번호(10자리)') }}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									:value="form.registrationNumber"
									@input="autoTraderNumber"
									:placeholder="tt('10자리 사업자등록번호를 입력해주세요')"
									:class="{ error: registrationNumberError }"
									maxlength="12"
								/>
								<span class="input-error-msg" v-if="registrationNumberError">
									<img src="@/assets/svg/input-icon-warning.svg" />
									<p>
										{{ tt('유효하지 않은 사업자등록번호입니다.') }}
									</p>
								</span>
							</div>
							<div class="Popup__input-field">
								<p>{{ tt('대표자명') }}<span class="required">*</span></p>
								<input
									type="text"
									:value="form.nameOfRepresentative"
									@input="inputNameOfRepresentative"
									:placeholder="tt('대표자 이름을 입력해주세요')"
								/>
							</div>
							<div class="Popup__input-field">
								<p>{{ tt('이메일') }}<span class="required">*</span></p>
								<input
									type="text"
									:value="form.email"
									@input="inputEmail"
									:placeholder="tt('전자세금계산서를 발급받을 이메일을 입력해주세요')"
								/>
							</div>
							<div class="Popup__input-field">
								<p>{{ tt('업태') }}</p>
								<input type="text" v-model="form.bizType" :placeholder="tt('업태를 입력해주세요')" />
							</div>
							<div class="Popup__input-field">
								<p>{{ tt('종목') }}</p>
								<input type="text" v-model="form.bizClass" :placeholder="tt('종목을 입력해주세요')" />
							</div>
							<div class="Popup__input-field">
								<p>{{ tt('전화번호') }}</p>
								<input type="text" v-model="form.phone" @input="inputTel" :placeholder="tt('전화번호를 입력해주세요')" />
							</div>
							<div class="Popup__input-field">
								<p>{{ tt('주소') }}</p>
								<AddressInput
									:hasPostcode="false"
									:postcode="form.postcode"
									:address="form.address"
									:addressDetail="form.addressDetail"
									addressPlaceholder="주소를 검색해주세요"
									@search="changeAddress"
								/>
							</div>
							<div class="noti-box">
								<p
									v-html="
										tt(
											`<b class='red'>${formatDepositExpireDate()}</b>까지 입금하지 않으면 라이선스가 <b class=red>비활성화</b>됩니다.`
										)
									"
								></p>
							</div>
						</div>
						<div class="Popup__bottom">
							<button class="Btn__yellow" :disabled="!isFormValid" @click="showCompletePayment">
								{{ tt('전자세금계산서 발급하기') }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="Popup__background Popup__depth2"></div>
		</template>
	</portal>
</template>

<script>
import AddressInput from '@/components/input/AddressInput.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import MixinLicense from '@/mixins/license'
import { ToastType } from '@/utils/define/ToastType'
import { createPaymentEbill } from '@/services/api/payment'
import StringUtils from '@/utils/modules/StringUtils'
export default {
	components: {
		AddressInput,
	},
	name: 'ModalLicensePaymentEbill',
	mixins: [MixinLicense],
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		isAdditionMode: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		async isShow(value) {
			if (value) {
				await this.loadLicenseManagement()
				this.setInitForm()
			} else {
				this.clearForm()
			}
		},
	},
	data() {
		return {
			form: {
				nameOfCompany: '',
				registrationNumber: '',
				nameOfRepresentative: '',
				email: '',
				purpose: 'LICENSE',
				bizType: '',
				bizClass: '',
				phone: '',
				postcode: '',
				address: '',
				addressDetail: '',
			},
			registrationNumberError: false,
			regNumberErrorText: '공급받는자 사업자번호가 올바르지 않습니다.',
		}
	},
	computed: {
		...mapState('license', ['licenseManagement']),
		isFormValid() {
			const isNameOfRepresentativeValid = this.form.nameOfRepresentative !== ''
			const isRegistrationNumberValid = this.form.registrationNumber !== '' && this.form.registrationNumber.length === 12
			const isNameOfCompanyValid = this.form.nameOfCompany !== ''
			const isEmailValid = this.form.email !== '' && this.isValidEmail(this.form.email)

			return isNameOfRepresentativeValid && isRegistrationNumberValid && isNameOfCompanyValid && isEmailValid
		},
	},
	methods: {
		...mapMutations('license', ['setEbillId']),
		async setInitForm() {
			this.registrationNumberError = false
			const response = await this.lookupPreviousEbillInfo()
			Object.assign(this.form, response.data)
		},
		close() {
			this.$emit('close')
		},
		clearForm() {
			this.form = {
				nameOfCompany: '',
				registrationNumber: '',
				nameOfRepresentative: '',
				email: '',
				purpose: 'LICENSE',
				bizType: '',
				bizClass: '',
				phone: '',
				postcode: '',
				address: '',
				addressDetail: '',
			}
		},
		changeAddress({ postcode, address, addressDetail }) {
			this.form.postcode = postcode
			this.form.address = address
			this.form.addressDetail = addressDetail
		},
		isValidEmail(email) {
			// ref: https://www.w3resource.com/javascript/form/email-validation.php
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				return true
			}
			return false
		},
		autoTrader(companyNum) {
			// ref: https://life-with-coding.tistory.com/46 [나다움]
			companyNum = companyNum.replace(/[^0-9]/g, '')
			let tempNum = ''
			if (companyNum.length < 4) {
				return companyNum
			} else if (companyNum.length < 6) {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				return tempNum
			} else if (companyNum.length < 11) {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				tempNum += '-'
				tempNum += companyNum.substr(5)
				return tempNum
			} else {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				tempNum += '-'
				tempNum += companyNum.substr(5)
				return tempNum
			}
		},
		autoTraderNumber(e) {
			const traderNumber = this.autoTrader(e.target.value)
			this.form.registrationNumber = traderNumber
		},
		inputNameOfRepresentative(e) {
			this.form.nameOfRepresentative = e.target.value
		},
		inputEmail(e) {
			this.form.email = e.target.value
		},
		inputTel(e) {
			const number = StringUtils.autoHypenTel(e.target.value)
			this.form.phone = number
		},
		async showCompletePayment() {
			try {
				const instId = this.$route.params.instituteId
				const response = await createPaymentEbill(instId, this.form)
				const ebillId = response.data.id
				this.setEbillId(ebillId)
				if (this.isAdditionMode) {
					// 추가 모드
					const purchaseResponse = await this.purchaseLicenseBasicAddition()
					this.successPurchase()
				} else {
					// 첫 구매 or 재구매 모드
					const purchaseResponse = await this.purchaseLicenseBasic()
					this.successPurchase()
				}
			} catch (err) {
				this.$root.toast('전자세금계산서 발급 실패', err.data.msg, 'error')
			}
		},
		async successPurchase() {
			this.showSuccessToast()
			await this.loadLicense()
			await this.loadLicenseHistory()
			this.$emit('close')
			this.$emit('complete')
		},
		// 사업자 등록 번호가 잘못된 경우 나오는 에러
		checkregistrationNumberError(response) {
			if (response.data.msg.indexOf(this.regNumberErrorText) >= 0) {
				this.registrationNumberError = true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
input {
	&.error {
		border: solid 1px #ff3b31 !important;
	}
}
::v-deep {
	.address-input-wrapper {
		width: 100%;
		.btn-gray {
			height: 30px;
		}
	}
}
.input-error-msg {
	display: flex;
	align-items: center;
	p {
		color: #ff3b31 !important;
		padding-bottom: 0px !important;
		margin-left: 6px;
	}
}

.Popup__centered {
	position: relative;
	margin: 1.75rem auto;
	min-height: calc(100% - 3.5rem);
	display: flex;
	align-items: center;
	max-width: 520px;
}
.ModalPaymentTax {
	color: #000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	outline: 0;
	color: #000;
	.Popup__container {
		// max-height: 544px;
		position: relative;
		width: 500px;
		top: unset;
		left: unset;
		transform: unset;
	}
	.Popup__content {
		// max-height: 70vh;
		// overflow-y: auto;
		h5 {
			font-size: 0.875rem;
			font-weight: 700;
			margin-bottom: -10px;
		}
		.Popup__input-field {
			margin-top: 20px;
		}
		> span {
			font-size: 10px;
			font-weight: 400;
			display: block;
			color: $PLACEHOLDER;
			margin-top: 8px;
		}
		.noti-box {
			width: 100%;
			border: 1px solid $DIVIDER;
			border-radius: 5px;
			padding: 15px;
			margin-top: 20px;
			background-color: $GREY_0;
			p {
				font-size: 0.875rem;
				color: #000;
				padding-left: 12px;
				position: relative;
				&:before {
					width: 3px;
					height: 3px;
					background-color: #000;
					content: '';
					border-radius: 3px;
					top: 10px;
					left: 0;
					position: absolute;
				}
				span {
					font-weight: bold;
				}
			}
		}
	}
	.Popup__bottom {
		padding: 0px 20px;
		.Btn__yellow {
			width: 100%;
			height: 48px;
			margin-top: 0px;
			margin-bottom: 20px;
			font-size: 0.875rem;
			font-weight: 700;
		}
	}
}
</style>
