var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('안전정보')))]), _c('div', {
    staticClass: "msds-list"
  }, _vm._l(_vm.msdsKeys, function (key) {
    return _c('div', {
      key: key,
      staticClass: "msds-item-wrap"
    }, [_vm.msds[key] ? _c('msds-item', {
      attrs: {
        "msds": _vm.msds[key],
        "msdsKey": key
      }
    }) : _vm._e()], 1);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }