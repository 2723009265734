import { render, staticRenderFns } from "./GHSLabel3130_A.vue?vue&type=template&id=65f07be0&scoped=true&"
import script from "./GHSLabel3130_A.vue?vue&type=script&lang=js&"
export * from "./GHSLabel3130_A.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f07be0",
  null
  
)

export default component.exports