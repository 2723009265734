var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('migration-header', {
    attrs: {
      "isMigration": true,
      "text": _vm.headerText
    }
  }), _c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h5', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.titleText)
    }
  }), _c('h6', {
    staticClass: "sub-title",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('선택한 연구실을 랩매니저 PRO로 이전합니다.'))
    }
  }), _c('ul', {
    staticClass: "migration-info"
  }, [_c('li', {
    staticClass: "migration-info-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('연구실이 이전되면 연구실에 소속된 모든 멤버는 랩매니저 PRO를 사용하게 됩니다.')) + " ")]), _c('li', {
    staticClass: "migration-info-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('랩매니저 PRO로 이전된 연구실 멤버는 랩매니저 구버전 접속이 불가합니다.')) + " ")])])]), _c('migration-lab-selector', {
    attrs: {
      "selectedLabId": _vm.selectedLabId,
      "labs": _vm.legacyLab
    },
    on: {
      "selectedLab": _vm.getSelectedLab
    }
  }), _c('migration-button-area', {
    attrs: {
      "nextDisable": !_vm.isSelectAnyLab
    },
    on: {
      "next": _vm.goToNext,
      "back": _vm.geToBack
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }