import dangerStorageAPI from '@/services/api/safety/dangerStorage'

const safetyManagement = {
	namespaced: true,
	state: {
		tab: null,
		dangerStorageList: [],
		statusList: [],
		count: 0,
		loadParams: {
			id: null,
			keyword: null,
			showOnlyExist: false,
		},
	},
	getters: {
		tab: state => state.loadParams.id,
		dangerStorageList: state => state.dangerStorageList,
		statusList: state => state.statusList,
		count: state => state.count,
		keyword: state => state.loadParams.keyword,
		showOnlyExist: state => state.loadParams.showOnlyExist,
	},
	mutations: {
		setDangerStorageList: (state, data) => {
			state.dangerStorageList = data.list
			state.count = data.count
		},
		setStatusList: (state, data) => {
			state.statusList = data
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		initParams: state => {
			state.loadParams = {
				id: null,
				keyword: null,
				showOnlyExist: false,
			}
		},
	},
	actions: {
		getDangerStorage: async ({ rootGetters, commit }) => {
			const response = await dangerStorageAPI.lookupDangerStorageList(rootGetters.instituteId, null)
			commit('setDangerStorageList', response.data)
		},
		getStorageStatusList: async ({ rootGetters, state, commit }, params) => {
			commit('setStatusList', [])
			commit('setLoadParams', params)
			const response = await dangerStorageAPI.lookupStorageStatusList(rootGetters.instituteId, state.loadParams)
			commit('setStatusList', response.data)
		},
		setSetting: async ({ rootGetters }, payload) => {
			return await dangerStorageAPI.setMultiple(rootGetters.instituteId, payload)
		},
		exportExcel: async ({ rootGetters }, body) => {
			return await dangerStorageAPI.exportExcel(rootGetters.instituteId, body)
		},
	},
}

export default safetyManagement
