<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt('카테고리 선택') }}</div>
			<div class="form-msg" :class="{ error: !isValid }">
				{{ tt(validMessage) }}
			</div>
		</div>
		<category-selector ref="category-selector" noSmall :value="categoryInfo" @change="setCategoryInfo"></category-selector>
	</div>
</template>
<script>
import CategorySelector from '@/views/common/components/categorySelector/CategorySelector.vue'
import { mapState, mapMutations } from 'vuex'
export default {
	name: 'CargoCategorySelector',
	components: { CategorySelector },
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapState('inventoryGen2/setting', ['categoryInfo']),
		validMessage() {
			return this.isValid ? this.tt('입력 완료') : this.tt('분류를 선택해주세요')
		},
		isValid() {
			return this.categoryInfo
		},
	},
	watch: {},
	created() {},
	methods: {
		...mapMutations('inventoryGen2/setting', ['setCategoryInfo']),
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
</style>
