<template>
	<portal :to="portalTo" v-if="isShow">
		<div class="AddAuthorityMemberPopup Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt(title) }}</h5>
					<button class="Popup__close-btn" type="button">
						<img src="@/assets/svg/popup-close.svg" @click="hide" />
					</button>
				</div>
				<div class="Popup__content">
					<lm-input-search class="block" @search="search" :placeholder="tt(searchPlaceholder)"></lm-input-search>
					<div class="Organization__member-list">
						<ul class="horizational-list">
							<tree-view
								ref="tree-view"
								v-if="treeData"
								:treeData="selectTreeData"
								textField="name"
								:draggable="false"
								:selectWithChild="false"
								:mode="mode"
								modal="location"
								:keyword="keyword"
								@changeActive="getActiveId"
								@load="loadedTree"
							></tree-view>
						</ul>
					</div>
				</div>
				<div class="Popup__bottom">
					<button class="Btn__management" @click="goPage">
						{{ tt('보관함 관리') }}
					</button>
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="complete">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import LmInputSearch from '@/views/common/components/input/LmInputSearch.vue'
import MixinModal from '@/mixins/modal'
import SearchBox from '@/views/common/components/SearchBox.vue'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ModalTreeview',
	mixins: [MixinModal],
	components: {
		SearchBox,
		TreeView,
		LmInputSearch,
	},
	props: {
		portalTo: { type: String },
		title: { type: String },
		searchPlaceholder: { type: String },
		treeData: { type: Array },
		mode: { type: String },
		hideSubStorage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectTreeData: [],
			keyword: '',
			selectData: {},
			selectList: [],
			selectedIdList: [],
		}
	},
	methods: {
		show(idList) {
			this.isShow = true
			this.selectTreeData = this.$root.deepCopy(this.treeData)
			this.selectTreeData.forEach(x => {
				this.callStorage(x)
			})
			if (idList != undefined) {
				this.selectedIdList = [...idList]
			}
		},
		callStorage(x) {
			// 위치인 경우에 보관함 목록 불러오기
			if (x.storages && x.storages.length > 0) {
				x.storages.forEach(y => {
					y.storage = true
					if (this.hideSubStorage) {
						y.children = []
					} else {
						if (y.children.length > 0) {
							y.children.forEach(c => {
								this.callStorageChildren(c)
							})
						}
					}
				})

				x.children = [...x.children, ...x.storages]
			}

			// 위치, 보관함 모두 동작함
			// 하위 보관함인 경우 children 트리에 편입하지 않음
			if (this.hideSubStorage) {
				if (x.children.length > 0) {
					x.children.forEach(c => {
						this.callStorage(c)
					})
				}
			} else {
				if (x.children.length > 0 && !x.storage) {
					x.children.forEach(c => {
						this.callStorage(c)
					})
				}
			}
		},
		callStorageChildren(c) {
			c.storage = true
			if (c.children.length > 0) {
				c.children.forEach(d => {
					this.callStorageChildren(d)
				})
			}
		},
		search(keyword) {
			this.keyword = keyword
		},
		goPage() {
			this.$router.push({
				name: PageName.Institution.Location,
			})
		},
		getActiveId(id, data) {
			console.log(data)
			let isArray = Array.isArray(data)
			if (['storage', 'storageGroup', 'active'].includes(this.mode)) {
				this.selectData = isArray ? data.shift() : data
			} else {
				this.selectData = [...data]
			}
		},
		complete() {
			console.log(this.selectData)
			this.$emit('complete', this.selectData)
		},
		loadedTree() {
			if (this.selectedIdList.length > 0) {
				this.$refs['tree-view'].setActiveIdList(this.selectedIdList)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.AddAuthorityMemberPopup {
	.horizational-list {
		height: 500px;
		width: 100%;
		padding-top: 0;
		margin-top: 20px;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		overflow-y: auto;
		li {
			padding: 12px;
			border-bottom: 0;
			transition: 0.3s all linear;
			&:hover {
				background-color: $GREY_1;
			}
			button {
				img {
					width: 20px;
					background-image: '@/assets/svg/Select-Circle.svg';
				}
			}
			> div {
				h6 {
					> img {
						width: 12px;
						margin-left: 6px;
					}
				}
			}
		}
	}
}
</style>
