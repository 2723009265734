<template>
	<modal-filter ref="modal" @confirm="confirm" @reset="reset">
		<template #modal-content v-if="filter">
			<filter-list ref="filter-status" title="입고상태" :filterCookieName="filterCookieName.status" :list="filter.status" />
			<filter-list ref="filter-categories" title="카테고리" :filterCookieName="filterCookieName.categories" :list="filter.categories" />
			<filter-list ref="filter-brand" title="브랜드" :filterCookieName="filterCookieName.brands" :list="filter.brands" />
			<filter-list
				ref="filter-registerMethods"
				title="구매처"
				:filterCookieName="filterCookieName.registerMethods"
				:list="filter.registerMethods"
			/>
			<filter-list ref="filter-shipStatus" title="배송상태" :filterCookieName="filterCookieName.shipStatus" :list="filter.shipStatus" />
		</template>
	</modal-filter>
</template>
<script>
import ModalFilter from '@/views/common/components/modalGen2/ModalFilterBase.vue'
import FilterList from '@/views/common/components/modalGen2/components/filter/FilterList.vue'
import { mapActions, mapState } from 'vuex'
export default {
	components: {
		ModalFilter,
		FilterList,
	},
	computed: {
		...mapState('cargo/list', ['filter', 'filterCookieName']),
	},
	methods: {
		...mapActions('cargo/list', ['getFilter']),
		async show() {
			if (this.filter == null) await this.getFilter()
			this.$refs['modal'].show()
		},
		confirm() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.setCookies !== 'undefined') it.setCookies()
			})
			let params = {
				status: this.$refs['filter-status'].checkedList ?? null,
				categoriesId: this.$refs['filter-categories'].checkedList ?? null,
				brandsId: this.$refs['filter-brand'].checkedList ?? null,
				registerMethods: this.$refs['filter-registerMethods'].checkedList ?? null,
				shipStatus: this.$refs['filter-shipStatus'].checkedList ?? null,
			}
			this.$emit('changeLoadParams', params)
			this.$refs['modal'].hide()
			this.$root.toast('필터', '필터가 적용되었습니다.', 'success')
		},
		reset() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.reset !== 'undefined') it.reset()
			})
		},
	},
}
</script>
<style lang="scss" scoped></style>
