<template>
	<b-overlay :show="isLoading" spinner-variant="primary" rounded="sm">
		<div class="bundle-list-wrapper" ref="scroll-container" @scroll="onScroll">
			<div class="search-box">
				<img class="btn-search" src="@/views/components/searchBox/assets/ic-search.svg" />
				<input :value="keyword" @change="changeKeyword" type="text" :placeholder="placeholder" />
			</div>
			<div class="list-wrapper">
				<div class="item-wrapper">
					<div class="item" :class="{ active: selectedItem == null }" @click="select(null)">
						<div style="width: calc(100% - 20px)">
							<div class="name">전체보기</div>
						</div>
						<img src="@/assets/svg/arrow/icon-arrow-right.svg" />
					</div>
				</div>
				<div class="item-wrapper" v-for="(item, i) in list" :key="'bundleKey_' + i">
					<div class="item" @click="select(item, i)" :class="{ active: isActive(item, i) }">
						<div style="width: calc(100% - 20px)">
							<div class="name" v-html="getName(item)"></div>
							<div class="subname">
								<span v-if="item.volumeUnit">{{ floor(item.normalizeCurrentVolume).toLocaleString() + item.volumeUnit }}</span>
								<span>{{ (item.itemCount?.toLocaleString() ?? 0) + tt('개') }}</span>
							</div>
						</div>
						<img src="@/assets/svg/arrow/icon-arrow-right.svg" />
					</div>
				</div>
				<div class="item-wrapper" v-if="noneGroupbyDataItemCount > 0">
					<div class="item" @click="selectEmpty" :class="{ active: selectedItem == 'EMPTY' }">
						<div style="width: calc(100% - 20px)">
							<div class="name" v-html="getName(null)"></div>
							<div class="subname">
								{{ (noneGroupbyDataItemCount?.toLocaleString() ?? 0) + tt('개') }}
							</div>
						</div>
						<img src="@/assets/svg/arrow/icon-arrow-right.svg" />
					</div>
				</div>
			</div>
		</div>
	</b-overlay>
</template>
<script>
import PageName from '@/utils/define/PageName'
import StringUtils from '@/utils/modules/StringUtils'
import MixinScrolling from '@/mixins/scrolling'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
export default {
	props: {
		previousPage: {
			type: Object,
			default: () => {},
		},
	},
	mixins: [MixinScrolling],
	data() {
		return {
			isLoading: false,
		}
	},
	computed: {
		...mapState('inventoryGen2/list', ['bundleOptions', 'selectedBundleOption']),
		...mapGetters('inventoryGen2/bundle', ['keyword', 'list', 'selectedItem', 'count', 'noneGroupbyDataItemCount', 'scrollTop']),
		...mapGetters('inventoryGen2/list', ['selectedCategory']),
		placeholder() {
			return this.bundleOptions.find(it => it.value == this.selectedBundleOption)?.name + '(으)로 검색'
		},
	},
	watch: {
		selectedBundleOption(value) {
			console.log(value)
			if (value != 'none') {
				this.setSelectedItem(null)
				this.setLoadParams({ groupby: value, keyword: null, offset: 0 })
			}
		},
		async isBottom() {
			if (this.isLoading && this.scrollDirection == 'UP') return
			this.isLoading = true
			await this.getList(true)
			this.isLoading = false
		},
	},
	methods: {
		...mapMutations('inventoryGen2/bundle', ['initParams', 'setSelectedItem', 'setScrollTop']),
		...mapActions('inventoryGen2/bundle', ['setLoadParams', 'getList']),
		...mapActions({
			getInventoryList: 'inventoryGen2/list/setLoadParams',
		}),
		changeKeyword(e) {
			this.setLoadParams({ keyword: e.target.value })
		},
		getName(item) {
			let name = ''
			if (this.selectedBundleOption == 'NAME') {
				name = item?.name ?? this.tt('이름 없음')
			} else if (this.selectedBundleOption == 'PRODUCT_CODE') {
				name = item?.productCode ?? this.tt('제품번호 없음')
			} else if (this.selectedBundleOption == 'CASNO') {
				name = item?.casno ?? this.tt('CAS No 없음')
			}

			return StringUtils.replaceHighlight(name, this.keyword, 'span', 'highlight')
		},
		floor(value) {
			if (value) {
				return Math.floor(value * 100) / 100
			}
			return 0
		},
		isActive(item, i) {
			return this.selectedItem == this.getName(item) + i
		},
		async select(item, i) {
			if (item == null) this.setSelectedItem(null)
			else this.setSelectedItem(this.getName(item) + i)
			let loadParams = {}
			if (item == null) loadParams.bundle = {}
			else if (this.selectedBundleOption == 'NAME') {
				loadParams = { bundle: { volumeUnit: item?.volumeUnit, name: item?.name } }
			} else if (this.selectedBundleOption == 'PRODUCT_CODE') {
				loadParams = {
					bundle: { volumeUnit: item?.volumeUnit, productCode: item?.productCode },
				}
				// this.$cookies.set('filter_inventory_brandsId', JSON.stringify(loadParams.brandsId))
			} else if (this.selectedBundleOption == 'CASNO') {
				loadParams = { bundle: { volumeUnit: item?.volumeUnit, casno: item?.casno } }
			}
			await this.getInventoryList(loadParams)
			this.setScrollTop(this.lastScrollTop)
		},
		async selectEmpty() {
			this.setSelectedItem('EMPTY')
			let loadParams = {}
			if (this.selectedBundleOption == 'NAME') {
				loadParams = { bundle: { noneName: true } }
			} else if (this.selectedBundleOption == 'PRODUCT_CODE') {
				loadParams = { bundle: { noneProductCode: true } }
			} else if (this.selectedBundleOption == 'CASNO') {
				loadParams = { bundle: { noneCasno: true } }
			}
			await this.getInventoryList(loadParams)
			this.setScrollTop(this.lastScrollTop)
		},
	},
}
</script>
<style lang="scss" scoped>
.bundle-list-wrapper {
	min-width: 300px;
	max-width: 300px;
	height: 100%;
	border-right: 1px solid #eee;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	.item {
		width: 300px;
	}
	.search-box {
		z-index: 10;
		background-color: white;
		position: sticky;
		top: 0px;
		display: flex;
		align-items: center;
		width: 300px;
		height: 40px;
		padding: 8px 26px;
		border-bottom: 1px solid #eee;
		input {
			width: 100%;
			border: none;
			font-size: 14px;
		}
	}
	.list-wrapper {
		width: 100%;
		height: calc(100% - 40px);
		position: relative;
		.item-wrapper {
			width: 100%;
			padding: 8px;
			border-bottom: 1px solid #eee;
			.item {
				cursor: pointer;
				height: 50px;
				font-size: 14px;
				border-radius: 8px;
				padding: 8px;
				padding-left: 22px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				&.active {
					background-color: $LAB_YELLOW;
				}
				&:hover:not(.active) {
					background-color: $SUB-YELLOW_100;
				}
				.name {
					max-width: 100%;
					overflow: hidden;
					color: #111;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 14px;
					font-weight: 700;
					line-height: normal;
				}
				.subname {
					font-size: 12px;
					display: flex;
					gap: 10px;
					align-items: center;
					justify-content: flex-start;
				}
			}
		}
	}
}
::v-deep {
	.highlight {
		background-color: #ff3b314d;
	}
}
</style>
