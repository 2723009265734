var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.groupInfo && !_vm.isStart ? _c('div', {
    staticClass: "StorageBox__group"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.groupInfo && _vm.groupInfo.type == 'INSTITUTE' ? _vm.tt('기관 공용 보관함') : _vm.tt('보관함')) + " ")]), _vm.$root.userPermission.storage && _vm.$root.userPermission.storage.update ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.showStorage
    }
  }, [_c('img', {
    staticStyle: {
      "max-height": "20px"
    },
    attrs: {
      "src": require("@/assets/svg/add-storage.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('보관함 관리')) + " ")]) : _vm._e()]), _vm.groupInfo.type == 'INSTITUTE' ? _c('div', {
    staticClass: "institution-storage-noti"
  }, [_c('h6', [_vm._v("💡 " + _vm._s(_vm.tt('기관 공용 보관함')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('공용 보관함으로 지정하면 모든 멤버가 사용할 수 있습니다.')) + " ")])]) : _vm._e(), _vm.noStorages && _vm.$root.userPermission.storage && _vm.$root.userPermission.storage.update ? _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_storage.svg"),
      "alt": "no-image"
    }
  }), _c('h6', {
    domProps: {
      "textContent": _vm._s(_vm.groupInfo.type == 'INSTITUTE' ? _vm.tt('기관에서 공용으로 사용하는 보관함이 없습니다') : _vm.tt('지정된 보관함이 없습니다'))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.groupInfo.type == 'INSTITUTE' ? '<b>[보관함 관리]</b>를 클릭하여 추가해보세요' : '보관함을 지정해보세요'))
    }
  })]) : _vm._l(_vm.storages, function (storage, idx) {
    return _c('div', {
      key: "storage-".concat(idx),
      staticClass: "accordin"
    }, [_c('button', {
      staticClass: "Btn__collapse",
      "class": {
        checked: _vm.checkFoldList[idx]
      },
      on: {
        "click": function click($event) {
          return _vm.checkIsFold(idx);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.replaceLocationPathNames(storage.locationPathNames)) + " "), _c('span', [_vm._v(_vm._s(storage.storages.length) + _vm._s(_vm.tt('개의 보관함')))]), _c('i')]), storage.storages ? _c('ul', {
      staticClass: "StorageBox__list",
      "class": _vm.boxListClass(idx)
    }, _vm._l(storage.storages, function (innerStorage, inIdx) {
      return _vm.storages ? _c('storage-item', {
        key: "".concat(innerStorage.name, "-").concat(inIdx),
        attrs: {
          "storageInfo": innerStorage,
          "isOrganization": true,
          "userPermission": _vm.userPermission
        },
        on: {
          "editStorage": _vm.showModalEditStorage,
          "deleteStorage": _vm.showModalDeleteStorage
        }
      }) : _vm._e();
    }), 1) : _vm._e()]);
  }), _vm.groupInfo.type != 'INSTITUTE' ? _c('div', {
    staticClass: "StorageBox__delete"
  }, [_c('div', {
    staticClass: "right-btn-group",
    on: {
      "click": _vm.showModalDeleteGroup
    }
  }, [_c('button', {
    staticClass: "Btn__delete"
  }, [_vm._v(" " + _vm._s(_vm.tt('그룹 삭제')) + " ")])])]) : _vm._e()], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }