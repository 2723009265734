var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-item",
    on: {
      "click": function click($event) {
        return _vm.$emit('click', _vm.item);
      }
    }
  }, [_c('td', [_c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm.item.isInventory ? _c('span', {
    staticClass: "has-item"
  }, [_vm._v(_vm._s(_vm.tt('(Registererd)')))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.item.name) + " ")]), _c('p', {
    staticClass: "info-sub",
    domProps: {
      "innerHTML": _vm._s(_vm.formatText(_vm.item.subname))
    }
  })])]), _vm.isChemical ? _c('td', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.formatText(_vm.item.casno))
    }
  })]) : _vm._e(), _c('td', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.formatText(_vm.item.productCode))
    }
  })]), _c('td', [_c('div', {
    staticClass: "brand-wrap"
  }, [_vm.item.brand && _vm.item.brand.image ? _c('img', {
    attrs: {
      "width": "70",
      "height": "30",
      "src": _vm.item.brand.image
    }
  }) : _vm._e(), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.formatBrand(_vm.item.brand))
    }
  })])]), _vm.isChemical ? _c('td', [_vm.item.volumes && _vm.item.volumes.length > 0 ? _c('div', [_c('span', {
    staticClass: "hover",
    attrs: {
      "id": "tooltip-target-".concat(_vm.item.id)
    }
  }, [_vm._v(_vm._s(_vm.tt('${1} 종류', _vm.item.volumes.length)))]), _c('b-tooltip', {
    attrs: {
      "target": "tooltip-target-".concat(_vm.item.id),
      "triggers": "hover"
    }
  }, _vm._l(_vm.item.volumes, function (volume) {
    return _c('p', {
      key: volume,
      staticClass: "volume"
    }, [_vm._v(_vm._s(volume))]);
  }), 0)], 1) : _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.nullText)
    }
  })]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }