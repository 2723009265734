import list from './list'
import item from './item'

export default {
	namespaced: true,
	modules: {
		list,
		item,
	},
}
