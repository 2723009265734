<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('카테고리 추가') }}</div>
		</template>
		<template>
			<inventory-category-selector />
			<form-category-name
				title="카테고리 이름 입력"
				:isValid="!!categoryInfoName"
				:categoryInfoName="categoryInfoName"
				@change="changeCategoryInfoName"
				ref="form-category-name"
			/>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="confirm">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import InventoryCategorySelector from '../InventoryCategorySelector.vue'
import FormCategoryName from '@/views/common/components/form/FormCategoryName.vue'
export default {
	components: {
		InventoryCategorySelector,
		FormCategoryName,
	},
	computed: {
		...mapState('inventoryGen2/setting', ['categoryInfo']),
	},
	data() {
		return {
			categoryInfoName: '',
		}
	},
	methods: {
		...mapMutations('inventoryGen2/setting', ['setInitCategoryInfo']),
		show() {
			this.categoryInfoName = ''
			this.setInitCategoryInfo()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		changeCategoryInfoName(value) {
			this.categoryInfoName = value
		},
		confirm() {
			if (this.categoryInfoName && this.categoryInfo) {
				const newCategory = { ...this.categoryInfo }
				newCategory['name'] = this.categoryInfoName
				this.$emit('addCategory', newCategory)
				this.hide()
				this.$root.toast(this.tt('추가 완료'), this.tt('카테고리가 추가되었습니다'), 'success')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-form-cargo.scss';
</style>
