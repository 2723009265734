<template>
	<div class="form-input" :class="{ error: isShowRequire }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper">
			<input
				class="form-number-input"
				type="text"
				ref="input-number"
				:placeholder="tt(placeholder)"
				v-model="inputValue"
				@input="changeValue"
			/>
			<b class="unit" v-if="optionList && optionList.length === 1">{{ tt(inputUnit) }}</b>
			<!-- select: START -->
			<form-unit-select-option
				v-else
				ref="unit-select-option"
				:value="tt(unit)"
				:optionList="optionList"
				@input="changeUnit"
			></form-unit-select-option>
			<!-- select: END -->
		</div>
		<span class="error" v-if="isShowRequire">{{ tt(errorMessage) }}</span>
	</div>
</template>
<script>
import FormUnitSelectOption from '@/views/common/components/form/FormUnitSelectOption'
export default {
	name: 'FormTextWithUnit',
	components: { FormUnitSelectOption },
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		unit: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		optionList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			inputValue: '',
			inputUnit: '',
			isShowRequire: false,
		}
	},
	computed: {
		resultValue() {
			const newValue = {
				value: this.inputValue,
				unit: this.inputUnit,
			}
			return newValue
		},
	},
	mounted() {
		if (this.inputValue !== this.value) this.inputValue = this.value ?? null
		if (this.optionList.length === 1 && this.inputUnit != this.optionList[0]) {
			this.inputUnit = this.optionList[0]
			if (this.inputValue && this.inputUnit != this.unit) this.$emit('input', this.resultValue)
		} else if (this.inputUnit !== this.unit) this.inputUnit = this.unit
	},
	watch: {
		unitList: {
			deep: true,
			handler() {
				// currentValue 대응 작업
				if (this.optionList.length === 1 && this.inputUnit != this.optionList[0]) {
					this.inputUnit = this.optionList[0]
					if (this.inputValue && this.inputUnit != this.unit) this.$emit('input', this.resultValue)
				}
			},
		},
		unit(unit) {
			if (this.inputUnit !== this.unit) {
				this.inputUnit = unit
			}
		},
		value(value) {
			if (this.inputValue !== this.value) {
				this.inputValue = value
			}
		},
	},
	methods: {
		changeValue(e) {
			this.inputValue = e.target.value.trimLeft()
			this.$emit('input', this.resultValue)
		},
		changeUnit(unit) {
			this.inputUnit = unit
			this.$emit('input', this.resultValue)
		},
		showRequire() {
			this.isShowRequire = (this.required && !this.inputValue) || (this.inputValue && !this.inputUnit)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-input.scss';
</style>
