<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="form-wrap">
				<form-text-single-line
					:name="'카테고리 이름'"
					:value="categoryInfoName"
					:placeholder="'카테고리 이름을 입력해 주세요'"
					:errorMessage="'이름 입력해주세요!!!'"
					:required="true"
					@input="value => inputFormValue(value)"
				></form-text-single-line>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="confirm">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
export default {
	components: {
		FormTextSingleLine,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			categoryInfoName: '',
		}
	},
	methods: {
		...mapMutations('inventoryGen2/setting', ['setInitCategoryInfo']),
		show(value) {
			this.categoryInfoName = value
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		inputFormValue(value) {
			this.categoryInfoName = value
		},
		confirm() {
			console.log('this.categoryInfoName', this.categoryInfoName)
			if (this.categoryInfoName) {
				this.hide()
				this.$emit('editCategory', this.categoryInfoName)
			} else this.$root.toast(this.tt('편집 실패'), this.tt('카테고리 이름을 입력해 주세요'), 'error')
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-form-cargo.scss';
.form-input {
	width: 100%;
	max-width: 100%;
}
</style>
