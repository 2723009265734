import axios from 'axios'
import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	lookupMemberList: instId => `institute/${instId}/member/list`,
	lookupMember: (instId, id) => `/institute/${instId}/member?id=${id}`,
	updateMember: instId => `/institute/${instId}/member`,
	deleteMember: (instId, ids) => `/institute/${instId}/member?${ParamMaker.setArrayString('ids', ids)}`,
	searchMemberList: (instId, paramsObject) => `/institute/${instId}/member/search?${ParamMaker.setParamUsingObject(paramsObject)}`,
	lookupInitPw: instId => `institute/${instId}/member/initial/password`,
	sendInviteEmail: instId => `/institute/${instId}/member/invite/email`,
	createInviteLink: instId => `/institute/${instId}/member/invite/link`,
	checkFile: instId => `institute/${instId}/member/check/file`,
	checkInfo: instId => `institute/${instId}/member/check`,
	checkInitPw: code => {
		let parameter = `user/initial/password`
		if (code != undefined) parameter += `?code=${code}`
		return parameter
	},
}

const instituteMemberAPI = {
	lookupMemberList: (instId, offset, length, keyword, groupsId) =>
		axiosInstance.get(endPoint.lookupMemberList(instId), {
			params: {
				offset: offset,
				length: length,
				keyword: keyword,
				groupsId: groupsId,
			},
		}),
	lookupMember: (instId, id) => axiosInstance.get(endPoint.lookupMember(instId, id)),
	addMember: (instId, paramsObject) => axiosInstance.post(endPoint.updateMember(instId), paramsObject),
	updateMember: (instId, formData) =>
		axiosInstance.put(endPoint.updateMember(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	deleteMember: (instId, ids) => axiosInstance.delete(endPoint.deleteMember(instId, ids)),
	searchMemberList: (instId, paramsObject) => axiosInstance.get(endPoint.searchMemberList(instId, paramsObject)),
	lookupInitPw: instId => axiosInstance.get(endPoint.lookupInitPw(instId)),
	updateInitPw: (instId, password) => {
		return axiosInstance.put(endPoint.lookupInitPw(instId), password)
	},
	checkFile: (instId, formData) =>
		axiosInstance.post(endPoint.checkFile(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	checkInfo: (instId, paramsObject) => {
		return axiosInstance.post(endPoint.checkInfo(instId), paramsObject)
	},
	checkInitPw: code => {
		return axiosInstance.get(endPoint.checkInitPw(code))
	},
	changeInitPw: payload => {
		return axiosInstance.put(endPoint.checkInitPw(), payload)
	},
	createInviteLink: (instId, data) => {
		return axiosInstance.post(endPoint.createInviteLink(instId), data).then(result => {
			return axios
				.post('https://short.lab-manager.com/pro', {
					dest: result.data.link,
				})
				.then(shortResult => {
					result.data.link = shortResult.data.url
					return result
				})
		})
	},
	sendInviteEmail: (instId, data) => {
		return axiosInstance.post(endPoint.sendInviteEmail(instId), data)
	},
}

export default instituteMemberAPI
