import draftAPI from '@/services/api/purchase/draft'
import _ from 'lodash'
import cookies from 'vue-cookies'
import { draftViewOption } from '@/utils/define/ViewOption'

const state = {
	list: [],
	count: 0,
	loadParams: {
		keyword: '', // 검색어
		offset: 0,
		length: 50,
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
		draftStatus: [], // 상태 필터
		budgetsId: JSON.parse(cookies.get('filter_draft_budget')) ?? [], // 예산 유형 필터
		draftGroupsId: JSON.parse(cookies.get('filter_draft_group')) ?? [], // 기안 연구실 그룹 필터
		startDraftCreateDatetime: '',
		endDraftCreateDatetime: '',
	},
	filter: null,
	filterCookieName: {
		budget: 'filter_draft_budget',
		group: 'filter_draft_group',
	},
	viewOptionCookieName: 'viewOption_draft',
	checkedViewOption: JSON.parse(cookies.get('viewOption_draft')) ?? [],
	viewOptionList: draftViewOption,
}

const getters = {
	keyword: state => state.loadParams.keyword,
	offset: state => state.loadParams.offset,
	length: state => state.loadParams.length,
	selectedStatus: state => (state.loadParams.draftStatus.length == 0 ? 'ALL' : state.loadParams.draftStatus[0]),
	filteredCount: state => state.loadParams.draftGroupsId.length + state.loadParams.budgetsId.length,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setOffset: (state, pageNumber) => {
		state.loadParams.offset = (pageNumber - 1) * length
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			if (loadParams[key] == null) {
				delete state.loadParams[key]
			} else state.loadParams[key] = loadParams[key]
		})
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	setViewOptions: (state, list) => {
		cookies.set(state.viewOptionCookieName, JSON.stringify(list))
		state.checkedViewOption = list
	},
	initFilterCookies: (state, needReset) => {
		if (needReset)
			Object.values(state.filterCookieName).forEach(key => {
				cookies.set(key, JSON.stringify([]))
			})
		state.loadParams = {
			keyword: '', // 검색어
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			draftStatus: [], // 상태 필터
			budgetsId: JSON.parse(cookies.get('filter_draft_budget')) ?? [], // 예산 유형 필터
			draftGroupsId: JSON.parse(cookies.get('filter_draft_group')) ?? [], // 기안 연구실 그룹 필터
			startDraftCreateDatetime: '',
			endDraftCreateDatetime: '',
		}
	},
}

const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		const response = await draftAPI.list(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	getFilter: async ({ commit, rootGetters }) => {
		const response = await draftAPI.filterList(rootGetters.instituteId)
		commit('setFilter', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		dispatch('getList')
	},
	exportExcel: async ({ state, rootGetters }, type) => {
		const response = await draftAPI.listExportExcel(rootGetters.instituteId, type == 'ALL' ? null : state.loadParams)
		return response
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
