<template>
	<div class="form-select-box unit" ref="select-box">
		<div class="select-box">
			<span v-if="placeholder">{{ placeholder }}</span>
			<b-dropdown no-caret toggle-class="text-decoration-none" variant="link" right>
				<template #button-content>
					<p>{{ tt(selectOptionName) }}</p>
				</template>
				<b-dropdown-item @click.stop="clickOption(option)" :key="`option-${option}`" v-for="option in optionList">
					{{ tt(option) }}
				</b-dropdown-item>
			</b-dropdown>
			<!-- 			
			<div ref="form-option-list" class="form-option-list unit" v-if="isShowOption">
				<ul class="option-list">
					<li @click.stop="clickOption(option)" :key="`option-${option}`" v-for="option in optionList">
						{{ option }}
					</li>
				</ul>
			</div> -->
		</div>
	</div>
</template>
<script>
import MixinFormSelect from '@/mixins/form/formSelect'
export default {
	components: {},
	props: {
		optionList: {
			type: Array,
			default: () => [],
		},
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	mixins: [MixinFormSelect],
	data() {
		return {
			isShowOption: false,
			inputValue: '',
		}
	},
	computed: {
		selectOptionName() {
			return this.inputValue ? this.inputValue : this.tt('선택')
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-option-list.scss';
@import '@/styles/scss/form/_form-select-box.scss';
</style>
