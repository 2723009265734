<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ExportInstitutionMemberPopup Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt('기관에서 내보내기') }}
					</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text">
						{{ tt('정말로 멤버를 기관에서 내보내시겠습니까?') }}
					</p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__delete-btn-group Popup__btn-group">
						<button class="Btn__close" type="button" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__delete" type="button" @click="exportInst">
							{{ tt('내보내기') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalExportInstMember',
	mixins: [MixinModal],
	methods: {
		close() {
			this.hide()
		},
		exportInst() {
			this.$emit('export')
		},
	},
}
</script>

<style lang="scss" scoped>

</style>
