import list from './list'
import item from './item'
import history from './history'
import form from './form'
import setting from './setting'
import divide from './divide'
import wait from './wait'
import historyInfo from './historyInfo'
import disposal from './disposal'
import bundle from './bundle'
export default {
	namespaced: true,
	modules: {
		list,
		item,
		history,
		form,
		setting,
		divide,
		wait,
		historyInfo,
		disposal,
		bundle,
	},
}
