<template>
	<div class="info-wrap">
		<p class="info-header">{{ tt('안전정보') }}</p>
		<div class="msds-list">
			<template v-for="key in msdsKeys">
				<div class="msds-item-wrap" :key="key" v-if="msds[key]">
					<button @click="handleClick(key)">
						<msds-item :msds="msds[key]" :msdsKey="key"></msds-item>
					</button>
				</div>
			</template>
		</div>
		<msds-multi-file-viewer
			ref="viewer"
			:msds="msds"
			:ghs="ghs"
			@delete="deleteUploadedMsds"
			@uploadFile="upload"
			@changeFile="changeFile"
			@createGHS="$emit('createGHS')"
			@previewGHS="$emit('manageGHS')"
		></msds-multi-file-viewer>
	</div>
</template>
<script>
import GhsItem from '@/views/inventoryGen2/components/detail/safetyInfo/GhsItem.vue'
import MsdsItem from '@/views/inventoryGen2/components/detail/safetyInfo/MsdsItem.vue'
import MsdsMultiFileViewer from '@/views/common/components/modalGen2/MsdsMultiFileViewer.vue'
import { mapActions, mapMutations } from 'vuex'
export default {
	name: 'SafetyInfo',
	components: { 'ghs-item': GhsItem, MsdsItem, MsdsMultiFileViewer },
	props: {
		msds: {
			type: Object,
			default: null,
		},
		ghs: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			uploadFile: null,
		}
	},
	computed: {
		msdsKeys() {
			return Object.keys(this.msds)
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/item', ['setUploadMsds']),
		...mapActions('inventoryGen2/item', ['deleteMsds', 'uploadMsds']),
		handleClick(key) {
			this.$refs['viewer'].show(key)
		},
		deleteUploadedMsds() {
			this.deleteMsds()
			this.$root.toast('MSDS 삭제 완료', 'MSDS 삭제가 완료되었습니다.', 'success')
		},
		async upload(event) {
			if (event.target.files?.length > 0) {
				await this.setUploadMsds(event.target.files[0])
				await this.uploadMsds()
				this.$root.toast('MSDS 업로드 완료', 'MSDS 업로드가 완료되었습니다.', 'success')
				this.$emit('upload')
			}
		},
		async changeFile(event) {
			if (event.target.files?.length > 0) {
				await this.setUploadMsds(event.target.files[0])
				await this.uploadMsds()
				this.$root.toast('MSDS 파일변경 완료', 'MSDS 파일변경이 완료되었습니다.', 'success')
				this.$emit('changeFile')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.msds-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: 20px;
		.msds-item-wrap {
			& > button {
				margin-right: 20px;
				padding: 0;
			}
		}
		.ghs-item-wrap {
			& > button {
				margin-right: 20px;
				padding: 0;
			}
		}
	}
}
</style>
