<template>
	<div class="select-table-wrapper">
		<table class="select-table">
			<table-header @change="changeAllChecked" />
			<table-item v-for="(item, index) in productInfoList" :key="index" :item="item" :allChecked="allCheck" :index="index" :useSale="useSale" />
		</table>
		<div class="no-data" v-if="productInfoList.length === 0">
			<img :src="require('@/assets/svg/no-data_item.svg')" />
			<p>{{ tt('상품이 없습니다') }}</p>
			<span>{{ tt('구매 요청이 필요한 상품을 추가해주세요') }}</span>
		</div>
		<product-info-price-table
			v-if="productInfoList.length !== 0"
			:total="totalPrice"
			:deliveryFee="totalDeliveryFee"
			:sale="totalSalePrice"
			:vatPrice="vatPrice"
		></product-info-price-table>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ProductInfoPriceTable from './ProductInfoPriceTable.vue'
import TableHeader from './header.vue'
import TableItem from './item.vue'

export default {
	components: {
		ProductInfoPriceTable,
		TableHeader,
		TableItem,
	},
	props: {
		productInfoList: {
			type: Array,
			default: () => {},
		},
		useSale: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			allCheck: false,
		}
	},
	computed: {
		// TODO: 할인에 대한 정의가 되어있지않아서, 임시로 데이터 막 넣음
		// TODO: 배송비에 대한 정의가 되어있지않아서, 임시로 데이터 막 넣음
		totalPrice() {
			let price = 0
			this.productInfoList?.forEach(product => {
				price += product.unitPrice * product.quantity
			})
			return price
		},
		totalDeliveryFee() {
			let price = 0
			// this.productInfoList?.forEach(product => {
			// 	price += product.deliveryFee * product.quantity
			// })
			return price
		},
		totalSalePrice() {
			let price = 0
			this.productInfoList?.forEach(product => {
				if (product.discountPrice) price += product.discountPrice
			})
			return price
		},
		vatPrice() {
			return (this.totalPrice - this.totalSalePrice) / 10
		},
	},
	methods: {
		...mapMutations('draft/item', ['setSelectedItems']),
		changeAllChecked(val) {
			this.allCheck = val
		},
	},
}
</script>

<style lang="scss" scoped>
.select-table-wrapper {
	display: flex;
	flex-direction: column;
	border: 1px solid $INPUT;
	border-radius: 4px;
	width: 100%;
	table {
		border-collapse: collapse;
	}
	.no-data {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 200px;
		color: $SECONDARY_2;
		gap: 5px;
		img {
			width: 48px;
			height: 48px;
			margin-bottom: 10px;
		}
		p {
			font-weight: 700;
			font-size: 16px;
		}
		span {
			font-weight: 400;
			font-size: 14px;
		}
	}
}
</style>
