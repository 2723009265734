<template>
	<div class="header-wrapper">
		<h2 class="header-title">{{ title }}</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<search-box :value="keyword" class="search-box" :placeholder="tt('검색')" @change="changeKeyword"></search-box>
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
					:image="require('@/assets/svg/filter.svg')"
					@click="filterBtnClick"
				></image-button>
				<!-- date button -->
				<date-picker-button
					:image="require('@/assets/svg/list/inventory-calendar.svg')"
					:activeImage="require('@/assets/svg/list/active/calendar-active.svg')"
					:text="'날짜'"
					:subText="dateButtonSubtext"
					:defaultTime="calendarDatetime"
					:isActive="dateButtonSubtext != null"
					@pickDate="pickDate"
					@changeDate="changeCalendar"
				></date-picker-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button :text="'보기옵션'" :image="require('@/assets/svg/ViewOption(Button).svg')" @click="watchBtnClick"></image-button>
				<excel-download-button @downloadAll="exportList('ALL')" @downloadFiltered="exportList('FILTERED')" />
			</div>
		</div>
		<modal-filter ref="modal-filter" @changeLoadParams="setLoadParams" />
		<modal-view-option ref="modal-view-option" />
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import SearchBox from '@/views/components/searchBox'
import ModalFilter from './ModalFilter.vue'
import ModalViewOption from './ModalViewOption.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import DatePickerButton from '@/views/common/components/baseButton/DatePickerButton.vue'
import ExcelDownloadButton from '@/views/common/components/baseButton/ExcelDownloadButton.vue'
import MixinListFixedHeader from '@/views/inventoryGen2/mixin/MixinListFixedHeader'
import { create } from 'domain'

export default {
	mixins: [MixinListFixedHeader],
	components: {
		SearchBox,
		ModalFilter,
		ModalViewOption,
		DatePickerButton,
		ExcelDownloadButton,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState('inventoryGen2/history', ['filterCookieName']),
		...mapGetters('inventoryGen2/history', ['filteredCount', 'keyword']),
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
	},
	data() {
		return {
			isActiveCalendar: false,
			cookieFilterKey: 'inventoryHistoryFilter',
			cookieDatetimeFilterKey: 'inventoryHistoryDatetimeFilter',
		}
	},
	created() {
		this.initList()
	},
	methods: {
		...mapMutations('inventoryGen2/history', {
			setParams: 'setLoadParams',
			initFilterCookies: 'initFilterCookies',
		}),
		...mapActions('inventoryGen2/history', ['setLoadParams', 'exportExcel']),
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		watchBtnClick() {
			this.$refs['modal-view-option'].show()
		},
		pickDate() {
			this.$root.toast(this.tt('기간 필터 적용 완료'), this.tt('기간 필터가 적용되었습니다.'), 'success')
		},
		async changeKeyword(keyword) {
			await this.setLoadParams({ keyword })
		},
		async exportList(type) {
			const response = await this.exportExcel(type)
			window.fileDownload(response, `${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('관리기록')}.xlsx`)
		},

		initList() {
			let query = Object.assign({}, this.$route.query)
			let params = {}
			if (query?.types) {
				params.types = query.types instanceof Array ? query.types : [query.types]
				this.$cookies.set(this.filterCookieName.types, JSON.stringify(params.types))
			}
			if (query?.categoriesId) {
				params.categoriesId = query.categoriesId instanceof Array ? query.categoriesId : [query.categoriesId]
				this.$cookies.set(this.filterCookieName.categoriesId, JSON.stringify(params.categoriesId))
			}
			if (query?.startDatetime) {
				params.startDatetime = query.startDatetime
			}
			if (query?.endDatetime) {
				params.endDatetime = query.endDatetime
			}
			this.setParams(params)
			if (params.startDatetime && params.endDatetime) {
				this.changeCalendar([params.startDatetime, params.endDatetime])
			} else this.createdInit()
			if (Object.keys(query).length > 0) this.$router.replace({ query: {} })
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/list/_list-header.scss';
</style>
