var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "information-wrapper"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('결제 정보')))]), _c('div', {
    staticClass: "info-list"
  }, [_vm.isPaymentMethodEbill ? [_c('div', {
    staticClass: "noti-box"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('${1}까지 입금하지 않으면 라이선스가 <b class=red>비활성화</b>됩니다.', _vm.tt("<b class='red'>".concat(_vm.formatDepositExpireDate(), "</b>"))))
    }
  })]), _c('div', {
    staticClass: "noti-box last"
  }, [_c('p', [_vm._v(" " + _vm._s("".concat(_vm.licenseManagement.schedule.virtualAccountBankName, " ").concat(_vm.licenseManagement.schedule.virtualAccountNumber, " (").concat(_vm.tt('예금주'), ": ").concat(_vm.licenseManagement.schedule.virtualAccountHolder, ")")) + " ")]), _c('p', [_vm._v(" " + _vm._s("".concat(_vm.tt('결제금액'), ": \u20A9").concat(_vm.formatNumberLocale(_vm.licenseManagement.schedule.totalPrice))) + " ")])])] : _vm._e(), _c('div', [_c('p', [_vm._v(_vm._s(_vm.tt('결제 상태')))]), _vm.isPaymentMethodEbill && _vm.isLicenseDepositWait ? _c('p', {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.tt('입금대기')))]) : _c('p', [_vm._v(_vm._s(_vm.tt('결제 완료')))])]), _vm.isPaymentMethodEbill ? _c('div', [_c('p', {
    staticClass: "name"
  }, [_vm._v("입금 기한")]), _c('p', [_vm._v(" " + _vm._s(_vm.formatExpireDate(_vm.purchaseCompleteInfo.depositExpireDatetime)) + " ")])]) : _vm._e(), _c('div', [_c('p', [_vm._v(" " + _vm._s(!_vm.isPaymentMethodEbill ? _vm.tt('결제일') : _vm.tt('주문일')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.formatDatetime(_vm.purchaseCompleteInfo.createDatetime, false)) + " ")])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.tt('결제수단')))]), _c('p', [_vm._v(_vm._s(_vm.paymentMethod2Text(_vm.purchaseCompleteInfo.paymentMethod)))])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.tt('거래서류')))]), _vm.isPaymentMethodEbill ? _c('div', [_c('a', {
    attrs: {
      "download": "",
      "href": _vm.purchaseCompleteInfo.receiptUrl
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('전자세금계산서')) + " ")]), _c('span', [_vm._v(" / ")]), _c('a', {
    attrs: {
      "download": "",
      "href": _vm.purchaseCompleteInfo.virtualAccountConfirmationUrl
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('가상계좌발급서')) + " ")])]) : _c('a', {
    attrs: {
      "download": "",
      "href": _vm.purchaseCompleteInfo.receiptUrl
    }
  }, [_vm._v(_vm._s(_vm.tt('영수증')))])]), _c('div', [_c('p', {
    staticClass: "name"
  }, [_vm._v("결제 금액")]), _c('p', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.tt('₩${1}', _vm.formatNumberLocale(_vm.purchaseCompleteInfo.totalPrice))) + " ")])])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }