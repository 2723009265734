var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('기관 정보 편집')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.complete
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "information-wrapper"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": _vm.logoURL,
      "alt": "icon"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['imageUpload'].click();
      }
    }
  }), _c('input', {
    ref: "imageUpload",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "hidden": ""
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _c('div', {
    staticClass: "input-wrapper",
    "class": {
      error: !_vm.isValidName
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('기관명')) + " "), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('기관명을 입력해주세요')
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('필수값을 입력해주세요')))])]), _c('div', {
    staticClass: "input-wrapper",
    "class": {
      error: !_vm.isValidTrader
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('사업자등록번호')) + " ")]), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('사업자등록번호를 입력해주세요'),
      "maxlength": "12"
    },
    domProps: {
      "value": _vm.form.traderRegistrationNumber
    },
    on: {
      "input": _vm.autoTraderNumber
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('사업자등록번호를 형식에 맞게 입력해주세요')))])]), _c('div', {
    staticClass: "input-wrapper",
    "class": {
      error: !_vm.isValidPhone
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('전화번호')) + " ")]), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('전화번호를 입력해주세요'),
      "maxlength": "13"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": _vm.autoHypenTel
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('연락처를 형식에 맞게 입력해주세요')))])]), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('주소')))]), _c('div', {
    staticClass: "flex"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('주소를 검색해 주세요')
    },
    domProps: {
      "value": _vm.form.address
    }
  }), _c('button', {
    staticClass: "button-white",
    on: {
      "click": _vm.searchAddress
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('주소 검색')) + " ")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.addressDetail,
      expression: "form.addressDetail"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('상세 주소를 입력해주세요')
    },
    domProps: {
      "value": _vm.form.addressDetail
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "addressDetail", $event.target.value);
      }
    }
  })])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }