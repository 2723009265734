<template>
	<div class="ingredient-item" :class="[className]">
		<textarea
			type="text"
			ref="input"
			:placeholder="placeholder"
			:class="{ error: error }"
			v-model="textAreaValue"
			@input="handleInput"
			@change="autoResizeTextarea"
			:readonly="false"
			@focus="showSearch = true"
			@focusout="showSearch = false"
			@click="autoResizeTextarea"
			@keydown="autoResizeTextarea"
			@keyup="autoResizeTextarea"
		/>
		<span class="error" v-if="error"> <img class="error-img" src="@/assets/svg/input-icon-warning.svg" />{{ errorMsg }}</span>
		<form-ingredient-search-list
			v-if="showSearchItemList"
			:keyword="value"
			:className="className"
			@clickItem="clickItem"
		></form-ingredient-search-list>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FormIngredientSearchList from '@/views/common/components/form/ingredient/FormIngredientSearchList.vue'

export default {
	name: 'FormIngredientInput',
	components: { FormIngredientSearchList },
	props: {
		value: {
			type: String,
			default: '',
		},
		className: {
			type: String,
			default: 'name',
		},
		placeholder: {
			type: String,
			default: '',
		},
		isUseSearch: {
			type: Boolean,
			default: false,
		},
		errorMsg: {
			type: String,
			default: '필수값을 입력해주세요.',
		},
		showError: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.isShowError = this.showError
		if (this.textAreaValue !== this.value) {
			this.textAreaValue = this.value
		}
		this.$nextTick(() => {
			this.autoResizeTextarea()
		})
	},
	data() {
		return {
			showSearch: false,
			textAreaValue: null,
			isShowError: false,
		}
	},
	watch: {
		value(value) {
			if (value !== this.textAreaValue) {
				this.textAreaValue = value
				this.isShowError = false
			}
		},
	},
	computed: {
		...mapGetters('ingredient', ['searchList', 'searchParams']),
		showSearchItemList() {
			return this.isUseSearch && this.showSearch && this.value && this.searchList?.length > 0
		},
		error() {
			return ['name', 'ratio'].includes(this.className) && !this.textAreaValue && this.isShowError
		},
	},
	methods: {
		...mapMutations('ingredient', ['setParams', 'setKeywordType', 'setKeyword']),
		...mapActions('ingredient', ['getSearchList', 'addSearchList']),
		handleInput(e) {
			this.textAreaValue = e.target.value
			this.$emit('input', {
				value: e.target.value,
				className: this.className,
			})

			if (this.isUseSearch) {
				this.setKeywordType(this.className)
				this.setKeyword(e.target.value)
				this.getSearchList()
			}
			this.autoResizeTextarea()
		},
		autoResizeTextarea() {
			let textarea = this.$refs['input']
			const overflowText = this.value?.length > 10
			if (textarea) {
				textarea.style.height = 'auto'
				let height = textarea.scrollHeight
				textarea.style.height = !overflowText ? '30px' : `${height + 6}px`
			}
		},
		clickItem(item) {
			this.$emit('clickItem', item)
		},
		showRequire() {
			this.isShowError = true
		},
		initRequire() {
			this.isShowError = false
		},
	},
}
</script>

<style lang="scss" scoped>
.ingredient-item {
	display: flex;
	flex-direction: column;
	&.name {
		width: 250px;
	}
	&.casno {
		width: 140px;
	}
	&.ratio {
		width: 140px;
	}
}

textarea {
	width: calc(100% - 10px);
	border-radius: 5px;
	font-size: 0.875rem;
	font-weight: 400;
	padding-left: 10px;
	padding-top: 3px;
	border: 1px solid $COLOR_CCC;
	min-height: 30px;
	resize: none;

	&:focus {
		border: 1px solid $DEFAULT;
	}

	&:read-only {
		background-color: $GREY_1;
		border: 1px solid $INPUT_BORDER;
		&:focus {
			border: 1px solid $INPUT_BORDER;
		}
	}
	&.error {
		border: 1px solid $RED !important;
	}
}
textarea::placeholder {
	color: $PLACEHOLDER;
}
span.error {
	font-weight: 400;
	font-size: 12px;
	color: $RED;
}
.error-img {
	padding-right: 4px;
}
</style>
