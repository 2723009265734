import _ from 'lodash'
import { LargeClass } from '@/utils/define/ItemCode'
import categoryAPI from '@/services/api/category'
import lmsAPI from '@/services/api/purchase/lms'

const state = {
	productInfo: {},
	category: null,
	categoryInfo: null,
	sellerInfo: {},
	list: [],
	count: 0,
	deliveryId: null,
	loadParams: {
		keyword: '', // 검색어
		keywordType: 'TEXT',
		keywordTarget: 'ALL',
		subKeyword: '',
		sellerIds: [],
		brandIds: [],
		offset: 0,
		length: 10,
		recommendSeed: 0,
		order: ['RECOMMEND'],
		orderDirection: ['DESC'],
	},
	selectedItem: null,
	productList: [],
	productCount: 0,
	productLoadParams: {
		catalogId: 0,
		offset: 0,
		length: 5,
		sellerIds: [],
		options: [],
		order: ['FAVORITE'],
		orderDirection: ['DESC'],
	},
	showError: false,
	cartList: [],
	selectedCartItems: [],
}

const findFieldIdxByKeyName = keyname => state.categoryInfo?.fields?.inventoryFields?.findIndex(it => it.key == keyname)

const getters = {
	productInfo: state => state.productInfo,
	sellerInfo: state => state.sellerInfo,
	category: state => state.category,
	categoryInfo: state => {
		const categoryInfo = _.cloneDeep(state.categoryInfo)
		const currentVolumeIndex = findFieldIdxByKeyName('currentVolume')
		const volumeIndex = findFieldIdxByKeyName('volume')
		if (categoryInfo?.classification === LargeClass.CHEMICAL && currentVolumeIndex !== -1) {
			categoryInfo.fields.inventoryFields[currentVolumeIndex].unit = categoryInfo?.fields?.inventoryFields[volumeIndex].unit
		}
		return categoryInfo
	},
	deliveryId: state => state.deliveryId,
	cargoFormData: state => {
		if (state.category) {
			const formData = new FormData()
			Object.entries(state.productInfo).forEach(([key, value]) => {
				switch (key) {
					case 'ingredient':
						value.forEach((item, idx) => {
							formData.append(`${key}[${idx}][name]`, item.name)
							formData.append(`${key}[${idx}][ratio]`, item.ratio)
							if (item.casno) {
								formData.append(`${key}[${idx}][casno]`, item.casno)
							}
						})
						break
					case 'images':
					case 'tagsId':
					case 'qrSticker':
						value.forEach(item => formData.append(key, item))
						break
					default:
						formData.append(key, value)
						break
				}
			})
			if (state.uploadMsds) {
				formData.append('msds', state.uploadMsds)
			}
			return formData
		} else {
			return null
		}
	},
	showError: state => state.showError,
	isProductValid: state => {
		if (state.categoryInfo) {
			const requireFields = state.categoryInfo?.fields.inventoryFields
				.filter(f => !f.readOnly && f.isRequire && f.isPurchase)
				.map(item => item.key)
			const useUnitFields = state.categoryInfo?.fields.inventoryFields
				.filter(f => (!f.readOnly && f.isPurchase && f?.unit?.length > 0) || f.key === 'currentVolume')
				.map(item => item.key)
			let isRequireData = true
			requireFields.forEach(requireKey => {
				// 필수 값인 경우
				if (!state.productInfo[requireKey]) {
					isRequireData = false
				}
			})
			if (!state.productInfo.unitPrice) isRequireData = false

			// Unit이 있는 값인 경우
			useUnitFields.forEach(unitKey => {
				if (state.productInfo[unitKey] && !state.productInfo[`${unitKey}Unit`]) {
					// 값은 있지만 Unit이 없는 경우
					isRequireData = false
				}
			})

			return isRequireData
		} else return false
	},
	isUnitValid: state => {
		const useUnitFields = state.categoryInfo?.fields?.inventoryFields
			.filter(f => f.isActive && !f.readOnly && f?.unit?.length > 0 && f.key !== 'currentVolume')
			.map(item => item.key)
		let isRequireData = true
		useUnitFields?.forEach(unitKey => {
			if (!!state.item?.[unitKey] && !state.item?.[`${unitKey}Unit`]) {
				// 값은 있지만 Unit이 없는 경우
				isRequireData = false
			}
		})
		return isRequireData
	},
	isIngredientValid: state => {
		if (state.productInfo.ingredient) {
			return state.productInfo.ingredient.find(i => !i.name || !i.ratio) === undefined
		} else return true
	},
	isSellerValid: state => {
		return !!state.sellerInfo.name
	},
	lowest: state => {
		let priceList = state.productList.filter(item => item.unitPrice !== 0).map(item => item.unitPrice)
		if (priceList.length === 0) return 0
		return priceList.reduce((prev, curr) => {
			return prev <= curr ? prev : curr
		})
	},
	quick: state => {
		let shippingList = state.productList.filter(item => item.shippingDay && item.shippingDay !== 0).map(item => item.shippingDay)
		if (shippingList.length === 0) return 0
		return shippingList.reduce((prev, curr) => {
			return prev <= curr ? prev : curr
		})
	},
}

const mutations = {
	setDeliveryId: (state, id) => {
		state.deliveryId = id
	},
	setInitProductInfo: state => {
		state.productInfo = {}
		state.category = null
		state.categoryInfo = null
		state.sellerInfo = {}
	},
	setProductInfo: (state, productInfo) => {
		state.productInfo = productInfo
	},
	setCategory: (state, category) => {
		state.category = category
		if (category) {
			state.productInfo.categoryType = category.type
			state.productInfo.categoryId = category.id
		}
	},
	setCategoryInfo: (state, categoryInfo) => {
		state.categoryInfo = {
			fields: {
				inventoryFields: categoryInfo.inventoryFields.filter(it => it.isPurchase),
			},
			...state.category,
		}
	},
	setSellerInfo: (state, sellerInfo) => {
		state.sellerInfo = sellerInfo
	},
	//
	setInitSearchProduct: state => {
		state.list = []
		state.count = 0
		state.loadParams = {
			keyword: '', // 검색어
			keywordType: 'TEXT',
			keywordTarget: 'ALL',
			subKeyword: '',
			sellerIds: [],
			brandIds: [],
			offset: 0,
			length: 10,
			recommendSeed: 0,
			order: ['RECOMMEND'],
			orderDirection: ['DESC'],
		}
		state.selectedItem = null
		state.productList = []
		state.productCount = 0
		state.productLoadParams = {
			catalogId: 0,
			offset: 0,
			length: 5,
			sellerIds: [],
			options: [],
			order: ['FAVORITE'],
			orderDirection: ['DESC'],
		}
	},
	setList: (state, list) => {
		state.list = list
	},
	setCount: (state, count) => {
		state.count = count
	},
	setShowError: (state, showError) => {
		state.showError = showError
	},
	setOffset: (state, pageNumber) => {
		state.loadParams.offset = (pageNumber - 1) * length
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			state.loadParams[key] = loadParams[key]
		})
		if (state.loadParams.offset == 0) state.loadParams.recommendSeed = parseInt(Math.random() * 1000)
	},
	setSelectedItem: (state, item) => {
		state.productLoadParams = {
			catalogId: 0,
			offset: 0,
			length: 5,
			sellerIds: [],
			options: [],
			order: ['FAVORITE'],
			orderDirection: ['DESC'],
		}
		state.selectedItem = item
	},
	setProductList: (state, list) => {
		state.productList = list
		state.productLoadParams.offset = state.productList.length
	},
	setProductCount: (state, count) => {
		state.productCount = count
	},
	setProductOffset: (state, pageNumber) => {
		state.productLoadParams.offset = (pageNumber - 1) * length
	},
	setProductLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			state.productLoadParams[key] = loadParams[key]
		})
	},
	setCartList: (state, data) => {
		state.cartList = data
	},
	setSelectedCartItems: (state, data) => {
		state.selectedCartItems = data
	},
	setCartProductCount: (state, payload) => {
		const { index, quantity } = payload
		state.cartList[index].quantity = quantity
	},
}

const actions = {
	getCategoryInfo: async ({ commit, rootGetters }, type) => {
		const response = await categoryAPI.getCategoryFieldsByType(rootGetters.instituteId, {
			type: type,
		})
		commit('setCategoryInfo', response.data)
	},
	selectCategory: async ({ commit, dispatch }, category) => {
		commit('setCategory', category)
		if (category) {
			dispatch('getCategoryInfo', category.type)
		}
	},
	//
	getList: async ({ state, commit, rootGetters }) => {
		// let params = _.cloneDeep(state.loadParams)
		// let keyword = params.keyword
		// if (state.loadParams.subKeyword) keyword += ' ' + state.loadParams.subKeyword
		// params.keyword = keyword
		const response = await lmsAPI.list(rootGetters.instituteId, { ...state.loadParams, deliveryId: state.deliveryId })
		commit('setList', response.data.list)
		commit('setCount', response.data.count)
	},
	addList: async ({ state, commit, rootGetters }) => {
		let { length, offset } = state.loadParams
		// let params = _.cloneDeep(state.loadParams)
		// let keyword = params.keyword
		// if (state.loadParams.subKeyword) keyword += ' ' + state.loadParams.subKeyword
		// params.keyword = keyword
		if (state.count > offset + length) {
			await commit('setLoadParams', { ...state.loadParams, offset: offset + length })
			const response = await lmsAPI.list(rootGetters.instituteId, { ...state.loadParams, deliveryId: state.deliveryId })
			await commit('setList', [...state.list, ...response.data.list])
		}
	},
	setLoadParams: async ({ commit, dispatch, state }, loadParams) => {
		commit('setLoadParams', loadParams)
		if (state.loadParams.keyword) dispatch('getList')
		else {
			commit('setList', [])
			commit('setCount', 0)
		}
	},
	getItem: async ({ commit, dispatch, rootGetters }, { id, isRecommend }) => {
		const response = await lmsAPI.item(rootGetters.instituteId, { deliveryId: state.deliveryId, catalogId: id })
		commit('setSelectedItem', { ...response.data, isRecommend })
		dispatch('setProductLoadParams', { catalogId: id })
	},
	//
	getProductList: async ({ state, commit, rootGetters }, isRefresh = true) => {
		let { offset } = state.productLoadParams
		const response = await lmsAPI.productList(rootGetters.instituteId, {
			...state.productLoadParams,
			deliveryId: state.deliveryId,
			offset: isRefresh ? 0 : offset,
		})
		commit('setProductList', isRefresh ? response.data.list : [...state.productList, ...response.data.list])
		commit('setProductCount', response.data.count)
	},
	setProductLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setProductLoadParams', loadParams)
		dispatch('getProductList')
	},
	getCartList: async ({ state, commit, rootGetters }) => {
		const response = await lmsAPI.cartList(rootGetters.instituteId, { deliveryId: state.deliveryId })
		commit('setCartList', response.data)
	},
}
export default { namespaced: true, state, getters, mutations, actions }
