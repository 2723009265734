<template>
	<div :key="componentKey">
		<div class="user-info-box" @click="showAccountSetting">
			<img :src="userInfo.image ? userInfo.image : require('@/assets/svg/member-photo-default.svg')" />
			<p>{{ tt('${1}님', userInfo.name) }}</p>
		</div>

		<!--modals-->
		<modal-account-setting ref="modal-account-setting"></modal-account-setting>
	</div>
</template>

<script>
import userAPI from '@/services/api/institute/user'
import ModalAccountSetting from '@/views/app/modals/ModalAccountSetting.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
	components: {
		ModalAccountSetting,
	},
	async created() {
		await this.loadUserInfo()
		this.userInfo = this.user
	},
	mounted() {
		setTimeout(() => {
			this.componentKey += 1
		}, 1000)
	},
	computed: {
		...mapState('user', ['user']),
	},
	data() {
		return {
			userInfo: {},
			componentKey: 0,
		}
	},
	watch: {},
	methods: {
		...mapActions('user', ['loadUserInfo']),
		showAccountSetting() {
			if (this.userInfo != null) {
				this.$refs['modal-account-setting'].show()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.user-info-box {
	position: absolute;
	top: 13px;
	right: 20px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 34px;
	padding: 0 10px;
	border-radius: 4px;
	transition: 0.3s;
	cursor: pointer;
	> img {
		width: 24px;
		height: 24px;
		object-fit: cover;
		border: 1px solid $INPUT;
		margin-right: 5px;
		border-radius: 24px;
		box-sizing: border-box;
	}
	p {
		font-size: 0.875rem;
		font-weight: 700;
	}
	&:hover {
		background-color: $GREY_4;
	}
}
</style>
