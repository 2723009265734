<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('태그 추가') }}</div>
		</template>
		<template>
			<div class="form-input">
				<p class="name">{{ tt('태그') }}<span class="required">*</span></p>
				<div class="input-wrapper">
					<input type="text" :class="{ error: isInvalid }" :placeholder="tt('태그를 입력해주세요')" v-model="inputValue" />
				</div>
				<span v-if="isInvalid" class="error">{{ tt('태그를 입력해주세요') }}</span>
			</div>
		</template>
		<template #modal-footer="{ hide }">
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="confirm">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'ModalAddTag',
	data() {
		return {
			inputValue: '',
			isInvalid: false,
		}
	},
	methods: {
		...mapActions('tag', ['addTag']),
		show() {
			this.inputValue = ''
			this.isInvalid = false
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async confirm() {
			if (!this.inputValue) {
				this.isInvalid = true
			} else {
				try {
					await this.addTag(this.inputValue)
				} catch (e) {
					if (e.response.status === 412) {
						this.$emit('activeTag', this.inputValue)
					} else
						this.$root.toast(
							this.tt('태그 추가 실패'),
							this.tt(e.response.data.msg ? e.response.data.msg : '태그 추가에 실패하였습니다'),
							'error'
						)
				}
				this.hide()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-add-tag.scss';
</style>
