var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "hide-footer": "",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.tt('GHS 라벨 생성 가이드')) + " ")])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "content"
  }, [_c('h4', [_vm._v(_vm._s(_vm.tt('GHS 라벨 작성 방법')))]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.tt('1. MSDS 문서 내 위험ㆍ유해성 항목을 참고하여 위험성 분류 (물리적 위험성, 건강유해성, 환경유해성)를 선택합니다.')) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.tt('2. MSDS 문서 내 유해ㆍ위험 문구 항목을 참고하여 분류기준에 따라 유해 위험성을 알리는 문구를 모두 선택합니다.')) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.tt('3. 다음버튼을 누른 뒤 공급자 기관명, 연락처, 주소를 입력하여 GHS 라벨 생성을 완료합니다.')) + " ")])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }