var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-wrapper"
  }, [_c('div', {
    staticClass: "title-area"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " ")]), _c('div', {
    staticClass: "subtitle"
  }, [_c('b', {
    "class": [_vm.item.store.paymentStatus, _vm.isCancelOrReturn]
  }, [_vm._v(_vm._s(_vm.tt(_vm.statusKor)))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.subtitle)
    }
  })])]), _c('div', {
    staticClass: "btn-area"
  }, [_vm.orderId ? _c('div', [_c('base-button', {
    on: {
      "click": function click($event) {
        return _vm.click('STORE_ORDER');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('주문 상세보기')))]), _vm.canPay ? _c('base-button', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.click('STORE_PAYMENT');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('결제하기')))]) : _c('base-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.tt(_vm.tooltipReason ? _vm.tooltipReason : ''),
      expression: "tt(tooltipReason ? tooltipReason : '')",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "color": "cant"
    }
  }, [_vm._v(_vm._s(_vm.tt('결제하기')))])], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }