<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade @close="$emit('close')">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="form-wrap">
				<div class="form-header">
					{{ tt('공급자 정보') }}
				</div>
				<div class="form-data-wrap">
					<component
						ref="form"
						v-for="field in fields"
						v-bind:is="formType(field)"
						:key="`${field.key}`"
						:name="tt(field.name)"
						:value="inputValue(field)"
						:placeholder="tt(placeholder(field))"
						:errorMessage="tt(placeholder(field))"
						@input="value => handleInput(field, value)"
						:maxLength="maxLength(field)"
						:required="isRequired(field)"
					></component>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<modal-footer
				okText="다음으로"
				@goPrev="$emit('goPrev')"
				@ok="goNext"
				@cancel="
					$emit('close')
					hide()
				"
				isPrev
			></modal-footer>
		</template>
	</b-modal>
</template>

<script>
import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
import FormPhone from '@/views/common/components/form/FormPhone.vue'
import ModalFooter from '@/views/common/components/modalGen2/components/footer/ModalFooter.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'ModalGHSForm',
	components: { ModalFooter },
	props: {},
	data() {
		return {
			value: null,
		}
	},
	computed: {
		...mapGetters('modal/ghs', ['provider', 'isProviderValid', 'mode', 'etc']),
		formType() {
			return field => {
				switch (field.key) {
					case 'name':
					case 'address':
					case 'etc':
						return FormTextSingleLine
					case 'phone':
						return FormPhone
				}
			}
		},
		fields() {
			return [
				{
					name: '공급자 기관명',
					key: 'name',
				},
				{
					name: '공급자 연락처',
					key: 'phone',
				},
				{
					name: '공급자 주소',
					key: 'address',
				},
				{
					name: '기타',
					key: 'etc',
				},
			]
		},
		placeholder() {
			return field => {
				switch (field.key) {
					case 'name':
						return `${field.name}을 입력해주세요`
					case 'phone':
					case 'address':
					case 'etc':
						return `${field.name}를 입력해주세요`
					default:
						return `${field.name} 입력`
				}
			}
		},
		inputValue() {
			return field => {
				if (field.key === 'etc') {
					return this.etc
				}
				return this.value?.[field.key]
			}
		},
		isRequired() {
			return field => {
				return field.key !== 'etc'
			}
		},
		maxLength() {
			return field => {
				return field.key !== 'etc' ? null : 40
			}
		},
		title() {
			return this.mode === 'EDIT' ? 'GHS 라벨 편집' : 'GHS 라벨 생성'
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('modal/ghs', ['setProvider', 'setEtc']),
		show() {
			this.$nextTick(() => {
				this.value = null
				this.$refs['modal'].show()
				if (this.provider) {
					this.value = this.provider
				}
			})
		},
		hide() {
			this.$refs['modal'].hide()
		},
		handleInput(field, value) {
			if (field.key === 'etc') {
				this.setEtc(value)
			} else {
				const obj = {}
				obj[field.key] = value
				this.setProvider({ ...this.provider, ...obj })
			}
		},
		goNext() {
			if (this.isProviderValid) {
				this.$emit('goNext')
			} else {
				this.$refs['form'].forEach(item => item.showRequire())
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';

::v-deep .modal-header {
	padding: 20px;
	border: 0;
	background: $GREY_1;
	.title {
		font-size: 18px;
		font-weight: 700;
	}
	.button-gray {
		position: absolute;
		top: 20px;
		right: 65px;
		background: $GREY_4;
		display: flex;
		border-radius: 4px;
		padding: 5px 10px;
		height: 30px;
		font-size: 14px;
		font-weight: 700;
		&:hover {
			background: $HOVER_BUTTON_COMMON;
		}
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 700px;
	.modal-content {
		background-color: $LAB_WHITE;
		border: 0;
	}
}

::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	padding: 0px;
	justify-content: center;
	align-items: center;
	gap: 20px;
	overflow: hidden;
	background: $GREY_1;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	.content {
		height: 100%;
		border-top: 1px solid $DIVIDER;
		h4 {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 8px;
		}
		.text {
			font-size: 14px;
		}
	}
}

::v-deep .modal-footer {
	padding: 20px;
	border: 0;
	.modal-footer-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.footer {
			display: flex;
			width: 100%;
			gap: 10px;
			justify-content: space-between;

			.left {
				display: flex;
			}
			.right {
				width: 210px;
				gap: 10px;
				display: flex;
			}
			button {
				border: 0;
				box-shadow: none;
				display: flex;
				height: auto;
				align-items: center;
				cursor: pointer;
				white-space: pre;
				min-width: 100px;
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 700;
				border-radius: 5px;
				justify-content: center;
			}
			.button-white {
				background: $LAB_WHITE;
				border: 1px solid $DIVIDER;
				color: #111 !important;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
						linear-gradient(0deg, #fff, #fff);
				}
			}
			.button-primary {
				background: $LAB_YELLOW;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
				}
			}
		}
	}
}
</style>
