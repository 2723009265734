import locationAPI from '@/services/api/institute/location'
import storageAPI from '@/services/api/institute/storage'

const Storage = {
	namespaced: true,
	state: () => ({
		storageTree: undefined,
		selectedStorage: undefined,
		warningList: undefined,
		storageList: [],
		editStorageId: undefined,
	}),
	getters: {
		storageTree(state) {
			return state.storageTree
		},
		selectedStorage(state) {
			return state.selectedStorage
		},
		warningList(state) {
			return state.warningList
		},
		getStorageList(state) {
			return state.storageList
		},
	},
	mutations: {
		setStorageTree(state, payload) {
			state.storageTree = payload
		},
		setSelectedStorage(state, payload) {
			state.selectedStorage = payload
		},
		setWarningList(state, payload) {
			state.warningList = payload
		},
		setStorageList(state, payload) {
			state.storageList = payload
		},
		setEditStorageId(state, payload) {
			state.editStorageId = payload
		},
	},
	actions: {
		loadStorageTree: async ({ commit, dispatch, rootGetters }, payload) => {
			const { institutionId, storageId } = payload
			const response = await storageAPI.lookupStorageTree(rootGetters.instituteId, storageId)
			commit('setStorageTree', response.data)
			const detailPayload = {
				institutionId: institutionId,
				storageId: response.data.id,
			}
			await dispatch('loadStorageDetail', detailPayload)
		},
		loadStorageDetail: async ({ commit, rootGetters }, payload) => {
			const { institutionId, storageId } = payload
			const response = await storageAPI.lookupDetailStorageTree(rootGetters.instituteId, storageId)
			commit('setSelectedStorage', response.data)
		},
		loadWarningList: async ({ commit }) => {
			const response = await locationAPI.lookupWarningList()
			commit('setWarningList', response.data)
		},
		totalStorageTree: async ({ commit, rootGetters }, instId) => {
			const response = await storageAPI.totalStorageTree(rootGetters.instituteId)
			commit('setStorageList', response.data)
		},
	},
}
export default Storage
