import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	lookupChemicalList: (instId, offset, length, order, orderDirection, keyword) => {
		let parameter = `/institute/${instId}/safety/harmfulchemical/list?offset=${offset}&length=${length}`
		if (order.length != 0) parameter += `&${ParamMaker.setArrayString('order', order)}`
		if (orderDirection.length != 0) parameter += `&${ParamMaker.setArrayString('orderDirection', orderDirection)}`
		if (keyword != undefined) parameter += `&keyword=${keyword}`
		return parameter
	},
	exportChemicalDiaryExcel: instId => `/institute/${instId}/safety/harmfulchemical/mng/export/excel`,
	exportChemicalExcel: instId => `/institute/${instId}/safety/harmfulchemical/list/export/excel`,
	exportChemicalExcelStartDate: instId => `/institute/${instId}/safety/harmfulchemical/mng/export/excel/startDatetime`,
}

const chemcialAPI = {
	lookupChemicalList: (instId, offset, length, order, orderDirection, keyword) =>
		axiosInstance.get(endPoint.lookupChemicalList(instId, offset, length, order, orderDirection, keyword)),
	exportChemicalDiaryExcel: (instId, body) =>
		axiosInstance.post(endPoint.exportChemicalDiaryExcel(instId), body, {
			responseType: 'blob',
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	exportChemicalExcel: instId =>
		axiosInstance.post(
			endPoint.exportChemicalExcel(instId),
			{},
			{
				responseType: 'blob',
			}
		),
	exportChemicalExcelStartDate: (instId, body) =>
		axiosInstance.post(endPoint.exportChemicalExcelStartDate(instId), body, {
			responseType: 'json',
		}),
}

export default chemcialAPI
