var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "menu"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$emit('addDivide');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/icon-subdivide.svg"),
      "width": "24",
      "height": "24"
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.tt('소분 용량 입력')) + " ")])])]), _c('div', {
    staticClass: "right"
  }, [_c('button', {
    staticClass: "button-gray",
    attrs: {
      "disabled": _vm.noItem
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('selectStorage');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/icon-storage-add".concat(_vm.noItem ? '-disabled' : '', ".svg")),
      "width": "24",
      "height": "24"
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.tt('보관함 지정')))])]), _c('button', {
    staticClass: "button-gray",
    attrs: {
      "disabled": _vm.noItem
    },
    on: {
      "click": function click($event) {
        return _vm.removeSelectedItem();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/icon-trash".concat(_vm.noItem ? '-disabled' : '', ".svg")),
      "width": "24",
      "height": "24"
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.tt('삭제')))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }