var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.groupInfo && _vm.groupInfo.type != 'INSTITUTE' ? _c('div', {
    staticClass: "Orgainzation__access"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('접근 권한')))]), _vm.userPermission.institute.organization.update || _vm.isStart ? _c('button', {
    staticClass: "ml-auto btn Dropdown__btn",
    "class": [{
      point: _vm.isStart && _vm.order == 3 || _vm.isHint
    }, {
      pointTop: _vm.isStart && _vm.order == 3 || _vm.isHint
    }],
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleDropdownOn.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Authority.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('접근권한 관리')) + " "), _vm.isDropdownOn ? _c('div', {
    staticClass: "Dropdown__menu"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openModalAddGroupAuth.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('그룹')) + " ")]), _c('button', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openModalAddMemberAuth.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('멤버')) + " ")])]) : _vm._e(), _vm.isStart && _vm.order == 3 || _vm.isHint ? _c('div', {
    staticClass: "order-balloon"
  }, [_c('div', {
    staticClass: "order-top"
  }, [_vm._m(0), !_vm.isHint ? _c('button', {
    on: {
      "click": _vm.closeOrder
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })]) : _vm._e()]), _c('h6', [_vm._v(_vm._s(_vm.tt('3. 접근 가능한 그룹 또는 멤버 추가하기')))]), _c('p', [_vm._v(_vm._s(_vm.tt('그룹에 소속된 멤버를 추가해보세요.')))])]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "Organization__access-list"
  }, [_c('ul', {
    staticClass: "horizational-list"
  }, [_vm._l(_vm.access.groups, function (group, idx) {
    return _c('li', {
      key: "group-".concat(idx),
      staticClass: "Organization__access-item no-cursor"
    }, [_c('div', {
      staticClass: "box"
    }, [_c('h3', [_vm._v(_vm._s(_vm.getGroupInitialCharacter(group.name)))])]), _c('div', {
      staticClass: "Organization__access-item-info"
    }, [_c('h6', [_vm._v(_vm._s(group.name))]), _c('p', [_vm._v(_vm._s(group.memberCount) + _vm._s(_vm.tt('명')))])]), _vm.userPermission.institute.organization.update ? _c('button', {
      staticClass: "btn Btn__select Dropdown__btn"
    }, [_vm._v(" " + _vm._s(_vm.tt(_vm.showAccessPermission(group.accessPermission))) + " "), _c('div', {
      staticClass: "Dropdown__menu"
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.updatePermission('read', group);
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('조회 허용')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연구실의 정보, 물품 등을 조회할 수 있습니다.')) + " ")])]), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.updatePermission('update', group);
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('편집 허용')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연구실의 정보, 물품 등을 조회하고 편집할 수 있습니다.')) + " ")])]), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.removePermission(group);
        }
      }
    }, [_c('h6', {
      staticClass: "red-color"
    }, [_vm._v(" " + _vm._s(_vm.tt('접근 권한 없애기')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('접근 권한을 없앱니다.')) + " ")])])])]) : _vm._e()]);
  }), _vm._l(_vm.access.users, function (user, idx) {
    return _c('li', {
      key: "user-".concat(idx),
      staticClass: "Organization__access-item no-cursor"
    }, [_c('img', {
      attrs: {
        "src": user.image ? user.image : require('@/assets/svg/member-photo-default.svg')
      }
    }), _c('div', {
      staticClass: "Organization__access-item-info"
    }, [_c('h6', [_vm._v(_vm._s(user.name))]), _c('p', [_vm._v(_vm._s(user.email))])]), _vm.userPermission.institute.organization.update ? _c('button', {
      staticClass: "btn Btn__select Dropdown__btn"
    }, [_vm._v(" " + _vm._s(_vm.tt(_vm.showAccessPermission(user.accessPermission))) + " "), _c('div', {
      staticClass: "Dropdown__menu"
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.updatePermission('read', user);
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('조회 허용')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연구실의 정보, 물품 등을 조회할 수 있습니다.')) + " ")])]), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.updatePermission('update', user);
        }
      }
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('편집 허용')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연구실의 정보, 물품 등을 조회하고 편집할 수 있습니다.')) + " ")])]), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.removePermission(user);
        }
      }
    }, [_c('h6', {
      staticClass: "red-color"
    }, [_vm._v(" " + _vm._s(_vm.tt('접근 권한 없애기')) + " ")]), _c('p', [_vm._v(_vm._s(_vm.tt('접근 권한을 없앱니다.')))])])])]) : _vm._e()]);
  })], 2), _vm.noAccessData ? _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_setting.svg"),
      "alt": "no-image"
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.tt('접근 권한을 가진 그룹 또는 멤버가 없습니다')))]), _c('p', [_c('b', [_vm._v("[" + _vm._s(_vm.tt('접근권한 관리')) + "]")]), _vm._v(" " + _vm._s(_vm.tt('버튼을 클릭하여 추가해보세요')) + " ")])]) : _vm._e()])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order"
  }, [_c('span'), _c('span'), _c('span', {
    staticClass: "active"
  })]);
}]

export { render, staticRenderFns }