var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail-wrapper"
  }, [_c('div', {
    staticClass: "common-header-wrapper"
  }, [_c('div', [_c('h2', [_vm._v(_vm._s(_vm.tt('상품주문')))])])]), _c('div', {
    staticClass: "common-detail-wrapper regist-step2-wrapper"
  }, [_c('div', {
    staticClass: "product-info-wrapper"
  }, [_c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('상품 정보')))]), _c('div', {
    staticClass: "delivery-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/store/icon-location.svg")
    }
  }), _c('p', {
    on: {
      "click": _vm.showModalAddress
    }
  }, [_vm._v(_vm._s(_vm.addressText))]), _c('span', {
    on: {
      "click": _vm.showModalAddress
    }
  }, [_vm._v(_vm._s(_vm.deliveryInfo ? '변경' : '등록'))])])]), _c('div', {
    staticClass: "btn-area"
  }, [_vm.draftInfo.purchaseType == 'INTERNAL' ? [_c('div', {
    staticClass: "left-btn"
  }, [_c('image-button', {
    attrs: {
      "image": require('@/assets/svg/store/labmanager-store.svg'),
      "text": '에서 상품검색'
    },
    on: {
      "click": function click($event) {
        return _vm.storeShowModal('modal-store-search-product');
      }
    }
  }), _c('merck-tooltip', {
    attrs: {
      "cssVariable": _vm.cssVariable,
      "isAnimation": true,
      "isClose": false,
      "isBrandModal": false
    }
  }, [_c('img', {
    attrs: {
      "width": "245px",
      "src": require("@/assets/images/icon-sale-search.png")
    }
  })])], 1)] : [_c('image-button', {
    attrs: {
      "image": require('@/assets/svg/Add(Button).svg'),
      "text": '상품 추가'
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-add-product'].show();
      }
    }
  })], _c('base-button', {
    attrs: {
      "disabled": _vm.selectedItems.length === 0
    },
    on: {
      "click": _vm.btnSelectedDelete
    }
  }, [_vm._v(_vm._s(_vm.tt('선택 삭제')))])], 2), _c('div', [_c('product-info-regist-table', {
    attrs: {
      "productInfoList": _vm.draftInfo.purchaseItems
    }
  })], 1)]), _c('store-order-info-new'), _c('budget-info', {
    ref: "budget-info",
    attrs: {
      "isValid": _vm.isValid
    }
  }), _vm.getSelectedBudget ? _c('div', {
    staticClass: "budget-status-wrapper"
  }, [_c('total-amount', {
    attrs: {
      "title": '예산 현황',
      "info": _vm.amountForm,
      "budget": _vm.getSelectedBudget
    }
  })], 1) : _vm._e(), _c('orderer-info', {
    ref: "orderer-info",
    attrs: {
      "isValid": _vm.isValid
    }
  }), _c('recipient-info', {
    ref: "recipient-info",
    attrs: {
      "isValid": _vm.isValid
    },
    on: {
      "getOrdererInfo": _vm.getOrdererInfo
    }
  })], 1), _c('div', {
    staticClass: "common-detail-bottom"
  }, [_vm.draftInfo.purchaseItems.length > 0 && _vm.isMerckTooltipShow ? _c('merck-tooltip', {
    attrs: {
      "cssVariable": _vm.cssVariable2
    }
  }, [_c('img', {
    attrs: {
      "width": "360px",
      "src": require("@/assets/images/icon-sale-order.png")
    }
  })]) : _vm._e(), _c('BadgeLimitCredit', {
    staticStyle: {
      "position": "absolute",
      "left": "30px"
    }
  }), _c('base-button', {
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "size": "lg",
      "color": 'primary'
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.tt('주문하기')))])], 1), _c('modal-confirm', {
    ref: "modal-estimate-delete",
    attrs: {
      "title": "상품 삭제",
      "content": "견적서로 추가된 상품은 전체 삭제됩니다.<br>상품 전체 삭제하시겠습니까?",
      "confirmText": "전체 삭제"
    }
  }), _c('modal-add-product', {
    ref: "modal-add-product",
    on: {
      "addProduct": _vm.addPurchaseItem
    }
  }), _c('modal-store-search-product', {
    ref: "modal-store-search-product"
  }), _c('modal-estimate', {
    ref: "modal-estimate",
    on: {
      "addProductByEstimate": _vm.addProductByEstimate
    }
  }), _c('modal-add-cart-product', {
    ref: "modal-add-cart-product"
  }), _c('modal-payment-requirement', {
    on: {
      "ok": _vm.submit
    }
  }), _c('modal-confirm', {
    ref: "modal-order-confirm",
    attrs: {
      "title": "상품을 주문하시겠습니까?",
      "content": "상품주문은 후불결제 방식으로 진행됩니다. 랩매니저 스토어에 주문이 접수되며,<br>판매자의 주문확인 및 주문승인 처리 후 배송이 시작됩니다.",
      "confirmText": "주문하기"
    }
  }), _c('modal-confirm', {
    ref: "modal-requirement-out",
    attrs: {
      "title": "페이지 이동",
      "confirmText": "나가기",
      "content": "주문 필수 정보를 입력하여야 상품 주문이 가능합니다.<br>페이지를 나가시겠습니까?"
    }
  }), _c('modal-confirm', {
    ref: "modal-store",
    attrs: {
      "title": "랩매니저 스토어",
      "confirmText": "랩매니저 스토어로 가기",
      "content": "랩매니저 스토어 이용 기록이 없는 기관입니다.<br>랩매니저 스토어에서 기관 설정을해주세요"
    }
  }), _c('modal-confirm', {
    ref: "modal-can-order",
    attrs: {
      "title": "주문불가",
      "confirmText": "확인",
      "variant": "warning",
      "content": "배송 가능한 상품이 없습니다.<br>상품을 다시 한번 확인해주세요."
    }
  }), _c('modal-confirm', {
    ref: "modal-load-purchase",
    attrs: {
      "title": "완료되지 않은 주문 정보가 있습니다",
      "confirmText": "불러오기",
      "cancelText": "아니오",
      "content": "완료되지 않은 주문 정보를 불러오시겠습니까?"
    }
  }), _c('modal-loading', {
    ref: "modal-loading"
  }), _c('modal-remain-later-pay', {
    ref: "modal-remain-later-pay"
  }), _c('modal-add-address', {
    ref: "modal-add-address",
    on: {
      "success": _vm.initDeliveryInfo
    }
  }), _c('modal-edit-address', {
    ref: "modal-edit-address",
    on: {
      "add": function add($event) {
        return _vm.$refs['modal-add-address'].show();
      },
      "remove": _vm.initDeliveryInfo,
      "change": _vm.changeAddress
    }
  }), _c('ModalLicenseAndSale', {
    ref: "ModalLicenseAndSale",
    on: {
      "purchaseWithoutSale": _vm.purchaseWithoutSale,
      "purchaseConfirm": _vm.submit
    }
  }), _c('ModalNoSaleOrder', {
    ref: "ModalNoSaleOrder",
    attrs: {
      "items": _vm.draftInfo.purchaseItems
    },
    on: {
      "prev": function prev($event) {
        return _vm.$refs['ModalLicenseAndSale'].show();
      },
      "change": _vm.changeNormalPrice
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }