var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isNudgeVisible ? _c('div', {
    staticClass: "nudge-wrapper"
  }, [_c('img', {
    staticClass: "close",
    attrs: {
      "src": require("@/assets/svg/close.svg")
    },
    on: {
      "click": _vm.closeNudge
    }
  }), _c('img', {
    attrs: {
      "src": require("./image/nudge-m10-3.png")
    },
    on: {
      "click": function click($event) {
        return _vm.goM10();
      }
    }
  })]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }