import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'

const routes = [
	{
		name: PageName.Purchase.Product.Order,
		path: RoutePath.Purchase.Product.Order,
		props: true,
		component: () => import('@/views/purchase/product/pages/Order.vue'),
	},
	{
		name: PageName.Purchase.Product.Pay,
		path: RoutePath.Purchase.Product.Pay,
		props: true,
		component: () => import('@/views/purchase/product/pages/Pay.vue'),
	},
	{
		name: PageName.Purchase.Product.PayDetail,
		path: RoutePath.Purchase.Product.PayDetail,
		props: true,
		component: () => import('@/views/purchase/product/pages/PayDetail.vue'),
	},
	{
		name: PageName.Purchase.Product.OrderComplete,
		path: RoutePath.Purchase.Product.OrderComplete,
		props: true,
		component: () => import('@/views/purchase/product/pages/OrderComplete.vue'),
	},
]

export default routes
