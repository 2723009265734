<template>
	<div v-if="isShow" class="add-count-wrap" :style="`top: ${top}px; right: calc(100vw - ${right}px)`">
		<!-- <div v-if="isShow" class="add-count-wrap"> -->
		<div>
			<b>{{ tt('추가할 수량') }}</b>
			<p>{{ tt('을 입력해주세요.') }}</p>
			<button @click="hide">
				<img src="@/assets/svg/popup-close.svg" alt="close" />
			</button>
		</div>
		<input-button-counter ref="input-button-counter" v-model="quantity" :min="min" sizeType="sm"></input-button-counter>
		<base-button style="height: 40px" @click="handleClick" :color="'primary'">{{ tt('추가하기') }}</base-button>
	</div>
</template>

<script>
import InputButtonCounter from '@/views/purchase/cargo/components/cargoList/counter/InputButtonCounter.vue'

export default {
	components: {
		InputButtonCounter,
	},
	data() {
		return {
			isShow: false,
			quantity: 1,
			min: 1,
			max: 5,
			index: 0,
			right: 0,
			top: 0,
		}
	},
	watch: {
		quantity(val) {
			if (val === 0) this.quantity = 1
		},
	},
	methods: {
		show(x, y, idx, stock) {
			this.isShow = true
			this.right = x
			this.top = y
			this.quantity = 1
			this.index = idx
			this.max = stock
		},
		hide() {
			this.$emit('hide')
			this.isShow = false
		},
		handleClick() {
			this.$emit('confirm', this.index, this.quantity)
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
.add-count-wrap {
	position: fixed;
	white-space: pre-line;
	z-index: 1060;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 355px;
	opacity: 1;
	visibility: visible;
	background: white;
	border: 1px solid $DIVIDER;
	border-radius: 6px;
	padding: 20px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	& > div {
		display: flex;
		button {
			margin-left: auto;
		}
	}
}
.add-count-wrap:after {
	border-bottom: 0px solid transparent;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid $LAB_WHITE;
	content: '';
	position: absolute;
	bottom: -8px;
	left: 160px;
	z-index: 1062;
}
.add-count-wrap:before {
	border-bottom: 0px solid transparent;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid $INPUT;
	content: '';
	position: absolute;
	bottom: -9px;
	left: 160px;
	z-index: 1061;
}
</style>
