var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "AddAuthorityMemberPopup Popup__depth3"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " ")]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.hide
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('search-box', {
    attrs: {
      "placeholder": _vm.tt(_vm.searchBoxPlaceholder)
    },
    on: {
      "inputValue": _vm.getInputGroupName,
      "search": _vm.searchTree
    }
  }), _c('div', {
    staticClass: "Organization__member-list"
  }, [_c('ul', {
    staticClass: "horizational-list"
  }, [_c('TreeView', {
    key: "modal-tree-view",
    ref: "modal-tree-view",
    attrs: {
      "treeData": _vm.organizationData,
      "textField": "name",
      "subTextField": "membersCount",
      "subTextEndWord": _vm.tt('명'),
      "draggable": false,
      "mode": _vm.mode,
      "modal": "group",
      "selectWithChild": false,
      "keyword": _vm.keyword,
      "limitSelectable": _vm.limitSelectable
    },
    on: {
      "changeActive": _vm.getActiveId,
      "load": _vm.loadedTree
    }
  })], 1)])], 1), _c('div', {
    staticClass: "Popup__bottom"
  }, [!_vm.isStart ? _c('button', {
    staticClass: "Btn__management",
    on: {
      "click": _vm.goOrganization
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('조직도 관리')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }