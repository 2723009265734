import inventoryAPI from '@/services/api/inventory/gen2'
import _ from 'lodash'
import cookies from 'vue-cookies'

const state = {
	categoryList: [],
	selectedId: null,
	category: {},
	mngcode: {},
	isLoading: false,
	categoryInfo: null,
	categoryCount: 0,
}

const getters = {
	chemicalList: state => state.categoryList.filter(it => it.classification === 'CAT_CHEMICAL'),
	chromatographyList: state => state.categoryList.filter(it => it.classification === 'CAT_CHROMATOGRAPHY'),
	labwareList: state => state.categoryList.filter(it => it.classification === 'CAT_LABWARE'),
	protectionList: state => state.categoryList.filter(it => it.classification === 'CAT_PROTECTION'),
	etcList: state => state.categoryList.filter(it => it.classification === 'CAT_ETC'),
	activeCodeList: state => {
		if (!state.mngcode.activeClassList) return []
		return state.mngcode.activeClassList.map(it => it.code)
	},
}

const mutations = {
	setCategoryList: (state, list) => {
		state.categoryList = list
	},
	setSelectedId: (state, id) => {
		state.selectedId = id
	},
	setCategory: (state, data) => {
		state.category = data
	},
	setMngcode: (state, data) => {
		state.mngcode = data
	},
	setActiveClassList: (state, payload) => {
		state.mngcode.activeClassList = payload
	},
	setInitCategoryInfo: state => {
		state.categoryInfo = null
	},
	setCategoryInfo: (state, categoryInfo) => {
		state.categoryInfo = categoryInfo
	},
	setCategoryCount: (state, data) => {
		state.categoryCount = data.count
	},
}

const actions = {
	getCategoryList: async ({ commit, rootGetters }) => {
		const response = await inventoryAPI.categoryList(rootGetters.instituteId)
		commit('setCategoryList', response.data)
	},
	setSelectedId: async ({ commit, dispatch }, id) => {
		commit('setSelectedId', id)
		dispatch('getCategory')
	},
	getCategory: async ({ commit, dispatch, rootGetters, state }) => {
		const response = await inventoryAPI.category(rootGetters.instituteId, { id: state.selectedId })
		commit('setCategory', response.data)
	},
	getMngcode: async ({ commit, rootGetters }) => {
		const response = await inventoryAPI.mngcode(rootGetters.instituteId)
		commit('setMngcode', response.data)
	},
	editMngcode: async ({ commit, dispatch, rootGetters }, payload) => {
		const response = await inventoryAPI.editMngcode(rootGetters.instituteId, payload)
		dispatch('getMngcode')
	},
	getCategoryCount: async ({ commit, rootGetters }, id) => {
		const response = await inventoryAPI.countCategory(rootGetters.instituteId, { id: id })
		commit('setCategoryCount', response.data)
	},
	//
	addCategory: async ({ rootGetters, dispatch }, payload) => {
		const response = await inventoryAPI.addCategory(rootGetters.instituteId, payload)
		await dispatch('getCategoryList')
		await dispatch('setSelectedId', response.data.id)
	},
	editCategory: async ({ rootGetters, dispatch }, payload) => {
		await inventoryAPI.editCategory(rootGetters.instituteId, payload)
		await dispatch('getCategoryList')
		await dispatch('getCategory')
	},
	deleteCategory: async ({ rootGetters, dispatch, state }) => {
		await inventoryAPI.deleteCategory(rootGetters.instituteId, { id: state.category.id })
		const idx = state.categoryList.findIndex(it => it.id === state.category.id)
		await dispatch('getCategoryList')
		if (state.categoryList[idx - 1].id) dispatch('setSelectedId', state.categoryList[idx - 1].id)
	},
	initializeCategory: async ({ rootGetters, dispatch, state }) => {
		await inventoryAPI.initializeCategory(rootGetters.instituteId, { id: state.category.id })
		await dispatch('getCategoryList')
		await dispatch('getCategory')
	},
	copyCategory: async ({ rootGetters, dispatch, state }, payload) => {
		const response = await inventoryAPI.copyCategory(rootGetters.instituteId, { id: state.category.id, name: payload })
		await dispatch('getCategoryList')
		dispatch('setSelectedId', response.data.id)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
