import ghs from './ghs'
import printSticker from './printSticker'
import inputUse from './inputUse'
import addDivide from './addDivide'

export default {
	namespaced: true,
	modules: {
		ghs,
		printSticker,
		inputUse,
		addDivide,
	},
}
