<template>
	<div class="detail-wrapper" v-if="item && item.id == id">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ item.name }}</h2>
				<h6>{{ item.code }}</h6>
			</div>
			<div class="button-groups">
				<image-button :text="'편집'" :image="require('@/assets/svg/button-icon-edit.svg')" @click="gotoEdit"></image-button>
				<image-button :text="'삭제'" :image="require('@/assets/svg/recycle-bin.svg')" @click="deleteModalBudget"></image-button>
			</div>
		</div>
		<div class="common-detail-wrapper">
			<div>
				<h5>{{ tt('예산 정보') }}</h5>
				<info-table :items="budgetInfo"></info-table>
			</div>
			<div>
				<h5>{{ tt('참여 부서') }}</h5>
				<info-table :items="groupInfo"></info-table>
			</div>
			<div>
				<h5>{{ tt('요약정보') }}</h5>
				<budget-summary :summary="item.summary" />
			</div>
			<div>
				<h5>
					<div>
						<div>{{ tt('예산 현황') }}</div>
						<div class="sub">
							<b>{{ tt('상세검색') }}</b
							>{{ tt('예산현황표에서 조회할 항목을 검색하거나 선택할 수 있습니다') }}
						</div>
					</div>
					<div class="button-groups">
						<image-button
							@click="exportDetail"
							:text="'목록 다운로드'"
							:image="require('@/assets/svg/excel-download.svg')"
						></image-button>
					</div>
				</h5>
				<budget-search
					@change="list => $refs['group-budget-search'].setCheckedList(list)"
					@changeKeyword="keyword => $refs['group-budget-search'].setKeyword(keyword)"
					:groups="item.groups"
					:year="item.year"
					:isMonth="item.budgetType == 'MONTH'"
				/>
				<group-budget-table ref="group-budget-search" :originGroups="item.groups" :year="item.year" :isMonth="item.budgetType == 'MONTH'" />
			</div>
			<expenditure-list :budgetId="item.id" :year="item.year" />
		</div>
		<modal-confirm ref="modal-delete" variant="warning" title="예산 삭제" content="예산을 정말로 삭제하시겠습니까?" confirmText="삭제" />
	</div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import InfoTable from '@/views/purchase/draft/components/detail/InfoTable.vue'
import BudgetSummary from '../components/status/Summary.vue'
import BudgetSearch from '../components/status/BudgetSearch.vue'
import GroupBudgetTable from '../components/status/GroupBudgetTable.vue'
import ExpenditureSearch from '../components/detail/ExpenditureSearch.vue'
import ExpenditureList from '../components/status/ExpenditureList.vue'
import PageName from '@/utils/define/PageName'
import ExcelDownloadButton from '@/views/common/components/baseButton/ExcelDownloadButton.vue'
export default {
	components: {
		InfoTable,
		BudgetSummary,
		BudgetSearch,
		GroupBudgetTable,
		ExpenditureSearch,
		ExpenditureList,
		ExcelDownloadButton,
	},
	computed: {
		...mapState('budget/item', ['item']),
		id() {
			return this.$route.params.id
		},
		budgetInfo() {
			return [
				{ label: this.tt('예산 번호'), value: this.item.code },
				{ label: this.tt('예산 이름'), value: this.item.name },
			]
		},
		groupInfo() {
			let groups = this.item.groups.map((g, i) => ({ label: this.tt('참여 그룹${1}', i + 1), value: g.name }))
			if (groups.length % 2 == 1) groups[groups.length - 1].class = 'lg'
			return groups
		},
	},
	async created() {
		await this.getDetail(this.id)
		this.setStorageDetailPaths([this.item.name])
	},
	methods: {
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		...mapActions('budget/item', ['getDetail', 'deleteBudget', 'exportExcel']),
		gotoEdit() {
			if (this.$root.userPermission.budget?.update) this.$router.push({ name: PageName.Budget.Edit, params: { id: this.id } })
			else this.$root.toast('권한', '예산 편집 권한이 없습니다.', 'error')
		},
		deleteModalBudget() {
			if (this.$root.userPermission.budget?.delete) {
				this.$refs['modal-delete'].show(async () => {
					try {
						const response = await this.deleteBudget(this.id)
						if (response.status == 205) {
							this.$root.toast('예산 삭제', `예산이 삭제되었습니다.`, 'success')
							this.$router.go(-1)
						}
					} catch (e) {
						this.$root.toast('예산 삭제', `예산에 관련된 기안이 있습니다.`, 'error')
					}
				})
			} else this.$root.toast('권한', '예산 삭제 권한이 없습니다.', 'error')
		},
		async exportDetail() {
			const response = await this.exportExcel(this.id)
			window.fileDownload(response, `${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('${1} 예산 현황', this.item.name)}.xlsx`)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
</style>
