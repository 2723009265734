<template>
	<tr class="table-item" :class="{ new: isNew }" @click="goToDetail(item.id)">
		<td>
			<div>
				<span :class="['expenditure', 'status', item.status]">
					{{ tt(statusKor) }}
				</span>
			</div>
		</td>
		<td v-if="isView('no')">
			<div>
				<span>{{ tt(item.no) }}</span>
			</div>
		</td>
		<td>
			<div>
				<div>
					{{ tt(item.subject) }}
				</div>
			</div>
		</td>
		<td v-if="isView('budgetName')">
			<div>
				<span>{{ item.budget ? tt(item.budget.name) : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('purchaseType')">
			<div>
				<span>{{ tt(item.purchaseType == 'INTERNAL' ? '랩매니저 스토어' : '외부 거래처') }}</span>
			</div>
		</td>
		<td v-if="isView('group')">
			<div>
				<span>{{ item.group ? tt(item.group.name) : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('register')">
			<div class="profile-wrap">
				<img class="profile-img" :src="profileImage" /><span>{{ tt(item.register.name) }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ createDatetime }}</span>
			</div>
		</td>
		<td v-if="isView('concludeDatetime')">
			<div>
				<span>{{ concludeDatetime }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState } from 'vuex'
import { draftStatus } from '@/utils/define/StatusCode'

import { DateTime } from 'luxon'
// TODO: Table Mixin
import MixinItem from '@/mixins/inventory/MixinItem'
export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('draft/list', ['checkedViewOption']),
		statusKor() {
			return draftStatus[this.item.status]
		},
		profileImage() {
			return this.item.register.image ?? require('@/assets/svg/member-photo-default.svg')
		},
		concludeDatetime() {
			return this.item.concludeDatetime ? DateTime.fromISO(this.item.concludeDatetime).toFormat('yyyy-MM-dd T') : '-'
		},
	},
	methods: {
		goToDetail(id) {
			this.setNew()
			this.$emit('click', id)
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
