<template>
	<div class="info-wrap" v-if="hasCasno">
		<p class="info-header">{{ tt('물성 정보') }}</p>
		<div class="property-list">
			<button @click="goToChemspider">
				<property
					:img="require('@/views/inventoryGen2/components/svg/icon-chem-spider.svg')"
					title="Chem Spider"
					subtext="켐스파이더에서 정보 보기"
				></property>
			</button>
			<button @click="goToSRD">
				<property
					:img="require('@/views/inventoryGen2/components/svg/icon-srd.svg')"
					title="물성 정보 참조"
					subtext="Standard Reference Data"
				></property>
			</button>
		</div>
	</div>
</template>
<script>
import Property from './Property.vue'
export default {
	name: 'PropertiesInfo',
	components: { Property },
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		hasCasno() {
			return this.item?.casno
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		goToChemspider() {
			window.open(`http://www.chemspider.com/Search.aspx?q=${this.item.casno}`)
		},
		goToSRD() {
			window.open(`https://srd.lab-manager.com/detail/${this.item.casno}`)
		},
	},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.property-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: 20px;
		& > button {
			margin-right: 20px;
			padding: 0;
		}
	}
}
</style>
