var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_vm.isShow ? _c('div', {
    staticClass: "experience-modal-background"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "header-text-wrapper"
  }, [_c('h1', {
    staticClass: "header-text-title"
  }, [_vm._v(_vm._s(_vm.tt('이메일 인증')))]), _c('span', {
    staticClass: "header-text-content"
  }, [_vm._v(" " + _vm._s(_vm.tt('랩매니저 PRO를 이용하기 위해, 이메일 인증을 진행해주세요.')) + " ")])]), _c('div', {
    staticClass: "content-img-wrapper"
  }, [_c('img', {
    staticClass: "success-icon",
    attrs: {
      "src": require("@/assets/svg/email-authentication.svg")
    }
  })]), _c('div', {
    staticClass: "bottom-wrapper"
  }, [_c('lm-confirm-button-middle', {
    attrs: {
      "text": _vm.tt('인증하기')
    },
    on: {
      "click": _vm.onClickAuthentication
    }
  })], 1)])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }