let { hazard, hCode, pCode } = {
	hazard: [
		{
			code: 'P01',
			name: '폭발성 물질',
			hCode: ['H200', 'H201', 'H202', 'H203', 'H204', 'H205'],
		},
		{
			code: 'P02',
			name: '인화성가스',
			hCode: ['H220', 'H221', 'H232'],
		},
		{
			code: 'P03',
			name: '에어로졸',
			hCode: ['H222', 'H223', 'H229'],
		},
		{
			code: 'P04',
			name: '산화성가스',
			hCode: ['H270'],
		},
		{
			code: 'P05',
			name: '고압가스',
			hCode: ['H280', 'H281'],
		},
		{
			code: 'P06',
			name: '인화성액체',
			hCode: ['H224', 'H225', 'H226', 'H227'],
		},
		{
			code: 'P07',
			name: '인화성고체',
			hCode: ['H228'],
		},
		{
			code: 'P08',
			name: '자기반응성 물질 및 혼합물',
			hCode: ['H240', 'H241', 'H242'],
		},
		{
			code: 'P09',
			name: '자연발화성 액체',
			hCode: ['H250'],
		},
		{
			code: 'P10',
			name: '자연발화성 고체',
			hCode: ['H250'],
		},
		{
			code: 'P11',
			name: '자기발열성 물질 및 혼합물',
			hCode: ['H251', 'H252'],
		},
		{
			code: 'P12',
			name: '물반응성 물질 및 혼합물',
			hCode: ['H260', 'H261'],
		},
		{
			code: 'P13',
			name: '산화성액체',
			hCode: ['H271', 'H272'],
		},
		{
			code: 'P14',
			name: '산화성고체',
			hCode: ['H271', 'H272'],
		},
		{
			code: 'P15',
			name: '유기과산화물',
			hCode: ['H240', 'H241', 'H242'],
		},
		{
			code: 'P16',
			name: '금속부식성 물질',
			hCode: ['H290'],
		},
		{
			code: 'H01',
			name: '급성독성',
			hCode: ['H300', 'H301', 'H302', 'H303', 'H310', 'H311', 'H312', 'H313', 'H330', 'H331', 'H332', 'H333'],
		},
		{
			code: 'H02',
			name: '피부 부식성 또는 자극성',
			hCode: ['H314', 'H315'],
		},
		{
			code: 'H03',
			name: '심한 눈 손상 또는 눈 자극성',
			hCode: ['H318', 'H319', 'H320'],
		},
		{
			code: 'H04',
			name: '호흡기 또는 피부 과민성',
			hCode: ['H317', 'H334'],
		},
		{
			code: 'H05',
			name: '생식세포 변이원성',
			hCode: ['H340', 'H341'],
		},
		{
			code: 'H06',
			name: '발암성',
			hCode: ['H350', 'H351'],
		},
		{
			code: 'H07',
			name: '생식독성',
			hCode: ['H360', 'H361', 'H362'],
		},
		{
			code: 'H08',
			name: '특정 표적장기 독성-1회 노출',
			hCode: ['H335', 'H336', 'H370', 'H371'],
		},
		{
			code: 'H09',
			name: '특정 표적장기 독성-반복 노출',
			hCode: ['H372', 'H373'],
		},
		{
			code: 'H10',
			name: '흡인 유해성',
			hCode: ['H304', 'H305'],
		},
		{
			code: 'E01',
			name: '수생환경 유해성',
			hCode: ['H400', 'H410', 'H411', 'H412', 'H413'],
		},
		{
			code: 'E02',
			name: '오존층 유해성',
			hCode: ['H420'],
		},
	],
	hCode: {
		H200: {
			text: '불안정한 폭발성 물질.',
			ghs: ['g1'],
			signal: '위험',
			pCode: ['P201', 'P250', 'P280'],
			pCode_action: ['P370+P372+P380+P373'],
			pCode_save: ['P401'],
			pCode_dispose: ['P501'],
		},
		H201: {
			text: '폭발성 물질; 대폭발 위험.',
			ghs: ['g1'],
			signal: '위험',
			pCode: ['P210', 'P230', 'P234', 'P240', 'P250', 'P280'],
			pCode_action: ['P370+P372+P380+P373'],
			pCode_save: ['P401'],
			pCode_dispose: ['P501'],
		},
		H202: {
			text: '폭발성 물질; 심한 분출 위험.',
			ghs: ['g1'],
			signal: '위험',
			pCode: ['P210', 'P230', 'P234', 'P240', 'P250', 'P280'],
			pCode_action: ['P370+P372+P380+P373'],
			pCode_save: ['P401'],
			pCode_dispose: ['P501'],
		},
		H203: {
			text: '폭발성 물질; 화재, 폭풍 또는 분출 위험.',
			ghs: ['g1'],
			signal: '위험',
			pCode: ['P210', 'P230', 'P234', 'P240', 'P250', 'P280'],
			pCode_action: ['P370+P372+P380+P373'],
			pCode_save: ['P401'],
			pCode_dispose: ['P501'],
		},
		H204: {
			text: '화재 또는 분출 위험.',
			ghs: ['g1'],
			signal: '경고',
			pCode: ['P210', 'P234', 'P240', 'P250', 'P280'],
			pCode_action: ['P370+P372+P380+P373', 'P370+P380+P375'],
			pCode_save: ['P401'],
			pCode_dispose: ['P501'],
		},
		H205: {
			text: '화재 시 대폭발 할 수 있음.',
			signal: '위험',
			pCode: ['P210', 'P230', 'P234', 'P240', 'P250', 'P280'],
			pCode_action: ['P370+P372+P380+P373'],
			pCode_save: ['P401'],
			pCode_dispose: ['P501'],
		},
		H220: {
			text: '극인화성 가스.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P210'],
			pCode_action: ['P377', 'P381'],
			pCode_save: ['P403'],
			pCode_dispose: [],
		},
		H221: {
			text: '인화성 가스.',
			signal: '경고',
			pCode: ['P210'],
			pCode_action: ['P377', 'P381'],
			pCode_save: ['P403'],
			pCode_dispose: [],
		},
		H222: {
			text: '극인화성 에어로졸.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P210', 'P211', 'P251'],
			pCode_action: [],
			pCode_save: ['P410+P412'],
			pCode_dispose: [],
		},
		H223: {
			text: '인화성 에어로졸.',
			ghs: ['g2'],
			signal: '경고',
			pCode: ['P210', 'P211', 'P251'],
			pCode_action: [],
			pCode_save: ['P410+P412'],
			pCode_dispose: [],
		},
		H224: {
			text: '극인화성 액체 및 증기.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P210', 'P233', 'P240', 'P241', 'P242', 'P243', 'P280'],
			pCode_action: ['P303+P361+P353', 'P370+P378'],
			pCode_save: ['P403+P235'],
			pCode_dispose: ['P501'],
		},
		H225: {
			text: '고인화성 액체 및 증기.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P210', 'P233', 'P240', 'P241', 'P242', 'P243', 'P280'],
			pCode_action: ['P303+P361+P353', 'P370+P378'],
			pCode_save: ['P403+P235'],
			pCode_dispose: ['P501'],
		},
		H226: {
			text: '인화성 액체 및 증기.',
			ghs: ['g2'],
			signal: '경고',
			pCode: ['P210', 'P233', 'P240', 'P241', 'P242', 'P243', 'P280'],
			pCode_action: ['P303+P361+P353', 'P370+P378'],
			pCode_save: ['P403+P235'],
			pCode_dispose: ['P501'],
		},
		H227: {
			text: '가연성 액체.',
			signal: '경고',
			pCode: ['P210', 'P280'],
			pCode_action: ['P370+P378'],
			pCode_save: ['P403'],
			pCode_dispose: ['P501'],
		},
		H228: {
			text: '인화성 고체.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P210', 'P240', 'P241', 'P280'],
			pCode_action: ['P370+P378'],
			pCode_save: [],
			pCode_dispose: [],
		},
		H229: {
			text: '압력용기; 가열하면 터질 수 있음.',
			signal: '경고',
			pCode: ['P210', 'P251'],
			pCode_action: [],
			pCode_save: ['P410+P412'],
			pCode_dispose: [],
		},
		H232: {
			text: '공기에 노출되면 자연발화할 수 있음.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P222', 'P280'],
			pCode_action: [],
			pCode_save: [],
			pCode_dispose: [],
		},
		H240: {
			text: '가열하면 폭발할 수 있음.',
			ghs: ['g1'],
			signal: '위험',
			pCode: ['P210', 'P234', 'P235', 'P240', 'P280'],
			pCode_action: ['P370+P372+P380+P373'],
			pCode_save: ['P403', 'P411', 'P420'],
			pCode_dispose: ['P501'],
		},
		H241: {
			text: '가열하면 화재 또는 폭발 할 수 있음.',
			signal: '위험',
			ghs: ['g1', 'g2'],
			pCode: ['P210', 'P234', 'P235', 'P240', 'P280'],
			pCode_action: ['P370+P372+P380+P373'],
			pCode_save: ['P403', 'P411', 'P420'],
			pCode_dispose: ['P501'],
		},
		H242: {
			text: '가열하면 화재를 일으킬 수 있음.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P210', 'P234', 'P235', 'P240', 'P280'],
			pCode_action: ['P370+P378'],
			pCode_save: ['P403', 'P411', 'P420'],
			pCode_dispose: ['P501'],
		},
		H250: {
			text: '공기에 노출되면 자연발화함.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P210', 'P222', 'P231+P232', 'P233', 'P280'],
			pCode_action: ['P302+P334', 'P381'],
			pCode_save: [],
			pCode_dispose: [],
		},
		H251: {
			text: '자기발열성: 화재를 일으킬 수 있음.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P235', 'P280'],
			pCode_action: [],
			pCode_save: ['P407', 'P410', 'P414', 'P420'],
			pCode_dispose: [],
		},
		H252: {
			text: '대량으로 존재 시 자기발열성: 화재를 일으킬 수 있음.',
			ghs: ['g2'],
			signal: '경고',
			pCode: ['P235', 'P280'],
			pCode_action: [],
			pCode_save: [],
			pCode_dispose: [],
		},
		H260: {
			text: '물과 접촉시 자연발화하는 인화성 가스를 발생시킴.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P223', 'P231+P232', 'P280'],
			pCode_action: ['P302+P335+P334', 'P370+P378'],
			pCode_save: ['P402+P404'],
			pCode_dispose: ['P501'],
		},
		H261: {
			text: '물과 접촉시 인화성 가스를 발생시킴.',
			ghs: ['g2'],
			signal: '위험',
			pCode: ['P223', 'P231+P232', 'P280'],
			pCode_action: ['P302+P335+P334', 'P370+P378'],
			pCode_save: ['P402+P404'],
			pCode_dispose: ['P501'],
		},
		H270: {
			text: '화재를 일으키거나 강렬하게 함: 산화제.',
			ghs: ['g3'],
			signal: '위험',
			pCode: ['P220', 'P244'],
			pCode_action: ['P370+P376'],
			pCode_save: ['P403'],
			pCode_dispose: [],
		},
		H271: {
			text: '화재 또는 폭발을 일으킬 수 있음: 강산화제.',
			ghs: ['g3'],
			signal: '위험',
			pCode: ['P210', 'P220', 'P280', 'P283'],
			pCode_action: ['P306+P360', 'P371+P380+P375', 'P370+P378'],
			pCode_save: ['P420'],
			pCode_dispose: ['P501'],
		},
		H272: {
			text: '화재를 강렬하게 함: 산화제.',
			ghs: ['g3'],
			signal: '위험',
			pCode: ['P210', 'P220', 'P280'],
			pCode_action: ['P370+P378'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H280: {
			text: '고압가스 포함:가열하면 폭발할 수 있음.',
			signal: '경고',
			ghs: ['g4'],
			pCode: [],
			pCode_action: [],
			pCode_save: ['P410+P403'],
			pCode_dispose: [],
		},
		H281: {
			text: '냉동액화가스 포함: 극저온의 화상 또는 손상을 일으킬 수 있음.',
			ghs: ['g4'],
			signal: '경고',
			pCode: ['P282'],
			pCode_action: ['P336+P315'],
			pCode_save: ['P403'],
			pCode_dispose: [],
		},
		H290: {
			text: '금속을 부식시킬 수 있음.',
			ghs: ['g5'],
			signal: '경고',
			pCode: ['P234'],
			pCode_action: ['P390'],
			pCode_save: ['P406'],
			pCode_dispose: [],
		},
		H300: {
			text: '삼키면 치명적임.',
			ghs: ['g6'],
			signal: '위험',
			pCode: ['P264', 'P270'],
			pCode_action: ['P301+P310', 'P321', 'P330'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H301: {
			text: '삼키면 유독함.',
			ghs: ['g6'],
			signal: '위험',
			pCode: ['P264', 'P270'],
			pCode_action: ['P301+P310', 'P321', 'P330'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H302: {
			text: '삼키면 유해함.',
			ghs: ['g7'],
			signal: '경고',
			pCode: ['P264', 'P270'],
			pCode_action: ['P301+P312', 'P330'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H303: {
			text: '삼키면 유해할 수 있음.',
			ghs: [],
			signal: '경고',
			pCode: ['P264', 'P270'],
			pCode_action: ['P312'],
			pCode_save: [],
			pCode_dispose: [],
		},
		H304: {
			text: '삼켜서 기도로 유입되면 치명적일 수 있음.',
			ghs: ['g8'],
			signal: '위험',
			pCode: [],
			pCode_action: ['P301+P310', 'P331'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H305: {
			text: '삼켜서 기도로 유입되면 유해할 수 있음.',
			ghs: ['g8'],
			signal: '경고',
			pCode: [],
			pCode_action: ['P301+P310', 'P331'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H310: {
			text: '피부와 접촉하면 치명적임.',
			ghs: ['g6'],
			signal: '위험',
			pCode: ['P262', 'P264', 'P270', 'P280'],
			pCode_action: ['P302+P352', 'P310', 'P321', 'P361+P364'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H311: {
			text: '피부와 접촉하면 유독함.',
			ghs: ['g6'],
			signal: '위험',
			pCode: ['P280'],
			pCode_action: ['P302+P352', 'P312', 'P321', 'P361+P364'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H312: {
			text: '피부와 접촉하면 유해함.',
			ghs: ['g7'],
			signal: '경고',
			pCode: ['P280'],
			pCode_action: ['P302+P352', 'P312', 'P321', 'P361+P364'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H314: {
			text: '피부에 심한 화상과 눈에 손상을 일으킴.',
			ghs: ['g6'],
			signal: '위험',
			pCode: ['P260', 'P264', 'P280'],
			pCode_action: ['P301+P330+P331', 'P303+P361+P353', 'P363', 'P304+P340', 'P310', 'P321', 'P305+P351+P338'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H315: {
			text: '피부에 자극을 일으킴.',
			ghs: ['g7'],
			signal: '경고',
			pCode: ['P264', 'P280'],
			pCode_action: ['P302+P352', 'P321', 'P332+P313', 'P362+P364'],
			pCode_save: [],
			pCode_dispose: [],
		},
		H317: {
			text: '알레르기성 피부 반응을 일으킬 수 있음.',
			ghs: ['g7'],
			signal: '경고',
			pCode: ['P261', 'P272', 'P280'],
			pCode_action: ['P302+P352', 'P333+P313', 'P321', 'P362+P364'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H318: {
			text: '눈에 심한 손상을 일으킴.',
			ghs: ['g6'],
			signal: '위험',
			pCode: ['P280'],
			pCode_action: ['P305+P351+P338', 'P310'],
			pCode_save: [],
			pCode_dispose: [],
		},
		H319: {
			text: '눈에 심한 자극을 일으킴.',
			ghs: ['g7'],
			signal: '경고',
			pCode: ['P264', 'P280'],
			pCode_action: ['P305+P351+P338', 'P337+P313'],
			pCode_save: [],
			pCode_dispose: [],
		},
		H320: {
			text: '눈에 자극을 일으킴.',
			signal: '경고',
			pCode: ['P264'],
			pCode_action: ['P305+P351+P338', 'P337+P313'],
			pCode_save: [],
			pCode_dispose: [],
		},
		H330: {
			text: '흡입하면 치명적임.',
			ghs: ['g6'],
			signal: '위험',
			pCode: ['P260', 'P271', 'P284'],
			pCode_action: ['P304+P340', 'P310', 'P320'],
			pCode_save: ['P403+P233', 'P405'],
			pCode_dispose: ['P501'],
		},
		H331: {
			text: '흡입하면 유독함.',
			ghs: ['g6'],
			signal: '위험',
			pCode: ['P261', 'P271'],
			pCode_action: ['P304+P340', 'P311', 'P321'],
			pCode_save: ['P403+P233', 'P405'],
			pCode_dispose: ['P501'],
		},
		H332: {
			text: '흡입하면 유해함.',
			ghs: ['g7'],
			signal: '경고',
			pCode: ['P261', 'P271'],
			pCode_action: ['P304+P340', 'P312'],
			pCode_save: [],
			pCode_dispose: [],
		},
		H334: {
			text: '흡입 시 알레르기성 반응, 천식 또는 호흡 곤란 등을 일으킬 수 있음.',
			ghs: ['g8'],
			signal: '위험',
			pCode: ['P261', 'P284'],
			pCode_action: ['P304+P340', 'P342+P311'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H340: {
			text: '유전적인 결함을 일으킬 수 있음 (주1).',
			ghs: ['g8'],
			signal: '위험',
			pCode: ['P201', 'P202', 'P280'],
			pCode_action: ['P308+P313'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H341: {
			text: '유전적인 결함을 일으킬 것으로 의심됨 (주1).',
			ghs: ['g8'],
			signal: '경고',
			pCode: ['P201', 'P202', 'P280'],
			pCode_action: ['P308+P313'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H350: {
			text: '암을 일으킬 수 있음 (주2).',
			ghs: ['g8'],
			signal: '위험',
			pCode: ['P201', 'P202', 'P280'],
			pCode_action: ['P308+P313'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H351: {
			text: '암을 일으킬 것으로 의심됨 (주2).',
			ghs: ['g8'],
			signal: '경고',
			pCode: ['P201', 'P202', 'P280'],
			pCode_action: ['P308+P313'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H360: {
			text: '태아 또는 생식능력에 손상을 일으킬 수 있음 (주3) (주4).',
			ghs: ['g8'],
			signal: '위험',
			pCode: ['P201', 'P202', 'P280'],
			pCode_action: ['P308+P313'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H361: {
			text: '태아 또는 생식능력에 손상을 일으킬 것으로 의심됨 (주3) (주4).',
			ghs: ['g8'],
			signal: '경고',
			pCode: ['P201', 'P202', 'P280'],
			pCode_action: ['P308+P313'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H362: {
			text: '모유를 먹는 아이에게 유해할 수 있음.',
			signal: '없음',
			pCode: ['P201', 'P260', 'P263', 'P264', 'P270'],
			pCode_action: ['P308+P313'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H335: {
			text: '호흡기 자극을 일으킬 수 있음.',
			ghs: ['g7'],
			signal: '경고',
			pCode: ['P261', 'P271'],
			pCode_action: ['P304+P340', 'P312'],
			pCode_save: ['P403+P233', 'P405'],
			pCode_dispose: ['P501'],
		},
		H336: {
			text: '졸음 또는 현기증을 일으킬 수 있음.',
			ghs: ['g7'],
			signal: '경고',
			pCode: ['P261', 'P271'],
			pCode_action: ['P304+P340', 'P312'],
			pCode_save: ['P403+P233', 'P405'],
			pCode_dispose: ['P501'],
		},
		H370: {
			text: '장기(주5)에 손상을 일으킴 (주6).',
			ghs: ['g8'],
			signal: '위험',
			pCode: ['P260', 'P264', 'P270'],
			pCode_action: ['P308+P311', 'P321'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H371: {
			text: '장기(주5)에 손상을 일으킬 수 있음 (주6).',
			ghs: ['g8'],
			signal: '경고',
			pCode: ['P260', 'P264', 'P270'],
			pCode_action: ['P308+P311'],
			pCode_save: ['P405'],
			pCode_dispose: ['P501'],
		},
		H372: {
			text: '장기간 또는 반복 노출되면 장기(주5)에 손상을 일으킴 (주7).',
			ghs: ['g8'],
			signal: '위험',
			pCode: ['P260', 'P264', 'P270'],
			pCode_action: ['P314'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H373: {
			text: '장기간 또는 반복 노출되면 장기(주5)에 손상을 일으킬 수 있음 (주7).',
			ghs: ['g8'],
			signal: '경고',
			pCode: ['P260'],
			pCode_action: ['P314'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H400: {
			text: '수생생물에 매우 유독함.',
			ghs: ['g9'],
			signal: '경고',
			pCode: ['P273'],
			pCode_action: ['P391'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H410: {
			text: '장기적 영향에 의해 수생생물에 매우 유독함.',
			ghs: ['g9'],
			signal: '경고',
			pCode: ['P273'],
			pCode_action: ['P391'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H411: {
			text: '장기적 영향에 의해 수생생물에 유독함.',
			ghs: ['g9'],
			signal: '없음',
			pCode: ['P273'],
			pCode_action: ['P391'],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H412: {
			text: '장기적 영향에 의해 수생생물에 유해함.',
			signal: '없음',
			pCode: ['P273'],
			pCode_action: [],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H413: {
			text: '장기적 영향에 의해 수생생물에 유해의 우려가 있음.',
			signal: '없음',
			pCode: ['P273'],
			pCode_action: [],
			pCode_save: [],
			pCode_dispose: ['P501'],
		},
		H420: {
			text: '대기 상층부의 오존층을 파괴하여 공공의 건강 및 환경에 유해함.',
			ghs: ['g7'],
			signal: '경고',
			pCode_action: [],
			pCode_save: [],
			pCode_dispose: [],
		},
	},
	pCode: {
		P101: '의학적인 조치가 필요한 경우, 제품의 용기 또는 라벨을 보시오',
		P102: '어린이 손이 닿지 않는 곳에 보관하시오',
		P103: '사용 전에 라벨을 읽으시오',
		P301: '삼켰다면;',
		P302: '피부에 묻으면;',
		P303: '피부(또는 머리카락)에 묻으면;',
		P304: '흡입하면;',
		P305: '눈에 묻으면;',
		P306: '의류에 묻으면;',
		P308: '노출되거나 노출이 우려되면;',
		P310: '즉시 의료기관/의사/···의 진찰을 받으시오.',
		P311: '의료기관/의사/···의 진찰을 받으시오.',
		P312: '불편함을 느끼면 의료기관/의사/···의 진찰을 받으시오.',
		P313: '의학적인 조치/조언을 받으시오.',
		P314: '불편함을 느끼면 의학적인 조치·조언을 받으시오.',
		P315: '즉시 의학적인 조치·조언을 받으시오.',
		P320: '긴급히 ... 처치를 하시오.',
		P321: '... 처치를 하시오.',
		P330: '입을 씻어내시오.',
		P331: '토하게 하지 마시오.',
		P332: '피부 자극이 나타나면;',
		P333: '피부자극 또는 홍반이 나타나면;',
		P334: '차가운 물에 담그시오. [또는 젖은 붕대로 감싸시오.]',
		P335: '피부에 묻은 물질을 털어내시오.',
		P336: '미지근한 물로 언 부분을 녹이시오. 손상된 부위를 문지르지 마시오.',
		P337: '눈에 자극이 지속되면;',
		P338: '가능하면 콘택트렌즈를 제거하시오. 계속 씻으시오.',
		P340: '신선한 공기가 있는 곳으로 옮기고 호흡하기 쉬운 자세로 안정을 취하시오.',
		P342: '호흡기 증상이 나타나면;',
		P351: '몇 분간 물로 조심해서 씻으시오.',
		P352: '다량의 물/···(으)로 씻으시오.',
		P353: '피부를 물로 씻으시오.[또는 샤워하시오.]',
		P360: '의류를 벗기 전에 오염된 의류 및 피부를 다량의 물로 즉시 씻어내시오.',
		P361: '오염된 모든 의류를 즉시 벗으시오.',
		P362: '오염된 의류를 벗으시오.',
		P363: '다시 사용 전 오염된 의류는 세척하시오.',
		P364: '다시 사용 전 세척하시오',
		P370: '화재 시;',
		P371: '대형 화재 시;',
		P372: '폭발 위험성이 있음.',
		P373: '화염이 폭발성 물질에 도달하면 불을 끄려 하지 마시오.',
		P375: '폭발의 위험이 있으므로 거리를 유지하면서 불을 끄시오.',
		P376: '안전하게 처리하는 것이 가능하다면 누출을 막으시오.',
		P377: '가스 누출 화재; 누출을 안전하게 막을 수 없다면 불을 끄려하지 마시오.',
		P378: '불을 끄기 위해 ... 을(를) 사용하시오.',
		P380: '주변 지역의 사람을 대피시키시오.',
		P381: '누출시 모든 점화원을 제거하시오.',
		P390: '물질손상을 방지하기 위해 누출물을 흡수시키시오.',
		P391: '누출물을 모으시오.',
		'P301+P310': '삼켰다면; 즉시 의료기관/의사/···의 진찰을 받으시오..',
		'P301+P312': '삼켜서 불편함을 느끼면 의료기관/의사/···의 진찰을 받으시오.',
		'P301+P330+P331': '삼켰다면 입을 씻어내시오. 토하게 하려 하지 마시오.',
		'P302+P334': '피부에 묻으면 차가운 물에 담그시오. [또는 젖은 붕대로 감싸시오.]',
		'P302+P335+P334': '피부에 묻으면; 피부에 묻은 물질을 털어내시오. 차가운 물에 담그시오.[또는 젖은 붕대로 감싸시오.]',
		'P302+P352': '피부에 묻으면 다량의 물/···(으)로 씻으시오.',
		'P303+P361+P353': '피부(또는 머리카락)에 묻으면 오염된 모든 의류를 즉시 벗으시오. 피부를 물로 씻으시오.[또는 샤워하시오.]',
		'P304+P312': '흡입하면; 불편함을 느끼면 의료기관/의사/···의 진찰을 받으시오.',
		'P304+P340': '흡입하면; 신선한 공기가 있는 곳으로 옮기고 호흡하기 쉬운 자세로 안정을 취하시오.',
		'P305+P351+P338': '눈에 묻으면 몇 분간 물로 조심해서 씻으시오. 가능하면 콘택트렌즈를 제거하시오. 계속 씻으시오.',
		'P306+P360': '의류에 묻으면 의류를 벗기 전에 오염된 의류 및 피부를 다량의 물로 즉시 씻어내시오.',
		'P308+P311': '노출되거나 노출이 우려되면; 의료기관/의사/···의 진찰을 받으시오.',
		'P308+P313': '노출되거나 노출이 우려되면; 의학적인 조치/조언을 받으시오.',
		'P332+P313': '피부 자극이 나타나면; 의학적인 조치/조언을 받으시오.',
		'P333+P313': '피부자극 또는 홍반이 나타나면; 의학적인 조치/조언을 받으시오.',
		'P336+P315': '미지근한 물로 언 부분을 녹이시오, 손상된 부위를 문지르지 마시오. 즉시 의학적인 조치/조언을 받으시오',
		'P337+P313': '눈에 대한 자극이 지속되면; 의학적인 조치/조언을 받으시오.',
		'P342+P311': '호흡기 증상이 나타나면; 의료기관/의사/···의 진찰을 받으시오.',
		'P361+P364': '오염된 모든 의류를 즉시 벗고 다시 사용 전 세척하시오.',
		'P362+P364': '오염된 의류를 벗고 다시 사용 전 세척하시오.',
		'P370+P372+P380+P373': '화재 시; 폭발 위험성이 있음. 주변 지역의 사람을 대피시키시오. 화염이 폭발성 물질에 도달하면 불을 끄려하지 마시오.',
		'P370+P376': '화재 시; 안전하게 처리하는 것이 가능하다면 누출을 막으시오.',
		'P370+P378': '화재 시; 불을 끄기 위해 … 을(를) 사용하시오.',
		'P370+P380+P375': '화재 시; 주변지역의 사람을 대피시키시오. 폭발의 위험이 있으므로 거리를 유지하면서 불을 끄시오',
		'P370+P380+P375 +P378':
			'화재 시; 주변지역의 사람을 대피시키시오. 폭발의 위험이 있으므로 거리를 유지하면서 불을 끄시오[불을 끄기 위해 ···을(를) 사용하시오.]',
		'P371+P380+P375': '대형 화재 시; 주변지역의 사람을 대피시키시오. 폭발의 위험이 있으므로 거리를 유지하면서 불을 끄시오',
		P401: '(관련 법규에 명시된 내용에 따라) 보관하시오.',
		P402: '건조한 장소에 보관하시오.',
		P403: '환기가 잘 되는 곳에 보관하시오.',
		P404: '밀폐된 용기에 보관하시오.',
		P405: '잠금장치를 하여 저장하시오.',
		P406: '금속부식성 물질이므로 (제조자 또는 행정관청에서 정한) 내부식성 용기에 보관하시오.',
		P407: '적재물 또는 팔레트 사이의 간격을 유지하시오.',
		P410: '직사광선을 피하시오.',
		P411: '반응성이 높은 물질이므로 보관 시 ...℃를 넘지 않도록 유의하시오.',
		P412: '50℃ 이상의 온도에 노출시키지 마시오.',
		P413: '반응성이 높은 물질이므로 …㎏ 이상으로 보관중일 때는 …℃를 넘지 않도록 유의하시오.',
		P420: '다른 물질과 격리하여 보관하시오.',
		'P402+P404': '건조한 장소에 보관하시오. 밀폐된 용기에 보관하시오.',
		'P403+P233': '용기는 환기가 잘 되는 곳에 단단히 밀폐하여 저장하시오.',
		'P403+P235': '환기가 잘 되는 곳에 보관하고 저온으로 유지하시오',
		'P410+P403': '직사광선을 피하고 환기가 잘 되는 곳에 보관하시오.',
		'P410+P412': '직사광선을 피하고 50℃ 이상의 온도에 노출시키지 마시오.',
		P501: '폐기물 관련 법령에 따라 내용물/용기를 폐기하시오.',
		P502: '제조자 또는 공급자가 제공한 재생 또는 재활용에 대한 정보를 참조하시오.',
		P201: '사용 전 취급 설명서를 확보하시오.',
		P202: '모든 안전 예방조치 문구를 읽고 이해하기 전에는 취급하지 마시오.',
		P210: '열, 고온의 표면, 스파크, 화염 및 그 밖의 점화원으로부터 멀리하시오. 금연',
		P211: '화염 또는 다른 점화원에 분사하지 마시오.',
		P212: '밀폐상태에서 가열 또는 둔감제의 감소를 피하시오.',
		P220: '의류 및 그 밖의 가연성 물질로부터 멀리하시오.',
		P222: '공기에 접촉시키지 마시오.',
		P223: '물에 접촉시키지 마시오.',
		P230: '... (으)로 젖은 상태를 유지하시오.',
		P231: '불활성 기체/··· 하에서 취급 및 저장 하시오.',
		P232: '습기를 방지하시오.',
		P233: '용기를 단단히 밀폐하시오.',
		P234: '원래의 용기에만 보관하시오.',
		P235: '저온으로 유지하시오.',
		P240: '용기와 수용설비를 접지하시오.',
		P241: '방폭형 [전기/환기/조명/···]설비를 사용하시오.',
		P242: '스파크가 발생하지 않는 도구를 사용하시오',
		P243: '정전기 방지 조치를 취하시오.',
		P244: '밸브 및 관이음쇠에 그리스와 오일이 묻지 않도록 하시오.',
		P250: '연마/충격/마찰/···을 가하지 마시오.',
		P251: '사용 후에도 구멍을 뚫거나 태우지 마시오.',
		P260: '분진/흄/가스/미스트/증기/스프레이를(을) 흡입하지 마시오.',
		P261: '분진/흄/가스/미스트/증기/스프레이의 흡입을 피하시오',
		P262: '눈, 피부, 의류에 묻지 않도록 하시오.',
		P263: '임신 · 수유기간에는 접촉하지 마시오.',
		P264: '취급 후에는 ···을(를) 철저히 씻으시오.',
		P270: '이 제품을 사용할 때에는 먹거나, 마시거나 흡연하지 마시오.',
		P271: '옥외 또는 환기가 잘 되는 곳에서만 취급하시오.',
		P272: '작업장 밖으로 오염된 작업복을 반출하지 마시오.',
		P273: '환경으로 배출하지 마시오.',
		P280: '보호장갑/보호의/보안경/안면보호구를 착용하시오.',
		P282: '방한장갑 및 안면 보호구 또는 보안경을 착용하시오.',
		P283: '방화복 또는 방염복을 착용하시오.',
		P284: '[환기가 잘 되지 않는 경우]호흡용 보호구를 착용하시오.',
		'P231+P232': '불활성 기체/···하에서 취급 및 저장하시오 습기를 방지하시오.',
	},
}

let response = {
	hazard: ['P01', 'E01'],
	hCode: ['H200', 'H201', 'H202', 'H400'],
}

// const dangerStatement = response.hCode.map(code => hCode[code]?.text).join(' ')
// let precautionCodes = []
// let pictograms = []
// let signal = ''
// let signalProcessEnd = false

// response.hCode.forEach(code => {
// 	hCode[code]?.pCode?.forEach(code => {
// 		if (precautionCodes.indexOf(code) < 0) precautionCodes.push(code)
// 	})
// 	if (hCode[code]?.ghs?.length > 0) {
// 		pictograms = pictograms.concat(hCode[code].ghs)
// 	}
// 	if (!signalProcessEnd && hCode[code]?.signal) {
// 		switch (hCode[code]?.signal) {
// 			case '위험':
// 				signal = hCode[code]?.signal
// 				signalProcessEnd = true
// 				break
// 			case '경고':
// 				signal = hCode[code]?.signal
// 				break
// 			case '없음':
// 				if (!signal) signal = hCode[code]?.signal
// 				break
// 		}
// 	}
// })
// const precationstatement = precautionCodes.map(code => pCode[code]).join(' ')
// pictograms = [...new Set(pictograms)]
// console.log(dangerStatement + '\n' + precationstatement + '\n' + pictograms + '\n' + signal)

export { hazard, hCode, pCode }
