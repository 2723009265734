<template>
	<div class="tab-wrap">
		<span v-for="(item, index) in tabList" :key="index">
			<input type="radio" :id="'tab' + index + 1" name="TabList" :value="index" v-model="selectTabId" />
			<label :for="'tab' + index + 1">
				<img v-if="item.id" :src="require(`@/views/inventoryGen2/svg/` + item.id + `.svg`)" />
				{{ tt(item.name) }}
			</label>
		</span>
	</div>
</template>

<script>
export default {
	name: 'ManagementTab',
	components: {},
	props: {
		tabList: {
			type: Array,
			default: () => {},
		},
		selectTab: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		selectTabId: {
			get() {
				return this.selectTab
			},
			set(newValue) {
				this.$emit('change', newValue)
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.tab-wrap {
	// padding-bottom: 20px;
	border-bottom: 1px solid $DIVIDER;
	// padding: 15px 20px;
	span {
		input[type='radio'] {
			width: 0;
			height: 0;
			opacity: 0;
			visibility: hidden;
		}
		label {
			cursor: pointer;
			margin: 0 15px -1px;
			display: inline-block;
			padding-bottom: 10px;
			img {
				filter: grayscale(100%);
				opacity: 0.8;
				// margin-bottom: -5px;
				// margin-right: 3px;
				width: 20px;
				height: 20px;
			}
			font-size: 0.875rem;
			color: $SECONDARY_2;
		}
		input[type='radio']:checked + label {
			font-weight: 700;
			color: #000;
			border-bottom: 2px solid $LAB_YELLOW;
			img {
				filter: none;
				opacity: 1;
			}
		}
	}
}
</style>
