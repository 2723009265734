var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "approval-info-item"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt(_vm.approvalTitle)))])]), _c('div', {
    staticClass: "content"
  }, [_c('div', [_vm.approvalState ? _c('p', {
    "class": _vm.approvalState
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.status ? _vm.status : '-')) + " ")]) : _vm._e(), _c('h6', [_vm._v(_vm._s(_vm.tt(_vm.name)))]), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.affiliation,
      expression: "affiliation",
      modifiers: {
        "hover": true
      }
    }]
  }, [_vm._v(_vm._s(_vm.tt(_vm.affiliation)))])])]), _vm.isFooter ? _c('div', {
    staticClass: "footer"
  }, [_c('span', [_vm._v(_vm._s(_vm.dateTime ? _vm.dateTime : '-'))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }