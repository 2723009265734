<template>
	<div class="SafetydiagnosisCheckup">
		<div class="title-wrap" style="height: 55px">
			<h2 class="AppContainer__title" style="padding-top: 0">
				{{ tt('특수건강진단')
				}}<button @click="showNotice">
					<img src="@/assets/svg/Question.svg" alt="icon" />
				</button>
			</h2>
			<div class="switch-box">
				<h6>{{ tt('검진일 초과한 인원만 보기') }}</h6>
				<label class="switch">
					<input type="checkbox" v-model="showOnlyOverdue" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="AppContainer">
			<div class="Filter__box" style="padding-top: 10px">
				<search-box :placeholder="tt('검색')" :loadList="loadDiagnosisList"></search-box>
				<div class="right-btn-group">
					<button
						class="btn checkup"
						@click="showInputCheckup(checkList)"
						:disabled="checkList.length == 0"
						v-if="userPermission.safe && userPermission.safe.diagnosis.create"
					>
						<img src="@/assets/svg/icon-edit.svg" alt="icon" />{{ tt('검진일 입력') }}
						<div class="balloon">
							<p>{{ tt('목록에서 항목을 선택해주세요') }}</p>
						</div>
					</button>
					<button class="btn" @click="downloadExcel"><img src="@/assets/svg/icon-excel.svg" alt="icon" />{{ tt('목록 다운로드') }}</button>
				</div>
			</div>
			<div class="Table__wrap scroll">
				<table
					:class="{
						noPermission: userPermission.safe && !userPermission.safe.diagnosis.create,
					}"
				>
					<thead>
						<tr>
							<th>
								<button class="checkbox" :class="({ active: isActive }, { active: isCheck })" @click="allChecked"></button>
							</th>
							<th
								v-for="item in checkHeaderList"
								:key="item.name"
								:width="item.width"
								:class="[{ headerHover: item.sort }, { asc: item.direction == 'ASC' }]"
							>
								<span>
									{{ tt(item.name) }}
									<button @click="changeSort(item)" v-if="item.sort">
										<img src="@/assets/svg/arrow-b.svg" alt="icon" />
									</button>
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<health-item
							:allCheck="isActive"
							@checkId="addCheckIdList"
							@uncheckId="deleteCheckIdList"
							:headers="headerList"
							v-for="item in list"
							:key="item.id"
							:item="item"
							:instId="instId"
							:offset="offset"
							:length="length"
							@inputDiagnosis="showInputCheckup"
							:userPermission="userPermission"
						></health-item>
					</tbody>
				</table>
				<no-data-list v-if="list.length == 0" :image="noData.image" :title="noData.title" :content="noData.content"></no-data-list>
			</div>
		</div>

		<div class="Menu__floating" v-if="checkList.length > 0">
			<h4>
				<span class="count">{{ checkList.length }}{{ tt('개') }}</span
				>{{ tt('선택') }}
			</h4>
			<div class="btn-group">
				<button @click="showInputCheckup(checkList)">
					<img src="@/assets/svg/icon-edit.svg" alt="버튼icon" />
					<span>{{ tt('검진일 입력') }}</span>
				</button>
			</div>
		</div>

		<pagination :total="count" @page="pagination"></pagination>

		<!--modal-->
		<modal-diagnosis-checkup-notice :isShow="displayNotice" @close="hideNotice"></modal-diagnosis-checkup-notice>
		<modal-input-checkup ref="input-checkup" @submit="hideInputCheckup"></modal-input-checkup>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import SearchBox from '@/views/common/components/SearchBox.vue'
import ModalHealthCheckupNotice from '@/views/safety/diagnosis/modals/ModalHealthCheckupNotice.vue'
import ModalInputCheckup from '@/views/safety/diagnosis/modals/ModalInputCheckup.vue'
import HealthItem from '@/views/safety/diagnosis/components/HealthItem.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import NoDataList from '@/views/common/components/NoDataList.vue'

export default {
	name: 'SafetyHealthCheckup',
	props: {},
	components: {
		SearchBox,
		HealthItem,
		'modal-diagnosis-checkup-notice': ModalHealthCheckupNotice,
		ModalInputCheckup,
		Pagination,
		NoDataList,
	},
	created() {
		this.instId = this.$route.params.instituteId
		this.checkHeaderList = this.headerList
		this.loadDiagnosisList()
	},
	data() {
		return {
			instId: null,
			isActive: false,
			isCheck: false,
			order: [],
			orderDirection: [],
			checkList: [],
			count: 0,
			list: [],
			offset: 0,
			length: 50,
			keyword: undefined,
			showOnlyOverdue: false,
			headerList: [
				{
					name: this.tt('상태'),
					width: 100,
					value: true,
					sort: false,
				},
				{
					name: this.tt('멤버 정보'),
					width: 280,
					value: true,
					sort: true,
					order: 'LAST_DIAGNOSIS_DATE',
					direction: 'DESC',
				},
				{
					name: this.tt('소속'),
					width: 288,
					value: true,
					sort: false,
				},
				{
					name: this.tt('최근 검진일'),
					width: 190,
					value: true,
					sort: false,
				},
				{
					name: this.tt('다음 검진 기한'),
					width: 260,
					value: true,
					sort: false,
				},
				{
					name: this.tt('관리'),
					width: 210,
					value: true,
					sort: false,
				},
			],
			displayNotice: false,
			displayInputCheckup: false,
			noData: {
				image: '',
				title: '',
				content: '',
			},
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('diagnosis', ['diagnosisList']),
		...mapState('institutionInformation', ['listKeyword']),
	},
	watch: {
		showOnlyOverdue(val) {
			this.showOnlyOverdue = val
			this.loadDiagnosisList()
		},
		list(val) {
			if (val.length == 0) {
				this.noData = {
					image: 'person',
					title: '특수건강진단 대상 인원이 없습니다',
					content: '특수건강진단 대상 물질을 사용한 멤버가 자동으로 목록에 추가됩니다.',
				}
				if (this.listKeyword.length > 0) {
					this.noData = {
						image: 'search',
						title: '조회된 결과가 없습니다.',
						content: '다시 한번 확인해 주세요.',
					}
				}
			}
		},
	},
	methods: {
		...mapMutations('institutionInformation', ['setKeyword']),
		...mapActions('diagnosis', ['lookupDiagnosisList', 'updateDiagnosisDate', 'exportExcel']),
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
			this.loadDiagnosisList()
		},
		loadDiagnosisList() {
			this.count = 0

			const payload = {
				instId: this.instId,
				offset: this.offset,
				length: this.length,
				keyword: this.listKeyword,
				order: this.order,
				orderDirection: this.orderDirection,
				showOnlyOverdue: this.showOnlyOverdue,
			}
			this.lookupDiagnosisList(payload).then(() => {
				this.count = this.diagnosisList.count
				this.list = this.diagnosisList.list
			})
		},
		addCheckIdList(id) {
			this.checkList.push(id)
			if (this.checkList.length == this.list.length) {
				this.isCheck = true
			}
		},
		deleteCheckIdList(id) {
			for (let i = 0; i < this.checkList.length; i++) {
				if (this.checkList[i] == id) {
					this.checkList.splice(i, 1)
				}
			}
			this.isCheck = false
		},
		allChecked() {
			if (this.isActive) {
				this.isActive = false
				this.isCheck = false
			} else {
				this.isActive = true
				this.isCheck = true
			}
		},
		changeSort(item) {
			this.order = []
			this.orderDirection = []
			this.order.push(item.order)
			if (item.direction == 'DESC') item.direction = 'ASC'
			else item.direction = 'DESC'
			this.orderDirection.push(item.direction)
			this.loadDiagnosisList()
		},
		showNotice() {
			this.displayNotice = true
		},
		hideNotice() {
			this.displayNotice = false
		},
		showInputCheckup(usersId) {
			this.$refs['input-checkup'].show(usersId)
		},
		async hideInputCheckup(usersId, date) {
			const payload = {
				instId: this.instId,
				body: {
					usersId: usersId,
					datetime: date,
				},
			}

			const response = await this.updateDiagnosisDate(payload)
			this.$refs['input-checkup'].hide()
			await this.loadDiagnosisList()
			this.checkList = []
			this.isActive = false
			this.isCheck = false

			const toastTitle = this.tt('진단일 입력')
			const toastContent = this.tt('선택한 인원의 진단일이 저장되었습니다')
			this.$root.toast(toastTitle, toastContent, 'success')
		},
		async changeOverDue() {
			this.showOnlyOverdue = !this.showOnlyOverdue
		},
		formatDate(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear()
			if (month.length < 2) {
				month = '0' + month
			}
			if (day.length < 2) {
				day = '0' + day
			}
			return [year, month, day].join('-')
		},
		async downloadExcel() {
			const response = await this.exportExcel(this.$route.params.instituteId)
			window.fileDownload(response, this.formatDate(new Date()) + `_` + this.tt('특수건강진단 리스트.xlsx'))
		},
	},
}
</script>

<style lang="scss" scoped>
.SafetydiagnosisCheckup {
	.title-wrap {
		width: 100%;
		display: flex;
		align-items: center;
		height: auto;
		h2 {
			display: flex;
			align-items: center;
			height: auto;
			button {
				width: 24px;
				height: 24px;
				padding: 0;
				margin-left: 4px;
				img {
					width: inherit;
					height: inherit;
				}
			}
		}
		.switch-box {
			margin-left: auto;
			margin-right: 30px;
			display: flex;
			align-items: center;
			height: auto;
			padding: 9px 15px;
			background: $SUB-YELLOW_100;
			border-radius: 10px;
			h6 {
				font-size: 0.875rem;
				color: rgba(0, 0, 0, 0.87);
				font-weight: 700;
				margin-right: 10px;
			}
		}
	}
	.checkup {
		.balloon {
			display: none;
		}
	}
	.checkup:disabled {
		position: relative;
		.balloon {
			position: absolute;
			padding: 12px 15px;
			display: block;
			background: $LAB_WHITE;
			border-radius: 5px;
			top: 37px;
			right: 10px;
			color: #000;
			font-weight: normal;
			font-size: 0.8125rem;
			visibility: hidden;
			z-index: 30;
			border: 1px solid rgba(0, 0, 0, 0.6);
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
			width: unset;
			padding: 10px 15px;
			box-sizing: border-box;
			white-space: initial;
			text-align: left;
			h6 {
				font-size: 0.875rem;
				color: #000;
				font-weight: 700;
				padding-bottom: 3px;
			}
			p {
				width: 100%;
				white-space: pre;
			}
		}
		.balloon:before {
			border-top: 0px solid transparent;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-bottom: 9px solid rgba(0, 0, 0, 0.6);
			content: '';
			position: absolute;
			top: -9.2px;
			right: 14px;
			z-index: 1003;
		}
		.balloon:after {
			border-top: 0px solid transparent;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 8px solid $LAB_WHITE;
			content: '';
			position: absolute;
			top: -8px;
			right: 15px;
			z-index: 1004;
		}
		&:hover {
			.balloon {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.Table__wrap {
		height: calc(100vh - 228px);
		table {
			tbody {
				tr {
					cursor: pointer;
					td {
						.state {
							&.safe {
								color: #57c17e;
							}
							&.error {
								color: $RED;
							}
						}
						.info {
							cursor: pointer;
							text-align: left;
							display: flex;
							img {
								display: inline-block;
								width: 40px;
								height: 40px;
							}
							div {
								padding-left: 10px;
								h6 {
									font-size: 1rem;
									color: #000;
									font-weight: bold;
									display: block;
									> span {
										font-weight: normal;
										padding-left: 2px;
										display: inline-block;
									}
								}
								p {
									font-size: 0.75rem;
									color: $PLACEHOLDER;
									font-weight: 400;
								}
							}
						}
						> span {
							display: flex;
							align-items: center;
							height: auto;
							img {
								margin-right: 5px;
							}
							&.error {
								color: $RED;
							}
							&.no-data {
								color: $PLACEHOLDER;
							}
						}
					}
				}
			}
		}
	}
}

.noPermission {
	thead {
		th {
			&:last-child {
				display: none;
			}
		}
	}
}
.btn {
}
</style>
