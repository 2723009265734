var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "hide-header-close": "",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " ")]), _c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/create/success-image-check-200x200.svg"),
      "width": "200"
    }
  }), _c('div', {
    staticClass: "button-wrap"
  }, [_c('button', {
    staticClass: "button-primary",
    on: {
      "click": function click($event) {
        _vm.$emit('confirm');

        _vm.hide();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.confirmBtnText)) + " ")]), _c('button', {
    staticClass: "button-white",
    on: {
      "click": function click($event) {
        _vm.$emit('cancel');

        _vm.hide();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.cancelBtnText)) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }