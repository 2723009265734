<template>
	<portal to="lm-modal">
		<div class="experience-modal-background" v-if="isShow">
			<div class="modal-wrapper">
				<div class="header-text-wrapper">
					<h1 class="header-text-title">{{ tt('이메일 인증') }}</h1>
					<span class="header-text-content">
						{{ tt('랩매니저 PRO를 이용하기 위해, 이메일 인증을 진행해주세요.') }}
					</span>
				</div>
				<div class="content-img-wrapper">
					<img class="success-icon" src="@/assets/svg/email-authentication.svg" />
				</div>
				<div class="bottom-wrapper">
					<lm-confirm-button-middle :text="tt('인증하기')" @click="onClickAuthentication"></lm-confirm-button-middle>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'

export default {
	name: 'ModalEmailAuthentication',
	mixins: [MixinModal],
	components: {
		LmConfirmButtonMiddle,
	},
	methods: {
		onClickAuthentication() {
			this.$emit('authentication')
		},
	},
}
</script>

<style lang="scss" scoped>
.experience-modal-background {
	width: 100%;
	height: 100%;
	background: $DIM;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;

	.modal-wrapper {
		width: 500px;
		height: 513px;
		padding: 48px;
		background: white;
		border-radius: 20px;
		.header-text-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			height: 102px;
			.header-text-title {
				font-size: 2rem;
			}
			.header-text-content {
				font-weight: 400;
				font-size: 16px;
				margin-top: 10px;
			}
		}
		.content-img-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			.success-icon {
				width: 200px;
				height: 200px;
			}
		}
		.bottom-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100px;
			padding-top: 40px;
			align-items: center;
			justify-content: space-between;
		}
	}
}
</style>
