<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('장바구니에서 불러오기') }}</div>
		</template>
		<template>
			<div class="content-wrap">
				<div>
					<span class="checkbox"><input type="checkbox" v-model="allCheck" id="checkAll" /></span>
					<label for="checkAll">{{ tt('전체선택') }}</label>
					<!-- <base-button :disabled="selectedCartItems.length === 0" @click="deleteItem">{{ tt('선택 삭제') }}</base-button> -->
				</div>
				<product-info-regist-table :list="cartList" :allChecked="allCheck"></product-info-regist-table>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<base-button @click="hide">{{ tt('닫기') }}</base-button>
					<base-button :disabled="selectedCartItems.length === 0" :color="'primary'" @click="add">{{ tt('추가하기') }}</base-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ProductInfoRegistTable from './components/modalAddCartProduct/ProductInfoRegistTable.vue'
export default {
	components: {
		ProductInfoRegistTable,
	},
	// watch: {
	// 	allCheck(val) {
	// 		console.log('allCheck', val)
	// 	},
	// },
	computed: {
		...mapState('draft/form', ['cartList', 'selectedCartItems']),
	},
	data() {
		return {
			allCheck: false,
		}
	},
	methods: {
		...mapMutations('draft/form', ['setSelectedCartItems']),
		...mapActions('draft/form', ['getCartList']),
		...mapActions('draft/item', ['setPurchaseItems']),
		async init() {
			this.allCheck = true
			this.setSelectedCartItems([])
			await this.getCartList()
		},
		show() {
			this.init()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		deleteItem() {
			console.log('선택 삭제 API 붙이기')
		},
		add() {
			this.selectedCartItems.forEach(item => {
				let selectItem = {}
				Object.entries(item).forEach(([key, value]) => {
					if (key === 'category') {
						selectItem['category'] = value
						// selectItem['categoryType'] = value.code
					} else if (key === 'code') {
						selectItem['productCode'] = value
					} else if (key === 'id') {
						selectItem['cartId'] = value
					} else if (key === 'options') {
						selectItem['volume'] = parseFloat(value[0].value)
						selectItem['volumeUnit'] = value[0].value.replace(/[^a-z]/gi, '')
					} else if (value) {
						selectItem[key] = value
					}
				})
				this.setPurchaseItems(selectItem)
			})
			this.hide()
			this.$root.toast('상품 추가 완료', '선택한 상품이 추가되었습니다.', 'success')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-add-cart-product.scss';
</style>
