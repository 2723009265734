<template>
	<div class="header-wrapper">
		<h2 class="header-title">
			{{ tt('상품결제') }}
			<!-- <div class="toggle-button">
				<h6>{{ tt('결제 가능 항목만 조회하기') }}</h6>
				<label class="switch">
					<input type="checkbox" />
					<span class="slider round"></span>
				</label>
			</div> -->
		</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<search-box class="search-box" :value="keyword" :placeholder="tt('검색')" @change="changeKeyword"></search-box>

				<image-button
					:text="filterText"
					:image="require('@/assets/svg/filter.svg')"
					:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
					@click="filterBtnClick"
					:isActive="filteredCount > 0"
				></image-button>
				<date-picker-button
					:image="require('@/assets/svg/list/inventory-calendar.svg')"
					:activeImage="require('@/assets/svg/list/active/calendar-active.svg')"
					:text="'기간조회'"
					:subText="dateButtonSubtext"
					:defaultTime="calendarDatetime"
					:isActive="isActiveCalendar"
					@pickDate="pickDate"
					@changeDate="changeDate"
				></date-picker-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button
					:text="'보기옵션'"
					:image="require('@/assets/svg/ViewOption(Button).svg')"
					@click="visibleOptionBtnClick"
				></image-button>
				<excel-download-button @downloadAll="exportList('ALL')" @downloadFiltered="exportList('FILTERED')" />
			</div>
		</div>
		<!-- 모달 5종세트 -->
		<modal-filter ref="modal-filter" @changeLoadParams="changeLoadParams" />
		<modal-view-option ref="modal-view-option" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SearchBox from '@/views/components/searchBox'
import ImageButton from '@/views/common/components/baseButton/ImageButton.vue'
import DatePickerButton from '@/views/common/components/baseButton/DatePickerButton.vue'
import PageName from '@/utils/define/PageName'
import ExcelDownloadButton from '@/views/common/components/baseButton/ExcelDownloadButton.vue'
import ModalViewOption from '../modal/ModalViewOption.vue'
import ModalFilter from '../modal/ModalFilter.vue'
export default {
	components: {
		SearchBox,
		ImageButton,
		DatePickerButton,
		ExcelDownloadButton,
		ModalViewOption,
		ModalFilter,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		dateButtonSubtext: {
			type: String,
			default: '',
		},
		calendarDatetime: {
			type: Array,
			default: [],
		},
	},
	computed: {
		...mapGetters('purchaseProduct/list', ['keyword', 'filteredCount']),
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
	},
	data() {
		return {
			// 선택된 Tab - 전체 , 진행중 , 승인 , 반려
			isActiveCalendar: false,
		}
	},
	methods: {
		...mapActions('purchaseProduct/list', ['exportExcel']),
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		visibleOptionBtnClick() {
			this.$refs['modal-view-option'].show()
		},
		pickDate() {
			this.$root.toast(this.tt('기간 필터 적용 완료'), this.tt('기간 필터가 적용되었습니다.'), 'success')
		},
		changeDate(date) {
			this.isActiveCalendar = date[0] != null
			this.$emit('changeDate', date)
		},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword })
		},
		changeLoadParams(params) {
			this.$emit('changeLoadParams', params)
		},
		async exportList(type) {
			const response = await this.exportExcel(type)
			window.fileDownload(response, `${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('상품 결제 목록')}.xlsx`)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/list/_list-header.scss';
.toggle-button {
	display: flex;
	margin-left: auto;
	h6 {
		font-size: 14px;
		font-weight: 700;
		color: $DEFAULT;
		margin-right: 24px;
	}
	padding: 8px 15px;
	background-color: $SUB-YELLOW_100;
	border-radius: 10px;
}
</style>
