<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Location__delete-location-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title" v-if="isTypeLocation">{{ locationInfo.name }} {{ tt('삭제') }}</h5>

					<h5 class="Popup__title" v-else>{{ tt('보관함 삭제') }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text" v-if="isTypeLocation">
						{{ locationInfo.name }}
						{{ tt('위치를 삭제하시겠습니까?') }}
					</p>
					<p class="Popup__plain-text" v-else>
						{{ tt('보관함을 정말로 삭제하시겠습니까?') }}<br />
						{{ tt('보관함을 사용중인 모든 연구실에서 보관함이 삭제됩니다.') }}
					</p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__delete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">
							{{ tt('취소') }}
						</button>
						<button class="Btn__delete" @click="confirm">
							{{ tt('삭제') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { mapGetters, mapState } from 'vuex'
import locationAPI from '@/services/api/institute/location'
import storageAPI from '@/services/api/institute/storage'
export default {
	name: 'ModalDeleteLocation',
	mixins: [MixinModal],
	data() {
		return {
			institutionId: '',
			locationInfo: undefined,
			storageId: undefined,
		}
	},
	computed: {
		...mapState('location', ['selectedLocation', 'locationTree']),
		isTypeLocation() {
			return this.modalType === 'location'
		},
	},
	methods: {
		show(type, data) {
			console.log(data)
			this.modalType = type
			this.isShow = true
			console.log(this.isTypeLocation)
			if (!this.isTypeLocation) {
				this.storageId = data.id
			} else {
				this.locationInfo = this.selectedLocation.info
			}
		},
		async confirm() {
			if (this.isTypeLocation) {
				const { id, instituteId } = this.locationInfo
				// 위치 삭제
				let title, content, type
				try {
					await locationAPI.deleteLocation(this.$route.params.instituteId, id)
					title = this.tt('위치 삭제 완료')
					content = this.tt('위치가 삭제되었습니다')
					type = 'success'
					this.$root.toast(title, content, type)
					this.$emit('refresh', this.locationTree[0].id)
				} catch (error) {
					const { data, status } = error.response
					if (status === 412) {
						title = this.tt('위치 삭제 실패')
						content = this.tt('물품이 있는 위치는 삭제할 수 없습니다')
						type = 'error'
						this.$root.toast(title, content, type)
					} else {
						this.$root.toast(this.tt('위치 삭제 실패'), data.msg, type)
					}
				} finally {
					this.hide()
				}
			} else {
				// 보관함 삭제
				try {
					const response = await storageAPI.deleteStroage(this.$route.params.instituteId, this.storageId)
					if (response.status === 205) {
						let title = this.tt('보관함 삭제 완료')
						let content = this.tt('보관함이 삭제되었습니다')
						let type = 'success'
						this.$root.toast(title, content, type)
						this.hide()
						this.$emit('refresh', this.selectedLocation.info.id)
					}
				} catch (error) {
					if (error.response.data.status === 412) {
						this.$root.toast('보관함 삭제 실패', '물품이 있는 보관함은 삭제할 수 없습니다', 'error')
					} else {
						this.$root.toast('보관함 삭제 실패', error.response.data.msg, 'error')
					}
				}
			}
		},
	},
}
</script>

<style lang="scss">
.DeleteLocationPopup {
}
</style>
