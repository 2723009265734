<template>
	<div id="app" @click="appClick" v-if="!useSidebarOrHeader || $root.user.language">
		<main class="App__inner-container">
			<app-side-menu
				ref="menu"
				v-if="useSidebarOrHeader"
				:userPermission="userPermission"
				:license="license"
				:summaryInfo="summary"
				:info="info"
				@showLicense="showNeedProLicense"
			></app-side-menu>
			<!-- 튜토리얼 배너 -->
			<app-header
				:userInfo="user"
				:instInfo="institute"
				:class="{
					'right-menu-on': $root.rightMenuOn,
					'notice-on': $root.isNoticeOn,
				}"
				v-if="useSidebarOrHeader"
			></app-header>
			<section
				:class="{
					'App__router-container': hasInstitute,
					scroll: !hasInstitute,
					'App__router-container-no-sidebar': !hasInstitute,
				}"
			>
				<router-view
					v-if="$root.userPermission"
					:key="$route.name"
					:userPermission="userPermission"
					:license="license"
					:isExp="$route.params.isExp"
					:state="eolState"
					@showLicense="showNeedProLicense"
					@loadPermission="loadUserPermission"
					@loadSummary="loadSummary"
					@changeInfo="changeInfo"
					@openEmailCertificationModal="openEmailCertificationModal"
					@openJoinSuccessModal="openJoinSuccessModal"
					@appInitFlow="appInitFlow"
				></router-view>
			</section>
		</main>
		<lm-toast-list></lm-toast-list>
		<portal-target name="lm-modal" multiple> </portal-target>
		<modal-notice-license ref="modal-notice-license"></modal-notice-license>
		<modal-complete-transfer v-if="eolState && eolState.status === 'DONE' && eolState.isFirstVisit"></modal-complete-transfer>
		<modal-email-authentication ref="modal-email-authentication" @authentication="onClickAuthentication"></modal-email-authentication>
		<modal-success-join ref="modal-success-join" @start="onClickModalSuccessJoin"></modal-success-join>

		<ModalHowToAddLicense ref="ModalHowToAddLicense" @refreshData="refreshLicense" @purchaseLicense="$root.purchaseLicense()" />
		<!-- <ModalPayment ref="ModalPayment" /> -->
		<ModalLicense ref="ModalPayment" />
		<ModalConfirm
			ref="modal-license-add"
			title="라이선스 추가구매 필요"
			content="라이선스 수가 부족하여, 멤버 추가가 불가합니다.<br />라이선스를 추가 구매하면, 멤버를 추가할 수 있습니다. "
			confirmText="라이선스 구매하기"
			@confirm="$refs['ModalPayment'].showModalPayment('add')"
		/>
		<Nudge ref="nudge" />
		<!-- Modal Area : End -->
	</div>
</template>

<script>
import AppHeader from '@/views/app/components/AppHeader.vue'
import AppSideMenu from '@/views/app/components/AppSideMenu'
import LmToastList from '@/views/common/components/Toast/ToastList.vue'
import AppMixin from '@/mixins/index'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
import userAPI from '@/services/api/institute/user'
import { getToken, goToSSOMain, isLogin, setToken } from '@/services/api/common'
import institutionInformationAPI from '@/services/api/institute/information'
import instituteMemberAPI from '@/services/api/institute/member'
import ModalNoticeLicense from '@/views/common/components/modals/ModalNoticeLicense.vue'
import ModalCompleteTransfer from '@/views/common/components/modals/ModalCompleteTransfer.vue'
import axiosInstance from '@/services/axios'
import cookies from 'vue-cookies'
import logger from '@/services/logger'
import ModalConfirm from '@/views/common/components/modalGen2/ModalConfirm.vue'

// Saas 신규 : 이강민, 박찬우
import ModalSuccessJoin from '@/views/app/components/ModalSuccessJoin.vue'
import ModalEmailAuthentication from '@/views/app/components/ModalEmailAuthentication.vue'

import ModalHowToAddLicense from '@/views/institute2/modals/ModalHowToAddLicense.vue'
import ModalPayment from '@/views/institute/information/modals/ModalPayment.vue'
import ModalLicense from '@/views/institute2/modals/ModalLicense.vue'

import { goToSSOEmail } from '@/services/api/common'
import license from '@/mixins/license'

import Nudge from '@/views/components/nudge/Nudge.vue'

export default {
	name: PageName.Home,
	components: {
		AppHeader,
		AppSideMenu,
		LmToastList,
		ModalNoticeLicense,
		ModalCompleteTransfer,
		ModalSuccessJoin,
		ModalEmailAuthentication,
		ModalConfirm,
		ModalPayment,
		ModalLicense,
		ModalHowToAddLicense,
		Nudge,
	},
	mixins: [`AppMixin`],
	data() {
		return {
			showModalAccountSetting: false,
			instituteId: null,
			code: null,
			summary: {},
			summaryType: {},
			info: {},
			// instId 있는 상태에서 둘러보기 이용시
			// TODO: API 나오면 확인해서 체크
			init: {
				status: [],
				registerMethods: [],
				categoriesId: [],
				storagesId: [],
				assigneesId: [],
				brandsId: [],
				expireDate: [],
				currentVolume: [],
				threshold: [],
				bundle: [],
				legalsId: [],
				groupsId: [],
				dumpersId: [],
			},
			isProccessingInitFlow: false,
			isInvitePage: location.href.includes('acceptInvite') || location.href.includes('createInstitution') ? true : false,
		}
	},
	async created() {
		// EOL Area
		// console.warn('isAppModeExperience >>> ', this.isAppModeExperience)
		this.setToast()
		this.setLicense()
		await this.appInitFlow()
	},
	watch: {
		async $route(to, from) {
			// DATA: 로그 관련 - 장은학
			// DATA START----
			// this.processRoute(to, from)
			//---- DATA END

			if (!location.href.includes('acceptInvite')) {
				this.loadUserPermission()
			}

			if (location.href.includes('acceptInvite') || location.href.includes('createInstitution')) {
				this.isInvitePage = true
			}

			if (this.$refs['menu']) {
				this.$refs['menu'].isShow = false
			}
			let toSplit = to.path.split('/')
			let fromSplit = from.path.split('/')
			if (
				(toSplit[4] && fromSplit[4] && toSplit[4] == fromSplit[4]) ||
				(toSplit[3] === fromSplit[3] && !toSplit.includes('whole') && !fromSplit.includes('whole'))
			) {
				this.setKeyword(this.listKeyword)
			} else {
				this.setKeyword('')
				this.setFilter(this.init)
				this.setDumpFilter(this.init)
			}
			this.togglePaginationOption(false)
			try {
				await this.loadLicense()
				this.expiredLicense()
				await this.loadSummary()
			} catch (e) {
				console.log(e)
			}
		},
	},
	computed: {
		...mapGetters('user', ['hasInstitutionId']),
		...mapGetters('appEOL', ['isNewUser', 'isMigrationPage']),
		...mapGetters('logger', ['getSession']), //DATA: 로깅 모듈 관련 - 장은학
		...mapState('logger', ['session']), //DATA: 로깅 모듈 관련 - 장은학
		...mapState('user', ['user', 'institute']),
		...mapState('institutionInformation', ['listKeyword', 'instInfo']),
		...mapState('license', ['license', 'licenseHistory']),
		...mapState('appEOL', ['eolState']),
		...mapState('userPermission', ['userPermission']),
		...mapState('pagination', ['showPaginationOption']),
		...mapState('inventory', ['inventoryFilter']),
		hasInstitute() {
			return this.$route.params.instituteId != null
		},
		isNotStartPro() {
			return this.$route.path.indexOf('startPro') < 0 && this.$route.path.indexOf('gettingStart') < 0
		},
		useSidebarOrHeader() {
			if (this.$route.path.indexOf('excelRegisteringMember') >= 0) {
				return false
			} else {
				return (this.hasInstitute && this.isNotStartPro && !this.isMigrationPage) || this.isAppModeExperience
			}
		},
		isCompleteInitSetting() {
			return this.$route.params.isCreated
		},
		queryToken() {
			if (location.href.indexOf('token') >= 0) {
				const queryParams = {}

				location.href
					.split('?')[1]
					.split('&')
					.map(paramText => {
						paramText = paramText.split('=')
						queryParams[paramText[0]] = paramText[1]
					})

				return queryParams.token ? queryParams.token : ''
			} else {
				return ''
			}
		},
	},
	// Data: logging methods / 장은학
	// Data START ---
	async mounted() {
		// await this.r_initLoggerSession()
	},
	// --- Data END

	methods: {
		// Data: logging methods / 장은학
		// Data START---
		...mapActions('institutionInformation', ['loadInstitutionInfo']),
		...mapActions('logger', ['initLoggerProfile']),
		...mapMutations('logger', ['updateLastElapsedMs']),
		async r_loggerProcessExit(e, current_route, session) {
			e.preventDefault()
			await logger.routeReport('EXIT', current_route, session)
		},
		async r_initLoggerSession() {
			const token = await getToken()
			await this.initLoggerProfile(token)
			const s = await this.getSession
			await window.addEventListener('beforeunload', e => this.r_loggerProcessExit(e, this.$route.name, s))
			console.log('sessioninit:', s)
			logger.routeReport('HOME', 'EXTERNAL', s)
		},
		processRoute: async function (to, from) {
			await logger.routeReport(to, from, this.getSession)
			await this.updateLastElapsedMs(new Date().getTime())
			window.scrollTo(0, 0)
		},
		// ---Data END

		...mapMutations('user', ['setUser']),
		...mapMutations('institutionInformation', ['setInstInfo', 'setKeyword']),
		...mapMutations('inventory', ['setFilter', 'setDumpFilter']),
		...mapActions('toast', ['showToast']),
		...mapActions('user', ['loadUserInfo', 'loadUserInstituteInfo', 'loginUser', 'loadExUserToken']),
		...mapActions('license', ['loadLicense', 'loadLicenseHistory']),
		...mapActions('appEOL', ['loadEolState']),
		...mapActions('userPermission', ['loadUserPermission']),
		...mapActions('pagination', ['togglePaginationOption']),

		async appInitFlow() {
			// this.isInvitePage = false

			if (this.hasInstitute) {
				cookies.set('instituteId', this.$route.params.instituteId)
			}

			const queryToken = this.queryToken

			if (queryToken) {
				setToken(queryToken)
			}
			const token = queryToken ? queryToken : getToken()
			if (!token) {
				if (location.href.includes('acceptInvite')) {
					goToSSOMain(location.href)
					return false
				} else if (location.href.includes('createInstitution')) {
					return false
				} else {
					if (this.$route.path.includes('changeInitPw')) {
						goToSSOMain(window.location.href)
					}
					if (!this.isAppModeExperience) {
						goToSSOMain()
					}
				}
			} else {
				axiosInstance.setAuth(token)
			}

			if (this.$route.path.includes('changeInitPw')) {
				return false
			}

			if (this.isAppModeExperience) {
				const token = await this.loadExUserToken()
				if (token) {
					setToken(token)
					axiosInstance.setAuth(token)
				}
			}

			axiosInstance.loadGlobalFilter()

			const loginResponse = await this.loginUser()
			// console.log(loginResponse)
			const isLoginError = loginResponse && loginResponse.msg === 'ERROR'
			if (isLoginError) {
				goToSSOMain()
			}

			await this.loadUserInfo()
			await this.loadEolState()

			if (this.isRedirectByEolAction()) {
				this.actionEol()
			} else {
				await this.loadUserInstituteInfo()
				await this.loadUserPermission()

				try {
					// const globalFilter = cookies.get('GlobalFilterStorage')

					// if(globalFilter) {
					//     axiosInstance.setGlobalFilter(globalFilter)
					// }

					if (location.href.includes('acceptInvite')) {
						// this.isInvitePage = true
						return false
					}
					if (this.hasInstitutionId) {
						this.info = { ...this.institute }
						this.instituteId = this.institute.id
						if (!this.$route.path.includes(this.instituteId)) {
							this.routeDashboard()
						}
						await this.loadLicense()
						await this.loadInstitutionInfo(this.instituteId)
						await this.loadSummary()
					} else {
						this.routeCreateInstitution()
					}
				} catch (err) {
					if (err.response.status == 403) {
						await this.loadLicense()
						this.expiredLicense()
					}
				}
			}
		},
		expiredLicense() {
			if (this.license?.status == 'EXPIRED') {
				if (![PageName.Institution.Information, PageName.Institution.MemberManagement].includes(this.$route.name)) {
					this.$root.toast('라이선스 만료', '라이선스가 만료되었습니다', 'error')
					this.$router.push({
						params: { instituteId: this.instituteId },
						name: PageName.Institution.Information,
					})
				}
			}
		},
		async fetchData() {
			await this.loadUserInstituteInfo()
			const response = await userAPI.lookupUserInfo()
			this.setUser(response.data)
		},
		async loadUserCode() {
			const response = await instituteMemberAPI.checkInitPw(this.code)

			this.user = response.data
		},
		showNeedProLicense() {
			this.$refs['modal-notice-license'].show()
		},
		async loadSummary() {
			if (this.$refs['menu']) {
				this.$refs['menu'].type = {}
			}
			const response = await institutionInformationAPI.lookupSummary(this.instituteId)
			this.summary = response.data
		},
		changeInfo(info) {
			this.info = info.info
		},
		routeChangeInitPassword() {
			this.$router.push({
				name: 'changeInitPw',
			})
		},
		appClick(event) {
			this.$root.initStateOnMenu()
			this.$root.cardInput = false
			event.stopPropagation()
		},
		setToast() {
			this.$root.toast = (title, content, type) => {
				const payload = {
					type: type,
					message: {
						title: this.tt(title),
						content: this.tt(content),
					},
				}
				this.showToast(payload)
			}
		},
		setLicense() {
			this.$root.addLicense = () => {
				if (this.userPermission?.license?.update) this.$refs['modal-license-add'].show()
				else this.$root.toast('권한 없음', '권한이 없습니다.', 'error')
			}
			this.$root.purchaseLicense = (confirmFn, confirmText) => {
				if (this.userPermission?.license?.create) this.$refs['ModalPayment'].showModalPurchase(confirmFn, confirmText)
				else this.$root.toast('권한 없음', '권한이 없습니다.', 'error')
			}
			this.$root.howToAddLicense = () => {
				this.$refs['ModalHowToAddLicense'].show()
			}
			this.$root.managementLicense = () => {
				if (this.userPermission?.license?.read) this.$refs['ModalPayment'].showModalManagement()
				else this.$root.toast('권한 없음', '권한이 없습니다.', 'error')
			}
			this.$root.setSubscribePurchaseMode = value => {
				if (this.userPermission?.license?.update) this.$refs['ModalPayment'].setSubscribePurchaseMode(value)
				else this.$root.toast('권한 없음', '권한이 없습니다.', 'error')
			}
			this.$root.refreshLicense = async () => {
				await this.refreshLicense()
			}
		},
		async refreshLicense() {
			await this.loadUserInstituteInfo()
			const institutionId = this.$route.params.instituteId
			await this.loadInstitutionInfo(institutionId)
			await this.loadLicense()
			this.$refs['ModalPayment'].setSubscribePurchaseMode(false)
		},
		onClickModalSuccessJoin() {
			this.$refs['modal-success-join'].hide()
		},
		onClickAuthentication() {
			this.$refs['modal-email-authentication'].hide()
			goToSSOEmail()
		},
		openEmailCertificationModal() {
			if (!this.isAppModeExperience) {
				this.$refs['modal-email-authentication'].show()
			}
		},
		openJoinSuccessModal(title) {
			this.$refs['modal-success-join'].show(title)
		},
	},
}
</script>

<style lang="scss">
html,
body {
	max-width: 4096px;
	width: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
}

body {
	margin: 0;
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	width: auto;
	// height: 100%;
	overflow-y: hidden;
}

.App__header-temparary {
	display: block;
	position: absolute;
	left: 50%;
	z-index: 1000;
}

.App__router-container {
	display: block;
	position: fixed;
	z-index: 0;
	top: 60px;
	left: 250px;
	width: calc(100% - 250px);
	min-width: 650px;
	height: calc(100% - 60px) !important;
	/* overflow-y: hidden; */
	// overflow-x: auto;
	overflow-y: auto;
	&.experience {
		top: 140px !important;
		height: calc(100% - 140px) !important;
	}
}

.App__router-container-no-sidebar {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	min-width: 650px;
	height: 100%;
	width: 100%;
	max-width: unset;
	overflow-y: auto;
	background-color: $GREY_0;
}

.App__inner-container {
	display: block;
	position: relative;
	z-index: 0;
	min-width: 650px;
	height: 100%;
	min-height: 900px;
}

* {
	font-family: Pretendard;
	letter-spacing: normal;
	font-stretch: normal;
	font-style: normal;
}

input:focus,
button:focus,
textarea:focus {
	outline: none;
}
div,
input {
	box-sizing: border-box;
}
</style>
