var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "footer-wrap"
  }, [_c('div', {
    staticClass: "left-wrap"
  }), _c('div', {
    staticClass: "right-wrap"
  }, [_c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.searchStore
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/purchase/cargo/svg/icon-cart.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('랩매니저 스토어에서 찾기')))])]), _c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.printItemIdSticker
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/purchase/cargo/svg/icon-print.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('물품ID 스티커 출력')))])]), _c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.requestPurchase
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-request-buy.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('구매 요청')))])]), _vm.isShowGHSButton && !_vm.isGhsRegistered ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$emit('makeGHS');
      }
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/purchase/cargo/svg/icon-ghs.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('GHS 라벨 생성')))])]) : _vm.isShowGHSButton && _vm.isGhsRegistered ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$emit('manageGHS');
      }
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/purchase/cargo/svg/icon-ghs.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('GHS 라벨 관리')))])]) : _vm._e(), _vm.isCategoryChemical && _vm.hasCurrentVolume && _vm.isAvailable ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.divide
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-subdivide.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('소분')))])]) : _vm._e(), !_vm.isCategoryEquipment && _vm.hasCurrentVolume && !_vm.isUnAvailable ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-input-use'].show();
      }
    }
  }, [_vm.isCategoryChromato ? _c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/icon-input-use-chromato.svg")
    }
  }) : _c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-input-use.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt(_vm.inputUseButtonText)))])]) : _vm._e(), _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-select-storage'].show(_vm.item.storage);
      }
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-move.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('보관함 변경')))])]), !_vm.item.isDispose ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-dispose'].show();
      }
    }
  }, [_c('img', {
    staticClass: "image-icon",
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-disposal.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('폐기')))])]) : _vm._e()]), _c('modal-input-use', {
    ref: "modal-input-use",
    attrs: {
      "isCanCount": !_vm.isCategoryEquipment,
      "title": _vm.inputUseTitle,
      "volume": _vm.item.currentVolume,
      "volumeUnit": _vm.volumeUnit,
      "largeClass": _vm.item.category.classification,
      "useCount": _vm.item.useCount,
      "type": "CONSUME",
      "cancelText": "취소",
      "confirmText": "완료"
    },
    on: {
      "confirm": _vm.consume,
      "showEmptyVolume": function showEmptyVolume(body) {
        return _vm.$refs['modal-empty-volume'].show(body);
      }
    }
  }), _c('modal-empty-volume', {
    ref: "modal-empty-volume",
    attrs: {
      "title": "잔여 용량 없음",
      "type": "DISPOSE",
      "largeClass": _vm.item.category.classification
    },
    on: {
      "dispose": _vm.disposeEmptyVolume,
      "noDispose": _vm.noDisposeEmptyVolume
    }
  }), _c('modal-textarea', {
    ref: "modal-dispose",
    attrs: {
      "title": "물품 폐기",
      "titleTextarea": "폐기 사유",
      "headerText": "물품을 정말로 폐기하시겠습니까?<br />이 동작은 되돌릴 수 없습니다.",
      "placeholder": "폐기 사유를 입력해주세요(200자 제한)",
      "confirmText": "폐기",
      "variant": "warning",
      "type": "DISPOSE"
    },
    on: {
      "complete": _vm.dispose
    }
  }), _c('modal-single-select-storage', {
    ref: "modal-select-storage",
    attrs: {
      "title": "위치 및 보관함 선택"
    },
    on: {
      "complete": _vm.changeStorage
    }
  }), _c('modal-divide', {
    ref: "modal-divide"
  }), _c('modal-select-sticker-type', {
    ref: "modal-select-sticker-type",
    attrs: {
      "item": _vm.item
    },
    on: {
      "complete": function complete($event) {
        return _vm.$refs['modal-print-sticker'].show();
      }
    }
  }), _c('modal-print-sticker-single', {
    ref: "modal-print-sticker",
    attrs: {
      "type": "inventory",
      "item": _vm.item
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }