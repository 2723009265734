var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-form-item', {
    attrs: {
      "title": _vm.title,
      "required": _vm.required,
      "direction": _vm.direction,
      "description": _vm.description
    }
  }, [_c('div', {
    staticClass: "upload-form-item-wrapper"
  }, [_c('div', {
    staticClass: "upload-area",
    on: {
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.dropFile.apply(null, arguments);
      }
    }
  }, [_c('input', {
    ref: "file",
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('첨부할 파일을 마우스로 끌어오거나, 직접 선택해 주세요')))]), _c('button', {
    staticClass: "btn btn-gray sm",
    on: {
      "click": function click($event) {
        return _vm.$refs['file'].click();
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('파일 선택')))])]), _vm.files && _vm.files.length > 0 ? _c('div', {
    staticClass: "file-wrapper"
  }, _vm._l(_vm.files, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "file"
    }, [_c('div', {
      staticClass: "info"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/empty-doc.svg")
      }
    }), _c('span', [_vm._v(_vm._s(file.name))])]), _c('img', {
      attrs: {
        "src": require("@/assets/svg/close_c.svg")
      },
      on: {
        "click": function click($event) {
          return _vm.onClickDeleteFile(file);
        }
      }
    })]);
  }), 0) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }