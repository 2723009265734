var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CompleteInvite"
  }, [_c('receive-invite-header'), _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": _vm.logo
    }
  })]), _c('h1', {
    domProps: {
      "innerHTML": _vm._s("".concat(_vm.name, " ").concat(_vm.tt('연구실에<br>가입이 완료되었습니다')))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>라이선스가 만료</b>되어 랩매니저 PRO를 정상적으로 이용할 수 없습니다.<br>관리자가 <b>라이선스 구매 후, 이용 가능</b>합니다.'))
    }
  })]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "phone"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/phone.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.phone))])]), _c('div', {
    staticClass: "email"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/email.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.email))])])]), _c('modal-email-authentication', {
    ref: "modal-email-authentication",
    on: {
      "authentication": _vm.onClickAuthentication
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }