var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppNotice",
    "class": _vm.isNoticeOn ? 'active' : ''
  }, [_c('button', {
    staticClass: "inner",
    on: {
      "click": _vm.showNoticeList
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.newCount > 0 ? require('@/assets/svg/icon-notice-on.svg') : require('@/assets/svg/icon-notice-off.svg'),
      "alt": "icon"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.tt('알림(${1})', _vm.newCount)))])]), _vm.isNoticeOn == true ? _c('div', {
    staticClass: "notice-list"
  }, [_c('div', {
    staticClass: "title flex"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('알림(${1})', '+' + _vm.newCount)))]), _c('div', {
    staticClass: "ml-auto"
  }, [_c('button', {
    staticClass: "more",
    on: {
      "click": function click($event) {
        _vm.showOption = !_vm.showOption;
      },
      "blur": function blur($event) {
        _vm.showOption = false;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/More.svg")
    }
  }), _vm.showOption ? _c('ul', {
    staticClass: "option-list"
  }, [_vm.noticeList.length > 0 ? _c('li', {
    on: {
      "click": _vm.noticeAllRead
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/member-invite-authority-check.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('모두 읽은 상태로 표시')))])]) : _vm._e(), _vm.noticeList.length > 0 ? _c('li', {
    on: {
      "click": function click($event) {
        return _vm.noticeDelete();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-disposal.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('알림 모두 없애기')))])]) : _vm._e(), _c('li', {
    on: {
      "click": _vm.showSetting
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/authority-setting.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('알림 설정')))])])]) : _vm._e()]), _c('button', {
    staticClass: "Popup__close-btn close",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/close.svg"),
      "alt": "close"
    }
  })])])]), _c('ul', {
    staticClass: "list"
  }, _vm._l(_vm.noticeList, function (item) {
    return _c('li', {
      key: item.id,
      "class": item.isNew == true ? 'new' : ''
    }, [_c('div', {
      on: {
        "click": function click($event) {
          return _vm.confirmNotice(item);
        }
      }
    }, [_c('h6', [_vm._v(" " + _vm._s(item.title) + " "), item.isNew ? _c('img', {
      attrs: {
        "src": require("@/assets/svg/notice-new.svg"),
        "alt": "icon"
      }
    }) : _vm._e()]), _c('p', [_vm._v(_vm._s(item.content))]), _c('span', [_vm._v(_vm._s(_vm.formatDatetime(item.createDatetime)))])]), _c('button', {
      staticClass: "ml-auto"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.detailOption,
        expression: "detailOption"
      }],
      attrs: {
        "type": "radio"
      },
      domProps: {
        "value": item.id,
        "checked": _vm._q(_vm.detailOption, item.id)
      },
      on: {
        "click": function click($event) {
          return _vm.hideDetailOption(item.id);
        },
        "change": function change($event) {
          _vm.detailOption = item.id;
        }
      }
    }), _c('img', {
      attrs: {
        "src": require("@/assets/svg/More.svg")
      }
    }), _vm.detailOption == item.id ? _c('ul', {
      staticClass: "option-list"
    }, [item.isNew ? _c('li', {
      on: {
        "click": function click($event) {
          return _vm.noticeRead(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/member-invite-authority-check.svg")
      }
    }), _c('span', [_vm._v(_vm._s(_vm.tt('읽은 상태로 표시')))])]) : _vm._e(), _c('li', {
      on: {
        "click": function click($event) {
          return _vm.noticeDelete(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/icon-disposal.svg")
      }
    }), _c('span', [_vm._v(_vm._s(_vm.tt('알림 없애기')))])])]) : _vm._e()])]);
  }), 0), _vm.noticeList.length == 0 ? _c('div', {
    staticClass: "no-data-list"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_list.svg")
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.tt('알림이 없습니다')))]), _c('p', [_vm._v(_vm._s(_vm.tt('새로운 알림이 오면 목록에 추가됩니다')))])]) : _vm._e()]) : _vm._e(), _c('modal-account-setting', {
    ref: "modal-account-setting"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }