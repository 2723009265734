<template>
	<b-modal centered scrollable @close="cancel" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<item-category-selector title="카테고리 선택" ref="category-selector"></item-category-selector>
			<regist-method-selector
				v-if="category"
				:isGas="isGas"
				:isChemical="isChemical"
				:isValid="!!registMethod"
				:value="registMethod"
				:isShowError="showError"
				@change="type => setRegistMethod(type)"
			></regist-method-selector>
		</template>
		<template #modal-footer>
			<modal-footer completeText="다음" cancelText="취소" @cancel="cancel" @complete="goNext"></modal-footer>
		</template>
	</b-modal>
</template>
<script>
import ItemCategorySelector from '@/views/inventoryGen2/components/regist/ItemCategorySelector.vue'
import RegistMethodSelector from '@/views/inventoryGen2/components/regist/registMethodSelector'
import ModalFooter from '@/views/common/components/modals/ModalFooter.vue'

import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'ModalRegistItem',
	components: { ItemCategorySelector, RegistMethodSelector, ModalFooter },
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/form', ['category', 'categoryInfo', 'isGas', 'isChemical', 'registMethod', 'showError']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/form', ['initItem', 'setRegistMethod']),
		show() {
			this.initItem()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cancel() {
			this.initItem()
			this.hide()
		},
		goNext() {
			if (!this.categoryInfo || !this.registMethod) {
				this.$refs['category-selector'].showRequire()
			} else {
				this.hide()
			}
			this.$emit('next')
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-body::-webkit-scrollbar {
	display: none;
}

@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-regist-item.scss';
</style>
