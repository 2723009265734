<template>
	<div class="expenditure-list-wrapper">
		<h6>
			{{ tt('지출내역 목록') }}
		</h6>
		<div class="list-wrapper">
			<expenditure-table :list="list" />
		</div>
		<pagination notSticky :total="count" @page="setLoadParams"></pagination>
	</div>
</template>
<script>
import Pagination from '@/views/common/components/Pagination.vue'
import ExpenditureTable from './table.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
	name: 'ExpenditureList',
	components: {
		ExpenditureTable,
		Pagination,
	},
	props: {
		budgetId: {
			type: [String, Number],
			default: null,
		},
		group: {
			type: Object,
			default: null,
		},
		year: {
			type: Number,
		},
	},
	computed: {
		...mapState('budget/expenditure', ['list', 'count']),
	},
	async created() {
		this.initLoadParams()
		await this.setLoadParams({ budgetId: this.budgetId, year: this.year, groupId: this.group?.id })
	},
	methods: {
		...mapMutations('budget/expenditure', ['initLoadParams']),
		...mapActions('budget/expenditure', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
.expenditure-list-wrapper {
	h6 {
		font-weight: 700;
		font-size: 16px;
		line-height: 23px;
		margin-bottom: 8px;
	}
	.list-wrapper {
		position: relative;
		width: 100%;
		overflow-x: auto;
		border: 1px solid $INPUT;
		border-radius: 4px;
		& > * {
			min-height: 500px;
		}
	}
}
</style>
