var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('h1', [_vm._v(_vm._s(_vm.tt('상품주문이 완료되었습니다')))]), _c('b', [_vm._v(_vm._s(_vm.tt('주문번호 : ${1}', _vm.orderNumber)))]), _c('b', [_vm._v(_vm._s(_vm.tt('주문자 이메일 : ${1}', _vm.ordererEmail)))]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("\uB7A9\uB9E4\uB2C8\uC800 \uC2A4\uD1A0\uC5B4\uC5D0 \uC0C1\uD488 \uC8FC\uBB38\uC774 \uC811\uC218\uB418\uC5C8\uC2B5\uB2C8\uB2E4.<br>'\uC8FC\uBB38 \uC0C1\uC138 \uD654\uBA74\uC73C\uB85C \uC774\uB3D9'\uC744 \uD074\uB9AD \uD558\uC2DC\uBA74 \uC8FC\uBB38 \uC815\uBCF4\uB97C \uD655\uC778\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br>\u203B \uC0C1\uD488\uB300\uAE08 \uACB0\uC81C\uB294 \uC8FC\uBB38\uC790 \uC2B9\uC778 \uC774\uD6C4 \uAC00\uB2A5 \uD569\uB2C8\uB2E4."))
    }
  }), _c('div', {
    staticClass: "button-wrap"
  }, [_c('base-button', {
    staticStyle: {
      "height": "47px"
    },
    on: {
      "click": _vm.goOrder
    }
  }, [_vm._v(_vm._s(_vm.tt('상품 추가 주문하기')))]), _c('base-button', {
    staticStyle: {
      "height": "47px"
    },
    attrs: {
      "color": 'primary'
    },
    on: {
      "click": _vm.goStore
    }
  }, [_vm._v(_vm._s(_vm.tt('주문상세 화면으로 이동')))])], 1)]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/store/order-success.svg")
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }