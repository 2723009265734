<template>
	<b-modal ref="modal" size="sm" hide-header centered hide-footer no-close-on-backdrop no-fade v-if="item">
		<img v-if="item.image" :class="{ canClick: item.url != null }" :src="item.image" @click="moveToUrl" />
		<div class="popup-wrapper" v-else>
			<img class="icon-notice" src="@/assets/images/icon-main-popup.png" />
			<h2>{{ item.title }}</h2>
			<p v-html="item.content"></p>
			<img class="btn-notice" :class="{ canClick: item.url != null }" src="@/assets/images/btn-main-popup.png" @click="moveToUrl" />
		</div>
		<div class="btn-footer">
			<button @click="clickDonwShow">{{ tt('일주일간 보지 않기') }}</button>
			<button @click="hide">{{ tt('닫기') }}</button>
		</div>
	</b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
const dontShow7daysKey = 'dontShow7daysKey'
export default {
	created() {},
	computed: {
		...mapState('mainPopup', ['item']),
		lastNoticeId() {
			return this.$cookies.get(dontShow7daysKey)?.id ?? null
		},
		isNoticeShowAllow() {
			let date = this.$cookies.get(dontShow7daysKey)?.date ?? null
			if (date == null) return true
			return this.$DateTime.fromISO(date).plus({ days: 7 }) <= this.$DateTime.now()
		},
	},
	methods: {
		...mapActions('mainPopup', ['getNotice']),
		async show() {
			await this.getNotice()
			if (this.item && (this.isNoticeShowAllow || this.item?.id != this.lastNoticeId)) {
				await this.$nextTick()
				this.$refs.modal.show()
			}
		},
		hide() {
			this.$refs.modal.hide()
		},
		clickDonwShow() {
			this.$cookies.set(
				dontShow7daysKey,
				JSON.stringify(
					{
						id: this.item.id,
						date: this.$DateTime.now().toISO(),
					},
					'7d'
				)
			)
			this.$refs.modal.hide()
		},
		moveToUrl() {
			if (this.item.url) {
				if (this.item.url.includes('labmanagerpro')) {
					this.$router.push({
						name: this.item.url.replace('labmanagerpro://', ''),
					})
				} else if (this.item.url.includes('http')) {
					window.open(this.item.url, '_blank')
				}
			}
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.modal-dialog {
		width: 335px;
		max-width: 335px;
		.modal-content {
			border: none;
			background-color: transparent;
		}
	}
	.modal-body {
		padding: 0px;
		& > img,
		.popup-wrapper {
			border-radius: 10px 10px 0px 0px;
			width: 335px;
			height: 430px;
			// cursor: pointer;
		}
		.canClick {
			cursor: pointer;
		}
		.popup-wrapper {
			background-color: $SUB-YELLOW_100;
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: unset;
			.icon-notice {
				margin-top: 48px;
			}
			.btn-notice {
				cursor: pointer;
				margin-top: auto;
				margin-bottom: 48px;
			}
			h2 {
				font-size: 22px;
				color: $SECONDARY_1;
				margin-top: 30px;
				font-weight: 600;
			}
			p {
				padding: 0px 20px;
				width: 100%;
				margin-top: 20px;
				font-size: 15px;
				word-break: break-all;
				font-weight: 400;
				line-height: 200%;
			}
		}
		.btn-footer {
			width: 100%;
			height: 56px;
			display: flex;
			background-color: white;
			border-radius: 0px 0px 10px 10px;
			button {
				justify-content: center;
				align-items: center;
				width: 50%;
				font-size: 14px;
				color: $SECONDARY_2;
				font-weight: 500;
				&:last-child {
					border-left: 1px solid $DIVIDER;
				}
			}
		}
	}
}
</style>
