<template>
	<div class="total-price-wrapper">
		<div>
			<div class="label">{{ tt('소비자가 합계') }}</div>
			<div class="value">{{ tt('${1}원', formatNumberLocale(total)) }}</div>
			<img src="@/assets/svg/table-minus.svg" />
			<div class="label">{{ tt('할인금액') }}</div>
			<div class="value red">{{ tt('${1}원', formatNumberLocale(sale ?? 0)) }}</div>
			<img src="@/assets/svg/table-plus.svg" />
			<div class="label">{{ tt('배송비') }}</div>
			<div class="value">{{ tt('${1}원', formatNumberLocale(deliveryFee)) }}</div>
			<img src="@/assets/svg/table-plus.svg" />
			<div class="label">{{ tt('VAT(10%)') }}</div>
			<div class="value">{{ tt('${1}원', formatNumberLocale(vatPrice)) }}</div>
		</div>
		<div>
			<b>{{ tt('주문 금액 합계') }}:</b>
			<div class="value lg">{{ tt('${1}원', formatNumberLocale(totalExpenditurePrice)) }}</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		total: {
			type: [Number, String],
			default: 0,
		},
		deliveryFee: {
			type: [Number, String],
			default: 0,
		},
		sale: {
			type: [Number, String],
			default: 0,
		},
		vatPrice: {
			type: [Number, String],
			default: 0,
		},
	},
	computed: {
		totalExpenditurePrice() {
			let price = 0
			price = this.total + this.deliveryFee - this.sale + this.vatPrice
			return price
		},
	},
}
</script>
<style lang="scss" scoped>
.total-price-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 104px;
	gap: 15px;
	border-top: 1px solid $INPUT;
	background: $GREY_0;
	justify-content: center;
	align-items: center;
	line-height: 20px;
	div {
		display: flex;
		gap: 10px;
		b {
			margin-right: 10px;
		}
		.label {
			color: $SECONDARY_2;
		}
		.value {
			&.red {
				color: $RED;
			}
			&.lg {
				font-size: 1.25rem;
			}
		}
	}
}
</style>
