var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }), _c('div', {
          staticClass: "footer-right"
        }, [_vm.noCancel ? _c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.cancelText)) + " ")]) : _vm._e(), _vm.hasConfirm ? _c('button', {
          "class": "button-".concat(_vm.variant),
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.confirmText)) + " ")]) : _vm._e()])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "form-input"
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.label))), _vm.isRequired ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]), _c('div', {
    staticClass: "input-wrapper"
  }, [_vm.type === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    ref: "inputValue",
    attrs: {
      "placeholder": _vm.tt(_vm.label + '를 입력해주세요'),
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.inputValue) ? _vm._i(_vm.inputValue, null) > -1 : _vm.inputValue
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.inputValue,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.inputValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.inputValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.inputValue = $$c;
        }
      }
    }
  }) : _vm.type === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    ref: "inputValue",
    attrs: {
      "placeholder": _vm.tt(_vm.label + '를 입력해주세요'),
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.inputValue, null)
    },
    on: {
      "change": function change($event) {
        _vm.inputValue = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    ref: "inputValue",
    attrs: {
      "placeholder": _vm.tt(_vm.label + '를 입력해주세요'),
      "type": _vm.type
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputValue = $event.target.value;
      }
    }
  })])]), _vm._t("default")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }