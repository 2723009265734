<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name">pH Range(From-To)<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper">
			<input type="number" placeholder="pH Range(From)" v-model="inputFrom" @input="handleInput($event, 'From')" />
			<input type="number" placeholder="pH Range(To)" v-model="inputTo" @input="handleInput($event, 'To')" />
		</div>
		<span class="error" v-if="error">{{ tt(errorMessage) }}</span>
	</div>
</template>

<script>
export default {
	name: 'FormPhRange',
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			default: null,
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputFrom: null,
			inputTo: null,
		}
	},
	computed: {
		error() {
			return this.required && this.isShowError && !(this.inputFrom || this.inputTo)
		},
	},
	watch: {
		value: {
			deep: true,
			handler(value) {
				if (value?.from !== this.inputFrom) {
					this.inputFrom = value.from
				}
				if (value?.to !== this.inputTo) {
					this.inputTo = value.to
				}
			},
		},
	},
	mounted() {
		if (this.value?.from !== this.inputFrom) {
			this.inputFrom = this.value.from
		}
		if (this.value?.to !== this.inputTo) {
			this.inputTo = this.value.to
		}
	},
	methods: {
		handleInput(e, type) {
			this[`input${type}`] = e.target.value
			this.$emit('input', {
				from: this.inputFrom,
				to: this.inputTo,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-input.scss';
</style>
