<template>
	<div class="Member__all">
		<div class="Member__filter">
			<search-box :placeholder="tt('멤버 이름으로 검색해주세요')" :loadList="loadMemberList"></search-box>
			<image-button
				:text="filterText"
				:isActive="filteredCount > 0"
				:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
				:image="require('@/assets/svg/filter.svg')"
				@click="showFilter"
			></image-button>
			<div class="right-btn-group">
				<button
					class="btn"
					@click="showInviteMemberModal"
					v-if="userPermission.institute && userPermission.institute.member.create && !isAppModeExperience"
				>
					<img src="@/assets/svg/add-member.svg" />
					{{ tt('멤버 초대') }}
				</button>
				<button
					class="btn"
					@click="showRegisteringMember('add')"
					v-if="userPermission.institute && userPermission.institute.member.create && !isAppModeExperience"
				>
					<img src="@/assets/svg/button-icon-lisence-copy.svg" />
					{{ tt('멤버 추가') }}
				</button>
				<button
					class="btn"
					@click="goAllReigstering"
					v-if="userPermission.institute && userPermission.institute.member.create && !isAppModeExperience"
				>
					<img src="@/assets/svg/excel-download.svg" />
					{{ tt('멤버 일괄 추가') }}
				</button>
				<button class="btn" @click="showInitPwSetting" v-if="!isAppModeExperience">
					<img src="@/assets/svg/icon-password.svg" />
					{{ tt('초기 비밀번호 설정') }}
				</button>
				<button
					class="btn"
					@click="showExportMemberModal('multi')"
					:disabled="checkList.length == 0"
					v-if="userPermission.institute && userPermission.institute.member.delete && !isAppModeExperience"
					style="margin-left: 30px"
				>
					<img src="@/assets/svg/button-memeber-export.svg" alt="버튼이미지" />{{ tt('내보내기') }}
				</button>
				<button
					class="btn"
					@click="showAddPermissionModal"
					:disabled="checkList.length == 0"
					v-if="userPermission.institute && userPermission.institute.member.create"
				>
					<img src="@/assets/svg/Authority2.svg" alt="버튼이미지" />
					{{ tt('권한 추가') }}
				</button>
			</div>
		</div>
		<div class="Member__table Table__wrap" :class="{ experience: isAppModeExperience }">
			<table>
				<thead>
					<tr>
						<th>
							<button class="checkbox" :class="({ active: isActive }, { active: isCheck })" @click="allChecked"></button>
						</th>
						<th width="434">
							<span>{{ tt('멤버 정보') }}</span>
						</th>
						<th width="278">
							<span>{{ tt('소속') }}</span>
						</th>
						<!-- class="sorting active" -->
						<th width="390">
							<span>{{ tt('권한') }} </span>
						</th>
						<th width="240">
							<span>{{ tt('관리') }}</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<member-item
						v-for="item in list"
						:key="item.id"
						:item="item"
						:allCheck="isActive"
						@checkId="addCheckIdList"
						@uncheckId="deleteCheckIdList"
						@updateMember="showEditMemberModal"
						@updatePermission="showEditPermissionModal"
						@exportMember="showExportMemberModal"
						@infoMember="showInfoMemberModal"
						:checkList="checkList"
						:userPermission="userPermission"
					></member-item>
				</tbody>
			</table>
			<no-data-list v-if="list.length == 0" :image="noData.image" :title="noData.title" :content="noData.content"></no-data-list>
		</div>
		<div class="Menu__floating" v-if="checkList.length > 0">
			<h4>
				<span class="count">{{ checkCount }}{{ tt('명') }}</span
				>{{ tt('선택') }}
			</h4>
			<div class="btn-group">
				<button @click="showAddPermissionModal" v-if="userPermission.institute && userPermission.institute.member.update">
					<img src="@/assets/svg/Authority2.svg" alt="버튼icon" />
					<span>{{ tt('권한 추가') }}</span>
				</button>
				<button @click="showExportMemberModal('multi')" v-if="userPermission.institute && userPermission.institute.member.delete">
					<img src="@/assets/svg/button-memeber-export.svg" alt="버튼icon" />
					<span>{{ tt('내보내기') }}</span>
				</button>
			</div>
		</div>

		<pagination :total="count" @page="pagination"></pagination>

		<modal-invite-member ref="modal-invite-member"></modal-invite-member>
		<modal-export-inst-member ref="modal-export-inst-member" @export="hideExportMemberModal"></modal-export-inst-member>
		<modal-add-member-permission ref="modal-add-member-permission" @isHide="hideAddPermissionModal"></modal-add-member-permission>
		<modal-edit-member-permission ref="modal-edit-member-permission" @isHide="hideEditPermissionModal"></modal-edit-member-permission>
		<!-- <modal-edit-member
            ref="modal-edit-member"
            @isHide="hideEditMemberModal"
            :member="this.selectMember"
        ></modal-edit-member> -->
		<modal-member-info
			ref="modal-member-info"
			:userInfo="userInfo"
			:userPermission="userPermission"
			@delete="showExportMemberModal"
			@info="hideInfoMemberModal"
			@completeRegistering="completeRegistering"
			@showRegisteringMember="showRegisteringMember"
		></modal-member-info>
		<modal-filter ref="modal-filter" :filterGroupList="filterGroupList" @filter="hideFilterModal"></modal-filter>
		<!--2021.12 추가-->
		<modal-registering-member
			ref="modal-registering-member"
			page="member"
			:userInfo="userInfo"
			@update="updateRegistering"
			@complete="completeRegistering"
		></modal-registering-member>
		<modal-init-pw-setting ref="modal-init-pw-setting" @hideInitPwSetting="hideInitPwSetting"></modal-init-pw-setting>
		<modal-complete-registering-member
			ref="modal-complete-registering-member"
			@registering="showRegisteringMember('add')"
		></modal-complete-registering-member>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ModalInviteMember from '@/views/institute/member/modals/ModalInviteMember.vue'
import ModalExportInstMember from '@/views/institute/member/modals/ModalExportInstMember.vue'
import ModalAddMemberPermission from '@/views/institute/member/modals/ModalAddMemberPermission.vue'
import ModalEditMemberPermission from '@/views/institute/member/modals/ModalEditMemberPermission.vue'
import ModalRegisteringMember from '@/views/institute/member/modals/ModalRegisteringMember.vue'
import ModalInitPwSetting from '@/views/institute/member/modals/ModalInitPwSetting.vue'
import ModalCompleteRegisteringMember from '@/views/creation/modals/ModalCompleteRegisteringMember.vue'
import instituteGroupAPI from '@/services/api/institute/group'
import ModalMemberInfo from '@/views/common/components/modals/ModalMemberInfo.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import MemberItem from '@/views/institute/member/components/MemberItem.vue'
import SearchBox from '@/views/common/components/SearchBox.vue'
import ModalFilter from '@/views/common/components/modals/ModalFilter.vue'
import PageName from '@/utils/define/PageName'
import instituteMemberAPI from '@/services/api/institute/member'
import institutePermissionAPI from '@/services/api/institute/permission'
import userAPI from '@/services/api/institute/user'
import NoDataList from '@/views/common/components/NoDataList.vue'
import { isClickedInstitution } from '@/store/modules/isClickedItem'
import MixinLicense from '@/mixins/license'

export default {
	name: 'MemberAll',
	components: {
		MemberItem,
		ModalInviteMember,
		ModalExportInstMember,
		ModalAddMemberPermission,
		ModalEditMemberPermission,
		// ModalEditMember,
		ModalMemberInfo,
		Pagination,
		SearchBox,
		ModalFilter,
		ModalRegisteringMember,
		ModalInitPwSetting,
		ModalCompleteRegisteringMember,
		NoDataList,
	},
	mixins: [MixinLicense],
	data() {
		return {
			isActive: false,
			isCheck: false,
			instId: '',
			keyword: '',
			offset: 0,
			length: 50,
			groupsId: [],
			checkList: [],
			checkId: '',
			checkCount: 0,
			list: [],
			filter: false,
			filterList: [],
			filterGroupList: [],
			resetFilter: false,
			count: 0,
			selectMember: {},
			initPw: null,
			userInfo: {},
			noData: {
				image: '',
				title: '',
				content: '',
			},
			filteredCount: 0,
		}
	},
	async created() {
		this.instId = this.$route.params.instituteId
		this.loadUser()
		this.loadFilter()
		await this.loadMemberList()
		this.loadInitPw()
		await this.loadInstitutionInfo(this.instId)
		await this.loadLicense()
		this.checkLicense()
	},
	watch: {
		checkList: function () {
			this.checkCount = this.checkList.length
		},
		list(val) {
			if (val.length == 0) {
				this.noData = {
					image: 'search',
					title: '조회된 결과가 없습니다',
					content: '다시 한 번 확인해 주세요',
				}
			}
		},
	},
	computed: {
		...mapState('member', ['memberList', 'member']),
		...mapState('institutionInformation', ['listKeyword']),
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
	},
	methods: {
		...mapActions('institutionInformation', ['loadInstitutionInfo']),
		...mapActions('member', ['lookupMemberList', 'lookupMember', 'deleteMember', 'updateMember']),
		...mapActions('permission', ['addMemberPermission', 'deleteMemberPermission', 'deleteGroupPermission', 'addGroupPermission']),
		checkLicense() {
			if (this.isTrial) {
				this.$root.howToAddLicense()
			} else if (this.license.quantity < this.instInfo.allMemberCount) {
				this.$root.addLicense()
			}
		},
		async loadUser() {
			const response = await userAPI.lookupUserInfo()

			this.userInfo = response.data
		},
		async loadFilter() {
			this.filterGroupList = []
			const response = await instituteGroupAPI.lookupGroupList(this.$route.params.instituteId)

			const list = response.data
			list.forEach(x => {
				this.filterGroupList.push({
					id: x.id,
					name: x.name,
					checked: false,
				})
			})
		},
		async loadMemberList() {
			this.checkList = []
			const response = await instituteMemberAPI.lookupMemberList(this.instId, this.offset, this.length, this.listKeyword, this.groupsId)
			if (response.status == 200) {
				this.list = response.data.members
				this.count = response.data.count
			}
		},
		async loadInitPw() {
			const response = await instituteMemberAPI.lookupInitPw(this.$route.params.instituteId)

			this.initPw = response.data.password
		},
		allChecked() {
			if (this.isActive) {
				this.isActive = false
				this.isCheck = false
			} else {
				this.isActive = true
				this.isCheck = true
			}
		},
		addCheckIdList(id) {
			this.checkList.push(id)
			if (this.checkList.length == this.list.length) {
				this.isCheck = true
			}
		},
		deleteCheckIdList(id) {
			for (let i = 0; i < this.checkList.length; i++) {
				if (this.checkList[i] === id) {
					this.checkList.splice(i, 1)
				}
			}
			this.isCheck = false
		},
		showFilter() {
			this.$refs['modal-filter'].show(null, null, this.resetFilter)
		},
		hideFilterModal(filterList) {
			this.resetFilter = false
			this.$refs['modal-filter'].hide()
			this.filterGroupList = filterList.groupList
			this.groupsId = []
			filterList.groupList.forEach(x => {
				if (x.checked == true) {
					this.groupsId.push(x.id)
				}
			})

			this.filteredCount = this.groupsId.length

			this.$root.toast(this.tt('필터'), this.tt('필터가 적용되었습니다'), 'success')
			this.loadMemberList()
		},
		checkIdList(list) {
			this.checkList = list
		},
		showExportMemberModal(id) {
			if (id != 'multi') {
				this.checkId = id
			} else {
				this.checkId = null
			}
			this.$refs['modal-export-inst-member'].show()
		},
		async hideExportMemberModal() {
			this.$refs['modal-export-inst-member'].hide()
			let ids = []
			if (this.checkId) {
				ids.push(this.checkId)
			} else {
				ids = this.checkList
			}
			instituteMemberAPI
				.deleteMember(this.instId, ids)
				.then(() => {
					this.$root.toast('내보내기 완료', '멤버를 기관에서 내보냈습니다', 'success')
					this.loadMemberList()
				})
				.catch(error => {
					this.$root.toast('내보내기 실패', error.response.data.msg, 'error')
				})
		},
		showAddPermissionModal() {
			this.$refs['modal-add-member-permission'].show()
		},
		hideAddPermissionModal(list) {
			this.$refs['modal-add-member-permission'].hide()
			list.forEach(x => {
				const addData = {
					instId: this.instId,
					body: {
						usersId: this.checkList,
						permissionId: x.id,
					},
				}
				this.addMemberPermission(addData).then(() => {
					this.$root.toast('정보 편집완료', '멤버 정보가 편집되었습니다', 'success')
					this.loadMemberList()
				})
			})
		},
		showEditMemberModal(id) {
			const payload = {
				instId: this.instId,
				id: id,
			}
			this.lookupMember(payload).then(() => {
				if (this.initPw == null) {
					this.showInitPwSetting('init')
					this.$root.toast('초기 비밀번호 설정', '초기 비밀번호를 먼저 설정해주세요', 'warning')
				} else {
					this.$refs['modal-registering-member'].show('edit', this.member)
				}
			})
		},
		showEditPermissionModal(id) {
			const payload = {
				instId: this.instId,
				id: id,
			}
			this.lookupMember(payload).then(() => {
				this.$refs['modal-edit-member-permission'].show(this.member.permissions)
			})
		},
		hideEditPermissionModal(list) {
			this.$refs['modal-edit-member-permission'].hide()
			if (list != undefined) {
				this.changePermission(list)
			}

			const toastTitle = this.tt('권한 관리')
			const toastContent = this.tt('권한이 저장되었습니다')
			this.$root.toast(toastTitle, toastContent, 'success')
		},
		showInfoMemberModal(id) {
			const payload = {
				instId: this.instId,
				id: id,
			}
			this.lookupMember(payload).then(() => {
				this.$refs['modal-member-info'].show(id, this.instId)
			})
		},
		async hideInfoMemberModal(member) {
			this.$refs['modal-member-info'].hide()
			this.changePermission(member.permissions)
			this.changeGroup(member.groups)
			const formData = new FormData()
			formData.append('id', member.id)
			formData.append('name', member.name)
			formData.append('phone', member.phone)
			if (typeof member.image == 'object') {
				formData.append('image', member.image)
			} else {
				formData.delete('image')
			}

			const response = await instituteMemberAPI.updateMember(this.instId, formData)
			if (response.status == 205) {
				this.$root.toast('정보 편집완료', '멤버 정보가 편집되었습니다', 'success')
				this.loadMemberList()
			}
		},
		changeGroup(list) {
			const changeList = []
			for (let i = 0; i < list.length; i++) {
				changeList.push(list[i].id)
			}
			const preList = []
			for (let i = 0; i < this.member.groups.length; i++) {
				preList.push(this.member.groups[i].id)
			}
			const deleteList = preList.filter(x => !changeList.includes(x))
			const addList = changeList.filter(x => !preList.includes(x))
			for (let i = 0; i < deleteList.length; i++) {
				instituteGroupAPI.deleteGroupMember(deleteList[i], [this.member.id]).then(() => {
					this.loadMemberList()
				})
			}
			for (let i = 0; i < addList.length; i++) {
				if (addList[i] != '') {
					instituteGroupAPI.addGroupMember(addList[i], { ids: [this.member.id] }).then(() => {
						this.loadMemberList()
					})
				}
			}
		},
		changePermission(list) {
			const changeList = []
			for (let i = 0; i < list.length; i++) {
				changeList.push(list[i].id)
			}
			const preList = []
			for (let i = 0; i < this.member.permissions.length; i++) {
				preList.push(this.member.permissions[i].id)
			}
			const deleteList = preList.filter(x => !changeList.includes(x))
			const addList = changeList.filter(x => !preList.includes(x))
			for (let i = 0; i < deleteList.length; i++) {
				let deleteData = {
					instId: this.instId,
					userId: this.member.id,
					permissionId: deleteList[i],
				}
				this.deleteMemberPermission(deleteData).then(() => {
					this.loadMemberList()
				})
			}
			for (let i = 0; i < addList.length; i++) {
				if (addList[i] != '') {
					let addData = {
						instId: this.instId,
						body: {
							usersId: [this.member.id],
							permissionId: addList[i],
						},
					}
					this.addMemberPermission(addData).then(() => {
						this.loadMemberList()
					})
				}
			}
		},
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
			this.loadMemberList()
		},
		showInitPwSetting() {
			this.$refs['modal-init-pw-setting'].show()
		},
		hideInitPwSetting() {
			this.$refs['modal-init-pw-setting'].hide()
			this.loadInitPw()
		},
		showRegisteringMember(mode, item) {
			if (this.initPw == null) {
				this.$refs['modal-member-info'].hide()
				this.showInitPwSetting('init')
				this.$root.toast('초기 비밀번호 설정', '초기 비밀번호를 먼저 설정해주세요', 'warning')
			} else {
				this.$refs['modal-complete-registering-member'].hide()
				this.$refs['modal-registering-member'].show(mode, item)
			}
		},
		updateRegistering(info) {
			this.loadMemberList()
			if (this.$refs['modal-member-info'].isShow == true) {
				this.$refs['modal-member-info'].show(info.id, this.$route.params.instituteId)
			}
			this.$refs['modal-registering-member'].hide()
		},
		completeRegistering(info, mode) {
			this.loadMemberList()
			this.$refs['modal-registering-member'].hide()
			if (info && mode == 'add') {
				this.$refs['modal-complete-registering-member'].show(info)
			}
		},
		goAllReigstering() {
			this.$router.push({
				name: 'excelRegisteringMember',
				query: {
					page: 'member',
				},
			})
		},
		showInviteMemberModal() {
			this.$refs['modal-invite-member'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_member.scss';
.Table__wrap.Member__table {
	max-height: calc(100vh - 30px) !important;
	height: calc(100vh - 224px) !important;
	table {
		tbody {
			tr {
				cursor: pointer;
			}
		}
	}
}
</style>
