<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt('등록할 수량') }}</div>
			<div class="form-msg" :class="{ error: !isCounterValid }">
				{{ tt('${1}개', multiCreateCount) }}
			</div>
		</div>
		<div class="error-msg" v-if="isShowError">
			{{ tt('수량은 최소 ${1}개 이상 최대 ${2}개 이하입니다.', min, max) }}
		</div>
		<form-counter
			ref="form-counter"
			:title="tt('등록할 수량')"
			:min="min"
			:max="max"
			:value="value"
			:hasOrderCount="hasOrderCount"
			@change="setCount"
			:isShowError="error"
		></form-counter>
	</div>
</template>
<script>
import FormCounter from '@/views/common/components/form/FormCounter.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'CargoFormCounter',
	components: { FormCounter },
	props: {
		min: {
			type: [Number, String],
			default: 1,
		},
		max: {
			type: [Number, String],
			default: 500,
		},
	},
	data() {
		return {
			isShowError: false,
			value: 0,
		}
	},
	computed: {
		...mapGetters('cargo/form', ['cargoFormType', 'multiCreateCount']),
		isCounterValid() {
			return this.multiCreateCount <= this.max && this.multiCreateCount >= this.min
		},
		hasOrderCount() {
			return ['NEED_INPUT_DETAIL', 'NEED_INPUT_LIST', 'EDIT'].includes(this.cargoFormType)
		},
		error() {
			return !this.isCounterValid && this.isShowError
		},
	},
	created() {},
	mounted() {
		this.value = this.multiCreateCount
	},
	methods: {
		...mapMutations('cargo/form', ['setMultiCreateCount']),
		showRequire() {
			this.isShowError = !this.isCounterValid
			this.$refs['form-counter']?.showError()
		},
		setCount(value) {
			this.setMultiCreateCount(value)
		},
	},
}
</script>

<style lang="scss" scoped>
.error-msg {
	background: $SUB-RED;
	border-radius: 5px;
	min-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $RED;
	font-weight: 400;
	font-size: 14px;
}
.form-wrap {
	display: flex;
	flex-direction: column;
	padding: 20px;
	background: white;
	border: 1px solid $DIVIDER;
	border-radius: 8px;
	gap: 18px;
	width: 660px;
	&.sm {
		padding: 10px !important;
	}
	.msg-warning {
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		color: $RED;
	}
	.form-header {
		display: flex;
		justify-content: space-between;

		font-weight: 700;
		font-size: 16px;
		&.column {
			flex-direction: column;
		}
		.form-header-subtext {
			margin-top: 5px;
		}
		.form-header-text {
			flex-direction: row;
		}
		.form-title {
		}
		.form-msg {
			color: $GREEN;
			&.error {
				color: $RED;
			}
		}
	}
	.form-header-subtext {
		font-weight: 400;
		font-size: 14px;
	}
	.counter-wrap {
		width: 300px;
		.input-header {
			display: flex;
			justify-content: space-between;

			font-size: 12px;
			font-weight: 400;
			margin-bottom: 8px;

			.input-title {
			}
			.input-sub {
				color: $BLUE;
			}
		}
	}
	.input-wrap {
		max-width: 300px;
		// width: calc(50% - 9px);
	}
	.form-data-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 18px;
	}
}
</style>
