<template>
	<b-modal ref="image-viewer" centered hide-footer no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="content">
				<img class="image" :src="image" v-if="!isRawSvg" />
				<div class="image" v-html="image" v-else></div>
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalImageViewer',
	props: {
		title: {
			type: String,
			default: '',
		},
		image: {
			type: String,
			default: null,
		},
		isRawSvg: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShow: false,
		}
	},
	components: {},
	methods: {
		show() {
			this.$refs['image-viewer'].show()
		},
		hide() {
			this.$refs['image-viewer'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	padding: 20px;
	border: 0;
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
	width: auto;
}

::v-deep .modal-dialog {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 800px;
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	gap: 20px;
	max-height: 700px;
	overflow-x: scroll;
	border-top: 1px solid $DIVIDER;
	.content {
		align-items: center;
		max-width: 800px;
	}
}
</style>
