<template>
	<tr class="table-item">
		<td>
			<div>{{ createDatetime }}</div>
		</td>
		<td>
			<div style="min-width: 250px; max-width: 300px">{{ item.budget.name }}</div>
		</td>
		<td>
			<div>{{ item.budget.code }}</div>
		</td>
		<td>
			<div style="min-width: 200px; max-width: 250px" v-b-tooltip.hover :title="item.draft.subject">
				{{ item.draft.subject ? item.draft.subject : tt('기안없이 상품 주문') }}
			</div>
		</td>
		<td class="right">
			<div>{{ tt('${1}원', item.amount.toLocaleString()) }}</div>
		</td>
		<td>
			<div v-b-tooltip.hover :title="item.draft.group.name">{{ item.draft.group.name }}</div>
		</td>
		<td>
			<div class="profile-wrap"><img class="profile-img" :src="userImage" /> {{ item.draft.user.name }}</div>
		</td>
		<td>
			<div>{{ draftCreateDatetime }}</div>
		</td>
		<td>
			<div>{{ draftConcludeDatetime }}</div>
		</td>
	</tr>
</template>
<script>
import PageName from '@/utils/define/PageName'
import MemberDefault from '@/assets/svg/member-photo-default.svg'
export default {
	props: {
		item: {
			type: Object,
		},
	},
	computed: {
		draftCreateDatetime() {
			return this.$DateTime.fromISO(this.item.draft.createDatetime).toFormat('yyyy.MM.dd T')
		},
		draftConcludeDatetime() {
			if (this.item.draft.concludeDatetime) return this.$DateTime.fromISO(this.item.draft.concludeDatetime).toFormat('yyyy.MM.dd T')
			else return '-'
		},
		createDatetime() {
			return this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy.MM.dd T')
		},
		userImage() {
			if (this.item.draft.user.image) return this.item.draft.user.image
			else return MemberDefault
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
