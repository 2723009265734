var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "common-detail-wrapper"
  }, [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('편성 정보')))]), _c('input-select', {
    ref: "year",
    attrs: {
      "title": "연도",
      "inValidText": '연도를 선택해주세요',
      "placeholder": "연도를 선택해주세요",
      "isRequired": "",
      "isValid": !_vm.inValid || !!_vm.$refs['year'].value,
      "inputWidthSize": 400,
      "list": _vm.yearList
    }
  }, [_c('div', {
    staticClass: "subtext"
  }, [_vm._v(_vm._s(_vm.tt('내후년까지 예산 편성이 가능합니다.')))])])], 1), _c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('예산 정보')))]), _c('input-text', {
    ref: "name",
    attrs: {
      "title": "예산 이름",
      "placeholder": "예산 이름을 입력해주세요",
      "isRequired": "",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "inValidText": '예산 이름을 입력해주세요',
      "isValid": !_vm.inValid || !!_vm.$refs['name'].value
    }
  }), _c('input-text', {
    ref: "code",
    attrs: {
      "title": "예산 번호",
      "placeholder": "예산 번호를 입력해주세요",
      "inputWidthSize": 400,
      "inputHeightSize": 36
    }
  })], 1), _c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('예산')))]), _c('input-radio', {
    ref: "budgetType",
    attrs: {
      "isValid": !_vm.inValid || !!_vm.$refs['budgetType'].value,
      "title": "예산 구분",
      "list": _vm.typeList,
      "required": true
    }
  })], 1), _c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('참여부서')))]), _c('select-group', {
    ref: "groups",
    attrs: {
      "isRequired": "",
      "defaultValue": _vm.registrationInfo.groups,
      "inValidText": '참여 부서를 선택해주세요',
      "isValid": !_vm.inValid || _vm.$refs['groups'].value.length > 0
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }