<template>
	<b-modal ref="modal" centered no-enforce-focus no-close-on-backdrop no-fade no-close-on-esc>
		<h1>라이선스 추가하기</h1>
		<p>
			라이선스 수 제한으로 멤버 추가, 초대가 불가합니다.<br />
			라이선스를 구매하거나, 인원 무제한 체험을 선택해보세요.
		</p>
		<table class="table-license">
			<tr>
				<th>구분</th>
				<th>라이선스 구매</th>
				<th>인원 무제한 체험</th>
			</tr>
			<tr>
				<th>기능</th>
				<td>모든 기능</td>
				<td>모든 기능</td>
			</tr>
			<tr>
				<th>계정 수</th>
				<td>약정 계정 수</td>
				<td>제한없음</td>
			</tr>
			<tr>
				<th>기간</th>
				<td>약정 기간</td>
				<td class="red">1개월</td>
			</tr>
			<tr>
				<th>Merck 등 인기 브랜드<br />최대 20% 할인혜택</th>
				<td>O</td>
				<td class="red">X</td>
			</tr>
		</table>
		<div class="grey-box">
			멤버 추가 없이 1계정은 계속 무료사용이 가능합니다. <br />
			계속 무료로 사용하시려면 아래의 ‘닫기’를 클릭해주세요.
		</div>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<button class="button-primary" @click="purchaseLicense">
					{{ tt('라이선스 구매하기') }}
				</button>
				<button class="button-white" @click="freetier">
					{{ tt('1개월 무료 체험하기') }}
				</button>
				<button class="button-white" @click="hide">
					{{ tt('닫기') }}
				</button>
			</div>
		</template>
		<ModalConfirm
			ref="modal-freeTier"
			title="1개월 인원 무제한 체험하기"
			content="1개월 무료체험 후에는 라이선스를 구매해야 사용할 수 있습니다. <br />인원 무제한 체험을 시작 하시겠습니까?"
			confirmText="무료 체험하기"
			@confirm="freetierConfirm"
		/>
	</b-modal>
</template>
<script>
import { mapActions } from 'vuex'
import MixinLicense from '@/mixins/license'

export default {
	mixins: [MixinLicense],
	methods: {
		...mapActions('license', ['requestFreetier', '']),
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async freetierConfirm() {
			try {
				const response = await this.requestFreetier()
				await this.$root.refreshLicense()
				this.$root.toast(
					'인원 무제한 체험 시작',
					`${this.formatDatetime(this.license.expireDate)}까지 인원을 무제한으로 추가할 수 있습니다. 멤버를 추가해보세요.`,
					'success'
				)
				this.hide()
			} catch (e) {
				this.$root.toast('무료 신청 실패', e.response.data.msg, 'error')
			}
		},
		freetier() {
			if (this.isLicenseDepositWait) {
				this.$root.toast('라이선스 구매', '입금 대기 중인 구매 건이 있습니다.', 'error')
			} else {
				this.$refs['modal-freeTier'].show()
			}
		},
		purchaseLicense() {
			if (this.isLicenseDepositWait) {
				this.$root.toast('라이선스 구매', '입금 대기 중인 구매 건이 있습니다.', 'error')
			} else {
				this.$emit('purchaseLicense')
			}
			this.hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
::v-deep {
	.modal-dialog {
		max-width: 580px;
		width: 580px;
	}
	.modal-content {
		border-radius: 20px;
	}
	.modal-body {
		overflow: hidden;
		align-items: center;
		padding: 0px 50px;
		padding-bottom: 20px;
		h1 {
			font-size: 32px;
		}
		p {
			font-size: 16px;
			color: $DEFAULT;
			text-align: center;
		}
		.grey-box {
			text-align: center;
			width: 100%;
			background-color: #f5f5f5;
			border-radius: 5px;
			padding: 10px;
			font-size: 14px;
			line-height: 130%;
		}
		.table-license {
			width: 100%;
			text-align: center;
			font-size: 14px;
			th {
				background-color: #f5f5f5;
				font-weight: 500;
				min-height: 30px;
				padding: 5px;
				height: 30px;
				border: 1px solid $INPUT;
				&:first-child {
					width: 160px;
					border-left: none;
				}
				&:last-child {
					border-right: none;
				}
			}
			td {
				display: table-cell !important;
				border: 1px solid $INPUT;
				padding: 10px;
				font-size: 16px;
				&:last-child {
					border-right: none;
				}
			}
		}
	}
	.modal-footer-wrap {
		flex-direction: column;
		gap: 10px;
		padding-bottom: 30px;
		button {
			max-width: 260px;
			width: 260px;
			font-size: 16px !important;
			height: 48px !important;
		}
	}
	.image {
		width: 100%;
	}
}
</style>
