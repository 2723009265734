<template>
	<div class="member-wrapper" v-if="member" :class="{ red: isSelect }">
		<div class="data-wrapper member">
			<div class="data-image-wrapper">
				<img class="member-image" v-if="member.image" :src="member.image" />
				<img class="member-image" v-else src="@/assets/svg/migration/member-none.svg" />
			</div>
			<span class="member-name">{{ member.name }}</span>
		</div>
		<div class="data-wrapper email">
			<span class="member-email">{{ member.email }}</span>
		</div>
		<div class="data-wrapper status">
			<span class="member-status-text" :class="{ red: isSelect }">{{ statusText }}</span>

			<img v-if="isSelect" class="info-image" src="@/assets/svg/migration/icon-migration-member-red.svg" />
			<img v-else class="info-image" src="@/assets/svg/migration/icon-migration-member-green.svg" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		member: {
			type: Object,
			default: null,
		},
	},
	computed: {
		isSelect() {
			return ['SELECT'].includes(this.member.status)
		},
		statusText() {
			return this.isSelect ? this.tt('자동 이전 제외') : this.tt('자동 이전')
		},
	},
}
</script>

<style lang="scss" scoped>
.member-wrapper {
	padding: 0px 20px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $DIVIDER;
	&.red {
		background: linear-gradient(0deg, rgba(255, 87, 70, 0.05), rgba(255, 87, 70, 0.05)), linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
	}
	.data-wrapper {
		display: flex;
		text-align: left;
		align-items: center;
		height: 60px;
		padding: 0px 5px;
		.data-image-wrapper {
			width: 40px;
			height: 40px;
			border-radius: 8px;
			margin-right: 10px;
			.member-image {
				width: 40px;
				height: 40px;
				border-radius: 8px;
			}
		}
		.member-name {
			font-size: 16px;
			font-weight: 700;
			line-height: 23px;
			letter-spacing: 0px;
			color: #000000de;
			height: 23px;

			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.member-email {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: 0px;
			color: #000000de;
			height: 23px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding-left: 5px;
		}

		.member-status-text {
			font-family: Pretendard;
			font-size: 12px;
			font-weight: 700;
			line-height: 17px;
			letter-spacing: 0px;
			text-align: right;
			color: $GREEN;
			&.red {
				color: $RED;
			}
		}

		&.member {
			width: 140px;
		}

		&.email {
			width: 286px;
		}

		&.status {
			width: 133px;
			padding-right: 0px;
			justify-content: flex-end;
		}
	}
}
</style>
