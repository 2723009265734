var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v(" " + _vm._s(_vm.tt('선택')) + " ")]), _c('th', [_vm._v(" " + _vm._s(_vm.tt('견적상태')) + " ")]), _c('th', {
    staticStyle: {
      "width": "150px"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('견적번호')) + " ")]), _c('th', {
    staticStyle: {
      "width": "400px"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('상품정보')) + " ")]), _c('th', [_vm._v(" " + _vm._s(_vm.tt('작성자')) + " ")]), _c('th', [_vm._v(" " + _vm._s(_vm.tt('견적생성일')) + " ")]), _c('th', [_vm._v(" " + _vm._s(_vm.tt('최종편집일')) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }