var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " "), _c('img', {
          staticClass: "question",
          attrs: {
            "width": "24",
            "height": "24",
            "src": require("@/views/purchase/cargo/svg/icon-round-question.svg")
          },
          on: {
            "click": function click($event) {
              return _vm.$refs['modal-describe'].show();
            }
          }
        })])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('button', {
          staticClass: "button-primary",
          attrs: {
            "disabled": !_vm.data
          },
          on: {
            "click": function click($event) {
              return _vm.exportPdf(_vm.item.name);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('PDF로 출력')) + " ")])])];
      },
      proxy: true
    }])
  }, [[_vm.previewData ? _c('div', {
    staticClass: "body"
  }, [_c('table', [_c('table-header'), _c('tbody', [_c('tr', [_vm.data ? _c('td', {
    staticClass: "type"
  }, [_vm.ghs ? _c('div', {
    staticClass: "content"
  }, [_c('p', {
    staticClass: "sticker-type"
  }, [_vm._v(_vm._s(_vm.data.type))]), _c('p', {
    staticClass: "size"
  }, [_vm._v(_vm._s(_vm.ghs.paperSpec))])]) : _vm.data.qrData ? _c('div', {
    staticClass: "content"
  }, [_c('p', {
    staticClass: "sticker-type"
  }, [_vm._v(_vm._s(_vm.data.type))]), _c('p', {
    staticClass: "size"
  }, [_vm._v(_vm._s(_vm.data.paperSpec))])]) : _vm._e()]) : _vm._e(), _c('td', {
    staticClass: "preview"
  }, [_c('div', {
    staticClass: "preview-content",
    on: {
      "click": function click($event) {
        return _vm.$refs['viewer'].show();
      }
    }
  }, [!_vm.isTypeQr ? _c('div', {
    ref: "previewImage",
    staticClass: "preview-image",
    domProps: {
      "innerHTML": _vm._s(_vm.itemImage)
    }
  }) : _c('div', {
    staticClass: "qr-wrap"
  }, [_c('img', {
    attrs: {
      "width": "70",
      "height": "70",
      "src": _vm.itemImage
    }
  })]), _c('p', {
    staticClass: "code"
  }, [_vm._v(_vm._s(_vm.identifierCode))])])])])]), _vm.data ? _c('modal-multi-image-viewer', {
    ref: "viewer",
    attrs: {
      "title": "미리보기",
      "imageList": _vm.data.image,
      "isRawSvg": !_vm.isTypeQr,
      "isCaption": _vm.isTypeQr,
      "captionList": _vm.identifierList,
      "isSingle": true
    }
  }) : _vm._e()], 1)]) : _vm._e(), _c('a', {
    attrs: {
      "href": "https://www.law.go.kr/LSW/admRulLsInfoP.do?admRulSeq=2100000049106",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "btm-describe",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('GHS+QR 스티커는 <b class=\'underline\'>「위험물안전관리법」시행규칙 별표 19</b>에 의하여 지정된 법령규격으로 출력됩니다.'))
    }
  })])], _c('modal-describe-sticker', {
    ref: "modal-describe"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }