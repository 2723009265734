var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "OrganizationTree__page-container"
  }, [_c('inst-header', {
    attrs: {
      "title": "institution_management.organization_chart"
    }
  }), _c('div', {
    staticClass: "OrganizationTree__container"
  }, [_c('div', {
    staticClass: "OrganizationTree__chart-container"
  }, [_c('div', [_vm._v(_vm._s(_vm.activeId))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.keyword,
      expression: "keyword"
    }],
    attrs: {
      "type": "text",
      "name": ""
    },
    domProps: {
      "value": _vm.keyword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.keyword = $event.target.value;
      }
    }
  }), _c('TreeView', {
    attrs: {
      "treeData": _vm.groupList,
      "textField": "name",
      "subTextField": "membersCount",
      "subTextEndWord": "명",
      "keyword": _vm.keyword
    },
    on: {
      "changeActive": _vm.changeActiveId
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }