import vvuex from '@/lib/vvuex'
import settingAPI from '@/services/api/inventory/setting'

const settingCategory = vvuex({
	namespaced: true,
	state: {
		tab: 1,
		categoryList: [],
		category: {},
		count: 0,
		mngcode: {},
	},
	getters: {
		getTab: state => state.tab,
		getCategoryList: state => state.categoryList,
		getCategory: state => state.category,
		getCount: state => state.count,
		getMngcode: state => state.mngcode,
	},
	mutations: {
		setTab: (state, payload) => {
			state.tab = payload
		},
		setCategoryList: (state, payload) => {
			state.categoryList = payload
		},
		setCategory: (state, payload) => {
			state.category = payload
		},
		setCount: (state, payload) => {
			state.count = payload
		},
		setMngcode: (state, payload) => {
			state.mngcode = payload
		},
		setActiveClassList: (state, payload) => {
			state.mngcode.activeClassList = payload
		},
	},
	actions: {
		lookupCategoryList: async ({ commit, rootGetters }, instId) => {
			const response = await settingAPI.lookupCategoryList(rootGetters.instituteId)
			commit('setCategoryList', response.data)
		},
		lookupCategory: async ({ commit }, payload) => {
			const { instId, id } = payload
			const response = await settingAPI.lookupCategory(instId, id)
			commit('setCategory', response.data)
		},
		addCategory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await settingAPI.addCategory(instId, body)
		},
		editCategory: async ({ commit }, payload) => {
			const { instId, body } = payload
			await settingAPI.editCategory(instId, body)
		},
		deleteCategory: async ({ commit }, payload) => {
			const { instId, id } = payload
			await settingAPI.deleteCategory(instId, id)
		},
		countCategory: async ({ commit }, payload) => {
			const { instId, id } = payload
			const response = await settingAPI.countCategory(instId, id)
			commit('setCount', response.data.count)
		},
		lookupMngcode: async ({ commit }, instId) => {
			const response = await settingAPI.lookupMngcode(instId)
			commit('setMngcode', response.data)
		},
		editMngcode: async ({ commit, dispatch }, payload) => {
			const { instId, body } = payload
			await settingAPI.editMngcode(instId, body)
			dispatch('lookupMngcode', instId)
		},
	},
})

export default settingCategory
