import axios from 'axios'
let CancelToken = null
let source = null

const endPoint = {
	getMsdsParseResult: () => 'https://msds-parser.lab-manager.com/parse',
}

const msdsParserAPI = {
	getMsdsParseResult: form => {
		CancelToken = axios.CancelToken
		source = CancelToken.source()
		return axios.post(endPoint.getMsdsParseResult(), form, {
			headers: { 'Content-Type': 'multipart/form-data' },
			cancelToken: source.token,
		})
	},
	cancelMsdsParse: () => {
		source?.cancel()
	},
}

export default msdsParserAPI
