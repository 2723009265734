var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }), _c('div', {
          staticClass: "footer-right"
        }, [_vm.noCancel ? _c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.cancelText)) + " ")]) : _vm._e(), _vm.hasConfirm ? _c('button', {
          "class": "button-".concat(_vm.variant),
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.confirmText)) + " ")]) : _vm._e()])])];
      },
      proxy: true
    }])
  }, [[_vm.noContent ? _c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.content))
    }
  }) : _vm._e(), _vm._t("default")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }