<template>
	<div
		id="ghsContent"
		style="width: fit-content; display: flex; flex-direction: column; padding: 3mm; background: white; border-radius: 10px"
		v-if="previewData"
	>
		<div :id="`ghsWrap${idx}`" v-for="(item, idx) in labelCount" :style="ghsWrapStyle" :key="item">
			<div style="display: flex; flex-direction: column; height: 100%; justify-content: space-around">
				<div :style="fieldStyle">
					<h1 :style="nameStyle">
						{{ previewData.name }}
					</h1>
					<div style="margin-top: 5px; font-size: 10px; transform: translate(-10%, 50%) scale(0.8); display: inline-block">
						<span>CAS: {{ previewData.casno }}</span> | <span v-if="signalText">{{ tt('신호어') }} : {{ tt(signalText) }}</span> |
						<span v-if="previewData.productCode">{{ tt('제품번호') }} : {{ previewData.productCode }}</span> |
						<span v-if="previewData.brand && previewData.brand.name">{{ tt('브랜드') }} : {{ previewData.brand.name }}</span>
					</div>
				</div>
				<div :style="fieldStyle" v-if="ghsPictogram">
					<img v-for="img in ghsPictogram" :key="img" :style="pictogramStyle" :src="require(`@/assets/ghs/${img}.png`)" />
				</div>
				<div class="text-container" :style="textContainerStyle">
					<h2 class="subtitle" :style="styleSubtitle">
						{{ tt('유해&middot;위험 문구') }}
					</h2>
					<div class="text" v-if="hCodeText" :style="textStyle">
						{{ hCodeText }}
					</div>
				</div>
				<div class="text-container" :style="textContainerStyle">
					<h2 class="subtitle" :style="styleSubtitle">{{ tt('예방조치문구') }}</h2>
					<div class="text" v-if="pCodeText" :style="textStyle">
						{{ ellipsisPCode }}
					</div>
					<div
						class="text red"
						style="
							font-size: 7px;
							font-weight: 400;
							overflow-wrap: break-word;
							color: #ff3b31;
							transform: translate(-10%, 0%) scale(0.8);
							display: inline-block;
						"
					>
						*{{ tt('자세한 예방조치는 MSDS를 참고해주세요.') }}
					</div>
				</div>
				<div
					class="text-container provider"
					style="border-bottom: none; display: flex; justify-content: space-between; padding: 0; height: 100%"
				>
					<div class="left-container" style="width: 70%; padding: 5px">
						<h2 class="subtitle" :style="styleSubtitle">{{ tt('공급자 정보') }}</h2>
						<div class="text" v-if="provider" :style="textStyle">{{ provider.name }} | {{ provider.phone }} | {{ provider.address }}</div>
						<div class="text" v-if="etc" :style="textStyle">
							{{ etc }}
						</div>
					</div>
					<div class="qr-container" :style="qrContainerStyle">
						<div v-if="previewData.qrSticker">
							<qr-code style="margin: auto; width: 30px; margin-top: 5px" :text="previewData.qrSticker" :size="qrSize"></qr-code>
							<p class="qr-code" :style="qrCodeStyle">
								{{ previewData.qrSticker.slice(0, 12) }}
							</p>
						</div>
						<div v-else-if="qrStickerData">
							<img :style="qrImgStyle" :src="qrStickerData.image" />
							<p class="qr-code" :style="qrCodeStyle">
								{{ qrStickerData.identifier.slice(0, 12) }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import StringUtils from '@/utils/modules/StringUtils'
import { mapGetters } from 'vuex'
export default {
	name: 'GHSLabel',
	components: {},
	props: {},
	data() {
		return {
			qrSize: 30,
		}
	},
	computed: {
		...mapGetters('modal/ghs', ['previewData', 'pCodeText', 'hCodeText', 'provider', 'signalText', 'ghsPictogram', 'etc']),
		...mapGetters('modal/printSticker', ['qrStickerData']),
		labelCount() {
			return this.previewData.multiCreateCount ?? 1
		},
		ellipsisPCode() {
			return StringUtils.lengthEllipsis(this.pCodeText, 40)
		},
		styleSubtitle() {
			return { fontSize: '8px', fontWeight: 700, margin: 0, overflowWrap: 'break-word' }
		},
		ghsWrapStyle() {
			return {
				width: '96mm',
				height: '67mm',
				border: '1px solid black',
				borderRadius: '8px',
				backgroundColor: '#fff',
				margin: '10px',
				pageBreakAfter: 'auto',
				breakInside: 'avoid',
				cssFloat: 'left',
				display: 'block',
				fontSize: '10px',
			}
		},
		nameStyle() {
			return {
				marginTop: '0',
				marginBottom: '0',
				fontSize: '13px',
				fontWeight: 700,
				lineHeight: 0.9,
			}
		},
		pictogramStyle() {
			return {
				width: '35px',
				height: '35px',
			}
		},
		textContainerStyle() {
			return {
				borderBottom: '1px solid black',
				padding: '5px',
				overflow: 'hidden',
				position: 'relative',
				height: '100%',
			}
		},
		textStyle() {
			return {
				fontSize: '8px',
				fontWeight: 400,
				overflowWrap: 'break-word',
				transform: 'translate(-10%, 0%) scale(0.8)',
			}
		},
		qrContainerStyle() {
			return {
				textAlign: 'center',
				width: '15%',
				borderLeft: '1px solid black',
				height: '100%',
				minHeight: '30px',
				padding: '0px 5px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}
		},
		qrBorderStyle() {
			return {
				display: 'flex',
				border: '1px solid #111',
				padding: '3px',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: '3px',
			}
		},
		qrCodeStyle() {
			return {
				textAlign: 'center',
				fontSize: '10px',
				fontWeight: 400,
				transform: 'translate(0%, 0%) scale(0.7)',
				display: 'table-cell',
			}
		},
		fieldStyle() {
			return {
				borderBottom: '1px solid black',
				padding: '8px',
				overflow: 'hidden',
				height: '100%',
			}
		},
		qrImgStyle() {
			return {
				width: '40%',
				height: '60%',
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped></style>
