<template>
	<div class="badge-wrapper" :class="{ warning: overDueNoPaidPriceWithVat > 0 }" v-b-tooltip.html="tootlipText" @click="gotoStorePayment">
		<div class="title">
			{{ tt('후불결제 잔여 한도') }}
		</div>
		<img v-if="overDueNoPaidPriceWithVat > 0" src="@/assets/svg/input-icon-warning.svg" />
		<div class="credit">{{ limitCredit.toLocaleString() }}원 ▸</div>
	</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
	computed: {
		...mapState('lms', ['lmsInstitute']),
		overDueNoPaidPriceWithVat() {
			return this.lmsInstitute?.overDueNoPaidPriceWithVat ?? 0
		},
		limitCredit() {
			return (this.lmsInstitute?.limitCredit ?? 0) - (this.lmsInstitute?.noPaidPriceWithVat ?? 0)
		},
		tootlipText() {
			if (this.overDueNoPaidPriceWithVat > 0)
				return '결제 기간이 초과된 주문이 있습니다. <br />해당 주문이 결제가 완료되어야 추가 주문이 가능합니다.'
			else return null
		},
	},
	methods: {
		gotoStorePayment() {
			this.$root.goToStore('mypage/unpayment')
		},
	},
}
</script>
<style lang="scss" sceopd>
.badge-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	padding: 4px 10px;
	background-color: $SUB-YELLOW_200;
	border-radius: 4px;
	font-size: 14px;
	gap: 5px;
	cursor: pointer;
	.title {
		font-weight: 500;
		color: $SECONDARY_2;
	}
	.credit {
		font-weight: 700;
		color: $FONT_YELLOW2;
	}
	&.warning {
		background-color: $SUB-RED;
		.credit {
			color: $RED;
		}
	}
}
</style>
