<template>
	<ul class="recent-reason-wrap" v-show="hasComment">
		<div class="reason-header">
			<p class="title">{{ tt('최근 입력한 사유') }}</p>
		</div>
		<div>
			<li class="reason-wrap" v-for="(comment, idx) in commentList" :key="idx" @click.stop="$emit('clickComment', comment.comment)">
				<span v-html="replaceAllText(comment.comment, '<br>')" v-b-tooltip.hover.html="replaceAllText(comment.comment, '<br>')"></span>
				<img src="@/assets/svg/Close-1.svg" width="24" height="24" @click.stop="deleteComment(comment.id)" />
			</li>
		</div>
	</ul>
</template>
<script>
export default {
	name: 'InputUseRecentTexts',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		commentList() {
			return this.list
		},
		hasComment() {
			return this.commentList?.length !== 0 && this.show
		},
	},
	methods: {
		deleteComment(id) {
			this.$emit('deleteComment', id)
		},
		replaceAllText(val, params = ' ') {
			return val ? val.replaceAll('\\n', params) : null
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .tooltip-inner {
	text-align: left;
}
.recent-reason-wrap {
	padding-top: 0 !important;
	.reason-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		top: 0;
		z-index: 2;
		padding: 10px 5px;
		background: $LAB_WHITE;
		.title {
			font-size: 0.75rem;
			color: $SECONDARY_2;
		}
		.delete-btn {
			font-size: 0.75rem;
			font-weight: bold;
			color: $BLUE;
			cursor: pointer;
		}
	}
	.reason-wrap {
		span {
			width: 100%;
			display: -webkit-box;
			max-height: 40px;
			overflow: hidden;
			vertical-align: top;
			text-overflow: ellipsis;
			word-break: break-all;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}
}
</style>
