var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('배송지 변경')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }, [_c('base-button', {
          attrs: {
            "color": 'white'
          },
          on: {
            "click": _vm.add
          }
        }, [_vm._v(_vm._s(_vm.tt('배송지 등록')))])], 1), _c('div', {
          staticClass: "footer-right"
        }, [_c('base-button', {
          attrs: {
            "color": 'white'
          },
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(_vm._s(_vm.tt('취소')))]), _c('base-button', {
          attrs: {
            "color": "lab-black"
          },
          on: {
            "click": _vm.change
          }
        }, [_vm._v(_vm._s(_vm.tt('변경')))])], 1)])];
      },
      proxy: true
    }])
  }, [[_c('p', [_vm._v(_vm._s(_vm.tt('배송지 선택')))]), _vm.selectList.length > 0 ? _c('div', {
    staticClass: "select-wrapper"
  }, [_c('b-dropdown', {
    ref: "dropdown",
    attrs: {
      "no-caret": "",
      "toggle-class": "option-wrapper",
      "variant": "link",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm.selectedValue ? _c('p', {
          ref: "name",
          staticClass: "name"
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.selectedValue.name)) + " ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 970412147)
  }, _vm._l(_vm.selectList, function (item, index) {
    return _c('b-dropdown-item', {
      key: "item-".concat(index),
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.changeOption(index);
        }
      }
    }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt(item.name)) + " ")]), _c('div', {
      staticClass: "edit-wrapper"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/button-icon-edit.svg")
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$refs['modal-add-address'].show(item);
        }
      }
    }), _c('img', {
      attrs: {
        "src": require("@/assets/svg/Trash.svg")
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.removeAddress(item);
        }
      }
    })])]);
  }), 1)], 1) : _vm._e(), _vm.selectedValue ? _c('div', {
    staticClass: "select-delivery-info"
  }, [_vm.selectedValue.isDefault ? _c('div', {
    staticClass: "default"
  }, [_vm._v(_vm._s(_vm.tt('기본 배송지')))]) : _vm._e(), _c('h6', [_vm._v(_vm._s(_vm.selectedValue.name))]), _c('p', {
    staticClass: "address"
  }, [_vm._v("[" + _vm._s(_vm.selectedValue.postcode) + "] " + _vm._s(_vm.selectedValue.address))]), _c('p', {
    staticClass: "sub"
  }, [_vm._v(" " + _vm._s(_vm.selectedValue.instituteName ? _vm.selectedValue.instituteName : '') + " " + _vm._s(_vm.selectedValue.groupName ? ' / ' + _vm.selectedValue.groupName : '') + " ")])]) : _vm._e()], _c('modal-add-address', {
    ref: "modal-add-address"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }