var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.id == 1 ? _c('card-possession-status', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "list": _vm.possessionTrend,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e(), _vm.id == 2 && _vm.userPermission.safe && _vm.userPermission.safe.diagnosis.read && !_vm.isBasic ? _c('card-health-checkup', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "list": _vm.diagnosisList,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e(), _vm.id == 3 ? _c('card-registering-item', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "trend": _vm.createTrend,
      "summary": _vm.createSummary,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e(), _vm.id == 4 ? _c('card-disposal-status', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "trend": _vm.disposalTrend,
      "summary": _vm.disposalSummary,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e(), _vm.id == 5 ? _c('card-msds-upload', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "list": _vm.msdsUpload,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e(), _vm.id == 6 && _vm.userPermission.safe && _vm.userPermission.safe.dangerstorage.read && !_vm.isBasic ? _c('card-storage-quantity', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "list": _vm.dangerStorage,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e(), _vm.id == 7 ? _c('card-chemical-substances-status', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "trend": _vm.useTrend,
      "list": _vm.useSummary,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e(), _vm.id == 8 && _vm.userPermission.safe && _vm.userPermission.safe.legalitems.read && !_vm.isBasic ? _c('card-law-material', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "list": _vm.legalList.list,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e(), _vm.id == 9 ? _c('card-disposal-item', {
    attrs: {
      "id": _vm.id,
      "instId": _vm.instId,
      "visible": _vm.visible,
      "setting": _vm.setting,
      "list": _vm.disposalItem,
      "toggleCard": _vm.toggleCard
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }