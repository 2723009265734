<template>
	<tr class="table-item" :class="{ new: isNew }" @click="goToDetail">
		<td @click.stop>
			<!-- 체크박스 -->
			<span class="checkbox"><input type="checkbox" v-model="checked" /> </span>
		</td>
		<td v-if="isView('mngCode')">
			<div>
				<span>{{ item.mngcode == null && item.isPostprocessing ? '생성중' : item.mngcode ?? '-' }}</span>
			</div>
		</td>
		<td v-if="isView('category')">
			<div>
				<span>{{ categoryName }}</span>
			</div>
		</td>
		<td v-if="isView('status')">
			<div>
				<span>{{ type }}</span>
			</div>
		</td>
		<td v-if="isView('name')">
			<div style="min-width: 250px; max-width: 400px">
				<h6 style="font-weight: 700">{{ isEmpty(item.name) }}</h6>
				<div class="subtext" v-if="item">
					<span v-if="item.brand && item.brand.name">{{ item.brand.name }}</span>
					<span v-if="item.subname">{{ item.subname }}</span>
				</div>
			</div>
		</td>
		<td v-if="isView('dumpMemo')" style="height: 80px">
			<div v-b-tooltip.hover.html="replaceAllText(dumpMemo, '<br />')" class="content-wrap" style="min-width: 250px; max-width: 370px">
				<span v-html="replaceAllText(dumpMemo, '<br />')"></span>
			</div>
		</td>
		<td v-if="isView('assignee')">
			<div class="profile-wrap" style="min-width: 100px">
				<img class="profile-img" :src="assigneeImage" />
				<p v-if="item.assignee && item.assignee.name">{{ tt(item.assignee.name) }}</p>
			</div>
		</td>
		<td v-if="isView('dumper')">
			<div class="profile-wrap" style="min-width: 100px">
				<img class="profile-img" :src="dumperImage" />
				<p>{{ dumperName }}</p>
			</div>
		</td>
		<td v-if="isView('dumpDatetime')">
			<div>
				<span>{{ dumpDatetime }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import PageName from '@/utils/define/PageName'
import { mapState, mapMutations, mapActions } from 'vuex'
import MixinItem from '@/mixins/inventory/MixinItem'
export default {
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('inventoryGen2/disposal', ['selectedItems', 'checkedViewOption']),
		type() {
			return this.item.isDel ? this.tt('삭제') : this.item.isDispose ? this.tt('폐기') : ''
		},
		assigneeImage() {
			return this.item.assignee && this.item.assignee.image ? this.item.assignee.image : require('@/assets/svg/member-photo-default.svg')
		},
		dumpMemo() {
			return this.item.disposeMemo ? this.item.disposeMemo : this.item.deleteMemo ? this.item.deleteMemo : '-'
		},
		dumperName() {
			return this.item.disposer && this.item.disposer.name
				? this.item.disposer.name
				: this.item.deleter && this.item.deleter.name
				? this.item.deleter.name
				: '-'
		},
		dumperImage() {
			return this.item.disposer && this.item.disposer.image
				? this.item.disposer.image
				: this.item.deleter && this.item.deleter.image
				? this.item.deleter.image
				: require('@/assets/svg/member-photo-default.svg')
		},
		dumpDatetime() {
			return this.item.deleteDatetime
				? this.$DateTime.fromISO(this.item.deleteDatetime).toFormat('yyyy.MM.dd')
				: this.item.disposeDatetime
				? this.$DateTime.fromISO(this.item.disposeDatetime).toFormat('yyyy.MM.dd')
				: ''
		},
		categoryName() {
			if (!this.item.category?.name) return ''
			return this.item.category.name
		},
		checked: {
			get() {
				return this.selectedItems.some(item => item.id === this.item.id)
			},
			set(value) {
				if (value) {
					this.setSelectedItems(this.selectedItems.concat(this.item))
				} else {
					this.setSelectedItems(this.selectedItems.filter(item => item.id !== this.item.id))
				}
			},
		},
	},
	methods: {
		...mapMutations('inventoryGen2/disposal', ['setSelectedItems']),
		...mapActions('inventoryGen2/disposal', ['removeItem', 'checkRestore', 'restoreItem']),
		goToDetail() {
			this.setNew()
			this.$router.push({
				name: 'DisposalDetailGen2',
				params: {
					id: this.item.id,
				},
			})
		},
		replaceAllText(val, params = ' ') {
			return val ? val.replaceAll('\\n', params) : null
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
::v-deep .tooltip-inner {
	text-align: left;
}
td {
	button {
		width: 36px;
		height: 36px;
		background: $GREY_4;
		border-radius: 4px;
	}
	.content-wrap {
		span {
			display: -webkit-box;
			max-height: 40px;
			overflow: hidden;
			vertical-align: top;
			text-overflow: ellipsis;
			word-break: break-all;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}
}
.status {
	font-weight: 700;
	color: $RED;
	&.AVAILABLE {
		color: $GREEN;
	}
	&.HOLDING_OWN {
		color: $BLUE;
	}
}
.subtext {
	display: flex;
	gap: 10px;
	span {
		font-size: 12px;
		color: $SECONDARY_2;
		&:nth-child(2) {
			padding-left: 10px;
			border-left: 1px solid $INPUT;
		}
	}
}
.content-wrap {
	display: flex;
	flex-direction: column;
	div {
		display: flex;
		gap: 5px;
	}
}
::v-deep .assignee-change {
	display: flex;
	gap: 5px;
}
::v-deep .assignee-img {
	border-radius: 24px;
	width: 24px;
	height: 24px;
	border: 1px solid $INPUT;
}
::v-deep .memo-wrap {
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>
