<template>
	<div class="CompleteInvite">
		<receive-invite-header></receive-invite-header>
		<div class="wrap">
			<div class="imgbox">
				<img :src="logo" />
			</div>
			<h1 v-html="`${name} ${tt('연구실에<br>가입이 완료되었습니다')}`"></h1>
			<p
				v-html="
					tt(
						'<b>라이선스가 만료</b>되어 랩매니저 PRO를 정상적으로 이용할 수 없습니다.<br>관리자가 <b>라이선스 구매 후, 이용 가능</b>합니다.'
					)
				"
			></p>
		</div>
		<div class="bottom">
			<div class="phone">
				<img src="@/assets/svg/phone.svg" />
				<p>{{ phone }}</p>
			</div>
			<div class="email">
				<img src="@/assets/svg/email.svg" />
				<p>{{ email }}</p>
			</div>
		</div>

		<modal-email-authentication ref="modal-email-authentication" @authentication="onClickAuthentication"></modal-email-authentication>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import ReceiveInviteHeader from '@/views/invite/receive/components/ReceiveInviteHeader.vue'
import ModalEmailAuthentication from '@/views/app/components/ModalEmailAuthentication.vue'
import { goToSSOEmail } from '@/services/api/common'

export default {
	components: {
		ReceiveInviteHeader,
		ModalEmailAuthentication,
	},
	data() {
		return {
			name: '',
			logo: '',
			phone: '',
			email: '',
		}
	},
	computed: {
		...mapState('user', ['user']),
	},
	created() {
		this.init()
	},
	mounted() {
		this.user.isCertificated === false ? this.$refs['modal-email-authentication'].show() : this.$refs['modal-email-authentication'].hide()
	},
	methods: {
		init() {
			this.name = this.$route.params.name
			this.logo = this.$route.params.logo
			this.phone = '070-4189-9493'
			this.email = 'lmcs@smartjackwp.com'
		},
		onClickAuthentication() {
			this.$refs['modal-email-authentication'].hide()
			goToSSOEmail()
		},
	},
}
</script>

<style lang="scss" scoped>
.CompleteInvite {
	width: 100vw;
	height: 100vh;
	z-index: 100;
	top: 0;
	left: 0;
	background-color: $LAB_WHITE;
	position: fixed;
	.wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		.imgbox {
			width: 72px;
			height: 72px;
			border: 1px solid $DIVIDER;
			border-radius: 16px;
			padding: 6px;
			margin: 0 auto;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				border-radius: 16px;
			}
		}
		h1 {
			margin-top: 10px;
			font-size: 3rem;
			font-weight: 700;
		}
		p {
			font-size: 1.125rem;
			font-weight: 400;
			color: $RED;
			margin-top: 20px;
		}
	}
	.bottom {
		width: 100%;
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 5px;
			img {
				margin-right: 5px;
			}
			p {
				font-size: 0.875rem;
				color: #000;
				font-weight: 400;
			}
		}
	}
}
</style>
