var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "ModalCompleteRegisteringMember"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt('멤버 등록 완료')))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('ul', [_c('li', [_c('span', [_vm._v(_vm._s(_vm.tt('이메일(ID)')))]), _c('p', [_vm._v(_vm._s(_vm.info.email))])]), _c('li', [_c('span', [_vm._v(_vm._s(_vm.tt('비밀번호')))]), _c('p', [_vm._v(_vm._s(_vm.info.password))])])]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('멤버 등록이 완료되었습니다. 위의 이메일로 등록된 계정정보를 발송했습니다. ')) + " ")])]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('확인')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.registering
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('계속 등록')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }