<template>
	<b-modal ref="msds-file-viewer" centered hide-footer no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">MSDS</div>
		</template>
		<template>
			<div class="content">
				<iframe class="pdf-viewer" v-if="pdfUrl != null" :src="pdfUrl" frameborder="0"></iframe>
			</div>
		</template>
	</b-modal>
</template>

<script>
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
export default {
	name: 'MSDSFileViewer',
	props: {
		pdfUrl: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	components: {
		ModalCommon,
	},
	methods: {
		show() {
			this.$refs['msds-file-viewer'].show()
		},
		hide() {
			this.$refs['msds-file-viewer'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/viewer/_msds-file-viewer.scss';
</style>
