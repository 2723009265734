var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "expenditure-list-wrapper"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.tt('지출내역 목록')) + " ")]), _c('div', {
    staticClass: "list-wrapper"
  }, [_c('expenditure-table', {
    attrs: {
      "list": _vm.list
    }
  })], 1), _c('pagination', {
    attrs: {
      "notSticky": "",
      "total": _vm.count
    },
    on: {
      "page": _vm.setLoadParams
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }