<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt(title) }}</div>
			<div class="form-msg" :class="{ error: !isValid }">
				{{ isValid ? tt('입력 완료') : tt('이름을 입력해주세요') }}
			</div>
		</div>
		<div class="form-data-wrap">
			<form-text-single-line
				:name="'카테고리 이름'"
				:value="categoryInfoName"
				:placeholder="'카테고리 이름을 입력해 주세요'"
				:errorMessage="'이름 입력해주세요!!!'"
				:required="true"
				@input="value => inputFormValue(value)"
			></form-text-single-line>
		</div>
	</div>
</template>

<script>
import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
export default {
	name: 'FormCategoryName',
	components: { FormTextSingleLine },
	props: {
		title: {
			type: String,
			default: '',
		},
		isValid: {
			type: Boolean,
			default: false,
		},
		categoryInfoName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		inputFormValue(value) {
			this.$emit('change', value)
		},
		// showRequire() {
		// 	this.$refs['form-seller'].forEach(ref => {
		// 		ref.showRequire()
		// 	})
		// },
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
.form-input {
	width: 100%;
	max-width: 100%;
}
</style>
