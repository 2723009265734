<template>
	<div class="InstManagement__header-container" :class="{ subtitle: hasSubTitle }">
		<div class="InstManagement__header-container-left">
			<div class="InstManagement__header-title">
				{{ tt(title) }}
			</div>
			<div class="InstManagement__header-subtitle" v-if="hasSubTitle">
				{{ subTitle }}
			</div>
		</div>
		<div class="InstManagement__header-container-right">
			<button class="AppContainer__subtitle-btn btn ml-auto" v-if="hasEditButton" @click="editButtonFn">
				<img alt="icon" src="@/assets/svg/button-icon-edit.svg" />{{ tt('편집') }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InstHeader',
	props: {
		title: {
			type: String,
		},
		subTitle: {
			type: String,
		},
		hasSubTitle: {
			type: Boolean,
			default: false,
		},
		hasEditButton: {
			type: Boolean,
			default: false,
		},
		editButtonFn: {
			type: Function,
		},
	},
}
</script>

<style lang="scss" scoped>
.InstManagement__header-container {
	display: flex;
	width: 100%;
	min-width: 600px;
	padding: 10px 30px;
	border-bottom: solid 1px #e4e5ea;
	justify-content: space-between;
	align-items: center;
	min-height: 55px;
}

.InstManagement__header-container-left {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
}

.InstManagement__header-title {
	text-align: left;
	font-weight: bold;
	font-size: 24px;
}

.InstManagement__header-subtitle {
	text-align: left;
	font-size: 12px;
	line-height: 17px;
	color: rgba(102, 102, 102, 1);
	font-weight: 400;
}

.InstManagement__header-container-right {
	display: flex;
	justify-content: flex-end;
	width: 200px;
}
</style>
