<template>
	<b-modal ref="modal" no-close-on-backdrop centered @close="hide">
		<template #modal-title>{{ tt('QR code/Barcode로 물품 정보찾기') }} </template>
		<template>
			<div class="product-description" v-if="type === 'product'">
				<p class="description" v-html="tt('시약병에 부착된 라벨의 QR코드 혹은 바코드를 스캔해주세요')"></p>
				<p class="description" v-html="tt('아래 예시 이미지를 참고해주세요')"></p>
			</div>
			<div class="inventory-description" v-else>
				<ul>
					<li class="description" v-html="tt('시약병에 부착된 라벨의 QR코드 혹은 바코드를 스캔해주세요')"></li>
					<li class="description" v-html="tt('물품에 할당된 QR 스티커를 스캔해주세요')"></li>
				</ul>
			</div>
			<img v-if="type === 'product'" class="image" src="@/views/inventoryGen2/svg/detail/example-qr-search-image.svg" />
			<div class="image-wrap" v-else>
				<img class="image" src="@/views/inventoryGen2/svg/detail/ex-qr-search-inventory1.svg" />
				<img class="image" src="@/views/inventoryGen2/svg/detail/ex-qr-search-inventory2.svg" />
			</div>
			<div class="loading-lottie" v-if="isLoading">
				<lm-modal-loading
					ref="modal-loading"
					:height="type === 'product' ? '500px' : '227px'"
					:play="isLoading"
					backgroundColor="#FFFFFFCC"
				></lm-modal-loading>
				<div class="loading-text">{{ tt('물품을 검색 중입니다.') }}</div>
			</div>
			<input
				ref="scan"
				class="scan-input"
				v-model="qrValue"
				@blur.once="blur"
				autofocus
				@keydown.enter="inputQr"
				@keypress.enter="qrValue = null"
			/>
		</template>

		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left">
					<button class="button-white" @click="$refs['modal-available-brand'].show()">
						<img src="@/views/inventoryGen2/svg/detail/icon-question.svg" />{{ tt('스캔 가능한 브랜드보기') }}
					</button>
				</div>
				<div class="footer-right">
					<button class="button-primary" @click="hide">
						{{ tt('닫기') }}
					</button>
				</div>
			</div>
		</template>
		<modal-available-brand ref="modal-available-brand"></modal-available-brand>
	</b-modal>
</template>
<script>
import ModalAvailableBrand from '@/views/common/components/modalGen2/ModalAvailableBrand.vue'
import LmModalLoading from '@/views/common/components/LmModalLoading.vue'
import { checkKor } from '@/utils/modules/Regex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'ModalSearchProductUsingQr',
	components: { ModalAvailableBrand, LmModalLoading },
	props: {},
	data() {
		return {
			qrValue: null,
			type: '', // product, inventory
			isLoading: false,
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.$refs?.scan?.focus()
		})
	},
	methods: {
		show(type) {
			this.type = type
			this.$refs['modal'].show()
			setTimeout(() => {
				this.$refs['scan'].focus()
			}, 200)
		},
		hide() {
			this.$refs['modal'].hide()
		},
		inputQr(e) {
			const scannedQr = this.$_.cloneDeep(this.qrValue)

			if (!checkKor(scannedQr)) {
				this.$emit('scan', scannedQr)
			} else {
				this.$root.toast(this.tt('QR 인식 불가'), this.tt('한/영 키를 눌러 영문 입력으로 전환해주세요.'), ToastType.WARNING)
			}
		},
		blur() {
			if (this.$refs?.scan) {
				this.$refs?.scan?.focus()
			}
		},
		setIsLoading(value) {
			this.isLoading = value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-search-product-using-qr.scss';
.scan-input {
	opacity: 0;
	position: absolute;
	left: 0px;
	top: 0px;
	ime-mode: disabled;
	-webkit-ime-mode: disabled;
	-moz-ime-mode: disabled;
	-ms-ime-mode: disabled;
}

.loading-lottie {
	position: absolute;
	top: 66px;
	width: 463px;
	border-radius: 7px;
}
.loading-text {
	display: flex;
	position: absolute;
	align-items: center;
	width: 100%;
	bottom: calc(50% - 100px);
	justify-content: center;
}
</style>
