var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.institute ? _c('div', {
    staticClass: "user-wrapper"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.tt('주문자 정보')) + " ")]), _c('div', {
    staticClass: "order-user-wrapper"
  }, [_c('input-text', {
    attrs: {
      "isReadOnly": "",
      "title": "소속",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "placeholder": "소속을 입력해주세요",
      "inputValue": _vm.institute.name
    }
  }), _c('input-text', {
    ref: "ordererName",
    attrs: {
      "isRequired": "",
      "title": "이름",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "placeholder": "이름을 입력해주세요",
      "inputValue": _vm.$root.user.name,
      "inValidText": '이름을 입력해주세요',
      "isValid": !_vm.isValid || !!_vm.$refs['ordererName'].value
    }
  }), _c('input-text', {
    ref: "ordererEmail",
    attrs: {
      "isRequired": "",
      "title": "이메일",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "placeholder": "이메일을 입력해주세요",
      "inValidText": '이메일을 입력해주세요',
      "inputValue": _vm.userEmail,
      "isValid": !_vm.isValid || !!_vm.$refs['ordererEmail'].value
    }
  }), _c('input-text', {
    ref: "ordererPhone",
    attrs: {
      "isRequired": "",
      "title": "연락처",
      "inputWidthSize": 400,
      "inputHeightSize": 36,
      "inputValue": _vm.$root.user.phone,
      "placeholder": "연락처를 입력해주세요",
      "inValidText": '연락처를 입력해주세요',
      "isValid": !_vm.isValid || !!_vm.$refs['ordererPhone'].value
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }