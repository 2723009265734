var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    }
  }, [_c('div', {
    staticClass: "seller-wrapper"
  }, [_vm.seller.id ? _c('div', {
    staticClass: "seller-image"
  }, [_c('img', {
    attrs: {
      "src": _vm.image
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "seller-info"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(_vm._s(_vm.tt('판매자 정보')))]), _c('div', {
    staticClass: "info-table"
  }, [_c('div', {
    staticClass: "info-row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('판매자')))]), _c('div', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.tt("".concat(_vm.cleanData(_vm.seller.name)))))])]), _c('div', {
    staticClass: "info-row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('담당자')))]), _c('div', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.tt("".concat(_vm.cleanData(_vm.seller.contactName)))))])]), _c('div', {
    staticClass: "info-row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('연락처')))]), _c('div', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.tt("".concat(_vm.cleanData(_vm.seller.contactPhone)))))])]), _c('div', {
    staticClass: "info-row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('사업자등록번호')))]), _c('div', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.tt("".concat(_vm.cleanData(_vm.seller.businessNumber)))))])]), _c('div', {
    staticClass: "info-row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('주소')))]), _c('div', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.tt("".concat(_vm.cleanData(_vm.seller.address)))))])])]), _vm.seller.id ? _c('div', {
    staticClass: "buttons"
  }, [_c('base-button', {
    attrs: {
      "color": 'primary'
    },
    on: {
      "click": function click($event) {
        return _vm.goPage();
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('판매자 상품 보기')))])], 1) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }