<template>
	<!-- 선택 이전 대상 -->
	<div class="page-wrapper">
		<migration-header :isMigration="true"></migration-header>
		<div class="content-slide-wrapper">
			<div class="content-wrapper">
				<div class="text-wrapper">
					<h3 class="title" v-html="title"></h3>
					<div class="content" v-html="content"></div>
					<div class="subtext" v-html="subtext"></div>
				</div>
			</div>
			<div class="institute-item-wrapper">
				<migration-select-item-new
					v-for="(item, index) in migrationInstitutes"
					:key="`migration-item-new-${index}`"
					:item="item"
					@click="showMigrationModal"
				></migration-select-item-new>
				<migration-select-item-legacy @click="showMigrationModal"></migration-select-item-legacy>
			</div>
		</div>
		<modal-common-confirm
			:isShow="displayConfirmModal"
			:hideConfirm="hideConfirmButton"
			:title="confirmModalTitle"
			:content="confirmModalContent"
			:buttonText="confirmModalButtonText"
			:cancelText="confirmModalCancelButton"
			@confirm="confirmModalClick"
			@close="displayConfirmModal = false"
		></modal-common-confirm>
	</div>
</template>

<script>
import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'
import { exproUrl } from '@/services/api/common'
import { requestSelectMigration, requestRejectMigration } from '@/services/api/migration'
import MixinMigration from '@/mixins/migration'
import MigrationHeader from '@/views/migration/components/MigrationHeader.vue'
import MigrationSelectItemLegacy from '@/views/migration/components/MigrationSelectItemLegacy.vue'
import MigrationSelectItemNew from '@/views/migration/components/MigrationSelectItemNew.vue'
import { mapActions, mapState } from 'vuex'
import ModalCommonConfirm from '@/views/common/components/modals/ModalCommonConfirm.vue'
import { EOLType } from '@/utils/define/DefineEOLType'

export default {
	components: {
		LmConfirmButtonMiddle,
		MigrationHeader,
		MigrationSelectItemLegacy,
		MigrationSelectItemNew,
		ModalCommonConfirm,
	},
	mixins: [MixinMigration],
	data() {
		return {
			title: this.tt('랩매니저 PRO로 전환하시겠습니까?'),
			content: this.tt('소속중인 연구실이 PRO로 이전되었습니다.<br>아래 정보를 확인하고, PRO 전환여부를 결정해주십시오.'),
			subtext: this.tt('※ 랩매니저 PRO로 한번 전환하면, 이후 기존 랩매니저의 접속 및 사용이 불가능 합니다.'),
			migrationText: this.tt('데이터 이전하기'),
			lastSelectItem: null,
			displayConfirmModal: false,
			hideConfirmButton: false,
		}
	},
	created() {},
	computed: {
		...mapState('license', ['type', 'status']),
		...mapState('appEOL', ['eolState']),
		migrationInstitutes() {
			return this.eolState ? this.eolState.institute : []
		},
		confirmModalButtonText() {
			switch (this.lastSelectItemStatus) {
				case this.status.ACTIVATE:
				case this.status.EXPIRED:
					this.hideConfirmButton = false
					return this.tt('전환하기')
				case this.status.FULL:
					this.hideConfirmButton = true
					return ''
				default:
					this.hideConfirmButton = false
					return this.tt('이용하기')
			}
		},
		confirmModalTitle() {
			switch (this.lastSelectItemStatus) {
				case this.status.ACTIVATE:
				case this.status.EXPIRED:
					return this.tt('랩매니저 PRO 이전')
				case this.status.FULL:
					return this.tt('랩매니저 PRO 이전 실패')
				default:
					return this.tt('기존 랩매니저 이용하기')
			}
		},
		confirmModalContent() {
			switch (this.lastSelectItemStatus) {
				case this.status.ACTIVATE:
					return this.tt(
						'랩매니저 PRO로 서비스를 전환하면 기존 랩매니저에서 이용하던 연구실을 다시 이용하실 수 없습니다. 정말로 PRO 전환을 하시겠습니까?'
					)
				case this.status.EXPIRED:
					return this.tt(
						'현재 랩매니저 PRO의 등록되어있는 기관의 <b class="red">라이선스가 만료된 상태</b>입니다.<br>라이선스 구매를 해야 사용이 가능합니다. 정말로 PRO 전환을 하시겠습니까?'
					)
				case this.status.FULL:
					return this.tt(
						'현재 전환, 가입하시려는 랩매니저 PRO 연구실의 <b class="red">라이선스 수가 부족</b>합니다.<br>관리자가 라이선스 구매 후, 전환 가능합니다.'
					)
				default:
					return this.tt(
						'PRO로 전환하지 않고 기존 랩매니저를 이용 하시겠습니까?<br>지금 전환하지 않으면, 이전된 연구실에 가입할 수 없습니다.'
					)
			}
		},
		confirmModalCancelButton() {
			switch (this.lastSelectItemStatus) {
				case this.status.FULL:
					return this.tt('닫기')
				default:
					return this.tt('취소')
			}
		},
		lastSelectItemStatus() {
			return this.lastSelectItem && this.lastSelectItem.license.status
		},
	},
	methods: {
		...mapActions('appEOL', ['loadEolState']),
		goToExperience() {
			window.open(exproUrl, '_self')
		},
		showMigrationModal(item) {
			this.lastSelectItem = item
			console.log(this.lastSelectItem)
			this.displayConfirmModal = true
		},
		async confirmModalClick() {
			console.log(this.lastSelectItemStatus)
			switch (this.lastSelectItemStatus) {
				case this.status.ACTIVATE:
				case this.status.EXPIRED:
					// 선택이전 요청 api
					try {
						const instituteId = this.lastSelectItem.id
						const body = { instituteId: instituteId }
						await requestSelectMigration(body)
						await this.loadEolState()
					} catch (e) {
					} finally {
						location.href = '/'
					}
					break
				case this.status.FULL:
					return
				default:
					try {
						await requestRejectMigration()
						await this.loadEolState()
						this.routeLegacyLM()
					} catch (error) {
					} finally {
						location.href = process.env.VUE_APP_LEGACY_SERVICE_URL
					}
					break
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background: white;
	.content-slide-wrapper {
		width: 100vw;
		height: calc(100% - 60px);
		display: flex;
		flex-direction: column;
		overflow-y: auto;

		align-items: center;
		position: absolute;
		top: 60px;

		.content-wrapper {
			width: 750px;
			display: flex;
			flex-direction: column;
			.text-wrapper {
				margin-bottom: 40px;
				.title {
					font-size: 48px;
					font-style: normal;
					font-weight: 700;
					line-height: 70px;
					letter-spacing: 0px;
					text-align: center;
					margin-bottom: 20px;
				}

				.content {
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 26px;
					letter-spacing: 0px;
					text-align: center;
					margin-bottom: 10px;
				}

				.subtext {
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 17px;
					letter-spacing: 0px;
					text-align: center;
					color: $SECONDARY_2;
				}
			}
			.institute-item-wrapper {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
	}
}
</style>
