<template>
	<tr v-if="item">
		<td v-if="headers[0].value" @click="detail(item)">
			<div class="state" :class="item.status == 'SAFE' ? 'safe' : 'warning'">
				<img src="@/assets/images/uploaded.png" alt="icon" />
				<p v-text="item.status == 'SAFE' ? tt('등록됨') : tt('업로드 필요')"></p>
			</div>
		</td>
		<td v-if="headers[1].value" @click="detail(item)">
			<div class="description">
				<h6 class="Btn__hover-balloon-w" v-if="item.name">
					<span>{{ item.name }}</span>
					<div class="balloon">
						{{ item.name }}
					</div>
				</h6>
				<span v-if="item.brand">{{ item.brand.name }}{{ item.brand.name && item.subname ? ' | ' : '' }}{{ item.subname }}</span>
			</div>
		</td>
		<td v-if="headers[2].value" @click="detail(item)">
			<p :class="item.productCode ? '' : 'nodata-txt'" v-text="item.productCode ? item.productCode : tt('정보없음')"></p>
		</td>
		<td v-if="headers[3].value" @click="detail(item)">
			<p :class="item.casno ? '' : 'nodata-txt'" v-text="item.casno ? item.casno : tt('정보없음')"></p>
		</td>
		<td v-if="headers[4].value" @click="detail(item)">
			<div class="msds" v-if="item.msds.master != null || item.msds.institute != null || item.msds.kosha != null">
				<div v-if="item.msds.master">
					<img v-if="item.msds.master.brand.image" :src="item.msds.master.brand.image" alt="icon" />{{ tt('제조사 MSDS') }}
				</div>
				<div v-if="item.msds.kosha">
					<img v-if="item.msds.kosha.brand.image" :src="item.msds.kosha.brand.image" alt="icon" />{{ tt('안전보건공단 MSDS') }}
				</div>
				<div v-if="item.msds.institute" v-b-tooltip.hover="item.msds.institute.user.name + '님이 업로드하였습니다.'">
					<img
						v-if="item.msds.institute.user"
						:src="item.msds.institute.user.image ?? require('@/assets/svg/noMemberImg.svg')"
						alt="icon"
					/>{{ tt('업로드된 MSDS') }}
				</div>
			</div>
			<p v-else class="nodata-txt">{{ tt('없음') }}</p>
		</td>
		<td v-if="headers[5].value && userPermission.safe && userPermission.safe.msds.create">
			<div class="btn-wrap">
				<button class="btn" v-if="item.msds.institute" @click="download">
					<img src="@/assets/svg/icon-download.svg" alt="icon" />
					{{ tt('다운로드') }}
				</button>

				<button class="btn" v-if="item.msds.institute" @click="$refs['modal-change-file'].show()">
					<img src="@/assets/svg/icon-file-change.svg" alt="icon" />
					{{ tt('파일변경') }}
				</button>
				<button class="btn" v-if="item.msds.institute" @click="$refs['modal-delete'].show()">
					<img src="@/assets/svg/icon-delete.svg" alt="icon" />
					{{ tt('삭제') }}
				</button>
				<div class="btn" v-else @click="$refs['upload-file'].click()">
					<img src="@/assets/svg/icon-upload-file.svg" alt="icon" />
					{{ tt('업로드') }}
				</div>
			</div>
		</td>
		<td v-if="headers[6].value">
			{{ formatDate(item.lastUpdateDatetime) }}
		</td>
		<msds-multi-file-viewer
			ref="viewer"
			:msds="clickedItem.msds"
			:ghs="clickedItem.ghs"
			@delete="deleteUpload"
			@uploadFile="upload"
			@changeFile="upload"
			@createGHS="$refs['make-ghs'].show()"
			@previewGHS="manageGHS"
			@loadList="$emit('loadList')"
		></msds-multi-file-viewer>
		<modal-confirm
			ref="modal-change-file"
			title="업로드된 MSDS 변경"
			content="정말로 업로드된 MSDS의 파일을 대체하시겠습니까?<br/>기존 파일은 사라집니다"
			confirmText="파일변경"
			@confirm="$refs['upload-file'].click()"
		></modal-confirm>
		<modal-confirm
			ref="modal-delete"
			variant="warning"
			title="업로드된 MSDS 삭제"
			content="정말로 업로드된 MSDS를 삭제하시겠습니까?<br/>이 동작은 되돌릴 수 없습니다"
			confirmText="삭제"
			@confirm="deleteUpload"
		></modal-confirm>
		<modal-confirm
			ref="modal-delete-ghs"
			variant="warning"
			title="GHS 라벨 삭제"
			content="GHS 라벨을 삭제하면 브랜드, 제품번호가 동일한 모든 물품의 GHS라벨이 삭제됩니다. 삭제 하시겠습니까?"
			confirmText="삭제"
			@confirm="deleteGHS"
		/>

		<modal-make-ghs
			ref="make-ghs"
			:msds="clickedItem.msds"
			@showGuide="$refs['guide'].show()"
			@goNext="
				$refs['make-ghs'].hide()
				$refs['ghs-form'].show()
			"
			@uploadMSDS="uploadMsdsFile"
		/>
		<modal-ghs-form
			ref="ghs-form"
			@goNext="showPreviewGHS"
			@goPrev="
				$refs['make-ghs'].show('prev')
				$refs['ghs-form'].hide()
			"
		></modal-ghs-form>
		<modal-ghs-preview
			ref="ghs-preview"
			@goPrev="
				$refs['ghs-preview'].hide()
				$refs['ghs-form'].show()
			"
			@complete="completeCreateGHS"
		></modal-ghs-preview>
		<modal-ghs-label ref="ghs-label" :item="item" @edit="editGHS" @delete="showModalDeleteGHS"></modal-ghs-label>
		<input class="upload-file" type="file" accept=".pdf" @change="upload" ref="upload-file" />
	</tr>
</template>

<script>
import MsdsMultiFileViewer from '@/views/common/components/modalGen2/MsdsMultiFileViewer.vue'
import msdsAPI from '@/services/api/safety/msds'

import ModalMakeGhs from '@/views/common/components/modalGen2/ghs/ModalMakeGHS.vue'
import ModalGhsForm from '@/views/common/components/modalGen2/ghs/ModalGHSForm.vue'
import ModalGhsPreview from '@/views/common/components/modalGen2/ghs/ModalGHSPreview.vue'
import ModalGhsLabel from '@/views/common/components/modalGen2/ghs/ModalGHSLabel.vue'

import MixinModalGhs from '@/mixins/modalGhs/mixinModalGhs'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
	name: 'MsdsItem',
	mixins: [MixinModalGhs],
	components: {
		MsdsMultiFileViewer,
		ModalMakeGhs,
		ModalGhsForm,
		ModalGhsPreview,
		ModalGhsLabel,
	},
	props: {
		instId: {
			type: String,
			default: null,
		},
		headers: {
			type: Array,
			default: [],
		},
		item: {
			type: Object,
			defualt: null,
		},
	},
	computed: {
		...mapState('msds', ['isShowModal', 'clickedItem']),
		ghsPayload() {
			return this.item?.brand?.id && this.item?.productCode
				? {
						brandId: this.item?.brand?.id,
						productCode: this.item?.productCode,
						inventoryId: this.item?.inventoryId,
						ghs: this.ghsData,
				  }
				: {
						inventoryId: this.item?.inventoryId,
						ghs: this.ghsData,
				  }
		},
	},
	data() {
		return {
			isClick: false,
		}
	},
	methods: {
		...mapMutations('msds', ['setShowModal', 'setClickedItem']),
		...mapActions('msds', ['deleteMsds', 'getMsds']),
		detail(item) {
			this.setShowModal(true)
			this.setClickedItem(item)
			console.log(item)
			if (item.msds.institute && !item.msds.master) this.$refs['viewer'].show('institute')
			else this.$refs['viewer'].show('master')
		},
		formatDate(date) {
			if (date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear()
				if (month.length < 2) {
					month = '0' + month
				}
				if (day.length < 2) {
					day = '0' + day
				}
				return [year, month, day].join('-')
			} else {
				return '-'
			}
		},
		async upload(event) {
			console.log(event)
			if (event?.target?.files?.length > 0) this.uploadFile(event.target.files)
		},
		async uploadMsdsFile(file) {
			this.uploadFile([file])
		},
		uploadFile(files) {
			const formData = new FormData()

			if (this.item.brand && this.item.brand.id) {
				formData.append('brandId', this.item.brand?.id)
			}
			if (this.item.inventoryId != null) {
				formData.append('inventoryId', this.item.inventoryId)
			}
			if (this.item.productCode != null) {
				formData.append('productCode', this.item.productCode)
			}
			formData.append('file', files[0])

			msdsAPI.updateMsds(this.instId, formData).then(() => {
				const toastTitle = this.tt(this.item.msds.institute ? 'MSDS 파일변경 완료' : 'MSDS 업로드 완료')
				const toastContent = this.tt(this.item.msds.institute ? 'MSDS 파일변경이 완료되었습니다.' : 'MSDS 업로드가 완료되었습니다.')
				this.$root.toast(toastTitle, toastContent, 'success')
				if (!this.isShowModal) this.$emit('loadList')
				else this.getMsds()
			})
		},
		async deleteUpload() {
			await this.deleteMsds({
				productCode: this.item.productCode,
				brandId: this.item.brand?.id,
				inventoryId: this.item.inventoryId,
			})
			const toastTitle = this.tt('MSDS 삭제 완료')
			const toastContent = this.tt('MSDS 삭제가 완료되었습니다.')
			this.$root.toast(toastTitle, toastContent, 'success')
			if (!this.isShowModal) this.$emit('loadList')
			else await this.getMsds()
		},
		async download() {
			const body = {
				name: this.item.name,
				productCode: this.item.productCode,
				brandName: this.item.brand?.name,
				url: this.item.msds.institute.url,
			}
			const response = await msdsAPI.downloadMsds(this.instId, body)

			window.fileDownload(response, `${body.name}-${this.formatDate(this.item.msds.institute.createDatetime)}.pdf`)
		},
		async completeCreateGHS() {
			this.$refs['ghs-preview'].hide()
			this.$refs['ghs-label'].show()

			let text = this.mode === 'EDIT' ? '편집' : '생성'
			await this.uploadGhsLabel(this.ghsPayload)
			this.$root.toast(`GHS ${text} 완료`, `GHS 라벨 ${text}이 완료되었습니다`, 'success')
			if (!this.isShowModal) this.$emit('loadList')
			else await this.getMsds()
		},
		manageGHS() {
			this.$refs['ghs-label'].show()
			this.setPreviewData(this.item)
		},
		async deleteGHS() {
			const { brandId, productCode, inventoryId } = this.ghsPayload
			if (brandId && productCode) {
				await this.deleteGhsLabel({ brandId: brandId, productCode: productCode, inventoryId: inventoryId })
			} else {
				await this.deleteGhsLabel({ inventoryId: inventoryId })
			}
			// GHS 라벨 삭제시, stickerType : null로 세팅
			this.$root.toast(`GHS 삭제 완료`, `GHS 라벨이 삭제되었습니다`, 'success')
			this.$refs['ghs-label'].hide()
			if (!this.isShowModal) this.$emit('loadList')
			else await this.getMsds()
		},
	},
}
</script>

<style lang="scss" scoped>
.safe {
	color: $GREEN !important;
}
.warning {
	color: $PLACEHOLDER !important;
	img {
		content: url(~@/assets/svg/not-uploaded.svg);
	}
}

tr {
	td {
		&:nth-child(1) {
			min-width: unset;
			width: unset !important;
			max-width: unset !important;
			padding-left: 40px !important;
		}
		.state {
			&.safe {
				color: $GREEN;
			}
			&.error {
				color: $RED;
			}
		}
		.description {
			cursor: pointer;
		}
		.nodata-txt {
			color: $PLACEHOLDER;
		}
		.msds {
			display: flex;
			align-items: center;
			height: auto;
			> div {
				display: flex;
				align-items: center;
				height: auto;
				font-size: 0.875rem;
				color: rgba(0, 0, 0, 0.87);
				font-weight: 700;
				margin-right: 20px;
				img {
					margin-right: 6px;
					width: 24px;
					height: 24px;
					object-fit: cover;
					border-radius: 50%;
					border: 1px solid #eee;
				}
			}
		}
		.btn-wrap {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: auto;
			.btn {
				font-weight: bold;
				margin-right: 10px;
				img {
					padding-right: 0;
				}
			}
		}
	}
	.upload-file {
		display: none;
	}
}
</style>
