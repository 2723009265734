<template>
	<portal to="lm-modal">
		<div class="Popup__depth3" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt(title) }}</h5>
					<button class="Popup__close-btn" @click="cancel">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text">
						{{ tt(desc.split('&')[0]) }}<br />
						{{ tt(desc.split('&')[1]) }}
					</p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button @click="cancel" class="Btn__close">
							{{ tt('취소') }}
						</button>
						<button @click="complete" :class="`Btn__${button}`" style="width: unset; min-width: 80px">
							{{ tt(button_text) }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalCommon',
	mixins: [MixinModal],
	data() {
		return {
			title: '',
			desc: '',
			button: '',
			button_text: '',
		}
	},
	methods: {
		show(title, desc, button, button_text) {
			this.isShow = true
			this.title = title
			this.desc = desc
			this.button = button
			this.button_text = button_text
		},
		cancel() {
			this.$emit('isHide', { button: this.button_text, check: false })
		},
		complete() {
			this.$emit('isHide', { button: this.button_text, check: true })
		},
	},
}
</script>

<style lang="scss">
.Popup__container {
	.Popup__bottom {
		.Popup__btn-group {
			button.Btn__init {
				background: $LAB_BLACK;
				color: white;
				text-decoration: none;
			}
		}
	}
}
</style>
