<template>
	<div class="form-input" :class="{ error: error }">
		<div class="form-header">
			<div>
				<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span> <span></span></p>
			</div>
			<div class="threshold" v-if="isFieldThreshold && item && item.volume">
				{{ tt('물품 사이즈(용량) : ${1}', `${item.volume}${item.volumeUnit}`) }}
			</div>
		</div>
		<div class="input-wrapper">
			<input
				class="form-number-input"
				type="text"
				ref="input-number"
				:placeholder="tt(placeholder)"
				v-model="inputValue"
				@input="changeValue"
			/>
			<b class="unit" v-if="optionList && optionList.length === 1">{{ tt(inputUnit) }}</b>
			<!-- select: START -->
			<form-unit-select-option
				v-else
				ref="unit-select-option"
				:value="tt(unit)"
				:optionList="optionList"
				@input="changeUnit"
			></form-unit-select-option>
			<!-- select: END -->
		</div>
		<span class="error" v-if="error">{{ errorMessage }}</span>
	</div>
</template>
<script>
import FormUnitSelectOption from '@/views/common/components/form/FormUnitSelectOption'
import { verifyFloat } from '@/utils/modules/VerifyForm.js'

export default {
	name: 'FormNumberWithUnit',
	components: { FormUnitSelectOption },
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		unit: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		optionList: {
			type: Array,
			default: () => [],
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
		field: {
			type: Object,
			default: null,
		},
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			inputValue: null,
			inputUnit: '',
		}
	},
	computed: {
		resultValue() {
			const newValue = {
				value: String(this.inputValue).replaceAll(',', '') ?? null,
				unit: this.inputUnit,
			}
			return newValue
		},
		error() {
			return (
				(this.required && this.isShowError && (!this.inputValue || this.inputValue <= 0 || !this.inputUnit)) ||
				(!this.required && this.isShowError && !!this.inputValue && !this.inputUnit)
			)
		},
		isFieldThreshold() {
			return this.field?.key === 'threshold'
		},
		isFieldVolume() {
			return this.field?.key == 'volume'
		},
	},
	mounted() {
		this.initUnitByList()
		if (this.isFieldThreshold && this.item?.volumeUnit) {
			// 관리정보 - 안전재고량 등록시, 앞 화면 물품정보에서 등록한 물품 사이즈(용량)의 unit 따라가기
			this.inputValue = this.item.threshold ?? ''
			this.inputUnit = this.item.volumeUnit
			this.$emit('input', this.resultValue)
		}

		if (this.inputValue !== this.value) this.inputValue = this.value ?? null
		if (this.optionList.length === 1 && this.inputUnit != this.optionList[0]) {
			this.inputUnit = this.optionList[0]
			if (this.inputValue && this.inputUnit != this.unit) this.$emit('input', this.resultValue)
		} else if (this.inputUnit !== this.unit) this.inputUnit = this.unit
	},
	watch: {
		optionList: {
			deep: true,
			handler() {
				// currentValue 대응 작업
				if (this.optionList.length === 1 && this.inputUnit != this.optionList[0]) {
					this.inputUnit = this.optionList[0]
					if (this.inputValue && this.inputUnit != this.unit) this.$emit('input', this.resultValue)
				}
			},
		},
		unit(unit) {
			if (this.inputUnit !== this.unit) {
				this.inputUnit = unit
			}
			if (!unit) this.initUnitByList()
		},
		value(value) {
			if (this.inputValue !== this.value) {
				this.inputValue = value ?? null
			}
		},
	},
	methods: {
		changeValue(e) {
			e.target.value = verifyFloat(e.target.value)
			this.inputValue = e.target.value
			this.$emit('input', this.resultValue)
		},
		changeUnit(unit) {
			this.inputUnit = unit
			this.$emit('input', this.resultValue)
		},
		initUnitByList() {
			// 물품의 createDatetime이 없을때 -> 물품등록중 일때
			if (!this.item.volumeUnit || this.item?.volumes?.length > 0) {
				if (this.isFieldVolume && this.optionList.includes('g')) {
					// 물품 사이즈(용량) 기본 unit 지정 및 용량 추가 로직
					this.inputValue = ''
					this.inputUnit = 'g'
					if (this.item?.volumes?.length > 0) {
						// 검색 아이템으로 등록시, item에 종류가 있으면 default로 셋팅
						const volumesUnit = this.item.volumes.map(v => v.replace(`${parseFloat(v)}`, '')) // 용량 + 단위에서 단위(unit) 분리
						const isSame = volumesUnit.every(v => v === volumesUnit[0]) // 분리한 unit들이 일치한지 체크
						if (isSame) {
							this.inputUnit = volumesUnit[0] // 한 종류의 unit이면 defultUnit으로 셋팅
						}
						if (volumesUnit.length === 1) {
							this.inputValue = parseFloat(this.item.volumes[0]) // 용량이 하나면 default 용량으로 셋팅
						}
					}
					this.$emit('input', this.resultValue)
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-input.scss';
.form-header {
	display: flex;
	justify-content: space-between;
	.threshold {
		color: #0b6cff;
		font-size: 12px;
		font-weight: 400;
	}
}
</style>
