<template>
	<div id="Member" class="Member">
		<h2 class="AppContainer__title">{{ tt('멤버') }}</h2>
		<div class="Member__container">
			<!-- <tab :tabList="tabList" :tab="tab" @selectTab="selectTab"></tab> -->
			<member-all :userPermission="userPermission"></member-all>
			<!-- <invite-member v-if="tab == 2"></invite-member> -->
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Tab from '@/views/common/components/Tab.vue'
import MemberAll from '@/views/institute/member/components/MemberAll.vue'
import MemberInvite from '@/views/institute/member/components/MemberInvite.vue'

export default {
	name: 'MemberList',
	components: {
		Tab,
		MemberAll,
		MemberInvite,
	},
	data() {
		return {
			tabList: [],
		}
	},
	watch: {
		memberList: function () {
			this.tabList = [
				{ name: '전체 멤버', count: this.memberList.count },
				{ name: '초대목록', count: 0 },
			]
		},
	},
	computed: {
		...mapState('member', ['tab', 'memberList']),
	},
	methods: {
		...mapMutations('member', ['setTab']),
		selectTab(item) {
			this.setTab(item)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_member.scss';
</style>
