<template>
	<div class="use-wrap">
		<div class="input-head-text">
			<p>{{ tt('사용 패키지 수량') }}</p>
			<p>
				<span class="volume-text">{{ tt(`현재 패키지 수량`) }}</span> :
				<span class="current-volume">{{ `${volume ? volume : 0}${volumeUnit}` }}</span>
			</p>
		</div>
		<div class="input-container">
			<div class="input-wrap">
				<input
					class="input-use"
					:class="{ error: isShowVolumeError && isInputVolumeOver(volume) }"
					:value="inputValue"
					:placeholder="tt(`사용 패키지 수량을 입력해주세요`)"
					@input="handleInput"
				/>
				<span class="input-unit">{{ tt(volumeUnit) }}</span>
			</div>
			<button class="button-gray" @click="useAll">
				{{ tt('잔량 모두 입력') }}
			</button>
		</div>
		<p class="error-msg" v-if="isShowVolumeError && isInputVolumeOver(volume)">
			<img src="@/views/inventoryGen2/components/svg/icon-error.svg" />
			{{ tt(`사용 패키지 수량을 입력해주세요.`) }}
		</p>
	</div>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
import { mapGetters, mapMutations } from 'vuex'
import Decimal from 'decimal.js'
import { verifyInteger } from '@/utils/modules/VerifyForm.js'

export default {
	name: 'DefaultInput',
	components: {},
	mixins: [MixinItem],
	props: {
		volume: {
			type: [Number, String],
			default: null,
		},
		volumeUnit: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputValue: null,
		}
	},
	computed: {
		...mapGetters('modal/inputUse', ['useVolume', 'isInputVolumeEmpty', 'isShowVolumeError', 'isInputVolumeOver']),
	},
	watch: {
		useVolume(value) {
			this.inputValue = value
			if (value < this.volume) {
				this.setIsShowVolumeError(false)
			}
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('modal/inputUse', ['setUseVolume', 'setIsShowVolumeError']),
		init() {
			this.inputValue = null
			this.setUseVolume(null)
			this.setIsShowVolumeError(false)
		},
		useAll() {
			this.inputValue = this.volume
			this.setUseVolume(this.volume)
		},
		handleInput(e) {
			e.target.value = verifyInteger(e.target.value)
			this.useValue = e.target.value
			this.$emit('input', this.useValue ? Decimal(this.useValue).toNumber() : '')
		},
	},
}
</script>

<style lang="scss" scoped>
input {
	width: 100%;
	font-size: 14px;
	padding: 0px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	padding: 5px 60px 5px 10px;
	&.error {
		border: 1px solid #ff3b31 !important;
	}
}

p {
	font-size: 14px;
	font-weight: 400;
}

.input-container {
	display: flex;
	gap: 10px;
	height: 30px;
	.input-wrap {
		display: flex;
		width: 100%;
		position: relative;
		.input-unit {
			width: 60px;
			position: absolute;
			top: 4px;
			right: 0px;
			font-size: 14px;
			text-align: right;
			padding-right: 10px;
		}
	}
}

.use-wrap {
	.input-head-text {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
		& > p {
			font-size: 12px;
			.volume-text {
				color: $PLACEHOLDER;
			}
			.current-volume {
				color: $BLUE;
			}
		}
	}
	.input-use {
		&:focus-within {
			border: 1px solid $SECONDARY_1;
		}
	}
	.require {
		color: $RED;
	}
}

.error-msg {
	display: flex;
	align-items: center;
	color: $RED;
	font-size: 12px;
	font-weight: 400;
	img {
		margin-right: 6px;
	}
}
</style>
