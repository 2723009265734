var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "complete-container"
  }, [_c('span', {
    staticClass: "complete",
    "class": !_vm.isValid ? 'unvalid' : null
  }, [_c('div', {
    staticClass: "text-slot-area",
    "class": !_vm.isValid ? 'unvalid' : null
  }, [_vm._t("default")], 2), _c('img', {
    staticClass: "delete-btn",
    attrs: {
      "src": require("@/assets/svg/search-delete.svg"),
      "alt": "delete"
    },
    on: {
      "click": _vm.onClickDeleteBtn
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }