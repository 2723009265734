<template>
	<div class="form-wrap">
		<div class="form-header">
			<div class="form-title">{{ tt(title) }}</div>
			<div class="form-msg" :class="{ error: !isValid }">
				{{ tt(validMessage) }}
			</div>
		</div>
		<div class="error-msg" v-if="error">
			{{ tt('등록 방법 선택은 필수입니다.') }}
		</div>
		<div class="selector-wrap">
			<div class="auto-regist" v-if="isChemical && !isGas">
				<div class="title-header">
					<p class="title">{{ tt('자동등록') }}</p>
				</div>
				<item
					v-if="!isGas"
					:image="require('@/views/inventoryGen2/svg/create/regist-method-image.svg')"
					title="사진으로 등록"
					subtext="사진분석 및 검수 후에 자동으로 등록해드립니다"
					:active="isActive('AUTO_IMAGE')"
					@click="selectType('AUTO_IMAGE')"
				></item>
				<!-- <item
					:image="require('@/views/inventoryGen2/svg/create/regist-method-msds.svg')"
					title="MSDS/COA 파일로 등록"
					subtext="파일분석 및 검수 후에 자동으로 등록해드립니다"
					:active="isActive('AUTO_DOC')"
					@click="selectType('AUTO_DOC')"
				></item> -->
			</div>
			<div class="manual-regist">
				<div class="title-header">
					<p class="title">{{ tt('수기등록') }}</p>
				</div>
				<item
					:image="require('@/views/inventoryGen2/svg/create/regist-method-excel.svg')"
					title="엑셀 파일로 등록"
					subtext="지정된 양식의 엑셀을 업로드하여 등록합니다"
					:active="isActive('MANUAL_FILE')"
					@click="selectType('MANUAL_FILE')"
				></item>
				<item
					:image="require('@/views/inventoryGen2/svg/create/regist-method-manual.svg')"
					title="DB 검색/직접입력"
					subtext="물성정보를 검색하고, 직접 입력하여 등록합니다"
					:active="isActive('MANUAL_DIRECT')"
					@click="selectType('MANUAL_DIRECT')"
				></item>
			</div>
		</div>
	</div>
</template>
<script>
import Item from '@/views/inventoryGen2/components/regist/registMethodSelector/item.vue'
export default {
	name: 'RegistMethodSelector',
	components: { Item },
	props: {
		title: {
			type: String,
			default: '등록 방법 선택',
		},
		isGas: {
			type: Boolean,
			default: false,
		},
		isChemical: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: null,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			type: null,
		}
	},
	computed: {
		error() {
			return !this.type && this.isShowError
		},
		isActive() {
			return type => this.type === type
		},
		validMessage() {
			return this.type ? '선택완료' : '필수값을 선택해주세요'
		},
	},
	watch: {
		value: {
			handler(value) {
				this.type = value
			},
		},
	},
	created() {},
	mounted() {
		if (this.type !== this.value) {
			this.type = this.value
		}
		if (!this.isChemical) {
			this.type = 'MANUAL_DIRECT'
			this.$emit('change', this.type)
		}
	},
	methods: {
		selectType(type) {
			this.type = type
			this.$emit('change', type)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
.selector-wrap {
	border: 1px solid $INPUT;
	border-radius: 5px;
	overflow: hidden;
	.auto-regist {
		.title-header {
			padding: 6px 15px;
			background: #fbfaf9;
			border-width: 0px 1px 1px 0px;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			border-bottom: 1px solid $INPUT;
			.title {
				font-size: 12px;
				font-weight: 500;
				color: $SECONDARY_2;
			}
		}
	}
	.manual-regist {
		.title-header {
			padding: 6px 15px;
			color: $SECONDARY_2;
			background: #fbfaf9;
			border-width: 0px 1px 1px 0px;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			border-bottom: 1px solid $INPUT;
			.title {
				font-size: 12px;
				font-weight: 500;
				color: $SECONDARY_2;
			}
		}
	}
	.auto-regist + .manual-regist {
		border-top: 1px solid $INPUT;
		.title-header {
			border-radius: 0px;
		}
	}
}
</style>
