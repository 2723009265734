var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_c('img', {
          staticStyle: {
            "margin-right": "5px"
          },
          attrs: {
            "src": require("@/assets/svg/store/labmanager-store.svg")
          }
        }), _vm._v(_vm._s(_vm.tt('상품 검색')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }), _c('div', {
          staticClass: "footer-right"
        }, [_c('base-button', {
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(_vm._s(_vm.tt('닫기')))])], 1)])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "search-wrap"
  }, [_c('div', {
    staticClass: "search-box-main"
  }, [_c('div', {
    staticClass: "search-box-wrap"
  }, [_c('store-search-box', {
    ref: "search-box",
    attrs: {
      "placeholder": _vm.tt('물품명, CAS No, 제품번호, 브랜드로 검색'),
      "value": _vm.keyword
    },
    on: {
      "change": _vm.changeMainSearch
    }
  }), _vm.keyword.length === 0 && !_vm.selectedItem && _vm.isMerckTooltipShow ? _c('merck-tooltip', {
    attrs: {
      "cssVariable": _vm.cssVariable
    }
  }, [_c('img', {
    attrs: {
      "width": "360px",
      "src": require("@/assets/images/icon-sale-search2.png")
    }
  })]) : _vm._e()], 1)])]), !_vm.selectedItem ? _c('div', {
    staticClass: "table-wrap"
  }, [_c('search-sort-tab', {
    attrs: {
      "tabList": _vm.tabList,
      "selectTab": _vm.catalogSelectTab
    },
    on: {
      "change": _vm.changeTab
    }
  }), _vm.list.length !== 0 ? _c('ul', {
    ref: "scroll-container",
    on: {
      "scroll": _vm.onScroll
    }
  }, [_vm.keyword && _vm.isMerckTooltipShow ? _c('merck-tooltip', {
    attrs: {
      "cssVariable": _vm.cssVariable2
    }
  }, [_c('img', {
    attrs: {
      "width": "360px",
      "src": require("@/assets/images/icon-sale-select.png")
    }
  })]) : _vm._e(), _vm._l(_vm.list, function (item, index) {
    return _c('product-info-item', {
      key: index,
      attrs: {
        "item": item,
        "keyword": _vm.keyword
      },
      on: {
        "click": _vm.getStoreItem
      }
    });
  })], 2) : _vm.keyword.length === 0 ? _c('div', {
    staticClass: "no-data"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('상품 검색')))]), _c('span', [_vm._v(" " + _vm._s(_vm.tt('상품명, CAS No, 제품번호, 브랜드로 검색해주세요')) + " ")])]) : _c('div', {
    staticClass: "no-data"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('결과 없음')))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt('검색결과가 없습니다.')) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.tt('랩매니저 스토어를 통해 상품을 요청해보세요.')) + " ")])])], 1) : _c('div', {
    staticClass: "product-detail-wrap"
  }, [_c('image-button', {
    attrs: {
      "text": '뒤로가기',
      "image": require('@/assets/svg/Left.svg')
    },
    on: {
      "click": _vm.prev
    }
  }), _c('ul', [_c('product-info-item', {
    attrs: {
      "item": _vm.selectedItem,
      "keyword": _vm.keyword,
      "usePrice": true,
      "isSearch": false
    }
  })], 1), _c('div', {
    staticClass: "check-wrap"
  }, [_c('search-check-box', {
    attrs: {
      "title": '판매자',
      "list": _vm.sellerFilterList,
      "type": 'sellerIds'
    },
    on: {
      "change": _vm.setProductLoadParams
    }
  }), _c('search-check-box', {
    attrs: {
      "title": '사이즈(용량)',
      "list": _vm.sizeFilterList,
      "type": 'options'
    },
    on: {
      "change": _vm.setProductLoadParams
    }
  })], 1), _c('product-search-sort-tab', {
    attrs: {
      "tabList": _vm.tabList,
      "selectTab": _vm.productSelectTab
    },
    on: {
      "change": _vm.changeProductTab
    }
  }), _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('product-info-table', {
    on: {
      "addShow": function addShow($event) {
        _vm.isTooltip = false;
      },
      "addHide": function addHide($event) {
        _vm.isTooltip = true;
      },
      "confirm": _vm.hide
    }
  }), _vm.isMerckTooltipShow && _vm.isTooltip && _vm.selectedItem.brand && _vm.selectedItem.brand.isMerck ? _c('merck-tooltip', {
    attrs: {
      "cssVariable": _vm.cssVariable3
    }
  }, [_c('img', {
    attrs: {
      "width": "360px",
      "src": require("@/assets/images/icon-sale-add.png")
    }
  })]) : _vm._e()], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }