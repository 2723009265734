<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddChildStorageBoxPopup" ref="AddChildStorageBoxPopup">
			<div class="Popup__background"></div>
			<div class="Location__edit-location-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title" v-if="isEditMode">{{ originName }} {{ tt('편집') }}</h5>
					<h5 class="Popup__title" v-else-if="isAddLocation">
						{{ tt('위치 추가') }}
					</h5>
					<h5 class="Popup__title" v-else>
						{{ tt('하위 보관함 추가') }}
					</h5>
					<button class="Popup__close-btn" @click.stop="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field">
						<p v-if="isAddLocation || isEditLocation">{{ tt('위치명') }}<span class="required">*</span></p>
						<p v-else>{{ tt('하위 보관함명') }}<span class="required">*</span></p>
						<input type="text" :placeholder="tt('위치명을 입력해주세요')" v-model="name" @keypress.enter="complete" />
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click.stop="hide">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click.stop="complete" :disabled="name && !selectedInfo.name">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { mapGetters, mapState } from 'vuex'
import locationAPI from '@/services/api/institute/location'
export default {
	name: 'ModalAddChildLocation',
	mixins: [MixinModal],
	data() {
		return {
			modalTitle: '',
			originName: '',
			name: '',
			selectedInfo: undefined,
			instituteId: undefined,
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('location', ['selectedLocation']),
		...mapGetters('location', ['getSelectedStorage']),
		isAddLocation() {
			return this.modalType === 'ADD_LOCATION'
		},
		isEditLocation() {
			return this.modalType === 'EDIT_LOCATION'
		},
		isAddStorage() {
			return this.modalType === 'ADD_STORAGE'
		},
		isEditStorage() {
			return this.modalType === 'EDIT_STORAGE'
		},
		isEditMode() {
			return this.isEditLocation || this.isEditStorage
		},
	},
	methods: {
		show(type) {
			this.isShow = true
			this.modalType = type
			this.instituteId = this.$route.params.instituteId
			switch (type) {
				case 'ADD_LOCATION':
					this.selectedInfo = this.selectedLocation.info
					this.initName()
					break
				case 'EDIT_LOCATION':
					this.selectedInfo = this.selectedLocation.info
					this.setName()
					break
			}
		},
		initName() {
			this.name = ''
			this.originName = ''
		},
		setName() {
			this.name = this.selectedInfo.name
			this.originName = this.selectedInfo.name
		},
		showToast(title, content, type) {
			this.$root.toast(title, content, type)
		},
		async complete(a) {
			let title = ''
			let content = ''
			let type = ''
			let data = null
			let locationName = this.name.trim()

			if (locationName.length == 0) {
				this.showToast('위치 추가 실패', '위치명은 필수값입니다', 'error')
			} else {
				switch (this.modalType) {
					case 'ADD_LOCATION':
						data = {
							parentId: this.selectedInfo.id,
							name: this.name,
						}
						await locationAPI.createLocation(this.instituteId, data)
						title = this.tt('위치 추가 완료')
						content = this.tt('위치가 추가되었습니다')
						type = 'success'
						this.showToast(title, content, type)
						this.$emit('refresh', data.parentId)
						break
					case 'EDIT_LOCATION':
						data = {
							id: this.selectedInfo.id,
							name: this.name,
						}
						await locationAPI.updateLocation(this.instituteId, data)
						title = this.tt('위치 편집 완료')
						content = this.tt('위치가 편집되었습니다')
						type = 'success'

						this.showToast(title, content, type)
						this.$emit('refresh', this.selectedInfo.id)
						break
				}
				this.hide()
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
