var render = function () {
  var _vm$item$mngcode;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-item",
    "class": {
      "new": _vm.isNew
    },
    on: {
      "click": _vm.goToDetail
    }
  }, [_c('td', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('span', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, null) > -1 : _vm.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checked = $$c;
        }
      }
    }
  })])]), _vm.isView('mngCode') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.item.mngcode == null && _vm.item.isPostprocessing ? '생성중' : (_vm$item$mngcode = _vm.item.mngcode) !== null && _vm$item$mngcode !== void 0 ? _vm$item$mngcode : '-'))])])]) : _vm._e(), _vm.isView('category') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.categoryName))])])]) : _vm._e(), _vm.isView('status') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.type))])])]) : _vm._e(), _vm.isView('name') ? _c('td', [_c('div', {
    staticStyle: {
      "min-width": "250px",
      "max-width": "400px"
    }
  }, [_c('h6', {
    staticStyle: {
      "font-weight": "700"
    }
  }, [_vm._v(_vm._s(_vm.isEmpty(_vm.item.name)))]), _vm.item ? _c('div', {
    staticClass: "subtext"
  }, [_vm.item.brand && _vm.item.brand.name ? _c('span', [_vm._v(_vm._s(_vm.item.brand.name))]) : _vm._e(), _vm.item.subname ? _c('span', [_vm._v(_vm._s(_vm.item.subname))]) : _vm._e()]) : _vm._e()])]) : _vm._e(), _vm.isView('dumpMemo') ? _c('td', {
    staticStyle: {
      "height": "80px"
    }
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html",
      value: _vm.replaceAllText(_vm.dumpMemo, '<br />'),
      expression: "replaceAllText(dumpMemo, '<br />')",
      modifiers: {
        "hover": true,
        "html": true
      }
    }],
    staticClass: "content-wrap",
    staticStyle: {
      "min-width": "250px",
      "max-width": "370px"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceAllText(_vm.dumpMemo, '<br />'))
    }
  })])]) : _vm._e(), _vm.isView('assignee') ? _c('td', [_c('div', {
    staticClass: "profile-wrap",
    staticStyle: {
      "min-width": "100px"
    }
  }, [_c('img', {
    staticClass: "profile-img",
    attrs: {
      "src": _vm.assigneeImage
    }
  }), _vm.item.assignee && _vm.item.assignee.name ? _c('p', [_vm._v(_vm._s(_vm.tt(_vm.item.assignee.name)))]) : _vm._e()])]) : _vm._e(), _vm.isView('dumper') ? _c('td', [_c('div', {
    staticClass: "profile-wrap",
    staticStyle: {
      "min-width": "100px"
    }
  }, [_c('img', {
    staticClass: "profile-img",
    attrs: {
      "src": _vm.dumperImage
    }
  }), _c('p', [_vm._v(_vm._s(_vm.dumperName))])])]) : _vm._e(), _vm.isView('dumpDatetime') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.dumpDatetime))])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }