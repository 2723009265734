var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('fixed-header', {
    attrs: {
      "title": _vm.title,
      "subTitle": _vm.subTitle
    },
    on: {
      "changeLoadParams": _vm.changeLoadParams
    }
  }), _c('div', {
    staticClass: "table-wrapper",
    "class": {
      isInventoryList: _vm.isInventoryList
    }
  }, [_vm.isLoading ? _c('lm-modal-loading', {
    ref: "modal-loading",
    attrs: {
      "height": "100%",
      "play": _vm.isLoading,
      "backgroundColor": "#FFFFFFCC"
    }
  }) : [_c('inventory-table', {
    ref: "inventory-table",
    attrs: {
      "list": _vm.list,
      "previousPage": _vm.previousPage
    }
  }), _vm.selectedItems.length > 0 ? _c('floating-menu') : _vm._e()]], 2), _c('pagination', {
    ref: "pagination",
    attrs: {
      "propsOffset": _vm.loadParams.offset,
      "total": _vm.count
    },
    on: {
      "page": _vm.pagination
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }