var render = function () {
  var _domProps, _domProps2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "AddOrganizationGroupPopup"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Organization__add-group-popup Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_vm.isAddMode ? _c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(" " + _vm._s(_vm.tt('그룹 생성')) + " ")]) : _c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(" " + _vm._s(_vm.tt('그룹 정보 편집')) + " ")]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.hide
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [!_vm.isInstitute ? _c('div', {
    staticClass: "Popup__radio-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('그룹유형')))]), _c('div', {
    staticClass: "radio-form"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.type,
      expression: "groupInfo.type"
    }],
    attrs: {
      "type": "radio",
      "name": "LAB",
      "id": "LAB",
      "value": "LAB"
    },
    domProps: (_domProps = {
      "checked": _vm.isLab
    }, _domProps["checked"] = _vm._q(_vm.groupInfo.type, "LAB"), _domProps),
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.groupInfo, "type", "LAB");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "LAB"
    }
  }, [_c('span', [_c('span')]), _vm._v(_vm._s(_vm.tt('연구실')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.type,
      expression: "groupInfo.type"
    }],
    attrs: {
      "type": "radio",
      "name": "NORMAL",
      "id": "NORMAL",
      "value": "NORMAL"
    },
    domProps: (_domProps2 = {
      "checked": _vm.isNormal
    }, _domProps2["checked"] = _vm._q(_vm.groupInfo.type, "NORMAL"), _domProps2),
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.groupInfo, "type", "NORMAL");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "NORMAL"
    }
  }, [_c('span', [_c('span')]), _vm._v(_vm._s(_vm.tt('일반')))])])]) : _vm._e(), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('그룹명'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.name,
      expression: "groupInfo.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('그룹명을 입력해주세요'),
      "maxlength": "30"
    },
    domProps: {
      "value": _vm.groupInfo.name
    },
    on: {
      "change": _vm.changeName,
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.groupInfo, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('연락처')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupInfo.phone,
      expression: "groupInfo.phone"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('연락처를 입력해주세요'),
      "maxlength": "13"
    },
    domProps: {
      "value": _vm.groupInfo.phone
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.groupInfo, "phone", $event.target.value);
      }, _vm.autoHypen]
    }
  })]), _vm.isLab ? _c('div', {
    staticClass: "Popup__input-field Dropdown-input"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('연구실안전책임자')))]), _c('div', {
    on: {
      "click": function click($event) {
        return _vm.openModalSelectMember('safeResponsibility', _vm.assignee);
      }
    }
  }, [_c('input', {
    staticClass: "cursor",
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('연구실안전책임자를 선택해주세요'),
      "disabled": ""
    },
    domProps: {
      "value": _vm.checkInvalidAssignee(_vm.assignee)
    }
  })])]) : _vm._e(), _vm.isLab ? _c('div', {
    staticClass: "Popup__input-field Dropdown-input"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('연구실안전관리자')))]), _c('div', {
    on: {
      "click": function click($event) {
        return _vm.openModalSelectMember('safeAdmin', _vm.subAssignee);
      }
    }
  }, [_c('input', {
    staticClass: "cursor",
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('연구실안전관리자를 선택해주세요'),
      "disabled": ""
    },
    domProps: {
      "value": _vm.checkInvalidAssignee(_vm.subAssignee)
    }
  })])]) : _vm._e(), !_vm.isStart ? _c('div', {
    staticClass: "Popup__input-field deleteable-input-field Dropdown-input"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('위치')))]), _vm._l(_vm.locationList, function (item, index) {
    return _c('div', {
      key: "location_" + index
    }, [_c('input', {
      attrs: {
        "type": "text",
        "placeholder": _vm.tt('위치를 선택해주세요'),
        "readonly": ""
      },
      domProps: {
        "value": item.name
      },
      on: {
        "click": _vm.selectLocation
      }
    }), _c('button', {
      staticClass: "Btn-delete-item btn",
      on: {
        "click": function click($event) {
          return _vm.removeLocationList(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/recycle-bin.svg")
      }
    })])]);
  }), _c('button', {
    staticClass: "AppContainer__add-item-btn",
    on: {
      "click": _vm.addLocation
    }
  }, [_vm._v("+" + _vm._s(_vm.tt('추가하기')))])], 2) : _vm._e()]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])]), _c('portal-target', {
    attrs: {
      "name": "lm-modal-add-group"
    }
  }), _c('portal', {
    attrs: {
      "to": "lm-modal-add-group"
    }
  }, [_c('modal-select-member', {
    ref: "modal-select-member",
    attrs: {
      "isStart": _vm.isStart
    },
    on: {
      "selectMember": _vm.setSelectedMember
    }
  }), _c('modal-select-treeview', {
    ref: "modal-select-treeview",
    attrs: {
      "title": "위치 지정",
      "mode": "STORAGE",
      "treeData": _vm.treeData,
      "selectedList": _vm.locationIdList,
      "checkChild": false
    },
    on: {
      "submit": _vm.addStorage
    }
  })], 1)], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }