<template>
	<div class="user-wrapper">
		<h5>
			{{ tt('수령자 정보') }}
			<label
				><input type="checkbox" v-model="check" /> <b>{{ tt('주문자 정보와 동일') }}</b></label
			>
		</h5>
		<div class="order-user-wrapper">
			<input-text
				isRequired
				ref="recipientName"
				title="이름"
				:inputWidthSize="400"
				:inputHeightSize="36"
				placeholder="이름을 입력해주세요"
				:inputValue="$root.user.name"
				:inValidText="'이름을 입력해주세요'"
				:isValid="!isValid || !!$refs['recipientName'].value"
			></input-text>
			<input-text
				isRequired
				ref="recipientPhone"
				title="연락처"
				:inputWidthSize="400"
				:inputHeightSize="36"
				:inputValue="$root.user.phone"
				placeholder="연락처를 입력해주세요"
				:inValidText="'연락처를 입력해주세요'"
				:isValid="!isValid || !!$refs['recipientPhone'].value"
			></input-text>
			<input-text
				ref="deliveryRequest"
				title="배송요청사항"
				:inputWidthSize="400"
				:inputHeightSize="36"
				placeholder="최대 50자 입력이 가능합니다"
			></input-text>
		</div>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import InputText from '@/views/common/components/items/InputText.vue'
export default {
	props: {
		isValid: {
			type: Boolean,
		},
	},
	data() {
		return {
			check: false,
		}
	},
	watch: {
		check() {
			if (this.check) {
				this.$emit('getOrdererInfo')
				this.$refs['recipientName'].value = this.ordererInfo.ordererName
				this.$refs['recipientPhone'].value = this.ordererInfo.ordererPhone
			} else {
				this.$refs['recipientName'].value = ''
				this.$refs['recipientPhone'].value = ''
			}
		},
	},
	computed: {
		...mapState('draft/item', ['ordererInfo']),
		info() {
			return {
				recipientName: this.$refs['recipientName'].value,
				recipientPhone: this.$refs['recipientPhone'].value,
				deliveryRequest: this.$refs['deliveryRequest'].value,
			}
		},
	},
	components: {
		InputText,
	},
}
</script>
<style lang="scss" scoped>
h5 {
	font-weight: 700;
	label {
		cursor: pointer;
		margin-left: 16px;
		b {
			margin-left: 8px;
			font-size: 16px;
		}
	}
}
.order-user-wrapper {
	margin-top: 15px;
	padding: 20px;
	border: 1px solid $INPUT;
	border-radius: 5px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	.item-wrapper {
		border-bottom: 0px;
	}
}
</style>
