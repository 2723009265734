var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasComment,
      expression: "hasComment"
    }],
    staticClass: "recent-reason-wrap"
  }, [_c('div', {
    staticClass: "reason-header"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.tt('최근 입력한 사유')))])]), _c('div', _vm._l(_vm.commentList, function (comment, idx) {
    return _c('li', {
      key: idx,
      staticClass: "reason-wrap",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$emit('clickComment', comment.comment);
        }
      }
    }, [_c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.html",
        value: _vm.replaceAllText(comment.comment, '<br>'),
        expression: "replaceAllText(comment.comment, '<br>')",
        modifiers: {
          "hover": true,
          "html": true
        }
      }],
      domProps: {
        "innerHTML": _vm._s(_vm.replaceAllText(comment.comment, '<br>'))
      }
    }), _c('img', {
      attrs: {
        "src": require("@/assets/svg/Close-1.svg"),
        "width": "24",
        "height": "24"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.deleteComment(comment.id);
        }
      }
    })]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }