<template>
	<div class="button-list">
		<button class="toggle-button" :class="{ active: isButtonActive(item) }" v-for="item in list" :key="item.code" @click="setActive(item)">
			{{ tt(item.name) }}
		</button>
	</div>
</template>
<script>
export default {
	name: 'ButtonList',
	components: {},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			activeList: [],
		}
	},
	computed: {
		isButtonActive() {
			return item => this.activeList?.map(i => i.code).includes(item.code)
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		setActive(item) {
			this.activeList = !this.isButtonActive(item) ? [...this.activeList, item] : this.activeList.filter(c => c.code !== item.code)
			this.$emit('change', this.activeList)
		},
		setData(activeList) {
			this.activeList = activeList
		},
	},
}
</script>

<style lang="scss" scoped>
.button-list {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
	.toggle-button {
		display: flex;
		border: 1px solid $DIVIDER;
		border-radius: 8px;
		padding: 6px 8px;
		font-weight: 400;
		font-size: 12px;
		text-align: left;
		white-space: unset;
		&.active {
			border: 2px solid $LAB_YELLOW;
			border-radius: 8px;
			color: #ffa90b;
			background: #fec7411a;
			font-weight: 700;
		}
	}
}
</style>
