<template>
	<div class="info-field-wrap">
		<p class="field-name">{{ tt(fieldName) }}</p>
		<div class="field-wrap">
			<div class="field-text" v-if="isSticker" @click="$refs['modal-print-sticker'].show()">
				{{ tt('미리보기') }}
			</div>
			<div v-else class="field-text-none">-</div>
		</div>
		<modal-print-sticker ref="modal-print-sticker" :selectedCargoId="[itemId]"></modal-print-sticker>
	</div>
</template>
<script>
import ModalPrintSticker from '@/views/purchase/cargo/components/cargoList/modal/ModalPrintSticker.vue'
export default {
	name: 'ProductIdStickerField',
	components: { ModalPrintSticker },
	props: {
		fieldName: {
			type: String,
			default: '',
		},
		isRequire: {
			type: Boolean,
			default: false,
		},
		itemId: {
			type: [Number, String],
			default: null,
		},
		ghs: {
			type: Object,
			default: null,
		},
		qrSticker: {
			type: Array,
			default: null,
		},
		stickerType: {
			type: String,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		isSticker() {
			if (this.stickerType == null) return false
			if (this.stickerType == 'GHS+QR') return this.qrSticker?.length > 0 && !!this.ghs
			return this.qrSticker?.length > 0
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-field-wrap {
	padding-right: 10px;
	width: auto;
	.field-name {
		text-align: left;
		font-size: 12px;
		font-weight: 400;
		color: $SECONDARY_2;
		margin-bottom: 6px !important;
	}
	.field-wrap {
		display: flex;
		min-width: 280px;
		min-height: 20px;
		position: relative;
		flex-wrap: wrap;
		.field-text {
			gap: 22.5px;
			color: $BLUE;
			text-decoration: underline;
			font-size: 14px;
			font-weight: 400;
			text-align: left;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
		}
		.field-text-none {
			font-size: 14px;
			font-weight: 400;
			text-align: left;
		}
	}
	.field-data-wrap-red {
		width: 280px;
		height: 20px;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
		color: $RED;
	}
}
</style>
