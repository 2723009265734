<template>
	<button class="button-wrapper btn" :disabled="disabled" :class="[{ active: isActive, disabled: disabled }, size, color]" @click="handleClick">
		<slot></slot>
	</button>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: '', // sm , md , lg
		},
		color: {
			type: String,
			default: '', // primary - 노랑 , warning
		},
	},
	methods: {
		handleClick() {
			this.$emit('click')
		},
	},
}
</script>

<style lang="scss" scoped>
.button-wrapper {
	display: flex;
	background-color: $GREY_4;
	border-radius: 4px;
	height: 30px;
	padding: 5px 10px;
	font-size: 14px;
	font-weight: 700;
	gap: 5px;
	align-items: center;
	text-align: center;
	justify-content: center;
	&.active {
		border: 1px solid $TEXT_YELLOW;
		background-color: $SUB-YELLOW_200;
		.btn-text {
			color: $FONT_YELLOW2 !important;
		}
		.btn-subtext {
			color: $FONT_YELLOW2 !important;
		}
	}
	&.disabled {
		opacity: 0.2;
	}
	// TODO: css 설정
	// &.size-sm {
	// }
	// &.color-warning {
	// }

	// size
	&.sm {
		font-size: 12px;
		height: 20px;
	}
	&.lg {
		font-size: 16px;
		height: 40px;
	}
	// color
	&.warning {
		background: $SUB-RED;
		&.only {
			color: $RED;
		}
	}
	&.primary {
		background: $LAB_YELLOW;
		color: black;
		&.only {
			background: white;
			color: $LAB_YELLOW;
			border: 1px solid $LAB_YELLOW;
		}
	}
	&.accept {
		background: $GREEN;
		color: $LAB_WHITE;
	}
	&.reject {
		background: $RED;
		color: $LAB_WHITE;
	}
	&.lab-black {
		background: $LAB_BLACK;
		color: $LAB_WHITE;
	}
	&.white {
		background: $LAB_WHITE;
		color: rgba(0, 0, 0, 0.87);
		border: 1px solid $DIVIDER !important;
	}
}
</style>
