<template>
	<section class="AppHeader__container" :class="{ experience: isAppModeExperience }" v-if="subPath">
		<div class="AppHeader__left-header">
			<p @click="goInsitution">{{ headerPath }}</p>
			<span> / </span>
			<p
				:class="{
					active: detailPaths.length == 0 && !$route.params.name,
				}"
				@click="clickMenu"
			>
				{{ tt(subPath) }}
			</p>
			<b v-if="detailPaths.length > 0">&nbsp;/ {{ tt(detailPaths[0]) }}</b>
			<b v-else-if="$route.params.name">&nbsp;/ {{ $route.params.name }}</b>
		</div>
		<div class="AppHeader__right-header">
			<div class="btn-notice" @click="$root.managementLicense()"><img src="@/assets/svg/icon-billing.svg" /> {{ tt('라이선스') }}</div>
			<div class="btn-notice" @click="goNotice"><img src="@/assets/svg/icon-notice.svg" /> {{ tt('공지사항') }}</div>
			<app-notice></app-notice>
			<app-header-menu v-if="!isAppModeExperience"></app-header-menu>
		</div>
	</section>
</template>

<script>
import AppHeaderMenu from '@/views/app/components/AppHeaderMenu'
import AppNotice from '@/views/app/components/AppNotice'
import PageName from '@/utils/define/PageName'
import { mapMutations, mapState } from 'vuex'
import { menuList } from '@/utils/define/menu'
export default {
	name: 'AppHeader',
	components: { AppHeaderMenu, AppNotice },
	props: {
		instInfo: {
			type: Object,
			default: () => {},
		},
	},
	mounted() {
		if (this.storageDetailPaths) {
			this.detailPaths = this.storageDetailPaths
		} else {
			this.detailPaths = []
		}
		this.setFullName()
	},
	watch: {
		$route: {
			deep: true,
			handler(to, from) {
				if (this.subPath != null) {
					this.detailPaths = []
					this.setFullName()
				}
			},
		},
		storageDetailPaths: {
			deep: true,
			handler(newValue) {
				this.detailPaths = newValue
				this.setFullName()
			},
		},
	},
	computed: {
		...mapState('breadcrumb', ['storageDetailPaths']),
		headerPath() {
			if (this.instInfo) {
				return this.instInfo.name
			}
		},
		menuList() {
			return menuList
		},
		subPath() {
			let list = this.menuList
			let routePath = this.$route.path
			for (let item of list) {
				if (item.childList) {
					for (let child of item.childList) {
						if (child.subMenuList) {
							for (let sub of child.subMenuList) {
								if (sub.categoryType && this.$route.params.categoryType == sub.categoryType) {
									this.pagePath = sub.pageName
									return sub.menuTitle
								} else if (routePath.indexOf('waitingRegistration') >= 0) {
									this.pagePath = sub.pageName
									return sub.menuTitle
								}
							}
						}
						if (routePath.indexOf(this.slicePath(child.menuPath)) >= 0) {
							this.pagePath = child.pageName
							return child.menuTitle
						}
					}
				} else {
					if (routePath.indexOf(this.slicePath(item.menuPath)) >= 0) {
						this.pagePath = item.pageName
						return item.menuTitle
					}
				}
			}
		},
	},
	data() {
		return {
			detailPaths: [],
			pagePath: null,
			rightMenuOn: false,
		}
	},
	methods: {
		...mapMutations('breadcrumb', ['setPageName']),
		goInsitution() {
			this.$router.push({
				name: PageName.Institution.Information,
			})
		},
		slicePath(path) {
			if (path) {
				return path.replace('/:instituteId', '')
			}
		},
		clickMenu() {
			if (this.pagePath) {
				this.$router.push({
					name: this.pagePath,
				})
			} else {
				window.location.reload()
			}
		},
		goNotice() {
			window.open('https://www.lab-manager.com/blog/notice')
		},
		setFullName() {
			let pageName = this.subPath
			if (this.detailPaths.length > 0) pageName += '/' + this.detailPaths[0]
			else if (this.$route.params.name) pageName += '/' + this.$route.params.name
			this.setPageName(pageName)
		},
	},
}
</script>

<style lang="scss" scoped>
.AppHeader__container {
	display: flex;
	position: fixed;
	min-width: 600px;
	width: calc(100% - 250px);
	left: 250px;
	background-color: white;
	padding: 10px 30px;
	font-size: 13px;
	height: 60px;
	z-index: -1;
	color: #000;
	border-bottom: 1px solid $DIVIDER;
	&.right-menu-on {
		z-index: 2;
	}
	&.notice-on {
		z-index: 2;
	}
	&.experience {
		top: 80px;
	}
}

.AppHeader__left-header {
	display: flex;
	width: 80%;
	height: 100%;
	background-color: white;
	text-align: left;
	align-items: center;
	p {
		display: inline-block;
		cursor: pointer;
		white-space: nowrap;
		margin-bottom: 0px;
		&.active {
			font-weight: 700;
			user-select: none;
			pointer-events: none;
			text-decoration: none;
		}
	}
	span {
		margin: 0 3px;
	}
	a {
		display: inline-block;
		&.active {
			font-weight: 700;
			user-select: none;
			pointer-events: none;
			text-decoration: none;
		}
	}
	b {
		white-space: nowrap;
		max-width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.AppHeader__right-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 50%;
	height: 100%;
	text-align: right;
	gap: 5px;
	font-size: 0.875rem;
	color: #000;
	font-weight: 700;
	.btn-notice {
		display: flex;
		align-items: center;
		border-radius: 8px;
		padding: 10px;
		cursor: pointer;
		white-space: nowrap;
		img {
			margin-right: 5px;
		}
		&:hover {
			background-color: $GREY_2;
		}
	}
}
</style>
