var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-header"
  }, [_c('th', {
    "class": _vm.orderClass('STATUS'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('STATUS');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('결재상태')))])]), _vm.isView('no') ? _c('th', {
    "class": _vm.orderClass('NO'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('NO');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('문서번호')))])]) : _vm._e(), _c('th', {
    "class": _vm.orderClass('SUBJECT'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('SUBJECT');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('기안 제목')))])]), _vm.isView('budgetName') ? _c('th', {
    "class": _vm.orderClass('BUDGET'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('BUDGET');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('예산 이름')))])]) : _vm._e(), _vm.isView('purchaseType') ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('구매방식')))])]) : _vm._e(), _vm.isView('group') ? _c('th', {
    "class": _vm.orderClass('GROUP'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('GROUP');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('기안 연구실')))])]) : _vm._e(), _vm.isView('register') ? _c('th', {
    "class": _vm.orderClass('REGISTER'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('REGISTER');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('기안자')))])]) : _vm._e(), _c('th', {
    "class": _vm.orderClass('CREATE_DATETIME'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('CREATE_DATETIME');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('기안일시')))])]), _vm.isView('concludeDatetime') ? _c('th', {
    "class": _vm.orderClass('CONCLUDE_DATETIME'),
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.changeOrder('CONCLUDE_DATETIME');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('결재완료일시')))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }