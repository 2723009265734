<template>
	<div class="product-info-table-wrap" ref="scroll-container" @scroll="onScroll">
		<table>
			<tr class="table-header">
				<th>
					<span>{{ tt('판매자') }}</span>
				</th>
				<th>
					<span>{{ tt('SKU') }}</span>
				</th>
				<th>
					<span>{{ tt('옵션') }}</span>
				</th>
				<th>
					<span>{{ tt('재고') }}</span>
				</th>
				<th>
					<span>{{ tt('가격') }}</span>
				</th>
				<th>
					<span>{{ tt('배송정보') }}</span>
				</th>
				<th>
					<span>{{ tt('추가') }}</span>
				</th>
			</tr>
			<tr class="table-item" v-for="(item, index) in productList" :key="index" @click="goStoreDetail(item.productInfoId)">
				<td>
					<div class="imgbox">
						<img :src="item.seller && item.seller.image ? item.seller.image : require('@/assets/svg/member-photo-default.svg')" />
					</div>
				</td>
				<td>
					<div>
						<span>{{ tt(item.sku) }}</span>
					</div>
				</td>
				<td>
					<div>
						<span> {{ tt('사이즈(용량)') }} : {{ tt(`${item.options[0] ? item.options[0].value : ''}`) }} </span>
					</div>
				</td>
				<td>
					<div>
						<span>{{ tt('${1}개', item.stock) }}</span>
					</div>
				</td>
				<td>
					<div>
						<span :class="{ lowest: lowest == item.unitPrice }">{{ tt('${1}원', CommaNum(item.sellingUnitPrice)) }}</span>
						<p v-if="item.discountUnitPrice" style="font-weight: 400; color: #999; text-decoration: line-through">
							{{ tt('${1}원', CommaNum(item.unitPrice)) }}
						</p>
					</div>
				</td>
				<td>
					<div>
						<p>{{ tt(item.deliveryFee ? '유료배송' : '무료배송') }}</p>
						<span
							style="font-size: 12px; font-weight: 700"
							:class="[
								{
									quick: quick === item.shippingDay,
								},
								{ noDate: quick !== item.shippingDay && !item.shippingDay },
							]"
							>{{ tt(shippingStatus(item.shippingDay)) }}</span
						>
					</div>
				</td>
				<td>
					<div>
						<button class="button-primary" @click.stop="showAddCount($event, index, item.stock)">{{ tt('추가') }}</button>
					</div>
				</td>
			</tr>
		</table>
		<modal-add-product ref="modal-add-product" @hide="$emit('addHide')" @confirm="addProduct"></modal-add-product>
		<modal-add-product-confirm ref="modal-add-product-confirm" @confirm="confirm"></modal-add-product-confirm>
		<modal-confirm
			ref="modal-add"
			title="상품의 재고보다 많은 수량을 추가합니다."
			:confirmText="'상품 추가'"
			content="재고부족으로 판매자가 주문을 취소하거나, 부분출고 될 수 있습니다.<br>상품을 추가하시겠습니까?"
			@confirm="addItem"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import ModalAddProduct from './ModalAddCount.vue'
import ModalAddProductConfirm from './ModalAddProductConfirm.vue'
import MixinScrolling from '@/mixins/scrolling'
export default {
	components: {
		ModalAddProduct,
		ModalAddProductConfirm,
	},
	mixins: [MixinScrolling],
	props: {
		// list: {
		// 	type: Array,
		// 	default: () => [],
		// },
	},
	data() {
		return {
			selectedIndex: 0,
			selectedCount: 0,
		}
	},
	watch: {
		isBottom(value) {
			if (value) {
				this.getProductList(false)
			}
		},
	},
	computed: {
		...mapState('draft/form', ['selectedItem', 'productList']),
		...mapGetters('draft/form', ['lowest', 'quick']),
	},
	methods: {
		...mapActions('draft/form', ['getProductList']),
		...mapActions('draft/item', ['setPurchaseItems']),
		CommaNum(num) {
			if (!num) return 0
			return num.toLocaleString('ko-KR')
		},
		goStoreDetail(id) {
			// TODO - 랩매니저 스토어 옵션 선택된 상세 화면
			this.$root.goToStore(`product/list/detail/${id}`)
		},
		showAddCount(e, index, stock) {
			this.$emit('addShow')
			this.$refs['modal-add-product'].show(
				e.target.getBoundingClientRect().right + 162,
				e.target.getBoundingClientRect().top - 167,
				index,
				stock
			)
		},
		shippingStatus(value) {
			if (value === 0) return this.tt('당일출고')
			else if (value) return this.tt('약 ${1}일 이내 출고예정', value)
			else return this.tt('판매자 문의필요')
		},
		addProduct(index, quantity) {
			this.selectedIndex = index
			this.selectedCount = quantity

			this.setProduct()
			this.$refs['modal-add-product-confirm'].show()

			// if (quantity > this.productList[this.selectedIndex].stock) {
			// 	this.$refs['modal-add'].show()
			// } else {
			// 	this.setProduct()
			// 	this.$refs['modal-add-product-confirm'].show()
			// }
		},
		addItem() {
			this.setProduct()
			this.$refs['modal-add'].hide()
			this.$refs['modal-add-product-confirm'].show()
		},
		confirm() {
			this.$emit('confirm')
			this.$root.toast(this.tt('상품 추가 완료'), this.tt('입력한 상품이 추가되었습니다.'), 'success')
		},
		setProduct() {
			let selectItem = {
				quantity: this.selectedCount,
			}
			Object.entries(this.selectedItem).forEach(([key, value]) => {
				if (key === 'code') {
					selectItem['productCode'] = value
				} else if (value != null && value != undefined && key != 'productInfos') {
					selectItem[key] = value
				}
			})
			Object.entries(this.productList[this.selectedIndex]).forEach(([key, value]) => {
				if (key === 'seller') {
					selectItem[key] = value
					// selectItem['sellerName'] = value.name
				} else if (key === 'options') {
					selectItem['volume'] = parseFloat(value[0].value)
					selectItem['volumeUnit'] = value[0].value.replace(/[^a-z]/gi, '')
				} else if (key === 'sellingUnitPrice') {
					selectItem[key] = value
					selectItem['sellingPrice'] = value * this.selectedCount
				} else if (key === 'discountUnitPrice') {
					selectItem[key] = value
					selectItem['discountPrice'] = value * this.selectedCount
				} else if (value != null && value != undefined) {
					selectItem[key] = value
				}
			})

			this.setPurchaseItems(selectItem)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
@import '@/styles/scss/table/_table-item.scss';

::v-deep .table-header {
	box-shadow: none;
	border-bottom: 1px solid $DIVIDER;
}
.product-info-table-wrap {
	display: inline-block;
	min-height: 210px;
	text-align: left;
	overflow: auto;
	white-space: nowrap;
	table {
		th {
			& > span,
			div {
				min-width: 50px;
				max-width: 250px;
			}
		}
		td {
			& > div {
				position: relative;
				min-width: 50px;
				max-width: 250px;
				p {
					font-weight: 700;
				}
			}
		}
	}

	.imgbox {
		background-color: white;
		width: 120px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		overflow: hidden;
		padding: 0 10px;
		margin-right: 10px;
		img {
			width: 100%;
			height: inherit;
			object-fit: contain;
		}
	}
}
span {
	&.lowest {
		padding-left: 14px;
		background-image: url(~@/views/common/components/modalGen2/svg/low-price-blue.svg);
		background-repeat: no-repeat;
		background-position: center left 0;
		background-size: 12px;
		color: $BLUE;
		font-weight: 700;
	}
	&.quick {
		padding-left: 17px;
		background-image: url(~@/views/common/components/modalGen2/svg/time-blue.svg);
		background-repeat: no-repeat;
		background-position: center left 0;
		color: $BLUE;
		font-weight: bold !important;
	}
	&.noDate {
		font-weight: 400 !important;
		color: $PLACEHOLDER;
	}
}
</style>
