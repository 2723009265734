var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.tt('물품 소분하기')) + " ")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('modal-footer', {
          on: {
            "cancel": function cancel($event) {
              return _vm.$refs['modal'].hide();
            },
            "ok": _vm.complete
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "body-wrap"
  }, [_c('div', {
    staticClass: "body-header-wrap"
  }, [_c('div', {
    staticClass: "body-header",
    "class": {
      error: _vm.error
    }
  }, [_c('span', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.item.name) + " ")]), _c('span', {
    staticClass: "remain-volume",
    "class": [{
      green: _vm.isValidVolume,
      red: !_vm.isValidVolume
    }]
  }, [_vm._v(" " + _vm._s("".concat(_vm.tt('잔여 용량'), ": ").concat(_vm.remainVolume.toLocaleString()).concat(_vm.item.volumeUnit)) + " ")])]), _vm.error ? _c('span', {
    staticClass: "error-msg"
  }, [_vm._v(_vm._s(_vm.tt('잔여 용량을 초과하였습니다.')))]) : _vm._e()]), _c('div', {
    staticClass: "divide-form-wrap"
  }, [_c('div', {
    staticClass: "divide-form-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.tt('용량')))]), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.tt('개수')))])]), _vm._l(_vm.data, function (d, idx) {
    return _c('div', {
      key: d.key,
      staticClass: "divide-form"
    }, [_c('form-divide', {
      attrs: {
        "placeholder": "용량을 입력해주세요",
        "value": d.volume,
        "unit": _vm.item.volumeUnit
      },
      on: {
        "input": function input(volume) {
          return _vm.changeVolume(volume, idx);
        }
      }
    }), _c('div', {
      staticClass: "cross-wrap"
    }, [_c('img', {
      staticClass: "divide-cross",
      attrs: {
        "width": "12",
        "height": "12",
        "src": require("@/views/inventoryGen2/svg/detail/divide-cross-icon.svg")
      }
    })]), _c('form-divide', {
      attrs: {
        "placeholder": "개수를 입력해주세요",
        "value": d.count,
        "unit": "개"
      },
      on: {
        "input": function input(count) {
          return _vm.changeCount(count, idx);
        }
      }
    }), _c('button', {
      staticClass: "button-gray trash",
      attrs: {
        "disabled": !_vm.canRemoveItem
      },
      on: {
        "click": function click($event) {
          return _vm.removeForm(idx);
        }
      }
    }, [_vm.canRemoveItem ? _c('img', {
      attrs: {
        "src": require("@/views/inventoryGen2/svg/detail/icon-trash.svg"),
        "width": "24",
        "height": "24"
      }
    }) : _c('img', {
      attrs: {
        "src": require("@/assets/svg/icon/icon-trash-disable.svg"),
        "width": "24",
        "height": "24"
      }
    })])], 1);
  })], 2), _c('button', {
    staticClass: "btn-add",
    on: {
      "click": _vm.addForm
    }
  }, [_vm._v(_vm._s("+ ".concat(_vm.tt('추가하기'))))])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }