var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " "), _c('img', {
          staticClass: "question",
          attrs: {
            "width": "24",
            "height": "24",
            "src": require("@/views/purchase/cargo/svg/icon-round-question.svg")
          },
          on: {
            "click": function click($event) {
              return _vm.$refs['modal-describe'].show();
            }
          }
        })])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('button', {
          staticClass: "button-primary",
          on: {
            "click": function click($event) {
              return _vm.exportPdf();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "body"
  }, [_c('table', [_c('table-header'), _c('tbody', _vm._l(_vm.previewData, function (item, idx) {
    return _c('table-item', {
      key: item.id,
      ref: "item",
      refInFor: true,
      attrs: {
        "item": item,
        "idx": idx + 1
      }
    });
  }), 1)], 1)]), _c('a', {
    attrs: {
      "href": "https://www.law.go.kr/LSW/admRulLsInfoP.do?admRulSeq=2100000049106",
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "btm-describe",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('GHS+QR 스티커는 <b class=\'underline\'>「위험물안전관리법」시행규칙 별표 19</b>에 의하여 지정된 법령규격으로 출력됩니다.'))
    }
  })])], _c('modal-describe-sticker', {
    ref: "modal-describe"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }