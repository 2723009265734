var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-header"
  }, [_c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('정보')))])]), _vm.isChemical ? _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('CAS No')))])]) : _vm._e(), _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('제품번호')))])]), _c('th', [_c('span', [_vm._v(_vm._s(_vm.tt('브랜드')))])]), _vm.isChemical ? _c('th', [_vm.isChemical ? _c('span', [_vm._v(_vm._s(_vm.tt('물품 사이즈(용량) 종류')))]) : _c('span', [_vm._v(_vm._s(_vm.tt('패키지(소모품) 수량 종류')))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }