import cookies from 'vue-cookies'
import store from '@/store/index'

const _baseAccountURL = process.env.VUE_APP_BASE_ACCOUNT_URL
const _baseURLExceptHttp = process.env.VUE_APP_BASE_URL_EXCEPT_HTTP
const _storeURL = process.env.VUE_APP_STORE_URL
const COOKIES_DOMAIN = location.hostname.includes('lab-manager.com') ? _baseURLExceptHttp : location.hostname

const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY
const LAB_KEY = process.env.VUE_APP_LAB_KEY
// const FILTER_KEY = process.env.VUE_APP_FILTER_KEY;
// const STORAGE_ID_KEY = process.env.VUE_APP_STORAGE_ID_KEY;
// const STORAGE_NAME_KEY = process.env.VUE_APP_STORAGE_NAME_KEY;
const SERVICE_URL = process.env.VUE_APP_SERVICE_URL
const LEGACY_SERVICE_URL = process.env.VUE_APP_LEGACY_SERVICE_URL

cookies.config(Infinity, null, COOKIES_DOMAIN)
const isLogin = () => {
	return !cookies.isKey(TOKEN_KEY) ? false : true
}

const getAccountUrl = () => {
	return _baseAccountURL
}

const storeUrl = () => {
	return _storeURL
}

const getCurrentLabId = () => {
	return null
}

const getServiceUrl = () => {
	return SERVICE_URL
}

const getLegacyServiceUrl = () => {
	return LEGACY_SERVICE_URL
}

const setToken = token => {
	cookies.set(TOKEN_KEY, token)
}

const INSTITUTE_ID_KEY = 'instituteId'
const setCookieInstituteId = instituteId => {
	cookies.set(INSTITUTE_ID_KEY, instituteId)
}

const getToken = () => {
	return cookies.get(TOKEN_KEY)
}

const logout = () => {
	deleteLocalStorage()
	deleteAllCookies()
}
const deleteLocalStorage = () => {
	localStorage.clear()
}

const deleteAllCookies = () => {
	cookies.keys().forEach(key => {
		// TODO : 서비스에서 사용하는 쿠키값만 삭제해야함. .lab-manager.com 도메인에 다른 서비스에서 넣는 쿠키값이 있는데 로그아웃시 모두 함께 삭제되고 있음
		if (!key.includes('utm') && !key.includes('dontShow7days')) cookies.remove(key)
	})
	cookies.remove(TOKEN_KEY)
	cookies.remove(LAB_KEY)
}

const goToSSOMain = url => {
	logout()
	location.replace(`${_baseAccountURL}/SignIn?redirect=${url ? encodeURIComponent(url) : window.location.origin}&pro=true`)
}

const goToSSOEmail = () => {
	location.replace(`${_baseAccountURL}/pro/EmailAuth?redirect=${window.location.origin}&pro=true&token=${getToken()}`)
}

const goToInstitute = () => {
	const instId = cookies.get(INSTITUTE_ID_KEY)
	location.replace(`${SERVICE_URL}/${instId}/institute/institutionInformation`)
}

const hasInstitutionId = () => {
	return store.getters['user/hasInstitutionId']
}

const exproUrl = 'https://ex-pro.lab-manager.com'

const loginCheck = (from, to, next) => {
	if (isLogin()) {
		next()
	} else {
		location.replace(`${_baseAccountURL}/SignIn?redirect=${window.location.origin}&pro=true`)
	}
}

export {
	isLogin,
	getAccountUrl,
	storeUrl,
	getCurrentLabId,
	setToken,
	getToken,
	logout,
	deleteLocalStorage,
	deleteAllCookies,
	getServiceUrl,
	getLegacyServiceUrl,
	goToSSOMain,
	goToSSOEmail,
	hasInstitutionId,
	COOKIES_DOMAIN,
	exproUrl,
	loginCheck,
	INSTITUTE_ID_KEY,
	setCookieInstituteId,
	goToInstitute,
}
