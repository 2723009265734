<template>
	<div id="TreeView">
		<DraggableTree :data="treeData" :width="`${width}px`" height="100%" :draggable="draggable" @click="click" @change="change" ref="tree">
			<div
				slot-scope="{ data, store }"
				class="checker"
				@dragenter.stop=";(isStoragePage || isStartPage) && storageDragenter($event, data)"
				@dragover.stop=";(isStoragePage || isStartPage) && storageDragover($event, data)"
				@dragleave.stop=";(isStoragePage || isStartPage) && storageDragleave($event, data)"
				@drop.stop=";(isStoragePage || isStartPage) && moveStorage($event, data)"
				:id="`checker_${data.id}`"
			>
				<template v-if="!data.isDragPlaceHolder">
					<button
						v-if="data[childrenField] && data[childrenField].length"
						@click="open($event, store, data)"
						:class="data.open ? 'unfold' : 'folded'"
					>
						{{ data.open ? '-' : '+' }}&nbsp;
						<!-- 플러스 마이너스 버튼영역 -->
					</button>
					<span class="icon" v-if="(page == 'organization' || modal == 'group') && isRoot(data.id)" style="margin-left: 15px">
						<img src="@/assets/svg/institution-building_y.svg" class="instituteImage" />
					</span>
					<span class="icon" v-else-if="(page == 'organization' || modal == 'group') && !isRoot(data.id)" style="margin-left: 15px">
						<img src="@/assets/svg/group_y.svg" />
					</span>
					<span class="icon" v-else-if="page == 'location' || (modal == 'location' && !data.storage)" style="margin-left: 15px">
						<img src="@/assets/svg/location_y.svg" />
					</span>
					<span class="icon storage" v-if="page == 'storage' || (data.storage && modal == 'location')" style="margin-left: 15px">
						<img src="@/assets/svg/storage_y.svg" />
					</span>
					<div
						class="node-text"
						:class="{
							'root-text': isRoot(data.id),
							storage: data.storage,
						}"
						v-html="setMarkTag(data[textField])"
					></div>
					<div class="node-sub-text" v-if="isNotUndefined(data[subTextField])">{{ data[subTextField] }}{{ subTextEndWord }}</div>
					<div class="new-img" v-if="page && data.isNew">
						<img src="@/assets/svg/new.svg" />
					</div>
					<!--멤버 관리 중 그룹 지정 시 최상단 기관은 선택불가(isRoot)-->
					<input
						v-if="
							(mode === 'selectable' && (!isRoot(data.id) || modal == 'location')) ||
							(mode === 'multiStorage' && data.storage) ||
							(mode === 'STORAGE' && modal === 'location' && !isRoot(data.id))
						"
						type="checkbox"
						:id="`treeviewCheckbox${data.id}`"
						:value="data.id"
						:checked="activeIdList.includes(data.id)"
					/><label
						v-if="
							(mode === 'selectable' && (!isRoot(data.id) || modal == 'location')) ||
							(mode === 'multiStorage' && data.storage) ||
							(mode === 'STORAGE' && modal === 'location' && !isRoot(data.id))
						"
						@click="click(store, data)"
						:for="`treeviewCheckbox${data.id}`"
						class="checkboxLabel"
					></label>
					<input
						v-if="(mode === 'storage' && data.storage) || mode === 'storageGroup'"
						type="radio"
						:id="`treeviewCheckbox${data.id}`"
						:value="data.id"
						:checked="activeIdList.includes(data.id)"
					/><label
						v-if="(mode === 'storage' && data.storage) || mode === 'storageGroup'"
						@click="click(store, data)"
						:for="`treeviewCheckbox${data.id}`"
						class="radioLabel"
					></label>
				</template>
			</div>
		</DraggableTree>
	</div>
</template>

<script>
import { DraggableTree } from '@/views/common/components/tree/CustomDraggableTree'
import StringUtils from '@/utils/modules/StringUtils'
import MixinStorageItemDraggable from '@/mixins/storageItemDraggable'
import { isClickedInstitution } from '@/store/modules/isClickedItem'

export default {
	mixins: [MixinStorageItemDraggable],
	async created() {},
	async mounted() {
		await this.$emit('load')
		if (this.activeIdList.length > 0) {
			this.$refs['tree'].activeNode(this.$refs['tree'].getNodeById(this.activeIdList[0]))
		}
	},
	components: {
		DraggableTree,
	},
	props: {
		// 이름으로 사용할 필드 이름
		textField: {
			type: String,
			default: 'text',
		},

		// 회색글씨로 나타낼 subname 필드 이름
		subTextField: {
			type: String,
			default: 'subText',
		},

		// 회색글씨 subname 어미
		subTextEndWord: {
			text: String,
			default: '',
		},

		// 자식 노드 필드 명
		childrenField: {
			type: String,
			default: 'children',
		},

		// 트리 데이터
		treeData: {
			type: Array,
			default: function () {
				return []
			},
		},

		// 트리 모드 "active" 및 "selectable" 및 "storage"
		mode: {
			type: String,
			default: 'active',
		},

		// 선택 모드일 시, 최대 선택 갯수
		limitSelectable: {
			type: Number,
			default: 9999,
		},

		// 선택 모드일시, 부모 노드 선택시 자식노드도 함께 선택되는 여부
		selectWithChild: {
			type: Boolean,
			default: true,
		},

		// drag를 통해 데이터 변경 가능여부
		draggable: {
			type: Boolean,
			default: true,
		},

		// 트리 검색어
		keyword: {
			type: String,
			default: '',
		},
		// 트리의 너비
		width: {
			type: Number,
			default: 450,
		},
		isStoragePage: {
			type: Boolean,
			default: false,
		},
		//페이지 이름
		page: {
			type: String,
			default: '',
		},
		//data 종류
		modal: {
			type: String,
			default: '',
		},
	},
	watch: {
		activeIdList() {
			const nodeArr = []
			this.activeIdList.forEach(id => {
				nodeArr.push(this.$refs['tree'].getNodeById(id))
			})
			this.$emit('changeActive', this.activeIdList, nodeArr)
		},
		keyword() {
			this._search()
		},
		treeData: {
			immediate: true,
			handler(data, old) {
				this._setClosedNode(data)
			},
		},
	},
	data: () => {
		return {
			activeIdList: [],
			initialIdList: [],
			activeId: '',
		}
	},
	computed: {
		// opendList() {
		// 	return this.getAllList()
		// },
		isStartPage() {
			return this.$route.name === 'startPro'
		},
	},
	methods: {
		//열려있는 모든 데이터 가져오기
		//todo : 모든 new list 확인 시 new icon 숨기는 flow 추가 시 복구
		// getAllList(tree, result) {
		// 	if (!tree) {
		// 		tree = this.treeData
		// 		result = []
		// 	}

		// 	tree.forEach(node => {
		// 		if (this.activeIdList.includes(node.id)) {
		// 			node.active = true
		// 		}

		// 		result.push(node)
		// 		if (this.page) {
		// 			if (
		// 				node.isNew &&
		// 				!isClickedInstitution[this.page].includes(node.id)
		// 			) {
		// 				isClickedInstitution[this.page].push(node.id)
		// 			}
		// 			localStorage.setItem(
		// 				this.page,
		// 				isClickedInstitution[this.page].join()
		// 			)
		// 		}

		// 		if (node.children && node.children.length > 0) {
		// 			this.getAllList(node.children, result)
		// 		}
		// 	})

		// 	return result
		// },
		// isClickedItem(id) {
		// 	let result = false

		// 	if (this.page) {
		// 		let clickedList = localStorage.getItem(`clicked${this.page}`)
		// 			? localStorage.getItem(`clicked${this.page}`).split(',')
		// 			: []
		// 		if (clickedList.includes(id)) {
		// 			result = true
		// 		}
		// 	}

		// 	return result
		// },
		setMarkTag(str) {
			if (str) return StringUtils.setSearchMarkTag(str, this.keyword)
		},
		isRoot(id) {
			let isRoot = false

			for (let i = 0, len = this.treeData.length; i < len; i++) {
				if (this.treeData[i].id === id) {
					isRoot = true
				}
			}

			return isRoot
		},
		open(event, store, data) {
			event.stopPropagation()

			store.toggleOpen(data)

			if (!data.open) {
				this._addClosedNode(data.id)
			} else {
				this._removeClosedNode(data.id)
			}
		},
		click(store, data) {
			//todo : 모든 new list 확인 시 new icon 숨기는 flow 추가 시 복구
			// if (this.page) {
			// 	if (
			// 		data.isNew &&
			// 		!isClickedInstitution[`clicked${this.page}`].includes(
			// 			data.id
			// 		)
			// 	) {
			// 		isClickedInstitution[`clicked${this.page}`].push(data.id)
			// 	}
			// 	localStorage.setItem(
			// 		`clicked${this.page}`,
			// 		isClickedInstitution[`clicked${this.page}`]
			// 	)
			// }
			if (this.mode === 'storage') {
				if (data.storage != undefined) {
					store.activeNode(data, true)
					this.activeId = data.id
					this.$emit('changeActive', data.id, data)
				}
			} else if (this.mode === 'storageGroup') {
				store.activeNode(data, true)
				this.activeIdList[0] = data.id
				this.activeId = data.id
				this.$emit('changeActive', data.id, data)
			} else if (this.mode === 'active') {
				store.activeNode(data, true)
				this.activeIdList[0] = data.id
				this.$emit('changeActive', data.id, data)
			} else if (this.mode === 'multiStorage') {
				if (data.storage != undefined) {
					if (this.activeIdList.indexOf(data.id) >= 0) {
						this._deactiveNode(data.id)
					} else {
						if (this.limitSelectable >= this.activeIdList.length) {
							this._activeNode(data.id)
						}
					}
					this.$emit(
						'changeActive',
						this.activeIdList,
						this.activeIdList.map(id => this.$refs['tree'].getNodeById(id))
					)
				}
			} else {
				console.log(data)
				//멤버 그룹 지정 시 최상단이 기관일 경우 선택 불가
				if (this.isRoot(data.id)) {
					return false
				}

				if (this.activeIdList.indexOf(data.id) >= 0) {
					this._deactiveNode(data.id)

					if (this.selectWithChild) {
						const childIdList = this._getAllChild(data, true, true)
						childIdList.forEach(childId => {
							if (childId) {
								this._deactiveNode(childId)
							}
						})
					}
				} else {
					if (this.limitSelectable >= this.activeIdList.length) {
						this._activeNode(data.id)
					}

					if (this.selectWithChild) {
						const childIdList = this._getAllChild(data, false, true)
						if (this.limitSelectable >= this.activeIdList.length + childIdList.length) {
							childIdList.forEach(childId => {
								if (childId) {
									this._activeNode(childId)
								}
							})
						}
					}
				}

				if (this.selectWithChild) {
					this._parentCheck(data.parent)
				}
				this.$emit(
					'changeActive',
					this.activeIdList,
					this.activeIdList.map(id => this.$refs['tree'].getNodeById(id))
				)
			}
		},
		_activeNode(id) {
			this.$refs['tree'].activeNode(this.$refs['tree'].getNodeById(id))
			if (this.activeIdList.indexOf(id) < 0) {
				this.activeIdList.push(id)
			}
		},
		_deactiveNode(id) {
			this.$refs['tree'].deactiveNode(this.$refs['tree'].getNodeById(id))
			if (this.activeIdList.indexOf(id) >= 0) {
				this.activeIdList.remove(id)
			}
		},
		_parentCheck(parent) {
			if (parent.isRoot) {
				return
			}
			// 부모의 현재 상태
			// 자식들의 상태 체크
			const parentActive = !!parent.active // 부모의 현재 상태
			let isAllChildActive = true
			let isAllChildDeactive = true
			const childIdList = this._getAllChild(parent, false, true)

			childIdList.forEach(childId => {
				if (!this.$refs['tree'].getNodeById(childId).active) {
					isAllChildActive = false
				}
			})

			childIdList.forEach(childId => {
				if (this.$refs['tree'].getNodeById(childId).active) {
					isAllChildDeactive = false
				}
			})

			if (isAllChildActive && !parentActive) {
				this._activeNode(parent.id)
			} else if (isAllChildDeactive && parentActive) {
				this._deactiveNode(parent.id)
			}

			this._parentCheck(parent.parent)
		},
		_getAllParent(data) {
			let idList = []
			if (data.parent && !data.parent.isRoot) {
				idList.push(data.parent.id)
				idList = idList.concat(this._getAllParent(this.$refs['tree'].getNodeById(idList[0])))
			}

			return idList
		},
		_getAllChild(data, active, deep = true) {
			let idList = data.children.map(d => {
				if (typeof active === 'undefined') {
					return d.id
				} else {
					if (active) {
						if (d.active) {
							return d.id
						}
					} else {
						if (!d.active) {
							return d.id
						}
					}
				}
			})

			if (deep) {
				data.children.forEach(d => {
					idList = idList.concat(this._getAllChild(d, active))
				})
			}

			return idList.filter(id => typeof id !== 'undefined')
		},
		_search() {
			const leafList = this._getAllChild(this.$refs['tree'].data[0], undefined, true)
				.map(id => {
					const node = this.$refs['tree'].getNodeById(id)
					return node
				})
				.filter(data => {
					return typeof data !== 'undefined'
				})
			if (this.keyword) {
				console.warn(this.keyword)
				leafList.reverse().forEach(data => {
					data.class = 'hide'
				})
				let withResultCount = 0
				leafList.reverse().forEach(data => {
					if (data.name.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0) {
						data.class = ''
						const parentList = this._getAllParent(data)
						parentList.forEach(parentId => {
							this.$refs['tree'].getNodeById(parentId).class = ''
						})

						const childList = this._getAllChild(data, undefined, true)
						childList.forEach(childId => {
							this.$refs['tree'].getNodeById(childId).class = ''
						})
						withResultCount++
					} else {
						this._deactiveNode(data.id)
					}
				})

				if (withResultCount == 0) {
					this.$emit('withResult', false)
				} else {
					this.$emit('withResult', true)
				}
			} else {
				this.$emit('withResult', true)
				leafList.reverse().forEach(data => {
					data.class = ''
				})
			}
		},
		change(node, dropendParent) {
			this.$emit('changeTree', node, dropendParent)
		},
		isNotUndefined(value) {
			if (typeof value === 'undefined') {
				return false
			}

			return true
		},
		setActiveId(activeId) {
			this.activeId = activeId
			this.activeIdList[0] = activeId
			this.initialIdList = [...this.activeIdList]

			this._activeNode(activeId)
		},
		getActiveNode(activeId) {
			return this.$refs['tree'].getNodeById(activeId)
		},
		setActiveIdList(list) {
			this.activeId = list[0]
			this.activeIdList = [...list]
			this.initialIdList = [...this.activeIdList]
			for (let i = 0, len = this.activeIdList.length; i < len; i++) {
				try {
					if (i === 0) {
						this._activeNode(this.activeIdList[i])
					} else {
						this.$refs['tree'].activeNode(this.$refs['tree'].getNodeById(this.activeIdList[i]))

						this._activeNode(this.activeIdList[i])
					}
				} catch (error) {
					console.warn(error)
				}
			}
		},
		getActiveIdList() {
			return this.activeIdList
		},
		getChangedList() {
			const addedIdList = []
			const removedIdList = []

			this.activeIdList.forEach(id => {
				if (this.initialIdList.indexOf(id) < 0) {
					addedIdList.push(id)
				}
			})

			this.initialIdList.forEach(id => {
				if (this.activeIdList.indexOf(id) < 0) {
					removedIdList.push(id)
				}
			})

			return {
				activeIdList: this.activeIdList,
				initialIdList: this.initialIdList,
				addedIdList: addedIdList,
				removedIdList: removedIdList,
			}
		},
		moveStorage(event, item) {
			let locationId = ''
			if (event.target.id === '') {
				locationId = event.target.parentNode.id.replaceAll('checker_', '')
			} else {
				locationId = event.target.id.replaceAll('checker_', '')
			}
			const storageItemData = this.storageDrop(event, item)

			const sendData = {
				storageItem: storageItemData.storageItem,
				locationId: locationId,
			}
			this.$emit('moveStorage', sendData)
		},

		//// 열린 노드 유지하기
		_addClosedNode(id) {
			if (!this._isClosedNode(id)) {
				const nodeList = this._getClosedNodeList()
				nodeList.push(id)
				this.$cookies.set('tree_Closed_node', nodeList.join('|'), '1d')
			}
		},
		_removeClosedNode(id) {
			if (this._isClosedNode(id)) {
				const nodeList = this._getClosedNodeList()
				nodeList.remove(id)
				this.$cookies.set('tree_Closed_node', nodeList.join('|'), '1d')
			}
		},
		_isClosedNode(id, nodeList = this._getClosedNodeList()) {
			if (nodeList.indexOf(id) >= 0) {
				return nodeList.indexOf(id)
			}

			return false
		},
		_getClosedNodeList() {
			const nodeList = this.$cookies.get('tree_Closed_node')
			if (typeof nodeList === 'string') {
				return nodeList.split('|')
			} else {
				return ['']
			}
		},
		_setClosedNode(treeData, nodeList = this._getClosedNodeList()) {
			treeData.forEach(node => {
				if (this._isClosedNode(node.id, nodeList)) {
					if (this.$refs['tree']) {
						this.$refs['tree'].closeNode(this.$refs['tree'].getNodeById(node.id))
					}
				}

				if (Array.isArray(node.children) && node.children.length > 0) {
					this._setClosedNode(node.children, nodeList)
				}
			})
		},
	},
}
</script>

<style lang="scss">
$border-color: $INPUT;
.OrganizationTree__chart-container {
	border-right: 1px solid $DIVIDER;

	.OrganizationTree__main-container {
		overflow-y: auto;
		overflow-x: auto;
	}
}

#TreeView {
	.active > .tree-node-inner-back div.node-text {
		color: $TEXT_YELLOW;
	}
	.hide {
		display: none;
	}
	div.node-sub-text {
		font-size: 12px;
		color: $PLACEHOLDER;
		display: inline-block;
		font-weight: normal;
		margin-left: 10px;
		margin-bottom: -2px;
	}
	.he-tree {
		max-width: 420px !important;
		box-sizing: border-box;
		// width: 300px;
		// height: 100%;
		// display: block;
		// text-align: left;
		// padding-left: 30px;
		// margin: 0;
		box-sizing: border-box;
		> .tree-node {
			// margin-left: -30px;
			> .tree-node-children {
				&:after {
					display: none;
				}
				> .tree-node {
					> .tree-node-inner-back {
						.tree-node-inner {
							&::before {
								display: none;
							}
							&:after {
								display: none;
							}
						}
					}
					&.open {
						background-color: none;
						&:after {
							display: none;
						}
					}
					> .tree-node-children {
						&:after {
							display: none;
						}
						> .tree-node {
							&.open {
								&:after {
									border-left: 1px solid $border-color;
								}
								&:last-child {
									&:after {
										border-left: 1px solid $LAB_WHITE;
									}
								}
							}
						}
					}
				}
			}
		}
		.tree-node {
			position: relative;

			&.open {
				position: relative;
				.tree-node-children {
					> .tree-node {
						&:last-child {
							&:after {
								height: 0;
							}
						}
					}
					.tree-node {
						&:after {
							width: 1px;
							height: caLc(100% - 25px);
							content: '';
							border-left: 1px solid $border-color;
							position: absolute;
							left: -30px;
							top: 0;
						}
					}
				}
			}
			.tree-node-inner-back {
				position: relative;
				transition: 0.3s all ease;
				cursor: pointer;
				width: 100%;
				box-sizing: border-box;
				input[type='checkbox'] {
					visibility: hidden;
				}
				label {
					&.checkboxLabel {
						width: 20px;
						height: 20px;
						background-image: url(~@/assets/svg/add-member-plus.svg);
						background-repeat: no-repeat;
						background-position: center;
						background-size: 20px;
						display: inline-block;
						cursor: pointer;
						transition: 0.3s all ease;
						&:hover {
							background-image: url(~@/assets/svg/add-member-plus-hover.svg);
						}
						position: absolute;
						right: 16px;
						margin-top: 1px;
					}
					&.radioLabel {
						margin-left: auto;
						color: $LAB_YELLOW;
						width: 20px;
						height: 20px;
						border-radius: 20px;
						border: 1px solid #ccc;
						box-sizing: border-box;
						cursor: pointer;
						display: inline-block;
						background: $LAB_WHITE;
						margin-bottom: -4px;
						position: absolute;
						right: 1rem;
					}
				}
				input[type='radio'] {
					opacity: 0;
				}
				input[type='radio']:not(:checked) + label.radioLabel {
					transition: 0.5s all ease;
					&:hover {
						border-color: $LAB_BLACK;
						transition: 0.5s all ease;
					}
				}
				input[type='radio']:checked + label {
					background-image: url(~@/assets/svg/radio-checked.svg);
					border: 1px solid $LAB_YELLOW;
					width: 20px;
					height: 20px;
					background-repeat: no-repeat;
					background-size: 22px;
					border-radius: 20px;
					background-position: center;
				}
				input[type='checkbox']:checked + label.checkboxLabel {
					width: 20px;
					height: 20px;
					background-image: url(~@/assets/svg/add-member-plus-checked.svg);
					background-repeat: no-repeat;
					background-position: center;
					background-size: 20px;
				}
				&:hover {
					background-color: $GREY_4;
					label {
						&.checkboxLabel {
							width: 20px;
							height: 20px;
							display: inline-block;
							background-image: url(~@/assets/svg/add-member-plus-hover.svg);
						}
					}
				}
			}
			.tree-node-inner {
				font-size: 1rem;
				color: #000;
				height: 50px;
				display: flex;
				align-items: center;
				font-weight: 700;
				position: relative;
				width: 100%;
				&:before {
					width: 30px;
					height: 25px;
					border-bottom: 1px solid $border-color;
					border-left: 1px solid $border-color;
					content: '';
					z-index: 1;
					position: absolute;
					top: 0;
					left: -30px;
				}
				&:after {
					width: 1px;
					height: 100%;
					content: '';
					position: absolute;
					top: -25px;
					left: -30px;
					border-left: 1px solid $border-color;
				}
				.icon {
					width: 20px;
					display: inline-block;
					img {
						width: inherit;
						display: inline-block;
						margin-bottom: 10px;
					}
				}
				.checker {
					width: 100%;
					display: flex;
					align-items: center;
					height: auto;
					border-radius: 4px;
				}
				div.node-text {
					display: inline-block;
					padding-left: 8px;
					max-width: calc(100% - 75px);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					&.storage {
						padding-left: 5px;
					}
				}
				button {
					width: 14px;
					height: 14px;
					margin-left: -7px;
					margin-right: -2px;
					margin-top: 2px;
					position: absolute;
					border: 0;
					background-color: rgba(0, 0, 0, 0);
					box-sizing: border-box;
					padding: 0;
					z-index: 3;
					background-repeat: no-repeat;
					background-size: 14px;
					text-indent: -9999px;
					&.folded {
						background-image: url(~@/assets/svg/organization-folded.svg);
					}
					&.unfold {
						background-image: url(~@/assets/svg/organization-unfold.svg);
					}
				}
			}
		}
	}
}

.tree-node {
	.icon {
		width: 20px;
		height: 20px;
		img {
			filter: brightness(0);
		}
	}
	&.active {
		> .tree-node-inner-back {
			.icon {
				img {
					filter: unset;
				}
			}
		}
	}
	.new-img {
		width: 14px;
		height: 14px;
		margin-left: 5px;
		display: inline-flex;
		align-items: center;
		img {
			width: 100%;
			height: 100%;
			margin-bottom: 3px;
		}
	}
}
</style>
