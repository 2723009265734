var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "counter-wrap"
  }, [_c('div', {
    staticClass: "input-header"
  }, [_c('div', {
    staticClass: "input-title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " "), _c('p', {
    staticClass: "required"
  }, [_vm._v("*")])])]), _c('input-button-counter', {
    ref: "input-button-counter",
    attrs: {
      "min": _vm.min,
      "max": _vm.max,
      "sizeType": "sm",
      "isShowError": _vm.isShowError
    },
    model: {
      value: _vm.count,
      callback: function callback($$v) {
        _vm.count = $$v;
      },
      expression: "count"
    }
  }), _vm.error || _vm.isShowError ? _c('p', {
    staticClass: "error"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/purchase/icon-error.svg"),
      "width": "12",
      "height": "12"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('${1}을 입력해주세요.', _vm.title)) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }