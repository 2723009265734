import { searchIngredient } from '@/services/api/inventory/ingredient.js'
import _ from 'lodash'

const state = {
	searchList: [],
	searchListCount: Number.MAX_SAFE_INTEGER,
	searchParams: {
		keyword: '',
		offset: 0,
		length: 20,
	},
	keywordType: '',
}
const getters = {
	searchList: state => state.searchList,
	searchParams: state => state.searchParams,
}
const mutations = {
	setSearchList: (state, searchList) => {
		state.searchList = _.cloneDeep(searchList)
	},
	setSearchListCount: (state, count) => {
		state.searchListCount = count
	},
	setParams: (state, params) => {
		state.searchParams = _.cloneDeep(params)
	},
	setKeywordType: (state, keywordType) => {
		state.keywordType = keywordType === 'name' ? 'NAME' : 'CASNO'
	},
	setKeyword: (state, keyword) => {
		state.searchParams.keyword = keyword
	},
}
const actions = {
	getSearchList: async ({ commit, rootGetters, state }, keywordType) => {
		commit('setParams', {
			keyword: state.searchParams.keyword,
			offset: 0,
			length: 20,
			keywordType: state.keywordType,
		})
		commit('setSearchListCount', Number.MAX_SAFE_INTEGER)
		const response = await searchIngredient(
			rootGetters.instituteId,
			state.searchParams
		)
		commit('setSearchListCount', response.data.count)
		commit('setSearchList', response.data.list)
	},
	addSearchList: async ({ commit, rootGetters, state }) => {
		if (state.searchListCount > state.searchParams.offset) {
			const response = await searchIngredient(
				rootGetters.instituteId,
				state.searchParams
			)
			commit('setSearchList', [
				...state.searchList,
				...response.data.list,
			])
		}
	},
}
export default { namespaced: true, state, getters, mutations, actions }
