var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "msds-wrapper"
  }, [_c('img', {
    staticClass: "msds-icon-img",
    attrs: {
      "src": _vm.msdsImg
    }
  }), _c('div', {
    staticClass: "msds-content"
  }, [_c('div', {
    staticClass: "msds-title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " ")]), _c('div', {
    staticClass: "msds-subtext"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.subtext)) + " ")])]), _c('div', {
    staticClass: "btn-wrapper"
  }, [_c('button', {
    staticClass: "show-msds"
  }, [_vm._v(_vm._s(_vm.tt('보러가기')))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }