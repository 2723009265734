var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: _vm.error,
      'modal-divide': _vm.forModalDivide
    }
  }, [_c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    ref: "input-number",
    staticClass: "form-number-input",
    attrs: {
      "type": "number",
      "placeholder": _vm.tt(_vm.placeholder),
      "disabled": _vm.forModalDivide
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "input": _vm.handleInput
    }
  }), _c('b', {
    staticClass: "unit"
  }, [_vm._v(_vm._s(_vm.tt(_vm.unit)))])]), _vm.error ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }