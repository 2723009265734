<template>
	<div class="page-wrapper">
		<migration-header :isMigration="true" :text="headerText"></migration-header>
		<div class="content-wrapper">
			<div class="text-wrapper">
				<h5 class="title" v-html="titleText"></h5>
				<div class="content" v-html="content"></div>
			</div>
			<div class="input-wrapper">
				<input
					class="institution-name-input"
					:value="instituteName"
					@keyup="changeInstituteName"
					:placeholder="inputPlaceholder"
					@keypress.enter="isAnyNameInput ? routeMigrationMemberList() : ''"
				/>
			</div>
			<migration-button-area
				@back="routeMigrationSelectLab"
				@next="isAnyNameInput ? routeMigrationMemberList() : ''"
				:nextDisable="!isAnyNameInput"
			></migration-button-area>
		</div>
	</div>
</template>

<script>
import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'
import MixinMigration from '@/mixins/migration'
import MigrationHeader from '@/views/migration/components/MigrationHeader.vue'
import MigrationButtonArea from '@/views/migration/components/MigrationButtonArea.vue'
import { mapMutations } from 'vuex'

export default {
	components: {
		LmConfirmButtonMiddle,
		MigrationHeader,
		MigrationButtonArea,
	},
	mixins: [MixinMigration],
	data() {
		return {
			headerText: this.tt('기관명 입력'),
			titleText: this.tt('랩매니저 PRO에서 사용할 기관명을 입력해 주세요.'),
			content: this.tt(
				'기관명은 연구실명 및 팀명의 상위개념입니다.<br/>대학 연구실은 대학명, 기업 연구실은 기업명을 입력해 주세요.<br/>(기관명 예시: 한국대학교, 한국제약)'
			),
			inputPlaceholder: this.tt('기관명'),
			instituteName: '',
		}
	},
	created() {
		this.instituteName = this.storedInstituteName
	},
	computed: {
		isAnyNameInput() {
			return this.storedInstituteName.length > 0
		},
		storedInstituteName() {
			return this.$store.state.migration.instituteName
		},
	},
	methods: {
		...mapMutations('migration', ['setInstituteName']),
		changeInstituteName(event) {
			this.instituteName = event.target.value
			this.setInstituteName(event.target.value)
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.content-wrapper {
		display: flex;
		flex-direction: column;
		width: 700px;
		border-radius: 16px;
		padding: 40px 50px 30px 50px;
		background: white;
		box-shadow: 0px 1px 2px 0px #0000001a;

		.input-wrapper {
			.institution-name-input {
				width: 100%;
				height: 50px;
				border: 1px solid #e6e6e6;
				box-sizing: border-box;
				border-radius: 5px;
				padding: 14px 12px;
				font-size: 14px;
				&:focus {
					border: #111111 1px solid;
				}
			}
		}
		.text-wrapper {
			margin-bottom: 30px;
			.title {
				font-size: 24px;
				font-weight: 700;
				line-height: 35px;
				letter-spacing: 0px;
				text-align: left;
				padding-bottom: 10px;
			}
			.content {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0px;
				text-align: left;
				color: $LAB_BLACK;
			}
		}
	}
}
</style>
