import axiosInstance from '@/services/axios'

const endPoint = {
	createInstitute: () => '/institute/simple',
}

const instituteAPI = {
	createInstitute: params => axiosInstance.post(endPoint.createInstitute(), params),
}

export default instituteAPI
