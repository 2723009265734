<template>
	<div class="progress-indicator">
		<div class="indicator" :style="`width: ${step}%`"></div>
	</div>
</template>

<script>
export default {
	props: {
		progressStep: {
			type: Number,
			default: 1,
		},
	},
	computed: {
		step() {
			return 33.33 * this.progressStep
		},
	},
}
</script>

<style lang="scss" scoped>
.progress-indicator {
	width: 100%;
	height: 6px;
	background-color: $DIVIDER;
	position: absolute;
	top: 58px;
	left: 0;
	.indicator {
		height: inherit;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background-color: $LAB_YELLOW;
	}
}
</style>
