var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal-form-cargo-product",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.goNext
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('다음')) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_vm.cargoFormType === 'ADD' ? _c('div', {
    staticClass: "form-wrap sm"
  }, [_c('div', {
    staticClass: "msg-warning"
  }, [_vm._v(" " + _vm._s("".concat(_vm.tt('지출결의서 상신을 하지 않고 직접 추가하는 물품의 경우'))) + " "), _c('b', [_vm._v(_vm._s("".concat(_vm.tt('지출 내역 및 예산에 반영되지 않습니다.'))))])])]) : _vm._e(), _c('cargo-category-selector', {
    ref: "category-selector",
    attrs: {
      "title": "카테고리 선택"
    }
  }), _c('cargo-form-counter', {
    ref: "form-counter"
  }), _vm.isCategoryId ? _c('cargo-form-product', {
    ref: "form-product"
  }) : _vm._e(), _vm.isUsedIngredient && _vm.isCategoryId ? _c('cargo-form-ingredient-list', {
    ref: "form-ingredient"
  }) : _vm._e(), _vm.isUsedMsds && _vm.isCategoryId ? _c('cargo-form-msds') : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }