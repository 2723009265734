var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "divide-counter-wrap"
  }, [_c('div', {
    staticClass: "divide-counter-header"
  }, [_c('div', {
    staticClass: "text",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>분리할 수량</b>을 입력해주세요.'))
    }
  }), _c('img', {
    staticClass: "close",
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "width": "14",
      "height": "14"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$emit('close');
      }
    }
  })]), _c('input-button-counter', {
    attrs: {
      "max": _vm.max,
      "min": _vm.min
    },
    model: {
      value: _vm.divideCount,
      callback: function callback($$v) {
        _vm.divideCount = $$v;
      },
      expression: "divideCount"
    }
  }), _c('button', {
    staticClass: "confirm-button",
    on: {
      "click": function click($event) {
        return _vm.$emit('divide', _vm.divideCount);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('분리하기')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }