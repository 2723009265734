import { mapActions, mapGetters, mapState } from 'vuex'
import Validation from '@/utils/Validation'

export default {
	data() {
		return {
			cardInput: false,
			cardNumber: '',
			expirationMonthYear: '',
			form: {
				number: '',
				// 이니시스에서 필수값이 아님(22.02.22 현우님이랑 확인)
				password: '',
				expirationMonthYear: '',
				registrationNumber: '',
				isDefault: true,
				phone: '',
				email: '',
				nameOfCompany: '',
				nameOfRepresentative: '',
				purpose: 'LICENSE',
				bizType: '',
				bizClass: '',
				postcode: '',
				address: '',
				addressDetail: '',
			},
			expirationMonthYear: '',
			cardNumberMaxLength: 19,
			formNumberMaxLength: 16,
			cardNoError: false,
			passwordError: false,
			expirationError: false,
			registrationNoError: false,
			phoneNoError: false,
			emailNoError: false,
		}
	},
	watch: {
		'form.number': {
			deep: true,
			handler(newValue) {
				if (newValue) this.cardNumber = this.addCardDash(newValue, true)
			},
		},
		expirationMonthYear: {
			handler(newValue) {
				this.form.expirationMonthYear = this.addYearMonthDelimeter(newValue)
			},
		},
	},
	computed: {
		...mapState('paymentCard', ['hasDefaultCreditCard', 'defaultCreditCard']),
	},
	methods: {
		...mapActions('paymentCard', ['loadDefaultCreditCard', 'registDefaultCreditCard']),
		...mapActions('invoice', ['createEbill']),
		initData() {
			console.log(2)
			this.form = {
				number: '',
				// 이니시스에서 필수값이 아님(22.02.22 현우님이랑 확인)
				password: '',
				expirationMonthYear: '',
				registrationNumber: '',
				isDefault: true,
				phone: '',
				email: '',
				nameOfCompany: '',
				nameOfRepresentative: '',
				purpose: 'LICENSE',
				bizType: '',
				bizClass: '',
				postcode: '',
				address: '',
				addressDetail: '',
			}
			this.cardNumber = ''
			this.expirationMonthYear = ''
			// 에러 초기화
			this.cardNoError = false
			this.passwordError = false
			this.expirationError = false
			this.registrationNoError = false
			this.phoneNoError = false
			this.emailNoError = false
			if (this.user?.phone) this.form.phone = Validation.autoHypenTel(this.user.phone)

			if (this.user?.email) this.form.email = this.user.email
		},
		showKeypad(e) {
			this.cardInput = false
		},

		addCardDash(cardNum, hide = false) {
			var amexPrefixRegex = /^3[47]/
			var dinersPrefixRegex = /^3(?:0[0-5]|[689][0-9])|^3095/

			var isAmexcard = amexPrefixRegex.test(cardNum)
			var isDinerscard = dinersPrefixRegex.test(cardNum)
			if (isAmexcard) {
				this.cardNumberMaxLength = 17
				this.formNumberMaxLength = 15
			} else if (isDinerscard) {
				this.cardNumberMaxLength = 16
				this.formNumberMaxLength = 14
			} else {
				this.cardNumberMaxLength = 19
				this.formNumberMaxLength = 16
			}
			let cardNumLength = cardNum.length
			let cardNumber = []
			let delimeter = '-'

			if (isAmexcard) {
				// 15자리
				if (cardNumLength <= 4) {
					return cardNum
				} else if (cardNumLength > 4 && cardNumLength <= 10) {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 6)) : cardNum.substr(4, 6))
					return cardNumber.join(delimeter)
				} else {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 6)) : cardNum.substr(4, 6))
					cardNumber.push(cardNum.substr(10, 5))
					return cardNumber.join(delimeter)
				}
			} else if (isDinerscard) {
				// 14자리
				if (cardNumLength <= 4) {
					return cardNum
				} else if (cardNumLength > 4 && cardNumLength <= 10) {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 6)) : cardNum.substr(4, 6))
					return cardNumber.join(delimeter)
				} else {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 6)) : cardNum.substr(4, 6))
					cardNumber.push(cardNum.substr(10, 4))
					return cardNumber.join(delimeter)
				}
			} else {
				// 16자리
				if (cardNumLength <= 4) {
					return cardNum
				} else if (cardNumLength > 4 && cardNumLength <= 8) {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 4)) : cardNum.substr(4, 4))
					return cardNumber.join(delimeter)
				} else if (cardNumLength > 8 && cardNumLength <= 12) {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 4)) : cardNum.substr(4, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(8, 4)) : cardNum.substr(8, 4))
					return cardNumber.join(delimeter)
				} else {
					cardNumber.push(cardNum.substr(0, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(4, 4)) : cardNum.substr(4, 4))
					cardNumber.push(hide ? this.replaceAstrisk(cardNum.substr(8, 4)) : cardNum.substr(8, 4))
					cardNumber.push(cardNum.substr(12, 4))
					return cardNumber.join(delimeter)
				}
			}
		},
		replaceAstrisk(str) {
			let result = ''
			for (let i = 0, length = str.length; i < length; i++) {
				result += '*'
			}
			return result
		},
		addNumber(number) {
			if (this.formNumberMaxLength > this.form.number.length) {
				this.form.number += number
			}
		},
		removeInputNumber() {
			const numberLength = this.form.number.length
			this.form.number = this.form.number.slice(0, numberLength - 1)
		},
		addYearMonthDelimeter(yearMonth) {
			if (yearMonth.length > 2) {
				return [yearMonth.substr(0, 2), yearMonth.substr(2, 2)].join('/')
			} else {
				return yearMonth
			}
		},
		inputExpirationMonthYear(event) {
			this.expirationMonthYear = event.target.value.replace(/[^0-9]/g, '')
			const isNumber = event.data >= '0' && event.data <= '9'
			if (isNumber) {
				event.target.value = this.addYearMonthDelimeter(this.expirationMonthYear)
			} else {
				const length = this.form.expirationMonthYear.length
				event.target.value = this.expirationMonthYear.substr(0, length)
			}
		},

		inputRegistrationNumber(event) {
			const isNumber = event.data >= '0' && event.data <= '9'
			const value = event.target.value.replace(/[^0-9]/g, '')
			const isDelete = event.inputType === 'deleteContentBackward'
			if (isNumber || isDelete) {
				if (value.length <= 6) {
					event.target.value = value
					this.form.registrationNumber = event.target.value.replace(/[^0-9]/g, '')

					console.warn(event.target.value)
				} else {
					const traderNumber = this.autoRegistrationNumber(value)
					event.target.value = traderNumber
					this.form.registrationNumber = traderNumber
				}
			} else {
				event.target.value = value.slice(0, value.length - 2)
			}
		},
		autoRegistrationNumber(companyNum) {
			// ref: https://life-with-coding.tistory.com/46 [나다움]
			companyNum = companyNum.replace(/[^0-9]/g, '')
			let tempNum = ''
			if (companyNum.length < 4) {
				return companyNum
			} else if (companyNum.length < 6) {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				return tempNum
			} else if (companyNum.length < 11) {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				tempNum += '-'
				tempNum += companyNum.substr(5)
				return tempNum
			} else {
				tempNum += companyNum.substr(0, 3)
				tempNum += '-'
				tempNum += companyNum.substr(3, 2)
				tempNum += '-'
				tempNum += companyNum.substr(5)
				return tempNum
			}
		},
		inputPassword(event) {
			const isNumber = event.data >= '0' && event.data <= '9'
			if (isNumber) {
				this.form.password = event.target.value
			} else {
				event.target.value = this.form.password
			}
		},
		autoHypenTel(e) {
			const phoneNumber = Validation.autoHypenTel(e.target.value)
			this.setPhone(phoneNumber)
		},
		setPhone(phoneNumber) {
			this.form.phone = phoneNumber
		},
		validPhone() {
			let result = true
			if (this.form.phone?.length >= 1) {
				if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.form.phone)) {
					result = false
				}
			}
			return result
		},
		validEmail() {
			// ref: https://www.w3resource.com/javascript/form/email-validation.php
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)) {
				return true
			}
			return false
		},
		changeAddress({ postcode, address, addressDetail }) {
			this.form.postcode = postcode
			this.form.address = address
			this.form.addressDetail = addressDetail
		},
	},
}
