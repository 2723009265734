<template>
	<div class="info-wrap">
		<p class="info-header">{{ tt('물품정보') }}</p>
		<div class="field-wrap">
			<product-info-field
				:item="item"
				:fieldInfo="fieldInfo"
				:hideField="hideField"
				v-for="fieldInfo in fields"
				:key="`${fieldInfo.key}`"
			></product-info-field>
		</div>
	</div>
</template>
<script>
import { LargeClass } from '@/utils/define/ItemCode'
import { mapGetters } from 'vuex'
import ProductInfoField from './ProductInfoField.vue'
export default {
	name: 'ProductInfo',
	components: { ProductInfoField },
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		fields() {
			return this.inventoryFields?.filter(it => !this.hideField.includes(it.key))
		},
		...mapGetters('cargo/item', ['inventoryFields']),
		hideField() {
			let hideFieldKey = ['ingredient']
			if (this.item.categoryType !== LargeClass.ETC) {
				hideFieldKey.push('currentVolume')
			}
			return hideFieldKey
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	gap: 20px;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.field-wrap {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}
}
</style>
