var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('관리정보')))]), _c('div', {
    staticClass: "field-wrap"
  }, _vm._l(_vm.fields, function (fieldInfo) {
    return _c('management-info-field', {
      key: "".concat(fieldInfo.key),
      attrs: {
        "item": _vm.item,
        "fieldInfo": fieldInfo,
        "fieldName": _vm.formatFieldName(fieldInfo),
        "isRequire": fieldInfo.isRequire,
        "fieldText": _vm.formatFieldText(fieldInfo.key),
        "isData": _vm.isData(fieldInfo.key)
      }
    });
  }), 1), _c('div', {
    staticClass: "field-wrap"
  }, [_vm.tagField && _vm.tagField.isActive ? _c('tag-field', {
    attrs: {
      "fieldName": "태그",
      "isRequire": _vm.tagField.isRequire,
      "tag": _vm.item.tagList
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }