var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.hide,
      expression: "hide"
    }],
    ref: "dropdown",
    attrs: {
      "no-caret": "",
      "variant": "link",
      "toggle-class": "text-decoration-none ".concat(_vm.isActive ? 'active' : '')
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        var _vm$text;

        return [_c('span', [_vm._v(_vm._s((_vm$text = _vm.text) !== null && _vm$text !== void 0 ? _vm$text : _vm.selectName))]), _c('img', {
          attrs: {
            "src": _vm.isActive ? require('@/assets/svg/arrow-down_y.svg') : require('@/assets/svg/arrow-down.svg')
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.options, function (option) {
    return _c('b-dropdown-item', {
      key: option.value,
      on: {
        "click": function click($event) {
          return _vm.selectItem(option.value);
        }
      }
    }, [_c('p', [_vm._v(_vm._s(_vm.tt(option.name)))])]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }