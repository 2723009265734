var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.productInfo ? _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isManagementValid || !_vm.isUnitValid
    }
  }, [_vm._v(" " + _vm._s(_vm.isManagementValid && _vm.isUnitValid ? _vm.tt('입력 완료') : !_vm.isUnitValid ? _vm.tt('단위를 선택해주세요') : _vm.tt('필수값을 입력해주세요')) + " ")])]), _c('div', {
    staticClass: "form-data-wrap"
  }, [_vm._l(_vm.requiredMngFields, function (field) {
    return _c(_vm.formInput(field), {
      key: "".concat(_vm.categoryInfo.id, "-").concat(field.key),
      ref: "required-form",
      refInFor: true,
      tag: "component",
      attrs: {
        "name": field.name,
        "item": _vm.productInfo,
        "value": _vm.formValue(field.key),
        "unit": _vm.formUnit(field.key),
        "placeholder": _vm.formMessage(field.name),
        "errorMessage": _vm.formMessage(field.name),
        "optionList": _vm.formOptionList(field),
        "required": field.isRequire,
        "maxSize": _vm.productInfo.multiCreateCount,
        "isShowError": _vm.isShowError,
        "field": field
      },
      on: {
        "input": function input(value) {
          return _vm.inputFormValue(value, field.key);
        },
        "removeImg": _vm.removeImage
      }
    });
  }), !_vm.showManagement && _vm.mngFields.length > 0 ? _c('button', {
    on: {
      "click": function click($event) {
        _vm.showManagement = !_vm.showManagement;
      }
    }
  }, [_c('span', {
    staticClass: "show-detail"
  }, [_vm._v(_vm._s(_vm.tt('상세히 입력하기')))]), _c('img', {
    attrs: {
      "src": require("@/views/common/components/form/svg/chevron-down.svg"),
      "width": "15"
    }
  })]) : _vm._l(_vm.mngFields, function (field) {
    return _c(_vm.formInput(field), {
      key: "".concat(_vm.categoryInfo.id, "-").concat(field.key),
      ref: "form",
      refInFor: true,
      tag: "component",
      attrs: {
        "name": field.name,
        "value": _vm.formValue(field.key),
        "unit": _vm.formUnit(field.key),
        "placeholder": _vm.formMessage(field.name),
        "errorMessage": _vm.formMessage(field.name),
        "optionList": _vm.formOptionList(field),
        "required": field.isRequire,
        "maxSize": _vm.productInfo.multiCreateCount,
        "isShowError": _vm.isShowError,
        "field": field,
        "item": _vm.productInfo
      },
      on: {
        "input": function input(value) {
          return _vm.inputFormValue(value, field.key);
        },
        "removeImg": _vm.removeImage
      }
    });
  })], 2)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }