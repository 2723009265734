import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	list: (instId, paramsObject) => `/institute/${instId}/draft/list/approval-completed?${ParamMaker.setParamUsingObject(paramsObject)}`,
	filterList: instId => `/institute/${instId}/draft/list/approval-completed/filter`,
	detail: (instId, id) => `/institute/${instId}/draft/${id}`,
	settle: (instId, ids) => `/institute/${instId}/draft/settle`,
	unSettle: (instId, ids) => `/institute/${instId}/draft/unsettle`,
	listExportExcel: instituteId => `/institute/${instituteId}/draft/list/approval-completed/export/excel`,
}

const deadlineAPI = {
	list: (instId, paramsObject) => axiosInstance.get(endPoint.list(instId, paramsObject)),
	filterList: instId => axiosInstance.get(endPoint.filterList(instId)),
	detail: (instId, id) => axiosInstance.get(endPoint.detail(instId, id)),
	settle: (instId, body) => axiosInstance.patch(endPoint.settle(instId), body),
	unSettle: (instId, body) => axiosInstance.patch(endPoint.unSettle(instId), body),
	listExportExcel: (institueId, payload) =>
		axiosInstance.post(endPoint.listExportExcel(institueId), payload, {
			responseType: 'blob',
		}),
}

export default deadlineAPI
