<template>
	<div class="legacy-item-wrapper" @click.stop="handleClick">
		<div class="content-wrapper">
			<div class="icon-wrapper">
				<img src="@/assets/svg/migration/icon-legacy-lab-manager.svg" />
			</div>
			<div class="text-wrapper">
				<div class="text-title" v-html="titleText"></div>
				<div class="text-content" v-html="contentText"></div>
			</div>
			<div class="arrow-wrapper">
				<img src="@/assets/svg/arrow/icon-arrow-right.svg" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			titleText: this.tt('기존 랩매니저 이용하기'),
			contentText: this.tt('기존 랩매니저를 그대로 이용하실 수 있습니다.'),
		}
	},
	methods: {
		handleClick() {
			this.$emit('click')
		},
	},
}
</script>

<style lang="scss" scoped>
.legacy-item-wrapper {
	cursor: pointer;
	height: 100px;
	width: 600px;
	left: 0px;
	top: 296px;
	border-radius: 16px;
	margin: 20px;
	.content-wrapper {
		display: flex;
		padding: 20px;
		border-radius: 16px;
		border: 1px solid $INPUT;
		box-shadow: 0px 1px 2px 0px #0000001a;
		height: 100%;
		.icon-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.text-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 5px 10px;
			width: 100%;

			.text-title {
				font-size: 18px;
				font-weight: 700;
				line-height: 26px;
				letter-spacing: 0px;
				text-align: left;
			}
			.text-content {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0px;
				text-align: left;
				color: $SECONDARY_2;
			}
		}
		.arrow-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}
}
</style>
