var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    "class": {
      disabled: !_vm.item.canOrder
    }
  }, [_c('td', [_c('input', {
    attrs: {
      "disabled": !_vm.item.canOrder,
      "type": "radio",
      "name": "estimateId"
    },
    domProps: {
      "value": _vm.item.id,
      "checked": _vm.item.id == _vm.selectedEstimateId
    },
    on: {
      "change": _vm.change
    }
  })]), _c('td', [_c('span', {
    staticClass: "status",
    "class": [_vm.item.status.code]
  }, [_vm._v(_vm._s(_vm.tt(_vm.statusText)))])]), _c('td', {
    staticClass: "underline",
    on: {
      "click": _vm.goEstimate
    }
  }, [_vm._v(_vm._s(_vm.item.no))]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.item.productName))])]), _c('td', [_vm._v(_vm._s(_vm.item.writer.name))]), _c('td', [_vm._v(_vm._s(_vm.$DateTime.fromISO(_vm.item.createDatetime).toFormat('yyyy-MM-dd')))]), _c('td', [_vm._v(_vm._s(_vm.$DateTime.fromISO(_vm.item.updateDatetime).toFormat('yyyy-MM-dd')))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }