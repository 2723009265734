var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal-regist-item', {
    ref: "modal-regist-item",
    attrs: {
      "title": "물품 등록"
    },
    on: {
      "next": _vm.registItemNextStep
    }
  }), _c('modal-camera', {
    ref: "modal-camera",
    attrs: {
      "title": "사진으로 등록",
      "isStartPopup": ""
    },
    on: {
      "noPermission": _vm.noPermissionCam,
      "prev": function prev($event) {
        return _vm.$refs['modal-regist-item'].show();
      },
      "next": function next($event) {
        return _vm.$refs['modal-item-management'].show();
      },
      "change": _vm.changeUrls
    }
  }), _c('modal-search-product', {
    ref: "modal-search-product",
    attrs: {
      "title": "등록할 물품 검색",
      "categoryInfo": _vm.categoryInfo,
      "forRegistItem": ""
    },
    on: {
      "selectItem": _vm.selectProduct,
      "inputDirect": function inputDirect($event) {
        return _vm.$refs['modal-item-product'].show();
      },
      "setRegistType": function setRegistType(type) {
        return _vm.setRegistMethod(type);
      }
    }
  }), _c('modal-item-product', {
    ref: "modal-item-product",
    attrs: {
      "title": "물품정보 등록",
      "forRegistItem": ""
    },
    on: {
      "cancel": function cancel($event) {
        return _vm.initItem();
      },
      "complete": _vm.showManagementModal
    }
  }), _c('modal-item-management', {
    ref: "modal-item-management",
    attrs: {
      "title": "관리정보 등록"
    },
    on: {
      "goPrev": _vm.managementPrev,
      "complete": _vm.managementComplete
    }
  }), _c('modal-complete-regist', {
    ref: "modal-complete-regist",
    attrs: {
      "title": "물품 등록 완료!",
      "confirmBtnText": "더 등록하기"
    },
    on: {
      "confirm": _vm.registMore,
      "cancel": function cancel($event) {
        return _vm.initItem();
      }
    }
  }), _c('modal-excel-regist', {
    ref: "modal-excel-regist",
    attrs: {
      "title": "Excel 파일로 등록"
    },
    on: {
      "complete": _vm.excelRegistComplete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }