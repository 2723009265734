const ParamMaker = {
	setArrayString: (key, paramsList) => paramsList.map(item => `${key}[]=${item}`).join('&'),
	setParamUsingObject: paramObject => {
		const keyList = Object.keys(paramObject)
		const paramStrList = []
		for (let idx = 0; idx < keyList.length; idx++) {
			const key = keyList[idx]
			const value = paramObject[key]
			if (Array.isArray(value)) {
				for (let i = 0; i < value.length; i++) {
					paramStrList.push(`${key}[]=${paramObject[key][i]}`)
				}
			} else if (key == 'bundle') {
				if (value['casno'] != undefined) paramStrList.push(`${key}[casno]=${value['casno']}`)
			} else {
				paramStrList.push(`${key}=${paramObject[key]}`)
			}
		}
		return paramStrList.join('&')
	},
	setSearchKeywordEncoding: paramObject => {
		if (paramObject.keyword) {
			paramObject.keyword = paramObject.keyword.replace(/&/g, '%26').replace(/#/g, '%23').replace(/\+/g, '%2B').replace(/\%/g, '%25')
		}
		return paramObject
	},
}

export default ParamMaker
