<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Location__delete-location-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt(title) }}</h5>
					<button class="Popup__close-btn" @click="cancel">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text" v-html="tt(content)"></p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__delete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="cancel">
							{{ tt('취소') }}
						</button>
						<button class="Btn__delete" @click="confirmDelete">
							{{ tt('삭제') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import instituteGroupAPI from '@/services/api/institute/group'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import router from '@/router'

export default {
	name: 'ModalConfirmDelete',
	mixins: [MixinModal],
	props: {
		title: {
			type: String,
		},
		content: {
			type: String,
		},
	},
	data() {
		return {
			memberId: undefined,
		}
	},
	computed: {
		...mapGetters('organization', ['selectedGroupInfo']),
	},
	methods: {
		...mapMutations('organization', ['setSelectedGroupInfo']),
		...mapActions('organization', ['loadSelectedGroupInfo']),
		show(type, memberId) {
			this.modalType = type
			this.isShow = true
			this.memberId = memberId
		},
		confirmDelete() {
			const { id, parentId, name } = this.selectedGroupInfo
			if (this.modalType === 'DELETE_GROUP') {
				instituteGroupAPI.deleteGroup(id).then(
					response => {
						const title = this.tt('그룹 삭제 완료')
						const content = this.tt('그룹이 삭제되었습니다')
						this.showToastConfirm(title, content)
						this.loadSelectedGroupInfo(parentId).then(() => {
							this.$emit('refresh')
							this.hide()
						})
					},
					error => {
						const { code, msg } = error.response.data
						const title = this.tt('그룹 삭제 실패')
						const content = `${msg}`
						this.showToastError(title, content)
						this.hide()
					}
				)
			} else if (this.modalType === 'DELETE_MEMBER') {
				instituteGroupAPI
					.deleteGroupMember(id, [this.memberId])
					.then(response => {
						const title = this.tt('그룹원 내보내기')
						const content = this.tt('그룹원 내보내기에 성공했습니다.')
						this.showToastConfirm(title, content)
						this.$emit('hideMemberInfo')
						this.$emit('refresh')
						this.hide()
					})
					.catch(error => {
						const title = this.tt('그룹원 내보내기 실패')
						const content = error.response.data.msg
						this.showToastError(title, content)
					})
			}
		},
		showToastConfirm(title, content) {
			const type = 'success'
			this.$root.toast(title, content, type)
		},
		showToastError(title, content) {
			const type = 'error'
			this.$root.toast(title, content, type)
		},
		cancel() {
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped></style>
