<template>
	<div class="Organization__info">
		<div class="AppContainer__subtitle">
			<h6>{{ tt('정보') }}</h6>
			<button
				class="AppContainer__subtitle-btn btn"
				v-if="!isInstitute && userPermission.group && userPermission.group.update"
				@click="openModalEditGroup"
			>
				<img src="@/assets/svg/button-icon-edit.svg" alt="icon" />
				{{ tt('편집') }}
			</button>
		</div>
		<ul class="Organization__info-list">
			<li class="Organization__info-item">
				<span>{{ tt('그룹 유형') }}</span>
				<p>{{ groupType }}</p>
			</li>
			<li class="Organization__info-item" v-if="phone">
				<span>{{ tt('연락처') }}</span>
				<p>{{ phone }}</p>
			</li>
			<li class="Organization__info-item">
				<span>{{ groupInfo && groupInfo.type == 'INSTITUTE' ? tt('기관 멤버 수') : tt('멤버수') }}</span>
				<p>{{ memberCountText }}</p>
			</li>
			<li
				class="Organization__info-item"
				v-show="hasLocation"
				v-for="(location, idx) in locations"
				:key="`org-info-${location}-${idx}`"
				v-if="groupInfo.type != 'INSTITUTE'"
			>
				<span v-if="hasLocationSingle">{{ tt('위치') }}</span>
				<span v-else>{{ tt('위치') }}{{ idx + 1 }}</span>
				<p>{{ location.name }}</p>
			</li>
			<li class="Organization__info-item" v-if="isTypeLab && assignee.name">
				<span>{{ tt('연구실안전책임자') }}</span>
				<p>{{ assignee.name }}</p>
			</li>
			<li class="Organization__info-item" v-if="isTypeLab && subAssignee.name">
				<span>{{ tt('연구실안전관리자') }}</span>
				<p>{{ subAssignee.name }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'OrganizationInfoItem',
	props: {
		groupInfo: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {}
	},
	watch: {
		// groupInfo: {
		//     deep: true,
		//     handler() {
		//         const { type, phone, memberCount, locations } = this.groupInfo
		//         this.type = type
		//         this.phone = phone
		//         this.memberCount = memberCount
		//         this.locations = locations
		//         if (this.groupInfo.assignee) {
		//             this.assignee = this.groupInfo.assignee
		//         }
		//         if (this.groupInfo.subAssignee) {
		//             this.subAssignee = this.groupInfo.subAssignee
		//         }
		//     },
		// },
	},
	computed: {
		type() {
			if (this.groupInfo) {
				return this.groupInfo.type
			}
		},
		phone() {
			if (this.groupInfo) {
				return this.groupInfo.phone
			}
		},
		memberCount() {
			if (this.groupInfo) {
				return this.groupInfo.memberCount
			}
		},
		locations() {
			if (this.groupInfo) {
				return this.groupInfo.locations
			}
		},
		assignee() {
			if (this.groupInfo) {
				return this.groupInfo.assignee
			}
		},
		subAssignee() {
			if (this.groupInfo) {
				return this.groupInfo.subAssignee
			}
		},
		isInstitute() {
			return this.type === 'INSTITUTE'
		},
		groupType() {
			const isInstitute = this.type === 'INSTITUTE'
			const isLab = this.type === 'LAB'

			if (isInstitute) return this.tt('기관')
			else if (isLab) return this.tt('연구실')
			else return this.tt('일반')
		},
		isTypeLab() {
			return this.type === 'LAB'
		},
		hasLocation() {
			return this.locations.length > 0
		},
		hasLocationSingle() {
			return this.locations.length === 1
		},
		memberCountText() {
			return `${this.memberCount}${this.tt('명')}`
		},
	},
	methods: {
		setName(key) {
			return {
				type: this.tt('그룹 유형'),
				phoneNum: this.tt('연락처'),
				memberNo: this.tt('멤버수'),
				location: this.tt('위치'),
			}[key]
		},
		setLocationName(idx) {
			return this.tt(this.setName('location')) + Number(idx + 1)
		},
		openModalEditGroup() {
			this.$emit('openModalEditGroup')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_organization.scss';
</style>
