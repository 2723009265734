import ParamMaker from '@/utils/ParamMaker'
import axiosInstance from '@/services/axios'

const endPoint = {
	lookupMsdsList: (instId, offset, length, order, orderDirection, keyword, status, brandsId, msdsKind) => {
		let parameter = `/institute/${instId}/safety/msds/list?offset=${offset}&length=${length}`
		if (order.length != 0) parameter += `&${ParamMaker.setArrayString('order', order)}`
		if (orderDirection.length != 0) parameter += `&${ParamMaker.setArrayString('orderDirection', orderDirection)}`
		if (keyword != undefined) parameter += `&keyword=${keyword}`
		if (status.length != 0) parameter += `&${ParamMaker.setArrayString('status', status)}`
		if (brandsId.length != 0) parameter += `&${ParamMaker.setArrayString('brandsId', brandsId)}`
		if (msdsKind.length != 0) parameter += `&${ParamMaker.setArrayString('msdsKind', msdsKind)}`
		return parameter
	},
	updateMsds: instId => `/institute/${instId}/safety/msds`,
	deleteMsds: (instId, productCode, brandId, inventoryId) => {
		let parameter = `/institute/${instId}/safety/msds?brandId=${brandId}`
		if (productCode != undefined) parameter += `&productCode=${productCode}`
		if (inventoryId != undefined) parameter += `&inventoryId=${inventoryId}`

		return parameter
	},
	downloadMsds: instId => `/institute/${instId}/safety/msds/download`,
	exportMsdsExcel: (instId, order, orderDirection, keyword, status, brandsId) => {
		let parameter = `/institute/${instId}/safety/msds/list/excel?`
		const params = []
		if (order.length != 0) params.push(`${ParamMaker.setArrayString('order', order)}`)
		if (orderDirection.length != 0) params.push(`${ParamMaker.setArrayString('orderDirection', orderDirection)}`)
		if (keyword != undefined) params.push(`keyword=${keyword}`)
		if (status.length != 0) params.push(`${ParamMaker.setArrayString('status', status)}`)
		if (brandsId.length != 0) params.push(`${ParamMaker.setArrayString('brandsId', brandsId)}`)

		return `${parameter}${params.join('&')}`
	},
	getMsds: instId => `/institute/${instId}/safety/msds`,
}

const msdsAPI = {
	lookupMsdsList: (instId, offset, length, order, orderDirection, keyword, status, brandsId, msdsKind) =>
		axiosInstance.get(endPoint.lookupMsdsList(instId, offset, length, order, orderDirection, keyword, status, brandsId, msdsKind)),
	exportMsdsExcel: (instId, order, orderDirection, keyword, status, brandsId) => {
		return axiosInstance.post(
			endPoint.exportMsdsExcel(instId, order, orderDirection, keyword, status, brandsId),
			{},
			{
				responseType: 'blob',
			}
		)
	},
	updateMsds: (instId, formData) =>
		axiosInstance.post(endPoint.updateMsds(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	deleteMsds: (instId, productCode, brandId, inventoryId) => axiosInstance.delete(endPoint.deleteMsds(instId, productCode, brandId, inventoryId)),
	downloadMsds: (instId, body) =>
		axiosInstance.post(endPoint.downloadMsds(instId), body, {
			responseType: 'blob',
		}),
	getMsds: (instId, params) => axiosInstance.get(endPoint.getMsds(instId), { params }),
}

export default msdsAPI
