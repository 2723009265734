<template>
	<portal to="lm-modal">
		<!-- ModalLicenseManagement -->
		<!-- 기관 관리 / 라이선스 관리 모달 -->
		<div class="Modal ModalLicenseManagement" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('라이선스 관리') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="license-info">
						<div class="license-summary">
							<div class="imgbox">
								<img src="@/assets/images/lm-pro-icon.png" />
							</div>
							<div class="txtbox">
								<div class="license-header-text">
									<h6>{{ licenseTypeText }}</h6>
									<margin-span :styleObj="{ marginRight: '3px' }"></margin-span>
									<lm-ballon
										v-if="isNotCertEdu"
										:title="tt('교육기관 인증 방법')"
										:content="
											tt(
												'인증자료 제출 필수<br>&nbsp;•&nbsp;학생일 경우: 학생증 앞면, 입학 허가서<br>&nbsp;•&nbsp;교수님일 경우: 교직원증, 재직증명서<br><br>lmcs@smartjackwp.com으로 인증자료를 보내주세요.<br>영업일 기준 2일내 인증이 완료됩니다.'
											)
										"
									></lm-ballon>
								</div>
								<p v-if="isFreeTier">
									{{ tt('30일 무료') }}
								</p>
								<p v-else-if="isTrial">
									{{ tt('무료') }}
								</p>
								<p v-else-if="isEducation">
									{{ tt('${1}원 / 연 (연구실 당)', formatNumberLocale(licenseRatePlan['YEAR']['EDUCATION'])) }}
								</p>
								<p v-else>
									{{ tt('${1}원 / 월 (라이선스 당)', formatNumberLocale(getAdditonalPrice)) }}
								</p>
							</div>
						</div>
						<div class="license-current">
							<h5>{{ tt('현재 라이선스') }}</h5>
							<div class="waiting-noti" v-if="isLicenseDepositWait">
								<ul>
									<li>
										<p
											v-html="
												tt(
													'${1}까지 입금하지 않으면 라이선스가 <b class=red>비활성화</b>됩니다.',
													tt(`<b class='red'>${formatExpireDate(licenseManagement.schedule.expireDatetime)}</b>`)
												)
											"
										></p>
									</li>
								</ul>
								<ul>
									<li>
										{{
											tt(
												licenseManagement.schedule.virtualAccountBankName + ' ${1} (예금주: ${2})',
												licenseManagement.schedule.virtualAccountNumber,
												licenseManagement.schedule.virtualAccountHolder
											)
										}}
									</li>
									<li>
										{{ tt('결제금액: ${1}원', formatNumberLocale(licenseManagement.schedule.totalPrice)) }}
									</li>
								</ul>
							</div>
							<ul>
								<li>
									<p>{{ tt('상태') }}</p>
									<div class="status">
										<span
											class="license-status"
											:class="{
												expired: !isLicenseActivate,
											}"
										>
											<img src="@/assets/svg/sign-safe.svg" />
											{{ licenseStatusText }}
										</span>
										<span class="waiting-status" v-if="isLicenseDepositWait">
											{{ tt('입금대기') }}
										</span>
									</div>
								</li>
								<li>
									<p>{{ tt('라이선스 만료일') }}</p>
									<span>
										{{ formatDatetime(license.expireDate) }}
									</span>
								</li>
								<!--프리티어인 경우-->
								<li v-if="isFreeTier">
									<p>{{ tt('멤버 수') }}</p>
									<span class="license-member">
										{{ memberCount }}
									</span>
								</li>
								<!--라이선스 활성화 된 경우(결제 완료)-->
								<li v-else>
									<p>
										{{ tt('활성화된 멤버 / 라이선스 수') }}
									</p>
									<span class="license-member">
										<span :class="{ red: isMemberFull }">
											{{ formatNumberLocale(memberCount) }}
										</span>
										<span class="license-count">
											/
											{{ license.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(license.quantity) }}
										</span>
										<!-- <span class="red" v-if="isLicenseDepositWait">
											{{ `(+${license.scheduledQuantity})` + tt('추가예정') }}
										</span> -->
									</span>
								</li>
							</ul>
						</div>
						<button v-if="isLicenseDepositWait" class="Btn__yellow" v-text="tt('주문취소')" @click="clickOrderCancel"></button>
						<button
							v-else-if="!isLicenseDepositWait && userPermission.license && userPermission.license.create && !isEducation"
							class="Btn__yellow"
							v-text="!isLicenseActivate || isFreeTier || isTrial ? tt('라이선스 구매') : tt('라이선스 추가')"
							@click="!isLicenseActivate || isFreeTier || isTrial ? showPurchase('buying') : showPurchase('add')"
						></button>

						<button
							v-else-if="userPermission.license?.create && !isLicenseActivate"
							class="Btn__yellow"
							v-text="tt('라이선스 구매')"
							@click="showPurchase('buying')"
						></button>

						<div class="regular-payment" v-if="beingSubscription">
							<h5>{{ tt('다음 결제') }}</h5>
							<ul>
								<li>
									<p>{{ tt('등록된 결제 수단') }}</p>
									<b>
										{{
											`${licenseManagement.subscription.card.issuer}${tt('카드')} **** **** **** ${
												licenseManagement.subscription.card.number
											}`
										}}
									</b>
								</li>
								<li>
									<p>{{ tt('다음 결제일') }}</p>
									<span>
										{{ licenseManagement.subscription.paymentDate }}
									</span>
								</li>
								<li>
									<p>{{ tt('라이선스 수') }}</p>
									<span
										:class="{
											red: isLicenseLessThanPurchaseError,
										}"
									>
										{{
											licenseManagement.subscription.quantity == MAX_QUANTITY
												? tt('무제한')
												: formatNumberLocale(licenseManagement.subscription.quantity)
										}}
									</span>
								</li>
								<li>
									<p>{{ tt('라이선스 적용기간') }}</p>
									<span>
										{{ licenseManagement.subscription.period }}
									</span>
								</li>
								<li>
									<p>{{ tt('라이선스 적용일수') }}</p>
									<span>
										{{ formatNumberLocale(licenseManagement.subscription.month) + tt('개월') }}
									</span>
								</li>
								<li>
									<p>{{ tt('결제 예정 금액') }}</p>
									<span class="license-price">
										￦{{ formatNumberLocale(licenseManagement.subscription.totalPrice) }}
										<i>
											<img @click="toggle()" :class="{ folded: priceFolded }" src="@/assets/svg/arrow-down.svg" />
										</i>
									</span>
								</li>
								<li v-if="!priceFolded">
									<p>{{ tt('소계') }}</p>
									<span> ￦{{ formatNumberLocale(licenseManagement.subscription.price) }} </span>
								</li>
								<li v-if="!priceFolded">
									<p>VAT(10%)</p>
									<span> ￦{{ formatNumberLocale(licenseManagement.subscription.vat) }} </span>
								</li>
							</ul>
						</div>

						<!-- v-if="!isFreeTier && !beingSubscription" -->
						<button class="btn" v-if="!isFreeTier && beingSubscription && isLicenseActivate" @click="manageSubscribe">
							{{ tt('정기결제 관리') }}
						</button>

						<!-- <button
                        class="btn"
                        v-if="!isFreeTier && !beingSubscription"
                        @click="showPurchase('setting')"
                    >
                        {{ tt('정기결제 설정') }}
                    </button> -->
						<div
							class="not-use-noti"
							v-if="
								beingSubscription &&
								isPaymentCardError &&
								userPermission.license &&
								userPermission.license.update &&
								isLicenseActivate
							"
						>
							<p
								v-html="
									tt(
										'<span style=color:#ff5746;>결제카드 정보</span>와 관련하여 문제가 발생했습니다. <br>결제카드를 업데이트해주세요'
									)
								"
							></p>
							<!-- 카드 에러 -->
							<button @click="$emit('changeCard')">
								{{ tt('정기결제 관리') }}
							</button>
						</div>
						<div class="not-use-noti" v-if="memberMoreThanSubscribeLicense && userPermission.license && userPermission.license.update">
							<p
								v-html="
									tt(
										'<span style=color:#ff5746;>결제될 라이선스 수</span>보다 <span style=color:#ff5746;>멤버 수</span>가 더 많습니다.<br>정기결제 정보를 업데이트해주세요'
									)
								"
							></p>

							<button @click="showPurchase('buying')" v-if="userPermission.license && userPermission.license.update">
								{{ tt('정기결제 관리') }}
							</button>
						</div>
						<div class="not-use-noti" v-if="isSubscriptionPurchaseError">
							<p
								v-html="
									tt('<span style=color:#ff5746;>결제실패</span> 로 라이선스 결제가 완료되지 않았습니다.<br>문의: 02-1533-3061')
								"
							></p>
						</div>
					</div>
				</div>
			</div>
			<modal-confirm-delete-v-2
				ref="modal-confirm-delete"
				:title="tt('주문취소')"
				:content="tt('정말로 라이선스 구매 주문을 취소하시겠습니까?')"
				:delBtnText="tt('주문취소')"
				:delBtnFn="cancelOrder"
			></modal-confirm-delete-v-2>
			<modal-common-confirm
				:isShow="displayModalRegistCardConfirm"
				:title="tt('결제카드 등록')"
				:content="tt('정기결제를 설정하기 위해서는 먼저 결제카드를 등록해야합니다.')"
				@close="displayModalRegistCardConfirm = false"
				@confirm="displayModalRegistCard = true"
			></modal-common-confirm>
			<!-- modal-registering-card : 카드 정보 입력 모달 (결제카드 변경시)-->
			<modal-regist-card
				:isShow="displayModalRegistCard"
				@close="displayModalRegistCard = false"
				:mode="beingSubscription ? 'CHANGE' : 'REGIST'"
				@showManageSubscribe="showPurchase('management')"
				onlyRegist
			></modal-regist-card>
		</div>
	</portal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import MixinLicense from '@/mixins/license'
import ModalConfirmDeleteV2 from '@/views/common/components/modals/ModalConfirmDeleteV2.vue'
import ModalRegistCard from '@/views/institute/information/modals/ModalRegistCard.vue'
import { ToastType } from '@/utils/define/ToastType'
import ModalCommonConfirm from '@/views/common/components/modals/ModalCommonConfirm.vue'
import MixinPayment from '@/mixins/payment'
import LmBallon from '@/views/common/components/balloon/LmBallon.vue'
import MarginSpan from '@/views/common/components/style/MarginSpan.vue'

export default {
	name: 'ModalLicenseManagement',
	mixins: [MixinLicense, MixinPayment],
	components: {
		ModalConfirmDeleteV2,
		ModalRegistCard,
		ModalCommonConfirm,
		LmBallon,
		MarginSpan,
	},
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			priceFolded: true,
			//temp
			isRegular: true,
			isPrettier: false,
			isExpired: false,
			cardInfo: {
				isUse: true,
			},
			displayModalCancel: false,
			displayModalRegistCard: false,
			displayModalRegistCardConfirm: false,
		}
	},
	async created() {
		try {
			await this.loadLicenseRatePlan()
		} catch (e) {
			console.log(e)
		}
	},
	watch: {
		async isShow(value) {
			if (value) {
				await this.loadLicenseManagement()
				await this.loadLicenseRatePlan()
			}
		},
	},
	computed: {
		...mapState('license', ['licenseManagement']),
		...mapGetters('license', ['hasPaymentMethod']),
		// managementLicenseTitle() {
		//     return this.cardInfo.isUse
		//         ? this.tt('라이선스 관리')
		//         : this.tt('라이선스 설정')
		// },
		isMemberFull() {
			return this.license.quantity === this.memberCount
		},
		isLowerQuantityThanMember() {
			return this.quantity < this.memberCount
		},
		memberMoreThanSubscribeLicense() {
			return this.licenseManagement.subscription.errorCode === 1
		},
	},
	methods: {
		...mapActions('license', ['loadLicense', 'loadLicenseManagement', 'loadLicenseRatePlan', 'cancelWaitingLicense']),
		...mapActions('payment', ['loadDefaultCreditCard', 'registDefaultCreditCard']),
		close() {
			this.$emit('close')
		},
		toggle() {
			this.priceFolded = !this.priceFolded
		},
		manageSubscribe() {
			if (this.beingSubscription) {
				this.showPurchase('management')
			} else {
				// 정기결제 아닐때
				if (this.hasDefaultCreditCard) {
					// 기본 카드 있을때
					this.showPurchase('management')
				} else {
					this.displayModalRegistCardConfirm = true
				}
			}
		},
		showPurchase(mode) {
			// mode : buying(라이선스 구매)
			// mode : add(라이선스 추가)
			// mode : setting(정기결제 설정)
			// mode : management(라이선스 관리)
			this.$emit('purchase', mode)
		},
		clickOrderCancel() {
			this.$refs['modal-confirm-delete'].show()
		},
		async cancelOrder() {
			const response = await this.cancelWaitingLicense()
			if (response.status == 205) {
				this.showCancelOrderToast()
				this.loadLicense()
			}
			this.$refs['modal-confirm-delete'].hide()
			await this.loadLicenseManagement()
			this.close()
		},
		showCancelOrderToast() {
			const title = this.tt('라이선스 주문취소')
			const content = this.tt('라이선스 구매주문이 취소되었습니다.')
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
		},
	},
}
</script>

<style lang="scss" scoped>
.red {
	color: #ff5746;
	font-weight: 700 !important;
}

.license-header-text {
	display: flex;
}

.ModalLicenseManagement {
	width: 100%;
	height: 100%;
	z-index: 999;
	color: #000;
	.Popup__content {
		.license-info {
			.license-summary {
				display: flex;
				align-items: center;
				height: auto;
				.imgbox {
					width: 50px;
					height: 50px;
					overflow: hidden;
					border-radius: 10px;
					img {
						width: inherit;
						height: inherit;
					}
				}
				.txtbox {
					margin-left: 20px;
					h6 {
						font-size: 0.875rem;
						font-weight: 700;
					}
					p {
						font-size: 0.875rem;
						font-weight: 400;
						margin-top: 2px;
					}
				}
			}
			h5 {
				font-size: 0.875rem;
				font-weight: 700;
				margin-bottom: 10px;
			}
			ul {
				li {
					display: flex;
					min-height: 30px;
					align-items: center;
					p {
						font-size: 0.875rem;
						color: $SECONDARY_2;
						font-weight: 400;
					}
					.status {
						margin-left: auto;
						display: flex;
						align-items: center;
						span {
							margin-left: 5px;
							font-size: 0.875rem;
							font-weight: 400;
							&.license-status {
								height: 30px;
								border: 1px solid #b5e7d2;
								border-radius: 5px;
								display: inline-flex;
								font-size: 12px;
								font-weight: 700;
								align-items: center;
								padding: 0 10px;
								background-color: #f4fffa;
								color: $GREEN;
								img {
									margin-right: 2px;
								}
								&.expired {
									font-size: 12px;
									font-weight: 700;
									border: 1px solid #ffdad7;
									border-radius: 5px;
									color: #ff5746;
									background-color: #fff5f5;
									img {
										content: url(~@/assets/svg/sign-warning.svg);
									}
								}
							}
							&.waiting-status {
								height: 30px;
								border: 1px solid #ffdad7;
								border-radius: 5px;
								font-size: 12px;
								font-weight: 700;
								display: inline-flex;
								color: #ff5746;
								background-color: #fff5f5;
								display: inline-flex;
								align-items: center;
								padding: 0 10px;
							}
						}
					}
					> span {
						font-size: 0.875rem;
						font-weight: 400;
						display: inline-block;
						margin-left: auto;
						text-align: right;
						&.license-price {
							display: inline-flex;
							align-items: center;
							height: auto;
						}
						span {
							display: inline-block;
							font-size: inherit;
							font-weight: inherit;
							margin-left: 3px;
						}
						i {
							display: inline-flex;
							height: auto;
							align-items: center;
							cursor: pointer;
							img {
								margin-bottom: -2px;
								transform: rotate(180deg);
								transition: all 0.3s;
								&.folded {
									transform: rotate(360deg);
								}
							}
						}
					}
					> b {
						font-size: 0.875rem;
						font-weight: 700;
						display: inline-block;
						margin-left: auto;
						text-align: right;
					}
				}
			}
			button {
				width: 100%;
				height: 48px;
				font-size: 0.875rem;
				font-weight: 700;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 15px;
				&.Btn__yellow {
					box-shadow: unset;
				}
				&.btn {
					margin-bottom: 10px;
				}
			}
			.license-current {
				margin-top: 20px;
				.waiting-noti {
					ul {
						background: $GREY_0;
						display: block;
						padding: 10px 15px;
						border: 1px solid $DIVIDER;
						border-radius: 5px;
						font-size: 0.875rem;
						line-height: 1.4;
						margin-bottom: 10px;
						li {
							font-weight: 400;
							min-height: 22px;
							p.red {
								font-weight: bold;
								color: #ff5746;
							}
							&::before {
								content: '•';
								padding-bottom: 2px;
								padding-left: 3px;
								padding-right: 9px;
							}
						}
					}
					/* p {
                    } */
				}
			}
			.regular-payment {
				margin-top: 20px;
				padding-top: 20px;
				width: 100%;
				border-top: 1px solid $INPUT;
			}
			.not-use-noti {
				width: 100%;
				height: 60px;
				background-color: rgba(255, 87, 70, 0.1);
				border-radius: 5px;
				display: flex;
				align-items: center;
				padding: 10px 15px;
				margin: 10px 0;
				p {
					font-size: 0.875rem;
					color: #000;
					width: 320px;
					span {
						color: #ff5746;
						display: inline-block;
					}
				}
				button {
					font-size: 0.875rem;
					color: $BLUE;
					font-weight: 700;
					padding-right: 15px;
					background-image: url(~@/assets/svg/arrow_right_s.svg);
					background-repeat: no-repeat;
					background-position: top 11px right 0;
					background-size: 15px;
					width: unset;
					margin-top: 0;
				}
			}
		}
	}
}
</style>
