<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<input type="checkbox" class="checkbox" v-model="allCheck" :class="{ someChecked: !allCheck && !isEmptySelectedItems }" />
		</th>
		<th>
			<span>{{ tt('입력자') }}</span>
		</th>
		<th :class="orderClass('TYPE')" @click.stop="changeOrder('TYPE')">
			<span>{{ tt('내용') }}</span>
		</th>
		<th>
			<span>{{ tt('상세') }}</span>
		</th>
		<th v-if="isView('categoryClassification')">
			<span>{{ tt('카테고리 분류') }}</span>
		</th>
		<th v-if="isView('categoryType')">
			<span>{{ tt('카테고리 상세 분류') }}</span>
		</th>
		<th v-if="isView('category')">
			<span>{{ tt('카테고리') }}</span>
		</th>
		<th v-if="isView('mngCode')">
			<span>{{ tt('관리번호') }}</span>
		</th>
		<th v-if="isView('name')">
			<span>{{ tt('물품정보') }}</span>
		</th>
		<th v-if="isView('brand')">
			<span>{{ tt('브랜드') }}</span>
		</th>
		<th v-if="isView('productCode')">
			<span>{{ tt('제품번호') }}</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')" v-if="isView('createDatetime')">
			<span>{{ tt('시간') }}</span>
		</th>
		<th>
			<span style="min-width: 80px">{{ tt('기록 삭제') }}</span>
		</th>
		<th>
			<span style="min-width: 80px">{{ tt('물품 복구') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapState('inventoryGen2/history', ['list', 'selectedItems', 'checkedViewOption']),
		...mapGetters('inventoryGen2/history', ['order', 'orderDirection', 'isEmptySelectedItems']),
		allCheck: {
			get() {
				return this.list.every(it => this.selectedItems.map(sit => sit.id).includes(it.id)) && this.list.length !== 0
			},
			set(val) {
				if (val) this.setSelectedItems(this.list)
				else this.setSelectedItems([])
			},
		},
		defaultUnCheck() {
			return ['categoryClassification', 'categoryType', 'brand', 'productCode']
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapMutations('inventoryGen2/history', ['setSelectedItems']),
		...mapActions('inventoryGen2/history', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
