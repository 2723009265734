import { searchProduct } from '@/services/api/searchProduct'

const state = {
	list: null,
	count: null,
	listParams: {
		categoryType: null,
		keyword: null,
		length: 20,
		offset: 0,
		secondKeyword: '',
	},
	isSearched: false,
}

const getters = {
	list: state => state.list,
	count: state => state.count,
	listParams: state => state.listParams,
	isSearched: state => state.isSearched,
}

const mutations = {
	setDefault: state => {
		state.list = null
		state.count = null
		state.listParams = {
			categoryType: null,
			keyword: null,
			length: 20,
			offset: 0,
			secondKeyword: '',
		}
		state.isSearched = false
	},
	setList: (state, list) => {
		state.list = list
	},
	setCount: (state, count) => {
		state.count = count
	},
	setListParams: (state, listParams) => {
		state.listParams = listParams
	},
	setIsSearched: (state, isSearched) => {
		state.isSearched = isSearched
	},
}

const actions = {
	getSearchProduct: async ({ commit, rootGetters, state }) => {
		// params
		// categoryType, keyword, offset, length, secondKeyword, qrBarcode
		try {
			const response = await searchProduct(rootGetters.instituteId, {
				...state.listParams,
			})
			await commit('setList', response.data.list)
			await commit('setCount', response.data.count)
		} catch (e) {
		} finally {
			await commit('setIsSearched', true)
		}
	},
	addSearchProduct: async ({ commit, rootGetters, state }) => {
		let { length, offset } = state.listParams
		if (state.count > offset + length) {
			await commit('setListParams', { ...state.listParams, offset: offset + length })
			const response = await searchProduct(rootGetters.instituteId, state.listParams)
			await commit('setList', [...state.list, ...response.data.list])
		}
	},
	getSearchProductUsingQr: async ({ commit, rootGetters, state }) => {
		try {
			const response = await searchProduct(rootGetters.instituteId, {
				...state.listParams,
			})
			const { count, list } = response.data

			if (count === 1) {
				console.warn(list)
			} else {
				await commit('setList', list)
				await commit('setCount', count)
			}
		} catch (e) {
		} finally {
			await commit('setIsSearched', true)
		}
	},
}
export default { namespaced: true, state, getters, mutations, actions }
