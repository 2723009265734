<template>
	<div class="header-wrapper">
		<h2 class="header-title">{{ title }}</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<search-box class="search-box" :placeholder="tt('검색')" @change="changeKeyword"></search-box>
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
					:image="require('@/assets/svg/filter.svg')"
					@click="filterBtnClick"
				></image-button>
				<date-picker-button
					:image="require('@/assets/svg/list/inventory-calendar.svg')"
					:activeImage="require('@/assets/svg/list/active/calendar-active.svg')"
					:text="'날짜'"
					:subText="dateButtonSubtext"
					:defaultTime="calendarDatetime"
					:isActive="dateButtonSubtext != null"
					@pickDate="pickDate"
					@changeDate="changeCalendar"
				></date-picker-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button :text="'보기옵션'" :image="require('@/assets/svg/ViewOption(Button).svg')" @click="watchBtnClick"></image-button>
				<excel-download-button @downloadAll="exportList('ALL')" @downloadFiltered="exportList('FILTERED')" />
				<image-button
					v-if="userPermission && userPermission.item.dump.update"
					:text="'복구'"
					:disabled="selectedItems.length === 0"
					:image="require('@/views/inventoryGen2/svg/Restore.svg')"
					@click="$refs['modal-restore'].show()"
				></image-button>
				<image-button
					v-if="userPermission && userPermission.item.dump.delete"
					:text="'영구삭제'"
					:disabled="selectedItems.length === 0"
					:image="require('@/assets/svg/Trash.svg')"
					@click="$refs['modal-remove'].show()"
				></image-button>
			</div>
		</div>
		<modal-filter ref="modal-filter" @changeLoadParams="setLoadParams" />
		<modal-view-option ref="modal-view-option" />
		<modal-confirm
			ref="modal-restore"
			title="물품 복구"
			confirmText="복구"
			:content="`선택된 ${selectedItems.length}개 물품을 복구하시겠습니까?`"
			@confirm="restore"
		/>
		<modal-confirm
			ref="modal-remove"
			title="물품 영구삭제"
			confirmText="영구삭제"
			variant="warning"
			:content="`선택된 ${selectedItems.length}개 물품을 영구적으로 삭제하시겠습니까?`"
			@confirm="remove"
		/>
	</div>
</template>

<script>
import SearchBox from '@/views/components/searchBox'
import ModalFilter from './ModalFilter.vue'
import ModalViewOption from './ModalViewOption.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import DatePickerButton from '@/views/common/components/baseButton/DatePickerButton.vue'
import ExcelDownloadButton from '@/views/common/components/baseButton/ExcelDownloadButton.vue'
import MixinListFixedHeader from '@/views/inventoryGen2/mixin/MixinListFixedHeader'

export default {
	mixins: [MixinListFixedHeader],
	components: {
		SearchBox,
		ModalFilter,
		ModalViewOption,
		DatePickerButton,
		ExcelDownloadButton,
		MixinListFixedHeader,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		previousPage: {
			type: Object,
			default: null,
		},
	},
	computed: {
		...mapState('inventoryGen2/disposal', ['filterCookieName', 'selectedItems']),
		...mapGetters('inventoryGen2/disposal', ['filteredCount']),
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
	},
	data() {
		return {
			isActiveCalendar: false,
			cookieFilterKey: 'inventoryDisposalFilter',
			cookieDatetimeFilterKey: 'inventoryDisposalDatetimeFilter',
		}
	},
	mounted() {
		this.initList()
	},
	methods: {
		...mapMutations('inventoryGen2/disposal', {
			setParams: 'setLoadParams',
			initFilterCookies: 'initFilterCookies',
			setSelectedItems: 'setSelectedItems',
		}),
		...mapActions('inventoryGen2/disposal', ['setLoadParams', 'exportExcelDisposeList', 'restoreItem', 'removeItem']),
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		watchBtnClick() {
			this.$refs['modal-view-option'].show()
		},
		pickDate() {
			this.$root.toast(this.tt('기간 필터 적용 완료'), this.tt('기간 필터가 적용되었습니다.'), 'success')
		},
		async changeKeyword(keyword) {
			await this.setLoadParams({ keyword })
		},
		async exportList(type) {
			const response = await this.exportExcelDisposeList(type)
			window.fileDownload(response, `${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('폐기/삭제된 물품 목록')}.xlsx`)
		},
		initList() {
			console.log(1)
			if (
				this.previousPage?.name !== null &&
				(this.$route.name !== this.recentPageName || this.previousPage?.name !== PageName.InventoryGen2.DisposalDetail)
			) {
				this.setSelectedItems([])
				this.initFilterCookies(true)
			}
			let query = Object.assign({}, this.$route.query)
			let params = {}
			if (query?.status) {
				params.status = query.status instanceof Array ? query.status : [query.status]
				this.$cookies.set(this.filterCookieName.status, JSON.stringify(params.status))
			}
			if (query?.categoriesId) {
				params.categoriesId = query.categoriesId instanceof Array ? query.categoriesId : [query.categoriesId]
				this.$cookies.set(this.filterCookieName.categoriesId, JSON.stringify(params.categoriesId))
			}
			if (query?.startDatetime) {
				params.startDatetime = query.startDatetime
			}
			if (query?.endDatetime) {
				params.endDatetime = query.endDatetime
			}
			console.log(params)
			this.setParams(params)
			if (params.startDatetime && params.endDatetime) {
				this.changeCalendar([params.startDatetime, params.endDatetime])
			} else this.createdInit()
			if (Object.keys(query).length > 0) this.$router.replace({ query: {} })
		},
		async restore() {
			await this.restoreItem({ itemsId: this.selectedItems.map(it => it.id) })
			this.$root.toast('물품 복구', `${this.selectedItems.length}개의 물품이 복구되었습니다.`, 'success')
			this.setSelectedItems([])
		},
		async remove() {
			await this.removeItem({ itemsId: this.selectedItems.map(it => it.id) })
			this.$root.toast('물품 영구삭제', `${this.selectedItems.length}개의 물품이 영구삭제되었습니다.`, 'success')
			this.setSelectedItems([])
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/list/_list-header.scss';
</style>
