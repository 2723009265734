var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('migration-header', {
    attrs: {
      "isMigration": true
    }
  }), _c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h3', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })]), _c('div', {
    staticClass: "button-wrapper"
  }, [_c('lm-confirm-button-middle', {
    attrs: {
      "color": "gray",
      "text": _vm.cancelText
    },
    on: {
      "click": _vm.goToExperience
    }
  })], 1), _c('div', {
    staticClass: "bottom-text-wrapper",
    on: {
      "click": _vm.openMigrationDocs
    }
  }, [_c('span', {
    staticClass: "bottom-text",
    domProps: {
      "innerHTML": _vm._s(_vm.bottomText)
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }