import axiosInstance from '@/services/axios'
import institute from '@/store/modules/institute'
import ParamMaker from '@/utils/ParamMaker'

const baseStoreURL = instituteId => `/institute/${instituteId}/store/tunnel/mall`

const endPoint = {
	instituteId: instituteId => `/institute/${instituteId}/store/institute`,
	institute: instituteId => `${baseStoreURL(instituteId)}/institute/${instituteId}`,
	list: instituteId => `${baseStoreURL(instituteId)}/catalog/search`,
	item: instituteId => `${baseStoreURL(instituteId)}/catalog`,
	productList: instituteId => `${baseStoreURL(instituteId)}/catalog/product/list`,
	//
	cartList: instituteId => `${baseStoreURL(instituteId)}/institute/${instituteId}/cart/list`, // m10 기획 변경으로 인한 장바구니 미사용
	estimateList: instituteId => `${baseStoreURL(instituteId)}/institute/${instituteId}/estimate/list`, // m10 기획 변경으로 인한 견적서 미사용
	estimate: instituteId => `${baseStoreURL(instituteId)}/institute/${instituteId}/estimate`, // m10 기획 변경으로 인한 견적서 미사용
	deliveryList: instituteId => `${baseStoreURL(instituteId)}/institute/${instituteId}/delivery/list`,
	addAddress: instituteId => `${baseStoreURL(instituteId)}/institute/${instituteId}/delivery/`,
	requirement: (instituteId, storeInstId) => `${baseStoreURL(instituteId)}/institute/${instituteId}/requirement`,
	savedProductList: instituteId => `${baseStoreURL(instituteId)}/product/list`,
	// TODO 잔여 용량 조회
	ownStoreProduct: instituteId => `/institute/${instituteId}/inventory/own/store-products`,
	nopaidSummary: instituteId => `/institute/${instituteId}/draft/order/nopaid/summary/monthly`,
	orderInfo: instituteId => `${baseStoreURL(instituteId)}/institute/${instituteId}/order`,
	promotionBrandList: instituteId => `${baseStoreURL(instituteId)}/brand/list/promotion`,
}

const api = {
	list: (instituteId, params) => axiosInstance.get(endPoint.list(instituteId), { params }),
	item: (instituteId, params) => axiosInstance.get(endPoint.item(instituteId), { params: params }),
	productList: (instituteId, params) => axiosInstance.get(endPoint.productList(instituteId), { params }),
	cartList: (instId, params) => axiosInstance.get(endPoint.cartList(instId), { params }),
	instituteId: instituteId => axiosInstance.get(endPoint.instituteId(instituteId)),
	institute: instituteId => axiosInstance.get(endPoint.institute(instituteId)),
	deliveryList: instituteId => axiosInstance.get(endPoint.deliveryList(instituteId)),
	addAddress: (instituteId, body) => axiosInstance.post(endPoint.addAddress(instituteId), body),
	updateAddress: (instituteId, body) => axiosInstance.patch(endPoint.addAddress(instituteId), body),
	deleteAddress: (instituteId, params) => axiosInstance.delete(endPoint.addAddress(instituteId), { params }),
	estimateList: (instituteId, params) => axiosInstance.get(endPoint.estimateList(instituteId), { params }),
	estimate: (instituteId, id) => axiosInstance.get(endPoint.estimate(instituteId), { params: { id } }),
	savedProductList: (instituteId, params) => axiosInstance.get(endPoint.savedProductList(instituteId), { params }),
	// TODO 잔여 용량 조회
	ownStoreProduct: (instituteId, params) => axiosInstance.get(endPoint.ownStoreProduct(instituteId), { params }),
	requirement: (instituteId, storeInstId) => axiosInstance.get(endPoint.requirement(instituteId, storeInstId)),
	nopaidSummary: instituteId => axiosInstance.get(endPoint.nopaidSummary(instituteId)),
	orderInfo: (instituteId, params) => axiosInstance.get(endPoint.orderInfo(instituteId), { params }),
	getPromotionBrandList: instituteId => axiosInstance.get(endPoint.promotionBrandList(instituteId)),
}

export default api
