var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isActive ? _c('div', {
    staticClass: "info-field-wrap"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name)))]), _vm.isRequire && _vm.value == null ? _c('div', {
    staticClass: "field-data-wrap-red"
  }, [_vm._v(_vm._s(_vm.tt('미입력')))]) : _c('div', {
    staticClass: "field-data-wrap",
    "class": {
      unfold: _vm.isFold
    }
  }, [_c('div', {
    ref: "field-text",
    staticClass: "field-text",
    "class": {
      unfold: _vm.isFold
    }
  }, [_vm._v(_vm._s(_vm.value))]), _vm.isShowTriangle ? _c('img', {
    staticClass: "fold-triangle",
    attrs: {
      "src": require("@/views/purchase/cargo/svg/triangle-".concat(_vm.isFold ? 'up' : 'down', ".svg"))
    },
    on: {
      "click": function click($event) {
        _vm.isFold = !_vm.isFold;
      }
    }
  }) : _vm._e()])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }