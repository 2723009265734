<template>
	<div class="ghs-wrapper">
		<img class="ghs-icon-img" src="@/views/inventoryGen2/svg/detail/icon-ghs-item.svg" />
		<div class="ghs-content">
			<div class="ghs-title">
				{{ tt(title) }}
			</div>
			<div class="ghs-subtext">
				{{ tt(subtext) }}
			</div>
		</div>
		<div class="btn-wrapper">
			<button class="show-ghs">{{ tt('보러가기') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GhsItem',
	props: {
		ghs: {
			type: Object,
			default: () => {},
		},
	},
	components: {},
	computed: {
		title() {
			return this.tt('GHS 라벨')
		},
		subtext() {
			return `${this.tt('${1}님이 업로드하였습니다', this?.ghs?.user?.name)} (${this.formatDate(this.ghs?.createDatetime, 'yyyy.MM.dd')})`
		},
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.ghs-wrapper {
	display: flex;
	border-radius: 8px;
	padding: 18px;
	background: $GREY_0;
	align-items: center;
	border: 1px solid $DIVIDER;
	width: 415px;
	height: 70px;
	.ghs-icon-img {
		width: 36px;
		height: 36px;
		border-radius: 5px;
		border: 0.5px solid $COLOR_CCC;
		margin-top: 2px;
	}
	.ghs-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 10px;
		.ghs-title {
			font-size: 14px;
			font-weight: 700;
		}
		.ghs-subtext {
			text-align: left;
			font-size: 12px;
			font-weight: 400;
			color: $BLACK_60;
		}
	}
	.btn-wrapper {
		display: flex;
		align-items: center;
		.show-ghs {
			height: 30px;
			font-size: 14px;
			font-weight: 700;
			color: $COLOR_000000DE;
			background: $GREY_4;
			border-radius: 15px;
			padding: 5px 10px;
		}
	}
}
</style>
