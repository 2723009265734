var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-item"
  }, [_c('td', [_c('div', [_vm._v(_vm._s(_vm.createDatetime))])]), _c('td', [_c('div', {
    staticStyle: {
      "min-width": "250px",
      "max-width": "300px"
    }
  }, [_vm._v(_vm._s(_vm.item.budget.name))])]), _c('td', [_c('div', [_vm._v(_vm._s(_vm.item.budget.code))])]), _c('td', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticStyle: {
      "min-width": "200px",
      "max-width": "250px"
    },
    attrs: {
      "title": _vm.item.draft.subject
    }
  }, [_vm._v(" " + _vm._s(_vm.item.draft.subject ? _vm.item.draft.subject : _vm.tt('기안없이 상품 주문')) + " ")])]), _c('td', {
    staticClass: "right"
  }, [_c('div', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.item.amount.toLocaleString())))])]), _c('td', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.item.draft.group.name
    }
  }, [_vm._v(_vm._s(_vm.item.draft.group.name))])]), _c('td', [_c('div', {
    staticClass: "profile-wrap"
  }, [_c('img', {
    staticClass: "profile-img",
    attrs: {
      "src": _vm.userImage
    }
  }), _vm._v(" " + _vm._s(_vm.item.draft.user.name))])]), _c('td', [_c('div', [_vm._v(_vm._s(_vm.draftCreateDatetime))])]), _c('td', [_c('div', [_vm._v(_vm._s(_vm.draftConcludeDatetime))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }