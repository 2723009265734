<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<input
				type="checkbox"
				:active="isCheckedAll"
				v-model="isChecked"
				:class="{ someChecked: !isCheckedAll && !isEmptySelectItem }"
				@change="handleChange"
			/>
		</th>
		<th :class="orderClass('STATUS')" @click.stop="changeOrder('STATUS')">
			<span>{{ tt('입고상태') }}</span>
		</th>
		<th :class="orderClass('MULTI_CREATE_COUNT')" @click.stop="changeOrder('MULTI_CREATE_COUNT')" v-if="isView('multiCreateCount')">
			<span>{{ tt('수량') }}</span>
		</th>
		<th :class="orderClass('STORAGE')" @click.stop="changeOrder('STORAGE')" v-if="isView('location')">
			<span>{{ tt('위치/보관함') }}</span>
		</th>
		<th :class="orderClass('REGISTER_METHOD')" @click.stop="changeOrder('REGISTER_METHOD')" v-if="isView('registerMethod')">
			<span>{{ tt('구매처') }}</span>
		</th>
		<th :class="orderClass('NAME')" @click.stop="changeOrder('NAME')" v-if="isView('name')">
			<span>{{ tt('정보') }}</span>
		</th>
		<th v-if="isView('category')">
			<span>{{ tt('카테고리') }}</span>
		</th>
		<th :class="orderClass('BRAND_NAME')" @click.stop="changeOrder('BRAND_NAME')" v-if="isView('brand')">
			<span>{{ tt('브랜드') }}</span>
		</th>
		<th :class="orderClass('PRODUCT_CODE')" @click.stop="changeOrder('PRODUCT_CODE')" v-if="isView('productCode')">
			<span>{{ tt('제품번호') }}</span>
		</th>
		<th :class="orderClass('VOLUME')" @click.stop="changeOrder('VOLUME')" v-if="isView('volume')">
			<span>{{ tt('용량/패키지 수량') }}</span>
		</th>
		<th :class="orderClass('SHIP_STATUS')" @click.stop="changeOrder('SHIP_STATUS')" v-if="isView('shipStatus')">
			<span>{{ tt('배송정보') }}</span>
		</th>
		<th v-if="isView('register')">
			<span>{{ tt('입고자') }}</span>
		</th>
		<th v-if="isView('completeDatetime')">
			<span>{{ tt('입고일시') }}</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')">
			<span>{{ tt('목록 추가일시') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	name: 'CargoTableHeader',
	components: {},
	props: {},
	data() {
		return {
			isChecked: false,
		}
	},
	mixins: [MixinHeader],
	computed: {
		...mapState('cargo/list', ['checkedViewOption']),
		...mapGetters('cargo/list', ['list', 'selectItem', 'isEmptySelectItem', 'order', 'orderDirection']),
		isCheckedAll() {
			return this.list?.length === this.selectItem.length
		},
	},
	watch: {
		isCheckedAll(value) {
			this.isChecked = value
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapActions('cargo/list', ['setLoadParams']),
		...mapMutations('cargo/list', ['setSelectItem']),
		handleChange() {
			this.isChecked ? this.setSelectItem(this.list) : this.setSelectItem([])
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
