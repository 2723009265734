var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: (_vm.isShowRequire || _vm.isShowError) && !_vm.value
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    staticClass: "storage",
    attrs: {
      "type": "text",
      "readonly": "",
      "placeholder": _vm.tt(_vm.placeholder)
    },
    domProps: {
      "value": _vm.fullpath
    },
    on: {
      "click": _vm.showModalTree
    }
  }), _c('button', {
    staticClass: "storage-button",
    on: {
      "click": _vm.showModalTree
    }
  }, [_vm._v(_vm._s(_vm.tt('선택')))])]), (_vm.isShowRequire || _vm.isShowError) && !_vm.value ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.tt(_vm.errorMessage)))]) : _vm._e(), _c('modal-single-select-storage', {
    ref: "modal-select-storage",
    attrs: {
      "title": "보관함 선택"
    },
    on: {
      "complete": _vm.selectStorage
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }