import draftAPI from '@/services/api/purchase/draft'
import productAPI from '@/services/api/purchase/product'
import lmsAPI from '@/services/api/purchase/lms'
import _ from 'lodash'
import item from '../cargo/item'
import list from './list'

const state = {
	item: {},
	groupList: [],
	usableBudgetList: [],
	draftInfo: {
		groupId: null, // 그룹 ID
		subject: null, // 제목
		content: null, // 내용
		budgetId: null, // 예산 ID
		purchaseType: 'INTERNAL', // 구매 타입 - temp : INTERNAL
		expendAmount: 0, // 지출금액
		// 결재선
		approvalLine: [],
		// 구매 상품
		purchaseItems: [],
		attachments: [],
	},
	selectedItems: [],
	ownStoreProductList: [],
	selectedGroup: null,
	ordererInfo: {},
}

const getters = {
	getUsableBudgetList: state => {
		return state.usableBudgetList
			.map(budget => {
				return {
					code: budget.code,
					name: budget.name,
					value: budget.id,
				}
			})
			.concat([
				{
					code: null,
					name: '선택안함',
					value: null,
				},
			])
	},
	getSelectedBudget: state => state.usableBudgetList.find(budget => budget.id === state.draftInfo.budgetId),
}

const mutations = {
	setOrdererInfo: (state, data) => {
		state.ordererInfo = data
	},
	setItem: (state, data) => {
		state.item = data
	},
	setGroupList: (state, payload) => {
		state.groupList = payload
	},
	setUsableBudgetList: (state, payload) => {
		state.usableBudgetList = payload
	},
	// regist
	initDraftRegistration: state => {
		state.draftInfo = {
			groupId: null, // 그룹 ID
			subject: null, // 제목
			content: null, // 내용
			budgetId: null, // 예산 ID
			purchaseType: 'INTERNAL', // 구매 타입 - temp : INTERNAL
			expendAmount: 0, // 지출금액
			// 결재선
			approvalLine: [],
			// 구매 상품
			purchaseItems: [],
			attachments: [],
			estimateId: null,
			deliveryId: null,
			ordererName: null,
			ordererEmail: null,
			ordererPhone: null,
			recipientName: null,
			recipientPhone: null,
			deliveryRequest: null,
		}
		state.selectedGroup = null
	},
	initDraftRegistrationStep2: state => {
		state.draftInfo = {
			...state.draftInfo,
			...{ purchaseItems: [], attachments: [], expendAmount: 0 },
		}
	},
	setDraftInfo: (state, payload) => {
		state.draftInfo = payload
	},
	setDraftGroupId: (state, payload) => {
		state.draftInfo.groupId = payload
	},
	setDraftGroup: (state, payload) => {
		state.selectedGroup = payload
	},
	setDraftApprovalLine: (state, payload) => {
		state.draftInfo.approvalLine = payload
	},
	// step 2
	setPurchaseItems: (state, payload) => {
		if (state.draftInfo.estimateId) {
			state.draftInfo.purchaseItems = []
			state.draftInfo.estimateId = null
		}
		;(payload instanceof Array ? payload : [payload]).forEach(item => {
			// let exItem = state.draftInfo.purchaseItems.find(it => it.productId == item.productId)
			// if (exItem) {
			// 	exItem.cartId = item.cartId
			// 	exItem.quantity += item.quantity
			// } else {
			state.draftInfo.purchaseItems.push(item)
			// }
		})
	},
	setOwnStoreProductList: (state, payload) => {
		payload.forEach(it => {
			if (!state.ownStoreProductList.some(item => item.productCode === it.productCode)) {
				state.ownStoreProductList.push(it)
			}
		})
	},
	initPurchaseItems: state => {
		state.draftInfo.purchaseItems = []
	},
	setQuantity: (state, payload) => {
		const { index, quantity } = payload
		state.draftInfo.purchaseItems[index].quantity = quantity
		state.draftInfo.purchaseItems[index].sellingPrice = state.draftInfo.purchaseItems[index].sellingUnitPrice * quantity
		state.draftInfo.purchaseItems[index].discountPrice = state.draftInfo.purchaseItems[index].discountUnitPrice * quantity
	},
	setExpendAmount: state => {
		let price = 0
		state.draftInfo.purchaseItems?.forEach(product => {
			price += product.sellingPrice
				? product.sellingPrice + product.sellingPrice / 10
				: (product.unitPrice + product.unitPrice / 10) * product.quantity
		})
		state.draftInfo.expendAmount = price
	},
	setSelectedItems: (state, data) => {
		state.selectedItems = data
	},
	deletePurchaseItems: state => {
		state.draftInfo.purchaseItems = state.draftInfo.purchaseItems.filter((item, index) => {
			return !state.selectedItems.includes(index)
		})
		state.selectedItems = []
	},
	deleteAttachments: (state, index) => {
		state.draftInfo.attachments.splice(index, 1)
	},
	updateDraftInfo: (state, payload) => {
		Object.keys(payload).forEach(key => {
			state.draftInfo[key] = payload[key]
		})
	},
	setBudgetId: (state, id) => {
		state.draftInfo.budgetId = id
	},
	setNoSale: state => {
		state.draftInfo?.purchaseItems?.forEach(it => {
			it.discountUnitPrice = 0
			it.sellingUnitPrice = it.unitPrice
			it.discountPrice = 0
			it.sellingPrice = it.unitPrice * it.quantity
		})
	},
}

const actions = {
	getDetail: async ({ commit, rootGetters }, id) => {
		const response = await draftAPI.detail(rootGetters.instituteId, id)
		commit('setItem', response.data)
	},
	delete: async ({ rootGetters }, id) => {
		await draftAPI.delete(rootGetters.instituteId, id)
	},
	// regist
	getGroupList: async ({ state, commit }) => {
		const response = await draftAPI.groupList()
		commit('setGroupList', response.data)
		// TODO: 기안작성자가 소속된 그룹 or 연구실이 없이 최상위 기관에만 소속되어있을때를 대비한 api 변경이 이루어지면 주석 제거
		let labGroup = response.data.filter(it => it.type === 'LAB' || it.type === 'NORMAL')
		if (labGroup.length > 0) commit('setDraftInfo', { ...state.draftInfo, ...{ groupId: labGroup[0].id } })
		else commit('setDraftInfo', { ...state.draftInfo, ...{ groupId: response.data[0].id } })
	},
	getUsableBudgetList: async ({ commit, rootGetters }, groupId) => {
		const response = await draftAPI.usableBudgetList(rootGetters.instituteId, groupId)
		commit('setUsableBudgetList', response.data)
	},
	setDraftGroupId: ({ commit, dispatch }, groupId) => {
		commit('setDraftGroupId', groupId)
		dispatch('getUsableBudgetList', groupId)
	},
	setPurchaseItems: async ({ commit, dispatch }, payload) => {
		let list = []
		if (payload instanceof Array) {
			payload.forEach((item, index) => {
				if (item.brand && item.brand.masterId) {
					list.push({
						[index]: {
							masterBrandId: item.brand.masterId,
							productCode: item.code,
						},
					})
				}
			})
		} else {
			if (payload.brand && payload.brand.masterId)
				list.push({
					masterBrandId: payload.brand.masterId,
					productCode: payload.productCode,
				})
		}
		if (list.length > 0) dispatch('ownStoreProduct', { list: list })
		commit('setPurchaseItems', payload)
		commit('setExpendAmount')
	},
	setQuantity: ({ commit }, payload) => {
		commit('setQuantity', payload)
		commit('setExpendAmount')
	},
	deletePurchaseItems: ({ commit }, index) => {
		commit('deletePurchaseItems', index)
		commit('setExpendAmount')
	},
	createDraft: async ({ rootGetters }, formData) => {
		return await draftAPI.create(rootGetters.instituteId, formData)
	},
	createOrder: async ({ rootGetters }, formData) => {
		return await productAPI.order(rootGetters.instituteId, formData)
	},
	ownStoreProduct: async ({ commit, rootGetters }, params) => {
		const response = await lmsAPI.ownStoreProduct(rootGetters.instituteId, params)
		commit('setOwnStoreProductList', response.data)
	},
	savedProductList: async ({ commit, rootGetters }, list) => {
		const payload = { productIds: list.map(it => it.productId), instituteId: rootGetters.instituteId, promotionCode: 'M10' }
		const response = await lmsAPI.savedProductList(rootGetters.instituteId, payload)
		commit(
			'setPurchaseItems',
			response.data.list.map(it => {
				return {
					...it,
					quantity: list.find(t => t.productId === it.productId).quantity,
					sellingPrice: list.find(t => t.productId === it.productId).quantity * it.sellingUnitPrice,
					discountPrice: list.find(t => t.productId === it.productId).quantity * it.discountUnitPrice,
					volume: parseFloat(it.options[0].value),
					volumeUnit: it.options[0].value.replace(/[^a-z]/gi, ''),
				}
			})
		)
		commit('setExpendAmount')
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
