<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddAuthorityMemberPopup Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt(title) }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<lm-input-search class="block" @search="searchMember" :placeholder="tt(searchBoxPlaceholder)"></lm-input-search>
					<div class="Organization__member-list">
						<ul class="horizational-list list-wrap" ref="list" @scroll.passive="scroll">
							<div class="no-data-box" v-if="!hasMember">
								<img src="@/assets/svg/group-none.svg" alt="no-image" />
								<p v-if="!isSearched" v-html="tt('기관 내 멤버가 없습니다.') + '<br>' + tt('멤버를 추가해주세요')"></p>
								<button v-if="!isSearched" @click="goToMemberManagement">
									{{ tt('멤버 관리') }}
								</button>

								<p v-if="isSearched" v-html="tt('기관 내 멤버가 없습니다.') + '<br>' + tt('멤버를 추가해주세요')"></p>
							</div>
							<li
								@mouseover="hoverItem(member.id)"
								@mouseout="notHoverItem()"
								class="Organization__member-item"
								:class="{
									checked: isChecked(member.id),
									included: isIncluded(member.id),
								}"
								v-for="member in memberList.members"
								:key="member.id"
								@click="selectId(member.id)"
							>
								<img src="@/assets/svg/member-detail-photo.svg" alt="group-image" />

								<div class="Organization__group-item-info">
									<h6 v-html="highlightName(member.name)"></h6>
									<!-- <h6>{{member.name}}</h6> -->
									<p>{{ showGroupNames(member.groups) }}</p>
								</div>
								<button>
									<img src="@/assets/svg/add-member-plus.svg" alt="삭제 icon" />
								</button>
							</li>
						</ul>
					</div>
				</div>
				<div class="Popup__bottom">
					<button class="Btn__management" @click="goToMemberManagement" v-if="!isStart">
						{{ tt('멤버 관리') }}
					</button>
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="complete">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import LmInputSearch from '@/views/common/components/input/LmInputSearch'
// import SearchBox from '@/views/common/components/SearchBox.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import instituteGroupAPI from '@/services/api/institute/group'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ModalAddMember',
	components: { LmInputSearch },
	mixins: [MixinModal],
	props: {
		groupInfo: {
			type: Object,
			default: () => {},
		},
		isStart: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			title: '',
			originMemberList: [],
			selectedMemberList: [],
			searchBoxPlaceholder: '멤버 이름으로 검색해주세요',
			offset: 0,
			length: 10,
			searchBoxInputValue: '',
			isSearched: false,
			hoveredId: '',
			totalCount: 0,
			members: [],
			prevMemberList: [],
		}
	},
	computed: {
		...mapState('member', ['memberList']),
		...mapGetters('organization', ['selectedGroupInfo']),
		hasMember() {
			return this.memberList.members.length > 0
		},
		isAddMember() {
			return this.modalType === 'ADD_MEMBER'
		},
		isAddMemberAccess() {
			return this.modalType === 'ADD_MEMBER_ACCESS'
		},
	},
	methods: {
		...mapActions('member', ['lookupMemberList']),
		show(type) {
			this.isShow = true
			this.modalType = type

			if (this.isAddMember) {
				this.title = this.tt('그룹원 추가')
				if (this.groupInfo.members.length > 0) {
					this.groupInfo.members.forEach(x => {
						this.selectedMemberList.push(x.id)
					})
				} else {
					this.selectedMemberList = []
				}
			}
			if (this.isAddMemberAccess) {
				this.title = this.tt('권한 추가: 멤버')
				if (this.groupInfo.access.users.length > 0) {
					this.groupInfo.access.users.forEach(x => {
						this.selectedMemberList.push(x.id)
					})
				}
			}
			this.prevMemberList = [...this.selectedMemberList]
			this.setInstMemberList()
		},
		hide() {
			this.isShow = false
			this.selectedMemberList = []
			this.initSearchOption()
		},
		initSearchOption() {
			this.offset = 0
			this.keyword = ''
			this.searchBoxInputValue = ''
			this.isSearched = false
		},
		async complete() {
			const { id } = this.selectedGroupInfo
			const completeData = {
				ids: this.selectedMemberList.filter(x => {
					return !this.prevMemberList.includes(x)
				}),
			}
			const deleteData = {
				ids: this.prevMemberList.filter(x => {
					return !this.selectedMemberList.includes(x)
				}),
			}
			if (this.isAddMember) {
				if (deleteData.ids.length > 0) {
					const deleteResponse = await instituteGroupAPI.deleteGroupMember(id, deleteData.ids)
				}

				if (completeData.ids.length > 0) {
					const response = await instituteGroupAPI.addGroupMember(id, completeData)
				}
				this.showAddGroupToast()
				this.$emit('refresh')
				this.hide()
				this.$emit('changeOrderAccess')
			} else if (this.isAddMemberAccess) {
				const requestData = {
					usersId: this.selectedMemberList.filter(x => {
						return !this.prevMemberList.includes(x)
					}),
					permission: {
						read: true,
						update: false,
					},
				}
				await instituteGroupAPI.addAccessAuthority(id, requestData)
				this.showAddAuthToast()
				this.$emit('refresh')
				this.hide()
				this.$emit('completeOrganization')
			}
		},
		showAddGroupToast() {
			const title = '그룹원 추가완료'
			const content = '그룹원이 추가되었습니다'
			const type = 'success'
			this.$root.toast(title, content, type)
		},
		showAddAuthToast() {
			const title = '접근 권한 추가완료'
			const content = '접근 권한이 추가되었습니다'
			const type = 'success'
			this.$root.toast(title, content, type)
		},
		setInstMemberList(isSearch) {
			const { instituteId, id } = this.selectedGroupInfo
			this.keyword = this.searchBoxInputValue
			const payload = {
				instId: instituteId,
				keyword: this.searchBoxInputValue,
			}
			this.lookupMemberList(payload)
			this.members = [...this.memberList.members]
			this.totalCount = this.memberList.count
			const newMemberList = this.members.filter(member => {
				if (this.isAddMember) {
					const isInGroup = member.groups.map(item => item.id).indexOf(id) > -1
					if (isInGroup) {
						return
					} else {
						return member
					}
				} else if (this.isAddMemberAccess) {
					const hasAuth = this.selectedGroupInfo.access.users.map(user => user.id).indexOf(id) > -1
					if (hasAuth) {
						return
					} else {
						return member
					}
				}
				this.members = [...this.members, ...newMemberList]
			})
			if (isSearch) {
				this.isSearched = true
			}
		},
		// 무한 스크롤시 데이터 추가
		addInstMemberList() {
			const { instituteId, id } = this.selectedGroupInfo
			this.keyword = this.searchBoxInputValue
			this.lookupMemberList({
				instId: instituteId,
				keyword: this.keyword,
			}).then(() => {
				this.members = [...this.memberList.members]
				this.totalCount = this.memberList.count
				const newMemberList = this.members.filter(member => {
					if (this.isAddMember) {
						const isInGroup = member.groups.map(item => item.id).indexOf(id) > -1
						if (isInGroup) {
							return
						} else {
							return member
						}
					} else if (this.isAddMemberAccess) {
						const hasAuth = this.selectedGroupInfo.access.users.map(user => user.id).indexOf(id) > -1
						if (hasAuth) {
							return
						} else {
							return member
						}
					}
				})
				this.members = [...this.members, ...newMemberList]
			})
		},
		selectId(id) {
			const idx = this.selectedMemberList.indexOf(id)
			if (idx > -1) {
				this.selectedMemberList.splice(idx, 1)
			} else {
				this.selectedMemberList.push(id)
			}
		},
		scroll() {
			const { clientHeight, scrollTop, scrollHeight } = this.$refs['list']
			const isScrollEnd = clientHeight + scrollTop === scrollHeight
			const hasMoreData = this.totalCount - (this.offset + this.length) > 0
			// 무한 스크롤 조건
			if (hasMoreData && isScrollEnd) {
				this.offset += this.length
				this.addInstMemberList()
			}
		},
		searchMember(searchName) {
			this.offset = 0
			this.searchBoxInputValue = searchName
			this.setInstMemberList(true)
		},
		hoverItem(id) {
			this.hoveredId = id
		},
		notHoverItem() {
			this.hoveredId = ''
		},
		isChecked(id) {
			return this.selectedMemberList.indexOf(id) > -1
		},
		isIncluded(id) {
			return id === this.hoveredId
		},
		goToMemberManagement() {
			this.$router.push({
				name: PageName.Institution.MemberManagement,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_organization.scss';
.AddAuthorityMemberPopup {
	.horizational-list {
		height: 500px;
		width: 100%;
		padding-top: 0;
		margin-top: 20px;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		overflow-y: auto;
		box-sizing: border-box;
		li {
			padding: 12px;
			border-bottom: 0;
			transition: 0.3s all linear;
			&:hover {
				background-color: $GREY_1;
			}
			> img {
				object-fit: contain !important;
				margin-top: 3px;
			}
			button {
				img {
					width: 20px;
				}
			}
			> div {
				h6 {
					> img {
						width: 12px;
						margin-left: 6px;
					}
				}
			}
		}
	}
}
</style>
