<template>
	<div class="Orgainzation__access" v-if="groupInfo && groupInfo.type != 'INSTITUTE'">
		<div class="AppContainer__subtitle">
			<h6>{{ tt('접근 권한') }}</h6>
			<button
				class="ml-auto btn Dropdown__btn"
				:class="[{ point: (isStart && order == 3) || isHint }, { pointTop: (isStart && order == 3) || isHint }]"
				@click.stop="handleDropdownOn"
				v-if="userPermission.institute.organization.update || isStart"
			>
				<img src="@/assets/svg/Authority.svg" alt="icon" />
				{{ tt('접근권한 관리') }}
				<div class="Dropdown__menu" v-if="isDropdownOn">
					<button @click.stop="openModalAddGroupAuth">
						{{ tt('그룹') }}
					</button>
					<button @click.stop="openModalAddMemberAuth">
						{{ tt('멤버') }}
					</button>
				</div>
				<div class="order-balloon" v-if="(isStart && order == 3) || isHint">
					<div class="order-top">
						<div class="order">
							<span></span>
							<span></span>
							<span class="active"></span>
						</div>
						<button @click="closeOrder" v-if="!isHint">
							<img src="@/assets/svg/popup-close.svg" alt="close" />
						</button>
					</div>
					<h6>{{ tt('3. 접근 가능한 그룹 또는 멤버 추가하기') }}</h6>
					<p>{{ tt('그룹에 소속된 멤버를 추가해보세요.') }}</p>
				</div>
			</button>
		</div>
		<div class="Organization__access-list">
			<ul class="horizational-list">
				<li class="Organization__access-item no-cursor" v-for="(group, idx) in access.groups" :key="`group-${idx}`">
					<div class="box">
						<h3>{{ getGroupInitialCharacter(group.name) }}</h3>
					</div>
					<div class="Organization__access-item-info">
						<h6>{{ group.name }}</h6>
						<p>{{ group.memberCount }}{{ tt('명') }}</p>
					</div>
					<button class="btn Btn__select Dropdown__btn" v-if="userPermission.institute.organization.update">
						{{ tt(showAccessPermission(group.accessPermission)) }}
						<div class="Dropdown__menu">
							<button @click="updatePermission('read', group)">
								<h6>{{ tt('조회 허용') }}</h6>
								<p>
									{{ tt('연구실의 정보, 물품 등을 조회할 수 있습니다.') }}
								</p>
							</button>
							<button @click="updatePermission('update', group)">
								<h6>{{ tt('편집 허용') }}</h6>
								<p>
									{{ tt('연구실의 정보, 물품 등을 조회하고 편집할 수 있습니다.') }}
								</p>
							</button>
							<button @click="removePermission(group)">
								<h6 class="red-color">
									{{ tt('접근 권한 없애기') }}
								</h6>
								<p>
									{{ tt('접근 권한을 없앱니다.') }}
								</p>
							</button>
						</div>
					</button>
				</li>
				<li class="Organization__access-item no-cursor" v-for="(user, idx) in access.users" :key="`user-${idx}`">
					<img :src="user.image ? user.image : require('@/assets/svg/member-photo-default.svg')" />
					<div class="Organization__access-item-info">
						<h6>{{ user.name }}</h6>
						<p>{{ user.email }}</p>
					</div>
					<button class="btn Btn__select Dropdown__btn" v-if="userPermission.institute.organization.update">
						{{ tt(showAccessPermission(user.accessPermission)) }}
						<div class="Dropdown__menu">
							<button @click="updatePermission('read', user)">
								<h6>{{ tt('조회 허용') }}</h6>
								<p>
									{{ tt('연구실의 정보, 물품 등을 조회할 수 있습니다.') }}
								</p>
							</button>
							<button @click="updatePermission('update', user)">
								<h6>{{ tt('편집 허용') }}</h6>
								<p>
									{{ tt('연구실의 정보, 물품 등을 조회하고 편집할 수 있습니다.') }}
								</p>
							</button>
							<button @click="removePermission(user)">
								<h6 class="red-color">
									{{ tt('접근 권한 없애기') }}
								</h6>
								<p>{{ tt('접근 권한을 없앱니다.') }}</p>
							</button>
						</div>
					</button>
				</li>
			</ul>
			<div class="no-data-box" v-if="noAccessData">
				<img src="@/assets/svg/no-data_setting.svg" alt="no-image" />
				<h6>{{ tt('접근 권한을 가진 그룹 또는 멤버가 없습니다') }}</h6>
				<p>
					<b>[{{ tt('접근권한 관리') }}]</b>
					{{ tt('버튼을 클릭하여 추가해보세요') }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import instituteGroupAPI from '@/services/api/institute/group'
import { mapGetters } from 'vuex'
export default {
	name: 'OrganizationAccessList',
	props: {
		groupInfo: {
			type: Object,
		},
		isStart: {
			type: Boolean,
			default: false,
		},
		order: {
			type: Number,
			default: 0,
		},
		isHint: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		groupInfo: {
			deep: true,
			handler(newGroupInfo) {
				this.access = newGroupInfo.access
			},
		},
	},
	data() {
		return {
			access: {
				groups: [],
				users: [],
			},
			addAuthority: false,
		}
	},
	computed: {
		...mapGetters('organization', ['selectedGroupInfo']),
		isDropdownOn() {
			return this.$root.orgTreeDropdownOn
		},
		noAccessData() {
			return this.access.groups.length === 0 && this.access.users.length === 0
		},
	},
	methods: {
		showAccessPermission(permission) {
			const { update } = permission
			return update ? this.tt('편집 허용') : this.tt('조회 허용')
		},
		updatePermission(type, access) {
			const groupId = this.selectedGroupInfo.id
			const { accessId } = access
			const requestData = {
				id: accessId,
				permission: {
					read: false,
					update: false,
				},
			}

			if (type === 'read') {
				requestData.permission.read = true
			} else if (type === 'update') {
				requestData.permission.read = true
				requestData.permission.update = true
			}
			instituteGroupAPI.editAccessAuthority(groupId, requestData).then(response => {
				this.showToastEditAccess()
				this.$emit('refresh')
			})
		},
		removePermission(access) {
			const groupId = this.selectedGroupInfo.id
			const { accessId } = access
			instituteGroupAPI.deleteAccessAuthority(groupId, accessId).then(response => {
				this.showToastDeleteAccess()
				this.$emit('refresh')
			})
		},
		handleDropdownOn() {
			this.$root.orgTreeDropdownOn = true
		},
		handleDropdownOff() {
			this.$root.orgTreeDropdownOn = false
		},
		getGroupInitialCharacter(groupName) {
			return groupName ? groupName.substr(0, 1) : ''
		},
		openModalAddMemberAuth() {
			this.$emit('openMemberModal', 'ADD_MEMBER_ACCESS')
			this.handleDropdownOff()
		},
		openModalAddGroupAuth() {
			this.$emit(
				'openGroupModal',
				this.groupInfo.access.groups.map(group => group.id)
			)
		},
		showToastEditAccess() {
			const title = '접근 권한 편집 완료'
			const content = '접근 권한이 편집되었습니다.'
			const type = 'success'
			this.$root.toast(title, content, type)
		},
		showToastDeleteAccess() {
			const title = '접근 권한 삭제'
			const content = '접근 권한이 삭제되었습니다.'
			const type = 'success'
			this.$root.toast(title, content, type)
		},
		closeOrder() {
			this.$emit('closeOrder')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_organization.scss';

.Organization__access-item {
	&.no-cursor {
		cursor: default !important;
	}
	&:hover {
		// .Btn__select {
		//     border:1px solid #DADBDC !important;
		//     background-color: #DADBDC !important;
		// }
		.Btn__select:focus {
			border: 1px solid #dadbdc !important;
			background-color: #dadbdc !important;
		}
		.Btn__select:hover {
			border: 1px solid #dadbdc !important;
			background-color: #dadbdc !important;
		}
	}
}
</style>
