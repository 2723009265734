import { mapActions, mapGetters, mapMutations } from 'vuex'

import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
import FormNumberUnit from '@/views/common/components/form/FormNumberWithUnit.vue'
import FormStorage from '@/views/common/components/form/FormStorage.vue'
import FormSelect from '@/views/common/components/form/FormSelect.vue'
import FormSelectMember from '@/views/common/components/form/FormSelectMember.vue'
import FormDatetime from '@/views/common/components/form/FormDatetime.vue'
import FormMultipleLine from '@/views/common/components/form/FormMultipleLine.vue'
import FormTagList from '@/views/common/components/form/FormTagList.vue'
import FormImage from '@/views/common/components/form/FormImage.vue'
import FormQr from '@/views/common/components/form/FormQr.vue'
import FormMember from '@/views/common/components/form/FormMember.vue'

export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		isValid: {
			type: Boolean,
			default: false,
		},
		categoryInfo: {
			type: [Object, null],
			default: null,
		},
		memberList: {
			type: Array,
			default: () => [],
		},
		productInfo: {
			type: [Object, null],
			default: null,
		},
		isManagementValid: {
			type: Boolean,
			default: false,
		},
		isUnitValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('form/image', ['imageList', 'removeImageLink']),
		managementSequenceIdx() {
			return key =>
				[
					'storage',
					'assignee',
					'threshold',
					'expireDatetime',
					'lotno',
					'incompanyMngcode',
					'openDatetime',
					'buyer',
					'buyDatetime',
					'price',
					'qrSticker',
					'memo',
					'tag',
					'image',
					'category',
					'docsList',
					'failMemos',
					'location',
				].indexOf(key)
		},
		managementFields() {
			let returnFields = []
			let fields = this.categoryInfo?.fields?.managementFields.filter(f => f.isActive && !f.isReadOnly)
			if (fields) {
				for (let i = 0; i < fields.length; i++) {
					let idx = this.managementSequenceIdx(fields[i].key)
					returnFields[idx] = fields[i]
				}
				return returnFields.filter(f => f)
			} else {
				return []
			}
		},
		formInput() {
			return field => {
				const { varType, key } = field
				switch (key) {
					case 'storage':
						return FormStorage
					case 'qrSticker':
						return FormQr
					case 'expireDatetime':
						return FormDatetime
					case 'tag':
						return FormTagList
					case 'image':
						return FormImage
					default:
						return {
							text_single_line: FormTextSingleLine,
							number_with_unit: FormNumberUnit,
							select_option: FormSelect,
							select_member: FormMember,
							select_datetime: FormDatetime,
							text_multiple_line: FormMultipleLine,
							null: '',
						}[varType]
				}
			}
		},
		formValue() {
			return fieldKey => {
				if (fieldKey === 'image') {
					return this.imageList ? [...this.imageList] : []
				} else if (fieldKey === 'tag') {
				}
				return this.productInfo?.[fieldKey]
			}
		},
		formUnit() {
			return fieldKey => this.productInfo?.[`${fieldKey}Unit`]
		},
		formMessage() {
			return name => {
				switch (name) {
					case '구매자':
					case '담당자':
						return this.tt(`${name}를 선택해주세요`)
					case '보관함':
						return this.tt(`${name}을 선택해주세요`)
					case '사내 관리번호':
					case '코멘트':
						return this.tt(`${name}를 입력해주세요`)
					case 'Lot No.':
						return this.tt(`LOT No를 입력해주세요`)
					case '안전재고량':
					case '구매가격':
						return this.tt(`${name}을 입력해주세요`)
					case '유효기간(일)':
					case '개봉일':
					case '구매일':
						return 'YYYY - MM - DD'
					default:
						return ''
				}
			}
		},
		formOptionList() {
			return field => {
				switch (field.key) {
					case 'assignee':
					case 'buyer':
						return this.memberList.map(m => {
							return {
								id: m.id,
								name: m.name,
								image: m.image,
							}
						})
					case 'threshold':
					case 'price':
						return field.unit
					default:
						return []
				}
			}
		},
	},
	created() {
		this.getTagList()
	},
	mounted() {
		this.setImageList(this.productInfo?.['image']?.length > 0 ? this.productInfo?.['image'] : [])
	},
	methods: {
		...mapMutations('form/image', ['setImageList', 'setRemoveImageLink']),
		...mapActions('tag', ['getTagList']),
		inputFormValue(value, fieldKey) {
			this.$emit('change', value, fieldKey)
			if (value === 'image') {
				this.setImageList(value)
			}
		},
		showRequire() {
			this.$refs['form'].forEach(ref => {
				if (ref.showRequire) {
					ref.showRequire()
				}
			})
		},
		removeImage(removeImageLink) {
			removeImageLink.forEach(link => {
				if (!this.removeImageLink?.includes(link)) {
					this.setRemoveImageLink([...(this.removeImageLink ?? []), link])
				}
			})
			this.$emit('removeImg', this.removeImageLink)
		},
	},
}
