<template>
	<div class="common-detail-wrapper">
		<div>
			<h6>{{ tt('편성 정보') }}</h6>
			<input-select
				ref="year"
				title="연도"
				:inValidText="'연도를 선택해주세요'"
				placeholder="연도를 선택해주세요"
				isRequired
				:isValid="!inValid || !!$refs['year'].value"
				:inputWidthSize="400"
				:list="yearList"
			>
				<div class="subtext">{{ tt('내후년까지 예산 편성이 가능합니다.') }}</div></input-select
			>
		</div>
		<div>
			<h6>{{ tt('예산 정보') }}</h6>
			<input-text
				ref="name"
				title="예산 이름"
				placeholder="예산 이름을 입력해주세요"
				isRequired
				:inputWidthSize="400"
				:inputHeightSize="36"
				:inValidText="'예산 이름을 입력해주세요'"
				:isValid="!inValid || !!$refs['name'].value"
			></input-text>
			<input-text ref="code" title="예산 번호" placeholder="예산 번호를 입력해주세요" :inputWidthSize="400" :inputHeightSize="36"></input-text>
		</div>
		<div>
			<h6>{{ tt('예산') }}</h6>
			<input-radio
				:isValid="!inValid || !!$refs['budgetType'].value"
				ref="budgetType"
				title="예산 구분"
				:list="typeList"
				:required="true"
			></input-radio>
		</div>
		<div>
			<h6>{{ tt('참여부서') }}</h6>
			<select-group
				ref="groups"
				isRequired
				:defaultValue="registrationInfo.groups"
				:inValidText="'참여 부서를 선택해주세요'"
				:isValid="!inValid || $refs['groups'].value.length > 0"
			></select-group>
		</div>
	</div>
</template>
<script>
import InputText from '@/views/common/components/items/InputText.vue'
import InputSelect from '@/views/common/components/items/InputSelect.vue'
import InputRadio from '@/views/common/components/items/InputRadio.vue'

import SelectGroup from '../components/create/SelectGroup.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
	name: 'BudgetInfo',
	components: {
		InputText,
		InputSelect,
		InputRadio,

		SelectGroup,
	},
	computed: {
		...mapState('budget/item', ['registrationInfo']),
		...mapState('institute/group', ['groups']),
		currentYear() {
			return this.$DateTime.now().year
		},
		yearList() {
			return [
				{ value: this.currentYear, name: this.currentYear },
				{ value: this.currentYear + 1, name: this.currentYear + 1 },
				{ value: this.currentYear + 2, name: this.currentYear + 2 },
			]
		},
		typeList() {
			return [
				{
					value: 'YEAR',
					name: this.tt('연 예산'),
				},
				{
					value: 'MONTH',
					name: this.tt('월별 예산'),
				},
			]
		},
	},
	data() {
		return {
			inValid: false,
		}
	},
	async mounted() {
		this.setData()
	},
	methods: {
		...mapMutations('budget/item', ['setRegistrationInfo']),
		isValid() {
			try {
				this.inValid = false
				let form = {}
				Object.keys(this.$refs).forEach(key => {
					if (!this.inValid && this.$refs[key].isRequired && (!this.$refs[key].value || this.$refs[key].value.length == 0)) {
						this.inValid = true
						throw 'invalid'
					}
					form[key] = this.$refs[key].value
				})
				this.setRegistrationInfo(form)
				return true
			} catch (error) {
				this.$root.toast('필수값 미입력', '필수값을 입력해주세요.', 'error')
				return false
			}
		},
		async setData() {
			await this.$nextTick()
			this.$refs['year'].value = this.yearList[0].value
			if (this.registrationInfo != null) {
				Object.entries(this.registrationInfo).forEach(([key, value]) => {
					if (value != null && this.$refs[key]) this.$refs[key].setValue(value)
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.subtext {
	margin-top: 10px;
	font-size: 14px;
	color: $SECONDARY_2;
}
.common-detail-wrapper {
	min-height: calc(100% - 130px);
}
</style>
