<template>
	<b-modal ref="modal" centered scrollable no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('기관 정보 편집') }}</div>
		</template>
		<template>
			<div class="information-wrapper">
				<img class="logo" @click="$refs['imageUpload'].click()" :src="logoURL" alt="icon" />
				<input ref="imageUpload" type="file" accept="image/*" hidden @change="uploadFile" />
				<div class="input-wrapper" :class="{ error: !isValidName }">
					<p>
						{{ tt('기관명') }}
						<span class="required">*</span>
					</p>
					<input type="text" :placeholder="tt('기관명을 입력해주세요')" v-model="form.name" />
					<span>{{ tt('필수값을 입력해주세요') }}</span>
				</div>
				<!-- 사업자등록번호 -->
				<div class="input-wrapper" :class="{ error: !isValidTrader }">
					<p>
						{{ tt('사업자등록번호') }}
					</p>
					<input
						type="text"
						:placeholder="tt('사업자등록번호를 입력해주세요')"
						:value="form.traderRegistrationNumber"
						@input="autoTraderNumber"
						maxlength="12"
					/>
					<span>{{ tt('사업자등록번호를 형식에 맞게 입력해주세요') }}</span>
				</div>
				<!-- 연락처 -->
				<div class="input-wrapper" :class="{ error: !isValidPhone }">
					<p>
						{{ tt('전화번호') }}
					</p>
					<input type="text" :placeholder="tt('전화번호를 입력해주세요')" :value="form.phone" @input="autoHypenTel" maxlength="13" />
					<span>{{ tt('연락처를 형식에 맞게 입력해주세요') }}</span>
				</div>
				<!-- 주소 -->
				<div class="input-wrapper">
					<p>{{ tt('주소') }}</p>
					<div class="flex">
						<input type="text" :placeholder="tt('주소를 검색해 주세요')" :value="form.address" />
						<button class="button-white" @click="searchAddress">
							{{ tt('주소 검색') }}
						</button>
					</div>
					<input type="text" :placeholder="tt('상세 주소를 입력해주세요')" v-model="form.addressDetail" />
				</div>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="complete">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import Validation from '@/utils/Validation'
import { addressFinderOpen } from '@/services/api/addressFinder'
import { mapActions, mapState } from 'vuex'
import form from '@/store/modules/form'
export default {
	computed: {
		...mapState('institutionInformation', ['instInfo']),
		logoURL() {
			if (typeof this.form.logo === 'object' && this.form.logo) {
				return URL.createObjectURL(this.form.logo)
			} else {
				return this.form.logo ?? require('@/assets/svg/institution-building.svg')
			}
		},
	},
	data() {
		return {
			form: {
				logo: null,
				name: null,
				phone: null,
				address: null,
				addressDetail: null,
			},
			isValidName: true,
			isValidTrader: true,
			isValidPhone: true,
		}
	},
	methods: {
		...mapActions('institutionInformation', ['editInstituteInformation']),
		show() {
			this.form = this.$_.cloneDeep(this.instInfo.info)
			this.$refs.modal.show()
		},
		hide() {
			this.$refs.modal.hide()
		},
		async complete() {
			const formData = new FormData()
			await this.validPhone()
			await this.validTrader()
			if (this.form.name == null || this.form.name.length == 0) {
				this.isValidName = false
			}

			if (!this.isValidName || !this.isValidTrader || !this.isValidPhone) {
				return false
			}
			formData.append('name', this.form.name)
			formData.append('phone', this.form.phone ?? '')
			formData.append('address', this.form.address ?? '')
			formData.append('addressDetail', this.form.addressDetail ?? '')
			formData.append('traderRegistrationNumber', this.form.traderRegistrationNumber ?? '')
			if (this.form.logo instanceof File) {
				formData.append('logo', this.form.logo)
			}
			try {
				await this.editInstituteInformation(formData)
				this.$root.toast(this.tt('기관 정보 편집 완료'), this.tt('기관 정보가 편집되었습니다'), 'success')
				this.hide()
			} catch (e) {
				this.$root.toast(this.tt('기관 정보 편집 실패'), e.response?.data?.msg, 'error')
			}
		},
		uploadFile() {
			const files = this.$refs['imageUpload'].files
			if (files?.length > 0) {
				this.form.logo = files[0]
			}
			this.$refs['imageUpload'].value = null
		},
		validPhone() {
			this.isValidPhone = true
			if (this.form.phone && this.form.phone != null) {
				if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.form.phone)) {
					this.isValidPhone = false
				}
			}
		},
		validTrader() {
			const value = this.form.traderRegistrationNumber
			this.isValidTrader = true
			if (value) {
				let valueMap = value
					.replace(/-/gi, '')
					.split('')
					.map(function (item) {
						return parseInt(item, 10)
					})

				if (valueMap.length === 10 || !value) {
					let multiply = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5)
					let checkSum = 0

					for (let i = 0; i < multiply.length; ++i) {
						checkSum += multiply[i] * valueMap[i]
					}

					checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10)
					return Math.floor(valueMap[9]) === 10 - (checkSum % 10)
				}
				this.isValidTrader = false
				this.$root.toast(this.tt('사업자등록증 형식 확인'), this.tt('사업자등록번호를 형식에 맞게 입력해주세요'), 'error')
			}
		},
		async searchAddress() {
			const locale = this.$root.user.language
			const isKorean = locale === 'ko'
			const localeMode = isKorean ? 'K' : 'E'
			const result = await addressFinderOpen(window, localeMode)
			const { address, addressEnglish, bname, bname2English } = result
			this.form.address = isKorean ? `${address}(${bname})` : `${addressEnglish}(${bname2English})`
		},
		changeName(e) {
			const name = e.target.value
			this.isValidName = true
			this.setName(name)
		},
		autoHypenTel(e) {
			const phoneNumber = Validation.autoHypenTel(e.target.value)
			this.isValidPhone = true
			this.form.phone = phoneNumber
		},
		autoTraderNumber(e) {
			const traderNumber = Validation.autoTraderNumber(e.target.value)
			this.form.traderRegistrationNumber = traderNumber
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
.information-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	.logo {
		width: 80px;
		height: 80px;
		object-fit: cover;
		border-radius: 16px;
		border: 1px solid #e4e5ea;
		cursor: pointer;
		padding: 6px;
	}
	.input-wrapper {
		margin-top: 20px;
		width: 100%;
		p {
			font-size: 12px;
			font-weight: 400;
			margin-bottom: 8px !important;
		}
		.flex {
			display: flex;
			gap: 10px;
			margin-bottom: 10px;
		}
		input {
			width: 100%;
			border-radius: 5px;
			font-size: 14px;
			font-weight: 400;
			padding-left: 10px;
			height: 30px;
			border: 1px solid $COLOR_CCC;
		}
		&.error {
			input {
				border-color: $RED;
			}
			> span {
				display: block;
			}
		}
		> span {
			font-size: 0.75rem;
			color: $RED;
			font-weight: 400;
			position: relative;
			height: 17px;
			display: none;
			padding-left: 16px;
			padding-top: 2px;
			&:before {
				position: absolute;
				width: 12px;
				height: 12px;
				content: url(~@/assets/svg/noti-error.svg);
				top: 4px;
				left: 0;
			}
		}
		.button-white {
			background: $LAB_WHITE;
			border: 1px solid $DIVIDER;
			color: #111 !important;
			padding: 5px 10px;
			font-size: 14px;
			border-radius: 5px;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
					linear-gradient(0deg, #fff, #fff);
			}
		}
	}
}
</style>
