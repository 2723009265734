var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal-form-approval-line-selected",
    attrs: {
      "size": "1000",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('결재선 지정')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.complete
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "content"
  }, [_c('approval-line-treeview'), _c('approval-line-form', {
    attrs: {
      "isValid": _vm.isValid
    }
  })], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }