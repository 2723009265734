<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddAuthorityMemberPopup Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt(title) }}
					</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<search-box :placeholder="searchPlaceholder" @inputValue="getInputGroupName" @search="searchTree"></search-box>
					<div class="Organization__member-list">
						<ul class="horizational-list">
							<TreeView
								v-if="treeData"
								ref="modal-tree-view"
								key="modal-tree-view"
								:treeData="treeData"
								textField="name"
								modal="group"
								subTextField="membersCount"
								:subTextEndWord="tt('명')"
								:draggable="false"
								:mode="'selectable'"
								:selectWithChild="false"
								:keyword="keyword"
								@changeActive="getActiveId"
								@load="loadedTree"
							></TreeView>
						</ul>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="complete">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import SearchBox from '@/views/common/components/SearchBox.vue'
import TreeView from '@/views/common/components/tree/TreeView.vue'

export default {
	name: 'ModalSelectGroupV2',
	components: { SearchBox, TreeView },
	mixins: [MixinModal],
	props: {
		treeData: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
		},
		searchPlaceholder: {
			type: String,
		},
		// 기존에 선택된 데이터 Array
		selectedList: {
			type: Array,
		},
		selectMode: {
			type: String,
		},
	},
	data() {
		return {
			memberList: [],
			originGroupList: [],
			selectedGroupList: [],
			selectedGroupNameList: [],
			searchBoxPlaceholder: '그룹 이름으로 검색해주세요',
			organizationData: null,
			inputGroupName: '',
			selectedIndex: 0,
			mode: 'selectable',
			groupList: [],
		}
	},
	methods: {
		show() {
			this.isShow = true
			this.mode = this.selectMode
		},
		complete() {
			console.warn(this.selectedList)
			this.$emit('completeFn', this.getChangedList())
			this.hide()
		},
		loadedTree() {
			this.$refs['modal-tree-view'].setActiveIdList(this.selectedList)
		},
		searchTree() {
			this.keyword = this.inputGroupName
			this.$refs['modal-tree-view'].setActiveIdList([...this.selectedList])
		},
		getInputGroupName(groupName) {
			this.inputGroupName = groupName
		},
		getActiveId(idList, dataList) {
			this.selectedGroupList = idList
		},
		getActiveName(nameList) {
			this.selectedGroupNameList = nameList
		},
		hide() {
			this.isShow = false
		},
		getChangedList() {
			return this.$refs['modal-tree-view'].getChangedList()
		},
	},
}
</script>

<style lang="scss" scoped>

@import '@/styles/scss/institution/_organization.scss';

.Organization__member-list {
	height: 500px;
}
.AddAuthorityMemberPopup {
	.horizational-list {
		height: 500px;
		width: 100%;
		padding-top: 0;
		margin-top: 20px;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		overflow-y: auto;
		li {
			padding: 12px;
			border-bottom: 0;
			transition: 0.3s all linear;
			&:hover {
				background-color: $GREY_1;
			}
			button {
				img {
					width: 20px;
					background-image: '@/assets/svg/Select-Circle.svg';
				}
			}
			> div {
				h6 {
					> img {
						width: 12px;
						margin-left: 6px;
					}
				}
			}
		}
	}
}
</style>
