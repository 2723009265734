import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	lookupWaitingList: (instId, offset, length, status, categoriesType, registersId, startDatetime, endDatetime) => {
		let parameter = `/institute/${instId}/inspect/list?offset=${offset}`
		if (length) parameter += `&length=${length}`
		if (status) parameter += `&status=${status}`
		if (categoriesType && categoriesType.length != 0) parameter += `&${ParamMaker.setArrayString('categoriesType', categoriesType)}`
		if (registersId && registersId.length != 0) parameter += `&${ParamMaker.setArrayString('registersId', registersId)}`
		if ((startDatetime != undefined) & (endDatetime != undefined)) parameter += `&startDatetime=${startDatetime}&endDatetime=${endDatetime}`
		return parameter
	},
	lookupInventoryList: instId => {
		return `/institute/${instId}/inventory/list`
	},
	lookupBundleList: (instId, categoryId, groupby, classification, keyword) => {
		let parameter = `/institute/${instId}/inventory/bundle/list?groupby=${groupby}&categoryClassification=${classification}`
		if (categoryId != undefined) parameter += `&categoryId=${categoryId}`
		if (keyword != undefined) parameter += `&keyword=${keyword}`
		return parameter
	},
	lookupInventory: (instId, id) => `/institute/${instId}/inventory?id=${id}`,
	lookupAssignees: instId => `/institute/${instId}/inventory/assignees`, //물품 담당자들 조회
	lookupRegistrants: instId => `/institute/${instId}/inventory/registrants`, //물품 등록자들 조회
	lookupDumpers: instId => `/institute/${instId}/inventory/dumpers`, //삭제/폐기자들 조회
	lookupLogMembers: instId => `/institute/${instId}/inventory/log/filter`, //기록 멤버 조회
	createInventory: instId => `/institute/${instId}/inventory`, //물품 생성
	updateInventory: instId => `/institute/${instId}/inventory`, //물품 편집
	subdivideInventory: instId => `/institute/${instId}/inventory/subdivide`, //물품 소분
	deleteInventory: instId => `/institute/${instId}/inventory`, //물품들 삭제
	completeInventory: instId => `/institute/${instId}/inventory/complete`,
	updateStorageInventory: instId => `/institute/${instId}/inventory/storage`, //물품들의 보관함 변경
	disposeInventory: instId => `/institute/${instId}/inventory/dispose`, //물품들 폐기
	restoreInventory: instId => `/institute/${instId}/inventory/restore`, //폐기/삭제 물품들 복구
	holdInventory: instId => `/institute/${instId}/inventory/hold`, //사용하기
	returnInventory: instId => `/institute/${instId}/inventory/return`, //사용 완료하기
	consumeInventory: instId => `/institute/${instId}/inventory/consume`, //사용량 입력하기
	lookupLogList: (instId, itemId, offset, length, keyword, startDatetime, endDatetime, order, orderDirection, categoriesId, membersId, types) => {
		let parameter = `/institute/${instId}/inventory/log?offset=${offset}&length=${length}`
		if (itemId != undefined) parameter += `&itemId=${itemId}`
		if (keyword != undefined) parameter += `&keyword=${keyword}`
		if ((startDatetime != undefined) & (endDatetime != undefined)) parameter += `&startDatetime=${startDatetime}&endDatetime=${endDatetime}`
		if (order.length != 0) parameter += `&${ParamMaker.setArrayString('order', order)}`
		if (orderDirection != 0) parameter += `&${ParamMaker.setArrayString('orderDirection', orderDirection)}`
		if (categoriesId && categoriesId.length != 0) parameter += `&${ParamMaker.setArrayString('categoriesId', categoriesId)}`
		if (membersId && membersId.length != 0) parameter += `&${ParamMaker.setArrayString('membersId', membersId)}`
		if (types && types.length != 0) parameter += `&${ParamMaker.setArrayString('types', types)}`
		return parameter
	},
	deleteLogInventory: (instId, ids) => `/institute/${instId}/inventory/log?${ParamMaker.setArrayString('ids', ids)}`, //물품 사용기록 삭제
	lookupDumpList: (
		instId,
		offset,
		length,
		keyword,
		startDatetime,
		endDatetime,
		order,
		orderDirection,
		status,
		categoriesId,
		assigneesId,
		dumpersId
	) => {
		let parameter = `/institute/${instId}/inventory/dump/list?offset=${offset}&length=${length}`
		if (keyword != undefined) parameter += `&keyword=${keyword}`
		if (order.length != 0) parameter += `&${ParamMaker.setArrayString('order', order)}`
		if (orderDirection != 0) parameter += `&${ParamMaker.setArrayString('orderDirection', orderDirection)}`
		if ((startDatetime != undefined) & (endDatetime != undefined)) parameter += `&startDatetime=${startDatetime}&endDatetime=${endDatetime}`
		if (status.length != 0) parameter += `&${ParamMaker.setArrayString('status', status)}`
		if (categoriesId.length != 0) parameter += `&${ParamMaker.setArrayString('categoriesId', categoriesId)}`
		if (assigneesId.length != 0) parameter += `&${ParamMaker.setArrayString('assigneesId', assigneesId)}`
		if (dumpersId.length != 0) parameter += `&${ParamMaker.setArrayString('dumpersId', dumpersId)}`
		return parameter
	},
	searchProduct: (instId, paramsObject) => `/institute/${instId}/inventory/product/search`,
	// searchIngredient: (instId, keyword, offset, length) => `/institute/${instId}/inventory/ingredient/search?keyword=${keyword}&offset=${offset}&length=${length}`,
	searchIngredient: (instId, paramsObject) =>
		`/institute/${instId}/inventory/ingredient/search?
			keyword=${paramsObject.keyword}&
			offset=${paramsObject.offset}&
			length=${paramsObject.length}`,
	searchBrand: (instId, keyword, isUse) => {
		let parameter = `/institute/${instId}/inventory/brand/search?isUse=${isUse}`
		if (keyword != '') parameter += `&keyword=${keyword}`
		return parameter
	},
	lookupTagList: instId => `/institute/${instId}/tag/all`,
	lookupSearchTagList: (instId, keyword, offset, length, categoriesId) => {
		let parameter = `/institute/${instId}/tag/list?offset=${offset}&length=${length}`
		if (keyword != '') parameter += `&keyword=${keyword}`
		if (categoriesId.length != 0) parameter += `&${ParamMaker.setArrayString('categoriesId', categoriesId)}`
		return parameter
	},
	createTag: instId => `/institute/${instId}/tag`,
	deleteTag: (instId, id) => `/institute/${instId}/tag`,
	downloadInventoryList: (instId, paramsObject) =>
		`/institute/${instId}/inventory/list/export/excel?${ParamMaker.setParamUsingObject(paramsObject)}`,
	downloadInventory: instId => `/institute/${instId}/inventory/list/export/excel`,
	downloadHistory: instId => `/institute/${instId}/inventory/log/export/excel`,
	lookupUnit: () => `/unit`,
	lookupTypeList: (instId, keyword, categoryType) => {
		let parameter = `/institute/${instId}/inventory/kind/search?categoryType=${categoryType}`
		if (keyword != '') parameter += `&keyword=${keyword}`
		return parameter
	},
	searchCode: (value, classification) => `/product/search/code?value=${value}&classification=${classification}`,
	validateQRSticker: instId => `/institute/${instId}/inventory/qrSticker`,
	getBrandList: instId => `/institute/${instId}/inventory/brand/search`,
}

const inventoryAPI = {
	lookupWaitingList: (instId, offset, length, status, categoriesType, registersId, startDatetime, endDatetime) =>
		axiosInstance.get(endPoint.lookupWaitingList(instId, offset, length, status, categoriesType, registersId, startDatetime, endDatetime)),
	lookupInventoryList: (instId, paramsObject) =>
		axiosInstance.get(endPoint.lookupInventoryList(instId), {
			params: ParamMaker.setSearchKeywordEncoding(paramsObject),
		}),
	lookupBundleList: (instId, categoryId, groupby, classification, keyword) =>
		axiosInstance.get(endPoint.lookupBundleList(instId, categoryId, groupby, classification, keyword)),
	lookupInventory: (instId, id) => axiosInstance.get(endPoint.lookupInventory(instId, id)),
	lookupAssignees: instId => axiosInstance.get(endPoint.lookupAssignees(instId)),
	lookupRegistrants: instId => axiosInstance.get(endPoint.lookupRegistrants(instId)),
	lookupDumpers: instId => axiosInstance.get(endPoint.lookupDumpers(instId)),
	lookupLogMembers: instId => axiosInstance.get(endPoint.lookupLogMembers(instId)),
	createInventory: (instId, formData) =>
		axiosInstance.post(endPoint.createInventory(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	updateInventory: (instId, formData) =>
		axiosInstance.put(endPoint.updateInventory(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	subdivideInventory: (instId, body) => axiosInstance.put(endPoint.subdivideInventory(instId), body),
	deleteInventory: (instId, itemsId, memo) =>
		axiosInstance.delete(endPoint.deleteInventory(instId), {
			params: {
				memo: memo,
				itemsId: itemsId,
			},
		}),
	completeInventory: (instId, itemsId) =>
		axiosInstance.delete(endPoint.completeInventory(instId), {
			params: {
				itemsId: itemsId,
			},
		}),
	updateStorageInventory: (instId, body) => axiosInstance.patch(endPoint.updateStorageInventory(instId), body),
	disposeInventory: (instId, body) => axiosInstance.patch(endPoint.disposeInventory(instId), body),
	restoreInventory: (instId, body) => axiosInstance.patch(endPoint.restoreInventory(instId), body),
	holdInventory: (instId, body) => axiosInstance.patch(endPoint.holdInventory(instId), body),
	returnInventory: (instId, body) => axiosInstance.patch(endPoint.returnInventory(instId), body),
	consumeInventory: (instId, body) => axiosInstance.patch(endPoint.consumeInventory(instId), body),
	lookupLogList: (instId, itemId, offset, length, keyword, startDatetime, endDatetime, order, orderDirection, categoriesId, membersId, types) =>
		axiosInstance.get(
			endPoint.lookupLogList(
				instId,
				itemId,
				offset,
				length,
				keyword,
				startDatetime,
				endDatetime,
				order,
				orderDirection,
				categoriesId,
				membersId,
				types
			)
		),
	deleteLogInvengory: (instId, ids) => axiosInstance.delete(endPoint.deleteLogInventory(instId, ids)),
	lookupDumpList: (
		instId,
		offset,
		length,
		keyword,
		startDatetime,
		endDatetime,
		order,
		orderDirection,
		status,
		categoriesId,
		assigneesId,
		dumpersId
	) =>
		axiosInstance.get(
			endPoint.lookupDumpList(
				instId,
				offset,
				length,
				keyword,
				startDatetime,
				endDatetime,
				order,
				orderDirection,
				status,
				categoriesId,
				assigneesId,
				dumpersId
			)
		),
	searchProduct: (instId, paramsObject) => {
		return axiosInstance.get(endPoint.searchProduct(instId, paramsObject), {
			params: paramsObject,
		})
	},
	searchIngredient: (instId, paramsObject) => axiosInstance.get(endPoint.searchIngredient(instId, paramsObject)),
	searchBrand: (instId, keyword, isUse) => axiosInstance.get(endPoint.searchBrand(instId, keyword, isUse)),
	lookupTagList: instId => axiosInstance.get(endPoint.lookupTagList(instId)),
	lookupSearchTagList: (instId, keyword, offset, length, categoriesId) =>
		axiosInstance.get(endPoint.lookupSearchTagList(instId, keyword, offset, length, categoriesId)),
	createTag: (instId, body) => axiosInstance.post(endPoint.createTag(instId), body),
	deleteTag: (instId, params) => axiosInstance.delete(endPoint.deleteTag(instId), { params }),
	downloadInventory: (instId, body) =>
		axiosInstance.post(endPoint.downloadInventory(instId), body, {
			responseType: 'blob',
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	downloadHistory: (instId, body) =>
		axiosInstance.post(endPoint.downloadHistory(instId), body, {
			responseType: 'blob',
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	lookupUnit: value =>
		axiosInstance.get(endPoint.lookupUnit(), {
			params: {
				value: value,
			},
		}),
	lookupTypeList: (instId, keyword, categoryType) => axiosInstance.get(endPoint.lookupTypeList(instId, keyword, categoryType)),
	searchCode: (value, classification) => axiosInstance.get(endPoint.searchCode(value, classification)),
	validateQRSticker: (instId, params) =>
		axiosInstance.get(endPoint.validateQRSticker(instId), {
			params: params,
		}),
	getBrandList: (instId, params) => axiosInstance.get(endPoint.getBrandList(instId), { params: params }),
}

export default inventoryAPI
