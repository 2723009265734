d
<template>
	<div class="Dashboard" :class="[{ setting: setting }, { scroll: dashboardScroll > 200 }]" @scroll="scroll" ref="dashboard">
		<h2 class="AppContainer__title">
			<div class="flex">
				{{ setting ? tt('대시보드 설정') : tt('대시보드') }}
				<div class="ml-auto">
					<button class="btn" @click="setting = true" v-if="setting == false">
						<img src="@/assets/svg/authority-setting.svg" alt="icon" />
						{{ tt('설정') }}
					</button>
					<button class="btn Btn__close" v-if="setting == true" @click="cancelSetting">
						{{ tt('취소') }}
					</button>
					<button class="btn Btn__complete" v-if="setting == true" @click="saveSetting">
						{{ tt('저장') }}
					</button>
				</div>
			</div>
		</h2>
		<div class="AppContainer">
			<div class="Dashboard-setting-noti" v-if="setting == true && noti == true">
				<h5>💡 {{ tt('대시보드 설정하기') }}</h5>
				<ul>
					<li>
						1.
						<img :src="require(`@/assets/images/dashboard-switch${$root.user.language == 'en' ? '_en' : ''}.png`)" />
						{{ tt('스위치를 On/Off 하여 대시보드에서 보여줄 항목을 선택해주세요.') }}
					</li>
					<li>
						{{ tt('2. 항목을 드래그하여 위치를 변경할 수 있습니다.') }}
					</li>
					<li>{{ tt('3. 대시보드 설정은 계정별로 저장됩니다.') }}</li>
				</ul>
				<button class="close" @click="noti = false">
					<img src="@/assets/svg/member-invite-authority-x.svg" alt="icon" />
				</button>
			</div>
			<div class="Dashboard-init-noti" v-if="setting == true && noti == true">
				<h5>↩️&nbsp;{{ tt('대시보드 초기화') }}</h5>
				<p>{{ tt('대시보드 기본 설정으로 초기화됩니다.') }}</p>
				<button class="Btn__black" @click="showInit">
					{{ tt('초기화') }}
				</button>
			</div>
			<draggable
				class="card-wrap"
				v-if="copyDashboardList"
				:list="copyDashboardList.left"
				group="people"
				v-bind="dragOptions"
				@start="drag = true"
				@end="drag = false"
			>
				<transition-group type="transition" :name="!drag ? 'flip-list' : null">
					<div class="card-box" v-for="item in copyDashboardList.left" :key="item.id">
						<dashboard-cards
							:id="item.id"
							:visible="item.visible"
							:setting="setting"
							:instId="$route.params.instituteId"
							:createTrend="createTrend"
							:createSummary="createSummary"
							:disposalTrend="disposalTrend"
							:disposalSummary="disposalSummary"
							:useTrend="useTrend"
							:useSummary="useSummary"
							:possessionTrend="possessionTrend"
							:disposalItem="disposalItem"
							:msdsUpload="msdsUpload"
							:diagnosisList="diagnosisList"
							:dangerStorage="dangerStorage"
							:legalList="legalList"
							@visibleCard="visibleCard"
							:license="license"
							:userPermission="userPermission"
						></dashboard-cards>
					</div>
				</transition-group>
			</draggable>
			<draggable
				class="card-wrap"
				v-if="copyDashboardList"
				:list="copyDashboardList.right"
				group="people"
				v-bind="dragOptions"
				ghost-class="ghost2"
				@start="drag = true"
				@end="drag = false"
			>
				<transition-group type="transition" :name="!drag ? 'flip-list' : null">
					<div class="card-box" v-for="item in copyDashboardList.right" :key="item.id">
						<dashboard-cards
							:id="item.id"
							:visible="item.visible"
							:setting="setting"
							:instId="$route.params.instituteId"
							:createTrend="createTrend"
							:createSummary="createSummary"
							:disposalTrend="disposalTrend"
							:disposalSummary="disposalSummary"
							:useTrend="useTrend"
							:useSummary="useSummary"
							:possessionTrend="possessionTrend"
							:disposalItem="disposalItem"
							:msdsUpload="msdsUpload"
							:diagnosisList="diagnosisList"
							:dangerStorage="dangerStorage"
							:legalList="legalList"
							@visibleCard="visibleCard"
							:license="license"
							:userPermission="userPermission"
						></dashboard-cards>
					</div>
				</transition-group>
			</draggable>
		</div>
		<modal-main-notice ref="modal-main-notice" />
		<modal-main-research ref="modal-main-research" />

		<!--modals-->
		<modal-common ref="modal-common" @isHide="initDashboard"></modal-common>
	</div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import draggable from 'vuedraggable'
import DashboardCards from '@/views/dashboard/pages/components/DashboardCards.vue'
import dashboardAPI from '@/services/api/dashboard'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
import ModalMainNotice from '@/views/common/components/modalGen2/ModalMainNotice.vue'
import ModalMainResearch from '@/views/common/components/modalGen2/ModalMainResearch.vue'
export default {
	name: 'Dashboard',
	components: {
		draggable,
		DashboardCards,
		ModalCommon,
		ModalMainNotice,
		ModalMainResearch,
	},
	props: {
		license: {
			type: Object,
			default: () => {},
		},

		isJoin: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('user', ['institute', 'user']),
		...mapState('dashboard', [
			'createTrend',
			'createSummary',
			'disposalTrend',
			'disposalSummary',
			'useTrend',
			'useSummary',
			'possessionTrend',
			'disposalItem',
			'msdsUpload',
			'diagnosisList',
			'dangerStorage',
			'legalList',
		]),
		dragOptions() {
			return {
				animation: 600,
				group: 'description',
				disabled: !this.setting,
				ghostClass: 'ghost',
				dragClass: 'sortable-drag',
				chosenClass: 'sortable-chosen',
				removeCloneOnHide: true,
			}
		},
	},
	created() {
		this.getDashboard()
		this.$emit('changeInfo', { info: this.institute })
	},
	mounted() {
		if (this.isJoin) {
			this.$emit('appInitFlow')
			this.showJoinSuccessModal()
		}
		this.$refs['modal-main-notice'].show()
		this.$refs['modal-main-research'].show()
	},
	watch: {
		user: {
			immediate: true,
			deep: true,
			handler(user) {
				if (user.isCertificated === false) {
					this.showEmailCertificationModal()
				}
			},
		},
	},
	data() {
		return {
			drag: false,
			setting: false,
			noti: true,
			copyDashboardList: {},
			initDashboardList: {
				left: [
					{ id: 1, name: '물품 보유 현황', visible: true },
					{ id: 2, name: '특수건강진단 현황', visible: true },
					{ id: 3, name: '물품 등록 현황', visible: true },
					{ id: 4, name: '물품 폐기 현황', visible: true },
				],
				right: [
					{ id: 5, name: 'MSDS 업로드 현황', visible: true },
					{
						id: 6,
						name: '저장소별 위험물 지정수량 현황',
						visible: true,
					},
					{ id: 7, name: '화학물질 현황', visible: true },
					{ id: 8, name: '법령별 화학물질 현황', visible: true },
					{ id: 9, name: '폐기해야할 물품', visible: true },
				],
			},
			liteDashboardList: {
				left: [
					{ id: 1, name: '물품 보유 현황', visible: true },
					{ id: 3, name: '물품 등록 현황', visible: true },
					{ id: 4, name: '물품 폐기 현황', visible: true },
				],
				right: [
					{ id: 5, name: 'MSDS 업로드 현황', visible: true },
					{ id: 7, name: '화학물질 현황', visible: true },
					{ id: 9, name: '폐기해야할 물품', visible: true },
				],
			},
			dashboardScroll: 0,
		}
	},
	methods: {
		...mapActions('dashboard', [
			'lookupCreateTrendList',
			'lookupCreateTrendSummary',
			'lookupDisposalTrendList',
			'lookupDisposalTrendSummary',
			'lookupUseTrendList',
			'lookupUseTrendSummary',
			'lookupPossessionTrendList',
			'lookupDisposalItem',
			'lookupMsdsUpload',
			'lookupDiagnosisList',
			'lookupDangerStorage',
			'lookupLegalList',
			'updateDashboardSetting',
		]),
		showEmailCertificationModal() {
			this.$emit('openEmailCertificationModal')
		},
		showJoinSuccessModal() {
			this.$emit('openJoinSuccessModal', this.$route.params.joinModalTitle)
		},
		scroll() {
			const scrollTop = this.$refs['dashboard'].scrollTop

			if (scrollTop) {
				this.dashboardScroll = scrollTop
			}
		},
		async getDashboardList() {
			this.copyDashboardList = []
			try {
				const response = await dashboardAPI.lookupDashboardList(this.$route.params.instituteId)

				this.dashboardList = response.data

				this.copyDashboardList = JSON.parse(JSON.stringify(this.dashboardList))
			} catch (error) {
				console.error(error)
			}
		},
		async getDashboard() {
			const instId = this.$route.params.instituteId

			const columnPayload = {
				instId: instId,
				scope: 'day',
			}
			await this.getDashboardList()
			this.lookupCreateTrendList(columnPayload)
			this.lookupCreateTrendSummary(columnPayload)
			this.lookupDisposalTrendList(columnPayload)
			this.lookupDisposalTrendSummary(columnPayload)
			this.lookupUseTrendList(columnPayload)
			this.lookupUseTrendSummary(columnPayload)
			this.lookupPossessionTrendList(instId)
			this.lookupDisposalItem(instId)
			this.lookupMsdsUpload(instId)
			if (this.license.type != 'BASIC') {
				this.lookupDiagnosisList(instId)
				this.lookupDangerStorage(instId)
				this.lookupLegalList(instId)
			}
		},
		visibleCard(setting) {
			for (let item of this.copyDashboardList.left) {
				if (setting.id === item.id) {
					item.visible = setting.visible
				}
			}
			for (let item of this.copyDashboardList.right) {
				if (setting.id === item.id) {
					item.visible = setting.visible
				}
			}
		},
		async cancelSetting() {
			this.copyDashboardList = []
			this.setting = false
			await this.getDashboardList()
			this.copyDashboardList = JSON.parse(JSON.stringify(this.dashboardList))
		},
		saveSetting() {
			const instId = this.$route.params.instituteId

			if (this.copyDashboardList) {
				const payload = {
					instId: instId,
					body: {
						left: this.copyDashboardList.left,
						right: this.copyDashboardList.right,
					},
				}

				this.updateDashboardSetting(payload).then(() => {
					this.showSaveSuccessToast()
					this.getDashboardList()
				})
			}

			this.setting = false
		},
		showSaveSuccessToast() {
			this.$root.toast(this.tt('대시보드 설정 완료'), this.tt('대시보드 설정이 저장되었습니다'), 'success')
		},
		showInit() {
			this.$refs['modal-common'].show(
				this.tt('설정 초기화'),
				this.tt('정말로 초기화하시겠습니까?') + '&' + this.tt('대시보드 기본 설정으로 초기화됩니다.'),
				'black',
				this.tt('초기화')
			)
		},
		initDashboard(select) {
			if (select.check == true) {
				const payload = {
					instId: this.$route.params.instituteId,
					body: {
						left: this.initDashboardList.left,
						right: this.initDashboardList.right,
					},
				}

				this.updateDashboardSetting(payload).then(() => {
					this.$root.toast('설정 초기화', '대시보드 설정을 초기화했습니다', 'success')
					this.getDashboard()
				})

				this.setting = false
			}
			this.$refs['modal-common'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';

.scroll {
	> .AppContainer__title {
		box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
	}
}
</style>
