import lmsAPI from '@/services/api/purchase/lms'

const state = {
	loadParams: {
		keyword: null,
		startDatetime: null,
		endDatetime: null,
		offset: 0,
		length: 10,
	},
	selectedEstimateId: null,
	list: [],
	count: 0,
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setOffset: (state, pageNumber) => {
		state.loadParams.offset = (pageNumber - 1) * length
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			if (key == 'groupsId') state.loadParams.groupId = loadParams[key][0]
			else state.loadParams[key] = loadParams[key]
		})
	},
	setSelectedEstimateId: (state, id) => {
		state.selectedEstimateId = id
	},
}
const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		const response = await lmsAPI.estimateList(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		dispatch('getList')
	},
	getDetail: async ({ rootGetters, state }) => {
		const response = await lmsAPI.estimate(rootGetters.instituteId, state.selectedEstimateId)
		return response.data
	},
}
export default { namespaced: true, state, mutations, actions }
