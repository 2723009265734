<template>
	<div class="option-info left">
		<div class="option-image">
			<img class="option-image-box" :src="image" />
		</div>
		<div class="option-content">
			<div class="seller-name">
				<span @click="showModal" style="cursor: pointer">
					{{ `[${tt(product.seller.name)}]` }}
				</span>
			</div>
			<div class="name">
				{{ product.name }}
			</div>
			<div class="subname">
				{{ product.subname }}
			</div>
			<div class="data">
				<span
					>{{ tt('카테고리') }} : {{ tt(cleanData(category2String(product.categoryType ? product.categoryType : product.category))) }}</span
				>
				<span
					>{{ tt('브랜드') }} : {{ tt(cleanData(product.brandName ? product.brandName : product.brand ? product.brand.name : '-')) }}</span
				>
				<span>{{ tt('제품번호') }} : {{ tt(cleanData(product.productCode ? product.productCode : product.code)) }}</span>
				<span>{{ tt('CAS No') }} : {{ tt(cleanData(product.casno)) }}</span>
				<span v-if="product.volume">
					{{ tt('사이즈(용량)') }} : {{ tt(`${product.volume ? product.volume : product.currentVolume}${product.volumeUnit}`) }}
				</span>
			</div>
			<!-- m10 기획 변경으로 인한 제거 ( 주석처리 ) -->
			<!-- <div class="price-delivery">
				<span class="price"> {{ tt('${1}원', CommaNum(product.unitPrice)) }} </span>
				<span v-if="product.stock === 0 || product.stock">
					<img :src="require('@/views/purchase/draft/svg/Stock.svg')" style="margin-right: 2px" />
					{{ tt('재고') }} : {{ tt('${1}개', stock) }}
				</span>
				<span v-if="product.shippingDay">
					<img :src="require('@/views/purchase/draft/svg/Shipping.svg')" style="margin-right: 2px" />
					{{ tt('약 ${1}일 이내 출고예정', product.shippingDay) }}
				</span>
				<span v-if="storeStock > 0" style="color: #faa400">
					<img src="../../svg/Error_y.svg" />
					{{ tt('이미 보유중인 물품입니다') }}
				</span>
			</div> -->
		</div>
		<modal-seller-info ref="modal-seller-info" :seller="product.seller"></modal-seller-info>
	</div>
</template>

<script>
import { LargeClassToString, MiddleClassToString } from '@/utils/define/ItemCode'
import ModalSellerInfo from '../modal/ModalSellerInfo.vue'

export default {
	components: {
		ModalSellerInfo,
	},
	props: {
		product: {
			type: Object,
			default: () => {},
		},
		isDelivery: {
			type: Boolean,
			default: false,
		},
		useSale: {
			type: Boolean,
			default: true,
		},
		storeStock: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		image() {
			return this.product.brand?.image ?? require('@/assets/svg/no-image.svg')
		},
		stock() {
			return this.product.stock ? this.product.stock : 0
		},
	},
	methods: {
		cleanData(data) {
			return data ?? '-'
		},
		category2String(category) {
			if (typeof category === 'string') {
				let largeCategory = category.substring(0, category.lastIndexOf('_'))
				if (largeCategory === 'CAT') return `${this.tt(LargeClassToString(category))}` // category by 1 depth
				return `${this.tt(LargeClassToString(largeCategory))} / ${this.tt(MiddleClassToString(category))}`
			}

			return category.parents[0] ? `${this.tt(category.parents[0].korName)} / ${this.tt(category.korName)}` : `${this.tt(category.korName)}`
		},
		CommaNum(num) {
			return num.toLocaleString('ko-KR')
		},
		showModal() {
			this.$refs['modal-seller-info'].show()
		},
	},
}
</script>

<style lang="scss" scope>
.option-info {
	display: flex;
	.option-image {
		width: 80px;
		height: 80px;
		border: 1px solid $DIVIDER;
		border-radius: 4px;
		img.option-image-box {
			object-fit: contain;
			width: 100%;
			height: 100%;
			border-radius: 4px;
		}
		// img {
		// 	border-radius: 4px;
		// }
	}
	.option-content {
		margin-left: 16px;
		font-size: 12px;
		line-height: 18px;
		color: $SECONDARY_2;
		font-weight: 400;
		width: calc(100% - 100px);
		text-align: left;
		.name {
			font-size: 16px;
			font-weight: 700;
			color: $DEFAULT;
			margin-bottom: 4px;
		}
		.data,
		.size {
			font-size: 12px;
			color: $SECONDARY_2;
			span + span {
				display: inline-block;
				&::before {
					vertical-align: middle;
					margin-left: 6px;
					margin-right: 6px;
					display: inline-block;
					height: 12px;
					content: '';
					border-right: 1px solid $INPUT;
				}
			}
		}
		.price {
			font-size: 14px;
			color: $DEFAULT;
		}

		.price-delivery {
			font-size: 14px;
			font-weight: 500;
			color: $DEFAULT;
			margin-top: 10px;
			// .delivery {
			// 	&::after {
			// 		border: none;
			// 	}
			// }
			span + span {
				display: inline-block;
				img {
					margin-bottom: 3px;
				}
				&::before {
					margin-bottom: 3px;
					vertical-align: middle;
					margin-left: 6px;
					margin-right: 6px;
					display: inline-block;
					height: 12px;
					content: '';
					border-right: 1px solid $INPUT;
				}
			}
		}
	}
}
</style>
