<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade hide-footer>
		<template #modal-title>
			{{ tt('라이선스 관리') }}
		</template>
		<template>
			<h5>{{ tt('이용중인 라이선스') }}</h5>
			<div class="license-summary">
				<img class="imgbox" src="@/assets/images/lm-pro-icon.png" />
				<div class="txtbox">
					<h6>{{ licenseTypeText }}</h6>
					<p v-if="isFreeTier">
						{{ tt('30일 무료') }}
					</p>
					<p v-else-if="isTrial">
						{{ tt('무료') }}
					</p>
					<p v-else-if="isEducation">
						{{ tt('${1}원 / 연구실당 (연 기준 ・ VAT 별도)', formatNumberLocale(licenseRatePlan['YEAR']['EDUCATION'])) }}
					</p>
					<p v-else>
						{{ tt('${1}원 / 라이선스 당(월 기준 ・ VAT 별도)', formatNumberLocale(getAdditonalPrice)) }}
					</p>
				</div>
			</div>
			<div class="license-info">
				<h5>{{ tt('현재 라이선스') }}</h5>
				<ul>
					<li>
						<p>{{ tt('상태') }}</p>
						<div class="status">
							<span
								class="license-status"
								:class="{
									expired: !isLicenseActivate,
								}"
							>
								{{ licenseStatusText }}
							</span>
							<span class="waiting-status" v-if="isLicenseDepositWait">
								{{ tt('입금대기') }}
							</span>
						</div>
					</li>
					<li>
						<p>{{ tt('라이선스 만료일') }}</p>
						<span>
							<span :class="{ red: !isLicenseActivate, bold: !isLicenseActivate }">
								{{ formatDatetime(license.expireDate) }}
							</span>
							<span
								class="blue add"
								v-if="isLicenseActivate && isPayLicense && !beingSubscription && !isLicenseDepositWait"
								@click="$emit('addDate')"
							>
								{{ tt('추가') }}
							</span>
						</span>
					</li>
					<!--라이선스 활성화 된 경우(결제 완료)-->
					<li v-if="isFreeTier">
						<p>
							{{ tt('멤버 수') }}
						</p>
						<span class="license-member">
							{{ formatNumberLocale(memberCount) }}
						</span>
					</li>
					<li v-else>
						<p>
							{{ tt('활성화된 멤버 / 라이선스 수') }}
						</p>
						<span class="licese-member">
							<span class="bold" :class="{ red: isMemberFull }">
								{{ formatNumberLocale(memberCount) }}
							</span>
							<span class="license-count">
								/
								{{ license.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(license.quantity) }}
							</span>
							<span class="blue add" v-if="isLicenseActivate && !isEducation && !isLicenseDepositWait" @click="$emit('addMember')">
								{{ tt('추가') }}
							</span>
						</span>
					</li>
				</ul>
			</div>
			<DepositInformation v-if="isLicenseDepositWait" @hide="hide" />
			<div class="modal-footer" v-else-if="!isLicenseActivate || beingSubscription || isFreeTier">
				<button v-if="!isLicenseActivate || isFreeTier" class="button-primary" @click="buyLicense">
					{{ tt('라이선스 구매') }}
				</button>
				<!-- <button v-else-if="isLicenseActivate && !beingSubscription" class="button-gray">
				{{ tt('정기결제로 편하게 관리해보세요') }}
				<span class="start">정기결제 시작하기</span>
			</button> -->
				<LicenseManageFold v-else-if="isLicenseActivate && beingSubscription" @manageSubscribe="$emit('manageSubscribe')" />
			</div>
			<div v-else></div>
		</template>
	</b-modal>
</template>
<script>
import DepositInformation from '../components/DepositInformation.vue'
import LicenseManageFold from '../components/LicenseManageFold.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
export default {
	components: {
		DepositInformation,
		LicenseManageFold,
	},
	mixins: [MixinLicense, MixinPayment],
	methods: {
		async show() {
			await this.loadLicenseManagement()
			await this.loadLicenseRatePlan()
			this.$refs['modal'].show()
			if (this.beingSubscription) {
				await this.loadDefaultCreditCard()
			}
		},
		hide() {
			this.$refs['modal'].hide()
		},
		buyLicense() {
			this.$root.purchaseLicense()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-manage.scss';
</style>
