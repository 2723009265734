import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	lookupLegalList: (instId, offset, length, orders, ordersDirection, keyword) => {
		let parameter = `/institute/${instId}/safety/legal/items/count/list?offset=${offset}&length=${length}`
		if (orders && orders.length != 0) parameter += `&${ParamMaker.setArrayString('orders', orders)}`
		if (ordersDirection && ordersDirection.length != 0) parameter += `&${ParamMaker.setArrayString('ordersDirection', ordersDirection)}`
		if (keyword != '') parameter += `&keyword=${keyword}`
		return parameter
	},
	legalListByCasno: instituteId => `/institute/${instituteId}/safety/legal/list`,
}

const legalAPI = {
	lookupLegalList: (instId, offset, length, orders, ordersDirection, keyword) =>
		axiosInstance.get(endPoint.lookupLegalList(instId, offset, length, orders, ordersDirection, keyword)),
	legalListByCasno: (instituteId, params) => axiosInstance.get(endPoint.legalListByCasno(instituteId), { params }),
}

export default legalAPI
