<template>
	<b-modal
		ref="modal"
		:id="id"
		centered
		:title="tt(modalTitle)"
		:ok-title="tt('저장')"
		ok-variant="warning"
		ok-only
		:ok-disabled="isDisabledBtn"
		button-size="lg"
		@show="onShow"
		@ok="onSave"
		no-close-on-backdrop
		no-fade
	>
		<div>
			<input-form-item
				v-model="info.name"
				direction="column"
				title="배송지명"
				placeholder="배송지명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0; width: 100%"
				:autofocus="!isEdit"
				required
			/>
			<input-form-item
				v-model="info.instituteName"
				direction="column"
				title="학교 / 기업명"
				placeholder="학교 / 기업명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0; width: 100%; margin-top: 10px"
				required
			/>
			<input-form-item
				v-model="info.groupName"
				direction="column"
				title="학과 / 부서명"
				placeholder="학과 / 부서명을 입력해주세요"
				:inputStyle="inputStyle"
				style="padding: 0; width: 100%; margin-top: 10px"
				required
			/>
			<input-form-item
				:postcode="info.postcode"
				:address="info.address"
				:addressDetail="info.addressDetail"
				direction="column"
				type="address"
				title="주소"
				:inputStyle="inputStyle"
				@searchAddress="onSearchAddress"
				required
			/>
			<div class="chekbox-wrapper">
				<input v-model="info.isDefault" type="checkbox" id="save" name="save" class="checkbox" />
				<label for="save">{{ tt('기본 배송지로 저장') }}</label>
			</div>
		</div>
	</b-modal>
</template>

<script>
import InputFormItem from '@/components/form-item/InputFormItem.vue'
import { mapActions, mapGetters } from 'vuex'
import MixinStore from '@/mixins/store'
export default {
	name: 'ModalShippingAddress',
	components: {
		InputFormItem,
	},
	mixins: [MixinStore],
	props: {
		title: {
			type: String,
			default: '배송지 등록',
		},
		item: {
			type: Object,
			default: () => ({}),
		},
		id: {
			type: String,
			default: 'ModalShippingAddress',
		},
	},
	data() {
		return {
			inputStyle: {
				width: '566px',
			},
			info: {},
			isDisabledBtn: true,
			isEdit: false,
		}
	},
	computed: {
		modalTitle() {
			return this.isEdit ? '배송지 수정' : this.title
		},
	},
	watch: {
		item(val) {
			this.info = this.$_.cloneDeep(val)
		},
		info: {
			handler() {
				this.isDisabledBtn =
					!this.info.name ||
					!this.info.instituteName ||
					!this.info.groupName ||
					!this.info.postcode ||
					!this.info.address ||
					!this.info.addressDetail
			},
			deep: true,
		},
	},
	methods: {
		init() {
			if (!this.isEdit) {
				this.info = {
					id: 0,
					name: '',
					instituteName: '',
					groupName: '',
					postcode: '',
					address: '',
					addressDetail: '',
					isDefault: 0,
				}
				this.isDisabledBtn = true
			}
		},
		show(item) {
			this.isEdit = false
			if (item) {
				this.isEdit = true
				this.info = this.$_.cloneDeep(item)
			}
			this.$refs['modal'].show()
		},
		onShow() {
			this.init()
		},
		onSearchAddress({ postcode, address, addressDetail }) {
			this.info.postcode = postcode
			this.info.address = address
			this.info.addressDetail = addressDetail
			this.info = this.$_.cloneDeep(this.info)
		},
		async onSave(e) {
			e.preventDefault()

			this.isEdit ? this.update() : this.regist()
		},
		async regist() {
			try {
				this.info.isDefault = !!this.info.isDefault
				await this.addAddress(this.info)
				this.$emit('success')
				this.$root.toast('배송지 등록 완료', '새 배송지를 등록했습니다.', 'success')
				this.$refs.modal.hide()
			} catch (e) {
				this.$root.toast('배송지 등록 실패', e.response.data.msg, 'error')
			}
		},
		async update() {
			try {
				await this.updateAddress({
					instituteId: this.lmsInstituteId,
					body: {
						...this.info,
						deliveryId: this.info.id,
						isDefault: !!this.info.isDefault,
					},
				})

				this.$emit('success', this.info)
				this.$root.toast('배송지 수정 완료', '배송지를 수정하였습니다.', 'success')
				this.$refs.modal.hide()
			} catch (e) {
				this.$root.toast('편집 실패', e.response.data.msg, 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .btn {
	&.disabled {
		border: none !important;
		background: $GREY_1 !important;
	}
}
::v-deep .item-wrapper {
	margin-bottom: 10px;
}
.chekbox-wrapper {
	display: flex;
	align-items: center;
	gap: 11px;
	margin-bottom: 40px;
	label {
		font-size: 0.875rem;
		font-weight: 500;
		color: $COLOR_000;
		margin-bottom: 0px;
	}
}
::v-deep {
	.modal-body {
		padding: 0.5rem 1rem;
	}
	.modal-dialog {
		max-width: 600px;
	}
	.modal-header {
		border-bottom: 1px solid $DIVIDER;
	}
	.modal-footer {
		border-top: 1px solid $DIVIDER;
		justify-content: center;
		.btn {
			justify-content: center;
			width: 400px;
			height: 48px;
			background: $LAB_YELLOW;
		}
	}
}
</style>
