<template>
	<div class="SafetyHealthCheckupDetail scroll">
		<div class="CheckupDetail__title" v-if="info != null">
			<div class="imgbox">
				<img :src="info.image ? info.image : require('@/assets/svg/member-photo-default.svg')" />
			</div>
			<h2 class="AppContainer__title">{{ tt('${1}님의 특수건강진단 관리', info.name) }}</h2>
			<button
				class="ml-tuo Btn__complete"
				@click="showInputCheckup(info.id)"
				v-if="userPermission.safe && userPermission.safe.diagnosis.create"
			>
				{{ tt('진단일 입력') }}
			</button>
		</div>
		<div class="CheckupDetail__summary boxwrap" v-if="diagnosisInfo != null">
			<h5>{{ tt('특수건강진단 요약') }}</h5>
			<ul class="list">
				<li v-if="diagnosisInfo.nextDiagDate != null" :class="progessDate(diagnosisInfo.nextDiagDate) < 0 ? '' : 'error'">
					<div class="imgbox">
						<img
							:src="
								progessDate(diagnosisInfo.nextDiagDate) < 0
									? require('@/assets/svg/safety-summary-icon1.svg')
									: require('@/assets/svg/icon-error.svg')
							"
							alt="icon"
						/>
					</div>
					<div class="txtbox">
						<p>{{ tt('다음 진단일: ${1}까지', formatDate(diagnosisInfo.nextDiagDate)) }}</p>
						<span
							v-text="
								progessDate(diagnosisInfo.nextDiagDate) < 0
									? tt('${1}일 남음', Math.abs(progessDate(diagnosisInfo.nextDiagDate)))
									: tt('${1}일 경과', progessDate(diagnosisInfo.nextDiagDate))
							"
						></span>
					</div>
				</li>
				<li v-if="diagnosisInfo.lastDiagDate">
					<div class="imgbox">
						<img src="@/assets/svg/safety-summary-icon2.svg" alt="icon" />
					</div>
					<div class="txtbox">
						<p>
							{{ tt('최근 진단일') }}:
							{{ formatDate(diagnosisInfo.lastDiagDate) }}
						</p>
						<span>{{ tt('입력자') }}: {{ diagnosisInfo.lastDiagWriterName }}</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="CheckupDetail__info boxwrap" v-if="info != null">
			<h5>{{ tt('대상인원 정보') }}</h5>
			<ul class="list">
				<li v-if="info.name">
					<span>{{ tt('이름') }}</span>
					<p>{{ info.name }}</p>
				</li>
				<li v-if="info.email">
					<span>{{ tt('이메일') }}</span>
					<p>{{ info.email }}</p>
				</li>
				<li v-if="info.phone">
					<span>{{ tt('연락처') }}</span>
					<p>{{ info.phone }}</p>
				</li>
				<li v-for="(item, idx) in info.groups" :key="idx" v-if="item != null">
					<span>{{ tt('소속') }}{{ idx + 1 }}</span>
					<p>{{ item.name }}</p>
				</li>
			</ul>
		</div>
		<div class="CheckupDetail__usage-history boxwrap" v-if="usageInfo.length > 0">
			<h5>
				{{ tt('특수건강진단 대상 물질 사용기록') }}
				<button @click="showNotice">
					<img src="@/assets/svg/Question.svg" alt="icon" />
				</button>
			</h5>
			<table>
				<thead>
					<tr>
						<th>
							<span>{{ tt('카테고리') }}</span>
						</th>
						<th>
							<span>{{ tt('관리번호') }}</span>
						</th>
						<th>
							<span>{{ tt('물품정보') }}</span>
						</th>
						<th><span>CAS No</span></th>
						<th>
							<span>{{ tt('사용시간') }}</span>
						</th>
						<th>
							<span>{{ tt('진단 기한') }}</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in usageInfo" :key="`usage` + index">
						<td>{{ item.categoryName }}</td>
						<td>
							<span v-text="item.inventory.mngcode ? item.inventory.mngcode : '-'"></span>
						</td>
						<td>
							<div class="description">
								<h6>{{ item.inventory.name }}</h6>
								<span>{{ item.inventory.subname }}</span>
							</div>
						</td>
						<td>{{ item.inventory.casno }}</td>
						<td>{{ formatDate(item.useDatetime) }}</td>
						<td
							:class="{
								warning: item.targetDiagDate && item.targetDiagDate == fastDate,
							}"
						>
							<p v-if="item.targetDiagDate">
								{{ item.targetDiagDate }}
							</p>
							<p v-else>-</p>
							<span v-if="item.targetDiagDate && item.targetDiagDate == fastDate">{{ tt('가장 빠른 다음 진단 기한입니다.') }}</span>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="Table__pagination">
				<pagination :total="usageCount" @page="usagePagination"></pagination>
			</div>
		</div>
		<div class="CheckupDetail__checkup-history boxwrap" v-if="diagInfo.length > 0">
			<h5>{{ tt('특수건강진단 기록') }}</h5>
			<table>
				<thead>
					<tr>
						<th width="840">
							<span>{{ tt('진단일') }}</span>
						</th>
						<th>
							<span>{{ tt('입력자') }}</span>
						</th>
						<th>
							<span>{{ tt('입력일') }}</span>
						</th>
						<th v-if="userPermission.safe.diagnosis.create">
							<span>{{ tt('관리') }}</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in diagInfo" :key="`diag` + index">
						<td>
							<b>{{ formatDate(item.diagnosisDate) }}</b>
						</td>
						<td>{{ item.writer.name }}</td>
						<td>{{ formatDate(item.createDatetime) }}</td>
						<td v-if="userPermission.safe.diagnosis.create">
							<button class="btn" @click="showDelete(item)">
								<img src="@/assets/svg/icon-delete.svg" />
								{{ tt('삭제') }}
							</button>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="Table__pagination">
				<pagination :total="diagCount" @page="diagPagination"></pagination>
			</div>
		</div>

		<!--modal-->
		<modal-common ref="modal-common" @isHide="hideModal"></modal-common>
		<modal-health-checkup-notice :isShow="displayNotice" @close="hideNotice"></modal-health-checkup-notice>
		<modal-input-checkup ref="input-checkup" @submit="hideInputCheckup"></modal-input-checkup>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Pagination from '@/views/common/components/Pagination.vue'
import instituteMemberAPI from '@/services/api/institute/member'

import ModalHealthCheckupNotice from '@/views/safety/diagnosis/modals/ModalHealthCheckupNotice.vue'
import ModalInputCheckup from '@/views/safety/diagnosis/modals/ModalInputCheckup.vue'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'

export default {
	name: 'SafetyHealthCheckupDetail',
	props: {},
	components: {
		ModalHealthCheckupNotice,
		'modal-input-checkup': ModalInputCheckup,
		Pagination,
		ModalCommon,
	},
	async created() {
		this.today = new Date()
		await this.loadList()
		await this.loadInfo()
		// await this.nextDiagDate()
	},
	mounted() {},
	data() {
		return {
			info: null,
			today: '',
			displayNotice: false,
			displayInputCheckup: false,
			pagination: [1, 2, 3, 4, 5],
			currentPage: 1,
			totalPage: 778,
			usageOffset: 0,
			usageLength: 50,
			usageCount: 0,
			usageInfo: [],
			diagOffset: 0,
			diagLength: 50,
			diagCount: 0,
			diagInfo: [],
			summaryInfo: '',
			selectId: '',
			offset: null,
			length: 50,
			keyword: undefined,
			showOnlyOverdue: false,
			order: [],
			orderDirection: [],
			list: [],
			fastDate: null,
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('diagnosis', ['diagnosisList', 'diagnosisUsageInfo', 'diagnosisInfo', 'diagnosisLogInfo']),
	},
	methods: {
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		...mapActions('diagnosis', [
			'lookupDiagnosisList',
			'lookupDiagnosisInfo',
			'lookupDiagnosisUsageInfo',
			'lookupDiagnosisLogInfo',
			'updateDiagnosisDate',
			'deleteDiagnosis',
		]),
		async loadList() {
			await instituteMemberAPI.lookupMember(this.$route.params.instituteId, this.$route.params.id).then(response => {
				this.info = response.data
				this.setStorageDetailPaths([this.tt('${1}님의 특수건강진단 관리', this.info.name)])
			})
		},
		async loadInfo() {
			this.summaryInfo = ''
			this.usageInfo = []
			this.diagInfo = []

			if (this.info != null) {
				let infoPayload = {
					instId: this.$route.params.instituteId,
					userId: this.$route.params.id,
					offset: this.usageOffset,
					length: this.usageLength,
				}
				await this.loadSummaryInfo(infoPayload)
				this.loadUsageInfo(infoPayload)
				this.loadDiagnosisLogInfo(infoPayload)
			}
		},
		async loadSummaryInfo(payload) {
			await this.lookupDiagnosisInfo(payload)
			this.summaryInfo = this.diagnosisInfo
		},
		loadUsageInfo(payload) {
			this.lookupDiagnosisUsageInfo(payload).then(() => {
				this.usageInfo = this.diagnosisUsageInfo.list
				this.usageCount = this.diagnosisUsageInfo.count

				if (this.usageInfo.length > 0) {
					this.nextDiagDate()
				}
			})
		},
		loadDiagnosisLogInfo(payload) {
			this.lookupDiagnosisLogInfo(payload).then(() => {
				this.diagInfo = this.diagnosisLogInfo.list
				this.diagCount = this.diagnosisLogInfo.count
			})
		},
		showNotice() {
			this.displayNotice = true
		},
		hideNotice() {
			this.displayNotice = false
		},
		showInputCheckup(usersId) {
			this.$refs['input-checkup'].show(usersId)
		},
		async hideInputCheckup(usersId, date) {
			const payload = {
				instId: this.$route.params.instituteId,
				body: {
					usersId: usersId,
					datetime: date,
				},
			}

			const response = await this.updateDiagnosisDate(payload)
			this.$refs['input-checkup'].hide()
			await this.loadInfo()

			const toastTitle = this.tt('진단일 입력')
			const toastContent = this.tt('선택한 인원의 진단일이 저장되었습니다')
			this.$root.toast(toastTitle, toastContent, 'success')
		},
		progessDate(date) {
			if (date) {
				let diffDays = this.$DateTime.now().diff(this.$DateTime.fromISO(date), 'days')
				console.log(diffDays.toObject())
				return Math.floor(diffDays.toObject().days)
			}
		},
		formatDate(date) {
			if (date) {
				return this.$DateTime.fromISO(date).toISODate()
			}
			return '-'
		},
		usagePagination(page) {
			this.usageOffset = page.offset
			this.usageLength = page.length
			let infoPayload = {
				instId: this.$route.params.instituteId,
				userId: this.$route.params.id,
				offset: this.usageOffset,
				length: this.usageLength,
			}
			this.loadUsageInfo(infoPayload)
		},
		diagPagination(page) {
			this.diagOffset = page.offset
			this.diagLength = page.length
			this.usageOffset = page.offset
			this.usageLength = page.length
			let infoPayload = {
				instId: this.$route.params.instituteId,
				userId: this.$route.params.id,
				offset: this.diagOffset,
				length: this.diagLength,
			}
			this.loadDiagnosisLogInfo(infoPayload)
		},
		firstDiagnosisDate(date) {
			return date
		},
		showDelete(item) {
			const title = this.tt('특수건강진단 기록 삭제')
			const desc = this.tt('정말로 진단 기록을 삭제하시겠습니까?') + '&' + this.tt('이 동작은 되돌릴 수 없습니다')
			const button = 'delete'
			const button_text = this.tt('삭제')
			this.$refs['modal-common'].show(title, desc, button, button_text)
			this.selectId = item.id
		},
		async hideModal(select) {
			if (select.check == true) {
				const payload = {
					instId: this.$route.params.instituteId,
					ids: [this.selectId],
				}
				await this.deleteDiagnosis(payload)
				await this.loadInfo()

				const toastTitle = this.tt('진단 기록 삭제')
				const toastContent = this.tt('진단 기록이 삭제되었습니다')
				this.$root.toast(toastTitle, toastContent, 'success')
			}

			this.$refs['modal-common'].hide()
			// this.close()
		},
		async nextDiagDate() {
			const arr = this.usageInfo.map(x => x.targetDiagDate)
			if (arr.length > 0) {
				this.fastDate = arr.reduce((prev, curr) => (prev <= curr ? prev : curr))
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.SafetyHealthCheckupDetail {
	width: 100%;
	padding: 10px 50px 0;
	box-sizing: border-box;
	overflow-y: auto;

	.Table__pagination {
		position: relative;
		border-top: 0;
		padding: 0;
		.pagination {
			li {
				width: 30px;
				display: flex;
			}
		}
		.Table__per-page {
			right: 0;
			p {
				padding-right: 0;
			}
		}
		.Btn__select {
			display: none !important;
		}
	}
	.boxwrap {
		width: 100%;
		padding-top: 30px;
		border-top: 1px solid $DIVIDER;
		h5 {
			font-size: 1rem;
			color: #000;
			font-weight: 700;
			margin-bottom: 20px;
			text-align: left;
		}
		ul {
			text-align: left;
			li {
				width: 25%;
				display: inline-block;
			}
		}
	}
	table {
		width: 100%;
		border: 1px solid $INPUT;
		overflow: hidden;
		border-radius: 5px;
		border-spacing: 0;
		border-collapse: unset;
		text-align: left;
		font-size: 0.875rem;
		color: #000;
		margin-bottom: 20px;
		thead {
			background-color: $GREY_2;
			th {
				height: 40px;
				font-size: 0.875rem;
				font-weight: 700;
				color: $SECONDARY_2;
				padding: 5px;
				box-sizing: border-box;
				span {
					display: block;
					padding: 0 7.5px;

					border-left: 1px solid $INPUT;
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 0 12.5px;
					border-bottom: 1px solid $DIVIDER;
					height: 60px;
					.error {
						p {
							color: $RED;
							font-weight: 700;
						}
						span {
							display: block;
							font-size: 10px;
							font-weight: 400;
							color: $RED;
						}
					}
					.description {
						h6 {
							font-size: 0.875rem;
							color: $DEFAULT;
							font-weight: 700;
						}
						span {
							font-size: 0.75rem;
							color: $SECONDARY_2;
							font-weight: 400;
							display: block;
						}
					}
				}
				&:last-child {
					td {
						border-bottom: 0;
					}
				}
			}
		}
	}
	.CheckupDetail__title {
		display: flex;
		align-items: center;
		height: auto;
		margin-bottom: 10px;
		.imgbox {
			width: 60px;
			height: 60px;
			margin-right: 20px;
			overflow: hidden;
			border-radius: 8px;
			img {
				width: 100%;
				height: inherit;
				object-fit: cover;
			}
		}
		h2 {
			padding-left: 0;
		}
		.Btn__complete {
			margin-left: auto;
			height: 36px;
			border-radius: 8px;
			padding: 0 16px;
			font-weight: bold;
		}
	}
	.CheckupDetail__summary {
		padding-bottom: 30px;
		.list {
			padding-top: 10px;
			li {
				display: inline-flex;
				align-items: center;
				height: auto;
				&.error {
					.imgbox {
						border: 1px solid $RED;
						img {
							content: url(~@/assets/svg/safety-summary-error.svg);
						}
					}
					.txtbox {
						p {
							color: $RED;
						}
						span {
							color: $RED;
						}
					}
				}
				.imgbox {
					width: 36px;
					height: 36px;
					overflow: hidden;
					border-radius: 5px;
					border: 1px solid #ccc;
					background: $LAB_WHITE;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
					img {
						width: 24px;
						height: 24px;
					}
				}
				.txtbox {
					text-align: left;
					p {
						font-size: 0.875rem;
						color: #000;
						font-weight: 700;
					}
					span {
						font-size: 0.75rem;
						color: rgba(0, 0, 0, 0.6);
						display: block;
					}
				}
			}
		}
	}
	.CheckupDetail__info {
		.list {
			padding-bottom: 10px;
			li {
				padding-bottom: 20px;
				span {
					font-size: 0.75rem;
					color: rgba(0, 0, 0, 0.6);
					display: block;
				}
				p {
					font-size: 0.875rem;
					color: rgba(0, 0, 0, 0.87);
					height: 20px;
				}
			}
		}
	}
	.CheckupDetail__usage-history {
		h5 {
			display: flex;
			align-items: center;
			height: auto;
			button {
				width: 24px;
				height: 24px;
				padding: 0;
				margin-left: 4px;
				img {
					width: inherit;
					height: inherit;
				}
			}
		}
		p {
			&.warning {
				color: $RED;
			}
			&.warning ~ span {
				color: $RED;
				display: block;
				font-size: 10px;
				font-weight: 400;
				padding-top: 1px;
			}
		}
		td {
		}
		padding-bottom: 50px;
	}
	.CheckupDetail__checkup-history {
		th {
			white-space: pre;
		}
		td {
			white-space: pre;
		}
		.btn {
			img {
				padding-right: 0;
			}
		}
		padding-bottom: 50px;
	}
}
</style>

<style lang="scss" scoped>
.SafetyHealthCheckupDetail {
	width: 100%;
	padding: 10px 50px 0;
	box-sizing: border-box;
	overflow-y: auto;

	.Table__pagination {
		position: relative;
		border-top: 0;
		.pagination {
			li {
				width: 30px;
				display: flex;
			}
		}
		.Table__per-page {
			right: 0;
			p {
				padding-right: 0;
			}
		}
		.Btn__select {
			display: none !important;
		}
	}
}

.warning {
	p {
		color: $RED;
		font-weight: bold;
	}
	span {
		display: block;
		font-weight: 400;
		color: $RED;
		font-size: 10px;
	}
}
</style>
