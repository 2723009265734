<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<input
				type="checkbox"
				v-model="isChecked"
				:checked="isChecked"
				:class="{ someChecked: !isKeySelectedAll && !isEmptyKeySelected }"
				@change="checkChange"
				:disabled="isEmptyDivideItems"
			/>
			<!-- @change="handleChange" -->
		</th>
		<th>
			<span class="volume">{{ tt('용량') }}</span>
		</th>
		<th>
			<span class="storage">{{ tt('보관함') }}</span>
		</th>
		<th>
			<span>{{ tt('관리 및 추가정보') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'DivideTableHeader',
	components: {},
	props: {},
	data() {
		return {
			isChecked: false,
		}
	},
	mixins: [],
	computed: {
		...mapGetters('inventoryGen2/divide', ['selectedItem', 'isEmptyKeySelected', 'isKeySelectedAll', 'allItemDivideKey', 'isEmptyDivideItems']),
	},
	watch: {
		isKeySelectedAll: {
			deep: true,
			handler(value) {
				this.isChecked = value
			},
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/divide', ['setSelectedItem']),
		checkChange() {
			this.setSelectedItem(this.isChecked ? this.allItemDivideKey : [])
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
.table-header {
	th {
		width: auto !important;
	}
}
.header-checkbox {
	min-width: 70px !important;
	width: 70px;
}

.volume {
	min-width: 378px !important;
}

.storage {
	min-width: 350px !important;
}
</style>
