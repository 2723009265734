var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "Modal ModalWellDone"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Modal__container"
  }, [_c('div', {
    staticClass: "Modal__content"
  }, [_vm._m(0), _c('h2', [_vm._v("Well Done!")]), _c('p', [_vm._v(_vm._s(_vm.tt(_vm.content)))]), _c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.button)) + " ")]), _vm.mode == 3 ? _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.goGuide
    }
  }, [_vm._v(_vm._s(_vm.tt('랩매니저 가이드보기')))]) : _vm._e()])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/check.png")
    }
  })]);
}]

export { render, staticRenderFns }