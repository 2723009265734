var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppHeader__menu-item",
    "class": {
      disabled: _vm.disableClick
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.nested,
      expression: "nested"
    }],
    attrs: {
      "src": require("@/assets/svg/arrow-down.svg")
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVisible,
      expression: "isVisible"
    }],
    staticClass: "AppHeader__menu-list",
    "class": {
      left: _vm.isDirectionLeft
    }
  }, _vm._l(_vm.itemNameList, function (item, index) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isVisible,
        expression: "isVisible"
      }],
      key: "header-inner-item-".concat(index),
      on: {
        "click": _vm.hideDropdown
      }
    }, [_vm.isVisible ? _c('app-header-menu-item-vue', {
      attrs: {
        "text": _vm.tt(item.itemName)
      }
    }) : _vm._e()], 1);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }