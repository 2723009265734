<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			{{ tt(mode == 'REGISTER' ? '결제카드 등록' : '결제카드 변경') }}
		</template>
		<template>
			<div class="input-wrapper">
				<h5>{{ tt('결제수단') }}</h5>
				<div class="Popup__input-field">
					<p>{{ tt('카드번호') }}<span class="required">*</span></p>
					<input
						type="text"
						:placeholder="tt('14 ~ 16자리 번호를 입력해주세요')"
						:maxlength="cardNumberMaxLength"
						:value="cardNumber"
						@focus.stop.prevent="cardInput = true"
						@click.stop="cardInput = true"
						ref="input-cardnum"
						:class="{
							'on-input': cardInput,
							error: cardNoError,
						}"
						readonly
					/>
					<span class="error-text" v-if="cardNoError">
						<img src="@/assets/svg/input-icon-warning.svg" />
						<p>
							{{ tt('유효하지 않은 카드번호입니다.') }}
						</p>
					</span>
					<card-number-keyboard
						v-click-outside="showKeypad"
						v-if="cardInput"
						@add="addNumber"
						@remove="removeInputNumber"
					></card-number-keyboard>
				</div>
				<div class="Popup__input-field">
					<p>
						{{ tt('유효기간(MM/YY)') }}
						<span class="required">*</span>
					</p>
					<input
						type="text"
						:placeholder="tt('MM/YY')"
						maxlength="5"
						@input="inputExpirationMonthYear"
						:value="form.expirationMonthYear"
						:class="{ error: expirationError }"
					/>
					<span class="error-text" v-if="expirationError">
						<img src="@/assets/svg/input-icon-warning.svg" />
						<p>
							{{ tt('잘못된 유효기간입니다.') }}
						</p>
					</span>
				</div>
				<div class="Popup__input-field">
					<p>
						{{ tt('비밀번호 앞 2자리') }}
						<span class="required">*</span>
					</p>
					<input
						type="password"
						:placeholder="tt('비밀번호 앞 2자리를 입력해주세요')"
						maxlength="2"
						@input="inputPassword"
						:value="form.password"
						:class="{ error: passwordError }"
					/>

					<span class="error-text" v-if="passwordError">
						<img src="@/assets/svg/input-icon-warning.svg" />
						<p>
							{{ tt('잘못된 비밀번호입니다.') }}
						</p>
					</span>
				</div>
				<div class="Popup__input-field">
					<p>{{ tt('생년월일/사업자등록번호') }}<span class="required">*</span></p>
					<input
						type="text"
						:placeholder="tt('생년월일(6자리) 혹은 사업자 등록번호(10자리)를 입력해주세요')"
						maxlength="12"
						@input="inputRegistrationNumber"
						:value="form.registrationNumber"
						:class="{ error: registrationNoError }"
					/>
					<span class="error-text" v-if="registrationNoError">
						<img src="@/assets/svg/input-icon-warning.svg" />
						<p>
							{{ tt('잘못된 생년월일 또는 사업자등록번호입니다.') }}
						</p>
					</span>
				</div>
				<span
					v-html="
						tt(
							'개인카드 혹은 기명 법인카드인 경우 생년월일 6자리를 입력해주세요.<br>무기명 법인카드인 경우 사업자등록번호 10자리를 입력해주세요.'
						)
					"
				></span>
				<div class="Popup__input-field">
					<p>{{ tt('연락처') }}<span class="required">*</span></p>
					<input
						type="text"
						:placeholder="tt('연락처를 입력해주세요')"
						maxlength="13"
						:value="form.phone"
						@input="autoHypenTel"
						:class="{ error: phoneNoError }"
					/>
					<span class="error-text" v-if="phoneNoError">
						<img src="@/assets/svg/input-icon-warning.svg" />
						<p>
							{{ tt('연락처를 형식에 맞게 입력해주세요') }}
						</p>
					</span>
				</div>
				<div class="Popup__input-field">
					<p>{{ tt('이메일') }}<span class="required">*</span></p>
					<input type="email" :placeholder="tt('이메일 입력해주세요')" v-model="form.email" :class="{ error: emailNoError }" />
					<span class="error-text" v-if="emailNoError">
						<img src="@/assets/svg/input-icon-warning.svg" />
						<p>
							{{ tt('이메일을 형식에 맞게 입력해주세요') }}
						</p>
					</span>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<button class="Btn__yellow" @click="confirm">
				{{ tt(mode == 'REGIST' ? '등록 및 결제하기' : '변경하기') }}
			</button>
		</template>
	</b-modal>
</template>
<script>
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
import CardNumberKeyboard from '../components/CardNumberKeyboard.vue'
export default {
	components: {
		CardNumberKeyboard,
	},
	mixins: [MixinLicense, MixinPayment],
	data() {
		return {
			mode: 'REGIST',
			confirmFn: null,
		}
	},
	methods: {
		show(mode = 'REGIST', confirmFn = null) {
			this.initData()
			this.mode = mode
			this.confirmFn = confirmFn
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async confirm() {
			if (!this.validPhone()) {
				this.phoneNoError = true
				return
			}
			if (!this.validEmail()) {
				this.emailNoError = true
				return
			}
			try {
				const response = await this.registDefaultCreditCard(this.form)
				const cardId = response.data.id
				await this.updateLicenseSubscription({ cardId })
				this.$root.toast('카드 등록', '결제 카드를 등록하였습니다', 'success')
				this.hide()
				if (this.confirmFn) this.confirmFn(cardId)
				this.$emit('confirm', cardId)
			} catch (error) {
				this.$root.toast('카드 등록 실패', error?.response?.data?.msg ?? '카드 등록에 실패하였습니다. 카드정보를 확인해주세요', 'error')
				return
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-card_ebil.scss';
</style>
