<template>
	<div class="AddAuthorityMemberPopup Popup__depth3" v-if="isShow">
		<div class="Popup__background"></div>
		<div class="Popup__container Popup__size-sm">
			<div class="Popup__top">
				<h5 class="Popup__title">{{ tt(title) }}</h5>
				<button class="Popup__close-btn" @click="hide">
					<img src="@/assets/svg/popup-close.svg" alt="close" />
				</button>
			</div>
			<div class="Popup__content">
				<search-box :placeholder="searchPlaceholder" :loadList="searchKeyword" @reset="resetKeyword"></search-box>
				<div class="Organization__member-list">
					<ul class="horizational-list">
						<TreeView
							v-if="locationData"
							ref="modal-tree-view"
							key="modal-tree-view"
							textField="name"
							subTextField="membersCount"
							:draggable="false"
							mode="storageGroup"
							:selectWithChild="false"
							:keyword="keyword"
							@changeActive="getActiveId"
							:treeData="locationData"
							modal="location"
						></TreeView>
					</ul>
				</div>
			</div>
			<div class="Popup__bottom">
				<!-- <button class="Btn__management" @click="hide">
                        {{ $t('organization.modal.organization_management') }}
                    </button> -->
				<div class="Popup__complete-btn-group Popup__btn-group">
					<button class="Btn__close" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="Btn__complete" @click="complete">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SearchBox from '@/views/common/components/SearchBox.vue'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
	name: 'ModalSelectLocation',
	components: { SearchBox, TreeView },
	props: {
		// 기존에 선택된 데이터 Array
		selectedLocation: {
			type: Object,
		},
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		async isShow(value) {
			this.keyword = ''

			if (value) {
				const response = await this.loadLocationTreeV2(this.$route.params.instituteId)
				this.locationData = _.cloneDeep(response.data)
				if (this.selectedLocation.id) {
					this.$refs['modal-tree-view'].setActiveIdList([this.selectedLocation.id])
				} else {
					this.$refs['modal-tree-view'].setActiveIdList([this.selectedLocation.info.id])
				}
			}
		},
	},
	data() {
		return {
			title: this.tt('위치 지정'),
			locationData: [],
			originGroupList: [],
			selectedGroupList: [],
			selectedGroupNameList: [],
			searchPlaceholder: '위치를 검색해주세요',
			mode: 'selectable',
			groupList: [],
			keyword: '',
		}
	},
	computed: {
		// ...mapState('location', ['selectedLocation']),
	},
	methods: {
		...mapActions('location', ['loadLocationTreeV2']),
		complete() {
			const activeId = this.$refs['modal-tree-view'].getActiveIdList()[0]
			const activeNode = this.$refs['modal-tree-view'].getActiveNode(activeId)
			this.$emit('complete', activeNode)
			this.hide()
		},
		searchKeyword(keyword) {
			keyword ? (this.keyword = keyword) : (this.keyword = '')
		},
		getActiveId(idList, dataList) {
			this.selectedGroupList = dataList
		},
		getActiveName(nameList) {
			this.selectedGroupNameList = nameList
		},
		hide() {
			this.$emit('close')
		},
		resetKeyword() {
			this.keyword = ''
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_organization.scss';

.Organization__member-list {
	height: 500px;
}
.AddAuthorityMemberPopup {
	.horizational-list {
		height: 500px;
		width: 100%;
		padding-top: 0;
		margin-top: 20px;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		overflow-y: auto;
		li {
			padding: 12px;
			border-bottom: 0;
			transition: 0.3s all linear;
			&:hover {
				background-color: $GREY_1;
			}
			button {
				img {
					width: 20px;
					background-image: '@/assets/svg/Select-Circle.svg';
				}
			}
			> div {
				h6 {
					> img {
						width: 12px;
						margin-left: 6px;
					}
				}
			}
		}
	}
}
</style>
