var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input"
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('div', {
    staticClass: "datepicker"
  }, [_c('date-picker', {
    attrs: {
      "range": "",
      "rangeSeparator": _vm.rangeSeparator
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }