<template>
	<b-modal centered scrollable hide-footer @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<p class="Popup__plain-text">
				{{ tt('물품관리번호는 최대 5개의 항목으로 구성 가능합니다.') }}
			</p>
			<ul>
				<li
					v-for="item in list"
					:key="item.value"
					class="flex"
					:class="[{ checked: item.select }, { disabled: item.select }]"
					@click="selectCheck(item.value)"
				>
					<img :src="require(`@/assets/svg/` + item.icon)" />
					<div>
						<h6 class="mng-title">
							{{ item.title }}
							<img
								@click.stop="$refs['modal-storage-order-info'].show()"
								v-if="item.value == 'MNGCODE_STORAGE_ORDER'"
								src="@/views/inventoryGen2/svg/Guide.svg"
							/>
						</h6>
						<p>{{ item.description }}</p>
					</div>
				</li>
			</ul>
		</template>
		<modal-confirm
			ref="modal-add"
			title="보관함 등록순서 추가"
			content="보관함 등록순서 선택 시 보관함은 필수 관리항목입니다.<br/>관리항목에 보관함을 추가하시겠습니까?"
			confirmText="추가"
		/>
		<modal-confirm
			ref="modal-delete-close"
			title="관리번호에서 제외 불가"
			:noCancel="false"
			variant="white"
			confirmText="확인"
			:content="`보관함 등록순서 선택 시 보관함은 필수 관리항목입니다.`"
		/>
		<ModalStorageOrderInfo ref="modal-storage-order-info" />
	</b-modal>
</template>

<script>
import ModalStorageOrderInfo from './ModalStorageOrderInfo'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	components: {
		ModalStorageOrderInfo,
	},
	computed: {
		...mapState('inventoryGen2/setting', ['mngcode']),
		...mapGetters('inventoryGen2/setting', ['activeCodeList']),
		title() {
			return this.index ? '관리항목 변경' : '관리항목 추가'
		},
		list() {
			return [
				{
					title: this.tt('카테고리'),
					description: this.tt('카테고리를 약어로 표시합니다.'),
					icon: 'category-management-icon1.svg',
					value: 'MNGCODE_CATEGORY_NAME',
					select: this.activeCodeList.includes('MNGCODE_CATEGORY_NAME') ? true : false,
				},
				{
					title: this.tt('보관함'),
					description: this.tt('보관함을 약어로 표시합니다.'),
					icon: 'category-management-icon6.svg',
					value: 'MNGCODE_STORAGE_NAME',
					select: this.activeCodeList.includes('MNGCODE_STORAGE_NAME') ? true : false,
				},
				{
					title: this.tt('물품명'),
					description: this.tt('물품명의 앞글자 2자리를 표시합니다.'),
					icon: 'category-management-icon3.svg',
					value: 'MNGCODE_INVENTORY_NAME',
					select: this.activeCodeList.includes('MNGCODE_INVENTORY_NAME') ? true : false,
				},
				{
					title: this.tt('등록 연도/월/일(YYMMDD)'),
					description: this.tt('물품이 등록된 연도/월/일을 표시합니다.'),
					icon: 'category-management-icon7.svg',
					value: 'MNGCODE_DATE_YYMMDD',
					select: this.activeCodeList.includes('MNGCODE_DATE_YYMMDD') ? true : false,
				},
				{
					title: this.tt('등록 연도(YY)'),
					description: this.tt('물품이 등록된 연도를 표시합니다.'),
					icon: 'category-management-icon8.svg',
					value: 'MNGCODE_DATE_YY',
					select: this.activeCodeList.includes('MNGCODE_DATE_YY') ? true : false,
				},
				{
					title: this.tt('등록순서'),
					description: this.tt('기관 기준으로 물품이 등록된 순서를 숫자로 표시합니다.'),
					icon: 'category-management-icon5.svg',
					value: 'MNGCODE_INVENTORY_ORDER',
					select: this.activeCodeList.includes('MNGCODE_INVENTORY_ORDER') ? true : false,
				},
				{
					title: this.tt('보관함 등록순서'),
					description: this.tt('보관함에 물품이 등록된 순서를 숫자로 표시합니다.'),
					icon: 'category-management-icon5.svg',
					value: 'MNGCODE_STORAGE_ORDER',
					select: this.activeCodeList.includes('MNGCODE_STORAGE_ORDER') ? true : false,
				},
			]
		},
	},
	data() {
		return {
			index: null,
		}
	},
	methods: {
		...mapMutations('inventoryGen2/setting', ['setActiveClassList']),
		...mapActions('inventoryGen2/setting', ['editMngcode']),
		show(idx) {
			this.index = idx
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		selectCheck(value) {
			const activeList = JSON.parse(JSON.stringify(this.mngcode.activeClassList))
			if (value === 'MNGCODE_STORAGE_ORDER' && !this.activeCodeList.includes('MNGCODE_STORAGE_NAME')) {
				// 보관함 등록순서 추가시, 보관함이 active안되어있다면
				activeList.push(this.mngcode.inactiveClassList.find(it => it.code == 'MNGCODE_STORAGE_NAME'))
				this.$refs['modal-add'].show(() => this.submit(activeList, value))
			} else {
				this.submit(activeList, value)
			}
		},
		async submit(list, value) {
			try {
				const managementCode = this.mngcode.inactiveClassList.find(it => it.code === value)
				if (managementCode) {
					if (this.index !== undefined) {
						if (list[this.index].code == 'MNGCODE_STORAGE_NAME') {
							if (value == 'MNGCODE_STORAGE_ORDER') {
								throw 'no storage'
							} else if (this.activeCodeList.includes('MNGCODE_STORAGE_ORDER')) {
								// 보관함 관리항목 변경시, 보관함 등록순서가 active되어있다면
								throw 'active order'
							}
						}
						list[this.index] = managementCode
					} else {
						if (list.length > 4 && value == 'MNGCODE_STORAGE_ORDER') {
							throw 'max length'
						}
						list.push(managementCode)
					}
					await this.editMngcode({ activeClassList: list })
					this.$root.toast(this.tt('관리번호 재설정'), this.tt('물품 개수에 따라 시간이 다소 소요될 수 있습니다'), 'success')
					this.hide()
				}
			} catch (err) {
				const title = this.index ? '변경' : '추가'
				if (err == 'max length') {
					this.$root.toast(this.tt(`관리번호 ${title} 실패`), this.tt('최대 5개의 항목으로 구성 가능합니다.'), 'error')
				} else if (err == 'no storage') {
					this.$root.toast(
						this.tt(`관리번호 ${title}불가`),
						this.tt('보관함 등록 순서를 사용하시려면 보관함도 필수로 선택해야합니다.'),
						'error'
					)
				} else if (err == 'active order') {
					this.$refs['modal-delete-close'].show()
				} else {
					this.$root.toast(this.tt(`관리번호 ${title}불가`), this.tt(err.response.data.msg), 'warning')
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	border: 0;
}
ul {
	padding-top: 10px;
	li {
		width: 100%;
		border: 1px solid $INPUT;
		border-radius: 8px;
		background-color: $LAB_WHITE;
		padding: 12px 20px;
		height: 70px;
		box-sizing: border-box;
		margin-top: 10px;
		font-size: 0.875rem;
		cursor: pointer;
		transition: 0.5s all ease;
		position: relative;
		&:hover {
			background-color: $GREY_1;
		}
		input {
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			top: 0;
			left: 0;
			cursor: pointer;
			&:before {
				display: none;
			}
			&:after {
				display: none;
			}
		}
		img {
			margin-right: 20px;
		}
		h6 {
			font-weight: 700;
			font-size: 0.875rem;
			color: #000;
			img {
				cursor: pointer;
			}
		}
		p {
			// width: 90%;
			color: $SECONDARY_2;
			font-size: 0.875rem;
		}
		span {
			position: absolute;
			top: 50%;
			right: 20px;
			font-size: 0.75rem;
			color: $PLACEHOLDER;
			font-weight: 400;
			display: inline-block;
			transform: translate(0, -50%);
		}
		&.checked {
			border: 1px solid $TEXT_YELLOW;
			background-color: $SUB-YELLOW_100;
			cursor: not-allowed;
			img {
				// opacity: 0.5;
			}
			h6 {
				color: $DEFAULT;
			}
			p {
				color: $SECONDARY_2;
			}
		}
		&.disabled {
			cursor: not-allowed;
			// background-color: $GREY_1;
			input {
				cursor: not-allowed;
			}
		}
	}
}
</style>
