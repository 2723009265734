const editInventory = {
	namespaced: true,
	state: () => ({
		divideIndex: 0,
		qrScanList: [],
		divideQrScan: {},
		maxQr: 1,
		currentStorage: {},
	}),
	mutations: {
		setDivideIndex: (state, payload) => {
			state.divideIndex = payload
		},
		setQrList: (state, payload) => {
			state.qrScanList = payload
		},
		setMaxQr: (state, payload) => {
			state.maxQr = payload
		},
		setQrByIndex: (state, payload) => {
			const { index, data } = payload
			state.divideQrScan[index] = data
			state.divideQrScan = { ...state.divideQrScan }
		},
		getQrByIndex: (state, payload) => {
			return state.divideQrScan[payload]
		},
		resetQrScan: state => {
			state.qrScanList = []
		},
		resetAll: state => {
			state.divideIndex = 0
			state.qrScanList = []
			state.divideQrScan = {}
			state.maxQr = 1
		},
		setCurrentStorage: (state, payload) => {
			state.currentStorage = payload
		},
	},
	actions: {},
}
export default editInventory
