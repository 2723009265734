var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "Modal ModalNoticeLocation"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Modal__container"
  }, [_c('div', {
    staticClass: "Modal__content"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('보관함 추가하기')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('생성된 위치 중에 <b>보관함을 등록할 위치를 선택</b>하고, 그 위치에 <b>보관함을 등록</b>할 수 있습니다.<br>보관함의 <b>관리자, 경고표지</b> 등을 입력하여 관리할 수 있습니다.'))
    }
  }), _vm._m(0), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('시작하기')) + " ")])])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/create-3.png")
    }
  })]);
}]

export { render, staticRenderFns }