<template>
	<div class="tree-node-wrap" :class="{ root: node.parentId == null && type != 'storage' }">
		<div class="tree-node" :class="{ unaccessible: node.accessible == false }" @click.stop="setChecked">
			<div class="info-wrap" :class="{ root: node.parentId == null && type != 'storage' }">
				<div v-if="isSubtree">
					<img v-if="isTreeOpen" class="tree-close" src="../svg/icon-node-close.svg" width="16" height="24" @click.stop="toggleTreeOpen" />
					<img v-else class="tree-open" src="../svg/icon-node-open.svg" width="16" height="24" @click.stop="toggleTreeOpen" />
				</div>
				<div class="data-wrap" v-b-tooltip.hover="node.name">
					<img class="tree-icon" :src="imageSrc" width="20" height="20" style="filter: brightness(0)" />
					<div class="name" v-html="name"></div>
				</div>
			</div>
		</div>
		<div class="checkbox" v-if="node.accessible != false">
			<input type="checkbox" :checked="isChecked" @change="setChecked" />
		</div>
		<div v-if="isTreeOpen" class="node-children">
			<filter-node
				ref="child-node"
				v-for="group in node.children"
				:key="group.id"
				:node="group"
				:type="type === 'storage' ? 'storage' : 'location'"
			></filter-node>
			<filter-node ref="child-node" :node="storage" v-for="storage in node.storages" :key="storage.id" type="storage"></filter-node>
		</div>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import MixinTree from '@/mixins/tree'
export default {
	name: 'FilterNode',
	components: {},
	props: {
		node: {
			type: [Object, null],
			default: null,
		},
		type: {
			type: String,
			default: '',
		},
	},
	mixins: [MixinTree],
	computed: {
		...mapState('tree/globalFilter', ['checkedId', 'keyword']),
		isChecked() {
			if (this.type == 'location') return this.getAllStorages(this.node)
			else return this.checkedId.includes(this.node.id)
		},
		imageSrc() {
			return this.type === 'storage'
				? require('@/assets/svg/storage.svg')
				: this.node.parentId != null
				? require('@/assets/svg/group.svg')
				: require('@/assets/svg/institution-building_y.svg')
		},
	},
	methods: {
		...mapMutations('tree/globalFilter', [
			'setCheckedItem',
			'checkedAllChildren',
			'unCheckedAllChildren',
			'checkedAllChildren',
			'uncheckedAllChildren',
		]),
		setChecked() {
			if (this.type == 'location') {
				if (this.isChecked) this.uncheckedAllChildren(this.node)
				else this.checkedAllChildren(this.node)
			} else this.setCheckedItem(this.node.id)
		},
		getAllStorages(node) {
			return (
				node.storages.filter(s => this.checkedId.includes(s.id)).length == node.storages.length &&
				node.children.filter(l => {
					return this.getAllStorages(l)
				}).length == node.children.length
			)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../scss/_tree-node.scss';
</style>
