<template>
	<div>
		<input class="cursor" type="text" :placeholder="tt('위치를 선택해주세요')" :value="item.name" readonly @click="showModalLocation(index)" />
		<button class="Btn-delete-item btn" @click="removeLocation(index)">
			<img src="@/assets/svg/recycle-bin.svg" />
		</button>
	</div>
</template>

<script>
export default {
	name: 'LocationBox',
	props: {
		item: {
			type: Object,
			default: null,
		},
		list: {
			type: Array,
			default: null,
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			inputText: this.item.name,
			selectItem: {},
		}
	},
	watch: {
		item: {
			deep: true,
			handler(item) {
				this.inputText = item.name
			},
		},
	},
	methods: {
		showModalLocation(index) {
			this.$emit('select', index)
		},
		removeLocation(index) {
			this.$emit('delete', index)
		},
	},
}
</script>
