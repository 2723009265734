var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "badge-wrap"
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "width": "24",
      "height": "24"
    }
  }), _c('div', {
    staticClass: "text",
    "class": {
      invalid: !_vm.isValid
    },
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.text))
    }
  }), _c('div', {
    staticClass: "subtext"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.subtext)) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }