<template>
	<div>
		<button ref="btn-wrapper" class="Btn__select" :disabled="disabled">
			<input
				type="text"
				:placeholder="tt('권한을 지정해주세요')"
				:disabled="disabled"
				readonly
				:value="inputText"
				@mousedown="_inputMouseDown"
				@click="$refs['btn-wrapper'].focus(), checkArr()"
			/>
			<ul class="Form__select-option-list scroll" ref="option-box">
				<li
					class="Form__select-option-item"
					v-for="option in list"
					:key="option.id"
					:value="option.name"
					@click="selectedArr[option.name] == true ? '' : selectOption(option)"
					:class="{ disabled: selectedArr[option.name] == true }"
				>
					{{ option.name }}
				</li>
			</ul>
		</button>
		<button @click="deleteOption(item)" class="Btn-setting-item btn" :disabled="inputText.length == 0">
			<img src="@/assets/svg/Trash.svg" alt="icon" />
		</button>
	</div>
</template>

<script>
export default {
	name: 'PermissionBox',
	props: {
		item: {
			type: Object,
			default: null,
		},
		list: {
			type: Array,
			default: null,
		},
		index: {
			type: Number,
			default: 0,
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			disabled: false,
			inputText: '',
			selectItem: {},
			permissionList: [],
			selectedArr: {},
		}
	},
	watch: {
		item: function () {
			this.inputText = this.item.name
		},
	},
	created() {
		this.selectedArr = {}
		this.inputText = this.item.name
		this.checkArr()
	},
	computed: {},
	methods: {
		_inputMouseDown(e) {
			e.stopPropagation()
			e.preventDefault()
		},
		selectOption(item) {
			this.inputText = item.name
			this.selectItem = {
				index: this.index,
				item: item,
			}
			this.$emit('select', this.selectItem)
			this.$refs['btn-wrapper'].blur()
			this.checkArr()
		},
		deleteOption(item) {
			this.selectItem = {
				index: this.index,
				item: item,
			}
			this.$emit('delete', this.selectItem)
			this.checkArr()
		},
		checkArr() {
			this.selectedArr = {}

			this.selectedList.forEach((x, index) => {
				this.list.forEach(option => {
					if (option.name == x.name) {
						this.selectedArr[x.name] = true
					}
				})
			})
		},
	},
}
</script>

<style lang="scss" scoped>


li {
	&.disabled {
		color: $PLACEHOLDER;
		cursor: not-allowed;
	}
	button {
		width: inherit !important;
		height: inherit !important;
		padding: 0 !important;
		margin-left: 0 !important;
		display: block;
		color: inherit;
		text-align: left;
	}
}
.Btn__select {
	.Form__select-option-list {
		top: unset;
		bottom: 38px;
	}
}
</style>
