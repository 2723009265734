import inventoryAPI from '@/services/api/inventory/gen2'
import _ from 'lodash'
import cookies from 'vue-cookies'
import { inventoryViewOption } from '@/utils/define/ViewOption'
const migrateViewoption = () => {
	let viewOption = JSON.parse(cookies.get('viewOption_inventory')) ?? []
	const isMigrated = cookies.get('viewOption_migrate_23-04-25') ?? false
	if (viewOption.length > 0 && !isMigrated) {
		if (!viewOption.includes('incompanyMngcode')) viewOption.push('incompanyMngcode')
		if (!viewOption.includes('lotno')) viewOption.push('lotno')
		if (!viewOption.includes('msds')) viewOption.push('msds')
		cookies.set('viewOption_inventory', JSON.stringify(viewOption))
	}
	cookies.set('viewOption_migrate_23-04-25', true)
	return viewOption
}
const state = {
	list: [],
	count: 0,
	loadParams: {
		offset: 0,
		length: 50,
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
		keyword: '',
		status: JSON.parse(cookies.get('filter_inventory_status')) || [],
		categoriesId: JSON.parse(cookies.get('filter_inventory_categoriesId')) || [],
		categoriesClassification: [],
		registerMethods: JSON.parse(cookies.get('filter_inventory_registerMethods')) || [],
		brandsId: JSON.parse(cookies.get('filter_inventory_brandsId')) || [],
		storagesId: JSON.parse(cookies.get('filter_inventory_storagesId')) || [],
		assigneesId: JSON.parse(cookies.get('filter_inventory_assigneesId')) || [],
		hasMsds: JSON.parse(cookies.get('filter_inventory_hasMsds')) || [],
		legalsId: JSON.parse(cookies.get('filter_inventory_legalsId')) || [],
		currentVolume: JSON.parse(cookies.get('filter_inventory_currentVolume')) || [],
		threshold: JSON.parse(cookies.get('filter_inventory_threshold')) || [],
		expireDate: JSON.parse(cookies.get('filter_inventory_expireDate')) || [],
		qrSticker: '',
		qrBarcode: '',
		productCode: '',
		tagsId: [],
		isFavorite: false,
		bundle: {},
	},
	filter: null,
	filterCookieName: {
		status: 'filter_inventory_status',
		categoriesId: 'filter_inventory_categoriesId',
		registerMethods: 'filter_inventory_registerMethods',
		storagesId: 'filter_inventory_storagesId',
		brandsId: 'filter_inventory_brandsId',
		assigneesId: 'filter_inventory_assigneesId',
		hasMsds: 'filter_inventory_hasMsds',
		legalsId: 'filter_inventory_legalsId',
		currentVolume: 'filter_inventory_currentVolume',
		threshold: 'filter_inventory_threshold',
		expireDate: 'filter_inventory_expireDate',
	},
	selectedItems: [],
	viewOptionCookieName: 'viewOption_inventory',
	checkedViewOption: migrateViewoption(),
	viewOptionList: inventoryViewOption,
	selectedBundleOption: cookies.get('bundleOption') ?? 'none',
	isScan: false,
	recentPageName: null,
	bundleOptions: [
		{
			value: 'none',
			name: '사용안함',
		},
		{
			value: 'CASNO',
			name: 'CAS No',
		},
		{
			value: 'PRODUCT_CODE',
			name: '제품번호',
		},
		{
			value: 'NAME',
			name: '물품이름',
		},
	],
}

const getters = {
	selectedCategory: state => (state.loadParams.categoriesClassification.length === 0 ? 'ALL' : state.loadParams.categoriesClassification[0]),
	filteredCount: state =>
		state.loadParams.status.length +
		state.loadParams.categoriesId.length +
		state.loadParams.brandsId.length +
		state.loadParams.registerMethods.length +
		state.loadParams.storagesId.length +
		state.loadParams.legalsId.length +
		state.loadParams.assigneesId.length +
		state.loadParams.hasMsds.length +
		state.loadParams.threshold.length +
		state.loadParams.currentVolume.length +
		state.loadParams.expireDate.length,
	filteredList: state => {
		return {
			status: state.loadParams.status,
			categoriesId: state.loadParams.categoriesId,
			brandsId: state.loadParams.brandsId,
			registerMethods: state.loadParams.registerMethods,
			storagesId: state.loadParams.storagesId,
			assigneesId: state.loadParams.assigneesId,
			hasMsds: state.loadParams.hasMsds,
			legalsId: state.loadParams.legalsId,
			currentVolume: state.loadParams.currentVolume,
			threshold: state.loadParams.threshold,
			expireDate: state.loadParams.expireDate,
		}
	},
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
	isEmptySelectedItems: state => state.selectedItems.length === 0,
	selectedItemsId: state => state.selectedItems.map(it => it.id),
	loadParams: state => {
		let loadParams = _.cloneDeep(state.loadParams)
		loadParams.legalsId = loadParams.legalsId.concat(state.loadParams.detailLegalsId ?? [])
		if (loadParams.tagsId?.length > 0) loadParams.keyword = ''
		if (loadParams.bundle?.name) loadParams.bundle.name = encodeURIComponent(loadParams.bundle.name)
		return loadParams
	},
	selectedBundleOptionText: state => {
		return '묶어보기: ' + (state.bundleOptions.find(it => it.value == state.selectedBundleOption)?.name ?? '사용안함')
	},
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			if (state.loadParams[key] !== loadParams[key] && key !== 'offset') {
				state.loadParams.offset = 0
			}
			state.loadParams[key] = loadParams[key]
		})
	},
	setSelectedItems: (state, data) => {
		state.selectedItems = data
	},
	setViewOptions: (state, list) => {
		cookies.set(state.viewOptionCookieName, JSON.stringify(list))
		state.checkedViewOption = list
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	initFilterCookies: (state, needReset) => {
		if (needReset) {
			Object.values(state.filterCookieName).forEach(key => {
				cookies.set(key, JSON.stringify([]))
			})
			cookies.set('bundleOption', 'none')
		}
		state.loadParams = {
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			keyword: '',
			status: JSON.parse(cookies.get(`filter_inventory_status`)) || [],
			categoriesId: JSON.parse(cookies.get(`filter_inventory_categoriesId`)) || [],
			categoriesClassification: [],
			registerMethods: JSON.parse(cookies.get(`filter_inventory_registerMethods`)) || [],
			brandsId: JSON.parse(cookies.get(`filter_inventory_brandsId`)) || [],
			storagesId: JSON.parse(cookies.get(`filter_inventory_storagesId`)) || [],
			assigneesId: JSON.parse(cookies.get('filter_inventory_assigneesId')) || [],
			hasMsds: JSON.parse(cookies.get('filter_inventory_hasMsds')) || [],
			legalsId: JSON.parse(cookies.get(`filter_inventory_legalsId`)) || [],
			currentVolume: JSON.parse(cookies.get(`filter_inventory_currentVolume`)) || [],
			threshold: JSON.parse(cookies.get(`filter_inventory_threshold`)) || [],
			expireDate: JSON.parse(cookies.get(`filter_inventory_expireDate`)) || [],
			qrSticker: '',
			qrBarcode: '',
			productCode: '',
			groupsId: [],
			tagsId: [],
			isFavorite: false,
			bundle: {},
		}
		state.isScan = false
		state.selectedBundleOption = 'none'
	},
	setIsScan: (state, bool) => {
		state.isScan = bool
	},
	setPageName: (state, name) => {
		state.recentPageName = name
	},
	setBundleOption: (state, options) => {
		state.selectedBundleOption = options
		cookies.set('bundleOption', options)
	},
}

const actions = {
	getList: async ({ state, getters, commit, rootGetters }) => {
		// let loadParams = _.cloneDeep(state.loadParams)
		// if (loadParams.order[0] == 'VOLUME2') loadParams.order[0] = 'VOLUME'
		console.log(getters.loadParams)
		const response = await inventoryAPI.list(rootGetters.instituteId, getters.loadParams)
		commit('setList', response.data)
	},
	getFilter: async ({ commit, rootGetters, getters }) => {
		const response = await inventoryAPI.filter(rootGetters.instituteId, {
			categoriesClassification: getters.loadParams.categoriesClassification,
		})
		commit('setFilter', response.data)
	},
	getTagList: async ({ getters, rootGetters }, isAll = false) => {
		let keyword = getters.loadParams.keyword
		if (keyword.length > 0 && keyword[0] == '#') {
			keyword = keyword.slice(1)
		}
		const response = await inventoryAPI.tagList(rootGetters.instituteId, {
			keyword: keyword,
			offset: 0,
			length: 1000,
		})
		return response
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		return dispatch('getList')
	},
	useItem: async ({ rootGetters, dispatch }, itemIds) => {
		await inventoryAPI.useItem(rootGetters.instituteId, { itemsId: itemIds })
		dispatch('getList')
	},
	returnItem: async ({ rootGetters, dispatch }, itemIds) => {
		await inventoryAPI.returnItem(rootGetters.instituteId, { itemsId: itemIds })
		dispatch('getList')
	},
	changeItemStorage: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.changeStorage(rootGetters.instituteId, body)
		dispatch('getList')
	},
	disposeItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.disposeItem(rootGetters.instituteId, body)
		dispatch('getList')
	},
	removeItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.removeItem(rootGetters.instituteId, body)
		dispatch('getList')
	},
	getAllList: async ({ state, getters, commit, rootGetters }) => {
		const params = { ...getters.loadParams, offset: 0, length: state.count }
		const response = await inventoryAPI.list(rootGetters.instituteId, params)
		return response.data
	},
	exportExcel: async ({ getters, rootGetters }, type) => {
		const response = await inventoryAPI.exportExcelList(rootGetters.instituteId, type == 'ALL' ? null : getters.loadParams)
		return response
	},
	getQrSearchList: async ({ state, commit, rootGetters }, params) => {
		const response = await inventoryAPI.list(rootGetters.instituteId, params)
		if (response.data.count > 0) {
			commit('initFilterCookies', true)
			commit('setIsScan', true)
			commit('setLoadParams', { qrBarcode: params.qrBarcode })
			commit('setList', response.data)
		}
		return response.data
	},
	addFavorite: async ({ rootGetters }, id) => {
		await inventoryAPI.addFavorite(rootGetters.instituteId, { inventoryId: id })
	},
	deleteFavorite: async ({ rootGetters }, id) => {
		await inventoryAPI.deleteFavorite(rootGetters.instituteId, { inventoryId: id })
	},
	updateMultiple: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.editItemMultiple(rootGetters.instituteId, body)
		await dispatch('getList')
	},
	setBundleOption: async ({ dispatch, commit }, options) => {
		commit('setBundleOption', options)
		await dispatch('setLoadParams', { offset: 0, bundle: {} })
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
