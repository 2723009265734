var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('modal-footer', {
          attrs: {
            "completeText": "다음",
            "cancelText": "취소"
          },
          on: {
            "cancel": _vm.cancel,
            "complete": _vm.goNext
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('item-category-selector', {
    ref: "category-selector",
    attrs: {
      "title": "카테고리 선택"
    }
  }), _vm.category ? _c('regist-method-selector', {
    attrs: {
      "isGas": _vm.isGas,
      "isChemical": _vm.isChemical,
      "isValid": !!_vm.registMethod,
      "value": _vm.registMethod,
      "isShowError": _vm.showError
    },
    on: {
      "change": function change(type) {
        return _vm.setRegistMethod(type);
      }
    }
  }) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }