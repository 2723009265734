<template>
	<tr>
		<td @click="detail({ id: 'ALL', name: item.name })">
			<b>{{ item.name }}</b>
		</td>
		<td v-for="legalCount in item.legalByItemsCount" :key="legalCount.id" @click="detail(legalCount)">
			<p>{{ legalCount.kindCount }}{{ tt('종') }} ({{ legalCount.count }}{{ tt('개') }})</p>
		</td>
	</tr>
</template>

<script>
import item from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'

export default {
	name: 'LegalItem',
	props: {
		item: {
			type: Object,
		},
	},
	methods: {
		detail(legal) {
			this.$router.push({
				name: PageName.Safety.LegalProductDetail,
				params: {
					legalName: legal.name,
				},
				query: {
					classification: 'CAT_CHEMICAL',
					legalsId: legal.id == 'ALL' ? this.item.legalByItemsCount.map(it => it.id) : [legal.id],
					groupsId: [this.item.id],
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/safety/_safety.scss';

td {
	b {
		&:hover {
			text-decoration: underline;
		}
	}
	p {
		&:hover {
			text-decoration: underline;
		}
	}
}
</style>
