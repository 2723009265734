<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddAuthorityMemberPopup Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt(title) }}
					</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<search-box :placeholder="tt(searchBoxPlaceholder)" @inputValue="getInputGroupName" @search="searchTree"></search-box>
					<div class="Organization__member-list">
						<ul class="horizational-list">
							<TreeView
								ref="modal-tree-view"
								key="modal-tree-view"
								:treeData="organizationData"
								textField="name"
								subTextField="membersCount"
								:subTextEndWord="tt('명')"
								:draggable="false"
								:mode="mode"
								modal="group"
								:selectWithChild="false"
								:keyword="keyword"
								:limitSelectable="limitSelectable"
								@changeActive="getActiveId"
								@load="loadedTree"
							></TreeView>
						</ul>
					</div>
				</div>
				<div class="Popup__bottom">
					<button class="Btn__management" @click="goOrganization" v-if="!isStart">
						{{ tt('조직도 관리') }}
					</button>
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="complete">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import SearchBox from '@/views/common/components/SearchBox.vue'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import instituteGroupAPI from '@/services/api/institute/group'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ModalAddGroupAuth',
	components: { SearchBox, TreeView },
	mixins: [MixinModal],
	props: {
		groupInfo: {
			type: Object,
		},
		isStart: {
			type: Boolean,
			default: false,
		},
		limitSelectable: {
			type: Number,
		},
		mode: {
			type: String,
			default: 'selectable',
		},
	},
	data() {
		return {
			title: '',
			memberList: [],
			originGroupList: [],
			selectedGroupList: [],
			selectedGroupNameList: [],
			searchBoxPlaceholder: '그룹 이름으로 검색해주세요',
			organizationData: [],
			inputGroupName: '',
			selectedIndex: 0,
			groupList: [],
			selectedData: [],
		}
	},
	watch: {
		groupInfo: {
			deep: true,
			handler() {
				this.originGroupList = this.groupInfo.access.groups.map(item => item.id)
			},
		},
		isShow(value) {
			if (value) {
			}
		},
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('organization', ['groupTreeData']),
		...mapGetters('organization', ['selectedGroupInfo']),
	},
	methods: {
		...mapActions('organization', ['loadGroupTree']),
		async show(type, selectedData, data) {
			this.modalType = type
			this.selectedData = selectedData
			this.isShow = true
			this.keyword = ''

			const instId = this.$route.params.instituteId
			const response = await instituteGroupAPI.lookupGroupTree(instId)
			this.organizationData = response.data

			if (type === 'STORAGE') {
				this.title = this.tt('담당 그룹 관리')
				if (selectedData.length > 0) {
					this.$refs['modal-tree-view'].setActiveIdList(selectedData)
				}
				this.mode = 'storageGroup'
			} else if (type === 'GROUP') {
				this.title = this.tt('그룹 지정')
				if (selectedData.length > 0) {
					this.$refs['modal-tree-view'].setActiveIdList(selectedData)
					this.selectedGroupList = data
				}
			} else if (type === 'LOCATION') {
				this.title = this.tt('사용 그룹 관리')
				if (selectedData) {
					this.$refs['modal-tree-view'].setActiveIdList(selectedData)
				}
			} else {
				this.title = this.tt('권한 추가: 그룹')
				if (selectedData) {
					this.originGroupList = [...this.selectedGroupList]
				}
			}
		},
		complete() {
			if (this.modalType === 'STORAGE') {
				this.completeSelectGroups()
			} else if (this.modalType === 'GROUP') {
				this.hide()
				const payload = {
					groupList: this.groupList,
					index: this.selectedIndex,
					data: this.selectedGroupList,
				}
				this.$emit('select', payload)
			} else if (this.modalType == 'LOCATION') {
				this.$emit('refresh', this.selectedGroupList)
				this.$emit('completeLocation', this.selectedGroupList)
			} else {
				this.completeAddAuthority()
			}
		},
		completeSelectGroups() {
			console.warn(this.selectedGroupList)
			this.$emit('selectGroups', this.selectedGroupList)
			this.hide()
		},
		async completeAddAuthority() {
			const { addedIdList, removedIdList } = this.getChangedList()

			// 그룹 권한 추가
			if (addedIdList.length > 0) {
				const addData = {
					groupsId: addedIdList,
					permission: {
						read: true,
						update: false,
					},
				}
				await instituteGroupAPI.addAccessAuthority(this.groupInfo.id, addData)
			}

			// 그룹 권한 삭제
			const groupsIds = this.groupInfo.access.groups.map(item => item.id)
			const groupsAccessIds = this.groupInfo.access.groups.filter(item => removedIdList.indexOf(item.id) >= 0).map(item => item.accessId)

			for (let i = 0, length = groupsAccessIds.length; i < length; i++) {
				await instituteGroupAPI.deleteAccessAuthority(this.groupInfo.id, groupsAccessIds[i])
			}
			this.$emit('refresh')
			this.hide()
			this.showCompleteToast()
			this.$emit('completeOrganization')
		},
		loadedTree() {
			let groups

			if (this.groupInfo != null) {
				groups = this.groupInfo.access.groups.map(group => group.id)
			} else {
				groups = this.selectedData
			}
			const groupsAccessIds = []
			for (let i = 0, length = groups.length; i < length; i++) {
				if (groupsAccessIds.indexOf(groups[i]) < 0) groupsAccessIds.push(groups[i])
			}
			this.$refs['modal-tree-view'].setActiveIdList(groupsAccessIds)
		},
		searchTree() {
			this.keyword = this.inputGroupName
		},
		getInputGroupName(groupName) {
			this.inputGroupName = groupName
		},
		getActiveId(idList, dataList) {
			const isModeGroup = this.mode === 'storageGroup'
			if (isModeGroup) {
				const dataListIsNotEmpty = ![null, undefined].includes(dataList)
				// dataList는 여기서는 Object임
				this.selectedGroupList = dataListIsNotEmpty ? [dataList] : []
			} else {
				if (dataList && dataList[0] != undefined) {
					this.selectedGroupList = dataList
				} else {
					this.selectedGroupList = []
				}
			}
		},
		getActiveName(nameList) {
			this.selectedGroupNameList = nameList
		},
		showCompleteToast() {
			const title = this.tt('접근 권한 추가완료')
			const content = this.tt('접근 권한이 추가되었습니다')
			const type = 'success'
			this.$root.toast(title, content, type)
		},
		setActiveIdList(groupList) {
			this.selectedGroupList = [...groupList]
		},
		getChangedList() {
			const changedList = this.$refs['modal-tree-view'].getChangedList()
			return changedList
		},
		goOrganization() {
			this.$router.push({
				name: PageName.Institution.Organization,
			})
		},
	},
}
</script>

<style lang="scss" scoped>

@import '@/styles/scss/institution/_organization.scss';

.Organization__member-list {
	height: 500px;
}
.AddAuthorityMemberPopup {
	.horizational-list {
		height: 500px;
		width: 100%;
		padding-top: 0;
		margin-top: 20px;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		overflow-y: auto;
		li {
			padding: 12px;
			border-bottom: 0;
			transition: 0.3s all linear;
			&:hover {
				background-color: $GREY_1;
			}
			button {
				img {
					width: 20px;
					background-image: '@/assets/svg/Select-Circle.svg';
				}
			}
			> div {
				h6 {
					> img {
						width: 12px;
						margin-left: 6px;
					}
				}
			}
		}
	}
}
</style>
