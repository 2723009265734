var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-input-wrapper"
  }, [_c('input', {
    ref: "input",
    staticClass: "base-input",
    "class": {
      error: _vm.error
    },
    style: _vm.inputStyle,
    attrs: {
      "type": _vm.type,
      "placeholder": _vm.placeholder,
      "maxlength": _vm.maxlength,
      "min": _vm.min,
      "max": _vm.max,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event.target.value);
      },
      "keypress": function keypress($event) {
        return _vm.$emit('keypress', $event);
      },
      "keydown": function keydown($event) {
        return _vm.$emit('keydown', $event);
      },
      "keyup": function keyup($event) {
        return _vm.$emit('keyup', $event);
      },
      "click": function click($event) {
        return _vm.$emit('click', $event);
      },
      "focus": function focus($event) {
        return _vm.$emit('focus', $event);
      },
      "blur": function blur($event) {
        return _vm.$emit('blur', $event);
      }
    }
  }), _vm.isError ? _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-login-error.svg")
    }
  }), _c('span', {
    staticClass: "error-msg"
  }, [_vm._v(_vm._s(_vm.errorMessage))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }