var render = function () {
  var _vm$noneGroupbyDataIt, _vm$noneGroupbyDataIt2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "spinner-variant": "primary",
      "rounded": "sm"
    }
  }, [_c('div', {
    ref: "scroll-container",
    staticClass: "bundle-list-wrapper",
    on: {
      "scroll": _vm.onScroll
    }
  }, [_c('div', {
    staticClass: "search-box"
  }, [_c('img', {
    staticClass: "btn-search",
    attrs: {
      "src": require("@/views/components/searchBox/assets/ic-search.svg")
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.keyword
    },
    on: {
      "change": _vm.changeKeyword
    }
  })]), _c('div', {
    staticClass: "list-wrapper"
  }, [_c('div', {
    staticClass: "item-wrapper"
  }, [_c('div', {
    staticClass: "item",
    "class": {
      active: _vm.selectedItem == null
    },
    on: {
      "click": function click($event) {
        return _vm.select(null);
      }
    }
  }, [_c('div', {
    staticStyle: {
      "width": "calc(100% - 20px)"
    }
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v("전체보기")])]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow/icon-arrow-right.svg")
    }
  })])]), _vm._l(_vm.list, function (item, i) {
    var _item$itemCount$toLoc, _item$itemCount;

    return _c('div', {
      key: 'bundleKey_' + i,
      staticClass: "item-wrapper"
    }, [_c('div', {
      staticClass: "item",
      "class": {
        active: _vm.isActive(item, i)
      },
      on: {
        "click": function click($event) {
          return _vm.select(item, i);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "width": "calc(100% - 20px)"
      }
    }, [_c('div', {
      staticClass: "name",
      domProps: {
        "innerHTML": _vm._s(_vm.getName(item))
      }
    }), _c('div', {
      staticClass: "subname"
    }, [item.volumeUnit ? _c('span', [_vm._v(_vm._s(_vm.floor(item.normalizeCurrentVolume).toLocaleString() + item.volumeUnit))]) : _vm._e(), _c('span', [_vm._v(_vm._s(((_item$itemCount$toLoc = (_item$itemCount = item.itemCount) === null || _item$itemCount === void 0 ? void 0 : _item$itemCount.toLocaleString()) !== null && _item$itemCount$toLoc !== void 0 ? _item$itemCount$toLoc : 0) + _vm.tt('개')))])])]), _c('img', {
      attrs: {
        "src": require("@/assets/svg/arrow/icon-arrow-right.svg")
      }
    })])]);
  }), _vm.noneGroupbyDataItemCount > 0 ? _c('div', {
    staticClass: "item-wrapper"
  }, [_c('div', {
    staticClass: "item",
    "class": {
      active: _vm.selectedItem == 'EMPTY'
    },
    on: {
      "click": _vm.selectEmpty
    }
  }, [_c('div', {
    staticStyle: {
      "width": "calc(100% - 20px)"
    }
  }, [_c('div', {
    staticClass: "name",
    domProps: {
      "innerHTML": _vm._s(_vm.getName(null))
    }
  }), _c('div', {
    staticClass: "subname"
  }, [_vm._v(" " + _vm._s(((_vm$noneGroupbyDataIt = (_vm$noneGroupbyDataIt2 = _vm.noneGroupbyDataItemCount) === null || _vm$noneGroupbyDataIt2 === void 0 ? void 0 : _vm$noneGroupbyDataIt2.toLocaleString()) !== null && _vm$noneGroupbyDataIt !== void 0 ? _vm$noneGroupbyDataIt : 0) + _vm.tt('개')) + " ")])]), _c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow/icon-arrow-right.svg")
    }
  })])]) : _vm._e()], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }