var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('modal-footer', {
          attrs: {
            "okText": "다음으로",
            "isPrev": ""
          },
          on: {
            "goPrev": function goPrev($event) {
              return _vm.$emit('goPrev');
            },
            "ok": _vm.goNext,
            "cancel": function cancel($event) {
              _vm.$emit('close');

              _vm.hide();
            }
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_vm._v(" " + _vm._s(_vm.tt('공급자 정보')) + " ")]), _c('div', {
    staticClass: "form-data-wrap"
  }, _vm._l(_vm.fields, function (field) {
    return _c(_vm.formType(field), {
      key: "".concat(field.key),
      ref: "form",
      refInFor: true,
      tag: "component",
      attrs: {
        "name": _vm.tt(field.name),
        "value": _vm.inputValue(field),
        "placeholder": _vm.tt(_vm.placeholder(field)),
        "errorMessage": _vm.tt(_vm.placeholder(field)),
        "maxLength": _vm.maxLength(field),
        "required": _vm.isRequired(field)
      },
      on: {
        "input": function input(value) {
          return _vm.handleInput(field, value);
        }
      }
    });
  }), 1)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }