import noticeAPI from '@/services/api/notice'

const state = {
	item: null,
}

const mutations = {
	setNotice: (state, data) => {
		state.item = data
	},
}

const actions = {
	getNotice: async ({ commit }) => {
		const response = await noticeAPI.getList()
		if (response.data.length > 0) commit('setNotice', response.data[0])
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
