var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: !_vm.isValid
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    staticClass: "qr",
    "class": {
      full: _vm.isRegistFull
    },
    attrs: {
      "type": "text",
      "readonly": "",
      "placeholder": _vm.tt('등록 안 됨')
    },
    domProps: {
      "value": _vm.tt(_vm.statusText)
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showQrModal.apply(null, arguments);
      }
    }
  }), _vm.statusText ? _c('div', {
    staticClass: "reset",
    on: {
      "click": _vm.init
    }
  }, [_vm._v(_vm._s(_vm.tt('리셋')))]) : _vm._e(), _c('button', {
    staticClass: "qr-button",
    on: {
      "click": _vm.showQrModal
    }
  }, [_vm._v(_vm._s(_vm.tt('스캔')))])]), _c('modal-scan-qr-sticker', {
    ref: "modal-scan-qr",
    attrs: {
      "maxSize": _vm.maxSize
    },
    on: {
      "save": _vm.handleSave
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }