var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "management-wrap"
  }, [_c('div', {
    staticClass: "title-wrap"
  }, [_c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('관리번호')) + ": " + _vm._s(_vm.mainText))]), _c('p', [_vm._v(_vm._s(_vm.subText))])]), _c('image-button', {
    attrs: {
      "image": require('@/views/inventoryGen2/svg/Guide.svg'),
      "text": '관리번호 가이드'
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-management-number-guide'].show();
      }
    }
  }), _vm.activeClassList && _vm.activeClassList.length < 5 ? _c('image-button', {
    attrs: {
      "image": require('@/views/inventoryGen2/svg/Add.svg'),
      "text": '관리항목 추가'
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-add-management-number'].show();
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "content-wrap"
  }, [_c('ul', _vm._l(_vm.activeClassList, function (item, index) {
    return _c('li', {
      key: "code".concat(index)
    }, [_vm._v(" " + _vm._s(_vm.tt('${1}번째', index + 1)) + " ")]);
  }), 0), _c('draggable', _vm._b({
    attrs: {
      "draggable": ".item-wrap"
    },
    on: {
      "start": _vm.onDragStart,
      "end": _vm.onDragEnd
    },
    model: {
      value: _vm.activeClassList,
      callback: function callback($$v) {
        _vm.activeClassList = $$v;
      },
      expression: "activeClassList"
    }
  }, 'draggable', _vm.dragOptions, false), [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": "flip-list"
    }
  }, _vm._l(_vm.activeClassList, function (item, index) {
    return _c('classification-item', {
      key: "code".concat(index),
      attrs: {
        "item": item,
        "index": index
      },
      on: {
        "changeManagement": function changeManagement($event) {
          return _vm.$refs['modal-add-management-number'].show(index);
        },
        "deleteManagement": _vm.deleteModalManagementNumber
      }
    });
  }), 1)], 1)], 1), _c('modal-management-number-guide', {
    ref: "modal-management-number-guide"
  }), _c('modal-add-management-number', {
    ref: "modal-add-management-number"
  }), _c('modal-confirm', {
    ref: "modal-delete-management-number",
    attrs: {
      "title": "관리번호에서 제외하기",
      "confirmText": "제외하기",
      "content": "\uAD00\uB9AC\uBC88\uD638\uC5D0\uC11C \uD574\uB2F9 \uAD00\uB9AC\uD56D\uBAA9\uC744 \uC81C\uC678\uD569\uB2C8\uB2E4.</br>\uC5B8\uC81C\uB4E0\uC9C0 \uAD00\uB9AC\uD56D\uBAA9\uC744 \uB2E4\uC2DC \uCD94\uAC00\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4."
    }
  }), _c('modal-confirm', {
    ref: "modal-delete-close",
    attrs: {
      "title": "관리번호에서 제외 불가",
      "noCancel": false,
      "variant": "white",
      "confirmText": "확인",
      "content": "\uBCF4\uAD00\uD568 \uB4F1\uB85D\uC21C\uC11C \uC120\uD0DD \uC2DC \uBCF4\uAD00\uD568\uC740 \uD544\uC218 \uAD00\uB9AC\uD56D\uBAA9\uC785\uB2C8\uB2E4."
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }