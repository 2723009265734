import vvuex from '@/lib/vvuex'
import instituteGroupAPI from '@/services/api/institute/group'
const Organization = vvuex({
	namespaced: true,
	state: {
		institutionName: '스마트잭',
		institutionMemberCount: -1,
		groupTreeData: [
			{
				id: null,
				parentId: null,
				name: '스마트잭',
				phone: '123-4444-4444',
				members: [],
				memberCount: 0,
				children: [],
			},
		],
		selectedGroup: undefined,
		selectedGroupInfo: undefined,
		groupTreeList: undefined,
		globalTree: [],
	},
	getters: {
		institutionName: state => state.institutionName,
		institutionMemberCount: state => state.institutionMemberCount,
		groupTreeData: state => state.groupTreeData,
		groupTreeList: state => state.groupTreeList,
		selectedGroup: state => state.selectedGroup,
		selectedGroupInfo: state => state.selectedGroupInfo,
		globalTree: state => state.globalTree,
	},
	mutations: {
		setGroupTreeData: (state, payload) => {
			state.groupTreeData = payload
		},
		setGroupTreeList: (state, payload) => {
			state.groupTreeList = payload
		},
		setSelectedGroup: (state, payload) => {
			state.selectedGroup = payload
		},
		setInstitutionMemberCount: (state, payload) => {
			state.institutionMemberCount = payload
		},
		setSelectedGroupInfo: (state, payload) => {
			state.selectedGroupInfo = payload
		},
		setGlobalGroup: (state, payload) => {
			state.globalTree = payload
		},
	},
	actions: {
		loadGroupTree: async ({ commit }, instId) => {
			const response = await instituteGroupAPI.lookupGroupTree(instId)
			commit('setGroupTreeData', response.data)
		},
		loadSelectedGroupInfo: ({ commit }, groupId) => {
			return new Promise(async (resolve, reject) => {
				const response = await instituteGroupAPI.lookupGroupInfo(groupId)
				commit('setSelectedGroupInfo', response.data)
				resolve()
			})
		},
		loadGroupList: async ({ commit }, instId) => {
			const response = await instituteGroupAPI.lookupGroupList(instId)
			commit('setGroupTreeList', response.data)
		},
		lookupGlobalGroup: async ({ commit }, payload) => {
			await instituteGroupAPI.lookupGlobalGroup(payload)
		},
	},
})

export default Organization
