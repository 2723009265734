<template>
	<div class="no-data-list" ref="no-data-inventory">
		<img :class="{ list: image == 'list' }" :src="image.length > 0 ? require('@/assets/svg/no-data_' + image + '.svg') : ''" />
		<h6 v-html="tt(title)"></h6>
		<p v-html="tt(content)"></p>
	</div>
</template>

<script>
export default {
	props: {
		image: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
.no-data-list {
	position: absolute;
	width: 100%;
	height: calc(100% - 80px);
	top: calc(50%);
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	img {
		width: 60px;
		margin: 0 auto;
		&.list {
			width: 206px;
		}
	}
	h6 {
		font-size: 1rem;
		font-weight: 700;
		color: $PLACEHOLDER;
		margin: 24px 0 6px;
	}
	p {
		font-size: 0.875rem;
		font-weight: 400;
		color: $PLACEHOLDER;
		b {
			display: inline-block;
			font-weight: bold;
		}
	}
}
</style>
