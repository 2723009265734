<template>
	<div class="AppContainer__tabs">
		<div class="tab-inner">
			<span v-for="(item, index) in tabList" :key="index">
				<input type="radio" :id="'tab' + index + 1" name="TabList" :value="item.id == null ? index + 1 : item.id" v-model="selectTab" />
				<label :for="'tab' + index + 1">
					{{ tt(item.name) }}
					<span class="count">{{ item.count }}</span>
				</label>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Tab',
	props: {
		tabList: {
			type: Array,
			default: [{ name: '' }],
		},
		tab: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			selectTab: 1,
		}
	},
	created() {
		this.selectTab = this.tab
	},
	watch: {
		tab: function () {
			this.selectTab = this.tab
		},
		selectTab: function () {
			this.$emit('selectTab', this.selectTab)
		},
	},
}
</script>
