var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-select-sticker', {
    ref: "form-select-sticker",
    attrs: {
      "category": _vm.category,
      "item": _vm.item,
      "isGhs": _vm.isGhs,
      "isRequireGhs": _vm.isRequireGhs
    },
    on: {
      "change": _vm.setStickerType,
      "completeGHS": _vm.completeGHS,
      "cancelGHSQR": function cancelGHSQR($event) {
        return _vm.setStickerType(null);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }