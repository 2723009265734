var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "toggle-list"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isUpdating,
      "spinner-variant": "primary",
      "rounded": "sm"
    }
  }, _vm._l(_vm.fields, function (field, index) {
    return _c('li', {
      key: field.key
    }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt(field.name)) + " "), field.isRequire ? _c('span', {
      staticClass: "required"
    }, [_vm._v("*")]) : _vm._e(), field.key === 'expireDatetime' && field.isActive === true ? _c('span', {
      staticClass: "expireDay"
    }, [_vm._v(" " + _vm._s(_vm.tt('(${1}일)', _vm.category.expirationDay)) + " ")]) : _vm._e(), _vm.type === 'custom' ? _c('span', {
      staticClass: "txt"
    }, [_vm._v(" " + _vm._s(_vm.tt(_vm.switchText(field.varType))) + " ")]) : _vm._e()]), field.key === 'expireDatetime' && field.isActive === true ? _c('p', {
      staticClass: "changeBtn",
      on: {
        "click": _vm.showChangeExpireDatetimeModal
      }
    }, [_vm._v(" " + _vm._s(_vm.tt('변경')) + " ")]) : _vm._e(), _c('div', {
      staticClass: "switch-wrap"
    }, [_c('label', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: _vm.tooltipText(field.isRequire),
        expression: "tooltipText(field.isRequire)",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "switch",
      on: {
        "click": function click($event) {
          return _vm.clickToggle(field.isRequire);
        }
      }
    }, [_c('input', {
      attrs: {
        "type": "checkbox",
        "readonly": _vm.isUpdating,
        "disabled": field.isRequire || !_vm.isUpdate
      },
      domProps: {
        "checked": field.isActive
      },
      on: {
        "input": function input($event) {
          return _vm.updateItem($event, index);
        }
      }
    }), _c('span', {
      staticClass: "slider round"
    })]), _vm.type === 'custom' ? _c('button', {
      staticClass: "btn Dropdown__btn"
    }, [_c('img', {
      attrs: {
        "src": require("@/views/inventoryGen2/svg/Setting.svg")
      }
    }), _c('div', {
      staticClass: "Dropdown__menu"
    }, [_c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: !_vm.isUpdate ? _vm.tt('권한이 없습니다.') : '',
        expression: "!isUpdate ? tt('권한이 없습니다.') : ''",
        modifiers: {
          "hover": true
        }
      }]
    }, [_c('button', {
      attrs: {
        "disabled": !_vm.isUpdate
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('editField', index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.tt('편집')) + " ")])]), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: !_vm.isDelete ? _vm.tt('권한이 없습니다.') : '',
        expression: "!isDelete ? tt('권한이 없습니다.') : ''",
        modifiers: {
          "hover": true
        }
      }]
    }, [_c('button', {
      attrs: {
        "disabled": !_vm.isDelete
      },
      on: {
        "click": function click($event) {
          return _vm.deleteCategoryField(index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.tt('삭제')) + " ")])])])]) : _vm._e()])]);
  }), 0), _c('modal-confirm', {
    ref: "modal-change-expire-datetime",
    attrs: {
      "title": "유효기간 변경",
      "confirmText": "변경",
      "noContent": false
    },
    on: {
      "confirm": _vm.changeExpireDatetime
    }
  }, [_c('form-text-single-line', {
    attrs: {
      "name": '유효기간(일)',
      "value": _vm.expireDatetime,
      "placeholder": '유효기간을 입력해 주세요',
      "required": true
    },
    on: {
      "input": function input(value) {
        return _vm.inputFormValue(value);
      }
    }
  })], 1), _c('modal-confirm', {
    ref: "modal-delete-field",
    attrs: {
      "title": "항목 삭제",
      "confirmText": "삭제",
      "variant": "warning",
      "content": "\uC815\uB9D0\uB85C \uD56D\uBAA9\uC744 \uC0AD\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?</br>\uAE30\uC874 \uBB3C\uD488 \uC815\uBCF4\uC5D0\uC11C \uD56D\uBAA9\uC774 \uC0AD\uC81C\uB429\uB2C8\uB2E4."
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }