var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.tt('라이선스 관리')) + " ")];
      },
      proxy: true
    }])
  }, [[_c('h5', [_vm._v(_vm._s(_vm.tt('이용중인 라이선스')))]), _c('div', {
    staticClass: "license-summary"
  }, [_c('img', {
    staticClass: "imgbox",
    attrs: {
      "src": require("@/assets/images/lm-pro-icon.png")
    }
  }), _c('div', {
    staticClass: "txtbox"
  }, [_c('h6', [_vm._v(_vm._s(_vm.licenseTypeText))]), _vm.isFreeTier ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('30일 무료')) + " ")]) : _vm.isTrial ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('무료')) + " ")]) : _vm.isEducation ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('${1}원 / 연구실당 (연 기준 ・ VAT 별도)', _vm.formatNumberLocale(_vm.licenseRatePlan['YEAR']['EDUCATION']))) + " ")]) : _c('p', [_vm._v(" " + _vm._s(_vm.tt('${1}원 / 라이선스 당(월 기준 ・ VAT 별도)', _vm.formatNumberLocale(_vm.getAdditonalPrice))) + " ")])])]), _c('div', {
    staticClass: "license-info"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('현재 라이선스')))]), _c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('상태')))]), _c('div', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "license-status",
    "class": {
      expired: !_vm.isLicenseActivate
    }
  }, [_vm._v(" " + _vm._s(_vm.licenseStatusText) + " ")]), _vm.isLicenseDepositWait ? _c('span', {
    staticClass: "waiting-status"
  }, [_vm._v(" " + _vm._s(_vm.tt('입금대기')) + " ")]) : _vm._e()])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('라이선스 만료일')))]), _c('span', [_c('span', {
    "class": {
      red: !_vm.isLicenseActivate,
      bold: !_vm.isLicenseActivate
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDatetime(_vm.license.expireDate)) + " ")]), _vm.isLicenseActivate && _vm.isPayLicense && !_vm.beingSubscription && !_vm.isLicenseDepositWait ? _c('span', {
    staticClass: "blue add",
    on: {
      "click": function click($event) {
        return _vm.$emit('addDate');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('추가')) + " ")]) : _vm._e()])]), _vm.isFreeTier ? _c('li', [_c('p', [_vm._v(" " + _vm._s(_vm.tt('멤버 수')) + " ")]), _c('span', {
    staticClass: "license-member"
  }, [_vm._v(" " + _vm._s(_vm.formatNumberLocale(_vm.memberCount)) + " ")])]) : _c('li', [_c('p', [_vm._v(" " + _vm._s(_vm.tt('활성화된 멤버 / 라이선스 수')) + " ")]), _c('span', {
    staticClass: "licese-member"
  }, [_c('span', {
    staticClass: "bold",
    "class": {
      red: _vm.isMemberFull
    }
  }, [_vm._v(" " + _vm._s(_vm.formatNumberLocale(_vm.memberCount)) + " ")]), _c('span', {
    staticClass: "license-count"
  }, [_vm._v(" / " + _vm._s(_vm.license.quantity == _vm.MAX_QUANTITY ? _vm.tt('무제한') : _vm.formatNumberLocale(_vm.license.quantity)) + " ")]), _vm.isLicenseActivate && !_vm.isEducation && !_vm.isLicenseDepositWait ? _c('span', {
    staticClass: "blue add",
    on: {
      "click": function click($event) {
        return _vm.$emit('addMember');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('추가')) + " ")]) : _vm._e()])])])]), _vm.isLicenseDepositWait ? _c('DepositInformation', {
    on: {
      "hide": _vm.hide
    }
  }) : !_vm.isLicenseActivate || _vm.beingSubscription || _vm.isFreeTier ? _c('div', {
    staticClass: "modal-footer"
  }, [!_vm.isLicenseActivate || _vm.isFreeTier ? _c('button', {
    staticClass: "button-primary",
    on: {
      "click": _vm.buyLicense
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('라이선스 구매')) + " ")]) : _vm.isLicenseActivate && _vm.beingSubscription ? _c('LicenseManageFold', {
    on: {
      "manageSubscribe": function manageSubscribe($event) {
        return _vm.$emit('manageSubscribe');
      }
    }
  }) : _vm._e()], 1) : _c('div')]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }