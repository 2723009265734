var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.useSidebarOrHeader || _vm.$root.user.language ? _c('div', {
    attrs: {
      "id": "app"
    },
    on: {
      "click": _vm.appClick
    }
  }, [_c('main', {
    staticClass: "App__inner-container"
  }, [_vm.useSidebarOrHeader ? _c('app-side-menu', {
    ref: "menu",
    attrs: {
      "userPermission": _vm.userPermission,
      "license": _vm.license,
      "summaryInfo": _vm.summary,
      "info": _vm.info
    },
    on: {
      "showLicense": _vm.showNeedProLicense
    }
  }) : _vm._e(), _vm.useSidebarOrHeader ? _c('app-header', {
    "class": {
      'right-menu-on': _vm.$root.rightMenuOn,
      'notice-on': _vm.$root.isNoticeOn
    },
    attrs: {
      "userInfo": _vm.user,
      "instInfo": _vm.institute
    }
  }) : _vm._e(), _c('section', {
    "class": {
      'App__router-container': _vm.hasInstitute,
      scroll: !_vm.hasInstitute,
      'App__router-container-no-sidebar': !_vm.hasInstitute
    }
  }, [_vm.$root.userPermission ? _c('router-view', {
    key: _vm.$route.name,
    attrs: {
      "userPermission": _vm.userPermission,
      "license": _vm.license,
      "isExp": _vm.$route.params.isExp,
      "state": _vm.eolState
    },
    on: {
      "showLicense": _vm.showNeedProLicense,
      "loadPermission": _vm.loadUserPermission,
      "loadSummary": _vm.loadSummary,
      "changeInfo": _vm.changeInfo,
      "openEmailCertificationModal": _vm.openEmailCertificationModal,
      "openJoinSuccessModal": _vm.openJoinSuccessModal,
      "appInitFlow": _vm.appInitFlow
    }
  }) : _vm._e()], 1)], 1), _c('lm-toast-list'), _c('portal-target', {
    attrs: {
      "name": "lm-modal",
      "multiple": ""
    }
  }), _c('modal-notice-license', {
    ref: "modal-notice-license"
  }), _vm.eolState && _vm.eolState.status === 'DONE' && _vm.eolState.isFirstVisit ? _c('modal-complete-transfer') : _vm._e(), _c('modal-email-authentication', {
    ref: "modal-email-authentication",
    on: {
      "authentication": _vm.onClickAuthentication
    }
  }), _c('modal-success-join', {
    ref: "modal-success-join",
    on: {
      "start": _vm.onClickModalSuccessJoin
    }
  }), _c('ModalHowToAddLicense', {
    ref: "ModalHowToAddLicense",
    on: {
      "refreshData": _vm.refreshLicense,
      "purchaseLicense": function purchaseLicense($event) {
        return _vm.$root.purchaseLicense();
      }
    }
  }), _c('ModalLicense', {
    ref: "ModalPayment"
  }), _c('ModalConfirm', {
    ref: "modal-license-add",
    attrs: {
      "title": "라이선스 추가구매 필요",
      "content": "라이선스 수가 부족하여, 멤버 추가가 불가합니다.<br />라이선스를 추가 구매하면, 멤버를 추가할 수 있습니다. ",
      "confirmText": "라이선스 구매하기"
    },
    on: {
      "confirm": function confirm($event) {
        return _vm.$refs['ModalPayment'].showModalPayment('add');
      }
    }
  }), _c('Nudge', {
    ref: "nudge"
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }