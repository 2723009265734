<template>
	<nav class="AppSideMenu__container">
		<!--global filter-->
		<div class="logo" @click="goDashboard" v-if="institute">
			<div class="imgbox" :class="{ noLogo: !institute.logo }">
				<img v-if="institute.logo" :src="institute.logo + `?date=${new Date().getTime()}`" />
				<h5 v-else>{{ institute.name ? institute.name.slice(0, 1) : '' }}</h5>
			</div>
			<h3>{{ institute.name }}</h3>
			<span class="badge-trial" v-if="isTrial || isFreeTier">{{ tt('무료') }}</span>
			<span class="badge-trial expired" v-else-if="!isLicenseActivate">{{ tt('만료됨') }}</span>
		</div>
		<app-side-global-filter :userPermission="userPermission"></app-side-global-filter>
		<ul class="AppSideMenu__item-container" ref="menu-container">
			<li v-for="(menu, index) in menuList" :key="`menu_` + index">
				<div class="title" :class="{ selected: isSelected(menu.menuPath) }" @click="event => toggle(event, menu.menuTitle)">
					<img :src="require('@/assets/svg/' + menu.menuIcon)" alt="icon" />
					<h6>
						{{ tt(menu.menuTitle) }}
					</h6>
					<img
						v-if="menu.menuImage"
						style="width: calc(100% - 120px); margin-right: 10px"
						:src="require('@/assets/svg/' + menu.menuImage)"
					/>
					<image-button :class="{ menuFold: openMenuList.includes(menu.menuTitle) }" :image="require('@/assets/svg/arrow-down.svg')" />
				</div>
				<ul class="sub-menu" v-if="menu.childList && openMenuList.includes(menu.menuTitle)">
					<li
						v-for="(sub, index) in menu.childList"
						:key="`submenu_` + index"
						v-show="showMenu(sub.menuPath)"
						:class="[{ block: block(sub.menuPath) }, { fold: sub.menuFold != null }]"
					>
						<p
							@click.stop="
								sub.clickMenu && !block(sub.menuPath)
									? handleClick(sub.clickMenu(), $event)
									: sub.clickMenu && block(sub.menuPath)
									? showLicense()
									: ''
							"
							:class="{ selected: isSelected(sub.menuPath) }"
						>
							{{ tt(sub.menuTitle) }}
							<img
								v-b-tooltip.hover="sub.tooltip"
								class="newpage"
								v-if="sub.icon"
								:src="require('@/assets/svg/' + sub.icon)"
								alt="icon"
							/>
							<img v-if="warningSafety(sub.menuPath) && type[sub.menuPath]" class="warning" src="@/assets/svg/input-icon-warning.svg" />
							<img v-if="subNewInventory(sub.menuPath)" class="new" src="@/assets/svg/new.svg" />
						</p>
						<merck-tooltip
							v-if="sub.pageName === 'ProductOrder' && !isSelected(sub.menuPath)"
							:cssVariable="cssVariable"
							:isAnimation="true"
							:isClose="false"
							:isBrandModal="false"
						>
							<!-- <div style="display: flex; align-items: center">
								<img width="30" height="30" src="@/assets/images/icon-sale.png" />
								<span>
									{{ tt('인기 시약 브랜드') }}
									<br />
									{{ tt('최대') }} <span class="highlight-red">{{ tt('20%할인') }}</span>
								</span>
							</div> -->
							<img width="135px" src="@/assets/images/icon-sale-side.png" />
						</merck-tooltip>
						<image-button
							:class="{ menuFold: fold }"
							@click="fold = !fold"
							v-if="sub.menuFold != null"
							:image="require('@/assets/svg/arrow-down.svg')"
						/>
						<div class="tooltip">
							{{ tt('STANDARD 라이선스에서 사용 가능합니다') }}
						</div>
						<ul class="depth-menu" v-if="sub.subMenuList" :class="{ menuFold: fold }">
							<li v-for="(item, index) in sub.subMenuList" :key="`depth_` + index" :ref="sub.menuTitle">
								<p
									@click.stop="item.clickMenu ? handleClick(item.clickMenu(), $event) : ''"
									:class="{
										selected: isSelected(item.menuPath, item.categoryType),
									}"
								>
									{{ tt(item.menuTitle) }}
									<img v-if="item.icon" :src="require(item.icon)" />
									<img v-if="subNewInventory(item.menuPath)" class="new" src="@/assets/svg/new.svg" />
								</p>
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>

		<!--modals-->
		<!-- <inventory-setting
			ref="inventory-setting"
			@setting="hideSettingItem"
			@showLicense="showLicense"
			:userPermission="userPermission"
			:license="license"
		></inventory-setting> -->
		<inventory-gen2-setting ref="inventory-gen2-setting"></inventory-gen2-setting>
	</nav>
</template>

<script>
import { menuList } from '@/utils/define/menu'
// import InventorySetting from '@/views/inventory/setting/pages/InventorySetting.vue'
import Balloon from '@/views/common/components/Balloon.vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Classification } from '@/utils/define/ItemCode'

import AppSideGlobalFilter from '@/views/app/components/AppSideMenu/components/AppSideGlobalFilter.vue'
import instituteInformationAPI from '@/services/api/institute/information'
import InventoryGen2Setting from '@/views/inventoryGen2/setting'

import MerckTooltip from '@/views/common/components/tooltip/MerckTooltip.vue'

export default {
	name: 'AppSideMenu',
	props: {
		license: {
			type: Object,
			default: () => {},
		},
		summaryInfo: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		// InventorySetting,
		Balloon,
		AppSideGlobalFilter,
		InventoryGen2Setting,
		MerckTooltip,
	},
	async created() {
		if (this.$route.query.isMenuOpen) {
			if (!this.openMenuList.includes('물품관리') && (this.$route.path.includes('inventory') || this.$route.path.includes('purchase'))) {
				this.toggleMenu('물품관리')
			} else if (!this.openMenuList.includes('안전관리') && this.$route.path.includes('safety')) {
				this.toggleMenu('안전관리')
			} else if (!this.openMenuList.includes('구매관리') && this.$route.path.includes('product')) {
				this.toggleMenu('구매관리')
			}
		}
	},
	async mounted() {
		if (this.$refs['menu-container']) {
			this.menuHeight = this.$refs['menu-container'].offsetHeight
		}
		this.windowHeight = window.innerHeight
		window.addEventListener('resize', () => {
			this.windowHeight = window.innerHeight
		})
	},
	data() {
		return {
			fold: false,
			rightMenuOn: false,
			windowHeight: null,
			menuHeight: null,
			type: {},
			updateList: {},
			colorList: [
				{
					backgroundColor: '#eb3c96',
					textColor: '#fff',
					imageUrl: require('@/assets/svg/icon/icon-merck.svg'),
				},
				{
					backgroundColor: '#2dbecd',
					textColor: '#503291',
					imageUrl: require('@/assets/svg/icon/icon-merck-503291.svg'),
				},
				{
					backgroundColor: '#503291',
					textColor: '#96d7d2',
					imageUrl: require('@/assets/svg/icon/icon-merck-96d7d2.svg'),
				},
				{
					backgroundColor: '#f8cc46',
					textColor: '#503291',
					imageUrl: require('@/assets/svg/icon/icon-merck-503291.svg'),
				},
				{
					backgroundColor: '#a5cd50',
					textColor: '#0f69af',
					imageUrl: require('@/assets/svg/icon/icon-merck-0f69af.svg'),
				},
			],
			randomNumber: Math.floor(Math.random() * 5),
		}
	},
	computed: {
		...mapState('user', ['institute']),
		...mapGetters('license', ['isBasic', 'isFreeTier', 'isTrial', 'isLicenseActivate']),
		...mapState('appSideMenu', ['openMenuList']),
		cssVariable() {
			return {
				'--top': '-70%',
				'--left': '40%',
				'--backgroundColor': '#fff',
				'--textColor': 'black',
			}
		},
		menuList() {
			return menuList
		},
		setLite() {
			if (this.isBasic) {
				return true
			} else {
				return false
			}
		},
		summary() {
			return this.summaryInfo
		},
		// isInstitute() {
		// 	if (this.$route.path.indexOf('institute') >= 0) {
		// 		this.$nextTick(() => {
		// 			this.$refs['menu-container'].scrollTo(0, 1120)
		// 		})
		// 	}
		// 	return this.$route.path.indexOf('institute') >= 0
		// },
	},
	watch: {},
	methods: {
		...mapMutations('appSideMenu', ['toggleMenu']),
		toggle(event, menuName) {
			this.toggleMenu(menuName)
			this.$nextTick(() => {
				const scrollOffset = event.target.closest('.AppSideMenu__item-container > li').offsetTop
				const liHeight = event.target.closest('.AppSideMenu__item-container > li').clientHeight
				const scrollHeight = this.$refs['menu-container'].scrollTop + this.$refs['menu-container'].clientHeight
				if (scrollOffset > scrollHeight && this.openMenuList.includes(menuName)) {
					this.$refs['menu-container'].scrollTo({
						left: 0,
						top: liHeight + this.$refs['menu-container'].scrollTop,
						behavior: 'smooth',
					})
				}
			})
		},
		handleClick(clickEvent, event) {
			// console.log(event.target.closest('.AppSideMenu__item-container > li').offsetTop - 112)
			// this.$nextTick(() => {
			// 	this.$refs['menu-container'].scrollTo({
			// 		left: 0,
			// 		top: event.target.closest('.AppSideMenu__item-container > li').offsetTop - 112,
			// 		behavior: 'smooth',
			// 	})
			// })
			if (clickEvent) {
				if (clickEvent == 'inventoryGen2Setting') this.showSettingItem()
				if (typeof clickEvent == 'object' && clickEvent?.type == 'store') {
					this.$root.goToStore(clickEvent.link)
				}
				return clickEvent
			}
		},
		isSelected(path, category) {
			if (category) {
				if (this.$route.params.categoryType == category) {
					return true
				} else {
					return false
				}
			}

			if (path) {
				let slicePath = path.replace('/:instituteId', '')

				if (this.$route.path.indexOf(slicePath) >= 0) {
					return true
				}

				return false
			}
		},
		showSettingItem() {
			// this.$refs['inventory-setting'].show()
			this.$refs['inventory-gen2-setting'].show()
		},
		hideSettingItem() {
			this.$refs['inventory-setting'].hide()
		},
		showMenu(path) {
			if (path && this.userPermission) {
				if (path.includes('authority')) {
					return this.userPermission.permission?.read
				} else if (path.includes('history')) {
					return this.userPermission.item?.history?.read
				} else if (path.includes('disposal')) {
					return this.userPermission.item?.dump?.read
				} else if (path.includes('dangerousStorage')) {
					return this.userPermission.safe?.dangerstorage?.read
				} else if (path.includes('safetyChemicals')) {
					return this.userPermission.safe?.harmfulchemical?.read
				} else if (path.includes('safetyManagementMaterials')) {
					return this.userPermission.safe?.specialmanagement?.read
				} else if (path.includes('healthCheck')) {
					return this.userPermission.safe?.diagnosis?.read
				} else if (path.includes('legalProduct')) {
					return this.userPermission.safe?.legalitems?.read
				} else if (path.includes('budget')) {
					return this.userPermission.budget?.read
				} else if (path.includes('draft')) {
					return this.userPermission.draft?.create
				} else if (path.includes('approval')) {
					return this.userPermission.draft?.read
				} else if (path.includes('cargo')) {
					return this.userPermission.cargo?.read
				} else if (path.includes('product/order')) {
					return this.userPermission.order?.create
				} else if (path.includes('product/pay')) {
					return this.userPermission.order?.read
				} else {
					return true
				}
			} else {
				return true
			}
		},
		block(path) {
			if (path) {
				if (this.setLite && path.indexOf('safety') >= 0 && path.indexOf('msds') < 0) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
		showLicense() {
			this.$emit('showLicense')
		},
		warningSafety(path) {
			let result = false
			let safety = this.summary.safety

			if (this.indexOfPath(path, 'safety') && safety) {
				if (this.indexOfPath(path, 'msds')) {
					result = safety.msds.status
				} else if (this.indexOfPath(path, 'danger')) {
					result = safety.dangerstorage.status
				} else if (this.indexOfPath(path, 'health')) {
					result = safety.specialdiagnosis.status
				}
			}

			return result
		},
		indexOfPath(path, item) {
			return path.indexOf(item) >= 0
		},
		subNewInventory(path) {
			let result = false

			const summaryKeys = Object.keys(this.summary)
			const institute = this.summary.institute
			const inventory = this.summary.inventory
			const safety = this.summary.safety
			const purchase = this.summary.purchase
			const budget = this.summary.budget
			const approval = this.summary.approval
			// let categoriesKeys = []
			// let categoriesValues = []
			// if (inventory) {
			// 	categoriesKeys = Object.keys(inventory.category)
			// 	categoriesValues = Object.values(inventory.category)
			// }

			summaryKeys.forEach(key => {
				if (this.indexOfPath(path, key)) {
					// 예산 관리
					if (this.indexOfPath(path, 'budget/list')) {
						result = budget.isNew
					}
					// 결재 관리
					if (this.indexOfPath(path, 'purchase/draft')) {
						result = approval.draftBox.isNew
					} else if (this.indexOfPath(path, 'purchase/approval')) {
						result = approval.approvalBox.isNew
					}
					// 구매 관리
					if (this.indexOfPath(path, 'store/delivery')) {
						result = purchase.order.isNew
					} else if (this.indexOfPath(path, 'store/payment')) {
						result = purchase.payment.isNew
					}
					// 물품 관리
					if (this.indexOfPath(path, 'inventory/list')) {
						result = inventory.inv.isNew
					} else if (this.indexOfPath(path, 'purchase/cargo')) {
						result = inventory.cargo.isNew
					} else if (this.indexOfPath(path, 'inventory/waitingList')) {
						result = inventory.inspect.isNew
					} else if (this.indexOfPath(path, 'inventory/historyList')) {
						result = inventory.log.isNew
					} else if (this.indexOfPath(path, 'inventory/disposalList')) {
						result = inventory.dump.isNew
					}
					// 안전 관리
					if (this.indexOfPath(path, 'msds')) {
						result = safety.msds.isNew
					} else if (this.indexOfPath(path, 'danger')) {
						result = safety.dangerstorage.isNew
					} else if (this.indexOfPath(path, 'health')) {
						result = safety.specialdiagnosis.isNew
					}
					// 기관 관리
					if (this.indexOfPath(path, 'organization')) {
						result = institute.group.isNew
					} else if (this.indexOfPath(path, 'location')) {
						result = institute.location.isNew
					} else if (this.indexOfPath(path, 'member')) {
						result = institute.member.isNew
					} else if (this.indexOfPath(path, 'authority')) {
						result = institute.permission.isNew
					}
				}
			})

			return result
		},
		//todo
		//1. click event 시 새롭게 등록된 아이디 리스트와 클릭된 아이디 리스트 비교해서 새롭게 등록된 리스트를 확인했는지 여부 추가 필요
		//2. last datetime update 여부를 확인한 후, update가 됐으면, 그 이후 새롭게 등록된 id list 와 클릭된 list 다시 비교 필요
		// getLocalStorageNewList(key) {
		// 	let result = true

		// 	let newList = localStorage.getItem(key)
		// 		? localStorage.getItem(key).split(',')
		// 		: []
		// 	let clickedList = localStorage.getItem(`clicked${key}`)
		// 		? localStorage.getItem(`clicked${key}`).split(',')
		// 		: []

		// 	if (newList.length > 0 && newList.length == clickedList.length) {
		// 		result = false
		// 	}

		// 	return result
		// },
		// async getNewIdList(key, sub) {
		// 	let target = `${key}.${sub}`

		// 	const response = await instituteInformationAPI.lookupIsNew(
		// 		this.$route.params.instituteId,
		// 		target
		// 	)

		// 	if (response.data && response.data[sub]) {
		// 		console.log(localStorage.getItem(sub), sub)
		// 	}

		// 	// if(localStorage.getItem(sub)) {
		// 	// 	response.data[sub]
		// 	// }
		// },
		// getAllChangeList() {
		// 	const summaryKeys = Object.keys(this.summary)
		// 	let categoriesKeys = []
		// 	let updateList = {}
		// 	let allKeys = []

		// 	summaryKeys.forEach(key => {
		// 		let subKeys = Object.keys(this.summary[key])

		// 		subKeys.forEach(sub => {
		// 			this.getNewIdList(key, sub)
		// 			if (sub == 'category') {
		// 				categoriesKeys = Object.keys(this.summary[key].category)

		// 				categoriesKeys.forEach(category => {
		// 					updateList[category] = {
		// 						lastUpdateDatetime:
		// 							this.summary[key][sub].lastUpdateDatetime,
		// 						change: true,
		// 					}
		// 					allKeys.push(category)
		// 				})
		// 			} else {
		// 				updateList[sub] = {
		// 					lastUpdateDatetime:
		// 						this.summary[key][sub].lastUpdateDatetime,
		// 					change: true,
		// 				}
		// 				allKeys.push(sub)
		// 			}
		// 		})
		// 	})

		// 	if (!localStorage.getItem('updateList')) {
		// 		localStorage.setItem('updateList', JSON.stringify(updateList))
		// 	} else {
		// 		if (
		// 			JSON.stringify(updateList) ==
		// 			localStorage.getItem('updateList')
		// 		) {
		// 			allKeys.forEach(key => {
		// 				updateList[key].change = false
		// 			})
		// 		} else {
		// 			localStorage.setItem(
		// 				'updateList',
		// 				JSON.stringify(updateList)
		// 			)
		// 		}
		// 	}

		// 	this.updateList = updateList
		// },
		goDashboard() {
			this.$router.push({
				name: 'Dashboard',
				params: {
					instituteId: this.$route.params.instituteId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
// .merck-tooltip {
// 	position: absolute;
// 	display: block;
// 	width: min-content;
// 	background-color: #eb3c96;
// 	border-radius: 8px;
// 	padding: 0 5px;
// 	top: -50%;
// 	left: 50%;
// 	animation: float 1s ease-in-out infinite;
// 	.merck-tooltip-inner {
// 		color: $LAB_WHITE;
// 		padding: 5px 0;
// 		top: -5px;
// 		left: 105%;
// 		font-size: 12px;
// 		width: max-content;
// 		&:after {
// 			content: '';
// 			position: absolute;
// 			border-style: solid;
// 			border-width: 6px;
// 			//
// 			top: 50%;
// 			right: 100%;
// 			margin-top: -5px;
// 			border-color: transparent #eb3c96 transparent transparent;
// 		}
// 	}
// }
// @keyframes float {
// 	0% {
// 		// box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
// 		transform: translatey(0px);
// 	}
// 	50% {
// 		// box-shadow: 0 25px 15px 0px $DIM;
// 		transform: translatex(-20px);
// 	}
// 	100% {
// 		// box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
// 		transform: translatey(0px);
// 	}
// }
::v-deep {
	.merck-tooltip {
		background: none;
		padding: 0px;
		.merck-tooltip-inner {
			&:before {
				content: none;
			}
			&:after {
				content: none;
			}
		}
	}
}
::-webkit-scrollbar {
	display: none;
}
.AppSideMenu__container {
	display: block;
	width: 250px;
	height: 100%;
	position: fixed;
	background-color: #f7f6f3;
	text-align: left;
	z-index: 1;
	overflow: initial;
	&.overflow {
		overflow-y: auto;
	}
	.logo {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 10px;
		height: 60px;
		padding: 1rem;
		border-bottom: 1px solid #e1e0dd;
		cursor: pointer;
		.imgbox {
			min-width: 36px;
			width: 36px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			overflow: hidden;
			&.noLogo {
				background-color: $SECONDARY_2;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			h5 {
				font-size: 1rem;
				color: $LAB_WHITE;
				font-weight: 700;
			}
		}
		h3 {
			font-size: 0.875rem;
			font-weight: 700;
			// width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.badge-trial {
			background-color: #f4fffa;
			border: 1px solid #b5e7d2;
			border-radius: 5px;
			font-size: 10px;
			font-weight: 700;
			padding: 0px 6px;
			// margin-left: auto;
			color: $GREEN;
			height: 18px;
			min-width: 33px;
			width: 33px;
			text-align: center;
			&.expired {
				width: 65px;
				min-width: 65px;
				background: #fff5f5;
				border: 1px solid #ffdad7;
				color: $RED;
			}
		}
	}

	.AppSideMenu__item-container {
		border-top: 1px solid #e1e0dd;
		color: #000;
		height: calc(100% - 115px);
		overflow-y: auto;
		padding-bottom: 300px;
		// &.isInstitute {
		// 	padding-bottom: 64vh;
		// }
		> li {
			border-bottom: 1px solid $DIVIDER;
		}
		.title {
			cursor: pointer;
			padding-left: 16px;
			padding-right: 8px;
			font-size: 14px;
			font-weight: 500;
			display: flex;
			align-items: center;
			height: 55px;
			width: calc(100%);
			margin: 4px auto;
			border-radius: 5px;
			&:nth-child(1) {
				cursor: pointer;
			}
			&.selected {
				font-weight: 700;
			}
			h6 {
				font-size: inherit;
				margin-left: 7px;
				width: calc(100% - 32px);
				&.pointer {
					cursor: pointer;
				}
			}
			> img {
				width: 24px;
				height: 24px;
			}
		}

		button {
			display: inline-flex;
			background-color: transparent !important;
			border: none !important;
			width: 22px;
			height: 22px;
			margin-left: auto;
			box-shadow: none !important;
			img {
				width: inherit;
				height: inherit;
			}
		}
		.menuFold {
			transform: rotate(180deg);
		}
		ul {
			padding: 10px 5px 10px 10px;
			background: #f0efec;
			width: 100%;
			li {
				width: 100%;
				cursor: pointer;
				position: relative;
				&:hover {
					background: #e9e8e5;
				}
				&.block {
					background-image: url(~@/assets/svg/lock-off.svg);
					background-repeat: no-repeat;
					background-position: center right 10px;
					background-size: 14px;
					&:hover {
						.tooltip {
							display: inline-block;
						}
					}
					p {
						opacity: 0.4;
					}
					.tooltip {
						padding: 6px 8px;
						border: 1px solid #000;
						border-radius: 5px;
						background-color: $LAB_WHITE;
						position: absolute;
						font-size: 0.75rem;
						font-weight: 700;
						z-index: 999;
						top: 30px;
						left: 0;
						&:before {
							border-top: 0px solid transparent;
							border-left: 9px solid transparent;
							border-right: 9px solid transparent;
							border-bottom: 9px solid rgba(0, 0, 0, 0.6);
							content: '';
							position: absolute;
							top: -9.2px;
							left: 40px;
							z-index: 1003;
						}
						&:after {
							border-top: 0px solid transparent;
							border-left: 8px solid transparent;
							border-right: 8px solid transparent;
							border-bottom: 8px solid $LAB_WHITE;
							content: '';
							position: absolute;
							top: -7.5px;
							left: 41px;
							z-index: 1004;
						}
					}
				}
				&.fold {
					display: block;
					> p {
						display: inline-block;
						width: calc(100% - 25px);
						&:hover {
							background-color: $DIVIDER;
							border-radius: 8px;
						}
						&.selected {
							&:hover {
								// background-color: $FONT_YELLOW2;
							}
						}
					}
					.fold-box {
						vertical-align: middle;
						width: 20px;
						height: 20px;
						display: inline-block;
						position: relative;
						background-color: transparent !important;
						.fold {
							position: absolute;
							top: 0;
							left: 0;
						}
					}
				}
				.tooltip {
					display: none;
				}
				p {
					font-size: 0.875rem;
					padding: 3px 5px 3px 31px;
					background-image: url(~@/assets/svg/dot-gray.svg);
					background-repeat: no-repeat;
					background-position: center left 8px;
					height: auto;
					margin: 2px auto;
					width: 100%;
					border-radius: 5px;
					display: flex;
					align-items: center;
					box-sizing: border-box;
					position: relative;
					&.selected {
						background-image: url(~@/assets/svg/dot-yellow.svg);
						color: $FONT_YELLOW2;
						// background-color: $LAB_YELLOW;
						font-weight: 700;
						letter-spacing: -0.02rem;
					}
					> img {
						&.new {
							margin-left: 5px;
							// opacity: 0;
							// &.display {
							//     opacity: 1;
							// }
						}
						&.warning {
							position: absolute;
							right: 11px;
							top: 8px;
						}
						&.newpage {
							margin-left: 5px;
						}
					}
				}
				.fold {
					// position: absolute;
					// top: 5px;
					// right: 23px;
					margin: 0;
					width: 0;
					height: 0;
					&:before {
						display: block;
						width: 20px;
						height: 20px;
						border: none;
						background-color: transparent;
						background-size: 20px;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url(~@/assets/svg/arrow-up.svg);
						z-index: 1;
						// transition: 0.5s all ease;
						transition: none;
						transform: rotate(180deg);
					}
					&:after {
						display: none;
					}
				}
				.fold:checked {
					&:before {
						transform: rotate(0deg);
					}
					&:after {
						display: none;
					}
				}
				ul {
					margin-left: 8px;
					width: calc(100% - 8px);
					max-height: 100vh;
					height: unset;
					display: block;
					&.menuFold {
						max-height: 0;
						height: 0;
						overflow: hidden;
						display: none;
					}
				}
			}
		}
	}
}
</style>
