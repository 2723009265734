<template>
	<div class="status-table-wrapper">
		<h6>
			{{ tt('예산현황표') }}
		</h6>
		<div class="status-table">
			<div class="left-fixed" style="width: 200px">
				<div style="width: 100%">
					<div class="header group bd">{{ tt('참여그룹') }}</div>
					<div class="item yellow bd">
						{{ tt('합계') }}
					</div>
					<div class="item bd" v-for="group in groups" :key="group.id" v-b-tooltip.hover="group.name">
						<p>{{ group.name }}</p>
					</div>
				</div>
			</div>
			<div class="table-content">
				<div :style="{ '--width': `${Object.keys(monthKey).length * 390}px` }">
					<div class="column" v-for="key in Object.keys(monthKey)" :key="key">
						<div class="header">
							<div>
								<div class="item bd">
									{{ tt(monthKey[key]) }}
								</div>
							</div>
							<div>
								<div class="item bd" v-if="hasBudget">
									{{ tt('예산') }}
								</div>
								<div class="item bd" v-if="hasExpenditure">
									{{ tt('지출') }}
								</div>
								<div class="item bd" v-if="hasBalance">
									{{ tt('잔액') }}
								</div>
							</div>
						</div>
						<div class="item yellow right">
							<div>
								<div class="item bd" v-if="hasBudget">{{ shareValue(key, 'budget') }}</div>
								<div class="item bd" v-if="hasExpenditure">{{ sum(key, 'expenditure') }}</div>
								<div class="item bd" v-if="hasBalance">{{ shareValue(key, 'balance') }}</div>
							</div>
						</div>
						<div class="item right">
							<div :style="{ height: `${groups.length * 40}px` }">
								<div class="item bd" v-if="hasBudget">{{ shareValue(key, 'budget') }}</div>
								<div class="item" v-if="hasExpenditure">
									<div class="item bd" v-for="group in groups" :key="`${key}_${group.id}`">
										{{ price(group.expenditure[key].expenditure) }}
									</div>
								</div>
								<div class="item bd" v-if="hasBalance">{{ shareValue(key, 'balance') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import MixinDate from '@/mixins/date'
export default {
	props: {
		originGroups: {
			type: Array,
			defualt: () => [],
		},
		year: {
			type: Number,
		},
		isMonth: {
			type: Boolean,
		},
	},
	data() {
		return {
			checkedList: [],
			keyword: '',
		}
	},
	mixins: [MixinDate],
	computed: {
		groups() {
			const list = this.checkedList.filter(c => c.type == 'GROUP')
			return this.originGroups.filter(it => (list.length == 0 || list.find(g => g.id == it.id)) && it.name.includes(this.keyword))
		},
		monthKey() {
			let monthKey = { total: this.tt('계(${1}년)', this.year) }
			if (this.isMonth)
				monthKey = {
					...monthKey,
					...this.monthObject,
				}
			const list = this.checkedList.filter(c => c.type == 'MONTH' && Object.keys(monthKey).includes(c.id)).map(c => c.id)
			if (list.length > 0) {
				console.log(list)
				Object.keys(monthKey).forEach(k => {
					if (!list.includes(k)) {
						delete monthKey[k]
					}
				})
			}
			return monthKey
		},
		hasBudget() {
			return !this.checkedList.find(it => it.type == 'CATEGORY') || this.checkedList.find(it => it.type == 'CATEGORY' && it.id == 'budget')
		},
		hasExpenditure() {
			return !this.checkedList.find(it => it.type == 'CATEGORY') || this.checkedList.find(it => it.type == 'CATEGORY' && it.id == 'expenditure')
		},
		hasBalance() {
			return !this.checkedList.find(it => it.type == 'CATEGORY') || this.checkedList.find(it => it.type == 'CATEGORY' && it.id == 'balance')
		},
	},
	methods: {
		sum(key, type) {
			return this.tt(
				'${1}원',
				this.groups
					.map(g => g.expenditure[key][type] ?? 0)
					.reduce((a, b) => a + b, 0)
					.toLocaleString()
			)
		},
		shareValue(key, type) {
			return this.tt('${1}원', this.groups.map(g => g.expenditure[key][type] ?? 0)[0].toLocaleString())
		},
		price(price) {
			return this.tt('${1}원', (price ?? 0).toLocaleString())
		},
		setCheckedList(checkedList) {
			this.checkedList = checkedList
			this.$forceUpdate()
		},
		setKeyword(keyword) {
			this.keyword = keyword
			this.$forceUpdate()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-budget.scss';
.table-content {
	flex-basis: calc(100% - 200px);
}
</style>
