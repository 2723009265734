<template>
	<div class="inputWrapper">
		<input
			type="text"
			ref="input"
			:placeholder="tt(placeHolder)"
			:value="value"
			:class="{ error: !isValid }"
			@input="handleInputValue"
			@keypress.enter="goNext"
		/>
		<span class="noti">{{ tt(notiMsg) }}</span>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: '',
		},
		placeHolder: {
			type: String,
			default: '',
		},
		notiMsg: {
			type: String,
			default: '',
		},
	},
	mounted() {
		this.$refs.input.focus()
	},
	data() {
		return {
			isValid: true,
		}
	},
	methods: {
		handleInputValue(event) {
			this.isValid = true
			this.$emit('changeInputValue', event.target.value)
		},
		goNext() {
			this.$emit('next')
		},
	},
}
</script>

<style lang="scss" scoped>
.inputWrapper {
	input[type='text'] {
		width: 700px;
		height: 48px;
		border: 1px solid #e6e6e6;
		border-radius: 5px;
		padding: 0 12px;
		&:focus {
			border-color: #000;
		}
		&.error {
			border-color: $RED;
		}
		&.error ~ .noti {
			display: block;
		}
	}
	.noti {
		width: 100%;
		display: none;
		font-size: 0.75rem;
		color: $RED;
		font-weight: 400;
		background-image: url(~@/assets/svg/noti-error.svg);
		padding-left: 16px;
		background-position: center left 0;
		background-repeat: no-repeat;
		margin-top: 10px;
	}
}
</style>
