var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal-single-select-storage",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.goStorageManagement
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('보관함 관리')) + " ")])]), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.complete
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "search-wrap"
  }, [_c('search-box', {
    ref: "search-box",
    attrs: {
      "noRecent": "",
      "placeholder": _vm.tt('보관함을 검색해주세요'),
      "value": _vm.keyword
    },
    on: {
      "change": _vm.setKeyword
    }
  })], 1), _c('div', {
    staticClass: "folding-wrapper"
  }, [_c('div', {
    on: {
      "click": function click($event) {
        return _vm.setTreeOpen(true);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-unfold.svg")
    }
  }), _vm._v(_vm._s(_vm.tt('전체 펼치기')))]), _c('span', {
    staticClass: "br"
  }), _c('div', {
    on: {
      "click": function click($event) {
        return _vm.setTreeOpen(false);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-fold.svg")
    }
  }), _vm._v(_vm._s(_vm.tt('전체 접기')))])]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "tree-wrap"
  }, _vm._l(_vm.list, function (location) {
    return _c('location-node', {
      key: location.id,
      attrs: {
        "node": location,
        "type": "location"
      }
    });
  }), 1)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }