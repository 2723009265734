var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-custom', {
    attrs: {
      "title": "추가정보",
      "isCustomValid": _vm.isCustomValid,
      "categoryInfo": _vm.categoryInfo,
      "memberList": _vm.getMemberList.members,
      "itemCustomField": _vm.itemCustomField,
      "isShowError": _vm.showError
    },
    on: {
      "change": _vm.setFormValue
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }