var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-wrap",
    "class": [_vm.sizeType]
  }, [_c('input', {
    staticClass: "input",
    "class": [_vm.error || _vm.isShowError ? 'error' : ''],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.count
    },
    on: {
      "input": _vm.handleInput,
      "change": _vm.handleChange
    }
  }), _c('div', {
    staticClass: "button-wrap"
  }, [_c('button', {
    staticClass: "minus",
    "class": {
      disable: _vm.isMin
    },
    on: {
      "click": _vm.subtract
    }
  }, [_vm._v("-")]), _c('button', {
    staticClass: "plus",
    "class": {
      disable: _vm.isMax
    },
    on: {
      "click": _vm.add
    }
  }, [_vm._v("+")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }