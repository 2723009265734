<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddChildStorageBoxPopup" ref="AddChildStorageBoxPopup">
			<div class="Popup__background"></div>
			<div class="Location__edit-location-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt(title) }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field">
						<p>{{ tt(labelName) }}<span class="required">*</span></p>
						<input type="text" :placeholder="tt('하위 보관함명을 입력해주세요')" :value="storageName" @input="changeStorageName" />
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">
							{{ tt('취소') }}
						</button>
						<button
							:class="{
								Btn__complete: !isSameName,
								Btn__disabled: isSameName,
							}"
							@click="complete"
						>
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
export default {
	name: 'AddChildStorageBoxPopup',
	mixins: [MixinModal],
	props: {
		title: {
			type: String,
		},
		labelName: {
			type: String,
		},
	},
	data() {
		return {
			isShow: false,
			storageName: '',
			originStorageName: '',
		}
	},
	computed: {
		isSameName() {
			return this.storageName === this.originStorageName
		},
	},
	methods: {
		show() {
			this.isShow = true
			this.storageName = ''
		},
		setStorageName(storageName) {
			this.storageName = storageName
			this.originStorageName = storageName
		},
		hide() {
			this.isShow = false
		},
		changeStorageName(e) {
			this.storageName = e.target.value
		},
		complete() {
			let name = this.storageName.trim()

			if (name.length == 0) {
				this.$root.toast(this.tt('하위 보관함 등록 실패'), this.tt('하위 보관함명은 필수값입니다'), 'error')
				return false
			} else {
				if (this.isSameName) return
				this.$emit('complete', this.storageName)
				this.hide()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.Btn__disabled {
	border: 1px solid rgba(242, 243, 244, 1);
	border-radius: 5px;
	font-weight: 700;
	background: linear-gradient(0deg, rgba(242, 243, 244, 0.2), rgba(242, 243, 244, 0.2)), linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
	color: #cacbcb !important;
	cursor: initial;
}
</style>
