var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isValid
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.validMessage)) + " ")])]), _vm.error ? _c('div', {
    staticClass: "error-msg"
  }, [_vm._v(" " + _vm._s(_vm.tt('등록 방법 선택은 필수입니다.')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "selector-wrap"
  }, [_vm.isChemical && !_vm.isGas ? _c('div', {
    staticClass: "auto-regist"
  }, [_c('div', {
    staticClass: "title-header"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.tt('자동등록')))])]), !_vm.isGas ? _c('item', {
    attrs: {
      "image": require('@/views/inventoryGen2/svg/create/regist-method-image.svg'),
      "title": "사진으로 등록",
      "subtext": "사진분석 및 검수 후에 자동으로 등록해드립니다",
      "active": _vm.isActive('AUTO_IMAGE')
    },
    on: {
      "click": function click($event) {
        return _vm.selectType('AUTO_IMAGE');
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "manual-regist"
  }, [_c('div', {
    staticClass: "title-header"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.tt('수기등록')))])]), _c('item', {
    attrs: {
      "image": require('@/views/inventoryGen2/svg/create/regist-method-excel.svg'),
      "title": "엑셀 파일로 등록",
      "subtext": "지정된 양식의 엑셀을 업로드하여 등록합니다",
      "active": _vm.isActive('MANUAL_FILE')
    },
    on: {
      "click": function click($event) {
        return _vm.selectType('MANUAL_FILE');
      }
    }
  }), _c('item', {
    attrs: {
      "image": require('@/views/inventoryGen2/svg/create/regist-method-manual.svg'),
      "title": "DB 검색/직접입력",
      "subtext": "물성정보를 검색하고, 직접 입력하여 등록합니다",
      "active": _vm.isActive('MANUAL_DIRECT')
    },
    on: {
      "click": function click($event) {
        return _vm.selectType('MANUAL_DIRECT');
      }
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }