import ParamMaker from '@/utils/ParamMaker'
import axiosInstance from '@/services/axios'

const endPoint = {
	lookupGroupTree: instId => `/institute/${instId}/group/tree`,
	lookupGroupList: instId => `/institute/${instId}/group/list`,
	lookupGroupInfo: groupId => `/group/${groupId}`,
	addGroup: instId => `/institute/${instId}/group`,
	deleteGroup: groupId => `/group/${groupId}`,
	editGroup: groupId => `/group/${groupId}`,
	lookupGroupMemberList: groupId => `/group/${groupId}/member/list`,
	addGroupMember: groupId => `/group/${groupId}/member`,
	editAccessAuthority: groupId => `/group/${groupId}/access`,
	deleteAccessAuthority: (groupId, accessId) => `/group/${groupId}/access?id=${accessId}`,
	addAccessAuthority: groupId => `/group/${groupId}/access`,
	deleteGroupMember: (groupId, groupIds) => `/group/${groupId}/member?${ParamMaker.setArrayString('ids', groupIds)}`,
	changeParentGroup: (groupId, data) => `/group/${groupId}`,
	lookupGlobalGroup: (instId, isAccessible) => `/institute/${instId}/sidebar/group/tree?isAccessible=${isAccessible}`,
}

const instituteGroupAPI = {
	lookupGroupTree: (instId, params) => axiosInstance.get(endPoint.lookupGroupTree(instId), { params }),
	lookupGroupList: instId => axiosInstance.get(endPoint.lookupGroupList(instId)),
	lookupGroupInfo: groupId => axiosInstance.get(endPoint.lookupGroupInfo(groupId)),
	addGroup: (instId, data) => axiosInstance.post(endPoint.addGroup(instId), data),
	deleteGroup: groupId => axiosInstance.delete(endPoint.deleteGroup(groupId)),
	editGroup: (groupId, data) => axiosInstance.put(endPoint.editGroup(groupId), data),
	lookupGroupMemberList: (groupId, params) =>
		axiosInstance.get(endPoint.lookupGroupMemberList(groupId), {
			params: params,
		}),
	addGroupMember: (groupId, data) => axiosInstance.post(endPoint.addGroupMember(groupId), data),
	editAccessAuthority: (groupId, data) => axiosInstance.patch(endPoint.editAccessAuthority(groupId), data),
	deleteAccessAuthority: (groupId, accessId) => axiosInstance.delete(endPoint.deleteAccessAuthority(groupId, accessId)),
	addAccessAuthority: (groupId, data) => axiosInstance.post(endPoint.addAccessAuthority(groupId), data),
	deleteGroupMember: (groupId, groupIds) => axiosInstance.delete(endPoint.deleteGroupMember(groupId, groupIds)),
	changeParentGroup: (groupId, data) => axiosInstance.patch(endPoint.changeParentGroup(groupId), data),
	lookupGlobalGroup: (instId, isAccessible) => axiosInstance.get(endPoint.lookupGlobalGroup(instId, isAccessible)),
}

export default instituteGroupAPI
