<template>
	<div class="detail-wrapper">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ tt('지출결의서 작성') }}</h2>
			</div>
		</div>
		<div class="common-detail-wrapper" :class="{ hide: step !== index + 1 }" v-for="(component, index) of componentList" :key="index">
			<component v-if="step === index + 1" :is="component" ref="draft-registration"></component>
		</div>
		<div class="common-detail-bottom">
			<base-button size="lg" :color="'primary only'" @click="prev">{{ tt(prevText) }}</base-button>
			<base-button size="lg" :color="'primary'" @click="next">{{ tt(nextText) }}</base-button>
		</div>
		<modal-confirm
			ref="modal-out"
			title="페이지 이동"
			:confirmText="step == 1 ? '나가기' : '이전으로'"
			content="페이지를 이동하면 지금까지 작성한 내용이 사라집니다.<br>페이지를 이동하시겠습니까?"
		/>
		<modal-confirm
			ref="modal-store"
			title="랩매니저 스토어"
			confirmText="랩매니저 스토어로 가기"
			content="랩매니저 스토어 이용 기록이 없는 기관입니다.<br>랩매니저 스토어에서 기관 설정을해주세요"
		/>
		<modal-confirm
			ref="modal-can-order"
			title="지출결의서 작성 불가"
			confirmText="확인"
			variant="warning"
			content="지출결의서에 배송 가능한 상품이 없습니다.<br>상품을 다시 한번 확인해주세요."
		/>
		<modal-confirm
			ref="modal-budget-over"
			title="예산 초과"
			confirmText="상신"
			content="지출 예정금액이 예산 잔여금액을 초과하였습니다.<br>정말로 지출결의서를 상신하시겠습니까?"
		/>
		<modal-payment-requirement @ok="next"></modal-payment-requirement>
		<modal-remain-later-pay ref="modal-remain-later-pay" />
		<ModalLicenseAndSale ref="ModalLicenseAndSale" @purchaseWithoutSale="purchaseWithoutSale" @purchaseConfirm="next" />
	</div>
</template>

<script>
const SELLER_KEY = {
	name: 'sellerName',
	contactName: 'sellerManager',
	contactPhone: 'sellerPhone',
	address: 'sellerAddress',
	businessNumber: 'sellerBusinessNumber',
}
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DraftRegistrationStep1 from '../components/regist/DraftRegistrationStep1.vue'
import DraftRegistrationStep2 from '../components/regist/DraftRegistrationStep2.vue'
import ModalPaymentRequirement from '@/views/purchase/componetns/modal/ModalPaymentRequirement.vue'
import ModalRemainLaterPay from '@/views/purchase/product/components/modal/ModalRemainLaterPay.vue'
import ModalLicenseAndSale from '@/views/purchase/product/components/modal/ModalLicenseAndSale.vue'
import MixinStore from '@/mixins/store'
export default {
	name: 'DraftRegistration',
	props: {},
	mixins: [MixinStore],
	components: {
		DraftRegistrationStep1,
		DraftRegistrationStep2,
		ModalPaymentRequirement,
		ModalRemainLaterPay,
		ModalLicenseAndSale,
	},
	data() {
		return {
			componentList: [DraftRegistrationStep1, DraftRegistrationStep2],
			step: 1,
		}
	},
	computed: {
		...mapState('user', ['user']),
		// ...mapState('institute/group', ['groups']),
		...mapState('draft/item', ['groupList', 'draftInfo']),
		...mapGetters('draft/item', ['getSelectedBudget']),
		mode() {
			return this.$route.params.mode
		},
		prevText() {
			return this.step === 1 ? '나가기' : '이전으로'
		},
		nextText() {
			return this.step === 1 ? '다음으로' : '작성 완료'
		},
	},
	watch: {
		'$route.query.step': {
			handler(newVal, oldVal) {
				if (!newVal) this.step = 1
				else this.step = 2
			},
			immediate: true,
		},
	},
	created() {
		this.initSelectedMembers()
		this.initDraftRegistration()
		this.loadDraftRegist()
	},
	methods: {
		...mapActions('user', ['loadUserInfo']),
		// ...mapActions('institute/group', ['getGroups']),
		...mapActions('draft/item', ['getGroupList', 'getUsableBudgetList', 'createDraft']),
		...mapMutations('institute/member', ['initSelectedMembers']),
		...mapMutations('draft/item', ['initDraftRegistration', 'setDraftGroup']),
		...mapMutations('institute/group', ['setSelectedGroup']),
		async loadDraftRegist() {
			await this.loadUserInfo() // 공통
			await this.getGroupList() // 공통
			await this.getUsableBudgetList(this.draftInfo.groupId)
			this.setSelectedGroup([this.groupList[0]])
			this.setDraftGroup(this.groupList[0])
		},
		// function
		prev() {
			this.$refs['modal-out'].show(() => {
				this.$router.go(-1)
			})
		},
		async next() {
			try {
				this.$refs['draft-registration'][0].validation()
			} catch (err) {
				if (err == 'no product') {
					this.$root.toast('상품 추가', '상품을 추가해주세요.', 'error')
				} else if (err == 'approvalLine') {
					this.$root.toast('결재선 지정', '결재선을 설정해주세요.', 'error')
				} else if (err == 'no license') {
					this.$refs['ModalLicenseAndSale'].show()
				} else {
					this.$root.toast('필수값 미입력', '필수값을 입력해주세요.', 'error')
				}
				return
			}
			if (this.step === 1) {
				if (this.draftInfo.purchaseType == 'INTERNAL') {
					if (!this.lmsInstituteId) await this.getLMSInstituteId()
					if (this.lmsInstituteId == null) {
						this.$refs['modal-store'].show(() => {
							this.$root.goToStore()
						})
						return
					} else {
						await this.getRequirement()
						if ((this.requirement?.canOrder || false) == false) {
							this.$bvModal.show('ModalPaymentRequirement')
							return
						}
						this.$refs['modal-remain-later-pay'].show()
					}
				}
				this.step = 2
				this.$router.push({
					query: {
						step: this.step,
					},
				})
			} else {
				let budget = this.getSelectedBudget?.summary?.budget - this.draftInfo.expendAmount
				if (this.getSelectedBudget && this.draftInfo.expendAmount > 0 && budget < 0) {
					this.$refs['modal-budget-over'].show(() => {
						this.submit()
					})
				} else {
					this.submit()
				}
			}
		},
		purchaseWithoutSale() {},
		async submit() {
			// this.formData를 Server로 보내는 로직
			console.log('this.draftInfo', this.draftInfo)
			const canOrderitem = this.draftInfo.purchaseItems.filter(it => !it.isBlock)
			if (canOrderitem?.length == 0) {
				this.$refs['modal-can-order'].show()
				return
			}
			const formData = new FormData()
			Object.entries(this.draftInfo).forEach(([key, value]) => {
				switch (key) {
					case 'approvalLine':
						value.forEach((item, idx) => {
							formData.append(`${key}[${idx}][userId]`, item.id)
							formData.append(`${key}[${idx}][groupId]`, item.parent.id)
						})
						break
					case 'purchaseItems':
						let i = 0
						value.forEach((item, idx) => {
							if (item.isBlock) return
							Object.entries(item).forEach(([itemKey, itemValue]) => {
								if (itemValue && itemKey)
									if (itemKey === 'seller') {
										Object.entries(itemValue).forEach(([k, v]) => {
											if (v && SELLER_KEY[k]) formData.append(`${key}[${i}][${SELLER_KEY[k]}]`, v)
										})
									} else if (itemKey === 'brand') {
										Object.entries(itemValue).forEach(([k, v]) => {
											if (k === 'id' && v) formData.append(`${key}[${i}][brandId]`, v)
											else if (k === 'name' && v) formData.append(`${key}[${i}][brandName]`, v)
											else if (k === 'image' && v) formData.append(`${key}[${i}][brandImage]`, v)
										})
									} else if (itemKey === 'category') {
										if (itemValue.code) formData.append(`${key}[${i}][categoryType]`, itemValue.code)
									} else if (itemKey === 'productId') {
										formData.append(`${key}[${i}][id]`, itemValue)
									} else formData.append(`${key}[${i}][${itemKey}]`, itemValue)
							})
							i++
						})
						break
					case 'attachments':
						value.forEach(item => {
							if (item) formData.append(key, item)
						})
						break
					default:
						if (value) formData.append(key, value)
						break
				}
			})
			const response = await this.createDraft(formData)
			if (response.status == 201 || response.status == 205) {
				this.$root.toast(`지출 결의서 작성 완료`, `지출 결의서가 상신되었습니다.`, 'success')
				const { draftId } = response.data.draftId
				this.$router.push({ name: 'DraftDetail', params: { id: draftId } })
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.common-detail-wrapper {
	&.hide {
		display: none;
	}
}
</style>
