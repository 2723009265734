var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isValid
    }
  }, [_vm._v(" " + _vm._s(_vm.isValid ? _vm.tt('입력 완료') : _vm.tt('이름을 입력해주세요')) + " ")])]), _c('div', {
    staticClass: "form-data-wrap"
  }, [_c('form-text-single-line', {
    attrs: {
      "name": '카테고리 이름',
      "value": _vm.categoryInfoName,
      "placeholder": '카테고리 이름을 입력해 주세요',
      "errorMessage": '이름 입력해주세요!!!',
      "required": true
    },
    on: {
      "input": function input(value) {
        return _vm.inputFormValue(value);
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }