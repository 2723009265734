var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Table__pagination"
  }, [_c('ul', {
    staticClass: "pagination"
  }, [_c('li', {
    staticClass: "first",
    "class": _vm.firstDisabled ? 'disabled' : '',
    on: {
      "click": _vm.firstPage
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/pagination-arrow.svg")
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/svg/pagination-arrow.svg")
    }
  })]), _c('li', {
    staticClass: "prev",
    "class": _vm.prevDisabled ? 'disabled' : '',
    on: {
      "click": _vm.prevPage
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/pagination-arrow.svg")
    }
  })]), _vm._l(_vm.pagination, function (page) {
    return _c('li', {
      key: page,
      "class": page == _vm.current ? 'active' : '',
      on: {
        "click": function click($event) {
          return _vm.currentPage(page);
        }
      }
    }, [_vm._v(" " + _vm._s(page) + " ")]);
  }), _c('li', {
    staticClass: "next",
    "class": {
      disabled: _vm.nextDisabled || _vm.maxPage == 0
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/pagination-arrow.svg")
    }
  })]), _c('li', {
    staticClass: "last",
    "class": {
      disabled: _vm.lastDisabled || _vm.maxPage == 0
    },
    on: {
      "click": _vm.lastPage
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/pagination-arrow.svg")
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/svg/pagination-arrow.svg")
    }
  })])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }