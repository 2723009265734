import {
	lookupLicense,
	lookupLicenseBasic,
	lookupLicenseManagement,
	lookupLicenseRatePlan,
	purchaseLicenseBasic,
	additionMonth,
	cancelWaitingLicense,
	lookupLicenseHistory,
	getAdditionQuantity,
	getAdditionMonth,
	additionQuantity,
	subscriptBasicLicense,
	lookupLicenseInfo4Subscription,
	updateLicenseSubscription,
	unsubscribeLicense,
	lookupAuthEdu,
	requestFreetier,
} from '@/services/api/institute/license'
import cookies from 'vue-cookies'
import { Constant } from '@/utils/define/Constant'
import { lookupPreviousEbillInfo } from '@/services/api/payment'
import { COOKIES_DOMAIN } from '@/services/api/common'
import purchase from './purchase'
const key_instituteId = 'instituteId'

cookies.config({
	domain: COOKIES_DOMAIN,
})

const index = {
	namespaced: true,
	modules: {
		purchase,
	},
	state: () => ({
		status: {
			ACTIVATE: 'ACTIVATE',
			EXPIRED: 'EXPIRED',
			FULL: 'FULL',
		},
		type: {
			TRIAL: 'TRIAL',
			FREE_TIER: 'FREE_TIER',
			STANDARD: 'STANDARD',
			BASIC: 'BASIC',
			LITE_EDU: 'LITE_EDU',
			EDUCATION: 'EDUCATION',
		},
		paymentMethod: {
			FREE: 'FREE', // 첫 결제(결제 한 적이 없음)
			CARD_REGISTERED: 'CARD_REGISTERED', // 등록된 카드 or 정기 결제
			CARD_INICIS: 'CARD_INICIS', // 신용카드(이니시스)
			ELECTRONIC_BILL: 'ELECTRONIC_BILL', // 전자세금계산서
		},
		license: {},
		licenseManagement: {
			schedule: {},
			subscription: {},
		},
		licenseRatePlan: { YEAR: { STANDARD: 50000, BASIC: 35000, EDUCATION: 300000 }, MONTH: { STANDARD: 60000, BASIC: 40000 } },
		licensePurchase: {},
		purchaseCompleteInfo: {},
		MAX_QUANTITY: 99999,
		MAX_MONTH: 999,
		EBILL_DUE_TIME: Constant.ebillDepositDueTime,
		purchaseForm: {},
		licenseHistory: [],
		authEdu: {
			certEduDatetime: null,
			phone: null,
		},
		isMonthly: false,
	}),
	getters: {
		isLicenseActivate: state => state.license.status === state.status.ACTIVATE,
		isLicenseDepositWait: state => state.license.isDepositWait,
		isFreeTier: state => state.license.type === state.type.FREE_TIER,
		isTrial: state => state.license.type === state.type.TRIAL,
		isEducation: state => state.license.type === state.type.EDUCATION,
		hasPaymentMethod: state => state.license.paymentMethod !== state.paymentMethod.FREE && state.license.paymentMethod !== null,
		hasPaymentStatus: state => state.license.paymentStatue !== null,
		beingSubscription: state => Object.keys(state.licenseManagement.subscription).length > 0,
		equalLicenseCount: state => {
			const { license, licenseManagement } = state
			return license.quantity === licenseManagement.subscription.quantity
		},
		hasLicenseHistory: state => state.licenseHistory.length > 1,
		getLicenseHistory: state => state.licenseHistory,
		hasSubscriptionError: state =>
			Object.keys(state.licenseManagement.subscription).length > 0 && state.licenseManagement.subscription.errorCode !== null,
		getPurchasePrice: state => state.licenseRatePlan[state.isMonthly ? 'MONTH' : 'YEAR'][state.license.type],
		getAdditonalPrice: state => {
			if (state.license.month >= 12) {
				return state.licenseRatePlan['YEAR'][state.license.type]
			} else return state.licenseRatePlan['MONTH'][state.license.type]
		},
		getCompletePurchasePrice: state => state.licenseRatePlan[state.isMonthly ? 'MONTH' : 'YEAR'][state.licensePurchase.plan],
		isCertEdu: state => state.license.type === state.type.LITE_EDU && state.license.isCertEdu,
		isFreetierOrLite: state => [state.type.FREE_TIER, state.type.BASIC, state.type.LITE_EDU].indexOf(state.license.type) >= 0,
		isBasic: state => [state.type.BASIC, state.type.LITE_EDU].indexOf(state.license.type) >= 0,
	},
	mutations: {
		setLicense: (state, license) => (state.license = { ...license }),
		setLicenseManagement: (state, licenseManagement) => (state.licenseManagement = { ...licenseManagement }),
		setLicenseRatePlan: (state, ratePlan) => (state.licenseRatePlan = { ...ratePlan }),
		setPurchaseInfo: (state, licensePurchase) => (state.licensePurchase = { ...licensePurchase }),
		initPurchaseForm: state => (state.purchaseForm = {}),
		savePurchaseForm: (state, form) => (state.purchaseForm = { ...form }),
		setEbillId: (state, ebillId) => (state.purchaseForm.ebillId = ebillId),
		setLicenseHistory: (state, history) => (state.licenseHistory = [...history]),
		setPurchaseCompleteInfo: (state, purchaseCompleteInfo) => (state.purchaseCompleteInfo = { ...purchaseCompleteInfo }),
		setCardId: (state, cardId) => (state.purchaseForm.cardId = cardId),
		setAuthEdu: (state, authEdu) => (state.authEdu = { ...authEdu }),
		setPhoneNumber: (state, phone) => (state.purchaseForm.phone = phone),
		setMonthly: (state, value) => (state.isMonthly = value),
	},
	actions: {
		loadLicense: async ({ commit }) => {
			const instId = cookies.get(key_instituteId)
			const response = await lookupLicense(instId)
			commit('setLicense', response.data)
		},
		loadLicenseManagement: ({ commit }) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await lookupLicenseManagement(instId)
				const data = response.data
				commit('setLicenseManagement', data)
				resolve()
			}),
		loadLicenseRatePlan: ({ commit }) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await lookupLicenseRatePlan(instId)
				commit('setLicenseRatePlan', response.data)
				resolve()
			}),
		loadLicensePurchaseInfo: ({ commit }, payload) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await lookupLicenseBasic(instId, payload)
				commit('setPurchaseInfo', response.data)
				resolve()
			}),
		purchaseLicenseBasic: ({ state, commit }, payload) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				try {
					commit('setPurchaseInfo', Object.assign(state.purchaseForm, payload))
					const response = await purchaseLicenseBasic(instId, state.purchaseForm)

					commit('setPurchaseCompleteInfo', response.data)
					resolve(response)
				} catch (error) {
					reject(error.response)
				}
			}),
		additionMonth: async ({ state, commit, rootGetters }, payload) => {
			commit('setPurchaseInfo', Object.assign(state.purchaseForm, payload))
			const response = await additionMonth(rootGetters.instituteId, state.purchaseForm)
			commit('setPurchaseCompleteInfo', response.data)
		},
		additionQuantity: async ({ state, commit, rootGetters }, payload) => {
			commit('setPurchaseInfo', Object.assign(state.purchaseForm, payload))
			const response = await additionQuantity(rootGetters.instituteId, state.purchaseForm)
			commit('setPurchaseCompleteInfo', response.data)
		},
		cancelWaitingLicense: () =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await cancelWaitingLicense(instId)
				resolve(response)
			}),
		lookupPreviousEbillInfo: () =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await lookupPreviousEbillInfo(instId)
				resolve(response)
			}),
		loadLicenseHistory: ({ commit }) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await lookupLicenseHistory(instId)
				commit('setLicenseHistory', response.data)
				resolve(response)
			}),
		getAdditionQuantity: async ({ commit, rootGetters }, payload) => {
			const response = await getAdditionQuantity(rootGetters.instituteId, payload)
			commit('setPurchaseInfo', response.data)
		},
		getAdditionMonth: async ({ commit, rootGetters }, payload) => {
			const response = await getAdditionMonth(rootGetters.instituteId, payload)
			commit('setPurchaseInfo', response.data)
		},
		subscriptBasicLicense: ({ commit }, payload) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await subscriptBasicLicense(instId, payload)
				resolve(response)
			}),
		loadLicenseInfo4Subscription: async ({ commit }, payload) => {
			const instId = cookies.get(key_instituteId)
			const response = await lookupLicenseInfo4Subscription(instId, payload)
			commit('setPurchaseInfo', response.data)
		},
		updateLicenseSubscription: ({ commit }, payload) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await updateLicenseSubscription(instId, payload)
				resolve(response)
			}),
		unsubscribeLicense: ({ commit }) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await unsubscribeLicense(instId)
				resolve(response)
			}),
		loadAuthEdu: ({ commit }) =>
			new Promise(async (resolve, reject) => {
				const instId = cookies.get(key_instituteId)
				const response = await lookupAuthEdu(instId)
				commit('setAuthEdu', response.data)
				resolve(response.data)
			}),
		requestFreetier: async ({ rootGetters }) => {
			const response = await requestFreetier(rootGetters.instituteId)
			return response
		},
	},
}
export default index
