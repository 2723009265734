<template>
	<tr>
		<td>
			<button class="checkbox" @click="checkBoxClick" :class="{ active: isClick }"></button>
		</td>
		<td @click="showInfoModal">
			<div class="info">
				<img v-if="item.image" :src="item.image" />
				<img v-else src="@/assets/svg/member-photo-default.svg" />
				<div>
					<h6 v-text="item.name">
						<img v-if="item.isNew && !isClicked" src="@/assets/svg/new.svg" />
					</h6>
					<p>{{ item.email }}</p>
				</div>
			</div>
		</td>
		<td @click="showInfoModal">
			<span v-if="item.groups.length == 1">{{ item.groups[0].name }}</span>
			<span v-else-if="item.groups.length > 1" v-text="tt('${1} 외 ${2}개 그룹', item.groups[0].name, item.groups.length - 1)"></span>
		</td>
		<td @click="showInfoModal">
			<span v-for="(permission, index) in item.permissions" :key="permission.key">
				<span v-if="permission.name != null"> {{ permission.name }}<span v-if="index != item.permissions.length - 1">, </span> </span>
			</span>
		</td>
		<td>
			<button class="more Dropdown__btn active" v-if="userPermission.institute.member.update || userPermission.institute.member.delete">
				<img src="@/assets/svg/member-more-vertical.svg" alt="more" />
				<div class="Dropdown__menu active">
					<button @click="showUpdateModal" v-if="userPermission.institute.member.update">
						{{ tt('정보편집') }}
					</button>
					<button @click="showPermissionModal" v-if="userPermission.institute.member.update">
						{{ tt('권한관리') }}
					</button>
					<button @click="showExportModal" v-if="userPermission.institute.member.delete">
						{{ tt('내보내기') }}
					</button>
				</div>
			</button>
		</td>
	</tr>
</template>

<script>
import { isClickedInstitution } from '@/store/modules/isClickedItem'

export default {
	name: 'MemberItem',
	props: {
		item: {
			type: Object,
			default: null,
		},
		allCheck: {
			type: Boolean,
			default: false,
		},
		checkList: {
			type: Array,
			default: [],
		},
	},
	mounted() {
		this.isClickedItem(this.item.id)
	},
	data() {
		return {
			isActive: false,
			isClick: false,
			isClicked: false,
		}
	},
	computed: {},
	watch: {
		allCheck: function () {
			if (this.allCheck) {
				this.isClick = true
			} else {
				this.isClick = false
			}
		},
		isClick: function () {
			if (this.isClick) {
				this.$emit('checkId', this.item.id)
			} else {
				this.$emit('uncheckId', this.item.id)
			}
		},
		checkList: function () {
			if (this.checkList.length == 0) {
				this.isClick = false
			}
		},
	},
	methods: {
		checkBoxClick() {
			if (this.isClick) {
				this.isClick = false
			} else {
				this.isClick = true
			}
		},
		showUpdateModal() {
			this.$emit('updateMember', this.item.id)
		},
		showPermissionModal() {
			this.$emit('updatePermission', this.item.id)
		},
		showExportModal() {
			this.$emit('exportMember', this.item.id)
		},
		async showInfoModal() {
			await this.click()
			await this.$emit('infoMember', this.item.id)
		},
		click() {
			if (this.item.isNew && !isClickedInstitution['member'].includes(this.item.id)) {
				isClickedInstitution['member'].push(this.item.id)
			}

			localStorage.setItem('member', isClickedInstitution['member'].join())
		},
		isClickedItem(id) {
			let result = false
			let clickedList = localStorage.getItem('member') ? localStorage.getItem('member').split(',') : []
			if (clickedList.includes(id)) {
				result = true
			}
			this.isClicked = result
			return result
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_member.scss';
</style>
