<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="Modal ModalMSDSFileChange Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt('업로드된 MSDS 파일변경') }}
					</h5>
					<button class="Popup__close-btn" @click="cancel">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text" v-html="tt('정말로 업로드된 MSDS의 파일을 대체하시겠습니까?<br>기존 파일은 사라집니다')"></p>
				</div>

				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="cancel">
							{{ tt('취소') }}
						</button>
						<button class="Btn__edit">
							<input type="file" accept=".pdf" ref="changeFile" @change="changeFile" />{{ tt('파일변경') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalMSDSFileChange',
	props: {},
	mixins: [MixinModal],
	data() {
		return {
			brandId: null,
			productCode: null,
			inventoryId: null,
		}
	},
	methods: {
		show(brandId, productCode, inventoryId) {
			this.isShow = true
			this.brandId = brandId
			this.productCode = productCode
			this.inventoryId = inventoryId
		},
		cancel() {
			this.$emit('isHide')
		},
		changeFile() {
			const file = this.$refs['changeFile'].files[0]

			if (file != null) {
				this.$emit('changeFile', this.brandId, this.productCode, this.inventoryId, file)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.Btn__edit {
	position: relative;
	input[type='file'] {
		position: absolute;
		width: inherit;
		height: inherit;
		opacity: 0;
		cursor: pointer;
	}
}
</style>
