var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.member ? _c('div', {
    staticClass: "member-wrapper",
    "class": {
      red: _vm.isSelect
    }
  }, [_c('div', {
    staticClass: "data-wrapper member"
  }, [_c('div', {
    staticClass: "data-image-wrapper"
  }, [_vm.member.image ? _c('img', {
    staticClass: "member-image",
    attrs: {
      "src": _vm.member.image
    }
  }) : _c('img', {
    staticClass: "member-image",
    attrs: {
      "src": require("@/assets/svg/migration/member-none.svg")
    }
  })]), _c('span', {
    staticClass: "member-name"
  }, [_vm._v(_vm._s(_vm.member.name))])]), _c('div', {
    staticClass: "data-wrapper email"
  }, [_c('span', {
    staticClass: "member-email"
  }, [_vm._v(_vm._s(_vm.member.email))])]), _c('div', {
    staticClass: "data-wrapper status"
  }, [_c('span', {
    staticClass: "member-status-text",
    "class": {
      red: _vm.isSelect
    }
  }, [_vm._v(_vm._s(_vm.statusText))]), _vm.isSelect ? _c('img', {
    staticClass: "info-image",
    attrs: {
      "src": require("@/assets/svg/migration/icon-migration-member-red.svg")
    }
  }) : _c('img', {
    staticClass: "info-image",
    attrs: {
      "src": require("@/assets/svg/migration/icon-migration-member-green.svg")
    }
  })])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }