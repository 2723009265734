var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card disposalItem",
    "class": [{
      warning: _vm.list.emptyVolume > 0 || _vm.list.overdueExpire > 0
    }, {
      safe: _vm.list.emptyVolume == 0 && _vm.list.overdueExpire == 0
    }, {
      displayOff: !_vm.displayCard
    }]
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._m(0), _c('h4', {
    staticClass: "Btn__hover-balloon-w",
    on: {
      "click": function click($event) {
        return _vm.goPage();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('폐기대상 화학물질')) + " "), _c('div', {
    staticClass: "balloon"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('폐기해야할 물품 목록으로 이동합니다')))])]), _vm._m(1)]), _vm.setting == false ? _c('h3', {
    staticClass: "ml-auto",
    domProps: {
      "textContent": _vm._s(_vm.list.emptyVolume > 0 || _vm.list.overdueExpire > 0 ? _vm.tt('${1}개 폐기 필요', _vm.list.emptyVolume + _vm.list.overdueExpire) : _vm.tt('안전'))
    }
  }) : _c('div', {
    staticClass: "ml-auto switch-setting"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('대시보드에 노출')))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.displayCard,
      expression: "displayCard"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.displayCard) ? _vm._i(_vm.displayCard, null) > -1 : _vm.displayCard
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.displayCard,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.displayCard = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.displayCard = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.displayCard = $$c;
        }
      }, function ($event) {
        return _vm.toggleCard(_vm.id, _vm.displayCard);
      }]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('div', {
    staticClass: "card-noti"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('폐기 현황')))]), _vm.list.emptyVolume > 0 || _vm.list.overdueExpire ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('보유 물품 중, <span style=color:#ff3b31;>유효기간이 만료되었거나 잔여 용량이 없는 물품</span>을 보여줍니다.'))
    }
  }) : _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('보유 물품 중, <span style=color:#0dc577;>유효기간이 만료되었거나 잔여 용량이 없는 물품</span>이 없습니다.'))
    }
  })]), _c('div', {
    staticClass: "card-box"
  }, [_c('div', {
    staticClass: "box-title"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('폐기해야 할 물품(화학물질)')))])]), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "chart-box"
  }, [_c('GChart', {
    ref: "chart",
    staticClass: "disposalChart",
    attrs: {
      "type": "PieChart",
      "events": _vm.chartEvents,
      "data": _vm.chartData,
      "options": _vm.chartOptions
    },
    on: {
      "ready": _vm.onChartReady
    }
  }), _vm.totalCount > 0 ? _c('div', {
    staticClass: "chart-title"
  }, [_c('h2', {
    staticStyle: {
      "color": "#ff3b31"
    }
  }, [_vm._v(_vm._s(_vm.disposalPercent) + "%")])]) : _c('div', {
    staticClass: "no-data_chart"
  }, [_c('div', {
    staticClass: "balloon"
  }, [_c('span'), _c('p', [_vm._v(_vm._s(_vm.tt('폐기 대상이 아닌 물품')))]), _c('b', [_vm._v("0개 0%")])])])], 1), _c('div', {
    staticClass: "chart-list"
  }, [_c('ul', [_vm._l(_vm.listKeys, function (item, index) {
    return _c('li', {
      key: "item_".concat(index),
      on: {
        "click": function click($event) {
          return _vm.goPage(item);
        }
      }
    }, [_c('div', {
      staticClass: "circle",
      "class": item
    }), _c('p', [_vm._v(_vm._s(_vm.tt(_vm.matchDisposalTitle(item))))]), _c('h5', [_vm._v(_vm._s(_vm.list[item]) + _vm._s(_vm.tt('개')))])]);
  }), _c('li', {
    staticClass: "total"
  }, [_c('p', [_vm._v("Total:")]), _c('h5', [_vm._v(_vm._s(_vm.totalCount) + _vm._s(_vm.tt('개')))])])], 2)])])]), _c('div', [_c('svg', {
    ref: "svg",
    staticStyle: {
      "width": "0",
      "height": "0",
      "position": "absolute"
    },
    attrs: {
      "aria-hidden": "true",
      "focusable": "false"
    }
  }, [_c('pattern', {
    attrs: {
      "id": "strike-fill",
      "x": "0",
      "y": "0",
      "width": "4",
      "height": "4",
      "patternUnits": "userSpaceOnUse",
      "patternTransform": "rotate(30)"
    }
  }, [_c('rect', {
    staticStyle: {
      "stroke": "none",
      "fill": "#ff3b31"
    },
    attrs: {
      "x": "0",
      "y": "0",
      "width": "2",
      "height": "4"
    }
  })])]), _c('svg', {
    ref: "svg",
    staticStyle: {
      "width": "0",
      "height": "0",
      "position": "absolute"
    },
    attrs: {
      "aria-hidden": "true",
      "focusable": "false"
    }
  }, [_c('pattern', {
    attrs: {
      "id": "dot-fill",
      "x": "0",
      "y": "0",
      "width": "10",
      "height": "10",
      "patternUnits": "userSpaceOnUse",
      "patternTransform": "rotate(30)"
    }
  }, [_c('rect', {
    attrs: {
      "width": "10",
      "height": "10",
      "x": "0",
      "y": "0",
      "fill": "#ff3b31"
    }
  }), _c('circle', {
    attrs: {
      "cx": "2",
      "cy": "2",
      "r": "2",
      "fill": "white"
    }
  })])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/dashboard-icon1.svg"),
      "alt": "icon"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow-diagonal.svg"),
      "alt": "icon"
    }
  })]);
}]

export { render, staticRenderFns }