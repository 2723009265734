var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('물품 소분 하기')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('modal-footer', {
          attrs: {
            "okText": "소분하기",
            "isOkDisabled": _vm.noItem
          },
          on: {
            "cancel": function cancel($event) {
              return _vm.$refs['modal'].hide();
            },
            "ok": _vm.divide
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "body-wrap"
  }, [_c('divide-menu', {
    on: {
      "addDivide": _vm.addDivide,
      "selectStorage": function selectStorage($event) {
        _vm.$refs['storage-selector'].show();

        _vm.setSelectedNode(null);
      }
    }
  }), _c('div', {
    staticClass: "table-wrap"
  }, [_c('divide-table-header'), _vm.noItem ? _c('div', {
    staticClass: "table-body"
  }, [_c('div', {
    staticClass: "no-item"
  }, [_c('img', {
    attrs: {
      "width": "69",
      "height": "80",
      "src": require("@/views/inventoryGen2/svg/detail/divide-no-item-icon.svg")
    }
  }), _c('span', {
    staticClass: "nodata-msg"
  }, [_vm._v(_vm._s(_vm.tt('소분할 용량과 개수를 입력해주세요')))]), _c('div', {
    staticClass: "button-wrap"
  }, [_c('button', {
    staticClass: "button-normal",
    on: {
      "click": _vm.addDivide
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/detail/icon-subdivide.svg"),
      "width": "24",
      "height": "24"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('소분 용량 입력')) + " ")])])])])]) : _vm._e(), _vm._l(_vm.divideItems, function (item, idx) {
    return _c('divide-table-item', {
      key: item.divideKeyNo,
      attrs: {
        "idx": idx,
        "item": item
      },
      on: {
        "removeItem": function removeItem($event) {
          return _vm.removeItem(idx);
        },
        "showManage": function showManage($event) {
          return _vm.showManage(idx);
        }
      }
    });
  })], 2)], 1), _c('modal-add-divide', {
    ref: "modal-add-divide",
    attrs: {
      "item": _vm.item,
      "divideItemsVolume": _vm.divideItemsVolume
    },
    on: {
      "complete": function complete(data) {
        return _vm.addDivideItem({
          detailItem: _vm.item,
          data: data
        });
      }
    }
  }), _c('modal-single-select-storage', {
    ref: "storage-selector",
    attrs: {
      "title": "보관함 지정"
    },
    on: {
      "complete": _vm.selectStorage
    }
  }), _c('modal-item-management', {
    ref: "modal-item-management",
    attrs: {
      "title": "관리 및 추가정보",
      "forDivide": ""
    },
    on: {
      "complete": _vm.completeManagement
    }
  }), _c('modal-textarea', {
    ref: "modal-dispose",
    attrs: {
      "title": "잔여 용량 없음",
      "titleTextarea": "폐기 사유",
      "headerText": "소분 후 잔여 잔여 용량 없습니다.<br/>기존 물품을 폐기하고, 소분을 완료하시겠습니까? ",
      "useMiddleBtn": "",
      "middleBtnText": "폐기없이 소분 완료",
      "confirmText": "폐기하고 소분 완료",
      "placeholder": "폐기 사유를 입력해주세요(100자 제한)",
      "variant": "warning",
      "type": "DISPOSE"
    },
    on: {
      "clickMiddle": function clickMiddle(memo) {
        return _vm.modalDisposeDivide(false, memo);
      },
      "complete": function complete(memo) {
        return _vm.modalDisposeDivide(true, memo);
      }
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }