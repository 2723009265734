<template>
	<div class="modals">
		<ModalLicenseManage ref="ModalLicenseManage" @addMember="addMember" @addDate="addDate" @manageSubscribe="manageSubscribe" />
		<ModalLicenseSubscribeFail ref="ModalLicenseSubscribeFail" @manageSubscribe="manageSubscribe" />
		<ModalLicenseSubscribe ref="ModalLicenseSubscribe" @complete="purchaseComplete" />
		<ModalLicensePurchase ref="ModalLicensePurchase" @complete="purchaseComplete" />
		<ModalLicenseAddMember ref="ModalLicenseAddMember" @complete="purchaseComplete" />
		<ModalLicenseAddDate ref="ModalLicenseAddDate" @complete="purchaseComplete" />
		<ModalPurchaseComplete ref="ModalPurchaseComplete" />
	</div>
</template>
<script>
import ModalLicenseManage from './ModalLicenseManage.vue'
import ModalLicenseSubscribe from './ModalLicenseSubscribe.vue'
import ModalLicenseSubscribeFail from './ModalLicenseSubscribeFail.vue'
import ModalLicensePurchase from './ModalLicensePurchase.vue'
import ModalLicenseAddMember from './ModalLicenseAddMember.vue'
import ModalLicenseAddDate from './ModalLicenseAddDate.vue'
import ModalPurchaseComplete from './ModalPurchaseComplete.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
export default {
	components: {
		ModalLicenseSubscribe,
		ModalLicenseSubscribeFail,
		ModalLicenseManage,
		ModalLicensePurchase,
		ModalPurchaseComplete,
		ModalLicenseAddMember,
		ModalLicenseAddDate,
	},
	mixins: [MixinLicense, MixinPayment],
	data() {
		return {
			confirmFn: null,
			completeText: '확인',
		}
	},
	methods: {
		showModalManagement() {
			this.hideAll()
			if (this.beingSubscription && this.licenseManagement.subscription.errorCode != null) {
				this.$refs['ModalLicenseSubscribeFail'].show()
			} else {
				this.$refs['ModalLicenseManage'].show()
			}
		},
		showModalPurchase(confirmFn, completeText) {
			if (this.isLicenseDepositWait) {
				this.$root.toast('라이선스 구매', '입금 대기 중인 구매 건이 있습니다.', 'error')
				return
			}
			this.completeText = completeText || '확인'
			this.confirmFn = confirmFn
			this.$refs['ModalLicensePurchase'].show()
		},
		addMember() {
			this.$refs['ModalLicenseAddMember'].show()
		},
		addDate() {
			this.$refs['ModalLicenseAddDate'].show()
		},
		manageSubscribe() {
			this.$refs['ModalLicenseSubscribe'].show()
		},
		async purchaseComplete() {
			this.hideAll()
			await this.loadLicense()
			this.$refs['ModalPurchaseComplete'].show()
		},
		hideAll() {
			Object.values(this.$refs).forEach(ref => {
				console.log(ref)
				if (ref.hide) {
					ref.hide()
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped></style>
