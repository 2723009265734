<template>
	<div class="detail-wrap" v-if="item">
		<detail-header :item="item" @updateFavorite="updateFavorite"></detail-header>
		<component v-bind:is="activeBadge" :item="item" :legals="legals"></component>
		<div class="register-info-wrap">
			<div class="info-title">{{ tt('물품정보') }}</div>
			<properties-info v-if="isCategoryChemical" :item="item"></properties-info>
			<safety-info
				v-if="hasMsds || !!item.ghs"
				:msds="item.msds"
				:ghs="item.ghs"
				@manageGHS="manageGHS"
				@uploadFile="getDetail($route.params.id)"
				@changeFile="getDetail($route.params.id)"
				@createGHS="$refs['make-ghs'].show()"
			></safety-info>
			<member-info :item="item"></member-info>
			<product-info :item="item" :inventoryFieldsInfo="inventoryFields"></product-info>
			<management-info :item="item" :managementFields="managementFields"></management-info>
			<custom-info v-if="hasCustomFields" :item="item" :customFields="customFields" :customFieldsData="customFieldsData"></custom-info>
			<ingredient-info :ingredient="item && item.ingredientList"></ingredient-info>
			<legal-info v-if="!isBasic" :legals="legals" :ingredientList="item.ingredientList" :casno="item.casno"></legal-info>
		</div>
		<div class="manage-info-wrap">
			<div class="info-title">
				{{ tt('물품관리 기록') }}
				<!-- <button class="gray-button">{{ tt('목록 다운로드') }}</button> -->
			</div>
			<div class="filter-wrap">
				<div class="filter-wrap-header">
					<div class="bold-text">
						{{ tt('상세검색') }}
					</div>
					<div class="text">
						{{ tt('물품관리 기록에서 조회할 항목을 검색하거나 선택할 수 있습니다') }}
					</div>
				</div>
				<history-info :item="item"></history-info>
			</div>
		</div>

		<detail-footer
			v-if="!item.isDel && !item.isDispose"
			:item="item"
			:isShowGHSButton="isCategoryChemical"
			@makeGHS="makeGHS"
			@manageGHS="manageGHS"
		></detail-footer>
		<modal-confirm
			ref="modal-delete-ghs"
			variant="warning"
			title="GHS 라벨 삭제"
			content="GHS 라벨을 삭제하면 브랜드, 제품번호가 동일한 모든 물품의 GHS라벨이 삭제됩니다. 삭제 하시겠습니까?"
			confirmText="삭제"
			@confirm="deleteGHS"
		/>
		<modal-make-ghs
			ref="make-ghs"
			:msds="item.msds"
			@showGuide="$refs['guide'].show()"
			@goNext="
				$refs['make-ghs'].hide()
				$refs['ghs-form'].show()
			"
			@uploadMSDS="uploadMsdsFile"
		/>
		<modal-ghs-form
			ref="ghs-form"
			@goNext="showPreviewGHS"
			@goPrev="
				$refs['make-ghs'].show('prev')
				$refs['ghs-form'].hide()
			"
		></modal-ghs-form>
		<modal-ghs-preview
			ref="ghs-preview"
			@goPrev="
				$refs['ghs-preview'].hide()
				$refs['ghs-form'].show()
			"
			@complete="completeCreateGHS"
		></modal-ghs-preview>
		<modal-ghs-label ref="ghs-label" :item="item" @edit="editGHS" @delete="showModalDeleteGHS"></modal-ghs-label>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import DetailHeader from '@/views/inventoryGen2/components/detail/header/DetailHeader.vue'
import BadgeListChemical from '@/views/inventoryGen2/components/detail/badge/BadgeListChemical.vue'
import BadgeListDefault from '@/views/inventoryGen2/components/detail/badge/BadgeListDefault.vue'
import PropertiesInfo from '@/views/inventoryGen2/components/detail/propertiesInfo/PropertiesInfo.vue'
import SafetyInfo from '@/views/inventoryGen2/components/detail/safetyInfo/SafetyInfo.vue'
import ManagementInfo from '@/views/inventoryGen2/components/detail/managementInfo/ManagementInfo.vue'
import DetailFooter from '@/views/inventoryGen2/components/detail/footer/DetailFooter.vue'
import ProductInfo from '@/views/inventoryGen2/components/detail/productInfo/ProductInfo.vue'
import CustomInfo from '@/views/inventoryGen2/components/detail/customInfo'
import HistoryInfo from '@/views/inventoryGen2/detail/historyInfo'

import ModalMakeGhs from '@/views/common/components/modalGen2/ghs/ModalMakeGHS.vue'
import ModalGhsForm from '@/views/common/components/modalGen2/ghs/ModalGHSForm.vue'
import ModalGhsPreview from '@/views/common/components/modalGen2/ghs/ModalGHSPreview.vue'
import ModalGhsLabel from '@/views/common/components/modalGen2/ghs/ModalGHSLabel.vue'

import MemberInfo from '@/views/purchase/cargo/components/cargoDetail/productInfo/memberInfo/MemberInfo.vue'
import IngredientInfo from '@/views/purchase/cargo/components/cargoDetail/productInfo/ingredientInfo/IngredientInfo.vue'
import LegalInfo from '@/views/purchase/cargo/components/cargoDetail/productInfo/leaglInfo/LegalInfo.vue'
import { LargeClass } from '@/utils/define/ItemCode'

import MixinModalGhs from '@/mixins/modalGhs/mixinModalGhs'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'InventoryDetail',
	mixins: [MixinModalGhs],
	components: {
		DetailHeader,
		BadgeListDefault,
		PropertiesInfo,
		SafetyInfo,
		MemberInfo,
		IngredientInfo,
		LegalInfo,
		ProductInfo,
		ManagementInfo,
		DetailFooter,
		CustomInfo,
		ModalMakeGhs,
		ModalGhsForm,
		ModalGhsPreview,
		ModalGhsLabel,
		HistoryInfo,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/item', ['item', 'legals', 'inventoryFields', 'managementFields', 'customFields', 'customFieldsData']),
		...mapGetters('license', ['isBasic']),
		isCategoryChemical() {
			return this.item?.category?.classification === LargeClass.CHEMICAL
		},
		hasMsds() {
			return this.isCategoryChemical && Object.values(this.item?.msds).some(i => i)
		},
		hasCustomFields() {
			return this.customFields?.length > 0
		},
		activeBadge() {
			switch (this.item?.category?.classification) {
				case 'CAT_CHEMICAL':
					return BadgeListChemical
				default:
					return BadgeListDefault
			}
		},
		ghsPayload() {
			return this.item?.brand?.id && this.item?.productCode
				? {
						brandId: this.item?.brand?.id,
						productCode: this.item?.productCode,
						inventoryId: this.item?.id,
						ghs: this.ghsData,
				  }
				: {
						inventoryId: this.item?.id,
						ghs: this.ghsData,
				  }
		},
	},
	watch: {
		'$route.params.id': {
			deep: true,
			async handler(to, from) {
				console.log('params.id')
				await this.getDetail(to)
				await this.getCategoryInfo()
				this.getGhsStatement()
				this.getUnitList()
				this.setUploadMsds(null)
				// AppHeader 경로 표시
				this.setStorageDetailPaths([this.item.name])
			},
		},
	},
	async created() {
		await this.getDetail(this.$route.params.id)
		await this.getCategoryInfo()
		this.getGhsStatement()
		this.getUnitList()
		this.setUploadMsds(null)
		// AppHeader 경로 표시
		this.setStorageDetailPaths([this.item.name])
	},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/item', ['setItem']),
		...mapActions('inventoryGen2/item', ['getDetail', 'getCategoryInfo']),
		...mapActions('inventoryGen2/form', ['uploadMsds', 'editStickerType']),
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		...mapActions('inventoryGen2/historyInfo', ['getList']),
		...mapMutations('inventoryGen2/form', ['setFormItem', 'setUploadMsds']),
		...mapActions('inventoryGen2/list', ['addFavorite', 'deleteFavorite']),
		async deleteGHS() {
			const { brandId, productCode, inventoryId } = this.ghsPayload
			if (brandId && productCode) {
				await this.deleteGhsLabel({ brandId: brandId, productCode: productCode, inventoryId: inventoryId })
			} else {
				await this.deleteGhsLabel({ inventoryId: inventoryId })
			}
			// GHS 라벨 삭제시, stickerType : null로 세팅
			await this.setFormItem({ ...this.item, stickerType: '' })
			await this.editStickerType()
			this.$root.toast(`GHS 삭제 완료`, `GHS 라벨이 삭제되었습니다`, ToastType.SUCCESS)
			this.$refs['ghs-label'].hide()
			await this.getDetail(this.$route.params.id)
			await this.getList()
		},
		manageGHS() {
			this.$refs['ghs-label'].show()
			this.setPreviewData(this.item)
		},
		async completeCreateGHS() {
			this.$refs['ghs-preview'].hide()
			this.$refs['ghs-label'].show()

			let text = this.mode === 'EDIT' ? '편집' : '생성'
			await this.uploadGhsLabel(this.ghsPayload)
			this.$root.toast(`GHS ${text} 완료`, `GHS 라벨 ${text}이 완료되었습니다`, ToastType.SUCCESS)
			await this.getDetail(this.$route.params.id)
			await this.getList()
		},
		showModalDeleteGHS() {
			this.$refs['modal-delete-ghs'].show()
		},
		async uploadMsdsFile(file) {
			await this.uploadMsds({
				inventoryId: this.item?.id,
				brandId: this.item?.brand?.id,
				productCode: this.item?.productCode,
				file: file,
			})
			await this.getDetail(this.$route.params.id)
			await this.getList()
		},
		async updateFavorite(type) {
			if (type === 'add') await this.addFavorite(this.item.id)
			else await this.deleteFavorite(this.item.id)
			await this.getDetail(this.$route.params.id)
		},
	},
	beforeDestroy() {
		this.setItem(null)
	},
}
</script>

<style lang="scss" scoped>
.detail-wrap {
	display: flex;
	flex-direction: column;
	height: auto !important;
	margin-bottom: 60px;
	min-width: 1080px;
	.register-info-wrap {
		padding: 20px 30px;
		.info-title {
			display: flex;
			height: 50px;
			font-size: 24px;
			font-weight: 700;
			padding-bottom: 15px;
			border-bottom: 1px solid black;
		}
	}

	.manage-info-wrap {
		padding: 20px 30px;
		justify-content: space-between;
		.info-title {
			width: 100%;
			display: flex;
			height: 50px;
			font-size: 24px;
			font-weight: 700;
			padding-bottom: 15px;
			justify-content: space-between;
			border-bottom: 1px solid black;
		}
	}
}

.filter-wrap {
	.filter-wrap-header {
		display: flex;
		padding: 30px 0 15px 0;
		align-items: center;
		.bold-text {
			font-size: 16px;
			font-weight: 700;
			margin-right: 10px;
		}
		.text {
			font-size: 14px;
			font-weight: 400;
			color: $SECONDARY_1;
		}
	}
}
</style>
