<template>
	<b-modal centered scrollable ref="modal" no-close-on-backdrop no-fade @close="e => cancel(e)">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<item-form-counter title="등록할 수량" v-if="isTypeImage" :value="multiCreateCount"></item-form-counter>
			<item-form-management ref="form-mng"> </item-form-management>
			<item-form-custom ref="form-custom" v-if="hasCustomFields"></item-form-custom>
			<item-form-select-sticker v-if="!isTypeImage && !forDivide"></item-form-select-sticker>
		</template>
		<template #modal-footer>
			<modal-confirm
				ref="modal-confirm"
				title="편집 취소"
				content="취소하면 편집된 정보가 적용되지 않습니다. 편집을 취소하시겠습니까?"
				confirmText="편집 취소"
				@confirm="hide()"
			></modal-confirm>
			<modal-footer :isPrev="!forDivide" @goPrev="goPrev" @cancel="cancel" @complete="clickComplete"></modal-footer>
		</template>
		<modal-loading ref="modal-loading" title="등록 중입니다." />
	</b-modal>
</template>
<script>
import ModalFooter from '@/views/common/components/modals/ModalFooter.vue'
import FormCounter from '@/views/common/components/form/FormCounter.vue'
import ItemFormCounter from '@/views/inventoryGen2/components/regist/form/ItemFormCounter.vue'
import ItemFormManagement from '@/views/inventoryGen2/components/regist/form/ItemFormManagement.vue'
import ItemFormCustom from '@/views/inventoryGen2/components/regist/form/ItemFormCustom.vue'
import ItemFormSelectSticker from '@/views/inventoryGen2/components/regist/form/ItemFormSelectSticker.vue'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { LargeClass, RegistType } from '@/utils/define/ItemCode'
import PageName from '@/utils/define/PageName'
import ModalLoading from '@/views/common/components/modalGen2/ModalLoading.vue'
export default {
	name: 'ModalItemManagement',
	components: { ModalFooter, FormCounter, ItemFormCounter, ItemFormManagement, ItemFormCustom, ItemFormSelectSticker, ModalLoading },
	props: {
		title: {
			type: String,
			default: '',
		},
		forDivide: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapState('inventoryGen2/form', ['inspectId', 'registMethod']),
		...mapGetters('inventoryGen2/form', [
			'customFields',
			'isActiveExpireDate',
			'expirationDay',
			'item',
			'isManagementValid',
			'isCustomValid',
			'registMethod',
			'category',
			'itemFormDataByDirect',
			'isRequireGhs',
			'ghs',
			'hasChangedStickerType',
			'itemFormType',
			'isManagementUnitValid',
		]),
		...mapGetters('modal/ghs', ['ghsData']),
		isTypeImage() {
			return this.registMethod === 'AUTO_IMAGE'
		},
		hasCustomFields() {
			return this.customFields?.length > 0
		},
		isGhs() {
			return !!this?.ghs
		},
		multiCreateCount() {
			return this.item?.multiCreateCount ?? 1
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('tag', ['setSelectedTag']),
		...mapMutations('inventoryGen2/form', ['initItem', 'setShowError', 'setFormItem', 'setGhs', 'editExcelListItem']),
		...mapActions('inventoryGen2/item', ['getDetail']),
		...mapActions('inventoryGen2/form', [
			'registItemByImage',
			'registItemByDirect',
			'getIsRequireGhs',
			'getGhs',
			'editInspectItem',
			'registInspectItem',
			'editItem',
		]),
		...mapActions('inventoryGen2/historyInfo', ['getList']),
		async show() {
			this.$refs['modal'].show()
			if (this.category?.classification === LargeClass.CHEMICAL) {
				if (this.item?.casno) {
					await this.getIsRequireGhs()
				}
				if (this.itemFormType === 'NORMAL_EDIT') {
					// TODO: normal_edit이 아닐때 setFormItem을 해줬던 이유가 뭔지...파악하기 (11월 16일 lsh님 커밋 참고하기 - 물품 상세 버그 fix )
					this.setFormItem({ ...this.item, multiCreateCount: 1 })
					if (!this.ghs) {
						await this.getGhs({ brandId: this.item?.brand?.id, productCode: this.item?.productCode })
						if (!this.hasChangedStickerType) {
							this.setFormItem({ ...this.item, stickerType: this.ghs ? 'GHS+QR' : null, ingredient: this.item.ingredient })
						}
					}
				} else {
					this.setSelectedTag([])
					if (this.isActiveExpireDate) {
						this.setFormItem({ ...this.item, expireDatetime: this.$DateTime.now().plus({ days: this.expirationDay }).toISODate() })
					}
				}
				if (this.item?.ghs && this.itemFormType === 'NORMAL_EDIT') {
					this.setGhs(this.item?.ghs)
				}
			}
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cancel(e) {
			if (this.itemFormType === 'NORMAL_EDIT' || this.itemFormType === 'EXCEL_EDIT') {
				this.$refs['modal-confirm'].show()
				e?.preventDefault()
				return
			}
			this.initItem()
			this.hide()
		},
		async clickComplete() {
			try {
				const isInvalid = !(this.isManagementValid && this.isManagementUnitValid && this.isCustomValid)
				const hasMultiCreateCount = this.item?.multiCreateCount > 0
				if (isInvalid || !hasMultiCreateCount) {
					this.setShowError(isInvalid || !hasMultiCreateCount)
					setTimeout(() => {
						const errorEl = document.getElementsByClassName('error')?.[0]
						const scrollBody = document.getElementsByClassName('modal-body')?.[0]
						const offset = errorEl.offsetTop
						scrollBody.scrollTo(0, offset - 120)
					}, 200)
				} else {
					if (this.forDivide) {
						this.hide()
						this.$emit('complete', this.$_.cloneDeep(this.item))
						return
					}
					this.$refs['modal-loading'].show()
					let response
					switch (this.itemFormType) {
						case 'NORMAL_REGIST':
							// 일반 등록
							switch (this.registMethod) {
								case RegistType.AUTO.IMAGE:
									// 사진 등록
									await this.registItemByImage()
									break
								case RegistType.MANUAL.DIRECT:
								case RegistType.MANUAL.SEARCH:
								case RegistType.MANUAL.QR:
									// 수기/DB 등록
									await this.registItemByDirect({
										formData: this.itemFormDataByDirect,
										ghsData: this.ghs ?? null,
										registMethod: this.registMethod,
									})
									break
								default:
									break
							}
							this.$root.toast('등록 완료', '물품이 수기등록되었습니다', 'success')
							break
						case 'NORMAL_EDIT':
							// 일반 - 상세 편집
							await this.editItem()
							await this.getDetail(this.item.id)
							await this.getList()
							this.$root.toast('편집 완료', '물품이 편집되었습니다', 'success')
							this.initItem()
							break
						case 'NORMAL_COPY':
							response = await this.registItemByDirect({
								formData: this.itemFormDataByDirect,
								ghsData: this.ghs ?? null,
								registMethod: 'MANUAL_COPY',
							})
							this.$root.toast('등록 완료', '물품이 복제 등록되었습니다.', 'success')
							break
						case 'INSPECT_REGIST':
							// 등록대기 중 - 수기 등록
							await this.editInspectItem()
							await this.registInspectItem({
								id: this.inspectId,
							})
							this.initItem()
							this.$root.toast('수기 등록 완료', '물품 등록되었습니다', 'success')
							this.$router.replace({
								name: PageName.InventoryGen2.List,
							})
							break
						case 'EXCEL_EDIT':
							this.editExcelListItem()
							this.$root.toast('관리 및 추가정보 변경', '관리 및 추가정보가 변경되었습니다.', 'success')
							break
						default:
							break
					}
					this.$refs['modal-loading'].hide()
					if (this.itemFormType === 'NORMAL_COPY') {
						this.$emit('complete', response.ids)
					} else this.$emit('complete')

					this.hide()
					this.setShowError(false)
				}
			} catch (e) {
				this.$root.toast(`오류`, `${e.response?.data?.msg ?? e}`, 'error')
				this.$refs['modal-loading'].hide()
			}
		},
		goPrev() {
			this.$emit('goPrev')
			this.hide()
		},
		inputFormValue(value, fieldKey) {
			this.$emit('change', value, fieldKey)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';

::v-deep .modal-dialog {
	max-width: 700px;
	.modal-content {
		background-color: $GREY_1;
		border-radius: 8px;
		border: 0;
		.form-wrap {
			padding: 20px;
			background: white;
			border: 1px solid $DIVIDER;
			border-radius: 8px;
			gap: 20px;
			.form-header {
				font-weight: 700;
				font-size: 16px;
				.form-title {
				}
				.form-msg {
				}
			}
		}
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	padding: 0px 20px;
	border: 0;
	gap: 20px;
	overflow-x: hidden;
}

::v-deep .modal-header {
	padding: 20px;
	border: 0;
}

::v-deep .modal-footer {
	padding: 20px;
	border: 0;
	.modal-footer-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.footer-left,
		.footer-right {
			display: flex;
			gap: 10px;
			button {
				border: 0;
				box-shadow: none;
				display: flex;
				height: auto;
				align-items: center;
				cursor: pointer;
				white-space: pre;
				transition: 0.5s all ease;
				min-width: 100px;
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 700;
				border-radius: 5px;
				justify-content: center;
			}
			.button-white {
				background: $LAB_WHITE;
				border: 1px solid $DIVIDER;
				color: #111 !important;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
						linear-gradient(0deg, #fff, #fff);
				}
			}
			.button-primary {
				background: $LAB_YELLOW;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
				}
			}
		}
	}
}

::v-deep .modal-title {
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	.title {
		color: #111 !important;
	}
}
</style>
