var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.tt('전자세금계산서 결제')) + " ")];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('button', {
          staticClass: "Btn__yellow",
          attrs: {
            "disabled": !_vm.isFormValid
          },
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('전자세금계산서 발급하기')) + " ")])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "input-wrapper"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('결제수단')))]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('상호명'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.nameOfCompany,
      expression: "form.nameOfCompany"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('법인명을 입력해주세요')
    },
    domProps: {
      "value": _vm.form.nameOfCompany
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "nameOfCompany", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.tt('사업자등록번호(10자리)')) + " "), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    "class": {
      error: _vm.registrationNoError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('10자리 사업자등록번호를 입력해주세요'),
      "maxlength": "12"
    },
    domProps: {
      "value": _vm.form.registrationNumber
    },
    on: {
      "input": _vm.inputRegistrationNumber
    }
  }), _vm.registrationNoError ? _c('span', {
    staticClass: "input-error-msg"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('유효하지 않은 사업자등록번호입니다.')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('대표자명'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.nameOfRepresentative,
      expression: "form.nameOfRepresentative"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('대표자 이름을 입력해주세요')
    },
    domProps: {
      "value": _vm.form.nameOfRepresentative
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "nameOfRepresentative", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('이메일'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    "class": {
      error: _vm.emailNoError
    },
    attrs: {
      "type": "email",
      "placeholder": _vm.tt('이메일 입력해주세요')
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _vm.emailNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('이메일을 형식에 맞게 입력해주세요')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('업태')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bizType,
      expression: "form.bizType"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('업태를 입력해주세요')
    },
    domProps: {
      "value": _vm.form.bizType
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "bizType", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('종목')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bizClass,
      expression: "form.bizClass"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('종목을 입력해주세요')
    },
    domProps: {
      "value": _vm.form.bizClass
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "bizClass", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('전화번호')))]), _c('input', {
    "class": {
      error: _vm.phoneNoError
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('연락처를 입력해주세요'),
      "maxlength": "13"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": _vm.autoHypenTel
    }
  }), _vm.phoneNoError ? _c('span', {
    staticClass: "error-text"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('연락처를 형식에 맞게 입력해주세요')) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('주소')))]), _c('AddressInput', {
    attrs: {
      "hasPostcode": false,
      "postcode": _vm.form.postcode,
      "address": _vm.form.address,
      "addressDetail": _vm.form.addressDetail,
      "addressPlaceholder": "주소를 검색해주세요"
    },
    on: {
      "search": _vm.changeAddress
    }
  })], 1), _c('div', {
    staticClass: "noti-box"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("<b class='red'>".concat(_vm.formatDepositExpireDate(), "</b>\uAE4C\uC9C0 \uC785\uAE08\uD558\uC9C0 \uC54A\uC73C\uBA74 \uB77C\uC774\uC120\uC2A4\uAC00 <b class=red>\uBE44\uD65C\uC131\uD654</b>\uB429\uB2C8\uB2E4.")))
    }
  })])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }