import { Check } from '@/utils/define/Translation'
export default {
	computed: {
		lang() {
			if (Check.English) {
				return {
					monthBeforeYear: false,
				}
			}
			return {
				formatLocale: {
					// MMMM
					months: this.monthsLong,
					// MMM
					monthsShort: this.monthsShort,
					// dddd
					weekdays: this.weekdays,
					// ddd
					weekdaysShort: this.weekdaysShort,
					// dd
					weekdaysMin: this.weekdaysMin,
				},
				yearFormat: this.tt('YYYY년'),
				monthBeforeYear: false,
			}
		},
		monthsLong() {
			if (!Check.English) return ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']
			return ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
		},
		monthsShort() {
			if (!Check.English) return ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']
			return ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
		},
		weekdays() {
			if (!Check.English) return ['일', '월', '화', '수', '목', '금', '토']
			return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
		},
		weekdaysShort() {
			if (!Check.English) return ['일', '월', '화', '수', '목', '금', '토']
			return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
		},
		weekdaysMin() {
			if (!Check.English) return ['일', '월', '화', '수', '목', '금', '토']
			return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
		},
		monthObject() {
			if (Check.English)
				return {
					jan: 'jan',
					feb: 'feb',
					mar: 'mar',
					apr: 'apr',
					may: 'may',
					jun: 'jun',
					jul: 'jul',
					aug: 'aug',
					sep: 'sep',
					oct: 'oct',
					nov: 'nov',
					dec: 'dec',
				}
			else
				return {
					jan: '1월',
					feb: '2월',
					mar: '3월',
					apr: '4월',
					may: '5월',
					jun: '6월',
					jul: '7월',
					aug: '8월',
					sep: '9월',
					oct: '10월',
					nov: '11월',
					dec: '12월',
				}
		},
	},
}
