import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'

const routes = [
	{
		name: PageName.Institution.Information,
		path: RoutePath.institute.information,
		props: true,
		component: () => import('@/views/institute2/pages/Information.vue'),
	},
]

export default routes
