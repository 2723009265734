var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "MemberInfoPopup"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _vm.member.id.length > 0 ? _c('div', {
    staticClass: "Memeber__info-popup Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "Member__info-img"
  }, [_vm.hasImg ? _c('img', {
    attrs: {
      "src": _vm.getSrc(_vm.member.image)
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/svg/member-detail-photo.svg"),
      "alt": "member-image"
    }
  })]), _c('h4', {
    staticClass: "Member__info-name"
  }, [_vm._v(_vm._s(_vm.member.name))]), _c('div', {
    staticClass: "Member__info-personal Member__info-content"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('정보')))]), _c('ul', [_c('li', [_c('h6', [_vm._v(_vm._s(_vm.tt('이메일')))]), _c('p', [_vm._v(_vm._s(_vm.member.email))])]), _vm.member.phone ? _c('li', [_c('h6', [_vm._v(_vm._s(_vm.tt('연락처')))]), _c('p', [_vm._v(_vm._s(_vm.member.phone))])]) : _vm._e(), _vm.member.permissions.length > 0 ? _c('li', [_c('h6', [_vm._v(_vm._s(_vm.tt('권한')))]), _c('p', [_vm._v(_vm._s(_vm.showPermissions))])]) : _vm._e()])]), _c('div', {
    staticClass: "Member__info-affiliation Member__info-content"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('소속')))]), _c('ul', [_vm._l(_vm.groupNames, function (groupName, idx) {
    return _c('li', {
      key: "".concat(groupName, "-").concat(idx)
    }, [_c('h6', [_vm._v(_vm._s(_vm.tt('소속')) + _vm._s(idx + 1))]), _c('p', [_vm._v(_vm._s(groupName))])]);
  }), _vm.groupNames.length == 0 ? _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('소속 없음')))])]) : _vm._e()], 2)])]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__center-btn-group Popup__btn-group"
  }, [_vm.userPermission.institute.member["delete"] ? _c('button', {
    staticClass: "Btn__export",
    on: {
      "click": _vm.deleteMember
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('내보내기')) + " ")]) : _vm._e(), _vm.userPermission.institute.member.update ? _c('button', {
    staticClass: "Btn__edit",
    staticStyle: {
      "width": "unset"
    },
    on: {
      "click": _vm.editMember
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('정보편집')) + " ")]) : _vm._e()])])]) : _vm._e()]), _c('modal-registering-member', {
    ref: "modal-registering-member",
    attrs: {
      "page": "member",
      "userInfo": _vm.userInfo
    },
    on: {
      "complete": _vm.completeRegistering
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }