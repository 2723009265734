import inventoryAPI from '@/services/api/inventory/gen2'
import settingAPI from '@/services/api/inventory/setting'
import legalAPI from '@/services/api/safety/legal'

const state = {
	item: null,
	categoryInfo: null,
	uploadMsds: null,
}

const getters = {
	item: state => state.item,
	legals: state => {
		let result = {}
		if (state.item && state.item.legalList) {
			state.item.legalList.forEach(item => {
				result[item.legalId] = item
			})
			return result
		} else {
			return null
		}
	},
	inventoryFields: state => state.categoryInfo?.fields?.inventoryFields?.filter(f => f.isActive) ?? [],
	managementFields: state => state.categoryInfo?.fields?.managementFields?.filter(f => f.isActive) ?? [],
	customFields: state => state.categoryInfo?.fields?.customFields?.filter(f => f.isActive) ?? [],
	customFieldsData: state => {
		if (state.item) {
			let result = {}
			state.item?.customField?.forEach(item => (result[item.key] = item?.value))
			return result
		}
		return null
	},
	uploadFormData: state => {
		const formData = new FormData()
		formData.append('productCode', state.item?.productCode ?? '')
		formData.append('brandId', state.item?.brand?.id ?? '')
		formData.append('inventoryId', state.item?.id)
		formData.append('file', state.uploadMsds)
		return formData
	},
	categoryInfo: state => state.categoryInfo,
}

const mutations = {
	setItem: (state, item) => {
		state.item = item
	},
	setCategoryInfo: (state, info) => {
		state.categoryInfo = info
	},
	setUploadMsds: (state, file) => {
		state.uploadMsds = file
	},
}

const actions = {
	getDetail: async ({ rootGetters, commit }, id) => {
		const response = await inventoryAPI.getDetail(rootGetters.instituteId, { id: id })
		commit('setItem', response.data)
	},
	getWaitingDetail: async ({ rootGetters, commit }, id) => {
		const response = await inventoryAPI.getWaitingDetail(rootGetters.instituteId, { id: id })
		console.warn('getWaitingDetail', response.data)
		commit('setItem', response.data)
	},
	getCategoryInfo: async ({ rootGetters, commit, state }) => {
		const response = await settingAPI.lookupCategory(rootGetters.instituteId, state.item?.category?.id)
		commit('setCategoryInfo', response.data)
	},
	uploadMsds: async ({ rootGetters, getters, commit, dispatch, state }) => {
		const response = await inventoryAPI.uploadMsds(rootGetters.instituteId, getters.uploadFormData)
		await dispatch('getDetail', state.item.id)
	},
	deleteMsds: async ({ rootGetters, commit, dispatch, state }) => {
		const response = await inventoryAPI.deleteMsds(rootGetters.instituteId, {
			productCode: state.item?.productCode ?? '',
			brandId: state.item?.brand?.id ?? '',
			inventoryId: state.item?.id,
		})
		await dispatch('getDetail', state.item.id)
	},
	useItem: async ({ rootGetters, dispatch }, itemId) => {
		await inventoryAPI.useItem(rootGetters.instituteId, { itemsId: [itemId] })
		await dispatch('getDetail', itemId)
	},
	consumeItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.consumeItem(rootGetters.instituteId, body)
		await dispatch('getDetail', state.item.id)
	},
	changeItemStorage: async ({ rootGetters, dispatch, state }, body) => {
		await inventoryAPI.changeStorage(rootGetters.instituteId, body)
		await dispatch('getDetail', state.item.id)
	},
	removeItem: async ({ rootGetters }, body) => {
		await inventoryAPI.removeItem(rootGetters.instituteId, body)
	},
	disposeItem: async ({ rootGetters }, body) => {
		await inventoryAPI.disposeItem(rootGetters.instituteId, body)
	},
	returnItem: async ({ rootGetters, dispatch }, body) => {
		await inventoryAPI.returnItem(rootGetters.instituteId, body)
		await dispatch('getDetail', state.item.id)
	},
	deleteInspectItem: async ({ rootGetters }, ids) => {
		await inventoryAPI.deleteInspectItem(rootGetters.instituteId, { ids: ids })
	},
	addInspectItemImage: async ({ rootGetters }, formData) => {
		const response = await inventoryAPI.editInspectItemUser(rootGetters.instituteId, formData)
	},
	getComment: async ({ rootGetters, commit }, type) => {
		const response = await inventoryAPI.getComment(rootGetters.instituteId, { type: type })
		return response.data
	},
	addComment: async ({ rootGetters }, body) => {
		await inventoryAPI.addComment(rootGetters.instituteId, body)
	},
	deleteComment: async ({ rootGetters }, id) => {
		await inventoryAPI.deleteComment(rootGetters.instituteId, { id: id })
	},
}

export default { namespaced: true, state, getters, mutations, actions }
