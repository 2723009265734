import budgetAPI from '@/services/api/budget'
import { DateTime } from 'luxon'
import cookies from 'vue-cookies'
import { budgetViewOption } from '@/utils/define/ViewOption'

const state = {
	yearStatus: [],
	list: [],
	count: 0,
	loadParams: {
		year: DateTime.now().year,
		keyword: null,
		groupsId: JSON.parse(cookies.get('filter_budget_group')) ?? [],
		offset: 0,
		length: 50,
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
	},
	filter: null,
	filterCookieName: {
		group: 'filter_budget_group',
	},
	viewOptionCookieName: 'viewOption_budget',
	checkedViewOption: JSON.parse(cookies.get('viewOption_budget')) ?? [],
	viewOptionList: budgetViewOption,
}

const getters = {
	keyword: state => state.loadParams.keyword,
	selectedYear: state => state.loadParams.year,
	groupsId: () => state.loadParams.groupsId,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
	filteredCount: () => state.loadParams.groupsId.length,
	yearStatus: state => state.yearStatus,
}

const mutations = {
	setBudgetYears: (state, data) => {
		let currentYear = DateTime.now().year
		if (data.length > 0) state.yearStatus = data
		else state.yearStatus = [currentYear]
		if (!state.yearStatus.includes(state.loadParams.year)) state.loadParams.year = currentYear
		state.yearStatus.sort((a, b) => b - a)
	},
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setOffset: (state, pageNumber) => {
		state.loadParams.offset = (pageNumber - 1) * length
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			state.loadParams[key] = loadParams[key]
		})
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	setViewOptions: (state, list) => {
		cookies.set(state.viewOptionCookieName, JSON.stringify(list))
		state.checkedViewOption = list
	},
}

const actions = {
	getList: async ({ state, commit, rootGetters }) => {
		const response = await budgetAPI.list(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	getBudgetYears: async ({ rootGetters, commit }) => {
		const response = await budgetAPI.year(rootGetters.instituteId)
		commit('setBudgetYears', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		dispatch('getList')
	},
	getFilter: async ({ commit, rootGetters }) => {
		const response = await budgetAPI.filter(rootGetters.instituteId)
		commit('setFilter', response.data)
	},
	exportExcel: async ({ state, rootGetters }, type) => {
		const response = await budgetAPI.listExportExcel(rootGetters.instituteId, type == 'ALL' ? { year: state.loadParams.year } : state.loadParams)
		return response
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
