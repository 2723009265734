import 'babel-polyfill'
import 'es6-promise/auto'
import es6Promise from 'es6-promise'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import router from '@/router'
// import i18n from '@/i18n'
import { COOKIES_DOMAIN } from '@/services/api/common'
import { mapState } from 'vuex'
import { Check } from '@/utils/define/Translation'
import { DateTime } from 'luxon'
import MixinMain from '@/mixins'
import PortalVue from 'portal-vue'
import VueColumnsResizable from 'vue-columns-resizable'
import vueDebounce from 'vue-debounce'
import VueGoogleCharts from 'vue-google-charts'
import translation from '@/localization'
import _, { unescape } from 'lodash'
import VueCookies from 'vue-cookies'
import axiosInstance from '@/services/axios'
import App from '@/views/App.vue'
// 임시점검 일경우
// import App from '@/views/inspection/index.vue'
import { BootstrapVue } from 'bootstrap-vue'

// import '@/styles/scss/global/_font.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue2-datepicker/index.css'
import '@/styles/scss/global/_reverse-bootstrap.scss'
import '@/styles/scss/global/_common.scss'

VueCookies.config(Infinity, null, COOKIES_DOMAIN)
Vue.use(VueCookies)

const originalPush = VueRouter.prototype.push

Array.prototype.remove = function (data, key) {
	const idx = this.findIndex(function (item) {
		if (key == null || key == undefined) {
			return item == data
		} else {
			return item[key] === data || item[key] === data[key]
		}
	}) // findIndex = find + indexOf
	if (idx > -1) this.splice(idx, 1)
}
Array.prototype.removeAt = function (idx) {
	if (idx > -1) this.splice(idx, 1)
}
Array.prototype.removeAll = function (list, key) {
	list.forEach(x => this.remove(x, key))
}

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			// here I check that click was outside the el and his children
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				vnode.context[binding.expression](event)
			}
		}
		document.body.addEventListener('mouseup', el.clickOutsideEvent)
	},
	unbind: function (el) {
		document.body.removeEventListener('mouseup', el.clickOutsideEvent)
	},
})
Vue.directive('visible', function (el, binding) {
	el.style.visibility = binding.value ? 'visible' : 'hidden'
})

es6Promise.polyfill()
Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.use(VueRouter)
Vue.use(PortalVue)
Vue.use(VueColumnsResizable)
Vue.use(vueDebounce)
Vue.use(VueGoogleCharts)
Vue.use(translation)
Vue.use(BootstrapVue)

//custom commom view
import BaseButton from '@/views/common/components/BaseButton.vue'
import ImageButton from '@/views/common/components/baseButton/ImageButton.vue'
import ModalConfirm from '@/views/common/components/modalGen2/ModalConfirm.vue'
import ModalPrompt from '@/views/common/components/modalGen2/ModalPrompt.vue'
Vue.component('BaseButton', BaseButton)
Vue.component('ImageButton', ImageButton)
Vue.component('ModalConfirm', ModalConfirm)
Vue.component('ModalPrompt', ModalPrompt)

// Qrcode Generator
import VueQRCodeComponent from 'vue-qr-generator'
Vue.component('qr-code', VueQRCodeComponent)

Vue.prototype.$_ = _
// Vue.prototype.$cookies = VueCookies
Vue.prototype.$axios = axiosInstance
Vue.prototype.$DateTime = DateTime

Vue.mixin(MixinMain)

import { storeUrl } from '@/services/api/common'
const storeLink = storeUrl()

new Vue({
	render: h => h(App),
	store,
	// i18n,
	router,
	translation,
	computed: {
		...mapState('user', ['user']),
		...mapState('breadcrumb', ['pageName']),
	},
	watch: {
		user: function () {
			if (this.user.language == 'en') {
				Check.English = true
			} else {
				Check.English = false
			}
			// Vue.use(translation)
			// this.$forceUpdate()
		},
	},
	data() {
		return {
			previousPage: null,
		}
	},
	methods: {
		deepCopy(obj) {
			try {
				return JSON.parse(JSON.stringify(obj))
			} catch (e) {
				return {}
			}
		},
		async goToStore(link = '', params) {
			await this.getLMSInstituteId()

			let storeUrl = `${storeLink}/${link}?isPro=true&proPageName=${encodeURIComponent(this.pageName)}&instituteId=${this.lmsInstituteId}`
			if (params) {
				Object.keys(params).forEach(key => {
					storeUrl += `&${key}=${encodeURIComponent(params[key])}`
				})
			}
			window.open(storeUrl, '_blank')
		},
	},
}).$mount('#app')

window.fileDownload = function (res, filename) {
	try {
		var blob = new Blob([res.data], { type: res.data.type })
		var fileName = filename
		fileName = unescape(fileName)

		if (window.navigator.msSaveOrOpenBlob) {
			// IE 10+
			window.navigator.msSaveOrOpenBlob(blob, fileName)
		} else {
			// not IE
			var link = document.createElement('a')
			var url = window.URL.createObjectURL(blob)
			link.href = url
			link.target = '_self'
			if (fileName) link.download = fileName
			document.body.append(link)
			link.click()
			link.remove()
			window.URL.revokeObjectURL(url)
		}
	} catch (e) {
		console.error(e)
	}
}
