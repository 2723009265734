var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-item",
    "class": {
      "new": _vm.isNew
    },
    on: {
      "click": _vm.goToDetail
    }
  }, [_c('td', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('span', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, null) > -1 : _vm.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checked = $$c;
        }
      }
    }
  })])]), _c('td', [_c('div', [_c('span', {
    staticStyle: {
      "display": "flex",
      "gap": "10px"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.status.image
    }
  }), _c('p', {
    staticClass: "status",
    "class": _vm.item.status
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.status.text)) + " ")])])])]), _c('td', {
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.categoryKor))])])]), _c('td', {
    staticStyle: {
      "min-width": "800px"
    }
  }, [_vm.item.images ? _c('div', {
    staticClass: "imgbox"
  }, [_vm._l(_vm.item.images.slice(0, 3), function (image, index) {
    return _c('img', {
      key: "image-".concat(index),
      attrs: {
        "src": image
      }
    });
  }), _vm.item.images.length > 3 ? _c('div', [_c('p', [_vm._v(_vm._s("\uC678 ".concat(_vm.item.images.length - 3)))])]) : _vm._e()], 2) : _vm._e()]), _c('td', {
    staticStyle: {
      "min-width": "200px"
    }
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.isEmpty(_vm.item.register.name)))])])]), _c('td', {
    staticStyle: {
      "min-width": "200px"
    }
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.createDatetime))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }