export default {
	data() {
		return {
			checkedList: [],
		}
	},
	computed: {
		filterList() {
			return [
				...this.categoryList.map(it => ({ ...it, type: 'CATEGORY' })),
				...this.monthList.map(it => ({ ...it, type: 'MONTH' })),
				...this.groupList.map(it => ({ ...it, type: 'GROUP' })),
				...this.userList.map(it => ({ ...it, type: 'USER' })),
				...this.budgetList.map(it => ({ ...it, type: 'BUDGET' })),
			]
		},
		categoryList() {
			return [
				{
					id: 'ALL',
					name: '전체',
				},
				{
					id: 'budget',
					name: '예산',
				},
				{
					id: 'expenditure',
					name: '지출',
				},
				{
					id: 'balance',
					name: '잔액',
				},
			]
		},
		cal() {
			return ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
		},
		monthList() {
			let list = [
				{
					id: 'ALL',
					name: '전체',
				},
			]
			for (var i = 1; i <= 12; i++) {
				list.push({
					id: this.cal[i - 1],
					name: this.$root.user.language == 'ko' ? `${i}월` : this.cal[i - 1],
				})
			}
			list.push({
				id: 'total',
				name: this.tt('계(${1}년)', this.year),
			})
			return list
		},
		groupList() {
			let groups = this.groups ?? []
			let list = [
				{
					id: 'ALL',
					name: '전체',
				},
			]
			return [...list, ...groups.map(g => ({ id: g.id, name: g.name }))]
		},
		userList() {
			let users = this.users ?? []
			let list = [
				{
					id: 'ALL',
					name: '전체',
				},
			]
			return [...list, ...users.map(g => ({ id: g.id, name: g.name }))]
		},
		budgetList() {
			let budgets = this.budgets ?? []
			let list = [
				{
					id: 'ALL',
					name: '전체',
				},
			]
			return [...list, ...budgets.map(g => ({ id: g.id, name: g.name }))]
		},
	},
	watch: {
		checkedList() {
			this.$emit('change', this.checkedList)
		},
	},
	methods: {
		checkedItem(item, type) {
			if (item.id == 'ALL') this.checekdAll(type)
			else {
				if (this.checkedList.find(it => it.id == item.id)) {
					this.checkedList.remove(item.id, 'id')
				} else this.checkedList.push({ ...item, type })
			}
		},
		checekdAll(type) {
			let list = this.filterList.filter(it => it.type == type).slice(1)
			if (list.length == this.checkedList.filter(it => it.type == type).length) {
				this.checkedList.removeAll(list, 'id')
			} else {
				this.checkedList.removeAll(list, 'id')
				this.checkedList.push(...list.map(i => ({ ...i, type })))
			}
		},
		removeItem(item) {
			this.checkedList.remove(item.id, 'id')
		},
		reset() {
			this.checkedList = []
			if (this.$refs['search-box']) this.$refs['search-box'].text = ''
		},
		checekdListByType(type) {
			return this.checkedList.filter(it => it.type == type).map(it => it.id)
		},
		isChecekdItem(id, type) {
			let list = this.filterList.filter(it => it.type == type).slice(1)
			if (id == 'ALL') return list.length == this.checkedList.filter(it => it.type == type).length
			return this.checekdListByType(type).includes(id)
		},
	},
}
