<template>
	<button class="lm-button" @click="handleClick" :class="[color, { disable: disable }]">
		{{ buttonText }}
	</button>
</template>

<script>
export default {
	name: 'LmSmallButton',
	props: {
		type: {
			type: String,
			default: 'common',
		},
		color: {
			type: String,
			default: 'yellow',
		},
		buttonText: {
			type: String,
			default: '',
		},
		disable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	methods: {
		handleClick() {
			this.$emit('click')
		},
	},
}
</script>

<style lang="scss" scoped>
.lm-button {
	display: flex;
	width: 100px;
	height: 30px;
	font-weight: 700;
	margin: 10px 0 10px;
	border-radius: 5px;
	align-items: center;
	justify-content: center;

	&.disable {
		background: $GREY_2 !important;
		border: 1px solid $GREY_4 !important;
		color: #bebebe !important;
		cursor: not-allowed !important;
	}

	&.yellow {
		background: linear-gradient(90deg, #fed671 0%, $LAB_YELLOW 98.81%);
		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(90deg, #fed671 0%, $LAB_YELLOW 98.81%);
		}
	}
	&.red {
		background: linear-gradient(135deg, #ff8377 0%, #ff3b31 100%);
		color: $LAB_WHITE;

		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(135deg, #ff8377 0%, #ff3b31 100%);
		}
	}

	&.white {
		background: $LAB_WHITE;
		border: 1px solid #cccccc;
		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
		}
	}
}
</style>
