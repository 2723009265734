var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('p', {
    staticClass: "header"
  }, [_vm._v(_vm._s(_vm.tt('물품정보')))]), _vm.isCategoryTypeChemical && _vm.hasMsds ? _c('safety-info', {
    attrs: {
      "msds": _vm.item.msds
    }
  }) : _vm._e(), _c('member-info', {
    attrs: {
      "item": _vm.item
    }
  }), _c('product-info', {
    attrs: {
      "item": _vm.item
    }
  }), _c('management-info', {
    attrs: {
      "item": _vm.item
    }
  }), _c('ingredient-info', {
    attrs: {
      "ingredient": _vm.item.inventoryFields.ingredient
    }
  }), !_vm.isBasic ? _c('legal-info', {
    attrs: {
      "legals": _vm.legals,
      "ingredientList": _vm.item.inventoryFields.ingredient,
      "casno": _vm.item.inventoryFields.casno
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }