var render = function () {
  var _vm$node$children;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "check-item"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.node.name,
      expression: "node.name",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "check-line"
  }, [_c('label', [_c('input', {
    staticClass: "checkbox",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.checkedList.includes(_vm.node.id)
    },
    on: {
      "change": function change($event) {
        return _vm.checked(_vm.node);
      }
    }
  }), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.node.name))
    }
  })]), ((_vm$node$children = _vm.node.children) === null || _vm$node$children === void 0 ? void 0 : _vm$node$children.length) > 0 ? _c('img', {
    staticClass: "arrow",
    "class": {
      fold: _vm.isTreeOpen
    },
    attrs: {
      "src": require("@/assets/svg/Triangle_Down.svg")
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleTreeOpen.apply(null, arguments);
      }
    }
  }) : _vm._e()]), _vm.isTreeOpen ? _c('div', {
    staticClass: "children"
  }, _vm._l(_vm.node.children, function (child) {
    return _c('filter-children', {
      key: child.id,
      attrs: {
        "node": child,
        "checkedList": _vm.checkedList
      },
      on: {
        "checked": _vm.checked
      }
    });
  }), 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }