var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-requirement-shipping"
  }, [_c('div', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.tt('주문을 통해 제품을 수령하실 배송지 정보를 입력해 주세요')))]), _vm.isEmpty ? _c('div', {
    staticClass: "address-regist-form"
  }, [_c('input-form-item', {
    ref: "name",
    staticStyle: {
      "padding": "0"
    },
    attrs: {
      "direction": "column",
      "title": "배송지명",
      "placeholder": "배송지명을 입력해주세요",
      "inputStyle": _vm.inputStyle
    },
    model: {
      value: _vm.info.name,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "name", $$v);
      },
      expression: "info.name"
    }
  }), _c('input-form-item', {
    ref: "instituteName",
    staticStyle: {
      "padding": "0"
    },
    attrs: {
      "direction": "column",
      "title": "학교 / 기업명",
      "placeholder": "학교 / 기업명을 입력해주세요",
      "inputStyle": _vm.inputStyle
    },
    model: {
      value: _vm.info.instituteName,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "instituteName", $$v);
      },
      expression: "info.instituteName"
    }
  }), _c('input-form-item', {
    ref: "groupName",
    staticStyle: {
      "padding": "0"
    },
    attrs: {
      "direction": "column",
      "title": "학과 / 부서명",
      "placeholder": "학과 / 부서명을 입력해주세요",
      "inputStyle": _vm.inputStyle
    },
    model: {
      value: _vm.info.groupName,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "groupName", $$v);
      },
      expression: "info.groupName"
    }
  }), _c('input-form-item', {
    ref: "address",
    attrs: {
      "postcode": _vm.info.postcode,
      "address": _vm.info.address,
      "addressDetail": _vm.info.addressDetail,
      "direction": "column",
      "type": "address",
      "title": "주소",
      "inputStyle": _vm.inputStyle
    },
    on: {
      "searchAddress": _vm.onSearchAddress
    }
  }), _c('div', {
    staticClass: "button-wrapper"
  }, [_c('button', {
    staticClass: "btn btn-primary lg",
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(_vm._s(_vm.tt('다음')))])])], 1) : _c('div', _vm._l(_vm.deliveryList, function (address) {
    return _c('div', {
      key: address.id,
      staticClass: "address-list"
    }, [_c('div', {
      staticClass: "address-item"
    }, [_c('div', [address.isDefault ? _c('span', {
      staticClass: "status"
    }, [_vm._v(_vm._s(_vm.tt('기본 배송지')))]) : _vm._e()]), _c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(address.name) + " ")]), _c('div', {
      staticClass: "address"
    }, [_vm._v(" " + _vm._s(_vm.addressFullName(address)) + " ")]), _c('div', {
      staticClass: "institute"
    }, [_vm._v(" " + _vm._s(_vm.instituteFullName(address)) + " ")])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }