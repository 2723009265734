var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tree-node-wrap",
    "class": {
      root: _vm.node.parentId == null && _vm.type != 'storage'
    }
  }, [_c('div', {
    staticClass: "tree-node",
    "class": {
      unaccessible: _vm.node.accessible == false
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.setChecked.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "info-wrap",
    "class": {
      root: _vm.node.parentId == null && _vm.type != 'storage'
    }
  }, [_vm.isSubtree ? _c('div', [_vm.isTreeOpen ? _c('img', {
    staticClass: "tree-close",
    attrs: {
      "src": require("../svg/icon-node-close.svg"),
      "width": "16",
      "height": "24"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleTreeOpen.apply(null, arguments);
      }
    }
  }) : _c('img', {
    staticClass: "tree-open",
    attrs: {
      "src": require("../svg/icon-node-open.svg"),
      "width": "16",
      "height": "24"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleTreeOpen.apply(null, arguments);
      }
    }
  })]) : _vm._e(), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.node.name,
      expression: "node.name",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "data-wrap"
  }, [_c('img', {
    staticClass: "tree-icon",
    staticStyle: {
      "filter": "brightness(0)"
    },
    attrs: {
      "src": _vm.imageSrc,
      "width": "20",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "name",
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  })])])]), _vm.node.accessible != false ? _c('div', {
    staticClass: "checkbox"
  }, [_c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isChecked
    },
    on: {
      "change": _vm.setChecked
    }
  })]) : _vm._e(), _vm.isTreeOpen ? _c('div', {
    staticClass: "node-children"
  }, [_vm._l(_vm.node.children, function (group) {
    return _c('filter-node', {
      key: group.id,
      ref: "child-node",
      refInFor: true,
      attrs: {
        "node": group,
        "type": _vm.type === 'storage' ? 'storage' : 'location'
      }
    });
  }), _vm._l(_vm.node.storages, function (storage) {
    return _c('filter-node', {
      key: storage.id,
      ref: "child-node",
      refInFor: true,
      attrs: {
        "node": storage,
        "type": "storage"
      }
    });
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }