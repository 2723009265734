import { COOKIES_DOMAIN, setCookieInstituteId } from '@/services/api/common'
import userAPI from '@/services/api/institute/user'

import cookies from 'vue-cookies'

cookies.config({
	domain: COOKIES_DOMAIN,
})

const User = {
	namespaced: true,
	state: () => ({
		isLogin: false,
		user: {
			name: undefined,
			id: undefined,
			phone: undefined,
			email: undefined,
			type: undefined,
			snsId: undefined,
			isMaster: undefined,
			isPush: undefined,
			language: undefined,
			isCertificated: undefined,
			isLegacyUser: undefined,
		},
		institute: undefined,
	}),
	getters: {
		hasInstitutionId: state => {
			return state.institute ? state.institute.id : false
		},
	},
	mutations: {
		setUser: (state, payload) => {
			state.user = payload
		},
		setInstitute: (state, payload) => {
			state.institute = payload
		},
	},
	actions: {
		loginUser: () => {
			return new Promise(async (resolve, reject) => {
				try {
					await userAPI.login()
				} catch (error) {
					resolve({
						error: error,
						msg: 'ERROR',
					})
				} finally {
					resolve()
				}
			})
		},
		loadUserInfo: ({ state, commit }) => {
			return new Promise(async (resolve, reject) => {
				try {
					const response = await userAPI.lookupUserInfo()
					commit('setUser', { ...response.data })
				} catch (error) {
				} finally {
					resolve()
				}
			})
		},
		loadUserInstituteInfo: ({ state, commit }) => {
			return new Promise(async (resolve, reject) => {
				try {
					const response = await userAPI.lookupUserInstInfo()
					commit('setInstitute', { ...response.data })
					const instId = response.data.id
					setCookieInstituteId(instId)
					resolve()
				} catch (e) {
				} finally {
					resolve()
				}
			})
		},
		loadExUserToken: ({ state, commit }) => {
			return new Promise(async (resolve, reject) => {
				try {
					const response = await userAPI.loadExUserToken()
					resolve(response.data?.token)
				} catch (e) {
				} finally {
					resolve()
				}
			})
		},
	},
}

export default User
