<template>
	<div class="requireInfo">
		<div class="boxwrap">
			<img :src="require(`@/assets/svg/` + icon)" />
			<h2>
				<span v-if="groupName && progressStep > 2">{{ groupName }}</span
				>{{ tt(mainText) }}
			</h2>
			<h3>{{ tt(subText) }}</h3>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		progressStep: {
			type: Number,
			default: 0,
		},
		groupName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			icon: 'progress-1.svg',
			mainText: '기관명을 입력해주세요.',
			subText: '기관명은 기업, 대학, 연구소, 공공기관 등의 이름을 의미합니다.',
		}
	},
	computed: {},
	watch: {
		progressStep: {
			handler(newValue) {
				switch (newValue) {
					case 1:
						this.icon = 'progress-1.svg'
						this.mainText = '기관명을 입력해주세요.'
						this.subText = '기관명은 기업, 대학, 연구소, 공공기관 등의 이름을 의미합니다.'
						break
					case 2:
						this.icon = 'progress-2.svg'
						this.mainText = '그룹명을 입력해주세요.'
						this.subText = '그룹명은 팀, 부서, 연구실 등의 이름을 의미합니다.'
						break
					case 3:
						this.icon = 'progress-3.svg'
						this.mainText = '에서 사용하고 계신 보관함을 등록해 주세요.'
						this.subText = '물품을 등록할 보관함을 추가해 주세요.'
						break
					case 4:
						this.icon = 'progress-4.svg'
						this.mainText = '에 소속된 멤버를 초대해 주세요.'
						this.subText = '그룹에 추가할 멤버의 이메일 주소를 입력하세요.'
						break
				}
			},
		},
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.requireInfo {
	.boxwrap {
		img {
			width: 50px;
			height: 50px;
			margin-bottom: 1rem;
		}
		h2 {
			font-size: 2rem;
			font-weight: 700;
			margin-bottom: 10px;
			span {
				display: inline-block;
				background: linear-gradient(180deg, $LAB_YELLOW 0%, #ffa90b 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
		h3 {
			font-size: 1.125rem;
			font-weight: 400;
			margin-bottom: 40px;
		}
	}
}
</style>
