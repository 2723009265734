<template>
	<div class="user-wrapper" v-if="institute">
		<h5>
			{{ tt('주문자 정보') }}
		</h5>
		<div class="order-user-wrapper">
			<input-text
				isReadOnly
				title="소속"
				:inputWidthSize="400"
				:inputHeightSize="36"
				placeholder="소속을 입력해주세요"
				:inputValue="institute.name"
			></input-text>
			<input-text
				isRequired
				ref="ordererName"
				title="이름"
				:inputWidthSize="400"
				:inputHeightSize="36"
				placeholder="이름을 입력해주세요"
				:inputValue="$root.user.name"
				:inValidText="'이름을 입력해주세요'"
				:isValid="!isValid || !!$refs['ordererName'].value"
			></input-text>
			<input-text
				isRequired
				ref="ordererEmail"
				title="이메일"
				:inputWidthSize="400"
				:inputHeightSize="36"
				placeholder="이메일을 입력해주세요"
				:inValidText="'이메일을 입력해주세요'"
				:inputValue="userEmail"
				:isValid="!isValid || !!$refs['ordererEmail'].value"
			></input-text>
			<input-text
				isRequired
				ref="ordererPhone"
				title="연락처"
				:inputWidthSize="400"
				:inputHeightSize="36"
				:inputValue="$root.user.phone"
				placeholder="연락처를 입력해주세요"
				:inValidText="'연락처를 입력해주세요'"
				:isValid="!isValid || !!$refs['ordererPhone'].value"
			></input-text>
		</div>
	</div>
</template>
<script>
import InputText from '@/views/common/components/items/InputText.vue'
import { mapState } from 'vuex'
export default {
	props: {
		isValid: {
			type: Boolean,
		},
	},
	computed: {
		...mapState('user', ['institute']),
		info() {
			return {
				ordererName: this.$refs['ordererName'].value,
				ordererEmail: this.$refs['ordererEmail'].value,
				ordererPhone: this.$refs['ordererPhone'].value,
			}
		},
		userEmail() {
			if (this.$root.user.email == 'null') {
				return ''
			}
			return this.$root.user.email
		},
	},
	components: {
		InputText,
	},
}
</script>
<style lang="scss" scoped>
h5 {
	font-weight: 700;
	label {
		cursor: pointer;
		margin-left: 16px;
		b {
			margin-left: 8px;
			font-size: 16px;
		}
	}
}
.order-user-wrapper {
	margin-top: 15px;
	padding: 20px;
	border: 1px solid $INPUT;
	border-radius: 5px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	.item-wrapper {
		border-bottom: 0px;
	}
}
</style>
