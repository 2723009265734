<template>
	<div class="AppHeader__menu" @click.stop="clickMenu" :class="isMenuOn ? 'active' : ''">
		<!-- <icon-header-profile v-if="!hasUserImg"></icon-header-profile> -->
		<img
			class="AppHeader__profile-img"
			:width="28"
			:height="28"
			:src="user.image ? user.image : require('@/assets/svg/member-photo-default.svg')"
		/>
		<div class="AppHeader__menu-name">{{ tt('${1}님', user.name) }}</div>
		<app-header-menu-list v-show="isMenuOn" :isMigration="isMigration"></app-header-menu-list>
	</div>
</template>

<script>
import AppHeaderMenuList from '@/views/app/components/AppHeaderMenu/components/AppHeaderMenuList.vue'
import IconHeaderProfile from '@/components/svg/IconHeaderProfile.vue'
import { mapState, mapActions } from 'vuex'
export default {
	name: 'AppHeaderMenu',
	components: {
		AppHeaderMenuList,
		IconHeaderProfile,
	},
	props: {
		isMigration: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		// this.loadUserInfo()
	},
	data() {
		return {
			hasUserImg: false,
			userImgSrc: 'userImgSrc',
			isMenuOn: false,
		}
	},
	watch: {
		'$root.rightMenuOn': {
			handler(newValue) {
				if (!newValue) this.isMenuOn = false
			},
		},
		'$root.getUserInfo': {
			hander(newValue) {},
		},
	},
	computed: {
		...mapState('user', ['user']),
	},
	methods: {
		...mapActions('user', ['loadUserInfo']),
		clickMenu() {
			this.$root.initStateOnMenu()
			const changedValue = !this.isMenuOn
			this.$root.rightMenuOn = changedValue
			this.isMenuOn = changedValue
		},
	},
}
</script>

<style lang="scss" scoped>
.AppHeader__menu {
	display: inline-flex;
	height: 24px;
	padding: 5px 10px;
	box-sizing: initial;
	border-radius: 4px;
	justify-content: space-around;
	align-items: center;
	font-weight: bold;
	line-height: 1;
	cursor: pointer;
	transition: 0.3s ease;
	&:hover {
		background-color: $GREY_4;
	}
	&.active {
		background-color: $GREY_4;
	}

	.AppHeader__profile-img {
		width: 24px;
		height: 24px;
		border-radius: 24px;
		border: solid 1px #ccc;
	}

	.AppHeader__menu-name {
		display: flex;
		height: 13px;
		align-items: center;
		margin-left: 5px;
		white-space: nowrap;
	}

	.AppHeader__menu-list {
		z-index: 2;
		width: 198px;
		position: absolute;
		top: 55px;
		right: 30px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
		border: solid 1px #ccc;
		background-color: $LAB_WHITE;
		border-radius: 5px;
		padding: 5px 0px;
		cursor: default;
	}
}
</style>
