var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.purchaseLicense
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('라이선스 구매하고 할인받기')) + " ")]), _c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.purchaseWithoutSale
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('할인없이 주문하기')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('h1', [_vm._v(_vm._s(_vm.tt('라이선스 구매하고 할인받기')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("<b style=color: '#FF3B31'>\uCD5C\uB300 20% \uD560\uC778</b>\uC744 \uC704\uD574 \uB77C\uC774\uC120\uC2A4 \uAD6C\uB9E4\uAC00 \uD544\uC694\uD569\uB2C8\uB2E4.<br />(\uB77C\uC774\uC120\uC2A4 \uAD6C\uB9E4 \uC2DC, \uD560\uC778\uAD6C\uB9E4 \uAE08\uC561 \uBB34\uC81C\uD55C)"))
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }