import { lookupPaymentDefaultCard, registPaymentCard } from '@/services/api/payment'
import { COOKIES_DOMAIN } from '@/services/api/common'
import cookies from 'vue-cookies'

const key_instituteId = 'instituteId'

cookies.config({
	domain: COOKIES_DOMAIN,
})

const index = {
	namespaced: true,
	state: () => ({
		hasDefaultCreditCard: false,
		// hasDefaultCreditCard : true,
		defaultCreditCard: {},
	}),
	getters: {},
	mutations: {
		setDefaultCreditCard: (state, payload) => {
			state.defaultCreditCard = { ...payload }
			state.hasDefaultCreditCard = payload ? true : false
		},
	},
	actions: {
		loadDefaultCreditCard: async ({ rootGetters, commit }) => {
			try {
				const response = await lookupPaymentDefaultCard(rootGetters.instituteId)
				commit('setDefaultCreditCard', response.data)
			} catch (e) {
				console.log(e)
			}
		},
		registDefaultCreditCard: async ({ rootGetters }, payload) => {
			return await registPaymentCard(rootGetters.instituteId, payload)
		},
	},
}
export default index
