<template>
	<div class="info-field-wrap">
		<p class="field-name">{{ tt(fieldName) }}</p>
		<div v-if="isRequire && isData == null" class="field-data-wrap-red">{{ tt('미입력') }}</div>
		<div v-else-if="isData" class="field-image-wrap">
			<img class="field-image" :src="item.link" v-for="(item, idx) in image" :key="item.id" @click="showImage(item.link, idx)" />
		</div>
		<div v-else class="field-image-wrap">
			<div class="field-text">-</div>
		</div>
		<modal-multi-image-viewer ref="image-viewer" title="이미지" :imageList="image.map(i => i.link)" :isCaption="false"></modal-multi-image-viewer>
	</div>
</template>
<script>
import ModalImageViewer from '@/views/common/components/modalGen2/ModalImageViewer.vue'
import ModalMultiImageViewer from '@/views/common/components/modalGen2/ModalMultiImageViewer.vue'
export default {
	name: 'ImageField',
	components: { ModalImageViewer, ModalMultiImageViewer },
	props: {
		fieldName: {
			type: String,
			default: '',
		},
		isRequire: {
			type: Boolean,
			default: false,
		},
		image: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			imageUrl: null,
		}
	},
	computed: {
		isData() {
			return this.image?.length > 0
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		showImage(imageUrl, idx) {
			this.imageUrl = imageUrl
			this.$refs['image-viewer'].show()
			this.$refs['image-viewer'].setImageIdx(idx)
		},
	},
}
</script>

<style lang="scss" scoped>
.info-field-wrap {
	padding-right: 10px;
	width: auto;
	.field-name {
		text-align: left;
		font-size: 12px;
		font-weight: 400;
		color: $SECONDARY_2;
		margin-bottom: 6px !important;
	}
	.field-image-wrap {
		display: flex;
		min-width: 280px;
		min-height: 20px;
		position: relative;
		flex-wrap: wrap;
		gap: 22.5px;
		.field-image {
			width: 100px;
			height: 150px;
			border-radius: 7px;
			border: 1px solid $DIVIDER;
			cursor: pointer;
			object-fit: cover;
		}
		.field-text {
			font-size: 14px;
			font-weight: 400;
			text-align: left;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.field-data-wrap-red {
		width: 280px;
		height: 20px;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
		color: $RED;
	}
}
</style>
