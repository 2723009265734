<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalCompleteRegisteringMember">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('멤버 등록 완료') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<ul>
						<li>
							<span>{{ tt('이메일(ID)') }}</span>
							<p>{{ info.email }}</p>
						</li>
						<li>
							<span>{{ tt('비밀번호') }}</span>
							<p>{{ info.password }}</p>
						</li>
					</ul>
					<p>
						{{ tt('멤버 등록이 완료되었습니다. 위의 이메일로 등록된 계정정보를 발송했습니다. ') }}
					</p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button @click="close" class="Btn__close">
							{{ tt('확인') }}
						</button>
						<button class="Btn__complete" @click="registering">
							{{ tt('계속 등록') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	mixins: [MixinModal],
	data() {
		return {
			info: {
				email: 'hongildong@smartjackwp.com',
				password: 'qwer1234',
			},
		}
	},
	methods: {
		show(info) {
			this.isShow = true
			if (info) {
				this.info = info
			}
		},
		close() {
			this.hide()
		},
		registering() {
			this.$emit('registering')
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalCompleteRegisteringMember {
	.Popup__content {
		ul {
			width: 100%;
			padding: 9px 22px;
			background-color: $GREY_1;
			box-sizing: border-box;
			border-radius: 5px;
			li {
				width: 100%;
				padding: 5px 0;
				span {
					display: inline-block;
					font-size: 0.875rem;
					color: $SECONDARY_2;
					font-weight: 400;
					width: 100px;
				}
				p {
					font-size: 0.875rem;
					color: $LAB_BLACK;
					font-weight: 400;
					display: inline-block;
				}
			}
		}
		> p {
			font-size: 1rem;
			color: #000;
			font-weight: 400;
			margin-top: 20px;
			word-break: keep-all;
		}
	}
}
</style>
