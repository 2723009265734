var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "use-wrap"
  }, [_c('div', {
    staticClass: "input-head-text"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('사용량')))]), _c('p', [_c('span', {
    staticClass: "volume-text"
  }, [_vm._v(_vm._s(_vm.tt("\uC794\uC5EC \uC6A9\uB7C9")))]), _vm._v(" : "), _c('span', {
    staticClass: "current-volume"
  }, [_vm._v(_vm._s("".concat(_vm.volume).concat(_vm.volumeUnit)))])])]), _c('div', {
    staticClass: "input-container"
  }, [_c('div', {
    staticClass: "input-wrap"
  }, [_c('input', {
    staticClass: "input-use",
    "class": {
      error: _vm.isShowVolumeError && _vm.isInputVolumeOver(_vm.volume)
    },
    attrs: {
      "placeholder": _vm.tt("\uC0AC\uC6A9\uB7C9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694")
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "input": _vm.handleChange
    }
  }), _c('span', {
    staticClass: "input-unit"
  }, [_vm._v(_vm._s(_vm.volumeUnit))])]), _c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.useAll
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('잔량 모두 입력')) + " ")])]), _vm.isShowVolumeError && _vm.isInputVolumeOver(_vm.volume) ? _c('p', {
    staticClass: "error-msg"
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-error.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.isInputVolumeEmpty ? _vm.tt("\uC0AC\uC6A9\uB7C9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694.") : _vm.tt("\uC794\uC5EC \uC6A9\uB7C9\uC744 \uCD08\uACFC\uD558\uC600\uC2B5\uB2C8\uB2E4.")) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }