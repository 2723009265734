var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "msds-file-viewer",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v("MSDS")])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "content"
  }, [_vm.pdfUrl != null ? _c('iframe', {
    staticClass: "pdf-viewer",
    attrs: {
      "src": _vm.pdfUrl,
      "frameborder": "0"
    }
  }) : _vm._e()])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }