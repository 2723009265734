var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item && _vm.item.draftPurchaseItemId == _vm.$route.params.id ? _c('div', {
    staticClass: "detail-page-wrapper"
  }, [_c('detail-header', {
    attrs: {
      "title": _vm.item.purchaseItems[0].name,
      "subtitle": _vm.subtitle,
      "status": _vm.item.store.paymentStatus,
      "orderId": _vm.item.orderId,
      "canPay": _vm.isCanPay,
      "item": _vm.item
    },
    on: {
      "click": _vm.showConfirmModal
    }
  }), _c('div', {
    staticClass: "detail-content-wrapper"
  }, [_c('div', [!_vm.item.isDirect ? _c('store-order-info', {
    ref: "store-order-info",
    attrs: {
      "type": "DRAFT"
    }
  }) : _c('store-order-info', {
    ref: "store-order-info",
    attrs: {
      "content": "판매자가 주문을 승인하면, 후불결제를 진행할 수 있습니다.",
      "type": "ORDER"
    }
  })], 1), _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('주문 정보')))]), _c('info-table', {
    attrs: {
      "items": _vm.orderInfo
    }
  }), _c('p', {
    staticClass: "order-store-description"
  }, [_vm._v(_vm._s(_vm.tt('취소 / 교환 / 반품 접수는 랩매니저 스토어에서 이용가능합니다')))])], 1), _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('결제 정보')))]), _c('info-table', {
    attrs: {
      "items": _vm.paymentInfo
    }
  })], 1), !_vm.item.isDirect ? _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('결재 정보')))]), _c('div', {
    staticClass: "approval-info-wrapper"
  }, [_vm.item.register ? _c('approval-info-item', {
    attrs: {
      "approvalTitle": '기안',
      "name": _vm.item.register.name,
      "affiliation": _vm.item.group.name,
      "dateTime": _vm.item.createDatetime ? _vm.formatDatetime(_vm.item.createDatetime) : '-'
    }
  }) : _vm._e(), _c('div', _vm._l(_vm.item.approvals, function (approval, index) {
    return _c('approval-info-item', {
      key: "approval_".concat(index),
      attrs: {
        "approvalTitle": '결재',
        "approvalState": approval.status,
        "name": approval.user.name,
        "affiliation": approval.group.name,
        "dateTime": approval.concludeDatetime ? _vm.formatDatetime(approval.concludeDatetime) : '-'
      }
    });
  }), 1)], 1)]) : _vm._e(), !_vm.item.isDirect ? _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('기안 정보')))]), _c('info-table', {
    attrs: {
      "items": _vm.draftInfoForTable
    }
  })], 1) : _vm._e(), _vm.item.budgetStatus ? _c('div', [_c('total-amount', {
    attrs: {
      "title": '예산 현황',
      "info": _vm.amountForm,
      "budget": _vm.item.budgetStatus
    }
  })], 1) : _vm._e(), _c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt('상품 정보')))]), _c('product-info-table', {
    attrs: {
      "productInfoList": _vm.item.purchaseItems
    }
  }), _c('p', {
    staticClass: "purchase-description"
  }, [_vm._v(" " + _vm._s(_vm.tt('추후 정산 시, 선택하는 상품항목에 따라 최종 결제금액이 지출 예정금액 합계와 상이할 수 있습니다.')) + " ")])], 1), _vm.attachmentsForTable[0].files ? _c('div', [_c('div', {
    staticClass: "file-wrap"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('첨부 파일')))]), _vm.attachmentsForTable[0].files && _vm.attachmentsForTable[0].files.length > 0 ? _c('image-button', {
    attrs: {
      "text": '모두 다운로드',
      "image": require('@/assets/svg/icon-file-download.svg')
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['files-table'].downloadAll();
      }
    }
  }) : _vm._e()], 1), _c('info-table', {
    ref: "files-table",
    attrs: {
      "items": _vm.attachmentsForTable
    }
  })], 1) : _vm._e()])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }