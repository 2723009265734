<template>
	<div class="info-wrap">
		<p class="info-header">{{ tt('물품정보') }}</p>
		<div class="field-wrap">
			<product-info-field v-for="fieldInfo in fields" :item="item" :fieldInfo="fieldInfo" :key="`${fieldInfo.key}`"></product-info-field>
		</div>
	</div>
</template>
<script>
import ProductInfoField from './ProductInfoField.vue'
export default {
	name: 'ProductInfo',
	components: { ProductInfoField },
	props: {
		item: {
			type: Object,
			default: null,
		},
		inventoryFieldsInfo: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		fields() {
			return this.inventoryFieldsInfo?.filter(field => field.isActive && !this.hideField.includes(field.key))
		},
		hideField() {
			let hideFieldKey = ['ingredient']
			return hideFieldKey ?? []
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	gap: 20px;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.field-wrap {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}
}
</style>
