<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalWithdrawalMember Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__content">
					<h5>{{ tt('${1}님', member) }}<br />{{ tt('정말로 탈퇴하시겠어요?') }}</h5>
					<p>{{ tt('랩매니저 회원 탈퇴 시, 모든 이용정보가 삭제됩니다.') }}<br />{{ tt('이용 정보는 탈퇴 후 6개월 간 보관됩니다.') }}</p>
					<label><input type="checkbox" v-model="agreement" />{{ tt('안내사항을 모두 확인했으며, 이에 동의합니다') }}</label>
					<div class="btn-wrap">
						<button class="Btn__withdrawal" @click="withdrawal">
							{{ tt('회원탈퇴') }}
						</button>
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import userAPI from '@/services/api/institute/user'
import { goToSSOMain } from '@/services/api/common'

export default {
	mixins: [MixinModal],
	data() {
		return {
			member: '',
			agreement: false,
		}
	},
	methods: {
		show(name) {
			this.isShow = true
			this.member = name
		},
		close() {
			this.hide()
		},
		withdrawal() {
			if (this.agreement == false) {
				this.$root.toast(this.tt('회원탈퇴 안내'), this.tt('회원탈퇴 안내사항에 먼저 동의해주세요'), 'error')

				return false
			} else {
				userAPI.withdrawal()
				goToSSOMain()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalWithdrawalMember {
	.Popup__container {
		.Popup__content {
			padding: 50px;
			text-align: center;
			h5 {
				font-size: 1.5rem;
				color: #000;
				font-weight: 700;
				line-height: 1.5;
			}
			p {
				font-size: 0.875rem;
				color: rgba(0, 0, 0, 0.87);
				font-weight: 400;
				margin-top: 10px;
			}
			label {
				font-size: 0.875rem;
				color: $DEFAULT;
				font-weight: 400;
				margin: 33px 0 33px;
				display: block;
				width: 100%;
				text-align: left;
				input {
					margin-right: 10px;
				}
			}
			.btn-wrap {
				width: 100%;
				text-align: center;
				button {
					width: 100%;
					height: 48px;
					font-size: 1rem;
					font-weight: 700;
					border-radius: 5px;
					display: block;
					margin-top: 10px;
					&.Btn__withdrawal {
						background-color: $RED;
						color: $LAB_WHITE;
					}
				}
			}
		}
	}
}
</style>
