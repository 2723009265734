<template>
	<base-button @click="handleClick" :isActive="isActive" :disabled="disabled">
		<img :src="img" />
		<div class="btn-text" :class="color" v-if="text">{{ tt(text) }}</div>
		<div class="btn-subtext" v-if="subText">{{ tt(subText) }}</div>
	</base-button>
</template>

<script>
export default {
	name: 'ImageButton',
	components: {},
	props: {
		text: {
			type: String,
			default: null,
		},
		subText: {
			type: String,
			default: null,
		},
		image: {
			type: String,
			defalut: null,
		},
		activeImage: {
			type: String,
			defalut: null,
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: '',
		},
	},
	computed: {
		img() {
			return this.isActive ? this.activeImage ?? this.image : this.image
		},
	},
	methods: {
		handleClick() {
			this.$emit('click')
		},
	},
}
</script>

<style lang="scss" scoped>
button img {
	min-height: 12px;
}
.btn-text {
	&.ACCEPT {
		color: $GREEN;
	}
}
</style>
