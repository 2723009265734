<template>
	<tr class="table-header">
		<th>
			<span>{{ tt('정보') }}</span>
		</th>
		<th v-if="isChemical">
			<span>{{ tt('CAS No') }}</span>
		</th>
		<th>
			<span>{{ tt('제품번호') }}</span>
		</th>
		<th>
			<span>{{ tt('브랜드') }}</span>
		</th>
		<th v-if="isChemical">
			<span v-if="isChemical">{{ tt('물품 사이즈(용량) 종류') }}</span>
			<span v-else>{{ tt('패키지(소모품) 수량 종류') }}</span>
		</th>
	</tr>
</template>

<script>
export default {
	name: 'HeaderChemical',
	components: {},
	props: {
		isChemical: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.table-header {
	height: 40px;
	box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 10%);
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 15;
	&.gray {
		background-color: $GREY_2;
	}
	th {
		padding: 10px 0px;
		font-size: 14px;
		font-weight: 700;
		color: $SECONDARY_2;
		& > span,
		div {
			display: inline-block;
			height: 20px;
			min-width: 180px;
			max-width: 200px;
			padding: 0px 8px;
			border-left: 1px solid $INPUT;
		}
		&:first-of-type {
			padding-left: 30px;
		}
	}
}
</style>
