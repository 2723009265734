import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	lookupManagementList: (instId, offset, length, order, orderDirection, keyword) => {
		let parameter = `/institute/${instId}/safety/specialmanagement/list?offset=${offset}&length=${length}`
		if (order.length != 0) parameter += `&${ParamMaker.setArrayString('order', order)}`
		if (orderDirection.length != 0) parameter += `&${ParamMaker.setArrayString('orderDirection', orderDirection)}`
		if (keyword != undefined) parameter += `&keyword=${keyword}`
		return parameter
	},
	exportDiaryExcel: instId => `/institute/${instId}/safety/specialmanagement/export/excel`,
	exportExcel: instId => `/institute/${instId}/safety/specialmanagement/list/export/excel`,
}

const managementAPI = {
	lookupManagementList: (instId, offset, length, order, orderDirection, keyword) =>
		axiosInstance.get(endPoint.lookupManagementList(instId, offset, length, order, orderDirection, keyword)),
	exportDiaryExcel: (instId, body) =>
		axiosInstance.post(endPoint.exportDiaryExcel(instId), body, {
			responseType: 'blob',
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	exportExcel: instId =>
		axiosInstance.post(
			endPoint.exportExcel(instId),
			{},
			{
				responseType: 'blob',
			}
		),
}

export default managementAPI
