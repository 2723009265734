<
<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			{{ tt('거래명세서 다운로드') }}
		</template>
		<template>
			<div class="input-wrapper">
				<h5>{{ tt('기업정보') }}</h5>
				<div class="Popup__input-field">
					<p>{{ tt('상호명') }}<span class="required">*</span></p>
					<input type="text" v-model="form.nameOfCompany" :placeholder="tt('법인명을 입력해주세요')" />
				</div>
				<div class="Popup__input-field">
					<p>
						{{ tt('사업자등록번호(10자리)') }}
						<span class="required">*</span>
					</p>
					<input
						type="text"
						:value="form.registrationNumber"
						@input="inputRegistrationNumber"
						:placeholder="tt('10자리 사업자등록번호를 입력해주세요')"
						:class="{ error: registrationNoError }"
						maxlength="12"
					/>
					<span class="input-error-msg" v-if="registrationNoError">
						<img src="@/assets/svg/input-icon-warning.svg" />
						<p>
							{{ tt('유효하지 않은 사업자등록번호입니다.') }}
						</p>
					</span>
				</div>
				<div class="Popup__input-field">
					<p>{{ tt('대표자명') }}<span class="required">*</span></p>
					<input type="text" v-model="form.nameOfRepresentative" :placeholder="tt('대표자 이름을 입력해주세요')" />
				</div>
				<div class="Popup__input-field">
					<p>{{ tt('업태') }}</p>
					<input type="text" v-model="form.bizType" :placeholder="tt('업태를 입력해주세요')" />
				</div>
				<div class="Popup__input-field">
					<p>{{ tt('종목') }}</p>
					<input type="text" v-model="form.bizClass" :placeholder="tt('종목을 입력해주세요')" />
				</div>
				<div class="Popup__input-field">
					<p>{{ tt('전화번호') }}</p>
					<input
						type="text"
						:placeholder="tt('연락처를 입력해주세요')"
						maxlength="13"
						:value="form.phone"
						@input="autoHypenTel"
						:class="{ error: phoneNoError }"
					/>
					<span class="error-text" v-if="phoneNoError">
						<img src="@/assets/svg/input-icon-warning.svg" />
						<p>
							{{ tt('연락처를 형식에 맞게 입력해주세요') }}
						</p>
					</span>
				</div>
				<div class="Popup__input-field">
					<p>{{ tt('주소') }}</p>
					<AddressInput
						:hasPostcode="false"
						:postcode="form.postcode"
						:address="form.address"
						:addressDetail="form.addressDetail"
						addressPlaceholder="주소를 검색해주세요"
						@search="changeAddress"
					/>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<b-overlay :show="isDownloading" spinner-variant="primary" rounded="sm">
				<button class="Btn__yellow" :disabled="!isFormValid" @click="downloadInvoice">
					{{ tt('다운로드') }}
				</button>
			</b-overlay>
		</template>
	</b-modal>
</template>
<script>
import { mapActions } from 'vuex'
import AddressInput from '@/components/input/AddressInput.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
export default {
	components: {
		AddressInput,
	},
	mixins: [MixinLicense, MixinPayment],
	data() {
		return {
			form: {
				licenseId: null,
				nameOfCompany: '',
				registrationNumber: '',
				nameOfRepresentative: '',
				bizType: '',
				bizClass: '',
				phone: '',
				postcode: '',
				address: '',
				addressDetail: '',
			},
			isDownloading: false,
		}
	},
	computed: {
		isFormValid() {
			const isNameOfRepresentativeValid = this.form.nameOfRepresentative !== ''
			const isRegistrationNumberValid = this.form.registrationNumber !== '' && this.form.registrationNumber?.length === 12
			const isNameOfCompanyValid = this.form.nameOfCompany !== ''

			return isNameOfRepresentativeValid && isRegistrationNumberValid && isNameOfCompanyValid
		},
	},
	methods: {
		...mapActions('invoice', ['downloadCi']),
		show(initForm) {
			console.log(initForm)
			this.initData(initForm)
			this.$refs['modal'].show()
		},
		initData(initForm) {
			this.form = {
				licenseId: null,
				nameOfCompany: '',
				registrationNumber: '',
				nameOfRepresentative: '',
				bizType: '',
				bizClass: '',
				phone: '',
				postcode: '',
				address: '',
				addressDetail: '',
			}
			this.registrationNoError = false
			if (initForm) Object.assign(this.form, initForm)
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async downloadInvoice() {
			this.isDownloading = true
			try {
				await this.downloadCi(this.form)
				this.$root.toast('거래명세서 다운로드', '거래명세서를 다운로드 하였습니다', 'success')
				this.hide()
			} catch (err) {
				console.log(err)
				this.$root.toast('거래명세서 다운로드 실패', err, 'error')
			}
			this.isDownloading = false
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-card_ebil.scss';
</style>
