var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "select-table-wrapper"
  }, [_c('table', {
    staticClass: "select-table"
  }, [_c('tr', {
    staticClass: "select-table-header"
  }, [_c('th', {
    attrs: {
      "width": "633px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('상품 정보')))])]), _c('th', {
    attrs: {
      "width": "100px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('수량')))])]), _c('th', {
    attrs: {
      "width": "135px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('상품금액')))])]), _c('th', {
    attrs: {
      "width": "130px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.tt('배송비')))])])]), _vm._l(_vm.productInfoList, function (product, index) {
    return _c('tr', {
      key: "product_".concat(index),
      staticClass: "select-table-body"
    }, [_c('td', [_c('option-info', {
      attrs: {
        "product": product,
        "useSale": _vm.useSale
      }
    })], 1), _c('td', [_c('div', [_c('div', [_vm._v(_vm._s(product.quantity))])])]), _c('td', [_c('div', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(product.sellingPrice))))])]), product.discountPrice > 0 ? _c('div', {
      staticStyle: {
        "text-decoration": "line-through",
        "color": "#999"
      }
    }, [_vm._v(" " + _vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(product.unitPrice * product.quantity))) + " ")]) : _vm._e()])]), _c('td', [_c('div', [_c('div', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(product.deliveryFee ? product.deliveryFee : 0))))])])])]);
  })], 2), _c('product-info-price-table', {
    attrs: {
      "total": _vm.totalPrice,
      "deliveryFee": _vm.totalDeliveryFee,
      "sale": _vm.totalSalePrice,
      "vatPrice": _vm.vatPrice
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }