var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal-filter', {
    ref: "modal",
    on: {
      "confirm": _vm.confirm,
      "reset": _vm.reset
    },
    scopedSlots: _vm._u([_vm.filter ? {
      key: "modal-content",
      fn: function fn() {
        return [_c('filter-list', {
          ref: "filter-budget",
          attrs: {
            "title": "예산",
            "filterCookieName": _vm.filterCookieName.budgetsId,
            "list": _vm.filter.budgets
          }
        }), _c('filter-list', {
          ref: "filter-group",
          attrs: {
            "title": "주문 연구실",
            "filterCookieName": _vm.filterCookieName.draftGroupsId,
            "list": _vm.filter.groups
          }
        })];
      },
      proxy: true
    } : null], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }