<template>
	<div class="approval-info-item">
		<div class="header">
			<h5>{{ tt(approvalTitle) }}</h5>
		</div>
		<div class="content">
			<div>
				<p v-if="approvalState" :class="approvalState">
					{{ tt(status ? status : '-') }}
				</p>
				<h6>{{ tt(name) }}</h6>
				<span v-b-tooltip.hover="affiliation">{{ tt(affiliation) }}</span>
			</div>
		</div>
		<div class="footer" v-if="isFooter">
			<span>{{ dateTime ? dateTime : '-' }}</span>
		</div>
	</div>
</template>

<script>
// TODO: 공용 컴포넌트화?
// import { DraftCode } from '@/utils/define/jw/JwCode'

export default {
	name: 'ApprovalInfoItem',
	props: {
		approvalTitle: {
			type: String,
			default: '',
		},
		approvalState: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		affiliation: {
			type: String,
			default: '',
		},
		dateTime: {
			type: String,
			default: '',
		},
		isFooter: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		status() {
			switch (this.approvalState) {
				case 'NOW':
					return '결재대기'
				case 'WAIT':
					return '대기'
				case 'ACCEPT':
					return '승인'
				case 'REJECT':
					return '반려'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.approval-info-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 180px;
	height: 100%;
	border: 1px solid $DIVIDER;
	border-radius: 8px;
	div {
		width: 100%;
		text-align: center;
		&.header {
			height: 35px;
			background: $GREY_1;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			border-bottom: 1px solid $DIVIDER;
		}
		&.content {
			height: 90px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&.footer {
			border-top: 1px solid $DIVIDER;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				padding-top: 0;
			}
		}
		h5 {
			line-height: 35px;
			font-size: 0.75rem;
			font-weight: 700;
			color: $SECONDARY_2;
		}
		p {
			font-size: 0.875rem;
			font-weight: 700;
			height: 21px;
			&.ACCEPT {
				color: $GREEN;
			}
			&.REJECT {
				color: $RED;
			}
		}
		h6 {
			padding-top: 10px;
			font-size: 0.75rem;
			font-weight: 400;
		}
		span {
			font-weight: 400;
			font-size: 0.75rem;
			padding-top: 2px;
			color: $SECONDARY_2;
			display: block;
		}
	}
}
</style>
