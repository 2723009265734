var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-field-wrap"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.fieldName)))]), _vm.isRequire && _vm.isData == null ? _c('div', {
    staticClass: "field-data-wrap-red"
  }, [_vm._v(_vm._s(_vm.tt('미입력')))]) : _vm.isData ? _c('div', {
    staticClass: "field-tag-wrap"
  }, _vm._l(_vm.tag, function (t) {
    return _c('div', {
      key: t.id,
      staticClass: "tag"
    }, [_vm._v(_vm._s("#".concat(t.name)))]);
  }), 0) : _c('div', {
    staticClass: "field-tag-wrap"
  }, [_c('div', {
    staticClass: "field-text"
  }, [_vm._v("-")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }