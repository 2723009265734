import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'
import { logout } from '../../common'

const endPoint = {
	lookupUserInfo: '/user',
	lookupUserInstInfo: '/user/institute',
	sendEmailCocde: '/user/email/code',
	login: '/user/signin',
	logout: '/user/signout',
	lookupNotice: (offset, length, instituteId) => `user/notification/list?offset=${offset}&length=${length}&instituteId=${instituteId}`,
	readNotice: '/user/notification/read',
	readAllNotice: '/user/notification/read/all',
	deleteNotice: ids => `/user/notification?${ParamMaker.setArrayString('ids', ids)}`,
	lookupInstitutionInfo: instId => `/insitute/${instId}`,
	lookupUserPermission: '/user/permission/essence',
	loadExUserToken: '/user/ex',
}

const userAPI = {
	lookupUserInfo: () => {
		return axiosInstance.get(endPoint.lookupUserInfo)
	},
	lookupUserInstInfo: () => {
		return axiosInstance.get(endPoint.lookupUserInstInfo)
	},
	updateUserInfo: body => {
		return axiosInstance.put(endPoint.lookupUserInfo, body)
	},
	sendEmailCocde: body => {
		return axiosInstance.post(endPoint.sendEmailCocde, body)
	},
	login: () => {
		return axiosInstance.post(endPoint.login)
	},
	logout: () => {
		logout()
		return axiosInstance.post(endPoint.logout)
	},
	withdrawal: () => {
		return axiosInstance.delete(endPoint.lookupUserInfo)
	},
	lookupNotice: (offset, length, instituteId) => {
		return axiosInstance.get(endPoint.lookupNotice(offset, length, instituteId))
	},
	readNotice: ids => {
		return axiosInstance.put(endPoint.readNotice, ids)
	},
	readAllNotice: () => {
		return axiosInstance.put(endPoint.readAllNotice)
	},
	deleteNotice: ids => {
		return axiosInstance.delete(endPoint.deleteNotice(ids))
	},
	lookupInstitutionInfo: () => axiosInstance.get(endPoint),
	lookupUserPermission: () => axiosInstance.get(endPoint.lookupUserPermission),
	loadExUserToken: () => axiosInstance.post(endPoint.loadExUserToken),
}

export default userAPI
