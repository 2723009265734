<template>
	<b-modal ref="search-modal" no-close-on-backdrop hide-footer centered @close="cancel">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="search-wrap">
				<div class="search-box-main-wrap">
					<div class="search-box-main">
						<div class="search-box-wrap">
							<search-box
								@change="changeKeyword"
								:hasQR="forRegistItem"
								@clickQR="$refs['modal-search-qr'].show('product')"
							></search-box>
						</div>
						<button class="button-gray" @click="isUseSecondKeyword = !isUseSecondKeyword">
							{{ tt('결과 내 검색') }}
							<img class="chevron" v-if="!isUseSecondKeyword" src="./svg/chevron-down.svg" />
							<img class="chevron" v-else src="./svg/chevron-up.svg" />
						</button>
					</div>
					<button class="button-gray" v-if="forRegistItem" @click="inputDirect">{{ tt('직접 입력하기') }}</button>
				</div>
				<div class="search-box-sub" v-if="isUseSecondKeyword">
					<search-box
						:value="listParams.secondKeyword"
						@change="changeSecondKeyword"
						:placeholder="tt('결과 내 검색')"
						noRecent
					></search-box>
				</div>
			</div>
			<div class="table-wrap" ref="scroll-container" @scroll="onScroll">
				<table>
					<search-product-header :isChemical="isChemical"></search-product-header>
					<search-product-item
						v-for="(item, i) in list"
						:key="`${item.name}-${i}`"
						:item="item"
						:isChemical="isChemical"
						@click="item => clickProductItem(item)"
					></search-product-item>
				</table>
				<div class="empty-table" v-if="isEmptyData">
					<p class="bold" v-if="!isSearched">{{ tt(nodataTitle) }}</p>
					<p class="bold" v-else>{{ tt('결과 없음') }}</p>
					<p class="sub" v-if="!isSearched" v-html="tt(nodataContent)"></p>
					<p class="sub" v-else v-html="tt('검색결과가 없습니다.<br/>물품 정보를 직접입력해주세요')"></p>
					<button class="button-border-black" @click="inputDirect" v-if="isSearched">{{ tt('직접 입력하기') }}</button>
				</div>
			</div>
		</template>
		<modal-search-product-using-qr ref="modal-search-qr" @scan="searchUsingScan"></modal-search-product-using-qr>
	</b-modal>
</template>

<script>
import SearchBox from '@/views/components/searchBox/index.vue'
import SearchProductHeader from '@/views/common/components/modalGen2/components/modalSearchProduct/header/SearchProductHeader.vue'
import SearchProductItem from '@/views/common/components/modalGen2/components/modalSearchProduct/item/SearchProductItem.vue'
import ModalSearchProductUsingQr from '@/views/common/components/modalGen2/ModalSearchProductUsingQr.vue'
import MixinScrolling from '@/mixins/scrolling'
import throttle from 'lodash/throttle'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
	name: 'ModalSearchProduct',
	components: { SearchBox, SearchProductHeader, SearchProductItem, ModalSearchProductUsingQr },
	mixins: [MixinScrolling],
	props: {
		title: {
			type: String,
			default: '',
		},
		categoryInfo: {
			type: Object,
			default: null,
		},
		forRegistItem: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isUseSecondKeyword: false,
			attemptCount: 0,
			isSearching: false,
		}
	},
	watch: {
		isUseSecondKeyword(value) {
			if (!value) {
				this.setListParams({ ...this.listParams, secondKeyword: '' })
			}
		},
		isBottom(value) {
			if (value) {
				this.addSearchProduct()
			}
		},
	},
	computed: {
		...mapGetters('searchProduct', ['count', 'list', 'listParams', 'isSearched']),
		isEmptyData() {
			return this.count < 1
		},
		nodataTitle() {
			return '물품 검색'
		},
		nodataContent() {
			if (this.categoryInfo) {
				switch (this.categoryInfo.type) {
					case 'CAT_CHEMICAL_REAGENT':
					case 'CAT_CHEMICAL_RAWMATERIAL':
					case 'CAT_CHEMICAL_STANDARD':
					case 'CAT_CHROMATOGRAPHY':
						return '물품명, CAS No, 제품번호, 브랜드로 검색해주세요'
					case 'CAT_CHEMICAL_MADE_REAGENT':
					case 'CAT_CHEMICAL_GAS':
						return '물품명, CAS No로 검색해주세요'
					case 'CAT_LABWARE_TOOL':
					case 'CAT_LABWARE_EQUIPMENT':
						return '물품명, 제품번호, 브랜드, 모델명으로 검색해주세요'
					case 'CAT_PROTECTION':
						return '물품명, 브랜드로 검색해주세요'
					case 'CAT_ETC':
						return '물품명으로 검색해주세요'
					default:
						return ''
				}
			} else {
				return ''
			}
		},
		isChemical() {
			if (this.categoryInfo) {
				switch (this.categoryInfo.type) {
					case 'CAT_CHEMICAL_REAGENT':
					case 'CAT_CHEMICAL_RAWMATERIAL':
					case 'CAT_CHEMICAL_STANDARD':
					case 'CAT_CHROMATOGRAPHY':
					case 'CAT_CHEMICAL_MADE_REAGENT':
					case 'CAT_CHEMICAL_GAS':
						return true
					default:
						return false
				}
			} else {
				return false
			}
		},
	},
	mounted() {
		// this.setDefault()
	},
	methods: {
		...mapMutations('searchProduct', ['setListParams', 'setDefault']),
		...mapActions('searchProduct', ['getSearchProduct', 'addSearchProduct', 'getSearchProductUsingQr']),
		show() {
			this.setDefault()
			this.attemptCount = 0
			this.$refs['search-modal'].show()
		},
		hide() {
			this.$refs['search-modal'].hide()
			this.setDefault()
		},
		cancel() {
			this.hide()
			this.$emit('cancel')
		},
		async changeKeyword(keyword) {
			this.$emit('setRegistType', 'MANUAL_SEARCH')
			if (keyword) {
				this.attemptCount += 1
				await this.setListParams({
					...this.listParams,
					categoryType: this.categoryInfo.type,
					keyword: keyword,
					attempt: this.attemptCount,
					type: 'lme-search',
				})
				await this.getSearchProduct()
			} else {
				this.setDefault()
			}
		},
		async changeSecondKeyword(keyword) {
			this.$emit('setRegistType', 'MANUAL_SEARCH')
			this.attemptCount += 1
			await this.setListParams({
				...this.listParams,
				categoryType: this.categoryInfo.type,
				secondKeyword: keyword,
				attempt: this.attemptCount,
				type: 'lme-search',
			})
			await this.getSearchProduct()
		},
		onScroll: throttle(function ({ target: { scrollTop, clientHeight, scrollHeight } }) {
			if (scrollTop > this.lastScrollTop) {
				this.scrollDirection = 'DOWN'
			} else {
				this.scrollDirection = 'UP'
			}
			this.isBottom = this.scrollDirection == 'DOWN' && scrollHeight - clientHeight - 300 < scrollTop
			this.lastScrollTop = scrollTop
		}, 100),
		async clickProductItem(item) {
			this.$emit('selectItem', item)
			await this.setListParams({
				...this.listParams,
				categoryType: this.categoryInfo.type,
				attempt: this.attemptCount,
				type: 'lme-search-success',
			})
			await this.getSearchProduct()
			this.hide()
		},
		inputDirect() {
			this.$emit('setRegistType', 'MANUAL_DIRECT')
			this.$emit('inputDirect')
			this.$refs['search-modal'].hide()
		},
		async searchUsingScan(scannedQr) {
			const newListParam = this.$_.cloneDeep(this.listParams)
			newListParam.qrBarcode = scannedQr
			await this.setListParams({ ...newListParam, categoryType: this.categoryInfo.type, keyword: '', length: 20, offset: 0 })

			this.$refs['modal-search-qr'].setIsLoading(true)
			await this.getSearchProductUsingQr()
			this.$refs['modal-search-qr'].setIsLoading(false)

			this.$refs['modal-search-qr'].hide()
			if (!this.count) {
				this.$root.toast('일치하는 데이터가 없음', '등록할 물품을 검색해주세요', 'warning')
			} else {
				this.$emit('setRegistType', 'MANUAL_QR')
				if (this.count === 1) {
					this.$emit('selectItem', this.list[0])
					this.hide()
				}
				this.$root.toast('QR 스캔 성공', '일치하는 데이터를 불러옵니다', 'success')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-search-product.scss';
</style>
