var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "Modal ModalMSDSFileChange Popup__depth2"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(" " + _vm._s(_vm.tt('업로드된 MSDS 파일변경')) + " ")]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.cancel
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('p', {
    staticClass: "Popup__plain-text",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('정말로 업로드된 MSDS의 파일을 대체하시겠습니까?<br>기존 파일은 사라집니다'))
    }
  })]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__edit"
  }, [_c('input', {
    ref: "changeFile",
    attrs: {
      "type": "file",
      "accept": ".pdf"
    },
    on: {
      "change": _vm.changeFile
    }
  }), _vm._v(_vm._s(_vm.tt('파일변경')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }