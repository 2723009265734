import Vue from 'vue'
import commonLng from '@/locales/commonLng.json'
import popupLng from '@/locales/popupLng.json'
import pageLng from '@/locales/pageLng.json'
import en from '@/locales/_en.json'
import ghs from '@/locales/_ghs.json'
import { Check } from '@/utils/define/Translation'

export const translation = {
	install(Vue) {
		Vue.prototype.tt = function (k, ...args) {
			if (!k) return
			let key = String(k)
			let result = key
			if (Check.English) {
				if (popupLng[this.$options.name] && popupLng[this.$options.name][key]) {
					result = popupLng[this.$options.name][key]
				}

				// if (
				// 	pageLng[this.$options.name] &&
				// 	pageLng[this.$options.name][key]
				// ) {
				// 	result = pageLng[this.$options.name][key]
				// }

				if (commonLng[key]) {
					result = commonLng[key]
				}

				if (key && en[key.slice(0, key.length - 1)]) {
					result = en[key.slice(0, key.length - 1)]
				}
				if (en[`${key}.`]) {
					result = en[`${key}.`]
				}
				if (ghs[key]) {
					result = ghs[key]
				}

				if (en[key]) {
					result = en[key]
				}
			}
			for (let i = 0; i < args.length; i++) {
				result = result.replace(new RegExp('\\$\\{' + (i + 1) + '\\}', 'g'), args[i])
			}
			return result
		}
	},
}

export default translation
