<template>
	<floating-menu :count="selectItemIdLength">
		<div class="button-list-wrapper">
			<div
				v-if="isIncludeCompleteSelectItem || !isAllReadySelectItem"
				class="button-wrapper disable"
				v-b-tooltip.hover.bottom
				:title="'입고처리할 수 없는 항목이 선택되었습니다'"
			>
				<img class="button-img" src="@/assets/svg/purchase/icon-cargo-disable.svg" />
				<div class="button-text">{{ tt('입고처리') }}</div>
			</div>
			<div v-else class="button-wrapper" @click="$emit('cargo')">
				<img class="button-img" src="@/assets/svg/purchase/icon-cargo-enable.svg" />
				<div class="button-text">{{ tt('입고처리') }}</div>
			</div>

			<div class="button-wrapper" :class="{ disable: isPrintDisabled }" @click="showModalSticker">
				<img class="button-img" :src="isPrintDisabled ? iconPrintDisable : iconPrintEnable" />
				<div class="button-text">{{ tt('스티커 출력') }}</div>
			</div>

			<div class="button-wrapper" @click.stop="$emit('delete')">
				<img class="button-img" :src="iconDeleteEnable" />
				<div class="button-text">{{ tt('삭제') }}</div>
			</div>
		</div>
		<modal-print-sticker ref="modal-print-sticker" :selectedCargoId="selectItemId"></modal-print-sticker>
	</floating-menu>
</template>

<script>
import FloatingMenu from '@/views/common/components/floatingMenu/FloatingMenu.vue'
import ModalPrintSticker from '@/views/purchase/cargo/components/cargoList/modal/ModalPrintSticker.vue'
import IconPrintEnable from '@/assets/svg/purchase/icon-print-enable.svg'
import IconPrintDisable from '@/assets/svg/purchase/icon-print-disable.svg'
import IconDeleteEnable from '@/assets/svg/purchase/icon-trash-enable.svg'
import IconDeleteDisable from '@/assets/svg/purchase/icon-trash-disable.svg'
import { mapGetters } from 'vuex'

export default {
	components: { FloatingMenu, ModalPrintSticker },
	props: {},
	data() {
		return {
			iconPrintEnable: IconPrintEnable,
			iconPrintDisable: IconPrintDisable,
			iconDeleteEnable: IconDeleteEnable,
			iconDeleteDisable: IconDeleteDisable,
		}
	},
	computed: {
		...mapGetters('cargo/list', ['selectItemId', 'isIncludeCompleteSelectItem', 'isAllReadySelectItem']),
		isPrintDisabled() {
			return false
		},
		selectItemIdLength() {
			return this.selectItemId?.length
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		showModalSticker() {
			this.$refs?.['modal-print-sticker'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/floatingMenu/_floating-button-list.scss';
</style>
