<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			{{ tt('라이선스 구매') }}
		</template>
		<template>
			<LicenseSelector @change="changePlan"> </LicenseSelector>
			<div class="license-select-wrapper">
				<div>
					<p>{{ tt('현재') }}</p>
					<div class="flex">
						<p v-if="isTrial || isFreeTier || !isLicenseActivate">{{ tt('멤버 수') }}</p>
						<p v-else>{{ tt('멤버 수 / 라이선스') }}</p>
						<span class="license-member">
							<span
								class="license-now-member"
								:class="{
									red: instInfo.allMemberCount >= this.license.quantity,
								}"
							>
								{{ formatNumberLocale(instInfo.allMemberCount) }}&nbsp;
							</span>
							<span class="license-quantity">
								{{
									!(isTrial || isFreeTier || !isLicenseActivate)
										? ' / ' + (license.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(license.quantity))
										: ''
								}}
							</span>
						</span>
					</div>
				</div>
				<div>
					<p>{{ tt('구매할 라이선스') }}</p>
					<div
						class="num-box"
						:class="{
							warning: isLowerQuantityThanMember,
						}"
					>
						<button :disabled="quantity < 2 || isLowerQuantityThanMember || plan == type.EDUCATION" @click="reduceCount">
							<img src="@/assets/svg/input-minus.svg" />
						</button>
						<p class="license-count-unit" v-if="plan == type.EDUCATION">
							{{ tt('무제한') }}
						</p>
						<input
							v-else
							class="license-count"
							v-model="quantity"
							type="number"
							min="1"
							pattern="[0-9]+"
							@onkeydown="preventInputE()"
							:class="{
								red: isLowerQuantityThanMember,
							}"
						/>
						<button @click="addCount" :disabled="plan == type.EDUCATION">
							<img src="@/assets/svg/input-plus.svg" />
						</button>
					</div>
				</div>
				<div class="input-warning" v-if="isLowerQuantityThanMember">
					<img src="@/assets/svg/input-icon-warning.svg" />
					<p>
						{{ tt('라이선스 수는 멤버수 보다 적을 수 없습니다.') }}
					</p>
				</div>
				<div>
					<p>{{ tt('사용기한 선택') }}</p>
					<div class="num-box">
						<button :class="{ cant: month < 2 || (month <= 12 && !isMonthly) }" @click="reducePeriod">
							<img src="@/assets/svg/input-minus.svg" />
						</button>
						<p class="license-count-unit">{{ tt('${1}개월', month) }}</p>
						<button :class="{ cant: month >= 11 && isMonthly }" @click="addPeriod" id="add-period">
							<img src="@/assets/svg/input-plus.svg" />
						</button>
					</div>
					<b-tooltip v-if="month >= 11 && isMonthly" target="add-period" triggers="hover">
						{{ tt('12개월 이상은 연간 결제를 이용하세요') }}
					</b-tooltip>
				</div>
				<div class="license-date">
					<ul>
						<li>
							<p>{{ tt('라이선스 적용기간') }}</p>
							<span>{{ licensePurchase.period }}</span>
						</li>
						<li>
							<p>{{ tt('라이선스 적용일수') }}</p>
							<span v-if="licensePurchase.chargedMonths">
								{{ licensePurchase.chargedMonths ? formatNumberLocale(licensePurchase.chargedMonths) + tt('개월') : '' }}
								{{ licensePurchase.chargedDays ? formatNumberLocale(licensePurchase.chargedDays) + tt('일') : '' }}
							</span>
							<span v-else>
								{{ licensePurchase.month ? formatNumberLocale(licensePurchase.month) + tt('개월') : '' }}
							</span>
						</li>
					</ul>
				</div>
				<div class="license-price">
					<ul>
						<li>
							<p>{{ tt('소계') }}</p>
							<span> ￦{{ formatNumberLocale(licensePurchase.price) }} </span>
						</li>
						<li>
							<p>VAT(10%)</p>
							<span> ￦{{ formatNumberLocale(licensePurchase.vat) }} </span>
						</li>
						<li>
							<h3>{{ tt('합계') }}</h3>
							<h2>￦{{ formatNumberLocale(licensePurchase.totalPrice) }}</h2>
						</li>
					</ul>
				</div>
				<div class="license-payment">
					<h5>{{ tt('결제수단') }}</h5>
					<div>
						<label class="card-registered-label">
							<div class="input-div" @click="selectedPayment = paymentMethod.CARD_REGISTERED">
								<img src="@/assets/svg/radio-off.svg" v-if="selectedPayment != paymentMethod.CARD_REGISTERED" />
								<img src="@/assets/svg/radio-on.svg" v-else />
								{{ tt('카드 정기결제') }}
							</div>
							<div
								class="change-card"
								v-if="selectedPayment == paymentMethod.CARD_REGISTERED && hasDefaultCreditCard"
								@click="editCard"
							>
								<span>{{ tt('결제카드 변경') }}</span>
								<img src="@/assets/svg/icon-blue-right-arrow.svg" width="16" height="24" />
							</div>
						</label>
						<div class="card-box" v-if="hasDefaultCreditCard && selectedPayment == paymentMethod.CARD_REGISTERED">
							<div class="imgbox">
								<img src="@/assets/svg/card.svg" />
							</div>
							<div class="txtbox">
								<p>
									{{ defaultCreditCard.issuer + tt('카드') }}

									({{ defaultCreditCard.number }})
								</p>
							</div>
						</div>
						<label @click="selectedPayment = paymentMethod.ELECTRONIC_BILL">
							<img src="@/assets/svg/radio-off.svg" v-if="selectedPayment != paymentMethod.ELECTRONIC_BILL" />
							<img src="@/assets/svg/radio-on.svg" v-else />
							{{ tt('전자세금계산서') }}
						</label>
					</div>
				</div>
				<div class="license-agree">
					<div class="agreement-box">
						<label style="cursor: pointer">
							<input type="checkbox" v-model="agreement" />
							{{ tt('(필수) 이용약관을 확인하였으며, 구매 진행에 동의합니다.') }}
						</label>
						<a href="https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7" target="_blank">
							{{ tt('보기') }}
						</a>
					</div>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<button class="button-primary" :disabled="disableConfirmButton" @click="confirm">
				{{ tt('결제하기') }}
			</button>
		</template>
		<ModalCard ref="ModalCard" />
		<ModalEbill ref="ModalEbill" />
	</b-modal>
</template>
<script>
import ModalCard from './ModalCard.vue'
import ModalEbill from './ModalEbill.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
import LicenseSelector from '../components/LicenseSelector.vue'
export default {
	components: {
		LicenseSelector,
		ModalCard,
		ModalEbill,
	},
	mixins: [MixinLicense, MixinPayment],
	data() {
		return {
			quantity: 1,
			month: 12,
			plan: 'STANDARD',
			selectedPayment: 'CARD_REGISTERED',
			agreement: false,
		}
	},
	computed: {
		isLowerQuantityThanMember() {
			return this.quantity < this.memberCount
		},
		disableConfirmButton() {
			return !this.agreement || this.isLowerQuantityThanMember
		},
	},
	watch: {
		async isMonthly() {
			if (!this.isMonthly) {
				this.month = 12
			} else {
				this.month = 1
			}
			await this.loadLicensePurchaseInfo({
				plan: this.plan,
				quantity: this.quantity,
				month: this.month,
			})
		},
	},
	methods: {
		async show() {
			await this.initData()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async initData() {
			this.quantity = this.memberCount
			this.month = 12
			this.plan = 'STANDARD'
			this.selectedPayment = 'CARD_REGISTERED'
			this.agreement = false
			await this.loadLicensePurchaseInfo({
				plan: this.plan,
				quantity: this.quantity,
				month: this.month,
			})
			await this.loadDefaultCreditCard()
		},
		async reduceCount() {
			this.quantity--
			if (this.quantity <= 0) this.quantity = 1
			await this.loadLicensePurchaseInfo({
				plan: this.plan,
				quantity: this.quantity,
				month: this.month,
			})
		},
		async addCount() {
			this.quantity++
			await this.loadLicensePurchaseInfo({
				plan: this.plan,
				quantity: this.quantity,
				month: this.month,
			})
		},
		async reducePeriod() {
			if (!this.isMonthly) {
				this.month -= 12
				if (this.month <= 0) this.month = 12
			} else {
				this.month--
				if (this.month <= 0) this.month = 1
			}
			await this.loadLicensePurchaseInfo({
				plan: this.plan,
				quantity: this.quantity,
				month: this.month,
			})
		},
		async addPeriod() {
			if (!this.isMonthly) {
				this.month += 12
			} else {
				this.month++
				if (this.month >= 12) this.month = 11
			}
			await this.loadLicensePurchaseInfo({
				plan: this.plan,
				quantity: this.quantity,
				month: this.month,
			})
		},
		async changePlan(value) {
			this.plan = value
			if (this.plan == this.type.EDUCATION) {
				this.quantity = 99999
				this.month = 12
			} else {
				this.quantity = this.memberCount > 0 ? this.memberCount : 1
			}
			await this.loadLicensePurchaseInfo({
				plan: this.plan,
				quantity: this.quantity,
				month: this.month,
			})
		},
		confirm() {
			if (this.selectedPayment == this.paymentMethod.CARD_REGISTERED) {
				if (this.hasDefaultCreditCard) {
					this.purchaseLicense({ cardId: this.defaultCreditCard.id })
				} else {
					// 카드 등록
					this.$refs['ModalCard'].show('REGISTER', cardId => this.purchaseLicense({ cardId }))
				}
			} else {
				this.$refs['ModalEbill'].show(ebillId => this.purchaseLicense({ ebillId }))
				// 전자세금 계산서
			}
		},
		editCard() {
			this.$refs['ModalCard'].show('EDIT', async cardId => {
				await this.loadDefaultCreditCard()
			})
		},
		async purchaseLicense({ cardId, ebillId }) {
			try {
				// 카드로 베이직 구매
				await this.purchaseLicenseBasic({
					isSubscribe: true,
					cardId: cardId,
					ebillId: ebillId,
					quantity: this.quantity,
					month: this.month,
					plan: this.plan,
					paymentMethod: this.selectedPayment,
				})
				this.$emit('complete')
				this.hide()
			} catch (e) {
				console.log(e)
				this.$root.toast('결제 실패', e?.response?.data?.msg ?? '결제에 실패하였습니다. 결제정보를 다시 확인해주세요', 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-purchase.scss';
</style>
