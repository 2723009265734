<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<input type="checkbox" class="checkbox" v-model="allCheck" :class="{ someChecked: !allCheck && !isEmptySelectedItems }" />
		</th>
		<th>
			<span>{{ tt('상태') }}</span>
		</th>
		<th>
			<span>{{ tt('카테고리') }}</span>
		</th>
		<th>
			<span>{{ tt('이미지 및 파일') }}</span>
		</th>
		<th>
			<span>{{ tt('등록자') }}</span>
		</th>
		<th>
			<span>{{ tt('제출일시') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapState('inventoryGen2/wait', ['list', 'selectedItems', 'checkedViewOption']),
		...mapGetters('inventoryGen2/wait', ['order', 'orderDirection', 'isEmptySelectedItems']),
		allCheck: {
			get() {
				return this.list.every(it => this.selectedItems.map(sit => sit.id).includes(it.id)) && this.list.length !== 0
			},
			set(val) {
				if (val) this.setSelectedItems(this.list)
				else this.setSelectedItems([])
			},
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapMutations('inventoryGen2/wait', ['setSelectedItems']),
		...mapActions('inventoryGen2/wait', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
