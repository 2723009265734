<template>
	<portal to="lm-modal" v-if="$root.institute && $root.user">
		<!-- ModalLicensePayment -->
		<!-- 라이선스 구매(buying), 추가 모달(add)-->
		<!-- 정기결제 관리(setting) -->
		<template v-if="isShow">
			<div class="Modal ModalManagePayment">
				<div class="Popup__centered">
					<div class="Popup__container Popup__size-sm">
						<div class="Popup__top">
							<h5 class="Popup__title" v-text="modalPurchaseTitle"></h5>
							<button class="Popup__close-btn" @click="close">
								<img src="@/assets/svg/popup-close.svg" alt="close" />
							</button>
						</div>
						<div class="Popup__content">
							<div class="license-info">
								<div class="license-summary" v-if="isAdditionMode || isManagementMode">
									<div class="imgbox">
										<img src="@/assets/images/lm-pro-icon.png" />
									</div>
									<div class="txtbox">
										<h6>{{ licenseTypeText }}</h6>
										<p v-if="isAdditionMode">
											{{ tt('${1}원 / (계정당 ・ VAT 별도)', formatNumberLocale(getAdditonalPrice)) }}
										</p>
										<p v-else>
											{{ tt('${1}원 / (계정당 ・ VAT 별도)', formatNumberLocale(getPurchasePrice)) }}
										</p>
									</div>
								</div>
								<!-- 라이선스 선택 -->
								<license-selector v-else @licenseType="getPlan"></license-selector>
								<button
									class="Btn__pink"
									v-if="showUnsubscribeButton && userPermission.license && userPermission.license.delete"
									@click="showModalUnsubscribe"
								>
									{{ tt('정기결제 해제') }}
								</button>
								<div class="license-num">
									<div>
										<p>{{ tt('현재') }}</p>
										<div class="flex">
											<p v-if="isTrial">{{ tt('멤버 수') }}:</p>
											<p v-else>{{ tt('멤버 수 / 라이선스') }}:</p>
											<span class="license-member">
												<span
													class="license-now-member"
													:class="{
														red: instInfo.allMemberCount >= this.license.quantity,
													}"
												>
													{{ formatNumberLocale(instInfo.allMemberCount) }}&nbsp;
												</span>
												<span class="license-quantity">
													{{
														!isTrial
															? ' / ' +
															  (license.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(license.quantity))
															: ''
													}}
												</span>
											</span>
										</div>
									</div>
									<div>
										<p>{{ tt('구매할 라이선스') }}</p>
										<div
											class="num-box"
											:class="{
												warning: isLowerQuantityThanMember,
											}"
										>
											<button
												:disabled="quantity < 2 || isLowerQuantityThanMember || plan == type.EDUCATION"
												@click="reduceCount"
											>
												<img src="@/assets/svg/input-minus.svg" />
											</button>
											<p class="license-count-unit" v-if="plan == type.EDUCATION">
												{{ tt('무제한') }}
											</p>
											<input
												v-else
												class="license-count"
												v-model="quantity"
												type="number"
												min="1"
												pattern="[0-9]+"
												@onkeydown="preventInputE()"
												:class="{
													red: isLowerQuantityThanMember,
												}"
											/>
											<button @click="addCount" :disabled="plan == type.EDUCATION">
												<img src="@/assets/svg/input-plus.svg" />
											</button>
										</div>
									</div>
									<div class="input-warning" v-if="isLowerQuantityThanMember">
										<img src="@/assets/svg/input-icon-warning.svg" />
										<p>
											{{ tt('라이선스 수는 멤버수 보다 적을 수 없습니다.') }}
										</p>
									</div>
									<div v-if="isPurchaseMode">
										<p>{{ tt('사용기한 선택') }}</p>
										<div class="num-box">
											<button
												:class="{ cant: month < 2 || (month <= 12 && !isMonthly && isPurchaseMode) }"
												@click="reducePeriod"
											>
												<img src="@/assets/svg/input-minus.svg" />
											</button>
											<p class="license-count-unit">{{ tt('${1}개월', month) }}</p>
											<button
												:class="{ cant: (month >= 11 && isMonthly && isPurchaseMode) || (month >= 12 && !isPurchaseMode) }"
												@click="addPeriod"
												id="add-period"
											>
												<img src="@/assets/svg/input-plus.svg" />
											</button>
										</div>
										<b-tooltip v-if="month >= 11 && isMonthly && isPurchaseMode" target="add-period" triggers="hover">
											{{ tt('12개월 이상은 연간 결제를 이용하세요') }}
										</b-tooltip>
									</div>
								</div>
								<div class="license-date">
									<ul>
										<li>
											<p>{{ tt('라이선스 적용기간') }}</p>
											<span>{{ licensePurchase.period }}</span>
										</li>
										<li>
											<p>{{ tt('라이선스 적용일수') }}</p>
											<span v-if="licensePurchase.chargedMonths">
												{{
													licensePurchase.chargedMonths
														? formatNumberLocale(licensePurchase.chargedMonths) + tt('개월')
														: ''
												}}
												{{ licensePurchase.chargedDays ? formatNumberLocale(licensePurchase.chargedDays) + tt('일') : '' }}
											</span>
											<span v-else>
												{{ licensePurchase.month ? formatNumberLocale(licensePurchase.month) + tt('개월') : '' }}
											</span>
										</li>

										<li v-if="isAdditionMode">
											<p>
												<span class="bold">
													{{
														licensePurchase.chargedMonths
															? formatNumberLocale(licensePurchase.chargedMonths) + tt('개월')
															: ''
													}}
													{{
														licensePurchase.chargedDays ? formatNumberLocale(licensePurchase.chargedDays) + tt('일') : ''
													}}
												</span>
												<span>
													{{ tt('에 대한 비례 결제금액') }}
												</span>
											</p>
											<span>
												{{ formatNumberLocale(licensePurchase.totalPrice) + '(VAT 포함)' }}
											</span>
										</li>
									</ul>
								</div>
								<div class="license-price">
									<ul>
										<li>
											<p>{{ tt('소계') }}</p>
											<span> ￦{{ formatNumberLocale(licensePurchase.price) }} </span>
										</li>
										<li>
											<p>VAT(10%)</p>
											<span> ￦{{ formatNumberLocale(licensePurchase.vat) }} </span>
										</li>
										<li>
											<h3>{{ tt('합계') }}</h3>
											<h2>￦{{ formatNumberLocale(licensePurchase.totalPrice) }}</h2>
										</li>
									</ul>
								</div>
								<div class="license-payment">
									<h5>{{ tt('결제수단') }}</h5>
									<!-- 정기결제 start -->
									<div v-if="!isManagementMode">
										<label v-if="hasDefaultCreditCard && isLicenseActivate && !isFreeTier" class="card-registered-label">
											<div class="input-div">
												<input type="radio" :value="paymentMethod.CARD_REGISTERED" v-model="selectedPayment" />
												{{ tt('등록된 카드로 결제') }}
											</div>
											<div
												class="change-card"
												v-if="isSelectedCardRegistered && !needUpdateCardError"
												@click="displayModalChangeCard = true"
											>
												<span>{{ tt('결제카드 변경') }}</span>
												<img src="@/assets/svg/icon-blue-right-arrow.svg" width="16" height="24" />
											</div>
										</label>
										<label v-else class="card-registered-label">
											<div class="input-div">
												<input type="radio" :value="paymentMethod.CARD_REGISTERED" v-model="selectedPayment" />{{
													tt('카드 정기결제')
												}}
											</div>
											<div
												class="change-card"
												v-if="isSelectedCardRegistered && hasDefaultCreditCard && defaultCreditCard.isValid"
												@click="displayModalChangeCard = true"
											>
												<span>{{ tt('결제카드 변경') }}</span>
												<img src="@/assets/svg/icon-blue-right-arrow.svg" width="16" height="24" />
											</div>
										</label>
										<div class="not-use-noti" v-if="isSelectedCardRegistered && needUpdateCardError">
											<p
												v-html="
													tt(
														'<span style=color:#ff3b31;>결제카드 정보</span>와 관련하여 문제가 발생했습니다. <br>결제카드를 업데이트해주세요'
													)
												"
											></p>
											<div
												class="change-card"
												v-if="isSelectedCardRegistered && hasDefaultCreditCard"
												@click="displayModalChangeCard = true"
											>
												<span>
													{{ tt('결제카드 변경') }}
												</span>

												<img src="@/assets/svg/icon-blue-right-arrow.svg" width="16" height="24" />
											</div>
										</div>
										<div class="card-box" v-if="hasDefaultCreditCard && selectedPayment == paymentMethod.CARD_REGISTERED">
											<div class="imgbox">
												<img src="@/assets/svg/card.svg" />
											</div>
											<div class="txtbox">
												<p>
													{{ defaultCreditCard.issuer + tt('카드') }}

													({{ defaultCreditCard.number }})
												</p>
											</div>
										</div>
										<label
											><input type="radio" :value="paymentMethod.ELECTRONIC_BILL" v-model="selectedPayment" />{{
												tt('전자세금계산서')
											}}</label
										>
									</div>

									<div v-else>
										<ul>
											<li>
												<p>{{ tt('카드번호') }}</p>
												<b>
													<span>
														{{ `${defaultCreditCard.issuer}${tt('카드')} **** **** **** ${defaultCreditCard.number}` }}
													</span>
												</b>
											</li>
										</ul>
										<button class="btn" @click="displayModalChangeCard = true">
											{{ tt('결제카드 변경') }}
										</button>
									</div>
								</div>
								<div class="license-bottom">
									<div class="agreement-box">
										<label>
											<input type="checkbox" v-model="agreement" />
											{{ tt('(필수) 이용약관을 확인하였으며, 구매 진행에 동의합니다.') }}
										</label>
										<a href="https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7" target="_blank">
											{{ tt('보기') }}
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="Popup__bottom" id="confirm-disabled">
							<!--  -->
							<button v-if="!isManagementMode" class="Btn__yellow" :disabled="disableConfirmButton" @click="doLicensePayment">
								{{ tt('결제하기') }}
							</button>
							<!-- 라이선스 구매일 때 -->
							<!-- <button
							v-else-if="!isSubscribePurchaseMode"
							class="Btn__yellow"
							:disabled="disableConfirmButton"
							@click="purchaseSubscribeLicense"
						>
							{{ tt('결제하기') }}
						</button> -->
							<!--  -->
							<button v-else class="Btn__yellow" :disabled="disableConfirmButton" @click="registOrEditSubscribeInformation">
								{{ tt('저장하기') }}
							</button>
							<b-tooltip v-if="disableConfirmButton" target="confirm-disabled">{{ disabledReason }}</b-tooltip>
						</div>
					</div>
				</div>
				<modal-regist-card :isShow="displayModalChangeCard" @close="displayModalChangeCard = false" mode="CHANGE"></modal-regist-card>
				<modal-confirm-delete-v-2
					ref="modal-unsubscribe"
					:title="tt('정기결제 해제')"
					:content="tt('정말로 정기결제를 해제하시겠습니까?')"
					:delBtnText="tt('해제')"
					:delBtnFn="unsubscribe"
				></modal-confirm-delete-v-2>
			</div>
			<div class="Popup__background"></div>
		</template>
	</portal>
</template>

<script>
import MixinLicense from '@/mixins/license'
import MixinPaymentCard from '@/mixins/payment'
import { mapMutations, mapState } from 'vuex'
import ModalRegistCard from '@/views/institute/information/modals/ModalRegistCard.vue'
import ModalConfirmDeleteV2 from '@/views/common/components/modals/ModalConfirmDeleteV2.vue'
import { ToastType } from '@/utils/define/ToastType'
import LicenseSelector from '@/views/institute/components/LicenseSelector.vue'

import evnetAPI from '@/services/api/event'

export default {
	name: 'ModalLicensePayment',
	components: { ModalRegistCard, ModalConfirmDeleteV2, LicenseSelector },
	mixins: [MixinLicense, MixinPaymentCard],
	props: {
		mode: {
			type: String,
			default: 'buying',
		},
		isShow: {
			type: Boolean,
			default: false,
		},
		// instInfo: {
		// 	type: Object,
		// 	default: () => {},
		// },
		isSubscribePurchaseMode: {
			type: Boolean,
			default: false,
		},
	},
	created() {},
	mounted() {
		console.log(this.defaultCreditCard, this.cardInfo)
	},
	data() {
		return {
			quantity: 1,
			month: 1,
			selectedPayment: 'CARD_REGISTERED',
			// selectedPayment: 'ELECTRONIC_BILL', // 정기결제 미배포시 설정
			agreement: false,
			plan: 'STANDARD',

			displayModalChangeCard: false,
		}
	},
	watch: {
		async isShow(value) {
			if (value) {
				this.getPlan(this.type.STANDARD)
				await this.loadLicenseManagement()
				const allMemberCount = this.instInfo.allMemberCount
				if (this.isAdditionMode || this.isPurchaseMode) {
					await this.loadLicenseRatePlan()
					this.month = 12
					if (this.isAdditionMode) {
						// 추가 구매시
						this.setMonthly(true)
						this.month = 1
						this.plan = this.license.type
						this.quantity = allMemberCount - this.license.quantity > 0 ? allMemberCount - this.license.quantity : 1
					} else {
						this.quantity = allMemberCount > 1 ? allMemberCount : 1
						this.setMonthly(false)
					}
				}
				if (this.isManagementMode) {
					// 정기결제 관리
					await this.loadDefaultCreditCard()

					if (this.beingSubscription) {
						// 기존 정기결제 정보 세팅
						this.quantity = this.licenseManagement.subscription.quantity
						this.month = this.licenseManagement.subscription.month
						this.plan = this.license.type
					}
				}
				this.loadInformation()
				this.initPurchaseForm()

				// 카드 에러시
				if (this.needUpdateCardError) {
					this.showToastNeedUpdateCard()
				}
			} else {
				this.initModal()
			}
		},
		async quantity(changeValue, beforeValue) {
			// 최대값 4자리 수
			if (changeValue > this.MAX_QUANTITY) {
				this.quantity = Number(beforeValue)
			}

			if (changeValue === '') this.quantity = 1
			if (this.isAdditionMode) {
				await this.loadLicenseAdditionInfo({
					quantity: this.quantity,
				})
			} else if (this.isPurchaseMode) {
				await this.loadLicensePurchaseInfo({
					plan: this.plan,
					quantity: this.quantity,
					month: this.month,
				})
			} else {
				await this.loadLicenseInfo4Subscription({
					plan: this.plan,
					quantity: this.quantity,
					month: this.month,
				})
			}
		},
		async month(changeValue, beforeValue) {
			// 최대값 3자리 수
			if (changeValue > this.MAX_MONTH) {
				this.month = Number(beforeValue)
			}

			if (changeValue === '') this.month = 1
			if (this.isPurchaseMode) {
				await this.loadLicensePurchaseInfo({
					quantity: this.quantity,
					month: this.month,
					plan: this.plan,
				})
			}
			if (this.isManagementMode) {
				await this.loadLicenseInfo4Subscription({
					quantity: this.quantity,
					month: this.month,
					plan: this.plan,
				})
			}
		},
		async plan(changeValue, beforeValue) {
			if (changeValue > this.MAX_QUANTITY) {
				this.quantity = Number(beforeValue)
			}

			const allMemberCount = this.instInfo.allMemberCount
			if (changeValue === '') this.quantity = 1
			if (this.plan == this.type.EDUCATION) this.quantity = this.MAX_QUANTITY
			else {
				if (this.isAdditionMode) this.quantity = allMemberCount - this.license.quantity > 0 ? allMemberCount - this.license.quantity : 1
				else if (this.isPurchaseMode) this.quantity = allMemberCount > 0 ? allMemberCount : 1
			}
			if (this.isAdditionMode) {
				await this.loadLicenseAdditionInfo({
					quantity: this.quantity,
				})
			} else if (this.isPurchaseMode) {
				await this.loadLicensePurchaseInfo({
					plan: this.plan,
					quantity: this.quantity,
					month: this.month,
				})
			} else {
				await this.loadLicenseInfo4Subscription({
					plan: this.plan,
					quantity: this.quantity,
					month: this.month,
				})
			}
		},
		isMonthly() {
			if (this.isMonthly || !this.isPurchaseMode) {
				this.month = 1
			} else {
				this.month = 12
			}
		},
	},
	computed: {
		modalPurchaseTitle() {
			switch (this.mode) {
				case 'buying':
					return this.tt('라이선스 구매')
				case 'add':
					return this.tt('라이선스 추가')
				case 'management':
					return this.tt('정기결제 관리')
			}
		},
		isLowerQuantityThanMember() {
			return !this.isAdditionMode && this.quantity < this.instInfo.allMemberCount
		},
		isSelectedCardRegistered() {
			return this.selectedPayment == this.paymentMethod.CARD_REGISTERED
		},
		isManagementMode() {
			return this.mode === 'management'
		},
		isAdditionMode() {
			return this.mode === 'add'
			// return this.isLicenseActivate && !this.isFreeTier
		},
		isPurchaseMode() {
			return this.mode === 'buying'
		},
		showUnsubscribeButton() {
			return this.isManagementMode && this.beingSubscription
		},
		needUpdateCardError() {
			return this.isPaymentCardError
		},
		disableConfirmButton() {
			return (
				!this.agreement || this.isLowerQuantityThanMember
				//  ||
				// (this.selectedPayment === this.paymentMethod.CARD_REGISTERED &&
				//     this.needUpdateCardError)
			)
		},
		disabledReason() {
			if (!this.agreement) return this.tt('구매진행에 동의해주세요.')
			else if (this.isLowerQuantityThanMember) return this.tt('멤버 수가 구매할 라이선스 수 보다 많습니다.')

			return null
		},
	},
	methods: {
		...mapMutations('license', ['savePurchaseForm', 'initPurchaseForm']),
		initModal() {
			// 모달 초기화
			this.selectedPayment = 'CARD_REGISTERED'
			this.agreement = false
		},
		close() {
			this.$emit('close')
		},
		async reloadLicense() {
			await this.loadLicense()
			await this.loadLicenseManagement()
		},
		async unsubscribe() {
			await this.unsubscribeLicense()
			this.showSuccessUnsubscribeToast()
			this.reloadLicense()
			this.$emit('close')
		},
		showModalUnsubscribe() {
			this.$refs['modal-unsubscribe'].show()
		},
		addCount() {
			this.quantity = Number(this.quantity) + 1
		},
		reduceCount() {
			this.quantity = Number(this.quantity) - 1
		},
		addPeriod() {
			if (this.isMonthly || !this.isPurchaseMode) {
				if (this.month < 11 || (!this.isPurchaseMode && this.month < 12)) this.month = Number(this.month) + 1
			} else {
				this.month += 12
			}
		},
		reducePeriod() {
			if ((this.isMonthly || !this.isPurchaseMode) && this.month > 1) {
				this.month = Number(this.month) - 1
			} else if (this.month > 12) {
				this.month -= 12
			}
		},
		async loadInformation() {
			try {
				if (this.isAdditionMode) {
					// 라이선스 추가
					await this.loadLicenseAdditionInfo({
						quantity: this.quantity,
					})
				} else if (this.isPurchaseMode) {
					// 라이선스 구매
					await this.loadLicensePurchaseInfo({
						quantity: this.quantity,
						month: this.month,
						plan: this.plan,
					})
				} else {
					// 라이선스 관리
					await this.loadLicenseInfo4Subscription({
						quantity: this.quantity,
						month: this.month,
						plan: this.plan,
					})
				}
			} catch (e) {
				this.$root.toast('라이선스 추가', e.response.data.msg, ToastType.ERROR)
				// this.close()
			}
		},
		async doLicensePayment() {
			this.setPurchaseForm()

			if (this.plan === this.type.LITE_EDU) {
				// 교육 할인일때
				if (this.authEdu.phone) {
					// 등록된 번호가 있을때
					this.setPhoneNumber(this.authEdu.phone)
					this.purchaseCommon()
				} else {
					if (this.isPurchaseMode) {
						this.$emit('showModalAuthEdu')
					} else {
						this.purchaseCommon()
					}
				}
			} else {
				// 교육 할인이 아닐때
				this.purchaseCommon()
			}
		},
		async purchaseCommon() {
			try {
				switch (this.selectedPayment) {
					case this.paymentMethod.CARD_REGISTERED:
						if (this.isAdditionMode) {
							// 추가
							this.setCardId(this.defaultCreditCard.id)
							await this.purchaseLicenseBasicAddition()

							this.showSuccessToast()
							await this.loadLicense()
							await this.loadLicenseManagement()
							this.$emit('close')
							this.$emit('complete', true)
						} else {
							// 구매의 경우
							if (this.hasDefaultCreditCard) {
								// 기본 카드 있는 경우
								this.purchaseByDefaultCard()
							} else {
								this.$emit('registCard')
							}
						}

						break
					case this.paymentMethod.ELECTRONIC_BILL:
						this.$emit('ebill')
						break
				}
			} catch (err) {
				this.$root.toast('라이선스 추가', err.response.data.msg, ToastType.ERROR)
			}
		},
		async purchaseByDefaultCard() {
			const cardId = this.defaultCreditCard.id
			// 카드로 베이직 구매
			await this.purchaseLicenseBasic({
				isSubscribe: true,
				cardId: cardId,
			})

			// 라이선스 데이터 갱신
			await this.loadLicense()
			await this.loadLicenseManagement()
			await this.loadDefaultCreditCard()
			this.showSuccessToast()
			// 완료 모달 켜기
			this.$emit('close')
			this.$emit('complete')
		},
		// 정기결제 에러시 정기결제 편집 후 결제
		async purchaseSubscribeLicense() {
			const cardId = this.defaultCreditCard.id
			this.setPurchaseForm()
			// isBook 이용해서 결제
			await this.purchaseLicenseBasic({
				isSubscribe: true,
				cardId: cardId,
				isBook: true,
			})
			this.showSuccessToast()
			this.reloadLicense()
			this.close()
			this.$emit('complete')
		},
		preventInputE(event) {
			return event.KeyCode == 69 ? false : true
		},
		focusLicenseDateInput() {
			this.$refs['license-data-input'].focus()
		},
		setPurchaseForm() {
			this.savePurchaseForm({
				quantity: this.quantity,
				month: this.month,
				plan: this.plan,
				paymentMethod: this.selectedPayment,
			})
		},
		async registOrEditSubscribeInformation() {
			const body = {
				quantity: this.quantity,
				month: this.month,
				cardId: this.defaultCreditCard.id,
			}
			if (this.beingSubscription) {
				await this.updateLicenseSubscription(body)
				this.showSuccessSaveSubscribe()
			} else {
				await this.subscriptBasicLicense(body)
				this.showSuccessSubscribe()
			}
			this.reloadLicense()
			this.close()
		},

		showSuccessUnsubscribeToast() {
			const title = this.tt('정기결제 해제')
			const content = this.tt('정기결제가 해제되었습니다.')
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
		},
		showSuccessSaveSubscribe() {
			const title = this.tt('정기결제 저장')
			const content = this.tt('정기결제 정보가 저장되었습니다.')
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
		},
		showSuccessSubscribe() {
			const title = this.tt('정기결제 관리완료')
			const content = this.tt('정기결제가 설정되었습니다.')
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
		},
		showToastNeedUpdateCard() {
			const title = this.tt('결제카드 업데이트 필요')
			const content = this.tt('결제카드 정보를 업데이트 해주세요.')
			const type = ToastType.ERROR
			this.$root.toast(title, content, type)
		},
		getPlan(plan) {
			this.plan = plan
		},
	},
}
</script>

<style lang="scss" scoped>
.license-now-member {
	color: black !important;
	&.red {
		color: #ff3b31 !important;
	}
}

.change-card {
	align-items: center;
	display: flex;
	font-size: 14px;
	color: $BLUE;
	font-weight: 700;
	cursor: pointer;
}
.input-div {
	display: flex;
	align-items: center;
}
.card-registered-label {
	justify-content: space-between;
}

.bold {
	font-weight: 700 !important;
	color: black;
}

.Popup__bottom {
	position: relative;
	width: 100%;
	& > * {
		margin: 0px 8px 0px 0px !important;
		width: 100% !important;
	}
}

.freetier-green {
	color: $GREEN;
	font-weight: 400;
	font-size: 14px;
	text-align: right;
	display: block !important;
}

.input-warning {
	display: flex;
	color: $RED;
	font-size: 12px;
	font-weight: 400;
	height: 17px;
	padding: 0px !important;
	align-items: center;
	margin-top: 4px;
	img {
		height: 12px;
		margin-right: 6px;
		padding-top: 2px;
	}
	p {
		display: inline-block;
		color: #ff3b31 !important;
		margin: 0px !important;
	}
}

.license-quantity {
	font-weight: 700;
	color: black !important;
}

.Btn__yellow {
	margin: 0px 20px;
	width: calc(100% - 40px);
	height: 48px;
	font-weight: 700;
	font-size: 14px;
	box-shadow: unset;
}
.Popup__centered {
	position: relative;
	margin: 1.75rem auto;
	min-height: calc(100% - 3.5rem);
	display: flex;
	align-items: center;
	max-width: 520px;
}

.ModalManagePayment {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 1050;
	outline: 0;
	color: #000;
	// &::-webkit-scrollbar {
	// 	width: 10px;
	// }
	.Popup__container {
		position: relative;
		width: 520px;
		top: unset;
		left: unset;
		transform: unset;
		// height: calc(100% - 135px) !important;
	}
	.Popup__content {
		// height: calc(100% - 135px) !important;
		// overflow-y: auto;
		// overflow-x: hidden;
		.license-info {
			.license-summary {
				display: flex;
				align-items: center;
				height: auto;
				.imgbox {
					width: 50px;
					height: 50px;
					overflow: hidden;
					border-radius: 10px;
					img {
						width: inherit;
						height: inherit;
					}
				}
				.txtbox {
					margin-left: 20px;
					h6 {
						font-size: 0.875rem;
						font-weight: 700;
					}
					p {
						font-size: 0.875rem;
						font-weight: 400;
						margin-top: 2px;
					}
				}
			}
			h5 {
				font-size: 0.875rem;
				font-weight: 700;
				margin-bottom: 10px;
			}
			ul {
				width: 100%;
				li {
					width: 100%;
					height: 30px;
					display: flex;
					align-items: center;
					p {
						font-size: 0.875rem;
						font-weight: 400;
						color: $SECONDARY_2;
					}
					span {
						font-size: 0.875rem;
						font-weight: 400;
						display: inline-block;
						margin-left: auto;
					}
					h3 {
						font-size: 1.25rem;
						font-weight: 700;
						color: $SECONDARY_2;
						margin-top: 10px;
					}
					h2 {
						font-size: 1.25rem;
						font-weight: 700;
						margin-left: auto;
						margin-top: 10px;
					}
					> b {
						font-size: 0.875rem;
						font-weight: 700;
						display: inline-block;
						margin-left: auto;
						text-align: right;
						span {
							display: inline-block;
							font-size: inherit;
							font-weight: inherit;
						}
					}
				}
			}
			.Btn__pink {
				margin-top: 20px;
			}
			.license-num {
				margin-top: 20px;
				border-top: 1px solid $INPUT;
				> div {
					padding-top: 20px;
					> p {
						font-size: 0.75rem;
						color: $DEFAULT;
						font-weight: 400;
						margin-bottom: 8px !important;
					}
					.num-box {
						width: 160px;
						display: flex;
						justify-content: space-between;
						height: 30px;
						border: 1px solid #ccc;
						border-radius: 5px;
						padding: 5px;
						align-items: center;
						&.warning {
							border: 1px solid $RED;
						}
						.license-count {
							font-size: 14px;
							font-weight: 400;
							width: 110px;
							text-align: center;
							border: none;
						}
						.license-date-count {
							font-size: 14px;
							font-weight: 400;
							width: 50px;
							text-align: right;
							border: none;
							padding: 0px;
						}
						.license-count-unit {
							text-align: left;
							font-size: 14px;
						}
						button {
							padding: 0;
							border-radius: 6px;
							width: 20px;
							height: 20px;
							border: 0;
							border: 1px solid $LAB_WHITE;
							box-sizing: border-box;
							img {
								width: inherit;
								height: inherit;
								padding: 0;
								margin: 0;
							}
							&.cant,
							&:disabled {
								border: 0 !important;
								img {
									opacity: 0.2;
								}
								&:hover {
									img {
										opacity: 0.2;
									}
								}
							}
							&:hover {
								img {
									opacity: 0.95;
								}
							}
						}
					}
				}
				.flex {
					p {
						font-size: 0.875rem;
						color: $SECONDARY_2;
						font-weight: 400;
					}
					span {
						font-size: 0.875rem;
						display: inline-block;
						margin-left: auto;
						text-align: right;
						font-weight: 700;
						color: $RED;
					}
				}
			}
		}
		.license-date {
			padding-top: 20px;
			margin-top: 20px;
			border-top: 1px solid $INPUT;
		}
		.license-price {
			padding-top: 20px;
			margin-top: 20px;
			border-top: 1px solid $INPUT;
			ul {
				width: 100%;
				li {
					width: 100%;
					height: 30px;
					display: flex;
					align-items: center;
					p {
						font-size: 0.875rem;
						font-weight: 400;
						color: $SECONDARY_2;
					}
					span {
						font-size: 0.875rem;
						font-weight: 400;
						display: inline-block;
						margin-left: auto;
					}
					h3 {
						font-size: 1.25rem;
						font-weight: 700;
						color: $SECONDARY_2;
						margin-top: 10px;
					}
					h2 {
						font-size: 1.25rem;
						font-weight: 700;
						margin-left: auto;
						margin-top: 10px;
					}
				}
			}
		}
		.license-payment {
			padding-top: 20px;
			margin-top: 20px;
			border-top: 1px solid $INPUT;
			label {
				display: flex;
				width: 100%;
				font-size: 0.875rem;
				font-weight: 400;
				height: 28px;
				align-items: center;
			}
			input[type='radio'] {
				width: 0;
				height: 0;
				position: relative;
				margin-right: 25px;
				margin-left: 0px;
				margin-top: -14px;
				&:before {
					width: 20px;
					height: 20px;
					background-color: $LAB_WHITE;
					content: url(~@/assets/svg/radio-off.svg);
					position: absolute;
					top: 0;
					left: 0;
				}
				&:checked {
					&:before {
						width: 20px;
						height: 20px;
						background-color: $LAB_WHITE;
						content: url(~@/assets/svg/radio-on.svg);
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
			button {
				width: 100%;
				margin-top: 20px;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.card-box {
				width: 100%;
				border: 1px solid $DIVIDER;
				border-radius: 12px;
				display: flex;
				align-items: center;
				height: 82px;
				padding: 0 20px;
				margin-bottom: 20px;
				background: $GREY_0;
				.imgbox {
					width: 60px;
					height: 40px;
					margin-right: 10px;
				}
				.txtbox {
					p {
						font-size: 0.875rem;
						font-weight: 400;
					}
					span {
						font-size: 0.875rem;
						font-weight: 400;
						display: inline-block;
						color: $SECONDARY_2;
					}
				}
			}
		}
		.not-use-noti {
			width: 100%;
			height: 60px;
			background-color: rgba(255, 87, 70, 0.1);
			border-radius: 5px;
			display: flex;
			align-items: center;
			padding: 10px 15px;
			margin: 10px 0;
			p {
				font-size: 0.875rem;
				color: #000;
				width: 320px;
				span {
					color: $RED;
					display: inline-block;
				}
			}
			button {
				font-size: 0.875rem;
				color: $BLUE;
				font-weight: 700;
				padding-right: 15px;
				background-image: url(~@/assets/svg/arrow_right_s.svg);
				background-repeat: no-repeat;
				background-position: top 11px right 0;
				background-size: 15px;
				width: unset;
				margin-top: 0;
			}
		}
		.license-bottom {
			padding-top: 20px;
			margin-top: 20px;
			border-top: 1px solid $INPUT;
			.agreement-box {
				width: 100%;
				min-height: 40px;
				background-color: $GREY_0;
				border: 1px solid $DIVIDER;
				border-radius: 5px;
				align-items: center;
				padding: 7px 10px;
				display: flex;
				input[type='checkbox'] {
					margin-right: 10px;
					&:before {
						border-radius: 7px;
					}
					&:checked {
						&:after {
							background-image: url(~@/assets/svg/select-check-on_y.svg);
							background-color: $LAB_YELLOW;
							border: 1px solid $LAB_YELLOW;
							border-radius: 7px;
						}
					}
				}
				label {
					font-size: 0.875rem;
					display: inline-block;
					cursor: pointer;
				}
				a {
					display: inline-block;
					color: $BLUE;
					text-decoration: underline;
					font-weight: 700;
					font-size: 0.875rem;
					margin-left: auto;
				}
			}
		}
	}
}
</style>
