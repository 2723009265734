var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isIngredient ? _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('구성성분')))]), _c('div', {
    staticClass: "field-wrap"
  }, [_c('ingredient-table', {
    attrs: {
      "ingredient": _vm.ingredient
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }