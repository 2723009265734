import inventoryAPI from '@/services/api/inventory/gen2'
import cookies from 'vue-cookies'

export default {
	namespaced: true,
	state: {
		loadParams: {
			groupby: cookies.get('bundleOption') ?? null,
			keyword: null,
			offset: 0,
			length: 30,
		},
		selectedItem: null,
		list: [],
		count: 0,
		noneGroupbyDataItemCount: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		list: state => state.list,
		selectedItem: state => state.selectedItem,
		noneGroupbyDataItemCount: state => state.noneGroupbyDataItemCount,
		count: state => state.count,
		scrollTop: state => state.scrollTop,
	},
	mutations: {
		setGroupby: (state, groupby) => {
			state.loadParams.groupby = groupby
		},
		setList: (state, data) => {
			state.list = state.list.concat(data.list)
			state.loadParams.offset = state.list.length
			state.count = data.count
			state.noneGroupbyDataItemCount = data.noneGroupbyDataItemCount
		},
		setScrollTop: (state, scrollTop) => {
			state.scrollTop = scrollTop
		},
		initParams: (state, groupby) => {
			state.loadParams = {
				groupby: groupby ?? null,
				keyword: null,
				offset: 0,
				length: 30,
			}
			state.selectedItem = null
			state.scrollTop = 0
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				if (state.loadParams[key] !== loadParams[key] && key !== 'offset') {
					state.loadParams.offset = 0
				}
				state.loadParams[key] = loadParams[key]
			})
		},
		initList: state => {
			state.list = []
		},
		setSelectedItem: (state, item) => {
			state.selectedItem = item
		},
	},
	actions: {
		getList: async ({ state, commit, rootGetters }, isMore = false) => {
			if (!isMore) {
				commit('setLoadParams', { offset: 0, length: 30 })
				commit('initList', [])
			} else if (state.list.length >= state.count && state.count != 0) return
			let listParams = rootGetters['inventoryGen2/list/loadParams']
			delete listParams.bundle
			const { data } = await inventoryAPI.getBundleList(rootGetters.instituteId, { ...state.loadParams, list: listParams })
			commit('setList', data)
		},
		setLoadParams: async ({ commit, dispatch }, params) => {
			commit('setLoadParams', params)
			await dispatch('getList', false)
		},
		// setSelectedItem: async ({ state, commit, dispatch }, item) => {
		// 	if (state.loadParams.groupby == 'NAME') {
		// 		commit('setSelectedItem', item.name)
		// 		// await dispatch('inventoryGen2/list/setLoadParams', { bundle: { name: encodeURIComponent(item.name), volumeUnit: item.volumeUnit } })
		// 	} else if (state.loadParams.groupby == 'CASNO') {
		// 		commit('setSelectedItem', item.casno)
		// 		// await dispatch('inventoryGen2/list/setLoadParams', { bundle: { casno: encodeURIComponent(item.casno), volumeUnit: item.volumeUnit } })
		// 	} else if (state.loadParams.groupby == 'PRODUCT_CODE') {
		// 		commit('setSelectedItem', item.productCode)
		// 		// await dispatch('inventoryGen2/list/setLoadParams', {
		// 		// 	bundle: { productCode: encodeURIComponent(item.productCode) },
		// 		// 	brandsId: item.brand?.id ? [item.brand.id] : [],
		// 		// 	volumeUnit: item.volumeUnit,
		// 		// })
		// 	}
		// },
		// setSelectedAll: async ({ commit, dispatch }) => {
		// 	commit('setSelectedItem', null)
		// 	// await dispatch('inventoryGen2/list/setLoadParams', { bundle: null })
		// },
	},
}
