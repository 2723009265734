import axiosInstance from '@/services/axios'

const endPoint = {
	list: instituteId => `/institute/${instituteId}/budget/list`,
	filter: instituteId => `/institute/${instituteId}/budget/list/filter`,
	detail: (instituteId, id) => `/institute/${instituteId}/budget/${id}`,
	expenditureList: instituteId => `/institute/${instituteId}/expenditure/list`,
	expenditureFilter: instituteId => `/institute/${instituteId}/expenditure/filter`,
	create: instituteId => `/institute/${instituteId}/budget`,

	year: instituteId => `/institute/${instituteId}/budget/year`,
	status: instituteId => `/institute/${instituteId}/budget/status`,
	listExportExcel: instituteId => `/institute/${instituteId}/budget/list/export/excel`,
	detailExportExcel: (instituteId, id) => `/institute/${instituteId}/budget/${id}/export/excel`,
	statusExportExcel: instituteId => `/institute/${instituteId}/budget/status/export/excel`,
	expenditureExportExcel: instituteId => `/institute/${instituteId}/expenditure/list/export/excel`,
}

const api = {
	list: (instituteId, params) => axiosInstance.get(endPoint.list(instituteId), { params }),
	filter: instituteId => axiosInstance.get(endPoint.filter(instituteId)),
	detail: (instituteId, id) => axiosInstance.get(endPoint.detail(instituteId, id)),
	expenditureList: (instituteId, params) => axiosInstance.get(endPoint.expenditureList(instituteId), { params }),
	expenditureFilter: (instituteId, params) => axiosInstance.get(endPoint.expenditureFilter(instituteId), { params }),
	create: (institueId, payload) => axiosInstance.post(endPoint.create(institueId), payload),
	edit: (institueId, payload) => axiosInstance.patch(endPoint.create(institueId), payload),
	delete: (institueId, id) => axiosInstance.delete(endPoint.create(institueId), { params: { id } }),
	year: institueId => axiosInstance.get(endPoint.year(institueId)),
	status: (institueId, params) => axiosInstance.get(endPoint.status(institueId), { params }),
	listExportExcel: (institueId, payload) =>
		axiosInstance.post(endPoint.listExportExcel(institueId), payload, {
			responseType: 'blob',
		}),
	detailExportExcel: (institueId, id) =>
		axiosInstance.post(endPoint.detailExportExcel(institueId, id), null, {
			responseType: 'blob',
		}),
	statusExportExcel: (institueId, payload) =>
		axiosInstance.post(endPoint.statusExportExcel(institueId), payload, {
			responseType: 'blob',
		}),
	expenditureExportExcel: (institueId, payload) =>
		axiosInstance.post(endPoint.expenditureExportExcel(institueId), payload, {
			responseType: 'blob',
		}),
}

export default api
