import cargoAPI from '@/services/api/purchase/cargo'
import _ from 'lodash'
import cookies from 'vue-cookies'
import { cargoViewOption } from '@/utils/define/ViewOption'

const state = {
	list: null,
	count: null,
	selectItem: [],
	loadParams: {
		keyword: null,
		groupsId: [],
		offset: 0,
		length: 50,
		order: ['CREATE_DATETIME'],
		orderDirection: ['DESC'],
		startDatetime: null,
		endDatetime: null,
		status: JSON.parse(cookies.get('filter_cargo_status')) ?? [],
		categoriesId: JSON.parse(cookies.get('filter_cargo_categories')) ?? [],
		brandsId: JSON.parse(cookies.get('filter_cargo_brands')) ?? [],
		registerMethods: JSON.parse(cookies.get('filter_cargo_registerMethods')) ?? [],
		shipStatus: JSON.parse(cookies.get('filter_cargo_shipStatus')) ?? [],
	},
	filter: null,
	filterCookieName: {
		status: 'filter_cargo_status',
		categories: 'filter_cargo_categories',
		brands: 'filter_cargo_brands',
		registerMethods: 'filter_cargo_registerMethods',
		shipStatus: 'filter_cargo_shipStatus',
	},
	viewOptionCookieName: 'viewOption_cargo',
	checkedViewOption: JSON.parse(cookies.get('viewOption_cargo')) ?? [],
	viewOptionList: cargoViewOption,
}

const getters = {
	selectedStatus: state => (state.loadParams.status.length != 1 ? 'ALL' : state.loadParams.status[0]),
	list: state => state.list,
	count: state => state.count,
	isEmptyList: state => state.count === 0,
	selectItem: state => state.selectItem,
	selectItemId: state => state.selectItem.map(i => i.id),
	isEmptySelectItem: state => state.selectItem.length === 0,
	isIncludeCompleteSelectItem: state => state.selectItem.map(i => i.status).some(i => i === 'COMPLETE'),
	isAllReadySelectItem: state => state.selectItem.map(i => i.isReady).every(i => i),
	filteredCount: state =>
		state.loadParams.status.length +
		state.loadParams.categoriesId.length +
		state.loadParams.brandsId.length +
		state.loadParams.registerMethods.length +
		state.loadParams.shipStatus.length,
	order: state => state.loadParams.order[0],
	orderDirection: state => state.loadParams.orderDirection[0],
	offset: state => state.loadParams.offset,
	length: state => state.loadParams.length,
	keyword: state => state.loadParams.keyword,
}

const mutations = {
	setCargoList: (state, cargoList) => {
		state.list = cargoList.list
		state.count = cargoList.count
	},
	setSelectItem: (state, item) => {
		state.selectItem = item
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			if (loadParams[key] == null) {
				delete state.loadParams[key]
			} else state.loadParams[key] = loadParams[key]
		})
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	setViewOptions: (state, list) => {
		cookies.set(state.viewOptionCookieName, JSON.stringify(list))
		state.checkedViewOption = list
	},
	initFilterCookies: (state, needReset) => {
		if (needReset)
			Object.values(state.filterCookieName).forEach(key => {
				cookies.set(key, JSON.stringify([]))
			})
		state.loadParams = {
			keyword: null,
			groupsId: [],
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			startDatetime: null,
			endDatetime: null,
			status: [],
			categoriesId: [],
			brandsId: [],
			registerMethods: [],
			shipStatus: [],
		}
	},
}

const actions = {
	getCargoList: async ({ commit, rootGetters, state }) => {
		const response = await cargoAPI.getCargoList(rootGetters.instituteId, state.loadParams)
		commit('setCargoList', response.data)
	},
	divideCargoItem: async ({ dispatch, rootGetters }, body) => {
		await cargoAPI.divideCargoItem(rootGetters.instituteId, body)
		dispatch('getCargoList')
	},
	deleteCargoItem: async ({ commit, dispatch, rootGetters, getters }, ids) => {
		await cargoAPI.deleteCargoItem(rootGetters.instituteId, { ids: ids ? ids : getters.selectItemId })
		if (!ids) {
			commit('setSelectItem', [])
		}
		dispatch('getCargoList')
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		await dispatch('getCargoList')
	},
	getFilter: async ({ commit, rootGetters }) => {
		const response = await cargoAPI.getFilter(rootGetters.instituteId)
		commit('setFilter', response.data)
	},
	exportExcel: async ({ state, rootGetters }, type) => {
		const response = await cargoAPI.exportExcel(rootGetters.instituteId, type == 'ALL' ? null : state.loadParams)
		return response
	},
	processCargoItem: async ({ rootGetters, state }, ids) => {
		await cargoAPI.processCargoItem(rootGetters.instituteId, { ids: ids })
	},
}
export default { namespaced: true, state, getters, mutations, actions }
