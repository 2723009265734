<template>
	<div class="page-wrapper">
		<fixed-header :title="tt('관리기록 조회')" />
		<div class="table-wrapper">
			<history-table v-if="previousPage" ref="inventory-table" :list="list" :previousPage="previousPage"></history-table>
			<floating-menu v-if="selectedItems.length > 0"></floating-menu>
		</div>
		<pagination ref="pagination" :total="count" @page="pagination"></pagination>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import Pagination from '@/views/common/components/Pagination.vue'
import FixedHeader from '../components/history/FixedHeader.vue'
import FloatingMenu from '../components/history/FloatingMenu.vue'
import HistoryTable from '../components/historyTable'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
export default {
	components: {
		Pagination,
		FixedHeader,
		FloatingMenu,
		HistoryTable,
	},
	computed: {
		...mapState('inventoryGen2/history', ['list', 'count', 'selectedItems']),
		...mapGetters('inventoryGen2/history', ['offset', 'length']),
	},
	mounted() {
		if (this.previousPage?.name != null && this.previousPage?.name !== PageName.InventoryGen2.Detail) {
			this.initFilterCookies(true)
			this.setSelectedItems([])
			this.$refs['pagination'].init(1)
		} else {
			this.$refs['pagination']?.changePage(this.offset, this.length)
		}
	},
	data() {
		return {
			previousPage: null,
		}
	},
	methods: {
		...mapMutations('inventoryGen2/history', ['setSelectedItems', 'initFilterCookies']),
		...mapActions('inventoryGen2/history', ['setLoadParams']),
		async pagination(page) {
			this.$refs['inventory-table']?.moveScrollTop()
			await this.setLoadParams(page)
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 165px);
	}
}
</style>
