<template>
	<portal to="lm-modal">
		<div class="experience-modal-background" v-if="isShow">
			<div class="modal-wrapper">
				<div class="header-text-wrapper">
					<h1 class="header-text-title">{{ title }}</h1>
					<span class="header-text-content" v-html="tt('랩매니저 PRO의 모든 기능을 사용할 수 있습니다!')"> </span>
				</div>
				<img class="eol-image" src="@/assets/svg/experience/pro-experience-modal.svg" />
				<div class="bottom-wrapper">
					<lm-confirm-button-middle :text="tt('닫기')" @click="close"></lm-confirm-button-middle>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

import LmConfirmButtonMiddle from '@/views/common/components/buttons/LmConfirmButtonMiddle.vue'

export default {
	components: {
		LmConfirmButtonMiddle,
	},
	mixins: [MixinModal],
	data() {
		return {
			title: '',
		}
	},
	methods: {
		show(title) {
			this.isShow = true
			if (title) {
				this.title = title
			} else {
				this.title = '가입 완료'
			}
		},
		close() {
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
.eol-image {
	margin: 20px;
}

.experience-modal-background {
	width: 100%;
	height: 100%;
	background: $DIM;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;

	.modal-wrapper {
		width: 500px;
		// height: 590px;
		padding: 50px 50px 60px 50px;
		background: white;
		border-radius: 20px;
		.header-text-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.header-text-content {
				font-weight: 400;
				font-size: 16px;
			}
			.header-text-subcontent {
				font-size: 12px;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0px;
				padding-top: 10px;
				padding-bottom: 10px;
				text-align: center;
				color: $SECONDARY_2;
			}
		}
		.bottom-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100px;
			padding-top: 17px;
			align-items: center;
			justify-content: space-between;
			.sub-text {
				color: $PLACEHOLDER;
				font-weight: 400;
				font-size: 14px;
				cursor: pointer;
			}
		}
	}
}
</style>
