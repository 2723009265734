var render = function () {
  var _vm$item$productInfos;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "product-info-item",
    "class": {
      isSearch: _vm.isSearch
    }
  }, [_c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": _vm.productImage
    }
  })]), _c('div', {
    staticClass: "product-info",
    on: {
      "click": _vm.click
    }
  }, [_c('div', {
    staticClass: "info"
  }, [_vm.item.isRecommend ? _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "10px",
      "align-items": "center",
      "margin-bottom": "8px"
    }
  }, [_vm.item.isRecommend ? _c('div', {
    staticClass: "badge-recommend"
  }, [_vm._v(" " + _vm._s(_vm.tt('같이 보면 좋은 상품')) + " ")]) : _vm._e()]) : _vm._e(), _c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.item.name))
    }
  }), _vm.item.subname ? _c('p', {
    staticClass: "subname",
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.item.subname))
    }
  }) : _vm._e(), _vm.item.minSellingUnitPrice && !_vm.usePrice ? _c('h4', {
    staticClass: "price"
  }, [_vm.item.discountPercent > 0 ? _c('span', {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.item.discountPercent) + "%")]) : _vm._e(), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.CommaNum(_vm.item.minSellingUnitPrice)) + '원' + (((_vm$item$productInfos = _vm.item.productInfos) === null || _vm$item$productInfos === void 0 ? void 0 : _vm$item$productInfos.length) > 0 ? '~' : null))
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "brand-info"
  }, [_vm.item.category ? _c('p', [_vm._v(" " + _vm._s(_vm.tt('카테고리')) + " : "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.tt(_vm.category2String(_vm.item.category))))
    }
  })]) : _vm._e(), _vm.item.brand && _vm.item.brand.name ? _c('p', [_vm._v(_vm._s(_vm.tt('브랜드')) + " : "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.tt(_vm.item.brand.name)))
    }
  })]) : _vm._e(), _vm.item.code ? _c('p', [_vm._v(_vm._s(_vm.tt('제품번호')) + " : "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.tt(_vm.item.code)))
    }
  })]) : _vm._e(), _vm.item.casno ? _c('p', [_vm._v(_vm._s(_vm.tt('CAS No')) + " : "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceHighlight(_vm.tt(_vm.item.casno)))
    }
  })]) : _vm._e(), _vm.item.categoryDisplayOptions && _vm.item.categoryDisplayOptions.length > 0 ? _c('div', _vm._l(_vm.item.categoryDisplayOptions, function (option, index) {
    return _c('p', {
      key: "option-".concat(index)
    }, [_vm._v(" " + _vm._s(_vm.tt(option.name)) + ": "), _c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.replaceHighlight(option.values.join(', ')))
      }
    })]);
  }), 0) : _vm._e()])]), !_vm.usePrice ? _c('div', {
    staticClass: "seller-list"
  }, [_c('ul', {
    staticStyle: {
      "overflow": "auto",
      "height": "140px"
    }
  }, _vm._l(_vm.item.productInfos, function (productInfo, index) {
    return _c('li', {
      key: "productInfo-".concat(index)
    }, [_c('div', [_c('p', [_vm._v(_vm._s(_vm.tt(productInfo.seller.name)))]), _c('p', [_vm._v(_vm._s(_vm.CommaNum(productInfo.minSellingUnitPrice)) + _vm._s(_vm.tt('원')))])]), productInfo.discountPercent ? _c('p', {
      staticStyle: {
        "font-weight": "400",
        "color": "#999",
        "text-decoration": "line-through"
      }
    }, [_vm._v(" " + _vm._s(_vm.tt('${1}원', _vm.CommaNum(productInfo.minUnitPrice))) + " ")]) : _vm._e()]);
  }), 0)]) : _c('div', {
    staticClass: "price-box"
  }, [_c('h6', [_vm.item.discountPercent > 0 ? _c('span', {
    staticClass: "red"
  }, [_vm._v(_vm._s(_vm.item.discountPercent) + "%")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.CommaNum(_vm.item.minSellingUnitPrice)) + _vm._s(_vm.tt('원~')) + " ")]), _vm.item.discountPercent ? _c('p', {
    staticStyle: {
      "font-weight": "400",
      "color": "#999",
      "text-decoration": "line-through"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('${1}원', _vm.CommaNum(_vm.item.minUnitPrice))) + " ")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }