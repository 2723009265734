<template>
	<div class="page-wrapper">
		<draft-list-header
			:title="tt('지출결의서 기안함')"
			:dateButtonSubtext="dateButtonSubtext"
			:calendarDatetime="calendarDatetime"
			@changeDate="changeCalendar"
			@changeLoadParams="setLoadParams"
		></draft-list-header>
		<draft-list-table ref="draft-table" v-if="previousPage" :previousPage="previousPage" :list="list"></draft-list-table>
		<div class="pagination-wrapper">
			<pagination ref="pagination" @page="pagination" :total="count"></pagination>
		</div>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MixinExpenditureList from '@/mixins/purchase/expenditureList'
import DraftListHeader from '../components/list/DraftListHeader.vue'
import DraftListTable from '../components/table'
import Pagination from '@/views/common/components/Pagination.vue'

export default {
	name: 'DraftList',
	mixins: [MixinExpenditureList],
	components: {
		DraftListHeader,
		DraftListTable,
		Pagination,
	},
	computed: {
		...mapState('draft/list', ['list', 'count']),
		...mapGetters('draft/list', ['offset', 'length']),
	},
	mounted() {
		if (this.previousPage?.name !== PageName.Purchase.DraftDetail) {
			this.initFilterCookies(true)
			this.$refs['pagination'].init(1)
		} else {
			this.$refs['pagination']?.changePage(this.offset, this.length)
		}
	},
	data() {
		return {
			previousPage: null,
			// cookie key
			cookieFilterKey: 'expenseDraftFilter',
			// cookieVisibleColumnsKey: 'expenseDraftVisibleColumns',
			cookieDatetimeFilterKey: 'expenseDraftDatetimeFilter',
		}
	},
	methods: {
		...mapMutations('draft/list', ['initFilterCookies']),
		...mapActions('draft/list', ['setLoadParams']),
		async pagination(page) {
			this.$refs['draft-table']?.moveScrollTop()
			await this.setLoadParams(page)
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	display: flex;
	flex-direction: column;
}
</style>
