<template>
	<base-item :title="title" :subContentText="subContentText" :required="isRequired">
		<span class="input-wrap">
			<input
				:type="type"
				v-model="value"
				:placeholder="tt(placeholder)"
				:disabled="isReadOnly"
				:class="{ error: !isValid }"
				@change="$emit('change')"
				:style="`width:${inputWidthSize}px; height:${inputHeightSize}px;`"
			/>
			<b class="unit" v-if="unit">{{ tt(unit) }}</b>
		</span>
		<div v-if="!isValid" class="error">
			<img src="@/assets/svg/purchase/icon-error.svg" alt="error" />
			<span>{{ tt(inValidText) }}</span>
		</div>
	</base-item>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputText',
	components: {
		BaseItem,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
		inputWidthSize: {
			type: Number,
			default: 300,
		},
		inputHeightSize: {
			type: Number,
			default: 36,
		},
		inputValue: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		unit: {
			type: String,
			default: '',
		},
		isReadOnly: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		inValidText: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			value: this.inputValue,
		}
	},
	watch: {
		inputValue: {
			handler(newVal) {
				this.value = newVal
			},
		},
	},
	computed: {
		// resultValue() {
		// 	console.log('뭐냐고!!!', this.inputValue)
		// 	return this.inputValue
		// },
	},
	methods: {
		// changeValue(event) {
		// 	event.target.value = event.target.value.trimLeft()
		// 	this.$emit('changeValue', event.target.value.trim())
		// },
		setValue(value) {
			this.value = value
		},
	},
}
</script>

<style lang="scss" scoped>
.input-wrap {
	position: relative;
	input[type='number'],
	input[type='text'],
	input[type='password'] {
		padding: 12px;
		border: 1px solid $FILE_BORDER;
		border-radius: 5px;

		&:disabled {
			background-color: $GREY_1;
		}
		&.error {
			border-color: $RED;
		}
	}
	b.unit {
		position: absolute;
		right: 12px;
		top: 0;
	}
}

.error {
	display: flex;
	span {
		font-size: 0.75rem;
		color: $RED;
		margin-left: 6px;
	}
}
</style>
