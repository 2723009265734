var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "common-detail-wrapper"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('예산 편성')))]), _c('div', {
    staticClass: "status-table-wrapper"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('예산표')))]), _c('div', {
    staticClass: "status-table"
  }, [_c('div', {
    staticClass: "left-fixed",
    staticStyle: {
      "width": "200px"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "200px"
    }
  }, [_c('div', {
    staticClass: "header group bd sm"
  }, [_vm._v(_vm._s(_vm.tt('예산명')))]), _c('div', {
    staticClass: "item bd"
  }, [_vm._v(_vm._s(_vm.name))])])]), _c('div', {
    staticClass: "table-content"
  }, [_c('div', {
    style: {
      '--width': "".concat(Object.keys(_vm.monthKey).length * 120, "px")
    }
  }, _vm._l(Object.keys(_vm.monthKey), function (key) {
    return _c('div', {
      key: key,
      staticClass: "column"
    }, [_c('div', {
      staticClass: "header sm"
    }, [_c('div', [_c('div', {
      staticClass: "item bd",
      "class": {
        disabled: _vm.budgetType == 'YEAR' && key != 'year'
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(_vm.monthKey[key])) + " ")])])]), _c('div', {
      staticClass: "item"
    }, [_c('div', [key != 'year' ? _c('div', {
      staticClass: "item bd"
    }, [_vm.budgetType == 'MONTH' ? _c('input', {
      ref: key,
      refInFor: true,
      staticClass: "input-budget",
      attrs: {
        "placeholder": _vm.tt('금액 입력'),
        "type": "number"
      },
      on: {
        "change": function change($event) {
          return _vm.setBudget(key);
        }
      }
    }) : _c('div', {
      staticClass: "disabled-area",
      on: {
        "click": _vm.showChangeModal
      }
    })]) : _c('div', {
      staticClass: "item bd"
    }, [_vm.budgetType == 'YEAR' ? _c('input', {
      ref: key,
      refInFor: true,
      staticClass: "input-budget",
      attrs: {
        "placeholder": _vm.tt('금액 입력'),
        "type": "number"
      },
      on: {
        "change": function change($event) {
          return _vm.setBudget(key);
        }
      }
    }) : _c('div', {
      staticClass: "item disabled-area yellow",
      on: {
        "click": _vm.showChangeModal
      }
    }, [_vm._v(_vm._s(_vm.tt('${1}원', _vm.totalAmount)))])])])])]);
  }), 0)])])]), _c('modal-confirm', {
    ref: "modal-change",
    attrs: {
      "title": "예산 분류 변경",
      "confirmText": "변경",
      "content": _vm.modalChangeContent
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }