var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }])
  }, [[_c('p', {
    staticClass: "Popup__plain-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('물품관리번호는 최대 5개의 항목으로 구성 가능합니다.')) + " ")]), _c('ul', _vm._l(_vm.list, function (item) {
    return _c('li', {
      key: item.value,
      staticClass: "flex",
      "class": [{
        checked: item.select
      }, {
        disabled: item.select
      }],
      on: {
        "click": function click($event) {
          return _vm.selectCheck(item.value);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/" + item.icon)
      }
    }), _c('div', [_c('h6', {
      staticClass: "mng-title"
    }, [_vm._v(" " + _vm._s(item.title) + " "), item.value == 'MNGCODE_STORAGE_ORDER' ? _c('img', {
      attrs: {
        "src": require("@/views/inventoryGen2/svg/Guide.svg")
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$refs['modal-storage-order-info'].show();
        }
      }
    }) : _vm._e()]), _c('p', [_vm._v(_vm._s(item.description))])])]);
  }), 0)], _c('modal-confirm', {
    ref: "modal-add",
    attrs: {
      "title": "보관함 등록순서 추가",
      "content": "보관함 등록순서 선택 시 보관함은 필수 관리항목입니다.<br/>관리항목에 보관함을 추가하시겠습니까?",
      "confirmText": "추가"
    }
  }), _c('modal-confirm', {
    ref: "modal-delete-close",
    attrs: {
      "title": "관리번호에서 제외 불가",
      "noCancel": false,
      "variant": "white",
      "confirmText": "확인",
      "content": "\uBCF4\uAD00\uD568 \uB4F1\uB85D\uC21C\uC11C \uC120\uD0DD \uC2DC \uBCF4\uAD00\uD568\uC740 \uD544\uC218 \uAD00\uB9AC\uD56D\uBAA9\uC785\uB2C8\uB2E4."
    }
  }), _c('ModalStorageOrderInfo', {
    ref: "modal-storage-order-info"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }