<template>
	<tr>
		<!-- <td>
			<div>
				<button
					class="checkbox"
					@click="checkBoxClick"
					:class="{ active: isClick }"
				></button>
			</div>
		</td> -->
		<td @click="authorityDetail">{{ order + 1 }}</td>
		<td @click="authorityDetail">
			<h6>
				{{ item.name }}
				<img v-if="item.isNew && !isClicked" src="@/assets/svg/new.svg" />
			</h6>
		</td>
		<td @click="authorityDetail">{{ item.lastUpdateDate }}</td>
		<td>
			<div class="Btn__group">
				<button class="btn arrow" :disabled="item.order == 1 ? true : false" @click="setLite ? showLicense() : moveTop()">
					<img style="padding: 0" src="@/assets/svg/arrow-up.svg" alt="arrow-icon" />
				</button>
				<button class="btn arrow" :disabled="item.order == total ? true : false" @click="setLite ? showLicense() : moveDown()">
					<img style="padding: 0" src="@/assets/svg/arrow-down.svg" alt="arrow-icon" />
				</button>
				<button
					class="btn"
					@click="setLite ? showLicense() : duplicateAuthority()"
					v-if="userPermission.permission.update && userPermission.permission.create"
				>
					<img src="@/assets/svg/Duplicate.svg" alt="복제icon" />
					{{ tt('복제') }}
				</button>
				<button class="btn" @click="setLite ? showLicense() : editAuthority()" v-if="userPermission.permission.update">
					<img src="@/assets/svg/button-icon-edit.svg" alt="icon" />
					{{ tt('편집') }}
				</button>
				<button
					class="btn"
					@click="setLite ? showLicense() : deleteAuthority()"
					:disabled="item.isAlmighty"
					v-if="userPermission.permission.delete"
				>
					<img src="@/assets/svg/Trash.svg" alt="icon" />
					{{ tt('삭제') }}
				</button>
			</div>
		</td>
	</tr>
</template>

<script>
import { isClickedInstitution } from '@/store/modules/isClickedItem'

export default {
	name: 'PermissionItem',
	props: {
		total: {
			type: Number,
			default: 0,
		},
		item: {
			type: Object,
			default: null,
		},
		allCheck: {
			type: Boolean,
			default: false,
		},
		order: {
			type: Number,
			default: 0,
		},

		license: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			isClick: false,
		}
	},
	watch: {
		allCheck: function () {
			if (this.allCheck) {
				this.isClick = true
			} else {
				this.isClick = false
			}
		},
		isClick: function () {
			if (this.isClick) {
				this.$emit('checkId', this.item.id)
			} else {
				this.$emit('uncheckId', this.item.id)
			}
		},
	},
	computed: {
		setLite() {
			if (this.license.type == 'BASIC') {
				return true
			} else {
				return false
			}
		},
		isClicked() {
			let result = false
			let clickedList = localStorage.getItem('permission') ? localStorage.getItem('permission').split(',') : []
			if (clickedList.includes(this.item.id)) {
				result = true
			}

			return result
		},
	},
	methods: {
		checkBoxClick() {
			if (this.isClick) {
				this.isClick = false
			} else {
				this.isClick = true
			}
		},
		authorityDetail() {
			let clickedList = localStorage.getItem('permission') ? localStorage.getItem('permission').split(',') : []

			if (!clickedList.includes(this.item.id) && this.item.isNew) {
				isClickedInstitution['permission'].push(this.item.id)
			}

			localStorage.setItem('permission', isClickedInstitution['permission'].join())

			this.$router.push({
				name: 'AuthorityDetail',
				params: { id: this.item.id },
			})
		},
		duplicateAuthority() {
			this.$emit('duplicate', this.item)
		},
		editAuthority() {
			this.$emit('edit', this.item)
		},
		deleteAuthority() {
			this.$emit('delete', this.item)
		},
		moveTop() {
			this.$emit('top', this.item)
		},
		moveDown() {
			this.$emit('down', this.item)
		},
		showLicense() {
			this.$emit('showLicense')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_authority.scss';
.Table__wrap {
	table {
		tbody {
			td:nth-child(1) {
				width: 110 !important;
				max-width: 110px;
				padding-left: 30px;
			}
		}
	}
}
</style>
