var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inputWrapper"
  }, [_c('input', {
    ref: "input",
    "class": {
      error: !_vm.isValid
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.tt(_vm.placeHolder)
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.handleInputValue,
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.goNext.apply(null, arguments);
      }
    }
  }), _c('span', {
    staticClass: "noti"
  }, [_vm._v(_vm._s(_vm.tt(_vm.notiMsg)))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }