<template>
	<div class="table-wrap">
		<table class="qr-table-wrap" cellspacing="0" cellpadding="0">
			<qr-scan-table-header @checkAll="checkAll"></qr-scan-table-header>
			<qr-scan-table-item
				v-for="qr in itemList"
				:key="`qr-${qr.id}`"
				:idx="qr.id"
				:item="qr"
				@checked="checkItem"
				@delete="deleteItem"
			></qr-scan-table-item>
		</table>
	</div>
</template>

<script>
import QrScanTableHeader from '@/views/common/components/modalGen2/components/qrScanTable/QrScanTableHeader.vue'
import QrScanTableItem from '@/views/common/components/modalGen2/components/qrScanTable/QrScanTableItem.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'QrScanTable',
	components: { QrScanTableHeader, QrScanTableItem },
	props: {
		itemList: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapState('form/qrcode', ['maxSize']),
		...mapGetters('form/qrcode', ['isSelectAll', 'isSelectSome', 'validQrCodeItemList', 'selectedList', 'validSelectedQrCodeList', 'qrcodeList']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('form/qrcode', ['setSelectedList', 'setQrcodeList']),
		checkAll() {
			if (this.isSelectAll) {
				// 모두 선택되어 있는 상황일때
				this.setSelectedList([])
			} else if (this.isSelectSome) {
				// 일부가 선택된 상태일 때
				let list = []
				for (let i = 0, length = this.validQrCodeItemList.length; i < length; i++) {
					if (this.maxSize - 1 < i) {
						break
					}
					if (!this.validSelectedQrCodeList?.includes(this.validQrCodeItemList[i].code)) {
						list.push(this.validQrCodeItemList[i])
					}
				}
				this.setSelectedList([...this.selectedList, ...list])
			} else {
				// 하나도 선택되지 않았을 때
				let list = []
				for (let i = 0, length = this.validQrCodeItemList?.length; i < length; i++) {
					if (this.maxSize - 1 < i) {
						break
					}
					list.push(this.validQrCodeItemList[i])
				}
				this.setSelectedList(list)
			}
		},
		checkItem(item) {
			if (this.validSelectedQrCodeList?.includes(item.code)) {
				this.setSelectedList(this.selectedList?.filter(i => i.code !== item.code))
			} else {
				this.setSelectedList([...this.selectedList, item])
			}
		},
		deleteItem(item) {
			this.setQrcodeList(this.qrcodeList?.filter(i => i.code !== item.code))
			this.setSelectedList(this.selectedList?.filter(i => i.code !== item.code))
		},
	},
}
</script>

<style lang="scss" scoped>
.table-wrap {
	height: 600px;
	overflow: auto;
	display: block;
	border-collapse: separate;
}
.qr-table-wrap {
	display: block;
}
</style>
