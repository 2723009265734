var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "ModalEditStorage Popup__depth2"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title",
    domProps: {
      "textContent": _vm._s(_vm.mode == 'ADD' ? _vm.tt('저장소 추가') : _vm.tt('저장소 편집'))
    }
  }), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('저장소 이름'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.storageName,
      expression: "storageName"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('저장소 이름을 입력해주세요')
    },
    domProps: {
      "value": _vm.storageName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.storageName = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "Popup__input-field deleteable-input-field Dropdown-input mt-20"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('위치'))), _c('span', {
    staticClass: "required"
  }, [_vm._v("*")])]), _vm._l(_vm.locationList, function (item, index) {
    return _c('div', {
      key: "location_" + index
    }, [_c('input', {
      attrs: {
        "type": "text",
        "placeholder": _vm.tt('위치를 선택해주세요'),
        "readonly": ""
      },
      domProps: {
        "value": _vm.setLocationName(item.locationName)
      },
      on: {
        "click": _vm.setLocation
      }
    }), _c('button', {
      staticClass: "Btn-delete-item btn",
      attrs: {
        "disabled": _vm.locationList.length < 2
      },
      on: {
        "click": function click($event) {
          return _vm.removeLocationList(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/recycle-bin.svg")
      }
    })])]);
  }), _c('button', {
    staticClass: "AppContainer__add-item-btn",
    on: {
      "click": _vm.addLocation
    }
  }, [_vm._v("+" + _vm._s(_vm.tt('추가하기')))])], 2)]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    attrs: {
      "disabled": _vm.locationList.length > 0 && _vm.locationList[0].locationId.length == 0 || !_vm.storageName || !_vm.storageName.replace(/\s/g, '')
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])]), _c('modal-select-treeview', {
    ref: "modal-select-treeview",
    attrs: {
      "title": "위치 지정",
      "mode": "STORAGE",
      "treeData": _vm.treeData,
      "selectedList": _vm.locationIdList,
      "checkChild": true
    },
    on: {
      "submit": _vm.addStorage
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }