<template>
	<b-modal ref="modal" size="sm" hide-header centered hide-footer no-close-on-backdrop no-fade>
		<div class="popup-wrapper">
			<img src="@/assets/images/icon-remain-clock.png" />
			<h2>{{ title }}</h2>
			<button class="button-primary" @click="goToStorePay">{{ tt('결제관리 바로가기 >') }}</button>
			<div class="remain-table">
				<div class="remian-row">
					<div>{{ tt('월') }}</div>
					<div>{{ tt('정산금액') }}</div>
					<div>{{ tt('상태') }}</div>
				</div>
				<div class="remian-row" v-for="(item, index) in nopaidList" :key="index">
					<div>{{ dateFormat(item) }}</div>
					<div>{{ tt('${1}원', item.noPaidPriceWithVat.toLocaleString()) }}</div>
					<div>{{ statusToText(item) }}</div>
				</div>
			</div>
			<p>{{ tt('정산기간 : 배송완료일이 속한 월의 익월 말일까지 입니다.') }}</p>
			<p>{{ tt('정산금액 : 배송 완료되었으나 결제되지 않은 총금액입니다.') }}</p>
		</div>
		<div class="btn-footer">
			<button @click="clickDonwShow">{{ tt('일주일간 보지 않기') }}</button>
			<button @click="hide">{{ tt('닫기') }}</button>
		</div>
	</b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
const dontShow7daysKey = 'dontShow7daysPayKey'
import MixinDate from '@/mixins/date'
export default {
	created() {},
	mixins: [MixinDate],
	computed: {
		...mapState('lms', ['nopaidList']),
		isNoticeShowAllow() {
			let date = this.$cookies.get(dontShow7daysKey) ?? null
			if (date == null) return true
			return this.$DateTime.fromISO(date).plus({ days: 7 }) <= this.$DateTime.now()
		},
		title() {
			let now = this.$DateTime.fromISO(this.$DateTime.now().toISODate())
			let lastDate = this.$DateTime.fromISO(this.$DateTime.now().endOf('month').toISODate())
			let { days } = lastDate.diff(now, ['days']).toObject()
			if (days == 0) {
				return this.tt('오늘은 후불결제 정산일 입니다. 대금결제를 완료해주세요.')
			}
			return this.tt('후불결제 정산 기간이 ${1}일 남았습니다. 대금결제를 완료해주세요.', days)
		},
	},
	methods: {
		...mapActions('lms', ['getNopaidList']),
		async show() {
			await this.getNopaidList()
			if (this.isNoticeShowAllow && this.nopaidList.length > 0) {
				await this.$nextTick()
				this.$refs.modal.show()
				return true
			}
			return false
		},
		getDate(item) {
			const { year, month } = item
			return this.$DateTime.local(year, month, 1).endOf('month')
		},
		dateFormat(item) {
			return this.getDate(item).toFormat('yy. L.')
		},
		statusToText(item) {
			let text = ''
			let limitDate = this.$DateTime.fromISO(this.getDate(item).toISODate())
			let now = this.$DateTime.fromISO(this.$DateTime.now().toISODate())
			let { days } = now.diff(limitDate, ['days']).toObject()
			if (limitDate > this.$DateTime.now()) {
				text = this.tt('기한내(${1}일)', days)
			} else {
				text = this.tt('초과(+${1}일)', days)
			}
			return text
		},
		hide() {
			this.$refs.modal.hide()
		},
		clickDonwShow() {
			this.$cookies.set(dontShow7daysKey, this.$DateTime.now().toISO(), '7d')
			this.$refs.modal.hide()
		},
		moveToUrl() {
			window.open(this.item.url, '_blank')
		},
		goToStorePay() {
			this.$root.goToStore(`mypage/unpayment`)
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.modal-dialog {
		width: 360px;
		max-width: 360px;
		.modal-content {
			border: none;
			background-color: transparent;
		}
	}
	.modal-body {
		padding: 0px;
		.popup-wrapper {
			padding: 40px 16px 24px;
			border-radius: 10px 10px 0px 0px;
			width: 360px;
			cursor: pointer;
		}
		.popup-wrapper {
			background-color: white;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			max-height: 540px;
			overflow-y: auto;
			cursor: unset;
			h2 {
				text-align: center;
				word-break: keep-all;
				font-size: 16px;
				color: $SECONDARY_1;
				margin-top: 30px;
				font-weight: 600;
				line-height: 23px;
			}
			p {
				position: relative;
				padding-left: 22px;
				padding-right: 10px;
				line-height: 16px;
				display: flex;
				align-items: center;
				width: 100%;
				font-size: 11px;
				word-break: keep-all;
				font-weight: 400;
				color: $SECONDARY_2;
				&::before {
					position: absolute;
					top: calc(50% - 2px);
					left: 10px;
					content: '';
					margin-right: 8px;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background-color: $SECONDARY_2;
				}
			}
			.remain-table {
				width: 100%;
				margin-top: 16px;
				text-align: center;
				margin-bottom: 8px;
				overflow-y: auto;
				.remian-row {
					font-size: 12px;
					color: $SECONDARY_1;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 34px;
					border: 1px solid $DIVIDER;
					border-top: none;
					div {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
					}
					&:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
						background-color: $GREY_2;
						border-top: 1px solid $DIVIDER;
					}
					&:last-child {
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					}
					div:nth-child(1) {
						border-right: 1px solid $DIVIDER;
						width: 90px;
					}
					div:nth-child(3) {
						border-left: 1px solid $DIVIDER;
						width: 110px;
					}
					div:nth-child(2) {
						width: 128px;
					}
				}
			}
			.button-primary {
				width: 100%;
				justify-content: center;
				font-size: 16px;
				font-weight: 500;
				border-radius: 8px;
				height: 40px;
				margin-top: 16px;
			}
		}
		.btn-footer {
			width: 100%;
			height: 56px;
			display: flex;
			background-color: white;
			border-radius: 0px 0px 10px 10px;
			border-top: 1px solid $DIVIDER;
			button {
				justify-content: center;
				align-items: center;
				width: 50%;
				font-size: 14px;
				color: $SECONDARY_2;
				font-weight: 500;
				&:last-child {
					border-left: 1px solid $DIVIDER;
				}
			}
		}
	}
}
</style>
