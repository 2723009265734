import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import { DateFormat } from '@/utils/define/DateFormat'
import { ToastType } from '@/utils/define/ToastType'

export default {
	data() {
		return {
			// beingSubscription: false
		}
	},
	computed: {
		...mapState('institutionInformation', ['instInfo']),
		...mapState('license', [
			'licenseManagement',
			'licenseRatePlan',
			'paymentMethod',
			'licensePurchase',
			'licenseHistory',
			'purchaseCompleteInfo',
			'purchaseForm',
			'hasSubscriptionError',
			'MAX_QUANTITY',
			'MAX_MONTH',
			'EBILL_DUE_TIME',
			'type',
			'authEdu',
			'status',
			'license',
			'isMonthly',
		]),
		...mapGetters('license', [
			'isLicenseActivate',
			'isLicenseDepositWait',
			'isFreeTier',
			'isTrial',
			'isEducation',
			'beingSubscription',
			'equalLicenseCount',
			'hasPaymentMethod',
			'hasLicenseHistory',
			'getLicenseHistory',
			'hasSubscriptionError',
			'getPurchasePrice',
			'getAdditonalPrice',
			'getCompletePurchasePrice',
			'isCertEdu',
		]),
		isNotCertEdu() {
			return this.license.type === this.type.LITE_EDU && !this.instInfo.info.isEdu
		},
		licenseTypeText() {
			return this.licenseType2Text(this.license.type)
		},
		licenseTypeTextForComplete() {
			return this.licenseType2Text(this.purchaseForm.plan)
		},
		licenseStatusText() {
			return this.isLicenseActivate ? this.tt('활성화') : this.tt('만료됨')
		},
		licenseQuantityText() {
			if (this.license.quantity == this.MAX_QUANTITY) return this.tt('무제한')
			return this.license.quantity ? this.license.quantity.toLocaleString() : ''
		},
		isPaymentMethodCardRegistered() {
			return this.purchaseForm.paymentMethod === this.paymentMethod.CARD_REGISTERED
		},
		isLicenseLessThanPurchaseError() {
			return this.licenseManagement.subscription.errorCode === 1
		},
		isPaymentCardError() {
			return this.licenseManagement.subscription.errorCode === 2
		},
		isSubscriptionPurchaseError() {
			return [3, 4, 500].indexOf(this.licenseManagement.subscription.errorCode) >= 0
		},
		isMemberFull() {
			return this.license.quantity === this.memberCount
		},
		memberCount() {
			return this.instInfo.allMemberCount
		},
		isPayLicense() {
			return !this.isFreeTier && !this.isTrial
		},
	},
	methods: {
		...mapMutations('license', ['setCardId', 'setPhoneNumber', 'setMonthly', 'savePurchaseForm', 'initPurchaseForm']),
		...mapActions('license', [
			'loadLicense',
			'loadLicenseManagement',
			'loadLicenseRatePlan',
			'loadLicensePurchaseInfo',
			'getAdditionQuantity',
			'getAdditionMonth',
			'purchaseLicenseBasic',
			'additionMonth',
			'additionQuantity',
			'lookupPreviousEbillInfo',
			'loadLicense',
			'loadLicenseHistory',
			'subscriptBasicLicense',
			'loadLicenseInfo4Subscription',
			'updateLicenseSubscription',
			'unsubscribeLicense',
			'loadAuthEdu',
			'cancelWaitingLicense',
		]),
		formatDatetime(datetime, unit = true) {
			if (unit) return this.tt('${1} 까지', DateTime.fromISO(datetime).toFormat(DateFormat.DashDateString))
			return DateTime.fromISO(datetime).toFormat(DateFormat.DashDateString)
		},
		formatDepositExpireDate() {
			const nowDateTime = DateTime.now()
			const tomorrowDate = nowDateTime.plus({ months: 1 }).toFormat('yyyy-MM-dd')
			return tomorrowDate
		},
		formatExpireDate(expireDate) {
			const nowDateTime = DateTime.now().toFormat('yyyy-MM-dd')
			const expireDateTime = DateTime.fromISO(expireDate).toFormat('yyyy-MM-dd')
			if (nowDateTime == expireDateTime) return `오늘 ${this.EBILL_DUE_TIME}`
			return expireDateTime
		},
		paymentMethod2Text(method, isAddition = false) {
			if (isAddition) {
				switch (method) {
					case this.paymentMethod.CARD_REGISTERED:
					case this.paymentMethod.CARD_INICIS:
						return this.tt('신용/체크카드')
					case this.paymentMethod.ELECTRONIC_BILL:
						return this.tt('전자세금계산서')
				}
			} else {
				switch (method) {
					case this.paymentMethod.CARD_REGISTERED:
						return this.tt(this.beingSubscription ? '카드 정기결제' : '카드 결제')
					case this.paymentMethod.CARD_INICIS:
						return this.tt('신용/체크카드')
					case this.paymentMethod.ELECTRONIC_BILL:
						return this.tt('전자세금계산서')
					case this.paymentMethod.FREE:
						return this.tt('프리티어')
				}
			}
		},
		showSuccessToast() {
			const title = this.tt('라이선스 주문 완료')
			const content = this.tt('라이선스 주문이 완료되었습니다.')
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
		},
		licenseType2Text(licenseType) {
			switch (licenseType) {
				case this.type.FREE_TIER:
					return this.tt('프리티어')
				case this.type.STANDARD:
					return this.tt('STANDARD')
				case this.type.BASIC:
					return this.tt('BASIC')
				case this.type.LITE_EDU:
					return this.tt('라이트 대학할인')
				case this.type.EDUCATION:
					return this.tt('대학 무제한')
				case this.type.TRIAL:
					return this.tt('FREE')
			}
		},
	},
}
