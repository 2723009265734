<template>
	<div class="nudge-wrapper" v-if="isNudgeVisible">
		<img class="close" @click="closeNudge" src="@/assets/svg/close.svg" />
		<img src="./image/nudge-m10-3.png" @click="goM10()" />
	</div>
</template>
<script>
import PageName from '@/utils/define/PageName'
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters('license', ['isFreeTier', 'isTrial', 'isLicenseActivate']),
		isNudgeVisible() {
			return (this.isFreeTier || this.isTrial || !this.isLicenseActivate) && this.nudgeShow
		},
	},
	data() {
		return {
			nudgeShow: false,
		}
	},
	mounted() {
		if (this.$route.name == PageName.Dashboard) {
			const date = localStorage.getItem('closeNudge3')
			if (!date) this.nudgeShow = true
			else {
				if (date != this.$DateTime.now().toISODate()) this.nudgeShow = true
			}
		}
	},
	methods: {
		closeNudge() {
			localStorage.setItem('closeNudge3', this.$DateTime.now().toISODate())
			this.nudgeShow = false
		},
	},
}
</script>
<style lang="scss" scoped>
.nudge-wrapper {
	position: fixed;
	right: 30px;
	bottom: 30px;
	z-index: 10;
	img {
		cursor: pointer;
		width: 375px;
		height: 260px;
		border-radius: 10px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	}
	.close {
		filter: brightness(100);
		cursor: pointer;
		position: absolute;
		width: 24px;
		height: 24px;
		right: 10px;
		top: 10px;
	}
}
</style>
