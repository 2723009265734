<template>
	<div class="AuthorityDetail">
		<div class="AppContainer__location-title">
			<h5 class="AppContainer__location-name">
				{{ permission.name }}
			</h5>
			<div class="AppContainer__location-btn-group">
				<button
					class="btn"
					@click="showDuplicateModal"
					v-if="userPermission.permission && userPermission.permission.create && userPermission.permission.update"
				>
					<img src="@/assets/svg/Duplicate.svg" alt="복제icon" />{{ tt('복제') }}
				</button>
				<button class="btn" @click="showEditModal" v-if="userPermission.permission && userPermission.permission.update">
					<img src="@/assets/svg/button-icon-edit.svg" alt="icon" />{{ tt('편집') }}
				</button>
				<button
					class="btn"
					@click="showDeleteModal"
					:disabled="permission.isAlmighty"
					v-if="userPermission.permission && userPermission.permission.delete"
				>
					<img src="@/assets/svg/Trash.svg" alt="icon" />{{ tt('삭제') }}
				</button>
			</div>
		</div>
		<div class="AuthorityDetail__table Table__wrap" :class="{ experience: isAppModeExperience }">
			<table>
				<thead>
					<tr>
						<th>{{ tt('분류') }}</th>
						<th>{{ tt('기능') }}</th>
						<th>{{ tt('권한여부') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td rowspan="6">
							{{ tt(title_authority.institute.title) }}
						</td>
						<td>{{ tt(title_authority.institute.info.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.institute.info.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.institute.info.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.institute.info.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.license.create) }}</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.license.create"
									v-on:change="changeCheckbox(authority.license.create)"
									:disabled="almighty"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.license.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.license.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.license.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.license.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.license.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.license.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="6">
							{{ tt(title_authority.group.title) }}
						</td>
						<td>
							{{ tt(title_authority.institute.organization.read) }}
						</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.institute.organization.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>
							{{ tt(title_authority.institute.organization.update) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.institute.organization.update"
									v-on:change="changeCheckbox"
									:disabled="almighty"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.group.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.group.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.group.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.group.read" v-on:change="changeCheckbox" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.group.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.group.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.group.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.group.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="4">
							{{ tt(title_authority.location.title) }}
						</td>
						<td>{{ tt(title_authority.location.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.location.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.location.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.location.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.location.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.location.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.location.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.location.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="4">
							{{ tt(title_authority.storage.title) }}
						</td>
						<td>{{ tt(title_authority.storage.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.storage.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.storage.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.storage.read" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.storage.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.storage.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.storage.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.storage.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="4">
							{{ tt(title_authority.institute.member.title) }}
						</td>
						<td>
							{{ tt(title_authority.institute.member.create) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.institute.member.create"
									v-on:change="changeCheckbox"
									:disabled="almighty"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.institute.member.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.institute.member.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>
							{{ tt(title_authority.institute.member.update) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.institute.member.update"
									v-on:change="changeCheckbox"
									:disabled="almighty"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>
							{{ tt(title_authority.institute.member.delete) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.institute.member.delete"
									v-on:change="changeCheckbox"
									:disabled="almighty"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="4">
							{{ tt(title_authority.permission.title) }}
						</td>
						<td>{{ tt(title_authority.permission.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.permission.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.permission.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.permission.read" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.permission.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.permission.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.permission.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.permission.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="4">
							{{ tt(title_authority.category.title) }}
						</td>
						<td>{{ tt(title_authority.category.create) }}</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.category.create"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.category.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.category.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.category.update) }}</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.category.update"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.category.delete) }}</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.category.delete"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="4">
							{{ tt(title_authority.mngcode.title) }}
						</td>
						<td>{{ tt(title_authority.mngcode.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.mngcode.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.mngcode.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.mngcode.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.mngcode.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.mngcode.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.mngcode.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.mngcode.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="4">
							{{ tt(title_authority.budget.title) }}
						</td>
						<td>{{ tt(title_authority.budget.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.budget.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.budget.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.budget.read" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.budget.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.budget.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.budget.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.budget.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="6">
							{{ tt(title_authority.draft.title) }}
						</td>
						<td>{{ tt(title_authority.draft.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.draft.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.draft.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.draft.read" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.draft.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.draft.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.draft.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.draft.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr v-if="authority.order">
						<td>{{ tt(title_authority.draft.order) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.order.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr v-if="authority.order">
						<td>{{ tt(title_authority.draft.orderRead) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.order.read" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="4">
							{{ tt(title_authority.cargo.title) }}
						</td>
						<td>{{ tt(title_authority.cargo.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.cargo.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.cargo.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.cargo.read" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.cargo.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.cargo.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.cargo.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.budget.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="9">
							{{ tt(title_authority.item.title) }}
						</td>
						<td>{{ tt(title_authority.item.create) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.create" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.item.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.item.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.item.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.item.dump.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.dump.read" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.item.dump.update) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.dump.update" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.item.dump.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.dump.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.item.history.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.history.read" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.item.history.delete) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.item.history.delete" v-on:change="changeCheckbox" :disabled="almighty" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td rowspan="14">
							{{ tt(title_authority.safe.title) }}
						</td>
						<td>{{ tt(title_authority.safe.msds.read) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="authority.safe.msds.read" v-on:change="changeCheckbox" disabled />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.safe.msds.management) }}</td>
						<td>
							<label class="switch">
								<input type="checkbox" v-model="management.msds" v-on:change="changeCheckbox" />
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>
							{{ tt(title_authority.safe.dangerstorage.read) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.safe.dangerstorage.read"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>
							{{ tt(title_authority.safe.dangerstorage.management) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="management.dangerstorage"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>
							{{ tt(title_authority.safe.harmfulchemical.read) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.safe.harmfulchemical.read"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>
							{{ tt(title_authority.safe.specialmanagement.read) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.safe.specialmanagement.read"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.safe.diagnosis.read) }}</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.safe.diagnosis.read"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>
							{{ tt(title_authority.safe.diagnosis.management) }}
						</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="management.diagnosis"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
					<tr>
						<td>{{ tt(title_authority.safe.legalitems.read) }}</td>
						<td>
							<label class="switch">
								<input
									type="checkbox"
									v-model="authority.safe.legalitems.read"
									v-on:change="changeCheckbox"
									:disabled="almighty || liteStatus"
								/>
								<span class="slider round"></span>
							</label>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<modal-duplicate-permission ref="modal-duplicate-permission" @isHide="hideDuplicateModal"></modal-duplicate-permission>
		<modal-edit-permission ref="modal-edit-permission" @isHide="hideEditModal"></modal-edit-permission>
		<modal-delete-permission ref="modal-delete-permission" @isHide="hideDeleteModal"></modal-delete-permission>
	</div>
</template>

<script>
import ModalDuplicatePermission from '@/views/institute/permission/modals/ModalDuplicatePermission.vue'
import ModalEditPermission from '@/views/institute/permission/modals/ModalEditPermission.vue'
import ModalDeletePermission from '@/views/institute/permission/modals/ModalDeletePermission.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import router from '@/router'
import PermissionList from '@/views/institute/permission/pages/PermissionList.vue'
import PageName from '@/utils/define/PageName'
import institutePermissionAPI from '@/services/api/institute/permission'

export default {
	name: 'PermissionDetail',
	props: {
		state: {
			type: Object,
		},
	},
	components: {
		ModalDuplicatePermission,
		ModalEditPermission,
		ModalDeletePermission,
		PermissionList,
	},
	async created() {
		await this.loadPermission()
		await this.loadPermissionList()
		this.initManagement()
	},
	data() {
		return {
			keyword: '',
			offset: 0,
			length: 50,
			authority: {
				item: {
					dump: {},
					history: {},
				},
				group: {},
				license: {},
				mngcode: {},
				storage: {},
				category: {},
				location: {},
				permission: {},
				institute: {
					info: {},
					member: {},
					organization: {},
				},
				payment: {},
				safe: {
					dangerstorage: {},
					specialmanagement: {},
					diagnosis: {},
					msds: {},
					legalitems: {},
					harmfulchemical: {},
				},
				budget: {},
				draft: {},
				cargo: {},
			},
			title_authority: {
				institute: {
					title: '기관 정보',
					info: {
						read: '기관 정보 조회',
						update: '기관 정보 편집',
					},
					organization: {
						read: '조직도 조회',
						update: '조직도 편집',
					},
					member: {
						title: '멤버',
						create: '멤버 등록',
						read: '멤버 정보 조회',
						update: '멤버 정보 편집',
						delete: '멤버 내보내기',
					},
				},
				license: {
					read: '라이선스 조회',
					create: '라이선스 구매',
					delete: '라이선스 해제',
					update: '라이선스 정기결제 설정',
				},
				group: {
					title: '조직도',
					create: '그룹 생성',
					read: '그룹 조회',
					update: '그룹 정보 편집',
					delete: '그룹 삭제',
				},
				location: {
					title: '위치',
					create: '위치 추가',
					read: '위치 조회',
					update: '위치 편집',
					delete: '위치 삭제',
				},
				storage: {
					title: '보관함',
					create: '보관함 생성',
					read: '모든 보관함 조회',
					update: '보관함 편집',
					delete: '보관함 삭제',
				},
				permission: {
					title: '권한',
					create: '권한 생성',
					read: '권한 조회',
					update: '권한 편집',
					delete: '권한 삭제',
				},
				category: {
					title: '카테고리 설정',
					create: '카테고리 생성',
					read: '카테고리 조회',
					update: '카테고리 정보 편집',
					delete: '카테고리 삭제',
				},
				mngcode: {
					title: '관리번호',
					create: '관리번호 추가',
					read: '관리번호 조회',
					update: '관리번호 편집',
					delete: '관리번호 삭제',
				},
				budget: {
					title: '예산관리',
					create: '예산 등록',
					read: '예산 조회',
					update: '예산 편집',
					delete: '예산 삭제',
				},
				draft: {
					title: '구매관리',
					create: '지출결의서 작성',
					read: '지출결의서 결재함 조회',
					delete: '지출결의서 삭제',
					update: '기안 결재/반려',
					order: '상품주문',
					orderRead: '상품결제',
				},
				cargo: {
					title: '입고관리',
					create: '입고 등록',
					read: '입고 물품 조회',
					update: '입고 물품 편집/입고 처리',
					delete: '입고 물품 삭제',
				},
				item: {
					title: '물품관리',
					create: '물품 등록',
					read: '물품 목록 조회',
					update: '물품 정보 편집',
					delete: '물품 폐기/삭제',
					dump: {
						read: '폐기/삭제 물품 조회',
						update: '폐기/삭제 물품 복구',
						delete: '폐기/삭제 물품 영구 삭제',
					},
					history: {
						read: '물품 사용기록 조회',
						delete: '물품 사용기록 삭제',
					},
				},
				safe: {
					title: '안전관리',
					dangerstorage: {
						read: '위험물 지정수량 배수 조회',
						management: '위험물 지정수량 배수 관리',
					},
					diagnosis: {
						read: '특수건강진단 조회',
						management: '특수건강진단 기록 관리',
					},
					msds: {
						read: 'MSDS 조회',
						management: 'MSDS 관리',
					},
					specialmanagement: {
						read: '특별관리물질 조회',
					},
					legalitems: {
						read: '그룹별 법령물품 조회',
					},
					harmfulchemical: {
						read: '유해화학물질 조회',
					},
				},
			},
			authorityList: {},
			permission: {},
			management: {
				msds: true,
				diagnosis: true,
				dangerstorage: true,
			},
		}
	},
	computed: {
		...mapState('permission', ['permissionList']),
		almighty() {
			if (this.permission) {
				return this.permission.isAlmighty
			}
		},
		liteStatus() {
			if (this.state && this.state.institute) {
				return (
					this.state.institute[0].license.type == 'BASIC' ||
					this.state.institute[0].license.type == 'LITE_EDU' ||
					this.state.institute[0].license.type == 'FREE_TIER'
				)
			} else {
				return false
			}
		},
	},
	watch: {},
	methods: {
		...mapActions('permission', ['addPermission', 'updatePermission', 'deletePermission', 'updateOrder', 'copyPermission']),
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		async loadPermission() {
			const response = await institutePermissionAPI.lookupPermission(this.$route.params.instituteId, this.$route.params.id)

			this.permission = response.data
			this.authority = JSON.parse(JSON.stringify(this.permission.value))
			this.setStorageDetailPaths([this.permission.name])
		},
		async loadPermissionList() {
			const response = await institutePermissionAPI.lookupPermissionList(this.$route.params.instituteId, this.keyword, this.offset, this.length)

			this.authorityList = response.data
		},
		initManagement() {
			if (this.authority.safe.msds.create == true) {
				this.management.msds = true
			} else {
				this.management.msds = false
			}

			if (this.authority.safe.diagnosis.create == true) {
				this.management.diagnosis = true
			} else {
				this.management.diagnosis = false
			}

			if (this.authority.safe.dangerstorage.create == true) {
				this.management.dangerstorage = true
			} else {
				this.management.dangerstorage = false
			}
		},
		changeManagement() {
			if (this.management.msds) {
				this.authority.safe.msds.create = true
				this.authority.safe.msds.delete = true
			} else {
				this.authority.safe.msds.create = false
				this.authority.safe.msds.delete = false
			}

			if (this.management.diagnosis) {
				this.authority.safe.diagnosis.create = true
				this.authority.safe.diagnosis.delete = true
			} else {
				this.authority.safe.diagnosis.create = false
				this.authority.safe.diagnosis.delete = false
			}

			if (this.management.dangerstorage) {
				this.authority.safe.dangerstorage.create = true
				this.authority.safe.dangerstorage.update = true
				this.authority.safe.dangerstorage.delete = true
			} else {
				this.authority.safe.dangerstorage.create = false
				this.authority.safe.dangerstorage.update = false
				this.authority.safe.dangerstorage.delete = false
			}
		},
		showDuplicateModal() {
			this.$refs['modal-duplicate-permission'].show()
		},
		async hideDuplicateModal(check) {
			this.$refs['modal-duplicate-permission'].hide()
			if (check) {
				let payload = {
					instId: this.$route.params.instituteId,
					body: {
						id: this.permission.id,
					},
				}
				const permissionId = await this.copyPermission(payload)
				this.$root.toast('복제 완료', '권한이 복제되었습니다', 'success')
				await this.$router.push({
					name: PageName.Institution.AuthorityDetail,
					params: { id: permissionId },
				})
				await this.loadPermission()
			}
		},
		showEditModal() {
			this.$refs['modal-edit-permission'].show(this.permission.id)
		},
		hideEditModal(permission) {
			this.$refs['modal-edit-permission'].hide()
			const payload = {
				instId: this.$route.params.instituteId,
				body: permission,
			}
			this.updatePermission(payload).then(() => {
				this.$root.toast('권한명 편집 완료', '권한명이 편집되었습니다', 'success')
				this.loadPermission()
			})
		},
		showDeleteModal() {
			this.$refs['modal-delete-permission'].show()
		},
		hideDeleteModal(check) {
			this.$refs['modal-delete-permission'].hide()
			if (check) {
				const payload = {
					instId: this.$route.params.instituteId,
					id: this.permission.id,
				}
				this.deletePermission(payload).then(() => {
					this.$root.toast('권한 삭제 완료', '권한을 삭제하였습니다', 'success')
					this.authorityList.list.forEach(element => {
						if (this.permission.id == element.id) {
							this.authorityList.list.splice(element.order - 1, 1)
						}
					})
					this.orderEdit()
				})
			}
		},
		orderEdit() {
			let copyList = this.authorityList.list
			let list = []
			for (let i = 0; i < copyList.length; i++) {
				let item = {
					id: copyList[i].id,
					order: i + 1,
				}
				list.push(item)
			}
			const payload = {
				instId: this.$route.params.instituteId,
				body: {
					permissions: list,
				},
			}
			this.updateOrder(payload).then(() => {
				router.push({ name: PageName.Institution.AuthoritySetting })
			})
		},
		async changeCheckbox(license) {
			if (license == true) {
				this.authority.payment.read = true
				this.authority.payment.update = true
				this.authority.payment.create = true
				this.authority.payment.delete = true
			} else if (license == false) {
				this.authority.payment.read = false
				this.authority.payment.update = false
				this.authority.payment.create = false
				this.authority.payment.delete = false
			}

			await this.changeManagement()
			const payload = {
				instId: this.$route.params.instituteId,
				body: {
					id: this.permission.id,
					value: this.authority,
				},
			}
			this.updatePermission(payload)
				.then(() => {
					const title = this.tt('편집 완료')
					const content = this.tt('편집사항이 저장되었습니다')
					this.$root.toast(title, content, 'success')
					this.loadPermission()
					this.$emit('loadPermission')
				})
				.catch(error => {
					const title = this.tt('편집실패')
					if (error.response.data.code == '03006') {
						this.$root.toast(title, error.response.data.msg, 'error')
					} else {
						this.$root.toast(title, this.tt('권한을 편집하던 중 오류가 발생했습니다'), 'error')
					}
					this.loadPermission()
				})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_authority.scss';
.PermissionDetail {
	overflow: hidden;
}

.Table__wrap {
	max-height: 84vh;
	table {
		thead {
			z-index: 1;
			th {
				&:first-child {
					width: 20%;
					max-width: unset;
				}
			}
		}
		tbody {
			tr {
				cursor: pointer;
				td {
					&:nth-child(1) {
						border-left: 1px solid $DIVIDER !important;
					}
				}
			}
		}
	}
}
</style>
