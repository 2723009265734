var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-item"
  }, [_c('th', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    attrs: {
      "disabled": _vm.item.status === 'FAIL' || !_vm.value && _vm.maxSize === _vm.selectedLength,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.value) ? _vm._i(_vm.value, null) > -1 : _vm.value
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('checked', _vm.item);
      },
      "change": function change($event) {
        var $$a = _vm.value,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.value = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.value = $$c;
        }
      }
    }
  })]), _c('th', [_c('div', [_vm._v(_vm._s(_vm.leadingZeros(_vm.idx, 3)))])]), _c('th', {
    staticClass: "code",
    staticStyle: {
      "width": "110px"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.item.code))])]), _c('th', {
    staticStyle: {
      "width": "90px"
    }
  }, [_c('div', {
    "class": {
      ok: _vm.item.status === 'OK',
      fail: _vm.item.status === 'FAIL'
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.itemStatus) + " ")]), _vm.item.reason ? _c('p', {
    staticClass: "reason"
  }, [_vm._v(_vm._s("(".concat(_vm.tt("".concat(_vm.item.reason)), ")")))]) : _vm._e()])]), _c('th', [_c('div', {
    staticClass: "delete-button",
    on: {
      "click": function click($event) {
        return _vm.$emit('delete', _vm.item);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon/icon-trash-enable.svg")
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }