<template>
	<div class="info-field-wrap" v-if="isLegalList">
		<p class="field-name">{{ tt(legal.legalName) }}</p>
		<ul>
			<li class="description" v-for="l in legal.list" :key="l.legalId">
				{{ l.description }}
			</li>
		</ul>
	</div>
</template>
<script>
export default {
	name: 'LegalInfoField',
	components: {},
	props: {
		legal: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		isLegalList() {
			return this.legal?.list?.length > 0
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-field-wrap {
	padding-right: 40px;
	margin-bottom: 20px;
	width: 500px;

	.field-name {
		text-align: left;
		font-size: 12px;
		font-weight: 400;
		color: $SECONDARY_2;
		margin-bottom: 6px !important;
	}
	.description {
		text-align: left;
		list-style-type: disc;
		margin-left: 22px;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 6px;
	}
}
</style>
