<template>
	<div class="Menu__floating" v-if="selectedItems.length > 0">
		<h4>
			<span class="count">{{ tt('${1}개', selectedItems.length) }}</span>
			{{ tt('선택') }}
		</h4>
		<div class="btn-group">
			<button @click="$refs['modal-remove'].show()">
				<img src="@/assets/svg/Trash.svg" />
				<span>{{ tt('삭제') }}</span>
			</button>
		</div>
		<modal-confirm
			ref="modal-remove"
			title="등록대기 중인 물품 삭제"
			confirmText="삭제"
			variant="warning"
			:content="`등록 대기 중인 물품을 삭제하시겠습니까?`"
			@confirm="remove"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
	computed: {
		...mapState('inventoryGen2/wait', ['selectedItems']),
	},
	methods: {
		...mapMutations('inventoryGen2/wait', ['setSelectedItems']),
		...mapActions('inventoryGen2/wait', ['removeItem']),
		async remove() {
			await this.removeItem({ ids: this.selectedItems.map(it => it.id) })
			this.$root.toast('물품 삭제 완료', `등록대기 중인 물품이 삭제되었습니다.`, 'success')
			this.setSelectedItems([])
		},
	},
}
</script>

<style lang="scss" scoped></style>
