class VVuex {
	constructor(data) {
		this.configure = {}

		for (let key in data) {
			this.configure[key] = data[key]
		}

		this.vData = this._deepCopy(typeof this.configure.state === 'function' ? this.configure.state() : this.configure.state)
		this.data = this._deepCopy(typeof this.configure.state === 'function' ? this.configure.state() : this.configure.state)

		this.configure.state = () => {
			let self = this
			return this.data
		}

		if (this.configure.getters) {
			let orgGetter = this._deepCopy(this.configure.getters)

			for (let key in this.configure.getters) {
				this.configure.getters[key] = state => {
					let self = orgGetter[key](this.vData)
					return new Proxy(orgGetter[key](this.configure.state()), {
						get(target, prop, val) {
							return target[prop]
						},
						set(target, prop, val) {
							if (typeof val === 'object') {
								self[prop] = self._deepCopy(val)
							} else {
								self[prop] = val
							}
							return true
						},
					})
				}
			}
		}

		if (this.configure.actions) {
			this.configure.actions.getModel = ({ commit }) => this.vData

			this.configure.actions.commitModel = ({ commit }) => {
				commit('commitModelData')
			}

			this.configure.actions.resetModel = ({ commit }) => {
				commit('resetModelData')
			}
		}

		if (this.configure.mutations) {
			this.configure.mutations.commitModelData = state => {
				this._deepCopy(this.vData, state)
			}

			this.configure.mutations.resetModelData = state => {
				this._deepCopy(this.data, state)
			}
		}
	}

	_deepCopy(obj, clone = {}) {
		if (Array.isArray(obj)) {
			clone = []
		}
		for (let key in obj) {
			if (Array.isArray(obj[key])) {
				let array = []
				for (let idx in obj[key]) {
					array[idx] = this._deepCopy(obj[key][idx])
				}
				clone[key] = array
			} else if (typeof obj[key] == 'object' && obj[key] != null) {
				clone[key] = this._deepCopy(obj[key])
			} else {
				clone[key] = obj[key]
			}
		}

		return clone
	}
}

module.exports = function (data) {
	return new VVuex(data).configure
}
