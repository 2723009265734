<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade @close="cancel">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="form-input">
				<p class="name">{{ tt(label) }}<span v-if="isRequired" class="required">*</span></p>
				<div class="input-wrapper">
					<input ref="inputValue" :type="type" :placeholder="tt(label + '를 입력해주세요')" v-model="inputValue" />
				</div>
			</div>
			<slot />
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" v-if="noCancel" @click="cancel">
						{{ tt(cancelText) }}
					</button>
					<button :class="`button-${variant}`" v-if="hasConfirm" @click="confirm">
						{{ tt(confirmText) }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	name: 'ModalConfirm',
	components: {},
	props: {
		type: {
			type: String,
			default: 'text',
		},
		title: {
			type: String,
		},
		label: {
			type: String,
		},
		noCancel: {
			type: Boolean,
			default: true,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		hasConfirm: {
			type: Boolean,
			default: true,
		},
		noContent: {
			type: Boolean,
			default: true,
		},
		confirmText: {
			type: String,
			default: '확인',
		},
		cancelText: {
			type: String,
			default: '취소',
		},
		variant: {
			type: String,
			default: 'primary',
		},
		defaultValue: {
			type: [String, Object, Number],
			default: null,
		},
	},
	data() {
		return {
			confirmFn: null,
			cancelFn: null,
			inputValue: null,
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show(confirmFn = null, cancelFn = null) {
			if (confirmFn) this.confirmFn = confirmFn
			if (cancelFn) this.cancelFn = cancelFn
			this.inputValue = this.defaultValue
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cancel() {
			this.$emit('cancel')
			if (this.cancelFn) this.cancelFn()
			this.hide()
		},
		confirm() {
			this.$emit('confirm', this.inputValue)
			if (this.confirmFn) this.confirmFn(this.inputValue)
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';

.form-input {
	position: relative;
	width: 100%;
	.name {
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 8px !important;
	}
	.input-wrapper {
		display: flex;
		align-items: center;
		input[type='text'],
		input[type='number'] {
			width: 100%;
			border-radius: 5px;
			font-size: 0.875rem;
			font-weight: 400;
			padding-left: 10px;
			height: 30px;
			border: 1px solid $COLOR_CCC;
			&:read-only {
				background-color: $GREY_1;
				border: 1px solid $INPUT_BORDER;
				&:focus {
					border: 1px solid $INPUT_BORDER;
				}
			}
			&.error {
				border: 1px solid $RED;
				&:focus {
					border: 1px solid $RED;
				}
			}
		}
		input::placeholder {
			color: $PLACEHOLDER;
		}
		b {
			position: absolute;
			bottom: 0.35rem;
			right: 12px;
			font-size: 12px;
			font-weight: 400;
		}

		.form-number-input {
			padding-right: 100px;
		}
	}
}
</style>
