var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    on: {
      "close": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('div', {
          staticClass: "footer-left"
        }, [_vm.isPrev ? _c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.clickPrev
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('이전으로')) + " ")]) : _vm._e()]), _c('div', {
          staticClass: "capture-button-wrap"
        }, [_vm.isCameraStarted && !_vm.isMax ? _c('img', {
          staticClass: "capture-button",
          attrs: {
            "src": require("@/views/inventoryGen2/svg/create/cam-capture-button.svg")
          },
          on: {
            "click": _vm.capture
          }
        }) : _vm._e()]), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-primary",
          attrs: {
            "disabled": !_vm.hasImages
          },
          on: {
            "click": function click($event) {
              _vm.hasImages ? _vm.goNext() : '';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.nextBtnText)) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "camera-wrap"
  }, [_vm.isStartPopup && _vm.showPopup ? _c('div', {
    staticClass: "start-popup",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('시약의 정보가 잘 보이도록<br/>여러번 촬영해주세요'))
    }
  }) : _vm._e(), _c('web-cam', {
    ref: "camera",
    attrs: {
      "width": "1000",
      "height": "600",
      "resolution": _vm.resolution,
      "deviceId": _vm.deviceId,
      "autoplay": true,
      "frontCam": false
    },
    on: {
      "cameras": _vm.onCameras,
      "started": _vm.startedCameras,
      "stopped": _vm.stoppedCameras,
      "error": _vm.error
    }
  }), _c('div', {
    staticClass: "image-list"
  }, [!_vm.isMax ? _c('img', {
    staticClass: "image-add",
    attrs: {
      "src": require("@/views/inventoryGen2/svg/create/add-image.svg")
    },
    on: {
      "click": _vm.uploadImage
    }
  }) : _vm._e(), _c('input', {
    ref: "file-input",
    staticClass: "image-input",
    attrs: {
      "type": "file",
      "accept": "image/png, image/gif, image/jpeg, image/jpg",
      "multiple": "",
      "hidden": ""
    },
    on: {
      "change": _vm.onChangeFile
    }
  }), _vm._l(_vm.urls, function (url, idx) {
    return _c('div', {
      key: idx,
      staticClass: "image-wrap"
    }, [_c('img', {
      staticClass: "upload-image",
      attrs: {
        "src": _vm.imageSrc(url)
      },
      on: {
        "click": function click($event) {
          return _vm.$refs['image-viewer'].show(idx);
        }
      }
    }), _c('img', {
      staticClass: "remove-image",
      attrs: {
        "src": require("@/views/inventoryGen2/svg/create/icon-remove-img.svg")
      },
      on: {
        "click": function click($event) {
          return _vm.removeImage(url);
        }
      }
    })]);
  }), _c('modal-multi-image-viewer', {
    ref: "image-viewer",
    attrs: {
      "title": "이미지",
      "imageList": _vm.urls
    }
  })], 2)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }