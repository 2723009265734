<template>
	<div class="member-list-wrapper" v-if="members">
		<div class="list-header">
			<div class="list-column member">{{ tt('멤버') }}</div>
			<div class="list-column email">{{ tt('이메일') }}</div>
			<div class="list-column status">{{ tt('구분') }}</div>
		</div>
		<div class="list-wrapper">
			<migration-member-table-item v-for="member in members" :member="member" :key="member.id"></migration-member-table-item>
		</div>
	</div>
</template>

<script>
import MigrationMemberTableItem from '@/views/migration/components/MigrationMemberTableItem.vue'
export default {
	components: { MigrationMemberTableItem },
	props: {
		members: {
			type: Array,
			default: null,
		},
	},
}
</script>

<style lang="scss" scoped>
.member-list-wrapper {
	height: 420px;
	width: 600px;
	border: 1px solid $INPUT;
	border-radius: 3px;
	overflow: hidden;
	.list-header {
		display: flex;
		height: 40px;
		justify-content: center;
		border-bottom: 1px solid $INPUT;
		border-radius: 3px 3px 0 0;

		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0px;
		text-align: left;
		background: $GREY_2;

		.list-column {
			display: flex;
			align-items: center;
			padding: 0px 7px;
			margin: 10px 0px;
			border-left: 1px solid $INPUT;
			border-right: 1px solid $INPUT;
			color: $SECONDARY_2;
			&.member {
				width: 140px;
			}

			&.email {
				width: 286px;
			}

			&.status {
				width: 133px;
			}
		}
	}
	.list-wrapper {
		height: 360px;
		overflow-y: auto;
	}
}
</style>
