<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade hide-footer @close="cancel">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="body">
				<div class="text-wrap" v-html="tt(headerText)"></div>
				<div class="reason-wrap" v-click-outside="focusOut">
					<div class="input-head-text">
						<p>{{ tt(titleTextarea) }}</p>
						<p class="text-length">
							<span class="length" :class="{ over: isLengthOver }">{{ text.length }}</span
							>/{{ textMaxLength }}
						</p>
					</div>
					<textarea
						class="input-reason"
						:class="{ error: isShowTextError && isLengthOver }"
						v-model="text"
						@focus="isFocus = true"
						:placeholder="tt(placeholder)"
					/>
					<p class="error-msg" v-if="isShowTextError && isLengthOver">
						<img src="@/views/inventoryGen2/components/svg/icon-error.svg" />
						{{ tt(errorMsg) }}
					</p>
					<recent ref="recent" :show="isKeywordWrapperOpen" :recentId="title" @clickRecent="clickRecent" />
				</div>
			</div>
			<div class="modal-footer-wrap">
				<button class="button-white" @click="cancel">
					{{ tt('취소') }}
				</button>
				<button :class="`button-${variant}`" @click="complete">
					{{ tt(confirmText) }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
import Recent from '../modalInputUse/recent.vue'
export default {
	name: 'ModalTextarea',
	mixins: [MixinItem],
	components: { Recent },
	props: {
		title: {
			type: String,
			default: '',
		},
		headerText: {
			type: String,
			default: '',
		},
		titleTextarea: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMsg: {
			type: String,
			default: '글자수를 초과하였습니다.',
		},
		textMaxLength: {
			type: Number,
			default: 100,
		},
		noRecent: {
			type: Boolean,
			default: false,
		},
		confirmText: {
			type: String,
			default: '',
		},
		variant: {
			type: String,
			default: 'primary',
		},
	},
	data() {
		return {
			isFocus: false,
			text: '',
			isShowTextError: false,
		}
	},
	computed: {
		isLengthOver() {
			return this.text.length > this.textMaxLength
		},
		isKeywordWrapperOpen() {
			return this.isFocus && this.text.length <= 0 && !this.noRecent
		},
	},
	watch: {
		isLengthOver(value) {
			if (!this.isLengthOver) this.isShowTextError = false
		},
	},
	methods: {
		initData() {
			this.text = ''
			this.isFocus = false
			this.isShowTextError = false
		},
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
			this.initData()
		},
		cancel() {
			this.$emit('cancel')
			this.hide()
		},
		checkValid() {},
		complete() {
			if (this.isLengthOver) {
				this.isShowTextError = true
				return
			}
			if (this.text) this.$refs['recent']?.addRecent(this.text)
			this.$emit('complete', this.text)
		},
		clickRecent(text) {
			this.text = text
			if (!this.noRecent) this.$refs['recent'].addRecent(this.text)
			this.isFocus = false
		},
		focusOut() {
			this.isFocus = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/inventory/gen2/modal/_modal-input-use.scss';
@import '@/styles/scss/modal/_modal-base.scss';
</style>
