<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddOrganizationGroupPopup">
			<div class="Popup__background"></div>
			<div class="Organization__add-group-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title" v-if="isAddMode">
						{{ tt('그룹 생성') }}
					</h5>
					<h5 class="Popup__title" v-else>
						{{ tt('그룹 정보 편집') }}
					</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__radio-field" v-if="!isInstitute">
						<p>{{ tt('그룹유형') }}</p>
						<div class="radio-form">
							<input type="radio" name="LAB" id="LAB" value="LAB" :checked="isLab" v-model="groupInfo.type" />
							<label for="LAB"
								><span><span></span></span>{{ tt('연구실') }}</label
							>
							<input type="radio" name="NORMAL" id="NORMAL" value="NORMAL" :checked="isNormal" v-model="groupInfo.type" />
							<label for="NORMAL"
								><span><span></span></span>{{ tt('일반') }}</label
							>
						</div>
					</div>
					<div class="Popup__input-field">
						<p>{{ tt('그룹명') }}<span class="required">*</span></p>
						<input type="text" :placeholder="tt('그룹명을 입력해주세요')" maxlength="30" v-model="groupInfo.name" @change="changeName" />
					</div>
					<div class="Popup__input-field">
						<p>{{ tt('연락처') }}</p>
						<input type="text" :placeholder="tt('연락처를 입력해주세요')" v-model="groupInfo.phone" @input="autoHypen" maxlength="13" />
					</div>
					<div class="Popup__input-field Dropdown-input" v-if="isLab">
						<p>{{ tt('연구실안전책임자') }}</p>
						<div @click="openModalSelectMember('safeResponsibility', assignee)">
							<input
								class="cursor"
								type="text"
								:placeholder="tt('연구실안전책임자를 선택해주세요')"
								:value="checkInvalidAssignee(assignee)"
								disabled
							/>
						</div>
					</div>
					<div class="Popup__input-field Dropdown-input" v-if="isLab">
						<p>{{ tt('연구실안전관리자') }}</p>
						<div @click="openModalSelectMember('safeAdmin', subAssignee)">
							<input
								class="cursor"
								type="text"
								:placeholder="tt('연구실안전관리자를 선택해주세요')"
								:value="checkInvalidAssignee(subAssignee)"
								disabled
							/>
						</div>
					</div>
					<div class="Popup__input-field deleteable-input-field Dropdown-input" v-if="!isStart">
						<p>{{ tt('위치') }}</p>
						<div v-for="(item, index) in locationList" :key="`location_` + index">
							<input type="text" :placeholder="tt('위치를 선택해주세요')" readonly :value="item.name" @click="selectLocation" />
							<button class="Btn-delete-item btn" @click="removeLocationList(index)">
								<img src="@/assets/svg/recycle-bin.svg" />
							</button>
						</div>

						<button class="AppContainer__add-item-btn" @click="addLocation">+{{ tt('추가하기') }}</button>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="complete">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
			<portal-target name="lm-modal-add-group"></portal-target>

			<portal to="lm-modal-add-group">
				<modal-select-member ref="modal-select-member" :isStart="isStart" @selectMember="setSelectedMember"></modal-select-member>
				<modal-select-treeview
					ref="modal-select-treeview"
					title="위치 지정"
					mode="STORAGE"
					:treeData="treeData"
					:selectedList="locationIdList"
					@submit="addStorage"
					:checkChild="false"
				></modal-select-treeview>
				<!-- <modal-select-location
                    ref="modal-select-location"
                    @isHide="hideModalLocation"
                ></modal-select-location> -->
			</portal>
		</div>
	</portal>
</template>

<script>
import { mapGetters } from 'vuex'
import MixinModal from '@/mixins/modal'
import ModalSelectMember from './ModalSelectMember.vue'
import instituteGroupAPI from '@/services/api/institute/group'
import Validation from '@/utils/Validation'
import ModalSelectLocation from '@/views/common/components/modals/ModalSelectGroup.vue'
import ModalSelectTreeview from '@/views/common/components/modals/ModalSelectTreeview.vue'
import LocationBox from '@/views/institute/location/modals/components/LocationBox.vue'
import locationAPI from '@/services/api/institute/location'
export default {
	name: 'ModalAddEditGroup',
	props: {
		isStart: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		ModalSelectMember,
		ModalSelectLocation,
		ModalSelectTreeview,
		LocationBox,
	},
	mixins: [MixinModal],
	data() {
		return {
			isShow: false,
			groupInfo: {
				parentId: '',
				name: '',
				type: '',
				phone: '',
				assigneeId: '',
				subAssigneeId: '',
				locationsId: [],
			},
			assignee: undefined,
			subAssignee: undefined,
			modalMode: '',
			locIdx: 0,
			locationList: [],
			assigneeCheck: true,
			treeData: [],
			locationIdList: [],
		}
	},
	watch: {
		groupInfo: {
			deep: true,
			handler(groupInfo) {
				this.groupInfo = groupInfo
			},
		},
	},
	computed: {
		...mapGetters('organization', ['selectedGroupInfo']),
		isAddMode() {
			return this.modalMode === 'ADD'
		},
		isInstitute() {
			return this.groupInfo.type === 'INSTITUTE'
		},
		isLab() {
			return this.groupInfo.type === 'LAB'
		},
		isNormal() {
			return this.groupInfo.type === 'NORMAL'
		},
		location() {
			return {
				id: '',
				name: '',
				parentId: '',
			}
		},
	},
	methods: {
		show(modalMode) {
			this.isShow = true
			this.modalMode = modalMode
			this.initForm()
			this.loadLocationList()
		},
		initForm() {
			this.locationList = [
				{
					id: '',
					parentId: '',
					name: '',
				},
			]
			this.locationIdList = []
			if (this.isAddMode) {
				this.groupInfo = {
					parentId: '',
					name: '',
					type: 'LAB',
					phone: '',
					assigneeId: '',
					subAssigneeId: '',
					locationsId: [],
				}
				this.assignee = undefined
				this.subAssignee = undefined
				this.locIdx = 0
			} else {
				const groupId = this.selectedGroupInfo.id
				this.groupInfo = {
					name: this.selectedGroupInfo.name,
					type: this.selectedGroupInfo.type,
					phone: this.selectedGroupInfo.phone,
					assigneeId: this.selectedGroupInfo.assignee.id,
					subAssigneeId: this.selectedGroupInfo.subAssignee.id,
					locationsId: [],
				}
				if (this.selectedGroupInfo.locations.length > 0) {
					this.locationList = this.$root.deepCopy(this.selectedGroupInfo.locations)
					this.locationList.forEach(x => {
						this.locationIdList.push(x.id)
					})
				}
				this.assignee = {
					...this.$root.deepCopy(this.selectedGroupInfo.assignee),
				}
				this.subAssignee = {
					...this.$root.deepCopy(this.selectedGroupInfo.subAssignee),
				}
				instituteGroupAPI.lookupGroupMemberList(groupId).then(response => {
					if (response.data.length == 0) this.assigneeCheck = false
				})
			}
		},
		async loadLocationList() {
			let response = await locationAPI.lookupLocationTree(this.$route.params.instituteId)
			this.treeData = response.data
		},
		setGroupType(type) {
			this.groupType = type
		},
		setSelectedMember(confirmData) {
			const { modalType, member } = confirmData
			switch (modalType) {
				case 'safeResponsibility':
					this.assignee = member
					this.groupInfo.assigneeId = member.id
					break
				case 'safeAdmin':
					this.subAssignee = member
					this.groupInfo.subAssigneeId = member.id
					break
			}
			this.groupInfo = { ...this.groupInfo }
		},
		changeName(event) {
			this.groupInfo.name = event.target.value
		},
		addInputLocation() {
			this.groupInfo.locations.push({})
		},
		removeInputLocation(index) {
			this.groupInfo.locations.splice(index, 1)
		},
		checkInvalidAssignee(value) {
			return value === undefined ? '' : value.name
		},
		openModalSelectMember(modalType, member) {
			this.$refs['modal-select-member'].show(modalType, member)
		},
		autoHypen() {
			this.groupInfo.phone = Validation.autoHypenTel(this.groupInfo.phone)
		},
		complete() {
			const { instituteId, id } = this.selectedGroupInfo
			this.locationIdList.forEach(x => {
				this.groupInfo.locationsId.push(x)
			})
			let groupName = this.groupInfo.name.trim()

			if (groupName == null || groupName.length == 0) {
				const title = this.tt('그룹생성 실패')
				const content = this.tt('그룹명을 입력해주세요')
				const type = 'error'
				this.$root.toast(title, content, type)

				return false
			} else {
				switch (this.modalMode) {
					case 'ADD':
						this.groupInfo.parentId = id
						instituteGroupAPI.addGroup(instituteId, this.groupInfo).then(response => {
							this.$emit('refresh', response.data.id)
							this.showToastAddGroup()
							this.hide()
							this.$emit('changeOrderGroup')
						})
						break
					case 'EDIT':
						instituteGroupAPI.editGroup(id, this.groupInfo).then(() => {
							this.$emit('refresh')
							this.showToastEditGroup()
							this.hide()
						})
						break
				}
			}
		},
		showModalLocation(index) {
			this.$refs['modal-select-location'].show('LOCATION')
			this.locIdx = index
		},
		showToastAddGroup() {
			this.$root.toast(this.tt('그룹 추가 완료'), this.tt('새로운 그룹이 추가되었습니다'), 'success')
		},
		showToastEditGroup() {
			const title = '그룹 편집 완료'
			const content = '그룹의 정보가 편집되었습니다'
			const type = 'success'
			this.$root.toast(title, content, type)
		},
		addLocation() {
			this.locationList.push({
				id: '',
				parentId: '',
				name: '',
			})
		},
		removeLocationList(idx) {
			this.locationList.splice(idx, 1)
			this.locationIdList.splice(idx, 1)
		},
		selectLocation(index) {
			this.locIdx = index
			switch (this.modalMode) {
				case 'ADD':
					this.$refs['modal-select-treeview'].show()
					break
				case 'EDIT':
					this.$refs['modal-select-treeview'].show('LOCATION', this.locationList[this.locIdx])
			}
		},
		addStorage(list) {
			this.locationList = []
			this.locationIdList = []
			this.$refs['modal-select-treeview'].hide()
			list.forEach(x => {
				this.locationList.push({
					locationId: x.id,
					locationName: x.pathName,
					name: x.name,
					parentId: x.parentId,
				})
				this.locationIdList.push(x.id)
			})
		},
		hideModalLocation(selected) {
			this.$refs['modal-select-location'].hide()
			this.locationList[this.locIdx].id = selected.id
			this.locationList[this.locIdx].name = selected.name
		},
		deleteLocation(index) {
			this.locationList.splice(index, 1)
		},
	},
}
</script>

<style lang="scss">
@import '@/styles/scss/institution/_modal-add-group.scss';
.AddOrganizationGroupPopup {
	.Popup__input-field {
		margin-top: 20px;
	}
}
input.cursor {
	cursor: pointer !important;
}
</style>
