<template>
	<div
		class="card disposalItem"
		:class="[
			{ warning: list.emptyVolume > 0 || list.overdueExpire > 0 },
			{ safe: list.emptyVolume == 0 && list.overdueExpire == 0 },
			{ displayOff: !displayCard },
		]"
	>
		<div class="card-title">
			<div class="imgbox">
				<img src="@/assets/svg/dashboard-icon1.svg" alt="icon" />
			</div>
			<h4 class="Btn__hover-balloon-w" @click="goPage()">
				{{ tt('폐기대상 화학물질') }}
				<div class="balloon">
					<p>{{ tt('폐기해야할 물품 목록으로 이동합니다') }}</p>
				</div>
				<button>
					<img src="@/assets/svg/arrow-diagonal.svg" alt="icon" />
				</button>
			</h4>
			<h3
				class="ml-auto"
				v-if="setting == false"
				v-text="list.emptyVolume > 0 || list.overdueExpire > 0 ? tt('${1}개 폐기 필요', list.emptyVolume + list.overdueExpire) : tt('안전')"
			></h3>
			<div class="ml-auto switch-setting" v-else>
				<h5>{{ tt('대시보드에 노출') }}</h5>
				<label class="switch">
					<input type="checkbox" v-model="displayCard" @change="toggleCard(id, displayCard)" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="card-noti">
			<h6>{{ tt('폐기 현황') }}</h6>
			<p
				v-if="list.emptyVolume > 0 || list.overdueExpire"
				v-html="tt('보유 물품 중, <span style=color:#ff3b31;>유효기간이 만료되었거나 잔여 용량이 없는 물품</span>을 보여줍니다.')"
			></p>
			<p v-else v-html="tt('보유 물품 중, <span style=color:#0dc577;>유효기간이 만료되었거나 잔여 용량이 없는 물품</span>이 없습니다.')"></p>
		</div>
		<div class="card-box">
			<div class="box-title">
				<h5>{{ tt('폐기해야 할 물품(화학물질)') }}</h5>
			</div>
			<div class="box-content">
				<div class="chart-box">
					<GChart
						type="PieChart"
						:events="chartEvents"
						:data="chartData"
						:options="chartOptions"
						ref="chart"
						class="disposalChart"
						@ready="onChartReady"
					/>
					<div class="chart-title" v-if="totalCount > 0">
						<h2 style="color: #ff3b31">{{ disposalPercent }}%</h2>
					</div>
					<div class="no-data_chart" v-else>
						<div class="balloon">
							<span></span>
							<p>{{ tt('폐기 대상이 아닌 물품') }}</p>
							<b>0개 0%</b>
						</div>
					</div>
				</div>
				<div class="chart-list">
					<ul>
						<li v-for="(item, index) in listKeys" :key="`item_${index}`" @click="goPage(item)">
							<div class="circle" :class="item"></div>
							<p>{{ tt(matchDisposalTitle(item)) }}</p>
							<h5>{{ list[item] }}{{ tt('개') }}</h5>
						</li>
						<li class="total">
							<p>Total:</p>
							<h5>{{ totalCount }}{{ tt('개') }}</h5>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<!-- Pattern 영역 -->
		<div>
			<svg style="width: 0; height: 0; position: absolute" aria-hidden="true" focusable="false" ref="svg">
				<pattern id="strike-fill" x="0" y="0" width="4" height="4" patternUnits="userSpaceOnUse" patternTransform="rotate(30)">
					<rect x="0" y="0" width="2" height="4" style="stroke: none; fill: #ff3b31" />
				</pattern>
			</svg>
			<svg style="width: 0; height: 0; position: absolute" aria-hidden="true" focusable="false" ref="svg">
				<pattern id="dot-fill" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse" patternTransform="rotate(30)">
					<rect width="10" height="10" x="0" y="0" fill="#ff3b31"></rect>
					<circle cx="2" cy="2" r="2" fill="white" />
				</pattern>
			</svg>
		</div>
	</div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import PageName from '@/utils/define/PageName'

export default {
	name: 'CardDisposalItem',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		instId: {
			type: String,
			default: '',
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Object,
			default: () => {},
		},
		toggleCard: {
			type: Function,
			default: () => {},
		},
	},
	mounted() {
		this.displayCard = this.visible
	},
	components: {
		GChart,
	},
	watch: {},
	computed: {
		listKeys() {
			return Object.keys(this.list).reverse()
		},
		totalCount() {
			let values = Object.values(this.list)

			if (values.length > 0) {
				return values.reduce((stack, el) => {
					return stack + el
				}, 0)
			} else {
				return 0
			}
		},
		disposalPercent() {
			return Math.round(
				((this.list.emptyVolume + this.list.overdueExpire) / (this.list.available + this.list.emptyVolume + this.list.overdueExpire)) * 100
			)
		},
		chartData() {
			return [
				['title', 'count', { type: 'string', role: 'tooltip', p: { html: true } }],
				[
					this.tt('폐기 대상이 아닌 물품'),
					this.list.available,
					this.tt('폐기 대상이 아닌 물품') +
						':' +
						'\n' +
						this.list.available +
						this.tt('개') +
						'(' +
						Math.round((this.list.available / (this.list.emptyVolume + this.list.overdueExpire + this.list.available)) * 100) +
						'%)',
				],
				[
					this.tt('유효기간 만료'),
					this.list.overdueExpire,
					this.tt('유효기간 만료') +
						':' +
						'\n' +
						this.list.overdueExpire +
						this.tt('개') +
						'(' +
						Math.round((this.list.overdueExpire / (this.list.emptyVolume + this.list.overdueExpire + this.list.available)) * 100) +
						'%)',
				],
				[
					this.tt('잔여 용량 없음'),
					this.list.emptyVolume,
					this.tt('잔여 용량 없음') +
						':' +
						'\n' +
						this.list.emptyVolume +
						this.tt('개') +
						'(' +
						Math.round((this.list.emptyVolume / (this.list.emptyVolume + this.list.overdueExpire + this.list.available)) * 100) +
						'%)',
				],
			]
		},
		chartOptions() {
			return {
				legend: 'none',
				colors: ['#FFF1F0', '#ff3b31', '#FF5747'],
				width: 210,
				height: 210,
				chartArea: {
					width: '90%',
					height: '90%',
				},
				backgroundColor: 'transparent',
				pieSliceBorderColor: 'white',
				pieSliceText: 'none',
				pieHole: 0.7,
				pieResidueSliceColor: 'red',
				tooltip: {
					isHtml: true,
				},
			}
		},
	},
	data() {
		return {
			displayCard: true,
			chartEvents: {
				select: () => {
					const chart = this.$refs.chart.chartObject
					const row = chart.getSelection()[0].row

					this.goPage(row)
				},
			},
		}
	},
	methods: {
		async goPage(select) {
			let expireDate
			let currentVolume

			if (select > -1 || select) {
				if (select == 1 || select == 'overdueExpire') {
					expireDate = ['<0']
					currentVolume = []
				} else if (select == 2 || select == 'emptyVolume') {
					expireDate = []
					currentVolume = ['<=0']
				} else {
					expireDate = ['>30', '<=30', 'notset']
					currentVolume = ['>0']
				}
			} else {
				expireDate = ['<0']
				currentVolume = ['<=0']
			}

			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					classification: 'CAT_CHEMICAL',
					expireDate: expireDate,
					currentVolume: currentVolume,
				},
			})
		},
		matchDisposalTitle(key) {
			if (key == 'available') {
				return '폐기 대상이 아닌 물품'
			} else if (key == 'emptyVolume') {
				return '잔여 용량 없음'
			} else if (key == 'overdueExpire') {
				return '유효기간 만료'
			}
		},
		onChartReady(chart, google) {
			const observer = new MutationObserver(() => {
				if (this.$refs['svg']) {
					this.$refs['svg'].setAttribute('xmlns', 'http://www.w3.org/2000/svg')
					Array.prototype.forEach.call(this.$refs['chart'].$el.getElementsByTagName('path'), rect => {
						if (rect.getAttribute('fill') === '#ff3b31') {
							rect.setAttribute('fill', 'url(#strike-fill) #ff3b31')
						}
						if (rect.getAttribute('fill') === '#ff5747') {
							rect.setAttribute('fill', 'url(#dot-fill) #ff3b31')
						}
					})
				}
			})
			observer.observe(this.$refs['chart'].$el, {
				childList: true,
				subtree: true,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';

.disposalItem {
	.card-title {
		h4 {
			&.Btn__hover-balloon-w {
				&:hover {
					.balloon {
						top: 36px !important;
						left: -10px !important;
						right: unset !important;
						&:before {
							left: 10px !important;
							right: unset !important;
						}
						&:after {
							left: 11px !important;
							right: unset !important;
						}
						p {
							white-space: pre;
						}
					}
				}
			}
		}
	}
	ul {
		li {
			&:not(.total):hover {
				p {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}
}

.no-data_chart {
	width: 200px;
	height: 200px;
	border: 30px solid #fff1f0;
	border-radius: 200px;
	position: absolute;
	top: 0;
	left: 0;
	&:hover {
		.balloon {
			display: inline-flex;
		}
	}
	.balloon {
		display: none;
		position: absolute;
		top: 15px;
		left: 50%;
		transform: translate(-50%, 0);
		background-color: $LAB_BLACK;
		height: 22px;
		align-items: center;
		padding: 0 8px;
		border-radius: 5px;
		&:after {
			border-bottom: 0px solid transparent;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-top: 4px solid $LAB_BLACK;
			content: '';
			position: absolute;
			bottom: -4px;
			left: 50%;
			transform: translate(-50%, 0);
		}
		span {
			width: 12px;
			height: 12px;
			border-radius: 5px;
			background-color: #fff1f0;
			display: inline-block;
			margin-right: 5px;
		}
		p {
			color: $LAB_WHITE;
			font-size: 0.75rem;
			font-weight: 400;
			white-space: nowrap;
		}
		b {
			color: $LAB_WHITE;
			font-size: 0.75rem;
			font-weight: 700;
			display: inline-block;
			margin-left: 5px;
			white-space: nowrap;
		}
	}
}

.circle {
	&.available {
		background-color: #fff1f0;
	}
	&.emptyVolume {
		content: url(~@/assets/svg/strike-red.svg);
	}
	&.overdueExpire {
		content: url(~@/assets/svg/dot-red.svg);
	}
}

.box-content {
	display: flex;
	align-items: center;
	margin-top: 20px;
	.chart-box {
		position: relative;
		cursor: pointer;
		.chart-title {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.chart-list {
		width: calc(100% - 278px);
		margin-left: 48px;
		margin-right: 20px;
		ul {
			li {
				width: 100% !important;
				margin: 0 0 !important;
				&.total {
					border-bottom: 0 !important;
					p {
						font-weight: 700 !important;
						max-width: 80%;
					}
				}
			}
		}
	}
}
</style>
