var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "detail-wrap"
  }, [_c('detail-header', {
    attrs: {
      "item": _vm.item
    }
  }), _c(_vm.activeBadge, {
    tag: "component",
    attrs: {
      "item": _vm.item,
      "legals": _vm.legals
    }
  }), _c('div', {
    staticClass: "register-info-wrap"
  }, [_c('div', {
    staticClass: "info-title"
  }, [_vm._v(_vm._s(_vm.tt('물품정보')))]), _vm.isCategoryChemical ? _c('properties-info', {
    attrs: {
      "item": _vm.item
    }
  }) : _vm._e(), _vm.hasMsds || !!_vm.item.ghs ? _c('safety-info', {
    attrs: {
      "msds": _vm.item.msds,
      "ghs": _vm.item.ghs
    },
    on: {
      "uploadFile": function uploadFile($event) {
        return _vm.getDetail(_vm.$route.params.id);
      },
      "changeFile": function changeFile($event) {
        return _vm.getDetail(_vm.$route.params.id);
      }
    }
  }) : _vm._e(), _c('member-info', {
    attrs: {
      "item": _vm.item
    }
  }), _c('product-info', {
    attrs: {
      "item": _vm.item,
      "inventoryFieldsInfo": _vm.inventoryFields
    }
  }), _c('management-info', {
    attrs: {
      "item": _vm.item,
      "managementFields": _vm.managementFields
    }
  }), _vm.hasCustomFields ? _c('custom-info', {
    attrs: {
      "item": _vm.item,
      "customFields": _vm.customFields,
      "customFieldsData": _vm.customFieldsData
    }
  }) : _vm._e(), _c('ingredient-info', {
    attrs: {
      "ingredient": _vm.item && _vm.item.ingredientList
    }
  }), !_vm.isBasic ? _c('legal-info', {
    attrs: {
      "legals": _vm.legals,
      "ingredientList": _vm.item.ingredientList,
      "casno": _vm.item.casno
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "manage-info-wrap"
  }, [_c('div', {
    staticClass: "info-title"
  }, [_vm._v(" " + _vm._s(_vm.tt('물품관리 기록')) + " ")]), _c('div', {
    staticClass: "filter-wrap"
  }, [_c('div', {
    staticClass: "filter-wrap-header"
  }, [_c('div', {
    staticClass: "bold-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('상세검색')) + " ")]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.tt('물품관리 기록에서 조회할 항목을 검색하거나 선택할 수 있습니다')) + " ")])]), _c('history-info', {
    attrs: {
      "item": _vm.item
    }
  })], 1)])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }