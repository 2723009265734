<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade no-close-on-esc>
		<h1 v-html="tt(`Merck 등 인기 브랜드 11종, <b style=color:#FF3B31>최대 20% 할인</b>`)"></h1>
		<p v-html="tt(`랩매니저 Pro 라이선스(요금제) 결제 후, 아래 브랜드 물품을 할인된 가격으로 만나보실 수 있습니다.`)"></p>
		<table class="brand-table">
			<tr>
				<th>{{ tt('브랜드') }}</th>
				<th>{{ tt('할인율') }}</th>
			</tr>
			<tr v-for="item in list" :key="item.id">
				<td>{{ tt(item.name) }}</td>
				<td class="sale"><img src="@/assets/svg/icon-low-price.svg" />{{ item.maxDiscountPercent }}%</td>
			</tr>
		</table>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<button v-if="isFreeTier || isTrial" class="button-primary" @click="purchaseLicense">
					{{ tt('라이선스 구매하기') }}
				</button>
				<button class="button-white" @click="hide">
					{{ tt('닫기') }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import MixinLicense from '@/mixins/license'
import { mapActions } from 'vuex'
export default {
	mixins: [MixinLicense],
	data() {
		return {
			list: [],
		}
	},
	methods: {
		...mapActions('lms', ['getPromotionBrandList']),
		async show() {
			this.list = (await this.getPromotionBrandList())?.map(it => {
				if (it.name.includes('MERCK')) it.name = 'Merck'
				return it
			})
			console.log(this.list)
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		purchaseLicense() {
			if (this.isLicenseDepositWait) {
				this.$root.toast('라이선스 구매', '입금 대기 중인 구매 건이 있습니다.', 'error')
			} else {
				this.$root.purchaseLicense()
			}
			this.hide()
			this.$emit('hide')
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
::v-deep {
	.modal-dialog {
		max-width: 520px;
		width: 520px;
	}
	.modal-content {
		border-radius: 20px;
	}
	.modal-body {
		overflow: hidden;
		align-items: center;
		padding: 0px 50px;
		padding-bottom: 20px;
		h1 {
			font-size: 22px;
			font-weight: 700;
		}
		p {
			font-size: 16px;
			color: $DEFAULT;
			text-align: center;
			& > b {
				color: $RED;
			}
		}
		.brand-table {
			width: 100%;
			font-size: 14px;
			font-weight: 500;
			text-align: center;
			tr {
				height: 30px;
				border-top: 1px solid $DIVIDER;
				&:last-child {
					border-bottom: 1px solid $DIVIDER;
				}
			}
			th {
				background-color: $GREY_2;
			}
			td,
			th {
				width: 50%;
				&:first-child {
					border-right: 1px solid $DIVIDER;
				}
			}
			.sale {
				color: $RED;
				img {
					margin-top: -2px;
					margin-right: 3px;
				}
			}
		}
	}
	.modal-footer-wrap {
		flex-direction: column;
		gap: 10px;
		padding-bottom: 30px;
		button {
			max-width: 260px;
			width: 260px;
			font-size: 16px !important;
			height: 48px !important;
		}
	}
}
</style>
