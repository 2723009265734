import groupAPI from '@/services/api/institute/group'
import _ from 'lodash'
import { getListByKeyword, getListBySelected } from '@/utils/Tree'

export default {
	namespaced: true,
	state: {
		groups: [],
		keyword: '',
		selectedMembers: [],
		list: [],
	},
	mutations: {
		initSelectedMembers: state => {
			state.selectedMembers = []
		},
		setGroups: (state, data) => {
			state.groups = data
		},
		setSelectMember: (state, member) => {
			state.selectedMembers.push(member)
		},
		setSelectedMembers: (state, members) => {
			state.selectedMembers = members
		},
		deleteMember: (state, index) => {
			state.selectedMembers.splice(index, 1)
		},
		setKeyword: (state, keyword) => {
			state.keyword = keyword
			if (state.keyword.length == 0 && state.selectedMembers.length > 0) {
				state.list = getListBySelected(
					state.groups,
					state.selectedMembers.map(g => g.id)
				)
			} else {
				state.list = getListByKeyword(state.groups, state.keyword)
			}
		},
		setTreeOpen: (state, isOpen) => {
			state.list = getListByKeyword(state.groups, state.keyword, isOpen)
		},
	},
	actions: {
		getGroups: async ({ commit, rootGetters }, payload) => {
			const response = await groupAPI.lookupGroupTree(rootGetters.instituteId, payload)
			commit('setGroups', response.data)
			commit('setKeyword', '')
			return response.data
		},
	},
}
