function base64toFile(base_data, filename) {
	//refs : https://bcdragonfly.tistory.com/74
	var arr = base_data.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}

	return new File([u8arr], filename, { type: mime })
}

export { base64toFile }
