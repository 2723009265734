var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "button-wrapper btn",
    "class": [{
      active: _vm.isActive,
      disabled: _vm.disabled
    }, _vm.size, _vm.color],
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }