<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('보기 옵션') }}</div>
		</template>
		<slot name="modal-content"> </slot>
		<template #modal-footer="{ hide }">
			<div class="modal-footer-wrap">
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="$emit('confirm')">
						{{ tt('저장') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
</style>
