<template>
	<div class="info-wrap" v-if="hasMainLegals || hasingredientLegals">
		<template v-if="hasMainLegals">
			<p class="info-header">
				{{ tt('법령정보') }}
				<img class="guide" src="@/views/inventoryGen2/svg/Guide.svg" @click="$refs['ModalLegalRefer'].show()" />
			</p>
			<div class="casno">CAS No: {{ casno }}</div>
			<div class="field-wrap">
				<legal-info-field :legal="legals[key]" v-for="key in legalKey" :key="key"></legal-info-field>
			</div>
		</template>
		<div class="divider"></div>
		<div class="ingredient-legal" :style="{ '--ingredientHeight': ingredientHeight }" v-if="hasingredientLegals">
			<p class="info-header">
				{{ tt('구성성분 법령정보') }}
			</p>
			<p class="info-subheader">아래에 표시된 법령정보는 구성 성분에 입력된 용량과 상관없이 Cas no 고유의 법령 정보를 보여주고 있습니다.</p>
			<template v-for="casno in Object.keys(ingredientLegals)" v-if="hasList(ingredientLegals[casno])">
				<div class="casno">CAS No: {{ casno }}</div>
				<div class="field-wrap">
					<legal-info-field :legal="legal" v-for="legal in ingredientLegals[casno]" :key="legal.legalId"></legal-info-field>
				</div>
			</template>
			<div class="gradient-area" v-if="ingredientHeight == '440px'">
				<button class="btn-more" @click="ingredientHeight = 'unset'">{{ tt('더보기') }}</button>
			</div>
		</div>
		<ModalLegalRefer ref="ModalLegalRefer" />
	</div>
</template>
<script>
import ModalLegalRefer from '@/views/common/components/modalGen2/ModalLegalRefer.vue'
import LegalInfoField from './LegalInfoField.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
	name: 'LegalInfo',
	components: { LegalInfoField, ModalLegalRefer },
	props: {
		legals: {
			type: Object,
			default: null,
		},
		ingredientList: {
			type: Array,
			default: () => [],
		},
		casno: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			ingredientHeight: '440px',
		}
	},
	computed: {
		...mapState('chemical', ['ingredientLegals']),
		hasMainLegals() {
			if (this.legals) {
				let totalLegalList = []
				Object.keys(this.legals).forEach(key => {
					totalLegalList.push(this.legals[key].list)
				})
				totalLegalList = totalLegalList.flat()
				return totalLegalList.length > 0
			}
			return false
		},
		hasingredientLegals() {
			if (this.ingredientLegals) {
				let totalLegalList = []
				Object.keys(this.ingredientLegals).forEach(key => {
					totalLegalList.push(this.ingredientLegals[key].map(it => it.list).flat())
				})
				totalLegalList = totalLegalList.flat()
				return totalLegalList.length > 0
			}
			return false
		},
		legalKey() {
			if (this.legals) {
				return Object.keys(this.legals)
			} else {
				return null
			}
		},
	},
	watch: {},
	created() {},
	async mounted() {
		if (this.ingredientList?.length > 0) {
			await this.getLegalByCasno(this.ingredientList.map(it => it.casno))
		} else {
			this.initIngredientCasno()
		}
	},
	methods: {
		...mapMutations('chemical', ['initIngredientCasno']),
		...mapActions('chemical', ['getLegalByCasno']),
		hasList(legals) {
			let totalLegalList = []
			totalLegalList.push(legals.map(it => it.list).flat())
			totalLegalList = totalLegalList.flat()
			return totalLegalList.length > 0
		},
	},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	padding: 25px 0px;
	border-bottom: 1px solid $DIVIDER;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.info-subheader {
		font-size: 14px;
		font-weight: 400;
		color: $SECONDARY_1;
	}
	.casno {
		font-size: 12px;
		font-weight: 500;
		color: white;
		padding: 2px 6px;
		background-color: $SECONDARY_1;
		border-radius: 4px;
	}
	.field-wrap {
		display: flex;
		flex-wrap: wrap;
	}
	.guide {
		cursor: pointer;
	}
	.divider {
		width: 100%;
		height: 1px;
		border-bottom: 1px solid $DIVIDER;
	}
	.ingredient-legal {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		overflow: hidden;
		max-height: var(--ingredientHeight);
		.casno {
			margin-top: 20px;
		}
		.gradient-area {
			position: absolute;
			width: 100%;
			height: 100px;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 57%);
			top: 340px;
			.btn-more {
				margin: 0 auto;
				margin-top: 30px;
				width: 100px;
				height: 36px;
				font-size: 14px;
				font-weight: 700;
				background-color: $GREY_4;
				border-radius: 5px;
				justify-content: center;
			}
		}
	}
}
</style>
