export default {
	computed: {
		today() {
			return new Date().toISOString()
		},
		oneMonthBeforeDate() {
			return (() => {
				const date = new Date()
				date.setMonth(date.getMonth() - 1)
				return date.toISOString()
			})()
		},
	},
	watch: {
		// filter list 확인 후 isActiveFilter 처리해주기
	},
	created() {
		// this.createdInit()
	},
	data() {
		return {
			dateButtonSubtext: null,
			calendarDatetime: [],
		}
	},
	methods: {
		async createdInit() {
			// 저장될 정보 쿠키 체크
			const savedDatetime = this.$cookies.get(this.cookieDatetimeFilterKey)
			if (savedDatetime) {
				this.changeCalendar(JSON.parse(savedDatetime))
			} else {
				this.changeCalendar([null, null])
			}
		},
		async changeCalendar(date) {
			console.log(2)
			this.isActiveCalendar = true
			let startDate = null
			let endDate = null
			if (date[0] != null) {
				startDate = this.formatDate(this.$DateTime.fromJSDate(new Date(date[0])).toISO())
				endDate = this.formatDate(this.$DateTime.fromJSDate(new Date(date[1])).toISO())
				this.dateButtonSubtext = `${startDate} ~ ${endDate}`
			} else {
				startDate = null
				endDate = null
				this.dateButtonSubtext = null
			}
			this.calendarDatetime = [...date]
			await this.setLoadParams({
				startDatetime: startDate,
				endDatetime: endDate,
			})
			this.$cookies.set(this.cookieDatetimeFilterKey, JSON.stringify(this.calendarDatetime))
		},
	},
}
