<template>
	<div class="AppNotice" :class="isNoticeOn ? 'active' : ''">
		<button @click="showNoticeList" class="inner">
			<img :src="newCount > 0 ? require('@/assets/svg/icon-notice-on.svg') : require('@/assets/svg/icon-notice-off.svg')" alt="icon" />
			<div>{{ tt('알림(${1})', newCount) }}</div>
		</button>
		<div class="notice-list" v-if="isNoticeOn == true">
			<div class="title flex">
				<p>{{ tt('알림(${1})', '+' + newCount) }}</p>
				<div class="ml-auto">
					<button class="more" @click="showOption = !showOption" @blur="showOption = false">
						<img src="@/assets/svg/More.svg" />
						<ul class="option-list" v-if="showOption">
							<li @click="noticeAllRead" v-if="noticeList.length > 0">
								<img src="@/assets/svg/member-invite-authority-check.svg" />
								<span>{{ tt('모두 읽은 상태로 표시') }}</span>
							</li>
							<li @click="noticeDelete()" v-if="noticeList.length > 0">
								<img src="@/assets/svg/icon-disposal.svg" />
								<span>{{ tt('알림 모두 없애기') }}</span>
							</li>
							<li @click="showSetting">
								<img src="@/assets/svg/authority-setting.svg" />
								<span>{{ tt('알림 설정') }}</span>
							</li>
						</ul>
					</button>
					<button class="Popup__close-btn close" @click="close">
						<img src="@/assets/svg/close.svg" alt="close" />
					</button>
				</div>
			</div>
			<ul class="list">
				<li v-for="item in noticeList" :key="item.id" :class="item.isNew == true ? 'new' : ''">
					<div @click="confirmNotice(item)">
						<h6>
							{{ item.title }}
							<img v-if="item.isNew" src="@/assets/svg/notice-new.svg" alt="icon" />
						</h6>
						<p>{{ item.content }}</p>
						<span>{{ formatDatetime(item.createDatetime) }}</span>
					</div>
					<button class="ml-auto">
						<input type="radio" v-model="detailOption" :value="item.id" @click="hideDetailOption(item.id)" />
						<img src="@/assets/svg/More.svg" />
						<ul class="option-list" v-if="detailOption == item.id">
							<li @click="noticeRead(item)" v-if="item.isNew">
								<img src="@/assets/svg/member-invite-authority-check.svg" />
								<span>{{ tt('읽은 상태로 표시') }}</span>
							</li>
							<li @click="noticeDelete(item)">
								<img src="@/assets/svg/icon-disposal.svg" />
								<span>{{ tt('알림 없애기') }}</span>
							</li>
						</ul>
					</button>
				</li>
			</ul>
			<div class="no-data-list" v-if="noticeList.length == 0">
				<img src="@/assets/svg/no-data_list.svg" />
				<h6>{{ tt('알림이 없습니다') }}</h6>
				<p>{{ tt('새로운 알림이 오면 목록에 추가됩니다') }}</p>
			</div>
		</div>

		<!--modals-->
		<modal-account-setting ref="modal-account-setting"></modal-account-setting>
	</div>
</template>

<script>
import userAPI from '@/services/api/institute/user'
import PageName from '@/utils/define/PageName'
import inventoryAPI from '@/services/api/inventory'
import ModalAccountSetting from '@/views/app/modals/ModalAccountSetting.vue'
import RoutePath from '@/utils/define/RoutePath'
import { Classification, FindLargeClass } from '@/utils/define/ItemCode'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	components: {
		ModalAccountSetting,
	},
	created() {
		this.loadNotice()
	},
	watch: {
		$route: {
			deep: true,
			handler(to, from) {
				this.loadNotice()
				if (this.isNoticeOn == true) {
					this.close()
				}
			},
		},
	},
	computed: {},
	data() {
		return {
			newCount: 5,
			isNoticeOn: false,
			noticeList: [],
			offset: 0,
			length: 10000,
			showOption: false,
			detailOption: null,
		}
	},
	methods: {
		...mapMutations('inventory', ['setInventory']),
		async loadNotice() {
			const response = await userAPI.lookupNotice(this.offset, this.length, this.instituteId)
			this.noticeList = response.data.list
			this.newCount = response.data.newCount
		},
		showNoticeList() {
			if (this.isNoticeOn == false) {
				this.isNoticeOn = true
				this.$root.isNoticeOn = true
			} else {
				this.isNoticeOn = false
				this.$root.isNoticeOn = false
			}
		},
		hideDetailOption(id) {
			if (this.detailOption != null && this.detailOption == id) {
				this.detailOption = null
			}
		},
		close() {
			this.isNoticeOn = false
			this.$root.isNoticeOn = false
		},
		formatDatetime(date) {
			if (date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear(),
					hour = d.getHours(),
					min = d.getMinutes()

				if (month.length < 2) {
					month = '0' + month
				}
				if (day.length < 2) {
					day = '0' + day
				}
				if (min < 10) {
					min = '0' + min
				}

				return [year, month, day].join('-') + '\n' + [hour, min].join(':')
			} else {
				return '-'
			}
		},
		async showSetting() {
			await this.$refs['modal-account-setting'].show(1)
			this.close()
			this.showOption = false
		},
		async noticeRead(item) {
			await userAPI.readNotice({ ids: [item.id] })
			this.detailOption = null
		},
		async noticeAllRead() {
			await userAPI.readAllNotice()
			await this.loadNotice()
			this.showOption = false
		},
		async noticeDelete(item) {
			const ids = []
			if (item) {
				ids.push(item.id)
			} else {
				this.noticeList.forEach(x => {
					ids.push(x.id)
				})
			}
			await userAPI.deleteNotice(ids)
			await this.loadNotice()
			this.detailOption = false
		},
		async confirmNotice(item) {
			await userAPI.readNotice({ ids: [item.id] })
			await this.goPage(item)
			await this.loadNotice()
			this.close()
		},
		async goPage(item) {
			if (item.type == 'INSPECT_FAIL') {
				if (this.$route.name == PageName.Inventory.Wait) {
					window.location.reload()
				} else {
					this.$router.push({
						name: PageName.Inventory.Wait,
					})
				}
			} else if (item.type == 'INVENTORY_DISPOSE' || item.type == 'INVENTORY_DELETE') {
				if (item.inventoryId) {
					this.$router.push({
						name: PageName.Inventory.DisposalDetail,
						params: {
							id: item.inventoryId,
						},
					})
				} else {
					if (this.$route.name == PageName.Inventory.Disposal) {
						window.location.reload()
					} else {
						this.$router.push({
							name: PageName.Inventory.Disposal,
						})
					}
				}
			} else if (item.type == 'DRAFT_APPROVE' || item.type == 'DRAFT_REJECT') {
				this.$router.push({
					name: PageName.Purchase.DraftDetail,
					params: {
						id: item.draftId,
					},
				})
			} else if (item.type == 'DRAFT_ARRIVAL' || item.type == 'DRAFT_CREATED') {
				this.$router.push({
					name: PageName.Purchase.ApprovalDetail,
					params: {
						id: item.draftId,
					},
				})
			} else if (item.type == 'ORDER_APPROVE') {
				this.$router.push({
					name: PageName.Purchase.Product.PayDetail,
					params: {
						id: item.draftPurchaseItemId,
					},
				})
			} else if (item.type == 'SAFETY_REPORT') {
				this.$router.push({
					name: PageName.Dashboard,
				})
			} else if (item.type === 'LICENSE_EXPIRED' || item.type === 'LICENSE_BEFORE_EXPIRE') {
				this.$router.push({
					name: PageName.Institution.Information,
				})
			} else {
				if (item.inventoryId) {
					if (this.$route.name == PageName.InventoryGen2.Detail) {
						this.$router.push({
							name: PageName.InventoryGen2.Detail,
							params: {
								id: item.inventoryId,
							},
						})
						setTimeout(() => {
							window.location.reload()
						}, 100)
					} else {
						console.log(parseInt(item.inventoryId))
						this.$router.push({
							name: PageName.InventoryGen2.Detail,
							params: {
								id: parseInt(item.inventoryId),
							},
						})
					}
				} else {
					if (this.$route.name == PageName.InventoryGen2.List) {
						window.location.reload()
					} else {
						this.$router.push({
							name: PageName.InventoryGen2.List,
							params: {
								classification: FindLargeClass(item.categoryType) ?? 'ALL',
							},
						})
					}
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.AppNotice {
	padding: 0 10px;
	height: 34px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	transition: 0.3s ease;
	cursor: pointer;
	position: relative;
	&:hover {
		background-color: $GREY_4;
	}
	&.active {
		background-color: $GREY_4;
	}
	> .inner {
		display: inline-flex;
		height: 34px;
		align-items: center;
		padding: 0;
		img {
			width: 24px;
			height: 24px;
			margin-right: 5px;
		}
		div {
			font-size: 0.875rem;
			color: #000;
			font-weight: 700;
		}
	}
	.notice-list {
		width: 400px;
		height: 684px;
		position: absolute;
		border-radius: 8px;
		border: 1px solid #ccc;
		text-align: left;
		filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.1));
		background-color: $LAB_WHITE;
		top: 44px;
		right: 0;
		overflow: hidden;
		.ml-auto {
			display: flex;
			align-items: center;
			height: auto;
		}
		button {
			&.more {
				width: 30px;
				height: 30px;
				border-radius: 4px;
				position: relative;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				&:focus {
					background-color: $GREY_4;
					// .option-list {
					//     display: block;
					// }
				}
			}
			.option-list {
				width: 314px;
				position: absolute;
				top: 32px;
				right: 0;
				border: 1px solid #ccc;
				filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.1));
				border-radius: 5px;
				padding: 5px;
				height: unset;
				z-index: 1;
				li {
					width: 100%;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: left;
					margin: 0;
					background-color: $LAB_WHITE;
					&:hover {
						background-color: $GREY_4;
					}
					span {
						padding: 0;
						margin-left: 8px;
						color: #000;
						font-size: 0.875rem;
					}
				}
			}
		}
		.title {
			width: 100%;
			height: 64px;
			display: flex;
			text-align: center;
			border-bottom: 1px solid #ccc;
			box-sizing: border-box;
			padding: 18px;
			p {
				font-size: 1.125rem;
				font-weight: 700;
			}
		}
		ul {
			height: calc(684px - 104px);
			overflow-y: auto;
			padding: 20px;
			background-color: $LAB_WHITE;
			&.list {
				> li {
					display: flex;
					align-items: flex-start;
					div {
						width: calc(100% - 30px);
					}
					button {
						margin-top: -4px;
						input[type='radio'] {
							position: absolute;
							width: 30px;
							height: 30px;
							opacity: 0;
							cursor: pointer;
						}
					}
				}
			}
			li {
				width: 100%;
				background-color: $GREY_1;
				border-radius: 8px;
				transition: 0.3s;
				padding: 15px;
				box-sizing: border-box;
				margin-bottom: 10px;
				h6 {
					font-size: 0.875rem;
					font-weight: 700;
					color: $SECONDARY_2;
					display: flex;
					align-items: center;
					> img {
						margin-left: 4px;
						width: 12px;
						height: 12px;
					}
				}
				p {
					padding-top: 6px;
					font-weight: 400;
					color: $SECONDARY_2;
					font-size: 0.875rem;
				}
				span {
					display: block;
					color: $PLACEHOLDER;
					font-size: 0.75rem;
					font-weight: 400;
					padding-top: 6px;
				}
				&:last-child {
					margin-bottom: 0;
				}
				&:hover {
					background-color: #f2f2f2;
					h6 {
						color: $SECONDARY_1;
					}
					p {
						color: $SECONDARY_1;
					}
					span {
						color: $SECONDARY_1;
					}
				}
				&.new {
					background-color: $SUB-YELLOW_100;
					h6 {
						color: $SECONDARY_1;
					}
					p {
						color: $SECONDARY_1;
					}
					span {
						color: $SECONDARY_1;
					}
					&:hover {
						background-color: $SUB-YELLOW_200;
					}
				}
			}
		}
	}
}

.no-data-list {
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	img {
		width: 206px;
		margin: 0 auto;
	}
	h6 {
		font-size: 1rem;
		font-weight: 700;
		color: $PLACEHOLDER;
		margin: 24px 0 6px;
	}
	p {
		font-size: 0.875rem;
		font-weight: 400;
		color: $PLACEHOLDER;
		b {
			display: inline-block;
			font-weight: bold;
		}
	}
}
</style>
