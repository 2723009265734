var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "datepicker"
  }, [_c('date-picker', {
    attrs: {
      "valueType": "format",
      "lang": _vm.lang,
      "placeholder": _vm.placeholder
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }