<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper" :class="{ error: error }">
			<input class="form-text-input" type="text" readonly :placeholder="tt(placeholder)" :value="inputValue" @click="showModalTree" />
			<button class="form-button" @click="showModalTree">
				<img src="@/views/common/components/form/svg/chevron-right.svg" width="15" />
			</button>
		</div>
		<modal-select-member ref="modal-select-member" :title="modalTitle" :isSelectSingle="true" @confirm="selectMember"></modal-select-member>
	</div>
</template>

<script>
import ModalSelectMember from '@/views/common/components/modalGen2/ModalSelectMember.vue'
export default {
	name: 'FormMember',
	components: {
		ModalSelectMember,
	},
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [Object, String],
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
		field: {
			type: Object,
			default: false,
		},
	},
	data() {
		return {
			// inputValue: '',
		}
	},
	computed: {
		error() {
			return this.required && this.isShowError && !this.inputValue
		},
		inputValue() {
			return {
				assignee: this.value?.name,
				buyer: this.value?.name,
			}[this.field.key]
		},
		modalTitle() {
			return {
				assignee: '담당자 추가',
				buyer: '구매자 추가',
			}[this.field.key]
		},
	},
	methods: {
		showModalTree() {
			this.$refs['modal-select-member'].show(this.value ? [this.value] : [])
		},
		selectMember(selectData) {
			this.$emit('input', selectData[0])
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-input.scss';
.form-button {
	position: absolute;
	right: 0px;
}
.form-text-input {
	cursor: pointer;
}
</style>
