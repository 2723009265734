import axiosInstance from '@/services/axios'

const endPoint = {
	loadEolState: '/user/migration/status',
}

const eolAPI = {
	loadEolState: status => {
		return axiosInstance.get(endPoint.loadEolState, {
			params: { status: status },
		})
	},
}

export default eolAPI
