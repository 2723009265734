var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppHeader__menu",
    "class": _vm.isMenuOn ? 'active' : '',
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.clickMenu.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticClass: "AppHeader__profile-img",
    attrs: {
      "width": 28,
      "height": 28,
      "src": _vm.user.image ? _vm.user.image : require('@/assets/svg/member-photo-default.svg')
    }
  }), _c('div', {
    staticClass: "AppHeader__menu-name"
  }, [_vm._v(_vm._s(_vm.tt('${1}님', _vm.user.name)))]), _c('app-header-menu-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMenuOn,
      expression: "isMenuOn"
    }],
    attrs: {
      "isMigration": _vm.isMigration
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }