<template>
	<div
		class="filter-btn"
		:class="{
			active: isActive,
		}"
		@click="$emit('click')"
	>
		{{ tt(text) }}
	</div>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		text: {
			type: [String, Number],
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
.filter-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	padding: 5px 10px;
	font-size: 14px;
	font-weight: 400;
	border-radius: 10px;
	background: $GREY_2; // $TABLE_HEADER
	color: $SECONDARY_2; // $SECONDARY_2
	cursor: pointer;
	white-space: nowrap;
	&.active {
		background: $SUB-YELLOW_200;
		color: $FONT_YELLOW2;
		font-weight: 500;
	}
}
</style>
