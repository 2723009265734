var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_vm.isShow ? _c('div', {
    staticClass: "experience-modal-background"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "header-text-wrapper"
  }, [_c('h1', {
    staticClass: "header-text-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('span', {
    staticClass: "header-text-content",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('랩매니저 PRO의 모든 기능을 사용할 수 있습니다!'))
    }
  })]), _c('img', {
    staticClass: "eol-image",
    attrs: {
      "src": require("@/assets/svg/experience/pro-experience-modal.svg")
    }
  }), _c('div', {
    staticClass: "bottom-wrapper"
  }, [_c('lm-confirm-button-middle', {
    attrs: {
      "text": _vm.tt('닫기')
    },
    on: {
      "click": _vm.close
    }
  })], 1)])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }