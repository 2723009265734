import budgetAPI from '@/services/api/budget'

const state = {
	list: [],
	loadParams: {
		groupId: null,
		year: null,
		budgetId: null, // 상세
		budgetsId: [], // 현황
		offset: 0,
		length: 50,
		keyword: '',
		startCreateDatetime: null,
		endCreateDatetime: null,
		startDraftCreateDatetime: null,
		endDraftCreateDatetime: null,
		startDraftConcludeDatetime: null,
		endDraftConcludeDatetime: null,
		draftGroupsId: [],
		draftUsersId: [],
	},
	filter: null,
	count: 0,
}

const getters = {
	groups: state => state.filter?.draftGroups ?? [],
	users: state => state.filter?.draftUsers ?? [],
	budgets: state => state.filter?.budgets ?? [],
}

const mutations = {
	setList: (state, data) => {
		state.list = data.list
		state.count = data.count
	},
	setFilter: (state, data) => {
		state.filter = data
	},
	setOffset: (state, pageNumber) => {
		state.loadParams.offset = (pageNumber - 1) * length
	},
	setLoadParams: (state, loadParams) => {
		Object.keys(loadParams).forEach(key => {
			if (key == 'groupsId') state.loadParams.groupId = loadParams[key][0]
			else state.loadParams[key] = loadParams[key]
		})
	},
	initLoadParams: state => {
		state.loadParams = {
			groupId: null,
			year: null,
			budgetId: null, // 상세
			budgetsId: [], // 현황
			offset: 0,
			length: 50,
			keyword: '',
			startCreateDatetime: null,
			endCreateDatetime: null,
			startDraftCreateDatetime: null,
			endDraftCreateDatetime: null,
			startDraftConcludeDatetime: null,
			endDraftConcludeDatetime: null,
			draftGroupsId: [],
			draftUsersId: [],
		}
	},
	setYear: (state, year) => {
		state.loadParams.year = year
	},
}

const actions = {
	getList: async ({ commit, rootGetters }) => {
		const response = await budgetAPI.expenditureList(rootGetters.instituteId, state.loadParams)
		commit('setList', response.data)
	},
	getFilter: async ({ commit, rootGetters }, params) => {
		const response = await budgetAPI.expenditureFilter(rootGetters.instituteId, params)
		commit('setFilter', response.data)
	},
	setLoadParams: async ({ commit, dispatch }, loadParams) => {
		commit('setLoadParams', loadParams)
		dispatch('getList')
	},
	setFiltered: async ({ dispatch }, filterList) => {
		dispatch('setLoadParams', {
			draftGroupsId: filterList.filter(it => it.type == 'GROUP').map(i => i.id),
			draftUsersId: filterList.filter(it => it.type == 'USER').map(i => i.id),
			budgetsId: filterList.filter(it => it.type == 'BUDGET').map(i => i.id),
		})
	},
	exportExcel: async ({ state, rootGetters }) => {
		const response = await budgetAPI.expenditureExportExcel(rootGetters.instituteId, {
			budgetId: state.loadParams.budgetId,
			year: state.loadParams.year,
			groupId: state.loadParams.groupId,
		})
		return response
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
