<template>
	<div class="information-wrapper">
		<h5>{{ tt('결제 정보') }}</h5>
		<div class="info-list">
			<template v-if="isPaymentMethodEbill">
				<div class="noti-box">
					<p
						v-html="
							tt(
								'${1}까지 입금하지 않으면 라이선스가 <b class=red>비활성화</b>됩니다.',
								tt(`<b class='red'>${formatDepositExpireDate()}</b>`)
							)
						"
					></p>
				</div>

				<div class="noti-box last">
					<p>
						{{
							`${licenseManagement.schedule.virtualAccountBankName} ${licenseManagement.schedule.virtualAccountNumber} (${tt(
								'예금주'
							)}: ${licenseManagement.schedule.virtualAccountHolder})`
						}}
					</p>
					<p>
						{{ `${tt('결제금액')}: ₩${formatNumberLocale(licenseManagement.schedule.totalPrice)}` }}
					</p>
				</div>
			</template>
			<div>
				<p>{{ tt('결제 상태') }}</p>
				<!--전자세금계산서의 경우-->
				<p v-if="isPaymentMethodEbill && isLicenseDepositWait" class="red">{{ tt('입금대기') }}</p>
				<!--이외-->
				<p v-else>{{ tt('결제 완료') }}</p>
			</div>
			<div v-if="isPaymentMethodEbill">
				<p class="name">입금 기한</p>
				<p>
					{{ formatExpireDate(purchaseCompleteInfo.depositExpireDatetime) }}
				</p>
			</div>
			<div>
				<p>
					{{ !isPaymentMethodEbill ? tt('결제일') : tt('주문일') }}
				</p>
				<p>
					{{ formatDatetime(purchaseCompleteInfo.createDatetime, false) }}
				</p>
			</div>
			<div>
				<p>{{ tt('결제수단') }}</p>
				<p>{{ paymentMethod2Text(purchaseCompleteInfo.paymentMethod) }}</p>
			</div>
			<div>
				<p>{{ tt('거래서류') }}</p>
				<!--전자세금계산서의 경우-->
				<div v-if="isPaymentMethodEbill">
					<a download :href="purchaseCompleteInfo.receiptUrl">
						{{ tt('전자세금계산서') }}
					</a>
					<span> / </span>
					<a download :href="purchaseCompleteInfo.virtualAccountConfirmationUrl">
						{{ tt('가상계좌발급서') }}
					</a>
				</div>
				<!--이외-->
				<a download :href="purchaseCompleteInfo.receiptUrl" v-else>{{ tt('영수증') }}</a>
			</div>
			<div>
				<p class="name">결제 금액</p>
				<p class="bold">
					{{ tt('₩${1}', formatNumberLocale(purchaseCompleteInfo.totalPrice)) }}
				</p>
			</div>
		</div>
	</div>
</template>
<script>
import ModalConfirm from '@/views/common/components/modalGen2/ModalConfirm.vue'
import MixinLicense from '@/mixins/license'
export default {
	components: {
		ModalConfirm,
	},
	mixins: [MixinLicense],
	computed: {
		isPaymentMethodEbill() {
			return this.purchaseCompleteInfo.paymentMethod == this.paymentMethod.ELECTRONIC_BILL
			// return true
		},
	},
	methods: {},
}
</script>
<style lang="scss" scoped>
.information-wrapper {
	padding: 20px 0;
	border-top: 1px solid $INPUT;
	display: flex;
	flex-direction: column;
	gap: 15px;
	.warning {
		font-size: 14px;
		color: $RED;
		word-break: keep-all;
	}
	.info-list {
		font-size: 14px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		& > div {
			display: flex;
			justify-content: space-between;
			.name {
				color: $SECONDARY_2;
			}
			.blue {
				color: $BLUE;
			}
			a {
				color: $BLUE;
				text-decoration: underline;
			}

			&.noti-box {
				display: block !important;
				width: 100%;
				border: 1px solid $DIVIDER;
				border-radius: 5px;
				padding: 15px;
				background-color: $GREY_0;
				p {
					font-size: 0.875rem;
					color: #000;
					padding-left: 12px;
					position: relative;
					&:before {
						width: 3px;
						height: 3px;
						background-color: #000;
						content: '';
						border-radius: 3px;
						top: 10px;
						left: 0;
						position: absolute;
					}
					span {
						font-weight: bold;
					}
				}
				&.last {
					margin-bottom: 10px;
				}
			}
		}
	}
	.btn-cancel {
		margin-top: 5px;
		justify-content: center;
		border-radius: 5px;
		height: 48px;
		color: $RED;
		background-color: $SUB-RED;
		width: 100%;
		font-size: 14px;
	}
}
</style>
