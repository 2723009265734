<template>
	<div class="form-input">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper">
			<div class="datepicker">
				<date-picker range v-model="date" :rangeSeparator="rangeSeparator"></date-picker>
			</div>
		</div>
	</div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
export default {
	name: 'FormMultiSelectDatetime',
	components: { DatePicker },
	props: {
		name: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		rangeSeparator: {
			type: String,
			default: ' ~ ',
		},
	},
	data() {
		return {
			date: null,
		}
	},
	computed: {},
	watch: {
		date(value) {
			this.$emit('change', value)
		},
	},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.input-wrapper.error ::v-deep {
	.mx-input-wrapper {
		border: 1px solid $RED;
	}
}

.input-wrapper ::v-deep {
	.datepicker {
		margin-left: 0px;
	}
	.mx-input {
		background: white;
		height: 28px;
		font-weight: 400;
		font-size: 14px;
		&::placeholder {
			color: $PLACEHOLDER;
			font-size: 12px;
		}
	}
	.mx-input-wrapper {
		width: 300px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.date {
		position: relative;
		top: 1px;
		left: -130px;
		z-index: 2;
		font-size: 14px;
		&.valid {
			color: $GREEN;
		}
		&.invalid {
			color: $RED;
		}
	}
}

.form-input {
	position: relative;
	// width: 300px;
	// max-width: 300px;
	width: calc(50% - 9px);
	&.list {
		.input-wrapper {
			input[type='text'],
			input[type='number'] {
				height: 36px;
				border: 1px solid $COLOR_CCC;
				padding-left: 12px;
			}
		}
	}

	.name {
		font-size: 0.75rem;
		font-weight: 400;
		margin-bottom: 8px !important;
	}
	.input-wrapper {
		display: flex;
		align-items: center;
		input::placeholder {
			color: $PLACEHOLDER;
		}
	}
}
</style>
