import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	list: (instId, paramsObject) => `/institute/${instId}/draft/list?${ParamMaker.setParamUsingObject(paramsObject)}`,
	filterList: instId => `/institute/${instId}/draft/filter`,
	detail: (instId, id) => `/institute/${instId}/draft/${id}`,
	delete: (instId, id) => `/institute/${instId}/draft?id=${id}`,
	groupList: () => `/user/groups/all`,
	usableBudgetList: (instId, groupId) => `/institute/${instId}/budget/list/usable?groupId=${groupId}`,
	create: instId => `/institute/${instId}/draft`,
	listExportExcel: instituteId => `/institute/${instituteId}/draft/list/export/excel`,
}

const draftAPI = {
	list: (instId, paramsObject) => axiosInstance.get(endPoint.list(instId, paramsObject)),
	filterList: instId => axiosInstance.get(endPoint.filterList(instId)),
	detail: (instId, id) => axiosInstance.get(endPoint.detail(instId, id)),
	delete: (instId, id) => axiosInstance.delete(endPoint.delete(instId, id)),
	groupList: () => axiosInstance.get(endPoint.groupList()),
	usableBudgetList: (instId, groupId) => axiosInstance.get(endPoint.usableBudgetList(instId, groupId)),
	create: (instId, formData) =>
		axiosInstance.post(endPoint.create(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	listExportExcel: (institueId, payload) =>
		axiosInstance.post(endPoint.listExportExcel(institueId), payload, {
			responseType: 'blob',
		}),
}

export default draftAPI
