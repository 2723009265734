<template>
	<div class="form-input" :class="{ error: (isShowRequire || isShowError) && !value }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="input-wrapper">
			<input class="storage" type="text" readonly :placeholder="tt(placeholder)" :value="fullpath" @click="showModalTree" />
			<button class="storage-button" @click="showModalTree">{{ tt('선택') }}</button>
		</div>
		<span class="error" v-if="(isShowRequire || isShowError) && !value">{{ tt(errorMessage) }}</span>
		<modal-single-select-storage ref="modal-select-storage" title="보관함 선택" @complete="selectStorage"></modal-single-select-storage>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalTreeview from '../modals/ModalTreeview.vue'
import ModalSingleSelectStorage from '@/views/common/components/modalGen2/ModalSingleSelectStorage.vue'

export default {
	name: 'FormStorage',
	components: { ModalTreeview, ModalSingleSelectStorage },
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			default: null,
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		unit: {
			type: String,
			default: '',
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			// storage: null,
			isShowRequire: false,
		}
	},
	computed: {
		fullpath() {
			if (this.value) {
				return this.value?.pathName
			}
			return ''
		},
		cookiesKey() {
			return 'SELECT_STORAGE_KEY'
		},
	},
	created() {},
	mounted() {},
	methods: {
		showModalTree() {
			this.$refs['modal-select-storage'].show(this.value)
		},
		selectStorage(selectData) {
			this.$cookies.set(this.cookiesKey, selectData)
			this.$emit('input', selectData)
		},
		showRequire() {
			this.isShowRequire = this.required && !this.value
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-storage.scss';
</style>
