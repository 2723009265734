<template>
	<div v-if="isLogin" class="migration-header">
		<span class="migration-header-text" v-html="text"></span>
		<div class="migration-right-header">
			<app-header-menu></app-header-menu>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'ReceiveInviteHeader',
	components: {
		AppHeaderMenu: () => import('@/views/app/components/AppHeaderMenu'),
	},
	props: {
		text: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState('user', ['user']),
		isLogin() {
			return this.user && this.user.email
		},
	},
}
</script>

<style lang="scss" scoped>
.migration-header {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	z-index: 100;
	.migration-header-text {
		font-size: 16px;
		font-weight: 700;
		line-height: 23px;
		letter-spacing: 0px;
		text-align: center;
	}
	.migration-right-header {
		position: absolute;
		top: 10px;
		right: 30px;
	}
}
</style>
