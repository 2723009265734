var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Search-box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }, {
      name: "debounce",
      rawName: "v-debounce:450",
      value: _vm.changeInput,
      expression: "changeInput",
      arg: "450"
    }],
    ref: "search-input",
    "class": [{
      searchInput: _vm.isScan
    }, {
      useQr: _vm.useQr && _vm.inputValue.length > 0
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder,
      "readonly": _vm.isScan
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.search.apply(null, arguments);
      },
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showRecent.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputValue = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "resetBtn"
  }, [_vm.isScan ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/Close-2.svg")
    },
    on: {
      "click": _vm.resetCode
    }
  }) : _vm.inputValue.length > 0 ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/Close-1.svg")
    },
    on: {
      "click": _vm.resetSearch
    }
  }) : _vm._e(), _vm.useQr && !_vm.isScan ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/QR Scan.svg")
    },
    on: {
      "click": _vm.showCode
    }
  }) : _vm._e()]), _c('button', {
    staticStyle: {
      "margin-left": "auto"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.search
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/group-search.svg")
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }