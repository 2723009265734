<template>
	<tr class="table-item" @click="goToDetail">
		<td>
			<div style="min-width: 100px">
				<span>{{ item.code ? item.code : '-' }}</span>
			</div>
		</td>
		<td>
			<div style="min-width: 250px; max-width: 300px">
				<div v-b-tooltip.hover :title="item.name">{{ item.name }}</div>
			</div>
		</td>
		<td v-if="isView('JoinedGroupRepName')">
			<div style="min-width: 250px; max-width: 300px">
				<span v-if="item.joinedGroupCount > 1">{{ tt('${1} 외 ${2}개 그룹', item.JoinedGroupRepName, item.joinedGroupCount - 1) }}</span>
				<span v-else>{{ item.JoinedGroupRepName }}</span>
			</div>
		</td>
		<td v-if="isView('budget')" class="right">
			<div>
				<span>{{ tt('${1}원', item.budget.toLocaleString()) }}</span>
			</div>
		</td>
		<td v-if="isView('expenditure')" class="right">
			<div>
				<span>{{ tt('${1}원', item.expenditure.toLocaleString()) }}</span>
			</div>
		</td>
		<td v-if="isView('balance')" class="right">
			<div>
				<span>{{ tt('${1}원', item.balance.toLocaleString()) }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ createDatetime }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState } from 'vuex'
import MixinItem from '@/mixins/inventory/MixinItem'
export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('budget/list', ['checkedViewOption']),
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: 'BudgetDetail',
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
