<template>
	<base-form-item :title="title" :required="required" :direction="direction" :description="description">
		<div class="upload-form-item-wrapper">
			<div class="upload-area" @drop.prevent="dropFile">
				<input ref="file" type="file" @change="uploadFile" multiple />
				<p>{{ tt('첨부할 파일을 마우스로 끌어오거나, 직접 선택해 주세요') }}</p>
				<button class="btn btn-gray sm" @click="$refs['file'].click()">{{ tt('파일 선택') }}</button>
			</div>
			<div v-if="files && files.length > 0" class="file-wrapper">
				<div v-for="(file, index) of files" :key="index" class="file">
					<div class="info">
						<img src="@/assets/svg/empty-doc.svg" />
						<span>{{ file.name }}</span>
					</div>
					<img src="@/assets/svg/close_c.svg" @click="onClickDeleteFile(file)" />
				</div>
			</div>
		</div>
	</base-form-item>
</template>

<script>
import BaseFormItem from '@/components/form-item/BaseFormItem.vue'

export default {
	name: 'UploadFormItem',
	components: {
		BaseFormItem,
	},
	extends: BaseFormItem,
	data() {
		return {
			files: [],
		}
	},
	methods: {
		dropFile(e) {
			this.$refs['file'].files = e.dataTransfer.files
			this.files = [...this.files, ...this.$refs['file'].files]
			this.$refs['file'].value = ''

			this.$emit('upload', this.files)
		},
		uploadFile() {
			this.files = [...this.files, ...this.$refs['file'].files]
			this.$refs['file'].value = ''

			this.$emit('upload', this.files)
		},
		onClickDeleteFile(file) {
			this.files.splice(this.files.indexOf(file), 1)

			this.$emit('delete', this.files)
		},
	},
}
</script>

<style lang="scss" scoped>
.upload-form-item-wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	.upload-area {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 16px;
		height: 156px;
		background: $GREY_1;
		border: 1px dashed #cacbcb;
		border-radius: 4px;
		> p {
			color: $PLACEHOLDER;
		}
		input[type='file'] {
			width: 100%;
			height: inherit;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			cursor: pointer;
		}
	}
	.file-wrapper {
		display: flex;
		flex-direction: column;
		gap: 8px;
		.file {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 12px 8px;
			border: 1px solid $INPUT;
			border-radius: 4px;
			.info {
				display: flex;
				align-items: center;
				gap: 8px;
			}
			> img {
				cursor: pointer;
			}
		}
	}
}
</style>
