<template>
	<div class="lab-selector-wrapper">
		<div class="lab-selector-counter">
			<span class="lab-selector-counter-text" v-html="selectedLabCountText"></span>
		</div>

		<div class="lab-selector-list-wrapper">
			<migration-lab-selector-item
				v-for="item in labs"
				:key="`lab-item-${item.labId}`"
				:item="item"
				@click="selectItem"
				:selectedLabId="selectedLabId"
			></migration-lab-selector-item>
		</div>
	</div>
</template>

<script>
import MigrationLabSelectorItem from '@/views/migration/components/MigrationLabSelectorItem.vue'
export default {
	components: { MigrationLabSelectorItem },
	props: {
		// 연구실 정보 리스트
		labs: {
			type: Array,
			default: () => [],
		},
		// 선택한 연구실 ID들
		selectedLabId: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {}
	},
	computed: {
		selectedLabCount() {
			return this.selectedLabId.length
		},
		selectedLabCountText() {
			if (this.selectedLabCount > 0) {
				return `<b>${this.selectedLabCount}${this.tt('개 연구실')}</b>${this.tt('이 선택되었습니다.')}`
			} else {
				return this.tt('랩매니저 PRO로 이전할 연구실을 모두 선택해주세요.')
			}
		},
	},
	methods: {
		selectItem(selectedLabId) {
			this.$emit('selectedLab', selectedLabId)
			// const isSelectedLab = this.selectedLabId.includes(item.labId)
			// console.warn('thisSelectedLab', this.selectedLabId)
			// if (isSelectedLab) {
			//     console.warn(
			//         this.selectedLabId.filter((labId) => labId !== item.labId)
			//     )
			//     this.selectedLabId = [
			//         ...this.selectedLabId.filter(
			//             (selectedItem) => selectedItem.labId !== item.labId
			//         ),
			//     ]
			// } else {
			//     this.selectedLabId.push(item.labId)
			// }
		},
	},
}
</script>

<style lang="scss" scoped>
.lab-selector-wrapper {
	width: 100%;
	height: 414px;

	.lab-selector-counter {
		display: flex;
		justify-content: center;
		align-items: center;
		background: $SUB-YELLOW_100;
		width: 100%;
		height: 40px;
		.lab-selector-counter-text {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: 0px;
			text-align: center;
		}
	}
	.lab-selector-list-wrapper {
		display: flex;
		flex-direction: column;
		border: 1px solid $INPUT;
		margin: 10px 0px;
		height: 364px;
		width: 100%;
		padding: 0px;
		border-radius: 5px;
		overflow-y: auto;
		overflow-x: hidden;
	}
}
</style>
