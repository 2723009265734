import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCategoryList: instId => `/institute/${instId}/category/list`,
	lookupCategory: (instId, id) => `/institute/${instId}/category?id=${id}`,
	addCategory: instId => `/institute/${instId}/category`,
	editCategory: instId => `/institute/${instId}/category`,
	deleteCategory: (instId, id) => `/institute/${instId}/category?id=${id}`,
	countCategory: (instId, id) => `/institute/${instId}/category/inventory/count?id=${id}`,
	lookupMngcode: instId => `/institute/${instId}/mngcode`,
	editMngcode: instId => `/institute/${instId}/mngcode`,
}

const settingAPI = {
	lookupCategoryList: instId => axiosInstance.get(endPoint.lookupCategoryList(instId)),
	lookupCategory: (instId, id) => axiosInstance.get(endPoint.lookupCategory(instId, id)),
	addCategory: (instId, body) => axiosInstance.post(endPoint.addCategory(instId), body),
	editCategory: (instId, body) => axiosInstance.put(endPoint.editCategory(instId), body),
	deleteCategory: (instId, id) => axiosInstance.delete(endPoint.deleteCategory(instId, id)),
	countCategory: (instId, id) => axiosInstance.get(endPoint.countCategory(instId, id)),
	lookupMngcode: instId => axiosInstance.get(endPoint.lookupMngcode(instId)),
	editMngcode: (instId, body) => axiosInstance.put(endPoint.editMngcode(instId), body),
}

export default settingAPI
