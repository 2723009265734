<template>
	<div class="page-wrapper">
		<fixed-header
			:title="tt('상품결제')"
			:dateButtonSubtext="dateButtonSubtext"
			:calendarDatetime="calendarDatetime"
			@changeDate="changeCalendar"
			@changeLoadParams="setLoadParams"
		></fixed-header>
		<purchase-product-table :list="list"></purchase-product-table>
		<div class="select-item-pricing">
			<div class="select-price">
				<div class="unpayment" v-if="noPaymentSummary">
					<span>{{ tt('미결제 총 금액') }}:</span> {{ tt('${1}원', noPaymentSummary.noPaidPriceWithVat.toLocaleString()) }}
					{{ tt('(${1}건)', noPaymentSummary.count) }}
				</div>
				<div class="select-pay">
					<span>{{ tt('선택금액') }}:</span><b>{{ tt('${1}원', priceSum.toLocaleString()) }} {{ tt('(${1}건)', selectItem.length) }}</b>
				</div>
			</div>
			<button
				class="btn-payment"
				:class="{ 'not-select': selectItem.length <= 0 }"
				@click="gotoStorePayment"
				v-b-tooltip.hover="tooltip ? tooltip : ''"
			>
				{{ tt('결제하기') }}
			</button>
		</div>
		<div class="pagination-wrapper">
			<pagination @page="getCurrentPage" :total="count"></pagination>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MixinExpenditureList from '@/mixins/purchase/expenditureList'
import FixedHeader from '../components/pay/FixedHeader.vue'
import PurchaseProductTable from '../components/pay/table/index.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import PageName from '@/utils/define/PageName'
export default {
	mixins: [MixinExpenditureList],
	components: {
		FixedHeader,
		PurchaseProductTable,
		Pagination,
	},
	computed: {
		...mapState('purchaseProduct/list', ['list', 'count', 'selectItem', 'noPaymentSummary']),
		priceSum() {
			return this.list
				.filter(it => this.selectItem.includes(it.id))
				.map(it => it.orderItemPriceWithVat)
				.reduce((a, b) => a + b, 0)
		},
		tooltip() {
			if (this.selectItem.length <= 0) return this.tt('결제할 항목을 선택해주세요')
			return null
		},
	},
	data() {
		return {
			previousPage: null,
			cookieDatetimeFilterKey: 'purchaseProductDatetimeFilter',
		}
	},
	created() {
		if (this.previousPage !== PageName.Purchase.Product.PayDetail) {
			this.initSelectItem()
			this.initFilterCookies(true)
		}
	},
	methods: {
		...mapActions('purchaseProduct/list', ['setLoadParams']),
		...mapMutations('purchaseProduct/list', ['initSelectItem', 'initFilterCookies']),
		gotoStorePayment() {
			if (this.selectItem.length > 0) {
				const list = this.list.filter(it => this.selectItem.includes(it.id)).map(it => it.orderItemId)
				this.$root.goToStore(`mypage/unpayment/pay`, { orderItemIds: JSON.stringify(list) })
			}
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	display: flex;
	flex-direction: column;
}
.select-item-pricing {
	border-top: 1px solid $DIVIDER;
	background-color: white;
	z-index: 10;
	bottom: 0px;
	display: flex;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100px;
	padding: 20px 30px;
	.select-price {
		font-size: 20px;
		color: $DEFAULT;
		span {
			margin-right: 10px;
		}
		.unpayment {
			text-align: left;
			font-size: 12px;
			color: $SECONDARY_2;
		}
		.select-pay {
			text-align: left;
			font-size: 20px;
		}
	}
	.btn-payment {
		justify-content: center;
		margin-left: auto;
		width: 200px;
		height: 100%;
		background-color: $LAB_YELLOW;
		border-radius: 10px;
		color: $DEFAULT;
		font-size: 20px;
		font-weight: 700;
		&.not-select {
			background-color: $GREY_2;
			color: $PLACEHOLDER;
		}
	}
}
</style>
