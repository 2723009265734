var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "AddAuthorityAdminPopup"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.hide
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('search-box', {
    attrs: {
      "placeholder": _vm.tt(_vm.searchBoxPlaceholder)
    },
    on: {
      "inputValue": _vm.setKeyword,
      "search": function search($event) {
        return _vm.setGroupMemberList(true);
      }
    }
  }), _c('div', {
    staticClass: "Organization__member-list"
  }, [!_vm.isSearched && !_vm.hasMember ? _c('div', {
    staticClass: "StorageBox__group-no-data no-data-box"
  }, [_c('img', {
    staticClass: "no-member-img",
    attrs: {
      "src": require("@/assets/svg/member-photo-default.svg"),
      "alt": "no-image"
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('기관 내 멤버가 없습니다.') + '<br>' + _vm.tt('멤버를 추가해주세요'))
    }
  }), _c('br'), _c('button', {
    staticClass: "Btn__management",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.goToMemberManagement
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('멤버 관리')) + " ")])]) : _vm.isSearched && !_vm.hasMember ? _c('div', {
    staticClass: "StorageBox__group-no-data no-data-box"
  }, [_c('img', {
    staticClass: "no-member-img",
    attrs: {
      "src": require("@/assets/svg/member-photo-default.svg"),
      "alt": "no-image"
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('검색 결과가 없습니다'))
    }
  }), _c('br')]) : _c('ul', {
    ref: "list",
    staticClass: "horizational-list",
    on: {
      "&scroll": function scroll($event) {
        return _vm.scroll.apply(null, arguments);
      }
    }
  }, _vm._l(_vm.members, function (member, idx) {
    var _domProps;

    return _c('li', {
      key: "".concat(member.id, "-").concat(idx),
      staticClass: "Organization__member-item safe",
      on: {
        "click": function click($event) {
          return _vm.clickMember(member.id);
        }
      }
    }, [!member.image ? _c('img', {
      attrs: {
        "src": require("@/assets/svg/member-detail-photo.svg"),
        "alt": "group-image"
      }
    }) : _c('img', {
      attrs: {
        "src": member.image
      }
    }), _c('div', {
      staticClass: "Organization__group-item-info",
      staticStyle: {
        "width": "calc(100% - 90px)"
      }
    }, [_c('h6', {
      domProps: {
        "innerHTML": _vm._s(_vm.highlightName(member.name))
      }
    }), _c('p', [_vm._v(_vm._s(_vm.showGroupNames(member.groups)))])]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedMemberId,
        expression: "selectedMemberId"
      }],
      attrs: {
        "id": member.id,
        "type": "radio",
        "name": "selectMember"
      },
      domProps: (_domProps = {
        "value": member.id,
        "checked": member.id === _vm.selectedMemberId
      }, _domProps["checked"] = _vm._q(_vm.selectedMemberId, member.id), _domProps),
      on: {
        "change": function change($event) {
          _vm.selectedMemberId = member.id;
        }
      }
    }), _c('label', {
      staticClass: "radio",
      attrs: {
        "for": member.id
      }
    })]);
  }), 0)])], 1), _c('div', {
    staticClass: "Popup__bottom"
  }, [!_vm.isStart ? _c('button', {
    staticClass: "Btn__management",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.goToMemberManagement
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('멤버 관리')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }