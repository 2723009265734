import { getAccountUrl } from '@/services/api/common'

const instituteRoot = 'institute'
const inventoryRoot = 'inventory'
const categoryRoot = 'category'
const safetyRoot = 'safety'
const purchaseRoot = 'purchase'
const RoutePath = {
	sso: `${getAccountUrl}/SignIn?redirect=${window.location.origin}&pro=true`,
	// home: '/',
	// redirectToken: '',
	Main: '/:instituteId/main',
	dashboard: `/:instituteId/dashboard`,
	create: `/createInstitution`,
	institute: {
		information: `/:instituteId/${instituteRoot}/institutionInformation`,
		organization: `/:instituteId/${instituteRoot}/organization`,
		organizationDetail: `/:instituteId/${instituteRoot}/organization/storage/:storageId`,
		location: `/:instituteId/${instituteRoot}/location`,
		storageDetail: `/:instituteId/${instituteRoot}/location/storage/:storageId`,
		memberManagement: `/:instituteId/${instituteRoot}/memberManagement`,
		authoritySetting: `/:instituteId/${instituteRoot}/authoritySetting`,
		authorityDetail: `/:instituteId/${instituteRoot}/authoritySetting/:id`,
	},
	// inventory: {
	// 	whole: `/:instituteId/${inventoryRoot}/${categoryRoot}/whole`,
	// 	category: `/:instituteId/${inventoryRoot}/${categoryRoot}/:categoryType`,
	// 	detail: `/:instituteId/${inventoryRoot}/${categoryRoot}/:categoryType/:id`,
	// 	wait: `/:instituteId/${inventoryRoot}/waitingRegistration`,
	// 	waitDetail: `/:instituteId/${inventoryRoot}/waitingRegistration/:id`,
	// 	chemical: `/:instituteId/${inventoryRoot}/${categoryRoot}/chemical`,
	// 	chromatography: `/:instituteId/${inventoryRoot}/${categoryRoot}/chromatography`,
	// 	labware: `/:instituteId/${inventoryRoot}/${categoryRoot}/labware`,
	// 	protection: `/:instituteId/${inventoryRoot}/${categoryRoot}/protection`,
	// 	etc: `/:instituteId/${inventoryRoot}/${categoryRoot}/etc`,
	// 	disposal: `/:instituteId/${inventoryRoot}/disposal`,
	// 	disposalDetail: `/:instituteId/${inventoryRoot}/disposal/:id`,
	// 	history: `/:instituteId/${inventoryRoot}/history`,
	// 	historyItem: `/:instituteId/${inventoryRoot}/history?itemId=:id`,
	// },
	InventoryGen2: {
		List: `/:instituteId/inventory/list`,
		Detail: `/:instituteId/inventory/list/detail/:id`,
		WaitList: `/:instituteId/inventory/waitingList`,
		WaitDetail: `/:instituteId/inventory/waitingList/detail/:id`,
		HistoryList: `/:instituteId/inventory/historyList`,
		DisposalList: `/:instituteId/inventory/disposalList`,
		DisposalDetail: `/:instituteId/inventory/disposalList/detail/:id`,
	},
	safety: {
		msds: `/:instituteId/${safetyRoot}/msds`,
		ghs: `/:instituteId/${safetyRoot}/ghsLabel`,
		dangerous: `/:instituteId/${safetyRoot}/dangerousStorage`,
		dangerousDetail: `/:instituteId/${safetyRoot}/dangerousStorage/detail/:legalName`,
		dangerousInfo: `/:instituteId/${safetyRoot}/dangerousStorage/:id`,
		management: `/:instituteId/${safetyRoot}/safetyManagementMaterials`,
		managementDetail: `/:instituteId/${safetyRoot}/safetyManagementMaterials/:casno/:name`,
		managementInfo: `/:instituteId/${safetyRoot}/safetyManagementMaterials/:id`,
		chemical: `/:instituteId/${safetyRoot}/safetyChemicals`,
		chemicalDetail: `/:instituteId/${safetyRoot}/safetyChemicals/:casno/:name/:productCode*`,
		chemicalInfo: `/:instituteId/${safetyRoot}/safetyChemicals/:id`,
		hazardous: `/:instituteId/${safetyRoot}/hazardousChemicalMaterial`,
		health: `/:instituteId/${safetyRoot}/healthCheck`,
		detail: `/:instituteId/${safetyRoot}/healthCheck/:id`,
		law: `/:instituteId/${safetyRoot}/healthCheck/:id`,
		legal: `/:instituteId/${safetyRoot}/legalProduct`,
		legalDetail: `/:instituteId/${safetyRoot}/legalProduct/list/:legalName`,
	},
	Migration: {
		Main: `/migration/main`,
		SelectLab: `/migration/selectLab`,
		Being: `/migration/being`,
		NoAdmin: `/migration/noAdmin`,
		Select: `/migration/select`,
		InputInstitutionName: `/migration/inputInstitutionName`,
		MemberList: '/migration/memberlist',
	},
	invite: {
		accept: `/acceptInvite`,
		expire: `/acceptInvite/:code/licenseExpire`,
	},
	Budget: {
		Status: '/:instituteId/budget/status',
		List: '/:instituteId/budget/list',
		Create: '/:instituteId/budget/list/create',
		Edit: '/:instituteId/budget/list/edit/:id',
		Detail: '/:instituteId/budget/list/:id',
	},
	Purchase: {
		DraftList: `/:instituteId/${purchaseRoot}/draft`,
		DraftDetail: `/:instituteId/${purchaseRoot}/draft/:id`,
		DraftRegistration: `/:instituteId/${purchaseRoot}/draft/regist/:mode`,
		ApprovalList: `/:instituteId/${purchaseRoot}/approval`,
		ApprovalDetail: `/:instituteId/${purchaseRoot}/approval/:id`,
		DeadlineList: `/:instituteId/${purchaseRoot}/deadline`,
		DeadlineDetail: `/:instituteId/${purchaseRoot}/deadline/:id`,
		CargoList: `/:instituteId/${purchaseRoot}/cargo`,
		CargoDetail: `/:instituteId/${purchaseRoot}/cargo/:cargoId`,
		Product: {
			Order: `/:instituteId/product/order`,
			Pay: `/:instituteId/product/pay`,
			PayDetail: `/:instituteId/product/pay/:id`,
			OrderComplete: `/:instituteId/product/order/complete/:id`,
		},
	},
}

export default RoutePath
