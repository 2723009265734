<template>
	<div class="MemberInvite">
		<member-filter></member-filter>
		<div class="Member__table Table__wrap">
			<table>
				<thead>
					<tr>
						<th>
							<div><input type="checkbox" /></div>
						</th>
						<th>멤버 정보</th>
						<th>소속(직책)</th>
						<th>권한</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div><input type="checkbox" checked /></div>
						</td>
						<td>
							<div class="info safe">
								<img src="@/assets/svg/member-photo-default.svg" />
								<div>
									<h6>Darlene Robertson<span>(가입대기)</span></h6>
									<p>nevaeh.simmons@example.com</p>
								</div>
							</div>
						</td>
						<td>스마트잭(대표)</td>
						<td>프리셋1</td>
						<td>
							<button class="btn">초대취소</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="Menu__floating">
			<h4><span class="count">2명</span>선택</h4>
			<div class="btn-group">
				<button>
					<img src="@/assets/svg/setting.svg" alt="버튼icon" />
					<span>권한변경</span>
				</button>
				<button>
					<img src="@/assets/svg/close.svg" alt="버튼icon" />
					<span>초대취소</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MemberInvite',
	components: {},
}
</script>

<style lang="scss" scoped>

@import '@/styles/scss/institution/_member.scss';
</style>
