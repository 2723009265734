import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'

const routes = [
	{
		name: PageName.Purchase.ApprovalList,
		path: RoutePath.Purchase.ApprovalList,
		component: () => import('@/views/purchase/approval/pages/ApprovalList.vue'),
	},
	{
		name: PageName.Purchase.ApprovalDetail,
		path: RoutePath.Purchase.ApprovalDetail,
		component: () => import('@/views/purchase/approval/pages/ApprovalDetail.vue'),
	},
]

export default routes
