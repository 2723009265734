var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('추가정보')))]), _c('div', {
    staticClass: "field-wrap"
  }, _vm._l(_vm.customFields, function (fieldInfo) {
    return _c('custom-info-field', {
      key: "".concat(fieldInfo.key),
      attrs: {
        "item": _vm.item,
        "fieldInfo": fieldInfo,
        "isAcvite": fieldInfo.isActive,
        "isRequire": fieldInfo.isRequire,
        "fieldText": _vm.formatFieldText(fieldInfo),
        "isData": _vm.isData(fieldInfo.key),
        "fieldName": _vm.formatFieldName(fieldInfo)
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }