<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade no-close-on-esc>
		<h1>{{ tt('라이선스 구매하고 할인받기') }}</h1>
		<p
			v-html="
				tt(`<b style=color: '#FF3B31'>최대 20% 할인</b>을 위해 라이선스 구매가 필요합니다.<br />(라이선스 구매 시, 할인구매 금액 무제한)`)
			"
		></p>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<button class="button-primary" @click="purchaseLicense">
					{{ tt('라이선스 구매하고 할인받기') }}
				</button>
				<button class="button-white" @click="purchaseWithoutSale">
					{{ tt('할인없이 주문하기') }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import MixinLicense from '@/mixins/license'

export default {
	mixins: [MixinLicense],
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		purchaseWithoutSale() {
			this.$emit('purchaseWithoutSale')
			this.hide()
		},
		purchaseLicense() {
			if (this.isLicenseDepositWait) {
				this.$root.toast('라이선스 구매', '입금 대기 중인 구매 건이 있습니다.', 'error')
			} else {
				this.$root.purchaseLicense(() => {
					this.$emit('purchaseConfirm')
				}, '상품 주문 계속하기')
			}
			this.hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
::v-deep {
	.modal-dialog {
		max-width: 580px;
		width: 580px;
	}
	.modal-content {
		border-radius: 20px;
	}
	.modal-body {
		overflow: hidden;
		align-items: center;
		padding: 0px 50px;
		padding-bottom: 20px;
		h1 {
			font-size: 32px;
			font-weight: 700;
		}
		p {
			font-size: 16px;
			color: $DEFAULT;
			text-align: center;
			& > b {
				color: $RED;
			}
		}
	}
	.modal-footer-wrap {
		flex-direction: column;
		gap: 10px;
		padding-bottom: 30px;
		button {
			max-width: 260px;
			width: 260px;
			font-size: 16px !important;
			height: 48px !important;
		}
	}
}
</style>
