<template>
	<div class="select-table-wrapper">
		<div class="table-wrapper">
			<table class="select-table">
				<tr class="select-table-header">
					<th width="633px">
						<span>{{ tt('상품 정보') }}</span>
					</th>
					<th width="150px">
						<span>{{ tt('수량') }}</span>
					</th>
					<th width="135px">
						<span>{{ tt('판매가') }}</span>
					</th>
					<th width="130px">
						<span>{{ tt('담은사람') }}</span>
					</th>
				</tr>
				<table-item v-for="(item, index) in list" :key="`product-${index}`" :item="item" :index="index" :allChecked="allChecked"></table-item>
			</table>
		</div>
		<div class="no-data" v-if="list.length === 0">
			<img :src="require('@/views/common/components/modalGen2/svg/cart.svg')" />
			<p>{{ tt('장바구니에 상품이 없습니다') }}</p>
		</div>
		<product-info-price-table
			:total="totalPrice"
			:deliveryFee="totalDeliveryFee"
			:sale="totalSalePrice"
			:vatPrice="vatPrice"
		></product-info-price-table>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ProductInfoPriceTable from './ProductInfoPriceTable.vue'
import TableItem from './item.vue'

export default {
	components: {
		ProductInfoPriceTable,
		TableItem,
	},
	props: {
		list: {
			type: Array,
			default: () => {},
		},
		allChecked: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('draft/form', ['selectedCartItems']),
		totalPrice() {
			let price = 0
			this.selectedCartItems?.forEach(product => {
				price += product.unitPrice * product.quantity
			})
			return price
		},
		totalDeliveryFee() {
			let price = 0
			// this.list?.forEach(product => {
			// 	price += product.deliveryFee * product.count
			// })
			return price
		},
		totalSalePrice() {
			let price = 0
			this.selectedCartItems?.forEach(product => {
				if (product.discountPrice) price += product.discountPrice
			})
			return price
		},
		vatPrice() {
			return (this.totalPrice - this.totalSalePrice) / 10
		},
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.select-table-wrapper {
	display: flex;
	flex-direction: column;
	border: 1px solid $INPUT;
	border-radius: 4px;
	width: 100%;
	.table-wrapper {
		max-height: 400px;
		overflow: auto;
	}
	table {
		border-collapse: collapse;
		.select-table-header {
			background: $GREY_2;
			th:first-child {
				padding-left: 20px;
				text-align: left;
			}
			th {
				padding-top: 5px;
				padding-bottom: 5px;
				color: $SECONDARY_2;
				text-align: center;
			}
		}
	}
	.no-data {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 400px;
		color: $SECONDARY_2;
		gap: 5px;
		img {
			width: 192px;
			height: 192px;
			margin-bottom: 10px;
		}
		p {
			font-size: 14px;
		}
		span {
			font-weight: 400;
			font-size: 14px;
		}
	}
}
</style>
