<template>
	<div v-if="item">
		<div>
			<h5>{{ tt('요약정보') }}</h5>
			<budget-summary :summary="item.summary" />
		</div>
		<br />
		<div>
			<h5>
				<div>
					<div>{{ tt('예산 현황') }}</div>
					<div class="sub">
						<b>{{ tt('상세검색') }}</b
						>{{ tt('예산현황표에서 조회할 항목을 검색하거나 선택할 수 있습니다') }}
					</div>
				</div>
				<div class="button-groups">
					<image-button @click="exportStatus" :text="'목록 다운로드'" :image="require('@/assets/svg/excel-download.svg')"></image-button>
				</div>
			</h5>
			<budget-search
				ref="budget-search"
				placeholder="그룹 또는 예산으로 검색"
				@change="list => $refs['group-budget-search'].setCheckedList(list)"
				@changeKeyword="keyword => $refs['group-budget-search'].setKeyword(keyword)"
				:groups="groups"
				:budgets="budgets"
				:year="selectedYear"
				isMonth
			/>
			<budget-table ref="group-budget-search" :originalBudgets="item.budgetStatusData" :year="selectedYear" isMonth />
		</div>
	</div>
</template>
<script>
import BudgetSummary from './Summary.vue'
import BudgetSearch from './BudgetSearch.vue'
import BudgetTable from './BudgetTable.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
	components: {
		BudgetTable,
		BudgetSummary,
		BudgetSearch,
	},
	props: {
		group: {
			type: Object,
		},
	},
	computed: {
		...mapGetters('budget/status', ['selectedYear', 'item']),
		groups() {
			let list = []
			this.item.budgetStatusData.forEach(d => {
				list.push(...d.groups.filter(g => !list.find(l => l.id == g.id)))
			})
			return list
		},
		budgets() {
			return this.item.budgetStatusData.map(d => {
				return {
					id: d.budgetId ?? d.budgetCode,
					name: d.budgetName,
				}
			})
		},
	},
	async created() {
		await this.setLoadParams({})
	},
	methods: {
		...mapActions('budget/status', ['exportExcel', 'setLoadParams']),
		async exportStatus() {
			const response = await this.exportExcel()
			window.fileDownload(
				response,
				`${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('${1}년 ${2} 예산 현황', this.selectedYear, this.group.name)}.xlsx`
			)
		},
		async loadData() {
			await this.$nextTick()
			this.$refs['budget-search'].reset()
			await this.setLoadParams({})
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
</style>
