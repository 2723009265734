<template>
	<div class="page-wrapper">
		<fixed-header :title="tt('폐기/삭제된 물품')" :previousPage="previousPage" />
		<div class="table-wrapper">
			<disposal-table :list="list"></disposal-table>
			<floating-menu v-if="selectedItems.length > 0"></floating-menu>
		</div>
		<pagination ref="pagination" :total="count" @page="pagination"></pagination>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import Pagination from '@/views/common/components/Pagination.vue'
import FixedHeader from '../components/disposal/FixedHeader.vue'
import FloatingMenu from '../components/disposal/FloatingMenu.vue'
import DisposalTable from '../components/disposalTable'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
	components: {
		Pagination,
		FixedHeader,
		FloatingMenu,
		DisposalTable,
	},
	computed: {
		...mapState('inventoryGen2/disposal', ['list', 'count', 'selectedItems', 'recentPageName']),
	},
	created() {},
	data() {
		return {
			previousPage: null,
		}
	},
	mounted() {
		if (
			this.previousPage?.name !== null &&
			(this.$route.query.status?.length ?? 0) == 0 &&
			(this.$route.name !== this.recentPageName || this.previousPage?.name !== PageName.InventoryGen2.DisposalDetail)
		) {
			this.$refs['pagination'].init(1)
		}
		this.setPageName(this.$route.name)
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
	methods: {
		...mapMutations('inventoryGen2/disposal', ['setSelectedItems', 'initFilterCookies', 'setPageName']),
		...mapActions('inventoryGen2/disposal', ['setLoadParams']),
		async pagination(page) {
			await this.setLoadParams(page)
		},
	},
}
</script>

<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 165px);
	}
}
</style>
