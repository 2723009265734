var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-input-button unselectable",
    "class": {
      empty: _vm.number == ''
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.addNumber.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.number))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }