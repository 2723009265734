<template>
	<div class="treeview-wrap">
		<div class="search-wrap">
			<search-box noRecent :placeholder="tt('그룹명 또는 이름으로 검색')" :value="keyword" @change="setKeyword"></search-box>
		</div>
		<div class="folding-wrapper">
			<div @click="setTreeOpen(true)"><img src="@/assets/svg/icon-unfold.svg" />{{ tt('전체 펼치기') }}</div>
			<span class="br"></span>
			<div @click="setTreeOpen(false)"><img src="@/assets/svg/icon-fold.svg" />{{ tt('전체 접기') }}</div>
		</div>
		<div class="content">
			<div class="tree-wrap">
				<member-node v-for="node in list" :key="node.id" :node="node" :type="'root'"></member-node>
			</div>
		</div>
		<div class="footer">
			<span v-html="footerText"></span>
		</div>
	</div>
</template>

<script>
import SearchBox from '@/views/components/searchBox'
import MemberNode from '@/views/common/components/tree/gen2/components/MemberNode.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import _ from 'lodash'

export default {
	name: 'ApprovalLineTreeview',
	components: {
		SearchBox,
		MemberNode,
	},
	props: {},
	computed: {
		...mapState('institute/member', ['selectedMembers', 'keyword', 'list']),
		footerText() {
			let text = `<p>멤버를&nbsp</p><b>더블클릭</b><p>하여 결재선에&nbsp</p> <b>추가</b><p>할 수 있습니다.</p>`
			if (this.selectedMembers.length === 4) text = `<b>결재선은 최대 4명까지 추가 가능합니다</b>`
			return this.tt(text)
		},
	},
	watch: {},
	async created() {
		await this.getGroups({
			name: '',
			duplication: false,
		})
	},
	mounted() {},
	methods: {
		...mapMutations('institute/member', ['setKeyword', 'setTreeOpen']),
		...mapActions('institute/member', ['getGroups']),
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-single-select-storage.scss';
.treeview-wrap {
	border: 1px solid $INPUT;
	border-radius: 5px;
	width: 380px;
	height: 100%;
	.search-wrap {
		width: 100%;
		height: 50px;
		background: #fbfaf9;
		padding: 10px;
		border-bottom: 1px solid $INPUT;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.folding-wrapper {
		padding: 10px;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 14px;
		color: $DEFAULT;
		border-bottom: 1px solid $INPUT;
		div {
			&:hover {
				background-color: $GREY_2;
			}
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 6px;
		}
		.br {
			display: block;
			width: 1px;
			height: 20px;
			background-color: $DIVIDER;
		}
	}
	.content {
		height: 450px;
		overflow-y: auto;
		overflow-x: hidden;
		.tree-wrap {
			margin-left: -15px;
			padding-right: 10px;
		}
	}

	.footer {
		background: #fbfaf9;
		border-top: 1px solid $INPUT;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		span {
			padding: 6px;
			display: flex;
			justify-content: center;
			font-size: 12px;
		}
	}
}
</style>
