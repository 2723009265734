<template>
	<div class="form-wrap">
		<div class="form-header" v-if="isAutoMsds">
			<div class="form-header-text">
				<div class="form-title">{{ tt('MSDS') }}</div>
				<div class="form-msg success">{{ tt(`${formMessage}`) }}</div>
			</div>
			<div class="form-header-text">
				<div class="form-header-subtext">{{ tt('아래 MSDS를 자동 제공하고 있습니다.') }}</div>
			</div>
			<div v-if="msdsInfo">
				<cargo-form-msds-item
					v-for="msdsKey in validMsdsKeyList"
					:msds="msdsInfo[msdsKey]"
					:msdsKey="msdsKey"
					:key="msdsKey"
				></cargo-form-msds-item>
			</div>
		</div>

		<div class="form-header">
			<div class="form-header-text">
				<div class="form-title">{{ tt('MSDS 직접 업로드') }}</div>
				<div v-if="!isAutoMsds" class="form-msg" :class="{ success: this.files && this.files.length > 0 }">{{ tt(`${formMessage}`) }}</div>
			</div>
		</div>
		<div class="type-file">
			<button class="button-gray" @click="uploadFile">{{ tt('파일선택') }}</button>

			<div class="error-text" v-if="showInvalid">{{ tt('MSDS 업로드는 필수입니다') }}.</div>
			<div class="upload-box" :class="{ error: showInvalid }" ref="upload-box">
				<input class="dropFile" type="file" ref="drop-file" @change="onChange" @drop="dropFile" accept=".pdf" />
				<div class="file-box" v-if="!files || files.length == 0">
					<img src="@/assets/svg/icon/icon-msds-file.svg" />
					<p class="file-box-text">{{ tt('MSDS 파일을 마우스로 끌어오거나, 직접 선택해 주세요') }}</p>
					<div class="btn-wrap">
						<button class="button-gray" ref="select-btn" @click="uploadFile">{{ tt('파일선택') }}</button>
					</div>
				</div>
				<ul class="file-list" v-else>
					<li v-for="(file, index) in files" :key="`key${index}`">
						<img src="@/assets/svg/icon/icon-docs.svg" />
						<p>{{ file.name }}</p>
						<button class="close-btn" @click="deleteFile">
							<img src="@/assets/svg/Close-1.svg" />
						</button>
					</li>
				</ul>
			</div>
		</div>
		<modal-confirm
			ref="modal-confirm"
			:title="tt('MSDS 직접 업로드')"
			:content="tt('MSDS는 하나의 파일만 업로드 할 수 있습니다.')"
			@confirm="hideModalConfirm"
			:noCancel="false"
		></modal-confirm>
		<slot></slot>
	</div>
</template>
<script>
import { ToastType } from '@/utils/define/ToastType'
import CargoFormMsdsItem from '@/views/purchase/cargo/components/cargoList/form/CargoFormMsdsItem.vue'
import { mapGetters } from 'vuex'
export default {
	name: 'FormMsds',
	components: { CargoFormMsdsItem },
	props: {
		msdsInfo: {
			type: [Object, null],
			default: null,
		},
		value: {
			type: [File],
			default: null,
		},
	},
	data() {
		return {
			files: null,
			showInvalid: false,
		}
	},
	computed: {
		...mapGetters('inventoryGen2/form', ['uploadMsds']),
		formMessage() {
			return this.isAutoMsds || this.files?.length > 0 ? `MSDS 등록완료` : `연동된 MSDS 없음`
		},
		isAutoMsds() {
			if (this.msdsInfo) {
				const { master, institute, kosha } = this.msdsInfo
				return master || institute || kosha
			} else {
				return null
			}
		},
		validMsdsKeyList() {
			return (
				this.msdsInfo &&
				Object.keys(this.msdsInfo).filter(key => {
					if (this.uploadMsds) {
						return key !== 'institute' && this.msdsInfo[key]
					} else {
						return this.msdsInfo[key]
					}
				})
			)
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.$nextTick(() => {
			if (this.value) {
				this.files = [this.value]
			}
		})
	},
	methods: {
		dropFile(event) {
			event.preventDefault()
			if (this.files?.length === 1) {
				this.$refs['modal-confirm'].show()
				return
			}
			if (event.dataTransfer.files) {
				this.$refs['drop-file'].files = event.dataTransfer.files
				this.onChange()
			}
		},
		uploadFile() {
			this.$refs['drop-file'].click()
		},
		onChange() {
			let fileValues = Object.values(this.$refs['drop-file'].files)
			const file = fileValues[0]
			if (file) {
				let isPdf = new RegExp('application/[a-z]*(pdf)').test(file.type)
				if (!isPdf) {
					this.files = this.files?.length > 0 ? this.files : []
					let title = 'MSDS 업로드 실패'
					let content = 'PDF 형식의 파일만 업로드 가능합니다.'
					this.$root.toast(title, content, ToastType.ERROR)
					return
				} else {
					this.files = [file]
				}
				this.changeItem(this.files)
			}
		},
		deleteFile() {
			this.files = []
			this.changeItem(this.files)
			if (this.$refs?.dropFile) {
				this.$refs['dropFile'].value = null
			}
			this.$emit('delete')
		},
		changeItem(value) {
			this.$emit('change', value)
		},
		hideModalConfirm() {
			this.$refs['modal-confirm'].hide()
		},
		setData(value) {
			this.files = [value]
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/form/_form-msds.scss';
</style>
