<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalChangeName Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('이름 변경') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field" :class="!isValidName ? 'warning' : ''">
						<p>{{ tt('이름') }}<span class="required">*</span></p>
						<input type="text" :placeholder="tt('이름을 입력해주세요')" v-model="name" @input="isValidName = true" />
						<span>{{ tt('이름을 입력해주세요') }}</span>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="submit">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalChangeName',
	mixins: [MixinModal],
	props: {},
	data() {
		return {
			name: null,
			isValidName: true,
		}
	},
	methods: {
		show(name) {
			this.isShow = true
			this.name = name
			this.isValidName = true
		},
		close() {
			this.hide()
		},
		validName() {
			if (this.name.length == 0 || this.name == null) {
				this.isValidName = false
			}
		},
		async submit() {
			await this.validName()

			if (this.isValidName) {
				this.$emit('submit', this.name)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__input-field {
	> span {
		display: none;
	}
	&.warning {
		> span {
			display: block;
			width: 100%;
			padding-left: 16px;
			background-image: url(~@/assets/svg/side-warning.svg);
			background-repeat: no-repeat;
			background-size: 12px;
			font-size: 0.75rem;
			background-position: center left 0;
			margin-top: 2px;
			color: $RED;
			margin-bottom: -14px;
		}
	}
}
</style>
