var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "toast-wrapper",
    staticClass: "Toast__list"
  }, _vm._l(_vm.getMessages, function (data) {
    return _c('toast', {
      key: "toast-".concat(data.toastId),
      attrs: {
        "title": data.title,
        "content": data.content,
        "toastId": String(data.toastId),
        "type": data.type
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }