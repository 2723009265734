<template>
	<tr class="table-item" v-if="item">
		<td class="header-checkbox">
			<div>
				<input type="checkbox" v-model="isChecked" :selected="isKeySelectedAll" />
			</div>
		</td>
		<td class="volume">
			<div class="volume-wrap">
				<img class="remove-btn" src="@/views/inventoryGen2/svg/detail/remove-circle.svg" width="18" height="18" @click="removeItem" />
				<form-divide :value="item.volume" :unit="item.volumeUnit" forModalDivide></form-divide>
			</div>
		</td>
		<td class="storage">
			<div class="storage-wrap">
				<form-storage :value="item.storage" @input="changeStorage"></form-storage>
			</div>
		</td>
		<td class="manage">
			<div class="manage-wrap">
				<button class="btn-edit" @click="$emit('showManage')">
					<img src="@/views/inventoryGen2/svg/detail/icon-pencil.svg" width="24" height="24" />
				</button>
				<span class="change-text" v-if="isChangedItem[idx]">{{ tt('변경됨') }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import FormStorage from '@/views/common/components/form/FormStorage.vue'
import FormDivide from '@/views/inventoryGen2/components/detail/modal/modalDivide/formDivide.vue'
import { mapGetters, mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'DivideTableItem',
	components: {
		FormStorage,
		FormDivide,
	},
	props: {
		idx: {
			type: [Number, String],
			default: -1,
		},
	},
	data() {
		return {
			isChecked: false || this.isKeySelectedAll,
		}
	},
	computed: {
		...mapGetters('inventoryGen2/divide', ['divideItems', 'selectedItem', 'isKeySelectedAll', 'isChangedItem']),
		isIncludeId() {
			return this.selectedItem.includes(this.item?.divideKeyNo)
		},
		item() {
			return this.divideItems?.[this.idx]
		},
	},
	watch: {
		isChecked(value) {
			if (value) {
				if (!this.isIncludeId) {
					this.setSelectedItem([...this.selectedItem, this.item?.divideKeyNo])
				}
			} else {
				this.setSelectedItem(this.selectedItem.filter((item, idx) => idx !== this.item?.divideKeyNo))
			}
		},
		isIncludeId(value) {
			this.isChecked = value
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/divide', ['setDivideItemsStorageByIdx', 'setSelectedItem']),
		removeItem() {
			this.$emit('removeItem')
		},
		changeStorage(storage) {
			this.setDivideItemsStorageByIdx({ storage: storage, idx: this.idx })
			this.$root.toast('보관함 지정 완료', '보관함이 지정되었습니다', ToastType.SUCCESS)
		},
	},
}
</script>

<style lang="scss" scoped>
.table-item {
	display: flex;
	align-items: center;
	min-height: 60px !important;
	border-bottom: 1px solid $DIVIDER;
	background: $LAB_WHITE;
	td {
		width: auto !important;
	}
}
.header-checkbox {
	min-width: 70px !important;
	width: 70px;
	display: flex;
	justify-content: center;
}

.volume {
	min-width: 378px !important;
	.volume-wrap {
		padding: 7px;
		display: flex;
		align-items: center;
		.remove-btn {
			margin-right: 10px;
			cursor: pointer;
		}
	}
}

.storage {
	min-width: 350px !important;
	.storage-wrap {
		display: flex;
		align-items: center;
		margin-left: 7px;
	}
}

.manage {
	.manage-wrap {
		min-width: 150px;
		display: flex;
		.btn-edit {
			margin-left: 7px;
			width: 30px;
			height: 30px;
			border-radius: 4px;
			background: $GREY_4;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $GREY_4, $GREY_4);
			}
		}
		.change-text {
			margin-left: 10px;
			display: flex;
			align-items: center;
			font-size: 14px;
			font-weight: 400;
			color: $BLUE;
		}
	}
}
</style>
