var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "label-wrap"
  }, [_c('div', {
    staticClass: "list-wrap"
  }, [_c('p', {
    staticClass: "list-title"
  }, [_vm._v(_vm._s(_vm.tt('유해･위험 문구')))]), !_vm.isHCodeList ? _c('p', {
    staticClass: "nodata"
  }, [_vm._v(_vm._s(_vm.tt('위험성 분류를 먼저 선택해 주세요')))]) : _vm._e(), _c('div', {
    staticClass: "text-list"
  }, _vm._l(_vm.hCodeList, function (hCode) {
    return _c('div', {
      key: hCode,
      staticClass: "hazard-text-wrap"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.checkedItem,
        expression: "checkedItem"
      }],
      attrs: {
        "type": "checkbox",
        "id": hCode
      },
      domProps: {
        "value": hCode,
        "checked": Array.isArray(_vm.checkedItem) ? _vm._i(_vm.checkedItem, hCode) > -1 : _vm.checkedItem
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.checkedItem,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = hCode,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.checkedItem = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.checkedItem = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.checkedItem = $$c;
          }
        }
      }
    }), _c('label', {
      staticClass: "hazard-text",
      attrs: {
        "for": hCode
      }
    }, [_c('div', {
      staticClass: "code"
    }, [_vm._v(_vm._s(hCode))]), _c('div', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.tt(_vm.hCodeText(hCode))))])])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }