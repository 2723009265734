var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('fixed-header', {
    attrs: {
      "title": _vm.tt('폐기/삭제된 물품'),
      "previousPage": _vm.previousPage
    }
  }), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('disposal-table', {
    attrs: {
      "list": _vm.list
    }
  }), _vm.selectedItems.length > 0 ? _c('floating-menu') : _vm._e()], 1), _c('pagination', {
    ref: "pagination",
    attrs: {
      "total": _vm.count
    },
    on: {
      "page": _vm.pagination
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }