<template>
	<div id="Organization" class="Organization">
		<div class="AppContainer__location-title">
			<h5 class="AppContainer__location-name">
				<img v-if="groupInfo && groupInfo.type == 'INSTITUTE'" src="@/assets/svg/institution-building_y.svg" class="instituteImage" />
				{{ getGroupName }}
			</h5>
		</div>
		<!-- 그룹 정보 -->
		<organization-info-item-list :groupInfo="groupInfo" :userPermission="userPermission" @openModalEditGroup="openModalEditGroup">
		</organization-info-item-list>
		<!-- 그룹원 -->
		<organization-member-list
			:groupInfo="groupInfo"
			:groupMembers="groupMembers"
			:isStart="isStart"
			:order="order"
			:isHint="isHint"
			:userPermission="userPermission"
			@openModal="openModalAddMember"
			@openDeleteModal="openModalDeleteMember"
			@openModalMemberInfo="openModalMemberInfo"
			@closeOrder="closeOrder"
			@getGroupMembersParams="getGroupMembersParams"
		></organization-member-list>
		<!-- 접근 권한 -->
		<organization-access-list
			:groupInfo="groupInfo"
			:isStart="isStart"
			:order="order"
			:isHint="isHint"
			:userPermission="userPermission"
			@openMemberModal="openModalAddMemberAuth"
			@openGroupModal="openModalAddGroupAuth"
			@refresh="refresh"
			@closeOrder="closeOrder"
		></organization-access-list>
		<organization-storage-box
			:groupInfo="groupInfo"
			:isStart="isStart"
			:userPermission="userPermission"
			@showModalDeleteGroup="openModalDeleteGroup"
			@showStorage="openModalStorage"
			@showModalDeleteStorage="showModalDeleteStorage"
			@showModalEditStorage="showModalEditStorage"
			v-if="groupInfo"
		></organization-storage-box>

		<!-- 모달 -->
		<modal-add-storage-v-2 ref="modal-edit-storage" type="EDIT" @refresh="refreshData"></modal-add-storage-v-2>
		<modal-delete-location ref="modal-delete" @refresh="refreshData"></modal-delete-location>
		<modal-add-member
			ref="modal-add-member"
			:groupInfo="groupInfo"
			:isStart="true"
			@refresh="refresh"
			@changeOrderAccess="changeOrderAccess"
			@completeOrganization="completeOrganization"
		></modal-add-member>
		<modal-add-group :isStart="true" ref="modal-add-group" @refresh="refresh" @changeOrderGroup="changeOrderGroup"></modal-add-group>
		<modal-confirm-delete
			ref="modal-confirm-delete"
			@hideMemberInfo="hideMemberInfo"
			@refresh="refresh"
			:title="title"
			:content="content"
		></modal-confirm-delete>
		<modal-add-group-auth ref="modal-add-group-auth" @refresh="refresh" :groupInfo="groupInfo" @completeOrganization="completeOrganization">
		</modal-add-group-auth>
		<modal-member-info
			ref="modal-member-info"
			:userPermission="userPermission"
			@delete="openModalDeleteMember"
			@refresh="refresh"
			@showRegisteringMember="showRegisteringMember"
		></modal-member-info>
		<modal-treeview
			ref="modal-treeview"
			portalTo="lm-modal"
			:title="tt('보관함 관리')"
			:searchPlaceholder="tt('보관함을 검색해주세요')"
			:treeData="storageTreeData"
			:hideSubStorage="true"
			mode="multiStorage"
			@complete="hideModalStorage"
		></modal-treeview>
		<modal-registering-member
			ref="modal-registering-member"
			page="member"
			:userInfo="user"
			@update="updateRegistering"
			@complete="completeRegistering"
		></modal-registering-member>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import locationAPI from '@/services/api/institute/location'
import storageAPI from '@/services/api/institute/storage'
import OrganizationInfoItemList from '@/views/institute/organization/components/OrganizationInfoItemList.vue'
import OrganizationMemberList from '@/views/institute/organization/components/OrganizationMemberList.vue'
import OrganizationAccessList from '@/views/institute/organization/components/OrganizationAccessList.vue'
import OrganizationStorageBox from '@/views/institute/organization/components/OrganizationStorageBox.vue'
import ModalAddStorageV2 from '@/views/institute/location/modals/ModalAddStorageV2.vue'
import ModalDeleteLocation from '@/views/institute/location/modals/ModalDeleteLocation.vue'
import ModalAddGroup from '@/views/institute/organization/modals/ModalAddEditGroup.vue'
import ModalConfirmDelete from '@/views/institute/organization/modals/ModalConfirmDelete.vue'
import ModalAddMember from '@/views/institute/organization/modals/ModalAddMember.vue'
import ModalAddGroupAuth from '@/views/institute/organization/modals/ModalAddGroupAuth.vue'
import ModalMemberInfo from '@/views/common/components/modals/ModalMemberInfo.vue'
import ModalTreeview from '@/views/common/components/modals/ModalTreeview.vue'
import ModalRegisteringMember from '@/views/institute/member/modals/ModalRegisteringMember.vue'
import ModalInitPwSetting from '@/views/institute/member/modals/ModalInitPwSetting.vue'
import instituteMemberAPI from '@/services/api/institute/member'
import instituteGroupAPI from '@/services/api/institute/group'

export default {
	name: 'OrganizationInfoArea',
	components: {
		OrganizationInfoItemList,
		OrganizationMemberList,
		OrganizationAccessList,
		OrganizationStorageBox,
		ModalAddStorageV2,
		ModalDeleteLocation,
		ModalAddGroup,
		ModalConfirmDelete,
		ModalAddMember,
		ModalAddGroupAuth,
		ModalMemberInfo,
		ModalTreeview,
		ModalRegisteringMember,
		ModalInitPwSetting,
	},
	props: {
		groupInfo: {
			type: Object,
		},
		isStart: {
			type: Boolean,
			default: false,
		},
		order: {
			type: Number,
			default: 0,
		},
		isHint: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			hasMember: true,
			title: '',
			content: '',
			storageTreeData: null,
			instId: '',
			storageIdList: [],
			storageAccessIdList: [],
			initPw: null,
			userInfo: {},
			groupMembers: {},
			membersParams: {
				offset: 0,
				length: 5,
			},
		}
	},
	async created() {
		this.instId = this.$route.params.instituteId
		const location = await locationAPI.lookupLocationStorageTree(this.instId)
		this.storageTreeData = location.data
	},
	watch: {
		groupInfo: {
			deep: true,
			async handler() {
				const response = await instituteGroupAPI.lookupGroupMemberList(this.groupInfo.id, this.membersParams)
				this.groupMembers = { ...response.data }
			},
		},
	},
	computed: {
		...mapState('user', ['user']),
		getGroupName() {
			return this.groupInfo ? this.groupInfo.name : ''
		},
	},
	methods: {
		openModalAddGroup() {
			this.$refs['modal-add-group'].show('ADD')
		},
		openModalEditGroup() {
			this.$refs['modal-add-group'].show('EDIT')
		},
		openModalAddMember(type) {
			this.$refs['modal-add-member'].show(type)
			this.$emit('closeOrder')
		},
		openModalAddMemberAuth(type) {
			this.$refs['modal-add-member'].show(type)
			if (this.isStart) {
				this.$emit('closeOrder')
			}
		},
		openModalDeleteGroup() {
			this.title = this.tt('그룹 삭제')
			this.content = this.tt('정말로 그룹을 삭제하시겠습니까? <br>그룹과 관련된 모든 정보가 사라집니다.')
			this.$refs['modal-confirm-delete'].show('DELETE_GROUP')
		},
		openModalDeleteMember(memberId) {
			this.title = this.tt('그룹원 내보내기')
			this.content = this.tt('정말로 그룹원에서 내보내시겠습니까?')
			this.$refs['modal-confirm-delete'].show('DELETE_MEMBER', memberId)
		},
		openModalStorage() {
			this.storageIdList = []
			this.storageAccessIdList = []
			this.groupInfo.storages.forEach(x => {
				x.storages.forEach(y => {
					this.storageIdList.push(y.id)
					this.storageAccessIdList.push(y.accessId)
				})
			})
			this.$refs['modal-treeview'].show(this.storageIdList)
		},
		hideModalStorage(storageList) {
			this.$refs['modal-treeview'].hide()
			const newIdList = storageList.filter(x => x).map(x => x.id)
			//삭제
			this.storageIdList.forEach((id, index) => {
				if (!newIdList.includes(id)) {
					const data = {
						storageId: id,
						accessIds: [this.storageAccessIdList[index]],
					}
					storageAPI.deleteStorageAccess(this.instId, data)
				}
			})
			//추가
			newIdList.forEach(id => {
				if (!this.storageIdList.includes(id)) {
					const data = {
						storageId: id,
						groupsId: [this.groupInfo.id],
						permission: {
							read: true,
							update: false,
						},
					}
					storageAPI.addStorageAccess(this.instId, data)
				}
			})
			this.refresh(this.groupInfo.id)
		},
		hideMemberInfo() {
			this.$refs['modal-member-info'].hide()
		},
		openModalAddGroupAuth() {
			this.$refs['modal-add-group-auth'].show()
			if (this.isStart) {
				this.$emit('closeOrder')
			}
		},
		openModalMemberInfo(memberId) {
			this.$refs['modal-member-info'].show(memberId, this.$route.params.instituteId)
		},
		refresh(id) {
			this.$emit('refresh', id)
		},
		changeOrderGroup() {
			this.$emit('changeOrderGroup')
		},
		changeOrderAccess() {
			this.$emit('changeOrderAccess')
		},
		closeOrder() {
			this.$emit('closeOrder')
		},
		completeOrganization() {
			this.$emit('completeOrganization')
		},
		async loadInitPw() {
			const response = await instituteMemberAPI.lookupInitPw(this.$route.params.instituteId)

			this.initPw = response.data.password
		},
		showInitPwSetting() {
			this.$refs['modal-init-pw-setting'].show()
		},
		showRegisteringMember(mode, item) {
			this.$refs['modal-registering-member'].show(mode, item)
		},
		updateRegistering(info) {
			if (this.$refs['modal-member-info'].isShow == true) {
				//정보편집시 해당 멤버가 조직도 그룹에 포함되어있는지 여부. 해당하지 않으면 멤버 정보를 보여줄 필요가 없음
				if (info.groupsId.indexOf(this.groupInfo.id) >= 0) {
					this.$refs['modal-member-info'].show(info.id, this.$route.params.instituteId)
				} else {
					this.$refs['modal-member-info'].hide()
					this.refreshData()
				}
			}
			this.$refs['modal-registering-member'].hide()
		},
		completeRegistering(info, mode) {
			this.$refs['modal-registering-member'].hide()
		},
		hideInitPwSetting() {
			this.$refs['modal-init-pw-setting'].hide()
			this.loadInitPw()
		},

		async loadGroupMembers() {
			const response = await instituteGroupAPI.lookupGroupMemberList(this.groupInfo.id, this.membersParams)
			this.groupMembers = { ...response.data }
		},
		getGroupMembersParams(params) {
			this.membersParams = params
			this.loadGroupMembers()
		},
		showModalDeleteStorage(data) {
			this.$refs['modal-delete'].show('storage', data)
		},
		showModalEditStorage(data) {
			this.$refs['modal-edit-storage'].show(data, 'EDIT')
		},
		refreshData() {
			this.$emit('refresh', this.groupInfo.id)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_organization.scss';

.AppContainer__location-name {
	display: flex;
	align-items: center;
	height: auto;
}

.instituteImage {
	width: 24px;
	height: 24px;
	filter: brightness(0%);
	margin-right: 8px;
}
</style>
