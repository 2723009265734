var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "label-wrap"
  }, [_vm.volume >= 500000 ? _c('ghs-label-3130-a') : _vm.volume < 500000 && _vm.volume >= 200000 ? _c('ghs-label-3130-b') : _vm.volume < 200000 && _vm.volume >= 50000 ? _c('ghs-label-3120') : _vm.volume < 50000 && _vm.volume >= 5000 ? _c('ghs-label-3118') : _c('ghs-label-3114')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }