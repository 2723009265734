import axiosInstance from '@/services/axios'

const endPoint = {
	statements: instId => `/institute/${instId}/safety/ghs/statements`,
	getGhsRequireState: instId => `/institute/${instId}/safety/ghs/required`,
	getGhs: instId => `/institute/${instId}/safety/ghs`,
	uploadGhs: instId => `/institute/${instId}/safety/ghs`,
	deleteGhs: instId => `/institute/${instId}/safety/ghs`,
	getGhsPreview: instId => `/institute/${instId}/safety/ghs/preview`,
	exportGhsQrPdf: instId => `/institute/${instId}/safety/ghs/export/pdf`,
}

const ghsAPI = {
	statements: instId => axiosInstance.get(endPoint.statements(instId)),
	getGhsRequireState: (instId, params) => axiosInstance.get(endPoint.getGhsRequireState(instId), { params: params }),
	getGhs: (instId, params) => axiosInstance.get(endPoint.getGhs(instId), { params: params }),
	uploadGhs: (instId, body) => axiosInstance.post(endPoint.getGhs(instId), body),
	deleteGhs: (instId, params) => axiosInstance.delete(endPoint.deleteGhs(instId), { params: params }),
	getGhsPreview: (instId, params) => axiosInstance.get(endPoint.getGhsPreview(instId), { params: params }),
	exportGhsQrPdf: (instId, body) =>
		axiosInstance.post(endPoint.exportGhsQrPdf(instId), body, {
			responseType: 'blob',
		}),
}

export default ghsAPI
