<template>
	<div>
		<div class="keyword-header" v-if="tags.length > 0">
			<span>
				{{ tt('태그 찾기') }}
			</span>
		</div>
		<ul class="tag-keyword-list">
			<li class="tag-keyword" v-for="(tag, index) in tags" :key="`tag_${index}`" @click.stop="$emit('clickTag', tag)">
				<span>
					{{ tag.name }}
				</span>
			</li>
		</ul>
	</div>
</template>
<script>
export default {
	name: 'InventoryTags',
	props: {
		tags: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {}
	},
	computed: {},
}
</script>

<style lang="scss" scoped></style>
