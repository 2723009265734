var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('td', {
    staticClass: "checkbox"
  }, [_c('input', {
    "class": {
      someChecked: !_vm.isSelectedAll && !_vm.isEmptySelectedItem
    },
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isChecked
    },
    on: {
      "click": _vm.selectAll
    }
  })]), _c('td', {
    staticClass: "no"
  }, [_vm._v("No")]), _c('td', {
    staticClass: "type"
  }, [_vm._v(_vm._s("".concat(_vm.tt('타입'), "/").concat(_vm.tt('규격'))))]), _c('td', {
    staticClass: "preview"
  }, [_vm._v(_vm._s(_vm.tt('미리보기')))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }