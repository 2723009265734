import inventoryAPI from '@/services/api/inventory'

const state = {
	tagList: null,
	selectedTag: null,
}

const getters = {
	tagList: state => state.tagList,
	selectedTag: state => state.selectedTag,
	selectedTagObj: state => state.tagList.filter(item => state.selectedTag.includes(item.id)),
}

const mutations = {
	setTagList: (state, tagList) => {
		state.tagList = tagList
		state.selectedTag = state.selectedTag?.filter(id => state.tagList.some(it => it.id == id))
	},
	initTag: state => {
		state.selectedTag = []
	},
	setSelectedTag: (state, selectedTag) => {
		state.selectedTag = selectedTag
	},
	toggleTag: (state, selectedTagId) => {
		let hasId = state.selectedTag && state.selectedTag.includes(selectedTagId)
		if (hasId) {
			state.selectedTag = state.selectedTag.filter(id => id !== selectedTagId)
		} else {
			if (state.selectedTag) {
				state.selectedTag = [...state.selectedTag, selectedTagId]
			} else {
				state.selectedTag = [selectedTagId]
			}
		}
	},
}

const actions = {
	getTagList: async ({ commit, rootGetters }) => {
		const response = await inventoryAPI.lookupTagList(rootGetters.instituteId)
		commit('setTagList', response.data)
	},
	addTag: async ({ dispatch, rootGetters }, tagname) => {
		await inventoryAPI.createTag(rootGetters.instituteId, { name: tagname })
		await dispatch('getTagList')
	},
	deleteTag: async ({ dispatch, rootGetters }, ids) => {
		await inventoryAPI.deleteTag(rootGetters.instituteId, { ids })
		await dispatch('getTagList')
	},
}
export default { namespaced: true, state, getters, mutations, actions }
