<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade hide-footer>
		<template #modal-title>
			<div class="title">
				{{ tt('물품 ID 스티커 출력') }}
			</div>
		</template>
		<template>
			<div class="body">
				<div
					class="describe"
					v-html="
						tt(
							'안전법령에 의해, GHS라벨+QR스티커는 용량에 따라 지정된 법령규격으로 출력합니다.<br />라벨을 프린트할 용지를 프린트에 넣고 인쇄해주세요.'
						)
					"
				></div>
				<a class="link" target="_blank" href="https://www.law.go.kr/LSW/admRulLsInfoP.do?admRulSeq=2100000049106"
					>※ {{ tt('「위험물안전관리법」시행규칙 별표 19') }}</a
				>
				<table>
					<thead>
						<tr>
							<td>{{ tt('타입') }}</td>
							<td>{{ tt('사이즈') }}</td>
							<td>{{ tt('스티커 규격') }}</td>
							<td>{{ tt('출력할 용지') }}</td>
							<td>{{ tt('출력 기기') }}</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>QR</td>
							<td>{{ tt('전체') }}</td>
							<td>
								<div>
									<p>
										{{ tt('1칸') }}
									</p>
									<p>24.5mm x 100mm</p>
								</div>
							</td>
							<td>{{ tt('폼텍 3219') }}</td>
							<td>{{ tt('일반 프린터') }}</td>
						</tr>
						<tr>
							<td>GHS+QR</td>
							<td>{{ tt('500L 이상') }}</td>
							<td>
								<div>
									<p>
										{{ tt('1칸') }}
									</p>
									<p>297mm x 210mm</p>
								</div>
							</td>
							<td>{{ tt('폼텍 3130') }}</td>
							<td>{{ tt('일반 프린터') }}</td>
						</tr>
						<tr>
							<td>GHS+QR</td>
							<td>{{ tt('200L ≤ 용량 < 500L') }}</td>
							<td>
								<div>
									<p>
										{{ tt('1칸') }}
									</p>
									<p>297mm x 210mm</p>
								</div>
							</td>
							<td>{{ tt('폼텍 3130') }}</td>
							<td>{{ tt('일반 프린터') }}</td>
						</tr>
						<tr>
							<td>GHS+QR</td>
							<td>{{ tt('50L ≤ 용량 < 200L') }}</td>
							<td>
								<div>
									<p>
										{{ tt('2칸') }}
									</p>
									<p>200mm x 140mm</p>
								</div>
							</td>
							<td>{{ tt('폼텍 3120') }}</td>
							<td>{{ tt('일반 프린터') }}</td>
						</tr>
						<tr>
							<td>GHS+QR</td>
							<td>{{ tt('전체') }}</td>
							<td>
								<div>
									<p>
										{{ tt('4칸') }}
									</p>
									<p>140mm x 99.1mm</p>
								</div>
							</td>
							<td>{{ tt('폼텍 3118') }}</td>
							<td>{{ tt('일반 프린터') }}</td>
						</tr>
						<tr>
							<td>GHS+QR</td>
							<td>{{ tt('전체') }}</td>
							<td>
								<div>
									<p>
										{{ tt('8칸') }}
									</p>
									<p>99.1mm x 67.7mm</p>
								</div>
							</td>
							<td>{{ tt('폼텍 3114') }}</td>
							<td>{{ tt('일반 프린터') }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	name: 'ModalDescribeSticker',
	components: {},
	props: {},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	padding: 15px 20px 10px 20px;
	border: 0;
	font-weight: 700;
	height: 40px;
	.modal-title {
		font-weight: 700;
		font-size: 16px;
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 660px;
	max-height: 700px;
	.modal-content {
		background-color: $LAB_WHITE;
		border-radius: 8px;
		border: 0;

		.form-wrap {
			padding: 20px;
			background: white;
			border: 1px solid #ccc;
			border-radius: 8px;
			gap: 20px;
			.form-header {
				font-weight: 700;
				font-size: 16px;
				.form-title {
				}
				.form-msg {
				}
			}
		}
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	padding: 0px 20px;
	overflow-y: auto;
	background: white;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	.body {
		.describe {
			font-size: 14px;
			font-weight: 400;
		}
		.link {
			font-size: 14px;
			font-weight: 400;
			color: $BLUE;
			text-decoration: underline;
		}
	}
}

tr + tr {
	border-top: 1px solid $INPUT;
}
td + td {
	border-left: 1px solid $INPUT;
}

table {
	width: 100%;
	text-align: center;
	border: 1px solid $INPUT;
	border-radius: 8px;
	margin: 20px 0px;
	font-size: 12px;
	thead {
		background: $GREY_2;
		color: $SECONDARY_1;
		font-size: 12px;
		font-weight: 700;
		border-bottom: 1px solid $INPUT;
		tr {
			height: 50px;
			padding: 15px;
			td {
			}
		}
	}
	tbody {
		tr {
			height: 50px;
		}
	}
}
</style>
