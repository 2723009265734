var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "license-item",
    "class": {
      point: _vm.isSelected,
      selected: _vm.isSelected,
      grey: _vm.disabled
    },
    attrs: {
      "id": "".concat(_vm.licenseType, "-disabled")
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.selectLicense.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "license-item-header"
  }, [_c('span', {
    staticClass: "license-item-title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.licenseType2Text(_vm.licenseType))) + " "), _vm.isTypePro ? _c('div', {
    staticClass: "recommand-icon"
  }, [_vm._v(" " + _vm._s(_vm.tt('추천!')) + " ")]) : _vm._e()]), _c('ul', {
    staticClass: "license-item-content"
  }, [_vm.licenseType != 'BASIC' ? _c('li', [_vm._v(_vm._s(_vm.tt('모든 기능 사용')))]) : _c('li', [_vm._v(_vm._s(_vm.tt('재고관리 기능에 집중')))]), _vm.licenseType == 'EDUCATION' ? _c('li', {
    staticClass: "important"
  }, [_vm._v(_vm._s(_vm.tt('인원 추가 무제한')))]) : _vm._e(), _c('li', {
    staticClass: "important"
  }, [_vm._v(" " + _vm._s(_vm.tt('MERCK 전 상품 10% 상시할인')) + " "), _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'Merck 10% 할인 안내로 가기',
      expression: "'Merck 10% 할인 안내로 가기'",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "src": require("@/assets/svg/Question.svg")
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.goM10.apply(null, arguments);
      }
    }
  })])])]), _c('div', {
    staticClass: "license-item-price"
  }, [_c('div', {
    staticClass: "price"
  }, [_vm._v(_vm._s("\u20A9".concat(_vm.licensePrice)))]), _c('div', {
    staticClass: "monhtly"
  }, [_vm._v(" " + _vm._s(_vm.tt('월간 금액')) + " / "), _c('span', {
    "class": {
      red: _vm.licenseType == _vm.type.EDUCATION
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.licenseType == _vm.type.EDUCATION ? '연구실당' : '계정당')))])]), _c('div', {
    staticClass: "yearPrice"
  }, [_vm._v("※ 연간 금액 = " + _vm._s("\u20A9".concat(_vm.licenseYearPrice)) + "/" + _vm._s(_vm.tt(_vm.licenseType == _vm.type.EDUCATION ? '연구실' : '계정')))])]), _vm.disabled ? _c('b-tooltip', {
    attrs: {
      "placement": "bottom",
      "target": "".concat(_vm.licenseType, "-disabled")
    }
  }, [_vm._v(_vm._s(_vm.disabledReason))]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }