var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input"
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "imglist"
  }, [!_vm.isImageLengthFull ? _c('div', [_vm._m(0), _c('input', {
    ref: "upload-input",
    staticClass: "img-upload",
    attrs: {
      "id": "upload-img",
      "multiple": "",
      "hidden": "",
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.onChange
    }
  })]) : _vm._e(), _vm._l(_vm.files, function (file, idx) {
    return _c('div', {
      key: _vm.fileUrl(file),
      staticClass: "img-wrap"
    }, [_c('img', {
      staticClass: "imgitem",
      attrs: {
        "src": _vm.fileUrl(file)
      },
      on: {
        "click": function click($event) {
          return _vm.$refs['image-viewer'].show(idx);
        }
      }
    }), _c('img', {
      staticClass: "icon-remove",
      attrs: {
        "src": require("./svg/icon-remove-img.svg")
      },
      on: {
        "click": function click($event) {
          return _vm.removeImg(idx);
        }
      }
    })]);
  })], 2), _c('modal-multi-image-viewer', {
    ref: "image-viewer",
    attrs: {
      "title": "이미지",
      "imageList": _vm.imageUrlList
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "img-add",
    attrs: {
      "for": "upload-img"
    }
  }, [_c('img', {
    attrs: {
      "src": require("./svg/add-image-icon.svg")
    }
  })]);
}]

export { render, staticRenderFns }