var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "number-input-wrapper"
  }, [_c('div', {
    staticClass: "inner-wrapper"
  }, [_vm._l(_vm.numbers, function (number, index) {
    return _c('div', {
      key: "".concat(number, "-").concat(index),
      staticClass: "card-input-button unselectable",
      "class": {
        empty: number == ''
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.addNumber(number);
        }
      }
    }, [_c('div', {
      staticClass: "number"
    }, [_vm._v(_vm._s(number))])]);
  }), _c('div', {
    staticClass: "bottom-button-wrapper"
  }, [_c('div', {
    staticClass: "card-input-button-bottom",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.remove.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/delete-card-input-number.svg"),
      "width": "28"
    }
  })]), _c('div', {
    staticClass: "card-input-button-bottom unselectable",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.reset.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('재배열')) + " ")])])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }