<template>
	<div class="option-info left">
		<div class="option-image">
			<img class="option-image-box" :src="image" />
		</div>
		<div class="option-content">
			<div class="seller-name">
				<span>
					{{ `[${tt(product.seller.name)}]` }}
				</span>
			</div>
			<div class="name">
				{{ product.name }}
			</div>
			<div class="subname">
				{{ product.subname }}
			</div>
			<div class="data">
				<span>{{ tt('카테고리') }} : {{ tt(cleanData(category2String(product.categoryType))) }}</span>
				<span>{{ tt('브랜드') }} : {{ tt(cleanData(product.brand ? product.brand.name : null)) }}</span>
				<span>{{ tt('제품번호') }} : {{ tt(cleanData(product.productCode)) }}</span>
				<span>{{ tt('CAS No') }} : {{ tt(cleanData(product.casno)) }}</span>
				<span v-if="product.volume">
					{{ tt('사이즈(용량)') }} : {{ tt(`${product.volume ? product.volume : product.currentVolume}${product.volumeUnit}`) }}
				</span>
				<!-- TODO: 백엔드 데이터 정확해지면 규격 , 형태 , DNA 정보 , 소스 , 패키지 수량 , 내경(ID) , Particle Size , 길이 , PoreSize 등등 추가하기 -->
			</div>
			<div class="price-delivery">
				<!-- 할인 받아서 처리해야됨 -->
				<span class="price"> {{ tt('${1}원', CommaNum(product.sellingUnitPrice)) }} </span>
				<!-- <span
					class="price"
					style="text-decoration: line-through; color: #999"
					>{{ product.originPrice }}
				</span> -->
				<!-- <span class="price">{{ product.price }}</span> -->
				<!-- <span v-if="useSale" class="price" style="text-decoration: line-through; color: #999">
					{{ product.price }}
				</span> -->
				<!-- <span class="delivery" v-if="isDelivery">{{
					tt(`임시 도착예정(장바구니할때 처리하기!)`)
				}}</span> -->
			</div>
		</div>
	</div>
</template>

<script>
import { LargeClassToString, MiddleClassToString } from '@/utils/define/ItemCode'
export default {
	components: {},
	props: {
		product: {
			type: Object,
			default: () => {},
		},
		isDelivery: {
			type: Boolean,
			default: false,
		},
		useSale: {
			type: Boolean,
			default: true,
		},
	},
	created() {},
	computed: {
		image() {
			return this.product.brand?.image ?? require('@/assets/svg/no-image.svg')
		},
	},
	methods: {
		cleanData(data) {
			return data ?? '-'
		},
		category2String(data) {
			if (data === 'CAT_LABETC') return `${this.tt('실험용품')}`
			else if (data === 'CAT_OFFICE') return `${this.tt('사무용품')}`
			else {
				let largeCategory = data.substring(0, data.lastIndexOf('_'))
				if (largeCategory === 'CAT') return `${this.tt(LargeClassToString(data))}` // category by 1 depth
				return `${this.tt(LargeClassToString(largeCategory))} / ${this.tt(MiddleClassToString(data))}`
			}
		},
		CommaNum(num) {
			return Number(num)?.toLocaleString('ko-KR')
		},
	},
}
</script>

<style lang="scss" scope>
.option-info {
	display: flex;
	flex-direction: row !important;
	justify-content: left !important;
	align-items: center;
	width: 100%;
	.option-image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		border: 1px solid $DIVIDER;
		border-radius: 4px;
		img.option-image-box {
			object-fit: contain;
			width: 100%;
			height: 100%;
			border-radius: 4px;
		}
		// img {
		// 	border-radius: 4px;
		// }
	}
	.option-content {
		margin-left: 16px;
		font-size: 12px;
		line-height: 18px;
		color: $SECONDARY_2;
		font-weight: 400;
		width: calc(100% - 100px);
		.name {
			font-size: 16px;
			font-weight: 700;
			color: $DEFAULT;
			margin-bottom: 4px;
		}
		.data,
		.size {
			font-size: 12px;
			color: $SECONDARY_2;
			span + span {
				display: inline-block;
				&::before {
					vertical-align: middle;
					margin-left: 6px;
					margin-right: 6px;
					display: inline-block;
					// width: 1px;
					height: 12px;
					content: '';
					border-right: 1px solid $INPUT;
				}
			}
			span {
				// &.division {
				// 	display: block !important;
				// 	& ~ span {

				// 	}
				// }
			}
		}
		.price {
			font-size: 14px;
			color: $DEFAULT;
		}

		.price-delivery {
			font-size: 14px;
			font-weight: 500;
			color: $DEFAULT;
			margin-top: 10px;
			.delivery {
				&::after {
					border: none;
				}
			}
		}
	}
}
</style>
