var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "no-close-on-backdrop": "",
      "centered": "",
      "hide-footer": ""
    },
    on: {
      "close": _vm.hide
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.tt('스캔 가능한 브랜드')) + " ")];
      },
      proxy: true
    }])
  }, [[_c('p', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s('QR코드 혹은 바코드를 스캔하여 물품 정보를 찾을 수 있는 브랜드는<br/> 다음과 같습니다.')
    }
  }), _c('div', {
    staticClass: "brand-list"
  }, _vm._l(_vm.brandList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "brand-item"
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/images/brand/' + item.image),
        "width": "80",
        "height": "40"
      }
    }), _c('div', {
      staticClass: "brand-name"
    }, [_c('p', {
      staticClass: "name"
    }, [_vm._v(_vm._s(_vm.tt(item.name)))])])]);
  }), 0)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }