var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-button', {
    attrs: {
      "isActive": _vm.isActive,
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.img
    }
  }), _vm.text ? _c('div', {
    staticClass: "btn-text",
    "class": _vm.color
  }, [_vm._v(_vm._s(_vm.tt(_vm.text)))]) : _vm._e(), _vm.subText ? _c('div', {
    staticClass: "btn-subtext"
  }, [_vm._v(_vm._s(_vm.tt(_vm.subText)))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }