var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "license-selector-wrapper"
  }, [_c('div', {
    staticClass: "switcher"
  }, [_c('div', {
    staticClass: "yearly",
    "class": {
      selected: !_vm.isMonthly
    },
    on: {
      "click": function click($event) {
        return _vm.changeMonthly(false);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('연간결제')) + " "), _c('span', [_vm._v(_vm._s(_vm.tt('최대 -16%')))])]), _c('div', {
    staticClass: "monthly",
    "class": {
      selected: _vm.isMonthly
    },
    on: {
      "click": function click($event) {
        return _vm.changeMonthly(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('월간 결제')) + " ")])]), _c('div', {
    staticClass: "selector-header"
  }, [_c('span', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.tt('라이선스 선택')))]), _c('span', {
    staticClass: "header-side-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('(VAT 별도)')) + " ")])]), _c('div', {
    staticClass: "selector-wrapper"
  }, _vm._l(_vm.licenseTypes, function (license) {
    return _c('license-selector-item', {
      key: license,
      attrs: {
        "licenseType": license,
        "selectedLicenseType": _vm.licenseType
      },
      on: {
        "getLicenseType": _vm.getLicenseType
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }