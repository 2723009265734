<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade hide-footer>
		<template #modal-title>
			{{ tt('라이선스 관리') }}
		</template>
		<template>
			<div class="error">
				<img src="@/assets/svg/input-icon-warning.svg" />
				{{ tt('결제카드 정보와 관련하여 문제가 발생했습니다. 결제카드를 업데이트해주세요.') }}
			</div>
			<div class="modal-footer" v-if="!isLicenseActivate || beingSubscription">
				<LicenseManageFold open isFail @manageSubscribe="$emit('manageSubscribe')" />
			</div>
		</template>
	</b-modal>
</template>
<script>
import LicenseManageFold from '../components/LicenseManageFold.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
export default {
	components: {
		LicenseManageFold,
	},
	mixins: [MixinLicense, MixinPayment],
	methods: {
		async show() {
			await this.loadLicenseManagement()
			await this.loadLicenseRatePlan()
			this.$refs['modal'].show()
			if (this.beingSubscription) {
				console.log(1)
				await this.loadDefaultCreditCard()
			}
		},
		hide() {
			this.$refs['modal'].hide()
		},
		buyLicense() {
			this.$root.purchaseLicense()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/license/_modal-license-manage.scss';
::v-deep {
	.modal-body {
		gap: 10px;
	}
}
</style>
