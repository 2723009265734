<template>
	<b-dropdown ref="dropdown" v-click-outside="hide" no-caret variant="link" :toggle-class="`text-decoration-none ${isActive ? 'active' : ''}`">
		<template #button-content>
			<span>{{ text ?? selectName }}</span
			><img :src="isActive ? require('@/assets/svg/arrow-down_y.svg') : require('@/assets/svg/arrow-down.svg')" />
		</template>
		<b-dropdown-item v-for="option in options" :key="option.value" @click="selectItem(option.value)">
			<p>{{ tt(option.name) }}</p>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
/* options: { value, name} */
export default {
	props: {
		text: {
			type: String,
			default: '선택',
		},
		options: {
			type: Array,
			default: () => [],
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		value: {
			type: [String, Number, Boolean, Object],
			default: null,
		},
	},
	computed: {
		selectName() {
			return this.options.find(it => it.value == this.value)?.name ?? '선택'
		},
	},
	methods: {
		selectItem(value) {
			this.$emit('update', value)
			this.$emit('change', value)
		},
		hide() {
			this.$refs['dropdown'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.text-primary {
		color: $LAB_YELLOW !important;
		width: 20px;
		height: 20px;
		margin-top: 4px;
	}
	.dropdown-menu {
		padding: 5px;
		border-radius: 5px;
		width: 100%;
		min-width: 100%;
	}
	.dropdown-item {
		display: flex;
		align-items: center;
		padding: 5px;
		border-radius: 5px;
		color: #000;
		font-size: 14px;
		&:hover,
		&:active {
			background-color: $GREY_2;
			border-radius: 4px;
		}
	}
	.btn {
		width: 140px;
		display: flex;
		background-color: $GREY_4;
		border-radius: 4px;
		height: 30px;
		padding: 5px 10px;
		font-size: 14px;
		font-weight: 700;
		align-items: center;
		justify-content: space-between;
		&:focus {
			box-shadow: none;
		}
		&:hover {
			color: black;
			border: 1px solid #f2f3f4;
		}
		&.active {
			border: 1px solid $TEXT_YELLOW;
			color: $TEXT_YELLOW;
			background-color: $SUB-YELLOW_200;
		}
	}
}
</style>
