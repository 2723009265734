var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Organization__group"
  }, [_c('div', {
    staticClass: "AppContainer__subtitle"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.isTypeInstitution ? _vm.tt('기관 멤버') : _vm.tt('그룹원')) + " ")]), _vm.isTypeInstitution && !_vm.isStart ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn",
    on: {
      "click": _vm.goMember
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/member.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('멤버 전체 보기')) + " ")]) : !_vm.isTypeInstitution && _vm.userPermission.institute && _vm.userPermission.institute.organization.update || _vm.isStart ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn Btn__hover-balloon",
    "class": [{
      point: _vm.isStart && _vm.order == 2 || _vm.isHint
    }, {
      pointTop: _vm.isStart && _vm.order == 2 || _vm.isHint
    }]
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/add-member.svg"),
      "alt": "icon"
    },
    on: {
      "click": _vm.openModalAddMember
    }
  }), _c('span', {
    on: {
      "click": _vm.openModalAddMember
    }
  }, [_vm._v(_vm._s(_vm.tt('그룹원 추가')))]), !_vm.isStart ? _c('div', {
    staticClass: "balloon"
  }, [_vm._v(" " + _vm._s(_vm.tt('기관에 소속된 멤버를 내 그룹에 추가할 수 있어요')) + " ")]) : _vm._e(), _vm.isStart && _vm.order == 2 || _vm.isHint ? _c('div', {
    staticClass: "order-balloon"
  }, [_c('div', {
    staticClass: "order-top"
  }, [_vm._m(0), !_vm.isHint ? _c('button', {
    on: {
      "click": _vm.closeOrder
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })]) : _vm._e()]), _c('h6', [_vm._v(_vm._s(_vm.tt('2. 그룹원 추가하기')))]), _c('p', [_vm._v(_vm._s(_vm.tt('그룹에 소속된 멤버를 추가해보세요.')))])]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "Organization__group-list"
  }, [_vm.hasMember ? _c('ul', {
    staticClass: "horizational-list"
  }, _vm._l(_vm.groupMembers.list, function (member, idx) {
    return _c('li', {
      key: "member-".concat(idx),
      staticClass: "Organization__group-item",
      on: {
        "click": function click($event) {
          return _vm.openModalMemberInfo(member.id);
        }
      }
    }, [_vm.hasImage(member.image) ? _c('img', {
      attrs: {
        "src": member.image,
        "alt": "group-image"
      }
    }) : _c('img', {
      attrs: {
        "src": require("@/assets/svg/member-photo-default.svg"),
        "alt": "group-image"
      }
    }), _c('div', {
      staticClass: "Organization__group-item-info"
    }, [_c('h6', [_vm._v(_vm._s(member.name))]), _c('p', [_vm._v(_vm._s(member.email))])]), !_vm.isTypeInstitution && _vm.userPermission.institute && _vm.userPermission.institute.organization.update ? _c('button', {
      staticClass: "AppContainer__subtitle-btn btn Btn__hover-balloon r-m20",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.removeGroupMember(member.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.tt('내보내기')) + " ")]) : _vm._e()]);
  }), 0) : _c('no-group-member'), _vm.hasMembers ? _c('organization-pagination', {
    attrs: {
      "isPerPage": false,
      "total": _vm.groupMembers.count
    },
    on: {
      "page": _vm.getGroupMemberInfoParams
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order"
  }, [_c('span'), _c('span', {
    staticClass: "active"
  }), _c('span')]);
}]

export { render, staticRenderFns }