<template>
	<div class="delivery-info-wrapper" v-if="draftInfo.purchaseType == 'INTERNAL'">
		<h5>
			{{ tt('배송지 정보') }} <span class="warning">{{ tt('*배송지에 따라 주문가능한 상품이 달라질 수 있습니다.') }}</span>
			<image-button
				class="btn-add"
				:image="require('@/assets/svg/icon/black-round-plus.svg')"
				:text="tt('새 배송지 등록')"
				@click="$refs['modal-add-address'].show()"
			></image-button>
		</h5>
		<div class="delivery-wrapper">
			<input-select
				ref="deliveryId"
				:title="'배송지 선택'"
				:placeholder="'배송지를 선택해주세요'"
				isRequired
				@change="change"
				:list="selectList"
				:item="draftInfo.deliveryId"
				:inValidText="'배송지를 선택해주세요.'"
				:isValid="!isValid || !!$refs['deliveryId'].value"
			>
				<div class="select-delivery-info" v-if="delivery">
					<div class="default" v-if="delivery.isDefault">{{ tt('기본 배송지') }}</div>
					<h6>{{ delivery.name }}</h6>
					<p class="address">[{{ delivery.postcode }}] {{ delivery.address }}</p>
					<p class="sub">
						{{ delivery.instituteName ? delivery.instituteName : '' }} {{ delivery.groupName ? ' / ' + delivery.groupName : '' }}
					</p>
				</div>
				<template #empty>
					<image-button
						class="btn-add"
						:image="require('@/assets/svg/icon/black-round-plus.svg')"
						:text="tt('새 배송지 등록')"
						@click="$refs['modal-add-address'].show()"
					></image-button>
				</template>
			</input-select>
		</div>
		<modal-add-address ref="modal-add-address" />
		<modal-confirm
			ref="modal-change"
			title="배송지 변경"
			content="배송지를 변경하는 경우 추가된 상품이 모두 삭제됩니다.<br>정말로 배송지 변경하시겠습니까?"
			confirmText="변경하기"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import InputSelect from '@/views/common/components/items/InputSelect.vue'
import ModalAddAddress from '../modal/ModalAddAddress.vue'
import ImageButton from '@/views/common/components/baseButton/ImageButton.vue'
import MixinStore from '@/mixins/store'
export default {
	props: {
		isValid: {
			type: Boolean,
		},
	},
	mixins: [MixinStore],
	components: {
		ModalAddAddress,
		InputSelect,
		ImageButton,
	},
	data() {
		return {
			delivery: null,
		}
	},
	watch: {
		deliveryList() {
			if (this.delivery == null && this.deliveryList.length > 0) {
				this.delivery = this.deliveryList.find(it => it.isDefault) ?? this.deliveryList[0]
				this.$refs['deliveryId'].value = this.delivery.id
			}
		},
	},
	async created() {
		await this.getDeliveryList()
	},
	computed: {
		...mapState('draft/item', ['draftInfo']),
		selectList() {
			return this.deliveryList.map(it => {
				return {
					value: it.id,
					name: (it.isDefault == 1 ? this.tt('[기본 배송지]') + ' ' : '') + it.name,
				}
			})
		},
		info() {
			return {
				deliveryId: this.$refs['deliveryId'].value,
			}
		},
	},
	methods: {
		// ...mapMutations('draft/item', ['updateDraftInfo']),
		change(value) {
			if (value != this.delivery.id) {
				if (this.draftInfo.purchaseItems.length > 0)
					this.$refs['modal-change'].show(
						() => {
							this.delivery = this.deliveryList.find(it => it.id == value)
							this.$emit('changeAddress')
						},
						() => {
							this.$refs['deliveryId'].value = this.delivery.id
						}
					)
				else this.delivery = this.deliveryList.find(it => it.id == value)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
h5 {
	display: flex;
	align-items: center;
	font-weight: 700;
	// margin-bottom: 30px;
	.warning {
		color: $RED;
		font-size: 16px;
		margin-left: 16px;
		font-weight: 400;
	}
	.btn-add {
		margin-left: auto;
	}
}
.delivery-wrapper {
	position: relative;
	margin-top: 16px;
	margin-bottom: 16px;
	padding: 10px 20px;
	border: 1px solid $INPUT;
	border-radius: 5px;
	width: 100%;
	.select-delivery-info {
		width: 100%;
		.default {
			margin-top: 16px;
			width: fit-content;
			padding: 0px 8px;
			height: 20px;
			background-color: $DIVIDER;
			border-radius: 5px;
			font-size: 12px;
			font-weight: 700;
			color: $SECONDARY_1;
		}
		h6 {
			margin-top: 10px;
			font-size: 16px;
			font-weight: 700;
			color: $DEFAULT;
		}
		.address {
			margin-top: 8px;
			font-weight: 500;
			font-size: 14px;
			color: $SECONDARY_2;
		}
		.sub {
			margin-top: 8px;
			font-weight: 500;
			font-size: 14px;
			color: $PLACEHOLDER;
		}
	}
	.item-wrapper {
		border-bottom: 0px;
	}
}
</style>
