import instituteMemberAPI from '@/services/api/institute/member'

const instituteMember = {
	namespaced: true,
	state: {
		tab: 1,
		memberList: {
			count: 0,
			members: [],
		},
		member: {},
	},
	getters: {
		getTab: state => state.tab,
		getMemberList: state => state.memberList,
		getMember: state => state.member,
	},
	mutations: {
		setTab: (state, payload) => {
			state.tab = payload
		},
		setMemberList: (state, payload) => {
			state.memberList = payload
		},
		setMember: (state, payload) => {
			state.member = payload
		},
	},
	actions: {
		lookupMemberList: async ({ commit, rootGetters }, params) => {
			const response = await instituteMemberAPI.lookupMemberList(
				rootGetters.instituteId,
				params?.offset,
				params?.length,
				params?.keyword,
				params?.groupsId
			)
			commit('setMemberList', response.data)
		},
		lookupMember: async ({ commit }, payload) => {
			const { instId, id } = payload
			return await instituteMemberAPI.lookupMember(instId, id).then(response => {
				commit('setMember', response.data)
			})
		},
		updateMember: async ({ commit }, payload) => {
			const { instId, body } = payload
			const response = await instituteMemberAPI.updateMember(instId, body)
			commit('setMemberList', response.data)
		},
		deleteMember: async ({ commit }, payload) => {
			const { instId, ids } = payload
			try {
				return await instituteMemberAPI.deleteMember(instId, ids)
			} catch (error) {
				return 'error'
			}
		},
	},
}

export default instituteMember
