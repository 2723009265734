var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modals"
  }, [_c('ModalLicenseManage', {
    ref: "ModalLicenseManage",
    on: {
      "addMember": _vm.addMember,
      "addDate": _vm.addDate,
      "manageSubscribe": _vm.manageSubscribe
    }
  }), _c('ModalLicenseSubscribeFail', {
    ref: "ModalLicenseSubscribeFail",
    on: {
      "manageSubscribe": _vm.manageSubscribe
    }
  }), _c('ModalLicenseSubscribe', {
    ref: "ModalLicenseSubscribe",
    on: {
      "complete": _vm.purchaseComplete
    }
  }), _c('ModalLicensePurchase', {
    ref: "ModalLicensePurchase",
    on: {
      "complete": _vm.purchaseComplete
    }
  }), _c('ModalLicenseAddMember', {
    ref: "ModalLicenseAddMember",
    on: {
      "complete": _vm.purchaseComplete
    }
  }), _c('ModalLicenseAddDate', {
    ref: "ModalLicenseAddDate",
    on: {
      "complete": _vm.purchaseComplete
    }
  }), _c('ModalPurchaseComplete', {
    ref: "ModalPurchaseComplete"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }