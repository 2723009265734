import Vue from 'vue'
import Router from 'vue-router'
import RoutePath from '@/utils/define/RoutePath'
import userAPI from '@/services/api/institute/user/index'
import axiosConfig from '@/services/axiosConfig'
import PageName from '@/utils/define/PageName'

// import createInstitution from '@/views/creation/pages/createInstitution.vue' // 기존 대규모 조직 생성 flow
import createInstitution from '@/views/creation/pages/createSmallInstitution.vue'
import gettingStart from '@/views/creation/pages/gettingStart.vue'
import registeringMember from '@/views/creation/pages/registeringMember.vue'
import excelRegisteringMember from '@/views/creation/pages/excelRegisteringMember.vue'
import changeInitPw from '@/views/creation/pages/changeInitPw.vue'
import startPro from '@/views/creation/pages/startPro.vue'
import MigrationRouter from '@/router/migration'
import Budget from '@/router/budget'
import Draft from '@/router/purchase/draft'
import Approval from '@/router/purchase/approval'
import Product from '@/router/purchase/product'
import Inventory from '@/router/inventory'
import Institute from '@/router/institute'
import cookies from 'vue-cookies'
// 2nd Gen
import PurchaseCargo from '@/router/purchase/cargo/index'

Vue.use(Router)

var allRoutes = []

var mainRoutes = []

var routes = [
	// {
	//     path: RoutePath.redirectToken,
	//     name: PageName.RedirectToken,
	//     component: () =>
	//         import(
	//             /* webpackChunkName: "lmpro-redirect-token" */ '@/views/app/pages/RedirectToken.vue'
	//         ),

	// },
	// {
	//     path: RoutePath.home,
	//     name: PageName.Home,
	//     component: () =>
	//         import(/* webpackChunkName: "lmpro-home" */ '@/views/App.vue'),
	//     // beforeEnter: loginCheck()
	// },
	{
		path: '*',
		redirect: to => {
			return { path: RoutePath.redirectToken }
		},
	},
	{
		path: RoutePath.dashboard,
		name: PageName.Dashboard,
		props: true,
		component: () => import(/* webpackChunkName: "lmpro-dashboard" */ '@/views/dashboard/pages/Dashboard.vue'),
		// beforeEnter: blockLoginUser,
	},
	// {
	// 	path: RoutePath.institute.information,
	// 	name: PageName.Institution.Information,
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "lmpro-institution-information" */ '@/views/institute/information/pages/InstitutionInfoPage.vue'
	// 			// `@/views/institute/information/pages/testpage.vue`
	// 		),
	// },
	{
		path: RoutePath.institute.organization,
		name: PageName.Institution.Organization,
		component: () => import(/* webpackChunkName: "lmpro-organization-tree" */ '@/views/institute/organization/pages/OrganizationPage.vue'),
	},
	{
		path: RoutePath.institute.organizationDetail,
		name: PageName.Institution.OrganizationDetail,
		component: () => import(/* webpackChunkName: "lmpro-storage-detail" */ '@/views/institute/location/pages/StorageDetailPage.vue'),
	},
	{
		path: RoutePath.institute.location,
		name: PageName.Institution.Location,
		component: () => import(/* webpackChunkName: "lmpro-location-storage" */ '@/views/institute/location/pages/LocationPage.vue'),
	},
	{
		path: RoutePath.institute.storageDetail,
		name: PageName.Institution.StorageDetail,
		component: () => import(/* webpackChunkName: "lmpro-storage-detail" */ '@/views/institute/location/pages/StorageDetailPage.vue'),
	},
	{
		path: RoutePath.institute.memberManagement,
		name: PageName.Institution.MemberManagement,
		component: () =>
			import(
				/* webpackChunkName: "lmpro-member-management" */
				'@/views/institute/member/pages/MemberList.vue'
			),
	},
	{
		path: RoutePath.institute.authoritySetting,
		name: PageName.Institution.AuthoritySetting,
		component: () => import(/* webpackChunkName: "lmpro-authority-setting" */ '@/views/institute/permission/pages/PermissionList.vue'),
	},
	{
		path: RoutePath.institute.authorityDetail,
		name: PageName.Institution.AuthorityDetail,
		component: () => import(/* webpackChunkName: "lmpro-authority-detail" */ '@/views/institute/permission/pages/PermissionDetail.vue'),
	},
	// {
	// 	path: RoutePath.inventory.whole,
	// 	name: PageName.Inventory.Whole,
	// 	component: () => import(/* webpackChunkName: "lmpro-inventory-category" */ '@/views/inventory/pages/Inventory.vue'),
	// },
	// {
	// 	path: RoutePath.inventory.category,
	// 	name: PageName.Inventory.List,
	// 	component: () => import(/* webpackChunkName: "lmpro-inventory-category" */ '@/views/inventory/category/pages/InventoryList.vue'),
	// },
	// {
	// 	path: RoutePath.inventory.detail,
	// 	name: 'InventoryDetail',
	// 	component: () => import(/* webpackChunkName: "lmpro-inventory-detail" */ '@/views/inventory/category/pages/InventoryDetail.vue'),
	// },
	// {
	// 	path: RoutePath.inventory.wait,
	// 	name: PageName.Inventory.Wait,
	// 	component: () => import(/* webpackChunkName: "lmpro-waiting-registration" */ '@/views/inventory/waiting/pages/WaitingRegistrationList.vue'),
	// },
	// {
	// 	path: RoutePath.inventory.waitDetail,
	// 	name: PageName.Inventory.WaitDetail,
	// 	component: () => import(/* webpackChunkName: "lmpro-waiting-registration-detail" */ '@/views/inventory/category/pages/InventoryDetail.vue'),
	// },
	// {
	// 	path: RoutePath.inventory.disposal,
	// 	name: PageName.Inventory.Disposal,
	// 	component: () => import(/* webpackChunkName: "lmpro-disposal" */ '@/views/inventory/disposal/pages/DisposalList.vue'),
	// },
	// {
	// 	path: RoutePath.inventory.disposalDetail,
	// 	name: PageName.Inventory.DisposalDetail,
	// 	component: () => import('@/views/inventoryGen2/detail/index'),
	// },
	// {
	// 	path: RoutePath.inventory.history,
	// 	name: PageName.Inventory.History,
	// 	component: () => import(/* webpackChunkName: "lmpro-history" */ '@/views/inventory/history/pages/HistoryList.vue'),
	// },
	// {
	// 	path: RoutePath.inventory.historyItem,
	// 	name: PageName.Inventory.HistoryItem,
	// 	component: () => import(/* webpackChunkName: "lmpro-history-item" */ '@/views/inventory/history/pages/HistoryList.vue'),
	// },

	// Tree Test : TODO 삭제
	{
		path: '/TreeSampleNonSelectable',
		name: 'TreeSampleNonSelectable',
		component: () => import(/* webpackChunkName: "lmpro-organization-tree" */ '@/views/common/components/tree/TreeSampleNonSelectable.vue'),
	},
	{
		path: '/TreeSampleSelectable',
		name: 'TreeSampleSelectable',
		component: () => import(/* webpackChunkName: "lmpro-organization-tree" */ '@/views/common/components/tree/TreeSampleSelectable.vue'),
	},
	{
		path: '/:instituteId/safety/msds',
		name: PageName.Safety.MSDS,
		component: () => import(/* webpackChunkName: "lmpro-safety-msds" */ '@/views/safety/msds/pages/SafetyMSDS.vue'),
	},
	{
		path: RoutePath.safety.dangerous,
		name: PageName.Safety.DangerousStorage,
		component: () =>
			import(/* webpackChunkName: "lmpro-safety-dangerous-quantity" */ '@/views/safety/dangerStorage/pages/SafetyDangerStorage.vue'),
	},
	{
		path: RoutePath.safety.dangerousDetail,
		name: PageName.Safety.DangerousStorageDetail,
		component: () => import('@/views/inventoryGen2/list/index'),
	},
	{
		path: RoutePath.safety.dangerousInfo,
		name: PageName.Safety.DangerousStorageInfo,
		component: () => import('@/views/inventoryGen2/detail/index'),
	},
	{
		path: RoutePath.safety.management,
		name: PageName.Safety.ManagementMaterials,
		component: () =>
			import(/* webpackChunkName: "lmpro-safety-special-management" */ '@/views/safety/management/pages/SafetyManagementMaterials.vue'),
	},
	{
		path: RoutePath.safety.managementDetail,
		name: PageName.Safety.ManagementMaterialsDetail,
		component: () => import('@/views/inventoryGen2/list/index'),
	},
	{
		path: RoutePath.safety.managementInfo,
		name: PageName.Safety.ManagementInfo,
		component: () => import('@/views/inventoryGen2/detail/index'),
	},
	{
		path: RoutePath.safety.chemical,
		name: PageName.Safety.ChemicalMaterials,
		component: () => import(/* webpackChunkName: "lmpro-safety-hazardous-chemical" */ '@/views/safety/chemical/pages/SafetyChemicals.vue'),
	},
	{
		path: RoutePath.safety.chemicalDetail,
		name: PageName.Safety.ChemicalMaterialsDetail,
		component: () => import('@/views/inventoryGen2/list/index'),
	},
	{
		path: RoutePath.safety.chemicalInfo,
		name: PageName.Safety.ChemicalInfo,
		component: () => import('@/views/inventoryGen2/detail/index'),
	},
	{
		path: RoutePath.safety.health,
		name: PageName.Safety.HealthCheck,
		component: () => import(/* webpackChunkName: "lmpro-safety-check" */ '@/views/safety/diagnosis/pages/SafetyHealthCheckup.vue'),
	},
	{
		path: RoutePath.safety.detail,
		name: PageName.Safety.HealthCheckDetail,
		component: () => import(/* webpackChunkName: "lmpro-inventory-detail" */ '@/views/safety/diagnosis/pages/SafetyHealthCheckupDetail.vue'),
	},
	{
		path: RoutePath.safety.legal,
		name: PageName.Safety.LegalProduct,
		component: () => import(/* webpackChunkName: "lmpro-safety-legal-product" */ '@/views/safety/legal/pages/SafetyLegalProduct.vue'),
	},
	{
		path: RoutePath.safety.legalDetail,
		name: PageName.Safety.LegalProductDetail,
		component: () => import('@/views/inventoryGen2/list/index'),
	},
	{
		path: RoutePath.create,
		name: PageName.creation.create,
		component: createInstitution,
	},
	{
		path: '/:instituteId/gettingStart',
		name: 'gettingStart',
		component: gettingStart,
	},
	{
		path: '/:instituteId/registeringMember',
		name: 'registeringMember',
		component: registeringMember,
	},
	{
		path: '/:instituteId/excelRegisteringMember',
		name: 'excelRegisteringMember',
		component: excelRegisteringMember,
	},
	{
		path: '/changeInitPw/:code',
		name: 'changeInitPw',
		component: changeInitPw,
	},
	{
		path: '/:instituteId/startPro',
		name: 'startPro',
		component: startPro,
	},
	{
		path: RoutePath.invite.accept,
		name: PageName.Invite.Receive,
		component: () => import(/* webpackChunkName: "lmpro-safety-legal-product" */ '@/views/invite/receive/pages/ReceiveInvitePage.vue'),
	},
	{
		path: RoutePath.invite.expire,
		name: PageName.Invite.Expire,
		component: () => import(/* webpackChunkName: "lmpro-safety-legal-product" */ '@/views/invite/receive/pages/LicenseExpirePage.vue'),
	},
]

mainRoutes = mainRoutes.concat(routes)
mainRoutes = mainRoutes.concat(MigrationRouter)
mainRoutes = mainRoutes.concat(Budget)
mainRoutes = mainRoutes.concat(Draft)
mainRoutes = mainRoutes.concat(Approval)
// mainRoutes = mainRoutes.concat(Deadline)
mainRoutes = mainRoutes.concat(PurchaseCargo)
mainRoutes = mainRoutes.concat(Product)
mainRoutes = mainRoutes.concat(Inventory)
mainRoutes = mainRoutes.concat(Institute)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: mainRoutes,
})

router.beforeEach(function (to, from, next) {
	if (to.params.instituteId) cookies.set('instituteId', to.params.instituteId)
	next()
})

const CODE_NO_INSTITUTION = '05007'

const goToDashboard = async (isToHome, next) => {
	axiosConfig.initAxios()
	const response = await userAPI.lookupUserInstInfo()
	const instituteId = response.data.id

	if (isToHome) {
		return next({
			name: PageName.Dashboard,
			params: {
				instituteId: instituteId,
			},
		})
	}
}

const commonUserFlow = async (isToHome, next) => {
	try {
		goToDashboard(isToHome, next)
	} catch (error) {
		if (isToHome && error.response.data.code === CODE_NO_INSTITUTION) {
			return next({
				name: PageName.creation.create,
			})
		}
	}
}

// router.beforeEach(async (to, from, next) => {
//     const token = to.query.token;
//     const isExperience = process.env.VUE_APP_MODE === APP_MODE_EXPERIENCE
//     const isDevExperience = process.env.VUE_APP_MODE === APP_MODE_DEV_EXPERIENCE
//     const modeExperience = isExperience || isDevExperience;

//     const devToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExZWI2ZjRjNDRmZDMzY2MiLCJpYXQiOjE2NDg0NjQ1MzIsImV4cCI6MTY1MzY0ODUzMn0.sag_lRm_6MdWu2iHMl6jrqiQa3SnW2OExiyplWhSezc'
//     // 개발 테스트 중일때는 직접 토큰 넣어주어야 함

//     const infiniteToken = isExperience ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExZWM5MjZmYjRlYzU2ODUiLCJpYXQiOjE2NDUzNzcxNTcsImV4cCI6MzUzODgzMzE1N30.XRujeEAeVuINKkVAEpIFuTPh_Cv9bhOkm5COuTsOv8c" : devToken

//     let isToHome = to.name === 'Home';
//     if(modeExperience){
//         // 둘러보기 실서버
//         setToken(infiniteToken);
//         goToDashboard(isToHome, next);
//     }

//     if(to.path.includes('changeInitPw')){
//         next();
//     }
//     // SSO를 통해 로그인한 경우
//     else if (token) {
//         setToken(token)
//         axiosConfig.initAxios()
//         await userAPI.login()
//         commonUserFlow(isToHome, next)

//     } else if(isLogin()) {
//         // 토큰이 있는 경우
//         axiosConfig.initAxios()
//         commonUserFlow(isToHome, next)
//     } else {
//         // 아무것도 아닐 때
//         // Action : SSO로 리다이렉트
//         next({
//             path : RoutePath.sso
//         })
//     }

//     next();
// })
export default router
