<template>
	<button class="regist-method-button" :class="{ active: active }" @click="$emit('click')">
		<img :src="image" width="40" height="40" />
		<div class="content">
			<p class="title">{{ tt(title) }}</p>
			<p class="subtext">{{ tt(subtext) }}</p>
		</div>
	</button>
</template>
<script>
export default {
	name: 'RegistMethodItem',
	components: {},
	props: {
		image: {
			type: String,
			default: null,
		},
		active: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		subtext: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.regist-method-button + .regist-method-button {
	border-top: 1px solid $INPUT;
}
.regist-method-button {
	display: flex;
	width: 100%;
	padding: 10px;
	min-height: 60px;
	&:last-child {
		&.active {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}
	&.active {
		background: $SUB-YELLOW_100;

		&::after {
			content: '';
			background-image: url(~@/views/inventoryGen2/svg/create/item-check.svg);
			background-repeat: no-repeat;
			width: 24px;
			height: 24px;
			padding-right: 5px;
		}
	}
	.content {
		display: flex;
		width: 100%;
		flex-direction: column;
		padding-left: 10px;
		align-items: flex-start;
		.title {
			font-size: 14px;
			font-weight: 700;
		}
		.subtext {
			font-size: 12px;
			font-weight: 400;
		}
	}
}
</style>
