<template>
	<modal-filter ref="modal" @confirm="confirm" @reset="reset">
		<template #modal-content v-if="filter">
			<filter-list ref="filter-group" title="참여 그룹" :filterCookieName="filterCookieName.group" :list="filter.participatingGroups">
			</filter-list>
		</template>
	</modal-filter>
</template>
<script>
import ModalFilter from '@/views/common/components/modalGen2/ModalFilterBase.vue'
import FilterList from '@/views/common/components/modalGen2/components/filter/FilterList.vue'
import { mapActions, mapState } from 'vuex'
export default {
	components: {
		ModalFilter,
		FilterList,
	},
	computed: {
		...mapState('budget/list', ['filter', 'filterCookieName']),
	},
	methods: {
		...mapActions('budget/list', ['getFilter']),
		async show() {
			if (this.filter == null) await this.getFilter()
			this.$refs['modal'].show()
		},
		confirm() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.setCookies !== 'undefined') it.setCookies()
			})
			let params = {
				groupsId: this.$refs['filter-group'].checkedList ?? null,
			}
			this.$emit('changeLoadParams', params)
			this.$refs['modal'].hide()
			this.$root.toast('필터', '필터가 적용되었습니다.', 'success')
		},
		reset() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.reset !== 'undefined') it.reset()
			})
		},
	},
}
</script>
<style lang="scss" scoped></style>
