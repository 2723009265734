var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-wrap"
  }, [_c('span', [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('ul', _vm._l(_vm.list, function (item, index) {
    return _c('li', {
      key: "category".concat(index),
      ref: "list",
      refInFor: true,
      "class": {
        active: item.id === _vm.activeId
      },
      on: {
        "click": function click($event) {
          return _vm.detailCategory(item.id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/views/inventoryGen2/svg/category/".concat(item.type, ".svg"))
      }
    }), _c('p', [_vm._v(_vm._s(_vm.tt(item.name)))])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }