export const addressFinderOpen = (window, lanaguageType) => {
	var width = 500 //팝업의 너비
	var height = 600 //팝업의 높이
	return new Promise(resolve => {
		window.daum.postcode.load(function () {
			/* eslint-disable */
			new daum.Postcode({
				width: width, //생성자에 크기 값을 명시적으로 지정해야 합니다.
    			height: height,
				oncomplete: function (data) {
					resolve(data);
				},
			}).open();
		});
	});
};
