const state = {
	count: 0,
	selected: 0,
	text: '',
}

const getters = {
	selected: state => state.selected,
	count: state => state.count,
	text: state => state.text,
}

const mutations = {
	setCount: (state, count) => (state.count = count),
	setSelected: (state, index) => (state.selected = index),
	setText: (state, text) => (state.text = text),
	initData: state => {
		state.count = 0
		state.selected = 0
		state.text = ''
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
}
