<template>
	<div class="Search-box">
		<input
			type="text"
			:placeholder="placeholder"
			v-model="inputValue"
			v-debounce:450="changeInput"
			@keypress.enter="search"
			ref="search-input"
			:class="[{ searchInput: isScan }, { useQr: useQr && inputValue.length > 0 }]"
			:readonly="isScan"
			@click.stop="showRecent"
		/>
		<!-- <div class="keyword-wrapper" v-if="$root.isSearchRecentOn && useRecentKeywords">
			<inventory-tag-keywords v-if="tagSearchList.length > 0" :tagSearchList="tagSearchList" @clickTag="clickTag"></inventory-tag-keywords>
			<inventory-recent-keywords
				v-if="useRecentKeywords"
				:recentKeywords="newKeywords"
				:classification="classification"
				:isShow="$root.isSearchRecentOn"
				@clickItem="clickItem"
				@removeItem="removeItem"
				@removeAllItem="removeAllItem"
			></inventory-recent-keywords>
		</div> -->
		<button class="resetBtn">
			<img v-if="isScan" src="@/assets/svg/Close-2.svg" @click="resetCode" />
			<img v-else-if="inputValue.length > 0" @click="resetSearch" src="@/assets/svg/Close-1.svg" />
			<img v-if="useQr && !isScan" @click="showCode" src="@/assets/svg/QR Scan.svg" />
		</button>
		<button type="button" @click="search" style="margin-left: auto">
			<img src="@/assets/svg/group-search.svg" />
		</button>
	</div>
</template>
<script>
// import InventoryRecentKeywords from '@/views/inventory/components/InventoryRecentKeywords.vue'
// import InventoryTagKeywords from '@/views/inventory/components/InventoryTagKeywords.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
	components: {
		// InventoryRecentKeywords,
		// InventoryTagKeywords,
	},
	props: {
		placeholder: {
			type: String,
		},
		useQr: {
			type: Boolean,
			default: false,
		},
		isScan: {
			type: Boolean,
			default: false,
		},
		keyword: {
			type: String,
			default: '',
		},
		useRecentKeywords: {
			type: Boolean,
			default: false,
		},
		classification: {
			type: String,
			default: '',
		},
		loadList: {
			type: Function,
			default: () => {},
		},
		categoryId: {
			type: Array,
			default: () => [],
		},
		keywordMaxCount: {
			type: Number,
			default: 20,
		},
	},
	async mounted() {
		this.$root.isSearchRecentOn = false
		if (this.listKeyword && this.listKeyword.length > 0 && this.useRecentKeywords) {
			this.inputValue = this.listKeyword
		}
		this.recentKeywordCookieKey = `${this.$route.params.instituteId}_${this.classification}`
		const params = {
			instId: this.$route.params.instituteId,
			keyword: this.listKeyword,
			offset: 0,
			length: 10,
			categoriesId: this.categoryId,
		}
		await this.lookupSearchTagList(params)
		this.initTagList = JSON.parse(JSON.stringify(this.searchTagList.list))
	},
	data() {
		return {
			inputValue: '',
			displayRecentKeywords: false,
			recentKeywords: [],
			newKeywords: [],
			tagSearchList: [],
			recentKeywordCookieKey: null,
			initTagList: [],
		}
	},
	computed: {
		...mapState('institutionInformation', ['listKeyword']),
		...mapState('inventory', ['searchTagList']),
	},
	watch: {},
	methods: {
		...mapMutations('institutionInformation', ['setKeyword']),
		...mapActions('inventory', ['lookupSearchTagList']),
		saveRecentKeyword(keyword) {
			let emptyPattern = /\s/g
			if (this.recentKeywords.indexOf(keyword) < 0 && keyword.replace(emptyPattern, '')) {
				this.recentKeywords.push(keyword)
				this.newKeywords.push(keyword)
				if (this.recentKeywords.length > this.keywordMaxCount) this.recentKeywords.shift()
				this.$cookies.set(this.recentKeywordCookieKey, JSON.stringify(this.recentKeywords))
			}
		},
		loadRecentKeywords() {
			let recentKeywords = this.$cookies.get(this.recentKeywordCookieKey)
			this.recentKeywords = recentKeywords ? [...JSON.parse(recentKeywords)] : []
			this.newKeywords = this.recentKeywords
		},
		async changeInput(event) {
			// this.inputValue = event.target.value
			await this.setKeyword(this.inputValue)
			if (this.useRecentKeywords) {
				if (!this.$root.isSearchRecentOn) {
					// 드래그 후 검색영역 밖에서 마우스를 땟을때,
					// 최근검색영역이 표시안됨현상 편집
					this.$root.isSearchRecentOn = true
				}
				this.newKeywords = this.recentKeywords.filter(v => v.includes(this.inputValue))
			}
			if (this.inputValue == '') {
				this.loadList(this.inputValue)
			}
			this.$emit('inputValue', this.inputValue)
			this.search()
		},
		search() {
			// #태그로 검색들어오면 tag로 인식되어야하나? --> 물어볼거
			// if(this.inputValue.indexOf('#') != -1 && this.searchTagList.find(x => x.name == this.inputValue)) {
			//     // 성공! -> 검색중인 검색어가 #이 들어가있고 , (and) 내가 보유한 searchTagList에 입력한 검색어가 포함되어있는지?
			//     console.log('this.inputValue', this.inputValue);
			// } else {
			//     // 추천검색 or 내가 보유한 searchTagList에 없는 태그를 검색했을때
			// }
			// END
			let emptyPattern = /\s/g
			if (!this.listKeyword.replace(emptyPattern, '')) {
				this.setKeyword('')
			}
			this.loadList(this.inputValue)
			this.saveRecentKeyword(this.inputValue)
			this.$emit('search')
		},
		resetSearch() {
			this.$refs['search-input'].value = ''
			this.inputValue = this.$refs['search-input'].value
			this.setKeyword('')
			this.$emit('reset')
			this.loadList(this.inputValue)
		},
		showCode() {
			this.$emit('showCode')
		},
		resetCode() {
			this.$emit('resetCode')
		},
		async showRecent() {
			this.loadRecentKeywords()
			const params = {
				instId: this.$route.params.instituteId,
				keyword: this.listKeyword,
				offset: 0,
				length: 10,
				categoriesId: this.categoryId,
			}
			await this.lookupSearchTagList(params)
			this.tagSearchList = this.searchTagList.list
			this.$root.isSearchRecentOn = true
		},
		hideRecent() {
			this.$root.initStateOnMenu()
		},
		async showTag() {},
		clickItem(keyword) {
			this.$emit('clickItem', keyword)
			this.inputValue = keyword
			this.hideRecent()
		},
		removeItem(keyword) {
			this.recentKeywords = [...this.recentKeywords.filter((item, itemIndex) => item !== keyword)]
			this.newKeywords = [...this.newKeywords.filter((item, itemIndex) => item !== keyword)]
			this.$cookies.set(this.recentKeywordCookieKey, JSON.stringify(this.recentKeywords))
		},
		removeAllItem() {
			this.newKeywords = []
			this.$cookies.set(this.recentKeywordCookieKey, '')
		},
		clickTag(id) {
			this.$emit('clickTag', id)
			let searchValue = this.tagSearchList.find(x => x.id == id).name
			if (searchValue) {
				this.inputValue = '#' + searchValue
			}
			this.hideRecent()
		},
	},
}
</script>
<style lang="scss" scoped>
.Search-box {
	position: relative;
	z-index: 30;
	.search-input::-webkit-input-placeholder {
		/* background-image: url(~@/assets/svg/QR Scan.svg) */
		background-image: url(~@/assets/svg/QR-Scan.svg);
		background-repeat: no-repeat;
		background-position: -5px center;
		padding-left: 18px;
		font-size: inherit;
	}
	.useQr {
		width: calc(100% - 78px);
	}
	.resetBtn {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 0;
		margin-right: 5px;
		img {
			width: 24px;
			height: 24px;
		}
	}
}
</style>
