<template>
	<li
		class="StorageBox__item"
		:class="[
			{ cursor: !isStart },
			{
				shortHeight:
					(isOrganization && mode == 2) || (userPermission.storage && !userPermission.storage.update && !userPermission.storage.delete),
			},
		]"
		@click="!isStart ? goToStorageDetail(storageInfo.id) : ''"
		ref="storagebox"
		:draggable="!isOrganization"
		@drag.self="storageDrag($event, storageInfo)"
		@dragstart="storageDragstart($event, storageInfo)"
		@dragend.self="storageDragend($event, storageInfo)"
	>
		<div :id="`storageData_${storageInfo.id}`">
			<div class="StorageBox__item-title">
				<h5>
					{{ storageInfo.name }}
					<img v-if="storageInfo.isNew && !isClicked" src="@/assets/svg/new.svg" />
					<!-- <img src="@/assets/svg/input-symbol-error.svg" alt="icon" /> -->
				</h5>
				<p>
					{{ storageInfo.assigneeGroup ? storageInfo.assigneeGroup.name : ' ' }}
				</p>
			</div>
			<div class="StorageBox__item-admin">
				<h6>{{ tt('관리자') }}</h6>
				<ul class="StorageBox__warning-list">
					<li>
						{{ tt('정') }}:
						<span v-if="storageInfo.assignee">{{ showAssigneeName }}</span>
					</li>
					<li>
						{{ tt('부') }}:
						<span v-if="storageInfo.subAssignee">{{ showSubassigneeName }}</span>
					</li>
				</ul>
			</div>
			<div class="StorageBox__item-count">
				<h6>{{ tt('물품 총 수량') }}</h6>
				<div class="count">
					{{ storageInfo.itemTotalCount + tt('개') }}
				</div>
			</div>
			<div class="StorageBox__item-admin">
				<h6>{{ tt('경고 표지') }}</h6>
				<ul class="StorageBox__item-warning StorageBox__item-admin" v-if="storageInfo">
					<li v-for="warnImg in storageInfo.warningSignImages.slice(0, showMaxImgLength)" :key="`${warnImg}`">
						<img :src="warnImg" alt="경고icon" />
					</li>
					<li v-if="storageInfo.warningSignImages.length > showMaxImgLength">
						<img src="@/assets/svg/exceed-sign.svg" />
					</li>
					<li v-if="storageInfo.warningSignImages.length == 0" style="display: block; width: 100%">
						<span style="display: flex; width: 42px; height: 42px; font-size: 14px; color: #999; align-items: center">{{
							tt('없음')
						}}</span>
					</li>
				</ul>
			</div>
			<div class="StorageBox__item-button-group" v-if="mode != 2" style="width: 100%">
				<button class="btn" @click.stop="editStorage" v-if="userPermission.storage && userPermission.storage.update">
					<img src="@/assets/svg/button-icon-edit.svg" alt="icon" />{{ tt('편집') }}
				</button>
				<button class="btn" @click.stop="deleteStorage" v-if="userPermission.storage && userPermission.storage.delete">
					<img src="@/assets/svg/button-icon-delete.svg" alt="icon" />{{ tt('삭제') }}
				</button>
			</div>
		</div>
	</li>
</template>

<script>
import PageName from '@/utils/define/PageName'
import MixinStorageItemDraggable from '@/mixins/storageItemDraggable'
import { mapMutations, mapState } from 'vuex'
import { isClickedInstitution } from '@/store/modules/isClickedItem'

export default {
	name: 'StorageItem',
	mixins: [MixinStorageItemDraggable],
	props: {
		storageInfo: {
			type: Object,
		},
		isOrganization: {
			type: Boolean,
			default: false,
		},
		isStart: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: Number,
			default: 0,
		},
	},
	mounted() {
		this.showMaxImgLength = this.isStart ? 3 : 4
	},
	data() {
		return {
			showMaxImgLength: 4,
		}
	},
	computed: {
		...mapState('location', ['selectedLocation']),
		showAssigneeName() {
			if (this.storageInfo.assignee != null) return this.storageInfo.assignee.name
		},
		showSubassigneeName() {
			if (this.storageInfo.subAssignee != null) return this.storageInfo.subAssignee.name
		},
		isClicked() {
			let result = false
			let clickedList = localStorage.getItem('storage') ? localStorage.getItem('storage').split(',') : []
			if (clickedList.includes(this.storageInfo.id)) {
				result = true
			}

			return result
		},
	},
	methods: {
		...mapMutations('storage', ['setEditStorageId']),
		goToStorageDetail(storageId) {
			if (!isClickedInstitution['storage'].includes(storageId) && this.storageInfo.isNew) {
				isClickedInstitution['storage'].push(storageId)
			}

			localStorage.setItem('storage', isClickedInstitution['storage'].join())

			if (this.$route.path.indexOf('organization') >= 0) {
				this.$router.push({
					name: PageName.Institution.OrganizationDetail,
					params: { storageId: storageId },
				})
			} else {
				this.$router.push({
					name: PageName.Institution.StorageDetail,
					params: { storageId: storageId },
				})
			}
		},
		editStorage() {
			this.setEditStorageId(this.storageInfo.id)
			this.$emit('editStorage', this.storageInfo, 'EDIT')
		},
		deleteStorage() {
			this.$emit('deleteStorage', this.storageInfo)
		},
		refresh(locationId) {
			this.$emit('refresh', locationId)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_storage-box.scss';

.no-warning-sign {
	display: flex;
	align-items: center;
	color: $PLACEHOLDER;
	width: 100%;
	height: 42px;
	font-size: 13px;
}

.cursor {
	cursor: pointer;
}

.shortHeight {
	height: 275px !important;
}

.dragging {
	cursor: move !important;
}
</style>
