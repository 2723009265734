<template>
	<div class="header-wrapper">
		<h2 class="header-title">{{ tt(title) }}</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<draft-search-box
					class="search-box"
					:value="keyword"
					:placeholder="tt('문서번호, 기안 제목, 연구실으로 검색')"
					@change="changeKeyword"
				></draft-search-box>
				<image-button
					:text="filterText"
					:image="require('@/assets/svg/filter.svg')"
					:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
					@click="filterBtnClick"
					:isActive="filteredCount > 0"
				></image-button>
				<date-picker-button
					:image="require('@/assets/svg/list/inventory-calendar.svg')"
					:activeImage="require('@/assets/svg/list/active/calendar-active.svg')"
					:text="'기안상신일'"
					:subText="dateButtonSubtext"
					:defaultTime="calendarDatetime"
					:isActive="dateButtonSubtext != null"
					@pickDate="pickDate"
					@changeDate="changeDate"
				></date-picker-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button
					:text="'보기옵션'"
					:image="require('@/assets/svg/ViewOption(Button).svg')"
					@click="visibleOptionBtnClick"
				></image-button>
				<excel-download-button @downloadAll="exportList('ALL')" @downloadFiltered="exportList('FILTERED')" />
				<image-button
					v-if="$root.userPermission.draft.create"
					:text="'지출결의서 작성'"
					:image="require('@/assets/svg/list/pencil.svg')"
					@click="registBtnClick"
				></image-button>
			</div>
		</div>
		<div class="status-btn-wrapper">
			<draft-list-filter-tab
				v-for="(status, i) in filterTabButton"
				:isActive="status.id === selectedStatus"
				:text="status.name"
				:key="`status-${i}`"
				@click="changeActiveStatus(status.id)"
			></draft-list-filter-tab>
		</div>
		<!-- 모달 5종세트 -->
		<modal-filter ref="modal-filter" @changeLoadParams="changeLoadParams" />
		<modal-view-option ref="modal-view-option" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DraftSearchBox from '@/views/components/searchBox'
import ImageButton from '@/views/common/components/baseButton/ImageButton.vue'
import DatePickerButton from '@/views/common/components/baseButton/DatePickerButton.vue'
import DraftListFilterTab from '@/views/common/components/buttons/ListFilterButton.vue'
import PageName from '@/utils/define/PageName'
import ExcelDownloadButton from '@/views/common/components/baseButton/ExcelDownloadButton.vue'
import ModalViewOption from '../modal/ModalViewOption.vue'
import ModalFilter from '../modal/ModalFilter.vue'
export default {
	components: {
		DraftSearchBox,
		ImageButton,
		DatePickerButton,
		DraftListFilterTab,
		ExcelDownloadButton,
		ModalViewOption,
		ModalFilter,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		dateButtonSubtext: {
			type: String,
			default: '',
		},
		calendarDatetime: {
			type: Array,
			default: [],
		},
	},
	computed: {
		...mapGetters('draft/list', ['keyword', 'selectedStatus', 'filteredCount']),
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
		filterTabButton() {
			return [
				{ id: 'ALL', name: '전체' },
				{ id: 'ING', name: '진행중' },
				{ id: 'ACCEPT', name: '승인' },
				{ id: 'REJECT', name: '반려' },
			]
		},
	},
	data() {
		return {
			// 선택된 Tab - 전체 , 진행중 , 승인 , 반려
			isActiveCalendar: false,
		}
	},
	methods: {
		...mapActions('draft/list', ['exportExcel']),
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		visibleOptionBtnClick() {
			this.$refs['modal-view-option'].show()
		},
		registBtnClick() {
			if (this.$root.userPermission.draft?.create)
				this.$router.push({
					name: PageName.Purchase.DraftRegistration,
					params: {
						mode: 'write',
					},
				})
			else this.$root.toast('권한', '지출결의서 작성 권한이 없습니다.', 'error')
		},
		pickDate() {
			this.$root.toast(this.tt('기간 필터 적용 완료'), this.tt('기간 필터가 적용되었습니다.'), 'success')
		},
		changeDate(date) {
			this.isActiveCalendar = true
			this.$emit('changeDate', date)
		},
		changeActiveStatus(id) {
			const status = id === 'ALL' ? [] : [id]
			this.changeLoadParams({ draftStatus: status })
		},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword })
		},
		changeLoadParams(params) {
			this.$emit('changeLoadParams', params)
		},
		async exportList(type) {
			const response = await this.exportExcel(type)
			window.fileDownload(response, `${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('기안 목록')}.xlsx`)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/list/_list-header.scss';
</style>
