<template>
	<div class="list-wrapper" v-if="list.length > 0">
		<div class="title-wrapper">
			<h5>{{ tt(title) }}</h5>
			<button @click="reset">{{ tt('초기화') }}</button>
		</div>
		<div class="check-list">
			<label class="check-item"
				><input class="checkbox" type="checkbox" @change="checkedAll" :checked="checkedList.length == list.length" />
				{{ tt('전체선택') }}</label
			>
			<label class="check-item" v-for="item in showingList" :key="item.id" v-b-tooltip.hover="tt(item.name)">
				<input class="checkbox" type="checkbox" @change="checked(item.id)" :checked="checkedList.includes(item.id)" />
				<img class="image" v-if="hasImage" :src="item.image ? item.image : defaultImage" />
				<div v-html="replaceHighlight(item.name)"></div>
			</label>
		</div>
		<div class="show-all" :class="{ open: isShowAll }" v-if="list.length >= 9" @click="isShowAll = !isShowAll">
			{{ tt(isShowAll ? '접기' : '모두보기') }}
		</div>
	</div>
</template>
<script>
import StringUtils from '@/utils/modules/StringUtils'
import { mapState, mapGetters } from 'vuex'
export default {
	props: {
		title: {
			type: String,
		},
		list: {
			type: Array,
			default: () => [],
		},
		filterCookieName: {
			type: String,
		},
		hasImage: {
			type: Boolean,
			default: false,
		},
		defaultImage: {
			type: String,
		},
	},
	data() {
		return {
			checkedList: [],
			isShowAll: false,
		}
	},
	watch: {
		text() {
			if (this.list.findLastIndex(it => it.name.toLowerCase().includes(this.text.toLowerCase())) > 8 && this.text.length > 0) {
				this.isShowAll = true
			} else if (this.text.length <= 0) this.isShowAll = false
		},
	},
	computed: {
		...mapGetters('filter', ['text']),
		showingList() {
			if (this.isShowAll) return this.list
			else return this.list.slice(0, 8)
		},
		countByText() {
			return this.list.filter(it2 => it2.name.toLowerCase().includes(this.text.toLowerCase())).length
		},
	},
	created() {
		if (this.filterCookieName) {
			this.checkedList = JSON.parse(this.$cookies.get(this.filterCookieName)) ?? []
		}
		if (this.list.slice(8).some(it => this.checkedList.includes(it.id))) {
			this.isShowAll = true
		}
	},
	methods: {
		reset() {
			this.checkedList = []
		},
		checkedAll() {
			if (this.checkedList.length == this.list.length) this.checkedList = []
			else this.checkedList = this.list.map(it => it.id)
		},
		checked(id) {
			if (this.checkedList.includes(id)) this.checkedList.remove(id)
			else this.checkedList.push(id)
		},
		setCookies() {
			if (this.filterCookieName == null) return
			this.$cookies.set(this.filterCookieName, JSON.stringify(this.checkedList))
		},
		replaceHighlight(value) {
			return StringUtils.replaceHighlight(value, this.text, 'span', 'highlight')
		},
	},
}
</script>
<style scoped lang="scss">
.list-wrapper {
	// padding: 25px 20px;
	border-bottom: 1px solid $DIVIDER;
	padding-bottom: 30px;
	margin-bottom: 30px;
	.title-wrapper {
		display: flex;
		align-items: center;
		h5 {
			font-size: 16px;
		}
		button {
			justify-content: center;
			margin-left: auto;
			width: 60px;
			height: 30px;
			border-radius: 4px;
			background-color: $GREY_4;
			color: $DEFAULT;
			font-size: 14px;
			font-weight: 700;
		}
	}
	.check-list {
		width: 100%;
		margin-top: 10px;
		display: inline-flex;
		flex-wrap: wrap;
		gap: 10px;
		.check-item {
			cursor: pointer;
			padding-left: 2px;
			align-items: center;
			display: flex;
			gap: 10px;
			font-size: 14px;
			flex-basis: calc((100% - 20px) / 3);
			width: calc((100% - 20px) / 3);
			img {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				object-fit: cover;
			}
			div {
				width: calc(100% - 34px);
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
	.show-all {
		width: fit-content;
		position: relative;
		cursor: pointer;
		font-weight: 700;
		color: $BLUE;
		font-size: 14px;
		margin-top: 10px;
		&::after {
			content: '';
			width: 16px;
			height: 16px;
			position: absolute;
			right: -18px;
			top: 3px;
			background-image: url(~@/assets/svg/arrow-b.svg);
			opacity: 0.8;
			scale: 0.8;
		}
		&.open::after {
			transform: rotate(180deg);
		}
	}
}
::v-deep {
	.highlight {
		background-color: $SUB-YELLOW_100;
		&.active {
			background-color: #ff3b314d;
		}
	}
}
</style>
