<template>
	<div class="info-wrap">
		<p class="info-header">{{ tt('업로드한 이미지') }}</p>
		<div class="image-list">
			<img @click="$refs['image-viewer'].show(idx)" width="110px" height="150px" v-for="(image, idx) in images" :src="image" :key="image" />
		</div>
		<modal-multi-image-viewer ref="image-viewer" title="이미지" :imageList="images"></modal-multi-image-viewer>
	</div>
</template>
<script>
import ModalMultiImageViewer from '@/views/common/components/modalGen2/ModalMultiImageViewer.vue'
export default {
	name: 'ImageField',
	components: { ModalMultiImageViewer },
	props: {
		images: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.image-list {
		display: flex;
		gap: 18px;
		flex-wrap: wrap;

		img {
			border-radius: 8px;
			cursor: pointer;
			object-fit: cover;
		}
	}
}
</style>
