const Modal = {
	namespaced: true,
	state: () => ({
		_accountSettingOn: false,
		_licenseManagement: false,
	}),
	getters: {
		getAccountSettingOn: state => state._accountSettingOn,
		getLicenseManagement: state => state._licenseManagement,
	},
	mutations: {
		setAccountSettingOn: (state, payload) => {
			state._accountSettingOn = payload
		},
		setLicenseManagement: (state, payload) => {
			state._licenseManagement = payload
		},
	},
	actions: {},
}

export default Modal
