import { render, staticRenderFns } from "./DraftRegistrationStep2.vue?vue&type=template&id=3da6a314&scoped=true&"
import script from "./DraftRegistrationStep2.vue?vue&type=script&lang=js&"
export * from "./DraftRegistrationStep2.vue?vue&type=script&lang=js&"
import style0 from "./DraftRegistrationStep2.vue?vue&type=style&index=0&id=3da6a314&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da6a314",
  null
  
)

export default component.exports