var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "filter-btn",
    "class": {
      active: _vm.isActive
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.text)) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }