import budgetAPI from '@/services/api/budget'
import _ from 'lodash'
const state = {
	item: null,
	registrationInfo: {
		budgetType: null,
		year: null,
		code: null,
		name: null,
		groups: [],
		groupsId: [],
		budget: {
			year: null,
			jan: null,
			feb: null,
			mar: null,
			apr: null,
			may: null,
			jun: null,
			jul: null,
			aug: null,
			sep: null,
			oct: null,
			nov: null,
			dec: null,
		},
	},
}

const getters = {
	payload: state => {
		let payload = { ...state.registrationInfo }
		payload.groupsId = state.registrationInfo.groups.map(g => g.id)
		return payload
	},
	budgetType: state => {
		return state.registrationInfo.budgetType
	},
	year: state => {
		return state.registrationInfo.year
	},
	budget: state => {
		return state.registrationInfo.budget
	},
	name: state => {
		return state.registrationInfo.name
	},
}

const mutations = {
	setItem: (state, data) => {
		state.item = data
	},
	initRegistrationInfo: state => {
		state.registrationInfo = {
			budgetType: null,
			year: null,
			code: null,
			name: null,
			groupsId: [],
			groups: [],
			budget: {
				year: null,
				jan: null,
				feb: null,
				mar: null,
				apr: null,
				may: null,
				jun: null,
				jul: null,
				aug: null,
				sep: null,
				oct: null,
				nov: null,
				dec: null,
			},
		}
	},
	setRegistrationInfo: (state, info) => {
		Object.entries(info).forEach(([key, value]) => {
			if (key != 'budget') state.registrationInfo[key] = value
			else {
				Object.entries(value).forEach(([key2, value2]) => {
					state.registrationInfo.budget[key2] = value2 ? parseInt(value2) : null
				})
			}
		})
	},
}

const actions = {
	getDetail: async ({ commit, rootGetters }, id) => {
		const response = await budgetAPI.detail(rootGetters.instituteId, id)
		commit('setItem', response.data)
	},
	createBudget: async ({ rootGetters, getters }) => {
		return await budgetAPI.create(rootGetters.instituteId, getters.payload)
	},
	editBudget: async ({ rootGetters, getters }) => {
		return await budgetAPI.edit(rootGetters.instituteId, getters.payload)
	},
	deleteBudget: async ({ rootGetters }, id) => {
		return await budgetAPI.delete(rootGetters.instituteId, id)
	},
	exportExcel: async ({ rootGetters }, id) => {
		const response = await budgetAPI.detailExportExcel(rootGetters.instituteId, id)
		return response
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
