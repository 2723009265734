var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppContainer__tabs"
  }, [_c('div', {
    staticClass: "tab-inner"
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c('span', {
      key: index
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectTab,
        expression: "selectTab"
      }],
      attrs: {
        "type": "radio",
        "id": 'tab' + index + 1,
        "name": "TabList"
      },
      domProps: {
        "value": item.id == null ? index + 1 : item.id,
        "checked": _vm._q(_vm.selectTab, item.id == null ? index + 1 : item.id)
      },
      on: {
        "change": function change($event) {
          _vm.selectTab = item.id == null ? index + 1 : item.id;
        }
      }
    }), _c('label', {
      attrs: {
        "for": 'tab' + index + 1
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(item.name)) + " "), _c('span', {
      staticClass: "count"
    }, [_vm._v(_vm._s(item.count))])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }