<template>
	<div class="registeringOrganization">
		<div class="wrapper">
			<div class="wrap">
				<div class="flex">
					<h5>{{ tt('조직도 설정하기') }}</h5>
					<!-- <div class="right-btn-group">
						<button class="Btn__black">
							<img src="@/assets/svg/excel-download.svg">
							{{tt('조직도 일괄 등록')}}
						</button>
					</div> -->
				</div>
				<div class="boxwrap">
					<div class="organization-tree">
						<div class="flex">
							<lm-input-search @search="searchTree" :placeholder="tt('그룹명으로 검색')"></lm-input-search>
							<div class="right-btn-group">
								<button class="btn" :class="{ point: order == 1 || isHint }">
									<img src="@/assets/svg//button-icon-lisence-copy.svg" alt="icon" @click="openModalAddGroup" />
									<span @click="openModalAddGroup">{{ tt('생성') }}</span>
									<div class="order-balloon" v-if="order == 1 || isHint">
										<div class="order-top">
											<div class="order">
												<span class="active"></span>
												<span></span>
												<span></span>
											</div>
											<button @click="closeOrder" v-if="!isHint">
												<img src="@/assets/svg/popup-close.svg" alt="close" />
											</button>
										</div>
										<h6>
											{{ tt('1. 연구실/팀/부서 등록하기') }}
										</h6>
										<p>
											{{ tt('그룹(연구실/팀/부서)을 등록해주세요') }}
										</p>
									</div>
								</button>
								<button class="btn ml-10" @click="showModalGroupDelete">
									<img src="@/assets/svg/Trash.svg" alt="icon" />{{ tt('삭제') }}
								</button>
							</div>
						</div>
						<div class="OrganizationTree__main-container" ref="chart-main-container" v-on:scroll="handleScroll">
							<!-- :treeData="organizationData" -->
							<page-tree-view
								v-if="treeData"
								ref="page-tree-view"
								textField="name"
								subTextField="membersCount"
								:treeData="treeData"
								:subTextEndWord="tt('명')"
								@changeActive="changeActiveId"
								@changeTree="changeTree"
								:keyword="keyword"
								:width="800"
								:userPermission="userPermission"
							></page-tree-view>
						</div>
					</div>
					<!-- 멤버 리스트  -->
					<tree-info-area
						v-if="selectedGroupInfo"
						:isStart="true"
						:groupInfo="selectedGroupInfo"
						ref="tree-info-area"
						@refresh="refreshData"
						@changeOrderGroup="changeOrderGroup"
						@changeOrderAccess="changeOrderAccess"
						@completeOrganization="complete"
						@closeOrder="closeOrder"
						:isHint="isHint"
						:order="order"
						:userPermission="userPermission"
					></tree-info-area>
				</div>
			</div>
		</div>

		<div class="hint-popup" v-if="isHint">
			<div class="Popup__background" @click="hideHint"></div>
		</div>

		<button class="Btn__hint" @click="showHint">
			<img src="@/assets/svg/Hint.svg" />
		</button>

		<!-- 그룹 삭제 확인 모달 -->
		<modal-confirm-delete ref="modal-confirm-delete" :title="tt(deleteTitleText)" :content="tt(deleteContentText)" @refresh="refreshData">
		</modal-confirm-delete>
		<modal-notice-organization ref="modal-notice-organization" @hideNotice="hideNotice"></modal-notice-organization>
	</div>
</template>

<script>
import LmInputSearchVue from '@/views/common/components/input/LmInputSearch.vue'
import TreeInfoArea from '@/views/institute/organization/components/OrganizationInfoArea.vue'
import ModalConfirmDelete from '@/views/institute/organization/modals/ModalConfirmDelete.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import instituteGroupAPI from '@/services/api/institute/group'
import ModalNoticeOrganization from '@/views/creation/modals/ModalNoticeOrganization.vue'

export default {
	name: 'registeringOrganization',
	props: {
		mode: {
			type: Number,
			default: 0,
		},
		displayNoticeOrganization: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		'lm-input-search': LmInputSearchVue,
		TreeInfoArea,
		ModalConfirmDelete,
		'page-tree-view': TreeView,
		ModalNoticeOrganization,
	},
	async created() {
		this.buttonModel.buttonText = this.tt('그룹 추가')
		await this.refreshData()
		if (this.displayNoticeOrganization) {
			this.showNotice()
		}
	},
	data() {
		return {
			keyword: '',
			activeGroupId: '',
			addGroupButtonText: '그룹 추가',
			showBoxShadow: false,
			buttonModel: {
				buttonText: '',
			},
			organizationData: [],
			organizationList: undefined,
			deleteTitleText: '그룹 삭제',
			deleteContentText: '정말로 그룹을 삭제하시겠습니까?<br>그룹과 관련된 모든 정보가 사라집니다.',
			treeData: null,
			order: 1,
			isHint: false,
		}
	},
	computed: {
		...mapState('organization', ['selectedGroup', 'selectedGroupInfo']),
	},
	methods: {
		...mapMutations('organization', ['setSelectedGroup', 'setSelectedGroupInfo']),
		handleScroll() {
			const scrollTop = this.$refs['chart-main-container'].scrollTop
			this.showBoxShadow = scrollTop !== 0 ? true : false
		},
		async refreshData(id) {
			const instId = this.$route.params.instituteId
			const response = await instituteGroupAPI.lookupGroupTree(instId)
			this.treeData = response.data
			if (id != undefined) {
				this.selectGroup(id)
			} else if (this.selectedGroupInfo) {
				this.selectGroup(this.selectedGroupInfo.id)
			} else {
				const rootId = this.treeData[0].id
				this.selectGroup(rootId)
			}
		},
		async selectGroup(groupId) {
			if (groupId === null) return
			const response = await instituteGroupAPI.lookupGroupInfo(groupId)
			this.setSelectedGroupInfo(response.data)
			this.activeGroupId = groupId
			this.$refs['page-tree-view'].setActiveId(groupId)
		},
		changeActiveId(id) {
			this.activeGroupId = id
			this.selectGroup(id)
		},
		async changeTree(node, parentNode) {
			const nodeId = node.id,
				parentId = parentNode.id,
				data = {
					parentId: parentId,
				}
			await instituteGroupAPI.changeParentGroup(nodeId, data)
			this.refreshData()
		},
		openModalAddGroup() {
			this.$refs['tree-info-area'].openModalAddGroup()
			this.closeOrder()
		},
		showModalGroupDelete() {
			this.$refs['modal-confirm-delete'].show('DELETE_GROUP')
		},
		searchTree(value) {
			this.keyword = value
		},
		closeOrder() {
			this.order = null
		},
		showHint() {
			this.isHint = true
		},
		hideHint() {
			this.isHint = false
		},
		showNotice() {
			this.$refs['modal-notice-organization'].show()
		},
		hideNotice() {
			this.$refs['modal-notice-organization'].hide()
			this.$emit('hideNoticeOrganization')
		},
		changeOrderGroup() {
			this.order = 2
		},
		changeOrderAccess() {
			this.order = 3
		},
		complete() {
			this.closeOrder()
			this.$emit('complete')
		},
	},
}
</script>

<style lang="scss" scoped>
.hint-popup {
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 770;
}

.registeringOrganization {
	.wrap {
		width: 1000px;
		margin: 40px auto;
		.flex {
			h5 {
				font-size: 1.5rem;
				font-weight: 700;
			}
		}
		.boxwrap {
			width: 100%;
			min-height: 600px;
			height: 70vh;
			margin-top: 20px;
			border-radius: 5px;
			border: 1px solid $INPUT;
			display: flex;
			.organization-tree {
				padding: 20px;
				width: 430px;
				box-sizing: border-box;
				#TreeView {
					margin-left: -20px;
					margin-top: 10px;
					height: 610px;
					overflow-y: auto;
					overflow-x: hidden;
					width: calc(100% + 40px);
				}
			}
		}
	}
	.fixed-btn {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		height: 78px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid $DIVIDER;
		background-color: $LAB_WHITE;
		.Btn__yellow {
			width: 195px;
			height: 48px;
			font-size: 1rem;
			font-weight: 700;
		}
		.Btn__skip {
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translate(0, -50%);
			font-size: 1rem;
			color: $SECONDARY_2;
			font-weight: 700;
			padding-right: 20px;
			background-image: url(~@/assets/svg/arrow-right.svg);
			background-repeat: no-repeat;
			background-position: center right 0;
		}
	}
}
</style>
