<template>
	<div class="AppHeader__menu-list">
		<div v-for="(item, index) in itemList" :key="index" @click="getItemEvent(item)">
			<item :text="tt(item.itemName)"></item>
		</div>
		<div class="AppHeader__menu-divider"></div>
		<div class="sub-menu-wrap" @mouseover="enterMenu" @mouseout="leaveMenu">
			<button @click.stop>
				{{ tt('서비스 이용') }}
			</button>
			<ul class="subm-menu" v-show="isVisibleNestedMenu">
				<li class="menu-item" v-for="(item, index) in subItemList" :key="`submenu-` + index" @click="getItemEvent(item)">
					{{ tt(item.itemName) }}
				</li>
			</ul>
		</div>

		<!--modals-->
		<modal-account-setting ref="modal-account-setting"></modal-account-setting>
	</div>
</template>

<script>
import userAPI from '@/services/api/institute/user'
import { goToSSOMain } from '@/services/api/common'
import AppHeaderMenuItemVue from '@/views/app/components/AppHeaderMenu/components/AppHeaderMenuItem.vue'
import ModalAccountSetting from '@/views/app/modals/ModalAccountSetting.vue'
import { logout } from '@/services/api/common'
import { mapMutations } from 'vuex'
import { MENU_ITEM, SUBMENU_ITEM } from '@/utils/define/Constant'

export default {
	name: 'AppHeaderMenuList',
	components: {
		item: AppHeaderMenuItemVue,
		ModalAccountSetting,
	},
	props: {
		isMigration: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		if (this.isMigration) {
			// console.warn('isMigration', this.isMigration)
			this.itemList = this.itemList.filter(item => item.id !== MENU_ITEM.ACCOUNT_SETTING)
		}
	},
	data() {
		return {
			itemList: [
				{
					id: MENU_ITEM.ACCOUNT_SETTING,
					itemName: '계정 설정',
					clickEvent: () => {
						this.showAccountSetting()
					},
				},
				// {
				//     itemName: '사용자 전환',
				//     clickEvent: () => {},
				// },
				{
					id: MENU_ITEM.LOGOUT,
					itemName: '로그아웃',
					clickEvent: () => {
						userAPI.logout()
						logout()
						goToSSOMain()
					},
				},
			],
			subItemList: [
				{
					id: SUBMENU_ITEM.NOTICE,
					itemName: '공지사항',
					clickEvent: () => {
						this.goNotice()
					},
				},
				{
					id: SUBMENU_ITEM.REQUEST,
					itemName: '이용문의',
					clickEvent: () => {
						this.goRequest()
					},
				},
				{
					id: SUBMENU_ITEM.HOMEPAGE,
					itemName: '홈페이지',
					clickEvent: () => {
						this.goHomepage()
					},
				},
				{
					id: SUBMENU_ITEM.PROVISIONS,
					itemName: '이용약관',
					clickEvent: () => {
						this.goProvisions()
					},
				},
			],
			versionName: {
				itemName: '서비스 이용',
				clickEvent: () => {},
			},
			isVisibleNestedMenu: false,
		}
	},
	computed: {},
	methods: {
		...mapMutations('modals', ['setAccountSetting']),
		getItemEvent(itemList) {
			return itemList.clickEvent()
		},
		enterMenu() {
			this.isVisibleNestedMenu = true
		},
		leaveMenu() {
			this.isVisibleNestedMenu = false
		},
		showAccountSetting() {
			this.$refs['modal-account-setting'].show()
		},
		goNotice() {
			window.open('https://www.lab-manager.com/blog/notice')
		},
		goRequest() {
			window.open('https://www.lab-manager.com/Request')
		},
		goHomepage() {
			window.open('https://lab-manager.com')
		},
		goProvisions() {
			window.open('https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7')
		},
	},
}
</script>

<style lang="scss" scoped>
.AppHeader__menu-list {
	width: 198px;
	position: absolute;
	top: 55px;
	right: 30px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #ccc;
	background-color: $LAB_WHITE;
	border-radius: 5px;
	padding: 5px 0px;
	cursor: default;
	color: #000;

	.AppHeader__menu-divider {
		position: relative;
		width: 200px;
		border: 0;
		height: 1px;
		left: 0px;
		margin-top: 5px;
		margin-bottom: 5px;
		background: #efeff4;
	}
	.sub-menu-wrap {
		position: relative;
		button {
			padding: 0 5px;
			box-sizing: border-box;
			width: calc(100% - 10px);
			margin: 0 5px;
			height: 32px;
			background-image: url(~@/assets/svg/arrow-right-edge.svg);
			background-repeat: no-repeat;
			background-position: center right 10px;
			font-size: 0.875rem;
			color: #000;
			font-weight: 400;
			transition: 0.3s;
			border-radius: 5px;
			&:hover {
				background-color: #f2f2f2;
			}
		}
		ul {
			position: absolute;
			width: 200px;
			border: 1px solid #ccc;
			border-radius: 5px;
			padding: 5px;
			right: 196px;
			top: -6px;
			text-align: left;
			background-color: $LAB_WHITE;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
			li {
				font-size: 0.875rem;
				font-weight: 400;
				color: #000;
				height: 30px;
				border-radius: 5px;
				transition: 0.3s;
				padding: 5px 10px;
				display: flex;
				align-items: center;
				cursor: pointer;
				box-sizing: border-box;
				&:hover {
					background-color: #f2f2f2;
				}
			}
		}
	}
}
</style>
