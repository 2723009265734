var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "button-list"
  }, _vm._l(_vm.list, function (item) {
    return _c('button', {
      key: item.code,
      staticClass: "toggle-button",
      "class": {
        active: _vm.isButtonActive(item)
      },
      on: {
        "click": function click($event) {
          return _vm.setActive(item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(item.name)) + " ")]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }