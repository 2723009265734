var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "legacy-item-wrapper",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "content-wrapper"
  }, [_vm._m(0), _c('div', {
    staticClass: "text-wrapper"
  }, [_c('div', {
    staticClass: "text-title",
    domProps: {
      "innerHTML": _vm._s(_vm.titleText)
    }
  }), _c('div', {
    staticClass: "text-content",
    domProps: {
      "innerHTML": _vm._s(_vm.contentText)
    }
  })]), _vm._m(1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon-wrapper"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/migration/icon-legacy-lab-manager.svg")
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "arrow-wrapper"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow/icon-arrow-right.svg")
    }
  })]);
}]

export { render, staticRenderFns }