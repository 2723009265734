var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.draftInfo.subject ? _c('div', {
    staticClass: "regist-step2-wrapper"
  }, [_vm.draftInfo.purchaseType == 'INTERNAL' ? _c('store-order-info', {
    ref: "store-order-info",
    attrs: {
      "type": "DRAFT",
      "content": "상신하신 지출 결의서가 승인되면, 랩매니저 스토어에 주문이 자동 접수됩니다."
    }
  }) : _vm._e(), _vm.draftInfo.purchaseType == 'INTERNAL' ? _c('delivery-info', {
    ref: "delivery-info",
    attrs: {
      "isValid": _vm.isValid
    },
    on: {
      "changeAddress": _vm.changeAddress
    }
  }) : _vm._e(), _c('div', {
    staticClass: "product-info-wrapper"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('상품 정보')))]), _c('div', {
    staticClass: "btn-area"
  }, [_vm.draftInfo.purchaseType == 'INTERNAL' ? [_c('image-button', {
    attrs: {
      "image": require('@/assets/svg/Search.svg'),
      "text": '랩매니저 스토어에서 검색'
    },
    on: {
      "click": function click($event) {
        return _vm.storeShowModal('modal-store-search-product');
      }
    }
  }), _c('image-button', {
    attrs: {
      "image": require('@/assets/svg/purchase/icon-cart.svg'),
      "text": '장바구니에서 추가'
    },
    on: {
      "click": function click($event) {
        return _vm.storeShowModal('modal-add-cart-product');
      }
    }
  }), _c('image-button', {
    attrs: {
      "text": '견적서로 추가',
      "image": require('@/assets/svg/purchase/icon-estimate.svg')
    },
    on: {
      "click": function click($event) {
        return _vm.storeShowModal('modal-estimate');
      }
    }
  })] : [_c('image-button', {
    attrs: {
      "image": require('@/assets/svg/Add(Button).svg'),
      "text": '상품 추가'
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-add-product'].show();
      }
    }
  })], _c('base-button', {
    attrs: {
      "disabled": _vm.selectedItems.length === 0
    },
    on: {
      "click": _vm.btnSelectedDelete
    }
  }, [_vm._v(_vm._s(_vm.tt('선택 삭제')))])], 2), _c('div', [_c('product-info-regist-table', {
    attrs: {
      "productInfoList": _vm.draftInfo.purchaseItems
    }
  })], 1)]), _vm.getSelectedBudget ? _c('div', {
    staticClass: "budget-status-wrapper"
  }, [_c('total-amount', {
    attrs: {
      "title": '예산 현황',
      "info": _vm.amountForm,
      "budget": _vm.getSelectedBudget
    }
  })], 1) : _vm._e(), _vm.draftInfo.purchaseType == 'INTERNAL' ? [_c('orderer-info', {
    ref: "orderer-info",
    attrs: {
      "isValid": _vm.isValid
    }
  }), _c('recipient-info', {
    ref: "recipient-info",
    attrs: {
      "isValid": _vm.isValid
    },
    on: {
      "getOrdererInfo": _vm.getOrdererInfo
    }
  })] : _vm._e(), _c('div', {
    staticClass: "file-wrapper"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('첨부 파일')))]), _c('div', {
    staticClass: "type-file"
  }, [_c('div', {
    ref: "upload-box",
    staticClass: "upload-box"
  }, [_c('input', {
    ref: "dropFile",
    staticClass: "dropFile",
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": _vm.onChange,
      "drop": _vm.dropFile
    }
  }), _vm.draftInfo.attachments.length == 0 ? _c('div', {
    staticClass: "file-box"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('첨부할 파일을 마우스로 끌어오거나, 직접 선택해 주세요')))]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('button', {
    ref: "select-btn",
    staticClass: "btn",
    on: {
      "click": _vm.uploadFile
    }
  }, [_vm._v(_vm._s(_vm.tt('파일 선택')))])])]) : _c('ul', {
    staticClass: "file-list"
  }, _vm._l(_vm.draftInfo.attachments, function (file, index) {
    return _c('li', {
      key: "key".concat(index)
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/docs.svg")
      }
    }), _c('p', [_vm._v(_vm._s(_vm.tt(file.name)))]), _c('button', {
      staticClass: "close-btn",
      on: {
        "click": function click($event) {
          return _vm.deleteAttachments(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/Close-1.svg")
      }
    })])]);
  }), 0)]), _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.uploadFile
    }
  }, [_vm._v(_vm._s(_vm.tt('파일 선택')))])])]), _c('modal-confirm', {
    ref: "modal-estimate-delete",
    attrs: {
      "title": "상품 삭제",
      "content": "견적서로 추가된 상품은 전체 삭제됩니다.<br>상품 전체 삭제하시겠습니까?",
      "confirmText": "전체 삭제"
    }
  }), _c('modal-add-product', {
    ref: "modal-add-product",
    on: {
      "addProduct": _vm.addPurchaseItem
    }
  }), _c('modal-store-search-product', {
    ref: "modal-store-search-product"
  }), _c('modal-estimate', {
    ref: "modal-estimate",
    on: {
      "addProductByEstimate": _vm.addProductByEstimate
    }
  }), _c('modal-add-cart-product', {
    ref: "modal-add-cart-product"
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }