<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade @close="cancel">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<Datepicker :name="label" v-model="inputValue" :placeholder="`${label}을 입력해주세요`" />
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" v-if="noCancel" @click="cancel">
						{{ tt(cancelText) }}
					</button>
					<button :class="`button-${variant}`" v-if="hasConfirm" @click="confirm">
						{{ tt(confirmText) }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import Datepicker from '@/views/common/components/form/FormDatetime.vue'
export default {
	name: 'ModalConfirm',
	components: {
		Datepicker,
	},
	props: {
		title: {
			type: String,
		},
		label: {
			type: String,
		},
		noCancel: {
			type: Boolean,
			default: true,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		hasConfirm: {
			type: Boolean,
			default: true,
		},
		noContent: {
			type: Boolean,
			default: true,
		},
		confirmText: {
			type: String,
			default: '확인',
		},
		cancelText: {
			type: String,
			default: '취소',
		},
		variant: {
			type: String,
			default: 'primary',
		},
	},
	data() {
		return {
			confirmFn: null,
			cancelFn: null,
			inputValue: null,
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show(confirmFn = null, cancelFn = null) {
			if (confirmFn) this.confirmFn = confirmFn
			if (cancelFn) this.cancelFn = cancelFn
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cancel() {
			this.$emit('cancel')
			if (this.cancelFn) this.cancelFn()
			this.hide()
		},
		confirm() {
			this.$emit('confirm', this.inputValue)
			if (this.confirmFn) this.confirmFn(this.inputValue)
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
::v-deep {
	.form-input {
		width: 100%;
	}
	.input-wrapper {
		.datepicker {
			width: 100%;
			.mx-datepicker {
				width: 100%;
			}
		}
		.mx-input-wrapper {
			width: 100%;
		}
	}
}
</style>
