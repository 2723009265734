const getListByKeyword = (list, keyword, isOpen) => {
	let newList = []
	if (list)
		list.forEach(node => {
			let isContains = false
			let newNode = JSON.parse(JSON.stringify(node))
			if (newNode.name.includes(keyword)) isContains = true
			if (newNode.members) {
				const members = newNode.members.filter(m => m.name.includes(keyword))
				if (members.length > 0) {
					isContains = true
					newNode.members = members
				} else if (!newNode.name.includes(keyword)) {
					newNode.members = []
				}
			}
			if (newNode.storages) {
				const storages = getListByKeyword(node.storages, keyword, isOpen)
				if (storages.length > 0) {
					isContains = true
					newNode.storages = storages.map(ch => {
						return { ...ch, isOpen: isOpen ?? keyword.length > 0 }
					})
				} else if (newNode.name.includes(keyword)) {
					newNode.storages = newNode.storages.map(ch => {
						return { ...ch, isOpen: isOpen ?? false }
					})
				} else {
					newNode.storages = []
				}
			}
			if (newNode.children) {
				const children = getListByKeyword(node.children, keyword, isOpen)
				if (children.length > 0) {
					isContains = true
					newNode.children = children.map(ch => {
						return { ...ch, isOpen: isOpen ?? keyword.length > 0 }
					})
				} else if (newNode.name.includes(keyword)) {
					newNode.children = newNode.children.map(ch => {
						return { ...ch, isOpen: isOpen ?? false }
					})
				} else {
					newNode.children = []
				}
			}
			if (isContains || newNode.storages?.length > 0)
				newList.push({ ...newNode, isOpen: isOpen ?? (keyword.length > 0 || (newNode.parentId == null && !newNode.locationId)) })
		})
	return newList
}

const getListBySelected = (list, selectdIds) => {
	let newList = []
	if (list)
		list.forEach(node => {
			let isContains = false
			let newNode = JSON.parse(JSON.stringify(node))
			if (selectdIds.includes(node.id)) isContains = true
			if (newNode.members?.some(m => selectdIds.includes(m.id))) {
				isContains = true
			}
			if (newNode.storages) {
				const storages = getListBySelected(node.storages, selectdIds)
				if (storages.some(s => s.isOpen == true)) {
					isContains = true
				}
				newNode.storages = storages
			}
			if (newNode.children) {
				const children = getListBySelected(node.children, selectdIds)
				if (children.some(s => s.isOpen == true)) {
					isContains = true
				}
				newNode.children = children
			}
			newList.push({ ...newNode, isOpen: isContains || (newNode.parentId == null && !newNode.locationId) })
		})
	return newList
}

const getStorageIds = (list, ignore = false) => {
	let arr = []
	if (list) {
		list.forEach(node => {
			if (node.accessible || true) arr.push(...getStorageIds(node.children, ignore))
			if (node.locationId || ignore) arr.push(node.id)
			if (node.storages) {
				// arr.push(...node.storages.map(s => s.id))
				arr.push(...getStorageIds(node.storages, ignore))
			}
		})
	}
	return arr
}

const getStorageCount = (list, ignore = false) => {
	let count = 0
	if (list) {
		list.forEach(node => {
			if (node.accessible || true) count += getStorageCount(node.children, ignore)
			if (node.locationId || ignore) count++
			if (node.storages) {
				count += getStorageCount(node.storages, ignore)
			}
		})
	}
	return count
}

const getStorageCountMathcingKeyword = (list, keyword) => {
	let count = 0
	const dfs = (list, keyword) => {
		list?.forEach(node => {
			if (node.accessible || true) dfs(node.children, keyword)
			if (node.name.toLowerCase().includes(keyword.toLowerCase())) count++
			dfs(node.storages, keyword)
		})
		return count
	}
	return dfs(list, keyword)
}
const getStorageMathcingKeyword = (list, keyword) => {
	const dfs = (list, keyword) => {
		let newList = JSON.parse(JSON.stringify(list))
		newList?.forEach(node => {
			if (node.children) node.children = [...dfs(node.children, keyword)]
			if (node.storages) node.storages = [...dfs(node.storages, keyword)]
			if (
				node.name.toLowerCase().includes(keyword.toLowerCase()) ||
				node.children?.some(it => it.isOpen) ||
				node.storages?.some(it => it.isOpen)
			) {
				node.isOpen = true
			} else {
				node.isOpen = false
			}
		})
		return newList
	}
	return dfs(list, keyword)
}

const isChildrenChecked = (list, checkedList) => {
	let isChecked = false
	const dfs = (list, checkedList) => {
		if (isChecked) return
		list?.forEach(node => {
			if (node.accessible || true) dfs(node.children, checkedList)
			if (checkedList.includes(node.id)) isChecked = true
			dfs(node.storages, checkedList)
		})
		return isChecked
	}
	return dfs(list, checkedList)
}

export {
	getListByKeyword,
	getListBySelected,
	getStorageIds,
	getStorageCount,
	getStorageMathcingKeyword,
	getStorageCountMathcingKeyword,
	isChildrenChecked,
}
