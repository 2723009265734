<template>
	<div class="select-category">
		<div class="category-table" :class="{ isSmall: !noSmall }">
			<div class="header">
				<div>{{ tt('분류') }}</div>
				<div>{{ tt('상세 분류') }}</div>
				<div v-if="!noSmall">{{ tt('카테고리') }}</div>
			</div>
			<div class="content">
				<div class="large">
					<ul>
						<li
							v-for="(option, index) in largeClass"
							:key="`large_${index}`"
							:class="{
								active: largeClassId == option.id,
								'disabled-select': fixLargeCategory,
								'unselect-all': fixLargeCategory && !isSelectAll,
							}"
							@click="selectLargeClass(option.id)"
						>
							{{ tt(option.name) }}
						</li>
					</ul>
				</div>
				<div class="middle">
					<ul>
						<li
							v-for="(option, index) in middleClass"
							:key="`middle_${index}`"
							:class="{
								active: middleClassId == option.id,
								'disabled-select': fixMiddleCategory,
								'unselect-all': fixMiddleCategory && !isSelectAll,
							}"
							@click="selectMiddleClass(option.id)"
						>
							{{ tt(option.name) }}
						</li>
					</ul>
					<div class="no-data" v-if="!largeClassId">
						<p>{{ tt('분류를 선택해주세요') }}</p>
					</div>
				</div>
				<div class="small" v-if="!noSmall">
					<ul>
						<li
							v-for="(option, index) in smallClass"
							:key="`small_${index}`"
							:class="{ active: smallClassId == option.id }"
							@click="selectSmallClass(option)"
						>
							{{ option.name }}
						</li>
					</ul>
					<div class="no-data" v-if="!middleClassId">
						<p>{{ tt('상세분류를 선택해주세요') }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { LargeClass, LargeClassToString, MiddleClassList, MiddleClassToString } from '@/utils/define/ItemCode'
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'CategorySelector',
	props: {
		title: {
			type: String,
			default: '',
		},
		value: {
			type: Object,
			default: () => {
				return {
					option: '',
					classification: '',
					name: '',
				}
			},
		},
		fixLargeCategory: {
			type: Boolean,
			default: false,
		},
		fixMiddleCategory: {
			type: Boolean,
			default: false,
		},
		noSmall: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data() {
		return {
			largeClassId: '',
			middleClassId: '',
			smallClassId: '',
		}
	},
	watch: {
		value: {
			deep: true,
			handler(category) {
				this.smallClassId = category?.id
			},
		},
	},
	computed: {
		...mapGetters('setting', ['getCategoryList']),
		largeClass() {
			return Object.entries(LargeClass).map(([key, value]) => {
				return { name: LargeClassToString(value), id: LargeClass[key] }
			})
		},
		middleClass() {
			if (this.largeClassId) {
				return MiddleClassList[this.largeClassId].map(classId => {
					return {
						name: MiddleClassToString(classId),
						id: classId,
					}
				})
			} else {
				return []
			}
		},
		smallClass() {
			return this.middleClassId && this.getCategoryList.filter(item => item.type === this.middleClassId)
		},
		isSelectAll() {
			if (!this.noSmall) {
				return this.largeClassId && this.middleClassId && this.smallClassId
			} else {
				return this.largeClassId && this.middleClassId
			}
		},
	},
	created() {
		if (!this.noSmall) this.lookupCategoryList()
	},
	async mounted() {
		this.largeClassId = this.value?.classification
		this.middleClassId = this.value?.type
		this.smallClassId = this.value?.id
	},
	methods: {
		...mapActions('setting', ['lookupCategoryList']),
		selectLargeClass(id) {
			if (this.fixLargeCategory) return
			this.largeClassId = id
			this.middleClassId = ''
			this.$emit('input', null)
			this.$emit('change', null)
		},
		selectMiddleClass(id) {
			if (this.fixMiddleCategory) return
			this.middleClassId = id
			if (!this.noSmall) {
				this.$emit('input', null)
				this.$emit('change', null)
			} else {
				const value = {
					classification: this.largeClassId,
					type: this.middleClassId,
					id: null,
				}
				this.$emit('input', value)
				this.$emit('change', value)
			}
		},
		selectSmallClass(option) {
			this.smallClassId = option.id
			this.$emit('input', option)
			this.$emit('change', option)
		},
		setData(categoryInfo) {
			if (categoryInfo) {
				this.largeClassId = categoryInfo?.classification
				this.middleClassId = categoryInfo?.type
				this.smallClassId = categoryInfo?.id
				this.$emit('input', categoryInfo)
				this.$emit('change', categoryInfo)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.select-category {
	.category-table {
		width: 100%;
		border: 1px solid $INPUT;
		border-radius: 5px;
		overflow: hidden;
		.content {
			.large {
				overflow-y: auto;
			}
		}
		&.isSmall {
			.header {
				div {
					width: calc(100% / 3);
				}
			}
			.content {
				> div {
					width: calc(100% / 3);
				}
				.large {
					overflow-y: auto;
				}
				.middle {
					overflow-y: auto;
				}
				.small {
					overflow-y: auto;
				}
			}
		}
		.header {
			width: 100%;
			border-bottom: 1px solid $INPUT;
			height: 30px;
			box-sizing: border-box;
			div {
				width: 50%;
				height: inherit;
				text-align: left;
				padding-left: 15px;
				font-size: 0.75rem;
				font-weight: 500;
				color: $SECONDARY_2;
				display: inline-flex;
				align-items: center;
				border-right: 1px solid $INPUT;
				box-sizing: border-box;
				&:last-child {
					border-right: 0;
				}
			}
		}
		.content {
			display: flex;
			> div {
				width: 50%;
				box-sizing: border-box;
				border-right: 1px solid $INPUT;
				display: inline-block;
				height: 280px;
				position: relative;
				&:last-child {
					border-right: 0;
					ul {
						li {
							background-image: unset;
							&.active {
								background-image: url(~@/assets/svg/categorySelector/checked-primary.svg);
								background-size: 24px;
							}
						}
					}
				}
				ul {
					width: 100%;
					li {
						width: 100%;
						height: 40px;
						border-bottom: 1px solid $DIVIDER;
						display: flex;
						align-items: center;
						font-size: 0.875rem;
						font-weight: 400;
						padding: 0 15px;
						background-image: url(~@/assets/svg/categorySelector/polygon-right-disable.svg);
						background-size: 16px;
						background-position: center right 8px;
						background-repeat: no-repeat;
						box-sizing: border-box;
						cursor: pointer;
						&.active {
							background-color: $SUB-YELLOW_100;
							background-image: url(~@/assets/svg/categorySelector/polygon-right-enable.svg);
							color: $LAB_YELLOW;
							font-weight: 500;
						}
						&.disabled-select {
							background: $GREY_1;
							color: $PLACEHOLDER;
							cursor: default;
							background-image: url(~@/assets/svg/categorySelector/polygon-right-disable.svg);
							background-size: 16px;
							background-position: center right 8px;
							background-repeat: no-repeat;
							&.active {
								background-color: $SUB-YELLOW_100;
								background-image: url(~@/assets/svg/categorySelector/polygon-right-enable.svg);
								background-repeat: no-repeat;
								color: $LAB_YELLOW;
								font-weight: 500;
								&.unselect-all {
									color: $SECONDARY_2;
								}
							}
						}
					}
				}
				.no-data {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 0.75rem;
					font-weight: 400;
					color: $SECONDARY_2;
					text-align: center;
					word-break: keep-all;
					width: 100%;
				}
			}
		}
	}
}
</style>
