import axiosInstance from '@/services/axios'

const endPoint = {
	lookupPermissionList: (instId, offset, length, keyword) => {
		if (length != undefined) {
			return `/institute/${instId}/permission/list?keyword=${keyword}&offset=${offset}&length=${length}`
		} else {
			return `/institute/${instId}/permission/list?keyword=${keyword}&offset=${offset}`
		}
	},
	lookupPermission: (instId, id) => `/institute/${instId}/permission?id=${id}`,
	updatePermission: instId => `/institute/${instId}/permission`,
	copyPermission: instId => `/institute/${instId}/permission/copy`,
	updateOrder: instId => `/institute/${instId}/permission/orders`,
	addMember: instId => `/institute/${instId}/permission/member`,
	deleteMember: (instId, userId, permissionId) => `/institute/${instId}/permission/member?userId=${userId}&permissionId=${permissionId}`,
	addGroup: instId => `/institute/${instId}/permission/group`,
	deleteGroup: (instId, groupId, permissionId) => `/institute/${instId}/permission/group?groupId=${groupId}&permissionId=${permissionId}`,
}

const institutePermissionAPI = {
	lookupPermissionList: (instId, offset, length, keyword) => axiosInstance.get(endPoint.lookupPermissionList(instId, offset, length, keyword)),
	lookupPermission: (instId, id) => axiosInstance.get(endPoint.lookupPermission(instId, id)),
	addPermission: (instId, body) => axiosInstance.post(endPoint.updatePermission(instId), body),
	copyPermission: (instId, body) => axiosInstance.post(endPoint.copyPermission(instId), body),
	updatePermission: (instId, body) => axiosInstance.put(endPoint.updatePermission(instId), body),
	deletePermission: (instId, id) => axiosInstance.delete(endPoint.lookupPermission(instId, id)),
	updateOrder: (instId, body) => axiosInstance.put(endPoint.updateOrder(instId), body),
	addMember: (instId, body) => axiosInstance.post(endPoint.addMember(instId), body),
	deleteMember: (instId, userId, permissionId) => axiosInstance.delete(endPoint.deleteMember(instId, userId, permissionId)),
	addGroup: (instId, body) => axiosInstance.post(endPoint.addGroup(instId), body),
	deleteGroup: (instId, groupId, permissionId) => axiosInstance.delete(endPoint.deleteGroup(instId, groupId, permissionId)),
}

export default institutePermissionAPI
