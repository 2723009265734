<template>
	<div class="legacy-item-wrapper" @click="onClickItem">
		<div class="content-wrapper" :class="{ create: !info }">
			<div class="item-header">
				<div class="icon-wrapper">
					<img :src="logo" />
				</div>
				<div class="text-wrapper">
					<div class="text-title" :class="{ expand: subTitle === '' }" v-html="tt(title)"></div>
					<div class="text-sub-title" v-html="tt(subTitle)"></div>
				</div>
				<div class="arrow-wrapper">
					<img src="@/assets/svg/arrow/icon-arrow-right.svg" />
				</div>
			</div>
			<template v-if="info">
				<div class="item-institute-info">
					<ul class="item-text-list">
						<li class="item-text">
							{{ tt('기관') }} :
							{{ instituteName }}
						</li>
						<li class="item-text">{{ tt('그룹') }} : {{ groupTitle }}</li>
						<li class="item-text">
							{{ tt('초대 일시') }} :
							{{ formatDatetime(info.createDatetime) }}
						</li>
						<li class="item-text">{{ tt('초대한 사람') }} : {{ hostName }}</li>
						<li v-if="isLogin" class="item-text">
							{{ tt('초대받은 사람') }} :
							{{ user.email }}
						</li>
					</ul>
				</div>
				<div class="item-license-info" v-if="info.license">
					<ul class="item-text-list">
						<li class="item-text" v-if="info.license.type">
							{{ tt('라이선스 :') }}
							<span :class="{ error: isExpired }">
								{{ licenseType2Text(info.license.type) }}
								<span v-if="isExpired">
									{{ tt('(만료됨)') }}
								</span>
								<span v-else>
									{{ tt(`(활성화: ${formatDatetime(info.license.expireDate)}까지)`) }}
								</span>
							</span>
						</li>
						<li class="item-text" v-if="info.license.quantity">
							{{ tt('활성화된 멤버 / 라이선스 수 :') }}
							<span :class="{ error: isOverCountLicense }">
								{{ info.institute.memberCount }} /
								{{ info.license.quantity }}
								{{ isOverCountLicense ? tt('(보유 라이선스 부족)') : null }}
							</span>
						</li>
					</ul>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import MixinLicense from '@/mixins/license'
export default {
	name: 'InvitedInstituteItem',
	props: {
		title: {
			type: String,
			default: '',
		},
		subTitle: {
			type: String,
			default: '',
		},
		logo: {
			type: String,
			default: require('@/assets/svg/default-institute-logo.svg'),
		},
		info: {
			type: Object,
			default: () => {},
		},
		isExpired: {
			type: Boolean,
			default: false,
		},
		isOverCountLicense: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [MixinLicense],
	computed: {
		...mapState('user', ['user']),
		isLogin() {
			return this.user && this.user.email
		},
		groupTitle() {
			if (this.isEmptyObject(this.info)) return '-'

			if (this.info.groups.length === 1) {
				return this.info.groups[0].name
			} else if (this.info.groups.length > 1) {
				return `${this.info.groups[0].name} 외 ${this.info.groups.length - 1}개의 연구실`
			}
			return '-'
		},
		instituteName() {
			return this.isEmptyObject(this.info) ? '' : this.info.institute.name
		},
		hostName() {
			return this.isEmptyObject(this.info) ? '' : `${this.info.host.email}(${this.info.host.name})`
		},
	},
	methods: {
		isEmptyObject(obj) {
			return Object.keys(obj).length === 0 && obj.constructor === Object
		},
		onClickItem() {
			this.$emit('click')
		},
	},
}
</script>

<style lang="scss" scoped>
.red::v-deep {
	color: red;
}
.legacy-item-wrapper {
	cursor: pointer;
	width: 600px;
	left: 0px;
	border-radius: 16px;
	margin: 20px;
	.content-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		border: 2px solid $TEXT_YELLOW;
		box-shadow: 0px 1px 2px 0px #0000001a;
		height: 100%;
		padding: 20px;
		&.create {
			border: 1px solid $DIVIDER;
		}
		.item-header {
			display: flex;
			align-items: center;
			.icon-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 60px;
				height: 60px;
				min-width: 60px;
				background-color: $LAB_WHITE;
				border: 1px solid $DIVIDER;
				border-radius: 16px;
				padding: 6px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 16px;
				}
			}
			.text-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 5px 10px;
				width: 100%;
				height: 58px;

				.text-title {
					font-size: 18px;
					font-weight: 700;
					line-height: 26px;
					letter-spacing: 0px;
					text-align: left;
					&.expand {
						line-height: 48px;
					}
				}
				.text-sub-title {
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0px;
					text-align: left;
					color: $SECONDARY_2;
				}
			}
			.arrow-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}
		}
		.item-institute-info,
		.item-license-info {
			background: $GREY_0;
			border: 1px solid $DIVIDER;
			width: 100%;
			border-radius: 10px;
			padding: 15px;
			margin: 16px 0 0 0;
			.item-text-list {
				margin-left: 25px;
				.item-text {
					list-style-type: initial;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					text-align: left;
					color: $DEFAULT;
					.error {
						color: $RED;
						font-weight: bold;
					}
				}
			}
		}
	}
}
</style>
