var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.tt('라이선스 관리')) + " ")];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "error"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt('결제카드 정보와 관련하여 문제가 발생했습니다. 결제카드를 업데이트해주세요.')) + " ")]), !_vm.isLicenseActivate || _vm.beingSubscription ? _c('div', {
    staticClass: "modal-footer"
  }, [_c('LicenseManageFold', {
    attrs: {
      "open": "",
      "isFail": ""
    },
    on: {
      "manageSubscribe": function manageSubscribe($event) {
        return _vm.$emit('manageSubscribe');
      }
    }
  })], 1) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }