var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-row"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "column-box"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('label', {
      key: index
    }, [_c('input', {
      staticClass: "checkbox",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": _vm.isChecekdItem(item.id)
      },
      on: {
        "change": function change($event) {
          return _vm.checkedItem(item);
        }
      }
    }), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: item.name,
        expression: "item.name",
        modifiers: {
          "hover": true
        }
      }]
    }, [_vm._v(_vm._s(_vm.tt(item.name)))])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }