import {
	lookupLicense,
	lookupLicenseBasic,
	lookupLicenseManagement,
	lookupLicenseRatePlan,
	purchaseLicenseBasic,
	cancelWaitingLicense,
	lookupLicenseHistory,
	lookupLicenseBasicAddition,
	purchaseLicenseBasicAddition,
	subscriptBasicLicense,
	lookupLicenseInfo4Subscription,
	updateLicenseSubscription,
	unsubscribeLicense,
	lookupAuthEdu,
	requestFreetier,
} from '@/services/api/institute/license'

import { lookupPreviousEbillInfo } from '@/services/api/payment'

const state = {
	purchaseForm: {
		plan: null,
		quantity: null,
		month: null,
	},
	licenseRatePlan: { YEAR: { STANDARD: 50000, BASIC: 35000, EDUCATION: 300000 }, MONTH: { STANDARD: 60000, BASIC: 40000 } },
	licensePurchase: {},
	purchaseCompleteInfo: {},
	MAX_QUANTITY: 99999,
	MAX_MONTH: 999,
	isMonthly: false,
}
const getters = {
	status: () => ({
		ACTIVATE: 'ACTIVATE',
		EXPIRED: 'EXPIRED',
		FULL: 'FULL',
	}),
	type: () => ({
		TRIAL: 'TRIAL',
		FREE_TIER: 'FREE_TIER',
		STANDARD: 'STANDARD',
		BASIC: 'BASIC',
		LITE_EDU: 'LITE_EDU',
		EDUCATION: 'EDUCATION',
	}),
	paymentMethod: () => ({
		FREE: 'FREE', // 첫 결제(결제 한 적이 없음)
		CARD_REGISTERED: 'CARD_REGISTERED', // 등록된 카드 or 정기 결제
		CARD_INICIS: 'CARD_INICIS', // 신용카드(이니시스)
		ELECTRONIC_BILL: 'ELECTRONIC_BILL', // 전자세금계산서
	}),
	EBILL_DUE_TIME: () => '23:30',
	getPurchasePrice: state => state.licenseRatePlan[state.isMonthly ? 'MONTH' : 'YEAR'][state.purchaseForm.plan],
	getCompletePurchasePrice: state => state.licenseRatePlan[state.isMonthly ? 'MONTH' : 'YEAR'][state.licensePurchase.plan],
}
const mutations = {
	initPurchaseForm: state => {
		state.purchaseForm = {
			plan: null,
			quantity: null,
			month: null,
		}
	},
	setPurchaseForm: (state, data) => {
		Object.assign(state.purchaseForm, data)
	},
	setPurchaseInfo: (state, licensePurchase) => (state.licensePurchase = { ...licensePurchase }),
	setLicenseRatePlan: (state, ratePlan) => (state.licenseRatePlan = { ...ratePlan }),
	setPurchaseInfo: (state, licensePurchase) => (state.licensePurchase = { ...licensePurchase }),
	setEbillId: (state, ebillId) => (state.purchaseForm.ebillId = ebillId),
	setPurchaseCompleteInfo: (state, purchaseCompleteInfo) => (state.purchaseCompleteInfo = { ...purchaseCompleteInfo }),
	setCardId: (state, cardId) => (state.purchaseForm.cardId = cardId),
	setMonthly: (state, value) => (state.isMonthly = value),
}

const actions = {
	setPurchaseForm: ({ commit, dispatch }, data) => {
		commit('setPurchaseForm', data)
	},
	getPurchaseLicenseInfo: async ({ commit, rootGetters, state }) => {
		const response = await lookupLicenseBasic(rootGetters.instituteId, state.purchaseForm)
		commit('setPurchaseInfo', response.data)
	},
	getAddLicenseInfo: async ({ commit, rootGetters, state }) => {
		const response = await lookupLicenseBasicAddition(rootGetters.instituteId, state.purchaseForm)
		commit('setPurchaseInfo', response.data)
	},
	getSubscribeLicenseInfo: async ({ commit, rootGetters, state }) => {
		const response = await lookupLicenseInfo4Subscription(rootGetters.instituteId, state.purchaseForm)
		commit('setPurchaseInfo', response.data)
	},
	getLicenseRatePlan: async ({ commit, rootGetters }) => {
		const { data } = await lookupLicenseRatePlan(rootGetters.instituteId)
		commit('setLicenseRatePlan', data)
	},
	purhcaseLicense: async ({ state, rootGetters, commit }, data) => {
		commit('setPurchaseForm', data)
		const response = await purchaseLicenseBasic(rootGetters.instituteId, state.purchaseForm)
		commit('setPurchaseCompleteInfo', response.data)
		return response
	},
	addLicense: async ({ state, rootGetters, commit }) => {
		const response = await purchaseLicenseBasicAddition(rootGetters.instituteId, state.purchaseForm)
		commit('setPurchaseCompleteInfo', response.data)
		return response
	},
	subscribeLicense: async ({ state, rootGetters }) => {
		const response = await subscriptBasicLicense(rootGetters.instituteId, state.purchaseForm)
		return response
	},
	updateSubscribe: async ({ state, rootGetters }) => {
		const response = await updateLicenseSubscription(rootGetters.instituteId, state.purchaseForm)
		return response
	},
	unsubscribeLicense: async ({ rootGetters }) => {
		const response = await unsubscribeLicense(rootGetters.instituteId, state.purchaseForm)
		return response
	},
	cancelEbil: async ({ rootGetters }) => {
		return await cancelWaitingLicense(rootGetters.instituteId)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
