const GroupAuthority = {
	namespaced: true,
	state: () => ({
		_group: {
			add: undefined,
			lookup: undefined,
			delete: undefined,
		},
		_organization: {
			settingRelationship: undefined,
		},
		_groupMember: {
			add: undefined,
			lookup: undefined,
			delete: undefined,
			authManage: undefined,
		},
	}),
	getters: {},
	mutations: {},
	actions: {},
}
export default GroupAuthority
