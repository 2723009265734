<template>
	<div class="header-wrap" v-if="item">
		<div class="header">
			<div class="left">
				<div class="title">{{ `${item.name}` }}</div>
				<div class="subtext">
					<div class="brand" v-if="item.brand && item.brand.name" v-html="formatBrand(item.brand)"></div>
					<div class="subname" v-if="item.subname" v-html="formatText(item.subname)"></div>
				</div>
			</div>
			<div class="right" v-if="!item.isDel && !item.isDispose">
				<div class="right-gray">
					<button class="favorite-button" :class="{ active: item.favorite }">
						<input type="checkbox" class="favorite" v-model="isFavorite" />
					</button>
					<button class="lmbutton gray" @click="$refs['modal-delete'].show()">{{ tt('삭제') }}</button>
					<button class="lmbutton gray" @click="showModalItemProductEdit">{{ tt('편집') }}</button>
					<button class="lmbutton gray" @click="showModalItemProductCopy">{{ tt('복제') }}</button>
				</div>
				<div class="right-primary">
					<button class="lmbutton primary" v-if="canUse" @click="use">{{ tt('사용하기') }}</button>
					<button class="lmbutton background-white-gray" v-else-if="item.status === 'UNAVAILABLE' && item.holder">
						{{ tt('${1}님이 사용중', item.holder.name) }}
					</button>
					<button class="lmbutton background-white-primary" v-else-if="item.status === 'HOLDING_OWN'" @click="returnUse">
						{{ tt('사용 완료하기') }}
					</button>
					<button class="lmbutton red" v-else-if="showDispose" @click="$refs['modal-dispose'].show()">
						{{ tt('폐기하기') }}
					</button>
				</div>
			</div>
		</div>

		<modal-input-use
			ref="modal-input-use"
			title="사용 완료하기"
			:volume="item.currentVolume"
			:volumeUnit="volumeUnit"
			:largeClass="item.category.classification"
			:useCount="item.useCount"
			:isCanCount="!isEquipment"
			type="RETURN"
			cancelText="취소"
			confirmText="완료"
			@confirm="completeUse"
			@showEmptyVolume="body => $refs['modal-empty-volume'].show(body)"
		></modal-input-use>
		<modal-empty-volume
			title="잔여 용량 없음"
			ref="modal-empty-volume"
			type="DISPOSE"
			@dispose="disposeEmptyVolume"
			@noDispose="noDisposeEmptyVolume"
		></modal-empty-volume>
		<modal-dispose title="물품 폐기" ref="modal-dispose" textAreaTitle="폐기 사유" @dispose="memo => dispose(memo)"> </modal-dispose>

		<modal-textarea
			ref="modal-delete"
			title="물품 삭제"
			titleTextarea="삭제 사유"
			headerText="물품을 정말로 삭제하시겠습니까?<br />이 동작은 되돌릴 수 없습니다."
			placeholder="삭제 사유를 입력해주세요(200자 제한)"
			confirmText="삭제"
			variant="warning"
			type="DELETE"
			@complete="remove"
		></modal-textarea>

		<modal-item-product
			ref="modal-item-product-edit"
			title="물품정보 편집"
			@complete="$refs['modal-item-management-edit'].show()"
		></modal-item-product>
		<modal-item-management
			ref="modal-item-management-edit"
			title="물품정보 편집"
			@goPrev="$refs['modal-item-product-edit'].show()"
		></modal-item-management>

		<modal-item-product
			ref="modal-item-product-copy"
			title="물품정보 등록"
			@complete="$refs['modal-item-management-copy'].show()"
		></modal-item-product>
		<modal-item-management
			ref="modal-item-management-copy"
			title="물품정보 등록"
			@goPrev="$refs['modal-item-product-copy'].show()"
			@complete="copyComplete"
		></modal-item-management>
		<modal-complete-regist
			ref="modal-complete-copy"
			title="복제 등록 완료!"
			confirmBtnText="목록으로"
			cancelBtnText="등록된 물품 상세보기"
			@confirm="goToInventoryList"
			@cancel="goCopyItem"
		></modal-complete-regist>
	</div>
</template>

<script>
import ModalInputUse from '@/views/inventoryGen2/components/detail/modal/modalInputUse/index.vue'
import MixinItem from '@/mixins/inventory/MixinItem'
import ModalEmptyVolume from '@/views/inventoryGen2/components/detail/modal/modalEmptyVolume/index.vue'
import ModalDispose from '@/views/inventoryGen2/components/detail/modal/modalDispose/index.vue'
import ModalItemProduct from '@/views/inventoryGen2/components/regist/modal/ModalItemProduct.vue'
import ModalItemManagement from '@/views/inventoryGen2/components/regist/modal/ModalItemManagement.vue'
import ModalTextarea from '@/views/inventoryGen2/components/detail/modal/modalTextarea/index.vue'
import ModalCompleteRegist from '@/views/inventoryGen2/components/regist/modal/ModalCompleteRegist.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import { LargeClass, MiddleClass } from '@/utils/define/ItemCode'

import MixinInventoryDetail from '@/views/inventoryGen2/components/detail/mixin/MixinInventoryDetail.js'
export default {
	name: 'DetailHeader',
	mixins: [MixinItem, MixinInventoryDetail],
	components: { ModalInputUse, ModalEmptyVolume, ModalDispose, ModalItemProduct, ModalItemManagement, ModalTextarea, ModalCompleteRegist },
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			nullText: `<span class="nodata">${this.tt('정보없음')}</span>`,
			copyIds: [],
		}
	},
	computed: {
		...mapState('user', ['user']),
		...mapGetters('modal/inputUse', ['useVolume']),
		customFieldObject() {
			let obj = {}
			this.item?.customField?.forEach(item => {
				obj[item.key] = item.value
			})
			return obj
		},
		isEquipment() {
			return this.item?.category?.type === MiddleClass.EQUIPMENT
		},
		volumeUnit() {
			return this.item.volumeUnit ?? '회'
		},
		canUse() {
			const isAvailable = ['AVAILABLE'].includes(this.item.status)
			if (this.isEquipment && isAvailable) {
				return true
			}
			if (isAvailable) {
				if (!this.item.expireDatetime) {
					return true
				} else {
					return this.item.expireDatetime > DateTime.now().toISO()
				}
			} else {
				return false
			}
		},
		showDispose() {
			const isUnavailable = this.item.status === 'UNAVAILABLE'
			if (this.isEquipment && !isUnavailable) return false
			return (
				isUnavailable ||
				(this.validVolumeCategory && this.item.currentVolume <= 0) ||
				(this.item.expireDatetime && this.item.expireDatetime < DateTime.now().toISO())
			)
		},
		validVolumeCategory() {
			return this.item.category.classification == LargeClass.CHEMICAL || this.item.category.classification == LargeClass.ETC
		},
		isFavorite: {
			get() {
				return this.item.favorite
			},
			set(value) {
				if (value) this.$emit('updateFavorite', 'add')
				else this.$emit('updateFavorite', 'delete')
			},
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('inventoryGen2/item', ['useItem', 'returnItem', 'removeItem']),
		...mapActions('inventoryGen2/historyInfo', ['getList']),
		...mapMutations('inventoryGen2/form', ['setCategory', 'setFormItem', 'setItemFormType', 'setItemCustomFields']),
		...mapActions('inventoryGen2/form', ['getCategoryInfo']),
		...mapMutations('tag', ['setSelectedTag']),
		...mapMutations('form/image', ['setRemoveImageLink']),
		async use() {
			try {
				await this.useItem(this.item.id)
				await this.getList()
			} catch (e) {
				this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
			}
		},
		returnUse() {
			// if (this.isEquipment) {
			// 	this.completeUse()
			// } else
			this.$refs['modal-input-use'].show()
		},
		async remove(memo) {
			try {
				await this.removeItem({
					itemsId: [this.item.id],
					memo: encodeURIComponent(memo),
				})
				this.$root.toast('삭제 완료', '물품이 삭제되었습니다', 'success')
				this.goToInventoryList()
			} catch (e) {
				this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
			}
		},
		async showModalItemProductEdit() {
			this.setItemFormType('NORMAL_EDIT')
			await this.setCategory(this.item.category)
			await this.getCategoryInfo()
			await this.setFormItem(this.item)
			await this.setItemCustomFields(this.customFieldObject)
			this.setSelectedTag(this.item.tagList.map(tag => tag.id))
			this.setRemoveImageLink([])

			this.$refs['modal-item-product-edit'].show()
		},
		async showModalItemProductCopy() {
			this.setItemFormType('NORMAL_COPY')
			await this.setCategory(this.item.category)
			await this.getCategoryInfo()
			await this.setFormItem({
				...this.item,
				incompanyMngcode: '',
				buyDatetime: '',
				memo: '',
				price: null,
				qrSticker: [],
				openDatetime: '',
				storageId: this.item.storage?.id,
                registerMethod: 'MANUAL_COPY',
			})
			await this.setItemCustomFields(this.customFieldObject)
			// this.setSelectedTag(this.item.tagList.map(tag => tag.id))
			this.setRemoveImageLink([])

			this.$refs['modal-item-product-copy'].show()
		},
		copyComplete(ids) {
			this.copyIds = ids
			this.$refs['modal-complete-copy'].show()
		},
		goCopyItem() {
			if (!this.copyIds && this.copyIds.length <= 0) return
			this.$router.push({
				name: 'InventoryDetailGen2',
				params: {
					id: this.copyIds[0],
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/inventory/gen2/_inventory-detail-header.scss';

.favorite-button {
	height: 36px;
	width: 36px;
	background-color: $GREY_4;
	border-radius: 8px;
	&:hover {
		filter: brightness(95%);
	}
	&.active {
		background-color: $SUB-YELLOW_200;
	}
	.favorite {
		&:after {
			top: -12px !important;
		}
	}
}
</style>
