var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    ref: "storagebox",
    staticClass: "StorageBox__item",
    "class": [{
      cursor: !_vm.isStart
    }, {
      shortHeight: _vm.isOrganization && _vm.mode == 2 || _vm.userPermission.storage && !_vm.userPermission.storage.update && !_vm.userPermission.storage["delete"]
    }],
    attrs: {
      "draggable": !_vm.isOrganization
    },
    on: {
      "click": function click($event) {
        !_vm.isStart ? _vm.goToStorageDetail(_vm.storageInfo.id) : '';
      },
      "drag": function drag($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.storageDrag($event, _vm.storageInfo);
      },
      "dragstart": function dragstart($event) {
        return _vm.storageDragstart($event, _vm.storageInfo);
      },
      "dragend": function dragend($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.storageDragend($event, _vm.storageInfo);
      }
    }
  }, [_c('div', {
    attrs: {
      "id": "storageData_".concat(_vm.storageInfo.id)
    }
  }, [_c('div', {
    staticClass: "StorageBox__item-title"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.storageInfo.name) + " "), _vm.storageInfo.isNew && !_vm.isClicked ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/new.svg")
    }
  }) : _vm._e()]), _c('p', [_vm._v(" " + _vm._s(_vm.storageInfo.assigneeGroup ? _vm.storageInfo.assigneeGroup.name : ' ') + " ")])]), _c('div', {
    staticClass: "StorageBox__item-admin"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('관리자')))]), _c('ul', {
    staticClass: "StorageBox__warning-list"
  }, [_c('li', [_vm._v(" " + _vm._s(_vm.tt('정')) + ": "), _vm.storageInfo.assignee ? _c('span', [_vm._v(_vm._s(_vm.showAssigneeName))]) : _vm._e()]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('부')) + ": "), _vm.storageInfo.subAssignee ? _c('span', [_vm._v(_vm._s(_vm.showSubassigneeName))]) : _vm._e()])])]), _c('div', {
    staticClass: "StorageBox__item-count"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('물품 총 수량')))]), _c('div', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_vm.storageInfo.itemTotalCount + _vm.tt('개')) + " ")])]), _c('div', {
    staticClass: "StorageBox__item-admin"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('경고 표지')))]), _vm.storageInfo ? _c('ul', {
    staticClass: "StorageBox__item-warning StorageBox__item-admin"
  }, [_vm._l(_vm.storageInfo.warningSignImages.slice(0, _vm.showMaxImgLength), function (warnImg) {
    return _c('li', {
      key: "".concat(warnImg)
    }, [_c('img', {
      attrs: {
        "src": warnImg,
        "alt": "경고icon"
      }
    })]);
  }), _vm.storageInfo.warningSignImages.length > _vm.showMaxImgLength ? _c('li', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/exceed-sign.svg")
    }
  })]) : _vm._e(), _vm.storageInfo.warningSignImages.length == 0 ? _c('li', {
    staticStyle: {
      "display": "block",
      "width": "100%"
    }
  }, [_c('span', {
    staticStyle: {
      "display": "flex",
      "width": "42px",
      "height": "42px",
      "font-size": "14px",
      "color": "#999",
      "align-items": "center"
    }
  }, [_vm._v(_vm._s(_vm.tt('없음')))])]) : _vm._e()], 2) : _vm._e()]), _vm.mode != 2 ? _c('div', {
    staticClass: "StorageBox__item-button-group",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.userPermission.storage && _vm.userPermission.storage.update ? _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.editStorage.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-edit.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('편집')) + " ")]) : _vm._e(), _vm.userPermission.storage && _vm.userPermission.storage["delete"] ? _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.deleteStorage.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-delete.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('삭제')) + " ")]) : _vm._e()]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }