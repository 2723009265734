import { getPreviousCi, getCi, downloadCi, createPaymentEbill } from '@/services/api/payment/index'
import html2pdf from 'html2pdf.js'
import { cloneDeep } from 'lodash'
export default {
	namespaced: true,
	state: {
		form: {
			licenseId: null,
			nameOfCompany: null,
			registrationNumber: null,
			nameOfRepresentative: null,
			email: null,
			bizType: null,
			bizClass: null,
			phone: null,
			address: null,
			addressDetail: null,
		},
	},
	mutations: {
		initForm: state => {
			state.form = {
				licenseId: null,
				nameOfCompany: null,
				registrationNumber: null,
				nameOfRepresentative: null,
				email: null,
				bizType: null,
				bizClass: null,
				phone: null,
				address: null,
				addressDetail: null,
			}
		},
		setForm: (state, data) => {
			state.form = cloneDeep(data)
		},
	},
	actions: {
		getPreviousCi: async ({ rootGetters, commit }) => {
			const resposne = await getPreviousCi(rootGetters.instituteId)
			// commit('setFrom', resposne.data)
			// console.log(resposne)
			return resposne.data
		},
		getCi: async ({ rootGetters, commit }, id) => {
			const resposne = await getCi(rootGetters.instituteId, id)
			// commit('setFrom', resposne.data)
			// console.log(resposne)
			return resposne.data
		},
		downloadCi: async ({ state, rootGetters, commit }, form) => {
			const response = await downloadCi(rootGetters.instituteId, form)
			html2pdf(response.data, {
				margin: 0,
				filename: '거래명세서.pdf',
				image: { type: 'jpg', quality: 0.95 },
				html2canvas: {
					scale: 1.5,
					dpi: 192,
				},
				jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4', compressPDF: true },
			})
			commit('initForm')
		},
		createEbill: async ({ rootGetters, commit }, form) => {
			const response = await createPaymentEbill(rootGetters.instituteId, form)
			return response.data
		},
	},
}
