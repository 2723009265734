<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalInviteMember">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('멤버 초대') }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="boxwrap setting">
						<div class="flex">
							<p>{{ tt('초대 설정') }}</p>
							<button class="Btn__hover-balloon-w">
								<img src="@/assets/svg/Info.svg" />
								<div class="balloon" v-html="tt(`초대할 멤버의 <b>권한</b>과 <b>그룹</b>을 설정해주세요`)"></div>
							</button>
						</div>
						<div class="flex" style="margin-top: 10px">
							<button ref="btn-wrapper" class="Btn__select">
								{{ tt('권한:') }}
								<input type="text" v-model="authority.name" readonly @click="$refs['btn-wrapper'].focus()" />
								<ul class="Form__select-option-list">
									<li
										class="Form__select-option-item"
										v-for="authority in authorityList"
										:key="authority.id"
										:value="authority.name"
										@click="onSelectAuthority(authority)"
									>
										{{ authority.name }}
									</li>
								</ul>
							</button>
							<div class="Dropdown__input" @click="addGroup">
								{{ tt('그룹:') }}
								<input type="text" v-model="groupAuth.name" readonly />
							</div>
						</div>
					</div>
					<div class="boxwrap link">
						<p>{{ tt('링크로 초대하기') }}</p>
						<div class="flex">
							<div>
								<lottie-animation
									v-show="isShowLottie"
									ref="lottie-animation"
									:animationData="require('@/assets/json/lm_loading_lottie.json')"
									:autoPlay="false"
									:loop="true"
									class="lottie-animation"
								></lottie-animation>
								<input class="link-input" ref="inviteLinkInput" type="text" v-model="inviteLink" readonly />
							</div>
							<button class="Btn__link" @click="copyToClipboardLink">
								<img src="@/assets/svg/Duplicate.svg" />
								{{ tt('링크 복사') }}
							</button>
						</div>
					</div>

					<div class="boxwrap email">
						<email-text-area ref="emailTextArea" class="email-area" :contentMaxHeight="561"></email-text-area>
						<toggle-text-area ref="toggle-text-area" title="전송 완료"></toggle-text-area>
					</div>
				</div>
				<div class="Popup__bottom">
					<button class="Btn__complete" @click="sendEmail">
						{{ tt('이메일 전송') }}
					</button>
				</div>
			</div>

			<!--modals-->
			<modal-add-group-auth ref="modal-add-group-auth" mode="storageGroup" @select="selectGroup"></modal-add-group-auth>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { mapState } from 'vuex'
import PermissionBox from '@/views/creation/pages/components/PermissionBox.vue'
import GroupBox from '@/views/institute/member/modals/components/GroupBox.vue'
import ModalAddGroupAuth from '@/views/institute/organization/modals/ModalAddGroupAuth.vue'
import EmailTextArea from '@/views/institute/member/components/EmailTextArea.vue'
import ToggleTextArea from '@/views/institute/member/components/ToggleTextArea.vue'
import institutePermissionAPI from '@/services/api/institute/permission'
import instituteMemberAPI from '@/services/api/institute/member'
import instituteGroupAPI from '@/services/api/institute/group'
import LottieAnimation from 'lottie-web-vue'

export default {
	name: 'ModalInviteMember',
	mixins: [MixinModal],
	components: {
		PermissionBox,
		GroupBox,
		ModalAddGroupAuth,
		EmailTextArea,
		ToggleTextArea,
		LottieAnimation,
	},
	data() {
		return {
			authorityList: [],
			authority: { id: '', name: '' },
			groupAuth: { id: '', name: '' },
			inviteLink: '',
			isShowLottie: false,
		}
	},
	computed: {
		...mapState('user', ['institute']),
	},
	methods: {
		initData() {
			this.authority = {
				id: '',
				name: '',
			}
			this.groupAuth = {
				id: '',
				name: '',
				parentId: null,
			}
			this.inviteLink = ''
			this.isShowLottie = false
		},
		async show() {
			this.isShow = true
			this.initData()

			const response = await institutePermissionAPI.lookupPermissionList(this.institute.id, 0, null, '')
			if (response?.data?.list) {
				this.authorityList = response.data.list
				if (this.authorityList.length > 0) this.authority = this.authorityList[0]
			}

			const response2 = await instituteGroupAPI.lookupGroupList(this.institute.id)
			if (response2?.data) {
				const groupRootNode = response2.data.find(group => {
					return group.parentId === null
				})

				if (groupRootNode) {
					this.groupAuth.id = groupRootNode.id
					this.groupAuth.name = groupRootNode.name
					this.groupAuth.parentId = groupRootNode.parentId
				}
			}

			this.refreshInviteLink()
		},
		onSelectAuthority(authority) {
			this.$refs['btn-wrapper'].blur()
			if (this.authority.id === authority?.id) return
			this.authority = authority
			this.refreshInviteLink()
		},
		addGroup() {
			this.$refs['modal-add-group-auth'].show('GROUP', [this.groupAuth.id])
		},
		selectGroup(selectedGroupList) {
			const selectedGroup = selectedGroupList?.data[0]
			if (!selectedGroup || Array.isArray(selectedGroup)) return
			else if (this.groupAuth.parentId === null && selectedGroup.parentId === null) return
			else if (this.groupAuth.id === selectedGroup.id) return

			this.groupAuth = {
				id: selectedGroup.id,
				name: selectedGroup.name,
				parentId: selectedGroup.parentId,
			}

			this.refreshInviteLink()
		},
		async refreshInviteLink() {
			this.playLoading()
			this.inviteLink = this.tt('링크 생성중...')

			const payload = {
				permissionsId: [this.authority.id],
				groupsId: [this.groupAuth.parentId ? this.groupAuth.id : ''],
			}

			const response = await instituteMemberAPI.createInviteLink(this.institute.id, payload)
			response?.data?.link ? (this.inviteLink = response.data.link) : (this.inviteLink = '')
			this.stopLoading()
		},
		copyToClipboardLink() {
			navigator.clipboard
				.writeText(this.inviteLink)
				.then(() => {
					this.$root.toast(this.tt('초대 링크 복사'), this.tt('초대 링크가 복사되었습니다.'), 'success')
				})
				.catch(() => {
					this.$root.toast(this.tt('초대 링크 복사'), this.tt('초대 링크 복사를 실패하였습니다.'), 'error')
				})
		},
		async sendEmail() {
			const emails = this.$refs['emailTextArea'].getEmailList()
			if (emails.length <= 0) return

			const payload = {
				emails: emails,
				permissionsId: [this.authority.id],
				groupsId: [this.groupAuth.parentId ? this.groupAuth.id : ''],
			}

			const response = await instituteMemberAPI.sendInviteEmail(this.institute.id, payload)

			if (response) {
				this.$root.toast(this.tt('초대 이메일 전송 완료'), this.tt('초대 이메일을 전송했습니다.'), 'success')

				this.$refs['emailTextArea'].clearMailList()
				this.$refs['toggle-text-area'].push(emails)
			}
		},
		playLoading() {
			this.isShowLottie = true
			this.$refs['lottie-animation'].anim.play()
		},
		stopLoading() {
			this.isShowLottie = false
			this.$refs['lottie-animation'].anim.stop()
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalInviteMember {
	.Popup__container {
		.Popup__top {
			width: 100%;
			border-bottom: 1px solid $DIVIDER;
		}
		.Popup__content {
			.boxwrap {
				width: 100%;
				margin-top: 30px;
				p {
					font-size: 0.75rem;
					font-weight: 700;
					display: flex;
					align-items: center;
				}
				.Btn__hover-balloon-w {
					padding: 0;
					img {
						width: 18px;
						height: 18px;
						margin-left: 5px;
					}
					.balloon {
						max-width: unset;
						width: 300px;
					}
				}
				.Btn__select {
					width: 150px;
					margin-right: 10px;
					background-image: url(~@/assets/svg/select-arrow.svg);
					background-size: 12px;
					background-position: center right 7px;
					border-radius: 5px;
					border: 1px solid #ccc;
					height: 30px;
					font-size: 14px;
					input {
						width: calc(100% - 20px);
						border: 0;
						height: 28px;
						cursor: pointer;
						margin-bottom: 2px;
					}
					&:focus-within {
						border-color: #000;
					}
				}
				.Dropdown__input {
					padding-left: 8px;
					width: calc(100% - 160px);
					border: 1px solid #ccc;
					border-radius: 5px;
					background-image: url(~@/assets/svg/arrow-next.svg);
					background-repeat: no-repeat;
					background-position: center right 5px;
					height: 30px;
					overflow: hidden;
					cursor: pointer;
					font-size: 0.875rem;
					input {
						border: 0;
						height: 28px;
						cursor: pointer;
					}
				}
				.Form__select-option-list {
					top: 30px;
				}
				&.link {
					.flex {
						margin-top: 10px;
						> div {
							display: flex;
							width: 330px;
							margin-right: 10px;
							height: 30px;
							border: 1px solid #ccc;
							border-radius: 5px;
							background-color: $GREY_2;
						}
						.lottie-animation {
							width: 76px;
							height: 76px;
							margin: -24px -24px 0 -24px;
						}
						input {
							width: 100%;
							border: 0;
							background-color: transparent;
							padding-left: 8px;
						}
						button {
							width: calc(100% - 340px);
							border: 1px solid #000;
							border-radius: 5px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 0.875rem;
							font-weight: 700;
							height: 30px;
							img {
								width: 20px;
							}
						}
					}
				}
			}
		}
		.Popup__bottom {
			padding-top: 0;
			button {
				width: 120px;
				height: 30px;
				border-radius: 5px;
				font-size: 0.875rem;
				font-weight: 700;
				display: inline-flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
.link-input {
	font-size: 14px;
}
</style>
