<template>
	<b-modal ref="modal" hide-footer centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">
				{{ tt('GHS 라벨 생성 가이드') }}
			</div>
		</template>
		<template>
			<div class="content">
				<!-- 23.07.05 기획팀 김연성님 요청으로 삭제 VOC-368-->
				<!-- <h4>{{ tt('GHS 라벨이 생성되지 않는 경우') }}</h4>
				<p class="text">
					{{ tt('화학물질 등록 시 5개의 제조사에 한하여 MSDS 분석 후 GHS 라벨을 자동으로 생성하는 기능을 제공하고 있습니다.') }}
					<br />
					( {{ tt('기능 지원 중인 제조사 : Sigma, TCI, Daejung, Duksan, Samchun') }}) <br /><br />
					{{
						tt(
							'MSDS 분석 기능을 지원하는 제조사가 아닌 경우, 직접 MSDS 파일을 업로드 하고 참고하여 GHS 추출 작업을 수동으로 진행해야 합니다. (추후 업데이트 예정)'
						)
					}}
				</p>
				<br /> -->
				<h4>{{ tt('GHS 라벨 작성 방법') }}</h4>
				<p class="text">
					{{ tt('1. MSDS 문서 내 위험ㆍ유해성 항목을 참고하여 위험성 분류 (물리적 위험성, 건강유해성, 환경유해성)를 선택합니다.') }}
					<br />
					{{ tt('2. MSDS 문서 내 유해ㆍ위험 문구 항목을 참고하여 분류기준에 따라 유해 위험성을 알리는 문구를 모두 선택합니다.') }}
					<br />
					{{ tt('3. 다음버튼을 누른 뒤 공급자 기관명, 연락처, 주소를 입력하여 GHS 라벨 생성을 완료합니다.') }}
				</p>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	name: 'ModalGHSGuide',
	components: {},
	props: {},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	padding: 20px;
	border: 0;
	.title {
		font-size: 18px;
		font-weight: 700;
	}
	.button-gray {
		position: absolute;
		top: 20px;
		right: 65px;
		background: $GREY_4;
		display: flex;
		border-radius: 4px;
		padding: 5px 10px;
		height: 30px;
		font-size: 14px;
		font-weight: 700;
		&:hover {
			background: $HOVER_BUTTON_COMMON;
		}
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 500px;
	height: 500px;
	.modal-content {
		background-color: $LAB_WHITE;
		border-radius: 8px;
		border: 0;
	}
}

::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	padding: 0px;
	gap: 20px;
	overflow: hidden;
	background: white;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	.content {
		height: 100%;
		padding: 20px;
		border-top: 1px solid $DIVIDER;
		h4 {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 8px;
		}
		.text {
			font-size: 14px;
		}
	}
}
</style>
