<template>
	<div class="header-wrapper">
		<h2 class="header-title">{{ title }}</h2>
		<h5 v-if="subTitle" class="header-sub-title">{{ subTitle }}</h5>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<search-box
					ref="search-box"
					class="search-box"
					:placeholder="tt('검색')"
					:hasQR="!isScan"
					:disabled="isScan"
					:isScan="isScan"
					:value="loadParams.keyword"
					@clickQR="$refs['modal-search-qr'].show('inventory')"
					@clickTag="clickTag"
					@change="changeKeyword"
					@resetBarcode="resetBarcode"
					hasTag
				></search-box>
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
					:image="require('@/assets/svg/filter.svg')"
					@click="filterBtnClick"
				></image-button>
				<BtnDropdown
					v-if=""
					ref="BtnBundle"
					:isActive="selectedBundleOption != 'none'"
					:value="selectedBundleOption"
					@change="setBundleOption"
					:text="selectedBundleOptionText"
					:options="bundleOptions"
				/>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button :text="'보기옵션'" :image="require('@/assets/svg/ViewOption(Button).svg')" @click="watchBtnClick"></image-button>
				<excel-download-button
					ref="ExcelDownloadBtn"
					v-if="isInventoryList"
					@downloadAll="exportList('ALL')"
					@downloadFiltered="exportList('FILTERED')"
				/>
				<image-button
					v-else
					:text="'목록 다운로드'"
					:image="require('@/assets/svg/excel-download.svg')"
					@click="exportList('FILTERED')"
				></image-button>
				<image-button
					v-if="isInventoryList"
					:text="'설정'"
					:image="require('@/assets/svg/authority-setting.svg')"
					@click="$refs['inventory-gen2-setting'].show()"
				></image-button>
				<image-button
					v-if="isInventoryList"
					:text="'등록'"
					:image="require('@/assets/svg/icon/black-round-plus.svg')"
					@click="$refs['item-modals'].showRegist()"
				></image-button>
			</div>
		</div>
		<div class="status-btn-wrapper" v-if="isInventoryList">
			<list-filter-tab
				v-for="category in filterTabButton"
				:isActive="category.id === selectedCategory"
				:text="category.name"
				:key="category.id"
				@click="changeActiveStatus(category.id)"
			></list-filter-tab>
			<button class="favorite-button" :class="{ active: loadParams.isFavorite }">
				<input type="checkbox" class="favorite" v-model="isFavorite" />
			</button>
		</div>
		<item-modals ref="item-modals"></item-modals>
		<modal-filter ref="modal-filter" @changeLoadParams="changeLoadParams" />
		<modal-view-option ref="modal-view-option" />
		<inventory-gen2-setting ref="inventory-gen2-setting"></inventory-gen2-setting>
		<modal-search-product-using-qr ref="modal-search-qr" @scan="searchUsingScan"></modal-search-product-using-qr>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import ListFilterTab from '@/views/common/components/buttons/ListFilterButton.vue'
import SearchBox from '@/views/components/searchBox'
import BtnDropdown from '@/views/components/dropdown/BtnDropdown'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ExcelDownloadButton from '@/views/common/components/baseButton/ExcelDownloadButton.vue'
import { RegistType } from '@/utils/define/ItemCode'
import ModalFilter from './modal/ModalFilter.vue'
import ModalViewOption from './modal/ModalViewOption.vue'
import { ToastType } from '@/utils/define/ToastType'
import ItemModals from '@/views/inventoryGen2/components/regist/ItemModals.vue'
import InventoryGen2Setting from '@/views/inventoryGen2/setting'
import ModalSearchProductUsingQr from '@/views/common/components/modalGen2/ModalSearchProductUsingQr.vue'

export default {
	components: {
		SearchBox,
		ListFilterTab,
		ModalFilter,
		ModalViewOption,
		ExcelDownloadButton,
		ItemModals,
		InventoryGen2Setting,
		ModalSearchProductUsingQr,
		BtnDropdown,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subTitle: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapState('inventoryGen2/list', ['loadParams', 'isScan', 'selectedBundleOption', 'bundleOptions']),
		...mapGetters('inventoryGen2/list', ['selectedCategory', 'filteredCount', 'selectedBundleOptionText']),
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
		filterTabButton() {
			return [
				{ id: 'ALL', name: '모든 카테고리' },
				{ id: 'CAT_CHEMICAL', name: '화학물질' },
				{ id: 'CAT_CHROMATOGRAPHY', name: '분석용 칼럼' },
				{ id: 'CAT_LABWARE', name: '연구장비' },
				{ id: 'CAT_PROTECTION', name: '보호구' },
				{ id: 'CAT_ETC', name: '소모품' },
			]
		},
		isInventoryList() {
			return this.$route.name == PageName.InventoryGen2.List && this.userPermission.item && this.userPermission.item.create
		},
		isFavorite: {
			get() {
				return this.loadParams.isFavorite
			},
			set(value) {
				this.changeLoadParams({ isFavorite: value })
			},
		},
		bundleTestTarget() {
			return [
				'11ee1faa6ba254aa',
				'11edb34083740990',
				'11ed96be0623aa48',
				'11edda847e745e6b',
				'11ecc41c89046619',
				'11ed28e635d55f7a',
				'11ece218b2c2a97f',
				'11ec5417a7452075',
				'11ecd806f16589ff',
			]
		},
		isBundleTargetInstitute() {
			return this.bundleTestTarget.includes(this.$route.params.instituteId)
		},
	},
	methods: {
		...mapActions('inventoryGen2/list', ['exportExcel', 'getQrSearchList', 'getTagList', 'setBundleOption']),
		...mapMutations('inventoryGen2/list', ['setIsScan', 'initFilterCookies']),
		...mapMutations('inventoryGen2/form', ['setItemFormType']),
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		watchBtnClick() {
			this.$refs['modal-view-option'].show()
		},
		registBtnClick() {
			if (this.$root.userPermission.item?.create) this.$router.push({ name: PageName.InventoryGen2.Create })
			else this.$root.toast('권한', '물품 등록 권한이 없습니다.', 'error')
		},
		changeActiveStatus(id) {
			if (id != this.selectedCategory && this.filteredCount > 0) {
				this.initFilterCookies(true)
				this.$root.toast('필터', '필터가 해제되었습니다', 'success')
			}
			const classification = id === 'ALL' ? [] : [id]
			this.changeLoadParams({ categoriesClassification: classification })
		},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword, tagsId: [] })
		},
		async clickTag(id) {
			await this.changeLoadParams({ tagsId: [id] })
		},
		async changeLoadParams(params) {
			await this.$emit('changeLoadParams', params)
		},
		async exportList(type) {
			this.$refs['ExcelDownloadBtn']?.setLoading(true)
			const response = await this.exportExcel(type)
			window.fileDownload(response, `${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('물품목록')}.xlsx`)
			this.$refs['ExcelDownloadBtn']?.setLoading(false)
		},
		registItemNextStep(type) {
			switch (type) {
				case RegistType.AUTO.IMAGE:
					this.$refs['modal-camera'].show()
					break
				default:
					break
			}
		},
		noPermissionCam() {
			this.$refs['modal-regist-item'].show()
			this.$root.toast('카메라 사용 차단됨', '브라우저 설정에서 차단을 해제해주세요', ToastType.ERROR)
		},
		changeUrls(urls) {
			console.warn(urls)
		},
		async searchUsingScan(scannedQr) {
			this.$refs['modal-search-qr'].setIsLoading(true)
			const response = await this.getQrSearchList({ qrBarcode: scannedQr, offset: 0, length: this.loadParams.length })
			this.$refs['modal-search-qr'].setIsLoading(false)

			this.$refs['modal-search-qr'].hide()
			if (response.count > 0) {
				this.$refs['search-box'].text = 'QR code/Barcode 스캔됨'
				this.$root.toast('QR 스캔 성공', '일치하는 데이터를 불러옵니다', 'success')
			} else {
				this.$root.toast('일치하는 데이터가 없음', '등록할 물품을 검색해주세요', 'warning')
			}
		},
		resetBarcode() {
			this.$refs['search-box'].text = ''
			this.changeLoadParams({ qrBarcode: '' })
			this.setIsScan(false)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/list/_list-header.scss';
.favorite-button {
	height: 30px;
	width: 36px;
	background-color: $GREY_4;
	border-radius: 10px;
	&:hover {
		filter: brightness(95%);
	}
	&.active {
		background-color: $SUB-YELLOW_200;
	}
	.favorite {
		&:after {
			top: -12px !important;
		}
	}
}
</style>
