<template>
	<div class="form-input">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="imglist">
			<div v-if="!isImageLengthFull">
				<label class="img-add" for="upload-img">
					<img src="./svg/add-image-icon.svg" />
				</label>
				<input id="upload-img" multiple hidden class="img-upload" type="file" @change="onChange" accept="image/*" ref="upload-input" />
			</div>
			<div v-for="(file, idx) in files" class="img-wrap" :key="fileUrl(file)">
				<img class="imgitem" :src="fileUrl(file)" @click="$refs['image-viewer'].show(idx)" />
				<img class="icon-remove" src="./svg/icon-remove-img.svg" @click="removeImg(idx)" />
			</div>
		</div>
		<modal-multi-image-viewer ref="image-viewer" title="이미지" :imageList="imageUrlList"></modal-multi-image-viewer>
	</div>
</template>

<script>
import ModalMultiImageViewer from '@/views/common/components/modalGen2/ModalMultiImageViewer.vue'

import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'FormImage',
	components: { ModalMultiImageViewer },
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: Array,
			default: () => [],
		},
		required: {
			type: Boolean,
			default: false,
		},
		maxImageLength: {
			type: Number,
			default: 10,
		},
	},
	computed: {
		...mapGetters('form/image', ['removeImageLink']),
		imageUrlList() {
			return this.files?.map(item => this.fileUrl(item))
		},
		isImageLengthFull() {
			return this.files?.length >= this.maxImageLength
		},
	},
	data() {
		return {
			files: [],
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.files = this.value?.filter(file => !this.removeImageLink || (this.removeImageLink && !this.removeImageLink?.includes(file)))
		})
	},
	watch: {},
	methods: {
		onChange(e) {
			var file = e.target.files
			if (file.length + this.files.length > this.maxImageLength) {
				this.$root.toast('이미지', `이미지는 최대 ${this.maxImageLength}개 등록할 수 있습니다.`, 'error')
				return
			}
			this.files.push(...file)
			this.$refs['upload-input'].value = null
			this.$emit('input', this.files)
		},
		removeImg(clickIdx) {
			const removeIds = this.files[clickIdx]?.id
			this.files = this.files.filter((file, idx) => idx !== clickIdx)
			this.$emit('input', this.files)
			// 편집시 삭제할 이미지 링크
			console.log(removeIds)
			this.$emit('removeImg', removeIds ? [removeIds] : [])
		},
		fileUrl(file) {
			if (typeof file === 'object') {
				return file?.link ? file.link : URL.createObjectURL(file)
			} else {
				return file
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-image-list.scss';
</style>
