<template>
	<div class="modal-footer-wrap">
		<div class="left">
			<button v-if="isPrev" class="button-white" @click="$emit('goPrev')">{{ tt(prevText) }}</button>
		</div>
		<div class="right">
			<button class="button-white" @click="$emit('cancel')">{{ tt(cancelText) }}</button>
			<button class="button-primary" @click="$emit('ok')" :disabled="isOkDisabled">{{ tt(okText) }}</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ModalFooter',
	components: {},
	props: {
		okText: {
			type: String,
			default: '완료',
		},
		cancelText: {
			type: String,
			default: '취소',
		},
		prevText: {
			type: String,
			default: '이전으로',
		},
		isPrev: {
			type: Boolean,
			default: false,
		},
		isOkDisabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.modal-footer-wrap {
	width: 100%;
	display: flex;
	justify-content: space-between;

	.left {
		display: flex;
		gap: 10px;
		align-items: flex-end;
	}
	.right {
		display: flex;
		gap: 10px;
		align-items: flex-end;
	}
	.left,
	.right {
		button {
			border: 0;
			box-shadow: none;
			display: flex;
			height: auto;
			align-items: center;
			cursor: pointer;
			white-space: pre;
			min-width: 100px;
			padding: 5px 10px;
			font-size: 14px;
			font-weight: 700;
			border-radius: 5px;
			justify-content: center;
			&:disabled {
				cursor: not-allowed;
			}
		}
		.button-white {
			background: $LAB_WHITE;
			border: 1px solid $DIVIDER;
			color: #111 !important;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
					linear-gradient(0deg, #fff, #fff);
			}
		}
		.button-primary {
			background: $LAB_YELLOW;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
			}
		}
	}
}
</style>
