<template>
	<div class="card storageQuantity" :class="[{ warning: list.unsafeCount > 0 }, { safe: list.unsafeCount == 0 }, { displayOff: !displayCard }]">
		<div class="card-title">
			<div class="imgbox">
				<img src="@/assets/svg/dashboard-icon5.svg" alt="icon" />
			</div>
			<h4 class="Btn__hover-balloon-w" @click="goPage">
				{{ tt('저장소별 위험물 지정수량 현황') }}
				<div class="balloon">
					<p>{{ tt('위험물 지정수량 배수로 이동합니다') }}</p>
				</div>
				<button>
					<img src="@/assets/svg/arrow-diagonal.svg" alt="icon" />
				</button>
			</h4>
			<h3
				class="ml-auto"
				v-if="setting == false"
				v-text="list.unsafeCount > 0 ? list.unsafeCount + tt('개') + '\n' + tt('위험') : tt('안전')"
			></h3>
			<div class="ml-auto switch-setting" v-else>
				<h5>{{ tt('대시보드에 노출') }}</h5>
				<label class="switch">
					<input type="checkbox" v-model="displayCard" @change="toggleCard(id, displayCard)" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="card-noti">
			<h6>{{ tt('위험물 지정수량 초과 현황') }}</h6>
			<p v-if="list.unsafeCount > 0" v-html="tt('저장소별로 <span style=color:#ff3b31>초과된 품목</span>만 보여집니다.')"></p>
			<p v-else v-html="tt('모든 저장소가 <span style=color:#0dc577;>안전하게</span> 관리되고 있습니다.')"></p>
		</div>
		<div class="card-box" v-for="(item, index) in list.list" :key="index" @click="goStorage(item.name)">
			<div class="box-title">
				<h5>{{ item.name }}</h5>
				<h4 class="ml-auto" :class="item.status == 'UNSAFE' ? 'warning' : 'safe'">
					<img src="@/assets/svg/icon-error.svg" alt="icon" />
					<span v-text="item.status == 'UNSAFE' ? tt('위험') : tt('안전')"></span>
				</h4>
			</div>
			<div class="box-content">
				<div class="box-inner" v-for="(subitem, index) in item.unsafeLegalList" :key="'storage-' + index">
					<span class="subtitle">{{ subitem.legalName }}</span>
					<ul>
						<li v-for="item in subitem.childLegalList" :key="item.legalId">
							<p>{{ item.legalName }}</p>
							<span><img src="@/assets/svg/icon-error.svg" alt="icon" /></span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'CardStorageQuantity',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Object,
		},
		toggleCard: {
			type: Function,
			default: () => {},
		},
	},
	mounted() {
		this.displayCard = this.visible
	},
	data() {
		return {
			displayCard: true,
		}
	},
	methods: {
		// dangerNotice() {
		//     let dangerArr = []
		//     let dangerCount = 0

		//     for (let i = 0; i < this.list.length; i++) {
		//         dangerArr.push(this.list[i].state)

		//         if (dangerArr[i] == 'danger') {
		//             dangerCount++
		//         }
		//     }

		//     return dangerCount
		// },
		goPage() {
			this.$router.push({
				name: PageName.Safety.DangerousStorage,
			})
		},
		goStorage(name) {
			this.$router.push({
				name: PageName.Safety.DangerousStorage,
				query: {
					storage: name,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';
</style>
