<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Location__delete-location-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt(title) }}</h5>
					<button class="Popup__close-btn" @click="close" v-if="!hideClose">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text" v-html="tt(content)"></p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__delete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="close" v-if="!hideCancel">
							{{ tt(cancelText) }}
						</button>
						<button class="Btn__confirm" @click="confirm" v-if="!hideConfirm">
							{{ tt(buttonText) }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
export default {
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
		},
		content: {
			type: String,
		},
		buttonText: {
			type: String,
			default: '확인',
		},
		cancelText: {
			type: String,
			default: '취소',
		},
		hideConfirm: {
			type: Boolean,
			default: false,
		},
		hideCancel: {
			type: Boolean,
			default: false,
		},
		hideClose: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		confirm() {
			this.$emit('confirm')
			this.close()
		},
		close() {
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
.Btn__confirm {
	background: linear-gradient(90deg, #fed671 0%, $LAB_YELLOW 98.81%) !important;
	color: black !important;
	width: 100% !important;
}

.red::v-deep {
	color: red;
}
</style>
