<template>
	<div
		class="lab-selector-item-wrapper"
		:class="{ checked: isChecked, ' no-permission': !item.canMigration }"
		@click.stop="handleClick"
		v-if="item"
	>
		<div class="initial-container" :class="{ 'no-permission': !item.canMigration }">
			<span class="initial-name" v-text="initialName"></span>
		</div>
		<div class="text-wrapper" :class="{ 'no-permission': !item.canMigration }">
			<div class="lab-name" v-text="item.labName"></div>
			<div class="subtext" v-text="itemSubtext"></div>
		</div>
		<div class="icon-wrapper" :class="{ 'no-permission': !item.canMigration }">
			<img v-if="item.canMigration" class="select-icon" />
			<div v-else class="no-permission-icon-wrapper">
				<div class="no-permission-text">{{ tt('권한없음') }}</div>
				<img class="select-icon" :class="{ 'no-permission': !item.canMigration }" />
			</div>
		</div>
		<div class="Btn__hover-balloon-w">
			<div class="balloon">
				<p v-html="ballonText"></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: null,
		},
		selectedLabId: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			ballonText: this.tt('가입되어있는 연구실 중, 권한이 <b>PI</b> 혹은 <b>관리자</b>인 <br> 연구실만 랩매니저 PRO로 이전할 수 있습니다.'),
		}
	},
	computed: {
		itemSubtext() {
			return `${this.item.instituteName} | ${this.item.piName} | ${this.item.memberCount}${this.tt('명')}`
		},
		initialName() {
			return this.item.labName.slice(0, 1).toUpperCase()
		},
		isChecked() {
			return this.selectedLabId.includes(this.item.labId) && this.item.canMigration
		},
	},
	methods: {
		handleClick() {
			if (!this.item.canMigration) return

			const includeLabId = this.selectedLabId.includes(this.item.labId)
			let newSelectedLabId = []
			if (includeLabId) {
				newSelectedLabId = this.selectedLabId.filter(id => id !== this.item.labId)
			} else {
				newSelectedLabId = [...this.selectedLabId, this.item.labId]
			}
			this.$emit('click', newSelectedLabId)
		},
	},
}
</script>

<style lang="scss" scoped>
.lab-selector-item-wrapper {
	display: flex;
	height: 80px;
	border: 1px solid $DIVIDER;
	padding: 20px;
	align-items: center;
	margin-right: -10px;

	.initial-container {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		background: $LAB_YELLOW;
		font-size: 16px;
		font-weight: 700;
		line-height: 23px;
		letter-spacing: 0px;
		text-align: center;
		justify-content: center;
		display: flex;
		align-items: center;

		&.no-permission {
			background: $GREY_2;
			color: $PLACEHOLDER !important;
		}
	}
	.text-wrapper {
		display: flex;
		width: 400px;
		padding: 0px 20px;
		flex-direction: column;
		text-align: left;
		letter-spacing: 0px;
		&.no-permission {
			.lab-name {
				color: $PLACEHOLDER;
			}
			.subtext {
				color: $PLACEHOLDER;
			}
		}
		.lab-name {
			font-size: 16px;
			font-weight: 700;
			line-height: 23px;
		}
		.subtext {
			font-size: 12px;
			font-weight: 400;
			line-height: 17px;
			color: #00000066;
			letter-spacing: 0px;
		}
	}
	img.select-icon {
		content: url(~@/assets/svg/check/icon-select-unchecked.svg);
		width: 20px;
		height: 20px;
	}
	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 120px;
		.no-permission-icon-wrapper {
			display: flex;
			align-items: center;
		}
	}

	.select-icon {
		&.no-permission {
			content: url(~@/assets/svg/check/icon-select-no-permission.svg);
			width: 20px;
			height: 20px;
		}
	}
	&:not(.no-permission):hover {
		img.select-icon {
			content: url(~@/assets/svg/check/icon-select-hover.svg);
			width: 20px;
			height: 20px;
		}
	}

	&.checked {
		background: $SUB-YELLOW_100;
		img.select-icon {
			content: url(~@/assets/svg/check/icon-select-checked.svg);
			width: 20px;
			height: 20px;
		}
	}

	.no-permission-text {
		color: $RED;
		font-size: 12px;
		font-weight: 400;
		line-height: 17px;
		letter-spacing: 0px;
		text-align: right;
		padding-right: 6px;
	}

	/* 권한 없는 경우 말풍선 */
	&.no-permission {
		&:hover {
			.balloon {
				visibility: visible;
				transition: 0.3s all ease;
			}
		}
		.balloon {
			position: absolute;
			padding: 10px 10px;
			display: block;
			background: $LAB_WHITE;
			border-radius: 5px;
			top: 33px;
			left: -365px;
			color: #000;
			font-weight: normal;
			visibility: hidden;
			z-index: 10000;
			border: 1px solid $SECONDARY_2;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
			width: 274px;
			box-sizing: border-box;
			max-width: none;
			white-space: initial;
			text-align: left;
			h6 {
				font-size: 14px;
				color: #000;
				font-weight: 700;
				padding-bottom: 3px;
			}
			p {
				width: 100% !important;
				/* word-break: keep-all; */
				font-size: 12px;
				font-weight: 400;
			}
		}
		.balloon:before {
			border-top: 0px solid transparent;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-bottom: 9px solid rgba(0, 0, 0, 0.6);
			content: '';
			position: absolute;
			top: -9.2px;
			left: 240px;
			z-index: 1003;
		}

		.balloon:after {
			border-top: 0px solid transparent;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 8px solid $LAB_WHITE;
			content: '';
			position: absolute;
			top: -8px;
			left: 241px;
			z-index: 1004;
		}
	}
}
</style>
