<template>
	<div class="badge-list-wrap" v-if="item">
		<item-badge :image="categoryImage" :text="categoryName" subtext="카테고리"></item-badge>
		<item-badge
			:image="require(`@/views/purchase/cargo/svg/badge-volume${isCurrentVolumeValid ? '' : '-red'}.svg`)"
			:text="formatVolumeBadge"
			subtext="잔여 용량"
			:isValid="isCurrentVolumeValid"
		></item-badge>
		<item-badge
			v-if="item.expireDatetime"
			:image="require(`@/views/purchase/cargo/svg/badge-expiredate${isNotExpired ? '' : '-red'}.svg`)"
			:text="isNotExpired ? formatDateFromISO(item.expireDatetime, '~yyyy-MM-dd') : tt('만료됨')"
			subtext="유효기간"
			:isValid="isNotExpired"
		></item-badge>
		<item-badge
			v-if="legals && legals['LEGAL-WG'].list.length > 0"
			:text="tt('위험물관리법')"
			:image="require('@/views/purchase/cargo/svg/badge-legal-danger.svg')"
			subtext="관련법령"
		></item-badge>
		<item-badge
			v-if="legals && legals['LEGAL-PG'].list.length > 0"
			:text="tt('폐기물관리법')"
			:image="require('@/views/purchase/cargo/svg/badge-legal-waste.svg')"
			subtext="관련법령"
		></item-badge>
		<item-badge
			v-if="legals && legals['LEGAL-SAB'].list.length > 0"
			:text="tt('산업안전보건법')"
			:image="require('@/views/purchase/cargo/svg/badge-legal-safety.svg')"
			subtext="관련법령"
		></item-badge>
		<item-badge
			v-if="legals && legals['LEGAL-HMG'].list.length > 0"
			:text="tt('화학물질관리법')"
			:image="require('@/views/purchase/cargo/svg/badge-legal-chemical.svg')"
			subtext="관련법령"
		></item-badge>
		<item-badge
			v-if="item.category.type.includes('GAS') && legals && legals['LEGAL-GG'].list.length > 0"
			:image="require('@/views/purchase/cargo/svg/badge-legal-gas.svg')"
			subtext="관련법령"
		></item-badge>
	</div>
</template>
<script>
import ItemBadge from '@/views/purchase/cargo/components/cargoDetail/badge/ItemBadge.vue'
import MixinItem from '@/mixins/inventory/MixinItem'
import { MiddleClassToString } from '@/utils/define/ItemCode'
import Decimal from 'decimal.js'
export default {
	name: 'BadgeListChemical',
	mixins: [MixinItem],
	components: { ItemBadge },
	props: {
		item: {
			type: Object,
			default: null,
		},
		legals: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			nullText: `<p class="nodata">${this.tt('정보없음')}</p>`,
		}
	},
	computed: {
		isCurrentVolumeValid() {
			return this.item?.currentVolume > 0
		},
		isNotExpired() {
			return this.item?.expireDatetime >= this.$DateTime.now().toISO()
		},
		formatVolumeBadge() {
			const volume =
				this.item.currentVolume > 0 && this.item.currentVolume < 1
					? Decimal(this.item.currentVolume).toFixed()
					: Number(this.item.currentVolume).toLocaleString()
			return this.isCurrentVolumeValid ? `${volume}${this.item.volumeUnit}` : this.tt('잔량없음')
		},
		categoryImage() {
			switch (this.item?.category?.type) {
				case 'CAT_CHEMICAL_REAGENT':
					return require('@/views/purchase/cargo/svg/category/reagent.svg')
				case 'CAT_CHEMICAL_MADE_REAGENT':
					return require('@/views/purchase/cargo/svg/category/made-reagent.svg')
				case 'CAT_CHEMICAL_RAWMATERIAL':
					return require('@/views/purchase/cargo/svg/category/raw-material.svg')
				case 'CAT_CHEMICAL_MADE_RAWMATERIAL':
					return require('@/views/purchase/cargo/svg/category/made-raw-material.svg')
				case 'CAT_CHEMICAL_STANDARD':
					return require('@/views/purchase/cargo/svg/category/standard.svg')
				default:
					return require('@/views/purchase/cargo/svg/category/reagent.svg')
			}
		},

		categoryName() {
			if (this.item?.category?.name) {
				return this.item.category.name.length >= 10 ? this.item.category.name.slice(0, 10) : this.item.category.name
			} else {
				return MiddleClassToString(this.item?.category?.type)
			}
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.badge-list-wrap {
	display: inherit;
	flex-direction: row;
	padding: 0px 30px;
	border-bottom: 1px solid $DIVIDER;
}

.badge-wrap {
	border-right: 1px solid $DIVIDER;
}

.badge-wrap + .badge-wrap {
	border-right: 1px solid $DIVIDER;
}
</style>
