<template>
	<div class="detail-wrapper">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ tt(title) }}</h2>
			</div>
		</div>
		<!-- <budget-info v-if="page == 1" /> -->
		<component v-bind:is="pageName" ref="registration-form" />
		<div class="common-detail-bottom">
			<!-- TODO: fixed bottom -->
			<base-button size="lg" color="primary only" @click="cancel">{{ tt(page == 1 ? '나가기' : '이전으로') }}</base-button>
			<base-button size="lg" color="primary" @click="nextStep">{{ tt('다음으로') }}</base-button>
		</div>
		<modal-confirm
			ref="modal-out"
			title="페이지 이동"
			:confirmText="page == 1 ? '나가기' : '이전으로'"
			content="페이지를 이동하면 지금까지 작성한 내용이 사라집니다.<br>페이지를 이동하시겠습니까?"
		/>
	</div>
</template>
<script>
import BudgetInfo from './info.vue'
import BudgetDivde from './divide.vue'

import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import { title } from 'process'
export default {
	components: {
		BudgetInfo,
		BudgetDivde,
	},
	computed: {
		...mapState('budget/item', ['registrationInfo', 'item']),
		page() {
			return this.$route.query.page ? this.$route.query.page : 1
		},
		pageName() {
			return this.page == 1 ? BudgetInfo : BudgetDivde
		},
		id() {
			return this.$route.params.id
		},
		title() {
			return `예산 ${this.id != null ? '편집' : '등록'}`
		},
	},
	async created() {
		this.initRegistrationInfo()
		this.setStorageDetailPaths([this.title])
		if (this.id) {
			await this.getDetail(this.id)
			this.setData()
		}
	},
	methods: {
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		...mapMutations('budget/item', ['initRegistrationInfo', 'setRegistrationInfo']),
		...mapActions('budget/item', ['createBudget', 'editBudget', 'getDetail']),
		cancel() {
			this.$refs['modal-out'].show(() => {
				this.$router.go(-1)
			})
		},
		async nextStep() {
			if (!this.$refs['registration-form'].isValid()) return
			if (this.page == 1) {
				this.$router.push({ query: { page: 2 } })
			} else {
				try {
					const response = this.id ? await this.editBudget() : await this.createBudget()
					if (response.status == 201 || response.status == 205) {
						this.$root.toast(this.title, `${this.title}이 완료되었습니다.`, 'success')
						this.$router.go(-2)
					}
				} catch (err) {
					this.$root.toast('등록 실패', err.response.data.msg, 'error')
				}
			}
		},
		setData() {
			let info = {}
			Object.keys(this.registrationInfo).forEach(key => {
				if (this.item[key]) {
					info[key] = this.$_.cloneDeep(this.item[key])
				}
			})
			info.budgetId = this.item.id
			let budget = {}
			if (info.budgetType == 'YEAR') budget.year = this.item.summary.budget
			else
				this.item.groups.forEach(g => {
					Object.entries(g.expenditure).forEach(([key, value]) => {
						if (key != 'total') budget[key] = value.budget
					})
				})
			info.budget = budget
			this.setRegistrationInfo(info)
			this.$refs['registration-form'].setData()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.subtext {
	margin-top: 10px;
	font-size: 14px;
	color: $SECONDARY_2;
}
.common-detail-wrapper {
	min-height: calc(100% - 130px);
}
</style>
