<template>
	<b-modal centered ref="modal-form-cargo-management" scrollable no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<cargo-form-management ref="form-management"></cargo-form-management>
			<form-select-sticker
				ref="form-select-sticker"
				:category="category"
				:item="cargoInfo"
				:isGhs="!!cargoInfo.ghs"
				@change="setStickerType"
				@completeGHS="completeGHS"
			></form-select-sticker>
			<form-check-auto-cargo></form-check-auto-cargo>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left">
					<button class="button-white" @click="$emit('goPrev')">
						{{ tt('이전으로') }}
					</button>
				</div>
				<div class="footer-right">
					<button class="button-white" @click="cancel">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="clickComplete">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import CargoFormManagement from '@/views/purchase/cargo/components/cargoList/form/CargoFormManagement.vue'
import FormSelectSticker from '@/views/common/components/form/FormSelectSticker.vue'
import FormCheckAutoCargo from '@/views/common/components/form/FormCheckAutoCargo.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import { LargeClass } from '@/utils/define/ItemCode'

import MixinFormScroll from '@/mixins/form/formScroll'
export default {
	name: 'ModalFormCargoManagement',
	components: { CargoFormManagement, FormSelectSticker, FormCheckAutoCargo },
	mixins: [MixinFormScroll],
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('cargo/form', [
			'category',
			'isActiveExpireDate',
			'expirationDay',
			'cargoInfo',
			'isManagementValid',
			'isManagementUnitValid',
			'cargoFormType',
		]),
		...mapGetters('cargo/item', ['item']),
		title() {
			switch (this.cargoFormType) {
				case 'ADD':
					return '관리정보 등록'
				case 'NEED_INPUT_LIST':
				case 'NEED_INPUT_DETAIL':
					return '관리정보 입력'
				case 'EDIT':
					return '관리정보 편집'
				default:
					return '관리정보 등록'
			}
		},
		isCategoryChemical() {
			return this.category?.classification === LargeClass.CHEMICAL
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('cargo/form', ['setCargoInfo', 'setInitCargoInfo', 'setShowError']),
		...mapActions('cargo/form', ['registCargo', 'editCargo']),
		...mapActions('cargo/list', ['getCargoList']),
		...mapActions('cargo/item', ['getCargoItem']),
		...mapMutations('tag', ['setTagList']),
		...mapMutations('form/image', ['setRemoveImageLink']),
		setStickerType(type) {
			this.setCargoInfo({ ...this.cargoInfo, stickerType: type })
		},
		completeGHS(ghs) {
			if (ghs) this.setCargoInfo({ ...this.cargoInfo, ghs: ghs })
			else {
				let cargoInfo = { ...this.cargoInfo }
				delete cargoInfo['ghs']
				this.setCargoInfo(cargoInfo)
			}
		},
		show() {
			this.$refs['modal-form-cargo-management'].show()
			if (this.isActiveExpireDate) {
				this.setCargoInfo({ ...this.cargoInfo, expireDatetime: this.$DateTime.now().plus({ days: this.expirationDay }).toISODate() })
			}
			this.$nextTick(() => {
				this.$refs['form-select-sticker'].setData(this.cargoInfo.stickerType)
			})
		},
		hide() {
			this.$refs['modal-form-cargo-management'].hide()
		},
		cancel() {
			this.$refs['modal-form-cargo-management'].hide()
			this.setInitCargoInfo()
			this.setTagList(null)
			this.setRemoveImageLink(null)
		},
		async clickComplete() {
			const isInvalid = this.isManagementValid && this.isManagementUnitValid
			if (isInvalid) {
				await this.registOrEdit()
				this.hide()
				this.setInitCargoInfo()
			} else {
				this.setShowError(!isInvalid)
				// await this.$refs['form-management'].showRequire()
				await this.scrollToInvalid()
			}
		},
		async registOrEdit() {
			switch (this.cargoFormType) {
				case 'ADD':
					await this.registCargo('MANUAL_CARGO')
					await this.getCargoList()
					this.$root.toast('정보입력 완료', '정보입력 완료하였습니다.', ToastType.SUCCESS)
					break
				case 'NEED_INPUT_LIST':
					await this.editCargo()
					await this.getCargoList()
					this.$root.toast('정보입력 완료', '정보입력 완료하였습니다.', ToastType.SUCCESS)
					break
				case 'NEED_INPUT_DETAIL':
					await this.editCargo()
					await this.getCargoItem(this.item.id)
					this.$root.toast('정보입력 완료', '정보입력 완료하였습니다.', ToastType.SUCCESS)
					break
				case 'EDIT':
					await this.editCargo()
					await this.getCargoItem(this.item.id)
					this.$root.toast('정보편집 완료', '정보편집 완료하였습니다.', ToastType.SUCCESS)
					break
				default:
					break
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
@import '@/styles/scss/modal/_modal-form-cargo.scss';
</style>
