var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "dashboard",
    staticClass: "Dashboard",
    "class": [{
      setting: _vm.setting
    }, {
      scroll: _vm.dashboardScroll > 200
    }],
    on: {
      "scroll": _vm.scroll
    }
  }, [_c('h2', {
    staticClass: "AppContainer__title"
  }, [_c('div', {
    staticClass: "flex"
  }, [_vm._v(" " + _vm._s(_vm.setting ? _vm.tt('대시보드 설정') : _vm.tt('대시보드')) + " "), _c('div', {
    staticClass: "ml-auto"
  }, [_vm.setting == false ? _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        _vm.setting = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/authority-setting.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('설정')) + " ")]) : _vm._e(), _vm.setting == true ? _c('button', {
    staticClass: "btn Btn__close",
    on: {
      "click": _vm.cancelSetting
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]) : _vm._e(), _vm.setting == true ? _c('button', {
    staticClass: "btn Btn__complete",
    on: {
      "click": _vm.saveSetting
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('저장')) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "AppContainer"
  }, [_vm.setting == true && _vm.noti == true ? _c('div', {
    staticClass: "Dashboard-setting-noti"
  }, [_c('h5', [_vm._v("💡 " + _vm._s(_vm.tt('대시보드 설정하기')))]), _c('ul', [_c('li', [_vm._v(" 1. "), _c('img', {
    attrs: {
      "src": require("@/assets/images/dashboard-switch".concat(_vm.$root.user.language == 'en' ? '_en' : '', ".png"))
    }
  }), _vm._v(" " + _vm._s(_vm.tt('스위치를 On/Off 하여 대시보드에서 보여줄 항목을 선택해주세요.')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('2. 항목을 드래그하여 위치를 변경할 수 있습니다.')) + " ")]), _c('li', [_vm._v(_vm._s(_vm.tt('3. 대시보드 설정은 계정별로 저장됩니다.')))])]), _c('button', {
    staticClass: "close",
    on: {
      "click": function click($event) {
        _vm.noti = false;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/member-invite-authority-x.svg"),
      "alt": "icon"
    }
  })])]) : _vm._e(), _vm.setting == true && _vm.noti == true ? _c('div', {
    staticClass: "Dashboard-init-noti"
  }, [_c('h5', [_vm._v("↩️ " + _vm._s(_vm.tt('대시보드 초기화')))]), _c('p', [_vm._v(_vm._s(_vm.tt('대시보드 기본 설정으로 초기화됩니다.')))]), _c('button', {
    staticClass: "Btn__black",
    on: {
      "click": _vm.showInit
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('초기화')) + " ")])]) : _vm._e(), _vm.copyDashboardList ? _c('draggable', _vm._b({
    staticClass: "card-wrap",
    attrs: {
      "list": _vm.copyDashboardList.left,
      "group": "people"
    },
    on: {
      "start": function start($event) {
        _vm.drag = true;
      },
      "end": function end($event) {
        _vm.drag = false;
      }
    }
  }, 'draggable', _vm.dragOptions, false), [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": !_vm.drag ? 'flip-list' : null
    }
  }, _vm._l(_vm.copyDashboardList.left, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "card-box"
    }, [_c('dashboard-cards', {
      attrs: {
        "id": item.id,
        "visible": item.visible,
        "setting": _vm.setting,
        "instId": _vm.$route.params.instituteId,
        "createTrend": _vm.createTrend,
        "createSummary": _vm.createSummary,
        "disposalTrend": _vm.disposalTrend,
        "disposalSummary": _vm.disposalSummary,
        "useTrend": _vm.useTrend,
        "useSummary": _vm.useSummary,
        "possessionTrend": _vm.possessionTrend,
        "disposalItem": _vm.disposalItem,
        "msdsUpload": _vm.msdsUpload,
        "diagnosisList": _vm.diagnosisList,
        "dangerStorage": _vm.dangerStorage,
        "legalList": _vm.legalList,
        "license": _vm.license,
        "userPermission": _vm.userPermission
      },
      on: {
        "visibleCard": _vm.visibleCard
      }
    })], 1);
  }), 0)], 1) : _vm._e(), _vm.copyDashboardList ? _c('draggable', _vm._b({
    staticClass: "card-wrap",
    attrs: {
      "list": _vm.copyDashboardList.right,
      "group": "people",
      "ghost-class": "ghost2"
    },
    on: {
      "start": function start($event) {
        _vm.drag = true;
      },
      "end": function end($event) {
        _vm.drag = false;
      }
    }
  }, 'draggable', _vm.dragOptions, false), [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": !_vm.drag ? 'flip-list' : null
    }
  }, _vm._l(_vm.copyDashboardList.right, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "card-box"
    }, [_c('dashboard-cards', {
      attrs: {
        "id": item.id,
        "visible": item.visible,
        "setting": _vm.setting,
        "instId": _vm.$route.params.instituteId,
        "createTrend": _vm.createTrend,
        "createSummary": _vm.createSummary,
        "disposalTrend": _vm.disposalTrend,
        "disposalSummary": _vm.disposalSummary,
        "useTrend": _vm.useTrend,
        "useSummary": _vm.useSummary,
        "possessionTrend": _vm.possessionTrend,
        "disposalItem": _vm.disposalItem,
        "msdsUpload": _vm.msdsUpload,
        "diagnosisList": _vm.diagnosisList,
        "dangerStorage": _vm.dangerStorage,
        "legalList": _vm.legalList,
        "license": _vm.license,
        "userPermission": _vm.userPermission
      },
      on: {
        "visibleCard": _vm.visibleCard
      }
    })], 1);
  }), 0)], 1) : _vm._e()], 1), _c('modal-main-notice', {
    ref: "modal-main-notice"
  }), _c('modal-main-research', {
    ref: "modal-main-research"
  }), _c('modal-common', {
    ref: "modal-common",
    on: {
      "isHide": _vm.initDashboard
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }