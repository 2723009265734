const index = {
	namespaced: true,
	// 상태가 fold이면 true
	state: () => ({
		openMenuList: ['홈', '물품관리', '구매관리'],
		inventoryListFold: true,
	}),
	mutations: {
		setInventoryListFold: (state, payload) => {
			state.inventoryListFold = payload
		},
		toggleMenu: (state, meunName) => {
			if (state.openMenuList.includes(meunName)) state.openMenuList.remove(meunName)
			else state.openMenuList.push(meunName)
		},
	},
	actions: {},
}
export default index
