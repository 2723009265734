var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: _vm.error
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.brandValue.name,
      expression: "brandValue.name"
    }, {
      name: "debounce",
      rawName: "v-debounce:300",
      value: _vm.handleInput,
      expression: "handleInput",
      arg: "300"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt(_vm.placeholder)
    },
    domProps: {
      "value": _vm.brandValue.name
    },
    on: {
      "focus": _vm.searchBrand,
      "blur": function blur($event) {
        _vm.active = false;
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.brandValue, "name", $event.target.value);
      }
    }
  }), _vm.error ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.tt(_vm.placeholder)))]) : _vm._e(), _c('ul', {
    staticClass: "brand-list",
    "class": {
      active: _vm.active
    }
  }, [_vm.noData ? _c('li', {
    staticClass: "brand-item"
  }, [_vm._v(_vm._s(_vm.tt('결과가 없습니다.')))]) : _vm._l(_vm.brandList, function (brand) {
    return _c('li', {
      key: brand.id,
      staticClass: "brand-item",
      domProps: {
        "innerHTML": _vm._s(_vm.replaceHighlight(brand.name))
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.clickItem(brand);
        }
      }
    });
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }