<template>
	<div
		v-if="count !== 0"
		class="floating-menu-wrapper"
		:style="{ left: leftPosition }"
		ref="menu-wrapper"
	>
		<div class="counting-box">
			<div class="counter">{{ count }}개</div>
			<div class="counter-text">{{ tt('선택') }}</div>
		</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		count: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {}
	},
	computed: {
		leftPosition() {
			if (this.$refs['menu-wrapper']) {
				const menuWrapperWidth = this.$refs['menu-wrapper'].clientWidth
				return `calc(50% - ${menuWrapperWidth / 2})`
			}
			return '50%'
		},
	},
}
</script>

<style lang="scss" scoped>
.floating-menu-wrapper {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	position: fixed;
	bottom: 90px;
	left: calc(50% + var(--leftPosition));
	height: 70px;
	background-color: white;
	border: 1px solid $COLOR_CCC;
	border-radius: 10px;
	z-index: 900;
	box-shadow: 0px 2px 4px 0px #0000001a;

	.counting-box {
		display: flex;
		width: 120px;
		height: 50px;
		margin: 10px 0px;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		border-right: 1px solid $DIVIDER;
		.counter {
			font-weight: 700;
			color: #ffa90b;
		}
		.counter-text {
			padding-left: 4px;
			font-weight: 400;
			color: $COLOR_000;
		}
	}
}
</style>
