<template>
	<button ref="btn-wrapper" class="Btn__select" :disabled="disabled">
		<input
			type="text"
			:placeholder="placeholder"
			:disabled="disabled"
			readonly
			:value="selectedOptionText"
			@mousedown="_inputMouseDown"
			@click="$refs['btn-wrapper'].focus()"
		/>
		<ul class="Form__select-option-list scroll" ref="option-box">
			<li class="Form__select-option-item" v-for="option in list" :key="option[valueKey]" @click="selectOption(option)">
				{{ option[textKey] }}
			</li>
		</ul>
	</button>
</template>

<script>
export default {
	props: {
		value: {
			type: Number,
			default: 0,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: [
				{
					value: '',
					text: '',
				},
			],
		},
		textKey: {
			type: String,
			default: 'text',
		},
		valueKey: {
			type: String,
			default: 'value',
		},
	},
	computed: {
		selectedOptionText() {
			if (this.selectedIndex >= 0) {
				return this.list[this.selectedIndex][this.textKey]
			} else {
				return ''
			}
		},
		selectedIndex() {
			for (let i = 0, len = this.list.length; i < len; i++) {
				const option = this.list[i]
				if (option[this.valueKey] === this.value) {
					return i
				}
			}
			return -1
		},
	},

	methods: {
		_inputMouseDown(e) {
			e.stopPropagation()
			e.preventDefault()
		},
		selectOption(option) {
			this.$emit('input', option[this.valueKey])
			this.$emit('change', option)
			this.$refs['btn-wrapper'].blur()
		},
	},
}
</script>

<style lang="scss" scoped></style>
