var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.summary ? _c('div', {
    staticClass: "summary-wrapper"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("../svg/icon-budget-plus.svg")
    }
  }), _c('div', {
    staticClass: "value plus"
  }, [_vm._v(_vm._s(_vm.tt('${1}원', _vm.budget)))]), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('총 예산 합계')))])]), _c('div', [_c('img', {
    attrs: {
      "src": require("../svg/icon-budget-minus.svg")
    }
  }), _c('div', {
    staticClass: "value minus"
  }, [_vm._v(_vm._s(_vm.tt('${1}원', _vm.expenditure)))]), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('총 지출 합계')))])]), _c('div', [_c('img', {
    attrs: {
      "src": require("../svg/icon-budget-sum.svg")
    }
  }), _c('div', {
    staticClass: "value sum"
  }, [_vm._v(_vm._s(_vm.tt('${1}원', _vm.balance)))]), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.tt('총 잔액 합계')))])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }