var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "Modal ModalNoticeLicense"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Modal__container"
  }, [_c('div', {
    staticClass: "Modal__content"
  }, [_c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('프로 라이선스에서<br>사용할 수 있는 기능입니다'))
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('라이선스를 업그레이드하고 더 다양한 기능을 만나보세요.')) + " ")]), _vm._m(0), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.goPrice
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('어떤 기능이 있나요?')) + " ")]), _c('button', {
    staticClass: "Btn__common",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('닫기')) + " ")])]), _c('label', [_c('input', {
    attrs: {
      "type": "checkbox"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('다시보지 않기')) + " ")])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/notice-license.png")
    }
  })]);
}]

export { render, staticRenderFns }