<template>
	<tr>
		<td>
			<button class="checkbox" @click="checkBoxClick" :class="{ active: isClick }"></button>
		</td>
		<td v-if="headers[0].value" @click="goInventory">
			<div class="description">
				<h6 v-if="item.name" v-html="replaceHighlight(item.name)"></h6>
				<div v-if="item.brand || item.subname">
					<span v-if="item.brand" v-html="replaceHighlight(item.brand.name)"></span>
					<span v-if="item.brand && item.subname">{{ ' | ' }} </span>
					<span v-if="item.subname">{{ item.subname }}</span>
				</div>
			</div>
		</td>
		<td v-if="headers[1].value" @click="goInventory">
			<p v-if="item.productCode" v-html="replaceHighlight(item.productCode)"></p>
			<p v-else class="nodata-txt">{{ tt('정보없음') }}</p>
		</td>
		<td v-if="headers[2].value" @click="goInventory" v-html="replaceHighlight(item.casno)"></td>
		<td v-if="headers[3].value" @click="goInventory">{{ commaVolume(item.totalNormalizedVolume) }}{{ item.totalNormalizedVolumeUnit }}</td>
		<td v-if="headers[4].value" @click="goInventory">{{ formatDate(item.createDatetime) }}</td>
		<!-- 주석 해제 시 parameter로 넘기는 아이템 다운로드 받을 수 있게 편집해야함 -->
		<!-- <td v-if="headers[3].value">
			<button class="btn management" @click="safetyDownload">
				<img src="@/assets/svg/icon-download.svg" alt="icon" />{{
					tt('관리대장')
				}}
				{{ tt('다운로드') }}
			</button>
		</td>
		<td v-if="headers[4].value" @click="goInventory">
			{{ formatDate(item.lastUpdateDatetime) }}
		</td> -->
	</tr>
</template>

<script>
import StringUtils from '@/utils/modules/StringUtils'
import PageName from '@/utils/define/PageName'
export default {
	name: 'ChemicalItem',
	props: {
		item: {
			type: Object,
			default: null,
		},
		allCheck: {
			type: Boolean,
			default: false,
		},
		headers: {
			type: Array,
			default: [],
		},
		checkList: {
			type: Array,
			default: [],
		},
		keyword: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isClick: false,
		}
	},
	watch: {
		allCheck: function () {
			if (this.allCheck) {
				this.isClick = true
			} else {
				this.isClick = false
			}
		},
		isClick: function () {
			const item = {
				brandId: this.item.brand.id,
				productCode: this.item.productCode,
				casno: this.item.casno,
				name: this.item.name,
			}
			if (this.isClick) {
				this.$emit('checkId', item)
			} else {
				this.$emit('uncheckId', item)
			}
		},
		checkList: function () {
			if (this.checkList == 0) {
				this.isClick = false
			}
		},
	},
	methods: {
		safetyDownload() {
			this.$emit('download', this.item)
		},
		checkBoxClick() {
			if (this.isClick) {
				this.isClick = false
			} else {
				this.isClick = true
			}
		},
		formatDate(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear()
			if (month.length < 2) {
				month = '0' + month
			}
			if (day.length < 2) {
				day = '0' + day
			}
			return [year, month, day].join('-')
		},
		commaVolume(obj) {
			let volume = String(obj)

			var regx = new RegExp(/(-?\d+)(\d{3})/)
			var bExists = volume.indexOf('.', 0) //0번째부터 .을 찾는다.
			var strArr = volume.split('.')
			while (regx.test(strArr[0])) {
				//문자열에 정규식 특수문자가 포함되어 있는지 체크
				//정수 부분에만 콤마 달기
				strArr[0] = strArr[0].replace(regx, '$1,$2') //콤마추가하기
			}

			if (bExists > -1) {
				//. 소수점 문자열이 발견되지 않을 경우 -1 반환
				volume = strArr[0] + '.' + strArr[1]
			} else {
				//정수만 있을경우 //소수점 문자열 존재하면 양수 반환
				volume = strArr[0]
			}
			return volume
		},
		goInventory() {
			this.$router.push({
				name: PageName.Safety.ChemicalMaterialsDetail,
				params: {
					casno: this.item.casno,
					name: this.item.name,
					productCode: this.item.productCode,
				},
				query: {
					brandsId: this.item.brand?.id ? [this.item.brand?.id] : null,
					classification: 'CAT_CHEMICAL',
				},
			})
		},
		replaceHighlight(value) {
			return StringUtils.replaceHighlight(value, this.keyword, 'tag', 'highlight')
		},
	},
}
</script>

<style lang="scss" scoped>
tr {
	td {
		.description {
			cursor: pointer;
			span {
			}
		}
		.nodata-txt {
			color: $PLACEHOLDER;
		}
	}
}
::v-deep {
	.highlight {
		background-color: #ff3b314d;
	}
}
</style>
