import { render, staticRenderFns } from "./registeringLocation.vue?vue&type=template&id=11d3bdd6&scoped=true&"
import script from "./registeringLocation.vue?vue&type=script&lang=js&"
export * from "./registeringLocation.vue?vue&type=script&lang=js&"
import style0 from "./registeringLocation.vue?vue&type=style&index=0&id=11d3bdd6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11d3bdd6",
  null
  
)

export default component.exports