const StorageAuthority = {
	namespaced: true,
	state: () => ({
		_storage: {
			add: undefined,
			edit: undefined,
			list: undefined,
			delete: undefined,
			subStorage: undefined,
			managerManage: undefined,
			responsibleGroupManage: undefined,
			location: undefined,
		},
	}),
	getters: {},
	mutations: {},
	actions: {},
}
export default StorageAuthority
