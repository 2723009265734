import cookies from 'vue-cookies'

const budgetViewOption = [
	{
		id: 'code',
		name: '예산 번호',
		isRequire: true,
	},
	{
		id: 'name',
		name: '예산 이름',
		isRequire: true,
	},
	{
		id: 'JoinedGroupRepName',
		name: '참여 그룹',
	},
	{
		id: 'budget',
		name: '총 예산 합계',
	},
	{
		id: 'expenditure',
		name: '총 지출 합계',
	},
	{
		id: 'balance',
		name: '총 잔액 합계',
	},
]

const draftViewOption = [
	{
		id: 'status',
		name: '결재상태',
		isRequire: true,
	},
	{
		id: 'subject',
		name: '기안 제목',
		isRequire: true,
	},
	{
		id: 'createDatetime',
		name: '기안일시',
		isRequire: true,
	},
	{
		id: 'no',
		name: '문서번호',
	},
	{
		id: 'budgetName',
		name: '예산 이름',
	},
	{
		id: 'purchaseType',
		name: '구매방식',
	},
	{
		id: 'group',
		name: '기안 연구실',
	},
	{
		id: 'register',
		name: '기안자',
	},
	{
		id: 'concludeDatetime',
		name: '결재완료일시',
	},
]

const deadlineViewOption = [
	{
		id: 'status',
		name: '정산 상태',
		isRequire: true,
	},
	{
		id: 'subject',
		name: '기안 제목',
		isRequire: true,
	},
	{
		id: 'createDatetime',
		name: '기안일시',
		isRequire: true,
	},
	{
		id: 'no',
		name: '문서번호',
	},
	{
		id: 'settle',
		name: '정산',
	},
	{
		id: 'expendAmount',
		name: '지출금액',
	},
	{
		id: 'budgetName',
		name: '예산 이름',
	},
	{
		id: 'group',
		name: '기안 연구실',
	},
	{
		id: 'register',
		name: '기안자',
	},
	{
		id: 'expenditureCreateDatetime',
		name: '예산차감일시',
	},
	{
		id: 'concludeDatetime',
		name: '결재완료일시',
	},
	{
		id: 'settler',
		name: '정산자',
	},
	{
		id: 'settleDatetime',
		name: '정산일시',
	},
]

const cargoViewOption = [
	{
		id: 'status',
		name: '입고상태',
		isRequire: true,
	},
	{
		id: 'multiCreateCount',
		name: '수량',
		isRequire: true,
	},
	{
		id: 'location',
		name: '위치/보관함',
	},
	{
		id: 'registerMethod',
		name: '구매처',
	},
	{
		id: 'name',
		name: '정보',
	},
	{
		id: 'category',
		name: '카테고리',
	},
	{
		id: 'brand',
		name: '브랜드',
	},
	{
		id: 'productCode',
		name: '제품번호',
	},
	{
		id: 'volume',
		name: '용량/패키지 수량',
	},
	{
		id: 'shipStatus',
		name: '배송정보',
	},
	{
		id: 'register',
		name: '입고자',
	},
	{
		id: 'completeDatetime',
		name: '입고일시',
	},
	{
		id: 'createDatetime',
		name: '목록추가일시',
	},
]

const inventoryViewOption = [
	{
		id: 'status',
		name: '상태',
		isRequire: true,
	},
	{
		id: 'name',
		name: '정보',
		isRequire: true,
	},
	{
		id: 'categoryClassification',
		name: '카테고리 분류',
		default: false,
	},
	{
		id: 'categoryType',
		name: '카테고리 상세 분류',
		default: false,
	},
	{
		id: 'category',
		name: '카테고리',
	},
	{
		id: 'brand',
		name: '브랜드',
	},
	{
		id: 'mngcode',
		name: '관리번호',
	},
	{
		id: 'incompanyMngcode',
		name: '사내관리번호',
	},
	{
		id: 'productCode',
		name: '제품번호',
	},
	{
		id: 'casno',
		name: 'CAS No',
	},
	{
		id: 'lotno',
		name: 'Lot No',
	},
	{
		id: 'volume',
		name: '물품 사이즈(용량)',
	},
	{
		id: 'currentVolume',
		name: '잔여 용량',
	},
	{
		id: 'etc_volume',
		name: '패키지 수량',
	},
	{
		id: 'etc_currentvolume',
		name: '잔여 수량',
	},
	{
		id: 'location',
		name: '위치/보관함',
	},
	{
		id: 'group',
		name: '담당 그룹',
	},
	{
		id: 'assignee',
		name: '담당자',
	},
	{
		id: 'msds',
		name: 'MSDS',
	},
	{
		id: 'expireDatetime',
		name: '유효기간',
	},
	{
		id: 'createDatetime',
		name: '등록일시',
	},
	{
		id: 'updateDatetime',
		name: '최근 변경일시',
	},
]

const historyViewOption = [
	{
		id: 'user',
		name: '입력자',
		isRequire: true,
	},
	{
		id: 'title',
		name: '내용',
		isRequire: true,
	},
	{
		id: 'content',
		name: '상세',
		isRequire: true,
	},
	{
		id: 'setting',
		name: '관리',
		isRequire: true,
	},
	{
		id: 'categoryClassification',
		name: '카테고리 분류',
		default: false,
	},
	{
		id: 'categoryType',
		name: '카테고리 상세 분류',
		default: false,
	},
	{
		id: 'category',
		name: '카테고리',
	},
	{
		id: 'mngCode',
		name: '관리번호',
	},
	{
		id: 'name',
		name: '물품정보',
	},
	{
		id: 'brand',
		name: '브랜드',
		default: false,
	},
	{
		id: 'productCode',
		name: '제품번호',
		default: false,
	},
	{
		id: 'createDatetime',
		name: '시간',
	},
]

const disposalViewOption = [
	{
		id: 'name',
		name: '물품정보',
		isRequire: true,
	},
	{
		id: 'mngCode',
		name: '관리번호',
	},
	{
		id: 'category',
		name: '카테고리',
	},
	{
		id: 'status',
		name: '분류',
	},
	{
		id: 'dumpMemo',
		name: '삭제(폐기) 사유',
	},
	{
		id: 'assignee',
		name: '담당자',
	},
	{
		id: 'dumper',
		name: '삭제(폐기)자',
	},
	{
		id: 'dumpDatetime',
		name: '삭제(폐기)일',
	},
]

const pruchaseProductViewOption = [
	{
		id: 'paymentStatus',
		name: '결제상태',
		isRequire: true,
	},
	{
		id: 'orderStatus',
		name: '주문상태',
		isRequire: true,
	},
	{
		id: 'orderNo',
		name: '주문번호',
		isRequire: true,
	},
	{
		id: 'name',
		name: '상품 정보',
		isRequire: true,
	},
	{
		id: 'quantity',
		name: '수량',
	},
	{
		id: 'payerName',
		name: '결제자',
	},
	{
		id: 'budgetName',
		name: '예산 이름',
	},
	{
		id: 'price',
		name: '결제금액',
	},
	{
		id: 'draftNo',
		name: '문서번호',
	},
	{
		id: 'paymentDatetime',
		name: '결제일시',
	},
	{
		id: 'draftName',
		name: '기안 제목',
	},
	{
		id: 'group',
		name: '주문 연구실',
	},
	{
		id: 'orderer',
		name: '주문자',
	},
	{
		id: 'createDatetime',
		name: '주문일시',
	},
	{
		id: 'concluder',
		name: '결재자',
	},
	{
		id: 'concludeDatetime',
		name: '결재완료일시',
	},
	{
		id: 'paymentNo',
		name: '결제번호',
	},
	{
		id: 'paymentCreateDatetime',
		name: '결제 요청일',
	},
	{
		id: 'businessRegistrationName',
		name: '결제처',
	},
	{
		id: 'paymentMethod',
		name: '결제수단',
	},
	{
		id: 'platform',
		name: '주문경로',
	},
]
export {
	budgetViewOption,
	draftViewOption,
	deadlineViewOption,
	cargoViewOption,
	inventoryViewOption,
	historyViewOption,
	disposalViewOption,
	pruchaseProductViewOption,
}
