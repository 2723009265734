var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: _vm.error
    }
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v("pH Range(From-To)"), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputFrom,
      expression: "inputFrom"
    }],
    attrs: {
      "type": "number",
      "placeholder": "pH Range(From)"
    },
    domProps: {
      "value": _vm.inputFrom
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.inputFrom = $event.target.value;
      }, function ($event) {
        return _vm.handleInput($event, 'From');
      }]
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputTo,
      expression: "inputTo"
    }],
    attrs: {
      "type": "number",
      "placeholder": "pH Range(To)"
    },
    domProps: {
      "value": _vm.inputTo
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.inputTo = $event.target.value;
      }, function ($event) {
        return _vm.handleInput($event, 'To');
      }]
    }
  })]), _vm.error ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.tt(_vm.errorMessage)))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }