<template>
	<base-item title="참여 부서" :required="isRequired">
		<div class="button-wrapper">
			<image-button :text="'참여 부서 추가'" :image="require('@/assets/svg/icon/black-round-plus.svg')" @click="addGroup"></image-button>
		</div>
		<div class="group-wrapper">
			<div class="group-item" v-for="group in selectedGroups" :key="group.id">
				<input type="text" disabled :value="group.name" />
				<image-button :image="require('@/assets/svg/recycle-bin.svg')" @click="deleteGroup(group)" />
			</div>
		</div>
		<div v-if="!isValid" class="error">
			<img src="@/assets/svg/purchase/icon-error.svg" alt="error" />
			<span>{{ tt(inValidText) }}</span>
		</div>
		<modal-select-group title="참여부서" ref="modal-select-group" @confirm="setSelectedGroup" />
	</base-item>
</template>
<script>
import ModalSelectGroup from '@/views/common/components/modalGen2/ModalSelectGroup.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import { mapMutations } from 'vuex'
export default {
	components: {
		BaseItem,
		ModalSelectGroup,
	},
	props: {
		defaultValue: {
			type: Array,
			default: () => [],
		},
		isValid: {
			type: Boolean,
			default: false,
		},
		inValidText: {
			type: String,
			default: '',
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedGroups: [],
		}
	},
	computed: {
		value() {
			return this.selectedGroups
		},
	},
	created() {
		this.initSelectedGroups()
		this.selectedGroups = this.$_.cloneDeep(this.defaultValue)
	},
	methods: {
		...mapMutations('institute/group', ['initSelectedGroups']),
		addGroup() {
			this.$refs['modal-select-group'].show(this.selectedGroups)
		},
		setSelectedGroup(selectedGroups) {
			this.selectedGroups = this.$_.cloneDeep(selectedGroups)
		},
		deleteGroup(group) {
			this.selectedGroups.remove(group.id, 'id')
		},
		setValue(value) {
			this.selectedGroups = value
		},
	},
}
</script>
<style lang="scss" scoped>
.group-wrapper {
	.group-item {
		height: 36px;
		display: flex;
		align-items: center;
		margin-top: 10px;
		gap: 10px;
		& > * {
			height: 100%;
		}
		input {
			width: 400px;
			background-color: $GREY_1;
			border: 1px solid #e6e6e6;
			border-radius: 5px;
			font-size: 14px;
			color: $DEFAULT;
		}
	}
}

.error {
	margin-top: 5px;
	display: flex;
	span {
		font-size: 0.75rem;
		color: $RED;
		margin-left: 6px;
	}
}
</style>
