<template>
	<portal to="lm-modal">
		<div class="Modal ModalLicenseAuthEducation Popup__depth2" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('교육기관 인증') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="license-edu-desrciption">
						<span class="description-title" v-html="tt('인증자료 제출 필수')"></span>
						<span
							class="description-content"
							i
							v-html="
								`&nbsp;•&nbsp;
							${tt('학생일 경우: 학생증 앞면, 입학 허가서')}`
							"
						></span>
						<span
							class="description-content"
							v-html="
								`&nbsp;•&nbsp;
							${tt('교수님일 경우: 교직원증, 재직증명서')}`
							"
						></span>
						<br />
						<span
							class="description-content"
							v-html="tt('lmcs@smartjackwp.com으로 인증자료를 보내주세요.<br/>영업일 기준 2일내 인증이 완료됩니다.')"
						></span>
					</div>
					<div class="license-phone-wrapper">
						<lm-phone-input
							:title="tt('연락처')"
							isRequired
							:placeholder="tt('연락처를 입력해주세요')"
							:value="phoneNumber"
							@value="getPhoneNumber"
							:isError="inputError"
							:errorMessage="tt('연락처를 입력해주세요')"
						></lm-phone-input>
						<div
							class="license-edu-input-description"
							v-html="tt('인증자료 검토 후 개별로 연락을 드립니다.</br>연락이 안 될 경우, 라이선스 주문이 취소될 수 있습니다.')"
						></div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="button-container">
						<lm-small-button :buttonText="tt('취소')" color="white" @click="close"></lm-small-button>
						<lm-small-button :buttonText="tt('완료')" color="yellow" @click="showPurchase"></lm-small-button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinLicense from '@/mixins/license'
import LmSmallButton from '@/views/common/components/buttons/LmSmallButton.vue'
import LmPhoneInput from '@/views/common/components/input/LmPhoneInput.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Validation from '@/utils/Validation'

export default {
	name: 'ModalCompleteLicensePayment',
	mixins: [MixinLicense],
	components: { LmSmallButton, LmPhoneInput },
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			phoneNumber: '',
			inputError: false,
		}
	},
	watch: {
		async isShow(value) {
			if (value) {
				await this.loadLicenseManagement()
			} else {
				;(this.phoneNumber = ''), (this.inputError = false)
			}
		},
	},
	computed: {
		...mapState('license', ['license', 'purchaseForm', 'paymentMethod', 'licenseManagement', 'purchaseCompleteInfo']),
		...mapGetters('license', ['isLicenseActivate', 'isLicenseDepositWait']),
		isPaymentMethodEbill() {
			return this.purchaseCompleteInfo.paymentMethod == this.paymentMethod.ELECTRONIC_BILL
		},
		isPaymentMethodCardRegistered() {
			return this.purchaseCompleteInfo.paymentMethod == this.paymentMethod.CARD_REGISTERED
		},
	},
	methods: {
		...mapActions('license', ['loadLicenseManagement']),
		// show(mode) {
		// 	this.mode = mode;
		// 	this.isShow = true;
		// },
		close() {
			this.$emit('close')
		},
		showPurchase() {
			if (Validation.validPhoneNumber(this.phoneNumber)) {
				this.setPhoneNumber(this.phoneNumber)
				this.$emit('purchase')
			} else {
				this.inputError = true
			}
			// console.warn(this.)
		},
		getPhoneNumber(phoneNumber) {
			this.phoneNumber = phoneNumber
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__depth2 {
	z-index: 1000 !important;
}
.ModalLicenseAuthEducation {
	color: #000;
	.Popup__container {
	}
	.Popup__content {
		height: calc(100% - 135px) !important;
		> .imgbox {
			width: 100%;
			height: 200px;
			text-align: center;
		}
		.license-edu-desrciption {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: auto;
			background-color: $GREY_2;
			border-radius: 16px;
			padding: 15px;
			.description-title {
				font-size: 14px;
				font-weight: 700;
			}
			.description-content {
				font-size: 14px;
				font-weight: 400;
			}
		}
		.license-phone-wrapper {
			margin-top: 10px;
			.license-edu-input-description {
				font-size: 14px;
				font-weight: 400;
				margin: 10px 0px;
			}
		}
	}
}

.Popup__bottom {
	padding-top: 0px !important;
	padding-bottom: 10px !important;
	display: flex;
	justify-content: flex-end;
	.button-container {
		display: flex;
		width: 210px;
		height: 100%;
		justify-content: space-between;
	}
}
</style>
