<template>
	<div class="number-input-wrapper">
		<div class="inner-wrapper">
			<div
				v-for="(number, index) in numbers"
				:key="`${number}-${index}`"
				class="card-input-button unselectable"
				:class="{ empty: number == '' }"
				@click.stop="addNumber(number)"
			>
				<div class="number">{{ number }}</div>
			</div>
			<div class="bottom-button-wrapper">
				<div class="card-input-button-bottom" @click.stop="remove">
					<img src="@/assets/svg/delete-card-input-number.svg" width="28" />
				</div>
				<div class="card-input-button-bottom unselectable" @click.stop="reset">
					{{ tt('재배열') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			numbers: [],
		}
	},
	created() {
		this.numbers = this.shuffleNumbers()
	},
	methods: {
		addNumber(number) {
			this.$emit('add', number)
		},
		remove() {
			this.$emit('remove')
		},
		reset() {
			this.numbers = this.shuffleNumbers()
		},
		shuffleNumbers() {
			return this.$_.shuffle([...Array.from(Array(10).keys()).concat(['', ''])])
		},
	},
}
</script>

<style lang="scss" scoped>
.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.card-input-button-bottom {
	display: flex;
	background: $LAB_WHITE;
	box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.5), 0px 0.5px 1px $DIM;
	border-radius: 5px;
	width: 87px;
	height: 40px;
	align-items: center;
	justify-content: center;
	font-weight: 700;
}

.number-input-wrapper {
	box-sizing: border-box;
	position: absolute;
	max-width: 203px;
	padding: 10px 5px 10px 10px;
	background: $DIVIDER;

	border: 1px solid $INPUT;
	box-sizing: border-box;
	/* web shadow */

	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	.inner-wrapper {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		height: 180px;
		.card-input-button {
			display: inline-block;
			background: $LAB_WHITE;
			box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.5), 0px 0.5px 1px $DIM;
			border-radius: 5px;
			width: 40px;
			height: 40px;
			margin-right: 4px;
			margin-bottom: 4px;
			.number {
				display: flex;
				width: 100%;
				height: 100%;
				font-weight: 700;
				font-size: 24px;
				align-items: center;
				justify-content: center;
			}
			&.empty {
				background: $GREY_1;
				box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.5), 0px 0.5px 1px $DIM;
				border-radius: 5px;
			}
		}
		.bottom-button-wrapper {
			display: flex;
			width: 100%;
			justify-content: space-between;
			margin-right: 4px;
			.bottom-button {
			}
		}
	}
}
</style>
