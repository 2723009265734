<template>
	<div class="datepicker">
		<date-picker v-model="date" valueType="format" :lang="lang" :placeholder="placeholder"></date-picker>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import MixinDate from '@/mixins/date'
export default {
	components: {
		DatePicker,
	},
	mixins: [MixinDate],
	props: {
		placeholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			date: null,
		}
	},
	watch: {
		date: function () {
			this.$emit('changeDate', this.date)
		},
	},
	methods: {
		setDate(date) {
			this.date = date
		},
	},
}
</script>
