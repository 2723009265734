<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<form-text-single-line
				:name="'항목명'"
				:value="field.name"
				:placeholder="'항목명을 입력해 주세요'"
				:required="true"
				@input="value => inputFormName(value)"
			></form-text-single-line>
			<form-select
				:name="'입력방식'"
				:required="true"
				:optionList="articleList.map(it => it.text)"
				:placeholder="tt('입력방식을 선택해주세요')"
				:value="selectedText"
				@input="text => inputFormValue(text)"
			></form-select>
			<form-text-single-line
				v-if="field.varType === 'text_single_line_with_unit'"
				:name="'단위'"
				:value="field.unit[0]"
				:placeholder="'단위를 입력해주세요'"
				:required="true"
				@input="value => inputFormUnit(value)"
			></form-text-single-line>
			<div v-if="field.varType === 'select_option'" class="option-wrap">
				<p>{{ tt('옵션') }}<span class="required">*</span></p>
				<div v-for="(item, index) in field.option" :key="`option-${index}`" class="input-wrapper">
					<input type="text" :placeholder="tt('옵션을 입력해주세요')" :value="item" @input="handleChange($event, index)" />
					<button class="Btn-delete-item btn" @click="deleteOption(index)"><img src="@/assets/svg/recycle-bin.svg" /></button>
				</div>
				<button @click="addOption">+{{ tt('추가하기') }}</button>
			</div>
			<div class="flex">
				<input type="checkbox" id="setting" v-model="field.isRequire" />
				<label for="setting" class="setting-label"
					>{{ tt('필수 입력값으로 설정') }}
					<button class="Btn__hover-balloon-w" type="button">
						<img src="@/assets/svg/question-mark.svg" alt="icon" />
						<div class="balloon">
							<h6>{{ tt('필수 입력값으로 설정') }}</h6>
							<p>
								{{ tt('필수 입력값으로 설정하면 물품 등록 시 해당 정보를 필수적으로 입력해야합니다.') }}
							</p>
						</div>
					</button>
				</label>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="confirm">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import FormTextSingleLine from '@/views/common/components/form/FormTextSingleLine.vue'
import FormSelect from '@/views/common/components/form/FormSelect.vue'
export default {
	components: {
		FormTextSingleLine,
		FormSelect,
	},
	watch: {
		['field.varType'](val) {
			if (val !== 'text_single_line_with_unit') this.field.unit = ''
			if (val !== 'select_option') this.field.option = ['']
		},
	},
	computed: {
		...mapState('inventoryGen2/setting', ['category']),
		title() {
			return this.index ? '항목 편집' : '항목 추가'
		},
		checkSingleUnit() {
			if (this.field.varType === 'text_single_line_with_unit' && !this.field.unit) return false
			return true
		},
		checkSelectOption() {
			if (this.field.varType === 'select_option' && this.field.option[0] === '') return false
			return true
		},
	},
	data() {
		return {
			index: null,
			articleList: [
				{
					text: this.tt('텍스트 및 숫자 입력(한 줄)'),
					value: 'text_single_line',
				},
				{
					text: this.tt('텍스트 및 숫자 입력(여러 줄)'),
					value: 'text_multiple_line',
				},
				{
					text: this.tt('텍스트 및 숫자 입력 + 단위'),
					value: 'text_single_line_with_unit',
				},
				{ text: this.tt('옵션 선택'), value: 'select_option' },
				{ text: this.tt('날짜 선택'), value: 'select_datetime' },
				{ text: this.tt('멤버 선택'), value: 'select_member' },
			],
			field: {},
			selectedText: '',
		}
	},
	methods: {
		...mapActions('inventoryGen2/setting', ['editCategory']),
		show(idx) {
			this.index = idx
			if (this.index >= 0) {
				this.field = JSON.parse(JSON.stringify(this.category.fields.customFields[this.index]))
				if (!this.field.option) this.field.option = ['']
				if (!this.field.unit) this.field.unit = ''
				this.selectedText = this.articleList.find(it => it.value === this.field.varType).text
			} else {
				this.field = {
					isActive: true,
					isRequire: false,
					key: '',
					name: '',
					option: [''],
					readOnly: false,
					unit: '',
					varType: '',
				}
				this.selectedText = ''
			}
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		async confirm() {
			const newFields = JSON.parse(JSON.stringify(this.category.fields))
			let str = ''
			if (this.field.name && this.field.varType && this.checkSingleUnit && this.checkSelectOption) {
				if (this.field.isRequire) this.field.isActive = true

				if (this.index) {
					newFields.customFields[this.index] = this.field
					str = '편집'
				} else {
					newFields.customFields.push(this.field)
					str = '추가'
				}
				await this.editCategory({
					id: this.category.id,
					fields: newFields,
				})
				this.$root.toast(this.tt('항목 ${1} 완료', str), this.tt('항목이 ${1}되었습니다', str), 'success')
				this.hide()
			} else {
				this.$root.toast('필수값 미입력', '필수값을 입력해주세요', 'error')
			}
		},
		inputFormName(value) {
			this.field.name = value
		},
		inputFormValue(text) {
			this.field.varType = this.articleList.find(it => it.text === text).value
		},
		inputFormUnit(value) {
			this.field.unit = value
		},
		handleChange(e, index) {
			this.field.option[index] = e.target.value
		},
		addOption() {
			this.field.option.push('')
		},
		deleteOption(index) {
			this.field.option.splice(index, 1)
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .form-select-box {
	.form-option-list {
		width: 100%;
		top: 10px;
		user-select: none;
	}
}

::v-deep .modal-header {
	border: 0;
}
::v-deep .modal-dialog-scrollable {
	.modal-content {
		overflow: inherit;
	}
	.modal-body {
		overflow-y: inherit;
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
::v-deep .modal-footer {
	padding: 20px;
	border: 0;
	.modal-footer-wrap {
		.footer-left,
		.footer-right {
			display: flex;
			gap: 10px;
			button {
				border: 0;
				box-shadow: none;
				display: flex;
				height: auto;
				align-items: center;
				cursor: pointer;
				white-space: pre;
				transition: 0.5s all ease;
				min-width: 100px;
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 700;
				border-radius: 5px;
				justify-content: center;
			}
			.button-white {
				background: $LAB_WHITE;
				border: 1px solid $DIVIDER;
				color: #111 !important;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
						linear-gradient(0deg, #fff, #fff);
				}
			}
			.button-primary {
				background: $LAB_YELLOW;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
				}
			}
		}
	}
}

.form-input {
	width: 100%;
	max-width: none;
}
.setting-label {
	display: flex;
	height: auto;
	align-items: center;
	padding-left: 7px;
	font-size: 0.875rem;
	color: #000;
	font-weight: normal;
	cursor: pointer;
	margin: 0 0 0 5px;
}
.option-wrap {
	.input-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
		input[type='text'] {
			width: calc(100% - 50px);
			border-radius: 5px;
			font-size: 14px;
			font-weight: 400;
			padding-left: 10px;
			height: 30px;
			border: 1px solid $COLOR_CCC;
			&:read-only {
				background-color: $GREY_1;
				border: 1px solid $INPUT_BORDER;
				&:focus {
					border: 1px solid $INPUT_BORDER;
				}
			}
		}
	}
	p {
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 8px !important;
	}
	& > button {
		margin-top: 10px;
		color: $TEXT_YELLOW;
		font-weight: bold;
		font-size: 0.875rem;
	}
}
</style>
