<template>
	<b-modal centered scrollable @close="hide" ref="modal" hide-footer no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('보관함 등록순서') }}</div>
		</template>
		<div class="wrapper">
			<p>
				물품관리번호를 구성할 때, 각 보관함에 물품이 등록된 순서를 번호로 부여하는 방법을 이용할 수 있습니다. 보관함 등록순서는 아래와 같이
				부여됩니다.
			</p>
			<div class="yellow-box">
				<div>
					<div class="mark">121</div>
					<div>A</div>
				</div>
				<div>_</div>
				<div>
					<div class="mark">24910</div>
					<div>B</div>
				</div>
			</div>
			<p>
				<b>A:</b> 첫번째 숫자는 각 보관함에 부여된 고유한 번호<br />
				<b>B:</b> 두번째 숫자는 해당 보관함에 등록된 순서를 각각 의미합니다.
			</p>
			<p>따라서, 위의 보관함 등록순서는 121번 보관함에 24,910번째로 등록된 물품을 의미합니다.</p>
		</div>
	</b-modal>
</template>
<script>
export default {
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.modal-header {
		border-bottom: none;
	}
}
.wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	p {
		color: #111;
		font-size: 14px;
	}
	.yellow-box {
		padding: 16px;
		display: flex;
		background: $SUB-YELLOW_100;
		border-radius: 10px;
		justify-content: center;
		gap: 8px;
		& > div {
			text-align: center;
			div {
				color: #979797;
			}
			.mark {
				padding: 0px;
				color: #111;
				text-align: center;
				position: relative;
				font-size: 14px;
				font-weight: bold;
				&::before {
					display: block;
					content: '';
					position: absolute;
					bottom: 0px;
					width: 100%;
					height: 1px;
					background-color: #111;
				}
			}
		}
	}
}
</style>
