<template>
	<tr>
		<td v-if="headers[0].value" @click="goInventory">
			<div class="description isNotFlex">
				<h6 v-if="item.name" v-html="replaceHighlight(item.name)"></h6>
				<div v-if="item.brand || item.subname">
					<span v-if="item.brand" v-html="replaceHighlight(item.brand.name)"></span>
					<span v-if="item.brand && item.subname">{{ ' | ' }} </span>
					<span v-if="item.subname">{{ item.subname }}</span>
				</div>
			</div>
			<!-- <b>{{ item.name }</b> -->
		</td>
		<td v-if="headers[1].value" @click="goInventory">
			<p :class="item.productCode ? '' : 'nodata-txt'" v-text="item.productCode ? item.productCode : tt('정보없음')"></p>
		</td>
		<td v-if="headers[2].value" @click="goInventory">
			<p v-if="item.casno" v-html="replaceHighlight(item.casno)"></p>
			<p v-else class="nodata-txt">{{ tt('정보없음') }}</p>
		</td>
		<td v-if="headers[3].value" @click="goInventory">{{ commaVolume(item.totalNormalizedVolume) }}{{ item.totalNormalizedVolumeUnit }}</td>
		<td v-if="headers[4].value" @click="goInventory">
			{{ formatDate(item.createDatetime) }}
		</td>
	</tr>
</template>

<script>
import StringUtils from '@/utils/modules/StringUtils'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ManagementItem',
	props: {
		headers: {
			type: Array,
			default: [],
		},
		item: {
			type: Object,
			defualt: null,
		},
		keyword: {
			type: String,
			default: '',
		},
	},
	methods: {
		formatDate(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear()
			if (month.length < 2) {
				month = '0' + month
			}
			if (day.length < 2) {
				day = '0' + day
			}
			return [year, month, day].join('-')
		},
		commaVolume(obj) {
			let volume = String(obj)

			var regx = new RegExp(/(-?\d+)(\d{3})/)
			var bExists = volume.indexOf('.', 0) //0번째부터 .을 찾는다.
			var strArr = volume.split('.')
			while (regx.test(strArr[0])) {
				//문자열에 정규식 특수문자가 포함되어 있는지 체크
				//정수 부분에만 콤마 달기
				strArr[0] = strArr[0].replace(regx, '$1,$2') //콤마추가하기
			}

			if (bExists > -1) {
				//. 소수점 문자열이 발견되지 않을 경우 -1 반환
				volume = strArr[0] + '.' + strArr[1]
			} else {
				//정수만 있을경우 //소수점 문자열 존재하면 양수 반환
				volume = strArr[0]
			}
			return volume
		},
		goInventory() {
			this.$router.push({
				name: PageName.Safety.ManagementMaterialsDetail,
				params: {
					casno: this.item.casno,
					name: this.item.name,
				},
				query: {
					brandsId: this.item.brand?.id ? [this.item.brand?.id] : null,
					classification: 'CAT_CHEMICAL',
				},
			})
		},
		replaceHighlight(value) {
			return StringUtils.replaceHighlight(value, this.keyword, 'tag', 'highlight')
		},
	},
}
</script>

<style lang="scss" scoped>
tr {
	td {
		&:nth-child(1) {
			min-width: unset !important;
			max-width: unset !important;
			width: unset !important;
			padding-left: 40px !important;
		}
		.nodata-txt {
			color: $PLACEHOLDER;
		}
		.Btn__hover-balloon-w {
			.balloon {
				white-space: nowrap;
			}
		}
	}
}
::v-deep {
	.highlight {
		background-color: #ff3b314d;
	}
}
</style>
