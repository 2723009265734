var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-msds', {
    attrs: {
      "msdsInfo": _vm.cargoInfo.msds,
      "value": _vm.uploadMsds
    },
    on: {
      "change": _vm.handleChange
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }