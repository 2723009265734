import axios from 'axios'
import userAPI from '@/services/api/institute/user'
import cookies from 'vue-cookies'
import { goToSSOMain } from '@/services/api/common'
import qs from 'qs'

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	timeout: 100000,
})
axiosInstance.defaults.paramsSerializer = params => {
	return qs.stringify(params, { encode: false, arrayFormat: 'brackets' })
}

axiosInstance.interceptors.request.use(
	function (config) {
		let newConfig = config
		if (newConfig?.params?.keyword) {
			newConfig.params.keyword = encodeURIComponent(config.params.keyword)
		}
		// if (newConfig?.params?.name) {
		// 	newConfig.params.name = encodeURIComponent(config.params.name)
		// }
		if (newConfig?.params?.subKeyword) {
			newConfig.params.subKeyword = encodeURIComponent(config.params.subKeyword)
		}
		if (newConfig?.params?.secondKeyword) {
			newConfig.params.secondKeyword = encodeURIComponent(config.params.secondKeyword)
		}
		return newConfig
	},
	function (error) {
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		//TODO: response code 별로 분기
		// console.error(`응답 실패 >>> response : ${JSON.stringify(error)}`);
		try {
			switch (error.response.status) {
				case 401:
					userAPI.logout()
					goToSSOMain()
					break
				case 404:
					console.warn(error.response.data.msg)
					break
				default:
					break
			}

			return Promise.reject(error)
		} catch (error) {
			return Promise.reject(error)
		}
	}
)

axiosInstance.setAuth = function (token) {
	axiosInstance.defaults.headers.common['Authorization'] = token
}

axiosInstance.loadGlobalFilter = function () {
	const filterValue = localStorage.getItem('GlobalFilterStorage-Headers')
	if (filterValue && filterValue.includes('[')) axiosInstance.defaults.headers.common['Global-Filter-Storage'] = JSON.parse(filterValue).join()
	else if (filterValue) axiosInstance.defaults.headers.common['Global-Filter-Storage'] = filterValue
}
axiosInstance.defaults.instituteId = cookies.get('instituteId')

export default axiosInstance
