const state = {
	useVolume: null,
	reason: '',
	isShowVolumeError: false,
	isShowReasonError: false,
}

const getters = {
	useVolume: state => state.useVolume,
	numberUseVolume: state => {
		if (typeof state.useVolume === 'string') return Number(state.useVolume?.replaceAll(',', ''))
		else return state.useVolume
	},
	reason: state => state.reason,
	isShowVolumeError: state => state.isShowVolumeError,
	isShowReasonError: state => state.isShowReasonError,
	isInputVolumeEmpty: (state, getters) =>
		getters.numberUseVolume === null || getters.numberUseVolume === Number.NaN || getters.numberUseVolume == 0,
	isInputVolumeOver: (state, getters) => volume => getters.numberUseVolume > volume,
	isInputReasonOver: (state, getters) => max => state.reason.length > max,
	reasonLength: state => state.reason.leng,
	formattedReason: state => state.reason.replaceAll('\\n', '\n'),
}

const mutations = {
	setUseVolume: (state, volume) => {
		state.useVolume = volume
	},
	setReason: (state, reason) => {
		state.reason = reason
	},
	setIsShowVolumeError: (state, showVolumeError) => {
		state.isShowVolumeError = showVolumeError
	},
	setIsShowReasonError: (state, showReasonError) => {
		state.isShowReasonError = showReasonError
	},
}

const actions = {}

export default { namespaced: true, state, getters, mutations, actions }
