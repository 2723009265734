import institutePermissionAPI from '@/services/api/institute/permission'
import vvuex from '@/lib/vvuex'

const institutePermission = vvuex({
	namespaced: true,
	state: {
		permissionList: {},
		permission: {},
	},
	getters: {
		getPermissionList: state => state.permissionList,
		getPermission: state => state.permission,
	},
	mutations: {
		setPermissionList: (state, payload) => {
			state.permissionList = payload
		},
		setPermission: (state, payload) => {
			state.permission = payload
		},
	},
	actions: {
		lookupPermissionList: async ({ commit }, payload) => {
			const { instId, offset, length, keyword } = payload
			const response = await institutePermissionAPI.lookupPermissionList(instId, offset, length, keyword)
			commit('setPermissionList', response.data)
		},
		lookupPermission: async ({ commit }, payload) => {
			const { instId, id } = payload
			const response = await institutePermissionAPI.lookupPermission(instId, id)
			commit('setPermission', response.data)
		},
		addPermission: async ({ commit }, payload) => {
			const { instId, body } = payload
			return await institutePermissionAPI.addPermission(instId, body)
		},
		copyPermission: async ({ commit }, payload) => {
			const { instId, body } = payload
			const response = await institutePermissionAPI.copyPermission(instId, body)
			return response.data.id
		},
		updatePermission: async ({ commit }, payload) => {
			const { instId, body } = payload
			const response = await institutePermissionAPI.updatePermission(instId, body)
			commit('setPermissionList', response.data)
		},
		deletePermission: async ({ commit }, payload) => {
			const { instId, id } = payload
			await institutePermissionAPI.deletePermission(instId, id)
		},
		updateOrder: async ({ commit }, payload) => {
			const { instId, body } = payload
			const response = await institutePermissionAPI.updateOrder(instId, body)
			commit('setPermissionList', response.data)
		},
		addMemberPermission: async ({ commit }, payload) => {
			const { instId, body } = payload
			const response = await institutePermissionAPI.addMember(instId, body)
			commit('setPermissionList', response.data)
		},
		deleteMemberPermission: async ({ commit }, payload) => {
			const { instId, userId, permissionId } = payload
			const response = await institutePermissionAPI.deleteMember(instId, userId, permissionId)
			commit('setPermissionList', response.data)
		},
		addGroupPermission: async ({ commit }, payload) => {
			const { instId, body } = payload
			const response = await institutePermissionAPI.addGroup(instId, body)
			commit('setPermissionList', response.data)
		},
		deleteGroupPermission: async ({ commit }, payload) => {
			const { instId, groupId, permissionId } = payload
			const response = await institutePermissionAPI.deleteGroup(instId, groupId, permissionId)
			commit('setPermissionList', response.data)
		},
	},
})

export default institutePermission
