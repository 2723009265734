<template>
	<!-- v-if="isChildrenIncludeKeyword || isParentIncludeKeyword" -->
	<div class="tree-node-wrap" :class="{ root: node.parentId == null && type != 'storage' }">
		<div class="tree-node" @click.stop="isSelectType ? setSelectedNode(node) : ''">
			<div class="info-wrap" :class="{ root: node.parentId == null && type != 'storage' }">
				<div v-if="isSubtree">
					<img v-if="isTreeOpen" class="tree-close" src="../svg/icon-node-close.svg" width="16" height="24" @click.stop="toggleTreeOpen" />
					<img v-else class="tree-open" src="../svg/icon-node-open.svg" width="16" height="24" @click.stop="toggleTreeOpen" />
				</div>
				<div class="data-wrap">
					<img class="tree-icon" :src="imageSrc" width="20" height="20" />
					<div class="name" v-html="name"></div>
				</div>
			</div>
		</div>
		<div class="checkbox">
			<div
				v-if="isSelectType"
				class="select-button"
				:class="{ selected: isSelected }"
				@click.stop="isSelectType ? setSelectedNode(node) : ''"
			></div>
		</div>
		<div v-if="isTreeOpen" class="node-children">
			<location-node
				ref="child-node"
				:node="children"
				v-for="children in node.children"
				:key="children.id"
				:type="type === 'storage' ? 'storage' : 'location'"
			></location-node>
			<location-node ref="child-node" :node="storage" v-for="storage in node.storages" :key="storage.id" type="storage"></location-node>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import MixinTree from '@/mixins/tree'

export default {
	name: 'LocationNode',
	components: {},
	props: {
		node: {
			type: Object,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
	},
	mixins: [MixinTree],
	computed: {
		...mapGetters('tree/location', ['selectedNode', 'usingTreeNodeType', 'keyword']),

		imageSrc() {
			return this.type === 'storage' ? require('../svg/icon-storage.svg') : require('@/assets/svg/location_s.svg')
		},
		isSelected() {
			return this.node.id === this.selectedNode?.id
		},
		isSelectType() {
			return this.usingTreeNodeType?.includes(this.type)
		},
	},

	methods: {
		...mapMutations('tree/location', ['setSelectedNode']),
	},
}
</script>

<style lang="scss" scoped>
@import '../scss/_tree-node.scss';
// #fcf8e3
</style>
