import axiosInstance from '@/services/axios'

const endPoint = {
	getCategoryInfo: instId => `/institute/${instId}/category`,
	getCategoryFieldsByType: instId => `/institute/${instId}/category/fields`,
}

const categoryAPI = {
	getCategoryInfo: (instId, params) =>
		axiosInstance.get(endPoint.getCategoryInfo(instId), {
			params: params,
		}),
	getCategoryFieldsByType: (instId, params) =>
		axiosInstance.get(endPoint.getCategoryFieldsByType(instId), {
			params: params,
		}),
}

export default categoryAPI
