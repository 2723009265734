<template>
	<div class="order-balloon" :class="{ left: mode == 'left' }" v-if="showBalloon">
		<div class="order-top">
			<div class="order">
				<span v-for="item in orderStep" :class="{ active: order == item }"></span>
			</div>
			<button @click="showBalloon = false">
				<img src="@/assets/svg/popup-close.svg" alt="close" />
			</button>
		</div>
		<h6>{{ tt(title) }}</h6>
		<p>{{ tt(content) }}</p>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		order: {
			type: Number,
			default: 0,
		},
		step: {
			type: Number,
			default: 0,
		},
		mode: {
			type: String,
			default: '',
		},
	},
	computed: {
		orderStep() {
			let orderArr = []

			for (let i = 1; i <= this.step; i++) {
				orderArr.push(i)
			}

			return orderArr
		},
	},
	data() {
		return {
			showBalloon: true,
		}
	},
}
</script>
