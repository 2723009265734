var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "preview-modal",
    attrs: {
      "centered": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt('GHS 라벨 미리보기')))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('modal-footer', {
          attrs: {
            "isPrev": ""
          },
          on: {
            "goPrev": function goPrev($event) {
              return _vm.$emit('goPrev');
            },
            "ok": function ok($event) {
              return _vm.$emit('complete');
            },
            "cancel": _vm.cancel
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('ghs-label', {
    attrs: {
      "volume": _vm.normalizedVolume
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }