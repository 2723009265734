var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "regist-method-button",
    "class": {
      active: _vm.active
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "width": "40",
      "height": "40"
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('p', {
    staticClass: "subtext"
  }, [_vm._v(_vm._s(_vm.tt(_vm.subtext)))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }