var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('div', {
    staticClass: "Modal ModalDeleteStorage"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v("저장소 삭제")]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _vm._m(0), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__delete",
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("삭제")])])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Popup__content"
  }, [_c('p', {
    staticClass: "Popup__plain-text"
  }, [_vm._v(" 정말로 저장소를 삭제하시겠습니까?"), _c('br'), _vm._v(" 이 동작은 되돌릴 수 없습니다. ")])]);
}]

export { render, staticRenderFns }