var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLegalList ? _c('div', {
    staticClass: "info-field-wrap"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.legal.legalName)))]), _c('ul', _vm._l(_vm.legal.list, function (l) {
    return _c('li', {
      key: l.legalId,
      staticClass: "description"
    }, [_vm._v(" " + _vm._s(l.description) + " ")]);
  }), 0)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }