<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade hide-footer @close="cancel">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="body">
				<div class="text-wrap" v-html="tt(headerText)"></div>
				<div class="reason-wrap">
					<div class="input-head-text">
						<p>{{ tt(titleTextarea) }}</p>
						<p class="text-length">
							<span class="length" :class="{ over: isLengthOver }">{{ text.length }}</span> /{{ textMaxLength }}
						</p>
					</div>
					<div v-click-outside="focusOut">
						<textarea
							class="input-reason"
							:class="{ error: isShowTextError && isLengthOver }"
							:value="formattedText"
							@input="setText"
							@focus="isFocus = true"
							:placeholder="tt(placeholder)"
						/>
						<p class="error-msg" v-if="isShowTextError && isLengthOver">
							<img src="@/views/inventoryGen2/components/svg/icon-error.svg" />
							{{ tt(errorMsg) }}
						</p>
						<recent
							ref="recent"
							:show="isKeywordWrapperOpen"
							:list="commentList"
							@clickComment="clickComment"
							@deleteComment="clickDeleteComment"
						/>
					</div>
				</div>
			</div>
			<div class="modal-footer-wrap">
				<button class="button-white" @click="cancel">
					{{ tt('취소') }}
				</button>
				<button class="button-white" v-if="useMiddleBtn" @click="$emit('clickMiddle')">
					{{ tt(middleBtnText) }}
				</button>
				<button :class="`button-${variant}`" @click="complete">
					{{ tt(confirmText) }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
import Recent from '@/views/inventoryGen2/components/detail/modal/modalInputUse/recent.vue'
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
export default {
	name: 'ModalTextarea',
	mixins: [MixinItem],
	components: { Recent },
	props: {
		title: {
			type: String,
			default: '',
		},
		headerText: {
			type: String,
			default: '',
		},
		titleTextarea: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMsg: {
			type: String,
			default: '글자수를 초과하였습니다.',
		},
		textMaxLength: {
			type: Number,
			default: 200,
		},
		noRecent: {
			type: Boolean,
			default: false,
		},
		confirmText: {
			type: String,
			default: '',
		},
		variant: {
			type: String,
			default: 'primary',
		},
		useMiddleBtn: {
			type: Boolean,
			default: false,
		},
		middleBtnText: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isFocus: false,
			text: '',
			isShowTextError: false,
			commentList: [],
		}
	},
	computed: {
		// ...mapState('inventoryGen2/item', ['commentList']),
		isLengthOver() {
			return this.text.length > this.textMaxLength
		},
		isKeywordWrapperOpen() {
			return this.isFocus && this.text.length <= 0 && !this.noRecent
		},
		formattedText() {
			return this.text.replaceAll('\\n', '\n')
		},
	},
	watch: {
		isLengthOver() {
			if (!this.isLengthOver) this.isShowTextError = false
		},
	},
	methods: {
		...mapActions('inventoryGen2/item', ['getComment', 'addComment', 'deleteComment']),
		initData() {
			this.text = ''
			this.isFocus = false
			this.isShowTextError = false
		},
		async show() {
			this.$refs['modal'].show()
			if (this.type) this.commentList = await this.getComment(this.type)
		},
		hide() {
			this.$refs['modal'].hide()
			this.initData()
		},
		cancel() {
			this.$emit('cancel')
			this.hide()
		},
		setText(e) {
			this.text = e.target.value
		},
		checkValid() {},
		async complete() {
			if (this.isLengthOver) {
				this.isShowTextError = true
				return
			}
			if (this.text) await this.addComment({ type: this.type, comment: this.formattedText })
			this.$emit('complete', this.formattedText)
		},
		clickComment(text) {
			this.text = text
			this.isFocus = false
		},
		async clickDeleteComment(id) {
			await this.deleteComment(id)
			this.commentList = await this.getComment(this.type)
		},
		focusOut() {
			this.isFocus = false
		},
		// doNotEnter(e) {
		// 	// 엔터 방지
		// 	if (e.keyCode === 13) {
		// 		e.preventDefault()
		// 		return false
		// 	}
		// },
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/inventory/gen2/modal/_modal-input-use.scss';
@import '@/styles/scss/modal/_modal-base.scss';
</style>
