var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "label-wrap"
  }, [_c('div', {
    staticClass: "list-wrap"
  }, [_c('p', {
    staticClass: "list-title"
  }, [_vm._v(_vm._s(_vm.tt('물리적 위험성')))]), _c('button-list', {
    ref: "list-p",
    attrs: {
      "list": _vm.physicalCodeList
    },
    on: {
      "change": function change(list) {
        return _vm.handleChange('physical', list);
      }
    }
  })], 1), _c('div', {
    staticClass: "list-wrap"
  }, [_c('p', {
    staticClass: "list-title"
  }, [_vm._v(_vm._s(_vm.tt('건강 유해성')))]), _c('button-list', {
    ref: "list-h",
    attrs: {
      "list": _vm.healthCodeList
    },
    on: {
      "change": function change(list) {
        return _vm.handleChange('health', list);
      }
    }
  })], 1), _c('div', {
    staticClass: "list-wrap"
  }, [_c('p', {
    staticClass: "list-title"
  }, [_vm._v(_vm._s(_vm.tt('환경 유해성')))]), _c('button-list', {
    ref: "list-e",
    attrs: {
      "list": _vm.environmentCodeList
    },
    on: {
      "change": function change(list) {
        return _vm.handleChange('environment', list);
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }