var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "ModalQuantityMultipleSetting"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt('지정수량 배수 설정')))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content Form__wrap"
  }, [_c('div', {
    staticClass: "Popup__select-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('저장소 선택')))]), _c('lm-option-box-number', {
    attrs: {
      "list": _vm.storageList,
      "valueKey": "id",
      "textKey": "name",
      "placeholder": _vm.tt('저장소를 선택해주세요')
    },
    on: {
      "change": _vm.changeStorage
    },
    model: {
      value: _vm.selectStorage,
      callback: function callback($$v) {
        _vm.selectStorage = $$v;
      },
      expression: "selectStorage"
    }
  })], 1), _c('div', {
    staticClass: "Popup__select-field mt-20"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('유별 선택')))]), _c('lm-option-box', {
    attrs: {
      "list": _vm.legalList,
      "valueKey": "legalId",
      "textKey": "legalName",
      "placeholder": _vm.tt('유별을 선택해주세요')
    },
    on: {
      "change": _vm.changeCategory
    },
    model: {
      value: _vm.selectCategory,
      callback: function callback($$v) {
        _vm.selectCategory = $$v;
      },
      expression: "selectCategory"
    }
  })], 1), _c('div', {
    staticClass: "Popup__select-field mt-20"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('품명 선택')))]), _c('lm-option-box', {
    attrs: {
      "list": _vm.productList,
      "valueKey": "legalId",
      "textKey": "legalName",
      "placeholder": _vm.tt('품명을 입력해주세요')
    },
    on: {
      "change": _vm.changeProduct
    },
    model: {
      value: _vm.selectProduct,
      callback: function callback($$v) {
        _vm.selectProduct = $$v;
      },
      expression: "selectProduct"
    }
  })], 1), _c('div', {
    staticClass: "flex mt-20",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('시행령 지정수량')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.volume,
      expression: "volume"
    }],
    attrs: {
      "type": "text",
      "readonly": "",
      "placeholder": _vm.tt('유별 및 품명을 선택해주세요')
    },
    domProps: {
      "value": _vm.volume
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.volume = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/multiplication.svg"),
      "alt": "icon"
    }
  })]), _c('div', {
    staticClass: "Popup__input-field"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('배수')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.multiple,
      expression: "multiple"
    }],
    attrs: {
      "type": "number",
      "placeholder": _vm.tt('배수를 입력해주세요')
    },
    domProps: {
      "value": _vm.multiple
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.multiple = $event.target.value;
      }
    }
  })])]), _c('span', {
    staticClass: "noti"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/noti-checked.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('최대 저장량')) + ": " + _vm._s(_vm.limitVolume) + _vm._s(_vm.volumeUnit))])]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }