<template>
	<div class="AppHeader__menu-item" :class="{ disabled: disableClick }">
		{{ text }}

		<img v-show="nested" src="@/assets/svg/arrow-down.svg" />
		<div class="AppHeader__menu-list" :class="{ left: isDirectionLeft }" v-show="isVisible">
			<div v-show="isVisible" v-for="(item, index) in itemNameList" :key="`header-inner-item-${index}`" @click="hideDropdown">
				<app-header-menu-item-vue v-if="isVisible" :text="tt(item.itemName)"></app-header-menu-item-vue>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'app-header-menu-item-vue',
	props: {
		text: {
			type: String,
		},
		nested: {
			type: Boolean,
			default: false,
		},
		disableClick: {
			type: Boolean,
			default: false,
		},
		nestedDirection: {
			type: String,
			default: 'left',
		},
		isVisible: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data() {
		return {
			itemNameList: [
				{
					itemName: '공지사항',
					clickEvent: () => {},
				},
				{
					itemName: '이용문의',
					clickEvent: () => {},
				},
				{
					itemName: '홈페이지',
					clickEvent: () => {
						this.goHomepage()
					},
				},
				{
					itemName: '이용약관',
					clickEvent: () => {},
				},
			],
		}
	},
	computed: {
		isDirectionLeft() {
			return this.nestedDirection === 'left'
		},
	},
	methods: {
		getItemName(index) {
			return this.itemNameList[index].itemName
		},
		hideDropdown() {
			this.rightMenuOn = false
		},
		goHomepage() {
			window.open('https://lab-manager.com')
		},
	},
}
</script>

<style lang="scss" scoped>
.AppHeader__menu-item {
	display: flex;
	width: calc(100% - 10px);
	height: 30px;
	font-size: 0.875rem;
	text-align: left;
	align-items: center;
	justify-content: space-between;
	margin: 0px 5px;
	border-radius: 5px;
	padding: 5px 11px;
	font-weight: normal;
	cursor: pointer;

	&:hover {
		background-color: #f2f2f2;
	}

	&.disabled {
		cursor: default;
	}

	.AppHeader__menu-list {
		width: 198px;
		position: absolute;
		top: 100px;
		right: 198px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
		border: solid 1px #e4e5ea;
		background-color: $LAB_WHITE;
		border-radius: 5px;
		padding: 5px 0px;
		cursor: default;

		&.left {
			right: 198px;
		}
	}
}
</style>
