<template>
	<div class="card lawMaterial" :class="{ displayOff: !displayCard }">
		<div class="card-title">
			<div class="imgbox">
				<img src="@/assets/svg/dashboard-icon7.svg" alt="icon" />
			</div>
			<h4 class="Btn__hover-balloon-w" @click="goPage">
				{{ tt('법령별 화학물질 현황') }}
				<div class="balloon">
					<p>{{ tt('그룹별 법령물품으로 이동합니다') }}</p>
				</div>
				<button>
					<img src="@/assets/svg/arrow-diagonal.svg" alt="icon" />
				</button>
			</h4>
			<div class="ml-auto switch-setting" v-if="setting == true">
				<h5>{{ tt('대시보드에 노출') }}</h5>
				<label class="switch">
					<input type="checkbox" v-model="displayCard" @change="toggleCard(id, displayCard)" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="card-box" v-for="(item, index) in list" :key="index">
			<div class="box-title" @click="goInventory(item.legalId)">
				<h5>{{ tt(item.legalName) }}</h5>
				<h4 class="ml-auto">{{ item.kindCount }}{{ tt('종') }} ({{ item.count }}{{ tt('개') }})</h4>
			</div>
			<div class="box-content">
				<ul>
					<li @click.stop="goInventory(subitem.legalId)" v-for="subitem in item.childLegalList" :key="subitem.legalId">
						<p>{{ tt(subitem.legalName) }}</p>
						<h5>{{ subitem.kindCount }}{{ tt('종') }} ({{ subitem.count }}{{ tt('개') }})</h5>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	name: 'CardLawMaterial',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Array,
		},
		toggleCard: {
			type: Function,
			default: () => {},
		},
	},
	mounted() {
		this.displayCard = this.visible
	},
	data() {
		return {
			displayCard: true,
		}
	},
	methods: {
		allCount(list) {
			const countList = []

			if (list.length > 0) {
				for (let i = 0; i < list.length; i++) {
					countList.push(list[i].count)
				}

				const sum = countList.reduce((a, b) => a + b)

				return sum
			}
		},
		goPage() {
			this.$router.push({
				name: PageName.Safety.LegalProduct,
			})
		},
		goInventory(legalsId) {
			this.$router.push({
				name: PageName.InventoryGen2.List,
				query: {
					classification: 'CAT_CHEMICAL',
					legalsId: [legalsId],
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';

.card {
	&.lawMaterial {
		// .card-title {
		// 	cursor: default !important;
		// 	.Btn__hover-balloon-w {
		// 		cursor: default !important;
		// 		text-decoration: none !important;
		// 		&:hover {
		// 			cursor: default;
		// 			text-decoration: none !important;
		// 		}
		// 	}
		// }
	}
}
</style>
