var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "detail-wrap",
    attrs: {
      "id": "cargo-detail"
    }
  }, [_c('detail-header', {
    on: {
      "delete": function _delete($event) {
        return _vm.$refs['modal-delete'].show();
      },
      "edit": _vm.showCargoEditProduct,
      "needInput": _vm.showCargoNeedInputProduct,
      "print": _vm.showPrintPopup
    }
  }), _c(_vm.activeBadge, {
    tag: "component",
    attrs: {
      "item": _vm.item,
      "legals": _vm.legals
    }
  }), _c('div', {
    staticClass: "detail-tab"
  }, _vm._l(_vm.tabList, function (tab) {
    return _c('div', {
      key: tab.key,
      staticClass: "tab-button",
      "class": {
        select: _vm.selectTab === tab.key
      },
      on: {
        "click": function click($event) {
          _vm.selectTab = tab.key;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.tt(tab.title)) + " ")]);
  }), 0), _c('div', {
    staticClass: "active-page-wrap"
  }, [_c(_vm.activePage, {
    tag: "component",
    attrs: {
      "item": _vm.item,
      "legals": _vm.legals
    }
  })], 1), _c('detail-footer', {
    attrs: {
      "item": _vm.item,
      "isShowEdit": _vm.isShowEdit,
      "isShowPrintSticker": _vm.isShowPrintSticker,
      "isShowGHSButton": _vm.isShowGHSButton
    },
    on: {
      "edit": _vm.showCargoEditProduct,
      "delete": function _delete($event) {
        return _vm.$refs['modal-delete'].show();
      },
      "makeGHS": _vm.makeGHS,
      "manageGHS": _vm.manageGHS
    }
  }), _c('modal-form-cargo-product', {
    ref: "modal-form-cargo-product",
    on: {
      "goNext": _vm.showCargoManagement
    }
  }), _c('modal-form-cargo-management', {
    ref: "modal-form-cargo-management",
    on: {
      "goPrev": _vm.showCargoProduct
    }
  }), _c('modal-confirm', {
    ref: "modal-delete",
    attrs: {
      "variant": "warning",
      "title": "입고 물품 삭제",
      "content": "입고 물품을 정말로 삭제하시겠습니까?",
      "confirmText": "삭제"
    },
    on: {
      "confirm": _vm.deleteItem
    }
  }), _c('modal-confirm', {
    ref: "modal-delete-ghs",
    attrs: {
      "variant": "warning",
      "title": "GHS 라벨 삭제",
      "content": "GHS 라벨을 정말로 삭제하시겠습니까?",
      "confirmText": "삭제"
    },
    on: {
      "confirm": _vm.deleteGHS
    }
  }), _c('modal-make-ghs', {
    ref: "make-ghs",
    attrs: {
      "msds": _vm.item.msds
    },
    on: {
      "showGuide": function showGuide($event) {
        return _vm.$refs['guide'].show();
      },
      "goNext": function goNext($event) {
        _vm.$refs['make-ghs'].hide();

        _vm.$refs['ghs-form'].show();
      },
      "uploadMSDS": _vm.uploadMSDS
    }
  }), _c('modal-ghs-form', {
    ref: "ghs-form",
    on: {
      "goNext": _vm.showPreviewGHS,
      "goPrev": function goPrev($event) {
        _vm.$refs['make-ghs'].show('prev');

        _vm.$refs['ghs-form'].hide();
      }
    }
  }), _c('modal-ghs-preview', {
    ref: "ghs-preview",
    on: {
      "goPrev": function goPrev($event) {
        _vm.$refs['ghs-preview'].hide();

        _vm.$refs['ghs-form'].show();
      },
      "complete": _vm.completeCreateGHS
    }
  }), _c('modal-ghs-label', {
    ref: "ghs-label",
    on: {
      "edit": _vm.editGHS,
      "delete": _vm.showModalDeleteGHS
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }