export default {
	data() {
		return {}
	},
	methods: {
		scrollToInvalid() {
			let offsetTop = document.querySelector('.form-input.error')?.offsetTop - 30
			document.querySelector('.modal-body')?.scrollTo(0, offsetTop)
		},
	},
}
