<template>
	<div :style="divStyle"></div>
</template>

<script>
export default {
	props: {
		divStyle: {
			type: Object,
			default: () => {},
		},
	},
}
</script>
