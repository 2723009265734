<template>
	<div class="excelRegisteringMemeber">
		<div class="wrapper">
			<page-header
				:title="tt('멤버 일괄 등록')"
				:isStart="$route.query.isStart ? $route.query.isStart : false"
				@goStartMember="goStartMember"
			></page-header>
			<ul>
				<li>
					<div class="flex">
						<h5>{{ tt('초기 비밀번호') }}</h5>
						<span v-if="isPw">{{ tt('설정완료') }}</span>
					</div>
					<input type="radio" v-model="order" :value="1" />
					<div class="content">
						<p v-html="tt('유저 계정의 초기 비밀번호를 설정합니다.<br>멤버 등록 시, 유저의 이메일로 초기 비밀번호가 전송됩니다.')"></p>
						<input type="text" :placeholder="tt('초기 비밀번호')" v-model="password" />
						<button class="Btn__yellow" @click="changePw" v-text="!password ? tt('저장') : tt('확인')"></button>
					</div>
				</li>
				<li>
					<div class="flex">
						<h5>{{ tt('엑셀 파일 업로드') }}</h5>
						<span>{{ fileName }}</span>
					</div>
					<input type="radio" v-model="order" :value="2" />
					<div class="content">
						<p
							v-html="
								tt(
									'샘플을 다운로드 받아 등록할 멤버의 정보를 입력해주세요.<br>엑셀 혹은 CSV 파일을 업로드하여 한번에 사용자를 추가/제거/편집 할 수 있습니다.'
								)
							"
						></p>
						<a
							class="Btn__yellow"
							:href="
								$route.query.page
									? '/template/멤버 일괄 등록 템플릿(멤버관리).xlsx'
									: '/template/멤버 일괄 등록 템플릿(Onboarding).xlsx'
							"
							download
						>
							{{ tt('엑셀 템플릿 다운로드') }}
						</a>
						<a
							class="Btn__yellow"
							style="margin-left: 10px"
							:href="
								$route.query.page
									? '/template/멤버 일괄 등록 템플릿(멤버관리).zip'
									: '/template/멤버 일괄 등록 템플릿(Onboarding).zip'
							"
							download
						>
							{{ tt('CSV 템플릿 다운로드') }}
						</a>
						<div class="excel-box" ref="excel-box" @drop="dropExcel">
							<label ref="file-button" :class="{ noneUserSelect: fileName }"
								><input class="dropFile" type="file" ref="dropFile" @change="onChange($event)"
							/></label>
							<div>
								<div v-if="!fileName">
									<img src="@/assets/svg/icon-excel_b.png" />
									<p>
										{{ tt('엑셀 혹은 CSV 파일을 마우스로 끌어오거나, 직접 선택해 주세요') }}
									</p>
								</div>
								<div class="isExcel" v-else>
									<img src="@/assets/svg/excel-download.svg" alt="icon" />
									<p>{{ fileName }}</p>
									<button @click="initFile">
										<img src="@/assets/svg/Close-1.svg" alt="icon" />
									</button>
								</div>
								<button class="btn" @click="showChangeFile">
									{{ tt('파일선택') }}
								</button>
							</div>
						</div>
					</div>
				</li>
				<li :class="{ noBorder: order == 3 }">
					<div class="flex">
						<h5>{{ tt('일괄 등록 미리보기') }}</h5>
						<span>{{ list.length }}{{ tt('명') }}</span>
					</div>
					<input type="radio" v-model="order" :value="3" />
					<div class="content">
						<div class="noti" v-if="list.length > 0" :class="error == true ? 'warning' : 'safe'">
							<p
								v-html="
									error == true
										? tt(
												`<span style='color:#ff3b31'>필수값 미입력</span> 혹은 <span style='color:#ff3b31'>입력값 오류</span>의 경우에는 <span style='color:#ff3b31'>빨간색</span>으로 표시되며 권한 미입력 시 일반 권한이 부여됩니다.`
										  )
										: `모든 항목이 <span style='color:#0DC577'>정상적으로 입력</span>되었습니다.`
								"
							></p>
						</div>
						<div class="boxwrap">
							<div class="flex">
								<search-box :placeholder="tt('검색')" @inputValue="inputKeyword" @search="search"></search-box>
								<div class="right-btn-group">
									<button class="btn" @click="showChangeFile">
										<img src="@/assets/svg/excel-download.svg" />
										{{ tt('파일 재업로드') }}
									</button>
									<button class="btn" :disabled="checkList.length == 0" @click="showDeleteMember()">
										<img src="@/assets/svg/PeopleOut.svg" />
										{{ tt('삭제하기') }}
									</button>
								</div>
							</div>
							<div class="Table__wrap">
								<table>
									<thead>
										<tr>
											<th>
												<button
													class="checkbox"
													:class="({ active: isActive }, { active: isCheck })"
													@click="allChecked"
												></button>
											</th>
											<th v-for="item in checkHeaderList" :key="item.name" :width="item.width">
												<span>
													{{ tt(item.name) }}
													<button @click="changeSort(item)" v-if="item.sort">
														<img src="@/assets/svg/arrow-b.svg" alt="icon" />
													</button>
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<registering-item
											ref="registering-item"
											v-for="(item, index) in list"
											:key="`list` + index"
											:list="list"
											:item="item"
											@checkId="addCheckIdList"
											@uncheckId="deleteCheckIdList"
											:headers="$route.query.page ? memberHeaderList : headerList"
											:offset="offset"
											:length="length"
											:mode="'all'"
											@showRegisteringMember="showRegisteringMember"
											@showDeleteMember="showDeleteMember"
											:checkList="checkList"
											:info="info"
											:index="index"
											:allCheck="isActive"
										></registering-item>
									</tbody>
								</table>
							</div>
						</div>

						<pagination :total="count" @page="pagination" :isPerPage="false"></pagination>
					</div>
				</li>
			</ul>
		</div>

		<div class="fixed-btn">
			<button class="Btn__yellow" @click="allMemberRegistering" :disabled="list.length == 0">
				{{ tt('등록') }}
			</button>
			<button class="Btn__lg" @click="$route.query.isStart ? goStartMember() : goPage()">
				{{ tt('취소') }}
			</button>
		</div>

		<!--modals-->
		<modal-registering-member
			ref="modal-registering-member"
			page="excel"
			@complete="changeInfo"
			@update="changeInfo"
			:userInfo="userInfo"
		></modal-registering-member>
		<modal-common ref="modal-common" @isHide="hideModal"></modal-common>
	</div>
</template>

<script>
import pageHeader from '@/views/creation/pages/components/pageHeader.vue'
import SearchBox from '@/views/common/components/SearchBox.vue'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
import ModalRegisteringMember from '@/views/institute/member/modals/ModalRegisteringMember.vue'
import registeringItem from '@/views/creation/pages/components/registeringItem.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import { mapState, mapActions } from 'vuex'
import instituteMemberAPI from '@/services/api/institute/member'
import userAPI from '@/services/api/institute/user'
import instituteInformationAPI from '@/services/api/institute/information'
import PageName from '@/utils/define/PageName'
import MixinLicense from '@/mixins/license'

export default {
	components: {
		pageHeader,
		SearchBox,
		ModalCommon,
		registeringItem,
		Pagination,
		ModalRegisteringMember,
	},
	created() {
		if (this.$route.query.page) {
			this.checkHeaderList = this.memberHeaderList
		} else {
			this.checkHeaderList = this.headerList
		}

		if (this.$route.query.isStart) {
			this.isStart = this.$route.query.isStart
		}
		this.count = this.list.length

		//temp
		this.copyList = JSON.parse(JSON.stringify(this.list))
	},
	mixins: [MixinLicense],
	async mounted() {
		await this.loadInitPw()

		if (this.password != null) {
			this.order = 2
		}
	},
	data() {
		return {
			order: 1,
			keyword: '',
			isActive: false,
			isCheck: false,
			count: 0,
			offset: 0,
			length: 50,
			checkList: [],
			headerList: [
				{
					name: this.tt('이름'),
					width: 120,
					sort: false,
				},
				{
					name: this.tt('이메일(ID)'),
					width: 235,
					sort: false,
				},
				{
					name: this.tt('연락처'),
					width: 145,
					sort: false,
				},
				{
					name: this.tt('권한'),
					width: 260,
					sort: false,
				},
				{
					name: this.tt('관리'),
					width: 170,
					sort: false,
				},
			],
			memberHeaderList: [
				{
					name: this.tt('이름'),
					width: 120,
					sort: false,
				},
				{
					name: this.tt('이메일(ID)'),
					width: 235,
					sort: false,
				},
				{
					name: this.tt('소속'),
					width: 444,
					sort: false,
				},
				{
					name: this.tt('연락처'),
					width: 145,
					sort: false,
				},
				{
					name: this.tt('권한'),
					width: 260,
					sort: false,
				},
				{
					name: this.tt('관리'),
					width: 170,
					sort: false,
				},
			],
			checkHeaderList: [],
			list: [],
			copyList: [],
			fileName: null,
			password: null,
			info: {},
			// error: false,
			num: null,
			selectedId: null,
			userInfo: {},
			isStart: false,
			isPw: false,
		}
	},
	computed: {
		error() {
			const err = []
			this.list.forEach(x => {
				x.errors.forEach(y => {
					err.push(y)
				})
			})

			if (err.length > 0) {
				return true
			} else {
				return false
			}
		},
	},
	watch: {},
	methods: {
		async loadUser() {
			const response = await userAPI.lookupUserInfo()

			this.userInfo = response.data
		},
		inputKeyword(keyword) {
			this.keyword = keyword
			if (this.keyword == '') {
				this.list = this.copyList
			}
		},
		search() {
			let keywordList = []

			this.list.forEach((x, index) => {
				let listKeys = Object.keys(x)

				for (let key of listKeys) {
					if (x[key] && x[key].indexOf(this.keyword) >= 0) {
						keywordList.push(x)
					}
				}

				for (let permission of x.permissions) {
					if (permission.name.indexOf(this.keyword) >= 0) {
						keywordList.push(x)
					}
				}
			})

			this.list = keywordList
		},
		addCheckIdList(id) {
			this.checkList.push(id)
			if (this.checkList.length == this.list.length) {
				this.isCheck = true
			}
		},
		deleteCheckIdList(id) {
			for (let i = 0; i < this.checkList.length; i++) {
				if (this.checkList[i] == id) {
					this.checkList.splice(i, 1)
				}
			}
			this.isCheck = false
		},
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
		},
		allChecked() {
			if (this.isActive) {
				this.isActive = false
				this.isCheck = false
			} else {
				this.isActive = true
				this.isCheck = true
			}
		},
		dropExcel(event) {
			event.preventDefault()
			this.$refs.dropFile.files = event.dataTransfer.files
			this.onChange(event)
		},
		async onChange(event) {
			const files = [...this.$refs.dropFile.files]

			if (files.length > 0) {
				const formData = new FormData()

				formData.append('file', files[0])

				await instituteMemberAPI
					.checkFile(this.$route.params.instituteId, formData)
					.then(response => {
						this.list = response.data
						this.copyList = JSON.parse(JSON.stringify(response.data))
					})
					.catch(error => {
						this.$root.toast(
							this.tt('멤버 일괄등록 실패'),
							this.tt('필수값이 미입력 되있거나, 입력값이 오류인 항목이 있습니다. 확인 후 재시도해주세요'),
							'error'
						)
					})

				this.fileName = files[0].name

				this.order = 3
			}

			this.$refs.dropFile.value = ''
		},
		showRegisteringMember(mode, item, index) {
			this.$refs['modal-registering-member'].show(mode, item)
			this.num = index
		},
		showDeleteMember(id) {
			if (id) {
				this.selectedId = id
				this.checkList = []
			}

			this.$refs['modal-common'].show(
				this.tt('목록에서 사용자 삭제'),
				this.tt('일괄 등록 미리보기에서 선택한 사용자를 삭제하시겠습니까?'),
				'delete',
				'삭제'
			)
		},
		showChangeFile() {
			this.$refs['modal-common'].show(
				this.tt('파일 변경'),
				this.tt('기존에 선택했던 파일이 새로운 파일로 교체되며, 미리보기에 있는 모든 정보는 사라집니다. 파일을 변경하시겠습니까?'),
				'yellow',
				'변경'
			)
		},
		hideModal(select) {
			if (select.button == '삭제') {
				if (select.check == true) {
					if (this.selectedId) {
						this.list.splice(this.selectedId, 1)
					}

					if (this.checkList.length > 0) {
						let tempArr = []
						this.checkList.forEach(x => {
							tempArr.push(this.list[x])
						})
						this.list = this.list.filter(item => !tempArr.includes(item))
					}
				}
			} else if (select.button == '변경') {
				if (select.check == true) {
					this.$refs['file-button'].click()
				}
			}
			this.$refs['modal-common'].hide()
			this.selectedId = null
			this.checkList = []
			this.isActive = false
		},
		async loadInitPw() {
			const response = await instituteMemberAPI.lookupInitPw(this.$route.params.instituteId)

			this.password = response.data.password

			if (this.password.length > 0) {
				this.isPw = true
			}
		},
		changePw() {
			const payload = {
				password: this.password,
			}

			if (this.password.length == 0 || this.password == null) {
				this.$root.toast(this.tt('저장 실패'), this.tt('초기 비밀번호를 입력해주세요'), 'error')

				return false
			} else if (this.password.length < 6) {
				this.$root.toast(this.tt('저장 실패'), this.tt('초기 비밀번호는 6자리 이상이어야 합니다'), 'error')

				return false
			}

			instituteMemberAPI.updateInitPw(this.$route.params.instituteId, payload)
			this.order = 2
			this.isPw = true
		},
		initFile() {
			this.fileName = null
		},
		goPage() {
			this.$router.go(-1)
		},
		async changeInfo(info) {
			this.copyList[this.num] = info
			let groupsIdArr = info.groupsId
			let permissionsIdArr = info.permissionsId
			for (let i = 0; i < this.copyList.length; i++) {
				if (i != this.num) {
					let permissionNameArr = []
					let groupsNameArr = []
					if (this.copyList[i].permissions) {
						for (let permission of this.copyList[i].permissions) {
							permissionNameArr.push(permission.name)
						}

						this.copyList[i].permissionsName = permissionNameArr.join(',')
					}
					if (this.copyList[i].groups) {
						for (let group of this.copyList[i].groups) {
							groupsNameArr.push(group.fullPathName)
						}

						this.copyList[i].groupsFullPathName = groupsNameArr.join(',')
					}
				}
				this.copyList[i].errors = []
			}
			this.list = this.copyList

			const payload = {
				list: this.list,
			}
			const response = await instituteMemberAPI.checkInfo(this.$route.params.instituteId, payload)
			this.list = []

			this.$refs['modal-registering-member'].hide()
			this.list = response.data

			for (let j = 0; j < this.list.length; j++) {
				this.list[j].groupsId = groupsIdArr
				this.list[j].permissionsId = permissionsIdArr
			}

			this.copyList = JSON.parse(JSON.stringify(this.list))
			this.$refs['registering-item'][0].isValid()
		},
		async allMemberRegistering() {
			let goNext = false
			if (this.error == true) {
				this.$root.toast(
					this.tt('멤버 일괄등록 실패'),
					this.tt('필수값이 미입력 되있거나, 입력값이 오류인 항목이 있습니다. 확인 후 재시도해주세요.'),
					'error'
				)

				return false
			}
			if (this.isTrial) {
				this.$root.howToAddLicense()
				return false
			} else if (this.list.length + this.instInfo.allMemberCount > this.license.quantity) {
				this.$root.addLicense()
				return false
			}

			for (let item of this.list) {
				if (!item.password) {
					item.password = this.password
				}
				await instituteMemberAPI.addMember(this.$route.params.instituteId, item)
				goNext = true
			}

			if (goNext) {
				this.$root.toast(this.tt('멤버 등록 완료'), this.tt('멤버가 등록되었습니다'), 'success')
				if (this.$route.query.page) {
					this.$router.replace({
						name: PageName.Institution.MemberManagement,
					})
				} else {
					this.$router.push({
						name: 'startPro',
						query: {
							isStart: true,
							orderMember: false,
						},
					})
				}
			}
		},
		goStartMember() {
			if (this.isStart == true) {
				this.$router.push({
					name: 'startPro',
					query: {
						isStart: this.isStart,
						orderMember: false,
					},
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
//temp
.excelRegisteringMemeber {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 101;
	top: 0;
	left: 0;
	background-color: $LAB_WHITE;
	overflow-y: auto;
}

.excelRegisteringMemeber {
	color: #000;
	.Btn__yellow {
		background: $LAB_YELLOW;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		display: inline-flex;
		width: 195px;
		height: 48px;
		align-items: center;
		justify-content: center;
		font-size: 0.875rem;
	}
	.wrapper {
		width: 1000px;
		margin: 0 auto;
		padding-bottom: 150px;
		ul {
			width: 100%;
			li {
				position: relative;
				border-bottom: 1px solid $INPUT;
				box-sizing: border-box;
				&.noBorder {
					border-bottom: 0;
				}
				> .flex {
					height: 75px;
					align-items: center;
					h5 {
						font-size: 1.5rem;
						font-weight: 700;
					}
					span {
						color: $BLUE;
						display: inline-block;
						font-size: 1rem;
						font-weight: 400;
						margin-left: auto;
						margin-right: 41px;
					}
				}
				input[type='radio'] {
					width: 0;
					height: 0;
					position: absolute;
					top: 17px;
					right: 36px;
					border: 0;
					&:before {
						width: 36px;
						height: 36px;
						cursor: pointer;
						content: url(~@/assets/svg/arrow-down_b.svg);
						border: 0;
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
				input[type='radio']:checked {
					&:before {
						transform: rotate(180deg);
					}
				}
				input[type='radio']:checked ~ .content {
					max-height: 1000vh;
					height: unset;
				}
				.content {
					max-height: 0;
					height: 0;
					overflow: hidden;
					text-align: left;
					> p {
						font-size: 1rem;
						font-weight: 400;
						line-height: 1.5;
						margin-top: 40px;
						width: 100%;
						display: block;
					}
				}
				&:nth-child(1) {
					input[type='text'] {
						display: block;
						width: 400px;
						margin-top: 20px;
						height: 48px;
						border: 1px solid #e6e6e6;
						border-radius: 5px;
						padding-left: 12px;
					}
					button {
						width: 400px;
						display: block;
						height: 48px;
						font-size: 1rem;
						font-weight: 700;
						margin-top: 20px;
						margin-bottom: 40px;
					}
				}
				&:nth-child(2) {
					.Btn__yellow {
						width: 195px;
						height: 48px;
						font-size: 0.875rem;
						font-weight: 700;
						margin: 20px 0;
					}
					.excel-box {
						width: 100%;
						height: 240px;
						border: 2px dashed $INPUT;
						border-radius: 10px;
						margin-bottom: 40px;
						position: relative;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						label {
							width: inherit;
							height: inherit;
							max-height: inherit;
							position: absolute;
							top: 0;
							left: 0;
							cursor: pointer;
							opacity: 0;
							display: block;
							position: absolute;
							input {
								width: inherit;
								height: inherit;
								max-height: inherit;
								cursor: pointer;
							}
							&.noneUserSelect {
								user-select: none;
								visibility: hidden;
							}
						}
						div {
							div {
								img {
									width: 80px;
									margin-bottom: 10px;
								}
								p {
									font-size: 0.875rem;
									font-weight: 400;
								}
								&.isExcel {
									display: flex;
									height: auto;
									align-items: center;
									position: relative;
									z-index: 2;
									img {
										width: 24px;
										height: 24px;
										display: inline-block;
										margin-bottom: 0;
									}
									p {
										font-size: 1rem;
										color: $DEFAULT;
										font-weight: 400;
										height: 24px;
										display: inline-block;
									}
									button {
										display: inline-flex;
										align-items: center;
										margin-top: 0;
										width: 24px;
										height: 24px;
										padding: 0;
										margin-left: 5px;
									}
								}
							}
							button {
								margin: 20px auto 0;
							}
						}
					}
				}
				&:nth-child(3) {
					.noti {
						width: 100%;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 20px;
						border-radius: 5px;
						&.safe {
							background-color: rgba(13, 197, 119, 0.1);
						}
						&.warning {
							background-color: rgba(255, 87, 70, 0.1);
						}
						p {
							font-size: 0.875rem;
						}
					}
					.boxwrap {
						width: 100%;
						border: 1px solid $INPUT;
						border-radius: 5px;
						.flex {
							padding: 20px 30px;
							width: 100%;
						}
						.Table__wrap {
							height: unset;
							min-height: 600px;
							max-height: unset;
							overflow-y: hidden;
							overflow-x: auto;
							td {
								&:last-child {
									button {
										display: inline-block;
										&:last-child {
											margin-left: 10px;
										}
									}
								}
							}
						}
					}
					.Table__pagination {
						border-top: 0;
					}
				}
			}
		}
	}
	.fixed-btn {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		height: 78px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid $DIVIDER;
		background-color: $LAB_WHITE;
		button {
			width: 195px;
			height: 48px;
			font-size: 1rem;
			font-weight: 700;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin: 0 5px;
			&.Btn__lg {
				background-color: $GREY_1;
			}
		}
	}
}
</style>
