var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.componentKey
  }, [_c('div', {
    staticClass: "user-info-box",
    on: {
      "click": _vm.showAccountSetting
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.userInfo.image ? _vm.userInfo.image : require('@/assets/svg/member-photo-default.svg')
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('${1}님', _vm.userInfo.name)))])]), _c('modal-account-setting', {
    ref: "modal-account-setting"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }