<template>
	<div class="category-wrap">
		<div class="side-wrap">
			<div class="menu-wrap" ref="scroll-con">
				<category-sub-menu ref="chemical" :type="'chemical'" :list="chemicalList" :activeId="selectedId" @detail="detail"></category-sub-menu>
				<category-sub-menu
					ref="chromatography"
					:type="'chromatography'"
					:list="chromatographyList"
					:activeId="selectedId"
					@detail="detail"
				></category-sub-menu>
				<category-sub-menu ref="labware" :type="'labware'" :list="labwareList" :activeId="selectedId" @detail="detail"></category-sub-menu>
				<category-sub-menu
					ref="protection"
					:type="'protection'"
					:list="protectionList"
					:activeId="selectedId"
					@detail="detail"
				></category-sub-menu>
				<category-sub-menu ref="etc" :type="'etc'" :list="etcList" :activeId="selectedId" @detail="detail"></category-sub-menu>
			</div>
			<div v-b-tooltip.hover="!isCreated ? tt('권한이 없습니다.') : ''">
				<div class="fixed-menu" :class="{ disabled: !isCreated }" @click="showAddCategory">
					<img src="@/assets/svg/category-add.svg" alt="icon" />
					<p>
						{{ tt('카테고리 추가') }}
					</p>
				</div>
			</div>
		</div>
		<div class="management-wrap">
			<div class="title-wrap">
				<div>
					<h5>{{ tt(category.name) }}</h5>
					<img src="@/views/inventoryGen2/svg/Edit.svg" @click="$refs['modal-edit-category'].show(category.name)" alt="icon" />
					<button class="btn Dropdown__btn">
						<img src="@/views/inventoryGen2/svg/More.svg" alt="icon" />
						<div class="Dropdown__menu">
							<span v-b-tooltip.hover="!isUpdate ? tt('권한이 없습니다.') : ''">
								<button :disabled="!isUpdate" @click="$refs['modal-initialize-category'].show()">
									{{ tt('초기화') }}
								</button>
							</span>
							<span v-b-tooltip.hover="!isCreated ? tt('권한이 없습니다.') : ''">
								<button :disabled="!isCreated" @click="$refs['modal-copy-category'].show(`${category.name}(Copy)`)">
									{{ tt('복제') }}
								</button>
							</span>
							<span v-b-tooltip.hover="!isDelete ? tt('권한이 없습니다.') : ''">
								<button :disabled="!isDelete" @click="showDeleteCategoryModal">
									{{ tt('삭제') }}
								</button>
							</span>
						</div>
					</button>
				</div>
				<div>
					<span>{{ tt(categorySubName) }}</span>
				</div>
			</div>
			<div class="info-wrap">
				<div>
					<div>
						<h6>{{ tt('물품정보') }}</h6>
					</div>
					<toggle-field :fields="inventoryFieldList" :type="'inventory'"></toggle-field>
				</div>
				<div>
					<div>
						<h6>{{ tt('관리정보') }}</h6>
					</div>
					<toggle-field :fields="managementFieldList" :type="'management'"></toggle-field>
				</div>
				<div>
					<div>
						<h6>{{ tt('추가정보') }}</h6>
						<image-button :text="'항목 추가'" :disabled="!isCreated" @click="$refs['modal-add-field'].show()"></image-button>
					</div>
					<toggle-field :fields="customFieldList" :type="'custom'" @editField="editField"></toggle-field>
					<div class="no-data-box" v-if="customFieldList.length == 0">
						<img style="width: 206px" src="@/assets/svg/no-data_list.svg" />
						<h6>{{ tt('추가 정보가 없습니다') }}</h6>
						<p v-html="tt('<b>[항목 추가]</b> 버튼을 클릭하여 추가해보세요')"></p>
					</div>
				</div>
				<div class="button-wrap">
					<span v-b-tooltip.hover="!isUpdate ? tt('권한이 없습니다.') : ''">
						<base-button :disabled="!isUpdate" @click="$refs['modal-initialize-category'].show()">{{ tt('초기화') }}</base-button>
					</span>
					<span v-b-tooltip.hover="!isCreated ? tt('권한이 없습니다.') : ''">
						<base-button :disabled="!isCreated" @click="$refs['modal-copy-category'].show(`${category.name}(Copy)`)">{{
							tt('복제')
						}}</base-button>
					</span>
					<span v-b-tooltip.hover="!isDelete ? tt('권한이 없습니다.') : ''">
						<base-button :disabled="!isDelete" @click="showDeleteCategoryModal" :color="'reject'">{{ tt('삭제') }}</base-button>
					</span>
				</div>
			</div>
		</div>
		<modal-add-category ref="modal-add-category" @addCategory="addCategoryInfo" />
		<modal-edit-category ref="modal-edit-category" title="카테고리 편집" @editCategory="editCategoryInfo" />
		<modal-confirm
			ref="modal-initialize-category"
			title="카테고리 초기화"
			confirmText="초기화"
			@confirm="initializeCategoryInfo"
			:content="`카테고리를 초기 설정값으로 되돌립니다.</br>추가정보의 경우 항목은 유치한채로 상태가 OFF로 변경됩니다.`"
		/>
		<modal-edit-category ref="modal-copy-category" title="카테고리 복제" @editCategory="copyCategoryInfo" />
		<modal-confirm
			ref="modal-delete-category"
			variant="warning"
			confirmText="삭제"
			:title="modalDeleteTitle"
			:hasConfirm="categoryCount === 0 ? true : false"
			:cancelText="categoryCount === 0 ? '취소' : '닫기'"
			:content="modalDeleteContent"
			@confirm="deleteCategoryInfo"
		/>
		<modal-add-field ref="modal-add-field"></modal-add-field>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { LargeClassToString, MiddleClassToString } from '@/utils/define/ItemCode'
import CategorySubMenu from '../components/setting/CategorySubMenu.vue'
import ToggleField from '../components/setting/ToggleField.vue'
import ModalAddCategory from '../components/setting/modal/ModalAddCategory.vue'
import ModalEditCategory from '../components/setting/modal/ModalEditCategory.vue'
import ModalAddField from '../components/setting/modal/ModalAddField.vue'

export default {
	components: {
		CategorySubMenu,
		ToggleField,
		ModalAddCategory,
		ModalEditCategory,
		ModalAddField,
	},
	watch: {
		category(val) {
			if (this.categoryList.length > 0) {
				let classification = val.classification.replace('CAT_', '').toLowerCase()
				let index = this[`${classification}List`].findIndex(it => it.id === val.id)
				this.$nextTick(() => {
					let node = this.$refs[classification].$el.lastChild.getElementsByTagName('li')[index]
					if (node) {
						this.$refs['scroll-con'].scrollTo({ top: node.offsetTop - 271, behavior: 'smooth' })
					}
				})
			}
		},
	},
	computed: {
		...mapState('inventoryGen2/setting', ['categoryList', 'category', 'categoryCount', 'selectedId']),
		...mapGetters('inventoryGen2/setting', ['chemicalList', 'chromatographyList', 'labwareList', 'protectionList', 'etcList']),
		inventoryFieldList() {
			if (!this.category.fields) return []
			return this.category.fields.inventoryFields
		},
		managementFieldList() {
			if (!this.category.fields) return []
			return this.category.fields.managementFields
		},
		customFieldList() {
			if (!this.category.fields) return []
			return this.category.fields.customFields
		},
		categorySubName() {
			if (!this.category.type) return ''
			let largeCategory = this.category.type.substring(0, this.category.type.lastIndexOf('_'))
			if (largeCategory === 'CAT') return `${this.tt(LargeClassToString(this.category.type))}` // category by 1 depth
			return `${this.tt(LargeClassToString(this.category.classification))} / ${this.tt(MiddleClassToString(this.category.type))}`
		},
		modalDeleteTitle() {
			return this.categoryCount === 0 ? `카테고리 삭제` : `카테고리 삭제 불가`
		},
		modalDeleteContent() {
			return this.categoryCount === 0
				? `정말로 카테고리를 삭제하시겠습니까?`
				: this.tt('카테고리를 삭제할 수 없습니다.<br/>해당 카테고리로 등록된 ${1}개의 물품이 존재합니다.', this.categoryCount)
		},
		isCreated() {
			return this.userPermission.category && this.userPermission.category.create
		},
		isUpdate() {
			return this.userPermission.category && this.userPermission.category.update
		},
		isDelete() {
			return this.userPermission.category && this.userPermission.category.delete
		},
	},
	async created() {
		await this.getCategoryList()
		await this.setSelectedId(this.categoryList[0].id)
	},
	methods: {
		...mapActions('inventoryGen2/setting', [
			'getCategoryList',
			'setSelectedId',
			'getCategory',
			'addCategory',
			'editCategory',
			'deleteCategory',
			'initializeCategory',
			'copyCategory',
			'getCategoryCount',
		]),
		async detail(id) {
			await this.setSelectedId(id)
		},
		async addCategoryInfo(categoryInfo) {
			await this.addCategory(categoryInfo)
		},
		async editCategoryInfo(value) {
			await this.editCategory({
				id: this.category.id,
				name: value,
			})
			this.$root.toast(this.tt('편집 완료'), this.tt('카테고리가 편집되었습니다'), 'success')
		},
		async initializeCategoryInfo() {
			await this.initializeCategory()
			this.$root.toast(this.tt('초기화 완료'), this.tt('카테고리가 초기화되었습니다'), 'success')
		},
		async copyCategoryInfo(value) {
			await this.copyCategory(value)
			this.$root.toast(this.tt('복제 완료'), this.tt('카테고리가 복제되었습니다'), 'success')
		},
		async deleteCategoryInfo() {
			await this.deleteCategory()
			this.$root.toast(this.tt('삭제 완료'), this.tt('카테고리가 삭제되었습니다'), 'success')
		},
		async showDeleteCategoryModal() {
			await this.getCategoryCount(this.selectedId)
			this.$refs['modal-delete-category'].show()
		},
		editField(index) {
			this.$refs['modal-add-field'].show(index)
		},
		showAddCategory() {
			if (this.isCreated) this.$refs['modal-add-category'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.Dropdown__menu {
	button:disabled {
		border: 0 !important;
	}
}
.category-wrap {
	display: flex;
	.side-wrap {
		border-right: 1px solid $DIVIDER;
		.menu-wrap {
			padding: 10px;
			height: 600px;
			overflow-y: auto;
			overflow-x: hidden;
			border-bottom: 1px solid $DIVIDER;
		}
		.fixed-menu {
			display: flex;
			font-size: 14px;
			padding: 10px 15px;
			gap: 10px;
			line-height: 28px;
			cursor: pointer;
			img {
				width: 28px;
				height: 28px;
			}
			&.disabled {
				background: $GREY_1;
				color: $PLACEHOLDER;
				cursor: not-allowed;
				img {
					opacity: 0.2;
				}
			}
		}
	}
	.management-wrap {
		width: 100%;
		.title-wrap {
			padding: 15px;
			border-bottom: 1px solid $DIVIDER;
			& > div:first-child {
				display: flex;
				gap: 10px;
				& > img {
					cursor: pointer;
				}
				& > button {
					width: 24px;
					height: 24px;
					margin-left: auto;
				}
			}
			span {
				font-size: 12px;
				color: $SECONDARY_2;
			}
		}
		.info-wrap {
			padding: 15px;
			overflow-y: auto;
			height: 570px;
			& > div {
				margin-bottom: 30px;
				& > div:first-child {
					display: flex;
					justify-content: space-between;
					font-weight: 700;
					margin-bottom: 10px;
				}
			}
		}
		.button-wrap {
			border-top: 1px solid $DIVIDER;
			padding-top: 20px;
			display: flex;
			justify-content: flex-end;
			gap: 10px;
			& > :first-child {
				margin-right: auto;
			}
		}
	}
}
</style>
