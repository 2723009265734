<template>
	<div class="status-table-wrapper">
		<h6>
			{{ tt('예산현황표') }}
		</h6>
		<div class="status-table">
			<div class="left-fixed" style="width: 600px">
				<div style="min-width: 400px">
					<div class="header">
						<div>
							<div class="item bd">
								{{ tt('예산') }}
							</div>
						</div>
						<div>
							<div class="item bd" style="width: 100px">
								{{ tt('코드') }}
							</div>
							<div class="item bd" style="min-width: 200px">
								{{ tt('이름') }}
							</div>
							<div class="item bd" style="width: 100px">
								{{ tt('구분') }}
							</div>
						</div>
					</div>
					<div class="item yellow bd">
						{{ tt('총계') }}
					</div>
					<div class="header item budget">
						<div v-for="budget in budgets" :key="budget.id" :style="{ height: `${(budget.groups.length + 1) * 40}px` }">
							<div class="item bd" style="width: 100px" v-b-tooltip.hover="budget.budgetCode">
								<p>{{ budget.budgetCode }}</p>
							</div>
							<div class="item bd" style="min-width: 200px" v-b-tooltip.hover="budget.budgetName">
								<p>{{ budget.budgetName }}</p>
							</div>
							<div class="item bd" style="width: 100px">
								{{ budget.bugetType == 'YEAR' ? tt('연별(Year)') : tt('월별(Month)') }}
							</div>
						</div>
					</div>
				</div>
				<div style="min-width: 200px">
					<div class="header bd">{{ tt('참여그룹') }}</div>
					<div class="item yellow bd">
						{{ tt('소계') }}
					</div>
					<div v-for="budget in budgets" :key="budget.id">
						<div class="item yellow bd">
							{{ tt('소계') }}
						</div>
						<div class="item bd" v-for="group in budget.groups" :key="group.id" v-b-tooltip.hover="group.name">
							<p>{{ group.name }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="table-content">
				<div :style="{ '--width': `${Object.keys(monthKey).length * 390}px` }">
					<div class="column" v-for="key in Object.keys(monthKey)" :key="key">
						<div class="header">
							<div>
								<div class="item bd">
									{{ tt(monthKey[key]) }}
								</div>
							</div>
							<div>
								<div class="item bd" v-if="hasBudget">
									{{ tt('예산') }}
								</div>
								<div class="item bd" v-if="hasExpenditure">
									{{ tt('지출') }}
								</div>
								<div class="item bd" v-if="hasBalance">
									{{ tt('잔액') }}
								</div>
							</div>
						</div>
						<div class="item yellow right">
							<div>
								<div class="item bd" v-if="hasBudget">{{ totalShareValue(key, 'budget') }}</div>
								<div class="item bd" v-if="hasExpenditure">{{ totalSum(key, 'expenditure') }}</div>
								<div class="item bd" v-if="hasBalance">{{ totalShareValue(key, 'balance') }}</div>
							</div>
						</div>
						<template v-for="budget in budgets">
							<div class="item yellow right">
								<div>
									<div class="item bd" v-if="hasBudget">{{ shareValue(budget.groups, key, 'budget') }}</div>
									<div class="item bd" v-if="hasExpenditure">{{ sum(budget.groups, key, 'expenditure') }}</div>
									<div class="item bd" v-if="hasBalance">{{ shareValue(budget.groups, key, 'balance') }}</div>
								</div>
							</div>
							<div class="item right">
								<div :style="{ height: `${budget.groups.length * 40}px` }">
									<div class="item bd" v-if="hasBudget">{{ shareValue(budget.groups, key, 'budget') }}</div>
									<div class="item" v-if="hasExpenditure">
										<div class="item bd" v-for="group in budget.groups" :key="`${key}_${group.id}`">
											{{ price(group.expenditure[key].expenditure) }}
										</div>
									</div>
									<div class="item bd" v-if="hasBalance">{{ shareValue(budget.groups, key, 'balance') }}</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import GroupSearchVue from '@/components/svg/GroupSearch.vue'
import MixinDate from '@/mixins/date'
export default {
	props: {
		originalBudgets: {
			type: Array,
			defualt: () => [],
		},
		groups: {
			type: Array,
			defualt: () => [],
		},
		year: {
			type: Number,
		},
		isMonth: {
			type: Boolean,
		},
	},
	data() {
		return {
			checkedList: [],
			keyword: '',
		}
	},
	mixins: [MixinDate],
	computed: {
		budgets() {
			const budgetCheckedList = this.checkedList.filter(c => c.type == 'BUDGET').map(b => b.id)
			const groupCheckedList = this.checkedList.filter(c => c.type == 'GROUP').map(g => g.id)
			let budgets = this.$_.cloneDeep(this.originalBudgets)
				.filter(it => budgetCheckedList.length == 0 || budgetCheckedList.includes(it.budgetId))
				.filter(it => groupCheckedList.length == 0 || it.groups.find(g => groupCheckedList.includes(g.id)))
				.filter(it => it.budgetName.includes(this.keyword) || it.groups.find(g => g.name.includes(this.keyword)))
				.map(it => {
					let newBudget = it
					newBudget.groups = it.groups
						.filter(g => groupCheckedList.length == 0 || groupCheckedList.includes(g.id))
						.filter(g => g.name.includes(this.keyword) || it.budgetName.includes(this.keyword))
					return newBudget
				})
				.filter(it => it.groups.length > 0)

			return budgets
		},
		monthKey() {
			let monthKey = { total: this.tt('계(${1}년)', this.year) }
			if (this.isMonth)
				monthKey = {
					...monthKey,
					...this.monthObject,
				}
			const list = this.checkedList.filter(c => c.type == 'MONTH' && Object.keys(monthKey).includes(c.id)).map(c => c.id)
			if (list.length > 0) {
				console.log(list)
				Object.keys(monthKey).forEach(k => {
					if (!list.includes(k)) {
						delete monthKey[k]
					}
				})
			}
			return monthKey
		},
		hasBudget() {
			return !this.checkedList.find(it => it.type == 'CATEGORY') || this.checkedList.find(it => it.type == 'CATEGORY' && it.id == 'budget')
		},
		hasExpenditure() {
			return !this.checkedList.find(it => it.type == 'CATEGORY') || this.checkedList.find(it => it.type == 'CATEGORY' && it.id == 'expenditure')
		},
		hasBalance() {
			return !this.checkedList.find(it => it.type == 'CATEGORY') || this.checkedList.find(it => it.type == 'CATEGORY' && it.id == 'balance')
		},
	},
	methods: {
		sum(groups, key, type) {
			return this.tt(
				'${1}원',
				groups
					.map(g => g.expenditure[key][type] ?? 0)
					.reduce((a, b) => a + b, 0)
					.toLocaleString()
			)
		},
		totalSum(key, type) {
			return this.tt(
				'${1}원',
				this.budgets
					.map(b => b.groups.map(g => g.expenditure[key][type] ?? 0).reduce((a, b) => a + b, 0))
					.reduce((a, b) => a + b, 0)
					.toLocaleString()
			)
		},
		shareValue(groups, key, type) {
			return this.tt('${1}원', groups.map(g => g.expenditure[key][type] ?? 0)[0].toLocaleString())
		},
		totalShareValue(key, type) {
			return this.tt(
				'${1}원',
				this.budgets
					.map(b => b.groups.map(g => g.expenditure[key][type] ?? 0)[0])
					.reduce((a, b) => a + b, 0)
					.toLocaleString()
			)
		},
		price(price) {
			return this.tt('${1}원', (price ?? 0).toLocaleString())
		},
		setCheckedList(checkedList) {
			this.checkedList = checkedList
			this.$forceUpdate()
		},
		setKeyword(keyword) {
			this.keyword = keyword
			this.$forceUpdate()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-budget.scss';
.table-content {
	flex-basis: calc(100% - 600px);
}
</style>
