var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', [_c('button', {
    staticClass: "checkbox",
    "class": {
      active: _vm.isClick
    },
    on: {
      "click": _vm.checkBoxClick
    }
  })]), _c('td', {
    on: {
      "click": _vm.showInfoModal
    }
  }, [_c('div', {
    staticClass: "info"
  }, [_vm.item.image ? _c('img', {
    attrs: {
      "src": _vm.item.image
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/svg/member-photo-default.svg")
    }
  }), _c('div', [_c('h6', {
    domProps: {
      "textContent": _vm._s(_vm.item.name)
    }
  }, [_vm.item.isNew && !_vm.isClicked ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/new.svg")
    }
  }) : _vm._e()]), _c('p', [_vm._v(_vm._s(_vm.item.email))])])])]), _c('td', {
    on: {
      "click": _vm.showInfoModal
    }
  }, [_vm.item.groups.length == 1 ? _c('span', [_vm._v(_vm._s(_vm.item.groups[0].name))]) : _vm.item.groups.length > 1 ? _c('span', {
    domProps: {
      "textContent": _vm._s(_vm.tt('${1} 외 ${2}개 그룹', _vm.item.groups[0].name, _vm.item.groups.length - 1))
    }
  }) : _vm._e()]), _c('td', {
    on: {
      "click": _vm.showInfoModal
    }
  }, _vm._l(_vm.item.permissions, function (permission, index) {
    return _c('span', {
      key: permission.key
    }, [permission.name != null ? _c('span', [_vm._v(" " + _vm._s(permission.name)), index != _vm.item.permissions.length - 1 ? _c('span', [_vm._v(", ")]) : _vm._e()]) : _vm._e()]);
  }), 0), _c('td', [_vm.userPermission.institute.member.update || _vm.userPermission.institute.member["delete"] ? _c('button', {
    staticClass: "more Dropdown__btn active"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/member-more-vertical.svg"),
      "alt": "more"
    }
  }), _c('div', {
    staticClass: "Dropdown__menu active"
  }, [_vm.userPermission.institute.member.update ? _c('button', {
    on: {
      "click": _vm.showUpdateModal
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('정보편집')) + " ")]) : _vm._e(), _vm.userPermission.institute.member.update ? _c('button', {
    on: {
      "click": _vm.showPermissionModal
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('권한관리')) + " ")]) : _vm._e(), _vm.userPermission.institute.member["delete"] ? _c('button', {
    on: {
      "click": _vm.showExportModal
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('내보내기')) + " ")]) : _vm._e()])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }