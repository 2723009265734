import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	list: instId => `/institute/${instId}/draft/list/order`,
	filterList: instId => `/institute/${instId}/draft/filter/order`,
	detail: instId => `/institute/${instId}/draft/order`,
	order: instId => `/institute/${instId}/draft/product/order`,
	listExportExcel: instId => `/institute/${instId}/draft/list/order/export/excel`,
}

const draftAPI = {
	list: (instId, params) =>
		axiosInstance.get(endPoint.list(instId), {
			params,
		}),
	filterList: instId => axiosInstance.get(endPoint.filterList(instId)),
	detail: (instId, id) =>
		axiosInstance.get(endPoint.detail(instId), {
			params: {
				draftPurchaseItemId: id,
			},
		}),
	order: (instId, formData) => axiosInstance.post(endPoint.order(instId), formData),
	listExportExcel: (institueId, payload) =>
		axiosInstance.post(endPoint.listExportExcel(institueId), payload, {
			responseType: 'blob',
		}),
}

export default draftAPI
