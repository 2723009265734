<template>
	<div class="registeringLocation">
		<div class="wrapper">
			<div class="wrap">
				<div class="flex">
					<h5>
						{{ mode == 2 ? tt('보관위치 생성하기') : tt('보관함 생성하기') }}
					</h5>
					<!-- <div class="right-btn-group">
						<button class="Btn__black">
							<img src="@/assets/svg/excel-download.svg">
							{{tt('위치/보관함 일괄 등록')}}
						</button>
					</div> -->
				</div>
				<div class="boxwrap">
					<div class="location-tree">
						<div class="flex">
							<lm-input-search @search="searchTree" :placeholder="tt('위치명으로 검색')"></lm-input-search>
							<button
								class="btn"
								:class="{
									point: (mode == 2 && order == 1) || isLocationHint,
								}"
								:disabled="mode == 3"
							>
								<img
									src="@/assets/svg/button-icon-lisence-copy.svg"
									alt="icon"
									@click.stop="mode == 3 ? '' : showModalAddChildLocation('ADD_LOCATION')"
								/>
								<span @click.stop="mode == 3 ? '' : showModalAddChildLocation('ADD_LOCATION')">{{ tt('생성') }}</span>
								<div class="order-balloon left" v-if="(mode == 2 && order == 1) || isLocationHint">
									<div class="order-top">
										<div class="order">
											<span class="active"></span>
											<span></span>
										</div>
										<button v-if="!isLocationHint" @click="closeOrder">
											<img src="@/assets/svg/popup-close.svg" alt="close" />
										</button>
									</div>
									<h6>{{ tt('1. 위치 등록하기') }}</h6>
									<ul>
										<li>
											{{ tt('클릭해서 건물위치(구조)를 등록해보세요.') }}
										</li>
										<li>
											{{ tt('하위 위치를 등록하려면 위치를 선택하고 버튼을 클릭하세요.') }}
										</li>
									</ul>
								</div>
							</button>
							<button
								class="btn"
								@click.stop="showModalDeleteLocation"
								style="margin-left: 10px"
								:disabled="mode == 3 || (locationTree && locationTree[0].children.length == 0)"
							>
								<img src="@/assets/svg/Trash.svg" alt="icon" />{{ tt('삭제') }}
							</button>
						</div>
						<div class="Location__main-container" ref="chart-main-container" v-on:scroll="handleScroll">
							<location-tree-view
								v-if="treeData.length > 0"
								ref="location-tree-view"
								:treeData="treeData"
								textField="name"
								@changeActive="changeActiveId"
								@changeTree="changeTree"
								mode="active"
								:selectWithChild="false"
								:keyword="keyword"
								@moveStorage="moveStorage"
							></location-tree-view>
							<div class="order-balloon-left" v-if="(mode == 3 && order == 1) || isStorageHint">
								<div class="order-top">
									<div class="order">
										<span class="active"></span>
										<span></span>
									</div>
									<button @click="closeOrder" v-if="!isStorageHint">
										<img src="@/assets/svg/popup-close.svg" alt="close" />
									</button>
								</div>
								<h6>{{ tt('1. 위치 선택하기') }}</h6>
								<p>
									{{ tt('보관함을 추가할 위치를 클릭하여 선택하세요') }}
								</p>
							</div>
						</div>
					</div>
					<div class="location-info" v-if="selectedLocation">
						<div class="AppContainer__location-title">
							<h5 class="AppContainer__location-name">
								{{ selectedLocation.info.name }}
							</h5>
							<img
								class="Location__button-arrow"
								src="@/assets/svg/Button_edit.svg"
								@click.stop="showModalAddChildLocation('EDIT_LOCATION')"
							/>
						</div>
						<!-- 사용그룹 : Start -->
						<div class="Location__group-box">
							<div class="AppContainer__subtitle" style="align-items: start">
								<div>
									<h6>{{ tt('사용 그룹') }}</h6>
								</div>
								<button
									:class="{
										point: (mode == 2 && order == 2) || isLocationHint,
									}"
									class="AppContainer__subtitle-btn btn"
								>
									<img src="@/assets/svg/authority-setting.svg" alt="icon" style="cursor: pointer" />
									<span @click="showModalAddUsingGroup">{{ tt('사용 그룹 관리') }}</span>
									<div class="order-balloon" v-if="(mode == 2 && order == 2) || isLocationHint">
										<div class="order-top">
											<div class="order">
												<span></span>
												<span class="active"></span>
											</div>
											<button @click="closeOrder" v-if="!isLocationHint">
												<img src="@/assets/svg/popup-close.svg" alt="close" />
											</button>
										</div>
										<h6>
											{{ tt('2. 사용 그룹 추가하기') }}
										</h6>
										<p>
											{{ tt('위치를 사용하고 있는 그룹을 추가해주세요.') }}
										</p>
									</div>
								</button>
							</div>
							<p v-if="hasGroups" class="Location__group-location">
								{{ usingGroups }}
							</p>
							<div v-else class="no-data-box">
								<img src="@/assets/svg/no-data_group.svg" alt="no-image" />
								<h6 v-html="tt('${1}(을)를 사용 중인 그룹이 없습니다.', selectedLocation.info.name)"></h6>
								<p style="padding-top: 0" v-html="tt('<b>[사용 그룹 관리]</b>버튼을 클릭하여 그룹을 추가해보세요')"></p>
							</div>
						</div>
						<!-- 사용그룹 : End -->
						<!-- 보관함 : Start  -->
						<div class="StorageBox__group">
							<div class="AppContainer__subtitle">
								<div>
									<h6>{{ tt('보관함') }}</h6>
								</div>
								<button
									class="AppContainer__subtitle-btn btn"
									:class="{
										point: (mode == 3 && order == 2) || isStorageHint,
									}"
									:disabled="mode == 2"
								>
									<img src="@/assets/svg/add-storage.svg" alt="추가icon" />
									<span @click="mode == 2 ? '' : showModalAddStorage('ADD')">{{ tt('보관함 추가') }}</span>
									<div class="order-balloon" v-if="(mode == 3 && order == 2) || isStorageHint">
										<div class="order-top">
											<div class="order">
												<span></span>
												<span class="active"></span>
											</div>
											<button @click="closeOrder" v-if="!isStorageHint">
												<img src="@/assets/svg/popup-close.svg" alt="close" />
											</button>
										</div>
										<h6>{{ tt('2. 보관함 추가하기') }}</h6>
										<p>
											{{ tt('위치에 배치된 보관함을 추가해주세요.') }}
										</p>
									</div>
								</button>
							</div>
							<ul class="StorageBox__list" v-if="hasStorages">
								<storage-item
									v-for="storage in selectedLocation.storages"
									:key="`storage-${storage.id}`"
									:storageInfo="storage"
									@editStorage="showModalEditStorage"
									@deleteStorage="showModalDeleteStorage"
									@refresh="refreshData"
									:isStart="true"
									:mode="mode"
									:userPermission="userPermission"
								></storage-item>
							</ul>
							<div class="no-data-box start" v-if="!hasStorages && mode == 2">
								<img src="@/assets/svg/no-data_lock.svg" />
								<h6 v-html="tt('보관위치 생성하기가 완료된 후 다음 단계에서<br>보관함 추가가 가능합니다.')"></h6>
							</div>
							<div v-else-if="!hasStorages && mode == 3" class="no-data-box">
								<img src="@/assets/svg/no-data_storage.svg" alt="no-image" />
								<h6 v-html="tt('보관함이 없습니다.')"></h6>
								<p v-html="tt('<b>[보관함 추가]</b> 버튼을 클릭하여 보관함을 추가해보세요')"></p>
							</div>
						</div>
						<!-- 보관함 : End  -->
					</div>
					<div class="no-location" v-else>
						<div class="no-data-box">
							<img src="@/assets/svg/no-data_location.svg" />
							<h6>{{ tt('위치를 선택해주세요') }}</h6>
							<p>{{ '좌측 영역에서 위치를 선택해주세요' }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="hint-popup" v-if="isLocationHint || isStorageHint">
			<div class="Popup__background" @click="hideHint"></div>
		</div>

		<button class="Btn__hint" @click="showHint">
			<img src="@/assets/svg/Hint.svg" />
		</button>

		<!-- 모달 : Start -->
		<modal-add-child-location ref="modal-add-child-location" @refresh="refreshData"></modal-add-child-location>
		<modal-delete-location ref="modal-delete" @refresh="refreshData"></modal-delete-location>
		<modal-add-using-group
			ref="modal-add-using-group"
			@refresh="hideModalAddUsingGroup"
			@completeLocation="complete"
			:isStart="true"
		></modal-add-using-group>
		<modal-add-storage-v-2
			v-if="selectedLocation"
			ref="modal-add-storage"
			type="ADD"
			:locationId="selectedLocation.info.id"
			@refresh="refreshData"
			@complete="complete"
		></modal-add-storage-v-2>
		<modal-add-storage-v-2
			v-if="selectedLocation"
			ref="modal-edit-storage"
			type="EDIT"
			:locationId="selectedLocation.info.id"
			@refresh="refreshData"
		></modal-add-storage-v-2>
		<modal-notice-location ref="modal-notice-location" @hideNoticeLocation="hideNoticeLocation"></modal-notice-location>
		<modal-notice-storage ref="modal-notice-storage" @hideNoticeStorage="hideNoticeStorage"></modal-notice-storage>
		<!-- 모달 : End -->
	</div>
</template>

<script>
import LmInputSearch from '@/views/common/components/input/LmInputSearch.vue'
import InstHeader from '@/views/institute/components/InstHeader'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import StorageItem from '@/views/institute/location/components/StorageItem.vue'
import { mapActions, mapState } from 'vuex'
import ModalAddChildLocation from '@/views/institute/location/modals/ModalAddChildLocation.vue'
import ModalDeleteLocation from '@/views/institute/location/modals/ModalDeleteLocation.vue'
import ModalAddUsingGroup from '@/views/institute/organization/modals/ModalAddGroupAuth.vue'
import locationAPI from '@/services/api/institute/location'
import ModalAddStorageV2 from '@/views/institute/location/modals/ModalAddStorageV2.vue'
import ModalNoticeLocation from '@/views/creation/modals/ModalNoticeLocation.vue'
import ModalNoticeStorage from '@/views/creation/modals/ModalNoticeStorage.vue'
import storageAPI from '@/services/api/institute/storage'
import userAPI from '@/services/api/institute/user'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'registeringLocation',
	components: {
		InstHeader,
		'location-tree-view': TreeView,
		LmInputSearch,
		StorageItem,
		ModalAddChildLocation,
		ModalDeleteLocation,
		ModalAddUsingGroup,
		ModalAddStorageV2,
		ModalNoticeLocation,
		ModalNoticeStorage,
	},
	props: {
		mode: {
			type: Number,
			default: 0,
		},
		displayNoticeLocation: {
			type: Boolean,
			default: false,
		},
		displayNoticeStorage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			instituteId: '',
			showBoxShadow: false,
			keyword: '',
			treeData: [],
			order: 1,
			isLocationHint: false,
			isStorageHint: false,
			userPermission: {},
		}
	},
	async created() {
		const response = await userAPI.lookupUserPermission()

		this.userPermission = response.data

		if (this.mode == 2) {
			await this.refreshData()
			this.isLocationHint = false
			if (this.displayNoticeLocation) {
				this.showNoticeLocation()
			}
		} else if (this.mode == 3) {
			this.order = 1
			this.isStorageHint = false
			if (this.displayNoticeStorage) {
				this.showNoticeStorage()
			}
		}
	},
	watch: {
		mode(val) {
			this.refreshData()
			if (val === 3) {
				if (this.displayNoticeStorage) {
					this.showNoticeStorage()
				}
			}
		},
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('location', ['selectedLocation', 'locationTree']),
		usingGroups() {
			return this.selectedLocation.groups.map(item => item.name).join(', ')
		},
		hasGroups() {
			return this.selectedLocation.groups.length > 0
		},
		hasStorages() {
			return this.selectedLocation.storages.length > 0
		},
		groupName() {
			return this.selectedLocation.info.name
		},
	},
	methods: {
		...mapActions('location', ['loadLocationTree', 'loadLocationDetail']),
		async initLocation() {
			this.instituteId = this.$route.params.instituteId
			await this.loadLocationTree(this.instituteId)
			this.treeData = this.$root.deepCopy(this.locationTree)
		},
		async refreshData(locationId) {
			this.instituteId = this.$route.params.instituteId
			await this.loadLocationTree(this.instituteId)
			this.treeData = this.$root.deepCopy(this.locationTree)
			console.log(this.locationTree)

			const locations = locationId ? locationId : this.treeData[0].id
			const detailPayload = {
				institutionId: this.instituteId,
				locationId: locations,
			}
			await this.loadLocationDetail(detailPayload)
			this.$refs['location-tree-view'].setActiveId(locations)
		},
		handleScroll() {
			const scrollTop = this.$refs['chart-main-container'].scrollTop
			this.showBoxShadow = scrollTop !== 0 ? true : false
		},
		async changeActiveId(locationId) {
			const detailPayload = {
				institutionId: this.instituteId,
				locationId: locationId,
			}
			await this.loadLocationDetail(detailPayload)
			this.$refs['location-tree-view'].setActiveId(locationId)
			this.order = 2
		},
		changeTree(node, parentNode) {
			locationAPI.updateLocation(this.instituteId, {
				parentId: parentNode.id,
				id: node.id,
			})
		},
		searchTree(keyword) {
			this.keyword = keyword
		},
		showModalAddChildLocation(type) {
			this.$refs['modal-add-child-location'].show(type)
			this.closeOrder()
		},
		showModalDeleteLocation() {
			this.$refs['modal-delete'].show('location')
		},
		showModalAddUsingGroup() {
			const modalUsingGroup = this.$refs['modal-add-using-group']
			modalUsingGroup.show(
				'LOCATION',
				this.selectedLocation.groups.map(group => group.id)
			)
			this.closeOrder()
		},
		hideModalAddUsingGroup(list) {
			this.$refs['modal-add-using-group'].hide()
			storageAPI
				.addUsingGroup(this.instituteId, {
					locationId: this.selectedLocation.info.id,
					groupsId: list.map(item => item.id),
				})
				.then(() => {
					this.refreshData(this.selectedLocation.info.id)
				})
		},
		showModalDeleteStorage(data) {
			this.$refs['modal-delete'].show('storage', data)
		},
		showModalAddStorage() {
			this.$refs['modal-add-storage'].show()
			this.closeOrder()
		},
		showModalEditStorage(data) {
			this.$refs['modal-edit-storage'].show(data)
		},
		closeOrder() {
			this.order = null
		},
		showHint() {
			if (this.mode == 2) {
				this.isLocationHint = true
			} else if (this.mode == 3) {
				this.isStorageHint = true
			}
		},
		hideHint() {
			this.isLocationHint = false
			this.isStorageHint = false
		},
		showNoticeLocation() {
			this.$refs['modal-notice-location'].show()
		},
		hideNoticeLocation() {
			this.$refs['modal-notice-location'].hide()
			this.$emit('hideNoticeLocation')
		},
		showNoticeStorage() {
			this.$refs['modal-notice-storage'].show()
		},
		hideNoticeStorage() {
			this.$refs['modal-notice-storage'].hide()
			this.$emit('hideNoticeStorage')
		},
		complete() {
			this.closeOrder()
			this.$emit('complete')
		},
		async moveStorage(emitData) {
			const { storageItem, locationId } = emitData
			const response = await storageAPI.updateStorage(this.instituteId, {
				id: storageItem.id,
				locationId: locationId,
			})
			this.showSuccessStorageMoveToast()
			this.refreshData(this.selectedLocation.info.id)
		},
		showSuccessStorageMoveToast() {
			const title = this.tt('보관함 이동 완료')
			const content = this.tt('보관함이 이동하였습니다.')
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
		},
	},
}
</script>

<style lang="scss" scoped>
.hint-popup {
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 770;
}

.registeringLocation {
	.wrap {
		width: 1000px;
		margin: 40px auto;
		.flex {
			h5 {
				font-size: 1.5rem;
				font-weight: 700;
			}
		}
		.boxwrap {
			width: 100%;
			min-height: 600px;
			height: 70vh;
			margin-top: 20px;
			border-radius: 5px;
			border: 1px solid $INPUT;
			display: flex;
			.location-tree {
				padding: 20px;
				width: 430px;
				box-sizing: border-box;
				border-right: 1px solid $INPUT;
				.Location__main-container {
					position: relative;
				}
				#TreeView {
					margin-left: -20px;
					margin-top: 10px;
					height: 538px;
					overflow-y: auto;
					overflow-x: hidden;
					width: calc(100% + 40px);
				}
			}
			.location-info {
				padding: 20px 30px;
				text-align: left;
				height: inherit;
				overflow-y: auto;
				width: calc(100% - 430px);
				h5 {
					font-size: 1.5rem;
					margin-right: 10px;
				}
				.Location__group-box {
					margin-top: 20px;
					.AppContainer__subtitle {
						display: flex;
						align-items: center !important;
						height: auto;
					}
					p {
						font-size: 0.875rem;
					}
				}
				.StorageBox__group {
					margin-top: 50px;
					.StorageBox__list {
						width: calc(100% + 22px);
						margin-left: -11px;
						margin-right: -11px;
						.StorageBox__item {
							max-width: 265px;
							min-width: 194px;
							width: calc(50% - 22px);
						}
					}
				}
			}
			.no-location {
				width: calc(100% - 430px);
				display: flex;
				align-items: center;
				justify-content: center;
				height: inherit;
				.no-data-box {
				}
			}
		}
	}
}
</style>
