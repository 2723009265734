<template>
	<div class="cargo-management-wrap">
		<cargo-list-header
			@showAddCargoModal="showCargoProduct"
			:calendarDatetime="calendarDatetime"
			:dateButtonSubtext="dateButtonSubtext"
			@changeDate="changeCalendar"
			@changeLoadParams="setLoadParams"
		></cargo-list-header>

		<cargo-table
			v-if="previousPage"
			:previousPage="previousPage"
			ref="cargo-table"
			@needInputCargoInfo="item => showCargoNeedInputProduct(item)"
		></cargo-table>
		<div class="pagination-wrapper">
			<pagination ref="pagination" @page="pagination" :total="count"></pagination>
		</div>
		<cargo-floating-menu @cargo="$refs['modal-cargo'].show()" @print="printItem" @delete="showModalDelete"></cargo-floating-menu>
		<modal-form-cargo-product ref="modal-form-cargo-product" @goNext="showCargoManagement"></modal-form-cargo-product>
		<modal-form-cargo-management ref="modal-form-cargo-management" @goPrev="showCargoProductPrev"></modal-form-cargo-management>
		<modal-confirm
			ref="modal-delete"
			title="삭제"
			content="선택한 항목을 삭제하시겠습니까?<br />이 동작은 되돌릴 수 없습니다."
			confirmText="삭제"
			@confirm="deleteCargo"
		></modal-confirm>
		<modal-confirm
			ref="modal-cargo"
			title="물품 입고 처리하기"
			:content="tt('${1}개 물품을 입고 처리 하시겠습니까?', selectItemId.length)"
			confirmText="입고처리"
			@confirm="cargoItem"
		></modal-confirm>
	</div>
</template>

<script>
import PageName from '@/utils/define/PageName'
import CargoListHeader from '../components/cargoList/CargoListHeader.vue'
import CargoTable from '../components/cargoList/table/CargoTable.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import CargoFloatingMenu from '@/views/purchase/cargo/components/cargoList/floatingMenu/CargoFloatingMenu.vue'
import ModalFormCargoProduct from '@/views/purchase/cargo/components/cargoList/modal/ModalFormCargoProduct.vue'
import ModalFormCargoManagement from '@/views/purchase/cargo/components/cargoList/modal/ModalFormCargoManagement.vue'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import MixinExpenditureList from '@/mixins/purchase/expenditureList'
export default {
	name: PageName.Purchase.CargoList,
	mixins: [MixinExpenditureList],
	components: {
		CargoListHeader,
		CargoTable,
		Pagination,
		CargoFloatingMenu,
		ModalFormCargoProduct,
		ModalFormCargoManagement,
	},
	data() {
		return {
			previousPage: null,
			isLoading: false,
			cookieFilterKey: 'expenseCargoFilter',
			cookieDatetimeFilterKey: 'expenseCargoDatetimeFilter',
		}
	},
	computed: {
		...mapGetters('cargo/list', ['count', 'selectItemId', 'offset', 'length']),
		...mapGetters('cargo/item', ['item', 'formShape']),
		...mapGetters('cargo/form', ['cargoInfo']),
	},
	watch: {},
	created() {},
	mounted() {
		if (this.previousPage?.name !== PageName.Purchase.CargoDetail) {
			this.setSelectItem([])
			this.initFilterCookies(true)
			this.$refs['pagination'].init(1)
		} else {
			this.$refs['pagination']?.changePage(this.offset, this.length)
		}
	},
	methods: {
		...mapMutations('cargo/form', ['setCargoFormType', 'setCargoInfo']),
		...mapMutations('cargo/list', ['setSelectItem', 'initFilterCookies']),
		...mapMutations('tag', ['setSelectedTag']),
		...mapActions('cargo/list', ['getCargoList', 'deleteCargoItem', 'setLoadParams', 'processCargoItem']),
		...mapActions('cargo/item', ['getCargoItem']),
		...mapActions('cargo/form', ['registCargo', 'setForm']),
		async pagination(page) {
			this.$refs['cargo-table']?.moveScrollTop()
			await this.setLoadParams(page)
		},
		async cargoItem() {
			await this.processCargoItem(this.selectItemId)
			this.setSelectItem([])
			this.$root.toast('입고 완료', '입고처리가 완료되었습니다.', ToastType.SUCCESS)
			await this.getCargoList()
		},
		printItem() {},
		showCargoProduct() {
			if (this.$root.userPermission.cargo?.create) {
				this.setCargoFormType('ADD')
				this.$refs['modal-form-cargo-product'].show()
				this.$refs['modal-form-cargo-management'].hide()
			} else this.$root.toast('권한', '항목 추가 권한이 없습니다.', 'error')
		},
		showCargoProductPrev() {
			this.$refs['modal-form-cargo-product'].show()
			this.$refs['modal-form-cargo-management'].hide()
		},
		showCargoManagement() {
			this.setSelectedTag([])
			let newItem = this.$_.cloneDeep(this.cargoInfo)

			//담당자 기본값 설정
			newItem.assignee = this.$root.user
			newItem.assigneeId = this.$root.user.id
			//보관함 기본값 설정
			const storageCookiesValue = this.$cookies.get('SELECT_STORAGE_KEY')
			if (storageCookiesValue) {
				newItem.storage = storageCookiesValue
				newItem.storageId = storageCookiesValue.id
			}
			this.setCargoInfo(newItem)
			this.$refs['modal-form-cargo-product'].hide()
			this.$refs['modal-form-cargo-management'].show()
		},
		showModalDelete() {
			this.$refs['modal-delete'].show()
		},
		async deleteCargo() {
			await this.deleteCargoItem()
			this.$root.toast('삭제 완료', '선택한 항목을 삭제하였습니다.', ToastType.SUCCESS)
		},
		async showCargoNeedInputProduct(item) {
			this.setCargoFormType('NEED_INPUT_LIST')
			await this.getCargoItem(item.id)
			await this.setForm(this.formShape)
			this.setSelectedTag(this.formShape.tagList.map(tag => tag.id))
			this.$refs['modal-form-cargo-product'].show()
		},
	},
	// inside vue-router
	// refs : https://forum.vuejs.org/t/get-the-previous-url-on-inside-a-component-with-vue-router/18010/4
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
}
</script>

<style lang="scss" scoped>
.cargo-management-wrap {
	.status-filter-wrap {
		display: flex;
		padding: 12px 30px;
		height: 54px;
		gap: 8px;
		.status-filter-button {
			height: 30px;
			padding: 5px 10px;
			border-radius: 10px;
			background: $GREY_2;
			font-size: 14px;
			color: $SECONDARY_2;
			font-weight: 400;
			&.active {
				background: $SUB-YELLOW_200;
				color: #faa400;
				font-weight: 500;
			}
		}
	}
}
</style>
