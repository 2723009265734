<template>
	<div class="info-field-wrap">
		<p class="field-name">{{ tt(fieldInfo.name) }}</p>
		<div v-if="fieldInfo.isRequire && item[fieldInfo.key] == null" class="field-data-wrap-red">{{ tt('미입력') }}</div>
		<div v-else class="field-data-wrap" :class="{ unfold: isFold }">
			<div class="field-text" :class="{ unfold: isFold }" ref="field-text">{{ fieldText }}</div>
			<img
				v-if="isShowTriangle"
				class="fold-triangle"
				:src="require(`@/views/purchase/cargo/svg/triangle-${isFold ? 'up' : 'down'}.svg`)"
				@click="isFold = !isFold"
			/>
		</div>
	</div>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
export default {
	name: 'ProductInfoField',
	components: {},
	mixins: [MixinItem],
	props: {
		item: {
			type: Object,
			default: null,
		},
		fieldInfo: {
			type: Object,
			default: null,
		},
		hideField: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isFold: false,
		}
	},
	computed: {
		fieldText() {
			if (this.item) {
				const inventory = this.item
				const value = inventory[this.fieldInfo.key]
				switch (this.fieldInfo.key) {
					case 'assay':
					case 'boilingPoint':
					case 'density':
					case 'flashPoint':
					case 'meltingPoint':
					case 'molarMass':
					case 'molecularWeight':
					case 'storageTemperature':
					case 'solubility':
					case 'volume':
						return this.formatVolume(value, inventory[`${this.fieldInfo.key}Unit`])
					case 'currentVolume':
						return this.formatVolume(value, inventory['volumeUnit'])
					case 'phRange':
						return `${inventory['phRangeFrom'] ? inventory['phRangeFrom'] : ''} - ${inventory['phRangeTo'] ? inventory['phRangeTo'] : ''}`
					case 'brand':
						return inventory.brand?.name ?? '-'
					default:
						return value ? value : '-'
				}
			}
			return null
		},
		isShowTriangle() {
			return this.getTextWidth(this.fieldText) >= 280
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		getTextWidth(text) {
			let font = '16px Pretendard'
			let canvas = document.createElement('canvas')
			let context = canvas.getContext('2d')
			context.font = font
			let width = context.measureText(text).width
			let formattedWidth = Math.ceil(width)
			return formattedWidth
		},
	},
}
</script>

<style lang="scss" scoped>
.info-field-wrap {
	padding-right: 10px;
	.field-name {
		text-align: left;
		font-size: 12px;
		font-weight: 400;
		color: $SECONDARY_2;
		margin-bottom: 6px !important;
	}
	.field-data-wrap {
		display: block;
		width: 280px;
		height: 20px;
		position: relative;
		&.unfold {
			height: auto;
		}
		.field-text {
			font-size: 14px;
			font-weight: 400;
			text-align: left;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			white-space: nowrap;
			&.unfold {
				text-overflow: unset;
				white-space: unset;
			}
		}
		.fold-triangle {
			position: absolute;
			top: 0px;
			right: -13px;
			cursor: pointer;
		}
	}
	.field-data-wrap-red {
		width: 280px;
		height: 20px;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
		color: $RED;
	}
}
</style>
