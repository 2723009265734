var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-wrapper"
  }, [_c('div', {
    staticClass: "info-table"
  }, _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: item.label,
      "class": item["class"]
    }, [_c('span', [_vm._v(_vm._s(_vm.tt(item.label)))]), item.files && item.files.length > 0 ? _c('span', _vm._l(item.files, function (file) {
      return _c('a', {
        key: file.id,
        ref: "download-file",
        refInFor: true,
        attrs: {
          "href": file.url
        }
      }, [_c('img', {
        attrs: {
          "src": require("@/assets/svg/docs.svg")
        }
      }), _vm._v(" " + _vm._s(file.name) + " ")]);
    }), 0) : _c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.tt(item.value ? _vm.replaceValue(item.value) : '-'))
      }
    })]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }