var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasMainLegals || _vm.hasingredientLegals ? _c('div', {
    staticClass: "info-wrap"
  }, [_vm.hasMainLegals ? [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(" " + _vm._s(_vm.tt('법령정보')) + " "), _c('img', {
    staticClass: "guide",
    attrs: {
      "src": require("@/views/inventoryGen2/svg/Guide.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['ModalLegalRefer'].show();
      }
    }
  })]), _c('div', {
    staticClass: "casno"
  }, [_vm._v("CAS No: " + _vm._s(_vm.casno))]), _c('div', {
    staticClass: "field-wrap"
  }, _vm._l(_vm.legalKey, function (key) {
    return _c('legal-info-field', {
      key: key,
      attrs: {
        "legal": _vm.legals[key]
      }
    });
  }), 1)] : _vm._e(), _c('div', {
    staticClass: "divider"
  }), _vm.hasingredientLegals ? _c('div', {
    staticClass: "ingredient-legal",
    style: {
      '--ingredientHeight': _vm.ingredientHeight
    }
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(" " + _vm._s(_vm.tt('구성성분 법령정보')) + " ")]), _c('p', {
    staticClass: "info-subheader"
  }, [_vm._v("아래에 표시된 법령정보는 구성 성분에 입력된 용량과 상관없이 Cas no 고유의 법령 정보를 보여주고 있습니다.")]), _vm._l(Object.keys(_vm.ingredientLegals), function (casno) {
    return _vm.hasList(_vm.ingredientLegals[casno]) ? [_c('div', {
      staticClass: "casno"
    }, [_vm._v("CAS No: " + _vm._s(casno))]), _c('div', {
      staticClass: "field-wrap"
    }, _vm._l(_vm.ingredientLegals[casno], function (legal) {
      return _c('legal-info-field', {
        key: legal.legalId,
        attrs: {
          "legal": legal
        }
      });
    }), 1)] : _vm._e();
  }), _vm.ingredientHeight == '440px' ? _c('div', {
    staticClass: "gradient-area"
  }, [_c('button', {
    staticClass: "btn-more",
    on: {
      "click": function click($event) {
        _vm.ingredientHeight = 'unset';
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('더보기')))])]) : _vm._e()], 2) : _vm._e(), _c('ModalLegalRefer', {
    ref: "ModalLegalRefer"
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }