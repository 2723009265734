<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<input type="checkbox" class="checkbox" v-model="allCheck" :class="{ someChecked: !allCheck && !isEmptySelectedItems }" />
		</th>
		<th v-if="isView('mngCode')" :class="orderClass('MNGCODE')" @click.stop="changeOrder('MNGCODE')">
			<span>{{ tt('관리번호') }}</span>
		</th>
		<th v-if="isView('category')">
			<span>{{ tt('카테고리') }}</span>
		</th>
		<th v-if="isView('status')">
			<span>{{ tt('분류') }}</span>
		</th>
		<th v-if="isView('name')" :class="orderClass('NAME')" @click.stop="changeOrder('NAME')">
			<span>{{ tt('물품정보') }}</span>
		</th>
		<th v-if="isView('dumpMemo')">
			<span>{{ tt('삭제(폐기) 사유') }}</span>
		</th>
		<th v-if="isView('assignee')">
			<span>{{ tt('담당자') }}</span>
		</th>
		<th v-if="isView('dumper')">
			<span>{{ tt('삭제(폐기)자') }}</span>
		</th>
		<th v-if="isView('dumpDatetime')" :class="orderClass('DUMP_DATETIME')" @click.stop="changeOrder('DUMP_DATETIME')">
			<span>{{ tt('삭제(폐기)일') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapState('inventoryGen2/disposal', ['list', 'selectedItems', 'checkedViewOption']),
		...mapGetters('inventoryGen2/disposal', ['order', 'orderDirection', 'isEmptySelectedItems']),
		allCheck: {
			get() {
				return this.list.every(it => this.selectedItems.map(sit => sit.id).includes(it.id)) && this.list.length !== 0
			},
			set(val) {
				if (val) this.setSelectedItems(this.list)
				else this.setSelectedItems([])
			},
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapMutations('inventoryGen2/disposal', ['setSelectedItems']),
		...mapActions('inventoryGen2/disposal', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
