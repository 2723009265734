var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: _vm.error
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleShowOption.apply(null, arguments);
      }
    }
  }, [_c('div', {
    ref: "select-box",
    staticClass: "form-select-box"
  }, [_c('div', {
    staticClass: "select-box"
  }, [_c('b-dropdown', {
    attrs: {
      "toggle-class": "text-decoration-none",
      "variant": "link",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('p', {
          "class": {
            placeholder: !_vm.inputValue
          }
        }, [_vm._v(_vm._s(_vm.selectOptionName))])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.optionList, function (option) {
    return _c('b-dropdown-item', {
      key: "option-".concat(option.id),
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.clickOption(option);
        }
      }
    }, [_c('div', {
      staticClass: "member-wrap"
    }, [option.image ? _c('img', {
      staticClass: "member-img",
      attrs: {
        "src": option.image
      }
    }) : _c('img', {
      staticClass: "member-img",
      attrs: {
        "src": require("@/assets/svg/member-photo-default.svg")
      }
    }), _c('div', {
      staticClass: "member-name"
    }, [_vm._v(_vm._s(option.name))])])]);
  }), 1)], 1)])]), _vm.error ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }