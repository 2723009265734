<template>
	<div class="form-wrap check-auto-cargo">
		<div class="left">
			<input type="checkbox" id="check-auto-cargo" v-model="isAutoCargo" />
			<label class="auto-cargo" for="check-auto-cargo">{{ tt('정보입력 완료와 동시에 물품을 입고처리합니다.') }}</label>
		</div>
		<div class="right">
			<!-- <img src="./svg/icon-question.svg" width="24" height="24" /> -->
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
	name: 'FormCheckAutoCargo',
	data() {
		return {
			isAutoCargo: false,
		}
	},
	watch: {
		isAutoCargo(value) {
			this.setAutoCargo(value)
		},
	},
	methods: {
		...mapMutations('cargo/form', ['setAutoCargo']),
	},
}
</script>

<style lang="scss" scoped>
.form-wrap {
	display: flex;
	flex-direction: column;
	padding: 20px;
	background: white;
	border: 1px solid $DIVIDER;
	border-radius: 8px;
	gap: 18px;
	width: 660px;
	&.check-auto-cargo {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.left {
			display: flex;
			align-items: center;
			font-size: 14px;
			flex-direction: row;

			input {
				top: -7px;
				width: 0px;
				height: 0px;
			}
			.auto-cargo {
				width: 550px;
				margin: 0px;
				padding-left: 25px;
				cursor: pointer;
			}
		}
		.right {
		}
	}
}
</style>
