<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<input type="checkbox" id="allCheck" class="checkbox" v-model="allCheck" :class="{ someChecked: !allCheck && !isEmptySelectedItems }" />
		</th>
		<th>
			<span>{{ tt('정보') }}</span>
		</th>
		<th>
			<span>{{ tt('제품번호') }}</span>
		</th>
		<th>
			<span>{{ tt('CAS No') }}</span>
		</th>
		<th>
			<span>{{ tt('사이즈') }}</span>
		</th>
		<th>
			<span>{{ tt('수량') }}</span>
		</th>
		<th>
			<span>{{ tt('보관함') }}</span>
		</th>
		<th>
			<span>{{ tt('정보 수정') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapState('inventoryGen2/form', ['excelList', 'selectedItems']),
		...mapGetters('inventoryGen2/form', ['isEmptySelectedItems']),
		allCheck: {
			get() {
				return this.excelList.every(it => this.selectedItems.map(sit => sit.tempId).includes(it.tempId)) && this.excelList.length !== 0
			},
			set(val) {
				if (val) this.setSelectedItems(this.excelList)
				else this.setSelectedItems([])
			},
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapMutations('inventoryGen2/form', ['setSelectedItems']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
.table-header {
	th {
		& > span,
		div {
			min-width: 90px;
		}
	}
}
</style>
