import axiosInstance from '@/services/axios'

const baseStoreURL = instituteId => `/institute/${instituteId}/store/tunnel/mall`

const endPoint = {
	getUserInfo: instId => `${baseStoreURL(instId)}/user`,
	getInstitute: instId => `${baseStoreURL(instId)}/user/institute`,
	verification: instId => `${baseStoreURL(instId)}/user/verification`,
	access: instId => `${baseStoreURL(instId)}/user/access`,
}

const userAPI = {
	getUserInfo: instId => {
		return axiosInstance.get(endPoint.getUserInfo(instId))
	},
	getInstitute: instId => {
		return axiosInstance.get(endPoint.getInstitute(instId))
	},
	verification: (instId, body) => {
		return axiosInstance.post(endPoint.verification(instId), body)
	},
	access: (instId, body) => {
		return axiosInstance.post(endPoint.access(instId), body)
	},
}

export default userAPI
