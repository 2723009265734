var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_vm.isFreeTier || _vm.isTrial ? _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.purchaseLicense
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('라이선스 구매하기')) + " ")]) : _vm._e(), _c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('닫기')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('h1', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("Merck \uB4F1 \uC778\uAE30 \uBE0C\uB79C\uB4DC 11\uC885, <b style=color:#FF3B31>\uCD5C\uB300 20% \uD560\uC778</b>"))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt("\uB7A9\uB9E4\uB2C8\uC800 Pro \uB77C\uC774\uC120\uC2A4(\uC694\uAE08\uC81C) \uACB0\uC81C \uD6C4, \uC544\uB798 \uBE0C\uB79C\uB4DC \uBB3C\uD488\uC744 \uD560\uC778\uB41C \uAC00\uACA9\uC73C\uB85C \uB9CC\uB098\uBCF4\uC2E4 \uC218 \uC788\uC2B5\uB2C8\uB2E4."))
    }
  }), _c('table', {
    staticClass: "brand-table"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.tt('브랜드')))]), _c('th', [_vm._v(_vm._s(_vm.tt('할인율')))])]), _vm._l(_vm.list, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', [_vm._v(_vm._s(_vm.tt(item.name)))]), _c('td', {
      staticClass: "sale"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/icon-low-price.svg")
      }
    }), _vm._v(_vm._s(item.maxDiscountPercent) + "%")])]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }