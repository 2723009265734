var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "size": "lg",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.tt('필터')) + " "), _vm.hasSearch ? _c('SearchFilter') : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "footer-left"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": function click($event) {
              return _vm.$emit('reset');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('전체 초기화')) + " ")])]), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          "class": "button-primary",
          on: {
            "click": function click($event) {
              return _vm.$emit('confirm');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('적용')) + " ")])])];
      },
      proxy: true
    }])
  }, [_vm._t("modal-content")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }