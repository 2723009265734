var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/no-data_group.svg"),
      "alt": "no-image"
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.tt('그룹원이 없습니다')))]), _c('p', [_c('b', [_vm._v("[" + _vm._s(_vm.tt('그룹원 추가')) + "]")]), _vm._v(" " + _vm._s(_vm.tt('버튼을 클릭하여 추가해보세요')) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }