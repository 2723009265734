var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "form-msg",
    "class": {
      error: !_vm.isValid
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.validMessage)) + " ")])]), _vm.isShowError ? _c('div', {
    staticClass: "error-msg"
  }, [_vm._v(" " + _vm._s(_vm.tt('카테고리 선택은 필수입니다.')) + " ")]) : _vm._e(), _c('category-selector', {
    ref: "category-selector",
    attrs: {
      "fixLargeCategory": _vm.isFixCategory,
      "fixMiddleCategory": _vm.isFixCategory,
      "value": _vm.category
    },
    on: {
      "change": _vm.changeCategory
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }