<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title
			><div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="search-wrap">
				<search-box
					noRecent
					:placeholder="tt('멤버 이름으로 검색해주세요')"
					:isImmediately="true"
					:model="keyword"
					@change="setKeyword"
				></search-box>
			</div>
			<div class="content">
				<div v-if="members && members.length" class="tree-wrap">
					<div v-for="member of members" :key="member.id" class="content-wrap" @click="selectMember(member)">
						<div class="user-info">
							<img :src="member.image ? member.image : require('@/assets/svg/member-photo-default.svg')" />
							<div class="right">
								<b v-html="replaceHighlight(member.name)"></b>
								<div>
									<!-- <span>
										{{ member.permissions.length ? member.permissions[0].name : '-' }}
									</span> -->
									<p v-if="member.groups.length > 0" v-html="replaceHighlight(groupInfo(member))"></p>
									<p class="ellipsis" v-html="replaceHighlight(member.email)"></p>
								</div>
							</div>
						</div>
						<div class="radio">
							<input type="radio" :checked="selectedMembers.find(m => m.id == member.id)" @change="selectMember(member)" />
							<span></span>
						</div>
					</div>
				</div>
				<div v-else class="empty-wrap">
					<div>
						<img src="@/assets/svg/empty-member.svg" />
						<span>{{ tt('검색 결과가 없습니다') }}</span>
					</div>
				</div>
			</div>
			<div class="selected-group">
				<template v-if="!selectedMembers.length">
					{{ tt('멤버를 선택해주세요') }}
				</template>
				<template v-else>
					{{ tt('${1}님이 선택되었습니다', selectedMembers[0].name) }}
				</template>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div v-if="leftBtnName" class="footer-left">
					<button class="button-white" @click="$emit('clickLeftBtn')">
						{{ tt(leftBtnName) }}
					</button>
				</div>
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="confirm">
						{{ tt(confirmText) }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SearchBox from '@/views/components/searchBox'
import { splitWithMerge, createFuzzyMatcher } from '@/utils/modules/FuzzySearch'

export default {
	components: {
		SearchBox,
	},
	props: {
		title: {
			type: String,
			default: '관리자 추가',
		},
		isSelectSingle: {
			type: Boolean,
			default: false,
		},
		isInclude: {
			type: Boolean,
			default: true,
		},
		leftBtnName: {
			type: String,
			default: '',
		},
		confirmText: {
			type: String,
			default: '완료',
		},
	},
	data() {
		return {
			selectedMembers: [],
			keyword: '',
		}
	},
	computed: {
		...mapState('member', ['memberList']),
		members() {
			if (!this.keyword) {
				return this.memberList.members
			} else {
				// 이름 , 이메일 , 그룹으로 검색
				const newArr = this.memberList.members.filter(
					member =>
						createFuzzyMatcher(this.keyword).test(member.name) ||
						createFuzzyMatcher(this.keyword).test(member.email) ||
						member.groups.some(group => createFuzzyMatcher(this.keyword).test(group.name))
				)
				return newArr
			}
		},
	},
	methods: {
		...mapActions('member', ['lookupMemberList']),
		async show(selectedMembers) {
			if (selectedMembers) this.selectedMembers = this.$_.cloneDeep(selectedMembers)
			else this.selectedMembers = []
			await this.lookupMemberList()
			this.keyword = ''
			this.$refs['modal'].show()
		},
		setKeyword(keyword) {
			this.keyword = keyword
		},
		selectMember(member) {
			this.selectedMembers = [member]
		},
		hide() {
			this.$refs['modal'].hide()
		},
		confirm() {
			if (this.selectedMembers.length > 0) {
				this.$emit('confirm', this.selectedMembers)
				this.hide()
			} else {
				this.$root.toast(this.tt(`멤버 선택`), this.tt('멤버를 선택해주세요.'), 'error')
			}
		},
		replaceHighlight(value) {
			const regex = createFuzzyMatcher(this.keyword)
			return this.keyword && value
				? value.replace(regex, (match, ...name) => {
						const letters = name.slice(0, splitWithMerge(this.keyword).length)
						let lastIndex = 0
						let highlited = []
						for (let i = 0; i < letters.length; i++) {
							const idx = match.indexOf(letters[i], lastIndex)
							highlited.push(match.substring(lastIndex, idx))
							highlited.push(`<mark class="highlight">${letters[i]}</mark>`)
							lastIndex = idx + 1
						}
						return highlited.join('')
				  })
				: value
		},
		groupInfo(member) {
			// 검색 일치하는 그룹 먼저 노출되게
			let groupName
			if (this.keyword) {
				member.groups.forEach(g => {
					if (createFuzzyMatcher(this.keyword).test(g.name)) {
						groupName = g.name
					}
				})
			}
			return member.groups?.length > 1
				? this.tt('${1} 외 ${2}그룹', groupName ?? member.groups[0].name, member.groups.length - 1)
				: groupName ?? member.groups[0].name
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-single-select-storage.scss';

.selected-group {
	margin-top: -20px;
	position: sticky;
	width: 100%;
	height: 30px;
	bottom: 0px;
	background-color: #fbfaf9;
	border: 1px solid $DIVIDER;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 101;
}
.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.empty-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		img {
			width: 40px;
			height: 40px;
		}
		span {
			font-size: 0.875rem;
			color: #00000099;
		}
	}
}
.content-wrap {
	position: relative;
	padding: 12px;
	border-bottom: 0;
	transition: 0.3s all linear;
	display: flex;
	height: auto;
	align-items: center;
	justify-content: space-between;
	&:hover {
		background: $GREY_1;
	}
	.user-info {
		display: flex;
		align-items: center;
		gap: 10px;
		img {
			width: 40px;
			height: 40px;
			border: 1px solid $DIVIDER;
			border-radius: 20px;
		}
		.right {
			display: flex;
			flex-direction: column;
			> div {
				display: flex;
				gap: 8px;
				font-size: 0.75rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 340px;
				span {
					color: #00438e;
				}
				p {
					color: $SECONDARY_2;
				}
			}
		}
	}
	.radio {
		padding: 7px 0;
		position: absolute;
		right: 5px;
		width: 30px;
		height: 30px;
		z-index: 101;
		cursor: pointer;
		&:hover {
			background: $GREY_1;
		}
		input {
			height: 20px;
			width: 20px;
			z-index: 1;
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		span {
			position: absolute;
			height: 20px;
			width: 20px;
			background-color: $LAB_WHITE;
			border-radius: 50%;
			border: 1px solid #ccc;
		}
		span:after {
			content: '';
			position: absolute;
			display: none;
		}
		& > input:checked ~ span:after {
			display: block;
		}
		& > span:after {
			top: 2px;
			left: 2px;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background: $LAB_YELLOW;
		}
		& > input:checked ~ span {
			border: 1px solid $LAB_YELLOW;
		}
	}
}
</style>
