var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card possessionStatus",
    "class": !_vm.displayCard ? 'displayOff' : ''
  }, [_c('div', {
    staticClass: "card-title"
  }, [_vm._m(0), _c('h4', {
    staticClass: "Btn__hover-balloon-w",
    on: {
      "click": _vm.goPage
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('물품 보유 현황')) + " "), _c('div', {
    staticClass: "balloon"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('물품목록으로 이동합니다')))])]), _vm._m(1)]), _vm.setting == true ? _c('div', {
    staticClass: "ml-auto switch-setting"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('대시보드에 노출')))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.displayCard,
      expression: "displayCard"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.displayCard) ? _vm._i(_vm.displayCard, null) > -1 : _vm.displayCard
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.displayCard,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.displayCard = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.displayCard = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.displayCard = $$c;
        }
      }, function ($event) {
        return _vm.toggleCard(_vm.id, _vm.displayCard);
      }]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])]) : _vm._e()]), _c('div', {
    staticClass: "card-box"
  }, [_c('div', {
    staticClass: "box-title"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('물품 보유 현황')))])]), _c('div', {
    staticClass: "box-content"
  }, [_c('div', {
    staticClass: "chart-wrap"
  }, [!_vm.showChart ? _c('div', {
    staticClass: "no-data_chart"
  }) : _vm._e(), _c('GChart', {
    ref: "chart",
    attrs: {
      "type": "PieChart",
      "data": _vm.chartData,
      "options": _vm.chartOptions,
      "events": _vm.chartEvents
    }
  }), _c('div', {
    staticClass: "chart-title"
  }, [_c('h2', [_vm._v(_vm._s(_vm.list.total) + _vm._s(_vm.tt('개')))]), _c('p', [_vm._v(_vm._s(_vm.tt('총 보유 물품 수')))])])], 1)])]), _c('div', {
    staticClass: "card-box"
  }, [_c('div', {
    staticClass: "box-title"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('보유 물품 수')))])]), _c('div', {
    staticClass: "box-content"
  }, _vm._l(_vm.list.classification, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "box-inner"
    }, [_c('span', {
      staticClass: "subtitle"
    }, [_vm._v(_vm._s(_vm.tt(_vm.matchSubtitle(index))))]), _c('ul', _vm._l(item, function (subitem) {
      return _c('li', {
        key: subitem.id,
        on: {
          "click": function click($event) {
            return _vm.goPageDetail(subitem.id, subitem.classification);
          }
        }
      }, [_c('div', {
        staticClass: "circle",
        "class": _vm.matchColors(subitem.classification)
      }), _c('p', [_vm._v(_vm._s(subitem.name))]), _c('h5', [_vm._v(_vm._s(subitem.count.toLocaleString()) + _vm._s(_vm.tt('개')))])]);
    }), 0)]);
  }), 0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/dashboard-icon3.svg"),
      "alt": "icon"
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow-diagonal.svg"),
      "alt": "icon"
    }
  })]);
}]

export { render, staticRenderFns }