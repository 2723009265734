var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "select-table-wrapper"
  }, [_c('table', {
    staticClass: "select-table"
  }, [_c('table-header', {
    on: {
      "change": _vm.changeAllChecked
    }
  }), _vm._l(_vm.productInfoList, function (item, index) {
    return _c('table-item', {
      key: index,
      attrs: {
        "item": item,
        "allChecked": _vm.allCheck,
        "index": index,
        "useSale": _vm.useSale
      }
    });
  })], 2), _vm.productInfoList.length === 0 ? _c('div', {
    staticClass: "no-data"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/svg/no-data_item.svg')
    }
  }), _c('p', [_vm._v(_vm._s(_vm.tt('상품이 없습니다')))]), _c('span', [_vm._v(_vm._s(_vm.tt('구매 요청이 필요한 상품을 추가해주세요')))])]) : _vm._e(), _vm.productInfoList.length !== 0 ? _c('product-info-price-table', {
    attrs: {
      "total": _vm.totalPrice,
      "deliveryFee": _vm.totalDeliveryFee,
      "sale": _vm.totalSalePrice,
      "vatPrice": _vm.vatPrice
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }