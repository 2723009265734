var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AuthorityDetail"
  }, [_c('div', {
    staticClass: "AppContainer__location-title"
  }, [_c('h5', {
    staticClass: "AppContainer__location-name"
  }, [_vm._v(" " + _vm._s(_vm.permission.name) + " ")]), _c('div', {
    staticClass: "AppContainer__location-btn-group"
  }, [_vm.userPermission.permission && _vm.userPermission.permission.create && _vm.userPermission.permission.update ? _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.showDuplicateModal
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Duplicate.svg"),
      "alt": "복제icon"
    }
  }), _vm._v(_vm._s(_vm.tt('복제')) + " ")]) : _vm._e(), _vm.userPermission.permission && _vm.userPermission.permission.update ? _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.showEditModal
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/button-icon-edit.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('편집')) + " ")]) : _vm._e(), _vm.userPermission.permission && _vm.userPermission.permission["delete"] ? _c('button', {
    staticClass: "btn",
    attrs: {
      "disabled": _vm.permission.isAlmighty
    },
    on: {
      "click": _vm.showDeleteModal
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Trash.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('삭제')) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "AuthorityDetail__table Table__wrap",
    "class": {
      experience: _vm.isAppModeExperience
    }
  }, [_c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.tt('분류')))]), _c('th', [_vm._v(_vm._s(_vm.tt('기능')))]), _c('th', [_vm._v(_vm._s(_vm.tt('권한여부')))])])]), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "rowspan": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.institute.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.institute.info.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.institute.info.read,
      expression: "authority.institute.info.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.institute.info.read) ? _vm._i(_vm.authority.institute.info.read, null) > -1 : _vm.authority.institute.info.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.institute.info.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.institute.info, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.institute.info, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.institute.info, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.institute.info.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.institute.info.update,
      expression: "authority.institute.info.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.institute.info.update) ? _vm._i(_vm.authority.institute.info.update, null) > -1 : _vm.authority.institute.info.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.institute.info.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.institute.info, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.institute.info, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.institute.info, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.license.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.license.create,
      expression: "authority.license.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.license.create) ? _vm._i(_vm.authority.license.create, null) > -1 : _vm.authority.license.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.license.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.license, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.license, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.license, "create", $$c);
        }
      }, function ($event) {
        return _vm.changeCheckbox(_vm.authority.license.create);
      }]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.license.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.license.read,
      expression: "authority.license.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.license.read) ? _vm._i(_vm.authority.license.read, null) > -1 : _vm.authority.license.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.license.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.license, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.license, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.license, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.license.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.license.update,
      expression: "authority.license.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.license.update) ? _vm._i(_vm.authority.license.update, null) > -1 : _vm.authority.license.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.license.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.license, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.license, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.license, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.license["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.license["delete"],
      expression: "authority.license.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.license["delete"]) ? _vm._i(_vm.authority.license["delete"], null) > -1 : _vm.authority.license["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.license["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.license, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.license, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.license, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.group.title)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.institute.organization.read)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.institute.organization.read,
      expression: "authority.institute.organization.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.institute.organization.read) ? _vm._i(_vm.authority.institute.organization.read, null) > -1 : _vm.authority.institute.organization.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.institute.organization.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.institute.organization, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.institute.organization, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.institute.organization, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.institute.organization.update)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.institute.organization.update,
      expression: "authority.institute.organization.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.institute.organization.update) ? _vm._i(_vm.authority.institute.organization.update, null) > -1 : _vm.authority.institute.organization.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.institute.organization.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.institute.organization, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.institute.organization, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.institute.organization, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.group.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.group.create,
      expression: "authority.group.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.group.create) ? _vm._i(_vm.authority.group.create, null) > -1 : _vm.authority.group.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.group.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.group, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.group, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.group, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.group.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.group.read,
      expression: "authority.group.read"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.group.read) ? _vm._i(_vm.authority.group.read, null) > -1 : _vm.authority.group.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.group.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.group, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.group, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.group, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.group.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.group.update,
      expression: "authority.group.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.group.update) ? _vm._i(_vm.authority.group.update, null) > -1 : _vm.authority.group.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.group.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.group, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.group, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.group, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.group["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.group["delete"],
      expression: "authority.group.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.group["delete"]) ? _vm._i(_vm.authority.group["delete"], null) > -1 : _vm.authority.group["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.group["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.group, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.group, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.group, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.location.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.location.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.location.create,
      expression: "authority.location.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.location.create) ? _vm._i(_vm.authority.location.create, null) > -1 : _vm.authority.location.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.location.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.location, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.location, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.location, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.location.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.location.read,
      expression: "authority.location.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.location.read) ? _vm._i(_vm.authority.location.read, null) > -1 : _vm.authority.location.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.location.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.location, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.location, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.location, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.location.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.location.update,
      expression: "authority.location.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.location.update) ? _vm._i(_vm.authority.location.update, null) > -1 : _vm.authority.location.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.location.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.location, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.location, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.location, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.location["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.location["delete"],
      expression: "authority.location.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.location["delete"]) ? _vm._i(_vm.authority.location["delete"], null) > -1 : _vm.authority.location["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.location["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.location, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.location, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.location, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.storage.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.storage.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.storage.create,
      expression: "authority.storage.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.storage.create) ? _vm._i(_vm.authority.storage.create, null) > -1 : _vm.authority.storage.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.storage.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.storage, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.storage, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.storage, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.storage.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.storage.read,
      expression: "authority.storage.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.storage.read) ? _vm._i(_vm.authority.storage.read, null) > -1 : _vm.authority.storage.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.storage.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.storage, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.storage, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.storage, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.storage.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.storage.update,
      expression: "authority.storage.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.storage.update) ? _vm._i(_vm.authority.storage.update, null) > -1 : _vm.authority.storage.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.storage.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.storage, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.storage, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.storage, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.storage["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.storage["delete"],
      expression: "authority.storage.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.storage["delete"]) ? _vm._i(_vm.authority.storage["delete"], null) > -1 : _vm.authority.storage["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.storage["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.storage, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.storage, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.storage, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.institute.member.title)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.institute.member.create)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.institute.member.create,
      expression: "authority.institute.member.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.institute.member.create) ? _vm._i(_vm.authority.institute.member.create, null) > -1 : _vm.authority.institute.member.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.institute.member.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.institute.member, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.institute.member, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.institute.member, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.institute.member.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.institute.member.read,
      expression: "authority.institute.member.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.institute.member.read) ? _vm._i(_vm.authority.institute.member.read, null) > -1 : _vm.authority.institute.member.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.institute.member.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.institute.member, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.institute.member, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.institute.member, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.institute.member.update)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.institute.member.update,
      expression: "authority.institute.member.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.institute.member.update) ? _vm._i(_vm.authority.institute.member.update, null) > -1 : _vm.authority.institute.member.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.institute.member.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.institute.member, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.institute.member, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.institute.member, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.institute.member["delete"])) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.institute.member["delete"],
      expression: "authority.institute.member.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.institute.member["delete"]) ? _vm._i(_vm.authority.institute.member["delete"], null) > -1 : _vm.authority.institute.member["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.institute.member["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.institute.member, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.institute.member, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.institute.member, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.permission.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.permission.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.permission.create,
      expression: "authority.permission.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.permission.create) ? _vm._i(_vm.authority.permission.create, null) > -1 : _vm.authority.permission.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.permission.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.permission, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.permission, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.permission, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.permission.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.permission.read,
      expression: "authority.permission.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.permission.read) ? _vm._i(_vm.authority.permission.read, null) > -1 : _vm.authority.permission.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.permission.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.permission, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.permission, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.permission, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.permission.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.permission.update,
      expression: "authority.permission.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.permission.update) ? _vm._i(_vm.authority.permission.update, null) > -1 : _vm.authority.permission.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.permission.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.permission, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.permission, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.permission, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.permission["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.permission["delete"],
      expression: "authority.permission.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.permission["delete"]) ? _vm._i(_vm.authority.permission["delete"], null) > -1 : _vm.authority.permission["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.permission["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.permission, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.permission, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.permission, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.category.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.category.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.category.create,
      expression: "authority.category.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.category.create) ? _vm._i(_vm.authority.category.create, null) > -1 : _vm.authority.category.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.category.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.category, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.category, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.category, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.category.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.category.read,
      expression: "authority.category.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.category.read) ? _vm._i(_vm.authority.category.read, null) > -1 : _vm.authority.category.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.category.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.category, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.category, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.category, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.category.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.category.update,
      expression: "authority.category.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.category.update) ? _vm._i(_vm.authority.category.update, null) > -1 : _vm.authority.category.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.category.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.category, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.category, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.category, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.category["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.category["delete"],
      expression: "authority.category.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.category["delete"]) ? _vm._i(_vm.authority.category["delete"], null) > -1 : _vm.authority.category["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.category["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.category, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.category, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.category, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.mngcode.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.mngcode.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.mngcode.create,
      expression: "authority.mngcode.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.mngcode.create) ? _vm._i(_vm.authority.mngcode.create, null) > -1 : _vm.authority.mngcode.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.mngcode.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.mngcode, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.mngcode, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.mngcode, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.mngcode.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.mngcode.read,
      expression: "authority.mngcode.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.mngcode.read) ? _vm._i(_vm.authority.mngcode.read, null) > -1 : _vm.authority.mngcode.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.mngcode.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.mngcode, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.mngcode, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.mngcode, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.mngcode.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.mngcode.update,
      expression: "authority.mngcode.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.mngcode.update) ? _vm._i(_vm.authority.mngcode.update, null) > -1 : _vm.authority.mngcode.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.mngcode.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.mngcode, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.mngcode, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.mngcode, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.mngcode["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.mngcode["delete"],
      expression: "authority.mngcode.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.mngcode["delete"]) ? _vm._i(_vm.authority.mngcode["delete"], null) > -1 : _vm.authority.mngcode["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.mngcode["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.mngcode, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.mngcode, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.mngcode, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.budget.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.budget.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.budget.create,
      expression: "authority.budget.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.budget.create) ? _vm._i(_vm.authority.budget.create, null) > -1 : _vm.authority.budget.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.budget.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.budget, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.budget, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.budget, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.budget.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.budget.read,
      expression: "authority.budget.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.budget.read) ? _vm._i(_vm.authority.budget.read, null) > -1 : _vm.authority.budget.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.budget.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.budget, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.budget, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.budget, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.budget.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.budget.update,
      expression: "authority.budget.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.budget.update) ? _vm._i(_vm.authority.budget.update, null) > -1 : _vm.authority.budget.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.budget.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.budget, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.budget, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.budget, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.budget["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.budget["delete"],
      expression: "authority.budget.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.budget["delete"]) ? _vm._i(_vm.authority.budget["delete"], null) > -1 : _vm.authority.budget["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.budget["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.budget, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.budget, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.budget, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.draft.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.draft.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.draft.create,
      expression: "authority.draft.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.draft.create) ? _vm._i(_vm.authority.draft.create, null) > -1 : _vm.authority.draft.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.draft.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.draft, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.draft, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.draft, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.draft.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.draft.read,
      expression: "authority.draft.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.draft.read) ? _vm._i(_vm.authority.draft.read, null) > -1 : _vm.authority.draft.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.draft.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.draft, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.draft, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.draft, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.draft["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.draft["delete"],
      expression: "authority.draft.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.draft["delete"]) ? _vm._i(_vm.authority.draft["delete"], null) > -1 : _vm.authority.draft["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.draft["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.draft, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.draft, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.draft, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.draft.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.draft.update,
      expression: "authority.draft.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.draft.update) ? _vm._i(_vm.authority.draft.update, null) > -1 : _vm.authority.draft.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.draft.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.draft, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.draft, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.draft, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _vm.authority.order ? _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.draft.order)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.order.create,
      expression: "authority.order.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.order.create) ? _vm._i(_vm.authority.order.create, null) > -1 : _vm.authority.order.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.order.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.order, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.order, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.order, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]) : _vm._e(), _vm.authority.order ? _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.draft.orderRead)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.order.read,
      expression: "authority.order.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.order.read) ? _vm._i(_vm.authority.order.read, null) > -1 : _vm.authority.order.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.order.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.order, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.order, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.order, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]) : _vm._e(), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.cargo.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.cargo.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.cargo.create,
      expression: "authority.cargo.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.cargo.create) ? _vm._i(_vm.authority.cargo.create, null) > -1 : _vm.authority.cargo.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.cargo.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.cargo, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.cargo, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.cargo, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.cargo.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.cargo.read,
      expression: "authority.cargo.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.cargo.read) ? _vm._i(_vm.authority.cargo.read, null) > -1 : _vm.authority.cargo.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.cargo.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.cargo, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.cargo, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.cargo, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.cargo.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.cargo.update,
      expression: "authority.cargo.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.cargo.update) ? _vm._i(_vm.authority.cargo.update, null) > -1 : _vm.authority.cargo.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.cargo.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.cargo, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.cargo, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.cargo, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.cargo["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.budget["delete"],
      expression: "authority.budget.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.budget["delete"]) ? _vm._i(_vm.authority.budget["delete"], null) > -1 : _vm.authority.budget["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.budget["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.budget, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.budget, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.budget, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.item.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item.create)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item.create,
      expression: "authority.item.create"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item.create) ? _vm._i(_vm.authority.item.create, null) > -1 : _vm.authority.item.create
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item.create,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item, "create", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item, "create", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item.read,
      expression: "authority.item.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item.read) ? _vm._i(_vm.authority.item.read, null) > -1 : _vm.authority.item.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item.update,
      expression: "authority.item.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item.update) ? _vm._i(_vm.authority.item.update, null) > -1 : _vm.authority.item.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item["delete"],
      expression: "authority.item.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item["delete"]) ? _vm._i(_vm.authority.item["delete"], null) > -1 : _vm.authority.item["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item.dump.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item.dump.read,
      expression: "authority.item.dump.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item.dump.read) ? _vm._i(_vm.authority.item.dump.read, null) > -1 : _vm.authority.item.dump.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item.dump.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item.dump, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item.dump, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item.dump, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item.dump.update)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item.dump.update,
      expression: "authority.item.dump.update"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item.dump.update) ? _vm._i(_vm.authority.item.dump.update, null) > -1 : _vm.authority.item.dump.update
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item.dump.update,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item.dump, "update", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item.dump, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item.dump, "update", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item.dump["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item.dump["delete"],
      expression: "authority.item.dump.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item.dump["delete"]) ? _vm._i(_vm.authority.item.dump["delete"], null) > -1 : _vm.authority.item.dump["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item.dump["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item.dump, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item.dump, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item.dump, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item.history.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item.history.read,
      expression: "authority.item.history.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item.history.read) ? _vm._i(_vm.authority.item.history.read, null) > -1 : _vm.authority.item.history.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item.history.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item.history, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item.history, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item.history, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.item.history["delete"])))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.item.history["delete"],
      expression: "authority.item.history.delete"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.item.history["delete"]) ? _vm._i(_vm.authority.item.history["delete"], null) > -1 : _vm.authority.item.history["delete"]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.item.history["delete"],
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.item.history, "delete", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.item.history, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.item.history, "delete", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "14"
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.safe.title)) + " ")]), _c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.safe.msds.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.safe.msds.read,
      expression: "authority.safe.msds.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.safe.msds.read) ? _vm._i(_vm.authority.safe.msds.read, null) > -1 : _vm.authority.safe.msds.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.safe.msds.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.safe.msds, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.safe.msds, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.safe.msds, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.safe.msds.management)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.management.msds,
      expression: "management.msds"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.management.msds) ? _vm._i(_vm.management.msds, null) > -1 : _vm.management.msds
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.management.msds,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.management, "msds", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.management, "msds", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.management, "msds", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.safe.dangerstorage.read)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.safe.dangerstorage.read,
      expression: "authority.safe.dangerstorage.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.safe.dangerstorage.read) ? _vm._i(_vm.authority.safe.dangerstorage.read, null) > -1 : _vm.authority.safe.dangerstorage.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.safe.dangerstorage.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.safe.dangerstorage, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.safe.dangerstorage, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.safe.dangerstorage, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.safe.dangerstorage.management)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.management.dangerstorage,
      expression: "management.dangerstorage"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.management.dangerstorage) ? _vm._i(_vm.management.dangerstorage, null) > -1 : _vm.management.dangerstorage
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.management.dangerstorage,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.management, "dangerstorage", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.management, "dangerstorage", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.management, "dangerstorage", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.safe.harmfulchemical.read)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.safe.harmfulchemical.read,
      expression: "authority.safe.harmfulchemical.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.safe.harmfulchemical.read) ? _vm._i(_vm.authority.safe.harmfulchemical.read, null) > -1 : _vm.authority.safe.harmfulchemical.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.safe.harmfulchemical.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.safe.harmfulchemical, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.safe.harmfulchemical, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.safe.harmfulchemical, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.safe.specialmanagement.read)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.safe.specialmanagement.read,
      expression: "authority.safe.specialmanagement.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.safe.specialmanagement.read) ? _vm._i(_vm.authority.safe.specialmanagement.read, null) > -1 : _vm.authority.safe.specialmanagement.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.safe.specialmanagement.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.safe.specialmanagement, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.safe.specialmanagement, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.safe.specialmanagement, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.safe.diagnosis.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.safe.diagnosis.read,
      expression: "authority.safe.diagnosis.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.safe.diagnosis.read) ? _vm._i(_vm.authority.safe.diagnosis.read, null) > -1 : _vm.authority.safe.diagnosis.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.safe.diagnosis.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.safe.diagnosis, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.safe.diagnosis, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.safe.diagnosis, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(" " + _vm._s(_vm.tt(_vm.title_authority.safe.diagnosis.management)) + " ")]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.management.diagnosis,
      expression: "management.diagnosis"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.management.diagnosis) ? _vm._i(_vm.management.diagnosis, null) > -1 : _vm.management.diagnosis
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.management.diagnosis,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.management, "diagnosis", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.management, "diagnosis", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.management, "diagnosis", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt(_vm.title_authority.safe.legalitems.read)))]), _c('td', [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.safe.legalitems.read,
      expression: "authority.safe.legalitems.read"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.almighty || _vm.liteStatus
    },
    domProps: {
      "checked": Array.isArray(_vm.authority.safe.legalitems.read) ? _vm._i(_vm.authority.safe.legalitems.read, null) > -1 : _vm.authority.safe.legalitems.read
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.authority.safe.legalitems.read,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.authority.safe.legalitems, "read", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.authority.safe.legalitems, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.authority.safe.legalitems, "read", $$c);
        }
      }, _vm.changeCheckbox]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])])])])]), _c('modal-duplicate-permission', {
    ref: "modal-duplicate-permission",
    on: {
      "isHide": _vm.hideDuplicateModal
    }
  }), _c('modal-edit-permission', {
    ref: "modal-edit-permission",
    on: {
      "isHide": _vm.hideEditModal
    }
  }), _c('modal-delete-permission', {
    ref: "modal-delete-permission",
    on: {
      "isHide": _vm.hideDeleteModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }