var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.members ? _c('div', {
    staticClass: "member-list-wrapper"
  }, [_c('div', {
    staticClass: "list-header"
  }, [_c('div', {
    staticClass: "list-column member"
  }, [_vm._v(_vm._s(_vm.tt('멤버')))]), _c('div', {
    staticClass: "list-column email"
  }, [_vm._v(_vm._s(_vm.tt('이메일')))]), _c('div', {
    staticClass: "list-column status"
  }, [_vm._v(_vm._s(_vm.tt('구분')))])]), _c('div', {
    staticClass: "list-wrapper"
  }, _vm._l(_vm.members, function (member) {
    return _c('migration-member-table-item', {
      key: member.id,
      attrs: {
        "member": member
      }
    });
  }), 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }