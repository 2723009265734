<template>
	<div class="OrganizationTree__page-container">
		<inst-header :title="tt('조직도 관리')"></inst-header>
		<div class="OrganizationTree__container">
			<div class="OrganizationTree__chart-container">
				<div class="OrganizationTree__chart-header" :class="{ shadow: showBoxShadow }">
					<lm-input-search @search="searchTree" :placeholder="tt('그룹명으로 검색')"></lm-input-search>
					<button
						class="AppContainer__subtitle-btn btn bold"
						@click="openModalAddGroup"
						v-if="userPermission.group && userPermission.group.create"
					>
						<img src="@/assets/svg//button-icon-lisence-copy.svg" alt="icon" />
						{{ tt('생성') }}
					</button>
					<button
						class="AppContainer__subtitle-btn btn ml-10 bold"
						@click="showModalGroupDelete"
						:disabled="selectedGroupInfo && !selectedGroupInfo.parentId"
						v-if="userPermission.group && userPermission.group.delete"
					>
						<img src="@/assets/svg/Trash.svg" alt="icon" />{{ tt('삭제') }}
					</button>
				</div>

				<div class="OrganizationTree__main-container" ref="chart-main-container" v-on:scroll="handleScroll">
					<!-- :treeData="organizationData" -->
					<div v-show="searchTreeData">
						<page-tree-view
							v-if="treeData"
							ref="page-tree-view"
							textField="name"
							subTextField="membersCount"
							:treeData="treeData"
							:subTextEndWord="tt('명')"
							@changeActive="changeActiveId"
							@changeTree="changeTree"
							:keyword="keyword"
							:width="800"
							:userPermission="userPermission"
							@withResult="withResult"
							page="organization"
						></page-tree-view>
					</div>
					<no-data-list
						v-if="!searchTreeData"
						image="search"
						title="검색 결과가 없습니다"
						content="단어의 철자가 정확한지 확인하세요"
					></no-data-list>
				</div>
			</div>
			<!-- 멤버 리스트  -->
			<tree-info-area
				:groupInfo="selectedGroupInfo"
				:userPermission="userPermission"
				ref="tree-info-area"
				@refresh="refreshData"
			></tree-info-area>
		</div>
		<!-- 멤버 리스트  -->

		<!-- 그룹 삭제 확인 모달 -->
		<modal-confirm-delete ref="modal-confirm-delete" :title="tt(deleteTitleText)" :content="tt(deleteContentText)" @refresh="refreshData">
		</modal-confirm-delete>
	</div>
</template>

<script>
import LmInputSearchVue from '@/views/common/components/input/LmInputSearch.vue'
import InstHeader from '@/views/institute/components/InstHeader'
import TreeInfoArea from '@/views/institute/organization/components/OrganizationInfoArea.vue'
import ModalConfirmDelete from '@/views/institute/organization/modals/ModalConfirmDelete.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import instituteGroupAPI from '@/services/api/institute/group'
import NoDataList from '@/views/common/components/NoDataList.vue'

export default {
	name: 'OrganizationPage',
	components: {
		'lm-input-search': LmInputSearchVue,
		InstHeader,
		TreeInfoArea,
		ModalConfirmDelete,
		'page-tree-view': TreeView,
		NoDataList,
	},
	data() {
		return {
			keyword: '',
			activeGroupId: '',
			addGroupButtonText: '그룹 추가',
			showBoxShadow: false,
			buttonModel: {
				buttonText: '',
			},
			organizationData: [
				{
					id: null,
					parentId: null,
					name: '스마트잭',
					phone: '123-4444-4444',
					members: [],
					memberCount: 0,
					children: [],
				},
			],
			organizationList: undefined,
			deleteTitleText: '그룹 삭제',
			deleteContentText: '정말로 그룹을 삭제하시겠습니까?<br>그룹과 관련된 모든 정보가 사라집니다.',
			treeData: null,
			searchTreeData: true,
		}
	},
	mounted() {
		this.buttonModel.buttonText = this.tt('그룹 추가')
		this.refreshData()
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('organization', ['selectedGroup', 'selectedGroupInfo']),
		...mapGetters('organization', ['groupTreeData']),
	},
	methods: {
		...mapActions('organization', ['loadGroupTree', 'loadGroupList']),
		...mapMutations('organization', ['setSelectedGroup', 'setSelectedGroupInfo']),
		handleScroll() {
			const scrollTop = this.$refs['chart-main-container'].scrollTop
			this.showBoxShadow = scrollTop !== 0 ? true : false
		},
		async refreshData(id) {
			const instId = this.$route.params.instituteId
			const response = await instituteGroupAPI.lookupGroupTree(instId)
			this.treeData = response.data
			// console.log('this.treeData', this.treeData);
			if (id != undefined) {
				this.selectGroup(id)
			} else if (this.selectedGroupInfo) {
				this.selectGroup(this.selectedGroupInfo.id)
			} else {
				const rootId = this.treeData[0].id
				this.selectGroup(rootId)
			}
		},
		async selectGroup(groupId) {
			if (groupId === null) return
			const response = await instituteGroupAPI.lookupGroupInfo(groupId)
			this.setSelectedGroupInfo(response.data)
			this.activeGroupId = groupId
			this.$refs['page-tree-view'].setActiveId(groupId)
		},
		openModalAddGroup() {
			this.$refs['tree-info-area'].openModalAddGroup()
		},
		showModalGroupDelete() {
			this.$refs['modal-confirm-delete'].show('DELETE_GROUP')
		},
		changeActiveId(id) {
			this.activeGroupId = id
			this.selectGroup(id)
		},
		async changeTree(node, parentNode) {
			const nodeId = node.id,
				parentId = parentNode.id,
				data = {
					parentId: parentId,
				}
			if (this.userPermission.institute.organization.update) {
				await instituteGroupAPI.changeParentGroup(nodeId, data)
			} else {
				this.$root.toast('권한 없음', '권한이 없습니다.', 'error')
			}
			this.refreshData()
		},
		searchTree(value) {
			this.keyword = value
		},
		withResult(result) {
			this.searchTreeData = result
		},
	},
}
</script>

<style lang="scss" scoped>
.OrganizationTree__page-container {
	display: block;
}

.OrganizationTree__container {
	display: flex;
	width: 100%;
	min-height: 236px;
	overflow-y: hidden;
	height: calc(100% - 55px);
}

.OrganizationTree__chart-container {
	display: flex;
	flex-direction: column;
	width: 450px;
	max-width: 450px;
	height: 100%;
}

.OrganizationTree__chart-header {
	display: flex;
	align-items: center;
	width: 100%;
	min-width: 390px;
	height: 70px;
	padding: 20px 30px;
	&.shadow {
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	}
}

.OrganizationTree__main-container {
	display: block;
	position: relative;
	width: 100%;
	height: calc(100% - 70px);
	overflow-y: auto;
	overflow-x: auto;
	user-select: none;
	padding-left: 20px;
}

.ml-10 {
	margin-left: 10px;
}

.bold {
	font-weight: 700;
	font-size: 14px;
}
</style>
