var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_vm.isShow ? _c('div', {
    staticClass: "Modal ModalLicenseAuthEducation Popup__depth2"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt('교육기관 인증')))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "license-edu-desrciption"
  }, [_c('span', {
    staticClass: "description-title",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('인증자료 제출 필수'))
    }
  }), _c('span', {
    staticClass: "description-content",
    attrs: {
      "i": ""
    },
    domProps: {
      "innerHTML": _vm._s("&nbsp;\u2022&nbsp;\n\t\t\t\t\t\t".concat(_vm.tt('학생일 경우: 학생증 앞면, 입학 허가서')))
    }
  }), _c('span', {
    staticClass: "description-content",
    domProps: {
      "innerHTML": _vm._s("&nbsp;\u2022&nbsp;\n\t\t\t\t\t\t".concat(_vm.tt('교수님일 경우: 교직원증, 재직증명서')))
    }
  }), _c('br'), _c('span', {
    staticClass: "description-content",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('lmcs@smartjackwp.com으로 인증자료를 보내주세요.<br/>영업일 기준 2일내 인증이 완료됩니다.'))
    }
  })]), _c('div', {
    staticClass: "license-phone-wrapper"
  }, [_c('lm-phone-input', {
    attrs: {
      "title": _vm.tt('연락처'),
      "isRequired": "",
      "placeholder": _vm.tt('연락처를 입력해주세요'),
      "value": _vm.phoneNumber,
      "isError": _vm.inputError,
      "errorMessage": _vm.tt('연락처를 입력해주세요')
    },
    on: {
      "value": _vm.getPhoneNumber
    }
  }), _c('div', {
    staticClass: "license-edu-input-description",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('인증자료 검토 후 개별로 연락을 드립니다.</br>연락이 안 될 경우, 라이선스 주문이 취소될 수 있습니다.'))
    }
  })], 1)]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('div', {
    staticClass: "button-container"
  }, [_c('lm-small-button', {
    attrs: {
      "buttonText": _vm.tt('취소'),
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }), _c('lm-small-button', {
    attrs: {
      "buttonText": _vm.tt('완료'),
      "color": "yellow"
    },
    on: {
      "click": _vm.showPurchase
    }
  })], 1)])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }