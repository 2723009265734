var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('안전정보')))]), _c('div', {
    staticClass: "msds-list"
  }, [_vm._l(_vm.msdsKeys, function (key) {
    return [_vm.msds[key] ? _c('div', {
      key: key,
      staticClass: "msds-item-wrap"
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.handleClick(key);
        }
      }
    }, [_c('msds-item', {
      attrs: {
        "msds": _vm.msds[key],
        "msdsKey": key
      }
    })], 1)]) : _vm._e()];
  }), _c('div', {
    staticClass: "ghs-item-wrap"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('manageGHS');
      }
    }
  }, [!!_vm.ghs ? _c('ghs-item', {
    attrs: {
      "ghs": _vm.ghs
    }
  }) : _vm._e()], 1)])], 2), _c('msds-multi-file-viewer', {
    ref: "viewer",
    attrs: {
      "msds": _vm.msds,
      "ghs": _vm.ghs
    },
    on: {
      "delete": _vm.deleteUploadedMsds,
      "uploadFile": _vm.upload,
      "changeFile": _vm.changeFile,
      "createGHS": function createGHS($event) {
        return _vm.$emit('createGHS');
      },
      "previewGHS": function previewGHS($event) {
        return _vm.$emit('manageGHS');
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }