import budgetAPI from '@/services/api/budget'
import { DateTime } from 'luxon'
export default {
	namespaced: true,
	state: {
		yearStatus: [],
		selectedYear: null,
		loadParams: {
			groupsId: [],
			year: null,
		},
		status: null,
	},
	getters: {
		selectedYear: state => state.loadParams.year,
		yearStatus: state => state.yearStatus,
		item: state => state.status,
	},
	mutations: {
		setBudgetYears: (state, data) => {
			let currentYear = DateTime.now().year
			if (data.length > 0) state.yearStatus = data
			else state.yearStatus = [currentYear]
			if (!state.yearStatus.includes(state.loadParams.year)) state.loadParams.year = currentYear
			state.yearStatus.sort((a, b) => b - a)
		},
		setStatus: (state, data) => {
			state.status = data
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setYear: (state, year) => {
			state.loadParams.year = year
		},
	},
	actions: {
		getStatus: async ({ rootGetters, state, commit }) => {
			const response = await budgetAPI.status(rootGetters.instituteId, state.loadParams)
			commit('setStatus', response.data)
		},
		getBudgetYears: async ({ rootGetters, commit }) => {
			const response = await budgetAPI.year(rootGetters.instituteId)
			commit('setBudgetYears', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getStatus')
		},
		exportExcel: async ({ state, rootGetters }) => {
			const response = await budgetAPI.statusExportExcel(rootGetters.instituteId, {
				year: state.loadParams.year,
				groupsId: state.loadParams.groupsId,
			})
			return response
		},
	},
}
