<template>
	<div class="SafetyLegalProduct">
		<h2 class="AppContainer__title">{{ tt('그룹별 법령물품') }}</h2>
		<div class="AppContainer">
			<div class="Filter__box">
				<search-box :placeholder="tt('그룹 이름을 검색해주세요')" :loadList="loadLegalList"></search-box>
				<!-- <div class="right-btn-group">
					<button class="btn">
						<img src="@/assets/svg/icon-excel.svg" alt="icon" />
                        목록 다운로드
					</button>
				</div> -->
			</div>
			<div class="Table__wrap scroll">
				<table>
					<thead>
						<tr>
							<th
								width="250"
								class="headerHover"
								:class="{
									asc: this.ordersDirection[0] == 'ASC',
								}"
							>
								<span
									>{{ tt('그룹 이름') }}
									<button>
										<img @click="changeSort" src="@/assets/svg/arrow-b.svg" alt="" />
									</button>
								</span>
							</th>
							<th width="280">
								<span>{{ tt('화학물질관리법') }}</span>
							</th>
							<th width="280">
								<span>{{ tt('폐기물관리법') }}</span>
							</th>
							<th width="280">
								<span>{{ tt('산업안전보건법') }}</span>
							</th>
							<th width="280">
								<span>{{ tt('위험물관리법') }}</span>
							</th>
							<!-- <th><span>{{tt('고압가스관리법')}}</span></th> -->
						</tr>
					</thead>
					<tbody>
						<legal-item v-for="item in list" :key="item.id" :item="item"></legal-item>
					</tbody>
				</table>
				<no-data-list v-if="list.length == 0" :image="noData.image" :title="noData.title" :content="noData.content"></no-data-list>
			</div>
		</div>

		<pagination :total="count" @page="pagination"></pagination>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import SearchBox from '@/views/common/components/SearchBox.vue'
import LegalItem from '@/views/safety/legal/components/LegalItem.vue'
import legalAPI from '@/services/api/safety/legal'
import Pagination from '@/views/common/components/Pagination.vue'
import NoDataList from '@/views/common/components/NoDataList.vue'

export default {
	name: 'SafetyLegalProduct',
	components: {
		SearchBox,
		LegalItem,
		Pagination,
		NoDataList,
	},
	data() {
		return {
			displayFilter: false,
			keyword: '',
			instId: '',
			offset: 0,
			length: 50,
			orders: [],
			ordersDirection: [],
			list: [],
			count: 0,
			noData: {
				image: '',
				title: '',
				content: '',
			},
		}
	},
	created() {
		this.instId = this.$route.params.instituteId
		this.loadLegalList()
	},
	computed: {
		...mapState('institutionInformation', ['listKeyword']),
	},
	watch: {
		list(val) {
			if (val.length == 0) {
				this.noData = {
					image: 'group',
					title: '법령물품을 보유하고 있는 그룹이 없습니다',
					content: '그룹이 담당하는 보관함에 법령물품이 있을 경우 자동으로 목록에 추가됩니다 ',
				}
				if (this.listKeyword.length > 0) {
					this.noData = {
						image: 'search',
						title: '조회된 결과가 없습니다.',
						content: '다시 한번 확인해 주세요.',
					}
				}
			}
		},
	},
	methods: {
		async loadLegalList() {
			const response = await legalAPI.lookupLegalList(
				this.instId,
				this.offset,
				this.length,
				this.orders,
				this.ordersDirection,
				this.listKeyword
			)
			if (response.status == 200) {
				this.list = response.data.list
				this.count = response.data.count
			}
		},
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
			this.loadLegalList()
		},
		changeSort() {
			this.orders = ['GROUP_NAME']
			if (this.ordersDirection.length == 0 || this.ordersDirection[0] == 'ASC') {
				this.ordersDirection = ['DESC']
			} else if (this.ordersDirection[0] == 'DESC') {
				this.ordersDirection = ['ASC']
			}
			this.loadLegalList()
		},
	},
}
</script>

<style lang="scss" scoped>

@import '@/styles/scss/inventory/_list.scss';
@import '@/styles/scss/safety/_safety.scss';
</style>
