<template>
	<div class="manage-table-wrap">
		<history-info-search @changeCheck="changeCheck" @changeKeyword="changeKeyword" @changeDatetime="changeDatetime"></history-info-search>
		<div class="history-table-wrap">
			<div class="title">
				<span>{{ tt('물품관리 기록') }}</span>
				<button class="button-gray" @click="$refs['modal-remove'].show()" :disabled="isEmptySelectedItems">
					<img class="image-icon" src="@/assets/svg/Trash.svg" />
					<p>{{ tt('삭제') }}</p>
				</button>
			</div>
			<div class="table-wrap">
				<table class="lm-table">
					<history-table-header> </history-table-header>
					<history-table-item v-for="hItem in list" :item="hItem" :key="hItem.id"></history-table-item>
					<div class="empty-table" v-if="count === 0">
						<img class="empty-img" :src="emptyImage" />
						<p class="bold">{{ tt(emptyText) }}</p>
						<p class="sub" v-html="emptySubText"></p>
					</div>
				</table>
			</div>
			<div class="pagination-wrap">
				<pagination :total="count" @page="pagination"></pagination>
			</div>
		</div>
		<modal-confirm
			ref="modal-remove"
			title="기록 삭제하기"
			confirmText="삭제하기"
			variant="warning"
			:content="`정말로 선택한 기록을 삭제하시겠습니까?</br>이 동작은 되돌릴 수 없습니다.`"
			@confirm="remove"
		/>
	</div>
</template>
<script>
import HistoryInfoSearch from '@/views/inventoryGen2/detail/historyInfo/HistoryInfoSearch.vue'
import HistoryTableHeader from '@/views/inventoryGen2/detail/historyInfo/HistoryTableHeader.vue'
import HistoryTableItem from '@/views/inventoryGen2/detail/historyInfo/HistoryTableItem.vue'
import Pagination from '@/views/common/components/Pagination.vue'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
	name: 'ManageInfo',
	components: { HistoryInfoSearch, HistoryTableHeader, HistoryTableItem, Pagination },
	props: {
		item: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapState('inventoryGen2/historyInfo', ['list', 'count', 'selectedItems', 'loadParams']),
		...mapGetters('inventoryGen2/historyInfo', ['isEmptySelectedItems']),
		initLoadParams() {
			return {
				itemId: this.item.id,
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
				keyword: '',
				startDatetime: '',
				endDatetime: '',
				types: [],
			}
		},
		selectedItemsId() {
			return this.selectedItems.map(item => item.id)
		},
		emptyImage() {
			return this.loadParams.keyword ? require('@/assets/svg/no-data_search.svg') : require('@/assets/svg/empty/inventory-empty-data.svg')
		},
		emptyText() {
			return this.tt(this.loadParams.keyword ? '검색 결과가 없습니다' : '물품 관리기록이 없습니다')
		},
		emptySubText() {
			return this.loadParams.keyword ? this.tt('입력자, 물품 이름으로 검색할 수 있습니다.') : ''
		},
	},
	watch: {},
	created() {
		this.setLoadParams(this.initLoadParams)
	},
	mounted() {
		this.getList()
	},
	methods: {
		...mapMutations('inventoryGen2/historyInfo', ['setLoadParams', 'setSelectedItems']),
		...mapActions('inventoryGen2/historyInfo', ['getList', 'removeItem']),
		async changeCheck(checkedData) {
			await this.setLoadParams({ types: checkedData.map(item => item.id) })
			await this.getList()
		},
		async changeKeyword(keyword) {
			await this.setLoadParams({ keyword: keyword })
			await this.getList()
		},
		async changeDatetime(datetime) {
			const [startDatetime, endDatetime] = datetime
			await this.setLoadParams({
				startDatetime: startDatetime.toISOString(),
				endDatetime: endDatetime.toISOString(),
			})
			await this.getList()
		},
		async remove() {
			await this.removeItem({ ids: [this.selectedItemsId] })
			this.$root.toast('기록 삭제 완료', `물품 기록이 삭제되었습니다.`, 'success')
			this.setSelectedItems([])
		},
		async pagination(page) {
			await this.setLoadParams(page)
			await this.getList()
		},
	},
}
</script>

<style lang="scss" scoped>
.history-table-wrap {
	display: block;
	.title {
		display: flex;
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 15px;
		justify-content: space-between;
		button {
			max-height: 30px;
			background: $GREY_4;
			border-radius: 4px;
			font-weight: 700;
			padding: 5px 10px;
			img + p {
				padding-left: 3px;
			}
			&:hover {
				background: $HOVER_BUTTON_COMMON;
			}
		}
	}
	.table-wrap {
		border: 1px solid $DIVIDER;
		border-radius: 4px;
		max-height: 460px;
		overflow-y: auto;
	}
	.pagination-wrap {
		position: relative;
		padding: 20px 0;
		z-index: 100;
		.Table__pagination {
			border-top: 0 !important;
		}
	}
}
.lm-table {
	position: relative;
	height: 100%;
	text-align: left;
	display: block;
	overflow: overlay;
	white-space: nowrap;
	border-spacing: 0;
	border-collapse: separate;
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: $SECONDARY_2;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		text-align: center;
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
