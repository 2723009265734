<template>
	<div class="SafetyMSDS">
		<div class="flex" style="height: 55px">
			<h2 class="AppContainer__title" style="padding-top: 0">MSDS</h2>
			<div class="situation">
				<p @click="statusFilter()">
					{{ tt('전체 ${1}종', msdsUpload.allCount) }}
				</p>
				<p class="upload" @click="statusFilter('SAFE')">
					<img src="@/assets/images/uploaded.png" />
					{{ tt('등록됨') }}
					{{ msdsUpload.allCount - msdsUpload.nonUploadCount }}{{ tt('종') }}
				</p>
				<p class="not-uploaded" @click="statusFilter('UNSAFE')">
					<img src="@/assets/svg/not-uploaded.svg" />
					{{ tt('업로드 필요') }} {{ msdsUpload.nonUploadCount }}{{ tt('종') }}
				</p>
				<div class="balloon">
					<GChart v-if="list.allCount > 0" type="PieChart" :data="chartData" :options="chartOptions" ref="chart" />
					<div v-else class="no-data_chart"></div>
					<ul>
						<li class="upload">
							<img src="@/assets/images/uploaded.png" />
							{{ tt('MSDS가 등록된 화학물질') }}
							<b class="ml-auto">{{ allCount }}</b>
						</li>
						<li class="not-uploaded">
							<img src="@/assets/svg/not-uploaded.svg" />
							{{ tt('MSDS 업로드가 필요한 화학물질') }}
							<b class="ml-auto">{{ nonUploadCount }}</b>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="Filter__box" style="padding-top: 6px">
			<search-box :placeholder="tt('검색')" :loadList="loadMsdsList"></search-box>
			<image-button
				:text="filterText"
				:isActive="filteredCount > 0"
				:activeImage="require('@/assets/svg/list/active/filter-active.svg')"
				:image="require('@/assets/svg/filter.svg')"
				@click="showFilter"
			></image-button>
			<div class="right-btn-group">
				<button class="btn" @click="downloadExcel"><img src="@/assets/svg/icon-excel.svg" alt="icon" />{{ tt('목록 다운로드') }}</button>
			</div>
		</div>
		<div class="Table__wrap scroll horizational-scroll">
			<table
				:class="{
					noPermission: userPermission.safe && !userPermission.safe.msds.create,
				}"
			>
				<thead>
					<tr>
						<th v-for="item in checkHeaderList" :key="item.name" :width="item.width" :class="[{ asc: item.direction == 'ASC' }]">
							<span>
								{{ item.name }}
								<button @click="changeSort(item)" v-if="item.sort">
									<img src="@/assets/svg/arrow-b.svg" alt="icon" />
								</button>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<msds-item
						:headers="headerList"
						v-for="(item, idx) in list"
						:key="idx"
						:item="item"
						:instId="instId"
						@loadList="loadMsdsList"
						:userPermission="userPermission"
						:class="{
							isNew: item.msds.institute && item.msds.institute.isNew && unClicked(item),
						}"
					></msds-item>
				</tbody>
			</table>
			<lm-modal-loading v-if="list.length == 0 && isLoading" :play="isLoading" height="calc(100% - 40px)"></lm-modal-loading>
			<no-data-list
				v-else-if="list.length == 0 && !isLoading"
				:image="noData.image"
				:title="noData.title"
				:content="noData.content"
			></no-data-list>
		</div>

		<div class="Menu__floating" v-if="checkList.length > 0">
			<h4>
				<span class="count">{{ checkList.length }}{{ tt('개') }}</span
				>{{ tt('선택') }}
			</h4>
			<div class="btn-group">
				<button>
					<img src="@/assets/svg/icon-download.svg" alt="버튼icon" />
					<span>{{ tt('다운로드') }}</span>
				</button>
			</div>
		</div>

		<pagination :total="count" @page="pagination"></pagination>

		<!--modal-->
		<modal-filter
			ref="modal-filter"
			:filterStatusList="filterStatusList"
			:filterBrandList="filterBrandList"
			:filterMsdsKindList="filterMsdsKindList"
			@close="closeFilter"
			@filter="hideFilter"
		></modal-filter>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SearchBox from '@/views/common/components/SearchBox.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import MsdsItem from '@/views/safety/msds/components/MsdsItem.vue'
import msdsAPI from '@/services/api/safety/msds'
import LmModalLoading from '@/views/common/components/LmModalLoading.vue'
import { GChart } from 'vue-google-charts'

//modals
import ModalFilter from '@/views/common/components/modals/ModalFilter.vue'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
import ModalMSDSFileChange from '@/views/safety/msds/modals/ModalMSDSFileChange.vue'
import NoDataList from '@/views/common/components/NoDataList.vue'

export default {
	name: 'SafetyMSDS',
	props: {},
	components: {
		SearchBox,
		Pagination,
		MsdsItem,
		ModalFilter,
		'modal-msds-file-change': ModalMSDSFileChange,
		ModalCommon,
		LmModalLoading,
		GChart,
		NoDataList,
	},
	async created() {
		this.isLoading = true
		this.instId = this.$route.params.instituteId
		this.checkHeaderList = this.headerList
		await this.loadFilter()
		await this.loadMsdsList()
		await this.getGhsStatement()
		this.isLoading = false
	},
	data() {
		return {
			instId: null,
			isActive: false,
			isCheck: false,
			order: ['LAST_UPDATE_DATETIME'],
			orderDirection: ['DESC'],
			checkList: [],
			count: 0,
			list: [],
			offset: 0,
			length: 50,
			keyword: undefined,
			productCode: null,
			inventoryId: null,
			brandId: null,
			brandsId: [],
			status: [],
			msdsKind: [],
			headerList: [
				{
					name: this.tt('상태'),
					width: 140,
					value: true,
					sort: false,
				},
				{
					name: this.tt('물품정보'),
					width: 265,
					value: true,
					sort: false,
				},
				{
					name: this.tt('제품번호'),
					width: 265,
					value: true,
					sort: false,
				},
				{
					name: 'CAS No',
					width: 120,
					value: true,
					sort: false,
				},
				{
					name: 'MSDS',
					width: 482,
					value: true,
					sort: false,
				},
				{
					name: this.tt('업로드된 MSDS 관리'),
					width: 200,
					value: true,
					sort: false,
				},
				{
					name: this.tt('업데이트일'),
					width: 210,
					value: true,
					sort: true,
					order: 'LAST_UPDATE_DATETIME',
					direction: 'DESC',
				},
			],
			checkHeaderList: [],
			filter: false,
			filterBrandList: [],
			filterStatusList: [],
			filterMsdsKindList: [],
			isLoading: true,
			chartOptions: {
				legend: 'none',
				colors: ['#0DC577', '#eee'],
				width: 80,
				height: 80,
				chartArea: {
					width: '100%',
					height: '100%',
				},
				backgroundColor: 'transparent',
				pieSliceBorderColor: 'white',
				pieSliceText: 'none',
				pieHole: 0.6,
				tooltip: {
					text: 'percentage',
					showColorCode: true,
					trigger: 'none',
				},
				enableInteractivity: false,
			},
			allCount: 0,
			nonUploadCount: 0,
			noData: {
				image: '',
				title: '',
				content: '',
			},
			filteredCount: 0,
		}
	},
	computed: {
		...mapState('user', ['institute', 'user']),
		...mapState('msds', ['msdsList']),
		...mapState('inventory', ['brand']),
		...mapState('institutionInformation', ['listKeyword']),
		...mapState('dashboard', ['msdsUpload']),
		chartData() {
			if (this.msdsUpload) {
				return [
					['title', 'count'],
					['업로드가 완료된 MSDS', this.msdsUpload.allCount - this.msdsUpload.nonUploadCount],
					['업로드가 필요한 MSDS', this.msdsUpload.nonUploadCount],
				]
			}
		},
		filterText() {
			if (this.filteredCount > 0) return this.tt('필터(${1})', this.filteredCount)
			return this.tt('필터')
		},
	},
	watch: {
		list(val) {
			if (val.length == 0) {
				this.noData = {
					image: 'list',
					title: 'MSDS를 관리해야 할 물질이 없습니다',
					content: 'MSDS 관리가 필요한 화학물질을 등록하면 자동으로 목록에 추가됩니다',
				}
				if (this.filter || this.listKeyword.length > 0) {
					this.noData = {
						image: 'search',
						title: '조회된 결과가 없습니다.',
						content: '다시 한번 확인해 주세요.',
					}
				}
			}
		},
	},
	methods: {
		...mapActions('msds', ['lookupMsdsList', 'exportMsdsExcel']),
		...mapActions('inventory', ['searchBrand']),
		...mapActions('dashboard', ['lookupMsdsUpload']),
		...mapActions('modal/ghs', ['getGhsStatement']),
		setClickedList() {
			let clickedList = this.$cookies.get(`${this.user.id}_clickedItem`)
			clickedList = clickedList ? JSON.parse(clickedList) : []
			clickedList = clickedList.filter(x => {
				const notOverTime = (new Date() - new Date(x.datetime)) / (1000 * 60 * 60 * 24) <= 2
				return notOverTime
			})

			this.$cookies.set(`${this.user.id}_list`)
		},
		unClicked(item) {
			let clickedList = this.$cookies.get(`${this.user.id}_clickedItem`)
			clickedList = clickedList ? JSON.parse(clickedList) : []

			if (clickedList) {
				const clickedListIds = clickedList.map(item => item.id)
				return clickedListIds.indexOf(item.id) < 0
			} else {
				return true
			}
		},
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
			this.loadMsdsList()
		},
		async loadMsdsList() {
			await this.lookupMsdsUpload(this.$route.params.instituteId)
			this.allCount = this.msdsUpload.allCount
				? Math.round(((this.msdsUpload.allCount - this.msdsUpload.nonUploadCount) / this.msdsUpload.allCount) * 100) + '%'
				: '0%'
			this.nonUploadCount = this.msdsUpload.allCount
				? Math.round((this.msdsUpload.nonUploadCount / this.msdsUpload.allCount) * 100) + '%'
				: '0%'

			if (this.$route.params.status != undefined) {
				this.status = this.$route.params.status
				this.filterStatusList.forEach((x, index) => {
					if (this.status.indexOf(x.value) >= 0) {
						this.filterStatusList[index].checked = true
						this.filter = true
					}
				})
				this.$route.params.status = undefined
			}

			const response = await msdsAPI.lookupMsdsList(
				this.instId,
				this.offset,
				this.length,
				this.order,
				this.orderDirection,
				this.listKeyword,
				this.status,
				this.brandsId,
				this.msdsKind
			)

			this.count = response.data.count
			this.list = response.data.list
			await this.setClickedList()
		},
		addCheckIdList(id) {
			this.checkList.push(id)
			if (this.checkList.length == this.list.length) {
				this.isCheck = true
			}
		},
		deleteCheckIdList(id) {
			for (let i = 0; i < this.checkList.length; i++) {
				if (this.checkList[i] == id) {
					this.checkList.splice(i, 1)
				}
			}
			this.isCheck = false
		},
		allChecked() {
			if (this.isActive) {
				this.isActive = false
				this.isCheck = false
			} else {
				this.isActive = true
				this.isCheck = true
			}
		},
		changeSort(item) {
			this.order = []
			this.orderDirection = []
			this.order = [item.order]
			if (item.direction == 'DESC') item.direction = 'ASC'
			else item.direction = 'DESC'
			this.orderDirection = [item.direction]
			this.loadMsdsList()
		},
		loadFilter() {
			this.filterStatusList = [
				{ text: this.tt('등록됨'), value: 'SAFE', checked: false },
				{
					text: this.tt('업로드 필요'),
					value: 'UNSAFE',
					checked: false,
				},
			]

			this.filterMsdsKindList = [
				{ text: this.tt('제조사 MSDS'), value: 'BRAND', checked: false },
				{
					text: this.tt('업로드된 MSDS'),
					value: 'UPLOADED',
					checked: false,
				},
			]

			this.filterBrandList = []
			this.searchBrand({
				instId: this.$route.params.instituteId,
				keyword: '',
				isUse: true,
			}).then(() => {
				this.brand.forEach(x => {
					this.filterBrandList.push({
						id: x.id,
						name: x.name,
						checked: false,
					})
				})
			})
		},
		showFilter() {
			this.filter = false
			this.$refs['modal-filter'].show()
		},
		closeFilter() {
			const filterArr = [this.brandsId, this.status, this.msdsKind]
			filterArr.forEach(x => {
				if (x.length > 0) {
					this.filter = true
				}
			})

			this.$refs['modal-filter'].hide()
		},
		hideFilter(filterList) {
			this.$refs['modal-filter'].hide()
			this.brandsId = []
			this.status = []
			this.msdsKind = []
			this.filter = false

			this.filterBrandList = filterList.brandList
			this.filterStatusList = filterList.statusList
			this.filterMsdsKindList = filterList.msdsKindList

			filterList.brandList.filter(x => {
				if (x.checked == true && x.id != 'ALL') {
					this.brandsId.push(x.id)
				}
			})
			filterList.statusList.filter(x => {
				if (x.checked == true && x.value != 'ALL') {
					this.status.push(x.value)
				}
			})
			filterList.msdsKindList.filter(x => {
				if (x.checked == true && x.value != 'ALL') {
					console.log(x)
					this.msdsKind.push(x.value)
				}
			})
			this.filteredCount = [...this.brandsId, ...this.status, ...this.msdsKind].length

			this.loadMsdsList()
			const toastTitle = this.tt('필터')
			const toastContent = this.tt('필터가 적용되었습니다.')
			this.$root.toast(toastTitle, toastContent, 'success')
		},
		statusFilter(status) {
			this.filter = false
			if (status) {
				this.status = [status]
				this.filterStatusList.forEach(x => {
					if (x.value == this.status) {
						x.checked = true
					}
				})
				this.filter = true
				const toastTitle = this.tt('필터')
				const toastContent = this.tt('필터가 적용되었습니다.')
				this.$root.toast(toastTitle, toastContent, 'success')
			} else {
				this.status = []
			}
			this.loadMsdsList()
		},
		loadSummary() {
			this.$emit('loadSummary')
		},

		async downloadExcel() {
			const response = await msdsAPI.exportMsdsExcel(this.instId, this.order, this.orderDirection, this.listKeyword, this.status, this.brandsId)

			window.fileDownload(response, this.formatDate(new Date()) + '_' + this.tt('MSDS 목록') + '.xlsx')
		},
		formatDate(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear()
			if (month.length < 2) {
				month = '0' + month
			}
			if (day.length < 2) {
				day = '0' + day
			}
			return [year, month, day].join('-')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/safety/_safety.scss';

.noPermission {
	thead {
		tr {
			th {
				&:nth-child(6) {
					display: none;
				}
			}
		}
	}
}

.no-data_chart {
	width: 80px;
	height: 80px;
	border-radius: 80px;
	border: 15px solid $GREEN;
	min-width: 80px;
}
</style>
