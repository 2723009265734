import inventoryAPI from '@/services/api/inventory/index.js'

const state = {
	qrcode: null,
	validRes: null,
	qrcodeList: null,
	selectedList: [],
	maxSize: null,
}
const getters = {
	qrcode: state => state.qrcode,
	validRes: state => state.validRes,
	qrcodeList: state => state.qrcodeList,
	onlyCodeList: state => state.qrcodeList?.map(item => item.code),
	validQrCodeItemList: state => state.qrcodeList?.filter(item => item.status === 'OK'),
	validSelectedQrCodeList: state => state.selectedList?.filter(item => item.status === 'OK').map(item => item.code),
	selectedList: state => state.selectedList,
	selectedLength: state => (state.selectedList ? state.selectedList.length : 0),
	isSelectAll: (state, getters) =>
		(state.maxSize === getters.selectedLength || getters.validQrCodeItemList?.length === getters.selectedLength) &&
		state.selectedList?.length !== 0,
	isSelectSome: (state, getters) => state.maxSize !== state.selectedLength && getters.selectedLength > 0,
	isQrcodeListEmpty: state => !state.qrcodeList || state.qrcodeList?.length === 0,
}
const mutations = {
	setQrcode: (state, qrcode) => {
		state.qrcode = qrcode
	},
	setQrcodeList: (state, qrcodeList) => {
		if (!state.qrcodeList) state.qrcodeList = []
		state.qrcodeList = qrcodeList
	},
	setValidRes: (state, validRes) => {
		state.validRes = validRes
	},
	setSelectedList: (state, selectedList) => {
		if (!selectedList) selectedList = []
		state.selectedList = selectedList
	},
	setMaxSize: (state, maxSize) => {
		state.maxSize = maxSize
	},
	setInit: state => {
		state.qrcode = null
		state.qrcodeList = null
		state.selectedList = null
		state.maxSize = -1
	},
}
const actions = {
	validQrCode: async ({ rootGetters, commit, state }) => {
		let response
		try {
			response = await inventoryAPI.validateQRSticker(rootGetters.instituteId, { value: state.qrcode })
		} catch (error) {
			response = error.response
		} finally {
			commit('setValidRes', response.data)
		}
	},
}
export default { namespaced: true, state, getters, mutations, actions }
