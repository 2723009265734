var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "ModalInviteMember"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt('멤버 초대')))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.hide
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "boxwrap setting"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('초대 설정')))]), _c('button', {
    staticClass: "Btn__hover-balloon-w"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Info.svg")
    }
  }), _c('div', {
    staticClass: "balloon",
    domProps: {
      "innerHTML": _vm._s(_vm.tt("\uCD08\uB300\uD560 \uBA64\uBC84\uC758 <b>\uAD8C\uD55C</b>\uACFC <b>\uADF8\uB8F9</b>\uC744 \uC124\uC815\uD574\uC8FC\uC138\uC694"))
    }
  })])]), _c('div', {
    staticClass: "flex",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c('button', {
    ref: "btn-wrapper",
    staticClass: "Btn__select"
  }, [_vm._v(" " + _vm._s(_vm.tt('권한:')) + " "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.authority.name,
      expression: "authority.name"
    }],
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.authority.name
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['btn-wrapper'].focus();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.authority, "name", $event.target.value);
      }
    }
  }), _c('ul', {
    staticClass: "Form__select-option-list"
  }, _vm._l(_vm.authorityList, function (authority) {
    return _c('li', {
      key: authority.id,
      staticClass: "Form__select-option-item",
      attrs: {
        "value": authority.name
      },
      on: {
        "click": function click($event) {
          return _vm.onSelectAuthority(authority);
        }
      }
    }, [_vm._v(" " + _vm._s(authority.name) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "Dropdown__input",
    on: {
      "click": _vm.addGroup
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('그룹:')) + " "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.groupAuth.name,
      expression: "groupAuth.name"
    }],
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.groupAuth.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.groupAuth, "name", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "boxwrap link"
  }, [_c('p', [_vm._v(_vm._s(_vm.tt('링크로 초대하기')))]), _c('div', {
    staticClass: "flex"
  }, [_c('div', [_c('lottie-animation', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowLottie,
      expression: "isShowLottie"
    }],
    ref: "lottie-animation",
    staticClass: "lottie-animation",
    attrs: {
      "animationData": require('@/assets/json/lm_loading_lottie.json'),
      "autoPlay": false,
      "loop": true
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inviteLink,
      expression: "inviteLink"
    }],
    ref: "inviteLinkInput",
    staticClass: "link-input",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.inviteLink
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inviteLink = $event.target.value;
      }
    }
  })], 1), _c('button', {
    staticClass: "Btn__link",
    on: {
      "click": _vm.copyToClipboardLink
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Duplicate.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.tt('링크 복사')) + " ")])])]), _c('div', {
    staticClass: "boxwrap email"
  }, [_c('email-text-area', {
    ref: "emailTextArea",
    staticClass: "email-area",
    attrs: {
      "contentMaxHeight": 561
    }
  }), _c('toggle-text-area', {
    ref: "toggle-text-area",
    attrs: {
      "title": "전송 완료"
    }
  })], 1)]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.sendEmail
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('이메일 전송')) + " ")])])]), _c('modal-add-group-auth', {
    ref: "modal-add-group-auth",
    attrs: {
      "mode": "storageGroup"
    },
    on: {
      "select": _vm.selectGroup
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }