var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_vm.leftBtnName ? _c('div', {
          staticClass: "footer-left"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": function click($event) {
              return _vm.$emit('clickLeftBtn');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.leftBtnName)) + " ")])]) : _vm._e(), _c('div', {
          staticClass: "footer-right"
        }, [_c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.hide
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.tt(_vm.confirmText)) + " ")])])])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "search-wrap"
  }, [_c('search-box', {
    attrs: {
      "noRecent": "",
      "placeholder": _vm.tt('멤버 이름으로 검색해주세요'),
      "isImmediately": true,
      "model": _vm.keyword
    },
    on: {
      "change": _vm.setKeyword
    }
  })], 1), _c('div', {
    staticClass: "content"
  }, [_vm.members && _vm.members.length ? _c('div', {
    staticClass: "tree-wrap"
  }, _vm._l(_vm.members, function (member) {
    return _c('div', {
      key: member.id,
      staticClass: "content-wrap",
      on: {
        "click": function click($event) {
          return _vm.selectMember(member);
        }
      }
    }, [_c('div', {
      staticClass: "user-info"
    }, [_c('img', {
      attrs: {
        "src": member.image ? member.image : require('@/assets/svg/member-photo-default.svg')
      }
    }), _c('div', {
      staticClass: "right"
    }, [_c('b', {
      domProps: {
        "innerHTML": _vm._s(_vm.replaceHighlight(member.name))
      }
    }), _c('div', [member.groups.length > 0 ? _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.replaceHighlight(_vm.groupInfo(member)))
      }
    }) : _vm._e(), _c('p', {
      staticClass: "ellipsis",
      domProps: {
        "innerHTML": _vm._s(_vm.replaceHighlight(member.email))
      }
    })])])]), _c('div', {
      staticClass: "radio"
    }, [_c('input', {
      attrs: {
        "type": "radio"
      },
      domProps: {
        "checked": _vm.selectedMembers.find(function (m) {
          return m.id == member.id;
        })
      },
      on: {
        "change": function change($event) {
          return _vm.selectMember(member);
        }
      }
    }), _c('span')])]);
  }), 0) : _c('div', {
    staticClass: "empty-wrap"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/svg/empty-member.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.tt('검색 결과가 없습니다')))])])])]), _c('div', {
    staticClass: "selected-group"
  }, [!_vm.selectedMembers.length ? [_vm._v(" " + _vm._s(_vm.tt('멤버를 선택해주세요')) + " ")] : [_vm._v(" " + _vm._s(_vm.tt('${1}님이 선택되었습니다', _vm.selectedMembers[0].name)) + " ")]], 2)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }