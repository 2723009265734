<template>
	<tr :class="{ disabled: !item.canOrder }">
		<td>
			<input
				:disabled="!item.canOrder"
				type="radio"
				name="estimateId"
				:value="item.id"
				@change="change"
				:checked="item.id == selectedEstimateId"
			/>
		</td>
		<td>
			<span class="status" :class="[item.status.code]">{{ tt(statusText) }}</span>
		</td>
		<td class="underline" @click="goEstimate">{{ item.no }}</td>
		<td>
			<b>{{ item.productName }}</b>
		</td>
		<td>{{ item.writer.name }}</td>
		<td>{{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd') }}</td>
		<td>{{ $DateTime.fromISO(item.updateDatetime).toFormat('yyyy-MM-dd') }}</td>
	</tr>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
	props: {
		item: {
			type: Object,
		},
	},
	computed: {
		...mapState('draft/estimate', ['selectedEstimateId']),
		statusText() {
			if (this.item.status.code == 'READ') return '확인'
			else if (this.item.status.code == 'ORDERED') return '주문완료'
			return '미확인'
		},
	},
	methods: {
		...mapMutations('draft/estimate', ['setSelectedEstimateId']),
		change(event) {
			this.setSelectedEstimateId(event.target.value)
		},
		goEstimate() {
			this.$root.goToStore(`mypage/estimate/${this.item.id}`)
		},
	},
}
</script>
<style lang="scss" scoped>
tr {
	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}
td {
	height: 70px;
	border-bottom: 1px solid $DIVIDER;
	font-size: 12px;
	font-weight: 500;
	color: $DEFAULT;
	&.underline:hover {
		text-decoration: underline;
		cursor: pointer;
	}
	.status {
		display: inline-block;
		padding: 0px 5px;
		font-size: 14px;
		font-weight: 700;
		border-radius: 5px;
		height: 20px;
		color: $SECONDARY_2;
		background-color: $DIVIDER;
		&.READ {
			color: #0075c5;
		}
		&.ORDERED {
			color: $GREEN;
			background-color: #dbf6eb;
		}
	}
	b {
		font-size: 14px;
	}

	input[type='radio'] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: inline-block;
		width: 18px;
		height: 18px;
		padding: 3px;
		/* background-color only for content */
		background-clip: content-box;
		border: 1px solid #ccc;
		background-color: white;
		border-radius: 50%;
		&:checked {
			background-color: $LAB_YELLOW;
			border: 1px solid $LAB_YELLOW;
		}
	}
}
</style>
