<template>
	<div class="counter-wrap">
		<div class="input-header">
			<div class="input-title">
				{{ tt(title) }}
				<p class="required">*</p>
			</div>
			<!-- <div class="input-sub" v-if="hasOrderCount">주문수량: n개</div> -->
		</div>
		<input-button-counter
			ref="input-button-counter"
			v-model="count"
			:min="min"
			:max="max"
			sizeType="sm"
			:isShowError="isShowError"
		></input-button-counter>
		<p class="error" v-if="error || isShowError">
			<img src="@/assets/svg/purchase/icon-error.svg" width="12" height="12" />
			{{ tt('${1}을 입력해주세요.', title) }}
		</p>
	</div>
</template>
<script>
import InputButtonCounter from '@/views/purchase/cargo/components/cargoList/counter/InputButtonCounter.vue'
export default {
	name: 'FormCounter',
	components: { InputButtonCounter },
	props: {
		title: {
			type: String,
			default: '등록할 수량',
		},
		value: {
			type: Number,
			default: 0,
		},
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: Number.MAX_SAFE_INTEGER,
		},
		hasOrderCount: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			count: 0,
			error: false,
		}
	},
	computed: {
		isValid() {
			return this.count >= this.min && this.count <= this.max
		},
	},
	watch: {
		value(value) {
			if (this.count != value) {
				this.$emit('input', this.count)
				this.$emit('change', this.count)
				this.count = value
			}
			if (this.isValid) this.error = !this.isValid
		},
		count(value) {
			this.$emit('input', this.count)
			this.$emit('change', this.count)
		},
	},
	created() {},
	mounted() {
		if (this.count !== this.value) this.count = this.value
	},
	methods: {
		changeCounter(count) {
			this.count = count
			this.$emit('input', count)
			this.$emit('change', count)
		},
		showError() {
			this.$refs['input-button-counter']?.showError()
			this.error = !this.isValid
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
.error {
	color: $RED;
	font-size: 12px;
	font-weight: 400;
}
</style>
