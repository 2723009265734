import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'

const MigrationMain = () => import(/*webpackChunkName: 'migration-main'*/ '@/views/migration/MigrationMain.vue')
const MigrationNoAdmin = () => import(/*webpackChunkName: 'migration-no-admin'*/ '@/views/migration/MigrationNoAdmin.vue')
const MigrationBeing = () => import(/*webpackChunkName: 'migration-being'*/ '@/views/migration/MigrationBeing.vue')
const MigrationSelect = () => import(/*webpackChunkName: 'migration-select'*/ '@/views/migration/MigrationSelect.vue')
const MigrationSelectLab = () => import(/*webpackChunkName: 'migration-select-lab'*/ '@/views/migration/MigrationSelectLab.vue')
const MigrationInputInstitutionName = () =>
	import(/*webpackChunkName: 'migration-input-institution-name'*/ '@/views/migration/MigrationInputInstitutionName.vue')
const MigrationMemberList = () => import(/*webpackChunkName: 'migration-member-list'*/ '@/views/migration/MigrationMemberList.vue')

const routes = [
	{
		name: PageName.Migration.Main,
		path: RoutePath.Migration.Main,
		component: MigrationMain,
	},
	{
		name: PageName.Migration.NoAdmin,
		path: RoutePath.Migration.NoAdmin,
		component: MigrationNoAdmin,
	},
	{
		name: PageName.Migration.Being,
		path: RoutePath.Migration.Being,
		component: MigrationBeing,
	},
	{
		name: PageName.Migration.Select,
		path: RoutePath.Migration.Select,
		component: MigrationSelect,
	},
	{
		name: PageName.Migration.SelectLab,
		path: RoutePath.Migration.SelectLab,
		component: MigrationSelectLab,
	},
	{
		name: PageName.Migration.InputInstitutionName,
		path: RoutePath.Migration.InputInstitutionName,
		component: MigrationInputInstitutionName,
	},
	{
		name: PageName.Migration.MemberList,
		path: RoutePath.Migration.MemberList,
		component: MigrationMemberList,
	},
]

export default routes
