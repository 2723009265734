var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "detail-header"
  }, [_c('div', {
    staticClass: "title-wrap"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('p', {
    staticClass: "create-time"
  }, [_vm._v(_vm._s(_vm.createDatetime))])]), _c('div', {
    staticClass: "button-wrap"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.inputDirect
    }
  }, [_vm._v(_vm._s(_vm.tt('수기 등록')))]), _vm.isVisibleSecondBtn ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.clickSecondBtn
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.secondBtnText)))]) : _vm._e()]), _c('div', {
    staticClass: "right"
  }, [_c('button', {
    staticClass: "button-red",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-delete'].show();
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('삭제')))])])]), _c('modal-confirm', {
    ref: "modal-delete",
    attrs: {
      "title": "".concat(_vm.title, " \uC0AD\uC81C"),
      "content": "".concat(_vm.title, "\uC744 \uC0AD\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?"),
      "variant": "warning"
    },
    on: {
      "confirm": _vm.deleteWaitingItem
    }
  }), _c('modal-camera', {
    ref: "modal-camera",
    attrs: {
      "title": _vm.tt('추가 촬영'),
      "isPrev": false,
      "max": _vm.cameraMax,
      "nextBtnText": "완료"
    },
    on: {
      "next": _vm.addImage
    }
  }), _c('modal-search-product', {
    ref: "modal-search-product",
    attrs: {
      "title": "등록할 물품 검색",
      "categoryInfo": _vm.categoryInfo,
      "forRegistItem": ""
    },
    on: {
      "selectItem": _vm.selectProduct,
      "cancel": function cancel($event) {
        return _vm.$refs['modal-search-product'].hide();
      },
      "inputDirect": _vm.inputDirectAtSearchProduct
    }
  }), _c('modal-item-product', {
    ref: "modal-product",
    attrs: {
      "title": "물품정보 등록"
    },
    on: {
      "cancel": function cancel($event) {
        return _vm.initItem();
      },
      "complete": function complete($event) {
        return _vm.$refs['modal-management'].show();
      }
    }
  }), _c('modal-item-management', {
    ref: "modal-management",
    attrs: {
      "title": "관리정보 등록"
    },
    on: {
      "goPrev": function goPrev($event) {
        return _vm.$refs['modal-product'].show();
      }
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }