<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title
			><div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="search-wrap">
				<search-box noRecent :placeholder="tt('그룹 이름으로 검색해주세요')" :model="keyword" @change="setKeyword"></search-box>
			</div>
			<div class="folding-wrapper">
				<div @click="setTreeOpen(true)"><img src="@/assets/svg/icon-unfold.svg" />{{ tt('전체 펼치기') }}</div>
				<span class="br"></span>
				<div @click="setTreeOpen(false)"><img src="@/assets/svg/icon-fold.svg" />{{ tt('전체 접기') }}</div>
			</div>
			<div class="content">
				<div class="tree-wrap">
					<group-node :isInclude="isInclude" :isSelectSingle="isSelectSingle" v-for="node in list" :key="node.id" :node="node"></group-node>
				</div>
			</div>
			<div class="selected-group" v-if="isSelectSingle && selectedGroups[0] && selectedGroups.length > 0">
				{{ tt('${1}이 선택되었습니다', selectedGroups[0].name) }}
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="confirm">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import GroupNode from '@/views/common/components/tree/gen2/components/GroupNode.vue'
import SearchBox from '@/views/components/searchBox'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
	components: {
		GroupNode,
		SearchBox,
	},
	props: {
		title: {
			type: String,
			default: '그룹 선택',
		},
		isSelectSingle: {
			type: Boolean,
			default: false,
		},
		isInclude: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapState('institute/group', ['groups', 'selectedGroups', 'keyword', 'list']),
	},
	methods: {
		...mapMutations('institute/group', ['setSelectedGroup', 'setKeyword', 'setTreeOpen']),
		...mapActions('institute/group', ['getGroups']),
		async show(selectedGroups, payload) {
			if (selectedGroups) this.setSelectedGroup(selectedGroups)
			else this.setSelectedGroup([])
			await this.getGroups(payload)
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		confirm() {
			this.hide()
			this.$emit('confirm', this.selectedGroups)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-single-select-storage.scss';
.tree-wrap {
	margin-left: -15px;
	margin-top: 10px;
	padding-right: 10px;
}
.selected-group {
	margin-top: -20px;
	position: sticky;
	width: 100%;
	height: 30px;
	bottom: 0px;
	background-color: #fbfaf9;
	border: 1px solid $DIVIDER;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
