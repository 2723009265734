<template>
	<table class="table-wrap" :class="{ selectedItem: selectItem.length > 0 }">
		<table-header />
		<table-item v-for="item in list" :key="item.id" :item="item" />
		<div class="empty-table" v-if="count === 0">
			<img class="empty-img" src="@/assets/svg/empty/inventory-empty-data.svg" />
			<p class="bold">{{ tt('검색 결과가 없습니다') }}</p>
			<p class="sub">
				{{ tt('주문 번호, 문서 번호, 기안 제목, 주문 연구실으로 검색할 수 있습니다.') }}
			</p>
		</div>
	</table>
</template>
<script>
import TableHeader from './header.vue'
import TableItem from './item.vue'
import { mapState } from 'vuex'
export default {
	components: {
		TableHeader,
		TableItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapState('purchaseProduct/list', ['count', 'selectItem']),
	},
}
</script>
<style lang="scss" scoped>
.table-wrap {
	border-top: 1px solid $DIVIDER;
	height: calc(100% - 270px);
	text-align: left;
	display: block;
	overflow: auto;
	white-space: nowrap;
}

.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: $SECONDARY_2;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
