<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<input type="checkbox" class="checkbox" v-model="allCheck" :class="{ someChecked: !allCheck && !isEmptySelectedItems }" />
		</th>
		<th>
			<span>{{ tt('입력자') }}</span>
		</th>
		<th :class="orderClass('TYPE')" @click.stop="changeOrder('TYPE')">
			<span>{{ tt('내용') }}</span>
		</th>
		<th>
			<span>{{ tt('상세') }}</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')">
			<span>{{ tt('시간') }}</span>
		</th>
		<th>
			<span style="min-width: 80px">{{ tt('관리') }}</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	name: 'HistoryTableHeader',
	computed: {
		...mapState('inventoryGen2/historyInfo', ['list', 'selectedItems']),
		...mapGetters('inventoryGen2/historyInfo', ['order', 'orderDirection', 'isEmptySelectedItems']),
		allCheck: {
			get() {
				return this.list.every(it => this.selectedItems.map(sit => sit.id).includes(it.id)) && this.list.length !== 0
			},
			set(val) {
				if (val) this.setSelectedItems(this.list)
				else this.setSelectedItems([])
			},
		},
		defaultUnCheck() {
			return ['categoryClassification', 'categoryType', 'brand', 'productCode']
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapMutations('inventoryGen2/historyInfo', ['setSelectedItems']),
		...mapActions('inventoryGen2/historyInfo', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
.table-header {
	background: $GREY_2 !important;
	box-shadow: none !important;
	border-bottom: 1px solid $DIVIDER;
}
th > span {
	display: flex !important;
}
.header-checkbox {
	max-width: 56px !important;
}
</style>
