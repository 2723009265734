<template>
	<div class="OrganizationTree__page-container">
		<inst-header title="institution_management.organization_chart"></inst-header>
		<div class="OrganizationTree__container">
			<div class="OrganizationTree__chart-container">
				<div>{{ activeId }}</div>
				<input type="text" name="" v-model="keyword" />
				<TreeView
					:treeData="groupList"
					textField="name"
					subTextField="membersCount"
					subTextEndWord="명"
					@changeActive="changeActiveId"
					:keyword="keyword"
				></TreeView>
			</div>
			<!-- 멤버 리스트  -->
		</div>
	</div>
</template>

<script>
import InstHeader from '@/views/institute/components/InstHeader.vue'
import TreeView from '@/views/common/components/tree/TreeView.vue'

export default {
	components: {
		InstHeader,
		TreeView,
	},
	data() {
		return {
			keyword: '',
			activeId: '',
			groupList: [
				// TODO : @seheelee-413 store 와 연결시키거나 동적으로 셋팅할 것
				{
					id: '0avopd',
					parentId: null,
					name: '스마트잭',
					phone: '123-4444-4444',
					members: [
						{
							id: '11ea521cefe012a0',
							name: '구영회',
						},
						{
							id: '11eba285a90168b5',
							name: '이상헌',
						},
						{
							id: '11eb6f4c44fd33cc',
							name: '이세희',
						},
					],
					membersCount: 3,
					children: [
						{
							id: 'bDh9ii',
							parentId: '0avopd',
							name: 'test1234',
							phone: '123-1231-2333',
							members: [],
							membersCount: 0,
							children: [
								{
									id: 'bDh9ii3',
									parentId: '0avopd',
									name: 'tttt3',
									phone: '123-1231-2333',
									members: [],
									membersCount: 0,
									children: [],
								},
							],
						},
						{
							id: '4msqoG',
							parentId: '0avopd',
							name: '그룹생성테스트',
							phone: '010-5551-8542',
							members: [
								{
									id: '11ea521cefe012a0',
									name: '구영회',
								},
								{
									id: '11eba285a90168b5',
									name: '이상헌',
								},
								{
									id: '11eb6f4c44fd33cc',
									name: '이세희',
								},
							],
							membersCount: 0,
							children: [
								{
									id: 'bDh9ii2',
									parentId: '0avopd',
									name: 'ddd123',
									phone: '123-1231-2333',
									members: [],
									membersCount: 0,
									children: [],
								},
							],
						},
					],
				},
			],
		}
	},
	methods: {
		changeActiveId(id, node) {
			this.activeId = id
		},
	},
}
</script>

<style lang="scss" scoped>
.OrganizationTree__page-container {
	display: block;
	height: calc(100% - 60px);
}

.OrganizationTree__container {
	display: flex;
	width: 100%;
	height: 100%;
	min-height: 236px;
	overflow-y: hidden;
}

.OrganizationTree__chart-container {
	display: flex;
	flex-direction: column;
	width: 450px;
	max-width: 450px;
	height: 100%;
}

.OrganizationTree__chart-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-width: 390px;
	height: 70px;
	padding: 20px 30px;
	z-index: $ORGANIZATION_CHART_HEADER;
	&.shadow {
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	}
}

.OrganizationTree__main-container {
	display: block;
	position: relative;
	width: 100%;
	height: calc(100%);
	overflow: scroll;
	user-select: none;
	padding-left: 20px;
}
</style>
