var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-item",
    "class": {
      error: _vm.item.error && _vm.item.error.length > 0 || !_vm.item.storage
    }
  }, [_c('td', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('span', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, null) > -1 : _vm.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checked = $$c;
        }
      }
    }
  })])]), _c('td', [_c('div', {
    staticClass: "info",
    staticStyle: {
      "width": "300px"
    }
  }, [_c('button', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickDelete.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/components/svg/icon-remove-round-x.svg")
    }
  })]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.bottom",
      value: _vm.hoverText,
      expression: "hoverText",
      modifiers: {
        "hover": true,
        "html": true,
        "bottom": true
      }
    }]
  }, [_c('div', [_vm.item.error.length > 0 || !_vm.item.storage ? _c('img', {
    attrs: {
      "src": _vm.unusableImage
    }
  }) : _vm._e(), _c('h6', {
    staticStyle: {
      "font-weight": "700"
    }
  }, [_vm._v(_vm._s(_vm.item.name))])]), _c('div', {
    staticClass: "subtext"
  }, [_vm.item.brand && _vm.item.brand.name ? _c('p', [_vm._v(_vm._s(_vm.item.brand.name))]) : _vm._e(), _vm.item.subname ? _c('p', [_vm._v(_vm._s(_vm.item.subname))]) : _vm._e()])])])]), _c('td', {
    staticStyle: {
      "height": "80px"
    }
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.isEmpty(_vm.item.productCode)))])])]), _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.isEmpty(_vm.item.casno)))])])]), _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.volume))])])]), _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.tt('${1}개', _vm.item.multiCreateCount)))])])]), _c('td', [_c('div', {
    staticClass: "storage-wrap"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.storageName
    }
  }), _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showSelectModal.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('선택')))])])]), _c('td', [_c('div', {
    staticStyle: {
      "min-width": "80px"
    }
  }, [_c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.showEditModal.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('수정')))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }