var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "property-wrapper"
  }, [_vm.img ? _c('img', {
    staticClass: "property-icon-img",
    attrs: {
      "src": _vm.img
    }
  }) : _vm._e(), _c('div', {
    staticClass: "property-content"
  }, [_c('div', {
    staticClass: "property-title"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.title)) + " ")]), _c('div', {
    staticClass: "property-subtext"
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.subtext)) + " ")])]), _c('div', {
    staticClass: "btn-wrapper"
  }, [_c('button', {
    staticClass: "show-button",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('보러가기')))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }