import userAPI from '@/services/api/institute/user'

const index = {
	namespaced: true,
	state: () => ({
		userPermission: {},
	}),
	getters: {},
	mutations: {
		setUserPermission: (state, payload) => {
			state.userPermission = payload
		},
	},
	actions: {
		loadUserPermission: ({ commit }) => {
			return new Promise(async (resolve, reject) => {
				try {
					const response = await userAPI.lookupUserPermission()
					commit('setUserPermission', { ...response.data })
					resolve()
				} catch (e) {
				} finally {
					resolve()
				}
			})
		},
	},
}
export default index
