<template>
	<div class="badge-wrap">
		<img :src="image" width="24" height="24" />
		<div class="text" :class="{ invalid: !isValid }" v-html="tt(text)"></div>
		<div class="subtext">
			{{ tt(subtext) }}
		</div>
	</div>
</template>
<script>
export default {
	name: 'ItemBadge',
	components: {},
	props: {
		image: {
			type: String,
		},
		text: {
			type: String,
		},
		subtext: {
			type: String,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.badge-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 20px 0px;
	padding: 0px 20px;
	min-width: 125px;
	height: 80px;
	gap: 3px;
}

.text {
	font-weight: 700;
	font-size: 14px;
	::v-deep .nodata {
		color: $PLACEHOLDER !important;
	}
	&.invalid {
		color: $RED !important;
	}
}

.subtext {
	font-weight: 400;
	font-size: 12px;
	color: #3c3c4399;
}
</style>
