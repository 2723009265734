var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "table-item",
    "class": {
      "new": _vm.isNew
    },
    on: {
      "click": function click($event) {
        return _vm.goToDetail(_vm.item.id);
      }
    }
  }, [_c('td', [_c('span', {
    staticClass: "checkbox",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isChecked,
      expression: "isChecked"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.isChecked) ? _vm._i(_vm.isChecked, null) > -1 : _vm.isChecked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.isChecked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isChecked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isChecked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isChecked = $$c;
        }
      }
    }
  })])]), _c('td', [_c('div', {
    staticClass: "status",
    "class": {
      complete: _vm.isComplete
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.tt(_vm.statusText)))]), _vm.isNeedInfo ? _c('p', {
    staticClass: "need-info",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$emit('needInputCargoInfo', _vm.item);
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('정보입력 필요')))]) : _vm._e()])]), _vm.isView('multiCreateCount') ? _c('td', [_c('div', {
    ref: "countWrap",
    staticClass: "count-wrap"
  }, [_c('button', {
    staticClass: "divide-button",
    "class": {
      disabled: _vm.isCannotDivide
    },
    attrs: {
      "id": "divideCounter".concat(_vm.item.id)
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.isShowCounter = !_vm.isShowCounter;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('분리')) + " ")]), _c('div', {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.formatNumber(_vm.item.multiCreateCount)) + _vm._s(_vm.tt('개')))]), _c('b-popover', {
    ref: "popover",
    staticClass: "cargo-popover",
    attrs: {
      "target": "divideCounter".concat(_vm.item.id),
      "placement": "bottom",
      "triggers": "click blur"
    }
  }, [_c('popover-divide-counter', {
    ref: "counter",
    attrs: {
      "max": _vm.item.multiCreateCount - 1,
      "min": 1,
      "value": 1
    },
    on: {
      "close": function close($event) {
        return _vm.$refs.popover.$emit('close');
      },
      "divide": _vm.divide
    }
  })], 1)], 1)]) : _vm._e(), _vm.isView('location') ? _c('td', [_c('div', {
    staticClass: "right"
  }, [_c('span', [_vm._v(_vm._s(_vm.formatStorage(_vm.item.storage)))])])]) : _vm._e(), _vm.isView('registerMethod') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.tt(_vm.registerMethod(_vm.formatText(_vm.item.registerMethod)))))])])]) : _vm._e(), _vm.isView('name') ? _c('td', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.item.name,
      expression: "item.name",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-title"
  }, [_vm._v(_vm._s(_vm.formatText(_vm.item.name)))]), _vm.item.brand || _vm.item.subname ? _c('p', {
    staticClass: "info-sub"
  }, [_vm._v(" " + _vm._s(_vm.item.brand ? _vm.item.brand.name : '') + " | " + _vm._s(_vm.item.subname ? _vm.item.subname : '') + " ")]) : _vm._e()])]) : _vm._e(), _vm.isView('category') ? _c('td', [_c('div', {
    staticClass: "category"
  }, [_c('span', [_vm._v(_vm._s(_vm.formatText(_vm.item.category ? _vm.item.category.name : null)))])])]) : _vm._e(), _vm.isView('brand') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.formatBrand(_vm.item.brand)))])])]) : _vm._e(), _vm.isView('productCode') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.formatText(_vm.item.productCode)))])])]) : _vm._e(), _vm.isView('volume') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.volume))])])]) : _vm._e(), _vm.isView('shipStatus') ? _c('td', [_c('div', [_c('p', [_vm._v(_vm._s(_vm.shipStatus))]), _c('p', {
    staticClass: "expect-time"
  }, [_vm._v(_vm._s(_vm.formatExpectDatetime))])])]) : _vm._e(), _vm.isView('register') ? _c('td', [_c('div', {
    staticClass: "profile-wrap"
  }, [_c('img', {
    staticClass: "profile-img",
    attrs: {
      "src": _vm.formatProfileImg(_vm.item.register.image)
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.formatText(_vm.item.register.name)) + " ")])])]) : _vm._e(), _vm.isView('completeDatetime') ? _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.formatDatetimeFromISO(_vm.item.completeDatetime)))])])]) : _vm._e(), _c('td', [_c('div', [_c('span', [_vm._v(_vm._s(_vm.formatDatetimeFromISO(_vm.item.createDatetime)))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }