<template>
	<div class="card-input-button unselectable" :class="{ empty: number == '' }" @click.stop="addNumber">
		<div class="number">{{ number }}</div>
	</div>
</template>

<script>
export default {
	props: {
		number: {
			type: [String, Number],
			default: '',
		},
	},
	methods: {
		addNumber() {
			if (this.number !== '') {
				this.$emit('add', this.number)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.card-input-button {
	display: inline-block;
	background: $LAB_WHITE;
	box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.5), 0px 0.5px 1px $DIM;
	border-radius: 5px;
	width: 40px;
	height: 40px;
	margin-right: 4px;
	margin-bottom: 4px;
	.number {
		display: flex;
		width: 100%;
		height: 100%;
		font-weight: 700;
		font-size: 24px;
		align-items: center;
		justify-content: center;
	}
	&.empty {
		background: $GREY_1;
		box-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.5), 0px 0.5px 1px $DIM;
		border-radius: 5px;
	}
}
</style>
