var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "legacy-item-wrapper",
    on: {
      "click": _vm.onClickItem
    }
  }, [_c('div', {
    staticClass: "content-wrapper",
    "class": {
      create: !_vm.info
    }
  }, [_c('div', {
    staticClass: "item-header"
  }, [_c('div', {
    staticClass: "icon-wrapper"
  }, [_c('img', {
    attrs: {
      "src": _vm.logo
    }
  })]), _c('div', {
    staticClass: "text-wrapper"
  }, [_c('div', {
    staticClass: "text-title",
    "class": {
      expand: _vm.subTitle === ''
    },
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.title))
    }
  }), _c('div', {
    staticClass: "text-sub-title",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.subTitle))
    }
  })]), _vm._m(0)]), _vm.info ? [_c('div', {
    staticClass: "item-institute-info"
  }, [_c('ul', {
    staticClass: "item-text-list"
  }, [_c('li', {
    staticClass: "item-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('기관')) + " : " + _vm._s(_vm.instituteName) + " ")]), _c('li', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.tt('그룹')) + " : " + _vm._s(_vm.groupTitle))]), _c('li', {
    staticClass: "item-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('초대 일시')) + " : " + _vm._s(_vm.formatDatetime(_vm.info.createDatetime)) + " ")]), _c('li', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.tt('초대한 사람')) + " : " + _vm._s(_vm.hostName))]), _vm.isLogin ? _c('li', {
    staticClass: "item-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('초대받은 사람')) + " : " + _vm._s(_vm.user.email) + " ")]) : _vm._e()])]), _vm.info.license ? _c('div', {
    staticClass: "item-license-info"
  }, [_c('ul', {
    staticClass: "item-text-list"
  }, [_vm.info.license.type ? _c('li', {
    staticClass: "item-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('라이선스 :')) + " "), _c('span', {
    "class": {
      error: _vm.isExpired
    }
  }, [_vm._v(" " + _vm._s(_vm.licenseType2Text(_vm.info.license.type)) + " "), _vm.isExpired ? _c('span', [_vm._v(" " + _vm._s(_vm.tt('(만료됨)')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.tt("(\uD65C\uC131\uD654: ".concat(_vm.formatDatetime(_vm.info.license.expireDate), "\uAE4C\uC9C0)"))) + " ")])])]) : _vm._e(), _vm.info.license.quantity ? _c('li', {
    staticClass: "item-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('활성화된 멤버 / 라이선스 수 :')) + " "), _c('span', {
    "class": {
      error: _vm.isOverCountLicense
    }
  }, [_vm._v(" " + _vm._s(_vm.info.institute.memberCount) + " / " + _vm._s(_vm.info.license.quantity) + " " + _vm._s(_vm.isOverCountLicense ? _vm.tt('(보유 라이선스 부족)') : null) + " ")])]) : _vm._e()])]) : _vm._e()] : _vm._e()], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "arrow-wrapper"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/arrow/icon-arrow-right.svg")
    }
  })]);
}]

export { render, staticRenderFns }