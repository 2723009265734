<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalQuantityMultipleSetting">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ tt('지정수량 배수 설정') }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content Form__wrap">
					<div class="Popup__select-field">
						<p>{{ tt('저장소 선택') }}</p>
						<lm-option-box-number
							v-model="selectStorage"
							:list="storageList"
							valueKey="id"
							textKey="name"
							@change="changeStorage"
							:placeholder="tt('저장소를 선택해주세요')"
						></lm-option-box-number>
					</div>
					<div class="Popup__select-field mt-20">
						<p>{{ tt('유별 선택') }}</p>
						<lm-option-box
							v-model="selectCategory"
							:list="legalList"
							valueKey="legalId"
							textKey="legalName"
							@change="changeCategory"
							:placeholder="tt('유별을 선택해주세요')"
						></lm-option-box>
					</div>
					<div class="Popup__select-field mt-20">
						<p>{{ tt('품명 선택') }}</p>
						<lm-option-box
							v-model="selectProduct"
							:list="productList"
							valueKey="legalId"
							textKey="legalName"
							@change="changeProduct"
							:placeholder="tt('품명을 입력해주세요')"
						></lm-option-box>
					</div>
					<div class="flex mt-20" style="width: 100%">
						<div class="Popup__input-field">
							<p>{{ tt('시행령 지정수량') }}</p>
							<input type="text" readonly v-model="volume" :placeholder="tt('유별 및 품명을 선택해주세요')" />
						</div>
						<div class="imgbox">
							<img src="@/assets/svg/multiplication.svg" alt="icon" />
						</div>
						<div class="Popup__input-field">
							<p>{{ tt('배수') }}</p>
							<input type="number" v-model="multiple" :placeholder="tt('배수를 입력해주세요')" />
						</div>
					</div>
					<span class="noti"
						><img src="@/assets/svg/noti-checked.svg" alt="icon" />{{ tt('최대 저장량') }}: {{ limitVolume }}{{ volumeUnit }}</span
					>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="submit">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import dangerStorageAPI from '@/services/api/safety/dangerStorage'
import LmOptionBox from '@/views/common/components/input/LmOptionBox.vue'
import LmOptionBoxNumber from '@/views/common/components/input/LmOptionBoxNumber.vue'
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalQuantityMultipleSetting',
	mixins: [MixinModal],
	props: {
		storageList: {
			type: Array,
			default: () => {},
		},
	},
	components: {
		LmOptionBox,
		LmOptionBoxNumber,
	},
	data() {
		return {
			selectStorage: '',
			selectCategory: '',
			selectProduct: '',
			multiple: null,
			legalList: [],
			productList: [],
			baseLimitVolume: null,
			limitVolume: null,
			volumeUnit: null,
			volume: '',
		}
	},
	created() {},
	watch: {
		multiple(val) {
			this.limitVolume = this.baseLimitVolume * val
		},
	},
	methods: {
		async show(storageId, category, item) {
			this.isShow = true
			this.selectStorage = storageId
			if (category && item) {
				this.selectCategory = category
				this.selectProduct = item.legalId
				this.volume = item.baseLimitVolume + item.volumeUnit
				this.baseLimitVolume = item.baseLimitVolume
				this.limitVolume = item.limitVolume
				this.volumeUnit = item.volumeUnit
				this.multiple = item.multiple
			} else {
				this.selectCategory = null
				this.selectProduct = null
				this.clearData()
			}
			await this.loadStorageLegal(this.$route.params.instituteId, this.selectStorage, category)
		},
		clearData() {
			this.multiple = null
			this.baseLimitVolume = null
			this.limitVolume = null
			this.volumeUnit = null
			this.volume = null
		},
		loadStorageLegal(instId, storage, category) {
			return new Promise(async (resolve, reject) => {
				const response = await dangerStorageAPI.lookupStorageLegalList(instId, storage)
				this.legalList = [...response.data]

				const filteredLegalList = response.data.filter(x => {
					return category == x.legalId
				})

				if (filteredLegalList.length > 0) {
					this.productList = [...filteredLegalList[0].childLegalList]
				}
				resolve()
			})
		},
		changeStorage(option) {
			this.loadStorageLegal(this.$route.params.instituteId, option.id)
			this.selectCategory = null
			this.selectProduct = null
			this.clearData()
		},
		changeCategory(option) {
			this.productList = []
			this.selectProduct = null
			if (this.selectCategory == option.legalId) {
				this.productList = option.childLegalList
			}
		},
		changeProduct(option) {
			const { baseLimitVolume, volumeUnit, multiple } = option
			this.baseLimitVolume = baseLimitVolume
			this.volumeUnit = volumeUnit
			this.volume = baseLimitVolume + volumeUnit
			this.multiple = multiple
			this.limitVolume = baseLimitVolume * multiple
		},
		close() {
			this.hide()
		},
		submit() {
			const payload = {
				id: this.selectStorage,
				legalId: this.selectProduct,
				multiple: this.multiple,
			}

			this.$emit('hide', payload)
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalQuantityMultipleSetting {
	.flex {
		.imgbox {
			padding: 0 13px;
			margin-bottom: -22px;
		}
	}
	.noti {
		display: flex;
		align-items: center;
		height: auto;
		margin-top: 10px;
		font-size: 0.75rem;
		font-weight: 400;
		color: $GREEN;
		img {
			margin-right: 4px;
		}
	}
	input:read-only {
		background-color: $GREY_2;
	}
}
</style>
