import inventoryAPI from '@/services/api/inventory/gen2'
import { cloneDeep, isEqual } from 'lodash'

const state = {
	selectedItem: [],
	// resetItem: null,
	originDivideItems: [],
	divideItems: [],
	divideKeyNo: 0, // 소분 item의 고유번호
	isDispose: false,
	disposeMemo: null,
}

const getters = {
	selectedItem: state => state.selectedItem,
	originDivideItems: state => state.originDivideItems,
	resetItem: state => state.resetItem,
	divideItems: state => state.divideItems,
	allItemDivideKey: state => state.divideItems.map(item => item.divideKeyNo),
	divideItemsVolume: state => state.divideItems.map(item => item.volume).reduce((prev, curr) => prev + curr, 0),
	isKeySelectedAll: (state, getters) => getters.allItemDivideKey?.length === state.selectedItem?.length && state.selectedItem.length > 0,
	isEmptyKeySelected: state => state.selectedItem?.length === 0,
	isEmptyDivideItems: state => state.divideItems?.length === 0,
	divideData: state => {
		const { id, name } = state.originDivideItems[0]
		const formData = new FormData()
		formData.append('itemId', id)
		formData.append('name', name)

		if (state.isDispose) {
			formData.append('isDispose', state.isDispose)
			formData.append('disposeMemo', state.disposeMemo)
		}
		state.divideItems?.forEach((item, idx) => {
			const keys = Object.keys(item)
			const prefix = `deriveItems[${idx}]`
			keys?.forEach(key => {
				const value = item?.[key]
				if (!value) return
				switch (key) {
					case 'assignee':
					case 'brand':
					case 'buyer':
					case 'deleter':
					case 'location':
					case 'storage':
					case 'register':
					case 'disposer':
						if (value) {
							formData.append(`${prefix}[${key}Id]`, value?.id)
						}
						break
					case 'volume':
						formData.append(`${prefix}[currentVolume]`, value)
					case 'volumeUnit':
					case 'threshold':
					case 'thresholdUnit':
					case 'expireDatetime':
					case 'openDatetime':
					case 'density':
					case 'densityUnit':
					case 'molecularWeight':
					case 'molecularWeightUnit':
					case 'molarMass':
					case 'molarMassUnit':
					case 'assay':
					case 'assayUnit':
					case 'solubility':
					case 'solubilityUnit':
					case 'molecularFormula':
					case 'storageTemperature':
					case 'storageTemperatureUnit':
					case 'flashPoint':
					case 'flashPointUnit':
					case 'boilingPoint':
					case 'boilingPointUnit':
					case 'meltingPoint':
					case 'meltingPointUnit':
						formData.append(`${prefix}[${key}]`, value)
						break
					case 'images':
						value.forEach((file, iIdx) => {
							formData.append(`${prefix}[images]`, file)
						})
						break
					case 'tagsId':
						value.forEach((tagId, tIdx) => {
							formData.append(`${prefix}[tagsId][${tIdx}]`, tagId)
						})
						break
					case 'customField':
						value.forEach((field, fIdx) => {
							formData.append(`${prefix}[customField][${fIdx}][key]`, field.key)
							formData.append(`${prefix}[customField][${fIdx}][value]`, field.value)
						})
						break
					case 'ingredient':
						value.forEach((field, fIdx) => {
							formData.append(`${prefix}[ingredient][${fIdx}][name]`, field.name)
							formData.append(`${prefix}[ingredient][${fIdx}][ratio]`, field.ratio)
							if (field.casno) formData.append(`${prefix}[ingredient][${fIdx}][casno]`, field.casno)
						})
					default:
						return
				}
			})
		})
		return formData
	},
	isChangedItem: state => {
		return state.divideItems.map((item, idx) => !isEqual(state.originDivideItems[idx], item))
	},
}

const mutations = {
	setInitDivide: state => {
		state.selectedItem = []
		state.originDivideItems = []
		state.resetItem = null
		state.divideItems = []
		state.divideKeyNo = 0
		state.isDispose = false
		state.disposeMemo = null
	},
	setSelectedItem: (state, selectedItem) => {
		state.selectedItem = selectedItem
	},
	setResetItem: (state, resetItem) => {
		state.resetItem = resetItem
	},
	setDivideItems: (state, divideItems) => {
		state.divideItems = divideItems
	},
	setOriginDivideItems: (state, originDivideItems) => {
		state.originDivideItems = originDivideItems
	},
	setDivideItemsStorageByIdx: (state, { storage, idx }) => {
		let newItems = state.divideItems
		if (storage) {
			const { id, name, pathName } = storage
			newItems[idx].storage = { id: id, name: name, pathName: pathName }
			state.divideItems = [...newItems]
		}
	},
	setDivideItemsByIdx: (state, { item, idx }) => {
		let newItem = state.divideItems
		if (item) {
			newItem[idx] = item
			state.divideItems = [...newItem]
		}
	},
	setDivideKeyNo: (state, keyNo) => {
		state.divideKeyNo = keyNo
	},
	setIsDispose: (state, isDispose) => {
		state.isDispose = isDispose
	},
	setDisposeMemo: (state, memo) => {
		state.disposeMemo = memo
	},
}

const actions = {
	addDivideItem: ({ state, commit }, { detailItem, data }) => {
		let addItemList = []
		data.forEach(item => {
			for (let i = 0; i < item.count; i++) {
				let newItem = cloneDeep(detailItem)
				newItem.volume = item.volume
				newItem.currentVolume = item.volume
				newItem.divideKeyNo = state.divideKeyNo
				newItem.images = []
				newItem.imageList = null
				newItem.multiCreateCount = 1
				commit('setDivideKeyNo', state.divideKeyNo + 1)
				addItemList.push(newItem)
			}
		})
		commit('setDivideItems', [...cloneDeep(addItemList), ...cloneDeep(state.divideItems)])
		commit('setOriginDivideItems', [...cloneDeep(addItemList), ...cloneDeep(state.originDivideItems)])
	},
	changeSelectedItemStorage: ({ state, commit }, storage) => {
		if (storage) {
			let items = cloneDeep(state.divideItems)
			items.forEach(item => {
				if (state.selectedItem?.includes(item.divideKeyNo)) {
					const { id, name, pathName } = storage
					item.storage = { id: id, name: name, pathName: pathName }
				}
			})
			commit('setSelectedItem', [])
			commit('setDivideItems', items)
		}
	},
	removeSelectedItem: ({ state, commit }) => {
		commit(
			'setDivideItems',
			state.divideItems.filter(item => !state.selectedItem.includes(item.divideKeyNo))
		)
		commit('setSelectedItem', [])
	},
	divideItem: async ({ state, rootGetters, getters }) => {
		await inventoryAPI.divideItem(rootGetters.instituteId, getters.divideData)
	},
}

export default { namespaced: true, state, getters, mutations, actions }
