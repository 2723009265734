var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "keyword-header"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.tt('최근 검색어')) + " ")]), _c('button', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.removeAllRecent($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('모두 삭제')) + " ")])]), _c('ul', {
    staticClass: "recent-keyword-list"
  }, _vm._l(_vm.recentList, function (keyword, index) {
    return _c('li', {
      key: "keyword_".concat(index),
      staticClass: "recent-keyword"
    }, [_c('img', {
      staticClass: "recent",
      attrs: {
        "src": require("@/assets/svg/recent-keyword.svg")
      }
    }), _c('span', {
      staticClass: "keyword-text",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.$emit('clickRecent', keyword);
        }
      }
    }, [_vm._v(_vm._s(keyword))]), _c('img', {
      staticClass: "keyword-image",
      attrs: {
        "src": require("@/assets/svg/Close-1.svg")
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.removeRecent(keyword);
        }
      }
    })]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }