var render = function () {
  var _vm$items;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-close-on-backdrop": "",
      "no-fade": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "modal-footer-wrap"
        }, [_c('button', {
          staticClass: "button-primary",
          on: {
            "click": _vm.change
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('정상 판매가로 변경하기')) + " ")]), _c('button', {
          staticClass: "button-white",
          on: {
            "click": _vm.prev
          }
        }, [_vm._v(" " + _vm._s(_vm.tt('이전으로')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('h1', [_vm._v(_vm._s(_vm.tt('이대로 주문 하시겠습니까?')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('라이선스 없이 주문할 경우 할인적용을 받아볼 수 없습니다.<br />(라이선스 구매시 최대 20% 할인)'))
    }
  }), _c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.tt('구분')))]), _c('th', [_vm._v(_vm._s(_vm.tt('할인가')))]), _c('th', [_vm._v(_vm._s(_vm.tt('정상 판매가')))])])]), ((_vm$items = _vm.items) === null || _vm$items === void 0 ? void 0 : _vm$items.length) > 0 ? _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.tt('결제 예정금액')))]), _c('td', {
    staticStyle: {
      "text-decoration": "line-through",
      "color": "#ff3b31"
    }
  }, [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(_vm.expectedSalePayPrice))))]), _c('td', [_c('b', [_vm._v(_vm._s(_vm.tt('${1}원', _vm.formatNumberLocale(_vm.expectedNormalPayPrice))))])])])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }