var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', [_c('button', {
    staticClass: "checkbox",
    "class": {
      active: _vm.isClick
    },
    on: {
      "click": _vm.checkBoxClick
    }
  })]), _vm.headers[0].value ? _c('td', {
    on: {
      "click": function click($event) {
        return _vm.detail(_vm.item);
      }
    }
  }, [_c('div', {
    staticClass: "state",
    "class": _vm.item.status != 'OK' ? 'warning' : ''
  }, [_c('span', {
    staticStyle: {
      "display": "inline-flex",
      "align-items": "center"
    }
  }, [_vm.item.status == 'OK' ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-safe.svg"),
      "alt": "상태icon"
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/svg/health-warning.svg"),
      "alt": "상태icon"
    }
  })]), _c('p', {
    domProps: {
      "textContent": _vm._s(_vm.item.status == 'OK' ? _vm.tt('안전') : _vm.tt('위험'))
    }
  })])]) : _vm._e(), _vm.headers[1].value ? _c('td', {
    on: {
      "click": function click($event) {
        return _vm.detail(_vm.item);
      }
    }
  }, [_c('div', {
    staticClass: "info"
  }, [_c('img', {
    attrs: {
      "src": _vm.item.user.image ? _vm.item.user.image : require('@/assets/svg/member-photo-default.svg')
    }
  }), _c('div', [_c('h6', [_vm._v(_vm._s(_vm.item.user.name))]), _c('p', [_vm._v(_vm._s(_vm.item.user.email))])])])]) : _vm._e(), _vm.headers[2].value ? _c('td', {
    on: {
      "click": function click($event) {
        return _vm.detail(_vm.item);
      }
    }
  }, [_vm.item.groupList.length == 1 ? _c('span', [_vm._v(_vm._s(_vm.item.groupList[0].name))]) : _vm.item.groupList.length > 1 ? _c('span', [_vm._v(_vm._s(_vm.item.groupList[0].name) + " " + _vm._s(_vm.tt('외')) + " " + _vm._s(_vm.item.groupList.length - 1) + " " + _vm._s(_vm.tt('개 그룹')))]) : _vm._e()]) : _vm._e(), _vm.headers[3].value ? _c('td', {
    on: {
      "click": function click($event) {
        return _vm.detail(_vm.item);
      }
    }
  }, [_c('p', {
    "class": _vm.item.lastDiagDate ? '' : 'nodata-txt',
    domProps: {
      "textContent": _vm._s(_vm.item.lastDiagDate ? _vm.formatDate(_vm.item.lastDiagDate) : _vm.tt('정보없음'))
    }
  })]) : _vm._e(), _vm.headers[4].value ? _c('td', {
    on: {
      "click": function click($event) {
        return _vm.detail(_vm.item);
      }
    }
  }, [!_vm.item.nextDiagDate ? _c('p', {
    staticClass: "nodata-txt",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v("정보없음")]) : _vm._e(), _vm.item.status == 'OK' && _vm.item.nextDiagDate ? _c('p', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_vm._v(_vm._s(_vm.tt('${1}까지', _vm.formatDate(_vm.item.nextDiagDate))))]) : _vm._e(), _vm.item.status == 'DANGER' && _vm.item.nextDiagDate ? _c('p', {
    staticClass: "error"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-error.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('${1}까지', _vm.formatDate(_vm.item.nextDiagDate)))), _vm.progessDate(_vm.item.nextDiagDate) > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.tt('${1}일 경과', _vm.progessDate(_vm.item.nextDiagDate))) + ")")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.headers[5].value && _vm.userPermission.safe && _vm.userPermission.safe.diagnosis.create ? _c('td', [_c('button', {
    staticClass: "btn input-checkup",
    on: {
      "click": _vm.showInput
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon-edit.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('검진일 입력')))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }