import { render, staticRenderFns } from "./DraftRegistrationStep1.vue?vue&type=template&id=61bc74bc&scoped=true&"
import script from "./DraftRegistrationStep1.vue?vue&type=script&lang=js&"
export * from "./DraftRegistrationStep1.vue?vue&type=script&lang=js&"
import style0 from "./DraftRegistrationStep1.vue?vue&type=style&index=0&id=61bc74bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bc74bc",
  null
  
)

export default component.exports