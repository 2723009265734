var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.tt('기안')))])]), _c('div', {
    staticClass: "draft-info"
  }, [_c('b', [_vm._v(_vm._s(_vm.tt('기안')))]), _c('p', [_c('span', [_vm._v(_vm._s(_vm.user.name))]), _c('span', [_vm._v(_vm._s(_vm.groupName))])])])]), _c('div', {
    staticClass: "content"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.tt('결재자')))])]), _c('draggable', _vm._b({
    attrs: {
      "draggable": ".approval-info"
    },
    on: {
      "start": function start($event) {
        _vm.isDragging = true;
      },
      "end": function end($event) {
        _vm.isDragging = false;
      }
    },
    model: {
      value: _vm.selectedItems,
      callback: function callback($$v) {
        _vm.selectedItems = $$v;
      },
      expression: "selectedItems"
    }
  }, 'draggable', _vm.dragOptions, false), [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": "flip-list"
    }
  }, _vm._l(_vm.selectedItems, function (member, index) {
    return _c('div', {
      key: "member-".concat(index),
      staticClass: "approval-info"
    }, [_c('img', {
      staticClass: "item-handle",
      attrs: {
        "src": require('@/assets/svg/purchase/Move.svg')
      }
    }), _c('b', [_vm._v(_vm._s(_vm.tt('결재자')))]), _c('p', [_c('span', [_vm._v(_vm._s(member.name))]), _c('span', [_vm._v(_vm._s(member.parent.name))])]), _c('img', {
      attrs: {
        "src": require('@/assets/svg/Trash.svg')
      },
      on: {
        "click": function click($event) {
          return _vm.deleteMember(index);
        }
      }
    })]);
  }), 0)], 1), _vm.selectedItems.length === 0 ? _c('div', {
    staticClass: "no-data",
    "class": {
      error: !_vm.isValid
    }
  }, [_c('p', [_c('span', [_vm._v(_vm._s(_vm.tt('결재선을 설정해 주세요')))])])]) : _vm._e()], 1), _c('div', {
    staticClass: "footer"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.footerText)
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }