var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('b-modal', {
    ref: "modal",
    attrs: {
      "size": "sm",
      "hide-header": "",
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    }
  }, [_vm.item.image ? _c('img', {
    "class": {
      canClick: _vm.item.url != null
    },
    attrs: {
      "src": _vm.item.image
    },
    on: {
      "click": _vm.moveToUrl
    }
  }) : _c('div', {
    staticClass: "popup-wrapper"
  }, [_c('img', {
    staticClass: "icon-notice",
    attrs: {
      "src": require("@/assets/images/icon-main-popup.png")
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.item.title))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.item.content)
    }
  }), _c('img', {
    staticClass: "btn-notice",
    "class": {
      canClick: _vm.item.url != null
    },
    attrs: {
      "src": require("@/assets/images/btn-main-popup.png")
    },
    on: {
      "click": _vm.moveToUrl
    }
  })]), _c('div', {
    staticClass: "btn-footer"
  }, [_c('button', {
    on: {
      "click": _vm.clickDonwShow
    }
  }, [_vm._v(_vm._s(_vm.tt('일주일간 보지 않기')))]), _c('button', {
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(_vm._s(_vm.tt('닫기')))])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }