var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "detail-wrapper"
  }, [_c('div', {
    staticClass: "common-header-wrapper"
  }, [_c('div', [_c('h2', [_vm._v(_vm._s(_vm.tt('지출결의서 작성')))])])]), _vm._l(_vm.componentList, function (component, index) {
    return _c('div', {
      key: index,
      staticClass: "common-detail-wrapper",
      "class": {
        hide: _vm.step !== index + 1
      }
    }, [_vm.step === index + 1 ? _c(component, {
      ref: "draft-registration",
      refInFor: true,
      tag: "component"
    }) : _vm._e()], 1);
  }), _c('div', {
    staticClass: "common-detail-bottom"
  }, [_c('base-button', {
    attrs: {
      "size": "lg",
      "color": 'primary only'
    },
    on: {
      "click": _vm.prev
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.prevText)))]), _c('base-button', {
    attrs: {
      "size": "lg",
      "color": 'primary'
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.nextText)))])], 1), _c('modal-confirm', {
    ref: "modal-out",
    attrs: {
      "title": "페이지 이동",
      "confirmText": _vm.step == 1 ? '나가기' : '이전으로',
      "content": "페이지를 이동하면 지금까지 작성한 내용이 사라집니다.<br>페이지를 이동하시겠습니까?"
    }
  }), _c('modal-confirm', {
    ref: "modal-store",
    attrs: {
      "title": "랩매니저 스토어",
      "confirmText": "랩매니저 스토어로 가기",
      "content": "랩매니저 스토어 이용 기록이 없는 기관입니다.<br>랩매니저 스토어에서 기관 설정을해주세요"
    }
  }), _c('modal-confirm', {
    ref: "modal-can-order",
    attrs: {
      "title": "지출결의서 작성 불가",
      "confirmText": "확인",
      "variant": "warning",
      "content": "지출결의서에 배송 가능한 상품이 없습니다.<br>상품을 다시 한번 확인해주세요."
    }
  }), _c('modal-confirm', {
    ref: "modal-budget-over",
    attrs: {
      "title": "예산 초과",
      "confirmText": "상신",
      "content": "지출 예정금액이 예산 잔여금액을 초과하였습니다.<br>정말로 지출결의서를 상신하시겠습니까?"
    }
  }), _c('modal-payment-requirement', {
    on: {
      "ok": _vm.next
    }
  }), _c('modal-remain-later-pay', {
    ref: "modal-remain-later-pay"
  }), _c('ModalLicenseAndSale', {
    ref: "ModalLicenseAndSale",
    on: {
      "purchaseWithoutSale": _vm.purchaseWithoutSale,
      "purchaseConfirm": _vm.next
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }