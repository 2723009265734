<template>
	<div class="modals">
		<modal-license-management
			ref="modal-management-license"
			:isShow="displayModalManagementLicense"
			:license="license"
			:userPermission="userPermission"
			:memberCount="instInfo.allMemberCount"
			@close="displayModalManagementLicense = false"
			@purchase="showModalPayment"
			@changeCard="displayModalChangeCard = true"
		></modal-license-management>

		<!-- modal-license-payment : 라이선스 구매, 추가 모달 -->
		<modal-license-payment
			:isShow="displayModalPayment"
			:mode="modalPaymentMode"
			:license="license"
			:userPermission="userPermission"
			:instInfo="instInfo"
			:isAdditionMode="isAdditionMode"
			@close="displayModalPayment = false"
			@registCard="showModalRegistCard"
			@ebill="showEbill"
			@complete="showCompletePayment"
			:isSubscribePurchaseMode="isSubscribePurchaseMode"
			@showModalAuthEdu="showModalAuthEducation"
			ref="license-payment"
		>
		</modal-license-payment>

		<!-- modal-license-payment-ebill : 전자세금계산서 정보 입력 모달 -->
		<modal-license-payment-ebill
			:isShow="displayModalEbill"
			:isAdditionMode="isAdditionMode"
			@close="displayModalEbill = false"
			@complete="showCompletePayment"
		>
		</modal-license-payment-ebill>

		<!-- modal-license-payment-ebill : 거래명세서 정보 입력 모달 -->
		<modal-license-invoice :isShow="displayModalInvoice" @close="displayModalInvoice = false" @complete="offBackModal"> </modal-license-invoice>

		<!-- modal-registering-card : 카드 정보 입력 모달 (결제카드 추가시)-->
		<modal-regist-card mode="REGIST" :isShow="displayModalRegistCard" @close="displayModalRegistCard = false" @complete="showCompletePayment">
		</modal-regist-card>

		<!-- modal-registering-card (CHANGE) : 카드 에러시 사용하는 카드 편집 모달 -->
		<modal-regist-card
			mode="CHANGE"
			:isShow="displayModalChangeCard"
			@close="displayModalChangeCard = false"
			@complete="showModalPurchase"
			@showManageSubscribe="showModalErrorManageSubscribe"
			@showModalPurchase="showModalPayment('buying')"
		>
		</modal-regist-card>

		<!-- modal-complete-license-payment : 결제 완료 정보 모달 -->
		<modal-complete-license-payment
			:isShow="displayModalCompletePayment"
			:isAdditionMode="isAdditionMode"
			:completeText="completeText"
			@close="closeCompleteLicensePayment"
			@showConfirmSubscribeModal="displayModalConfirmSubscribe = true"
		>
		</modal-complete-license-payment>
		<modal-common-confirm
			:isShow="displayModalConfirmSubscribe"
			:title="tt('정기결제 관리')"
			:content="tt('라이선스 수가 변경되었습니다.<br>정기결제 정보를 업데이트하시겠습니까?')"
			:buttonText="tt('설정하러 가기')"
			@close="displayModalConfirmSubscribe = false"
			@confirm="showModalManageSubscribe"
		></modal-common-confirm>
		<modal-license-auth-education
			:isShow="displayModalAuthEducation"
			@purchase="purchasePlanEdu"
			@close="displayModalAuthEducation = false"
		></modal-license-auth-education>
		<modal-common ref="modal-common"></modal-common>
	</div>
</template>
<script>
import ModalLicenseManagement from '@/views/institute/information/modals/ModalLicenseManagement.vue'
import ModalLicensePayment from '@/views/institute/information/modals/ModalLicensePayment.vue'
import ModalRegistCard from '@/views/institute/information/modals/ModalRegistCard.vue'
import ModalCompleteLicensePayment from '@/views/institute/information/modals/ModalCompleteLicensePayment.vue'
import ModalLicensePaymentEbill from '@/views/institute/information/modals/ModalLicensePaymentEbill.vue'
import ModalLicenseInvoice from '@/views/institute/information/modals/ModalLicenseInvoice.vue'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
import MixinLicense from '@/mixins/license'
import MixinPayment from '@/mixins/payment'
import ModalCommonConfirm from '@/views/common/components/modals/ModalCommonConfirm.vue'
import ModalLicenseAuthEducation from '@/views/institute/information/modals/ModalLicenseAuthEducation.vue'
export default {
	components: {
		ModalLicenseManagement,
		ModalLicensePayment,
		ModalRegistCard,
		ModalLicensePaymentEbill,
		ModalCompleteLicensePayment,
		ModalCommon,
		ModalCommonConfirm,
		ModalLicenseAuthEducation,
		ModalLicenseInvoice,
	},
	mixins: [MixinLicense, MixinPayment],
	data() {
		return {
			completeText: '확인',
			displayModalManagementLicense: false,
			displayModalPayment: false,
			displayModalRegularPayment: false,
			modalPaymentMode: null,
			displayModalEbill: false,
			displayModalCompletePayment: false,
			displayModalRegistCard: false,
			displayModalConfirmSubscribe: false,
			displayModalChangeCard: false,
			isSubscribePurchaseMode: false,
			displayModalAuthEducation: false,
			isAdditionMode: false,
			displayModalInvoice: false,
			confirmFn: null,
		}
	},
	methods: {
		setSubscribePurchaseMode(value) {
			this.isSubscribePurchaseMode = value
		},
		showModalManagement() {
			this.displayModalManagementLicense = true
		},
		hideModalManagement() {
			this.displayModalManagementLicense = false
		},
		showModalErrorManageSubscribe() {
			this.isSubscribePurchaseMode = true
			this.showModalPayment('management')
		},
		showModalRegistCard() {
			this.displayModalRegistCard = true
		},
		showRegiNoti() {
			this.$refs['modal-common'].show('결제카드 등록', '정기결제를 설정하기 위해서는 먼저 결제카드를 등록해야합니다.', 'complete', '등록')
		},
		showModalPayment(mode) {
			if (this.isLicenseDepositWait) {
				this.$root.toast('라이선스 구매', '입금 대기 중인 구매 건이 있습니다.', 'error')
				return
			}
			this.displayModalPayment = true
			this.modalPaymentMode = mode
			if (mode === 'add') {
				this.isAdditionMode = true
			}
		},
		showInvoice() {
			this.displayModalInvoice = true
		},
		showEbill() {
			this.displayModalEbill = true
		},
		showCompletePayment(isAdditionMode) {
			this.offBackModal()
			this.displayModalCompletePayment = true
			this.isAdditionMode = isAdditionMode
		},
		offBackModal() {
			this.displayModalManagementLicense = false
			this.displayModalEbill = false
			this.displayModalInvoice = false
			this.displayModalPayment = false
			this.displayLicenseModal = false
			this.displayModalAuthEducation = false
		},
		showModalManageSubscribe() {
			this.modalPaymentMode = 'management'
			this.displayModalPayment = true
		},
		showModalPurchase(confirmFn, completeText) {
			if (this.isLicenseDepositWait) {
				this.$root.toast('라이선스 구매', '입금 대기 중인 구매 건이 있습니다.', 'error')
				return
			}
			this.completeText = completeText || '확인'
			this.confirmFn = confirmFn
			this.modalPaymentMode = 'buying'
			this.displayModalPayment = true
		},
		showModalAuthEducation() {
			this.displayModalAuthEducation = true
		},
		purchasePlanEdu() {
			this.$refs['license-payment'].purchaseCommon()
		},
		showToastNeedCert() {
			console.log(this.license.type, this.instInfo)
			if (this.isNotCertEdu) {
				const title = this.tt('인증자료 제출 필요')
				const content = this.tt('인증자료를 제출해주세요')
				this.$root.toast(title, content, ToastType.WARNING)
			}
		},
		closeCompleteLicensePayment() {
			this.displayModalCompletePayment = false
			this.isAdditionMode = false
			if (this.confirmFn) this.confirmFn()
			this.offBackModal()
			this.$emit('closeComplete')
			this.confirmFn = null
			this.completeText = '확인'
		},
	},
}
</script>
