<template>
	<div class="select-table-wrapper">
		<table class="select-table">
			<tr class="select-table-header">
				<th width="633px">
					<span>{{ tt('상품 정보') }}</span>
				</th>
				<th width="100px">
					<span>{{ tt('수량') }}</span>
				</th>
				<th width="135px">
					<span>{{ tt('상품금액') }}</span>
				</th>
				<th width="130px">
					<span>{{ tt('배송비') }}</span>
				</th>
			</tr>
			<tr class="select-table-body" v-for="(product, index) in productInfoList" :key="`product_${index}`">
				<td>
					<option-info :product="product" :useSale="useSale"></option-info>
				</td>
				<td>
					<div>
						<div>{{ product.quantity }}</div>
					</div>
				</td>
				<td>
					<div>
						<div>
							<b>{{ tt('${1}원', formatNumberLocale(product.sellingPrice)) }}</b>
						</div>
						<div v-if="product.discountPrice > 0" style="text-decoration: line-through; color: #999">
							{{ tt('${1}원', formatNumberLocale(product.unitPrice * product.quantity)) }}
						</div>
					</div>
				</td>
				<td>
					<div>
						<div>{{ tt('${1}원', formatNumberLocale(product.deliveryFee ? product.deliveryFee : 0)) }}</div>
					</div>
				</td>
			</tr>
		</table>
		<product-info-price-table
			:total="totalPrice"
			:deliveryFee="totalDeliveryFee"
			:sale="totalSalePrice"
			:vatPrice="vatPrice"
		></product-info-price-table>
	</div>
</template>

<script>
// TODO: 공용 컴포넌트화?
import ProductInfoPriceTable from './ProductInfoPriceTable.vue'
import OptionInfo from './OptionInfo'

export default {
	components: {
		ProductInfoPriceTable,
		OptionInfo,
	},
	props: {
		productInfoList: {
			type: Array,
			default: () => {},
		},
		useSale: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		// TODO: 할인에 대한 정의가 되어있지않아서, 임시로 데이터 막 넣음
		// TODO: 배송비에 대한 정의가 되어있지않아서, 임시로 데이터 막 넣음
		totalPrice() {
			let price = 0
			this.productInfoList?.forEach(product => {
				price += product.unitPrice * product.quantity
			})
			return price
		},
		totalDeliveryFee() {
			let price = 0
			// this.productInfoList?.forEach(product => {
			// 	price += product.deliveryFee * product.count
			// })
			return price
		},
		totalSalePrice() {
			let price = 0
			this.productInfoList?.forEach(product => {
				if (product.discountPrice) price += Number(product.discountPrice)
			})
			return price
		},
		vatPrice() {
			return (this.totalPrice - this.totalSalePrice) / 10
		},
	},
}
</script>

<style lang="scss" scoped>
.select-table-wrapper {
	display: flex;
	flex-direction: column;
	border: 1px solid $INPUT;
	border-radius: 4px;
	table {
		border-collapse: collapse;
		.select-table-header {
			background: $GREY_2;
			th:first-child {
				padding-left: 20px;
				text-align: left;
			}
			th {
				padding-top: 5px;
				padding-bottom: 5px;
				color: $SECONDARY_2;
				text-align: center;
			}
		}
		.select-table-body {
			border-top: 1px solid $INPUT;
			td {
				padding-top: 20px;
				padding-bottom: 20px;
				height: 100%;
				border-right: 1px solid $INPUT;
				text-align: center;
			}
			td:first-child {
				padding-left: 20px;
				text-align: left;
			}
			td:last-child {
				border-right: none;
			}
		}
	}
}
</style>
