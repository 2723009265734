<template>
	<div v-if="isShow" class="modal-global-filter" v-click-outside="hide">
		<div class="header">
			<h6>{{ tt('조회 범위') }}</h6>
			<div class="right-btn-group">
				<button class="reset" @click="reset">
					<img src="@/assets/svg/reset.svg" />
				</button>
				<button class="close" @click="hide">
					<img src="@/assets/svg/popup-close.svg" alt="close" />
				</button>
			</div>
		</div>
		<div class="search-wrapper">
			<search-box class="search-box" noRecent :value="keyword" :placeholder="tt('검색')" @change="setKeyword"></search-box>
		</div>
		<div class="access-switch-wrapper">
			<h6>{{ tt('접근권한이 있는 항목만 보기') }}</h6>
			<label class="switch">
				<input type="checkbox" :checked="isAccessible" @change="setAccessible(!isAccessible)" />
				<span class="slider round"></span>
			</label>
		</div>
		<div class="folding-wrapper">
			<div @click="setTreeOpen(true)"><img src="@/assets/svg/icon-unfold.svg" />{{ tt('전체 펼치기') }}</div>
			<span class="br"></span>
			<div @click="setTreeOpen(false)"><img src="@/assets/svg/icon-fold.svg" />{{ tt('전체 접기') }}</div>
		</div>
		<div class="tree-wrapper" v-if="list.length > 0">
			<div>
				<global-filter-node v-for="node in list" :key="node.id" :node="node" type="location"></global-filter-node>
			</div>
		</div>
		<div class="loading-wrapper" v-else-if="list.length == 0 && keyword.length == 0">
			<lottie-animation
				class="loading"
				ref="lottie-animation"
				:animationData="require('@/assets/json/lm_loading_lottie.json')"
				:loop="true"
			></lottie-animation>
		</div>
		<div class="footer">
			<div class="selected-wrapper">
				{{ selectedText }}
			</div>
			<div class="buttons">
				<div class="right-btn-group">
					<button class="btn Btn__close" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="btn Btn__complete" :disabled="checkedId.length == 0" @click="confirm">
						{{ tt('적용') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import LottieAnimation from 'lottie-web-vue'
import SearchBox from '@/views/components/searchBox'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import GlobalFilterNode from '../tree/gen2/components/GlobalFilterNode'
export default {
	components: {
		SearchBox,
		GlobalFilterNode,
		LottieAnimation,
	},
	data() {
		return {
			isShow: false,
		}
	},
	computed: {
		...mapState('userPermission', ['userPermission']),
		...mapState('tree/globalFilter', ['keyword', 'isAccessible', 'checkedId', 'storageCount', 'list']),
		selectedText() {
			if (this.checkedId.length == this.storageCount) {
				if (this.userPermission.storage && this.userPermission.storage.read) {
					return this.tt('기관 내 모든 보관함이 선택되었습니다')
				} else {
					return this.tt('${1}개 보관함이 선택되었습니다', this.checkedId.length) + '(All)'
				}
			} else {
				if (this.checkedId.length > 0) return this.tt('${1}개 보관함', this.checkedId.length)
				else return this.tt('조회 범위를 선택해주세요')
			}
		},
	},
	methods: {
		...mapMutations('tree/globalFilter', ['setKeyword', 'setCheckedIds', 'resetFilter', 'setTreeOpen']),
		...mapActions('tree/globalFilter', ['getLocationStorageTree', 'setAccessible', 'getCheckedId']),
		async show() {
			this.isShow = true
			if (this.$refs['lottie-animation']) this.$refs['lottie-animation'].anim.play()
			await this.getLocationStorageTree()
			this.getCheckedId()
			if (this.$refs['lottie-animation']) this.$refs['lottie-animation'].anim.stop()
		},
		hide() {
			this.isShow = false
		},
		toggle() {
			if (this.isShow) this.hide()
			else this.show()
		},
		async reset() {
			this.resetFilter()
			await this.getLocationStorageTree()
		},
		async confirm() {
			this.setCheckedIds()
			this.$router.go()
		},
	},
}
</script>
<style lang="scss" scoped>
.modal-global-filter {
	position: absolute;
	left: 220px;
	top: 40px;
	min-width: 330px;
	max-width: 500px;
	height: 730px;
	border: 1px solid #ccc;
	background-color: white;
	border-radius: 4px;
	z-index: 1000;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	.header,
	.access-switch-wrapper {
		display: flex;
		align-items: center;
		height: 50px;
		border-bottom: 1px solid #e8e8e8;
		padding: 0px 15px;
		h6 {
			font-size: 16px;
			font-weight: 700;
		}
		&.access-switch-wrapper {
			h6 {
				font-size: 14px;
			}
			.switch {
				margin: 0px;
				margin-left: auto;
			}
		}
	}
	.search-wrapper {
		background-color: #fcfaf9;
		padding: 10px 15px;
		border-bottom: 1px solid #e8e8e8;
		.search-box {
			width: 100%;
		}
	}
	.folding-wrapper {
		padding: 10px 15px;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 14px;
		color: $DEFAULT;
		border-bottom: 1px solid #e8e8e8;
		div {
			&:hover {
				background-color: $GREY_2;
			}
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 6px;
		}
		.br {
			display: block;
			width: 1px;
			height: 20px;
			background-color: $DIVIDER;
		}
	}
	.tree-wrapper {
		height: calc(100% - 272px);
		overflow: auto;
		& > div {
			margin-left: -15px;
		}
	}
	.loading-wrapper {
		height: 400px;
		display: flex;
		align-items: center;
		justify-content: center;
		.loading {
			width: 180px;
		}
	}
	.footer {
		position: absolute;
		bottom: 0px;
		width: 100%;
		.selected-wrapper {
			background-color: #fcfaf9;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			color: #000;
			border-bottom: 1px solid #e8e8e8;
			border-top: 1px solid #e8e8e8;
		}
		.buttons {
			padding: 0px 15px;
			display: flex;
			align-items: center;
			height: 50px;
			button {
				width: 100px;
				justify-content: center;
			}
		}
	}
}
</style>
