<template>
	<form-select-sticker
		ref="form-select-sticker"
		:category="category"
		:item="item"
		:isGhs="isGhs"
		:isRequireGhs="isRequireGhs"
		@change="setStickerType"
		@completeGHS="completeGHS"
		@cancelGHSQR="setStickerType(null)"
	></form-select-sticker>
</template>
<script>
import FormSelectSticker from '@/views/common/components/form/FormSelectSticker.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	name: 'ItemFormSelectSticker',
	components: { FormSelectSticker },
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/form', ['item', 'registMethod', 'category', 'itemFormDataByDirect', 'isRequireGhs', 'ghs', 'itemFormType']),
		...mapGetters('modal/ghs', ['ghsData']),
		isGhs() {
			return !!this?.ghs
		},
		isTypeEdit() {
			return this.itemFormType === 'NORMAL_EDIT'
		},
		ghsPayload() {
			return this.item?.brand?.id && this.item?.productCode
				? {
						brandId: this.item?.brand?.id,
						productCode: this.item?.productCode,
						inventoryId: this.item?.id,
						ghs: this.ghsData,
				  }
				: {
						inventoryId: this.item?.id,
						ghs: this.ghsData,
				  }
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/form', ['setFormItem', 'setGhs', 'setHasChangedStickerType']),
		...mapActions('inventoryGen2/item', ['getDetail']),
		...mapActions('modal/ghs', ['uploadGhsLabel']),
		setStickerType(type) {
			this.setFormItem({ ...this.item, stickerType: type })
			this.setHasChangedStickerType(true)
		},
		async completeGHS(ghs) {
			this.setGhs(ghs)
			if (this.isTypeEdit) {
				this.setFormItem({ ...this.item, ghs: ghs })
				await this.uploadGhsLabel(this.ghsPayload)
				await this.getDetail(this.item.id)
			} else {
				this.setFormItem({ ...this.item, stickerType: 'GHS+QR' })
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
