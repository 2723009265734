<template>
	<div class="header-wrapper">
		<div class="title-area">
			<h2 class="title">
				{{ tt(title) }}
			</h2>
			<div class="subtitle">
				<b :class="[item.store.paymentStatus, isCancelOrReturn]">{{ tt(statusKor) }}</b>
				<p v-html="subtitle"></p>
			</div>
		</div>
		<div class="btn-area">
			<div v-if="orderId">
				<base-button @click="click('STORE_ORDER')">{{ tt('주문 상세보기') }}</base-button>
				<base-button v-if="canPay" @click="click('STORE_PAYMENT')" color="primary">{{ tt('결제하기') }}</base-button>
				<base-button v-else color="cant" v-b-tooltip.hover="tt(tooltipReason ? tooltipReason : '')">{{ tt('결제하기') }}</base-button>
			</div>
		</div>
	</div>
</template>

<script>
import { paymentStatus } from '@/utils/define/StatusCode'
export default {
	components: {},
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		status: {
			type: String,
			default: '',
		},
		orderId: {
			type: Number,
		},
		canPay: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			default: null,
		},
	},
	computed: {
		isCancelOrReturn() {
			return this.item.store.cancelStatus == 'DONE' || this.item.store.returnStatus == 'DONE' ? 'CANCEL' : ''
		},
		statusKor() {
			if (this.item.store.cancelStatus == 'DONE') return '주문취소'
			else if (this.item.store.returnStatus == 'DONE') return '반품완료'
			return paymentStatus[this.item.store.paymentStatus]
		},
		tooltipReason() {
			if (this.item.store.paymentStatus == 'PAID') return '이미 결제완료된 상품입니다'
			else if (this.item.store.cancelStatus == 'ING') return '주문취소 접수중인 상품은 결제할 수 없어요'
			else if (this.item.store.cancelStatus == 'DONE') return '주문취소 완료된 상품은 결제할 수 없어요'
			else if (this.item.store.returnStatus == 'ING') return '교환/반품 접수중인 상품은 결제할 수 없어요'
			else if (this.item.store.returnStatus == 'DONE') return '반품 완료된 상품은 결제할 수 없어요'
			else if (this.item.store.paymentStatus == 'CANCEL') return '결제취소된 상품입니다'
			else if (this.item.store.paymentStatus == 'WAIT') return '입금대기중인 상품입니다'
			else if (this.item.store.status == 'WAIT') return '판매자 주문승인 전 상품은 결제할 수 없어요'
			return null
		},
	},
	methods: {
		click(params) {
			this.$emit('click', params)
		},
	},
}
</script>

<style lang="scss" scoped>
.header-wrapper {
	width: 100%;
	padding: 8px 30px;
	min-height: 54px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
	border-bottom: 1px solid $DIVIDER;
	top: 60px;
	background: white;
	z-index: 200;
	.title {
		font-size: 1.5rem;
		font-weight: bold;
		color: #000;
		text-align: left;
		align-items: center;
		height: auto;
	}
	.subtitle {
		display: flex;
		font-size: 0.75rem;
		color: $SECONDARY_2;
		margin-top: 6px;
		b {
			height: 20px;
			padding: 0 5px;
			border-radius: 5px;
			font-weight: 700;
			text-align: center;
			display: inline-block;
			margin-right: 10px;
			background-color: $DIVIDER;
			color: $SECONDARY_2;
			&.ACCEPT,
			&.PAID {
				background-color: rgba(13, 197, 119, 0.15);
				color: $GREEN;
			}
			&.REJECT,
			&.CANCEL {
				background-color: $COLOR_BACKGROUND_RED;
				color: $RED;
			}
		}
		p {
			span {
				font-weight: 400;
				color: $SECONDARY_2;
				padding-right: 16px;
				position: relative;
				&:nth-last-child(2) {
					&:before {
						width: 1px;
						height: 12px;
						background-color: $DIVIDER;
						position: absolute;
						top: 4px;
						right: 8px;
						content: '';
					}
				}
			}
		}
		h2 {
			font-weight: 400;
		}
	}
	.btn-area {
		& > div {
			display: flex;
			gap: 20px;
			button {
				height: 36px;
				border-radius: 5px;
				min-width: 80px;
			}
		}
	}
}
::v-deep.cant {
	opacity: 0.2;
}
</style>
