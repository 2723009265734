var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('modal-footer', {
          on: {
            "cancel": _vm.hide,
            "complete": _vm.clickComplete
          }
        })];
      },
      proxy: true
    }])
  }, [[_vm.item ? _c('item-form-select-sticker') : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }