var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-fold-management', {
    ref: "form-fold-management",
    attrs: {
      "title": "관리정보",
      "categoryInfo": _vm.categoryInfo,
      "memberList": _vm.getMemberList.members,
      "productInfo": _vm.item,
      "expirationDay": _vm.expirationDay,
      "isManagementValid": _vm.isManagementValid,
      "isUnitValid": _vm.isManagementUnitValid,
      "isShowError": _vm.showError
    },
    on: {
      "change": _vm.changeFormManagement,
      "removeImg": _vm.removeImage
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }