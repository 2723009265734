var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "email-text-area-container"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "title-text",
    on: {
      "click": _vm.getEmailList
    }
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "delete-text",
    style: {
      color: _vm.isEmptyEmailList ? '#999999' : null
    },
    on: {
      "click": _vm.onClickClearMailList
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('모두 지우기')) + " ")])]), _c('div', {
    staticClass: "contents",
    "class": {
      error: _vm.isError
    },
    style: {
      minHeight: "".concat(_vm.contentMinHeight, "px")
    },
    on: {
      "click": _vm.onClickContentsArea
    }
  }, [_vm._l(_vm.completedEmailList, function (completedEmail, index) {
    return _c('complete-text', {
      key: index,
      ref: "completedEmailText",
      refInFor: true,
      attrs: {
        "keyValue": completedEmail.key
      },
      on: {
        "delete": _vm.onDeleteCompleteEmail
      }
    }, [_vm._v(_vm._s(completedEmail.value) + " ")]);
  }), _c('textarea', {
    staticClass: "email-textarea",
    attrs: {
      "wrap": "off",
      "autofocus": "",
      "placeholder": _vm.completedEmailList.length === 0 ? _vm.tt(_vm.placeholder) : null
    },
    on: {
      "keypress": _vm.onKeyPressTextArea,
      "keydown": _vm.onKeydownTextArea,
      "input": _vm.onInputTextArea
    }
  })], 2), _c('div', {
    staticClass: "bottom"
  }, [!_vm.isNotEmptyEmailList ? [_c('div', {
    staticClass: "error-text-area"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Error1.svg"),
      "alt": "error"
    }
  }), _c('span', {
    staticClass: "error-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('이메일을 입력해주세요')) + " ")])])] : _vm._e(), !_vm.isValidEmailList ? [_c('div', {
    staticClass: "error-text-area"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Error1.svg"),
      "alt": "error"
    }
  }), _c('span', {
    staticClass: "error-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('유효한 이메일을 입력해주세요')) + " ")])])] : _vm._e(), !_vm.isUnderMaxSize ? [_c('div', {
    staticClass: "error-text-area"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Error1.svg"),
      "alt": "error"
    }
  }), _c('span', {
    staticClass: "error-text"
  }, [_vm._v(" " + _vm._s(_vm.tt('이메일 초대는 한번에 최대 30개까지 가능합니다')) + " ")])])] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }