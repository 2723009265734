var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "requireInfo"
  }, [_c('div', {
    staticClass: "boxwrap"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/" + _vm.icon)
    }
  }), _c('h2', [_vm.groupName && _vm.progressStep > 2 ? _c('span', [_vm._v(_vm._s(_vm.groupName))]) : _vm._e(), _vm._v(_vm._s(_vm.tt(_vm.mainText)) + " ")]), _c('h3', [_vm._v(_vm._s(_vm.tt(_vm.subText)))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }