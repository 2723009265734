var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lm-button-wrapper"
  }, [_c('button', {
    staticClass: "lm-button",
    "class": _vm.color,
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.text))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }