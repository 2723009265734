<template>
	<div class="detail-wrap" v-if="item">
		<detail-header :item="item"></detail-header>
		<div class="product-info-wrap">
			<p class="description" v-if="isIng">
				{{ tt('AI 기술을 통한 시약정보 자동보정기술과 전문 검수원의 철저한 검수를 통해 정확한 정보로 물품을 등록해드립니다') }}
			</p>
			<ul class="description" v-if="isFail">
				<li class="fail-memo" v-for="(memo, idx) in failMemos" :key="idx">
					{{ memo }}
				</li>
			</ul>
			<member-info :item="item"></member-info>
			<image-field v-if="item.registerMethod === 'AUTO_IMAGE' && item.imageList" :images="item.imageList.map(i => i.link)"></image-field>
			<product-info :item="item"></product-info>
			<management-info :item="item" :managementFields="managementFields"></management-info>
			<custom-info v-if="hasCustomField" :item="item" :customFields="customFields" :customFieldsData="customFieldsData"></custom-info>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DetailHeader from '@/views/inventoryGen2/components/waitDetail/DetailHeader.vue'
import MemberInfo from '@/views/purchase/cargo/components/cargoDetail/productInfo/memberInfo/MemberInfo.vue'
import ProductInfo from '@/views/inventoryGen2/components/waitDetail/productInfo/ProductInfo.vue'
import ImageField from '@/views/inventoryGen2/components/waitDetail/ImageField.vue'
import ManagementInfo from '@/views/inventoryGen2/components/waitDetail/managementInfo/ManagementInfo.vue'
import CustomInfo from '@/views/inventoryGen2/components/detail/customInfo/index.vue'

export default {
	name: 'WaitingDetail',
	components: { DetailHeader, MemberInfo, ProductInfo, ImageField, ManagementInfo, CustomInfo },
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/item', ['item', 'categoryInfo', 'customFields', 'customFieldsData']),
		managementFields() {
			return this.categoryInfo?.fields?.managementFields
		},
		title() {
			return this.isIng ? '분석 중인 물품' : '분석 불가능한 물품'
		},
		isIng() {
			return this.item?.status === 'ING'
		},
		isFail() {
			return this.item?.status === 'FAIL'
		},
		hasCustomField() {
			return this.item?.customField?.length > 0
		},
		failMemos() {
			return this.item?.failMemos
		},
	},
	watch: {},
	async created() {
		await this.getWaitingDetail(this.$route.params.id)
		await this.getCategoryInfo()

		// AppHeader 경로 표시
		this.setStorageDetailPaths([this.title])
	},
	mounted() {},
	methods: {
		...mapActions('inventoryGen2/item', ['getWaitingDetail', 'getCategoryInfo']),
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
	},
}
</script>

<style lang="scss" scoped>
.detail-wrap {
	display: flex;
	flex-direction: column;
	.product-info-wrap {
		padding: 25px 30px;
		.description {
			font-size: 14px;
			font-weight: 400;
			text-align: left;
			padding-bottom: 25px;
			border-bottom: 1px solid $DIVIDER;
			.fail-memo {
				&::before {
					content: '·';
				}
			}
		}
	}
}
</style>
