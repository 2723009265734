import ParamMaker from '@/utils/ParamMaker'
import axiosInstance from '@/services/axios'

const endPoint = {
	lookupStorageTree: (instId, storageId) => `/institute/${instId}/storage/tree?id=${storageId}`,
	createStorage: instId => `/institute/${instId}/storage`,
	updateStorage: instId => `/institute/${instId}/storage`,
	deleteStorage: (instId, storageId) => `/institute/${instId}/storage?id=${storageId}`,
	addUsingGroup: instId => `/institute/${instId}/location/groups`,
	lookupDetailStorageInfo: (instId, storageId) => `/institute/${instId}/storage?id=${storageId}`,
	lookupTotalStorageTree: instId => `/institute/${instId}/storage/tree`,
	totalStorageTree: instId => `/institute/${instId}/storage/tree`,
	lookupTargetStorageTree: (instId, groupId) => `/institute/${instId}/storage/tree/target?id=${groupId}`,
	createChildStorage: instId => `/institute/${instId}/storage/child`,
	updateChildStorage: instId => `/institute/${instId}/storage/child`,
	addStorageAccess: instId => `/institute/${instId}/storage/access`,
	deleteStorageAccess: (instId, paramsObject) => `/institute/${instId}/storage/access?${ParamMaker.setParamUsingObject(paramsObject)}`,
	updateStorageAccess: (instId, body) => `/institute/${instId}/storage/access`,
	lookupWarningSigns: () => `/storage/warning/signs`,
	lookupRootStorageList: instId => `/institute/${instId}/storage/list`,
}

const storageAPI = {
	lookupStorageTree: (instId, storageId) => axiosInstance.get(endPoint.lookupStorageTree(instId, storageId)),
	createStorage: (instId, data) => axiosInstance.post(endPoint.createStorage(instId), data),
	updateStorage: (instId, data) => axiosInstance.put(endPoint.updateStorage(instId), data),
	deleteStroage: (instId, storageId) => axiosInstance.delete(endPoint.deleteStorage(instId, storageId)),
	addUsingGroup: (instId, data) => axiosInstance.put(endPoint.addUsingGroup(instId), data),
	lookupDetailStorageInfo: (instId, storageId) => axiosInstance.get(endPoint.lookupDetailStorageInfo(instId, storageId)),
	lookupTotalStorageTree: instId => axiosInstance.get(endPoint.lookupTotalStorageTree(instId)),
	totalStorageTree: instId => axiosInstance.get(endPoint.totalStorageTree(instId)),
	lookupTargetStorageTree: (instId, groupId) => axiosInstance.get(endPoint.lookupTargetStorageTree(instId, groupId)),
	createChildStorage: (instId, data) => axiosInstance.post(endPoint.createChildStorage(instId), data),
	updateChildStorage: (instId, data) => axiosInstance.put(endPoint.updateChildStorage(instId), data),
	addStorageAccess: (instId, data) => axiosInstance.post(endPoint.addStorageAccess(instId), data),
	deleteStorageAccess: (instId, paramsObject) => axiosInstance.delete(endPoint.deleteStorageAccess(instId, paramsObject)),
	updateStorageAccess: (instId, body) => axiosInstance.patch(endPoint.updateStorageAccess(instId), body),
	lookupWarningSigns: () => axiosInstance.get(endPoint.lookupWarningSigns()),
	lookupRootStorageList: instId => axiosInstance.get(endPoint.lookupRootStorageList(instId)),
}

export default storageAPI
