<template>
	<div class="form-wrap">
		<div class="form-header column">
			<div class="form-title">{{ tt('스티커 출력 양식') }}</div>
			<div class="form-header-subtext">
				{{ tt('물품에 부착할 [물품 ID 스티커] 양식을 선택해주세요.') }}
			</div>
		</div>

		<div class="form-data-wrap">
			<button class="sticker-btn" v-if="isCategoryChemical" :class="{ active: stickerType === 'GHS+QR' }" @click="showSelectGHSQR">
				{{ tt('GHS 라벨+QR 스티커') }}
			</button>
			<button class="sticker-btn" :class="{ active: stickerType === 'QR' }" @click="changeType('QR')">{{ tt('QR 스티커') }}</button>
			<button class="sticker-btn" :class="{ active: !stickerType }" @click="changeType(null)">
				{{ tt('부착 안 함') }}
			</button>
		</div>
		<div class="warn-msg-wrap" v-if="isRequireGhs">
			<p
				class="warn-msg"
				@click="openUrl('https://www.law.go.kr/LSW/admRulLsInfoP.do?admRulSeq=2100000049106')"
				v-html="
					tt('등록하려는 화학물질은 <b class=underline>「위험물안전관리법」시행규칙 별표 19</b>에 의하여 <b>GHS라벨</b>을 부착해야합니다.')
				"
			></p>
		</div>
		<modal-confirm
			ref="modal-make-ghs-confirm"
			title="GHS 라벨 생성"
			content="등록하려는 물품의 GHS 라벨이 없습니다. GHS라벨을 생성해주세요.<br/>제품 별 최초 1회 필요로합니다."
			confirmText="라벨 생성"
			@confirm="selectGHSQR"
			@cancel="cancelGHSQR"
		/>
		<modal-make-ghs
			ref="make-ghs"
			title="GHS 라벨 생성"
			:msds="msds"
			@close="
				cancelGHSQR()
				cancelParseMsds()
			"
			@showGuide="$refs['guide'].show()"
			@goNext="
				$refs['make-ghs'].hide()
				$refs['ghs-form'].show()
				cancelParseMsds()
			"
		/>
		<modal-ghs-form
			ref="ghs-form"
			@close="cancelGHSQR()"
			@goNext="showPreviewGHS"
			@goPrev="
				$refs['make-ghs'].show('prev')
				$refs['ghs-form'].hide()
			"
		></modal-ghs-form>
		<modal-ghs-preview
			ref="ghs-preview"
			@close="cancelGHSQR()"
			@goPrev="
				$refs['ghs-preview'].hide()
				$refs['ghs-form'].show()
			"
			@complete="completeCreateGHS"
		></modal-ghs-preview>
		<modal-ghs-label ref="ghs-label" @edit="editGHS" @delete="deleteGHS"></modal-ghs-label>
		<modal-confirm
			ref="modal-ghs-unselect"
			title="선택 해제"
			:content="
				tt('등록하려는 화학물질은 <b class=underline>「위험물안전관리법」시행규칙 별표 19</b>에 의하여 <b>GHS라벨</b>을 부착해야합니다.')
			"
			confirmText="선택 해제"
			@confirm="unselectGHS"
			@cancel="$refs['modal-ghs-unselect'].hide()"
		></modal-confirm>
	</div>
</template>

<script>
import { LargeClass } from '@/utils/define/ItemCode'

import ModalMakeGhs from '@/views/common/components/modalGen2/ghs/ModalMakeGHS.vue'
import ModalGhsForm from '@/views/common/components/modalGen2/ghs/ModalGHSForm.vue'
import ModalGhsPreview from '@/views/common/components/modalGen2/ghs/ModalGHSPreview.vue'
import ModalGhsLabel from '@/views/common/components/modalGen2/ghs/ModalGHSLabel.vue'
import MixinModalGhs from '@/mixins/modalGhs/mixinModalGhs'
import { mapActions, mapMutations } from 'vuex'

export default {
	name: 'FormSelectSticker',
	mixins: [MixinModalGhs],
	components: { ModalMakeGhs, ModalGhsForm, ModalGhsPreview, ModalGhsLabel },
	props: {
		category: {
			type: Object,
			default: null,
		},
		item: {
			type: Object,
			default: null,
		},
		isGhs: {
			type: Boolean,
			default: false,
		},
		isRequireGhs: {
			type: Boolean,
			default: false,
		},
		hideDelete: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			stickerType: null,
			recentClick: null,
		}
	},
	created() {
		if (this.isCategoryChemical) {
			this.getGhsStatement()
		}
	},
	mounted() {
		this.stickerType = this.item?.stickerType
	},
	computed: {
		isCategoryChemical() {
			return this.category?.classification === LargeClass.CHEMICAL
		},
		msds() {
			return this.item?.msds
		},
	},
	watch: {
		'item.stickerType': {
			handler(value) {
				this.stickerType = value
			},
		},
	},
	methods: {
		...mapMutations('modal/ghs', ['setMode', 'setPreviewData']),
		changeType(type) {
			if (type === 'QR' && this.stickerType === 'GHS+QR' && this.isRequireGhs) {
				this.recentClick = 'QR'
				this.$refs['modal-ghs-unselect'].show()
				return
			}
			this.stickerType = this.stickerType === type ? null : type
			this.$emit('change', this.stickerType)
		},
		openUrl(url) {
			window.open(url)
		},
		setData(type) {
			this.stickerType = type
		},
		showSelectGHSQR() {
			if (!this.isGhs) {
				if (this.stickerType !== 'GHS+QR') {
					this.$refs['modal-make-ghs-confirm'].show()
				}
			}

			if (this.isRequireGhs && this.stickerType === 'GHS+QR') {
				this.recentClick = 'GHS+QR'
				this.$refs['modal-ghs-unselect'].show()
				return
			}

			this.stickerType = this.stickerType === 'GHS+QR' ? null : 'GHS+QR'
			this.$emit('change', this.stickerType)
		},
		selectGHSQR() {
			this.$nextTick(() => {
				this.setMode('ADD')
				if (!this.item?.ghs) {
					this.$refs['make-ghs'].show()
				}
			})
		},
		showPreviewGHS() {
			if (this.mode === 'ADD') {
				this.setPreviewData(this.item)
			}
			this.$refs['ghs-form'].hide()
			this.$refs['ghs-preview'].show()
		},
		cancelGHSQR() {
			this.$emit('cancelGHSQR')
			this.stickerType = null
		},
		unselectGHS() {
			this.$refs['modal-ghs-unselect'].hide()
			this.stickerType = this.recentClick === 'QR' ? 'QR' : null
			this.recentClick = null
			this.$emit('change', this.stickerType)
		},
		deleteGHS() {
			this.$emit('deleteGHS')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-select-sticker.scss';
</style>
