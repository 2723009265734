var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "search-modal",
    attrs: {
      "no-close-on-backdrop": "",
      "hide-footer": "",
      "centered": ""
    },
    on: {
      "close": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "search-wrap"
  }, [_c('div', {
    staticClass: "search-box-main-wrap"
  }, [_c('div', {
    staticClass: "search-box-main"
  }, [_c('div', {
    staticClass: "search-box-wrap"
  }, [_c('search-box', {
    attrs: {
      "hasQR": _vm.forRegistItem
    },
    on: {
      "change": _vm.changeKeyword,
      "clickQR": function clickQR($event) {
        return _vm.$refs['modal-search-qr'].show('product');
      }
    }
  })], 1), _c('button', {
    staticClass: "button-gray",
    on: {
      "click": function click($event) {
        _vm.isUseSecondKeyword = !_vm.isUseSecondKeyword;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('결과 내 검색')) + " "), !_vm.isUseSecondKeyword ? _c('img', {
    staticClass: "chevron",
    attrs: {
      "src": require("./svg/chevron-down.svg")
    }
  }) : _c('img', {
    staticClass: "chevron",
    attrs: {
      "src": require("./svg/chevron-up.svg")
    }
  })])]), _vm.forRegistItem ? _c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.inputDirect
    }
  }, [_vm._v(_vm._s(_vm.tt('직접 입력하기')))]) : _vm._e()]), _vm.isUseSecondKeyword ? _c('div', {
    staticClass: "search-box-sub"
  }, [_c('search-box', {
    attrs: {
      "value": _vm.listParams.secondKeyword,
      "placeholder": _vm.tt('결과 내 검색'),
      "noRecent": ""
    },
    on: {
      "change": _vm.changeSecondKeyword
    }
  })], 1) : _vm._e()]), _c('div', {
    ref: "scroll-container",
    staticClass: "table-wrap",
    on: {
      "scroll": _vm.onScroll
    }
  }, [_c('table', [_c('search-product-header', {
    attrs: {
      "isChemical": _vm.isChemical
    }
  }), _vm._l(_vm.list, function (item, i) {
    return _c('search-product-item', {
      key: "".concat(item.name, "-").concat(i),
      attrs: {
        "item": item,
        "isChemical": _vm.isChemical
      },
      on: {
        "click": function click(item) {
          return _vm.clickProductItem(item);
        }
      }
    });
  })], 2), _vm.isEmptyData ? _c('div', {
    staticClass: "empty-table"
  }, [!_vm.isSearched ? _c('p', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.tt(_vm.nodataTitle)))]) : _c('p', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.tt('결과 없음')))]), !_vm.isSearched ? _c('p', {
    staticClass: "sub",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.nodataContent))
    }
  }) : _c('p', {
    staticClass: "sub",
    domProps: {
      "innerHTML": _vm._s(_vm.tt('검색결과가 없습니다.<br/>물품 정보를 직접입력해주세요'))
    }
  }), _vm.isSearched ? _c('button', {
    staticClass: "button-border-black",
    on: {
      "click": _vm.inputDirect
    }
  }, [_vm._v(_vm._s(_vm.tt('직접 입력하기')))]) : _vm._e()]) : _vm._e()])], _c('modal-search-product-using-qr', {
    ref: "modal-search-qr",
    on: {
      "scan": _vm.searchUsingScan
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }