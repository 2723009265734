<template>
	<b-modal centered scrollable @close="hide" ref="modal" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('배송지 변경') }}</div>
		</template>
		<template>
			<p>{{ tt('배송지 선택') }}</p>
			<div v-if="selectList.length > 0" class="select-wrapper">
				<b-dropdown ref="dropdown" no-caret toggle-class="option-wrapper" variant="link" right>
					<template #button-content>
						<p v-if="selectedValue" class="name" ref="name">
							{{ tt(selectedValue.name) }}
						</p>
					</template>
					<b-dropdown-item @click.stop="changeOption(index)" :key="`item-${index}`" v-for="(item, index) in selectList">
						<p>
							{{ tt(item.name) }}
						</p>
						<div class="edit-wrapper">
							<img @click.stop="$refs['modal-add-address'].show(item)" src="@/assets/svg/button-icon-edit.svg" />
							<img @click.stop="removeAddress(item)" src="@/assets/svg/Trash.svg" />
						</div>
					</b-dropdown-item>
				</b-dropdown>
			</div>
			<div class="select-delivery-info" v-if="selectedValue">
				<div class="default" v-if="selectedValue.isDefault">{{ tt('기본 배송지') }}</div>
				<h6>{{ selectedValue.name }}</h6>
				<p class="address">[{{ selectedValue.postcode }}] {{ selectedValue.address }}</p>
				<p class="sub">
					{{ selectedValue.instituteName ? selectedValue.instituteName : '' }}
					{{ selectedValue.groupName ? ' / ' + selectedValue.groupName : '' }}
				</p>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left">
					<base-button :color="'white'" @click="add">{{ tt('배송지 등록') }}</base-button>
				</div>
				<div class="footer-right">
					<base-button :color="'white'" @click="hide">{{ tt('취소') }}</base-button>
					<base-button color="lab-black" @click="change">{{ tt('변경') }}</base-button>
				</div>
			</div>
		</template>
		<modal-add-address ref="modal-add-address"></modal-add-address>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ModalAddAddress from '@/views/purchase/draft/components/modal/ModalAddAddress.vue'

export default {
	components: {
		ModalAddAddress,
	},
	data() {
		return {
			value: null,
		}
	},
	computed: {
		...mapState('lms', ['deliveryList']),
		selectList() {
			return this.deliveryList.map(it => {
				return {
					...it,
					name: (it.isDefault == 1 ? this.tt('[기본 배송지]') + ' ' : '') + it.name,
				}
			})
		},
		selectedValue() {
			return this.selectList.find(item => item.id === this.value)
		},
	},
	methods: {
		...mapActions('lms', ['deleteAddress']),
		show(item) {
			if (item) this.value = item
			this.$refs['modal'].show()
		},
		changeOption(index) {
			this.value = this.selectList[index].id
			// this.$emit('change', this.value)
		},
		// hideSelect() {
		// 	if (this.$refs['dropdown']) this.$refs['dropdown'].hide()
		// },
		hide() {
			this.$refs['modal'].hide()
		},
		add() {
			this.$emit('add')
			this.hide()
		},
		change() {
			this.$emit('change', this.value)
			this.hide()
		},
		async removeAddress(item) {
			try {
				await this.deleteAddress({ deliveryId: item.id })
				if (this.value === item.id) {
					this.value = this.deliveryList.find(it => it.isDefault)?.id ?? this.deliveryList[0]?.id
				}
				this.$emit('remove')
				this.$root.toast('배송지 삭제 완료', '선택한 배송지를 삭제하였습니다.', 'success')
			} catch (e) {
				this.$root.toast('배송지 삭제 실패', e.response.data.msg, 'error')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .close {
	color: $SECONDARY_2;
	font-size: 1.8rem;
}
::v-deep .modal-header {
	border-bottom: none;
	padding: 1rem 1rem 0;
}
::v-deep .modal-body {
	overflow: initial;
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 230px;
	& > p {
		font-size: 14px;
	}
}
::v-deep .modal-footer {
	border: 0;
	.modal-footer-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.footer-left,
		.footer-right {
			display: flex;
			gap: 10px;
			button {
				border: 0;
				box-shadow: none;
				display: flex;
				height: auto;
				align-items: center;
				cursor: pointer;
				white-space: pre;
				transition: 0.5s all ease;
				min-width: 100px;
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 700;
				border-radius: 5px;
				justify-content: center;
			}
			.button-white {
				background: $LAB_WHITE;
				border: 1px solid $DIVIDER;
				color: #111 !important;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $DIVIDER, $DIVIDER),
						linear-gradient(0deg, #fff, #fff);
				}
			}
			.button-primary {
				background: $LAB_YELLOW;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
				}
			}
		}
	}
}
.select-wrapper {
	width: 100%;
	height: 36px;
	background-image: url(~@/assets/svg/purchase/select-triangle.svg);
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: center right 8px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid $COLOR_CCC;
	&.error {
		border-color: $RED;
	}
}

::v-deep {
	.dropdown {
		height: 100%;
		width: 100%;
	}
	.option-wrapper {
		justify-content: left;
		width: 100%;
		height: 100%;
		background-color: transparent;
		border: none;
		padding-left: 10px;
		text-align: left;
		text-decoration: none;
		&:hover,
		&:active,
		&:focus {
			color: $DEFAULT;
			box-shadow: none;
		}
		b {
			margin-right: 10px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 90px;
		}
		p {
			max-width: 90%;
			min-width: 90px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: 400;
		}
		span {
			color: $PLACEHOLDER;
		}
	}
	ul {
		width: 100%;
		overflow-y: overlay;
		max-height: 200px;
		padding: 5px;
		li {
			padding-left: 10px;
			font-size: 0.875rem;
			display: flex;
			align-items: center;
			width: 100%;
			height: 36px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				background-color: $GREY_2;
				border-radius: 4px;
				.edit-wrapper {
					visibility: visible;
				}
			}
			a {
				display: flex;
				padding: 0px;
				width: 100%;
				height: 100%;
				align-items: center;
				&:hover {
					background-color: transparent;
				}
				&:active {
					color: $DEFAULT;
				}
				b {
					margin-right: 10px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					max-width: 90px;
				}
				p {
					max-width: 100%;
					min-width: 90px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
			.edit-wrapper {
				visibility: hidden;
				display: flex;
				gap: 2px;
				position: absolute;
				right: 16px;
				& > img:hover {
					opacity: 0.6;
				}
			}
		}
	}
}
.select-delivery-info {
	width: 100%;
	.default {
		margin-top: 16px;
		width: fit-content;
		padding: 0px 8px;
		height: 20px;
		background-color: $DIVIDER;
		border-radius: 5px;
		font-size: 12px;
		font-weight: 700;
		color: $SECONDARY_1;
	}
	h6 {
		margin-top: 10px;
		font-size: 16px;
		font-weight: 700;
		color: $DEFAULT;
	}
	.address {
		margin-top: 8px;
		font-weight: 500;
		font-size: 14px;
		color: $SECONDARY_2;
	}
	.sub {
		margin-top: 8px;
		font-weight: 400;
		font-size: 14px;
		color: $PLACEHOLDER;
	}
}
</style>
