var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "Modal ModalCompleteRegisteringLicense"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt('라이선스 주문 완료')))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/check.png")
    }
  })]), _c('div', {
    staticClass: "license-summary"
  }, [_c('div', {
    staticClass: "imgbox"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/lm-pro-icon.png")
    }
  })]), _c('div', {
    staticClass: "txtbox"
  }, [_c('h6', [_vm._v(_vm._s(_vm.licenseTypeTextForComplete))]), _c('p', [_vm._v(" " + _vm._s(_vm.tt("\uFFE6".concat(_vm.formatNumberLocale(_vm.getCompletePurchasePrice), " / ").concat(_vm.purchaseForm.plan == _vm.type.EDUCATION ? '연구실당(년 기준,' : '계정당(월 기준,', " VAT \uBCC4\uB3C4)"))) + " ")])])]), _c('div', {
    staticClass: "license-info"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('라이선스 정보')))]), _c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('상태')))]), _c('div', {
    staticClass: "license-status-wrapper"
  }, [_c('span', {
    staticClass: "license-status",
    "class": {
      warning: !_vm.isLicenseActivate
    }
  }, [_vm.isLicenseActivate ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/sign-safe.svg"),
      "height": "12"
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/svg/sign-warning.svg"),
      "height": "12"
    }
  }), _vm._v(" " + _vm._s(_vm.licenseStatusText) + " ")]), _vm.purchaseCompleteInfo.paymentStatus === 'WAIT' ? _c('span', {
    staticClass: "license-status warning"
  }, [_vm._v(" " + _vm._s(_vm.tt('입금대기')) + " ")]) : _vm._e()])]), _vm.purchaseCompleteInfo.paymentStatus != 'WAIT' ? _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('라이선스 만료일')))]), _c('span', [_vm._v(" " + _vm._s(_vm.formatDatetime(_vm.license.expireDate)) + " ")])]) : _vm._e(), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('구매한 라이선스 수')))]), _c('span', [_vm._v(_vm._s(_vm.purchaseCompleteInfo.quantity == _vm.MAX_QUANTITY ? _vm.tt('무제한') : _vm.formatNumberLocale(_vm.purchaseCompleteInfo.quantity)))])])])]), _c('div', {
    staticClass: "payment-info"
  }, [_c('h5', [_vm._v(_vm._s(_vm.tt('결제 정보')))]), _vm.isPaymentMethodEbill ? _c('div', {
    staticClass: "noti-box"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('${1}까지 입금하지 않으면 라이선스가 <b class=red>비활성화</b>됩니다.', _vm.tt("<b class='red'>".concat(_vm.formatDepositExpireDate(), "</b>"))))
    }
  })]) : _vm._e(), _vm.isPaymentMethodEbill ? _c('div', {
    staticClass: "noti-box last"
  }, [_c('p', [_vm._v(" " + _vm._s("".concat(_vm.licenseManagement.schedule.virtualAccountBankName, " ").concat(_vm.licenseManagement.schedule.virtualAccountNumber, " (").concat(_vm.tt('예금주'), ": ").concat(_vm.licenseManagement.schedule.virtualAccountHolder, ")")) + " ")]), _c('p', [_vm._v(" " + _vm._s("".concat(_vm.tt('결제금액'), ": \u20A9").concat(_vm.formatNumberLocale(_vm.licenseManagement.schedule.totalPrice))) + " ")])]) : _vm._e(), _c('ul', [_c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('결제 상태')))]), _vm.isPaymentMethodEbill && _vm.isLicenseDepositWait ? _c('span', {
    staticClass: "uncompleted"
  }, [_vm._v(_vm._s(_vm.tt('입금대기')) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.tt('결제 완료')))])]), _vm.isPaymentMethodEbill ? _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('입금기한')))]), _c('span', [_vm._v(_vm._s(_vm.formatDatetime(_vm.licenseManagement.schedule.expireDatetime, false)))])]) : _vm._e(), _c('li', [_c('p', [_vm._v(" " + _vm._s(!_vm.isPaymentMethodEbill ? _vm.tt('결제일') : _vm.tt('주문일')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.formatDatetime(_vm.purchaseCompleteInfo.createDatetime, false)) + " ")])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('결제수단')))]), _c('span', [_vm._v(_vm._s(_vm.paymentMethod2Text(this.purchaseCompleteInfo.paymentMethod, _vm.isAdditionMode)))])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('거래서류')))]), _vm.isPaymentMethodEbill ? _c('div', [_c('a', {
    attrs: {
      "download": "",
      "href": _vm.purchaseCompleteInfo.receiptUrl
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('전자세금계산서')) + " ")]), _c('span', [_vm._v(" / ")]), _c('a', {
    attrs: {
      "download": "",
      "href": _vm.purchaseCompleteInfo.virtualAccountConfirmationUrl
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('가상계좌발급서')) + " ")])]) : _c('a', {
    attrs: {
      "download": "",
      "href": _vm.purchaseCompleteInfo.receiptUrl
    }
  }, [_vm._v(_vm._s(_vm.tt('영수증')))])]), _c('li', [_c('p', [_vm._v(_vm._s(_vm.tt('금액')))]), _c('b', [_vm._v(" ￦" + _vm._s(_vm.formatNumberLocale(_vm.purchaseCompleteInfo.totalPrice)) + " ")])])])])]), _c('div', {
    staticClass: "Popup__bottom"
  }, [_c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.completeText)) + " ")])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }