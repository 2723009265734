var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.category ? _c('form-product', {
    ref: "form-product",
    attrs: {
      "title": "물품 정보",
      "productInfo": _vm.cargoInfo,
      "categoryInfo": _vm.categoryInfo,
      "isValid": _vm.isProductValid,
      "isUnitValid": _vm.isUnitValid,
      "hideFieldKey": _vm.hideFieldKey,
      "isShowError": _vm.showError
    },
    on: {
      "change": _vm.changeFormProduct,
      "selectItem": _vm.handleSelectItem
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }