var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-footer-wrap"
  }, [_c('div', {
    staticClass: "footer-left"
  }, [_vm.isPrev ? _c('button', {
    staticClass: "button-white",
    on: {
      "click": function click($event) {
        return _vm.$emit('goPrev');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.prevText)) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "footer-right"
  }, [_c('button', {
    staticClass: "button-white",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.cancelText)) + " ")]), _c('button', {
    staticClass: "button-primary",
    on: {
      "click": function click($event) {
        return _vm.$emit('complete');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt(_vm.completeText)) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }