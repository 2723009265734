var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "OrganizationTree__page-container"
  }, [_c('inst-header', {
    attrs: {
      "title": _vm.tt('조직도 관리')
    }
  }), _c('div', {
    staticClass: "OrganizationTree__container"
  }, [_c('div', {
    staticClass: "OrganizationTree__chart-container"
  }, [_c('div', {
    staticClass: "OrganizationTree__chart-header",
    "class": {
      shadow: _vm.showBoxShadow
    }
  }, [_c('lm-input-search', {
    attrs: {
      "placeholder": _vm.tt('그룹명으로 검색')
    },
    on: {
      "search": _vm.searchTree
    }
  }), _vm.userPermission.group && _vm.userPermission.group.create ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn bold",
    on: {
      "click": _vm.openModalAddGroup
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg//button-icon-lisence-copy.svg"),
      "alt": "icon"
    }
  }), _vm._v(" " + _vm._s(_vm.tt('생성')) + " ")]) : _vm._e(), _vm.userPermission.group && _vm.userPermission.group["delete"] ? _c('button', {
    staticClass: "AppContainer__subtitle-btn btn ml-10 bold",
    attrs: {
      "disabled": _vm.selectedGroupInfo && !_vm.selectedGroupInfo.parentId
    },
    on: {
      "click": _vm.showModalGroupDelete
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/Trash.svg"),
      "alt": "icon"
    }
  }), _vm._v(_vm._s(_vm.tt('삭제')) + " ")]) : _vm._e()], 1), _c('div', {
    ref: "chart-main-container",
    staticClass: "OrganizationTree__main-container",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchTreeData,
      expression: "searchTreeData"
    }]
  }, [_vm.treeData ? _c('page-tree-view', {
    ref: "page-tree-view",
    attrs: {
      "textField": "name",
      "subTextField": "membersCount",
      "treeData": _vm.treeData,
      "subTextEndWord": _vm.tt('명'),
      "keyword": _vm.keyword,
      "width": 800,
      "userPermission": _vm.userPermission,
      "page": "organization"
    },
    on: {
      "changeActive": _vm.changeActiveId,
      "changeTree": _vm.changeTree,
      "withResult": _vm.withResult
    }
  }) : _vm._e()], 1), !_vm.searchTreeData ? _c('no-data-list', {
    attrs: {
      "image": "search",
      "title": "검색 결과가 없습니다",
      "content": "단어의 철자가 정확한지 확인하세요"
    }
  }) : _vm._e()], 1)]), _c('tree-info-area', {
    ref: "tree-info-area",
    attrs: {
      "groupInfo": _vm.selectedGroupInfo,
      "userPermission": _vm.userPermission
    },
    on: {
      "refresh": _vm.refreshData
    }
  })], 1), _c('modal-confirm-delete', {
    ref: "modal-confirm-delete",
    attrs: {
      "title": _vm.tt(_vm.deleteTitleText),
      "content": _vm.tt(_vm.deleteContentText)
    },
    on: {
      "refresh": _vm.refreshData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }