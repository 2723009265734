<template>
	<tr>
		<td>
			<button class="checkbox" @click="checkBoxClick" :class="{ active: isClick }"></button>
		</td>
		<td v-if="headers[0].value">
			<div class="state" :class="item.status == 'SAFE' ? 'safe' : 'warning'">
				<img :src="item.status == 'SAFE' ? require('@/assets/svg/icon-safe.svg') : require('@/assets/svg/icon-error.svg')" alt="icon" />
				<p v-text="item.status == 'SAFE' ? tt('안전') : tt('위험')"></p>
			</div>
		</td>
		<td v-if="headers[1].value">
			<b>{{ item.name }}</b>
		</td>
		<td v-if="headers[2].value">
			<button class="Btn__hover-balloon-w" :class="[{ last: count == index + 1 }, { first: index == 0 }]">
				<span v-if="item.locations.length == 1">{{ setLocationName(item.locations[0].pathName) }}</span>
				<span v-else-if="item.locations.length > 1"
					>{{ setLocationName(item.locations[0].pathName) }} 외 {{ item.locations.length - 1 }}{{ tt('개') }} {{ tt('장소') }}</span
				>
				<div class="balloon">
					<h5>{{ item.name }}</h5>
					<ul>
						<li v-for="(list, idx) in item.locations" :key="list.id">{{ idx + 1 }}. {{ setLocationName(list.pathName) }}</li>
					</ul>
				</div>
			</button>
		</td>
		<td v-if="headers[3].value">
			<div class="flex">
				<button class="btn arrow" @click="move('UP')" :disabled="order == 0">
					<img style="padding: 0" src="@/assets/svg/arrow-up.svg" alt="arrow-icon" />
				</button>
				<button class="btn arrow" @click="move('DOWN')" :disabled="order == count - 1">
					<img style="padding: 0" src="@/assets/svg/arrow-down.svg" alt="arrow-icon" />
				</button>
			</div>
		</td>
		<td v-if="headers[4].value">
			<div class="flex">
				<button class="btn" @click="showEditStorage">
					<img src="@/assets/svg/button-icon-edit.svg" alt="icon" />
					{{ tt('편집') }}
				</button>
				<button class="btn" @click="showDeleteStorage(item.id)">
					<img src="@/assets/svg/Trash.svg" alt="icon" />
					{{ tt('삭제') }}
				</button>
			</div>
		</td>
	</tr>
</template>

<script>
export default {
	name: 'ManagementStorageItem',
	props: {
		item: {
			type: Object,
			default: null,
		},
		allCheck: {
			type: Boolean,
			default: false,
		},
		headers: {
			type: Array,
			default: [],
		},
		checkList: {
			type: Array,
			default: [],
		},
		order: {
			type: Number,
			default: 0,
		},
		count: {
			type: Number,
			default: 0,
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	components: {},
	mounted() {},
	data() {
		return {
			isClick: false,
		}
	},
	watch: {
		allCheck: function () {
			if (this.allCheck) {
				this.isClick = true
			} else {
				this.isClick = false
			}
		},
		isClick: function () {
			if (this.isClick) {
				this.$emit('checkId', this.item.id)
			} else {
				this.$emit('uncheckId', this.item.id)
			}
		},
		checkList: function () {
			if (this.checkList == 0) {
				this.isClick = false
			}
		},
	},
	methods: {
		checkBoxClick() {
			if (this.isClick) {
				this.isClick = false
			} else {
				this.isClick = true
			}
		},
		setLocationName(name) {
			return name
		},
		move(direction) {
			this.$emit('move', this.item.id, direction)
		},
		showEditStorage() {
			this.$emit('showEditStorage', 'EDIT', this.item)
		},
		showDeleteStorage(id) {
			this.$emit('showDeleteStorage', id)
		},
	},
}
</script>

<style lang="scss" scoped>
.Btn__hover-balloon-w {
	h5 {
		font-size: 0.875rem;
		color: 000;
		font-weight: 700;
		padding-bottom: 3px;
	}
	ul {
		li {
			font-size: 0.75rem;
			line-height: 1.3;
		}
	}
	&.last {
		.balloon {
			top: unset;
			bottom: 30px;
			&:before {
				border-bottom: 0px solid transparent;
				border-left: 9px solid transparent;
				border-right: 9px solid transparent;
				border-top: 9px solid rgba(0, 0, 0, 0.6);
				content: '';
				position: absolute;
				bottom: -9.2px;
				top: unset;
				left: 14px;
				z-index: 1003;
			}
			&:after {
				border-bottom: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 8px solid $LAB_WHITE;
				content: '';
				position: absolute;
				bottom: -8px;
				top: unset;
				left: 15px;
				z-index: 1004;
			}
		}
	}
	&.first {
		.balloon {
			top: 30px;
			bottom: unset;
			&:before {
				border-top: 0px solid transparent;
				border-left: 9px solid transparent;
				border-right: 9px solid transparent;
				border-bottom: 9px solid rgba(0, 0, 0, 0.6);
				content: '';
				position: absolute;
				top: -9.2px;
				bottom: unset;
				left: 14px;
				z-index: 1003;
			}
			&:after {
				border-top: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid $LAB_WHITE;
				content: '';
				position: absolute;
				top: -8px;
				bottom: unset;
				left: 15px;
				z-index: 1004;
			}
		}
	}
}

tr {
	td {
		button {
			&:nth-child(2) {
				margin-left: 10px;
			}
		}
	}
}
</style>
