var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "category-wrap"
  }, [_c('div', {
    staticClass: "side-wrap"
  }, [_c('div', {
    ref: "scroll-con",
    staticClass: "menu-wrap"
  }, [_c('category-sub-menu', {
    ref: "chemical",
    attrs: {
      "type": 'chemical',
      "list": _vm.chemicalList,
      "activeId": _vm.selectedId
    },
    on: {
      "detail": _vm.detail
    }
  }), _c('category-sub-menu', {
    ref: "chromatography",
    attrs: {
      "type": 'chromatography',
      "list": _vm.chromatographyList,
      "activeId": _vm.selectedId
    },
    on: {
      "detail": _vm.detail
    }
  }), _c('category-sub-menu', {
    ref: "labware",
    attrs: {
      "type": 'labware',
      "list": _vm.labwareList,
      "activeId": _vm.selectedId
    },
    on: {
      "detail": _vm.detail
    }
  }), _c('category-sub-menu', {
    ref: "protection",
    attrs: {
      "type": 'protection',
      "list": _vm.protectionList,
      "activeId": _vm.selectedId
    },
    on: {
      "detail": _vm.detail
    }
  }), _c('category-sub-menu', {
    ref: "etc",
    attrs: {
      "type": 'etc',
      "list": _vm.etcList,
      "activeId": _vm.selectedId
    },
    on: {
      "detail": _vm.detail
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.isCreated ? _vm.tt('권한이 없습니다.') : '',
      expression: "!isCreated ? tt('권한이 없습니다.') : ''",
      modifiers: {
        "hover": true
      }
    }]
  }, [_c('div', {
    staticClass: "fixed-menu",
    "class": {
      disabled: !_vm.isCreated
    },
    on: {
      "click": _vm.showAddCategory
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/category-add.svg"),
      "alt": "icon"
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.tt('카테고리 추가')) + " ")])])])]), _c('div', {
    staticClass: "management-wrap"
  }, [_c('div', {
    staticClass: "title-wrap"
  }, [_c('div', [_c('h5', [_vm._v(_vm._s(_vm.tt(_vm.category.name)))]), _c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/Edit.svg"),
      "alt": "icon"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-edit-category'].show(_vm.category.name);
      }
    }
  }), _c('button', {
    staticClass: "btn Dropdown__btn"
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/More.svg"),
      "alt": "icon"
    }
  }), _c('div', {
    staticClass: "Dropdown__menu"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.isUpdate ? _vm.tt('권한이 없습니다.') : '',
      expression: "!isUpdate ? tt('권한이 없습니다.') : ''",
      modifiers: {
        "hover": true
      }
    }]
  }, [_c('button', {
    attrs: {
      "disabled": !_vm.isUpdate
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-initialize-category'].show();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('초기화')) + " ")])]), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.isCreated ? _vm.tt('권한이 없습니다.') : '',
      expression: "!isCreated ? tt('권한이 없습니다.') : ''",
      modifiers: {
        "hover": true
      }
    }]
  }, [_c('button', {
    attrs: {
      "disabled": !_vm.isCreated
    },
    on: {
      "click": function click($event) {
        _vm.$refs['modal-copy-category'].show("".concat(_vm.category.name, "(Copy)"));
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('복제')) + " ")])]), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.isDelete ? _vm.tt('권한이 없습니다.') : '',
      expression: "!isDelete ? tt('권한이 없습니다.') : ''",
      modifiers: {
        "hover": true
      }
    }]
  }, [_c('button', {
    attrs: {
      "disabled": !_vm.isDelete
    },
    on: {
      "click": _vm.showDeleteCategoryModal
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('삭제')) + " ")])])])])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.tt(_vm.categorySubName)))])])]), _c('div', {
    staticClass: "info-wrap"
  }, [_c('div', [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('물품정보')))])]), _c('toggle-field', {
    attrs: {
      "fields": _vm.inventoryFieldList,
      "type": 'inventory'
    }
  })], 1), _c('div', [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('관리정보')))])]), _c('toggle-field', {
    attrs: {
      "fields": _vm.managementFieldList,
      "type": 'management'
    }
  })], 1), _c('div', [_c('div', [_c('h6', [_vm._v(_vm._s(_vm.tt('추가정보')))]), _c('image-button', {
    attrs: {
      "text": '항목 추가',
      "disabled": !_vm.isCreated
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-add-field'].show();
      }
    }
  })], 1), _c('toggle-field', {
    attrs: {
      "fields": _vm.customFieldList,
      "type": 'custom'
    },
    on: {
      "editField": _vm.editField
    }
  }), _vm.customFieldList.length == 0 ? _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    staticStyle: {
      "width": "206px"
    },
    attrs: {
      "src": require("@/assets/svg/no-data_list.svg")
    }
  }), _c('h6', [_vm._v(_vm._s(_vm.tt('추가 정보가 없습니다')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('<b>[항목 추가]</b> 버튼을 클릭하여 추가해보세요'))
    }
  })]) : _vm._e()], 1), _c('div', {
    staticClass: "button-wrap"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.isUpdate ? _vm.tt('권한이 없습니다.') : '',
      expression: "!isUpdate ? tt('권한이 없습니다.') : ''",
      modifiers: {
        "hover": true
      }
    }]
  }, [_c('base-button', {
    attrs: {
      "disabled": !_vm.isUpdate
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-initialize-category'].show();
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('초기화')))])], 1), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.isCreated ? _vm.tt('권한이 없습니다.') : '',
      expression: "!isCreated ? tt('권한이 없습니다.') : ''",
      modifiers: {
        "hover": true
      }
    }]
  }, [_c('base-button', {
    attrs: {
      "disabled": !_vm.isCreated
    },
    on: {
      "click": function click($event) {
        _vm.$refs['modal-copy-category'].show("".concat(_vm.category.name, "(Copy)"));
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('복제')))])], 1), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: !_vm.isDelete ? _vm.tt('권한이 없습니다.') : '',
      expression: "!isDelete ? tt('권한이 없습니다.') : ''",
      modifiers: {
        "hover": true
      }
    }]
  }, [_c('base-button', {
    attrs: {
      "disabled": !_vm.isDelete,
      "color": 'reject'
    },
    on: {
      "click": _vm.showDeleteCategoryModal
    }
  }, [_vm._v(_vm._s(_vm.tt('삭제')))])], 1)])])]), _c('modal-add-category', {
    ref: "modal-add-category",
    on: {
      "addCategory": _vm.addCategoryInfo
    }
  }), _c('modal-edit-category', {
    ref: "modal-edit-category",
    attrs: {
      "title": "카테고리 편집"
    },
    on: {
      "editCategory": _vm.editCategoryInfo
    }
  }), _c('modal-confirm', {
    ref: "modal-initialize-category",
    attrs: {
      "title": "카테고리 초기화",
      "confirmText": "초기화",
      "content": "\uCE74\uD14C\uACE0\uB9AC\uB97C \uCD08\uAE30 \uC124\uC815\uAC12\uC73C\uB85C \uB418\uB3CC\uB9BD\uB2C8\uB2E4.</br>\uCD94\uAC00\uC815\uBCF4\uC758 \uACBD\uC6B0 \uD56D\uBAA9\uC740 \uC720\uCE58\uD55C\uCC44\uB85C \uC0C1\uD0DC\uAC00 OFF\uB85C \uBCC0\uACBD\uB429\uB2C8\uB2E4."
    },
    on: {
      "confirm": _vm.initializeCategoryInfo
    }
  }), _c('modal-edit-category', {
    ref: "modal-copy-category",
    attrs: {
      "title": "카테고리 복제"
    },
    on: {
      "editCategory": _vm.copyCategoryInfo
    }
  }), _c('modal-confirm', {
    ref: "modal-delete-category",
    attrs: {
      "variant": "warning",
      "confirmText": "삭제",
      "title": _vm.modalDeleteTitle,
      "hasConfirm": _vm.categoryCount === 0 ? true : false,
      "cancelText": _vm.categoryCount === 0 ? '취소' : '닫기',
      "content": _vm.modalDeleteContent
    },
    on: {
      "confirm": _vm.deleteCategoryInfo
    }
  }), _c('modal-add-field', {
    ref: "modal-add-field"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }