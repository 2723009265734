<template>
	<form-ingredient-list ref="form-ingredient" :isShowError="showError" @change="changeIngredient"></form-ingredient-list>
</template>

<script>
import FormIngredientList from '@/views/common/components/form/ingredient/FormIngredientList.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'CargoFormIngredientList',
	components: { FormIngredientList },
	props: {},
	data() {
		return {
			ingredientList: [],
		}
	},
	computed: {
		...mapGetters('cargo/form', ['cargoInfo', 'showError']),
	},
	watch: {
		'cargoInfo.ingredient': {
			deep: true,
			handler(ingredient) {
				this.$refs['form-ingredient'].setData(ingredient)
			},
		},
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.$refs['form-ingredient'].setData(this.cargoInfo?.ingredient)
		})
	},
	methods: {
		...mapMutations('cargo/form', ['setIngredientInfo']),
		changeIngredient(value) {
			this.ingredientList = value
			this.setIngredientInfo(value)
		},
		showRequire() {
			this.$refs['form-ingredient'].showRequire()
		},
	},
}
</script>

<style lang="scss" scoped></style>
