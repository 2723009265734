var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item-wrap"
  }, [_c('div', {
    staticClass: "header-wrap"
  }, [_c('button', {
    staticClass: "item-handle"
  }), _c('h4', [_vm._v(_vm._s(_vm.textCode(_vm.item.code)))]), _c('button', {
    staticClass: "btn Dropdown__btn"
  }, [_c('img', {
    attrs: {
      "src": require("@/views/inventoryGen2/svg/Setting.svg")
    }
  }), _c('div', {
    staticClass: "Dropdown__menu"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('deleteManagement', _vm.index);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('관리번호에서 제외하기')) + " ")]), _c('button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('changeManagement', _vm.index);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('관리항목 변경하기')) + " ")])])])]), _c('ul', _vm._l(_vm.item.marks, function (subItem, idx) {
    return _c('li', {
      key: subItem.originId
    }, [_c('input', {
      attrs: {
        "type": "text",
        "maxlength": "6",
        "disabled": !_vm.item.canEditMark
      },
      domProps: {
        "value": subItem.value
      },
      on: {
        "blur": function blur($event) {
          return _vm.valueChange($event, idx);
        }
      }
    }), _c('p', [_vm._v(_vm._s(_vm.tt(subItem.text)))])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }