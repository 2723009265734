<template>
	<div class="list-wrapper" v-if="list.length > 0">
		<div class="title-wrapper">
			<h5>{{ tt(title) }}</h5>
			<button @click="reset">{{ tt('초기화') }}</button>
		</div>
		<div class="check-list">
			<div class="check-item">
				<label>
					<input class="checkbox" type="checkbox" @change="checkedAll" :checked="checkedList.length == storageCount" />
					{{ tt('전체선택') }}</label
				>
			</div>
			<filter-children v-for="item in showingList" :key="item.id" :node="item" :checkedList="checkedList" @checked="checked"> </filter-children>
		</div>
		<div class="show-all" :class="{ open: isShowAll }" v-if="list.length >= 9" @click="isShowAll = !isShowAll">
			{{ tt(isShowAll ? '접기' : '모두보기') }}
		</div>
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import FilterChildren from './FilterChildren.vue'
import {
	getListByKeyword,
	getStorageCount,
	getStorageIds,
	getStorageMathcingKeyword,
	getStorageCountMathcingKeyword,
	isChildrenChecked,
} from '@/utils/Tree'
export default {
	components: {
		FilterChildren,
	},
	props: {
		title: {
			type: String,
		},
		list: {
			type: Array,
			default: () => [],
		},
		filterCookieName: {
			type: String,
		},
		isLegal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkedList: [],
			isShowAll: false,
		}
	},
	computed: {
		...mapGetters('filter', ['text']),
		listByKeyword() {
			if (this.text.length >= 1) return getStorageMathcingKeyword(this.list, this.text)
			return this.list
		},
		showingList() {
			if (this.isShowAll) return this.listByKeyword
			else return this.listByKeyword.slice(0, 8)
		},
		storageCount() {
			return getStorageCount(this.list, this.isLegal)
		},
		storageIds() {
			return getStorageIds(this.list, this.isLegal)
		},
		countByText() {
			return getStorageCountMathcingKeyword(this.list, this.text)
		},
	},
	watch: {
		listByKeyword: {
			deep: true,
			handler() {
				if (this.listByKeyword.findLastIndex(it => it.isOpen) > 8 && this.text.length > 0) {
					this.isShowAll = true
				} else if (this.text.length <= 0) this.isShowAll = false
			},
		},
	},
	created() {
		if (this.filterCookieName) {
			this.checkedList = JSON.parse(this.$cookies.get(this.filterCookieName)) ?? []
		}
		if (isChildrenChecked(this.listByKeyword.slice(8), this.checkedList)) {
			this.isShowAll = true
		}
	},
	methods: {
		reset() {
			this.checkedList = []
		},
		checkedAll() {
			if (this.checkedList.length == this.storageCount) this.checkedList = []
			else this.checkedList = this.storageIds
		},
		checked(item) {
			if (this.checkedList.includes(item.id)) this.checkedList.remove(item.id)
			else {
				this.checkedList.push(item.id)
				if (item.children?.length > 0) {
					const storageIds = getStorageIds(item.children, this.isLegal)
					storageIds.forEach(it => {
						if (!this.checkedList.includes(it)) {
							this.checkedList.push(it)
						}
					})
				}
			}
		},
		setCookies() {
			if (this.filterCookieName == null) return
			this.$cookies.set(this.filterCookieName, JSON.stringify(this.checkedList))
		},
	},
}
</script>
<style scoped lang="scss">
.list-wrapper {
	// padding: 25px 20px;
	border-bottom: 1px solid $DIVIDER;
	padding-bottom: 30px;
	margin-bottom: 30px;
	.title-wrapper {
		display: flex;
		align-items: center;
		h5 {
			font-size: 16px;
		}
		button {
			justify-content: center;
			margin-left: auto;
			width: 60px;
			height: 30px;
			border-radius: 4px;
			background-color: $GREY_4;
			color: $DEFAULT;
			font-size: 14px;
			font-weight: 700;
		}
	}
	.check-list {
		width: 100%;
		margin-top: 10px;
		display: inline-flex;
		flex-wrap: wrap;
		gap: 10px;
		& > * {
			width: calc((100% - 20px) / 3);
		}
		.check-item {
			display: flex;
			label {
				cursor: pointer;
				padding-left: 2px;
				display: flex;
				input {
					margin-top: 3px;
					margin-right: 5px;
				}
				align-items: flex-start;
				gap: 10px;
				width: calc(100% - 20px);
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-size: 14px;
			}
		}
	}
	.show-all {
		width: fit-content;
		position: relative;
		cursor: pointer;
		font-weight: 700;
		color: $BLUE;
		font-size: 14px;
		margin-top: 10px;
		&::after {
			content: '';
			width: 16px;
			height: 16px;
			position: absolute;
			right: -18px;
			top: 3px;
			background-image: url(~@/assets/svg/arrow-b.svg);
			opacity: 0.8;
			scale: 0.8;
		}
		&.open::after {
			transform: rotate(180deg);
		}
	}
}
</style>
