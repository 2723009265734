<template>
	<div class="detail-wrapper">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ tt('상품주문') }}</h2>
			</div>
		</div>
		<!-- step2 -->
		<div class="common-detail-wrapper regist-step2-wrapper">
			<!-- <delivery-info ref="delivery-info" :isValid="isValid" v-if="draftInfo.purchaseType == 'INTERNAL'" @changeAddress="changeAddress" /> -->
			<div class="product-info-wrapper">
				<div>
					<h5>{{ tt('상품 정보') }}</h5>
					<div class="delivery-wrap">
						<img src="@/assets/svg/store/icon-location.svg" />
						<p @click="showModalAddress">{{ addressText }}</p>
						<span @click="showModalAddress">{{ deliveryInfo ? '변경' : '등록' }}</span>
					</div>
				</div>

				<div class="btn-area">
					<template v-if="draftInfo.purchaseType == 'INTERNAL'">
						<div class="left-btn">
							<image-button
								:image="require('@/assets/svg/store/labmanager-store.svg')"
								:text="'에서 상품검색'"
								@click="storeShowModal('modal-store-search-product')"
							>
							</image-button>
							<merck-tooltip :cssVariable="cssVariable" :isAnimation="true" :isClose="false" :isBrandModal="false">
								<img width="245px" src="@/assets/images/icon-sale-search.png" />
							</merck-tooltip>
						</div>
						<!-- <image-button
							:image="require('@/assets/svg/purchase/icon-cart.svg')"
							:text="'장바구니에서 추가'"
							@click="storeShowModal('modal-add-cart-product')"
						></image-button>
						<image-button
							:text="'견적서로 추가'"
							:image="require('@/assets/svg/purchase/icon-estimate.svg')"
							@click="storeShowModal('modal-estimate')"
						></image-button> -->
					</template>
					<template v-else>
						<image-button
							:image="require('@/assets/svg/Add(Button).svg')"
							:text="'상품 추가'"
							@click="$refs['modal-add-product'].show()"
						></image-button>
					</template>
					<base-button :disabled="selectedItems.length === 0" @click="btnSelectedDelete">{{ tt('선택 삭제') }}</base-button>
				</div>
				<div>
					<product-info-regist-table :productInfoList="draftInfo.purchaseItems"></product-info-regist-table>
				</div>
			</div>
			<!-- m10 기획 변경으로 인한 삭제 -->
			<!-- <div>
				<p style="text-align: center; color: #666">
					{{ tt('추후 정산 시, 선택하는 상품항목에 따라 최종 결제금액이 지출 예정금액 합계와 상이할 수 있습니다.') }}
				</p>
			</div> -->
			<store-order-info-new></store-order-info-new>
			<!-- m10 기획 변경으로 인한 삭제 -->
			<!-- <store-order-info ref="store-order-info" type="ORDER" /> -->
			<budget-info ref="budget-info" :isValid="isValid" />
			<div class="budget-status-wrapper" v-if="getSelectedBudget">
				<total-amount :title="'예산 현황'" :info="amountForm" :budget="getSelectedBudget"></total-amount>
			</div>
			<orderer-info ref="orderer-info" :isValid="isValid" />
			<recipient-info ref="recipient-info" @getOrdererInfo="getOrdererInfo" :isValid="isValid" />
		</div>
		<div class="common-detail-bottom">
			<merck-tooltip v-if="draftInfo.purchaseItems.length > 0 && isMerckTooltipShow" :cssVariable="cssVariable2">
				<img width="360px" src="@/assets/images/icon-sale-order.png" />
			</merck-tooltip>
			<BadgeLimitCredit style="position: absolute; left: 30px" />
			<base-button style="width: 320px" size="lg" :color="'primary'" @click="submit">{{ tt('주문하기') }}</base-button>
		</div>
		<modal-confirm
			ref="modal-estimate-delete"
			title="상품 삭제"
			content="견적서로 추가된 상품은 전체 삭제됩니다.<br>상품 전체 삭제하시겠습니까?"
			confirmText="전체 삭제"
		/>
		<modal-add-product ref="modal-add-product" @addProduct="addPurchaseItem" />
		<modal-store-search-product ref="modal-store-search-product" />
		<modal-estimate ref="modal-estimate" @addProductByEstimate="addProductByEstimate" />
		<modal-add-cart-product ref="modal-add-cart-product" />
		<modal-payment-requirement @ok="submit"></modal-payment-requirement>

		<modal-confirm
			ref="modal-order-confirm"
			title="상품을 주문하시겠습니까?"
			content="상품주문은 후불결제 방식으로 진행됩니다. 랩매니저 스토어에 주문이 접수되며,<br>판매자의 주문확인 및 주문승인 처리 후 배송이 시작됩니다."
			confirmText="주문하기"
		/>

		<modal-confirm
			ref="modal-requirement-out"
			title="페이지 이동"
			confirmText="나가기"
			content="주문 필수 정보를 입력하여야 상품 주문이 가능합니다.<br>페이지를 나가시겠습니까?"
		/>
		<modal-confirm
			ref="modal-store"
			title="랩매니저 스토어"
			confirmText="랩매니저 스토어로 가기"
			content="랩매니저 스토어 이용 기록이 없는 기관입니다.<br>랩매니저 스토어에서 기관 설정을해주세요"
		/>
		<modal-confirm
			ref="modal-can-order"
			title="주문불가"
			confirmText="확인"
			variant="warning"
			content="배송 가능한 상품이 없습니다.<br>상품을 다시 한번 확인해주세요."
		/>
		<modal-confirm
			ref="modal-load-purchase"
			title="완료되지 않은 주문 정보가 있습니다"
			confirmText="불러오기"
			cancelText="아니오"
			content="완료되지 않은 주문 정보를 불러오시겠습니까?"
		/>
		<modal-loading ref="modal-loading" />
		<modal-remain-later-pay ref="modal-remain-later-pay" />
		<modal-add-address ref="modal-add-address" @success="initDeliveryInfo" />
		<modal-edit-address ref="modal-edit-address" @add="$refs['modal-add-address'].show()" @remove="initDeliveryInfo" @change="changeAddress" />
		<ModalLicenseAndSale ref="ModalLicenseAndSale" @purchaseWithoutSale="purchaseWithoutSale" @purchaseConfirm="submit" />
		<ModalNoSaleOrder
			ref="ModalNoSaleOrder"
			:items="draftInfo.purchaseItems"
			@prev="$refs['ModalLicenseAndSale'].show()"
			@change="changeNormalPrice"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TotalAmount from '@/views/purchase/draft/components/detail/TotalAmount.vue'
import ProductInfoRegistTable from '@/views/purchase/draft/components/regist/ProductInfoRegistTable.vue'
import ModalAddProduct from '@/views/purchase/draft/components/modal/ModalAddProduct.vue'
import ModalStoreSearchProduct from '@/views/common/components/modalGen2/ModalStoreSearchProduct.vue'
import ModalEstimate from '@/views/purchase/draft/components/modal/ModalEstimate.vue'
import ModalAddCartProduct from '@/views/common/components/modalGen2/ModalAddCartProduct.vue'
import ModalLoading from '@/views/common/components/modalGen2/ModalLoading.vue'
import StoreOrderInfo from '@/views/purchase/draft/components/regist/StoreOrderInfo.vue'
import DeliveryInfo from '@/views/purchase/draft/components/regist/DeliveryInfo.vue'
import OrdererInfo from '@/views/purchase/draft/components/regist/OrdererInfo.vue'
import RecipientInfo from '@/views/purchase/draft/components/regist/RecipientInfo.vue'
import ModalPaymentRequirement from '@/views/purchase/componetns/modal/ModalPaymentRequirement.vue'
import BudgetInfo from '@/views/purchase/draft/components/regist/BudgetInfo.vue'
import PageName from '@/utils/define/PageName'
import MixinStore from '@/mixins/store'
import MixinLicense from '@/mixins/license'
import ModalRemainLaterPay from '../components/modal/ModalRemainLaterPay.vue'
import MerckTooltip from '@/views/common/components/tooltip/MerckTooltip.vue'
import ModalAddAddress from '@/views/purchase/draft/components/modal/ModalAddAddress.vue'
import ModalEditAddress from '@/views/purchase/draft/components/modal/ModalEditAddress.vue'
import StoreOrderInfoNew from '@/views/purchase/draft/components/regist/StoreOrderInfoNew.vue'
import ModalLicenseAndSale from '../components/modal/ModalLicenseAndSale.vue'
import ModalNoSaleOrder from '../components/modal/ModalNoSaleOrder.vue'
import BadgeLimitCredit from '../components/order/BadgeLimitCredit'
import { BUS } from '@/utils/modules/EventBus'

const SELLER_KEY = {
	name: 'sellerName',
	contactName: 'sellerManager',
	contactPhone: 'sellerPhone',
	address: 'sellerAddress',
	businessNumber: 'sellerBusinessNumber',
}
const PURCHASE_LIST_KEY = 'PurchaseItems-SavedList'
export default {
	name: 'ProductOrder',
	props: {},
	components: {
		TotalAmount,
		ProductInfoRegistTable,
		ModalAddProduct,
		ModalStoreSearchProduct,
		ModalEstimate,
		ModalAddCartProduct,
		StoreOrderInfo,
		DeliveryInfo,
		OrdererInfo,
		RecipientInfo,
		ModalPaymentRequirement,
		BudgetInfo,
		ModalLoading,
		ModalRemainLaterPay,
		MerckTooltip,
		ModalAddAddress,
		ModalEditAddress,
		StoreOrderInfoNew,
		ModalLicenseAndSale,
		ModalNoSaleOrder,
		BadgeLimitCredit,
	},
	mixins: [MixinStore, MixinLicense],
	created() {
		BUS.$on('merckTooltipHide', isShow => {
			this.isMerckTooltipShow = isShow
		})
	},
	data() {
		return {
			isValid: false,
			colorList: [
				{
					backgroundColor: '#eb3c96',
					textColor: '#fff',
					imageUrl: require('@/assets/svg/icon/icon-merck.svg'),
				},
				{
					backgroundColor: '#2dbecd',
					textColor: '#503291',
					imageUrl: require('@/assets/svg/icon/icon-merck-503291.svg'),
				},
				{
					backgroundColor: '#503291',
					textColor: '#96d7d2',
					imageUrl: require('@/assets/svg/icon/icon-merck-96d7d2.svg'),
				},
				{
					backgroundColor: '#f8cc46',
					textColor: '#503291',
					imageUrl: require('@/assets/svg/icon/icon-merck-503291.svg'),
				},
				{
					backgroundColor: '#a5cd50',
					textColor: '#0f69af',
					imageUrl: require('@/assets/svg/icon/icon-merck-0f69af.svg'),
				},
			],
			randomNumber: Math.floor(Math.random() * 5),
			deliveryInfo: null,
			isMerckTooltipShow: true,
		}
	},
	computed: {
		...mapState('draft/item', ['draftInfo', 'selectedItems']),
		...mapGetters('draft/item', ['getSelectedBudget']),
		cssVariable() {
			return {
				'--top': '-30%',
				'--left': '100%',
				'--backgroundColor': 'transparent',
			}
		},
		cssVariable2() {
			return {
				'--top': '-100%',
				'--backgroundColor': 'transparent',
			}
		},
		amountForm() {
			let form = {}
			if (!this.getSelectedBudget) return form
			let thisMonth = this.getSelectedBudget.budgetType === 'MONTH' ? `${this.getSelectedBudget.month}월` : ''
			form = {
				title: {
					name: `${this.getSelectedBudget.code} ${this.getSelectedBudget.name}`,
					status: this.getSelectedBudget.budgetType,
				},
				total: {
					name: this.tt('${1}년 ${2} 남은 잔액', this.getSelectedBudget.year, thisMonth),
					value: this.getSelectedBudget.summary?.budget ? this.getSelectedBudget.summary.budget : 0,
				},
				expense: {
					name: this.tt('지출 예정 금액'),
					value: this.draftInfo.expendAmount,
				},
				balance: {
					name: this.tt('결제 후 예상 잔액'),
					value: this.getSelectedBudget.summary.budget - this.draftInfo.expendAmount,
				},
			}
			return form
		},
		// deliveryInfo() {
		// 	if (this.deliveryList.length === 0 || !this.deliveryList[0].address) return null
		// 	return this.deliveryList.find(it => it.isDefault) ?? this.deliveryList[0]
		// },
		addressText() {
			if (!this.deliveryInfo) return '배송지가 없습니다'
			return `${this.deliveryInfo.address}, ${this.deliveryInfo.addressDetail}`
		},
	},
	watch: {
		'draftInfo.purchaseItems': {
			handler(newValue) {
				const obj = {
					value: newValue.map(it => {
						return {
							productId: it.productId,
							quantity: it.quantity,
						}
					}),
					expire: this.$DateTime.now().plus({ days: 3 }).toISO(),
				}
				localStorage.setItem(PURCHASE_LIST_KEY, JSON.stringify(obj))
			},
			deep: true,
		},
	},
	mounted() {
		this.initDraftRegistration()
		this.initDraftRegistrationStep2()
		this.initPurchase()
		this.initLMS()
	},
	methods: {
		...mapMutations('draft/item', [
			'setDraftInfo',
			'initPurchaseItems',
			'deleteAttachments',
			'initDraftRegistration',
			'initDraftRegistrationStep2',
			'setOrdererInfo',
			'setNoSale',
		]),
		...mapActions('draft/item', ['setPurchaseItems', 'deletePurchaseItems', 'createOrder', 'savedProductList']),
		...mapMutations('draft/form', ['setDeliveryId']),
		async initPurchase() {
			const purchaseObj = JSON.parse(localStorage.getItem(PURCHASE_LIST_KEY))
			if (this.$DateTime.now() > this.$DateTime.fromISO(purchaseObj?.expire)) {
				await this.$nextTick()
				localStorage.removeItem(PURCHASE_LIST_KEY)
				return
			}

			if (purchaseObj?.value?.length > 0) {
				this.$refs['modal-load-purchase'].show(
					async () => {
						await this.savedProductList(purchaseObj.value)
						this.$root.toast(`주문 정보 불러오기`, `주문 정보를 불러왔습니다.`, 'success')
					},
					() => {
						localStorage.removeItem(PURCHASE_LIST_KEY)
					}
				)
			}
		},
		async initLMS() {
			await this.$nextTick()
			if (!this.lmsInstituteId) await this.getLMSInstituteId()
			if (this.lmsInstituteId == null) {
				this.$refs['modal-store'].show(() => {
					// console.log(storeUrl())
					window.open(`${storeUrl()}?isPro=true&instituteId=${this.lmsInstituteId}`)
				})
				return
			} else {
				// m10 - 진입시 주문 필수정보 모달 주석 ( 기획 변경 )
				// await this.getRequirement()
				// if ((this.requirement?.canOrder || false) == false) {
				// 	this.$bvModal.show('ModalPaymentRequirement')
				// 	return
				// }
				await this.getDeliveryList()
				this.initDeliveryInfo()
				this.$refs['modal-remain-later-pay'].show()
			}
		},
		initDeliveryInfo() {
			if (this.deliveryList.length > 0 && this.deliveryList[0].address)
				this.deliveryInfo = this.deliveryList.find(it => it.isDefault) ?? this.deliveryList[0]
			else this.deliveryInfo = null
		},
		// // m10 - 필수정보 미 입력시 나가기 or 필수정보 재진입 안내 모달 주석 ( 기획 변경 )
		// async cancelRequirement() {
		// 	await this.getRequirement()
		// 	if ((this.requirement?.canOrder || false) == false) {
		// 		this.$refs['modal-requirement-out'].show(
		// 			() => {
		// 				this.$router.go(-1)
		// 			},
		// 			() => {
		// 				this.$bvModal.show('ModalPaymentRequirement')
		// 			}
		// 		)
		// 	}
		// },
		addPurchaseItem(item) {
			this.setPurchaseItems(item)
		},
		btnSelectedDelete() {
			if (this.draftInfo.estimateId != null) {
				this.$refs['modal-estimate-delete'].show(() => {
					this.initPurchaseItems()
				})
			} else this.deletePurchaseItems()
		},
		storeShowModal(modalName) {
			if (this.deliveryInfo && this.deliveryInfo.id) {
				this.setDeliveryId(this.deliveryInfo.id)
			}
			this.$refs[modalName].show()
			// m10 - 스토어 검색 모달 진입시 배송지 필수 주석 ( 기획 변경 )
			// const deliveryId = this.$refs['delivery-info'].info.deliveryId
			// if (!deliveryId) this.$root.toast(`배송지 선택`, `배송지를 선택해주세요.`, 'error')
			// else {
			// 	this.setDeliveryId(deliveryId)
			// 	this.$refs[modalName].show()
			// }
		},
		getOrdererInfo() {
			this.setOrdererInfo(this.$refs['orderer-info'].info)
		},
		changeAddress(id) {
			this.deliveryInfo = this.deliveryList.find(it => it.id === id)
			this.$root.toast(`배송지 변경 완료`, `배송지가 변경 되었습니다.`, 'success')
			this.initPurchaseItems()
		},
		async validation() {
			this.isValid = false
			if (this.draftInfo.purchaseItems.length === 0) {
				// this.$root.toast('상품 정보 미입력', '상품을 추가하여 주세요.', 'error')
				throw 'no product'
			}
			if (this.draftInfo.purchaseType == 'INTERNAL') {
				// 할인 적용된 금액
				const salePrice = this.draftInfo.purchaseItems
					.map(it => it.sellingPrice)
					.reduce((acc, cur) => {
						return (acc += cur)
					}, 0)
				// 원래 금액
				const normalPrice = this.draftInfo.purchaseItems.reduce((acc, current) => {
					acc += current.unitPrice * current.quantity
					return acc
				}, 0)
				// ( isPurchaseWithoutSale - true: 머크 제품 아닌거로만 구성 or 라이선스 미구매로 할인제외셋팅한거 )
				const isPurchaseWithoutSale = salePrice === normalPrice
				if ((this.isTrial || this.isFreeTier) && !isPurchaseWithoutSale) {
					// isPurchaseWithoutSale
					throw 'no license'
				}
				await this.getRequirement()
				if (!this.deliveryInfo?.id) {
					await this.getDeliveryList()
					this.initDeliveryInfo()
				}
				// const deliveryInfo = this.$refs['delivery-info'].info
				if ((this.requirement?.canOrder || false) == false || !this.deliveryInfo?.id) {
					throw 'no require'
				}
				const deliveryInfo = this.deliveryInfo.id
				const budgetInfo = this.$refs['budget-info'].info
				const ordererInfo = this.$refs['orderer-info'].info
				const recipientInfo = this.$refs['recipient-info'].info
				const lmsInfo = { deliveryId: deliveryInfo, ...budgetInfo, ...ordererInfo, ...recipientInfo }
				console.log('lmsInfo', lmsInfo)
				Object.keys(lmsInfo).forEach(key => {
					if (lmsInfo[key] == '' && key != 'deliveryRequest') {
						this.isValid = true
						throw 'invalid'
					}
				})
				this.setDraftInfo({ ...this.draftInfo, ...lmsInfo })
			}
		},
		//할인없이 주문하기
		purchaseWithoutSale() {
			this.$refs['ModalNoSaleOrder'].show()
		},
		changeNormalPrice() {
			// 정상 판매가로 변경
			this.setNoSale()
			this.$root.toast(`주문금액 변경 완료`, `할인 미적용 금액으로 변경되었습니다.`, 'success')
		},
		addProductByEstimate(estimate) {
			let estimateArr = []
			this.initPurchaseItems()
			estimate.products.forEach(item => {
				let selectItem = {}
				Object.entries(item).forEach(([key, value]) => {
					if (key === 'id') {
						selectItem['estimateItemId'] = value
					} else if (value) {
						selectItem[key] = value
					}
				})
				estimateArr.push(selectItem)
			})
			this.setPurchaseItems(estimateArr)
			this.setDraftInfo({ ...this.draftInfo, estimateId: estimate.id })
		},
		showModalAddress() {
			if (this.deliveryInfo) this.$refs['modal-edit-address'].show(this.deliveryInfo.id)
			else this.$refs['modal-add-address'].show()
		},
		editAddress(item) {
			this.$refs['modal-add-address'].show(item)
		},
		async submit() {
			try {
				await this.validation()
			} catch (err) {
				if (err == 'no product') {
					this.$root.toast('상품 추가', '상품을 추가해주세요.', 'error')
				} else if (err == 'no require') {
					// 필수정보 미입력
					this.$bvModal.show('ModalPaymentRequirement')
				} else if (err == 'no license') {
					if (this.draftInfo.purchaseItems.some(it => it.discountPercent > 0)) this.$refs['ModalLicenseAndSale'].show()
				} else {
					this.$root.toast('필수값 미입력', '필수값을 입력해주세요.', 'error')
				}
				return
			}
			const canOrderitem = this.draftInfo.purchaseItems.filter(it => !it.isBlock)
			if (canOrderitem?.length == 0) {
				this.$refs['modal-can-order'].show()
				return
			}

			this.$refs['modal-order-confirm'].show(async () => {
				const formData = { purchaseItems: [] }
				Object.entries(this.draftInfo).forEach(([key, value]) => {
					switch (key) {
						case 'purchaseItems':
							value.forEach((item, idx) => {
								let purchaseItem = {}
								if (item.isBlock) return
								console.log('item', item)
								Object.entries(item).forEach(([itemKey, itemValue]) => {
									if (itemValue && itemKey)
										if (itemKey === 'seller') {
											Object.entries(itemValue).forEach(([k, v]) => {
												if (v && SELLER_KEY[k]) {
													purchaseItem[SELLER_KEY[k]] = v
												}
											})
										} else if (itemKey === 'brand') {
											Object.entries(itemValue).forEach(([k, v]) => {
												if (k === 'id' && v) purchaseItem['brandId'] = v
												else if (k === 'name' && v) purchaseItem['brandName'] = v
												else if (k === 'image' && v) purchaseItem['brandImage'] = v
											})
										} else if (itemKey === 'category') {
											if (itemValue.code) purchaseItem['categoryType'] = itemValue.code
										} else if (itemKey === 'productId') {
											purchaseItem['id'] = itemValue
										} else purchaseItem[itemKey] = itemValue
								})
								formData.purchaseItems.push(purchaseItem)
							})
							break
						default:
							if (value) formData[key] = value
							break
					}
				})
				try {
					this.$refs['modal-loading'].show()
					const response = await this.createOrder(formData)
					if (response.status == 201 || response.status == 205) {
						localStorage.removeItem(PURCHASE_LIST_KEY)
						this.$root.toast(`주문완료`, `주문이 완료되었습니다.`, 'success')
						this.$router.push({
							name: PageName.Purchase.Product.OrderComplete,
							params: {
								id: response.data?.orderId,
							},
						})
						// this.$router.push({ name: PageName.Purchase.Product.Pay })
					}
				} catch (err) {
					console.log(err)
					this.$root.toast('주문실패', err?.response?.data?.msg ?? '오류가 발생했습니다. 잠시 후 다시 시도해주세요', 'error')
				}
				this.$refs['modal-loading'].hide()
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.delivery-wrap {
	margin-left: auto;
	font-weight: 400;
	font-size: 14px;
	display: inline-flex;
	align-items: center;
	height: 45px;
	gap: 5px;
	p {
		max-width: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	span {
		color: $TEXT_YELLOW;
		font-weight: 700;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
}
.regist-step2-wrapper {
	margin-bottom: 50px;
	h5 {
		font-weight: 700;
		// margin-bottom: 30px;
	}
	& > div {
		margin-bottom: 30px;
	}
	.product-info-wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 30px;
		div {
			display: flex;
			& > button {
				&:nth-last-child(1) {
					margin-left: auto;
					justify-content: flex-end;
				}
			}
		}
		.btn-area {
			gap: 20px;
			.left-btn {
				position: relative;
			}
		}
	}
	.file-wrapper {
		h5 {
			margin-bottom: 30px;
		}
		.type-file {
			.upload-box {
				// width: 800px;
				height: 120px;
				border: 1px dashed $FILE_BORDER;
				position: relative;

				overflow-y: auto;
				cursor: pointer;
				margin-bottom: 10px;
				input {
					width: 100%;
					height: 100%;
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					padding: 0 0;
					cursor: pointer;
					&.isFile {
						visibility: hidden;
					}
				}
				.file-box {
					text-align: center;
					width: 100%;
					z-index: 2;
					// position: relative;
					padding-top: 26px;
					.btn-wrap {
						width: 100%;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						button {
							margin-top: 10px;
							cursor: pointer;
						}
					}
				}
				.file-list {
					width: 100%;
					padding: 14px 10px;
					height: 100%;
					// position: relative;
					z-index: 2;
					li {
						width: 100%;
						height: 30px;
						display: flex;
						align-items: center;
						font-size: 0.875rem;
						font-weight: 400;
						img {
							margin-right: 2px;
						}
						button {
							position: relative;
						}
					}
				}
			}
		}
	}
}
</style>
