<template>
	<b-modal ref="modal" size="lg" hide-footer centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">
				{{ tt('법령별 출처') }}
			</div>
		</template>
		<template>
			<div class="content">
				<h4>{{ tt('위험물관리법') }}</h4>
				<p>
					{{ tt('국가위험물 정보시스템') }}
				</p>
				<h4>{{ tt('산업안전보건법') }}</h4>
				<p>
					{{ tt('안전보건공단 화학물질정보') }}
				</p>
				<p>
					{{ tt('국가법령정보센터') }}
				</p>
				<h4>{{ tt('화학물질관리법') }}</h4>
				<p>
					{{ tt('화학물질정보시스템') }}
				</p>
				<p>
					{{ tt('화학물질종합정보시스템') }}
				</p>
				<h4>{{ tt('폐기물관리법') }}</h4>
				<p>
					{{ tt('국가법령정보센터') }}
				</p>
				<p>
					{{ tt('화학물질종합정보시스템') }}
				</p>
				<div class="comment">*일부 CAS no의 경우 자체적으로 수집한 Data를 기반함</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.modal-lg {
		width: 700px !important;
	}
}
::v-deep .modal-header {
	padding: 20px;
	border: 0;
	.title {
		font-size: 18px;
		font-weight: 700;
	}
}
.content {
	padding: 20px;
	border-radius: 5px;
	background-color: $GREY_1;
	h4 {
		font-size: 14px;
		font-weight: bold;
		color: $DEFAULT;
		margin-top: 30px;
		margin-bottom: 10px;
		&:first-of-type {
			margin-top: 0px;
		}
	}
	p {
		padding-left: 5px;
		display: list-item;
		list-style-position: inside;
		margin-top: 5px;
		font-size: 12px;
		color: $DEFAULT;
	}
	.comment {
		margin-top: 30px;
		font-size: 12px;
		color: $SECONDARY_2;
	}
}
</style>
