var render = function () {
  var _vm$inputValue;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-input",
    "class": {
      error: _vm.error
    }
  }, [_vm.name ? _c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.name))), _vm.required ? _c('span', {
    staticClass: "required"
  }, [_vm._v("*")]) : _vm._e()]) : _vm._e(), _vm.isCasno && !_vm.isCasValid && ((_vm$inputValue = _vm.inputValue) === null || _vm$inputValue === void 0 ? void 0 : _vm$inputValue.length) > 0 ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'CAS 공식 홈페이지에서 제공하는 CAS No 검증 로직에 따라 CAS No 형식에 맞는 값을 입력했는지 검증한 결과입니다.',
      expression: "'CAS 공식 홈페이지에서 제공하는 CAS No 검증 로직에 따라 CAS No 형식에 맞는 값을 입력했는지 검증한 결과입니다.'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "casno-valid",
    "class": {
      valid: _vm.isCasValid
    }
  }, [_vm._v(" *CAS No를 다시 한번 확인해주세요 ")]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.tt(_vm.placeholder),
      "maxlength": _vm.max
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.inputValue = $event.target.value;
      }, _vm.handleChange]
    }
  })]), _vm.error ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.tt(_vm.errorMessage)))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }