import cargoAPI from '@/services/api/purchase/cargo'
import { random, cloneDeep } from 'lodash'
import { FindLargeClass, LargeClass } from '@/utils/define/ItemCode'
import categoryAPI from '@/services/api/category'

const state = {
	cargoFormType: 'ADD', // ADD, EDIT, NEED_INPUT_LIST, NEED_INPUT_DETAIL(입력필요)
	cargoInfo: {
		multiCreateCount: 1,
	},
	category: null,
	categoryInfo: null,
	uploadMsds: null,
	isAutoCargo: false, // 자동 입고 여부
	recentRegistCargoId: null, // 자동 입고시 사용할 registCargoId
	showError: false,
}

const findFieldIdxByKeyName = keyname => state.categoryInfo?.fields?.inventoryFields?.findIndex(it => it.key == keyname)

const getters = {
	cargoFormType: state => state.cargoFormType,
	cargoInfo: state => state.cargoInfo,
	category: state => state.category,
	categoryInfo: state => {
		const categoryInfo = cloneDeep(state.categoryInfo)
		const currentVolumeIndex = findFieldIdxByKeyName('currentVolume')
		const volumeIndex = findFieldIdxByKeyName('volume')
		if (categoryInfo?.classification === LargeClass.CHEMICAL && currentVolumeIndex !== -1) {
			if (categoryInfo?.fields) {
				categoryInfo.fields.inventoryFields[currentVolumeIndex].unit = categoryInfo?.fields?.inventoryFields[volumeIndex].unit
			}
		}
		return categoryInfo
	},
	isActiveExpireDate: state => state?.categoryInfo?.fields?.managementFields?.some(f => f.isActive && !f.readOnly && f.key == 'expireDatetime'),
	expirationDay: state => state.categoryInfo?.expirationDay ?? 730,
	multiCreateCount: state => state?.cargoInfo?.multiCreateCount,
	cargoFormData: state => {
		if (state.category) {
			const formData = new FormData()
			Object.entries(state.cargoInfo).forEach(([key, value]) => {
				if (value) {
					if (
						['EDIT', 'NEED_INPUT_LIST', 'NEED_INPUT_DETAIL'].includes(state.cargoFormType) &&
						['storage', 'register', 'assignee', 'msds', 'status', 'category', 'legalList', 'image'].includes(key)
					) {
						return
					}
					switch (key) {
						case 'ingredient':
							if (value.length === 0) {
								formData.append(key, '')
							} else {
								let count = 0
								value.forEach((item, idx) => {
									if (item.name && item.ratio) {
										formData.append(`${key}[${count}][name]`, item.name)
										formData.append(`${key}[${count}][ratio]`, item.ratio)

										if (item.casno) {
											formData.append(`${key}[${count}][casno]`, item.casno)
										}
										count++
									}
								})
							}
							break
						case 'images':
							value.forEach(item => formData.append(`${key}`, item))
							break
						case 'tagsId':
							// 편집시 데이터 없는 경우
							if (value.length === 0) {
								formData.append(key, '')
							} else {
								value.forEach(item => formData.append(`${key}[]`, item))
							}
							break
						case 'deleteImages':
						case 'qrSticker':
							if (value.length === 0) {
								formData.append(key, '')
							} else {
								value.forEach(item => formData.append(`${key}[]`, item))
							}
							break
						case 'ghs':
							value.dangerCode.forEach((item, idx) => {
								formData.append(`ghs[dangerCode][${idx}]`, item)
							})
							value.precautionCode.forEach((item, idx) => {
								formData.append(`ghs[precautionCode][${idx}]`, item)
							})
							let { address, name, phone } = value.provider
							formData.append(`ghs[provider][address]`, address)
							formData.append(`ghs[provider][name]`, name)
							formData.append(`ghs[provider][phone]`, phone)
							formData.append(`ghs[etc]`, value?.etc)
							break
						default:
							formData.append(key, value)
							break
					}
				} else {
					if (key === 'deleteGhs') formData.append('deleteGhs', value)
				}
			})
			if (state.uploadMsds) {
				formData.append('msds', state.uploadMsds)
			}
			return formData
		} else {
			return null
		}
	},
	isProductValid: state => {
		if (state.categoryInfo) {
			const requireFields = state.categoryInfo?.fields?.inventoryFields
				.filter(f => f.isActive && !f.readOnly && f.isRequire)
				.map(item => item.key)
			const useUnitFields = state.categoryInfo?.fields?.inventoryFields
				.filter(f => (f.isActive && !f.readOnly && f?.unit?.length > 1) || f.key === 'currentVolume')
				.map(item => item.key)
			let isRequireData = true
			if (!requireFields || !useUnitFields) return false
			requireFields?.forEach(requireKey => {
				// 필수 값인 경우
				if (!state.cargoInfo[requireKey]) {
					isRequireData = false
				}
			})
			return isRequireData
		} else return false
	},
	isUnitValid: state => {
		const useUnitFields = state.categoryInfo?.fields?.inventoryFields
			.filter(
				f => f.isActive && !f.readOnly && f?.unit?.length > 1 && (f.key !== 'currentVolume' || state.category.classification == 'CAT_ETC')
			)
			.map(item => item.key)
		let isRequireData = true
		useUnitFields?.forEach(unitKey => {
			if (!!state.cargoInfo[unitKey] && !state.cargoInfo[`${unitKey == 'currentVolume' ? 'volume' : unitKey}Unit`]) {
				// 값은 있지만 Unit이 없는 경우
				isRequireData = false
			}
		})
		return isRequireData
	},
	isIngredientValid: state => {
		let validIngredient = state.cargoInfo?.ingredient?.filter(i => i.name || i.ratio || i.casno)
		if (validIngredient?.length > 0) {
			return validIngredient.find(i => !i.name || !i.ratio) === undefined
		} else return true
	},
	isManagementValid: state => {
		if (state.categoryInfo) {
			const requireFields = state?.categoryInfo?.fields.managementFields
				.filter(f => f.isActive && !f.readOnly && f.isRequire)
				.map(item => item.key)
			let isRequireData = true
			requireFields.forEach(requireKey => {
				// 필수 값인 경우
				if (!state.cargoInfo[requireKey]) {
					isRequireData = false
				}
			})

			return isRequireData
		} else return false
	},
	isManagementUnitValid: state => {
		const useUnitFields = state.categoryInfo?.fields?.managementFields
			.filter(f => (f.isActive && !f.readOnly && f?.unit?.length > 1) || f.key === 'currentVolume')
			.map(item => item.key)
		let isRequireData = true
		useUnitFields?.forEach(unitKey => {
			if (!!state.cargoInfo[unitKey] && !state.cargoInfo[`${unitKey}Unit`]) {
				// 값은 있지만 Unit이 없는 경우
				isRequireData = false
			}
		})
		return isRequireData
	},
	isCategoryId: state => state.categoryInfo?.id,
	showError: state => state.showError,
}

const mutations = {
	setInitCargoInfo: state => {
		state.cargoInfo = {
			multiCreateCount: 1,
		}
		state.category = null
		state.categoryInfo = null
		state.isAutoCargo = false
		state.recentRegistCargoId = null
		state.uploadMsds = null
	},
	setCargoInfo: (state, cargoInfo) => {
		state.cargoInfo = cargoInfo
		if (cargoInfo?.brand_id) {
			state.cargoInfo.brandId = cargoInfo.brand_id
		}
		if (cargoInfo?.ingredientList) {
			state.cargoInfo.ingredient = cloneDeep(cargoInfo.ingredientList)
		}
		if (typeof cargoInfo?.qrSticker === 'string') {
			state.cargoInfo.qrSticker = [cargoInfo?.qrSticker]
		}
	},
	setCategory: (state, category) => {
		state.category = category
		if (category) {
			state.cargoInfo.categoryType = category.type
			state.cargoInfo.categoryId = category.id
		}
	},
	setCategoryType: (state, categoeyType) => {
		state.cargoInfo.categoryType = categoeyType
	},
	setMultiCreateCount: (state, count) => {
		state.cargoInfo.multiCreateCount = count
	},
	setCategoryInfo: (state, categoryInfo) => {
		state.categoryInfo = categoryInfo
	},
	setIngredientInfo: (state, ingredient) => {
		const cargoInfo = { ...state.cargoInfo }
		cargoInfo.ingredient = ingredient
		state.cargoInfo = cargoInfo
	},
	setMsds: (state, msds) => {
		state.uploadMsds = msds
	},
	setAutoCargo: (state, isAutoCargo) => {
		state.isAutoCargo = isAutoCargo
	},
	setRecentRegistCargoId: (state, cargoId) => {
		state.recentRegistCargoId = cargoId
	},
	setCargoFormType: (state, formType) => {
		state.cargoFormType = formType
	},
	setShowError: (state, showError) => {
		state.showError = showError
	},
}

const actions = {
	getCategoryInfo: async ({ commit, rootGetters }, categoryId) => {
		const response = await cargoAPI.getCategoryInfo(rootGetters.instituteId, {
			id: categoryId,
		})
		commit('setCategoryInfo', response.data)
	},
	getCategoryFieldsByType: async ({ commit, rootGetters }, categoryType) => {
		const response = await categoryAPI.getCategoryFieldsByType(rootGetters.instituteId, { type: categoryType })
		commit('setCategoryInfo', {
			classification: FindLargeClass(categoryType),
			type: categoryType,
		})
	},
	selectCategory: async ({ commit, dispatch }, category) => {
		if (category) {
			commit('setCategory', category)
			if (category.id) {
				dispatch('getCategoryInfo', category.id)
			} else {
				dispatch('getCategoryFieldsByType', category.type)
			}
		} else {
			commit('setCategory', null)
		}
	},
	registCargo: async ({ rootGetters, state, getters, commit, dispatch }, registerMethod) => {
		getters.cargoFormData.append('registerMethod', registerMethod)
		const response = await cargoAPI.registCargo(rootGetters.instituteId, getters.cargoFormData)
		if (state.isAutoCargo) {
			await commit('setRecentRegistCargoId', response.data?.id)
			await dispatch('processCargoItem')
		}
	},
	processCargoItem: async ({ rootGetters, state }) => {
		await cargoAPI.processCargoItem(rootGetters.instituteId, { ids: [state.recentRegistCargoId] })
	},
	setForm: async ({ commit, state, dispatch }, item) => {
		commit('setCargoInfo', item)
		if (item.category) {
			dispatch('selectCategory', { ...item.category, classification: FindLargeClass(item.category.type) })
		} else {
			dispatch('selectCategory', {
				type: item.categoryType,
				classification: FindLargeClass(item.categoryType),
			})
		}
	},
	editCargo: async ({ state, rootGetters, getters, commit, dispatch }) => {
		await cargoAPI.editCargo(rootGetters.instituteId, getters.cargoFormData)
		if (state.isAutoCargo) {
			await commit('setRecentRegistCargoId', state.cargoInfo.id)
			await dispatch('processCargoItem')
		}
	},
}
export default { namespaced: true, state, getters, mutations, actions }
