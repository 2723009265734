var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Toast__container",
    "class": [_vm.type]
  }, [_c('div', {
    staticClass: "Toast__inner-container"
  }, [_c('div', {
    staticClass: "Toast__indicator-wrapper"
  }, [_vm.isTypeSuccess ? _c('img', {
    staticClass: "Toast__indicator",
    attrs: {
      "src": require("@/assets/svg/IconSuccess.svg")
    }
  }) : _vm.isTypeWarning ? _c('img', {
    staticClass: "Toast__indicator",
    attrs: {
      "src": require("@/assets/svg/IconWarning.svg")
    }
  }) : _vm.isTypeError ? _c('img', {
    staticClass: "Toast__indicator",
    attrs: {
      "src": require("@/assets/svg/IconError.svg")
    }
  }) : _c('img', {
    staticClass: "Toast__indicator",
    attrs: {
      "src": require("@/assets/svg/IconInfo.svg")
    }
  })]), _c('div', {
    staticClass: "Toast__text-container"
  }, [_c('div', {
    staticClass: "Toast__title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('div', {
    staticClass: "Toast__content",
    domProps: {
      "innerHTML": _vm._s(_vm.tt(_vm.content))
    }
  })]), _c('div', {
    staticClass: "Toast__close-container",
    on: {
      "click": _vm.clickCloseToast
    }
  }, [_c('img', {
    staticClass: "icon-close",
    attrs: {
      "src": require("@/assets/svg/IconClose.svg")
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }