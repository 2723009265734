var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "AddAuthorityMemberPopup Popup__depth3"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-sm"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(_vm._s(_vm.tt(_vm.title)))]), _c('button', {
    staticClass: "Popup__close-btn",
    on: {
      "click": _vm.hide
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('lm-input-search', {
    staticClass: "block",
    attrs: {
      "placeholder": _vm.tt(_vm.searchBoxPlaceholder)
    },
    on: {
      "search": _vm.searchMember
    }
  }), _c('div', {
    staticClass: "Organization__member-list"
  }, [_c('ul', {
    ref: "list",
    staticClass: "horizational-list list-wrap",
    on: {
      "&scroll": function scroll($event) {
        return _vm.scroll.apply(null, arguments);
      }
    }
  }, [!_vm.hasMember ? _c('div', {
    staticClass: "no-data-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/group-none.svg"),
      "alt": "no-image"
    }
  }), !_vm.isSearched ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('기관 내 멤버가 없습니다.') + '<br>' + _vm.tt('멤버를 추가해주세요'))
    }
  }) : _vm._e(), !_vm.isSearched ? _c('button', {
    on: {
      "click": _vm.goToMemberManagement
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('멤버 관리')) + " ")]) : _vm._e(), _vm.isSearched ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('기관 내 멤버가 없습니다.') + '<br>' + _vm.tt('멤버를 추가해주세요'))
    }
  }) : _vm._e()]) : _vm._e(), _vm._l(_vm.memberList.members, function (member) {
    return _c('li', {
      key: member.id,
      staticClass: "Organization__member-item",
      "class": {
        checked: _vm.isChecked(member.id),
        included: _vm.isIncluded(member.id)
      },
      on: {
        "mouseover": function mouseover($event) {
          return _vm.hoverItem(member.id);
        },
        "mouseout": function mouseout($event) {
          return _vm.notHoverItem();
        },
        "click": function click($event) {
          return _vm.selectId(member.id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/member-detail-photo.svg"),
        "alt": "group-image"
      }
    }), _c('div', {
      staticClass: "Organization__group-item-info"
    }, [_c('h6', {
      domProps: {
        "innerHTML": _vm._s(_vm.highlightName(member.name))
      }
    }), _c('p', [_vm._v(_vm._s(_vm.showGroupNames(member.groups)))])]), _c('button', [_c('img', {
      attrs: {
        "src": require("@/assets/svg/add-member-plus.svg"),
        "alt": "삭제 icon"
      }
    })])]);
  })], 2)])], 1), _c('div', {
    staticClass: "Popup__bottom"
  }, [!_vm.isStart ? _c('button', {
    staticClass: "Btn__management",
    on: {
      "click": _vm.goToMemberManagement
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('멤버 관리')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "Popup__complete-btn-group Popup__btn-group"
  }, [_c('button', {
    staticClass: "Btn__close",
    on: {
      "click": _vm.hide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('취소')) + " ")]), _c('button', {
    staticClass: "Btn__complete",
    on: {
      "click": _vm.complete
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('완료')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }