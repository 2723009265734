<template>
	<div class="form-wrap" v-if="productInfo">
		<div class="form-header">
			<div class="form-title">{{ tt(title) }}</div>
			<div class="form-msg" :class="{ error: !isManagementValid || !isUnitValid }">
				{{ isManagementValid && isUnitValid ? tt('입력 완료') : !isUnitValid ? tt('단위를 선택해주세요') : tt('필수값을 입력해주세요') }}
			</div>
		</div>
		<div class="form-data-wrap">
			<component
				ref="required-form"
				v-bind:is="formInput(field)"
				v-for="field in requiredMngFields"
				:name="field.name"
				:item="productInfo"
				:value="formValue(field.key)"
				:unit="formUnit(field.key)"
				:placeholder="formMessage(field.name)"
				:errorMessage="formMessage(field.name)"
				:optionList="formOptionList(field)"
				:required="field.isRequire"
				:key="`${categoryInfo.id}-${field.key}`"
				:maxSize="productInfo.multiCreateCount"
				:isShowError="isShowError"
				:field="field"
				@input="value => inputFormValue(value, field.key)"
				@removeImg="removeImage"
			></component>
			<button v-if="!showManagement && mngFields.length > 0" @click="showManagement = !showManagement">
				<span class="show-detail">{{ tt('상세히 입력하기') }}</span>
				<img src="@/views/common/components/form/svg/chevron-down.svg" width="15" />
			</button>
			<template v-else>
				<component
					ref="form"
					v-bind:is="formInput(field)"
					v-for="field in mngFields"
					:name="field.name"
					:value="formValue(field.key)"
					:unit="formUnit(field.key)"
					:placeholder="formMessage(field.name)"
					:errorMessage="formMessage(field.name)"
					:optionList="formOptionList(field)"
					:required="field.isRequire"
					:key="`${categoryInfo.id}-${field.key}`"
					:maxSize="productInfo.multiCreateCount"
					:isShowError="isShowError"
					:field="field"
					:item="productInfo"
					@input="value => inputFormValue(value, field.key)"
					@removeImg="removeImage"
				></component>
			</template>
		</div>
	</div>
</template>

<script>
const showKey = ['storage', 'assignee', 'threshold', 'expireDatetime', 'lotno', 'incompanyMngcode']
import MixinFormManagement from '@/views/common/components/form/mixin/MixinFormManagement'

export default {
	name: 'FormFoldManagement',
	components: {},
	mixins: [MixinFormManagement],
	props: {
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showManagement: false,
		}
	},
	computed: {
		mngFields() {
			return this.managementFields?.filter(f => !f.isRequire && !showKey.includes(f.key)) ?? []
		},
		requiredMngFields() {
			return this.managementFields?.filter(f => f.isRequire || showKey.includes(f.key)) ?? []
		},
	},
	created() {},
	mounted() {
		if (this.productInfo?.imageList?.length > 0) {
			// 물품 관리 일때
			this.setImageList(
				this.productInfo?.imageList?.length > 0
					? this.productInfo?.imageList?.map(item => (!item && typeof item === 'object' ? item.link : item))
					: []
			)
		} else if (this.productInfo?.images?.length > 0) {
			// 소분시
			this.setImageList(this.productInfo?.images?.length > 0 ? this.productInfo?.images?.map(item => item) : [])
		}
	},
	methods: {
		removeImage(removeImageLink) {
			console.log(removeImageLink)
			removeImageLink.forEach(link => {
				if (!this.removeImageLink?.includes(link)) {
					this.setRemoveImageLink([...(this.removeImageLink ?? []), link])
				}
			})
			this.$emit('removeImg', this.removeImageLink)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-wrap.scss';
.show-detail {
	font-weight: 700;
	font-size: 14px;
	color: #0b6cff;
	line-height: 20px;
	padding-right: 3px;
}
</style>
