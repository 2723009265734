var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.draftInfo.purchaseType == 'INTERNAL' ? _c('div', {
    staticClass: "delivery-info-wrapper"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.tt('배송지 정보')) + " "), _c('span', {
    staticClass: "warning"
  }, [_vm._v(_vm._s(_vm.tt('*배송지에 따라 주문가능한 상품이 달라질 수 있습니다.')))]), _c('image-button', {
    staticClass: "btn-add",
    attrs: {
      "image": require('@/assets/svg/icon/black-round-plus.svg'),
      "text": _vm.tt('새 배송지 등록')
    },
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-add-address'].show();
      }
    }
  })], 1), _c('div', {
    staticClass: "delivery-wrapper"
  }, [_c('input-select', {
    ref: "deliveryId",
    attrs: {
      "title": '배송지 선택',
      "placeholder": '배송지를 선택해주세요',
      "isRequired": "",
      "list": _vm.selectList,
      "item": _vm.draftInfo.deliveryId,
      "inValidText": '배송지를 선택해주세요.',
      "isValid": !_vm.isValid || !!_vm.$refs['deliveryId'].value
    },
    on: {
      "change": _vm.change
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c('image-button', {
          staticClass: "btn-add",
          attrs: {
            "image": require('@/assets/svg/icon/black-round-plus.svg'),
            "text": _vm.tt('새 배송지 등록')
          },
          on: {
            "click": function click($event) {
              return _vm.$refs['modal-add-address'].show();
            }
          }
        })];
      },
      proxy: true
    }], null, false, 2463376454)
  }, [_vm.delivery ? _c('div', {
    staticClass: "select-delivery-info"
  }, [_vm.delivery.isDefault ? _c('div', {
    staticClass: "default"
  }, [_vm._v(_vm._s(_vm.tt('기본 배송지')))]) : _vm._e(), _c('h6', [_vm._v(_vm._s(_vm.delivery.name))]), _c('p', {
    staticClass: "address"
  }, [_vm._v("[" + _vm._s(_vm.delivery.postcode) + "] " + _vm._s(_vm.delivery.address))]), _c('p', {
    staticClass: "sub"
  }, [_vm._v(" " + _vm._s(_vm.delivery.instituteName ? _vm.delivery.instituteName : '') + " " + _vm._s(_vm.delivery.groupName ? ' / ' + _vm.delivery.groupName : '') + " ")])]) : _vm._e()])], 1), _c('modal-add-address', {
    ref: "modal-add-address"
  }), _c('modal-confirm', {
    ref: "modal-change",
    attrs: {
      "title": "배송지 변경",
      "content": "배송지를 변경하는 경우 추가된 상품이 모두 삭제됩니다.<br>정말로 배송지 변경하시겠습니까?",
      "confirmText": "변경하기"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }