<template>
	<div class="lm-input-wrapper">
		<span class="input-title">
			{{ title }}
			<p class="required" v-if="isRequired">*</p>
		</span>
		<input class="lm-input" :placeholder="placeholder" :class="[{ error: isError }]" :value="value" @input="handleInput" maxlength="13" />
		<span class="input-error-msg" v-if="isError">
			<img src="@/assets/svg/input-icon-warning.svg" />
			<p>
				{{ errorMessage }}
			</p>
		</span>
	</div>
</template>

<script>
import Validation from '@/utils/Validation'
export default {
	props: {
		// 필수 값 별표 표시 유무
		isRequired: {
			type: Boolean,
			default: false,
		},
		// 에러 상태 값
		isError: {
			type: Boolean,
			default: false,
		},
		// Form Title에 들어갈 메시지
		title: {
			type: String,
			default: '',
		},
		// input에 사용할 placeholder
		placeholder: {
			type: String,
			default: '',
		},
		// 에러시 사용할 에러메시지
		errorMessage: {
			type: String,
			default: '',
		},
		value: {},
	},
	watch: {
		changeValue: {
			deep: true,
			handler(newValue) {
				const phoneNumber = Validation.autoHypenTel(newValue)
				this.$emit('value', phoneNumber)
			},
		},
	},
	data() {
		return {
			changeValue: '',
		}
	},
	methods: {
		handleInput(event) {
			this.changeValue = event.target.value
		},
	},
}
</script>

<style lang="scss" scoped>
.lm-input-wrapper {
	display: flex;
	flex-direction: column;
	span.input-title {
		font-size: 12px;
		padding-bottom: 8px;

		p.required {
			color: $RED;
		}
	}
	input.lm-input {
		border: 1px solid #cccccc;
		background: $LAB_WHITE;
		border-radius: 5px;
		height: 30px;
		padding: 5px 10px;
		&.error {
			border: 1px solid $RED;
		}
		&:focus {
			border: 1px solid $SECONDARY_1;
		}
	}
	span.input-error-msg {
		display: flex;
		color: $RED;
		font-size: 12px;
		img {
			padding-right: 6px;
		}
	}
}
</style>
