<template>
	<div class="user-wrapper">
		<h5>
			{{ tt('예산 정보') }}
		</h5>
		<div class="order-user-wrapper">
			<div class="type-group">
				<input-text
					title="주문 연구실"
					:inputWidthSize="400"
					:inputHeightSize="36"
					:inputValue="selectedGroup ? selectedGroup.name : tt('주문 연구실을 선택해주세요')"
					:isReadOnly="true"
				></input-text>

				<button @click="showSelectedGroupModal">
					<img v-if="!selectedGroup" :src="require('@/assets/svg/Next.svg')" />
					<img v-else @click.stop="completeSelectedGroup(null)" :src="require('@/assets/svg/icon-delete-2.svg')" />
				</button>
			</div>
			<input-select
				ref="budgetId"
				:title="'예산'"
				:inputWidthSize="400"
				:placeholder="'예산을 선택해주세요'"
				:list="getUsableBudgetList"
				:item="draftInfo.budgetId"
				@change="changeBudget"
			>
				<div class="list-no-data" v-if="getUsableBudgetList.length === 1">
					<p>{{ tt(selectedGroup ? '등록된 예산이 없습니다.' : '주문 연구실을 선택해주세요') }}</p>
					<span v-if="selectedGroup" @click="$router.push({ name: 'BudgetCreate' })">{{ tt('예산 등록하기') }}</span>
				</div>
			</input-select>
		</div>
		<modal-select-group
			title="기안 연구실 선택"
			ref="modal-select-group"
			:isSelectSingle="true"
			:defaultData="groups"
			@confirm="completeSelectedGroup"
		/>
	</div>
</template>
<script>
import InputText from '@/views/common/components/items/InputText.vue'
import InputSelect from '@/views/common/components/items/InputSelect.vue'
import ModalSelectGroup from '@/views/common/components/modalGen2/ModalSelectGroup.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
	props: {
		isValid: {
			type: Boolean,
		},
	},
	data() {
		return {
			check: true,
		}
	},
	created() {
		this.initSelectedGroups()
		this.setDraftGroup(null)
		this.setDraftGroupId(null)
		this.setBudgetId(null)
	},
	computed: {
		...mapState('draft/item', ['groupList', 'draftInfo', 'selectedGroup']),
		...mapState('institute/group', ['groups', 'selectedGroups']),
		...mapGetters('draft/item', ['getUsableBudgetList']),
		info() {
			return {
				groupId: this.selectedGroup?.id ?? null,
				budgetId: this.$refs['budgetId'].value ?? null,
			}
		},
	},
	methods: {
		...mapActions('draft/item', ['setDraftGroupId']),
		...mapActions('institute/group', ['getGroups']),
		...mapMutations('institute/group', ['initSelectedGroups']),
		...mapMutations('draft/item', ['setDraftInfo', 'setDraftApprovalLine', 'setDraftGroup', 'setBudgetId']),
		async showSelectedGroupModal() {
			this.$refs['modal-select-group'].show(this.selectedGroup ? [this.selectedGroup] : null)
		},
		completeSelectedGroup(selectedGroups) {
			if (selectedGroups) {
				this.setDraftGroup(selectedGroups[0])
				this.setDraftGroupId(selectedGroups[0].id)
			} else {
				this.initSelectedGroups()
				this.setDraftGroup(null)
				this.setDraftGroupId(null)
			}
			this.setBudgetId(null)
		},
		changeBudget(id) {
			this.setBudgetId(id)
		},
	},
	components: {
		InputText,
		InputSelect,
		ModalSelectGroup,
	},
}
</script>
<style lang="scss" scoped>
h5 {
	font-weight: 700;
	label {
		cursor: pointer;
		margin-left: 16px;
		b {
			margin-left: 8px;
			font-size: 16px;
		}
	}
}
.order-user-wrapper {
	margin-top: 15px;
	padding: 20px;
	border: 1px solid $INPUT;
	border-radius: 5px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	.item-wrapper {
		border-bottom: 0px;
	}
	.type-group {
		height: 67px;
		button {
			cursor: pointer;
			position: relative;
			top: -52px;
			left: 250px;
			display: flex;
			width: 400px;
			height: 36px;
			padding: 12px 6px;
			justify-content: right;
		}
	}
	.list-no-data {
		display: flex;
		justify-content: space-between;
		width: 33%;
		font-size: 14px;
		padding: 5px 0px;
		p {
			color: $SECONDARY_2;
			font-weight: 400;
		}
		span {
			cursor: pointer;
			text-decoration-line: underline;
			font-weight: 500;
			color: $BLUE;
		}
	}
}
</style>
