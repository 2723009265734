<template>
	<div class="info-wrap">
		<p class="info-header">{{ tt('안전정보') }}</p>
		<div class="msds-list">
			<div class="msds-item-wrap" v-for="key in msdsKeys" :key="key">
				<msds-item v-if="msds[key]" :msds="msds[key]" :msdsKey="key"></msds-item>
			</div>
		</div>
	</div>
</template>
<script>
import MsdsItem from './MsdsItem.vue'
export default {
	name: 'SafetyInfo',
	components: { MsdsItem },
	props: {
		msds: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		msdsKeys() {
			return Object.keys(this.msds)
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-wrap {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 25px 0px;
	align-items: flex-start;
	border-bottom: 1px solid $DIVIDER;
	.info-header {
		font-size: 16px;
		font-weight: 700;
	}
	.msds-list {
		display: flex;
		flex-direction: row;
	}
}
</style>
