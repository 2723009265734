var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isShow ? _c('portal', {
    attrs: {
      "to": "lm-modal"
    }
  }, [_c('div', {
    staticClass: "ModalManagementNotice"
  }, [_c('div', {
    staticClass: "Popup__background"
  }), _c('div', {
    staticClass: "Popup__container Popup__size-md"
  }, [_c('div', {
    staticClass: "Popup__top"
  }, [_c('h5', {
    staticClass: "Popup__title"
  }, [_vm._v(" " + _vm._s(_vm.tt('특별관리물질 취급일지')) + " ")]), _c('button', {
    staticClass: "Popup__close-btn"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/popup-close.svg"),
      "alt": "close"
    },
    on: {
      "click": _vm.close
    }
  })])]), _c('div', {
    staticClass: "Popup__content"
  }, [_c('h6', [_vm._v(_vm._s(_vm.tt('특별관리물질 취급방법')))]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.tt('1. "특별관리물질이란 「산업안전보건법 시행규칙」 별표 18 제1호나목에 따른 발암성 물질, 생식세포 변이원성 물질, 생식독성(生殖毒性) 물질 등 근로자에게 중대한 건강장해를 일으킬 우려가 있는 물질로서 별표 12에서 특별관리물질로 표기된 물질을 말합니다.')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('2. 별표 12에 따른 물질을 취급하는 경우에는 물질명·사용량 및 작업내용 등이 포함된 특별관리물질 취급일지를 작성하여 갖춰두어야 합니다.')) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.tt('3. 법령 관리 물품 기능을 통해 연구실에서 보유한 특별관리물질을 살펴볼 수 있습니다.')) + " ")])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }