<template>
	<tr class="table-item">
		<td>
			<!-- 체크박스 -->
			<span class="checkbox" @click.stop><input type="checkbox" v-model="checked" /> </span>
		</td>
		<!-- 입력자 -->
		<td>
			<span class="profile-wrap">
				<img class="profile-img" :src="userImage" />
				<p>{{ tt(item.user.name) }}</p>
			</span>
		</td>
		<td>
			<div class="title">
				{{ tt(type.title) }}
			</div>
		</td>
		<!-- 상세 -->
		<td style="min-width: 400px">
			<div class="content-wrap" style="min-width: 250px; max-width: 370px">
				<span v-html="tt(type.content)" v-b-tooltip.hover.html="tt(type.content)"></span>
			</div>
		</td>
		<!-- 시갼 -->
		<td style="min-width: 160px">
			<span>{{ createDatetime }}</span>
		</td>
		<!-- 관리 -->
		<td style="min-width: 100px">
			<div class="button-wrap">
				<button class="button-delete" @click.stop="$refs['modal-remove'].show()">
					<img src="@/views/inventoryGen2/components/svg/icon-disposal.svg" />
					<span>{{ tt('삭제') }}</span>
				</button>
			</div>
		</td>
		<modal-confirm
			ref="modal-remove"
			title="기록 삭제하기"
			confirmText="삭제하기"
			variant="warning"
			:content="`정말로 선택한 기록을 삭제하시겠습니까?</br>이 동작은 되돌릴 수 없습니다.`"
			@confirm="remove"
		/>
	</tr>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import MixinItem from '@/mixins/inventory/MixinItem'
export default {
	name: 'HistoryTableItem',
	components: {},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('inventoryGen2/historyInfo', ['selectedItems']),
		type() {
			const convertText = this.replaceAllText(this.item.memo, '<br />')
			switch (this.item.type) {
				case 'ADD':
					return {
						title: '등록',
						content: '-',
					}
				case 'EDIT':
					return {
						title: '편집',
						content: '-',
					}
				case 'HOLD':
					return {
						title: '사용',
						content: '-',
					}
				case 'RETURN':
					return {
						title: '사용완료',
						content: convertText ?? '-',
					}
				case 'CONSUME':
					let volume = this.item.changeUseCount ? this.item.changeUseCount : this.item.changeVolume
					let unit = this.item.changeUseCount ? this.item.volumeUnit : this.item.changeVolumeUnit
					let beforeStr = this.item.changeUseCount ? '사용횟수' : '사용량'
					return {
						title: this.tt('${1} 입력${2}', `${beforeStr}`, `[${volume}${unit}]`),
						content: convertText ?? '-',
					}
				case 'DISPOSE':
					return {
						title: '폐기',
						content: convertText ?? '-',
					}
				case 'DELETE':
					return {
						title: '삭제',
						content: convertText ?? '-',
					}
				case 'RESTORE':
					return {
						title: '복구',
						content: convertText ?? '-',
					}
				case 'REASSIGN':
					let beforeAssigneeImage = this.item.beforeAssignee?.image ?? require('@/assets/svg/member-photo-default.svg')
					let afterAssigneeImage = this.item.afterAssignee?.image ?? require('@/assets/svg/member-photo-default.svg')
					return {
						title: '담당자 변경',
						content:
							this.item.beforeAssignee && this.item.afterAssignee
								? `<div class="assignee-change"><img class="assignee-img" src="${beforeAssigneeImage}" /><p>${this.item.beforeAssignee.name}</p> -> <img class="assignee-img " src="${afterAssigneeImage}" /><p>${this.item.afterAssignee.name}</p></div>`
								: '-',
					}
				case 'SUBDIVIDE':
					return {
						title: '소분',
						content: convertText ?? '-',
					}
				case 'CHANGE_STORAGE':
					return {
						title: '보관함 변경',
						content:
							this.item.beforeStorage && this.item.afterStorage
								? `${this.item.beforeStorage.locationPathName}&${this.item.beforeStorage.pathName} -> ${this.item.afterStorage.locationPathName}&${this.item.afterStorage.pathName}`
								: '-',
					}
				default:
					return {
						title: '-',
						content: '-',
					}
			}
		},
		userImage() {
			return this.item.user && this.item.user.image ? this.item.user.image : require('@/assets/svg/member-photo-default.svg')
		},
		checked: {
			get() {
				return this.selectedItems.some(item => item.id === this.item.id)
			},
			set(value) {
				if (value) {
					this.setSelectedItems(this.selectedItems.concat(this.item))
				} else {
					this.setSelectedItems(this.selectedItems.filter(item => item.id !== this.item.id))
				}
			},
		},
		createDatetime() {
			if (!this.item.createDatetime) return null
			return this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd T')
		},
	},
	data() {
		return {
			selectedId: null,
		}
	},
	methods: {
		...mapMutations('inventoryGen2/historyInfo', ['setSelectedItems']),
		...mapActions('inventoryGen2/historyInfo', ['removeItem']),
		async remove() {
			await this.removeItem({ ids: [this.item.id] })
			this.$root.toast('기록 삭제 완료', `물품 기록이 삭제되었습니다.`, 'success')
			this.setSelectedItems([])
		},
		replaceAllText(val, params = ' ') {
			return val ? val.replaceAll('\\n', params) : null
		},
	},
}
</script>
<style scoped lang="scss">
::v-deep .tooltip-inner {
	text-align: left;
}
.table-item {
	height: 60px !important;
	cursor: default !important;

	&:hover {
		background: $GREY_1;
		transition: 0.5s all ease;
	}
}
tr {
	&:last-child {
		td {
			border-bottom: 0 !important;
		}
	}
}
td {
	font-size: 14px;
	.title {
		display: flex;
		padding-left: 9px;
		font-weight: 700;
		justify-content: flex-start;
	}
	.checkbox {
		justify-content: flex-end;
		padding-right: 9px;
	}
	.profile-wrap {
		display: flex;
		gap: 5px;
		img {
			width: 24px;
			height: 24px;
			border-radius: 25px;
		}
		p {
			font-weight: 400;
			font-size: 14px;
		}
	}
	.content-wrap {
		display: flex;
		padding-left: 9px;
		width: 100%;
		max-width: 600px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		& > div {
			display: flex;
			gap: 5px;
			width: 100%;
		}
		span {
			white-space: normal;
			display: -webkit-box;
			max-height: 40px;
			overflow: hidden;
			vertical-align: top;
			text-overflow: ellipsis;
			word-break: break-all;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}
	.button-wrap {
		padding-left: 9px;
	}
	button {
		max-height: 30px;
		background: $GREY_4;
		border-radius: 4px;
		font-weight: 700;
		padding: 5px 10px;
		&:hover {
			background: $HOVER_BUTTON_COMMON;
		}
		img {
			width: 24px;
			height: 24px;
		}
		img + span {
			padding-left: 3px;
		}
	}
	> span {
		display: flex;
		height: 60px;
		justify-content: flex-start;
		align-items: center;
		text-align: left;
		padding-left: 9px;
	}
}
.subtext {
	display: flex;
	gap: 10px;
	span {
		font-size: 12px;
		color: $SECONDARY_2;
	}
}
.content-text {
	display: flex;
}

::v-deep .assignee-img {
	border-radius: 24px;
	width: 24px;
	height: 24px;
	border: 1px solid $INPUT;
}
</style>
