var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('migration-header', {
    attrs: {
      "isMigration": true,
      "text": _vm.headerText
    }
  }), _c('div', {
    staticClass: "content-container"
  }, [_c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h3', {
    staticClass: "text-title",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _c('div', {
    staticClass: "text-content",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })]), _c('div', {
    staticClass: "timer-container"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/migration/migration-timer.svg")
    }
  }), _c('div', {
    staticClass: "timer-text",
    domProps: {
      "innerHTML": _vm._s(_vm.timerText)
    }
  })])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }