<template>
	<b-modal ref="modal-scan-qr-sticker" centered @close="close" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<input
				type="text"
				v-model="scannedCode"
				ref="scan"
				style="
					opacity: 0;
					position: absolute;
					left: 0px;
					top: 0px;
					ime-mode: disabled;
					-webkit-ime-mode: disabled;
					-moz-ime-mode: disabled;
					-ms-ime-mode: disabled;
				"
				@blur="autoFocus"
				@keypress.enter="scanCode"
			/>
			<div class="body">
				<div class="qr-counter" :class="{ full: isRegistFull }">
					<div class="counter-text">{{ tt('할당된 QR/물품 수량') }}</div>
					<div class="counter" :class="{ full: isRegistFull }">{{ `${selectedLength}/${maxSize}` }}</div>
				</div>
				<qr-scan-table :itemList="qrcodeList" :maxSize="maxSize"></qr-scan-table>
				<div class="btm-describe">{{ tt('스캐너를 이용해 물품에 할당할 QR 스티커를 스캔해주세요') }}</div>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<button class="button-gray" @click="setInit">
					{{ tt('초기화') }}
				</button>
				<button class="button-primary" @click="$emit('save', validSelectedQrCodeList)">
					{{ saveButtonText }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import QrScanTable from './components/qrScanTable/QrScanTable.vue'
import { ToastType } from '@/utils/define/ToastType'
import { checkKor } from '@/utils/modules/Regex'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	name: 'ModalScanQrSticker',
	components: { QrScanTable },
	props: {
		maxSize: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			scannedCode: '',
			idCounter: 0,
		}
	},
	computed: {
		...mapGetters('form/qrcode', [
			'qrcode',
			'validRes',
			'qrcodeList',
			'onlyCodeList',
			'selectedList',
			'selectedLength',
			'validSelectedQrCodeList',
		]),
		title() {
			return 'QR 스티커 스캔'
		},
		saveButtonText() {
			if (this.selectedList?.length > 0) {
				return this.tt('저장(${1})', this.selectedList?.length)
			} else {
				return this.tt('저장')
			}
		},
		isRemainCode() {
			return code => this.onlyCodeList.includes(code)
		},
		validQrLength() {
			return this.qrcodeList?.filter(item => item.status === 'OK').length ?? 0
		},
		isRegistFull() {
			return this.selectedLength === this.maxSize
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('form/qrcode', ['validQrCode']),
		...mapMutations('form/qrcode', ['setQrcode', 'setQrcodeList', 'setMaxSize', 'setSelectedList']),
		async show() {
			this.$refs['modal-scan-qr-sticker'].show()
			setTimeout(() => {
				this.$refs['scan'].focus()
			}, 200)
		},
		hide() {
			this.$refs['modal-scan-qr-sticker'].hide()
		},
		async scanCode() {
			if (!checkKor(this.scannedCode)) {
				this.setQrcode(this.scannedCode)
				if (!this.qrcodeList) {
					this.setQrcodeList([])
				}

				await this.validQrCode()
				if (this.validRes === true) {
					if (this.isRemainCode(this.scannedCode)) {
						this.setQrcodeList([
							...this.qrcodeList,
							{
								id: this.idCounter++,
								code: this.scannedCode,
								status: 'FAIL',
								reason: '중복된 바코드',
							},
						])
					} else {
						this.setQrcodeList([
							...this.qrcodeList,
							{
								id: this.idCounter++,
								code: this.scannedCode,
								status: 'OK',
							},
						])
					}
				} else if (this.validRes.msg) {
					this.setQrcodeList([
						...this.qrcodeList,
						{
							id: this.idCounter++,
							code: this.scannedCode,
							status: 'FAIL',
							reason: this.validRes.msg,
						},
					])
				}
				// 검증해야함
			} else {
				this.$root.toast(this.tt('QR 인식 불가'), this.tt('한/영 키를 눌러 영문 입력으로 전환해주세요.'), ToastType.WARNING)
			}
			this.scannedCode = ''
		},
		autoFocus() {
			this.$nextTick(() => {
				if (this.$refs['scan']) {
					this.$refs['scan'].focus()
				}
			})
		},
		setInit() {
			this.setQrcodeList([])
			this.setSelectedList([])
		},
		close() {
			this.hide()
			this.setInit()
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
	padding: 15px 20px;
	border: 0;
	font-weight: 700;
	height: 54px;
	.modal-title {
		font-weight: 700;
		font-size: 16px;
	}
}

::v-deep .modal-content {
	box-shadow: 0px 2px 4px 0px #0000001a;
}

::v-deep .modal-dialog {
	max-width: 350px;
	max-height: 700px;
	.modal-content {
		background-color: $LAB_WHITE;
		border-radius: 8px;
		border: 0;

		.form-wrap {
			padding: 20px;
			background: white;
			border: 1px solid #ccc;
			border-radius: 8px;
			gap: 20px;
			.form-header {
				font-weight: 700;
				font-size: 16px;
				.form-title {
				}
				.form-msg {
				}
			}
		}
	}
}
::v-deep .modal-body {
	display: flex;
	flex-direction: column;
	border: 0;
	padding: 0px;
	gap: 20px;
	overflow: hidden;
	border-top: 1px solid $DIVIDER;
	background: $SYSTEM_BACKGROUND;

	.body {
		height: 680px;
		.qr-counter {
			background: #fbfaf9;
			padding: 15px 20px;
			color: $LAB_BLACK;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 50px;
			&.full {
				background: linear-gradient(0deg, rgba(13, 197, 119, 0.1), rgba(13, 197, 119, 0.1)), linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
			}
		}
		.counter {
			font-size: 20px;
			font-weight: 700;
			&.full {
				color: $GREEN;
			}
		}
		.btm-describe {
			height: 30px;
			padding: 7px 10px;
			display: flex;
			justify-content: center;
			font-size: 12px;
			font-weight: 400;
			background-color: #fbfaf9;
			align-items: center;
			border-top: 1px solid $DIVIDER;
			border-bottom: 1px solid $DIVIDER;
		}
	}
}

::v-deep .modal-footer {
	align-items: center;
	.modal-footer-wrap {
		margin: 0px;
		width: 100%;
		display: flex;
		gap: 10px;
		.button-gray {
			display: flex;
			justify-content: center;
			width: 100px;
			height: 50px;
			background-color: $GREY_2;
			font-size: 16px;
			font-weight: 700;
			border-radius: 5px;
		}
		.button-primary {
			display: flex;
			justify-content: center;
			width: 220px;
			height: 50px;
			background-color: $LAB_YELLOW;
			font-size: 16px;
			font-weight: 700;
			border-radius: 5px;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $LAB_YELLOW, $LAB_YELLOW);
			}
		}
	}
}
</style>
