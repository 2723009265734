var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.html",
      value: _vm.tootlipText,
      expression: "tootlipText",
      modifiers: {
        "html": true
      }
    }],
    staticClass: "badge-wrapper",
    "class": {
      warning: _vm.overDueNoPaidPriceWithVat > 0
    },
    on: {
      "click": _vm.gotoStorePayment
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.tt('후불결제 잔여 한도')) + " ")]), _vm.overDueNoPaidPriceWithVat > 0 ? _c('img', {
    attrs: {
      "src": require("@/assets/svg/input-icon-warning.svg")
    }
  }) : _vm._e(), _c('div', {
    staticClass: "credit"
  }, [_vm._v(_vm._s(_vm.limitCredit.toLocaleString()) + "원 ▸")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }