<template>
	<div class="loading-wrapper" :style="cssProps">
		<div class="lottie-wrap">
			<lottie-animation
				ref="lottie-animation"
				:animationData="require('@/assets/json/lm_loading_lottie.json')"
				:autoPlay="play"
				:loop="true"
			></lottie-animation>
		</div>
	</div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'

export default {
	name: 'LmLoading',
	components: {
		LottieAnimation,
	},
	props: {
		height: {
			type: [Number, String],
			default: 200,
		},
		backgroundColor: {
			type: String,
			default: '#fff',
		},
		play: {
			type: Boolean,
			default: false,
		},
		opacity: {
			type: Number,
			default: 1,
		},
	},
	watch: {
		play: {
			hanlder(value) {
				value ? this.playLoading() : this.stopLoading()
			},
		},
	},
	computed: {
		cssProps() {
			return {
				display: 'flex',
				width: '100%',
				'--height': this.height,
				'justify-content': 'center',
				'align-items': 'center',
				'background-color': this.backgroundColor,
				'--opacity': this.opacity,
			}
		},
	},
	methods: {
		playLoading() {
			this.$refs['lottie-animation'].anim.play()
		},
		stopLoading() {
			this.$refs['lottie-animation'].anim.stop()
		},
	},
}
</script>

<style lang="scss" scoped>
.loading-wrapper {
	display: var(--display);
	width: var(--width);
	height: var(--height);
	justify-content: var(--justify-content);
	align-items: var(--align-items);
	background-color: var(--backgroundColor);
	opacity: var(--opacity);
}
.lottie-wrap {
	display: flex;
	width: 300px;
	height: 150px;
	padding: 30px 30px;
	justify-content: center;
}
</style>
