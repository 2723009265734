var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Member",
    attrs: {
      "id": "Member"
    }
  }, [_c('h2', {
    staticClass: "AppContainer__title"
  }, [_vm._v(_vm._s(_vm.tt('멤버')))]), _c('div', {
    staticClass: "Member__container"
  }, [_c('member-all', {
    attrs: {
      "userPermission": _vm.userPermission
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }