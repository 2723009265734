var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.group ? _c('div', {
    staticClass: "detail-wrapper"
  }, [_c('div', {
    staticClass: "common-header-wrapper"
  }, [_vm.isFloding ? _c('image-button', {
    staticClass: "btn-floding",
    attrs: {
      "image": require('../components/svg/arrow-floding.svg')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('unflod');
      }
    }
  }) : _vm._e(), _c('h2', [_vm._v(_vm._s(_vm.group.name))])], 1), _c('div', {
    staticClass: "budget-nav-wrapper"
  }, [_c('div', {
    staticClass: "nav-item",
    "class": {
      active: _vm.pageName == 'BudgetStatus'
    },
    on: {
      "click": function click($event) {
        _vm.pageName = 'BudgetStatus';
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('예산')))]), _c('div', {
    staticClass: "nav-item",
    "class": {
      active: _vm.pageName == 'ExpenditureList'
    },
    on: {
      "click": function click($event) {
        _vm.pageName = 'ExpenditureList';
      }
    }
  }, [_vm._v(_vm._s(_vm.tt('지출내역')))])]), _c('div', {
    staticClass: "content-wrapper common-detail-wrapper"
  }, [_c(_vm.pageName, {
    ref: "current-page",
    tag: "component",
    attrs: {
      "group": _vm.group,
      "year": _vm.selectedYear
    }
  })], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }