<template>
	<li class="product-info-item" :class="{ isSearch: isSearch }">
		<div class="imgbox">
			<img :src="productImage" />
		</div>
		<div class="product-info" @click="click">
			<div class="info">
				<!-- todo : 뱃지 조건 달기 -->
				<div v-if="item.isRecommend" style="display: flex; gap: 10px; align-items: center; margin-bottom: 8px">
					<div class="badge-recommend" v-if="item.isRecommend">
						{{ tt('같이 보면 좋은 상품') }}
					</div>
				</div>
				<h6 v-html="replaceHighlight(item.name)"></h6>
				<p class="subname" v-if="item.subname" v-html="replaceHighlight(item.subname)"></p>
				<h4 class="price" v-if="item.minSellingUnitPrice && !usePrice">
					<span class="red" v-if="item.discountPercent > 0">{{ item.discountPercent }}%</span>
					<span v-html="replaceHighlight(CommaNum(item.minSellingUnitPrice)) + '원' + (item.productInfos?.length > 0 ? '~' : null)"></span>
				</h4>
			</div>
			<div class="brand-info">
				<p v-if="item.category">
					{{ tt('카테고리') }} :
					<span v-html="replaceHighlight(tt(category2String(item.category)))"></span>
				</p>
				<p v-if="item.brand && item.brand.name">{{ tt('브랜드') }} : <span v-html="replaceHighlight(tt(item.brand.name))"></span></p>
				<p v-if="item.code">{{ tt('제품번호') }} : <span v-html="replaceHighlight(tt(item.code))"></span></p>
				<p v-if="item.casno">{{ tt('CAS No') }} : <span v-html="replaceHighlight(tt(item.casno))"></span></p>
				<div v-if="item.categoryDisplayOptions && item.categoryDisplayOptions.length > 0">
					<p v-for="(option, index) in item.categoryDisplayOptions" :key="`option-${index}`">
						{{ tt(option.name) }}: <span v-html="replaceHighlight(option.values.join(', '))"></span>
					</p>
				</div>
			</div>
		</div>
		<div class="seller-list" v-if="!usePrice">
			<ul style="overflow: auto; height: 140px">
				<li v-for="(productInfo, index) in item.productInfos" :key="`productInfo-${index}`">
					<div>
						<p>{{ tt(productInfo.seller.name) }}</p>
						<p>{{ CommaNum(productInfo.minSellingUnitPrice) }}{{ tt('원') }}</p>
					</div>
					<p v-if="productInfo.discountPercent" style="font-weight: 400; color: #999; text-decoration: line-through">
						{{ tt('${1}원', CommaNum(productInfo.minUnitPrice)) }}
					</p>
				</li>
			</ul>
		</div>

		<div class="price-box" v-else>
			<h6>
				<span class="red" v-if="item.discountPercent > 0">{{ item.discountPercent }}%</span>
				{{ CommaNum(item.minSellingUnitPrice) }}{{ tt('원~') }}
			</h6>
			<p v-if="item.discountPercent" style="font-weight: 400; color: #999; text-decoration: line-through">
				{{ tt('${1}원', CommaNum(item.minUnitPrice)) }}
			</p>
		</div>
	</li>
</template>

<script>
import { mapState } from 'vuex'
import { LargeClassToString, MiddleClassToString } from '@/utils/define/ItemCode'
export default {
	name: 'ProductInfoItem',
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		keyword: {
			type: String,
			default: '',
		},
		usePrice: {
			type: Boolean,
			default: false,
		},
		isSearch: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {}
	},
	created() {},
	computed: {
		// ...mapState('draft/form', ['temp']),
		// displayOptionName() {},
		// displayOptions() {},
		productOptions() {
			if (this.$_.isEmpty(this.item) || this.item.productCount <= 0) return ''

			return this.item.categoryDisplayOptions.values.join(', ')
		},
		productImage() {
			if (this.item.images?.length > 0) {
				return this.item.images[0]
			} else if (this.item.brand?.image) {
				return this.item.brand.image
			} else return require('@/assets/svg/no-data_item.svg')
		},
	},
	methods: {
		CommaNum(num) {
			if (!num) return 0
			return num.toLocaleString('ko-KR')
		},
		category2String(category) {
			// let largeCategory = data.substring(0, data.lastIndexOf('_'))
			// if (largeCategory === 'CAT') return `${this.tt(LargeClassToString(data))}` // category by 1 depth
			// return `${this.tt(LargeClassToString(largeCategory))} / ${this.tt(MiddleClassToString(data))}`
			return category.parents[0] ? `${this.tt(category.parents[0].korName)} / ${this.tt(category.korName)}` : `${this.tt(category.korName)}`
		},
		replaceHighlight(value) {
			let regex = new RegExp(this.keyword, 'gi')
			return this.keyword ? value.replace(regex, match => `<mark class="highlight">${match}</mark>`) : value
		},
		click() {
			this.$emit('click', this.item.catalogId, this.item.isRecommend)
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep mark {
	padding: 0 0 !important;
	&.highlight {
		font-weight: bold;
		background-color: #ffeaea;
	}
}
.product-info-item {
	display: flex;
	align-items: flex-start;
	width: 100%;
	min-height: 180px;
	background: white;
	border-bottom: 1px solid $DIVIDER;
	padding: 20px;
	&.isSearch {
		cursor: pointer;
	}
	&:last-child {
		border-bottom: none;
	}
	.imgbox {
		width: 140px;
		height: 140px;
		background-color: white;
		border: 1px solid $DIVIDER;
		border-radius: 4px;
		overflow: hidden;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: inherit;
			height: inherit;
			object-fit: contain;
		}
		h4 {
			display: block;
			width: 100%;
			padding: 10px;
			word-break: break-all;
			font-size: 1rem;
			font-weight: 700;
			text-align: center;
		}
	}
	.product-info {
		width: calc(100% - 400px);
		height: 100%;
		padding-left: 20px;
		.info {
			max-width: 580px;
			h6 {
				margin-top: 2px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				font-size: 1rem;
				line-height: 130%;
				font-weight: 700;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			.subname {
				font-size: 0.75rem;
				font-weight: 400;
				color: $SECONDARY_2;
			}
			.price {
				margin-top: 10px;
				align-items: center;
				display: flex;
				gap: 5px;
				font-size: 1.25rem;
				font-weight: 700;
			}
			.badge-recommend {
				display: inline-block;
				padding: 3px 7px;
				border-radius: 4px;
				background-color: $BACKGROUND_BLUE;
				font-size: 12px;
				font-weight: 500;
				color: $BLUE;
			}
			.badge-merck {
				height: 23px;
			}
		}
		.brand-info {
			width: 100%;
			margin-top: 20px;
			white-space: initial;
			overflow: hidden;
			line-height: 100%;
			> div,
			p {
				display: inline-block;
				position: relative;
				margin-right: 16px;
				font-size: 0.75rem;
				font-weight: 400;
				color: $SECONDARY_2;
				&:before {
					position: absolute;
					width: 1px;
					height: 12px;
					background-color: $INPUT;
					content: '';
					right: -8px;
					top: 3px;
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}

	.seller-list {
		padding: 0 0;
		height: 140px;
		width: 260px;
		border-left: 1px solid $DIVIDER;
		ul {
			margin-right: 0;
			width: 100%;
			padding-right: 0;
			li {
				display: flex;
				flex-direction: column;
				& > div {
					width: 100%;
					display: flex;
					justify-content: space-between;
				}
			}
		}
	}
	div {
		&.price-box {
			margin-left: auto;
			text-align: right;
			padding: 0 0;
		}

		h5 {
			font-weight: 700;
			line-height: 23px;
		}
		span {
			line-height: 18px;
		}
		h6 {
			font-weight: 700;
			font-size: 1.25rem;
			line-height: 29px;
		}
	}
	ul {
		width: 280px;
		padding: 0 20px;
		li {
			display: flex;
			align-items: center;
			width: 100%;
			margin-bottom: 5px;
			height: 21px;
			&:first-child {
				p {
					font-weight: 700;
					&:last-child {
						color: $BLUE;
					}
				}
			}
			p {
				font-size: 0.75rem;
				&:first-child {
				}
				&:last-child {
					margin-left: auto;
				}
			}
		}
	}
}
</style>
