<template>
	<div class="total-amount-wrap">
		<div class="total-amount-title">
			<h5>{{ tt(title) }}</h5>
			<image-button
				v-if="budget"
				:text="'상세 예산현황표 보기'"
				:image="require('@/assets/svg/table.svg')"
				@click="showBudgetStatusTable"
			></image-button>
		</div>
		<div class="total-amount" v-if="info">
			<div class="title">
				<div v-html="infoTitle"></div>
				<span>{{ tt(status) }}</span>
			</div>
			<div class="amount-box">
				<div class="total">
					<p>{{ tt(info.total.name) }}</p>
					<h6>{{ tt('${1}원', CommaNum(info.total.value)) }}</h6>
				</div>
				<div class="expense">
					<p>{{ tt(info.expense.name) }}</p>
					<h6>{{ tt('${1}원', CommaNum(info.expense.value)) }}</h6>
				</div>
				<div class="balance">
					<p>{{ tt(info.balance.name) }}</p>
					<h6>{{ tt('${1}원', CommaNum(info.balance.value)) }}</h6>
				</div>
			</div>
			<div class="noti" v-if="amountNoti">
				<span v-html="amountNoti"></span>
			</div>
		</div>
		<modal-budget-table ref="modal-budget-table" :budget="budget" />
	</div>
</template>

<script>
import ImageButton from '@/views/common/components/baseButton/ImageButton.vue'
import ModalBudgetTable from '@/views/budget/components/status/ModalBudgetTable.vue'
export default {
	name: 'TotalAmount',
	components: {
		ImageButton,
		ModalBudgetTable,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		info: {
			type: Object,
			default: () => {},
		},
		budget: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		infoTitle() {
			if (this.info.title.name) {
				return `<b>${this.info.title.name}</b>`
			}
		},
		amountNoti() {
			if (this.info.expense && this.info.expense.value > 0 && this.info.balance && this.info.balance.value < 0) {
				return this.tt(`지출 예정 금액이 <b>남은 잔액</b>을 <b>초과</b>합니다.`)
			}
		},
		status() {
			if (!this.info.title.status) {
				return ''
			}

			if (this.info.title.status.toLowerCase() == 'month') {
				return `월별 예산(Month)`
			} else if (this.info.title.status.toLowerCase() == 'year') {
				return `연 예산(Year)`
			}
		},
	},
	methods: {
		CommaNum(num) {
			return num ? num.toLocaleString('ko-KR') : 0
		},
		showBudgetStatusTable() {
			// console.warn('open 상세 예산현황표', this.budget.budgetStatus)
			this.$refs['modal-budget-table'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.total-amount-wrap {
	h5 {
		font-weight: 700;
	}
	width: 100%;
	.total-amount-title {
		display: flex;
		align-items: center;
		height: auto;
		margin-bottom: 1rem;
		button {
			margin-left: auto;
		}
	}
	.total-amount {
		width: 100%;
		border: 1px solid $INPUT;
		border-radius: 4px;
		overflow: hidden;
		.title {
			height: 40px;
			box-sizing: border-box;
			background-color: $GREY_2;
			display: flex;
			align-items: center;
			font-size: 0.875rem;
			padding-left: 20px;
			border-bottom: 1px solid $INPUT;
			> div {
				display: inline-flex;
				color: $SECONDARY_2;
			}
			b {
				display: inline-block;
				margin-right: 10px;
				color: $SECONDARY_2;
			}
			span {
				color: $SECONDARY_2;
				margin-left: 10px;
				display: inline-block;
				font-weight: 400;
			}
		}
		.amount-box {
			width: 100%;
			display: flex;
			align-items: center;
			div {
				box-sizing: border-box;
				width: calc(100% / 3);
				height: 80px;
				border-right: 1px solid $INPUT;
				padding: 16px 0;
				position: relative;
				text-align: center;
				p {
					font-size: 0.875rem;
					font-weight: 500;
					color: inherit;
					margin-bottom: 2px;
				}
				h6 {
					font-size: 1.125rem;
					font-weight: 700;
					color: inherit;
				}
				&:last-child {
					border-right: 0;
				}
				&.total {
					color: $DEEP_BLUE;
					&:after {
						position: absolute;
						top: calc(50% - 9px);
						width: 18px;
						height: 18px;
						content: url(~@/assets/svg/table-circle-minus.svg);
						right: -9px;
					}
				}
				&.expense {
					color: $RED;
					&:after {
						position: absolute;
						top: calc(50% - 9px);
						width: 18px;
						height: 18px;
						content: url(~@/assets/svg/table-circle-equal.svg);
						right: -9px;
					}
				}
				&.balance {
					color: $LIGHT_BLUE;
				}
			}
		}
		.noti {
			width: 100%;
			height: 40px;
			background-color: rgba(255, 87, 70, 0.1);
			display: flex;
			align-items: center;
			justify-content: center;
			border-top: 1px solid $INPUT;
			span {
				font-size: 0.75rem;
				font-weight: 400;
				color: $RED;
				padding-left: 16px;
				background-image: url(~@/assets/svg/noti-error.svg);
				background-repeat: no-repeat;
				background-size: 12px;
				background-position: center left 0;
			}
		}
	}
}
</style>
