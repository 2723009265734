<template>
	<b-modal size="1000" centered ref="modal-form-approval-line-selected" no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('결재선 지정') }}</div>
		</template>
		<template>
			<div class="content">
				<approval-line-treeview></approval-line-treeview>
				<approval-line-form :isValid="isValid"></approval-line-form>
			</div>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-right">
					<button class="button-white" @click="hide">
						{{ tt('취소') }}
					</button>
					<button class="button-primary" @click="complete">
						{{ tt('완료') }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import ApprovalLineTreeview from '@/views/purchase/draft/components/form/ApprovalLineTreeview.vue'
import ApprovalLineForm from '@/views/purchase/draft/components/form/ApprovalLineForm.vue'
import { mapState, mapMutations } from 'vuex'
// import { LargeClass } from '@/utils/define/ItemCode'
export default {
	name: 'ModalFormApprovalLineSelected',
	components: { ApprovalLineTreeview, ApprovalLineForm },
	props: {},
	data() {
		return {
			isValid: true,
		}
	},
	computed: {
		// ...mapState('institute/group', ['groups']),
		// ...mapState('draft/item', ['draftInfo']),
		...mapState('institute/member', ['selectedMembers']),
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		// ...mapMutations('draft/item', ['setDraftInfo']),
		show() {
			this.$refs['modal-form-approval-line-selected'].show()
		},
		hide() {
			this.$refs['modal-form-approval-line-selected'].hide()
		},
		complete() {
			if (this.selectedMembers.length === 0) {
				this.isValid = false
				return false
			}
			this.$emit('complete', this.selectedMembers)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-form-approval-line-selected.scss';
</style>
