var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "image-viewer",
    attrs: {
      "size": "xl",
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.tt(_vm.title)))])];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticClass: "viewer"
  }, [_c('div', {
    staticClass: "image-background"
  }, [!_vm.isRawSvg ? _c('img', {
    staticClass: "image",
    attrs: {
      "src": _vm.imageSrc(_vm.selectImg)
    }
  }) : _c('div', {
    ref: "large-img",
    staticClass: "image",
    domProps: {
      "innerHTML": _vm._s(_vm.selectImg)
    }
  })])]), _c('div', {
    staticClass: "img-slider"
  }, _vm._l(_vm.imageList, function (image, idx) {
    return _c('div', {
      key: idx,
      staticClass: "slider-content",
      "class": {
        selected: _vm.isSelected(idx)
      },
      on: {
        "click": function click($event) {
          return _vm.setImageIdx(idx);
        }
      }
    }, [!_vm.isRawSvg ? _c('img', {
      ref: "image",
      refInFor: true,
      staticClass: "slider-image",
      attrs: {
        "src": _vm.imageSrc(image)
      }
    }) : _c('div', {
      ref: "image",
      refInFor: true,
      staticClass: "slider-image",
      "class": {
        selected: _vm.isSelected(idx)
      },
      domProps: {
        "innerHTML": _vm._s(image)
      },
      on: {
        "click": function click($event) {
          return _vm.setImageIdx(idx);
        }
      }
    }), _vm.isCaption ? _c('div', {
      staticClass: "caption"
    }, [_vm._v(_vm._s(_vm.captionList[idx]))]) : _vm._e()]);
  }), 0)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }