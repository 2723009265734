<template>
	<tr class="table-header">
		<th>
			<input
				type="checkbox"
				@click="$emit('checkAll')"
				v-model="value"
				:class="{ some: isSelectSome && !isSelectAll }"
				:disabled="isQrcodeListEmpty"
			/>
		</th>
		<th>
			<span>{{ tt('No') }}</span>
		</th>
		<th style="width: 110px">
			<span>{{ tt('바코드 값') }}</span>
		</th>
		<th style="width: 90px">
			<span>{{ tt('상태') }}</span>
		</th>
		<th>
			<span>{{ tt('삭제') }}</span>
		</th>
	</tr>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'QrScanTableHeader',
	components: {},
	props: {},
	data() {
		return {
			value: false,
		}
	},
	computed: {
		...mapGetters('form/qrcode', ['isSelectAll', 'isSelectSome', 'isQrcodeListEmpty']),
	},
	watch: {
		isSelectAll(value) {
			this.value = value
		},
		isQrcodeListEmpty(value) {
			if (value) this.value = false
		},
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.value = this.isSelectAll
		})
	},
	methods: {
		selectAll() {
			this.value = !this.value
		},
	},
}
</script>

<style lang="scss" scoped>
.table-header {
	height: 40px;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 10;
	display: block;
	border-top: 0.5px solid $DIVIDER;
	border-bottom: 0.5px solid $DIVIDER;
	&.gray {
		background-color: $GREY_2;
	}
	th {
		padding: 10px 0px;
		width: 50px;
		font-size: 14px;
		font-weight: 400;
		max-height: 30px;
		text-align: center;
		align-items: center;
	}
	th + th {
		border-left: 0.5px solid $DIVIDER;
	}
}

input.some {
	&::before {
		width: 18px;
		height: 18px;
		content: '';
		border: 1px solid #ccc;
		border-radius: 4px;
		background-color: $LAB_WHITE;
		background-size: 24px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(~@/views/purchase/cargo/svg/icon-check-mutiple-status.svg) !important;
		z-index: 1;
		margin-top: -2px;
		margin-left: -1px;
		box-sizing: border-box;
	}
}
</style>
