<template>
	<div class="input-radio-wrap">
		<base-item :title="title" :required="isRequired">
			<div class="input-radio-content">
				<span v-for="(item, index) in list" :key="`radio_${index}`">
					<input
						:id="`radio_${index}`"
						type="radio"
						v-model="value"
						:value="item.value"
						name="radioList"
						@change="changeItem(item.value)"
					/>
					<label :for="`radio_${index}`">
						{{ tt(item.name) }}
					</label>
				</span>
			</div>
		</base-item>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputRadio',
	components: {
		BaseItem,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Array,
			default: () => [],
		},
		selectedValue: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			value: this.selectedValue,
		}
	},
	created() {
		if (this.selectedValue) this.value = this.selectedValue
		else this.value = this.list[0].value
	},
	methods: {
		changeItem(value) {
			this.value = value
			// this.$emit('changeValue', value)
		},
		setValue(value) {
			this.value = value
		},
	},
}
</script>

<style lang="scss" scoped>
.input-radio-content {
	height: 100%;
	display: flex;
	align-items: center;
	input[type='radio'] {
		margin-right: 10px;
		display: none;
	}
	label {
		padding-left: 30px;
		margin-right: 30px;
		margin-bottom: 0px;
		position: relative;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			display: none;
			top: 3px;
			left: 3px;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background: $LAB_YELLOW;
		}
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 20px;
			width: 20px;
			background-color: white;
			border: 1px solid #ccc;
			border-radius: 50%;
		}
		&.disabled {
			// 랩매니저 스토어 임시 disabled
			color: #ccc;
			cursor: default;
		}
	}
	input:checked ~ label::before {
		border: 1px solid $LAB_YELLOW;
	}
	input:checked ~ label::after {
		display: block;
	}
}
// .radio-wrap {
// 	display: flex;
// 	.radio-content {
// 		margin-left: 50px;
// 		input[type='radio'] {
// 			margin-right: 10px;
// 		}
// 		label {
// 			margin-right: 30px;
// 		}
// 	}
// }
</style>
