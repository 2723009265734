<template>
	<div class="management-wrap">
		<div class="title-wrap">
			<div>
				<h5>{{ tt('관리번호') }}: {{ mainText }}</h5>
				<p>{{ subText }}</p>
			</div>
			<image-button
				@click="$refs['modal-management-number-guide'].show()"
				:image="require('@/views/inventoryGen2/svg/Guide.svg')"
				:text="'관리번호 가이드'"
			></image-button>
			<image-button
				v-if="activeClassList && activeClassList.length < 5"
				@click="$refs['modal-add-management-number'].show()"
				:image="require('@/views/inventoryGen2/svg/Add.svg')"
				:text="'관리항목 추가'"
			></image-button>
		</div>
		<div class="content-wrap">
			<ul>
				<li v-for="(item, index) in activeClassList" :key="`code${index}`">
					{{ tt('${1}번째', index + 1) }}
				</li>
			</ul>
			<draggable v-model="activeClassList" draggable=".item-wrap" v-bind="dragOptions" @start="onDragStart" @end="onDragEnd">
				<transition-group type="transition" name="flip-list">
					<classification-item
						v-for="(item, index) in activeClassList"
						:key="`code${index}`"
						:item="item"
						:index="index"
						@changeManagement="$refs['modal-add-management-number'].show(index)"
						@deleteManagement="deleteModalManagementNumber"
					></classification-item>
				</transition-group>
			</draggable>
		</div>
		<modal-management-number-guide ref="modal-management-number-guide"></modal-management-number-guide>
		<modal-add-management-number ref="modal-add-management-number"></modal-add-management-number>
		<modal-confirm
			ref="modal-delete-management-number"
			title="관리번호에서 제외하기"
			confirmText="제외하기"
			:content="`관리번호에서 해당 관리항목을 제외합니다.</br>언제든지 관리항목을 다시 추가할 수 있습니다.`"
		/>
		<modal-confirm
			ref="modal-delete-close"
			title="관리번호에서 제외 불가"
			:noCancel="false"
			variant="white"
			confirmText="확인"
			:content="`보관함 등록순서 선택 시 보관함은 필수 관리항목입니다.`"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import ClassificationItem from '../components/setting/ClassificationItem.vue'
import ModalManagementNumberGuide from '../components/setting/modal/ModalManagementNumberGuide.vue'
import ModalAddManagementNumber from '../components/setting/modal/ModalAddManagementNumber.vue'

export default {
	components: {
		draggable,
		ClassificationItem,
		ModalManagementNumberGuide,
		ModalAddManagementNumber,
	},
	data() {
		return {
			isDragging: false,
		}
	},
	computed: {
		...mapState('inventoryGen2/setting', ['mngcode']),
		...mapGetters('inventoryGen2/setting', ['activeCodeList']),
		activeClassList: {
			get() {
				return this.mngcode.activeClassList
			},
			async set(value) {
				this.setActiveClassList(value)
				await this.editMngcode({ activeClassList: value })
			},
		},
		mainText() {
			let text = ''
			if (!this.activeClassList) return text
			this.activeClassList.forEach(it => {
				if (it.marks[0].value !== null) {
					text += it.marks[0].value
				}
				text += ' - '
			})
			text = text.slice(0, -2)
			return text
		},
		subText() {
			let text = ''
			if (!this.activeClassList) return text
			this.activeClassList.forEach(it => {
				if (it.marks[0].value !== null) {
					text += it.marks[0].value + `(${it.marks[0].text})`
				}
				text += ' - '
			})
			text = text.slice(0, -2)
			return text
		},
		dragOptions() {
			return {
				handle: '.item-handle',
				scrollSensitivity: 110,
				['force-fallback']: true,
			}
		},
	},
	async created() {
		await this.getMngcode(this.institute.id)
	},
	methods: {
		...mapActions('inventoryGen2/setting', ['getMngcode', 'editMngcode']),
		...mapMutations('inventoryGen2/setting', ['setActiveClassList']),
		onDragStart(e) {
			this.isDragging = true
			e.target.classList.add('grabbing')
		},
		onDragEnd(e) {
			this.isDragging = false
			e.target.classList.remove('grabbing')
			this.$root.toast(this.tt('관리번호 재설정'), this.tt('물품 개수에 따라 시간이 다소 소요될 수 있습니다'), 'success')
		},
		deleteModalManagementNumber(index) {
			if (this.mngcode.activeClassList[index].code === 'MNGCODE_STORAGE_NAME' && this.activeCodeList.includes('MNGCODE_STORAGE_ORDER')) {
				this.$refs['modal-delete-close'].show()
				return false
			}

			this.$refs['modal-delete-management-number'].show(async () => {
				try {
					const newClassList = JSON.parse(JSON.stringify(this.mngcode.activeClassList))
					newClassList.splice(index, 1)
					await this.editMngcode({ activeClassList: newClassList })
					this.$root.toast(this.tt('관리번호 재설정'), this.tt('물품 개수에 따라 시간이 다소 소요될 수 있습니다'), 'success')
				} catch (e) {}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.grabbing {
	cursor: grabbing;
}

.flip-list-move {
	transition: transform 0.5s;
}
.no-move {
	transition: transform 0s;
}
.management-wrap {
	.title-wrap {
		display: flex;
		gap: 10px;
		border-bottom: 1px solid $DIVIDER;
		padding: 15px;
		& > div {
			margin-right: auto;
		}
		h5 {
			font-weight: 700;
			font-size: 16px;
		}
		p {
			font-size: 14px;
			color: $PLACEHOLDER;
		}
		button {
			align-self: center;
		}
	}
	.content-wrap {
		padding: 10px;
		width: 100%;
		height: 100%;
		overflow: auto;
		white-space: nowrap;
		& > ul {
			box-sizing: border-box;
			text-align: left;
			li {
				width: 270px;
				margin-right: 15px;
				font-weight: 700;
				color: #000;
				font-size: 1rem;
				// padding: 20px 10px;
				box-sizing: border-box;
				display: inline-block;
			}
		}
		& > div {
			height: 515px;
		}
	}
}
</style>
