<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">
				{{ tt('물품 소분하기') }}
			</div>
		</template>
		<template>
			<div class="body-wrap">
				<div class="body-header-wrap">
					<div class="body-header" :class="{ error: error }">
						<span class="name">
							{{ item.name }}
						</span>
						<span class="remain-volume" :class="[{ green: isValidVolume, red: !isValidVolume }]">
							{{ `${tt('잔여 용량')}: ${remainVolume.toLocaleString()}${item.volumeUnit}` }}
						</span>
					</div>

					<span class="error-msg" v-if="error">{{ tt('잔여 용량을 초과하였습니다.') }}</span>
				</div>
				<div class="divide-form-wrap">
					<div class="divide-form-header">
						<div class="title">{{ tt('용량') }}</div>
						<div class="title">{{ tt('개수') }}</div>
					</div>
					<div class="divide-form" v-for="(d, idx) in data" :key="d.key">
						<form-divide
							placeholder="용량을 입력해주세요"
							:value="d.volume"
							:unit="item.volumeUnit"
							@input="volume => changeVolume(volume, idx)"
						></form-divide>
						<div class="cross-wrap">
							<img class="divide-cross" width="12" height="12" src="@/views/inventoryGen2/svg/detail/divide-cross-icon.svg" />
						</div>
						<form-divide
							placeholder="개수를 입력해주세요"
							:value="d.count"
							unit="개"
							@input="count => changeCount(count, idx)"
						></form-divide>
						<button class="button-gray trash" @click="removeForm(idx)" :disabled="!canRemoveItem">
							<img v-if="canRemoveItem" src="@/views/inventoryGen2/svg/detail/icon-trash.svg" width="24" height="24" />
							<img v-else src="@/assets/svg/icon/icon-trash-disable.svg" width="24" height="24" />
						</button>
					</div>
				</div>
				<button class="btn-add" @click="addForm">{{ `+ ${tt('추가하기')}` }}</button>
			</div>
		</template>
		<template #modal-footer>
			<modal-footer @cancel="$refs['modal'].hide()" @ok="complete"></modal-footer>
		</template>
	</b-modal>
</template>
<script>
import ModalFooter from '@/views/common/components/modalGen2/components/footer/ModalFooter.vue'
import FormDivide from '@/views/inventoryGen2/components/detail/modal/modalDivide/formDivide.vue'
import { mapGetters, mapMutations } from 'vuex'
import Decimal from 'decimal.js'
export default {
	name: 'ModalAddDivide',
	components: { ModalFooter, FormDivide },
	props: {
		item: {
			type: Object,
			default: null,
		},
		divideItemsVolume: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return { createCount: 1, showError: false }
	},
	computed: {
		...mapGetters('modal/addDivide', ['data', 'usedVolume']),
		remainVolume() {
			return Decimal.sub(this.item.currentVolume, Decimal.add(this.usedVolume ?? 0, this.divideItemsVolume ?? 0))
		},
		isValidVolume() {
			return this.remainVolume >= 0
		},
		error() {
			return this.showError && !this.isValidVolume
		},
		canRemoveItem() {
			return this.data.length > 1
		},
	},
	watch: {
		isValidVolume() {
			if (this.remainVolume >= 0) {
				this.showError = false
			}
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('modal/addDivide', ['setInit', 'setItem', 'setData']),
		init() {
			this.showError = false
			this.setItem(this.item)
		},
		show() {
			this.setInit()
			this.init()
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		addForm() {
			let data = [...this.data]
			data.push({ key: this.createCount++, count: null, volume: null })
			this.setData(data)
		},
		removeForm(idx) {
			let data = this.data.filter((item, itemIdx) => idx !== itemIdx)
			this.setData(data)
		},
		changeCount(count, idx) {
			let data = this.$_.cloneDeep(this.data)
			data[idx].count = Number(count)
			this.setData(data)
		},
		changeVolume(volume, idx) {
			let data = this.$_.cloneDeep(this.data)
			data[idx].volume = Number(volume)
			this.setData(data)
		},
		complete() {
			if (!this.isValidVolume) {
				this.showError = true
				this.$root.toast('잔여 용량 초과', '잔여 용량을 초과하였습니다', 'error')
			} else {
				this.$emit('complete', this.data)
				this.hide()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-add-divide.scss';
</style>
