<template>
	<form-product
		v-if="category"
		title="물품정보"
		:productInfo="item"
		:categoryInfo="categoryInfo"
		:isValid="isProductValid"
		:isUnitValid="isUnitValid"
		:hideFieldKey="hideFieldKey"
		ref="form-product"
		:isShowError="showError"
		@change="changeFormProduct"
		@selectItem="handleSelectItem"
		forRegistItem
		:isSearch="!isFormTypeEdit"
	>
	</form-product>
</template>
<script>
import FormProduct from '@/views/common/components/form/FormProduct.vue'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { LargeClass } from '@/utils/define/ItemCode'

export default {
	name: 'ItemFormProduct',
	components: {
		FormProduct,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('inventoryGen2/form', ['item', 'category', 'categoryInfo', 'isProductValid', 'isUnitValid', 'showError', 'itemFormType']),
		isFormTypeEdit() {
			return this.itemFormType === 'NORMAL_EDIT'
		},
		hideFieldKey() {
			if (!this.isFormTypeEdit) {
				return this.categoryInfo?.classification !== LargeClass.ETC ? ['currentVolume'] : []
			} else {
				return []
			}
		},
		getMsdsParam() {
			return {
				brandId: this.item?.brandId ?? this.item.brand?.id,
				productCode: this.item?.productCode,
			}
		},
	},
	watch: {
		'item.productCode'() {
			this.getMsds(this.getMsdsParam)
		},
		'item.brandId'() {
			this.getMsds(this.getMsdsParam)
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/form', ['setFormItem']),
		...mapActions('inventoryGen2/form', ['getMsds']),
		changeFormProduct(value, fieldKey) {
			const newItem = { ...this.item }

			if (typeof value === 'object') {
				this.setObjectValue(newItem, fieldKey, value)
			} else {
				newItem[fieldKey] = value ?? null
				this.setFormItem(newItem)
			}
		},
		setObjectValue(newItem, fieldKey, value) {
			switch (fieldKey) {
				case 'volume':
				case 'currentVolume':
					// 잔여 용량 대응
					this.setVolume(newItem, fieldKey, value ?? null)
					break
				case 'brand':
					// 브랜드
					this.setBrand(newItem, value)
					break
				case 'phRange':
					this.setPhRange(newItem, value)
					break
				default:
					if (value.value !== null) {
						newItem[fieldKey] = value.value
					}
					if (value.unit) {
						newItem[`${fieldKey}Unit`] = value.unit
					}
					this.setFormItem(newItem)
					break
			}
		},
		setVolume(newItem, fieldKey, value) {
			if (fieldKey === 'currentVolume') {
				if (Number(value.value) >= Number(newItem.volume)) {
					newItem.volume = value.value
				}
				newItem.currentVolume = value.value
				newItem.currentVolumeUnit = value.unit ? value.unit : newItem?.currentVolumeUnit
				newItem.volumeUnit = value.unit ? value.unit : newItem?.currentVolumeUnit
			} else if (fieldKey === 'volume') {
				if (this.isFormTypeEdit) {
					if (Number(value.value) <= Number(newItem.currentVolume) || !newItem.currentVolume) {
						newItem.currentVolume = value.value
						newItem.currentVolumeUnit = value.unit ? value.unit : newItem?.volumeUnit
					}
				} else {
					newItem.currentVolume = value.value
					newItem.currentVolumeUnit = value.unit ? value.unit : newItem?.volumeUnit
				}
				newItem.volume = value.value
				newItem.volumeUnit = value.unit ? value.unit : newItem?.volumeUnit
			}
			this.setFormItem(newItem)
		},
		setBrand(newItem, value) {
			if (value.id) {
				newItem['brand'] = value
				newItem['brandName'] = value.name
				newItem['brandId'] = value.id ?? null
			} else if ((value.name ?? null) != null) {
				newItem['brandName'] = value.name
				newItem['brandId'] = null
				newItem['brand'] = null
			}
			console.warn(newItem.brandName, newItem.brandId, newItem.brand)

			if (!value.name) {
				newItem['brand'] = null
				newItem['brandName'] = null
				newItem['brandId'] = null
			}
			console.warn({ ...newItem })
			this.setFormItem(newItem)
		},
		setPhRange(newItem, value) {
			newItem['phRangeFrom'] = value.from
			newItem['phRangeTo'] = value.to
			this.setFormItem(newItem)
		},
		handleSelectItem(item) {
			const { brand, casno, kind, modelName, name, productCode, spec, subname, volume, volumeUnit, volumes } = item
			this.setFormItem({
				...this.item,
				casno: casno,
				kind: kind,
				modelName: modelName,
				name: name,
				productCode: productCode,
				spec: spec,
				subname: subname,
				volume: volume,
				volumeUnit: volumeUnit,
				currentVolumeUnit: volumeUnit,
				volumes: volumes, // 잔여 용량 대응
			})
			if (brand) {
				this.setFormItem({ ...this.item, brandId: brand.id, brandName: brand.name })
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-form-item.scss';
</style>
