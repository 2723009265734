<template>
	<modal-filter ref="modal" @confirm="confirm" @reset="reset" hasSearch>
		<template #modal-content v-if="filter">
			<filter-list
				ref="filter-registerMethods"
				title="등록방식"
				:filterCookieName="filterCookieName.registerMethods"
				:list="filter.registerMethods"
			>
			</filter-list>
			<filter-list ref="filter-status" title="상태" :filterCookieName="filterCookieName.status" :list="filter.status"> </filter-list>
			<filter-list ref="filter-categoriesId" title="카테고리" :filterCookieName="filterCookieName.categoriesId" :list="filter.categories">
			</filter-list>
			<filter-list ref="filter-brandsId" title="브랜드" :filterCookieName="filterCookieName.brandsId" :list="filter.brands"> </filter-list>
			<filter-list-storage ref="filter-storagesId" title="보관함" :filterCookieName="filterCookieName.storagesId" :list="filter.storages">
			</filter-list-storage>
			<filter-list
				ref="filter-assigneesId"
				hasImage
				:defaultImage="require('@/assets/svg/icon/icon-no-member-img.svg')"
				title="담당자"
				:filterCookieName="filterCookieName.assigneesId"
				:list="filter.assignees"
			>
			</filter-list>
			<filter-list ref="filter-msds" title="MSDS" :filterCookieName="filterCookieName.hasMsds" :list="filter.hasMsds"> </filter-list>
			<!-- <filter-list ref="filter-legalsId" title="해당법령" :filterCookieName="filterCookieName.legalsId" :list="filter.legals"> </filter-list> -->
			<filter-list-storage isLegal ref="filter-legalsId" title="해당법령" :filterCookieName="filterCookieName.legalsId" :list="filter.legals">
			</filter-list-storage>
			<filter-list ref="filter-currentVolume" title="잔여 용량" :filterCookieName="filterCookieName.currentVolume" :list="filter.currentVolume">
			</filter-list>
			<filter-list ref="filter-threshold" title="안전재고량" :filterCookieName="filterCookieName.threshold" :list="filter.threshold">
			</filter-list>
			<filter-list ref="filter-expireDate" title="유효기간" :filterCookieName="filterCookieName.expireDate" :list="filter.expireDate">
			</filter-list>
		</template>
	</modal-filter>
</template>
<script>
import ModalFilter from '@/views/common/components/modalGen2/ModalFilterBase.vue'
import FilterList from '@/views/common/components/modalGen2/components/filter/FilterList.vue'
import FilterListStorage from '@/views/common/components/modalGen2/components/filter/FilterListStorage.vue'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
	components: {
		ModalFilter,
		FilterList,
		FilterListStorage,
	},
	computed: {
		...mapGetters('filter', ['text', 'selected']),
		...mapState('inventoryGen2/list', ['filter', 'filterCookieName']),
	},
	watch: {
		text() {
			if (this.text.length >= 1) {
				const count = Object.values(this.$refs)
					.map(it => it.countByText ?? 0)
					.reduce((a, b) => a + b, 0)
				this.setCount(count)
				if (this.selected == 1) this.changeScroll(1, this.selected)
				else this.setSelected(count == 0 ? 0 : 1)
			} else if (this.text == '' && this.$refs['modal'].body) {
				this.$refs['modal'].body.scrollTop = 0
			}
		},
		async selected(newVal, oldVal) {
			this.changeScroll(newVal, oldVal)
		},
	},
	methods: {
		...mapMutations('filter', ['setCount', 'setSelected']),
		...mapActions('inventoryGen2/list', ['getFilter']),
		async show() {
			await this.getFilter()
			this.$refs['modal'].show()
		},
		confirm() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.setCookies !== 'undefined') it.setCookies()
			})
			let params = {
				registerMethods: this.$refs['filter-registerMethods'].checkedList ?? null,
				status: this.$refs['filter-status'].checkedList ?? null,
				categoriesId: this.$refs['filter-categoriesId'].checkedList ?? null,
				brandsId: this.$refs['filter-brandsId'].checkedList ?? null,
				storagesId: this.$refs['filter-storagesId'].checkedList ?? null,
				assigneesId: this.$refs['filter-assigneesId'].checkedList ?? null,
				hasMsds: this.$refs['filter-msds'].checkedList ?? null,
				legalsId: this.$refs['filter-legalsId'].checkedList ?? null,
				currentVolume: this.$refs['filter-currentVolume'].checkedList ?? null,
				threshold: this.$refs['filter-threshold'].checkedList ?? null,
				expireDate: this.$refs['filter-expireDate'].checkedList ?? null,
			}
			this.$emit('changeLoadParams', params)
			this.$refs['modal'].hide()
			this.$root.toast('필터', '필터가 적용되었습니다.', 'success')
		},
		reset() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.reset !== 'undefined') it.reset()
			})
		},
		async changeScroll(newVal, oldVal) {
			await this.$nextTick()
			const body = this.$refs.modal.$refs.modal.$refs.body
			if (newVal == 0 && body) body.scrollTop = 0
			else {
				if (oldVal != 0) {
					const beforeHighlight = document.querySelectorAll('.check-item .highlight')?.item(oldVal - 1)
					beforeHighlight?.classList?.remove('active')
				}
				const currentHighlight = document.querySelectorAll('.check-item .highlight')?.item(newVal - 1)
				const scrollTop = currentHighlight.offsetTop
				body.scrollTop = scrollTop - 100
				currentHighlight.classList.add('active')
			}
		},
	},
}
</script>
<style lang="scss" scoped></style>
