import StringUtils from '@/utils/modules/StringUtils'
export default {
	data() {
		return {
			isTreeOpen: false,
		}
	},
	computed: {
		isSubtree() {
			return this.node.children?.length > 0 || this.node.storages?.length > 0
		},
		isJustStoreages() {
			return !!this.node.children?.length && this.node.storages?.length > 0
		},
		name() {
			return this.keyword ? StringUtils.replaceHighlight(this.node.name, this.keyword, 'span', 'search-keyword') : this.node.name
		},
	},
	mounted() {
		// if (this.isJustStoreages) {
		// 	this.isTreeOpen = false
		// }
		if (this.node.isOpen) this.isTreeOpen = true
	},
	watch: {
		node: {
			deep: true,
			handler() {
				this.isTreeOpen = this.node.isOpen
			},
		},
	},
	methods: {
		toggleTreeOpen() {
			this.isTreeOpen = !this.isTreeOpen
		},
		openAllChildren() {
			this.$refs['child-node']
		},
	},
}
