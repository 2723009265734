var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gettingStart"
  }, [_c('div', {
    staticClass: "starting"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('h1', [_vm._v("Getting Started!")]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.tt('랩매니저 Pro를 시작하기 위해서 멤버를 등록하고<br>조직도, 건물 위치, 보관함 등을 설정해주세요!'))
    }
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('button', {
    staticClass: "Btn__yellow",
    on: {
      "click": _vm.goRegisteringMember
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('설정하기')) + " ")]), _c('button', {
    staticClass: "Btn__lg",
    on: {
      "click": _vm.goExperience
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('튜토리얼')) + " ")]), _c('span', {
    on: {
      "click": _vm.goGuide
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('랩매니저 가이드보기')) + " ")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }