<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade hide-footer @close="cancel">
		<template #modal-title>
			<div class="title">{{ tt(title) }}</div>
		</template>
		<template>
			<div class="body">
				<div class="text-wrap" v-html="tt('물품을 정말로 폐기하시겠습니까?<br/>이 동작은 되돌릴 수 없습니다.')"></div>
				<div class="reason-wrap" v-click-outside="focusOut">
					<div class="input-head-text">
						<p v-html="tt(textAreaTitle)"></p>
						<p class="text-length">
							<span class="length" :class="{ over: isLengthOver }">{{ text.length }}</span
							>/{{ textMaxLength }}
						</p>
					</div>
					<textarea
						class="input-reason"
						:class="{ error: isShowTextError && isLengthOver }"
						v-model="text"
						@focus="isFocus = true"
						:placeholder="tt(`폐기 사유를 입력해주세요(${textMaxLength}자 제한)`)"
					/>
					<p class="error-msg" v-if="isShowTextError && isLengthOver">
						<img src="@/views/inventoryGen2/components/svg/icon-error.svg" />
						{{ tt('글자수를 초과하였습니다.') }}
					</p>
					<recent ref="recent" :show="isKeywordWrapperOpen" recentId="물품 폐기" @clickRecent="clickRecent" />
				</div>
			</div>
			<div class="modal-footer-wrap">
				<button class="button-white" @click="cancel">
					{{ tt('취소') }}
				</button>
				<button class="button-warning" @click="disposeComplete">
					{{ tt('폐기') }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import MixinItem from '@/mixins/inventory/MixinItem'
import MixinModalDispose from '@/views/inventoryGen2/mixin/MixinModalDispose.js'
import Recent from '@/views/inventoryGen2/components/detail/modal/modalInputUse/recent.vue'
export default {
	name: 'ModalDisposal',
	mixins: [MixinItem, MixinModalDispose],
	components: { Recent },
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/inventory/gen2/modal/_modal-input-use.scss';
@import '@/styles/scss/modal/_modal-base.scss';
</style>
