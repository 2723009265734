<template>
	<b-modal centered ref="modal" size="xl" no-close-on-backdrop no-fade hide-footer>
		<template #modal-title>
			<div class="title">{{ tt('구매관리 관련') }} <span class="tip">Tip</span></div>
		</template>
		<template>
			<div class="content">
				<p
					v-if="isOrder"
					v-html="tt(`[Tip 1] 상품주문 화면에서 상품을 주문하면,&nbsp;<span style='color:#faa400'>랩매니저 스토어로 접수</span>됩니다.`)"
				></p>
				<p
					v-else
					v-html="
						tt(
							`[Tip 1] 랩매니저 스토어 상품을 포함한 지출결의서를 승인하면, 랩매니저 스토어에&nbsp;<span style='color:#faa400'>자동으로 주문이 접수</span>됩니다.`
						)
					"
				></p>
				<p
					v-html="
						tt(
							`[Tip 2] 판매자가 주문을 승인한 후에,&nbsp;<span style='color:#faa400'>후불결제</span>가 가능합니다. 상품결제 페이지에서 결제할 상품을 확인해보세요`
						)
					"
				></p>
				<div class="flow-wrapper">
					<div>
						<img src="./images/icon-tip-1.png" />
						<p>{{ tt('랩매니저 스토어 상품 선택') }}</p>
					</div>
					<img src="./images/icon-arrow.svg" />
					<template v-if="!isOrder">
						<div>
							<img src="./images/icon-tip-2-1.png" />
							<p>{{ tt('지출결의서 작성') }}</p>
						</div>
						<img src="./images/icon-arrow.svg" />
					</template>
					<div>
						<img src="./images/icon-tip-2.png" />
						<p>{{ tt(isOrder ? '상품주문' : '지출결의서 승인') }}</p>
					</div>
					<img src="./images/icon-arrow.svg" />
					<div>
						<img src="./images/icon-tip-3.png" />
						<p>{{ tt('랩매니저 스토어 주문 접수') }}</p>
					</div>
					<img src="./images/icon-arrow.svg" />
					<div>
						<img src="./images/icon-tip-4.png" />
						<p>{{ tt('판매자 주문 승인') }}</p>
					</div>
					<img src="./images/icon-bar.svg" />
					<div>
						<img src="./images/icon-tip-5.png" />
						<p>{{ tt('후불 결제') }}</p>
					</div>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: 'ORDER',
		},
	},
	computed: {
		isOrder() {
			return this.type == 'ORDER'
		},
	},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
.title {
	display: flex;
	align-items: center;
	.tip {
		display: inline-block;
		padding: 0px 12px;
		margin-left: 8px;
		color: white;
		background: $RED;
		border-radius: 20px;
		font-size: 12px;
	}
}
::v-deep .modal-dialog {
	max-width: 1000px;
}
.content {
	margin-top: 10px;
	& > p {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $SECONDARY_1;
		&::before {
			content: '';
			margin-right: 8px;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: $DEFAULT;
		}
	}
	.flow-wrapper {
		margin-top: 30px;
		margin-bottom: 20px;
		display: flex;
		gap: 15px;
		div {
			display: flex;
			flex-direction: column;
			align-items: center;
			p {
				text-align: center;
				width: 80px;
				color: $SECONDARY_2;
				word-break: break-word;
				font-size: 11px;
				font-weight: 500;
				margin-top: 10px;
			}
		}
	}
}
</style>
