var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.focusOut,
      expression: "focusOut"
    }],
    staticClass: "search-box-wrapper"
  }, [_c('b-dropdown', {
    attrs: {
      "variant": "transparent",
      "menu-class": "option-menu",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "type"
        }, [_c('span', [_vm._v(_vm._s(_vm.typeText[_vm.currentType]))]), _c('img', {
          attrs: {
            "src": require("./assets/dropdown.svg")
          }
        })])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.typeText, function (value, key) {
    return _c('b-dropdown-item', {
      key: key,
      on: {
        "click": function click($event) {
          _vm.currentType = key;
        }
      }
    }, [_vm._v(_vm._s(value))]);
  }), 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.text,
      expression: "text"
    }],
    ref: "keyword",
    staticClass: "searchbox-keyword",
    attrs: {
      "placeholder": _vm.tt(_vm.placeholder)
    },
    domProps: {
      "value": _vm.text
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onDataChanged.apply(null, arguments);
      },
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.text = $event.target.value;
      }, _vm.handleInput],
      "focus": function focus($event) {
        _vm.isFocus = true;
      }
    }
  }), _c('div', {
    staticClass: "icon-wrapper"
  }, [_vm.text.length > 0 ? _c('img', {
    staticClass: "delete",
    attrs: {
      "src": require("./assets/delete.svg")
    },
    on: {
      "click": _vm.deleteValue
    }
  }) : _vm._e(), _vm.hasQR && _vm.text.length === 0 ? _c('img', {
    staticClass: "qr",
    attrs: {
      "src": require("./assets/search-qr.svg")
    },
    on: {
      "click": _vm.clickQR
    }
  }) : _vm._e(), _c('div', {
    staticClass: "search",
    on: {
      "click": _vm.onDataChanged
    }
  }, [_c('img', {
    attrs: {
      "src": require("./assets/search-white.svg")
    }
  })])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isKeywordWrapperOpen,
      expression: "isKeywordWrapperOpen"
    }],
    staticClass: "keyword-wrapper"
  }, [_c('recent', {
    ref: "recent",
    on: {
      "clickRecent": _vm.clickRecent
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }