import ParamMaker from '@/utils/ParamMaker'
import axiosInstance from '@/services/axios'

const endPoint = {
	lookupInstInfo: instId => `/institute/${instId}`,
	lookupLicense: instId => `/institute/${instId}/license`,
	editInstInfo: instId => `/institute/${instId}`,
	lookupMemberAll: (instId, keyword, groupsIdList, offset, length) =>
		`institute/${instId}/member/list?keyword=${keyword}&${ParamMaker.setArrayString('groupsId', groupsIdList)}&offset=${offset}&length=${length}`,
	lookupSummary: instId => `/institute/${instId}/sidebar/summary`,
	lookupIsNew: (instId, target) => `institute/${instId}/sidebar/newlist?target=${target}`,
}

const instituteInformationAPI = {
	lookupInstInfo: instId => axiosInstance.get(endPoint.lookupInstInfo(instId)),
	lookupLicense: instId => axiosInstance.get(endPoint.lookupLicense(instId)),
	editInstInfo: (instId, formData) =>
		axiosInstance.put(endPoint.editInstInfo(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	lookupMemberAll: (instId, keyword, groupsIdList, offset, length) =>
		axiosInstance.get(endPoint.lookupMemberAll(instId, keyword, groupsIdList, offset, length)),
	lookupMemberInfo: (instId, id) => axiosInstance.get(endPoint.lookupMemberInfo(instId, id)),
	lookupAuthorityList: instId => axiosInstance.get(endPoint.lookupAuthorityList(instId)),
	lookupSummary: instId => axiosInstance.get(endPoint.lookupSummary(instId)),
	lookupIsNew: (instId, target) => axiosInstance.get(endPoint.lookupIsNew(instId, target)),
}

export default instituteInformationAPI
