<template>
	<tr class="table-item" :class="{ new: isNew }" @click="goToDetail(item.id)">
		<td>
			<span class="checkbox" @click.stop v-b-tooltip.hover="tt(tooltipReason)">
				<input :disabled="!isCanPay" type="checkbox" :checked="isChecked" @change="checkedId(item.id)" />
			</span>
		</td>
		<td style="width: 80px">
			<div style="min-width: 80px; max-width: 120px">
				<span :class="['expenditure', 'status', item.paymentStatus, isCancelOrReturn]"> {{ tt(paymentStatus) }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					<p>
						<b>{{ tt(orderStatus) }}</b>
					</p>
					<p v-if="orderStatusSub" style="color: #ff3b31; font-size: 12px">{{ tt(orderStatusSub) }}</p>
				</span>
			</div>
		</td>
		<td>
			<div>
				<span style="text-decoration: underline" @click="showOrderDetail">{{ item.orderNo }}</span>
			</div>
		</td>
		<td>
			<div class="info-wrap" style="min-width: 350px; max-width: 450px">
				<p class="info-title">{{ formatText(item.product.name) }}</p>
				<p class="info-sub" v-if="item.product.brand || item.product.subname">
					{{ item.product.brand ? item.product.brand.name : '' }} |
					{{ item.product.subname ? item.product.subname : '' }}
				</p>
			</div>
		</td>
		<td v-if="isView('quantity')">
			<div>
				<span>{{ item.quantity }}</span>
			</div>
		</td>
		<td v-if="isView('payerName')">
			<div>
				<span>{{ formatText(item.payerName) }}</span>
			</div>
		</td>
		<td v-if="isView('budgetName')">
			<div>
				<span>{{ item.budget ? item.budget.name : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('price')">
			<div>
				<span>{{ tt('${1}원', item.orderItemPriceWithVat.toLocaleString()) }}</span>
			</div>
		</td>
		<td v-if="isView('draftNo')">
			<div>
				<span>{{ item.draft.subject ? item.draft.no : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('paymentDatetime')">
			<div>
				<span>{{ formatDatetimeFromISO(item.paymentDatetime) }}</span>
			</div>
		</td>
		<td v-if="isView('draftName')">
			<div>
				<span>{{ formatText(item.draft.subject) }}</span>
			</div>
		</td>
		<td v-if="isView('group')">
			<div>
				<span>{{ item.group ? item.group.name : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('orderer')">
			<div>
				<span>{{ item.orderer.name }}</span>
			</div>
		</td>
		<td v-if="isView('createDatetime')">
			<div>
				<span>{{ formatDatetimeFromISO(item.createDatetime) }}</span>
			</div>
		</td>
		<td v-if="isView('concluder')">
			<div>
				<span>{{ !item.draft.isDirect ? item.concluderName : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('concludeDatetime')">
			<div>
				<span>{{ !item.draft.isDirect ? formatDatetimeFromISO(item.concludeDatetime) : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('paymentNo')">
			<div>
				<span>{{ formatText(item.paymentNo) }}</span>
			</div>
		</td>
		<td v-if="isView('paymentCreateDatetime')">
			<div>
				<span>{{ formatDatetimeFromISO(item.paymentCreateDatetime) }}</span>
			</div>
		</td>
		<td v-if="isView('businessRegistrationName')">
			<div>
				<span>{{ formatText(item.businessRegistrationName) }}</span>
			</div>
		</td>
		<td v-if="isView('paymentMethod')">
			<div>
				<span>{{ tt(paymentMethod) }}</span>
			</div>
		</td>
		<td v-if="isView('platform')">
			<div>
				<span>{{ tt(item.orderPath) }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { paymentStatus, paymentMethod } from '@/utils/define/StatusCode'
import PageName from '@/utils/define/PageName'
import { DateTime } from 'luxon'
import MixinItem from '@/mixins/inventory/MixinItem'
export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('purchaseProduct/list', ['checkedViewOption', 'selectItem']),
		paymentStatus() {
			if (this.item.cancelStatus == 'DONE') return '주문취소'
			else if (this.item.returnStatus == 'DONE') return '반품완료'
			return paymentStatus[this.item.paymentStatus]
		},
		isCancelOrReturn() {
			return this.item.cancelStatus == 'DONE' || this.item.returnStatus == 'DONE' ? 'CANCEL' : ''
		},
		paymentMethod() {
			return this.item.paymentMethod ? paymentMethod[this.item.paymentMethod] : '-'
		},
		orderStatus() {
			if (this.item.returnStatus == 'DONE') return '교환/반품 완료'
			else if (this.item.cancelStatus == 'DONE') return '주문취소 완료'
			else if (this.item.status == 'WAIT' && this.item.cancelStatus == null) return '재고확인중'
			else if (this.item.status == 'APPROVE') return '출고준비중'
			else if (this.item.status == 'SHIPPING_ING') return '배송중'
			else if (this.item.status == 'SHIPPING_COMPLETE') return '배송완료'
			else if (this.item.status == 'CONFIRM') return '구매확정'
			return '재고확인중'
		},
		orderStatusSub() {
			if (this.item.returnStatus == 'ING') return '교환/반품 접수'
			else if (this.item.cancelStatus == 'ING') return '주문취소 접수'
			if (this.item.returnStatus == 'REJECT') return '교환/반품 거절'
			else if (this.item.cancelStatus == 'REJECT') return '주문취소 거절'
			return null
		},
		tooltipReason() {
			if (this.item.paymentStatus == 'PAID') return '이미 결제완료된 상품입니다'
			else if (this.item.cancelStatus == 'ING') return '주문취소 접수중인 상품은 결제할 수 없어요'
			else if (this.item.cancelStatus == 'DONE') return '주문취소 완료된 상품은 결제할 수 없어요'
			else if (this.item.returnStatus == 'ING') return '교환/반품 접수중인 상품은 결제할 수 없어요'
			else if (this.item.returnStatus == 'DONE') return '반품 완료된 상품은 결제할 수 없어요'
			else if (this.item.paymentStatus == 'CANCEL') return '결제취소된 상품입니다'
			else if (this.item.paymentStatus == 'WAIT') return '입금대기중인 상품입니다'
			else if (this.item.status == 'WAIT') return '판매자 주문승인 전 상품은 결제할 수 없어요'
			return null
		},
		profileImage() {
			return this.item.register.image ?? require('@/assets/svg/member-photo-default.svg')
		},
		concludeDatetime() {
			return this.item.concludeDatetime ? DateTime.fromISO(this.item.concludeDatetime).toFormat('yyyy-MM-dd T') : '-'
		},
		isChecked() {
			return this.selectItem.includes(this.item.id)
		},

		isCanPay() {
			return this.item.cancelStatus == null && this.item.status != 'WAIT' && this.item.paymentStatus == null
		},
	},
	methods: {
		...mapMutations('purchaseProduct/list', ['checkedId']),
		goToDetail(id) {
			this.setNew()
			this.$router.push({
				name: PageName.Purchase.Product.PayDetail,
				params: {
					id: this.item.id,
				},
			})
		},
		showOrderDetail() {
			this.$root.goToStore(`mypage/order/${this.item.orderId}`)
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
