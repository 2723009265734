<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="DuplicateAuthorityPopup">
			<div class="Popup__background"></div>
			<div class="Location__delete-location-popup Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt('권한 복제') }}
					</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text">
						{{ tt('권한을 복제하시겠습니까?') }}
					</p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="close">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="submit">
							{{ tt('복제') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalDuplicatePermission',
	mixins: [MixinModal],
	methods: {
		close() {
			this.$emit('isHide', false)
		},
		submit() {
			this.$emit('isHide', true)
		},
	},
}
</script>
