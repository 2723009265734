<template>
	<form-msds ref="form-msds" :msdsInfo="msds" :value="msdsFile" @change="changeFile" @delete="deleteFile">
		<template>
			<modal-confirm
				ref="modal-confirm"
				title="MSDS 직접 업로드"
				content="이미 등록된 MSDS가 있습니다. MSDS파일을 변경하시면 브랜드, 제품번호가 동일한 모든 물품의 MSDS가 최신화됩니다. 업로드 하시겠습니까?"
				@confirm="setUploadMsds(file)"
				@cancel="$refs['form-msds'].deleteFile()"
			></modal-confirm>
		</template>
	</form-msds>
</template>
<script>
import FormMsds from '@/views/common/components/form/msds/FormMsds.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'ItemFormMsds',
	components: { FormMsds },
	props: {},
	data() {
		return {
			file: null,
		}
	},
	computed: {
		...mapGetters('inventoryGen2/form', ['item', 'uploadMsds', 'itemFormType']),
		msds() {
			return this.item?.msds
		},
		msdsFile() {
			return this.item?.msdsFile ?? this.uploadMsds
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('inventoryGen2/form', ['setMsds', 'setUploadMsds', 'setItemMsds']),
		changeFile(files) {
			let hasFile = files?.[0] ?? null
			if (hasFile && this.item?.msds) {
				const { institute } = this.item.msds
				const hasUploadMsds = institute
				if (hasUploadMsds) {
					this.file = files[0]
					this.$refs['modal-confirm'].show()
				} else {
					this.setUploadMsds(files[0])
					if (this.itemFormType === 'EXCEL_EDIT') {
						this.setItemMsds(files[0])
					}
				}
			} else {
				this.setUploadMsds(files[0] ?? null)
				if (this.itemFormType === 'EXCEL_EDIT') {
					this.setItemMsds(files[0] ?? null)
				}
			}
		},
		modalCancel() {},
		setData(value) {
			this.$refs['form-msds'].setData(value)
		},
		deleteFile() {
			if (this.itemFormType === 'NORMAL_EDIT') {
				this.$emit('')
				console.warn('등록된 msds 파일 지우는 로직 추가', this.item?.msds?.institute?.id)
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
