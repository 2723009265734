import institutionAPI from '@/services/api/institute/information'

const institutionInformation = {
	namespaced: true,
	state: {
		name: '스마트잭',
		instInfo: {
			info: {
				logo: '',
				phone: '',
				name: '',
				address: '',
				traderRegistrationNumber: '',
			},
			license: {},
			allMemberCount: null,
			almightyMembers: [],
		},
		form: {
			info: {
				logo: '',
				phone: '',
				name: '',
				address: '',
				traderRegistrationNumber: '',
			},
		},
		listKeyword: '',
		tagsId: [],
	},
	getters: {
		institutionName: state => state.name,
		institutionInfo: state => state.instInfo,
	},
	mutations: {
		initTags: state => {
			state.tagsId = []
		},
		setInstInfo: (state, payload) => {
			state.instInfo = payload
		},
		setInstInfoAtForm: (state, payload) => {
			state.form = payload
		},
		setLogoUrl: (state, payload) => {
			state.form.info.logo = payload
		},
		setTraderNumber: (state, payload) => {
			state.form.info.traderRegistrationNumber = payload
		},
		setName: (state, payload) => {
			state.form.info.name = payload
		},
		setAddress: (state, payload) => {
			state.form.info.address = payload
		},
		setPhone: (state, payload) => {
			state.form.info.phone = payload
		},
		setLicense: (state, payload) => {
			state.license = payload
		},
		setKeyword: (state, payload) => {
			state.listKeyword = payload
		},
		setTags: (state, payload) => {
			state.tagsId = payload
		},
	},
	actions: {
		loadInstitutionInfo: async ({ commit }, instId) => {
			const response = await institutionAPI.lookupInstInfo(instId)
			commit('setInstInfo', response.data)
		},
		loadInstitutionInfoAtForm: async ({ commit }, instId) => {
			const response = await institutionAPI.lookupInstInfo(instId)
			commit('setInstInfoAtForm', response.data)
		},
		setInstituteLicense: async ({ commit }, instId) => {
			const response = await institutionAPI.lookupLicense(instId)
			commit('setLicense', response.data)
		},
		editInstituteInformation: async ({ commit, state, dispatch, rootGetters }, formData) => {
			try {
				const response = await institutionAPI.editInstInfo(rootGetters.instituteId, formData)
				await dispatch('loadInstitutionInfo', rootGetters.instituteId)
				await commit('user/setInstitute', state.instInfo.info, { root: true })
				return response
			} catch (e) {
				throw e
			}
		},
	},
}
export default institutionInformation
