var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "lm-table"
  }, [_c('table-header'), _vm._l(_vm.list, function (item) {
    return _c('table-item', {
      key: item.id,
      attrs: {
        "item": item
      }
    });
  }), _vm.count === 0 ? _c('div', {
    staticClass: "empty-table"
  }, [_c('img', {
    staticClass: "empty-img",
    attrs: {
      "src": require("@/assets/svg/empty/inventory-empty-data.svg")
    }
  }), _c('p', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.tt('검색 결과가 없습니다')))]), _c('p', {
    staticClass: "sub"
  }, [_vm._v(" " + _vm._s(_vm.tt('예산 번호, 기안 제목, 기안 연구실으로 검색할 수 있습니다.')) + " ")])]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }