<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="AddAuthorityMemberPopup Popup__depth3">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">
						{{ tt(title) }}
					</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<!-- <search-box
                        :placeholder="searchBoxPlaceholder"
                        @search="getInputGroupName"
                        @search="searchTree"
                    ></search-box> -->
					<div class="Organization__member-list">
						<ul class="horizational-list">
							<TreeView
								v-if="organizationData"
								ref="modal-tree-view"
								key="modal-tree-view"
								:treeData="organizationData"
								textField="name"
								subTextField="membersCount"
								subTextEndWord="명"
								:draggable="false"
								:mode="mode"
								:selectWithChild="false"
								:keyword="keyword"
								@changeActive="getActiveId"
								@load="loadedTree"
							></TreeView>
						</ul>
					</div>
				</div>
				<div class="Popup__bottom">
					<!-- <button
                        class="Btn__management"
                        v-if="type == 'STORAGE'"
                        @click="goToLocationTree"
                    >
                        보관함 관리
                    </button> -->
					<!-- <button
                        class="Btn__management"
                        @click="goToOrganizationTree"
                    >
                        위치 관리
                    </button> -->
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">
							{{ tt('취소') }}
						</button>
						<button class="Btn__complete" @click="complete">
							{{ tt('완료') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import SearchBox from '@/views/common/components/SearchBox.vue'
import TreeView from '@/views/common/components/tree/TreeView.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import instituteGroupAPI from '@/services/api/institute/group'
import router from '@/router'

export default {
	name: 'ModalSelectGroup',
	components: { SearchBox, TreeView },
	mixins: [MixinModal],
	props: {
		groupInfo: {
			type: Object,
		},
	},
	data() {
		return {
			title: '',
			memberList: [],
			originGroupList: [],
			selectedGroupList: [],
			selectedGroupNameList: [],
			searchBoxPlaceholder: '',
			organizationData: null,
			inputGroupName: '',
			mode: '',
			type: '',
			isShow: false,
			groupId: '',
		}
	},
	watch: {
		groupInfo: {
			deep: true,
			handler() {
				this.originGroupList = this.groupInfo.access.groups.map(item => item.id)
			},
		},
	},
	computed: {
		...mapState('user', ['institute']),
		...mapState('organization', ['groupTreeData']),
		...mapGetters('organization', ['selectedGroupInfo']),
	},
	methods: {
		...mapActions('organization', ['loadGroupTree']),
		...mapActions('location', ['lookupTotalStorageTree', 'lookupTotalLocationTree']),
		complete() {
			this.$emit('isHide', this.selectedGroupList)
		},
		completeSelectGroups() {
			this.$emit('selectGroups', this.selectedGroupList)
			this.hide()
		},
		completeAddAuthority() {
			// const selectedGroupId = this.selectedGroupInfo.id
			// const addGroupList = this.selectedGroupList.filter(
			//     (item) => this.originGroupList.indexOf(item) < 0
			// )
			const data = {
				groupsId: this.selectedGroupList,
				usersId: [],
				permission: {
					read: true,
					update: false,
				},
			}
			instituteGroupAPI.addAccessAuthority(this.groupId, data).then(response => {
				this.$emit('refresh')
				this.hide()
				this.showCompleteToast()
			})
		},
		loadedTree() {
			this.$refs['modal-tree-view'].setActiveIdList([...this.originGroupList])
		},
		goToLocationTree() {
			router.push({ name: 'Location' })
		},
		goToOrganizationTree() {
			router.push({ name: 'Location' })
		},
		getActiveId(idList, dataList) {
			this.selectedGroupList = dataList
		},
		getActiveName(nameList) {
			this.selectedGroupNameList = nameList
		},
		showCompleteToast() {
			const title = this.tt('접근 권한 추가완료')
			const content = this.tt('접근 권한이 추가되었습니다')
			const type = 'success'
			this.$root.toast(title, content, type)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/institution/_organization.scss';

.Organization__member-list {
	height: 500px;
}
.AddAuthorityMemberPopup {
	.horizational-list {
		height: 500px;
		width: 100%;
		padding-top: 0;
		margin-top: 20px;
		border-radius: 4px;
		border: 1px solid $DIVIDER;
		overflow-y: auto;
		box-sizing: border-box;
		li {
			padding: 12px;
			border-bottom: 0;
			transition: 0.3s all linear;
			&:hover {
				background-color: $GREY_1;
			}
			button {
				img {
					width: 20px;
					background-image: '@/assets/svg/Select-Circle.svg';
				}
			}
			> div {
				h6 {
					> img {
						width: 12px;
						margin-left: 6px;
					}
				}
			}
		}
	}
}
</style>
