<template>
	<span :style="styleObj"></span>
</template>

<script>
export default {
	props: {
		styleObj: {
			type: Object,
			default: () => {},
		},
	},
}
</script>
