var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-field-wrap"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.tt(_vm.fieldName)))]), _c('div', {
    staticClass: "field-wrap"
  }, [_vm.isSticker ? _c('div', {
    staticClass: "field-text",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-print-sticker'].show();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('미리보기')) + " ")]) : _c('div', {
    staticClass: "field-text-none"
  }, [_vm._v("-")])]), _c('modal-print-sticker', {
    ref: "modal-print-sticker",
    attrs: {
      "selectedCargoId": [_vm.itemId]
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }