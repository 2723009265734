import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'
import router from '@/router'
const menuList = [
	{
		menuTitle: '홈',
		menuIcon: 'side-dashboard.svg',
		childList: [
			{
				menuTitle: '대시보드',
				menuPath: RoutePath.dashboard,
				pageName: PageName.Dashboard,
				clickMenu: () => {
					router.push({
						name: PageName.Dashboard,
					})
				},
			},
		],
	},
	{
		menuTitle: '예산관리',
		menuIcon: 'sideMenu/icon-expense.svg',
		childList: [
			{
				menuTitle: '예산 현황',
				menuPath: RoutePath.Budget.Status,
				pageName: PageName.Budget.Status,
				clickMenu: () => {
					router
						.push({
							name: PageName.Budget.Status,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '예산 목록',
				menuPath: RoutePath.Budget.List,
				pageName: PageName.Budget.List,
				clickMenu: () => {
					router
						.push({
							name: PageName.Budget.List,
						})
						.catch(() => {})
				},
			},
		],
	},
	{
		menuTitle: '결재관리',
		menuIcon: 'sideMenu/approval-management.svg',
		childList: [
			{
				menuTitle: '지출결의서 기안함',
				menuPath: RoutePath.Purchase.DraftList,
				pageName: PageName.Purchase.DraftList,
				clickMenu: () => {
					router
						.push({
							name: PageName.Purchase.DraftList,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '지출결의서 결재함',
				menuPath: RoutePath.Purchase.ApprovalList,
				pageName: PageName.Purchase.ApprovalList,
				clickMenu: () => {
					router
						.push({
							name: PageName.Purchase.ApprovalList,
						})
						.catch(() => {})
				},
			},
		],
	},
	{
		// todo 편집
		menuTitle: '구매관리',
		menuIcon: 'sideMenu/purchase-management.svg',
		menuImage: 'store/labmanager-store.svg',
		childList: [
			{
				menuTitle: '상품주문',
				menuPath: RoutePath.Purchase.Product.Order,
				pageName: PageName.Purchase.Product.Order,
				clickMenu: () => {
					router
						.push({
							name: PageName.Purchase.Product.Order,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '주문/배송 관리',
				menuPath: 'store/delivery',
				icon: 'store/icon-store.svg',
				tooltip: '랩매니저 스토어로 이동합니다',
				pageName: '',
				clickMenu: () => {
					return { type: 'store', link: 'mypage/order' }
				},
			},
			{
				menuTitle: '결제관리',
				menuPath: 'store/payment',
				icon: 'store/icon-store.svg',
				tooltip: '랩매니저 스토어로 이동합니다',
				pageName: '',
				clickMenu: () => {
					return { type: 'store', link: 'mypage/unpayment' }
				},
			},
			// {
			// 	menuTitle: '상품결제',
			// 	menuPath: RoutePath.Purchase.Product.Pay,
			// 	pageName: PageName.Purchase.Product.Pay,
			// 	clickMenu: () => {
			// 		router
			// 			.push({
			// 				name: PageName.Purchase.Product.Pay,
			// 			})
			// 			.catch(() => {})
			// 	},
			// },
			// {
			// 	menuTitle: '기타설정',
			// 	menuPath: '',
			// 	icon: 'store/icon-store.svg',
			// 	tooltip: '랩매니저 스토어로 이동합니다',
			// 	pageName: '',
			// 	clickMenu: () => {
			// 		return { type: 'store', link: 'mypage/lab' }
			// 	},
			// },
		],
	},
	{
		menuTitle: '물품관리',
		menuIcon: 'Inventory.svg',
		sideClick: true,
		childList: [
			{
				menuTitle: '물품목록',
				menuPath: RoutePath.InventoryGen2.List,
				pageName: PageName.InventoryGen2.List,
				clickMenu: () => {
					router
						.push({
							name: PageName.InventoryGen2.List,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '입고등록 대기',
				menuPath: RoutePath.Purchase.CargoList,
				pageName: PageName.Purchase.CargoList,
				clickMenu: () => {
					router
						.push({
							name: PageName.Purchase.CargoList,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '사진등록 대기',
				menuPath: RoutePath.InventoryGen2.WaitList,
				pageName: PageName.InventoryGen2.WaitList,
				clickMenu: () => {
					router
						.push({
							name: PageName.InventoryGen2.WaitList,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '관리기록 조회',
				menuPath: RoutePath.InventoryGen2.HistoryList,
				pageName: PageName.InventoryGen2.HistoryList,
				clickMenu: () => {
					router
						.push({
							name: PageName.InventoryGen2.HistoryList,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '폐기/삭제된 물품',
				menuPath: RoutePath.InventoryGen2.DisposalList,
				pageName: PageName.InventoryGen2.DisposalList,
				clickMenu: () => {
					router
						.push({
							name: PageName.InventoryGen2.DisposalList,
						})
						.catch(() => {})
				},
			},
			// {
			// 	menuTitle: '폐기/삭제된 물품',
			// 	menuPath: RoutePath.inventory.disposal,
			// 	pageName: PageName.Inventory.Disposal,
			// 	clickMenu: () => {
			// 		router
			// 			.push({
			// 				name: PageName.Inventory.Disposal,
			// 			})
			// 			.catch(() => {})
			// 	},
			// },
			{
				menuTitle: '설정',
				menuPath: '',
				pageName: PageName.InventoryGen2.Setting,
				clickMenu: () => {
					return 'inventoryGen2Setting'
				},
			},
		],
	},
	{
		menuTitle: '안전관리',
		menuIcon: 'Safety1.svg',
		childList: [
			{
				menuTitle: 'MSDS',
				menuPath: RoutePath.safety.msds,
				pageName: PageName.Safety.MSDS,
				clickMenu: () => {
					router
						.push({
							name: PageName.Safety.MSDS,
						})
						.catch(() => {})
				},
			},
			//todo
			// {
			// 	menuTitle: 'GHS 라벨',
			// 	menuPath: RoutePath.safety.ghs,
			// 	pageName: PageName.Safety.GHSLabel,
			// 	clickMenu: () => {
			// 		router
			// 			.push({
			// 				name: PageName.Safety.GHSLabel,
			// 			})
			// 			.catch(() => {})
			// 	},
			// },
			{
				menuTitle: '위험물 지정수량 배수',
				menuPath: RoutePath.safety.dangerous,
				pageName: PageName.Safety.DangerousStorage,
				clickMenu: () => {
					router
						.push({
							name: PageName.Safety.DangerousStorage,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '유해화학물질',
				menuPath: RoutePath.safety.chemical,
				pageName: PageName.Safety.ChemicalMaterials,
				clickMenu: () => {
					router
						.push({
							name: PageName.Safety.ChemicalMaterials,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '특별관리물질',
				menuPath: RoutePath.safety.management,
				pageName: PageName.Safety.ManagementMaterials,
				clickMenu: () => {
					router
						.push({
							name: PageName.Safety.ManagementMaterials,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '특수건강진단',
				menuPath: RoutePath.safety.health,
				pageName: PageName.Safety.HealthCheck,
				clickMenu: () => {
					router
						.push({
							name: PageName.Safety.HealthCheck,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '그룹별 법령물품',
				menuPath: RoutePath.safety.legal,
				pageName: PageName.Safety.LegalProduct,
				clickMenu: () => {
					router
						.push({
							name: PageName.Safety.LegalProduct,
						})
						.catch(() => {})
				},
			},
		],
	},
	{
		menuTitle: '기관 관리',
		menuIcon: 'Groups.svg',
		childList: [
			{
				menuTitle: '기관 정보',
				menuPath: RoutePath.institute.information,
				pageName: PageName.Institution.Information,
				clickMenu: () => {
					router
						.push({
							name: PageName.Institution.Information,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '조직도',
				menuPath: RoutePath.institute.organization,
				pageName: PageName.Institution.Organization,
				clickMenu: () => {
					router
						.push({
							name: PageName.Institution.Organization,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '위치 및 보관함',
				menuPath: RoutePath.institute.location,
				pageName: PageName.Institution.Location,
				clickMenu: () => {
					router
						.push({
							name: PageName.Institution.Location,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '멤버',
				menuPath: RoutePath.institute.memberManagement,
				pageName: PageName.Institution.MemberManagement,
				clickMenu: () => {
					router
						.push({
							name: PageName.Institution.MemberManagement,
						})
						.catch(() => {})
				},
			},
			{
				menuTitle: '권한',
				menuPath: RoutePath.institute.authoritySetting,
				pageName: PageName.Institution.AuthoritySetting,
				clickMenu: () => {
					router
						.push({
							name: PageName.Institution.AuthoritySetting,
						})
						.catch(() => {})
				},
			},
		],
	},
]

export { menuList }
