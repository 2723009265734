var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "header-wrap"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s("".concat(_vm.item.name)))]), _c('div', {
    staticClass: "subtext"
  }, [_vm.item.brand && _vm.item.brand.name ? _c('div', {
    staticClass: "brand",
    domProps: {
      "innerHTML": _vm._s(_vm.formatBrand(_vm.item.brand))
    }
  }) : _vm._e(), _vm.item.subname ? _c('div', {
    staticClass: "subname",
    domProps: {
      "innerHTML": _vm._s(_vm.formatText(_vm.item.subname))
    }
  }) : _vm._e()])]), !_vm.item.isDel && !_vm.item.isDispose ? _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "right-gray"
  }, [_c('button', {
    staticClass: "favorite-button",
    "class": {
      active: _vm.item.favorite
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isFavorite,
      expression: "isFavorite"
    }],
    staticClass: "favorite",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.isFavorite) ? _vm._i(_vm.isFavorite, null) > -1 : _vm.isFavorite
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.isFavorite,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isFavorite = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isFavorite = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isFavorite = $$c;
        }
      }
    }
  })])]), _c('div', {
    staticClass: "right-primary"
  }, [_vm.canUse ? _c('button', {
    staticClass: "lmbutton primary",
    on: {
      "click": _vm.use
    }
  }, [_vm._v(_vm._s(_vm.tt('사용하기')))]) : _vm.item.status === 'UNAVAILABLE' && _vm.item.holder ? _c('button', {
    staticClass: "lmbutton background-white-gray"
  }, [_vm._v(" " + _vm._s(_vm.tt('${1}님이 사용중', _vm.item.holder.name)) + " ")]) : _vm.item.status === 'HOLDING_OWN' ? _c('button', {
    staticClass: "lmbutton background-white-primary",
    on: {
      "click": _vm.returnUse
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('사용 완료하기')) + " ")]) : _vm.showDispose ? _c('button', {
    staticClass: "lmbutton red",
    on: {
      "click": function click($event) {
        return _vm.$refs['modal-dispose'].show();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tt('폐기하기')) + " ")]) : _vm._e()])]) : _vm._e()]), _c('modal-input-use', {
    ref: "modal-input-use",
    attrs: {
      "title": "사용 완료하기",
      "volume": _vm.item.currentVolume,
      "volumeUnit": _vm.volumeUnit,
      "largeClass": _vm.item.category.classification,
      "useCount": _vm.item.useCount,
      "isCanCount": !_vm.isEquipment,
      "type": "RETURN",
      "cancelText": "취소",
      "confirmText": "완료"
    },
    on: {
      "confirm": _vm.completeUse,
      "showEmptyVolume": function showEmptyVolume(body) {
        return _vm.$refs['modal-empty-volume'].show(body);
      }
    }
  }), _c('modal-empty-volume', {
    ref: "modal-empty-volume",
    attrs: {
      "title": "잔여 용량 없음",
      "type": "DISPOSE"
    },
    on: {
      "dispose": _vm.disposeEmptyVolume,
      "noDispose": _vm.noDisposeEmptyVolume
    }
  }), _c('modal-dispose', {
    ref: "modal-dispose",
    attrs: {
      "title": "물품 폐기",
      "textAreaTitle": "폐기 사유"
    },
    on: {
      "dispose": function dispose(memo) {
        return _vm.dispose(memo);
      }
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }