var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "badge-list-wrap"
  }, [_c('item-badge', {
    attrs: {
      "image": _vm.categoryImage,
      "text": _vm.categoryName,
      "subtext": "카테고리"
    }
  }), _vm.item.categoryType !== 'CAT_LABWARE_EQUIPMENT' ? _c('item-badge', {
    attrs: {
      "image": require("@/views/purchase/cargo/svg/badge-box".concat(_vm.isCurrentVolumeValid ? '' : '-red', ".svg")),
      "text": _vm.tt(_vm.formatVolumeBadge),
      "subtext": _vm.item.categoryType === 'CAT_ETC' ? '잔여 수량' : '패키지 수량',
      "isValid": _vm.isCurrentVolumeValid
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }