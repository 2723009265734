var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "LmInputSearch__container"
  }, [_c('input', {
    staticClass: "LmInputSearch__input",
    attrs: {
      "placeholder": _vm.tt(_vm.placeholder)
    },
    domProps: {
      "value": _vm.keyword
    },
    on: {
      "input": _vm.changeValue,
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.search.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "LmInputSearch__button-wrapper"
  }, [_c('group-search', {
    on: {
      "keyup:enter": _vm.search
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }