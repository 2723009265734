<template>
	<tr>
		<td>{{ formatDatetime(item.createDatetime, false) }}</td>
		<td>{{ item.quantity == MAX_QUANTITY ? tt('무제한') : formatNumberLocale(item.quantity) }}</td>
		<td>
			<p>{{ formatNumberLocale(item.periodDaysCount) + tt('일') }}</p>
			<span>{{ `(${item.period})` }}</span>
		</td>
		<td>{{ `₩${formatNumberLocale(item.totalPrice)}` }}</td>
		<td>{{ item.type == 'FREE_TIER' ? '프리티어' : paymentMethod2Text(item.paymentMethod, isAdditionItem) }}</td>
		<td>
			<div class="payment-status warning" v-if="isPaymentEbill && isPaymentWait">
				<b>
					{{ tt('입금대기') }}
					<margin-span :styleObj="{ marginRight: '3px' }"></margin-span>
					<lm-ballon
						:title="tt('입금대기')"
						:content="
							tt(
								`${item.virtualAccount.bankName} ${item.virtualAccount.number} (예금주: ${
									item.virtualAccount.holder
								})<br>결제금액: ￦${formatNumberLocale(item.totalPrice)}`
							)
						"
						:balloonStyle="{ width: '340px' }"
					></lm-ballon>
				</b>
				<span>{{ `${tt('입금기한')}: ${formatDatetime(item.virtualAccount.expireDatetime)}` }}</span>
			</div>
			<div>
				<div class="payment-status warning" v-if="isPaymentCancel">
					<b>{{ tt('주문취소') }}</b>
				</div>

				<div class="payment-status" v-if="isPaymentSuccess">
					<p>{{ tt('결제 완료') }}</p>
				</div>

				<div class="payment-status" v-if="isPaymentFail">
					<p class="underline" v-b-tooltip.hover="item.memo">{{ tt('결제 실패') }}</p>
				</div>
			</div>
		</td>
		<td>
			<div class="download-link">
				<template v-if="isPaymentEbill && !isPaymentCancel && (item.receiptUrl || item.virtualAccount?.confirmationUrl)">
					<a download :href="item.receiptUrl">{{ tt('전자세금계산서') }}</a>
					<span>/</span>
					<a download :href="item.virtualAccount?.confirmationUrl">{{ tt('가상계좌발급서') }}</a>
					<span>/</span>
					<a href="#" @click="$emit('showInvoice')">{{ tt('거래명세서') }}</a>
				</template>
				<template v-else-if="isPaymentEbill && isPaymentCancel">
					<a download :href="item.cancelReceiptUrl" v-if="item.cancelReceiptUrl">{{ tt('(취소)전자세금계산서') }}</a>
					<span v-if="item.cancelReceiptUrl && item.receiptUrl">/</span>
					<a v-if="item.receiptUrl" download :href="item.receiptUrl">{{ tt('(구매)전자세금계산서') }}</a>
				</template>
				<template v-else-if="!isPaymentEbill && item.receiptUrl">
					<a download :href="item.receiptUrl">{{ tt('영수증') }}</a>
					<span>/</span>
					<a href="#" @click="$emit('showInvoice')">{{ tt('거래명세서') }}</a>
				</template>
				<template v-else>-</template>
			</div>
		</td>
		<td>
			{{ item.payDate }}
		</td>
	</tr>
	<!-- <tr>
			<td>2021-01-01</td>
			<td>100</td>
			<td>
				<p>12개월</p>
				<span>(2022~01-01~2023-01-01)</span>
			</td>
			<td>￦60,000,000</td>
			<td>신용/체크카드</td>
			<td>
				<div class="payment-status">
					<p>결제 완료</p>
				</div>
			</td>
			<td>
				<div class="download-link">
					<a download>{{ tt('영수증') }}</a>
				</div>
			</td>
		</tr>
		<tr>
			<td>2021-01-01</td>
			<td>100</td>
			<td>
				<p>365일</p>
				<span>(2022~01-01~2023-01-01)</span>
			</td>
			<td>￦60,000,000</td>
			<td>신용/체크카드</td>
			<td>
				<div class="payment-status warning">
					<b>주문취소</b>
				</div>
			</td>
			<td>
				<div class="download-link">
					<a download>{{ tt('영수증') }}</a>
				</div>
			</td>
		</tr> -->
</template>

<script>
import MixinLicense from '@/mixins/license'
import LmBallon from '@/views/common/components/balloon/LmBallon.vue'
import MarginSpan from '@/views/common/components/style/MarginSpan.vue'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	components: { LmBallon, MarginSpan },
	mixins: [MixinLicense],
	computed: {
		isPaymentEbill() {
			return this.item.paymentMethod === this.paymentMethod.ELECTRONIC_BILL
		},
		isPaymentCancel() {
			return this.item.paymentStatus === 'CANCEL'
		},
		isPaymentSuccess() {
			return this.item.paymentStatus === 'SUCCESS'
		},
		isPaymentWait() {
			return this.item.paymentStatus === 'WAIT'
		},
		isPaymentFail() {
			return this.item.paymentStatus === 'FAIL'
		},
		isAdditionItem() {
			return this.item.memo === '추가 구매'
		},
	},
}
</script>

<style lang="scss" scoped>
td {
	height: 50px;
	border-bottom: 1px solid $INPUT;
	font-size: 0.875rem;
	font-weight: 400;
	color: #000;
	padding: 0 10px;
	white-space: nowrap;
	&:first-child {
		padding-left: 20px;
	}
	span {
		font-size: 10px;
		font-weight: 400;
		display: block;
	}
	.payment-status {
		b {
			display: flex;
			align-items: center;
			height: auto;
			button {
				padding: 0;
				&:hover {
					.balloon {
						display: block;
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
		&.warning {
			color: $RED;
		}
	}
	.download-link {
		a {
			font-size: 0.875rem;
			color: $BLUE;
			font-weight: 400;
			display: inline-block;
			text-decoration: underline;
			cursor: pointer;
		}
		span {
			font-size: 0.875rem;
			font-weight: 400;
			display: inline-block;
			margin: 0 3px;
		}
	}
	.underline {
		color: $RED;
		text-decoration: underline;
	}
}
</style>
