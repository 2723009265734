import managementAPI from '@/services/api/safety/management'

const safetyManagement = {
	namespaced: true,
	state: {
		managementList: {},
	},
	getters: {
		getManagement: state => state.managementList,
	},
	mutations: {
		setManagementList: (state, payload) => {
			state.managementList = payload
		},
	},
	actions: {
		lookupManagementList: async ({ commit }, payload) => {
			const { instId, offset, length, order, orderDirection, keyword } = payload
			const response = await managementAPI.lookupManagementList(instId, offset, length, order, orderDirection, keyword)
			commit('setManagementList', response.data)
		},
		exportDiaryExcel: async ({ commit }, payload) => {
			const { instId, body } = payload
			return await managementAPI.exportDiaryExcel(instId, body)
		},
		exportExcel: async ({ commit }, instId) => {
			return await managementAPI.exportExcel(instId)
		},
	},
}

export default safetyManagement
