<template>
	<div>
		<h5>
			<div>
				<div>{{ tt('지출 내역 조회') }}</div>
				<div class="sub">
					<b>{{ tt('상세검색') }}</b
					>{{ tt('지출 내역에서 조회할 항목을 검색하거나 선택할 수 있습니다') }}
				</div>
			</div>
			<div class="button-groups">
				<image-button @click="exportList" :text="'목록 다운로드'" :image="require('@/assets/svg/excel-download.svg')"></image-button>
			</div>
		</h5>
		<expenditure-search ref="expenditure-search" :budgetId="budgetId" :year="year" />
		<expenditure-list :budgetId="budgetId" :year="year" :group="group" />
	</div>
</template>
<script>
import ExpenditureSearch from '../detail/ExpenditureSearch.vue'
import ExpenditureList from '../detail/ExpenditureList.vue'
import { mapActions } from 'vuex'
export default {
	components: {
		ExpenditureSearch,
		ExpenditureList,
	},
	props: {
		budgetId: {
			type: [String, Number],
			default: null,
		},
		year: {
			type: Number,
			default: null,
		},
		group: {
			type: Object,
			default: null,
		},
	},
	methods: {
		...mapActions('budget/expenditure', ['exportExcel', 'setLoadParams', 'getFilter']),
		async exportList(type) {
			const response = await this.exportExcel(type)
			window.fileDownload(response, `${this.$DateTime.now().toFormat('yyyy-MM-dd')}_${this.tt('지출 내역')}.xlsx`)
		},
		async loadData() {
			await this.$nextTick()
			this.$refs['expenditure-search'].resetSearch()
			this.$refs['expenditure-search'].reset()
			await this.setLoadParams({})
			await this.getFilter({ budgetId: this.budgetId, year: this.year })
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
</style>
