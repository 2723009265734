var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('migration-header', {
    attrs: {
      "isMigration": true,
      "text": _vm.headerText
    }
  }), _c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "text-wrapper"
  }, [_c('h5', {
    staticClass: "title",
    domProps: {
      "innerHTML": _vm._s(_vm.titleText)
    }
  }), _c('div', {
    staticClass: "migration-member-info-wrapper"
  }, [_c('div', {
    staticClass: "info-text-wrapper top"
  }, [_c('div', {
    staticClass: "info-header"
  }, [_c('img', {
    staticClass: "info-image",
    attrs: {
      "src": require("@/assets/svg/migration/icon-migration-member-green.svg")
    }
  }), _c('div', {
    staticClass: "text-green"
  }, [_vm._v(_vm._s(_vm.textGreen))])]), _c('div', {
    staticClass: "info-text",
    domProps: {
      "innerHTML": _vm._s(_vm.infoTextGreen)
    }
  })]), _c('div', {
    staticClass: "info-text-wrapper"
  }, [_c('div', {
    staticClass: "info-header"
  }, [_c('img', {
    staticClass: "info-image",
    attrs: {
      "src": require("@/assets/svg/migration/icon-migration-member-red.svg")
    }
  }), _c('div', {
    staticClass: "text-red"
  }, [_vm._v(_vm._s(_vm.textRed))])]), _c('div', {
    staticClass: "info-text",
    domProps: {
      "innerHTML": _vm._s(_vm.infoTextRed)
    }
  })])]), _c('div', {
    staticClass: "subtext",
    domProps: {
      "innerHTML": _vm._s(_vm.subtext)
    }
  })]), _c('migration-member-table', {
    attrs: {
      "members": _vm.migrationMember
    }
  }), _c('migration-button-area', {
    attrs: {
      "nextButtonText": _vm.nextButtonText,
      "nextDisable": _vm.isNextDisabled
    },
    on: {
      "back": _vm.routeMigrationInputInstitutionName,
      "next": _vm.confirm
    }
  })], 1), _c('modal-common-confirm', {
    attrs: {
      "title": _vm.modalCommonTitle,
      "content": _vm.modalCommonContent,
      "isShow": _vm.displayConfirmModal,
      "buttonText": _vm.confirmButtonText
    },
    on: {
      "close": function close($event) {
        _vm.displayConfirmModal = false;
      },
      "confirm": _vm.callMigrationApi
    }
  }), _c('modal-confirm', {
    ref: "modal-no-member",
    attrs: {
      "title": "자동 이전 대상자 없음",
      "content": "랩매니저 PRO 자동 이전 가능한 대상자가 없습니다.<br/><br/>* 문의사항: 고객센터 1533-3061",
      "hasConfirm": false,
      "cancelText": "닫기"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }