<template>
	<b-modal ref="modal" no-close-on-backdrop centered hide-footer @close="hide">
		<template #modal-title>{{ tt('스캔 가능한 브랜드') }} </template>
		<template>
			<p class="description" v-html="'QR코드 혹은 바코드를 스캔하여 물품 정보를 찾을 수 있는 브랜드는<br/> 다음과 같습니다.'"></p>
			<div class="brand-list">
				<div class="brand-item" v-for="item in brandList" :key="item.id">
					<img :src="require('@/assets/images/brand/' + item.image)" width="80" height="40" />
					<div class="brand-name">
						<p class="name">{{ tt(item.name) }}</p>
					</div>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	name: 'ModalAvailableBrand',
	components: {},
	props: {},
	data() {
		return {}
	},
	computed: {
		brandList() {
			// TODO : 추가될 예정이라면 API에서 가져오는게 맞을 듯
			return [
				{ id: 1, name: 'Sigma-aldrich', image: 'SigmaAldrich.png' },
				{
					id: 2,
					name: 'TCI(Tokyo Chemical Industry)',
					image: 'TCI.png',
				},
				{
					id: 3,
					name: 'SAMCHUN(삼전순약공업주식회사)',
					image: 'Shinetsu.png',
				},
				{ id: 4, name: 'Duksan(덕산)', image: 'Duksan.png' },
				{ id: 5, name: 'Acros Organics', image: 'acrosorganics.png' },
				{ id: 6, name: 'Agilent', image: 'Agilent Technologies.png' },
				{ id: 7, name: 'BD', image: 'BD.png' },
				{ id: 8, name: 'BIO-RAD', image: 'BioRad.png' },
				{ id: 9, name: 'Ehrenstorfer', image: 'Ehrenstorfer.png' },
				{
					id: 10,
					name: 'Fluka (Honeywell Fluka)',
					image: 'HoneywellFluka.png',
				},
				{ id: 11, name: 'Gibco', image: 'Gibco.png' },
				{
					id: 12,
					name: 'Hanna Instruments',
					image: 'Hanna_instruments.png',
				},
				{
					id: 13,
					name: 'Honeywell Burdick & jackson',
					image: 'HoneywellBurdick&Jackson.png',
				},
				{ id: 14, name: 'J.T. Baker', image: 'J.TBaker.png' },
				{ id: 15, name: 'Junsei Cheical', image: 'Junsei.png' },
				{ id: 16, name: 'Kanto Chemical', image: 'Kanto.png' },
			]
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-search-product-using-qr.scss';
.brand-list {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 5px;
	border: 1px solid $INPUT;
	max-height: 460px;
	overflow-y: auto;
	.brand-item {
		display: flex;
		border-radius: 5px;
		overflow: hidden;
		height: 40px;
		min-height: 40px;
		border-bottom: 1px solid $DIVIDER;
		.brand-name {
			width: 100%;
			padding: 10px 15px;
			display: flex;
			align-items: center;
			.name {
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
}
</style>
