var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('input', {
    staticClass: "cursor",
    attrs: {
      "type": "text",
      "placeholder": _vm.tt('위치를 선택해주세요'),
      "readonly": ""
    },
    domProps: {
      "value": _vm.item.name
    },
    on: {
      "click": function click($event) {
        return _vm.showModalLocation(_vm.index);
      }
    }
  }), _c('button', {
    staticClass: "Btn-delete-item btn",
    on: {
      "click": function click($event) {
        return _vm.removeLocation(_vm.index);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/recycle-bin.svg")
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }