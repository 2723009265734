import instituteGroupAPI from '@/services/api/institute/group'
import cookies from 'vue-cookies'
import { getListByKeyword, getStorageIds, getStorageCount } from '@/utils/Tree'
const filterCookieName = 'GlobalFilterStorage-Headers'

const state = {
	list: [],
	checkedId: [],
	isAccessible: true,
	storageCount: 0,
	keyword: '',
	tree: [],
}

const getters = {
	isResetDisabled: state => state.storageCount == state.checkedId.length,
	list: state => state.list,
}

const mutations = {
	setList: (state, list) => {
		state.tree = list
		state.storageCount = getStorageCount(list)
	},
	setCheckedId: (state, list) => {
		state.checkedId = list.length > 0 ? list : getStorageIds(state.list)
	},
	setCheckedItem: (state, id) => {
		if (state.checkedId.includes(id)) {
			state.checkedId.remove(id)
		} else {
			state.checkedId.push(id)
		}
	},
	setCheckedIds: state => {
		if (state.storageCount == state.checkedId.length) {
			state.checkedId = []
		}
		localStorage.setItem(filterCookieName, JSON.stringify(state.checkedId))
	},
	setAccessible: (state, value) => {
		state.isAccessible = value
	},
	resetFilter: state => {
		state.accessible = true
		state.checkedId = getStorageIds(state.list)
		localStorage.setItem(filterCookieName, JSON.stringify(state.checkedId))
	},
	setKeyword: (state, keyword) => {
		state.keyword = keyword
		state.list = getListByKeyword(state.tree, state.keyword)
	},
	checkedAllChildren: (state, location) => {
		const checkedAllChildren = l => {
			state.checkedId.push(...l.storages.filter(s => !state.checkedId.includes(s.id)).map(s => s.id))
			l.children.forEach(c => {
				if (c.accessible) checkedAllChildren(c)
			})
		}
		checkedAllChildren(location)
	},
	uncheckedAllChildren: (state, location) => {
		const uncheckedAllChildren = l => {
			l.storages.forEach(s => state.checkedId.remove(s.id))
			l.children.forEach(c => {
				if (c.accessible) uncheckedAllChildren(c)
			})
		}
		uncheckedAllChildren(location)
	},
	setTreeOpen: (state, isOpen) => {
		state.list = getListByKeyword(state.tree, state.keyword, isOpen)
	},
}

const actions = {
	getLocationStorageTree: async ({ state, commit, rootGetters }) => {
		const response = await instituteGroupAPI.lookupGlobalGroup(rootGetters.instituteId, state.isAccessible)
		commit('setList', response.data)
		commit('setKeyword', '')
	},
	getCheckedId: ({ commit }) => {
		const ids = JSON.parse(localStorage.getItem(filterCookieName))
		commit('setCheckedId', ids ? ids : [])
	},
	resetFilter: ({ commit }) => {
		commit('resetFilter')
	},
	setAccessible: async ({ commit, dispatch }, value) => {
		commit('setAccessible', value)
		await dispatch('getLocationStorageTree')
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
