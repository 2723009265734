var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_vm.isAutoMsds ? _c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-header-text"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt('MSDS')))]), _c('div', {
    staticClass: "form-msg success"
  }, [_vm._v(_vm._s(_vm.tt("".concat(_vm.formMessage))))])]), _c('div', {
    staticClass: "form-header-text"
  }, [_c('div', {
    staticClass: "form-header-subtext"
  }, [_vm._v(_vm._s(_vm.tt('아래 MSDS를 자동 제공하고 있습니다.')))])]), _vm.msdsInfo ? _c('div', _vm._l(_vm.validMsdsKeyList, function (msdsKey) {
    return _c('cargo-form-msds-item', {
      key: msdsKey,
      attrs: {
        "msds": _vm.msdsInfo[msdsKey],
        "msdsKey": msdsKey
      }
    });
  }), 1) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "form-header"
  }, [_c('div', {
    staticClass: "form-header-text"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.tt('MSDS 직접 업로드')))]), !_vm.isAutoMsds ? _c('div', {
    staticClass: "form-msg",
    "class": {
      success: this.files && this.files.length > 0
    }
  }, [_vm._v(_vm._s(_vm.tt("".concat(_vm.formMessage))))]) : _vm._e()])]), _c('div', {
    staticClass: "type-file"
  }, [_c('button', {
    staticClass: "button-gray",
    on: {
      "click": _vm.uploadFile
    }
  }, [_vm._v(_vm._s(_vm.tt('파일선택')))]), _vm.showInvalid ? _c('div', {
    staticClass: "error-text"
  }, [_vm._v(_vm._s(_vm.tt('MSDS 업로드는 필수입니다')) + ".")]) : _vm._e(), _c('div', {
    ref: "upload-box",
    staticClass: "upload-box",
    "class": {
      error: _vm.showInvalid
    }
  }, [_c('input', {
    ref: "drop-file",
    staticClass: "dropFile",
    attrs: {
      "type": "file",
      "accept": ".pdf"
    },
    on: {
      "change": _vm.onChange,
      "drop": _vm.dropFile
    }
  }), !_vm.files || _vm.files.length == 0 ? _c('div', {
    staticClass: "file-box"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/icon/icon-msds-file.svg")
    }
  }), _c('p', {
    staticClass: "file-box-text"
  }, [_vm._v(_vm._s(_vm.tt('MSDS 파일을 마우스로 끌어오거나, 직접 선택해 주세요')))]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('button', {
    ref: "select-btn",
    staticClass: "button-gray",
    on: {
      "click": _vm.uploadFile
    }
  }, [_vm._v(_vm._s(_vm.tt('파일선택')))])])]) : _c('ul', {
    staticClass: "file-list"
  }, _vm._l(_vm.files, function (file, index) {
    return _c('li', {
      key: "key".concat(index)
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/icon/icon-docs.svg")
      }
    }), _c('p', [_vm._v(_vm._s(file.name))]), _c('button', {
      staticClass: "close-btn",
      on: {
        "click": _vm.deleteFile
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/svg/Close-1.svg")
      }
    })])]);
  }), 0)])]), _c('modal-confirm', {
    ref: "modal-confirm",
    attrs: {
      "title": _vm.tt('MSDS 직접 업로드'),
      "content": _vm.tt('MSDS는 하나의 파일만 업로드 할 수 있습니다.'),
      "noCancel": false
    },
    on: {
      "confirm": _vm.hideModalConfirm
    }
  }), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }