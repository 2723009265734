<template>
	<div class="card MsdsUpload" :class="[{ warning: list.nonUploadCount > 0 }, { safe: list.nonUploadCount < 1 }, { displayOff: !displayCard }]">
		<div class="card-title">
			<div class="imgbox">
				<img src="@/assets/svg/dashboard-icon4.svg" alt="icon" />
			</div>
			<h4 class="Btn__hover-balloon-w" @click="goPage()">
				{{ tt('MSDS 등록 현황') }}
				<div class="balloon">
					<p>{{ tt('MSDS 페이지로 이동합니다') }}</p>
				</div>
				<button>
					<img src="@/assets/svg/arrow-diagonal.svg" alt="icon" />
				</button>
			</h4>
			<h3
				class="ml-auto"
				v-if="setting == false"
				v-text="list.nonUploadCount > 0 ? list.nonUploadCount + tt('종') + '\n' + tt('업로드 필요') : tt('안전')"
			></h3>
			<div class="ml-auto switch-setting" v-else>
				<h5>{{ tt('대시보드에 노출') }}</h5>
				<label class="switch">
					<input type="checkbox" v-model="displayCard" @change="toggleCard(id, displayCard)" />
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<div class="card-noti">
			<h6>{{ tt('MSDS 관리') }}</h6>
			<p
				v-html="
					tt(
						`화학물질 중에서 <b>브랜드</b>와 <b>제품번호</b>가 같은 경우 <b>동일한 종류</b>로 취급합니다.<br>MSDS는 화학물질 종류 단위로 등록이 필요합니다.`
					)
				"
			></p>
		</div>

		<div class="card-box">
			<div class="box-title">
				<h5>{{ tt('화학물질 MSDS 등록 현황') }}</h5>
			</div>
			<div class="box-content">
				<div class="chart-wrap no-data-chart-wrap" v-if="list.allCount == 0">
					<div class="no-data_chart">
						<div class="balloon">
							<span></span>
							<p>{{ tt('등록된 MSDS') }}</p>
							<b>0{{ tt('종') }} 0%</b>
						</div>
					</div>
					<div class="chart-title">
						<p style="width: 100px; word-break: keep-all" v-html="tt('MSDS를 관리해야 할 물질이 없습니다')"></p>
					</div>
				</div>
				<div class="chart-box" v-else>
					<GChart type="PieChart" :data="chartData" :options="chartOptions" :events="chartEvents" ref="chart" />
					<div class="chart-title">
						<h2 style="color: #0dc577" v-if="list.allCount > 0">
							<span>{{ uploadPercent }}%</span>
						</h2>
						<p v-else style="font-size: 0.75rem; color: #000; text-align: center">
							{{ 'MSDS를 관리해야 할 물질이 없습니다' }}
						</p>
					</div>
				</div>
				<div class="chart-list">
					<ul>
						<li @click="goPage(0)">
							<div class="circle green"></div>
							<p>{{ tt('MSDS 등록된 화학물질') }}</p>
							<h5>{{ uploadedCount }}{{ tt('종') }}</h5>
						</li>
						<li @click="goPage(1)">
							<div class="circle palewgreen"></div>
							<p>{{ tt('MSDS 업로드가 필요한 화학물질') }}</p>
							<h5>{{ list.nonUploadCount }}{{ tt('종') }}</h5>
						</li>
						<li class="total">
							<p>Total:</p>
							<h5>{{ list.allCount }}{{ tt('종') }}</h5>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import PageName from '@/utils/define/PageName'

export default {
	name: 'CardMsdsUpload',
	props: {
		id: {
			type: Number,
			default: 0,
		},
		instId: {
			type: String,
			default: '',
		},
		visible: {
			type: Boolean,
			default: false,
		},
		setting: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Object,
			default: () => {},
		},
		toggleCard: {
			type: Function,
			default: () => {},
		},
	},
	components: {
		GChart,
	},
	mounted() {
		this.displayCard = this.visible
	},
	computed: {
		uploadPercent() {
			return Math.round(((this.list.allCount - this.list.nonUploadCount) / this.list.allCount) * 100)
		},
		uploadedCount() {
			return this.list.allCount - this.list.nonUploadCount
		},
		chartData() {
			return [
				['title', 'count', { type: 'string', role: 'tooltip', p: { html: true } }],
				[
					this.tt('등록된 MSDS'),
					this.list.allCount - this.list.nonUploadCount,
					this.tt('등록된 MSDS') +
						':' +
						'\n' +
						(this.list.allCount - this.list.nonUploadCount) +
						this.tt('종') +
						'(' +
						Math.round(((this.list.allCount - this.list.nonUploadCount) / this.list.allCount) * 100) +
						'%)',
				],
				[
					this.tt('업로드 안 된 MSDS'),
					this.list.nonUploadCount,
					this.tt('업로드 안 된 MSDS') +
						':' +
						'\n' +
						this.list.nonUploadCount +
						this.tt('종') +
						'(' +
						Math.round((this.list.nonUploadCount / this.list.allCount) * 100) +
						'%)',
				],
			]
		},
	},
	data() {
		return {
			chartOptions: {
				legend: 'none',
				colors: ['#0DC577', '#E7F9F1'],
				width: 210,
				height: 210,
				chartArea: {
					width: '90%',
					height: '90%',
				},
				backgroundColor: 'transparent',
				pieSliceBorderColor: 'white',
				pieSliceText: 'none',
				pieHole: 0.7,
				tooltip: {
					isHtml: true,
				},
			},
			displayCard: true,
			chartEvents: {
				select: () => {
					const chart = this.$refs.chart.chartObject
					const row = chart.getSelection()[0].row
					this.goPage(row)
				},
			},
		}
	},
	methods: {
		goPage(select) {
			let status

			if (select >= 0) {
				if (select == 1) {
					status = ['UNSAFE']
				} else {
					status = ['SAFE']
				}
			} else {
				status = []
			}
			this.$router.push({
				name: PageName.Safety.MSDS,
				params: {
					status: status,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/dashboard/_dashboard-cards.scss';

.no-data-chart-wrap {
	// position: absolute;
	height: 200px !important;
	max-width: 200px !important;
	.no-data_chart {
		width: 200px;
		height: 200px;
		border: 30px solid $GREEN;
		border-radius: 270px;
		&:hover {
			.balloon {
				display: inline-flex;
			}
		}
		.balloon {
			display: none;
			position: absolute;
			top: 15px;
			left: 50%;
			transform: translate(-50%, 0);
			background-color: $LAB_BLACK;
			height: 22px;
			align-items: center;
			padding: 0 8px;
			border-radius: 5px;
			&:after {
				border-bottom: 0px solid transparent;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid $LAB_BLACK;
				content: '';
				position: absolute;
				bottom: -4px;
				left: 50%;
				transform: translate(-50%, 0);
			}
			span {
				width: 12px;
				height: 12px;
				border-radius: 5px;
				background-color: $GREEN;
				display: inline-block;
				margin-right: 5px;
			}
			p {
				color: $LAB_WHITE;
				font-size: 0.75rem;
				font-weight: 400;
				white-space: nowrap;
			}
			b {
				color: $LAB_WHITE;
				font-size: 0.75rem;
				font-weight: 700;
				display: inline-block;
				margin-left: 5px;
				white-space: nowrap;
			}
		}
	}
}

.box-content {
	display: flex;
	align-items: center;
	margin-top: 20px;
	.chart-box {
		position: relative;
		cursor: pointer;
		.chart-title {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			p {
				white-space: nowrap;
			}
		}
	}
	.chart-list {
		width: calc(100% - 278px);
		margin-left: 48px;
		margin-right: 20px;
		ul {
			li {
				width: 100% !important;
				margin: 0 0 !important;
				&.total {
					border-bottom: 0 !important;
					p {
						font-weight: 700 !important;
						max-width: 80%;
					}
				}
			}
		}
	}
}
</style>
