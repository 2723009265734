import deadlineAPI from '@/services/api/purchase/deadline'
import _ from 'lodash'

const state = {
	item: {},
}

const getters = {}

const mutations = {
	setItem: (state, data) => {
		state.item = data
	},
}

const actions = {
	getDetail: async ({ commit, rootGetters }, id) => {
		const response = await deadlineAPI.detail(rootGetters.instituteId, id)
		commit('setItem', response.data)
	},
	settle: async ({ dispatch, rootGetters }, body) => {
		const { ids } = body
		await deadlineAPI.settle(rootGetters.instituteId, body)
		dispatch('getDetail', ids[0])
	},
	unSettle: async ({ dispatch, rootGetters }, body) => {
		const { ids } = body
		await deadlineAPI.unSettle(rootGetters.instituteId, body)
		dispatch('getDetail', ids[0])
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
