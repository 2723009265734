var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', {
    staticClass: "badge-list-wrap"
  }, [_c('item-badge', {
    attrs: {
      "image": _vm.categoryImage,
      "text": _vm.categoryName,
      "subtext": "카테고리"
    }
  }), _c('item-badge', {
    attrs: {
      "image": require("@/views/purchase/cargo/svg/badge-volume".concat(_vm.isVolumeValid ? '' : '-red', ".svg")),
      "text": _vm.formatVolumeBadge,
      "subtext": "잔여 용량",
      "isValid": _vm.isVolumeValid
    }
  }), _vm.item.managementFields.expireDatetime ? _c('item-badge', {
    attrs: {
      "image": require("@/views/purchase/cargo/svg/badge-expiredate".concat(_vm.isNotExpired ? '' : '-red', ".svg")),
      "text": _vm.isNotExpired ? _vm.formatDateFromISO(_vm.item.managementFields.expireDatetime, '~yyyy-MM-dd') : _vm.tt('만료됨'),
      "subtext": "유효기간",
      "isValid": _vm.isNotExpired
    }
  }) : _vm._e(), _vm.legals && _vm.legals['LEGAL-WG'].list.length > 0 ? _c('item-badge', {
    attrs: {
      "text": _vm.tt('위험물관리법'),
      "image": require('@/views/purchase/cargo/svg/badge-legal-danger.svg'),
      "subtext": "관련법령"
    }
  }) : _vm._e(), _vm.legals && _vm.legals['LEGAL-PG'].list.length > 0 ? _c('item-badge', {
    attrs: {
      "text": _vm.tt('폐기물관리법'),
      "image": require('@/views/purchase/cargo/svg/badge-legal-waste.svg'),
      "subtext": "관련법령"
    }
  }) : _vm._e(), _vm.legals && _vm.legals['LEGAL-SAB'].list.length > 0 ? _c('item-badge', {
    attrs: {
      "text": _vm.tt('산업안전보건법'),
      "image": require('@/views/purchase/cargo/svg/badge-legal-safety.svg'),
      "subtext": "관련법령"
    }
  }) : _vm._e(), _vm.legals && _vm.legals['LEGAL-HMG'].list.length > 0 ? _c('item-badge', {
    attrs: {
      "text": _vm.tt('화학물질관리법'),
      "image": require('@/views/purchase/cargo/svg/badge-legal-chemical.svg'),
      "subtext": "관련법령"
    }
  }) : _vm._e(), _vm.item.categoryType.includes('GAS') && _vm.legals && _vm.legals['LEGAL-GG'].list.length > 0 ? _c('item-badge', {
    attrs: {
      "image": require('@/views/purchase/cargo/svg/badge-legal-gas.svg'),
      "subtext": "관련법령"
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }