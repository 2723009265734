var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "hide-header": "",
      "hide-footer": "",
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "no-fade": ""
    }
  }, [_c('div', {
    staticClass: "body"
  }, [_c('lottie-animation', {
    ref: "lottie-animation",
    attrs: {
      "animationData": require('@/assets/json/lm_loading_lottie.json'),
      "loop": ""
    }
  }), _c('h5', [_vm._v(_vm._s(_vm.tt(_vm.title)))]), !_vm.noMsg ? _c('p', [_vm._v(_vm._s(_vm.tt('잠시만 기다려주시기 바랍니다.')))]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }