<template>
	<div class="property-wrapper">
		<img class="property-icon-img" v-if="img" :src="img" />
		<div class="property-content">
			<div class="property-title">
				{{ tt(title) }}
			</div>
			<div class="property-subtext">
				{{ tt(subtext) }}
			</div>
		</div>
		<div class="btn-wrapper">
			<button class="show-button" @click="$emit('click')">{{ tt('보러가기') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Property',
	props: {
		img: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			default: '',
		},
		subtext: {
			type: String,
			default: '',
		},
	},
	components: {},
	computed: {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.property-wrapper {
	display: flex;
	border-radius: 8px;
	padding: 18px;
	background: $GREY_0;
	align-items: center;
	border: 1px solid $DIVIDER;
	width: 415px;
	height: 70px;
	.property-icon-img {
		width: 36px;
		height: 36px;
		border-radius: 5px;
		border: 0.5px solid $COLOR_CCC;
		margin-top: 2px;
	}
	.property-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 10px;
		.property-title {
			font-size: 14px;
			font-weight: 700;
		}
		.property-subtext {
			font-size: 12px;
			font-weight: 400;
			color: $BLACK_60;
		}
	}
	.btn-wrapper {
		display: flex;
		align-items: center;
		.show-button {
			height: 30px;
			font-size: 14px;
			font-weight: 700;
			color: $COLOR_000000DE;
			background: $GREY_4;
			border-radius: 15px;
			padding: 5px 10px;
		}
	}
}
</style>
