import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/User'
import toast from '@/store/modules/Toast'
import modals from '@/store/modules/Modals'
import institutionInformation from '@/store/modules/institute/information'
import groupAuthority from '@/store/modules/authority/GroupAuthority'
import locationAuthority from '@/store/modules/authority/LocationAuthority'
import storageAuthority from '@/store/modules/authority/StorageAuthority'
import createPersistedState from 'vuex-persistedstate'
import organization from '@/store/modules/institute/organization'
import breadcrumb from '@/store/modules/BreadCrumb'
import location from '@/store/modules/institute/location'
import storage from '@/store/modules/institute/storage'
import member from '@/store/modules/institute/member'
import permission from '@/store/modules/institute/permission'
import inventory from '@/store/modules/inventory'
import dashboard from '@/store/modules/dashboard'
import setting from '@/store/modules/inventory/setting'
import inspect from '@/store/modules/inventory/inspect'
import msds from '@/store/modules/safety/msds'
import management from '@/store/modules/safety/management'
import chemical from '@/store/modules/safety/chemical'
import diagnosis from '@/store/modules/safety/diagnosis'
import appSideMenu from '@/store/modules/AppSideMenu'
import editInventory from '@/store/modules/inventory/editInventory'
import license from '@/store/modules/institute/license'
import paymentCard from '@/store/modules/payment/card'
import invoice from '@/store/modules/payment/invoice'
import appEOL from '@/store/modules/AppEOL'
import userPermission from '@/store/modules/permission'
import migration from '@/store/modules/migration'
import pagination from '@/store/modules/pagination'
import cargo from '@/store/modules/purchase/cargo'
import draft from '@/store/modules/purchase/draft'
import approval from '@/store/modules/purchase/approval'
import deadline from '@/store/modules/purchase/deadline'
import purchaseProduct from '@/store/modules/purchase/product'
import budget from './modules/budget'
import form from '@/store/modules/form'
import ingredient from '@/store/modules/ingredient/index'
import tree from '@/store/modules/tree'
import qrcode from '@/store/modules/form/qrcode'
import tag from '@/store/modules/tag'
import searchProduct from '@/store/modules/searchProduct'
import modal from '@/store/modules/modal'
import lms from '@/store/modules/purchase/lms'
import logger from '@/store/modules/logger' //DATA: 로그 관련 - 장은학
import cookies from 'vue-cookies'
import institute from '@/store/modules/institute'
import inventoryGen2 from '@/store/modules/inventoryGen2'
import mainPopup from './modules/mainPopup'
import scroll from '@/store/modules/scroll'
import filter from '@/store/modules/modal/filter'
import dangerStorage from '@/store/modules/safety/dangerStorage'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)
const store = new Vuex.Store({
	namespaced: true,
	modules: {
		logger, //DATA: 로그 관련 - 장은학
		user,
		toast,
		modals,
		breadcrumb,
		institutionInformation,
		groupAuthority,
		locationAuthority,
		storageAuthority,
		organization,
		location,
		storage,
		member,
		permission,
		inventory,
		dashboard,
		setting,
		inspect,
		msds,
		management,
		chemical,
		diagnosis,
		appSideMenu,
		editInventory,
		license,
		paymentCard,
		appEOL,
		userPermission,
		migration,
		pagination,
		cargo,
		draft,
		approval,
		deadline,
		budget,
		form,
		ingredient,
		tree,
		institute,
		qrcode,
		tag,
		searchProduct,
		modal,
		lms,
		inventoryGen2,
		mainPopup,
		purchaseProduct,
		scroll,
		filter,
		invoice,
		dangerStorage,
	},
	strict: debug,
	getters: {
		instituteId: state => {
			let id = cookies.get('instituteId')
			return state.user?.institute?.id ? state.user?.institute?.id : id
		},
	},
	plugins: [
		createPersistedState({
			paths: ['appSideMenu', 'draft/item'],
		}),
	],
})

export default store
