var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item.assignee ? _c('div', {
    staticClass: "info-wrap"
  }, [_c('p', {
    staticClass: "info-header"
  }, [_vm._v(_vm._s(_vm.tt('연락처')))]), _c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "member-wrap"
  }, [_c('div', {
    staticClass: "member-info-wrap"
  }, [_vm.item.assignee.image ? _c('img', {
    staticClass: "member-img",
    attrs: {
      "src": _vm.item.assignee.image
    }
  }) : _c('img', {
    staticClass: "member-img",
    attrs: {
      "src": require("@/assets/svg/noMemberImg.svg")
    }
  }), _c('div', {
    staticClass: "member-info"
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.item.assignee.name))]), _c('p', {
    staticClass: "phone"
  }, [_vm._v(_vm._s(_vm.item.assignee.phone))])])]), _c('div', {
    staticClass: "member-spot"
  }, [_vm._v(" " + _vm._s(_vm.tt('담당자')) + " ")])]), _vm.item.holder ? _c('div', {
    staticClass: "member-wrap"
  }, [_c('div', {
    staticClass: "member-info-wrap"
  }, [_vm.item.holder.image ? _c('img', {
    staticClass: "member-img",
    attrs: {
      "src": _vm.item.holder.image
    }
  }) : _c('img', {
    staticClass: "member-img",
    attrs: {
      "src": require("@/assets/svg/noMemberImg.svg")
    }
  }), _c('div', {
    staticClass: "member-info"
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.item.holder.name))]), _c('p', {
    staticClass: "phone"
  }, [_vm._v(_vm._s(_vm.item.holder.phone))])])]), _c('div', {
    staticClass: "member-spot"
  }, [_vm._v(" " + _vm._s(_vm.tt('사용자')) + " ")])]) : _vm._e()])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }