<template>
	<div class="lm-sb-wrapper" ref="search-box" v-click-outside="focusOut">
		<div class="lm-sb">
			<input
				ref="input"
				class="transparent"
				:value="text"
				v-debounce:300="onDataChanged"
				@focus="isFocus = true"
				:placeholder="tt(placeholder)"
			/>
			<template v-if="text.length > 0">
				<div class="search-count-wrapper" :class="{ active: count > 0 }">{{ `${selected} / ${count}` }}</div>
				<img class="qr" src="./assets/ic-delete.svg" @click="deleteValue" />
			</template>
			<div class="btn-cursor-wrapper">
				<img @click="prev" :class="{ active: selected > 1 }" src="./assets/ic-arrow-up.svg" />
				<img @click="next" :class="{ active: selected < count }" src="./assets/ic-arrow-down.svg" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
	props: {
		placeholder: {
			type: String,
			default: '검색',
		},
	},
	data() {
		return {
			isFocus: false,
		}
	},
	computed: {
		...mapGetters('filter', ['text', 'selected', 'count']),
	},
	methods: {
		...mapMutations('filter', ['setText', 'setSelected', 'setCount']),
		onDataChanged(value) {
			this.setText(value)
		},
		focus() {
			this.$refs.input.focus()
		},
		deleteValue() {
			this.setText('')
			this.setSelected(0)
			this.setCount(0)
		},
		focusOut() {
			this.isFocus = false
		},
		prev() {
			if (this.selected > 1) {
				this.setSelected(this.selected - 1)
			}
		},
		next() {
			if (this.selected < this.count) {
				this.setSelected(this.selected + 1)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.lm-sb-wrapper {
	position: relative;
	.lm-sb {
		position: relative;
		width: 300px;
		height: 36px;
		background-color: white;
		border-radius: 8px;
		display: flex;
		align-items: center;
		// padding-right: 5px;
		padding: 5px;
		border: 1px solid #e4e5ea;
		&.readonly {
			border: none;
		}
		input {
			flex: 1;
			background-color: transparent;
			width: 100%;
			height: 100%;
			padding-left: 5px;
			font-size: 14px;
			font-weight: 400;
			color: $DEFAULT;
			border: none;
		}
		.qr {
			padding: 5px;
			cursor: pointer;
		}
		.search-count-wrapper {
			min-width: 40px;
			font-size: 14px;
			color: #666;
			font-weight: 400;
			text-align: right;
			margin-right: 5px;
			&.active {
				color: #111;
			}
		}
		.btn-cursor-wrapper {
			padding-left: 5px;
			margin-left: 5px;
			height: 24px;
			border-left: 1px solid #eee;
			display: flex;
			img {
				cursor: pointer;
				height: 100%;
				opacity: 0.3;
				&:hover {
					border-radius: 5px;
					background-color: #eee;
				}
				&.active {
					opacity: 1;
				}
			}
		}
	}
}
</style>
