import productAPI from '@/services/api/purchase/product'
import _ from 'lodash'

const state = {
	item: null,
}

const getters = {}

const mutations = {
	setItem: (state, data) => {
		state.item = data
	},
}

const actions = {
	getDetail: async ({ commit, rootGetters }, id) => {
		const response = await productAPI.detail(rootGetters.instituteId, id)
		commit('setItem', response.data)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
