import axiosInstance from '@/services/axios'
import ParamMaker from '@/utils/ParamMaker'

const endPoint = {
	lookupInspectList: (instId, offset, length, orders, ordersDirection, status, categoriesId, registersId, startDatetime, endDatetime) => {
		let parameter = `/institute/${instId}/inspect/list?offset=${offset}&length=${length}`
		if (orders.length != 0) parameter += `&${ParamMaker.setArrayString('orders', orders)}`
		if (ordersDirection.length != 0) parameter += `&${ParamMaker.setArrayString('ordersDirection', ordersDirection)}`
		if (categoriesId.length != 0) parameter += `&${ParamMaker.setArrayString('categoriesId', categoriesId)}`
		if (status.length != 0) parameter += `&${ParamMaker.setArrayString('status', status)}`
		if (registersId.length != 0) parameter += `&${ParamMaker.setArrayString('registersId', registersId)}`
		if ((startDatetime != undefined) & (endDatetime != undefined)) parameter += `&startDatetime=${startDatetime}&endDatetime=${endDatetime}`
		return parameter
	},
	lookupInspect: (instId, id) => `/institute/${instId}/inspect?id=${id}`,
	lookupRegisters: instId => `/institute/${instId}/inspect/list/filter`,
	deleteInspect: (instId, ids) => `institute/${instId}/inspect?${ParamMaker.setArrayString('ids', ids)}`,
	editInspect: instId => `/institute/${instId}/inspect`,
}

const inspectAPI = {
	lookupInspectList: (instId, offset, length, orders, ordersDirection, status, categoriesId, registersId, startDatetime, endDatetime) =>
		axiosInstance.get(
			endPoint.lookupInspectList(instId, offset, length, orders, ordersDirection, status, categoriesId, registersId, startDatetime, endDatetime)
		),
	lookupInspect: (instId, id) => axiosInstance.get(endPoint.lookupInspect(instId, id)),
	lookupRegisters: instId => axiosInstance.get(endPoint.lookupRegisters(instId)),
	deleteInspect: (instId, ids) => axiosInstance.delete(endPoint.deleteInspect(instId, ids)),
	editInspect: (instId, formData) =>
		axiosInstance.put(endPoint.editInspect(instId), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
}

export default inspectAPI
