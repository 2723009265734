<template>
	<div class="detail-page-wrapper" id="approval-detail" v-if="item && item.id == $route.params.id">
		<!-- header -->
		<approval-detail-header
			:title="item.subject"
			:subtitle="item.no"
			:status="item.approvalStatus"
			:permission="item.draftDetailPermission"
			:orderId="item.orderId"
			@click="showConfirmModal"
			@print="showPrintPopup"
		></approval-detail-header>
		<div class="detail-content-wrapper">
			<div>
				<store-order-info v-if="item.purchaseType == 'INTERNAL'" ref="store-order-info" type="DRAFT" />
			</div>
			<div>
				<h5>{{ tt('결재 정보') }}</h5>
				<div class="approval-info-wrapper">
					<approval-info-item
						v-if="item.register"
						:approvalTitle="'기안'"
						:name="item.register.name"
						:affiliation="item.group.name"
						:dateTime="item.createDatetime ? formatDatetime(item.createDatetime) : '-'"
					></approval-info-item>
					<div>
						<approval-info-item
							v-for="(approval, index) in item.approvals"
							:key="`approval_${index}`"
							:approvalTitle="'결재'"
							:approvalState="approval.status"
							:name="approval.user.name"
							:affiliation="approval.group.name"
							:dateTime="approval.concludeDatetime ? formatDatetime(approval.concludeDatetime) : '-'"
						></approval-info-item>
					</div>
				</div>
			</div>
			<div>
				<h5>{{ tt('기안 정보') }}</h5>
				<info-table :items="draftInfoForTable"></info-table>
			</div>
			<div class="print-section">
				<h5>{{ tt('상품 정보') }}</h5>
				<product-info-table :productInfoList="item.purchaseItems"></product-info-table>
			</div>
			<div v-if="item.budgetStatus">
				<total-amount :title="'예산 현황'" :info="amountForm" :budget="item.budgetStatus"></total-amount>
			</div>
			<div>
				<div class="file-wrap">
					<h5>{{ tt('첨부 파일') }}</h5>
					<image-button
						v-if="attachmentsForTable[0].files && attachmentsForTable[0].files.length > 0"
						:text="'모두 다운로드'"
						:image="require('@/assets/svg/icon-file-download.svg')"
						@click="$refs['files-table'].downloadAll()"
					></image-button>
				</div>
				<info-table ref="files-table" :items="attachmentsForTable"></info-table>
			</div>
		</div>
		<!-- Modal -->
		<modal-confirm
			ref="modal-cancel"
			title="결재 취소"
			content="지출결의서 결재를 취소하시겠습니까?<br>결재를 취소 후, 다시 승인하거나 반려하실 수 있습니다."
			confirmText="결재 취소"
			variant="warning"
		/>
		<modal-confirm ref="modal-accept" title="지출 승인" :content="acceptContent" confirmText="승인" variant="accept"> </modal-confirm>
		<modal-confirm ref="modal-reject" title="지출 반려" content="지출결의서를 반려하시겠습니까?" confirmText="반려" variant="warning" />
		<modal-confirm
			ref="modal-order-cancel"
			title="주문 취소"
			content="해당 결재에 연결된 스토어 주문이 있습니다.<br>주문 상세보기 버튼을 눌러 주문을 취소해주세요."
			confirmText="주문 상세보기"
			variant="warning"
		/>
		<modal-remain-later-pay ref="modal-remain-later-pay" />
		<modal-loading ref="modal-loading" />
	</div>
</template>

<script>
const STATUS_WORD = {
	REJECT: '반려',
	ACCEPT: '승인',
	CANCEL: '취소',
}

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MixinExpenditureDetail from '@/mixins/purchase/expenditureDetail'
import ApprovalDetailHeader from '../components/detail/header.vue'
import ApprovalInfoItem from '@/views/purchase/draft/components/detail/ApprovalInfoItem.vue'
import InfoTable from '@/views/purchase/draft/components/detail/InfoTable.vue'
import ProductInfoTable from '@/views/purchase/draft/components/detail/ProductInfoTable.vue'
import TotalAmount from '@/views/purchase/draft/components/detail/TotalAmount.vue'
import ModalConfirm from '@/views/common/components/modalGen2/ModalConfirm.vue'
import ModalLoading from '@/views/common/components/modalGen2/ModalLoading.vue'
import StoreOrderInfo from '@/views/purchase/draft/components/regist/StoreOrderInfo.vue'
import ModalRemainLaterPay from '@/views/purchase/product/components/modal/ModalRemainLaterPay.vue'
export default {
	name: 'ApprovalDetail',
	mixins: [MixinExpenditureDetail],
	components: {
		ApprovalDetailHeader,
		ApprovalInfoItem,
		InfoTable,
		ProductInfoTable,
		TotalAmount,
		ModalConfirm,
		ModalLoading,
		StoreOrderInfo,
		ModalRemainLaterPay,
	},
	computed: {
		...mapState('approval/item', ['item']),
		isLastApproval() {
			const lastApproval = this.item.approvals[this.item.approvals.length - 1]
			return lastApproval.status == 'NOW' && lastApproval.user.id == this.$root.user.id
		},
		acceptContent() {
			if (this.item.purchaseType == 'INTERNAL') return '랩매니저 스토어 주문이 자동 접수되며,<br>판매자 주문승인 후 후불결제가 가능합니다.'
			else return '지출결의서를 승인하시겠습니까?'
		},
	},
	methods: {
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		...mapActions('approval/item', ['getDetail', 'draftConclude', 'draftConcludeCancel']),
		showRemainPay() {
			this.$refs['modal-remain-later-pay'].show()
		},
		async loadDetail() {
			await this.getDetail(this.$route.params.id)
			if (this.item?.purchaseType == 'INTERNAL') this.showRemainPay()
			this.setStorageDetailPaths([this.item.subject])
		},
		showConfirmModal(params) {
			if (this.$root.userPermission.draft?.update) {
				if (params == 'CANCEL') {
					this.$refs['modal-cancel'].show(() => {
						this.cancelDraft()
					})
				} else if (params == 'REJECT') {
					this.$refs['modal-reject'].show(() => {
						this.rejectDraft()
					})
				} else if (params == 'ACCEPT') {
					this.$refs['modal-accept'].show(() => {
						this.acceptDraft()
					})
				} else if (params == 'STORE_ORDER') {
					this.gotoStoreOrderDetail()
				} else if (params == 'STORE_PAYMENT') {
					this.gotoStorePayment()
				}
			} else this.$root.toast('권한', `${title} 권한이 없습니다.`, 'error')
		},

		async cancelDraft() {
			try {
				await this.draftConcludeCancel({
					id: this.$route.params.id,
				})
				let word = this.item.approvalStatus === 'ACCEPT' ? '을' : '를'
				this.$root.toast(
					this.tt(`${STATUS_WORD[this.item.approvalStatus]} 취소 완료`),
					this.tt(`지출결의 ${STATUS_WORD[this.item.approvalStatus]}${word} 취소했습니다.`),
					'success'
				)
			} catch (error) {
				console.warn(error)
			}
		},

		async acceptDraft() {
			try {
				this.$refs['modal-loading'].show()
				const response = await this.draftConclude({
					id: this.$route.params.id,
					conclude: 'ACCEPT',
					storeOrder: true,
				})
				if (response.status == 201 || response.status == 205) {
					this.$root.toast(this.tt('지출 승인'), this.tt('지출결의를 승인했습니다.'), 'success')
				}
			} catch (error) {
				this.$root.toast('주문 실패', error?.response?.data?.msg ?? '오류가 발생했습니다. 잠시 후 다시 시도해주세요', 'error')
			}
			this.$refs['modal-loading'].hide()
		},
		async rejectDraft() {
			try {
				await this.draftConclude({
					id: this.$route.params.id,
					conclude: 'REJECT',
				})
				this.$root.toast(this.tt('지출 반려'), this.tt('지출결의를 반려했습니다.'), 'success')
				if (this.item.orderId) {
					this.$refs['modal-order-cancel'].show(() => {
						this.gotoStoreOrderDetail()
					})
				}
			} catch (error) {
				console.warn(error)
			}
		},
		gotoStoreOrderDetail() {
			this.$root.goToStore(`mypage/order/${this.item.orderId}`)
		},
		gotoStorePayment() {
			this.$root.goToStore(`mypage/unpayment`)
		},
		showPrintPopup() {
			const printTitle = `${this.item.subject}_기안_상세페이지`

			const styles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'))
				.map(style => style.outerHTML)
				.join('')

			const printContents = document.getElementById('approval-detail').outerHTML
			const popupWin = window.open('', '_blank', 'width=1000,height=1000')
			popupWin.document.open()
			popupWin.document.write(`
			    <html>
                <head>
                    <title>${printTitle}</title>
                    ${styles}
                    <style>
                    #approval-detail {
                        width: 100%;
                        height: 100%;
                    }
                    .print-hide {
                        display: none !important;
                    }
                    @media print {
                        body {
                            overflow: visible;
                        }
                        #approval-detail {
                            height: auto !important;
                        }
                        .print-section {
                            page-break-inside: avoid;
                        }
                    }
                    </style>
                </head>
                <body onload="window.print(); window.close();">
                    ${printContents}
                </body>
                </html>
			`)
			popupWin.document.close()
			popupWin.onload = function () {
				popupWin.focus()
				popupWin.print()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/purchase/expenditure/detail/_expenditure-detail.scss';
.check-accept {
	font-size: 14px;
	input {
		margin-right: 10px;
	}
}
</style>
