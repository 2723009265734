<template>
	<div class="form-input" :class="{ error: error }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div
			v-if="isCasno && !isCasValid && inputValue?.length > 0"
			v-b-tooltip.hover="'CAS 공식 홈페이지에서 제공하는 CAS No 검증 로직에 따라 CAS No 형식에 맞는 값을 입력했는지 검증한 결과입니다.'"
			class="casno-valid"
			:class="{ valid: isCasValid }"
		>
			*CAS No를 다시 한번 확인해주세요
		</div>
		<div class="input-wrapper">
			<input type="text" :placeholder="tt(placeholder)" v-model="inputValue" @input="handleChange" :maxlength="max" />
		</div>
		<span class="error" v-if="error">{{ tt(errorMessage) }}</span>
	</div>
</template>

<script>
import Validation from '@/utils/Validation'
export default {
	name: 'FormTextSingleLine',
	props: {
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
		isCasno: {
			type: Boolean,
			default: false,
		},
		maxLength: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			inputValue: '',
			isShowRequire: false,
			isCasValid: true,
		}
	},
	computed: {
		error() {
			return this.required && this.isShowError && !this.inputValue
		},
		max() {
			if (this.isCasno) return 12
			else return this.maxLength
		},
	},
	watch: {
		value() {
			if (this.inputValue !== this.value) this.inputValue = this.value
		},
	},
	mounted() {
		if (this.inputValue !== this.value) this.inputValue = this.value
	},
	methods: {
		handleChange(e) {
			this.inputValue = e.target.value.trimLeft()
			if (this.isCasno) {
				this.inputValue = Validation.autoCasno(this.inputValue)
				this.isCasValid = Validation.validCasno(this.inputValue)
			}
			this.$emit('input', this.inputValue)
		},
		showRequire() {
			this.isShowRequire = this.required && !this.inputValue
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-input.scss';
.casno-valid {
	position: absolute;
	right: 0px;
	top: 0px;
	font-size: 12px;
	color: $BLUE;
	// &::before {
	// 	vertical-align: middle;
	// 	display: inline-block;
	// 	content: '';
	// 	width: 3px;
	// 	height: 3px;
	// 	border-radius: 50%;
	// 	background-color: 0B6CFF;
	// }
	// &.valid {
	// 	color: $GREEN;
	// 	&::before {
	// 		background-color: $GREEN;
	// 	}
	// }
}
</style>
