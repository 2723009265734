<template>
	<div class="form-input" :class="{ error: !isValid }">
		<p class="name" v-if="name">{{ tt(name) }}<span v-if="required" class="required">*</span></p>
		<div class="taglist">
			<div class="tagitem add" @click="showModalAddTag">
				<img width="24" height="24" src="@/assets/svg/icon/black-round-plus.svg" />
				{{ tt('태그 추가') }}
			</div>
			<div class="tagitem" v-for="tag in tagList" :key="tag.id" :class="{ active: isActive(tag.id) }" @click="handleClick(tag.id)">
				{{ `#${tag.name}` }}
			</div>
		</div>
		<div class="btn-edit" @click="$refs['ModalEdtiFormTagList'].show()">편집</div>
		<modal-add-tag ref="modal-add-tag" @activeTag="activeTag"></modal-add-tag>
		<ModalEdtiFormTagList ref="ModalEdtiFormTagList" />
	</div>
</template>

<script>
import ModalAddTag from '@/views/common/components/modalGen2/ModalAddTag.vue'
import ModalEdtiFormTagList from '@/views/common/components/modalGen2/ModalEditTagList.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'FormTagList',
	components: { ModalAddTag, ModalEdtiFormTagList },
	props: {
		name: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters('tag', ['tagList', 'selectedTag']),
		isActive() {
			return id => this.selectedTag && this.selectedTag.includes(id)
		},
	},
	watch: {
		selectedTag: {
			deep: true,
			handler(value) {
				this.$emit('input', value)
			},
		},
	},
	mounted() {
		// this.setSelectedTag([])
	},
	methods: {
		...mapMutations('tag', ['setSelectedTag', 'toggleTag']),
		handleClick(id) {
			this.toggleTag(id)
		},
		showModalAddTag() {
			this.$refs['modal-add-tag'].show()
		},
		activeTag(val) {
			const id = this.tagList.find(tag => tag.name === val).id
			this.toggleTag(id)
			this.$root.toast(this.tt('태그 중복'), this.tt('이미 추가한 태그입니다'), 'warning')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/form/_form-tag-list.scss';
</style>
