<template>
	<div class="input-wrap" :class="[sizeType]">
		<input
			class="input"
			:class="[error || isShowError ? 'error' : '']"
			type="number"
			:value="count"
			@input="handleInput"
			@change="handleChange"
		/>
		<div class="button-wrap">
			<button class="minus" :class="{ disable: isMin }" @click="subtract">-</button>
			<button class="plus" :class="{ disable: isMax }" @click="add">+</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InputButtonCounter',
	components: {},
	props: {
		value: {
			type: Number,
			default: 0,
		},
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: Number.MAX_SAFE_INTEGER,
		},
		sizeType: {
			type: String,
			default: '',
		},
		isShowError: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			count: 0,
			error: false,
		}
	},
	computed: {
		isMin() {
			return this.count === this.min
		},
		isMax() {
			return this.count === this.max
		},
	},
	watch: {
		value(value) {
			if (this.count !== value) this.count = value
			if (value >= this.min) this.error = false
		},
	},
	created() {},
	mounted() {
		this.count = this.value
	},
	methods: {
		add() {
			if (this.count < this.max) {
				this.count += 1
				this.handleChange()
			}
		},
		subtract() {
			if (this.count > this.min) {
				this.count -= 1
				this.handleChange()
			}
		},
		handleInput(e) {
			this.count = Number(e.target.value)
			this.$emit('input', this.count)
		},
		handleChange() {
			this.$emit('input', this.count)
		},
		showError() {
			this.error = this.value < this.min
		},
	},
}
</script>

<style lang="scss" scoped>
.input-wrap {
	display: flex;
	min-width: 85px;
	&.sm {
		height: 30px;
	}
	.input {
		border: 0;
		width: 100%;
		height: 100%;
		font-size: 14px;
		border-radius: 5px;
		border: 1px solid $COLOR_CCC;
		padding: 8px 60px 8px 8px;
		font-weight: 400;
		&.error {
			border: 1px solid #ff3b31 !important;
		}
	}
	.button-wrap {
		display: flex;
		position: relative;
		right: 50px;
		width: 0px;
		align-items: center;
		gap: 5px;
		.plus,
		.minus {
			display: flex;
			width: 20px;
			height: 20px;
			justify-content: center;
			align-items: center;
			background-color: $GREY_4;
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, $GREY_4, $GREY_4);
			}
			&.disable {
				color: $COLOR_CCC;
				background: linear-gradient(0deg, $GREY_4, $GREY_4), linear-gradient(0deg, rgba(242, 243, 244, 0.2), rgba(242, 243, 244, 0.2)),
					linear-gradient(0deg, $LAB_WHITE, $LAB_WHITE);
				cursor: not-allowed;
			}
		}
		.minus {
			font-size: 25px;
			font-weight: 300;
		}
	}
}
</style>
