<template>
	<div class="info-field-wrap">
		<p class="field-name">{{ tt(fieldName) }}</p>
		<div v-if="isRequire && isData == null" class="field-data-wrap-red">{{ tt('미입력') }}</div>
		<div v-else-if="isData" class="field-tag-wrap">
			<div class="tag" v-for="t in tag" :key="t.id">{{ `#${t.name}` }}</div>
		</div>
		<div v-else class="field-tag-wrap">
			<div class="field-text">-</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'TagField',
	components: {},
	props: {
		fieldName: {
			type: String,
			default: '',
		},
		isRequire: {
			type: Boolean,
			default: false,
		},
		tag: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		isData() {
			return this.tag?.length > 0
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.info-field-wrap {
	padding-right: 10px;
	.field-name {
		text-align: left;
		font-size: 12px;
		font-weight: 400;
		color: $SECONDARY_2;
		margin-bottom: 6px !important;
	}
	.field-tag-wrap {
		display: flex;
		width: 300px;
		position: relative;
		flex-wrap: wrap;
		gap: 5px;
		.tag {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 3px 6px;
			border-radius: 5px;
			background-color: black;
			color: white;
			font-size: 10px;
			font-weight: 400;
			min-height: 20px;
			text-align: left;
		}
		.field-text {
			font-size: 14px;
			font-weight: 400;
			text-align: left;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.field-data-wrap-red {
		width: 280px;
		height: 20px;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
		color: $RED;
	}
}
</style>
