<template>
	<form-product
		title="물품 정보"
		v-if="category"
		:productInfo="cargoInfo"
		:categoryInfo="categoryInfo"
		:isValid="isProductValid"
		:isUnitValid="isUnitValid"
		:hideFieldKey="hideFieldKey"
		ref="form-product"
		:isShowError="showError"
		@change="changeFormProduct"
		@selectItem="handleSelectItem"
	>
	</form-product>
</template>
<script>
import FormProduct from '@/views/common/components/form/FormProduct.vue'
import ModalSearchProduct from '@/views/common/components/modalGen2/ModalSearchProduct.vue'
import { mapGetters, mapMutations } from 'vuex'
import { LargeClass } from '@/utils/define/ItemCode'

export default {
	name: 'CargoFormProduct',
	components: {
		FormProduct,
		ModalSearchProduct,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters('cargo/form', ['category', 'categoryInfo', 'cargoInfo', 'isProductValid', 'isUnitValid', 'cargoFormType', 'showError']),
		hideFieldKey() {
			return this.categoryInfo?.classification !== LargeClass.ETC ? ['currentVolume'] : []
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapMutations('cargo/form', ['setCargoInfo']),
		changeFormProduct(value, fieldKey) {
			const newCargo = { ...this.cargoInfo }

			if (typeof value === 'object') {
				this.setObjectValue(newCargo, fieldKey, value)
			} else {
				newCargo[fieldKey] = value
				this.setCargoInfo(newCargo)
			}
		},
		setObjectValue(newCargo, fieldKey, value) {
			switch (fieldKey) {
				case 'volume':
				case 'currentVolume':
					// 잔여 용량 대응
					this.setVolume(newCargo, fieldKey, value)
					break
				case 'brand':
					// 브랜드
					this.setBrand(newCargo, value)
					break
				case 'phRange':
					this.setPhRange(newCargo, value)
					break
				default:
					console.warn(fieldKey)
					if (value.value != null) {
						newCargo[fieldKey] = value.value
					}
					if (value.unit) {
						newCargo[`${fieldKey}Unit`] = value.unit
					}
					this.setCargoInfo(newCargo)
					break
			}
		},
		setVolume(newCargo, fieldKey, value) {
			newCargo['currentVolumeUnit'] = value.unit ? value.unit : newCargo?.volumeUnit
			newCargo['volumeUnit'] = value.unit ? value.unit : newCargo?.currentVolumeUnit
			if (fieldKey === 'volume') {
				newCargo.volume = value.value
				if (['EDIT', 'NEED_INPUT_LIST', 'NEED_INPUT_DETAIL'].includes(this.cargoFormType)) {
					if (Number(value.value) <= Number(newCargo.currentVolume) || !newCargo.currentVolume) {
						newCargo.currentVolume = value.value
						newCargo.currentVolumeUnit = value.unit ? value.unit : newCargo?.volumeUnit
					}
				} else {
					newCargo.currentVolume = value.value
					newCargo.currentVolumeUnit = value.unit ? value.unit : newCargo?.volumeUnit
				}
			}
			if (fieldKey === 'currentVolume') {
				console.warn(value.value, newCargo.volume)
				newCargo.currentVolume = value.value
				if (!newCargo.volume || newCargo.volume < value.value) {
					newCargo.volume = value.value
				}
			}
			this.setCargoInfo(newCargo)
		},
		setBrand(newCargo, value) {
			if (value.id) {
				newCargo['brandName'] = value.name
				newCargo['brandId'] = value.id
			} else if (value.name) {
				newCargo['brandName'] = value.name
			}

			if (value.name === '' || value.name === null) {
				newCargo['brandName'] = null
				newCargo['brandId'] = null
			}
			this.setCargoInfo(newCargo)
		},
		setPhRange(newCargo, value) {
			newCargo['phRangeFrom'] = value.from
			newCargo['phRangeTo'] = value.to
			this.setCargoInfo(newCargo)
		},
		showRequire() {
			this.$refs['form-product'] && this.$refs['form-product']?.showRequire()
		},
		handleSelectItem(item) {
			const { brand, casno, kind, modelName, name, productCode, spec, subname, volume, volumeUnit } = item
			this.setCargoInfo({
				...this.cargoInfo,
				casno: casno,
				kind: kind,
				modelName: modelName,
				name: name,
				productCode: productCode,
				spec: spec,
				subname: subname,
				volume: volume,
				volumeUnit: volumeUnit,
				currentVolumeUnit: volumeUnit, // 잔여 용량 대응
			})
			if (brand) {
				this.setCargoInfo({ ...this.cargoInfo, brandId: brand.id, brandName: brand.name })
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
