<template>
	<div>
		<div class="keyword-header">
			<span>
				{{ tt('최근 검색어') }}
			</span>
			<button @click.stop="removeAllRecent($event)">
				{{ tt('모두 삭제') }}
			</button>
		</div>
		<ul class="recent-keyword-list">
			<li class="recent-keyword" v-for="(recent, index) in recentList" :key="`keyword_${index}`">
				<img class="recent" src="@/assets/svg/recent-keyword.svg" />
				<p class="keyword-type">{{ keywordTypeText(recent.type) }}</p>
				<span class="keyword-text" @click.stop="$emit('clickRecent', recent)"> {{ recent.value }}</span>
				<img class="keyword-image" src="@/assets/svg/Close-1.svg" @click.stop="removeRecent(recent)" />
			</li>
		</ul>
	</div>
</template>
<script>
const TYPE_TEXT = {
	ALL: '통합검색',
	NAME: '물품명',
	CASNO: 'CAS No',
	CATNO: '제품번호',
	BRAND: '브랜드',
	SELLER: '판매자',
}
export default {
	name: 'InventoryRecentKeywords',
	computed: {
		recentKey() {
			return `${this.$route.name}_${this.$root.user?.id}`
		},
	},
	data() {
		return {
			recentList: [],
		}
	},
	mounted() {
		this.recentList = JSON.parse(this.$cookies.get(this.recentKey) ?? '[]')
	},
	methods: {
		addRecent(recent) {
			if (recent?.value?.length >= 2) {
				if (this.recentList.some(it => it.value === recent.value && it.type === recent.type)) {
					this.recentList = this.recentList.filter(it => it.value !== recent.value || it.type !== recent.type)
				}
				this.recentList.unshift(recent)
				this.recentList = this.recentList.slice(0, 20)
				this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
			}
		},
		removeRecent(recent) {
			this.recentList = this.recentList.filter(it => it.value !== recent.value || it.type !== recent.type)
			this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
		},
		removeAllRecent(event) {
			this.recentList = []
			this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
		},
		keywordTypeText(type) {
			return TYPE_TEXT[type]
		},
	},
}
</script>

<style lang="scss" scoped>
.keyword-type {
	color: $SECONDARY_2;
	font-size: 12px;
	width: 80px;
	margin-left: 5px;
	cursor: pointer;
}
</style>
