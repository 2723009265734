<template>
	<b-modal ref="modal" centered size="xl" scrollable no-close-on-backdrop no-fade>
		<template #modal-title>
			<div class="title">{{ tt('구매내역 조회') }}</div>
		</template>
		<template>
			<div class="table-wrapper">
				<table>
					<thead>
						<tr>
							<th v-for="(item, index) in headerList" :key="'history' + index">
								{{ item }}
							</th>
						</tr>
					</thead>
					<tbody>
						<modal-order-history-item
							:item="orderItem"
							v-for="orderItem in getLicenseHistory"
							@showInvoice="showInovice(orderItem.ciId, orderItem.id)"
							:key="orderItem.id"
						></modal-order-history-item>
					</tbody>
				</table>
			</div>
		</template>
		<template #modal-footer>
			<div class="footer-right">
				<button class="button-white" @click="hide">
					{{ tt('닫기') }}
				</button>
			</div>
		</template>
		<ModalDownloadInvoice ref="ModalDownloadInvoice" />
	</b-modal>
</template>
<script>
import MixinLicense from '@/mixins/license'
import ModalOrderHistoryItemVue from '../components/ModalOrderHistoryItem.vue'
import ModalDownloadInvoice from './ModalDownloadInvoice.vue'
import { mapActions } from 'vuex'
export default {
	name: 'ModalOrderHistory',
	mixins: [MixinLicense],
	components: { 'modal-order-history-item': ModalOrderHistoryItemVue, ModalDownloadInvoice },
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			headerList: [
				this.tt('구매일'),
				this.tt('라이선스 수'),
				this.tt('적용기간'),
				this.tt('금액'),
				this.tt('결제수단'),
				this.tt('상태'),
				this.tt('거래서류 다운로드'),
				this.tt('결제일'),
			],
		}
	},
	computed: {},
	methods: {
		...mapActions('invoice', ['getCi', 'getPreviousCi']),
		async show() {
			await this.loadLicenseHistory()
			this.$refs.modal.show()
		},
		hide() {
			this.$refs.modal.hide()
		},
		async showInovice(ciId, licenseId) {
			let invoiceForm = {}
			if (ciId) {
				console.log(ciId)
				invoiceForm = await this.getCi(ciId)
			} else {
				invoiceForm = await this.getPreviousCi()
				invoiceForm.licenseId = licenseId
			}
			this.$refs['ModalDownloadInvoice'].show(invoiceForm)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-base.scss';
::v-deep {
	.modal-body {
		padding: 0px;
		min-height: 500px;
	}
}
.table-wrapper {
	width: 100%;
	overflow: scroll;
	height: 100%;
	table {
		width: 1100px; //  결제일 추가로 사이즈 fix 시킴
		background-color: $LAB_WHITE;
		max-height: 480px;
		overflow-y: auto;
		border-spacing: 0;
		border-collapse: unset;
		thead {
			position: sticky;
			top: 0;
			height: 40px;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
			background-color: white;
			z-index: 100;

			th {
				font-size: 0.875rem;
				font-weight: 700;
				color: $SECONDARY_2;
				white-space: nowrap;
				padding: 0 10px;
				&:first-child {
					padding-left: 20px;
				}
			}
		}
		tbody {
			tr {
				td {
					height: 50px;
					border-bottom: 1px solid $INPUT;
					font-size: 0.875rem;
					font-weight: 400;
					color: #000;
					&:first-child {
						padding-left: 20px;
					}
					span {
						font-size: 10px;
						font-weight: 400;
						display: block;
					}
					.payment-status {
						b {
							display: flex;
							align-items: center;
							height: auto;
							button {
								padding: 0;
								&:hover {
									.balloon {
										display: block;
										opacity: 1;
										visibility: visible;
									}
								}
							}
						}
						&.warning {
							color: $RED;
						}
					}
					.download-link {
						a {
							font-size: 0.875rem;
							color: $BLUE;
							font-weight: 400;
							display: inline-block;
							text-decoration: underline;
							cursor: pointer;
						}
						span {
							font-size: 0.875rem;
							font-weight: 400;
							display: inline-block;
							margin: 0 3px;
						}
					}
				}
			}
		}
	}
}
</style>
