import list from './list'
import item from './item'
import expenditure from './expenditure'
import status from './status'
export default {
	namespaced: true,
	modules: {
		list,
		item,
		expenditure,
		status,
	},
}
