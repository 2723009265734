<template>
	<div class="registeringMember">
		<div class="wrapper">
			<div class="wrap">
				<div class="flex">
					<h5>{{ tt('멤버 등록') }}</h5>
					<span>{{ tt('멤버 수') }}: {{ count }}{{ tt('명') }}</span>
					<div class="right-btn-group">
						<button class="Btn__black" @click="showInitPwSetting">
							<img src="@/assets/svg/icon-password.svg" />
							{{ tt('초기 비밀번호 설정') }}
						</button>
						<button class="Btn__black" :class="[{ point: orderMember == 2 || isHint }, { pointLeft: isHint }]">
							<img src="@/assets/svg/excel-download.svg" />
							<span @click="goExcel">{{ tt('멤버 일괄 추가') }}</span>
							<div class="order-balloon" v-if="orderMember == 2 || isHint">
								<div class="order-top">
									<div class="order"></div>
									<button @click="closeOrder" v-if="!isHint">
										<img src="@/assets/svg/popup-close.svg" alt="close" />
									</button>
								</div>
								<h6>{{ tt('멤버 일괄 등록하기') }}</h6>
								<p>
									{{ tt('엑셀 혹은 CSV 파일을 업로드하여 한번에 사용자를 추가할 수 있습니다.') }}
								</p>
							</div>
						</button>
					</div>
				</div>
				<div class="boxwrap">
					<div class="flex">
						<search-box :placeholder="tt('검색')" :loadList="loadMemberList"></search-box>
						<div class="right-btn-group">
							<button class="btn" @click="showExportInst()" :disabled="checkList.length == 0">
								<img src="@/assets/svg/PeopleOut.svg" />
								{{ tt('내보내기') }}
							</button>
							<button class="btn" :class="{ point: orderMember == 1 || isHint }" v-if="orderMember != 2">
								<img @click="showRegisteringMember('add')" src="@/assets/svg/Invite.svg" />
								<span @click="showRegisteringMember('add')">{{ tt('멤버 등록') }}</span>
								<div class="order-balloon" v-if="orderMember == 1 || isHint">
									<div class="order-top">
										<div class="order"></div>
										<button @click="orderMember = 2" v-if="!isHint">
											<img src="@/assets/svg/popup-close.svg" alt="close" />
										</button>
									</div>
									<h6>{{ tt('멤버 등록하기') }}</h6>
									<p>
										{{ tt('기관에 소속된 멤버를 등록해주세요.') }}
									</p>
								</div>
							</button>
						</div>
					</div>
					<div class="Table__wrap">
						<table>
							<thead>
								<tr>
									<th>
										<button class="checkbox" :class="({ active: isActive }, { active: isCheck })" @click="allChecked"></button>
									</th>
									<th
										v-for="item in checkHeaderList"
										:key="item.name"
										:width="item.width"
										:class="[{ headerHover: item.sort }, { asc: item.direction == 'ASC' }]"
									>
										<span>
											{{ tt(item.name) }}
											<button @click="changeSort(item)" v-if="item.sort">
												<img src="@/assets/svg/arrow-b.svg" alt="icon" />
											</button>
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<registering-item
									v-for="item in list"
									:key="item.id"
									:item="item"
									@checkId="addCheckIdList"
									@uncheckId="deleteCheckIdList"
									:headers="headerList"
									:offset="offset"
									:length="length"
									:mode="'individual'"
									@showRegisteringMember="showRegisteringMember"
									@showExportInst="showExportInst"
									:checkList="checkList"
									:info="info"
								></registering-item>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total="count" @page="pagination" :isPerPage="false"></pagination>

				<div class="fixed-btn">
					<button class="Btn__yellow">
						{{ tt('멤버등록 완료') }}
					</button>
					<button class="Btn__skip" @click="goDashboard">
						{{ tt('초기설정 건너뛰기') }}
					</button>
				</div>

				<button class="Btn__hint" @click="showHint">
					<img src="@/assets/svg/Hint.svg" />
				</button>
			</div>
		</div>

		<div class="hint-popup" v-if="isHint">
			<div class="Popup__background" @click="hideHint"></div>
		</div>

		<!--modals-->
		<modal-registering-member
			ref="modal-registering-member"
			page="create"
			@complete="completeRegistering"
			@update="updateRegistering"
			:userInfo="info"
		></modal-registering-member>
		<modal-init-pw-setting
			ref="modal-init-pw-setting"
			@registering="showRegistering('add')"
			@hideInitPwSetting="hideInitPwSetting"
		></modal-init-pw-setting>
		<modal-complete-registering-member
			ref="modal-complete-registering-member"
			@registering="showRegisteringMember('add')"
		></modal-complete-registering-member>
		<modal-common ref="modal-common" @isHide="exportInst"></modal-common>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import SearchBox from '@/views/common/components/SearchBox.vue'
import ModalRegisteringMember from '@/views/institute/member/modals/ModalRegisteringMember.vue'
import ModalInitPwSetting from '@/views/institute/member/modals/ModalInitPwSetting.vue'
import ModalCompleteRegisteringMember from '@/views/creation/modals/ModalCompleteRegisteringMember.vue'
import ModalCommon from '@/views/common/components/modals/ModalCommon.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import registeringItem from '@/views/creation/pages/components/registeringItem.vue'
import instituteMemberAPI from '@/services/api/institute/member'
import userAPI from '@/services/api/institute/user'
import Validation from '@/utils/Validation'

export default {
	components: {
		SearchBox,
		ModalRegisteringMember,
		ModalInitPwSetting,
		ModalCompleteRegisteringMember,
		ModalCommon,
		Pagination,
		registeringItem,
	},
	created() {
		if (this.$route.query.orderMember == false) {
			this.closeOrder()
		}

		this.checkHeaderList = this.headerList
		this.count = this.list.length
		this.loadMemberList()
		this.loadInitPw()
		this.loadUser()
	},
	data() {
		return {
			keyword: '',
			isActive: false,
			isCheck: false,
			order: [],
			orderDirection: [],
			count: 0,
			offset: 0,
			length: 25,
			checkList: [],
			headerList: [
				{
					name: this.tt('이름'),
					width: 120,
					sort: true,
					order: 'NAME',
					direction: 'DESC',
				},
				{
					name: this.tt('이메일(ID)'),
					width: 235,
					sort: false,
				},
				{
					name: this.tt('연락처'),
					width: 145,
					sort: false,
				},
				{
					name: this.tt('권한'),
					width: 260,
					sort: false,
				},
				{
					name: this.tt('관리'),
					width: 170,
					sort: false,
				},
			],
			checkHeaderList: [],
			list: [],
			selectedId: null,
			initPw: null,
			info: {},
			orderMember: 1,
			isHint: false,
		}
	},
	watch: {},
	computed: {
		...mapState('institutionInformation', ['listKeyword']),
	},
	methods: {
		async loadUser() {
			const response = await userAPI.lookupUserInfo()

			this.info = response.data
		},
		async loadMemberList() {
			this.checkList = []
			const response = await instituteMemberAPI.lookupMemberList(
				this.$route.params.instituteId,
				this.offset,
				this.length,
				this.listKeyword,
				this.groupsId
			)
			if (response.status == 200) {
				this.list = response.data.members
				this.count = this.list.length
			}

			this.list.forEach((x, index) => {
				if (x.phone.indexOf('-') < 0) {
					this.list[index].phone = Validation.autoHypenTel(this.list[index].phone)
				}
			})
		},
		async loadInitPw() {
			const response = await instituteMemberAPI.lookupInitPw(this.$route.params.instituteId)

			this.initPw = response.data.password
		},
		addCheckIdList(id) {
			this.checkList.push(id)
			if (this.checkList.length == this.list.length) {
				this.isCheck = true
			}
		},
		deleteCheckIdList(id) {
			for (let i = 0; i < this.checkList.length; i++) {
				if (this.checkList[i] == id) {
					this.checkList.splice(i, 1)
				}
			}
			this.isCheck = false
		},
		pagination(page) {
			this.offset = page.offset
			this.length = page.length
		},
		allChecked() {
			if (this.isActive) {
				this.isActive = false
				this.isCheck = false
			} else {
				this.isActive = true
				this.isCheck = true
			}
		},
		changeSort(item) {
			this.order = []
			this.orderDirection = []
			this.order.push(item.order)
			if (item.direction == 'DESC') item.direction = 'ASC'
			else item.direction = 'DESC'
			this.orderDirection.push(item.direction)
			// this.loadDiagnosisList()
		},
		showInitPwSetting(mode) {
			this.$refs['modal-init-pw-setting'].show(mode)
		},
		hideInitPwSetting() {
			this.$refs['modal-init-pw-setting'].hide()
		},
		async showRegisteringMember(mode, item) {
			await this.loadInitPw()
			if (this.initPw == null) {
				this.showInitPwSetting('init')
			} else {
				this.hideInitPwSetting()
				this.$refs['modal-complete-registering-member'].hide()
				this.$refs['modal-registering-member'].show(mode, item)
			}
			this.closeOrder()
		},
		showRegistering(mode) {
			this.$refs['modal-registering-member'].show(mode)
		},
		completeRegistering(info) {
			this.loadMemberList()
			this.$refs['modal-registering-member'].hide()
			this.$refs['modal-complete-registering-member'].show(info)
			this.orderMember = 2
		},
		updateRegistering() {
			this.loadMemberList()
			this.$refs['modal-registering-member'].hide()
		},
		showExportInst(id) {
			if (id) {
				this.selectedId = id
			}

			this.$refs['modal-common'].show('기관에서 내보내기', '정말로 선택한 사용자를 기관에서 내보내시겠습니까?', 'delete', '삭제')
		},
		async exportInst(select) {
			if (select.check == true) {
				let ids = []
				if (this.selectedId) {
					ids.push(this.selectedId)
				} else {
					ids = this.checkList
				}
				await instituteMemberAPI.deleteMember(this.$route.params.instituteId, ids)
				this.$root.toast('내보내기 완료', '멤버를 기관에서 내보냈습니다', 'success')
				this.loadMemberList()
			}
			this.$refs['modal-common'].hide()
			this.selectedId = null
		},
		goExcel() {
			this.$router.push({
				name: 'excelRegisteringMember',
				query: {
					isStart: true,
				},
			})
		},
		goDashboard() {
			this.$router.push({
				name: 'Dashboard',
			})
		},
		closeOrder() {
			this.orderMember = null
		},
		showHint() {
			this.isHint = true
		},
		hideHint() {
			this.isHint = false
		},
	},
}
</script>

<style lang="scss" scoped>
.hint-popup {
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 770;
}

.registeringMember {
	color: #000;
	overflow-y: auto;
	height: 100%;
	margin-top: 0 !important;
	padding-top: 80px;
	.wrapper {
		.wrap {
			width: 1000px;
			margin: 0 auto;
			overflow: initial;
			margin-bottom: 78px;
			> .flex {
				margin-bottom: 20px;
				h5 {
					font-size: 1.5rem;
					font-weight: 700;
				}
				span {
					font-size: 0.875rem;
					color: $DEFAULT;
					font-weight: 400;
					margin-left: 10px;
					display: inline-block;
					margin-top: 3px;
				}
				button {
					height: 30px;
				}
				> .right-btn-group {
					margin-right: 3px;
				}
			}
			.boxwrap {
				width: 100%;
				border: 1px solid $INPUT;
				border-radius: 5px;
				overflow: initial;
				.flex {
					padding: 20px 30px;
					width: 100%;
				}
				.Table__wrap {
					height: unset;
					min-height: 600px;
					overflow: initial;
					overflow-x: auto;
					td {
						&:last-child {
							button {
								display: inline-block;
								&:last-child {
									margin-left: 10px;
								}
							}
						}
					}
				}
			}
		}
		.Table__pagination {
			border-top: 0;
		}
	}
	.fixed-btn {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		height: 78px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid $DIVIDER;
		background-color: $LAB_WHITE;
		.Btn__yellow {
			width: 195px;
			height: 48px;
			font-size: 1rem;
			font-weight: 700;
		}
		.Btn__skip {
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translate(0, -50%);
			font-size: 1rem;
			color: $SECONDARY_2;
			font-weight: 700;
			padding-right: 20px;
			background-image: url(~@/assets/svg/arrow-right.svg);
			background-repeat: no-repeat;
			background-position: center right 0;
		}
	}
	.point {
		.order-balloon {
			.order-top {
				position: absolute;
				width: 100%;
				right: 30px;
				top: 30px;
			}
		}
	}
}
</style>
