<template>
	<div class="footer-wrap" v-if="item">
		<div class="left-wrap">
			<!-- <button v-if="isShowEdit" class="button-gray" @click="showModalItemProduct">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-edit.svg" />
				<p>{{ tt('편집') }}</p>
			</button>
			<button v-if="isShowDelete" class="button-gray" @click="$refs['modal-delete'].show()">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-round-delete.svg" />
				<p>{{ tt('삭제') }}</p>
			</button> -->
		</div>
		<div class="right-wrap">
			<button class="button-gray" @click="searchStore">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-cart.svg" />
				<p>{{ tt('랩매니저 스토어에서 찾기') }}</p>
			</button>

			<button class="button-gray" @click="printItemIdSticker">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-print.svg" />
				<p>{{ tt('물품ID 스티커 출력') }}</p>
			</button>
			<button class="button-gray" @click="requestPurchase">
				<img class="image-icon" src="@/views/inventoryGen2/components/svg/icon-request-buy.svg" />
				<p>{{ tt('구매 요청') }}</p>
			</button>
			<button v-if="isShowGHSButton && !isGhsRegistered" class="button-gray" @click="$emit('makeGHS')">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-ghs.svg" />
				<p>{{ tt('GHS 라벨 생성') }}</p>
			</button>
			<button v-else-if="isShowGHSButton && isGhsRegistered" class="button-gray" @click="$emit('manageGHS')">
				<img class="image-icon" src="@/views/purchase/cargo/svg/icon-ghs.svg" />
				<p>{{ tt('GHS 라벨 관리') }}</p>
			</button>
			<button class="button-gray" @click="divide" v-if="isCategoryChemical && hasCurrentVolume && isAvailable">
				<img class="image-icon" src="@/views/inventoryGen2/components/svg/icon-subdivide.svg" />
				<p>{{ tt('소분') }}</p>
			</button>

			<button v-if="!isCategoryEquipment && hasCurrentVolume && !isUnAvailable" class="button-gray" @click="$refs['modal-input-use'].show()">
				<img v-if="isCategoryChromato" class="image-icon" src="@/views/inventoryGen2/svg/detail/icon-input-use-chromato.svg" />
				<img v-else class="image-icon" src="@/views/inventoryGen2/components/svg/icon-input-use.svg" />
				<p>{{ tt(inputUseButtonText) }}</p>
			</button>

			<button class="button-gray" @click="$refs['modal-select-storage'].show(item.storage)">
				<img class="image-icon" src="@/views/inventoryGen2/components/svg/icon-move.svg" />
				<p>{{ tt('보관함 변경') }}</p>
			</button>

			<button class="button-gray" @click="$refs['modal-dispose'].show()" v-if="!item.isDispose">
				<img class="image-icon" src="@/views/inventoryGen2/components/svg/icon-disposal.svg" />
				<p>{{ tt('폐기') }}</p>
			</button>
		</div>
		<modal-input-use
			ref="modal-input-use"
			:isCanCount="!isCategoryEquipment"
			:title="inputUseTitle"
			:volume="item.currentVolume"
			:volumeUnit="volumeUnit"
			:largeClass="item.category.classification"
			:useCount="item.useCount"
			type="CONSUME"
			cancelText="취소"
			confirmText="완료"
			@confirm="consume"
			@showEmptyVolume="body => $refs['modal-empty-volume'].show(body)"
		></modal-input-use>
		<modal-empty-volume
			ref="modal-empty-volume"
			title="잔여 용량 없음"
			type="DISPOSE"
			:largeClass="item.category.classification"
			@dispose="disposeEmptyVolume"
			@noDispose="noDisposeEmptyVolume"
		>
		</modal-empty-volume>
		<modal-textarea
			ref="modal-dispose"
			title="물품 폐기"
			titleTextarea="폐기 사유"
			headerText="물품을 정말로 폐기하시겠습니까?<br />이 동작은 되돌릴 수 없습니다."
			placeholder="폐기 사유를 입력해주세요(200자 제한)"
			confirmText="폐기"
			variant="warning"
			type="DISPOSE"
			@complete="dispose"
		></modal-textarea>
		<modal-single-select-storage title="위치 및 보관함 선택" ref="modal-select-storage" @complete="changeStorage"></modal-single-select-storage>
		<!-- <modal-textarea
			ref="modal-delete"
			title="물품 삭제"
			titleTextarea="삭제 사유"
			headerText="물품을 정말로 삭제하시겠습니까?<br />이 동작은 되돌릴 수 없습니다."
			placeholder="삭제 사유를 입력해주세요(200자 제한)"
			confirmText="삭제"
			variant="warning"
			type="DELETE"
			@complete="remove"
		></modal-textarea> -->
		<!-- <modal-item-product ref="modal-item-product" title="물품정보 편집" @complete="$refs['modal-item-management'].show()"></modal-item-product>
		<modal-item-management ref="modal-item-management" title="물품정보 편집" @goPrev="$refs['modal-item-product'].show()"></modal-item-management> -->
		<modal-divide ref="modal-divide"></modal-divide>
		<modal-select-sticker-type
			ref="modal-select-sticker-type"
			:item="item"
			@complete="$refs['modal-print-sticker'].show()"
		></modal-select-sticker-type>
		<modal-print-sticker-single ref="modal-print-sticker" type="inventory" :item="item"></modal-print-sticker-single>
	</div>
</template>
<script>
import { LargeClass, MiddleClass } from '@/utils/define/ItemCode'
import ModalEmptyVolume from '@/views/inventoryGen2/components/detail/modal/modalEmptyVolume/index.vue'
import ModalInputUse from '@/views/inventoryGen2/components/detail/modal/modalInputUse/index.vue'
import ModalTextarea from '@/views/inventoryGen2/components/detail/modal/modalTextarea/index.vue'
import PageName from '@/utils/define/PageName'
import ModalSingleSelectStorage from '@/views/common/components/modalGen2/ModalSingleSelectStorage.vue'
// import ModalItemProduct from '@/views/inventoryGen2/components/regist/modal/ModalItemProduct.vue'
// import ModalItemManagement from '@/views/inventoryGen2/components/regist/modal/ModalItemManagement.vue'
import ModalDivide from '@/views/inventoryGen2/components/detail/modal/modalDivide/index.vue'
import ModalPrintStickerSingle from '@/views/inventoryGen2/components/detail/modal/modalPrintStickerSingle/index.vue'
import ModalSelectStickerType from '@/views/inventoryGen2/components/detail/modal/modalSelectStickerType/index.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MixinInventoryDetail from '@/views/inventoryGen2/components/detail/mixin/MixinInventoryDetail.js'
export default {
	name: 'DetailFooter',
	components: {
		ModalEmptyVolume,
		ModalInputUse,
		ModalSingleSelectStorage,
		ModalTextarea,
		// ModalItemProduct,
		// ModalItemManagement,
		ModalDivide,
		ModalPrintStickerSingle,
		ModalSelectStickerType,
	},
	mixins: [MixinInventoryDetail],
	props: {
		item: {
			type: Object,
			default: null,
		},
		isShowEdit: {
			type: Boolean,
			default: true,
		},
		isShowDelete: {
			type: Boolean,
			default: true,
		},
		isShowGHSButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('modal/inputUse', ['useVolume']),
		isCategoryChemical() {
			return [LargeClass.CHEMICAL].includes(this.item?.category.classification)
		},
		isGhsRegistered() {
			if (this.item) {
				return !!this.item?.ghs
			} else {
				return null
			}
		},
		inputUseTitle() {
			switch (this.item?.category?.classification) {
				case LargeClass.CHROMATOGRAPHY:
					return '사용횟수 입력하기'
				case LargeClass.ETC:
					return '사용수량 입력하기'
				default:
					return '사용량 입력하기'
			}
		},
		inputUseButtonText() {
			switch (this.item?.category?.classification) {
				case LargeClass.CHROMATOGRAPHY:
					return '사용횟수 입력'
				case LargeClass.ETC:
					return '사용수량 입력'
				default:
					return '사용량 입력'
			}
		},
		volumeUnit() {
			return this.item.volumeUnit ?? '회'
		},
		isCategoryEquipment() {
			return this.item?.category?.type === MiddleClass.EQUIPMENT
		},
		isCategoryChromato() {
			return this.item?.category?.type === MiddleClass.CHROMATOGRAPHY
		},
		hasCurrentVolume() {
			return this.item?.currentVolume > 0
		},
		customFieldObject() {
			let obj = {}
			this.item?.customField?.forEach(item => {
				obj[item.key] = item.value
			})
			return obj
		},
		isAvailable() {
			return this.item.status === 'AVAILABLE'
		},
		isUnAvailable() {
			return this.item.status == 'UNAVAILABLE'
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		...mapActions('inventoryGen2/item', ['changeItemStorage', 'consumeItem', 'disposeItem', 'removeItem', 'returnItem']),
		...mapMutations('inventoryGen2/form', ['setCategory', 'setFormItem', 'setItemFormType', 'setItemCustomFields']),
		...mapActions('inventoryGen2/form', ['getCategoryInfo']),
		...mapMutations('form/image', ['setRemoveImageLink']),
		...mapActions('inventoryGen2/historyInfo', ['getList']),
		...mapMutations('tag', ['setSelectedTag']),
		requestPurchase() {
			this.$router.push({
				name: PageName.Purchase.DraftRegistration,
				params: {
					mode: 'write',
				},
				query: {
					name: this.item?.name,
					productCode: this.item?.productCode,
					brandId: this.item?.brand?.id,
				},
			})
		},
		async changeStorage(selectStorage) {
			try {
				if (selectStorage === null) return
				await this.changeItemStorage({
					itemsId: [this.item.id],
					storageId: selectStorage.id,
				})
				await this.getList()
				this.$root.toast('보관함 변경 완료', '보관함이 변경되었습니다', 'success')
			} catch (e) {
				this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
			}
		},
		async consume(body) {
			try {
				await this.consumeItem({ id: this.item.id, ...body })
				const isChromato = this.item?.category?.classification === LargeClass.CHROMATOGRAPHY
				const text = isChromato ? '사용횟수' : '사용량'
				const textWithJosa = isChromato ? '사용횟수가' : '사용량이'
				await this.getList()
				this.$root.toast(`${text} 입력 완료`, `${textWithJosa} 입력되었습니다`, 'success')
			} catch (e) {
				this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
			}
		},
		// async remove(memo) {
		// 	try {
		// 		await this.removeItem({
		// 			itemsId: [this.item.id],
		// 			memo: encodeURIComponent(memo),
		// 		})
		// 		this.$root.toast('삭제 완료', '물품이 삭제되었습니다', 'success')
		// 		this.goToInventoryList()
		// 	} catch (e) {
		// 		this.$root.toast(`${e.response?.status} Error`, `Error msg: ${e.response?.data?.msg}`, 'error')
		// 	}
		// },
		searchStore() {
			this.$root.goToStore(`search/list`, { keyword: this.item.name, type: 'NAME' })
		},
		// async showModalItemProduct() {
		// 	this.setItemFormType('NORMAL_EDIT')
		// 	await this.setCategory(this.item.category)
		// 	await this.getCategoryInfo()
		// 	await this.setFormItem(this.item)
		// 	await this.setItemCustomFields(this.customFieldObject)
		// 	this.setSelectedTag(this.item.tagList.map(tag => tag.id))
		// 	this.setRemoveImageLink([])

		// 	this.$refs['modal-item-product'].show()
		// },
		divide() {
			this.$refs['modal-divide'].show()
		},
		printItemIdSticker() {
			if (this.item?.stickerType) {
				this.$refs['modal-print-sticker'].show()
			} else {
				this.$refs['modal-select-sticker-type'].show()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.footer-wrap {
	display: flex;
	position: fixed;
	bottom: 0;
	width: calc(100% - 250px);
	height: 60px;
	background-color: white;
	border-top: 1px solid $DIVIDER;
	justify-content: space-between;
	padding: 15px 30px;
	align-items: center;
	z-index: 200;
	.left-wrap {
		display: flex;
		gap: 10px;
	}
	.right-wrap {
		display: flex;
		gap: 10px;
	}
}

.button-gray {
	justify-content: center;
	background: $GREY_4;
	display: flex;
	border-radius: 4px;
	padding: 5px 10px;
	height: 30px;
	font-size: 14px;
	font-weight: 700;
	&:hover {
		background: $HOVER_BUTTON_COMMON;
	}
	.image-icon {
		width: 24px;
		height: 24px;
		margin-right: 2px;
	}
}

.modal-area {
	position: absolute;
}
</style>
