import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		textMaxLength: {
			type: Number,
			default: 100,
		},
		noRecent: {
			type: Boolean,
			default: false,
		},
		largeClass: {
			type: String,
		},
		textAreaTitle: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isFocus: false,
			text: '',
			isShowTextError: false,
			commentList: [],
			consumeBody: null,
		}
	},
	computed: {
		isLengthOver() {
			return this.text.length > this.textMaxLength
		},
		isKeywordWrapperOpen() {
			return this.isFocus && this.text.length <= 0 && !this.noRecent
		},
	},
	watch: {
		isLengthOver(value) {
			if (!this.isLengthOver) this.isShowTextError = false
		},
	},
	created() {},
	mounted() {},
	methods: {
		...mapActions('inventoryGen2/item', ['getComment', 'addComment', 'deleteComment']),
		initData() {
			this.text = ''
			this.isFocus = false
			this.isShowTextError = false
		},
		async show(body) {
			this.consumeBody = body
			this.$refs['modal'].show()
			if (this.type) this.commentList = await this.getComment(this.type)
		},
		hide() {
			this.$refs['modal'].hide()
			this.initData()
		},
		cancel() {
			this.$emit('cancel')
			this.hide()
		},
		checkValid() {},
		disposeComplete() {
			if (this.isLengthOver) {
				this.isShowTextError = true
				return
			}
			// if (this.text) this.$refs['recent']?.addRecent(this.text)
			this.$emit('dispose', this.text, this.consumeBody)
		},
		noDisposeComplete() {
			if (this.isLengthOver) {
				this.isShowTextError = true
				return
			}
			// if (this.text) this.$refs['recent']?.addRecent(this.text)
			this.$emit('noDispose', this.consumeBody)
		},
		clickRecent(text) {
			this.text = text
			// if (!this.noRecent) this.$refs['recent']?.addRecent(this.text)
			this.isFocus = false
		},
		focusOut() {
			this.isFocus = false
		},
		async clickDeleteComment(id) {
			await this.deleteComment(id)
			this.commentList = await this.getComment(this.type)
		},
	},
}
