<template>
	<tr class="table-item" :class="{ new: isNew }" @click="goToDetail">
		<td @click.stop>
			<!-- 체크박스 -->
			<span class="checkbox"><input type="checkbox" v-model="checked" /> </span>
		</td>
		<td>
			<div class="profile-wrap" style="min-width: 100px">
				<img class="profile-img" :src="userImage" />
				<p>{{ tt(item.user.name) }}</p>
			</div>
		</td>
		<td>
			<div>
				<span style="font-weight: 700">{{ tt(type.title) }}</span>
			</div>
		</td>
		<td style="height: 80px">
			<div class="content-wrap" style="min-width: 250px; max-width: 370px">
				<span v-html="tt(type.content)" v-b-tooltip.hover.html="tt(type.content)"></span>
			</div>
		</td>
		<td v-if="isView('categoryClassification')">
			<div>
				<span>{{ tt(categoryClasification) }}</span>
			</div>
		</td>
		<td v-if="isView('categoryType')">
			<div>
				<span>{{ tt(categoryType) }}</span>
			</div>
		</td>
		<td v-if="isView('category')">
			<div>
				<span>{{ categoryKor }}</span>
			</div>
		</td>
		<td v-if="isView('mngCode')">
			<div v-b-tooltip.hover="item.inventory.mngcode == null ? '관리번호 생성중입니다.\n1~2분정도 소요됩니다.' : null">
				<span>{{ item.inventory.mngcode == null && item.inventory.isPostprocessing ? '생성중' : item.inventory.mngcode ?? '생성중' }}</span>
			</div>
		</td>
		<td v-if="isView('name')">
			<div style="min-width: 250px; max-width: 400px">
				<h6 style="font-weight: 700">{{ isEmpty(item.inventory.name) }}</h6>
				<div class="subtext" v-if="item.inventory">
					<span v-if="item.inventory.brand && item.inventory.brand.name">{{ item.inventory.brand.name }}</span>
					<span v-if="item.inventory.subname">{{ item.inventory.subname }}</span>
				</div>
			</div>
		</td>
		<td v-if="isView('brand')">
			<div>
				<span>{{ item.inventory.brand ? isEmpty(item.inventory.brand.name) : '-' }}</span>
			</div>
		</td>
		<td v-if="isView('productCode')">
			<div>
				<span>{{ isEmpty(item.inventory.productCode) }}</span>
			</div>
		</td>
		<td v-if="isView('createDatetime')">
			<div>
				<span>{{ createDatetime }}</span>
			</div>
		</td>
		<td>
			<div style="min-width: 80px">
				<button @click.stop="$refs['modal-remove'].show()">
					<img src="@/assets/svg/icon-disposal.svg" />
				</button>
			</div>
		</td>
		<td>
			<div style="min-width: 80px">
				<button v-if="isDelete" @click.stop="clickRestore">
					<img src="@/views/inventoryGen2/svg/Restore.svg" />
				</button>
			</div>
		</td>
		<modal-confirm
			ref="modal-remove"
			title="기록 삭제하기"
			confirmText="삭제하기"
			variant="warning"
			:content="`정말로 선택한 기록을 삭제하시겠습니까?</br>이 동작은 되돌릴 수 없습니다.`"
			@confirm="remove"
		/>
		<modal-confirm ref="modal-restore" title="물품 복구" confirmText="복구" :content="`물품을 복구하시겠습니까?`" />
		<modal-confirm
			ref="modal-restore-impossible"
			title="물품 복구 불가"
			cancelText="확인"
			:hasConfirm="false"
			:content="'물품의 기존 카테고리가 삭제되어 복구할 수 없습니다.'"
		/>
		<modal-confirm
			ref="modal-restore-change-storage"
			title="물품 복구"
			confirmText="복구"
			:content="`물품이 위치하던 기존 보관함이 존재하지 않아 보관함 선택이 필요합니다.</br>물품을 복구하시겠습니까?`"
		/>
	</tr>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import MixinItem from '@/mixins/inventory/MixinItem'
import { LargeClassToString, MiddleClassToString } from '@/utils/define/ItemCode'
import { DateTime } from 'luxon'
import item from '@/store/modules/budget/item'
export default {
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	mixins: [MixinItem],
	computed: {
		...mapState('inventoryGen2/history', ['selectedItems', 'checkedViewOption']),
		type() {
			const convertText = this.replaceAllText(this.item.memo, '<br />')
			switch (this.item.type) {
				case 'ADD':
					return {
						title: '등록',
						content: '-',
					}
				case 'EDIT':
					return {
						title: '편집',
						content: '-',
					}
				case 'HOLD':
					return {
						title: '사용',
						content: '-',
					}
				case 'RETURN':
					return {
						title: '사용완료',
						content: convertText ?? '-',
					}
				case 'CONSUME':
					let volume = this.item.changeUseCount ? this.item.changeUseCount : this.item.changeVolume
					let unit = this.item.changeUseCount ? this.item.volumeUnit : this.item.changeVolumeUnit
					let beforeStr = this.item.changeUseCount ? '사용횟수' : '사용량'
					return {
						title: this.tt('${1} 입력${2}', `${beforeStr}`, `[${volume}${unit}]`),
						content: convertText ?? '-',
					}
				case 'DISPOSE':
					return {
						title: '폐기',
						content: convertText ?? '-',
					}
				case 'DELETE':
					return {
						title: '삭제',
						content: convertText ?? '-',
					}
				case 'RESTORE':
					return {
						title: '복구',
						content: convertText ?? '-',
					}
				case 'REASSIGN':
					let beforeAssigneeImage = this.item.beforeAssignee?.image ?? require('@/assets/svg/member-photo-default.svg')
					let afterAssigneeImage = this.item.afterAssignee?.image ?? require('@/assets/svg/member-photo-default.svg')
					return {
						title: '담당자 변경',
						content:
							this.item.beforeAssignee && this.item.afterAssignee
								? `<div class="assignee-change"><img class="assignee-img" src="${beforeAssigneeImage}" /><p>${this.item.beforeAssignee.name}</p> -> <img class="assignee-img " src="${afterAssigneeImage}" /><p>${this.item.afterAssignee.name}</p></div>`
								: '-',
					}
				case 'SUBDIVIDE':
					return {
						title: '소분',
						content: convertText ?? '-',
					}
				case 'CHANGE_STORAGE':
					return {
						title: '보관함 변경',
						content:
							this.item.beforeStorage && this.item.afterStorage
								? `${this.item.beforeStorage.locationPathName}&${this.item.beforeStorage.pathName} -> ${this.item.afterStorage.locationPathName}&${this.item.afterStorage.pathName}`
								: '-',
					}
				default:
					return {
						title: '-',
						content: '-',
					}
			}
		},
		userImage() {
			return this.item.user && this.item.user.image ? this.item.user.image : require('@/assets/svg/member-photo-default.svg')
		},
		categoryKor() {
			if (!this.item.inventory?.category?.type) return ''
			return this.tt(MiddleClassToString(this.item.inventory.category.type))
		},
		categoryClasification() {
			return LargeClassToString(this.item.inventory?.category?.classification) ?? '-'
		},
		categoryType() {
			return MiddleClassToString(this.item.inventory?.category?.type) ?? '-'
		},
		checked: {
			get() {
				return this.selectedItems.some(item => item.id === this.item.id)
			},
			set(value) {
				if (value) {
					this.setSelectedItems(this.selectedItems.concat(this.item))
				} else {
					this.setSelectedItems(this.selectedItems.filter(item => item.id !== this.item.id))
				}
			},
		},
		isDelete() {
			return this.item.type === 'DELETE' || this.item.type === 'DISPOSE'
		},
		defaultUnCheck() {
			return ['categoryClassification', 'categoryType', 'brand', 'productCode']
		},
	},
	methods: {
		...mapMutations('inventoryGen2/history', ['setSelectedItems']),
		...mapActions('inventoryGen2/history', ['removeItem', 'checkRestore', 'restoreItem']),
		goToDetail() {
			this.setNew()
			this.$emit('click', this.item.inventory.id)
		},
		async remove() {
			await this.removeItem({ ids: [this.item.id] })
			this.$root.toast('기록 삭제 완료', `물품 기록이 삭제되었습니다.`, 'success')
			this.setSelectedItems([])
		},
		async clickRestore() {
			let isRestore = false
			const response = await this.checkRestore({ itemsId: [this.item.inventory.id] })
			Object.entries(response).forEach(async ([key, value]) => {
				if (key === 'possible' && value[0]) {
					isRestore = true
				}
			})

			if (isRestore) {
				this.$refs['modal-restore'].show(() => {
					this.restore(response.possible[0])
				})
			} else {
				if (response.impossible.category.length > 0) {
					this.$refs['modal-restore-impossible'].show()
				} else {
					this.$refs['modal-restore-change-storage'].show(this.$emit('showSelectStorageModal', response.impossible.storage[0]))
				}
			}
		},
		async restore(id) {
			await this.restoreItem({ itemsId: [id] })
			this.$root.toast(this.tt('물품 복구 완료'), this.tt('물품이 복구되었습니다.'), 'success')
		},
		replaceAllText(val, params = ' ') {
			return val ? val.replaceAll('\\n', params) : null
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
::v-deep .tooltip-inner {
	text-align: left;
}
td {
	button {
		width: 36px;
		height: 36px;
		background: $GREY_4;
		border-radius: 4px;
	}
}
.status {
	font-weight: 700;
	color: $RED;
	&.AVAILABLE {
		color: $GREEN;
	}
	&.HOLDING_OWN {
		color: $BLUE;
	}
}
.subtext {
	display: flex;
	gap: 10px;
	span {
		font-size: 12px;
		color: $SECONDARY_2;
		&:nth-child(2) {
			padding-left: 10px;
			border-left: 1px solid $INPUT;
		}
	}
}
.content-wrap {
	display: flex;
	flex-direction: column;
	span {
		display: -webkit-box;
		max-height: 40px;
		overflow: hidden;
		vertical-align: top;
		text-overflow: ellipsis;
		word-break: break-all;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
	div {
		display: flex;
		gap: 5px;
	}
}
::v-deep .assignee-change {
	display: flex;
	gap: 5px;
}
::v-deep .assignee-img {
	border-radius: 24px;
	width: 24px;
	height: 24px;
	border: 1px solid $INPUT;
}
</style>
